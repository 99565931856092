import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Grid, Segment, Button, Dimmer, Loader } from "semantic-ui-react";
import { LevelsColumn } from "../../../../Components/Selector/LevelsColumn";
import { TypesColumn } from "../../../../Components/Selector/TypesColumn";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
  selectLevel,
  selectAllLevels,
  selectType,
  selectAllTypes,
  selectTypeCeilings,
  selectAllTypesCeilings,
  selectLevelCeilings,
  selectAllLevelsCeilings,
} from "../Actions/index";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { selectedItems } from "../Helpers";
import { WithTranslation, withTranslation } from "react-i18next";
import Tab from "./Tab";

const eraseSelectionHandler = async (props: Props) => {
  const tree = await api.selection.elementsByLevelAndType("wall");
  const treeCeiling = await api.selection.elementsByLevelAndType("ceiling");
  let selectedTypes: any[] = [];
  props.typesData.forEach((e) => {
    if (e.Checked) selectedTypes.push(e.Type);
  });
  let selectedTypesCeilings: any[] = [];
  let selectedElemsCeilings: any[] = [];

  if (props.typesDataCeilings) {
    props.typesDataCeilings.forEach((e) => {
      if (e.Checked) selectedTypesCeilings.push(e.Type);
    });

    selectedElemsCeilings = [].concat.apply(
      [],
      selectedItems(
        treeCeiling,
        props.selectedLevelsCeilings,
        selectedTypesCeilings
      ).map((e: any) => e.Ids)
    );
  }
  let selectedElemsWall: any[] = [].concat.apply(
    [],
    selectedItems(tree, props.selectedLevels, selectedTypes).map(
      (e: any) => e.Ids
    )
  );

  let selectedElems: any[] = [...selectedElemsWall, ...selectedElemsCeilings];

  if (selectedElems.length) {
    const nb_walls = selectedElems.length;
    for (let i = 0; i < nb_walls; i++) {
      await api.familyEditor.setParams([
        {
          Id: selectedElems[i],
          Params: [{ Name: "Processed", Type: "YesNo", Value: 0 }],
        },
      ]);
      await api.familyEditor.setParams([
        {
          Id: selectedElems[i],
          Params: [{ Name: "SG_System", Type: "Text", Value: " " }],
        },
      ]);
      const elems = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: {
              Name: "id",
              Type: "Integer",
              Value: parseInt(selectedElems[i]),
            },
            Rule: "Equals",
          },
        ],
        null
      );
      const albaElems = await api.queries.filterElements(
        "Wall",
        [
          {
            Param: {
              Name: "AlbaId",
              Type: "Text",
              Value: selectedElems[i],
            },
            Rule: "Equals",
          },
        ],
        null
      );
      await api.familyEditor.deleteElementsByIds(elems.concat(albaElems));
    }
    api.windowsHandler.closeWindow();
  }
};

interface Props {
  selectorType: string;
  Icon: string;
  language: string;
  levelsData: LevelData[];
  typesData: TypeData[];
  typesDataCeilings: TypeData[];
  levelsDataCeilings: LevelData[];
  selectedLevelsCeilings: string[];
  selectedTypesCeilings: string[];
  routes: any;
  selectedLevels: string[];
  selectedTypes: string[];
  selectLevel: any;
  selectAllLevels: Function;
  selectType: any;
  selectAllTypes: Function;
  selectAllTypesCeilings: Function;
  selectLevelCeilings: Function;
  selectAllLevelsCeilings: Function;
  selectTypeCeilings: Function;
  setRoute: any;
}

interface State {
  erasing: boolean;
}

export class GroupSelector extends React.Component<
  Props & WithTranslation,
  State
> {
  componentDidMount = () => {
    api.windowsHandler.resizeWindow(900, 500);
  };

  state = {
    erasing: false,
  };

  //   public static defaultProps={
  //     wording:{
  //         levels:{
  //             "French": "Niveaux",
  //             "English": "Levels",
  //             "German": "Ebenen",
  //             "Italian": "Livelli"
  //         },
  //         ceilingTypes:{
  //             "French": "Types de plafonds",
  //             "English": "Ceiling types",
  //             "German": "Deckentypen",
  //             "Italian": "Tipi di soffito"
  //         },
  //         wallTypes:{
  //           "French": "Types de murs",
  //           "English": "Wall types",
  //           "German": "Wandtypen",
  //           "Italian": "Tipi di muro"
  //         },
  //         selection:{
  //             "French": "Sélection",
  //             "English": "Selection",
  //             "German": "Auswahl",
  //             "Italian": "Selezione"
  //         },
  //         back:{
  //           "French": "Retour",
  //           "English": "Back",
  //           "German": "Zurück",
  //           "Italian": "Indietro"
  //         },
  //         validate:{
  //             "French": "Valider",
  //             "English": "Validate",
  //             "German": "Bestätigen",
  //             "Italian": "Convalidare"
  //         },
  //         deletePending:{
  //           "French": "Suppression en cours...",
  //           "English": "Deleting...",
  //           "German": "Löschen...",
  //           "Italian": "La cancellazione in corso..."
  //       }
  //     }
  // }

  selectorTypes = () => {
    const { t } = this.props;
    switch (this.props.selectorType) {
      case "wall":
        return t("wallTypes");
      case "ceiling":
        return t("ceilingTypes");
      default:
        return t("wallTypes");
    }
  };

  backHandler = () => {
    this.props.setRoute(Routes.ROOT);
  };

  validateHandler = () => {
    if (this.props.selectedTypes.length) {
      this.props.setRoute(Routes.MAPPING);
    }
  };

  render() {
    const { t } = this.props;
    if (this.state.erasing) {
      return (
        <Dimmer active={true}>
          <Loader content={t("deletePending")}></Loader>
        </Dimmer>
      );
    }

    return (
      <div id="delete-groupSelector-container">
        <FunctionalityHeader
          Icon={this.props.Icon}
          name={t("selection")}
          width={80}
          height={30}
        />
        <div style={{ padding: 5, marginBottom: "5%" }}>
          <div style={{ width: "100%", backgroundColor: "none" }}>
            <Tab
              tabWallName={t("wall")}
              tabWallContent={
                <div style={{ margin: "auto" }}>
                  <Grid
                    columns={2}
                    stackable
                    style={{
                      backgroundColor: "none",
                      height: 340,
                    }}
                    container
                    centered
                  >
                    <LevelsColumn
                      selectLevel={this.props.selectLevel}
                      selectAllLevels={this.props.selectAllLevels}
                      columnTitle={t("levels")}
                      levelsData={this.props.levelsData}
                      selectedLevels={this.props.selectedLevels}
                      color="blue"
                    />
                    <TypesColumn
                      selectType={this.props.selectType}
                      selectAllTypes={this.props.selectAllTypes}
                      selectedLevels={this.props.selectedLevels}
                      columnTitle={this.selectorTypes()}
                      color="blue"
                      typesData={this.props.typesData}
                    />
                  </Grid>
                </div>
              }
              tabCeilingName={t("ceiling")}
              tabCeilingContent={
                <div style={{ margin: "auto" }}>
                  <Grid
                    columns={2}
                    stackable
                    style={{
                      backgroundColor: "none",
                      height: 340,
                    }}
                    container
                    centered
                  >
                    <LevelsColumn
                      selectLevel={this.props.selectLevelCeilings}
                      selectAllLevels={this.props.selectAllLevelsCeilings}
                      columnTitle={t("levels")}
                      levelsData={this.props.levelsDataCeilings}
                      selectedLevels={this.props.selectedLevelsCeilings}
                      color="blue"
                    />
                    <TypesColumn
                      selectType={this.props.selectTypeCeilings}
                      selectAllTypes={this.props.selectAllTypesCeilings}
                      selectedLevels={this.props.selectedLevelsCeilings}
                      columnTitle={t("CeilingTypes")}
                      color="blue"
                      typesData={this.props.typesDataCeilings}
                    />
                  </Grid>
                </div>
              }
            />
            <Segment basic>
              <Button onClick={this.backHandler} floated="left" primary>
                {t("back")}
              </Button>
              <Button
                onClick={(e) => {
                  this.setState({ erasing: true });
                  eraseSelectionHandler(this.props);
                }}
                // disabled={this.props.selectedTypes.length === 0 || this.props.selectedTypesCeilings.length === 0}
                floated="right"
                primary
              >
                {t("validate")}
              </Button>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    levelsData: state.levelsData,
    typesData: state.typesData,
    selectedLevels: state.selectedLevels,
    selectedTypes: state.selectedTypes,
    levelsDataCeilings: state.levelsDataCeilings,
    typesDataCeilings: state.typesDataCeilings,
    selectedLevelsCeilings: state.selectedLevelsCeilings,
    selectedTypesCeilings: state.selectedTypesCeilings,
    name: ownProps.name,
    setRoute: ownProps.setRoute,
  };
};

export default connect(mapStateToProps, {
  selectType,
  selectAllTypes,
  selectLevel,
  selectAllLevels,
  selectTypeCeilings,
  selectAllTypesCeilings,
  selectLevelCeilings,
  selectAllLevelsCeilings,
})(withTranslation("riggibs")(GroupSelector));
