import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { LicenseRoot } from "./Components/root";

export const functionalityId = "58a79ec5-15a7-4c37-9862-debee03341c8";

export const licenseTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    350,
    300,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <LicenseRoot />
    </ParentRoot>
  );
};

export const LicenseRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <LicenseRoot />
    </ParentRoot>
  );
};

export const LicenseWindow: Functionality = {
  Name: {},
  Trigger: licenseTrigger,
  ShortDesc: {},
  Id: functionalityId,
  Icon: "",
  RootComponent,
  ParentId: "0",
};

export default LicenseWindow;
