import React, { Component, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import SavedSelection from "./SavedSelection";
import _, { filter, map } from "lodash";
import { Selection } from "../Actions/types";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";

interface State {}

const mapState = (
  state: SelectionStore,
  ownProps: {
    setRoute: any;
    crudSelectionId: any;
    revitViewName: string;
    selections?: {
      [key: string]: Selection<PlacoOptions>;
    };
  }
) => ({
  selections: state.selections || ownProps.selections,
  setRoute: ownProps.setRoute,
  moduleData: state.moduleData,
  crudSelectionId: ownProps.crudSelectionId,
  revitViewName: ownProps.revitViewName,
  language: state.language,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = InjectedCounterProps &
  PropsFromRedux & {
    selections: { [key: string]: Selection<PlacoOptions> };
    setRoute: any;
    module: string;
    selectionPar: boolean;
    setSelectionId: any;
    crudSelectionId: any;
    revitViewName: string;
    onError: any;
  };

export class SavedSelections extends Component<Props, State> {
  state = {};

  convertDate(d: any) {
    let sp = d.split("/");
    let ret = [sp[1], sp[0], sp[2]].join("/");
    return ret;
  }

  render() {
    const {
      selections,
      setRoute,
      module,
      selectionPar,
      setSelectionId,
      moduleData,
      crudSelectionId,
      language,
      revitViewName,
      onError,
    } = this.props;
    let selectionValue: Selection<PlacoOptions>[] | undefined = [];

    if (selectionPar) {
      const moduleParZone = moduleData.moduleParZone;
      selectionValue = _.filter(selections, function (o) {
        return o.Zone === moduleParZone;
      });
    } else {
      selectionValue = _.filter(selections, function (o) {
        return o.Zone === true || o.Zone === false;
      });
    }

    //Sorting Zone/Selection
    let selectionData = _.values(selectionValue);

    let select: any = selectionData.filter((a: any) => a.Zone !== true);
    let selectZone: any = selectionData.filter((a: any) => a.Zone === true);

    //Sorting Zone/Selection by name
    // select.sort((a: { Name: string; }, b: { Name: string; }) => a.Name.localeCompare(b.Name));
    // selectZone.sort((a: { Name: string; }, b: { Name: string; }) => a.Name.localeCompare(b.Name));

    //Sorting Zone/Selection by Date (Use date convert function and then comparing between two dates)
    select.sort((a: any, b: any) => {
      return (
        +new Date(this.convertDate(b.Date)) -
        +new Date(this.convertDate(a.Date))
      );
    });
    selectZone.sort((a: any, b: any) => {
      return (
        +new Date(this.convertDate(b.Date)) -
        +new Date(this.convertDate(a.Date))
      );
    });

    let sortedSelection: any[] = [...select, ...selectZone];

    const filteredSelection = filter(sortedSelection, function (selection) {
      return selection.RevitView === revitViewName;
    });

    return (
      <div>
        {filteredSelection.map((selection, index) => {
          return (
            <SavedSelection
              key={`selection-value-${index}`}
              setRoute={setRoute}
              selection={selection}
              module={module}
              selectionPar={selectionPar}
              setSelectionId={setSelectionId}
              selections={sortedSelection}
              crudSelectionId={crudSelectionId}
              language={language}
              onError={onError}
            />
          );
        })}
      </div>
    );
  }
}

export default connect(mapState, mapDispatch)(SavedSelections);
