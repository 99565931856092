export type Language = "French" | "English";
export type Zone = true | false | "All";

export const wording: WordingType = {
  name: {
    French: "Sélection",
    English: "Selection",
  },
  saveSelectionList: {
    French: "Liste des sélection de systèmes sauvegardées",
    English: "Saved selections list",
  },
  selectionName: {
    French: "Nom de la sélection",
    English: "Selection name",
  },
  editDate: {
    French: "Date de modification",
    English: "Edit date",
  },
  noSelectionSaved: {
    French: "Aucune sélection sauvegardée",
    English: "No selection saved",
  },
  newManualSelection: {
    French: "Nouvelle sélection (manuelle)",
    English: "New manual selection",
  },
  newGroupSelection: {
    French: "Nouvelle sélection (groupée)",
    English: "New group selection",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
  invalidSelectionName: {
    French: "Nom de sélection invalide",
    English: "Invalid selection name",
  },
  levels: {
    French: "Niveaux",
    English: "Levels",
  },
  zone: {
    French: "Zone",
    English: "Zone",
  },
  newSelectionBtn: {
    French: "Nouvelle Sélection",
    English: "New Selection",
  },
  newZoneBtn: {
    French: "Nouvelle Zone",
    English: "New Zone",
  },
  type: {
    French: "Type",
    English: "Type",
  },
  cancel: {
    French: "Annuler",
    English: "Cancel",
  },
};

export type WordingType = {
  name: LanguageType;
  zone: LanguageType;
  levels: LanguageType;
  saveSelectionList: LanguageType;
  selectionName: LanguageType;
  editDate: LanguageType;
  newManualSelection: LanguageType;
  newGroupSelection: LanguageType;
  back: LanguageType;
  validate: LanguageType;
  invalidSelectionName: LanguageType;
  noSelectionSaved: LanguageType;
  newSelectionBtn: any;
  newZoneBtn: any;
  type: any;
  cancel: any;
};

export type LanguageType = {
  French: string;
  English: string;
};
