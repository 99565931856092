import { SET_RESET_CRITERIA, CriteriaActionType, CriteriaType, SET_SELECTED_SOURCE, SET_SELECTED_MAPPING,
    SET_SELECTED_FORMAT, 
    SET_SELECTED_VERSION,
    SET_SELECTED_ENVIRONMENT,
    SET_SELECTED_BUSINESS,
    SET_SELECTED_REGION,
    SET_SELECTED_LANGUAGE,
    SET_SELECTED_GENERIC,
    SET_SELECTED_BUSINESS_CONTAINERID,
    SET_SELECTED_BUSINESS_TECH_NAME,
    SET_SELECTED_TYPE,
    SET_SELECTED_REGIONName,
    SET_IS_CRITERIA_VALID} from "../Types";


const intialState: CriteriaType = {
    selectedSource: "",
    selectedMapping: "",
    selectedBusiness: "",
    selectedBusinessContainerId: 0,
    selectedBusinessTechnicalName: "",
    selectedRegion: "",
    selectedEnvironment: "",
    selectedLanguage: "",
    selectedFormat: "",
    selectedVersion: "",
    selectedEntity: "",
    selectedGeneric: "",
    selectedType: "",
    selectedRegionName: "",
    isValidCriteria: {}
};


const criteriaReducer = (state: CriteriaType = intialState, action: CriteriaActionType): CriteriaType => {
    const newState = { ...state };
    switch (action.type) {
        case SET_RESET_CRITERIA:
            return {
                ...state,
                selectedSource: "",
                selectedMapping: "",
                selectedBusiness: "",
                selectedBusinessContainerId: 0,
                selectedBusinessTechnicalName: "",
                selectedRegion: "",
                selectedEnvironment: "",
                selectedLanguage: "",
                selectedFormat: "",
                selectedVersion: "",
                selectedEntity: "",
                selectedGeneric: "",
                selectedType: "",
                selectedRegionName:"",
                isValidCriteria: {}
              };
        case SET_SELECTED_SOURCE:
            return {
                ...state,
                selectedSource: action.selectedSource,
            };
        case SET_SELECTED_FORMAT:
            return {
                ...state,
                selectedFormat: action.selectedFormat,
            };
        case SET_SELECTED_VERSION:
            return {
                ...state,
                selectedVersion: action.selectedVersion,
            };
        case SET_SELECTED_MAPPING:
            return {
                ...state,
                selectedMapping: action.selectedMapping,
            };
        case SET_SELECTED_ENVIRONMENT:
            return {
                ...state,
                selectedEnvironment: action.selectedEnvironment,
            };
        case SET_SELECTED_BUSINESS:
            return {
                ...state,
                selectedBusiness: action.selectedBusiness
            };
        case SET_SELECTED_REGION:
            return {
                ...state,
                selectedRegion: action.selectedRegion
            };
            case SET_SELECTED_REGIONName:
                return {
                    ...state,
                    selectedRegionName: action.selectedRegionName
                };    
        case SET_SELECTED_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.selectedLanguage
            };
        case SET_SELECTED_GENERIC:
            return {
                ...state,
                selectedGeneric: action.selectedGeneric
            };
        case SET_SELECTED_BUSINESS_CONTAINERID:
            return {
                ...state,
                selectedBusinessContainerId: action.selectedBusinessContainerId
            };
        case SET_SELECTED_BUSINESS_TECH_NAME:
            return {
                ...state,
                selectedBusinessTechnicalName: action.selectedBusinessTechnicalName
            };
        case SET_SELECTED_TYPE:
            return {
                ...state,
                selectedType: action.selectedType
            };
        case SET_IS_CRITERIA_VALID:
            return {
                ...state,
                isValidCriteria: action.isValidCriteria
            };
        default:
            return state;
    }
    return newState;
}

export default criteriaReducer;