import React, { Component } from "react";
import { Button, Input } from "semantic-ui-react";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { api } from "../../../../RevitJS/API";
class Waiter extends Component {
  state = {
    distance: 400,
  };

  componentDidMount = async () => {
    // api.windowHandler.resizeWindow(100, 100);
    // window.revit.addCotesMM(1000);
  };

  async sendEvent() {
    await api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Cotation",
      module: "RIGIPSBIM",
    });
  }

  render = () => {
    const { t } = this.props;
    return (
      <div>
        <FunctionalityHeader name={t("dimensions")} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 20 }}>
            {"Abstand der Masslinien"} (mm):{" "}
            <Input
              style={{ margin: 10 }}
              value={this.state.distance}
              onChange={(e, d) =>
                this.setState({ distance: parseFloat(d.value) })
              }
            />
            <Button
              primary
              onClick={async () => {
                await window.revit.addCotesMM(this.state.distance);
                await this.sendEvent();
                // await wait(1000);
                // api.windowHandler.closeWindow();
              }}
            >
              {"Bestätigen"}
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

// Waiter.defaultProps={
//   wording:{
//     validate:{
//       "French": "Valider",
//       "English": "Validate",
//       "German": "Bestätigen",
//       "Italian": "Convalidare"
//     },
//     dimensions:{
//       "French": "Cotation",
//       "English": "Dimensions",
//       "German": "Abmessungen",
//       "Italian": "Dimensioni"
//     },
//     dimensionsDistance:{
//       "French": "Distance des cotes",
//       "English": "Dimension distances",
//       "German": "Maßabstände",
//       "Italian": "Distanze dimensionali"
//     }
//   }
// }

const mapStateToProps = (state) => {
  return {
    // language: state.language
  };
};

export default connect(mapStateToProps)(withTranslation("riggibs")(Waiter));
