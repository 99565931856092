import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Grid, Segment, Button, Dimmer, Loader } from "semantic-ui-react";
import { LevelsColumn } from "../../../../Components/Selector/LevelsColumn";
import { TypesColumn } from "../../../../Components/Selector/TypesColumn";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
  selectLevel,
  selectType,
  selectAllTypes,
  selectAllLevels,
} from "../Actions/index";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { selectedItems } from "../Helpers";

const eraseSelectionHandler = async (props: Props) => {
  const tree = await api.selection.elementsByLevelAndType("ceiling");
  let selectedTypes: any[] = [];
  props.typesData.forEach((e) => {
    if (e.Checked) selectedTypes.push(e.Type);
  });
  const selectedElems = [].concat.apply(
    [],
    selectedItems(tree, props.selectedLevels, selectedTypes).map(
      (e: any) => e.Ids
    )
  );
  if (selectedElems.length) {
    const nb_walls = selectedElems.length;
    for (let i = 0; i < nb_walls; i++) {
      await api.familyEditor.setParams([
        {
          Id: selectedElems[i],
          Params: [
            { Name: "Processed", Type: "YesNo", Value: 0 },
            {
              Name: "ProductName",
              Type: "Text",
              Value: "",
            },
            {
              Name: "MaxLength",
              Type: "Text",
              Value: "",
            },
            {
              Name: "MaxWidth",
              Type: "Text",
              Value: "",
            },
            {
              Name: "RoomNames",
              Type: "Text",
              Value: " ",
            },
          ],
        },
      ]);
      const elems = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: {
              Name: "id",
              Type: "Integer",
              Value: parseInt(selectedElems[i]),
            },
            Rule: "Equals",
          },
        ],
        null
      );
      await api.familyEditor.deleteElementsByIds(elems);
    }
    api.windowsHandler.closeWindow();
  }
};

interface Props {
  selectorType: string;
  Icon: string;
  language: string;
  wording: {
    wallTypes: { [key: string]: string };
    ceilingTypes: { [key: string]: string };
    selection: { [key: string]: string };
    levels: { [key: string]: string };
    back: { [key: string]: string };
    validate: { [key: string]: string };
    deletePending: { [key: string]: string };
  };
  levelsData: LevelData[];
  typesData: TypeData[];
  routes: any;
  selectedLevels: string[];
  selectedTypes: string[];
  selectLevel: any;
  selectType: any;
  selectAllTypes: Function;
  selectAllLevels: Function;
  setRoute: any;
  config:any
}

interface State {
  erasing: boolean;
}

export class GroupSelector extends React.Component<Props, State> {
  componentDidMount = () => {
    api.windowsHandler.resizeWindow(800, 500);
  };

  state = {
    erasing: false,
  };

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
        German: "Ebenen",
        Italian: "Livelli",
      },
      ceilingTypes: {
        French: "Types de plafonds",
        English: "Ceiling types",
        German: "Deckentypen",
        Italian: "Tipi di soffito",
      },
      wallTypes: {
        French: "Types de murs",
        English: "Wall types",
        German: "Wandtypen",
        Italian: "Tipi di muro",
      },
      selection: {
        French: "Sélection",
        English: "Selection",
        German: "Auswahl",
        Italian: "Selezione",
      },
      back: {
        French: "Retour",
        English: "Back",
        German: "Zurück",
        Italian: "Indietro",
      },
      validate: {
        French: "Valider",
        English: "Validate",
        German: "Bestätigen",
        Italian: "Convalidare",
      },
      deletePending: {
        French: "Suppression en cours...",
        English: "Deleting...",
        German: "Löschen...",
        Italian: "La cancellazione in corso...",
      },
    },
  };

  selectorTypes = () => {
    const { wording, language } = this.props;
    const wallTypes =
      wording.wallTypes[language] || wording.wallTypes["English"];
    const ceilingTypes =
      wording.ceilingTypes[language] || wording.ceilingTypes["English"];
    switch (this.props.selectorType) {
      case "wall":
        return wallTypes;
      case "ceiling":
        return ceilingTypes;
      default:
        return wallTypes;
    }
  };

  backHandler = () => {
    this.props.setRoute(Routes.ROOT);
  };

  validateHandler = () => {
    if (this.props.selectedTypes.length) {
      this.props.setRoute(Routes.MAPPING);
    }
  };

  render() {
    const { wording, language } = this.props;
    if (this.state.erasing) {
      const deletePending =
        wording.deletePending[language] || wording.deletePending["English"];
      return (
        <Dimmer active={true} style={{height: "calc(100vh - 30px)"}}>
          <Loader content={deletePending}></Loader>
        </Dimmer>
      );
    }

    const levels = wording.levels[language] || wording.levels["English"];
    const back = wording.back[language] || wording.back["English"];
    const validate = wording.validate[language] || wording.back["English"];
    
    return (
      <div id="delete-groupSelector-container">
        <FunctionalityHeader
          Icon={this.props.config.REACT_APP_SERVERURL + "/plugin_new_logo/ecophon-new-logo_icon.png"}
          width={40}
          color="grey"
          height={40}
          name={this.props.wording.selection[this.props.language].toUpperCase()}
        />
        <div style={{ padding: 5, overflow: "auto", marginBottom: "15px" }}>
          <div style={{ width: "100%", backgroundColor: "none" }}>
            <div style={{ margin: "auto" }}>
              <Grid
                columns={2}
                stackable
                style={{
                  backgroundColor: "none",
                  height: 340,
                  overflow: "auto",
                }}
                container
                centered
              >
                <LevelsColumn
                  selectLevel={this.props.selectLevel}
                  selectAllLevels={this.props.selectAllLevels}
                  columnTitle={levels}
                  levelsData={this.props.levelsData}
                  color="yellow"
                />
                <TypesColumn
                  selectType={this.props.selectType}
                  selectAllTypes={this.props.selectAllTypes}
                  selectedLevels={this.props.selectedLevels}
                  columnTitle={this.selectorTypes()}
                  color="yellow"
                  typesData={this.props.typesData}
                />
              </Grid>
            </div>
            <Segment basic style={{ marginBottom: 40 }}>
              <Button onClick={this.backHandler} floated="left" color="yellow">
                {back}
              </Button>
              <Button
                onClick={(e) => {
                  this.setState({ erasing: true });
                  eraseSelectionHandler(this.props);
                }}
                disabled={this.props.selectedTypes.length === 0}
                floated="right"
                color="yellow"
              >
                {validate}
              </Button>
            </Segment>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    levelsData: state.levelsData,
    typesData: state.typesData,
    selectedLevels: state.selectedLevels,
    selectedTypes: state.selectedTypes,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    selectorType: state.selectorType,
    config: state.config
  };
};

export default connect(mapStateToProps, {
  selectType,
  selectLevel,
  selectAllTypes,
  selectAllLevels,
})(GroupSelector);
