import React from "react";
import { Icon, Image, Button, Dropdown } from "semantic-ui-react";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { Language } from "../../RevitJS/Types/RevitTypes";
import { releaseNoteTrigger } from "../../Plugins/General/ReleaseNote";
import { MyConfig } from "../../Helper";
import { reengineeringTrigger } from "../re-engineering/common/re_engineering_index";

interface PluginHeaderProps {
  plugin: Plugin | undefined;
  Name: string;
  resetSelectedPlugin: () => Action;
  language: Language;
  wording: any;
  config: any;
}

export const PluginHeader = (props: PluginHeaderProps) => {
  const dispatch = useDispatch();

  const onReleaseNoteClick = async () => {
    releaseNoteTrigger(props.config);
  };

  const exportModel = async () => {
    const config = await MyConfig();
    await reengineeringTrigger(config, 'EXPORT', props.plugin?.Name?.trim());
  };

  const importModel = async () => {
    const config = await MyConfig();
    reengineeringTrigger(config, 'IMPORT', props.plugin?.Name?.trim());
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          background: "#e8e8e8",
          display: "flex",
          alignItems: "center",
          margin: "-10px",
          padding: "0 10px",
          height: "50px",
        }}
      >
        <Icon
          style={{ cursor: "pointer", color: "#005EB8" }}
          onClick={() => dispatch(props.resetSelectedPlugin())}
          name="angle left"
        />
        <Image
          style={{ width: "auto", height: "2em", mixBlendMode: "darken" }}
          // style={this.props.plugin?.Name?.toLowerCase().replace(/\s/g, '') === "placo®bim" || this.props.plugin?.Name === "PrescriBIM" ? {width: 40, height: 30, mixBlendMode: "darken"} : { width: "auto", height: "2em", mixBlendMode: "darken" }}
          src={props.plugin ? props.plugin.Icon : "/saint-gobain.jpg"}
        />
        <div
          style={{ fontSize: 18, color: "rgb(0, 94, 184)", marginLeft: 6 }}
          className={
            props.plugin?.Name === "Model Generator" ? "text-black" : ""
          }
        >
          {props.plugin ? props.plugin.Name : props.Name}
        </div>
        <div style={{ marginLeft: "auto", display: "flex", justifyContent:"flex-end", minWidth:"120px" }}>
          {(props.plugin?.Name?.trim() === "Placo® BIM" || props.plugin?.Name?.trim() === "Gyproc® Layout Tool") &&

            <Dropdown
              icon='cog icon'
              floating
              labeled
              button
              className='icon import-export-icon'
            >
              <Dropdown.Menu>
                <Dropdown.Item text="Export" icon="upload" onClick={exportModel}></Dropdown.Item>
                <Dropdown.Item text="Import" icon="download" onClick={importModel} ></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }

          <Button
            onClick={() => {
              onReleaseNoteClick();
            }}
            style={{
              padding: "7px 10px",
              background: "#d6d6d6 none",
              marginRight: 0,
              fontWeight: "bold",
              color: "black",
              fontSize: "small",
            }}
          >
            {props.wording.releaseNote[props.language]}
          </Button>
        </div>

      </div>
    </div>
  );
};
PluginHeader.defaultProps = {
  Name: "Plugin Name",
  Icon: "/saint-gobain.jpg",
  wording: {
    releaseNote: {
      French: "Notes de Version",
      English: "Release Notes",
      German: "Notes de Version",
      Italian: "Notes de Version",
      Indonesian: "Notes de Version",
    },
  },
};
