import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import {
  writeToCellAndStyle,
  addDetailsArrayHeader,
  addCeilingsDetailsArrayHeader,
  addGlobalLevelHeader,
} from "../Helpers";
import Resizer from "react-image-file-resizer";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { isEmpty } from "lodash";

export const getExcel = async (config: any) => {
  return await fetch(
    config.REACT_APP_SERVERURL + "/Excel/QuickMetresv3.xlsx"
  ).then((response) => {
    return response.arrayBuffer();
  });
};

export async function getDocumentName() {
  return await api.queries.getActiveDocumentName();
}

export const scheduleHandler = async (
  filteredTree: any,
  setProcessing: any,
  config: any
) => {
  const workbook = new Excel.Workbook();
  let blob = await getExcel(config);
  workbook.xlsx.load(blob).then(async (wkbk) => {
    await writeWorkbook(wkbk, filteredTree, config);
    wkbk.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: "visible", // Set activeTab to 0
      },
    ];
    const buf = await wkbk.xlsx.writeBuffer();

    const documentName = await getDocumentName();
    const d = new Date();
    let monthNumber = d.getMonth() + 1;
    let month =
      monthNumber.toString().length === 1 ? "0" + monthNumber : monthNumber;
    let dayNumber = d.getDate();
    let day = dayNumber.toString().length === 1 ? "0" + dayNumber : dayNumber;
    const fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Métrés`;
    setProcessing();
    //window.addEventListener("blur", () => api.windowsHandler.closeWindow());
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  });
};

const writeWorkbook = async (
  workbook: Excel.Workbook,
  filteredTree: any,
  config: any
) => {
  await writeData(workbook, filteredTree, config);
};

export const writeData = async (
  workbook: Excel.Workbook,
  filteredTree: any,
  config: any
) => {
  const logoImageId = await addImagetoWorkbook(workbook, config);
  const wallsDataSheet = getWorksheet(workbook, "Métrés_Cloisons");
  const ceilingsDataSheet = getWorksheet(workbook, "Métrés_Plafonds");

  // add logo to Walls sheet
  logoImageId !== 0 &&
    wallsDataSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });

  // add logo to ceilings sheet
  logoImageId !== 0 &&
    ceilingsDataSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });

  let { wallsDataCloned, summaryRow, parLevelRows } = await extractData(
    filteredTree.walls
  );
  let {
    //   mainRows: mainCeilingsRows,
    ceilingDataCloned,
    summaryRow: summaryCeilingsRow,
    parLevelCeilingRows,
  } = await extractCeilingsData(filteredTree.ceilings);
  let tempProjectInfo: any = await bimStorage.getItem(storageKey.PROJECT_INFO);
  const projectInfo = tempProjectInfo === null ? {} : tempProjectInfo;
  let ProjectData: any = await api.queries.getProjectData();
  const projectName = await getDocumentName();
  const projData: any = await api.queries.getProjectInformation();

  if (projectInfo?.PROJECT_INFO_FORM) {
    const address = `${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_address}, ${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_postal} ${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_villa} - ${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_pays}`;
    const enterpriseName =
      projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_name;
    wallsDataSheet.getCell("F8").value = enterpriseName;
    wallsDataSheet.getCell("F9").value = address;

    ceilingsDataSheet.getCell("F8").value = enterpriseName;
    ceilingsDataSheet.getCell("F9").value = address;

    const chantierInfo = projectInfo.PROJECT_INFO_FORM.modelInfo.find(
      (e: any) => e.model_name === ProjectData.ProjectId
    );

    wallsDataSheet.getCell(`B10`).value = `${
      chantierInfo?.information_chantier_reference ?? ""
    }`;
    ceilingsDataSheet.getCell(`B10`).value = `${
      chantierInfo?.information_chantier_reference ?? ""
    }`;
  }

  if (projData) {
    wallsDataSheet.getCell(`B8`).value = projData.Name.replace(/&apos;/g, "'");
    ceilingsDataSheet.getCell(`B8`).value = projData.Name.replace(
      /&apos;/g,
      "'"
    );
  }

  if (projectName) {
    wallsDataSheet.getCell("F13").value = projectName;
    ceilingsDataSheet.getCell("F13").value = projectName;
  }

  let startingPointForFirstTable = 17;

  for (let i = 0; i < summaryCeilingsRow.length; i++) {
    //ceilingsDataSheet.insertRow(startingPointForFirstTable + i, {}, "o+");

    // ceilingsDataSheet.mergeCells(
    //   `B${startingPointForFirstTable + i}:C${startingPointForFirstTable + i}`
    // );

    let tmpCeiling = ceilingsDataSheet.getCell(
      `B${startingPointForFirstTable + i}`
    );
    tmpCeiling.alignment = {
      ...tmpCeiling.alignment,
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };

    writeToCellAndStyle(
      ceilingsDataSheet,
      `B${startingPointForFirstTable + i}`,
      summaryCeilingsRow[i][1],
      true,
      false
    );
    writeToCellAndStyle(
      ceilingsDataSheet,
      `C${startingPointForFirstTable + i}`,
      summaryCeilingsRow[i][2],
      true,
      true
    );

    // writeToCellAndStyle(
    //   ceilingsDataSheet,
    //   `D${startingPointForFirstTable + i}`,
    //   summaryCeilingsRow[i][2],
    //   true,
    //   true
    // );

    ceilingsDataSheet.getRow(startingPointForFirstTable + i).font = {
      bold: false,
    };
  }

  for (let i = 0; i < summaryRow.length; i++) {
    // wallsDataSheet.insertRow(startingPointForFirstTable + i, {}, "o+");

    // wallsDataSheet.mergeCells(
    //   `B${startingPointForFirstTable + i}:C${startingPointForFirstTable + i}`
    // );

    let tmp = wallsDataSheet.getCell(`B${startingPointForFirstTable + i}`);
    tmp.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };

    writeToCellAndStyle(
      wallsDataSheet,
      `B${startingPointForFirstTable + i}`,
      summaryRow[i][1],
      true,
      false
    );

    writeToCellAndStyle(
      wallsDataSheet,
      `C${startingPointForFirstTable + i}`,
      summaryRow[i][2],
      true,
      true
    );

    wallsDataSheet.getRow(startingPointForFirstTable + i).font = {
      bold: false,
    };
  }

  let GlobalParLevelStartingPoint =
    startingPointForFirstTable + summaryRow.length + 3;

  let CeilingsGlobalParLevelStartingPoint =
    startingPointForFirstTable + summaryCeilingsRow.length + 3;

  let startingPointForSecondTable =
    GlobalParLevelStartingPoint + parLevelRows.length + 3;

  let startingPointForSecondCeilingTable =
    CeilingsGlobalParLevelStartingPoint + parLevelCeilingRows.length + 3;

  for (let i = 0; i < wallsDataCloned.length; i++) {
    writeToCellAndStyle(
      wallsDataSheet,
      `A${startingPointForSecondTable + i}`,
      wallsDataCloned[i][0],
      true,
      false
    );

    // wallsDataSheet.mergeCells(
    //   `B${startingPointForSecondTable + i}`
    // );

    let tmp = wallsDataSheet.getCell(`B${startingPointForSecondTable + i}`);
    tmp.alignment = {
      ...tmp.alignment,
      horizontal: "center",
      vertical: "middle",
    };

    writeToCellAndStyle(
      wallsDataSheet,
      `B${startingPointForSecondTable + i}`,
      wallsDataCloned[i][3],
      true,
      false
    );
    writeToCellAndStyle(
      wallsDataSheet,
      `C${startingPointForSecondTable + i}`,
      wallsDataCloned[i][4],
      true,
      true
    );

    writeToCellAndStyle(
      wallsDataSheet,
      `D${startingPointForSecondTable + i}`,
      wallsDataCloned[i][5],
      true,
      false
    );
    writeToCellAndStyle(
      wallsDataSheet,
      `E${startingPointForSecondTable + i}`,
      wallsDataCloned[i][6],
      true,
      true
    );
    writeToCellAndStyle(
      wallsDataSheet,
      `F${startingPointForSecondTable + i}`,
      wallsDataCloned[i][7],
      true,
      true
    );
    // writeToCellAndStyle(
    //   wallsDataSheet,
    //   `G${startingPointForSecondTable + i}`,
    //   mainRows[i][5],
    //   true,
    //   true
    // );
    wallsDataSheet.getRow(startingPointForSecondTable + i).font = {
      bold: false,
    };
  }

  // add ceilings row
  for (let i = 0; i < ceilingDataCloned.length; i++) {
    writeToCellAndStyle(
      ceilingsDataSheet,
      `A${startingPointForSecondCeilingTable + i}`,
      ceilingDataCloned[i][0],
      true,
      false
    );
    // ceilingsDataSheet.mergeCells(
    //   `B${startingPointForSecondCeilingTable + i}:C${startingPointForSecondCeilingTable + i}`
    // );

    let tmp = ceilingsDataSheet.getCell(
      `B${startingPointForSecondCeilingTable + i}`
    );
    tmp.alignment = {
      ...tmp.alignment,
      horizontal: "center",
      vertical: "middle",
    };

    writeToCellAndStyle(
      ceilingsDataSheet,
      `B${startingPointForSecondCeilingTable + i}`,
      ceilingDataCloned[i][3],
      true,
      false
    );

    writeToCellAndStyle(
      ceilingsDataSheet,
      `C${startingPointForSecondCeilingTable + i}`,
      ceilingDataCloned[i][4],
      true,
      true
    );
    writeToCellAndStyle(
      ceilingsDataSheet,
      `D${startingPointForSecondCeilingTable + i}`,
      ceilingDataCloned[i][5],
      true,
      false
    );
    writeToCellAndStyle(
      ceilingsDataSheet,
      `E${startingPointForSecondCeilingTable + i}`,
      ceilingDataCloned[i][6],
      true,
      true
    );
    writeToCellAndStyle(
      ceilingsDataSheet,
      `F${startingPointForSecondCeilingTable + i}`,
      ceilingDataCloned[i][7],
      true,
      true
    );
    ceilingsDataSheet.getRow(startingPointForSecondCeilingTable + i).font = {
      bold: false,
    };
  }

  addDetailsArrayHeader(wallsDataSheet, startingPointForSecondTable - 2);
  addCeilingsDetailsArrayHeader(
    ceilingsDataSheet,
    startingPointForSecondCeilingTable - 2
  );

  //Write heading of GLOBAL Par Level Table
  addGlobalLevelHeader(wallsDataSheet, GlobalParLevelStartingPoint - 2);
  addGlobalLevelHeader(
    ceilingsDataSheet,
    CeilingsGlobalParLevelStartingPoint - 2
  );

  //Write data into Global Par Level Table
  writeGlobalParLevel(
    wallsDataSheet,
    parLevelRows,
    GlobalParLevelStartingPoint
  );
  writeGlobalParLevel(
    ceilingsDataSheet,
    parLevelCeilingRows,
    CeilingsGlobalParLevelStartingPoint
  );
};

export const addImagetoWorkbook = async (
  excelBook: Excel.Workbook,
  config: any
) => {
  let tempProjectInfo: any = await bimStorage.getItem(storageKey.PROJECT_INFO);
  var data = tempProjectInfo === null ? {} : tempProjectInfo;
  if (!isEmpty(data)) {
    let logo = data.PROJECT_INFO_FORM.companyInfo.information_enterprise_logo;
    if (!logo.includes("/html", 0)) {
      const resizedImg = await resizeFile(b64toBlob(logo));
      const imageId = excelBook.addImage({
        base64: resizedImg ? resizedImg : logo,
        extension: "png",
      });
      return imageId;
    }
    return 0;
  } else {
    return 0;
  }
};

const b64toBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      110,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const getWorksheet = (workbook: Excel.Workbook, name: string) => {
  return workbook.getWorksheet(name);
};

const writeGlobalParLevel = async (
  dataSheet: Excel.Worksheet,
  OuvrageRows: any,
  startingRow: number
) => {
  for (let i = 0; i < OuvrageRows.length; i++) {
    writeToCellAndStyle(
      dataSheet,
      `A${startingRow + i}`,
      OuvrageRows[i][0],
      false,
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `B${startingRow + i}`,
      OuvrageRows[i][1],
      false,
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `C${startingRow + i}`,
      OuvrageRows[i][2],
      false,
      true
    );
    dataSheet.getRow(startingRow + i).font = {
      bold: false,
    };
    dataSheet.getRow(startingRow + i).getCell(startingRow).alignment = {
      wrapText: true,
    };
  }
};

export const extractData = async (tree: any) => {
  let nbLevels = tree.length;
  let detailRows: any = [];
  let levelRows: any = {};
  let wallSolArr: any = [];
  let wallsDataCloned: any = [];
  let mainArr: any = [];

  for (let i = 0; i < nbLevels; i++) {
    let elems = tree[i].elems;
    let nb_types = elems.length;
    let level = tree[i].level;

    for (let j = 0; j < nb_types; j++) {
      let ids = elems[j].ids;
      let type = elems[j].type;
      if (j === 0) levelRows[level] = {};
      if (ids.length > 0) {
        let wallsData = await api.queries.getWallsData(ids);

        const solutionData = await api.queries.getObjectsParams(ids, [
          "SG_System",
        ]);
        let wallSolutions = getWallsWithSolutionsData(wallsData, solutionData);

        let rows = await detailMainBuilder(wallSolutions, type, tree[i].level);
        detailRows = detailRows.concat(rows);

        let arr = wallSolutions.filter((a: any) => a.solution !== " ");
        if (arr.length > 0) {
          wallSolArr = wallSolArr.concat(arr);
        }
      }
    }
  }
  //for sorting data level wise, arranged in [key:value] pair

  detailRows.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      solution: wall[2],
      type: wall[3],
      area: wall[4],
      id: wall[5],
      height: wall[6],
      length: wall[7],
    });
  });

  // //data sorted
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  // //removing [key:value] pair and pushed it to another array
  mainArr.forEach((wall: any) => {
    wallsDataCloned.push([
      wall.level,
      wall.zone,
      wall.solution,
      wall.type,
      wall.area,
      wall.id,
      wall.height,
      wall.length,
    ]);
  });

  let newRows: any = await detailMainBodyBuilder(detailRows);
  let parLevelRows: any = await detailNewSummaryBuilder(newRows);
  let mainRows: any = detailRows;
  let summaryRow: any = await detailSummaryBuilder(mainRows);
  return {
    //mainRows,
    wallsDataCloned,
    levelRows,
    summaryRow,
    parLevelRows,
  };
};
export const extractCeilingsData = async (tree: any) => {
  let nbLevels = tree.length;
  let detailRows: any = [];
  let levelRows: any = {};
  let wallSolArr: any = [];
  let ceilingDataCloned: any = [];
  let mainArr: any = [];

  for (let i = 0; i < nbLevels; i++) {
    let elems = tree[i].elems;
    let nb_types = elems.length;
    let level = tree[i].level;

    for (let j = 0; j < nb_types; j++) {
      let ids = elems[j].ids;
      let type = elems[j].type;
      if (j === 0) levelRows[level] = {};
      if (ids.length > 0) {
        let ceilingsData = await api.queries.getCeilingsData(ids);
        const solutionData = await api.queries.getObjectsParams(ids, [
          "SG_System",
        ]);
        let ceilingSolutions = getCeilingsWithSolutionsData(
          ceilingsData,
          solutionData
        );
        let rows = await detailCeilingsMainBuilder(
          ceilingSolutions,
          type,
          tree[i].level
        );
        detailRows = detailRows.concat(rows);

        let arr = ceilingSolutions.filter((a: any) => a.solution !== " ");
        if (arr.length > 0) {
          wallSolArr = wallSolArr.concat(arr);
        }
      }
    }
  }

  //for sorting data level wise, arranged in [key:value] pair
  detailRows.forEach((ceiling: any) => {
    mainArr.push({
      level: ceiling[0],
      zone: ceiling[1],
      ouvrage: ceiling[2],
      solution: ceiling[3],
      type: ceiling[4],
      area: ceiling[5],
      id: ceiling[6],
      perimeter: ceiling[7],
      height: ceiling[8],
    });
  });

  //data sorted
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  //removing [key:value] pair and pushed it to another array
  mainArr.forEach((ceiling: any) => {
    ceilingDataCloned.push([
      ceiling.level,
      ceiling.zone,
      ceiling.ouvrage,
      ceiling.solution,
      ceiling.type,
      ceiling.area,
      ceiling.id,
      ceiling.perimeter,
      ceiling.height,
    ]);
  });
  let newMainRows: any = await detailMainBodyBuilder(detailRows);
  let parLevelCeilingRows: any = await detailNewSummaryBuilder(newMainRows);
  let mainRows: any = detailRows;
  let summaryRow: any = await detailSummaryBuilder(detailRows);
  return {
    //mainRows,
    ceilingDataCloned,
    levelRows,
    summaryRow,
    parLevelCeilingRows,
  };
};

export const detailNewSummaryBuilder = async (wallsData: any) => {
  return wallsData.filter((e: any) => e[e.length - 1] === "NA");
};
// export const detailSummaryBuilder = (solutionRows: any) => {
//   let rows: any = [];
//   let dataArr = groupBy(solutionRows, "solution");
//   _.forEach(dataArr, function (dt, key) {
//     let area: number = 0;
//     for (let i = 0; i < dt.length; i++) {
//       area += dt[i].Area;
//     }
//     rows.push([" ", " ", key, " ", Math.round(area * 10) / 10]);
//   });
//   return rows;
// };

export const detailSummaryBuilder = async (wallsData: any) => {
  let rowsArr: any = [];
  let rows: any = [];
  let strList: string[] = [];
  let str: string = "";
  wallsData.forEach((wall: any) => {
    if (wall[3] !== str) {
      if (!strList.includes(wall[3])) {
        strList.push(wall[3]);
      }
      str = wall[3];
    }
    let area: number = (wall[4] * 10) / 10;
    rowsArr.push({ walltype: wall[3], wallarea: area.toFixed(2) });
  });
  let wallType: string = "";
  let wallArea: number = 0;

  strList.forEach((str: any) => {
    wallType = str;
    wallArea = 0;
    let arr = rowsArr.filter((a: any) => a.walltype === str);
    arr.forEach((item: any) => {
      wallArea += (item.wallarea * 10) / 10;
    });
    rows.push(["", wallType, wallArea]);
  });

  let sortedRows = sortBy(rows, [
    function (o) {
      return o[2];
    },
  ]).reverse();
  return sortedRows;
};

export const detailMainBuilder = async (
  wallsData: any,
  type: any,
  level: any
) => {
  let rows: any = [];
  wallsData.forEach((wall: any) => {
    let area: number = (wall.Area * 10) / 10;
    let height: number = (wall.Height as number) / 1000;
    let length: number = wall.Length / 1000;
    rows.push([
      level,
      "Zone 1",
      wall.solution,
      type,
      parseFloat(area.toFixed(2)),
      parseInt(wall.Id),
      parseFloat(height.toFixed(3)),
      parseFloat(length.toFixed(3)),
    ]);
  });
  return rows;
};
export const detailCeilingsMainBuilder = async (
  ceilingData: any,
  type: any,
  level: any
) => {
  const ceilingTree = await api.selection.elementsByLevelAndType("ceiling");

  const FindLevel = (ceilingID: string) => {
    const wall: any = Object.values(ceilingTree.Tree).find((e: any) => {
      return e.Elements.some((a: any) => {
        return a.Ids.includes(ceilingID);
      });
    });

    return wall.Level.Name;
  };
  let rows: any = [];
  let Ouvrage = ceilingData.Solution;
  ceilingData.forEach((ceiling: any) => {
    rows.push([
      FindLevel(ceiling.Id),
      Ouvrage,
      ceiling.solution,
      type,
      Math.round(ceiling.Area * 100) / 100,
      parseInt(ceiling.Id),
      ceiling.Perimeter.toFixed(2),
      ceiling.Height.toFixed(2),
    ]);
  });
  return rows;
};

export const detailMainBodyBuilder = async (wallsData: any) => {
  const distinct = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
  let mainArr: any = [];
  wallsData.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      solution: wall[2],
      type: wall[3],
      area: wall[4],
      id: wall[5],
      height: wall[6],
      length: wall[7],
    });
  });
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  let levelArr: any = [];
  levelArr = mainArr.map((a: any) => a.level).filter(distinct);
  let wallType: string = "";
  let wallArea: number = 0;
  let summary: any = [];
  levelArr.forEach((level: any) => {
    wallArea = 0;
    wallType = "";
    mainArr
      .filter((a: any) => a.level === level)
      .forEach((wall: any) => {
        wallArea = 0;
        if (wallType !== wall.type) {
          let arr = mainArr.filter(
            (a: any) => a.type === wall.type && a.level === level
          );
          arr.forEach((item: any) => {
            wallArea += (item.area * 10) / 10;
          });
          wallType = wall.type;
          summary.push({
            level: level,
            walltype: wallType,
            area: wallArea,
          });
        }
      });
  });
  let rows: any = [];
  let level: string = "";
  summary = orderBy(summary, ["level", "area"], ["asc", "desc"]);
  mainArr.forEach((data: any) => {
    if (data.level !== level) {
      summary
        .filter((a: any) => a.level === data.level)
        .forEach((row: any) => {
          rows.push([row.level, row.walltype, row.area, "NA", "NA", "NA"]);
        });
      level = data.level;
    }
    rows.push([
      data.level,
      data.type,
      data.area,
      data.id,
      data.height,
      data.length,
    ]);
  });
  return rows;
};

const getWallsWithSolutionsData = (wallsData: any, solutionData: any) => {
  wallsData.forEach((wall: any) => {
    let data = solutionData.find((a: any) => a.Id === wall.Id)?.Params[0];
    let solution = data === undefined ? null : data.Value;
    wall.solution = solution === null ? " " : getSolutionName(solution);
  });
  return wallsData;
};

const getCeilingsWithSolutionsData = (ceilingsData: any, solutionData: any) => {
  ceilingsData.forEach((ceiling: any) => {
    let data = solutionData.find((a: any) => a.Id === ceiling.Id)?.Params[0];
    let solution = data === undefined ? null : data.Value;
    ceiling.solution = solution === null ? " " : getSolutionName(solution);
  });
  return ceilingsData;
};

const getSolutionName = (solution: string) => {
  let solutionname: string = "";
  let solArr: string[] = solution.split("-");
  for (let i = 1; i < solArr.length; i++) {
    solutionname += solArr[i];
  }
  return solutionname;
};

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
