import React from "react";
import { Dropdown, Table } from "semantic-ui-react";
import "../Assets/main.scss";

export const SystemTypeList = () => {
  //const {wording:{revitSystemType, systemChoice, actions}, language, selectionGroup, setRoute} = this.props;
  const SelectionList = [
    {
      systemTypeName:
        "Mur de base:Complexes de doublage thermique Doublissimo® -13+100 (2)",
      systemName: "Autre système (non PLACO)",
    },
    {
      systemTypeName:
        "Mur de base:Contre cloisons 1 BA13 avec GR32 100 R=3.15 (1)",
      systemName: "Cloison Caroplatre® 5 Hydro - EI 60 - 31dB - 3,40m",
    },
    {
      systemTypeName:
        "Mur de base:Cloison Caroplatre® 7 - EI 120 - 35dB - 4,00m (15)",
      systemName: "Cloison Caroplatre® 10 - EI 180 - 35dB - 5,20m",
    },
    {
      systemTypeName:
        "Mur de base:Paroi C Stil® 160 - 1 x Placo® Duo'Tech® 25 - 3'-63 - EI 60 - 2,55m - MS (1)",
      systemName:
        "Cloison Placostil® 98/62 - 1x Placoplatre® BA 18S - EI60 - 47dB - 3,55m",
    },
  ];

  const systems = [
    {
      key: "1",
      text: "Autre système (non PLACO)",
      value: "Autre système (non PLACO)",
    },
    {
      key: "2",
      text: "Cloison Caroplatre® 5 Hydro - EI 60 - 31dB - 3,40m",
      value: "Cloison Caroplatre® 5 Hydro - EI 60 - 31dB - 3,40m",
    },
    {
      key: "3",
      text: "Cloison Caroplatre® 10 - EI 180 - 35dB - 5,20m",
      value: "Cloison Caroplatre® 10 - EI 180 - 35dB - 5,20m",
    },
  ];

  //const handleChange = (e:any, { value:any }) => this.setState({ value })
  const DisplayData = SelectionList.map((info) => {
    return (
      <Table.Row>
        <Table.Cell>{info.systemTypeName}</Table.Cell>
        <Table.Cell>
          <Dropdown
            className="ui selection dropdown advance-selector-poc-dropdown"
            placeholder=""
            name="system"
            options={systems}
            icon="chevron down"
            //value={value}
          />
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={6} textAlign="center">
            Revit System Type
            {/* {revitSystemType[language]} */}
          </Table.HeaderCell>
          <Table.HeaderCell width={6} textAlign="center">
            Systems
            {/* {systemChoice[language]} */}
          </Table.HeaderCell>
          <Table.HeaderCell widht={3} textAlign="center">
            Actions
            {/* {actions[language]} */}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{DisplayData}</Table.Body>
    </Table>
  );
};
