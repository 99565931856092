export enum APP_WINDOW_NAME {
  UPDATE_SYSTEM_WINDOW = "UPDATE_SYSTEM_WINDOW",
}

export const CLOISONS_ID = "wall";
export const PLAFONDS_ID = "ceiling";
export const DOUBLAGES_ID = "liningwall";
export const GAINES_ID = "gainestechniqueswall";

export const BOILERPLATE_HEADER_TEXT = "Boilerplate header text";
