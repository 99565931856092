import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { ConfirmModal } from "../PopUpModals/ConfirmModal";
import { Language } from "./Type";

interface Props {
  setRoute: any;
  routes: any;
  applicationLanguage: Language;
  popUpVisible: any;
  toggleConfirmModal: any;
  onYesAction: any;
  onNoAction: any;
  confirmModal: boolean;
  description:string;
}

export const EditSelectionButton = (props: Props) => {
  return (
    <Grid columns={1} stackable style={{ ...style.editSelectionSegment }}>
      <Grid.Column style={{ padingBottom: "0px" }}>
        <div
          style={{
            padding: 5,
            cursor: "pointer",
            minWidth: "0px",
            float: "left",
          }}
          // onClick={() => this.handleEditButton()}
          onClick={() => {
            props.popUpVisible()
              ? props.toggleConfirmModal()
              : props.setRoute(props.routes.SELECTION_HOME);
          }}
        >
          <Icon
            name="edit"
            color="blue"
            size="big"
            style={{ fontSize: "1.5em" }}
          />
          <span style={{ color: "rgb(14,110,184)" }}>
            {wording.editSelection[props.applicationLanguage]}
          </span>
        </div>
      </Grid.Column>
      <ConfirmModal
        confirmModal={props.confirmModal}
        no="NON"
        yes="OUI"
        description={props.description}
        header="Sauvegarde"
        toggleConfirmModal={() => props.toggleConfirmModal()}
        onYesAction={() => setTimeout(()=>{
          props.onYesAction()
        },500)}
        onNoAction={() => props.onNoAction()}
      />
    </Grid>
  );
};

const wording: WordingType = {
  editSelection: {
    French: "Créer / Modifier des sélections",
    English: "Create / Modify selections",
  },
};

type WordingType = {
  editSelection: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};
