import React, { useContext, useEffect, useState } from "react";
import AccordionContainer from "../../../Common/components/AccordionContainer";
import FunctionalityHeader from "../../../Common//components/FunctionalityHeader";
import DetailsProperty from "../../../Common/components/DetailsProperty";
import { Button, CircularProgress } from "@material-ui/core";
import { CLOISONS_ID, DOUBLAGES_ID, PLAFONDS_ID } from "../../assets/constants";
import { selectWindowToShow } from "../../store/app/effects";
import { Property } from "../../../Properties/store/properties/types";
import { connect, ConnectedProps } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";

import "./index.scss";
import { getConfigurationParameter } from "../../../../../Services/mapping-config-service";
import { IMappingConfigContextType, MappingConfigContext } from "../../../Common/context/mappingConfig.context";


export const SystemDetailsContainer = ({
  system,
  systemTypeID,
  selectWindowToShow,
  previousWindow,
  config,
  t,
}: SystemDetailsContainerProps) => {
  const [details, setDetails] = useState<Property[]>([]);
  
  const [familyTypeAttributes, setFamilyTypeAttributes] = useState<any>([]);
  const [systemName, setSystemName] = useState("");

  const { advanseSelectorData, mainCategoryData } = useContext(
    MappingConfigContext
  ) as IMappingConfigContextType;


  useEffect(() => {
    const att = system.attributes.find(
      (a: any) => a.technicalName === "GFR-Works name"
    );
    const type = att?.values[0]?.value;

    const attributesModal =
      type === "Cloisons"
        ? CLOISONS_ID
        : type === "Plafonds"
          ? PLAFONDS_ID
          : DOUBLAGES_ID;

    const loadFamilyTypeAttributes = async () => {
      if (config && Object.keys(config).length > 0) {

        let productName = system.attributes.find((p: any) => p.technicalName === advanseSelectorData?.productname?.attributeName);
        let productAttributeName = productName?.values[0]?.value
        setSystemName(productAttributeName);

        await getSystemAttributesWithValues(config, system, attributesModal, mainCategoryData);
      }
    }
    loadFamilyTypeAttributes();

  }, []);

  const getSystemAttributesWithValues = async (config: any, system: any, type: string, familyTypeData: any ) => {

    let familyTypes = familyTypeData.familyType;//await getSystemFamilyAttributes(config);
    let familyType = familyTypes.find((c: any) => c.categoryName.toLowerCase() === type.toLowerCase())
    let configParam = await getConfigurationParameter(familyType.bimObjectRule, config);
    let categoryData = familyType?.categoryData.filter((c: any) => c.chosen === true);

    if (categoryData && categoryData.length > 0) {
      let detailsData: any = [];
      let mainCategories = [...new Set(categoryData.map((c: any) => c.mainCategory))];

      mainCategories = mainCategories.filter((m: any) => m && m !== "");

      mainCategories.forEach((mainCategory: any) => {
        let categories = categoryData.filter((c: any) => c.mainCategory === mainCategory);

        categories.forEach((cat: any) => {
          let paramValue = getValue(cat, system, configParam);

          if (cat.subCategory && cat.subCategory !== "") {
            let subCategories = cat.subCategory.split('|');

            subCategories.forEach((subCategory: any) => {
              let existingChapter = detailsData.find((d: any) => d.chapterName === cat.mainCategory)
              if (existingChapter) {

                let existingChapterDifferentData = detailsData.find((d: any) => d.chapterName === cat.mainCategory && d.chapterData.some((c: any) => c.categoryName === subCategory.trim()));
                if (existingChapterDifferentData) {
                  let existingCategory = existingChapterDifferentData.chapterData.find((chapter: any) => chapter.categoryName === subCategory.trim())
                  if (existingCategory) {
                    existingCategory["pamData"].push({
                      aid: "",
                      key: "",
                      technicalName: cat.pimAttribute,
                      label: cat.label,
                      value: paramValue,
                    })
                  }
                }
                else {
                  existingChapter.chapterData.push({
                    categoryName: subCategory.trim(),
                    pamData: [{
                      aid: "",
                      key: "",
                      technicalName: cat.pimAttribute,
                      label: cat.label,
                      value: paramValue,
                    }]
                  })
                }
              }
              else {
                detailsData.push({
                  chapterName: cat.mainCategory,
                  chapterData: [{
                    categoryName: subCategory.trim(),
                    pamData: [{
                      aid: "",
                      key: "",
                      technicalName: cat.pimAttribute,
                      label: cat.label,
                      value: paramValue,
                    }]
                  }]
                })
              }
            });
          }
          else {
            let existingCategory = detailsData.find((d: any) => d.categoryName === cat.mainCategory);
            if (existingCategory) {
              existingCategory["pamData"].push({
                aid: "",
                key: "",
                technicalName: cat.pimAttribute,
                label: cat.label,
                value: paramValue,
              })
            }
            else {
              detailsData.push({
                categoryName: cat.mainCategory,
                pamData: [{
                  aid: "",
                  key: "",
                  technicalName: cat.pimAttribute,
                  label: cat.label,
                  value: paramValue,
                }]
              })
            }
          }
        });
      });
      setFamilyTypeAttributes(detailsData);
      console.log("detailsData :", detailsData);
    }
  }

  const getValue = (cat: any, system: any, configParam: any) => {
    let paramValue: any;
    if (cat.pimAttribute && cat.pimAttribute !== "") {
      paramValue = getValueFromPimAttribute(system, cat.pimAttribute);
    }
    else {
      let param = configParam.parameters.find((p: any) => p.labelName === cat.label)?.values;
      paramValue = param?.numericValue || param?.value;
      // console.log("param?.numericValue : {0}, param?.value : {1}", param?.numericValue, param?.value)
      //paramValue = param?.type === "INT_VALUE" ? param?.numericValue : param?.value;
    }
    return paramValue ? paramValue : "";
  }

  const getValueFromPimAttribute = (system: any, pimAttribute: string) => {
    let value = system.attributes.find((a: any) => a.technicalName === pimAttribute);
    return value?.values[0]?.numericValue ? value?.values[0]?.numericValue : value?.values[0]?.value;
  }

  const handleBackToLoadSystems = () => {
    selectWindowToShow(previousWindow);
  };
  return (
    <div
      className="PropertyGlobalContainer"
      style={{ overflow: "auto", paddingRight: 20, marginRight: 0 }}
    >
      <FunctionalityHeader
        name={systemName}//{system.translation}
        icon={config.REACT_APP_SERVERURL + "/PrescriBIM_Logo.png"}
      />
      <div className="DetailsContainer">
        {/* {systemAttributes && systemAttributes.length > 0 && (
          <div className="PropertiesContent">
            <div className="Properties">
              <div className="PropertyDetailsContent">
                {systemAttributes.map((p: any) => (
                  <AccordionContainer
                    key={t(p.chapterName) || t(p.categoryName)}
                    title={t(p.chapterName) || t(p.categoryName)}
                    properties={
                      p.chapterData &&
                      p.chapterData.map(
                        (prop: { categoryName: any; pamData: any[] }) => ({
                          title: t(prop.categoryName),
                          onClick: () =>
                            setDetails(
                              prop.pamData &&
                              prop.pamData.map((propr) => ({
                                title: t(propr.key),
                                value: propr.value,
                              }))
                            ),
                        })
                      )
                    }
                    onClick={() =>
                      p.pamData &&
                      setDetails(
                        p.pamData &&
                        p.pamData.map((prop: any) => ({
                          title: t(prop.key),
                          value: prop.value,
                        }))
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <DetailsProperty
              properties={
                details && details.length > 0
                  ? details
                  : systemAttributes[0].pamData.map((prop: any) => ({
                    title: t(prop.key),
                    value: prop.value,
                  }))
              }
            />
          </div>
        )} */}
        {familyTypeAttributes && familyTypeAttributes.length > 0 ? (
          <div className="PropertiesContent">
            <div className="Properties">
              <div className="PropertyDetailsContent">
                {familyTypeAttributes.map((p: any) => (
                  <AccordionContainer
                    key={p.chapterName || p.categoryName}
                    title={p.chapterName || p.categoryName}
                    properties={
                      p.chapterData &&
                      p.chapterData.map(
                        (prop: { categoryName: any; pamData: any[] }) => ({
                          title: prop.categoryName,
                          onClick: () =>
                            setDetails(
                              prop.pamData &&
                              prop.pamData.map((propr) => ({
                                title: propr.label,
                                value: propr.value,
                              }))
                            ),
                        })
                      )
                    }
                    onClick={() =>
                      p.pamData &&
                      setDetails(
                        p.pamData &&
                        p.pamData.map((prop: any) => ({
                          title: prop.label,
                          value: prop.value,
                        }))
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <DetailsProperty
              properties={
                details && details.length > 0
                  ? details
                  : familyTypeAttributes[0]?.pamData?.map((prop: any) => ({
                    title: prop.label,
                    value: prop.value,
                  }))
              }
            />
          </div>
        ) : (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "calc(100vh - 200px)" }}>
          <CircularProgress className="LoadSystemCircularProgress" />
        </div>)}
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton detailActionButton"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handleBackToLoadSystems()}
        >
          {t("RETURN")}
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    system: state.systemDetails.system,
    systemTypeID: state.systems.systemTypeID,
    previousWindow: state.app.previousWindow,
    config: state.config,
  };
}

const mapDispatchToProps = {
  selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SystemDetailsContainerProps = PropsFromRedux & WithTranslation;

export default connector(withTranslation(["library"])(SystemDetailsContainer));
