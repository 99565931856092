import { AnyAction } from "redux";
import { ProductDetailData } from "../Types";
import { multiSelectBufferAction, resetBufferAction, searchAction, applyFiltersAction, resetFiltersAction, selectSystemAction, tempMultiSelectBufferAction, resetTempBufferAction } from "../Actions/types";

type multiSelectionBufferAction = multiSelectBufferAction | resetBufferAction | searchAction | applyFiltersAction | resetFiltersAction | selectSystemAction;
type tempMultiSelectionBufferAction = tempMultiSelectBufferAction | resetTempBufferAction | searchAction | applyFiltersAction | resetFiltersAction | selectSystemAction;

export const multiSelectionBuffer = (multiSelectionBuffer: ProductDetailData[] | null = null, action: multiSelectionBufferAction) =>{
    switch(action.type){
        case "MULTI_SELECT_BUFFER":
            return action.systems;
        // case "RESET_BUFFER":
        //     return null;
        case "SELECT_SYSTEM":
            return null;
        case "SEARCH":
            return null;
        case "APPLY_FILTERS":
            return null;
        // case "RESET_FILTERS":
        //     return null;
        default:
            return multiSelectionBuffer;
    }
}

export const tempMultiSelectionBuffer = (tempMultiSelectionBuffer: ProductDetailData[] | null = null, action: tempMultiSelectionBufferAction) =>{
    switch(action.type){
        case "TEMP_MULTI_SELECT_BUFFER":
            return action.systems;
        case "RESET_TEMP_MULTI_SELECT_BUFFER":
            return null;
        case "SELECT_SYSTEM":
            return null;
        case "SEARCH":
            return null;
        case "APPLY_FILTERS":
            return null;
        // case "RESET_FILTERS":
        //     return null;
        default:
            return tempMultiSelectionBuffer;
    }
}