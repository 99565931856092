import React from "react";
import CalepinerTableHeader from "./CalepinerTableHeader";
import CalepinerTableRow from "./CalepinerTableRow";
import { map } from "lodash";

const style = {
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200)",
    height: "calc(100vh - 195px)",
    overflowY: "scroll" as const,
  },
};

interface Props {
  data: any;
  register: any;
  watch: any;
  onSelectAll: any;
  isAllSelected: boolean;
}

export const CalepinerTable = (props: Props) => {
  const { data, register, watch, onSelectAll, isAllSelected } = props;

  return (
    <>
      <CalepinerTableHeader
        register={register}
        onSelectAll={onSelectAll}
        isAllSelected={isAllSelected}
      />
      <div style={style.tableSegment_body}>
        {map(data, (dat: any, key: any) => (
          <CalepinerTableRow
            data={dat}
            register={register}
            rowNumber={key}
            watch={watch}
          />
        ))}
      </div>
    </>
  );
};

export default CalepinerTable;
