import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { fetchPlacoSolutionsDetails } from "../../../API";
import AccordionContainer from "../../../Plugins/PlacoBIMv3/CommonModules/AccordionContainer";
import DetailsProperty from "../../../Plugins/PlacoBIMv3/CommonModules/DetailsProperty";
import { setPAMAttributesValuesModal } from "../../../Plugins/PlacoBIMv3/CommonModules/utils/attributes.injection.helpers";
import { Property } from "../../../Plugins/PlacoBIMv3/Selection/Actions/types";
import { FunctionalityHeader } from "../../../Plugins/PlacoBIMv3/Selection/Components/FunctionalityHeader";

export const DetailsContainers = ({
  setRoute,
  returnRedirect,
  detailsProp,
}: any) => {
  const [details, setDetails] = useState<Property[]>([]);
  const [systemAttributes, setSystemAttributes] = useState<any>([]);
  const [system, setSystemBuffer] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [systemName, setSystemName] = useState("");

  const { t, i18n } = useTranslation(["library"]);
  useEffect(() => {
    async function callSystemDetails(oid: string, reduxSystem: any) {
      try {
        const solutionDetails = await fetchPlacoSolutionsDetails(
          [oid],
          detailsProp.config
        ).catch((error) => {
          throw error;
        });
        const system = solutionDetails.data.objects[0];

        let productName = system?.attributes?.find((a:any) => a.technicalName ==="A-Solution product name")?.values[0]?.value;
        setSystemName(productName);

        const systemType = _.get(reduxSystem, "GFR-Works name");
        const attributesModal = systemType.includes("Cloisons")
          ? "WALL"
          : systemType.includes("Plafonds")
          ? "CEILING"
          : "LINING_WALLS";
        //const attributesModal = systemType === 'Cloisons' ? 'WALL' : systemType === 'Plafonds' ? 'CEILING' : 'LINING_WALLS';
        var attributes = setPAMAttributesValuesModal(
          attributesModal,
          system.attributes
        );
        setSystemAttributes(attributes);
        setLoading(false);
      } catch (error) {
        console.log(error);
        // dispatch(
        //   setErrorModal({
        //     Open: true,
        //     Code: "503",
        //     Report: error,
        //     Reload: true,
        //   })
        // );
      }
    }

    if (detailsProp.selectionBuffer !== null) {
      const reduxSystem = detailsProp.selectionBuffer;
      setSystemBuffer(reduxSystem);
      callSystemDetails(reduxSystem.oid, reduxSystem);
    }
  }, []);

  if (loading) {
    return (
      <Dimmer active={loading} page>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div
      className="PropertyGlobalContainer"
      style={{ overflow: "auto", paddingRight: 20, marginRight: 0 }}
    >
      <FunctionalityHeader
        name={systemName}//{system.translation}
        Icon={detailsProp.functionalityIcon}
      />
      <div className="DetailsContainer">
        {systemAttributes && systemAttributes.length > 0 && (
          <div className="PropertiesContent" style={{ height: "100%" }}>
            <div className="Properties">
              <div className="PropertyDetailsContent">
                {systemAttributes.map((p: any) => (
                  <AccordionContainer
                    key={t(p.chapterName) || t(p.categoryName)}
                    title={t(p.chapterName) || t(p.categoryName)}
                    properties={
                      p.chapterData &&
                      p.chapterData.map(
                        (prop: { categoryName: any; pamData: any[] }) => ({
                          title: t(prop.categoryName),
                          onClick: () =>
                            setDetails(
                              prop.pamData &&
                                prop.pamData.map((propr) => ({
                                  title: t(propr.key),
                                  value: propr.value,
                                }))
                            ),
                        })
                      )
                    }
                    onClick={() =>
                      p.pamData &&
                      setDetails(
                        p.pamData &&
                          p.pamData.map((prop: any) => ({
                            title: t(prop.key),
                            value: prop.value,
                          }))
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <DetailsProperty
              properties={
                details && details.length > 0
                  ? details
                  : systemAttributes[0].pamData.map((prop: any) => ({
                      title: t(prop.key),
                      value: prop.value,
                    }))
              }
            />
          </div>
        )}
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton detailActionButton"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => {
            returnRedirect();
          }}
        >
          {t("RETURN")}
        </Button>
      </div>
    </div>
  );
};
