import React from "react";
import { useState, useEffect } from "react";
import { TopHeader } from "../../Components/Headers";
import { Segment, Button, Header, Icon, Modal } from "semantic-ui-react";
import { queryPlacoDataFromStore } from "../../../../API";
import { GroupSelector } from "../features";
import { useForm } from "react-hook-form";
import { SystemsMappingTable } from "../Components/Table/SelectionMapping/SystemsMappingTable";
import { cloneDeep, map, words, values } from "lodash";
import { ESelectionMethod, IWording } from "../../Types";
import { SelectionMethodPopup } from "../../Components/Popup";
import { SubHeader } from "../../Components/Headers/SubHeader";
import {
  onManualSelection,
  uniqLevel,
  uniqueElements,
} from "../utils/selection";
import { queryGyprocData } from "../../../../API/query";
import { mergeWallType } from "../Helper/AddWallTypeAfterAssignment";

const wording: IWording = {
  back: {
    French: "Retour",
    English: "Back",
  },
  stepTwo: {
    French: "Etape 2/2: Table de correspondance des systèmes",
    English: "Step 2/2: Systems mapping table",
  },
};

interface IProps {
  setRoute: any;
  selection: any;
  onSelectionSave: any;
  onSystemTypeDelete: any;
  onGroupValidate: any;
  onSelectionUpdate: any;
  onSystemsChange: any;
}

const style = {
  addSystem: {
    margin: "0 1rem",
    fontSize: "14px",
    color: "rgb(14,110,184)",
    cursor: "pointer",
  },
};
export const SystemsMapping = (props: IProps) => {
  const {
    setRoute,
    selection,
    onSelectionSave,
    onSystemTypeDelete,
    onGroupValidate,
    onSelectionUpdate,
    onSystemsChange,
  } = props;
  const walls = selection.SelectionDetails;
  const [markSolutions, setMarkSolutions] = useState<any>(null);
  const [groupShow, setGroupShow] = useState<boolean>(false);
  const [selectionMethodShowHide, setSelectionMethodShowHide] =
    useState<boolean>(false);
  const [method, setMethod] = useState<ESelectionMethod>(
    ESelectionMethod.MANUAL
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, dirtyFields },
  } = useForm();

  const createDefaultValues = () => {
    const dt: any = {};

    walls.forEach((wl: any) => {
      if (wl.Solution) {
        dt[`${words(wl.ElementName).join("")}`] = {
          label: wl.Solution.Name,
          value: wl.Solution.Oid,
        };
      }
    });
    return dt;
  };

  useEffect(() => {
    const deflValues = createDefaultValues();
    reset(deflValues);
  }, [walls]);

  const dropDownSolutionData = async () => {
    if (walls.length > 0) {
      const placoData: any = await queryGyprocData();

      if (placoData) {
        placoData.sort((a: any, b: any) => {
          const translationA = a.translation.toLowerCase();
          const translationB = b.translation.toLowerCase();

          if (translationA < translationB) {
            return -1;
          }
          if (translationA > translationB) {
            return 1;
          }
          return 0;
        });

        setMarkSolutions(
          map(placoData, (obj: any) => {
            return {
              label: obj.translation,
              value: obj.oid,
            };
          })
        );
      }
    }
  };

  useEffect(() => {
    dropDownSolutionData();
  }, []);

  const onSubmit = async (data: any) => {
    let cloneWall = cloneDeep(walls);

    map(cloneWall, (wall) => {
      const key = words(wall.ElementName).join("");
      const wallData = data[key];
      if (wallData) {
        wall["Solution"] = {
          ExternalName: "",
          Name: wallData["label"],
          Oid: wallData["value"],
          ElementType: "Wall",
        };
      }
      delete wall["Id"];
      delete wall["SelectionId"];
      return wall;
    });
    onSelectionSave(cloneWall);
  };

  const onSelectionMethodChanges = async (method: ESelectionMethod) => {
    setSelectionMethodShowHide(false);
    setMethod(method);
    if (method === ESelectionMethod.GROUP) {
      setGroupShow(true);
    } else {
      let { elements, floors } = await onManualSelection("wall");
      if (elements && floors) {
        if (elements.length > 0 && floors.length > 0) {
          const uniqElements = uniqueElements(elements.concat(walls));
          const uniqLevels = uniqLevel(floors.concat(selection.Floors));
          onSelectionUpdate(Object.values(uniqElements), uniqLevels);
        }
      }
    }
  };

  const onGroupBack = () => {
    setGroupShow(false);
  };

  const onGroupSelectionValidate = (elements: any, floors: any) => {
    setGroupShow(false);
    if (elements && floors) {
      if (elements.length > 0 && floors.length > 0) {
        const uniqElements = cloneDeep(uniqueElements(elements.concat(walls)));
        const uniqLevels = uniqLevel(floors.concat(selection.Floors));
        onSelectionUpdate(Object.values(uniqElements), uniqLevels);
      }
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <TopHeader
        Icon={"/plugin_new_logo/BeneluxIcon.png"}
        name={selection.Name}
      />
      <SubHeader heading={wording.stepTwo["English"]} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Segment basic style={{ width: "100%" }}>
          <SystemsMappingTable
            markSolutions={markSolutions}
            onRowDelete={onSystemTypeDelete}
            {...props}
            control={control}
            onSystemsChange={onSystemsChange}
          />
        </Segment>
        {markSolutions && (
          <Segment>
            <Header
              as="a"
              content={"Add a system type"}
              image={
                <Icon
                  name="plus circle"
                  color={"blue"}
                  size="big"
                  style={{ fontSize: "18px" }}
                />
              }
              style={style.addSystem}
              onClick={() => setSelectionMethodShowHide(true)}
            />
          </Segment>
        )}

        <Segment
          basic
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: 30,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Button
            color="orange"
            onClick={async () => {
              setRoute("ROOT");
            }}
          >
            {wording.back["English"]}
          </Button>
          <Button
            primary
            type="submit"
            disabled={!isValid || !markSolutions || walls.length === 0}
          >
            Save
          </Button>
        </Segment>
      </form>
      <SelectionMethodPopup
        show={selectionMethodShowHide}
        onClose={() => setSelectionMethodShowHide(false)}
        onSave={onSelectionMethodChanges}
      />
      <Modal
        dimmer="blurring"
        className="modal-close"
        closeIcon
        open={groupShow}
        onClose={() => setGroupShow(false)}
      >
        <Modal.Content>
          <GroupSelector
            onBack={onGroupBack}
            onValidate={onGroupSelectionValidate}
          />
        </Modal.Content>
      </Modal>
    </div>
  );

  return <></>;
};
