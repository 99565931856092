import { TextField } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { PROJECT_NAME } from "../../../assets/constants";
import "./body.scss";

function Body({ projectNameChanged, projectName = "", nameNotAlreadyExisting, t }: IProps) {
    return (
        <div id="project-name-body-container">
            <div className="px-4 py-2">
                <div className="row">
                    <div className="offset-3 col-6">
                        <TextField
                            label={t(PROJECT_NAME)}
                            variant="outlined"
                            size="small"
                            value={projectName}
                            onChange={(event) =>
                                projectNameChanged(event.target.value)
                            }
                            fullWidth
                        />
                    </div>
                    {!nameNotAlreadyExisting &&
                        <div className="offset-3 col-6" style={{ marginTop: 5, paddingLeft: 25, color: 'red' }}>
                            {t("PROJECT_ALREADY_EXISTS")}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    projectNameChanged: Function;
    projectName?: string;
    nameNotAlreadyExisting?: boolean;
};

export default withTranslation(["pam"])(Body);
