import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import { Routes } from "./root";
import GroupSelector from "./GroupSelector";
import SelectionMapping from "./SelectionMapping";
import { fetchRemoteData, loadSelections, initLanguage } from "../Actions";
import ProductsPage from "./ProductsPage";
import _ from "lodash";
import TechnicalLanding from "../../DocumentTechniques/Components/TechnicalLanding/TechnicalLanding";
import { SystemDetailsContainer } from "./SystemDetailsContainer";
import { SystemConfigurationContainer } from "./SystemConfigurationContainer";
import { WithTranslation, withTranslation } from "react-i18next";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";
import { SelectionList } from "./SelectionList";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    selectionPar: ownProps.selectionPar,
    module: ownProps.module,
    language: state.language,
  };
};

const mapDispatchToProps = {
  fetchRemoteData,
  loadSelections,
  initLanguage,
  setConfig,
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InjectedCounterProps & {
    module: string;
    route: string;
    setRoute: any;
    selectionPar: boolean;
    language: string;
    initLanguage: any;
    setConfig: any;
    onError: any;
  } & WithTranslation;

export class AppBody extends Component<Props> {
  state = {
    selectionID: [],
  };

  public static defaultProps = {
    module: "selection",
  };

  componentDidMount = async () => {
    this.props.initLanguage();

    setTimeout(() => {
      this.props.i18n.changeLanguage(this.props.language);
    });

    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  setSelectionId = (Id: string) => {
    if (_.includes(this.state.selectionID, Id)) {
      this.setState({
        selectionID: [..._.without(this.state.selectionID, Id)],
      });
    } else {
      this.setState({ selectionID: [...this.state.selectionID, Id] });
    }
  };

  returnRedirect = () => {
    this.props.setRoute(Routes.PRODUCT_SELECTOR);
  };

  returnRedirectAdvSelector = () => {
    this.props.setRoute(Routes.PRODUCT_SELECTOR);
  };

  returnRedirectMapping = () => {
    this.props.setRoute(Routes.MAPPING);
  };

  render() {
    const { route, setRoute, module, selectionPar, onError } = this.props;
    switch (route) {
      case Routes.ROOT:
        return (
          <SelectionList
            route={route}
            setRoute={setRoute}
            module={module}
            setSelectionId={this.setSelectionId}
            selectionPar={selectionPar}
            onError={onError}
          />
        );
      case Routes.GROUP_SELECTION:
        return (
          <GroupSelector route={route} setRoute={setRoute} module={module} />
        );
      case Routes.MAPPING:
        return (
          <SelectionMapping
            route={route}
            setRoute={setRoute}
            module={module}
            setSelectionId={this.setSelectionId}
            selectionPar={selectionPar}
            onError={onError}
          />
        );
      case Routes.PRODUCT_SELECTOR:
        return <ProductsPage setRoute={setRoute} />;
      case Routes.TECHNICAL_LANDING:
        return <TechnicalLanding routes={route} setRoute={setRoute} />;
      case Routes.SYSTEM_DETAILS:
        return (
          <SystemDetailsContainer
            setRoute={setRoute}
            returnRedirect={this.returnRedirectAdvSelector}
            onError={onError}
          />
        );
      case Routes.SYSTEM_DETAILS_MAPPING:
        return (
          <SystemDetailsContainer
            setRoute={setRoute}
            returnRedirect={this.returnRedirectMapping}
            onError={onError}
          />
        );
      case Routes.SYSTEM_CONFIGURATION:
        return (
          <SystemConfigurationContainer
            setRoute={setRoute}
            returnRedirect={this.returnRedirect}
            onError={onError}
          />
        );
      default:
        return null;
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withTranslation(["library"])(AppBody));
