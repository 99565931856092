import axios from "axios";
import { api } from "../../../../RevitJS/API";

const GetAnonymousFile = async (file: string, ext: string, cng: any) => {
   
    const baselUrl = cng.REACT_APP_FILESERVER;
    const url:any = `${baselUrl}/static/directdownload/${file}.${ext}`
    try {
        const {data} = await axios.get(url,{responseType: 'blob'})
        return data        
      } catch(error) {
        const base64File = await api.queries.downloadFileAnonymous(
            baselUrl!,
            "/static/prepare/",
            file,
            ext
          );
          const base64Response = await fetch(`data:application/pdf;base64,${base64File}`);
          const blob = await base64Response.blob();
          return blob     
      }

}

const dataURLtoFile = (dataurl:any, filename:any) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}
export { GetAnonymousFile, dataURLtoFile }