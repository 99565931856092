import { bimStorage } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import _, { flattenDeep, forEach, map } from "lodash";

const updateSelectionIfWallDeleted = async (selectionId: any) => {
  const RevitProject = await bimStorage.onProjectData();

  let countChanges: any = 0;

  let gyprocSelections: any[] = [];
  let result1: any = await new Promise((resolve, reject) =>
    window.indec.getSelection(parseInt(selectionId), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return null;
    }); //await bimStorage.getSelection(selectionId);

  if (result1) {
    gyprocSelections.push(result1);

    //get all walls from modal
    const wallTree = await api.selection.elementsByLevelAndType("wall");

    const getAllTypeIdsfromSelection = async (typeTree: any) => {
      const LeveWiseObjectIds: any = {};

      typeTree.forEach((level: any) => {
        const levelTypeIds: any = _.flatten(
          level.Elements.map((e: any) => {
            return e.Ids;
          })
        );

        const ArrLenght = levelTypeIds.length;
        const newObj: any = {};
        for (let i = 0; i < ArrLenght; i++) {
          newObj[levelTypeIds[i]] = levelTypeIds[i];
        }

        LeveWiseObjectIds[level.Level.Name] = newObj;
      });
      return LeveWiseObjectIds;
    };

    const getIdsByItsTypefromSelection = async (typeTree: any) => {
      const TypeWiseObjectIds: any = {};
      let allTypes: any[] = [];
      typeTree.forEach((level: any) => {
        const types: any = _.flatten(
          level.Elements.map((e: any) => {
            return e.Type;
          })
        );

        allTypes = [...allTypes, ...types];
      });
      var uniqueTypes = allTypes.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      uniqueTypes.forEach((element) => {
        let typeIds: any[] = [];
        const newObj: any = {};
        typeTree.forEach((level: any) => {
          let type = level.Elements.filter((e: any) => {
            return e.Type === element;
          });

          if (type && type.length > 0) {
            typeIds = [...typeIds, ...type[0].Ids];
          }
        });
        for (let i = 0; i < typeIds.length; i++) {
          newObj[typeIds[i]] = typeIds[i];
        }

        TypeWiseObjectIds[element] = newObj;
      });

      return TypeWiseObjectIds;
    };

    const allWallIds: any = await getAllTypeIdsfromSelection(wallTree.Tree);

    const allWallIdsByType: any = await getIdsByItsTypefromSelection(
      wallTree.Tree
    );

    //To check if any wall deleted by model/exterior
    let checkIfWallDeletedByModel: boolean = false;
    let onlyWallIds: any = map(allWallIds, (ele: any) => {
      return Object.values(ele);
    });

    onlyWallIds = flattenDeep(onlyWallIds);

    forEach(result1.SelectionDetails, (ele: any) => {
      forEach(ele.Ids, (i: any) => {
        if (!onlyWallIds.includes(i)) {
          checkIfWallDeletedByModel = true;
          return;
        }
      });
    });

    const updatedSelections = Object.values(gyprocSelections).reduce(
      (acc: any, curr: any) => {
        if (RevitProject === curr.ProjectId) {
          const levelArr = new Set();
          let changedWallsIds: any[] = [];

          let walls: any = curr.SelectionDetails.map((e: any) => {
            e.Ids = e.Ids.filter((a: any) => {
              for (const [key, value] of Object.entries(allWallIdsByType)) {
                const wall: any = value;
                if (wall[a] && key === e.ElementName) {
                  return true;
                } else if (!wall[a] && key === e.ElementName) {
                  let row = JSON.parse(JSON.stringify(e));
                  let typeName: string = "";
                  for (const [key, value] of Object.entries(allWallIdsByType)) {
                    const wallIds: any = value;
                    if (wallIds[a]) {
                      typeName = key;
                      break;
                    }
                  }

                  let isAdded: boolean = false;
                  changedWallsIds.forEach((revitRow) => {
                    if (revitRow.ElementName === typeName) {
                      if (!revitRow.Ids.includes(a)) {
                        revitRow.Ids = [...revitRow.Ids, a];
                      }
                      isAdded = true;
                    }
                  });

                  let indexValue = 0;
                  if (!isAdded && typeName !== "") {
                    if (changedWallsIds.length > 0) {
                      indexValue =
                        changedWallsIds[changedWallsIds.length - 1].Index + 1;
                    } else {
                      indexValue = curr.SelectionDetails.length;
                    }

                    let maxIds = curr.SelectionDetails.map(
                      (a: any) => a.Id
                    ).sort((one: any, two: any) => (one > two ? -1 : 1));

                    row.ElementName = typeName;
                    row.Ids = [a];
                    row.Index = indexValue;
                    row.Id = maxIds[0] + 1;
                    for (const [keyLevel, value] of Object.entries(
                      allWallIds
                    )) {
                      const wall: any = value;
                      if (wall[a]) {
                        // 2. if any wall from the level exist add that level to level arr
                        levelArr.add(keyLevel);
                      }
                    }
                    changedWallsIds.push(row);
                  }
                } else if (wall[a] && key !== e.ElementName) {
                  let row = JSON.parse(JSON.stringify(e));
                  let typeName: string = key;

                  let isAdded: boolean = false;
                  changedWallsIds.forEach((revitRow) => {
                    if (revitRow.ElementName === typeName) {
                      if (!revitRow.Ids.includes(a)) {
                        revitRow.Ids = [...revitRow.Ids, a];
                      }
                      isAdded = true;
                    }
                  });

                  let indexValue = 0;
                  if (!isAdded) {
                    if (changedWallsIds.length > 0) {
                      indexValue =
                        changedWallsIds[changedWallsIds.length - 1].Index + 1;
                    } else {
                      indexValue = curr.SelectionDetails.length;
                    }

                    let maxIds = curr.SelectionDetails.map(
                      (a: any) => a.Id
                    ).sort((one: any, two: any) => (one > two ? -1 : 1));

                    row.ElementName = typeName;
                    row.Ids = [a];
                    row.Index = indexValue;
                    row.Id = maxIds[0] + 1;
                    for (const [keyLevel, value] of Object.entries(
                      allWallIds
                    )) {
                      const wall: any = value;
                      if (wall[a]) {
                        // 2. if any wall from the level exist add that level to level arr
                        levelArr.add(keyLevel);
                      }
                    }
                    changedWallsIds.push(row);
                  }
                }
              }
            });

            e.Ids = e.Ids.filter((a: any) => {
              // 1. check if the id exist in every level
              for (const [key, value] of Object.entries(allWallIds)) {
                const wall: any = value;
                if (wall[a]) {
                  // 2. if any wall from the level exist add that level to level arr
                  levelArr.add(key);
                  return true;
                }
              }
            });

            return e;
          });

          if (changedWallsIds && changedWallsIds.length > 0) {
            countChanges = countChanges + 1;
            changedWallsIds.forEach((changedwallRow) => {
              let existingWall = walls.filter((w: any) => {
                return w.ElementName == changedwallRow.ElementName;
              });

              if (existingWall && existingWall.length > 0) {
                existingWall[0].Ids = [
                  ...existingWall[0].Ids,
                  ...changedwallRow.Ids,
                ];
              } else {
                walls = [...walls, changedwallRow];
              }
            });
          }

          acc[curr.Id] = curr;
          acc[curr.Id].SelectionDetails = walls.filter((wall: any) => {
            return wall.Ids.length > 0;
          });

          if (acc[curr.Id].Floors) {
            acc[curr.Id].Floors = acc[curr.Id].Floors.filter((level: any) => {
              return levelArr.has(level.Name);
            });
          }

          if (acc[curr.Id].SelectionDetails.length === 0) {
            delete acc[curr.Id];
          }
        } else {
          acc[curr.Id] = curr;
        }
        return acc;
      },
      {}
    );

    console.log(updatedSelections);
    console.log("changes total: ", countChanges);

    let response: boolean = false;

    if (countChanges > 0 || checkIfWallDeletedByModel) {
      response = await bimStorage.updateSelection(
        parseInt(Object.keys(updatedSelections)[0]),
        Object.values(updatedSelections)[0]
      );
    }
  }

  return countChanges;
};
export default updateSelectionIfWallDeleted;
