import { Button } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    getImageAssetUrlAttributeFromRange,
    getNameAttributeFromRange,
    getShortDescAttributeFromRange,
} from "../../../helpers/utils";
import Element from "../../../models/Element.model";
import PixelLoader from "../../common/loader/PixelLoader";
import "./body.scss";
import FiltersComponent from "./filtersComponent/FiltersComponent";
import RangeComponentHeader from "./header/RangeComponentHeader";
import LooseElementsComponent from "./looseElementsComponent/LooseElementsComponent";
import RangeComponent from "./rangeComponent/RangeComponent";
import SelectionComponent from "./selectionComponent/SelectionComponent";

function Body({
    items,
    loading = false,
    allAvailableElements,
    looseElements,
    setCurrentItemAndSanitizeAvailableElements,
    handleValidateSelection,
    inEdition,
    openCatalog,
    getElementName,
    t,
}: IProps) {
    return (
        <div id="range-selection-body-container">
            <div className="px-4 py-2">
                <div className="row">
                    <div
                        className="col-12"
                        id="range-selection-table-container"
                    >
                        <table
                            id="range-selection-table"
                            className="w-100 text-center"
                        >
                            <RangeComponentHeader></RangeComponentHeader>

                            {loading ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <PixelLoader></PixelLoader>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <>
                                    <tbody className="w-100">
                                        {items.map(
                                            (item: any, itemIndex: number) => {
                                                return (
                                                    <tr
                                                        key={`range-item-tr-${item.range.oid}`}
                                                        className="w-100"
                                                    >
                                                        <td className="range-component-td">
                                                            <RangeComponent
                                                                name={getNameAttributeFromRange(
                                                                    item.range
                                                                )}
                                                                desc={getShortDescAttributeFromRange(
                                                                    item.range
                                                                )}
                                                                image={getImageAssetUrlAttributeFromRange(
                                                                    item.range
                                                                )}
                                                            />
                                                        </td>
                                                        <td className="range-aviable-elements-td">
                                                            <div>{`${item.compatibleAndAvailableElements.length}/${allAvailableElements.length}`}</div>
                                                        </td>
                                                        <td className="range-filters-selectors-td">
                                                            <div
                                                                className="d-flex"
                                                                style={{
                                                                    minHeight:
                                                                        "20em",
                                                                }}
                                                            >
                                                                {item.current ? (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                width:
                                                                                    "40%",
                                                                                borderRight:
                                                                                    "2px solid whitesmoke",
                                                                            }}
                                                                        >
                                                                            <FiltersComponent
                                                                                item={
                                                                                    item
                                                                                }
                                                                                content={[
                                                                                    {
                                                                                        filter:
                                                                                            "pipes",
                                                                                        filterElementsNbr:
                                                                                            item.pipesLength,
                                                                                    },
                                                                                    {
                                                                                        filter:
                                                                                            "fittings",
                                                                                        filterElementsNbr:
                                                                                            item.fittingsLength,
                                                                                    },
                                                                                    {
                                                                                        filter:
                                                                                            "accessories",
                                                                                        filterElementsNbr:
                                                                                            item.accessoriesLength,
                                                                                    },
                                                                                ]}
                                                                            ></FiltersComponent>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                width:
                                                                                    "60%",
                                                                                borderLeft:
                                                                                    "2px solid whitesmoke",
                                                                                border:
                                                                                    "1px solid #505050",
                                                                            }}
                                                                        >
                                                                            <SelectionComponent
                                                                                itemIndex={
                                                                                    itemIndex
                                                                                }
                                                                                handleValidateSelection={
                                                                                    handleValidateSelection
                                                                                }
                                                                                item={
                                                                                    item
                                                                                }
                                                                                filters={
                                                                                    item.filters
                                                                                }
                                                                                getElementName={
                                                                                    getElementName
                                                                                }
                                                                            ></SelectionComponent>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="m-auto">
                                                                        <Button
                                                                            id="footer-next-button"
                                                                            className="ml-2"
                                                                            variant="contained"
                                                                            disabled={
                                                                                inEdition
                                                                            }
                                                                            onClick={(
                                                                                event
                                                                            ) => {
                                                                                setCurrentItemAndSanitizeAvailableElements(
                                                                                    item
                                                                                );
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                "SHOW_OBJECTS_SELECTION_FOR_THIS_RANGE"
                                                                            )}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                    <LooseElementsComponent
                                        getElementName={getElementName}
                                        looseElementsList={looseElements}
                                        openCatalog={openCatalog}
                                    ></LooseElementsComponent>
                                </>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    loading: boolean;
    items: any;
    allAvailableElements: Element[];
    looseElements: Element[];
    setCurrentItemAndSanitizeAvailableElements: Function;
    handleValidateSelection: Function;
    inEdition: boolean;
    openCatalog: Function;
    getElementName: Function;
};

type IState = {};

export default withTranslation(["pam"])(Body);
