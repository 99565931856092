import React from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { Routes } from "./root";

export const BackButtonModal = (props: any) => {
  
  return (
    <Modal open={props.backupModal} size="mini" dimmer="blurring">
      <Modal.Header className="modalHeader">
        {props.backup}{" "}
        <Icon
          name="close"
          style={{ float: "right", cursor: "pointer" }}
          onClick={props.closeBackupModal}
        ></Icon>
      </Modal.Header>
      <Modal.Description style={{ textAlign: "center", padding: "15px 15px" }}>
        <p>{props.description}</p>
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button
          color="orange"
          size="tiny"
          onClick={() => {
            props.setRoute(Routes.ROOT);
          }}
        >
          {props.no}
        </Button>
        <Button
          size="tiny"
          type="submit"
          primary
          onClick={() => {
            Promise.resolve(props.saveAction()).then(() => {
              props.setRoute(Routes.ROOT);
            });           
          }}
        >
          {props.yes}
        </Button>
      </div>
    </Modal>
  );
};
