import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { UserManualRoot } from "./components/root";

export const functionalityId = "58a79ec5-15a7-4c37-9862-debee03341c8";

export const schedulesTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <UserManualRoot />
    </ParentRoot>
  );
};

export const UserManualRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <UserManualRoot />
    </ParentRoot>
  );
};

export const UserManual: Functionality = {
  Name: {
    French: "Manuel utilisateur",
    English: "User Manual",
    German: "Manuel utilisateur",
    Italian: "Manuel utilisateur",
    Indonesian: "Manuel utilisateur",
  },
  Trigger: schedulesTrigger,
  ShortDesc: {
    French: "Télécharger le guide",
    English: "Download the guide",
    German: "Télécharger le guide",
    Italian: "Télécharger le guide",
    Indonesian: "Télécharger le guide",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Manuel.png",
  RootComponent,
  ParentId: "0",
};
