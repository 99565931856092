import React, { Component } from "react";
import AppBody from "./AppBody";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../../Selection/Reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps;

export enum Routes {
  ROOT = "Home",
}

interface State {
  route: Routes;
}

class ReverseDirectionRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    this.store = createStore(
      reducers,
      composeWithDevTools(applyMiddleware(thunk))
    );
  }

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    // api.windowsHandler.showDevTools();
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody
          route={this.state.route}
          setRoute={this.setRoute}
          onError={this.props.onError}
        />
      </Provider>
    );
  };
}

export default withErrorSubscription(ReverseDirectionRoot);
