
import {
  LOAD_SYSTEMS,
  LOAD_SYSTEMS_SUCCESS,
  LOAD_SYSTEMS_ERROR,
  LOAD_SYSTEMS_TO_UPDATED,
  LOAD_SYSTEMS_TO_UPDATED_ERROR,
  SystemsActionTypes,
  SystemsState,
  LOAD_UPDATED_SYSTEMS_SUCCESS
} from "./types";

const initialState: SystemsState = {
  loadingSystems: false,
  currentSystemsToUpdate: [],
  data: {
    liningWallsDetails: [],
    partitionsDetails: [],
    ceilingsDetails: [],
    gainestechniqueswallDetails: [],
  }
}

export default function updateSystemsReducer(
  state = initialState,
  action: SystemsActionTypes
): SystemsState {
  switch (action.type) {
    case LOAD_SYSTEMS:
      return {
        ...state,
        loadingSystems: true,
      };
    case LOAD_SYSTEMS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loadingSystems: false,
      };
    case LOAD_SYSTEMS_ERROR:
      return {
        ...state,
        loadingSystems: false,
      };
    case LOAD_SYSTEMS_TO_UPDATED:
      return {
        ...state,
      };
    case LOAD_UPDATED_SYSTEMS_SUCCESS:
      return {
        ...state,
        currentSystemsToUpdate: action.currentSystems,
      };
    case LOAD_SYSTEMS_TO_UPDATED_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
