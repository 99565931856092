import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../../Selection/Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";
import { composeWithDevTools } from "redux-devtools-extension";
import { setPluginDataToDB } from "../../../../Helper";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps;

/**
 * Remeber write Routes value in CAPITAL Letters only
 */
export enum Routes {
  SELECTION_HOME_TECHNICAL = "SELECTION_HOME_TECHNICAL",
  TECHNICAL_ROOT = "TECHNICAL_ROOT",
  PROJECT_INFO = "PROJECT_INFO_FORM",
  PROJECT_INFO_SUPPLIMENTARY = "SUPPLIMENTARY_FORM",
  COVER_PAGE = "COVER_PAGE",
  REFERENCE_CCTP = "REFERENCECCTPLANDING",
  TECHNICAL_LANDING = "TECHNICAL_LANDING",
  ADD_SYSTEM_TYPE = "ADD_SYSTEM_TYPE",
  PREVIEW_PDF = "PDF_PREVIEW",
  GROUP_SELECTION = "GROUP_SELECTION",
  MAPPING = "MAPPING",
  PRODUCT_SELECTOR = "PRODUCT_SELECTOR",
  BACK_FROM_SELECTION = "BACK_FROM_SELECTION",
  DOC_SELECTION_HOME = "DOC_SELECTION_HOME",
  SELECTION_HOME = "SELECTION_HOME",
  SYSTEM_DETAILS = "SYSTEM_DETAILS",
  SYSTEM_CONFIGURATION = "SYSTEM_CONFIGURATION",
}

interface State {
  route: Routes;
}

class TechniqueRoot extends Component<Props, State> {
  state = {
    route: Routes.TECHNICAL_ROOT,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    this.store = createStore(
      reducers,
      composeWithDevTools(applyMiddleware(thunk))
    );
  }

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    // api.windowsHandler.showDevTools();
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      setPluginDataToDB("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      setPluginDataToDB("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody
          route={this.state.route}
          setRoute={this.setRoute}
          onError={this.props.onError}
        />
      </Provider>
    );
  };
}

export default withErrorSubscription(TechniqueRoot);
