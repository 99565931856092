import React from "react";
import "./index.scss";
import "semantic-ui-css/semantic.min.css";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { oldStore } from "./Reducers";
import { QueryClient, QueryClientProvider } from "react-query";
import { NewRouter } from "./Routes";
import { createRoot } from "react-dom/client";
import "./App.css";
import "abortcontroller-polyfill";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={oldStore}>
    <QueryClientProvider client={queryClient}>
      <NewRouter />
    </QueryClientProvider>
  </Provider>
);

serviceWorker.unregister();
