export enum PAM_SYSTEM_TYPE_KEYS {
  WALL = "WALL",
  CEILING = "CEILING",
  LINING_WALLS = "LINING_WALLS",
}

export enum CategoryTypeEnum {
  wall = <any>'Wall',
  ceiling = <any>'Ceiling',
  liningwall = <any>'Lining Wall',
  floor = <any>'Floor',
  gainestechniqueswall =<any>'Gaines Techniques'
}