import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Routes } from "./root";
import { fetchRemoteData, loadSelections } from "../../Selection/Actions";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import { Selection } from "../../Selection/Actions/types";
import MetresLanding from "./MetresLanding";
import { bimStorage, storageKey } from "../../../../BIMStore";
import _ from "lodash";
import { MetSelection } from "./MetSelection";
import SelectionRoot from "../../Selection/Components/root";
import { NomenclatureRoot } from "../../Nomenclature/Components/root";
import { initLanguage } from "../../Nomenclature/Actions";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};

const mapDispatchToProps = {
  fetchRemoteData,
  loadSelections,
  initLanguage,
  setConfig,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  route: string;
  setRoute: any;
  initLanguage: any;
};

export class AppBody extends Component<Props> {
  state = {
    selectionPar: false,
    selectionID: [],
  };

  componentDidMount = async () => {
    this.props.initLanguage();

    const cng = await MyConfig();
    this.props.setConfig(cng);

    let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
    if (placoSelections) {
      let selections = placoSelections as {
        [key: string]: Selection<PlacoOptions>;
      };
      this.props.loadSelections(selections);
    }
  };
  setSelectionPar = (selectionPar: boolean) => {
    this.setState({
      selectionPar,
    });
  };

  setSelectionId = (Id: string) => {
    if (_.includes(this.state.selectionID, Id)) {
      this.setState({
        selectionID: [..._.without(this.state.selectionID, Id)],
      });
    } else {
      this.setState({ selectionID: [...this.state.selectionID, Id] });
    }
  };

  render() {
    const { route, setRoute } = this.props;
    switch (route) {
      case Routes.ROOT:
        return <MetresLanding route={route} setRoute={setRoute} />;
      case Routes.SELECTION_METRES_HOME:
        //api.windowsHandler.resizeWindow(900, 507);
        return <MetSelection setRoute={setRoute} />;
      case Routes.SELECTION_HOME:
        //api.windowsHandler.resizeWindow(900, 507);
        return <SelectionRoot module="selection" />;
      case Routes.QUICK_CALEPINAGE:
        return <NomenclatureRoot />;
      default:
        return null;
    }
  }
}

export default connector(AppBody);
