import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Routes } from "./root";
import { fetchRemoteData, loadSelections } from "../../Selection/Actions";
import Axios from "axios";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import SavedCalpinage from "./CalepinageList/SavedCalpinage";
import SelectionMapping from "../../Selection/Components/SelectionMapping";
import GroupSelector from "../../Selection/Components/GroupSelector";
import ProductsPage from "../../Selection/Components/ProductsPage";
import Solution from "../Components/Solution";
import { Selection } from "../../Selection/Actions/types";
import CalpinageHomeOptimized from "./CalpinageHomeOptimized";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { CalSelection } from "./CalSelection";
import SelectionRoot from "../../Selection/Components/root";
import { initLanguage } from "../Actions";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";
const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};

const mapDispatchToProps = {
  fetchRemoteData,
  loadSelections,
  initLanguage,
  setConfig,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InjectedCounterProps & {
    route: string;
    setRoute: any;
    module: string;
    initLanguage: any;
  };

export class AppBody extends Component<Props> {
  state = {
    selectionID: [],
  };

  public static defaultProps = {
    module: "Calepinage",
  };

  componentDidMount = async () => {
    this.props.initLanguage();
    const cng = await MyConfig("Calepinage");

    // let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);

    // if (placoSelections) {
    //   let selections = placoSelections as {
    //     [key: string]: Selection<PlacoOptions>;
    //   };
    //   this.props.loadSelections(selections);
    // }
    this.props.setConfig(cng);
  };

  returnRedirect = () => {
    this.props.setRoute(Routes.CALPINAGE_SOLUTION);
  };

  render() {
    const { route, setRoute } = this.props;
    switch (route) {
      case Routes.ROOT:
        return <SavedCalpinage route={route} setRoute={setRoute} />;
      case Routes.CALPINAGE_HOME:
        return (
          <CalpinageHomeOptimized
            route={route}
            setRoute={setRoute}
            onError={this.props.onError}
          />
        );
      case Routes.CAL_SELECTION_HOME:
        return <CalSelection setRoute={setRoute} />;
      case Routes.GROUP_SELECTION:
        return (
          <GroupSelector route={route} setRoute={setRoute} module="selection" />
        );
      case Routes.MAPPING:
        return (
          <SelectionMapping
            route={route}
            setRoute={setRoute}
            module="selection"
          />
        );
      case Routes.PRODUCT_SELECTOR:
        return <ProductsPage setRoute={setRoute} />;
      case Routes.CALPINAGE_SOLUTION:
        return <Solution setRoute={setRoute} onError={this.props.onError} />;
      case Routes.SELECTION_HOME:
        //api.windowsHandler.resizeWindow(900, 507);
        return <SelectionRoot module="selection" />;
      // case Routes.SYSTEM_CONFIGURATION:
      //   return <SystemConfigurationContainer setRoute={setRoute} returnRedirect={this.returnRedirect} />;
      default:
        return null;
    }
  }
}

export default connector(AppBody);
