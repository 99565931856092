import {
    getPlacoSystemDetails,
    getPlacoSystemDetailsWithChildren,
  } from "../Requests";
  import { api } from "../../../RevitJS/API";
  
  export const selectedItems = (data, selectedLevels, selectedTypes) => {
    if (selectedLevels.length > 0 && selectedTypes.length > 0)
      return groupByArray(
        data.Tree.filter((level) => selectedLevels.includes(level.Level.Name))
          .map((level) => level.Elements)
          .map((s) => s.map((nested) => nested))
          .reduce((prev, current) => prev.concat(current))
          .filter((e) => selectedTypes.includes(e.Type)),
        "Type"
      ).map((el) => {
        return {
          Type: el.key,
          Ids: [].concat.apply(
            [],
            el.values.map((val) => val.Ids)
          ),
        };
      });
    return [];
  };
  
  export const selectedItemsManualSelection = (data) => {
    return groupByArray(
      data.Tree.map((level) => level.Elements)
        .map((s) => s.map((nested) => nested))
        .reduce((prev, current) => prev.concat(current)),
      "Type"
    ).map((el) => {
      return {
        Type: el.key,
        Ids: [].concat.apply(
          [],
          el.values.map((val) => val.Ids)
        ),
      };
    });
  };
  
  export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  
  export function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }
  
  export const mergeItems = (arr) =>
    groupByArray(arr, "Type").map((el) => {
      return {
        Type: el.key,
        Count: el.values.reduce(function (prev, cur) {
          return prev + cur.Count;
        }, 0),
        Checked: false,
      };
    });
  
  export const elementTypes = (data) => {
    return data.Tree.length > 0
      ? mergeItems(
          data.Tree.map((level) => level.Elements)
            .map((s) =>
              s.map((nested) => {
                return {
                  Type: nested.Type,
                  Count: nested.Ids.length,
                  Ids: nested.Ids,
                  Checked: false,
                };
              })
            )
            .reduce((prev, current) => prev.concat(current))
            .sort((a, b) => (a.Type < b.Type ? -1 : 1))
        )
      : null;
  };
  
  export const levelData = (data, levellist) => {
    if (levellist.length === 0) return data;
    let levelsData = data.Tree.filter((level) =>
      levellist.includes(level.Level.Name)
    );
    return { Tree: levelsData };
  };
  
  export const selectedIds = (data, selectedLevels, selectedTypes) => {
    if (selectedLevels.length > 0 && selectedTypes.length > 0)
      return data.Tree.filter((level) =>
        selectedLevels.includes(level.Level.Name)
      )
        .map((level) => level.Elements)
        .map((s) => s.map((nested) => nested))
        .reduce((prev, current) => prev.concat(current))
        .filter((e) => selectedTypes.includes(e.Type))
        .map((el) => el.Ids)
        .reduce((prev, current) => prev.concat(current));
    return [];
  };
  
  export const getLayoutAttributesAid = (attributes) => {
    let plateWidth = "";
    if (
      attributes.find(
        (i) => i.technicalName === "GFR-Width of the UB unpacked (m)"
      )
    ) {
      console.log("This attribute was found for this solution");
      plateWidth = attributes.find(
        (i) => i.technicalName === "GFR-Width of the UB unpacked (m)"
      ).aid;
    } else {
      if (
        attributes.find(
          (i) => i.technicalName === "A-Solution Width"
        )
      )
      {
      plateWidth = attributes.find((i) => i.technicalName === "A-Solution Width")
        .aid;
      }
    }
    let layoutProducts = [
      "GFR-Product 1",
      "GFR-Product 2",
      "GFR-Product 2",
      "GFR-Product 3",
      "GFR-Product 4",
      "GFR-Product 5",
      "GFR-Product 6",
      "GFR-Product 7",
      "GFR-Product 8",
      "GFR-Product 9",
      "GFR-Product 10",
      "GFR-Product 11",
      "GFR-Product 12",
      "GFR-Product 13",
      "GFR-Product 14",
      "GFR-Product 15",
    ];
    return {
      layers: attributes.find((i) => i.technicalName === "GFR-BIM layers").aid,
      rails: attributes
        .filter((i) => layoutProducts.includes(i.technicalName))
        .map((attr) => attr.aid),
      frames: attributes
        .filter((i) => layoutProducts.includes(i.technicalName))
        .map((attr) => attr.aid),
      framesSpace: attributes.find(
        (i) => i.technicalName === "GFR-Distance between frames (in m)"
      ).aid,
      doubleFraming: attributes.find(
        (i) => i.technicalName === "GFR-Profiles (1 or 2)"
      ).aid,
      layerNumber: attributes.find((i) => i.technicalName === "GFR-Layer number")
        .aid,
      layerContent: attributes.find(
        (i) => i.technicalName === "GFR-Layer content"
      ).aid,
      layerName: attributes.find((i) => i.technicalName === "GFR-Layer name").aid,
      layerColor: attributes.find((i) => i.technicalName === "GFR-Layer color")
        .aid,
      layerThickness: attributes.find(
        (i) => i.technicalName === "GFR-Layer thickness mm"
      ).aid,
      plateWidth,
      systemName: attributes.find(
        (i) => i.technicalName === "A-Solution product name"
      ).aid,
    };
  };
  
  export const getLiningWallsAttributesAid = (attributes) => {
    let plateWidth;
    if (
      attributes.find(
        (i) => i.technicalName === "GFR-Width of the UB unpacked (m)"
      )
    ) {
      console.log("This attribute was found for this solution");
      plateWidth = attributes.find(
        (i) => i.technicalName === "GFR-Width of the UB unpacked (m)"
      ).aid;
    } else {
      console.log("The attribute is not present for this solution");
      plateWidth = attributes.find((i) => i.technicalName === "A-Solution Width")
        .aid;
    }
    let layoutProducts = [
      "GFR-Product 1",
      "GFR-Product 2",
      "GFR-Product 2",
      "GFR-Product 3",
      "GFR-Product 4",
      "GFR-Product 5",
      "GFR-Product 6",
      "GFR-Product 7",
      "GFR-Product 8",
      "GFR-Product 9",
      "GFR-Product 10",
      "GFR-Product 11",
      "GFR-Product 12",
      "GFR-Product 13",
      "GFR-Product 14",
      "GFR-Product 15",
    ];
    return {
      layers: attributes.find((i) => i.technicalName === "GFR-BIM layers").aid,
      rails: attributes
        .filter((i) => layoutProducts.includes(i.technicalName))
        .map((attr) => attr.aid),
      frames: attributes
        .filter((i) => layoutProducts.includes(i.technicalName))
        .map((attr) => attr.aid),
      framesSpace: attributes.find(
        (i) => i.technicalName === "GFR-Distance between frames (in m)"
      ).aid,
      doubleFraming: attributes.find(
        (i) => i.technicalName === "GFR-Profiles (1 or 2)"
      ).aid,
      layerNumber: attributes.find((i) => i.technicalName === "GFR-Layer number")
        .aid,
      layerContent: attributes.find(
        (i) => i.technicalName === "GFR-Layer content"
      ).aid,
      layerName: attributes.find((i) => i.technicalName === "GFR-Layer name").aid,
      layerColor: attributes.find((i) => i.technicalName === "GFR-Layer color")
        .aid,
      layerThickness: attributes.find(
        (i) => i.technicalName === "GFR-Layer thickness mm"
      ).aid,
      plateWidth,
      systemName: attributes.find(
        (i) => i.technicalName === "A-Solution product name"
      ).aid,
      technicalName: attributes.find(
        (i) => i.technicalName === "GFR-Profiles (1 or 2)"
      ).aid,
    };
  };
  
  export const makeDefaultLayer = (side, index) => {
    let layer = {};
    layer[side + index] = "None";
    layer[side + index + "_" + "Name"] = "None";
    return layer;
  };
  
  export const addDefaultLayer = (cladding, side) => {
    if (cladding.length >= 3) return cladding;
    let nb_layers_to_add = 3 - cladding.length;
    cladding = [...cladding];
    for (let i = 0; i < nb_layers_to_add; i++) {
      cladding.push(makeDefaultLayer(side, 3 - nb_layers_to_add + i + 1));
    }
    return cladding;
  };
  
  export const makeLayer = (
    side,
    index,
    layerThickness,
    layerName,
    layerColor
  ) => {
    let layer = {};
    layer[side + index.toString()] = layerThickness;
    layer[side + index.toString() + "_" + "Name"] = layerName;
    layer[side + index.toString() + "_" + "Color"] = layerColor;
    return layer;
  };
  
  export const parseLayers = (layers, attributesId) => {
    let layers_nb = layers.length;
    let firstCladding = true;
    let claddingA = [],
      claddingB = [],
      rails = [],
      frames = [];
    for (let j = 0; j < layers_nb; j++) {
      let layerAttributes = layers[j].subAttributes;
      let layerContent = layerAttributes.find(
        (e) => e.aid === attributesId.layerContent
      ).values[0].value;
      let layerThickness = layerAttributes.find(
        (e) => e.aid === attributesId.layerThickness
      ).values[0].numericValue;
      let layerName = layerAttributes.find(
        (e) => e.aid === attributesId.layerName
      ).values[0].value;
      if (layerContent === "Plaque de plâtre") {
        // api.windowHandler.showDevTools();
  
        let layerColor = layerAttributes.find(
          (e) => e.aid === attributesId.layerColor
        ).values[0].value;
        console.log(layerColor);
        if (firstCladding) {
          claddingA.push(
            makeLayer("E", j + 1, layerThickness, layerName, layerColor)
          );
        } else {
          claddingB.push(
            makeLayer("I", layers_nb - j, layerThickness, layerName, layerColor)
          );
        }
      } else {
        firstCladding = false;
      }
    }
    claddingB.reverse();
    return {
      claddingA: addDefaultLayer(claddingA, "E"),
      claddingB: addDefaultLayer(claddingB, "I"),
      rails,
      frames,
    };
  };
  
  export const parseSystem = (
    systemAndChildren,
    attributesId,
    Reverse = false,
    Flipped = false
  ) => {
    let system = systemAndChildren.find((obj) =>
      obj.types.includes("Solution Product")
    ).attributes;
    let products = systemAndChildren.filter((obj) =>
      obj.types.includes("Product")
    );
    console.log(attributesId.framesSpace);
    console.log(system);
    console.log(system.find((e) => e.aid === attributesId.framesSpace));
    let framesSpace =
      system.find((e) => e.aid === attributesId.framesSpace) &&
      system.find((e) => e.aid === attributesId.framesSpace).values[0]
        .numericValue;
    // console.log(system.find(e => e.aid === attributesId.plateWidth));
  
    let systemName =
      system.find((e) => e.aid === attributesId.systemName) &&
      system.find((e) => e.aid === attributesId.systemName).values[0].value;
    
    let layers = system.filter((e) => e.aid === attributesId.layers)
      ? system.filter((e) => e.aid === attributesId.layers)
      : [];
    layers.sort(function (a, b) {
      let a_number = a.subAttributes.find(
        (e) => e.aid === attributesId.layerNumber
      ).values[0].numericValue;
      let b_number = b.subAttributes.find(
        (e) => e.aid === attributesId.layerNumber
      ).values[0].numericValue;
      return a_number - b_number;
    });
    let sgRails;
    // let sgRails = system.find(e => e.aid === attributesId.rails).values[0].value;
  
    if (attributesId.rails) {
      for (let i = 0; i < attributesId.rails.length; i++) {
        let attribute = system.find((e) => e.aid === attributesId.rails[i]);
        if (attribute) {
          let name = attribute.values[0].value;
          if (name) {
            if (name.startsWith("Rail") || name.startsWith("Cornière")) {
              sgRails = name;
            }
          }
        }
      }
    }
  
    let sgFrames;
    if (attributesId.frames) {
      for (let i = 0; i < attributesId.frames.length; i++) {
        let attribute = system.find((e) => e.aid === attributesId.frames[i]);
        if (attribute) {
          let name = attribute.values[0].value;
          if (name) {
            if (name.startsWith("Montant")) {
              sgFrames = name;
            }
          }
        }
      }
    }
    // let sgFrames =system.find(e => e.aid === attributesId.frames).values[0].value;
  
    // api.windowHandler.showDevTools();
    // console.log('////');
    // console.log(system.find(e => e.aid === attributesId.doubleFraming).values[0]);
  
    let systemElems = attributesId && parseLayers(layers, attributesId);
    let plateName =
      systemElems.claddingB[0].I1_Name || systemElems.claddingA[0].E1_Name;
  
    let plateProduct = products.find(
      (product) => product.translation === plateName
    );
    let plateWidth = attributesId && plateProduct && plateProduct.attributes.find(
      (e) => e.aid === attributesId.plateWidth
    ).values[0].numericValue;
    // api.windowHandler.showDevTools();
    // console.log(plateWidth);
    // console.log(system.find(e => e.aid === attributesId.doubleFraming).values[0])
    let result = {
      systemName,
      framesSpace: framesSpace * 1000,
      plateWidth: plateWidth * 1000,
      E1: systemElems.claddingA[0].E1,
      E2: systemElems.claddingA[1].E2,
      E3: systemElems.claddingA[2].E3,
      I1: systemElems.claddingB[0].I1,
      I2: systemElems.claddingB[1].I2,
      I3: systemElems.claddingB[2].I3,
      E1_Name: systemElems.claddingA[0].E1_Name,
      E2_Name: systemElems.claddingA[1].E2_Name,
      E3_Name: systemElems.claddingA[2].E3_Name,
      I1_Name: systemElems.claddingB[0].I1_Name,
      I2_Name: systemElems.claddingB[1].I2_Name,
      I3_Name: systemElems.claddingB[2].I3_Name,
      E1_Color: systemElems.claddingA[0].E1_Color,
      E2_Color: systemElems.claddingA[1].E2_Color,
      E3_Color: systemElems.claddingA[2].E3_Color,
      I1_Color: systemElems.claddingB[0].I1_Color,
      I2_Color: systemElems.claddingB[1].I2_Color,
      I3_Color: systemElems.claddingB[2].I3_Color,
      Reverse,
      Flipped,
      FreeEnd: "PLACO",
      Lining: systemName && systemName.includes("Doublage") ? true : false,
      railsSpace: 0,
      staggering: plateWidth * 1000 > 900 ? true : false,
      doubleFraming: system.find((e) => e.aid === attributesId.doubleFraming)
        ? system.find((e) => e.aid === attributesId.doubleFraming).values[0]
            .value.toLowerCase() === "simple"
          ? false
          : true
        : false,
      doubleAmount: false,
      Frame1_Name: sgFrames,
      Rail1_Name: sgRails,
      SAA: systemName && systemName.includes("SAA") ? true : false,
      SAD: systemName && systemName.includes("SAD") ? true : false,
    };
  
    if (result.E1 === "None") {
      let swapper;
  
      swapper = result.I1;
      result.I1 = result.E1;
      result.E1 = swapper;
  
      swapper = result.I1_Name;
      result.I1_Name = result.E1_Name;
      result.E1_Name = swapper;
  
      swapper = result.I2;
      result.I2 = result.E2;
      result.E2 = swapper;
  
      swapper = result.I2_Name;
      result.I2_Name = result.E2_Name;
      result.E2_Name = swapper;
  
      swapper = result.I3;
      result.I3 = result.E3;
      result.E3 = swapper;
  
      swapper = result.I3_Name;
      result.I3_Name = result.E3_Name;
      result.E3_Name = swapper;
  
      swapper = result.I1_Color;
      // result.I1_Color = result.E1_Color;
      result.E1_Color = swapper;
  
      swapper = result.I2_Color;
      // result.I2_Color = result.E2_Color;
      result.E2_Color = swapper;
  
      swapper = result.I3_Color;
      // result.I3_Color = result.E3_Color;
      result.E3_Color = swapper;
    }
  
    return result;
  };
  
  export const parseSystem0 = (
    system,
    attributesId,
    Reverse = false,
    Flipped = false
  ) => {
    let framesSpace = system.find((e) => e.aid === attributesId.framesSpace)
      .values[0].numericValue;
    let plateWidth = system.find((e) => e.aid === attributesId.plateWidth)
      .values[0].numericValue;
    let systemName = system.find((e) => e.aid === attributesId.systemName)
      .values[0].value;
    let layers = system.filter((e) => e.aid === attributesId.layers);
    layers.sort(function (a, b) {
      let a_number = a.subAttributes.find(
        (e) => e.aid === attributesId.layerNumber
      ).values[0].numericValue;
      let b_number = b.subAttributes.find(
        (e) => e.aid === attributesId.layerNumber
      ).values[0].numericValue;
      return a_number - b_number;
    });
    let sgRails = system.find((e) => e.aid === attributesId.rails).values[0]
      .value;
    let sgFrames = system.find((e) => e.aid === attributesId.frames).values[0]
      .value;
  
    let systemElems = parseLayers(layers, attributesId);
  
    let result = {
      systemName,
      framesSpace: framesSpace * 1000,
      plateWidth,
      E1: systemElems.claddingA[0].E1,
      E2: systemElems.claddingA[1].E2,
      E3: systemElems.claddingA[2].E3,
      I1: systemElems.claddingB[0].I1,
      I2: systemElems.claddingB[1].I2,
      I3: systemElems.claddingB[2].I3,
      E1_Name: systemElems.claddingA[0].E1_Name,
      E2_Name: systemElems.claddingA[1].E2_Name,
      E3_Name: systemElems.claddingA[2].E3_Name,
      I1_Name: systemElems.claddingB[0].I1_Name,
      I2_Name: systemElems.claddingB[1].I2_Name,
      I3_Name: systemElems.claddingB[2].I3_Name,
      Reverse,
      Flipped,
      Lining: systemName.includes("Doublage") ? true : false,
      railsSpace: 0,
      staggering: plateWidth > 900 ? true : false,
      doubleFraming:
        system.find((e) => e.aid === attributesId.doubleFraming).values[0] ===
        "simple"
          ? false
          : true,
      doubleAmount: false,
      Frame1_Name: sgFrames,
      Rail1_Name: sgRails,
      SAA: systemName.includes("SAA") ? true : false,
      SAD: systemName.includes("SAD") ? true : false,
    };
  
    if (result.E1 === "None") {
      let swapper;
  
      swapper = result.I1;
      result.I1 = result.E1;
      result.E1 = swapper;
  
      swapper = result.I1_Name;
      result.I1_Name = result.E1_Name;
      result.E1_Name = swapper;
  
      swapper = result.I2;
      result.I2 = result.E2;
      result.E2 = swapper;
  
      swapper = result.I2_Name;
      result.I2_Name = result.E2_Name;
      result.E2_Name = swapper;
  
      swapper = result.I3;
      result.I3 = result.E3;
      result.E3 = swapper;
  
      swapper = result.I3_Name;
      result.I3_Name = result.E3_Name;
      result.E3_Name = swapper;
    }
  
    return result;
  };
  
  export const getSystemData = async (
    bdd,
    systemIDs,
    Reverse = false,
    Flipped = false
  ) => {
    let firstSystemID = systemIDs[0];
    let attributes = await getPlacoSystemDetailsWithChildren(
      bdd,
      firstSystemID
    ).then(function (response) {
      return response.data.context.attributeDependencies[0].attributes;
    });
    let systemData = [];
    let attributesId = getLayoutAttributesAid(attributes);
    let nb = systemIDs.length;
    for (let i = 0; i < nb; i++) {
      let system = await getPlacoSystemDetailsWithChildren(
        bdd,
        systemIDs[i]
      ).then(function (response) {
        // console.log(response.data.objects);
        // let allAttributes = response.data.objects.filter(object => object.types.includes("Product")).map(object => object.attributes);
        return response.data.objects;
      });
      systemData.push(parseSystem(system, attributesId, Reverse, Flipped));
    }
    // console.log(systemData);
    return systemData;
  };
  
  export const drawWall = (setGroupNumber, increment) => async (layoutData) => {
    // console.log(layoutData);
    let groups = layoutData.data;
    let groupsNb = groups.length;
    // console.log(setGroupNumber);
    setGroupNumber && setGroupNumber(groupsNb);
    // alert(groupsNb);
    for (let g = 0; g < groupsNb; g++) {
      let wallNb = groups[g].length;
      // alert(wallNb);
      for (let i = 0; i < wallNb; i++) {
        try {
          await api.familyEditor.setParams([
            {
              Id: groups[g][i].id,
              Params: [{ Name: "Processed", Type: "YesNo", Value: 1 }],
            },
          ]);
          await api.familyEditor.setParams([
            {
              Id: groups[g][i].id,
              Params: [
                {
                  Name: "SG_System",
                  Type: "Text",
                  Value: groups[g][i].technicalName,
                },
              ],
            },
          ]);
          if (groups[g][i].rails.length)
            await api.familyEditor.placeFamiliesAtPoints(groups[g][i].rails);
          if (groups[g][i].flipped_rails.length)
            await api.familyEditor.placeFamiliesAtPoints(
              groups[g][i].flipped_rails
            );
          if (groups[g][i].frames.length) {
            let simpleFrames = groups[g][i].frames.filter(
              (frame) => frame.FamilyName === "Ossature"
            );
            let doubleFrames = groups[g][i].frames.filter(
              (frame) => frame.FamilyName === "Ossature-Double"
            );
            if (simpleFrames.length) {
              await api.familyEditor.placeFamiliesAtPoints(simpleFrames);
            }
            if (doubleFrames.length) {
              await api.familyEditor.placeFamiliesAtPoints(doubleFrames);
            }
          }
          groups[g][i].plasterboards.length &&
            (await api.familyEditor.placeFamiliesAtPoints(
              groups[g][i].plasterboards
            ));
        } catch (ex) {
          console.log(ex);
        }
      }
      // alert(g);
      increment && increment();
    }
    // increment();
  };
  
  export const setFiltersAid = (filters, attributes) => {
    let sortedFilters = {};
    return filters.map((filter) => {
      return {
        ...filter,
        aid: attributes.find((att) => filter.pimAttribute === att.technicalName)
          .aid,
      };
    });
  };
  
  export const setFiltersOptions = (filters, systemsDetails) => {
    filters = [...filters];
    systemsDetails.forEach((system) => {
      filters.forEach((filter) => {
        filter.options = filter.options || [];
        let attrValue = system[filter.name];
        if (attrValue !== undefined) {
          if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
        } else {
        }
      });
    });
    return filters.map((filter) => {
      filter.options.sort((a, b) => {
        if (filter.pimAttribute === "GFR-Fire protection") {
          return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
        } else {
          return a - b;
        }
      });
      return filter;
    });
  };
  
  export const addFilterFields = (systemsDetails, filters) => {
    return systemsDetails.map((sys) => {
      filters.map((filter) => {
        let attr = sys.attributes.find((attr) => attr.aid === filter.aid);
        if (attr) {
          let attrValue;
          if (filter.type === "Numeric") attrValue = attr.values[0].numericValue;
          else {
            attrValue = attr.values[0].value;
          }
          sys[filter.name] = attrValue;
        } else {
          sys[filter.name] = undefined;
        }
      });
      return sys;
    });
  };
  
  export const getDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
  
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + yyyy;
  };
  
  export const isArrayNull = (arr) => {
    return arr === null || arr === undefined ? [] : arr;
  };
  
  export const checkIfAnyProcessed = async (groups) => {
    let isprocessed = false;
    let groupsNb = groups.length;
    for (let g = 0; g < groupsNb && !isprocessed; g++) {
      let wallNb = groups[g].length;
      for (let i = 0; i < wallNb && !isprocessed; i++) {
        try {
          let data = await api.familyEditor.getParams([
            {
              Id: groups[g][i].id,
              Params: [{ Name: "Processed", Type: "YesNo" }],
            },
          ]);
          if (data.result === null || data.result === 0) {
            isprocessed = false;
          } else if (data.result === 1) {
            isprocessed = true;
          }
        } catch (ex) {
          isprocessed = false;
          console.log(ex);
        }
      }
      //increment && increment();
    }
    return isprocessed;
  };
  
  export const filterElements2 = (filters, partitionsDetails) => {
    return filters.reduce(
      (acc, current) => {
        if (current.options) {
          if (current.value) {
            if (current.value === "") return acc;
            let matchingDetails;
            switch (current.criterion) {
              case "GreaterThan":
                return acc.filter((el, i) => {
                  matchingDetails = partitionsDetails.find(
                    (details) => details.technicalName === el.technicalName
                  );
                  return (
                    matchingDetails.filterFields[current.name] >= current.value
                  );
                });
              case "Equals":
                return acc.filter((el, i) => {
                  matchingDetails = partitionsDetails.find(
                    (details) => details.technicalName === el.technicalName
                  );
                  return (
                    matchingDetails.filterFields[current.name] === current.value
                  );
                });
              case "Includes":
                return acc.filter((el, i) => {
                  matchingDetails = partitionsDetails.find(
                    (details) => details.technicalName === el.technicalName
                  );
                  return current.value.includes(
                    matchingDetails.filterFields[current.name]
                  );
                });
              default:
                return acc;
            }
          }
          return acc;
        }
        return acc;
      },
      [...partitionsDetails]
    );
  };
  