import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../Reducers";
import {
  SET_SELECTION_TREE,
  InitGroupSelectorAction,
  INIT_GROUP_SELECTOR,
  SelectTypeAction,
  SELECT_TYPE,
  SelectLevelAction,
  SELECT_LEVEL,
  SelectCeilingLevelAction,
  SELECT_CEILING_LEVEL,
  SelectCeilingTypeAction,
  SELECT_CEILING_TYPE,
  SET_CEILING_SELECTION_TREE,
} from "./types";

import { ID, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { TypeData, SelectedItem } from "../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../RevitJS/API";
import { elementTypes, levelData } from "./../../../../RevitJS/Helpers/index";

export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export interface InitLanguageAction {
  type: typeof INIT_LANGUAGE;
  language: Language;
}

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: Language;
}

export type LanguageAction = InitLanguageAction | SetLanguageAction;

export const setSelectionTree = (tree: ElementsTree) => {
  return {
    type: SET_SELECTION_TREE,
    tree,
  };
};

export const setCeilingSelectionTree = (tree: ElementsTree) => {
  return {
    type: SET_CEILING_SELECTION_TREE,
    tree,
  };
};

export const initGroupSelector = (
  tree: ElementsTree,
  ceilingTree: ElementsTree
): InitGroupSelectorAction => {
  return {
    type: INIT_GROUP_SELECTOR,
    tree,
    ceilingTree,
  };
};

export const selectType =
  (typeName: string): ThunkAction<void, DrawStore, unknown, SelectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const { typesData, selectedTypes } = getState();
    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    let selectedTypeIndex = typesData.findIndex(
      (type: any) => type.Type === typeName
    );

    if (typesData[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedTypes.filter(
        (type: any) => type !== typeName
      );
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedTypes, typeName];
    }

    dispatch({
      type: SELECT_TYPE,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectCeilingType =
  (
    typeName: string
  ): ThunkAction<void, DrawStore, unknown, SelectCeilingTypeAction> =>
  async (dispatch: any, getState: any) => {
    const { ceilingTypesData, selectedCeilingTypes } = getState();
    let typesDataCopy = [...ceilingTypesData];
    let selectedTypesCopy = [...selectedCeilingTypes];

    let selectedTypeIndex = ceilingTypesData.findIndex(
      (type: any) => type.Type === typeName
    );

    if (ceilingTypesData[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedCeilingTypes.filter(
        (type: any) => type !== typeName
      );
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedCeilingTypes, typeName];
    }

    dispatch({
      type: SELECT_CEILING_TYPE,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectLevel =
  (
    levelName: string
  ): ThunkAction<void, DrawStore, unknown, SelectLevelAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    let selectedLevelIndex = levelsData.findIndex(
      (level: any) => level.Name === levelName
    );

    if(selectedLevelIndex<0){
      selectedLevelsCopy = [];
    }else{
      if (levelsData[selectedLevelIndex].Checked) {
        levelsDataCopy[selectedLevelIndex].Checked = false;
        selectedLevelsCopy = selectedLevels.filter(
          (level: any) => level !== levelName
        );
      } else {
        levelsDataCopy[selectedLevelIndex].Checked = true;
        selectedLevelsCopy = [...selectedLevels, levelName];
      }
    }

    // if (levelsData[selectedLevelIndex].Checked) {
    //   levelsDataCopy[selectedLevelIndex].Checked = false;
    //   selectedLevelsCopy = selectedLevels.filter(
    //     (level: any) => level !== levelName
    //   );
    // } else {
    //   levelsDataCopy[selectedLevelIndex].Checked = true;
    //   selectedLevelsCopy = [...selectedLevels, levelName];
    // }

    dispatch({
      type: SELECT_LEVEL,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const selectCeilingLevel =
  (
    levelName: string
  ): ThunkAction<void, DrawStore, unknown, SelectCeilingLevelAction> =>
  async (dispatch: any, getState: any) => {
    const { ceilingLevelsData, selectedCeilingLevels, ceilingSelectionTree } =
      getState();
    let levelsDataCopy = [...ceilingLevelsData];
    let selectedLevelsCopy = [...selectedCeilingLevels];

    let selectedLevelIndex = ceilingLevelsData.findIndex(
      (level: any) => level.Name === levelName
    );
    
    if(selectedLevelIndex<0){
      selectedLevelsCopy = [];
    }else{
      if (ceilingLevelsData[selectedLevelIndex].Checked) {
        levelsDataCopy[selectedLevelIndex].Checked = false;
        selectedLevelsCopy = selectedCeilingLevels.filter(
          (level: any) => level !== levelName
        );
      } else {
        levelsDataCopy[selectedLevelIndex].Checked = true;
        selectedLevelsCopy = [...selectedCeilingLevels, levelName];
      }
    }
    
    // if (ceilingLevelsData[selectedLevelIndex].Checked) {
    //   levelsDataCopy[selectedLevelIndex].Checked = false;
    //   selectedLevelsCopy = selectedCeilingLevels.filter(
    //     (level: any) => level !== levelName
    //   );
    // } else {
    //   levelsDataCopy[selectedLevelIndex].Checked = true;
    //   selectedLevelsCopy = [...selectedCeilingLevels, levelName];
    // }

    dispatch({
      type: SELECT_CEILING_LEVEL,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(ceilingSelectionTree, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, DrawStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "French" as Language; //(await api.queries.getRevitLanguage()) as Language;
      localStorage.setItem("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };

export const toogleLevels =
  (): ThunkAction<void, DrawStore, unknown, SelectLevelAction> =>
  async (dispatch: any, getState: any) => {
    let state = getState();
    let data = state.selectionTree;
    let levelsData = state.levelsData;
    let typesData = state.typesData;
    let selectedLevels = state.selectedLevels;

    let copy = [...levelsData];
    let selectedTypes: any = [];
    let nbLevelsSelected = selectedLevels.length;
    if (nbLevelsSelected) {
      selectedLevels = [];
      selectedTypes = [];
      copy = levelsData.map((levelData: any) => {
        return { ...levelData, Checked: false };
      });
    } else {
      copy = levelsData.map((levelData: any) => {
        return { ...levelData, Checked: true };
      });
      selectedLevels = levelsData.map((levelData: any) => levelData.Name);
      selectedTypes = [];
    }
    levelsData = copy;
    typesData = elementTypes(levelData(data, selectedLevels));
    dispatch({
      type: "SELECT_LEVEL",
      selectedLevels,
      selectedTypes,
      levelsData,
      typesData,
    });
  };

export const toogleCeilingLevels =
  (): ThunkAction<void, DrawStore, unknown, SelectCeilingLevelAction> =>
  async (dispatch: any, getState: any) => {
    let state = getState();
    let data = state.ceilingSelectionTree;
    let levelsData = state.ceilingLevelsData;
    let typesData = state.ceilingTypesData;
    let selectedLevels = state.selectedCeilingLevels;

    let copy = [...levelsData];
    let selectedTypes: any = [];
    let nbLevelsSelected = selectedLevels.length;
    if (nbLevelsSelected) {
      selectedLevels = [];
      selectedTypes = [];
      copy = levelsData.map((levelData: any) => {
        return { ...levelData, Checked: false };
      });
    } else {
      copy = levelsData.map((levelData: any) => {
        return { ...levelData, Checked: true };
      });
      selectedLevels = levelsData.map((levelData: any) => levelData.Name);
      selectedTypes = [];
    }
    levelsData = copy;
    typesData = elementTypes(levelData(data, selectedLevels));
    dispatch({
      type: "SELECT_CEILING_LEVEL",
      selectedLevels,
      selectedTypes,
      levelsData,
      typesData,
    });
  };

export const toogleTypes =
  (): ThunkAction<void, DrawStore, unknown, SelectTypeAction> =>
  async (dispatch: any, getState: any) => {
    let state = getState();
    let data = state.tree;
    let levelsData = state.levelsData;
    let typesData = state.typesData;
    let selectedLevels = state.selectedLevels;
    let selectedTypes = state.selectedTypes;
    let copy;
    let nbTypesSelected = selectedTypes.length;
    if (nbTypesSelected) {
      selectedTypes = [];
      copy = typesData.map((typeData: any) => {
        return { ...typeData, Checked: false };
      });
    } else {
      copy = typesData.map((typeData: any) => {
        return { ...typeData, Checked: true };
      });
      selectedTypes = typesData.map((typeData: any) => typeData.Type);
    }
    typesData = copy;
    dispatch({
      type: "SELECT_TYPE",
      typesData,
      selectedTypes,
    });
  };

export const toogleCeilingTypes =
  (): ThunkAction<void, DrawStore, unknown, SelectCeilingTypeAction> =>
  async (dispatch: any, getState: any) => {
    let state = getState();
    let ceilingTypesData = state.ceilingTypesData;
    let selectedTypes = state.selectedCeilingTypes;
    let copy;
    let nbTypesSelected = selectedTypes.length;
    if (nbTypesSelected) {
      selectedTypes = [];
      copy = ceilingTypesData.map((typeData: any) => {
        return { ...typeData, Checked: false };
      });
    } else {
      copy = ceilingTypesData.map((typeData: any) => {
        return { ...typeData, Checked: true };
      });
      selectedTypes = ceilingTypesData.map((typeData: any) => typeData.Type);
    }
    ceilingTypesData = copy;
    dispatch({
      type: "SELECT_CEILING_TYPE",
      typesData: ceilingTypesData,
      selectedTypes,
    });
  };

export const setElementType =
  (
    elementType: "wall" | "ceiling"
  ): ThunkAction<void, DrawStore, unknown, any> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: "SET_ELEMENT_TYPE",
      elementType,
    });
  };
