import React from "react";
import { Image, Button, Card } from "semantic-ui-react";

export class PlacoCard extends React.Component {
  baseImageUrl = this.props.config.REACT_APP_BDDURL.replace(
    "/plugins/authenticated",
    ""
  );
  render() {
    const { t } = this.props;
    return (
      <Card
        style={{
          cursor: "pointer",
          border:
            this.props.selectionBuffer !== null
              ? this.props.oid === this.props.selectionBuffer.oid
                ? `1px solid rgb(${100}, ${100}, ${255})`
                : "none"
              : "none",
        }}
      >
        <Card.Content
          onClick={(e) => {
            this.props.setSelectionBuffer(this.props.system);
          }}
        >
          <Image
            floated="left"
            size="tiny"
            src={this.baseImageUrl + this.props.image}
          />
          <Card.Content>{this.props.productName}</Card.Content>
        </Card.Content>
        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              color="blue"
              onClick={() => {
                // this.props.setDetailOid(this.props.oid);
                this.props.setSelectionBuffer(this.props.system);
                this.props.showSystemDetails();
                // this.props.setProductDetailName(this.props.productName);
              }}
            >
              {t("details")}
            </Button>
            <Button
              color="green"
              onClick={() => {
                this.props.setSelectionBuffer(this.props.system);
                this.props.showLayoutDetails();
                // this.props.setDetailOid(this.props.oid);
                // this.props.setProductDetailName(this.props.productName);
              }}
            >
              {t("configuration")}
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

PlacoCard.defaultProps = {
  productName: "Cloison Placostil® 120/70 Lisaflam® - EI 120 - 50dB - 4,95m",
  productImage:
    "http://placolog.placo.fr/solution/asset/img/is_cl_03_001/cover_is_cl_03_001.jpg",
};

// const mapStateToProps = state =>{
//     return{
//         selectionBuffer: state.selectionBuffer,
//         rowNumber: state.rowNumber,
//     }
// }

// export default connect(mapStateToProps, {setSelectionBuffer, showLayoutDetails, showSystemDetails, setDetailOid, setProductDetailName})(PlacoCard);
