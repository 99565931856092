import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import { acceptTermsOfUse } from "../../actions/userInfoActions";
import { AppWindowName, GENERAL_TERMS_OF_USE } from "../../assets/constants";
import { checkAcceptedGTOU } from "../../helpers/utils";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./termsOfUse.scss";

class TermsOfUse extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.toggleTermsOfUse = this.toggleTermsOfUse.bind(this);
    this.setTermsOfUse = this.setTermsOfUse.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  componentDidMount() {
    checkAcceptedGTOU() ? this.setTermsOfUse(true) : this.setTermsOfUse(false);
  }

  toggleTermsOfUse() {
    this.setTermsOfUse(!this.props.userInfo.acceptedTermsOfUse);
  }

  setTermsOfUse(value: boolean = false) {
    this.props.acceptTermsOfUse(value);
  }

  nextPage() {
    this.props.selectWindowToShow(AppWindowName.INTRODUCTION_VIDEO_WINDOW);
  }

  render() {
    let { userInfo, t } = this.props;
    return (
      <div id="terms-of-use-container">
        <Header
          displayName={t(GENERAL_TERMS_OF_USE) || "General terms of use"}
        ></Header>
        <Body
          acceptedTermsOfUse={userInfo.acceptedTermsOfUse}
          changedTermsOfUse={this.toggleTermsOfUse}
        ></Body>
        <Footer
          savedProjectsButtonDisabled={true}
          disabledNextButton={!userInfo.acceptedTermsOfUse}
          clickedNextButton={this.nextPage}
        ></Footer>
        {/* <button onClick={this.props.acceptTermsOfUse}></button> */}
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
  };
};

let mapDispatchToProps = {
  acceptTermsOfUse,
  selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {
  acceptedTermsOfUse: boolean;
};

export default connector(withTranslation(["pam"])(TermsOfUse));
