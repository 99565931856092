import { ThunkAction } from "redux-thunk";
import { PropertiesActionTypes } from "./types";
import {
  loadPropertiesRequest,
  loadPropertiesSuccess,
  restorePropertiesDetails,
  loadPropertiesError,
  selectProperty,
  defaultConfiguration,
} from "./actions";
import * as propertiesService from "../../services/properties.service";
import { FLOORSYSTEM_ID, WALLSYSTEM_ID } from "../../assets/constants";
import { DrawStore } from "../reducers";
import { getCurrentConfiguration } from "../../services/properties.service.local";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../../Common/assets/attributes.injection";
import {
  getDefaultConfigurationByType,
  setPAMAttributesModal,
} from "../../../Common/utils/attributes.injection.helpers";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";

type Effect = ThunkAction<any, DrawStore, any, PropertiesActionTypes>;

export const loadProperties =
  (t: any, config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadPropertiesRequest());
    let { systemCategoryID } = getState().properties;
    let selectedProperties: any = {};
    let systemProperties: any[] = [];
    await propertiesService
      .loadProperties(Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME), config)
      .then((properties: any[]) => {
        systemProperties = properties;
      })
      .catch(() => dispatch(loadPropertiesError()));
    await getCurrentConfiguration()
      .then((properties) => {
        selectedProperties = properties?.propertiesConfiguration;
        dispatch(defaultConfiguration(properties?.isDefaultConfiguration));
      })
      .catch(() => dispatch(loadPropertiesError()));
    if (!selectedProperties)
      selectedProperties = {
        // liningWallsProperties: getDefaultConfigurationByType(PAM_SYSTEM_TYPE_KEYS.LINING_WALLS, systemProperties) || [],
        // partitionsProperties: getDefaultConfigurationByType(PAM_SYSTEM_TYPE_KEYS.WALL, systemProperties) || [],
        // ceilingsProperties: getDefaultConfigurationByType(PAM_SYSTEM_TYPE_KEYS.CEILING, systemProperties) || [],
        wallsSystemProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.WALL,
            systemProperties
          ) || [],
        floorSystemProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.FLOOR,
            systemProperties
          ) || [],
      };

    const data = {
      wallsSystemProperties: setPAMAttributesModal(
        PAM_SYSTEM_TYPE_KEYS.WALL,
        systemProperties,
        selectedProperties.wallsSystemProperties
      ),
      floorSystemProperties: setPAMAttributesModal(
        PAM_SYSTEM_TYPE_KEYS.FLOOR,
        systemProperties,
        selectedProperties.floorSystemProperties
      ),
    };

    const currentPropertiesDetail =
      systemCategoryID === FLOORSYSTEM_ID
        ? data.floorSystemProperties
        : data.wallsSystemProperties;
    const currentSelectedProperties =
      systemCategoryID === FLOORSYSTEM_ID
        ? selectedProperties.floorSystemProperties
        : selectedProperties.wallsSystemProperties;

    dispatch(
      loadPropertiesSuccess(
        systemProperties,
        data,
        selectedProperties,
        currentSelectedProperties,
        currentPropertiesDetail
      )
    );
  };

export const restoreProperties =
  (typeId: number): Effect =>
  (dispatch: any, getState: any) => {
    dispatch(loadPropertiesRequest());
    let { data, selectedProperties } = getState().properties;
    if (typeId === FLOORSYSTEM_ID && data.floorSystemProperties.length > 0)
      dispatch(
        restorePropertiesDetails(
          data.floorSystemProperties,
          selectedProperties.floorSystemProperties,
          typeId
        )
      );
    else if (typeId === WALLSYSTEM_ID && data.wallsSystemProperties.length > 0)
      dispatch(
        restorePropertiesDetails(
          data.wallsSystemProperties,
          selectedProperties.wallsSystemProperties,
          typeId
        )
      );
  };

export const checkProperty =
  (property: any, checked: boolean): Effect =>
  (dispatch: any, getState: any) => {
    let {
      currentSelectedProperties,
      currentPropertiesDetail,
      selectedProperties,
      data,
      systemCategoryID,
    } = getState().properties;
    currentPropertiesDetail = currentPropertiesDetail.map((element: any) =>
      element.chapterData
        ? {
            ...element,
            chapterData: element.chapterData.map((prop: any) =>
              prop.categoryName === property.categoryName
                ? { ...prop, checked: checked }
                : prop
            ),
          }
        : element
    );
    currentSelectedProperties = checked
      ? currentSelectedProperties.some(
          (prop: any) => prop.categoryName === property.categoryName
        )
        ? [...currentSelectedProperties]
        : [...currentSelectedProperties, { ...property, checked: checked }]
      : [
          ...currentSelectedProperties.filter(
            (prop: any) => prop.categoryName !== property.categoryName
          ),
        ];

    data = {
      wallsSystemProperties:
        systemCategoryID === WALLSYSTEM_ID
          ? [...currentPropertiesDetail]
          : [...data.wallsSystemProperties],
      floorSystemProperties:
        systemCategoryID === FLOORSYSTEM_ID
          ? [...currentPropertiesDetail]
          : [...data.floorSystemProperties],
    };
    selectedProperties = {
      wallsSystemProperties:
        systemCategoryID === WALLSYSTEM_ID
          ? [...currentSelectedProperties]
          : [...selectedProperties.wallsSystemProperties],
      floorSystemProperties:
        systemCategoryID === FLOORSYSTEM_ID
          ? [...currentSelectedProperties]
          : [...selectedProperties.floorSystemProperties],
    };

    dispatch(
      selectProperty(
        currentSelectedProperties,
        currentPropertiesDetail,
        selectedProperties,
        data
      )
    );
  };

export const setDefaultConfiguration =
  (checked: boolean): Effect =>
  (dispatch: any, getState: any) => {
    dispatch(defaultConfiguration(checked));
  };
