import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ThemeProvider } from '@material-ui/styles';
import createTheme from '@material-ui/core/styles/createTheme'

import "./index.scss";

export default function AlertDialog({
  open,
  setOpen,
  progress,
  title,
  content,
  onClose,
}) {

 
//changing color for progress to blue
const theme = createTheme({
  palette: {
    primary:{
      main: '#0288d1',
    }
  },
});

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
      return;
    setOpen(false);
    onClose();
  };

  const handleCancel = () => {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {progress ? "Chargement des systèmes" : title}
        </DialogTitle>
        <DialogContent>
          {progress ? (
            <div className={"LinearProgress"}>
              <ThemeProvider theme={theme}>
              <LinearProgress />
              </ThemeProvider>
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
        {progress ? (<Button
            onClick={() => handleCancel()}
            className='loadActionButton detailActionButton'
            classes={{ root: "button", label: "buttonLabel" }}
            variant="contained"
            //color="primary"
          >
            {"CANCEL"}
          </Button> ) :(<Button
            onClick={() => handleClose()}
            className='loadActionButton detailActionButton'
            disabled={progress}
            classes={{ root: "button", label: "buttonLabel" }}
            variant="contained"
            //color="primary"
          >
            {"OK"}
          </Button>)}
        </DialogActions>
      </Dialog>
    </div>
  );
}
