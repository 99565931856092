import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SKIP_INTRO_TEXT } from "../../../assets/constants";
import intro from "../../../assets/intro-placeholder.png";
import AnimatedCheckbox from "../../common/animated-checkbox/AnimatedCheckbox";
import "./body.scss";

function Body({ skipIntro, skipIntroChanged, t, language }: Props) {
    return (
        <div id="intro-body-container">
            <div className="row">
                <div className="col-12 p-4">
                    <div id="intro-body-intro-container">
                        {/* <img id="logo" src={intro} alt="Logo placeholder" /> */}
                        <iframe style={{ display: language === "French" ? "block" : "none" }} src="https://cdnapisec.kaltura.com/p/387001/sp/38700100/embedIframeJs/uiconf_id/39074502/partner_id/387001?iframeembed=true&playerId=kaltura_player&entry_id=1_rq3w9hc0&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=fr&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_tev11rrt" width="100%" height="100%" allowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameBorder="0" title="Lecteur Kaltura" ></iframe>
                        <iframe style={{ display: language === "English" ? "block" : "none" }} src="https://cdnapisec.kaltura.com/p/387001/sp/38700100/embedIframeJs/uiconf_id/39074502/partner_id/387001?iframeembed=true&playerId=kaltura_player&entry_id=1_m1oeysz4&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=fr&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_zr3wz1y6" width="100%" height="100%" allowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameBorder="0" title="Lecteur Kaltura"></iframe>
                    </div>
                    <div className="row py-2">
                        {/* <div className="offset-6 col-6 d-flex justify-content-end">
                            <AnimatedCheckbox
                                label={t(SKIP_INTRO_TEXT)}
                                textColor={"#FF8C00"}
                                checked={skipIntro}
                                changed={skipIntroChanged}
                            ></AnimatedCheckbox>
                        </div> */}
                        {/* <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = WithTranslation & {
    skipIntro: boolean;
    skipIntroChanged: Function;
    language?: string;
};

export default withTranslation(["pam"])(Body);
