import { forEach, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Segment,
  Table,
  Grid,
  Icon,
  Button,
  Modal,
  Image,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import { SelectionStore } from "../../Selection/Reducers";
import ColorPicker from "./Colorisation/ColorPicker";
import { FunctionalityHeader } from "./FunctionalityHeader";
import { Routes } from "./root";
import wording from "../Resources/wording.json";
import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  initProductSelector,
  multiSelectBuffer,
  resetTempBuffer,
  savePlacoData,
  setFilterType,
  tempMultiSelectBuffer,
} from "../../Selection/Actions";
import { ProductDetailData } from "../../Selection/Types";
import { api } from "../../../../RevitJS/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { removeCloseWindow } from "../../CommonModules/Helpers/sessionRemoveCloseWindow";

export const PersonalColorTable = (props: any) => {
  const reduxState = useSelector((state: SelectionStore) => state);
  const [earsing] = useState<boolean>(false);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [savepopup, setSavepopup] = useState<boolean>(false);
  const [index, setIndex] = useState<Number>(0);
  const [color, setColor] = useState<any>({ r: "", g: "", b: "", a: "" });
  const [selectedSolutionData, setSelectedSolutionData] = useState<
    ProductDetailData[]
  >([]);
  const dispatch = useDispatch();
  useEffect(() => {
    async function getInitialData() {
      await loadDataFromStorage();
    }
    getInitialData();
  }, []);

  const loadDataFromStorage = async () => {
    setLoading(true);
    const data = await bimStorage.getItem(storageKey.PLACO_PRODUCTS);
    if (data && !reduxState.placoData.available) {
      let attributes = data.context.attributeDependencies[0].attributes;
      dispatch(savePlacoData(reduxState, attributes, data.objects, attributes));
    }

    setLoading(false);
  };

  const toggleModal = (action: boolean) => {
    setDisplayColorPicker(action);
    //see my default custom colors
    localStorage.setItem("personalize", "true");
    localStorage.removeItem("identification");
  };

  const colorChangeHandler = (color: any) => {
    let solutions;
    if (reduxState.tempMultiSelectionBuffer) {
      solutions = JSON.parse(
        JSON.stringify(reduxState.tempMultiSelectionBuffer)
      );
    } else if (reduxState.multiSelectionBuffer) {
      solutions = JSON.parse(JSON.stringify(reduxState.multiSelectionBuffer));
    }
    forEach(solutions, (value: any, indexValue: number) => {
      if (index === indexValue) {
        value.color = color;
      }
    });

    if (reduxState.tempMultiSelectionBuffer) {
      dispatch(tempMultiSelectBuffer(solutions));
    } else if (reduxState.multiSelectionBuffer) {
      dispatch(tempMultiSelectBuffer(solutions));
    }

    setColor({ r: color.r, g: color.g, b: color.b, a: color.a });
  };

  const advanceSelector = () => {
    let productSubType: any = "partitionWall";

    dispatch(setFilterType(productSubType));
    props.setRoute(Routes.PRODUCT_PAGE);
    dispatch(initProductSelector());
  };

  const toggleSavePopup = (action: boolean) => {
    if (
      reduxState.tempMultiSelectionBuffer &&
      reduxState.tempMultiSelectionBuffer.length > 0
    ) {
      setSavepopup(action);
    } else {
      dispatch(resetTempBuffer());
      props.setRoute(Routes.ROOT);
    }
  };

  const validateHandler = async () => {
    if (reduxState.tempMultiSelectionBuffer) {
      dispatch(multiSelectBuffer(reduxState.tempMultiSelectionBuffer));
      await bimStorage.setItem(
        storageKey.REPERAGE_PERSONALISE_COLOR,
        JSON.stringify(reduxState.tempMultiSelectionBuffer)
      );

      //dispatch(tempMultiSelectBuffer([]));
      dispatch(resetTempBuffer());
      props.setRoute(Routes.ROOT);
    } else if (reduxState.tempMultiSelectionBuffer === null) {
      props.setRoute(Routes.ROOT);
    }
    localStorage.setItem("isModification", "false");
    removeCloseWindow();
    if (localStorage.getItem("reperageDefault")) {
      localStorage.removeItem("reperageDefault");
    }
  };

  useEffect(() => {
    getRecorededData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.tempMultiSelectionBuffer, reduxState.multiSelectionBuffer]);

  const getRecorededData = () => {
    let selData: ProductDetailData[] = [];

    let selectOther: any = [];
    let selectplafond: any = [];
    // if (reduxState.tempMultiSelectionBuffer && reduxState.tempMultiSelectionBuffer.length > 0) {
    if (reduxState.tempMultiSelectionBuffer) {
      forEach(
        reduxState.tempMultiSelectionBuffer,
        (value: any, index: number) => {
          if (!selData.some((s: any) => s.oid === value.oid)) {
            selData.push(value);
          }
        }
      );
    } else if (reduxState.multiSelectionBuffer) {
      forEach(reduxState.multiSelectionBuffer, (value: any, index: number) => {
        selData.push(value);
      });
    }

    forEach(selData, (seld: ProductDetailData) => {
      if (!seld.translation.includes("Plafond")) {
        selectOther.push(seld);
      } else {
        selectplafond.push(seld);
      }
    });

    selData = [...selectOther, ...selectplafond];
    setSelectedSolutionData(selData);
  };

  const deleteDocumentData = async (oid: any) => {
    let solutions;
    if (
      reduxState.tempMultiSelectionBuffer &&
      reduxState.tempMultiSelectionBuffer.length > 0
    ) {
      solutions = JSON.parse(
        JSON.stringify(reduxState.tempMultiSelectionBuffer)
      );
    } else if (reduxState.multiSelectionBuffer) {
      solutions = JSON.parse(JSON.stringify(reduxState.multiSelectionBuffer));
    }

    let index = solutions.findIndex((a: any) => a.oid === oid);
    if (index !== -1) {
      solutions.splice(index, 1);
    }
    dispatch(tempMultiSelectBuffer(solutions));
    localStorage.setItem("isModification", "true");
  };

  if (earsing || loading) {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content="Chargement en cours..."></Loader>
      </Dimmer>
    );
  }

  return (
    <div style={{ position: "relative", height: "calc(100vh - 25px)" }}>
      <FunctionalityHeader
        Icon={reduxState.functionalityIcon}
        name={wording.reperagelowercase[reduxState.language]}
        subheader={wording.customColors[reduxState.language]}
      />
      {/* MODAL FOR MAIN COLORPICKER */}
      {displayColorPicker && (
        <ColorPicker
          displayColorPicker={displayColorPicker}
          toggleModal={toggleModal}
          language={reduxState.language}
          currentColor={color}
          colorChangeHandler={colorChangeHandler}
          config={reduxState.config}
        ></ColorPicker>
      )}

      {/* COLORIZATION TABLE */}
      <Segment basic style={{ height: "calc(100vh - 30vh)", overflow: "auto" }}>
        <Table celled>
          <Table.Header>
            <Table.Row style={{ textAlign: "center" }}>
              <Table.HeaderCell style={{ width: "80%" }}>
                {wording.systems[reduxState.language]}{" "}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "20%" }}>
                {wording.colorChoice[reduxState.language]}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedSolutionData.length > 0 ? (
              map(selectedSolutionData, (solution: any, index: any) => {
                let productName:any = solution?.attributes?.find((a:any) => a.technicalName === "A-Solution product name")?.values[0]?.value;
            
                return (
                  <Table.Row>
                    <Table.Cell style={{ width: "80%" }}>
                      {productName}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "center", width: "20%" }}>
                      <div style={{ display: "inline-flex" }}>
                        <div className="swatch">
                          <div
                            style={
                              solution.color && {
                                width: "36px",
                                height: "20px",
                                borderRadius: "2px",
                                marginTop: "7px",
                                background: `rgba(${solution.color.r}, ${solution.color.g}, ${solution.color.b}, ${solution.color.a})`,
                              }
                            }
                          />
                        </div>
                        <div>
                          <Image
                            src={require("../Resources/Paintbucket.png")}
                            style={{ paddingLeft: "15px", cursor: "pointer" }}
                            onClick={() => {
                              toggleModal(true);
                              setColor(solution.color);
                              setIndex(index);
                            }}
                          />
                          <div
                            style={
                              solution.color && {
                                width: "25px",
                                height: "5px",
                                borderRadius: "2px",
                                margin: "auto",
                                background: `rgba(${solution.color.r}, ${solution.color.g}, ${solution.color.b}, ${solution.color.a})`,
                              }
                            }
                          />
                        </div>
                        {/* <Image
                          src={require("../Resources/trash.png")}
                          style={{
                            paddingLeft: "10px",
                            cursor: "pointer",
                            width: "30px",
                            height: "20px",
                            top: "5px",
                          }}
                          onClick={() => deleteDocumentData(solution.oid)}
                        /> */}
                        <div
                          className="trashIcon"
                          style={{ marginLeft: 6, marginTop: 6 }}
                          onClick={() => deleteDocumentData(solution.oid)}
                        >
                          <Popup
                            trigger={
                              <FontAwesomeIcon icon={faTrash as IconProp} />
                            }
                            content="Supprimer"
                            on="hover"
                            inverted
                            size="mini"
                            position="bottom right"
                          />
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="2" style={{ width: "100%" }}>
                  <div className="noData">
                    {wording.noCustomColorSaved[reduxState.language]}
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
      {/* <Segment style={{ border: "none", boxShadow: "none", paddingBottom: "0px" }}> */}
      <Grid columns={1} stackable>
        <div
          style={{ width: "300px", cursor: "pointer", marginLeft: "10px" }}
          onClick={() => {
            advanceSelector();
          }}
        >
          <Icon name="plus circle" color="blue" size="big" />
          <span style={{ color: "rgb(14,110,184)" }}>
            {wording.addAnotherSystem[reduxState.language]}
          </span>
        </div>
      </Grid>

      {/* </Segment> */}

      <div
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 0,
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <Button
          color="orange"
          onClick={() => {
            localStorage.removeItem("reperageDefault");
            toggleSavePopup(true);
            //props.setRoute(Routes.ROOT);
            //this.props.resetBuffer();
          }}
        >
          {wording.back[reduxState.language]}
        </Button>
        <Button
          color="blue"
          //disabled={!reduxState.tempMultiSelectionBuffer}
          disabled={
            reduxState.multiSelectionBuffer?.length === 0 &&
            reduxState.tempMultiSelectionBuffer === null
          }
          onClick={() => {
            validateHandler();
          }}
        >
          {" "}
          {wording.record[reduxState.language]}
        </Button>
      </div>

      <Modal open={savepopup} size="mini" dimmer="blurring">
        <Modal.Description
          style={{ textAlign: "center", padding: "15px 10px" }}
        >
          <p>{wording.message[reduxState.language]}</p>
        </Modal.Description>
        <div style={{ margin: "10px 0px", textAlign: "center" }}>
          <Button
            color="orange"
            size="tiny"
            onClick={() => {
              props.setRoute(Routes.ROOT);
              dispatch(resetTempBuffer());
              //this.props.resetBuffer();
            }}
          >
            {wording.no[reduxState.language]}
          </Button>
          <Button
            size="tiny"
            type="submit"
            primary
            onClick={() => {
              validateHandler();
              toggleSavePopup(false);
            }}
          >
            {wording.yes[reduxState.language]}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
