import { Draw2 } from "./Draw2";
import { Delete } from "./Delete";
import { Quantitatifs } from "./Quantitatifs";
import { Colorization } from "./Colorization";

export const pluginId = "91896076-56ea-42bb-a9b6-1da281d4eef6";

export const CLT = {
  TechnicalName: "CLT",
  Name: "",
  Icon: "/plugin_new_logo/ecophon-new-logo_icon.png",
  ShortDesc: {
    French: "Outil de calepinage Ecophon Ceiling",
    English: "Ecophon Ceiling Layout Tool",
    German: "Ecophon Ceiling Layout Tool",
    Italian: "Ecophon Ceiling Layout Tool",
  },
  Id: pluginId,
  Color: "#1A171B",
  Functionalities: [Draw2, Delete, Colorization, Quantitatifs],
};
