import React, { Component } from "react";
import { connect } from "react-redux";
import { Routes } from "./root";
import MetresDownloader from "./ExcelExport/MetresDownloader";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { setprojectData } from "../../Selection/Actions/index";
import { setLanguage } from "../Actions";
import { initLanguage } from "../../Calpinage/Actions";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";
interface Props {
  route: Routes;
  setRoute: any;
  Icon: any;
  projectData: initProjectData;
  setprojectData: any;

  setLanguage: any;
  initLanguage: any;
  setConfig: any;
}

export class AppBody extends Component<Props> {
  componentDidMount = async () => {
    // api.windowsHandler.showDevTools();
    this.props.initLanguage();
    this.fetchProjectId();
    //this.props.setLanguage(localStorage.getItem("savedLanguage"))
    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  fetchProjectId = async () => {
    if (this.props.projectData.ProjectId === "") {
      let projectData: initProjectData = await api.queries.getSetProjectData();

      if (projectData.ProjectId === "") {
        // this.toggleModal();
      } else {
        this.props.setprojectData(projectData);
      }
    }
  };
  render() {
    switch (this.props.route) {
      case Routes.ROOT:
        return (
          <>
            {" "}
            <FunctionalityHeader name={"Quantitatifs"} Icon={this.props.Icon} />
            <MetresDownloader
              routes={this.props.route}
              setRoute={this.props.setRoute}
            />
          </>
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    Icon: state.functionalityIcon,
    projectData: state.projectData,
  };
};

export default connect(mapStateToProps, {
  setprojectData,
  setLanguage,
  initLanguage,
  setConfig,
})(AppBody);
