import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";
import Papa from "papaparse";
import "./body.scss";

function Body({ files, setFiles, t }: IProps) {
    const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            for (let i = 0; i < event.target.files.length; i++) {
                let file = event.target.files[i];
                Papa.parse(file, {
                    complete: function (results, originalFile) {
                        setFiles({ file: originalFile, data: results.data });
                    },
                });
            }
        }
    };

    function dropHandler(event: React.DragEvent<HTMLDivElement>) {
        event.currentTarget.classList.remove("file-drag-over");
        event.preventDefault();
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === "file") {
                    let file = event.dataTransfer.items[i].getAsFile();
                    if (file) {
                        Papa.parse(file, {
                            complete: function (results, originalFile) {
                                console.log(results);
                                setFiles({
                                    file: originalFile,
                                    data: results.data,
                                });
                            },
                        });
                    }
                }
            }
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                Papa.parse(event.dataTransfer.files[i], {
                    complete: function (results, originalFile) {
                        console.log(results);
                        setFiles({ file: originalFile, data: results.data });
                    },
                });
            }
        }
    }

    function dragOverHandler(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.currentTarget.classList.add("file-drag-over");
    }

    function dragOutHandler(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.currentTarget.classList.remove("file-drag-over");
    }

    return (
        <>
            <div id="csv-import-component-body-container">
                <div className="row mx-1 my-1">
                    <div
                        id="filenames-div"
                        className="col-11 p-0"
                        onDrop={dropHandler}
                        onDragOver={dragOverHandler}
                        onDragLeave={dragOutHandler}
                    >
                        {files.length === 0 && (
                            <p
                                className="text-center"
                                style={{ color: "gray" }}
                            >
                                {t("EMPTY_FILES_LIST")}
                            </p>
                        )}
                        {files.map(
                            (
                                fileObject: { file: File; data: any },
                                index: number
                            ) => {
                                return (
                                    <div
                                        key={`${fileObject.file.name}-${index}`}
                                    >
                                        <div className="file-name-div px-3">
                                            {" "}
                                            {fileObject.file.name}{" "}
                                        </div>
                                        <div
                                            className="separator"
                                            style={{ width: "95%" }}
                                        ></div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <div className="col-1">
                        <Button
                            as="label"
                            htmlFor="file"
                            type="button"
                            className="float-right text-center"
                            style={{ padding: ".5em" }}
                            primary
                        >
                            {/* {t("SELECT_FILES")} */}
                            <Icon
                                name="download"
                                size="large"
                                className="m-auto"
                            />
                        </Button>

                        <input
                            type="file"
                            id="file"
                            accept=".xlsx,.xlsm,.xls,.xml,.csv"
                            hidden
                            onChange={fileChange}
                            multiple
                        ></input>
                    </div>
                </div>
            </div>
        </>
    );
}

type IProps = WithTranslation & {
    files: any[];
    setFiles: Function;
};

export default withTranslation(["pamCanalisation"])(Body);
