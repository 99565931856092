import React, { Component } from "react";
import { Button, Dimmer, Icon, Segment, Card, Image } from "semantic-ui-react";
import { CompactPicker, GithubPicker, SketchPicker } from "react-color";
import AddFavColor from "./AddFavColor";
import difference from "lodash/difference";
import { bimStorage, storageKey } from "../../../../BIMStore";

interface Props {
  wording: {
    otherColors: { [key: string]: string };
    ok: { [key: string]: string };
    favouriteColors: { [key: string]: string };
    standardColors: { [key: string]: string };
    cancel: { [key: string]: string };
    addfavouriteColor: { [key: string]: string };
  };
  language: string;
  displayColorPicker: boolean;
  toggleModal: any;
  currentColor: { r: any; g: any; b: any; a: any };
  colorChangeHandler: any;
  config: any;
}

interface State {
  color: { r: any; g: any; b: any; a: any };
  openCustomColorModal: boolean;
  openFavColorModal: boolean;
  favColor: string[];
  selectedFavColor: any;
  isFavChanged: boolean;
}

export class ColorPicker extends Component<Props, State> {
  state = {
    color: { r: 241, g: 112, b: 19, a: 1 },
    openCustomColorModal: false,
    openFavColorModal: false,
    favColor: [],
    selectedFavColor: undefined,
    isFavChanged: true,
  };

  public static defaultProps = {
    wording: require("./Resources/wording.json"),
  };

  handleClose = () => {
    this.props.toggleModal(false);
  };

  handleChange = (color: any) => {
    this.setState({ color: color.rgb });
    this.setState({ selectedFavColor: undefined });
    localStorage.setItem("isModification", "true");
  };

  handleFavChange = (color: any) => {
    this.setState({ color: color.rgb });
    this.setState({ selectedFavColor: color.hex });
    localStorage.setItem("isModification", "true");
  };

  removeFavColor = () => {
    if (this.state.selectedFavColor) {
      let favColors = this.state.favColor;
      let index = favColors.findIndex(
        (f: any) => f === this.state.selectedFavColor
      );
      if (index !== -1) {
        favColors.splice(index, 1);
      }

      //this.setState({ favColor: favColors });
      this.addFavColor(favColors);
      this.setState({ color: this.props.currentColor });
    }
    localStorage.setItem("isModification", "true");
  };

  handleButtonClick = (clickType: string) => {
    if (clickType === "ok") {
      this.props.colorChangeHandler(this.state.color);
    } else if (clickType === "cancel") {
      // this.props.colorChangeHandler(this.props.currentColor);
      this.setState({ color: this.props.currentColor });
    }
    this.props.toggleModal(false);
    localStorage.setItem("isModification", "false");
  };

  addFavColor = (temp: string[]) => {
    let filteredArray = difference(temp, this.state.favColor);
    bimStorage.setItem(
      storageKey.FAV_COLORS,
      JSON.stringify([...this.state.favColor, ...filteredArray])
    );

    this.setState({ favColor: [...this.state.favColor, ...filteredArray] });
    this.props.toggleModal(true);
    this.setState({ isFavChanged: true });
    this.setState({ openFavColorModal: false });
    localStorage.setItem("isModification", "true");
  };

  setIsFavChanged = (isFavChanged: boolean) => {
    this.setState({ isFavChanged: isFavChanged });
    localStorage.setItem("isModification", "true");
  };

  async componentDidMount() {
    // setting the default value to currentColor
    let savedFavColors = await bimStorage.getItem(storageKey.FAV_COLORS);
    this.setState({
      color: this.props.currentColor,
      favColor: savedFavColors || [],
    });
    this.setState({ isFavChanged: true });
  }
  defaultImage = (e: any) => {
    e.target.src = "/dummy-img.png";
  }
  render() {
    return (
      <div>
        {/* MODAL FOR MAIN COLORPICKER */}
        <Dimmer active={this.props.displayColorPicker} className="top-0">
          <Segment
            style={{
              // width: 1090,
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <Card className="cardFloat">
              <Card.Content
                header={this.props.wording.favouriteColors[this.props.language]}
                style={{ backgroundColor: "#ebebeb", fontSize: "smaller" }}
              />
              <Card.Content extra style={{ display: "flex", height: "70px" }}>
                <div style={{ width: "30px" }}>
                  <Icon
                    name="plus circle"
                    size="large"
                    color="blue"
                    onClick={() => {
                      this.setState({ openFavColorModal: true });
                      //this.props.toggleModal(false);
                    }}
                  />

                  <Icon
                    style={{ bottom: "-13px", position: "relative" }}
                    name="minus circle"
                    size="large"
                    color="red"
                    onClick={() => {
                      this.removeFavColor();
                    }}
                  />
                </div>
                <div
                  style={{
                    height: "60px",
                    overflowX: "auto",
                  }}
                >
                  <GithubPicker
                    width="100%"
                    triangle="hide"
                    colors={this.state.favColor}
                    onChange={this.handleFavChange}
                    className="standard-colorpicker"
                  />
                </div>
              </Card.Content>
              <Card.Content
                header={this.props.wording.standardColors[this.props.language]}
                style={{ backgroundColor: "#ebebeb", fontSize: "smaller" }}
              />
              <Card.Content />
              <CompactPicker
                color={this.state.color}
                onChange={this.handleChange}
              />
              <Card.Content extra>
                <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  <Image
                    src={require("./Resources/morecolor.png")}
                    style={{ paddingLeft: "2px" }}
                    onError={this.defaultImage}
                  />
                  <span
                    onClick={() => {
                      this.setState({ openCustomColorModal: true });
                      //this.props.toggleModal(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {this.props.wording.otherColors[this.props.language]}
                  </span>
                </div>
              </Card.Content>
              <Card.Content extra style={{ textAlign: "center" }}>
                <Icon
                  name="check square"
                  size="large"
                  color="green"
                  onClick={() => {
                    this.handleButtonClick("ok");
                    this.props.toggleModal(false);
                  }}
                />
                <Icon
                  name="window close"
                  size="large"
                  color="red"
                  onClick={() => {
                    this.handleButtonClick("cancel");
                    this.props.toggleModal(false);
                  }}
                />
              </Card.Content>
            </Card>
          </Segment>
        </Dimmer>

        {/* MODAL FOR EXTENDED SKETCH COLORPICKER */}
        <Dimmer active={this.state.openCustomColorModal}>
          <Segment
            style={{
              // width: 1090,
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <Card style={{ float: "right", width: "230px" }}>
              <Card.Content extra>
                <SketchPicker
                  color={this.state.color}
                  onChange={this.handleChange}
                  presetColors={[]}
                />
              </Card.Content>
              <Card.Content extra>
                <Button
                  onClick={() => {
                    this.setState({ openCustomColorModal: false });
                    this.handleButtonClick("ok");
                  }}
                >
                  {this.props.wording.ok[this.props.language]}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ openCustomColorModal: false });
                    this.handleButtonClick("cancel");
                  }}
                >
                  {this.props.wording.cancel[this.props.language]}
                </Button>
              </Card.Content>
            </Card>
          </Segment>
        </Dimmer>
        <AddFavColor
          openFavColorModal={this.state.openFavColorModal}
          closeFavColorModal={() => {
            this.setState({ openFavColorModal: false });
          }}
          toggleModal={this.props.toggleModal}
          language={this.props.language}
          favColor={this.state.favColor}
          addFavColor={this.addFavColor}
          isFavChanged={this.state.isFavChanged}
          setIsFavChanged={this.setIsFavChanged}
        />
      </div>
    );
  }
}

export default ColorPicker;
