import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../Common/assets/attributes.injection";
import {
  FILTER_VALUE_TYPE,
  FILTER_CRITERION_TYPE,
  FILTER_CHOICE_TYPE,
} from "../assets/constants";

export const placoFilters = {
  Walls: [
    {
      name: "Type de cloison",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.EQUALS,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      name: "Nom du parement",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.NAME_OF_THE_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Hauteur limite (m)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LIMIT_HEIGHT_M,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      name: "Protection Incendie",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_RESISTANCE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Affaiblissement acoustique (DB)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.ACOUSTIC_ATTENUATION_RA_ON_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },

    {
      name: "Epaisseur totale (mm)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.THICKNESS,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Réaction au feu du parement plaque",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.REACTION_TO_FIRE_OF_THE_SHEET_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },

    {
      name: "Résistance aux chocs (J)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.IMPACT_RESISTANCE_J,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Montants (s ou d)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    // Do not remove "Layout possible"
    // {
    //     aid:"",
    //     name: "Layout possible",
    //     pimAttribute: "GFR-Layout possible",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    /// Do not remove "Works name"
    // {
    //     aid: "",
    //     name: "Works name",
    //     pimAttribute: "GFR-Works name",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    // {
    // aid: "",
    //     name: "Nombre de plaques par parement",
    //     pimAttribute: "GFR-No. Of plasterboard per facing",
    //     type: FILTER_VALUE_TYPE.NUMERIC,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null
    // },
  ],
  LiningWalls: [
    {
      name: "Type de doublage",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.DUBBING_TYPE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.EQUALS,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      name: "Nom du parement",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.NAME_OF_THE_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Hauteur limite (m)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LIMIT_HEIGHT_M,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      name: "Protection incendie en + du support",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Isolation acoustique en + du support (DB)",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },

    {
      name: "Type de mur support",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_SUPPORT_WALL,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Résistance aux chocs (J)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.IMPACT_RESISTANCE_J,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Montants (s ou d)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Encombrement au sol (mm)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.SPACE_ON_GROUND_MM,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    // Do not remove "Layout possible"
    // {
    //     aid:"",
    //     name: "Layout possible",
    //     pimAttribute: "GFR-Layout possible",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    // Do not remove "Works name"
    // {
    //     aid: "",
    //     name: "Works name",
    //     pimAttribute: "GFR-Works name",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    // values are not numeric -> not compatible with Slider
    // {
    //     name: "Résistance thermique (R)",
    //     pimAttribute: "GFR-Thermal Resistance",
    //     type: FILTER_VALUE_TYPE.NUMERIC,
    //     criterion: FILTER_CRITERION_TYPE.BETWEEN,
    //     choiceType: FILTER_CHOICE_TYPE.SLIDER,
    //     options: null,
    //     value: [null, null],
    // },
    // {
    // aid: "",
    //     name: "Epaisseur totale (mm)",
    //     pimAttribute: "GFR-Total thickness in mm",
    //     type: FILTER_VALUE_TYPE.NUMERIC,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    // {
    // aid: "",
    //     name: "Réaction au feu du parement plaque",
    //     pimAttribute: "GFR-Reaction to fire of board cover",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    // {
    //     name: "Pare vapeur",
    //     pimAttribute: "GFR-Vapor barrier",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null
    // },
  ],
  Ceiling: [
    {
      name: "Type de plafond",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CEILING_TYPE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Protection Incendie",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_RESISTANCE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Affaiblissement acoustique (DB)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.ACOUSTIC_ATTENUATION_RA_ON_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      name: "Réaction au feu du parement plaque",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.REACTION_TO_FIRE_OF_THE_SHEET_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Support (plancher)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_SUPPORT_FLOOR,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },

    // {
    //   name: "Entraxe ossature primaire (m)",
    //   pimAttribute:
    //     PAM_ATTRIBUTES_TECHNICAL_NAME.PRIMARY_FRAME_CENTER_DISTANCE_M,
    //   type: FILTER_VALUE_TYPE.NUMERIC,
    //   criterion: FILTER_CRITERION_TYPE.GREATERTHEN,
    //   choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
    //   options: null,
    //   value: null,
    // },
    {
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    // {
    //   name: "Poids maxi isolant (kg/m²)",
    //   pimAttribute:
    //     PAM_ATTRIBUTES_TECHNICAL_NAME.MAXIMUM_INSULATING_MATERIAL_WEIGHT,
    //   type: FILTER_VALUE_TYPE.NUMERIC,
    //   criterion: FILTER_CRITERION_TYPE.BETWEEN,
    //   choiceType: FILTER_CHOICE_TYPE.SLIDER,
    //   options: null,
    //   value: null,
    // },

    {
      name: "Type de plafond démontable",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CEILING_TYPE_OF_GRID,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Disposition des perforations",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.RANDOM_REGULAR_PERFORATIONS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    // Do not remove "Layout possible"
    // {
    //     aid:"",
    //     name: "Layout possible",
    //     pimAttribute: "GFR-Layout possible",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    /// Do not remove "Works name"
    // {
    //     aid: "",
    //     name: "Works name",
    //     pimAttribute: "GFR-Works name",
    //     type: FILTER_VALUE_TYPE.TEXT,
    //     criterion: FILTER_CRITERION_TYPE.INCLUDES,
    //     choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
    //     options: null,
    //     value: null,
    // },
    // {aid: "",
    //     name: "Prix fourni posé en € (batichiffrage®)",
    //     pimAttribute: "GFR-Price (bc)",
    //     type: FILTER_VALUE_TYPE.NUMERIC,
    //     criterion: "GreaterThan",
    //     choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
    //     options: null,
    //     value: null
    // },
  ],
  GainesTechniques: [
    {
      name: "Type de gaines techniques",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.DUBBING_TYPE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.EQUALS,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      name: "Nom du parement",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.NAME_OF_THE_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Hauteur limite (m)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LIMIT_HEIGHT_M,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      name: "Protection incendie en + du support",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Isolation acoustique en + du support (DB)",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },

    {
      name: "Type de mur support",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_SUPPORT_WALL,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Résistance aux chocs (J)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.IMPACT_RESISTANCE_J,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Montants (s ou d)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Encombrement au sol (mm)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.SPACE_ON_GROUND_MM,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
  ],
};
