import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "4ea24f46-8b97-416d-89f8-52d5b8f59719";

export const vueOrigineTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const VueDoRigineRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const VueOrigine: Functionality = {
  Name: {
    English: "Vue d’origine",
    Indonesian: "Vue d’origine",
  },
  Trigger: vueOrigineTrigger,
  ShortDesc: {
    English: "Afficher le modèle de base",
    Indonesian: "Afficher le modèle de base",
  },
  Id: functionalityId,
  Icon: "/Facade/vueDOrigine.png",
  RootComponent,
  ParentId: "0",
};
