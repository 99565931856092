import { filter } from "lodash";
import React, { Fragment } from "react"
import { Checkbox, Table } from "semantic-ui-react"

interface Props {
    moduleData: any[];
    selectionId: any[];
    selectedRecords: any[];
    setSelectedRecords: any;
    documentType: string | undefined;
    translations: any;
    language: string;
}
export const DossierComponent = (prop: Props) => {

    const filteredSelection = (id: string) => filter(prop.moduleData, function (selection: any) {
        return selection.documentId === id && selection.documentType === prop.documentType && selection.documentId !== null;
    });

    const selectedRecord = (e: any, data: any) => {
        let r = prop.selectedRecords;

        prop.moduleData.map((item: any, i: number) => {
            if (item.documentId === data.documentId) {
                let index = prop.selectedRecords.findIndex((f: any) => f.documentId === data.documentId);
                if (index === -1) {
                    item.checked = true;
                    data.checked = true;
                    r.push(data);
                } else {
                    item.checked = false;
                    data.checked = false;
                    r.splice(index, 1);
                }

                prop.setSelectedRecords(r);
            }
        });
    }


    return (
        <Table className="tableborderexport dossier-export-table fixed-table-header">
            <Table.Header className="header">
                <Table.Row>
                    <Table.HeaderCell className="width5" textAlign="center"></Table.HeaderCell>
                    <Table.HeaderCell className="width70">{prop.translations[prop.language].tableHeader.documentName}</Table.HeaderCell>
                    <Table.HeaderCell className="width25" textAlign="center">{prop.translations[prop.language].tableHeader.modifiedDate}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Fragment>
                    {
                        prop.selectionId !== undefined && prop.selectionId.length > 0 ?
                            prop.selectionId.map((row: any, index: number) => {
                                let selectedData = filteredSelection(row);
                                return selectedData !== undefined && selectedData.length > 0 ?
                                    (<Table.Row>
                                        <Table.Cell className="windowCursor width5">
                                            <Checkbox type="checkbox" defaultChecked={selectedData[0].checked} onChange={(e) => selectedRecord(e, selectedData[0])} />
                                        </Table.Cell>
                                        <Table.Cell className="windowCursor width70">
                                            {selectedData[0].name}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center" className="windowCursor width25">
                                            {selectedData[0].documentDate}
                                        </Table.Cell>
                                    </Table.Row>) : <></>
                            })
                            : <Table.Row>
                                <Table.Cell colSpan={3} textAlign="center" className="windowCursor" >
                                    {prop.translations[prop.language].message.noDossier}
                                </Table.Cell>
                            </Table.Row>
                    }
                </Fragment>
            </Table.Body>
        </Table>  
    )
}
