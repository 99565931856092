import {
  getPlacoSystemsDetailsAndRelation,
  getAnySystemDetailsWithChildren,
} from "../Requests";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import {
  ProductDetailData,
  AttributeDescriptor,
  LayoutAttributesAid,
  PimAttribute,
  BddLayer,
  AttributeValue,
} from "../../../../RevitJS/Types/BddTypes";
import {
  ArticleAttributesAid,
  ArticleComboList,
  ExtractDetail,
  cladding,
  ProductDetails,
  GFR,
  ExcelExtract,
  PackageAttributesAid,
  Revit,
} from "../Model";

import map from "lodash/map";
import find from "lodash/find";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import get from "lodash/get";

import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";

// export const CategoryMap: Array<{ type: string; category: string }> = [
//   { type: "plaque", category: "Products » Plasterboards" },
//   { type: "montant", category: "Amount" },
//   { type: "montant", category: "Montant" },
//   { type: "rail", category: "Rail" },
//   { type: "rail", category: "Cr2" },
//   {
//     type: "resilent",
//     category:
//       "Products » Accessories » Accessories Finishes » Strip Joints » Pp Tapes",
//   },
//   { type: "coating", category: "Products » Coated Joint » Coatings" },
//   { type: "wool", category: "Products » Generic Products » Wool" },
//   {
//     type: "joints",
//     category: "Products » Accessories » Accessories Finishes Ls » Strip Joints",
//   },
//   {
//     type: "screw",
//     category:
//       "Products » Accessories » Accessories Fasteners » Accessories Fasteners » Screw",
//   },
// ];

export const CategoryMap: Array<{ type: string; category: string }> = [
  { type: "plaque", category: "Products » Plasterboards" },
  { type: "montant", category: "Amount" },
  { type: "montant", category: "Montant" },
  { type: "rail", category: "Rail" },
  { type: "rail", category: "Cr2" },
  {
    type: "resilent",
    category:
      "Products » Accessories » Accessories Finishes » Strip Joints » Pp Tapes",
  },
  { type: "coating", category: "Products » Coated Joint » Coatings" },
  { type: "wool", category: "Products » Generic Products » Wool" },
  {
    type: "joints",
    category: "Products » Accessories » Accessories Finishes Ls » Strip Joints",
  },
  { type: "joints", category: "Colle" },
  {
    type: "screw",
    category:
      "Products » Accessories » Accessories Fasteners » Accessories Fasteners » Screw",
  },
];

export const CategoryMapNonLayout = {
  plaque: [
    {
      PRODUCT_CODE: "PR00021530",
      PRODUCT_NAME: "Placo® Duo'Tech® 25",
    },
    {
      PRODUCT_CODE: "PR00021509",
      PRODUCT_NAME: "Placo® Phonique BA 13",
    },
    {
      PRODUCT_CODE: "PR00021475",
      PRODUCT_NAME: "Placoflam® BA 13",
    },
    {
      PRODUCT_CODE: "PR00021578",
      PRODUCT_NAME: "Placopan® 50",
    },
    {
      PRODUCT_CODE: "PR00021429",
      PRODUCT_NAME: "Placoplatre® BA 18",
    },
    {
      PRODUCT_CODE: "PR00022055",
      PRODUCT_NAME: "Gaineo® Marine 70/50",
    },
    {
      PRODUCT_CODE: "PR00021418",
      PRODUCT_NAME: "Placoplatre® BA 13",
    },
    {
      PRODUCT_CODE: "PR00021394",
      PRODUCT_NAME: "Placoplatre® BA 18S",
    },
    {
      PRODUCT_CODE: "PR00021479",
      PRODUCT_NAME: "Placoflam® BA 15",
    },
    {
      PRODUCT_CODE: "PR00021409",
      PRODUCT_NAME: "Placomarine® BA 13",
    },
    {
      PRODUCT_CODE: "PR00021396",
      PRODUCT_NAME: "Placoplatre® BA 18S Marine",
    },
    {
      PRODUCT_CODE: "PR00021562",
      PRODUCT_NAME: "Placo® Activ'Air® BA 13",
    },
    {
      PRODUCT_CODE: "PR00021505",
      PRODUCT_NAME: "Glasroc® H Ocean 13",
    },
    {
      PRODUCT_CODE: "PR00041322",
      PRODUCT_NAME: "Placo® X-Ray Protection",
    },
    {
      PRODUCT_CODE: "PR00021401",
      PRODUCT_NAME: "Placoplatre® BA 25",
    },
    {
      PRODUCT_CODE: "PR00022109",
      PRODUCT_NAME: "Caroplatre® Standard 5",
    },
    {
      PRODUCT_CODE: "PR00022115",
      PRODUCT_NAME: "Caroplatre® Standard 7",
    },
    {
      PRODUCT_CODE: "PR00022117",
      PRODUCT_NAME: "Caroplatre® Standard 10",
    },
    {
      PRODUCT_CODE: "PR00022119",
      PRODUCT_NAME: "Caroplatre® Hydro 5",
    },
    {
      PRODUCT_CODE: "PR00022125",
      PRODUCT_NAME: "Caroplatre® Hydro 7",
    },
    {
      PRODUCT_CODE: "PR00022123",
      PRODUCT_NAME: "Caroplatre® Hydro 10",
    },
    {
      PRODUCT_CODE: "PR00022114",
      PRODUCT_NAME: "Caroplatre® Alvéolé 7",
    },
    {
      PRODUCT_CODE: "PR00022135",
      PRODUCT_NAME: "Caroplatre® Hydro Alvéolé 7",
    },
    {
      PRODUCT_CODE: "PR00021583",
      PRODUCT_NAME: "Placopan® Marine 50",
    },
    {
      PRODUCT_CODE: "PR00021585",
      PRODUCT_NAME: "Placopan® Premium 50",
    },
    {
      PRODUCT_CODE: "PR00021586",
      PRODUCT_NAME: "Placopan® Premium Marine 50",
    },
  ],
  rail: [
    {
      PRODUCT_CODE: "PR00022193",
      PRODUCT_NAME: "Cornière Stil® CR2",
    },
    {
      PRODUCT_CODE: "PR00022196",
      PRODUCT_NAME: "Fourrure Stil® F 530",
    },
    {
      PRODUCT_CODE: "PR00022432",
      PRODUCT_NAME: "Rail 50",
    },
    {
      PRODUCT_CODE: "PR00022215",
      PRODUCT_NAME: "Rail Stil® F 530",
    },
    {
      PRODUCT_CODE: "PR00022209",
      PRODUCT_NAME: "Rail Stil® R 48",
    },
    {
      PRODUCT_CODE: "PR00027064",
      PRODUCT_NAME: "Rail C Stil® R30",
    },
    {
      PRODUCT_CODE: "PR00022248",
      PRODUCT_NAME: "Fourrure Hydrostil®+ F 530 500h",
    },
    {
      PRODUCT_CODE: "PR00022211",
      PRODUCT_NAME: "Rail Stil® R 70",
    },
    {
      PRODUCT_CODE: "PR00022205",
      PRODUCT_NAME: "Rail Stil® R 100",
    },
    {
      PRODUCT_CODE: "PR00022214",
      PRODUCT_NAME: "Rail Stil® R 90",
    },
    {
      PRODUCT_CODE: "PR00022228",
      PRODUCT_NAME: "Lisse Megastil® 50/30",
    },
    {
      PRODUCT_CODE: "PR00022447",
      PRODUCT_NAME: "Cornière d'angle métal",
    },
    {
      PRODUCT_CODE: "PR00022232",
      PRODUCT_NAME: "Cornière Megastil® 50/50",
    },
  ],
  montant: [
    {
      PRODUCT_CODE: "PR00022237",
      PRODUCT_NAME: "Montant High-Stil® 70",
    },
    {
      PRODUCT_CODE: "PR00022203",
      PRODUCT_NAME: "Montant Stil® M 48",
    },
    {
      PRODUCT_CODE: "PR00022207",
      PRODUCT_NAME: "Montant Stil® M 70",
    },
    {
      PRODUCT_CODE: "PR00022201",
      PRODUCT_NAME: "Montant Stil® M 100",
    },
    {
      PRODUCT_CODE: "PR00022204",
      PRODUCT_NAME: "Montant Stil® M 90",
    },
    {
      PRODUCT_CODE: "PR00022367",
      PRODUCT_NAME: "Eclisse High-Stil® 70",
    },
    {
      PRODUCT_CODE: "PR00027065",
      PRODUCT_NAME: "Bac C Stil® 30",
    },
  ],
  accessoires: [
    {
      PRODUCT_CODE: "PR00022558",
      PRODUCT_NAME: "MAP® Formule + 25 kg",
    },
    {
      PRODUCT_CODE: "PR00022433",
      PRODUCT_NAME: "Bande PP grand Rouleau",
    },
    {
      PRODUCT_CODE: "PR00022531",
      PRODUCT_NAME: "Placojoint® PR4 25kg",
    },
    {
      PRODUCT_CODE: "PR00022548",
      PRODUCT_NAME: "Placomix® Hydro",
    },
    {
      PRODUCT_CODE: "PR00022363",
      PRODUCT_NAME: "Sabot High-Stil®",
    },
    {
      PRODUCT_CODE: "PR00022384",
      PRODUCT_NAME: "Boulon TH 12/30",
    },
    {
      PRODUCT_CODE: "PR00022336",
      PRODUCT_NAME: "Patte Megaclip®",
    },
    {
      PRODUCT_CODE: "PR00022394",
      PRODUCT_NAME: "Vis Megastil® 6,3/25",
    },
    {
      PRODUCT_CODE: "PR00022398",
      PRODUCT_NAME: "Vis TTPC 25",
    },
    {
      PRODUCT_CODE: "PR00022400",
      PRODUCT_NAME: "Vis TTPC 45",
    },
    {
      PRODUCT_CODE: "PR00022399",
      PRODUCT_NAME: "Vis TTPC 55",
    },
    {
      PRODUCT_CODE: "PR00022434",
      PRODUCT_NAME: "Semelle 50",
    },
    {
      PRODUCT_CODE: "PR00022402",
      PRODUCT_NAME: "Vis TTPC 35",
    },
    {
      PRODUCT_CODE: "PR00022406",
      PRODUCT_NAME: "Vis TTPC 70",
    },
    {
      PRODUCT_CODE: "PR00022418",
      PRODUCT_NAME: "Clavette 50",
    },
    {
      PRODUCT_CODE: "PR00022404",
      PRODUCT_NAME: "Vis TTPC 90",
    },
    {
      PRODUCT_CODE: "PR00022282",
      PRODUCT_NAME: "Appui Rénovation Stil® F 530",
    },
    {
      PRODUCT_CODE: "PR00022401",
      PRODUCT_NAME: "Vis TRPF 13",
    },
    {
      PRODUCT_CODE: "PR00027351",
      PRODUCT_NAME: "Vis TRPF 25",
    },
    {
      PRODUCT_CODE: "PR00022288",
      PRODUCT_NAME: "Ruban résilient 45",
    },
    {
      PRODUCT_CODE: "PR00022420",
      PRODUCT_NAME: "Vis Hydrostil®+ TTPC 25 500h",
    },
    {
      PRODUCT_CODE: "PR00022520",
      PRODUCT_NAME: "Bande Hydro Tape",
    },
    {
      PRODUCT_CODE: "PR00022421",
      PRODUCT_NAME: "Vis Hydrostil®+ TTPC 41 500h",
    },
    {
      PRODUCT_CODE: "PR00022459",
      PRODUCT_NAME: "Bande résiliente Caroplatre® 5 en liège",
    },
    {
      PRODUCT_CODE: "PR00022573",
      PRODUCT_NAME: "Placol® 2 Heures 25kg",
    },
    {
      PRODUCT_CODE: "PR00058385",
      PRODUCT_NAME: "Patte à scellement",
    },
    {
      PRODUCT_CODE: "PR00022452",
      PRODUCT_NAME: "Bande Résiliente Caroplatre® 7 en liège",
    },
    {
      PRODUCT_CODE: "PR00022461",
      PRODUCT_NAME: "Bande résiliente Caroplatre® 10 en liège",
    },
    {
      PRODUCT_CODE: "PR00022577",
      PRODUCT_NAME: "Placol Hydro 25kg",
    },
    {
      PRODUCT_CODE: "PR00022423",
      PRODUCT_NAME: "Clavette Coupée 50 200",
    },
    {
      PRODUCT_CODE: "PR00022546",
      PRODUCT_NAME: "Placomix® Premium",
    },
    {
      PRODUCT_CODE: "PR00022550",
      PRODUCT_NAME: "Placomix® PRO",
    },
  ],
  wool: [
    {
      PRODUCT_CODE: "PR00021593",
      PRODUCT_NAME: "Placomur® Essentiel 0.55 13+20",
    },
    {
      PRODUCT_CODE: "PR00021602",
      PRODUCT_NAME: "Placomur® Essentiel 0.65 13+20",
    },
    {
      PRODUCT_CODE: "PR00021615",
      PRODUCT_NAME: "Placomur® Essentiel 1.10 13+40",
    },
    {
      PRODUCT_CODE: "PR00021674",
      PRODUCT_NAME: "Placomur® Essentiel Marine 2.15 13+80",
    },
    {
      PRODUCT_CODE: "PR00021704",
      PRODUCT_NAME: "Placomur® Performance Marine 2.55 13+80",
    },
    {
      PRODUCT_CODE: "PR00021718",
      PRODUCT_NAME: "Placomur® Performance Marine 2.65 13+100",
    },
    {
      PRODUCT_CODE: "PR00021751",
      PRODUCT_NAME: "Placomur® Performance Marine 3.15 13+100",
    },
    {
      PRODUCT_CODE: "PR00021768",
      PRODUCT_NAME: "Placomur® Performance Marine 3.40 13+100",
    },
    {
      PRODUCT_CODE: "PR00021789",
      PRODUCT_NAME: "Placomur® Performance Marine 3.80 13+120",
    },
    {
      PRODUCT_CODE: "PR00021804",
      PRODUCT_NAME: "Placomur® Performance Marine 4.10 13+120",
    },
    {
      PRODUCT_CODE: "PR00021823",
      PRODUCT_NAME: "Placomur® Performance Marine 4.40 13+140",
    },
    {
      PRODUCT_CODE: "PR00021921",
      PRODUCT_NAME: "Doublissimo® Performance 2.55 13+80",
    },
    {
      PRODUCT_CODE: "PR00021931",
      PRODUCT_NAME: "Doublissimo® Performance 2.75 13+80",
    },
    {
      PRODUCT_CODE: "PR00021954",
      PRODUCT_NAME: "Doublissimo® Performance 3.15 13+100",
    },
    {
      PRODUCT_CODE: "PR00021967",
      PRODUCT_NAME: "Doublissimo® Performance 3.40 13+100",
    },
    {
      PRODUCT_CODE: "PR00021989",
      PRODUCT_NAME: "Doublissimo® Performance 3.80 13+120",
    },
    {
      PRODUCT_CODE: "PR00022002",
      PRODUCT_NAME: "Doublissimo® Performance 4.10 13+120",
    },
    {
      PRODUCT_CODE: "PR00022016",
      PRODUCT_NAME: "Doublissimo® Performance 4.40 13+140",
    },
    {
      PRODUCT_CODE: "PR00022032",
      PRODUCT_NAME: "Doublissimo® Performance 4.75 13+140",
    },
    {
      PRODUCT_CODE: "PR00022037",
      PRODUCT_NAME: "Doublissimo® Performance 5.35 13+160",
    },
    {
      PRODUCT_CODE: "PR00021882",
      PRODUCT_NAME: "Placotherm®+ 6.55 13+140",
    },
    {
      PRODUCT_CODE: "PR00021854",
      PRODUCT_NAME: "Placotherm®+ Marine 1.90 13+40",
    },
    {
      PRODUCT_CODE: "PR00021863",
      PRODUCT_NAME: "Placotherm®+ Marine 2.80 13+60",
    },
    {
      PRODUCT_CODE: "PR00021867",
      PRODUCT_NAME: "Placotherm®+ Marine 3.75 13+80",
    },
    {
      PRODUCT_CODE: "PR00021872",
      PRODUCT_NAME: "Placotherm®+ Marine 4.70 13+100",
    },
    {
      PRODUCT_CODE: "PR00021876",
      PRODUCT_NAME: "Placotherm®+ Marine 5.60 13+120",
    },
    {
      PRODUCT_CODE: "PR00064167",
      PRODUCT_NAME: "Laine Isoconfort 35 ép. 80mm",
    },
    {
      PRODUCT_CODE: "PR00057207",
      PRODUCT_NAME: "Laine de verre épaisseur 45",
    },
    {
      PRODUCT_CODE: "PR00064265",
      PRODUCT_NAME: "Laine GR 32 roulé revêtu kraft ép. 100mm",
    },
    {
      PRODUCT_CODE: "PR00021831",
      PRODUCT_NAME: "Placomur® Performance 4.75 13+140",
    },
    {
      PRODUCT_CODE: "PR00021705",
      PRODUCT_NAME: "Placomur® Performance PV 2.55 13+80",
    },
    {
      PRODUCT_CODE: "PR00021722",
      PRODUCT_NAME: "Placomur® Performance PV 2.65 13+100",
    },
    {
      PRODUCT_CODE: "PR00021748",
      PRODUCT_NAME: "Placomur® Performance PV 3.15 13+100",
    },
    {
      PRODUCT_CODE: "PR00021770",
      PRODUCT_NAME: "Placomur® Performance PV 3.40 13+100",
    },
    {
      PRODUCT_CODE: "PR00021791",
      PRODUCT_NAME: "Placomur® Performance PV 3.80 13+120",
    },
    {
      PRODUCT_CODE: "PR00021803",
      PRODUCT_NAME: "Placomur® Performance PV 4.10 13+120",
    },
    {
      PRODUCT_CODE: "PR00021818",
      PRODUCT_NAME: "Placomur® Performance PV 4.40 13+140",
    },
    {
      PRODUCT_CODE: "PR00021899",
      PRODUCT_NAME: "Doublissimo® Essentiel 1.90 13+60",
    },
    {
      PRODUCT_CODE: "PR00061650",
      PRODUCT_NAME: "Laine PAR PHONIC Pro larg. 900mm ép. 45mm",
    },
    {
      PRODUCT_CODE: "PR00061660",
      PRODUCT_NAME: "Laine PAR PHONIC TECH ép. 30mm",
    },
    {
      PRODUCT_CODE: "PR00064266",
      PRODUCT_NAME: "Laine GR 32 roulé revêtu kraft ép. 120mm",
    },
    {
      PRODUCT_CODE: "PR00021833",
      PRODUCT_NAME: "Placomur® Performance PV 4.75 13+140",
    },
    {
      PRODUCT_CODE: "PR00021595",
      PRODUCT_NAME: "Placomur® Essentiel Marine 0.55 13+20",
    },
    {
      PRODUCT_CODE: "PR00021605",
      PRODUCT_NAME: "Placomur® Essentiel Marine 0.65 13+20",
    },
    {
      PRODUCT_CODE: "PR00021621",
      PRODUCT_NAME: "Placomur® Essentiel Marine 1.10 13+40",
    },
    {
      PRODUCT_CODE: "PR00021628",
      PRODUCT_NAME: "Placomur® Essentiel Marine 1.30 13+40",
    },
    {
      PRODUCT_CODE: "PR00021700",
      PRODUCT_NAME: "Placomur® Performance 2.55 13+80",
    },
    {
      PRODUCT_CODE: "PR00021714",
      PRODUCT_NAME: "Placomur® Performance 2.65 13+100",
    },
    {
      PRODUCT_CODE: "PR00021743",
      PRODUCT_NAME: "Placomur® Performance 3.15 13+100",
    },
    {
      PRODUCT_CODE: "PR00021772",
      PRODUCT_NAME: "Placomur® Performance 3.40 13+100",
    },
    {
      PRODUCT_CODE: "PR00021783",
      PRODUCT_NAME: "Placomur® Performance 3.80 13+120",
    },
    {
      PRODUCT_CODE: "PR00021801",
      PRODUCT_NAME: "Placomur® Performance 4.10 13+120",
    },
    {
      PRODUCT_CODE: "PR00021822",
      PRODUCT_NAME: "Placomur® Performance 4.40 13+140",
    },
    {
      PRODUCT_CODE: "PR00021922",
      PRODUCT_NAME: "Doublissimo® Performance PV 2.55 13+80",
    },
    {
      PRODUCT_CODE: "PR00021935",
      PRODUCT_NAME: "Doublissimo® Performance PV 2.75 13+80",
    },
    {
      PRODUCT_CODE: "PR00021953",
      PRODUCT_NAME: "Doublissimo® Performance PV 3.15 13+100",
    },
    {
      PRODUCT_CODE: "PR00021966",
      PRODUCT_NAME: "Doublissimo® Performance PV 3.40 13+100",
    },
    {
      PRODUCT_CODE: "PR00022035",
      PRODUCT_NAME: "Doublissimo® Performance Marine 4.75 13+140",
    },
    {
      PRODUCT_CODE: "PR00021856",
      PRODUCT_NAME: "Placotherm®+ 1.90 13+40",
    },
    {
      PRODUCT_CODE: "PR00021859",
      PRODUCT_NAME: "Placotherm®+ 2.80 13+60",
    },
    {
      PRODUCT_CODE: "PR00021865",
      PRODUCT_NAME: "Placotherm®+ 3.75 13+80",
    },
    {
      PRODUCT_CODE: "PR00021869",
      PRODUCT_NAME: "Placotherm®+ 4.70 13+100",
    },
    {
      PRODUCT_CODE: "PR00021877",
      PRODUCT_NAME: "Placotherm®+ 5.60 13+120",
    },
    {
      PRODUCT_CODE: "PR00061652",
      PRODUCT_NAME: "Laine Isoconfort 35 ép. 100mm",
    },
    {
      PRODUCT_CODE: "PR00061657",
      PRODUCT_NAME: "Laine PAR PHONIC TECH ép. 85mm",
    },
    {
      PRODUCT_CODE: "PR00021624",
      PRODUCT_NAME: "Placomur® Essentiel 1.30 13+40",
    },
    {
      PRODUCT_CODE: "PR00021646",
      PRODUCT_NAME: "Placomur® Essentiel 1.60 13+60",
    },
    {
      PRODUCT_CODE: "PR00021655",
      PRODUCT_NAME: "Placomur® Essentiel 1.90 13+60",
    },
    {
      PRODUCT_CODE: "PR00021677",
      PRODUCT_NAME: "Placomur® Essentiel 2.15 13+80",
    },
    {
      PRODUCT_CODE: "PR00021649",
      PRODUCT_NAME: "Placomur® Essentiel Marine 1.60 13+60",
    },
    {
      PRODUCT_CODE: "PR00021659",
      PRODUCT_NAME: "Placomur® Essentiel Marine 1.90 13+60",
    },
    {
      PRODUCT_CODE: "PR00021835",
      PRODUCT_NAME: "Placomur® Performance Marine 4.75 13+140",
    },
    {
      PRODUCT_CODE: "PR00021879",
      PRODUCT_NAME: "Doublissimo® Essentiel 1.30 13+40",
    },
    {
      PRODUCT_CODE: "PR00021988",
      PRODUCT_NAME: "Doublissimo® Performance PV 3.80 13+120",
    },
    {
      PRODUCT_CODE: "PR00022001",
      PRODUCT_NAME: "Doublissimo® Performance PV 4.10 13+120",
    },
    {
      PRODUCT_CODE: "PR00022022",
      PRODUCT_NAME: "Doublissimo® Performance PV 4.40 13+140",
    },
    {
      PRODUCT_CODE: "PR00022031",
      PRODUCT_NAME: "Doublissimo® Performance PV 4.75 13+140",
    },
    {
      PRODUCT_CODE: "PR00021926",
      PRODUCT_NAME: "Doublissimo® Performance Marine 2.55 13+80",
    },
    {
      PRODUCT_CODE: "PR00021937",
      PRODUCT_NAME: "Doublissimo® Performance Marine 2.75 13+80",
    },
    {
      PRODUCT_CODE: "PR00021956",
      PRODUCT_NAME: "Doublissimo® Performance Marine 3.15 13+100",
    },
    {
      PRODUCT_CODE: "PR00021964",
      PRODUCT_NAME: "Doublissimo® Performance Marine 3.40 13+100",
    },
    {
      PRODUCT_CODE: "PR00021990",
      PRODUCT_NAME: "Doublissimo® Performance Marine 3.80 13+120",
    },
    {
      PRODUCT_CODE: "PR00022003",
      PRODUCT_NAME: "Doublissimo® Performance Marine 4.10 13+120",
    },
    {
      PRODUCT_CODE: "PR00022020",
      PRODUCT_NAME: "Doublissimo® Performance Marine 4.40 13+140",
    },
    {
      PRODUCT_CODE: "PR00021880",
      PRODUCT_NAME: "Placotherm®+ Marine 6.55 13+140",
    },
  ],
};

const ProductArticleMap: { [key: string]: { [key: string]: string } } = {
  Vis: {
    "Vis Habito® 35": "Vis Habito 35/boite800pces",
    "Vis TTPC 25": "Vis TTPC 25/bte1500P",
    "Vis TTPC 35": "Vis TTPC 35/bte1500P",
    "Vis TTPC 45": "Vis TTPC 45/bte1500P",
  },
  Stil: {
    "Cornière Stil® CR2": "Cornière Stil® CR2 bot30L",
    "Rail Stil® F 530": "Rail Stil® F 530/235 bot20L",
    "Rail Stil® R 100": "Rail Stil® R 100/300 bot10L",
    "Rail Stil® R 150 GH": "Rail Stil® R 150/400 GH bot6L",
    "Rail Stil® R 36": "Rail Stil® R 36/300 bot10L",
    "Rail Stil® R 48": "Rail Stil® R 48/300 bot10L",
    "Rail Stil® R 62": "Rail Stil® R 62/300 bot10L",
    "Rail Stil® R 70": "Rail Stil® R 70/300 bot10L",
    "Rail Stil® R 90": "Rail Stil® R 90/300 bot10L",
  },
  Placojoint: {
    "Placojoint® PR4 25kg": "Placojoint® PR4 25kg 60s",
    "Placojoint® SN 25kg": "Placojoint® SN 25kg 60s",
  },
};

export const fetExtractnew = async (
  oid: ID,
  config: any
): Promise<ExcelExtract> => {
  const { Objects, AttributeDescriptors } = await detailedRequestHandler(
    oid,
    config
  );
  const { solutionsProducts, products, genericproduct, articles } =
    objectsSorter(Objects);

  let revitproductData: Revit[] = [];

  const attributeDict = attributeSelector(AttributeDescriptors);
  const artAttrDic = artAttributeSelector(AttributeDescriptors);

  return await analyzeProducts(
    [...products, ...genericproduct],
    articles,
    attributeDict,
    artAttrDic,
    config
  ).then((data) => {
    const filter = (name: string) => {
      return maptoPackagedArticle(data.filter((item) => item.type === name));
    };
    return {
      products: [...solutionsProducts, ...products, ...genericproduct],
      plaques: filter("plaque"),
      montants: filter("montant"),
      rails: filter("rail"),
      wools: filter("wool"),
      accessories: {
        coatings: filter("coating"),
        joints: filter("joints"),
        resilent: filter("resilent"),
        screws: filter("screw"),
      },
      productdictionary: attributeDict,
      articledictionary: artAttrDic,
      solutionProducts: solutionsProducts,
      revitData: revitproductData,
    };
  });
};

export const fetchExtracts = async (
  oid: ID,
  config: any
): Promise<ExtractDetail> => {
  const { Objects, AttributeDescriptors } = await detailedRequestHandler(
    oid,
    config
  );
  const { solutionsProducts, products, genericproduct, articles } =
    objectsSorter(Objects);
  const attributeDict = attributeSelector(AttributeDescriptors);
  let solutionProduct = solutionsProducts[0];
  const { attributes } = solutionProduct;
  const artAttrDic = artAttributeSelector(AttributeDescriptors);
  const { claddings } = extractCladding(
    attributes,
    AttributeDescriptors,
    articles,
    products,
    attributeDict,
    config
  );
  const montants = await extractMontant(
    products,
    articles,
    attributeDict,
    artAttrDic,
    config
  );
  const wools = [
    {
      product: genericproduct[0],
      GFR: [],
      type: "Laine",
      selectedarticle: -1,
      categorypath: "",
    },
  ];
  const screws = maptoPackagedArticle(
    await extractProducts("Vis", products, articles, attributeDict, config)
  );
  const rails = maptoPackagedArticle(
    await extractProducts("Stil", products, articles, attributeDict, config)
  );
  const joints = maptoPackagedArticle(
    await extractProducts("Bande", products, articles, attributeDict, config)
  );
  const resilents = maptoPackagedArticle(
    await extractProducts("Ruban", products, articles, attributeDict, config)
  );
  const coatings = maptoPackagedArticle(
    await extractProducts(
      "Placojoint",
      products,
      articles,
      attributeDict,
      "Joint",
      config
    )
  );

  const extDetails = {
    claddings,
    montants,
    wools,
    screws,
    rails,
    joints,
    resilents,
    coatings,
    products: [...products, ...genericproduct],
    solutionProduct,
  };

  return extDetails;
};

export const extractCladding = (
  attributes: PimAttribute[],
  AttributeDescriptors: AttributeDescriptor[],
  articles: ProductDetailData[],
  products: ProductDetailData[],
  attributeDict: LayoutAttributesAid,
  config: any
): { claddings: cladding[] } => {
  const layersAttributes = attributesFilterer(attributes, attributeDict);

  const { claddings } = layersSorter(layersAttributes, attributeDict);
  let bddLayers = claddingBuilder(claddings, attributeDict);
  let flags = new Set();
  const productCladding = bddLayers
    .filter((item) => {
      return flags.has(item.layerName) ? false : !!flags.add(item.layerName);
    })
    .map((item) => setLayerProduct(item, products));

  const claddingList: cladding[] = [];
  productCladding.forEach(async (cladding) => {
    const artAttrDic = artAttributeSelector(AttributeDescriptors);
    const gfrarticles = getProductArticleNames(
      cladding.product!,
      attributeDict
    );
    const gfrList: GFR[] = await getGFRArticles(gfrarticles, articles, config);
    const plaqueArray = getComboList(gfrList, artAttrDic);
    //const packagedArticle = await getPackagedArticles(cladding.product!.oid);
    claddingList.push({
      product: cladding.product!,
      GFR: gfrList,
      productComboList: plaqueArray.comboList,
      type: "Cladding",
      selectedarticle: -1,
      categorypath: "",
    });
  });
  return { claddings: claddingList };
};

const extractMontant = async (
  product: ProductDetailData[],
  articles: ProductDetailData[],
  attributeDict: LayoutAttributesAid,
  artAttrDic: ArticleAttributesAid,
  config: any
): Promise<
  {
    productComboList: ArticleComboList[];
    product: ProductDetailData;
    GFR: GFR[];
    type: string;
    categorypath: string;
    selectedarticle: number;
  }[]
> => {
  //const montant: montant={montantComboList:[],product:[]};
  const montant = await extractProducts(
    "Montant",
    product,
    articles,
    attributeDict,
    config
  );
  const montants = montant.map((mnt: ProductDetails) => {
    const list = getComboList(mnt.GFR, artAttrDic);

    return {
      ...mnt,
      productComboList: list.comboList,
    };
  });
  return montants;
};

const analyzeProducts = async (
  products: ProductDetailData[],
  articles: ProductDetailData[],
  prdAttrDict: LayoutAttributesAid,
  artAttrDict: ArticleAttributesAid,
  config: any
) => {
  let analyzedProducts: ProductDetails[] = [];
  for (const pr of products) {
    const categorypath = firstFromAttributeValue(
      getAttributeValue(pr, prdAttrDict.categorypath)
    );

    for (const iterator of CategoryMap) {
      if (categorypath.includes(iterator.category)) {
        let gfrList: GFR[] = [];
        if (pr.types[0] === "Product") {
          const gfrarticles = getProductArticleNames(pr, prdAttrDict);
          gfrList = await getGFRArticles2(
            gfrarticles,
            articles,
            artAttrDict,
            config
          );
        }

        const product: ProductDetails = {
          product: pr,
          GFR: gfrList,
          type: iterator.type,
          selectedarticle: -1,
          categorypath: categorypath,
        };
        analyzedProducts.push(product);
      }
    }
  }
  return analyzedProducts;
};

const extractProducts = async (
  name: string,
  product: ProductDetailData[],
  articles: ProductDetailData[],
  attributeDict: LayoutAttributesAid,
  config: any,
  name2?: string
): Promise<ProductDetails[]> => {
  let prod: ProductDetailData[] = [];
  if (!name2) {
    prod = product.filter((item) => item.translation.includes(name));
  } else {
    prod = product.filter(
      (item) =>
        item.translation.includes(name) || item.translation.includes(name2)
    );
  }

  const products: ProductDetails[] = [];

  for (const iterator of prod) {
    const gfrarticles = getProductArticleNames(iterator, attributeDict);
    const gfrList: GFR[] = await getGFRArticles(gfrarticles, articles, config);
    const product: ProductDetails = {
      product: iterator!,
      GFR: gfrList,
      type: name,
      selectedarticle: -1,
      categorypath: "",
    };
    products.push(product);
  }

  return products;
};

const getAttributeValue = (
  article: ProductDetailData,
  aid: string
): AttributeValue[] => {
  return article.attributes.find((attr) => attr.aid === aid)!.values;
};
const firstFromAttributeValue = (attribute: AttributeValue[]) => {
  return attribute[0].value;
};

export const getGFRArticles2 = async (
  gfrList: string[],
  articles: ProductDetailData[],
  artAttrDic: ArticleAttributesAid,
  config: any
) => {
  const gfr = articles.filter((item) => gfrList?.includes(item.translation));

  let finalList: Promise<GFR>[] = [];
  gfr.forEach(async (item) => {
    if (
      firstFromAttributeValue(
        getAttributeValue(item, artAttrDic.placo100)
      ).toLocaleLowerCase() === "true"
    ) {
      const gfr = getPackagedArticles2(item, artAttrDic.placo100, config);
      finalList.push(gfr);
    }
  });
  const finalGFR = await Promise.all(finalList);
  return finalGFR;
};
export const getGFRArticles = async (
  gfrList: string[],
  articles: ProductDetailData[],
  config: any
) => {
  const gfr = articles.filter((item) => gfrList.includes(item.translation));

  let finalList: Promise<GFR>[] = [];
  gfr.forEach(async (item) => {
    const gfr = getPackagedArticles(item, config);
    finalList.push(gfr);
  });
  const finalGFR = await Promise.all(finalList);
  return finalGFR;
};

export const getPackagedArticles2 = (
  article: ProductDetailData,
  placo100: string,
  config: any
): Promise<GFR> => {
  return getAnySystemDetailsWithChildren(article.oid, config).then(
    (response) => {
      const object: ProductDetailData[] = response.data.objects;
      const attributeDescriptors: AttributeDescriptor[] =
        response.data.context.attributeDependencies[0].attributes;
      const gfr: GFR = {
        article: article,
        packagedarticles: {
          attributeDescriptors: attributeDescriptors,
          articles: object.filter(
            (obj) =>
              obj.types[0] === "Packaged Article" &&
              obj.attributes
                .find((item) => item.aid === placo100)
                ?.values[0].value.toLocaleLowerCase() === "true"
          ),
        },
      };
      return gfr;
    }
  );
};

export const getPackagedArticles = (
  article: ProductDetailData,
  config: any
): Promise<GFR> => {
  return getAnySystemDetailsWithChildren(article.oid, config).then(
    (response) => {
      const object: ProductDetailData[] = response.data.objects;
      const attributeDescriptors: AttributeDescriptor[] =
        response.data.context.attributeDependencies[0].attributes;
      const gfr: GFR = {
        article: article,
        packagedarticles: {
          attributeDescriptors: attributeDescriptors,
          articles: object.filter((obj) => obj.types[0] === "Packaged Article"),
        },
      };
      return gfr;
    }
  );
};

export const getComboList = (
  articles: GFR[],
  attrDict: ArticleAttributesAid
): { comboList: ArticleComboList[] } => {
  return {
    comboList: articles.map((item: GFR) => {
      const name = item.packagedarticles.articles[0].translation;
      const height = item.packagedarticles.articles[0].attributes.find(
        (art) => art.aid === attrDict.unpackedLength
      )?.values[0].value!;
      return {
        value: name,
        text: name,
        key: name,
        height: height,
      };
    }),
  };
};

export const childRequestHandler = async (
  oid: ID,
  config: any
): Promise<{
  products: ProductDetailData[];
  articles: ProductDetailData[];
}> => {
  return await getAnySystemDetailsWithChildren(oid, config).then((response) => {
    const object: ProductDetailData[] = response.data.objects;
    const attributeDescriptors: AttributeDescriptor[] =
      response.data.attributeDependencies[0].attributes;
    return {
      attributeDescriptors: attributeDescriptors,
      products: object.filter((obj) => obj.types[0] === "Product"),
      articles: object.filter((obj) => obj.types[0] === "Article"),
    };
  });
};

export const detailedRequestHandler = async (
  oid: ID,
  config: any
): Promise<{
  Objects: ProductDetailData[];
  AttributeDescriptors: AttributeDescriptor[];
}> => {
  return await getPlacoSystemsDetailsAndRelation(oid, config).then(
    (response: any) => {
      return {
        Objects: response.data.objects,
        AttributeDescriptors:
          response.data.context.attributeDependencies[0].attributes,
      };
    }
  );
};

export const objectsSorter = (
  objects: ProductDetailData[]
): {
  solutionsProducts: ProductDetailData[];
  products: ProductDetailData[];
  genericproduct: ProductDetailData[];
  articles: ProductDetailData[];
} => {
  return {
    solutionsProducts: objects.filter(
      (obj) => obj.types[0] === "Solution Product"
    ),
    products: objects.filter((obj) => obj.types[0] === "Product"),
    genericproduct: objects.filter((obj) => obj.types[0] === "Generic Product"),
    articles: objects.filter((obj) => obj.types[0] === "Article"),
  };
};

export const findAttributeAid = (
  attributeDescriptors: AttributeDescriptor[],
  attributeName: string
) => {
  return attributeDescriptors.find(
    (attr) => attr.technicalName === attributeName
  )?.aid;
};

export const attributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): LayoutAttributesAid => {
  return {
    layers: findAttributeAid(attributeDescriptors, "GFR-BIM layers") as string,
    layerName: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer name"
    ) as string,
    layerColor: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer color"
    ) as string,
    layerContent: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer content"
    ) as string,
    layerThickness: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer thickness mm"
    ) as string,
    frameSpace: findAttributeAid(
      attributeDescriptors,
      "GFR-Distance between frames (in m)"
    ) as string,
    doubleFraming: findAttributeAid(
      attributeDescriptors,
      "GFR-Profiles (1 or 2)"
    ) as string,
    solutionName: findAttributeAid(
      attributeDescriptors,
      "A-Solution product name"
    ) as string,
    layerNumber: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer number"
    ) as string,
    articles: findAttributeAid(attributeDescriptors, "GFR-Articles") as string,
    categorypath: findAttributeAid(
      attributeDescriptors,
      "RS-CategoryPath"
    ) as string,
    packagedArticles: findAttributeAid(
      attributeDescriptors,
      "GFR-Packaged articles"
    ) as string,
  };
};

export const artAttributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): ArticleAttributesAid => {
  return {
    placo100: findAttributeAid(
      attributeDescriptors,
      "GFR-To Offre 100% Placo"
    ) as string,
    plaqueHeight: findAttributeAid(
      attributeDescriptors,
      "GFR-Height limit in m"
    ) as string,
    unpackedLength: findAttributeAid(
      attributeDescriptors,
      "GFR-Length of the UB unpacked (m)"
    ) as string,
    packagedArticle: findAttributeAid(
      attributeDescriptors,
      "GFR-Packaged articles"
    ) as string,
  };
};

export const packageAttributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): PackageAttributesAid => ({
  eancode: findAttributeAid(
    attributeDescriptors,
    "GFR-EAN low level (base unit)"
  ) as string,
  saleunit: findAttributeAid(
    attributeDescriptors,
    "GFR-Sales unit (Transac)"
  ) as string, //"Unité de vente
  qtypurchased: findAttributeAid(
    attributeDescriptors,
    "GFR-Number of invoicing units per base unit"
  ) as string, //Quantité réelle achetée
  qtybase: findAttributeAid(
    attributeDescriptors,
    "GFR-Number of base units per sales unit (channel 11)"
  ) as string, //Quantité conditionnée
  surface: findAttributeAid(
    attributeDescriptors,
    "GFR-Surface of the UB unwrapped (m²)"
  ) as string,
  unpackedLength: findAttributeAid(
    attributeDescriptors,
    "GFR-Length of the UB unpacked (m)"
  ) as string, //rails & plaque
  qtydecoupe: findAttributeAid(
    attributeDescriptors,
    "GFR-Number of invoicing units per base unit"
  ) as string, //rais,montant, plaque
  hauter: findAttributeAid(
    attributeDescriptors,
    "GFR-Thickness / UB unpacked height (m)"
  ) as string, //montant plaque
  hauterart: findAttributeAid(
    attributeDescriptors,
    "GFR-Packaged article thickness/height (mm)"
  ) as string, //montatn plaque hauter article
  baseunit: findAttributeAid(
    attributeDescriptors,
    "GFR-Base unit (UB)"
  ) as string, ///Unit
});

//GFR-Label unit (UE)  --- PAL,Paletten ---2151
//GFR-Base unit (UB)  ---SAC 2028
//GFR-Sales unit UV EAN code --- 3972
//GFR-Sales unit (Transac) --- 3996
//GFR-EAN low level (base unit) ---3928
//GFR-Quantity of base unit per alternative unit  ---2937
//GFR-Number of invoicing units per base unit  --3953
//GFR-Number of base units per sales unit (channel 11) ---4022
//GFR-Surface of the UB unwrapped (m²)  -- 3363
//GFR-Number of invoicing units per base unit ---3953 rails
//GFR-Packaged article thickness/height (mm)

export const attributesFilterer = (
  attributes: PimAttribute[],
  attrDict: LayoutAttributesAid
): PimAttribute[] => {
  return attributes.filter((attribute) => attribute.aid === attrDict.layers);
};
// export const solutionProduct = ()

export const layersSorter = (
  layersAttributes: PimAttribute[],
  attrDict: LayoutAttributesAid
) => {
  return {
    claddings: layersAttributes.filter((attr) =>
      attr.subAttributes
        .find((subAttr) => subAttr.aid === attrDict.layerContent)
        ?.values[0].value.startsWith("Plaque")
    ),
  };
};

export const claddingBuilder = (
  claddingAttributes: PimAttribute[],
  attrDict: LayoutAttributesAid
): BddLayer[] => {
  return claddingAttributes.map((claddAttr) => {
    return {
      layerName: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerName
      )?.values[0].value,
      layerContent: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerContent
      )?.values[0].value,
      layerColor: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerColor
      )?.values[0].value,
      layerThickness: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerThickness
      )?.values[0].numericValue,
      layerNumber: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerNumber
      )?.values[0].numericValue,
    };
  });
};

export const setLayerProduct = (
  bddLayer: BddLayer,
  products: ProductDetailData[]
): BddLayer => {
  return {
    ...bddLayer,
    product: products.find(
      (product) => product.translation === bddLayer.layerName
    ) as ProductDetailData,
  };
};

export const getProductArticleNames = (
  product: ProductDetailData,
  attrDict: LayoutAttributesAid
): string[] => {
  let artlist: string[] | undefined = product.attributes
    .find((attr) => attr.aid === attrDict.articles)
    ?.values.map((value: any) => value.value);

  return artlist ? (artlist.length >= 1 ? artlist : [""]) : [""];
};

export const setLayerArticles = (
  bddLayer: BddLayer,
  articleNames: string[],
  articles: ProductDetailData[]
): BddLayer => {
  return {
    ...bddLayer,
    articles: articles.filter((article) =>
      articleNames.includes(article.translation)
    ),
  };
};

export const bddLayersProcessor = (
  layers: BddLayer[],
  attrDict: LayoutAttributesAid,
  products: ProductDetailData[],
  articles: ProductDetailData[]
): BddLayer[] => {
  return layers.map((layer) => {
    const { product } = setLayerProduct(layer, products);
    if (product) {
      return {
        ...layer,
        product,
        articles: setLayerArticles(
          layer,
          getProductArticleNames(product, attrDict),
          articles
        ).articles,
      };
    } else {
      return layer;
    }
  });
};

export const maptoPackagedArticle = (
  prodDetails: ProductDetails[]
): ProductDetails[] => {
  prodDetails.forEach((item) => {
    if (ProductArticleMap[item.type] !== undefined) {
      item.selectedarticle = findPackagedArticle(
        item.type,
        item.GFR,
        item.product.translation
      );
    }
  });
  return prodDetails;
};

const findPackagedArticle = (
  type: string,
  list: GFR[],
  translation: string
): number => {
  let index = -1;
  for (let [key, value] of Object.entries(ProductArticleMap[type])) {
    //let article:ProductDetailData;
    if (translation === key) {
      list.forEach((item) => {
        item.packagedarticles.articles.forEach((art, ind) => {
          if (art.translation === value) {
            index = ind;
          }
        });
      });
    }
  }
  return index;
};

export const writeWorkbook = (
  workbook: Excel.Workbook,
  extract: ExcelExtract[],
  plaq: string[][],
  mont: string[],
  revitData: any
) => {
  writeWools(workbook, extract);
  writeAccesory(workbook, extract);
  writeRails(workbook, extract);
  writeMontants(workbook, extract, mont, revitData.frames);
  writePlaques(workbook, extract, plaq, revitData.plasterboards);
  writeCommande(workbook, extract, plaq, mont);
  writeSummary(workbook, extract, plaq, mont);
};

export const getWorksheet = (workbook: Excel.Workbook, name: string) => {
  return workbook.getWorksheet(name);
};

export const getPackagedArticle = (prod: ProductDetails) => {};
export const formatValue = (name: string, code: string, qty: string) => {
  let col: string[] = [];
  col[5] = name;
  col[6] = code;
  col[7] = qty;
  return col;
};

export const writeAccesory = (
  workbook: Excel.Workbook,
  extract: ExcelExtract[]
) => {
  // extract.forEach((data) => {
  //     const newRow3Values = [1, 2, 3, 4, 5];
  //     const newRow4Values = ["one", "two", "three", "four", "five"];
  //     summary.spliceRows(16, 1, newRow3Values, newRow4Values);
  // });
  // let extractedAccessories = extractAccessories(extract);
  // let accessorrySummary = getWorksheet(workbook, "Feuille accessoires");
  // accessorrySummary.duplicateRow(14, extractedAccessories.length - 1, true);
  // for (let i = 0; i < extractedAccessories.length; i++) {
  //   let row = accessorrySummary.getRow(i + 14);
  //   row.values = extractedAccessories[i];
  //   row.eachCell(function (cell, colNumber) {
  //     cell.border = {
  //       top: { style: "thin" },
  //       left: { style: "thin" },
  //       bottom: { style: "thin" },
  //       right: { style: "thin" },
  //     };
  //   });
  //   row.commit();
  // }
};

export const writeWools = (
  workbook: Excel.Workbook,
  extractDetails: ExcelExtract[]
) => {
  let woolSheet = getWorksheet(workbook, "Feuille laine");

  let wools = extractWools(extractDetails);

  woolSheet.duplicateRow(14, wools.length - 1, true);

  // for (let i = 0; i < wools.length; i++) {
  //   let row = woolSheet.getRow(i + 14);
  //   row.values = wools[i];

  //   row.eachCell(function (cell, colNumber) {
  //     cell.border = {
  //       top: { style: "thin" },
  //       left: { style: "thin" },
  //       bottom: { style: "thin" },
  //       right: { style: "thin" },
  //     };
  //   });

  //   row.commit();
  // }
};
export const extractWools = (extractDetails: ExcelExtract[]) => {
  const collected_articles: any = [];
  forEach(extractDetails, (extractDetail) => {
    let levelName = extractDetail.revitData[0].levelName;
    forEach(extractDetail.wools, (wool, key) => {
      let collected_article = [];
      collected_article.push(levelName); //'Niveau'
      collected_article.push("Zone 1");
      collected_article.push(wool.product.translation);
      collected_article.push("");
      collected_article.push("");
      collected_article.push("");
      collected_article.push("");
      collected_articles.push(collected_article);
    });
  });
  return collected_articles;
};

export const extractAccessories = (extractDetails: ExcelExtract[]) => {
  const collected_articles: any = [];
  forEach(extractDetails, function (extractDetail) {
    let levelName = extractDetail.revitData[0].levelName;

    //accessories
    forEach(extractDetail.accessories, function (accessory, key) {
      if (accessory.length > 0) {
        // accessory array (coatings, joints, resilent, screws)
        forEach(accessory, function (accessoryObj) {
          // first GFR
          const gfrFirst = accessoryObj.GFR[0];
          const packagedarticles = gfrFirst.packagedarticles;
          const articles = packagedarticles.articles;
          const attributeDescriptors = packagedarticles.attributeDescriptors;
          const attributeAids = packageAttributeSelector(attributeDescriptors);

          forEach(articles, function (articleObj) {
            let collected_article = [];
            collected_article.push(levelName); //'Niveau'
            collected_article.push("Zone 1"); // Zone
            collected_article.push(articleObj.translation); //Article
            // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
            const articleAttribute = articleObj.attributes;

            const surfaceAttribute = find(articleAttribute, [
              "aid",
              attributeAids.surface,
            ]);
            if (surfaceAttribute) {
              const findm2ValueObj = find(surfaceAttribute.values, [
                "unit",
                "m²",
              ]);
              const surface_value =
                findm2ValueObj && get(findm2ValueObj, "value");
              collected_article.push(surface_value); //Surface
            }

            collected_article.push(""); //Ratio
            collected_article.push(""); //Quantite

            const baseunit = find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);
            if (baseunit) {
              const baseunit_value = get(baseunit.values[0], "value");
              collected_article.push(baseunit_value); //Unite
            }

            const qtybase = find(articleAttribute, [
              "aid",
              attributeAids.qtybase,
            ]);
            if (qtybase) {
              const qtybase_value = get(qtybase.values[0], "value");
              collected_article.push(qtybase_value); // QuantiteConditioned
            }

            const saleunit = find(articleAttribute, [
              "aid",
              attributeAids.saleunit,
            ]);
            if (saleunit) {
              const saleunit_value = get(saleunit.values[0], "value");
              collected_article.push(saleunit_value); //UnitDeVente
            }

            collected_articles.push(collected_article);
          });
        });
      }
    });
  });

  return collected_articles;
};

export const extractRevitData = async (
  levels: any,
  itemData: any,
  oid: string
) => {
  let revitData: any = [];

  for (let item of itemData) {
    let levelName = levels.find((a: any) => a.Id === item.LevelId).Name;
    revitData.push({
      ElementId: item.Id,
      systemType: item.Name,
      label: item.label,
      objectType: item.objectType,
      oid: oid,
      technicalName: item.technicalName,
      levelId: item.LevelId,
      levelName: levelName,
    });
  }
  let idsList = revitData.map((a: any) => a.ElementId);
  let data = await api.queries.getWallsData(idsList);
  if (data.length > 0) {
    for (let dt of revitData) {
      let element: any = data.find((a) => a.Id === dt.ElementId);
      if (element) {
        dt.Area = element.Area;
        dt.Length = element.Length;
        dt.Width = element.Width;
        dt.Height = element.Height;
      }
    }
  }
  return revitData;
};

export const writeRails = (
  workbook: Excel.Workbook,
  extractDetails: ExcelExtract[]
) => {
  let railSheet = getWorksheet(workbook, "Feuille Rails");

  let rails = extractRails(extractDetails);

  railSheet.duplicateRow(14, rails.length - 1, true);

  for (let i = 0; i < rails.length; i++) {
    let row = railSheet.getRow(i + 14);
    row.values = rails[i];

    row.eachCell(function (cell, colNumber) {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    row.commit();
  }
};

export const extractRails = (extractDetails: ExcelExtract[]) => {
  const collected_articles: any = [];
  forEach(extractDetails, function (extractDetail) {
    let levelName = extractDetail.revitData[0].levelName;
    //accessories
    forEach(extractDetail.rails, function (rail, key) {
      // accessory array (coatings, joints, resilent, screws)
      // forEach(accessory, function (accessoryObj) {

      // first GFR
      const gfrFirst = rail.GFR[0];
      const packagedarticles = gfrFirst.packagedarticles;
      const articles = packagedarticles.articles;
      const attributeDescriptors = packagedarticles.attributeDescriptors;
      const attributeAids = packageAttributeSelector(attributeDescriptors);

      forEach(articles, function (articleObj) {
        let collected_article = [];
        collected_article.push(levelName); //'Niveau'
        collected_article.push("Zone 1"); // Zone
        collected_article.push(articleObj.translation); //Article
        // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
        const articleAttribute = articleObj.attributes;

        const length = find(articleAttribute, [
          "aid",
          attributeAids.unpackedLength,
        ]);
        if (length) {
          // const findm2ValueObj = find(length.values, ['unit', "m"]);
          const length_value = length && get(length.values[0], "value");
          collected_article.push(length_value); //Surface
        }
        const unit = find(articleAttribute, ["aid", attributeAids.baseunit]);
        if (unit) {
          // const findm2ValueObj = find(unit.values, ['unit', "m²"]);
          const unit_value = unit && get(unit.values[0], "value");
          collected_article.push(unit_value); //Surface
        }

        collected_article.push(""); //Ratio
        collected_article.push(""); //Quantite

        collected_articles.push(collected_article);
      });
      // });
    });
  });
  return collected_articles;
};

export const writeMontants = (
  workbook: Excel.Workbook,
  extractDetails: ExcelExtract[],
  mont: string[],
  montantsRevitData: any
) => {
  let railSheet = getWorksheet(workbook, "Feuille Montants");

  let rails = extractMontants(extractDetails, mont, montantsRevitData);

  railSheet.duplicateRow(14, rails.length - 1, true);

  for (let i = 0; i < rails.length; i++) {
    let row = railSheet.getRow(i + 14);
    row.values = rails[i];

    row.eachCell(function (cell, colNumber) {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    row.commit();
  }
};

export const extractMontants = (
  extractDetails: ExcelExtract[],
  mont: string[],
  montantsRevitData: any
) => {
  const collected_articles: any = [];
  forEach(extractDetails, function (extractDetail, index) {
    let levelName = extractDetail.revitData[0].levelName;
    let wallHeight = extractDetail.revitData[0].height;
    let wallArea = extractDetail.revitData[0].area;
    //accessories

    let slproduct = extractDetail.solutionProducts[0];
    let solution_product = [];
    solution_product.push(levelName); //'Niveau'
    solution_product.push("Zone 1");
    solution_product.push(slproduct.translation);
    solution_product = [
      ...solution_product,
      "",
      wallHeight,
      wallArea,
      "",
      "",
      "",
    ];

    collected_articles.push(solution_product);
    forEach(extractDetail.montants, function (montant, key) {
      // accessory array (coatings, joints, resilent, screws)
      // forEach(accessory, function (accessoryObj) {

      // first GFR
      const gfrFirst = montant.GFR.find(
        (item) => item.packagedarticles.articles[0].translation === mont[index]
      )!;
      const packagedarticles = gfrFirst.packagedarticles;
      const articles = packagedarticles.articles;

      let wallIds = extractDetail.revitData.map((a) => a.wallId);
      let revitDetails: RevitData[] = getRevitDetails(
        wallIds,
        montantsRevitData
      );

      let articleName = articles[0].translation;
      for (let dt of revitDetails) {
        for (let dtt of dt.data) {
          let collected_article = [];
          collected_article.push(levelName); //'Niveau'
          collected_article.push("Zone 1"); // Zone
          collected_article.push(""); // Zone
          collected_article.push(articleName); //Article

          collected_article.push("");
          collected_article.push("");
          collected_article.push(dtt.Length);
          collected_article.push("");
          collected_article.push("");

          collected_articles.push(collected_article);
        }
      }

      // forEach(articles, function (articleObj) {
      //   let collected_article = [];
      //   collected_article.push(levelName); //'Niveau'
      //   collected_article.push("Zone 1"); // Zone
      //   collected_article.push(""); // Zone
      //   collected_article.push(articleObj.translation); //Article
      //   // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
      //   const articleAttribute = articleObj.attributes;
      //   collected_article.push(
      //     getAttValue(articleAttribute, attributeAids.hauter)
      //   );
      //   collected_article.push(
      //     getAttSurValue(articleAttribute, attributeAids.surface)
      //   );
      //   collected_article.push(
      //     getAttValue(articleAttribute, attributeAids.hauterart)
      //   );
      //   collected_article.push(
      //     getAttValue(articleAttribute, attributeAids.qtydecoupe)
      //   );
      //   collected_article.push(""); //Ratio

      //   collected_articles.push(collected_article);
      // });
      // });
    });
  });
  return collected_articles;
};

const getAttValue = (articleAttribute: PimAttribute[], aid: string) => {
  const attribute = find(articleAttribute, ["aid", aid]);
  if (attribute) {
    // const findm2ValueObj = find(length.values, ['unit', "m"]);
    const attribute_value = attribute && get(attribute.values[0], "value");
    return attribute_value;
  }

  return "";
};

export const writePlaques = (
  workbook: Excel.Workbook,
  extractDetails: ExcelExtract[],
  plaq: string[][],
  plaqueRevitData: any
) => {
  let railSheet = getWorksheet(workbook, "Feuille Plaques");
  let rails = extractPlaques(extractDetails, plaq, plaqueRevitData);

  railSheet.duplicateRow(14, rails.length - 1, true);

  for (let i = 0; i < rails.length; i++) {
    let row = railSheet.getRow(i + 14);
    row.values = rails[i];

    row.eachCell(function (cell, colNumber) {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    row.commit();
  }
};

export const extractPlaques = (
  extractDetails: ExcelExtract[],
  plaq: string[][],
  plaqueRevitData: any
) => {
  const collected_articles: any = [];

  forEach(extractDetails, function (extractDetail, plindex) {
    let levelName = extractDetail.revitData[0].levelName;
    let wallHeight = extractDetail.revitData[0].height;
    let wallArea = extractDetail.revitData[0].area;

    //accessories
    let slproduct = extractDetail.solutionProducts[0];
    let solution_product = [];
    solution_product.push(levelName); //'Niveau'
    solution_product.push("Zone 1");
    solution_product.push(slproduct.translation);
    solution_product = [
      ...solution_product,
      "",
      wallHeight,
      wallArea,
      "",
      "",
      "",
      "",
    ];

    collected_articles.push(solution_product);
    forEach(extractDetail.plaques, function (montant, key) {
      // accessory array (coatings, joints, resilent, screws)
      // forEach(accessory, function (accessoryObj) {

      // first GFR
      const gfrFirst = montant.GFR.find(
        (item) =>
          item.packagedarticles.articles[0].translation === plaq[plindex][key]
      )!;
      const packagedarticles = gfrFirst.packagedarticles;
      const articles = packagedarticles.articles;

      let wallIds = extractDetail.revitData.map((a) => a.wallId);
      let revitDetails: RevitData[] = getRevitDetails(wallIds, plaqueRevitData);

      let articleName = articles[0].translation;
      for (let dt of revitDetails) {
        for (let dtt of dt.data) {
          let collected_article = [];
          collected_article.push(levelName); //'Niveau'
          collected_article.push("Zone 1"); // Zone
          collected_article.push(""); // Zone
          collected_article.push(articleName); //Article

          collected_article.push("");
          collected_article.push("");
          collected_article.push(dtt.Width);
          collected_article.push(dtt.Height);
          collected_article.push("");
          collected_article.push("");
          //const articleAttribute = articleObj.attributes;

          // collected_article.push(
          //   getAttValue(articleAttribute, attributeAids.hauter)
          // );
          // collected_article.push(
          //   getAttSurValue(articleAttribute, attributeAids.surface)
          // );
          // collected_article.push(
          //   getAttValue(articleAttribute, attributeAids.unpackedLength)
          // );
          // collected_article.push(
          //   getAttValue(articleAttribute, attributeAids.hauterart)
          // );
          // collected_article.push(
          //   getAttValue(articleAttribute, attributeAids.qtydecoupe)
          // );
          // collected_article.push(""); //Ratio

          collected_articles.push(collected_article);
        }
      }
      // forEach(articles, function (articleObj) );
      // });
    });
  });
  return collected_articles;
};

type RevitData = {
  wallId: string;
  data: any[];
};

const getRevitDetails = (wallIds: string[], revitData: any) => {
  let revData: RevitData[] = [];
  for (var wall of wallIds) {
    let data = get(revitData, wall);
    if (data !== undefined) {
      revData.push({ wallId: wall, data: data });
    }
  }
  return revData;
};

export const writeCommande = (
  workbook: Excel.Workbook,
  extractDetails: ExcelExtract[],
  plaq: string[][],
  mont: string[]
) => {
  let railSheet = getWorksheet(workbook, "Feuille Commande matériaux");

  let rails = extractCommande(extractDetails, plaq, mont);

  railSheet.duplicateRow(19, rails.length - 1, true);

  for (let i = 0; i < rails.length; i++) {
    let row = railSheet.getRow(i + 19);
    row.values = rails[i];

    row.eachCell(function (cell, colNumber) {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    row.commit();
  }

  //Summary
  let summ = groupBy(rails);
  let summcol: any[] = [];
  map(summ, (item) => {
    let coll = ["", "", ""];
    coll = [...coll, item[0][3], item[0][4], "", item[0][5], "", item[0][6]];
    summcol.push(coll);
  });

  railSheet.duplicateRow(16, summcol.length, true);
  for (let i = 0; i < rails.length; i++) {
    let row = railSheet.getRow(i + 16);
    row.values = summcol[i];

    row.eachCell(function (cell, colNumber) {
      if (parseInt(cell.col) > 3) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });

    row.commit();
  }
};

export const extractCommande = (
  extractDetails: ExcelExtract[],
  plaq: string[][],
  mont: string[]
) => {
  const collected_articles: any = [];
  const list = ["montants", "plaques", "rails"];
  forEach(extractDetails, function (extractDetail, plindex) {
    let levelName = extractDetail.revitData[0].levelName;
    //accessories

    let slproduct = extractDetail.solutionProducts[0];
    let solution_product = [];
    solution_product.push(levelName); //'Niveau'
    solution_product.push("Zone 1");
    solution_product.push(slproduct.translation);
    solution_product = [...solution_product, "", "", "", "", ""];

    //collected_articles.push(solution_product);
    forEach(extractDetail, function (article: any, key) {
      // accessory array (coatings, joints, resilent, screws)

      if (list.indexOf(key) > -1) {
        forEach(article, function (articleObj: ProductDetails, index) {
          const gfrFirst = retriveGFR(
            articleObj,
            key,
            plaq[plindex],
            mont,
            index
          );
          const packagedarticles = gfrFirst.packagedarticles;
          const articles = packagedarticles.articles;
          const attributeDescriptors = packagedarticles.attributeDescriptors;
          const attributeAids = packageAttributeSelector(attributeDescriptors);

          forEach(articles, function (articleObj) {
            let collected_article = [];
            collected_article.push(levelName); //'Niveau'
            collected_article.push("Zone 1"); // Zone
            collected_article.push(""); // Zone
            collected_article.push(articleObj.translation); //Article
            // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
            const articleAttribute = articleObj.attributes;

            collected_article.push(
              getAttValue(articleAttribute, attributeAids.eancode)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.qtybase)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.baseunit)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.qtypurchased)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.saleunit)
            );

            collected_articles.push(collected_article);
          });
        });
      } else if (key === "wools") {
        let collected_article = [];
        let art: ProductDetails = article[0]!;
        collected_article.push(levelName); //'Niveau'
        collected_article.push("Zone 1"); // Zone
        collected_article.push(""); // Zone
        collected_article.push(art.product.translation); //Article
        // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};

        collected_article = [...collected_article, "", "", "", "", ""];
        collected_articles.push(collected_article);
      } else if (key === "accessories") {
        // if (article.length > 0) {
        // accessory array (coatings, joints, resilent, screws)
        forEach(article, function (accessory) {
          // first GFR
          forEach(accessory, function (accessoryObj) {
            // first GFR
            const gfrFirst = accessoryObj.GFR[0];
            const packagedarticles = gfrFirst.packagedarticles;
            const articles = packagedarticles.articles;
            const attributeDescriptors = packagedarticles.attributeDescriptors;
            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            forEach(articles, function (articleObj) {
              let collected_article = [];
              collected_article.push(levelName); //'Niveau'
              collected_article.push("Zone 1"); // Zone
              collected_article.push("");
              collected_article.push(articleObj.translation); //Article
              // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
              const articleAttribute = articleObj.attributes;

              collected_article.push(
                getAttValue(articleAttribute, attributeAids.eancode)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.qtybase)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.baseunit)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.qtypurchased)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.saleunit)
              );

              collected_articles.push(collected_article);
            });
          });
        });
      }
    });
  });
  return collected_articles;
};

const retriveGFR = (
  articleObj: ProductDetails,
  key: string,
  plaq: string[],
  mont: string[],
  index: any
) => {
  switch (key) {
    case "plaques": {
      return articleObj.GFR.find(
        (item) => item.packagedarticles.articles[0].translation === plaq[index]
      )!;
    }
    case "montant": {
      return articleObj.GFR.find(
        (item) => item.packagedarticles.articles[0].translation === mont[index]
      )!;
    }
    default: {
      return articleObj.GFR[0];
    }
  }
};

export const writeSummary = (
  workbook: Excel.Workbook,
  extractDetails: ExcelExtract[],
  plaq: string[][],
  mont: string[]
) => {
  let railSheet = getWorksheet(workbook, "Feuille Synthèse");

  let rails = extractSummary(extractDetails, plaq, mont);

  //Summary
  let summ = groupBy(rails);
  let summcol: any[] = [];
  map(summ, (item) => {
    if (item[0][4].length > 0) {
      let coll = ["", "", "", ""];
      coll = [...coll, item[0][4], item[0][5], "", item[0][7], "", item[0][9]];
      summcol.push(coll);
    }
  });

  // railSheet.duplicateRow(16, summcol.length, true);

  let startingPointForSecondTable = 0;

  for (let i = 0; i < summcol.length; i++) {
    const rowNumberToInsert = 16 + i;
    railSheet.insertRow(rowNumberToInsert, summcol[i], "o");
    startingPointForSecondTable = rowNumberToInsert;
  }

  startingPointForSecondTable = startingPointForSecondTable + 5;

  // railSheet.duplicateRow(startingPointForSecond, rails.length - 1, true);

  for (let i = 0; i < rails.length; i++) {
    railSheet.insertRow(startingPointForSecondTable + i, rails[i], "o");
  }

  for (let i = 0; i < rails.length; i++) {
    let row = railSheet.getRow(startingPointForSecondTable + i);
    // row.values = rails[i];
    if (rails[i][2] !== "") {
      row.eachCell(function (cell: any, colNumber: any) {
        Object.assign(cell.fill, {
          bgColor: { theme: 0, tint: 0 },
          pattern: "none",
          type: "pattern",
        });

        Object.assign(cell.font, {
          bold: false,
          color: { argb: "#000" },
          family: 2,
          name: "Arial",
          size: 12,
        });
        return cell;
      });
    }

    row.commit();
  }
};

export const extractSummary = (
  extractDetails: ExcelExtract[],
  plaq: string[][],
  mont: string[]
) => {
  const collected_articles: any = [];
  const list = ["montants", "plaques", "rails"];
  forEach(extractDetails, function (extractDetail, plindex) {
    let levelName = extractDetail.revitData[0].levelName;
    //accessories

    let slproduct = extractDetail.solutionProducts[0];
    let solution_product = [];
    solution_product.push(levelName); //'Niveau'
    solution_product.push("Zone 1");
    solution_product.push(slproduct.translation);
    solution_product = [...solution_product, "", "", "", "", "", ""];

    collected_articles.push(solution_product);
    forEach(extractDetail, function (article: any, key: any) {
      // accessory array (coatings, joints, resilent, screws)

      if (list.indexOf(key) > -1) {
        forEach(article, function (articleObj: ProductDetails, index) {
          const gfrFirst = retriveGFR(
            articleObj,
            key,
            plaq[plindex],
            mont,
            index
          );
          const packagedarticles = gfrFirst.packagedarticles;
          const articles = packagedarticles.articles;
          const attributeDescriptors = packagedarticles.attributeDescriptors;
          const attributeAids = packageAttributeSelector(attributeDescriptors);

          forEach(articles, function (articleObj) {
            let collected_article = [];
            collected_article.push(levelName); //'Niveau'
            collected_article.push("Zone 1"); // Zone
            collected_article.push("");
            collected_article.push(""); // Zone
            collected_article.push(articleObj.translation); //Article
            // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
            const articleAttribute = articleObj.attributes;

            collected_article.push(
              getAttValue(articleAttribute, attributeAids.eancode)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.qtybase)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.baseunit)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.qtypurchased)
            );
            collected_article.push(
              getAttValue(articleAttribute, attributeAids.saleunit)
            );

            collected_articles.push(collected_article);
          });
        });
      } else if (key === "wools") {
        let collected_article = [];
        let art: ProductDetails = article[0]!;
        collected_article.push(levelName); //'Niveau'
        collected_article.push("Zone 1"); // Zone
        collected_article.push("");
        collected_article.push(""); // Zone
        collected_article.push(art.product.translation); //Article
        // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};

        collected_article = [...collected_article, "", "", "", "", ""];
        collected_articles.push(collected_article);
      } else if (key === "accessories") {
        // if (article.length > 0) {
        // accessory array (coatings, joints, resilent, screws)
        forEach(article, function (accessory) {
          forEach(accessory, function (accessoryObj) {
            // first GFR
            const gfrFirst = accessoryObj.GFR[0];
            const packagedarticles = gfrFirst.packagedarticles;
            const articles = packagedarticles.articles;
            const attributeDescriptors = packagedarticles.attributeDescriptors;
            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            forEach(articles, function (articleObj) {
              let collected_article = [];
              collected_article.push(levelName); //'Niveau'
              collected_article.push("Zone 1");
              collected_article.push("");
              collected_article.push(""); // Zone
              collected_article.push(articleObj.translation); //Article
              // collected_article = {...collected_article, ...{'oid' : articleObj.oid}};
              const articleAttribute = articleObj.attributes;

              collected_article.push(
                getAttValue(articleAttribute, attributeAids.eancode)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.qtybase)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.baseunit)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.qtypurchased)
              );
              collected_article.push(
                getAttValue(articleAttribute, attributeAids.saleunit)
              );

              collected_articles.push(collected_article);
            });
          });
        });
      }
    });
  });
  return collected_articles;
};

// Extracting Quantities of Plasterboard, Rails and frames

const extractPlasterboardQuantity = async () => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );
  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );
  let data: any = [];
  for (let board of plasterboardDetails) {
    let params: any = [];
    params = board.Params;
    let id: string = "",
      height: number = 0,
      length: number = 0,
      width: number = 0,
      name: string = "";
    for (let par of params) {
      switch (par.Name) {
        case "id":
          id = par.Value;
          break;
        case "PlasterboardHeight":
          height = par.Value;
          break;
        case "PlasterboardLength":
          length = par.Value;
          break;
        case "PlasterboardWidth":
          width = par.Value;
          break;
        case "name":
          name = par.Value;
          break;
      }
    }
    data.push({
      Id: board.Id,
      WallId: id,
      Name: name,
      Height: height,
      Length: length,
      Width: width,
      Area: Math.round(length * height * 0.0001) / 100,
    });
  }
  return formatDataBy(data, "WallId");
};

const extractFramesQuantity = async () => {
  const idsSimple = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature" },
        Rule: "Equals",
      },
    ],
    null
  );
  const idsDouble = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Placo_Ossature-Double",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  let frameIds = [...idsSimple, ...idsDouble, ...idsDouble];
  const frameDetails = await api.queries.getObjectsParams(frameIds, [
    "id",
    "Longueur",
    "Nom",
  ]);
  let data: any = [];
  for (let board of frameDetails) {
    let params: any = [];
    params = board.Params;
    let id: string = "",
      length: number = 0,
      name: string = "";
    for (let par of params) {
      switch (par.Name) {
        case "id":
          id = par.Value;
          break;
        case "Longueur":
          length = par.Value;
          break;
        case "Nom":
          name = par.Value;
          break;
      }
    }
    data.push({
      WallId: id,
      Id: board.Id,
      Name: name,
      Length: length,
    });
  }
  return formatDataBy(data, "WallId");
};

const extractRailsQuantity = async () => {
  // const bottomRailsLegacyIds = await api.queries.filterElements(
  //   "Generic",
  //   [
  //     {
  //       Param: { Name: "Name", Type: "Builtin", Value: "Rail" },
  //       Rule: "Equals",
  //     },
  //   ],
  //   null
  // );
  // const upperRailsLegacyIds = await api.queries.filterElements(
  //   "Generic",
  //   [
  //     {
  //       Param: { Name: "Name", Type: "Builtin", Value: "Rail-Retourne" },
  //       Rule: "Equals",
  //     },
  //   ],
  //   null
  // );
  const bottomRailsNewIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-bas" },
        Rule: "Equals",
      },
    ],
    null
  );
  const upperRailsNewIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-haut" },
        Rule: "Equals",
      },
    ],
    null
  );

  const upperRailsIds = upperRailsNewIds; //upperRailsLegacyIds.concat(upperRailsNewIds);
  const bottomRailsIds = bottomRailsNewIds; //bottomRailsLegacyIds.concat(bottomRailsNewIds);

  const railsIds = bottomRailsIds.concat(upperRailsIds);

  const railsDetails = await api.queries.getObjectsParams(railsIds, [
    "id",
    "Longueur",
    "Nom",
  ]);
  let data: any = [];
  for (let board of railsDetails) {
    let params: any = [];
    params = board.Params;
    let id: string = "",
      length: number = 0,
      name: string = "";
    for (let par of params) {
      switch (par.Name) {
        case "id":
          id = par.Value;
          break;
        case "Longueur":
          length = par.Value;
          break;
        case "Nom":
          name = par.Value;
          break;
      }
    }
    data.push({
      Id: board.Id,
      WallId: id,
      Name: name,
      Length: length,
    });
  }
  return formatDataBy(data, "WallId");
};

const formatDataBy = function (arr: any, key: any) {
  return arr.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export const extractQuantity = async () => {
  return {
    plasterboards: await extractPlasterboardQuantity(),
    frames: await extractFramesQuantity(),
    rails: await extractRailsQuantity(),
  };
};

// Extracting Quantities of Plasterboard, Rails and frames
