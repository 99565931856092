import { ThunkAction } from "redux-thunk";
import { CustomParameter, LoadSystemsActionTypes } from "./types";
import * as productsService from "../../services/project.service";
import {
  loadSystemsRequest,
  loadSystemsSuccess,
  loadSystemsError,
  loadSystemsToUpdateSuccess,
  loadSystemsToUpdateError,
  loadSystemsToUpdateRequest,
} from "./actions";
import { DrawStore } from "../reducers";
import { ID } from "../../../../../RevitJS/Types/RevitTypes";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import * as propertiesService from "../../../Properties/services/properties.service";
import { fillOldNewProperties } from "../../utils/utils";
import { getCurrentConfiguration } from "../../../Properties/services/properties.service.local";
import {
  fillSystemsModel,
  getDefaultConfigurationByType,
  getRelationofObjects,
} from "../../../Common/utils/attributes.injection.helpers";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../../Common/assets/attributes.injection";
import _ from "lodash";

type Effect = ThunkAction<any, DrawStore, any, LoadSystemsActionTypes>;

export const loadSystems =
  (t: any, config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsRequest());
    let systems: any[] = [];
    let customParameterResult2 =
      (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[];
    await productsService
      .loadProjects(config)
      .then((results: any[]) => {
        systems = results;
        dispatch(loadSystemsError());
      })
      .catch(() => dispatch(loadSystemsError()));

    systems = systems.filter((system) =>
      customParameterResult2.some((currentSystem) =>
        currentSystem?.paramData.some(pd => pd.key === 'ID Système' && pd.value === system.externalName)
        // currentSystem?.solution.includes(system.translation)
      )
    );
    customParameterResult2 = customParameterResult2.filter((system) =>
      systems?.some((currentSystem) =>
        system?.paramData.some(pd => pd.key === 'ID Système' && pd.value === currentSystem.externalName)
        // system?.solution.includes(currentSystem.translation)
      )
    );
    const systemIds = systems.map((product: { oid: ID }) => product.oid);
    if (systemIds && systemIds.length > 0) {
      dispatch(loadSystemsToUpdateRequest());
      await productsService
        .getSystemDetailsById(systemIds, config)
        .then(async (results) => {
          let currentSystemDetail = results.data.objects.map((sys) => {
            return { ...sys, filterFields: {} } as unknown;
          }) as ProductDetailData[];
          let elementData: CustomParameter[] = [];
          let selectedProperties: any = {};
          let systemProperties: any[] = [];
          let isDefaultConfiguration = false;
          await propertiesService
            .loadProperties(
              Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME),
              config
            )
            .then((properties: any[]) => {
              systemProperties = properties;
            });
          await getCurrentConfiguration().then((properties) => {
            selectedProperties = properties?.propertiesConfiguration;
            isDefaultConfiguration = properties?.isDefaultConfiguration;
          });
          if (!selectedProperties)
            selectedProperties = {
              liningWallsProperties:
                getDefaultConfigurationByType(
                  PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
                  systemProperties
                ) || [],
              partitionsProperties:
                getDefaultConfigurationByType(
                  PAM_SYSTEM_TYPE_KEYS.WALL,
                  systemProperties
                ) || [],
              ceilingsProperties:
                getDefaultConfigurationByType(
                  PAM_SYSTEM_TYPE_KEYS.CEILING,
                  systemProperties
                ) || [],
              gainesTechniquesProperties:
                getDefaultConfigurationByType(
                  PAM_SYSTEM_TYPE_KEYS.GAINES_TECHNIQUES,
                  systemProperties
                ) || [],
            };
          let data = {
            liningWallsDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                valueObject[0].value === "Isolation des murs" &&
                customParameterResult2.some((s) => {
                  let solutionProductNameAttr = system.attributes.find(c => c.technicalName === 'A-Solution product name');
                  return s?.solution.includes(solutionProductNameAttr ? solutionProductNameAttr.values[0].value : system.translation);
                }
                )
              );
            }),
            partitionsDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                valueObject[0].value === "Cloisons" &&
                customParameterResult2.some((s) => {
                  let solutionProductNameAttr = system.attributes.find(c => c.technicalName === 'A-Solution product name');
                  return s?.solution.includes(solutionProductNameAttr ? solutionProductNameAttr.values[0].value : system.translation);
                }
                )
              );
            }),
            ceilingsDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                (valueObject[0].value === "Plafonds décoratifs" ||
                  valueObject[0].value === "Plafonds") &&
                customParameterResult2.some((s) => {
                  let solutionProductNameAttr = system.attributes.find(c => c.technicalName === 'A-Solution product name');
                  return s?.solution.includes(solutionProductNameAttr ? solutionProductNameAttr.values[0].value : system.translation);
                }
                )
              );
            }),
            gainesTechniquesDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                (valueObject[0].value === "Gaines techniques") &&
                customParameterResult2.some((s) => {
                  let solutionProductNameAttr = system.attributes.find(c => c.technicalName === 'A-Solution product name');
                  return s?.solution.includes(solutionProductNameAttr ? solutionProductNameAttr.values[0].value : system.translation);
                }
                )
              );
            }),
          };

          await getRelationofObjects(data, config);

          elementData = fillSystemsModel(
            selectedProperties,
            data,
            isDefaultConfiguration,
            t
          );
          console.log("elementData: ", elementData);
          let elementData2 =
            elementData.length > 0
              ? elementData.map((sys) => ({
                  ...sys,
                  paramData: sys?.paramData.filter(
                    (att: any) => !att.toEmpty && !att.toDelete
                  ),
                }))
              : [];
          console.log("elementData2: ", elementData2);

          const systems = fillOldNewProperties(
            elementData2,
            customParameterResult2,
            t
          );

          elementData = fillSystemsModel(
            selectedProperties,
            data,
            isDefaultConfiguration,
            t
          );

          elementData = elementData.filter((system) =>
            systems.some((s) => s?.systemName.includes(system?.solution))
          );

          let distinctSystems: any[] = [];
          const map = new Map();
          for (const system of systems) {
            distinctSystems = [];
            map.clear();
            system.systemProperties.forEach((property: any) => {
              if (
                !map.has(property.propertyLabel + " - " + property.oldValue)
              ) {
                map.set(
                  property.propertyLabel + " - " + property.oldValue,
                  true
                ); // set any value to Map
                distinctSystems.push({
                  propertyLabel: property.propertyLabel,
                  oldValue: property.oldValue,
                  newValue: property.newValue,
                });

                system.systemProperties = distinctSystems;
              }
            });
          }

          dispatch(loadSystemsToUpdateSuccess(elementData));
          dispatch(loadSystemsSuccess(elementData, systems));
        })
        .catch(() => dispatch(loadSystemsToUpdateError()));
    }
  };
