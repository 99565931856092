import React from "react";
import { Container, Header } from "semantic-ui-react";

interface FunctionalityHeaderProps {
  Icon: string;
  header: string;
  subheader: string;
}

export class FunctionalityHeader extends React.Component<FunctionalityHeaderProps> {
  public static defaultProps = {
    Icon: "/saint-gobain.jpg",
  };

  render() {
    return (
      <Container textAlign="center" style={{ marginBottom: 0 }}>
        <Header
          color="blue"
          style={{ paddingTop: "0", paddingBottom: 0 }}
          image={this.props.Icon}
          as="h3"
          content={this.props.header}
          subheader={this.props.subheader}
        />
        {/* <Image src="/PlacoBIM.jpg"
                style={{width: "2.5em",  height: "2em", top: "-2.5em", left: "16.5em"}}></Image> */}
      </Container>
    );
  }
}
