import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { setPluginDataToDB } from "../../../Helper";
import { api } from "../../../RevitJS/API";
import React from "react";
import { GenericTestRoot } from "./Components/root";

export const functionalityId = "8f98532c-d123-4b58-a487-af2146ae382e";

export const GenericTestTrigger = async (config: any) => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
        setPluginDataToDB("token", urlParams.get("token")!);
    }
    api.windowsHandler.openWindow(
        900,
        565,
        `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
    );

    api.eventLog.SetEvent({
        data: [
            {
                name: "",
                value: "",
                values: [],
            },
        ],
        eventAction: "Open",
        eventCategory: "Module Access",
        eventLabel: "Generic Test Plugin",
        module: "GENERIC TEST PLUGIN",
    });
};

const RootComponent = () => {
    return (
        <ParentRoot
            pluginId={pluginId}
            functionalityId={functionalityId}
            module="genericTest"
        >
            <GenericTestRoot />
        </ParentRoot>
    );
};

export const TestRootComponent = () => {
    return (
        <ParentRoot
            pluginId={pluginId}
            functionalityId={functionalityId}
            module="genericTest"
        >
            <GenericTestRoot />
        </ParentRoot>
    );
};

export const Test: Functionality = {
    Name: { French: "Generic Test Plugin", English: "Generic Test Plugin", Indonesian: "Generic Test Plugin" },
    Trigger: GenericTestTrigger,
    ShortDesc: {
        French: "Plans des ouvrages",
        English: "Plans of the works",
        Indonesian: "Plans des ouvrages",
    },
    Id: functionalityId,
    Icon: "/pluginIcons_V3/ReperageV3-trans.png",
    RootComponent,
    ParentId: "0",
};