import { ThunkAction } from "redux-thunk";
import { PropertiesActionTypes } from "./types";
import {
  loadPropertiesRequest,
  loadPropertiesSuccess,
  restorePropertiesDetails,
  loadPropertiesError,
  selectProperty,
  defaultConfiguration,
} from "./actions";
import * as propertiesService from "../../services/properties.service";
import { CLOISONS_ID, DOUBLAGES_ID, PLAFONDS_ID } from "../../assets/constants";
import { DrawStore } from "../reducers";
import { getCurrentConfiguration } from "../../services/properties.service.local";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../../Common/assets/attributes.injection";
import {
  getDefaultConfigurationByType,
  getDefaultSystemAttributes,
  setPAMAttributesModal,
  setPAMAttributesModalNew,
} from "../../../Common/utils/attributes.injection.helpers";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";
import { getAllPlugins, getPrescriptionActiveVersion } from "../../../../../Services/mapping-config-service";
import { GAINES_ID } from "../../../LoadSystemContainer/assets/constants";

type Effect = ThunkAction<any, DrawStore, any, PropertiesActionTypes>;

export const loadProperties =
  (t: any, config: any, mainCategoryData: any): Effect =>
    async (dispatch: any, getState: any) => {
      dispatch(loadPropertiesRequest());
      let { systemTypeID } = getState().properties;
      let selectedProperties: any = {};
      let systemProperties: any[] = [];
      await propertiesService
        .loadProperties(Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME), config)
        .then((properties: any[]) => {
          systemProperties = properties;
        })
        .catch(() => dispatch(loadPropertiesError()));

      let newSystemProperties: any = mainCategoryData?.familyType;// await getSystemFamilyAttributes(config);

      await getCurrentConfiguration()
        .then((properties: any) => {        
          let propertiesData =  properties?.propertiesConfiguration;
          if(propertiesData && propertiesData['gainesProperties'] === undefined){
            propertiesData['gainesProperties'] = getDefaultSystemAttributes(
              //PAM_SYSTEM_TYPE_KEYS.CEILING,
              GAINES_ID,
              newSystemProperties
            ) || [];
          }

          selectedProperties = propertiesData;
          dispatch(defaultConfiguration(properties?.isDefaultConfiguration));
        })
        .catch(() => dispatch(loadPropertiesError()));
      if (!selectedProperties)
        selectedProperties = {
          liningWallsProperties:
            getDefaultSystemAttributes(
              //PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
              DOUBLAGES_ID,
              newSystemProperties
            ) || [],
          // getDefaultConfigurationByType(
          //   PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
          //   systemProperties
          // ) || [],
          partitionsProperties:
            getDefaultSystemAttributes(
              //PAM_SYSTEM_TYPE_KEYS.WALL,
              CLOISONS_ID,
              newSystemProperties
            ) || [],
          ceilingsProperties:
            getDefaultSystemAttributes(
              //PAM_SYSTEM_TYPE_KEYS.CEILING,
              PLAFONDS_ID,
              newSystemProperties
            ) || [],
          gainesProperties:
            getDefaultSystemAttributes(
              //PAM_SYSTEM_TYPE_KEYS.CEILING,
              GAINES_ID,
              newSystemProperties
            ) || [],
        };

      const data = {
        liningWallsProperties: setPAMAttributesModalNew(
          DOUBLAGES_ID,//PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
          newSystemProperties,
          selectedProperties.liningWallsProperties
        ),
        partitionsProperties: setPAMAttributesModalNew(
          CLOISONS_ID,//PAM_SYSTEM_TYPE_KEYS.WALL,
          newSystemProperties,
          selectedProperties.partitionsProperties
        ),
        ceilingsProperties: setPAMAttributesModalNew(
          PLAFONDS_ID,//PAM_SYSTEM_TYPE_KEYS.CEILING,
          newSystemProperties,
          selectedProperties.ceilingsProperties
        ),
        gainesProperties: setPAMAttributesModalNew(
          GAINES_ID,//PAM_SYSTEM_TYPE_KEYS.CEILING,
          newSystemProperties,
          selectedProperties.gainesProperties
        ),
      };

      const currentPropertiesDetail =
      systemTypeID === GAINES_ID ? 
        data.gainesProperties
      : systemTypeID === CLOISONS_ID
          ? data.partitionsProperties
          : systemTypeID === PLAFONDS_ID
            ? data.ceilingsProperties
            : data.liningWallsProperties;
      const currentSelectedProperties =
      systemTypeID === GAINES_ID ? 
        selectedProperties.gainesProperties
        : systemTypeID === CLOISONS_ID
          ? selectedProperties.partitionsProperties
          : systemTypeID === PLAFONDS_ID
            ? selectedProperties.ceilingsProperties
            : selectedProperties.liningWallsProperties;

      dispatch(
        loadPropertiesSuccess(
          systemProperties,
          data,
          selectedProperties,
          currentSelectedProperties,
          currentPropertiesDetail
        )
      );
    };

export const restoreProperties =
  (typeId: any): Effect =>
    (dispatch: any, getState: any) => {
      dispatch(loadPropertiesRequest());
      let { data, selectedProperties } = getState().properties;
      if (typeId === CLOISONS_ID && data.partitionsProperties.length > 0)
        dispatch(
          restorePropertiesDetails(
            data.partitionsProperties,
            selectedProperties.partitionsProperties,
            typeId
          )
        );
      else if (typeId === PLAFONDS_ID && data.ceilingsProperties.length > 0)
        dispatch(
          restorePropertiesDetails(
            data.ceilingsProperties,
            selectedProperties.ceilingsProperties,
            typeId
          )
        );
      else if (typeId === DOUBLAGES_ID && data.liningWallsProperties.length > 0)
        dispatch(
          restorePropertiesDetails(
            data.liningWallsProperties,
            selectedProperties.liningWallsProperties,
            typeId
          )
        );
        else if(typeId === GAINES_ID && data.gainesProperties.length > 0)
        dispatch(
          restorePropertiesDetails(
            data.gainesProperties,
            selectedProperties.gainesProperties,
            typeId
          )
      )
    };

export const checkProperty =
  (property: any, checked: boolean): Effect =>
    (dispatch: any, getState: any) => {
      let {
        currentSelectedProperties,
        currentPropertiesDetail,
        selectedProperties,
        data,
        systemTypeID,
      } = getState().properties;

      currentPropertiesDetail = currentPropertiesDetail.map((element: any) =>
        element.chapterData
          ? {
            ...element,
            chapterData: element.chapterData.map((prop: any) =>
              prop.categoryName === property.categoryName
                ? { ...prop, checked: checked }
                : prop
            ),
          }
          : element
      );

      //#region to show only selected attribute in properties  
      let onlySelected = currentPropertiesDetail.map((element: any) =>
        element.chapterData
          ? {
            ...element,
            chapterData: element.chapterData.filter((prop: any) => prop.checked === true)
          }
          : element);

      currentSelectedProperties = checked
        ? currentSelectedProperties.some(
          (prop: any) => prop.categoryName === property.categoryName
        )
          ? [...currentSelectedProperties]
          : [...currentSelectedProperties, { ...property, checked: checked }]
        : [
          ...currentSelectedProperties.filter(
            (prop: any) => prop.categoryName !== property.categoryName
          ),
        ];

      data = {
        liningWallsProperties:
          systemTypeID === DOUBLAGES_ID
            ? [...onlySelected]
            : [...data.liningWallsProperties],
        partitionsProperties:
          systemTypeID === CLOISONS_ID
            ? [...onlySelected]
            : [...data.partitionsProperties],
        ceilingsProperties:
          systemTypeID === PLAFONDS_ID
            ? [...onlySelected]
            : [...data.ceilingsProperties],
        gainesProperties:
          systemTypeID === GAINES_ID
            ? [...onlySelected]
            :[data.gainesProperties],
      };
      selectedProperties = {
        liningWallsProperties:
          systemTypeID === DOUBLAGES_ID
            ? [...currentSelectedProperties]
            : [...selectedProperties.liningWallsProperties],
        partitionsProperties:
          systemTypeID === CLOISONS_ID
            ? [...currentSelectedProperties]
            : [...selectedProperties.partitionsProperties],
        ceilingsProperties:
          systemTypeID === PLAFONDS_ID
            ? [...currentSelectedProperties]
            : [...selectedProperties.ceilingsProperties],
        gainesProperties:
          systemTypeID === GAINES_ID
            ? [...currentSelectedProperties]
            : [...selectedProperties.gainesProperties],
      };

      dispatch(
        selectProperty(
          currentSelectedProperties,
          currentPropertiesDetail,
          selectedProperties,
          data
        )
      );
    };

export const setDefaultConfiguration =
  (checked: boolean): Effect =>
    (dispatch: any, getState: any) => {
      dispatch(defaultConfiguration(checked));
    };

export const getSystemFamilyAttributes = async (config: any) => {
  // const prescriptionList = await getAllPrescriptions(config);
  // const prescriBIMplugin = prescriptionList.find((p: any) => p.name === 'PrescriBIM');
  // let attributes = await getPrescriptionPluginFamilyAttribute(prescriBIMplugin.prescriptionPluginId, config);
  let plugins = await getAllPlugins(config);
  const application = plugins.find((p: any) => p.applicationName === 'PrescriBIM');
  let data = await getPrescriptionActiveVersion(application.applicationId, config);
  let attributes =  data?.versions[0]?.categoryData;
  //attributes.categoryData = attributes.categoryData.filter((c: any) => c.chosen === true);
  return attributes?.familyTypes;
}
