import { isEmpty, size } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Icon,
  Form,
  Message,
  Button,
  Grid,
  Popup,
  Loader,
  Input,
} from "semantic-ui-react";
import { SelectionStore } from "../../../PlacoBIMv3/Selection/Reducers";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import { FacadeStore } from "../../Store/Reducers";
import wording from "../../Resources/wording.json";
import { v4 } from "uuid";
import { dbStoreNameFacadeCalepinage, Routes } from "./root";
import {
  DeleteFacadeCalpinage,
  SetBufferDateDetails,
  SetBufferDetails,
  SetEditBuffer,
  SetModuleData,
  updateSelectionNameAction,
} from "../../Store/Actions";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { SetProjectData } from "../../Store/Actions/projectAction";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import _ from "lodash";
import { api } from "../../../../RevitJS/API";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  Icon: string;
  name: string;
  subheader: string;
  setRoute: any;
};

const Landing = (props: Props) => {
  const reduxState = useSelector((state: FacadeStore) => state);
  const language: string = reduxState.language;
  const buffer = reduxState.buffer;
  const projectData = reduxState.projectData;
  const dispatch = useDispatch();

  const translation: any = wording;

  const [documentNameDimmer, setDocumentNameDimmer] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [documentDuplicateError, setDocumentDuplicateError] =
    React.useState(false);
  const [documentNameError, setDocumentNameError] = React.useState(false);
  const [newDocumentName, setNewDocumentName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [calpinageF4Data, seCalpinageF4Data] = useState<any>([]);
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [modifyLoader, setModifyLoader] = React.useState(false);
  const [calpinageId, setCalpinageId] = useState<string>("");
  const [calpinageName, setCalpinageName] = useState<any>("");
  const [editName, setEditName] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  //  const [updateSelectionNameAction, setUpdateSelectionNameAction] = useState<any>("");
  const [editNameValue, setEditNameValue] = useState<string>("");

  //const calpinageF4Data: any = [];

  useEffect(() => {
    setLoading(true);

    async function getData() {
      await loadSavedModule();
    }

    getData();
  }, []);

  const fetchProjectId = async () => {
    let project: initProjectData = await api.queries.getSetProjectData();

    if (project.ProjectId === "") {
    } else {
      dispatch(SetProjectData(project));
    }
    return project;
  };

  const loadSavedModule = async () => {
    let projectId: any;
    if (projectData.ProjectId === "") {
      let project = await fetchProjectId();
      projectId = project.ProjectId;
    } else {
      projectId = projectData.ProjectId;
    }

    if (isEmpty(reduxState.moduleData.data)) {
      let calepinageData = await bimStorage.listModule(
        dbStoreNameFacadeCalepinage,
        projectId
      );
      seCalpinageF4Data(calepinageData);
      dispatch(SetModuleData(calepinageData));
      console.log("this is dispatcher", calepinageData);
    } else {
      seCalpinageF4Data(reduxState.moduleData.data);
    }

    setLoading(false);
  };

  const closeDimmer = () => {
    setDocumentNameDimmer(false);
    setNewDocumentName("");
    setDocumentNameError(false);
    setDocumentDuplicateError(false);
  };

  const openDimmer = () => {
    setDocumentNameDimmer(true);
  };

  const handleDocumentName = async () => {
    if (newDocumentName.trim().length < 5) {
      setDocumentNameError(true);
    } else {
      let isDocumentFound = Object.keys(calpinageF4Data).find((e: any) => {
        return (
          calpinageF4Data[e].Name.toLowerCase() ===
          newDocumentName.toLowerCase()
        );
      });

      if (isDocumentFound) {
        setDocumentDuplicateError(true);
      } else {
        let date = new Date();
        let data: any = {
          name: newDocumentName,
          date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          id: v4(),
          time: Date.now(),
          revitView: projectData.ProjectId,
        };
        dispatch(SetBufferDetails(data));
        dispatch(SetBufferDateDetails(data));
        props.setRoute(Routes.NEW_CALEPINAGE);
        closeDimmer();
      }
    }
  };

  const onEdit = async (id: string) => {
    setModifyLoader(true);
    const calepinageDetail = await bimStorage.listDetailModule(
      dbStoreNameFacadeCalepinage,
      id
    );

    if (calepinageDetail) {
      dispatch(SetEditBuffer(JSON.parse(calepinageDetail)));
      props.setRoute(Routes.NEW_CALEPINAGE);
    }

    setModifyLoader(false);
  };

  const deleteFacadeCalpinage = (id: string, name: string) => {
    setCalpinageId(id);
    setCalpinageName(name);
    toggleConfirmModel();
  };

  const toggleConfirmModel = () => {
    setConfirmModal(!confirmModal);
  };

  const onNoAction = () => {
    toggleConfirmModel();
  };

  const onYesAction = () => {
    dispatch(DeleteFacadeCalpinage(calpinageId));

    bimStorage.deleteModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId,
      calpinageName
    );

    toggleConfirmModel();
  };

  const editSelectionName = (Id: string, Name: string) => {
    setEditName(Name);
    setEditId(Id);
  };

  const updateSelectionname = async (Id: string, Name: string) => {
    let errorMessage = "";
    const currentRevit = props.projectData.ProjectId;
    console.log("currentRevit ", currentRevit);
    //const currentRevit = getPluginDataFromDB("getActiveDocumentNameResult");
    if (Name === "") {
      errorMessage = "Nom de sélection invalide";
    }
    if (Name.trim().length < 5) {
      errorMessage = "Texte de 5 caractères minimum";
    }
    _.forEach(calpinageF4Data, (value) => {
      if (
        value.Name.toLowerCase() === Name.trim().toLowerCase() &&
        Id !== value.Id &&
        value.RevitView === currentRevit
      ) {
        errorMessage = "Une autre sélection existe déjà sous ce nom";
      }
    });

    if (errorMessage === "") {
      let updateArray = [...calpinageF4Data];
      console.log("hiee");
      let newArray: any = _.map(updateArray, (obj) => {
        if (obj.Id === Id) {
          obj.Name = Name;
        }
        return obj;
      });
      // Promise.resolve(seCalpinageF4Data(newArray)) ;
      Promise.resolve(dispatch(updateSelectionNameAction(Id, Name.trim())));
      bimStorage.setItem(storageKey.F4, JSON.stringify(Name.trim()));
      setEditId("");
      setEditName("");
      setErrorMessage("");
    } else {
      setErrorMessage(errorMessage);
      // this.setState({ errorMessage: errorMessage });
    }

    localStorage.setItem("isModification", "false");
  };

  return (
    <>
      <FunctionalityHeader
        Icon={
          reduxState.config.REACT_APP_SERVERURL + "/Facade/calpinageTitle.png"
        }
        name={translation.calepinage[language]}
        subheader={translation.listText[language]}
        config={reduxState.config}
      />
      <div
        style={{ height: "calc(100vh - 140px)", width: "100%", padding: 10 }}
        className="facadeF4"
      >
        <div style={{ height: "100%" }}>
          <div
            className="headerRow"
            style={{ border: "0.5px solid rgb(200, 200, 200)" }}
          >
            <div
              className="headerCols"
              style={{
                width: "50%",
                height: 30,
              }}
            >
              {translation.documentName[language]}
            </div>
            <div
              className="headerCols"
              style={{
                width: "40%",
                height: 30,
                borderRight: "0 none",
              }}
            >
              {translation.dateOfModification[language]}
            </div>
            <div
              className="headerCols"
              style={{
                width: "10%",
                height: 30,
                borderLeft: "0 none",
              }}
            ></div>
          </div>
          <div
            style={{
              border: "0.5px solid rgb(200, 200, 200)",
              height: "92%",
              overflowY: "auto",
              borderTop: "0 none",
            }}
          >
            {size(calpinageF4Data) === 0 ? (
              <Grid.Row>
                <Grid.Column
                  style={{
                    width: "100%",
                    color: "rgb(175,171,171)",
                    padding: 20,
                    textAlign: "center",
                  }}
                ></Grid.Column>
              </Grid.Row>
            ) : (
              calpinageF4Data.map((selection: any, i: any) => {
                return (
                  <div key={i}>
                    <Grid.Row>
                      <Grid.Column style={{ width: "50%" }}>
                        <Popup
                          trigger={
                            <>
                              {/* {editId === "" && (
                                <div
                                 
                                  onClick={() =>
                                    editSelectionName(
                                      selection.Id,
                                      selection.Name.trim()
                                    )
                                  }
                               >
                                 <div>{selection.Name}</div>
                                  
                               </div>
                             )} */}
                              {editId === selection.Id ? (
                                <div
                                  style={{
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                    display: "flex",
                                    width: "100%",
                                    color: "black",
                                    border: "0.2px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div>
                                    <Input
                                      type="text"
                                      placeholder="Search..."
                                      value={editName}
                                      onChange={(e, { value }) => {
                                        setEditName(value);
                                        setEditNameValue(value);

                                        localStorage.setItem(
                                          "isModification",
                                          "true"
                                        );
                                      }}
                                      action
                                      error={errorMessage !== ""}
                                      style={{ width: "100%" }}
                                    >
                                      <input />
                                      <Popup
                                        trigger={
                                          <Button
                                            icon
                                            onClick={() => {
                                              updateSelectionname(
                                                selection.Id,
                                                editName
                                              );
                                            }}
                                          >
                                            <Icon name="check" />
                                          </Button>
                                        }
                                        content="Valider la modification du nom"
                                        on="hover"
                                        inverted
                                        size="mini"
                                        position="bottom center"
                                      />
                                      <Popup
                                        trigger={
                                          <Button
                                            icon
                                            onClick={() => {
                                              editSelectionName("", "");
                                            }}
                                          >
                                            <Icon name="close" />
                                          </Button>
                                        }
                                        content="Annuler la modification du nom"
                                        on="hover"
                                        inverted
                                        size="mini"
                                        position="bottom center"
                                      />
                                    </Input>
                                    {errorMessage !== "" && (
                                      <div
                                        style={{
                                          fontSize: "9px",
                                          color: "red",
                                        }}
                                      >
                                        {errorMessage}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                    display: "block",
                                    width: "100%",
                                    color: "black",
                                    border: "0.2px",
                                    cursor: "pointer",
                                    wordWrap: "break-word",
                                    textAlign: "center",
                                    overflow: "hidden",
                                  }}
                                  onClick={() =>
                                    editSelectionName(
                                      selection.Id,
                                      selection.Name.trim()
                                    )
                                  }
                                >
                                  <div>{selection.Name}</div>
                                </div>
                              )}
                            </>
                          }
                          content="Cliquer pour modifier le nom"
                          on="hover"
                          inverted
                          size="mini"
                          position="bottom center"
                        />
                      </Grid.Column>
                      <Grid.Column
                        style={{ width: "40%", justifyContent: "center" }}
                      >
                        {selection.Date}
                      </Grid.Column>
                      <Grid.Column
                        style={{ width: "10%", justifyContent: "center" }}
                      >
                        <div>
                          {modifyLoader === true ? (
                            <Loader
                              type="Oval"
                              color="#00BFFF" //3 secs
                              height={18}
                              width={18}
                            />
                          ) : (
                            <Popup
                              trigger={
                                <div
                                  onClick={() => onEdit(selection.Id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Icon
                                    name="edit"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              }
                              content="Modifier"
                              on="hover"
                              inverted
                              size="mini"
                              position="bottom center"
                            />
                          )}
                        </div>
                        <div>
                          <Popup
                            trigger={
                              <div
                                onClick={() =>
                                  deleteFacadeCalpinage(
                                    selection.Id,
                                    selection.Name
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Icon
                                  name="delete"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            }
                            content="Supprimer"
                            on="hover"
                            inverted
                            size="mini"
                            position="bottom right"
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "0px 10px 10px",
          cursor: "pointer",
        }}
        onClick={openDimmer}
      >
        <Icon
          name="plus circle"
          className="facadeIcon"
          color="green"
          size="big"
        />
        <span style={{ color: "rgb(112,173,71)" }}>
          {translation.newLayout[language]}
        </span>
      </div>

      <Modal
        onClose={closeDimmer}
        open={documentNameDimmer}
        size="mini"
        dimmer="blurring"
        className="FacadeModal"
      >
        <Modal.Header
          className="modalHeader"
          content={translation.layoutName[language]}
        ></Modal.Header>
        <Modal.Actions className="modalActions">
          <Form>
            <Form.Input
              autoFocus
              fluid
              placeholder={translation.placeholderTextLength[language]}
              value={newDocumentName}
              onChange={(e, data) => {
                let value = data.value.toLowerCase();
                setNewDocumentName(value);

                if (value.length < 5) {
                  setDocumentDuplicateError(false);
                  setDisabled(true);
                } else {
                  setDocumentDuplicateError(false);
                  setDisabled(false);
                }
              }}
            />
          </Form>
          {documentNameError && (
            <Message
              style={{ textAlign: "left" }}
              error
              // header={
              //   this.props.wording.invalidDocumentName[this.props.language]
              // }
              content={"Le nom du document doit contenir au moins 5 caractères"}
            />
          )}
          {documentDuplicateError && (
            <Message
              style={{ textAlign: "left" }}
              error
              content={"Une autre sélection existe déjà sous ce nom"}
            />
          )}

          <div className="modalButton">
            <Button color="grey" onClick={closeDimmer}>
              {translation.back[language]}
            </Button>
            <Button
              disabled={disabled}
              type="submit"
              onClick={handleDocumentName}
              color="green"
            >
              {translation.validate[language]}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>

      <Modal
        open={confirmModal}
        size="mini"
        dimmer="blurring"
        className="FacadeModal"
      >
        <Modal.Header className="modalHeader">
          {"Supprimer la Calepinage"}
          <Icon
            style={{ float: "right", cursor: "pointer" }}
            name="close"
            onClick={onNoAction}
          />
        </Modal.Header>
        <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
          <p style={{ padding: "0px 10px" }}>
            {"Voulez-vous vraiment supprimer cette?"}
          </p>
        </Modal.Description>
        <div style={{ margin: "10px 0px", textAlign: "center" }}>
          <Button color="grey" size="tiny" onClick={onNoAction}>
            {translation.no[language]}
          </Button>
          <Button
            color="green"
            size="tiny"
            type="submit"
            primary
            onClick={() => {
              onYesAction();
            }}
          >
            {translation.yes[language]}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapState = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    moduleData: state.moduleData,
    projectData: state.projectData,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connect(mapState, mapDispatch)(Landing);
