import React, { useEffect, useState } from "react";
import { Button, Icon, Message } from "semantic-ui-react";
import { generalizeLanguage } from "../../Helper";
import { updateTrigger } from "../../Plugins/General/Update";
import { api } from "../../RevitJS/API";

type Langs = "French" | "English" | "German" | "Italian" | "Indonesian";
interface Props {
  version: string | null;
  loading: boolean;
  language: Langs;
  versiontobe: string | null;
  config:any;
}
interface Wording {
  updateBtn: { [K in Langs]: string };
  downloading: { [K in Langs]: string };
  updateContent: { [K in Langs]: string };
  bimToolboxDownload: { [K in Langs]: string };
  bimToolboxDownloadBtn: { [K in Langs]: string };
}
const wording: Wording = {
  updateBtn: {
    French: "Mettre à jour",
    English: "Update",
    German: "Aktualisieren",
    Italian: "Aggiornare",
    Indonesian: "Update",
  },
  downloading: {
    French: "Téléchargement de la mise à jour...",
    English: "Downloading the update ...",
    German: "Das Update wird heruntergeladen...",
    Italian: "Download dell'aggiornamento...",
    Indonesian: "Downloading the update ...",
  },
  updateContent: {
    French: "Veuillez mettre votre plugin à jour pour continuer.",
    English: "Please update your plugin to continue.",
    German: "Bitte aktualisieren Sie Ihr Plugin, um fortzufahren.",
    Italian: "Aggiorna il tuo plugin per continuare.",
    Indonesian: "Please update your plugin to continue.",
  },
  bimToolboxDownload: {
    French:
      "Si l’update n’est pas disponible, merci de télécharger la dernière version de l’installeur",
    English:
      "If the update is not available, please download the latest version of the installer",
    German:
      "Wenn das Update nicht verfügbar ist, laden Sie bitte die neueste Version des Installationsprogramms herunter",
    Italian:
      "Se l'aggiornamento non è disponibile, scarica l'ultima versione del programma di installazione",
    Indonesian:
      "If the update is not available, please download the latest version of the installer",
  },
  bimToolboxDownloadBtn: {
    French: "Télécharger l’installeur",
    English: "Download the installer",
    German: "Herunterladen",
    Italian: "Scarica il programma di installazione",
    Indonesian: "Download the installer",
  },
};
const langList: [Langs, Langs, Langs, Langs] = [
  "French",
  "English",
  "German",
  "Italian",
];


const StoreNeedsUpdate = (props: Props) => {
  const [lang, setLang] = useState<Langs>(props.language);
  const BimtoolboxLink: string = `${props.config.REACT_APP_FILESERVER}/static/directdownload/bimtoolbox.zip`;

  useEffect(() => {
    let language = generalizeLanguage(props.language);
    langList.includes(language) ? setLang(language) : setLang("English");
  }, [props.language]);

  const callToUpdate = () => {
    if (!window.revit.isArchicad) {
      updateTrigger(props.config);
    } else {
      if (!window.revit.isMac())
        api.framework.runInstaller(
          props.config.REACT_APP_ENV as string,
          `${props.config.REACT_APP_FILESERVER}/static/directdownload/archicad_${props.versiontobe}.msi`
        );
      else
        api.framework.runInstaller(
          props.config.REACT_APP_ENV as string,
          `${props.config.REACT_APP_FILESERVER}/static/directdownload/archicad_${props.versiontobe}.pkg`
        );
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "10px" }}>
      {/* <Button primary onClick={props.updateHandler} loading={props.loading}>{wording.updateBtn[lang]}</Button> */}
      <Button primary onClick={() => callToUpdate()} loading={props.loading}>
        {wording.updateBtn[lang]}
      </Button>

      <div style={{ marginTop: 10 }}>
        <em>
          {props.loading
            ? wording.downloading[lang]
            : wording.updateContent[lang]}
        </em>
      </div>
      <Message compact style={{ margin: "20px auto", paddingBottom: 20 }}>
        <div style={{ padding: "6px 20px 20px 20px" }}>
          <em>{wording.bimToolboxDownload[lang]}</em>
        </div>
        <Button primary basic as="a" href={BimtoolboxLink} download>
          <Icon name="download" />
          &nbsp;{wording.bimToolboxDownloadBtn[lang]}{" "}
        </Button>
      </Message>
    </div>
  );
};

export default StoreNeedsUpdate;
