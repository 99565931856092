export enum APP_WINDOW_NAME {
  BOILERPLATE_WINDOW = "BOILERPLATE_WINDOW",
}

export const BOILERPLATE_HEADER_TEXT = "Boilerplate header text";
export const SYSTEM_PROPERTIES = "SYSTEM_PROPERTIES";

export const CLOISONS = "Cloisons";
export const PLAFONDS = "Plafonds";
export const DOUBLAGES = "Doublages";
export const GAINES = "Gaines Techniques";

export const CLOISONS_ID = 1;
export const PLAFONDS_ID = 2;
export const DOUBLAGES_ID = 3;
export const GAINES_ID = 4;

export const systemTypes = [
  {
    label: CLOISONS,
    value: CLOISONS_ID
  },
  {
    label: PLAFONDS,
    value: PLAFONDS_ID
  },
  {
    label: DOUBLAGES,
    value: DOUBLAGES_ID
  },
  {
    label: GAINES,
    value: GAINES_ID
  },
];
