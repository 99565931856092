import React, { useEffect, useState } from "react";
import { getAllApplicationDetailsByLang, MyConfig } from "../../../../Helper";
import { Dimmer, Loader } from "semantic-ui-react";
import { getSanitizedHtmlString } from "../../../../Dock/Hooks/useSanitizedHtml";

interface ReleaseNoteProps {
  wording: any;
}

export const ReleaseNote = ({ wording }: ReleaseNoteProps) => {
  const [data, setData] = useState<{ language: string; releaseNotes: string }>({
    language: "",
    releaseNotes: "",
  });

  useEffect(() => {
    async function getReleaseNote() {
      const cng = await MyConfig();
      let savedLanguage: any = localStorage.getItem("savedLanguage");

      let allApps: any = await getAllApplicationDetailsByLang(
        cng,
        getLanguageCode(savedLanguage)
      );
      let selectedPlugin = localStorage.getItem("TechnicalName") as string;
      let selectedPluginDetails = allApps.data.find(
        (a: any) => a.applicationTechnicalName === selectedPlugin
      );
      if (selectedPluginDetails && selectedPluginDetails.releaseNotes) {
        setData({
          language: savedLanguage,
          releaseNotes: selectedPluginDetails.releaseNotes,
        });
      } else {
        setData({
          language: savedLanguage,
          releaseNotes: `<p style="text-align:center;color:grey"> ${wording.noDataAvailable[savedLanguage]} </p>`,
        });
      }
    }
    getReleaseNote();
  }, [wording.noDataAvailable]);

  const createMarkup = (html: string) => {
    return {
      __html: getSanitizedHtmlString(html),
    };
  };

  const getLanguageCode = (lang: string | null) => {
    switch (lang) {
      case "German":
        return "de";
      case "French":
        return "fr";
      case "Italian":
        return "it";
      case "Indonesian":
        return "id";
      default:
        return "en";
    }
  };

  if (data.releaseNotes === "") {
    return (
      <div className="release-note-container">
        <Dimmer inverted active style={{ height: "calc(100vh - 30px)" }}>
          <Loader />
        </Dimmer>
      </div>
    );
  }

  return (
    <div className="release-note-container">
      <div
        style={{
          color: "rgb(33, 133, 208)",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "600",
          margin: "10px",
        }}
      >
        {wording.releaseNoteHeader[data.language]}
      </div>
      <div
        style={{
          width: "96%",
          border: "2px solid #efefef",
          margin: "auto",
          marginTop: "1rem",
          padding: "1rem",
          height: "calc(100vh - 16vh)",
          overflowY: "auto",
        }}
        className="preview"
        dangerouslySetInnerHTML={createMarkup(data.releaseNotes)}
      ></div>
    </div>
  );
};

ReleaseNote.defaultProps = {
  wording: {
    releaseNoteHeader: {
      French: "Notes de publication",
      English: "Release Notes",
      German: "Notes de publication",
      Italian: "Notes de publication",
      Indonesian: "Notes de publication",
    },
    noDataAvailable: {
      French: "Aucune donnée disponible",
      English: "No Data Found",
      German: "Aucune donnée disponible",
      Italian: "Aucune donnée disponible",
      Indonesian: "Aucune donnée disponible",
    },
  },
};
