//#region imports
import _, { cloneDeep, find, flattenDeep, groupBy, includes, isEmpty, keysIn, map, orderBy, pickBy, remove, round, startsWith, uniq } from "lodash";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import { v4 } from "uuid";
//#endregion imports

//#region internal imports
import { bimStorage, storageKey } from "../../../BIMStore";
import { asyncForEach, loadSelections } from "../../../Plugins/PlacoBIMv3/Selection/Actions";
import { api } from "../../../RevitJS/API";
import { initProjectData, PlacoOptions, RowOptions, SelectionGroup } from "../../../RevitJS/Types/StoreTypes";
import { myConfig } from "../../../Plugins/ModelGen/Shared/myConfig";
import { fetchPlacoSolutionsByTechnicalNames } from "../../../API";
import { Dimmer, Loader } from "semantic-ui-react";
import { MyConfig } from "../../../Helper";
import { fetchPlacoDataInGroupSplit } from "../../../Plugins/PlacoBIMv3/Calpinage/Helpers/placoHelper";
import { montantDefault, plaqueDefault } from "../../../Plugins/PlacoBIMv3/Calpinage/Components/Solution/TableRow";
import { dbStoreNameCalepinage } from "../../../Plugins/PlacoBIMv3/Calpinage/Components/root";
import { fetchPlacoCeilingDataInGroupSplit } from "../../../Plugins/PlacoBIMv3/Calpinage/Helpers/placoCeilingHelper";
import { SuccesPopupComponent } from "./common/successPopupComponent";
//#endregion internal imports

interface Props {
translations: any;
language: string;
}

export type Selection<T extends RowOptions> = {
    Name: string;
    Id: string;
    Duplicated: number;
    Zone: boolean;
    SelectionByType: {
      wall: SelectionGroup<T>;
      ceiling: SelectionGroup<T>;
      others: SelectionGroup<T>;
    };
    Date: string;
    Levels: string[];
    Update: boolean;
    Color: any;
    RevitView?: string;
    Time: number;
  };

  
export const ReendineeringComponent = (prop: Props) => {
    
    const[loading, setLoading] = useState(false);
    const[active, setActive] = useState(false);
    const[progressMessage, setProgressMessage] = useState('0%');
    const[popupDetails, setPopupDetails] = useState<any>({header: prop.translations[prop.language].header.save , message: prop.translations[prop.language].message.success });
  
    const dispatch = useDispatch();

    useEffect(() => {
      setLoading(true);
        async function loadSelection(){
          getSelection();
          exportData();
        }
        loadSelection();
      }, []);

      const getSelection=async ()=>{
        let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
        if (placoSelections) {
          let selections = placoSelections as {
            [key: string]: Selection<PlacoOptions>;
          };

          dispatch(loadSelections(selections));
        }
      }

      
      const closePopup =() => {window.revit.closeWindow();}

      const exportData = async () => {
        //#region declaration
        let date = new Date();
        const config = await myConfig();
        const cng = await MyConfig("Calepinage");
        const projectData: initProjectData = await api.queries.getSetProjectData();
        const productData = await bimStorage.getItem(storageKey.PLACO_PRODUCTS);
        let fileName = projectData.ProjectPath.split('\\')[projectData.ProjectPath.split('\\').length-1];
        let levels: any = [];
        let selections: any = [];
        let selectionDataList: any = [];
        let calepinages: any = [];
         let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
        if (placoSelections) {
          selectionDataList = placoSelections as {
            [key: string]: Selection<PlacoOptions>;
          };
        }
        //#endregion declaration
        
        //#region fetch wall data
        const processedWallIds: any[] = await api.queries.filterElements(
          "Wall",
          [
            {
              Param: { Name: "Processed", Type: "Boolean", Value: true },
              Rule: "Equals",
            },
          ],
          null
        );

        let processedWallParams = await api.queries.getObjectsParams(
          processedWallIds,
          ["SG_System", "Plaque", "Montant"]
        );

        let processedWallSolution = uniq(map(processedWallParams, "Params[0].Value"));
                    
        const  wallSolutionData = await fetchPlacoSolutionsByTechnicalNames(
          processedWallSolution,
          config
        );
        //#endregion fetch wall data

        //#region fetch ceiling data
        const processedCeilingIds: any[] = await api.queries.filterElements(
          "Ceiling",
          [
            {
              Param: { Name: "Processed", Type: "Boolean", Value: true },
              Rule: "Equals",
            },
          ],
          null
        );

        let processedCeilingParams = await api.queries.getObjectsParams(
          processedCeilingIds,
          ["SG_System", "Plaque", "Montant"]
        );

        let processedCeilingSolution = uniq(map(processedCeilingParams, "Params[0].Value"));
                    
        const  ceilingSolutionData = await fetchPlacoSolutionsByTechnicalNames(
          processedCeilingSolution,
          config
        );

        //#endregion fetch ceiling data


        if(processedWallIds.length === 0 && processedCeilingIds.length === 0){
          setPopupDetails({header: prop.translations[prop.language].header.warning , message: prop.translations[prop.language].message.noLayout });
          setLoading(false);
          setActive(true);
        } else {
              //#region get levels
              let wallData = await api.queries.getWallsData(processedWallIds);
              let ceilingData = await api.queries.getCeilingsData(processedCeilingIds);
             
              //#endregion get levels
              let levelCount = 0;

                //check if filename is already exists
                let filterSelection = filter(selectionDataList, function(s: any){
                  return s.Name.includes(fileName)
                });

                //create object for selection
                let newselection = {
                  Id : v4(),
                  Name: fileName+'_'+filterSelection.length,
                  Zone : false,
                  Duplicated: 0,
                  RevitView: projectData.ProjectId,
                  Date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
                  Time: date.getTime(),
                  Levels: [levels],
                  Update: false,
                  Color: { "r": 0, "g": 247, "b": 16, "a": 1 },
                  SelectionByType: {
                      wall: {
                        Type: "wall",
                        Rows: []
                      },
                      ceiling: {
                        Type: "ceiling",
                        Rows:[],
                      },
                      others: {
                        Type: "others",
                        Rows: []
                      }
                    }
                };
                
              //fetch wall and ceiling data for mapped system
              const wallRows = await getRowType(wallData, processedWallParams, productData, true);

              const ceilingRows = await getRowType(ceilingData, processedCeilingParams, productData, false);
             
              //group by wall and ceiling data based on wall id and solution id
              const wallDataList = checkData(wallRows);
              const ceilingDataList = checkData(ceilingRows);
              const maxDataList = wallDataList.length > ceilingDataList.length ? wallDataList : ceilingDataList;
              
              //loop to create selection based on list recieved after grouping by 
              await asyncForEach(maxDataList, async (data: any, index: number) => {
                levelCount++;
                let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
                if (placoSelections) {
                  selectionDataList = placoSelections as {
                    [key: string]: Selection<PlacoOptions>;
                  };
                }

                let filterSelection = filter(selectionDataList, function(s: any){
                  return s.Name.includes(fileName)
                });
                let selectionData = cloneDeep(newselection);

                let count = filterSelection.length;
                selectionData.Name = fileName+'_'+ count;
                selectionData.Id = v4();
                selectionData.SelectionByType.wall.Rows = wallDataList[index] === undefined ? [] : wallDataList[index].records;
                selectionData.SelectionByType.ceiling.Rows = ceilingDataList[index] === undefined ? [] : ceilingDataList[index].records;
                
                levels = [];
                levels = wallDataList[index] === undefined ? levels : getLevels(wallData,wallDataList[index].records , levels, true);
                levels = ceilingDataList[index] === undefined ? levels : getLevels(ceilingData,ceilingDataList[index].records, levels, false);
                selectionData.Levels = levels;
                selections.push(selectionData);
                //#region saveSelection
                  await saveSelection(selectionData);
                  await SaveCalpinage(selectionData, fileName, calepinages, cng, projectData.ProjectId);
                  setProgressMessage(Math.round((levelCount / maxDataList.length) * 100)+'%');
                //#endregion saveSelection

                if(levelCount === maxDataList.length){
                  setLoading(false);
                  setActive(true);
                }
              });
            }
      }


      //#region create list of wall and ceiling for group by 
      const checkData = (data: any) => {
        let dataList: any = [];
        dataList.push({index: 0, records: []});
        _.forEach(data, (wall: any) => {
          let filterData: any = [];
          _.forEach(dataList, function(s: any){
            _.forEach(s.records, function(w:any){ 
              if(w.RevitSelection.RevitType === wall.RevitSelection.RevitType){
                filterData.push(w);
              }
            });
          });

          if(filterData.length === 0){
            dataList[0].records.push(wall);
          } else {
            if(dataList.length >= (filterData.length + 1)){
              dataList[filterData.length].records.push(wall);
            } else {
              dataList.push({index: filterData.length, records: []});
              dataList[filterData.length].records.push(wall);
            }
          }
         });

         return dataList;
      }
      //#endregion create list of wall and ceiling for group by 

      const setRowData = (index: number, revitType: string,wallId: string) =>{
        return {
          Checked: true,
          Index: index,
          RevitSelection: { RevitType: revitType, Ids: [wallId] },
          Options: {
              MappedSystem: null,
              Duplicated: 1,
          },
          OtherPlacoSystem: false
        }
      }

      const getLevels=(data: any, rows: any, levels: any, isWall: boolean)=>{
        _.forEach(rows, (row: any)=>{
          _.forEach(row.RevitSelection.Ids, (revitId: any) => {
            const typeData = data.filter((d: any) => d.Id === revitId)[0];
            const levelNameIndex = levels.findIndex((level: any) => level === typeData.LevelName);
            if(levelNameIndex === -1){
              levels.push(typeData.LevelName);
            }
          });
        });

        return levels;
      }

      //#region function to set mapped system
      const getRowType = (data: any, processedParams: any, solutionData: any, isWall: boolean) =>{
        
        let rows: any = [];
        _.forEach(data, async(wall: any)=>{
          const processData = processedParams.filter((pw: any) => pw.Id === wall.Id)[0];
          const index = rows.findIndex((row: any) => 
                        row?.RevitSelection?.RevitType === (isWall ? wall.WallType : wall.CeilingType) 
                        && row.Options.MappedSystem?.technicalName === processData?.Params[0].Value  );              

          if(index > -1){
            rows[index].RevitSelection.Ids.push(wall.Id);
          } else {
            let typeRow = setRowData(rows.length,(isWall ? wall.WallType : wall.CeilingType), wall.Id);
            _.forEach(solutionData.objects, (solData: any) =>{
              if(solData.technicalName === processData.Params[0].Value){
                _.forEach(solData.attributes, (attr: any) =>{
                  if(attr.values[0]){
                    solData[attr.technicalName] = attr.values[0].value;
                  }
                });
                typeRow.Options.MappedSystem = solData;
              }
            });

            rows.push(typeRow);
          }
        });

        return rows;
      }
      //#endregion function to set mapped system
      
      //#region setCalepinage
      const groupWallsDataFromSelection = async (selection: any, cng: any) => {
        /// Extract selection ids
        let selectionIds = map(
          selection,
          (n) => {
            return n.Id;
          }
        );
    
        /// Extract selection list based on ids
        let selectionList = filter(selection, function (o) {
          return includes(selectionIds, o.Id);
        });
    
        /// 1. Extract selected wall ids from selection.
        let wallRows = flattenDeep(
          map(selectionList, (ele) => {
            return map(ele.SelectionByType.wall.Rows, (row) => {
              return {
                ...row,
                zone: ele.Zone ? ele.Name : "",
              };
            });
          })
        );

        let wallIdsExt = flattenDeep(
          map(wallRows, (ele) => {
            if (ele.RevitSelection) {
              return ele.RevitSelection.Ids;
            }
          })
        );
    
        /// 2. Check for duplicate wall ids
        let dupliactesWallIds = keysIn(
          pickBy(groupBy(wallIdsExt), (x) => {
            return x.length > 1;
          })
        );
    
        if (dupliactesWallIds.length === 0) {
          /// 3. Get walls data from revit
          let wallsData = await api.queries.getWallsData(wallIdsExt);
    
          /// 4. Extract solution ids
          let solutionIds = uniq(
            flattenDeep(
              map(wallRows, (ele) => {
                return ele.Options.MappedSystem.oid;
              })
            )
          );

          /// 5. Get solution data from api respected to solution ids
          const { extractedSolutionProduct } = await fetchPlacoDataInGroupSplit(
            solutionIds,
            cng
          );
          wallsData = orderBy(wallsData, ["LevelElevation"], ["asc"]);
    
          let grpWallsData: any[] = [];
    
          _.forEach(wallsData, (value: any, index: number) => {
            let wallRow = find(wallRows, (walRow: any) => {
              return includes(walRow.RevitSelection.Ids, value.Id);
            });
    
            let height: String = parseFloat(
              (value.Height / 1000).toString()
            ).toFixed(2);

            let oneValue = find(grpWallsData, {
              LevelId: value.LevelId,
              Zone: wallRow.zone,
              WallType: value.WallType,
              Height: height,
              PlacoSolution: wallRow.Options.MappedSystem.longName
                ? wallRow.Options.MappedSystem.longName
                : wallRow.Options.MappedSystem.translation,
            });
    
            if (oneValue) {
              let ids = oneValue.Ids;
              ids.push(value.Id);
              find(grpWallsData, {
                LevelId: value.LevelId,
                Zone: wallRow.zone,
                WallType: value.WallType,
                Height: height,
                PlacoSolution: wallRow.Options.MappedSystem.longName
                  ? wallRow.Options.MappedSystem.longName
                  : wallRow.Options.MappedSystem.translation,
              }).Ids = ids;
            } else {
              grpWallsData.push({
                Height: height,
                LevelName: value.LevelName,
                PlacoSolution: wallRow.Options.MappedSystem.longName
                  ? wallRow.Options.MappedSystem.longName
                  : wallRow.Options.MappedSystem.translation,
                PlacoSolutionId: wallRow.Options.MappedSystem.oid,
                PlacoSolutionHeight:
                  wallRow.Options.MappedSystem["GFR-Height limit in m"],
                Zone: wallRow.zone,
                WallType: value.WallType,
                LevelElevation: value.LevelElevation,
                LevelId: value.LevelId,
                Ids: [value.Id],
                montant: wallRow.montant,
                plaque: wallRow.plaque,
              });
            }
          });
    
          const proceessWalls = processNewWallData(
            grpWallsData,
            extractedSolutionProduct
          );

          /// 7. Set state for wallsData, SolutionProducts
        return proceessWalls;
        }
      };

      const processNewWallData = (preWallData: any, extractedSolutionProduct: any[]) => {
        return map(preWallData, (wallObject: any, index: number) => {
          const extractedSolutionProducts = find(
            extractedSolutionProduct,
            function (o: any) {
              return o.solutionProduct.oid === wallObject.PlacoSolutionId;
            }
          );
    
          let fetIsLayout: boolean = true,
            fetIsPlaco: boolean = true,
            fetIsChk: boolean = true,
            plaqsArray: any[] = [],
            montantsArray: any[] = [];
    
          plaqsArray = filter(extractedSolutionProducts?.products, {
            type: "plaque",
          });
    
          map(plaqsArray, (plq, index) => {
            remove(plq.articles, function (n: any) {
              return startsWith(n.value, "PPM");
            });
            return plq;
          });
          montantsArray = filter(extractedSolutionProducts?.products, {
            type: "montant",
          });
          if (
            !wallObject.PlacoSolutionId.includes("custom") &&
            extractedSolutionProducts?.layoutPossible.toLowerCase() === "true"
          ) {
            fetIsLayout = true;
            fetIsPlaco = true;
    
            if (isEmpty(wallObject.plaque)) {
              wallObject.plaque = plaqsArray.map((item) =>
                plaqueDefault(
                  item.articles,
                  `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
                )
              );
            }
    
            if (isEmpty(wallObject.montant)) {
              wallObject.montant = montantsArray.map((item) =>
                montantDefault(
                  item.articles,
                  `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
                )
              );
            }
          } else {
            fetIsLayout = false;
            if (wallObject.PlacoSolutionId.includes("custom")) {
              fetIsChk = false;
              fetIsPlaco = false;
              wallObject.PlacoSolutionHeight = "";
              wallObject.plaque = [];
              wallObject.montant = [];
            } else {
              if (isEmpty(wallObject.plaque)) {
                wallObject.plaque = plaqsArray.map((item) =>
                  plaqueDefault(
                    item.articles,
                    `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
                  )
                );
              }
    
              if (isEmpty(wallObject.montant)) {
                wallObject.montant = montantsArray.map((item) =>
                  montantDefault(
                    item.articles,
                    `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
                  )
                );
              }
            }
          }
    
          wallObject.layoutPossible = fetIsLayout;
          wallObject.placo = fetIsPlaco;
          wallObject.plaqueArray = plaqsArray;
          wallObject.montantArray = montantsArray;
          wallObject.installationType = extractedSolutionProducts?.installationType;
          wallObject.supportCeiling = extractedSolutionProducts?.supportCeiling;
          wallObject.chk = wallObject.chk === undefined ? fetIsChk : wallObject.chk;
    
          if (wallObject.installationType && wallObject.supportCeiling) {
            wallObject.x = wallObject.x ? wallObject.x : "";
            wallObject.y = wallObject.y ? wallObject.y : "";
          }
    
          return wallObject;
        });
      };

      const groupCeilingsDataFromSelection = async (selection: any, cng: any) => {
        /// Extract selection ids
        let selectionIds = map(
          selection,
          (n) => {
            return n.Id;
          }
        );
    
        /// Extract selection list based on ids
        let selectionList = filter(selection, function (o) {
          return includes(selectionIds, o.Id);
        });
    
        /// 1. Extract selected wall ids from selection.
        let ceilingRows = flattenDeep(
          map(selectionList, (ele) => {
            return map(ele.SelectionByType.ceiling.Rows, (row) => {
              return {
                ...row,
                zone: ele.Zone ? ele.Name : "",
              };
            });
          })
        );
    
        let ceilingIdsExt = flattenDeep(
          map(ceilingRows, (ele) => {
            if (ele.RevitSelection) {
              return ele.RevitSelection.Ids;
            }
          })
        );
    
        /// 2. Check for duplicate wall ids
        let dupliactesCeilingIds = keysIn(
          pickBy(groupBy(ceilingIdsExt), (x) => {
            return x.length > 1;
          })
        );
    
        if (dupliactesCeilingIds.length === 0) {
          /// 3. Get walls data from revit
          let ceilingsData = await api.queries.getCeilingsData(ceilingIdsExt);
    
          /// 4. Extract solution ids
          let solutionIds = uniq(
            flattenDeep(
              map(ceilingRows, (ele) => {
                return ele.Options.MappedSystem.oid;
              })
            )
          );
         
          /// 5. Get solution data from api respected to solution ids
          const { extractedSolutionProduct } =
            await fetchPlacoCeilingDataInGroupSplit(solutionIds, cng);
    
          ceilingsData = orderBy(ceilingsData, ["LevelElevation"], ["asc"]);
    
          let grpCeilingsData: any[] = [];
    
          _.forEach(ceilingsData, (value: any, index: number) => {
            let ceilingRow = find(ceilingRows, (walRow: any) => {
              return includes(walRow.RevitSelection.Ids, value.Id);
            });
    
            let height: String = parseFloat(
              (value.Height / 1000).toString()
            ).toFixed(2);
    
            let oneValue = find(grpCeilingsData, {
              LevelId: value.LevelId,
              Zone: ceilingRow.zone,
              CeilingType: value.CeilingType,
              Height: height,
              PlacoSolution: ceilingRow.Options.MappedSystem.longName
                ? ceilingRow.Options.MappedSystem.longName
                : ceilingRow.Options.MappedSystem.translation,
            });
    
            if (oneValue) {
              let ids = oneValue.Ids;
              ids.push(value.Id);
              find(grpCeilingsData, {
                LevelId: value.LevelId,
                Zone: ceilingRow.zone,
                CeilingType: value.CeilingType,
                Height: height,
                PlacoSolution: ceilingRow.Options.MappedSystem.longName
                  ? ceilingRow.Options.MappedSystem.longName
                  : ceilingRow.Options.MappedSystem.translation,
              }).Ids = ids;
            } else {
              grpCeilingsData.push({
                Height: height,
                LevelName: value.LevelName,
                PlacoSolution: ceilingRow.Options.MappedSystem.longName
                  ? ceilingRow.Options.MappedSystem.longName
                  : ceilingRow.Options.MappedSystem.translation,
                PlacoSolutionId: ceilingRow.Options.MappedSystem.oid,
                PlacoSolutionHeight:
                  ceilingRow.Options.MappedSystem["GFR-Height limit in m"],
                Zone: ceilingRow.zone,
                CeilingType: value.CeilingType,
                LevelElevation: value.LevelElevation,
                LevelId: value.LevelId,
                Ids: [value.Id],
                montant: ceilingRow.montant,
                plaque: ceilingRow.plaque,
              });
            }
          });
    
          const proceessWalls = processNewWallData(
            grpCeilingsData,
            extractedSolutionProduct
          );
          /// 7. Set state for wallsData, SolutionProducts
         return proceessWalls;
        }
      };
      //#endregion setCalepinage

      //#region  savedata
      const saveSelection = async (bufferSelection: any) =>{
        if (bufferSelection) {
          dispatch({
            type: "SAVE_BUFFER",
            selection: bufferSelection,
          });
        }
      }

        const SaveCalpinage = async(selection: any, documentName: string, calepinage: any, cng: any, projectId: any ) =>{
          let time = Date.now();
          let date = new Date();
          let selections: any[] = [];
          selections.push(selection)
          let walls = await groupWallsDataFromSelection(selections, cng);
          let ceilings = await groupCeilingsDataFromSelection(selections, cng);
          const calepinageList = await bimStorage.listModule(storageKey.CALEPINAGE, projectId);
          const filterCalepinage = filter(calepinageList, function(c:any){
            return c.Name.includes(documentName);
          });
          let data = {
            Name: documentName+'_'+ filterCalepinage.length,
            Date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
            selections: {status: true, list:[{Id:selection.Id,Time: time}]},
            calepinage :{
                status: true,
                list:{
                  walls: walls,
                  ceilings: ceilings
                }, 
                version:1
              },
            Id: v4(),
            revitView: selection.RevitView,
            time: time,
            selectionList: selections
          }
    
          //calepinage.push(data);
          await bimStorage.setModule(
            dbStoreNameCalepinage,
            data.revitView,
            data.Name,
            data.Date,
            data.Id,
            JSON.stringify(data)
          );
        }
    
      //#endregion savedata

      if (loading) {
        return (
          <Dimmer active={loading} page>
            <Loader content={progressMessage} />
          </Dimmer>
        );
      }    

return (<div>
    <SuccesPopupComponent 
            active={active} 
            setActive={setActive}  
            closePopup={closePopup} 
            header={popupDetails.header} 
            message={popupDetails.message}></SuccesPopupComponent>
</div>);
        }