import React, { useEffect, useState } from "react";
import AccordionContainer from "../../../Common/components/AccordionContainer";
import FunctionalityHeader from "../../../Common//components/FunctionalityHeader";
import DetailsProperty from "../../../Common/components/DetailsProperty";
import Button from "@material-ui/core/Button";
import { CLOISONS_ID, PLAFONDS_ID } from "../../assets/constants";
import { selectWindowToShow } from "../../store/app/effects";
import { Property } from "../../../Properties/store/properties/types";
import { connect, ConnectedProps } from "react-redux";
import { setPAMAttributesValuesModal } from "../../../Common/utils/attributes.injection.helpers";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";
import { withTranslation, WithTranslation } from "react-i18next";

import "./index.scss";

export const SystemDetailsContainer = ({
  system,
  systemTypeID,
  selectWindowToShow,
  previousWindow,
  config,
  t,
}: SystemDetailsContainerProps) => {
  const [details, setDetails] = useState<Property[]>([]);
  const [systemAttributes, setSystemAttributes] = useState<any>([]);
  const [systemName, setSystemName] = useState("");

  useEffect(() => {
    let productName = system.attributes.find((a: any) => a.technicalName === "A-Solution product name")?.values[0]?.value;
    setSystemName(productName);
    const att = system.attributes.find(
      (a: any) => a.technicalName === "GFR-Works name"
    );
    const type = att?.values[0]?.value;

    const attributesModal =
      type === "Cloisons"
        ? PAM_SYSTEM_TYPE_KEYS.WALL
        : type === "Plafonds"
          ? PAM_SYSTEM_TYPE_KEYS.CEILING
          : PAM_SYSTEM_TYPE_KEYS.LINING_WALLS;

    //const attributesModal = systemTypeID === CLOISONS_ID ? PAM_SYSTEM_TYPE_KEYS.WALL : systemTypeID === PLAFONDS_ID ? PAM_SYSTEM_TYPE_KEYS.CEILING : PAM_SYSTEM_TYPE_KEYS.LINING_WALLS
    var attributes = setPAMAttributesValuesModal(
      attributesModal,
      system.attributes
    );
    setSystemAttributes(attributes);
    console.log("system: ", system);
  }, []);

  const handleBackToLoadSystems = () => {
    selectWindowToShow(previousWindow);
  };
  return (
    <div
      className="PropertyGlobalContainer"
      style={{ overflow: "auto", paddingRight: 20, marginRight: 0 }}
    >
      <FunctionalityHeader
        name={systemName}
        icon={config.REACT_APP_SERVERURL + "/PrescriBIM_Logo.png"}
      />
      <div className="DetailsContainer">
        {systemAttributes && systemAttributes.length > 0 && (
          <div className="PropertiesContent">
            <div className="Properties">
              <div className="PropertyDetailsContent">
                {systemAttributes.map((p: any) => (
                  <AccordionContainer
                    key={t(p.chapterName) || t(p.categoryName)}
                    title={t(p.chapterName) || t(p.categoryName)}
                    properties={
                      p.chapterData &&
                      p.chapterData.map(
                        (prop: { categoryName: any; pamData: any[] }) => ({
                          title: t(prop.categoryName),
                          onClick: () =>
                            setDetails(
                              prop.pamData &&
                              prop.pamData.map((propr) => ({
                                title: t(propr.key),
                                value: propr.value,
                              }))
                            ),
                        })
                      )
                    }
                    onClick={() =>
                      p.pamData &&
                      setDetails(
                        p.pamData &&
                        p.pamData.map((prop: any) => ({
                          title: t(prop.key),
                          value: prop.value,
                        }))
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <DetailsProperty
              properties={
                details && details.length > 0
                  ? details
                  : systemAttributes[0].pamData.map((prop: any) => ({
                    title: t(prop.key),
                    value: prop.value,
                  }))
              }
            />
          </div>
        )}
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton detailActionButton"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handleBackToLoadSystems()}
        >
          {t("RETURN")}
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    system: state.systemDetails.system,
    systemTypeID: state.systems.systemTypeID,
    previousWindow: state.app.previousWindow,
    config: state.config,
  };
}

const mapDispatchToProps = {
  selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SystemDetailsContainerProps = PropsFromRedux & WithTranslation;

export default connector(withTranslation(["library"])(SystemDetailsContainer));
