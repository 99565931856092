import {
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY,
  PAM_ATTRIBUTES_TECHNICAL_NAME,
  PAM_LAYER_ATTRIBUTES,
  GENERIC_ATTRIBUTES_TO_REMOVE,
  SHARED_ATTRIBUTES,
  MATERIAL_PRODUCT_ATRRIBUTES,
  MATERIAL_CUSTOM_PARAMETER_ATRRIBUTES,
} from "../assets/attributes.injection";
import {
  WALLSYSTEM_ID,
  FLOORSYSTEM_ID,
} from "../../LoadSystemContainer/assets/constants";
import { PAM_CATEGORIES_KEYS, PAM_ATTRIBUTES_KEYS } from "../assets/types";
import _ from "lodash";
import { getWeberSystemsDetailsWithRelations } from "../../LoadSystemContainer/services/project.service";

export const setPAMAttributesModal = (type, attributes, selectedCategories) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  const generalData = attributesByType.find(
    (a) => a.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
  );
  attributesModal.push({
    checked: true,
    categoryName: generalData.categoryName,
    pamData: generalData.pamData.map((attribute) => {
      return {
        aid: attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        )?.aid,
        technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
        key: attribute,
      };
    }),
  });
  const chaptersNames = attributesByType
    .map((x) => x.chapterName)
    .filter(
      (value, index) =>
        value &&
        attributesByType.map((x) => x.chapterName).indexOf(value) === index
    );
  attributesModal.push(
    ...chaptersNames.map((chapterName) =>
      setPAMAttributesModalByChapter(
        chapterName,
        attributesByType,
        attributes,
        selectedCategories
      )
    )
  );

  return attributesModal;
};

export const getDefaultConfigurationByType = (type, attributes) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  attributesByType.forEach((conf) => {
    attributesModal.push({
      checked: true,
      categoryName: conf.categoryName,
      pamData: conf.pamData.map((attribute) => {
        return {
          aid: attributes.find(
            (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
          )?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
        };
      }),
    });
  });

  return attributesModal;
};

const setPAMAttributesModalByChapter = (
  chapterName,
  attributesByType,
  attributes,
  selectedCategories
) => {
  const attributesByChapter = attributesByType
    .filter((attribute) => attribute.chapterName === chapterName)
    .map((a) => ({
      checked: selectedCategories.some(
        (cat) => cat.categoryName === a.categoryName
      ),
      categoryName: a.categoryName,
      pamData: a.pamData.map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
        };
      }),
    }));
  return {
    chapterName: chapterName,
    chapterData: attributesByChapter,
  };
};

export const getRelationofObjects = async (data, config) => {
  //let { liningWallsDetails, partitionsDetails, ceilingsDetails } = data;
  let { floorSystemDetails, wallsSystemDetails } = data;
  let Oid = floorSystemDetails
    .map((a) => a.oid)
    .concat(wallsSystemDetails.map((a) => a.oid));

  let obj = null;
  await getWeberSystemsDetailsWithRelations(Oid, config).then((response) => {
    obj = response.data;
    floorSystemDetails = GetMaterialDetails(floorSystemDetails, obj, config);
    wallsSystemDetails = GetMaterialDetails(wallsSystemDetails, obj, config);
  });
};

export const GetMaterialDetails = (sectionDetails, obj, config) => {
  for (let section of sectionDetails) {
    let layerArr = section.attributes.filter(
      (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    );
    let linningRelArr = obj.context.relations.filter(
      (a) => a.oid1 === section.oid
    );
    let sectionChild = [];
    // let layeridArr = [];
    for (let layr of layerArr) {
      let order = layr.subAttributes.find(
        (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NUMBER
      ).values[0].numericValue;
      let layerId = GetLayerId(order, linningRelArr);
      //layeridArr.push({ Id: , order: order });

      for (let child of obj.objects.filter((a) => a.oid === layerId)) {
        child.order = order;

        MATERIAL_PRODUCT_ATRRIBUTES.map((param) => {
          let attrValue = child.attributes.find(a => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[param]);
          let attribute = null;
          if(attrValue?.values[0]){
            attribute = attrValue.values[0].value;
          }

          if (PAM_ATTRIBUTES_TECHNICAL_NAME[param] === "BIM 3D texture" && child.mediaDescriptors) {
            attribute = child.mediaDescriptors.find(m => m.types.includes(PAM_ATTRIBUTES_TECHNICAL_NAME[param]))?.url;
            if (attribute) {
              attribute = config.REACT_APP_MODEL_GEN_BIMDATABASE_API_URL + attribute;
            }
          }

          let dt = JSON.parse(JSON.stringify(layr.subAttributes[0]));
          dt.technicalName = PAM_ATTRIBUTES_TECHNICAL_NAME[param];
          dt.values[0].value = attribute === null || undefined ? null : attribute;
          dt.aid = 0;
          layr.subAttributes.push(dt);
          sectionChild.push(child);
          
          return param;
        });

        MATERIAL_CUSTOM_PARAMETER_ATRRIBUTES.map((param) => {
          let attribute = child.attributes.find(a => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[param])?.values[0].value;

          let dt = JSON.parse(JSON.stringify(layr.subAttributes[0]));
          dt.technicalName = PAM_ATTRIBUTES_TECHNICAL_NAME[param];
          dt.values[0].value = attribute === null || undefined ? null : attribute;
          dt.aid = 0;
          layr.subAttributes.push(dt);
          sectionChild.push(child);
          return param;
        });
      }
    }

    // for (let layId of layeridArr) {

    // }
    section.child = sectionChild;
  }
};

export const GetLayerId = (order, relationList) => {
  let layerId = null;
  for (let rel of relationList) {
    if ( rel.attributes.length > 0 &&
      rel.attributes?.find(
        (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_DATA
      )?.values !== null
    ) {
      let value = rel.attributes?.find(
        (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_DATA
      ).values;
      if (value.filter((a) => a.numericValue === order).length === 1) {
        layerId = rel.oid2;
        break;
      }
    }
  }
  return layerId;
};

export const fillSystemsModel = (
  properties,
  data,
  isDefaultConfiguration,
  t
) => {
  const systemsModel = [];
  const { wallsSystemDetails, floorSystemDetails } = data;
  let { wallsSystemProperties, floorSystemProperties } = properties;

  let wallsProperties1 = [];
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.WALL.forEach((cat) =>
    wallsProperties1.push({
      ...cat,
      toDelete:
        !wallsSystemProperties
          .map((c) => c.categoryName)
          .includes(cat.categoryName),
      toEmpty:
        !wallsSystemProperties
          .map((c) => c.categoryName)
          .includes(cat.categoryName)
    })
  );

  let floorProperties1 = [];
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.FLOOR.forEach((cat) =>
    floorProperties1.push({
      ...cat,
      toDelete:
        !floorSystemProperties
          .map((c) => c.categoryName)
          .includes(cat.categoryName),
      toEmpty:
        !floorSystemProperties
          .map((c) => c.categoryName)
          .includes(cat.categoryName),
    })
  );

  //let wallsProperties1 = [];
  // PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.WALL.forEach((cat) =>
  //   wallsProperties1.push({
  //     ...cat,
  //     toDelete:
  //       !wallsSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName) &&
  //       !floorSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName),
  //     toEmpty:
  //       wallsSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName) &&
  //       !floorSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName),
  //   })
  // );
  //let partitionsProperties1 = [];
  // let floorProperties1 = [];
  // PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.FLOOR.forEach((cat) =>
  //   floorProperties1.push({
  //     ...cat,
  //     toDelete:
  //       !wallsSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName) &&
  //       !floorSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName),
  //     toEmpty:
  //       !wallsSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName) &&
  //       floorSystemProperties
  //         .map((c) => c.categoryName)
  //         .includes(cat.categoryName),
  //   })
  // );

  systemsModel.push(
    ...fillSystemsModelCategorie(
      wallsProperties1,
      wallsSystemDetails,
      PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.WALL,
      "wall",
      isDefaultConfiguration,
      t
    )
  );
  systemsModel.push(
    ...fillSystemsModelCategorie(
      floorProperties1,
      floorSystemDetails,
      PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.FLOOR,
      "floor",
      isDefaultConfiguration,
      t
    )
  );

  return systemsModel;
};

const fillSystemsModelCategorie = (
  properties,
  systems,
  propertiesModel,
  systemType,
  isDefaultConfiguration,
  t
) => {
  if (isDefaultConfiguration) {
    properties = properties.map((property) =>
      isDefaultConfiguration &&
        property.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
        ? {
          categoryName: PAM_CATEGORIES_KEYS.GENERAL_DATA,
          checked: true,
          pamData: property.pamData.filter(
            (p) => !GENERIC_ATTRIBUTES_TO_REMOVE.some((att) => att === p.key)
          ),
        }
        : property
    );
  }
  return systems.map((system) => {
    return {
      solution: isDefaultConfiguration
        ? system.translation
          ? system.translation
          : null
        : system.translation,
      productId: system.externalName,
      elementType: systemType,
      paramData: [
        ...fillSystemProperties(
          system,
          properties,
          propertiesModel,
          isDefaultConfiguration,
          t,
          systemType
        ),
      ],
      materialAttributes: fillMaterialAttributes(system, t),
    };
  });
};

const fillSystemProperties = (
  system,
  properties,
  propertiesModel,
  isDefaultConfiguration,
  t,
  systemType
) => {
  let finalPropertiesModel = [];
  propertiesModel.forEach((element) => {
    element.pamData.forEach((property) => {
      const att = system.attributes.find(
        (attribute) =>
          PAM_ATTRIBUTES_TECHNICAL_NAME[property] === attribute.technicalName
      );

      if (property === PAM_ATTRIBUTES_KEYS.GENERATION_DATE) {
        var today = new Date();

        finalPropertiesModel.push({
          category: t(element.categoryName),
          key: t(property),
          value: `${today.getFullYear()}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today
              .getDate()
              .toString()
              .padStart(2, "0")} ${today
                .toTimeString()
                .toString()
                .replace("GMT", "UTC")}`,
          toDelete: false,
          toEmpty: false,
        });
      } else {
        finalPropertiesModel.push({
          category: t(element.categoryName),
          key: t(property),
          value:
            att?.values[0]?.value?.split('"').join("") ||
            null,
          toDelete:
            (isDefaultConfiguration
              ? GENERIC_ATTRIBUTES_TO_REMOVE.some((prop) => prop === property)
              : false) ||
            // (isDefaultConfiguration && systemType === "wall"
            //   ? GENERIC_ATTRIBUTES_TO_REMOVE.some((prop) => prop !== "WEBSITE" && prop === property) :
            //   (isDefaultConfiguration && systemType === "floor") ?
            //     GENERIC_ATTRIBUTES_TO_REMOVE.some((prop) => prop === "WEBSITE" && prop === property)
            //     : false) ||
            properties.find((cat) => cat.categoryName === element.categoryName)
              .toDelete,
          toEmpty:
            properties.find((cat) => cat.categoryName === element.categoryName)
              .toEmpty &&
            (SHARED_ATTRIBUTES.some((prop) => prop === property)
              ? finalPropertiesModel
                .filter((prop) => prop.Key === t(property))
                .every((prop) => prop.toEmpty)
              : true),
        });
      }
    });
  });
  let finalPropertiesM = [];
  finalPropertiesModel.forEach((e) => {
    console.log(
      e.key,
      finalPropertiesModel.filter((prop) => prop.key === e.key),
      finalPropertiesModel
        .filter((prop) => prop.key === e.key)
        .every((prop) => prop.toDelete),
      SHARED_ATTRIBUTES.some((property) => t(property) === e.key)
    );
    finalPropertiesM.push({
      ...e,
      toDelete:
        e.toDelete &&
        (SHARED_ATTRIBUTES.some((property) => t(property) === e.key)
          ? finalPropertiesModel
            .filter((prop) => prop.key === e.key)
            .every((prop) => prop.toDelete)
          : true),
      toEmpty:
        e.toEmpty &&
        (SHARED_ATTRIBUTES.some((property) => t(property) === e.key)
          ? finalPropertiesModel
            .filter((prop) => prop.key === e.key)
            .every((prop) => prop.toEmpty)
          : true),
    });
  });
  return finalPropertiesM;
};

const fillMaterialAttributes = (system, t) => {
  console.log("system", system);
  let propertiesModel = [];
  let attributes = system.attributes
    .filter(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    )
    .filter(
      (layer) =>
        !layer.subAttributes
          .find(
            (attribute) =>
              attribute.technicalName ===
              PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
          )
          ?.values[0]?.value?.includes("Mur support")
    );
  attributes.forEach((element) => {
    propertiesModel.push(
      PAM_LAYER_ATTRIBUTES.map((param) => {
        var subAtt = element?.subAttributes?.find(
          (att) => att.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[param]
        );

        return {
          label: t(param),
          value: subAtt?.values[0]?.value || null,
        };
      })
    );
  });
  return propertiesModel;
};

export const setPAMAttributesValuesModal = (type, attributes) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  const generalData = attributesByType.find(
    (a) => a.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
  );

  attributesModal.push({
    categoryName: generalData.categoryName,
    pamData: generalData.pamData
      .filter((attribute) => attribute !== PAM_ATTRIBUTES_KEYS.GENERATION_DATE && attribute !== PAM_ATTRIBUTES_KEYS.SYSTEM_ID)
      .map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
          value: att?.values[0]?.value?.split('"').join("") || null,
        };
      }),
  });
  const chaptersNames = attributesByType
    .map((x) => x.chapterName)
    .filter(
      (value, index) =>
        value &&
        attributesByType.map((x) => x.chapterName).indexOf(value) === index
    );
  attributesModal.push(
    ...chaptersNames.map((chapterName) =>
      setPAMAttributesValuesModalByChapter(
        chapterName,
        attributesByType,
        attributes
      )
    )
  );
  attributes = attributes.filter(
    (att) =>
      !Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME).some(
        (tech) => att.technicalName === tech
      )
  );

  return attributesModal;
};

const setPAMAttributesValuesModalByChapter = (
  chapterName,
  attributesByType,
  attributes
) => {
  const attributesByChapter = attributesByType
    .filter((attribute) => attribute.chapterName === chapterName)
    .map((a) => ({
      categoryName: a.categoryName,
      pamData: a.pamData.map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
          value:
            attribute === PAM_ATTRIBUTES_KEYS.TECHNICAL_OPINION_OR_DTU_NUMBER
              ? att?.values[0]?.value.replace('"', " ").replace('®"', " ")
              : attribute === PAM_ATTRIBUTES_KEYS.ACTIV_AIR
                ? att?.values[0]?.value === "True"
                  ? "Vrai"
                  : "Faux"
                : attribute === PAM_ATTRIBUTES_KEYS.NAME_OF_INSULATION
                  ? attributes.find(
                    (attribute) =>
                      attribute.technicalName.includes(
                        PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
                      ) &&
                      (attribute.values[0]?.value.includes("PAR") ||
                        attribute.values[0]?.value.includes("Isoconfort") ||
                        attribute.values[0]?.value.includes("GR 32") ||
                        attribute.values[0]?.value.includes("IBR") ||
                        attribute.values[0]?.value.startsWith("Laine"))
                  )?.values[0]?.value
                  : att?.values[0]?.numericValue ||
                  att?.values[0]?.value?.split('"').join("") ||
                  null,
        };
      }),
    }));
  return {
    chapterName: chapterName,
    chapterData: attributesByChapter,
  };
};

export const fillConfigurationModal = (system, t) => {
  const SystemConfiguration = {
    systemType: system.attributes.find(
      (attribute) =>
        attribute.technicalName ===
        PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION
    )?.values[0]?.value,
    parement: fillParmentConfiguration(system, t),
    profile: system.attributes.find(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D
    )?.values[0]?.value,
    frameDistance: system.attributes.find(
      (attribute) =>
        attribute.technicalName ===
        PAM_ATTRIBUTES_TECHNICAL_NAME.CENTER_DISTANCE_BETWEEN_FRAMES_M
    )?.values[0]?.value,
    rail: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) && attribute.values[0]?.value.includes("Rail")
      )?.values[0]?.value,
      value: "",
    },
    montant: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) && attribute.values[0]?.value.includes("Montant")
      )?.values[0]?.value,
      value: "",
    },
    isolation: fillIsolationConfiguration(system, t),
    // isolation: {
    //   name: system.attributes.find(
    //     (attribute) =>
    //       attribute.technicalName.includes(
    //         PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
    //       ) &&
    //       (attribute.values[0]?.value.includes('PAR') ||
    //         attribute.values[0]?.value.includes('Isoconfort') ||
    //         attribute.values[0]?.value.includes('GR 32') ||
    //         attribute.values[0]?.value.includes('IBR') ||
    //         attribute.values[0]?.value.startsWith('Laine'))
    //   )?.values[0]?.value,
    //   value: "",
    // },
  };
  return SystemConfiguration;
};

const fillIsolationConfiguration = (system, t) => {
  const attributes = system.attributes.filter((attribute) =>
    attribute.technicalName.includes(PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT)
  );

  const model = attributes
    .filter(
      (attribute) =>
        attribute.values[0]?.value.includes("PAR") ||
        attribute.values[0]?.value.includes("Isoconfort") ||
        attribute.values[0]?.value.includes("GR 32") ||
        attribute.values[0]?.value.includes("IBR") ||
        attribute.values[0]?.value.startsWith("Laine")
    )
    .map((attribute) => attribute?.values[0]?.value);

  return model;
};

const fillParmentConfiguration = (system, t) => {
  const configurationModel = [];
  const bimLayers = system.attributes
    .filter(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    )
    .filter(
      (layer) =>
        !layer.subAttributes
          .find(
            (attribute) =>
              attribute.technicalName ===
              PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
          )
          ?.values[0]?.value?.includes("Mur support")
    );
  let ParmentNumber = 1;
  for (
    let currentLayerNumber = 1;
    currentLayerNumber <= bimLayers.length + 1;
    currentLayerNumber++
  ) {
    const currentLayer = bimLayers.find(
      (lay) =>
        lay.subAttributes.find(
          (attribute) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NUMBER
        )?.values[0]?.numericValue === currentLayerNumber
    );
    if (
      currentLayer?.subAttributes.find(
        (attribute) =>
          attribute.technicalName ===
          PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
      )?.values[0]?.value === "Plaque de plâtre"
    ) {
      configurationModel.push({
        parmentNumber: ParmentNumber,
        layerThickness: currentLayer.subAttributes.find(
          (attribute) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_THIKNESS_MM
        )?.values[0]?.value,
        layerName: currentLayer.subAttributes.find(
          (attribute) =>
            attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NAME
        )?.values[0]?.value,
      });
    }
    if (
      currentLayer?.subAttributes.find(
        (attribute) =>
          attribute.technicalName ===
          PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
      )?.values[0]?.value !== "Plaque de plâtre" &&
      configurationModel.filter((p) => p.parmentNumber === 1).length > 0
    ) {
      ParmentNumber = 2;
    }
  }
  return configurationModel;
};

export const getSystemTypeByWorksNameObject = (system) => {
  const gfrWorksNameObject = _.find(system.attributes, {
    technicalName: "WID-System Family",
  });
  if (gfrWorksNameObject) {
    const valueObject = _.get(gfrWorksNameObject, "values");
    if (valueObject[0].value === "Wall System")
      return WALLSYSTEM_ID;
    if (valueObject[0].value === "Floor System")
      return FLOORSYSTEM_ID;
    //if (valueObject[0].value === "Isolation des murs") return DOUBLAGES_ID;
  }
  return WALLSYSTEM_ID;
};

