import { combineReducers, createStore, applyMiddleware, Action } from "redux";
import { errorData, Plugin } from "../RevitJS/Types/StoreTypes";
import { pluginsData } from "../Plugins";
import thunk from "redux-thunk";
import { ID, Language } from "../RevitJS/Types/RevitTypes";
import { FakeModel } from "../RevitJS/Types/FakerTypes";
import { Models } from "../RevitFaker/Models";
import {
  InitLanguageAction,
  INIT_LANGUAGE,
  SET_LANGUAGE,
  LanguageAction,
  SET_ERROR_MODAL,
} from "../Actions/types";

export interface StoreState {
  plugins: Plugin[];
  selectedPluginId: ID;
  popUpWindow: boolean;
  popUpHidden: boolean;
  manualSelection: boolean;
  windowDimensions: { width: number; height: number };
  popUpComponent: any;
  fakeModels: FakeModel[];
  selectedFakeModel: FakeModel;
  language: Language;
  languageInitialized: boolean;
  errorModalData: errorData;
  config: any;
}

const config = function (state = {}, action: any) {
  switch (action.type) {
    case "SET_CONFIG":
      return action.payload;
    default:
      return state;
  }
};

const plugins = function (plugins = pluginsData, action: Action) {
  switch (action.type) {
    default:
      return plugins;
  }
};

const selectedPluginId = function (selectedPluginId = null, action: any) {
  switch (action.type) {
    case "SELECT_PLUGIN":
      return action.id;
    case "RESET_SELECTED_PLUGIN":
      return null;
    default:
      return selectedPluginId;
  }
};

const popUpWindow = function (popUpWindow = false, action: any) {
  switch (action.type) {
    case "OPEN_WINDOW":
      return true;
    case "CLOSE_WINDOW":
      return false;
    default:
      return popUpWindow;
  }
};

const popUpHidden = function (popUpHidden = false, action: any) {
  switch (action.type) {
    case "SHOW_WINDOW":
      return false;
    case "HIDE_WINDOW":
      return true;
    default:
      return popUpHidden;
  }
};

const manualSelection = function (manualSelection = false, action: any) {
  switch (action.type) {
    case "START_SELECTION":
      return true;
    case "END_SELECTION":
      return false;
    default:
      return manualSelection;
  }
};

const windowDimensions = function (
  windowDimensions = { width: 800, height: 500 },
  action: any
) {
  switch (action.type) {
    case "RESIZE_WINDOW":
      return action.windowDimensions;
    default:
      return windowDimensions;
  }
};

const popUpComponent = function (popUpComponent = null, action: any) {
  switch (action.type) {
    case "SET_POPUP_CONTENT":
      return action.component;
    default:
      return popUpComponent;
  }
};

const fakeModels = function (fakeModels = Models, action: any) {
  switch (action.type) {
    default:
      return fakeModels;
  }
};

const selectedFakeModel = function (
  selectedFakeModel = Models[0],
  action: any
) {
  switch (action.type) {
    default:
      return selectedFakeModel;
  }
};

const language = function (
  language: Language = "French",
  action: LanguageAction
) {
  switch (action.type) {
    case INIT_LANGUAGE:
      return action.language;
    case SET_LANGUAGE:
      return action.language;
    default:
      return language;
  }
};

const languageInitialized = function (
  languageInitialized: boolean = false,
  action: InitLanguageAction
) {
  switch (action.type) {
    case INIT_LANGUAGE:
      return true;
    default:
      return languageInitialized;
  }
};

const errorModalData = function (
  errorModalData: errorData = {
    Open: false,
    Code: "001",
    Report: null,
    Reload: false,
  },
  action: any
) {
  switch (action.type) {
    case SET_ERROR_MODAL:
      return action.value;
    default:
      return errorModalData;
  }
};

const reducers = combineReducers({
  plugins,
  selectedPluginId,
  popUpWindow,
  manualSelection,
  popUpHidden,
  windowDimensions,
  popUpComponent,
  fakeModels,
  selectedFakeModel,
  language,
  languageInitialized,
  errorModalData,
  config,
});

export const oldStore = createStore(reducers, applyMiddleware(thunk));
