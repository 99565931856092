import {
  setHeaderStyle,
  informationTable,
  adjustColumnsSize,
  seTableStyle,
} from "./tableHelaper";
import _, { forEach } from "lodash";
import { api } from "../../../RevitJS/API";

export const profileQuantities = async (ProfilesSheet:any) => {
  let headersMainProfile = [
    { ref: "A1", name: "Floor" },
    { ref: "B1", name: "Rigips system" },
    { ref: "C1", merge: "C1:D1",  name: "Rigips profile type" },
    { ref: "E1", merge: "E1:G1", name: "Quantity profiles" },
  ];
  const mainTableHeaders = setHeaderStyle(ProfilesSheet, headersMainProfile);

 
  let mainTableDetailColumns = [
    { name: "Nr", filterButton: true },
    { name: "Nr", filterButton: true },
    { name: "rails? Frame?", filterButton: true },
    { name: "rails? Frame?", filterButton: true },
    { name: "XX", filterButton: true },
    { name: "XX", filterButton: true },
    { name: "mm", filterButton: true },
  ];

  let profileTableDetails  = await getProfiles();

  _.forEach(_.times(profileTableDetails.profilesData.length + 1, Number), (value, index) => {   
    const rowNumber = index + 2;
    ProfilesSheet.mergeCells(`C${rowNumber}:D${rowNumber}`)
    ProfilesSheet.mergeCells(`E${rowNumber}:F${rowNumber}`)
  })
  


  ProfilesSheet.addTable({
    name: "Profiles",
    ref: "A2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
      showRowStripes: true,
    },
    columns: mainTableDetailColumns,
    rows: profileTableDetails.profilesData,
  });
  let totalTableProfile = [
    { ref: "H1", merge: "H1:J1", name: "Total quantity profiles per type" },
  ];
  const totalTableHeaders = setHeaderStyle(
    ProfilesSheet,
    totalTableProfile,
    "548232"
  );

  let totalTableeDetailColumns = [
    { name: "Name", filterButton: true },
    { name: "XX", filterButton: true },
    { name: "m2", filterButton: true },
  ];
  ProfilesSheet.addTable({
    name: "Profiles",
    ref: "H2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
      showRowStripes: true,
    },
    columns: totalTableeDetailColumns,
    rows: profileTableDetails.profilesDataTotal,
  });

  const TableInformation = informationTable(ProfilesSheet, profileTableDetails.profilesData.length + 3);
  const TablesStyle = seTableStyle(ProfilesSheet, profileTableDetails.profilesData.length + 3);
  applyColumnSize(ProfilesSheet);
};

const applyColumnSize = (insulationSheet:any) => {
  insulationSheet.getColumn("A").width = 12;
  insulationSheet.getColumn("B").width = 12;
  insulationSheet.getColumn("C").width = 11;
  insulationSheet.getColumn("D").width = 11;
  insulationSheet.getColumn("E").width = 11;
  insulationSheet.getColumn("F").width = 11;
  insulationSheet.getColumn("G").width = 11;
  insulationSheet.getColumn("H").width = 11;
  insulationSheet.getColumn("I").width = 11;
  insulationSheet.getColumn("J").width = 11;
};

const getProfiles = async () => {
  const profilesData:any = [];
  const railBasIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Rigips_Rail-bas" },
        Rule: "Equals",
      }
    ],
    null
  );

  const railHautIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Rigips_Rail-haut" },
        Rule: "Equals",
      }
    ],
    null
  );

  const ossatureIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Rigips_Ossature" },
        Rule: "Equals",
      }
    ],
    null
  );

  const ossatureDoubleIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Rigips_Ossature-Double",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  const profilesIds = [...railBasIds, ...railHautIds, ...ossatureIds, ...ossatureDoubleIds];

  let profilesDetails = await api.queries.getObjectsParams(profilesIds, [
    "id",
    "Longueur",
    "Nom",
    "systemName"
  ]);

  profilesDetails = profilesDetails.map((board:any) => ({
    Id: board.Id,
    WallId: board.Params.find((p:any) => p.Name === "id")?.Value,
    Name: board.Params.find((p:any) => p.Name === "Nom")?.Value,
    Length: board.Params.find(
      (p:any) => p.Name === "Longueur"
    )?.Value
  }));

  const wallIds = _.uniq(_.map(profilesDetails, "WallId"));
  const wallSystemParameter = await api.queries.getObjectsParams(wallIds, [
    "SG_System",
  ]);

  profilesDetails = profilesDetails.map((board:any) => {
    board.SystemName = wallSystemParameter.find(
      (p:any) => p.Id === board.WallId
    ).Params[0].Value;
    return board;
  });

  const walltree = await api.selection.elementsByLevelAndType("wall");
  console.log("walltree: ", walltree);
  walltree.Tree.forEach((level) => {
    profilesData.push([level.Level.Name, " ", " ", " ", " ", " ", " "]);
    let wallsIdByLevel:any = [];
    level.Elements.forEach((element) => {
      wallsIdByLevel.push(...element.Ids);
    });
    let ProfilesBySystemName = profilesDetails.filter((board:any) =>
      wallsIdByLevel.includes(board.WallId.toString())
    );
    
    ProfilesBySystemName = _.chain(ProfilesBySystemName)
      .groupBy("SystemName")
      .map((value, key) => ({ SystemName: key, profilesDetails: value }))
      .value();
    ProfilesBySystemName.forEach((p:any) => {
      profilesData.push([" ", p.SystemName, " "," ", " ", " ", " "]);
      
      let ProfilesByProfileType = _.chain(p.profilesDetails)
        .groupBy("Name")
        .map((value, key) => ({ ProfileType: key, profilesDetails: value }))
        .value();

      console.log("ProfilesByProfileType:", ProfilesByProfileType);
      ProfilesByProfileType.forEach((board) => {
        profilesData.push([" ", " ", board.ProfileType, board.ProfileType," ", " ", " "]);
        let ProfilesByLength = _.chain(board.profilesDetails)
          .groupBy("Length")
          .map((value, key) => ({ Length: key, profilesDetails: value }))
          .value();
          
        ProfilesByLength.forEach((t) => {
          profilesData.push([
            " ",
            " ",
            " ",
            " ",
            t.profilesDetails.length,
            t.profilesDetails.length,
            parseFloat(t.Length)
          ]);
        });
      });
    });
  });

  _.forEach(_.times(3, _.constant(0)), function (value) {
    profilesData.push([" ", " ", " ", " ", " ", " ", " "]);
  });

  console.log("profilesData: ", profilesData);

  let profilesDataTotal:any = [];
  let ProfilesTotal = _.chain(profilesDetails)
    .groupBy("Name")
    .map((value, key) => ({ SystemName: key, profilesDetails: value }))
    .value();

  ProfilesTotal.forEach((p) => {
    profilesDataTotal.push([
      p.SystemName,
      p.profilesDetails.length,
      parseFloat(p.profilesDetails.reduce((acc, board) => acc + board.Length, 0)),
    ]);
  });

  const totalBlankRowAdded = profilesData.length - profilesDataTotal.length;

  _.forEach(_.times(totalBlankRowAdded, _.constant(0)), function (value) {
    profilesDataTotal.push([" ", " ", " "]);
  });

  console.log("profilesDataTotal: ", profilesDataTotal);
  return { profilesData, profilesDataTotal };
};
