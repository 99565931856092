import { FilterType } from "../../../../RevitJS/Types/StoreTypes";
import { AnyAction } from "redux";
import { setSelectorTypeAction, setFilterTypeAction } from "../Actions/types";

type filterTypeAction = setSelectorTypeAction | setFilterTypeAction;

export const filterType = (filterType: FilterType = "partitionWall", action: filterTypeAction): FilterType =>{
    switch(action.type){
        case "SET_SELECTOR_TYPE":
            switch(action.selectorType){
                case "wall":
                    return "partitionWall";
                default:
                    return "ceiling"
            }
        case "SET_FILTER_TYPE":
            return action.filterType;
        default:
            return filterType;
    }
}