import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { AppBody } from "./Page/AppBody";

export const functionalityId = "32579ddf-b039-4dae-afa2-0f71dc48c4a2";

export const selectionTrigger = async (config: any) => {
  localStorage.setItem("load", "true");

  api.windowsHandler.openWindow(
    900,
    550,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Selection",
    module: "GYPROC",
  });
};

export const GyprocSelectionRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="gyprocSelection"
    >
      <AppBody />
    </ParentRoot>
  );
};

export const Selection: Functionality = {
  Name: {
    French: "Sélection",
    English: "Selection",
    German: "Sélection",
    Italian: "Sélection",
    Indonesian: "Sélection",
  },
  Trigger: selectionTrigger,
  ShortDesc: {
    French: "Gérer les systèmes Revit",
    English: "Manage Revit Systems",
    German: "Gérer les systèmes Revit",
    Italian: "Gestisci i sistemi Revit",
    Indonesian: "Kelola Sistem Revit",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Sélection.png",
  RootComponent: GyprocSelectionRootComponent,
  ParentId: "0",
};
