import React from "react";
import { Grid, Header, Menu, Column } from "semantic-ui-react";
import { Checkbox } from "@material-ui/core";
import { LevelItems } from "./LevelItems";
import LevelsToogler from "./LevelsToogler";
import "./selectorStyle.scss";
import {filter} from "lodash";


export class LevelsColumn extends React.Component {
    state = {
        selection: null,
    };

    componentDidMount() {}

    render() {
       
        let allChk  = filter(this.props.levelsData, { Checked: true });
        return (
            <Grid.Column>
                <Header as="h3" textAlign="center" color={this.props.color}>
                    {this.props.columnTitle}
                </Header>
                    <Menu
                        style={{
                            height: "calc(100vh - 260px)",
                            overflowY: "auto",
                            margin: "auto",
                        }}
                        vertical
                        size="small"
                        fluid
                    >
                        <LevelItems
                            selectLevel={this.props.selectLevel}
                            levelsData={this.props.levelsData}   
                        />
                    </Menu>

                {}

                {this.props.selectAllLevels && (
                    <div
                        id="select-all-checkbox-div"
                        className={`d-flex justify-content-center checkbox-color-${this.props.color}`}
                    >
                        <Checkbox
                            onChange={(e) => {
                                this.props.selectAllLevels(
                                    e.currentTarget.checked 
                                );
                            }} 
                            checked={this.props.levelsData.length === allChk.length}
                            style={{ maxWidth: "80%" }}
                        />
                    </div>
                )}
            </Grid.Column>
        );
    }
}
