import React, { useState, useEffect, Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { AppBodyMet } from "./AppBody";
import { composeWithDevTools } from "redux-devtools-extension";
import { setPluginDataToDB } from "../../../../Helper";
import {
    withErrorSubscription,
    InjectedCounterProps,
  } from "../../../../ErrorManagement/components/ErrorBoundry";
type Props = InjectedCounterProps;


export enum Routes {
  SELECTION_METRES_HOME = "SELECTION_METRES_HOME",
  ROOT = "SELECTIONLANDING",
  SELECTION_HOME = "SELECTION_HOME",
  QUICK_CALEPINAGE = "QUICK_CALEPINAGE"
}

interface State {
  route: Routes;
}

const MetresRoot = (props: any) => {
  const [route, setRoute] = useState(Routes.ROOT);



  const setRouteMetres = (route: Routes) => {
    setRoute(route);
  };

  useEffect(() => {
    // api.windowsHandler.showDevTools();
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      setPluginDataToDB("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      setPluginDataToDB("savedLanguage", urlParams.get("language")!);
    }
  }, []);

    return (
        <AppBodyMet 
        route = {route}
        setRoute = {setRouteMetres}
        />
    );
}

// export default connect(mapStateToProps, mapDispatchToProps)(DrawRoot)
export default withErrorSubscription(MetresRoot);