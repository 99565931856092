import React, { Component } from "react";
import { Container, Button, Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
    setSelectionTree,
    initGroupSelector,
    initGroupSelectorCeiling,
    initMappingTableWallsManualSelection,
    initMappingTableCeilingsManualSelection
} from "../Actions";
import { Routes } from "./root";
import { selectedItemsManualSelection } from "../../../../RevitJS/Helpers";
import { WithTranslation, withTranslation } from "react-i18next";
import { clearTree, filterTree } from "../Helpers";
import { MyConfig } from "../../../../Helper";

interface Props {
    Icon: string;
    name: string;
    setRoute: any;
    setSelectionTree: any;
    initGroupSelector: any;
    initGroupSelectorCeiling: any;
    initMappingTableWallsManualSelection: any;
    initMappingTableCeilingsManualSelection: any
}

interface State { isloading: boolean }

export class SelectionLanding extends Component<
    Props & WithTranslation,
    State
> {
    state = {
        isloading: true,
    };

    componentDidMount = async () => {
        api.windowsHandler.resizeWindow(600, 200);
        const config = await MyConfig();
        let isTransferedSuccess = await api.queries.transferProjectStandards(config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS, "alba_template.rte");
        this.setState({ isloading: false })
    };

    manualSelectionHandler = async () => {
        api.windowsHandler.hideWindow();
        let selectionTree = await api.selection.manualSelection("wall&ceiling");
        let wallTree = clearTree(filterTree(selectionTree, "wall"));
        let ceilingTree = clearTree(filterTree(selectionTree, "ceiling"));
        let selectedItemsWalls = selectedItemsManualSelection(wallTree);
        this.props.initMappingTableWallsManualSelection(selectedItemsWalls);
        let selectedItemsCeilings = selectedItemsManualSelection(ceilingTree);
        this.props.initMappingTableCeilingsManualSelection(selectedItemsCeilings)
        this.props.setRoute(Routes.MAPPING);
        api.windowsHandler.showWindow();
    };

    groupSelectionHandler = async () => {
        let tree = await api.selection.elementsByLevelAndType("wall");
        let treeCeiling = await api.selection.elementsByLevelAndType("ceiling");
        this.props.initGroupSelector(tree);
        this.props.initGroupSelectorCeiling(treeCeiling)
        this.props.setRoute(Routes.GROUP_SELECTION);
    };

    render() {
        const { t } = this.props;

        if (this.state.isloading) {
            return (
                <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
                    <Loader />
                </Dimmer>
            );
        }

        return (
            <div id="draw2-selectionLanding-container">
                <FunctionalityHeader
                    Icon={this.props.Icon}
                    name={t("selection")}
                    width={100}
                    height={40}
                />
                <Container textAlign="center" style={{ margin: "auto" }}>
                    <Button
                        onClick={this.manualSelectionHandler}
                        primary
                        size="big"
                        style={{ marginRight: "5%" }}
                    >
                        {t("manualSelection")}
                    </Button>
                    <Button
                        onClick={this.groupSelectionHandler}
                        primary
                        size="big"
                        style={{ marginLeft: "5%" }}
                    >
                        {t("groupSelection")}
                    </Button>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        Icon: state.functionalityIcon,
        name: ownProps.name,
        setRoute: ownProps.setRoute,
    };
};

export default connect(mapStateToProps, {
    setSelectionTree,
    initGroupSelector,
    initGroupSelectorCeiling,
    initMappingTableWallsManualSelection,
    initMappingTableCeilingsManualSelection
})(withTranslation(["riggibs"])(SelectionLanding));
