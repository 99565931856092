import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { MyConfig } from "../../../../Helper";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";

interface Props {
  setRoute: any;
}

const IFCGenerator = (props: Props) => {

  const [config, setConfig] = useState({REACT_APP_SERVERURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])
  
  return (
    <>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/ModelGenerator/plugin-logo.png"}
        name={"IFC Generator"}
        subheader={"From Existing Revit Objects"}
        config={config}
      />
      <Container textAlign="center" style={{ marginTop: 40 }}></Container>
    </>
  );
};

export default IFCGenerator;
