import React, { useState, useEffect, useRef } from "react";
import { Button } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { MyConfig } from "../../../../../Helper";

export const ImageDisplay = (props: any) => {
  const inputRef: any = useRef(null);
  const imgRef: any = useRef(null);
  const [file, setFile] = useState<any>("");

  useEffect(() => {
    loadDefaultmage();
  }, []);

  //useeffect of previous plugin is not included

  const toDataURL = (url: any, callback: any) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const loadDefaultmage = async () => {
    if (!props.value) {
      const cng = await MyConfig();
      const url = cng.REACT_APP_SERVERURL + "saint-gobain.jpg";
      setFile(url);

      const base64 = await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((res) => {
            reader.onloadend = () => {
              res(reader.result);
            };
          });
        });
      props.setValue(props.name, base64);
    }
  };

  const handleFileChange = (e: any) => {
    let files = e.target.files;
    let allFiles: any = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };
        allFiles.push(fileInfo);
        setFile(allFiles[0].base64);
        props.setValue(props.name, allFiles[0].base64);
      };
    }
  };

  const handleButtonClick = () => {
    inputRef?.current?.click();
  };

  return (
    <div>
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept="image/*"
      />
      <img
        ref={imgRef}
        src={props.value}
        alt="logo"
        className="logoHolder"
        {...props}
      />
      <Button onClick={handleButtonClick} className="btnLogo">
        Browse
      </Button>
    </div>
  );
};
