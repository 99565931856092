import React, { Component } from "react";
import "./pagination.css";
import {
  Container,
  Form,
  Button,
  Label,
  Grid,
  Select,
  Input,
} from "semantic-ui-react";

const PaginationComponent = (props: { pageObj: any }) => {
  let pageObj = props.pageObj;
  let perPageArray = [25, 50, 75, 100];

  return (
    <Grid columns="equal" style={{ alignItems: "center" }}>
      <Grid.Row>
        <Grid.Column>
          {/* <Button.Group>
                <Button
                  basic
                  color="blue"
                  icon="angle double left"
                  onClick={event => this.props.onPaginatePrevious(event)}
                  disabled={pageObj.CurrentPage === 1 ? true : false}
                />
                <Label className="pgNo" color="blue">
                  {pageObj.PageCountText}
                </Label>
                <Button
                  basic
                  color="blue"
                  icon="angle double right"
                  onClick={event => this.props.onPaginateNext(event)}
                  disabled={
                    pageObj.CurrentPage ==
                      Math.floor(
                        (pageObj.completelist.length + pageObj.Limit - 1) /
                          pageObj.Limit
                      ) || 0 === pageObj.completelist.length / pageObj.Limit
                      ? true
                      : false
                  }
                />
              </Button.Group> */}
          <span style={{ color: "#1e70bf" }}>
            Showing 1-25 of 337
            <a
              className="hoverPointer"
              //onClick={(event) => props.onPaginateFirst(event)}
            >
              <i aria-hidden="true" className="angle double left icon"></i>
            </a>{" "}
            <a
              className="hoverPointer"
              //onClick={(event) => this.props.onPaginatePrevious(event)}
            >
              <i aria-hidden="true" className="angle left icon"></i>
            </a>{" "}
            <input
              type="text"
              className="currentPage"
              disabled
              // value={pageObj.CurrentPage}
            ></input>
            <a
              className="hoverPointer"
              //onClick={(event) => this.props.onPaginateNext(event)}
            >
              <i aria-hidden="true" className="angle right icon"></i>
            </a>{" "}
            <a
              className="hoverPointer"
              // onClick={(event) => this.props.onPaginateLast(event)}
            >
              <i aria-hidden="true" className="angle double right icon"></i>
            </a>{" "}
          </span>
          {perPageArray.map((val, i) => (
            <a
              key={i}
              // className={
              //   pageObj.Limit === val
              //     ? "hoverPointer highlight"
              //     : "hoverPointer noHighlight"
              // }
              // onClick={(event) => this.props.onChangePageLimit(event, val)}
            >
              {val}{" "}
            </a>
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PaginationComponent;
