import { api } from "../../../RevitJS/API";
import Waiter from "./Components/Waiter";
import { pluginId } from "../index";
import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "cbbc5a16-7230-4576-81ef-795a067da69f";

export const Components = [
  {
    Id: "a15e0a9b-63fd-471e-a19c-a0070bba112d",
    Component: Waiter,
    Name: "Group Selector",
    custom: {},
  },
];

export const trigger = (config: any) => {
  api.windowsHandler.openWindow(
    400,
    150,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "BoM",
    module: "CLT",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Waiter />
    </ParentRoot>
  );
};

export const CltQuantitatiffRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Waiter />
    </ParentRoot>
  );
};

export const Quantitatifs = {
  Name: {
    French: "Quantitatifs",
    English: "Quantity take off",
    German: "Quantity take off",
    Italian: "Quantity take off",
  },
  Trigger: trigger,
  ShortDesc: {
    French: "Calcul détaillé des quantités",
    English: "Detailed quantity take off",
    German: "Layout quantity take off",
    Italian: "Layout quantity take off",
  },
  Id: functionalityId,
  RootComponent,
  Icon: "/pluginIcons/Icon-Quantitatifs.jpg",
  ParentId: "0",
};
