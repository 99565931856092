import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { NomenclatureRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "e7f1efde-96e9-4240-bd93-e09761e31d76";

export const NomenclatureTrigger = (config: any) => {
  const queryString = window.location.hash;
  const urlParams = new URLSearchParams(queryString.split("?")[1]);
  if (urlParams.get("token")) {
    localStorage.setItem("token", urlParams.get("token")!);
  }
  if (urlParams.get("language")) {
    localStorage.setItem("savedLanguage", urlParams.get("language")!);
  }
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <NomenclatureRoot />
    </ParentRoot>
  );
};

export const NomenclatureRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <NomenclatureRoot />
    </ParentRoot>
  );
};

export const Nomenclature: Functionality = {
  Name: {
    French: "Métrés rapides",
    English: "Quick Takeoffs",
    Indonesian: "Métrés rapides",
  },
  Trigger: NomenclatureTrigger,
  ShortDesc: {
    French: "Cloisons / Doublages / Plafonds",
    English: "Partitions / Linings / Ceilings",
    Indonesian: "Cloisons / Doublages / Plafonds",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Métrés.png",
  RootComponent,
  ParentId: "0",
};
