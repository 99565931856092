import React from "react";
import { Button, Grid, Icon, Popup } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import "../../Resources/commonStyles.css";

export function StepsData(props: any) {

  return (
      props.data.map((element: any) => (
        <Grid.Row key={element.name}>
          <Grid.Column width="five" textAlign="right">
            <Icon
              aria-hidden="true"
              className="grey caret right big icon margin-10"
            ></Icon>
          </Grid.Column>
          <Grid.Column width="six">
            <Button
              onClick={element.action}
              disabled={element.isDisabled===undefined? false: element.isDisabled}
              primary
              size="medium"
              className="margin-10"
              content={element.name}
              fluid
            ></Button>
          </Grid.Column>
          <Grid.Column width="five" textAlign="left">
            <Popup
              content={
                element.isActionCompleted
                  ? ReactHtmlParser(element.name + " " + props.wording.completed[props.language])
                  : ReactHtmlParser(element.name + " " + props.wording.notCompleted[props.language])
              }
              position="top center"
              inverted
              size="mini"
              trigger={
                <Icon
                  aria-hidden="true"
                  className={
                    element.isActionCompleted
                      ? "green check circle outline big icon margin-10"
                      : "red times circle outline big icon margin-10"
                  }
                ></Icon>
              }
            />
          </Grid.Column>
        </Grid.Row>
      ))
  );
}