import { ThunkAction } from "redux-thunk";
import { SelectionStore } from "../../Selection/Reducers";
import * as type from "./types";
import { InitLanguageAction, INIT_LANGUAGE } from "./types";

export const setDossierData = (dossierData: any) => {
  return {
    type: type.SET_DOSSIER_DATA,
    dossierData,
  };
};

export const setDossierSelection = (value: boolean) => {
  return {
    type: type.SET_METRE_SELECTION,
    value,
  };
};

export const setDossierParEtage = (value: []) => {
  return {
    type: type.SELECT_METRE_PAR_ETAGE,
    value,
  };
};

export const setDossierParZone = (value: boolean) => {
  return {
    type: type.SELECT_METRE_PAR_ZONE,
    value,
  };
};

export const clearDossierSelection = (value: boolean) => {
  return {
    type: type.CLEAR_SELECT_METRE,
    value,
  };
};

export const setPrevPage = (prevPage: string) => {
  return {
    type: type.SET_PREV_PAGE,
    prevPage,
  };
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage = (): any => async (dispatch: any) => {
  const setRevitLanguage = async () => {
    let revitLanguage = "French" as Language; //(await api.queries.getRevitLanguage()) as Language;
    localStorage.setItem("savedLanguage", revitLanguage);
    dispatch({
      type: INIT_LANGUAGE,
      language: revitLanguage,
    });
  };
  let savedLanguage: any = localStorage.getItem("savedLanguage"); //await bimStorage.getItem(storageKey.LANGUAGE);
  if (savedLanguage) {
    if (isLanguage(savedLanguage)) {
      dispatch({
        type: INIT_LANGUAGE,
        language: savedLanguage,
      });
    } else {
      setRevitLanguage();
    }
  } else {
    setRevitLanguage();
  }
};
