export const flattenDataRules = (dataRules: any, assignedAttribute: any = null) => {
    let rules: any = [];
    let conditionCount = 1;
    dataRules?.forEach((dataRule: any) => {
        conditionCount = 1;
        dataRule.forEach((dr: any) => {
            rules.push({
                attribute: dr.attribute,
                caseValue: dr.caseValue,
                relation: dr.relation,
                value: dr.value,
                condition: conditionCount === dataRule.length ? "OR" : "AND"
            });
            conditionCount++;
        });

    });

    return rules;
}

export const getMaterialApperance = (apperance: any, className: string, filterAttribute: any) => {
    let apperanceList: any = [];
    let productIndex: number = 1;

    if (apperance !== null) {
        apperance.apperanceList.forEach((apperanceData: any) => {
            if (apperanceData.classType === className) {
                apperanceData.parameters.forEach((para: any) => {
                    let productItem: any = mapAttributeWithMappingConfig(para, "MaterialApperance");
                    let value: string = productItem.value;
                    if (productItem.attributeName !== "" && filterAttribute.some((a: any) => a.technicalName === productItem.attributeName)) {
                        var filterData = filterAttribute.find((a: any) => a.technicalName === productItem.attributeName); //[0]
                        value = filterData?.values[0]?.value;
                        if (!getDataRule(productItem.DataRules, filterAttribute, null, null)) {
                            value = "";
                        }

                        apperanceList.Add(elementData(productItem.attributeName, productItem.bimField, value, "", null, "MaterialApperance", null, true, false, false));
                    }

                    let id: string = "6" + "0000000-0000-0000-0000-000000000" + productIndex.toString();//ToString("000");
                });
            }
        });

    }

    return apperanceList;
}

export const getMaterialGraphics = (graphics: any, className: string, filterAttribute: any, subAttributes: any) => {
    let graphicsList: any = [];
    let productIndex: number = 1;
    if (graphics !== null) {
        graphics.graphicsData.forEach((grapList: any) => {
            if (grapList.classType === className) {
                grapList.parameters.forEach((para: any) => {
                    let productItem = mapAttributeWithMappingConfig(para, "MaterialGraphics");
                    let value: string = productItem.value;
                    if (productItem.attributeName !== "" && filterAttribute.some((a: any) => a.technicalName === productItem.attributeName)) {
                        var filterData = filterAttribute.find((a: any) => a.technicalName === productItem.attributeName);//[0];
                        value = filterData?.values[0]?.value;
                        if (!getDataRule(productItem.dataRules, filterAttribute, null, null)) {
                            value = "";
                        }
                    }

                    let id: string = "5" + "0000000-0000-0000-0000-000000000" + productIndex.toString();//ToString("000");

                    graphicsList.push(elementData(productItem.bimField, productItem.bimField, value, "", null, "MaterialGraphics", null, true, false, false));

                });

                let surfacebackground: string = getDataRuleValue(flattenDataRules(grapList.surfaceBackground), filterAttribute, subAttributes, null);
                let surfaceForeground: string = getDataRuleValue(flattenDataRules(grapList.surfaceForeground), filterAttribute, subAttributes, null);
                let cutBackground: string = getDataRuleValue(flattenDataRules(grapList.cutBackground), filterAttribute, subAttributes, null);
                let cutForeground: string = getDataRuleValue(flattenDataRules(grapList.cutForeground), filterAttribute, subAttributes, null);
                let surfacebackgroundColor: string = getDataRuleValue(flattenDataRules(grapList.surfaceBackgroundColor), filterAttribute, subAttributes, null);
                let surfaceForegroundColor: string = getDataRuleValue(flattenDataRules(grapList.surfaceForegroundColor), filterAttribute, subAttributes, null);
                let cutBackgroundColor: string = getDataRuleValue(flattenDataRules(grapList.cutBackgroundColor), filterAttribute, subAttributes, null);
                let cutForegroundColor: string = getDataRuleValue(flattenDataRules(grapList.cutForegroundColor), filterAttribute, subAttributes, null);

                graphicsList.push(elementData("SurfaceForegroundPatternColor", "SurfaceForegroundPatternColor", surfaceForegroundColor, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("SurfaceBackgroundPatternColor", "SurfaceBackgroundPatternColor", surfacebackgroundColor, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("CutForegroundPatternColor", "CutForegroundPatternColor", cutForegroundColor, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("CutBackgroundPatternColor", "CutBackgroundPatternColor", cutBackgroundColor, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("SurfaceForegroundPattern", "SurfaceForegroundPattern", surfaceForeground, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("SurfaceBackgroundPattern", "SurfaceBackgroundPattern", surfacebackground, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("CutForegroundPattern", "CutForegroundPattern", cutForeground, "", null, "MaterialGraphics", null, true, false, false));
                graphicsList.push(elementData("CutBackgroundPattern", "CutBackgroundPattern", cutBackground, "", null, "MaterialGraphics", null, true, false, false));
            }
        });
    }

    return graphicsList;
}

export const getMaterialIdentity = (materialIdentity: any, filterAttribute: any) => {
    let matIdentity: any;
    let identityList: any = [];

    let productIndex: number = 1;
    if (materialIdentity != null) {
        //foreach(MappingConfigParameters para in materialIdentity.Parameters)
        materialIdentity.parameters.forEach((para: any) => {
            let productItem = mapAttributeWithMappingConfig(para, "IdentityMaterial");
            let value: string = productItem?.value;
            if (productItem.attributeName !== "" && filterAttribute.some((a: any) => a.technicalName === productItem.attributeName)) {
                var filterData = filterAttribute.find((a: any) => a.technicalName === productItem.attributeName)//[0];
                value = filterData?.values[0]?.value;
                if (!getDataRule(productItem.dataRules, filterAttribute, null, null)) {
                    value = "";
                }
            }

            let id: string = "4" + "0000000-0000-0000-0000-000000000" + productIndex.toString(); //.ToString("000");

            identityList.push(elementData(productItem.revitKey, productItem.bimField, value, "", null, "MaterialIdentity", null, true, false, false));
        });
    }

    return identityList;
}

const getDataRule = (datarules: any, attributeData: any, solution: any, pimId: any) => {
    let result: boolean = true;
    let count: number = 0;
    let condition: string = "";
    datarules.forEach((dataRule: any) => {
        if (attributeData.some((a: any) => a.technicalName.toLowerCase() === dataRule.attribute.toLowerCase())) {
            let attributeValue: string = attributeData.filter((a: any) => a.technicalName.toLowerCase() === dataRule.attribute.toLowerCase())[0]?.values[0]?.value;
            condition = count === 0 ? dataRule.Condition : datarules.ToList()[count - 1].Condition;

            // if (decimal.TryParse(attributeValue, out decimal test)) {
            //     attributeValue = Decimal.Parse(attributeValue).ToString("0.####");
            // }

            result = getConditionValue(attributeValue, condition, result, count, dataRule)?.result;
        }

        count++;
    });

    if (!result && pimId !== null) {
        //SolutionHelper.AddErrors(solution, pimId, "Solution level data rule is not satisfying the condition");
    }

    return result;
}

export const getDataRuleValue = (datarules: any, attributeData: any, sublayerattributes: any, defaultValue: any = "structure") => {
    let result = true, hasorcondition = false, currentResult = false;
    let count = 0;
    let condition = "", value = "";

    datarules.forEach((dataRule: any) => {

        if (attributeData.some((a: any) => a.technicalName === dataRule.attribute)) {
            let attributeValue = attributeData.find((a: any) => a.technicalName === dataRule.attribute)?.values[0].value;
            condition = count === 0 ? dataRule.condition : datarules[count - 1].condition;
            hasorcondition = condition?.toUpperCase() === "OR" ? true : hasorcondition;

            let output = getConditionValue(attributeValue, condition, result, count, dataRule);
            result = output.result;
            currentResult = output.currentResult;

            if (hasorcondition && count > 0) {
                value = currentResult ? dataRule.relation : value;
            }
            else {
                value = result ? dataRule.relation : defaultValue;
            }
        }
        else if (sublayerattributes !== null && sublayerattributes.length > 0 && sublayerattributes.some((a: any) => a.technicalName === dataRule.attribute)) {
            let attributeValue = sublayerattributes.find((a: any) => a.technicalName === dataRule.attribute)?.values[0].value;
            condition = count === 0 ? dataRule.condition : datarules[count - 1].condition;
            hasorcondition = condition?.toUpperCase() === "OR" ? true : hasorcondition;

            //result = getConditionValue(attributeValue, condition, result, count, dataRule, out currentResult);
            let output = getConditionValue(attributeValue, condition, result, count, dataRule);
            result = output.result;
            currentResult = output.currentResult;

            if (hasorcondition && count > 0) {
                value = currentResult ? dataRule.relation : value;
            }
            else {
                value = result ? dataRule.relation : defaultValue;
            }
        }
        count++;

    });
    return value;
}

export const getConditionValue = (attributeValue: string, condition: string, result: boolean, count: number, dataRule: any) => {
    let currentResult = false;
    if (dataRule.relation.toUpperCase() === "CONTAINS" || dataRule.caseValue.toUpperCase() === "CONTAINS") {
        currentResult = isContains(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.caseValue.toUpperCase() === "ISNOTCONTAINS" || dataRule.relation.toUpperCase() === "ISNOTCONTAINS") {
        currentResult = !isContains(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "EQUALTO" || dataRule.caseValue.toUpperCase() === "EQUALTO") {
        currentResult = isEqualTo(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "ISNOTEMPTY" || dataRule.caseValue.toUpperCase() === "ISNOTEMPTY") {
        currentResult = isNotEmpty(attributeValue);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "ISEMPTY" || dataRule.caseValue.toUpperCase() === "ISEMPTY") {
        currentResult = isEmpty(attributeValue);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "GREATERTHAN" || dataRule.caseValue.toUpperCase() === "GREATERTHAN") {
        currentResult = isGreaterThan(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "LESSTHAN" || dataRule.caseValue.toUpperCase() === "LESSTHAN") {
        currentResult = isLessThan(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "STARTSWITH" || dataRule.caseValue.toUpperCase() === "STARTSWITH") {
        currentResult = isStartWith(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "ENDSWITH" || dataRule.caseValue.toUpperCase() === "ENDSWITH") {
        currentResult = isEndsWith(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }
    else if (dataRule.relation.toUpperCase() === "BETWEEN" || dataRule.caseValue.toUpperCase() === "BETWEEN") {
        currentResult = isBetween(attributeValue, dataRule.value);
        result = count === 0 ? currentResult : checkAndCondition(condition) ? result && currentResult : result || currentResult;
    }

    return { result: result, currentResult: currentResult };
}

const isContains = (attributeValue: string, dataRuleValue: string): boolean => {
    return attributeValue.toLowerCase().includes(dataRuleValue.toLowerCase());
}

const checkAndCondition = (condition: string): boolean => {
    return condition.toLowerCase() === "and" ? true : false;
}

const isEqualTo = (attributeValue: string, dataRuleValue: string) => {
    return attributeValue.toLowerCase() === (dataRuleValue.toLowerCase()) ? true : false;
}

const isNotEmpty = (attributeValue: string) => {
    return attributeValue && attributeValue !== "" ? true : false;
}

const isEmpty = (attributeValue: string) => {
    return !attributeValue && attributeValue === "" ? true : false;
}

const isGreaterThan = (attributeValue: string, dataRuleValue: string) => {
    return parseInt(attributeValue) > parseInt(dataRuleValue) ? true : false;
}

const isLessThan = (attributeValue: string, dataRuleValue: string) => {
    return parseInt(attributeValue) < parseInt(dataRuleValue) ? true : false;
}

const isStartWith = (attributeValue: string, dataRuleValue: string) => {
    return attributeValue.startsWith(dataRuleValue) ? true : false;
}

const isEndsWith = (attributeValue: string, dataRuleValue: string) => {
    return attributeValue.endsWith(dataRuleValue) ? true : false;
}

const isBetween = (attributeValue: string, dataRuleValue: string) => {
    return parseInt(attributeValue) > parseInt(dataRuleValue.split(",")[0]) &&
        parseInt(attributeValue) < parseInt(dataRuleValue.split(",")[1]) ? true : false;
}

export const mapAttributeWithMappingConfig = (parameter: any, bimType: string, mandatory: boolean = false) => {
    return {
        attributeName: parameter.attributeName,
        revitKey: parameter.revitKey === null ? parameter.parameterId : parameter.revitKey,
        bimField: bimType === "DefaultObject" ? parameter.parameterName : parameter.name != null ? parameter.name : parameter.parameterId,
        bimType: bimType,
        bimLayerNumber: "0",
        parentAttributeName: parameter.attributeParentName.length > 0 ? parameter.attributeParentName[0] : "",
        mandatoryField: "",
        mandatory: parameter.mandatory,
        decimalPlace: parameter.numberOfDecimal.value,
        value: parameter.attributeName === "" || parameter.attributeName === null ? parameter.values.value !== "" ? parameter.values.value : parameter.values.numericValue : "",
        dataRules: flattenDataRules(parameter.dataRules),
    };
}

export const elementData = (_field: string, _label: string, _value: string, _identifier: string, _parameterDataType: any,
    _parameterType: string, _builtInGroup: any, _isSharedParameter: boolean, _isFamilyParameter: boolean, _isHelper: boolean,
    _isDefaultParameter: boolean = false, _functionName: string = "", _coreBoundry: string = "", _mappingConfigData: any = null) => {
    return {
        field: _field,
        label: _label,
        value: _value,
        identifier: _identifier,
        parameterDataType: _parameterDataType,
        parameterType: _parameterType,
        builtInGroup: _builtInGroup,
        isSharedParameter: _isSharedParameter,
        isFamilyParameter: _isFamilyParameter,
        isHelper: _isHelper,
        isDefaultParameter: _isDefaultParameter,
        functionName: _functionName,
        coreBoundry: _coreBoundry,
        mappingConfigData: _mappingConfigData,
    }

}