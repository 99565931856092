import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { connect } from "react-redux";
import { wait } from "../../../../RevitJS/Helpers";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { filter, find, map } from "lodash";

interface Props {
  language: string;
  setRoute: any;
}

class Waiter extends Component<Props> {
  componentDidMount = async () => {
    await wait(1000).then(async () => {
      const plasterboardIds = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
            Rule: "Equals",
          },
        ],
        null
      );
      const framesIds = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature" },
            Rule: "Equals",
          },
        ],
        null
      );
      // const bottomRailsLegacyIds = await api.queries.filterElements(
      //   "Generic",
      //   [
      //     {
      //       Param: { Name: "Name", Type: "Builtin", Value: "Rail" },
      //       Rule: "Equals",
      //     },
      //   ],
      //   null
      // );
      // const upperRailsLegacyIds = await api.queries.filterElements(
      //   "Generic",
      //   [
      //     {
      //       Param: { Name: "Name", Type: "Builtin", Value: "Rail-Retourne" },
      //       Rule: "Equals",
      //     },
      //   ],
      //   null
      // );
      const bottomRailsNewIds = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-bas" },
            Rule: "Equals",
          },
        ],
        null
      );
      const upperRailsNewIds = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-haut" },
            Rule: "Equals",
          },
        ],
        null
      );

      const upperRailsIds = upperRailsNewIds; //upperRailsLegacyIds.concat(upperRailsNewIds);
      const bottomRailsIds = bottomRailsNewIds; //bottomRailsLegacyIds.concat(bottomRailsNewIds);
      const allProcessedWallIds = await api.queries.filterElements(
        "Wall",
        [
          {
            Param: { Name: "Processed", Type: "Boolean", Value: true },
            Rule: "Equals",
          },
        ],
        null
      );

      let selectedWallParams = await api.queries.getObjectsParams(
        allProcessedWallIds,
        ["Processed", "SG_System", "Flipped", "Plaque", "Montant", "IsLayout"]
      );

      let processedWallParams = filter(selectedWallParams, (wapa, index) => {
        return (
          find(wapa.Params, {
            Name: "Processed",
            Type: "YesNo",
            Value: "1",
          }) &&
          find(wapa.Params, {
            Name: "IsLayout",
            Type: "YesNo",
            Value: "1",
          })
        );
      });

      if (processedWallParams.length > 0) {
        let processedWallIds = map(processedWallParams, "Id");

        const railsIds = bottomRailsIds.concat(upperRailsIds);
        let plasterboardsFilter = await api.queries.createSelectionFilter(
          "SG_Plasterboards",
          plasterboardIds
        );
        let framesFilter = await api.queries.createSelectionFilter(
          "SG_Frames",
          framesIds
        );
        let railsFilter = await api.queries.createSelectionFilter(
          "SG_Rails",
          railsIds
        );
        let processedWallsFilter = await api.queries.createSelectionFilter(
          "SG_Revit_Walls",
          processedWallIds
        );

        let activeView = await api.queries.getActiveViewDetails();
        let activeViewId = activeView.Id;

        await api.viewHandler.setSelectionFilterVisibility(
          activeViewId,
          plasterboardsFilter,
          true
        );
        await api.viewHandler.setSelectionFilterVisibility(
          activeViewId,
          framesFilter,
          true
        );
        await api.viewHandler.setSelectionFilterVisibility(
          activeViewId,
          railsFilter,
          true
        );
        await api.viewHandler.setSelectionFilterVisibility(
          activeViewId,
          processedWallsFilter,
          false
        );
      }
    });
    bimStorage.setItem(storageKey.IS_SUPERPOSER_ENABLED, JSON.stringify(true));
    api.windowsHandler.closeWindow();
  };

  render = () => {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Vue du calepinage Placo®..."} />
      </Dimmer>
    );
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    language: state.language,
    setRoute: ownProps.setRoute,
  };
};

export default connect(mapStateToProps)(Waiter);
