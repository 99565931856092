import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { connect } from "react-redux";
import { ColorizationRevitApi } from "../../../../ErrorManagement/utils/revitApiError";

export const colorization = async (props: any) => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );
  ColorizationRevitApi(props);
  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
      "Mark",
      "Identifiant",
      "Couleur_Plaque",
    ]
  );
  ColorizationRevitApi(props);
  const plasterboardsPerName = plasterboardDetails.reduce(function (
    a: { [x: string]: any[] },
    c: { Params: { Value: string | number }[] }
  ) {
    a[c.Params[4].Value] = a[c.Params[4].Value] || [];
    a[c.Params[4].Value].push(c);
    return a;
  },
  Object.create(null));

  let keys = Object.keys(plasterboardsPerName);
  for (let i = 0; i < keys.length; i++) {
    // api.windowHandler.showDevTools();
    let plasterboards = plasterboardsPerName[keys[i]];
    let color = plasterboards[0].Params.find(
      (param: { Name: string }) => param.Name === "Couleur_Plaque"
    ).Value;

    // param.Name === "Mark" || param.Name === "Identifiant" ||
    let rgbColor;
    if (color) {
      let splitColor = color.split(",");
      rgbColor = {
        Red: parseInt(splitColor[0]),
        Green: parseInt(splitColor[1]),
        Blue: parseInt(splitColor[2]),
      };
    } else {
      rgbColor = {
        Red: Math.ceil(Math.random() * 255),
        Green: Math.ceil(Math.random() * 255),
        Blue: Math.ceil(Math.random() * 255),
      };
    }
    let ids = plasterboards.map((el: { Id: any }) => el.Id);
    let filter = await api.queries.createSelectionFilter(keys[i], ids);
    ColorizationRevitApi(props);
    await api.viewHandler.setFilterColor(filter, rgbColor);
    ColorizationRevitApi(props);
  }

  await sendEvent();
};

export const colorizationForExisitingFilters = async (props: any) => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );
  ColorizationRevitApi(props);
  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
      "Mark",
      "Identifiant",
      "Couleur_Plaque",
    ]
  );
  ColorizationRevitApi(props);
  const plasterboardsPerName = plasterboardDetails.reduce(function (
    a: { [x: string]: any[] },
    c: { Params: { Value: string | number }[] }
  ) {
    a[c.Params[4].Value] = a[c.Params[4].Value] || [];
    a[c.Params[4].Value].push(c);
    return a;
  },
  Object.create(null));

  let keys = Object.keys(plasterboardsPerName);

  for (let i = 0; i < keys.length; i++) {
    let plasterboards = plasterboardsPerName[keys[i]];
    let color = plasterboards[0].Params.find(
      (param: { Name: string }) => param.Name === "Couleur_Plaque"
    ).Value;

    let rgbColor;
    if (color) {
      let splitColor = color.split(",");
      rgbColor = {
        Red: parseInt(splitColor[0]),
        Green: parseInt(splitColor[1]),
        Blue: parseInt(splitColor[2]),
      };
    } else {
      rgbColor = {
        Red: Math.ceil(Math.random() * 255),
        Green: Math.ceil(Math.random() * 255),
        Blue: Math.ceil(Math.random() * 255),
      };
    }
    let ids = plasterboards.map((el: { Id: any }) => el.Id);

    let filter = await api.queries.getExistingSelectionFilter(keys[i], ids);
    ColorizationRevitApi(props);
    if (filter) {
      await api.viewHandler.setFilterColor(filter, rgbColor);
      ColorizationRevitApi(props);
    }
  }
};

const sendEvent = async () => {
  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Colorize",
    module: "PLACOBIM",
  });
};

interface Props {
  language: string;
  setRoute: any;
  onError: any;
}

class Waiter extends Component<Props> {
  state = {
    distance: 400,
  };
  componentDidMount = async () => {
    // api.windowHandler.showDevTools();
    await colorization(this.props);
    if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "false") {
      api.windowsHandler.closeWindow();
    }
    // api.windowHandler.resizeWindow(100, 100);

    // window.revit.addCotesMM(1000);
  };

  render = () => {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Colorisation en cours..."} />
      </Dimmer>
    );
  };
}

const mapStateToProps = (state: { language: any }) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(Waiter);
