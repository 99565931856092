import { api } from "./../../../../RevitJS/API/index";

export const elemsByLevelName = async (wallsIds: any) => {
  let projectTree = await api.selection.elementsByLevelAndType("wall");
  let tree = projectTree.Tree.map((level: any) => {
    return {
      level: level.Level.Name,
      elems: [].concat.apply(
        [],
        level.Elements.map((elems: any) =>
          elems.Ids.filter((id: any) => wallsIds.includes(id))
        )
      ),
    };
  });

  const wallsByLevelName: any = {};
  let nbLevels = tree.length;
  for (let i = 0; i < nbLevels; i++) {
    wallsByLevelName[tree[i].level] = tree[i].elems;
  }
  return wallsByLevelName;
};

export const rowsByLevel = (rows: any) =>
  rows.reduce(function (a: any, c: any) {
    a[c.levelName] = a[c.levelName] || [];
    a[c.levelName].push(c);
    return a;
  }, Object.create(null));

export const rowsByLevelAndName = (byLevel: any) => {
  const rowsByLevelAndName: any = {};
  Object.keys(byLevel).forEach((level) => {
    rowsByLevelAndName[level] = byLevel[level].reduce(function (
      a: any,
      c: any
    ) {
      a[c.name] = a[c.name] || [];
      a[c.name].push(c);
      return a;
    },
    Object.create(null));
  });
  return rowsByLevelAndName;
};

export const rowsByLevelNameAndLength = (byLevelAndName: any) => {
  let rowsByLevelNameAndLength: any = {};
  Object.keys(byLevelAndName).forEach((level) => {
    rowsByLevelNameAndLength[level] = {};
    Object.keys(byLevelAndName[level]).forEach((name) => {
      rowsByLevelNameAndLength[level][name] = byLevelAndName[level][
        name
      ].reduce(function (a: any, c: any) {
        a[c.length] = a[c.length] || [];
        a[c.length].push(c);
        return a;
      }, Object.create(null));
    });
  });
  return rowsByLevelNameAndLength;
};

export const rowsByLevelNameLengthAndHeight = (
  by_level_name_and_length: any
) => {
  let rowsByLevelNameLengthAndHeight: any = {};
  Object.keys(by_level_name_and_length).forEach((level) => {
    rowsByLevelNameLengthAndHeight[level] = {};
    Object.keys(by_level_name_and_length[level]).forEach((name) => {
      rowsByLevelNameLengthAndHeight[level][name] = {};
      Object.keys(by_level_name_and_length[level][name]).forEach((length) => {
        rowsByLevelNameLengthAndHeight[level][name][
          length
        ] = by_level_name_and_length[level][name][length].reduce(function (
          a: any,
          c: any
        ) {
          a[c.height] = a[c.height] || [];
          a[c.height].push(c);
          return a;
        },
        Object.create(null));
      });
    });
  });
  return rowsByLevelNameLengthAndHeight;
};

