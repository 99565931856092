import React, { Component, useEffect, useState } from "react";
import { Menu } from "semantic-ui-react";
import { Pane } from "./Pane";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { FakeModel } from "../../RevitJS/Types/FakerTypes";
import { MyConfig } from "../../Helper";
import { useSelector } from "react-redux";
import { StoreState } from "../../Reducers";
import { useDispatch } from "react-redux";
import {
  cancelManualSelection,
  endManualSelection,
  resetSelectedPlugin,
  selectPlugin,
} from "../../Store/Actions";
import { GetTokenExpiryInSecs } from "../CommonComponents/Session/Authentication";

const flatButtonStyle = {
  border: "1px solid rgb(180, 180, 180)",
  background: "rgb(240, 240, 240)",
  boxShadow: "none",
  borderRadius: "0px",
};

export const RevitFaker = () => {
  const reduxState = useSelector((state: StoreState) => state);
  const dispatch = useDispatch();
  const [config, setConfig] = useState<any>(null);

  const getConfig = async () => {
    const cng = await MyConfig();
    setConfig(cng);
  };

  useEffect(() => {
    getConfig();
  }, []);

  if (!config) {
    return <></>;
  }

  const tokenExpiry = GetTokenExpiryInSecs();

  return (
    <div style={{ minWidth: 1000 }}>
      <Menu size="tiny" style={{ height: "7vh" }}>
        <Menu.Item>
          <h4 style={{ color: "rgb(24, 88, 168)" }}>Revit Faker</h4>
        </Menu.Item>
      </Menu>
      <div
        style={{
          width: "100%",
          height: "5.2vh",
          position: "fixed",
          top: "7.1vh",
          backgroundColor: "rgb(245, 245, 245)",
          display: "flex",
          alignItems: "center",
        }}
      >
        {reduxState.manualSelection && (
          <div style={{ marginLeft: 5 }}>
            <button
              style={{ ...flatButtonStyle, marginLeft: 5, marginRight: 5 }}
              onClick={() => dispatch(endManualSelection())}
            >
              Terminate
            </button>
            <button
              style={{ ...flatButtonStyle, marginLeft: 5, marginRight: 5 }}
              onClick={() => dispatch(cancelManualSelection())}
            >
              Cancel
            </button>
            <span style={{ marginLeft: 5 }}>
              Please click on terminate to emulate the end of a manual selection
              in Revit
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          height: "2.5vh",
          position: "fixed",
          top: "12.2vh",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundImage: `url('${config.REACT_APP_SERVERURL}/revit_top.PNG')`,
        }}
      >
        <div style={{ position: "fixed", top: "14.7vh", width: "100%" }}>
          <Pane
            selectedFakeModel={reduxState.selectedFakeModel}
            popUpComponent={reduxState.popUpComponent}
            windowDimensions={reduxState.windowDimensions}
            popUpHidden={reduxState.popUpHidden}
            popUpWindow={reduxState.popUpWindow}
            resetSelectedPlugin={() => dispatch(resetSelectedPlugin())}
            selectedPluginId={reduxState.selectedPluginId}
            plugins={reduxState.plugins}
            selectPlugin={(pluginId) => dispatch(selectPlugin(pluginId))}
            authenticated={tokenExpiry.isExpire}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "6vh",
            position: "fixed",
            bottom: 0,
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundPosition: "left",
            backgroundImage: `url('${config.REACT_APP_SERVERURL}/revit_bottom.PNG')`,
            zIndex: -1,
          }}
        ></div>
      </div>
    </div>
  );
};
