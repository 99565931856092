import React from "react";
import { useState, useEffect } from "react";
import WindowedSelect from "react-windowed-select";
import { Controller } from "react-hook-form";
import {
  Table,
  Button,
  Icon,
  Input,
  Popup,
  Modal,
  Dropdown,
} from "semantic-ui-react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core/styles";
import { words } from "lodash";

interface Props {
  wall: any;
  markSolutions: any;
  control: any;
  onRowDelete: any;
  onSystemsChange:any;
}

const SystemsMappingRow = (props: Props) => {
  const { wall, markSolutions, control, onRowDelete,onSystemsChange } = props;
  const [selectedOption, setSelectedOption] = useState<any>({
    label: wall.Solution ? wall.Solution.Name : "",
    value: wall.Solution ? wall.Solution.Oid : "",
  });
  const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "black",
      boxShadow: theme.shadows[1],
      fontWeight: "bold",
      fontSize: 11,
      // height:40,
    },
  }))(Tooltip);
  return (
    <Table.Row>
      <Table.Cell width="6">{`${wall.ElementName} (${wall.Ids.length})`}</Table.Cell>
      <Table.Cell width="9">
        <Table.Row>
          <Table.Cell
            className="windowCursor"
            style={{
              padding: "0px",
              border: "0px",
              width: "100%",
            }}
          >
            <LightTooltip
              title={selectedOption.label}
              arrow
              placement="top-start"
            >
              <div>
                <Controller
                  control={control}
                  name={`${words(wall.ElementName).join("")}`}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <WindowedSelect {...field} onChange={(selectedOption:any) => field.onChange(onSystemsChange(selectedOption, wall))}  search options={markSolutions} />
                  )}
                />
              </div>
            </LightTooltip>
          </Table.Cell>
          <Table.Cell
            style={{
              border: "1px solid rgba(34,36,38,.15)",
              backgroundColor: "#d6d6d673",
              padding: "0px",
            }}
          >
            <Popup
              trigger={
                <Button
                  icon
                  disabled
                  style={{
                    margin: "0px",
                    background: "none",
                  }}
                >
                  <Icon name="search" color="blue" style={{ padding: "0px" }} />
                </Button>
              }
              content="Advanced search"
              on="hover"
              position="top center"
              inverted
              size="mini"
            />
          </Table.Cell>
        </Table.Row>
      </Table.Cell>
      <Table.Cell widht="1">
        <Button.Group className="actionButtonGroup">
          <Popup
            trigger={
              <Button
                icon
                style={{ background: "none" }}
                onClick={(e: any) => {
                  e.preventDefault();
                  onRowDelete(wall.ElementName);
                }}
              >
                <Icon name="close" rotated="clockwise" color="red" />
              </Button>
            }
            content="Delete"
            on="hover"
            position="top center"
            inverted
            size="mini"
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

export default SystemsMappingRow;
