import { Action } from "redux";
import { Language } from "../../../../../RevitJS/Types/RevitTypes";
import { APP_WINDOW_NAME } from "../../assets/constants";

export const CHANGE_CURRENT_WINDOW = "CHANGE_CURRENT_WINDOW";
export const INIT_LANGUAGE = "INIT_LANGUAGE"

export interface AppState {
  currentWindow: APP_WINDOW_NAME;
  previousWindow: APP_WINDOW_NAME;
  language: Language
}

interface ChangeCurrentWindow extends Action {
  type: typeof CHANGE_CURRENT_WINDOW;
  currentWindow: APP_WINDOW_NAME;
}

interface ChangeCurrentLanguage extends Action {
  type: typeof INIT_LANGUAGE;
  language: Language;
}

export type AppActionTypes = ChangeCurrentWindow | ChangeCurrentLanguage;
