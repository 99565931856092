import { InfoActionType, InfoType, SET_INFO_STATUS} from "../Types";


const intialState: InfoType = {
    infoType: null,
    isLoading: false,
    openInfo: false,
    infoText: null,
    infoList: [],
    customComponents: null,
    headerText: null,
    showCancelBtn: false,
};


const infoReducer = (state: InfoType = intialState, action: InfoActionType): InfoType => {
    const newState = { ...state };
    switch (action.type) {
        case SET_INFO_STATUS:
            console.log("SET_INFO_STATUS");
            return {
                ...state,
                infoType: action.infoObj.infoType,
                isLoading: action.infoObj.isLoading,
                openInfo: action.infoObj.openInfo,
                infoText: action.infoObj.infoText,
                // infoList: action.infoObj.infoList,
                // customComponents: action.infoObj.customComponents,
                // headerText: action.infoObj.headerText,
                // showCancelBtn: action.infoObj.showCancelBtn,
            };
        default:
            return state;
    }
    return newState;
}

export default infoReducer;