import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import {
  Grid,
  Segment,
  Button,
  Dimmer,
  Loader,
  Menu,
  Message,
  Modal,
} from "semantic-ui-react";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
  selectLevel,
  selectType,
  selectCeilingLevel,
  selectCeilingType,
} from "../Actions/index";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { selectedItems, filterProjectTree } from "../Helpers";
import { scheduleHandler } from "../Export";
import {
  toogleLevels,
  toogleCeilingLevels,
  toogleTypes,
  toogleCeilingTypes,
  setElementType,
} from "./../Actions/index";
import { TypesColumn } from "./TypesColumn";
import { LevelsColumn } from "./LevelsColumn";

interface Props {
  selectorType: string;
  Icon: string;
  language: string;
  wording: {
    wallTypes: { [key: string]: string };
    ceilingTypes: { [key: string]: string };
    selection: { [key: string]: string };
    levels: { [key: string]: string };
    back: { [key: string]: string };
    validate: { [key: string]: string };
    measurementsInProgress: { [key: string]: string };
    walls: { [key: string]: string };
    ceilings: { [key: string]: string };
    wall: { [key: string]: string };
    ceiling: { [key: string]: string };
  };
  levelsData: LevelData[];
  typesData: TypeData[];
  routes: any;
  selectedLevels: string[];
  selectedTypes: string[];
  selectLevel: any;
  selectType: any;
  setRoute: any;
  setSelectedIds: any;
  toogleLevels: any;
  toogleTypes: any;
  elementType: "wall" | "ceiling";
  setElementType: any;
  selectCeilingLevel: any;
  selectCeilingType: any;
  ceilingLevelsData: LevelData[];
  ceilingTypesData: TypeData[];

  selectedCeilingLevels: string[];
  selectedCeilingTypes: string[];
  toogleCeilingTypes: any;
  toogleCeilingLevels: any;
  config: any;
}

interface State {
  processing: boolean;
  isDownloadSuccessful: boolean;
  buttonLoading: boolean;
}

export class GroupSelector extends React.Component<Props, State> {

  state = {
    processing: false,
    isDownloadSuccessful: false,
    buttonLoading: false,
  };
  componentDidMount = () => {
    // api.windowsHandler.showDevTools();
    this.setState({ processing: true });
    api.windowsHandler.resizeWindow(800, 500);
    setTimeout(() => {
      api.windowsHandler.resizeWindow(800, 500);
    }, 1000);
    this.setState({ processing: false });
  };

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
        German: "Ebenen",
        Italian: "Livelli",
      },
      ceilingTypes: {
        French: "Types de plafonds",
        English: "Ceiling types",
        German: "Deckentypen",
        Italian: "Tipi di soffito",
      },
      wallTypes: {
        French: "Types de murs",
        English: "Wall types",
        German: "Wandtypen",
        Italian: "Tipi di muro",
      },
      selection: {
        French: "Sélection Métré",
        English: "Selection Metres",
        German: "Auswahl",
        Italian: "Selezione",
      },
      back: {
        French: "Retour",
        English: "Back",
        German: "Zurück",
        Italian: "Indietro",
      },
      validate: {
        French: "Télécharger",
        English: "Validate",
        German: "Bestätigen",
        Italian: "Convalidare",
      },
      deletePending: {
        French: "Suppression en cours...",
        English: "Deleting...",
        German: "Löschen...",
        Italian: "La cancellazione in corso...",
      },
      measurementsInProgress: {
        French: "Métrés en cours...",
        English: "Measurements in progress...",
        German: "Messungen laufen...",
        Italian: "Misure in corso...",
      },
      walls: {
        French: "Murs",
        English: "Walls",
        German: "Wände",
        Italian: "Muri",
      },
      ceilings: {
        French: "Plafonds",
        English: "Ceilings",
        German: "Wände",
        Italian: "Muri",
      },
      wall: {
        French: "Mur",
        English: "Wall",
        German: "Wände",
        Italian: "Muri",
      },
      ceiling: {
        French: "Plafond",
        English: "Ceiling",
        German: "Wände",
        Italian: "Muri",
      },
      level: {
        French: "Niveaux",
        English: "Levels",
        German: "Ebenen",
        Italian: "Livelli",
      },
      wallType: {
        French: "Types de murs",
        English: "Wall types",
        German: "Wandtyp",
        Italian: "Tipo de muro",
      },
      thickness: {
        French: "Epaisseur (mm)",
        English: "Thickness (mm)",
        German: "Dicke (mm)",
        Italien: "Spessore (mm)",
      },
      Id: {
        French: "Id du mur",
        English: "Wall Ids",
        German: "Wand-IDs",
        Italian: "ID de muri",
      },
      height: {
        French: "Hauteur (mm)",
        English: "Height (mm)",
        German: "Höhe (mm)",
        Italian: "Altezza (mm)",
      },
      length: {
        French: "Longueur (mm)",
        English: "Length (mm)",
        German: "Länge (mm)",
        Italian: "Lunghezza (mm)",
      },
      surface: {
        French: "Surface (m²)",
        English: "Surface (m²)",
        German: "Oberfläche (m²)",
        Italian: "Superficie (m²)",
      },
      fileName: {
        French: "Nomenclature des murs",
        English: "Wall schedules",
        German: "Wandinventar",
        Italian: "Nomenclatura murale",
      },
    },
  };

  selectorTypes = () => {
    switch (this.props.elementType) {
      case "wall":
        return this.props.wording.wallTypes[this.props.language];
      case "ceiling":
        return this.props.wording.ceilingTypes[this.props.language];
      default:
        return this.props.wording.wallTypes[this.props.language];
    }
  };

  typesSelector = () => {
    switch (this.props.elementType) {
      case "wall":
        return this.props.wording.wall[this.props.language];
      case "ceiling":
        return this.props.wording.ceiling[this.props.language];
      default:
        return this.props.wording.wall[this.props.language];
    }
  };

  backHandler = () => {
    this.props.selectCeilingLevel([]);
    this.props.selectLevel([]);
    this.setState({ buttonLoading: true });
    setTimeout(() => {
      this.props.setRoute(Routes.ROOT);
      this.setState({ buttonLoading: false });
    }, 2000);
  };

  validateHandler = async (setProcessing: any) => {
    const tree = {
      walls: [],
      ceilings: [],
    };

    // Check Walls
    if (this.props.selectedTypes.length) {
      const wallTree = await api.selection.elementsByLevelAndType("wall");
      let selectedTypes: any[] = [];
      this.props.typesData.forEach((e) => {
        if (e.Checked) selectedTypes.push(e.Type);
      });
      const selectedElems = [].concat.apply(
        [],
        selectedItems(wallTree, this.props.selectedLevels, selectedTypes).map(
          (e: any) => e.Ids
        )
      );
      let filteredWallTree = filterProjectTree(
        wallTree,
        selectedElems,
        this.props.selectedLevels
      );
      tree.walls = filteredWallTree;
    }

    // Check Ceilings
    if (this.props.selectedCeilingLevels.length) {
      const ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
      let selectedCeilingTypes: any[] = [];
      this.props.ceilingTypesData.forEach((e) => {
        if (e.Checked) selectedCeilingTypes.push(e.Type);
      });
      const selectedCeilingElems = [].concat.apply(
        [],
        selectedItems(
          ceilingTree,
          this.props.selectedCeilingLevels,
          selectedCeilingTypes
        ).map((e: any) => e.Ids)
      );
      let filteredCeilingTree = filterProjectTree(
        ceilingTree,
        selectedCeilingElems,
        this.props.selectedCeilingLevels
      );
      tree.ceilings = filteredCeilingTree;
    }
    await scheduleHandler(tree, setProcessing, this.props.config);
    this.setState({ isDownloadSuccessful: true });
  };

  setProcessing = () => {
    this.setState({ processing: false });
  };

  render() {
    const { wording, language, elementType, setElementType } = this.props;

    const measurementsInProgress =
      wording.measurementsInProgress[language] ||
      wording.measurementsInProgress["French"];

    if (this.state.processing) {
      return (
        <div>
          <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
            <Loader content={measurementsInProgress}></Loader>
          </Dimmer>

          <Modal
            size="tiny"
            open={this.state.isDownloadSuccessful}
            dimmer="blurring"
          >
            <Modal.Description
              style={{
                textAlign: "center",
                padding: "2rem 1.6rem 1.6rem 1.6rem",
              }}
            >
              <h3 style={{ color: "rgb(33, 133, 208)" }}>
                Information - Métrés
              </h3>
              <p>Génération de fichier de métrés terminée avec succès.</p>
              <Button
                primary
                onClick={() => {
                  this.setState({ isDownloadSuccessful: false });
                }}
              >
                Continuer
              </Button>
            </Modal.Description>
          </Modal>
        </div>
      );
    }
    return (
      <div>
        <FunctionalityHeader
          Icon={this.props.Icon}
          name={this.props.wording.selection[this.props.language]}
        />
        <div style={{ padding: 5 }}>
          <div style={{ width: "100%", backgroundColor: "none" }}>
            <div style={{ margin: "auto" }}>
              <Menu
                widths={2}
                style={{ marginTop: "5px", marginBottom: "10px" }}
              >
                <Menu.Item
                  name={this.props.wording.walls[this.props.language]}
                  active={elementType === "wall"}
                  onClick={() => setElementType("wall")}
                  className={`${elementType === "wall"
                    ? "menuItemActive"
                    : "menuItemNotActive"
                    }`}
                />
                <Menu.Item
                  name={this.props.wording.ceilings[this.props.language]}
                  active={elementType === "ceiling"}
                  onClick={() => setElementType("ceiling")}
                  className={`${elementType === "ceiling"
                    ? "menuItemActive"
                    : "menuItemNotActive"
                    }`}
                />
              </Menu>
              {elementType === "wall" &&
                (this.props.typesData && this.props.typesData.length > 0 ? (
                  <Grid
                    columns={2}
                    stackable
                    style={{
                      backgroundColor: "none",
                      // height: 340,
                      // overflow: "auto",
                    }}
                    container
                    centered
                  >
                    <LevelsColumn
                      selectLevel={this.props.selectLevel}
                      columnTitle={
                        this.props.wording.levels[this.props.language]
                      }
                      levelsData={this.props.levelsData}
                      color="blue"
                      selectedLevels={this.props.selectedLevels}
                      toogleLevels={this.props.toogleLevels}
                    />
                    <TypesColumn
                      selectType={this.props.selectType}
                      selectedLevels={this.props.selectedLevels}
                      columnTitle={this.selectorTypes()}
                      color="blue"
                      setSelectedIds={this.props.setSelectedIds}
                      selectedTypes={this.props.selectedTypes}
                      toogleTypes={this.props.toogleTypes}
                      typesData={this.props.typesData}
                    />
                  </Grid>
                ) : (
                  <Message compact>
                    Aucun élément de type {this.typesSelector()} n'est dessiné
                    dans le projet
                  </Message>
                ))}

              {elementType === "ceiling" &&
                (this.props.ceilingTypesData &&
                  this.props.ceilingTypesData.length > 0 ? (
                  <Grid
                    columns={2}
                    stackable
                    style={{
                      backgroundColor: "none",
                      // height: 340,
                      // overflow: "auto",
                    }}
                    container
                    centered
                  >
                    <LevelsColumn
                      selectLevel={this.props.selectCeilingLevel}
                      columnTitle={
                        this.props.wording.levels[this.props.language]
                      }
                      levelsData={this.props.ceilingLevelsData}
                      color="blue"
                      selectedLevels={this.props.selectedCeilingLevels}
                      toogleLevels={this.props.toogleCeilingLevels}
                    />
                    <TypesColumn
                      selectType={this.props.selectCeilingType}
                      selectedLevels={this.props.selectedCeilingLevels}
                      columnTitle={this.selectorTypes()}
                      color="blue"
                      setSelectedIds={this.props.setSelectedIds}
                      selectedTypes={this.props.selectedCeilingTypes}
                      toogleTypes={this.props.toogleCeilingTypes}
                      typesData={this.props.ceilingTypesData}
                    />
                  </Grid>
                ) : (
                  <Message compact>
                    Aucun élément de type {this.typesSelector()} n'est dessiné
                    dans le projet
                  </Message>
                ))}
            </div>
            <Segment
              basic
              style={{
                padding: "0 40px",
                marginTop: -10,
                bottom: "10px",
                position: "absolute",
              }}
            >
              <Button onClick={this.backHandler} floated="left" color="orange" loading={this.state.buttonLoading}>
                {this.props.wording.back[this.props.language]}
                {/* Back */}
              </Button>
              <Button
                onClick={(e) => {
                  this.setState({ processing: true });
                  this.validateHandler(this.setProcessing);
                }}
                disabled={
                  this.props.selectedTypes.length === 0 &&
                  this.props.selectedCeilingTypes.length === 0
                }
                floated="right"
                primary
              >
                {/* Validate */}
                {this.props.wording.validate[this.props.language]}
              </Button>
            </Segment>
          </div>
        </div>

        <Modal
          size="tiny"
          open={this.state.isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>Information - Métrés</h3>
            <p>Génération de fichier de métrés terminée avec succès.</p>
            <Button
              primary
              onClick={() => {
                this.setState({ isDownloadSuccessful: false });
                api.windowsHandler.closeWindow();
                //window.location.href = window.location.origin + "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/abfb1e2e-b36b-4be4-a78d-df2472cbb96c";
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    levelsData: state.levelsData,
    typesData: state.typesData,
    selectedLevels: state.selectedLevels,
    selectedTypes: state.selectedTypes,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    elementType: state.elementType,
    ceilingLevelsData: state.ceilingLevelsData,
    ceilingTypesData: state.ceilingTypesData,
    selectedCeilingLevels: state.selectedCeilingLevels,
    selectedCeilingTypes: state.selectedCeilingTypes,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  selectType,
  selectLevel,
  toogleLevels,
  toogleTypes,
  setElementType,
  selectCeilingLevel,
  selectCeilingType,
  toogleCeilingTypes,
  toogleCeilingLevels,
})(GroupSelector);
