import React, { useState, useEffect } from "react";
import wordingJson from "../../Resources/wording.json";
import { InfoForm } from "./InfoForm";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { getCountry } from "../CommonComponent/country";
import { initProjectData } from "../../utils/types";
import { api } from "../../../../../RevitJS/API";
import { TopHeader } from "../../../Components/Headers";
import SaveProject from "../../../CommonModules/SaveProject";

interface Props {
  setRoute: any;
  route: string;
}
export const InfoProjects = (props: Props) => {
  const { setRoute, route } = props;
  const [language, setLanguage] = useState<string>("English");
  const [projectData, setProjectData] = useState<any>([]);
  const [infoFormData, setInfoFormData] = useState<any>(null);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  let country: any = getCountry();

  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };


  useEffect(() => {
    const getProjectData = async () => {
      let projectData: initProjectData = await api.queries.getSetProjectData();
      if (projectData.ProjectId !== "") {
        setProjectData(projectData);
      }
      else{
        toggleModal();
      }
    };
    getProjectData();
  }, []);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    const response = await bimStorage.getInfoForm();
    console.log("Infoformresponse", response);
    if (response) {
      setInfoFormData(response);
    }
  };

  const formData: any = [
    {
      header: wordingJson.formHeader1["English"],
      fields: [
        {
          type: "input",
          name: "information_enterprise_name",
          label: wordingJson.name["English"],
          mandatory: true,
        },
        {
          type: "file",
          name: "information_enterprise_logo",
          label: "Logo",
          mandatory: true,
        },
        {
          type: "input",
          name: "information_enterprise_address",
          label: wordingJson.address["English"],
          mandatory: true,
        },
        {
          type: "input",
          name: "information_enterprise_postal",
          label: wordingJson.codePostal["English"],
          mandatory: true,
        },
        {
          type: "input",
          name: "information_enterprise_villa",
          label: wordingJson.city["English"],
          mandatory: true,
        },
        {
          type: "select",
          name: "information_enterprise_pays",
          label: wordingJson.pays["English"],
          options: country,
          defaultData: "France",
          mandatory: true,
        },
        {
          type: "input",
          name: "information_enterprise_telephone",
          label: wordingJson.telephone["English"],
          mandatory: false,
        },
        {
          type: "input",
          name: "information_enterprise_email",
          label: wordingJson.email["English"],
          mandatory: false,
        },
      ],
    },
    {
      header: wordingJson.formHeader2["English"],
      fields: [
        {
          type: "input",
          name: "information_chantier_reference",
          label: wordingJson.reference["English"],
          mandatory: true,
        },
        {
          type: "input",
          name: "information_chantier_address",
          label: wordingJson.address["English"],
          mandatory: true,
        },
        {
          type: "input",
          name: "information_chantier_postal",
          label: wordingJson.codePostal["English"],
          mandatory: true,
        },
        {
          type: "input",
          name: "information_chantier_villa",
          label: wordingJson.city["English"],
          mandatory: true,
        },
        {
          type: "select",
          name: "information_chantier_pays",
          label: wordingJson.pays["English"],
          options: country,
          defaultData: "France",
          mandatory: true,
        },
        {
          type: "input",
          name: "information_chantier_referencecctp",
          label: wordingJson.referenceCCTP["English"],
          mandatory: true,
        },
        {
          type: "date",
          name: "information_chantier_add_date",
          label: wordingJson.dateOfEdition["English"],
          mandatory: true,
        },
        {
          type: "info",
          name: "mandatory_fields",
          label: wordingJson.mandatory["English"],
          mandatory: false,
        },
      ],
    },
  ];

  const changeDateHandler = () => {
    let today: any = new Date();
    let date =
      today.getDate() +
      "/" +
      parseInt(today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    bimStorage.setItem(storageKey.PROJECT_INFO_DATE, JSON.stringify(date));
  };

  if (!infoFormData) {
    return <></>;
  }

  return (
    <div>
      <TopHeader Icon={""} name={"Information Project"} />
      <InfoForm
        changeDateHandler={changeDateHandler}
        data={formData}
        route={route}
        language={language}
        projectData={projectData}
        setProjectData={setProjectData}
        setRoute={setRoute}
        infoFormData={infoFormData}
      />
      <SaveProject
              toggleModal={toggleModal}
              confirmModal={confirmModal}
              onYesAction={onYesAction}
      />
    </div>
  );
};
