import {
  find,
  filter,
  map,
  remove,
  isEmpty,
  round,
  startsWith,
  flattenDeep,
  keysIn,
  includes,
  pickBy,
  groupBy,
  uniq,
  orderBy,
  forEach,
} from "lodash";
import {
  montantDefault,
  plaqueDefault,
} from "../../Calpinage/Components/Solution/TableRow";

export const processNewCeilingData = (
  preCeilingData: any,
  extractedSolutionProduct: any[]
) => {
  return map(preCeilingData, (ceilingObject: any, index: number) => {
    const extractedSolutionProducts = find(
      extractedSolutionProduct,
      function (o: any) {
        return o.solutionProduct.oid === ceilingObject.PlacoSolutionId;
      }
    );

    let fetIsLayout: boolean = true,
      fetIsPlaco: boolean = true,
      fetIsChk: boolean = true,
      plaqsArray: any[] = [],
      montantsArray: any[] = [];

    plaqsArray = filter(extractedSolutionProducts?.products, {
      type: "plaque",
    });

    map(plaqsArray, (plq, index) => {
      remove(plq.articles, function (n: any) {
        return startsWith(n.value, "PPM");
      });
      return plq;
    });
    montantsArray = filter(extractedSolutionProducts?.products, {
      type: "montant",
    });
    fetIsLayout = false;
    if (ceilingObject.PlacoSolutionId.includes("custom")) {
      fetIsChk = false;
      fetIsPlaco = false;
      ceilingObject.PlacoSolutionHeight = "";
      ceilingObject.plaque = [];
      ceilingObject.montant = [];
    } else {
      if (isEmpty(ceilingObject.plaque)) {
        ceilingObject.plaque = plaqsArray.map((item) =>
          plaqueDefault(
            item.articles,
            `${round(parseFloat(ceilingObject.Height) - 0.01, 2)}`
          )
        );
      }

      if (isEmpty(ceilingObject.montant)) {
        ceilingObject.montant = montantsArray.map((item) =>
          montantDefault(
            item.articles,
            `${round(parseFloat(ceilingObject.Height) - 0.01, 2)}`
          )
        );
      }
    }

    ceilingObject.layoutPossible = fetIsLayout;
    ceilingObject.placo = fetIsPlaco;
    ceilingObject.plaqueArray = plaqsArray;
    ceilingObject.montantArray = montantsArray;
    ceilingObject.installationType =
      extractedSolutionProducts?.installationType;
    ceilingObject.supportCeiling = extractedSolutionProducts?.supportCeiling;
    ceilingObject.chk =
      ceilingObject.chk === undefined ? fetIsChk : ceilingObject.chk;

    if (ceilingObject.installationType && ceilingObject.supportCeiling) {
      ceilingObject.x = ceilingObject.x ? ceilingObject.x : "";
      ceilingObject.y = ceilingObject.y ? ceilingObject.y : "";
    }

    return ceilingObject;
  });
};
