import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FunctionalityHeader from "../../../Common/components/FunctionalityHeader";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { Button, CircularProgress } from "@material-ui/core";
import { api } from "../../../../../RevitJS/API";
import PlacoCard from "../Common/PlacoCard";
import { selectWindowToShow } from "../../store/app/effects";
import { cleanCartSystem } from "../../store/cart/effects";
import { connect, ConnectedProps } from "react-redux";
import { APP_WINDOW_NAME } from "../../assets/constants";
import * as productsService from "../../services/project.service";
import { getCurrentConfiguration } from "../../../Properties/services/properties.service.local";
import * as propertiesService from "../../../Properties/services/properties.service";
import { CustomParameter } from "../../../../../RevitJS/Types/RevitTypes";
import AlertDialog from "../../../Common/components/AlertDialog";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../../Common/assets/attributes.injection";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";
import {
  fillSystemsModel,
  getDefaultConfigurationByType,
  getRelationofObjects,
} from "../../../Common/utils/attributes.injection.helpers";

import "./index.scss";
import _ from "lodash";

interface RenderCardProps {
  products?: ProductDetailData[] | undefined;
  onShowDetails?: any;
  onConfigurate?: any;
}

const RenderCard = ({ products }: RenderCardProps) => (
  <>
    {products &&
      products.map((product, index) => (
        <PlacoCard
          productImage={product.thumbnailUrl}
          key={product.oid}
          index={index}
          oid={product.oid}
          productName={product.translation}
          technicalName={product.technicalName}
          system={product}
        ></PlacoCard>
      ))}
  </>
);

export const LoadSystemCartContainer: React.FC<
  LoadSystemCartContainerProps
> = ({
  cartSystemItems,
  cartCount,
  selectWindowToShow,
  cleanCartSystem,
  data,
  config,
  t,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(true);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  useEffect(() => {
    api.windowsHandler.resizeWindow(970, 500);
    // api.windowsHandler.showDevTools();
  }, []);

  const handleValidateCartContent = async (config: any) => {
    setOpenDialog(true);
    let elementData: CustomParameter[] = [];
    let selectedProperties: any = {};
    let systemProperties: any[] = [];
    let isDefaultConfiguration = false;
    await propertiesService
      .loadProperties(Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME), config)
      .then((properties: any[]) => {
        systemProperties = properties;
      });
    await getCurrentConfiguration().then((properties) => {
      selectedProperties = properties?.propertiesConfiguration;
      isDefaultConfiguration = properties?.isDefaultConfiguration;
    });

    if (!selectedProperties)
      selectedProperties = {
        wallsSystemProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.WALL,
            systemProperties
          ) || [],
        floorSystemProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.FLOOR,
            systemProperties
          ) || [],
      };

    await getRelationofObjects(data, config);
    elementData = fillSystemsModel(
      selectedProperties,
      data,
      isDefaultConfiguration,
      t
    );
    let customParameterResult2 =
      (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[];
    let dataToInject = elementData.filter(
      (system) =>
        !customParameterResult2.find(
          (element) => element?.solution === system?.solution
        )
    );

    console.log("elementData: ", elementData);
    if (!elementData) {
      setShowWarning(true);
    }

    await api.queries
      .createElementTypeWithCustomParametersForWeberIndonesia(
        elementData,
        config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
      )
      .then(() => setProgress(false));

    if (elementData) {
      trackInfo(elementData, selectedProperties);
    } else {
      throw new Error("TraceabilityError: Element Data Empty");
    }
  };

  const trackInfo = async (
    elementData: CustomParameter[],
    selectedProperties:
      | {
          wallsSystemProperties: any[];
          floorSystemProperties: any[];
        }
      | undefined
  ) => {
    let solutionList: any = elementData.map((o: any) => {
      return _.pick(o, ["elementType", "solution"]);
    });

    let generic = _.includes(
      solutionList[0].solution,
      "ou techniquement équivalent"
    );

    let attributeList = _.uniqBy(
      [
        {
          Type: "wall",
          Properties: selectedProperties?.wallsSystemProperties.map(
            (o: any) => {
              return _.pick(o, ["categoryName"]);
            }
          ),
        },
        {
          Type: "floor",
          Properties: selectedProperties?.floorSystemProperties.map(
            (o: any) => {
              return _.pick(o, ["categoryName"]);
            }
          ),
        },
      ],
      "Type"
    );

    let projectInfo: any = await api.queries.getProjectInformation();
    let projectName = projectInfo.Name;
    let projectData: any = await api.queries.getProjectData();
    let fileName = projectData.ProjectPath.split("\\").pop();
    let revitVersion = await api.framework.getRevitVersion();

    let version = await api.framework.getVersion();
    let productName = await api.framework.getProductName();

    if (window.revit.isArchicad) {
      fileName = "";
      projectName = "";
      revitVersion = "";
    }

    api.eventLog.SetEvent({
      data: [
        {
          name: "Name of solutions",
          value: "",
          values: solutionList,
        },
        {
          name: "List of attributes",
          value: "",
          values: attributeList,
        },
        {
          name: "Generic",
          value: generic,
          values: [],
        },
        {
          name: "Information",
          value: "",
          values: [
            {
              File_Name: fileName,
              Project_Name: projectName,
              Version: revitVersion,
            },
          ],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Family Creation",
      module: "WEBINDONPRESCRI",
    });
  };

  const handleCloseDialog = () => {
    cleanCartSystem();
    selectWindowToShow(APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW);
  };

  const handleBackToLoadSystems = () => {
    selectWindowToShow(APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW);
  };

  return (
    <div className="LoadSystemGlobalContainer">
      <FunctionalityHeader
        name={t("LOAD_SYSTEM_TITLE")}
        icon={config.REACT_APP_SERVERURL + "/WeberIndonesia_logo.png"}
      />
      <div className="CartSystemDetailsContainer" style={{ height: "78%" }}>
        <div className="LoadSystemCardContainer">
          {cartSystemItems ? (
            <RenderCard products={cartSystemItems}></RenderCard>
          ) : (
            <CircularProgress className="LoadSystemCircularProgress" />
          )}
        </div>
      </div>
      <div className="LoadSystemButtonContainer">
        <Button
          className="loadActionButton dark-grey-color"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handleBackToLoadSystems()}
        >
          {t("RETURN")}
        </Button>
        <Button
          className="loadActionButton yellow-color"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handleValidateCartContent(config)}
        >
          {t("LOAD_SELECTED_SYSTEMS")}
        </Button>
      </div>
      {showWarning ? (
        <AlertDialog
          open={openDialog}
          onClose={handleCloseDialog}
          setOpen={setOpenDialog}
          progress={progress}
          title={t("WARNING_LOAD_SYSTEMS")}
          content={t("WARNING_LOAD_SYSTEMS_MESSAGE")}
        />
      ) : (
        <AlertDialog
          open={openDialog}
          onClose={handleCloseDialog}
          setOpen={setOpenDialog}
          progress={progress}
          title={t("INFORMATION_LOAD_SYSTEMS")}
          content={t("INFORMATION_LOAD_SYSTEMS_MESSAGE")}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    cartSystemItems: state.cart.currentSystemItems,
    cartCount: state.cart.cartCount,
    systemTypeID: state.systems.systemTypeID,
    searchKeyWords: state.systems.searchKeyWords,
    data: state.cart.data,
    config: state.config,
  };
}

const mapDispatchToProps = {
  selectWindowToShow,
  cleanCartSystem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoadSystemCartContainerProps = PropsFromRedux & WithTranslation;

export default connector(
  withTranslation(["weberIndonesia"])(LoadSystemCartContainer)
);
