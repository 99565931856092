// import { Draw } from './Draw';
import { PamImport } from "./pam-import";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
// import { Technical } from './Technical';
// import { Spotting } from './Spotting';
// import { ressources } from '../../Ressources';

export const pluginId = "317ca9f1-510b-4336-b98d-cd6f52b46dfb";

export const pam: Plugin = {
  TechnicalName: "PAMDI",
  Name: "",
  Icon: "/pluginIcons/logo_pam_functionality.png",
  ShortDesc: {
    French: "Import de données BIM",
    English: "BIM Data import",
    German: "BIM Data import",
    Italian: "BIM Data import",
  },
  Id: pluginId,
  Color: "#1A171B",
  Functionalities: [PamImport],
};
