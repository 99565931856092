import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Icon,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { bimStorage } from "../../../../BIMStore";
import { SelectionTable } from "../Components/Table/Selection";
import { useForm } from "react-hook-form";

interface Props {
  setDetailRoute: any;
  onSelect: (selectionId: any) => void;
  selectionId: any;
  setRoute: any
}
export const Selection = (props: Props) => {
  const { setDetailRoute, onSelect, selectionId } = props;
  const [selectionList, setSelectionList] = useState<any>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, dirtyFields },
  } = useForm();

  const listModules = async () => {
    const modules = await bimStorage.listSelection();
    if (modules) {
      setSelectionList(modules);
    }
  };

  const onSubmit = (formData: any) => {
    console.log("formData", formData);
    onSelect(formData.selections);
  };

  useEffect(() => {
    listModules();
  }, []);

  if (!selectionList) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Segment style={{ height: "calc(100vh - 30pxpx)" }}>
        {selectionList.length === 0 ? (
          <div>No saved selection</div>
        ) : (
          <SelectionTable
            data={selectionList}
            register={register}
            selectionId={selectionId}
          />
        )}
      </Segment>
      <div
            style={{
              position: "absolute",
              bottom: "10px",
              padding: 5,
              cursor: "pointer",
              minWidth: "0px",
              left: "10px",
              zIndex: "2"
            }}
            onClick={() => {
              props.setRoute("SELECTION_HOME");
              props.setDetailRoute("SELECTION_HOME")
            }}
          >
            <Icon
              name="edit"
              color="blue"
              size="big"
              style={{ fontSize: "1.5em" }}
            />
            <span style={{ color: "rgb(14,110,184)", textTransform: "capitalize"  }}>
              create / modify selection
            </span>
          </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <span>
          <Button
            color="orange"
            onClick={() => setDetailRoute("")}
            content={"Back"}
          ></Button>
        </span>
        <span>
          <Button color="blue" content={"Validate"} type={"submit"}></Button>
        </span>
      </div>
    </form>
  );
};
