import React from "react";
import {
  Icon,
  Checkbox,
  Radio,
  Input,
  Popup,
  Button,
  Modal,
} from "semantic-ui-react";
import { getPrintDate } from "../../../../Utils";

interface Props {
  data: any;
  register: any;
  selectionId: any;
  multiSelection:any;
}

export const SelectionTableRow = (props: Props) => {
  const { data, register, selectionId,multiSelection } = props;
  const { Name, Date, Floors, Id } = data;
  return (
    <div
      className="row-hover"
      style={{
        padding: "5px",
        minHeight: "25px",
        display: "flex",
        alignItems: "center",
        borderBottom: "0.5px solid white",
        cursor: "pointer",
      }}
    >
       {multiSelection ? (
        <input
          {...register("selections")}
          type="checkbox"
          value={Id}
          id={Id}
          defaultChecked={Id.toString() === selectionId}
          className="w-5"
        />
      ) : (
        <input
          {...register("selections")}
          type="radio"
          value={Id}
          id={Id}
          defaultChecked={Id.toString() === selectionId}
          className="w-5"
        />
      )}

      <Popup
        trigger={
          <div
            style={{
              fontWeight: "bold",
              display: "inline-block",
              width: "55%",
              color: "black",
              cursor: "pointer",
              wordWrap: "break-word",
              overflow: "hidden",
            }}
            onClick={() => {}}
          >
            <div>{Name}</div>
          </div>
        }
        content="Cliquer pour modifier le nom/ 
        Click to edit name"
        on="hover"
        inverted
        size="mini"
        position="bottom center"
      />
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          width: "20%",
          color: "black",
          display: "inline-flex",
        }}
      >
        <div>{Floors.map((y: any) => y.Name).join(",")}</div>
      </div>
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          width: "20%",
          color: "black",
          display: "inline-flex",
        }}
      >
        <div>{getPrintDate(Date)}</div>
      </div>
    </div>
  );
};

export default SelectionTableRow;
