import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ReverseDirectionRoot from "./Components/root";
import { overLapElements } from "../Overlap/Components/Waiter";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { ErrorFallback } from "../../../Components/CommonComponents/ErrorManagement";

export const functionalityId = "a3a0c38d-db27-4ee2-96db-7e130824b3ff";

export const reverseDirectionTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    400,
    150,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  await overLapElements();
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Reverse Direction"
    >
      <ReverseDirectionRoot />
    </ParentRoot>
  );
};

export const ReversDirectionRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Reverse Direction"
    >
      <ReverseDirectionRoot />
    </ParentRoot>
  );
};

export const ReverseDirection: Functionality = {
  Name: {
    French: "Inverser",
    English: "Reverse",
    German: "Inverser",
    Italian: "Inverser",
    Indonesian: "Inverser",
  },
  Trigger: reverseDirectionTrigger,
  ShortDesc: {
    French: "Changer le sens de pose",
    English: "Change the laying direction",
    German: "Changer le sens de pose",
    Italian: "Changer le sens de pose",
    Indonesian: "Changer le sens de pose",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Inverser.png",
  RootComponent,
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
