import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { ProjectDataActionType, SET_PROJECT_DATA } from "../Types";

export const ProjectDataReducer = (
    data: initProjectData | undefined = { ProjectId: "", ProjectPath: "" },
    action: ProjectDataActionType
) => {
    switch (action.type) {
        case SET_PROJECT_DATA:
            return action.data;
        default:
            return data;
    }
};
