import {
  find,
  filter,
  map,
  flattenDeep,
  keysIn,
  includes,
  pickBy,
  groupBy,
  uniq,
  orderBy,
  forEach,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import { fetchPlacoDataInGroupSplit } from "../../Calpinage/Helpers/placoHelper";
import { processNewWallData } from "./processWall";

export const groupWallsDataFromSelection = async (
  selections: any,
  selectSelection: string[],
  config: any
) => {
  /// Extract selection ids

  /// Extract selection list based on ids
  let selectionList = filter(selections, function (o) {
    return includes(selectSelection, o.Id);
  });

  /// 1. Extract selected wall ids from selection.
  let wallRows = flattenDeep(
    map(selectionList, (ele) => {
      return map(ele.SelectionByType.wall.Rows, (row) => {
        return {
          ...row,
          zone: ele.Zone ? ele.Name : "",
        };
      });
    })
  );

  let wallIdsExt = flattenDeep(
    map(wallRows, (ele) => {
      if (ele.RevitSelection) {
        return ele.RevitSelection.Ids;
      }
    })
  );

  /// 2. Check for duplicate wall ids
  let dupliactesWallIds = keysIn(
    pickBy(groupBy(wallIdsExt), (x) => {
      return x.length > 1;
    })
  );

  if (dupliactesWallIds.length === 0) {
    /// 3. Get walls data from revit
    let wallsData = await api.queries.getWallsData(wallIdsExt);

    /// 4. Extract solution ids
    let solutionIds = uniq(
      flattenDeep(
        map(wallRows, (ele) => {
          return ele.Options.MappedSystem.oid;
        })
      )
    );
    /// 5. Get solution data from api respected to solution ids
    const { extractedSolutionProduct } = await fetchPlacoDataInGroupSplit(
      solutionIds,
      config
    );
    wallsData = orderBy(wallsData, ["LevelElevation"], ["asc"]);

    let grpWallsData: any[] = [];

    forEach(wallsData, (value: any, index: number) => {
      let wallRow = find(wallRows, (walRow: any) => {
        return includes(walRow.RevitSelection.Ids, value.Id);
      });

      let height: String = parseFloat((value.Height / 1000).toString()).toFixed(
        2
      );

      let oneValue = find(grpWallsData, {
        LevelId: value.LevelId,
        Zone: wallRow.zone,
        WallType: value.WallType,
        Height: height,
        PlacoSolution: wallRow.Options.MappedSystem.longName
          ? wallRow.Options.MappedSystem.longName
          : wallRow.Options.MappedSystem.translation,
      });

      if (oneValue) {
        let ids = oneValue.Ids;
        ids.push(value.Id);
        find(grpWallsData, {
          LevelId: value.LevelId,
          Zone: wallRow.zone,
          WallType: value.WallType,
          Height: height,
          PlacoSolution: wallRow.Options.MappedSystem.longName
            ? wallRow.Options.MappedSystem.longName
            : wallRow.Options.MappedSystem.translation,
        }).Ids = ids;
      } else {
        grpWallsData.push({
          Height: height,
          LevelName: value.LevelName,
          PlacoSolution: wallRow.Options.MappedSystem.longName
            ? wallRow.Options.MappedSystem.longName
            : wallRow.Options.MappedSystem.translation,
          PlacoSolutionId: wallRow.Options.MappedSystem.oid,
          PlacoSolutionHeight:
            wallRow.Options.MappedSystem["GFR-Height limit in m"],
          Zone: wallRow.zone,
          WallType: value.WallType,
          LevelElevation: value.LevelElevation,
          LevelId: value.LevelId,
          Ids: [value.Id],
          montant: wallRow.montant,
          plaque: wallRow.plaque,
        });
      }
    });

    const proceessWalls = processNewWallData(
      grpWallsData,
      extractedSolutionProduct
    );

    return proceessWalls;
  }

  return null;
};
