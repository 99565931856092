import { combineReducers } from "redux";
import app from "./appReducer";
import { RootState } from "./initialState";
import projects from "./projectsReducer";
import userInfo from "./userInfoReducer";

export { RootState };

export default combineReducers({
    userInfo,
    projects,
    app,
});
