import React, { useState } from "react";
import { FunctionalityHeader } from "../../../../Selection/Components/FunctionalityHeader";
import { Container, Dimmer, Loader } from "semantic-ui-react";

export const BIMModel = () => {
  const [loading, setLoading] = useState<boolean>(false);

  // if (loading) {
  //     return (
  //         <>
  //             <Container
  //                 className="grid-container"
  //                 style={{ width: "90%", border: "0 none", padding: "0" }}
  //             >
  //                 <Dimmer
  //                     active
  //                     style={{
  //                         height: "calc(100vh - 30px)",
  //                     }}
  //                 >
  //                     <Loader />
  //                 </Dimmer>
  //             </Container>
  //         </>
  //     );
  // }

  return (
    <>
      <FunctionalityHeader
        Icon={""}
        name={"QUANTITATIFS PAR RATIO"}
        subheader=""
      />

      <Container
        className="header-alignment subHeader"
        style={{ marginTop: "1rem" }}
      >
        {`2/2 Ratio personnalisés`}
      </Container>
    </>
  );
};
export default BIMModel;
