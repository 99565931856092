import { ThunkAction } from "redux-thunk";
import { SystemDetailsActionTypes } from "./types";
import { setAdvanceSelectorAction, setCurrentSystemAction } from "./actions";
import { DrawStore } from "../reducers";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";

type Effect = ThunkAction<any, DrawStore, any, SystemDetailsActionTypes>;

export const setCurrentSystemDetail =
  (system: ProductDetailData): Effect =>
    (dispatch: any, getState: any) => {
      dispatch(setCurrentSystemAction(system));
    };

export const setAdvanceSelectorDetail =
  (advanceSelector: any): Effect =>
    (dispatch: any, getState: any) => {
      dispatch(setAdvanceSelectorAction(advanceSelector));
    };
