import React, { useEffect, useState } from "react";
import AccordionContainer from "../../../Common/components/AccordionContainer";
import Checkbox from "@material-ui/core/Checkbox";
import FunctionalityHeader from "../../../Common/components/FunctionalityHeader";
import DetailsProperty from "../../../Common/components/DetailsProperty";
import { Button, CircularProgress, Box } from "@material-ui/core";
import { systemCategories } from "../../assets/constants";
import SelectSystemType from "../../../Common/components/SelectSystemType";
import AlertDialog from "../../../Common/components/AlertDialog";
import { DrawStore } from "../../store/reducers";
import { connect, ConnectedProps } from "react-redux";
import {
  loadProperties,
  restoreProperties,
  checkProperty,
  setDefaultConfiguration,
  getSystemFamilyAttributes,
} from "../../store/properties/effects";
import { loadSystems } from "../../store/systems/effects";
import { savePropertiesConfiguration } from "../../services/properties.service.local";
import { withTranslation, WithTranslation } from "react-i18next";
import { api } from "../../../../../RevitJS/API";

import "./index.scss";
import { fillSystemsModel, fillSystemsModelNew } from "../../../Common/utils/attributes.injection.helpers";
import { CustomParameter } from "../../../Common/assets/types";
import { getAllPlugins, getPrescriptionActiveVersion } from "../../../../../Services/mapping-config-service";
import { CategoryTypeEnum } from "../../../Common/assets/constant";
import _ from "lodash";

interface DefaultConfigurationProps {
  Default: boolean;
  OnChange: any;
  t: any;
}

export const DefaultConfiguration: React.FC<DefaultConfigurationProps> = ({
  Default,
  OnChange,
  t,
}) => (
  <div className="PropertySelectContent">
    <Checkbox
      checked={Default}
      onChange={(event, data) => {
        OnChange(data);
      }}
      color="default"
    />
    <Box fontWeight="fontWeightBold" m={1}>
      {t("GENERIC_SYSTEM")}
    </Box>
    <Box fontStyle="italic" m={1}>
      {t("GENERIC_SYSTEM_DETAIL")}{" "}
    </Box>
  </div>
);

export const PropertiesContainer: React.FC<PropertiesContainerProps> = ({
  properties,
  data,
  loadingSystems,
  loadingProperties,
  loadProperties,
  restoreProperties,
  checkProperty,
  selectedProperties,
  systemCategoryID,
  isDefaultConfiguration,
  setDefaultConfiguration,
  loadSystems,
  config,
  t,
}) => {
  const [details, setDetails] = useState<any[] | undefined>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(true);
  const [categoryTypeOptions, setCategoryTypeOption] = useState<any[]>([]);

  useEffect(() => {
    api.windowsHandler.resizeWindow(970, 500);

    const loadCategoryTypes = async () => {
      if (config && Object.keys(config).length > 0) {
        
        let plugins = await getAllPlugins(config);
        const application = plugins.find((p: any) => p.applicationName === 'Mortar Utama Indonesia');
        let data = await getPrescriptionActiveVersion(application.applicationId, config);
        let advancedSelectorapi = data?.versions[0]?.advanceSelector;

        bindCategoryTypes(advancedSelectorapi?.mainCategories)
      }
    }

    if (config && Object.keys(config).length > 0) {
      if (!properties || properties.length === 0) {
        loadProperties(t, config);
        loadSystems(t, config);
        loadCategoryTypes();
      }
    }
  }, [config]);

  const bindCategoryTypes = (mainCategories: any) => {
    let options: any[] = [];
    _.forEach(CategoryTypeEnum, (dt: any) => {
      if (mainCategories.some((m: any) => m.type === Object.keys(CategoryTypeEnum)[Object.values(CategoryTypeEnum).indexOf(dt)])) {
        options.push({
          value:
            Object.keys(CategoryTypeEnum)[Object.values(CategoryTypeEnum).indexOf(dt)],
          label: dt,
        });
      }
    });

    setCategoryTypeOption(options);
  };

  const handleSavePropertiesConfiguration = async (selectedProperties: any) => {
    setOpenDialog(true);

    savePropertiesConfiguration(selectedProperties, isDefaultConfiguration);
    let newSystemProperties: any = await getSystemFamilyAttributes(config);

    console.log("data: ", data);
    let elementData = await fillSystemsModelNew(
      selectedProperties,
      data,
      isDefaultConfiguration,
      t,
      config,
      newSystemProperties
    );

    await api.queries
      .createElementTypeWithCustomParametersForWeberIndonesia(elementData, config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO)
      .then(() => setProgress(false));
  };

  const handelCloseDialog = () => {
    api.windowsHandler.closeWindow();
  };

  const handleBack = () => {
    api.windowsHandler.closeWindow();
  };

  return (
    <div className="PropertyGlobalContainer" style={{ height: "98%" }}>
      <FunctionalityHeader
        name={t("SYSTEM_PROPERTIES")}
        icon={config.REACT_APP_SERVERURL + "/WeberIndonesia_logo.png"}
      />
      <div className="PropertiesContainer">
        {loadingProperties || loadingSystems ? (
          <div className="PropertiesCircularProgressContainer">
            <CircularProgress className="PropertiesCircularProgress" />
          </div>
        ) : (
          <>
            <DefaultConfiguration
              Default={isDefaultConfiguration}
              OnChange={setDefaultConfiguration}
              t={t}
            />
            <div className="PropertiesContent">
              <div className="Properties">
                <div className="PropertySelectContainer">
                  <div className="PropertySelectTitle">{t("SYSTEM_TYPE")}</div>
                  <div className="PropertySelectValue">
                    <SelectSystemType
                      systemTypes={categoryTypeOptions}
                      selectedID={systemCategoryID}
                      onChange={restoreProperties}
                    />
                  </div>
                </div>
                <div className="PropertyDetailsContent">
                  {properties.map((p) => (
                    <AccordionContainer
                      key={t(p.chapterName) || t(p.categoryName)}
                      title={t(p.chapterName) || t(p.categoryName)}
                      properties={
                        p.chapterData &&
                        p.chapterData.map((prop: any) => ({
                          title: t(prop.categoryName),
                          value: (
                            <Checkbox
                              checked={prop.checked}
                              onChange={(event, data) => {
                                checkProperty(prop, data);
                              }}
                              color="default"
                            ></Checkbox>
                          ),
                          onClick: () =>
                            setDetails(
                              prop.pamData &&
                              prop.pamData.map((propr: { label: any, key: any }) => ({
                                title: propr.label || t(propr.key),
                              }))
                            ),
                        }))
                      }
                      onClick={() =>
                        p.pamData &&
                        setDetails(
                          p.pamData &&
                          p.pamData.map((prop: any) => ({
                            title: prop.label || t(prop.key),
                            value: prop.value,
                          }))
                        )
                      }
                    />
                  ))}
                </div>
              </div>
              <DetailsProperty
                properties={
                  details && details.length > 0
                    ? details
                    : properties[0]?.pamData?.map((prop: any) => ({
                      title: prop.label || t(prop.key),
                      value: prop.value,
                    }))
                }
              />
            </div>
          </>
        )}
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton dark-grey-color"
          style={{ textTransform: "none" }}
          onClick={() => handleBack()}
        >
          {t("CLOSE")}
        </Button>
        <Button
          className="loadActionButton yellow-color"
          style={{ textTransform: "none" }}
          onClick={() => handleSavePropertiesConfiguration(selectedProperties)}
        >
          {t("LOAD_PROPERTIES")}
        </Button>
      </div>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={handelCloseDialog}
        progress={progress}
        title={t("Information")}
        content={t("LOAD_PROPERTIES_INFORMATION")}
      />
    </div>
  );
};

function mapStateToProps(state: DrawStore) {
  return {
    properties: state.properties.currentPropertiesDetail,
    loadingProperties: state.properties.loadingProperties,
    selectedProperties: state.properties.selectedProperties,
    systemCategoryID: state.properties.systemCategoryID,
    isDefaultConfiguration: state.properties.isDefaultConfiguration,
    data: state.systems.data,
    loadingSystems: state.systems.loadingSystems,
    config: state.config,
  };
}

const mapDispatchToProps = {
  loadProperties,
  restoreProperties,
  checkProperty,
  setDefaultConfiguration,
  loadSystems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PropertiesContainerProps = PropsFromRedux & WithTranslation;

export default connector(
  withTranslation(["weberIndonesia"])(PropertiesContainer)
);
