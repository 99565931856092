import axios from "axios";
import { getValidJwtToken } from "../API/authenticate.api";

export const getAllPrescriptions = async (config: any) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL + "prescription-plugins?locale=en";
  const result = await getSecureApi(url, config);

  return result.prescriptionPlugins;
};

export const getPrescription = async (prescriptionId: string, config: any) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_ADMIN_API_URL +
    "prescription-plugins/" +
    prescriptionId;
  const result = await getSecureApi(url, config);

  return result;
};

export const getPrescriptionDataRules = async (
  prescriptionPluginId: string,
  config: any
) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL +
    "prescription-plugins/" +
    prescriptionPluginId +
    "/data-rules";
  const result = await getSecureApi(url, config);

  return {
    solutionLevels: result.solutionLevels,
    productLevels: result.productLevels,
  };
};

export const getPrescriptionPluginAdvanceSelector = async (
  prescriptionPluginId: string,
  config: any,
  activeVersionId: string
) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL +
    "prescription-plugins/" +
    prescriptionPluginId +
        "/versions/" + 
        activeVersionId +
        "/advance-selectors"
  const result = await getSecureApi(url, config);

  return result;
};

export const getPrescriptionPluginFamilyAttribute = async (
  prescriptionPluginId: string,
  config: any,
  activeVersionId: string
) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL +
    "prescription-plugins/" +
    prescriptionPluginId +
    "/versions/" + 
    activeVersionId +
    "/familyType";
  const result = await getSecureApi(url, config);

  return result;
};

export const getPrescriptionActiveVersion = async (
  applicationId: string,
  config: any
) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL +
    "applications/" +
    applicationId +
    "/active-versions";
  const result = await getSecureApi(url, config);

  return result;
};

export const getConfigurationParameter = async (
  mappingconfigid: string,
  config: any
) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL +
    "configurations/" +
    mappingconfigid +
    "/parameters";
  const result = await getSecureApi(url, config);
  return result;
};

export const getMappingConfig = async (
  bimObjectRuleId: string,
  config: any
) => {
  let url =
    config.REACT_APP_MAPPING_CONFIG_API_URL +
    "configurations-wrapped/" +
    bimObjectRuleId;
  const result = await getSecureApi(url, config);

  return result;
};

export const getAllPlugins = async (config: any) => {
  const result = await getSecureApi(
    config.REACT_APP_API_BIMAUTH_URL +
      "plugins/authenticated/pluginsetup/applications",
    config
  );
  return result;
};

export const getLayoutRuleIdDetails = async (layoutRuleId: string,config: any) => {
    let url = config.REACT_APP_MAPPING_CONFIG_API_URL + "layout-configurator/layout-rule/layout-rule-details/" + layoutRuleId;
    const result = await getSecureApi(url, config);

    return result;
}

export const getSecureApi = (function () {
  return async function (url: string, config: any) {
    const token = await getValidJwtToken(config);
    let result = await axios({
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
    return result;
  };
})();
