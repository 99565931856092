import { combineReducers } from "redux";
import app from "./app/reducers";
import propertiesReducer from "./properties/reducers";
import systemsReducer from "./systems/reducers";
import { AppActionTypes, AppState } from "./app/types";
import { PropertiesActionTypes, PropertiesState } from "./properties/types"
import { SystemsActionTypes, SystemsState } from './systems/types'
import { config } from "../../../CLT/Delete/Reducers";


export const reducers = combineReducers({
    app: app,
    properties: propertiesReducer,
    systems: systemsReducer,
    config
});

export interface DrawStore {
    properties: PropertiesState;
    app: AppState;
    systems: SystemsState;
    config: any
}

export type ActionTypes = PropertiesActionTypes | AppActionTypes | SystemsActionTypes

export type RootState = ReturnType<typeof reducers>