import {
    SetElementParametersAction
} from "../Actions/types";
import {ElementParameter} from "../Actions/objectTypes";


export const elementParameter = (
    typesData: ElementParameter[] = [],
    action: SetElementParametersAction
) => {
    switch (action.type) {
        case "SET_ELEMENT_PARAMETERS":
            return action.value;
        default:
            return typesData;
    }
};
