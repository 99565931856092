import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import "./pixelAccordion.scss";

function PixelAccordion({ headLabel = "", children = [] }: IProps) {
    const toggleAccordion = (element: EventTarget & HTMLDivElement) => {
        if (element.parentElement?.classList.contains("expanded")) {
            if (element.parentElement) {
                element.parentElement.classList.remove("expanded");
            }
        } else {
            if (element.parentElement) {
                element.parentElement.classList.add("expanded");
            }
        }
    };

    return (
        <div className="pixel-accordion">
            <div
                className="pixel-accordion-head"
                onClick={(event) => {
                    event.stopPropagation();
                    toggleAccordion(event.currentTarget);
                }}
            >
                <label className="m-0">{headLabel}</label>
                <div className="d-flex flex-column justify-content-center">
                    <ExpandMore className="pixel-accordion-expand-icon-more"></ExpandMore>
                    <ExpandLess className="pixel-accordion-expand-icon-less"></ExpandLess>
                </div>
            </div>
            <div className="pixel-accordion-body">{children}</div>
        </div>
    );
}

type IProps = { headLabel: any; children: React.ReactNode };

export default PixelAccordion;
