import { filter } from "lodash";
import React, { Fragment } from "react"
import { Checkbox, Table } from "semantic-ui-react"
import { getPrintDate } from "../../../../Plugins/Gyproc/Utils";

interface Props {
    moduleData: any[];
    selectionId: any[];
    selectedRecords: any[];
    setSelectedRecords: any;
    translations: any;
    language: string;
}
export const CalpinageComponent = (prop: Props) => {
    const filteredSelection = (id: string) => filter(prop.moduleData, function (selection: any) {
        return selection.Id === id;
    });

    const selectedRecord = (e: any, data: any) => {
        let r = prop.selectedRecords;
        let index = prop.selectedRecords.findIndex((f: any) => f.Id === data.Id);
        if (index === -1) {
            r.push(data);
        } else {
            r.splice(index, 1);
        }

        prop.setSelectedRecords(r);
    }

    return (
        <Table className="tableborderexport  fixed-table-header">
            <Table.Header className="header">
                <Table.Row>
                    <Table.HeaderCell className="width5" textAlign="center"></Table.HeaderCell>
                    <Table.HeaderCell className="width70">{prop.translations[prop.language].tableHeader.configurationName}</Table.HeaderCell>
                    <Table.HeaderCell className="width25" textAlign="center">{prop.translations[prop.language].tableHeader.modifiedDate}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Fragment>
                    {
                        prop.selectionId.length > 0 ?
                            prop.selectionId.map((row: any, index: number) => {
                                let selectedData = filteredSelection(row);
                                return (<Table.Row>
                                    <Table.Cell className="windowCursor width5">
                                        <Checkbox type="checkbox" onChange={(e) => selectedRecord(e, selectedData[0])} />
                                    </Table.Cell>
                                    <Table.Cell className="windowCursor width70">
                                        {selectedData[0].Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center" className="windowCursor width25">
                                        {getPrintDate(selectedData[0].Date)}
                                    </Table.Cell>
                                </Table.Row>)
                            })
                            : <Table.Row>
                                <Table.Cell colSpan={3} textAlign="center" className="windowCursor" >
                                    {prop.translations[prop.language].message.noLayout}
                                </Table.Cell>
                            </Table.Row>
                    }
                </Fragment>
            </Table.Body>
        </Table>
    )
}