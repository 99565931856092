// export type CurrentWindow = "GENERAL_TERMS_OF_USE" | "INTRODUCTION_VIDEO";

export enum AppWindowName {
    GENERAL_TERMS_OF_USE_WINDOW = "GENERAL_TERMS_OF_USE",
    INTRODUCTION_VIDEO_WINDOW = "INTRODUCTION_VIDEO",
    PROJECT_MENU_WINDOW = "PROJECT_MENU_WINDOW",
    PROJECT_NAME_WINDOW = "PROJECT_NAME_WINDOW",
    PROJECT_COUNTRY_WINDOW = "PROJECT_COUNTRY_WINDOW",
    ELEMENT_SELECTION_WINDOW = "ELEMENT_SELECTION_WINDOW",
    RANGE_SELECTION_WINDOW = "RANGE_SELECTION_WINDOW",
    SYSTEM_NAME_WINDOW = "SYSTEM_NAME_WINDOW",
    COUPLING_SELECTION_WINDOW = "COUPLING_SELECTION_WINDOW",
    RECAP_WINDOW = "RECAP_WINDOW",
    DATA_TRANSFERT = "DATA_TRANSFERT",
    FINISH_WINDOW = "FINISH_WINDOW",
}

export enum Country {
    FR = "FR",
    UK = "UK",
}

export enum SelectionMethod {
    MANUAL = "MANUAL",
    SYSTEM_NAME = "SYSTEM_NAME",
}

export const GENERAL_TERMS_OF_USE = "GENERAL_TERMS_OF_USE";
export const INTRODUCTION = "INTRODUCTION";
export const SAVED_PROJECTS = "SAVED_PROJECTS";
export const NEW_PROJECT = "NEW_PROJECT";
export const STEP_1_DATA_LIBRARY_CONFIGURATION =
    "STEP_1_DATA_LIBRARY_CONFIGURATION";
export const STEP_2_ELEMENT_SELECTION = "STEP_2_ELEMENT_SELECTION";
export const STEP_3_RANGE_SELECTION = "STEP_3_RANGE_SELECTION";
export const STEP_4_COUPLING_SELECTION = "STEP_4_COUPLING_SELECTION";
export const STEP_4_COUPLING_SELECTION_TRANSFER_SUCCESS = "STEP_4_COUPLING_SELECTION_TRANSFER_SUCCESS";
export const STEP_5_RECAP = "STEP_5_RECAP";

export const GENERAL_TERMS_OF_USE_TEXT =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla malesuada pellentesque elit eget gravida cum sociis natoque. Nisl tincidunt eget nullam non nisi est sit amet. Morbi tristique senectus et netus et malesuada fames ac turpis. Eget aliquet nibh praesent tristique magna sit amet. Tellus pellentesque eu tincidunt tortor aliquam. Vulputate dignissim suspendisse in est ante in. Dolor purus non enim praesent elementum facilisis leo vel fringilla. Euismod elementum nisi quis eleifend quam adipiscing vitae proin. Varius duis at consectetur lorem donec massa sapien faucibus et. Tempus quam pellentesque nec nam aliquam sem et. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla facilisi. Mollis nunc sed id semper risus in." +
    "Tortor consequat id porta nibh venenatis cras sed. Suspendisse sed nisi lacus sed viverra tellus in hac habitasse. Tincidunt id aliquet risus feugiat in ante metus. Sapien faucibus et molestie ac. Vitae tempus quam pellentesque nec nam aliquam sem. Fermentum dui faucibus in ornare. Nunc vel risus commodo viverra maecenas. Quis auctor elit sed vulputate mi. Id semper risus in hendrerit gravida rutrum quisque non tellus. Neque ornare aenean euismod elementum nisi." +
    "Blandit libero volutpat sed cras ornare arcu dui vivamus arcu. Quam lacus suspendisse faucibus interdum posuere lorem ipsum. Arcu cursus vitae congue mauris rhoncus aenean vel. Quis ipsum suspendisse ultrices gravida. Nullam non nisi est sit amet facilisis magna etiam tempor. Neque viverra justo nec ultrices. Tempor commodo ullamcorper a lacus vestibulum sed arcu non odio. Nunc lobortis mattis aliquam faucibus purus in massa. Posuere morbi leo urna molestie at elementum eu. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Magna sit amet purus gravida quis blandit turpis cursus in. Elementum eu facilisis sed odio. Ultrices dui sapien eget mi proin. Purus semper eget duis at tellus at urna. Quam viverra orci sagittis eu. Ipsum suspendisse ultrices gravida dictum fusce ut placerat orci nulla. Ac placerat vestibulum lectus mauris ultrices eros." +
    "Turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie. Non quam lacus suspendisse faucibus. Eu scelerisque felis imperdiet proin. Auctor augue mauris augue neque gravida in fermentum et. Congue mauris rhoncus aenean vel. Volutpat ac tincidunt vitae semper quis. Quis viverra nibh cras pulvinar mattis. Viverra maecenas accumsan lacus vel facilisis volutpat est. Adipiscing commodo elit at imperdiet dui accumsan. Non quam lacus suspendisse faucibus. Tortor posuere ac ut consequat semper viverra nam." +
    "Ullamcorper velit sed ullamcorper morbi tincidunt. Sed faucibus turpis in eu mi. Aliquam id diam maecenas ultricies mi eget. Accumsan sit amet nulla facilisi morbi tempus. Feugiat nibh sed pulvinar proin gravida hendrerit. Sed vulputate odio ut enim. Neque volutpat ac tincidunt vitae semper quis lectus. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Auctor neque vitae tempus quam. Nulla posuere sollicitudin aliquam ultrices. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Platea dictumst quisque sagittis purus sit. Faucibus et molestie ac feugiat sed. Amet consectetur adipiscing elit pellentesque habitant morbi. Lacus laoreet non curabitur gravida arcu ac. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada nunc. Dictum sit amet justo donec. Facilisi etiam dignissim diam quis enim lobortis scelerisque.";

export const ACCEPT_GENERAL_TERMS_OF_USE_TEXT =
    "ACCEPT_GENERAL_TERMS_OF_USE_TEXT";

export const USER_ACCEPTED_GENERAL_TERMS_OF_USE =
    "USER_ACCEPTED_GENERAL_TERMS_OF_USE";

export const USER_SKIP_INTRO = "USER_SKIP_INTRO";

export const SKIP_INTRO_TEXT = "SKIP_INTRO_TEXT";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const PROCEED = "PROCEED";
export const PROJECT_NAME = "PROJECT_NAME";
export const COUNTRY_OF_CONSTRUCTION = "COUNTRY_OF_CONSTRUCTION";
export const NO_SAVED_PROJECTS = "NO_SAVED_PROJECTS";

// PROJECT'S TABLE
export const ProjectTableHeadCells = [
    {
        id: "name",
        numeric: true,
        disablePadding: false,
        label: "PROJECT_NAME",
        date: false,
    },
    {
        id: "modification_date",
        numeric: true,
        disablePadding: false,
        label: "MODIFICATION_DATE",
        date: true,
    },
];

export const ElementType = {
    PipeAccessory: "PipeAccessory",
    PipeCurves: "PipeCurves",
    PipeFitting: "PipeFitting",
};

export enum CouplingQualityTypes {
    STANDARD = "Standard",
    INOX = "Inox",
}

export enum MainMaterialTypes {
    STEEL_W2 = "Steel W2",
    ACIER_W2 = "Acier W2",
    STEEL_W4 = "Steel W4",
    ACIER_W4 = "Acier W4",
    STEEL_W5 = "Steel W5",
    ACIER_W5 = "Acier W5",
}
