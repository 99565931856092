import React from "react";
import { Button, Checkbox, Form, Modal } from "semantic-ui-react";
import "../../Resources/modal.css";
import { SelectionStore } from "../../../Selection/Reducers";
import { connect } from "react-redux";
import { InitDossierData } from "../../../Selection/Actions/types";

const FormatChoiceModal = (props: any) => {
  return (
    <Modal open={props.formatChoiceModal} size="mini" dimmer="inverted">
      <Modal.Header className="modalHeader">Choix du format</Modal.Header>
      <Modal.Actions>
        <Form style={{ margin: "0px 0px 0px 100px" }}>
          <Form.Field style={{ display: "grid" }}>
          {props.dossierData && props.dossierData["downloadObj"].map((item:any, i:number) => (
            <Checkbox
              key={i}
              checked={item.isSelected}
              className="chkFields"
              onChange={(e) => props.onDownloadObjectCheck(item.name)}
              label={item.name}
            />
          ))}
          </Form.Field>
        </Form>
        <div className="modalButton">
          <Button
            color="orange"
            size="tiny"
            onClick={() => {
              props.closeDimmer();
            }}
          >
            {props.wording.cancel[props.language]}
          </Button>
          <Button
            // disabled={!props.newDocumentName}
            type="submit"
            size="tiny"
            primary
            onClick={() => {
              props.downloadObjects();
            }}
          >
            {props.wording.validate[props.language]}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    language: state.language,
    setRoute: ownProps.setRoute,
    routes: ownProps.route,
    Icon: state.functionalityIcon,
  };
};

export default connect(mapStateToProps)(FormatChoiceModal);
