import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { enIN, fr, de, it } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../../../Resources/FormPanel.css";
import { ImageDisplay } from "../Image";

export const FormField = (props: any) => {
  const [datecheck, setdatecheck] = useState(false);
  let lang;
  switch (props.language) {
    case "French":
      lang = fr;
      break;
    case "English":
      lang = enIN;
      break;
    case "German":
      lang = de;
      break;
    case "Italian":
      lang = it;
      break;
    default:
      lang = fr;
      break;
  }

  return (
    <>
      {props.type === "select" && (
        <Form.Select
          {...props}
          selected={props.value ?? ""}
          label={
            props.mandatory ? (
              <span className="font-weight-field">
                {props.label}
                <span className="alert"> *</span>
              </span>
            ) : (
              <span className="font-weight-field">{props.label}</span>
            )
          }
          search
          className={
            props.errors[props.name] && !props.value
              ? "inputCont select-field red-border hide-cursor"
              : "inputCont select-field hide-cursor"
          }
          onChange={(d: any) => {
            props.setValue(props.name, d.target.innerText);
          }}
        ></Form.Select>
      )}
      {props.type === "file" && <ImageDisplay {...props} />}
      {props.type === "date" && (
        <div className="field inputCont">
          <div className="font-weight-field">
            {props.label}
            <span className="alert"> *</span>
          </div>
          <DatePicker
            locale={lang}
            dateFormat="dd/MM/yyyy"
            selected={props.value && new Date(props.value)}
            onChange={(date) => {
              props.setValue(props.name, date?.toString());
              if (date) {
                setdatecheck(true);
              } else {
                setdatecheck(false);
              }
            }}
            className={
              datecheck
                ? "date-inputCont"
                : props.errors[props.name]
                ? "date-inputCont date-red-border"
                : "date-inputCont"
            }
          />
        </div>
      )}
      {props.type === "info" && <h5 className="alert">{props.label}</h5>}
      {props.type === "input" && (
        <Form.Input
          {...props}
          label={
            props.mandatory ? (
              <span className="font-weight-field">
                {props.label}
                <span className="alert"> *</span>
              </span>
            ) : (
              <span className="font-weight-field">{props.label}</span>
            )
          }
          onChange={(e) => {
            props.setValue(props.name, e.target.value);
          }}
          error={props.mandatory}
          className={
            props.errors[props.name] &&
            (props.value === undefined || props.value === "") &&
            props.mandatory
              ? "error inputCont red-border"
              : "inputCont"
          }
        />
      )}
    </>
  );
};
