import { TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { setGroupTreesAction, selectLevelAction, selectTypeAction } from "../Actions/types";
import { elementTypes, levelData, mergeItems } from "../../../../RevitJS/Helpers";
import { api } from "../../../../RevitJS/API";

type ceilingTypesDataAction = selectLevelAction | selectTypeAction | setGroupTreesAction;

export const ceilingTypesData = (
    typesData: TypeData[] = [],
    action: ceilingTypesDataAction
  ): TypeData[] => {
    switch (action.type) {
      case "SET_GROUP_TREES":
          return elementTypes(levelData(action.ceilingTree, [])) as TypeData[]
      case "SELECT_LEVEL":
          if(action.selectorType === "ceiling"){
              return action.typesData;
          }
          return typesData;
      case "SELECT_TYPE":
          if(action.selectorType === "ceiling"){
              return action.typesData;
          }
          return typesData;
      default:
        return typesData;
    }
  };