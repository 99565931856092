import React, { ReactElement } from 'react'
import { Segment, Label, Button } from 'semantic-ui-react'

interface Props {
    version: string | null
}

export default function StoreWillUpdate({version}: Props): ReactElement {
    return (
        <Segment basic fluid textAlign="center" style={{ marginTop: 40 }}>
            {/* <Label style={{ position: "fixed", top: "5%", right: 15 }}>v. {version}</Label> */}
            <Button primary>Installation...</Button>
            <br />
            <div style={{ marginTop: 30 }}>
                Revit va redémarrer <br />
                L'installation devrait prendre une à deux minutes<br />
                <strong>Veuillez ne pas redémarrer Revit dans l'intervalle</strong>
            </div>
        </Segment>
    )
}
