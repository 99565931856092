import { SystemDetailsActionTypes, SET_CURRENT_SYSTEM_DETAIL, SET_ADVANCE_SELECTOR_DETAIL } from './types'
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";

export function setCurrentSystemAction(
  system: ProductDetailData,
): SystemDetailsActionTypes {
  return {
    type: SET_CURRENT_SYSTEM_DETAIL,
    system: system,
  };
}

export function setAdvanceSelectorAction(
  advanceSelector: any,
): SystemDetailsActionTypes {
  return {
    type: SET_ADVANCE_SELECTOR_DETAIL,
    advanceSelector: advanceSelector,
  };
}
