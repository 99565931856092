export enum APP_WINDOW_NAME {
  BOILERPLATE_WINDOW = "BOILERPLATE_WINDOW",
}

export const BOILERPLATE_HEADER_TEXT = "Boilerplate header text";
export const SYSTEM_PROPERTIES = "SYSTEM_PROPERTIES";

export const WALLSYSTEM = "Wall System";
export const FLOORSYSTEM = "Floor System";

export const WALLSYSTEM_ID = "wall";
export const FLOORSYSTEM_ID = "floor";

export const systemCategories = [
  {
    label: WALLSYSTEM,
    value: WALLSYSTEM_ID
  },
  {
    label: FLOORSYSTEM,
    value: FLOORSYSTEM_ID
  },
];
