import { combineReducers } from "redux";
import { config } from "../../../CLT/Delete/Reducers";
import app from "./app/reducers";
import { AppActionTypes, AppState } from "./app/types";


export const reducers = combineReducers({
    app: app,
    config
});

export interface DrawStore {
    app: AppState;
    config:any
}

export type ActionTypes = AppActionTypes 

export type RootState = ReturnType<typeof reducers>