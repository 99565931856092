import { Metres } from "./Metres";
import { Selection } from "./Selection";
import { Suppression } from "./Suppression";
import { DocumentTechniques } from "./DocumentTechniques";
import { Calpinage } from "./Calpinage";
import { ProjectInfo } from "../PlacoBIMv3-Test/ProjectInfo";
import { Functionality, Plugin } from "../../RevitJS/Types/StoreTypes";
import { ReverseDirection } from "./ReverseDirection";
import { ReverseSides } from "./ReverseSides";
import { UserManual } from "./UserManual";
import { Help } from "./Help";
import { ShowWalls } from "./ShowWalls";
import { ShowSolutions } from "./ShowSolutions";
import { Colorization } from "./Colorization";
import { Dimensions } from "./Dimensions";
import { Overlap } from "./Overlap";
// import { Quantity } from "./QuantitatiffOldProd";   Please don't remove this commented line
import { Quantity } from "./Quantitatiff";
import { Nomenclature } from "./Nomenclature";
import { Reperage } from "./Reperage";
import { QuantifierParRatio } from "./QuantifierParRatio";
import { LoadSystemContainer } from "./Bibliothèque/LoadSystemContainer";
import { Properties } from "./Bibliothèque/Properties";
import { Updates } from "./Bibliothèque/UpdateSystems";

export const pluginId = "931991b6-a570-46ac-be5d-8865058dcb12";
export const functionalityId = "9922aa18-a188-49d7-af00-faf69e903659";
export const biblioFunctionalityId = "98fe05e3-266c-4671-8821-c9887747f678";

export const CalpinageDropdown: Functionality = {
  Name: {
    French: "Calepinage",
    English: "Layout",
    German: "Calepinage",
    Italian: "Calepinage",
    Indonesian: "Calepinage,",
  },
  Trigger: () => {},
  ShortDesc: {
    French: "Lancer L'outil de calepinage",
    English: "Launch the layout tool",
    German: "Lancer L'outil de calepinage",
    Italian: "Lancer L'outil de calepinage",
    Indonesian: "Lancer L'outil de calepinage",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Calepinage.png",
  RootComponent: "",
  ParentId: "0",
};

export const BibliothequeDropdown: Functionality = {
  Name: {
    French: "Bibliothèque",
    English: "Library",
    German: "Bibliothèque",
    Italian: "Bibliothèque",
    Indonesian: "Bibliothèque,",
  },
  Trigger: () => {},
  ShortDesc: {
    French: "Modéliser avec des systèmes PLACO®",
    English: "Modeling with PLACO® systems",
    German: "Modéliser avec des systèmes PLACO®",
    Italian: "Modéliser avec des systèmes PLACO®",
    Indonesian: "Modéliser avec des systèmes PLACO®",
  },
  Id: biblioFunctionalityId,
  Icon: "/PLACO_ICONS_New/Biblio.png",
  RootComponent: "",
  ParentId: "0",
};

export const PlacoBimv3Test: Plugin = {
  TechnicalName: "PLACOBIMTEST",
  Name: "",
  Icon: "/PlacoBIM.jpg",
  ShortDesc: {
    French: "Solution BIM par Placo®",
    English: "Placo® tools for BIM v3",
    German: "Solution BIM par Placo®",
    Italian: "Solution BIM par Placo®",
    Indonesian: "Solution BIM par Placo®",
  },
  Id: pluginId,
  Color: "#005EB8",
  Functionalities: [
    ProjectInfo,
    BibliothequeDropdown,
    Properties,
    LoadSystemContainer,
    Updates,
    Selection,
    Metres,
    Nomenclature,
    DocumentTechniques,
    Reperage,
    QuantifierParRatio,
    CalpinageDropdown,
    Calpinage,
    ReverseSides,
    ReverseDirection,
    Suppression,
    Colorization,
    Quantity,
    Dimensions,
    ShowSolutions,
    ShowWalls,
    Overlap,
    Help,
    UserManual,
  ],
};
