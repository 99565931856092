import React, { useState } from "react";
import { Checkbox, Radio } from "semantic-ui-react";
import { Selection } from "../../Selection/Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import isEmpty from "lodash/isEmpty";
import "../Selection/SelectionStyle.css";
interface Props {
  selectRow: any;
  selection: Selection<PlacoOptions>;
  defaultChecked: boolean;
}

export const PerEtageRow = (props: Props) => {
  let { selectRow, selection, defaultChecked } = props;

  const convertDate = (d: any) => {
    let sp = d.split("/");
    let str1: any = sp[1];
    let str2: any = sp[0];
    if (str1.length == 1) {
      str1 = 0 + sp[1];
    } if (str2.length == 1) {
      str2 = 0 + sp[0];
    }
    let ret = [str2, str1, sp[2]].join("/");
    return ret;
  };

  return (
    <div className="HoveredRow">
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          display: "flex",
          width: "5%",
          color: "black",
          border: "0.2px",
        }}
      >
        <Radio
          name="radioGroup"
          label=""
          onChange={(e, d) => {
            selectRow(selection.Id, d.checked);
          }}
          checked={defaultChecked}
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          display: "block",
          width: "33%",
          color: "black",
          border: "0.2px",
          cursor: "pointer",
          wordWrap: "break-word",
          textAlign: "center",
        }}
      >
        {selection.Name}
      </div>

      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "25%",
          color: "black",
          display: "flex",
        }}
      >
        <div style={{ width: "100%" }}>
          {!isEmpty(selection.Levels) && selection.Levels.join("; ")}
        </div>
      </div>
      {/* <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "25%",
          color: "black",
          display: "flex",
        }}
      >
        <Checkbox disabled />
      </div> */}
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "25%",
          color: "black",
          display: "flex",
        }}
      >
        <div style={{ width: "30%" }}>{convertDate(selection.Date)}</div>
      </div>
    </div>
  );
};
