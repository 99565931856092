import React, { useState } from "react";
import { Checkbox, Radio } from "semantic-ui-react";
import { Selection } from "../../Selection/Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import isEmpty from "lodash/isEmpty";

interface Props {
  selectRow: any;
  selection: Selection<PlacoOptions>;
  defaultChecked: boolean;
}

export const PerZoneRow = (props: Props) => {
  let { selectRow, selection, defaultChecked } = props;
  const [rowHover, setRowHover] = useState<boolean>(false);

  const convertDate = (d: any) => {
    let sp = d.split("/");
    let str1: any = sp[1];
    let str2: any = sp[0];
    if (str1.length == 1) {
      str1 = 0 + sp[1];
    } if (str2.length == 1) {
      str2 = 0 + sp[0];
    }
    let ret = [str2, str1, sp[2]].join("/");
    return ret;
  };

  return (
    <div
      onMouseEnter={() => setRowHover(true)}
      onMouseLeave={() => setRowHover(false)}
      style={styleSelector(rowHover)}
    >
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          display: "flex",
          width: "5%",
          color: "black",
          border: "0.2px",
        }}
      >
        <Checkbox
          onChange={(e, d) => selectRow(selection.Id, d.checked)}
          checked={defaultChecked}
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          display: "block",
          width: "33%",
          color: "black",
          border: "0.2px",
          cursor: "pointer",
          wordWrap: "break-word",
          textAlign: "center",
        }}
      >
        {selection.Name}
      </div>

      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "25%",
          color: "black",
          display: "flex",
        }}
      >
        <div style={{ width: "100%" }}>
          {!isEmpty(selection.Levels) && selection.Levels.join("; ")}
        </div>
      </div>
      {/* <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "25%",
          color: "black",
          display: "flex",
        }}
      >
        {defaultChecked ? (
          <Checkbox disabled checked={selection.Zone} defaultChecked />
        ) : (
          <Checkbox disabled checked={selection.Zone} />
        )}
      </div> */}
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "25%",
          color: "black",
          display: "flex",
        }}
      >
        <div style={{ width: "30%" }}>{convertDate(selection.Date)}</div>
      </div>
    </div>
  );
};

const styleSelector = (hovered: boolean) => {
  if (!hovered) {
    return {
      padding: 5,
      minHeight: 25,
      // backgroundColor: "rgb(250, 250, 255)",
      display: "flex",
      alignItems: "center",
      borderBottom: "0.5px solid white",
    };
  }
  return {
    padding: 5,
    minHeight: 25,
    backgroundColor: "rgb(221, 231, 246)",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
    cursor: "pointer",
  };
};
