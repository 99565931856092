import flattenDeep from "lodash/flattenDeep";
import { parseRigipsSystem } from "../..";
import { MappingRowRigips } from "../../../../../../RevitJS/Types/StoreTypes";
import {
  A11LayersParser,
  AyLayersParser,
} from "../../LayersParsers/a1LayersParser";

export const a1Mapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = A11LayersParser(parsedSystem.layersDesc);
    parsedSystem.E1 = AlbaStructure[0]?.thickness;
    parsedSystem.E1_Name = "Alba";
    parsedSystem.I1 = AlbaStructure[2]?.thickness;
    parsedSystem.I1_Name = "Ghost";
    parsedSystem.plateWidth = 10e9;
    parsedSystem.AlbaStructure = AlbaStructure;
  }
  return parsedSystem;
};

export const a112yMapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = AyLayersParser(parsedSystem.layersDesc) as any[][];
    let [cladding_A, cladding_B] = AlbaStructure;

    parsedSystem.E1 = cladding_A[0].thickness;
    parsedSystem.E1_Name = cladding_A[0].name;
    parsedSystem.I1 = cladding_B[0].thickness;
    parsedSystem.I1_Name = cladding_B[0].name;
    parsedSystem.E2 = cladding_A.length > 1 ? cladding_A[0].thickness : "None";
    parsedSystem.E2_Name = cladding_A.length > 1 ? cladding_A[0].name : "None";
    parsedSystem.I2 = cladding_B.length > 1 ? cladding_B[0].thickness : "None";
    parsedSystem.I2_Name = cladding_B.length > 1 ? cladding_B[0].name : "None";

    // AlbaStructure[0] = flattenDeep(AlbaStructure[0]);
    // AlbaStructure[2] = flattenDeep(AlbaStructure[2]);
    parsedSystem.AlbaStructure = flattenDeep(AlbaStructure);
  }
  return parsedSystem;
};

export const a122yMapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = AyLayersParser(parsedSystem.layersDesc) as any[][];
    let [cladding_A, cladding_B] = AlbaStructure;
    parsedSystem.E1 = cladding_A[0].thickness;
    parsedSystem.E1_Name = cladding_A[0].name;
    parsedSystem.I1 = cladding_B[0].thickness;
    parsedSystem.I1_Name = cladding_B[0].name;
    parsedSystem.E2 = cladding_A.length > 1 ? cladding_A[0].thickness : "None";
    parsedSystem.E2_Name = cladding_A.length > 1 ? cladding_A[0].name : "None";
    parsedSystem.I2 = cladding_B.length > 1 ? cladding_B[0].thickness : "None";
    parsedSystem.I2_Name = cladding_B.length > 1 ? cladding_B[0].name : "None";
    parsedSystem.AlbaStructure = flattenDeep(AlbaStructure);
    parsedSystem.doubleAmount = true;
  }
  return parsedSystem;
};
