import React, { Component, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Routes, ElementType } from "../Util/type";
import MetresDownloader from "./ExcelExport/MetresDownloader";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { setprojectData } from "../../Selection/Actions/index";
import { setLanguage } from "../Actions";
import { initLanguage } from "../../Calpinage/Actions";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";
import { Container } from "semantic-ui-react";
import RatioTable from "./TypeOfQuantitatifs/RatioTable";
import { ElementSelector } from "./ElementSelection/ElementSelector";
import {
  QuantityContext,
  IQuantityContextType,
} from "../Context/quantityContext";

import { useSelector, useDispatch } from "react-redux";
import { MetresStore } from "../Reducers";
interface Props {
  onError: any;
}

const AppBody = (props: Props) => {
  const reduxState = useSelector((state: MetresStore) => state);
  const { root, setRoot } = useContext(QuantityContext) as IQuantityContextType;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProjectId = async () => {
      const cng = await MyConfig();
      dispatch(setConfig(cng));
      if (reduxState.projectData.ProjectId === "") {
        let projectData: initProjectData =
          await api.queries.getSetProjectData();

        if (projectData.ProjectId === "") {
        } else {
          dispatch(setprojectData(projectData));
        }
      }
    };
    fetchProjectId();
  }, []);

  switch (root) {
    case Routes.ROOT:
      return (
        <>
          <FunctionalityHeader
            Icon={reduxState.functionalityIcon}
            name={"QUANTITATIFS"}
            subheader=""
          />

          <Container
            className="header-alignment subHeader"
            style={{ marginTop: "1rem" }}
          >
            {`1/2 Choix du type de quantitatifs`}
          </Container>
          <Container
            className="grid-container"
            style={{ paddingTop: 15, height: "calc(100% - 150px)" }}
          >
            <MetresDownloader />
          </Container>
        </>
      );
    case Routes.ELEMENTSELECTION:
      return (
        <>
          {" "}
          <FunctionalityHeader Icon={""} name={"QUANTITATIFS"} subheader="" />
          <ElementSelector />
        </>
      );
    case Routes.CUSTOMERATIO:
      return (
        <>
          <FunctionalityHeader Icon={""} name={"QUANTITATIFS"} subheader="" />
          <RatioTable onError={props.onError} />
        </>
      );
    default:
      return <></>;
  }
};

export default AppBody;
