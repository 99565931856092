import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../Reducers";
import { Language } from "../../../../RevitJS/Types/RevitTypes";
import thunk from "redux-thunk";
import  AppBody  from "./AppBody";

interface Props {}

export enum Routes {
  ROOT = "Draw",
  MAPPING = "Mapping",
  GROUP_SELECTION = "GroupSelection",
  PRODUCT_SELECTOR = "ProductSelector"
}

interface State {
  route: Routes;
}

export class WallsSchedulesRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    this.store = createStore(reducers, applyMiddleware(thunk));
  }

  setRoute = (route: Routes) => {
    this.setState({
      route
    });
  };

  componentDidMount = () => {};

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody route={this.state.route} setRoute={this.setRoute} />
      </Provider>
    );
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(DrawRoot)
