import React, { useEffect, useState } from "react";
import { Button, Container } from "semantic-ui-react";
import { MyConfig } from "../../../../Helper";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";

interface Props {
  setRoute: any;
}

const Quantitatifs = (props: Props) => {
  const [config, setConfig] = useState({REACT_APP_SERVERURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])
  
  return (
    <>
     <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/Facade/quantitatifs.png"}
        name={"Quantitatifs"}
        subheader={"Extraction des matériaux"}
        config={config}
      />
      <Container textAlign="center" style={{ marginTop: 40 }}>
        <Button green size="big" className="green" style={{ minWidth: "200px" }}>
          {"Enregistrer la liste des matériaux"}
        </Button>
      </Container>
    </>
  );
};

export default Quantitatifs;
