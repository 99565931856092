import React, { Component } from "react";
import { Window, TitleBar, Text } from "react-desktop/windows";
import { Container } from "semantic-ui-react";
import { api } from "../../RevitJS/API";

interface Props {
  color: string;
  theme: string;
  popUpWindow: boolean;
  popUpHidden: boolean;
  windowDimensions: { width: number; height: number };
  children: any;
}

interface State {}

function vhTOpx(value: number): number {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    y = w.innerHeight || e.clientHeight || g.clientHeight;

  var result = (y * value) / 100;
  return result;
}

export default class PopupWindow extends Component<Props, State> {
  static defaultProps = {
    color: "#cc7f29",
    theme: "light",
  };

  state = {};

  render() {
    const {
      windowDimensions: { width, height },
    } = this.props;
    let top =
      window.innerHeight - vhTOpx(12.2) - height > 0
        ? (window.innerHeight + vhTOpx(12.2) - height) / 2
        : vhTOpx(12.2);
    let left =
      window.innerWidth * 0.75 - width > 0
        ? (window.innerWidth * 0.75 - width) / 2
        : 150;

    if (this.props.popUpWindow) {
      return (
        <div style={{ position: "fixed", top, left }}>
          <Window
            style={{ zIndex: 6 }}
            color={this.props.color}
            theme={this.props.theme}
            chrome
            height={`${height}px`}
            padding="12px"
            width={`${width}px`}
            hidden={this.props.popUpHidden}
          >
            <TitleBar
              title="Revit"
              controls
              onCloseClick={() => api.windowsHandler.closeWindow()}
            />
            <Text color={this.props.theme === "dark" ? "white" : "#333"}>
              <div style={{ minWidth: 400 }}>
                {
                  <Container
                    style={{
                      width: width - 22,
                      height: height - 60,
                      overflow: "auto",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    {this.props.children}
                  </Container>
                }
              </div>
            </Text>
          </Window>
        </div>
      );
    }

    return null;
  }
}
