import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../../Selection/Reducers";
import { Icon, Input, Button, Popup } from "semantic-ui-react";
import {
  duplicateSelection,
  deleteSelection,
  toggleZone,
} from "../../../Selection/Actions";
import { dbStoreNameCalepinage, Routes } from "../root";
import { setEditBuffer, deleteCalepinage } from "../../Actions";
import { ConfirmModal } from "../../../CommonModules/PopUpModals/ConfirmModal";
import { bimStorage } from "../../../../../BIMStore";
import { initProjectData } from "../../../../../RevitJS/Types/StoreTypes";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface State {
  hovered: boolean;
  confirmModal: boolean;
  modifyLoader: boolean;
}

const mapState = (
  state: SelectionStore,
  ownProps: {
    selection: any;
    setRoute: any;
    editSelectionName: any;
    updateSelectionname: any;
    editId: string;
    editName: string;
    errorMessage: string;
    setEditName: any;
  }
) => {
  return {
    moduleData: state.moduleData,
    selection: ownProps.selection,
    setRoute: ownProps.setRoute,
    editSelectionName: ownProps.editSelectionName,
    updateSelectionname: ownProps.updateSelectionname,
    editId: ownProps.editId,
    editName: ownProps.editName,
    errorMessage: ownProps.errorMessage,
    setEditName: ownProps.setEditName,
    projectData: state.projectData,
    config: state.config,
  };
};

const mapDispatch = {
  duplicateSelection,
  deleteSelection,
  setEditBuffer,
  toggleZone,
  deleteCalepinage,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  moduleData: any;
  setEditBuffer: any;
  deleteCalepinage: any;
  projectData: initProjectData;
  config: any;
};

export class SavedSelection extends Component<Props, State> {
  state = {
    hovered: false,
    confirmModal: false,
    modifyLoader: false,
  };

  componentDidMount = () => {};

  checkCalpinageSelection = (selectionId: string): boolean => {
    if (this.props.moduleData.moduleSelection.includes(selectionId)) {
      return true;
    }
    return false;
  };

  onDeleteIconClick = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };
  onDeleteYesCllick = () => {
    this.props.deleteCalepinage(this.props.selection.Id);

    bimStorage.deleteModule(
      dbStoreNameCalepinage,
      this.props.projectData.ProjectId,
      this.props.selection.Name
    );

    this.onDeleteIconClick();
  };

  onEdit = async (id: string) => {
    this.setState({ modifyLoader: true });
    const calepinageDetail = await bimStorage.listDetailModule(
      dbStoreNameCalepinage,
      id
    );

    if (calepinageDetail) {
      Promise.resolve(
        this.props.setEditBuffer(JSON.parse(calepinageDetail))
      ).then(() => {
        this.props.setRoute(Routes.CALPINAGE_HOME);
      });
    }
    this.setState({ modifyLoader: false });
  };
  render() {
    const {
      selection,
      editSelectionName,
      updateSelectionname,
      editId,
      editName,
      errorMessage,
      setEditName,
    } = this.props;
    const { hovered } = this.state;
    let convertDate = (d: any) => {
      let sp = d.split("/");
      let str1: any = sp[1];
      let str2: any = sp[0];
      if (str1.length == 1) {
        str1 = 0 + sp[1];
      }
      if (str2.length == 1) {
        str2 = 0 + sp[0];
      }
      let ret = [str2, str1, sp[2]].join("/");
      return ret;
    };
    return (
      <div
        style={{
          display: "table",
          width: "100%",
          padding: "5px",
        }}
        onMouseEnter={() =>
          editId !== selection.Id && this.setState({ hovered: true })
        }
        onMouseLeave={() => this.setState({ hovered: false })}
        className="row-hover"
      >
        <div
          style={{
            justifyContent: "center",
            fontWeight: "bold",
            display: "block",
            width: "50%",
            color: "black",
            float: "left",
            border: "0.2px solid transparent",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          <div>
            {editId !== selection.Id && (
              <Popup
                trigger={
                  <div
                    onClick={() => {
                      this.setState({ hovered: false });
                      editSelectionName(selection.Id, selection.Name);
                    }}
                  >
                    {selection.Name}
                  </div>
                }
                content="Cliquer pour modifier le nom"
                on="hover"
                inverted
                size="mini"
                position="bottom center"
              />
            )}
            {editId === selection.Id && (
              <Input
                type="text"
                placeholder="Enter Name..."
                value={editName}
                onChange={(e: any, { value }: any) => {
                  setEditName(value);
                  localStorage.setItem("isModification", "true");
                }}
                action
              >
                <input />
                <Popup
                  trigger={
                    <Button
                      icon
                      onClick={() => {
                        updateSelectionname(selection.Id, editName);
                      }}
                    >
                      <Icon name="check" />
                    </Button>
                  }
                  content="Valider la modification du nom"
                  on="hover"
                  inverted
                  size="mini"
                  position="bottom center"
                />
                <Popup
                  trigger={
                    <Button
                      icon
                      onClick={() => {
                        editSelectionName("", "");
                      }}
                    >
                      <Icon name="close" />
                    </Button>
                  }
                  content="Annuler la modification du nom"
                  on="hover"
                  inverted
                  size="mini"
                  position="bottom center"
                />
              </Input>
            )}
            {errorMessage !== "" && editId === selection.Id && (
              <div style={{ fontSize: "9px", color: "red" }}>
                {errorMessage}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "bold",
            width: "50%",
            color: "black",
            float: "left",
            padding:
              editId === selection.Id
                ? errorMessage !== "" && editId === selection.Id
                  ? "29.1px 0px"
                  : "19.2px 0px"
                : "0px 0px",
            border: "0.2px solid transparent",
          }}
        >
          {convertDate(selection.Date)}
          <div
            style={{
              float: "right",
              display: "flex",
              justifyContent: "center",
              padding: "0px 5px",
              height: "1px",
              borderBottom: "1px solid white",
            }}
          >
            {this.state.modifyLoader === true ? (
              <Oval
                color="#00BFFF" //3 secs
                height={18}
                width={18}
              />
            ) : (
              <Popup
                content="Modifier"
                position="top center"
                inverted
                size="mini"
                trigger={
                  <div
                    className="editIcon"
                    onClick={() => this.onEdit(selection.Id)}
                    style={{ cursor: "pointer", marginRight: 12 }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt as IconProp} />
                  </div>
                }
              />
            )}

            <Popup
              content="Supprimer"
              position="top right"
              inverted
              size="mini"
              trigger={
                <div
                  className="trashIcon"
                  onClick={this.onDeleteIconClick}
                  style={{ cursor: "pointer", marginRight: 10 }}
                >
                  <FontAwesomeIcon icon={faTrash as IconProp} />
                </div>
              }
            />
          </div>
        </div>
        <ConfirmModal
          confirmModal={this.state.confirmModal}
          no="NON"
          yes="OUI"
          description="Voulez-vous vraiment supprimer cette?"
          header="Supprimer la Calepinage"
          onYesAction={this.onDeleteYesCllick}
          onNoAction={() => this.onDeleteIconClick()}
        />
      </div>
    );
  }
}

export default connector(SavedSelection);
