import React, { useEffect, useState } from "react";
import AccordionContainer from "../../CommonModules/AccordionContainer";
import { FunctionalityHeader } from "./FunctionalityHeader";
import DetailsProperty from "../../CommonModules/DetailsProperty";
import Button from "@material-ui/core/Button";
import { Property } from "../Actions/types";
import { useSelector } from "react-redux";
import { SelectionStore } from "../Reducers";
import _ from "lodash";
import { setPAMAttributesValuesModal } from "../../CommonModules/utils/attributes.injection.helpers";
import { useTranslation } from "react-i18next";
import { fetchPlacoSolutionsDetails } from "../../../../API";
import { Dimmer, Loader } from "semantic-ui-react";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";

type Props = InjectedCounterProps & {
  setRoute: any;
  returnRedirect: any;
};
export const SystemDetailsContainer = (props: Props) => {
  const [details, setDetails] = useState<Property[]>([]);
  const [systemAttributes, setSystemAttributes] = useState<any>([]);
  const [system, setSystemBuffer] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [systemName, setSystemName] = useState("");

  const { t } = useTranslation(["library"]);

  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  useEffect(() => {
    async function callSystemDetails(oid: string, reduxSystem: any) {
      try {
        const solutionDetails = await fetchPlacoSolutionsDetails(
          [oid],
          reduxState.config
        ).catch((error) => {
          throw error;
        });
        const system = solutionDetails.data.objects[0];

        let productName = system?.attributes?.find((a:any) => a.technicalName ==="A-Solution product name")?.values[0]?.value;
        setSystemName(productName);

        const systemType = _.get(reduxSystem, "GFR-Works name");
        const attributesModal = systemType.includes("Cloisons")
          ? "WALL"
          : systemType.includes("Plafonds")
          ? "CEILING"
          : "LINING_WALLS";
        //const attributesModal = systemType === 'Cloisons' ? 'WALL' : systemType === 'Plafonds' ? 'CEILING' : 'LINING_WALLS';
        var attributes = setPAMAttributesValuesModal(
          attributesModal,
          system.attributes
        );
        setSystemAttributes(attributes);
        setLoading(false);
      } catch (error) {
        return Promise.reject(error);
      }
    }

    if (reduxState.selectionBuffer !== null) {
      const reduxSystem = reduxState.selectionBuffer;
      setSystemBuffer(reduxSystem);
      callSystemDetails(reduxSystem.oid, reduxSystem).catch((error) => {
        props.onError(errorCodeKey.PB_SE_L_002, error.stack, true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Dimmer active={loading} page>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div
      className="PropertyGlobalContainer"
      style={{ overflow: "auto", paddingRight: 20, marginRight: 0 }}
    >
      <FunctionalityHeader
        name={systemName}//{system.translation}
        Icon={reduxState.functionalityIcon}
      />
      <div className="DetailsContainer">
        {systemAttributes && systemAttributes.length > 0 && (
          <div className="PropertiesContent" style={{ height: "100%" }}>
            <div className="Properties">
              <div className="PropertyDetailsContent">
                {systemAttributes.map((p: any) => (
                  <AccordionContainer
                    key={t(p.chapterName) || t(p.categoryName)}
                    title={t(p.chapterName) || t(p.categoryName)}
                    properties={
                      p.chapterData &&
                      p.chapterData.map(
                        (prop: { categoryName: any; pamData: any[] }) => ({
                          title: t(prop.categoryName),
                          onClick: () =>
                            setDetails(
                              prop.pamData &&
                                prop.pamData.map((propr) => ({
                                  title: t(propr.key),
                                  value: propr.value,
                                }))
                            ),
                        })
                      )
                    }
                    onClick={() =>
                      p.pamData &&
                      setDetails(
                        p.pamData &&
                          p.pamData.map((prop: any) => ({
                            title: t(prop.key),
                            value: prop.value,
                          }))
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <DetailsProperty
              properties={
                details && details.length > 0
                  ? details
                  : systemAttributes[0].pamData.map((prop: any) => ({
                      title: t(prop.key),
                      value: prop.value,
                    }))
              }
            />
          </div>
        )}
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton detailActionButton"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => {
            props.returnRedirect();
          }}
        >
          {t("RETURN")}
        </Button>
      </div>
    </div>
  );
};
