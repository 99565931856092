import React, { Component } from "react";
import { Button, Dimmer, Icon, Segment, Card, Image } from "semantic-ui-react";
import { CompactPicker, SketchPicker } from "react-color";

interface Props {
  wording: {
    ok: { [key: string]: string };
    cancel: { [key: string]: string };
    addfavouriteColor: { [key: string]: string };
    otherColors: { [key: string]: string };
  };
  language: string;
  openFavColorModal: any;
  closeFavColorModal: any;
  toggleModal: any;
  addFavColor: any;
  favColor: any[];
  isFavChanged: boolean;
  setIsFavChanged: any;
}

interface State {
  favColor: any[];
  temp: string[];
  color: string;
  openFavCustomModal: boolean;
  openFavColorModal: boolean;
  colorHexCode: string;
  colors: string[];
}

export class AddFavColor extends Component<Props, State> {
  state = {
    favColor: this.props.favColor,
    temp: this.props.favColor,
    color: "",
    openFavCustomModal: false,
    openFavColorModal: false,
    colorHexCode: "",
    colors: [
      "#D0021B",
      "#F5A623",
      "#44546A",
      "#F8E71C",
      "#8B572A",
      "#7ED321",
      "#417505",
      "#BD10E0",
      "#9013FE",
      "#4A90E2",
      "#50E3C2",
      "#B8E986",
      "#000000",
      "#4A4A4A",
      "#9B9B9B",
      "#FFFFFF",
    ],
  };

  public static defaultProps = {
    wording: require("../../Resources/wording.json"),
  };

  // Add To Favourite
  addFavouriteColor = (color: any) => {
    if (!this.state.temp.some((item) => item === color)) {
      this.setState({
        temp: [...this.state.temp, color],
      });
    }
  };

  cancelfav = () => {
    if (
      !this.state.temp.some((item) =>
        this.setState({ temp: [...this.props.favColor] })
      )
    ) {
      this.props.closeFavColorModal();
      this.setState({ openFavCustomModal: false });
      this.props.toggleModal(true);
    }
  };

  addfav = () => {
    if (this.state.temp !== null) {
      this.props.addFavColor(this.state.temp);
    } else {
      this.setState({ temp: [...this.props.favColor] });
    }
    this.setState({ openFavCustomModal: false });
  };

  handleChange = (color: any) => {
    this.setState({ color: color.hex });
    this.addFavouriteColor(color.hex);
  };

  handleChangeComplete = (color: any) => {
    this.setState({ colorHexCode: color });
  };

  addExtendedColor = () => {
    this.addFavouriteColor(this.state.colorHexCode);
    this.setState({ openFavCustomModal: false });
  };

  componentDidMount() {
    this.setState({ temp: this.props.favColor });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    //if (this.props.isFavChanged || (this.props.favColor.length > this.state.temp.length)) {
    if (this.props.isFavChanged) {
      var favcolor = this.props.favColor;
      this.setState({ temp: favcolor });
      this.props.setIsFavChanged(false);
    }
  }

  render() {
    return (
      <div>
        <Dimmer active={this.props.openFavColorModal}>
          <Card
            style={{
              border: "none",
              float: "center",
            }}
          >
            <Card.Content
              header={this.props.wording.addfavouriteColor[this.props.language]}
              style={{
                backgroundColor: "#ebebeb",
                fontSize: "smaller",
              }}
            />
            <Card.Content extra>
              {/* {JSON.stringify(this.props.favColor)}
              {JSON.stringify(this.state.favColor)} */}
              {this.state.colors.map((e: any) =>
                this.state.temp.includes(e.toLowerCase()) ? (
                  <style>
                    {`\
               .compact-picker span div[title="${e.toUpperCase()}"]{outline: 1px solid #000000 !important }\
               `}
                  </style>
                ) : (
                  <style>
                    {`\
              .compact-picker span div[title="${e.toUpperCase()}"]{outline: none }\
              `}
                  </style>
                )
              )}

              <CompactPicker
                colors={[
                  "#D0021B",
                  "#F5A623",
                  "#44546A",
                  "#F8E71C",
                  "#8B572A",
                  "#7ED321",
                  "#417505",
                  "#BD10E0",
                  "#9013FE",
                  "#4A90E2",
                  "#50E3C2",
                  "#B8E986",
                  "#000000",
                  "#4A4A4A",
                  "#9B9B9B",
                  "#FFFFFF",
                ]}
                onChange={this.handleChange}
              />
            </Card.Content>
            <Card.Content extra>
              <div style={{ width: "100%", cursor: "pointer", color: "black" }}>
                <Image
                  src={require("../../Resources/morecolor.png")}
                  style={{ paddingLeft: "2px" }}
                />
                <span
                  onClick={() => {
                    this.setState({ openFavCustomModal: true });
                    // this.props.closeFavColorModal();
                  }}
                >
                  {this.props.wording.otherColors[this.props.language]}
                </span>
              </div>
            </Card.Content>
            <Card.Content extra style={{ textAlign: "center" }}>
              <Icon
                name="checkmark box"
                size="large"
                color="green"
                onClick={() => {
                  this.addfav();
                }}
              />
              <Icon
                name="window close"
                size="large"
                color="red"
                onClick={() => this.cancelfav()}
              />
            </Card.Content>
          </Card>

          {/* Extended Custom Color Picker */}
        </Dimmer>

        <Dimmer active={this.state.openFavCustomModal}>
          <Segment
            style={{
              // width: 1090,
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <Card style={{ float: "right", width: "230px" }}>
              <Card.Content extra>
                <SketchPicker
                  color={this.state.colorHexCode}
                  onChange={(e) => this.handleChangeComplete(e.hex)}
                  presetColors={[]}
                  disableAlpha
                />
              </Card.Content>
              <Card.Content extra>
                <Button
                  onClick={() => {
                    this.addExtendedColor();
                  }}
                >
                  {this.props.wording.ok[this.props.language]}
                </Button>
                <Button onClick={this.cancelfav}>
                  {this.props.wording.cancel[this.props.language]}
                </Button>
              </Card.Content>
            </Card>
          </Segment>
        </Dimmer>
      </div>
    );
  }
}

export default AddFavColor;
