import {
  ceil,
  find,
  flatMap,
  forEach,
  map,
  uniq,
  get,
  filter,
  cloneDeep,
} from "lodash";
import React, { useEffect, useContext, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Grid,
  Loader,
  Pagination,
  Table,
  Modal,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../../Selection/Components/FunctionalityHeader";
import { Routes } from "../root";
import {
  IQuantityContextType,
  QuantityContext,
} from "../../context/quantityContext";
import { api } from "../../../../../RevitJS/API";
import { fetchPlacoDataInGroupSplit } from "../../../Calpinage/Helpers/placoHelper";
import {
  collectEtageData,
  collectZoneData,
  collectAccessoryWithCustomRatio,
  collectZoneAccessoryWithCustomRatio,
} from "../../../Quantitatiff/Extract/extractHelper";
import { woolType } from "../../../Quantitatiff/Extract/type";
import {
  Accessory,
  Montant,
  Rails,
  Plaqs,
} from "../../../Quantitatiff/Actions/types";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { RatioTableRow } from "./RatioTableNewRow";
import { wait } from "../../../../../RevitJS/Helpers";
import { writeOptimizedBooks } from "../../../Quantitatiff/Extract/extractOpt";
import { writeOptimizedZoneBooks } from "../../../Quantitatiff/Extract/extractOptZone";
import {
  collectEtageCeilingData,
  collectZoneCeilingData,
  collectCeilingAccessoryWithCustomRatio,
  collectCeilingZoneAccessoryWithCustomRatio,
} from "../../../Quantitatiff/Extract/extractCeilingHelper";
import { writeOptimizedZoneCeilingBooks } from "../../../Quantitatiff/Extract/extractOptZoneCeiling";
import { writeOptimizedCeilingBooks } from "../../../Quantitatiff/Extract/extractOptCeiling";
import { asyncForEach } from "../../../Selection/Actions";
import { ContextType, GlobalStateContext } from "../../context/golbalStateContext";

export interface IRationTable {
  typeArticle: string;
  nameArticle: string;
  ratioDefault: number;
  rationCustom: number;
  order: number;
  placoSolution: string;
  unit: string;
  articleList: any;
  solutionId?: number;
}

interface Props {
  route: any;
  setRoute: any;
}

interface IMapzoneWall {
  zone: any;
  walls: any;
}

export interface ICollectedArticle {
  collected_wool: woolType[];
  collected_accessory: Accessory[];
  collected_rail: Rails[];
  collected_montant: Montant[];
  collected_plaques: Plaqs[];
  default_ratio: IRationTable[];
  finalExtract: any;
  userRatio: { [key: string]: string };
  mapZoneWall: IMapzoneWall[] | null;
  processedWallParams: any;
  ratioListArray: any;
}

const RatioTable = (props: Props) => {
  const {
    processWalls,
    config,
    setUnitConversionArray,
    getUnitConversionArray,
    elementType,
    processCeilings,
    selectionType,
  } = useContext(QuantityContext) as IQuantityContextType;
  const [loading, setLoading] = useState<boolean>(false);
  const [isInput, setIsInput] = useState<boolean>(false);
  const [extractData, setExtractData] = useState<
    ICollectedArticle | undefined
  >();
  const [raiseWarning, setRaiseWarning] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [ratioPrompt, setRatioPrompt] = useState<boolean>(false);
  const [ratioMessage, setRatioMessage] = useState<string[]>();
  const { t, i18n } = useTranslation(["library"]);
  const [activePage, setActivePage] = useState<number>(1);
  const [zoneElements, setZoneElements] = useState<any>();
  const { globalState, setGlobalStateByKey } = useContext(GlobalStateContext) as ContextType;

  const { register, handleSubmit } = useForm();

  const handleCustomRatioCheckClick = (e: any, data: any) => {
    setIsInput(data.checked);
  };

  const setWarning = (message: string) => {
    setRaiseWarning(true);
    setWarningMessage(message);
  };

  useEffect(() => {
    async function getProcessData() {
      let processElements =
        elementType === "wall" ? processWalls : processCeilings;

      const chkProcessElements = filter(processElements, (el: any) => {
        return el.chk;
      });

      let processedElementIds = flatMap(map(chkProcessElements, "Ids"));

      let elementsData =
        elementType === "wall"
          ? await api.queries.getWallsData(processedElementIds)
          : await api.queries.getCeilingsData(processedElementIds);

      //let processedSolution = uniq(map(processedWallParams, "Params[0].Value"));
      // /// 16. Fetch placo solutions from api database

      // /// 17. Extract solution ids from fetch data
      const solutionOids: string[] = uniq(
        map(chkProcessElements, "PlacoSolutionId")
      );

      // /// 18. Fetch solution details with child and parent
      // ///     Extract products, solution products and articles
      let { extractedSolutionProduct } = await fetchPlacoDataInGroupSplit(
        solutionOids,
        config
      );

      extractedSolutionProduct = map(
        extractedSolutionProduct,
        (extSol, index) => {
          extSol.layoutPossible = false;
          return extSol;
        }
      );

      let processedElementParams = flatMap(
        map(chkProcessElements, (elemData, index) => {
          const mapSolutionProducts = map(
            extractedSolutionProduct,
            (extSol: any) => {
              return {
                oid: extSol.solutionProduct.oid,
                technicalName: extSol.solutionProduct.technicalName,
              };
            }
          );
          const solProduct: any = find(mapSolutionProducts, {
            oid: elemData.PlacoSolutionId,
          });

          return map(elemData.Ids, (elemId, wallIdIndex) => {
            return {
              Id: elemId,
              Params: [
                {
                  Name: "SG_System",
                  Type: "Text",
                  TypeSolution: null,
                  Value: solProduct ? solProduct.technicalName : "",
                },
                {
                  Name: "Plaque",
                  Type: "Text",
                  TypeSolution: null,
                  Value: elemData.plaque.join(";"),
                },
                {
                  Name: "Montant",
                  Type: "Text",
                  TypeSolution: null,
                  Value: elemData.montant.join(";"),
                },
              ],
            };
          });
        })
      );

      return {
        chkProcessElements,
        elementsData,
        extractedSolutionProduct,
        processedElementParams,
      };
    }

    async function collectExtractData(
      processedWallParams: any,
      extractedSolutionProduct: any,
      elementsData: any,
      setUnitArray: any,
      mapZoneElement: any
    ) {
      try {
        let unitConversionArray = getUnitConversionArray();
        if (selectionType === "zone") {
          if (elementType === "wall") {
            await forEach(processedWallParams, (wall, index) => {
              const wallDetails = find(elementsData, { Id: wall.Id });
              mapZoneElement.forEach((fin: any) => {
                fin.walls.forEach((id: any) => {
                  if(wallDetails.Id === id){
                    wallDetails.zone = fin.zone;
                  }
                })
              })
              const placoSolution = find(extractedSolutionProduct, (slProduct) => {
                return slProduct.solutionProduct.technicalName === wall.Params[0].Value;
              });
              const solutionP = filter(placoSolution.solutionProduct.attributes, (prd: { technicalName: string; }) => {
                return prd.technicalName === "A-Solution product name"
              });

              console.log("Solution P: ", solutionP);
              setGlobalStateByKey(placoSolution.solutionProduct.translation, solutionP[0].values[0].value);
            });
            return await collectZoneData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              mapZoneElement,
              unitConversionArray,
              true
            );
          } else {
            await forEach(processedWallParams, (wall, index) => {
              const ceilingDetails = find(elementsData, { Id: wall.Id });
              mapZoneElement.forEach((fin: any) => {
                fin.ceilings.forEach((id: any) => {
                  if(ceilingDetails.Id === id){
                    ceilingDetails.zone = fin.zone;
                  }
                })
              })
              const placoSolution = find(extractedSolutionProduct, (slProduct) => {
                return slProduct.solutionProduct.technicalName === wall.Params[0].Value;
              });
              const solutionP = filter(placoSolution.solutionProduct.attributes, (prd: { technicalName: string; }) => {
                return prd.technicalName === "A-Solution product name"
              });
              
              console.log("Solution P: ", solutionP);
              setGlobalStateByKey(placoSolution.solutionProduct.translation, solutionP[0].values[0].value);
            });
            return await collectZoneCeilingData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              mapZoneElement,
              unitConversionArray,
              true
            );
          }
        } else {
          if (elementType === "wall") {
            await forEach(processedWallParams, (wall, index) => {
              const placoSolution = find(extractedSolutionProduct, (slProduct) => {
                return slProduct.solutionProduct.technicalName === wall.Params[0].Value;
              });
          
              if (placoSolution) {
                const solutionP = filter(placoSolution.solutionProduct.attributes, (prd: { technicalName: string; }) => {
                  return prd.technicalName === "A-Solution product name"
                });

                console.log("Solution P: ", solutionP);
                setGlobalStateByKey(placoSolution.solutionProduct.translation, solutionP[0].values[0].value);
              }
            });
            return await collectEtageData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              unitConversionArray,
              true
            );
          } else {
            await forEach(processedWallParams, (ceiling, index) => {
              const ceilingDetails = find(elementsData, { Id: ceiling.Id });
              const placoSolution = find(extractedSolutionProduct, (slProduct) => {
                return (
                  slProduct.solutionProduct.technicalName === ceiling.Params[0].Value
                );
              });
        
              if (placoSolution) {
                const solutionP = filter(placoSolution.solutionProduct.attributes, (prd: { technicalName: string; }) => {
                  return prd.technicalName === "A-Solution product name"
                });
                
                console.log("Solution P: ", solutionP);
                setGlobalStateByKey(placoSolution.solutionProduct.translation, solutionP[0].values[0].value);

              }
            });
            return await collectEtageCeilingData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              unitConversionArray,
              true
            );
          }
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }

    async function extractRatio() {
      setLoading(true);
      const {
        chkProcessElements,
        processedElementParams,
        extractedSolutionProduct,
        elementsData,
      } = await getProcessData();

      if (extractedSolutionProduct.length > 0) {
        const mapZoneElement = map(chkProcessElements, (zs) => {
          return {
            zone: zs.Zone,
            walls: zs.Ids,
            ceilings: zs.Ids,
          };
        });

        console.log(getUnitConversionArray());

        const collectedExtractData = await collectExtractData(
          processedElementParams,
          extractedSolutionProduct,
          elementsData,
          setUnitConversionArray,
          mapZoneElement
        );

        setZoneElements(mapZoneElement);
        if (collectedExtractData) {
          setExtractData(collectedExtractData);
        } else {
          setWarning(
            t(
              "Les quantitatifs par étage et par zone ne peuvent pas être générés car aucun système calepiné n'appartient à une zone créée dans le module Sélection"
            )
          );
        }
      } else {
        setWarning(
          t(
            "Les quantitatifs par étage et par zone ne peuvent pas être générés car aucun système calepiné n'appartient à une zone créée dans le module Sélection"
          )
        );
      }

      setLoading(false);
    }

    extractRatio();
  }, []);

  const totalPagesNumber = extractData?.ratioListArray
    ? ceil(extractData?.ratioListArray.length / 10)
    : 1;

  const onSubmit = async (data: any) => {
    console.log("data", data);

    if (extractData) {
      console.log("extractData", extractData);
      setLoading(true);
      await wait(1000);

      let ratioArray: any = [];

      map(extractData.ratioListArray, (ratArray, index) => {
        if (ratArray.articleList) {
          map(ratArray.articleList, (artLi, artIndex) => {
            if (artLi.selected) {
              ratioArray.push({
                typeArticle: ratArray.typeArticle,
                nameArticle: artLi.articleName,
                ratioDefault: artLi.ratio,
                rationCustom: artLi.rationCustom ? ratArray.rationCustom : 0,
                order: ratArray.order,
                placoSolution: ratArray.placoSolution,
                unit: ratArray.unit,
                articleList: null,
                solutionId: ratArray.solutionId,
              });
            }
          });
        } else {
          ratioArray.push({
            typeArticle: ratArray.typeArticle,
            nameArticle: ratArray.nameArticle,
            ratioDefault: ratArray.ratioDefault,
            rationCustom: ratArray.rationCustom ? ratArray.rationCustom : 0,
            order: ratArray.order,
            placoSolution: ratArray.placoSolution,
            unit: ratArray.unit,
            articleList: null,
            solutionId: ratArray.solutionId,
          });
        }
      });

      let moExtractState: any = {};
      moExtractState = extractData;
      moExtractState["default_ratio"] = JSON.parse(JSON.stringify(ratioArray));
      moExtractState["userRatio"] = JSON.parse(
        JSON.stringify(extractData["userRatio"])
      );

      if (Object.keys(moExtractState).length > 0) {
        forEach(data, (dtValue, dtKey) => {
          let articleName = "";
          const modifiedDefaultRation = map(
            extractData.default_ratio,
            (extrRatio: IRationTable) => {
              const rationValue =
                extrRatio.solutionId +
                extrRatio.nameArticle
                  .split(" ")
                  .join("")
                  .replace(/[^a-zA-Z0-9]/g, "");
              if (rationValue === dtKey) {
                extrRatio.rationCustom = dtValue;
                articleName = extrRatio.solutionId + extrRatio.nameArticle;
              }
              return extrRatio;
            }
          );

          moExtractState.default_ratio = modifiedDefaultRation;
          moExtractState.userRatio = {
            ...moExtractState.userRatio,
            [articleName]: dtValue,
          };
        });

        await bimStorage.setItem(
          storageKey.QUANTIFY,
          JSON.stringify(moExtractState.userRatio)
        );

        let emptyRatio: string[] = [];

        forEach(moExtractState.default_ratio, (dfRatio: any, inkey: number) => {
          if (isInput) {
            if (
              dfRatio.rationCustom === 0 ||
              dfRatio.rationCustom === "0" ||
              dfRatio.rationCustom === "" ||
              dfRatio.rationCustom === "999" ||
              dfRatio.rationCustom === "9999"
            ) {
              emptyRatio.push(dfRatio.nameArticle);
            }
          } else if (dfRatio.ratioDefault === 0) {
            emptyRatio.push(dfRatio.nameArticle);
          }
        });

        if (emptyRatio.length > 0) {
          setRatioMessage(emptyRatio);
          setRatioPrompt(true);
        } else {
          if (isInput) {
            if (selectionType === "zone") {
              if (elementType === "wall") {
                const updateZoneAccessoryByRatio =
                  await collectZoneAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitConversionArray,
                    },
                    zoneElements
                  );

                if (updateZoneAccessoryByRatio) {
                  moExtractState.collected_accessory =
                    updateZoneAccessoryByRatio;
                }
              } else {
                const updateZoneAccessoryByRatio =
                  await collectCeilingZoneAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitConversionArray,
                    },
                    zoneElements
                  );

                if (updateZoneAccessoryByRatio) {
                  moExtractState.collected_accessory =
                    updateZoneAccessoryByRatio;
                }
              }
            } else {
              if (elementType === "wall") {
                const updateAccessoryByRatio =
                  await collectAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitConversionArray,
                    }
                  );
                if (updateAccessoryByRatio) {
                  moExtractState.collected_accessory = updateAccessoryByRatio;
                }
              } else {
                const updateAccessoryByRatio =
                  await collectCeilingAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitConversionArray,
                    }
                  );
                if (updateAccessoryByRatio) {
                  moExtractState.collected_accessory = updateAccessoryByRatio;
                }
              }
            }
          }

          if (selectionType === "zone") {
            if (elementType === "ceiling") {
              setLoading(true);
              await writeOptimizedZoneCeilingBooks(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitConversionArray,
                },
                getUnitConversionArray,
                config,
                moExtractState,
                isInput,
                globalState
              );
              setLoading(false);
            } else {
              await writeOptimizedZoneBooks(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitConversionArray,
                },
                getUnitConversionArray,
                config,
                moExtractState,
                isInput,
                globalState
              );
              setLoading(false);
            }
          } else {
            if (elementType === "ceiling") {
              setLoading(true);
              await writeOptimizedCeilingBooks(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitConversionArray,
                },
                getUnitConversionArray,
                config,
                moExtractState,
                isInput,
                globalState
              );
              setLoading(false);
            } else {
              setLoading(true);
              await writeOptimizedBooks(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitConversionArray,
                },
                getUnitConversionArray,
                config,
                moExtractState,
                isInput,
                globalState
              );
              setLoading(false);
            }
          }
        }
      }
    }
    setLoading(false);
  };

  const onChangeArticle = (
    solutionId: any,
    articleId: any,
    articleName: any,
    typeArticle: any
  ) => {
    if (solutionId && articleId && articleName) {
      if (extractData) {
        let cloneExtractData = JSON.parse(JSON.stringify(extractData));
        let rationDeepListArray = cloneDeep(cloneExtractData.ratioListArray);
        rationDeepListArray = map(rationDeepListArray, (rat: any) => {
          if (rat.articleId === articleId && rat.solutionId === solutionId) {
            map(rat.articleList, (artList) => {
              if (artList.articleName === articleName) {
                rat.defaultValue = artList.articleName;
                rat.ratioDefault = artList.ratio;
                rat.rationCustom = artList.rationCustom;
                artList.selected = true;
              } else {
                artList.selected = false;
              }
              return artList;
            });
            return rat;
          }
          return rat;
        });

        if (typeArticle === "Produits isolants") {
          let woolListArray = cloneDeep(cloneExtractData.collected_wool);
          woolListArray = map(woolListArray, (rat: any) => {
            if (rat.solutionId === solutionId) {
              map(rat.articleLists, (artList) => {
                if (artList.articleName === articleName) {
                  artList.selected = true;
                } else {
                  artList.selected = false;
                }
                return artList;
              });
              return rat;
            }
            return rat;
          });
          cloneExtractData.collected_wool = woolListArray;
        } else if (typeArticle === "Produits plâtre") {
          let plaqueListArray = cloneDeep(cloneExtractData.collected_plaques);
          plaqueListArray = map(plaqueListArray, (rat: any) => {
            if (rat.articleId === articleId && rat.solutionId === solutionId) {
              map(rat.articleLists, (artList) => {
                if (artList.articleName === articleName) {
                  artList.selected = true;
                } else {
                  artList.selected = false;
                }
                return artList;
              });
              return rat;
            }
            return rat;
          });
          cloneExtractData.collected_plaques = plaqueListArray;
        } else if (typeArticle === "Ossatures Vertical") {
          let montantListArray = cloneDeep(cloneExtractData.collected_montant);
          montantListArray = map(montantListArray, (rat: any) => {
            if (rat.articleId === articleId && rat.solutionId === solutionId) {
              map(rat.articleLists, (artList) => {
                if (artList.articleName === articleName) {
                  artList.selected = true;
                } else {
                  artList.selected = false;
                }
                return artList;
              });
              return rat;
            }
            return rat;
          });
          cloneExtractData.collected_montant = montantListArray;
        }
        cloneExtractData.ratioListArray = rationDeepListArray;
        setExtractData(cloneExtractData);
      }
    }
  };
  useEffect(() => {
    let containerToBeScrolled: any = document.querySelector(".height470");
    containerToBeScrolled.scrollTop = 0;
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, [activePage]);

  if (loading) {
    return (
      <>
        <Container
          className="grid-container"
          style={{ width: "90%", border: "0 none", padding: "0" }}
        >
          <Dimmer
            active
            style={{
              height: "calc(100vh - 30px)",
            }}
          >
            <Loader />
          </Dimmer>
        </Container>
      </>
    );
  }

  return (
    <>
      <FunctionalityHeader
        Icon={""}
        name={"QUANTITATIFS PAR RATIO"}
        subheader=""
      />
      <Container
        className="header-alignment subHeader"
        style={{ marginTop: "1rem", width: "90%" }}
      >
        {`2/2 Ratio personnalisés`}
      </Container>
      <Container
        className="grid-container"
        style={{ width: "90%", border: "0 none", padding: "0" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} className="height470">
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="width10">
                        Type article
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width30">
                        Ouvrage
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width20">
                        Nom de l’article
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width15">
                        Ratio par défaut{" "}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width25">
                        <Checkbox
                          label="Ratio personnalisé"
                          checked={isInput}
                          onChange={handleCustomRatioCheckClick}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width15">
                        Unité/m²{" "}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <RatioTableRow
                    default_ratio={extractData?.default_ratio}
                    isInput={isInput}
                    register={register}
                    activePage={activePage}
                    ratioListArray={extractData?.ratioListArray}
                    onChangeArticle={onChangeArticle}
                  />
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            className="middel-full-width aligned-pagination"
            style={{ marginTop: "0 !important" }}
          >
            <Grid.Column className="rationButton" floated="left">
              <Button
                color="orange"
                onClick={() => props.setRoute(Routes.ROOT)}
              >
                Annuler
              </Button>
              <Button primary type="submit">
                Générer
              </Button>
            </Grid.Column>
            <Grid.Column className="ratioPagination" floated="right" width={6}>
              <Pagination
                activePage={activePage}
                onPageChange={(e, { activePage }) => {
                  setActivePage(activePage as number);
                  // window.scrollTop(0,0);
                }}
                totalPages={totalPagesNumber}
                firstItem={null}
                lastItem={null}
              />
            </Grid.Column>
          </Grid>
        </form>
      </Container>
      <Modal
        size={"tiny"}
        open={ratioPrompt}
        onClose={() => {
          setRatioPrompt(false);
          setRatioMessage([]);
        }}
      >
        <Modal.Header>Ratio Empty</Modal.Header>
        <Modal.Content>
          <>
            <p>{t("EMPTY_RATIO")}</p>
            {ratioMessage &&
              ratioMessage.map((rmess) => {
                return <p>{rmess}</p>;
              })}
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={() => {
              setRatioPrompt(false);
              setRatioMessage([]);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default RatioTable;
