import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import MetresRoot from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import jwt_decode from "jwt-decode";
import QuantityProvider from "./Context/quantityContext";

export const functionalityId = "e9ea6940-9055-4a76-a717-4579300b4ec5";

export const trigger = async (config: any) => {
  api.windowsHandler.openWindow(
    920,
    610,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Quantitatifs",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Quantitatives"
    >
      <QuantityProvider>
        <MetresRoot />
      </QuantityProvider>
    </ParentRoot>
  );
};

export const QuantitatiffRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Quantitatives"
    >
      <QuantityProvider>
        <MetresRoot />
      </QuantityProvider>
    </ParentRoot>
  );
};

export const Quantity: Functionality = {
  Name: {
    French: "Quantitatifs",
    English: "Quantitatives",
    German: "Quantitatifs",
    Italian: "Quantitatifs",
    Indonesian: "Quantitatifs",
  },
  Trigger: trigger,
  ShortDesc: {
    French: "Télécharger les quantitatifs",
    English: "Download the quantitatives",
    German: "Télécharger les quantitatifs",
    Italian: "Télécharger les quantitatifs",
    Indonesian: "Télécharger les quantitatifs",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Quantitatifs.png",
  RootComponent,
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
