import React from "react";
import { Provider } from "react-redux";
import AppBody from "../components/AppBody";
import configureStore from "../store";

import "./index.scss";
import MappingConfigProvider from "../../Common/context/mappingConfig.context";

interface Props {}

const store = configureStore();

export const UserManualRoot: React.FC<Props> = () => {
  return (
    <Provider store={store}>
      <MappingConfigProvider>
        <AppBody />
      </MappingConfigProvider>
    </Provider>
  );
};
