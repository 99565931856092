import React from "react"
import { Provider } from "react-redux"
import AppBody from "./AppBody"
import configureStore from "../store"

import './index.scss'

interface Props { }

const store = configureStore()

export const Root: React.FC<Props> = ({ }) => {

    return (
        <Provider store={store}>
            <AppBody />
        </Provider>
    )
}

