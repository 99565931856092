import Excel from "exceljs";

export const setCellAlignment = (
    cell: Excel.Cell,
    horizontal:
      | "left"
      | "center"
      | "right"
      | "fill"
      | "justify"
      | "centerContinuous"
      | "distributed",
    vertical: "top" | "middle" | "bottom" | "distributed" | "justify"
  ) => {
    cell.alignment = {
      horizontal: horizontal,
      vertical: vertical,
      wrapText: true,
    };
  };
  
  export const setCellFont = (
    cell: Excel.Cell,
    font: "Arial" | "Calibri",
    size: 9 | 10 | 11,
    bold: true | false,
    color: "blue" | "black"
  ) => {
    let applyColor: string = "FF000000";
  
    if (color === "blue") applyColor = "FF4473c4";
  
    cell.font = {
      bold: bold,
      color: { argb: applyColor },
      family: 2,
      name: font,
      size: size,
    };
  };
  
  export const setColumnType = (cell: Excel.Cell) => {
    cell.model.type = 2;
    cell.numFmt = "0";
  };
  
  export const setSolidFill = (cell: Excel.Cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "D9D9D9",
      },
    };
  };


  
export const setCellBorder = (
    cell: Excel.Cell,
    all: boolean,
    top?: boolean,
    bottom?: boolean,
    right?: boolean,
    left?: boolean
  ) => {
    if (all) {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    } else {
      if (top) {
        cell.border = {
          top: { style: "thin" },
        };
      }
  
      if (left) {
        cell.border = {
          left: { style: "thin" },
        };
      }
  
      if (bottom) {
        cell.border = {
          bottom: { style: "thin" },
        };
      }
  
      if (right) {
        cell.border = {
          right: { style: "thin" },
        };
      }

      if(top && bottom) {
        cell.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
        };
      }

      if(left && right) {
        cell.border = {
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }

      if(bottom && right) {
        cell.border = {
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      if(top && right) {
        cell.border = {
          top: { style: "thin" },
          right: { style: "thin" },
        };
      }

      if(bottom && left) {
        cell.border = {
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
      }

      if(top && left) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
        };
      }

      if(top && bottom && right) {
        cell.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  };
  