import React, { useEffect, useState } from "react";
import { FunctionalityLauncher } from "./FunctionalityLauncher";
import { List } from "semantic-ui-react";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { ID } from "../../RevitJS/Types/RevitTypes";
interface FunctionalityLauncherProps {
  language: string;
  plugin: Plugin | undefined;
  selectedPluginId: ID;
  config: any;
}
export const FunctionalityLauncherList = (
  props: FunctionalityLauncherProps
) => {
  const [currentPluginId, setCurrentPluginId] = useState<ID>("");

  const setPluginId = (pluginId: ID) => {
    setCurrentPluginId(pluginId || "");
  };

  const quickMetresId = "e7f1efde-96e9-4240-bd93-e09761e31d76";
  if (!props.plugin) return null;

  if (currentPluginId === "abfb1e2e-b36b-4be4-a78d-df2472cbb96c") {
    localStorage.setItem("moduleName", "Métrés");
  } else {
    localStorage.removeItem("moduleName");
  }

  const functionalityLaunchers = (plugin: Plugin) =>
    plugin.Functionalities.filter((func) => func.ParentId === "0").map((func) =>
      // hide Quick Calepinage from the list
      func.Id === quickMetresId ? (
        ""
      ) : (
        <FunctionalityLauncher
          key={func.Id}
          language={props.language}
          ShortDesc={func.ShortDesc}
          Name={func.Name}
          Icon={func.Icon}
          Trigger={func.Trigger}
          ParentId={func.ParentId}
          plugin={props.plugin}
          Id={func.Id}
          childList={plugin.Functionalities.filter(
            (child) => child.ParentId !== "0" && child.ParentId === func.Id
          )}
          currentPluginId={currentPluginId}
          setPluginId={setPluginId}
          config={props.config}
        />
      )
    );
  return (
    <List
      style={{
        height: "calc(100vh - 70px)",
        overflow: "auto",
        margin: "20px 0 0 0",
      }}
    >
      {functionalityLaunchers(props.plugin)}
    </List>
  );
};
FunctionalityLauncherList.defaultProps = {
  language: "German",
};
