import { InvalidatedProject } from "typescript";
import Project from "../models/Project.model";
import { db } from "./indexedDb";
import { Country } from "../assets/constants";

export async function getEstimatedQuota() {
  if (navigator.storage && navigator.storage.estimate) {
    const estimation = await navigator.storage.estimate();
    if (estimation && estimation.usage && estimation.quota) {
      const usageInMib = Math.round(estimation.usage / (1024 * 1024));
      const quotaInMib = Math.round(estimation.quota / (1024 * 1024));
      return Math.round((usageInMib / quotaInMib) * 100);
    }
  }
}

async function showEstimatedQuota() {
  if (navigator.storage && navigator.storage.estimate) {
    const estimation = await navigator.storage.estimate();
    if (estimation && estimation.usage && estimation.quota) {
      const usageInMib = Math.round(estimation.usage / (1024 * 1024));
      const quotaInMib = Math.round(estimation.quota / (1024 * 1024));
    }
  } else {
    console.error("StorageManager not found");
  }
}

export async function saveNewProject(project: Project) {
  showEstimatedQuota();
  await db.transaction("rw", [db.projectsList], async () => {
    localStorage.setItem("CURRENT_PROJECT", JSON.stringify(project));
    let tmp: any = await getProject(project);
    if (tmp) {
      db.projectsList.update(tmp.id, project);
    } else {
      return await db.projectsList.add(project);
    }
  });
  return project;
}

export async function getSavedProjects() {
  showEstimatedQuota();

  let projectsList = await db.transaction("r", [db.projectsList], async () => {
    let projectsList = await db.projectsList.toArray();
    return projectsList;
  });
  return projectsList;
}

export async function deleteProject(projectToDelete: Project) {
  showEstimatedQuota();

  await db.transaction("rw", [db.projectsList], async () => {
    let tmp: any = await getProject(projectToDelete);
    if (tmp) {
      await db.projectsList.delete(tmp.id);
    }
  });
  return await getSavedProjects();
}

export async function getProject(project: Project) {
  showEstimatedQuota();

  let projectToReturn: Project | null = null;
  await db.transaction("r", [db.projectsList], async () => {
    await db.projectsList.each((projectTmp) => {
      if (project.name === projectTmp.name) {
        projectToReturn = projectTmp;
      }
    });
  });
  return projectToReturn;
}

export async function updateProjectProperty(
  projectToUpdate: Project,
  key: any,
  value: any
) {
  showEstimatedQuota();

  await db.transaction("rw", [db.projectsList], async () => {
    let tmp: any = await getProject(projectToUpdate);
    if (tmp) {
      await db.projectsList.update(tmp.id, { [key]: value });
    }
  });

  return await getSavedProjects();
}
