import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import DimensionsRoot from "./Components/root";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "4c002a66-3130-4053-a976-249a1d0a1488";

export const dimensionsTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    500,
    170,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="dimensions"
    >
      <DimensionsRoot />
    </ParentRoot>
  );
};

export const DimensionRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="dimensions"
    >
      <DimensionsRoot />
    </ParentRoot>
  );
};

export const Dimensions: Functionality = {
  Name: {
    French: "Cotation",
    English: "Quotation",
    German: "Cotation",
    Italian: "Cotation",
    Indonesian: "Cotation",
  },
  Trigger: dimensionsTrigger,
  ShortDesc: {
    French: "Côtes de calepinage",
    English: "Layout ribs",
    German: "Côtes de calepinage",
    Italian: "Côtes de calepinage",
    Indonesian: "Côtes de calepinage",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Cotation.png",
  RootComponent,
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
