import Excel from "exceljs";
import _ from "lodash";
import { MyConfig } from "../../../../Helper";
import { wait } from "../../../../RevitJS/Helpers";
import {
  Binding,
  Category,
  ID,
  ParamGroup,
  ParamQuery,
} from "../../../../RevitJS/Types/RevitTypes";

export const getExcel = async (excelName: string, cng:any) => {
  return await fetch(
    cng.REACT_APP_SERVERURL + "/Excel/PamCanalisation/" + excelName
  ).then((response) => {
    return response.arrayBuffer();
  });
};

export const getMapping = async (files: { file: File; data: any }[], cng:any) => {
  const workbook = new Excel.Workbook();
  let blob = await getExcel("Mapping.xlsx", cng);
  let families: {
    market: string;
    application: string;
    range: string;
    junction1: string;
    fileNames?: string[];
  }[] = [];
  let wkbk = await workbook.xlsx.load(blob);
  let datasheet = wkbk.getWorksheet("ENGLISH");
  files.forEach((fileObject: { file: File; data: any }) => {
    datasheet.eachRow({ includeEmpty: false }, (row, index) => {
      if (index > 0) {
        let currentCell = row.getCell(`AB`);
        if (currentCell.value && currentCell.value !== "") {
          if (currentCell.value.toString().includes(fileObject.file.name)) {
            let tmpMarket = row.getCell(`O`).value?.toString();
            let tmpApplication = row.getCell(`P`).value?.toString();
            let tmpRange = row.getCell(`Q`).value?.toString();
            let tmpJunction1 = row.getCell(`T`).value?.toString();

            let tmpFamily = {
              market: tmpMarket ? tmpMarket : "",
              application: tmpApplication ? tmpApplication : "",
              range: tmpRange ? tmpRange : "",
              junction1: tmpJunction1 ? tmpJunction1 : "",
              fileNames: [fileObject.file.name],
            };
            families.push(tmpFamily);
          }
        }
      }
    });
  });

  // const buf = await wkbk.xlsx.writeBuffer();
  // saveAs(new Blob([buf]), `${fileName}.xlsx`);
  return families;
};

export const mapCsv = async (
  files: { file: File; data: any }[],
  families: {
    market: string;
    application: string;
    range: string;
    junction1: string;
  }[]
) => {
  const workbook = new Excel.Workbook();
  const cng = await MyConfig();
  let blob = await getExcel("Mapping.xlsx", cng);
  let wkbk = await workbook.xlsx.load(blob);
  let datasheet = wkbk.getWorksheet("ENGLISH");
  files.forEach((fileObject: { file: File; data: any }) => {
    datasheet.eachRow((row) => {
      let currentFileName = row.getCell(`AB`)?.value;
      if (currentFileName && currentFileName !== "") {
        if (fileObject.file.name === currentFileName) {
        }
      }
    });
  });
};

export const transformCsvToRfa = async (
  fileName: string,
  params: { paramGroup: ParamGroup; param: ParamQuery }[]
) => {
  await loadCsvToRfa(fileName, params);
};
//ElementParamSetter[]
export const loadCsvToRfa = async (
  fileName: string,
  params: { paramGroup: ParamGroup; param: ParamQuery }[]
): Promise<ID[]> => {
  if (!window.revit) {
    const setParam = async (setters: any[]) => {
      return setters.map((setter) =>
        Math.ceil(Math.random() * 1000000).toString()
      );
    };
    return setParam(params);
  }

  localStorage.setItem("setParams", "true");
  localStorage.setItem("setParamsResult", "null");
  let processing = true;
  window.revit.InjectCsvAndParams(fileName, JSON.stringify(params));
  while (processing) {
    await wait(50);
    processing = JSON.parse(localStorage.getItem("setParams") as string);
  }
  return JSON.parse(localStorage.getItem("setParamsResult") as string);
};

export default "";
