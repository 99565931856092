import { setFiltersOptions } from "../../../../RevitJS/Helpers";
import {
    fetchPlacoDataAction,
    applyFiltersAction,
    resetFiltersAction,
} from "../Actions/types";
import { placoFilters } from "../Resources";
import { FetchPlacoDataActionType, SolutionFilterType } from "../Types";

type filtersAction =
    | fetchPlacoDataAction
    | applyFiltersAction
    | resetFiltersAction
    | FetchPlacoDataActionType;

export const filters = (
    filters: SolutionFilterType = placoFilters,
    action: filtersAction
): SolutionFilterType => {
    switch (action.type) {
        case "FETCH_PLACO_DATA":
            return {
                liningWalls: action.liningWallsDetails.filters,
                partitionWalls: action.partitionsDetails.filters,
                ceilings: action.ceilingsDetails.filters,
                protectionFeu: action.protectionFeuDetails.filters,
                gainesTech: action.gainesTechDetails.filters,
                conduitGaines: action.conduitGainesDetails.filters,
            };
        case "SAVE_PLACO_FILTER_PRODUCTS":
            return action.filters;
        case "APPLY_FILTERS":
            return action.filters;
        case "RESET_FILTERS":
            return action.filters;
        default:
            return filters;
    }
};
