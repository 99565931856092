import { AxiosInstance } from "axios";
import axios from "axios";
import {
  ProductMeta,
  ProductsByType,
  ProductDetailData,
} from "../../../../RevitJS/Types/BddTypes";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import { SystemDetails } from "../../../../Components/ProductsBrowser/SystemDetails";
import _ from "lodash";
import {
  setFiltersAid,
  addFilterFields,
  setFiltersOptions,
} from "../../../../RevitJS/Helpers";
import { getSystemsDetailsAndFilters } from "../Helpers";
import { PlacoSystem } from "../Actions/objectTypes";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";

export const getBrandByCode = (bdd: any, brandCode: string) =>
  bdd("/objects/class/Brand/locale/en", { text: brandCode });
export const getPlacoProduts = (bdd: any, brandOid: string) =>
  bdd("/objects/class/SGObject/locale/en", {
    dependencies: [
      {
        className: "Brand",
        oids: [brandOid],
      },
    ],
    types: [],
    principal: true,
  });

export const fetchPlacoData = async (
  bdd: AxiosInstance
): Promise<ProductMeta[]> => {
  let placoBrand = await getBrandByCode(bdd, "PLACO ");
  let placoBrandOid = placoBrand.data.objects[0].oid;

  const products: ProductMeta[] = await getPlacoProduts(
    bdd,
    placoBrandOid
  ).then(function (response: any) {
    return response.data.objects;
  });
  return products;
};

export const sortPlacoData = (products: ProductMeta[]): ProductsByType => {
  let partitions: ProductMeta[] = [],
    liningWalls: ProductMeta[] = [],
    complexes: ProductMeta[] = [],
    ceilings: ProductMeta[] = [];
  products.forEach((product) => {
    if (product.longName.startsWith("Cloison")) {
      partitions.push(product);
    } else if (product.longName.startsWith("Doublage")) {
      liningWalls.push(product);
    } else if (product.longName.startsWith("Complexes")) {
      complexes.push(product);
    } else if (product.longName.startsWith("Plafond")) {
      ceilings.push(product);
    }
  });

  return {
    partitions,
    liningWalls,
    complexes,
    ceilings,
  };
};

export const sortPlacoDataByGFRWorksName = async (
  data: {
    objects: ProductDetailData[];
  },
  filters: {
    liningWalls: any[];
    partitionWalls: any[];
    ceilings: any[];
    protectionFeu: any[];
    gainesTech: any[];
    conduitGaines: any[];
  },
  systems: ProductMeta[],
  bdd: AxiosInstance
): Promise<{
  partitions: ProductMeta[];
  liningWalls: ProductMeta[];
  complexes: ProductMeta[];
  ceilings: ProductMeta[];
  partitionsDetails: PlacoSystem;
  liningWallsDetails: PlacoSystem;
  ceilingsDetails: PlacoSystem;
  protectionFeuDetails: PlacoSystem;
  protectionFeu: ProductMeta[];
  gainesTechDetails: PlacoSystem;
  gainesTech: ProductMeta[];
  conduitGainesDetails: PlacoSystem;
  conduitGaines: ProductMeta[];
}> => {
  let partitionsDet: ProductDetailData[] = [],
    liningWallsDet: ProductDetailData[] = [],
    complexesDet: ProductDetailData[] = [],
    ceilingsDet: ProductDetailData[] = [],
    partitions: ProductMeta[] = [],
    liningWalls: ProductMeta[] = [],
    complexes: ProductMeta[] = [],
    ceilings: ProductMeta[] = [],
    protectionFeuDet: ProductDetailData[] = [],
    protectionFeu: ProductMeta[] = [],
    gainesTechDet: ProductDetailData[] = [],
    gainesTech: ProductMeta[] = [],
    conduitGainesDet: ProductDetailData[] = [],
    conduitGaines: ProductMeta[] = [];

  data.objects.forEach((productDetail) => {
    const attributes = productDetail.attributes;
    const gfrWorksNameObject = _.find(attributes, {
      technicalName: "GFR-Works name",
    });
    const product = _.find(systems, { oid: productDetail.oid });
    if (gfrWorksNameObject) {
      const valueObject: any = _.get(gfrWorksNameObject, "values");
      if (valueObject[0].value === "Cloisons") {
        partitionsDet.push(productDetail);
        product && partitions.push(product);
      } else if (valueObject[0].value === "Isolation des murs") {
        liningWallsDet.push(productDetail);
        product && liningWalls.push(product);
      } else if (valueObject[0].value === "Complexes") {
        complexesDet.push(productDetail);
        product && complexes.push(product);
      } else if (
        valueObject[0].value === "Plafonds décoratifs" ||
        valueObject[0].value === "Plafonds"
      ) {
        ceilingsDet.push(productDetail);
        product && ceilings.push(product);
      } else if (valueObject[0].value === "Protection feu") {
        protectionFeuDet.push(productDetail);
        product && protectionFeu.push(product);
      } else if (valueObject[0].value === "Conduit gaines") {
        conduitGainesDet.push(productDetail);
        product && conduitGaines.push(product);
      } else if (valueObject[0].value === "Gaines techniques") {
        gainesTechDet.push(productDetail);
        product && gainesTech.push(product);
      }
    }
  });

  let partitionsDetails = await getSystemsDetailsAndFilters(
    partitions,
    partitionsDet,
    filters.partitionWalls
  );
  let liningWallsDetails = await getSystemsDetailsAndFilters(
    liningWalls,
    liningWallsDet,
    filters.liningWalls
  );
  let ceilingsDetails = await getSystemsDetailsAndFilters(
    ceilings,
    ceilingsDet,
    filters.ceilings
  );
  const protectionFeuDetails = await getSystemsDetailsAndFilters(
    protectionFeu,
    protectionFeuDet,
    filters.protectionFeu
  );
  const gainesTechDetails = await getSystemsDetailsAndFilters(
    gainesTech,
    gainesTechDet,
    filters.gainesTech
  );
  const conduitGainesDetails = await getSystemsDetailsAndFilters(
    conduitGaines,
    conduitGainesDet,
    filters.conduitGaines
  );

  return {
    partitions,
    liningWalls,
    complexes,
    ceilings,
    partitionsDetails,
    liningWallsDetails,
    ceilingsDetails,
    protectionFeuDetails,
    protectionFeu,
    gainesTechDetails,
    gainesTech,
    conduitGainesDetails,
    conduitGaines,
  };
};

export const getPlacoSystemsDetails = (bdd: any, systemOid: ID[]) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid);

const split_array = (arr: any[], chunk = 400): any[][] => {
  let res = [];
  var i, j, temparray;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray = arr.slice(i, i + chunk);
    res.push(temparray);
  }
  return res;
};

export const getSystemDetailsById = async (
  ids: ID[],
  bdd: AxiosInstance
): Promise<{ data: { objects: ProductDetailData[] } }> => {
  let ids_container: string[][] = split_array(ids);

  let systemDetailsRequests: any = [];

  await asyncForEach(ids_container, async (ids: string[], index: number) => {
    let responseSystemDetail = await getPlacoSystemsDetails(bdd, ids);
    if (responseSystemDetail) {
      systemDetailsRequests = _.concat(
        systemDetailsRequests,
        responseSystemDetail
      );
    }
  });

  let systemDetailsRequest: { data: { objects: any[] } } = {
    data: {
      objects: [],
    },
  };

  for (let i = 0; i < systemDetailsRequests.length; i++) {
    // eslint-disable-next-line no-loop-func
    let withContextSystem = _.map(
      systemDetailsRequests[i].data.objects,
      (o: any) => {
        return {
          ...o,
          context:
            systemDetailsRequests[i].data.context.attributeDependencies[0]
              .attributes,
        };
      }
    );

    systemDetailsRequest.data.objects =
      systemDetailsRequest.data.objects.concat(withContextSystem);
  }

  return systemDetailsRequest;
};

export const getPlacoSystemDetailsWithChildren = (bdd: any, systemOid: ID) =>
  bdd(
    "/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true",
    [systemOid]
  );

export const fetchFiles = function (path: any, fileObj: any) {
  return path.get(
    "/file/" +
      fileObj.descriptorId +
      "?filename=" +
      fileObj.fileName +
      "." +
      fileObj.fileExtension
  );
};

async function asyncForEach(array: any, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
