import React, { useEffect, useState } from "react";
import { TopHeader, SubHeader } from "../../Components/Headers";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { MyConfig } from "../../../../Helper";
import { api } from "../../../../RevitJS/API";
import { Oval } from "react-loader-spinner";
import { Container, Button, Loader, Dimmer, Modal } from "semantic-ui-react";
import { scheduleHandler } from "../Export";
interface Props {
  route: string;
  setRoute: any;
}

export const SelectionLanding = ({ route, setRoute }: Props) => {
  useEffect(() => {
    api.windowsHandler.resizeWindow(600, 200);
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isDownloadSuccessful, setIsDownloadSuccessful] = useState<boolean>(false);

  const setProcessingValue = () => {
    setProcessing(false);
  };

  const groupSelectionHandler = () => {
    setRoute("GROUP_SELECTION");
  };
  const manualSelectionHandler = async () => {
    setLoading(true);
    api.windowsHandler.hideWindow();
    let tree = await new Promise((resolve, reject) =>
      window.indec.manualSelection(
        { isSingleSelection: false, category: "wall" },
        resolve,
        reject
      )
    )
      .then((x: any) => {
        return JSON.parse(x);
      })
      .catch((ex: any) => {
        console.error(ex);
        return { Tree: [] };
      });
    //await api.selection.manualSelection("wall");
    if (tree.Tree.length > 0) {
      await api.windowsHandler.showWindow();
      setProcessing(true);

      await scheduleHandler(tree, setIsDownloadSuccessful);
    }
    await api.windowsHandler.showWindow();
    setLoading(false);
  };
  if (processing) {
    return (
      <div>
        <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
          <Loader content={"Scehdules Pending"}></Loader>
        </Dimmer>
        <Modal
          size="tiny"
          open={isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>Information - Métrés</h3>
            <p>Génération de fichier de métrés terminée avec succès.</p>
            <Button primary onClick={() => {
                setIsDownloadSuccessful(false);
                api.windowsHandler.closeWindow();
              }
            }>
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }
  return (
    <div>
      <TopHeader name={"Schedules"} Icon="" />
      <Container textAlign="center" style={{ marginTop: 20 }}>
        <Button
          onClick={manualSelectionHandler}
          primary
          size="big"
          style={{ marginRight: "5%" }}
        >
          Manual Selection
          {loading === true ? (
            <div
              style={{
                float: "right",
                marginLeft: "5px",
              }}
            >
              <Oval
                color="#00BFFF" //3 secs
                height={16}
                width={16}
              />{" "}
            </div>
          ) : (
            <div></div>
          )}
        </Button>
        <Button
          onClick={groupSelectionHandler}
          primary
          size="big"
          style={{ marginLeft: "5%" }}
        >
          Group Selection
          {/* {this.state.loading === true ? <div style={{
              float: 'right',
              marginLeft: '5px'
            }}><LoaderSpinner
                type="Oval"
                color="#00BFFF" //3 secs
                height={16}
                width={16}
              /> </div> : <div></div>} */}
        </Button>
      </Container>

      {/* <Modal
          size="tiny"
          // open={this.state.isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>Information - Métrés</h3>
            <p>Génération de fichier de métrés terminée avec succès.</p>
            <Button
              primary
              onClick={() => {
                
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal> */}
    </div>
  );
};
