import React, { useState, useEffect, Component } from "react";
import { AppBodyformat } from "./AppBodyformat";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";
export enum Routes {
  ROOT = "Waiter",
}
type Props = InjectedCounterProps;

interface State {
  route: Routes;
}

const DimensionsRoot = (props: any) => {
  const [route, setRoute] = useState(Routes.ROOT);

  useEffect(() => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  }, []);

  return (
    <React.Fragment>
      <AppBodyformat route={route} onError={props.onError} />
    </React.Fragment>
  );
};

export default withErrorSubscription(DimensionsRoot);
