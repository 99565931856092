import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "10daf824-7d86-4edd-a6e3-cada7fd187c7";

export const supprimerTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const FacadeSupprimerRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const Supprimer: Functionality = {
  Name: {
    English: "Supprimer",
    Indonesian: "Supprimer",
  },
  Trigger: supprimerTrigger,
  ShortDesc: {
    English: "Effacer le calepinage",
    Indonesian: "Effacer le calepinage",
  },
  Id: functionalityId,
  Icon: "/Facade/supprimer.png",
  RootComponent,
  ParentId: "0",
};
