import { applyMiddleware, createStore, Store } from 'redux'
import thunk from 'redux-thunk'
import { reducers } from './reducers'

export default function configureStore(): any {

  let middleware = applyMiddleware(thunk)

  const store = createStore(reducers, middleware) as Store

  return store
}

