import { ThunkAction } from "redux-thunk";
import { FacadeStore } from "../Reducers";
import { InitLanguageAction, INIT_LANGUAGE } from "../Types";

export * from "./langAction";
export * from "./bufferAction";
export * from "./moduleAction";

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const InitLanguage = (): any => async (dispatch: any) => {
  const setRevitLanguage = async () => {
    let revitLanguage = "French" as Language;
    localStorage.setItem("savedLanguage", revitLanguage);
    dispatch({
      type: INIT_LANGUAGE,
      language: revitLanguage,
    });
  };
  let savedLanguage: any = localStorage.getItem("savedLanguage");
  if (savedLanguage) {
    if (isLanguage(savedLanguage)) {
      dispatch({
        type: INIT_LANGUAGE,
        language: savedLanguage,
      });
    } else {
      setRevitLanguage();
    }
  } else {
    setRevitLanguage();
  }
};
