import React from "react";
import { pluginId } from "../..";
import ParentRoot from "../../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../../RevitJS/API";
import { Functionality } from "../../../../RevitJS/Types/StoreTypes";
import { Root } from "../../../PlacoBIMv5/LoadSystemContainer/components/root";

export const functionalityId = "8d3e7ce8-0051-4b6f-a391-010fc400e6a4";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Library_Load",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot pluginId={pluginId} functionalityId={functionalityId} module="">
      <Root openedFrom={"PLACOBIM"} />
    </ParentRoot>
  );
};

export const BiblioLoadSystemRootComponent = () => {
  return (
    <ParentRoot pluginId={pluginId} functionalityId={functionalityId} module="">
      <Root openedFrom={"PLACOBIM"} />
    </ParentRoot>
  );
};

export const LoadSystemContainer: Functionality = {
  Name: {
    French: "Charger",
    English: "Load",
    German: "Charger",
    Italian: "Charger",
    Indonesian: "Charger",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Charger des systèmes",
    English: "Load systems into the project",
    German: "Charger des systèmes",
    Italian: "Charger des systèmes",
    Indonesian: "Charger des systèmes",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Charger.png",
  RootComponent,
  ParentId: "98fe05e3-266c-4671-8821-c9887747f678",
};
