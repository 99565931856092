import { parseRigipsSystem } from "../..";
import { MappingRowRigips } from "../../../../../../RevitJS/Types/StoreTypes";
import {
  A11LayersParser,
  AyLayersParser,
} from "../../LayersParsers/a1LayersParser";

export const a3Mapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = A11LayersParser(parsedSystem.layersDesc);
    parsedSystem.E1 =
      AlbaStructure[0]?.thickness || AlbaStructure[2]?.thickness;
    parsedSystem.E1_Name = "Alba";
    parsedSystem.I1 = AlbaStructure[2]?.thickness;
    parsedSystem.I1_Name = "Ghost";
    parsedSystem.plateWidth = 10e9;
    parsedSystem.AlbaStructure = AlbaStructure;
    parsedSystem.Lining = true;
  }
  return parsedSystem;
};

export const a3yMapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = AyLayersParser(parsedSystem.layersDesc);
    // parsedSystem.E1 =
    //   AlbaStructure[0]?.thickness || AlbaStructure[2]?.thickness;
    // parsedSystem.E1_Name = "Alba";
    // parsedSystem.I1 = AlbaStructure[2]?.thickness;
    // parsedSystem.I1_Name = "Ghost";
    // parsedSystem.plateWidth = 10e9;
    // parsedSystem.AlbaStructure = AlbaStructure;
    // parsedSystem.Lining = true;
  }
  return parsedSystem;
};
