import _ from "lodash";
import { api } from "../../../../RevitJS/API";
import { groupByArray } from "../../../../RevitJS/Helpers";
import {
  Category,
  ID,
  ParamGroup,
  ColorRGB,
  ViewDetail,
  ElementParamSetter,
} from "../../../../RevitJS/Types/RevitTypes";
import {
  CouplingQualityTypes,
  ElementType,
  MainMaterialTypes,
  USER_ACCEPTED_GENERAL_TERMS_OF_USE,
  USER_SKIP_INTRO,
} from "../assets/constants";
import CouplingFamilyType, { CoupligGasketMaterial } from "../models/Coupling";
import Element from "../models/Element.model";
import Item from "../models/Item.model";
import {
  ASSETS_ATTR_TECHNICAL_NAME,
  ASSETS_LOCAL_URL_SUB_ATTR_TECHNICAL_NAME,
  FAMILY_TYPE_ATTR_TECHNICAL_NAME,
  GASKET_MATERIAL_ATTR_TECHNICAL_NAME,
  MAIN_MATERIAL_TYPE_ATTR_TECHNICAL_NAME,
  NAME_ATTR_TECHNICAL_NAME,
  RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME,
  RAW_PRODUCT_PIM_ID_ATTR_TECHNICAL_NAME,
  SHORT_DESC_ATTR_TECHNICAL_NAME,
  RECOMMENDATION_LEVEL_ATTR_TECHNICAL_NAME,
  DATA_INJECTION_OPTIONS,
} from "../services/constants.config";

export const checkAcceptedGTOU = () => {
  return (
    localStorage.getItem(USER_ACCEPTED_GENERAL_TERMS_OF_USE) &&
    localStorage.getItem(USER_ACCEPTED_GENERAL_TERMS_OF_USE) === "true"
  );
};

export const checkSkipIntro = () => {
  return (
    localStorage.getItem(USER_SKIP_INTRO) &&
    localStorage.getItem(USER_SKIP_INTRO) === "true"
  );
};

export const createFiltersFromElements = (elementsList: any[]) => {
  let filters: any = {
    pipes: {},
    fittings: {},
    accessories: {},
  };

  const treatDiameter = (element: any) => {
    let dn: any = {};
    if (element.Diameter !== undefined && element.Diameter !== null) {
      dn = {
        diameter: element.Diameter,
        selected: false,
        nbrElements: 1,
      };
      return dn;
    }
  };

  const treatAngle = (element: any) => {
    let category: any = {};
    let angle: string;
    if (element.Angle !== undefined && element.Angle !== null) {
      angle = `${element.Angle}`;
    } else {
      angle = "noAngle";
    }
    category[angle] = [];
    category[angle].push(treatDiameter(element));
    return category;
  };

  const addToFilters = (filter: any, tmp: any, element: any) => {
    let found = false;
    Object.keys(filters[filter]).forEach((key) => {
      Object.keys(tmp).forEach((key2) => {
        if (key2 === key) {
          found = true;
          if (
            filters[filter][key].find((e: any) => {
              if (e.diameter === tmp[key2][0].diameter) {
                e.nbrElements++;
                return true;
              } else {
                return false;
              }
            }) === undefined
          ) {
            filters[filter][key].push(...tmp[key2]);
          }
        }
      });
    });
    if (!found) {
      filters[filter] = {
        ...filters[filter],
        ...treatAngle(element),
      };
    }
  };

  elementsList.forEach((element: any) => {
    switch (element.Type) {
      case ElementType.PipeAccessory:
        // les crochets ici servent a limiter le scope du let dans la ligne "let tmp = ..."
        {
          let tmp = treatAngle(element);
          addToFilters("accessories", tmp, element);
        }
        break;
      case ElementType.PipeCurves:
        {
          let tmp = treatAngle(element);
          addToFilters("pipes", tmp, element);
        }
        break;
      case ElementType.PipeFitting:
        {
          let tmp = treatAngle(element);
          addToFilters("fittings", tmp, element);
        }
        break;
    }
  });
  return filters;
};

export const getImageAssetUrlAttributeFromRange = (range: any) => {
  const attr = range.attributes.find((attr: any) => {
    return attr.technicalName === ASSETS_ATTR_TECHNICAL_NAME;
  });

  const subAttr = attr.subAttributes.find((subAttr: any) => {
    return subAttr.technicalName === ASSETS_LOCAL_URL_SUB_ATTR_TECHNICAL_NAME;
  });

  return subAttr.values[0].value;
};

export const getAttributeFromObject = (
  object: any,
  attributeName: string,
  attributeTextSearch: boolean = false
) => {
  if (object) {
    if (!attributeTextSearch) {
      const attr = object.attributes?.find((attr: any) => {
        return attr.technicalName === attributeName;
      });
      if (attr) {
        return attr?.values[0]?.unit
          ? `${attr?.values[0]?.value} ${attr?.values[0]?.unit}`
          : attr?.values[0]?.value;
      }
      return null;
    } else {
      let attrs: any[] = [];
      attrs.push(
        ...object.attributes.filter((attr: any) => {
          return attr.technicalName.includes(attributeName);
        })
      );
      if (attrs.length > 0) {
        attrs = attrs.map((attr) => {
          return {
            rangeName: attr.technicalName.replace(attributeName, "").trim(),
            value: attr.values[0]?.value,
          };
        });
        return attrs;
      }
      return null;
    }
  } else {
    return null;
  }
};

export const getNameAttributeFromRange = (range: any) => {
  return getAttributeFromObject(range, NAME_ATTR_TECHNICAL_NAME);
};

export const getShortDescAttributeFromRange = (range: any) => {
  return getAttributeFromObject(range, SHORT_DESC_ATTR_TECHNICAL_NAME);
};

export const sanitizeSubCategories = (subCategory: any) => {
  let classifiedSubCategory: any = {
    pipes: [],
    fittings: [],
    accessories: [],
  };
  subCategory.data.objects.forEach((rangeSubCategory: any) => {
    if (
      rangeSubCategory.technicalName
        .toLowerCase()
        .includes("Pipe".toLowerCase())
    ) {
      classifiedSubCategory.pipes.push(rangeSubCategory.oid);
    } else if (
      rangeSubCategory.technicalName
        .toLowerCase()
        .includes("Fitting".toLowerCase())
    ) {
      classifiedSubCategory.fittings.push(rangeSubCategory.oid);
    } else if (
      rangeSubCategory.technicalName
        .toLowerCase()
        .includes("Accessorie".toLowerCase())
    ) {
      classifiedSubCategory.fittings.push(rangeSubCategory.oid);
    }
  });
  return classifiedSubCategory;
};

const fittingVerificationFunction = (
  element: Element,
  rawProductPimId: string | number
) => {
  if (
    element.PimId &&
    element.PimId.toString().toLowerCase() ===
      rawProductPimId.toString().toLowerCase()
  ) {
    return true;
  }
  return false;
};

const pipeVerificationFunction = (element: Element, rawProductName: string) => {
  let elementComment: string = "";
  if (
    element.Comment &&
    (element.Comment.toLowerCase() === "UU type".toLowerCase() ||
      element.Comment.toLowerCase() === "Type UU".toLowerCase())
  ) {
    elementComment = "uu pipe";
  } else if (
    element.Comment &&
    (element.Comment.toLowerCase() === "UE type".toLowerCase() ||
      element.Comment.toLowerCase() === "Type EU".toLowerCase())
  ) {
    elementComment = "ue pipe";
  }

  const elementDiameter: string | number = element.Diameter;

  if (
    rawProductName.toLowerCase().includes(elementComment) &&
    (rawProductName.includes(`DN${elementDiameter} `) ||
      rawProductName.includes(`DN${elementDiameter}/`))
  ) {
    return true;
  } else {
    return false;
  }
};

export const findCompatibleProductsForSpecificRange = (
  element: Element,
  rangeProducts: any,
  attributeName: string,
  verificationfunction: Function
) => {
  let found = false;
  rangeProducts.forEach((rangeProduct: any) => {
    const productSpecificAttr = rangeProduct.attributes.find((attr: any) => {
      if (attr.technicalName === attributeName) {
        return true;
      }
      return false;
    });

    if (
      productSpecificAttr &&
      productSpecificAttr.values &&
      productSpecificAttr.values[0] &&
      productSpecificAttr.values[0].value
    ) {
      const rawProductName: string = productSpecificAttr.values[0].value;

      if (verificationfunction(element, rawProductName)) {
        found = true;
        element.pimObject = rangeProduct;
      }
    }
  });
  return found;
};

export const findCompatibleProductsForAllRanges = (
  rangeProducts: any,
  allAvailableElements: Element[]
) => {
  let looseElements: Element[] = [];
  let compatibleElements = allAvailableElements.filter((element) => {
    let found = false;
    if (element.Type === ElementType.PipeCurves) {
      found = findCompatibleProductsForSpecificRange(
        element,
        rangeProducts.pipes,
        RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME,
        pipeVerificationFunction
      );
    } else if (
      // in PIM DB, accessories are listed under Fittings subCategory,
      // so they get same treatement for reconsiliation,
      // but to classify in filterComponents, the element type from Revit separates them
      element.Type === ElementType.PipeFitting ||
      element.Type === ElementType.PipeAccessory
    ) {
      found = findCompatibleProductsForSpecificRange(
        element,
        rangeProducts.fittings,
        RAW_PRODUCT_PIM_ID_ATTR_TECHNICAL_NAME,
        fittingVerificationFunction
      );
    }
    if (!found) {
      looseElements.push(element);
    }
    return found;
  });
  return { compatibleElements, looseElements };
};

export const setCurrentItemAndSanitizeAvailableElements = (
  currentItem: Item,
  tmpItems: Item[]
) => {
  let selectedElements: Element[] = [];
  currentItem.current = false;
  currentItem.compatibleAndAvailableElements.forEach((element: Element) => {
    if (element.selected) {
      selectedElements.push(_.cloneDeep(element));
    }
    let tmp = currentItem?.allCompatibleElements.find((tmpElement: Element) => {
      return element.Id === tmpElement.Id;
    });

    if (tmp) {
      tmp.selected = element.selected;
    }
  });

  let index = -1;
  tmpItems.forEach((item, currentItemIndex) => {
    if (item.range.technicalName === currentItem?.range.technicalName) {
      index = currentItemIndex;
    }
  });

  if (tmpItems && index > -1) {
    tmpItems[index] = _.cloneDeep(currentItem);
    tmpItems[index].current = false;
  }

  tmpItems = tmpItems.map((item, itemIndex): Item => {
    if (item.range.technicalName !== currentItem?.range.technicalName) {
      let allOthersSelectedElements: Element[] = [];
      tmpItems.forEach((item2: Item, tmpItemIndex: number) => {
        if (itemIndex !== tmpItemIndex) {
          item2.allCompatibleElements.forEach((element) => {
            if (element.selected) {
              allOthersSelectedElements.push(_.cloneDeep(element));
            }
          });
        }
      });
      item.compatibleAndAvailableElements = _.cloneDeep(
        item.allCompatibleElements
      ).filter((element) => {
        let alreadySelected = false;
        allOthersSelectedElements.forEach((selectedElement) => {
          if (selectedElement.Id === element.Id) {
            alreadySelected = true;
          }
        });
        return !alreadySelected;
      });
      item.compatibleAndAvailableElementsSanitized = [];
      _.cloneDeep(item.compatibleAndAvailableElements).forEach(
        (element: Element) => {
          let found = item.compatibleAndAvailableElementsSanitized.some(
            (elementSanitized: Element) => {
              return (
                getElementName(element) === getElementName(elementSanitized)
              );
            }
          );
          if (!found) {
            item.compatibleAndAvailableElementsSanitized.push(element);
          }
        }
      );
    }
    return item;
  });

  return tmpItems;
};

const getCouplingDiameter = (couplingRawProductName: string) => {
  let re = /(?:^|\W)DN(\w+)(?!\w)/g,
    match,
    matches = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = re.exec(couplingRawProductName))) {
    matches.push(match[1]);
  }
  return matches[0];
};

const translateMainMaterialTypeToQuality = (
  mainMaterialType: string
): string => {
  switch (mainMaterialType) {
    case MainMaterialTypes.STEEL_W2:
    case MainMaterialTypes.ACIER_W2:
      return CouplingQualityTypes.STANDARD;
    case MainMaterialTypes.STEEL_W4:
    case MainMaterialTypes.ACIER_W4:
    case MainMaterialTypes.STEEL_W5:
    case MainMaterialTypes.ACIER_W5:
      return CouplingQualityTypes.INOX;
    default:
      return mainMaterialType;
  }
};

export const sanitizeCouplings = (couplingsList: any[]) => {
  let sanitizedCouplingsList: CouplingFamilyType[] = [];

  couplingsList.forEach((coupling) => {
    let familyFound = false;
    let tmpFamily = getAttributeFromObject(
      coupling,
      FAMILY_TYPE_ATTR_TECHNICAL_NAME
    )
      ?.replace(/Coupling/gi, "")
      ?.trim();
    sanitizedCouplingsList.forEach((sanitizedCoupling: CouplingFamilyType) => {
      if (
        tmpFamily &&
        tmpFamily.toLowerCase() === sanitizedCoupling.familyType.toLowerCase()
      ) {
        familyFound = true;
        /////////////////////// QUALITY
        let tmpQuality = translateMainMaterialTypeToQuality(
          getAttributeFromObject(
            coupling,
            MAIN_MATERIAL_TYPE_ATTR_TECHNICAL_NAME
          )
        );
        let qualityFound = false;
        sanitizedCoupling.quality.forEach((quality: any) => {
          if (
            tmpQuality &&
            tmpQuality.toLowerCase() === quality.name.toLowerCase()
          ) {
            qualityFound = true;
          }
        });
        if (!qualityFound) {
          sanitizedCoupling.quality.push({
            name: _.cloneDeep(tmpQuality),
          });
          if (!sanitizedCoupling.selectedQuality) {
            sanitizedCoupling.selectedQuality = _.cloneDeep(tmpQuality);
          }
        }

        /////////////////////// GASKET
        let gasketFound = false;
        let tmpGasketMaterial = getAttributeFromObject(
          coupling,
          GASKET_MATERIAL_ATTR_TECHNICAL_NAME
        );
        sanitizedCoupling.gasketMaterial.forEach(
          (gasketMaterial: CoupligGasketMaterial) => {
            if (
              tmpGasketMaterial &&
              tmpGasketMaterial.toLowerCase() ===
                gasketMaterial.name.toLowerCase()
            ) {
              gasketFound = true;
            }
          }
        );
        if (!gasketFound && tmpGasketMaterial) {
          sanitizedCoupling.gasketMaterial.push({
            name: _.cloneDeep(tmpGasketMaterial),
          });
          if (!sanitizedCoupling.selectedGasket) {
            sanitizedCoupling.selectedGasket = _.cloneDeep(tmpGasketMaterial);
          }
        }
        let tmpCoupling: any = {
          dn: getCouplingDiameter(
            getAttributeFromObject(
              coupling,
              RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME
            )
          ),
          oid: coupling.oid,
        };

        tmpCoupling.quality = tmpQuality ? tmpQuality : undefined;
        tmpCoupling.gasketMaterial = tmpGasketMaterial
          ? tmpGasketMaterial
          : undefined;
        let tmpRecommendations: any[] = getAttributeFromObject(
          coupling,
          RECOMMENDATION_LEVEL_ATTR_TECHNICAL_NAME,
          true
        );
        if (!tmpCoupling.recommendations) {
          tmpCoupling.recommendations = [];
        }
        tmpCoupling.recommendations.push(...tmpRecommendations);
        sanitizedCoupling.couplings.push(tmpCoupling);
      }
    });
    if (!familyFound && tmpFamily) {
      let tmpQuality: string = getAttributeFromObject(
        coupling,
        MAIN_MATERIAL_TYPE_ATTR_TECHNICAL_NAME
      );
      let tmpGasket: string = getAttributeFromObject(
        coupling,
        GASKET_MATERIAL_ATTR_TECHNICAL_NAME
      );
      let tmpTechnicalName: string = getCouplingDiameter(
        getAttributeFromObject(coupling, RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME)
      );
      let newObject: any = {
        familyType: tmpFamily.replace(/Coupling/gi, "").trim(),
      };
      newObject.couplings =
        tmpTechnicalName && coupling.oid
          ? [{ dn: tmpTechnicalName, oid: coupling.oid }]
          : [];
      if (tmpQuality) {
        newObject.quality = [
          {
            name: translateMainMaterialTypeToQuality(_.cloneDeep(tmpQuality)),
          },
        ];
        if (newObject.couplings[0]) {
          newObject.couplings[0].quality = translateMainMaterialTypeToQuality(
            _.cloneDeep(tmpQuality)
          );
          newObject.selectedQuality = translateMainMaterialTypeToQuality(
            _.cloneDeep(tmpQuality)
          );
        }
      } else {
        newObject.quality = [];
        newObject.selectedQuality = undefined;
      }
      if (tmpGasket) {
        newObject.gasketMaterial = [{ name: _.cloneDeep(tmpGasket) }];
        if (newObject.couplings[0]) {
          newObject.couplings[0].gasketMaterial = _.cloneDeep(tmpGasket);
          newObject.selectedGasket = _.cloneDeep(tmpGasket);
        }
      } else {
        newObject.gasketMaterial = [];
        newObject.selectedGasket = undefined;
      }
      let tmpRecommendations: any[] = getAttributeFromObject(
        coupling,
        RECOMMENDATION_LEVEL_ATTR_TECHNICAL_NAME,
        true
      );
      if (newObject.couplings[0]) {
        if (!newObject.couplings[0].recommendations) {
          newObject.couplings[0].recommendations = [];
        }
        newObject.couplings[0].recommendations.push(...tmpRecommendations);
      }

      sanitizedCouplingsList.push(newObject);
    }
  });
  sanitizedCouplingsList.forEach((familyType) => {
    let tmp = null;
    familyType.quality.forEach((quality) => {
      if (quality.name === "Standard") {
        tmp = quality.name;
      }
    });
    if (tmp) {
      familyType.selectedQuality = tmp;
    }
  });
  return sanitizedCouplingsList;
};

export const getValidAttributesToInjectFromElement = (object: any, t: any) => {
  let dataInjectionOptionsKeys = Object.keys(DATA_INJECTION_OPTIONS);
  let validAttributesToInject: any[] = [];
  if (object) {
    dataInjectionOptionsKeys.forEach((optionKey: string) => {
      object.attributes.forEach((attr: any) => {
        if (
          attr.technicalName ===
          DATA_INJECTION_OPTIONS[optionKey].attribute_name
        ) {
          validAttributesToInject.push({
            attrObject: attr,
            revitNameMap: t(optionKey),
            paramGroup: DATA_INJECTION_OPTIONS[optionKey].param_group,
          });
        }
      });
    });
  }
  return validAttributesToInject;
};

export const getParametersToCreateByCategories = (
  elementsToInject: {
    elementObject: any;
    element: any;
    coupling?: any;
  }[],
  t: any
) => {
  let categoriesAndAttributes: {
    categoryName: Category;
    attributes: {
      attrObject: any;
      revitNameMap: string;
      paramGroup: ParamGroup;
    }[];
  }[] = [];
  let validAttributesToInjectCoupling: any[] = [];

  elementsToInject.forEach((elementToInject) => {
    let { element, elementObject: object, coupling } = elementToInject;

    let validAttributesToInject = getValidAttributesToInjectFromElement(
      object,
      t
    );
    let attributesToInjectCoupling: any[] = [];

    if (coupling) {
      attributesToInjectCoupling.push(
        ...getValidAttributesToInjectFromElement(coupling, t)
      );
    }

    attributesToInjectCoupling.forEach((attrCoupling) => {
      let found = false;
      validAttributesToInjectCoupling.forEach((validAttr) => {
        if (
          attrCoupling.attrObject.technicalName ===
          validAttr.attrObject.technicalName
        ) {
          found = true;
        }
      });
      if (!found) {
        validAttributesToInjectCoupling.push(attrCoupling);
      }
    });

    let categoryFound: boolean = false;
    categoriesAndAttributes.forEach((categoryAndAttr) => {
      if (categoryAndAttr.categoryName === element.Type) {
        categoryFound = true;
        validAttributesToInject.forEach((attributeToInject) => {
          let attributeFound = false;
          categoryAndAttr.attributes.forEach(
            (attr: {
              attrObject: any;
              revitNameMap: string;
              paramGroup: ParamGroup;
            }) => {
              if (
                attr.attrObject.technicalName ===
                  attributeToInject.attrObject.technicalName &&
                attr.revitNameMap === t(attributeToInject.revitNameMap)
              ) {
                attributeFound = true;
              }
            }
          );
          if (!attributeFound) {
            categoryAndAttr.attributes.push(attributeToInject);
          }
        });
      }
    });
    if (!categoryFound) {
      if (element.Type) {
        categoriesAndAttributes.push({
          categoryName: element.Type,
          attributes: _.cloneDeep(validAttributesToInject),
        });
      }
    }
  });
  return { categoriesAndAttributes, validAttributesToInjectCoupling };
};

export const createParametersByCategories = async (
  elementsToInject: {
    elementObject: any;
    element: any;
    coupling?: any;
  }[],
  t: any
) => {
  let { categoriesAndAttributes, validAttributesToInjectCoupling } =
    getParametersToCreateByCategories(elementsToInject, t);

  for (let i = 0; i < categoriesAndAttributes.length; i++) {
    let categoryAndAttr = categoriesAndAttributes[i];
    for (let j = 0; j < categoryAndAttr.attributes.length; j++) {
      let attr = categoryAndAttr.attributes[j];
      await api.familyEditor.createParameter(
        categoryAndAttr.categoryName,
        "Instance",
        attr.paramGroup,
        {
          Name: t(attr.revitNameMap),
          Type: "Text",
        }
      );
    }
  }
  for (let i = 0; i < validAttributesToInjectCoupling.length; i++) {
    let validCouplingAttr = validAttributesToInjectCoupling[i];
    await api.familyEditor.createParameter(
      "PipeAccessory",
      "Instance",
      validCouplingAttr.paramGroup,
      {
        Name: t(validCouplingAttr.revitNameMap),
        Type: "Text",
      }
    );
  }
  return Promise.resolve();
};

export const setParameterOnElement = async (
  t: any,
  element: Element,
  object: any,
  coupling: any = null
) => {
  let params: any[] = [];
  let coupling_params: any[] = [];
  let dataInjectionOpetionsKeys = Object.keys(DATA_INJECTION_OPTIONS);
  for (let i = 0; i < dataInjectionOpetionsKeys.length; i++) {
    if (element.Type) {
      let param_value = getAttributeFromObject(
        object,
        DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].attribute_name
      );

      if (param_value) {
        // if (createParameterResult) {
        params.push({
          Name: t(dataInjectionOpetionsKeys[i]),
          Type: "Text",
          Value: param_value,
        });
        // }
      }
    }
  }

  for (let i = 0; i < dataInjectionOpetionsKeys.length; i++) {
    let coupling_param_value = getAttributeFromObject(
      coupling,
      DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].attribute_name
    );
    if (coupling_param_value) {
      // if (createParameterResult) {
      coupling_params.push({
        Name: t(dataInjectionOpetionsKeys[i]),
        Type: "Text",
        Value: coupling_param_value,
      });
      // }
    }
  }

  let couplingIds = await api.selection.getCouplingsIds(`${element.Id}`);

  await api.familyEditor.setParams([
    {
      Id: element.Id,
      Params: params,
    },
  ]);

  return Promise.all(
    couplingIds.map(async (couplingId) => {
      return await api.familyEditor.setParams([
        {
          Id: couplingId,
          Params: coupling_params,
        },
      ]);
    })
  );
};

export const createParameter = async (
  t: any,
  element: Element,
  object: any,
  coupling: any = null
) => {
  let elementAttributeData: any[] = [];
  let couplingAttributeData: any[] = [];

  let paramsList: ElementParamSetter[] = [];
  let params: any[] = [];
  let coupling_params: any[] = [];
  let dataInjectionOpetionsKeys = Object.keys(DATA_INJECTION_OPTIONS);
  for (let i = 0; i < dataInjectionOpetionsKeys.length; i++) {
    if (element.Type) {
      let param_value = getAttributeFromObject(
        object,
        DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].attribute_name
      );

      if (param_value) {
        elementAttributeData.push({
          ElementId: element.Id,
          CategoryName: element.Type,
          TechnicalName:
            DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].attribute_name,
          ParamGroup:
            DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].param_group,
          Label: t(dataInjectionOpetionsKeys[i]),
          Type: "Text",
          Value: param_value,
        });
      }
    }
  }

  for (let i = 0; i < dataInjectionOpetionsKeys.length; i++) {
    let coupling_param_value = getAttributeFromObject(
      coupling,
      DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].attribute_name
    );
    let couplingIds = await api.selection.getCouplingsIds(`${element.Id}`);
    couplingIds.forEach((couplingId) => {
      if (coupling_param_value) {
        couplingAttributeData.push({
          ElementId: element.Id,
          CouplingId: couplingId,
          CategoryName: "PipeAccessory",
          TechnicalName:
            DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].attribute_name,
          ParamGroup:
            DATA_INJECTION_OPTIONS[dataInjectionOpetionsKeys[i]].param_group,
          Label: t(dataInjectionOpetionsKeys[i]),
          Type: "Text",
          Value: coupling_param_value,
        });
      }
    });
  }

  let elementList = groupByArray(elementAttributeData, "ElementId");
  for (let ele of elementList) {
    let elementParams: any[] = [];
    elementParams = groupByArray(ele.values, "ParamGroup");
    for (let param of elementParams) {
      let paramList = param.values;
      let Id: string = param.values[0].ElementId;
      let CategoryName = param.values[0].CategoryName;
      let ParamGroup = param.values[0].ParamGroup;
      param = [];
      for (let par of paramList) {
        params.push({ Name: par.Label, Type: par.Type, Value: par.Value });
      }
      paramsList.push({ Id: Id, Params: params });
      await api.familyEditor.createAndSetParameters(
        CategoryName,
        "Instance",
        ParamGroup,
        paramsList
      );
    }
  }

  paramsList = [];
  elementList = groupByArray(couplingAttributeData, "ElementId");
  for (let ele of elementList) {
    let couplingList = groupByArray(ele.values, "CouplingId");
    for (let couple of couplingList) {
      let elementParams: any[] = [];
      elementParams = groupByArray(couple.values, "ParamGroup");
      for (let param of elementParams) {
        let paramList = param.values;
        let Id: string = param.values[0].CouplingId;
        let CategoryName = param.values[0].CategoryName;
        let ParamGroup = param.values[0].ParamGroup;
        param = [];
        for (let par of paramList) {
          coupling_params.push({
            Name: par.Label,
            Type: par.Type,
            Value: par.Value,
          });
        }
        paramsList.push({ Id: Id, Params: coupling_params });
        await api.familyEditor.createAndSetParameters(
          CategoryName,
          "Instance",
          ParamGroup,
          paramsList
        );
      }
    }
  }

  //return Promise.all(
  // couplingIds.map(async (couplingId) => {
  //   return await api.familyEditor.setParams([
  //     {
  //       Id: couplingId,
  //       Params: coupling_params,
  //     },
  //   ]);
  // })
  //);
};

export const highlightElementsInModel = async (
  elementsIdsToHighlight: string[]
) => {
  let result: ID | null = null;
  if (elementsIdsToHighlight && elementsIdsToHighlight.length > 0) {
    result = await api.queries.createSelectionFilter(
      "pam-highlight-elements-in-model-filter",
      elementsIdsToHighlight
    );
    await api.viewHandler.setFilterColor(result, {
      Red: 0,
      Green: 255,
      Blue: 0,
    });

    let view: ViewDetail = await api.queries.getActiveViewDetails();

    await api.viewHandler.setSelectionFilterVisibility(view.Id, result, true);
  }
  return result;
};

export const getElementName = (element: Element): string => {
  let tmpAngle = element.Angle !== null ? " - " + element.Angle + "deg" : "";
  let tmpDiameter = element.Diameter !== null ? " - DN" + element.Diameter : "";

  return element.FamilyName + tmpAngle + tmpDiameter;
};

export const getElementOrCouplingFromData = (
  objects: any[],
  mode: "ELEMENT" | "COUPLING"
) => {
  let elementObject: any = null;
  let couplingObject: any = null;
  if (mode === "COUPLING") {
    objects.forEach((object: any) => {
      object.attributes.forEach((attr: any) => {
        if (
          attr.technicalName
            .toLowerCase()
            .includes("P-Recommendation level for".toLowerCase())
        ) {
          couplingObject = _.cloneDeep(object);
        }
      });
    });
  } else if (mode === "ELEMENT") {
    objects.forEach((object: any) => {
      let found = false;
      object.attributes.forEach((attr: any) => {
        couplingObject = _.cloneDeep(object);
        if (
          attr.technicalName
            .toLowerCase()
            .includes("P-Recommendation level for".toLowerCase())
        ) {
          found = true;
        }
      });
      if (!found) {
        elementObject = object;
      }
    });
  }

  if (mode === "ELEMENT") {
    return elementObject;
  } else if (mode === "COUPLING") {
    return couplingObject;
  }
};

export const extractElementFromData = (response: any): any => {
  let data = _.cloneDeep(response.data.objects);
  data = data.filter(
    (object: { types: string[] }) => object.types[0] === "Product"
  );
  data = data.map((object: any) => {
    const article = response.data.objects
      .find(
        (o: any) =>
          o.types[0] === "Article" &&
          o.parentOids.some((p: any) => p === object.oid)
      )
      ?.attributes.find((a: any) => a.technicalName === "A-Article Name");
    return article
      ? {
          ...object,
          attributes: [...object.attributes, article],
        }
      : object;
  });
  return data;
};
