import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import { skipIntro } from "../../actions/userInfoActions";
import { AppWindowName, INTRODUCTION } from "../../assets/constants";
import { checkSkipIntro } from "../../helpers/utils";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./intro.scss";

class Intro extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let lang = props.i18n.language;
    // let lang = localStorage.getItem("savedLanguage");
    // if (!lang || (lang !== "English" && lang !== "French")) {
    //     lang = "English";
    // }

    this.state = {
      language: lang,
    };
    this.toggleSkipIntro = this.toggleSkipIntro.bind(this);
    this.setSkipIntro = this.setSkipIntro.bind(this);
    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  componentDidMount() {
    checkSkipIntro() ? this.setSkipIntro(true) : this.setSkipIntro(false);
  }

  toggleSkipIntro() {
    let { userInfo } = this.props;
    this.setSkipIntro(!userInfo.skipIntro);
  }

  setSkipIntro(value: boolean = false) {
    let { skipIntro } = this.props;
    skipIntro(value);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.GENERAL_TERMS_OF_USE_WINDOW);
  }

  nextPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  render() {
    let { userInfo, t } = this.props;
    return (
      <div id="intro-container">
        <Header
          displayName={t(INTRODUCTION) || ""}
          setLanguage={(lang: any) => {
            this.setState({
              language: lang,
            });
          }}
        ></Header>
        <Body
          skipIntro={userInfo.skipIntro}
          skipIntroChanged={this.toggleSkipIntro}
          language={this.state.language}
        ></Body>
        <Footer
          savedProjectsButtonDisabled={true}
          disabledNextButton={!userInfo.acceptedTermsOfUse}
          clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
          skipIntro={userInfo.skipIntro}
          skipIntroChanged={this.toggleSkipIntro}
          showSkipIntro={true}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
  };
};

let mapDispatchToProps = {
  skipIntro,
  selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { language: string };

export default connector(withTranslation(["pam"])(Intro));
