import { AnyAction } from "redux";
import { ProductDetailData } from "../Types";
import { selectBufferAction, resetBufferAction, searchAction, applyFiltersAction, resetFiltersAction, selectSystemAction } from "../Actions/types";

type selectionBufferAction = selectBufferAction | resetBufferAction | searchAction | applyFiltersAction | resetFiltersAction | selectSystemAction;

export const selectionBuffer = (selectionBuffer: ProductDetailData | null = null, action: selectionBufferAction) =>{
    switch(action.type){
        case "SELECT_BUFFER":
            return action.system;
        case "RESET_BUFFER":
            return null;
        case "SELECT_SYSTEM":
            return null;
        case "SEARCH":
            return null;
        case "APPLY_FILTERS":
            return null;
        case "RESET_FILTERS":
            return null;
        default:
            return selectionBuffer;
    }
}