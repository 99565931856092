import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { MetresRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "abfb1e2e-b36b-4be4-a78d-df2472cbb96c";

const NomenclatureTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    500,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Métrés",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={"Nomenclature"}
    >
      <MetresRoot />
    </ParentRoot>
  );
};

export const MetresRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={"Nomenclature"}
    >
      <MetresRoot />
    </ParentRoot>
  );
};

export const Metres: Functionality = {
  Name: {
    French: "Métrés",
    English: "Nomenclature",
    German: "Métrés",
    Italian: "Métrés",
    Indonesian: "Métrés",
  },
  Trigger: NomenclatureTrigger,
  ShortDesc: {
    French: "Cloisons / Doublages / Plafonds",
    English: "Partitions / Linings / Ceilings",
    German: "Cloisons / Doublages / Plafonds",
    Italian: "Cloisons / Doublages / Plafonds",
    Indonesian: "Cloisons / Doublages / Plafonds",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Métrés.png",
  RootComponent,
  ParentId: "0",
};
