import { Action } from 'redux'
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";

export const ADD_SYSTEM_TO_CART = "ADD_SYSTEM_TO_CART";
export const REMOVE_SYSTEM_FROM_CART = "REMOVE_SYSTEM_FROM_CART";
export const CHANGE_TYPE_ID = 'CHANGE_TYPE_ID'

export const CLEAN_CART = 'CLEAN_CART'

export interface CartState {
  currentSystemItems: ProductDetailData[]
  cartCount: number
  data: {
    wallsSystemDetails: ProductDetailData[],
    floorSystemDetails: ProductDetailData[],
    // liningWallsDetails: ProductDetailData[],
    // partitionsDetails: ProductDetailData[],
    // ceilingsDetails: ProductDetailData[]
  }
}

interface AddSystemToCartAction extends Action {
  type: typeof ADD_SYSTEM_TO_CART
  cartSystemItem: ProductDetailData,
  systemTypeID: any//number
}

interface RemoveSystemFromCartAction extends Action {
  type: typeof REMOVE_SYSTEM_FROM_CART
  systemTypeID: any,//number
  index: number,
  sytemIndex: number
}

interface CleanCartAction extends Action {
  type: typeof CLEAN_CART
}

export type CartActionTypes = AddSystemToCartAction | RemoveSystemFromCartAction | CleanCartAction

