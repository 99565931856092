import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.systemName}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Propriété</TableCell>
                  <TableCell align="right">Valeur avant mise à jour</TableCell>
                  <TableCell align="right">Valeur après mise à jour</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.systemProperties.map((systemPropertyRow) => (
                  <TableRow key={systemPropertyRow.propertyLabel}>
                    <TableCell component="th" scope="row">
                      {systemPropertyRow.propertyLabel}
                    </TableCell>
                    <TableCell align="right">
                      {systemPropertyRow.oldValue}
                    </TableCell>
                    <TableCell align="right">
                      {systemPropertyRow.newValue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ Systems }) {
  return (
    <TableContainer component={Paper} style={{ maxHeight: '85%' }}>
      <Table aria-label="collapsible table">
        <TableBody>
          {Systems.map((row) => (
            <Row key={row.systemName} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
