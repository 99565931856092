import React from "react";
import { Button, Container, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "../Resources/Buttonpack.css";
import { Routes } from "./root";

interface Props {
  setRoute: any;
  routes: any;
  cancel: string;
  confirm: string;
  prevPage: string;
  nexPagehandler? : any;
}

export default class ButtonPack extends React.Component<Props> {
  nextPageHandler = async () => {
    this.props.setRoute(Routes.SUPPLIMENTARY);
  };
  returnHandler = async () => {
  
    this.props.prevPage === Routes.TECHNICAL_LANDING
      ? this.props.setRoute(Routes.TECHNICAL_LANDING)
      : this.props.setRoute(Routes.ROOT);
  };

  render() {
    return (
      <Segment className="text-center no-border button-pack" style={{
        bottom:0, left:0, width:"100%", position:"sticky", padding:"1em"
      }}>
        <Container style={{ textAlign: "center" }}>
          <Button
            color="orange"
            size="medium"
            onClick={() => this.returnHandler()}
            className={
              this.props.routes === "PROJECT_INFO_FORM" &&
              this.props.prevPage !== Routes.TECHNICAL_LANDING
                ? "divHide"
                : ""
            }
            content={this.props.cancel}
            style={{ marginRight: 30 }}
          ></Button>

          <Button size="medium" primary content={this.props.confirm}></Button>
          <Button
            color="orange"
            size="medium"
            onClick={this.props.nexPagehandler}
            className={this.props.routes === "SUPPLIMENTARY_FORM" ? "divHide" : ""}
            style={{ marginLeft: "5%" }}
          >
            Information supplémentaire
          </Button>
        </Container>
       
      </Segment>
    );
  }
}
