import { CHANGE_CURRENT_WINDOW, AppActionTypes, INIT_LANGUAGE } from "./types"
import { APP_WINDOW_NAME } from "../../assets/constants";
import { Language } from "../../../../../RevitJS/Types/RevitTypes";

export function selectWindowToShowAction(windowToShow: APP_WINDOW_NAME) : AppActionTypes {
  return {
    type: CHANGE_CURRENT_WINDOW,
    payload: windowToShow,
  }
}

export const changeCurrentLanguageAction = (language: Language): AppActionTypes => {
  return {
    type: INIT_LANGUAGE,
    language: language,
  }
}

