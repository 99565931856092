import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

export const ConfirmModal = (props: any) => {
  return (
    <Modal open={props.confirmModal} size="mini" dimmer="blurring">
      <Modal.Header className="modalHeader">
        {props.header}
        <Icon
          style={{ float: "right", cursor: "pointer" }}
          name="close"
          onClick={props.toggleConfirmModal ?? props.onNoAction}
        />
      </Modal.Header>
      <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
        <p style={{padding:"0px 10px"}}>{props.description}</p>
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button color="orange" size="tiny" onClick={props.onNoAction}>
          {props.no}
        </Button>
        <Button
          size="tiny"
          type="submit"
          primary
          onClick={() => {
            props.onYesAction();
          }}
        >
          {props.yes}
        </Button>
      </div>
    </Modal>
  );
};
