import { ThunkAction } from "redux-thunk";
import { CartActionTypes } from "./types";
import {
  addSystemToCartAction,
  cleanCart,
  removeSystemFromCartAction,
} from "./actions";
import { DrawStore } from "../reducers";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { ID } from "../../../../../RevitJS/Types/RevitTypes";
import { WALLSYSTEM_ID, FLOORSYSTEM_ID } from "../../assets/constants";

type Effect = ThunkAction<any, DrawStore, any, CartActionTypes>;

export const addSystemToCart =
  (system: ProductDetailData): Effect =>
  (dispatch: any, getState: any) => {
    let { systemTypeID } = getState().systems;
    dispatch(addSystemToCartAction(system, systemTypeID));
  };

export const removeSystemFromCart =
  (oid: ID): Effect =>
  (dispatch: any, getState: any) => {
    const index = getState()
      .cart.currentSystemItems.map(function (x: any) {
        return x.oid;
      })
      .indexOf(oid);
    let { systemTypeID } =  getState().systems;
    let systemIndex = getState()
                      .cart.data.wallsSystemDetails.map(function (x: any) {
                        return x.oid;
                      })
                      .indexOf(oid); 
    if(systemIndex  !== -1  ) {
      systemTypeID = WALLSYSTEM_ID;
    } else  {
      systemTypeID  = FLOORSYSTEM_ID;
      systemIndex = getState()
                    .cart.data.floorSystemDetails.map(function (x: any) {
                      return x.oid;
                    })
                    .indexOf(oid);
    }
    
    dispatch(removeSystemFromCartAction(index, systemTypeID, systemIndex));
  };

export const checkCartSystem =
  (oid: ID): Effect =>
  (dispatch: any, getState: any) => {
    return getState().cart.currentSystemItems.find(
      (item: any) => oid === item.oid
    );
  };

export const cleanCartSystem = (): Effect => (dispatch: any, getState: any) => {
  dispatch(cleanCart());
};
