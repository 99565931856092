import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { AppBody } from "./Components/Page/AppBody";

export const functionalityId = "c34011d4-5ba0-43a3-9b8b-912f60c4f88b";

export const infoProjectsTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    800,
    650,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

export const GyprocProjectInfoRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="project info"
    >
      <AppBody />
    </ParentRoot>
  );
};

export const ProjectInfo: Functionality = {
  Name: {
    French: "Renseignements sur le projet",
    English: "Project Information",
    German: "Projekt Information",
    Italian: "Informazioni di progetto",
    Indonesian: "Informasi Proyek",
  },
  Trigger: infoProjectsTrigger,
  ShortDesc: {
    French: "Informations sur le projet et l'entreprise",
    English: "Project and company information",
    German: "Projekt- und Firmeninformationen",
    Italian: "Informazioni sul progetto e sull'azienda",
    Indonesian: "Informasi proyek dan perusahaan",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Projet.png",
  RootComponent: GyprocProjectInfoRootComponent,
  ParentId: "0",
};
