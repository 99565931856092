import React from "react";
import { pluginId } from "..";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { HelpRoot } from "./Components/root";

export const functionalityId = "4b2aab5f-e91f-46b3-99be-fadccf9306fe";

export const helpTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    500,
    // `https://www.placo.fr/Services/Le-batiment-numerique-avec-Placo-R/PrescriBIM-la-palette-BIM-de-Placo-R-se-renforce`
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <HelpRoot />
    </ParentRoot>
  );
};

export const HelpRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <HelpRoot />
    </ParentRoot>
  );
};

export const Help: Functionality = {
  Name: {
    French: "A propos",
    English: "About",
    German: "A propos",
    Italian: "A propos",
    Indonesian: "A propos",
  },
  Trigger: helpTrigger,
  ShortDesc: {
    French: "L’outil PrescriBIM",
    English: "The PrescriBIM tool",
    German: "L’outil PrescriBIM",
    Italian: "L’outil PrescriBIM",
    Indonesian: "L’outil PrescriBIM",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_A_propos.png",
  RootComponent,
  ParentId: "0",
};
