import React, { Component } from "react";
import { Routes } from "./root";
import IdentificationTable from "./IdentificationTable";
import ReperageLanding from "./ReperageLanding";
import GroupSelector from "../../Selection/Components/GroupSelector";
import SelectionMapping from "../../Selection/Components/SelectionMapping";
import { SelectionStore } from "../../Selection/Reducers";
import { connect, ConnectedProps } from "react-redux";
import {
  fetchRemoteData,
  loadSelections,
  setprojectData,
} from "../../Selection/Actions";
import SelectionRoot from "../../Selection/Components/root";
import { ReperageSelection } from "./RaperageSelection";
import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { Selection } from "../../Selection/Actions/types";
import { initLanguage } from "../Actions";
import { api } from "../../../../RevitJS/API";
import { SystemDetailsContainer } from "../../Selection/Components/SystemDetailsContainer";
import { SystemConfigurationContainer } from "../../Selection/Components/SystemConfigurationContainer";
import ProductsPage from "./ProductsPage";
import { PersonalColorTable } from "./PersonalColorTable";
import ReperageParametrageRevit from "./ReperageParametrageRevit";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";
import { ConfigurationContainers } from "../../../../Components/AdvanceSelector/systemConfiguration/ConfigurationContainers";
import { DetailsContainers } from "../../../../Components/AdvanceSelector/systemDetails/DetailsContainers";

const mapState = (state: SelectionStore, ownProps: any) => ({
  setRoute: ownProps.setRoute,
  routes: ownProps.route,
  config: state.config,
  selectionBuffer: state.selectionBuffer
});
const mapDispatch = {
  fetchRemoteData,
  loadSelections,
  initLanguage,
  setprojectData,
  setConfig,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  route: string;
  setRoute: any;
  selectionPar: boolean;
  module: string;
  initLanguage: any;
  loadSelections: any;
  setprojectData: any;
  config: any;
  selectionBuffer:any
};

export class AppBody extends Component<Props> {
  public static defaultProps = {
    module: "Reperage",
    selectionPar: false,
  };

  componentDidMount = async () => {
    this.props.initLanguage();

    // let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);

    // if (placoSelections) {
    //   let selections = placoSelections as {
    //     [key: string]: Selection<PlacoOptions>;
    //   };
    //   this.props.loadSelections(selections);
    // }
    let projectData: initProjectData = await api.queries.getSetProjectData();
    this.props.setprojectData(projectData);

    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  returnRedirect = () => {
    this.props.setRoute(Routes.PRODUCT_PAGE);
  };

  render() {
    const { route, setRoute, selectionPar, module } = this.props;
    switch (route) {
      case Routes.ROOT:
      case Routes.BACK_FROM_SELECTION:
        return <ReperageLanding route={route} setRoute={setRoute} />;
      case Routes.COLORISATION_TABLE:
        return <IdentificationTable route={route} setRoute={setRoute} />;
      case Routes.PERSONALISECOLOR_TABLE:
        return <PersonalColorTable route={route} setRoute={setRoute} />;
      case Routes.SELECTION_HOME:
        return <SelectionRoot module="selection" selectionPar={selectionPar} />;
      case Routes.GROUP_SELECTION:
        return <GroupSelector route={route} setRoute={setRoute} />;
      case Routes.MAPPING:
        return (
          <SelectionMapping route={route} setRoute={setRoute} module={module} />
        );
      case Routes.PRODUCT_PAGE:
        return <ProductsPage setRoute={setRoute} />;
      case Routes.REPERAGE_SELECTION:
        return <ReperageSelection setRoute={setRoute} />;
      case Routes.SYSTEM_DETAILS:
        return (
          <DetailsContainers
            setRoute={setRoute}
            returnRedirect={this.returnRedirect}
            detailsProp={this.props}
          />
        );
      case Routes.SYSTEM_CONFIGURATION:
        return (
          <ConfigurationContainers
            setRoute={setRoute}
            returnRedirect={this.returnRedirect}
            configProps={this.props}
          />
        );
      case Routes.REVIT_SETUP:
        //return <ReperageSelection setRoute={setRoute} />;
        return <ReperageParametrageRevit setRoute={setRoute} />;
      default:
        return null;
    }
  }
}

//export default connect(mapState)(AppBody);
export default connector(AppBody);
