import Dexie from "dexie";
import Project from "../models/Project.model";

export class AppDatabase extends Dexie {
    projectsList!: Dexie.Table<Project, number>;
    constructor() {
        super("ProjectsDB");

        var db = this;

        let dbVersion = db.verno && db.verno > 0 ? db.verno : 1;

        db.version(dbVersion).stores({
            projectsList: "++id, value",
        });
    }
}

export var db = new AppDatabase();
