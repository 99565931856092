import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SelectionMethod } from "../../../assets/constants";
import manualSelect from "../../../assets/manual_select_img.png";
import manualSelectInverse from "../../../assets/manual_select_inverse.png";
import systemNameSelect from "../../../assets/system_name_select_img.png";
import systemNameSelectInvers from "../../../assets/system_name_select_inverse.png";
import "./body.scss";

function Body({ elementSelectionButtonClicked, t }: IProps) {
    const hover = (e: HTMLElement, mode: "manual" | "group") => {
        if (mode === "manual") {
            e.setAttribute("src", manualSelectInverse);
        } else {
            e.setAttribute("src", systemNameSelectInvers);
        }
    };

    const unhover = (e: HTMLElement, mode: "manual" | "group") => {
        if (mode === "manual") {
            e.setAttribute("src", manualSelect);
        } else {
            e.setAttribute("src", systemNameSelect);
        }
    };

    return (
        <div id="element-selection-body-container">
            <div className="px-4 py-2">
                <div className="row">
                    <div className="offset-4 col-2">
                        <button
                            onClick={() => {
                                elementSelectionButtonClicked(
                                    SelectionMethod.MANUAL
                                );
                            }}
                            className="xlarge-btn"
                        >
                            <img
                                src={manualSelect}
                                alt="Logo placeholder"
                                className="round"
                                onMouseOver={(e) => {
                                    hover(e.currentTarget, "manual");
                                }}
                                onMouseOut={(e) => {
                                    unhover(e.currentTarget, "manual");
                                }}
                            />
                        </button>
                        <p className="text-center color-sg-pam-orange">
                            <strong>{t("MANUAL_SELECT").toUpperCase()}</strong>
                        </p>
                    </div>
                    <div className="col-2">
                        <button
                            onClick={() => {
                                elementSelectionButtonClicked(
                                    SelectionMethod.SYSTEM_NAME
                                );
                            }}
                            className="xlarge-btn"
                        >
                            <img
                                src={systemNameSelect}
                                alt="Logo placeholder"
                                className="round"
                                onMouseOver={(e) => {
                                    hover(e.currentTarget, "group");
                                }}
                                onMouseOut={(e) => {
                                    unhover(e.currentTarget, "group");
                                }}
                            />
                        </button>
                        <p className="text-center color-sg-pam-orange">
                            <strong>
                                {t("SYSTEM_NAME_SELECT").toUpperCase()}
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    elementSelectionButtonClicked: Function;
};

export default withTranslation(["pam"])(Body);
