import React, { useContext, useEffect, useState } from "react";
import FunctionalityHeader from "../../../Common/components/FunctionalityHeader";
import { Button, CircularProgress, Box } from "@material-ui/core";
import { DrawStore } from "../../store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { loadSystems } from "../../store/updateSystems/effects";
import { CSVLink } from "react-csv";
import DataTable from "../DataTable";
import { api } from "../../../../../RevitJS/API";
import AlertDialog from "../../../Common/components/AlertDialog";
import { WithTranslation, withTranslation } from "react-i18next";

import "./index.scss";
import { IMappingConfigContextType, MappingConfigContext } from "../../../Common/context/mappingConfig.context";

export const UpdateSystemContainer: React.FC<UpdateContainerProps> = ({
  loadSystems,
  currentSystemsToUpdate,
  systems,
  loadingSystems,
  config,
  t,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(true);
  const { mainCategoryData } = useContext(
    MappingConfigContext
  ) as IMappingConfigContextType;


  useEffect(() => {
    if (mainCategoryData !== null) {
      loadSystems(t, config, mainCategoryData);
    }
  }, [config, mainCategoryData]);

  const handleUpdateSystemsContent = async () => {
    setOpenDialog(true);
    currentSystemsToUpdate = currentSystemsToUpdate.filter((sys) =>
      systems.some((s) => s.systemName === sys.solution)
    );
    await api.queries
      .createElementTypeWithCustomParameters(
        currentSystemsToUpdate,
        config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
      )
      .then(() => setProgress(false));

    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Update",
      eventCategory: "Module Execution",
      eventLabel:
        localStorage.getItem("TechnicalName") !== "PLACOBIM"
          ? "Data Update"
          : "Library_Data Update",
      module: localStorage.getItem("TechnicalName") as string,
    });
  };

  const csvData = (systems: any) => {
    const systemData: any[] = [];
    systemData.push([
      "Nom du système",
      "Nom de la propriété",
      "Valeur avant mise à jour",
      "Valeur après mise à jour",
    ]);
    systems.forEach((element: any) => {
      element.systemProperties.forEach((prop: any) => {
        systemData.push([
          element.systemName,
          prop.propertyLabel,
          prop.oldValue,
          prop.newValue,
        ]);
      });
    });

    return systemData;
  };

  const handelCloseDialog = () => {
    api.windowsHandler.closeWindow();
  };
  return (
    <div className="PropertyGlobalContainer">
      <FunctionalityHeader
        name={"Mise à jour des systèmes"}
        icon={config.REACT_APP_SERVERURL + "/PrescriBIM_Logo.png"}
      />
      <div className="UpdateSystemContainer">
        <div className="updateSystems">
          {loadingSystems ? (
            <div className="PropertiesCircularProgressContainer">
              <CircularProgress className="PropertiesCircularProgress" />
            </div>
          ) : systems && systems.length > 0 ? (
            <>
              <Box fontStyle="italic" m={1}>
                {t("UPDATE_INFORMATION")}
              </Box>
              <DataTable Systems={systems} />
            </>
          ) : (
            <Box fontStyle="italic" m={1}>
              {"Toutes les données des systèmes intégrés sont mises à jour"}
            </Box>
          )}
        </div>
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton detailActionButton"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handelCloseDialog()}
        >
          {t("CLOSE")}
        </Button>
        {systems && systems.length > 0 && (
          <div>
            <CSVLink
              className="MuiButtonBase-root button MuiButton-contained MuiButton-containedPrimary CSVLinkButton loadActionButton detailActionButton"
              data={csvData(systems)}
              separator={";"}
              filename={"Placos Systems updated data.csv"}
            >
              {t("EXPORT_TO_CSV")}
            </CSVLink>
            <Button
              className="loadActionButton detailActionButton"
              style={{ textTransform: "none" }}
              variant="contained"
              onClick={() => handleUpdateSystemsContent()}
              disabled={!(systems && systems.length > 0)}
            >
              {t("UPDATE_SYSTEMS")}
            </Button>
          </div>
        )}
      </div>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={handelCloseDialog}
        progress={progress}
        title={"Information"}
        content={t("UPDATE_INFORMATION")}
      />
    </div>
  );
};

function mapStateToProps(state: DrawStore) {
  return {
    systems: state.updateSystems.systems,
    currentSystemsToUpdate: state.updateSystems.currentSystemsToUpdate,
    loadingSystems: state.updateSystems.loadingSystems,
    config: state.config,
  };
}

let mapDispatchToProps = {
  loadSystems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type UpdateContainerProps = PropsFromRedux & WithTranslation;

export default connector(withTranslation(["library"])(UpdateSystemContainer));
