import { ProductDetailData } from "../../../../RevitJS/Types/BddTypes";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../Common/assets/attributes.injection";
import {
  DISTRIBUTIVE_STANDARD,
  ISOLATION_DES_MURS,
  SEPARATIVE_STANDARD,
  SEPARATIVE_STANDARD_SAA,
  SEPARATIVE_STANDARD_SAD,
  FILTER_CRITERION_TYPE,
  FILTER_VALUE_TYPE,
  DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR,
} from "../assets/constants";

export const addFilterFields = (
  systemsDetails: ProductDetailData[],
  filters: any
) => {
  return systemsDetails.map((sys: any) => {
    filters.forEach((filter: any) => {
      sys.filterFields[filter.name] = [];
      const attr = sys.attributes.find(
        (attr: any) => attr.technicalName === filter.pimAttribute
      );
      if (attr) {
        attr.values.forEach((element: any) => {
          let value =
            filter.type === FILTER_VALUE_TYPE.NUMERIC
              ? element.numericValue
              : element.value;
          sys.filterFields[filter.name].push(value);
        });
      } else {
        sys.filterFields[filter.name] = undefined;
      }
    });
    return sys;
  });
};

export const setFiltersOptions = (
  systemsDetails: ProductDetailData[],
  filters: any
) => {
  filters = [...filters];
  systemsDetails.forEach((system) => {
    filters.forEach((filter: any) => {
      filter.options = filter.options || [];
      const attrValue = system.filterFields[filter.name];
      if (attrValue !== undefined) {
        attrValue.forEach((element: any) => {
          if (!filter.options.includes(element)) {
            filter.options.push(element);
          }
        });
      }
    });
  });

  return filters.map((filter: any) => {
    if (filter.options) {
      filter.options.sort((a: any, b: any) => {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;

        var aaN = parseInt(a);
        var bbN = parseInt(b);

        if (isNaN(aaN) && isNaN(bbN)) {
          var aA = a.replace(reA, "");
          var bA = b.replace(reA, "");

          if (aA === bA) {
            var aNN = parseInt(a.replace(reN, ""), 10);
            var bNN = parseInt(b.replace(reN, ""), 10);
            return aNN === bNN ? 0 : aNN > bNN ? 1 : -1;
          }
        }

        if (!isNaN(aaN) && !isNaN(bbN)) {
          return aaN < bbN ? -1 : aaN > bbN ? 1 : 0;
        }

        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    return filter;
  });
};

export const filterElements = (systemsDetails: any[], filters: any[]) => {
  return filters.reduce(
    (acc, current) => {
      if (current.options) {
        if (current.value) {
          if (current.value === "") return acc;
          let matchingDetails;
          switch (current.criterion) {
            case FILTER_CRITERION_TYPE.GREATERTHEN:
              return acc.filter((el: { technicalName: string }, i: any) => {
                matchingDetails = systemsDetails.find(
                  (details) => details.technicalName === el.technicalName
                );
                return (
                  matchingDetails?.filterFields[current.name] >= current.value
                );
              });
            case FILTER_CRITERION_TYPE.EQUALS:
              return acc.filter((el: { technicalName: string }, i: any) => {
                matchingDetails = systemsDetails.find(
                  (details) => details.technicalName === el.technicalName
                );
                return (
                  matchingDetails?.filterFields[current.name] === current.value
                );
              });
            case FILTER_CRITERION_TYPE.INCLUDES:
              return acc.filter((el: { technicalName: string }, i: any) => {
                matchingDetails = systemsDetails.find(
                  (details) => details.technicalName === el.technicalName
                );
                let filterFields = matchingDetails?.filterFields[current.name];
                return (
                  current.value.filter((c: any) =>
                    filterFields?.some((m: any) => c === m)
                  ).length > 0
                );
                // return current.value.includes(
                //   matchingDetails?.filterFields[current.name]
                // );
              });
            case FILTER_CRITERION_TYPE.BETWEEN:
              if (
                current.value[0] !== undefined &&
                current.value[1] !== undefined
              ) {
                return acc.filter((el: any) => {
                  matchingDetails = systemsDetails
                    .filter(
                      (detail: any) =>
                        detail?.filterFields[current.name] !== undefined
                    )
                    .find(
                      (details: any) =>
                        details.technicalName === el.technicalName
                    );
                  return (
                    current.value[0] <=
                      matchingDetails?.filterFields[current.name] &&
                    matchingDetails?.filterFields[current.name] <=
                      current.value[1]
                  );
                });
              } else {
                return acc;
              }

            default:
              return acc;
          }
        }
        return acc;
      }
      return acc;
    },
    [...systemsDetails]
  );
};

export const getSystemType = (system: any) => {
  const SystemType = system.attributes.find(
    (attribute: any) =>
      attribute.technicalName ===
      PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION
  )?.values[0]?.value;

  const SystemName = system.attributes.find(
    (attribute: any) =>
      attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.SYSTEM_NAME
  )?.values[0]?.value;
  if (SystemType === DISTRIBUTIVE_STANDARD) return DISTRIBUTIVE_STANDARD;
  if (SystemType === DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR)
    return DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR;
  else if (SystemType === ISOLATION_DES_MURS || SystemName.includes("Doublage"))
    return ISOLATION_DES_MURS;
  else if (SystemType === SEPARATIVE_STANDARD && SystemName.includes("SAA"))
    return SEPARATIVE_STANDARD_SAA;
  else if (SystemType === SEPARATIVE_STANDARD && SystemName.includes("SAD"))
    return SEPARATIVE_STANDARD_SAD;
};
