import React, { Component, useEffect } from "react";
import { Routes } from "./root";
import { Waiter } from "./Waiter";

interface Props {
  route: Routes;
  onError: any;
}

export const AppBodyformat = ({ route, onError }: Props) => {
  switch (route) {
    case Routes.ROOT:
      return <Waiter onError={onError} />;
    default:
      return null;
  }
};

const mapStateToProps = (state: null, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};
