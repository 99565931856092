import { Language } from "../RevitJS/Types/RevitTypes";
import { Plugin } from "../RevitJS/Types/StoreTypes";

export const START_SELECTION = "START_SELECTION";
export const END_SELECTION = "END_SELECTION";
export const OPEN_WINDOW = "OPEN_WINDOW";
export const CLOSE_WINDOW = "CLOSE_WINDOW";
export const SHOW_WINDOW = "SHOW_WINDOW";
export const HIDE_WINDOW = "HIDE_WINDOW";
export const RESIZE_WINDOW = "RESIZE_WINDOW";
export const MINIMIZE_WINDOW = "MINIMIZE_WINDOW";
export const MAXIMIZE_WINDOW = "MAXIMIZE_WINDOW";
export const RESTORE_WINDOW = "RESTORE_WINDOW";
export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_PLUGINS = "SET_PLUGINS";
export const SET_ERROR_MODAL = "SET_ERROR_MODAL";

export interface InitLanguageAction{
    type: typeof INIT_LANGUAGE;
    language: Language
}

export interface SetLanguageAction{
    type: typeof SET_LANGUAGE,
    language: Language
}

export interface SetPluginsAction{
    type: typeof SET_PLUGINS,
    plugin: Plugin[]
}

export type LanguageAction = InitLanguageAction | SetLanguageAction