import React from "react";
import { Container, Grid, Icon, Popup } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { FunctionalityHeader } from "./FunctionalityHeader";
import { StepsData } from "../StepsData/StepsData";

interface Props {
    name: string;
    Icon: string;
    subheader?: string;
    totalStepsCompleted: number;
    steps: any;
    language: string;
    onSave: any;
    wording: {
        title: { [key: string]: string };
        completedSteps: { [key: string]: string };
        paretage: { [key: string]: string };
        parzone: { [key: string]: string };
        selections: { [key: string]: string };
        calpinage: { [key: string]: string };
        back: { [key: string]: string };
        calepiner: { [key: string]: string };
        completed: { [key: string]: string };
        notCompleted: { [key: string]: string };
        loadingFamilies: { [key: string]: string };
        processing: { [key: string]: string };
        no: { [key: string]: string };
        yes: { [key: string]: string };
        backup: { [key: string]: string };
        description: { [key: string]: string };
        layoutSaved: { [key: string]: string };
        layoutSavedHeader: { [key: string]: string };
        save: { [key: string]: string };
        noWallsHeading: { [key: string]: string };
        noWalls: { [key: string]: string };
    };
}

export const Calepinage = (props: Props) => {
    return (
        <div style={{ height: "100%" }}>
            <FunctionalityHeader
                Icon={props.Icon}
                name={props.name}
                subheader=""
            />

            <Container
                className="header-alignment subHeader"
                style={{ marginTop: "1rem" }}
            >
                {props.totalStepsCompleted +
                    "/" +
                    props.steps.length +
                    " " +
                    ReactHtmlParser(
                        props.wording.completedSteps[props.language]
                    )
                }
            </Container>
            <Container className="grid-container" style={{ paddingTop: 15 }}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <div style={{ textAlign: "right" }}>
                                <Popup
                                    content={ReactHtmlParser(
                                        props.wording.save[props.language]
                                    )}
                                    position="top center"
                                    inverted
                                    size="mini"
                                    trigger={
                                        <Icon
                                            name="save"
                                            color="blue"
                                            size="big"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                Promise.resolve(props.onSave()).then(() => {
                                                    localStorage.setItem("isModification", "false");
                                                });
                                                ;
                                            }}
                                        ></Icon>
                                    }
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid style={{ width: "100%", }}>
                        <StepsData data={props.steps} {...props} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

Calepinage.defaultProps = {
    wording: {
        title: {
            French: "CALEPINAGE",
            English: "LAYOUT",
        },
        completedSteps: {
            French: "étapes complétées",
            English: "completed steps",
        },
        paretage: {
            French: "Sélection",
            English: "Selection",
        },
        parzone: {
            French: "Zone",
            English: "Zone",
        },
        selections: {
            French: "Sélections",
            English: "Selections",
        },
        calpinage: {
            French: "Configuration de Calepinage",
            English: "Layout configuration",
        },
        back: {
            French: "Retour",
            English: "Back",
        },
        calepiner: {
            French: "Calepiner",
            English: "Calepine",
        },
        completed: {
            French: "Completed",
            English: "Completed",
        },
        notCompleted: {
            French: "Not Completed",
            English: "Not Completed",
        },
        loadingFamilies: {
            French: "Chargement des familles...",
            English: "Loading Families",
        },
        processing: {
            French: "Calepinage en cours: ",
            English: "Layout in progress: ",
        },
        no: {
            French: "Non",
            English: "No",
        },
        yes: {
            French: "Oui",
            English: "Yes",
        },
        backup: {
            French: "Sauvegarde",
            English: "Backup",
        },
        description: {
            French:
                "Voulez-vous enregistrer la configuration de calepinage  en cours de modification avant de quitter ?",
            English:
                "Do you want to save the configuration before exiting this menu?",
        },
        layoutSaved: {
            French: "Calepinage sauvegardé!",
            English: "Saved Layout!",
        },
        layoutSavedHeader: {
            French: "Sauver succès",
            English: "Layout saved successful",
        },
        save: {
            French: "Sauvegarder votre configuration",
            English: "Save your configuration",
        },
        noWalls: {
            French: "Aucun Murs n'a été sélectionné dans le projet",
            English: "No walls selected",
        },
        noWallsHeading: {
            French: "Aucun Murs",
            English: "No walls",
        },
    },
};