import { INIT_LANGUAGE, LangActionType, LanguageType, RESET_LANGUAGE, SET_LANGUAGE } from "../Types";


const intialState: LanguageType = "English";


const LanguageReducer = (state: LanguageType = intialState, action: LangActionType): LanguageType => {
    switch (action.type) {
        case SET_LANGUAGE:
            state = action.payload;
            return state;
        case RESET_LANGUAGE:
            state = intialState;
            return state;
        case INIT_LANGUAGE:
            state = action.language;
            return state;
        default:
            return state;
    }
}

export default LanguageReducer;