import React, { useContext, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FunctionalityHeader from "../../../Common/components/FunctionalityHeader";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { Button, CircularProgress } from "@material-ui/core";
import { api } from "../../../../../RevitJS/API";
import PlacoCard from "../Common/PlacoCard";
import SearchComponenet from "../Common/Search";
import SelectSystemType from "../../../Common/components/SelectSystemType";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../store/app/effects";
import {
  loadSystemsDetails,
  loadSystems,
  loadMoreSystemsDetails,
  deleteSearchKeyWord,
  addSearchKeyWord,
  displayFavorite,
  displaySystems,
} from "../../store/systems/effects";
import { setCurrentSystemDetail } from "../../store/systemDetail/effects";
import { filterSystemsDetails } from "../../store/systems/effects";
import { APP_WINDOW_NAME } from "../../assets/constants";
import { DrawStore } from "../../store/reducers";
import {
  changeFavoriteSystemType,
  clearAllFavorite,
  loadFavorites,
} from "../../store/favorite/effects";
import {
  applyFilters,
  loadFilters,
  resetFilters,
  resetSearchFilter,
} from "../../store/filter/effects";

import "./index.scss";
import Filters from "../../../../../Components/AdvanceSelector/Filter/Components";
import { ShoppingCartComponent } from "../../../../../Components/AdvanceSelector/Cart/Components";
import AlertDialog from "../../../Common/components/AlertDialog";
import _ from "lodash";
import {
  IMappingConfigWeberContextType,
  MappingConfigWeberContext,
} from "../../../Common/context/mappingconfigweber.context";

const RenderCard = ({
  products,
  mappingConfig,
  setOpenDialog,
  openDialog,
  t,
}: RenderCardProps) => (
  <>
    {mappingConfig && products ? (
      products.length > 0 ? (
        products.map((product, index) => {
          let productName = product.attributes.find(
            (p: any) =>
              p.technicalName === mappingConfig?.productname?.attributeName
          );
          let productAttributeName = productName?.values[0]?.value;
          return (
            <PlacoCard
              productImage={product.thumbnailUrl}
              key={product.oid}
              index={index}
              oid={product.oid}
              //productName={product.translation}
              productName={productAttributeName}
              technicalName={product.technicalName}
              system={product}
              showDetailsButton={mappingConfig ? mappingConfig.detailsUI : true}
            ></PlacoCard>
          );
        })
      ) : (
        <AlertDialog
          open={openDialog}
          onClose={() => {}}
          setOpen={setOpenDialog}
          progress={false}
          title={t("WARNING_LOAD_SYSTEMS")}
          content={t("WARNING_PLUGIN_SETUP_MESSAGE")}
        />
      )
    ) : null}
  </>
);

export const LoadSystemContainer: React.FC<LoadSystemContainerProps> = ({
  displayedSystemsCount,
  favoriteDisplayed,
  favoriteCount,
  loadingSystemsDetails,
  loadingSystems,
  loadSystems,
  filterSystemsDetails,
  loadMoreSystemsDetails,
  setCurrentSystemDetail,
  systems,
  systemsDetails,
  loadSystemsDetails,
  cartCount,
  selectWindowToShow,
  filters,
  systemTypeID,
  changeFavoriteSystemType,
  loadFavorites,
  searchKeyWords,
  addSearchKeyWord,
  deleteSearchKeyWord,
  t,
  resetSearchFilter,
  config,
  loadFilters,
  applyFilters,
  displayFavorite,
  displaySystems,
  resetFilters,
  clearAllFavorite,
}) => {
  const [isFavoriteDisplayed, setIsFavoriteDisplayed] =
    useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [filterButtonColor] = useState<any>({
    resetFavoriteButtonColor: "#A7A8AA",
    showFavoriteButtonColor: "#FAE100",
    resetFilterButtonColor: "#646464",
  });

  const [openDialog, setOpenDialog] = useState<boolean>(true);
  const { advanseSelectorData, categoryTypeOptions, activeVersionData } =
    useContext(MappingConfigWeberContext) as IMappingConfigWeberContextType;

  useEffect(() => {
    api.windowsHandler.resizeWindow(970, 530);
    if (config && Object.keys(config).length > 0) {
      if (!systems || systems.length === 0) loadSystems(config);
    }
  }, [config]);

  useEffect(() => {
    if (config && Object.keys(config).length > 0) {
      loadSystemsDetails(
        systemTypeID,
        config,
        categoryTypeOptions,
        advanseSelectorData,
        activeVersionData
      );
      loadFavorites(config);
    }
  }, [
    JSON.stringify(systems),
    systemTypeID,
    config,
    categoryTypeOptions,
    advanseSelectorData,
  ]);

  useEffect(() => {
    changeFavoriteSystemType();
  }, [systemTypeID]);

  useEffect(() => {
    if (systemsDetails && systemsDetails.length > 0 && count === 0) {
      loadFilters(advanseSelectorData);
      setCount(1);
    }
  }, [systemsDetails, advanseSelectorData]);

  useEffect(() => {
    document.getElementsByClassName("LoadSystemCardContainer")[0].scrollTop = 0;
    if (count > 0) {
      filterSystemsDetails(advanseSelectorData);
    }
  }, [
    JSON.stringify(filters),
    JSON.stringify(searchKeyWords),
    count,
    advanseSelectorData,
  ]);

  const handleScrollSystems = (event: any) => {
    if (
      event.target.scrollHeight - event.target.scrollTop >
        event.target.clientHeight - 10 &&
      event.target.scrollHeight - event.target.scrollTop <=
        event.target.clientHeight + 10 &&
      favoriteDisplayed === false
    ) {
      loadMoreSystemsDetails();
    }
  };

  const handleShowCartContent = () => {
    selectWindowToShow(APP_WINDOW_NAME.DETAILS_CART_WINDOW);
  };

  const handleBackToLoadSystems = () => {
    api.windowsHandler.closeWindow();
  };

  const onFilterChange = (e: any, d: any, pimAttribute: string) => {
    if (d.value !== undefined) {
      applyFilters(pimAttribute, d.value);
    } else {
      applyFilters(pimAttribute, d);
    }

    filterSystemsDetails(advanseSelectorData);
  };

  const displayAllSystems = () => {
    resetFilters(advanseSelectorData);
    displaySystems();
    setIsFavoriteDisplayed(false);
  };
  const displayFavoriteSystems = () => {
    resetFilters(advanseSelectorData);
    displayFavorite();
    setIsFavoriteDisplayed(true);
  };

  const resetAllFilters = () => {
    resetFilters(advanseSelectorData);
    filterSystemsDetails(advanseSelectorData);
  };

  return (
    <div className="LoadSystemGlobalContainer">
      <FunctionalityHeader
        name={t("LOAD_SYSTEM_TITLE")}
        icon={config.REACT_APP_SERVERURL + "/WeberIndonesia_logo.png"}
      />
      <div className="LoadSystemContainer">
        {/* <FiltersComponent favoriteCount={favoriteCount} /> */}
        <Filters
          showFavorite={true}
          filtersData={filters}
          favoriteCount={favoriteCount}
          isFavoriteDisplayed={isFavoriteDisplayed}
          onChange={onFilterChange}
          clearAllFavorite={clearAllFavorite}
          displaySystems={displayAllSystems}
          displayFavorite={displayFavoriteSystems}
          resetFilters={resetAllFilters}
          buttonColor={filterButtonColor}
        ></Filters>
        <div className="LoadSystem">
          <div className="LoadSystemSearch">
            <SelectSystemType
              systemTypes={categoryTypeOptions}
              selectedID={systemTypeID}
              onChange={(event: any) => {
                loadSystemsDetails(
                  event,
                  config,
                  categoryTypeOptions,
                  advanseSelectorData,
                  activeVersionData
                );
                if (isFavoriteDisplayed) {
                  displayAllSystems();
                }
                resetAllFilters();
                resetSearchFilter();
              }}
            />
            <SearchComponenet
              searchKeyWords={searchKeyWords}
              onAddKeyWord={addSearchKeyWord}
              onDeleteKeyWord={deleteSearchKeyWord}
              systemCount={
                favoriteDisplayed ? favoriteCount : displayedSystemsCount
              }
            />
            {/* <ShoppingCartComponentOld
              countSystem={cartCount}
              onClick={handleShowCartContent}
              disabled={cartCount <= 0}
            /> */}
            <ShoppingCartComponent
              countSystem={cartCount}
              onClick={handleShowCartContent}
              disabled={cartCount <= 0}
            />
          </div>
          <div
            className="LoadSystemCardContainer"
            onScroll={(event) => handleScrollSystems(event)}
          >
            {(loadingSystems || loadingSystemsDetails) &&
            advanseSelectorData ? (
              <CircularProgress className="LoadSystemCircularProgress" />
            ) : (
              <RenderCard
                products={systemsDetails}
                mappingConfig={advanseSelectorData}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                t={t}
              ></RenderCard>
            )}
          </div>
        </div>
      </div>
      <div className="LoadSystemButtonContainer">
        <Button
          className="loadActionButton dark-grey-color"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handleBackToLoadSystems()}
        >
          {t("CLOSE")}
        </Button>
        <Button
          className="loadActionButton yellow-color"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handleShowCartContent()}
          endIcon={<ShoppingCartIcon />}
          disabled={cartCount <= 0}
        >
          {t("SHOW_SELECTED_SYSTEM")}
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state: DrawStore) {
  return {
    systems: state.systems.systems,
    systemsDetails: state.systems.systemsDetails,
    loadingSystemsDetails: state.systems.loadingSystemsDetails,
    loadingSystems: state.systems.loadingSystems,
    displayedSystemsCount: state.systems.displayedSystemsCount,
    cartCount: state.cart.cartCount,
    favoriteCount: state.favorite.favoriteCount,
    favoriteDisplayed: state.systems.favoriteDisplayed,
    filters: state.filters.filters,
    systemTypeID: state.systems.systemTypeID,
    searchKeyWords: state.systems.searchKeyWords,
    config: state.config,
  };
}

let mapDispatchToProps = {
  selectWindowToShow,
  loadSystemsDetails,
  loadSystems,
  loadMoreSystemsDetails,
  setCurrentSystemDetail,
  filterSystemsDetails,
  changeFavoriteSystemType,
  loadFavorites,
  deleteSearchKeyWord,
  addSearchKeyWord,
  resetSearchFilter,
  loadFilters,
  applyFilters,
  displayFavorite,
  displaySystems,
  resetFilters,
  clearAllFavorite,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoadSystemContainerProps = PropsFromRedux & WithTranslation;

export default connector(
  withTranslation(["weberIndonesia"])(LoadSystemContainer)
);

interface RenderCardProps {
  products?: ProductDetailData[] | undefined;
  onShowDetails?: any;
  mappingConfig?: any;
  setOpenDialog?: any;
  openDialog?: any;
  t: any;
}
