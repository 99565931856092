import { TextField } from "@material-ui/core";
import React, { UIEvent, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import downloadIcon from "../../../../assets/download-icon.png";
import { highlightElementsInModel } from "../../../../helpers/utils";
import Element from "../../../../models/Element.model";
import "./looseElementsComponent.scss";

function LooseElementsComponent({
    looseElementsList,
    getElementName,
    openCatalog,
    t,
}: IProps) {
    const [searchValue, setSearch] = useState("");

    const [nbrElementsToShow, setNbrElementsToShow] = useState(20);

    // useEffect(() => {
    //     setNbrElementsToShow(20);
    // }, [searchValue, filters]);

    const handleScroll = (e: UIEvent) => {
        const closeToBottom =
            e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                e.currentTarget.clientHeight ===
            0;
        if (closeToBottom) {
            setNbrElementsToShow(nbrElementsToShow + 20);
        }
    };

    const search = () => {
        return looseElementsList
            .filter((element: Element) => {
                return getElementName(element)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
            })
            .splice(0, nbrElementsToShow);
    };

    const highlightElementsInModelLocal = () => {
        let elementsIdsList: string[] = [];
        elementsIdsList.push(
            ...looseElementsList.map((element: Element) => {
                return String(element.Id);
            })
        );

        highlightElementsInModel(elementsIdsList).then((result) => {
            console.log("done");
            console.log(result);
        });
    };

    return (
        <tbody className="w-100">
            <tr
                key="range-item-tr-no-compatible-elemnts"
                id="range-item-tr-no-compatible-elemnts"
                className="w-100"
            >
                <td className="range-component-td">
                    <div className="d-flex flex-column justify-content-between range-component-div">
                        <strong>{t("ELEMENTS_WITH_NO_RANGE_AVAILABLE")}</strong>
                    </div>
                </td>
                <td className="range-aviable-elements-td">
                    <span>
                        <strong>{looseElementsList.length}</strong>
                    </span>
                </td>
                <td className="range-filters-selectors-td">
                    <div className="row m-0">
                        <div
                            className="d-flex flex-column justify-content-center"
                            style={{
                                width: "40%",
                                borderRight: "2px solid whitesmoke",
                            }}
                        >
                            <div className="d-flex flex-column my-4">
                                <button
                                    id="loose-elements-highlight-in-model-btn"
                                    className="m-auto"
                                    onClick={() => {
                                        highlightElementsInModelLocal();
                                    }}
                                >
                                    <img
                                        src={downloadIcon}
                                        style={{
                                            width: "1.5em",
                                            height: "1.5em",
                                        }}
                                        alt="Highligh in model"
                                    ></img>
                                </button>
                                <span style={{ color: "#e5531b" }}>
                                    {" "}
                                    {t("HIGHLIGHT_IN_MODEL")}
                                </span>
                            </div>
                            <div className="d-flex flex-column my-4">
                                <button
                                    type="button"
                                    id="loose-elements-download-pam-objects"
                                    className="m-auto"
                                    onClick={() => {
                                        openCatalog();
                                    }}
                                >
                                    <img
                                        src={downloadIcon}
                                        style={{
                                            width: "1.5em",
                                            height: "1.5em",
                                        }}
                                        alt="Download PAM OBject"
                                    ></img>
                                </button>
                                <span style={{ color: "#e5531b" }}>
                                    {" "}
                                    {t("DOWNLOAD_PAM_OBJECTS")}
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "60%",
                                borderLeft: "2px solid whitesmoke",
                                border: "1px solid #505050",
                            }}
                        >
                            <div
                                className="col-12 d-flex flex-column justify-content-center"
                                style={{
                                    backgroundColor: "#505050",
                                    height: "3em",
                                }}
                            >
                                <TextField
                                    label={`${t("SEARCH")}...`}
                                    onChange={(e) => {
                                        setSearch(e.currentTarget.value);
                                    }}
                                    value={searchValue}
                                    variant="outlined"
                                />
                            </div>
                            <div
                                className="col-12"
                                onScroll={handleScroll}
                                style={{
                                    maxHeight: "18.9em",
                                    minHeight: "15em",
                                    overflowY: "auto",
                                }}
                            >
                                {search().map(
                                    (
                                        element: Element,
                                        elementIndex: number
                                    ) => {
                                        return (
                                            <div
                                                key={`element-range-selection-key-div-
                                                
                                                ${element.Id}`}
                                                // ${element.SystemType}-${element.SystemName}-
                                                className="row m-0"
                                            >
                                                <div className="col-12 text-left px-1 element-selection-element-text">
                                                    {getElementName(element)}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    );
}

type IProps = WithTranslation & {
    looseElementsList: Element[];
    getElementName: Function;
    openCatalog: Function;
};

export default withTranslation(["pam"])(LooseElementsComponent);
