import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Icon, Input, Button, Popup } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { ConfirmModal } from "../../CommonModules/PopUpModals/ConfirmModal";
import Moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faClone,
  faTrash,
} from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { GoArrowRight } from "react-icons/go";

interface State {}

const mapState = () => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  doc: any;
  openDocumentData: any;
  deleteDocumentData: any;
  cloneDocumentData: any;
  onChangeDocumentName: any;
  onDocumentSelect: any;
  onSaveDocumentName: any;
  selectedDocId: string;
  errorId: string;
  newName: string;
  wording: {
    cloneDocument: { [key: string]: string };
    editDocument: { [key: string]: string };
    deleteDocument: { [key: string]: string };
  };
  language: string;
};

export class SavedDocuments extends Component<Props, State> {
  state = {
    fieldError: null,
    confirmModal: false,
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  public async validateField(currName: any, prevName: any) {
    this.props.onSaveDocumentName(this.props.doc);

    if (currName.trim().length < 5) {
      this.setState({
        fieldError: "Texte de 5 caractères minimum",
      });
    } else {
      this.setState({
        fieldError: "Une autre sélection existe déjà sous ce nom",
      });
    }
    localStorage.setItem("isModification", "false");
  }

  onDeleteIconClick = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  editSelectionName = (Id: string, Name: string) => {
    this.setState({ editId: Id, editName: Name });
  };

  render() {
    Moment.locale("en");
    const convertDate = (d: any) => {
      let sp = d.split("/");
      let ret = [sp[0], sp[1], sp[2]].join("/");
      let dateformate: string = ret;
      if (sp[2] === 2022) {
        dateformate = [sp[0], sp[1], sp[2]].join("/");
      } else if (sp[2] === 2021 || sp[2] < 2021) {
        dateformate = [sp[1], sp[0], sp[2]].join("/");
      }
      return dateformate;
    };

    return (
      <div className="docRow" style={{ height: "auto" }}>
        <div
          className="rowVal"
          style={{
            display: "block",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          {this.props.selectedDocId === this.props.doc.documentId ? (
            <div>
              <Input
                type="text"
                placeholder="Enter Name..."
                value={this.props.newName}
                style={{ width: "auto" }}
                action
                onChange={(e) => {
                  this.props.onChangeDocumentName(e, this.props.doc);
                  localStorage.setItem("isModification", "true");
                }}
              >
                <input />
                <Popup
                  trigger={
                    <Button
                      icon
                      onClick={(e: any) =>
                        this.validateField(this.props.newName, this.props.doc)
                      }
                    >
                      <Icon name="check" />
                    </Button>
                  }
                  content="Valider la modification du nom"
                  on="hover"
                  inverted
                  size="mini"
                  position="bottom center"
                />
                <Popup
                  trigger={
                    <Button
                      icon
                      onClick={() => {
                        this.props.onDocumentSelect("", "");
                      }}
                    >
                      <Icon name="close" />
                    </Button>
                  }
                  content="Annuler la modification du nom"
                  on="hover"
                  inverted
                  size="mini"
                  position="bottom center"
                />
              </Input>
              {this.props.errorId === this.props.doc.documentId && (
                <div style={{ fontSize: "9px", color: "red" }}>
                  {this.state.fieldError}
                </div>
              )}
              {/* <Input
                className={
                  this.props.errorId === this.props.doc.documentId
                    ? "inputDocRow errorInput"
                    : "inputDocRow"
                }
                onChange={(e) =>
                  this.props.onChangeDocumentName(e, this.props.doc)
                }
                style={{ width: "100%" }}
                value={this.props.newName}
              />
              <span
                onClick={(e) => this.props.onSaveDocumentName(this.props.doc)}
              >
                <Icon name="save" />
              </span> */}
            </div>
          ) : (
            <Popup
              trigger={
                <span
                  onClick={() => this.props.onDocumentSelect(this.props.doc)}
                >
                  {this.props.doc.name}
                </span>
              }
              content="Cliquer pour modifier le nom"
              on="hover"
              inverted
              size="mini"
              position="bottom center"
            />
          )}
        </div>
        <div className="rowVal">
          {
            convertDate(this.props.doc.documentDate)
            // Moment(this.props.doc.documentDate).format("MM/DD/YYYY") !== "Invalid date" ? convertDate(this.props.doc.documentDate) : this.props.doc.documentDate
          }
        </div>
        {/* <div className="rowVal">{this.props.doc.documentDate}</div> */}
        <div className="iconCont">
          <span
            className="editIcon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.props.openDocumentData(this.props.doc.documentId)
            }
          >
            <Popup
              content={ReactHtmlParser(
                this.props.wording.editDocument[this.props.language]
              )}
              position="top left"
              inverted
              size="mini"
              trigger={<FontAwesomeIcon icon={faPencilAlt as IconProp} />}
            />
          </span>
          <span
            className="cloneIcon"
            style={{
              cursor: "pointer",
              marginLeft: 10,
            }}
            onClick={() =>
              this.props.cloneDocumentData(
                this.props.doc.documentId,
                this.props.doc.documentType
              )
            }
          >
            <Popup
              content={ReactHtmlParser(
                this.props.wording.cloneDocument[this.props.language]
              )}
              position="top center"
              inverted
              size="mini"
              trigger={<FontAwesomeIcon icon={faClone as IconProp} />}
            />
          </span>

          {/* Delete */}
          <span
            className="trashIcon"
            style={{
              cursor: "pointer",
              marginLeft: 10,
            }}
            onClick={this.onDeleteIconClick}
          >
            <Popup
              content={ReactHtmlParser(
                this.props.wording.deleteDocument[this.props.language]
              )}
              position="top right"
              inverted
              size="mini"
              trigger={<FontAwesomeIcon icon={faTrash as IconProp} />}
            />
          </span>

          {/* DAE */}
          <span
            style={{
              marginLeft: 10,
              display:
                this.props.doc.documentType === "DAE" ? "none" : "inline-flex",
              cursor: "pointer",
            }}
            onClick={() =>
              this.props.cloneDocumentData(this.props.doc.documentId, "DAE")
            }
          >
            <Popup
              // content={ReactHtmlParser(
              //   this.props.wording.cloneDocument[this.props.language]
              // )}
              content="Convertir en DAE"
              on="hover"
              position="top right"
              inverted
              size="mini"
              trigger={
                <span className="arrowicon">
                  <GoArrowRight style={{ marginRight: -4 }} />
                  <span className="fontBebasIcon">DAE</span>
                </span>
              }
            ></Popup>
          </span>

          {/* DOE */}
          <span
            className=""
            style={{
              marginLeft: 10,
              display:
                this.props.doc.documentType === "DOE" ? "none" : "inline-flex",
              cursor: "pointer",
            }}
            onClick={() =>
              this.props.cloneDocumentData(this.props.doc.documentId, "DOE")
            }
          >
            <Popup
              // content={ReactHtmlParser(
              //   this.props.wording.cloneDocument[this.props.language]
              // )}
              content="Convertir en DOE"
              on="hover"
              position="top right"
              inverted
              size="mini"
              trigger={
                <span className="arrowicon">
                  <GoArrowRight style={{ marginRight: -4 }} />
                  <span className="fontBebasIcon">DOE</span>
                </span>
              }
            ></Popup>
          </span>

          {/* DAO */}
          <span
            className=""
            style={{
              marginLeft: 10,
              display:
                this.props.doc.documentType === "DAO" ? "none" : "inline-flex",
              cursor: "pointer",
            }}
            onClick={() =>
              this.props.cloneDocumentData(this.props.doc.documentId, "DAO")
            }
          >
            <Popup
              // content={ReactHtmlParser(
              //   this.props.wording.cloneDocument[this.props.language]
              // )}
              content="Convertir en DAO"
              on="hover"
              position="top right"
              inverted
              size="mini"
              trigger={
                <span className="arrowicon">
                  <GoArrowRight style={{ marginRight: -4 }} />
                  <span className="fontBebasIcon">DAO</span>
                </span>
              }
            ></Popup>
          </span>
        </div>
        <ConfirmModal
          confirmModal={this.state.confirmModal}
          no="NON"
          yes="OUI"
          description="Voulez vous vraiment supprimer ce document?"
          header="Supprimer la Document"
          onYesAction={() =>
            this.props.deleteDocumentData(this.props.doc.documentId)
          }
          onNoAction={() => this.onDeleteIconClick()}
        />
      </div>
    );
  }
}

export default connector(SavedDocuments);
