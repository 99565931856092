import React from "react";
import { TypeItem } from "./TypeItem";

export const TypeItems = (props) => {
    return props.typesData
        ? props.typesData.map((item) => (
              <TypeItem
                  selectedLevels={props.selectedLevels}
                  selectType={props.selectType}
                  key={item.Type}
                  type={item.Type}
                  count={item.Count}
                  checked={item.Checked}
                  color={props.color}
              />
          ))
        : null;
};

TypeItem.defaultProps = {
    typesData: [],
};
