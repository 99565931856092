import { filter, find, forEach, includes, map, uniqBy, concat } from "lodash";
import {
  fetchPlacoDataIncludeChildern,
  fetchPlacoDataIncludeRelatedObjects,
} from "../../../../API/request";
import { CategoryMap } from "../Extract";
import {
  packagedArticleLevelFilter,
  packagedArticleLevelFilterCeiling,
  productArticleLevelFilter,
  productArticleLevelFilterCeiling,
} from "./placoHelper";

interface slProductType {
  context: {
    attributeDependencies: any;
    objectDependencies: any;
    relations: any;
  };
  message: null;
  objects: any;
}

const slProductInitialState = {
  context: {
    attributeDependencies: [
      { attributes: [], className: "SGObjectAttribute" },
      { attributes: [], className: "RelationAttribute" },
    ],
    objectDependencies: [],
    relations: [],
  },
  message: null,
  objects: [],
};

export const fetchPlacoCeilingDataInGroupSplit = async (
  uniqSystemsOids: string[],
  config: any
) => {
  let grpRelatedData: slProductType = slProductInitialState;

  const groupResponseIncludeRelatedObjects: any =
    await fetchPlacoDataIncludeRelatedObjects(uniqSystemsOids, config);

  forEach(groupResponseIncludeRelatedObjects, (relDat, ind) => {
    grpRelatedData.context.attributeDependencies[0].attributes = uniqBy(
      concat(
        grpRelatedData.context.attributeDependencies[0].attributes,
        relDat.context.attributeDependencies[0].attributes
      ),
      "aid"
    );
    grpRelatedData.context.attributeDependencies[1].attributes = uniqBy(
      concat(
        grpRelatedData.context.attributeDependencies[1].attributes,
        relDat.context.attributeDependencies[1].attributes
      ),
      "aid"
    );
    grpRelatedData.context.objectDependencies = uniqBy(
      concat(
        grpRelatedData.context.objectDependencies,
        relDat.context.objectDependencies
      ),
      "className"
    );
    grpRelatedData.context.relations = uniqBy(
      concat(grpRelatedData.context.relations, relDat.context.relations),
      "rid"
    );
    grpRelatedData.objects = uniqBy(
      concat(grpRelatedData.objects, relDat.objects),
      "oid"
    );
  });

  const filteredProduct = filter(grpRelatedData.objects, (object) => {
    if (object.types[0] === "Product") {
      return true;
    }
    return false;
  });

  const prodcutForPackandArticle = map(filteredProduct, "oid");

  const grpResponseIncludeChildren: any = await fetchPlacoDataIncludeChildern(
    prodcutForPackandArticle,
    config
  );

  forEach(grpResponseIncludeChildren, (relDat, ind) => {
    grpRelatedData.context.attributeDependencies[0].attributes = uniqBy(
      concat(
        grpRelatedData.context.attributeDependencies[0].attributes,
        relDat.context.attributeDependencies[0].attributes
      ),
      "aid"
    );
    grpRelatedData.context.attributeDependencies[1].attributes = uniqBy(
      concat(
        grpRelatedData.context.attributeDependencies[1].attributes,
        relDat.context.attributeDependencies[1].attributes
      ),
      "aid"
    );
    grpRelatedData.context.objectDependencies = uniqBy(
      concat(
        grpRelatedData.context.objectDependencies,
        relDat.context.objectDependencies
      ),
      "className"
    );
    grpRelatedData.context.relations = uniqBy(
      concat(grpRelatedData.context.relations, relDat.context.relations),
      "rid"
    );
    grpRelatedData.objects = uniqBy(
      concat(grpRelatedData.objects, relDat.objects),
      "oid"
    );
  });

  const extractedSolutionProduct: any[] = [];

  if (grpRelatedData) {
    const objects = grpRelatedData.objects;
    const contextAttributes =
      grpRelatedData.context.attributeDependencies[0].attributes;
    const relationAttributes =
      grpRelatedData.context.attributeDependencies[1].attributes;
    const contextRelations = grpRelatedData.context.relations;

    const solutionProducts = objects.filter(
      (obj: any) => obj.types[0] === "Solution Product"
    );

    const articles = objects.filter((obj: any) => obj.types[0] === "Article");

    const packagedArticles = objects.filter(
      (obj: any) => obj.types[0] === "Packaged Article"
    );

    await forEach(solutionProducts, async (solutionProduct, spIndex) => {
      let layoutPossible = "true"; //productLayoutPossible.values[0].value.toLowerCase();

      let installationType = false;

      const installationTypeObject = find(solutionProduct.attributes, {
        technicalName: "GFR-Installation type",
      });

      if (installationTypeObject) {
        if (
          installationTypeObject.values[0].value.toLowerCase() === "suspendu"
        ) {
          installationType = true;
        }
      }

      /// Temp fix
      if (!includes(solutionProduct.translation, "portée")) {
        installationType = true;
      }

      let supportCeiling = false;

      let supportCeilingValid = [
        "Charpente bois ou métallique",
        "Plancher bois",
        "Charpente bois ou métallique",
        "Plancher béton",
        "poutrelles hourdis béton",
        "planchers mixtes non collaborants",
      ];

      const supportCeilingTypeObject = find(solutionProduct.attributes, {
        technicalName: "GFR-Support (ceiling)",
      });

      if (supportCeilingTypeObject) {
        if (
          includes(
            supportCeilingValid,
            supportCeilingTypeObject.values[0].value
          )
        ) {
          supportCeiling = true;
        }
      }

      if (layoutPossible === "true") {
        const spOid = solutionProduct.oid;
        const spRelations = filter(contextRelations, { oid1: spOid });
        const mapSpRelationIdsOfProducts = map(spRelations, "oid2");
        const products = filter(objects, function (o) {
          return includes(mapSpRelationIdsOfProducts, o.oid);
        });

        const extractedProducts: any[] = [];

        forEach(products, async (product, productIndex) => {
          const productRSCategoryPath = find(product.attributes, {
            technicalName: "RS-CategoryPath",
          });
          const filteredCategoryPath = find(CategoryMap, function (o) {
            return includes(productRSCategoryPath.values[0].value, o.category);
          });

          const productValid = productArticleLevelFilter(product.attributes);
          let extractedArticles: any[] = [];

          if (filteredCategoryPath?.type === "wool") {
            const productArticles = filter(articles, function (o) {
              return includes(o.parentOids, product.oid);
            });
            forEach(productArticles, (productArticle, prArticleIndex) => {
              const validArticle = productArticleLevelFilterCeiling(
                productArticle.attributes
              );
              if (validArticle) {
                const selectedPackagedArticle = filter(
                  packagedArticles,
                  (packArticle) => {
                    const isArticleObject = find(packArticle.attributes, {
                      technicalName: "A-Article Name",
                    });

                    const packArticleValid = packagedArticleLevelFilterCeiling(
                      packArticle.attributes
                    );

                    if (packArticleValid) {
                      if (isArticleObject) {
                        return (
                          isArticleObject.values[0].value ===
                          productArticle.translation
                        );
                      }
                    }
                    return false;
                  }
                );

                if (selectedPackagedArticle) {
                  const articleLength = find(productArticle.attributes, {
                    technicalName: "GFR-Length of the UB unpacked (m)",
                  });

                  forEach(selectedPackagedArticle, (pkArticle) => {
                    extractedArticles.push({
                      value: pkArticle.translation,
                      text: pkArticle.translation,
                      key: pkArticle.translation,
                      height: articleLength.values[0].value,
                      article: productArticle,
                      placo100: productValid,
                      packagedArticle: pkArticle,
                    });
                  });
                }
              }
            });

            extractedProducts.push({
              product: product,
              articles: extractedArticles,
              type: filteredCategoryPath?.type,
            });
          } else if (productValid) {
            const productArticles = filter(articles, function (o) {
              return includes(o.parentOids, product.oid);
            });

            if (filteredCategoryPath) {
              forEach(productArticles, (productArticle, prArticleIndex) => {
                const validArticle = productArticleLevelFilter(
                  productArticle.attributes
                );
                if (validArticle) {
                  if (filteredCategoryPath?.type === "plaque") {
                    const selectedPackagedArticle = filter(
                      packagedArticles,
                      (packArticle) => {
                        const isArticleObject = find(packArticle.attributes, {
                          technicalName: "A-Article Name",
                        });

                        const packArticleValid = packagedArticleLevelFilter(
                          packArticle.attributes
                        );
                        if (packArticleValid) {
                          if (isArticleObject) {
                            return (
                              isArticleObject.values[0].value ===
                              productArticle.translation
                            );
                          }
                        }
                        return false;
                      }
                    );

                    if (selectedPackagedArticle) {
                      const articleLength = find(productArticle.attributes, {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      });

                      forEach(selectedPackagedArticle, (pkArticle) => {
                        extractedArticles.push({
                          value: pkArticle.translation,
                          text: pkArticle.translation,
                          key: pkArticle.translation,
                          height: articleLength.values[0].value,
                          article: productArticle,
                          placo100: productValid,
                          packagedArticle: pkArticle,
                        });
                      });
                    }
                  } else if (filteredCategoryPath?.type === "montant") {
                    const selectedPackagedArticle = filter(
                      packagedArticles,
                      (packArticle) => {
                        const isArticleObject = find(packArticle.attributes, {
                          technicalName: "A-Article Name",
                        });

                        const packArticleValid = packagedArticleLevelFilter(
                          packArticle.attributes
                        );

                        if (packArticleValid) {
                          if (isArticleObject) {
                            return (
                              isArticleObject.values[0].value ===
                              productArticle.translation
                            );
                          }
                        }
                        return false;
                      }
                    );

                    if (selectedPackagedArticle) {
                      const articleLength = find(productArticle.attributes, {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      });

                      forEach(selectedPackagedArticle, (pkArticle) => {
                        extractedArticles.push({
                          value: pkArticle.translation,
                          text: pkArticle.translation,
                          key: pkArticle.translation,
                          height: articleLength.values[0].value,
                          article: productArticle,
                          placo100: productValid,
                          packagedArticle: pkArticle,
                        });
                      });
                    }
                  } else {
                    const selectedPackagedArticle = filter(
                      packagedArticles,
                      (packArticle) => {
                        const isArticleObject = find(packArticle.attributes, {
                          technicalName: "A-Article Name",
                        });

                        // const packArticleValid = packagedArticleLevelFilter(
                        //   packArticle.attributes
                        // );

                        // if (packArticleValid) {
                        if (isArticleObject) {
                          return (
                            isArticleObject.values[0].value ===
                            productArticle.translation
                          );
                        }
                        // }
                        return false;
                      }
                    );

                    if (selectedPackagedArticle) {
                      const articleLength = find(productArticle.attributes, {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      });

                      forEach(selectedPackagedArticle, (pkArticle) => {
                        extractedArticles.push({
                          value: pkArticle.translation,
                          text: pkArticle.translation,
                          key: pkArticle.translation,
                          height: articleLength.values[0].value,
                          article: productArticle,
                          placo100: productValid,
                          packagedArticle: pkArticle,
                        });
                      });
                    }
                  }
                }
              });

              extractedProducts.push({
                product: product,
                articles: extractedArticles,
                type: filteredCategoryPath?.type,
              });
            }

            // For checking plaque was present or not
            if (
              extractedArticles.length === 0 &&
              filteredCategoryPath?.type === "plaque"
            ) {
              layoutPossible = "false";
            }
          }
        });

        extractedSolutionProduct.push({
          solutionProduct: solutionProduct,
          products: extractedProducts,
          layoutPossible: layoutPossible,
          attributes: contextAttributes,
          relationAttributes: relationAttributes,
          relations: contextRelations,
          installationType: installationType,
          supportCeiling: supportCeiling,
        });
      } else {
        extractedSolutionProduct.push({
          solutionProduct: solutionProduct,
          products: null,
          layoutPossible: layoutPossible,
          attributes: contextAttributes,
          relationAttributes: relationAttributes,
          relations: contextRelations,
          installationType: installationType,
          supportCeiling: supportCeiling,
        });
      }
    });
  }

  return { extractedSolutionProduct };
};
