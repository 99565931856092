import React from "react";
import SelectionTableHeader from "./SelectionTableHeader";
import SelectionTableRow from "./SelectionTableRow";
import { map } from "lodash";
import { getDateToCompare } from "../../../../Utils";

const style = {
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200)",
    height: "calc(100vh - 195px)",
    overflowY: "auto" as const,
  },
};

interface Props {
  data: any;
  register: any;
  selectionId: any;
  multiSelection?:any;
}

export const SelectionTable = (props: Props) => {
  const { data, register, selectionId,multiSelection=false } = props;
  
  return (
    <>
      <SelectionTableHeader />
      <div style={style.tableSegment_body}>
        {data
        .slice()
        .sort((a:any, b:any) => getDateToCompare(b.Date) - getDateToCompare(a.Date))
        .map((dat: any, key: any) => (
          <SelectionTableRow
            data={dat}
            register={register}
            selectionId={selectionId}
            key={key}
            multiSelection={multiSelection}
          />
        ))}
      </div>
    </>
  );
};

export default SelectionTable;
