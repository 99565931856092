import React, { Component } from "react";
import { Container, Button, Loader, Dimmer } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
  setSelectionTree,
  initGroupSelector,
  initGroupSelectorCeiling,
} from "../Actions";
import { Routes } from "./root";
import { selectedItemsManualSelection } from "../../../../RevitJS/Helpers";
import { WithTranslation, withTranslation } from "react-i18next";

const eraseSelection = async (tree: any) => {
  try {
    const idsToDelete = [].concat.apply(
      [],
      tree.map((e: any) => e.Ids)
    );
    const nb_walls = idsToDelete.length;
    for (let i = 0; i < nb_walls; i++) {
      await api.familyEditor.setParams([
        {
          Id: idsToDelete[i],
          Params: [{ Name: "Processed", Type: "YesNo", Value: 0 }],
        },
      ]);
      await api.familyEditor.setParams([
        {
          Id: idsToDelete[i],
          Params: [{ Name: "SG_System", Type: "Text", Value: " " }],
        },
      ]);
      const elems = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: {
              Name: "id",
              Type: "Integer",
              Value: parseInt(idsToDelete[i]),
            },
            Rule: "Equals",
          },
        ],
        null
      );
      const albaElems = await api.queries.filterElements(
        "Wall",
        [
          {
            Param: {
              Name: "AlbaId",
              Type: "Text",
              Value: idsToDelete[i],
            },
            Rule: "Equals",
          },
        ],
        null
      );
      await api.familyEditor.deleteElementsByIds(elems.concat(albaElems));
    }
    await sendEvent();
  } catch (e) {
    console.log("Error in Delete Rigips!");
  }
  api.windowsHandler.closeWindow();
};

const sendEvent = async () => {
  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Delete",
    module: "RIGIPSBIM",
  });
};

interface Props {
  Icon: string;
  name: string;
  setRoute: any;
  setSelectionTree: any;
  initGroupSelector: any;
  initGroupSelectorCeiling: any;
}

interface State {
  erasing: boolean;
}

export class SelectionLanding extends Component<
  Props & WithTranslation,
  State
> {
  state = {
    erasing: false,
  };

  // public static defaultProps={
  // wording:{
  //     selection:{
  //         "French": "Sélection",
  //         "English": "Selection",
  //         "German": "Auswahl",
  //         "Italian": "Selezione"
  //     },
  //     manualSelection:{
  //         "French": "Sélection Manuelle",
  //         "English": "Manual Selection",
  //         "German": "Manuelle Auswahl",
  //         "Italian": "Selectionz manuale"
  //     },
  //     groupSelection:{
  //         "French": "Sélection groupée",
  //         "English": "Group selection",
  //         "German": "Gruppenauswahl",
  //         "Italian": "Selezione del gruppo"
  //     },
  //     deletePending:{
  //         "French": "Suppression en cours",
  //         "English": "Deleting",
  //         "German": "Löschen",
  //         "Italian": "La cancellazione in corso"
  //     }
  // }
  // }

  componentDidMount = () => {
    api.windowsHandler.resizeWindow(600, 200);
  };

  manualSelectionHandler = async () => {
    api.windowsHandler.hideWindow();
    let tree = await api.selection.manualSelection("wall&ceiling");
    this.setState({
      erasing: true,
    });
    await api.windowsHandler.showWindow();
    let selectedItems = selectedItemsManualSelection(tree);
    eraseSelection(selectedItems);
  };

  groupSelectionHandler = async () => {
    let tree = await api.selection.elementsByLevelAndType("wall");
    let treeCeiling = await api.selection.elementsByLevelAndType("ceiling");
    this.props.initGroupSelector(tree);
    this.props.initGroupSelectorCeiling(treeCeiling);
    this.props.setRoute(Routes.GROUP_SELECTION);
  };

  render() {
    const { t } = this.props;

    if (this.state.erasing) {
      return (
        <Dimmer active={true}>
          <Loader content={t("deletePending")} />
        </Dimmer>
      );
    }
    return (
      <div id="delete-selectionLanding-container">
        <FunctionalityHeader
          Icon={this.props.Icon}
          name={t("selection")}
          width={100}
          height={40}
        />
        <Container textAlign="center" style={{ margin: "auto" }}>
          <Button
            onClick={this.manualSelectionHandler}
            primary
            size="big"
            style={{ marginRight: "5%" }}
          >
            {t("manualSelection")}
          </Button>
          <Button
            onClick={this.groupSelectionHandler}
            primary
            size="big"
            style={{ marginLeft: "5%" }}
          >
            {t("groupSelection")}
          </Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    name: ownProps.name,
    setRoute: ownProps.setRoute,
  };
};

export default connect(mapStateToProps, {
  setSelectionTree,
  initGroupSelector,
  initGroupSelectorCeiling,
})(withTranslation("riggibs")(SelectionLanding));
