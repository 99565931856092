import Excel from "exceljs";
import {
  concat,
  defaultTo,
  round,
  find,
  forEach,
  includes,
  map,
  reduce,
  uniq,
} from "lodash";
import { CeilingData } from "../../../../RevitJS/Types/RevitTypes";

export const overviewByProduct = async (
  overviewByRoomSheet: Excel.Worksheet,
  ceilingDetails: CeilingData[]
) => {
  const ceilingDetailsByLevelNProduct = reduce(
    ceilingDetails,
    function (result: any, value: CeilingData, key: any) {
      const lvname = value.LevelName || "";

      if (!result[lvname]) {
        result[lvname] = {};
      }

      const prd = value.ProductName || "";
      if (!result[lvname][prd]) {
        result[lvname][prd] = [];
      }

      result[lvname][prd].push(value);
      return result;
    },
    {}
  );

  let startingRow: number = 11;

  forEach(ceilingDetailsByLevelNProduct, (levelValue, levelKey) => {
    if (Object.keys(levelValue).join("") !== "") {
      let roomWithMultipleCeiling = reduce(
        ceilingDetails,
        function (result: any, value: any, key: any) {
          const roomOrCeiling =
            value.RoomName === null || value.RoomName === ""
              ? value.Id
              : value.RoomName;
          (result[roomOrCeiling] || (result[roomOrCeiling] = [])).push(
            parseFloat(value.Id)
          );
          return result;
        },
        {}
      );
      const levelHeaderRow = startingRow;

      startingRow = startingRow + 1;

      let tableRow: any = [];

      const tableHeaderRow: any = [];

      tableHeaderRow.push({
        name: "Ecophon product",
        row: startingRow,
        column: 1,
      });

      let productCeilingData: any = {};
      let ceilingHavingProduct: string[] = [];

      map(levelValue, (productValue, productKey) => {
        if (productKey !== "") {
          let ceilingTotalArea: any = {};
          forEach(roomWithMultipleCeiling, (ceid, index) => {
            let totalArea = 0;
            forEach(ceid, (celId, index) => {
              const ceObject: CeilingData = find(productValue, {
                Id: celId.toString(),
              });
              if (ceObject) {
                totalArea = ceObject.Area
                  ? totalArea + round(ceObject.Area, 0)
                  : totalArea;
              }
            });

            if (totalArea !== 0) {
              ceilingTotalArea[index] = totalArea;
              ceilingHavingProduct.push(index);
            }
          });
          productCeilingData[productKey] = ceilingTotalArea;
        }
      });

      let futureColumnIndex = 2;
      ceilingHavingProduct = uniq(ceilingHavingProduct);
      forEach(ceilingHavingProduct, (ceilingName, index) => {
        tableHeaderRow.push({
          name: ceilingName,
          row: startingRow,
          column: futureColumnIndex,
        });
        futureColumnIndex = futureColumnIndex + 1;
      });

      startingRow = startingRow + 1;
      map(levelValue, (productValue, productKey) => {
        if (productKey !== "") {
          tableRow.push({
            name: productKey,
            row: startingRow,
            column: 1,
          });
          let columnStart = 2;
          forEach(ceilingHavingProduct, (ceilingName, index) => {
            const cellObject = {
              name: productCeilingData[productKey][ceilingName],
              row: startingRow,
              column: columnStart,
            };
            tableRow.push(cellObject);
            columnStart = columnStart + 1;
          });
          startingRow = startingRow + 1;
        }
      });

      overviewByRoomSheet.mergeCells(
        levelHeaderRow,
        1,
        levelHeaderRow,
        ceilingHavingProduct.length + 1
      );
      overviewByRoomSheet.getRow(levelHeaderRow).getCell("A").value = levelKey;
      overviewByRoomSheet.getRow(levelHeaderRow).getCell("A").font = {
        name: "Arial",
        size: 10,
        bold: true,
        color: { argb: "000000" },
      };
      overviewByRoomSheet.getRow(levelHeaderRow).getCell("A").border = {
        bottom: { style: "thin" },
      };

      overviewByRoomSheet.getRow(levelHeaderRow).getCell("A").alignment = {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      };
      overviewByRoomSheet.getRow(levelHeaderRow).getCell("A").fill = {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "FFE000" },
      };

      forEach(tableHeaderRow, (tbHead, index) => {
        overviewByRoomSheet.getRow(tbHead.row).getCell(tbHead.column).value =
          tbHead.name;

        if (typeof tbHead.name !== "string") {
          overviewByRoomSheet
            .getRow(tbHead.row)
            .getCell(tbHead.column).model.type = 2;
          overviewByRoomSheet.getRow(tbHead.row).getCell(tbHead.column).numFmt =
            "0";
        }
      });

      forEach(tableRow, (tbHead, index) => {
        overviewByRoomSheet.getRow(tbHead.row).getCell(tbHead.column).value =
          tbHead.name;
      });

      setHeaderStyle(overviewByRoomSheet, tableHeaderRow);

      forEach(tableRow, (tbRow, key) => {
        overviewByRoomSheet.getRow(tbRow.row).getCell(tbRow.column).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FFFFFF",
          },
        };

        overviewByRoomSheet.getRow(tbRow.row).getCell(tbRow.column).border = {
          bottom: { style: "thin" },
        };

        overviewByRoomSheet.getRow(tbRow.row).getCell(tbRow.column).alignment =
          {
            horizontal: tbRow.column === 1 ? "left" : "center",
            vertical: "middle",
            wrapText: tbRow.column === 1 ? false : true,
          };

        overviewByRoomSheet.getRow(tbRow.row).getCell(tbRow.column).font = {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        };
        //});

        overviewByRoomSheet.getRow(tbRow.row).height = 20;

        overviewByRoomSheet.getRow(tbRow.row).commit();
      });

      startingRow = startingRow + 1;
    }
  });

  buildProjectInfoSection(overviewByRoomSheet);

  overviewByRoomSheet.properties.defaultRowHeight = 22;
  overviewByRoomSheet.getColumn("A").width = 42;

  forEach(ceilingDetails, (value, index) => {
    overviewByRoomSheet.getColumn(index + 2).width = 17.82;
  });
};

export const setHeaderStyle = async (
  overviewByRoomSheet: Excel.Worksheet,
  celReferences: any,
  color = "FFE000"
) => {
  celReferences.forEach((element: any) => {
    if (element.merge) overviewByRoomSheet.mergeCells(element.merge);
    overviewByRoomSheet.getRow(element.row).getCell(element.column).value =
      element.name;
    overviewByRoomSheet.getRow(element.row).getCell(element.column).alignment =
      {
        vertical: "middle",
        horizontal: element.column === 1 ? "left" : "center",
        wrapText: true,
      };
    overviewByRoomSheet.getRow(element.row).getCell(element.column).font = {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: element.fontColor || "000000" },
    };
    overviewByRoomSheet.getRow(element.row).getCell(element.column).fill = {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: color },
    };
  });
};

export const setProjectInfo = async (
  overviewByRoomSheet: Excel.Worksheet,
  celReferences: any,
  color = "FFE000"
) => {
  celReferences.forEach((element: any) => {
    if (element.merge) overviewByRoomSheet.mergeCells(element.merge);
    overviewByRoomSheet.getCell(element.ref).value = element.name;
    overviewByRoomSheet.getCell(element.ref).alignment = element.alignment;
    overviewByRoomSheet.getCell(element.ref).font = {
      name: "Arial",
      size: element.fontSize,
      bold: false,
      color: { argb: element.fontColor || "000000" },
    };
    overviewByRoomSheet.getCell(element.ref).border =
      element.cellBorder > 0
        ? {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          }
        : {};
  });
};

const buildProjectInfoSection = (overviewByRoomSheet: Excel.Worksheet) => {
  let projectInfo = [
    {
      ref: "A1",
      merge: "A1:H1",
      name: "CLT4BIM",
      fontSize: 20,
      cellBorder: 0,
      alignment: {
        vertical: "bottom",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
    {
      ref: "A2",
      merge: "A2:H2",
      name: "Qty take off",
      fontSize: 12,
      cellBorder: 0,
      alignment: {
        vertical: "top",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
    {
      ref: "A3",
      name: "Project name",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A4",
      name: "Project code",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A5",
      name: "Project total area",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A6",
      name: "Region/country",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A7",
      name: "City",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A8",
      name: "Date",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "B3",
      merge: "B3:D3",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B4",
      merge: "B4:D4",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B5",
      merge: "B5:D5",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B6",
      merge: "B6:D6",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B7",
      merge: "B7:D7",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B8",
      merge: "B8:D8",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "E3",
      merge: "E3:F3",
      name: "Project handled by",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "E4",
      merge: "E4:F4",
      name: "ASM in charge",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "E5",
      merge: "E5:F5",
      name: "Ceiling Contractor",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "E6",
      merge: "E6:F6",
      name: "N°",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "G3",
      merge: "G3:H3",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "G4",
      merge: "G4:H4",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "G5",
      merge: "G5:H5",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "G6",
      merge: "G6:H6",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
  ];

  setProjectInfo(overviewByRoomSheet, projectInfo);

  overviewByRoomSheet.getRow(1).height = 40;
  overviewByRoomSheet.getRow(2).height = 25;
  // overviewByRoomSheet.getColumn("A").width = 42;

  for (let index = 3; index <= 11; index++) {
    overviewByRoomSheet.getRow(index).height = 20;
  }
};

const buildMainTableSection = (
  overviewByRoomSheet: Excel.Worksheet,
  ceilingDetails: any
) => {
  let headersMainBoard = [
    { ref: "A11", name: "Level", width: 10.36 },
    { ref: "B11", name: "Room number", width: 15.18 },
    { ref: "C11", name: "Room name", width: 26.91 },
    { ref: "D11", name: "System Type", width: 26.91 },
    { ref: "E11", name: "Ecophon product", width: 42 },
    { ref: "F11", name: "Area (m2)", width: 14.18 },
    { ref: "G11", name: "Room perimeter (m)", width: 20.91 },
    { ref: "H11", name: "Ceiling height (m)", width: 18.64 },
  ];

  let totalCeilingArea = 0;
  const overviewByRoomData = map(ceilingDetails, (ceiling, index) => {
    totalCeilingArea = totalCeilingArea + round(defaultTo(ceiling.Area, 0), 0);

    return [
      ceiling.LevelName,
      parseFloat(ceiling.Id),
      "",
      ceiling.CeilingType,
      "",
      ceiling.Area ? round(ceiling.Area, 0) : "",
      ceiling.Perimeter ? round(ceiling.Perimeter, 0) : "",
      ceiling.Height ? round(ceiling.Height, 0) : "",
    ];
  });

  overviewByRoomSheet.addTable({
    name: "OverviewByRoom",
    ref: "A11",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: undefined,
      showRowStripes: false,
    },
    columns: headersMainBoard,
    rows: overviewByRoomData,
  });

  const mainTableHeaders = setHeaderStyle(
    overviewByRoomSheet,
    headersMainBoard
  );

  overviewByRoomSheet.properties.defaultRowHeight = 20;

  for (let index = 12; index <= overviewByRoomData.length + 12; index++) {
    let row = overviewByRoomSheet.getRow(index);
    row.height = 20;

    row.eachCell(function (cell: any, colNumber: number) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FFFFFF",
        },
      };

      cell.border = {
        bottom: { style: "thin" },
      };

      cell.alignment = {
        horizontal: includes([2, 6, 7, 8], colNumber) ? "center" : "left",
        vertical: "middle",
        wrapText: true,
      };

      cell.font = {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      };
    });

    row.commit();
  }

  let ceilingAreaRow = overviewByRoomData.length + 15;
  totalAreaSection(overviewByRoomSheet, totalCeilingArea, ceilingAreaRow);
};

const totalAreaSection = (
  overviewByRoomSheet: Excel.Worksheet,
  totalCeilingArea: number,
  ceilingAreaRow: number
) => {
  let ceilingAreaNameColumn = `A${ceilingAreaRow}:B${ceilingAreaRow}`;
  overviewByRoomSheet.mergeCells(ceilingAreaNameColumn);
  overviewByRoomSheet.getCell(`A${ceilingAreaRow}`).value =
    "TOTAL Ecophon Ceiling area";
  overviewByRoomSheet.getCell(`A${ceilingAreaRow}`).font = {
    name: "Arial",
    size: 10,
    bold: true,
    color: { argb: "000000" },
  };
  overviewByRoomSheet.getCell(`A${ceilingAreaRow}`).border = {
    bottom: { style: "thin" },
  };

  overviewByRoomSheet.getCell(`C${ceilingAreaRow}`).border = {
    bottom: { style: "thin" },
  };

  overviewByRoomSheet.getCell(`C${ceilingAreaRow}`).value = totalCeilingArea;
};
