import { type } from "os"
import {
    SET_RESET_PRODUCTS_LISTS,
    SetResetProductsListActionType,
    SetPimProductsListActionType,
    SET_PIM_PRODUCTS_LISTS,
    SetProductsListActionType,
    SET_PRODUCTS_LISTS,
    SetProductFiltersActionType,
    SET_PRODUCTS_FILTERS
} from "../Types"

export const SetResetProductsList = (): SetResetProductsListActionType => {
    return {
        type: SET_RESET_PRODUCTS_LISTS
    }
}

export const setPimProductsList = (data: any): SetPimProductsListActionType => {
    return {
        type: SET_PIM_PRODUCTS_LISTS,
        pimproductList: data
    }
}

export const setProductsList = (data: any): SetProductsListActionType => {
    return {
        type: SET_PRODUCTS_LISTS,
        productList: data
    }
}

export const setProductFilters = (data: any): SetProductFiltersActionType => {
    return {
        type: SET_PRODUCTS_FILTERS,
        productFilters: data
    }
}
