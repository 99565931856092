import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "984a91d1-6216-44d8-8625-c2807e6a55e4";

export const miterJointTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const FacadeQuantitatiffRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const Quantitatifs: Functionality = {
  Name: {
    English: "Quantitatifs",
    Indonesian: "Quantitatifs",
  },
  Trigger: miterJointTrigger,
  ShortDesc: {
    English: "Extraction des matériaux",
    Indonesian: "Extraction des matériaux",
  },
  Id: functionalityId,
  Icon: "/Facade/quantitatifs.png",
  RootComponent,
  ParentId: "0",
};
