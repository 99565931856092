import { Language } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import {
  Tree,
  TypeData,
  LevelData,
  GroupSelectedItem,
  Filter,
  PIMLayoutAttributesAidForNomenclature,
} from "../Actions/types";
import _ from "lodash";
import { placoFilters } from "../Resources/placoFilters";
import { config } from "../../../CLT/Delete/Reducers";

export const language = (
  language: Language = "French",
  action: any
): Language => {
  switch (action.type) {
    default:
      return language;
  }
};

export const tree = (
  tree: Tree = { Wall: [], Level: [], Ceiling: [] },
  action: any
) => {
  switch (action.type) {
    case "SET_TREE":
      return action.tree;
    default:
      return tree;
  }
};

export const selectedTypes = (state: any = new Map(), action: any) => {
  switch (action.type) {
    case "SELECT_TYPES":
      console.log(action);
      return state.set(action.item, action.isChecked);
    case "SELECT_ALL_TYPES":
      action.item.map((typesItem: any, index: any) => {
        return state.set(typesItem, action.isChecked);
      });
      return state;
    case "RENDER_TYPES_DATA":
      return makeAllSelectedTypesFalse(action, state);
    default:
      return state;
  }
};

export const selectedLevels = (state: any = new Map(), action: any) => {
  switch (action.type) {
    case "SELECT_LEVEL":
      return state.set(action.item, action.isChecked);
    case "SELECT_ALL_LEVEL":
      action.item.map((levelItem: any, index: any) => {
        return state.set(levelItem, !action.isChecked);
      });
      return state;
    default:
      return state;
  }
};

export const isAllLevelSelected = (
  state: any = { wall: false, ceiling: false },
  action: any
) => {
  switch (action.type) {
    case "SELECT_ALL_LEVEL":
      return action.selectedTabType === "wall"
        ? { ...state, wall: !action.isChecked }
        : { ...state, ceiling: !action.isChecked };
    case "SELECT_LEVEL":
      return action.selectedTabType === "wall"
        ? { ...state, wall: false }
        : { ...state, ceiling: false };
    default:
      return state;
  }
};

export const isAllTypesSelected = (
  state: any = { wall: false, ceiling: false },
  action: any
) => {
  switch (action.type) {
    case "SELECT_ALL_TYPES":
      return action.selectedTabType === "wall"
        ? { ...state, wall: !action.isChecked }
        : { ...state, ceiling: !action.isChecked };
    case "SELECT_TYPES":
      return action.selectedTabType === "wall"
        ? { ...state, wall: false }
        : { ...state, ceiling: false };
    default:
      return state;
  }
};

export const typesData = (
  typesData: TypeData = { type: "", data: [] },
  action: any
) => {
  switch (action.type) {
    case "RENDER_TYPES_DATA":
      return action.typesData;
    default:
      return typesData;
  }
};

export const levelsData = (levelsData: LevelData = [], action: any) => {
  switch (action.type) {
    case "INIT_LEVELS_DATA":
      return action.levelsData;
    case "SELECT_LEVEL_NOMANCLATURE":
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const groupSelectedItems = (
  groupSelectedItemTechnical: GroupSelectedItem[] = [],
  action: any
) => {
  switch (action.type) {
    case "SELECT_TYPES":
      return addRemoveSelectedItems(action, groupSelectedItemTechnical);
    case "SELECT_ALL_TYPES":
      return addRemoveSelectedItems(action, groupSelectedItemTechnical);
    case "RENDER_TYPES_DATA":
      return removeSelectedItems(action, groupSelectedItemTechnical);
    default:
      return groupSelectedItemTechnical;
  }
};

export const filters = (
  filters: { [key: string]: Filter[] } = placoFilters,
  action: any
) => {
  let copy: { [key: string]: Filter[] };
  switch (action.type) {
    case "UPDATE_WALL_FILTERS":
      copy = { ...filters };
      copy.Walls = action.filters;
      return copy;
    case "UPDATE_LINING_FILTERS":
      copy = { ...filters };
      copy.LiningWalls = action.filters;
      return copy;
    case "FETCH_PLACO_DATA":
      copy = { ...filters };
      copy.Walls = action.filters;
      copy.LiningWalls = action.liningWallFilters;
      copy.Ceiling = action.ceilingFilters;
      return copy;
    case "RESET_FILTERS":
      copy = { ...filters };
      copy.Walls = copy.Walls.map((filter) => {
        filter.name === "Hauteur limite (m)" ||
        filter.name === "Affaiblissement acoustique (DB)" ||
        filter.name === "Encombrement au sol (mm)" ||
        filter.name === "Isolation acoustique en + du support (DB)"
          ? (filter.value = [null, null])
          : (filter.value = null);
        return filter;
      });
      copy.LiningWalls = copy.LiningWalls.map((filter) => {
        filter.name === "Hauteur limite (m)" ||
        filter.name === "Affaiblissement acoustique (DB)" ||
        filter.name === "Encombrement au sol (mm)" ||
        filter.name === "Isolation acoustique en + du support (DB)"
          ? (filter.value = [null, null])
          : (filter.value = null);
        return filter;
      });
      copy.Ceiling = copy.Ceiling.map((filter) => {
        filter.value = null;
        return filter;
      });
      return copy;
    case "SET_FILTER_VALUE":
      copy = { ...filters };
      let updatedFilter = copy[action.filterType].map((filter: Filter) => {
        if (filter.name !== action.name) {
          return filter;
        }
        console.log(action.value);
        filter.value = _.isEmpty(String(action.value)) ? null : action.value;
        return filter;
      });
      copy[action.filterType] = updatedFilter;
      return copy;
    default:
      return filters;
  }
};

export const selectedTabType = (state: string = "walls", action: any) => {
  switch (action.type) {
    case "SET_TAB_TYPE":
      return action.selectedTab;
    default:
      return state;
  }
};

export const selectedItems = (selectedItems: any = [], action: any) => {
  switch (action.type) {
    case "SET_SELECTED_ITEMS":
      return action.itemData;
    default:
      return selectedItems;
  }
};

export const extractDetails = (extractDetails: any = [], action: any) => {
  switch (action.type) {
    case "CREATE_EXTRACT_DETAILS":
      return action.extractDetails;
    default:
      return extractDetails;
  }
};

export const liningWallsDetails = (
  liningwallsDetails: any = [],
  action: any
) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.liningwallsDetails ? action.liningwallsDetails : [];
    default:
      return liningwallsDetails;
  }
};

export const ceilingDetails = (ceilingDetails: any = [], action: any) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.ceilingDetails ? action.ceilingDetails : [];
    default:
      return ceilingDetails;
  }
};

export const loadingProductList = (
  loadingProductList: any = true,
  action: any
) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return false;
    default:
      return loadingProductList;
  }
};

export const ceilings = (ceilings: any = [], action: any) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.ceilings;
    default:
      return ceilings;
  }
};

export const complexes = (complexes: any = [], action: any) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.complexes;
    default:
      return complexes;
  }
};

export const liningWalls = (liningWalls: any = [], action: any) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.liningWalls;
    default:
      return liningWalls;
  }
};

export const partitionsDetails = (partitionsDetails: any = [], action: any) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.partitionsDetails;
    default:
      return partitionsDetails;
  }
};

export const partitions = (partitions: any = [], action: any) => {
  switch (action.type) {
    case "FETCH_PLACO_DATA":
      return action.partitions;
    default:
      return partitions;
  }
};

export const nomenclatureLoader = (loading: boolean = false, action: any) => {
  switch (action.type) {
    case "SET_NOMENCLATURE_LOADER":
      return action.load;
    default:
      return loading;
  }
};

export const rowNumber = (rowNumber: any = null, action: any) => {
  switch (action.type) {
    case "SET_ROW_NUMBER":
      return action.rowNumber;
    default:
      return rowNumber;
  }
};

export const productType = (productType: string = "wall", action: any) => {
  switch (action.type) {
    case "SET_PRODUCT_TYPE":
      return action.productType;
    default:
      return productType;
  }
};

export const productSubType = (
  productsubtype: string = "Walls",
  action: any
) => {
  switch (action.type) {
    case "SET_PRODUCT_SUB_TYPE":
      return action.productsubtype;
    default:
      return productsubtype;
  }
};

export const detailOid = (detailOid: any = null, action: any) => {
  switch (action.type) {
    case "SET_DETAIL_OID":
      return action.detailOid;
    default:
      return detailOid;
  }
};

export const filteredElements = (filteredElements: any = [], action: any) => {
  switch (action.type) {
    case "INIT_FILTERED_ELEMENTS":
      return action.filteredElements;
    case "SEARCH":
      return action.filteredElements;
    default:
      return filteredElements;
  }
};

export const displayedElements = (displayedElements: any = [], action: any) => {
  console.log(action.type);
  switch (action.type) {
    case "INIT_FILTERED_ELEMENTS":
      return action.filteredElements.length > 20
        ? action.filteredElements.slice(0, 20)
        : action.filteredElements;
    case "SEARCH":
      return action.filteredElements.length > 20
        ? action.filteredElements.slice(0, 20)
        : action.filteredElements;
    case "SCROLLED_BOTTOM":
      return action.displayedElements;
    default:
      return displayedElements;
  }
};

export const selectionBuffer = (selectionBuffer: any = null, action: any) => {
  switch (action.type) {
    case "SET_SELECTION_BUFFER":
      return action.selectionBuffer;
    case "RESET_SELECTION_BUFFER":
      return null;
    default:
      return selectionBuffer;
  }
};

export const searchedWord = (searchedWord: any = "", action: any) => {
  switch (action.type) {
    case "SET_SEARCHED_WORD":
      return action.word;
    case "RESET_WORD":
    case "INIT_FILTERED_ELEMENTS":
    case "SET_PRODUCT_SUB_TYPE":
      return "";
    default:
      return searchedWord;
  }
};

export const layoutDetailsDisplayed = (
  layoutDetailsDisplayed: any = false,
  action: any
) => {
  switch (action.type) {
    case "SHOW_LAYOUT_DETAILS":
      return true;
    case "HIDE_LAYOUT_DETAILS":
      return false;
    default:
      return layoutDetailsDisplayed;
  }
};

export const systemDetailsDisplayed = (
  systemDetailsDisplayed: any = false,
  action: any
) => {
  switch (action.type) {
    case "SHOW_SYSTEM_DETAILS":
      return true;
    case "HIDE_SYSTEM_DETAILS":
      return false;
    default:
      return systemDetailsDisplayed;
  }
};

export const productDetailName = (
  productDetailName: any = null,
  action: any
) => {
  switch (action.type) {
    case "SET_PRODUCT_DETAIL_NAME":
      return action.productDetailName;
    default:
      return productDetailName;
  }
};

export const liningWallsAttributesAid = (
  layoutAttributesAid: any = null,
  action: any
) => {
  switch (action.type) {
    case "SET_LINING_WALLS_ATTRIBUTES_AID":
      return action.layoutAttributesAid;
    default:
      return layoutAttributesAid;
  }
};

export const layoutAttributesAid = (
  layoutAttributesAid: any = null,
  action: any
) => {
  switch (action.type) {
    case "SET_LAYOUT_ATTRIBUTES_AID":
      return action.layoutAttributesAid;
    default:
      return layoutAttributesAid;
  }
};

export const groupNumber = (groupNumber: any = null, action: any) => {
  switch (action.type) {
    case "SET_GROUP_NUMBER":
      // console.log(action);
      return action.groupNumber;
    default:
      return groupNumber;
  }
};

export const increment = () => {
  return {
    type: "INCREMENT",
  };
};

export const progress = (progress: number = 0, action: any) => {
  switch (action.type) {
    case "SET_PROGRESS":
      return action.progress;
    case "INCREMENT":
      return progress + 1;
    default:
      return progress;
  }
};

export const processing = (processing: boolean = false, action: any) => {
  switch (action.type) {
    case "PROCESSING":
      return true;
    case "STOP_PROCESSING":
      return false;
    default:
      return processing;
  }
};

export const step = (step: string = "loading", action: any) => {
  switch (action.type) {
    case "SET_STEP":
      return action.step;
    default:
      return step;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const liningWallsDetails2 = (liningWallsDetails = [], action: any) => {
  switch (action.type) {
    case "SET_LINING_WALLS_DETAILS":
      return action.liningWallsDetails;
    default:
      return liningWallsDetails;
  }
};

export const systemType = (systemType = "walls", action: any) => {
  switch (action.type) {
    case "SET_SYSTEM_TYPE":
      return action.systemType;
    default:
      return systemType;
  }
};

export const featuredSystemDraw = (featuredSystem = null, action: any) => {
  switch (action.type) {
    case "SET_DETAIL_OID":
      return action.featuredSystem;
    default:
      return featuredSystem;
  }
};

export interface MetresStore {
  language: Language;
  functionalityIcon: string;
  tree: Tree;
  selectedTypes: any;
  selectedLevels: any;
  typesData: TypeData[];
  levelsData: LevelData;
  groupSelectedItems: GroupSelectedItem[];
  filters: { [key: string]: Filter[] };
  selectedTabType: string;
  selectedItems: any;
  partitions: any;
  partitionsDetails: any;
  liningWalls: any;
  complexes: any;
  ceilings: any;
  loadingProductList: any;
  ceilingDetails: any;
  liningWallsDetails: any;
  nomenclatureLoader: any;
  rowNumber: any;
  productType: string;
  productSubType: string;
  detailOid: any;
  filteredElements: any;
  displayedElements: any;
  selectionBuffer: any;
  searchedWord: any;
  layoutDetailsDisplayed: any;
  systemDetailsDisplayed: any;
  productDetailName: any;
  liningWallsAttributesAid: any;
  layoutAttributesAid: any;
  extractDetails: any;
  groupNumber: any;
  increment: any;
  progress: any;
  processing: any;
  step: any;
  isAllLevelSelected: any;
  isAllTypesSelected: any;
  liningWallsDetails2: any;
  systemType: any;
  featuredSystemDraw: any;
  config: any;
}

//#region functions
function makeAllSelectedTypesFalse(action: any, state: any): any {
  action.callBy === "level" &&
    _.forEach(action.typesData, (value, key) => {
      state.set(key, false);
    });
  return state;
}

function addRemoveSelectedItems(
  action: any,
  itemList: GroupSelectedItem[]
): GroupSelectedItem[] {
  if (action.isChecked) {
    _.forEach(action.object.items, (value, key) => {
      itemList = [...itemList, value];
    });
    return itemList;
  } else {
    _.forEach(action.object.items, (value, key) => {
      itemList = _.reject(itemList, value);
    });
    return itemList;
  }
}

function removeSelectedItems(
  action: any,
  itemList: GroupSelectedItem[]
): GroupSelectedItem[] {
  if (action.callBy === "level") {
    _.forEach(action.typesData, (typeValue, key) => {
      _.forEach(typeValue, (value, index) => {
        itemList = _.reject(itemList, ["Name", value.Name]);
      });
    });
  }
  return itemList;
}

//#endregion

export const placoDependecies = (
  state: { brand: string; region: string } = { brand: "", region: "" },
  action: any
) => {
  switch (action.type) {
    case "SET_PLACO_DEPENDECIES":
      return { ...state, brand: action.brand, region: action.region };
    default:
      return state;
  }
};

export const reducers = combineReducers({
  language,
  tree,
  selectedTypes,
  selectedLevels,
  typesData,
  levelsData,
  groupSelectedItems,
  filters,
  selectedTabType,
  selectedItems,
  partitions,
  partitionsDetails,
  liningWalls,
  complexes,
  ceilings,
  loadingProductList,
  ceilingDetails,
  liningWallsDetails,
  nomenclatureLoader,
  rowNumber,
  productType,
  productSubType,
  detailOid,
  filteredElements,
  displayedElements,
  selectionBuffer,
  searchedWord,
  layoutDetailsDisplayed,
  systemDetailsDisplayed,
  productDetailName,
  liningWallsAttributesAid,
  layoutAttributesAid,
  extractDetails,
  groupNumber,
  increment,
  progress,
  processing,
  step,
  isAllLevelSelected,
  isAllTypesSelected,
  functionalityIcon,
  liningWallsDetails2,
  systemType,
  featuredSystemDraw,
  placoDependecies,
  config,
});
