import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { EditSelectionButton } from "../../CommonModules/Selection/EditSelectionBtn";
import { List } from "../../CommonModules/Selection/List";
import { Language } from "../../Selection/Actions/types";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { Selection } from "../../Selection/Actions/types";
import { Routes } from "./root";
import { setMetresSelection, setprojectData } from "../../Selection/Actions";

interface Props {
  setRoute: any;
}

export const MetSelection = (props: Props) => {
  /**
   * The `useSelector()` hook is working like mapStateToProps from class component
   * Means that you cas access redux state using useSelector Hook
   * For ex. state.language
   */
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );
  /**
   * The `useDispatch() Hook is works like mapDispatch from class component
   * Means that you can dispatch any action using useDispatch()
   */
  const dispatch = useDispatch();

  const applicationLanguage: Language = reduxState.language;

  const [selectionID, setSelectionID] = useState<string[]>([]);
  const [selections, setSelections] = useState<{
    [key: string]: Selection<PlacoOptions>;
  }>({});
  const [revitViewName, setRevitViewName] = useState<string>("");

  useEffect(() => {
    async function fetchRevitDocumentType() {
      let projectData: initProjectData = await api.queries.getSetProjectData();
      if (projectData.ProjectId !== "") {
        dispatch(setprojectData(projectData));
      }
      const documentName = await api.queries.getActiveDocumentName();
      setRevitViewName(documentName);
    }
    if (revitViewName === "") {
      fetchRevitDocumentType();
    }
    async function fetchPlacoSelections() {
      let savedSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );

      if (savedSelections) {
        let parsedSelections = savedSelections as {
          [key: string]: Selection<PlacoOptions>;
        };

        if (!_.isEqual(selections, parsedSelections)) {
          setSelections(parsedSelections);
        }
      }
    }

    fetchPlacoSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectRow = (id: string) => {
    if (reduxState.moduleData.moduleParZone) {
      if (_.includes(selectionID, id)) {
        let filtSelectionIds = _.remove(selectionID, (n) => {
          return n !== id;
        });
        setSelectionID(filtSelectionIds);
      } else {
        let filtSelectionIds = _.concat(selectionID, id);
        setSelectionID(filtSelectionIds);
      }
    } else {
      setSelectionID([id]);
    }
  };

  const handleSubmit = () => {
    if (selectionID.length > 0) {
      Promise.resolve(dispatch(setMetresSelection(selectionID))).then(() => {
        props.setRoute(Routes.ROOT);
      });
    }
  };

  const convertDate = (d: any) => {
    let sp = d.split("/");
    let ret = [sp[1], sp[0], sp[2]].join("/");
    return ret;
  };

  //Sorting Date
  var sortingSelection = _.filter(selections, {
    RevitView: reduxState.projectData.ProjectId,
  });

  // Sorting Date
  sortingSelection.sort((a, b) => {
    return +new Date(convertDate(b.Date)) - +new Date(convertDate(a.Date));
  });

  return (
    <div style={{ height: "100%" }}>
      <FunctionalityHeader
        Icon={reduxState.functionalityIcon}
        name={wording.name[applicationLanguage]}
      />
      <List
        selections={sortingSelection}
        selectRow={selectRow}
        zone={reduxState.moduleData.moduleParZone}
        applicationLanguage={applicationLanguage}
        moduleData={selectionID.length > 0 ? selectionID : []}
      />

      <EditSelectionButton
        setRoute={props.setRoute}
        routes={Routes}
        applicationLanguage={applicationLanguage}
        popUpVisible={() => {
          return false;
        }}
        toggleConfirmModal={false}
        onYesAction={null}
        onNoAction={null}
        confirmModal={false}
        description="Voulez-vous sauvegarder votre document?"
      />

      <Grid columns={1} stackable style={{ ...style.processButtonSegment }}>
        <Grid.Column style={{ padingBottom: "0px", textAlign: "center" }}>
          <Button color="orange" onClick={() => props.setRoute(Routes.ROOT)}>
            {wording.back[applicationLanguage]}
          </Button>
          <Button
            type="submit"
            disabled={selectionID.length > 0 ? false : true}
            primary
            onClick={() => handleSubmit()}
          >
            {wording.validate[applicationLanguage]}
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const wording: WordingType = {
  name: {
    French: "Métrés",
    English: "Nomenclature",
  },
  editSelection: {
    French: "Créer / Modifier des sélections",
    English: "Create / Modify selections",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Télécharger",
    English: "Download",
  },
};

type WordingType = {
  name: LanguageType;
  editSelection: LanguageType;
  back: LanguageType;
  validate: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
  processButtonSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};
