import axios from "axios";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";
import { wait } from "../../../../RevitJS/Helpers";
const token = localStorage.getItem("token");

export async function filemanagementRequest(
  url: string,
  body: any,
  cng: any,
  headers: any,
  get: boolean = false
) {
  try {
    let bddInstance = axios.create({
      baseURL: cng.REACT_APP_FILEMANAGEMENTAPIURL_EXTERNAL,
      headers: headers,
    });

    bddInstance.interceptors.request.use(async (request) => {
      return await authFilter(request, cng);
    });

    bddInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (!originalRequest._retry || originalRequest._retry < 8) {
          originalRequest._retry =
            originalRequest._retry || originalRequest._retry >= 0
              ? originalRequest._retry + 1
              : 0;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
          axios.defaults.headers.common["Content-Type"] = `multipart/form-data`;
          await wait(1000);
          return bddInstance(originalRequest);
        }

        if (originalRequest._retry === 8) {
          //window.location.href = cng.REACT_APP_SERVERURL + "#/home";
          return error.response;
        }
        return Promise.reject(error);
      }
    );

    if (get) {
      return await bddInstance.get(url);
    } else {
      return await bddInstance.post(url, body, headers);
    }
  } catch (error) {
    throw error;
  }
}

export async function filemanagementRequestBlob(
  url: string,
  body: any,
  cng: any,
  get: boolean = false
) {
  let bddInstance = axios.create({
    baseURL: cng.REACT_APP_FILEMANAGEMENTAPIURL_EXTERNAL,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + token,
    },
    responseType: "blob",
  });

  bddInstance.interceptors.request.use(async (request) => {
    return await authFilter(request, cng);
  });

  bddInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (!originalRequest._retry || originalRequest._retry < 8) {
        originalRequest._retry =
          originalRequest._retry || originalRequest._retry >= 0
            ? originalRequest._retry + 1
            : 0;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
        await wait(1000);
        return bddInstance(originalRequest);
      }

      if (originalRequest._retry === 8) {
        //window.location.href = cng.REACT_APP_SERVERURL + "#/home";
        return;
      }
      return Promise.reject(error);
    }
  );

  if (get) {
    return await bddInstance.get(url);
  } else {
    return await bddInstance.post(url, body);
  }
}

export async function filemanagementRequestDelete(url: string, cng: any) {
  try {
    let bddInstance = axios.create({
      baseURL: cng.REACT_APP_FILEMANAGEMENTAPIURL_EXTERNAL,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    });

    bddInstance.interceptors.request.use(async (request) => {
      return await authFilter(request, cng);
    });

    bddInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (!originalRequest._retry || originalRequest._retry < 8) {
          originalRequest._retry =
            originalRequest._retry || originalRequest._retry >= 0
              ? originalRequest._retry + 1
              : 0;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
          await wait(1000);
          return bddInstance(originalRequest);
        }

        if (originalRequest._retry === 8) {
          //window.location.href = cng.REACT_APP_SERVERURL + "#/home";
          //return;
          return error.response;
        }
        return Promise.reject(error);
      }
    );
    return await bddInstance.delete(url);
  } catch (error) {
    throw error;
  }
}

export const CheckFilePDFPhotoBox = async (reust: any, config: any) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
    Authorization: "Bearer " + token,
  };
  const response = await filemanagementRequest(
    "/checkFile",
    reust,
    config,
    headers
  );
  return response;
};

export const DownloadPDFPhotoBox = async (response: any, config: any) => {
  const fileResponse = await filemanagementRequestBlob(
    "/downloadPDF",
    response,
    config
  );
  return fileResponse;
};

export const UploadDocumentToFilemanagement = async (
  fileData: any,
  config: any
) => {
  try {
    let formData: FormData = new FormData();
    formData.append("anonymous", "true");
    formData.append("file", fileData);
    console.log("formData", formData);
    // const aaa = formData.arrayBuffer();
    // console.log("formData", aaa);
    // const fileResponse = await filemanagementRequest(
    //   "/uploadFile?anonymous=true",
    //   formData,
    //   config
    // ).catch((error) => {
    //   throw error;
    // });
    // return fileResponse;
    let headers = {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      Authorization: "Bearer " + token,
    };
    return await filemanagementRequest(
      "/uploadFile?anonymous=true",
      formData,
      config,
      headers
    ).catch((error) => {
      return Promise.reject(error);
    });
  } catch (error) {
    console.log("error in api file:", error);
    throw error;
  }
};

export const DeleteDocumentToFileManagement = async (
  filename: string,
  config: any
) => {
  const flResposne = await filemanagementRequestDelete(
    "/delete/" + filename + "?anonymous=true",
    config
  );
  return flResposne;
};

export const GetAllDocumentNameFromFileManagement = async (config: any) => {
  let headers = {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
    Authorization: "Bearer " + token,
  };

  const flresponse = await filemanagementRequest(
    "/list?anonymous=true",
    null,
    config,
    headers,
    true
  );
  return flresponse;
};

export const DownloadDocumentFromFileManagement = async (
  filename: string,
  config: any
) => {
  const response = await filemanagementRequestBlob(
    "/download/" + filename,
    null,
    config,
    true
  );
  return response;
};
