import React from "react";
import { Container, Header } from "semantic-ui-react";

interface FunctionalityHeaderProps {
  name: string;
  Icon: string;
  width?: number;
  subheader?: string;
}

export class FunctionalityHeader extends React.Component<FunctionalityHeaderProps> {
  render() {
    const { name, Icon, width } = this.props;
    return (
      <Container textAlign="center" style={{ marginBottom: 0, width: "100%" }}>
        <Header
          color="blue"
          style={{ paddingTop: "0", paddingBottom: 0 }}
          as="h3"
          subheader={this.props.subheader ?? ""}
          content={name}
          image={Icon !== "" ? Icon : "/PlacoBIM.jpg"}
        ></Header>
        {/* <Image
          src="/plugin_new_logo/PlacoBIM.png"
          style={{height: "1.8em", position: "absolute", top: "0em",  left: "26em",  width: "3em"
        }}></Image> */}
      </Container>
    );
  }
}
