import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { Root } from "./components/root";

export const functionalityId = "1e41dd56-3d5b-11eb-adc1-0242ac120002";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Load",
    module: "PRESCRIBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root openedFrom={"PRESCRIBIM"} />
    </ParentRoot>
  );
};

export const LoadSystemRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root openedFrom={"PRESCRIBIM"} />
    </ParentRoot>
  );
};

export const LoadSystemContainer: Functionality = {
  Name: {
    French: "Charger",
    English: "Load",
    German: "Charger",
    Italian: "Charger",
    Indonesian: "Charger",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Charger des systèmes",
    English: "Load systems into the project",
    German: "Charger des systèmes",
    Italian: "Charger des systèmes",
    Indonesian: "Charger des systèmes",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Library_Charger.png",
  RootComponent,
  ParentId: "0",
};
