import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Grid, Message } from "semantic-ui-react";
import { LevelsColumn } from "../../Selection/Components/LevelsColumn";
import { TypesColumn } from "../../Selection/Components/TypesColumn";
import { TypeData, LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { selectAllLevel, selectAllType } from "../../Selection/Actions";
import { selectorTp } from "../../Selection/Actions/types";

interface Props {
  wording: {
    ceilingTypes: { [key: string]: string };
    wallTypes: { [key: string]: string };
    levels: { [key: string]: string };
    wall: { [key: string]: string };
    ceiling: { [key: string]: string };
  };
  language: string;
  selectorType: selectorTp;
  wallTypesData: TypeData[];
  wallLevelsData: LevelData[];
  wallSelectedLevels: string[];
  ceilingTypesData: TypeData[];
  ceilingLevelsData: LevelData[];
  ceilingSelectedLevels: string[];
  selectAllLevel: any;
  selectAllType: any;
}
interface State { }

export class GroupSelectorBody extends Component<Props, State> {
  state = {};

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
      },
      ceilingTypes: {
        French: "Types de plafonds",
        English: "Ceiling types",
      },
      wallTypes: {
        French: "Types de murs",
        English: "Wall types",
      },
      ceiling: {
        French: "plafond",
        English: "Ceiling",
      },
      wall: {
        French: "mur",
        English: "Wall",
      },
    },
  };

  selectorTypes = () => {
    switch (this.props.selectorType) {
      case "wall":
        return this.props.wording.wallTypes[this.props.language];
      case "ceiling":
        return this.props.wording.ceilingTypes[this.props.language];
      default:
        return this.props.wording.wallTypes[this.props.language];
    }
  };

  typesSelector = () => {
    switch (this.props.selectorType) {
      case "wall":
        return this.props.wording.wall[this.props.language];
      case "ceiling":
        return this.props.wording.ceiling[this.props.language];
      default:
        return this.props.wording.wall[this.props.language];
    }
  };

  render() {
    const {
      wording: { levels },
      language,
      selectorType,
      wallTypesData,
      wallLevelsData,
      ceilingLevelsData,
      ceilingTypesData,
      wallSelectedLevels,
      ceilingSelectedLevels,
    } = this.props;
    return (
      <div style={{ width: "100%", backgroundColor: "none" }}>
        <div>
          {selectorType === "wall" ? (
            wallLevelsData.length > 0 ? (
              <Grid columns={2} stackable style={{ backgroundColor: "none" }}>
                <LevelsColumn
                  color="blue"
                  columnTitle={levels[language]}
                  levelsData={wallLevelsData}
                  selectAllLevel={this.props.selectAllLevel}
                />
                <TypesColumn
                  columnTitle={this.selectorTypes()}
                  color="blue"
                  selectedLevels={wallSelectedLevels}
                  typesData={wallTypesData}
                  selectAllType={this.props.selectAllType}
                />
              </Grid>
            ) : (
              <Message compact>
                Aucun élément de type {this.typesSelector()} n'est dessiné dans le projet
              </Message>
            )
          ) : ceilingLevelsData.length > 0 ? (
            <Grid columns={2} stackable style={{ backgroundColor: "none" }}>
              <LevelsColumn
                color="blue"
                columnTitle={levels[language]}
                levelsData={ceilingLevelsData}
                selectAllLevel={this.props.selectAllLevel}
              />
              <TypesColumn
                columnTitle={this.selectorTypes()}
                color="blue"
                selectedLevels={ceilingSelectedLevels}
                typesData={ceilingTypesData}
                selectAllType={this.props.selectAllType}
              />
            </Grid>
          ) : (
            <Message compact>
              Aucun élément de type {this.typesSelector()} n'est dessiné dans le projet
            </Message>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { selectorType: selectorTp }
) => ({
  language: state.language,
  selectorType: ownProps.selectorType,
  wallLevelsData: state.wallLevelsData,
  wallTypesData: state.wallTypesData,
  ceilingLevelsData: state.ceilingLevelsData,
  ceilingTypesData: state.ceilingTypesData,
  wallSelectedLevels: state.wallSelectedLevels,
  ceilingSelectedLevels: state.ceilingSelectedLevels,
});

const mapDispatchToProps = {
  selectAllLevel: selectAllLevel,
  selectAllType: selectAllType,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelectorBody);
