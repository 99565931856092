
export function groupBy(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByArray(xs:any, key:any) {
  return xs.reduce(function(rv:any, x:any) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r:any) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}

export const mergeItems = (arr:any) =>
  groupByArray(arr, "Type").map((el:any) => {
    return {
      Type: el.key,
      Count: el.values.reduce(function(prev:any, cur:any) {
        return prev + cur.Count;
      }, 0),
      Checked: false
    };
  });

export const elementTypes = (data:any) =>
  data.Tree.length > 0
    ? mergeItems(
        data.Tree.map((level:any) => level.Elements)
          .map((s:any) =>
            s.map((nested:any) => {
              return {
                Type: nested.Type,
                Count: nested.Ids.length,
                Checked: false
              };
            })
          )
          .reduce((prev:any, current:any) => prev.concat(current))
          .sort((a:any, b:any) => (a.Type < b.Type ? -1 : 1))
      )
    : null;

export const levelData = (data:any, levellist:any) => {
  if (levellist.length === 0) return data;
  let levelsData = data.Tree.filter((level:any) =>
    levellist.includes(level.Level.Name)
  );
  return { Tree: levelsData };
};

export const selectedIds = (data:any, selectedLevels:any, selectedTypes:any) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
    return data.Tree.filter((level:any) => selectedLevels.includes(level.Level.Name))
      .map((level:any) => level.Elements)
      .map((s:any) => s.map((nested:any) => nested))
      .reduce((prev:any, current:any) => prev.concat(current))
      .filter((e:any) => selectedTypes.includes(e.Type))
      .map((el:any) => el.Ids)
      .reduce((prev:any, current:any) => prev.concat(current));
  return [];
};

export const selectedItems = (data:any, selectedLevels:any, selectedTypes:any) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
  
  return groupByArray(
    // group array objects by unique 'Type'
    data.Tree.filter((level:any) => selectedLevels.includes(level.Level.Name)) //filter an object from 'Tree' of which 'Level.Name' includes in 'selectedlevels' array
      .map((level:any) => level.Elements) // map on or loop through 'Elements' object
      .map((s:any) => s.map((nested:any) => nested)) //  map on or loop through on objects inside 'Element' array
      .reduce((prev:any, current:any) => prev.concat(current)) // reduce or concat Elements objects into single array
      .filter((e:any) => selectedTypes.includes(e.Type)), // filter single array for which 'Type' includes in 'selectedTypes'
    "Type"
  ).map((el:any) => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map((val:any) => val.Ids)
      )
    };
  });
  // return [];
};

export const selectedItemsManualSelection = (data:any) => {
  return groupByArray(
    data.Tree.map((level:any) => level.Elements)
      .map((s:any) => s.map((nested:any) => nested))
      .reduce((prev:any, current:any) => prev.concat(current)),
    "Type"
  ).map((el:any) => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map((val:any) => val.Ids)
      )
    };
  });
};
