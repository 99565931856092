import React from "react";
import { Grid } from "semantic-ui-react";
import { ListHeader } from "./ListHeader";
import { Language, wording, Zone } from "./Type";
import { Selection } from "../../Selection/Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import { PerEtageRow } from "./PerEtageRow";
import map from "lodash/map";
import includes from "lodash/includes";
import { PerZoneRow } from "./PerZoneRow";

type Props = {
  applicationLanguage: Language;
  selections: Selection<PlacoOptions>[];
  selectRow: any;
  zone: Zone;
  moduleData: any;
  height?: any;
};

export const List = (props: Props) => {
  let { applicationLanguage, selections, selectRow, zone, moduleData, height } =
    props;

  if (Object.keys(selections).length <= 0) {
    return (
      <Grid
        columns={1}
        stackable
        style={
          height
            ? { ...style.tableSegment, ...height, marginTop: "0em" }
            : { ...style.tableSegment, ...style.height_375, marginTop: "0em" }
        }
      >
        <Grid.Column>
          <div
            style={{
              padding: 5,
              height: 25,
              // backgroundColor: "rgb(250, 250, 255)",
              display: "flex",
              alignItems: "center",
              borderBottom: "0.5px solid white",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                color: "grey",
              }}
            >
              {wording.noSelectionSaved[applicationLanguage]}
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <Grid
      columns={1}
      stackable
      style={
        height
          ? { ...style.tableSegment, ...height, marginTop: "0em" }
          : { ...style.tableSegment, ...style.height_375, marginTop: "0em" }
      }
    >
      <Grid.Column>
        <ListHeader applicationLanguage={applicationLanguage} />
        <div style={style.tableSegment_body} className="height265">
          {map(
            selections,
            (selection: Selection<PlacoOptions>, key: string) => {
              switch (zone) {
                case true:
                  return (
                    selection.Zone && (
                      <PerZoneRow
                        key={selection.Id}
                        selection={selection}
                        selectRow={selectRow}
                        defaultChecked={includes(moduleData, selection.Id)}
                      />
                    )
                  );
                case false:
                  return (
                    !selection.Zone && (
                      <PerEtageRow
                        key={selection.Id}
                        selection={selection}
                        selectRow={selectRow}
                        defaultChecked={includes(moduleData, selection.Id)}
                      />
                    )
                  );
                default:
                  return (
                    <PerZoneRow
                      key={selection.Id}
                      selection={selection}
                      selectRow={selectRow}
                      defaultChecked={includes(moduleData, selection.Id)}
                    />
                  );
              }
            }
          )}
        </div>
      </Grid.Column>
    </Grid>
  );
};

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
  },
  height_435: {
    height: "435px",
  },
  height_486: {
    height: "486px",
  },
  height_375: {
    height: "calc(100vh - 150px)",
  },
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(14,110,184)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "40%",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "6%",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "24%",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    fontWeight: 700,
    width: "17%",
    color: "black",
  },
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200",
    height: "calc(100vh - 238px)",
    overflowY: "scroll" as const,
  },
};
