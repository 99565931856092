import React, { useState } from "react";
import { Provider } from "react-redux";
import store from "../../Store";
import AppBody from "./AppBody";

export enum Routes {
  ROOT = "ROOT",
}

const Root = () => {
  const [route, setRoute] = useState(Routes.ROOT);
  return (
    <>
      <Provider store={store}>
        <AppBody route={route} setRoute={setRoute} />
      </Provider>
    </>
  );
};

export default Root;
