import React from "react";
import { PluginHeader } from "./PluginHeader";
import { FunctionalityLauncherList } from "./FunctionalityLauncherList";
import { StoreState } from "../../Reducers";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedPlugin } from "../../Store/Actions";

const PluginHome = () => {
  const reduxState = useSelector((state: StoreState) => state);
  const dispatch = useDispatch();

  const pluginObject: any = reduxState.selectedPluginId
    ? reduxState.plugins.find(
        (data: any) => data.plugin.Id === reduxState.selectedPluginId
      )
    : undefined;

  if (!pluginObject) {
    return <></>;
  }

  return (
    <div style={{ padding: 10, width: "100%", height: "98vh" }}>
      <PluginHeader
        resetSelectedPlugin={() => dispatch(resetSelectedPlugin())}
        plugin={pluginObject.plugin}
        language={reduxState.language}
        config={reduxState.config}
      />
      <FunctionalityLauncherList
        language={reduxState.language}
        plugin={pluginObject.plugin}
        selectedPluginId={reduxState.selectedPluginId}
        config={reduxState.config}
      />
      <br />
    </div>
  );
};

export default PluginHome;
