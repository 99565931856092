import React, { Component } from "react";
import "./RiggibsDraw2Styles.scss";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import { Routes } from "./root";
import {
  fetchRigipsRemoteData,
  fetchRigipsRemoteDataCeilings,
  initLanguage,
} from "../Actions";
import SelectionLanding from "./SelectionLanding";
import SolutionMapping from "./SolutionMapping";
import ProductsPage from "./ProductsPage";
import { withTranslation, WithTranslation } from "react-i18next";
import GroupSelector from "./GroupSelector";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";

interface Props {
  route: Routes;
  setRoute: any;
  fetchRigipsRemoteData: any;
  fetchRigipsRemoteDataCeilings: any;
  initLanguage: any;
  currentLanguage: any;
  setConfig: any;
}

export class AppBody extends Component<Props & WithTranslation> {
  componentDidMount = async () => {
    const cng = await MyConfig();

    this.props.initLanguage();

    this.props.fetchRigipsRemoteData(cng);
    this.props.fetchRigipsRemoteDataCeilings(cng);
    setTimeout(() => {
      this.props.i18n.changeLanguage(this.props.currentLanguage);
    });
    this.props.setConfig(cng);
  };

  render() {
    switch (this.props.route) {
      case Routes.ROOT:
        return (
          <SelectionLanding
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      case Routes.GROUP_SELECTION:
        return (
          <GroupSelector
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      case Routes.MAPPING:
        return <SolutionMapping setRoute={this.props.setRoute} />;
      case Routes.PRODUCT_SELECTOR:
        return <ProductsPage setRoute={this.props.setRoute} />;
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    currentLanguage: state.language,
  };
};

export default connect(mapStateToProps, {
  fetchRigipsRemoteData,
  fetchRigipsRemoteDataCeilings,
  initLanguage,
  setConfig,
})(withTranslation(["riggibs"])(AppBody));
