import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectionStore } from "../Reducers";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import SelectionMappingTable from "./SelectionMappingTable";
import {
  GenericMappingRow,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { selectorTp, SelectionGroup } from "../Actions/types";
import { makeGetMappingList } from "../Selectors";

interface Props {
  selectorType: selectorTp;
  selectionList: GenericMappingRow<PlacoOptions>[];
  setRoute: any;
  globalLoader: boolean;
  selectionPar: boolean;
  module: string;
  handleDimmer: any;
  setScrollPosition: any;
  setScrollConfigPosition:any;
}
interface State { }

export class SelectionMappingBody extends Component<Props, State> {
  state = {};

  render() {
    const { selectionList, globalLoader } = this.props;

    return (
      <div>
        <Grid stackable>
          <Grid.Row style={{ paddingTop: 5 }}>
            <Grid.Column columns={12}>
              <SelectionMappingTable
                selectionGroup={selectionList}
                setRoute={this.props.setRoute}
                selectionPar={this.props.selectionPar}
                module={this.props.module}
                handleDimmer={this.props.handleDimmer}
                setScrollPosition={this.props.setScrollPosition}
                setScrollConfigPosition={this.props.setScrollConfigPosition}

              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Dimmer active={globalLoader} page>
          <Loader />
        </Dimmer>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  let getMappingList = makeGetMappingList();

  const mapStateToProps = (
    state: SelectionStore,
    ownProps: { setRoute: any; module: string }
  ) => {
    return {
      selectorType: state.selectorType,
      selectionList: getMappingList(state, state.selectorType),
      setRoute: ownProps.setRoute,
      globalLoader: state.globalLoader,
      module: ownProps.module,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SelectionMappingBody);
