import { api } from "./../../../../RevitJS/API/index";
import { promptCheckBoxData } from "../Resources/promptCheckBoxDataModel";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { duplicateBufferSystem } from "../../Selection/Actions";

export async function getDocumentName() {
  return await api.queries.getActiveDocumentName();
}

export async function getProjectId() {
  let projectdata: any = await api.queries.getSetProjectData();
  return projectdata.ProjectId;
}

export function getCurrentDate() {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(Date.now());
}

export const getDate = () => {
  let today = new Date();
  let dd: any = today.getDate();
  let mm: any = today.getMonth() + 1; //January is 0!

  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "/" + mm + "/" + yyyy;
};

export const saveDossierTechniques = async (data: any, config: any) => {
  try {
    data.time = data.bufferTime;
    data.bufferTime = 0;
    let tempDossierData: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    let dossierTechnique = tempDossierData === null ? [] : tempDossierData;
    let updatedDossierTechnique = [];
    //let date = new Date
    let originalDocument = getCurrentDate();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data.duplicated === 0 ? originalDocument : data.documentDate;
    //data.documentDate=getCurrentDate()
    // if there are existing Dossier Technique
    if (dossierTechnique && dossierTechnique.length > 0) {
      //check if document already exist
      const isExist = dossierTechnique.some(
        (a: any) => a.documentId === data.documentId
      );

      if (isExist) {
        // if already exist and only update done modify the value to new one
        updatedDossierTechnique =
          dossierTechnique === null
            ? []
            : dossierTechnique.map((c: any) => {
                return c.documentId === data.documentId ? data : c;
              });
        await bimStorage.setItem(
          storageKey.DOSSIER_TECHNIQUE,
          JSON.stringify(updatedDossierTechnique)
        );
        return;
      } else {
        // if not exist push the data to
        dossierTechnique.push(data);
        await bimStorage.setItem(
          storageKey.DOSSIER_TECHNIQUE,
          JSON.stringify(dossierTechnique)
        );
        return;
      }
    } else {
      // if there are no dossier technique
      dossierTechnique.push(data);
      await bimStorage.setItem(
        storageKey.DOSSIER_TECHNIQUE,
        JSON.stringify(dossierTechnique)
      );
    }
  } catch (error) {
    throw error;
  }
};

export async function checkIfDossierTechniquesExist(
  name: string,
  modelName: string,
  documentId: string,
  documentType: string,
  config: any
) {
  let dossierTechnique: any = await bimStorage.getItem(
    storageKey.DOSSIER_TECHNIQUE
  );
  let rtn: boolean = false;

  if (name.trim().length < 5) {
    rtn = true;
  } else {
    if (dossierTechnique && dossierTechnique.length > 0) {
      dossierTechnique = dossierTechnique.filter(
        (a: any) =>
          a.name === name.toLowerCase() &&
          a.modelName === modelName &&
          a.documentType === documentType &&
          a.documentId !== documentId
      );
      if (dossierTechnique.length > 0) {
        if (dossierTechnique) rtn = true;
      } else {
        rtn = false;
      }
    }
  }
  return rtn;
}

export async function deleteDossierTechniques(data: any, config: any) {
  let tempDossierData: any = await bimStorage.getItem(
    storageKey.DOSSIER_TECHNIQUE
  );
  let dossierTechnique = tempDossierData === null ? [] : tempDossierData;
  if (dossierTechnique && dossierTechnique.length > 0) {
    dossierTechnique = dossierTechnique.filter(
      (a: any) => a.documentId !== data.documentId
    );
  }

  bimStorage.setItem(
    storageKey.DOSSIER_TECHNIQUE,
    JSON.stringify(dossierTechnique)
  );
}

export const isArrayNull = (arr: any) => {
  return arr === null || arr === undefined ? [] : arr;
};

export const fetchChkList = (chkName: string) => {
  let list: any = [];
  let data: any = promptCheckBoxData;
  data
    .find((chkData: any) => chkData.name === chkName)
    .chklist.map((chkData: any) => {
      list.push({
        name: chkData.value,
        key: chkData.key,
        isSelected: chkName === "downloadDAE" ? true : false,
      });
    });
  return list;
};

export const onLinkClick = function (link: any) {
  window.revit.openAppLink(link);
};

export const createFileWithNewName = (fileData: any, startsWith: string) => {
  let end = fileData["name"].split(".")[fileData["name"].split(".").length - 1];
  let new_fileName =
    startsWith +
    getDate().replace(/\//g, "") +
    new Date().getTime() +
    "." +
    end;
  var blob = fileData.slice(0, fileData.size, "application/pdf");
  return new File([blob], new_fileName, { type: "application/pdf" });
};
