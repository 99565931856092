import { CLOISONS_ID, DOUBLAGES_ID, GAINES_ID, PLAFONDS_ID } from "../../assets/constants";
import {
  FavoriteState,
  FavoriteActionTypes,
  ADD_SYSTEM_TO_FAVORITE,
  REMOVE_SYSTEM_FROM_FAVORITE,
  CHANGE_TYPE_ID,
  LOAD_SYSTEMS_FAVORITES,
  CLEAR_SYSTEMS_FAVORITES
} from "./types";

export const initialState: FavoriteState = {
  currentFavoriteSystemItems: [],
  data: {
    liningWallsDetails: [],
    partitionsDetails: [],
    ceilingsDetails: [],
    gainestechniqueswallDetails: []
  },
  favoriteCount: 0
};

export default function favoriteReducer(
  state = initialState,
  action: FavoriteActionTypes
) {
  switch (action.type) {
    case ADD_SYSTEM_TO_FAVORITE:
      return {
        ...state,
        currentFavoriteSystemItems: action.systemTypeID === action.currentSystemTypeID ? [...state.currentFavoriteSystemItems, action.favoriteSystemItem] : [...state.currentFavoriteSystemItems],
        data: {
          liningWallsDetails: action.systemTypeID === DOUBLAGES_ID ? [...state.data.liningWallsDetails, action.favoriteSystemItem] : [...state.data.liningWallsDetails],
          partitionsDetails: action.systemTypeID === CLOISONS_ID ? [...state.data.partitionsDetails, action.favoriteSystemItem] : [...state.data.partitionsDetails],
          ceilingsDetails: action.systemTypeID === PLAFONDS_ID ? [...state.data.ceilingsDetails, action.favoriteSystemItem] : [...state.data.ceilingsDetails],
          gainestechniqueswallDetails: action.systemTypeID === GAINES_ID ? [...state.data.gainestechniqueswallDetails, action.favoriteSystemItem] : [...state.data.gainestechniqueswallDetails],
        },
        favoriteCount: state.favoriteCount + 1
      };
    case REMOVE_SYSTEM_FROM_FAVORITE:
      return {
        ...state,
        currentFavoriteSystemItems: action.systemTypeID === action.currentSystemTypeID ? [...state.currentFavoriteSystemItems.slice(0, action.index),
        ...state.currentFavoriteSystemItems.slice(action.index + 1)] : [...state.currentFavoriteSystemItems],
        data: {
          liningWallsDetails: action.systemTypeID === DOUBLAGES_ID ? [...state.data.liningWallsDetails.slice(0, action.index),
          ...state.data.liningWallsDetails.slice(action.index + 1)] : [...state.data.liningWallsDetails],
          partitionsDetails: action.systemTypeID === CLOISONS_ID ? [...state.data.partitionsDetails.slice(0, action.index),
          ...state.data.partitionsDetails.slice(action.index + 1)] : [...state.data.partitionsDetails],
          ceilingsDetails: action.systemTypeID === PLAFONDS_ID ? [...state.data.ceilingsDetails.slice(0, action.index),
          ...state.data.ceilingsDetails.slice(action.index + 1)] : [...state.data.ceilingsDetails],
          gainestechniqueswallDetails: action.systemTypeID === GAINES_ID ? [...state.data.gainestechniqueswallDetails.slice(0, action.index),
            ...state.data.gainestechniqueswallDetails.slice(action.index + 1)] : [...state.data.gainestechniqueswallDetails],
        },
        favoriteCount: state.favoriteCount - 1
      };
    case CHANGE_TYPE_ID:
      return {
        ...state,
        currentFavoriteSystemItems: action.favoriteSystemItems || [],
      };
    case LOAD_SYSTEMS_FAVORITES:
      return {
        ...state,
        currentFavoriteSystemItems: action.systemTypeID === GAINES_ID ? [...action.data.gainestechniqueswallDetails]: action.systemTypeID === DOUBLAGES_ID ? [...action.data.liningWallsDetails] : action.systemTypeID === CLOISONS_ID ? [...action.data.partitionsDetails] : [...action.data.ceilingsDetails],
        data: { ...action.data },
        favoriteCount: action.favoriteCount
      };
    case CLEAR_SYSTEMS_FAVORITES:
      return {
        currentFavoriteSystemItems: [],
        data: {
          liningWallsDetails: [],
          partitionsDetails: [],
          ceilingsDetails: [],
          gainestechniqueswallDetails: []
        },
        favoriteCount: 0
      }
    default:
      return state;
  }
}
