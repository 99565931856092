import React from "react";
import {
  GenericMappingRow,
  LevelData,
  PlacoOptions,
  TypeData,
} from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "./FunctionalityHeader";
import {
  Grid,
  Segment,
  Button,
  Dimmer,
  Container,
  Icon,
  Header,
  Modal,
} from "semantic-ui-react";
import { SelectionStore } from "../Reducers";
import { connect, ConnectedProps } from "react-redux";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { clearTree, filterTree } from "../Helpers";
import {
  setSelectorType,
  saveBuffer,
  setGroupTrees,
  initMappingTableManualSelection,
  toggleBufferUpdate,
  initOtherPlacoSystem,
  replaceBuffer,
  resetFilters,
} from "../Actions";
import SelectionMappingBody from "./SelectionMappingBody";
import _, { difference } from "lodash";
import { selectorTp } from "../Actions/types";
import ReactHtmlParser from "react-html-parser";
import { ProductDetailData } from "../Types";
import { Selection } from "../Actions/types";
import { ElementParameter } from "../Actions/objectTypes";
import { getWallPlacoSystems, getCeilingPlacoSystems } from "../Selectors";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { dbStoreNameCalepinage } from "../../Calpinage/Components/root";
import { onLinkClick } from "../../DocumentTechniques/Helpers";
import { overLapElements } from "../../Overlap/Components/Waiter";
import { Oval } from "react-loader-spinner";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";
import { removeCloseWindow } from "../../CommonModules/Helpers/sessionRemoveCloseWindow";

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    IconPath: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    selectorType: state.selectorType,
    bufferSelection: state.bufferSelection,
    globalLoader: state.globalLoader,
    selectionPar: ownProps.selectionPar,
    module: ownProps.module,
    prevPage: state.moduleData.prevPage,
    elementParameter: state.elementParameter,
    wallDetailedSystems: getWallPlacoSystems(state),
    ceilingDetailedSystems: getCeilingPlacoSystems(state),
    projectData: state.projectData,
    config: state.config,
  };
};

const mapDispatch = {
  setSelectorType,
  saveBuffer,
  setGroupTrees,
  initMappingTableManualSelection,
  toggleBufferUpdate,
  initOtherPlacoSystem,
  replaceBuffer,
  resetFilters,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InjectedCounterProps & {
    IconPath: string;
    language: string;
    setSelectionId: any;
    wording: {
      wallTypes: { [key: string]: string };
      ceilingTypes: { [key: string]: string };
      selection: { [key: string]: string };
      levels: { [key: string]: string };
      back: { [key: string]: string };
      validate: { [key: string]: string };
      stepTwo: { [key: string]: string };
      partitionWalls: { [key: string]: string };
      conflictHeader: { [key: string]: string };
      conflictContent: { [key: string]: string };
      conflictButton: { [key: string]: string };
      deleteWarningContent: { [key: string]: string };
      deleteWarningButton: { [key: string]: string };
      ceilings: { [key: string]: string };
      enregistrer: { [key: string]: string };
      closeWarningMessage: { [key: string]: string };
      save: { [key: string]: string };
      yes: { [key: string]: string };
      no: { [key: string]: string };
      continuer: { [key: string]: string };
      attention: { [key: string]: string };
      saveSelection: { [key: string]: string };
      assignSolution: { [key: string]: string };
      others: { [key: string]: string };
    };
    levelsData: LevelData[];
    typesData: TypeData[];
    routes: any;
    selectorType: selectorTp;
    setRoute: any;
    bufferSelection: any;
    setGroupTrees: any;
    module: string;
    globalLoader: any;
    selectionPar: boolean;
    prevPage: string;
    replaceBuffer: any;
    elementParameter: ElementParameter[];
    wallDetailedSystems: ProductDetailData[];
    ceilingDetailedSystems: ProductDetailData[];
    resetFilters: any;
    config: any;
  };

const SetPrefilledSolution = (
  bufferSelection: Selection<PlacoOptions> | null,
  elementParameter: ElementParameter[],
  wallDetailedSystems: ProductDetailData[],
  ceilingDetailedSystems: ProductDetailData[],
  replaceBuffer: any,
  toggleEnregister: any
) => {
  let dummyBufferSelection = bufferSelection;
  if (dummyBufferSelection) {
    let selectionListByType = dummyBufferSelection.SelectionByType;

    if (selectionListByType) {
      selectionListByType.wall.Rows.map(
        (row: GenericMappingRow<PlacoOptions>, index: number) => {
          if (!row.Options.MappedSystem) {
            let uniqueEleParam = elementParameter.find(
              (el) => el.solution === row.RevitSelection.RevitType
            );
            let paramData = uniqueEleParam?.paramData.find(
              (el) => el.key === "ID Système"
            );

            if (paramData) {
              if (paramData.value) {
                let selectedSystem = wallDetailedSystems.find(
                  (el) => el.externalName === paramData?.value
                ) as ProductDetailData;
                row.Options.MappedSystem = selectedSystem;
              }
            }
          }

          return row;
        }
      );

      selectionListByType.ceiling.Rows.map(
        (row: GenericMappingRow<PlacoOptions>, index: number) => {
          if (!row.Options.MappedSystem) {
            let uniqueEleParam = elementParameter.find(
              (el) => el.solution === row.RevitSelection.RevitType
            );
            let paramData = uniqueEleParam?.paramData.find(
              (el) => el.key === "ID Système"
            );

            if (paramData) {
              if (paramData.value) {
                let selectedSystem = ceilingDetailedSystems.find(
                  (el) => el.externalName === paramData?.value
                ) as ProductDetailData;
                row.Options.MappedSystem = selectedSystem;
              }
            }
          }

          return row;
        }
      );
    }

    dummyBufferSelection.SelectionByType = selectionListByType;
    replaceBuffer(dummyBufferSelection);
    toggleEnregister(dummyBufferSelection);
  }
};

export class SelectionMapping extends React.Component<Props> {
  solutionTable: React.RefObject<HTMLDivElement>;
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.solutionTable = React.createRef();
    this.ref = React.createRef();
  }

  state = {
    closeAlertModal: false,
    saveWarning: false,
    enregister: true,
    fillSolutionWarning: false,
    showConflictPopup: false,
    active: false,
    showWallsCeilingsWillDeltetePopup: false,
    isCurrentGroupSelection: false,
    validateLoading: false,
    ref: null,
  };

  onRefChange = (node: any) => {
    this.setState({ ref: node });
  };

  GetUniqueWallsandCeilings = async () => {
    // return the default buffer if is not a zone
    if (!this.props.bufferSelection.Zone) {
      return this.props.bufferSelection;
    }

    // get all placoData
    const placoSelections = await bimStorage.getItem(
      storageKey.PLACOSELECTIONS
    );

    // get only currentl project data
    const currentPlacoSelection =
      this.getCurrentZoneSelections(placoSelections);

    // check if wall exist in other zone
    const wallExist = this.checkIfTypeExistInOtherZone(
      currentPlacoSelection,
      "wall"
    );

    // check if ceiling exist in other zone
    const ceilingExist = this.checkIfTypeExistInOtherZone(
      currentPlacoSelection,
      "ceiling"
    );

    // if any or the wall or ceiling exist show the popup
    if (wallExist || ceilingExist) {
      // if current selectin is not group only then show the popup else directly update the values
      if (!this.state.isCurrentGroupSelection)
        this.setState({
          showWallsCeilingsWillDeltetePopup: true,
        });
    }

    // now to delete the duplicate wall or ceiling first clone the current buffer
    let tempBuffer = _.cloneDeep(this.props.bufferSelection);

    // function to check if wall id exist in other zones and return true or false
    const checkIfTypeIdExistInOtherZone = (type: any, id: any) => {
      let currentPlacoType = currentPlacoSelection
        ? currentPlacoSelection.map((a: any) => {
            return a.SelectionByType[type].Rows.map(
              (e: any) => e.RevitSelection.Ids
            );
          })
        : [];
      currentPlacoType = _.flattenDepth(currentPlacoType, 2);

      const typeExist = currentPlacoType.includes(id);

      return typeExist;
    };

    // get only unique walls
    const tempBufferWalls = tempBuffer.SelectionByType.wall.Rows.map(
      (e: any) => {
        return {
          ...e,
          RevitSelection: {
            ...e.RevitSelection,
            Ids: e.RevitSelection.Ids.filter((a: any) => {
              return !checkIfTypeIdExistInOtherZone("wall", a);
            }),
          },
        };
      }
    );

    // remove walls, if no walls remaining after removing duplicate delte the wallRow
    const tempWallNoBlank = tempBufferWalls.filter((e: any) => {
      return e.RevitSelection.Ids.length > 0;
    });

    // get only unique ceilings
    const tempBufferCeilings = tempBuffer.SelectionByType.ceiling.Rows.map(
      (e: any) => {
        return {
          ...e,
          RevitSelection: {
            ...e.RevitSelection,
            Ids: e.RevitSelection.Ids.filter((a: any) => {
              return !checkIfTypeIdExistInOtherZone("ceiling", a);
            }),
          },
        };
      }
    );

    // remove celining, if no ceiling remaining after removing duplicate delete the ceilingRow
    const tempCeilingNoBlank = tempBufferCeilings.filter((e: any) => {
      return e.RevitSelection.Ids.length > 0;
    });

    // Update the walls and celing with the updated unique values in current buffer
    tempBuffer.SelectionByType.wall.Rows = tempWallNoBlank;
    tempBuffer.SelectionByType.ceiling.Rows = tempCeilingNoBlank;
    return tempBuffer;
  };

  componentDidMount = async () => {
    if (!localStorage.getItem("selectionStep")) {
      localStorage.setItem("selectionStep", "true");
    }
    // check selection used in calepinage
    bimStorage.getItem(storageKey.CURR_SELECTION_TYPE).then((data: any) => {
      if (data?.selectionType && data?.selectionType === "Group")
        this.setState({
          isCurrentGroupSelection: true,
        });

      bimStorage.setItem(storageKey.CURR_SELECTION_TYPE, JSON.stringify({}));
    });

    if (this.props.module === "Suppression") {
      this.props.setSelectionId(this.props.bufferSelection.Id);
      this.props.saveBuffer();
      this.props.setRoute(Routes.SUPPRESSION);
      api.windowsHandler.showWindow();
    }

    // get the unique if it's zone else it will return the curent prop
    const tempBuffer = await this.GetUniqueWallsandCeilings();

    SetPrefilledSolution(
      tempBuffer,
      this.props.elementParameter,
      this.props.wallDetailedSystems,
      this.props.ceilingDetailedSystems,
      this.props.replaceBuffer,
      this.toggleEnregister
    );

    this.props.toggleBufferUpdate(false);

    // if (this.solutionTable !== null && localStorage.getItem("scrollPosition")) {
    //  let scrollTop: any = localStorage.getItem("scrollPosition") !== null ? localStorage.getItem("scrollPosition") : "0";
    //  const divElement: any = this.solutionTable.current;
    //   divElement.scrollTop = parseInt(scrollTop);
    //   localStorage.removeItem("scrollPosition");
    // }
    if (this.state.ref !== null && localStorage.getItem("scrollPosition")) {
      let scrollTop: any =
        localStorage.getItem("scrollPosition") !== null
          ? localStorage.getItem("scrollPosition")
          : "0";
      const divElement: any = this.state.ref;
      divElement.scroll({
        top: Number(localStorage.getItem("scrollPosition")),
        left: 0,
        behaviour: "auto",
      });
      localStorage.removeItem("scrollPosition");
    }
  };

  componentDidUpdate = async (oldProps: Props, oldState: any) => {
    if (!_.isEqual(oldProps.bufferSelection, this.props.bufferSelection)) {
      //this.toggleEnregister(this.props.bufferSelection);
      localStorage.setItem("isModification", "true");
      bimStorage.getItem(storageKey.CURR_SELECTION_TYPE).then((data: any) => {
        if (data?.selectionType && data?.selectionType === "Group")
          this.setState({
            isCurrentGroupSelection: true,
          });

        bimStorage.setItem(storageKey.CURR_SELECTION_TYPE, JSON.stringify({}));
      });

      const tempBuffer = await this.GetUniqueWallsandCeilings();

      SetPrefilledSolution(
        tempBuffer,
        this.props.elementParameter,
        this.props.wallDetailedSystems,
        this.props.ceilingDetailedSystems,
        this.props.replaceBuffer,
        this.toggleEnregister
      );
    }
  };

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
      },
      ceilingTypes: {
        French: "Types de plafonds",
        English: "Ceiling types",
      },
      wallTypes: {
        French: "Types de murs",
        English: "Wall types",
      },
      selection: {
        French: "Sélection",
        English: "Selection",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
      partitionWalls: {
        French: "Murs",
        English: "Partition Walls",
      },
      conflictHeader: {
        French: "Conflit de systèmes",
        English: "Systems conflict",
      },
      deleteWarningContent: {
        French:
          "Un ou plusieurs des murs et plafonds que vous avez sélectionnés sont déjà présents sur une autre zone, ils seront ignorés dans la sélection en cours.",
        English:
          "One or more of the walls and ceilings you have selected are already present in another zone, they will be ignored in the current selection.",
      },
      deleteWarningButton: {
        French: "Fermer",
        English: "Continue",
      },
      conflictContent: {
        French:
          "Vous avez un ou plusieurs systèmes qui existent déjà dans une autre zone.",
        English:
          "You have one or more systems that already exist in another zone.",
      },
      conflictButton: {
        French: "Oui",
        English: "Ok",
      },
      ceilings: {
        French: "Plafonds",
        English: "Ceilings",
      },
      others: {
        French: "Autres Systèmes",
        English: "Other Systems",
      },
      stepTwo: {
        French:
          "<strong>Etape 2/2 </strong>: Table de correspondance des systèmes",
        English: "<strong>Step 2/2 </strong>: Systems mapping table",
      },
      addSystem: {
        French: "Ajouter un type de système",
        English: "Add a system type",
      },
      addOtherSystem: {
        French:
          "Ajouter d’autres systèmes Placo (Protection feu ,Gaines techniques, Conduit gaines)",
        English:
          "Add other placo systems (Protection feu ,Gaines techniques, Conduit gaines)",
      },
      newManualSelection: {
        French: "Sélection manuelle",
        English: "Manual selection",
      },
      newGroupSelection: {
        French: "Sélection groupée",
        English: "Group selection",
      },
      enregistrer: {
        French: "Enregistrer",
        English: "Save",
      },
      closeWarningMessage: {
        French:
          "Êtes vous sûr de vouloir quitter sans avoir enregistré votre sélection ?",
        English:
          "Are you sure you want to quit without saving your selection ?",
      },
      downloadError: {
        French:
          "Le fichier que vous essayez de télécharger n'est pas disponible pour le moment Veuillez contacter l'assistance technique de PLACO si besoin: ",
        English:
          "The file you are trying to download is not available at the moment ",
      },
      toClose: {
        French: "Fermer",
        English: "To close",
      },
      save: { French: "Fermer", English: "Save" },
      yes: { French: "Oui", English: "Yes" },
      no: { French: "Non", English: "No" },
      continuer: { French: "CONTINUER", English: "CONTINUE" },
      attention: { French: "Attention", English: "Attention" },
      saveSelection: {
        French:
          " Voulez-vous enregistrer la sélection en cours de modification avant de quitter ?",
        English: "Do you want to save the selection before exiting this menu?",
      },
      assignSolution: {
        French:
          "Pour sauvegarder la sélection, veuillez attribuer des solutions à tous vos systèmes sélectionnés.",
        English:
          "To save the selection, please assign solutions to all your selected systems.",
      },
    },
  };

  backHandler = () => {
    if (this.props.prevPage === Routes.REFERENCE_CCTP)
      this.props.setRoute(Routes.REFERENCE_CCTP);
    else this.props.setRoute(Routes.ROOT);
  };

  saveBufferAndRedirect = () => {
    this.props.saveBuffer();
    if (this.props.prevPage === Routes.REFERENCE_CCTP) {
      this.props.setRoute(Routes.REFERENCE_CCTP);
    } else {
      this.props.setRoute(Routes.ROOT);
    }
  };

  checkIfTypeExistInOtherZone = (currentPlacoSolutions: any, type: string) => {
    let currentPlacoType = currentPlacoSolutions?.map((a: any) => {
      return a.SelectionByType[type].Rows.map((e: any) => e.RevitSelection.Ids);
    });
    currentPlacoType = _.flattenDepth(currentPlacoType, 2);

    let currentSelectionIds = this.props.bufferSelection.SelectionByType[
      type
    ].Rows.map((e: any) => e.RevitSelection.Ids);
    currentSelectionIds = _.flatten(currentSelectionIds);

    const typeExist = currentSelectionIds.some((e: any) => {
      return currentPlacoType.includes(e);
    });

    return typeExist;
  };

  getCurrentZoneSelections = (placoSelections: any) => {
    if (placoSelections) {
      return Object.values(placoSelections).filter((e: any) => {
        return (
          e.RevitView === this.props.bufferSelection.RevitView &&
          e.Id !== this.props.bufferSelection.Id &&
          e.Zone
        );
      });
    }
  };

  validateHandler = async () => {
    try {
      localStorage.setItem("isModification", "false");
      removeCloseWindow();
      if (localStorage.getItem("selectionStep")) {
        localStorage.removeItem("selectionStep");
      }

      // if current selection is not zone save and redirect
      if (!this.props.bufferSelection.Zone) {
        this.saveBufferAndRedirect();
        return;
      }

      // if current selection is zone than check if ceilings and walls
      // exists in another zone if it exist show warning popup else save and redirect

      const placoSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );
      const currentPlacoSelection =
        this.getCurrentZoneSelections(placoSelections);
      const wallExist = this.checkIfTypeExistInOtherZone(
        currentPlacoSelection,
        "wall"
      );
      const ceilingExist = this.checkIfTypeExistInOtherZone(
        currentPlacoSelection,
        "ceiling"
      );

      if (wallExist || ceilingExist) {
        this.setState({
          showConflictPopup: true,
        });
        return;
      }

      this.saveBufferAndRedirect();
    } catch (error) {
      throw error;
    }
  };

  manualSelectionHandler = async () => {
    this.setState({ validateLoading: true });
    const isSuperPoser = await bimStorage.getItem(
      storageKey.IS_SUPERPOSER_ENABLED
    );
    if (isSuperPoser && isSuperPoser === true) {
      await overLapElements();
      bimStorage.setItem(
        storageKey.IS_SUPERPOSER_ENABLED,
        JSON.stringify(false)
      );
    }
    //await overLapElements();
    api.windowsHandler.hideWindow();
    let selectionTree = await api.selection.manualSelection("wall&ceiling");
    let wallTree = clearTree(filterTree(selectionTree, "wall"));
    let ceilingTree = clearTree(filterTree(selectionTree, "ceiling"));
    if (wallTree && wallTree.Tree && wallTree.Tree.length > 0) {
      this.props.setSelectorType("wall");
    } else if (ceilingTree && ceilingTree.Tree && ceilingTree.Tree.length > 0) {
      this.props.setSelectorType("ceiling");
    }
    this.props.setGroupTrees(wallTree, ceilingTree);
    this.props.initMappingTableManualSelection();
    this.props.toggleBufferUpdate(false);
    this.setState({ validateLoading: false });
    api.windowsHandler.showWindow();
    localStorage.setItem("isModification", "true");
  };

  groupSelectionHandler = async () => {
    let wallTree = await api.selection.elementsByLevelAndType("wall");
    let ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
    await this.props.setGroupTrees(wallTree, ceilingTree);
    this.props.setRoute(Routes.GROUP_SELECTION);
  };

  headerNameDisplay = (module: string, selectionname: string): string => {
    if (module.toLowerCase() !== "selection") {
      return module + " > " + selectionname;
    }
    return selectionname;
  };

  toggleEnregister = (bufferSelection: any) => {
    if (bufferSelection) {
      const wall = bufferSelection.SelectionByType.wall.Rows;
      const ceiling = bufferSelection.SelectionByType.ceiling.Rows;
      const others = bufferSelection.SelectionByType.others?.Rows;

      let enregister = true;
      if (wall) {
        const selectedWall = _.filter(wall, { Checked: true });
        if (selectedWall) {
          _.forEach(selectedWall, (value, key) => {
            if (value.Options.MappedSystem === null) {
              enregister = false;
            } else {
              if (value.Options.MappedSystem.translation === "") {
                enregister = false;
              }
            }
          });
        }
      }

      if (ceiling) {
        const selectedCeiling = _.filter(ceiling, { Checked: true });

        if (selectedCeiling) {
          _.forEach(selectedCeiling, (value, key) => {
            if (value.Options.MappedSystem === null) {
              enregister = false;
            } else {
              if (value.Options.MappedSystem.translation === "") {
                enregister = false;
              }
            }
          });
        }
      }

      if (others) {
        const selectedOthers = _.filter(others, { Checked: true });

        if (selectedOthers) {
          _.forEach(selectedOthers, (value, key) => {
            if (value.Options.MappedSystem === null) {
              enregister = false;
            } else {
              if (value.Options.MappedSystem.translation === "") {
                enregister = false;
              }
            }
          });
        }
      }
      if (wall.length === 0 && ceiling.length === 0 && others.length === 0) {
        enregister = false;
      }
      this.setState({ enregister: enregister });
    }
  };

  saveOrDiscard = async () => {
    let bufferSelectionId = this.props.bufferSelection.Id;
    let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS)!;
    let bufferSelectionPrevState = _.get(placoSelections, bufferSelectionId);

    if (bufferSelectionPrevState) {
      if (!_.isEqual(this.props.bufferSelection, bufferSelectionPrevState)) {
        this.setState({ saveWarning: true });
      } else {
        this.backHandler();
      }
    } else {
      this.setState({ saveWarning: true });
    }
  };

  markSelectedStep = (stepType: string) => {
    return this.props.selectorType === stepType
      ? style.typeButtonSelected
      : style.typeButton;
  };

  handleDimmer = () => {
    this.setState({ active: !this.state.active });
  };

  // setScrollPosition = () => {
  //   if (this.solutionTable !== null) {
  //     const scrollPosition = this.solutionTable.current?.scrollTop;

  //     if (scrollPosition)
  //       localStorage.setItem("scrollPosition", scrollPosition?.toString());
  //   }

  // }

  setScrollPosition = () => {
    if (this.state.ref !== null) {
      const refValue: any = this.state.ref;
      const scrollPosition: any = refValue?.scrollTop;
      if (scrollPosition)
        localStorage.setItem("scrollPosition", scrollPosition?.toString());
    }
  };

  setScrollConfigPosition = () => {
    if (this.state.ref !== null) {
      const refValue: any = this.state.ref;
      const scrollPosition: any = refValue.scrollTop;
      if (scrollPosition) {
        localStorage.setItem(
          "scrollConfigPosition",
          scrollPosition?.toString()
        );
      }
    }
  };

  setPositionOnHover(event: any) {
    if (localStorage.getItem("scrollConfigPosition")) {
      setTimeout(() => {
        const refValue: any = this.state.ref;
        refValue?.scroll({
          top: Number(localStorage.getItem("scrollConfigPosition")),
          left: 0,
          behaviour: "auto",
        });
        localStorage.removeItem("scrollConfigPosition");
      });
    }
  }

  render() {
    const {
      wording: {
        selection,
        back,
        stepTwo,
        partitionWalls,
        conflictHeader,
        conflictContent,
        conflictButton,
        deleteWarningButton,
        deleteWarningContent,
        ceilings,
        others,
        addSystem,
        newManualSelection,
        newGroupSelection,
        enregistrer,
        closeWarningMessage,
        addOtherSystem,
        downloadError,
        toClose,
        save,
        yes,
        no,
        continuer,
        attention,
        saveSelection,
        assignSolution,
      },
      language,
      IconPath,
      selectorType,
      setSelectorType,
      setRoute,
      bufferSelection,
      selectionPar,
      module,
    } = this.props;

    return (
      bufferSelection && (
        <div
          style={{ height: "100%" }}
          onMouseOver={(e) => this.setPositionOnHover(e)}
        >
          <Modal
            size="tiny"
            open={this.state.active}
            // closeIcon
            // onClose={() => this.setState({ active: false })}
          >
            <Modal.Content>
              <div
                style={{
                  textAlign: "center",
                  padding: "2rem 1.6rem 1.6rem 1.6rem",
                }}
              >
                <p>
                  {downloadError[language]}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      onLinkClick("mailto:support.placobim@saint-gobain.com")
                    }
                  >
                    support.placobim@saint-gobain.com
                  </a>
                </p>
                <Button color="blue" onClick={() => this.handleDimmer()}>
                  {toClose[language]}
                </Button>
              </div>
            </Modal.Content>
          </Modal>

          <Modal
            size="mini"
            open={this.state.showConflictPopup}
            dimmer="blurring"
          >
            <Modal.Content>
              <div style={{ padding: "2rem", textAlign: "center" }}>
                <h3 style={{ color: "#2185d0" }}>{conflictHeader[language]}</h3>
                <div style={{ marginBottom: 20 }}>
                  {conflictContent[language]}
                </div>
                <Button
                  color="blue"
                  onClick={() => {
                    this.setState({
                      showConflictPopup: false,
                    });
                  }}
                >
                  {conflictButton[language]}
                </Button>
              </div>
            </Modal.Content>
          </Modal>
          {/* Warning popup if user added walls or cieling used in other zone */}
          <Modal
            size="small"
            open={this.state.showWallsCeilingsWillDeltetePopup}
            dimmer="blurring"
          >
            <Modal.Content>
              <div style={{ padding: "2rem", textAlign: "center" }}>
                <h3 style={{ color: "#2185d0" }}>{conflictHeader[language]}</h3>
                <div style={{ marginBottom: 20 }}>
                  {deleteWarningContent[language]}
                </div>
                <Button
                  color="blue"
                  onClick={() => {
                    this.setState({
                      showWallsCeilingsWillDeltetePopup: false,
                    });
                  }}
                >
                  {deleteWarningButton[language]}
                </Button>
              </div>
            </Modal.Content>
          </Modal>

          <Container text>
            <Header
              as="h3"
              content={bufferSelection.Name}
              image={IconPath}
              style={style.functionalityHeader}
            />
          </Container>
          <Grid columns={1} stackable className="SelectionTable">
            <Grid.Column style={style.stepBarColumn}>
              <Segment style={style.stepBar}>
                {ReactHtmlParser(stepTwo[language])}
              </Segment>
            </Grid.Column>
          </Grid>
          <Grid columns={1} stackable>
            <Grid.Row columns={2} style={style.gridRow}>
              <Grid.Column>
                <Segment
                  style={this.markSelectedStep("wall")}
                  onClick={() => setSelectorType("wall")}
                >
                  {partitionWalls[language]}
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment
                  style={this.markSelectedStep("ceiling")}
                  onClick={() => setSelectorType("ceiling")}
                >
                  {ceilings[language]}
                </Segment>
              </Grid.Column>
              {/* <Grid.Column>
                <Segment
                  style={this.markSelectedStep("others")}
                  onClick={() => setSelectorType("others")}
                >
                  {others[language]}
                </Segment>
              </Grid.Column> */}
            </Grid.Row>
            <Grid.Column>
              <div ref={this.onRefChange} style={style.solutionTable}>
                <SelectionMappingBody
                  setRoute={setRoute}
                  selectionPar={selectionPar}
                  module={module}
                  handleDimmer={this.handleDimmer}
                  setScrollPosition={this.setScrollPosition}
                  setScrollConfigPosition={this.setScrollConfigPosition}
                />
              </div>
            </Grid.Column>
          </Grid>
          <Grid columns={1} stackable>
            <Header
              as="a"
              content={
                selectorType === "others"
                  ? addOtherSystem[language]
                  : addSystem[language]
              }
              image={
                <Icon
                  name="plus circle"
                  color={selectorType === "others" ? "orange" : "blue"}
                  size="big"
                  style={{ fontSize: "18px" }}
                />
              }
              style={
                selectorType === "others"
                  ? { ...style.addSystem, ...{ color: "orange" } }
                  : style.addSystem
              }
              onClick={() => {
                selectorType === "others"
                  ? this.props.initOtherPlacoSystem()
                  : this.props.toggleBufferUpdate(true);
                localStorage.setItem("scrollPosition", "0");
              }}
            />
          </Grid>
          <Grid columns={1} stackable>
            <Grid.Column style={style.saveDiscardButtonsRow}>
              <Button
                onClick={this.saveOrDiscard}
                style={{ float: "left" }}
                color="orange"
              >
                {back[language]}
              </Button>
              <Button
                onClick={this.validateHandler}
                disabled={!this.state.enregister}
                primary
                style={{ float: "right" }}
              >
                {enregistrer[language]}
              </Button>
            </Grid.Column>
          </Grid>
          <Dimmer
            active={bufferSelection?.Update}
            style={{ position: "fixed" }}
          >
            <Segment style={{ width: 600, height: 150 }}>
              {/* <Header className="modalBg" style={{ backgroundColor: "#000" }}> */}
              <span
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => this.props.toggleBufferUpdate(false)}
                className="close-icon"
              >
                <Icon name="times"></Icon>
              </span>
              {/* </Header> */}

              <Container textAlign="center">
                <Grid>
                  <FunctionalityHeader
                    Icon={IconPath}
                    name={selection[language]}
                  />
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Button
                        onClick={this.manualSelectionHandler}
                        primary
                        size="big"
                        floated="right"
                        className="selectionBtn1"
                        style={{ height: 80 }}
                      >
                        {newManualSelection[this.props.language]}
                        {this.state.validateLoading === true ? (
                          <Oval
                            color="#00BFFF" //3 secs
                            height={16}
                            width={16}
                          />
                        ) : (
                          <div></div>
                        )}
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Button
                        onClick={this.groupSelectionHandler}
                        primary
                        size="big"
                        floated="left"
                        className="selectionBtn1"
                        style={{ height: 80 }}
                      >
                        {newGroupSelection[this.props.language]}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Segment>
          </Dimmer>
          <Modal open={this.state.saveWarning} size="mini" dimmer="inverted">
            <Modal.Header className="modalHeader">
              {save[language]}
              <Icon
                name="close"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    saveWarning: false,
                  });
                }}
              ></Icon>
            </Modal.Header>
            <Modal.Description
              style={{ textAlign: "center", padding: "15px 0px" }}
            >
              <p>{saveSelection[language]}</p>
            </Modal.Description>
            <div style={{ margin: "10px 0px", textAlign: "center" }}>
              <Button
                color="orange"
                size="tiny"
                onClick={() => this.backHandler()}
              >
                {no[language]}
              </Button>
              <Button
                size="tiny"
                type="submit"
                primary
                onClick={() => {
                  if (!this.state.enregister) {
                    this.setState({ fillSolutionWarning: true });
                  } else {
                    this.validateHandler().catch((error) => {
                      this.props.onError(
                        errorCodeKey.PB_SE_H_011,
                        error.stack,
                        true
                      );
                    });
                  }
                }}
              >
                {yes[language]}
              </Button>
            </div>
          </Modal>
          <Modal
            size="mini"
            dimmer="blurring"
            open={this.state.closeAlertModal}
            closeOnDimmerClick={false}
          >
            <Modal.Header style={{ backgroundColor: "blue" }}>
              {attention[language]}
            </Modal.Header>
            <Modal.Content>{closeWarningMessage[language]}</Modal.Content>
            <Modal.Actions>
              <Button
                negative
                onClick={() => this.setState({ closeAlertModal: false })}
              >
                {no[language]}
              </Button>
              <Button positive onClick={() => window.close()}>
                {yes[language]}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            size={"tiny"}
            open={this.state.fillSolutionWarning}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header
              style={{
                textAlign: "center",
                color: "#2185d0",
                borderBottom: "none",
              }}
            >
              {module}
            </Modal.Header>
            <Modal.Content
              style={{
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              <p>{assignSolution[language]}</p>
            </Modal.Content>
            <Modal.Actions
              style={{
                textAlign: "center",
                borderTop: "none",
              }}
            >
              <Button
                primary
                onClick={() => {
                  this.setState({
                    fillSolutionWarning: false,
                    saveWarning: false,
                  });
                }}
              >
                {continuer[language]}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      )
    );
  }
}

const style = {
  functionalityHeader: {
    textAlign: "center",
  },
  stepBar: {
    padding: "2px 0px 2px 5px",
    backgroundColor: "rgb(14,110,184)",
    color: "white",
  },
  stepBarColumn: {
    margin: "0px 1rem",
    paddingBottom: "5px",
    marginTop: "5px",
  },
  gridRow: {
    marginTop: "0px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0px",
  },
  typeButton: {
    textAlign: "center",
    padding: "0.5em 1em",
    fontWeight: "bold",
    border: "1px solid #a9a9a9",
    cursor: "pointer",
    backgroundColor: "#f0f0f0",
    color: "#a9a9a9",
  },
  typeButtonSelected: {
    textAlign: "center",
    padding: "0.5em 1em",
    fontWeight: "bold",
    border: "1px solid rgb(14,110,184)",
    cursor: "pointer",
    backgroundColor: "rgb(14,110,184)",
    color: "#fff",
  },
  solutionTable: {
    // margin: "0 1rem",
    // overflow: "auto",
    // height: "59vh",
    // "overflow-x": "hidden"
    "overflow-x": "hidden",
    "overflow-y": "auto",
    height: "calc(100vh - 260px)",
    margin: "0px 0px 0px 1rem",
    padding: "9px 10px 0px 0px",
  },
  addSystem: {
    margin: "0 1rem",
    fontSize: "14px",
    color: "rgb(14,110,184)",
    cursor: "pointer",
  },
  saveDiscardButtonsRow: {
    textAlign: "center",
    margin: "0 1rem",
  },
};

export default connector(SelectionMapping);
