import { AppWindowName } from "../assets/constants";
import { initializedProject } from "../models/Project.model";
import StateModel from "../models/State.model";

export let RootState: StateModel = {
    userInfo: {
        acceptedTermsOfUse: false, // Value stored in local storage (true first time -> false forever)
        skipIntro: false, // Value stored in local storage (true first time -> user can make it false forever)
    },
    projects: {
        projectsList: [], // Value stored in DB
        currentProject: { ...initializedProject }, // Can be persisted in DB
    },
    app: {
        currentWindow: AppWindowName.GENERAL_TERMS_OF_USE_WINDOW,
    },
};
