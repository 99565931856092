import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../Reducers";
import {
    SET_SELECTION_TREE,
    InitGroupSelectorAction,
    INIT_GROUP_SELECTOR,
    SelectTypeAction,
    SELECT_TYPE,
    SelectLevelAction,
    SELECT_LEVEL,
} from "./types";

import { ID, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { elementTypes, levelData } from "../../../../RevitJS/Helpers";
import { TypeData, SelectedItem } from "../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../RevitJS/API";
import { changeLanguageForUtils } from "../Helpers";

export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export interface InitLanguageAction {
    type: typeof INIT_LANGUAGE;
    language: Language;
}

export interface SetLanguageAction {
    type: typeof SET_LANGUAGE;
    language: Language;
}

export type LanguageAction = InitLanguageAction | SetLanguageAction;

export const setSelectionTree = (tree: ElementsTree) => {
    return {
        type: SET_SELECTION_TREE,
        tree,
    };
};

export const initGroupSelector = (
    tree: ElementsTree
): InitGroupSelectorAction => {
    return {
        type: INIT_GROUP_SELECTOR,
        tree,
    };
};

export const selectType = (
    typeName: string
): ThunkAction<void, DrawStore, unknown, SelectTypeAction> => async (
    dispatch,
    getState
) => {
    const { typesData, selectedTypes } = getState();
    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    let selectedTypeIndex = typesData.findIndex(
        (type) => type.Type === typeName
    );

    if (typesData[selectedTypeIndex].Checked) {
        typesDataCopy[selectedTypeIndex].Checked = false;
        selectedTypesCopy = selectedTypes.filter((type) => type !== typeName);
    } else {
        typesDataCopy[selectedTypeIndex].Checked = true;
        selectedTypesCopy = [...selectedTypes, typeName];
    }

    dispatch({
        type: SELECT_TYPE,
        typesData: typesDataCopy,
        selectedTypes: selectedTypesCopy,
    });
};

export const selectAllTypes = (
    check: boolean
): ThunkAction<void, DrawStore, unknown, SelectTypeAction> => async (
    dispatch,
    getState
) => {
    const { typesData, selectedTypes } = getState();

    let selectedTypesCopy: any[] = [];
    let typesDataCopy = typesData.map((type) => {
        type.Checked = check;
        if (check) {
            selectedTypesCopy.push(type.Type);
        }
        return type;
    });

    dispatch({
        type: SELECT_TYPE,
        typesData: typesDataCopy,
        selectedTypes: selectedTypesCopy,
    });
};

export const selectLevel = (
    levelName: string
): ThunkAction<void, DrawStore, unknown, SelectLevelAction> => async (
    dispatch,
    getState
) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    let selectedLevelIndex = levelsData.findIndex(
        (level) => level.Name === levelName
    );

    if (levelsData[selectedLevelIndex].Checked) {
        levelsDataCopy[selectedLevelIndex].Checked = false;
        selectedLevelsCopy = selectedLevels.filter(
            (level) => level !== levelName
        );
    } else {
        levelsDataCopy[selectedLevelIndex].Checked = true;
        selectedLevelsCopy = [...selectedLevels, levelName];
    }

    dispatch({
        type: SELECT_LEVEL,
        selectedLevels: selectedLevelsCopy,
        selectedTypes: [],
        levelsData: levelsDataCopy,
        typesData: elementTypes(
            levelData(selectionTree, selectedLevelsCopy)
        ) as TypeData[],
    });
};

export const selectAllLevels = (
    check: boolean
): ThunkAction<void, DrawStore, unknown, SelectLevelAction> => async (
    dispatch,
    getState
) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let selectedLevelsCopy: any[] = [];
    let levelsDataCopy = levelsData.map((level) => {
        level.Checked = check;
        if (check) {
            selectedLevelsCopy.push(level.Name);
        }
        return level;
    });
    dispatch({
        type: SELECT_LEVEL,
        selectedLevels: selectedLevelsCopy,
        selectedTypes: [],
        levelsData: levelsDataCopy,
        typesData: elementTypes(
            levelData(selectionTree, selectedLevelsCopy)
        ) as TypeData[],
    });
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English", "German"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage = (): ThunkAction<
    void,
    DrawStore,
    unknown,
    InitLanguageAction
> => async (dispatch) => {
    const setRevitLanguage = async () => {
        let revitLanguage = "English" as Language;//(await api.queries.getRevitLanguage()) as Language;
        changeLanguageForUtils(revitLanguage);
        localStorage.setItem("savedLanguage", revitLanguage);
        dispatch({
            type: INIT_LANGUAGE,
            language: revitLanguage,
        });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    console.log("Saved Lang " + savedLanguage);
    if (savedLanguage) {
        if (isLanguage(savedLanguage)) {
            dispatch({
                type: INIT_LANGUAGE,
                language: savedLanguage,
            });
        } else {
            setRevitLanguage();
        }
    } else {
        setRevitLanguage();
    }
};
