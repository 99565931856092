import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FunctionalityHeader from "../../../Common/components/FunctionalityHeader";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { Button, CircularProgress } from "@material-ui/core";
import { api } from "../../../../../RevitJS/API";
import PlacoCard from "../Common/PlacoCard";
import { selectWindowToShow } from "../../store/app/effects";
import { cleanCartSystem } from "../../store/cart/effects";
import { connect, ConnectedProps } from "react-redux";
import { APP_WINDOW_NAME } from "../../assets/constants";
import * as productsService from "../../services/project.service";
import { getCurrentConfiguration } from "../../../Properties/services/properties.service.local";
import * as propertiesService from "../../../Properties/services/properties.service";
import { CustomParameter } from "../../../../../RevitJS/Types/RevitTypes";
import AlertDialog from "../../../Common/components/AlertDialog";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../../Common/assets/attributes.injection";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";
import {
  fillSystemsModel,
  getDefaultConfigurationByType,
  getRelationofObjects,
} from "../../../Common/utils/attributes.injection.helpers";

import "./index.scss";
import _ from "lodash";

interface RenderCardProps {
  products?: ProductDetailData[] | undefined;
  onShowDetails?: any;
  onConfigurate?: any;
}

const RenderCard = ({ products }: RenderCardProps) => (
  <>
    {products &&
      products.map((product, index) => {
        let productName = product?.attributes?.find(
          (a: any) => a.technicalName === "A-Solution product name"
        )?.values[0]?.value;
        return (
          <PlacoCard
            productImage={product.thumbnailUrl}
            key={product.oid}
            index={index}
            oid={product.oid}
            productName={productName}
            technicalName={product.technicalName}
            system={product}
          ></PlacoCard>
        );
      })}
  </>
);

export const LoadSystemCartContainer: React.FC<
  LoadSystemCartContainerProps
> = ({
  cartSystemItems,
  cartCount,
  selectWindowToShow,
  cleanCartSystem,
  data,
  t,
  config,
}) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [progress, setProgress] = useState<boolean>(true);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    useEffect(() => {
      api.windowsHandler.resizeWindow(970, 600);
      // api.windowsHandler.showDevTools();
    }, []);

    const handleValidateCartContent = async () => {
      let elementData: CustomParameter[] = [];
      let selectedProperties: any = {};
      let systemProperties: any[] = [];
      let isDefaultConfiguration = false;
      console.log("isnide handle click");

      await propertiesService
        .loadProperties(Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME), config)
        .then((properties: any[]) => {
          systemProperties = properties;
        });
      await getCurrentConfiguration().then((properties) => {
        selectedProperties = properties?.propertiesConfiguration;
        isDefaultConfiguration = properties?.isDefaultConfiguration;
        if(selectedProperties && selectedProperties['gainesTechniquesProperties'] === undefined){
          selectedProperties['gainesTechniquesProperties'] = getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.GAINES_TECHNIQUES,
            systemProperties
          ) || [];
        }
      });

      if (!selectedProperties)
        selectedProperties = {
          liningWallsProperties:
            getDefaultConfigurationByType(
              PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
              systemProperties
            ) || [],
          partitionsProperties:
            getDefaultConfigurationByType(
              PAM_SYSTEM_TYPE_KEYS.WALL,
              systemProperties
            ) || [],
          ceilingsProperties:
            getDefaultConfigurationByType(
              PAM_SYSTEM_TYPE_KEYS.CEILING,
              systemProperties
            ) || [],
          gainesTechniquesProperties:
            getDefaultConfigurationByType(
              PAM_SYSTEM_TYPE_KEYS.GAINES_TECHNIQUES,
              systemProperties
            ) || [],
        };

      await getRelationofObjects(data, config);
      elementData = fillSystemsModel(
        selectedProperties,
        data,
        isDefaultConfiguration,
        t
      );
      let customParameterResult2 =
        (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[];
      elementData.filter(
        (system) =>
          !customParameterResult2.find(
            (element) => element?.solution === system?.solution
          )
      );

      console.log("elementData: ", elementData);
      if (!elementData) {
        setShowWarning(true);
      }
      setOpenDialog(true);

      await api.queries
        .createElementTypeWithCustomParameters(
          elementData,
          config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
        )
        .then(() => setProgress(false));

      if (elementData) {
        trackInfo(elementData, selectedProperties);
      } else {
        throw new Error("TraceabilityError: Element Data Empty");
      }
    };

    const trackInfo = async (
      elementData: CustomParameter[],
      selectedProperties:
        | {
          ceilingsProperties: any[];
          liningWallsProperties: any[];
          partitionsProperties: any[];
          gainesTechniquesProperties: any[];
        }
        | undefined
    ) => {
      let solutionList: any = elementData.map((o: any) => {
        return _.pick(o, ["elementType", "solution", "productId"]);
      });

      let generic = _.includes(
        solutionList[0].solution,
        "ou techniquement équivalent"
      );

      let attributeList = _.uniqBy(
        [
          {
            Type: "lining_walls",
            Properties: selectedProperties?.liningWallsProperties.map(
              (o: any) => {
                return _.pick(o, ["categoryName"]);
              }
            ),
          },
          {
            Type: "partition_walls",
            Properties: selectedProperties?.partitionsProperties.map((o: any) => {
              return _.pick(o, ["categoryName"]);
            }),
          },
          {
            Type: "ceiling",
            Properties: selectedProperties?.ceilingsProperties.map((o: any) => {
              return _.pick(o, ["categoryName"]);
            }),
          },
          {
            Type: "gainesTechnique",
            Properties: selectedProperties?.gainesTechniquesProperties.map((o: any) => {
              return _.pick(o, ["categoryName"]);
            }),
          },
        ],
        "Type"
      );

      let projectInfo: any = await api.queries.getProjectInformation();
      let projectName = projectInfo.Name;
      let projectData: any = await api.queries.getProjectData();
      let fileName = projectData.ProjectPath.split("\\").pop();
      let revitVersion = await api.framework.getRevitVersion();

      if (window.revit.isArchicad) {
        fileName = "";
        projectName = "";
        revitVersion = "";
      }

      api.eventLog.SetEvent({
        data: [
          {
            name: "Name of solutions",
            value: "",
            values: solutionList,
          },
          {
            name: "List of attributes",
            value: "",
            values: attributeList,
          },
          {
            name: "Generic",
            value: generic,
            values: [],
          },
          {
            name: "Information",
            value: "",
            values: [
              {
                File_Name: fileName,
                Project_Name: projectName,
                Version: revitVersion,
              },
            ],
          },
        ],
        eventAction: "Generate",
        eventCategory: "Module Execution",
        eventLabel:
          localStorage.getItem("moduleName") !== "PLACOBIM"
            ? "Family Creation"
            : "Library_Family Creation",
        module: localStorage.getItem("moduleName") as string, //"PRESCRIBIM",
      });
    };

    const handleCloseDialog = () => {
      cleanCartSystem();
      selectWindowToShow(APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW);
    };

    const handleBackToLoadSystems = () => {
      selectWindowToShow(APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW);
    };

    return (
      <div className="LoadSystemGlobalContainer">
        <FunctionalityHeader
          name={"Charger des systèmes dans le projet"}
          icon={config.REACT_APP_SERVERURL + "/PrescriBIM_Logo.png"}
        />
        <div className="CartSystemDetailsContainer">
          <div className="LoadSystemCardContainer">
            {cartSystemItems ? (
              <RenderCard products={cartSystemItems}></RenderCard>
            ) : (
              <CircularProgress className="LoadSystemCircularProgress" />
            )}
          </div>
        </div>
        <div className="LoadSystemButtonContainer">
          <Button
            className="loadActionButton detailActionButton"
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={() => handleBackToLoadSystems()}
          >
            Retour
          </Button>
          <Button
            className="loadActionButton detailActionButton"
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={() => handleValidateCartContent()}
          >
            Charger les systèmes sélectionnés
          </Button>
        </div>
        {showWarning ? (
          <AlertDialog
            open={openDialog}
            onClose={handleCloseDialog}
            setOpen={setOpenDialog}
            progress={progress}
            title={t("WARNING_LOAD_SYSTEMS")}
            content={t("WARNING_LOAD_SYSTEMS_MESSAGE")}
          />
        ) : (
          <AlertDialog
            open={openDialog}
            onClose={handleCloseDialog}
            setOpen={setOpenDialog}
            progress={progress}
            title={t("INFORMATION_LOAD_SYSTEMS")}
            content={t("INFORMATION_LOAD_SYSTEMS_MESSAGE")}
          />
        )}
      </div>
    );
  };

function mapStateToProps(state: any) {
  return {
    cartSystemItems: state.cart.currentSystemItems,
    cartCount: state.cart.cartCount,
    systemTypeID: state.systems.systemTypeID,
    searchKeyWords: state.systems.searchKeyWords,
    data: state.cart.data,
    config: state.config,
  };
}

const mapDispatchToProps = {
  selectWindowToShow,
  cleanCartSystem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoadSystemCartContainerProps = PropsFromRedux & WithTranslation;

export default connector(withTranslation(["library"])(LoadSystemCartContainer));
