import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Grid, Segment, Button } from "semantic-ui-react";
import { LevelsColumn } from "../../../../Components/Selector/LevelsColumn";
import { TypesColumn } from "../../../../Components/Selector/TypesColumn";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
    selectLevel,
    selectType,
    initMappingTable,
    selectAllLevels,
    selectAllTypes,
} from "../Actions/index";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { selectionBuffer } from "../../../RiggipsBIM/Draw2/Reducers";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props {
    selectorType: string;
    Icon: string;
    language: string;
    levelsData: LevelData[];
    typesData: TypeData[];
    routes: any;
    selectedLevels: string[];
    selectedTypes: string[];
    selectLevel: any;
    selectAllLevels: Function;
    selectType: any;
    selectAllTypes: Function;
    setRoute: any;
    initMappingTable: any;
    config:any;
}

interface State { }

export class GroupSelector extends React.Component<Props & WithTranslation, State> {
    componentDidMount = () => {
    };

    state = {};

    selectorTypes = () => {
        const { t } = this.props;

        switch (this.props.selectorType) {
            case "wall":
                return t('wallTypes');
            case "ceiling":
                return t('ceilingTypes');
            default:
                return t('wallTypes');
        }
    };

    backHandler = () => {
        this.props.setRoute(Routes.ROOT);
    };

    validateHandler = async () => {
        if (this.props.selectedTypes.length) {
            this.props.initMappingTable();
            await api.windowsHandler.resizeWindow(1010,  510);
            this.props.setRoute(Routes.MAPPING);
        }
    };

    render() {
        const { t } = this.props;
        return (
            <div id="draw2-groupSelector-container">
                <FunctionalityHeader
                    Icon={this.props.config.REACT_APP_SERVERURL + "/plugin_new_logo/ecophon-new-logo_icon.png"}
                    width={40}
                    color="grey"
                    height={40}
                    name={t('selection').toUpperCase()}
                />
                <div style={{ padding: 5, overflow: "auto", marginBottom: "15px" }}>
                    <div style={{ width: "100%", backgroundColor: "none" }}>
                        <div style={{ margin: "auto" }}>
                            <Grid
                                columns={2}
                                stackable
                                style={{
                                    backgroundColor: "none",
                                    height: 340,
                                    overflow: "auto",
                                }}
                                container
                                centered
                            >
                                <LevelsColumn
                                    selectLevel={this.props.selectLevel}
                                    selectAllLevels={this.props.selectAllLevels}
                                    columnTitle={t('levels')}
                                    levelsData={this.props.levelsData}
                                    color="yellow"
                                />
                                <TypesColumn
                                    selectType={this.props.selectType}
                                    selectAllTypes={this.props.selectAllTypes}
                                    selectedLevels={this.props.selectedLevels}
                                    columnTitle={t('ceilingTypes')}
                                    color="yellow"
                                    typesData={this.props.typesData}
                                />
                            </Grid>
                        </div>
                        <Segment basic style={{ marginBottom: 40 }}>
                            <Button
                                onClick={this.backHandler}
                                floated="left"
                                color="yellow"
                            >
                                {t('back')}
                            </Button>
                            <Button
                                onClick={this.validateHandler}
                                disabled={this.props.selectedTypes.length === 0}
                                floated="right"
                                color="yellow"
                            >
                                {t('validate')}
                            </Button>
                        </Segment>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        Icon: state.functionalityIcon,
        language: state.language,
        levelsData: state.levelsData,
        typesData: state.typesData,
        selectedLevels: state.selectedLevels,
        selectedTypes: state.selectedTypes,
        name: ownProps.name,
        setRoute: ownProps.setRoute,
        config:state.config
    };
};

export default connect(mapStateToProps, {
    selectType,
    selectLevel,
    selectAllLevels,
    selectAllTypes,
    initMappingTable,
})(withTranslation(["clt"])(GroupSelector))
