import React, { useState, useEffect } from "react";
import { Grid, Menu, Segment, Button } from "semantic-ui-react";
import GroupColumn from "./GroupColumn";
import { api } from "../../../../../RevitJS/API";
import {
  filter,
  cloneDeep,
  map,
  forEach,
  find,
  flattenDeep,
  reduce,
  includes,
  values,
} from "lodash";
import { getLevelElements } from "../../utils/selection";
import { IWording } from "../../../Types";
interface Props {
  onBack: () => void;
  onValidate: (elements: any, floors: any) => void;
}

interface Element {
  ElementName: string;
  ElementType: string;
  Ids: string[];
  Checked: boolean;
}

interface Elements {
  [key: string]: Element;
}

const wording: IWording = {
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
};

const GroupBox = (props: Props) => {
  const { onBack, onValidate } = props;
  const [activeItem, setActiveItem] = useState("wallType");
  const [levels, setLevels] = useState<any>(null);
  const [walls, setWalls] = useState<any>([]);
  const [elements, setElements] = useState<any>(null);
  const [selectedWallTypes, setSelectedWallTypes] = useState<any>([]);
  const [selectedWallThickness, setSelectedWallThickness] = useState<any>([]);

  const callBack = async (response: string, status: string) => {
    if (response) {
      const result = JSON.parse(response);
      const rLevels = map(result, "Levels");
      setLevels(rLevels);
      setElements(result);
    }
  };

  useEffect(() => {
    api.selection.getRevitElements(["wall"], callBack);
  }, []);

  useEffect(() => {
    if (levels && elements) {
      const walls = getLevelElements(levels, elements, activeItem);
      if (activeItem === "wallThickness") {
        Object.keys(walls).forEach((key) => {
          const wall = walls[key.toString()];
          const elementName = selectedWallThickness.find(
            (item2: any) => item2.ElementName === wall.ElementName
          );
          if (elementName) {
            walls[key].Checked = true;
          }
        });
      } else if (activeItem === "wallType") {
        Object.keys(walls).forEach((key) => {
          const wall = walls[key];
          const elementName = selectedWallTypes.find(
            (item2: any) => item2.ElementName === wall.ElementName
          );
          if (elementName) {
            walls[key].Checked = true;
          }
        });
      }
      const sortedKeys = Object.keys(walls).sort((a, b) => {
        const wallA = walls[a];
        const wallB = walls[b];
        if (wallB.Ids.length !== wallA.Ids.length) {
          return wallB.Ids.length - wallA.Ids.length;
        } else {
          return wallA.ElementName.localeCompare(wallB.ElementName);
        }
      });
      const sortedWalls: any = {};
      let i = 0;
      sortedKeys.forEach((key) => {
        sortedWalls[i] = walls[key];
        i++;
      });
      setWalls(sortedWalls);
    }
  }, [levels, elements, activeItem]);

  const onAllLevelChecked = (items: any, checked: boolean) => {
    let cloneLevels = cloneDeep(levels);
    cloneLevels.forEach((level: any) => {
      level.Checked = checked;
    });
    setLevels(cloneLevels);
  };

  const onLevelChecked = (item: any, checked: boolean) => {
    let cloneLevels = cloneDeep(levels);
    cloneLevels.forEach((level: any) => {
      if (level.Name === item.Name) level.Checked = checked;
    });
    setLevels(cloneLevels);
  };

  const onAllWallChecked = (items: any, checked: boolean) => {
    let cloneWalls = cloneDeep(walls);
    forEach(cloneWalls, (wall: any) => {
      wall.Checked = checked;
    });
    setWalls(cloneWalls);
    setSelectedWallThickness([]);
    setSelectedWallTypes([]);
  };

  const onWallChecked = (item: any, checked: boolean) => {
    let cloneWalls = cloneDeep(walls);
    forEach(cloneWalls, (wall: any) => {
      if (wall.ElementName === item.ElementName) wall.Checked = checked;
    });
    setWalls(cloneWalls);
    setSelectedWallThickness([]);
    setSelectedWallTypes([]);
  };

  const changeSelectionType = (param: string) => {
    let selectedWalls = filter(walls, { Checked: true });
    if (param === "wallThickness") {
      setSelectedWallTypes(selectedWalls);
    } else if (param === "wallType") {
      setSelectedWallThickness(selectedWalls);
    }
    setActiveItem(param);
  };

  const onProcessValidate = () => {
    if (activeItem === "wallThickness") {
      // const selectedWalls = filter(walls, (elem) => {
      //   return find(selectedLevels, { Id: elem.Levels.Id });
      // });
      const selectedWalls = flattenDeep(
        map(filter(walls, { Checked: true }), "Ids")
      );
      const elementType = flattenDeep(map(elements, "ElementsType"));
      const result1 = reduce(
        selectedWalls,
        function (result: any, value: any, key: any) {
          const wallType = find(elementType, (elem: any) =>
            includes(elem.Ids, value)
          );
          (
            result[wallType.ElementName] ||
            (result[wallType.ElementName] = {
              Checked: true,
              Ids: [],
              ElementName: wallType.ElementName,
            })
          ).Ids.push(value);
          return result;
        },
        {}
      );
      onValidate(values(result1), filter(levels, { Checked: true }));
    } else {
      onValidate(
        filter(walls, { Checked: true }),
        filter(levels, { Checked: true })
      );
    }
  };

  if (levels && walls) {
    // check all walls are selected
    const allWallsSelected: boolean =
      filter(walls, { Checked: false }).length === 0 ? true : false;

    // check all levels are selected
    const allLevelsSelected: boolean =
      filter(levels, { Checked: false }).length === 0 ? true : false;

    return (
      <>
        <Segment
          basic
          style={{
            border: "1px solid #d6d6d6",
            marginBottom: "10px",
            paddingTop: "10px",
            display: "block",
          }}
        >
          <div style={{ width: "100%", backgroundColor: "none" }}>
            <div>
              <Grid columns={2} stackable style={{ backgroundColor: "none" }}>
                {/* for level column */}
                <GroupColumn
                  data={levels}
                  showHeader={true}
                  headerText={"Levels"}
                  onChecked={onLevelChecked}
                  onAllChecked={onAllLevelChecked}
                  allChecked={allLevelsSelected}
                  disabled={false}
                />
                <Grid.Column>
                  {/* for wall type and thickness */}
                  <Menu
                    widths={2}
                    style={{ marginTop: "5px", marginBottom: "0px" }}
                  >
                    <Menu.Item
                      name="wallType"
                      active={activeItem === "wallType"}
                      onClick={(name) => changeSelectionType("wallType")}
                      className={`${
                        activeItem === "wallType"
                          ? "menuItemActive"
                          : "menuItemNotActive"
                      }`}
                    ></Menu.Item>
                    <Menu.Item
                      name="wallThickness"
                      active={activeItem === "wallThickness"}
                      onClick={(name) => changeSelectionType("wallThickness")}
                      className={`${
                        activeItem === "wallThickness"
                          ? "menuItemActive"
                          : "menuItemNotActive"
                      }`}
                    ></Menu.Item>
                  </Menu>
                  {activeItem === "wallThickness" ? (
                    <GroupColumn
                      data={walls}
                      showHeader={false}
                      onChecked={onWallChecked}
                      onAllChecked={onAllWallChecked}
                      allChecked={allWallsSelected}
                      disabled={
                        filter(levels, { Checked: true }).length > 0
                          ? false
                          : true
                      }
                    />
                  ) : (
                    <GroupColumn
                      data={walls}
                      showHeader={false}
                      onChecked={onWallChecked}
                      onAllChecked={onAllWallChecked}
                      allChecked={allWallsSelected}
                      disabled={
                        filter(levels, { Checked: true }).length > 0
                          ? false
                          : true
                      }
                    />
                  )}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </Segment>
        <Segment
          basic
          style={{
            display: "flex",
            justifyContent: "space-between",
            // position: "absolute",
            // bottom: 10,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Button color="orange" onClick={() => onBack()}>
            {wording.back["English"]}
          </Button>
          <Button
            primary
            onClick={() => onProcessValidate()}
            disabled={
              filter(walls, { Checked: true }).length > 0 ? false : true
            }
          >
            {wording.validate["English"]}
          </Button>
        </Segment>
      </>
    );
  }

  return <></>;
};

export default GroupBox;
