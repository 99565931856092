import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { IWording } from "../Types";

const wording: IWording = {
  header: {
    French: "Sauvegarder",
    English: "Save",
  },
  message: {
    French: "Veuillez enregistrer le projet avant de continuer",
    English: "Please save the project before continuing",
  },
  No: {
    French: "Non",
    English: "No",
  },
  Yes: {
    French: "Accepter",
    English: "Yes",
  },
};

interface Props {
  toggleModal: any;
  confirmModal: boolean;
  onYesAction: any;
  dontUpdateSelections?: any;
}

export const SaveProject = (props: Props) => {
  console.log(props.confirmModal);
  return (
    <Modal open={props.confirmModal} size="mini" dimmer="blurring">
      <Modal.Header className="modalHeader">
        {" "}
        {wording.header["English"]}
      </Modal.Header>
      <Modal.Description style={{ ...style.modalDescription }}>
        {wording.message["English"]}
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button
          size="tiny"
          type="submit"
          primary
          onClick={() => {
            props.onYesAction();
          }}
        >
          {wording.Yes["English"]}
        </Button>
      </div>
    </Modal>
  );
};

const style = {
    modalDescription: {
      textAlign: "center",
      padding: "15px 0px",
    },
  };
export default SaveProject;