import React, {Fragment} from 'react';
import TypeItem from './TypeItem';
import { TypeData } from '../../../../RevitJS/Types/StoreTypes';

type Props = {
    typesData: TypeData[],
    selectedLevels: string[]
}

export const TypeItems = (props: Props): JSX.Element | null =>{
    const {typesData, selectedLevels} = props;
    return (typesData ? 
    <Fragment>{typesData.map(item => <TypeItem selectedLevels={selectedLevels} key={item.Type} type={item.Type} count={item.Count} checked={item.Checked}/>)}</Fragment>
    : null)
};
