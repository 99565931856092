// EXAMPLES :

export const BRAND = "SAINT-GOBAIN PAM Batiment";

export const UK = "GBR";
export const FR = "FRA";

export const DATA_INJECTION_OPTIONS: any = [
  {
    label: "GLAND_PRESENCE_JUNCTION_1",
    checked: false,
    attribute_name: "GlandPresence1",
    param_group: "CONSTRUCTION",
    param_type:"Boolean"
  },
  {
    label: "LOCKING_STATE_JUNCTION_1",
    checked: false,
    attribute_name: "LockingState1",
    param_group: "CONSTRUCTION",
    param_type:"Boolean"
  },
  {
    label: "GLAND_PRESENCE_JUNCTION_2",
    checked: false,
    attribute_name: "GlandPresence2",
    param_group: "CONSTRUCTION",
    param_type:"Boolean"
  },
  {
    label: "LOCKING_STATE_JUNCTION_2",
    checked: false,
    attribute_name: "LockingState2",
    param_group: "CONSTRUCTION",
    param_type:"Boolean"
  },
  {
    label: "PN",
    checked: false,
    attribute_name: "PN",
    param_group: "DATA",
    param_type:"Integer"
  },
];
