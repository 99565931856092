import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TopHeader } from "../../Components/Headers";
import { SubHeader } from "../../Components/Headers";
import { CalepinerTable } from "../Components/Table/Calepiner";
import {
  Container,
  Grid,
  Icon,
  Popup,
  Button,
  Segment,
  Confirm,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { filter, find } from "lodash";

interface Props {
  setDetailRoute: any;
  data: any;
  onCalepinerValidate: any;
  onSelectAll: any;
}
export const Calpiner = (props: Props) => {
  const {
    setDetailRoute,
    data: { CalepinageDetails, SelectAll },
    onCalepinerValidate,
    onSelectAll,
  } = props;
  const { register, handleSubmit, watch, setValue, getValues, reset } =
    useForm();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true);
  const [studWarning, setStudWarning] = useState<boolean>(false);

  const createDefaultValues = () => {
    if (CalepinageDetails) {
      let isCheck = false;
      CalepinageDetails.forEach((wl: any, index: any) => {
        if (wl.Check) {
          isCheck = true;
        }
      });
      setIsValid(isCheck);
    }
  };

  useEffect(() => {
    createDefaultValues();
  }, [CalepinageDetails]);

  useEffect(() => {
    let isCheck = false;
    let isSelected = true;
    watch().CalepinageDetails.forEach((cd: any) => {
      if (cd.Check) {
        isCheck = true;
      } else {
        isSelected = false;
      }
    });

    setIsValid(isCheck);
    setIsAllSelected(isSelected);
  }, [watch()]);

  const onSelectionAll = (checked: any) => {
    let isCheck = false;
    CalepinageDetails.forEach((wl: any, index: any) => {
      if (wl.IsLayout) {
        setValue(`CalepinageDetails[${index}].Check`, checked);
      }
    });
    onSelectAll(checked);
  };

  const onCalpinerValidating = (data: any) => {
    const clDetails = filter(data.CalepinageDetails, { Check: true });
    const findStudDistancNot600 = find(
      clDetails,
      (dc: any) => dc.StudDistance === "400"
    );

    if (findStudDistancNot600) {
      setStudWarning(true);
    } else {
      onCalepinerValidate(data);
    }
  };

  const onStudDistanceConfirm = () => {
    const values = getValues();
    onCalepinerValidate(values);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onCalpinerValidating)}>
        <Segment style={{ height: "calc(100vh - 30pxpx)" }}>
          {CalepinageDetails.length === 0 ? (
            <div>No data found</div>
          ) : (
            <CalepinerTable
              data={CalepinageDetails}
              register={register}
              watch={watch}
              onSelectAll={onSelectionAll}
              isAllSelected={isAllSelected}
            />
          )}
        </Segment>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <span>
            <Button
              color="orange"
              onClick={() => setDetailRoute("")}
              content={"Back"}
            ></Button>
          </span>
          <span>
            <Button
              color="blue"
              content={"Validate"}
              type={"submit"}
              disabled={!isValid}
            ></Button>
          </span>
        </div>
      </form>
      <Confirm
        open={studWarning}
        content="Please note: by reducing the profile distance, the coupling between the cavity of GypFrame partition walls is increased. This reduces sound insulation. For more information view the Gyproc instructions or contact the Gyproc Helpdesk by telephone 0347-325165 or by e-mail to helpdesk@gyproc.nl"
        onCancel={() => setStudWarning(false)}
        onConfirm={() => onStudDistanceConfirm()}
      />
    </>
  );
};
