import _ from "lodash";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../Common/assets/attributes.injection";

export const fillAttributes = (systems, systemsToUpdate, t) => {
  const systemsDetails = [];
  systems.forEach((element) => {
    let systemToUpdate = systemsToUpdate.find(
      (system) => system.solution === element.translation
    );
    const attributes = systemToUpdate.paramData
      .filter(
        (att) =>
          att.key !== t(PAM_ATTRIBUTES_TECHNICAL_NAME.GENERATION_DATE) &&
          Object.keys(PAM_ATTRIBUTES_TECHNICAL_NAME).some(
            (a) => t(a) === att.key
          )
      )
      .map((att) => {
        const attributeKey = Object.keys(PAM_ATTRIBUTES_TECHNICAL_NAME).find(
          (a) => t(a) === att.key
        );
        const systemAtt = element.attributes.find((attribute) => {
          return (
            PAM_ATTRIBUTES_TECHNICAL_NAME[attributeKey] ===
            attribute.technicalName
          );
        });
        return {
          category: att.category,
          key: att.key,
          value:
            systemAtt?.values[0]?.numericValue ||
            systemAtt?.values[0]?.value ||
            null,
        };
      });
    systemsDetails.push({
      solution: element.translation,
      elementType: systemToUpdate.elementType,
      paramData: [...attributes],
    });
  });
  return systemsDetails;
};

export const fillOldNewProperties = (systems, systemsToUpdate, t) => {
  console.log("systems: ", systems);
  console.log("systemsToUpdate: ", systemsToUpdate);
  const systemsDetails = [];
  systems.forEach((element) => {
    let systemToUpdate = systemsToUpdate.find(
      (system) => system.solution === element.solution
    );

    const attributes = element.paramData
      .filter(
        (att) => att.key !== t(PAM_ATTRIBUTES_TECHNICAL_NAME.GENERATION_DATE)
      )
      .map((att) => {
        const systemAtt = systemToUpdate.paramData.find(
          (attribute) => attribute.key === att.key
        );
        return {
          propertyLabel: att.key,
          newValue: "" + (att?.value ? att?.value : null),
          oldValue: "" + (systemAtt?.value ? systemAtt?.value : null),
        };
      })
      .filter((att) => {
        return (
          att.oldValue
            .toString()
            .replace("®", "")
            .replace(/\s/g, "")
            .replace(".", ",")
            .toUpperCase() !==
          att.newValue
            .toString()
            .replace("®", "")
            .replace(/\s/g, "")
            .replace(".", ",")
            .toUpperCase()
        );
      });
    if (attributes.length > 0)
      systemsDetails.push({
        systemName: element.solution,
        systemProperties: [...attributes],
      });
  });
  return systemsDetails;
};
