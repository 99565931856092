import React, { useEffect } from "react";
import Landing from "./landing";
import NewCalepinage from "./newCalepinage";
import Parameters from "./parameters";
import Options from "./options";
import ContextFacade from "./contextFacade";
import { api } from "../../../../RevitJS/API";
import { useDispatch, useSelector } from "react-redux";
import { InitLanguage, SetLanguage } from "../../Store/Actions";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { SetProjectData } from "../../Store/Actions/projectAction";
import { MyConfig } from "../../../../Helper";

const App = (props: { route: string; setRoute: any }) => {
  const reduxState = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchProjectId() {
      let projectData: initProjectData = await api.queries.getSetProjectData();

      if (projectData.ProjectId === "") {
      } else {
        dispatch(SetProjectData(projectData));
      }
    }

    fetchProjectId();
    fetchConfig();
    dispatch(InitLanguage());
  }, []);

  const fetchConfig = async () => {
    const payload = await MyConfig();
    dispatch({ type: "SET_CONFIG", payload });
  };

  switch (props.route) {
    case "ROOT":
      return <Landing setRoute={props.setRoute} />;
    case "NEW_CALEPINAGE":
      return <NewCalepinage setRoute={props.setRoute} />;
    case "PARAMETERS":
      return <Parameters setRoute={props.setRoute} />;
    case "CONTEXT_DE_LA_FACADE":
      return <ContextFacade setRoute={props.setRoute} />;
    case "OPTIONS":
      return <Options setRoute={props.setRoute} />;
    default:
      return <Landing setRoute={props.setRoute} />;
  }
};

export default App;
