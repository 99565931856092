import {
  PlacoData,
  ProductDetailData,
  PIMLayoutAttributesAid,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import { ElementsTree, Language } from "../../../../RevitJS/Types/RevitTypes";
import {
  TypeData,
  LevelData,
  MappingRow,
  MappingRowRigips,
  MappingRowRigipsCeilings,
} from "../../../../RevitJS/Types/StoreTypes";
import { elementTypes } from "../../../../RevitJS/Helpers";

export const FETCH_PLACO_DATA = "INIT_PLACO_DATA";
export const SET_SELECTION_TREE = "SET_SELECTION_TREE";
export const INIT_GROUP_SELECTOR = "SET_GROUP_SELECTOR";
export const SELECT_TYPE = "SELECT_TYPE";
export const SELECT_LEVEL = "SELECT_LEVEL";
export const SELECT_ALL_LEVELS = "SELECT_ALL_LEVELS";

export const INIT_MAPPING_TABLE_WALLS = "INIT_MAPPING_TABLE_WALLS";
export const SELECT_SYSTEM = "SELECT_SYSTEM";
export const TOGGLE_CHECK = "TOGGLE_CHECK";
export const TOGGLE_ALL = "TOGGLE_ALL";
export const INIT_PRODUCT_SELECTOR = "INIT_PRODUCT_SELECTOR";
export const SET_SELECTION_BUFFER = "SET_SELECTION_BUFFER";
export const SET_ACTIVE_MAPPING_ROW_INDEX = "SET_ACTIVE_MAPPING_ROW_INDEX";
export const RESET_ACTIVE_MAPPING_ROW = "RESET_ACTIVE_MAPPING_ROW";
export const RESET_SELECTION_BUFFER = "RESET_SELECTION_BUFFER";
export const SEARCH = "SEARCH";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const INIT_LAYOUT_PROGRESS = "INIT_LAYOUT_PROGRESS";
export const UPDATE_LAYOUT_PROGRESS = "UPDATE_LAYOUT_PROGRESS";
export const START_LAYOUT = "START_LAYOUT";
export const END_LAYOUT = "END_LAYOUT";

export const INIT_LAYOUT_PROGRESS_CEILING = "INIT_LAYOUT_PROGRESS_CEILING";
export const UPDATE_LAYOUT_PROGRESS_CEILING = "UPDATE_LAYOUT_PROGRESS_CEILING";
export const START_LAYOUT_CEILING = "START_LAYOUT_CEILING";
export const END_LAYOUT_CEILING = "END_LAYOUT_CEILING";

export const FETCH_RIGIPS_DATA = "FETCH_RIGIPS_DATA";
export const SET_FIRE_CONSTRAIN = "SET_FIRE_CONSTRAIN";
export const SET_SWITCH_PAREMENT = "SET_SWITCH_PAREMENT";
export const SET_REVERSE = "SET_REVERSE";
export const SET_PUBLIC_CONSTRAIN = "SET_PUBLIC_CONSTRAIN";
export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const TOGGLE_CHECK_CEILINGS = "TOGGLE_CHECK_CEILINGS";
export const SELECT_SYSTEM_CEILINGS = "SELECT_SYSTEM_CEILINGS";
export const TOGGLE_ALL_CEILINGS = "TOGGLE_ALL_CEILINGS";
export const SET_FIRE_CONSTRAIN_CEILINGS = "SET_FIRE_CONSTRAIN_CEILINGS";
export const SET_ADDITIONAL_WEIGHT_CEILINGS = "SET_ADDITIONAL_WEIGHT_CEILINGS";
export const FETCH_RIGIPS_DATA_CEILINGS = "FETCH_RIGIPS_DATA_CEILINGS";

export const INIT_GROUP_SELECTOR_CEILING = "INIT_GROUP_SELECTOR_CEILING";
export const SELECT_TYPE_CEILING = "SELECT_TYPE_CEILING";
export const SELECT_LEVEL_CEILING = "SELECT_LEVEL_CEILING";
export const SET_SELECTION_TREE_CEILING = "SET_SELECTION_TREE_CEILING";

export const INIT_MAPPING_TABLE_CEILINGS = "INIT_MAPPING_TABLE_CEILINGS";

export type selectorTp = "wall" | "ceiling" | "others";

export interface InitLanguageAction {
  type: typeof INIT_LANGUAGE;
  language: Language;
}

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: Language;
}

export type LanguageAction = InitLanguageAction | SetLanguageAction;

export interface FetchDataAction {
  type: typeof FETCH_PLACO_DATA;
  liningWallsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  partitionsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  layoutAttributesAid: PIMLayoutAttributesAid;
}

export interface SetSelectionTreeAction {
  type: typeof SET_SELECTION_TREE;
  tree: ElementsTree;
}

export interface SetSelectionTreeCeilingAction {
  type: typeof SET_SELECTION_TREE_CEILING;
  tree: ElementsTree;
}

export interface InitGroupSelectorAction {
  type: typeof INIT_GROUP_SELECTOR;
  tree: ElementsTree;
}

export interface InitGroupSelectorCeilingAction {
  type: typeof INIT_GROUP_SELECTOR_CEILING;
  tree: ElementsTree;
}

export interface SelectLevelAction {
  type: typeof SELECT_LEVEL;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectTypeCeilingAction {
  type: typeof SELECT_TYPE_CEILING;
  typesData: TypeData[];
  selectedTypes: string[];
}

export interface SelectLevelCeilingAction {
  type: typeof SELECT_LEVEL_CEILING;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectTypeAction {
  type: typeof SELECT_TYPE;
  typesData: TypeData[];
  selectedTypes: string[];
}

export interface InitMappingTableCeilingsAction {
  type: typeof INIT_MAPPING_TABLE_CEILINGS;
  mappingRowsCeilings: MappingRowRigipsCeilings[];
}

export interface InitMappingTableWallsAction {
  type: typeof INIT_MAPPING_TABLE_WALLS;
  mappingRowsWalls: MappingRowRigips[];
}

export interface SelectSystemAction {
  type: typeof SELECT_SYSTEM;
  index: number;
  system: ProductDetailData;
}

export interface SelectSystemCeilingsAction {
  type: typeof SELECT_SYSTEM_CEILINGS;
  index: number;
  system: ProductDetailData;
}

export interface ToggleCheckAction {
  type: typeof TOGGLE_CHECK;
  Index: number;
}

export interface ToggleAllAction {
  type: typeof TOGGLE_ALL;
  checked: boolean;
}

export interface ToggleCheckCeilingsAction {
  type: typeof TOGGLE_CHECK_CEILINGS;
  Index: number;
}

export interface ToggleAllCeilingsAction {
  type: typeof TOGGLE_ALL_CEILINGS;
  checked: boolean;
}

export interface InitProductSelectorAction {
  type: typeof INIT_PRODUCT_SELECTOR;
  displayedElements: ProductDetailData[];
  filteredElements: ProductDetailData[];
}

export interface SetSelectionBufferAction {
  type: typeof SET_SELECTION_BUFFER;
  system: ProductDetailData;
}

export interface SetActiveRowIndexAction {
  type: typeof SET_ACTIVE_MAPPING_ROW_INDEX;
  index: number;
}

export interface ResetActiveRowAction {
  type: typeof RESET_ACTIVE_MAPPING_ROW;
}

export interface ResetSelectionBufferAction {
  type: typeof RESET_SELECTION_BUFFER;
}

export interface SearchAction {
  type: typeof SEARCH;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  searchedWord: string;
}

export interface ApplyFiltersAction {
  type: typeof APPLY_FILTERS;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  filters: { partitions: any[]; liningWalls: any[] };
}

export interface ResetFiltersAction {
  type: typeof RESET_FILTERS;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  filters: { partitions: any[]; liningWalls: any[] };
}

export interface InitLayoutProgressAction {
  type: typeof INIT_LAYOUT_PROGRESS;
}

export interface UpdateLayoutProgressAction {
  type: typeof UPDATE_LAYOUT_PROGRESS;
  progress: number;
}

export interface StartLayoutAction {
  type: typeof START_LAYOUT;
}

export interface EndLayoutAction {
  type: typeof END_LAYOUT;
}

export interface InitLayoutProgressCeilingAction {
  type: typeof INIT_LAYOUT_PROGRESS_CEILING;
}

export interface UpdateLayoutProgressCeilingAction {
  type: typeof UPDATE_LAYOUT_PROGRESS_CEILING;
  progress: number;
}

export interface StartLayoutCeilingAction {
  type: typeof START_LAYOUT_CEILING;
}

export interface EndLayoutCeilingAction {
  type: typeof END_LAYOUT_CEILING;
}

export interface FetchRigipsDataAction {
  type: typeof FETCH_RIGIPS_DATA;
  products: ProductMeta[];
}

export interface FetchRigipsDataCeilingsAction {
  type: typeof FETCH_RIGIPS_DATA_CEILINGS;
  products: ProductMeta[];
}

export interface SetFireConstrainAction {
  type: typeof SET_FIRE_CONSTRAIN;
  Index: number;
  FireConstrain: boolean;
}

export interface SetFireConstrainCeilingsAction {
  type: typeof SET_FIRE_CONSTRAIN_CEILINGS;
  Index: number;
  FireConstrain: boolean;
}

export interface SetSwitchParementAction {
  type: typeof SET_SWITCH_PAREMENT;
  Index: number;
  SwitchParement: boolean;
}

export interface SetReverseAction {
  type: typeof SET_REVERSE;
  Index: number;
  Reverse: boolean;
}

export interface SetPublicConstrainAction {
  type: typeof SET_PUBLIC_CONSTRAIN;
  Index: number;
  PublicConstrain: boolean;
}

export interface SetAdditionalWeightCeilingsAction {
  type: typeof SET_ADDITIONAL_WEIGHT_CEILINGS;
  Index: number;
  additionalWeight: boolean;
}

export type ConstrainAction =
  | SetReverseAction
  | SetSwitchParementAction
  | SetFireConstrainAction
  | SetPublicConstrainAction;

export type DataAction = FetchDataAction | FetchRigipsDataAction;

export type FilterAction = FetchDataAction;

export type SelectionTreeAction = SetSelectionTreeAction;

export type SelectionTreeCeilingAction = SetSelectionTreeCeilingAction;

export type SelectorAction =
  | InitGroupSelectorAction
  | SelectLevelAction
  | SelectTypeAction;

export type SelectorCeilingAction =
  | InitGroupSelectorCeilingAction
  | SelectLevelCeilingAction
  | SelectTypeCeilingAction;

export type MappingTableWallsAction =
  | InitMappingTableWallsAction
  | SelectSystemAction
  | ToggleAllAction
  | ToggleCheckAction
  | ConstrainAction;

export type MappingTableCeilingsAction =
  | InitMappingTableCeilingsAction
  | SelectSystemCeilingsAction
  | ToggleAllCeilingsAction
  | ToggleCheckCeilingsAction
  | SetFireConstrainCeilingsAction
  | SetAdditionalWeightCeilingsAction;

export type ProductSelectorAction =
  | InitProductSelectorAction
  | SetSelectionBufferAction
  | SetActiveRowIndexAction
  | ResetActiveRowAction
  | ResetSelectionBufferAction
  | SearchAction
  | ApplyFiltersAction
  | ResetFiltersAction;

export type LayoutAction =
  | InitLayoutProgressAction
  | UpdateLayoutProgressAction
  | StartLayoutAction
  | EndLayoutAction;

  export type LayoutCeilingAction =
  | InitLayoutProgressCeilingAction
  | UpdateLayoutProgressCeilingAction
  | StartLayoutCeilingAction
  | EndLayoutCeilingAction;
