import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { PamRoot } from "./components/root";

export const functionalityId = "bce977ee-25fb-46d6-9e1b-be4bc00afee3";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    620,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Load",
    module: "PAMDI",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <PamRoot />
    </ParentRoot>
  );
};

export const PamImportRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <PamRoot />
    </ParentRoot>
  );
};

export const PamImport: Functionality = {
  Name: {
    French: "PAM® Import des données",
    English: "PAM® Data Import",
    German: "PAM® Data Import",
    Italian: "PAM® Data Import",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Données BIM",
    English: "BIM Data",
    German: "BIM Data",
    Italian: "BIM Data",
  },
  Id: functionalityId,
  Icon: "/pluginIcons/logo_pam_functionality.png",
  RootComponent,
  ParentId: "0",
};
