import {
  faPencilAlt,
  faClone,
  faTrash,
} from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import {
  Icon,
  Checkbox,
  Radio,
  Input,
  Popup,
  Button,
  Modal,
} from "semantic-ui-react";
import "../../../Resources/index.css";
import { isEmpty } from "lodash";
import { ColorPicker } from "../../ColorPicker/ColorPicker";
import { getPrintDate } from "../../../../Utils";

interface Props {
  data: any;
  onColorChanged: any;
  onRowEdit: any;
  onRowDuplicate: any;
  onRowDeleteIconClick: any;
  editId: any;
  editName: any;
  setEditName: any;
  editSelectionName: any;
  eyeHandler: any;
  updateSelectionname: any;
  errorMessage: any;
  setEditedNameValue: any;
}

export const HomeTableBody = (props: Props) => {
  const { data, onRowEdit, onColorChanged, onRowDeleteIconClick } = props;
  const { Name, Floors, Zone, Date, Id, Color } = data;
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  return (
    <div
      className="row-hover"
      style={{
        padding: "5px",
        minHeight: "25px",
        display: "flex",
        alignItems: "center",
        borderBottom: "0.5px solid white",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          display: "flex",
          width: "5%",
          color: "black",
          border: "0.2px",
        }}
      >
        {/* eye or loader */}
        <Popup
          trigger={
            <div
              onClick={() => {
                props.eyeHandler(Id, Name.trim(), Color);
              }}
            >
              <Icon name="eye" color="black" />
            </div>
          }
          // <Icon name="eye" color="black" />}
          content="View the selection by colorization"
          on="hover"
          inverted
          size="mini"
          position="bottom left"
        />
      </div>

      {/* ////////////////////////start//////////////////////// */}
      {props.editId !== Id && (
        <Popup
          trigger={
            <div
              style={{
                fontWeight: "bold",
                display: "block",
                width: "30%",
                color: "black",
                cursor: "pointer",
                wordWrap: "break-word",
                overflow: "hidden",
              }}
              onClick={() => {
                props.editSelectionName(Id, Name.trim());
              }}
            >
              <div>{Name}</div>
            </div>
          }
          content="Click to edit name"
          on="hover"
          inverted
          size="mini"
          position="bottom center"
        />
      )}

      {props.editId === Id && (
        <Popup
          trigger={
            <div
              style={{
                justifyContent: "center",
                fontWeight: "bold",
                display: "flex",
                width: "35%",
                color: "black",
                border: "0.2px",
                cursor: "pointer",
              }}
            >
              <div>
                <Input
                  type="text"
                  placeholder="Search..."
                  value={props.editName}
                  onChange={(event: any) => {
                    props.setEditName(event.target.value);
                    props.setEditedNameValue(event.target.value);
                    localStorage.setItem("isModification", "true");
                  }}
                  action
                  style={{ width: "100%" }}
                >
                  <input />
                  <Popup
                    trigger={
                      <Button
                        icon
                        onClick={() => {
                          props.updateSelectionname(Id, props.editName);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    }
                    content="Validate name change"
                    on="hover"
                    inverted
                    size="mini"
                    position="bottom center"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon
                        onClick={() => {
                          props.editSelectionName("", "");
                        }}
                      >
                        <Icon name="close" />
                      </Button>
                    }
                    content="Undo name change"
                    on="hover"
                    inverted
                    size="mini"
                    position="bottom center"
                  />
                </Input>
                {props.errorMessage !== "" && (
                  <div style={{ fontSize: "9px", color: "red" }}>
                    {props.errorMessage}
                  </div>
                )}
              </div>
            </div>
          }
          content="Click to edit name"
          on="hover"
          inverted
          size="mini"
          position="bottom center"
        />
      )}

      {/* //////////////////end//////////////////////// */}
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "15%",
          color: "black",
          display: "flex",
        }}
      >
        <div> {Floors.map((y: any) => y.Name).join(",")}</div>
      </div>

      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "20%",
          color: "black",
          display: "flex",
        }}
      >
        {Zone ? "Zone" : "Sélection"}
      </div>
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "15%",
          color: "black",
          display: "flex",
        }}
      >
        <div>{getPrintDate(Date)}</div>
      </div>

      {/* for color picker */}
      <div
        style={{
          display: "inline-flex",
          alignContent: "center",
          justifyContent: "center",
          width: "15%",
          alignItems: "center",
        }}
      >
        <ColorPicker
          onColorChanged={(color) => onColorChanged(Id, color)}
          color={Color}
        />

        <div
          className="editIcon"
          style={{
            marginRight: "10px",
          }}
          onClick={(index) => onRowEdit(Id)}
        >
          <Popup
            trigger={<FontAwesomeIcon icon={faPencilAlt as IconProp} />}
            content="Edit"
            on="hover"
            inverted
            size="mini"
            position="bottom center"
          />
        </div>
        <div
          className="cloneIcon"
          style={{
            marginRight: "10px",
          }}
          onClick={(index) => props.onRowDuplicate(Id)}
        >
          <Popup
            trigger={<FontAwesomeIcon icon={faClone as IconProp} />}
            content="Duplicate"
            on="hover"
            inverted
            size="mini"
            position="bottom left"
          />
        </div>

        <div
          className="trashIcon"
          onClick={(index) => onRowDeleteIconClick(Id)}
        >
          <Popup
            trigger={<FontAwesomeIcon icon={faTrash as IconProp} />}
            content="Delete"
            on="hover"
            inverted
            size="mini"
            position="bottom right"
          />
        </div>
      </div>

      <Modal size="tiny">
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>
              Vous allez modifier une sélection utilisée pour un dossier
              technique ou une configuration de calepinage. Ça pourra impacter
              la configuration sauvegardé. Veuillez revérifier après votre
              modification
            </p>
            <Button color="orange">Annuler</Button>
            <Button color="blue">Continuer</Button>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};
