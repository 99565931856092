
import { CLOISONS_ID } from "../../assets/constants";
import {
  PropertiesState,
  PropertiesActionTypes,
  SET_DEFAULT_CONFIGURATION,
  LOAD_PROPERTIES,
  LOAD_PROPERTIES_SUCCESS,
  LOAD_PROPERTIES_ERROR,
  SET_PROPERTIES_DETAILS,
  SELECT_PROPERTIES
} from "./types";

const initialState: PropertiesState = {
  loadingProperties: true,
  properties: [],
  currentSelectedProperties: [],
  currentPropertiesDetail: [],
  data: {
    liningWallsProperties: [],
    partitionsProperties: [],
    ceilingsProperties: [],
    gainesProperties: [],
  },
  selectedProperties: {
    liningWallsProperties: [],
    partitionsProperties: [],
    ceilingsProperties: [],
    gainesProperties: [],
  },
  isDefaultConfiguration: false,
  systemTypeID: CLOISONS_ID,
};

export default function propertiesReducer(
  state = initialState,
  action: PropertiesActionTypes
): PropertiesState {
  switch (action.type) {
    case LOAD_PROPERTIES:
      return {
        ...state,
        loadingProperties: true,
      };
    case LOAD_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.properties,
        currentPropertiesDetail: action.currentPropertiesDetail,
        data: {
          gainesProperties: action.gainesProperties,
          liningWallsProperties: action.liningWallsProperties,
          partitionsProperties: action.partitionsProperties,
          ceilingsProperties: action.ceilingsProperties,
        },
        currentSelectedProperties: action.currentSelectedProperties,
        selectedProperties: { ...action.selectedProperties },
        loadingProperties: false,
      };
    case LOAD_PROPERTIES_ERROR:
      return {
        ...state,
        loadingProperties: false,
      };
    case SET_PROPERTIES_DETAILS:
      return {
        ...state,
        currentPropertiesDetail: [...action.currentPropertiesDetails],
        currentSelectedProperties: [...action.selectedProperties],
        systemTypeID: action.systemType,
        loadingProperties: false,
      };
    case SELECT_PROPERTIES:
      return {
        ...state,
        currentSelectedProperties: [...action.currentSelectedProperties],
        currentPropertiesDetail: [...action.currentPropertiesDetail],
        data: { ...action.data },
        selectedProperties: { ...action.selectedProperties },
      };
    case SET_DEFAULT_CONFIGURATION:
      return {
        ...state,
        isDefaultConfiguration: action.defaultConfiguration,
      };
    default:
      return state;
  }
}
