import { bimStorage, storageKey } from "../../../../BIMStore";
import { db } from "./indexedDb";

export async function getFavorites() {
  let favoritesToReturn: any[] = [];
  // await db.transaction("r", [db.favorites], async () => {
  //   await db.favorites.each((favorite) => {
  //     favoritesToReturn.push(favorite);
  //   });
  // });
  favoritesToReturn = await bimStorage.getItem(storageKey.WEBERINDONESIA_FAVORITE);
  return favoritesToReturn;
}

export async function clearFavoris() {
  await getFavorites().then(async (results) => {
    // await db.transaction("rw", [db.favorites], async () => {
    //   return await db.favorites.clear();
    // })
    return bimStorage.setItem(storageKey.WEBERINDONESIA_FAVORITE, JSON.stringify([]));
  });
}

export async function AddFavorite(favorite: any) {
  // await getFavorites().then(async (results) => {
  //   let fav = results.find((f) => f.oid === favorite.oid);
  //   if (!fav) {
  //     await db.transaction("rw", [db.favorites], async () => {
  //       return await db.favorites.add(favorite);
  //     });
  //   }
  // });
  //let favoriteData = await bimStorage.getItem(storageKey.WEBERINDONESIA_FAVORITE)
  await getFavorites().then(async (results) => {
    if (!results) {
      return bimStorage.setItem(storageKey.WEBERINDONESIA_FAVORITE, JSON.stringify([favorite]));
    }
    else {
      let fav = results.find((f: any) => f.oid === favorite.oid);
      if (!fav) {
        return bimStorage.setItem(storageKey.WEBERINDONESIA_FAVORITE, JSON.stringify([...results, favorite]));
      }
    }
  });
}

export async function RemoveFavorite(favorite: any) {
  // await getFavorites().then(async (results) => {
  //   let fav = results.find((f) => f.oid === favorite.oid);
  //   if (fav) {
  //     await db.transaction("rw", [db.favorites], async () => {
  //       return await db.favorites.delete(fav.id);
  //     });
  //   }
  // });
  await getFavorites().then(async (results) => {
    let index = results.findIndex((f) => f.oid === favorite.oid);
    if (index > -1) {
      results.splice(index, 1);
      bimStorage.setItem(storageKey.WEBERINDONESIA_FAVORITE, JSON.stringify([...results]));
    }
  });
  return favorite;
}
