import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Table,
  Button,
  Loader,
  Dimmer,
  Segment,
  Header,
  Checkbox,
} from "semantic-ui-react";
import { SelectionStore } from "../Reducers";
import { FilterType } from "../../../../RevitJS/Types/StoreTypes";
import { showLayoutDetails, hideLayoutDetails } from "../Actions";
import { parseSystem } from "../../../../RevitJS/Helpers";
import { SolutionFilterType, ProductDetailData } from "../Types";
import { PIMLayoutAttributesAid } from "../../../../RevitJS/Types/BddTypes";
import { getLayoutAttributesAid } from "../Helpers";

const mapStateToProps = (state: SelectionStore) => {
  const { filterType, filters } = state;
  let selectedFilters = ((
    filterType: FilterType,
    filters: SolutionFilterType
  ) => {
    if (filterType === "partitionWall") {
      return state.filters.partitionWalls;
    }
    if (filterType === "liningWall") {
      return state.filters.liningWalls;
    }
    return state.filters.ceilings;
  })(filterType, filters);
  
  return {
    placoData: state.placoData,
    filterType: state.filterType,
    selectionBuffer: state.selectionBuffer,
    filters: selectedFilters,
    language: state.language,
    layoutDetailsVisible: state.layoutDetailsVisible,
    selectorType: state.selectorType,
    placoSGObjectAttribute: state.placoSGObjectAttribute
  };
};

const mapDispatchToProps = {
  showLayoutDetails: showLayoutDetails,
  hideLayoutDetails: hideLayoutDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  selectionBuffer: ProductDetailData | null;
  filters: any[];
  language: string;
  wording: {
    sideA: { [key: string]: string };
    sideB: { [key: string]: string };
    lining: { [key: string]: string };
    SAA: { [key: string]: string };
    SAD: { [key: string]: string };
  };
  placoSGObjectAttribute: any;
};

const row = (key: any, value: string) => {
  if (key) {
    return (
      <Table.Row key={key}>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
      </Table.Row>
    );
  }
  return null;
};

export class ConfigurationView extends React.Component<Props> {
  componentDidMount = () => {};

  public static defaultProps = {
    wording: {
      sideA: {
        French: "Parement A",
        English: "Side A",
      },
      sideB: {
        French: "Parement B",
        English: "Side B",
      },
      lining: {
        French: "Doublage",
        English: "Lining",
      },
      SAA: {
        French: "SAA",
        English: "SAA",
      },
      SAD: {
        French: "SAD",
        English: "SAD",
      },
    },
    lining: false,
    E1: 12.5,
    E1_Name: "Aquaroc 13",
    E2: 12.5,
    E2_Name: "Aquaroc 13",
    E3: 12.5,
    E3_Name: "Aquaroc 13",
    I1: 12.5,
    I1_Name: "Aquaroc 13",
    I2: 12.5,
    I2_Name: "Aquaroc 13",
    SAA: false,
    SAD: true,
  };

  render() {
    const sideA = (systemConf: any) => {
      if (systemConf.lining)
        return (
          <div>
            <Checkbox
              checked
              label={this.props.wording.lining[this.props.language]}
              readOnly
            />
            <br />
            <br />
          </div>
        );
      return (
        <div>
          <Header>{this.props.wording.sideA[this.props.language]}</Header>
          <Table celled>
            <Table.Body>
              {systemConf.E1_Name !== "None"
                ? row(systemConf.E1_Name, systemConf.E1)
                : null}
              {systemConf.E2_Name !== "None"
                ? row(systemConf.E2_Name, systemConf.E2)
                : null}
              {systemConf.E3_Name !== "None"
                ? row(systemConf.E3_Name, systemConf.E3)
                : null}
            </Table.Body>
          </Table>
          <br />
        </div>
      );
    };

    const sideB = (systemConf: any) => {
      if (systemConf.lining)
        return (
          <div>
            {/* <Checkbox
              checked
              label={this.props.wording.lining[language]}
              readOnly
            /> */}
            <br />
            <br />
          </div>
        );
      return (
        <div>
          <Header>{this.props.wording.sideB[this.props.language]}</Header>
          <Table celled>
            <Table.Body>
              {systemConf.I1_Name !== "None"
                ? row(systemConf.I1_Name, systemConf.I1)
                : null}
              {systemConf.I2_Name !== "None"
                ? row(systemConf.I2_Name, systemConf.I2)
                : null}
              {systemConf.I3_Name !== "None"
                ? row(systemConf.I3_Name, systemConf.I3)
                : null}
            </Table.Body>
          </Table>
          <br />
        </div>
      );
    };

    const SAASAD = (systemConf: any) => {
      if (systemConf.SAA)
        return (
          <div>
            <Checkbox
              checked
              label={this.props.wording.SAA[this.props.language]}
              readOnly
            />
            <br />
            <br />
          </div>
        );
      if (systemConf.SAD)
        return (
          <div>
            <Checkbox
              checked
              label={this.props.wording.SAD[this.props.language]}
              readOnly
            />
            <br />
            <br />
          </div>
        );
      return null;
    };

    const details = (systemConf: any) => [
      // <SideA systemConf={systemConf} wording={this.props.wording} />,
      sideA(systemConf),
      sideB(systemConf),
      SAASAD(systemConf),
    ];

    const center = () => {
      if (
        // this.props.placoData.layoutAttributesAid &&
        this.props.placoData &&
        this.props.selectionBuffer?.oid &&
        this.props.layoutDetailsVisible === true
      ) {
        if (this.props.selectionBuffer) {

          let layoutAttributesAid = getLayoutAttributesAid(
            this.props.placoSGObjectAttribute
          ) as PIMLayoutAttributesAid;

          let systemConf = parseSystem(
            this.props.selectionBuffer,
            layoutAttributesAid
          );
          return details(systemConf);
        } else {
          return (
            <Segment basic>
              <Dimmer inverted={true} active>
                <Loader />
              </Dimmer>
            </Segment>
          );
        }
      }
      return (
        <Segment basic>
          <Dimmer inverted={true} active>
            <Loader />
          </Dimmer>
        </Segment>
      );
    };

    return (
      <Modal open={this.props.layoutDetailsVisible} dimmer="blurring">
        <Modal.Header>{this.props.selectionBuffer?.translation}</Modal.Header>
        <Modal.Content>
          <Modal.Description
            style={{
              minHeight: "4em",
              maxHeight: "36.25em",
              overflowY: "scroll",
            }}
          >
            {/* {() => center()} */}
            {center()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={(e) => {
              this.props.hideLayoutDetails();
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connector(ConfigurationView);
