import isEqual from "lodash/isEqual";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import remove from "lodash/remove";
import concat from "lodash/concat";
import uniq from "lodash/uniq";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Icon } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import { EditSelectionButton } from "../../CommonModules/Selection/EditSelectionBtn";
import { List } from "../../CommonModules/Selection/List";
import { Language } from "../../Selection/Actions/types";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { Selection } from "../../Selection/Actions/types";
import { dbStoreNameCalepinage, Routes } from "./root";
import {
  setCalepinageSelection,
  setHistoryPath,
} from "../../Selection/Actions";
import { saveCalpinageBuffer } from "../Actions";

interface Props {
  setRoute: any;
}

export const CalSelection = (props: Props) => {
  /**
   * The `useSelector()` hook is working like mapStateToProps from class component
   * Means that you cas access redux state using useSelector Hook
   * For ex. state.language
   */
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  /**
   * The `useDispatch() Hook is works like mapDispatch from class component
   * Means that you can dispatch any action using useDispatch()
   */
  const dispatch = useDispatch();

  const applicationLanguage: Language = reduxState.language;

  const [selectionID, setSelectionID] = useState<string[]>([]);
  const [historySelectionID, sethistorySelectionID] = useState<string[]>([]);

  const [selections, setSelections] = useState<{
    [key: string]: Selection<PlacoOptions>;
  }>({});
  const [confirmModal, setOpen] = useState(false);

  useEffect(() => {
    async function fetchPlacoSelections() {
      let savedSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );

      if (savedSelections) {
        let parsedSelections = savedSelections as {
          [key: string]: Selection<PlacoOptions>;
        };

        if (!isEqual(selections, parsedSelections)) {
          setSelections(parsedSelections);
        }
      }
    }

    fetchPlacoSelections();
    sethistorySelectionID(
      map(reduxState.moduleData.calpinageData.buffer.selections.list, "Id")
    );
  }, []);

  /**
   *
   * @param id Selection Id
   * @param checked Proposed checked value
   */
  const selectRow = (id: string, checked: boolean) => {
    let cloneSelectionId: string[] = JSON.parse(JSON.stringify(selectionID));

    /// 1. check history selected first object is zone
    let historyWasZone = filter(reduxState.selections, {
      RevitView: reduxState.projectData.ProjectId,
      Id: historySelectionID[0],
      Zone: true,
    });

    /// 2. If history was zone
    if (historyWasZone.length > 0) {
      /// 3. if not checked and history include id, then remove it from history array
      let historyIncludeId = includes(historySelectionID, id);
      let cloneHistorySelection = JSON.parse(
        JSON.stringify(historySelectionID)
      );

      if (!checked && historyIncludeId) {
        remove(cloneHistorySelection, (n) => {
          return n === id;
        });
        sethistorySelectionID(cloneHistorySelection);
      }

      /// 4. If remaining history ids not included in cloneselectionId then add it
      if (cloneHistorySelection.length > 0) {
        cloneSelectionId = concat(cloneSelectionId, cloneHistorySelection);
      }
    }

    if (reduxState.moduleData.moduleParZone) {
      if (checked) {
        cloneSelectionId.push(id);
      } else {
        remove(cloneSelectionId, (n) => {
          return n === id;
        });
      }
    } else {
      cloneSelectionId = [id];
    }

    setSelectionID(uniq(cloneSelectionId));
  };

  const handleSubmit = async (routeTo: string) => {
    if (selectionID.length > 0) {
      let prevSelectionList = map(
        reduxState.moduleData.calpinageData.buffer.selections.list,
        "Id"
      );

      if (!isEqual(prevSelectionList, selectionID)) {
        let selectedSelectionObject = filter(
          selections,
          (sel: Selection<PlacoOptions>, key: string) => {
            return includes(selectionID, sel.Id);
          }
        );

        let mapSelectionObject = map(selectedSelectionObject, (n) => {
          return { Id: n.Id, Time: n.Time };
        });

        Promise.resolve(dispatch(setCalepinageSelection(mapSelectionObject)));
      }
    }

    if (routeTo === "routeToHome") {
      props.setRoute(Routes.CALPINAGE_HOME);
    } else if (routeTo === "routeToSelection" || !(selectionID.length > 0)) {
      await bimStorage.setModule(
        dbStoreNameCalepinage,
        reduxState.projectData.ProjectId,
        reduxState.moduleData.calpinageData.buffer.Name,
        reduxState.moduleData.calpinageData.buffer.Date,
        reduxState.moduleData.calpinageData.buffer.Id,
        JSON.stringify(reduxState.moduleData.calpinageData.buffer)
      );
      dispatch(saveCalpinageBuffer());
      toggleConfirmModal();
      props.setRoute(Routes.SELECTION_HOME);
    }
  };

  // to toggle modal on edit selection link
  const toggleConfirmModal = () => {
    setOpen(!confirmModal);
  };

  const checkConfirmModal = () => {
    let prevSelectionList = map(
      reduxState.moduleData.calpinageData.buffer.selections.list,
      "Id"
    );

    // get saved calepinage data
    const prevCalepinageData = reduxState.moduleData.calpinageData.data;
    // get current calepinage buffer
    const currentCalepinageBuffer = reduxState.moduleData.calpinageData.buffer;

    //if previously there is not calepinage show popup always
    if (Object.keys(prevCalepinageData).length === 0) {
      return true;
    }

    //check if current calepinage buffer is different than previously saved with same id

    if (
      isEqual(
        prevCalepinageData[currentCalepinageBuffer.Id],
        currentCalepinageBuffer
      )
    ) {
      return false;
    } else {
      return true;
    }

    // let stateSelectionTobeCheck =
    //   selectionID.length > 0 ? selectionID : historySelectionID;
    // if (!isEqual(prevSelectionList, stateSelectionTobeCheck)) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  // to toggle modal on edit selection link
  const isVisible = () => {
    let visible = false;
    let selectedId = selectionID.length > 0 ? selectionID : historySelectionID;
    /// 1. check selectionID length is greated than 0
    if (selectedId.length > 0) {
      if (reduxState.moduleData.moduleParZone) {
        let filteredSelection = filter(reduxState.selections, {
          RevitView: reduxState.projectData.ProjectId,
          Zone: true,
        });

        let selectedSelection = filter(filteredSelection, (n) => {
          return includes(selectedId, n.Id);
        });

        visible = selectedSelection.length > 0;
      } else {
        let filteredSelection = filter(reduxState.selections, {
          RevitView: reduxState.projectData.ProjectId,
          Zone: false,
        });

        let selectedSelection = filter(filteredSelection, (n) => {
          return includes(selectedId, n.Id);
        });

        visible = selectedSelection.length > 0;
      }
    }

    return visible;
  };

  const convertDate = (d: any) => {
    let sp = d.split("/");
    let ret = [sp[1], sp[0], sp[2]].join("/");
    return ret;
  };
  let sortedCalSelection = filter(reduxState.selections, {
    RevitView: reduxState.projectData.ProjectId,
  });

  sortedCalSelection.sort((a, b) => {
    return +new Date(convertDate(b.Date)) - +new Date(convertDate(a.Date));
  });

  return (
    <div style={{ height: "100%" }}>
      <FunctionalityHeader
        Icon={reduxState.functionalityIcon}
        name={wording.name[applicationLanguage]}
      />
      <List
        selections={sortedCalSelection}
        selectRow={selectRow}
        zone={reduxState.moduleData.moduleParZone}
        applicationLanguage={applicationLanguage}
        moduleData={selectionID.length > 0 ? selectionID : historySelectionID}
      />

      <Grid columns={1} stackable style={{ ...style.editSelectionSegment }}>
        <Grid.Column style={{ padingBottom: "0px" }}>
          <div
            style={{
              padding: 5,
              cursor: "pointer",
              minWidth: "0px",
              float: "left",
            }}
            // onClick={() => this.handleEditButton()}
            onClick={() => {
              props.setRoute(Routes.SELECTION_HOME);
            }}
          >
            <Icon
              name="edit"
              color="blue"
              size="big"
              style={{ fontSize: "1.5em" }}
            />
            <span style={{ color: "rgb(14,110,184)" }}>
              {wording.editSelection[applicationLanguage]}
            </span>
          </div>
        </Grid.Column>
      </Grid>

      {/* <EditSelectionButton
        setRoute={props.setRoute}
        routes={Routes}
        applicationLanguage={applicationLanguage}
        popUpVisible={() => checkConfirmModal()}
        toggleConfirmModal={() => toggleConfirmModal()}
        onYesAction={() => handleSubmit("routeToSelection")}
        onNoAction={() => {
          toggleConfirmModal();
          props.setRoute(Routes.SELECTION_HOME);
        }}
        confirmModal={confirmModal}
        description="Voulez-vous enregistrer la configuration de calepinage en cours de modification avant de quitter?"
      /> */}

      <Grid columns={1} stackable style={{ ...style.processButtonSegment }}>
        <Grid.Column style={{ padingBottom: "0px", textAlign: "center" }}>
          <Button
            color="orange"
            onClick={() => props.setRoute(Routes.CALPINAGE_HOME)}
          >
            {wording.back[applicationLanguage]}
          </Button>
          {isVisible() ? (
            <Button
              type="submit"
              primary
              onClick={() => {
                dispatch(setHistoryPath("calselection"));
                handleSubmit("routeToHome");
              }}
            >
              {wording.validate[applicationLanguage]}
            </Button>
          ) : (
            <Button
              type="submit"
              primary
              onClick={() => {
                dispatch(setHistoryPath("calselection"));
                handleSubmit("routeToHome");
              }}
              disabled
            >
              {wording.validate[applicationLanguage]}
            </Button>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

const wording: WordingType = {
  name: {
    French: "CALEPINAGE",
    English: "LAYOUT",
  },
  editSelection: {
    French: "Créer / Modifier des sélections",
    English: "Create / Modify selections",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
};

type WordingType = {
  name: LanguageType;
  editSelection: LanguageType;
  back: LanguageType;
  validate: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
  processButtonSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};
