import PouchDB from "pouchdb";
import { bdd } from "../../../PAM/pam-import/helpers/interceptors";

let db = new PouchDB("pam_projects_db");

export const loadProperties = async (
  technicalNames: string[] | undefined,
  config: any
): Promise<any[]> => {
  let SGObjectAttributes = await bdd(
    "/attributes/class/SGObjectAttribute/locale/fr",
    {
      excludeContextAttributes: true,
      excludeContextObjects: true,
      dependencies: [],
      technicalNames: technicalNames,
    },
    config
  ).then(function (response) {
    return response.data.attributes;
  });

  return SGObjectAttributes;
};
