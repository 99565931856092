import countryJson from "../../Resources/country.json";
export const getCountry = () => {
  let arr: any[] = [];
  countryJson.map((data: any) => {
    return arr.push({
      key: data.key,
      text: data.value,
      value: data.key,
    });
  });
  return arr;
};
