import React, { useEffect, useState } from 'react'
import AccordionContainer from '../../../Common/components/AccordionContainer'
import FunctionalityHeader from '../../../Common//components/FunctionalityHeader'
import Button from '@material-ui/core/Button'
import { DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR, DISTRIBUTIVE_STANDARD, ISOLATION_DES_MURS, SEPARATIVE_STANDARD_SAA, SEPARATIVE_STANDARD_SAD } from '../../assets/constants'
import { selectWindowToShow } from '../../store/app/effects'
import { connect, ConnectedProps } from 'react-redux';
import { fillConfigurationModal } from '../../../Common/utils/attributes.injection.helpers'
import { withTranslation, WithTranslation } from 'react-i18next'
import { getSystemType } from '../../utils/utils'

import './index.scss'
import { getAllPlugins, getPrescriptionActiveVersion } from '../../../../../Services/mapping-config-service'



export const SystemConfigurationContainer = ({ system, systemTypeID, selectWindowToShow, previousWindow, config, t }: SystemConfigurationContainerProps) => {
  const [systemConfiguration, setSystemConfiguration] = useState<any>();
  const [systemName, setSystemName] = useState("");

  useEffect(() => {
    const loadTitle = async () => {
      if (config && Object.keys(config).length > 0) {
        let plugins = await getAllPlugins(config);
        const application = plugins.find((p: any) => p.applicationName === 'PrescriBIM');
        let data = await getPrescriptionActiveVersion(application.applicationId, config);
        let advancedSelectorapi = data?.versions[0]?.advanceSelector;
        
        let productName = system?.attributes.find((p: any) => p.technicalName === advancedSelectorapi?.productname?.attributeName);
        let productAttributeName = productName?.values[0]?.value
        setSystemName(productAttributeName);
      }
    }
    loadTitle();
    
    const configuration = fillConfigurationModal(system, t)
    setSystemConfiguration(configuration)
  }, [])

  const handleBackToLoadSystems = () => {
    selectWindowToShow(previousWindow);
  }

  const getSystemImagePath = (system: any) => {
    const systemType = getSystemType(system);
    switch (systemType) {
      case DISTRIBUTIVE_STANDARD:
        return require('../../assets/CloisonDistributive.png')
      case DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR:
        return require('../../assets/CloisonDistributive.png')
      case ISOLATION_DES_MURS:
        return require('../../assets/Doublage.png')
      case SEPARATIVE_STANDARD_SAA:
        return require('../../assets/SAA.png')
      case SEPARATIVE_STANDARD_SAD:
        return require('../../assets/SAD.png')
      default:
        break;
    }
  }

  return (
    <div className="PropertyGlobalContainer" style={{ overflow: "auto", paddingRight: 20, marginRight: 0 }}>
      <FunctionalityHeader name={systemName} icon={config.REACT_APP_SERVERURL + "/PrescriBIM_Logo.png"} />
      <div className='DetailsContainer'>
        <div className='PropertiesContent'>
          {systemConfiguration && <>
            <div className='Properties'>
              <div className='PropertyDetailsContent'>
                <AccordionContainer
                  key={'Parement'}
                  defaultExpand
                  title={systemConfiguration.parement.filter((p: any) => p.parmentNumber === 2).length > 0 ? 'Parement 1' : 'Parement'}
                  properties={systemConfiguration.parement.filter((p: any) => p.parmentNumber === 1).map((p: any) => ({ title: p.layerName, value: p.layerThickness }))}
                />
                {systemConfiguration.parement.filter((p: any) => p.parmentNumber === 2).length > 0 && <AccordionContainer
                  key={'Parement 2'}
                  defaultExpand
                  title={'Parement 2'}
                  properties={systemConfiguration.parement.filter((p: any) => p.parmentNumber === 2).map((p: any) => ({ title: p.layerName, value: p.layerThickness }))}
                />}
                <AccordionContainer
                  key={'Montants'}
                  defaultExpand
                  title={'Montants'}
                  properties={[{ title: systemConfiguration.montant.name, value: systemConfiguration.montant.value },
                  { title: 'Montants (s ou d)', value: systemConfiguration.profile },
                  { title: 'Entraxe (m)', value: systemConfiguration.frameDistance }]}
                />
                <AccordionContainer
                  key={'Rails'}
                  defaultExpand
                  title={'Rails'}
                  properties={[{ title: systemConfiguration.rail.name, value: systemConfiguration.rail.value }]}
                />
                <AccordionContainer
                  key={'Isolation'}
                  defaultExpand
                  title={'Isolation'}
                  properties={systemConfiguration.isolation.map((i: any) => ({ title: i, value: "" }))}
                />
              </div>
            </div>
            <img src={getSystemImagePath(system)} width={400} height={330} className="img-center" /></>}
        </div>
      </div>
      <div className='PropertyButtonContainer'>
        <Button className='loadActionButton detailActionButton' style={{ textTransform: 'none' }} variant="contained" onClick={() => handleBackToLoadSystems()}>
          {t('RETURN')}
        </Button>
      </div>
    </div>

  )
}

function mapStateToProps(state: any) {
  return {
    system: state.systemDetails.system,
    systemTypeID: state.systems.systemTypeID,
    previousWindow: state.app.previousWindow,
    config: state.config
  };
}

const mapDispatchToProps = {
  selectWindowToShow
};


const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;


type SystemConfigurationContainerProps = PropsFromRedux & WithTranslation;

export default connector(withTranslation(["library"])(SystemConfigurationContainer))