import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { Root } from "./components/root";

export const functionalityId = "5893c78b-9e68-4580-9cc3-6b33581e7646";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Load",
    module: "WEBINDONPRESCRI",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <Root />
    </ParentRoot>
  );
};

export const RootLoadSystemsComponentTest = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <Root />
    </ParentRoot>
  );
};

export const LoadSystemContainer: Functionality = {
  Name: {
    English: "Load",
    Indonesian: "Memuat",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    English: "Load systems into the project",
    Indonesian: "Memuat sistem dalam proyek",
  },
  Id: functionalityId,
  Icon: "/weberIcons/LoadBW.png",
  RootComponent,
  ParentId: "0",
};
