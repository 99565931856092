import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { ReperageRoot } from "./Components/root";
import { setPluginDataToDB } from "../../../Helper";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "547f5d6a-8296-4d01-887b-39fa41e422c0";

export const ReperageTrigger = async (config: any) => {
  const queryString = window.location.hash;
  const urlParams = new URLSearchParams(queryString.split("?")[1]);
  if (urlParams.get("token")) {
    setPluginDataToDB("token", urlParams.get("token")!);
  }
  api.windowsHandler.openWindow(
    900,
    565,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Repérage",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="reperage"
    >
      <ReperageRoot />
    </ParentRoot>
  );
};

export const GyprocReperageComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="reperage"
    >
      <ReperageRoot />
    </ParentRoot>
  );
};

export const Reperage: Functionality = {
  Name: { French: "Spotting", English: "Spotting", Indonesian: "Spotting" },
  Trigger: ReperageTrigger,
  ShortDesc: {
    French: "Plans of the works",
    English: "Plans of the works",
    Indonesian: "Plans of the works",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/ReperageV3-trans.png",
  RootComponent,
  ParentId: "0",
};
