import { Action } from 'redux'
import { ResetSearchKeyWordAction } from '../systems/types'

export const LOAD_FILTERS = "LOAD_FILTERS";
export const APPLY_FILTERS = "APPLY_FILTERS";

export interface FiltersState {
    filters: any[],
    loadingFilters: boolean
}

interface LoadFiltersAction extends Action {
    type: typeof LOAD_FILTERS
    filters: any[]
}

interface ApplyFiletersAction extends Action {
    type: typeof APPLY_FILTERS
    filters: any[]
}

export type FiltersActionTypes = LoadFiltersAction | ApplyFiletersAction | ResetSearchKeyWordAction
