import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import WindowedSelect from "react-windowed-select";
import { Button, Icon, Modal, Popup, Table } from "semantic-ui-react";
import { queryPlacoDataFromStore } from "../../../../../API";
import { SelectionStore } from "../../../Selection/Reducers";
import { makeGetDropdownPlacoSolutions } from "../../../Selection/Selectors";
import { NonPlacoSolutionRow } from "./NonPlacoSolutionRow";
import {
  initProductSelector,
  savePlacoData,
  selectBuffer,
  setFilterType,
} from "../../../Selection/Actions";
import { Routes } from "../root";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core/styles";
import "./windowCursor.css";
import { ProductDetailData } from "../../../Selection/Types";
import { Language } from "../../../Selection/Actions/types";
import { LanguageType } from "../../../CommonModules/Selection/Type";
import { propReduce } from "@turf/turf";
interface FunctionProps {
  setRoute: any;
  index: number;
  solutionName: string;
  handleCloneSolution: any;
  updateStatus?: any;
  CCTPReference: any;
  srno: any;
  handleActiveRow: any;
  RevitSystem: string;
  oid: string;
  setScrollConfigPosition: any;
}

export const SolutionSelect = (props: FunctionProps) => {
  const dispatch = useDispatch();
  const [selectorType, setSelectorType] = useState("wall");
  const [showWarning, setShowWarning] = useState(false);

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>({ label: "", value: "" });

  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  const applicationLanguage: Language = reduxState.language;

  useEffect(() => {
    if (props.solutionName.indexOf("Plafond") > -1) {
      setSelectorType("ceiling");
    } else {
      setSelectorType(reduxState.selectorType);
    }

    setSelectedOption({
      label: props.solutionName,
      value: props.oid,
    });
  }, [props.solutionName, reduxState.selectorType]);

  //Set nonPlacoName state for Non Placo Option
  const [nonPlacoName, setName] = useState({
    label: "",
    value: "",
    isNonPlaco: true,
  });

  // TempNonPlacoName is used to compare previous nonPlacoName and current nonPlacoName
  const [TempNonPlacoName, setTempName] = useState("");

  //START Placo Solution fetching and setting dropdown values
  const { data } = useQuery("placodatafrombimstore", queryPlacoDataFromStore, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (data && !reduxState.placoData.available) {
    let attributes = data.context.attributeDependencies[0].attributes;
    dispatch(savePlacoData(reduxState, attributes, data.objects, attributes));
  }

  let tempSelector = makeGetDropdownPlacoSolutions();
  const options = tempSelector(reduxState, selectorType);

  const dropDownOptions = (Index: number, SelectorType: string) => {
    // return _.concat(
    //   [
    //     {
    //       label: "Autre système (non PLACO)",
    //       value: "custom_" + SelectorType + "_" + Index,
    //     },
    //   ],
    //   options
    // );
    return options;
  };
  //END Placo Solution fetching and setting dropdown values

  const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "white",
      boxShadow: theme.shadows[1],
      fontWeight: "bold",
      fontSize: 11,
      // height:40,
    },
  }))(Tooltip);

  const defaultValue = () => {
    if (selectedOption !== null) {
      // if (
      //   _.includes(selectedOption.value, "custom_") &&
      //   TempNonPlacoName === ""
      // ) {
      //   props.updateStatus(false); // if we have selected a non placo valid button should be diabled until we save a name
      // }
      return selectedOption; // set chosen option from dropdown
    } else {
      return { label: props.solutionName, value: props.solutionName }; // set original row's solution name by default
    }
  };

  const handleChange = (e: any) => {
    setSelectedOption(e);
    if (e.label !== "Autre système (non PLACO)") {
      let isNonPlaco = false;
      e = { ...e, isNonPlaco };
      props.handleCloneSolution(props.index, e, props.srno);
    }
  };

  // for advance selection
  const advanceSelector = () => {
    props.setScrollConfigPosition();
    props.handleActiveRow(props.srno);
    bimStorage.setItem(
      storageKey.ACTIVE_PRODUCT,
      JSON.stringify(props.RevitSystem)
    );
    let productSubType: any = "partitionWall";
    switch (selectorType) {
      case "ceiling":
        productSubType = "ceiling";
        break;
      default:
        if (props.RevitSystem.toLowerCase().includes("cloison")) {
          productSubType = "partitionWall";
        } else if (props.RevitSystem.toLowerCase().includes("doublage")) {
          productSubType = "liningWall";
        }
    }

    const selectedSolution = data.objects.filter(
      (e: any) => e.translation === selectedOption.label
    )[0] as ProductDetailData;
    if (selectedSolution) {
      dispatch(selectBuffer(selectedSolution));

      dispatch(setFilterType(productSubType));
      props.setRoute(Routes.PRODUCT_SELECTOR);
      dispatch(initProductSelector());
    } else {
      setShowWarning(true);
    }
  };

  const openAdvSelector = () => {
    let productSubType: any = "partitionWall";
    switch (selectorType) {
      case "ceiling":
        productSubType = "ceiling";
        break;
      default:
        if (props.RevitSystem.toLowerCase().includes("cloison")) {
          productSubType = "partitionWall";
        } else if (props.RevitSystem.toLowerCase().includes("doublage")) {
          productSubType = "liningWall";
        }
    }

    dispatch(setFilterType(productSubType));
    props.setRoute(Routes.PRODUCT_SELECTOR);
    dispatch(initProductSelector());
    setShowWarning(false);
  };

  return (
    <Table style={{ border: "0px" }}>
      <Table.Body>
        <Table.Row style={{ width: "100%" }}>
          <Table.Cell
            className="windowCursor"
            style={{ width: "100%", padding: 0 }}
          >
            <LightTooltip
              title={selectedOption.label}
              arrow
              placement="top-start"
            >
              <div>
                <WindowedSelect
                  style={{ width: "100%" }}
                  search
                  options={dropDownOptions(props.index, selectorType)}
                  value={defaultValue()}
                  onChange={handleChange}
                />
              </div>
            </LightTooltip>
          </Table.Cell>
          <Table.Cell
            style={{
              border: "1px solid rgba(34, 36, 38, 0.15)",
              backgroundColor: "rgba(214, 214, 214, 0.45)",
              padding: 0,
            }}
          >
            {" "}
            <Popup
              trigger={
                <Button
                  icon
                  style={{ margin: "0px", background: "none" }}
                  onClick={advanceSelector}
                >
                  <Icon name="search" color="blue" style={{ padding: "0px" }} />
                </Button>
              }
              content="Recherche avancée"
              on="hover"
              position="top center"
              inverted
              size="mini"
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row style={{ width: "100%" }}>
          <Table.Cell style={{ width: "100%", padding: 0 }}>
            {selectedOption?.label === "Autre système (non PLACO)" ? (
              <NonPlacoSolutionRow
                defaultValue={defaultValue}
                index={props.index}
                changeCloneNonPlacoName={props.handleCloneSolution}
                nonPlacoName={nonPlacoName}
                setName={setName}
                TempNonPlacoName={TempNonPlacoName}
                setTempName={setTempName}
                updateStatus={props.updateStatus}
                CCTPReference={props.CCTPReference}
              />
            ) : null}
          </Table.Cell>
        </Table.Row>

        <Modal size="tiny" open={showWarning} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>{wording.warningMessage[applicationLanguage]} </p>
            <Button
              primary
              onClick={() => {
                openAdvSelector();
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </Table.Body>
    </Table>
  );
};

const wording: WordingType = {
  warningMessage: {
    French: "Cette solution n'existe plus dans la base de données",
    English: "This Solution no longer exists in the Database.",
  },
};

type WordingType = {
  warningMessage: LanguageType;
};
