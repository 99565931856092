import { flattenDeep } from "lodash";

export const A01LayersParser = (layersDesc: string) => {
  switch (layersDesc) {
    case "A60":
      return [
        {
          name: "A60",
          thickness: 60,
        },
      ];
    case "A80":
      return [
        {
          name: "A80",
          thickness: 80,
        },
      ];
    case "A100":
      return [
        {
          name: "A100",
          thickness: 100,
        },
      ];
    case "A100, ASS":
      return [
        {
          name: "A100, ASS",
          thickness: 100,
        },
      ];
    case "AG100, ASS":
      return [
        {
          name: "AG100, ASS",
          thickness: 100,
        },
      ];
    case "A140":
      return [
        {
          name: "A140",
          thickness: 140,
        },
      ];
    case "AH60":
      return [
        {
          name: "AH60",
          thickness: 60,
        },
      ];
    case "AH80":
      return [
        {
          name: "AH80",
          thickness: 80,
        },
      ];
    case "AH100":
      return [
        {
          name: "AH100",
          thickness: 100,
        },
      ];
    case "A25":
      return [
        {
          name: "A25",
          thickness: 25,
        },
      ];
    case "A40":
      return [
        {
          name: "A40",
          thickness: 40,
        },
      ];
    case "AH25":
      return [
        {
          name: "AH25",
          thickness: 25,
        },
      ];
    case "AH40":
      return [
        {
          name: "AH40",
          thickness: 40,
        },
      ];
    case "AB25":
      return [
        {
          name: "AB25",
          thickness: 25,
        },
      ];
    case "AB40":
      return [
        {
          name: "AB40",
          thickness: 40,
        },
      ];
    case "AG100":
      return [
        {
          name: "AG100",
          thickness: 100,
        },
      ];
    case "RB":
      return [
        {
          name: "RB",
          thickness: 12.5,
        },
      ];
  }
};

export const A11LayersParser = (layersDesc: string) => {
  let layers;
  if (layersDesc.includes("_ _")) {
    layers = layersDesc.split("_ _").map((word) => word.trim());
  } else {
    layers = layersDesc.split("_").map((word) => word.trim());
  }
  return [
    A01LayersParser(layers[0])?.[0],
    null,
    A01LayersParser(layers[1])?.[0],
  ];
};

export const AyLayersParser = (layersDesc: string) => {
  let layers = layersDesc
    .split("_")
    .map((word) => word.trim())
    .filter((layer) => layer !== "");
  let cladding_A = layers[0].split("/").map((layer) => A01LayersParser(layer));
  if (layers[1]) {
    let cladding_B = layers[1]
      .split("/")
      .map((layer) => A01LayersParser(layer))
      .reverse();
    // return [cladding_A, (layersDesc.match(/_/g) || []).length, cladding_B];
    return [flattenDeep(cladding_A), null, flattenDeep(cladding_B)];
  }
  // return [cladding_A, (layersDesc.match(/_/g) || []).length];
  return [flattenDeep(cladding_A), null];
};
