import React from "react";
import Excel from "exceljs";
import { api } from "../../../../RevitJS/API";
import { find, flatten, get, map } from "lodash";
import { overviewByRoom } from "./OverviewByRoom";
import { overviewByProduct } from "./OverviewByProduct";
import { CeilingData } from "../../../../RevitJS/Types/RevitTypes";
import { setCellBorder } from "../../../PlacoBIMv3/Quantitatiff/Extract/excelUI";
import { saveAs } from "file-saver";
import { overviewByLevel } from "./OverviewByLevel";
import { overviewByProject } from "./OverviewByProject";

export const excelExport = async () => {
  const workbook = new Excel.Workbook();

  const projectOverviewSheet = workbook.addWorksheet("Project Overview", {
    views: [{ showGridLines: false }],
  });

  const overviewByRoomSheet = workbook.addWorksheet("Overview by room", {
    views: [{ showGridLines: false }],
  });

  const overviewByProductSheet = workbook.addWorksheet("Overview by product", {
    views: [{ showGridLines: false }],
  });

  let ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
  let ceilingLevels = map(ceilingTree.Tree, "Level");
  let ceilingIds = flatten(
    map(flatten(map(ceilingTree.Tree, "Elements")), "Ids")
  );

  let ceilingDetails = await api.queries.getCeilingsData(ceilingIds);

  ceilingDetails = map(ceilingDetails, (ceiling, index) => {
    let ceilingData = find(ceilingLevels, { Id: ceiling.LevelId });
    ceiling.Elevation = get(ceilingData, "Elevation");
    return ceiling;
  });

  await overviewByProject(
    projectOverviewSheet,
    ceilingTree,
    ceilingLevels,
    ceilingIds
  );

  await overviewByRoom(overviewByRoomSheet, ceilingDetails);

  await overviewByProduct(overviewByProductSheet, ceilingDetails);

  await overviewByLevel(workbook);

  const buff = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buff], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  // window.addEventListener("blur", () => {
  //   api.windowsHandler.closeWindow();
  // });

  saveAs(blob, "CLT4BIM_quantitatifs_export");

  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "BoM",
    module: "CLT",
  });
  api.windowsHandler.closeWindow();
};

export const setLevelHeaderStyle = async (
  overviewByLevelSheet: Excel.Worksheet,
  row: number,
  column: number,
  color = "FFE000"
) => {
  overviewByLevelSheet.getRow(row).getCell(column).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  overviewByLevelSheet.getRow(row).getCell(column).font = {
    name: "Arial",
    size: 10,
    bold: true,
    color: { argb: "000000" },
  };
  overviewByLevelSheet.getRow(row).getCell(column).fill = {
    type: "pattern",
    pattern: "solid",
    bgColor: { argb: "000000" },
    fgColor: { argb: color },
  };

  if (column === 3) {
    setCellBorder(
      overviewByLevelSheet.getRow(row).getCell(column),
      false,
      true,
      true,
      true
    );
  } else {
    setCellBorder(
      overviewByLevelSheet.getRow(row).getCell(column),
      false,
      true,
      true
    );
  }
};

export const setLevelRowStyle = async (
  overviewByLevelSheet: Excel.Worksheet,
  row: number,
  column: number,
  color = "FFFFFF"
) => {
  if (column === 2) {
    overviewByLevelSheet.getRow(row).getCell(column).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
  } else {
    overviewByLevelSheet.getRow(row).getCell(column).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
  }

  overviewByLevelSheet.getRow(row).getCell(column).font = {
    name: "Arial",
    size: 10,
    bold: false,
    color: { argb: "000000" },
  };
  overviewByLevelSheet.getRow(row).getCell(column).fill = {
    type: "pattern",
    pattern: "solid",
    bgColor: { argb: "000000" },
    fgColor: { argb: color },
  };

  if (column === 3) {
    setCellBorder(
      overviewByLevelSheet.getRow(row).getCell(column),
      false,
      false,
      true,
      true
    );
  } else {
    setCellBorder(
      overviewByLevelSheet.getRow(row).getCell(column),
      false,
      false,
      true
    );
  }
};

const processGroup1 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
) => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: ceilingId,
      length: find(mr.Params, { Name: "longueur T24/38" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};

const processGroup2 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
) => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: find(mr.Params, { Name: "id" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};

const processGroup3 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
) => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: find(mr.Params, { Name: "id" }).Value,
      length: find(mr.Params, { Name: "length" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};

const processGroup4 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
) => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: find(mr.Params, { Name: "id" }).Value,
      name: "",
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};
