import i18n from "i18next";
// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import pamTranslationEN from "./translations/pam/English/translation.json";
import pamTranslationFR from "./translations/pam/French/translation.json";
import rigibsTranslationEN from "./translations/rigibs/English/translation.json";
import rigibsTranslationFR from "./translations/rigibs/French/translation.json";
import rigibsTranslationGM from "./translations/rigibs/German/translation.json";
import rigibsTranslationIT from "./translations/rigibs/Italian/translation.json";
import pamCanalisationEN from "./translations/pamCanalisation/English/translation.json";
import pamCanalisationFR from "./translations/pamCanalisation/French/translation.json";
import libraryEN from "./translations/library/English/translation.json";
import libraryFR from "./translations/library/French/translation.json";
import cltEN from "./translations/clt/English/translation.json";
import cltFR from "./translations/clt/French/translation.json";
import sgmaEN from "./translations/sgma/English/translation.json";
import sgmaFR from "./translations/sgma/French/translation.json";
import quantitatiffEN from "./translations/quantitatiff/English/translation.json";
import quantitatiffFR from "./translations/quantitatiff/French/translation.json";
import weberIndonesiaEN from "./translations/weberIndonesia/English/translation.json";
import weberIndonesiaID from "./translations/weberIndonesia/Indonesian/translation.json";
// the translations

const resources = {
  English: {
    pam: pamTranslationEN,
    riggibs: rigibsTranslationEN,
    pamCanalisation: pamCanalisationEN,
    library: libraryEN,
    clt: cltEN,
    sgma: sgmaEN,
    quantitatiff: quantitatiffEN,
    weberIndonesia: weberIndonesiaEN,
  },
  French: {
    pam: pamTranslationFR,
    riggibs: rigibsTranslationFR,
    pamCanalisation: pamCanalisationFR,
    library: libraryFR,
    clt: cltFR,
    sgma: sgmaFR,
    quantitatiff: quantitatiffFR
  },
  German: {
    riggibs: rigibsTranslationGM,
  },
  Italian: {
    riggibs: rigibsTranslationIT,
  },
  Indonesian:{
    weberIndonesia: weberIndonesiaID,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  //.use(Backend)

  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    resources,
    fallbackLng: "English",
    debug: false,
  });

export default i18n;
