import { START_SELECTION, END_SELECTION, OPEN_WINDOW, CLOSE_WINDOW, HIDE_WINDOW, RESIZE_WINDOW, InitLanguageAction, INIT_LANGUAGE, SetLanguageAction, SET_LANGUAGE, SetPluginsAction, SET_PLUGINS, MINIMIZE_WINDOW, MAXIMIZE_WINDOW, RESTORE_WINDOW, SET_ERROR_MODAL } from "./types";
import { windowDimensions } from "../RevitJS/Types/FakerTypes";
import { api } from "../RevitJS/API";
import { StoreState } from "../Reducers";
import { ThunkAction } from "redux-thunk";
import { errorData, Plugin } from "../RevitJS/Types/StoreTypes";
import { generalizeLanguage } from "../Helper";
// import { Language } from "../RevitJS/Types/RevitTypes";


export interface StartSelectionAction{
    type: typeof START_SELECTION,
}

export interface EndSelectionAction{
    type: typeof END_SELECTION
}

export interface OpenWindowAction{
    type: typeof OPEN_WINDOW,
    url: string
}

export interface CloseWindowAction{
    type: typeof CLOSE_WINDOW
}

export interface HideWindowAction{
    type: typeof HIDE_WINDOW
}

export interface MinimizeWindowAction{
    type: typeof MINIMIZE_WINDOW
}

export interface MaximizeWindowAction{
    type: typeof MAXIMIZE_WINDOW
}

export interface RestoreWindowAction{
    type: typeof RESTORE_WINDOW
}

export interface ResizeWindowAction{
    type: typeof RESIZE_WINDOW,
    windowDimensions: windowDimensions
}

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English", "German", "Italian", "Indonesian"]);
type Language = (typeof language)[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage = (
  ): ThunkAction<void, StoreState, unknown, InitLanguageAction> => async (
    dispatch,
  ) => {
    
    const setRevitLanguage = async () =>{
        let revitLanguage = await api.queries.getRevitLanguage() as Language;
        let lang = generalizeLanguage(revitLanguage);
        localStorage.setItem("savedLanguage", lang);
        dispatch({
            type: INIT_LANGUAGE,
            language: lang
        })
    }
    let savedLanguage:any = localStorage.getItem("savedLanguage");
    if(savedLanguage){
        if(isLanguage(savedLanguage)){
            dispatch({
                type: INIT_LANGUAGE,
                language: savedLanguage
            })
        }else{
            setRevitLanguage();
        }
  }else{
    setRevitLanguage();
  }
}

export const setLanguage = (language: Language): SetLanguageAction =>{
    localStorage.setItem("savedLanguage", language);
    return{
        type: SET_LANGUAGE,
        language: language
    }
}

export const setPlugins = (plugin: Plugin[]): SetPluginsAction =>{
    return{
        type: SET_PLUGINS,
        plugin: plugin
    }
}

export const setErrorModal = (modalState: errorData) => {
    return{
        type: SET_ERROR_MODAL,
        value: modalState
    }
}