import _, { filter, find, flattenDeep, forEach, includes, map } from "lodash";
import React, { Fragment, useState } from "react";
import uniq from "lodash/uniq";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { fetchPlacoSolutionsByTechnicalNames } from "../../../../API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { api } from "../../../../RevitJS/API";
import { drawWall } from "../../Calpinage/Helpers";
import {
  fetchPlacoDataInGroupSplit,
  parseSystemOptimized,
} from "../../Calpinage/Helpers/placoHelper";
import {
  getLayoutAttributesAid,
  layoutGroup,
  layoutInGroup,
} from "../../Calpinage/Requests";
import { overLapElements } from "../../Overlap/Components/Waiter";
import {
  asyncForEach,
  extractFramesQuantity,
  extractPlasterboardQuantity,
} from "../Helper";
import { useDispatch } from "react-redux";
import { colorizationForExisitingFilters } from "../../Colorization/Components/Waiter";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";
import { reverseSideRevitApi } from "../../../../ErrorManagement/utils/revitApiError";

const extractRails = (rails: any) => {
  if (rails[0].product.translation === "Rail Stil® R 48") {
    rails[0].articles.forEach((element: any) => {
      if (element.text === "Rail Stil® R 48/300 bot10L") {
        return element.text;
      }
    });
  }

  return rails[0].articles[0].text;
};

const RunReverse = async (props: any) => {
  try {
    let {
      setLoading,
      setGroupNumber,
      setProgress,
      setStep,
      progress,
      config,
      onError,
    } = props;
    /// 1. Bring up UI processing
    setLoading(true);

    /// 1.1 overlap elements
    await overLapElements();

    setProgress(0);
    setGroupNumber(0);
    setStep("loading");

    /// 2. Hide UI window
    api.windowsHandler.hideWindow();

    /// 3. Call manual selection only for walls
    const manualSelection = await api.selection.manualSelection("wall");
    reverseSideRevitApi(props);
    /// 4-a. If manual selection not empty
    if (manualSelection.Tree.length > 0) {
      try {
        /// 5. Show UI window
        api.windowsHandler.showWindow();

        /// 6. Extract Elements from selection
        let selectedElements = _.map(manualSelection.Tree, "Elements");

        /// 7. flatten the selected elements
        let flattenSelectedElements = _.flatten(selectedElements);

        /// 8. Extract unique wall ids number
        let selectedWallIds = _.map(flattenSelectedElements, "Ids");
        let uniqSelectedWallIds = _.uniq(_.flatten(selectedWallIds));

        if (uniqSelectedWallIds) {
          /// 9. Get object params (i.e. ["Processed", "SG_System"]) of wall
          let selectedWallParams = await api.queries.getObjectsParams(
            uniqSelectedWallIds,
            ["Processed", "SG_System", "Flipped", "Plaque", "Montant"]
          );
          reverseSideRevitApi(props);

          /// 10. Filter walls paramas where `Processed` is equal to 1
          ///     we called this processed wall params
          let processedWallParams = _.filter(
            selectedWallParams,
            (wapa, index) => {
              return (
                _.find(wapa.Params, {
                  Name: "Processed",
                  Type: "YesNo",
                  Value: "1",
                }) &&
                (wapa.Params.find((a: any) => a.Name === "Plaque") ===
                  undefined ||
                  wapa.Params.find((a: any) => a.Name === "Plaque")?.Value ===
                    null ||
                  wapa.Params.find((a: any) => a.Name === "Plaque")?.Value ===
                    "") &&
                (wapa.Params.find((a: any) => a.Name === "Montant") ===
                  undefined ||
                  wapa.Params.find((a: any) => a.Name === "Montant")?.Value ===
                    null ||
                  wapa.Params.find((a: any) => a.Name === "Montant")?.Value ===
                    "")
              );
            }
          );

          if (processedWallParams.length > 0) {
            /// 11. Take wall ids from processed walls params
            let processedWallIds = _.map(processedWallParams, "Id");

            /// 12. Get walls data of processed wall ids
            let wallsData = await api.queries.getWallsData(processedWallIds);
            reverseSideRevitApi(props);

            /// 13. Get plaster boards details of processed walls
            let pstDetails = await extractPlasterboardQuantity(
              processedWallIds
            );

            /// 14. Get Frames details of processed walls
            let frameDetails = await extractFramesQuantity(processedWallIds);

            _.map(wallsData, (wall, index) => {
              wall.Height = parseFloat(
                parseFloat(((wall.Height as number) / 1000).toString()).toFixed(
                  2
                )
              );
              wall.WallType = wall.WallType + " - " + wall.Height;

              /// Extract applied plaq for wall
              let wallPlaqObject = _.get(pstDetails, wall.Id);
              let plaqUniqueNames = _.uniq(_.map(wallPlaqObject, "Name"));

              /// Extract applied frames for wall
              let wallFrameObject = _.get(frameDetails, wall.Id);
              let frameUniqueNames = _.uniq(_.map(wallFrameObject, "Name"));

              wall.plaque = plaqUniqueNames;
              wall.montant = frameUniqueNames;

              /// 18 - a. See Wall is already flipped or not
              const wallPramObject = _.find(processedWallParams, {
                Id: wall.Id,
              });
              const flippedParam = _.find(wallPramObject.Params, {
                Name: "Flipped",
              });
              if (flippedParam) {
                wall.flipped = flippedParam.Value === "1" ? true : false;
              } else {
                wall.flipped = false;
              }
            });

            // Find the id of objects in wallsData with plaque's length equal to zero
            const idsToRemove = wallsData
            .filter((wall) => wall?.plaque?.length === 0)
            .map((wall) => wall.Id);

            // Filter processedWallParams & wallsData based on the idsToRemove
            processedWallParams = processedWallParams.filter(
            (params) => !idsToRemove.includes(params.Id)
            );

            wallsData = wallsData.filter(
              (data) => !idsToRemove.includes(data.Id)
            );

            /// 15. Take unique solution name from processed wall params
            let processedSolution = _.uniq(
              _.map(processedWallParams, "Params[1].Value")
            );
            /// 16. Fetch placo solutions from api database
            const data = await fetchPlacoSolutionsByTechnicalNames(
              processedSolution,
              config
            )
              .then(({ data }) => {
                return data;
              })
              .catch((error) => {
                return Promise.reject({
                  code: errorCodeKey.PB_PE_H_001,
                  stack: error.stack,
                });
              });
            /// 17. Extract solution ids from fetch data
            const solutionOids: string[] = _.map(data.objects, "oid");

            /// 18. Fetch solution details with child and parent
            ///     Extract products, solution products and articles
            const extractedSolutionProduct = await fetchPlacoDataInGroupSplit(
              solutionOids,
              config
            )
              .then(({ extractedSolutionProduct }) => {
                return extractedSolutionProduct;
              })
              .catch((error) => {
                return Promise.reject({
                  code: errorCodeKey.PB_PE_H_001,
                  stack: error.stack,
                });
              });

            /// 19. Create pre wall matching array based on unique solutions

            let preWallsMatching: any[] = [];
            _.forEach(extractedSolutionProduct, (extData, index) => {
              let attributesId: any = getLayoutAttributesAid(
                extData.attributes
              );
              let prSystem = parseSystemOptimized(
                extData.solutionProduct,
                extData.products,
                attributesId,
                false,
                true
              );
              preWallsMatching.push(prSystem);
            });

            /// 20. Group walls data based on wall type
            let wallsDataByWallType = _.groupBy(wallsData, "WallType");

            /// 21. Create wall matching array based on wall group
            let wallsMatching: any[] = [];

            _.forEach(wallsDataByWallType, (wall, index) => {
              /// Extract proceesed wall params based on wall Id
              let firstWallParamasObject = _.find(processedWallParams, {
                Id: wall[0].Id,
              });

              /// Extract solution name from processed wall params
              let solutionApplied = firstWallParamasObject.Params[1].Value;

              /// Extract pre wall matching object for solution (i.e solutionApplied)
              let wmatch = _.find(preWallsMatching, function (o) {
                return solutionApplied.includes(o.systemName);
              });

              const isAllFlipped = _.every(wall, ["flipped", true]);

              /// push wmatch in wall matching array with WallType
              wallsMatching.push({
                ...wmatch,
                WallType: index,
                Flipped: !isAllFlipped,
              });
            });

            /// 22. Update wall matching for
            ///     E1_Name, E2_Name, I1_Name, I2_Name, Frame_Name, Rail_Name, Frame_length
            ///     Input Type etc...
            _.forEach(wallsMatching, (wallMatch, wallMatchIndex) => {
              let calepinageListMember = _.get(
                wallsDataByWallType,
                wallMatch.WallType
              )[0];

              const matchesCase = (
                textArray: string[] | undefined,
                partial: string
              ) => {
                let response = partial;
                forEach(textArray, (text, index) => {
                  if (text.indexOf(partial) > -1) {
                    response = text;
                  }
                });
                return response;
              };

              /// Set plaqs in E1 and E2
              if (wallMatch[`E1`]) {
                /// Set plaqs in E1 and E2
                wallMatch[`E1`][`name`] = matchesCase(
                  calepinageListMember.plaque,
                  wallMatch[`E1`][`name`]
                );
              }

              /// if plaque length is equal to 1
              /// then for E2_Name set E1_Name
              if (wallMatch[`E2`]) {
                /// if plaque length is equal to 1
                /// then for E2_Name set E1_Name
                wallMatch[`E2`][`name`] = matchesCase(
                  calepinageListMember.plaque,
                  wallMatch[`E2`][`name`]
                );
              }

              if (wallMatch[`E3`]) {
                wallMatch[`E3`][`name`] =
                  wallMatch[`E3`][`name`] !== "None" &&
                  matchesCase(
                    calepinageListMember.plaque,
                    wallMatch[`E3`][`name`]
                  );
              }

              if (wallMatch[`I1`]) {
                wallMatch[`I1`][`name`] = matchesCase(
                  calepinageListMember.plaque,
                  wallMatch[`I1`][`name`]
                );
              }

              if (wallMatch[`I2`]) {
                wallMatch[`I2`][`name`] = matchesCase(
                  calepinageListMember.plaque,
                  wallMatch[`I2`][`name`]
                );
              }

              if (wallMatch[`I3`]) {
                wallMatch[`I3`][`name`] =
                  wallMatch[`I3`][`name`] !== "None" &&
                  matchesCase(
                    calepinageListMember.plaque,
                    wallMatch[`I3`][`name`]
                  );
              }

              /// Extract plaq board height
              let extSolProd = _.find(extractedSolutionProduct, function (o) {
                return o.solutionProduct.translation === wallMatch.systemName;
              });

              let plaqArticlesWithProduct = _.filter(extSolProd.products, {
                type: "plaque",
              });

              /// extract plaq board height
              let plaqArticleWithProduct =
                plaqArticlesWithProduct.length > 1
                  ? filter(plaqArticlesWithProduct, function (o) {
                      const isHaveArticle = find(o.articles, (oa) => {
                        return includes(calepinageListMember.plaque, oa.value);
                      });
                      if (isHaveArticle) {
                        return true;
                      }
                      return false;
                      // return o.product.translation.includes(
                      //   calepinageListMember.plaque[0].split(" ")[0]
                      // );
                    })
                  : [plaqArticlesWithProduct[0]];

              // let plaqArticle = _.find(
              //   plaqArticleWithProduct.articles,
              //   function (o) {
              //     let plq =
              //       calepinageListMember.plaque && calepinageListMember.plaque[0];
              //     return o.value === plq;
              //   }
              // );
              // if (plaqArticle) {
              //   let articleAttributes = _.find(plaqArticle.article.attributes, {
              //     technicalName: "GFR-Length of the UB unpacked (m)",
              //   });
              //   if (articleAttributes) {
              //     let heightinmm =
              //       articleAttributes.values[0].numericValue * 1000;
              //     wallMatch.board_height = heightinmm;
              //   }
              // }
              ///////

              const prArray = ["E1", "E2", "E3", "I1", "I2", "I3"];

              forEach(prArray, function (value) {
                if (wallMatch[value]) {
                  let e1BoardHeight = wallMatch[value][`board_height`];
                  let plaqE1Article = find(
                    flattenDeep(map(plaqArticleWithProduct, "articles")),
                    function (o) {
                      return o.value === wallMatch[value][`name`];
                    }
                  );
                  if (plaqE1Article) {
                    let articleAttributes = find(
                      plaqE1Article.article.attributes,
                      {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      }
                    );
                    if (articleAttributes) {
                      let heightinmm =
                        articleAttributes.values[0].numericValue * 1000;
                      e1BoardHeight = heightinmm;
                    }
                  }

                  wallMatch[value][`board_height`] = e1BoardHeight;
                }
              });

              /// Extract montant (i.e. frame) name
              wallMatch.Frame1_Name =
                calepinageListMember.montant && calepinageListMember.montant[0];
              let montantArticlesWithProduct = _.find(extSolProd.products, {
                type: "montant",
              });

              /// Extract frame length
              if (montantArticlesWithProduct) {
                let montantArticle = _.find(
                  montantArticlesWithProduct.articles,
                  function (o) {
                    let mon =
                      calepinageListMember.montant &&
                      calepinageListMember.montant[0];
                    return o.value === mon;
                  }
                );
                if (montantArticle) {
                  let articleAttributes = _.find(
                    montantArticle.article.attributes,
                    { technicalName: "GFR-Length of the UB unpacked (m)" }
                  );
                  if (articleAttributes) {
                    let heightinmm =
                      articleAttributes.values[0].numericValue * 1000;
                    wallMatch.frame_length = heightinmm;
                  }
                }
              }

              /// Extract rail
              let rails = _.filter(extSolProd.products, { type: "rail" });
              wallMatch.Rail1_Name = extractRails(rails);

              /// Extract solution technical name
              wallMatch.technicalName =
                extSolProd.solutionProduct.technicalName;
              //// Extract wall type
              wallMatch.InputType = wallMatch.WallType;
            });

            /// 23. Delete existing plasterboards, rails, ossature

            setStep("deleting");
            let elemsArr: string[] = [];
            await asyncForEach(
              processedWallIds,
              async (id: any, index: any) => {
                await api.familyEditor.setParams([
                  {
                    Id: id,
                    Params: [{ Name: "Processed", Type: "YesNo", Value: 0 }],
                  },
                ]);
                reverseSideRevitApi(props);

                const elems = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: parseInt(id),
                      },
                      Rule: "Equals",
                    },
                  ],
                  null
                );

                elemsArr = elemsArr.concat(elems);
                reverseSideRevitApi(props);
              }
            );

            if (elemsArr && elemsArr.length > 0)
              await api.familyEditor.deleteElementsByIds(elemsArr);

            setStep("families");
            /// 24. Applying family files

            // await api.familyEditor.createParameter("Wall", "Instance", "DATA", {
            //   Name: "Processed",
            //   Type: "Boolean",
            // });
            // await api.familyEditor.createParameter("Wall", "Instance", "DATA", {
            //   Name: "SG_System",
            //   Type: "Text",
            // });

            await api.familyEditor.loadFamilies(
              [
                {
                  FamilyName: "Placo_Plaque",
                  FamilyTypes: ["Placo_Plaque"],
                  Date: "2019_12_03_10_34_32",
                  Version: "1.0.0.1",
                },
              ],
              config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
            );
            reverseSideRevitApi(props);

            await api.familyEditor.loadFamilies(
              [
                {
                  FamilyName: "Placo_Ossature",
                  FamilyTypes: ["Placo_Ossature"],
                  Date: "2019_12_03_10_34_32",
                  Version: "1.0.0.1",
                },
              ],
              config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
            );
            reverseSideRevitApi(props);

            await api.familyEditor.loadFamilies(
              [
                {
                  FamilyName: "Placo_Rail-bas",
                  FamilyTypes: ["Placo_Rail-bas"],
                  Date: "2019_12_03_10_34_32",
                  Version: "1.0.0.1",
                },
              ],
              config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
            );
            reverseSideRevitApi(props);

            await api.familyEditor.loadFamilies(
              [
                {
                  FamilyName: "Placo_Rail-haut",
                  FamilyTypes: ["Placo_Rail-haut"],
                  Date: "2019_12_03_10_34_32",
                  Version: "1.0.0.1",
                },
              ],
              config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
            );
            reverseSideRevitApi(props);

            await api.familyEditor.loadFamilies(
              [
                {
                  FamilyName: "Placo_Ossature-Double",
                  FamilyTypes: ["Placo_Ossature-Double"],
                  Date: "2019_12_03_10_34_32",
                  Version: "1.0.0.1",
                },
              ],
              config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
            );
            /////
            reverseSideRevitApi(props);

            /// 25. Set processing
            setStep("processing");

            let uniqueLevelIds = uniq(map(wallsData, "LevelId"));
            let wallsDataByLevel = await api.queries.getWallsDataByLevel(
              uniqueLevelIds
            );
            reverseSideRevitApi(props);

            /// 26. Fetch Layout from layout calculator api
            let wallIdsGroups: any = await layoutGroup(
              wallsMatching,
              wallsData,
              wallsDataByLevel,
              config
            ).catch((error) => {
              return Promise.reject({
                code: errorCodeKey.PB_PE_H_007,
                stack: error.stack,
              });
            });

            let layoutData: any = await layoutInGroup(
              wallsMatching,
              wallsData,
              wallIdsGroups,
              wallsDataByLevel,
              config
            ).catch((error) => {
              return Promise.reject({
                code: errorCodeKey.PB_PE_H_007,
                stack: error.stack,
              });
            });

            await drawWall(
              setGroupNumber,
              setProgress, progress
            )(layoutData);
          }
        } else {
          setStep("nowall");
        }
      } catch (error: any) {
        onError(error.code, error.stack, true);
        setStep("error");
      } finally {
        setLoading(false);
      }
      await sendEvent();
    } else {
      setStep("nowall");
    }
    await colorizationForExisitingFilters(props);
    if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "false") {
      api.windowsHandler.closeWindow();
    }
  } catch (error) {
    console.log("error found in last step: ", error);
  }
};

const sendEvent = async () => {
  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Flip",
    module: "PLACOBIM",
  });
};

export const ReverseSideHome = ({ config, onError }: any) => {
  // React state Hook
  const [loading, setLoading] = useState<boolean>(false);
  const [groupNumber, setGroupNumber] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [step, setStep] = useState<string>("loading");
  const dispatch = useDispatch();

  const steps = () => {
    switch (step) {
      case "loading":
        return "Permutation en cours";
      case "families":
        return "Chargement des familles...";
      case "processing":
        return `Permutation en cours:  ${
          groupNumber ? Math.round((progress / groupNumber) * 100) : 0
        }%`;
      case "deleting":
        return "Suppression en cours";
      case "nowall":
        return "Rien de sélectionné...";
      case "error":
        return "Something went wrong...";
      default:
        return "Permutation en cours";
    }
  };

  if (loading) {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={steps()} />
      </Dimmer>
    );
  }

  return (
    <Fragment>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/PlacoBIM.jpg"}
        name={"Permutation"}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Button
            style={{ marginTop: 20 }}
            primary
            onClick={() => {
              RunReverse({
                setLoading,
                setGroupNumber,
                setProgress,
                setStep,
                progress,
                dispatch,
                config,
                onError,
              });
            }}
          >
            Séléctionnez des cloisons à permuter
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
