import { Language, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers, Action } from "redux";
import {
  ProductDetailData,
  PIMLayoutAttributesAid,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import {
  FETCH_PLACO_DATA,
  DataAction,
  FilterAction,
  SET_SELECTION_TREE,
  SelectionTreeAction,
  SelectorAction,
  INIT_GROUP_SELECTOR,
  SELECT_LEVEL,
  SELECT_TYPE,
  MappingTableAction,
  INIT_MAPPING_TABLE,
  SELECT_SYSTEM,
  ProductSelectorAction,
  INIT_PRODUCT_SELECTOR,
  SET_SELECTION_BUFFER,
  SET_ACTIVE_MAPPING_ROW_INDEX,
  RESET_SELECTION_BUFFER,
  SEARCH,
  APPLY_FILTERS,
  RESET_FILTERS,
  LayoutAction,
  INIT_LAYOUT_PROGRESS,
  UPDATE_LAYOUT_PROGRESS,
  START_LAYOUT,
  END_LAYOUT,
  FETCH_RIGIPS_DATA,
  FetchRigipsDataAction,
  SELECT_ECOPHON_SYSTEM,
  SelectEcophonSystemActions,
  SelectRangeAction,
  SelectConfigurationAction,
  SelectProductAction,
  SELECT_RANGE,
  SELECT_CONFIGURATION,
  TOGGLE_CHECK,
  TOGGLE_ALL,
  SELECT_ANGLE,
  SelectAngleAction,
  SELECT_PRODUCT,
  SELECT_PLENUM_HEIGHT,
  SELECT_MYHANGER,
  SelectHeightUnderCeilingAction,
  FAMILY_LOADING,
  END_PROGRESS,
} from "../Actions/types";
import { placoFilters } from "../Ressources/placoFilters";
import {
  TypeData,
  LevelData,
  MappingRowEcophon,
} from "../../../../RevitJS/Types/StoreTypes";
import { levelData, elementTypes } from "../../../../RevitJS/Helpers";
import { FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";
import { LayoutState } from "../../../../Layout/types";
import { LanguageAction, INIT_LANGUAGE } from "../../../../Actions/types";
import {
  EdgeAT24,
  Standard,
  config_600x600,
  dbFaker,
  Ecophon_Focus_A20_T24_600x600,
} from "../dbFaker";
import { config } from "../../Delete/Reducers";

export const language = (
  language: Language = "German",
  action: LanguageAction
): Language => {
  switch (action.type) {
    case INIT_LANGUAGE:
      return action.language;
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const placoData = (
  placoData = {
    partitionWalls: { systemsDetails: [], filters: [] },
    liningWalls: { systemsDetails: [], filters: [] },
  },
  action: DataAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return {
        partitionWalls: action.partitionsDetails,
        liningWalls: action.liningWallsDetails,
      };
    default:
      return placoData;
  }
};

export const rigipsMetaData = (
  rigipsMetaData: ProductMeta[] = [],
  action: FetchRigipsDataAction
) => {
  switch (action.type) {
    case FETCH_RIGIPS_DATA:
      return action.products;
    default:
      return rigipsMetaData;
  }
};

export const selectionTree = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeAction | SelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE:
      return action.tree;
    case INIT_GROUP_SELECTOR:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const filters = (
  filters: { liningWalls: any[]; partitions: any[] } = placoFilters,
  action: FilterAction | ProductSelectorAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return {
        liningWalls: action.liningWallsDetails.filters,
        partitions: action.partitionsDetails.filters,
      };
    case APPLY_FILTERS:
      return action.filters;
    case RESET_FILTERS:
      return action.filters;
    default:
      return filters;
  }
};

export const levelsData = (
  levelsData: LevelData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const typesData = (
  typesData: TypeData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL:
      return action.typesData;
    case SELECT_TYPE:
      return action.typesData;
    default:
      return typesData;
  }
};

export const selectorType = (selectorType: string = "wall", action: any) => {
  switch (action.type) {
    default:
      return selectorType;
  }
};

export const selectedTypes = (
  selectedTypes: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedTypes;
    case SELECT_TYPE:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedLevels = (
  selectedLevels: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const mappingRows = (
  mappingRows: MappingRowEcophon[] = [],
  action: MappingTableAction
): MappingRowEcophon[] => {
  switch (action.type) {
    case INIT_MAPPING_TABLE:
      return action.mappingRows;
    case SELECT_SYSTEM:
      return mappingRows.map((sys) =>
        sys.Index === action.index
          ? { ...sys, MappedSystem: action.system }
          : sys
      );
    case SELECT_RANGE:
      return mappingRows.map((sys) =>
        sys.Index === action.index ? { ...sys, Range: action.id } : sys
      );
    case SELECT_ECOPHON_SYSTEM:
      return mappingRows.map((sys) =>
        sys.Index === action.index ? { ...sys, EcophonSystem: action.id } : sys
      );
    case SELECT_CONFIGURATION:
      return mappingRows.map((sys) =>
        sys.Index === action.index ? { ...sys, Configuration: action.id } : sys
      );
    case SELECT_ANGLE:
      return mappingRows.map((sys) =>
        sys.Index === action.index ? { ...sys, Angle: action.id } : sys
      );
    case SELECT_PLENUM_HEIGHT:
      return mappingRows.map((sys) =>
        sys.Index === action.index
          ? { ...sys, HeightUnderCeiling: action.id }
          : sys
      );
    case SELECT_MYHANGER:
      return mappingRows.map((sys) =>
        sys.Index === action.index ? { ...sys, MyHanger: action.id } : sys
      );
    case SELECT_PRODUCT:
      return mappingRows.map((sys) =>
        sys.Index === action.index ? { ...sys, Product: action.id } : sys
      );
    case TOGGLE_CHECK: {
      let tmpMappingRows = mappingRows.map((row) => {
        if (row.Index === action.Index) {
          row.Checked = !row.Checked;
        }
        return row;
      });

      return tmpMappingRows;
    }
    case TOGGLE_ALL: {
      let tmpMappingRows = mappingRows.map((row) => {
        row.Checked = action.checked;
        return row;
      });
      return tmpMappingRows;
    }
    default:
      return mappingRows;
  }
};

export const filterTypeEnabled = (
  filterTypeEnabled: FilterTypeEnabled = "partitionsWalls",
  action: any
) => {
  switch (action.type) {
    default:
      return filterTypeEnabled;
  }
};

export const activeMappingRowIndex = (
  activeMappingRowIndex: null | number = null,
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SET_ACTIVE_MAPPING_ROW_INDEX:
      return action.index;
    default:
      return activeMappingRowIndex;
  }
};

export const filteredElements = (
  filteredElements: ProductDetailData[] = [],
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case INIT_PRODUCT_SELECTOR:
      return action.filteredElements;
    case SEARCH:
      return action.filteredElements;
    case APPLY_FILTERS:
      return action.filteredElements;
    case RESET_FILTERS:
      return action.filteredElements;
    default:
      return filteredElements;
  }
};

export const displayedElements = (
  displayedElements: ProductDetailData[] = [],
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case INIT_PRODUCT_SELECTOR:
      return action.displayedElements;
    case SEARCH:
      return action.displayedElements;
    case APPLY_FILTERS:
      return action.displayedElements;
    case RESET_FILTERS:
      return action.displayedElements;
    default:
      return displayedElements;
  }
};

export const selectionBuffer = (
  selectionBuffer: ProductDetailData | null = null,
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_BUFFER:
      return action.system;
    case RESET_SELECTION_BUFFER:
      return null;
    case APPLY_FILTERS:
      return null;
    case RESET_FILTERS:
      return null;
    case SEARCH:
      return null;
    default:
      return selectionBuffer;
  }
};

export const searchedWord = (
  searchedWord: string = "",
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SEARCH:
      return action.searchedWord;
    case APPLY_FILTERS:
      return "";
    case RESET_FILTERS:
      return "";
    default:
      return searchedWord;
  }
};

export const layoutAttributesAid = (
  layoutAttributesAid: PIMLayoutAttributesAid | null = null,
  action: DataAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return action.layoutAttributesAid;
    default:
      return layoutAttributesAid;
  }
};

export const layoutProgress = (
  layoutProgress: number = 0,
  action: LayoutAction
) => {
  switch (action.type) {
    case INIT_LAYOUT_PROGRESS:
      return 0;
    case UPDATE_LAYOUT_PROGRESS:
      return action.progress;
    default:
      return layoutProgress;
  }
};

export const layoutState = (
  layoutState: LayoutState = null,
  action: LayoutAction
): LayoutState => {
  switch (action.type) {
    case START_LAYOUT:
      return "StartLoading";
    case FAMILY_LOADING:
      return "FamilyLoading";
    case INIT_LAYOUT_PROGRESS:
      return "Processing";
    case END_LAYOUT:
      return "Ended";
    case END_PROGRESS:
      return null;
    default:
      return layoutState;
  }
};

export const ecophonSystems = (
  ecophonSystems: { [key: string]: { id: string } } = dbFaker.EcophonSystems,
  action: Action
) => {
  switch (action.type) {
    default:
      return ecophonSystems;
  }
};

export const ranges = (
  ranges: { [key: string]: { id: string } } = dbFaker.Ranges,
  action: Action
) => {
  switch (action.type) {
    default:
      return ranges;
  }
};

export const configurations = (
  configurations: { [key: string]: { id: string } } = dbFaker.Configurations,
  action: Action
) => {
  switch (action.type) {
    default:
      return configurations;
  }
};

export const products = (
  products: { [key: string]: { id: string } } = dbFaker.Products,
  action: Action
) => {
  switch (action.type) {
    default:
      return products;
  }
};

export const selectedEcophonSystem = (
  selectedEcophonSystem: string | null = Standard,
  action: SelectEcophonSystemActions
) => {
  switch (action.type) {
    case SELECT_ECOPHON_SYSTEM:
      return action.id;
    default:
      return selectedEcophonSystem;
  }
};

export const selectedRange = (
  selectedRange: string | null = EdgeAT24,
  action: SelectRangeAction
) => {
  switch (action.type) {
    case SELECT_ECOPHON_SYSTEM:
      return null;
    case SELECT_RANGE:
      return action.id;
    default:
      return selectedRange;
  }
};

export const selectedConfiguration = (
  selectedConfiguration: string | null = `${EdgeAT24}_${config_600x600}`,
  action: SelectConfigurationAction
) => {
  switch (action.type) {
    case SELECT_ECOPHON_SYSTEM:
      return null;
    case SELECT_RANGE:
      return null;
    case SELECT_CONFIGURATION:
      return action.id;
    default:
      return selectedConfiguration;
  }
};

export const selectedProduct = (
  selectedProduct:
    | string
    | null = `${EdgeAT24}_${config_600x600}_${Ecophon_Focus_A20_T24_600x600}`,
  action: SelectProductAction
) => {
  switch (action.type) {
    case SELECT_ECOPHON_SYSTEM:
      return null;
    case SELECT_RANGE:
      return null;
    case SELECT_CONFIGURATION:
      return null;
    case SELECT_PRODUCT:
      return action.id;
    default:
      return selectedProduct;
  }
};

export const selectedAngle = (
  selectedAngle: number | null = 0,
  action: SelectAngleAction
) => {
  switch (action.type) {
    case SELECT_ECOPHON_SYSTEM:
      return 0;
    case SELECT_ANGLE:
      return action.id;
    default:
      return selectedAngle;
  }
};

export const selectedHeightUnderCeiling = (
  selectedHeightUnderCeiling: number | null = 300,
  action: SelectHeightUnderCeilingAction
) => {
  switch (action.type) {
    case SELECT_ECOPHON_SYSTEM:
      return 0;
    case SELECT_PLENUM_HEIGHT:
      return action.id;
    default:
      return selectedHeightUnderCeiling;
  }
};

export const reducers = combineReducers({
  language,
  functionalityIcon,
  placoData,
  selectionTree,
  filters,
  levelsData,
  typesData,
  selectedLevels,
  selectedTypes,
  mappingRows,
  filterTypeEnabled,
  activeMappingRowIndex,
  filteredElements,
  displayedElements,
  selectionBuffer,
  searchedWord,
  layoutAttributesAid,
  layoutProgress,
  layoutState,
  rigipsMetaData,
  ecophonSystems,
  ranges,
  configurations,
  products,
  selectedEcophonSystem,
  selectedRange,
  selectedConfiguration,
  selectedProduct,
  selectedAngle,
  selectedHeightUnderCeiling,
  config,
});

export interface DrawStore {
  language: Language;
  functionalityIcon: string;
  placoData: {
    partitionWalls: { systemsDetails: []; filters: [] };
    liningWalls: { systemsDetails: []; filters: [] };
  };
  selectionTree: ElementsTree;
  filters: { liningWalls: any[]; partitions: any[] };
  levelsData: LevelData[];
  typesData: TypeData[];
  selectedLevels: string[];
  selectedTypes: string[];
  mappingRows: MappingRowEcophon[];
  filterTypeEnabled: FilterTypeEnabled;
  activeMappingRowIndex: number | null;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  selectionBuffer: ProductDetailData | null;
  searchedWord: string;
  layoutAttributesAid: PIMLayoutAttributesAid | null;
  layoutProgress: number;
  layoutState: LayoutState;
  rigipsMetaData: ProductMeta[];
  ecophonSystems: { [key: string]: { id: string } };
  ranges: { [key: string]: { id: string; parentSystem: string } };
  configurations: {
    [key: string]: { id: string; config: string; parentRange: string };
  };
  products: {
    [key: string]: {
      id: string;
      parentRange: string;
      parentConfig: string;
      parentSystem: string;
      product: string;
    };
  };
  selectedEcophonSystem: string | null;
  selectedRange: string | null;
  selectedConfiguration: string | null;
  selectedProduct: string | null;
  selectedAngle: number | null;
  selectedHeightUnderCeiling: number | null;
  config: any;
}

// let tree = await api.selection.elementsByLevelAndType("wall");
//     let elementsByLevelAndType = tree.Tree;
//     let typesData = elementTypes(levelData(tree, [])) as TypeData[];
//     this.setState({
//       levelsData: elementsByLevelAndType.map(level => ({
//         Name: level.Level.Name,
//         Checked: false
//       })),
//       typesData,
//       tree
//     });
