import React, { Component } from "react";
import {
  Container,
  Button,
  Grid,
  Icon,
  Popup,
  Segment,
  Header,
  Dimmer,
  Modal,
} from "semantic-ui-react";
import {
  Selection,
  PlacoOptions,
} from "../../../../../RevitJS/Types/StoreTypes";
import { SelectionStore } from "../../../Selection/Reducers";
import { connect } from "react-redux";
import { Routes } from "../root";
import "../../Resources/CommonStyles.css";
import ButtonPack from "../ButtonPack";
import ReactHtmlParser from "react-html-parser";
import { FunctionalityHeader } from "../FunctionalityHeader";
import { setPrevPage } from "../../Actions";
import { StepsData } from "../../../../../Components/StepsData/StepsData";
import { getProjectId, saveDossierTechniques } from "../../Helpers";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import _, {
  compact,
  filter,
  find,
  flatMap,
  forEach,
  includes,
  isEqual,
  map,
} from "lodash";
import { initProjectData } from "../../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../../RevitJS/API";
import { errorCodeKey } from "../../../../../ErrorManagement/utils/errorCodeEnum";
interface Props {
  tabName: string;
  newDocumentName: string;
  wording: {
    selections: { [key: string]: string };
    infoprojectsheader: { [key: string]: string };
    coverPage: { [key: string]: string };
    referenceCCTP: { [key: string]: string };
    completesteps: { [key: string]: string };
    completed: { [key: string]: string };
    notCompleted: { [key: string]: string };
    objects: { [key: string]: string };
    back: { [key: string]: string };
    cancel: { [key: string]: string };
    validate: { [key: string]: string };
    docSaved: { [key: string]: string };
    save: { [key: string]: string };
    saveHeader: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  routes: Routes;
  dossierData: any;
  Icon: string;
  setPrevPage: any;
  moduleData: any;
  projectData: initProjectData;
  config: any;
  onError: any;
}

export class TechnicalLanding extends React.Component<Props> {
  state = {
    isInfoProjectCompleted: false,
    active: false,
    isSelectionCompleted: this.props.dossierData.selections.status,
    isCCTPReferenceCompleted: this.props.dossierData.data.status,
  };

  // componentDidUpdate(prevProps : any, prevState : any) {
  //   if (prevProps.dossierData.data.status !== this.props.dossierData.data.status) {
  //     // Now fetch the new data here.
  //     this.setState({isCCTPReferenceCompleted: this.props.dossierData.data.status})
  //   }
  // }

  public static defaultProps = {
    wording: require("../../Resources/wording.json"),
  };
  ifWallOrCeilingDeletedInModal = async () => {
    // get list fo selections
    const placoSelections = await bimStorage.getItem(
      storageKey.PLACOSELECTIONS
    );

    // if walls used in the dossier has been deleted from the modal
    let updatedDossierData = _.cloneDeep(this.props.dossierData);

    // get selections of current Dossier
    const currentSelectionsId = updatedDossierData.selections.list[0];

    // check if any of the walls used in the current selection is not present in the modal

    // if any walls is deleted and same type of wall exist decrese the count
    placoSelections[currentSelectionsId].SelectionByType.wall.Rows = [];
    // alert("hi")
    bimStorage.setItem(
      storageKey.PLACOSELECTIONS,
      JSON.stringify(placoSelections)
    );
    // if all walls used on the same type has been delted remove the wall
    // saveDossierTechniques(updatedDossierData);

    // else if nothing is deleted dont do anything

    //alert("yes right place")
    // saveDossierTechniques(updatedDossierData);
  };
  componentDidMount = async () => {
    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );

    let projectInfo = tempProjectInfo === null ? {} : tempProjectInfo;
    let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
    const currentRevit = this.props.projectData.ProjectId;
    //this.ifWallOrCeilingDeletedInModal()

    this.setState({
      isInfoProjectCompleted:
        projectInfo &&
        projectInfo["PROJECT_INFO_FORM"] &&
        projectInfo["PROJECT_INFO_FORM"].modelInfo &&
        projectInfo["PROJECT_INFO_FORM"].modelInfo?.some(
          (e: any) => e.model_name === currentRevit
        )
          ? true
          : false,
      isCCTPReferenceCompleted: this.props.dossierData.data.status,
    });

    const [selectionUpdated, cctpUpdated] = checkSelectionUpdatedForDossier(
      this.props.dossierData.selections.list,
      this.props.dossierData.data.list,
      placoSelections
    );

    this.setState({
      isSelectionCompleted: selectionUpdated,
      isCCTPReferenceCompleted: cctpUpdated,
    });

    if (
      this.state.isInfoProjectCompleted ||
      this.state.isSelectionCompleted ||
      this.state.isCCTPReferenceCompleted
    ) {
      saveDossierTechniques(this.props.dossierData, this.props.config);
    }
    if (localStorage.getItem("min") === "0:0") {
      localStorage.removeItem("min");
    }
    if (localStorage.getItem("dossierStep4")) {
      localStorage.removeItem("dossierStep4");
    }
  };

  actionHandler = async (action: any) => {
    if (action === Routes.REFERENCE_CCTP) {
    }
    localStorage.setItem("dossierStep4", "true");
    this.props.setRoute(action);
  };

  setRouteToProjectInfo = () => {
    this.props.setPrevPage(Routes.TECHNICAL_LANDING);
    this.actionHandler(Routes.PROJECT_INFO);
    localStorage.setItem("isModification", "true");
  };

  toggleAction = (actionName: string, action: boolean) => {
    this.setState({ [actionName]: action });
  };

  render() {
    let steps = [
      {
        name: ReactHtmlParser(
          this.props.wording.infoprojectsheader[this.props.language]
        ),
        action: () => this.setRouteToProjectInfo(),
        isActionCompleted: this.state.isInfoProjectCompleted,
      },
      {
        name: ReactHtmlParser(
          this.props.wording.coverPage[this.props.language]
        ),
        action: () => this.actionHandler(Routes.COVER_PAGE),
        isDisabled: !this.state.isInfoProjectCompleted,
        isActionCompleted:
          this.props.dossierData.coverPage === undefined
            ? false
            : this.state.isInfoProjectCompleted
            ? this.props.dossierData.coverPage.status
            : false,
      },
      {
        name: ReactHtmlParser(
          this.props.wording.selections[this.props.language]
        ),
        action: () => this.actionHandler(Routes.DOC_SELECTION_HOME),
        isActionCompleted: this.state.isSelectionCompleted,
      },
      {
        name: ReactHtmlParser(
          this.props.wording.referenceCCTP[this.props.language]
        ),
        action: () => this.actionHandler(Routes.REFERENCE_CCTP),
        isDisabled: !this.state.isSelectionCompleted,
        isActionCompleted: this.state.isCCTPReferenceCompleted,
      },
    ];

    let totalStepsCompleted = 0;
    steps.forEach((step) => {
      if (step.isActionCompleted) totalStepsCompleted++;
    });

    return (
      <div style={{ padding: 10, height: "90vh" }}>
        <Modal open={this.state.active} size="mini" dimmer="blurring">
          <Modal.Header className="modalHeader">
            {this.props.wording.saveHeader[this.props.language]}{" "}
            <Icon
              name="close"
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                this.toggleAction("active", false);
              }}
            ></Icon>
          </Modal.Header>
          <Modal.Description
            style={{ textAlign: "center", padding: "15px 0px" }}
          >
            <p>{this.props.wording.docSaved[this.props.language]}</p>
          </Modal.Description>
        </Modal>

        <FunctionalityHeader
          name={this.props.dossierData.name}
          Icon={this.props.Icon}
          subheader=""
        />
        <Dimmer.Dimmable
          as={Container}
          dimmed={this.state.active}
          className="mainContainer"
          style={{
            width: "100%",
            height: "calc(100% - 30px)",
            position: "relative",
          }}
        >
          <Header className="subHeader" as="h5">
            {totalStepsCompleted +
              "/" +
              steps.length +
              " " +
              this.props.wording.completesteps[this.props.language]}
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={15}>
                <div style={{ textAlign: "right" }}>
                  <Popup
                    content={ReactHtmlParser(
                      this.props.wording.save[this.props.language]
                    )}
                    position="top center"
                    inverted
                    size="mini"
                    trigger={
                      <Icon
                        name="save"
                        color="blue"
                        size="big"
                        onClick={() => {
                          saveDossierTechniques(
                            this.props.dossierData,
                            this.props.config
                          ).catch((error) => {
                            this.props.onError(errorCodeKey.PB_DT_H_011);
                          });
                          localStorage.setItem("isModification", "false");
                          this.toggleAction("active", true);
                        }}
                      ></Icon>
                    }
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid style={{ width: "100%", marginBottom: 50 }}>
              <StepsData data={steps} {...this.props} />
            </Grid>
          </Grid>

          <Grid
            className="text-center"
            style={{
              position: "absolute",
              bottom: 30,
              width: "100%",
            }}
          >
            <Grid.Row>
              <Grid.Column>
                <ButtonPack
                  setRoute={this.props.setRoute}
                  routes={this.props.routes}
                  isAllStepsCompleted={
                    steps.every((e: any) => e.isActionCompleted) ? true : false
                  }
                  onError={this.props.onError}
                ></ButtonPack>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Dimmer.Dimmable>
      </div>
    );
  }
}

const mapDispatch = {
  setPrevPage,
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    language: state.language,
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    dossierData: state.moduleData.dossierData,
    Icon: state.functionalityIcon,
    projectData: state.projectData,
    config: state.config,
  };
};

export default connect(mapStateToProps, mapDispatch)(TechnicalLanding);

const checkSelectionUpdatedForDossier = (
  dossierSelection: any,
  dossierDataList: any,
  selections: any
) => {
  if (dossierSelection.length > 0) {
    // check if the current selection has been marked as zone, if yes marke both 3,4 steps incomplete
    const selectionDeleted = checkIfSelectionIsDeleted(
      dossierSelection,
      selections
    );

    if (selectionDeleted) return [false, false];

    // check if the selection has been change to zone
    const selectionZoned = checkIfSelectionMarkedZoned(
      dossierSelection,
      selections
    );
    if (selectionZoned) return [true, false];
    // check if selection value updated
    const selectionValueUpdated = checkIfSelectionValueUpdated(
      dossierSelection,
      selections,
      dossierDataList
    );
    if (selectionValueUpdated) return [true, false];
    // check if cctp is Empty
    const cctpEmpty = checkIfCctpEmpty(dossierDataList);

    if (cctpEmpty) return [true, false];

    return [true, true];
  }

  return [false, false];
};

const checkIfCctpEmpty = (dossierDataList: any) => {
  const checkifanyCCTPisempty = dossierDataList.some((e: any) => {
    return e.CCTPReference === "" && e.isSelected === true;
  });

  if (checkifanyCCTPisempty) return true;

  return false;
};

const checkIfSelectionIsDeleted = (dossierSelections: any, selections: any) => {
  const checkIfSelectionIsDeleted = dossierSelections.some((e: any) => {
    return selections[e] === undefined;
  });
  if (checkIfSelectionIsDeleted) return true;
  return false;
};

const checkIfSelectionMarkedZoned = (
  dossierSelection: any,
  selections: any
) => {
  const ifSelectionHasBeenZoned = dossierSelection.some((e: any) => {
    return selections[e].Zone;
  });

  if (ifSelectionHasBeenZoned) return true;
  return false;
};

const checIfNonPlacoSelectionRemoved = () => {};

const checkIfSelectionValueUpdated = (
  dossierSelection: any,
  selections: any,
  dossierDataList: any
) => {
  // Dossier Selection new Value
  const dossierHistorySelection = filter(
    selections,
    (sel: Selection<PlacoOptions>, index: string) => {
      if (includes(dossierSelection, index)) {
        return true;
      }
      return false;
    }
  );

  const wallDossierSelectionNewData = flatMap(
    map(dossierHistorySelection, (sel, index) => {
      return map(sel.SelectionByType.wall.Rows, (row, ind) => {
        return {
          Count: row.RevitSelection.Ids.length,
          RevitSystems: row.RevitSelection.RevitType,
          solutionOid: row.Options.MappedSystem?.oid,
          translation: row.Options.MappedSystem["A-Solution product name"],
        };
      });
    })
  );

  const ceilingDossierSelectionNewData = flatMap(
    map(dossierHistorySelection, (sel, index) => {
      return map(sel.SelectionByType.ceiling.Rows, (row, ind) => {
        return {
          Count: row.RevitSelection.Ids.length,
          RevitSystems: row.RevitSelection.RevitType,
          solutionOid: row.Options.MappedSystem?.oid,
          translation: row.Options.MappedSystem["A-Solution product name"],
        };
      });
    })
  );

  const dossierSelectionNewData = wallDossierSelectionNewData.concat(
    ceilingDossierSelectionNewData
  );

  // Dossier Selection old values
  const dossierHistoryData = compact(
    map(dossierDataList, (row, ind) => {
      if (!row.duplicated) {
        return {
          Count: row.Count,
          RevitSystems: row.RevitSystems,
          solutionOid: row.Solution.oid,
          translation: row.Solution.label,
        };
      }
      return null;
    })
  );

  /// remove cctp files for deleted non placo
  let cctpFiles: any = [];
  forEach(dossierDataList, (row, ind) => {
    if (row.isNonPlaco) {
      if (!find(dossierSelectionNewData, { solutionOid: row.Solution.oid })) {
        const fles = map(row.files, "fileData");
        cctpFiles = cctpFiles.concat(fles);
      }
    }
  });

  // if (cctpFiles.length > 0)
  //   api.queries.removePhotoboxFile(JSON.stringify(cctpFiles));

  if (
    !isEqual(
      _.sortBy(dossierSelectionNewData, "RevitSystems"),
      _.sortBy(dossierHistoryData, "RevitSystems")
    )
  ) {
    return true;
  } else {
    return false;
  }
};
