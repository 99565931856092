import {
  ID,
  ViewDetail,
  ParamQuery,
  CeilingData,
  CustomParameter,
  ElementParamSetter,
} from "../Types/RevitTypes";
import { escapeSpecialChars, wait } from "../Helpers";
import { RevitMappedWall } from "../../Layout/types";
import { initProjectData } from "../Types/StoreTypes";
import { PDFDocument } from "pdf-lib";
import { api } from ".";

export class Queries {
  getWallsData = async (wallsId: ID[]): Promise<RevitMappedWall[]> => {
    return new Promise((resolve, reject) =>
      window.indec.getWallsData({ ids: wallsId }, resolve, reject)
    )
      .then((x: any) => {
        return JSON.parse(x);
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      });

    // localStorage.setItem("getWallsData", "true");
    // localStorage.setItem("getWallsDataResult", "null");
    // localStorage.setItem("isErrorThrown", "False");

    // let processing: boolean = true;
    // let response = "";
    // window.revitCallBack = async (responseBack: any, status: string) => {
    //   //console.log(responseBack);
    //   response = responseBack;
    //   localStorage.setItem("getWallsData", "false");
    // };
    // window.revit.getWallsData(JSON.stringify(wallsId));

    // while (processing) {
    //   await wait(50);
    //   processing = JSON.parse(localStorage.getItem("getWallsData") as string);
    // }

    // response = response.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
    //   m.replace('"', '\\"')
    // );

    // response = response.replace(/\\/g, "\\");
    // response = escapeSpecialChars(response);
    // response = response.replace(/(:\s*"[^"]+:\s*)"([^"]+)"/gm, "$1$2");

    // if (this.isJson(response)) {
    //   return JSON.parse(response);
    // } else {
    //   return [];
    // }
  };

  async getRevitLanguage(): Promise<string> {
    if (!window.revit) {
      const getNavLanguage = async () => {
        let nav_language = window.navigator.language;
        switch (nav_language) {
          case "fr":
            return "French";
          case "fr-FR":
            return "French";
          case "de":
            return "German";
          case "de-CH":
            return "German";
          case "de-GR":
            return "German";
          default:
            return "English";
        }
      };
      return getNavLanguage();
    }

    localStorage.setItem("getRevitLanguage", "true");
    localStorage.setItem("getRevitLanguageResult", "null");

    let processing: boolean = true;

    window.revit.getRevitLanguage();

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("getRevitLanguage") as string
      );
    }
    if (this.isJson(localStorage.getItem("getRevitLanguageResult") as string)) {
      return JSON.parse(
        localStorage.getItem("getRevitLanguageResult") as string
      );
    } else {
      return localStorage.getItem("getRevitLanguageResult") as string;
    }
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  async getViewByName(name: string): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getViewByName", "true");
      localStorage.setItem("getViewByNameResult", "null");

      let processing: boolean = true;
      window.revit.getViewByName(name);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getViewByName") as string
        );
      }
      return JSON.parse(localStorage.getItem("getViewByNameResult") as string);
    }
  }

  async getViewByNameAndType(name: string, type: string): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getViewByNameAndType", "true");
      localStorage.setItem("getViewByNameAndTypeResult", "null");

      let processing: boolean = true;
      window.revit.getViewByNameAndType(name, type);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getViewByNameAndType") as string
        );
      }
      return JSON.parse(
        localStorage.getItem("getViewByNameAndTypeResult") as string
      );
    }
  }

  async getFilterByName(viewId: ID, name: string): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getFilterByName", "true");
      localStorage.setItem("getFilterByNameResult", "null");

      let processing = true;
      window.revit.getFilterByName(viewId, name);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getFilterByName") as string
        );
      }
      return JSON.parse(
        localStorage.getItem("getFilterByNameResult") as string
      );
    }
  }

  async duplicateActiveView(name: string): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("duplicateActiveView", "true");
      localStorage.setItem("duplicateActiveViewResult", "null");

      let processing = true;
      window.revit.duplicateActiveView(name);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("duplicateActiveView") as string
        );
      }
      // alert("one");
      return JSON.parse(
        localStorage.getItem("duplicateActiveViewResult") as string
      );
    }
  }

  async setActiveView(id: ID) {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("setActiveView", "true");
      localStorage.setItem("setActiveViewResult", "null");

      let processing = true;
      window.revit.setActiveView(id);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("setActiveView") as string
        );
      }
      return JSON.parse(localStorage.getItem("setActiveViewResult") as string);
    }
  }

  async createViewFilter(id: ID, filterName: string) {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("createViewFilter", "true");
      localStorage.setItem("createViewFilterResult", "null");

      let processing = true;
      window.revit.createViewFilter(id, filterName);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("createViewFilter") as string
        );
      }
      return JSON.parse(
        localStorage.getItem("createViewFilterResult") as string
      );
    }
  }

  async getActiveViewDetails(): Promise<ViewDetail> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getActiveViewDetails", "true");
      localStorage.setItem("getActiveViewDetailsResult", "null");
      localStorage.setItem("isErrorThrown", "False");
      let processing = true;
      window.revit.getActiveViewDetails();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getActiveViewDetails") as string
        );
      }
      return JSON.parse(
        localStorage.getItem("getActiveViewDetailsResult") as string
      );
    }
  }

  getCeilingsData = async (ceilingsId: ID[]): Promise<CeilingData[]> => {
    localStorage.setItem("getCeilingsData", "true");
    localStorage.setItem("getCeilingsDataResult", "null");
    localStorage.setItem("isErrorThrown", "False");
    let processing = true;
    window.revit.getCeilingsData(JSON.stringify(ceilingsId));

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("getCeilingsData") as string
      );
    }
    let response = localStorage.getItem("getCeilingsDataResult") as string;
    response = response.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );
    return JSON.parse(response);
  };

  async getFamilyInstancesByNameAndParentsIds(
    familyName: string,
    parentsIds: ID[]
  ): Promise<ID[]> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getFamilyInstancesByNameAndParentsIds", "true");
      localStorage.setItem(
        "getFamilyInstancesByNameAndParentsIdsResult",
        "null"
      );
      let processing = true;
      window.revit.getFamilyInstancesByNameAndParentsIds(
        familyName,
        JSON.stringify(parentsIds)
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem(
            "getFamilyInstancesByNameAndParentsIds"
          ) as string
        );
      }
      return JSON.parse(
        localStorage.getItem(
          "getFamilyInstancesByNameAndParentsIdsResult"
        ) as string
      );
    }
  }

  async getFamiliyInstancesIdsByNameAndParamValues(
    familyName: string,
    paramQuery: ParamQuery,
    values: any
  ): Promise<ID[]> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem(
        "getFamiliyInstancesIdsByNameAndParamValues",
        "true"
      );
      localStorage.setItem(
        "getFamiliyInstancesIdsByNameAndParamValuesResult",
        "null"
      );
      let processing = true;
      window.revit.getFamiliyInstancesIdsByNameAndParamValues(
        familyName,
        JSON.stringify(paramQuery),
        JSON.stringify(values)
      );

      while (processing) {
        await wait(50);
        // alert(JSON.parse(localStorage.getItem('getWallsData')));
        processing = JSON.parse(
          localStorage.getItem(
            "getFamiliyInstancesIdsByNameAndParamValues"
          ) as string
        );
      }
      return JSON.parse(
        localStorage.getItem(
          "getFamiliyInstancesIdsByNameAndParamValuesResult"
        ) as string
      );
    }
  }

  async filterElements(
    category: string,
    criteria: any,
    type = null
  ): Promise<ID[]> {
    try {
      if (!window.revit) {
        await wait(200);
        return JSON.parse("{}");
      } else {
        localStorage.setItem("filterElements", "true");
        localStorage.setItem("filterElementsResult", "null");
        localStorage.setItem("isErrorThrown", "False");
        let processing = true;
        window.revit.filterElements(
          category,
          JSON.stringify(criteria),
          JSON.stringify(type)
        );

        while (processing) {
          await wait(50);
          processing = JSON.parse(
            localStorage.getItem("filterElements") as string
          );
        }
        return JSON.parse(
          localStorage.getItem("filterElementsResult") as string
        );
      }
    } catch (error: any) {
      // throwerror;
      return Promise.reject(error);
    }
  }

  async getLevelById(levelId: string): Promise<any> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getLevelById", "true");
      localStorage.setItem("getLevelByIdResult", "null");
      let processing = true;
      window.revit.getLevelById(levelId);

      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("getLevelById") as string);
      }

      return JSON.parse(localStorage.getItem("getLevelByIdResult") as string);
    }
  }

  async createSelectionFilter(
    filterName: string,
    elementsIdList: ID[]
  ): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return "1254215";
    } else {
      localStorage.setItem("createSelectionFilter", "true");
      localStorage.setItem("createSelectionFilterResult", "null");
      localStorage.setItem("isErrorThrown", "False");
      let processing = true;
      window.revit.createSelectionFilter(
        filterName,
        JSON.stringify(elementsIdList)
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("createSelectionFilter") as string
        );
      }

      return JSON.parse(
        localStorage.getItem("createSelectionFilterResult") as string
      );
    }
  }

  async getExistingSelectionFilter(
    filterName: string,
    elementsIdList: ID[]
  ): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return "1254215";
    } else {
      localStorage.setItem("getExistingSelectionFilter", "true");
      localStorage.setItem("getExistingSelectionFilterResult", "null");
      localStorage.setItem("isErrorThrown", "False");
      let processing = true;
      window.revit.getExistingSelectionFilter(
        filterName,
        JSON.stringify(elementsIdList)
      );
      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getExistingSelectionFilter") as string
        );
      }

      return JSON.parse(
        localStorage.getItem("getExistingSelectionFilterResult") as string
      );
    }
  }

  async createSelectionFilterForEye(
    filterName: string,
    elementsIdList: ID[]
  ): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return "1254215";
    } else {
      localStorage.setItem("createSelectionFilter", "true");
      localStorage.setItem("createSelectionFilterResult", "null");
      let processing = true;
      window.revit.createSelectionFilterForEye(
        filterName,
        JSON.stringify(elementsIdList)
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("createSelectionFilter") as string
        );
      }

      return JSON.parse(
        localStorage.getItem("createSelectionFilterResult") as string
      );
    }
  }

  async getObjectsParams(objectIds: ID[], paramNames: string[]): Promise<any> {
    localStorage.setItem("getObjectsParams", "true");
    localStorage.setItem("getObjectsParamsResult", "null");
    localStorage.setItem("isErrorThrown", "False");
    let processing = true;
    window.revit.getObjectsParams(
      JSON.stringify(objectIds),
      JSON.stringify(paramNames)
    );

    while (processing) {
      await wait(50);
      // alert(JSON.parse(localStorage.getItem('getWallsData')));
      processing = JSON.parse(
        localStorage.getItem("getObjectsParams") as string
      );
    }

    let result = localStorage.getItem("getObjectsParamsResult") as string;
    result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );
    result = result.replace(/\\/g, "\\");
    return JSON.parse(result);
  }

  async getActiveDocumentName(): Promise<string> {
    if (!window.revit) {
      await wait(200);
      // return JSON.parse("{}");
      return "Projet TEST 2";
    } else {
      localStorage.setItem("getActiveDocumentName", "true");
      localStorage.setItem("getActiveDocumentNameResult", "null");
      localStorage.setItem("isErrorThrown", "False");
      let processing = JSON.parse(
        localStorage.getItem("getActiveDocumentName") as string
      );
      window.revit.getActiveDocumentName();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getActiveDocumentName") as string
        );
        // alert(localStorage.getItem("getActiveDocumentNameResult"));
        // alert(processing);
      }

      let result = localStorage.getItem(
        "getActiveDocumentNameResult"
      ) as string;
      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      return JSON.parse(result);
    }
  }

  async getProjectInformation(): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return "";
    } else {
      localStorage.setItem("getProjectInformation", "true");
      localStorage.setItem("getProjectInformationResult", "null");
      localStorage.setItem("isErrorThrown", "false");
      let processing = JSON.parse(
        localStorage.getItem("getProjectInformation") as string
      );
      window.revit.getProjectInformation();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getProjectInformation") as string
        );
        // alert(localStorage.getItem("getActiveDocumentNameResult"));
        // alert(processing);
      }

      let result = localStorage.getItem(
        "getProjectInformationResult"
      ) as string;

      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      return JSON.parse(result.replace(/\r?\n|\r/g, ""));
    }
  }

  async deleteView(id: string): Promise<string> {
    localStorage.setItem("deleteView", "true");

    let processing = true;
    window.revit.deleteView(id);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("deleteView") as string);
    }

    return "success";
  }

  async createElementTypeWithCustomParameters(
    elementData: CustomParameter[],
    url: string
  ): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return "";
    } else {
      localStorage.setItem("CreateElementTypeWithCustomParameters", "true");
      localStorage.setItem(
        "CreateElementTypeWithCustomParametersResult",
        "null"
      );
      let processing = JSON.parse(
        localStorage.getItem("CreateElementTypeWithCustomParameters") as string
      );
      console.log("CreateElementTypeWithCustomParameters :", elementData);
      let token: string = localStorage.getItem("token") as string;
      window.revit.createElementTypeWithCustomParameters(
        JSON.stringify(elementData),
        url,
        token
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem(
            "CreateElementTypeWithCustomParameters"
          ) as string
        );
      }

      let result = localStorage.getItem(
        "CreateElementTypeWithCustomParametersResult"
      ) as string;

      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      result = escapeSpecialChars(result);
      result = result.replace(/\r?\n|\r/g, "");
      result = result.replace(/\s+/g, " ").trim();
      return JSON.parse(result);
    }
  }

  async getCustomParametersFromElementType(): Promise<string> {
    localStorage.setItem("GetCustomParametersFromElementType", "true");
    localStorage.setItem("GetCustomParametersFromElementTypeResult", "null");
    let processing = JSON.parse(
      localStorage.getItem("GetCustomParametersFromElementType") as string
    );
    window.revit.getCustomParametersFromElementType();
    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("GetCustomParametersFromElementType") as string
      );
    }
    let result = localStorage.getItem(
      "GetCustomParametersFromElementTypeResult"
    ) as string;
    result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );
    result = result.replace(/\\/g, "\\");
    result = escapeSpecialChars(result);
    result = result.replace(/\r?\n|\r/g, "");
    result = result.replace(/\s+/g, " ").trim();
    result = result.replace(/(:\s*"[^"]+:\s*)"([^"]+)"/gm, "$1$2");
    return JSON.parse(result);
  }

  async getProjectData(): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return "";
    } else {
      let result: string = "";
      localStorage.setItem("getProjectData", "true");
      localStorage.setItem(
        "getProjectDataResult",
        JSON.stringify({ ProjectId: "", ProjectPath: "" })
      );
      localStorage.setItem("isErrorThrown", "false");

      let processing = JSON.parse(
        localStorage.getItem("getProjectData") as string
      );
      window.revit.getProjectData();
      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getProjectData") as string
        );
      }
      result = localStorage.getItem("getProjectDataResult") as string;
      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      return JSON.parse(result.replace(/\r?\n|\r/g, ""));
    }
  }

  async createProjectData(): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return "";
    } else {
      localStorage.setItem("createProjectData", "true");
      localStorage.setItem("createProjectDataResult", "null");
      let processing = JSON.parse(
        localStorage.getItem("createProjectData") as string
      );
      window.revit.createProjectData();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("createProjectData") as string
        );
      }

      let result = localStorage.getItem("createProjectDataResult") as string;

      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      // result = result.replace(/\></g,'\\');
      return JSON.parse(result.replace(/\r?\n|\r/g, ""));
    }
  }

  async getSetProjectData(): Promise<initProjectData> {
    if (!window.revit) {
      await wait(200);
      return { ProjectId: "", ProjectPath: "" };
    } else {
      let result: string = "";
      localStorage.setItem("getProjectData", "true");
      localStorage.setItem(
        "getProjectDataResult",
        JSON.stringify({ ProjectId: "", ProjectPath: "" })
      );
      localStorage.setItem("isErrorThrown", "False");
      let processing = JSON.parse(
        localStorage.getItem("getProjectData") as string
      );
      window.revit.getSetProjectData();
      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getProjectData") as string
        );
      }
      result = localStorage.getItem("getProjectDataResult") as string;
      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      const ddd = JSON.parse(result.replace(/\r?\n|\r/g, ""));
      localStorage.setItem("FileName", ddd.ProjectPath);
      if (ddd) {
        return { ProjectId: ddd.ProjectId, ProjectPath: ddd.ProjectPath };
      } else {
        return { ProjectId: "", ProjectPath: "" };
      }
    }
  }

  async downloadFileAnonymous(
    baseUrl: string,
    url: string,
    fileName: string,
    extension: string
  ): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("downloadFile", "true");
      let processing = true;
      const fileInBase64 = await window.revit.downloadFileAnonymous(
        baseUrl,
        url,
        fileName,
        extension
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("downloadFile") as string);
      }

      return fileInBase64;
    }
  }

  async downloadPhotoboxMultipart(
    baseUrl: string,
    url: string,
    requestData: string,
    fileName: string,
    extension: string,
    token: string
  ): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("downloadFile", "true");
      let processing = true;
      const fileInBase64 = await window.revit.downloadFilePhotoboxMutipart(
        baseUrl,
        url,
        requestData,
        fileName,
        extension,
        token
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("downloadFile") as string);
      }

      return fileInBase64;
    }
  }

  async downloadPhotoboxMultipartFile(
    baseUrl: string,
    fileArray: string,
    fileName: string,
    extension: string,
    token: string
  ): Promise<string> {
    console.log("downloadPhotoboxMultipartFile start:");
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("downloadFile", "true");
      let fileInBase64 = await window.revit.downloadPhotoboxMultipartFile(
        baseUrl,
        fileArray,
        fileName,
        extension,
        token
      );
      // if (fileInBase64 !== "" && this.isNumber(fileInBase64)) {
      //   console.log("fileInBase64 :", fileInBase64);
      //   let allSplittedFiles: any[] = [];
      //   let totalSplitedFiles = parseInt(fileInBase64);
      //   for (let i = 1; i <= totalSplitedFiles; i++) {
      //     let fileBase64 = await window.revit.getSplittedFiles(i, extension);
      //     allSplittedFiles.push(fileBase64);
      //   }

      //   if (allSplittedFiles && allSplittedFiles.length > 0) {
      //     const mergedPdf = await PDFDocument.create();

      //     for (let splitedFile of allSplittedFiles) {
      //       let document = await PDFDocument.load(splitedFile);
      //       const copiedPages = await mergedPdf.copyPages(
      //         document,
      //         document.getPageIndices()
      //       );
      //       copiedPages.forEach((page) => mergedPdf.addPage(page));
      //     }
      //     let pdf = await mergedPdf.save();
      //     console.log("pdf saved");
      //     //fileInBase64 = await mergedPdf.saveAsBase64();
      //     var blob = new Blob([pdf], { type: "octet/stream" });
      //     console.log("blob genrated");
      //     fileInBase64 = await this.blobToBase64(blob); //await this.uint8ToBase64(pdf);
      //     if (fileInBase64) {
      //       let splitedBase = fileInBase64.split(",");
      //       if (splitedBase && splitedBase.length > 0) {
      //         fileInBase64 = splitedBase[1];
      //       }
      //     }
      //     console.log("base64 genrated");
      //   }
      // }

      // while (processing) {
      //   await wait(50);
      //   processing = JSON.parse(localStorage.getItem("downloadFile") as string);
      // }
      console.log("fileInBase64:", fileInBase64);
      return fileInBase64;
    }
  }

  async mergeAndSaveFile(
    coverpage: any,
    downloadedFilename: string,
    filenameToBeSaved: string
  ) {
    var blobpdf = new Blob([coverpage], { type: "application/pdf" });
    let coverPageBase64 = await this.blobToBase64(blobpdf);

    let filepath = await window.revit.mergeAndSaveFile(
      coverPageBase64,
      downloadedFilename,
      filenameToBeSaved
    );
    console.log("mergeAndSaveFile filepath", filepath);
    return filepath;
  }

  async openFileExplorer(path: string) {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      let filepath = await window.revit.openFileExplorer(path);
    }
    return path;
  }

  async uint8ToBase64(arr: Uint8Array): Promise<string> {
    // return btoa(
    //   Array(arr.length)
    //     .fill('')
    //     .map((_, i) => String.fromCharCode(arr[i]))
    //     .join('')
    // );
    return btoa(String.fromCharCode(...new Uint8Array(arr)));
  }

  async blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  isNumber(str: string): boolean {
    if (typeof str !== "string") {
      return false;
    }

    if (str.trim() === "") {
      return false;
    }

    return !Number.isNaN(Number(str));
  }

  callSaveDialog() {
    window.revit.callSaveDialog();
  }

  async genrateDossierPDF(
    html: string,
    documentType: string,
    logo: any
  ): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("downloadFile", "true");
      let processing = true;
      const fileInBase64 = await window.revit.genrateDossierPDF(
        html,
        documentType,
        logo
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("downloadFile") as string);
      }

      return fileInBase64;
    }
  }

  async closeView(id: string): Promise<string> {
    localStorage.setItem("closeView", "true");

    let processing = true;
    window.revit.closeView(id);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("closeView") as string);
    }

    return "success";
  }

  async createSchedule(type: string, scheduleName: string): Promise<string> {
    localStorage.setItem("createSchedule", "true");

    let processing = true;
    window.revit.createSchedule(type, scheduleName);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("createSchedule") as string);
    }

    return "success";
  }

  async setScheduleImages(param: ElementParamSetter[]): Promise<string> {
    localStorage.setItem("setScheduleImages", "true");

    let processing = true;
    window.revit.setScheduleImages(JSON.stringify(param));

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("setScheduleImages") as string
      );
    }

    return "success";
  }

  async duplicateViewType(
    existingTypeName: string,
    newTypeName: string,
    type: string
  ): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("duplicateViewType", "true");
      localStorage.setItem("duplicateViewTypeResult", "null");

      let processing = true;
      window.revit.duplicateViewType(existingTypeName, newTypeName, type);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("duplicateViewType") as string
        );
      }
      // alert("one");
      return JSON.parse(
        localStorage.getItem("duplicateViewTypeResult") as string
      );
    }
  }

  async duplicateActiveViewInNewViewType(
    typeName: string,
    viewName: string
  ): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("DuplicateActiveViewInNewViewType", "true");
      localStorage.setItem("DuplicateActiveViewInNewViewTypeResult", "null");

      let processing = true;
      window.revit.duplicateActiveViewInNewViewType(typeName, viewName);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("DuplicateActiveViewInNewViewType") as string
        );
      }
      // alert("one");
      return JSON.parse(
        localStorage.getItem("DuplicateActiveViewInNewViewTypeResult") as string
      );
    }
  }

  async createElementTypeWithCustomParametersForWeberIndonesia(
    elementData: CustomParameter[],
    url: string
  ): Promise<string> {
    if (!window.revit) {
      await wait(200);
      return "";
    } else {
      localStorage.setItem(
        "CreateElementTypeWithCustomParametersForWeberIndonesia",
        "true"
      );
      localStorage.setItem(
        "CreateElementTypeWithCustomParametersForWeberIndonesiaResult",
        "null"
      );
      let processing = JSON.parse(
        localStorage.getItem(
          "CreateElementTypeWithCustomParametersForWeberIndonesia"
        ) as string
      );
      console.log(
        "CreateElementTypeWithCustomParametersForWeberIndonesia :",
        elementData
      );
      let token: string = localStorage.getItem("token") as string;
      window.revit.createElementTypeWithCustomParametersForWeberIndonesia(
        JSON.stringify(elementData),
        url,
        token
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem(
            "CreateElementTypeWithCustomParametersForWeberIndonesia"
          ) as string
        );
      }

      let result = localStorage.getItem(
        "CreateElementTypeWithCustomParametersForWeberIndonesiaResult"
      ) as string;

      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      result = escapeSpecialChars(result);
      result = result.replace(/\r?\n|\r/g, "");
      result = result.replace(/\s+/g, " ").trim();
      return result; //JSON.parse(result);
    }
  }

  getWallsDataByLevel = async (levelsId: ID[]): Promise<RevitMappedWall[]> => {
    localStorage.setItem("getWallsData", "true");
    localStorage.setItem("getWallsDataResult", "null");
    localStorage.setItem("isErrorThrown", "False");

    let processing: boolean = true;
    let response = "";
    window.revitCallBack = async (responseBack: any, status: string) => {
      response = responseBack;
      localStorage.setItem("getWallsData", "false");
    };
    window.revit.getAllWallsDataByLevel(JSON.stringify(levelsId));

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("getWallsData") as string);
    }

    response = response.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );

    response = response.replace(/\\/g, "\\");
    response = escapeSpecialChars(response);
    response = response.replace(/(:\s*"[^"]+:\s*)"([^"]+)"/gm, "$1$2");
    if (this.isJson(response)) {
      return JSON.parse(response);
    } else {
      return [];
    }
  };

  getRoomsWithWalls = async (ceilingWalls: string): Promise<any> => {
    localStorage.setItem("roomsWithWalls", "true");
    localStorage.setItem("roomsWithWallsResult", "null");

    let processing: boolean = true;
    window.revit.getRoomsWithWalls(ceilingWalls);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("roomsWithWalls") as string);
    }
    let response = localStorage.getItem("roomsWithWallsResult") as string;

    response = response.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );

    response = response.replace(/\\/g, "\\");
    response = escapeSpecialChars(response);
    response = response.replace(/(:\s*"[^"]+:\s*)"([^"]+)"/gm, "$1$2");
    return JSON.parse(response);
  };

  async deleteSchedule(scheduleName: string): Promise<string> {
    localStorage.setItem("deleteSchedule", "true");

    let processing = true;
    window.revit.deleteSchedule(scheduleName);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("deleteSchedule") as string);
    }

    return "success";
  }

  async getViewsByType(type: string): Promise<ID> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("getViewsByType", "true");
      localStorage.setItem("getViewsByTypeResult", "null");

      let processing: boolean = true;
      window.revit.getViewsByType(type);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getViewsByType") as string
        );
      }
      return JSON.parse(localStorage.getItem("getViewsByTypeResult") as string);
    }
  }

  async copyPhotoboxFile(
    fileToReplace: string = ""
  ): Promise<{ FileName: string; ScreenName: string; Error: string }> {
    localStorage.setItem("photoboxFile", "true");
    localStorage.setItem("photoboxFileResult", "null");

    let processing: boolean = true;
    window.revit.copyPhotoBoxFile(fileToReplace);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("photoboxFile") as string);
    }
    const response = JSON.parse(
      localStorage.getItem("photoboxFileResult") as string
    );
    return response;
  }

  async removePhotoboxFile(files: string): Promise<boolean> {
    localStorage.setItem("removePhotoboxFile", "true");

    let processing: boolean = true;
    window.revit.removePhotoBoxFile(files);

    while (processing) {
      await wait(50);

      processing = JSON.parse(
        localStorage.getItem("removePhotoboxFile") as string
      );
    }

    return true;
  }

  async UploadPhotoboxFile(
    files: string,
    token: string,
    baseURL: string
  ): Promise<string> {
    localStorage.setItem("uploadPhotoboxFile", "true");
    localStorage.setItem("uploadPhotoboxFileResult", "null");

    let processing: boolean = true;
    window.revit.uploadPhotoBoxFile(files, token, baseURL);

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("uploadPhotoboxFile") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("uploadPhotoboxFileResult") as string
    );
  }

  async CancelUploadPhotoboxFile() {
    let processing: boolean = true;
    localStorage.setItem("uploadPhotoboxFileCancel", "true");
    window.revit.cancelUploadPhotoboxFile();
    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("uploadPhotoboxFileCancel") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("uploadPhotoboxFileCancel") as string
    );
  }

  async getHelloWord(
    text: string,
    filePath: string,
    baseurl: string,
    token?: string
  ): Promise<any> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("myvar1", "true");
      localStorage.setItem("myvar2", "null");
      localStorage.setItem("generateObjectsResult", "");

      let processing: boolean = true;
      window.revit.getHelloWord(text, baseurl, token, filePath);

      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("myvar1") as string);
      }

      let result = localStorage.getItem("myvar2");
      // return JSON.parse(localStorage.getItem("myvar2") as string);
      return result;
    }
  }

  async generateObjects(solution: string): Promise<any> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("generateObjects", "true");
      localStorage.setItem("generateObjectsResult", "null");

      let processing: boolean = true;
      window.revit.generateObjects(solution);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("generateObjects") as string
        );
      }
      return JSON.parse(
        localStorage.getItem("generateObjectsResult") as string
      );
    }
  }

  async transferProjectStandards(url: string, fileName: string): Promise<any> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("transferProjectStandards", "true");
      localStorage.setItem("transferProjectStandardsResult", "null");
      let token: string = localStorage.getItem("token") as string;
      let processing: boolean = true;
      window.revit.transferProjectStandards(url, token, fileName);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("transferProjectStandards") as string
        );
      }

      return JSON.parse(
        localStorage.getItem("transferProjectStandardsResult") as string
      );
    }
  }

  async abortGenerationModGen(): Promise<any> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("generationabortstate", "");

      let processing: boolean = true;
      window.revit.abortGenerationModGen();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("generationabortstate") as string
        );
      }
      let result = localStorage.getItem("generationabortstate");
      return result;
    }
  }

  async deletePartsWalls(
    Ids: string[],
    deleteFraming: string = "true",
    deleteDryWall: string = "true"
  ): Promise<any> {
    localStorage.setItem("drawElements", "true");
    localStorage.setItem("drawElementsResult", "");
    let processing = JSON.parse(localStorage.getItem("drawElements") as string);
    window.revit.deleteParts(
      JSON.stringify({ data: Ids }),
      deleteFraming,
      deleteDryWall
    );
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("drawElements") as string);
    }
    let result = JSON.parse(
      localStorage.getItem("drawElementsResult") as string
    );
    return result;
  }

  async getPartsIds(wallIds: any) {
    if (!window.revit) {
      return;
    }
    localStorage.setItem("getPartsIds", "true");
    localStorage.setItem("getPartsIdsResult", "null");
    let processing = true;
    window.revit.getPartsIds(JSON.stringify(wallIds));

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("getPartsIds") as string);
    }

    return JSON.parse(localStorage.getItem("getPartsIdsResult") as string);
  }
}
