import * as React from "react";
import { createContext, useState, useEffect } from "react";
import { MyConfig } from "../../../../Helper";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { find, map, filter } from "lodash";
import { api } from "../../../../RevitJS/API";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";

export interface IQuantityContextType {
  selections: any;
  config: any;
  placoProducts: any;
  selectSelection: string[];
  setSelectedSelection: React.Dispatch<any>;
  processWalls: any[];
  setProcessWalls: React.Dispatch<any>;
  unitConversionArray: any[];
  setUnitConversionArray: any;
  getUnitConversionArray: any;
  sourceChoice: string;
  selectionType: string;
  elementType: string;
  setSourceChoice: any;
  setSelectionType: any;
  setElementType: any;
  processCeilings: any[];
  setProcessCeilings: React.Dispatch<any>;
}

export const QuantityContext = createContext<IQuantityContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const QuantityProvider: React.FC<Props> = ({ children }) => {
  const [selections, setSelection] = useState<any>(null);
  const [config, setConfig] = useState<any>(null);
  const [placoProducts, setPlacoProducts] = useState<any>(null);
  const [selectSelection, setSelectedSelection] = useState<string[]>([]);
  const [processWalls, setProcessWalls] = useState<any[]>([]);
  const [unitConversionArray, setUnitArray] = useState<any[]>([]);
  const [sourceChoice, setSourceChoice] = useState<string>("");
  const [selectionType, setSelectionType] = useState<string>("floor");
  const [elementType, setElementType] = useState<string>("wall");
  const [processCeilings, setProcessCeilings] = useState<any[]>([]);

  useEffect(() => {
    async function initialLoader() {
      const cng = await MyConfig();
      setConfig(cng);
      let projectData: initProjectData = await api.queries.getSetProjectData();
      if (projectData.ProjectId !== "") {
        const placoProductsList = await bimStorage.getItem(
          storageKey.PLACO_PRODUCTS
        );
        let savedSelections = await bimStorage.getItem(
          storageKey.PLACOSELECTIONS
        );
        const filteredSelection = filter(
          savedSelections,
          function (selection: any) {
            return selection.RevitView === projectData.ProjectId;
          }
        );

        setPlacoProducts(placoProductsList);
        setSelection(filteredSelection);
      }
    }

    initialLoader();
  }, []);

  const setUnitConversionArray = (array: any, element: any) => {
    setUnitArray((prevArray: any[]) => {
      map(array, (unitObj: any, unitObjIndex: any) => {
        if (
          !find(prevArray, {
            alternativeUnit: unitObj.alternativeUnit,
            ub: unitObj.ub,
            element: element,
          })
        ) {
          prevArray = [...prevArray, { ...unitObj, element: element }];
        }
      });
      return prevArray;
    });
  };

  const getUnitConversionArray = () => {
    return unitConversionArray;
  };

  return (
    <QuantityContext.Provider
      value={{
        selections,
        config,
        placoProducts,
        selectSelection,
        setSelectedSelection,
        processWalls,
        setProcessWalls,
        unitConversionArray,
        setUnitConversionArray,
        getUnitConversionArray,
        sourceChoice,
        selectionType,
        elementType,
        setSourceChoice,
        setSelectionType,
        setElementType,
        processCeilings,
        setProcessCeilings,
      }}
    >
      {children}
    </QuantityContext.Provider>
  );
};

export default QuantityProvider;
