import { SET_RESET_PRODUCTS_LISTS, ProductActionType, ProductType, SET_PIM_PRODUCTS_LISTS, SET_PRODUCTS_LISTS, SET_PRODUCTS_FILTERS } from "../Types";


const intialState: ProductType = {
    selectedProductList: [],
    excelproductList: [],
    pimproductList: [],
    productList:[],
    productFilters:[],
};


const productReducer = (state: ProductType = intialState, action: ProductActionType): ProductType => {
    const newState = { ...state };
    switch (action.type) {
        case SET_RESET_PRODUCTS_LISTS:
            console.log("SET_RESET_PRODUCTS_LISTS");
            return {
                ...state,
                selectedProductList: [],
                excelproductList: [],
                pimproductList: [],
                productList: [],
                productFilters: [],
            };
        case SET_PIM_PRODUCTS_LISTS:
            console.log("SET_PIM_PRODUCTS_LISTS");
            return {
                ...state,
                pimproductList: action.pimproductList === undefined ? null : action.pimproductList,
            };
        case SET_PRODUCTS_LISTS:
            console.log("SET_PRODUCTS_LISTS");
            return {
                ...state,
                productList: action.productList === undefined ? null : action.productList,
            };
        case SET_PRODUCTS_FILTERS:
            console.log("SET_PRODUCTS_FILTERS");
            return {
                ...state,
                productFilters: action.productFilters === undefined ? null : action.productFilters,
            };
        default:
            return state;
    }
    return newState;
}

export default productReducer;