import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "./FunctionalityHeader";
import {
  Grid,
  Segment,
  Button,
  Dimmer,
  Loader,
  Menu,
  Message,
} from "semantic-ui-react";
import { SelectionStore } from "../Reducers";
import { connect, ConnectedProps } from "react-redux";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import _ from "lodash";
import GroupSelectorBody from "./GroupSelectorBody";
import {
  setSelectorType,
  initMappingTable,
  toggleBufferUpdate,
} from "../Actions";
import { SavePopup } from "./SavePopup";
import { selectorTp } from "../Actions/types";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { Oval } from "react-loader-spinner";

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  let typesData: TypeData[] = [];
  typesData = typesData.concat(state.wallTypesData, state.ceilingTypesData);
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    selectorType: state.selectorType,
    bufferSelection: state.bufferSelection,
    module: ownProps.module,
    typesData: typesData,
    wallSelectedTypes: state.wallSelectedTypes,
    ceilingSelectedTypes: state.ceilingSelectedTypes,
    selections: state.selections,
    projectId: state.projectData.ProjectId,
    config: state.config,
  };
};

const mapDispatch = {
  setSelectorType,
  initMappingTable,
  toggleBufferUpdate,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  Icon: string;
  language: string;
  wording: {
    wallTypes: { [key: string]: string };
    ceilingTypes: { [key: string]: string };
    selection: { [key: string]: string };
    levels: { [key: string]: string };
    back: { [key: string]: string };
    validate: { [key: string]: string };
    stepOne: { [key: string]: string };
    partitionWalls: { [key: string]: string };
    ceilings: { [key: string]: string };
  };
  levelsData: LevelData[];
  typesData: TypeData[];
  routes: any;
  selectorType: selectorTp;
  setRoute: any;
  bufferSelection: any;
  module: string;
  setSelectorType: any;
  initMappingTable: any;
  toggleBufferUpdate: any;
  wallSelectedTypes: string[];
  ceilingSelectedTypes: string[];
  selections: any;
  projectId: string;
  config: any;
};

export class GroupSelector extends React.Component<Props> {
  state = {
    savepopup: false,
    allCeilingsWallsUsed: false,
    validateLoading: false,
  };

  componentDidMount = () => {
    // list of all walls and ceilings
    // list of all selections
    //get ids of all the walls and ceilings
    const allIds: string[] = _.flatten(
      this.props.typesData.reduce((a: any, e: any) => {
        if (e) {
          a.push(e.Ids);
        }
        return a;
      }, [])
    );

    //get ids of all the walls and ceilings ids already used by other zone
    const usedIds: any = Object.values(this.props.selections).reduce(
      (a: any, e: any) => {
        if (e.RevitView === this.props.projectId && e.Zone) {
          e.SelectionByType.ceiling.Rows.forEach((wall: any) => {
            a.push(wall.RevitSelection.Ids);
          });
          e.SelectionByType.wall.Rows.forEach((wall: any) => {
            a.push(wall.RevitSelection.Ids);
          });
        }
        return a;
      },
      []
    );

    // check if current selection is zone
    if (this.props.bufferSelection.Zone) {
      //check if difference between all ids and usedIds are 0
      if (_.difference(allIds, _.flatten(usedIds)).length === 0) {
        this.setState({
          allCeilingsWallsUsed: true,
        });
      }
    }

    // all A entries are into B

    // check if all the walls and ceilings are already used by other zone

    bimStorage.setItem(
      storageKey.CURR_SELECTION_TYPE,
      JSON.stringify({ selectionType: "Group" })
    );
    // api.windowsHandler.resizeWindow(1250, 700);
  };

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
      },
      ceilingTypes: {
        French: " de types plafonds",
        English: "Ceiling types",
      },
      wallTypes: {
        French: "de types murs",
        English: "Wall types",
      },
      selection: {
        French: "Sélection",
        English: "Selection",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
      stepOne: {
        French: "Etape 1/2 : Sélection des types de systèmes",
        English: "Step 1/2: Type system selection",
      },
      partitionWalls: {
        French: "Murs",
        English: "Partition Walls",
      },
      ceilings: {
        French: "Plafonds",
        English: "Ceilings",
      },
      yes: {
        French: "Oui",
        English: "Yes",
      },
      no: {
        French: "Non",
        English: "No",
      },
      message: {
        French:
          "Voulez-vous enregistrer la sélection en cours de modification avant de quitter ?",
        English: "Do you want to save the selection before exiting this menu?",
      },
    },
  };

  backHandler = () => {
    this.props.setRoute(Routes.ROOT);
  };

  validateHandler = () => {
    this.props.initMappingTable();

    if (
      this.props.ceilingSelectedTypes &&
      this.props.ceilingSelectedTypes.length > 0 &&
      this.props.wallSelectedTypes &&
      this.props.wallSelectedTypes.length > 0
    ) {
      this.props.setSelectorType("wall");
    } else if (
      this.props.selectorType === "wall" &&
      this.props.wallSelectedTypes &&
      this.props.wallSelectedTypes.length > 0
    ) {
      this.props.setSelectorType("wall");
    } else if (
      this.props.selectorType === "ceiling" &&
      this.props.ceilingSelectedTypes &&
      this.props.ceilingSelectedTypes.length > 0
    ) {
      this.props.setSelectorType("ceiling");
    }

    this.props.setRoute(Routes.MAPPING);
  };

  headerNameDisplay = (module: string, selectionname: string): string => {
    if (module.toLowerCase() !== "selection") {
      return module + " > " + selectionname;
    }
    return selectionname;
  };

  toggleModal = (modalName: string, action: boolean) => {
    const isOneChecked = _.filter(this.props.typesData, { Checked: true });
    if (isOneChecked.length > 0) {
      this.setState({ [modalName]: action });
    } else {
      const bufferSelection = this.props.bufferSelection;
      const wallCount = bufferSelection.SelectionByType.wall.Rows.length;
      const ceilingCount = bufferSelection.SelectionByType.ceiling.Rows.length;
      const othersCount = bufferSelection.SelectionByType.others.Rows.length;

      if (wallCount > 0 || ceilingCount > 0 || othersCount > 0) {
        this.props.setRoute(Routes.MAPPING);
      } else {
        this.props.setRoute(Routes.ROOT);
      }
    }
  };

  selectorTypes = () => {
    switch (this.props.selectorType) {
      case "wall":
        return this.props.wording.wallTypes[this.props.language];
      case "ceiling":
        return this.props.wording.ceilingTypes[this.props.language];
      default:
        return this.props.wording.wallTypes[this.props.language];
    }
  };

  render() {
    const {
      wording: { back, stepOne, partitionWalls, ceilings, validate },
      language,
      Icon,
      selectorType,
      setSelectorType,
      bufferSelection,
      module,
      levelsData,
      typesData,
    } = this.props;

    const isOneChecked = _.filter(typesData, { Checked: true });

    // if(isOneChecked.length)
    // {
    //   throw new Error('I crashed!');
    // }
    if (this.state.allCeilingsWallsUsed) {
      return (
        <div style={{ textAlign: "center", padding: "0 3rem" }}>
          <FunctionalityHeader
            Icon={Icon}
            name={this.headerNameDisplay(module, bufferSelection.Name)}
          />
          <br />
          <br />
          <br />
          <br />
          <Message warning size="big">
            Une nouvelle zone ne peut pas être créée car tous les systèmes (murs
            et plafonds) sont déjà inclus dans d’autres zones
          </Message>

          <Button onClick={() => this.backHandler()} color="orange">
            {back[language]}
          </Button>
        </div>
      );
    }
    return (
      <div style={{ height: "100%" }}>
        <SavePopup
          savepopup={this.state.savepopup}
          toggleloader={() => this.toggleModal("savepopup", true)}
          closeDimmer={() => this.toggleModal("savepopup", false)}
          validateSelection={() => this.validateHandler()}
          yes={this.props.wording.yes[this.props.language]}
          no={this.props.wording.no[this.props.language]}
          message={this.props.wording.message[this.props.language]}
          setRoute={this.props.setRoute}
          isBufferSelectionUpdate={bufferSelection?.Update}
        />
        <FunctionalityHeader
          Icon={Icon}
          name={this.headerNameDisplay(module, bufferSelection.Name)}
        />

        <Segment basic style={{ width: "100%" }}>
          <div
            style={{
              padding: 5,
              height: 25,
              backgroundColor: "rgb(14,110,184)",
              color: "white",
              display: "flex",
              alignItems: "center",
              borderBottom: "0.5px solid white",
            }}
          >
            <div>{stepOne[language]}</div>
          </div>

          <Menu widths={2} style={{ marginTop: "5px", marginBottom: "0px" }}>
            <Menu.Item
              name={partitionWalls[language]}
              active={selectorType === "wall"}
              onClick={() => setSelectorType("wall")}
              className={`${
                selectorType === "wall" ? "menuItemActive" : "menuItemNotActive"
              }`}
            />
            <Menu.Item
              name={ceilings[language]}
              active={selectorType === "ceiling"}
              onClick={() => setSelectorType("ceiling")}
              className={`${
                selectorType === "ceiling"
                  ? "menuItemActive"
                  : "menuItemNotActive"
              }`}
            />
          </Menu>

          <Segment
            basic
            style={{
              border: "1px solid #d6d6d6",
              marginBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <GroupSelectorBody selectorType={selectorType} />
          </Segment>
        </Segment>
        <Segment
          basic
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: 30,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Button
            onClick={() => this.toggleModal("savepopup", true)}
            color="orange"
          >
            {back[language]}
          </Button>
          <Button
            onClick={this.validateHandler}
            disabled={isOneChecked.length > 0 ? false : true}
            primary
          >
            {validate[language]}
            {this.state.validateLoading === true ? (
              <div
                style={{
                  float: "right",
                  marginLeft: "5px",
                }}
              >
                <Oval
                  color="#00BFFF" //3 secs
                  height={16}
                  width={16}
                />{" "}
              </div>
            ) : (
              <div></div>
            )}
          </Button>
        </Segment>
      </div>
    );
  }
}

export default connector(GroupSelector);
