import React from "react";
import { Container, Header, Image } from "semantic-ui-react";
import "../Resources/FunctionalityHeaderPlaco.css";

interface FunctionalityHeaderProps {
  name: string;
  Icon: string;
  subheader: string;
}

export class FunctionalityHeader extends React.Component<FunctionalityHeaderProps> {


  render() {
    return (
      <Container textAlign="center" style={{ marginBottom: 0, width: "100%" }}>
        <Header
          color="blue"
          style={{ paddingTop: "0", paddingBottom: 0 }}
          as="h3"
          content={this.props.name}
          image={this.props.Icon}
          subheader={this.props.subheader}
        />
      </Container>
    );
  }
}
