import React from "react";
import { IWording } from "../../../../Types";

const wording: IWording = {
  selectionName: {
    French: "Nom de la sélection",
    English: "Configuration Name",
  },
  editDate: {
    French: "Date de modification",
    English: "Modification Date",
  },
};

const style = {
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "60%",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    fontWeight: 700,
    width: "30%",
    color: "black",
  },
};

export const HomeTableHeader = () => {
  const applicationLanguage = "English";
  return (
    <div style={style.tableSegment_Header}>
      <div style={style.header_secondary_row}>
        <div style={style.header_column_1}>
          {wording.selectionName[applicationLanguage]}
        </div>
        <div style={style.header_column_4}>
          {wording.editDate[applicationLanguage]}
        </div>
        <div style={{ width: "10%" }}></div>
      </div>
    </div>
  );
};
export default HomeTableHeader;
