import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import DimensionsRoot from "./Components/root";

export const functionalityId = "cfd6750c-0aeb-46b4-b526-7fa0995c5b33";

export const dimensionsTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    500,
    170,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="dimensions"
    >
      <DimensionsRoot />
    </ParentRoot>
  );
};

export const GyprocDimensionsRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="dimensions"
    >
      <DimensionsRoot />
    </ParentRoot>
  );
};

export const Dimensions: Functionality = {
  Name: {
    French: "Dimensions",
    English: "Dimensions",
    German: "Maße",
    Italian: "Dimensioni",
    Indonesian: "Ukuran",
  },
  Trigger: dimensionsTrigger,
  ShortDesc: {
    French: "Dimensions du cadre",
    English: "Frame dimensions",
    German: "Rahmenabmessungen",
    Italian: "Dimensioni del telaio",
    Indonesian: "Dimensi bingkai",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Cotation.png",
  RootComponent,
  ParentId: "0",
};
