import {
  getLayoutAttributesAid,
  getPlacoSystemDetailsWithChildren,
} from "../Requests/index";
import _ from "lodash";
import { api } from "../../../../RevitJS/API";

export const parseSystem = (
  systemAndChildren: any,
  attributesId: any,
  Reverse = false,
  Flipped = false
) => {
  let system = systemAndChildren.find((obj: any) =>
    obj.types.includes("Solution Product")
  ).attributes;
  let products = systemAndChildren.filter((obj: any) =>
    obj.types.includes("Product")
  );

  let framesSpace =
    system.find((e: any) => e.aid === attributesId.framesSpace) &&
    system.find((e: any) => e.aid === attributesId.framesSpace).values[0]
      .numericValue;

  let systemName =
    system.find((e: any) => e.aid === attributesId.systemName) &&
    system.find((e: any) => e.aid === attributesId.systemName).values[0].value;

  let layers = system.filter((e: any) => e.aid === attributesId.layers)
    ? system.filter((e: any) => e.aid === attributesId.layers)
    : [];
  layers.sort(function (a: any, b: any) {
    let a_number = a.subAttributes.find(
      (e: any) => e.aid === attributesId.layerNumber
    ).values[0].numericValue;
    let b_number = b.subAttributes.find(
      (e: any) => e.aid === attributesId.layerNumber
    ).values[0].numericValue;
    return a_number - b_number;
  });
  let sgRails = "None";

  if (attributesId.rails) {
    for (let i = 0; i < attributesId.rails.length; i++) {
      let attribute = system.find((e: any) => e.aid === attributesId.rails[i]);
      if (attribute) {
        let name = attribute.values[0].value;
        if (name) {
          if (name.startsWith("Rail") || name.startsWith("Cornière")) {
            sgRails = name;
          }
        }
      }
    }
  }

  let sgFrames = "None";
  if (attributesId.frames) {
    for (let i = 0; i < attributesId.frames.length; i++) {
      let attribute = system.find((e: any) => e.aid === attributesId.frames[i]);
      if (attribute) {
        let name = attribute.values[0].value;
        if (name) {
          if (name.startsWith("Montant")) {
            sgFrames = name;
          }
        }
      }
    }
  }

  let systemElems = attributesId && parseLayers(layers, attributesId);
  let plateName =
    systemElems.claddingB[0].I1_Name !== "None"
      ? systemElems.claddingB[0].I1_Name
      : systemElems.claddingA[0].E1_Name;

  let plateProduct = products.find(
    (product: any) => product.translation === plateName
  );
  let plateWidth =
    attributesId && plateProduct
      ? plateProduct.attributes.find(
          (e: any) => e.aid === attributesId.plateWidth
        ).values[0].numericValue
      : 0;

  let technicalName = systemAndChildren.find((obj: any) =>
    obj.types.includes("Solution Product")
  ).technicalName;
  let result = {
    systemName,
    framesSpace: framesSpace * 1000,
    plateWidth: plateWidth * 1000,
    E1: systemElems.claddingA[0].E1,
    E2: systemElems.claddingA[1].E2,
    E3: systemElems.claddingA[2].E3,
    I1: systemElems.claddingB[0].I1,
    I2: systemElems.claddingB[1].I2,
    I3: systemElems.claddingB[2].I3,
    E1_Name: systemElems.claddingA[0].E1_Name,
    E2_Name: systemElems.claddingA[1].E2_Name,
    E3_Name: systemElems.claddingA[2].E3_Name,
    I1_Name: systemElems.claddingB[0].I1_Name,
    I2_Name: systemElems.claddingB[1].I2_Name,
    I3_Name: systemElems.claddingB[2].I3_Name,
    E1_Color: systemElems.claddingA[0].E1_Color,
    E2_Color: systemElems.claddingA[1].E2_Color,
    E3_Color: systemElems.claddingA[2].E3_Color,
    I1_Color: systemElems.claddingB[0].I1_Color,
    I2_Color: systemElems.claddingB[1].I2_Color,
    I3_Color: systemElems.claddingB[2].I3_Color,
    Reverse,
    Flipped,
    Lining: systemName && systemName.includes("Doublage") ? true : false,
    railsSpace: 0,
    staggering: plateWidth * 1000 > 900 ? true : false,
    doubleFraming: system.find((e: any) => e.aid === attributesId.doubleFraming)
      ? system
          .find((e: any) => e.aid === attributesId.doubleFraming)
          .values[0].value.toLowerCase() === "simple"
        ? false
        : true
      : false,
    doubleAmount: false,
    Frame1_Name: sgFrames,
    Rail1_Name: sgRails,
    SAA: systemName && systemName.includes("SAA") ? true : false,
    SAD: systemName && systemName.includes("SAD") ? true : false,
    InputType: "",
    FreeEnd: "",
    technicalName: technicalName,
  };

  if (result.E1 === "None") {
    let swapper;

    swapper = result.I1;
    result.I1 = result.E1;
    result.E1 = swapper;

    swapper = result.I1_Name;
    result.I1_Name = result.E1_Name;
    result.E1_Name = swapper;

    swapper = result.I2;
    result.I2 = result.E2;
    result.E2 = swapper;

    swapper = result.I2_Name;
    result.I2_Name = result.E2_Name;
    result.E2_Name = swapper;

    swapper = result.I3;
    result.I3 = result.E3;
    result.E3 = swapper;

    swapper = result.I3_Name;
    result.I3_Name = result.E3_Name;
    result.E3_Name = swapper;

    swapper = result.I1_Color;
    // result.I1_Color = result.E1_Color;
    result.E1_Color = swapper;

    swapper = result.I2_Color;
    // result.I2_Color = result.E2_Color;
    result.E2_Color = swapper;

    swapper = result.I3_Color;
    // result.I3_Color = result.E3_Color;
    result.E3_Color = swapper;
  }

  return result;
};

export const parseLayers = (layers: any, attributesId: any) => {
  let layers_nb = layers.length;
  let firstCladding = true;
  let claddingA = [],
    claddingB = [],
    rails: any = [],
    frames: any = [];
  for (let j = 0; j < layers_nb; j++) {
    let layerAttributes = layers[j].subAttributes;
    let layerContent = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerContent
    ).values[0].value;
    let layerThickness = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerThickness
    ).values[0].numericValue;
    let layerName = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerName
    ).values[0].value;
    if (layerContent === "Plaque de plâtre") {
      let layerColor = layerAttributes.find(
        (e: any) => e.aid === attributesId.layerColor
      ).values[0].value;

      if (firstCladding) {
        claddingA.push(
          makeLayer("E", j + 1, layerThickness, layerName, layerColor)
        );
      } else {
        claddingB.push(
          makeLayer("I", layers_nb - j, layerThickness, layerName, layerColor)
        );
      }
    } else {
      firstCladding = false;
    }
  }
  claddingB.reverse();
  return {
    claddingA: addDefaultLayer(claddingA, "E"),
    claddingB: addDefaultLayer(claddingB, "I"),
    rails,
    frames,
  };
};

export const makeLayer = (
  side: any,
  index: any,
  layerThickness: any,
  layerName: any,
  layerColor: any
) => {
  let layer: any = {};
  layer[side + index.toString()] = layerThickness;
  layer[side + index.toString() + "_Name"] = layerName;
  layer[side + index.toString() + "_Color"] = layerColor;
  return layer;
};

export const addDefaultLayer = (cladding: any, side: any) => {
  if (cladding.length >= 3) return cladding;
  let nb_layers_to_add = 3 - cladding.length;
  cladding = [...cladding];
  for (let i = 0; i < nb_layers_to_add; i++) {
    cladding.push(makeDefaultLayer(side, 3 - nb_layers_to_add + i + 1));
  }
  return cladding;
};

export const makeDefaultLayer = (side: any, index: any) => {
  let layer: any = {};
  layer[side + index] = "None";
  layer[side + index + "_Name"] = "None";
  return layer;
};

export const getSystemData = async (
  systemIDs: any,
  config: any,
  Reverse: boolean = false,
  Flipped: boolean = false
) => {
  let systemData = [];
  let attributesId;
  let nb = systemIDs.length;
  for (let i = 0; i < nb; i++) {
    let system = await getPlacoSystemDetailsWithChildren(
      systemIDs[i],
      config
    ).then(function (response: any) {
      return response.data;
    });

    if (i === 0) {
      attributesId = getLayoutAttributesAid(
        system.context.attributeDependencies[0].attributes
      );
    }

    systemData.push(
      parseSystem(system.objects, attributesId, Reverse, Flipped)
    );
  }

  return systemData;
};

export const drawWall =
  (setGroupNumber: any, increment: any) => async (layoutData: any) => {
    let groups = layoutData.data;
    let groupsNb = groups.length;
    setGroupNumber && setGroupNumber(groupsNb);
    // alert(groupsNb);
    for (let g = 0; g < groupsNb; g++) {
      let wallNb = groups[g].length;
      // alert(wallNb);
      for (let i = 0; i < wallNb; i++) {
        try {
          await api.familyEditor.setParams([
            {
              Id: groups[g][i].id,
              Params: [{ Name: "Processed", Type: "YesNo", Value: 1 }],
            },
          ]);
          await api.familyEditor.setParams([
            {
              Id: groups[g][i].id,
              Params: [
                {
                  Name: "SG_System",
                  Type: "Text",
                  Value: groups[g][i].technicalName,
                },
              ],
            },
          ]);

          if (groups[g][i].rails.length)
            await api.familyEditor.placeFamiliesAtPoints(groups[g][i].rails);
          if (groups[g][i].flipped_rails.length)
            await api.familyEditor.placeFamiliesAtPoints(
              groups[g][i].flipped_rails
            );
          if (groups[g][i].frames.length) {
            let simpleFrames = groups[g][i].frames.filter(
              (frame: any) => frame.FamilyName === "Placo_Ossature"
            );
            let doubleFrames = groups[g][i].frames.filter(
              (frame: any) => frame.FamilyName === "Placo_Ossature-Double"
            );
            if (simpleFrames.length) {
              await api.familyEditor.placeFamiliesAtPoints(simpleFrames);
            }
            if (doubleFrames.length) {
              await api.familyEditor.placeFamiliesAtPoints(doubleFrames);
            }
          }

          groups[g][i].plasterboards.length &&
            (await api.familyEditor.placeFamiliesAtPoints(
              groups[g][i].plasterboards
            ));
        } catch (ex) {}
      }
      // alert(g);
      increment && increment();
    }
    // increment();
  };

export const extractPlasterboardQuantity = async (wallIds: string[]) => {
  let criteria: {
    Param: { Name: string; Type: string; Value: string | number | number[] };
    Rule: string;
  }[] = [
    {
      Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
      Rule: "Equals",
    },
    {
      Param: {
        Name: "id",
        Type: "Integer",
        Value: _.map(wallIds, _.parseInt),
      },
      Rule: "Includes",
    },
  ];

  let plasterboardIds: string[] = await api.queries.filterElements(
    "Generic",
    criteria,
    null
  );

  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );
  let data: any = [];
  for (let board of plasterboardDetails) {
    let params: any = [];
    params = board.Params;
    let id: string = "",
      height: number = 0,
      length: number = 0,
      width: number = 0,
      name: string = "";
    for (let par of params) {
      switch (par.Name) {
        case "id":
          id = par.Value;
          break;
        case "PlasterboardHeight":
          height = par.Value;
          break;
        case "PlasterboardLength":
          length = par.Value;
          break;
        case "PlasterboardWidth":
          width = par.Value;
          break;
        case "name":
          name = par.Value;
          break;
      }
    }
    data.push({
      Id: board.Id,
      WallId: id,
      Name: name,
      Height: height,
      Length: length,
      Width: width,
      Area: Math.round(length * height * 0.0001) / 100,
    });
  }
  return formatDataBy(data, "WallId");
};

export const extractFramesQuantity = async (wallIds: string[]) => {
  let criteria: {
    Param: { Name: string; Type: string; Value: string | number | number[] };
    Rule: string;
  }[] = [
    {
      Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature" },
      Rule: "Equals",
    },
    {
      Param: {
        Name: "id",
        Type: "Integer",
        Value: _.map(wallIds, _.parseInt),
      },
      Rule: "Includes",
    },
  ];

  const idsSimple = await api.queries.filterElements("Generic", criteria, null);

  criteria = [
    {
      Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature-Double" },
      Rule: "Equals",
    },
    {
      Param: {
        Name: "id",
        Type: "Integer",
        Value: _.map(wallIds, _.parseInt),
      },
      Rule: "Includes",
    },
  ];

  const idsDouble = await api.queries.filterElements("Generic", criteria, null);

  let frameIds = [...idsSimple, ...idsDouble, ...idsDouble];
  const frameDetails = await api.queries.getObjectsParams(frameIds, [
    "id",
    "Longueur",
    "Nom",
  ]);
  let data: any = [];
  for (let board of frameDetails) {
    let params: any = [];
    params = board.Params;
    let id: string = "",
      length: number = 0,
      name: string = "";
    for (let par of params) {
      switch (par.Name) {
        case "id":
          id = par.Value;
          break;
        case "Longueur":
          length = par.Value;
          break;
        case "Nom":
          name = par.Value;
          break;
      }
    }
    data.push({
      WallId: id,
      Id: board.Id,
      Name: name,
      Length: length,
    });
  }
  return formatDataBy(data, "WallId");
};

const formatDataBy = function (arr: any, key: any) {
  return arr.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export async function asyncForEach(array: any, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
