import React from "react";
import {Provider} from "react-redux";
import Allreducers from '../Store/store';
import {createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk"
import App from './app';

const myStore = createStore(Allreducers, applyMiddleware(thunk))

export const TrainingOneRoot = () => {
    return <Provider store={myStore}>
        <App/>
    </Provider>
   
}
 