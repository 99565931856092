import React, { Component, useEffect, useState } from "react";
import { useStore } from "react-redux";
import { api } from "../../../../RevitJS/API";
import AppBody from "./AppBody";

export enum Routes {
  ROOT = "Waiter",
}

export const UpdateRoot = () => {
  const [route, setRoute] = useState(Routes.ROOT);

  useEffect(() => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  });

  return (
    <React.Fragment>
      <AppBody route={route} setRoute={setRoute} />
    </React.Fragment>
  );
};
