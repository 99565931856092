import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { HelpRoot } from "./Components/root";

export const functionalityId = "4b2aab5f-e91f-46b3-99be-fadccf9306fe";

export const helpTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    650,
    500,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <HelpRoot />
    </ParentRoot>
  );
};

export const HelpRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <HelpRoot />
    </ParentRoot>
  );
};

export const Help: Functionality = {
  Name: {
    French: "A propos",
    English: "About",
    German: "A propos",
    Italian: "A propos",
    Indonesian: "A propos",
  },
  Trigger: helpTrigger,
  ShortDesc: {
    French: "L’outil PLACO®BIM",
    English: "The PLACO®BIM tool",
    German: "L’outil PLACO®BIM",
    Italian: "L’outil PLACO®BIM",
    Indonesian: "L’outil PLACO®BIM",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/A Propos.png",
  RootComponent,
  ParentId: "0",
};
