import React, { useState, useEffect } from "react";
import { Grid, Menu, Segment, Button, Dimmer, Loader, Modal } from "semantic-ui-react";
import GroupColumn from "./GroupColumn";
import { api } from "../../../../RevitJS/API";
import { selectedItems, filterProjectTree } from "../Helpers";
import {
  filter,
  cloneDeep,
  map,
  forEach,
  find,
  flattenDeep,
  reduce,
  includes,
  values,
} from "lodash";
import { getLevelElements } from "../../Selection/utils/selection";
import { IWording } from "../../Types";
import { scheduleHandler } from "../Export";
interface Props {
  onBack: () => void;
}

const GroupBox = (props: Props) => {
  const { onBack } = props;
  const [activeItem, setActiveItem] = useState("wallType");
  const [levels, setLevels] = useState<any>(null);
  const [walls, setWalls] = useState<any>([]);
  const [elements, setElements] = useState<any>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedWallTypes,setSelectedWallTypes]=useState<any>([]);
  const [selectedWallThickness,setSelectedWallThickness]=useState<any>([]);
  const [isDownloadSuccessful, setIsDownloadSuccessful] = useState<boolean>(false);

  const callBack = async (response: string, status: string) => {
    if (response) {
      const result = JSON.parse(response);
      const rLevels = map(result, "Levels");
      setLevels(rLevels);
      setElements(result);
    }
  };

  const setProcessingValue = () => {
    setProcessing(false);
  };

  useEffect(() => {
    api.selection.getRevitElements(["wall"], callBack);
  }, []);

  useEffect(() => {
    if (levels && elements) {
      const walls = getLevelElements(levels, elements, activeItem);
      if(activeItem === "wallThickness"){
        forEach(walls, (wall: any) => {
          var elementName = selectedWallThickness.find((item2:any) => item2.ElementName == wall.ElementName);
          if(elementName){
            wall.Checked = true;
          }
        });
      }
      else if(activeItem === "wallType"){
        forEach(walls, (wall: any) => {
          var elementName = selectedWallTypes.find((item2:any) => item2.ElementName == wall.ElementName);
          if(elementName){
            wall.Checked = true;
          }
        });
      }

      setWalls(walls);
    }
  }, [levels, elements, activeItem]);

  const onAllLevelChecked = (items: any, checked: boolean) => {
    let cloneLevels = cloneDeep(levels);
    cloneLevels.forEach((level: any) => {
      level.Checked = checked;
    });
    setLevels(cloneLevels);
  };

  const onLevelChecked = (item: any, checked: boolean) => {
    let cloneLevels = cloneDeep(levels);
    cloneLevels.forEach((level: any) => {
      if (level.Name === item.Name) level.Checked = checked;
    });
    setLevels(cloneLevels);
  };

  const onAllWallChecked = (items: any, checked: boolean) => {
    let cloneWalls = cloneDeep(walls);
    forEach(cloneWalls, (wall: any) => {
      wall.Checked = checked;
    });
    setWalls(cloneWalls);
    setSelectedWallThickness([]);
    setSelectedWallTypes([]);

  };

  const onWallChecked = (item: any, checked: boolean) => {
    let cloneWalls = cloneDeep(walls);
    forEach(cloneWalls, (wall: any) => {
      if (wall.ElementName === item.ElementName) wall.Checked = checked;
    });
    setWalls(cloneWalls);
    setSelectedWallThickness([]);
    setSelectedWallTypes([]);

  };

  const changeSelectionType = (param: string) => {
    let selectedWalls = filter(walls, { Checked: true });
      if(param === "wallThickness"){
        setSelectedWallTypes(selectedWalls);
      }
      else if(param === "wallType"){
        setSelectedWallThickness(selectedWalls);
      }

    setActiveItem(param);
  };

  const onValidateGroup = (elements: any, floors: any) => {
    return { Floors: floors, SelectionDetails: elements };
    // setRoute("MAPPING");
  };

  const onProcessValidate = async () => {
    let tp: any;
    if (activeItem === "wallThickness") {
      const selectedWalls = flattenDeep(
        map(filter(walls, { Checked: true }), "Ids")
      );
      const elementType = flattenDeep(map(elements, "ElementsType"));
      const result1 = reduce(
        selectedWalls,
        function (result: any, value: any, key: any) {
          const wallType = find(elementType, (elem: any) =>
            includes(elem.Ids, value)
          );
          (
            result[wallType.ElementName] ||
            (result[wallType.ElementName] = {
              Checked: true,
              Ids: [],
              ElementName: wallType.ElementName,
            })
          ).Ids.push(value);
          return result;
        },
        {}
      );
      tp = onValidateGroup(values(result1), filter(levels, { Checked: true }));
    } else {
      tp = onValidateGroup(
        filter(walls, { Checked: true }),
        filter(levels, { Checked: true })
      );
      console.log(tp);
    }
    const wallTree = await api.selection.elementsByLevelAndType("wall");
    let selectedTypes: any[] = [];
    tp.SelectionDetails.forEach((e: any) => {
      if (e.Checked) selectedTypes.push(e.ElementName);
    });
    let selectedLevels: any[] = [];
    tp.Floors.forEach((e: any) => {
      if (e.Checked) selectedLevels.push(e.Name);
    });
    console.log(levels);
    console.log(walls);
    // let wallTree = elements;
    const selectedElems = [].concat.apply(
      [],
      selectedItems(wallTree, selectedLevels, selectedTypes).map(
        (e: any) => e.Ids
      )
    );
    let filteredWallTree = filterProjectTree(
      wallTree,
      selectedElems,
      selectedLevels
    );
    setProcessing(true);
    console.log(filteredWallTree);
    await scheduleHandler(filteredWallTree, setIsDownloadSuccessful);
  };

  if (processing) {
    return (
      <div>
        <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
          <Loader content={"Scehdules Pending"}></Loader>
        </Dimmer>
        <Modal
          size="tiny"
          open={isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>Information - Métrés</h3>
            <p>Génération de fichier de métrés terminée avec succès.</p>
            <Button primary onClick={() => {
                setIsDownloadSuccessful(false);
                api.windowsHandler.closeWindow();
              }
            }>
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }

  if (levels && walls) {
    // check all walls are selected
    const allWallsSelected: boolean =
      filter(walls, { Checked: false }).length === 0 ? true : false;

    // check all levels are selected
    const allLevelsSelected: boolean =
      filter(levels, { Checked: false }).length === 0 ? true : false;

    return (
      <>
        <Segment
          basic
          style={{
            border: "1px solid #d6d6d6",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <div style={{ width: "100%", backgroundColor: "none" }}>
            <div>
              <Grid columns={2} stackable style={{ backgroundColor: "none" }}>
                {/* for level column */}
                <GroupColumn
                  data={levels}
                  showHeader={true}
                  headerText={"Levels"}
                  onChecked={onLevelChecked}
                  onAllChecked={onAllLevelChecked}
                  allChecked={allLevelsSelected}
                  disabled={false}
                />
                <Grid.Column>
                  {/* for wall type and thickness */}
                  <Menu
                    widths={2}
                    style={{ marginTop: "5px", marginBottom: "0px" }}
                  >
                    <Menu.Item
                      name="wallType"
                      active={activeItem === "wallType"}
                      onClick={(name) => changeSelectionType("wallType")}
                      className={`${
                        activeItem === "wallType"
                          ? "menuItemActive"
                          : "menuItemNotActive"
                      }`}
                    ></Menu.Item>
                    <Menu.Item
                      name="wallThickness"
                      active={activeItem === "wallThickness"}
                      onClick={(name) => changeSelectionType("wallThickness")}
                      className={`${
                        activeItem === "wallThickness"
                          ? "menuItemActive"
                          : "menuItemNotActive"
                      }`}
                    ></Menu.Item>
                  </Menu>
                  {activeItem === "wallThickness" ? (
                    <GroupColumn
                      data={walls}
                      showHeader={false}
                      onChecked={onWallChecked}
                      onAllChecked={onAllWallChecked}
                      allChecked={allWallsSelected}
                      disabled={
                        filter(levels, { Checked: true }).length > 0
                          ? false
                          : true
                      }
                    />
                  ) : (
                    <GroupColumn
                      data={walls}
                      showHeader={false}
                      onChecked={onWallChecked}
                      onAllChecked={onAllWallChecked}
                      allChecked={allWallsSelected}
                      disabled={
                        filter(levels, { Checked: true }).length > 0
                          ? false
                          : true
                      }
                    />
                  )}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </Segment>
        <Segment
          basic
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: 30,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Button color="orange" onClick={() => onBack()}>
            {"Back"}
          </Button>
          <Button
            primary
            onClick={() => onProcessValidate()}
            disabled={
              filter(walls, { Checked: true }).length > 0 ? false : true
            }
          >
            {"Validate"}
          </Button>
        </Segment>
      </>
    );
  }

  return <></>;
};

export default GroupBox;
