import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TopHeader } from "../../Components/Headers";
import { SubHeader } from "../../Components/Headers";
import { ColorizationTable } from "./Table/Colorization";
import {
  Container,
  Grid,
  Icon,
  Popup,
  Button,
  Segment,
  Confirm,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { filter, find, map } from "lodash";
import { ColorPicker } from "./Colorisation/ColorPicker";

interface Props {
  setDetailRoute: any;
  colorizations: any;
  onColorizationUpdate: any;
}
export const Colorization = (props: Props) => {
  const { setDetailRoute, colorizations, onColorizationUpdate } = props;
  const { register, handleSubmit, watch, setValue, getValues, reset } =
    useForm();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true);
  const [colorForId, setColorForId] = useState<any>(null);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [currentColor, setcurrentColor] = useState<any>({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  const createDefaultValues = () => {
    if (colorizations) {
      let isCheck = false;
      colorizations.forEach((wl: any, index: any) => {
        if (wl.Check) {
          isCheck = true;
        }
      });
      setIsValid(isCheck);
    }
  };

  useEffect(() => {
    createDefaultValues();
  }, [colorizations]);

  useEffect(() => {
    let isCheck = false;
    let allSelected = true;
    watch().colorizations.forEach((cd: any) => {
      if (cd.Check) {
        isCheck = true;
      } else {
        allSelected = false;
      }
    });

    setIsValid(isCheck);
    setIsAllSelected(allSelected);
  }, [watch()]);

  const onSelectionAll = (checked: any) => {
    colorizations.forEach((wl: any, index: any) => {
      if (wl.IsLayout) {
        setValue(`colorizations[${index}].Check`, checked);
      }
    });
  };

  const onColorizationValidate = (data: any) => {
    const finalData: any = map(
      data.colorizations,
      (value: any, index: number) => {
        value.Solution = JSON.parse(value.Solution);
        value.Color = JSON.parse(value.Color);
        value.Ids = JSON.parse(value.Ids);
        return value;
      }
    );
    onColorizationUpdate(finalData);
    setDetailRoute("");
  };

  const onColorPick = (rowId: any, currentColor: any) => {
    setColorForId(rowId);
    setcurrentColor(currentColor);
    setColorPickerOpen(true);
  };

  const opeCloseColorPicker = (st: any) => {
    setColorPickerOpen(st);
  };

  const colorChangeHandler = (color: any) => {
    setValue(`colorizations[${colorForId}].Color`, JSON.stringify(color));
    map(colorizations, (value: any, index: number) => {
      if (colorForId === index) {
        value.Color = color;
      }
      return value;
    });
    onColorizationUpdate(colorizations);
  };

  if (!colorizations) {
    return <></>;
  }

  return (
    <>
      <ColorPicker
        displayColorPicker={colorPickerOpen}
        toggleModal={opeCloseColorPicker}
        currentColor={currentColor}
        colorChangeHandler={colorChangeHandler}
      />
      <form onSubmit={handleSubmit(onColorizationValidate)}>
        <Segment style={{ height: "calc(100vh - 30pxpx)" }}>
          {colorizations.length === 0 ? (
            <div>No data found</div>
          ) : (
            <ColorizationTable
              data={colorizations}
              register={register}
              watch={watch}
              onSelectAll={onSelectionAll}
              onColorPick={onColorPick}
              isAllSelected={isAllSelected}
            />
          )}
        </Segment>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <span>
            <Button
              color="orange"
              onClick={() => setDetailRoute("")}
              content={"Back"}
            ></Button>
          </span>
          <span>
            <Button
              color="blue"
              content={"Validate"}
              type={"submit"}
              disabled={!isValid}
            ></Button>
          </span>
        </div>
      </form>
    </>
  );
};
