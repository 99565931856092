import {
    SET_RESET_CRITERIA,
    SET_SELECTED_SOURCE,
    SetResetCriteriaActionType,
    SetSelectSourceActionType,
    SET_SELECTED_FORMAT,
    SetSelectedFormatActionType,
    SET_SELECTED_VERSION,
    SetSelectedVersionActionType,
    SET_SELECTED_ENVIRONMENT,
    SetSelectedEnvironmentActionType,
    SET_SELECTED_BUSINESS,
    SetSelectBusinessActionType,
    SET_SELECTED_REGION,
    SetSelectedRegionActionType,
    SET_SELECTED_LANGUAGE,
    SET_SELECTED_MAPPING,
    SET_SELECTED_GENERIC,
    SetSelectedLanguageActionType,
    SetSelectMappingActionType,
    SetSelectedGenericActionType,
    SET_SELECTED_BUSINESS_TECH_NAME,
    SET_SELECTED_BUSINESS_CONTAINERID,
    SET_SELECTED_TYPE,
    SetSelectBusinessContainerIdActionType,
    SetSelectBusinessTechnicalNameActionType,
    SetSelectedTypeActionType,
    SET_IS_CRITERIA_VALID,
    SetIsCriteriaValidActionType,
    SET_SELECTED_REGIONName
} from "../Types"

export const SetResetCriteria = (): SetResetCriteriaActionType => {
    return {
        type: SET_RESET_CRITERIA
    }
}

export const SetSelectSource = (data: string): SetSelectSourceActionType => {
    return {
        type: SET_SELECTED_SOURCE,
        selectedSource: data
    }
}

export const SetSelectFormat = (data: string): SetSelectedFormatActionType => {
    return {
        type: SET_SELECTED_FORMAT,
        selectedFormat: data
    }
}

export const SetSelectVersion = (data: string): SetSelectedVersionActionType => {
    return {
        type: SET_SELECTED_VERSION,
        selectedVersion: data
    }
}

export const SetSelectEnvironment = (data: string): SetSelectedEnvironmentActionType => {
    return {
        type: SET_SELECTED_ENVIRONMENT,
        selectedEnvironment: data
    }
}

export const setSelectBusiness = (data: string): SetSelectBusinessActionType => {
    return {
        type: SET_SELECTED_BUSINESS,
        selectedBusiness: data
    }
}

export const SetSelectRegion = (data: string): SetSelectedRegionActionType => {
    return {
        type: SET_SELECTED_REGION,
        selectedRegion: data
    }
}

export const setSelectedRegionName = (data: string) => {
    return {
      type: SET_SELECTED_REGIONName,
      selectedRegionName: data,
    };
  };

export const SetSelectLanguage = (data: string): SetSelectedLanguageActionType => {
    return {
        type: SET_SELECTED_LANGUAGE,
        selectedLanguage: data
    }
}

export const SetSelectMapping = (data: string): SetSelectMappingActionType => {
    return {
        type: SET_SELECTED_MAPPING,
        selectedMapping: data
    }
}

export const SetSelectGeneric = (data: string): SetSelectedGenericActionType => {
    return {
        type: SET_SELECTED_GENERIC,
        selectedGeneric: data
    }
}

export const SetSelectBusinessContainerId = (data: string): SetSelectBusinessContainerIdActionType => {
    return {
        type: SET_SELECTED_BUSINESS_CONTAINERID,
        selectedBusinessContainerId: data
    }
}

export const SetSelectBusinessTechnicalName = (data: string): SetSelectBusinessTechnicalNameActionType => {
    return {
        type: SET_SELECTED_BUSINESS_TECH_NAME,
        selectedBusinessTechnicalName: data
    }
}

export const SetSelectType = (data: string): SetSelectedTypeActionType => {
    return {
        type: SET_SELECTED_TYPE,
        selectedType: data
    }
}

export const SetIsCriteriaValid = (data: any): SetIsCriteriaValidActionType => {
    return {
        type: SET_IS_CRITERIA_VALID,
        isValidCriteria: data
    }
}
