import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { stringify } from "querystring";
import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import "./body.scss";

function Body({
  families,
  selectedFamily,
  setSelectedFamilyCriteria,
  t,
}: IProps) {
  const [marketOptions, setMarketOptions] = useState<any[]>([]);
  const [rangeOptions, setRangeOptions] = useState<any[]>([]);
  const [applicationOptions, setApplicationOptions] = useState<any[]>([]);
  const [junction1Options, setJunction1Options] = useState<any[]>([]);

  const removeDoubles = (
    key: "market" | "application" | "range" | "junction1"
  ): any[] => {
    let tmpNoRepeat: any[] = [];
    families.forEach((family) => {
      let familyObject = tmpNoRepeat.find((tmpFamily) => {
        return tmpFamily === family[key];
      });
      if (!familyObject) {
        tmpNoRepeat.push(family[key]);
      }
    });
    return tmpNoRepeat;
  };

  useEffect(() => {
    setMarketOptions(removeDoubles("market"));
    setRangeOptions(removeDoubles("range"));
    setApplicationOptions(removeDoubles("application"));
    setJunction1Options(removeDoubles("junction1"));
  }, [families]);

  return (
    <>
      <div id="family-component-body-container">
        {/* <Dropdown
                    placeholder="Market"
                    search
                    selection
                    options={marketOptions}
                /> */}
        <Autocomplete
          id="market-combo-box"
          placeholder={t("MARKET") || "Market"}
          options={marketOptions}
          getOptionLabel={(option) => option}
          style={{ width: 300, margin: "auto" }}
          value={selectedFamily.market}
          onChange={(event, value) => {
            setSelectedFamilyCriteria(value, "market");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontWeight: "bold" }}
              label={t("MARKET")}
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          id="application-combo-box"
          options={applicationOptions}
          getOptionLabel={(option) => option}
          style={{ width: 300, margin: "auto" }}
          onChange={(event, value) => {
            setSelectedFamilyCriteria(value, "application");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontWeight: "bold" }}
              label={t("APPLICATION")}
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          id="range-combo-box"
          options={rangeOptions}
          getOptionLabel={(option) => option}
          style={{ width: 300, margin: "auto" }}
          onChange={(event, value) => {
            setSelectedFamilyCriteria(value, "range");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontWeight: "bold" }}
              label={t("RANGE")}
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          id="junction1-combo-box"
          options={junction1Options}
          getOptionLabel={(option) => option}
          style={{ width: 300, margin: "auto" }}
          onChange={(event, value) => {
            setSelectedFamilyCriteria(value, "junction1");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontWeight: "bold" }}
              label={t("JUNCTION_1")}
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
}

type IProps = WithTranslation & {
  families: {
    market: string;
    application: string;
    range: string;
    junction1: string;
    fileNames?: string[];
  }[];
  selectedFamily: {
    market: string | null;
    range: string | null;
    application: string | null;
    junction1: string | null;
  };
  setSelectedFamilyCriteria: Function;
};

export default withTranslation(["pamCanalisation"])(Body);
