import Element from "./Element.model";
import Range, { initializedRange } from "./Range.model";

export default interface Item {
    name: string;
    current: boolean;
    range: any;
    elementsList: any[];
    allCompatibleElements: Element[];
    allCompatibleCouplings?: any[];
    compatibleAndAvailableElements: Element[];
    compatibleAndAvailableElementsSanitized: Element[];
    pipesLength?: number;
    fittingsLength?: number;
    accessoriesLength?: number;
    sortedRecommendation: string[];
    filters: {
        pipes: any;
        fittings: any;
        accessories: any;
    };
}

export const initializedItem: Item = {
    name: "",
    current: false,
    range: initializedRange,
    elementsList: [],
    allCompatibleElements: [],
    compatibleAndAvailableElements: [],
    compatibleAndAvailableElementsSanitized: [],
    allCompatibleCouplings: [],
    sortedRecommendation: [],
    filters: {
        pipes: {},
        fittings: {},
        accessories: {},
    },
};
