import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  Icon,
  Popup,
  Button,
  Checkbox,
} from "semantic-ui-react";
import { LayoutConfigType } from "../utils/types";
import {
  CalpinageContext,
  CaplinageContextType,
} from "../Context/CaplinageContext";

interface Props {
  setDetailRoute: any;
  setRoute: any;
  selectionStatus: boolean;
  calepinageStatus: boolean;
  onCalepinageRun: (layotuConfigure: LayoutConfigType) => void;
  onCalepinageSave: () => void;
}

export const Step = (props: Props) => {
  const {
    setDetailRoute,
    setRoute,
    selectionStatus,
    calepinageStatus,
    onCalepinageRun,
    onCalepinageSave,
  } = props;

  const { layoutConfigure, setLayoutConfigure } = useContext(
    CalpinageContext
  ) as CaplinageContextType;

  return (
    <>
      <Container className="grid-container" style={{ paddingTop: 15 }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={15}>
              <div style={{ textAlign: "right" }}>
                <Popup
                  content={"Save"}
                  position="top center"
                  inverted
                  size="mini"
                  trigger={
                    <Icon
                      name="save"
                      color="blue"
                      size="big"
                      style={{ cursor: "pointer" }}
                      onClick={() => onCalepinageSave()}
                    ></Icon>
                  }
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="five" textAlign="right">
              <Icon
                aria-hidden="true"
                className="grey caret right big icon margin-10"
              ></Icon>
            </Grid.Column>
            <Grid.Column width="six">
              <Button
                onClick={() => setDetailRoute("selection")}
                primary
                size="medium"
                className="margin-10"
                content={"Selections"}
                fluid
              ></Button>
            </Grid.Column>
            <Grid.Column width="five" textAlign="left">
              <Popup
                content={
                  selectionStatus
                    ? "Selection completed"
                    : "Selection not completed"
                }
                position="top center"
                inverted
                size="mini"
                trigger={
                  <Icon
                    aria-hidden="true"
                    className={
                      selectionStatus
                        ? "green check circle outline big icon margin-10"
                        : "red times circle outline big icon margin-10"
                    }
                  ></Icon>
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="five" textAlign="right">
              <Icon
                aria-hidden="true"
                className="grey caret right big icon margin-10"
              ></Icon>
            </Grid.Column>
            <Grid.Column width="six">
              <Button
                onClick={() => setDetailRoute("calepinage")}
                primary
                size="medium"
                className="margin-10"
                content={"Layout configuration"}
                fluid
                disabled={!selectionStatus}
              ></Button>
            </Grid.Column>
            <Grid.Column
              width="five"
              textAlign="left"
              display="inline-flex"
              alignItems="center"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <Popup
                content={
                  calepinageStatus
                    ? "Layout configuration completed"
                    : "Layout configuration not completed"
                }
                position="top center"
                inverted
                size="mini"
                trigger={
                  <Icon
                    aria-hidden="true"
                    className={
                      calepinageStatus
                        ? "green check circle outline big icon margin-10"
                        : "red times circle outline big icon margin-10"
                    }
                  ></Icon>
                }
                disabled={!selectionStatus}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="five" textAlign="right"></Grid.Column>

            <Grid.Column width="six">
              <div className="step-process">
                <Checkbox
                  label="Wall Type Assignment"
                  checked={layoutConfigure.WallTypeAssignment}
                />
                <Checkbox
                  label="Metal Framing"
                  defaultChecked={layoutConfigure.MetalFraming}
                  onChange={(e, { checked }) => {
                    setLayoutConfigure((prev: any) => {
                      return { ...prev, MetalFraming: checked! };
                    });
                  }}
                />
                <Checkbox
                  label="Drywall Layout"
                  defaultChecked={layoutConfigure.DryWall}
                  onChange={(e, { checked }) => {
                    setLayoutConfigure((prev: any) => {
                      return { ...prev, DryWall: checked! };
                    });
                  }}
                />
              </div>
            </Grid.Column>
            <Grid.Column width="five"></Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <div
        style={{
          position: "absolute",
          bottom: "30px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <span>
          <Button
            color="orange"
            onClick={() => setRoute("ROOT")}
            content={"Back"}
          ></Button>
        </span>
        <span>
          <Button
            color="blue"
            disabled={
              !selectionStatus ||
              (layoutConfigure.MetalFraming && !calepinageStatus) ||
              (layoutConfigure.DryWall && !calepinageStatus)
            }
            content={"Layout"}
            onClick={() => onCalepinageRun(layoutConfigure)}
          ></Button>
        </span>
      </div>
    </>
  );
};
