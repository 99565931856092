import LicenseWindow from "./License";
import UpdateProfile from "./Profile";
import ReleaseNote from "./ReleaseNote";
import Update from "./Update";

export const pluginId = "a1904a90-8130-4e50-8412-d03751af7d7c";

export const GenaralUI = {
  TechnicalName: "GenaralUI",
  Name: "",
  Icon: "",
  ShortDesc: {},
  Id: pluginId,
  Color: "",
  Functionalities: [UpdateProfile, Update, LicenseWindow, ReleaseNote],
};

//export default GenaralUI;
