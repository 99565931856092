import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Header } from "semantic-ui-react";
import { AppWindowName } from "../../assets/constants";
import { selectWindowToShow } from "../../actions/appActions";
import { selectOptionalProperties } from "../../actions/csvActions";

import Body from "./body/Body";
import "./dataTransfert.scss";
import Footer from "../common/footer/Footer";
import StateModel from "../../models/State.model";
import { api } from "../../../../../RevitJS/API";

class DataTransfert extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: true,
            progress: 0,
            buffer: 0,
            currentElementNbr: 0,
            currentElementName: "",
            totalElementsNbr: 0,
            finished: false,
        };

        this.nextPage = this.nextPage.bind(this);
    }

    async componentDidMount() {
        await api.familyEditor.injectCsvAndParams(
            "DIMENSIONS_ALL_FIXED_FLANGED_TEES_NATURAL_PECB",
            "Natural-Tes_a_Brides-PECB",
            []
        );
        // let { t } = this.props;
        // let accumulateElementsDone: number = 0;
        // this.setState({
        //     totalElementsNbr: this.props.selectedFile.length,
        // });

        // for (let file of this.props.selectedFile)
        // {
        //     let tmpCurrentElement = file.file.name
        //     await api.familyEditor.injectCsvAndParams(
        //         file.file.name,
        //         "Bride.rfa",
        //         this.props.selectedOptionalProperties.map(property => ({group: property.param_group, name: t(property.label), type: property.param_type }))
        //     );
        //     accumulateElementsDone++;
        //     this.setState({
        //         progress: Math.ceil(
        //             (accumulateElementsDone * 100) / this.state.totalElementsNbr
        //         ),
        //         currentElementNbr: accumulateElementsDone,
        //         currentElementName: tmpCurrentElement,
        //     });
        // }
        // setTimeout(() => {
        //     this.nextPage();
        // }, 500);
    }


    backPage() {
        let { selectWindowToShow } = this.props;
        selectWindowToShow(AppWindowName.PROPERTIES_PREFERENCE);
    }

    async nextPage() {
        let { selectWindowToShow } = this.props;
        // saveProject();
        // selectWindowToShow(AppWindowName.PROPERTIES_PREFERENCE);
    }

    render() {
        let { t } = this.props;
        return (
            <div id="data-transfert-container">
                <Header displayName={'Test test'}></Header>
                <Body
                    progress={this.state.progress}
                    buffer={this.state.buffer}
                    currentElementNbr={this.state.currentElementNbr}
                    currentElementName={this.state.currentElementName}
                    totalElementsNbr={this.state.totalElementsNbr}
                    finished={this.state.finished}
                ></Body>
                <span></span>
                <Footer
                    savedProjectsButtonShow={false}
                    showNextButton={false}
                    showBackButton={false}
                ></Footer>
            </div>
        );
    }
}

let mapStateToProps = (state: StateModel) => {
    return {
        csvList: state.csv.list,
        selectedFile: state.csv.selected,
        optionalProperties: state.csv.optionalProperties,
        selectedOptionalProperties: state.csv.selectedOptionalProperties
    };
};

let mapDispatchToProps = {
    selectWindowToShow,
    selectOptionalProperties
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {
    loading: boolean;
    progress: number;
    buffer: number;
    currentElementNbr: number;
    currentElementName: string;
    totalElementsNbr: number;
    finished: boolean;
};

export default connector(withTranslation(["pamCanalisation"])(DataTransfert));
