import { ThunkAction } from "redux-thunk";
import { AppActionTypes } from "./types";
import {
  changeCurrentLanguageAction,
  selectWindowToShowAction,
} from "./actions";
import { DrawStore } from "../reducers";
import { APP_WINDOW_NAME } from "../../assets/constants";
import { api } from "../../../../../RevitJS/API";

type Effect = ThunkAction<any, DrawStore, any, AppActionTypes>;

export const selectWindowToShow =
  (windowToShow: APP_WINDOW_NAME): Effect =>
  (dispatch: any, getState: any) => {
    dispatch(selectWindowToShowAction(windowToShow));
  };

export const initLanguage = (): Effect => async (dispatch) => {
  const setRevitLanguage = async () => {
    let revitLanguage = "French" as Language; //(await api.queries.getRevitLanguage()) as Language;
    localStorage.setItem("savedLanguage", revitLanguage);
    dispatch(changeCurrentLanguageAction(revitLanguage));
  };
  let savedLanguage: any = localStorage.getItem("savedLanguage");
  if (savedLanguage) {
    if (isLanguage(savedLanguage)) {
      dispatch(changeCurrentLanguageAction(savedLanguage));
    } else {
      setRevitLanguage();
    }
  } else {
    setRevitLanguage();
  }
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);
