import React, { Component } from "react";
import { connect } from "react-redux";
import { api } from "../../../../RevitJS/API";
import { initLanguage } from "../Actions";
import { Routes } from "./root";
import Waiter from "./Waiter";

interface Props {
  route: Routes;
  setRoute: any;
  initLanguage: any;
}

export class AppBody extends Component<Props> {

  componentDidMount = () => {
    this.props.initLanguage();
  }
  render() {
    switch (this.props.route) {
      case Routes.ROOT:
        return (
          <Waiter routes={this.props.route} setRoute={this.props.setRoute} />
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: null, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};

export default connect(mapStateToProps, { initLanguage })(AppBody);
