import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { UserManualRoot } from "./components/root";

export const functionalityId = "7be7d054-e0e3-4a71-9604-c1ea99c70f85";

export const schedulesTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <UserManualRoot />
    </ParentRoot>
  );
};

export const UserManualRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <UserManualRoot />
    </ParentRoot>
  );
};

export const UserManual: Functionality = {
  Name: {
    English: "User Manual",
    Indonesian: "Panduan Pengguna",
  },
  Trigger: schedulesTrigger,
  ShortDesc: {
    English: "Download the guide",
    Indonesian: "Unduh Panduan",
  },
  Id: functionalityId,
  Icon: "/weberIcons/UserguideBW.png",
  RootComponent,
  ParentId: "0",
};
