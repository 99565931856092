import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { api } from "../../../../../RevitJS/API";
import { selectWindowToShow } from "../../actions/appActions";
import {
  createNewProject,
  setCurrentProject,
  setProjectList,
} from "../../actions/projectsActions";
import { AppWindowName, Country, SAVED_PROJECTS } from "../../assets/constants";
import Project from "../../models/Project.model";
import StateModel from "../../models/State.model";
// import {
//     deleteProject,
//     getSavedProjects,
//     updateProjectProperty,
// } from "../../services/projects.service.local";
import {
  saveNewProject,
  getSavedProjects,
  deleteProject,
  updateProjectProperty,
} from "../../services/projects.service.local2";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./projectMenu.scss";
import WarningModal from "./warningModal/WarningModal";

class ProjectMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.backPage = this.backPage.bind(this);
    //this.nextPage = this.nextPage.bind(this);
    this.newProject = this.newProject.bind(this);
    this.deleteSelectedProject = this.deleteSelectedProject.bind(this);
    this.updateSelectedProjectProperty =
      this.updateSelectedProjectProperty.bind(this);
    this.goToProjectRecp = this.goToProjectRecp.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setModalStockage = this.setModalStockage.bind(this);

    this.state = {
      loading: false,
      modal: {
        open: false,
      },
      modalStockage: {
        open: false,
      },
    };
  }

  setModal(modal: { open: boolean }) {
    this.setState({
      modal: { ...modal },
    });
  }

  setModalStockage(modal: { open: boolean }) {
    this.setState({
      modalStockage: { ...modal },
    });
  }

  async componentDidMount() {
    let { setProjectList } = this.props;
    this.setState({ loading: true });
    try {
      await getSavedProjects().then((data: any) => {
        setProjectList(data);
        this.setState({ loading: false });
      });
    } catch (e) {
      this.setState({ loading: false });
    }

    // let project: Project = {
    //     allAvailableElements: [],
    //     country: Country.FR,
    //     creation_date: "11/09/2020",
    //     inEdition: true,
    //     items: [],
    //     looseElements: [],
    //     modification_date: "11/09/2020",
    //     name: "qsdz",
    //     validatedItems: [],
    // };

    // saveNewProject(project);
    // saveNewProject(project);

    // deleteProject(project);

    // updateProjectProperty(project, "name", "tayeb");

    // getSavedProjects();
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.INTRODUCTION_VIDEO_WINDOW);
  }

  // nextPage() {
  //     this.props.selectWindowToShow(AppWindowName.PROJECT_NAME_WINDOW);
  // }

  newProject() {
    let { createNewProject, selectWindowToShow } = this.props;
    createNewProject();
    selectWindowToShow(AppWindowName.PROJECT_NAME_WINDOW);
  }

  deleteSelectedProject(row: Project, index: number) {
    let { setProjectList } = this.props;
    this.setState({ loading: true });
    deleteProject(row).then((data: any) => {
      setProjectList(data);
      this.setState({ loading: false });
    });
  }

  updateSelectedProjectProperty(row: Project, key: string, value: any) {
    let { setProjectList } = this.props;

    updateProjectProperty(row, key, value).then((newProjectList) => {
      setProjectList(newProjectList);
    });
  }

  goToProjectRecp(projectToModify: Project) {
    let { selectWindowToShow } = this.props;
    this.props.setCurrentProject(projectToModify);
    selectWindowToShow(AppWindowName.RECAP_WINDOW);
  }

  render() {
    let { projectList, t } = this.props;

    return (
      <div id="project-menu-container">
        <Header displayName={t(SAVED_PROJECTS) || ""}></Header>
        <Body
          loading={this.state.loading}
          clickeNewProjectButton={this.newProject}
          projectsList={projectList}
          deleteProject={this.deleteSelectedProject}
          modifyProject={(projectToModify: Project) => {
            this.goToProjectRecp(projectToModify);
          }}
          updateProjectProperty={this.updateSelectedProjectProperty}
          setModal={this.setModal}
          setModalStockage={this.setModalStockage}
        ></Body>
        <Footer
          savedProjectsButtonDisabled={true}
          disabledNextButton={true}
          // clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
        ></Footer>
        <WarningModal
          modal={this.state.modal}
          setModal={this.setModal}
          key="modal1"
        ></WarningModal>
        <WarningModal
          modal={this.state.modalStockage}
          setModal={this.setModalStockage}
          stockage
          key="modal2"
        ></WarningModal>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    currentProject: state.projects.currentProject,
    projectList: state.projects.projectsList,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  createNewProject,
  setProjectList,
  updateProjectProperty,
  setCurrentProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {
  loading: boolean;
  modal: { open: boolean };
  modalStockage: { open: boolean };
};

export default connector(withTranslation(["pam"])(ProjectMenu));
