import { CLOISONS_ID, DOUBLAGES_ID, PLAFONDS_ID } from '../../assets/constants';
import {
    LOAD_SYSTEMS,
    LOAD_SYSTEMS_SUCCESS,
    LOAD_SYSTEMS_ERROR,
    LOAD_SYSTEMS_DETAILS,
    LOAD_SYSTEMS_DETAILS_SUCCESS,
    LOAD_SYSTEMS_DETAILS_MORE,
    LOAD_SYSTEMS_DETAILS_ERROR, SystemsState, SystemsActionTypes, DISPLAY_FAVORITE, FILTER_SYSTEMS, RESTORE_SYSTEMS_DETAILS, DISPLAY_SYSTEMS, LOAD_EXISTING_SYSTEMS, ADD_SEARCH_KEY_WORD, DELETE_SEARCH_KEY_WORD, RESET_SEARCH_KEY_WORD
} from './types';

export const initialState: SystemsState = {
    loadingSystems: true,
    loadingSystemsDetails: true,
    systems: [],
    systemsDetails: [],
    currentSystemDetail: [],
    data: {
        liningWallsDetails: [],
        partitionsDetails: [],
        ceilingsDetails: [],
        gainesTechniquesDetails: [],
        liningWallsMetaData: [],
        partitionsMetaData: [],
        ceilingsMetaData: [],
        gainesTechniquesMetaData: []
    },
    systemsMetaData: [],
    systemTypeID: CLOISONS_ID,
    displayedSystemsCount: 0,
    favoriteDisplayed: false,
    existingSystems: [],
    searchKeyWords: []
}

export default function systemsReducer(state = initialState, action: SystemsActionTypes) {
    switch (action.type) {
        case LOAD_SYSTEMS:
            return { ...state, loadingSystems: true };
        case LOAD_SYSTEMS_SUCCESS:
            return { ...state, loadingSystems: false, systems: action.systems };
        case LOAD_SYSTEMS_ERROR:
            return { ...state, loadingSystems: false };
        case LOAD_SYSTEMS_DETAILS:
            return { ...state, loadingSystemsDetails: true, currentSystemDetail: [], systemsDetails: [], displayedSystemsCount: 0 };
        case LOAD_SYSTEMS_DETAILS_SUCCESS:
            return {
                ...state,
                loadingSystemsDetails: false,
                data: {
                    ...state.data,
                    partitionsDetails: action.currentSystemsDetailsPartitions,
                    liningWallsDetails: action.currentSystemsDetailsLiningWalls,
                    ceilingsDetails: action.currentSystemsDetailsPlafonds,
                    gainesTechniquesDetails: action.currentSystemsDetailsGainesTechniques,
                    liningWallsMetaData: action.systemsMetaDataLiningWalls,
                    partitionsMetaData: action.systemsMetaDataPartitions,
                    ceilingsMetaData: action.systemsMetaDataPlafonds,
                    gainesTechniquesMetaData: action.systemsMetaDataGainesTechniques,
                },
                currentSystemDetail: action.systemType === CLOISONS_ID ? action.currentSystemsDetailsPartitions : action.systemType === DOUBLAGES_ID ? action.currentSystemsDetailsLiningWalls : action.systemType === PLAFONDS_ID ? action.currentSystemsDetailsPlafonds : action.currentSystemsDetailsGainesTechniques,
                systemsDetails: action.systemType === CLOISONS_ID ? action.currentSystemsDetailsPartitions.slice(0, 20) : action.systemType === DOUBLAGES_ID ? action.currentSystemsDetailsLiningWalls.slice(0, 20) : action.systemType === PLAFONDS_ID ? action.currentSystemsDetailsPlafonds.slice(0, 20) : action.currentSystemsDetailsGainesTechniques.slice(0, 20),
                systemsMetaData: action.systemType === CLOISONS_ID ? action.systemsMetaDataPartitions : action.systemType === DOUBLAGES_ID ? action.systemsMetaDataLiningWalls : action.systemType === PLAFONDS_ID ? action.systemsMetaDataPlafonds : action.systemsMetaDataGainesTechniques,
                systemTypeID: action.systemType,
                displayedSystemsCount: action.systemType === CLOISONS_ID ? action.currentSystemsDetailsPartitions.length : action.systemType === DOUBLAGES_ID ? action.currentSystemsDetailsLiningWalls.length : action.systemType === PLAFONDS_ID ? action.currentSystemsDetailsPlafonds.length : action.currentSystemsDetailsGainesTechniques.length,
            };
        case LOAD_SYSTEMS_DETAILS_ERROR:
            return { ...state, loadingSystemsDetails: false };
        case RESTORE_SYSTEMS_DETAILS:
            return {
                ...state,
                currentSystemDetail: action.currentSystemsDetails,
                systemsDetails: action.currentSystemsDetails.slice(0, 20),
                systemsMetaData: action.systemsMetaData,
                systemTypeID: action.systemType,
                displayedSystemsCount: action.currentSystemsDetails.length,
                loadingSystemsDetails: false
            };
        case LOAD_SYSTEMS_DETAILS_MORE:
            return { ...state, loadingSystemsDetails: false, systemsDetails: [...state.systemsDetails, ...action.systemsDetails] };
        case FILTER_SYSTEMS:
            return { ...state, loadingSystemsDetails: false, systemsDetails: action.currentSystemsDetails.slice(0, 20), currentSystemDetail: action.currentSystemsDetails, displayedSystemsCount: action.currentSystemsDetails.length }
        case DISPLAY_FAVORITE:
            return { ...state, systemsDetails: action.systemsDetails.slice(0, 20), favoriteDisplayed: true }
        case DISPLAY_SYSTEMS:
            return { ...state, systemsDetails: action.systemsDetails.slice(0, 20), displayedSystemsCount: action.systemsDetails.length, favoriteDisplayed: false }
        case ADD_SEARCH_KEY_WORD:
            return { ...state, searchKeyWords: [...state.searchKeyWords, action.searchWord] }
        case DELETE_SEARCH_KEY_WORD:
            return {
                ...state, searchKeyWords: [...state.searchKeyWords.slice(0, action.searchWordIndex),
                ...state.searchKeyWords.slice(action.searchWordIndex + 1)]
            }
        case RESET_SEARCH_KEY_WORD:
            return {
                ...state, searchKeyWords: []
            }
        case LOAD_EXISTING_SYSTEMS:
            return { ...state, existingSystems: action.existingSystems }
        default:
            return state;

    }
}

