import React, { useState } from "react";
import {
  Checkbox,
  Button,
  Popup,
  List,
  Segment,
  Table,
  Icon,
  Tab,
} from "semantic-ui-react";
import ProductInfoBox from "./ProductInfoBox";

export const ProductRow = (props: any) => {
  const [showProductInfoBox, setShowProductInfoBox] = useState<boolean>(false);

  const handleOpenProductInfoBox = () => {
    setShowProductInfoBox(true);
  };
  const handleCloseProductInfoBox = () => {
    setShowProductInfoBox(false);
  };
  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Checkbox label={"productName"} className="rowNmProd" />
        </Table.Cell>
        <Table.Cell>
          <span>
            <div style={{ color: "teal", cursor: "pointer" }}>
              <Icon
                name="eye"
                size="large"
                onClick={handleOpenProductInfoBox}
              />
            </div>
          </span>
        </Table.Cell>
        <Table.Cell>
          <span>
            <Popup
              trigger={
                <Button size="mini" inverted>
                  {" "}
                  <Icon name="check" size="large" />
                </Button>
              }
              content={"Status Text"}
              position="top right"
            />
          </span>
        </Table.Cell>

        <Table.Cell>
          <span>
            <div
              // size="mini"
              // color="red"
              //icon="delete"
              //onClick={() => props.deleteRow()}
              style={{ color: "red" }}
            >
              <Icon name="delete" size="large" />
            </div>
          </span>
        </Table.Cell>
      </Table.Row>

      <ProductInfoBox
        handleCloseProductInfoBox={handleCloseProductInfoBox}
        showProductInfoBox={showProductInfoBox}
      />
    </>
  );
};

export default ProductRow;
