import React, { useState, useEffect } from "react";
import {
  Table,
  Checkbox,
  Dropdown,
  Icon,
  Popup,
  Modal,
  Button,
} from "semantic-ui-react";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import matchesProperty from "lodash/matchesProperty";
import minBy from "lodash/minBy";
import { conforms, sortBy } from "lodash";
import maxBy from "lodash/maxBy";
import "../../Resources/common.css";

type Langs = "French" | "English";

const compareWallAndSolutionHeight = (
  wallHeight: number,
  solutionHeight: number
) => {
  if (wallHeight !== undefined) {
    if (solutionHeight < wallHeight) {
      // return "redHeight";
      return "blackHeight";
    }

    return "greenHeight";
  }
  return "";
};

export const plaqueDefault = (plaqueArray: any, wallHeight: string) => {
  // 1. retrun equal height (= wallHeight) plaque
  let selectedPlaque = find(plaqueArray, matchesProperty("height", wallHeight));

  // 2. if selectedPlaque null
  if (!selectedPlaque) {
    // 3. filter plaqs with "height" greater than wallheight only
    //    then retrun minimum height plaqs from that
    selectedPlaque = minBy(
      filter(
        plaqueArray,
        conforms({
          height: function (n: any) {
            let newHt = parseFloat(wallHeight);
            return n > newHt;
          },
        })
      ),
      "height"
    );
  }

  // 4. If selectedPlaque is null or empty
  //    return maximum height plaq.

  if (!selectedPlaque) {
    selectedPlaque = maxBy(plaqueArray, "height");
  }
  return selectedPlaque.value;
};

export const montantDefault = (montantArray: any, wallHeight: string) => {
  let selectedMontant: any = find(
    montantArray,
    matchesProperty("height", wallHeight)
  );

  if (!selectedMontant) {
    selectedMontant = minBy(
      filter(
        montantArray,
        conforms({
          height: function (n: any) {
            let newHt = parseFloat(wallHeight);
            return n > newHt;
          },
        })
      ),
      "height"
    );
  }

  if (!selectedMontant) {
    selectedMontant = maxBy(montantArray, "height");
  }
  return selectedMontant?.value;
};

const TableRowNonLayout = (props: {
  setRoute: any;
  walls: any;
  setPlaque: any;
  setMontant: any;
  index: any;
  //setLayoutPossible: any;
  showConfig: any;
  setCheckedWalls: any;
  key: any;
}) => {
  const [clickedWarning, setClickedWarning] = useState<boolean>(false);
  const [lang, setLang] = useState<Langs>("English");
  const [eventsEnabled, setEventsEnabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const populateHeight = () => {
    if (!walls.PlacoSolutionId.includes("custom")) {
      const height = walls.PlacoSolutionHeight; //props.MappedSystem["Hauteur limite (m)"];
      return height;
    }
    return "--";
  };

  const handlePlaqueChange = (e: any, data: any) => {
    let plaque = props.walls.plaque;
    (plaque[data.index] as string[]) = data.value;
    props.setPlaque(props.walls, plaque);
    localStorage.setItem("isModification", "true");
  };

  const handleMontantChange = (e: any, data: any) => {
    let montant = props.walls.montant;
    (montant[data.index] as string[]) = data.value;
    props.setMontant(props.walls, montant);
    localStorage.setItem("isModification", "true");
  };

  const { walls } = props;
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    setChecked(walls.chk && walls.placo && walls.length !== 0);
    let language: any = localStorage.getItem("savedLanguage");
    const changeLanguage = async () => {
      if (language !== lang) {
        setLang(language);
      } else if (language === "French") {
        setLang("French");
      } else {
        setLang("English");
      }
    };
    changeLanguage();
  }, [walls]);

  const showPopUp = (isPlaco: boolean, isLayoutPossible: boolean) => {
    if (isPlaco && isLayoutPossible) {
      setOpen(false);
    } else if (!isPlaco) {
      setOpen(true);
    } else if (!isLayoutPossible) {
      setOpen(true);
    }
  };

  return (
    <>
      <Popup
        inverted
        content={
          walls.placo
            ? "Ce système Placo ne peut être calepiné actuellement"
            : !walls.layoutPossible &&
              "Ce système Placo ne peut être calepiné actuellement"
        }
        on="hover"
        eventsEnabled={eventsEnabled}
        onClose={() => setOpen(false)}
        onOpen={() => showPopUp(walls.placo, walls.layoutPossible)}
        open={open}
        disabled={walls.chk} //disabled={(walls.layoutPossible || walls.chk) && walls.chk}
        trigger={
          <Table.Row
            style={!checked ? { color: "#cecece" } : {}}
            key={props.key}
          >
            <Table.Cell style={{ width: "5%", padding: "1em" }}>
              <Checkbox
                checked={checked}
                onChange={(e, data) => {
                  setChecked(!checked);
                  props.setCheckedWalls(
                    walls.WallType,
                    walls.Height,
                    walls.LevelName,
                    !checked
                  );
                }}
                disabled={walls.placo && walls.layoutPossible}
              />
            </Table.Cell>
            <Table.Cell style={{ width: "5%" }}>{walls.LevelName}</Table.Cell>
            {walls.Zone !== "" && <Table.Cell>{walls.Zone}</Table.Cell>}
            <Table.Cell
              style={{ width: "15%" }}
            >{`${walls.WallType} (${walls.Ids.length})`}</Table.Cell>
            <Table.Cell style={{ width: "5%" }}>{walls.Height}</Table.Cell>
            <Table.Cell style={{ width: "28%" }}>
              {walls.PlacoSolution}
            </Table.Cell>
            <Table.Cell
              className={compareWallAndSolutionHeight(
                parseFloat(walls.Height),
                parseFloat(populateHeight())
              )}
              style={{ width: "5%" }}
            >
              {walls.placo && populateHeight()}
            </Table.Cell>
            <Table.Cell style={{ width: "5%" }}>
              {walls.plaque && checked ? (
                <Icon
                  name="wrench"
                  color="blue"
                  onClick={() => {
                    if (localStorage.getItem("min") !== "0:0") {
                      props.showConfig(walls.PlacoSolutionId);
                    } else {
                      setClickedWarning(true);
                    }
                  }}
                  style={{ padding: "0px" }}
                />
              ) : null}
            </Table.Cell>
            <Modal
              size="mini"
              open={clickedWarning}
              //onClose={() => setClickedWarning(false)}
            >
              <Modal.Header>Warning</Modal.Header>
              <Modal.Content>{wording.contentWarning[lang]}</Modal.Content>
              <Modal.Actions>
                <Button positive onClick={() => setClickedWarning(false)}>
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
            <Table.Cell style={{ width: "16%" }}>
              {walls.plaque &&
                map(walls.plaqueArray, (item, index) => {
                  // we need to check if default plaque is not blank else it may render before state taking values
                  const sortedArticles = sortBy(item.articles, [
                    function (o) {
                      return o.text;
                    },
                  ]);
                  return (
                    walls.plaque[index] && (
                      <Popup
                        content={walls.plaque[index]}
                        hoverable={false}
                        trigger={
                          <Dropdown
                            name="plaque"
                            fluid
                            selection
                            options={sortedArticles}
                            //text={defaultPlaque}
                            defaultValue={walls.plaque[index]}
                            //value={defaultPlaque}
                            onChange={(e: any, data: any) => {
                              handlePlaqueChange(e, data);
                              localStorage.setItem("isModification", "true");
                            }}
                            // placeholder="Select Plaque"
                            index={index}
                            disabled={!checked}
                          />
                        }
                      />
                    )
                  );
                })}
            </Table.Cell>
            <Table.Cell style={{ width: "16%" }}>
              {walls.montant &&
                map(walls.montantArray, (item, index) => {
                  const sortedArticles = sortBy(item.articles, [
                    function (o) {
                      return o.text;
                    },
                  ]);

                  return (
                    walls.montant[index] && (
                      <Popup
                        position="top right"
                        content={walls.montant[index]}
                        hoverable={false}
                        trigger={
                          <Dropdown
                            name="montant"
                            fluid
                            selection
                            options={sortedArticles}
                            defaultValue={walls.montant[index]}
                            onChange={(e: any, data: any) => {
                              handleMontantChange(e, data);
                              localStorage.setItem("isModification", "true");
                            }}
                            placeholder="Select Montant"
                            index={index}
                            disabled={!checked}
                          />
                        }
                      />
                    )
                  );
                })}
            </Table.Cell>
          </Table.Row>
        }
      />
    </>
  );
};
const wording = {
  contentWarning: {
    French:
      "Votre session a expiré, veuillez vous reconnecter pour voir cette page",
    English: "Your session has expired, please log in again to see this page",
  },
};
export default TableRowNonLayout;
