import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { APP_WINDOW_NAME } from "../assets/constants";
import { selectWindowToShow, initLanguage } from "../store/app/effects";
import Waiter from "../components/Waiter";
import { MyConfig } from "../../../../Helper";
import { setConfig } from "../../../CLT/Delete/Actions";

export class AppBody extends Component<IProps, IState> {
  componentDidMount = async () => {
    this.props.initLanguage();
    setTimeout(() => {
      this.props.i18n.changeLanguage(this.props.currentLanguage);
    });

    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  render() {
    switch (this.props.app.currentWindow) {
      case APP_WINDOW_NAME.ROOT:
        return <Waiter />;

      default:
        return null;
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    app: state.app,
    currentLanguage: state.app.language,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  initLanguage,
  setConfig,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { allAvailableItems: Element[] };

export default connector(withTranslation(["library"])(AppBody));
