export const generateRandomColor = () => {
  let num = Math.round(0xffffff * Math.random());
  let r = num >> 16;
  let g = (num >> 8) & 255;
  let b = num & 255;
  let a = 1;
  return { r: r, g: g, b: b, a: a };
};

export const getPrintDate = (timeStamp: any) => {
  let date = new Date();
  if(timeStamp.indexOf("/") === -1){
    date = new Date(parseInt(timeStamp));
  } else {
    let d = timeStamp.split("/");
    if (d.length === 3) {
      date = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    }
  }
  return !isNaN(date as any)
    ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    : timeStamp;
};

export const getDateToCompare = (timeStamp: any) => {
  let date = new Date();
  if(timeStamp.indexOf("/") === -1){
    date = new Date(parseInt(timeStamp));
  } else {
    let d = timeStamp.split("/");
    if (d.length === 3) {
      date = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    }
  }
  return date.getTime();
};
