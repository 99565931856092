import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Grid, Dimmer, Loader, Menu } from "semantic-ui-react";
import _, {
  map,
  cloneDeep,
  uniqBy,
  filter,
  flattenDeep,
  groupBy,
  concat,
} from "lodash";
import SystemsMappingRow from "./SystemsMappingRow";
import { mergeWallType } from "../../../Helper/AddWallTypeAfterAssignment";
interface Props {
  markSolutions: any;
  selection: any;
  onRowDelete: any;
  control: any;
  onSystemsChange:any;
}

export const SystemsMappingTable = (props: Props) => {
  const { selection, markSolutions, control, onRowDelete,onSystemsChange } = props;
  const walls = selection.SelectionDetails;
  const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "black",
      boxShadow: theme.shadows[1],
      fontWeight: "bold",
      fontSize: 11,
      // height:40,
    },
  }))(Tooltip);

  if (walls.length === 0) {
    <div>No wall selected</div>;
  }

  if (!markSolutions) {
    return (
      <Dimmer inverted active style={{ height: "calc(100vh - 200px)" }}>
        <Loader />
      </Dimmer>
    );
  }
  return (
    <div>
      <Grid stackable style={{ marginTop: 5 }}>
        <Grid.Row style={{ paddingTop: 5 }}>
          <Grid.Column columns={12}>
            <div style={{ height: "calc(100vh - 240px)", overflowY: "auto" }}>
              {/* <SystemDetails /> */}
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="6" textAlign="center">
                      {"Revit System Type"}
                    </Table.HeaderCell>
                    <Table.HeaderCell width="8" textAlign="center">
                      {"Systems"}
                    </Table.HeaderCell>
                    <Table.HeaderCell width="1" textAlign="center">
                      {"Actions"}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {map(walls, (cel: any, key: any) => (
                    <SystemsMappingRow
                      key={key}
                      wall={cel}
                      markSolutions={markSolutions}
                      control={control}
                      onRowDelete={onRowDelete}
                      onSystemsChange={onSystemsChange}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
