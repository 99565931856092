import * as React from "react";
import { createContext, useState, useEffect } from "react";
import { MyConfig } from "../../../../Helper";
import { Routes, ElementType, QuantiType } from "../Util/type";
import { filter, find, map } from "lodash";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../RevitJS/API";
import { bimStorage, storageKey } from "../../../../BIMStore";

export interface IQuantityContextType {
  root: Routes;
  element: ElementType;
  quntiType: QuantiType;
  setRoot: (route: Routes) => void;
  setElementType: (element: ElementType) => void;
  setQuantiType: (element: QuantiType) => void;
}

export const QuantityContext = createContext<IQuantityContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const QuantityProvider: React.FC<Props> = ({ children }) => {
  const [root, setRoot] = useState<Routes>(Routes.ROOT);
  const [element, setElementType] = useState<ElementType>(ElementType.WALL);
  const [quntiType, setQuantiType] = useState<QuantiType>(QuantiType.FLOOR);

  return (
    <QuantityContext.Provider
      value={{
        root,
        element,
        quntiType,
        setRoot,
        setElementType,
        setQuantiType,
      }}
    >
      {children}
    </QuantityContext.Provider>
  );
};

export default QuantityProvider;
