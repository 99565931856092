import React from "react";
import { Functionality } from "../../RevitJS/Types/StoreTypes";
import { TrainingOne } from "./TrainingOne";
import { TrainingTwo } from "./TrainingTwo";
import { Plugin } from "../../RevitJS/Types/StoreTypes";

export const pluginId = "114e070d-5c07-4e6b-a0a3-d2ee17301fa5";

export const functionalityId = "a8676f1a-81d4-4a51-8260-e600eff803e6";

export const CalpinageDropdown: Functionality = {
  Name: { French: "PluginTraining", English: "PluginTraining", German: "PluginTraining", Italian: "PluginTraining", Indonesian: "PluginTraining" },
  Trigger: () => { },
  ShortDesc: {
    French: "To give training",
    English: "To give training",
    German: "To give training",
    Italian: "To give training",
    Indonesian: "To give training",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Calepinage.png",
  RootComponent: "",
  ParentId: "0",
};

export const pluginTraining: Plugin = {
  TechnicalName: "PLUGINTRAINING",
  Name: "",
  Icon: "/PlacoBIM.jpg",
  ShortDesc: {
    French: "To give training",
    English: "To give training",
    German: "To give training",
    Italian: "To give training",
    Indonesian: "To give training",
  },
  Id: pluginId,
  Color: "#005EB8",
  Functionalities: [
    TrainingOne , TrainingTwo ],
};
