import React, { Component } from "react";
import { api } from "../../../../RevitJS/API";
import {
  Segment,
  Table,
  Button,
  Checkbox,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Routes } from "./root";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
  MappingRowRigips,
  MappingRowRigipsCeilings,
} from "../../../../RevitJS/Types/StoreTypes";
import {
  drawLayout,
  albaSystemsList,
  toggleAll,
  drawLayoutCeilings,
} from "../Actions";
import MappingRowRigipsComponent from "./MappingRowRigips";
import { PIMLayoutAttributesAid } from "../../../../RevitJS/Types/BddTypes";
import { PLTResult, LayoutState } from "../../../../Layout/types";
import {
  parseRigipsCeilingsSystem,
  parseRigipsSystem,
  startInList,
} from "../Helpers";
import {
  A02LayersParser,
  A3PLayersParser,
  A3TLayersParser,
} from "../Helpers/LayersParsers/layersParsers";
import axios from "axios";
import { A01LayersParser } from "../Helpers/LayersParsers/a1LayersParser";
import { wait } from "../../../../RevitJS/Helpers";
import {
  a112yMapper,
  a1Mapper,
  a122yMapper,
} from "../Helpers/LayoutMappers/RigipsMappers/a1Mapper";
import {
  a3Mapper,
  a3yMapper,
} from "../Helpers/LayoutMappers/RigipsMappers/a3Mapper";
import { a5Mapper } from "../Helpers/LayoutMappers/RigipsMappers/a5Mapper";
import { WithTranslation, withTranslation } from "react-i18next";
import Tab from "./Tab";
import MappingRowRigipsCeilingsComponent from "./MappingRowRigipsCeilings";
import { getPlacoSystemsDetails } from "../Requests";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";
import { map, uniq } from "lodash";

export const rigipsSystemDispatcher = () => {};

export const layout = (
  localhost: any,
  wallTypesData: any,
  walls: any,
  wallsDataByLevel: any
) =>
  localhost.post("", {
    wallMatching: wallTypesData,
    walls: walls,
    allwalls: wallsDataByLevel,
  });

export const layoutCeilings = (localhost: any, ceilingsData: any) =>
  localhost.post("", { ceilingsData });

interface Props {
  layoutAttributesAid: PIMLayoutAttributesAid | null;
  language: string;
  Icon: string;
  mappingRowsWalls: MappingRowRigips[];
  mappingRowsCeilings: MappingRowRigipsCeilings[];
  layoutState: LayoutState;
  layoutProgress: number;
  layoutStateCeiling: LayoutState;
  layoutProgressCeiling: number;
  setRoute: any;
  drawLayout: any;
  drawLayoutCeilings: any;
  toggleAll: Function;
  config: any;
}

interface State {
  toogler: boolean;
}

export class SolutionMapping extends Component<Props & WithTranslation, State> {
  state = {
    toogler: true,
  };

  componentDidMount = () => {
    api.windowsHandler.resizeWindow(1000, 600);
    // api.windowsHandler.showDevTools();
  };

  componentDidUpdate = async () => {
    if (
      (this.props.layoutStateCeiling === "Ended" ||
        this.props.mappingRowsCeilings.length === 0) &&
      (this.props.layoutState === "Ended" ||
        this.props.mappingRowsWalls.length === 0)
    ) {
      await wait(1500);
      api.windowsHandler.closeWindow();
    }
  };

  validationHandler = async (config: any) => {
    config = this.props.config;
    const { mappingRowsWalls } = this.props;
    if (mappingRowsWalls.length > 0) {
      let wallTypesData: any[] = [];
      let wallsData: any[] = [];
      let wallsDataByLevel: any[] = [];
      for (let i = 0; i < mappingRowsWalls.length; i++) {
        let row = mappingRowsWalls[i];
        console.log("row : ", row);
        if (row.MappedSystem !== null) {
          let parsedSystem;
          let wallTypeData;

          let rowWallsData = await api.queries.getWallsData(
            row.RevitSelection.Ids
          );

          let max_height = Math.max(
            ...rowWallsData.map((wall) => wall.Height as number)
          );
          wallsData = [...wallsData, ...rowWallsData];
          if (startInList(row.MappedSystem.translation, albaSystemsList)) {
            if (
              startInList(row.MappedSystem.translation, [
                "1-A.1.1",
                "1-AH.1.1",
                "1-AB.1.1",
                "1-A.2.1",
                "1-AH.2.1",
                "1-AB.2.1",
              ])
            ) {
              parsedSystem = await a1Mapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else if (row.MappedSystem.translation.startsWith("5-A.1.1")) {
              parsedSystem = await a5Mapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else if (row.MappedSystem.translation.startsWith("3-A.1.1")) {
              parsedSystem = await a3Mapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else if (row.MappedSystem.translation.startsWith("3-A.1x.1")) {
              parsedSystem = await a3Mapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else if (row.MappedSystem.translation.startsWith("1-A.1.2y")) {
              parsedSystem = await a112yMapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else if (row.MappedSystem.translation.startsWith("1-A.2.2y")) {
              parsedSystem = await a122yMapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else if (row.MappedSystem.translation.startsWith("3-A.1.2y")) {
              parsedSystem = await a3yMapper(
                parsedSystem,
                config,
                row,
                max_height
              );
            } else {
              let systemParsed = await parseRigipsSystem(
                config,
                row.MappedSystem.oid,
                max_height,
                row.FireConstrain,
                row.SwitchParement,
                row.Reverse,
                row.PublicBuildingConstrain
              );

              let AlbaStructure;
              if (
                startInList(row.MappedSystem.translation, [
                  "1-A.0.1",
                  "1-AH.0.1",
                ])
              ) {
                AlbaStructure = A01LayersParser(systemParsed.layersDesc);
              } else if (row.MappedSystem.translation.startsWith("3-AP")) {
                AlbaStructure = A3PLayersParser(row.MappedSystem.longName);
              } else if (row.MappedSystem.translation.startsWith("3-AT")) {
                AlbaStructure = A3TLayersParser(row.MappedSystem.longName);
              } else if (
                startInList(row.MappedSystem.translation, ["3-A.0.1"])
              ) {
                AlbaStructure = A01LayersParser(systemParsed.layersDesc);
              } else if (
                startInList(row.MappedSystem.translation, ["5-A.0.1"])
              ) {
                AlbaStructure = A01LayersParser(systemParsed.layersDesc);
              } else if (
                startInList(row.MappedSystem.translation, [
                  "1-A.0.2",
                  "1-AH.0.2",
                ])
              ) {
                AlbaStructure = A02LayersParser(systemParsed.layersDesc);
              }
              parsedSystem = {
                LayoutType: "Alba",
                Flipped: false,
                Reverse: false,
                Lining: false,
                AlbaStructure,
                whool: systemParsed.whool,
              };
            }

            wallTypeData = {
              ...parsedSystem,
              technicalName: row.MappedSystem.longName,
              translation: row.MappedSystem.translation,
              InputType: row.RevitSelection.RevitType,
              FreeEnd: "PLACO",
            };
            wallTypesData.push(wallTypeData);
          } else {
            parsedSystem = await parseRigipsSystem(
              config,
              row.MappedSystem.oid,
              max_height,
              row.FireConstrain,
              row.SwitchParement,
              row.Reverse,
              row.PublicBuildingConstrain
            );

            if (
              startInList(row.MappedSystem.translation, [
                "3-AR",
                "3-DL",
                "3-GRF",
                "3-GRH",
                "3-RBS",
                "3-RB",
                "3-RDH",
                "3-RF",
                "5-RF",
                "5-AR",
                "5-DL",
                "5-GRF",
                "5-RDH",
              ])
            ) {
              parsedSystem.Lining = true;
              let buffer;
              if (isNaN(parsedSystem.E1) && !isNaN(parsedSystem.I1)) {
                buffer = parsedSystem.I1;
                parsedSystem.I1 = "None";
                parsedSystem.E1 = buffer;

                buffer = parsedSystem.I1_name;
                parsedSystem.I1_Name = "None";
                parsedSystem.E1_Name = buffer;

                buffer = parsedSystem.I2;
                parsedSystem.I2 = "None";
                parsedSystem.E2 = buffer;

                buffer = parsedSystem.I2_name;
                parsedSystem.I2_Name = "None";
                parsedSystem.E2_Name = buffer;

                buffer = parsedSystem.I3;
                parsedSystem.I3 = "None";
                parsedSystem.E3 = buffer;

                buffer = parsedSystem.I3_Name;
                parsedSystem.I3_Name = "None";
                parsedSystem.E3_Name = buffer;
              }
            }
            wallTypeData = {
              ...parsedSystem,
              technicalName: row.MappedSystem.longName,
              InputType: row.RevitSelection.RevitType,
              FreeEnd: "RIGIPS",
              AlbaStructure: null,
            };
            wallTypesData.push(wallTypeData);
          }
        }
      }
      let token = localStorage.getItem("token");

      let rigipsCalculatorUrl =
        this.props.config.REACT_APP_CALCULATORURL_RIGIPS;

      const localhost = axios.create({
        baseURL: rigipsCalculatorUrl,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });

      localhost.interceptors.request.use(async (request) => {
        return await authFilter(request, config);
      });
      let uniqueLevelIds = uniq(map(wallsData, "LevelId"));
      wallsDataByLevel = await api.queries.getWallsDataByLevel(uniqueLevelIds);

      let layoutRequest = await layout(
        localhost,
        wallTypesData,
        wallsData,
        wallsDataByLevel
      );
      let layoutData: PLTResult[][] = layoutRequest.data;
      map(layoutData, (layInner: any) => {
        map(layInner, (lay) => {
          const wall = wallsData.find((wl) => wl.Id === lay.Id);
          if (wall) {
            lay.LocationLine = wall.LocationLine;
            lay.Neighbours = wall.Neighbours;
          }
          return lay;
        });
        return layInner;
      });
      await this.props.drawLayout(layoutData);
    }
    const { mappingRowsCeilings } = this.props;
    if (mappingRowsCeilings.length > 0) {
      let ceilingsData: any[] = [];

      let tokenCeiling = localStorage.getItem("token");
      for (let i = 0; i < mappingRowsCeilings.length; i++) {
        let row = mappingRowsCeilings[i];
        await api.familyEditor.createParameter("Wall", "Instance", "DATA", {
          Name: "Processed",
          Type: "Boolean",
        });
        await api.familyEditor.createParameter("Wall", "Instance", "DATA", {
          Name: "SG_System",
          Type: "Text",
        });
        await api.familyEditor.createParameter("Wall", "Instance", "DATA", {
          Name: "AlbaId",
          Type: "Text",
        });
        if (row.MappedSystem !== null) {
          await getPlacoSystemsDetails(config, [row.MappedSystem.oid]).then(
            (response: any) => {
              return {
                attributes:
                  response.data.context.attributeDependencies[0].attributes,
                objects: response.data.objects,
              };
            }
          );
          for (let i = 0; i < row.RevitSelection.Ids.length; i++) {
            console.log("Value: ", row.MappedSystem.longName);
            await api.familyEditor.setParams([
              {
                Id: row.RevitSelection.Ids[i],
                Params: [{ Name: "Processed", Type: "YesNo", Value: 1 }],
              },
            ]);
            await api.familyEditor.setParams([
              {
                Id: row.RevitSelection.Ids[i],
                Params: [
                  {
                    Name: "SG_System",
                    Type: "Text",
                    Value: row.MappedSystem.longName,
                  },
                ],
              },
            ]);
          }

          let ceilingTypeData = await parseRigipsCeilingsSystem(
            config,
            row.MappedSystem.oid,
            row.FireConstrain,
            row.AdditionalWeight
          );

          let rowCeilingsData = await api.queries.getCeilingsData(
            row.RevitSelection.Ids
          );
          rowCeilingsData = rowCeilingsData.map((ceiling: any) => ({
            ...ceiling,
            params: ceilingTypeData,
          }));
          ceilingsData = [...ceilingsData, rowCeilingsData];
        }
        ceilingsData = ceilingsData.reduce((acc, curr) => acc.concat(curr), []);
      }

      const ceilingCalculatorUrl =
        this.props.config.REACT_APP_CALCULATORURL_RIGIPS?.replace(
          "plt/java",
          "ceiling/rigips/java"
        ).replace("plt", "ceiling/rigips/java");

      const profiles = axios.create({
        baseURL: ceilingCalculatorUrl,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + tokenCeiling,
        },
      });
      profiles.interceptors.request.use(async (request) => {
        return await authFilter(request, config);
      });
      let layoutRequestCeiling = await layoutCeilings(profiles, ceilingsData);
      let layoutDataCeiling: PLTResult[][] = layoutRequestCeiling.data;

      await this.props.drawLayoutCeilings(layoutDataCeiling);
    }
  };

  render() {
    const { t } = this.props;

    const layoutProgress = () => {
      switch (this.props.layoutState) {
        case "FamilyLoading":
          return t("familyLoading");
        case "Processing":
          return `${t("processing")} ${this.props.layoutProgress} %`;
      }
      switch (this.props.layoutStateCeiling) {
        case "FamilyLoading":
          return t("familyLoading");
        case "Processing":
          return `${t("processing")} ${this.props.layoutProgressCeiling} %`;
      }
      if (
        (this.props.layoutStateCeiling === "Ended" ||
          this.props.mappingRowsCeilings.length === 0) &&
        (this.props.layoutState === "Ended" ||
          this.props.mappingRowsWalls.length === 0)
      )
        return t("endLayout");
      return "";
    };

    return (
      <div id="draw2-solutionMapping-container rigipsContainer">
        <FunctionalityHeader
          Icon={this.props.Icon}
          name={t("mapping")}
          width={80}
          height={30}
        />
        <Dimmer
          active={
            this.props.layoutState !== null ||
            this.props.layoutStateCeiling !== null
          }
        >
          <Loader content={layoutProgress()} />
        </Dimmer>
        <Tab
          tabWallName={t("wall")}
          tabWallContent={
            <Segment
              style={{
                height: "calc(100vh - 200px)",
                overflow: "auto",
                padding: 0,
              }}
            >
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: 50 }}>
                      <Checkbox
                        checked={this.state.toogler}
                        onChange={(e, data) => {
                          this.setState({
                            toogler: !this.state.toogler,
                          });
                          this.props.toggleAll(data.checked);
                        }}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t("revitSystemType")}</Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: 150 }}>
                      {t("fireConstrain")}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: 150 }}>
                      {t("switchParement")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t("systemChoice")}</Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: 150 }}>
                      {t("reverse")}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: 150 }}>
                      {t("publicBuildingConstrain")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.mappingRowsWalls.map((row, index) => (
                    <MappingRowRigipsComponent
                      key={`riggibs-mapping-row-${row.Index}-${row.Checked}-${index}`}
                      setRoute={this.props.setRoute}
                      mappingRow={row}
                    />
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          }
          tabCeilingName={t("ceiling")}
          tabCeilingContent={
            <Segment style={{ height: 320, overflow: "auto", padding: 10 }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: 50 }}>
                      <Checkbox
                        checked={this.state.toogler}
                        onChange={(e, data) => {
                          this.setState({
                            toogler: !this.state.toogler,
                          });
                          this.props.toggleAll(data.checked);
                        }}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t("revitSystemType")}</Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: 150 }}>
                      {t("fireConstrain")}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ maxWidth: 150 }}>
                      {t("additionalWeight")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t("systemChoice")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.mappingRowsCeilings.map((row, index) => (
                    <MappingRowRigipsCeilingsComponent
                      key={`riggibs-mapping-row-${row.Index}-${row.Checked}-${index}`}
                      setRoute={this.props.setRoute}
                      mappingRow={row}
                    />
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          }
        />
        <Segment basic>
          <Button
            floated="left"
            primary
            onClick={(e) => {
              this.props.setRoute(Routes.ROOT);
              //   this.props.resetSelectedItems();
              //   this.props.resetSelectedSystems();
            }}
          >
            {t("back")}
          </Button>
          <Button floated="right" primary onClick={this.validationHandler}>
            {t("validate")}
          </Button>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    language: state.language,
    Icon: state.functionalityIcon,
    mappingRowsWalls: state.mappingRowsWalls,
    mappingRowsCeilings: state.mappingRowsCeilings,
    setRoute: ownProps.setRoute,
    layoutAttributesAid: state.layoutAttributesAid,
    layoutState: state.layoutState,
    layoutStateCeiling: state.layoutStateCeiling,
    layoutProgress: state.layoutProgress,
    layoutProgressCeiling: state.layoutProgressCeiling,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  drawLayout,
  drawLayoutCeilings,
  toggleAll,
})(withTranslation(["riggibs"])(SolutionMapping));
