import React, { useState } from "react";
import {
  Container,
  Grid,
  Icon,
  Popup,
  Button,
  Checkbox,
} from "semantic-ui-react";

interface Props {
  setDetailRoute: any;
  selectionStatus: boolean;
  colorizationStatus: boolean;
  revitSetupStatus: boolean;
  setRoute: any;
}

export const Step = (props: Props) => {
  const {
    setDetailRoute,
    selectionStatus,
    colorizationStatus,
    revitSetupStatus,
    setRoute,
  } = props;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width="five" textAlign="right">
          <Icon
            aria-hidden="true"
            className="grey caret right big icon margin-10"
          ></Icon>
        </Grid.Column>
        <Grid.Column width="six">
          <Button
            onClick={() => setDetailRoute("selection")}
            primary
            size="medium"
            className="margin-10"
            content={"Selections"}
            fluid
          ></Button>
        </Grid.Column>
        <Grid.Column width="five" textAlign="left">
          <Popup
            content={
              selectionStatus
                ? "Selection completed"
                : "Selection not completed"
            }
            position="top center"
            inverted
            size="mini"
            trigger={
              <Icon
                aria-hidden="true"
                className={
                  selectionStatus
                    ? "green check circle outline big icon margin-10"
                    : "red times circle outline big icon margin-10"
                }
              ></Icon>
            }
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="five" textAlign="right">
          <Icon
            aria-hidden="true"
            className="grey caret right big icon margin-10"
          ></Icon>
        </Grid.Column>
        <Grid.Column width="six">
          <Button
            onClick={() => setDetailRoute("colorization")}
            primary
            size="medium"
            className="margin-10"
            content={"Colorization"}
            fluid
            disabled={!selectionStatus}
          ></Button>
        </Grid.Column>
        <Grid.Column
          width="five"
          textAlign="left"
          display="inline-flex"
          alignItems="center"
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <Popup
            content={
              colorizationStatus
                ? "colorization completed"
                : "colorization not completed"
            }
            position="top center"
            inverted
            size="mini"
            trigger={
              <Icon
                aria-hidden="true"
                className={
                  colorizationStatus
                    ? "green check circle outline big icon margin-10"
                    : "red times circle outline big icon margin-10"
                }
              ></Icon>
            }
            disabled={!selectionStatus}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="five" textAlign="right">
          <Icon
            aria-hidden="true"
            className="grey caret right big icon margin-10"
          ></Icon>
        </Grid.Column>
        <Grid.Column width="six">
          <Button
            onClick={() => setDetailRoute("revitsetup")}
            primary
            size="medium"
            className="margin-10"
            content={"Revit Setup"}
            fluid
            disabled={!colorizationStatus}
          ></Button>
        </Grid.Column>
        <Grid.Column
          width="five"
          textAlign="left"
          display="inline-flex"
          alignItems="center"
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <Popup
            content={
              revitSetupStatus
                ? "Revit Setup completed"
                : "Revit Setup not completed"
            }
            position="top center"
            inverted
            size="mini"
            trigger={
              <Icon
                aria-hidden="true"
                className={
                  revitSetupStatus
                    ? "green check circle outline big icon margin-10"
                    : "red times circle outline big icon margin-10"
                }
              ></Icon>
            }
            disabled={!colorizationStatus}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
