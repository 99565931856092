import React from "react";
import { Container, Header, Image } from "semantic-ui-react";

interface FunctionalityHeaderProps {
  name: string;
  Icon: string;
  showIcon: boolean;
  color:
    | "blue"
    | "red"
    | "orange"
    | "yellow"
    | "olive"
    | "green"
    | "teal"
    | "violet"
    | "purple"
    | "pink"
    | "brown"
    | "grey"
    | "black"
    | undefined;
  width?: number;
  height?: number;
  marginTop?: number;
}

export const FunctionalityHeader = (props: FunctionalityHeaderProps) => {
  return (
    <Container textAlign="center" style={{ marginBottom: 0 }}>
      <Header
        color={props.color ? props.color : "blue"}
        style={{ paddingTop: "0", paddingBottom: 0 }}
        as="h3"
        //content={name}
      >
        {props.showIcon && (
          <Image
            style={{
              zIndex: 1,
              // width: width ? width : "",
              // height: height ? height : "",
              width: "2.5em",
              height: "2em",
            }}
            src={props.Icon}
          ></Image>
        )}
        {props.name}
      </Header>
    </Container>
  );
};
FunctionalityHeader.defaultProps = {
  showIcon: true,
  color: undefined,
};
