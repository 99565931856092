import { ADD_SYSTEM_TO_FAVORITE, REMOVE_SYSTEM_FROM_FAVORITE, FavoriteActionTypes, CHANGE_TYPE_ID, LOAD_SYSTEMS_FAVORITES, CLEAR_SYSTEMS_FAVORITES } from './types'
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";

export function addSystemToFavoriteAction(
  favoriteSystemItem: ProductDetailData,
  currentSystemTypeID: number,
  systemTypeID: any//number
): FavoriteActionTypes {
  return {
    type: ADD_SYSTEM_TO_FAVORITE,
    favoriteSystemItem: favoriteSystemItem,
    currentSystemTypeID: currentSystemTypeID, 
    systemTypeID: systemTypeID
  };
}

export function removeSystemFromFavoriteAction(
  index: number,
  currentSystemTypeID: number,
  systemTypeID: any//number
): FavoriteActionTypes {
  return {
    type: REMOVE_SYSTEM_FROM_FAVORITE,
    index: index,
    currentSystemTypeID: currentSystemTypeID, 
    systemTypeID: systemTypeID
  };
}

export const selectFavoriteSystemType = (favoriteSystemItems: ProductDetailData[]): FavoriteActionTypes => ({
  type: CHANGE_TYPE_ID,
  favoriteSystemItems: favoriteSystemItems,
})

export const loadSystemsFavorites = (data: any, favoriteCount: number, systemTypeID: number): FavoriteActionTypes => ({
  type: LOAD_SYSTEMS_FAVORITES,
  data: data,
  favoriteCount: favoriteCount,
  systemTypeID: systemTypeID
})


export const clearAllFavoriteAction = (): FavoriteActionTypes => ({
  type: CLEAR_SYSTEMS_FAVORITES,
})