import React, { Component } from "react";
import LoadedStore from "./LoadedStore";
import { Segment, Button, Loader } from "semantic-ui-react";
import { api } from "../../RevitJS/API";
import StoreNeedsUpdate from "./StoreNeedsUpdate";
import StoreWillUpdate from "./StoreWillUpdate";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { Action } from "redux";
import { Language } from "../../RevitJS/Types/RevitTypes";
import { CheckEnvironmentAccessibility, getMenuFromUser } from "../../Plugins";
import { generalizeLanguage, getAllApplicationDetails } from "../../Helper";

interface Props {
  selectPlugin: (id: string) => Action<any>;
  plugins: Plugin[];
  version: any;
  last_version: string | null;
  last_version_number: string | null;
  language: Language;
  setPlugins: (plugins: Plugin[]) => any;
  config: any;
  environment: string;
}
interface State {
  initialLoading: boolean;
  loading: boolean;
  downloadEnd: boolean;
  installing: boolean;
  plugins: Plugin[];
  //licenseKeyActivated: string;
  allApps: any[];
  // licenseKeyValue: string;
  responseMsg: string;
  revitVersion: number;
  isPremium: boolean;
}
const Wording = {
  restart: {
    French: "Redémarrer",
    English: "Restart",
    German: "Neustarten",
    Italian: "Ricominciare",
    Indonesian: "Restart",
  },
  updateDownloaded: {
    French: "Mise à jour téléchargée.",
    English: "Update downloaded.",
    German: "Aktualisierung heruntergeladen.",
    Italian: "Aggiornamento scaricato.",
    Indonesian: "Update downloaded.",
  },
  installInfo: {
    French: "Cliquez ci-dessus pour l'installer.",
    English: "Click above to install it.",
    German: "Klicken Sie oben, um es zu installieren.",
    Italian: "Fare clic sopra per installarlo.",
    Indonesian: "Click above to install it.",
  },
  RevitRestart: {
    French: "Cette opération provoquera le redémarrage de Revit",
    English: "This will cause Revit to restart.",
    German: "Dadurch wird Revit neu gestartet.",
    Italian: "Ciò causerà il riavvio di Revit.",
    Indonesian: "This will cause Revit to restart.",
  },
};
export default class StoreBody extends Component<Props, State> {
  state = {
    initialLoading: false,
    loading: false,
    downloadEnd: false,
    installing: false,
    plugins: [],
    //licenseKeyActivated: "true",
    allApps: [],
    //licenseKeyValue: "",
    responseMsg: "",
    isPremium: false,
    revitVersion: 0,
  };

  componentDidMount = async () => {
    try {
      this.setState({ initialLoading: true });
      let allApps: any = await getAllApplicationDetails(this.props.config);
      let userPlugins = await getMenuFromUser(allApps);
      const revitVersion = parseInt(await api.framework.getRevitVersion());
      this.setState({ revitVersion });

      allApps = CheckEnvironmentAccessibility(
        allApps.data,
        this.props.config.REACT_APP_ENV
      );

      let plugins: any = [];
      allApps.map((app: any) => {
        userPlugins.map((user: any) => {
          if (app.applicationTechnicalName === user.technicalName)
            plugins.push({
              name: app.applicationName,
              technicalName: user.technicalName,
              plugin: user.plugin,
              premium: app.premium,
            });
          return user;
        });
        return app;
      });

      let masterPluginsList = this.props.plugins as any;
      for (let userPlugin of plugins) {
        for (let masterPlugin of masterPluginsList.filter(
          (a: any) => a.plugin.Id === userPlugin.plugin.Id
        )) {
          masterPlugin.plugin.Name = userPlugin.name;
        }
      }
      let isPremium = plugins.some((a: any) => a.premium);

      this.props.setPlugins(masterPluginsList);
      this.setState({
        plugins,
        allApps: allApps.data,
        isPremium,
        initialLoading: false,
      });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  render() {
    let language = generalizeLanguage(this.props.language);

    const { loading, downloadEnd, initialLoading } = this.state;
    const { version, last_version_number, last_version, selectPlugin } =
      this.props;

    if (initialLoading) {
      return <Loader active={initialLoading} />;
    }

    if (version === "default") return null;
    if (version === "web") {
      return (
        <LoadedStore
          language={language}
          version="web"
          selectPlugin={selectPlugin}
          revitVersion={this.state.revitVersion}
          plugins={this.state.plugins} // plugins
          translation={13.5}
          environment=""
          config={this.props.config}
        />
      );
    }

    if (version === last_version_number) {
      return (
        <LoadedStore
          language={language}
          version={version}
          selectPlugin={selectPlugin}
          revitVersion={this.state.revitVersion}
          plugins={this.state.plugins} // plugins
          translation={0}
          environment=""
          config={this.props.config}
        />
      );
    } else {
      if (downloadEnd) {
        let version_string = version;
        let vintage = "20" + version_string.split(".")[0];
        if (this.state.installing) {
          return <StoreWillUpdate version={version} />;
        }
        return (
          <Segment basic fluid textAlign="center" style={{ marginTop: 20 }}>
            <Button
              primary
              onClick={async () => {
                this.setState({ installing: true });
                if (
                  this.props.config.REACT_APP_FILESERVER?.includes("bimts.ids")
                ) {
                  await api.framework.installUpdateIds(
                    vintage,
                    "bimts_" + this.props.last_version
                  );
                } else {
                  await api.framework.installUpdate(
                    vintage,
                    "bimts_" + this.props.last_version
                  );
                }
              }}
            >
              {Wording.restart[language]}
            </Button>
            <br />
            <div style={{ marginTop: 10 }}>
              {Wording.updateDownloaded[language]} <br />
              {Wording.installInfo[language]} <br />
              <strong>{Wording.RevitRestart[language]}</strong>
            </div>
          </Segment>
        );
      }
      if (!sessionStorage.getItem("isActiveSeesion")) {
        return (
          <StoreNeedsUpdate
            version={version}
            language={language}
            loading={loading}
            versiontobe={last_version}
            config={this.props.config}
          />
        );
      } else if (sessionStorage.getItem("isActiveSeesion") === "true") {
        return (
          <LoadedStore
            language={language}
            version={version}
            selectPlugin={selectPlugin}
            revitVersion={this.state.revitVersion}
            plugins={this.state.plugins} // plugins
            translation={0}
            environment=""
            config={this.props.config}
          />
        );
      }
    }
  }
}
