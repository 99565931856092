import { errorCodeKey } from "./errorCodeEnum";

export const reverseDirectionRevitApi = (props: any) => {
  if (localStorage.getItem("isErrorThrown") === "True") {
    //localStorage.setItem("isErrorThrown", "False");
    props.onError(errorCodeKey.PB_IN_H_001, "stack", true);
  }
};

export const reverseSideRevitApi = (props: any) => {
  if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "true") {
    // localStorage.setItem("isErrorThrown", "False");
    props.onError(errorCodeKey.PB_PE_H_001, "stack", true);
  }
};

export const CalpinageRevitApi = (props: any) => {
  const isErrorThrown = localStorage.getItem("isErrorThrown");
  if (isErrorThrown) {
    if (isErrorThrown.toLowerCase() === "true") {
      localStorage.setItem("isErrorThrown", "False");
      console.log("error in CalepinageRevitapi");
      props.onError(errorCodeKey.PB_LT_H_001, "stack", true);
    }
  }
};

export const SuppressionRevitApi = (props: any) => {
  if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "true") {
    //localStorage.setItem("isErrorThrown", "False");
    props.onError(errorCodeKey.PB_DE_H_001, "stack", true);
  }
};

export const DimensionRevitApi = (props: any) => {
  if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "true") {
    // localStorage.setItem("isErrorThrown", "False");
    props.onError(errorCodeKey.PB_CT_L_001, "stack", true);
  }
};

export const QuantitatiffRevitApi = (props: any) => {
  if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "true") {
    // localStorage.setItem("isErrorThrown", "False");
    props.onError(errorCodeKey.PB_QU_H_001, "stack", true);
  }
};

export const ColorizationRevitApi = (props: any) => {
  if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "true") {
    //localStorage.setItem("isErrorThrown", "False");
    props.onError(errorCodeKey.PB_CO_L_001, "stack", true);
  }
};
