import { Language, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import {
    SET_SELECTION_TREE,
    SelectionTreeAction,
    SelectorAction,
    INIT_GROUP_SELECTOR,
    SELECT_LEVEL,
    SELECT_TYPE,
} from "../Actions/types";

import { TypeData, LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { levelData, elementTypes } from "../../../../RevitJS/Helpers";
import { FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";

import { LanguageAction, INIT_LANGUAGE } from "../../../../Actions/types";

export const language = (
    language: Language = "German",
    action: LanguageAction
): Language => {
    switch (action.type) {
        case INIT_LANGUAGE:
            return action.language;
        default:
            return language;
    }
};

export const functionalityIcon = (
    functionalityIcon = "/pluginIcons/riggibs-logo-icon.png",
    action: any
): string => {
    switch (action.type) {
        default:
            return functionalityIcon;
    }
};

export const selectionTree = (
    selectionTree: ElementsTree = { Tree: [] },
    action: SelectionTreeAction | SelectorAction
) => {
    switch (action.type) {
        case SET_SELECTION_TREE:
            return action.tree;
        case INIT_GROUP_SELECTOR:
            return action.tree;
        default:
            return selectionTree;
    }
};

export const levelsData = (
    levelsData: LevelData[] = [],
    action: SelectorAction
) => {
    switch (action.type) {
        case INIT_GROUP_SELECTOR:
            return action.tree.Tree.map((level) => ({
                Name: level.Level.Name,
                Checked: false,
            }));
        case SELECT_LEVEL:
            return action.levelsData;
        default:
            return levelsData;
    }
};

export const typesData = (
    typesData: TypeData[] = [],
    action: SelectorAction
) => {
    switch (action.type) {
        case INIT_GROUP_SELECTOR:
            return elementTypes(levelData(action.tree, [])) as TypeData[];
        case SELECT_LEVEL:
            return action.typesData;
        case SELECT_TYPE:
            return action.typesData;
        default:
            return typesData;
    }
};

export const selectorType = (selectorType: string = "wall", action: any) => {
    switch (action.type) {
        default:
            return selectorType;
    }
};

export const selectedTypes = (
    selectedTypes: string[] = [],
    action: SelectorAction
) => {
    switch (action.type) {
        case SELECT_LEVEL:
            return action.selectedTypes;
        case SELECT_TYPE:
            return action.selectedTypes;
        default:
            return selectedTypes;
    }
};

export const selectedLevels = (
    selectedLevels: string[] = [],
    action: SelectorAction
) => {
    switch (action.type) {
        case SELECT_LEVEL:
            return action.selectedLevels;
        default:
            return selectedLevels;
    }
};

export const filterTypeEnabled = (
    filterTypeEnabled: FilterTypeEnabled = "partitionsWalls",
    action: any
) => {
    switch (action.type) {
        default:
            return filterTypeEnabled;
    }
};

export const reducers = combineReducers({
    language,
    functionalityIcon,
    selectionTree,
    levelsData,
    typesData,
    selectedLevels,
    selectedTypes,
});

export interface DrawStore {
    language: Language;
    functionalityIcon: string;
    selectionTree: ElementsTree;
    levelsData: LevelData[];
    typesData: TypeData[];
    selectedLevels: string[];
    selectedTypes: string[];
}
