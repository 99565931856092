import React from "react";
import { App } from "./AppBody";
import "../Resources/quantifier-main.scss";

export enum Routes {
  ROOT = "ROOT",
  BIMMODEL = "BIMMODEL",
  EXISTINGSELECTION = "EXISTINGSELECTION",
  SELECTIONLANDING = "SELECTIONLANDING",
  WALLTABLECOMPONENT = "WALLTABLECOMPONENT",
  CEILINGTABLECOMPONENT = "CEILINGTABLECOMPONENT",
  SELECTIONLIST = "SELECTIONLIST",
  ZONELIST = "ZONELIST",
  RATIOTABLE = "RATIOTABLE",
  SELECTIONHOME = "SELECTIONHOME",
}

const Root = () => {
  const [route, setRoute] = React.useState(Routes.ROOT);
  return <App route={route} setRoute={setRoute} />;
};

export default Root;
