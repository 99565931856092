import React, { useState, useEffect, Component } from "react";
import {
    withErrorSubscription,
    InjectedCounterProps,
  } from "../../../../ErrorManagement/components/ErrorBoundry";
import AppBody from "./AppBody";
type Props = InjectedCounterProps;

export enum Routes {
  ROOT = "ROOT",
  MAPPING = "MAPPING",
  GROUP_SELECTION = "GROUP_SELECTION",
  PRODUCT_SELECTOR = "ProductSelector",
}

interface State {
    route: Routes;
}

const NomenclatureRoot = (props: any) => {
    const [route, setRoute] = useState(Routes.ROOT);

    const setRouteMetres = (route: Routes) => {
        setRoute(route);
    };

    useEffect(() => {
        // api.windowsHandler.showDevTools();
        const queryString = window.location.hash;
        const urlParams = new URLSearchParams(queryString.split("?")[1]);
        if (urlParams.get("token")) {
            localStorage.setItem("token", `${urlParams.get("token")}`);
        }
        if (urlParams.get("language")) {
            localStorage.setItem("savedLanguage", `${urlParams.get("language")}`);
        }
    }, []);

    return (
        <AppBody
        route = {route}
        setRoute = {setRouteMetres}
        />
    )

}
export default withErrorSubscription(NomenclatureRoot);



