import { TypeData } from "../../../../RevitJS/Types/StoreTypes";
import {
    setGroupTreesAction,
    selectLevelAction,
    selectTypeAction,
} from "../Actions/types";
import { elementTypes, levelData } from "../../../../RevitJS/Helpers";
import { api } from "../../../../RevitJS/API";

type wallTypesDataAction =
    | setGroupTreesAction
    | selectLevelAction
    | selectTypeAction;

export const wallTypesData = (
    typesData: TypeData[] = [],
    action: wallTypesDataAction
) => {
    switch (action.type) {
        case "SET_GROUP_TREES":
            return elementTypes(levelData(action.wallTree, [])) as TypeData[];
        case "SELECT_LEVEL":
            if (action.selectorType === "wall") {
                if (action.typesDataByLevel) {
                    return action.typesDataByLevel;
                } else {
                    return action.typesData;
                }
            }
            return typesData;
        case "SELECT_TYPE":
            if (action.selectorType === "wall") {
                if (action.typesDataByLevel) {
                    return action.typesDataByLevel;
                } else {
                    return action.typesData;
                }
            }
            return typesData;
        default:
            return typesData;
    }
};
