export const mergeWallType = (walls: any) => {
  const result: any = [];
  walls.forEach((object: any) => {
    const existing: any = result.filter(
      (item: any) => item.ElementName == object.ElementName
    );
    if (existing.length) {
      const existingIndex: any = result.indexOf(existing[0]);
      result[existingIndex].Ids = result[existingIndex].Ids.concat(object.Ids);
    } else {
      if (typeof object.Ids == "string") object.Ids = [object.Ids];
      result.push(object);
    }
  });
  return result;
};
