import React from "react";
import { Popup, Button, Input, Icon } from "semantic-ui-react";
import { faPencilAlt, faTrash } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPrintDate } from "../../../../Utils";

interface Props {
  data: any;
  onRowEdit: (id: any) => void;
  onRowDelete: (id: any) => void;
  editId: any;
  editName: any;
  setEditName: any;
  editCalpinageName: any;
  updateCalpinagename: any;
  errorMessage: any;
  setEditedNameValue: any;
}

export const HomeTableRow = (props: Props) => {
  const { data, onRowEdit, onRowDelete } = props;
  const { Name, Date, Id } = data;
  return (
    <div
      className="row-hover"
      style={{
        padding: "5px",
        minHeight: "25px",
        display: "flex",
        alignItems: "center",
        borderBottom: "0.5px solid white",
        cursor: "pointer",
      }}
    >
      {props.editId !== Id && (
        <Popup
          trigger={
            <div
              style={{
                justifyContent: "center",
                fontWeight: "bold",
                display: "block",
                width: "60%",
                color: "black",
                border: "0.2px",
                cursor: "pointer",
                wordWrap: "break-word",
                textAlign: "center",
                overflow: "hidden",
              }}
              onClick={() => {
                props.editCalpinageName(Id, Name.trim());
              }}
            >
              <div>{Name}</div>
            </div>
          }
          content="Cliquer pour modifier le nom/ 
        Click to edit name"
          on="hover"
          inverted
          size="mini"
          position="bottom center"
        />
      )}
      {props.editId === Id && (
        <Popup
          trigger={
            <div
              style={{
                justifyContent: "center",
                fontWeight: "bold",
                display: "block",
                width: "60%",
                color: "black",
                border: "0.2px",
                cursor: "pointer",
                wordWrap: "break-word",
                textAlign: "center",
                overflow: "hidden",
              }}
            >
              <div>
                <Input
                  type="text"
                  placeholder="Search..."
                  value={props.editName}
                  onChange={(event: any) => {
                    props.setEditName(event.target.value);
                    props.setEditedNameValue(event.target.value);
                  }}
                  action
                  style={{ width: "100%" }}
                >
                  <input />
                  <Popup
                    trigger={
                      <Button
                        icon
                        onClick={() => {
                          props.updateCalpinagename(Id, props.editName);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    }
                    content="Validate name change"
                    on="hover"
                    inverted
                    size="mini"
                    position="bottom center"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon
                        onClick={() => {
                          props.editCalpinageName("", "");
                        }}
                      >
                        <Icon name="close" />
                      </Button>
                    }
                    content="Undo name change"
                    on="hover"
                    inverted
                    size="mini"
                    position="bottom center"
                  />
                </Input>
                {props.errorMessage !== "" && (
                  <div style={{ fontSize: "9px", color: "red" }}>
                    {props.errorMessage}
                  </div>
                )}
              </div>
            </div>
          }
          content="Click to edit name"
          on="hover"
          inverted
          size="mini"
          position="bottom center"
        />
      )}
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          width: "30%",
          color: "black",
          display: "flex",
        }}
      >
        <div>{getPrintDate(Date)}</div>
      </div>
      <div
        style={{
          width: "10%",
          justifyContent: "center",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <div
          className="editIcon"
          style={{
            marginRight: "6px",
            display: "inline-flex",
          }}
          onClick={(index) => onRowEdit(Id)}
        >
          <Popup
            trigger={<FontAwesomeIcon icon={faPencilAlt as IconProp} />}
            content="Modifier"
            on="hover"
            inverted
            size="mini"
            position="bottom center"
          />
        </div>
        <div
          className="trashIcon"
          style={{
            display: "inline-flex",
            marginLeft: "10px",
          }}
          onClick={(index) => onRowDelete(Id)}
        >
          <Popup
            trigger={<FontAwesomeIcon icon={faTrash as IconProp} />}
            content="Supprimer"
            on="hover"
            inverted
            size="mini"
            position="bottom right"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeTableRow;
