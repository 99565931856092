import React, { Component } from "react";
import "./CLTDraw2Styles.scss";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import { Routes } from "./root";
import { fetchRigipsRemoteData, initLanguage } from "../Actions";
import SelectionLanding from "./SelectionLanding";
import GroupSelector from "./GroupSelector";
import SolutionMapping from "./SolutionMapping";
import ProductsPage from "./ProductsPage";
import { withTranslation, WithTranslation } from "react-i18next";
import { setConfig } from "../../Delete/Actions";
import { MyConfig } from "../../../../Helper";

interface Props {
  route: Routes;
  setRoute: any;
  fetchRigipsRemoteData: any;
  initLanguage: any;
  currentLanguage: any;
  setConfig: any;
}

export class AppBody extends Component<Props & WithTranslation> {
  componentDidMount = async () => {
    const cng = await MyConfig();
    this.props.setConfig(cng);

    this.props.initLanguage();
    if (cng && Object.keys(cng).length > 0) {
      this.props.fetchRigipsRemoteData(cng);
    }

    setTimeout(() => {
      let selectedLanguage = localStorage.getItem("savedLanguage");
      if (selectedLanguage !== "English" && selectedLanguage !== "French") {
        this.props.i18n.changeLanguage("English");
      } else {
        this.props.i18n.changeLanguage(selectedLanguage);
      }
      //this.props.i18n.changeLanguage(this.props.currentLanguage);
    });
  };

  render() {
    switch (this.props.route) {
      case Routes.ROOT:
        return (
          <SelectionLanding
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      case Routes.GROUP_SELECTION:
        return (
          <GroupSelector
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      case Routes.MAPPING:
        return <SolutionMapping setRoute={this.props.setRoute} />;
      case Routes.PRODUCT_SELECTOR:
        return <ProductsPage setRoute={this.props.setRoute} />;
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    currentLanguage: state.language,
  };
};

export default connect(mapStateToProps, {
  fetchRigipsRemoteData,
  initLanguage,
  setConfig,
})(withTranslation(["clt"])(AppBody));
