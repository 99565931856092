import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { MyConfig } from "../../Helper";

const Wording: any = {
  message: {
    French: "Une nouvelle version est désormais disponible",
    English: "A new version is now available",
    German: "Eine neue Version ist jetzt verfügbar",
    Italian: "Una nuova versione è ora disponibile",
    Indonesian: "Versi baru sekarang tersedia",
  },
  update: {
    French: "Mettre à jour",
    English: "Update",
    German: "Aktualisieren",
    Italian: "Aggiornare",
    Indonesian: "Pemutakhiran",
  },
};

const UpdatePanel = (props: any) => {
  const goToUpdate = async () => {
    sessionStorage.removeItem("isActiveSeesion");
    let cng = await MyConfig();
    window.location.href = cng.REACT_APP_AUTHENTICATIONURL + `` + cng.REACT_APP_LOGIN_REDIRECT;
  }
  return (
    <>
      <span style={{ color: "rgba(30,30,30,255)" }}>
        {Wording.message[props.language]}
      </span>

      <Button
        secondary
        size="mini"
        style={{ backgroundColor: " rgba(91,91,91,255)", padding: " 5px 10px" }}
        onClick={() => goToUpdate()}
      >
        {Wording.update[props.language]}
      </Button>
    </>
  );
};

export default UpdatePanel;
