import { bimStorage, storageKey } from "../../../../BIMStore";

export async function getCurrentConfiguration() {
  let propertiesToReturn: any | null = null;
  propertiesToReturn = await bimStorage.getItem(
    storageKey.WEBERINDONESIA_PROPERTIES_NEW
  );
  return propertiesToReturn;
}

export async function savePropertiesConfiguration(
  properties: any,
  isDefaultConfiguration: boolean
) {
  bimStorage.setItem(storageKey.WEBERINDONESIA_PROPERTIES_NEW, JSON.stringify([]));
  properties = {
    wallsSystemProperties: properties.wallsSystemProperties.filter(
      (prop: any) => prop.categoryName !== "PRICE"
    ),
    floorSystemProperties: properties.floorSystemProperties.filter(
      (prop: any) => prop.categoryName !== "PRICE"
    ),
    // ceilingsProperties: properties.ceilingsProperties.filter(
    //   (prop: any) => prop.categoryName !== "PRICE"
    // ),
    // liningWallsProperties: properties.liningWallsProperties.filter(
    //   (prop: any) => prop.categoryName !== "PRICE"
    // ),
    // partitionsProperties: properties.partitionsProperties.filter(
    //   (prop: any) => prop.categoryName !== "PRICE"
    // ),
  };

  // await db.transaction("rw", [db.properties], async () => {
  //   return await db.properties.add({
  //     propertiesConfiguration: { ...properties },
  //     isDefaultConfiguration: isDefaultConfiguration,
  //   });
  //   // }
  // });

  bimStorage.setItem(
    storageKey.WEBERINDONESIA_PROPERTIES_NEW,
    JSON.stringify({
      propertiesConfiguration: { ...properties },
      isDefaultConfiguration: isDefaultConfiguration,
    })
  );
  return properties;
}
