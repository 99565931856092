import { WALLSYSTEM_ID, FLOORSYSTEM_ID } from "../../assets/constants";
import {
  FavoriteState,
  FavoriteActionTypes,
  ADD_SYSTEM_TO_FAVORITE,
  REMOVE_SYSTEM_FROM_FAVORITE,
  CHANGE_TYPE_ID,
  LOAD_SYSTEMS_FAVORITES,
  CLEAR_SYSTEMS_FAVORITES
} from "./types";

export const initialState: FavoriteState = {
  currentFavoriteSystemItems: [],
  data: {
    wallsSystemDetails: [],
    floorSystemDetails: [],
  },
  favoriteCount: 0
};

export default function favoriteReducer(
  state = initialState,
  action: FavoriteActionTypes
) {
  switch (action.type) {
    case ADD_SYSTEM_TO_FAVORITE:
      return {
        ...state,
        currentFavoriteSystemItems: action.systemTypeID === action.currentSystemTypeID ? [...state.currentFavoriteSystemItems, action.favoriteSystemItem] : [...state.currentFavoriteSystemItems],
        data: {
          wallsSystemDetails: action.systemTypeID === WALLSYSTEM_ID ? [...state.data.wallsSystemDetails, action.favoriteSystemItem] : [...state.data.wallsSystemDetails],
          floorSystemDetails: action.systemTypeID === FLOORSYSTEM_ID ? [...state.data.floorSystemDetails, action.favoriteSystemItem] : [...state.data.floorSystemDetails],
        },
        favoriteCount: state.favoriteCount + 1
      };
    case REMOVE_SYSTEM_FROM_FAVORITE:
      return {
        ...state,
        currentFavoriteSystemItems: action.systemTypeID === action.currentSystemTypeID ? [...state.currentFavoriteSystemItems.slice(0, action.index),
        ...state.currentFavoriteSystemItems.slice(action.index + 1)] : [...state.currentFavoriteSystemItems],
        data: {
          wallsSystemDetails: action.systemTypeID === WALLSYSTEM_ID ? [...state.data.wallsSystemDetails.slice(0, action.index),
          ...state.data.wallsSystemDetails.slice(action.index + 1)] : [...state.data.wallsSystemDetails],

          floorSystemDetails: action.systemTypeID === FLOORSYSTEM_ID ? [...state.data.floorSystemDetails.slice(0, action.index),
          ...state.data.floorSystemDetails.slice(action.index + 1)] : [...state.data.floorSystemDetails],
        },
        favoriteCount: state.favoriteCount - 1
      };
    case CHANGE_TYPE_ID:
      return {
        ...state,
        currentFavoriteSystemItems: action.favoriteSystemItems || [],
      };
    case LOAD_SYSTEMS_FAVORITES:
      return {
        ...state,
        currentFavoriteSystemItems: action.systemTypeID === WALLSYSTEM_ID ? [...action.data.wallsSystemDetails] : [...action.data.floorSystemDetails],
        data: { ...action.data },
        favoriteCount: action.favoriteCount
      };
    case CLEAR_SYSTEMS_FAVORITES:
      return {
        currentFavoriteSystemItems: [],
        data: {
          wallsSystemDetails: [],
          floorSystemDetails: [],
        },
        favoriteCount: 0
      }
    default:
      return state;
  }
}
