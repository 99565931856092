
import _, { } from "lodash";
import React, { Fragment, useState } from "react";
import { Button, Grid, SemanticWIDTHS } from "semantic-ui-react";
import { RowOptions, SelectionGroup } from "../../../RevitJS/Types/StoreTypes";
import { HeaderComponent } from "./common/headerComponent";
import { ExportModuleComponent } from "./exportModuleComponent";

interface Props {
  route: string;
  setRoute: any;
  translations: any;
  language: string;
  pluginName?: string;
}


export type Selection<T extends RowOptions> = {
  Name: string;
  Id: string;
  Duplicated: number;
  Zone: boolean;
  SelectionByType: {
    wall: SelectionGroup<T>;
    ceiling: SelectionGroup<T>;
    others: SelectionGroup<T>;
  };
  Date: string;
  Levels: string[];
  Update: boolean;
  Color: any;
  RevitView?: string;
  Time: number;
};



export const ExportComponent = (prop: Props) => {
  const [moduleName, setModuleName] = useState<string>("");
  const moduleList = [
    { name: prop.translations[prop.language].selection, key: 'Selection' },
    { name: prop.translations[prop.language].calepinage, key: 'Calepinage' }];
  
  if (!prop.pluginName){
    moduleList.splice(1, 0, { name: prop.translations[prop.language].dossier, key: 'Dossier' });
  }


  const openModule = (name: string) => {
    setModuleName(name);
  }

  if (moduleName !== "") {
    return <ExportModuleComponent moduleName={moduleName} setModuleName={setModuleName} pluginName={prop.pluginName} route={prop.route} setRoute={prop.setRoute} translations={prop.translations} language={prop.language} />
  }

  return (
    <div style={{ height: "100%" }}>
      <HeaderComponent
        Icon={prop.pluginName==="Gyproc" ? "/plugin_new_logo/BeneluxIcon.png" : "/PlacoBIM.jpg"}
        name={prop.translations[prop.language].header.export}
        subheader=""
      ></HeaderComponent>
      <Grid columns={moduleList.length as SemanticWIDTHS} padded={true}>
        <Grid.Row style={{ padding: "2.5rem 0.5rem 1.5rem" }}>
          <Fragment>
            {moduleList.map((item: any, i: number) => {
              return (
                <Grid.Column>
                  <Button key={i} style={{ minHeight: 60 }} fluid
                    color="blue" onClick={() => openModule(item.key)}>
                    {item.name}
                  </Button>
                </Grid.Column>
              );
            })
            }
          </Fragment>
        </Grid.Row>
      </Grid>

    </div>
  );
}

