import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Message,
  Segment,
  Input,
  Icon,
  Form,
} from "semantic-ui-react";
import { MyConfig } from "../../../../Helper";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import PaginationComponent from "../../Common/Components/Pagination/pagination";
import ProductTable from "./ProductTable";
import { Routes } from "./root";

interface Props {
  setRoute: any;
}

const ProductList = (props: Props) => {
  const [config, setConfig] = useState({REACT_APP_SERVERURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])
  
  return (
    <>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/ModelGenerator/plugin-logo.png"}
        name={"Object Generator"}
        subheader={"From Database / XLS"}
        config={config}
      />
      <Container style={{ width: "95%" }}>
        <Segment className="fluid segment p-0">
          <Form>
            <Form.Group widths="two">
              <Form.Input
                type="text"
                fluid
                icon="search"
                placeholder="Search..."
                label="Keyword Search : "
              />
              <Button.Group style={{ alignItems: "flex-end" }}>
                <Button color="green" className="width-80-px">
                  Success
                </Button>
                <Button color="red" className="width-80-px">
                  Error
                </Button>
                <Button color="blue" className="width-80-px">
                  All
                </Button>
              </Button.Group>
            </Form.Group>

            <PaginationComponent pageObj={undefined}></PaginationComponent>
          </Form>
          <div
            style={{
              height: "calc(100vh - 230px)",
              overflowY: "auto",
              marginTop: "30px",
            }}
          >
            <ProductTable></ProductTable>
          </div>
          <Segment className={"noExcelCont"}>
            <Icon name="info circle" size="big"></Icon>
            <h4>No data found</h4>
          </Segment>
          <Segment
            className="form-btn-block"
            style={{ position: "sticky", bottom: "0px", padding: "0 0 10px" }}
          >
            {/* <Message attached="bottom" error>
              <Icon name="attention" />
              Select a product to proceed
            </Message> */}
            <Segment style={{ textAlign: "right", padding: "0" }}>
              <Button color="red" onClick={() => props.setRoute(Routes.ROOT)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => props.setRoute(Routes.SELECTED_PRODUCTS)}
              >
                Proceed
              </Button>
            </Segment>
          </Segment>
        </Segment>
      </Container>
    </>
  );
};

export default ProductList;
