import _, {
  ceil,
  concat,
  forEach,
  groupBy,
  includes,
  map,
  round,
  sortBy,
  find,
  filter,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";
import {
  addGlobalZoneAccessoryInSummarySheet,
  addGlobalZoneMontantInSummarySheet,
  addGlobalZonePlqsInSummarySheet,
  addGlobalZoneRailInSummarySheet,
  addGlobalZoneWoolInSummarySheet,
  addImagetoWorkbook,
  alternativeUnitsSelector,
  dbSalesUnit,
  distributionChannelsAttributeSelector,
  findProductToProduct,
  getAttValue,
  newConditionedQuantityForMontant,
  newConditionedQuantityForPlaq,
  newConditionedQuantityForRail,
  oneToOneCommandeMapping,
  packageAttributeSelector,
  printNullOrZero,
  printSummaryZoneDetailRow,
  relationAttributeSelector,
  setColumnType,
  setDossierTechnicalInfo,
  setSolidFill,
  uniteDeVenteArray,
  uniteDeVenteToUnit,
  unitTobePrinted,
} from "./helper";
import { woolType } from "./type";
import { setCellAlignment, setCellBorder, setCellFont } from "./excelUI";
import {
  Accessory,
  AccessoryArticle,
  DetailCommande,
  GlobalCommande,
  GlobalSummary,
  Montant,
  MontantArticle,
  PlaqArticle,
  Plaqs,
  PlaqSummary,
  RailArticle,
  Rails,
  SummaryDetail,
  SummaryGroupDetail,
} from "../Actions/types";
import { saveAs } from "file-saver";
import {
  ICollectedArticle,
  IRationTable,
} from "../Components/TypeOfQuantitatifs/RatioTable";
import { wait } from "../../../../RevitJS/Helpers";
import articleunit from "../Util/articleunit.json";
import { liningWallsDetails } from "../../../PlacoBIM/Draw02/Reducers";

export async function asyncForEach(array: any, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export const writeOptimizedZoneBooks = async (
  props: any,
  getUnitConversionArray: any,
  config: any,
  collectedArticl: ICollectedArticle,
  useCustomRatio: boolean
) => {
  // props.setUnitConversionArray([], "");
  // props.setWallScheduleLoader(true);
  let unitConversionArray = getUnitConversionArray();
  props.setUnitConversionArray = (conversionArray: any, element: any) => {
    map(conversionArray, (unitObj: any, unitObjIndex: any) => {
      if (
        !find(unitConversionArray, {
          alternativeUnit: unitObj.alternativeUnit,
          ub: unitObj.ub,
          element: element,
        })
      ) {
        unitConversionArray = [
          ...unitConversionArray,
          { ...unitObj, element: element },
        ];
      }
    });
  };

  /// 9. Get object params (i.e. ["Processed", "SG_System"]) of wall

  const workbook = new Excel.Workbook();

  let blob = await fetch(
    config.REACT_APP_SERVERURL + "/Excel/quantitatiff_per_zone_v2.xlsx"
  ).then((response) => response.arrayBuffer());

  await workbook.xlsx.load(blob).then(async (workbookObject) => {
    // Wool

    const logoImageId = await addImagetoWorkbook(workbook, config);

    writeRatioNew(
      workbookObject,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const woolSummary = await writeWoolNew(
      workbookObject,
      logoImageId,
      collectedArticl.collected_wool as woolType[],
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const accessorySummary = await writeAccessoryNew(
      workbookObject,
      logoImageId,
      collectedArticl.collected_accessory,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const railSummary = await writeRailNew(
      workbookObject,
      logoImageId,
      collectedArticl.collected_rail,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const montantSummary = await writeMontantNew(
      workbookObject,
      logoImageId,
      collectedArticl.collected_montant,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const plaqueSummary = await writePlaquesNew(
      workbookObject,
      logoImageId,
      collectedArticl.collected_plaques,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    await writeDetailNext(
      workbookObject,
      logoImageId,
      collectedArticl.collected_accessory,
      collectedArticl.finalExtract,
      plaqueSummary,
      montantSummary,
      railSummary,
      woolSummary,
      accessorySummary,
      collectedArticl.mapZoneWall,
      props
    );

    const detailSummary = await writeSummary(
      workbookObject,
      collectedArticl.finalExtract,
      plaqueSummary,
      montantSummary,
      railSummary,
      woolSummary,
      accessorySummary,
      props,
      logoImageId,
      collectedArticl.mapZoneWall,
      config
    );

    // let unitConversionArray = getUnitConversionArray();

    await writeCommandeNew(
      workbook,
      detailSummary,
      unitConversionArray,
      logoImageId,
      config
    );

    const buf = await workbookObject.xlsx.writeBuffer();

    const documentName = await api.queries.getActiveDocumentName();
    const d = new Date();
    let monthNumber = d.getMonth() + 1;
    let month =
      monthNumber.toString().length === 1 ? "0" + monthNumber : monthNumber;
    let dayNumber = d.getDate();
    let day = dayNumber.toString().length === 1 ? "0" + dayNumber : dayNumber;
    const fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Quantitatifs_par_étage_par_zone`;

    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  });
  await sendEvent();
  await wait(1000);

  // props.setWallScheduleLoader(false);
};

export const writeOptimizedZoneBooksForQuantitatifs = async (
  props: any,
  getUnitConversionArray: any,
  config: any,
  collectedArticl: ICollectedArticle,
  useCustomRatio: boolean
) => {
  // props.setUnitConversionArray([], "");
  // props.setWallScheduleLoader(true);
  let unitConversionArray = getUnitConversionArray();

  props.setUnitConversionArray = (conversionArray: any, element: any) => {
    map(conversionArray, (unitObj: any, unitObjIndex: any) => {
      if (
        !find(unitConversionArray, {
          alternativeUnit: unitObj.alternativeUnit,
          ub: unitObj.ub,
          element: element,
        })
      ) {
        unitConversionArray = [
          ...unitConversionArray,
          { ...unitObj, element: element },
        ];
      }
    });
  };

  /// 9. Get object params (i.e. ["Processed", "SG_System"]) of wall

  const workbook = new Excel.Workbook();

  let blob = await fetch(
    config.REACT_APP_SERVERURL + "/Excel/quantitatiff_per_zone.xlsx"
  ).then((response) => response.arrayBuffer());

  await workbook.xlsx.load(blob).then(async (workbookObject) => {
    // Wool

    const logoImageId = await addImagetoWorkbook(workbook, config);

    writeRatio(workbookObject, collectedArticl.default_ratio, useCustomRatio);

    const woolSummary = await writeWool(
      workbookObject,
      logoImageId,
      collectedArticl.collected_wool as woolType[],
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const accessorySummary = await writeAccessory(
      workbookObject,
      logoImageId,
      collectedArticl.collected_accessory,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const railSummary = await writeRail(
      workbookObject,
      logoImageId,
      collectedArticl.collected_rail,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const montantSummary = await writeMontant(
      workbookObject,
      logoImageId,
      collectedArticl.collected_montant,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const plaqueSummary = await writePlaques(
      workbookObject,
      logoImageId,
      collectedArticl.collected_plaques,
      collectedArticl.default_ratio,
      useCustomRatio
    );

    const detailSummary = await writeSummary(
      workbookObject,
      collectedArticl.finalExtract,
      plaqueSummary,
      montantSummary,
      railSummary,
      woolSummary,
      accessorySummary,
      props,
      logoImageId,
      collectedArticl.mapZoneWall,
      config
    );

    //let unitConversionArray = getUnitConversionArray();

    await writeCommandeNew(
      workbook,
      detailSummary,
      unitConversionArray,
      logoImageId,
      config
    );

    const buf = await workbookObject.xlsx.writeBuffer();

    const documentName = await api.queries.getActiveDocumentName();
    const d = new Date();
    let monthNumber = d.getMonth() + 1;
    let month =
      monthNumber.toString().length === 1 ? "0" + monthNumber : monthNumber;
    let dayNumber = d.getDate();
    let day = dayNumber.toString().length === 1 ? "0" + dayNumber : dayNumber;
    const fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Quantitatifs_par_étage_par_zone`;

    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  });
  await sendEvent();
  await wait(1000);

  // props.setWallScheduleLoader(false);
};

const sendEvent = async () => {
  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Quantitatifs",
    module: "PLACOBIM",
  });
};

const writeRatio = async (
  workbookObject: Excel.Workbook,
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let woolSheet = workbookObject.getWorksheet("Ratio");
  const sortedDefaultRation = sortBy(defaultRatio, ["order", "nameArticle"]);
  let rowNumber = 2;
  _.forEach(sortedDefaultRation, (deftRatio: IRationTable) => {
    let row = woolSheet.getRow(rowNumber);

    row.values = [
      deftRatio.typeArticle,
      deftRatio.nameArticle,
      deftRatio.ratioDefault === 0 ? "NaN" : deftRatio.ratioDefault,
      deftRatio.rationCustom && useCustomRatio
        ? parseFloat(deftRatio.rationCustom.toString())
        : deftRatio.ratioDefault,
    ];

    row.eachCell(function (cell: any, colNumber: number) {
      let num: any = cell.value;

      if (num === "NA") {
        cell.value = "";
      } else {
        setCellAlignment(cell, "center", "middle");
      }

      setCellBorder(cell, true);
      setCellFont(cell, "Arial", 8, false, "black");
    });

    row.commit();
    rowNumber = rowNumber + 1;
  });
};

const writeWool = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  woolCollection: woolType[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let woolSheet = workbookObject.getWorksheet("Produits isolants");
  // logoImageId !== 0 && woolSheet.addImage(logoImageId, "H1:J4");
  logoImageId !== 0 &&
    woolSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(woolSheet, "D", "H");

  /**
   * Sort wools by level -> solution
   */
  let woolArray: woolType[] = _.orderBy(
    woolCollection,
    ["LevelElevation", "zone", "Solution", "WallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let woolSummary: any = [];
  if (woolArray.length > 0) {
    let rowNumber = 16;
    _.forEach(woolArray, (wool: woolType, index: number) => {
      let row = woolSheet.getRow(rowNumber);
      // let qColumn = round(wool.WoolArea / parseFloat(wool.SellingPackage), 2);

      let woolRatio = wool.Ratio;
      if (useCustomRatio) {
        const woolRatioDefaultObject = defaultRatio.find((defRatio) => {
          return defRatio.nameArticle === wool.ProductName;
        });

        if (woolRatioDefaultObject) {
          if (woolRatioDefaultObject.rationCustom) {
            woolRatio = woolRatioDefaultObject.rationCustom;
          } else {
            woolRatio = woolRatioDefaultObject.ratioDefault;
          }
        }
      }

      const woolArea = round(
        wool.ElementArea * parseFloat(woolRatio.toString()),
        2
      );

      let qColumn = round(woolArea / parseFloat(wool.SellingPackage), 2) || 0;
      qColumn = qColumn === Infinity ? 0 : qColumn;

      row.values = [
        wool.Level,
        wool.Zone || "",
        wool.Ovurage,
        printNullOrZero(wool.ElementHeight),
        printNullOrZero(wool.ElementArea),
        wool.ElementId,
        wool.ProductName,
        parseFloat(woolRatio.toString()),
        printNullOrZero(woolArea),
        // wool.Rolls,
        qColumn === 0 ? "" : qColumn,
        wool.LabelUnit,
      ];

      row.eachCell(function (cell: any, colNumber: number) {
        let num: any = cell.value;

        if (num === "NA") {
          cell.value = "";
        } else {
          setCellAlignment(cell, "center", "middle");
        }

        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 8, false, "black");

        if (colNumber === 6) {
          setColumnType(cell);
        }
      });

      row.commit();

      woolSummary.push({
        level: wool.Level,
        productName: wool.ProductName,
        elementId: wool.ElementId,
        ratio: woolRatio,
        quantity: round(wool.ElementArea * woolRatio, 2),
        unite: "M2",
        quantityConditioned: 0,
        saleUnit: "",
        solution: wool.Ovurage,
        walltype: wool.ElementType,
        zone: wool.Zone,
        sellingPackage: wool.SellingPackage,
        labelUnit: wool.LabelUnit,
        ean: wool.Ean,
        quantitySecond: qColumn === 0 ? "" : qColumn,
      });

      rowNumber = rowNumber + 1;
    });
  }
  return woolSummary;
};

const writeDetailNext = async (
  workbookObject: Excel.Workbook,
  logoImageId: any,
  collected_articles: any,
  finalExtract: any,
  plaqSummary: any,
  montantSummary: any,
  railSummary: any,
  woolSummary: any,
  accessorySummary: any,
  mapZoneWall: any,
  props: any
): Promise<any> => {
  let detailSheet = workbookObject.getWorksheet("Détail");
  let finalArray: any = [];

  logoImageId !== 0 &&
    detailSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });

  await setDossierTechnicalInfo(detailSheet, "D", "H");

  finalArray = [
    // ...collected_articles,
    ...plaqSummary,
    ...montantSummary,
    ...railSummary,
    ...woolSummary,
    ...accessorySummary,
  ];

  //  let strAscending = finalArray.sort((a: any, b: any) =>
  //   b.level > a.level ? 1 : -1,
  // );
  console.log(finalArray);

  let strAscending = finalExtract.sort((a: any, b: any) =>
    a.wallDetails.Id > b.wallDetails.Id ? 1 : -1
  );

 let partialF = finalExtract.map((item: any)=> {
  return{area: item.wallDetails.Area, height: (item.wallDetails.Height)/1000, Id: item.wallDetails.Id, levelName: item.wallDetails.LevelName, solutions: item.placoSolution.translation,
        zone: item.wallDetails.zone};
 })



 partialF.map((par: any) => {
  let eleArray: any = []
  finalArray.forEach((fin: any) => {
    if(par.Id === fin.elementId) {
      eleArray = concat(eleArray, fin)
    }
  })
  par.elements = eleArray
  return par
 })
 console.log(partialF)
 console.log(finalExtract);

  // const result = groupBy(finalArray, "elementId");

  let rowNumber: number = 16;
  let tempIndex = 0;

  await asyncForEach(partialF, async (item: any) => {
    const dataExtract = finalExtract[tempIndex];
    let row = detailSheet.getRow(rowNumber);
    row.values = [
      item.levelName,
      item.zone,
      item.solutions,
      item.height,
      item.area,
      item.Id,
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    row.eachCell(function (cell: any, colNumber: number) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "D9D9D9",
        },
      };

      setCellBorder(cell, true);
      setCellAlignment(cell, "center", "middle");
      setCellFont(cell, "Arial", 8, true, "black");
      if (colNumber === 6) {
        setColumnType(cell);
      }
    });
    rowNumber = rowNumber + 1;
    await asyncForEach(item.elements, async (ele: any) => {
      if(ele.plaq){
        let nValue = dataExtract.plaques.length;
        for (var p = 0; p < nValue; p++) {
          const tpp = dataExtract.plaques[p].articles;
          const selectedPackagedArticle = _.find(tpp, (object) => {
            return object.value ===ele.plaq;
          });

          if (selectedPackagedArticle) {
            // let q2 = round((dataObj[data].quantity * dataObj[data].plaqLength * dataObj[data].plaqWidth), 2)
            let q2 = round(ele.ratio * item.area, 2);

            const attributeDescriptors = dataExtract.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            const relationAttributeAids = relationAttributeSelector(
              dataExtract.relationAttributes
            );

            const distributionChannelsAids =
              distributionChannelsAttributeSelector(attributeDescriptors);

            const alternativeUnitsAids =
              alternativeUnitsSelector(attributeDescriptors);

            const articleAttribute =
              selectedPackagedArticle.packagedArticle.attributes;

            const dbsalesunit = dbSalesUnit(
              distributionChannelsAids,
              articleAttribute
            );

            const saleunit = _.find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);

            let salesunit = "";
            if (dbsalesunit) {
              salesunit = uniteDeVenteArray(dbsalesunit);
            } else {
              if (saleunit) {
                const saleunit_value = _.get(saleunit.values[0], "value");
                salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
              }
            }

            let finalunite = uniteDeVenteArray(
              findProductToProduct(
                dataExtract.relations,
                dataExtract.plaques[p].product.oid,
                relationAttributeAids.unite,
                dataExtract.placoSolution.oid
              )
            );

            let j = "";
            if (finalunite !== "") {
              j = uniteDeVenteToUnit(finalunite);
            } else {
              j = ele.quantityFirstUnit;
            }

            const conditionedQuantity = await newConditionedQuantityForPlaq(
              alternativeUnitsAids,
              articleAttribute,
              uniteDeVenteToUnit(finalunite),
              q2,
              salesunit,
              props
            );
            ///End

            let rowsd = detailSheet.getRow(rowNumber);
            rowsd.values = [
              "",
              "",
              "",
              "",
              "",
              "",
              ele.plaq,
              ele.ratio,
              q2,
              j,
              conditionedQuantity,
              salesunit,
            ];

            rowsd.eachCell(function (cell: any, colNumber: number) {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              setCellFont(cell, "Arial", 8, false, "black");
              setCellAlignment(cell, "center", "middle");
            });
            rowNumber = rowNumber + 1;
          }
        }
        
        console.log(ele);
      }
      if(ele.montant){
        let nValue = dataExtract.montants.length;
        for (var m = 0; m < nValue; m++) {
          const tpp = dataExtract.montants[m].articles;
          const selectedPackagedArticle = _.find(tpp, (object) => {
            return object.value === ele.montant;
          });
          if (selectedPackagedArticle) {
            // let q2 =round(dataObj[data].quantity * parseFloat(dataObj[data].montantLength),2);
            let q2 = round(ele.ratio * item.area, 2);

            const attributeDescriptors = dataExtract.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            const relationAttributeAids = relationAttributeSelector(
              dataExtract.relationAttributes
            );

            const distributionChannelsAids =
              distributionChannelsAttributeSelector(attributeDescriptors);

            const alternativeUnitsAids =
              alternativeUnitsSelector(attributeDescriptors);

            const articleAttribute =
              selectedPackagedArticle.packagedArticle.attributes;

            const dbsalesunit = dbSalesUnit(
              distributionChannelsAids,
              articleAttribute
            );

            const saleunit = _.find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);

            let salesunit = "";
            if (dbsalesunit) {
              salesunit = uniteDeVenteArray(dbsalesunit);
            } else {
              if (saleunit) {
                const saleunit_value = _.get(saleunit.values[0], "value");
                salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
              }
            }

            let finalunite = uniteDeVenteArray(
              findProductToProduct(
                dataExtract.relations,
                dataExtract.montants[m].product.oid,
                relationAttributeAids.unite,
                dataExtract.placoSolution.oid
              )
            );

            let j = "";
            if (finalunite !== "") {
              j = uniteDeVenteToUnit(finalunite);
            } else {
              j = ele.quantityFirstUnit;
            }

            const conditionedQuantity = await newConditionedQuantityForMontant(
              alternativeUnitsAids,
              articleAttribute,
              uniteDeVenteToUnit(finalunite),
              q2,
              salesunit,
              props
            );
            //end
            let rowsd = detailSheet.getRow(rowNumber);
            rowsd.values = [
              "",
              "",
              "",
              "",
              "",
              "",
              ele.montant,
              ele.ratio,
              q2,
              j,
              conditionedQuantity,
              salesunit,
            ];

            rowsd.eachCell(function (cell: any, colNumber: number) {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              setCellFont(cell, "Arial", 8, false, "black");
              setCellAlignment(cell, "center", "middle");
            });
            rowNumber = rowNumber + 1;
          }
        }
      }
      if(ele.rail){
        let nValue = dataExtract.rails.length;
        for (var r = 0; r < nValue; r++) {
          const tpp = dataExtract.rails[r].articles;
          const selectedPackagedArticle = _.find(tpp, (object) => {
            return object.value === ele.rail;
          });

          if (selectedPackagedArticle) {
            // let q2 = round(dataObj[data].quantity * parseFloat(dataObj[data].railLength), 2)
            let q2 = round(ele.ratio * item.area, 2);

            const attributeDescriptors = dataExtract.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            const relationAttributeAids = relationAttributeSelector(
              dataExtract.relationAttributes
            );

            const distributionChannelsAids =
              distributionChannelsAttributeSelector(attributeDescriptors);

            const alternativeUnitsAids =
              alternativeUnitsSelector(attributeDescriptors);

            const articleAttribute =
              selectedPackagedArticle.packagedArticle.attributes;

            const dbsalesunit = dbSalesUnit(
              distributionChannelsAids,
              articleAttribute
            );

            const saleunit = _.find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);

            let salesunit = "";
            if (dbsalesunit) {
              salesunit = uniteDeVenteArray(dbsalesunit);
            } else {
              if (saleunit) {
                const saleunit_value = _.get(saleunit.values[0], "value");
                salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
              }
            }

            let finalunite = uniteDeVenteArray(
              findProductToProduct(
                dataExtract.relations,
                dataExtract.rails[r].product.oid,
                relationAttributeAids.unite,
                dataExtract.placoSolution.oid
              )
            );

            let j = "";
            if (finalunite !== "") {
              j = uniteDeVenteToUnit(finalunite);
            } else {
              j = ele.quantityFirstUnit;
            }

            const conditionedQuantity = await newConditionedQuantityForRail(
              alternativeUnitsAids,
              articleAttribute,
              uniteDeVenteToUnit(finalunite),
              q2,
              salesunit,
              props
            );
            //end
            let rowsd = detailSheet.getRow(rowNumber);
            rowsd.values = [
              "",
              "",
              "",
              "",
              "",
              "",
              ele.rail,
              ele.ratio,
              q2,
              j,
              conditionedQuantity,
              salesunit,
            ];

            rowsd.eachCell(function (cell: any, colNumber: number) {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              setCellFont(cell, "Arial", 8, false, "black");
              setCellAlignment(cell, "center", "middle");
            });
            rowNumber = rowNumber + 1;
          }
        }
        console.log(ele);
      }
      if(ele.productName){
        console.log(ele);
        let qColumn =
          round(
            ele.quantity / parseFloat(ele.sellingPackage),
            2
          ) || 0;
        qColumn = qColumn === Infinity ? 0 : qColumn;

        let row = detailSheet.getRow(rowNumber);
        row.values = [
          "",
          "",
          "",
          "",
          "",
          "",
          ele.productName,
          ele.ratio,
          ele.quantity,
          "M2",
          qColumn === 0 ? "" : qColumn,
          ele.labelUnit,
        ];

        row.eachCell(function (cell: any, colNumber: number) {
          let num: any = cell.value;

          if (num === "NA") {
            cell.value = "";
          } else {
            setCellAlignment(cell, "center", "middle");
          }

          setCellBorder(cell, true);
          setCellFont(cell, "Arial", 8, false, "black");

          if (colNumber === 5) {
            setColumnType(cell);
          }
        });

        rowNumber = rowNumber + 1;

      }
      if(ele.article){
        let row = detailSheet.getRow(rowNumber);
        row.values = [
          "",
          "",
          "",
          "",
          "",
          "",
          ele.article,
          ele.ratio,
          ele.quantity,
          ele.unite,
          ele.quantityConditioned,
          ele.saleUnit,
        ];

        row.eachCell(function (cell: any) {
          setCellAlignment(cell, "center", "middle");
          setCellBorder(cell, true);
          setCellFont(cell, "Arial", 8, false, "black");
        });
        rowNumber = rowNumber + 1;
      }
    });


    tempIndex = tempIndex + 1;
  });



};

const writeRatioNew = async (
  workbookObject: Excel.Workbook,
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let woolSheet = workbookObject.getWorksheet("Ratio");
  const sortedDefaultRation = sortBy(defaultRatio, ["order", "nameArticle"]);
  let rowNumber = 2;
  _.forEach(sortedDefaultRation, (deftRatio: IRationTable) => {
    let row = woolSheet.getRow(rowNumber);

    row.values = [
      deftRatio.typeArticle,
      deftRatio.placoSolution,
      deftRatio.nameArticle,
      deftRatio.ratioDefault === 0 ? "NaN" : deftRatio.ratioDefault,
      deftRatio.rationCustom && useCustomRatio
        ? parseFloat(deftRatio.rationCustom.toString())
        : deftRatio.ratioDefault,
    ];

    row.eachCell(function (cell: any, colNumber: number) {
      let num: any = cell.value;

      if (num === "NA") {
        cell.value = "";
      } else {
        setCellAlignment(cell, "center", "middle");
      }

      setCellBorder(cell, true);
      setCellFont(cell, "Arial", 8, false, "black");
    });
    row.height = 42.5;
    row.commit();
    rowNumber = rowNumber + 1;
  });
};

const writeWoolNew = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  woolCollection: woolType[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  /**
   * Sort wools by level -> solution
   */
  let woolArray: woolType[] = _.orderBy(
    woolCollection,
    ["LevelElevation", "zone", "Solution", "WallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let woolSummary: any = [];
  if (woolArray.length > 0) {
    let rowNumber = 16;
    _.forEach(woolArray, (wool: woolType, index: number) => {
      let woolRatio = wool.Ratio;
      if (useCustomRatio) {
        const woolRatioDefaultObject = defaultRatio.find((defRatio) => {
          return defRatio.nameArticle === wool.ProductName;
        });

        if (woolRatioDefaultObject) {
          if (woolRatioDefaultObject.rationCustom) {
            woolRatio = woolRatioDefaultObject.rationCustom;
          } else {
            woolRatio = woolRatioDefaultObject.ratioDefault;
          }
        }
      }

      const woolArea = round(
        wool.ElementArea * parseFloat(woolRatio.toString()),
        2
      );

      let qColumn = round(woolArea / parseFloat(wool.SellingPackage), 2) || 0;
      qColumn = qColumn === Infinity ? 0 : qColumn;

      woolSummary.push({
        level: wool.Level,
        productName: wool.ProductName,
        elementId: wool.ElementId,
        ratio: woolRatio,
        quantity: round(wool.ElementArea * woolRatio, 2),
        unite: "M2",
        quantityConditioned: 0,
        saleUnit: "",
        solution: wool.Ovurage,
        walltype: wool.ElementType,
        zone: wool.Zone,
        sellingPackage: wool.SellingPackage,
        labelUnit: wool.LabelUnit,
        ean: wool.Ean,
        quantitySecond: qColumn === 0 ? "" : qColumn,
      });

      rowNumber = rowNumber + 1;
    });
  }
  return woolSummary;
};

const writeAccessoryNew = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  collected_articles: Accessory[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let accessorySummary: any = [];

  /**
   * Sort montants by level -> solution
   */
  let accessoryArray: Accessory[] = [];

  accessoryArray = _.orderBy(
    collected_articles,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let rowNumber: number = 16;
  _.forEach(accessoryArray, (accessory: Accessory, index: number) => {
    rowNumber = rowNumber + 1;

    _.forEach(accessory.articles, (article: AccessoryArticle, key: number) => {
      let accesoryRatio = article.ratio;
      if (useCustomRatio) {
        const accessoryRatioDefaultObject = defaultRatio.find((defRatio) => {
          return defRatio.nameArticle === article.article;
        });

        if (accessoryRatioDefaultObject) {
          if (accessoryRatioDefaultObject.rationCustom) {
            accesoryRatio = accessoryRatioDefaultObject.rationCustom;
          } else {
            accesoryRatio = accessoryRatioDefaultObject.ratioDefault;
          }
        }
      }

      accessorySummary.push({
        level: article.level,
        article: article.article,
        ratio: accesoryRatio,
        elementId: article.elementId,
        quantity:
          article.unitI === "Kilogramme" ||
          article.unitI === "M" ||
          article.unitI === "Kg"
            ? round(article.elementArea * accesoryRatio, 2)
            : ceil(article.elementArea * accesoryRatio),
        unite: article.unitI,
        quantityConditioned: article.quantityS,
        saleUnit: article.unitS,
        solution: article.solution,
        walltype: article.elementType,
        levelElevation: article.levelElevation,
        zone: article.zone,
      });

      rowNumber = rowNumber + 1;
    });
  });

  return accessorySummary;
};

const writeRailNew = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  railArray: Rails[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  /**
   * Sort rails by level -> solution
   */

  railArray = _.orderBy(
    railArray,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let headRowNumber: number = 16; // variable for header row number to plot total unite(H) and total plaqs(I) in header rows
  let rowHeightUnitSum: number = 0; // SUM of F*G
  let firstArticle: string = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let quantitySum: string = ""; // SUM(G) sum of G
  let firstSolution: string = "";
  let railSummary: any[] = [];
  let lastArticleLength: number = 0;
  let lastWallType: string = "";
  let lastLevelElevation: number = 0;
  let lastZone: string = "";
  let lastRailNonLayout: boolean = false;

  let levelS: string = "";
  let elemId: number = 0;
  let railSumRatio: number = 0;
  let finalunite = articleunit["wall"]["Ossatures horizontales"];
  // in rails array element which is having length 9 is a header row.
  let rowNumber: number = 16;

  _.forEach(railArray, (rail: Rails, key: number) => {
    let articleName = "";
    _.forEach(rail.articles, (article: RailArticle, articleKey: string) => {
      if (articleName === "" || articleName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          if (lastRailNonLayout) {
            railSummary.push({
              level: levelS,
              solution: firstSolution,
              walltype: lastWallType,
              quantity: rowHeightUnitSum,
              railLength: lastArticleLength,
              rail: firstArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
              quantityFirstUnit: finalunite,
              ratio: railSumRatio,
              elementId: elemId,
            });
          } else {
            railSummary.push({
              level: levelS,
              solution: firstSolution,
              walltype: lastWallType,
              quantity: round(rowHeightUnitSum / lastArticleLength, 2),
              railLength: lastArticleLength,
              rail: firstArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
              quantityFirstUnit: finalunite,
              ratio: railSumRatio,
              elementId: elemId,
            });
          }
        }

        levelS = rail.level;
        elemId = rail.elementId;
        articleName = article.articleName;
        firstArticle = article.articleName;
        firstSolution = article.solution;
        headRowNumber = rowNumber;
        rowHeightUnitSum = 0;
        quantitySum = "";
        lastArticleLength = article.articleLength;
        lastWallType = article.elementType;
        rowNumber = rowNumber + 1;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
      }

      if (!article.nonLayout) {
        rowHeightUnitSum =
          rowHeightUnitSum + article.articleRevitLength * article.nbUnit;

        lastRailNonLayout = false;
      } else {
        let railRatio = article.ratio || 0;
        if (useCustomRatio) {
          const railRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.articleName;
          });

          if (railRatioDefaultObject) {
            if (railRatioDefaultObject.rationCustom) {
              railRatio = railRatioDefaultObject.rationCustom;
            } else {
              railRatio = railRatioDefaultObject.ratioDefault;
            }
          }
        }

        quantitySum = quantitySum + `L${rowNumber},`;
        railSumRatio = railRatio;

        rowHeightUnitSum =
          rowHeightUnitSum +
          round(
            (rail.elementArea * (railRatio || 0)) / article.articleLength,
            2
          );

        lastRailNonLayout = true;
      }

      rowNumber = rowNumber + 1;
    });
  });

  if (railArray.length > 0) {
    if (lastRailNonLayout) {
      railSummary.push({
        level: levelS,
        solution: firstSolution,
        walltype: lastWallType,
        quantity: rowHeightUnitSum,
        railLength: lastArticleLength,
        rail: firstArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        ratio: railSumRatio,
        quantityFirstUnit: finalunite,
        elementId: elemId,
      });
    } else {
      railSummary.push({
        level: levelS,
        solution: firstSolution,
        walltype: lastWallType,
        quantity: round(rowHeightUnitSum / lastArticleLength, 2),
        railLength: lastArticleLength,
        rail: firstArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        ratio: railSumRatio,
        quantityFirstUnit: finalunite,
        elementId: elemId,
      });
    }
  }
  return railSummary;
};

const writeMontantNew = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  montantArray: Montant[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  montantArray = _.orderBy(
    montantArray,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let headRowNumber: number = 16; // variable for header row number to plot `Nb unite` (G) and `Nb total` (I) in header rows
  let rowHeightUnitSum: number = 0; // Sum of (Montant Article Revit height * unit used i.e +[F*G])
  let quantitySum: string = ""; // Nb unites SUM formula
  let montantSummary: any[] = [];
  let rowNumber: number = 16;

  let lastArticle: string = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let lastWallType: string = "";
  let lastSolution: string = "";
  let lastArticleLength: number = 0;
  let lastLevelElevation: number = 0;
  let lastZone: string = "";
  let lastMontantNonLayout: boolean = false;

  let levelS: string = "";
  let elemId: number = 0;
  let montantSumRatio: number = 0;
  let finalunite = articleunit["wall"]["Ossatures verticles"];

  _.forEach(montantArray, (montant: Montant, index: number) => {
    let articleName = "";
    let articles: any = montant.articleLists
      ? filter(montant.articleLists, ["selected", true])
      : montant.articles;

    _.forEach(articles, (article: MontantArticle, articleKey: string) => {
      if (articleName === "" || articleName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          if (lastMontantNonLayout) {
            montantSummary.push({
              level: levelS,
              solution: lastSolution,
              walltype: lastWallType,
              quantity: rowHeightUnitSum,
              montantLength: lastArticleLength,
              montant: lastArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
              quantityFirstUnit: finalunite,
              ratio: montantSumRatio,
              elementId: elemId,
            });
          } else {
            montantSummary.push({
              level: levelS,
              solution: lastSolution,
              walltype: lastWallType,
              quantity: round((rowHeightUnitSum * 1.05) / lastArticleLength, 0),
              montantLength: lastArticleLength,
              montant: lastArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
              quantityFirstUnit: finalunite,
              ratio: montantSumRatio,
              elementId: elemId,
            });
          }
        }

        headRowNumber = rowNumber;
        rowNumber = rowNumber + 1;
        levelS = montant.level;
        elemId = montant.elementId;
        lastSolution = article.solution;
        lastArticle = article.articleName;
        articleName = article.articleName;
        lastWallType = article.elementType;
        lastArticleLength = article.articleLength;
        quantitySum = "";
        rowHeightUnitSum = 0;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
      }

      if (!article.nonLayout) {
        rowHeightUnitSum =
          rowHeightUnitSum + article.articleRevitHeight * article.nbUnit;

        lastMontantNonLayout = false;
      } else {
        let montantRatio = article.ratio || 0;
        if (useCustomRatio) {
          const montantRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.articleName;
          });

          if (montantRatioDefaultObject) {
            if (montantRatioDefaultObject.rationCustom) {
              montantRatio = montantRatioDefaultObject.rationCustom;
            } else {
              montantRatio = montantRatioDefaultObject.ratioDefault;
            }
          }
        }

        montantSumRatio = montantRatio;

        rowHeightUnitSum =
          rowHeightUnitSum +
          round(
            (montant.elementArea * (montantRatio || 0)) / article.articleLength,
            2
          );

        lastMontantNonLayout = true;
      }

      rowNumber = rowNumber + 1;
    });
  });

  if (montantArray.length > 0) {
    if (lastMontantNonLayout) {
      montantSummary.push({
        level: levelS,
        solution: lastSolution,
        walltype: lastWallType,
        quantity: rowHeightUnitSum,
        montantLength: lastArticleLength,
        montant: lastArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        ratio: montantSumRatio,
        quantityFirstUnit: finalunite,
        elementId: elemId,
      });
    } else {
      montantSummary.push({
        level: levelS,
        solution: lastSolution,
        walltype: lastWallType,
        quantity: round((rowHeightUnitSum * 1.05) / lastArticleLength, 0),
        montantLength: lastArticleLength,
        montant: lastArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        ratio: montantSumRatio,
        quantityFirstUnit: finalunite,
        elementId: elemId,
      });
    }
  }

  return montantSummary;
};

const writePlaquesNew = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  plaqArray: Plaqs[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let headRowNumber: number = 16; // variable for header row number to plot total unite(H) and total plaqs(I) in header rows
  let rowLengthHeightUnitSum: number = 0; // SUM (F15*G15*H15 + F16*G16*H16) for single article
  let quantitySum: string = ""; // SUM(H) for single article
  let plaqSummary: PlaqSummary[] = [];
  let firstSolution: string = "";

  let levelS: string = "";
  let elemId: number = 0;
  let plaqSumRatio: number = 0;
  let finalunite = articleunit["wall"]["Ovurages platre"];

  let lastWallType: string = "";
  let lastPlaqWidth: number = 0;
  let lastPlaqLength: number = 0;
  let lastPlaqArticle: string = "";
  let lastLevelElevation: number = 0;
  let lastZone: string = "";
  let lastPlaqNonLayout: boolean = false;

  // in plaqs array element which is having length 9 is a header row.
  let rowNumber: number = 16;

  /**
   * Sort Plaqs by level -> solution
   */

  plaqArray = _.orderBy(
    plaqArray,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  _.forEach(plaqArray, (plaque: Plaqs, key: number) => {
    let articlePrevName = "";

    let articles: any = plaque.articleLists
      ? filter(plaque.articleLists, ["selected", true])
      : plaque.articles;

    _.forEach(articles, (article: PlaqArticle, artilceKey: string) => {
      if (articlePrevName === "" || articlePrevName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          if (lastPlaqNonLayout) {
            plaqSummary.push({
              level: levelS,
              solution: firstSolution,
              elementType: lastWallType,
              quantity: rowLengthHeightUnitSum,
              plaqWidth: lastPlaqWidth,
              plaqLength: lastPlaqLength,
              plaq: lastPlaqArticle,
              surfaceArticle: 0,
              levelElevation: lastLevelElevation,
              zone: lastZone || "",
              quantityFirstUnit: finalunite,
              ratio: plaqSumRatio,
              elementId: elemId,
            });
          } else {
            plaqSummary.push({
              level: levelS,
              solution: firstSolution,
              elementType: lastWallType,
              quantity: round(
                (1.05 * rowLengthHeightUnitSum) /
                  lastPlaqWidth /
                  lastPlaqLength,
                2
              ),
              plaqWidth: lastPlaqWidth,
              plaqLength: lastPlaqLength,
              plaq: lastPlaqArticle,
              surfaceArticle: rowLengthHeightUnitSum,
              levelElevation: lastLevelElevation,
              zone: lastZone || "",
              quantityFirstUnit: finalunite,
              ratio: plaqSumRatio,
              elementId: elemId,
            });
          }
        }

        elemId = plaque.elementId;
        headRowNumber = rowNumber;
        rowNumber = rowNumber + 1;
        levelS = plaque.level;
        rowLengthHeightUnitSum = 0;
        quantitySum = "";
        articlePrevName = article.articleName;
      }

      if (!article.nonLayout) {
        rowLengthHeightUnitSum =
          rowLengthHeightUnitSum +
          article.articleRevitLength *
            article.articleRevitHeight *
            article.nbUnit;

        firstSolution = article.solution;
        lastWallType = article.elementType;
        lastPlaqWidth = article.articleWidth;
        lastPlaqLength = article.articleLength;
        lastPlaqArticle = article.articleName;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
        lastPlaqNonLayout = false;
      } else {
        // For non layout

        let plaqueRatio = article.ratio || 0;
        if (useCustomRatio) {
          const plaqueRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.articleName;
          });

          if (plaqueRatioDefaultObject) {
            if (plaqueRatioDefaultObject.rationCustom) {
              plaqueRatio = plaqueRatioDefaultObject.rationCustom;
            } else {
              plaqueRatio = plaqueRatioDefaultObject.ratioDefault;
            }
          }
        }
        plaqSumRatio = plaqueRatio;

        rowLengthHeightUnitSum =
          rowLengthHeightUnitSum +
          round(
            (plaque.elementArea * (plaqueRatio || 0)) /
              (article.articleLength * article.articleWidth) /
              article.nbUnit,
            2
          );

        firstSolution = article.solution;
        lastWallType = article.elementType;
        lastPlaqWidth = article.articleWidth;
        lastPlaqLength = article.articleLength;
        lastPlaqArticle = article.articleName;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
        lastPlaqNonLayout = true;
      }

      rowNumber = rowNumber + 1;
    });

    // article loop end
  });

  if (plaqArray.length > 0) {
    if (lastPlaqNonLayout) {
      plaqSummary.push({
        level: levelS,
        solution: firstSolution,
        elementType: lastWallType,
        quantity: rowLengthHeightUnitSum,
        plaqWidth: lastPlaqWidth,
        plaqLength: lastPlaqLength,
        plaq: lastPlaqArticle,
        surfaceArticle: 0,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        quantityFirstUnit: finalunite,
        ratio: plaqSumRatio,
        elementId: elemId,
      });
    } else {
      plaqSummary.push({
        level: levelS,
        solution: firstSolution,
        elementType: lastWallType,
        quantity: round(
          (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
          2
        ),
        plaqWidth: lastPlaqWidth,
        plaqLength: lastPlaqLength,
        plaq: lastPlaqArticle,
        surfaceArticle: rowLengthHeightUnitSum,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        quantityFirstUnit: finalunite,
        ratio: plaqSumRatio,
        elementId: elemId,
      });
    }
  }
  return plaqSummary;
};

const writeAccessory = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  collected_articles: Accessory[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let accessorySheet = workbookObject.getWorksheet("Accessoires");
  // logoImageId !== 0 && accessorySheet.addImage(logoImageId, "J1:L3");
  logoImageId !== 0 &&
    accessorySheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(accessorySheet, "D", "H");

  let accessorySummary: any = [];

  /**
   * Sort montants by level -> solution
   */
  let accessoryArray: Accessory[] = [];

  accessoryArray = _.orderBy(
    collected_articles,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let rowNumber: number = 16;
  _.forEach(accessoryArray, (accessory: Accessory, index: number) => {
    let row = accessorySheet.getRow(rowNumber);
    row.values = [
      accessory.level,
      accessory.zone || "",
      accessory.solution,
      accessory.elementHeight,
      accessory.elementArea,
      accessory.elementId,
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    row.eachCell(function (cell: any, colNumber: number) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "D9D9D9",
        },
      };

      setCellBorder(cell, true);
      setCellAlignment(cell, "center", "middle");
      setCellFont(cell, "Arial", 8, true, "black");
      if (colNumber === 6) {
        setColumnType(cell);
      }
    });

    rowNumber = rowNumber + 1;

    _.forEach(accessory.articles, (article: AccessoryArticle, key: number) => {
      if (article.article) {
        let accesoryRatio = article.ratio;
        if (useCustomRatio) {
          const accessoryRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.article;
          });

          if (accessoryRatioDefaultObject) {
            if (accessoryRatioDefaultObject.rationCustom) {
              accesoryRatio = accessoryRatioDefaultObject.rationCustom;
            } else {
              accesoryRatio = accessoryRatioDefaultObject.ratioDefault;
            }
          }
        }

        let row = accessorySheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          printNullOrZero(article.elementArea),
          "",
          article.article,
          parseFloat(accesoryRatio.toString()),
          printNullOrZero(article.quantityI),
          unitTobePrinted(article.unitI),
          printNullOrZero(article.quantityS),
          unitTobePrinted(article.unitS),
        ];

        row.eachCell(function (cell: any) {
          setCellAlignment(cell, "center", "middle");

          // Calculate formula for Quantite column
          if (cell.address.startsWith("I")) {
            const unitValue = row.getCell("J").value;

            if (unitValue === "Kg" || unitValue === "M") {
              cell.value = round(article.elementArea * accesoryRatio, 2);
            } else {
              cell.value = ceil(article.elementArea * accesoryRatio);
            }
          }

          setCellBorder(cell, true);
          setCellFont(cell, "Arial", 8, false, "black");
        });

        accessorySummary.push({
          level: article.level,
          article: article.article,
          ratio: accesoryRatio,
          elementId: article.elementId,
          quantity:
            article.unitI === "Kilogramme" ||
            article.unitI === "M" ||
            article.unitI === "Kg"
              ? round(article.elementArea * accesoryRatio, 2)
              : ceil(article.elementArea * accesoryRatio),
          unite: article.unitI,
          quantityConditioned: article.quantityS,
          saleUnit: article.unitS,
          solution: article.solution,
          walltype: article.elementType,
          levelElevation: article.levelElevation,
          zone: article.zone,
        });

        rowNumber = rowNumber + 1;
      }
    });
  });

  return accessorySummary;
};

const writeRail = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  railArray: Rails[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let railSheet = workbookObject.getWorksheet("Ossatures Horizontales");

  // logoImageId !== 0 && railSheet.addImage(logoImageId, "I1:K3");
  logoImageId !== 0 &&
    railSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(railSheet, "D", "I");

  /**
   * Sort rails by level -> solution
   */

  railArray = _.orderBy(
    railArray,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let headRowNumber: number = 16; // variable for header row number to plot total unite(H) and total plaqs(I) in header rows
  let rowHeightUnitSum: number = 0; // SUM of F*G
  let firstArticle: string = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let quantitySum: string = ""; // SUM(G) sum of G
  let firstSolution: string = "";
  let railSummary: any[] = [];
  let lastArticleLength: number = 0;
  let lastWallType: string = "";
  let lastLevelElevation: number = 0;
  let lastZone: string = "";
  let lastRailNonLayout: boolean = false;

  // in rails array element which is having length 9 is a header row.
  let rowNumber: number = 16;

  _.forEach(railArray, (rail: Rails, key: number) => {
    let articleName = "";
    _.forEach(rail.articles, (article: RailArticle, articleKey: string) => {
      if (articleName === "" || articleName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          let headerRow = railSheet.getRow(headRowNumber);

          if (lastRailNonLayout) {
            railSummary.push({
              level: headerRow.getCell("A").value,
              solution: firstSolution,
              walltype: lastWallType,
              quantity: rowHeightUnitSum,
              railLength: lastArticleLength,
              rail: firstArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
            });
          } else {
            railSummary.push({
              level: headerRow.getCell("A").value,
              solution: firstSolution,
              walltype: lastWallType,
              quantity: round(rowHeightUnitSum / lastArticleLength, 2),
              railLength: lastArticleLength,
              rail: firstArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
            });
          }

          if (lastRailNonLayout) {
            /**
             * rowHeightUnitSum (i.e. SUM of F*G) divide by article length
             */
            headerRow.getCell("L").value = {
              formula: `ROUND(SUM(${quantitySum}),2)`,
              sharedFormula: "none",
              date1904: false,
            };

            for (let index = 1; index <= headerRow.cellCount; index++) {
              let cell = headerRow.getCell(index);
              setCellBorder(cell, true);
              setCellFont(cell, "Arial", 8, true, "black");
              setCellAlignment(cell, "center", "middle");
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };
              if (index === 6) {
                setColumnType(cell);
              }
            }
          } else {
            /**
             * rowHeightUnitSum (i.e. SUM of F*G) divide by article length
             */
            headerRow.getCell("L").value = round(
              rowHeightUnitSum / lastArticleLength,
              2
            );

            headerRow.getCell("K").value = round(rowHeightUnitSum, 2);

            headerRow.getCell("J").value = {
              formula: `SUM(${quantitySum})`,
              sharedFormula: "none",
              date1904: false,
            };

            for (let index = 1; index <= headerRow.cellCount; index++) {
              let cell = headerRow.getCell(index);
              setCellBorder(cell, true);
              setCellFont(cell, "Arial", 8, true, "black");
              setCellAlignment(cell, "center", "middle");
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };
              if (index === 6) {
                setColumnType(cell);
              }
            }
          }
        }

        if (articleName === "") {
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            article.zone || "",
            article.solution,
            printNullOrZero(article.elementHeight),
            printNullOrZero(article.elementArea),
            rail.elementId,
            article.articleName,
            "",
            "",
            "",
            "",
            "",
          ];

          for (let index = 1; index <= row.cellCount; index++) {
            let cell = row.getCell(index);
            setCellBorder(cell, true);
            setCellAlignment(cell, "center", "middle");
            setCellFont(cell, "Arial", 8, false, "black");
          }
        } else {
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            "",
            "",
            "",
            "",
            "",
            article.articleName,
            "",
            "",
            "",
            "",
            "",
          ];

          for (let index = 1; index <= row.cellCount; index++) {
            let cell = row.getCell(index);
            setCellBorder(cell, true);
            setCellAlignment(cell, "center", "middle");
            setCellFont(cell, "Arial", 10, false, "black");
          }
        }

        articleName = article.articleName;
        firstArticle = article.articleName;
        firstSolution = article.solution;
        headRowNumber = rowNumber;
        rowHeightUnitSum = 0;
        quantitySum = "";
        lastArticleLength = article.articleLength;
        lastWallType = article.elementType;
        rowNumber = rowNumber + 1;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
      }

      if (!article.nonLayout) {
        quantitySum = quantitySum + `J${rowNumber},`;

        // rowLengthHeightUnitSum = rowLengthHeightUnitSum + F*G
        rowHeightUnitSum =
          rowHeightUnitSum + article.articleRevitLength * article.nbUnit;

        lastRailNonLayout = false;

        let row = railSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          "",
          article.articleName,
          "",
          printNullOrZero(article.articleRevitLength),
          printNullOrZero(article.nbUnit),
          "",
          "",
        ];

        for (let index = 1; index <= row.cellCount; index++) {
          let cell = row.getCell(index);
          setCellBorder(cell, true);
          setCellAlignment(cell, "center", "middle");
          setCellFont(cell, "Arial", 8, false, "black");
        }
      } else {
        let railRatio = article.ratio || 0;
        if (useCustomRatio) {
          const railRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.articleName;
          });

          if (railRatioDefaultObject) {
            if (railRatioDefaultObject.rationCustom) {
              railRatio = railRatioDefaultObject.rationCustom;
            } else {
              railRatio = railRatioDefaultObject.ratioDefault;
            }
          }
        }

        quantitySum = quantitySum + `L${rowNumber},`;

        rowHeightUnitSum =
          rowHeightUnitSum +
          round(
            (rail.elementArea * (railRatio || 0)) / article.articleLength,
            2
          );

        lastRailNonLayout = true;

        let row = railSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          "",
          article.articleName,
          parseFloat(railRatio.toString()),
          "",
          "",
          "",
          round(
            (rail.elementArea * (railRatio || 0)) / article.articleLength,
            2
          ),
        ];

        for (let index = 1; index <= row.cellCount; index++) {
          let cell = row.getCell(index);
          if ([9, 10, 11].includes(index)) {
            cell.fill = {
              type: "pattern",
              pattern: "gray125",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }
          setCellBorder(cell, true);
          setCellAlignment(cell, "center", "middle");
          setCellFont(cell, "Arial", 8, false, "black");
        }
      }

      rowNumber = rowNumber + 1;
    });
  });

  if (railArray.length > 0) {
    let headerRow = railSheet.getRow(headRowNumber);

    if (lastRailNonLayout) {
      railSummary.push({
        level: headerRow.getCell("A").value,
        solution: firstSolution,
        walltype: lastWallType,
        quantity: rowHeightUnitSum,
        railLength: lastArticleLength,
        rail: firstArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
      });
    } else {
      railSummary.push({
        level: headerRow.getCell("A").value,
        solution: firstSolution,
        walltype: lastWallType,
        quantity: round(rowHeightUnitSum / lastArticleLength, 2),
        railLength: lastArticleLength,
        rail: firstArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
      });
    }

    if (lastRailNonLayout) {
      /**
       * rowHeightUnitSum (i.e. SUM of F*G) divide by article length.
       */
      headerRow.getCell("L").value = {
        formula: `SUM(${quantitySum})`,
        sharedFormula: "none",
        date1904: false,
      };

      //headerRow.getCell("K").value = round(rowHeightUnitSum, 2);

      for (let index = 1; index <= headerRow.cellCount; index++) {
        let cell = headerRow.getCell(index);
        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 8, true, "black");
        setCellAlignment(cell, "center", "middle");
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };
        if (index === 6) {
          setColumnType(cell);
        }
      }
    } else {
      // /**
      //  * rowHeightUnitSum (i.e. SUM of F*G) divide by article length.
      //  */
      headerRow.getCell("L").value = round(
        rowHeightUnitSum / lastArticleLength,
        2
      );

      headerRow.getCell("K").value = round(rowHeightUnitSum, 2);

      headerRow.getCell("J").value = {
        formula: `SUM(${quantitySum})`,
        sharedFormula: "none",
        date1904: false,
      };

      for (let index = 1; index <= headerRow.cellCount; index++) {
        let cell = headerRow.getCell(index);
        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 8, true, "black");
        setCellAlignment(cell, "center", "middle");
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };
        if (index === 6) {
          setColumnType(cell);
        }
      }
    }
  }
  return railSummary;
};

const writeMontant = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  montantArray: Montant[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let montantSheet = workbookObject.getWorksheet("Ossatures Verticales");

  // logoImageId !== 0 && montantSheet.addImage(logoImageId, "H1:J3");
  logoImageId !== 0 &&
    montantSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(montantSheet, "D", "I");

  /**
   * Sort montants by level -> solution
   */

  montantArray = _.orderBy(
    montantArray,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  let headRowNumber: number = 16; // variable for header row number to plot `Nb unite` (G) and `Nb total` (I) in header rows
  let rowHeightUnitSum: number = 0; // Sum of (Montant Article Revit height * unit used i.e +[F*G])
  let quantitySum: string = ""; // Nb unites SUM formula
  let montantSummary: any[] = [];
  let rowNumber: number = 16;

  let lastArticle: string = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let lastWallType: string = "";
  let lastSolution: string = "";
  let lastArticleLength: number = 0;
  let lastLevelElevation: number = 0;
  let lastZone: string = "";
  let lastMontantNonLayout: boolean = false;

  _.forEach(montantArray, (montant: Montant, index: number) => {
    let articleName = "";

    let articles: any = montant.articleLists
      ? filter(montant.articleLists, ["selected", true])
      : montant.articles;

    _.forEach(articles, (article: MontantArticle, articleKey: string) => {
      if (articleName === "" || articleName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          let headerRow = montantSheet.getRow(headRowNumber);

          if (lastMontantNonLayout) {
            montantSummary.push({
              level: headerRow.getCell("A").value,
              solution: lastSolution,
              walltype: lastWallType,
              quantity: rowHeightUnitSum,
              montantLength: lastArticleLength,
              montant: lastArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
            });
          } else {
            montantSummary.push({
              level: headerRow.getCell("A").value,
              solution: lastSolution,
              walltype: lastWallType,
              quantity: round((rowHeightUnitSum * 1.05) / lastArticleLength, 0),
              montantLength: lastArticleLength,
              montant: lastArticle,
              levelElevation: lastLevelElevation,
              zone: lastZone,
            });
          }

          if (lastMontantNonLayout) {
            /**
             * Formula for H Column sum of rowHeightUnitSum (i.e F*G) divide by
             * length of article
             */
            headerRow.getCell("K").value = {
              formula: `ROUND(SUM(${quantitySum}),2)`,
              sharedFormula: "none",
              date1904: false,
            };

            for (let index = 1; index <= headerRow.cellCount; index++) {
              let cell = headerRow.getCell(index);
              setCellBorder(cell, true);
              setCellFont(cell, "Arial", 8, true, "black");
              setCellAlignment(cell, "center", "middle");
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };
              if (index === 6) {
                setColumnType(cell);
              }
            }
          } else {
            /**
             * Formula for H Column sum of rowHeightUnitSum (i.e F*G) divide by
             * length of article
             */
            headerRow.getCell("K").value = round(
              (rowHeightUnitSum * 1.05) / lastArticleLength,
              0
            );

            headerRow.getCell("J").value = {
              formula: `SUM(${quantitySum})`,
              sharedFormula: "none",
              date1904: false,
            };

            for (let index = 1; index <= headerRow.cellCount; index++) {
              let cell = headerRow.getCell(index);
              setCellBorder(cell, true);
              setCellFont(cell, "Arial", 8, true, "black");
              setCellAlignment(cell, "center", "middle");
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };
              if (index === 6) {
                setColumnType(cell);
              }
            }
          }
        }

        if (articleName === "") {
          let row = montantSheet.getRow(rowNumber);
          row.values = [
            article.level,
            article.zone || "",
            article.solution,
            printNullOrZero(article.elementHeight),
            printNullOrZero(article.elementArea),
            montant.elementId,
            article.articleName,
            "",
            "",
            "",
            "",
          ];
          headRowNumber = rowNumber;
          rowNumber = rowNumber + 1;
        } else {
          let row = montantSheet.getRow(rowNumber);
          row.values = [
            article.level,
            "",
            "",
            "",
            "",
            "",
            article.articleName,
            "",
            "",
            "",
            "",
          ];
          headRowNumber = rowNumber;
          rowNumber = rowNumber + 1;
        }

        lastSolution = article.solution;
        lastArticle = article.articleName;
        articleName = article.articleName;
        lastWallType = article.elementType;
        lastArticleLength = article.articleLength;
        quantitySum = "";
        rowHeightUnitSum = 0;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
      }

      if (!article.nonLayout) {
        quantitySum = quantitySum + `J${rowNumber},`;

        // rowLengthHeightUnitSum = rowLengthHeightUnitSum + F*G
        rowHeightUnitSum =
          rowHeightUnitSum + article.articleRevitHeight * article.nbUnit;

        lastMontantNonLayout = false;
        let row = montantSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          "",
          article.articleName,
          "",
          printNullOrZero(article.articleRevitHeight),
          printNullOrZero(article.nbUnit),
          "",
        ];

        for (let index = 1; index <= row.cellCount; index++) {
          let cell = row.getCell(index);
          setCellBorder(cell, true);
          setCellFont(cell, "Arial", 8, false, "black");
          setCellAlignment(cell, "center", "middle");
        }
      } else {
        let montantRatio = article.ratio || 0;
        if (useCustomRatio) {
          const montantRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.articleName;
          });

          if (montantRatioDefaultObject) {
            if (montantRatioDefaultObject.rationCustom) {
              montantRatio = montantRatioDefaultObject.rationCustom;
            } else {
              montantRatio = montantRatioDefaultObject.ratioDefault;
            }
          }
        }

        quantitySum = quantitySum + `K${rowNumber},`;

        rowHeightUnitSum =
          rowHeightUnitSum +
          round(
            (montant.elementArea * (montantRatio || 0)) / article.articleLength,
            2
          );

        lastMontantNonLayout = true;
        let row = montantSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          "",
          article.articleName,
          parseFloat(montantRatio.toString()),
          "",
          "",
          round(
            (montant.elementArea * (montantRatio || 0)) / article.articleLength,
            2
          ),
        ];

        for (let index = 1; index <= row.cellCount; index++) {
          let cell = row.getCell(index);
          if ([9, 10].includes(index)) {
            cell.fill = {
              type: "pattern",
              pattern: "gray125",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }
          setCellBorder(cell, true);
          setCellFont(cell, "Arial", 8, false, "black");
          setCellAlignment(cell, "center", "middle");
        }
      }

      rowNumber = rowNumber + 1;
    });
  });

  if (montantArray.length > 0) {
    let headerRow = montantSheet.getRow(headRowNumber);

    if (lastMontantNonLayout) {
      montantSummary.push({
        level: headerRow.getCell("A").value,
        solution: lastSolution,
        walltype: lastWallType,
        quantity: rowHeightUnitSum,
        montantLength: lastArticleLength,
        montant: lastArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
      });
    } else {
      montantSummary.push({
        level: headerRow.getCell("A").value,
        solution: lastSolution,
        walltype: lastWallType,
        quantity: round((rowHeightUnitSum * 1.05) / lastArticleLength, 0),
        montantLength: lastArticleLength,
        montant: lastArticle,
        levelElevation: lastLevelElevation,
        zone: lastZone,
      });
    }

    if (lastMontantNonLayout) {
      headerRow.getCell("K").value = {
        formula: `ROUND(SUM(${quantitySum}),2)`,
        sharedFormula: "none",
        date1904: false,
      };

      for (let index = 1; index <= headerRow.cellCount; index++) {
        let cell = headerRow.getCell(index);
        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 8, true, "black");
        setCellAlignment(cell, "center", "middle");
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };
        if (index === 6) {
          setColumnType(cell);
        }
      }
    } else {
      /**
       * Formula for H Column sum of rowHeightUnitSum (i.e F*G) divide by
       * length of article
       */
      headerRow.getCell("K").value = round(
        (rowHeightUnitSum * 1.05) / lastArticleLength,
        0
      );

      headerRow.getCell("J").value = {
        formula: `SUM(${quantitySum})`,
        sharedFormula: "none",
        date1904: false,
      };

      for (let index = 1; index <= headerRow.cellCount; index++) {
        let cell = headerRow.getCell(index);
        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 8, true, "black");
        setCellAlignment(cell, "center", "middle");
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };
        if (index === 6) {
          setColumnType(cell);
        }
      }
    }
  }

  return montantSummary;
};

const writePlaques = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  plaqArray: Plaqs[],
  defaultRatio: IRationTable[],
  useCustomRatio: boolean
): Promise<any> => {
  let railSheet = workbookObject.getWorksheet("Produits plâtre");

  // logoImageId !== 0 && railSheet.addImage(logoImageId, "I1:K3");
  logoImageId !== 0 &&
    railSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(railSheet, "D", "I");

  let headRowNumber: number = 16; // variable for header row number to plot total unite(H) and total plaqs(I) in header rows
  let rowLengthHeightUnitSum: number = 0; // SUM (F15*G15*H15 + F16*G16*H16) for single article
  let quantitySum: string = ""; // SUM(H) for single article
  let plaqSummary: PlaqSummary[] = [];
  let firstSolution: string = "";

  let levelS: string = "";
  let elemId: number = 0;
  let plaqSumRatio: number = 0;
  let finalunite = articleunit["wall"]["Ovurages platre"];

  let lastWallType: string = "";
  let lastPlaqWidth: number = 0;
  let lastPlaqLength: number = 0;
  let lastPlaqArticle: string = "";
  let lastLevelElevation: number = 0;
  let lastZone: string = "";
  let lastPlaqNonLayout: boolean = false;

  // in plaqs array element which is having length 9 is a header row.
  let rowNumber: number = 16;

  /**
   * Sort Plaqs by level -> solution
   */

  plaqArray = _.orderBy(
    plaqArray,
    ["levelElevation", "zone", "solution", "wallArea"],
    ["asc", "asc", "asc", "desc"]
  );

  ///////////////////

  _.forEach(plaqArray, (plaque: Plaqs, key: number) => {
    let articlePrevName = "";

    let articles: any = plaque.articleLists
      ? filter(plaque.articleLists, ["selected", true])
      : plaque.articles;

    _.forEach(articles, (article: PlaqArticle, artilceKey: string) => {
      if (articlePrevName === "" || articlePrevName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          let headerRow = railSheet.getRow(headRowNumber);
          let cellAValue = headerRow.getCell("A").value;

          if (lastPlaqNonLayout) {
            plaqSummary.push({
              level: cellAValue ? cellAValue.toString() : "",
              solution: firstSolution,
              elementType: lastWallType,
              quantity: rowLengthHeightUnitSum,
              plaqWidth: lastPlaqWidth,
              plaqLength: lastPlaqLength,
              plaq: lastPlaqArticle,
              surfaceArticle: 0,
              levelElevation: lastLevelElevation,
              zone: lastZone || "",
              quantityFirstUnit: finalunite,
              ratio: plaqSumRatio,
              elementId: elemId,
            });
          } else {
            plaqSummary.push({
              level: cellAValue ? cellAValue.toString() : "",
              solution: firstSolution,
              elementType: lastWallType,
              quantity: round(
                (1.05 * rowLengthHeightUnitSum) /
                  lastPlaqWidth /
                  lastPlaqLength,
                2
              ),
              plaqWidth: lastPlaqWidth,
              plaqLength: lastPlaqLength,
              plaq: lastPlaqArticle,
              surfaceArticle: rowLengthHeightUnitSum,
              levelElevation: lastLevelElevation,
              zone: lastZone || "",
              quantityFirstUnit: finalunite,
              ratio: plaqSumRatio,
              elementId: elemId,
            });
          }

          if (lastPlaqNonLayout) {
            headerRow.getCell("L").value = {
              formula: `ROUND(SUM(${quantitySum}),2)`,
              sharedFormula: "none",
              date1904: false,
            };

            headerRow.eachCell(function (cell: any, colNumber: number) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };

              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              if (colNumber === 6) {
                setColumnType(cell);
              }
              setCellFont(cell, "Arial", 8, true, "black");
              setCellAlignment(cell, "center", "middle");
            });
          } else {
            // Formula for I cell =SUM(J15:J18) * 1.08 / 1.2 /2.5
            // for Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m and	Aquaroc® 13 120/250
            // plaqs[i - 1][9] is last article length
            // plaqs[i - 1][10] is last article width
            headerRow.getCell("L").value = round(
              (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
              2
            );

            headerRow.getCell("K").value = {
              formula: `SUM(${quantitySum})`,
              sharedFormula: "none",
              date1904: false,
            };

            headerRow.eachCell(function (cell: any, colNumber: number) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };

              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              if (colNumber === 6) {
                setColumnType(cell);
              }
              setCellFont(cell, "Arial", 8, true, "black");
              setCellAlignment(cell, "center", "middle");
            });
          }
        }

        /// if articlePrevName is not set or it's difference
        /// then add blank row
        if (articlePrevName === "") {
          /// if articlePrevName === "" then add solution row
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            article.zone || "",
            article.solution,
            article.elementHeight,
            article.elementArea,
            plaque.elementId,
            article.articleName,
            "",
            "",
            "",
            "",
            "",
          ];
          elemId = plaque.elementId;
          headRowNumber = rowNumber;
          rowNumber = rowNumber + 1;
        } else {
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            "",
            "",
            "",
            "",
            "",
            article.articleName,
            "",
            "",
            "",
            "",
            "",
          ];
          elemId = plaque.elementId;
          headRowNumber = rowNumber;
          rowNumber = rowNumber + 1;
        }
        rowLengthHeightUnitSum = 0;
        quantitySum = "";
        articlePrevName = article.articleName;
      }

      if (!article.nonLayout) {
        quantitySum = quantitySum + `K${rowNumber},`;

        // rowLengthHeightUnitSum = rowLengthHeightUnitSum + F*G*H
        rowLengthHeightUnitSum =
          rowLengthHeightUnitSum +
          article.articleRevitLength *
            article.articleRevitHeight *
            article.nbUnit;

        firstSolution = article.solution;
        lastWallType = article.elementType;
        lastPlaqWidth = article.articleWidth;
        lastPlaqLength = article.articleLength;
        lastPlaqArticle = article.articleName;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
        lastPlaqNonLayout = false;

        let row = railSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          "",
          article.articleName,
          "",
          article.articleRevitLength,
          article.articleRevitHeight,
          article.nbUnit,
          "",
        ];

        row.eachCell(function (cell: any, colNumber: number) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

          setCellFont(cell, "Arial", 8, false, "black");
          setCellAlignment(cell, "center", "middle");
        });
      } else {
        // For non layout

        let plaqueRatio = article.ratio || 0;
        if (useCustomRatio) {
          const plaqueRatioDefaultObject = defaultRatio.find((defRatio) => {
            return defRatio.nameArticle === article.articleName;
          });

          if (plaqueRatioDefaultObject) {
            if (plaqueRatioDefaultObject.rationCustom) {
              plaqueRatio = plaqueRatioDefaultObject.rationCustom;
            } else {
              plaqueRatio = plaqueRatioDefaultObject.ratioDefault;
            }
          }
        }
        quantitySum = quantitySum + `L${rowNumber},`;
        plaqSumRatio = plaqueRatio;

        rowLengthHeightUnitSum =
          rowLengthHeightUnitSum +
          round(
            (plaque.elementArea * (plaqueRatio || 0)) /
              (article.articleLength * article.articleWidth) /
              article.nbUnit,
            2
          );

        firstSolution = article.solution;
        lastWallType = article.elementType;
        lastPlaqWidth = article.articleWidth;
        lastPlaqLength = article.articleLength;
        lastPlaqArticle = article.articleName;
        lastLevelElevation = article.levelElevation;
        lastZone = article.zone || "";
        lastPlaqNonLayout = true;

        let row = railSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          "",
          article.articleName,
          parseFloat(plaqueRatio.toString()),
          "",
          "",
          "",
          round(
            (plaque.elementArea * (plaqueRatio || 0)) /
              (article.articleLength * article.articleWidth) /
              article.nbUnit,
            2
          ),
        ];

        row.eachCell(function (cell: any, colNumber: number) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

          if ([9, 10, 11].includes(colNumber)) {
            cell.fill = {
              type: "pattern",
              pattern: "gray125",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }

          setCellFont(cell, "Arial", 8, false, "black");
          setCellAlignment(cell, "center", "middle");
        });
      }

      rowNumber = rowNumber + 1;
    });

    // article loop end
  });

  if (plaqArray.length > 0) {
    let headerRow = railSheet.getRow(headRowNumber);
    let cellAValue = headerRow.getCell("A").value;

    if (lastPlaqNonLayout) {
      plaqSummary.push({
        level: cellAValue ? cellAValue.toString() : "",
        solution: firstSolution,
        elementType: lastWallType,
        quantity: rowLengthHeightUnitSum,
        plaqWidth: lastPlaqWidth,
        plaqLength: lastPlaqLength,
        plaq: lastPlaqArticle,
        surfaceArticle: 0,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        quantityFirstUnit: finalunite,
        ratio: plaqSumRatio,
        elementId: elemId,
      });
    } else {
      plaqSummary.push({
        level: cellAValue ? cellAValue.toString() : "",
        solution: firstSolution,
        elementType: lastWallType,
        quantity: round(
          (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
          2
        ),
        plaqWidth: lastPlaqWidth,
        plaqLength: lastPlaqLength,
        plaq: lastPlaqArticle,
        surfaceArticle: rowLengthHeightUnitSum,
        levelElevation: lastLevelElevation,
        zone: lastZone,
        quantityFirstUnit: finalunite,
        ratio: plaqSumRatio,
        elementId: elemId,
      });
    }

    if (lastPlaqNonLayout) {
      headerRow.getCell("L").value = {
        formula: `SUM(${quantitySum})`,
        sharedFormula: "none",
        date1904: false,
      };

      headerRow.eachCell(function (cell: any, colNumber: number) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };

        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        setCellFont(cell, "Arial", 8, true, "black");
        setCellAlignment(cell, "center", "middle");

        if (colNumber === 6) {
          setColumnType(cell);
        }
      });
    } else {
      // Formula for I cell =SUM(J15:J18) * 1.08 / 1.2 /2.5
      // for Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m and	Aquaroc® 13 120/250
      // plaqs[i - 1][9] is last article length
      // plaqs[i - 1][10] is last article width
      headerRow.getCell("L").value = round(
        (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
        2
      );

      headerRow.getCell("K").value = {
        formula: `SUM(${quantitySum})`,
        sharedFormula: "none",
        date1904: false,
      };

      headerRow.eachCell(function (cell: any, colNumber: number) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };

        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        setCellFont(cell, "Arial", 8, true, "black");
        setCellAlignment(cell, "center", "middle");

        if (colNumber === 6) {
          setColumnType(cell);
        }
      });
    }
  }
  return plaqSummary;
};

/**
 * Write 'Feuille Synthèse' (Summary) sheet
 * @param workbook Excel workbook
 * @param extractDetails Placo solution details containing Plaq, Montant, Wools, Accessory, Rails
 * @param qty Extracted data and unique data for Plasterboard, Montant, Rails, Accessory, Wools
 */
export const writeSummary = async (
  workbookObject: Excel.Workbook,
  finalExtract: any,
  plaqSummary: any,
  montantSummary: any,
  railSummary: any,
  woolSummary: any,
  accessorySummary: any,
  props: any,
  logoImageId: any,
  mapZoneWall: any,
  config: any
) => {
  let summarySheet = workbookObject.getWorksheet("Quantitatifs chantier");

  //logoImageId !== 0 && summarySheet.addImage(logoImageId, "H1:K3");
  logoImageId !== 0 &&
    summarySheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(summarySheet, "D", "G");

  let rowDetailsCount: number = 17;
  let detailsRow: { [Key: string]: SummaryGroupDetail } = {};
  let globalWool: {
    [Key: string]: GlobalSummary;
  } = {};
  let globalAccessory: {
    [Key: string]: GlobalSummary;
  } = {};

  let globalWoolbyZone: {
    [Key: string]: GlobalSummary;
  } = {};
  let globalAccessorybyZone: {
    [Key: string]: GlobalSummary;
  } = {};

  await asyncForEach(finalExtract, async (extractDetail: any, index: any) => {
    let wallType = extractDetail.wallDetails.WallType;
    let levelName = extractDetail.wallDetails.LevelName;
    let levelElevation = extractDetail.wallDetails.LevelElevation;
    let solutionName = extractDetail.placoSolution.translation;

    const detailZone = _.find(mapZoneWall, (m) => {
      return includes(m.walls, extractDetail.wallDetails.Id);
    });

    let plaqRow: { [Key: string]: SummaryDetail } = {};
    let montantRow: { [Key: string]: SummaryDetail } = {};
    let railRow: { [Key: string]: SummaryDetail } = {};
    let accRow: { [Key: string]: SummaryDetail } = {};
    let woolRow: { [Key: string]: SummaryDetail } = {};

    //#region plaqs

    const filteredPlaqSummary = _.filter(plaqSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredPlaqSummary,
      async (fltPlaqSum: any, fltPlaqSumIndex: any) => {
        await asyncForEach(
          extractDetail.plaques,
          async (plaqs: any, plaqsIndex: any) => {
            if (fltPlaqSum.zone === detailZone.zone) {
              // const selectedPackagedArticle = _.find(
              //   packagedArticles.objects,
              //   (object) => {
              //     if (
              //       object.translation === fltPlaqSum.plaq &&
              //       object.types[0] === "Packaged Article"
              //     ) {
              //       const packValid = packagedArticleLevelFilter(object.attributes);
              //       return packValid;
              //     }
              //     return false;
              //   }
              // );
              const selectedPackagedArticle = _.find(
                plaqs.articles,
                (object) => {
                  return object.value === fltPlaqSum.plaq;
                }
              );

              if (selectedPackagedArticle) {
                const attributeDescriptors = extractDetail.attributes;

                const attributeAids =
                  packageAttributeSelector(attributeDescriptors);

                const relationAttributeAids = relationAttributeSelector(
                  extractDetail.relationAttributes
                );

                const distributionChannelsAids =
                  distributionChannelsAttributeSelector(attributeDescriptors);

                const alternativeUnitsAids =
                  alternativeUnitsSelector(attributeDescriptors);

                const articleAttribute =
                  selectedPackagedArticle.packagedArticle.attributes;

                const dbsalesunit = dbSalesUnit(
                  distributionChannelsAids,
                  articleAttribute
                );

                const saleunit = _.find(articleAttribute, [
                  "aid",
                  attributeAids.baseunit,
                ]);

                let salesunit = "";
                if (dbsalesunit) {
                  salesunit = uniteDeVenteArray(dbsalesunit);
                } else {
                  if (saleunit) {
                    const saleunit_value = _.get(saleunit.values[0], "value");
                    salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
                  }
                }

                let finalunite = uniteDeVenteArray(
                  findProductToProduct(
                    extractDetail.relations,
                    plaqs.product.oid,
                    relationAttributeAids.unite,
                    extractDetail.placoSolution.oid
                  )
                );

                if (finalunite === "") {
                  finalunite = articleunit["wall"]["Ovurages platre"];
                }

                let newPlaqRow = plaqRow[selectedPackagedArticle.value] || {
                  levelName: levelName,
                  ouvrage: "",
                  wallType: wallType,
                  article: selectedPackagedArticle.value,
                  ean: getAttValue(articleAttribute, attributeAids.eancode),
                  nbArticles: 0,
                  quantity: 0,
                  unite: uniteDeVenteToUnit(finalunite),
                  quantityConditioned: 0,
                  uniteDeVente: salesunit,
                  zone: fltPlaqSum.zone,
                };

                const updatedQuantity =
                  extractDetail.layoutPossible === "false"
                    ? round(
                        fltPlaqSum.plaqWidth *
                          fltPlaqSum.plaqLength *
                          fltPlaqSum.quantity,
                        2
                      )
                    : round(
                        (newPlaqRow.nbArticles + fltPlaqSum.quantity) *
                          fltPlaqSum.plaqWidth *
                          fltPlaqSum.plaqLength,
                        2
                      );

                const conditionedQuantity = await newConditionedQuantityForPlaq(
                  alternativeUnitsAids,
                  articleAttribute,
                  uniteDeVenteToUnit(finalunite),
                  updatedQuantity,
                  salesunit,
                  props
                );

                plaqRow[selectedPackagedArticle.value] = {
                  ...newPlaqRow,
                  nbArticles: newPlaqRow.nbArticles + fltPlaqSum.quantity,
                  quantity: updatedQuantity,
                  quantityConditioned: conditionedQuantity,
                };
              }
            }
          }
        );
      }
    );
    //#endregion plaqs

    //#region montant
    const filteredMontantSummary = _.filter(montantSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredMontantSummary,
      async (fltMontantSum: any, fltMontantIndex: any) => {
        await asyncForEach(
          extractDetail.montants,
          async (montant: any, montantIndex: any) => {
            if (fltMontantSum.zone === detailZone.zone) {
              const selectedPackagedArticle = _.find(
                montant.articles,
                (object) => {
                  return object.value === fltMontantSum.montant;
                }
              );
              // const selectedPackagedArticle = _.find(
              //   packagedArticles.objects,
              //   (object) => {
              //     if (
              //       object.translation === fltMontantSum.montant &&
              //       object.types[0] === "Packaged Article"
              //     ) {
              //       const packValid = packagedArticleLevelFilter(object.attributes);
              //       return packValid;
              //     }
              //     return false;
              //   }
              // );

              if (selectedPackagedArticle) {
                const attributeDescriptors = extractDetail.attributes;

                const attributeAids =
                  packageAttributeSelector(attributeDescriptors);

                const relationAttributeAids = relationAttributeSelector(
                  extractDetail.relationAttributes
                );

                const distributionChannelsAids =
                  distributionChannelsAttributeSelector(attributeDescriptors);

                const alternativeUnitsAids =
                  alternativeUnitsSelector(attributeDescriptors);

                const articleAttribute =
                  selectedPackagedArticle.packagedArticle.attributes;

                const dbsalesunit = dbSalesUnit(
                  distributionChannelsAids,
                  articleAttribute
                );

                const saleunit = _.find(articleAttribute, [
                  "aid",
                  attributeAids.baseunit,
                ]);

                let salesunit = "";
                if (dbsalesunit) {
                  salesunit = uniteDeVenteArray(dbsalesunit);
                } else {
                  if (saleunit) {
                    const saleunit_value = _.get(saleunit.values[0], "value");
                    salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
                  }
                }

                let finalunite = uniteDeVenteArray(
                  findProductToProduct(
                    extractDetail.relations,
                    montant.product.oid,
                    relationAttributeAids.unite,
                    extractDetail.placoSolution.oid
                  )
                );

                if (finalunite === "") {
                  finalunite = articleunit["wall"]["Ossatures verticles"];
                }

                let newMontantRow = montantRow[
                  selectedPackagedArticle.value
                ] || {
                  levelName: levelName,
                  ouvrage: "",
                  wallType: wallType,
                  article: selectedPackagedArticle.value,
                  ean: getAttValue(articleAttribute, attributeAids.eancode),
                  nbArticles: 0,
                  quantity: 0,
                  unite: uniteDeVenteToUnit(finalunite),
                  quantityConditioned: 0,
                  uniteDeVente: salesunit,
                  zone: fltMontantSum.zone,
                };

                const updatedQuantity =
                  extractDetail.layoutPossible === "false"
                    ? round(
                        fltMontantSum.montantLength * fltMontantSum.quantity,
                        2
                      )
                    : round(
                        (newMontantRow.nbArticles + fltMontantSum.quantity) *
                          fltMontantSum.montantLength,
                        2
                      );

                const conditionedQuantity =
                  await newConditionedQuantityForMontant(
                    alternativeUnitsAids,
                    articleAttribute,
                    uniteDeVenteToUnit(finalunite),
                    updatedQuantity,
                    salesunit,
                    props
                  );

                montantRow[selectedPackagedArticle.value] = {
                  ...newMontantRow,
                  nbArticles: newMontantRow.nbArticles + fltMontantSum.quantity,
                  quantity: updatedQuantity,
                  quantityConditioned: conditionedQuantity,
                };
              }
            }
          }
        );
      }
    );

    //#endregion

    //#region rail
    const filteredRailSummary = _.filter(railSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredRailSummary,
      async (fltRailSum: any, fltRailIndex: any) => {
        await asyncForEach(
          extractDetail.rails,
          async (rail: any, railIndex: any) => {
            if (fltRailSum.zone === detailZone.zone) {
              const selectedPackagedArticle = _.find(
                rail.articles,
                (object) => {
                  return object.value === fltRailSum.rail;
                }
              );
              // const selectedPackagedArticle = _.find(
              //   packagedArticles.objects,
              //   (object) => {
              //     if (
              //       object.translation === fltRailSum.rail &&
              //       object.types[0] === "Packaged Article"
              //     ) {
              //       // const packValid = packagedArticleLevelFilter(object.attributes);
              //       // return packValid;
              //       return true;
              //     }
              //     return false;
              //   }
              // );

              if (selectedPackagedArticle) {
                const attributeDescriptors = extractDetail.attributes;

                const attributeAids =
                  packageAttributeSelector(attributeDescriptors);

                const relationAttributeAids = relationAttributeSelector(
                  extractDetail.relationAttributes
                );

                const distributionChannelsAids =
                  distributionChannelsAttributeSelector(attributeDescriptors);

                const alternativeUnitsAids =
                  alternativeUnitsSelector(attributeDescriptors);

                const articleAttribute =
                  selectedPackagedArticle.packagedArticle.attributes;

                const dbsalesunit = dbSalesUnit(
                  distributionChannelsAids,
                  articleAttribute
                );

                const saleunit = _.find(articleAttribute, [
                  "aid",
                  attributeAids.baseunit,
                ]);

                let salesunit = "";
                if (dbsalesunit) {
                  salesunit = uniteDeVenteArray(dbsalesunit);
                } else {
                  if (saleunit) {
                    const saleunit_value = _.get(saleunit.values[0], "value");
                    salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
                  }
                }

                let finalunite = uniteDeVenteArray(
                  findProductToProduct(
                    extractDetail.relations,
                    rail.product.oid,
                    relationAttributeAids.unite,
                    extractDetail.placoSolution.oid
                  )
                );

                if (finalunite === "") {
                  finalunite = articleunit["wall"]["Ossatures horizontales"];
                }

                let newRailRow = railRow[selectedPackagedArticle.value] || {
                  levelName: levelName,
                  ouvrage: "",
                  wallType: wallType,
                  article: selectedPackagedArticle.value,
                  ean: getAttValue(articleAttribute, attributeAids.eancode),
                  nbArticles: 0,
                  quantity: 0,
                  unite: uniteDeVenteToUnit(finalunite),
                  quantityConditioned: 0,
                  uniteDeVente: salesunit,
                  zone: fltRailSum.zone,
                };

                const updatedQuantity =
                  extractDetail.layoutPossible === "false"
                    ? round(fltRailSum.quantity * fltRailSum.railLength, 2)
                    : round(
                        (newRailRow.nbArticles + fltRailSum.quantity) *
                          fltRailSum.railLength,
                        2
                      );

                const conditionedQuantity = await newConditionedQuantityForRail(
                  alternativeUnitsAids,
                  articleAttribute,
                  uniteDeVenteToUnit(finalunite),
                  updatedQuantity,
                  salesunit,
                  props
                );

                railRow[selectedPackagedArticle.value] = {
                  ...newRailRow,
                  nbArticles: newRailRow.nbArticles + fltRailSum.quantity,
                  quantity: updatedQuantity,
                  quantityConditioned: conditionedQuantity,
                };
              }
            }
          }
        );
      }
    );

    //#endregion

    //#region acceosory
    const filteredAccessorySummary = _.filter(accessorySummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredAccessorySummary,
      async (fltAccSum: any, fltAccSumIndex: any) => {
        if (fltAccSum.zone === detailZone.zone) {
          const art = _.flattenDeep(
            _.map(extractDetail.accessories, "articles")
          );

          const selectedPackagedArticle = _.find(art, (object) => {
            return object.value === fltAccSum.article;
          });

          // const selectedPackagedArticle = _.find(
          //   packagedArticles.objects,
          //   (object) => {

          //     if (
          //       object.translation === fltAccSum.article &&
          //       object.types[0] === "Packaged Article"
          //     ) {
          //       const packValid = packagedArticleLevelFilter(object.attributes);
          //       return packValid;
          //     }
          //     return false;
          //   }
          // );

          if (selectedPackagedArticle) {
            const attributeDescriptors = extractDetail.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            const articleAttribute =
              selectedPackagedArticle.packagedArticle.attributes;
            // ean: getAttValue(articleAttribute, attributeAids.eancode),

            let newAccRow = accRow[selectedPackagedArticle.value] || {
              levelName: levelName,
              ouvrage: "",
              wallType: wallType,
              article: selectedPackagedArticle.value,
              ean: getAttValue(articleAttribute, attributeAids.eancode),
              quantity: 0,
              unite: fltAccSum.unite,
              quantityConditioned: 0,
              uniteDeVente: fltAccSum.saleUnit,
              zone: fltAccSum.zone,
            };

            const quantityCond =
              typeof fltAccSum.quantityConditioned === "string"
                ? parseFloat(fltAccSum.quantityConditioned)
                : fltAccSum.quantityConditioned;

            accRow[selectedPackagedArticle.value] = {
              ...newAccRow,
              quantity: newAccRow.quantity + fltAccSum.quantity,
              quantityConditioned: newAccRow.quantityConditioned + quantityCond,
            };
          }
        }
      }
    );

    //#endregion

    //#region wool

    const filteredWoolSummary = _.filter(woolSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredWoolSummary,
      async (fltWoolSum: any, fltWoolSumIndex: any) => {
        await asyncForEach(
          extractDetail.wools,
          async (woolObj: any, woolIndex: any) => {
            if (fltWoolSum.zone === detailZone.zone) {
              let newWoolRow = woolRow[fltWoolSum.productName] || {
                levelName: levelName,
                ouvrage: "",
                wallType: wallType,
                article: fltWoolSum.productName,
                ean: fltWoolSum.ean,
                quantity: 0,
                unite: fltWoolSum.unite,
                quantityConditioned: 0,
                uniteDeVente: fltWoolSum.labelUnit,
                zone: fltWoolSum.zone,
                sellingPackage: fltWoolSum.sellingPackage,
              };

              let qColumn =
                ceil(
                  (newWoolRow.quantity + fltWoolSum.quantity) /
                    parseFloat(fltWoolSum.sellingPackage)
                ) || 0;
              qColumn = qColumn === Infinity ? 0 : qColumn;

              woolRow[fltWoolSum.productName] = {
                ...newWoolRow,
                quantity: newWoolRow.quantity + fltWoolSum.quantity,
                quantityConditioned: qColumn,
              };
            }
          }
        );
      }
    );

    //#endregion wool

    const detailsRowObj = _.get(
      detailsRow,
      `${levelName}/${solutionName}/${detailZone.zone}`,
      null
    );
    const combineWallType = detailsRowObj
      ? detailsRowObj.elementType.includes(wallType)
        ? detailsRowObj.elementType
        : detailsRowObj.elementType + "; " + wallType
      : wallType;

    detailsRow[`${levelName}/${solutionName}/${detailZone.zone}`] = {
      solution: solutionName,
      elementType: combineWallType,
      level: levelName,
      plaqs: plaqRow,
      montants: montantRow,
      rails: railRow,
      accessory: accRow,
      wool: woolRow,
      levelElevation: levelElevation,
      zone: detailZone.zone,
    };
  });

  //#region Global wool extract

  _.forEach(woolSummary, (woolSumm, woolRowIndex) => {
    let finalunite = woolSumm.unite;
    if (finalunite === "") {
      finalunite = articleunit["wall"]["Ovurages isolants"];
    }

    let newGlobalWoolRow = globalWool[woolSumm.productName] || {
      level: woolSumm.level,
      ean: woolSumm.ean,
      article: woolSumm.productName,
      quantity: 0,
      unite: finalunite,
      quantityConditioned: 0,
      uniteDeVente: woolSumm.labelUnit,
      zone: woolSumm.zone,
    };

    let qColumn =
      ceil(
        (newGlobalWoolRow.quantity + woolSumm.quantity) /
          parseFloat(woolSumm.sellingPackage)
      ) || 0;
    qColumn = qColumn === Infinity ? 0 : qColumn;

    globalWool[woolSumm.productName] = {
      ...newGlobalWoolRow,
      quantity: newGlobalWoolRow.quantity + woolSumm.quantity,
      quantityConditioned: qColumn,
    };

    let newGlobalWoolbyZoneRow = globalWoolbyZone[
      `${woolSumm.productName}-${woolSumm.zone}`
    ] || {
      level: woolSumm.level,
      ean: woolSumm.ean,
      article: woolSumm.productName,
      quantity: 0,
      unite: woolSumm.unite,
      quantityConditioned: 0,
      uniteDeVente: woolSumm.labelUnit,
      zone: woolSumm.zone,
    };

    let qColumnn =
      ceil(
        (newGlobalWoolRow.quantity + woolSumm.quantity) /
          parseFloat(woolSumm.sellingPackage)
      ) || 0;
    qColumnn = qColumnn === Infinity ? 0 : qColumnn;

    globalWoolbyZone[`${woolSumm.productName}-${woolSumm.zone}`] = {
      ...newGlobalWoolbyZoneRow,
      quantity: newGlobalWoolbyZoneRow.quantity + woolSumm.quantity,
      quantityConditioned: qColumnn,
    };
  });

  //#endregion Global wool extract

  //#region Global accessory extract
  _.forEach(accessorySummary, (accessorySumm, accessoryRowIndex) => {
    let detailObjForean = _.get(
      detailsRow,
      `${accessorySumm.level}/${accessorySumm.solution}/${accessorySumm.zone}`
    );

    let newGlobalAccessRow = globalAccessory[accessorySumm.article] || {
      level: accessorySumm.level,
      article: accessorySumm.article,
      ean: _.get(
        _.find(detailObjForean.accessory, { article: accessorySumm.article }),
        "ean"
      ),
      quantity: 0,
      unite: accessorySumm.unite,
      quantityConditioned: 0,
      uniteDeVente: accessorySumm.saleUnit,
      zone: accessorySumm.zone,
    };

    globalAccessory[accessorySumm.article] = {
      ...newGlobalAccessRow,
      quantity: newGlobalAccessRow.quantity + accessorySumm.quantity,
      quantityConditioned:
        newGlobalAccessRow.quantityConditioned +
        accessorySumm.quantityConditioned,
    };

    let newGlobalAccessZoneRow = globalAccessorybyZone[
      `${accessorySumm.article}-${accessorySumm.zone}`
    ] || {
      level: accessorySumm.level,
      article: accessorySumm.article,
      ean: _.get(
        _.find(detailObjForean.accessory, { article: accessorySumm.article }),
        "ean"
      ),
      quantity: 0,
      unite: accessorySumm.unite,
      quantityConditioned: 0,
      uniteDeVente: accessorySumm.saleUnit,
      zone: accessorySumm.zone,
    };

    globalAccessorybyZone[`${accessorySumm.article}-${accessorySumm.zone}`] = {
      ...newGlobalAccessZoneRow,
      quantity: newGlobalAccessZoneRow.quantity + accessorySumm.quantity,
      quantityConditioned:
        newGlobalAccessZoneRow.quantityConditioned +
        accessorySumm.quantityConditioned,
    };
  });

  //#endregion Global accessory extract

  let { plaqCount, globalZonePlq } = await addGlobalZonePlqsInSummarySheet(
    detailsRow,
    summarySheet,
    rowDetailsCount
  );

  let { montantCount, globalZoneMontant } =
    await addGlobalZoneMontantInSummarySheet(
      detailsRow,
      summarySheet,
      plaqCount
    );

  let { railCount, globalZoneRail } = await addGlobalZoneRailInSummarySheet(
    detailsRow,
    summarySheet,
    montantCount
  );

  rowDetailsCount = await addGlobalZoneWoolInSummarySheet(
    globalWool,
    summarySheet,
    railCount
  );

  rowDetailsCount = await addGlobalZoneAccessoryInSummarySheet(
    globalAccessory,
    summarySheet,
    rowDetailsCount
  );

  //#region Global group by zone

  const zoneArticle = concat(
    map(globalZonePlq, (g) => {
      return g;
    }),
    map(globalZoneMontant, (g) => {
      return g;
    }),
    map(globalZoneRail, (g) => {
      return g;
    }),
    map(globalWoolbyZone, (g) => {
      return g;
    }),
    map(globalAccessorybyZone, (g) => {
      return g;
    })
  );

  const groupedZoneArticle = groupBy(zoneArticle, "zone");

  let summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = ["GLOBAL PAR ZONE"];
  summarySheet.mergeCells(
    `A${summarySheetRow.number}:K${summarySheetRow.number}`
  );

  summarySheetRow.eachCell(
    { includeEmpty: true },
    function (cell: Excel.Cell, colNumber: any) {
      cell.alignment = {
        horizontal: "left",
        vertical: "middle",
      };
      cell.font = {
        bold: true,
        color: { argb: "#000" },
        family: 2,
        name: "Calibri",
        size: 8,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "005EB8",
        },
      };
    }
  );

  rowDetailsCount = rowDetailsCount + 1;

  forEach(groupedZoneArticle, function (value, key) {
    summarySheetRow = summarySheet.getRow(rowDetailsCount);

    summarySheetRow.values = ["", "", "", "", key];

    summarySheet.mergeCells(
      `E${summarySheetRow.number}:K${summarySheetRow.number}`
    );

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: true,
          color: {
            argb: "#000",
          },
          family: 2,
          name: "Arial",
          size: 8,
        };
        if (colNumber > 4) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;

    summarySheetRow = summarySheet.getRow(rowDetailsCount);

    summarySheetRow.values = [
      "",
      "",
      "",
      "",
      "Code EAN",
      "Désignation de l'article",
      "Nb articles",
      "Q",
      "U",
      "Q",
      "U",
    ];

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: true,
          color: {
            argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
          },
          family: 2,
          name: "Arial",
          size: 8,
        };
        if (colNumber > 4) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
    forEach(value, (element) => {
      summarySheetRow = summarySheet.getRow(rowDetailsCount);

      summarySheetRow.values =
        element.nbArticles === 0
          ? [
              "",
              "",
              "",
              "",
              element.ean ? element.ean : "",
              element.article,
              "",
              "",
              "",
              "",
              "",
            ]
          : [
              "",
              "",
              "",
              "",
              element.ean ? element.ean : "",
              element.article,
              printNullOrZero(element.nbArticles),
              printNullOrZero(element.quantity),
              unitTobePrinted(element.unite),
              printNullOrZero(element.quantityConditioned),
              unitTobePrinted(element.uniteDeVente),
            ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 5) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          if (colNumber === 10 && cell.value === "0") {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 8 || colNumber === 9 ? true : false,
            color: {
              argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 8,
          };
          if (colNumber > 4) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
          if (colNumber === 5) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    });
  });
  //#endregion

  //#region Detail Heading
  summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = ["DETAILS PAR ETAGES ET ZONES"];
  summarySheet.mergeCells(
    `A${summarySheetRow.number}:K${summarySheetRow.number}`
  );

  summarySheetRow.eachCell(
    { includeEmpty: true },
    function (cell: Excel.Cell, colNumber: any) {
      cell.alignment = {
        horizontal: "left",
        vertical: "middle",
      };
      cell.font = {
        bold: true,
        color: { argb: "#000" },
        family: 2,
        name: "Calibri",
        size: 8,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FFCE00",
        },
      };
    }
  );

  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  //#region Detail Headers row

  summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = [
    "Niveau",
    "Zone",
    "Ouvrage",
    "Référence interne (Système REVIT)",
    "Code EAN",
    "Désignation de l'article",
    "Nb articles",
    "Q",
    "U",
    "Q",
    "U",
  ];

  summarySheetRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
    cell.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    cell.font = {
      bold: true,
      color: { argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000" },
      family: 2,
      name: "Arial",
      size: 8,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  // add caculated detail row
  //#region detailsRow

  let detailsOrderedRow = _.orderBy(
    detailsRow,
    ["levelElevation", "zone", "solution", "walltype"],
    ["asc", "asc", "asc", "asc"]
  );

  _.forEach(
    detailsOrderedRow,
    (detailRow: SummaryGroupDetail, detailRowIndex: any) => {
      let summerySheetRowNumber = rowDetailsCount;
      let isPlaqMontatRailPrinted = false;

      rowDetailsCount = rowDetailsCount + 1;

      _.forEach(
        detailRow.plaqs,
        (plaqRow: SummaryDetail, plaqRowIndex: string) => {
          if (plaqRow.nbArticles !== 0) {
            isPlaqMontatRailPrinted = true;
            summarySheetRow = summarySheet.getRow(rowDetailsCount);
            printSummaryZoneDetailRow(summarySheetRow, plaqRow);

            for (let index = 1; index <= summarySheetRow.cellCount; index++) {
              let cell = summarySheetRow.getCell(index);
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cell.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
              };
              cell.font = {
                bold: index === 8 || index === 9 ? true : false,
                color: {
                  argb: index === 8 || index === 9 ? "FF4473c4" : "#000",
                },
                family: 2,
                name: "Arial",
                size: 8,
              };
              if (cell.address.startsWith("E")) {
                cell.model.type = 2;
                cell.numFmt = "0";
              }
            }
            rowDetailsCount = rowDetailsCount + 1;
          }
        }
      );

      _.forEach(
        detailRow.montants,
        (montantRow: SummaryDetail, montantRowIndex: string) => {
          if (montantRow.nbArticles !== 0) {
            isPlaqMontatRailPrinted = true;
            summarySheetRow = summarySheet.getRow(rowDetailsCount);
            printSummaryZoneDetailRow(summarySheetRow, montantRow);

            for (let index = 1; index <= summarySheetRow.cellCount; index++) {
              let cell = summarySheetRow.getCell(index);
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cell.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
              };
              cell.font = {
                bold: index === 8 || index === 9 ? true : false,
                color: {
                  argb: index === 8 || index === 9 ? "FF4473c4" : "#000",
                },
                family: 2,
                name: "Arial",
                size: 8,
              };
              if (cell.address.startsWith("E")) {
                cell.model.type = 2;
                cell.numFmt = "0";
              }
            }
            rowDetailsCount = rowDetailsCount + 1;
          }
        }
      );

      _.forEach(
        detailRow.rails,
        (railRow: SummaryDetail, railRowIndex: string) => {
          if (railRow.nbArticles !== 0) {
            isPlaqMontatRailPrinted = true;
            summarySheetRow = summarySheet.getRow(rowDetailsCount);
            printSummaryZoneDetailRow(summarySheetRow, railRow);

            for (let index = 1; index <= summarySheetRow.cellCount; index++) {
              let cell = summarySheetRow.getCell(index);
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cell.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
              };
              cell.font = {
                bold: index === 8 || index === 9 ? true : false,
                color: {
                  argb: index === 8 || index === 9 ? "FF4473c4" : "#000",
                },
                family: 2,
                name: "Arial",
                size: 8,
              };
              if (cell.address.startsWith("E")) {
                cell.model.type = 2;
                cell.numFmt = "0";
              }
            }
            rowDetailsCount = rowDetailsCount + 1;
          }
        }
      );

      if (isPlaqMontatRailPrinted) {
        summarySheetRow = summarySheet.getRow(summerySheetRowNumber);

        summarySheetRow.values = [
          detailRow.level,
          detailRow.zone || "",
          detailRow.solution,
          detailRow.elementType,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];

        for (let index = 1; index <= summarySheetRow.cellCount; index++) {
          let cell = summarySheetRow.getCell(index);
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: true,
            color: { argb: "#000" },
            family: 2,
            name: "Arial",
            size: 8,
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "D9D9D9",
            },
          };
        }
      }
    }
  );

  return detailsRow;
};

//#region Commande New

export const writeCommandeNew = async (
  workbookObject: Excel.Workbook,
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  unitConversionArray: any,
  logoImageId: number,
  config: any
) => {
  /// Loop over detailsRow to collect Plaqs, Montant, Rails, Wool, Accessory
  /// For Details table
  let detailsTableArray: {
    [Key: string]: DetailCommande;
  } = {};
  let globalTableArray: {
    [Key: string]: GlobalCommande;
  } = {};

  let globalZoneTableArray: {
    [Key: string]: GlobalCommande;
  } = {};

  /// Collect Plaqs and Stored it in Key/Value pair
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.plaqs,
        (plaqRow: SummaryDetail, plaqRowIndex: string) => {
          /// Check key with Article name and Level name is present in detailsTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newPlaqRow = detailsTableArray[
            `${plaqRow.article}/${plaqRow.levelName}`
          ] || {
            levelName: plaqRow.levelName,
            article: plaqRow.article,
            ean: plaqRow.ean,
            quantity: 0,
            unite: plaqRow.unite,
            quantityConditioned: 0,
            uniteDeVente: plaqRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[`${plaqRow.article}/${plaqRow.levelName}`] = {
            ...newPlaqRow,
            quantity: newPlaqRow.quantity + plaqRow.quantity,
            quantityConditioned:
              newPlaqRow.quantityConditioned + plaqRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newPlaqGlobalRow = globalTableArray[`${plaqRow.article}`] || {
            article: plaqRow.article,
            ean: plaqRow.ean,
            quantity: 0,
            unite: plaqRow.unite,
            quantityConditioned: 0,
            uniteDeVente: plaqRow.uniteDeVente,
            element: "plaq",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${plaqRow.article}`] = {
            ...newPlaqGlobalRow,
            quantity: newPlaqGlobalRow.quantity + plaqRow.quantity,
            quantityConditioned:
              newPlaqGlobalRow.quantityConditioned +
              plaqRow.quantityConditioned,
          };

          let newZonePlaqGlobalRow = globalZoneTableArray[
            `${plaqRow.article}-${plaqRow.zone}`
          ] || {
            article: plaqRow.article,
            ean: plaqRow.ean,
            quantity: 0,
            unite: plaqRow.unite,
            quantityConditioned: 0,
            uniteDeVente: plaqRow.uniteDeVente,
            element: "plaq",
            levelElevation: detailRow.levelElevation,
            zone: plaqRow.zone,
          };
          globalZoneTableArray[`${plaqRow.article}-${plaqRow.zone}`] = {
            ...newZonePlaqGlobalRow,
            quantity: newZonePlaqGlobalRow.quantity + plaqRow.quantity,
            quantityConditioned:
              newZonePlaqGlobalRow.quantityConditioned +
              plaqRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Montants and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.montants,
        (montantRow: SummaryDetail, plaqRowIndex: string) => {
          /// Check key with Article name and Level name is present in detailsTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newMontantRow = detailsTableArray[
            `${montantRow.article}/${montantRow.levelName}`
          ] || {
            levelName: montantRow.levelName,
            article: montantRow.article,
            ean: montantRow.ean,
            quantity: 0,
            unite: montantRow.unite,
            quantityConditioned: 0,
            uniteDeVente: montantRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[`${montantRow.article}/${montantRow.levelName}`] = {
            ...newMontantRow,
            quantity: newMontantRow.quantity + montantRow.quantity,
            quantityConditioned:
              newMontantRow.quantityConditioned +
              montantRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newMontantGlobalRow = globalTableArray[
            `${montantRow.article}`
          ] || {
            article: montantRow.article,
            ean: montantRow.ean,
            quantity: 0,
            unite: montantRow.unite,
            quantityConditioned: 0,
            uniteDeVente: montantRow.uniteDeVente,
            element: "montant",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${montantRow.article}`] = {
            ...newMontantGlobalRow,
            quantity: newMontantGlobalRow.quantity + montantRow.quantity,
            quantityConditioned:
              newMontantGlobalRow.quantityConditioned +
              montantRow.quantityConditioned,
          };

          let newMontantZoneGlobalRow = globalZoneTableArray[
            `${montantRow.article}-${montantRow.zone}`
          ] || {
            article: montantRow.article,
            ean: montantRow.ean,
            quantity: 0,
            unite: montantRow.unite,
            quantityConditioned: 0,
            uniteDeVente: montantRow.uniteDeVente,
            element: "montant",
            levelElevation: detailRow.levelElevation,
            zone: montantRow.zone,
          };
          globalZoneTableArray[`${montantRow.article}-${montantRow.zone}`] = {
            ...newMontantZoneGlobalRow,
            quantity: newMontantZoneGlobalRow.quantity + montantRow.quantity,
            quantityConditioned:
              newMontantZoneGlobalRow.quantityConditioned +
              montantRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Rails and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.rails,
        (railRow: SummaryDetail, railRowIndex: string) => {
          let newRailRow = detailsTableArray[
            `${railRow.article}/${railRow.levelName}`
          ] || {
            levelName: railRow.levelName,
            article: railRow.article,
            ean: railRow.ean,
            quantity: 0,
            unite: railRow.unite,
            quantityConditioned: 0,
            uniteDeVente: railRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[`${railRow.article}/${railRow.levelName}`] = {
            ...newRailRow,
            quantity: newRailRow.quantity + railRow.quantity,
            quantityConditioned:
              newRailRow.quantityConditioned + railRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newRailGlobalRow = globalTableArray[`${railRow.article}`] || {
            article: railRow.article,
            ean: railRow.ean,
            quantity: 0,
            unite: railRow.unite,
            quantityConditioned: 0,
            uniteDeVente: railRow.uniteDeVente,
            element: "rail",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${railRow.article}`] = {
            ...newRailGlobalRow,
            quantity: newRailGlobalRow.quantity + railRow.quantity,
            quantityConditioned:
              newRailGlobalRow.quantityConditioned +
              railRow.quantityConditioned,
          };

          let newRailZoneGlobalRow = globalZoneTableArray[
            `${railRow.article}-${railRow.zone}`
          ] || {
            article: railRow.article,
            ean: railRow.ean,
            quantity: 0,
            unite: railRow.unite,
            quantityConditioned: 0,
            uniteDeVente: railRow.uniteDeVente,
            element: "rail",
            levelElevation: detailRow.levelElevation,
            zone: railRow.zone,
          };
          globalZoneTableArray[`${railRow.article}-${railRow.zone}`] = {
            ...newRailZoneGlobalRow,
            quantity: newRailZoneGlobalRow.quantity + railRow.quantity,
            quantityConditioned:
              newRailZoneGlobalRow.quantityConditioned +
              railRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Wool and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.wool,
        (woolRow: SummaryDetail, woolRowIndex: string) => {
          let newWoolRow = detailsTableArray[
            `${woolRow.article}/${woolRow.levelName}`
          ] || {
            levelName: woolRow.levelName,
            article: woolRow.article,
            ean: woolRow.ean,
            quantity: 0,
            unite: woolRow.unite,
            quantityConditioned: 0,
            uniteDeVente: woolRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
            sellingPackage: woolRow.sellingPackage,
          };
          detailsTableArray[`${woolRow.article}/${woolRow.levelName}`] = {
            ...newWoolRow,
            quantity: newWoolRow.quantity + woolRow.quantity,
            quantityConditioned:
              newWoolRow.quantityConditioned + woolRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newWoolGlobalRow = globalTableArray[`${woolRow.article}`] || {
            article: woolRow.article,
            ean: woolRow.ean,
            quantity: 0,
            unite: woolRow.unite,
            quantityConditioned: 0,
            uniteDeVente: woolRow.uniteDeVente,
            element: "wool",
            levelElevation: detailRow.levelElevation,
            sellingPackage: woolRow.sellingPackage,
          };
          globalTableArray[`${woolRow.article}`] = {
            ...newWoolGlobalRow,
            quantity: newWoolGlobalRow.quantity + woolRow.quantity,
            quantityConditioned:
              newWoolGlobalRow.quantityConditioned +
              woolRow.quantityConditioned,
          };

          let newWoolZoneGlobalRow = globalZoneTableArray[
            `${woolRow.article}-${woolRow.zone}`
          ] || {
            article: woolRow.article,
            ean: woolRow.ean,
            quantity: 0,
            unite: woolRow.unite,
            quantityConditioned: 0,
            uniteDeVente: woolRow.uniteDeVente,
            element: "wool",
            levelElevation: detailRow.levelElevation,
            zone: woolRow.zone,
            sellingPackage: woolRow.sellingPackage,
          };
          globalZoneTableArray[`${woolRow.article}-${woolRow.zone}`] = {
            ...newWoolZoneGlobalRow,
            quantity: newWoolZoneGlobalRow.quantity + woolRow.quantity,
            quantityConditioned:
              newWoolZoneGlobalRow.quantityConditioned +
              woolRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Accessory and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.accessory,
        (accessoryRow: SummaryDetail, accessoryRowIndex: string) => {
          let newAccessoryRow = detailsTableArray[
            `${accessoryRow.article}/${accessoryRow.levelName}`
          ] || {
            levelName: accessoryRow.levelName,
            article: accessoryRow.article,
            ean: accessoryRow.ean,
            quantity: 0,
            unite: accessoryRow.unite,
            quantityConditioned: 0,
            uniteDeVente: accessoryRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[
            `${accessoryRow.article}/${accessoryRow.levelName}`
          ] = {
            ...newAccessoryRow,
            quantity: newAccessoryRow.quantity + accessoryRow.quantity,
            quantityConditioned:
              newAccessoryRow.quantityConditioned +
              accessoryRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newAccessoryGlobalRow = globalTableArray[
            `${accessoryRow.article}`
          ] || {
            article: accessoryRow.article,
            ean: accessoryRow.ean,
            quantity: 0,
            unite: accessoryRow.unite,
            quantityConditioned: 0,
            uniteDeVente: accessoryRow.uniteDeVente,
            element: "accessory",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${accessoryRow.article}`] = {
            ...newAccessoryGlobalRow,
            quantity: newAccessoryGlobalRow.quantity + accessoryRow.quantity,
            quantityConditioned:
              newAccessoryGlobalRow.quantityConditioned +
              accessoryRow.quantityConditioned,
          };

          let newAccessoryZoneGlobalRow = globalZoneTableArray[
            `${accessoryRow.article}-${accessoryRow.zone}`
          ] || {
            article: accessoryRow.article,
            ean: accessoryRow.ean,
            quantity: 0,
            unite: accessoryRow.unite,
            quantityConditioned: 0,
            uniteDeVente: accessoryRow.uniteDeVente,
            element: "accessory",
            levelElevation: detailRow.levelElevation,
            zone: accessoryRow.zone,
          };
          globalZoneTableArray[`${accessoryRow.article}-${accessoryRow.zone}`] =
            {
              ...newAccessoryZoneGlobalRow,
              quantity:
                newAccessoryZoneGlobalRow.quantity + accessoryRow.quantity,
              quantityConditioned:
                newAccessoryZoneGlobalRow.quantityConditioned +
                accessoryRow.quantityConditioned,
            };
        }
      );
    }
  );

  _.map(globalTableArray, (globalRow: any, globalRowIndex: any) => {
    let roundedQuantityConditioned = String(
      ceil(globalRow.quantityConditioned)
    );
    globalRow.quantity = oneToOneCommandeMapping(
      unitConversionArray,
      globalRow.uniteDeVente,
      globalRow.unite,
      roundedQuantityConditioned,
      globalRow.element,
      globalRow.sellingPackage ? globalRow.sellingPackage : ""
    );
    globalRow.quantityConditioned = roundedQuantityConditioned;
  });

  _.map(globalZoneTableArray, (globalRow: any, globalRowIndex: any) => {
    let roundedQuantityConditioned = String(
      ceil(globalRow.quantityConditioned)
    );
    globalRow.quantity = oneToOneCommandeMapping(
      unitConversionArray,
      globalRow.uniteDeVente,
      globalRow.unite,
      roundedQuantityConditioned,
      globalRow.element,
      globalRow.sellingPackage ? globalRow.sellingPackage : ""
    );
    globalRow.quantityConditioned = roundedQuantityConditioned;
  });

  let commandeSheet = workbookObject.getWorksheet("Quantitatifs achats");

  //logoImageId !== 0 && commandeSheet.addImage(logoImageId, "F1:G3");
  logoImageId !== 0 &&
    commandeSheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 110, height: 90 },
    });
  await setDossierTechnicalInfo(commandeSheet, "B", "D");

  let rowDetailsCount: number = 17;
  let commandeSheetRow = commandeSheet.getRow(rowDetailsCount);
  _.forEach(
    globalTableArray,
    (globalRow: GlobalCommande, globalRowIndex: string) => {
      let commandeSheetRow = commandeSheet.getRow(rowDetailsCount);
      commandeSheetRow.values = [
        "",
        globalRow.ean,
        globalRow.article,
        printNullOrZero(globalRow.quantity),
        unitTobePrinted(globalRow.unite),
        printNullOrZero(globalRow.quantityConditioned),
        unitTobePrinted(globalRow.uniteDeVente),
      ];

      commandeSheetRow.eachCell(function (cell: Excel.Cell, colNumber: number) {
        if (colNumber !== 1) {
          setCellBorder(cell, true);
        }
        setCellAlignment(cell, "center", "middle");

        if (colNumber === 4 || colNumber === 5) {
          setCellFont(cell, "Arial", 8, true, "blue");
        } else {
          setCellFont(cell, "Arial", 8, false, "black");
        }

        if (colNumber === 2 || colNumber === 6) {
          setColumnType(cell);
        }
      });

      rowDetailsCount = rowDetailsCount + 1;
    }
  );

  commandeSheetRow = commandeSheet.getRow(rowDetailsCount);
  commandeSheetRow.values = ["GLOBAL PAR ZONE"];
  commandeSheet.mergeCells(
    `A${commandeSheetRow.number}:G${commandeSheetRow.number}`
  );

  commandeSheetRow.eachCell(
    { includeEmpty: true },
    function (cell: Excel.Cell, colNumber: any) {
      cell.alignment = {
        horizontal: "left",
        vertical: "middle",
      };
      cell.font = {
        bold: true,
        color: { argb: "#000" },
        family: 2,
        name: "Calibri",
        size: 8,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "005EB8",
        },
      };
    }
  );

  rowDetailsCount = rowDetailsCount + 1;

  let groupedGlobalZone = groupBy(globalZoneTableArray, "zone");

  forEach(groupedGlobalZone, function (value, key) {
    commandeSheetRow = commandeSheet.getRow(rowDetailsCount);

    commandeSheetRow.values = ["", key];

    commandeSheet.mergeCells(
      `B${commandeSheetRow.number}:G${commandeSheetRow.number}`
    );

    commandeSheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: true,
          color: {
            argb: "#000",
          },
          family: 2,
          name: "Arial",
          size: 8,
        };
        if (colNumber > 1) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;

    commandeSheetRow = commandeSheet.getRow(rowDetailsCount);

    commandeSheetRow.values = [
      "",
      "Code EAN",
      "Désignation de l'article",
      "Q",
      "U",
      "Q",
      "U",
    ];

    commandeSheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: true,
          color: {
            argb: colNumber === 4 || colNumber === 5 ? "FF4473c4" : "#000",
          },
          family: 2,
          name: "Arial",
          size: 8,
        };
        if (colNumber > 1) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
    forEach(value, (element) => {
      commandeSheetRow = commandeSheet.getRow(rowDetailsCount);

      commandeSheetRow.values =
        element.quantity === 0
          ? ["", element.ean, element.article, "", "", "", ""]
          : [
              "",
              element.ean,
              element.article,
              printNullOrZero(element.quantity),
              unitTobePrinted(element.unite),
              printNullOrZero(element.quantityConditioned),
              unitTobePrinted(element.uniteDeVente),
            ];

      commandeSheetRow.eachCell(function (cell: Excel.Cell, colNumber: number) {
        if (colNumber !== 1) {
          setCellBorder(cell, true);
        }
        setCellAlignment(cell, "center", "middle");

        if (colNumber === 4 || colNumber === 5) {
          setCellFont(cell, "Arial", 8, true, "blue");
        } else {
          setCellFont(cell, "Arial", 8, false, "black");
        }

        if (colNumber === 2 || colNumber === 6) {
          setColumnType(cell);
        }
      });

      rowDetailsCount = rowDetailsCount + 1;
    });
  });

  //#region Detail Heading
  commandeSheetRow = commandeSheet.getRow(rowDetailsCount);
  commandeSheetRow.values = ["DETAILS", "", "", "", "", "", ""];

  commandeSheetRow.eachCell(
    { includeEmpty: true },
    function (cell: Excel.Cell, colNumber: number) {
      setCellAlignment(cell, "left", "middle");
      setCellFont(cell, "Calibri", 8, true, "black");
      setSolidFill(cell);
    }
  );

  commandeSheet.mergeCells(`A${rowDetailsCount}:B${rowDetailsCount}`);
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  //#region Detail Headers row
  commandeSheetRow = commandeSheet.getRow(rowDetailsCount);
  commandeSheetRow.values = [
    "Niveau",
    "Code EAN",
    "Désignation de l'article",
    "Q",
    "U",
    "Q",
    "U",
  ];

  commandeSheetRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
    setCellAlignment(cell, "center", "middle");

    if (colNumber === 4 || colNumber === 5) {
      setCellFont(cell, "Arial", 8, true, "blue");
    } else {
      setCellFont(cell, "Arial", 8, true, "black");
    }

    setCellBorder(cell, true);
  });
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  const sortedCommande: DetailCommande[] = _.sortBy(detailsTableArray, [
    "levelElevation",
  ]);

  _.forEach(
    sortedCommande,
    (sortedDetailsRow: DetailCommande, sortedDetailsRowIndex: number) => {
      if (sortedDetailsRow.quantity !== 0) {
        commandeSheetRow = commandeSheet.getRow(rowDetailsCount);
        commandeSheetRow.values = [
          sortedDetailsRow.levelName,
          sortedDetailsRow.ean,
          sortedDetailsRow.article,
          printNullOrZero(sortedDetailsRow.quantity),
          unitTobePrinted(sortedDetailsRow.unite),
          printNullOrZero(sortedDetailsRow.quantityConditioned),
          unitTobePrinted(sortedDetailsRow.uniteDeVente),
        ];

        commandeSheetRow.eachCell(
          { includeEmpty: true },
          function (cell: Excel.Cell, colNumber: number) {
            setCellBorder(cell, true);
            setCellAlignment(cell, "center", "middle");
            if (colNumber === 4 || colNumber === 5) {
              setCellFont(cell, "Arial", 8, true, "blue");
            } else {
              setCellFont(cell, "Arial", 8, false, "black");
            }

            if (colNumber === 2) {
              setColumnType(cell);
            }

            if (
              colNumber === 6 &&
              (cell.value === "00" ||
                cell.value === "000000" ||
                cell.value === 0 ||
                cell.value === "0")
            ) {
              setColumnType(cell);
            }
          }
        );

        rowDetailsCount = rowDetailsCount + 1;
      }
    }
  );
};

//#endregion commande new
