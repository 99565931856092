import React from "react";
import { Grid, Header, Menu, SemanticCOLORS, Button } from "semantic-ui-react";
import { TypeItems } from "./TypeItems";
import { TypeData } from "../../../../RevitJS/Types/StoreTypes";
import _ from "lodash";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { SelectionStore } from "../Reducers";
import { connect } from "react-redux";

type Props = {
  color: SemanticCOLORS;
  columnTitle: string;
  selectedLevels: string[];
  typesData: TypeData[];
  selectAllType: any;
  currSelection: any;
  config: any;
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    currSelection: state.bufferSelection,
    config: state.config,
  };
};
const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
class TypesColumn extends React.Component<Props, { [key: string]: any }> {
  constructor(props: Props) {
    super(props);
    this.state = { currentType: _.cloneDeep(this.props.typesData) };
  }
  removeDataAlreadyExistInOtherZone = async () => {
    // if it's not a zone dont do anything
    if (!this.props.currSelection?.Zone) {
      this.setState({
        currentType: this.props.typesData,
      });

      return;
    }
    //Current Revit Id
    const currentProjId = this.props.currSelection.RevitView;
    const type: string =
      this.props.columnTitle === "Types de murs" ? "wall" : "ceiling";
    // get all placoData
    let placoSelections: any = await bimStorage
      .getItem(storageKey.PLACOSELECTIONS)
      .then((e: any) => {
        if (e) {
          return Object.values(e)
            .filter((a: any) => {
              return a.RevitView === currentProjId && a.Zone;
            })
            .reduce((acc: any, curr: any) => {
              return [
                ...acc,
                ...curr.SelectionByType[type].Rows.map((typeData: any) => {
                  return typeData.RevitSelection.Ids;
                }),
              ];
            }, []);
        }
      });

    const updatedList = this.props.typesData
      .map((e: any) => {
        const updatedIds = e.Ids.filter((id: any) => {
          return !_.flatten(placoSelections).some((b: any) => b === id);
        });
        return {
          ...e,
          Ids: updatedIds,
          Count: updatedIds.length,
        };
      })
      .filter((e: any) => {
        return e.Count > 0;
      });

    this.setState({
      currentType: updatedList,
    });
  };
  componentDidMount() {
    this.removeDataAlreadyExistInOtherZone();
  }
  componentDidUpdate(PrevProps: any, PrevState: any) {
    if (PrevProps !== this.props) {
      this.removeDataAlreadyExistInOtherZone();
    }
  }

  render() {
    const { color, columnTitle, selectedLevels } = this.props;

    const isOneChecked = _.filter(this.state.currentType, { Checked: true });

    return (
      <Grid.Column>
        <Header
          as="h3"
          textAlign="center"
          color={color}
          style={{ fontSize: "14px", marginBottom: "2px" }}
        >
          {columnTitle}
        </Header>
        <Menu
          style={{ maxHeight: "40vh", overflow: "auto", marginTop: "7px" }}
          vertical
          size="small"
          fluid
        >
          <TypeItems
            selectedLevels={selectedLevels}
            typesData={this.state.currentType}
          />
        </Menu>
        <div style={{ textAlign: "center" }}>
          <Button
            disabled={this.props.selectedLevels.length === 0 ? true : false}
            onClick={() =>
              this.props.selectAllType(isOneChecked.length > 0 ? false : true)
            }
            circular
            primary
            icon={isOneChecked.length > 0 ? "times" : "check"}
            // disabled={this.allLevelStatus(
            //   this.props.selectedLevels,
            //   this.props.levelsData,
            //   this.props.selectedTabType
            // )}
          />
        </div>
      </Grid.Column>
    );
  }
}
const ConnectedComponent = connector(TypesColumn);
export { ConnectedComponent as TypesColumn };
//export default connector(TypesColumn)
