import { filter } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react"
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../BIMStore";
import { dbStoreNameCalepinage } from "../../../Plugins/PlacoBIMv3/Calpinage/Components/root";
import { api } from "../../../RevitJS/API";
import { initProjectData, PlacoOptions, RowOptions, SelectionGroup } from "../../../RevitJS/Types/StoreTypes";
import { ReengineeringRoutes } from "../common/re_engineering_index";
import { CalpinageComponent } from "./childComponents/calpinageComponent";
import { DossierTabComponent } from "./childComponents/dossierTabComponent";
import { SelectionComponent } from "./childComponents/selectionComponent";
import { HeaderComponent } from "./common/headerComponent";
import { SuccesPopupComponent } from "./common/successPopupComponent";
import { asyncForEach } from "../../../Plugins/PlacoBIMv3/Selection/Actions";
import _ from "lodash";

interface Props {
  moduleName: string;
  route: string;
  setRoute: any;
  setModuleName: any;
  translations: any;
  language: string;
  pluginName?: string;
}

export type Selection<T extends RowOptions> = {
  Name: string;
  Id: string;
  Duplicated: number;
  Zone: boolean;
  SelectionByType: {
    wall: SelectionGroup<T>;
    ceiling: SelectionGroup<T>;
    others: SelectionGroup<T>;
  };
  Date: string;
  Levels: string[];
  Update: boolean;
  Color: any;
  RevitView?: string;
  Time: number;
};


export const ExportModuleComponent = (props: Props) => {
  const [moduleData, setdata] = useState<any[]>([]);
  const [selectionID, setSelectionID] = useState<any[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [active, setActive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popupSuccessDetails, setPopupSuccessDetails] = useState<any>({});
  const [revitDetails, setRevitDetails] = useState<any>('');

  useEffect(() => {
    setActive(false);
    setShowPopup(false);
    api.windowsHandler.resizeWindow(920, 500);
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let data: any = [];
      let count = 0;
      let selectionList: any = [];
      const projectData: initProjectData = await api.queries.getSetProjectData();
      setRevitDetails(projectData);
      if (props.pluginName === 'Gyproc') {
        const modules = await bimStorage.listSelection();

        for (const s of modules) {
          let selection = await new Promise((resolve, reject) =>
            window.indec.getSelection(parseInt(s.Id), resolve, reject)
          )
            .then((x: any) => {
              return x;
            })
            .catch((ex: any) => {
              console.error(ex);
              return [];
            });
          // let selection = await bimStorage.getSelection(s.Id)
          selectionList.push(selection);
        }
      } else {
        const placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
        selectionList = placoSelections as { [key: string]: Selection<PlacoOptions>; };
      }
      if (props.moduleName === "Selection") {
        data = selectionList;
        setSelectionID(Object.keys(selectionList));
      }
      else if (props.moduleName === 'Calepinage') {
        if (props.pluginName === 'Gyproc') {
          const calepinageList = await bimStorage.listCalepinage();

          let calepinageData: any[] = [];
          if (calepinageList !== '') {
            await asyncForEach(calepinageList, async (element: any) => {
              const calepinageDetail = await new Promise((resolve, reject) =>
                window.indec.getCalepinage(parseInt(element.Id), resolve, reject)
              )
                .then((x: any) => {
                  return x;
                })
                .catch((ex: any) => {
                  console.error(ex);
                  return [];
                });
              // const calepinageDetail = await bimStorage.getCalepinage(element.Id);
              let calpinageSelections: any = [];
              _.forEach(selectionList, (d: any) => {
                const s = filter(calepinageDetail.SelectionIds, function (id: any) {
                  return +id === d.Id;
                });
  
                if (s.length > 0) {
                  calpinageSelections.push(d);
                }
  
              });
  
              calepinageDetail.selectionList = calpinageSelections;
              calepinageDetail.Name = element.Name;
  
              calepinageData.push(calepinageDetail);
              count++;
              if (count === calepinageList.length) {
                data = calepinageData;
                let key = data.map((d: any) => d['Id']);
                setSelectionID(key);
                setdata(data);
              }
            });
          }
        } else {
          const calepinageList = await bimStorage.listModule(dbStoreNameCalepinage, projectData.ProjectId);
          
          let calepinageData: any[] = [];
          if (calepinageList !== '') {
            await asyncForEach(calepinageList, async (element: any) => {
              const calepinageDetail = JSON.parse(await bimStorage.listDetailModule(dbStoreNameCalepinage, element.Id));
              let calpinageSelections: any = [];
              _.forEach(selectionList, (d: any) => {
                const s = filter(calepinageDetail.selections.list, function (cs: any) {
                  return cs.Id === d.Id;
                });
  
                if (s.length > 0) {
                  calpinageSelections.push(d);
                }
  
              });
  
              calepinageDetail.selectionList = calpinageSelections;
              calepinageDetail.Name = element.Name;
  
              calepinageData.push(calepinageDetail);
              count++;
              if (count === calepinageList.length) {
                data = calepinageData;
                let key = data.map((d: any) => d['Id']);
                setSelectionID(key);
                setdata(data);
              }
            });
          }
        }
      } else {
        getDossierData('DAO');
      }

      setdata(data);
      setTimeout(async () => {
        setLoading(false);
      }, 3000);

    }

    fetchData();

  }, []);

  const exportData = () => {
    setActive(false);
    if (selectedRecords.length === 0) {
      setActive(true);
      setPopupSuccessDetails({
        header: props.translations[props.language].header.warning,
        message: props.translations[props.language].message.warning
      });
      return false;
    }
    let data = { moduleName: props.moduleName, records: selectedRecords };
    const date = new Date();
    const fileName = props.moduleName + "_" + date.toDateString() + "_" + date.getTime();
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", fileName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    setActive(true);
    setPopupSuccessDetails({
      header: props.translations[props.language].header.save,
      message: props.translations[props.language].message.exportSuccess
    });
  }

  const getDossierData = async (documentName: string) => {
    const projectData: initProjectData = await api.queries.getSetProjectData();
    let dossierList = await bimStorage.getItem(storageKey.DOSSIER_TECHNIQUE);
    dossierList = dossierList.filter((d: any) => d.modelName === projectData.ProjectId);
    let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
    let selectionList = placoSelections as { [key: string]: Selection<PlacoOptions>; };
    let dossierData: any[] = []
    let count = 0;
    dossierList.forEach((element: any) => {
      if (element.documentType === documentName) {
        element.checked = false;
        element.selectionList = filter(selectionList, function (d: any) {
          return element.selections.list[0] === d.Id;
        });

        let index = selectedRecords.findIndex((f: any) => f.documentId === element.documentId);
        if (index > -1) {
          element.checked = true;
        }
        dossierData.push(element);
      }

      count++;
      if (count === dossierList.length) {
        let data = dossierData;
        let key = data.map((d: any) => d['documentId']);
        setSelectionID(key);
        setdata(data);
      }
    });
  }

  const closePopup = async () => {
    props.setModuleName('');
    await api.windowsHandler.resizeWindow(900, 200);
    props.setRoute(ReengineeringRoutes.ROOT);

  }

  const closeMessagePopup = async () => {
    if (popupSuccessDetails.header === "Document Saved" || popupSuccessDetails.header === "Document Saved") {
      props.setModuleName('');
      await api.windowsHandler.resizeWindow(900, 200);
      props.setRoute(ReengineeringRoutes.ROOT);
    } else {
      setActive(false);
    }
  }

  if (loading) {
    return (
      <Dimmer active={loading} page>
        <Loader />
      </Dimmer>
    );
  }

  if (moduleData !== undefined) {
    return (<div >
      <HeaderComponent
        Icon={props.pluginName==="Gyproc" ? "/plugin_new_logo/BeneluxIcon.png" : "/PlacoBIM.jpg"}
        name={props.moduleName}
        subheader=""
      ></HeaderComponent>
      <div style={{ padding: '10px' }}>
        {props.moduleName === 'Selection' ?
          <SelectionComponent moduleData={moduleData} selectionId={selectionID} selectedRecords={selectedRecords} pluginName={props.pluginName} setSelectedRecords={setSelectedRecords} translations={props.translations} language={props.language} revitViewName={revitDetails.ProjectId} ></SelectionComponent>
          : props.moduleName === 'Calepinage' ?
            <CalpinageComponent moduleData={moduleData} selectionId={selectionID} selectedRecords={selectedRecords} setSelectedRecords={setSelectedRecords} translations={props.translations} language={props.language}></CalpinageComponent>
            :
            <DossierTabComponent moduleData={moduleData} getDossierData={getDossierData} setSelectionID={setSelectionID} selectionId={selectionID} selectedRecords={selectedRecords} setSelectedRecords={setSelectedRecords} translations={props.translations} language={props.language}></DossierTabComponent>
        }
      </div>
      <div className="modalButton" style={{ padding: '10px', marginTop: "0" }}>
        <Button color="orange" onClick={() => closePopup()}>
          {props.translations[props.language].button.cancel}
        </Button>
        <Button
          type="submit"
          primary
          onClick={exportData}
        >
          {props.translations[props.language].button.export}
        </Button>
      </div>

      <SuccesPopupComponent
        active={active}
        setActive={setActive}
        closePopup={closeMessagePopup}
        header={popupSuccessDetails.header}
        message={popupSuccessDetails.message} />
    </div>)
  } else {
    return <></>
  }
}