export const myConfig = () => {
  console.log("myConfig: Loading");
  let url0 =
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port +
    "/env.config.json";
  return fetch(url0, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
};

export const myConfigSync = () => {
  var request = new XMLHttpRequest();
  let url0 =
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port +
    "/env.config.json";
  request.open("GET", url0, false);
  request.send(null);
  return JSON.parse(request.responseText);
};
