import { Selection } from "../Gyproc/Selection";
import { Calpinage } from "../Gyproc/Calepinage";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { Delete } from "../Gyproc/Delete";
import { Metres } from "../Gyproc/Metres";
import { Reperage } from "../Gyproc/Reperage";
import { ProjectInfo } from "../Gyproc/ProjectInfo";
import { Dimensions } from "./Dimensions";

import "./Resouces/GyprocStyle.css";
export const pluginId = "99f4551c-183c-4745-97b4-7d05eea2835b";
export const functionalityId = "fedebd62-1e5e-461c-a75b-9636e6607acc";

export const Gyproc: Plugin = {
  TechnicalName: "GYPROC",
  Name: "",
  Icon: "/plugin_new_logo/BeneluxIcon.png",
  ShortDesc: {
    English: "BIM with Gyproc® Solutions",
  },
  Id: pluginId,
  Color: "#005EB8",
  Functionalities: [
    ProjectInfo,
    Selection,
    Metres,
    Reperage,
    Calpinage,
    Dimensions,
    Delete,
  ],
};
