import { TableSortLabel, TextField } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import React from "react";
import { withTranslation } from "react-i18next";
import "./PixelTable.scss";

const PixelTableHead = ({
    order,
    orderBy,
    onRequestSort,
    headCells,
    filters,
    setFilter,
    t,
}) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <thead className="pixel-table-head">
            <tr>
                {headCells.map((headCell) => (
                    <td
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? "none" : "default"}
                    >
                        <div className="row">
                            <div className="offset-1 col-9 pr-1">
                                <TextField
                                    label={t(headCell.label)}
                                    variant="outlined"
                                    size="small"
                                    value={filters[headCell.id]}
                                    onChange={(event) =>
                                        setFilter(
                                            headCell.id,
                                            event.target.value
                                        )
                                    }
                                    fullWidth
                                />
                            </div>
                            <div className="col-2 pl-0 d-flex">
                                {orderBy === headCell.id ? (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={
                                            orderBy === headCell.id
                                                ? order
                                                : "asc"
                                        }
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        <span></span>
                                    </TableSortLabel>
                                ) : (
                                    <span className="order-by-this-button">
                                        <ImportExportIcon
                                            className="order-by-this-button-svg"
                                            onClick={createSortHandler(
                                                headCell.id
                                            )}
                                            style={{ color: "white" }}
                                        ></ImportExportIcon>
                                    </span>
                                )}
                            </div>
                        </div>
                    </td>
                ))}
                <td
                    key="action-cell"
                    padding="checkbox"
                    align="center"
                    style={{ width: "12%" }}
                >
                    <strong>
                        {/* <SettingsIcon style={{ color: "white" }}></SettingsIcon> */}
                    </strong>
                </td>
            </tr>
        </thead>
    );
};

PixelTableHead.propTypes = {};

export default withTranslation(["pam"])(PixelTableHead);
