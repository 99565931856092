import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
//import { api } from "../../../../../RevitJS/API";
import { selectWindowToShow } from "../../actions/appActions";
import {
  resetCurrentProject,
  setCurrentProjectSelectionMethod,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  SelectionMethod,
  STEP_2_ELEMENT_SELECTION,
} from "../../assets/constants";
import Element from "../../models/Element.model";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import _ from "lodash";
import "./elementSelection.scss";
import { api } from "../../../../../RevitJS/API";
import { Dimmer, Loader } from "semantic-ui-react";

class ElementSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.projectMenu = this.projectMenu.bind(this);
    this.setElementSelectionMethod = this.setElementSelectionMethod.bind(this);
  }

  state = {
    loading: false,
  };

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.PROJECT_COUNTRY_WINDOW);
  }

  async nextPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.SYSTEM_NAME_WINDOW);
  }

  // on cree la liste des items a ajouter au projet à partir de la séléction de range
  createElementsFromSelection(elements: any): Element[] {
    let itemsList: Element[] = [];
    // on rajoute a l'element la propriete selected avec une valeur false par default
    // et on ajoute ca a la liste des items qui va etre persisté
    elements.map((element: Range & { selected?: boolean }) => {
      let tmp: any = { ...element };
      tmp.selected = false;
      itemsList.push(tmp);
    });
    return itemsList;
  }

  async setElementSelectionMethod(
    method: SelectionMethod = SelectionMethod.SYSTEM_NAME
  ) {
    this.setState({loading : true})
    let { setAllAvailableItems } = this.props;
    // this.checkCorrectProjectCountry(projectName);
    // this.props.setCurrentProjectCountry(projectName);
    let allAvailableElements = [];
    switch (method) {
      case SelectionMethod.SYSTEM_NAME:
        allAvailableElements = await api.selection.pipesBySystemNameSelection();
        break;
      case SelectionMethod.MANUAL:
        api.windowsHandler.hideWindow();
        allAvailableElements = await api.selection.manualSelection("pipe");
        api.windowsHandler.showWindow();

        break;
    }
    let allAvailableItems =
      this.createElementsFromSelection(allAvailableElements);

    setAllAvailableItems(allAvailableItems);
    this.props.setCurrentProjectSelectionMethod(method);
    setTimeout(() => {
      if (allAvailableItems && allAvailableItems.length > 0) { 
        this.setState({loading : false})
        this.nextPage(); 
      }
    }, 2000);

  }

  render() {
    let { t } = this.props;
    if (this.state.loading) {
      return (
        <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
          <Loader></Loader>
        </Dimmer>
      );
    }

    return (
      <div id="element-selection-container">
        <Header displayName={t(STEP_2_ELEMENT_SELECTION) || ""}></Header>
        <Body
          elementSelectionButtonClicked={this.setElementSelectionMethod}
        ></Body>
        <span></span>
        <Footer
          savedProjectsButtonShow={true}
          savedProjectsButtonDisabled={false}
          savedProjectButtonClicked={this.projectMenu}
          disabledNextButton={true}
          showNextButton={false}
          clickedNextButton={this.nextPage}
          showBackButton={false}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    currentProject: state.projects.currentProject,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  setCurrentProjectSelectionMethod,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux &
  WithTranslation & { setAllAvailableItems: Function };

type IState = {};

export default connector(withTranslation(["pam"])(ElementSelection));
