import {
  GenericMappingRow,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import {
  createSelectionAction,
  initMappingTableAction,
  saveBufferAction,
  editSelectionAction,
  selectSystemAction,
  duplicateSystemAction,
  deleteBufferElement,
  updatingMappingTableAction,
  updateBufferSelection,
  toggleSelectionRowAction,
  toggleAllSelectionRowAction,
  updatingOtherPlacoSystems,
  selectPlacoOtherSystemAction,
  Selection,
  ReplaceBufferSelection,
} from "../Actions/types";
import _ from "lodash";
import {
  ProductDetailData,
  PimAttribute,
  CustomProductDetailData,
} from "../../../../RevitJS/Types/BddTypes";

type bufferSelectionAction =
  | createSelectionAction<PlacoOptions>
  | initMappingTableAction<PlacoOptions>
  | saveBufferAction<PlacoOptions>
  | editSelectionAction<PlacoOptions>
  | selectSystemAction
  | duplicateSystemAction
  | deleteBufferElement
  | updatingMappingTableAction<PlacoOptions>
  | updateBufferSelection
  | toggleSelectionRowAction
  | toggleAllSelectionRowAction
  | updatingOtherPlacoSystems<PlacoOptions>
  | selectPlacoOtherSystemAction
  | ReplaceBufferSelection;

export const bufferSelection = (
  bufferSelection: Selection<PlacoOptions> | null = null,
  action: bufferSelectionAction
): Selection<PlacoOptions> | null => {
  switch (action.type) {
    case "SELECT_SYSTEM":
      if (bufferSelection !== null) {
        if (action.SelectorType === "wall") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              wall: {
                ...bufferSelection.SelectionByType.wall,
                Rows: bufferSelection.SelectionByType.wall.Rows.map((row) => {
                  if (row.Index === action.Index) {
                    return {
                      ...row,
                      Options: {
                        ...row.Options,
                        MappedSystem: action.System,
                      },
                    };
                  }
                  return row;
                }),
              },
            },
          };
        }
        if (action.SelectorType === "ceiling") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              ceiling: {
                ...bufferSelection.SelectionByType.ceiling,
                Rows: bufferSelection.SelectionByType.ceiling.Rows.map(
                  (row) => {
                    if (row.Index === action.Index) {
                      return {
                        ...row,
                        Options: {
                          ...row.Options,
                          MappedSystem: action.System,
                        },
                      };
                    }
                    return row;
                  }
                ),
              },
            },
          };
        }

        if (action.SelectorType === "others") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              others: {
                ...bufferSelection.SelectionByType.others,
                Rows: bufferSelection.SelectionByType.others.Rows.map((row) => {
                  if (row.Index === action.Index) {
                    return {
                      ...row,
                      Options: {
                        ...row.Options,
                        MappedSystem: action.System,
                      },
                    };
                  }
                  return row;
                }),
              },
            },
          };
        }
      }

      return bufferSelection;
    case "CREATE_SELECTION":
      return action.selection;
    case "INIT_MAPPING_TABLE":
      if (bufferSelection !== null)
        return {
          ...bufferSelection,
          Levels: action.levels,
          SelectionByType: {
            wall: {
              Type: "wall",
              Rows: action.wallMappingRows,
            },
            ceiling: {
              Type: "ceiling",
              Rows: action.ceilingMappingRows,
            },
            others: {
              Type: "others",
              Rows: action.othersMappingRows,
            },
          },
        };
      return bufferSelection;
    case "UPDATE_MAPPING_TABLE":
      if (bufferSelection !== null) {
        /*
         * Check wall/ceiling with same type is already there in buffer selection
         * If yes : add level and Ids in respective
         * If no : add new object(wall/ceiling row)
         */

        let wallRows = [...bufferSelection.SelectionByType.wall.Rows];
        _.map(
          action.wallMappingRows,
          (wallMappingRow: any, indexWallMappingRow: any) => {
            let presentWallType = _.findIndex(wallRows, function (o) {
              return (
                o.RevitSelection.RevitType ===
                wallMappingRow.RevitSelection.RevitType
              );
            });

            if (presentWallType !== -1) {
              wallRows[presentWallType].RevitSelection.Ids = _.uniq(
                _.concat(
                  wallRows[presentWallType].RevitSelection.Ids,
                  wallMappingRow.RevitSelection.Ids
                )
              );
              // wallRows[presentWallType.Index].Options.MappedSystem = null;
            } else {
              wallRows.push({ ...wallMappingRow, Index: wallRows.length });
            }
          }
        );

        let ceilingRows = [...bufferSelection.SelectionByType.ceiling.Rows];
        _.map(
          action.ceilingMappingRows,
          (ceilingMappingRow: any, indexCeilingMappingRow: any) => {
            let presentCeilingType = _.find(ceilingRows, function (o) {
              return (
                o.RevitSelection.RevitType ===
                ceilingMappingRow.RevitSelection.RevitType
              );
            });
            if (presentCeilingType) {
              ceilingRows[presentCeilingType.Index].RevitSelection.Ids = _.uniq(
                _.concat(
                  ceilingRows[presentCeilingType.Index].RevitSelection.Ids,
                  ceilingMappingRow.RevitSelection.Ids
                )
              );
              // ceilingRows[presentCeilingType.Index].Options.MappedSystem = null;
            } else {
              ceilingRows.push({
                ...ceilingMappingRow,
                Index: ceilingRows.length,
              });
            }
          }
        );

        ////////////////////////////////

        return {
          ...bufferSelection,
          Levels: _.uniq(_.concat(bufferSelection.Levels, action.levels)),
          SelectionByType: {
            ...bufferSelection.SelectionByType,
            wall: {
              ...bufferSelection.SelectionByType.wall,
              Rows: wallRows,
            },
            ceiling: {
              ...bufferSelection.SelectionByType.ceiling,
              Rows: ceilingRows,
            },
          },
          Update: false, // made a false, so that new selection after will not take previous selection values
        };
      }
      return bufferSelection;
    case "EDIT_SELECTION":
      return action.selection;
    case "SAVE_BUFFER":
      return null;
    case "DUPLICATE_BUFFER_SELECTION":
      if (bufferSelection !== null) {
        if (action.SelectorType === "wall") {
          const wallToCopy = _.find(bufferSelection.SelectionByType.wall.Rows, {
            Index: action.Index,
          });
          if (wallToCopy) {
            return {
              ...bufferSelection,
              SelectionByType: {
                ...bufferSelection.SelectionByType,
                wall: {
                  ...bufferSelection.SelectionByType.wall,
                  Rows: _.includes(
                    wallToCopy.Options.MappedSystem?.oid,
                    "custom"
                  )
                    ? _.concat(bufferSelection.SelectionByType.wall.Rows, [
                        {
                          ...wallToCopy,
                          Index:
                            bufferSelection.SelectionByType.wall.Rows.length,
                          Options: {
                            ...wallToCopy.Options,
                            MappedSystem: {
                              ...wallToCopy.Options.MappedSystem,
                              oid:
                                "custom_wall_" +
                                bufferSelection.SelectionByType.wall.Rows
                                  .length,
                            } as CustomProductDetailData,
                          },
                        },
                      ])
                    : _.concat(bufferSelection.SelectionByType.wall.Rows, [
                        {
                          ...wallToCopy,
                          Index:
                            bufferSelection.SelectionByType.wall.Rows.length,
                        },
                      ]),
                },
              },
            };
          }
          return bufferSelection;
        }
        if (action.SelectorType === "ceiling") {
          const ceilingToCopy = _.find(
            bufferSelection.SelectionByType.ceiling.Rows,
            {
              Index: action.Index,
            }
          );
          if (ceilingToCopy) {
            return {
              ...bufferSelection,
              SelectionByType: {
                ...bufferSelection.SelectionByType,
                ceiling: {
                  ...bufferSelection.SelectionByType.ceiling,
                  Rows: _.includes(
                    ceilingToCopy.Options.MappedSystem?.oid,
                    "custom"
                  )
                    ? _.concat(bufferSelection.SelectionByType.ceiling.Rows, [
                        {
                          ...ceilingToCopy,
                          Index:
                            bufferSelection.SelectionByType.ceiling.Rows.length,
                          Options: {
                            ...ceilingToCopy.Options,
                            MappedSystem: {
                              ...ceilingToCopy.Options.MappedSystem,
                              oid:
                                "custom_ceiling_" +
                                bufferSelection.SelectionByType.ceiling.Rows
                                  .length,
                            } as CustomProductDetailData,
                          },
                        },
                      ])
                    : _.concat(bufferSelection.SelectionByType.ceiling.Rows, [
                        {
                          ...ceilingToCopy,
                          Index:
                            bufferSelection.SelectionByType.ceiling.Rows.length,
                        },
                      ]),
                },
              },
            };
          }
        }

        if (action.SelectorType === "others") {
          const othersToCopy = _.find(
            bufferSelection.SelectionByType.others.Rows,
            {
              Index: action.Index,
            }
          );
          if (othersToCopy) {
            return {
              ...bufferSelection,
              SelectionByType: {
                ...bufferSelection.SelectionByType,
                others: {
                  ...bufferSelection.SelectionByType.others,
                  Rows: _.includes(
                    othersToCopy.Options.MappedSystem?.oid,
                    "custom"
                  )
                    ? _.concat(bufferSelection.SelectionByType.others.Rows, [
                        {
                          ...othersToCopy,
                          Index:
                            bufferSelection.SelectionByType.others.Rows.length,
                          Options: {
                            ...othersToCopy.Options,
                            MappedSystem: {
                              ...othersToCopy.Options.MappedSystem,
                              oid:
                                "custom_others_" +
                                bufferSelection.SelectionByType.others.Rows
                                  .length,
                            } as CustomProductDetailData,
                          },
                        },
                      ])
                    : _.concat(bufferSelection.SelectionByType.others.Rows, [
                        {
                          ...othersToCopy,
                          Index:
                            bufferSelection.SelectionByType.others.Rows.length,
                        },
                      ]),
                },
              },
            };
          }
        }
      }

      return bufferSelection;
    case "DELETE_BUFFER_ELEMENT":
      if (bufferSelection !== null) {
        if (action.SelectorType === "wall") {
          const wallRowCopy = [...bufferSelection.SelectionByType.wall.Rows];
          _.remove(wallRowCopy, function (o) {
            return o.Index === action.Index;
          });

          if (wallRowCopy) {
            return {
              ...bufferSelection,
              SelectionByType: {
                ...bufferSelection.SelectionByType,
                wall: {
                  ...bufferSelection.SelectionByType.wall,
                  Rows: wallRowCopy.map(
                    (row: GenericMappingRow<PlacoOptions>, index) => {
                      return {
                        ...row,
                        Index: index,
                        Options: {
                          ...row.Options,
                          MappedSystem: row.Options.MappedSystem
                            ? {
                                ...row.Options.MappedSystem,
                                oid: row.Options.MappedSystem?.oid.includes(
                                  "custom"
                                )
                                  ? "custom_wall_" + index
                                  : row.Options.MappedSystem?.oid,
                              }
                            : null,
                        },
                      } as GenericMappingRow<PlacoOptions>;
                    }
                  ),
                },
              },
            };
          }
          return bufferSelection;
        }
        if (action.SelectorType === "ceiling") {
          const ceilingRowCopy = [
            ...bufferSelection.SelectionByType.ceiling.Rows,
          ];
          _.remove(ceilingRowCopy, function (o) {
            return o.Index === action.Index;
          });
          if (ceilingRowCopy) {
            return {
              ...bufferSelection,
              SelectionByType: {
                ...bufferSelection.SelectionByType,
                ceiling: {
                  ...bufferSelection.SelectionByType.ceiling,
                  Rows: ceilingRowCopy.map(
                    (row: GenericMappingRow<PlacoOptions>, index) => {
                      return {
                        ...row,
                        Index: index,
                        Options: {
                          ...row.Options,
                          MappedSystem: row.Options.MappedSystem
                            ? {
                                ...row.Options.MappedSystem,
                                oid: row.Options.MappedSystem?.oid.includes(
                                  "custom"
                                )
                                  ? "custom_ceiling_" + index
                                  : row.Options.MappedSystem?.oid,
                              }
                            : null,
                        },
                      } as GenericMappingRow<PlacoOptions>;
                    }
                  ),
                },
              },
            };
          }
        }

        if (action.SelectorType === "others") {
          const othersRowCopy = [
            ...bufferSelection.SelectionByType.others.Rows,
          ];
          _.remove(othersRowCopy, function (o) {
            return o.Index === action.Index;
          });
          if (othersRowCopy) {
            return {
              ...bufferSelection,
              SelectionByType: {
                ...bufferSelection.SelectionByType,
                others: {
                  ...bufferSelection.SelectionByType.others,
                  Rows: othersRowCopy.map((row, index) => {
                    return {
                      ...row,
                      Index: index,
                    };
                  }),
                },
              },
            };
          }
        }
      }

      return bufferSelection;
    case "UPDATE_BUFFER_SELECTION":
      if (bufferSelection !== null) {
        return {
          ...bufferSelection,
          Update: action.update,
        };
      }
      return bufferSelection;
    case "TOGGLE_SELECTION_ROW":
      if (bufferSelection !== null) {
        if (action.SelectorType === "wall") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              wall: {
                ...bufferSelection.SelectionByType.wall,
                Rows: bufferSelection.SelectionByType.wall.Rows.map((row) => {
                  if (row.Index === action.Index) {
                    return {
                      ...row,
                      Checked: !row.Checked,
                    };
                  }
                  return row;
                }),
              },
            },
          };
        }
        if (action.SelectorType === "ceiling") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              ceiling: {
                ...bufferSelection.SelectionByType.ceiling,
                Rows: bufferSelection.SelectionByType.ceiling.Rows.map(
                  (row) => {
                    if (row.Index === action.Index) {
                      return {
                        ...row,
                        Checked: !row.Checked,
                      };
                    }
                    return row;
                  }
                ),
              },
            },
          };
        }

        if (action.SelectorType === "others") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              others: {
                ...bufferSelection.SelectionByType.others,
                Rows: bufferSelection.SelectionByType.others.Rows.map((row) => {
                  if (row.Index === action.Index) {
                    return {
                      ...row,
                      Checked: !row.Checked,
                    };
                  }
                  return row;
                }),
              },
            },
          };
        }
      }
      return bufferSelection;
    case "TOGGLE_ALL_SELECTION_ROW":
      if (bufferSelection !== null) {
        if (action.SelectorType === "wall") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              wall: {
                ...bufferSelection.SelectionByType.wall,
                Rows: bufferSelection.SelectionByType.wall.Rows.map((row) => {
                  return {
                    ...row,
                    Checked: action.Checked,
                  };
                }),
              },
            },
          };
        }
        if (action.SelectorType === "ceiling") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              ceiling: {
                ...bufferSelection.SelectionByType.ceiling,
                Rows: bufferSelection.SelectionByType.ceiling.Rows.map(
                  (row) => {
                    return {
                      ...row,
                      Checked: action.Checked,
                    };
                  }
                ),
              },
            },
          };
        }

        if (action.SelectorType === "others") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              others: {
                ...bufferSelection.SelectionByType.others,
                Rows: bufferSelection.SelectionByType.others.Rows.map((row) => {
                  return {
                    ...row,
                    Checked: action.Checked,
                  };
                }),
              },
            },
          };
        }
      }
      return bufferSelection;
    case "UPDATE_OTHER_PLACO_SYSTEMS":
      if (bufferSelection !== null)
        return {
          ...bufferSelection,
          SelectionByType: {
            ...bufferSelection.SelectionByType,
            others: bufferSelection.SelectionByType.others
              ? {
                  ...bufferSelection.SelectionByType.others,
                  Rows: _.concat(
                    bufferSelection.SelectionByType.others.Rows,
                    _.map(action.othersMappingRows, (item, index) => {
                      return {
                        ...item,
                        Index:
                          bufferSelection.SelectionByType.others.Rows.length +
                          index,
                      };
                    })
                  ),
                }
              : {
                  Type: "others",
                  Rows: _.map(action.othersMappingRows, (item, index) => {
                    return {
                      ...item,
                      Index: 1,
                    };
                  }),
                },
          },
        };
      return bufferSelection;
    case "SELECT_PLACO_OTHER_SYSTEM":
      let worksName = "Autre système (non PLACO)";
      if ((action.System as ProductDetailData).attributes) {
        const attributes = (action.System as ProductDetailData).attributes;
        const workObject = _.find(attributes, {
          technicalName: "GFR-Works name",
        });
        worksName = (workObject as PimAttribute).values[0].value;
      }

      if (bufferSelection !== null) {
        if (action.SelectorType === "wall") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              wall: {
                ...bufferSelection.SelectionByType.wall,
                Rows: bufferSelection.SelectionByType.wall.Rows.map((row) => {
                  if (row.Index === action.Index) {
                    return {
                      ...row,
                      Options: {
                        ...row.Options,
                        MappedSystem: action.System,
                      },
                      RevitSelection: {
                        ...row.RevitSelection,
                        RevitType: worksName,
                      },
                    };
                  }
                  return row;
                }),
              },
            },
          };
        }
        if (action.SelectorType === "ceiling") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              ceiling: {
                ...bufferSelection.SelectionByType.ceiling,
                Rows: bufferSelection.SelectionByType.ceiling.Rows.map(
                  (row) => {
                    if (row.Index === action.Index) {
                      return {
                        ...row,
                        Options: {
                          ...row.Options,
                          MappedSystem: action.System,
                        },
                        RevitSelection: {
                          ...row.RevitSelection,
                          RevitType: worksName,
                        },
                      };
                    }
                    return row;
                  }
                ),
              },
            },
          };
        }

        if (action.SelectorType === "others") {
          return {
            ...bufferSelection,
            SelectionByType: {
              ...bufferSelection.SelectionByType,
              others: {
                ...bufferSelection.SelectionByType.others,
                Rows: bufferSelection.SelectionByType.others.Rows.map((row) => {
                  if (row.Index === action.Index) {
                    return {
                      ...row,
                      Options: {
                        ...row.Options,
                        MappedSystem: action.System,
                      },
                      RevitSelection: {
                        ...row.RevitSelection,
                        RevitType: worksName,
                      },
                    };
                  }
                  return row;
                }),
              },
            },
          };
        }
      }
      return bufferSelection;
    case "REPLACE_BUFFER_SELECTION":
      return action.bufferSelection;
    default:
      return bufferSelection;
  }
};
