import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { MyConfig } from "../../../../../Helper";
import { selectWindowToShow } from "../../actions/appActions";
import { selectFile } from "../../actions/csvActions";
import { AppWindowName } from "../../assets/constants";
import { getMapping } from "../../helpers/utils";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./FamilyComponent.scss";

class FamilyComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.setSelectedFamilyCriteria = this.setSelectedFamilyCriteria.bind(this);
    this.areAllCriteriaSelected = this.areAllCriteriaSelected.bind(this);
    this.getCorrespondingFileName = this.getCorrespondingFileName.bind(this);
    this.state = {
      families: [],
      selectedFamily: {
        market: null,
        range: null,
        application: null,
        junction1: null,
      },
      config: {},
    };
  }
  setSelectedFamilyCriteria(value: any, criteria: string) {
    this.setState({
      selectedFamily: { ...this.state.selectedFamily, [criteria]: value },
    });
  }

  areAllCriteriaSelected() {
    return (
      this.state.selectedFamily.market &&
      this.state.selectedFamily.application &&
      this.state.selectedFamily.range &&
      this.state.selectedFamily.junction1
    );
  }

  componentDidMount = async () => {
    const cng = await MyConfig();
    getMapping(this.props.csvList, cng).then((result) => {
      this.setState({
        families: result,
      });
    });
  };

  getCorrespondingFileName(): string[] | null {
    let concernedFamily = this.state.families.filter((family) => {
      return (
        family.market === this.state.selectedFamily.market &&
        family.application === this.state.selectedFamily.application &&
        family.range === this.state.selectedFamily.range &&
        family.junction1 === this.state.selectedFamily.junction1
      );
    });

    if (
      concernedFamily &&
      concernedFamily.length > 0 &&
      concernedFamily.every((c) => c.fileNames !== undefined)
    ) {
      return concernedFamily
        .filter((f) => f.fileNames !== undefined && f.fileNames !== undefined)
        .map((c) => (c.fileNames ? c.fileNames[0] : ""))
        .filter((c) => c !== "");
    }
    return null;
  }

  nextPage() {
    let concernedFileName = this.getCorrespondingFileName();
    concernedFileName?.forEach((f) => {
      let correspondingFile = this.props.csvList.find((fileObject) => {
        return fileObject.file.name === f;
      });
      if (correspondingFile) {
        this.props.selectFile(correspondingFile);
      }
    });

    this.props.selectWindowToShow(AppWindowName.PROPERTIES_PREFERENCE);
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <div id="family-component-container">
          <Header displayName={t("FAMILY_CHOOSE") || "Choose family"}></Header>
          <Body
            families={this.state.families}
            selectedFamily={this.state.selectedFamily}
            setSelectedFamilyCriteria={this.setSelectedFamilyCriteria}
          ></Body>
          <Footer
            disabledNextButton={!this.areAllCriteriaSelected()}
            savedProjectsButtonShow={false}
            clickedNextButton={this.nextPage}
          ></Footer>
        </div>
      </>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    csvList: state.csv.list,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  selectFile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {
  families: {
    market: string;
    application: string;
    range: string;
    junction1: string;
    fileNames?: string[];
  }[];
  selectedFamily: {
    market: string | null;
    range: string | null;
    application: string | null;
    junction1: string | null;
  };
  config: any;
};

export default connector(withTranslation(["pamCanalisation"])(FamilyComponent));
