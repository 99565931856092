import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../reducers";
import {
  loadSystemsRequest,
  loadSystemsSuccess,
  loadSystemsError,
  loadSystemsDetailsRequest,
  loadSystemsDetailsSuccess,
  loadSystemsDetailsError,
  loadSystemsDetailsMore,
  filterSystemsRequest,
  displayFavoriteRequest,
  restoreSystemDetails,
  displaySystem,
  loadExistingSystems,
  AddSearchKeyWordAction,
  DeleteSearchKeyWordAction,
} from "./actions";
import { SystemsActionTypes } from "./types";
import * as productsService from "../../services/project.service";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { addFilterFields, filterElements } from "../../utils/utils";
import {
  CLOISONS_ID,
  DOUBLAGES_ID,
  GAINES_ID,
  PLAFONDS_ID,
  systemTypes,
} from "../../assets/constants";
import { CustomParameter, ID } from "../../../../../RevitJS/Types/RevitTypes";
import _ from "lodash";
import { api } from "../../../../../RevitJS/API";

type Effect = ThunkAction<any, DrawStore, any, SystemsActionTypes>;

export const loadSystems =
  (config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsRequest());
    await productsService
      .loadProjects(config)
      .then((results) => {
        dispatch(loadSystemsSuccess(results));
      })
      .catch(() => dispatch(loadSystemsError()));
  };

export const loadSystemsDetails =
  (typeID: number, config: any): Effect =>
  async (dispatch: any, getState: any) => {
    let type = systemTypes.find((systemType) => systemType.value === typeID);
    dispatch(loadSystemsDetailsRequest());
    let { systems, data } = getState().systems;
    if (type?.value === CLOISONS_ID && data.partitionsDetails.length > 0)
      dispatch(
        restoreSystemDetails(
          data.partitionsDetails,
          data.partitionsMetaData,
          type.value
        )
      );
    else if (type?.value === PLAFONDS_ID && data.ceilingsDetails.length > 0)
      dispatch(
        restoreSystemDetails(
          data.ceilingsDetails,
          data.ceilingsMetaData,
          type.value
        )
      );
    else if (type?.value === DOUBLAGES_ID && data.liningWallsDetails.length > 0)
      dispatch(
        restoreSystemDetails(
          data.liningWallsDetails,
          data.liningWallsMetaData,
          type.value
        )
      );
    else if (
      type?.value === GAINES_ID &&
      data.gainesTechniquesDetails.length > 0
    )
      dispatch(
        restoreSystemDetails(
          data.gainesTechniquesDetails,
          data.gainesTechniquesMetaData,
          type.value
        )
      );
    else {
      const systemIds = systems.map((product: { oid: ID }) => product.oid);
      if (systemIds && systemIds.length > 0) {
        productsService
          .getSystemDetailsById(systemIds, config)
          .then((results) => {
            let currentSystemDetail = results.data.objects.map((sys) => {
              return { ...sys, filterFields: {} } as unknown;
            }) as ProductDetailData[];
            currentSystemDetail = currentSystemDetail.map((sys) => ({
              ...sys,
              thumbnailUrl: systems.find(
                (system: any) => system.oid === sys.oid
              )?.thumbnailUrl,
            })) as ProductDetailData[];
            const currentSystemDetailCloisons = currentSystemDetail.filter(
              (productDetail) => {
                const gfrWorksNameObject = _.find(productDetail.attributes, {
                  technicalName: "GFR-Works name",
                });
                if (gfrWorksNameObject) {
                  const valueObject: any = _.get(gfrWorksNameObject, "values");
                  return valueObject[0].value === "Cloisons";
                }
              }
            );

            const currentSystemDetailCloisonsMetaData = systems.filter(
              (system: any) =>
                currentSystemDetailCloisons
                  .map((product: { oid: ID }) => product.oid)
                  .includes(system.oid)
            );
            const currentSystemDetailPlafonds = currentSystemDetail.filter(
              (productDetail) => {
                const gfrWorksNameObject = _.find(productDetail.attributes, {
                  technicalName: "GFR-Works name",
                });
                if (gfrWorksNameObject) {
                  const valueObject: any = _.get(gfrWorksNameObject, "values");
                  return (
                    valueObject[0].value === "Plafonds décoratifs" ||
                    valueObject[0].value === "Plafonds"
                  );
                }
              }
            );
            const currentSystemDetailPlafondsMetaData = systems.filter(
              (system: any) =>
                currentSystemDetailPlafonds
                  .map((product: { oid: ID }) => product.oid)
                  .includes(system.oid)
            );
            const currentSystemDetailLiningWalls = currentSystemDetail.filter(
              (productDetail) => {
                const gfrWorksNameObject = _.find(productDetail.attributes, {
                  technicalName: "GFR-Works name",
                });
                if (gfrWorksNameObject) {
                  const valueObject: any = _.get(gfrWorksNameObject, "values");
                  return valueObject[0].value === "Isolation des murs";
                }
              }
            );

            const currentSystemDetailLiningWallsMetaData = systems.filter(
              (system: any) =>
                currentSystemDetailLiningWalls
                  .map((product: { oid: ID }) => product.oid)
                  .includes(system.oid)
            );
            const currentSystemsDetailGainesTechniques =
              currentSystemDetail.filter((productDetail) => {
                const gfrWorksNameObject = _.find(productDetail.attributes, {
                  technicalName: "GFR-Works name",
                });
                if (gfrWorksNameObject) {
                  const valueObject: any = _.get(gfrWorksNameObject, "values");
                  console.log(valueObject[0].value);
                  return valueObject[0].value === "Gaines techniques";
                }
              });
            const currentSystemDetailGainesTechniquesMetaData = systems.filter(
              (system: any) =>
                currentSystemsDetailGainesTechniques
                  .map((product: { oid: ID }) => product.oid)
                  .includes(system.oid)
            );
            if (type) {
              dispatch(
                loadSystemsDetailsSuccess(
                  currentSystemDetailCloisons,
                  currentSystemDetailPlafonds,
                  currentSystemDetailLiningWalls,
                  currentSystemsDetailGainesTechniques,
                  currentSystemDetailCloisonsMetaData,
                  currentSystemDetailPlafondsMetaData,
                  currentSystemDetailLiningWallsMetaData,
                  currentSystemDetailGainesTechniquesMetaData,
                  type.value
                )
              );
            }
          })
          .catch(() => dispatch(loadSystemsDetailsError()));
      }
      const existingSystems: CustomParameter[] = [];
      //  (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[]
      dispatch(loadExistingSystems(existingSystems));
    }
  };

export const loadMoreSystemsDetails =
  (): Effect => async (dispatch: any, getState: any) => {
    if (
      getState().systems.systemsDetails.length <
      getState().systems.currentSystemDetail.length
    ) {
      dispatch(
        loadSystemsDetailsMore(
          getState().systems.currentSystemDetail.slice(
            getState().systems.systemsDetails.length,
            getState().systems.systemsDetails.length + 20
          )
        )
      );
    }
  };

export const filterSystemsDetails =
  (): Effect => async (dispatch: any, getState: any) => {
    dispatch(loadSystemsDetailsRequest());
    let {
      currentSystemDetail,
      data,
      systemTypeID,
      searchKeyWords,
      favoriteDisplayed,
    } = getState().systems;
    let { currentFavoriteSystemItems } = getState().favorite;
    let dataToFilter: ProductDetailData[] = [];
    if (favoriteDisplayed) dataToFilter = currentFavoriteSystemItems;
    else if (systemTypeID === CLOISONS_ID)
      dataToFilter = data.partitionsDetails;
    else if (systemTypeID === PLAFONDS_ID) dataToFilter = data.ceilingsDetails;
    else if (systemTypeID === DOUBLAGES_ID)
      dataToFilter = data.liningWallsDetails;
    else if (systemTypeID === GAINES_ID)
      dataToFilter = data.gainesTechniquesDetails;

    let { filters } = getState().filters;
    currentSystemDetail = addFilterFields(dataToFilter, filters);
    currentSystemDetail = filterElements(currentSystemDetail, filters);
    currentSystemDetail = currentSystemDetail.filter((el: ProductDetailData) =>
      searchKeyWords.every((keyWord: any) => {
        let solutionProductNameAttr = el.attributes.find(
          (sol: any) => sol.technicalName === "A-Solution product name"
        );
        let solutionName = solutionProductNameAttr
          ? solutionProductNameAttr.values[0].value
          : el.translation;

        return solutionName
          .toUpperCase()
          .replace("®", "")
          .replace(/\s/g, "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            keyWord
              ?.toUpperCase()
              .replace("®", "")
              .replace(/\s/g, "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") || ""
          );
      })
    );
    dispatch(filterSystemsRequest(currentSystemDetail));
  };

export const displayFavorite =
  (): Effect => async (dispatch: any, getState: any) => {
    const { currentFavoriteSystemItems } = getState().favorite;
    dispatch(displayFavoriteRequest(currentFavoriteSystemItems));
  };

export const displaySystems =
  (): Effect => async (dispatch: any, getState: any) => {
    let { data, systemTypeID } = getState().systems;
    let systems: ProductDetailData[] = [];
    if (systemTypeID === CLOISONS_ID) systems = data.partitionsDetails;
    else if (systemTypeID === PLAFONDS_ID) systems = data.ceilingsDetails;
    else if (systemTypeID === DOUBLAGES_ID) systems = data.liningWallsDetails;
    else if (systemTypeID === GAINES_ID) systems = data.gainesTechniquesDetails;
    dispatch(displaySystem(systems));
  };

export const addSearchKeyWord =
  (word: string): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(AddSearchKeyWordAction(word));
    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: word,
          values: [],
        },
      ],
      eventAction: "Get",
      eventCategory: "User Query",
      eventLabel:
        localStorage.getItem("moduleName") !== "PLACOBIM"
          ? "Keyword search"
          : "Library_Keyword search",
      module: localStorage.getItem("moduleName") as string, //"PRESCRIBIM",
    });
  };

export const deleteSearchKeyWord =
  (searchWordIndex: number): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(DeleteSearchKeyWordAction(searchWordIndex));
  };
