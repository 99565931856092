export enum Source {
    PIM = "PIM",
    BIM = "BIMDB",
    EXCEL = "EXCEL",
    EMPTY = ""
}

export enum Version {
    v2021 = "2021",
    v2022 = "2022",
    v2023 = "2023",
    v2024 = "2024",
}

export enum TechnicalName {
    PAM = "PAM",
    PLACO = "Placo",
    PAM_INTERNATIONAL = "PAM International",
}

export enum FORMAT {
    REVIT = "rvt",
    IFC = "ifc",
    RFA = "rfa",
}