export const PLAQUE_COLOR = [
  { mark: "Rigitherm®", color: [218, 231, 235] },
  { mark: "Rigips® Duo'Tech imprégnée", color: [164, 156, 209] },
  { mark: "Rigips® Glasroc H", color: [188, 193, 215] },
  { mark: "Rigidur® H", color: [181, 164, 148] },
  { mark: "Rigips® X-Ray Protection", color: [221, 219, 175] },
  { mark: "Plaque anti-feu Rigips®", color: [242, 198, 160] },
  { mark: "Alba®phon", color: [249, 252, 252] },
  { mark: "Rigips® Aquaroc", color: [160, 212, 209] },
  { mark: "Plaque Rigips® imprégnée", color: [233, 240, 208] },
  { mark: "Rigips® Glasroc F", color: [255, 171, 131] },
  { mark: "Rigips® Habito", color: [205, 184, 157] },
  { mark: "Alba®balance", color: [255, 233, 254] },
  { mark: "Rigips® Duraline imprégnée", color: [240, 223, 200] },
  { mark: "Rigips® Duo'Tech", color: [164, 156, 209] },
  { mark: "Plaque anti-feu Rigips® imprégnée", color: [242, 198, 160] },
  { mark: "Plaque Rigips®", color: [225, 230, 225] },
  { mark: "Rigips® Habito H", color: [205, 184, 157] },
  { mark: "Alba®therm", color: [218, 231, 235] },
  { mark: "Carreau de plâtre massif Alba® hydro", color: [215, 234, 213] },
  { mark: "Rigips® Duraline", color: [240, 223, 200] },
  { mark: "Carreau de plâtre massif Alba®", color: [249, 252, 252] }
];
