import React, { useEffect, useState } from "react";
import { MyConfig } from "../../../../Helper";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { api } from "../../../../RevitJS/API";
import { bimStorage } from "../../../../BIMStore";
import SaveProject from "../../CommonModules/SaveProject";
import { wording } from "../../../PlacoBIMv3/CommonModules/Selection/Type";
import {
  Container,
  Button,
  Loader,
  Dimmer,
  Grid,
  Modal,
} from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { Routes } from "./root";
import { TopHeader, SubHeader } from "../../Components/Headers";

interface Props {
  route: string;
  setRoute: any;
  // initLanguage: any;
}

interface State {
  erasing: boolean;
  confirmModal: boolean;
  isDownloadSuccessful: boolean;
}

export const MetresLanding = (props: Props) => {
  const [config, setConfig]: any = useState("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);


  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };
  useEffect(() => {
    api.windowsHandler.resizeWindow(800, 220);
    // initLanguage();
    async function getConfig() {
      const cng = await MyConfig();
      setConfig(cng);
    }
    const getProjectId = async () => {
      const projectId = await bimStorage.onProjectData();
      if(projectId){
        getConfig();
      }
      else{
        toggleModal();
      }
    }
    getProjectId();
    
  }, []);

  const defaultProps: any = {
    wording: {
      metres: {
        French: "Métrés",
        English: "Schedules",
      },
      paretage: {
        French: "Métrés par étage",
        English: "Metre per floor",
      },
      parzone: {
        French: "Métrés par étage <br/> et par zone",
        English: "Metre per floor and per zone",
      },
      schedulesPending: {
        French: "Métrés en cours",
        English: "Schedules pending",
        German: "Wandinventar in Bearbeitung",
        Italian: "Nomenclatura in corso",
      },
      quickMetre: {
        French: "Métrés rapide",
        English: "Quick Measurements",
        German: "Schnelle Messungen",
        Italian: "Misurazioni rapide",
      },
    },
  };

  // const paretageSelectionHandler = async () => {
  //   await api.windowsHandler.resizeWindow(900, 607);
  //   props.setRoute(Routes.SELECTION_METRES_HOME);
  // }

  return (
    <div>
      <TopHeader name={"Schedules"} Icon="" />
      <Container textAlign="center" style={{ marginTop: 20 }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={() => {
                  props.setRoute(Routes.SELECTION_METRES_HOME);
                }}
                primary
                style={{ height: 100 }}
                size="big"
                fluid
              >
                {ReactHtmlParser("From Saved Selection")}
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                onClick={() => {
                  props.setRoute(Routes.QUICK_CALEPINAGE);
                }}
                primary
                style={{ height: 100 }}
                fluid
                size="big"
              >
                {ReactHtmlParser("Quick Selection")}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <SaveProject
              toggleModal={toggleModal}
              confirmModal={confirmModal}
              onYesAction={onYesAction}
          />
        </Grid>
      </Container>
    </div>
  );
};

export default MetresLanding;
