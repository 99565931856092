import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { Root } from "./components/root";

export const functionalityId = "3a85132d-3740-47c2-a254-4be1f58238d4";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Properties",
    module: "PRESCRIBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root openedFrom={"PRESCRIBIM"}/>
    </ParentRoot>
  );
};

export const PropertiesRootComponentTest = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root openedFrom={"PRESCRIBIM"}/>
    </ParentRoot>
  );
};

export const Properties: Functionality = {
  Name: {
    French: "Propriétés",
    English: "Properties",
    German: "Propriétés",
    Italian: "Propriétés",
    Indonesian: "Propriétés",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Paramétrer les propriétés",
    English: "Set properties",
    German: "Paramétrer les propriétés",
    Italian: "Paramétrer les propriétés",
    Indonesian: "Paramétrer les propriétés",
  },
  Id: functionalityId,
  Icon: "./pluginIcons_V3/PlacoBIM_Icon_Library_Param.png",
  RootComponent,
  ParentId: "0",
};
