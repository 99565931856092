import React, { Component } from "react";
import { connect, Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../../Selection/Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";
import { composeWithDevTools } from "redux-devtools-extension";
import { MyConfig, setPluginDataToDB } from "../../../../Helper";
import { ErrorFallback } from "../../../../Components/CommonComponents/ErrorManagement";
import { setConfig } from "../../../CLT/Delete/Actions";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps;

export enum Routes {
  SELECTION_CALEPINAGE_HOME = "SELECTION_CALEPINAGE_HOME",
  ROOT = "ROOT",
  CALPINAGE_HOME = "CALPINAGE_HOME",
  SELECTION_HOME = "SELECTION_HOME",
  MAPPING = "MAPPING",
  GROUP_SELECTION = "GROUP_SELECTION",
  PRODUCT_SELECTOR = "PRODUCT_SELECTOR",
  BACK_FROM_SELECTION = "BACK_FROM_SELECTION",
  CALPINAGE_SOLUTION = "CALPINAGE_SOLUTION",
  CAL_SELECTION_HOME = "CAL_SELECTION_HOME",
  SYSTEM_CONFIGURATION = "SYSTEM_CONFIGURATION",
}

interface State {
  route: Routes;
}

export const dbStoreNameCalepinage = "Calepinage";

class CalpinageRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    this.store = createStore(
      reducers,
      composeWithDevTools(applyMiddleware(thunk))
    );
  }

  setRouteCalpinage = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = async () => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      setPluginDataToDB("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      setPluginDataToDB("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody
          route={this.state.route}
          setRoute={this.setRouteCalpinage}
          onError={this.props.onError}
        />
      </Provider>
    );
  };
}

export default withErrorSubscription(connect(null, {})(CalpinageRoot));
