import React from "react";
import { Routes } from "./root";
import { connect } from "react-redux";
import SuppressionHome from "./SuppressionHome";
import { AppBody } from "../../Selection/Page/AppBody";

interface Props {
    route: Routes;
    setRoute: any;
    onError: any;
  }

  export const AppBodyDelete = ({
    route,
    setRoute,
    onError,
  }: Props) => {
    switch (route) {
        case Routes.ROOT:
          return (
            <SuppressionHome setRoute={setRoute} onError={onError} />
          )
        case Routes.SELECTION_HOME:
          return <AppBody />
        default:
          return null;
      }
}

export default AppBodyDelete;