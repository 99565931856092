import React, { useState } from "react";
import {
  Icon,
  Checkbox,
  Radio,
  Image,
  Popup,
  Button,
  Select,
} from "semantic-ui-react";

interface Props {
  data: any;
  register: any;
  rowNumber: any;
  watch: any;
  onColorPick: any;
}

const style = {
  rowStyle: {
    padding: "5px",
    minHeight: "25px",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
    cursor: "pointer",
  },
};

export const ColorizationTableRow = (props: Props) => {
  const { data, register, rowNumber, watch, onColorPick } = props;
  const {
    Ids,
    Floor,
    ElementName,
    ElementHeight,
    Solution,
    ProductHeight,
    StudDistance,
    Key,
    Check,
    IsLayout,
    Color,
  } = data;
  const [open, setOpen] = useState(false);

  return (
    <Popup
      inverted
      content={!IsLayout && "Ce système ne peut être calepiné actuellement"}
      on="hover"
      eventsEnabled={true}
      onClose={() => setOpen(false)}
      onOpen={() => {
        !IsLayout && setOpen(true);
      }}
      open={open}
      trigger={
        <div
          className="row-hover"
          style={
            IsLayout
              ? style.rowStyle
              : {
                  ...style.rowStyle,
                  backgroundColor: "rgba(236, 240, 241, 0.5)",
                  color: "GrayText",
                }
          }
        >
          <input
            {...register(`colorizations[${rowNumber}].IsLayout`)}
            type="checkbox"
            defaultChecked={IsLayout}
            style={{ display: "none" }}
            className="w-5"
          />
          <input
            {...register(`colorizations[${rowNumber}].Ids`)}
            type="hidden"
            value={JSON.stringify(Ids)}
            className="w-5"
          />
          <input
            {...register(`colorizations[${rowNumber}].Check`)}
            type="checkbox"
            defaultChecked={Check}
            disabled={!IsLayout}
            className="w-5"
          />

          <div
            style={{
              justifyContent: "center",
              fontWeight: "bold",
              display: "block",
              width: "15%",
              color: IsLayout ? "black" : "GrayText",
              border: "0.2px",
              cursor: "pointer",
              wordWrap: "break-word",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <input
              {...register(`colorizations[${rowNumber}].Floor`)}
              type="hidden"
              value={Floor}
            />
            <div>{"Wall"}</div>
          </div>

          <div
            style={{
              justifyContent: "center",
              fontWeight: "bold",
              display: "block",
              width: "25%",
              color: IsLayout ? "black" : "GrayText",
              border: "0.2px",
              cursor: "pointer",
              wordWrap: "break-word",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <input
              {...register(`colorizations[${rowNumber}].ElementHeight`)}
              type="hidden"
              value={ElementHeight}
            />
            <input
              {...register(`colorizations[${rowNumber}].ElementName`)}
              type="hidden"
              value={ElementName}
            />
            <div>{`${ElementName} (${Ids.length})`}</div>
          </div>

          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "bold",
              width: "35%",
              color: IsLayout ? "black" : "GrayText",
              display: "flex",
            }}
          >
            <input
              {...register(`colorizations[${rowNumber}].Solution`)}
              type="hidden"
              value={JSON.stringify(Solution)}
            />
            <div>{Solution.Name}</div>
          </div>

          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "bold",
              width: "35%",
              color: IsLayout ? "black" : "GrayText",
              display: "flex",
            }}
          >
            <input
              {...register(`colorizations[${rowNumber}].Color`)}
              type="hidden"
              value={JSON.stringify(Color)}
            />
            <div style={{ display: "inline-flex" }}>
              <div className="swatch">
                <div
                  //style={styles.color}
                  style={
                    Color && {
                      width: "36px",
                      height: "20px",
                      borderRadius: "2px",
                      background: `rgb(${Color.r}, ${Color.g}, ${Color.b})`,
                      //cursor: "pointer",
                    }
                  }
                />
              </div>
              <div>
                <Image
                  src={require("../../../Resources/Paintbucket.png")}
                  style={{
                    paddingLeft: "15px",
                    marginRight: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onColorPick(rowNumber, Color);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ColorizationTableRow;
