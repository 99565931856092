import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import { Table, Button, Icon, Input, Popup, Modal } from "semantic-ui-react";
import {
  GenericMappingRow,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { ProductDetailData } from "../Types";
import {
  selectSystem,
  setActiveRowIndex,
  toggleSelectionRow,
  selectOtherPlacoSystem,
  setFilterType,
  showSystemDetails,
  selectBuffer,
} from "../Actions";
import { BuiltInCategory } from "../../../../RevitJS/Types/RevitTypes";
import {
  initProductSelector,
  duplicateBufferSystem,
  deleteInBufferSystem,
} from "../Actions";
import { Routes } from "./root";
import { bundleFileDescriptors } from "../Helpers";
import { fetchFiles } from "../Requests";
import fileSaver from "file-saver";
import { ElementParameter } from "../Actions/objectTypes";
import _ from "lodash";
import { selectorTp } from "../Actions/types";
import {
  makeGetDropdownPlacoSolutions,
  makeGetRelatedPlacoSolutions,
} from "../Selectors";

import WindowedSelect from "react-windowed-select";
import { fetchPlacoSolutionsDetails } from "../../../../API";
import { bimStorage, storageKey } from "../../../../BIMStore";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core/styles";
import "../../DocumentTechniques/Components/ReferencesCCTP/windowCursor.css";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";
// import { useDispatch } from "react-redux";
// import { setErrorModal } from "../../../../Actions/index";

interface State { }
const makeMapStateToProps = () => {
  const getPlacoSolutions = makeGetRelatedPlacoSolutions();
  const getDropdownOptions = makeGetDropdownPlacoSolutions();

  const mapStateToProps = (
    state: SelectionStore,
    ownProps: {
      mappingRow: GenericMappingRow<PlacoOptions>;
      setRoute: any;
      module: string;
    }
  ) => {
    return {
      detailedSystems: getPlacoSolutions(state, state.selectorType),
      selectorType: state.selectorType,
      mappingRow: ownProps.mappingRow,
      module: ownProps.module,
      elementParameter: state.elementParameter,
      dropdownOptions: getDropdownOptions(state, state.selectorType),
      config: state.config,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = {
  selectSystem,
  initProductSelector,
  setActiveRowIndex: setActiveRowIndex,
  duplicateBufferSystem,
  deleteInBufferSystem,
  toggleSelectionRow,
  selectOtherPlacoSystem,
  setFilterType,
  showSystemDetails: showSystemDetails,
  selectBuffer,
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  mappingRow: GenericMappingRow<PlacoOptions>;
  detailedSystems: ProductDetailData[];
  selectorType: BuiltInCategory | selectorTp;
  setRoute: any;
  toggleSelectionRow: any;
  selectOtherPlacoSystem: any;
  setFilterType: any;
  module: string;
  elementParameter: ElementParameter[];
  dropdownOptions: { label: string; value: string }[];
  handleDimmer: any;
  setScrollPosition: any;
  setScrollConfigPosition: any;
  config: any;
  onError: any;
};

class SelectionMappingRow extends Component<Props, State> {
  state = {
    customValue: "",
    downloadFlag: false,
    clickedWarning: false,
    lang: "English",
  };
  // dispatch = useDispatch();

  componentDidMount = () => {
    let language: any = localStorage.getItem("savedLanguage");
    this.setState({
      customValue: this.props.mappingRow.Options.MappedSystem?.translation,
    });
    setTimeout(() => {
      this.forceUpdate();
    }, 100);
    const changeLanguage = async () => {
      if (language === "English") {
        this.setState({ lang: "English" });
      } else if (language === "French") {
        this.setState({ lang: "French" });
      } else {
        this.setState({ lang: "English" });
      }
    };
    changeLanguage();
  };

  componentDidUpdate = (oldProps: any) => {
    if (oldProps !== this.props) {
      this.setState({
        customValue: this.props.mappingRow.Options.MappedSystem?.translation,
      });
    }
  };

  saveFile = async (response: any, fileName: any, contentType: any) => {
    var data = new Blob([response.data], { type: contentType });
    setTimeout(() => fileSaver.saveAs(data, fileName), 1000);
    this.setState({ downloadFlag: false });
  };

  downLoadSheet = async (mappedSystem: any) => {
    try {
      this.setState({ downloadFlag: true });
      const { data } = await fetchPlacoSolutionsDetails(
        [mappedSystem.oid],
        this.props.config
      ).catch((error) => {
        throw error;
      });
      // dispatch(
      //   setErrorModal({
      //     Open: true,
      //     Code: "404",
      //     Report: data,
      //     Reload: true,
      //   })
      // );
      const downloadBlobFl = axios.create({
        baseURL: this.props.config.REACT_APP_BDDURL,
        headers: {
          "Content-Type": "application/json;application/octet-stream",
          Accept: "*/*",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        responseType: "blob",
      });

      downloadBlobFl.interceptors.request.use(async (request) => {
        return await authFilter(request, this.props.config);
      });

      if (data) {
        const fileDescriptorBundle = bundleFileDescriptors(
          mappedSystem.translation,
          data.objects[0].fileDescriptors
        );
        // let fileObj = fileDescriptorBundle.find(
        //   (a: any) =>
        //     a.fileType === "System Datasheet"
        // );

        let fileObj = fileDescriptorBundle.find((a: any) => a.fileName != null);
        if (fileObj) {
          if (
            fileObj.fileType === "System Datasheet" ||
            fileObj.fileType === "System Diagram"
          ) {
            await fetchFiles(downloadBlobFl, fileObj).then((response: any) => {
              this.saveFile(
                response,
                fileObj.fileName + "." + fileObj.fileExtension,
                fileObj.contentType
              );
            });
          }
          else {
            this.setState({ downloadFlag: false });
            this.props.handleDimmer();
          }
        } else {
          this.setState({ downloadFlag: false });
          this.props.handleDimmer();
        }
      }
    } catch (error) {
      this.setState({ downloadFlag: false });
      const errorObj: any = error;
      if (errorObj?.response.status === 404) {
        this.props.handleDimmer();
      } else {
        this.props.handleDimmer();
      }
      return Promise.reject(error);
    }
  };

  getDetailData = (oid: any): any => {
    let productDetail: ProductDetailData[];

    productDetail = this.props.detailedSystems.filter(
      (element) => element.oid === oid
    );
    return productDetail && productDetail[0];
  };

  render() {
    const {
      mappingRow: {
        Index,
        RevitSelection: { RevitType, Ids },
        Options: { MappedSystem },
        OtherPlacoSystem,
      },
      detailedSystems,
      selectSystem,
      selectorType,
      selectOtherPlacoSystem,
      dropdownOptions,
    } = this.props;

    const defaultValue = (mappingRow: GenericMappingRow<PlacoOptions>) => {
      if (mappingRow.Options.MappedSystem) {
        if (mappingRow.Options.MappedSystem.oid.includes("custom")) {
          return {
            label: "Autre système (non PLACO)",
            value: mappingRow.Options.MappedSystem.oid,
          };
        }
        let productName:any=mappingRow.Options.MappedSystem.translation;
        if('attributes' in mappingRow.Options.MappedSystem)
        {
          let attributes= mappingRow.Options.MappedSystem["attributes"];
          productName = attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;           
        }
        
        return { 
          label: productName,
          value: mappingRow.Options.MappedSystem.oid,
        };
      } else {
        return { label: "", value: 0 };
      }
    };

    const dropDownOptions = (Index: number, SelectorType: string) => {
      return _.concat(
        [
          {
            label: "Autre système (non PLACO)",
            value: "custom_" + SelectorType + "_" + Index,
          },
        ],
        dropdownOptions
      );
    };

    const LightTooltip = withStyles((theme: Theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
        color: "white",
        boxShadow: theme.shadows[1],
        fontWeight: "bold",
        fontSize: 11,
        // height:40,
      },
    }))(Tooltip);

    const onSolutionSelect = (
      Index: number,
      System: ProductDetailData[],
      SelectorType: selectorTp,
      value: any
    ) => {
      const customIndex = "custom_" + SelectorType + "_" + Index;
      this.setState({ customValue: "" });
      if (value === customIndex) {
        selectSystem(
          Index,
          { translation: "", oid: customIndex },
          SelectorType
        );
      } else {
        selectSystem(
          Index,
          System.find((el) => el.oid === value) as ProductDetailData,
          SelectorType
        );
      }
    };

    const onOtherSolutionSelect = (
      Index: number,
      System: ProductDetailData[],
      SelectorType: selectorTp,
      value: any
    ) => {
      const customIndex = "custom_" + SelectorType + "_" + Index;
      this.setState({ customValue: "" });
      if (value === customIndex) {
        selectOtherPlacoSystem(
          Index,
          { translation: "", oid: customIndex },
          SelectorType
        );
      } else {
        selectOtherPlacoSystem(
          Index,
          System.find((el) => el.oid === value) as ProductDetailData,
          SelectorType
        );
      }
    };

    const advanceSelectorClick = async () => {
      try {
        bimStorage.setItem(
          storageKey.ACTIVE_PRODUCT,
          JSON.stringify(RevitType)
        );
        let productSubType = "partitionWall";
        if (OtherPlacoSystem) {
          productSubType = "protectionfeu";
        } else {
          switch (selectorType) {
            case "ceiling":
              productSubType = "ceiling";
              break;
            default:
              if (RevitType.toLowerCase().includes("cloison")) {
                productSubType = "partitionWall";
              } else if (RevitType.toLowerCase().includes("doublage")) {
                productSubType = "liningWall";
              }
          }
        }
        this.props.setFilterType(productSubType);
        this.props.setRoute(Routes.PRODUCT_SELECTOR);
        this.props.initProductSelector();
        this.props.setActiveRowIndex(this.props.mappingRow.Index);
        if (this.props.mappingRow.Options.MappedSystem?.oid) {
          this.props.selectBuffer(
            this.getDetailData(this.props.mappingRow.Options.MappedSystem?.oid)
          );
        }
        this.props.setScrollPosition();
      } catch (error) {
        return Promise.reject(error);
        // throw error;
      }
    };

    return (
      <Table.Row key={Index}>
        {/* <Table.Cell>
          <Checkbox
            onChange={(e, d) => {
              toggleSelectionRow(Index, selectorType);
            }}
            checked={Checked}
          /> 
        </Table.Cell>*/}
        <Table.Cell width="6">
          {selectorType === "others"
            ? `${RevitType}`
            : `${RevitType} (${Ids.length})`}
        </Table.Cell>
        <Table.Cell width="9">
          <Table.Row>
            <Table.Cell
              className="windowCursor"
              style={{ padding: "0px", border: "0px", width: "100%" }}
            >
              {/**
              WindowedSelect is an external library (For Dropdownlist)
              which loads dropdown options without time lag.
              For more information : https://github.com/jacobworrel/react-windowed-select
               */}
              <LightTooltip
                title={defaultValue(this.props.mappingRow).label}
                arrow
                placement="top-start"
              >
                <div>
                  <WindowedSelect
                    style={{ width: "100%" }}
                    search
                    options={dropDownOptions(Index, selectorType)}
                    value={defaultValue(this.props.mappingRow)}
                    onChange={(d: any) => {
                      if (d) localStorage.setItem("isModification", "true");
                      if (selectorType === "others") {
                        onOtherSolutionSelect(
                          Index,
                          detailedSystems,
                          selectorType,
                          d.value
                        );
                      } else {
                        onSolutionSelect(
                          Index,
                          detailedSystems,
                          selectorType,
                          d.value
                        );
                      }
                    }}
                    className="solution-select"
                  />
                </div>
              </LightTooltip>
            </Table.Cell>
            <Table.Cell
              style={{
                border: "1px solid rgba(34,36,38,.15)",
                backgroundColor: "#d6d6d673",
                padding: "0px",
              }}
            >
              <Popup
                trigger={
                  <Button
                    icon
                    style={{ margin: "0px", background: "none" }}
                    onClick={() =>
                      advanceSelectorClick().catch((error) => {
                        this.props.onError("PB-SE-H-002", error.stack, true);
                      })
                    }
                  >
                    <Icon
                      name="search"
                      color="blue"
                      style={{ padding: "0px" }}
                    />
                  </Button>
                }
                content="Recherche avancée"
                on="hover"
                position="top center"
                inverted
                size="mini"
              />
            </Table.Cell>
            {this.props.mappingRow.Options.MappedSystem ? (
              !this.props.mappingRow.Options.MappedSystem?.oid.includes(
                "custom_"
              ) ? (
                !this.props.mappingRow.RevitSelection.RevitType.includes(
                  "Gaines techniques"
                ) ? (
                  <Table.Cell
                    style={{
                      border: "1px solid rgba(34,36,38,.15)",
                      backgroundColor: "#d6d6d673",
                      padding: "0px",
                    }}
                  >
                    <Popup
                      trigger={
                        <Button
                          icon
                          style={{ margin: "0px", background: "none" }}
                          onClick={() => {
                            if (localStorage.getItem("min") !== "0:0") {
                              this.props.selectBuffer(
                                this.props.mappingRow.Options
                                  .MappedSystem as ProductDetailData
                              );
                              //this.props.showSystemDetails();
                              this.props.setRoute(
                                Routes.SYSTEM_DETAILS_MAPPING
                              );
                              this.props.setScrollConfigPosition();
                            } else {
                              this.setState({ clickedWarning: true });
                            }
                          }}
                        >
                          <Icon
                            name="list alternate outline"
                            color="blue"
                            style={{ padding: "0px" }}
                          />
                        </Button>
                      }
                      content="Détails"
                      on="hover"
                      position="top center"
                      inverted
                      size="mini"
                    />
                  </Table.Cell>
                ) : null
              ) : null
            ) : null}
            <Modal
              size="mini"
              open={this.state.clickedWarning}
            //onClose={() => setClickedWarning(false)}
            >
              <Modal.Header>Warning</Modal.Header>
              <Modal.Content>{wording.contentWarning["English"]}</Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  onClick={() => this.setState({ clickedWarning: false })}
                >
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={2} style={{ padding: "0px" }}>
              {defaultValue(this.props.mappingRow)?.value ===
                "custom_" + selectorType + "_" + Index && (
                  <Input
                    placeholder="Veuillez bien nommer votre solution non-Placo"
                    fluid
                    onChange={(e, d) => {
                      if (e) localStorage.setItem("isModification", "true");
                      this.setState({ customValue: d.value });
                    }}
                    value={this.state.customValue}
                    //added onBlur instead of onKeypress
                    onBlur={(event: any) => {
                      if (event) localStorage.setItem("isModification", "true");
                      this.setState({ customSolutionLoading: true });
                      selectSystem(
                        Index,
                        {
                          translation: this.state.customValue,
                          oid: "custom_" + selectorType + "_" + Index,
                        },
                        selectorType
                      );
                    }}
                    icon={
                      <Popup
                        trigger={
                          <Icon
                            style={{ padding: "0px" }}
                            name="save"
                            inverted
                            link
                            color={
                              this.props.mappingRow.Options.MappedSystem
                                ?.translation === this.state.customValue
                                ? "blue"
                                : "red"
                            }
                            onClick={() => {
                              this.setState({ customSolutionLoading: true });
                              selectSystem(
                                Index,
                                {
                                  translation: this.state.customValue,
                                  oid: "custom_" + selectorType + "_" + Index,
                                },
                                selectorType
                              );
                            }}
                          />
                        }
                        content="sauvegarder"
                        on="hover"
                        position="top center"
                        inverted
                        size="mini"
                      />
                    }
                  />
                )}
            </Table.Cell>
          </Table.Row>
        </Table.Cell>
        <Table.Cell widht="1">
          <Button.Group className="actionButtonGroup">
            {defaultValue(this.props.mappingRow).value !==
              "custom_" + selectorType + "_" + Index && (
                <Popup
                  trigger={
                    this.state.downloadFlag ? (
                      <Button icon style={{ background: "none" }}>
                        <Oval
                          color="#00BFFF" //3 secs
                          height={18}
                          width={18}
                        />
                      </Button>
                    ) : (
                      <Button
                        icon
                        disabled={
                          defaultValue(this.props.mappingRow).value === 0
                            ? true
                            : false
                        }
                        style={{ background: "none" }}
                        onClick={() =>
                          this.downLoadSheet(MappedSystem).catch((error) => {
                            this.props.onError(
                              errorCodeKey.PB_SE_L_002,
                              error.stack,
                              true
                            );
                          })
                        }
                      >
                        <Icon name="sign-in" rotated="clockwise" color="blue" />
                      </Button>
                    )
                  }
                  content="Télécharger la fiche système"
                  on="hover"
                  position="top center"
                  inverted
                  size="mini"
                />
              )}
            {/* {module.toLowerCase() === "selection" && (
              <Popup
                trigger={
                  <Button
                    icon
                    style={{ background: "none" }}
                    onClick={() =>
                      this.props.duplicateBufferSystem(Index, selectorType)
                    }
                  >
                    <Icon
                      name="clone outline"
                      rotated="clockwise"
                      color="blue"
                    />
                  </Button>
                }
                content="Dupliquer"
                on="hover"
                position="top center"
                inverted
                size="mini"
              />
            )} */}
            <Popup
              trigger={
                <Button
                  icon
                  style={{ background: "none" }}
                  onClick={() =>
                    this.props.deleteInBufferSystem(Index, selectorType)
                  }
                >
                  <Icon name="close" rotated="clockwise" color="red" />
                </Button>
              }
              content="Supprimer"
              on="hover"
              position="top center"
              inverted
              size="mini"
            />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    );
  }
}
const wording: WordingType = {
  contentWarning: {
    French:
      "Votre session a expiré, veuillez vous reconnecter pour voir cette page",
    English: "Your session has expired, please log in again to see this page",
    German: "Your session has expired, please log in again to see this page",
    Italian: "Your session has expired, please log in again to see this page",
  },
};

type WordingType = {
  contentWarning: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
  German: string;
  Italian: string;
};

export default connector(SelectionMappingRow);
