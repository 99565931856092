import React from "react";
import { PluginLauncher } from "./PluginLauncher";
import { List } from "semantic-ui-react";
// import {connect} from 'react-redux';
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { ID, Language } from "../../RevitJS/Types/RevitTypes";
import { Action } from "redux";

interface PluginLauncherListProps {
  plugins: Plugin[];
  selectPlugin: (id: ID) => Action;
  language: Language;
  config:any
}

export class PluginLauncherList extends React.Component<PluginLauncherListProps> {
  render() {
    const launchers = () =>
      this.props.plugins.map((data: any) => (
        <PluginLauncher
          language={this.props.language}
          selectPlugin={this.props.selectPlugin}
          key={data.plugin.Id}
          Icon={data.plugin.Icon}
          Name={data.name}
          Id={data.plugin.Id}
          ShortDesc={data.plugin.ShortDesc}
          Color={data.plugin.Color}
          TechnicalName={data.plugin.TechnicalName}
          config={this.props.config}
        />
      ));
    return <List>{launchers()}</List>;
  }
}
