import { AnyAction } from "redux";
import { searchAction, resetFiltersAction, setFilterTypeAction } from "../Actions/types";

type searchedWordAction = searchAction | resetFiltersAction | setFilterTypeAction;

export const searchedWord = (searchedWord: string = "", action: searchedWordAction) =>{
    switch(action.type){
        case "SEARCH":
            return action.searchedWord;
        case "SET_FILTER_TYPE":
            return "";
        case "RESET_FILTERS":
            return "";
        default:
            return searchedWord;
    }
}