import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, Checkbox, Container, Header } from "semantic-ui-react";
import { map, includes, filter, find } from "lodash";
import { api } from "../../../../RevitJS/API";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  onRevitSetupUpdate: any;
  revitSetupData: any;
  onBack: () => void;
}

type Shape<Fields> = {
  [Key in keyof Fields]: yup.ArraySchema<any, any> | yup.BooleanSchema;
};

interface Foo {
  floor: string[];
  threeD: string[];
  createNewPlan: boolean;
}

const validationSchema = yup.object<Shape<Foo>>().shape(
  {
    floor: yup
      .array()
      .when("threeD", (threeD: string[], schema: yup.ArraySchema<any, any>) => {
        return threeD[0] && threeD[0].length !== 1
          ? schema.min(1).required()
          : schema.optional().nullable();
      }),
    threeD: yup
      .array()
      .when("floor", (floor: string[], schema: yup.ArraySchema<any, any>) => {
        return floor[0] && floor[0].length !== 1
          ? schema.min(1).required()
          : schema.optional().nullable();
      }),
    createNewPlan: yup.boolean(),
  },
  [["floor", "threeD"]]
);

export const RevitSetup = (props: Props) => {
  const {
    onRevitSetupUpdate,
    revitSetupData: { floor, threeD, createNewPlan },
    onBack,
  } = props;

  const { register, handleSubmit, watch, setValue, getValues, formState } =
    useForm({
      defaultValues: {
        floor: map(floor, "ViewId"),
        threeD: map(threeD, "ViewId"),
        createNewPlan: createNewPlan,
      },
      resolver: yupResolver(validationSchema),
    });

  const [floorData, setFloorData] = useState<any>([]);
  const [threeDData, setThreeDData] = useState<any>([]);

  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    await loadFloors();
    await loadThreeD();
  };

  const loadFloors = async () => {
    let floors: any = await api.queries.getViewsByType("floor");
    floors = map(floors, (fr: any) => {
      let check: boolean = false;
      if (floor) {
        if (find(floor, { ViewId: fr.ViewId })) {
          check = true;
        }
      }

      return {
        LevelId: fr.LevelId,
        LevelName: fr.LevelName,
        ViewId: fr.ViewId,
        ViewName: fr.ViewName,
        type: "floor",
        check: check,
      };
    });
    setFloorData(floors);
  };

  const loadThreeD = async () => {
    let threeDs: any = await api.queries.getViewsByType("threeD");
    threeDs = map(threeDs, (thd: any) => {
      let check: boolean = false;
      if (threeD) {
        if (find(threeD, { ViewId: thd.ViewId })) {
          check = true;
        }
      }

      return {
        LevelId: thd.LevelId,
        LevelName: thd.LevelName,
        ViewId: thd.ViewId,
        ViewName: thd.ViewName,
        type: "threeD",
        check: check,
      };
    });
    setThreeDData(threeDs);
  };

  const onSubmit = (data: any) => {
    const rvtSetup: any = { floor: null, threeD: null, createNewPlan: false };
    if (data.floor) {
      const selectedFloor = filter(floorData, (fl: any) => {
        return includes(data.floor, fl.ViewId);
      });
      rvtSetup.floor = selectedFloor;
    }

    if (data.threeD) {
      const selected3D = filter(threeDData, (fl: any) => {
        return includes(data.threeD, fl.ViewId);
      });
      rvtSetup.threeD = selected3D;
    }
    rvtSetup.createNewPlan = data.createNewPlan;
    if (rvtSetup) {
      onRevitSetupUpdate(rvtSetup);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        className="header-alignment subHeader"
        style={{ marginTop: "10px", marginBottom: "0" }}
      >
        {"Paramétrage Revit"}
      </Container>

      <Container
        className="grid-container context-container"
        style={{
          height: "calc(100vh - 205px)",
          overflow: "auto",
          textAlign: "left",
          padding: "15px",
          border: "1px solid #ccc",
          marginTop: 0,
        }}
      >
        <div>
          <Header size="small" className="blue" style={{ marginBottom: "0" }}>
            Floor Plans
          </Header>
          <p style={{ marginBottom: "5px" }}>
            Please select REVIT floor plans to use as a basis to generate the
            Location plans
          </p>
          <div className="parameter-check-container">
            {map(floorData, (fr: any, key) => {
              return (
                <div className="ui checkbox">
                  <input
                    type="checkbox"
                    value={fr.ViewId}
                    {...register("floor")}
                    //  defaultChecked={fr.check}
                  />
                  <label>{fr.ViewName}</label>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <Header size="small" className="blue" style={{ marginBottom: "0" }}>
            3D view
          </Header>
          <p style={{ marginBottom: "5px" }}>
            Please select the REVIT 3D view to use as a basis for generate
            location plans
          </p>
          <div className="parameter-check-container">
            {map(threeDData, (th: any, key) => {
              return (
                <div className="ui checkbox">
                  <input
                    type="checkbox"
                    value={th.ViewId}
                    {...register("threeD")}
                    //defaultChecked={th.check}
                  />
                  <label>{th.ViewName}</label>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ paddingLeft: "5px" }}>
          <div className="ui checkbox">
            <input
              type="checkbox"
              {...register("createNewPlan")}
              //defaultChecked={createNewPlan}
            />
            <label>
              {
                "Check this case if you want to create a new spotting plan. If this box is not checked, the existing plan will be regenerated"
              }
            </label>
          </div>
        </div>
      </Container>

      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "8px",
          marginBottom: "20px",
        }}
      >
        <span>
          <Button color="orange" content={"Back"} onClick={() => onBack()} />
        </span>
        <span>
          <Button
            type="submit"
            color="blue"
            content={"validate"}
            disabled={!formState.isValid}
          />
        </span>
      </div>
    </form>
  );
};
