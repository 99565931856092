import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { DeleteRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "43142c42-9fbb-458b-b7db-f3bafab505a5";

export const deleteTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DeleteRoot />
    </ParentRoot>
  );
};

export const RiggipsDeleteRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DeleteRoot />
    </ParentRoot>
  );
};

export const Delete: Functionality = {
  Name: { French: "Supprimer", English: "Delete", German: "Löschen" },
  Trigger: deleteTrigger,
  ShortDesc: {
    French: "Effacer le calepinage",
    English: "Clear the layout",
    German: "Löschen Sie das Layout",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Suppression.png",
  RootComponent,
  ParentId: "0",
};
