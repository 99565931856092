import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddtoFavoriteIcon from "@material-ui/icons/StarBorderRounded";
import RemoveFavoriteIcon from "@material-ui/icons/Star";
import Button from "@material-ui/core/Button";
import AddtoCartIcon from "@material-ui/icons/AddShoppingCart";
import RemoveFromCartIcon from "@material-ui/icons/RemoveShoppingCart";
import {
  addSystemToCart,
  removeSystemFromCart,
  checkCartSystem,
} from "../../../store/cart/effects";
import {
  addSystemToFavorite,
  removeSystemFromFavorite,
  checkFavoriteSystem,
} from "../../../store/favorite/effects";
import { selectWindowToShow } from "../../../store/app/effects";
import { connect, ConnectedProps } from "react-redux";
import { ProductDetailData } from "../../../../../../RevitJS/Types/BddTypes";
import { APP_WINDOW_NAME } from "../../../assets/constants";
import { ID } from "../../../../../../RevitJS/Types/RevitTypes";
import { withTranslation, WithTranslation } from "react-i18next";
import { setCurrentSystemDetail } from "../../../store/systemDetail/effects";

import "./index.scss";
import _ from "lodash";

export const PlacoCard: React.FC<PlacoCardContainerProps> = ({
  system,
  oid,
  index,
  technicalName,
  productImage,
  productName,
  existingSystems,
  addSystemToCart,
  removeSystemFromCart,
  checkCartSystem,
  addSystemToFavorite,
  removeSystemFromFavorite,
  checkFavoriteSystem,
  setCurrentSystemDetail,
  selectWindowToShow,
  currentWindow,
  config,
  t,
}) => {
  const handleShowCartDetails = (system: ProductDetailData) => {
    setCurrentSystemDetail(system);
    selectWindowToShow(APP_WINDOW_NAME.DETAILS_SYSTEM_WINDOW);
  };

  const showConfiguration = (system: ProductDetailData) => {
    const gfrWorksNameObject = _.find(system.attributes, {
      technicalName: "GFR-Works name",
    });
    const partitionwallNameObject = _.find(system.attributes, {
      technicalName: "GFR-Partitionwall type",
    });
    if (gfrWorksNameObject) {
      const valueObject: any = _.get(gfrWorksNameObject, "values");
      const partitionWallObject: any = _.get(partitionwallNameObject, "values");
      const condition1 =
        valueObject[0].value === "Cloisons" &&
        partitionWallObject[0].value === "Distributive standard" &&
        !system.translation.includes("Caroplatre") &&
        !system.translation.includes("Placopan");
      const condition2 =
        valueObject[0].value === "Cloisons" &&
        partitionWallObject[0].value ===
        "Distributive ou séparative grande hauteur" &&
        (system.translation.includes("150 GH") ||
          system.translation.includes("Highstil"));
      const condition3 =
        valueObject[0].value === "Cloisons" &&
        partitionWallObject[0].value === "Séparative standard" &&
        (system.translation.includes("SAA") ||
          system.translation.includes("SAD"));
      const condition4 =
        valueObject[0].value === "Isolation des murs" &&
        (system.translation.includes("sur montant") ||
          system.translation.includes("grande hauteur") ||
          system.translation.includes("150 GH"));
      return condition1 || condition2 || condition3 || condition4;
    }
    return false;
  };

  const handleShowCartConfiguration = (system: ProductDetailData) => {
    setCurrentSystemDetail(system);
    selectWindowToShow(APP_WINDOW_NAME.CONFIGURATION_CART_WINDOW);
  };

  const systemInSolution = (system: ProductDetailData) => {
    return existingSystems.some(
      (sys: any) => system.translation === sys.solution
    );
  };

  const baseImageUrl = config.REACT_APP_BDDURL?.replace(
    "/plugins/authenticated",
    ""
  ) as string;
  const defaultImage = (e: any) => {
    e.target.src = "/dummy-img.png";
  }
  return (
    <Card
      className="loadCardContainer"
      variant="outlined"
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <CardContent
        className="loadCardContent flexRow"
        style={{
          padding: 0,
        }}
      >
        <img
          src={
            productImage
              ? baseImageUrl + productImage
              : "/dummy-img.png"
          }
          className="productImage"
          style={{ width: "117px" }}
          onError={defaultImage}
        />
        <div className="profuctName">
          <Typography variant="body2" component="p">
            {productName}
          </Typography>
        </div>
      </CardContent>
      <CardActions disableSpacing className="loadActionContainer flexColumn">
        <div className="loadActionIconContainer flexRow">
          {checkCartSystem(oid) ? (
            <IconButton
              aria-label="add to favorites"
              onClick={() => removeSystemFromCart(oid)}
            >
              <RemoveFromCartIcon style={{ color: "green" }} />
            </IconButton>
          ) : (
            <IconButton
              aria-label="add to favorites"
              onClick={() => addSystemToCart(system)}
              disabled={systemInSolution(system)}
            >
              <AddtoCartIcon />
            </IconButton>
          )}
          {checkFavoriteSystem(oid) ? (
            <IconButton
              aria-label="share"
              onClick={() => removeSystemFromFavorite(system)}
            >
              <RemoveFavoriteIcon style={{ color: "#FFCD02" }} />
            </IconButton>
          ) : (
            <IconButton
              aria-label="share"
              onClick={() => addSystemToFavorite(system)}
            >
              <AddtoFavoriteIcon />
            </IconButton>
          )}
        </div>
        <div className="loadActionButtons flexRow">
          <Button
            className={
              showConfiguration(system)
                ? "placoCardActionButton loadActionButton black-color"
                : "loadActionButtons loadActionButton black-color"
            }
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={() => handleShowCartDetails(system)}
          >
            {" "}
            {t("DETAILS")}{" "}
          </Button>
          {showConfiguration(system) && (
            <Button
              className="placoCardActionButton loadActionButton configurationActionButton"
              style={{ textTransform: "none" }}
              variant="contained"
              onClick={() => handleShowCartConfiguration(system)}
            >
              {" "}
              {t("CONFIGURATION")}{" "}
            </Button>
          )}
        </div>
      </CardActions>
    </Card>
  );
};

function mapStateToProps(state: any) {
  return {
    cartSystemItems: state.cart.currentSystemItems,
    favoriteSystemItems: state.favorite.currentFavoriteSystemItems,
    currentWindow: state.app.currentWindow,
    existingSystems: state.systems.existingSystems,
    config: state.config,
  };
}

let mapDispatchToProps = {
  checkCartSystem,
  addSystemToCart,
  removeSystemFromCart,
  checkFavoriteSystem,
  addSystemToFavorite,
  removeSystemFromFavorite,
  setCurrentSystemDetail,
  selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IState = {
  system: ProductDetailData;
  oid: ID;
  index: number;
  productImage?: string;
  productName?: string;
  technicalName?: string;
  config: any;
};

type PlacoCardContainerProps = PropsFromRedux & IState & WithTranslation;

export default connector(withTranslation(["weberIndonesia"])(PlacoCard));
