import React from "react";
import { useState, useEffect } from "react";
import { Home } from "./Home";
import { SystemsMapping } from "./SystemsMapping";
import { bimStorage } from "../../../../BIMStore";
import { remove, map } from "lodash";
import { GroupSelector } from "../features";
import { createNewSelection, onManualSelection } from "../utils/selection";
import { ESelectionMethod } from "../../Types";
import { mergeWallType } from "../Helper/AddWallTypeAfterAssignment";
import updateSelectionIfWallDeleted from "../../CommonModules/Helpers/selectionUpdateHelper";

export const AppBody = () => {
  const [route, setRoute] = useState<string>("ROOT");
  const [selection, setSelection] = useState<any>(null);

  const onGroupValidate = (elements: any, floors: any) => {
    setSelection((prevSelection: any) => {
      return { ...prevSelection, Floors: floors, SelectionDetails: elements };
    });
    setRoute("MAPPING");
  };

  const onSelectionUpdate = (elements: any, floors: any) => {
    setSelection((prevSelection: any) => {
      return { ...prevSelection, Floors: floors, SelectionDetails: elements };
    });
  };

  const onSystemTypeDelete = (value: any) => {
    var updatedSelectionDetails = remove(
      selection.SelectionDetails,
      function (n: any, v: any) {
        return n.ElementName != value;
      }
    );
    setSelection((prevSelection: any) => {
      return { ...prevSelection, SelectionDetails: updatedSelectionDetails };
    });
  };

  const onSystemsChange = (selectedOption: any, wallSelected: any) => {
    var selectionDetails = selection.SelectionDetails;
    map(selectionDetails, (wall) => {
      if (wallSelected.ElementName === wall.ElementName) {
        wall["Solution"] = {
          ExternalName: "",
          Name: selectedOption.label,
          Oid: selectedOption.value,
          ElementType: "Wall",
        };
      }
    });
    setSelection((prevSelection: any) => {
      return { ...prevSelection, SelectionDetails: selectionDetails };
    });
    return selectedOption;
  };

  const onNewSelectionName = async (name: string) => {
    const response = await createNewSelection(name);
    if (response) {
      setSelection(response);

      return response;
    }
    return null;
  };

  const onSelectionSave = async (elements: any) => {
    const projectId = await bimStorage.onProjectData();
    let response = false;
    if (projectId) {
      if (selection.Id) {
        var currentdate = new Date();
        const selectionObject = {
          ...selection,
          // Date: `${currentdate.getDate()}/${
          //   currentdate.getMonth() + 1
          // }/${currentdate.getFullYear()}`,
          Date: currentdate.getTime(),
          SelectionDetails: elements,
        };
        response = await bimStorage.updateSelection(
          selection.Id,
          selectionObject
        );
      } else {
        const selectionObject = { ...selection, SelectionDetails: elements };
        response = await bimStorage.saveSelection(selectionObject);
      }
    }

    if (response) {
      setRoute("ROOT");
    }
  };

  const onSelectionEdit = async (selectionId: any) => {
    const checkIfUpdated = await updateSelectionIfWallDeleted(selectionId);
    const selection = await new Promise((resolve, reject) =>
      window.indec.getSelection(parseInt(selectionId), resolve, reject)
    )
      .then((x: any) => {
        return x;
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      }); // bimStorage.getSelection(selectionId);

    if (selection.SelectionDetails) {
      let result: any = mergeWallType(selection.SelectionDetails);
      selection.SelectionDetails = result;
      setSelection(selection);
      setRoute("MAPPING");
    }
  };

  const onGroupBack = () => {
    setRoute("ROOT");
  };

  useEffect(() => {});

  switch (route) {
    case "ROOT":
      return (
        <Home
          onNewSelectionName={onNewSelectionName}
          onSelectionEdit={onSelectionEdit}
          setRoute={setRoute}
          onGroupValidate={onGroupValidate}
        />
      );
    case "GROUP_SELECTION":
      return (
        <GroupSelector
          onBack={onGroupBack}
          onValidate={onGroupValidate}
          showSubHeader={true}
          showHeader={true}
          headerName={selection.Name}
        />
      );
    case "MAPPING":
      return (
        <SystemsMapping
          setRoute={setRoute}
          selection={selection}
          onSystemTypeDelete={onSystemTypeDelete}
          onSelectionSave={onSelectionSave}
          onGroupValidate={onGroupValidate}
          onSelectionUpdate={onSelectionUpdate}
          onSystemsChange={onSystemsChange}
        />
      );
    default:
      return null;
  }
};
