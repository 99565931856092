import { Workbook, Worksheet } from "exceljs";
import {
  Dictionary,
  filter,
  find,
  forEach,
  groupBy,
  includes,
  map,
  orderBy,
  round,
  sumBy,
} from "lodash";
import { CeilingData, JsonLevel } from "../../../../RevitJS/Types/RevitTypes";
import { asyncForEach } from "../../../PlacoBIMv3/Quantitatiff/Extract/extractOpt";
import { dbFaker } from "../../Draw2/dbFaker";
import {
  ceilingElements,
  ceilingElementsgroupBy,
  elementProperties,
  getCeilingElement,
  getCeilingElementByLevel,
  getCeilingElementByProduct,
  getCeilings,
  getElementOfCeiling,
} from "./Helper";

interface finalResponse {
  row: number;
  column: number;
  value: string | number;
  merge?: boolean;
  mergeColumn?: number;
  border?: { top?: boolean; bottom?: boolean; left?: boolean; right?: boolean };
  blankRow: boolean;
  font?: {
    name: string;
    size: number;
    bold: boolean;
    color: { argb: string };
  };
  fill?: {
    type: "pattern";
    pattern: "solid";
    bgColor: { argb: string };
    fgColor: { argb: string };
  };
  alignment?: {
    vertical: "middle";
    horizontal: "center" | "left" | "right";
    wrapText: boolean;
  };
  columnWidth?: number;
  modelType?: boolean;
}
export const projectdata = async (
  ceilingLevel: JsonLevel,
  ceilingData: CeilingData[]
): Promise<finalResponse[]> => {
  let returnObject: finalResponse[] = [];

  returnObject.push({
    blankRow: false,
    row: 1,
    column: 1,
    value: "Project name",
    font: {
      name: "Arial",
      size: 14,
      bold: true,
      color: { argb: "000000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: 2,
    column: 1,
    value: "Level:",
    font: {
      name: "Arial",
      size: 12,
      bold: true,
      color: { argb: "000000" },
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    border: { bottom: true },
  });

  returnObject.push({
    blankRow: false,
    row: 2,
    column: 2,
    value: "Date:",
    font: {
      name: "Arial",
      size: 12,
      bold: true,
      color: { argb: "000000" },
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    border: { bottom: true },
  });

  returnObject.push({
    blankRow: false,
    row: 2,
    column: 3,
    value: "",
    border: { bottom: true },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: 2,
    column: 4,
    value: "",
    border: { bottom: true },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: 2,
    column: 5,
    value: "",
    border: { bottom: true },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  forEach(ceilingData, (ceiling, index) => {
    returnObject.push({
      blankRow: false,
      row: 2,
      column: 6 + index,
      value: "",
      border: { bottom: true },
      fill: {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "FFE000" },
      },
    });
  });

  returnObject.push({
    blankRow: false,
    row: 3,
    column: 1,
    value: ceilingLevel.Name,
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
  });

  return returnObject;
};

const ePlugCalculation: any = {
  "600x600": 3.35,
  "1200x600": 2.5,
  "1200x1200": 1.7,
  "1600x600": 2.3,
  "1800x600": 2.25,
  "2000x600": 2.2,
  "2400x600": 2.1,
};
export const overviewByLevel = async (workbook: Workbook) => {
  const { ceilingLevels, ceilingDetails } = await getCeilings();

  const elementData: ceilingElements = await getCeilingElement(ceilingDetails);

  await asyncForEach(
    ceilingLevels,
    async (ceilingLevel: JsonLevel, index: number) => {
      const ceilingOnLevel: CeilingData[] = filter(ceilingDetails, {
        LevelId: ceilingLevel.Id,
      });
      const projectDataResult: finalResponse[] = await projectdata(
        ceilingLevel,
        ceilingOnLevel
      );
      const summaryHeader: finalResponse[] = await summaryDataHeader(
        ceilingOnLevel
      );
      const elementDataByLevel: ceilingElements =
        await getCeilingElementByLevel(ceilingLevel, elementData);
      const elementDataByProduct: ceilingElementsgroupBy =
        await getCeilingElementByProduct(elementDataByLevel);
      let startingRow: number = 7;

      const summaryDetails: { value: finalResponse[]; startingRow: number } =
        await summaryData(elementDataByProduct, ceilingOnLevel, startingRow);

      if (summaryDetails.value.length > 0) {
        // adding sheet based on level name
        const overviewByLevelSheet = workbook.addWorksheet(ceilingLevel.Name, {
          views: [{ showGridLines: false }],
        });
        // filling projet data
        await plotElement(overviewByLevelSheet, projectDataResult);
        // summary table header
        await plotElement(overviewByLevelSheet, summaryHeader);
        // summary body
        await plotElement(overviewByLevelSheet, summaryDetails.value);

        startingRow = summaryDetails.startingRow + 1;

        /// strating the loop on ceilings to print the ceilings specific data
        await asyncForEach(
          ceilingOnLevel,
          async (ceiling: CeilingData, index: number) => {
            if (ceiling.ProductName) {
              const {
                selectedParentSystem,
                ePlugPerimeterMultiplyBy,
                dimensionForProduct,
                selectedParentConfig,
                selectedEcophonSystem,
              } = extraCalculations(ceiling);

              startingRow = startingRow + 1;
              const ceilingElementDetails = await getElementOfCeiling(
                elementDataByLevel,
                ceiling.Id
              );

              const dataHeaderRow = startingRow;
              startingRow = dataHeaderRow + 2;

              const mainRunnerBody = await ceilingDataBody(
                ceilingElementDetails.mainRunner,
                ceilingOnLevel,
                startingRow,
                "m"
              );

              startingRow = mainRunnerBody.startingRow;
              if (includes(selectedParentSystem, "Concealed Grid")) {
                if (dimensionForProduct === "1200x1200") {
                  const edgeStiffenerBody = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Edge stiffener",
                    ceiling.Area,
                    1.4
                  );
                  startingRow = edgeStiffenerBody.startingRow;
                  await plotElement(
                    overviewByLevelSheet,
                    edgeStiffenerBody.value
                  );
                } else {
                  const edgeStiffenerBody = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Support clip Dg20",
                    ceiling.MaxLength,
                    6.7
                  );
                  startingRow = edgeStiffenerBody.startingRow;
                  await plotElement(
                    overviewByLevelSheet,
                    edgeStiffenerBody.value
                  );
                }
              }
              const crossTeeBody = await ceilingDataBody(
                ceilingElementDetails.crossTee,
                ceilingOnLevel,
                startingRow,
                "m"
              );
              startingRow = crossTeeBody.startingRow;
              const hangerBody = await ceilingHangerBody(
                ceilingElementDetails.hanger,
                ceilingOnLevel,
                startingRow,
                "pcs",
                false
              );
              startingRow = hangerBody.startingRow;

              const angleTrimBody = await ceilingDataBody(
                ceilingElementDetails.angleTrim,
                ceilingOnLevel,
                startingRow,
                "m"
              );
              startingRow = angleTrimBody.startingRow;

              const bracket1Body = await ceilingDataBody(
                ceilingElementDetails.bracket1,
                ceilingOnLevel,
                startingRow,
                "pcs"
              );
              startingRow = bracket1Body.startingRow;

              const bracket2Body = await ceilingDataBody(
                ceilingElementDetails.bracket2,
                ceilingOnLevel,
                startingRow,
                "pcs"
              );
              startingRow = bracket2Body.startingRow;

              const spaceBarBody = await ceilingDataBody(
                ceilingElementDetails.spaceBar,
                ceilingOnLevel,
                startingRow,
                "m"
              );
              startingRow = spaceBarBody.startingRow;

              if (includes(selectedParentSystem, "Concealed Grid")) {
                const spaceBarWinchBody = await spaceBareWinchBody(
                  ceilingOnLevel,
                  startingRow,
                  "pcs",
                  "Connect Space bar winch",
                  ceiling.Area,
                  1.4
                );
                startingRow = spaceBarWinchBody.startingRow;

                await plotElement(
                  overviewByLevelSheet,
                  spaceBarWinchBody.value
                );
              }

              if (includes(selectedParentSystem, "Hygiene Concealed Grid")) {
                if (includes(selectedParentConfig[0], "C4")) {
                  const spaceBarWinchC4Body = await spaceBareWinchBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Space bar winch C4",
                    ceiling.Area,
                    1.4
                  );
                  startingRow = spaceBarWinchC4Body.startingRow;

                  await plotElement(
                    overviewByLevelSheet,
                    spaceBarWinchC4Body.value
                  );
                } else {
                  const spaceBarWinchC4Body = await spaceBareWinchBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Space bar winch",
                    ceiling.Area,
                    1.4
                  );
                  startingRow = spaceBarWinchC4Body.startingRow;

                  await plotElement(
                    overviewByLevelSheet,
                    spaceBarWinchC4Body.value
                  );

                  const hangerClipBody = await ceilingHangerBody(
                    ceilingElementDetails.hanger,
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    true,
                    "Connect Hanger clip"
                  );
                  startingRow = hangerClipBody.startingRow;
                  await plotElement(overviewByLevelSheet, hangerClipBody.value);

                  const friezeBracketBody = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Frieze Bracket",
                    ceiling.MaxLength,
                    6.7
                  );
                  startingRow = friezeBracketBody.startingRow;

                  await plotElement(
                    overviewByLevelSheet,
                    friezeBracketBody.value
                  );
                }
              }

              if (
                !includes(selectedParentSystem, "Hygiene") &&
                !includes(selectedParentSystem, "Hygiene Concealed Grid")
              ) {
                const hangerClipBody = await ceilingHangerBody(
                  ceilingElementDetails.hanger,
                  ceilingOnLevel,
                  startingRow,
                  "pcs",
                  true,
                  "Connect Hanger clip"
                );
                startingRow = hangerClipBody.startingRow;
                await plotElement(overviewByLevelSheet, hangerClipBody.value);
              }

              const hygieneClipBody = await ceilingDataBody(
                ceilingElementDetails.hygieneclip,
                ceilingOnLevel,
                startingRow,
                "m"
              );
              startingRow = hygieneClipBody.startingRow;

              const corniersBody = await ceilingDataBody(
                ceilingElementDetails.cornieres,
                ceilingOnLevel,
                startingRow,
                "m"
              );
              startingRow = corniersBody.startingRow;

              if (includes(selectedParentSystem, "Concealed Grid")) {
                const tileClipBody = await ceilingPerimeterBody(
                  ceilingOnLevel,
                  startingRow,
                  "pcs",
                  "Connect Perimeter tile clip",
                  ceiling.Perimeter,
                  3.05
                );
                startingRow = tileClipBody.startingRow;

                await plotElement(overviewByLevelSheet, tileClipBody.value);
              }

              if (
                includes(selectedParentSystem, "Standard") &&
                !includes(selectedParentSystem, "Hygiene")
              ) {
                if (includes(selectedParentConfig, "Edge E")) {
                  if (mainRunnerBody.value.length > 0) {
                    const ePlugBody = await ceilingPerimeterBody(
                      ceilingOnLevel,
                      startingRow,
                      "pcs",
                      "Connect E-plug (only for E edge)",
                      ceiling.Perimeter,
                      ePlugPerimeterMultiplyBy
                    );
                    startingRow = ePlugBody.startingRow;

                    await plotElement(overviewByLevelSheet, ePlugBody.value);
                  }
                }
              }

              if (includes(selectedParentSystem, "Hygiene Concealed Grid")) {
                if (includes(selectedParentConfig[0], "C4")) {
                  const supportClipC4 = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Support clip Dg20 C4",
                    ceiling.MaxLength,
                    6.7
                  );
                  startingRow = supportClipC4.startingRow;

                  await plotElement(overviewByLevelSheet, supportClipC4.value);
                }
              }

              if (includes(selectedParentSystem, "Corridor")) {
                if (
                  includes(selectedParentConfig, "Corridor Edge E_300") ||
                  includes(selectedParentConfig, "Corridor Edge E_600")
                ) {
                  const multiplyValue = includes(
                    selectedParentConfig,
                    "Corridor Edge E_300"
                  )
                    ? 2.2
                    : includes(selectedParentConfig, "Corridor Edge E_600")
                    ? 2.1
                    : 0;

                  const ePlugBody = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect E-plug",
                    ceiling.Perimeter,
                    multiplyValue
                  );
                  startingRow = ePlugBody.startingRow;

                  await plotElement(overviewByLevelSheet, ePlugBody.value);
                }

                if (
                  includes(selectedParentConfig, "Corridor Edge Ds_300") ||
                  includes(selectedParentConfig, "Corridor Edge Ds_600")
                ) {
                  const coverTrim = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Cover trim Ds",
                    ceiling.MaxLength,
                    3.33
                  );
                  startingRow = coverTrim.startingRow;

                  await plotElement(overviewByLevelSheet, coverTrim.value);

                  const supportClip = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Support clip Dg20",
                    ceiling.MaxWidth,
                    6.7
                  );
                  startingRow = supportClip.startingRow;

                  await plotElement(overviewByLevelSheet, supportClip.value);
                }
              }

              if (
                includes(selectedParentSystem, "Hygiene") ||
                includes(selectedParentSystem, "Hygiene Concealed Grid")
              ) {
                if (!includes(selectedParentConfig[0], "Clean Room")) {
                  if (selectedEcophonSystem.parentRange === "Edge Ds (C4)") {
                    const fixingPlateBody = await ceilingHangerBody(
                      ceilingElementDetails.hanger,
                      ceilingOnLevel,
                      startingRow,
                      "pcs",
                      true,
                      "Connect Fixing Plate C4"
                    );
                    startingRow = fixingPlateBody.startingRow;
                    await plotElement(
                      overviewByLevelSheet,
                      fixingPlateBody.value
                    );
                  } else {
                    const fixingPlateBody = await ceilingHangerBody(
                      ceilingElementDetails.hanger,
                      ceilingOnLevel,
                      startingRow,
                      "pcs",
                      true,
                      "Connect Fixing Plate"
                    );
                    startingRow = fixingPlateBody.startingRow;
                    await plotElement(
                      overviewByLevelSheet,
                      fixingPlateBody.value
                    );
                  }

                  const anchorScrewBody = await ceilingHangerBody(
                    ceilingElementDetails.hanger,
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    true,
                    "Connect Anchor Screw C4"
                  );
                  startingRow = anchorScrewBody.startingRow;

                  await plotElement(
                    overviewByLevelSheet,
                    anchorScrewBody.value
                  );

                  const instScrewBody = await ceilingPerimeterBody(
                    ceilingOnLevel,
                    startingRow,
                    "pcs",
                    "Connect Installation Screw C4",
                    ceiling.Perimeter,
                    3.34
                  );
                  startingRow = instScrewBody.startingRow;

                  await plotElement(overviewByLevelSheet, instScrewBody.value);
                }
              }
              // const trimBody = await ceilingDataBody(ceilingElementDetails.,ceilingOnLevel, startingRow, "m" )

              await plotElement(overviewByLevelSheet, mainRunnerBody.value);
              await plotElement(overviewByLevelSheet, crossTeeBody.value);
              await plotElement(overviewByLevelSheet, hangerBody.value);
              await plotElement(overviewByLevelSheet, hygieneClipBody.value);
              await plotElement(overviewByLevelSheet, angleTrimBody.value);
              await plotElement(overviewByLevelSheet, bracket1Body.value);
              await plotElement(overviewByLevelSheet, bracket2Body.value);
              await plotElement(overviewByLevelSheet, spaceBarBody.value);
              await plotElement(overviewByLevelSheet, corniersBody.value);

              if (
                mainRunnerBody.value.length > 0 ||
                crossTeeBody.value.length > 0 ||
                hangerBody.value.length > 0 ||
                angleTrimBody.value.length > 0 ||
                bracket1Body.value.length > 0 ||
                bracket2Body.value.length > 0 ||
                spaceBarBody.value.length > 0 ||
                corniersBody.value.length > 0
              ) {
                const ceilingHeaderData = await ceilingDataHeader(
                  ceilingOnLevel,
                  dataHeaderRow,
                  ceiling.Id,
                  ceiling.RoomName!
                );
                await plotElement(
                  overviewByLevelSheet,
                  ceilingHeaderData.value
                );
              }
            }
          }
        );
      }
    }
  );
};

const extraCalculations = (ceiling: CeilingData) => {
  const prName = ceiling?.ProductName;
  const ecophonSystem = ceiling?.EcophonSystem;
  let selectedParentSystem: any = [];
  let selectedEcophonSystem: any = {};
  if (prName && ecophonSystem) {
    const dbFakerProducts: { [key: string]: { id: string } } = dbFaker.Products;
    const mapProduct = map(dbFakerProducts, (pr: any, ind: number) => {
      return {
        parentSystem: pr["parentSystem"],
        product: pr["product"],
        parentConfig: pr["parentConfig"],
        parentRange: pr["parentRange"],
      };
    });
    selectedEcophonSystem = find(mapProduct, {
      product: prName,
      parentSystem: ecophonSystem,
    });

    selectedParentSystem = selectedEcophonSystem.parentSystem;
  }

  let ePlugPerimeterMultiplyBy = 0;
  const splitProductName: any = ceiling.ProductName?.split(" ");
  const dimensionForProduct = splitProductName[splitProductName?.length - 1];

  if (includes(selectedParentSystem, "Standard")) {
    ePlugPerimeterMultiplyBy = ePlugCalculation[dimensionForProduct];
  }

  const selectedParentConfig = selectedEcophonSystem.parentConfig;

  return {
    selectedParentSystem,
    ePlugPerimeterMultiplyBy,
    dimensionForProduct,
    selectedParentConfig,
    selectedEcophonSystem,
  };
};

const plotElement = async (
  overviewByLevelSheet: Worksheet,
  data: finalResponse[]
) => {
  await asyncForEach(data, (dt: finalResponse, index: number) => {
    overviewByLevelSheet.getRow(dt.row).height = 22;
    if (!dt.blankRow) {
      if (dt.merge) {
        overviewByLevelSheet.mergeCells(
          dt.row,
          dt.column,
          dt.row,
          dt.mergeColumn ? dt.mergeColumn : dt.column
        );

        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).value = dt.value;
      } else {
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).value = dt.value;
      }
      if (dt.font) {
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).font = dt.font;
      }

      if (dt.fill) {
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).fill = dt.fill;
      }

      if (dt.border) {
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).border = {
          top: dt.border.top ? { style: "thin" } : undefined,
          left: dt.border.left ? { style: "thin" } : undefined,
          bottom: dt.border.bottom ? { style: "thin" } : undefined,
          right: dt.border.right ? { style: "thin" } : undefined,
        };
      }

      if (dt.alignment) {
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).alignment =
          dt.alignment;
      }

      if (dt.columnWidth) {
        overviewByLevelSheet.getColumn(dt.column).width = dt.columnWidth;
      }

      if (dt.modelType) {
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).model.type = 2;
        overviewByLevelSheet.getRow(dt.row).getCell(dt.column).numFmt = "0";
      }
    }
  });
};

const summaryDataHeader = async (
  ceilingFromLevel: CeilingData[]
): Promise<finalResponse[]> => {
  let returnObject: finalResponse[] = [];

  returnObject.push({
    blankRow: false,
    row: 5,
    column: 1,
    value: "Summary",
    merge: true,
    mergeColumn: ceilingFromLevel.length + 5,
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 12,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
  });

  returnObject.push({
    blankRow: false,
    row: 6,
    column: 1,
    value: "Item nr",
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    columnWidth: 19.82,
  });

  returnObject.push({
    blankRow: false,
    row: 6,
    column: 2,
    value: "Product name",
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    columnWidth: 32.09,
  });

  returnObject.push({
    blankRow: false,
    row: 6,
    column: 3,
    value: "Dim.",
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { right: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    columnWidth: 10.36,
  });

  forEach(ceilingFromLevel, (ceiling, index) => {
    const roomNameString = map(ceiling.Rooms, "RoomName").toString();
    returnObject.push({
      blankRow: false,
      row: 6,
      column: 4 + index,
      value: roomNameString !== "" ? roomNameString : parseFloat(ceiling.Id),
      modelType: roomNameString !== "" ? false : true,
      fill: {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "FFE000" },
      },
      font: {
        name: "Arial",
        size: 10,
        bold: true,
        color: { argb: "000000" },
      },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      columnWidth: 12.18,
    });
  });

  returnObject.push({
    blankRow: false,
    row: 6,
    column: ceilingFromLevel.length + 4,
    value: "Total",
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { left: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    columnWidth: 11.18,
  });

  returnObject.push({
    blankRow: false,
    row: 6,
    column: ceilingFromLevel.length + 5,
    value: "Units",
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    columnWidth: 8.55,
  });

  return returnObject;
};
interface fixingPlate {
  productName: string;
  dimension: string;
  ceilingId: string;
  value: number;
}

const summaryData = async (
  elementData: ceilingElementsgroupBy,
  ceilingLevels: CeilingData[],
  startingRow: number
): Promise<{ value: finalResponse[]; startingRow: number }> => {
  let returnObject: finalResponse[] = [];

  let hangerAccessories: elementProperties[] = [];
  let hangerValueGroup = "120-200";

  forEach(elementData.hanger, (eleValue: elementProperties[], key: string) => {
    forEach(eleValue, (elem: elementProperties, elemKey: number) => {
      switch (elem.length) {
        case 120:
          hangerValueGroup = "120-200";
          break;
        case 201:
          hangerValueGroup = "201-340";
          break;
        case 341:
          hangerValueGroup = "341-600";
          break;
        case 601:
          hangerValueGroup = "601-1000";
          break;
        case 1001:
          hangerValueGroup = "1001-1400";
          break;
        case 1401:
          hangerValueGroup = "1401-1800";
          break;
        case 1801:
          hangerValueGroup = "1801-2000";
          break;
        default:
          hangerValueGroup = "120-200";
      }

      hangerAccessories.push({
        name: elem.name,
        length: hangerValueGroup,
        unit: "pcs",
        ceilingId: elem.ceilingId,
        levelId: elem.levelId,
        levelName: elem.levelName,
        id: "",
      });
    });
  });

  const otHanger: Dictionary<elementProperties[]> = groupBy(
    hangerAccessories,
    "name"
  );

  startingRow = summaryDataModelelement(
    elementData.mainRunner,
    ceilingLevels,
    startingRow,
    returnObject,
    "m"
  );

  startingRow = summaryDataModelelement(
    elementData.crossTee,
    ceilingLevels,
    startingRow,
    returnObject,
    "m"
  );

  startingRow = summaryHangerLengthDataModelelement(
    otHanger,
    ceilingLevels,
    startingRow,
    returnObject,
    false,
    ""
  );

  startingRow = summaryDataModelelement(
    elementData.angleTrim,
    ceilingLevels,
    startingRow,
    returnObject,
    "m"
  );

  startingRow = summaryDataModelelement(
    elementData.hygieneclip,
    ceilingLevels,
    startingRow,
    returnObject,
    "m"
  );

  startingRow = summaryDataModelelement(
    elementData.spaceBar,
    ceilingLevels,
    startingRow,
    returnObject,
    "m"
  );

  startingRow = summaryDataModelelement(
    elementData.cornieres,
    ceilingLevels,
    startingRow,
    returnObject,
    "m"
  );

  startingRow = summaryBracketDataModelelement(
    elementData.bracket1,
    ceilingLevels,
    startingRow,
    returnObject,
    "pcs"
  );

  startingRow = summaryBracketDataModelelement(
    elementData.bracket2,
    ceilingLevels,
    startingRow,
    returnObject,
    "pcs"
  );

  startingRow = summaryHangerDataModelelement(
    otHanger,
    ceilingLevels,
    startingRow,
    returnObject,
    true,
    "Connect Hanger Clip"
  );

  let otherAccessories: elementProperties[] = [];

  let connectFixingPlate: fixingPlate[] | [] = [];
  let connectAnchorScrew: fixingPlate[] | [] = [];

  await asyncForEach(
    ceilingLevels,
    async (ceiling: CeilingData, index: number) => {
      if (ceiling.ProductName) {
        const {
          selectedParentSystem,
          ePlugPerimeterMultiplyBy,
          dimensionForProduct,
          selectedParentConfig,
          selectedEcophonSystem,
        } = extraCalculations(ceiling);

        if (
          includes(selectedParentSystem, "Standard") &&
          !includes(selectedParentSystem, "Hygiene")
        ) {
          if (includes(selectedParentConfig, "Edge E")) {
            otherAccessories.push({
              name: "Connect E-plug (only for E edge)",
              length: ceiling.Perimeter
                ? round(ceiling.Perimeter * ePlugPerimeterMultiplyBy, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }
        }

        if (includes(selectedParentSystem, "Corridor")) {
          if (
            includes(selectedParentConfig, "Corridor Edge E_300") ||
            includes(selectedParentConfig, "Corridor Edge E_600")
          ) {
            const multiplyValue = includes(
              selectedParentConfig,
              "Corridor Edge E_300"
            )
              ? 2.2
              : includes(selectedParentConfig, "Corridor Edge E_600")
              ? 2.1
              : 0;

            otherAccessories.push({
              name: "Connect E-plug",
              length: ceiling.Perimeter
                ? round(ceiling.Perimeter * multiplyValue, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }

          if (
            includes(selectedParentConfig, "Corridor Edge Ds_300") ||
            includes(selectedParentConfig, "Corridor Edge Ds_600")
          ) {
            otherAccessories.push({
              name: "Connect Cover trim Ds",
              length: ceiling.MaxLength
                ? round(parseFloat(ceiling.MaxLength) * 3.33, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
            otherAccessories.push({
              name: "Connect Support clip Dg20",
              length: ceiling.MaxWidth
                ? round(parseFloat(ceiling.MaxWidth) * 6.7, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }
        }

        if (includes(selectedParentSystem, "Concealed Grid")) {
          if (dimensionForProduct === "1200x1200") {
            otherAccessories.push({
              name: "Connect Edge stiffener",
              length: ceiling.Area ? round(ceiling.Area * 1.4, 0) : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });

            otherAccessories.push({
              name: "Connect Space bar winch",
              length: ceiling.Area ? round(ceiling.Area * 0.7, 0) : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          } else {
            otherAccessories.push({
              name: "Connect Support clip Dg20",
              length: ceiling.MaxLength
                ? round(parseFloat(ceiling.MaxLength) * 6.7, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });

            otherAccessories.push({
              name: "Connect Space bar winch",
              length: ceiling.Area ? round(ceiling.Area * 1.4, 0) : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }

          otherAccessories.push({
            name: "Connect Perimeter tile clip",
            length: ceiling.Perimeter ? round(ceiling.Perimeter * 3.05, 0) : 0,
            unit: "pcs",
            ceilingId: ceiling.Id,
            levelId: ceiling.LevelId,
            levelName: ceiling.LevelName,
            id: "",
          });
        }

        if (
          includes(selectedParentSystem, "Hygiene") ||
          includes(selectedParentSystem, "Hygiene Concealed Grid")
        ) {
          if (!includes(selectedParentConfig[0], "Clean Room")) {
            if (selectedEcophonSystem.parentRange === "Edge Ds (C4)") {
              ceilingFixingPlate(
                otHanger,
                ceilingLevels,
                returnObject,
                "Connect Fixing Plate C4",
                ceiling,
                index,
                connectFixingPlate
              );
            } else {
              ceilingFixingPlate(
                otHanger,
                ceilingLevels,
                returnObject,
                "Connect Fixing Plate",
                ceiling,
                index,
                connectFixingPlate
              );
            }

            ceilingFixingPlate(
              otHanger,
              ceilingLevels,
              returnObject,
              "Connect Anchor Screw C4",
              ceiling,
              index,
              connectAnchorScrew
            );

            otherAccessories.push({
              name: "Connect Installation Screw C4",
              length: ceiling.Perimeter
                ? round(ceiling.Perimeter * 3.34, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          } else {
            otherAccessories.push({
              name: "Connect Frieze Bracket",
              length: ceiling.MaxLength
                ? round(parseFloat(ceiling.MaxLength) * 6.7, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }

          if (includes(selectedParentConfig[0], "C4")) {
            otherAccessories.push({
              name: "Connect Space bar winch C4",
              length: ceiling.Area ? round(ceiling.Area * 1.4, 0) : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });

            otherAccessories.push({
              name: "Connect Support Clip Dg20 C4",
              length: ceiling.MaxLength
                ? round(parseFloat(ceiling.MaxLength) * 6.7, 0)
                : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }

          if (includes(selectedParentConfig[0], "Clean Room")) {
            otherAccessories.push({
              name: "Connect Space bar winch",
              length: ceiling.Area ? round(ceiling.Area * 1.4, 0) : 0,
              unit: "pcs",
              ceilingId: ceiling.Id,
              levelId: ceiling.LevelId,
              levelName: ceiling.LevelName,
              id: "",
            });
          }
        }
      }
    }
  );

  startingRow = summaryFixingPlateModelelement(
    connectFixingPlate,
    startingRow,
    returnObject,
    ceilingLevels
  );

  startingRow = summaryFixingPlateModelelement(
    connectAnchorScrew,
    startingRow,
    returnObject,
    ceilingLevels
  );

  const otAcc: Dictionary<elementProperties[]> = groupBy(
    otherAccessories,
    "name"
  );

  startingRow = summaryDataModelelement(
    otAcc,
    ceilingLevels,
    startingRow,
    returnObject,
    "pcs"
  );

  return { value: returnObject, startingRow };
};

const summaryDataModelelement = (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  startingRow: number,
  returnObject: finalResponse[],
  unit: string
) => {
  forEach(eleData, (mbpnValue, key) => {
    const mapValueByLenght = groupBy(mbpnValue, "length");

    const sortedLength = orderBy(
      mapValueByLenght,
      [
        function (o) {
          return o[0].length;
        },
      ],
      "desc"
    );

    let summaryTotalRow = 0;
    forEach(mapValueByLenght, (lengthValue, lenKey) => {
      forEach(ceilingLevels, (ceiling, index) => {
        const elementByCeiling = filter(lengthValue, {
          ceilingId: ceiling.Id,
        });

        let calculateRow = 0;
        if (
          elementByCeiling.length > 0 &&
          (elementByCeiling[0].name === "Connect Installation Screw C4" ||
            elementByCeiling[0].name === "Connect Space bar winch" ||
            elementByCeiling[0].name === "Connect Perimeter tile clip")
        ) {
          calculateRow =
            parseFloat(lenKey) * parseFloat(elementByCeiling.length.toString());
        } else {
          calculateRow =
            lenKey === "0"
              ? parseFloat(elementByCeiling.length.toString())
              : round(
                  (parseFloat(lenKey) *
                    parseFloat(elementByCeiling.length.toString())) /
                    1000,
                  0
                );
        }

        summaryTotalRow = summaryTotalRow + calculateRow;
      });
    });

    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 1,
      value: " ",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 2,
      value: key,
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 3,
      value:
        sortedLength[0][0].name === "Connect Support clip Dg20" ||
        sortedLength[0][0].name === "Connect Installation Screw C4" ||
        sortedLength[0][0].name === "Connect Space bar winch" ||
        sortedLength[0][0].name === "Connect Perimeter tile clip"
          ? " "
          : sortedLength[0][0].length,
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { right: true, bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    });

    if (
      sortedLength[0][0].name === "Connect Installation Screw C4" ||
      sortedLength[0][0].name === "Connect Space bar winch" ||
      sortedLength[0][0].name === "Connect Perimeter tile clip"
    ) {
      let totalValue = 0;
      forEach(ceilingLevels, (ceiling, index) => {
        const elementByCeiling = filter(mbpnValue, {
          ceilingId: ceiling.Id,
        });

        const sumoflength = sumBy(elementByCeiling, "length");

        if (elementByCeiling.length > 0) {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: sumoflength,
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          });
        } else {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: "",
            border: { bottom: true },
          });
        }
      });
    } else {
      forEach(ceilingLevels, (ceiling, index) => {
        const elementByCeiling = filter(mbpnValue, {
          ceilingId: ceiling.Id,
        });
        const groupByLengthCeiling = groupBy(elementByCeiling, "length");
        let sumLength = 0;
        map(groupByLengthCeiling, (values, key) => {
          sumLength =
            sumLength + round((parseFloat(key) * values.length) / 1000, 0);
        });

        if (elementByCeiling.length > 0) {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: sumLength,
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          });
        } else {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: "",
            border: { bottom: true },
          });
        }
      });
    }
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 4 + ceilingLevels.length,
      value: summaryTotalRow,
      border: { bottom: true, left: true },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 5 + ceilingLevels.length,
      value: unit,
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    });
    startingRow = startingRow + 1;
    // forEach(mapValueByLenght, (lengthValue, lenKey) => {

    // });
  });

  return startingRow;
};

const summaryHangerLengthDataModelelement = (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  startingRow: number,
  returnObject: finalResponse[],
  duplicateKey: boolean,
  duplicateKeyValue: string
) => {
  forEach(eleData, (mbpnValue, key) => {
    const mapValueByLenght: any = groupBy(mbpnValue, "length");
    forEach(mapValueByLenght, (hangerGroup: any, hangerKey: string) => {
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 1,
        value: " ",
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 2,
        value: key,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "left",
          wrapText: true,
        },
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 3,
        value: hangerKey,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { right: true, bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
      });

      forEach(ceilingLevels, (ceiling, index) => {
        const elementByCeiling = filter(hangerGroup, {
          ceilingId: ceiling.Id,
        });
        if (elementByCeiling.length > 0) {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: parseFloat(elementByCeiling.length.toString()),
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          });
        } else {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: "",
            border: { bottom: true },
          });
        }
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 4 + ceilingLevels.length,
        value: parseFloat(hangerGroup.length.toString()),
        border: { bottom: true, left: true },
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 5 + ceilingLevels.length,
        value: "pcs",
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
      });
      startingRow = startingRow + 1;
      return hangerGroup;
    });
  });

  return startingRow;
};

const ceilingFixingPlate = (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  returnObject: finalResponse[],
  duplicateKeyValue: string,
  ceiling: CeilingData,
  index: number,
  connectFixingPlate: fixingPlate[]
) => {
  forEach(eleData, (mbpnValue, key) => {
    const mapValueByLenght: any = groupBy(mbpnValue, "length");
    forEach(mapValueByLenght, (hangerGroup: any, hangerKey: string) => {
      const elementByCeiling = filter(hangerGroup, {
        ceilingId: ceiling.Id,
      });
      if (elementByCeiling.length > 0) {
        connectFixingPlate.push({
          productName: duplicateKeyValue,
          dimension: hangerKey,
          ceilingId: ceiling.Id,
          value: parseFloat(elementByCeiling.length.toString()),
        });
      }
    });
  });
};

const summaryFixingPlateModelelement = (
  connectFixingPlate: fixingPlate[],
  startingRow: number,
  returnObject: any,
  ceilingLevels: any
) => {
  const groupByName = groupBy(connectFixingPlate, "productName");

  forEach(groupByName, (nameArray, nameKey) => {
    const groupByDimension = groupBy(nameArray, "dimension");

    forEach(groupByDimension, (dimensionArray, dimensionKey) => {
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 1,
        value: " ",
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 2,
        value: nameKey,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "left",
          wrapText: true,
        },
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 3,
        value: dimensionKey,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { right: true, bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
      });

      let totalValue = 0;

      forEach(ceilingLevels, (ceiling, index) => {
        const elementByCeiling = find(dimensionArray, {
          ceilingId: ceiling.Id,
        });

        if (elementByCeiling) {
          totalValue =
            totalValue + parseFloat(elementByCeiling.value.toString());
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: parseFloat(elementByCeiling.value.toString()),
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          });
        } else {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + index,
            value: "",
            border: { bottom: true },
          });
        }
      });

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 4 + ceilingLevels.length,
        value: totalValue,
        border: { bottom: true, left: true },
      });
      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 5 + ceilingLevels.length,
        value: "pcs",
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
      });
      startingRow = startingRow + 1;
    });
  });

  return startingRow;
};

const summaryHangerDataModelelement = (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  startingRow: number,
  returnObject: finalResponse[],
  duplicateKey: boolean,
  duplicateKeyValue: string
) => {
  forEach(eleData, (mbpnValue, key) => {
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 1,
      value: " ",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 2,
      value: duplicateKey ? duplicateKeyValue : key,
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 3,
      value: "",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { right: true, bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    });

    forEach(ceilingLevels, (ceiling, index) => {
      const elementByCeiling = filter(mbpnValue, {
        ceilingId: ceiling.Id,
      });
      if (elementByCeiling.length > 0) {
        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4 + index,
          value: parseFloat(elementByCeiling.length.toString()),
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        });
      } else {
        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4 + index,
          value: "",
          border: { bottom: true },
        });
      }
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 4 + ceilingLevels.length,
      value: parseFloat(mbpnValue.length.toString()),
      border: { bottom: true, left: true },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 5 + ceilingLevels.length,
      value: "pcs",
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    });
    startingRow = startingRow + 1;
    // forEach(mapValueByLenght, (lengthValue, lenKey) => {

    // });
  });

  return startingRow;
};

const summaryBracketDataModelelement = (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  startingRow: number,
  returnObject: finalResponse[],
  unit: string
) => {
  forEach(eleData, (mbpnValue, key) => {
    let summaryTotalRow = 0;

    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 1,
      value: " ",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 2,
      value: key,
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 3,
      value: "",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { right: true, bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    });

    forEach(ceilingLevels, (ceiling, index) => {
      const elementByCeiling = filter(mbpnValue, {
        ceilingId: ceiling.Id,
      });

      if (elementByCeiling.length > 0) {
        summaryTotalRow = summaryTotalRow + elementByCeiling.length;
        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4 + index,
          value: elementByCeiling.length,
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        });
      } else {
        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4 + index,
          value: "",
          border: { bottom: true },
        });
      }
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 4 + ceilingLevels.length,
      value: summaryTotalRow,
      border: { bottom: true, left: true },
    });
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 5 + ceilingLevels.length,
      value: unit,
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
    });
    startingRow = startingRow + 1;
    // forEach(mapValueByLenght, (lengthValue, lenKey) => {

    // });
  });

  return startingRow;
};

const ceilingDataHeader = async (
  ceilingFromLevel: CeilingData[],
  startingRow: number,
  ceilingId: any,
  roomName: string
): Promise<{ value: finalResponse[]; startingRow: number }> => {
  let returnObject: finalResponse[] = [];

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 1,
    value: roomName !== "" ? roomName : parseFloat(ceilingId),
    merge: true,
    mergeColumn: ceilingFromLevel.length + 5,
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
    font: {
      name: "Arial",
      size: 12,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
  });

  startingRow = startingRow + 1;

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 1,
    value: "Item number",
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 2,
    value: "Product name",
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 3,
    value: "Dim.",
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true, right: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 4,
    value: "Qtty.",
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  for (let i = 0; i < ceilingFromLevel.length - 1; i++) {
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 5 + i,
      value: "",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "FFE000" },
      },
    });
  }

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 4 + ceilingFromLevel.length,
    value: "Total",
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 5 + ceilingFromLevel.length,
    value: "Units",
    font: {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "FFE000" },
    },
  });

  return { value: returnObject, startingRow };
};

const ceilingDataBody = async (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  startingRow: number,
  units: string
): Promise<{ startingRow: number; value: finalResponse[] }> => {
  let returnObject: finalResponse[] = [];

  if (eleData) {
    forEach(eleData, (eleValue: elementProperties[], key: string) => {
      // marked header orw
      let headerRow = startingRow;
      let headerColumn = 4 + ceilingLevels.length;

      const mapValueByLenghtOriginal = groupBy(eleValue, "length");
      const orderMapValueByLength = orderBy(
        mapValueByLenghtOriginal,
        [
          function (o) {
            return o[0].length;
          },
        ],
        ["desc"]
      );
      // const mapValueByLenght = keyBy(orderMapValueByLength, '[0].length')

      let ceilColumnCount = 0;
      let totalHeaderCount = 0;
      /// decide if article is having segments else print in single line
      if (orderMapValueByLength.length === 1) {
        const lenKey = orderMapValueByLength[0][0].length;
        const totalValue = round(
          (parseFloat(lenKey) *
            parseFloat(orderMapValueByLength[0].length.toString())) /
            1000,
          0
        );

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 1,
          value: "",
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 2,
          value: key,
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 3,
          value: "",
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true, right: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4,
          value: parseFloat(orderMapValueByLength[0].length.toString()),
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        for (let i = 0; i < ceilingLevels.length - 1; i++) {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 5 + i,
            value: "",
            font: {
              name: "Arial",
              size: 10,
              bold: false,
              color: { argb: "000000" },
            },
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            },
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "000000" },
              fgColor: { argb: "D6D6D6" },
            },
          });
        }

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4 + ceilingLevels.length,
          value: parseFloat(orderMapValueByLength[0].length.toString()),
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 5 + ceilingLevels.length,
          value: units,
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });
      } else {
        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 1,
          value: "",
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 2,
          value: key,
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 3,
          value: "",
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true, right: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 4,
          value: "",
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        for (let i = 0; i < ceilingLevels.length - 1; i++) {
          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 5 + i,
            value: "",
            font: {
              name: "Arial",
              size: 10,
              bold: false,
              color: { argb: "000000" },
            },
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            },
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "000000" },
              fgColor: { argb: "D6D6D6" },
            },
          });
        }

        // catch header row
        headerRow = startingRow;
        // catch header total column
        headerColumn = 4 + ceilingLevels.length;
        // catch header colmn for qutty
        const quttyHeaderColumn = 4;
        let quttyTotalHeader = 0;

        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 5 + ceilingLevels.length,
          value: units,
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        forEach(orderMapValueByLength, (lengthValue, lenKeyIndex) => {
          startingRow = startingRow + 1;

          const lenKey = lengthValue[0].length;
          const totalValue = round(
            (parseFloat(lenKey) * parseFloat(lengthValue.length.toString())) /
              1000,
            0
          );

          quttyTotalHeader = quttyTotalHeader + lengthValue.length;

          if (ceilColumnCount === 0) {
            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 1,
              value: "",
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "left",
                wrapText: true,
              },
            });

            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 2,
              value: "Full length",
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "left",
                wrapText: true,
              },
            });

            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 3,
              value: parseFloat(lenKey),
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true, right: true },
              alignment: {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              },
            });

            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 4,
              value: parseFloat(lengthValue.length.toString()),
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              },
            });
          } else {
            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 1,
              value: "",
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "left",
                wrapText: true,
              },
            });

            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 2,
              value: `Segment ${ceilColumnCount}`,
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "left",
                wrapText: true,
              },
            });

            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 3,
              value: parseFloat(lenKey),
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true, right: true },
              alignment: {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              },
            });

            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 4,
              value: parseFloat(lengthValue.length.toString()),
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              },
            });
          }

          for (let i = 0; i < ceilingLevels.length - 1; i++) {
            returnObject.push({
              blankRow: false,
              row: startingRow,
              column: 5 + i,
              value: "",
              font: {
                name: "Arial",
                size: 10,
                bold: false,
                color: { argb: "000000" },
              },
              border: { bottom: true },
              alignment: {
                vertical: "middle",
                horizontal: "left",
                wrapText: true,
              },
            });
          }

          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 4 + ceilingLevels.length,
            value: totalValue,
            font: {
              name: "Arial",
              size: 10,
              bold: false,
              color: { argb: "000000" },
            },
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          });

          returnObject.push({
            blankRow: false,
            row: startingRow,
            column: 5 + ceilingLevels.length,
            value: units,
            font: {
              name: "Arial",
              size: 10,
              bold: false,
              color: { argb: "000000" },
            },
            border: { bottom: true },
            alignment: {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            },
          });

          ceilColumnCount = ceilColumnCount + 1;
          totalHeaderCount = totalHeaderCount + totalValue;
        });

        // qutty header column
        returnObject.push({
          blankRow: false,
          row: headerRow,
          column: quttyHeaderColumn,
          value: parseFloat(quttyTotalHeader.toString()),
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });

        //Count total
        returnObject.push({
          blankRow: false,
          row: headerRow,
          column: headerColumn,
          value: totalHeaderCount,
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });
      }

      startingRow = startingRow + 1;
    });
  }
  return { startingRow, value: returnObject };
};

const ceilingHangerBody = async (
  eleData: Dictionary<elementProperties[]>,
  ceilingLevels: CeilingData[],
  startingRow: number,
  units: string,
  hangerDuplicate: boolean,
  hangerDuplicateValue: string = ""
): Promise<{ startingRow: number; value: finalResponse[] }> => {
  let returnObject: finalResponse[] = [];

  let hangerValueGroup = "120-200";

  forEach(eleData, (eleValue: elementProperties[], key: string) => {
    const mapValueByLenght = groupBy(eleValue, "length");

    forEach(mapValueByLenght, (lengthValue, lenKey) => {
      switch (parseFloat(lenKey)) {
        case 120:
          hangerValueGroup = "120-200";
          break;
        case 201:
          hangerValueGroup = "201-340";
          break;
        case 341:
          hangerValueGroup = "341-600";
          break;
        case 601:
          hangerValueGroup = "601-1000";
          break;
        case 1001:
          hangerValueGroup = "1001-1400";
          break;
        case 1401:
          hangerValueGroup = "1401-1800";
          break;
        case 1801:
          hangerValueGroup = "1801-2000";
          break;
        default:
          hangerValueGroup = "120-200";
      }

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 1,
        value: "",
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "left",
          wrapText: true,
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          bgColor: { argb: "000000" },
          fgColor: { argb: "D6D6D6" },
        },
      });

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 2,
        value: hangerDuplicate ? hangerDuplicateValue : key,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "left",
          wrapText: true,
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          bgColor: { argb: "000000" },
          fgColor: { argb: "D6D6D6" },
        },
      });

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 3,
        value:
          hangerDuplicateValue === "Connect Hanger clip"
            ? ""
            : hangerValueGroup,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true, right: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          bgColor: { argb: "000000" },
          fgColor: { argb: "D6D6D6" },
        },
      });

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 4,
        value: round(parseFloat(lengthValue.length.toString()), 0),
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          bgColor: { argb: "000000" },
          fgColor: { argb: "D6D6D6" },
        },
      });

      for (let i = 0; i < ceilingLevels.length - 1; i++) {
        returnObject.push({
          blankRow: false,
          row: startingRow,
          column: 5 + i,
          value: "",
          font: {
            name: "Arial",
            size: 10,
            bold: false,
            color: { argb: "000000" },
          },
          border: { bottom: true },
          alignment: {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: "000000" },
            fgColor: { argb: "D6D6D6" },
          },
        });
      }

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 4 + ceilingLevels.length,
        value: round(parseFloat(lengthValue.length.toString()), 0),
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          bgColor: { argb: "000000" },
          fgColor: { argb: "D6D6D6" },
        },
      });

      returnObject.push({
        blankRow: false,
        row: startingRow,
        column: 5 + ceilingLevels.length,
        value: units,
        font: {
          name: "Arial",
          size: 10,
          bold: false,
          color: { argb: "000000" },
        },
        border: { bottom: true },
        alignment: {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          bgColor: { argb: "000000" },
          fgColor: { argb: "D6D6D6" },
        },
      });
      startingRow = startingRow + 1;
    });

    //Count total
  });

  return { startingRow, value: returnObject };
};

const ceilingPerimeterBody = async (
  ceilingLevels: CeilingData[],
  startingRow: number,
  units: string,
  key: string,
  perimeter: any,
  multiplyBy: number
): Promise<{ startingRow: number; value: finalResponse[] }> => {
  let returnObject: finalResponse[] = [];

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 1,
    value: "",
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 2,
    value: key,
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 3,
    value: "",
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true, right: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  let typeCorrectedPerimeter = perimeter;
  if (typeof perimeter === "string") {
    typeCorrectedPerimeter = parseFloat(perimeter);
  }

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 4,
    value:
      key === "Connect Support clip Dg20"
        ? round((typeCorrectedPerimeter * multiplyBy) / 1000, 0)
        : round(typeCorrectedPerimeter * multiplyBy, 0),
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  for (let i = 0; i < ceilingLevels.length - 1; i++) {
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 5 + i,
      value: "",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "D6D6D6" },
      },
    });
  }

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 4 + ceilingLevels.length,
    value:
      key === "Connect Support clip Dg20"
        ? round((typeCorrectedPerimeter * multiplyBy) / 1000, 0)
        : round(typeCorrectedPerimeter * multiplyBy, 0),
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 5 + ceilingLevels.length,
    value: units,
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  startingRow = startingRow + 1;

  return { startingRow, value: returnObject };
};

const spaceBareWinchBody = async (
  ceilingLevels: CeilingData[],
  startingRow: number,
  units: string,
  key: string,
  perimeter: any,
  multiplyBy: number
): Promise<{ startingRow: number; value: finalResponse[] }> => {
  let returnObject: finalResponse[] = [];

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 1,
    value: "",
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 2,
    value: key,
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 3,
    value: "",
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true, right: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  let typeCorrectedPerimeter = perimeter;
  if (typeof perimeter === "string") {
    typeCorrectedPerimeter = parseFloat(perimeter);
  }

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 4,
    value: round(typeCorrectedPerimeter * multiplyBy, 0),
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  for (let i = 0; i < ceilingLevels.length - 1; i++) {
    returnObject.push({
      blankRow: false,
      row: startingRow,
      column: 5 + i,
      value: "",
      font: {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      },
      border: { bottom: true },
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "D6D6D6" },
      },
    });
  }

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 4 + ceilingLevels.length,
    value: round(typeCorrectedPerimeter * multiplyBy, 0),
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  returnObject.push({
    blankRow: false,
    row: startingRow,
    column: 5 + ceilingLevels.length,
    value: units,
    font: {
      name: "Arial",
      size: 10,
      bold: false,
      color: { argb: "000000" },
    },
    border: { bottom: true },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: "D6D6D6" },
    },
  });

  startingRow = startingRow + 1;

  return { startingRow, value: returnObject };
};
