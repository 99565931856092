import React, { useEffect, useState } from "react";
import { Navigate, redirect, useLoaderData, useNavigate } from "react-router";
import { Button } from "semantic-ui-react";
import { GetTokenExpiryInSecs } from "../Components/CommonComponents/Session/Authentication";
import { MyConfig } from "../Helper";
import { api } from "../RevitJS/API";
import "../Components/Store/LandingScreen.scss";

type Langs = "French" | "English";

const LandingScreen = () => {
  const revitLanguage = useLoaderData();
  const [show, setShow] = useState(true);

  const onButtonClick = async () => {
    setShow((s) => !s);
    const config = await MyConfig("Landing Screen");
    window.location.href =
      config.REACT_APP_AUTHENTICATIONURL + `` + config.REACT_APP_LOGIN_REDIRECT;
  };

  const tokenExpiry = GetTokenExpiryInSecs();

  const lang = revitLanguage === "French" ? "French" : "English";

  if (!tokenExpiry.isExpire) {
    return <Navigate to="/dock" replace={true} />;
  }

  return (
    <div
      style={{
        height: "calc(100vh - 10px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: show ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <img src="/saint-gobain-logo-new.png" alt="" width={200} />
          <div style={{ textAlign: "center" }}>
            <Button
              as="a"
              //href={BimtoolboxLink}
              download
              style={{
                background: "#134293",
                color: "white",
                width: 194,
                borderRadius: 2,
                marginRight: 0,
                padding: 5,
                cursor: "default",
              }}
              content="BIM TOOLBOX"
            />
          </div>
        </div>
        <Button
          onClick={() => {
            onButtonClick();
          }}
          primary
          style={{
            // background: "#009bdc",
            width: 194,
            borderRadius: 7,
            margin: "0 auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
          //  content="Click Here to Login"
          content={wording.header[lang]}
        />
      </div>
    </div>
  );
};

const wording = {
  header: {
    English: "Click here to login",
    French: "Cliquez ici pour vous connecter",
  },
};

type WordingType = {
  header: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};
export default LandingScreen;
