import React from "react";
import { Grid } from "semantic-ui-react";
import { FormPanel } from "./FormPanel";

export const FormPanels = (props: any) => {
  let list = [];
  if (props.route === "PROJECT_INFO_FORM") {
    list = props.data;
  } else if (props.route === "SUPPLIMENTARY_FORM") {
    list = props.data[props.count];
  }

  return list.map((item: any, index: any) => {
    return (
      <Grid.Column key={index}>
        <FormPanel {...props} {...item} />
      </Grid.Column>
    );
  });
};
