import { chunk, map, find } from "lodash";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState, useContext } from "react";
import { Dropdown, Table, Popup } from "semantic-ui-react";
import {
  IQuantityContextType,
  QuantityContext,
} from "../../context/quantityContext";
import { IRationTable } from "./RatioTable";

type WordingType = {
  walls: LanguageType;
  ceilings: LanguageType;
  errorMessage: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

type Langs = "French" | "English";

export const RatioTableRow = (props: any) => {
  const [lang, setLang] = useState<Langs>("English");
  let {
    default_ratio,
    isInput,
    register,
    activePage,
    ratioListArray,
    onChangeArticle,
  } = props;
  const [pageDefault, setPageDefault] = useState<any>([]);
  const { elementType } = useContext(QuantityContext) as IQuantityContextType;

  const wording: WordingType = {
    walls: {
      French:
        "Le quantitatif ne peut pas être généré car aucun mur de la maquette est calepiné",
      English:
        "The quantity takeoff cannot be generated because no wall is layout in the model",
    },
    ceilings: {
      French:
        "Le quantitatif ne peut pas être généré car aucun plafond de la maquette est calepiné",
      English:
        "The quantity takeoff cannot be generated because no ceiling is layout in the model",
    },
    errorMessage: {
      French: "La valeur du ratio ne peut pas avoir plus de 3 décimales",
      English: "The value of ratio can't have more than 3 decimals",
    },
  };

  useEffect(() => {
    let language: any = localStorage.getItem("savedLanguage");
    const changeLanguage = async () => {
      if (language !== lang) {
        setLang(language);
      }
    };
    changeLanguage();
  }, []);

  useEffect(() => {
    let pageDefault: IRationTable[] = [];
    setPageDefault(pageDefault);
    if (ratioListArray) {
      const sortedDefaultRation = sortBy(ratioListArray, [
        "order",
        "nameArticle",
      ]);
      const chunkPageDefaultRatio = chunk(sortedDefaultRation, 10);
      if (chunkPageDefaultRatio.length > 0) {
        if (activePage) {
          pageDefault = chunkPageDefaultRatio[activePage - 1];
          setPageDefault(pageDefault);
        }
      }
    }
  }, [activePage, ratioListArray]);

  // To show a message when table is empty
  // if (elementType === "wall") {
  //   if (pageDefault.length === 0) {
  //     return (
  //       <Table.Body>
  //         <Table.Row>
  //           <Table.Cell
  //             colSpan={5}
  //             style={{ textAlign: "center", height: "60vh" }}
  //           >
  //             {wording.walls[lang]}
  //           </Table.Cell>
  //         </Table.Row>
  //       </Table.Body>
  //     );
  //   }
  // }
  // if (elementType === "ceiling") {
  //   if (pageDefault.length === 0) {
  //     return (
  //       <Table.Body>
  //         <Table.Row>
  //           <Table.Cell
  //             colSpan={5}
  //             style={{ textAlign: "center", height: "60vh" }}
  //           >
  //             {wording.ceilings[lang]}
  //           </Table.Cell>
  //         </Table.Row>
  //       </Table.Body>
  //     );
  //   }
  // }
  const ParseFloat = (str: any, val: any) => {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  };

  return (
    <Table.Body>
      {pageDefault.map((defRation: any, index: any) => {
        let ratioValue = defRation.ratioDefault ? defRation.ratioDefault : 0;
        let rationCustom = defRation.rationCustom ? defRation.rationCustom : 0;

        // if (defRation.articleList) {
        //   const selectedObj = find(defRation.articleList, { selected: true });
        //   if (selectedObj) {
        //     defaultValue = selectedObj.articleName;
        //     ratioValue = selectedObj.ratio;
        //     rationCustom = selectedObj.rationCustom;
        //   } else {
        //
        //   }
        // }

        if (defRation.defaultValue) {
          const inputKey =
            defRation.solutionId +
            defRation.defaultValue
              .split(" ")
              .join("")
              .replace(/[^a-zA-Z0-9]/g, "");

          return (
            <Table.Row key={index}>
              <Table.Cell className="width15">
                {defRation.typeArticle}
              </Table.Cell>
              <Table.Cell className="width30">
                {defRation.placoSolution}
              </Table.Cell>
              <Table.Cell className="width20">
                {defRation.articleList ? (
                  <Popup content={defRation.defaultValue}
                    trigger={
                      <Dropdown
                        placeholder="Select"
                        fluid
                        selection
                        options={defRation.articleList}
                        value={defRation.defaultValue}
                        onChange={(e: any) => {
                          onChangeArticle(
                            defRation.solutionId,
                            defRation.articleId,
                            e.target.innerText,
                            defRation.typeArticle
                          );
                        }}
                      />
                    }
                  />
                ) : (
                  defRation.nameArticle
                )}
              </Table.Cell>
              <Table.Cell className="width15" textAlign="center">
                {ratioValue === 0 ? "NaN" : ratioValue}
              </Table.Cell>
              <Table.Cell
                className={isInput ? "width20" : "ratioDeactive width20"}
                textAlign="center"
              >
                {isInput ? (
                  <input
                    {...register(inputKey, {
                      value:
                        rationCustom === 0
                          ? ratioValue
                          : ParseFloat(rationCustom, 3),
                      step: ".001",
                    })}
                    type="number"
                    className="width60 ratio-input"
                    style={{ textAlign: "center" }}
                    key={inputKey}
                    required
                    step=".001"
                    onInput={(e: any) => {
                      var t = e.target.value;
                      e.target.value =
                        t.indexOf(".") >= 0
                          ? t.substr(0, t.indexOf(".")) +
                            t.substr(t.indexOf("."), 4)
                          : t;
                    }}
                  />
                ) : rationCustom === 0 ? (
                  ratioValue
                ) : (
                  ParseFloat(rationCustom, 3)
                )}
              </Table.Cell>
              <Table.Cell className="width20">{defRation.unit}</Table.Cell>
            </Table.Row>
          );
        } else {
          return <></>;
        }
      })}
    </Table.Body>
  );
};
