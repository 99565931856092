import React, { useEffect, useState } from "react";
import FunctionalityHeader from "../../../Common/components/FunctionalityHeader";
import { Button, CircularProgress, Box } from "@material-ui/core";
import { DrawStore } from "../../store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { loadSystems } from "../../store/updateSystems/effects";
import { CSVLink } from "react-csv";
import DataTable from "../DataTable";
import { api } from "../../../../../RevitJS/API";
import AlertDialog from "../../../Common/components/AlertDialog";
import { WithTranslation, withTranslation } from "react-i18next";

import "./index.scss";

export const UpdateSystemContainer: React.FC<UpdateContainerProps> = ({
  loadSystems,
  currentSystemsToUpdate,
  systems,
  loadingSystems,
  config,
  t,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(true);

  useEffect(() => {
    if (config && Object.keys(config).length > 0) {
      loadSystems(t, config);
    }
  }, [config]);
  // useEffect(() => { api.windowsHandler.showDevTools() }, [])

  const handleUpdateSystemsContent = async () => {
    setOpenDialog(true);
    currentSystemsToUpdate = currentSystemsToUpdate.filter((sys) =>
      systems.some((s) => s.systemName === sys.solution)
    );
    await api.queries
      .createElementTypeWithCustomParametersForWeberIndonesia(
        currentSystemsToUpdate,
        config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO
      )
      .then(() => setProgress(false));

    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Update",
      eventCategory: "Module Execution",
      eventLabel: "Data Update",
      module: "WEBINDONPRESCRI",
    });
  };

  const csvData = (systems: any) => {
    const systemData: any[] = [];
    systemData.push([
      "System name",
      "Property name",
      "Value before update",
      "Value after update",
    ]);
    systems.forEach((element: any) => {
      element.systemProperties.forEach((prop: any) => {
        systemData.push([
          element.systemName,
          prop.propertyLabel,
          prop.oldValue,
          prop.newValue,
        ]);
      });
    });

    return systemData;
  };

  const handelCloseDialog = () => {
    api.windowsHandler.closeWindow();
  };
  return (
    <div className="PropertyGlobalContainer">
      <FunctionalityHeader
        name={t("UPDATE_SYSTEMS")}
        icon={config.REACT_APP_SERVERURL + "/WeberIndonesia_logo.png"}
      />
      <div className="UpdateSystemContainer">
        <div className="updateSystems">
          {loadingSystems ? (
            <div className="PropertiesCircularProgressContainer">
              <CircularProgress className="PropertiesCircularProgress" />
            </div>
          ) : systems && systems.length > 0 ? (
            <>
              <Box fontStyle="italic" m={1}>
                {t("UPDATE_INFORMATION")}
              </Box>
              <DataTable Systems={systems} />
            </>
          ) : (
            <Box fontStyle="italic" m={1}>
              {t("SYSTEM_UPDATED_MESSAGE")}
            </Box>
          )}
        </div>
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton dark-grey-color"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => handelCloseDialog()}
        >
          {t("CLOSE")}
        </Button>
        {systems && systems.length > 0 && (
          <div>
            <CSVLink
              className="MuiButtonBase-root button MuiButton-contained MuiButton-containedPrimary CSVLinkButton loadActionButton yellow-color"
              data={csvData(systems)}
              separator={";"}
              filename={"Mortar Utama Indonesia Systems updated data.csv"}
            >
              {t("EXPORT_TO_CSV")}
            </CSVLink>
            <Button
              className="loadActionButton yellow-color"
              style={{ textTransform: "none" }}
              variant="contained"
              onClick={() => handleUpdateSystemsContent()}
              disabled={!(systems && systems.length > 0)}
            >
              {t("UPDATE_SYSTEMS")}
            </Button>
          </div>
        )}
      </div>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={handelCloseDialog}
        progress={progress}
        title={"Information"}
        content={t("UPDATE_INFORMATION")}
      />
    </div>
  );
};

function mapStateToProps(state: DrawStore) {
  return {
    systems: state.updateSystems.systems,
    currentSystemsToUpdate: state.updateSystems.currentSystemsToUpdate,
    loadingSystems: state.updateSystems.loadingSystems,
    config: state.config,
  };
}

let mapDispatchToProps = {
  loadSystems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type UpdateContainerProps = PropsFromRedux & WithTranslation;

export default connector(
  withTranslation(["weberIndonesia"])(UpdateSystemContainer)
);
