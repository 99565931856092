import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { Properties } from "./Properties";
import { LoadSystemContainer } from "./LoadSystemContainer";
import { Updates } from "./UpdateSystems";
import { UserManual } from "./UserManual";
import { Help } from "./Help";

export const pluginId = "3c7bd045-e64b-4ae8-a98d-8c2d66c2ace2";

export const WeberIndonesiaTest: Plugin = {
  TechnicalName: "WEBINDONPRESCRIBIM",
  Name: "",
  Icon: "/Weber-Indonesia.png",
  ShortDesc: {
    English: "BIM Plugin",
  },
  Id: pluginId,
  Color: "#000000",
  Functionalities: [
    Properties,
    LoadSystemContainer,
    Updates,
    UserManual,
    // Help
  ],
};
