import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { Root } from "./components/root";

export const functionalityId = "6b7a209e-3aad-4c39-b407-9d9ad1aa7f45";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Updates",
    module: "WEBINDONPRESCRI",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <Root />
    </ParentRoot>
  );
};

export const RootUpdateSystemsComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <Root />
    </ParentRoot>
  );
};

export const Updates: Functionality = {
  Name: {
    English: "Updates",
    Indonesian: "Terbaru",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    English: "Update systems data",
    Indonesian: "Perbarui Sistem Data",
  },
  Id: functionalityId,
  Icon: "/weberIcons/UpdateBW.png",
  RootComponent,
  ParentId: "0",
};
