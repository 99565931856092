import { BufferActionType, BufferType, SET_BUFFER_DATE_DETAILS, SET_BUFFER_DETAILS, SET_CONTEXT_BUFFER, SET_EDIT_BUFFER, SET_MASTER_FRAME_BUFFER, SET_OPTIONS_BUFFER, SET_PARAMETER_BUFFER, SET_SELECTION_BUFFER } from "../Types";

const intialState: BufferType = {
    masterFrame: {
        status: false,
        optional: true,
        key: "masterFrame",
        data: {
            Ids: [],
            Levels: [],
            Type: ""
        }
    },
    selections: {
        status: false,
        optional: false,
        key: "selections",
        data: [{
            Ids: [""],
            Levels: [""],
            Type: ""
        }],
    },
    parameteres: {
        status: false,
        optional: false,
        key: "parameters",
        data: {
            grid: 600,
            minDist: 5,
            maxLength: 420,
            minLength: 80,
            epais: 0,
            rong: 0,
            large: 0
        },
    },
    context: {
        status: false,
        optional: false,
        key: "context",
        data: {
            top: { checked: "", value: 0 },
            left: { checked: "", value: 0 },
            bottom: { checked: "", value: 0 },
            right: { checked: "", value: 0 }
        },
    },
    options: {
        status: false,
        optional: false,
        key: "options",
        data: {
            optima: false,
            std: true,
            alc: true,
            acc: true,
            liernes: false,
            platineBas: false,
            platineHaut: true,
            ac: true,
        },
    },
    name: "",
    id: "",
    date: "",
    revitView: "",
};


const BufferReducer = (state: BufferType = intialState, action: BufferActionType): BufferType => {
    const newState = { ...state }
    switch (action.type) {
        case SET_MASTER_FRAME_BUFFER:
            newState.masterFrame.data = action.payload;
            newState.masterFrame.status = true;
            return newState;
        case SET_SELECTION_BUFFER:
            newState.selections.data = action.payload;
            newState.selections.status = true;
            return newState;
        case SET_PARAMETER_BUFFER:
            newState.parameteres.data = action.payload;
            newState.parameteres.status = true;
            return newState;
        case SET_CONTEXT_BUFFER:
            newState.context.data = action.payload;
            newState.context.status = true;
            return newState;
        case SET_OPTIONS_BUFFER:
            newState.options.data = action.payload;
            newState.options.status = true;
            return newState;
        case SET_BUFFER_DETAILS:
            newState.name = action.payload.name;
            newState.id = action.payload.id;
            newState.revitView = action.payload.revitView;
            newState.masterFrame = intialState.masterFrame;
            newState.selections = intialState.selections;
            newState.parameteres = intialState.parameteres;
            newState.context = intialState.context;
            newState.options = intialState.options;
            newState.date = intialState.date;
            newState.time = intialState.time;
            return newState;
        case SET_BUFFER_DATE_DETAILS:
            newState.date = action.payload.date;
            newState.time = action.payload.time;
            return newState;
        case SET_EDIT_BUFFER:
            newState.masterFrame = action.payload.masterFrame;
            newState.selections = action.payload.selections;
            newState.parameteres = action.payload.parameteres;
            newState.context = action.payload.context;
            newState.options = action.payload.options;
            newState.name = action.payload.name;
            newState.id = action.payload.id;
            newState.date = action.payload.date;
            newState.revitView = action.payload.revitView;
            return newState;
        default:
            return state;
    }
}

export default BufferReducer;