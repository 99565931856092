import React, { useEffect } from "react";
import MiterJoint from "./MiterJoint";
import { api } from "../../../../RevitJS/API";

const App = (props: {route: string, setRoute: any}) => {

  // useEffect(() => {
  //   api.windowsHandler.showDevTools();
  // }, [])

  switch(props.route)
  {
    case "ROOT":
      return <MiterJoint setRoute={props.setRoute}/>   
    default:
      return <MiterJoint setRoute={props.setRoute}/>
  }
}

export default App;
