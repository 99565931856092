import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../Reducers";
import { AxiosInstance } from "axios";
import {
  FETCH_PLACO_DATA,
  SET_SELECTION_TREE,
  InitGroupSelectorAction,
  INIT_GROUP_SELECTOR,
  SelectTypeAction,
  SELECT_TYPE,
  SelectLevelAction,
  SELECT_LEVEL,
  InitMappingTableAction,
  INIT_MAPPING_TABLE,
  SelectSystemAction,
  SELECT_SYSTEM,
  InitProductSelectorAction,
  INIT_PRODUCT_SELECTOR,
  SetSelectionBufferAction,
  SET_SELECTION_BUFFER,
  RESET_SELECTION_BUFFER,
  ResetSelectionBufferAction,
  SetActiveRowIndexAction,
  SET_ACTIVE_MAPPING_ROW_INDEX,
  SearchAction,
  SEARCH,
  APPLY_FILTERS,
  ApplyFiltersAction,
  ResetFiltersAction,
  RESET_FILTERS,
  FetchDataAction,
  INIT_LAYOUT_PROGRESS,
  InitLayoutProgressAction,
  UpdateLayoutProgressAction,
  UPDATE_LAYOUT_PROGRESS,
  StartLayoutAction,
  START_LAYOUT,
  EndLayoutAction,
  END_LAYOUT,
  FETCH_RIGIPS_DATA,
  FetchRigipsDataAction,
  SetFireConstrainAction,
  SET_FIRE_CONSTRAIN,
  SetPublicConstrainAction,
  SET_PUBLIC_CONSTRAIN,
  InitLanguageAction,
  INIT_LANGUAGE,
  SelectEcophonSystemAction,
  SELECT_ECOPHON_SYSTEM,
  SelectRange,
  SELECT_RANGE,
  SelectConfiguration,
  SelectProduct,
  SELECT_CONFIGURATION,
  SELECT_PRODUCT,
  TOGGLE_CHECK,
  ToggleAllAction,
  ToggleCheckAction,
  TOGGLE_ALL,
  SelectAngle,
  SelectHeightUnderCeiling,
  SelectMyHanger,
  SELECT_ANGLE,
  SELECT_PLENUM_HEIGHT,
  SELECT_MYHANGER,
  FamilyLoadingAction,
  FAMILY_LOADING,
  END_PROGRESS,
  EndProgressAction,
} from "./types";
import { fetchPlacoData, fetchRigipsData } from "../Requests";
import { sortPlacoData } from "../Requests";
import {
  ProductMeta,
  ProductsByType,
  ProductDetailData,
  PIMLayoutAttributesAid,
} from "../../../../RevitJS/Types/BddTypes";
import { ID, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import {
  elementTypes,
  levelData,
  getSelectedItems,
  filterElements2,
  getLayoutAttributesAid,
} from "../../../../RevitJS/Helpers";
import { getSystemsDetailsAndFilters, startInList } from "../Helpers";
import { TypeData, SelectedItem } from "../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../RevitJS/API";
import { wait } from "../../../../RevitJS/Helpers";
import { find, flatten, groupBy, map, round } from "lodash";
import { DropdownItemProps } from "semantic-ui-react";
import { MyConfig } from "../../../../Helper";

export const fetchRemoteData =
  (
    bdd: AxiosInstance
  ): ThunkAction<void, DrawStore, unknown, FetchDataAction> =>
  async (dispatch: any, getState: any) => {
    const { filters } = getState();
    let systems = await fetchPlacoData(bdd);
    let sortedSystems = sortPlacoData(systems) as ProductsByType;

    let IdsByType: { partitions: ID[]; liningWalls: ID[] } = {
      partitions: sortedSystems.partitions.map((system) => system.oid),
      liningWalls: sortedSystems.liningWalls.map((system) => system.oid),
    };

    let partitionsDetails = await getSystemsDetailsAndFilters(
      IdsByType.partitions,
      bdd,
      filters.partitions
    );
    let liningWallsDetails = await getSystemsDetailsAndFilters(
      IdsByType.liningWalls,
      bdd,
      filters.liningWalls
    );

    liningWallsDetails.systemsDetails = liningWallsDetails.systemsDetails.map(
      (sys, index) => {
        return {
          ...sys,
          thumbnailUrl: sortedSystems.liningWalls[index].thumbnailUrl,
        };
      }
    );
    partitionsDetails.systemsDetails = partitionsDetails.systemsDetails.map(
      (sys, index) => {
        return {
          ...sys,
          thumbnailUrl: sortedSystems.partitions[index].thumbnailUrl,
        };
      }
    );

    let layoutAttributesAid = getLayoutAttributesAid(
      partitionsDetails.attributes
    ) as PIMLayoutAttributesAid;

    dispatch({
      type: FETCH_PLACO_DATA,
      liningWallsDetails: liningWallsDetails,
      partitionsDetails,
      layoutAttributesAid,
    });
  };

function compare(a: ProductMeta, b: ProductMeta) {
  if (a.translation < b.translation) {
    return -1;
  }
  if (a.translation > b.translation) {
    return 1;
  }
  return 0;
}

export const albaSystemsList = [
  "1-A.0",
  "1-AH.0",
  "1-A.1",
  "1-AB",
  "1-A.2",
  "1-AH.2",
  "3-",
];

export const rigipsSystemsList = ["1-AR", "1-DL", "1-DLI"];

export const fetchRigipsRemoteData =
  (config: any): ThunkAction<void, DrawStore, unknown, FetchRigipsDataAction> =>
  async (dispatch: any, getState: any) => {
    let systems = await fetchRigipsData(config);
    let products = systems.filter((sys) =>
      startInList(sys.translation, albaSystemsList.concat(rigipsSystemsList))
    );
    products = products.sort((a, b) => compare(a, b));

    dispatch({
      type: FETCH_RIGIPS_DATA,
      products,
    });
  };

export const setSelectionTree = (tree: ElementsTree) => {
  return {
    type: SET_SELECTION_TREE,
    tree,
  };
};

export const initGroupSelector = (
  tree: ElementsTree
): InitGroupSelectorAction => {
  return {
    type: INIT_GROUP_SELECTOR,
    tree,
  };
};

export const selectType =
  (typeName: string): ThunkAction<void, DrawStore, unknown, SelectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const { typesData, selectedTypes } = getState();
    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    let selectedTypeIndex = typesData.findIndex(
      (type: any) => type.Type === typeName
    );

    if (typesData[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedTypes.filter(
        (type: any) => type !== typeName
      );
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedTypes, typeName];
    }

    dispatch({
      type: SELECT_TYPE,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectAllTypes =
  (check: boolean): ThunkAction<void, DrawStore, unknown, SelectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const { typesData, selectedTypes } = getState();

    let selectedTypesCopy: any[] = [];
    let typesDataCopy = typesData.map((type: any) => {
      type.Checked = check;
      if (check) {
        selectedTypesCopy.push(type.Type);
      }
      return type;
    });

    dispatch({
      type: SELECT_TYPE,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectLevel =
  (
    levelName: string
  ): ThunkAction<void, DrawStore, unknown, SelectLevelAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    let selectedLevelIndex = levelsData.findIndex(
      (level: any) => level.Name === levelName
    );

    if (levelsData[selectedLevelIndex].Checked) {
      levelsDataCopy[selectedLevelIndex].Checked = false;
      selectedLevelsCopy = selectedLevels.filter(
        (level: any) => level !== levelName
      );
    } else {
      levelsDataCopy[selectedLevelIndex].Checked = true;
      selectedLevelsCopy = [...selectedLevels, levelName];
    }

    dispatch({
      type: SELECT_LEVEL,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const selectAllLevels =
  (check: boolean): ThunkAction<void, DrawStore, unknown, SelectLevelAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let selectedLevelsCopy: any[] = [];
    let levelsDataCopy = levelsData.map((level: any) => {
      level.Checked = check;
      if (check) {
        selectedLevelsCopy.push(level.Name);
      }
      return level;
    });
    dispatch({
      type: SELECT_LEVEL,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const initMappingTable =
  (): ThunkAction<void, DrawStore, unknown, InitMappingTableAction> =>
  async (dispatch: any, getState: any) => {
    const { selectionTree, selectedLevels, selectedTypes } = getState();
    let selectedItems: SelectedItem[] = getSelectedItems(
      selectionTree,
      selectedLevels,
      selectedTypes
    );

    let Ids = flatten(
      selectedItems.map((s: any) => {
        return s.Ids;
      })
    );

    let rowCeilingsData = await api.queries.getCeilingsData(Ids);

    const selectedItemGroups = groupBy(rowCeilingsData, (item) => {
      return [item["LevelName"], item["CeilingType"], item["Height"]];
    });

    dispatch({
      type: INIT_MAPPING_TABLE,
      mappingRows: Object.keys(selectedItemGroups).map((key, index) => {
        let items = selectedItemGroups[key];
        let ids = items.map((i: any) => i.Id);
        let height: any = items[0]?.Height;
        let level: any = items[0]?.LevelName;
        return {
          Checked: true,
          Index: index,
          Level: level,
          RevitSelection: { RevitType: items[0]?.CeilingType, Ids: ids },
          MappedSystem: null,
          EcophonSystem: "Standard",
          Range: "Edge A T24",
          Configuration: "Edge A T24_600x600",
          Angle: 0,
          Product: "Edge A T24_600x600_Ecophon Focus™ A20 T24 600x600",
          HeightUnderCeiling: round(height * 1000),
          MyHanger: "Connect Adjustable Hanger",
        };
      }),
    });
  };

export const initMappingTableManualSelection =
  (
    selectedItems: SelectedItem[]
  ): ThunkAction<void, DrawStore, unknown, InitMappingTableAction> =>
  async (dispatch: any, getState: any) => {
    let Ids = flatten(
      selectedItems.map((s: any) => {
        return s.Ids;
      })
    );

    let rowCeilingsData = await api.queries.getCeilingsData(Ids);

    const selectedItemGroups = groupBy(rowCeilingsData, (item) => {
      return [item["LevelName"], item["CeilingType"], item["Height"]];
    });

    dispatch({
      type: INIT_MAPPING_TABLE,
      mappingRows: Object.keys(selectedItemGroups).map((key, index) => {
        let items = selectedItemGroups[key];
        let ids = items.map((i: any) => i.Id);
        let height: any = items[0]?.Height;
        let level: any = items[0]?.LevelName;
        return {
          Checked: true,
          Index: index,
          Level: level,
          RevitSelection: { RevitType: items[0]?.CeilingType, Ids: ids },
          MappedSystem: null,
          EcophonSystem: "Standard",
          Range: "Edge A T24",
          Configuration: "Edge A T24_600x600",
          Angle: 0,
          Product: "Edge A T24_600x600_Ecophon Focus™ A20 T24 600x600",
          HeightUnderCeiling: round(height * 1000),
          MyHanger: "Connect Adjustable Hanger",
        };
      }),
      // mappingRows: selectedItems.map((item, index) => {
      //   return {
      //     Checked: true,
      //     Index: index,
      //     Level: "",
      //     RevitSelection: { RevitType: item.Type, Ids: item.Ids },
      //     MappedSystem: null,
      //     EcophonSystem: "Standard",
      //     Range: "Edge A T24",
      //     Configuration: "Edge A T24_600x600",
      //     Angle: 0,
      //     Product: "Edge A T24_600x600_Ecophon Focus™ A20 T24 600x600",
      //     HeightUnderCeiling: 2300,
      //     MyHanger: "Connect Adjustable Hanger",
      //   };
      // }),
    });
  };

export const selectSystem = (
  index: number,
  system: ProductDetailData
): SelectSystemAction => {
  return {
    type: SELECT_SYSTEM,
    index,
    system,
  };
};

export const setFireConstrain = (
  index: number,
  fireConstrain: boolean
): SetFireConstrainAction => {
  return {
    type: SET_FIRE_CONSTRAIN,
    index,
    fireConstrain,
  };
};

export const setPublicConstrain = (
  index: number,
  publicConstrain: boolean
): SetPublicConstrainAction => {
  return {
    type: SET_PUBLIC_CONSTRAIN,
    index,
    publicConstrain,
  };
};

export const initProductSelector =
  (): ThunkAction<void, DrawStore, unknown, InitProductSelectorAction> =>
  async (dispatch: any, getState: any) => {
    const { filterTypeEnabled, placoData } = getState();
    let systems =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;
    dispatch({
      type: INIT_PRODUCT_SELECTOR,
      filteredElements: systems,
      displayedElements: systems.length > 20 ? systems.slice(0, 20) : systems,
    });
  };

export const setSelectionBuffer = (
  system: ProductDetailData
): SetSelectionBufferAction => {
  return {
    type: SET_SELECTION_BUFFER,
    system,
  };
};

export const resetSelectionBuffer = (): ResetSelectionBufferAction => {
  return {
    type: RESET_SELECTION_BUFFER,
  };
};

export const setActiveMappingRowIndex = (
  index: number
): SetActiveRowIndexAction => {
  return {
    type: SET_ACTIVE_MAPPING_ROW_INDEX,
    index,
  };
};

export const search =
  (word: string): ThunkAction<void, DrawStore, unknown, SearchAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterTypeEnabled, placoData } = getState();

    const activeFilters =
      filterTypeEnabled === "partitionsWalls"
        ? filters.partitions
        : filters.liningWalls;
    const activeData =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;

    let filteredElements = filterElements2(activeFilters, activeData);

    filteredElements = filteredElements.filter((el: ProductDetailData) =>
      el.translation.replace("®", "").toUpperCase().includes(word.toUpperCase())
    );

    dispatch({
      type: SEARCH,
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      searchedWord: word,
    });
  };

export const applyFilters =
  (
    filterName: string,
    filterValue: any
  ): ThunkAction<void, DrawStore, unknown, ApplyFiltersAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterTypeEnabled, placoData } = getState();

    const activeFilters =
      filterTypeEnabled === "partitionsWalls"
        ? [...filters.partitions]
        : [...filters.liningWalls];
    const activeData =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;

    const updatedFilters = activeFilters.map((filter) => {
      if (filter.name !== filterName) {
        return filter;
      }
      filter.value = filterValue;
      return filter;
    });

    let filtersCopy = { ...filters };
    if (filterTypeEnabled === "partitionsWalls") {
      filtersCopy.partitions = updatedFilters;
    } else {
      filtersCopy.liningWalls = updatedFilters;
    }

    let filteredElements = filterElements2(updatedFilters, activeData);

    dispatch({
      type: APPLY_FILTERS,
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      filters: filtersCopy,
    });
  };

export const resetFilters =
  (): ThunkAction<void, DrawStore, unknown, ResetFiltersAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterTypeEnabled, placoData, searchedWord } = getState();

    const activeFilters =
      filterTypeEnabled === "partitionsWalls"
        ? [...filters.partitions]
        : [...filters.liningWalls];
    const activeData =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;

    const updatedFilters = activeFilters.map((filter) => {
      return { ...filter, value: null };
    });

    let filtersCopy = { ...filters };
    if (filterTypeEnabled === "partitionsWalls") {
      filtersCopy.partitions = updatedFilters;
    } else {
      filtersCopy.liningWalls = updatedFilters;
    }
    let filteredElements = filterElements2(updatedFilters, activeData);

    dispatch({
      type: RESET_FILTERS,
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      filters: filtersCopy,
    });
  };

export const initLayoutProgress = (): InitLayoutProgressAction => {
  return {
    type: INIT_LAYOUT_PROGRESS,
  };
};

export const updateLayoutProgress = (
  progress: number
): UpdateLayoutProgressAction => {
  return {
    type: UPDATE_LAYOUT_PROGRESS,
    progress,
  };
};

export const startLayout = (): StartLayoutAction => {
  return {
    type: START_LAYOUT,
  };
};

export const familyLoading = (): FamilyLoadingAction => {
  return {
    type: FAMILY_LOADING,
  };
};

export const endLayout = (): EndLayoutAction => {
  return {
    type: END_LAYOUT,
  };
};

export const endProgress = (): EndProgressAction => {
  return {
    type: END_PROGRESS,
  };
};

export const drawLayout =
  (
    layoutData: any,
    ceilingRooms: any,
    eventData: any,
    ceilingsData: any
  ): ThunkAction<void, DrawStore, unknown, any> =>
  async (dispatch: any, getState: any) => {
    dispatch(familyLoading());

    let config = await MyConfig();

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_AngleTrim",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_MainRunner",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_CrossTee",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_Hanger",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_HygieneClip",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_ChannelTrim",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_SpaceBar",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_Connect Wall Bracket 1",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.2",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_Connect Wall Bracket 2",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.2",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_HorizontalBoard",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Ecophon_Connect_Ds_Clip",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.1",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
    );
    try {
      await api.familyEditor.loadFamilies(
        [
          {
            FamilyName: "Ecophon_Threaded_Rog",
            FamilyTypes: ["Connect"],
            Date: "2019_12_03_10_34_32",
            Version: "1.0.0.1",
          },
        ],
        config.REACT_APP_DOWNLOAD_FAMILY_URL_ECOPHON
      );
    } catch (error) {
      console.error(error);
    }

    dispatch(initLayoutProgress());

    let nb_ceilings = layoutData.ceilings.length;
    for (let i = 0; i < nb_ceilings; i++) {
      let ceilingId: string = "";

      let ceiling = layoutData.ceilings[i];
      ceilingId = ceiling.ceilingId;
      let rooms = find(ceilingRooms, { CeilingId: ceilingId });
      let roomNames =
        rooms.Rooms.length > 1
          ? map(rooms.Rooms, "RoomName").join("; ")
          : map(rooms.Rooms, "RoomName")[0];

      if (ceiling.main_runners.length) {
        let main_runners = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.main_runners
        );
      }

      if (ceiling.other_runners_150.length) {
        let medium_runners = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.other_runners_150
        );
      }

      if (ceiling.other_runners_300.length) {
        let medium_runners = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.other_runners_300
        );
      }

      if (ceiling.other_runners_600.length) {
        let medium_runners = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.other_runners_600
        );
      }

      if (ceiling.other_runners_900.length) {
        let medium_runners = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.other_runners_900
        );
      }

      if (ceiling.other_runners_1200.length) {
        let medium_runners = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.other_runners_1200
        );
      }

      if (ceiling.cornieres.length) {
        let cornieres = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.cornieres
        );
      }

      if (ceiling.hangers.length) {
        let hangers = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.hangers
        );
      }

      if (ceiling.hygiene_clips.length) {
        let hygiene_clips = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.hygiene_clips
        );
      }

      if (ceiling.ecarteurs.length) {
        let ecarteurs = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.ecarteurs
        );
      }

      if (ceiling.brackets1.length) {
        let brackets1 = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.brackets1
        );
      }
      if (ceiling.brackets2.length) {
        let brackets2 = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.brackets2
        );
      }
      if (ceiling.boards.length) {
        let boards = await api.familyEditor.placeFamiliesAtPoints(
          ceiling.boards
        );
      }

      const ceilingData = find(ceilingsData, { Id: ceilingId });
      const ceilingParams = ceilingData.params;

      await api.familyEditor.createAndSetParameters(
        "Ceiling",
        "Instance",
        "DATA",
        [
          {
            Id: ceilingId,
            Params: [
              { Name: "Processed", Type: "YesNo", Value: 1 },
              {
                Name: "ProductName",
                Type: "Text",
                Value: ceiling.productName,
              },
              {
                Name: "MaxLength",
                Type: "Text",
                Value: ceiling.ceilingMaxLength,
              },
              {
                Name: "MaxWidth",
                Type: "Text",
                Value: ceiling.ceilingMaxWidth,
              },
              {
                Name: "RoomNames",
                Type: "Text",
                Value: roomNames,
              },
              {
                Name: "EcophonSystem",
                Type: "Text",
                Value: ceilingParams.EcophonSystem,
              },
            ],
          },
        ]
      );
      dispatch(updateLayoutProgress(Math.ceil(((i + 1) * 100) / nb_ceilings)));
    }
    await sendEvent(eventData);
    dispatch(endLayout());

    await wait(1500);
  };

const sendEvent = async (eventData: any) => {
  let projectInfo: any = await api.queries.getProjectInformation();
  let projectName = projectInfo.Name;
  let projectData: any = await api.queries.getProjectData();
  let fileName = projectData.ProjectPath.split("\\").pop();
  let revitVersion = await api.framework.getRevitVersion();

  await api.eventLog.SetEvent({
    data: [
      {
        name: "Name of solutions",
        value: "",
        values: eventData,
      },
      {
        name: "Information",
        value: "",
        values: [
          {
            File_Name: fileName,
            Project_Name: projectName,
            Version: revitVersion,
          },
        ],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Calepinage",
    module: "CLT",
  });
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English", "German", "Italian"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, DrawStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = (await api.queries.getRevitLanguage()) as Language;
      localStorage.setItem("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };

export const selectEcophonSystem =
  (
    index: number,
    id: string
  ): ThunkAction<
    void,
    DrawStore,
    unknown,
    SelectEcophonSystemAction | SelectRange
  > =>
  async (dispatch: any, getState: any) => {
    const { ranges } = getState();
    const rangeOptions: DropdownItemProps[] = Object.keys(ranges)
      .filter((range) => ranges[range].parentSystem === id)
      .map((key) => ({ text: ranges[key].id, value: ranges[key].id }));

    const firstOptionValue: string | undefined =
      typeof rangeOptions[0].value === "string"
        ? rangeOptions[0].value
        : undefined;

    dispatch({
      type: SELECT_ECOPHON_SYSTEM,
      index,
      id,
    });
    if (firstOptionValue) {
      dispatch(selectRange(index, firstOptionValue));
    }
  };

export const selectRange =
  (
    index: number,
    id: string
  ): ThunkAction<void, DrawStore, unknown, SelectRange | SelectConfiguration> =>
  async (dispatch: any, getState: any) => {
    const { configurations } = getState();

    const configurationOptions: DropdownItemProps[] = Object.keys(
      configurations
    )
      .filter((conf: string) => configurations[conf].parentRange === id)
      .map((key) => ({
        text: configurations[key].config,
        value: configurations[key].id,
      }));

    const firstOptionValue: string | undefined =
      typeof configurationOptions[0].value === "string"
        ? configurationOptions[0].value
        : undefined;

    dispatch({
      type: SELECT_RANGE,
      index,
      id,
    });

    if (firstOptionValue) {
      dispatch(selectConfiguration(index, firstOptionValue));
    }
  };

export const selectConfiguration =
  (
    index: number,
    id: string
  ): ThunkAction<
    void,
    DrawStore,
    unknown,
    SelectProduct | SelectConfiguration
  > =>
  async (dispatch: any, getState: any) => {
    const { products } = getState();

    const productOptions: DropdownItemProps[] = Object.keys(products)
      .filter((conf: string) => products[conf].parentConfig === id)
      .map((key) => ({
        text: products[key].product,
        value: products[key].id,
      }));

    const firstOptionValue: string | undefined =
      typeof productOptions[0].value === "string"
        ? productOptions[0].value
        : undefined;

    dispatch({
      type: SELECT_CONFIGURATION,
      index,
      id,
    });

    if (firstOptionValue) {
      dispatch(selectProduct(index, firstOptionValue));
    }
  };

export const selectProduct = (index: number, id: string): SelectProduct => ({
  type: SELECT_PRODUCT,
  index,
  id,
});

export const selectAngle = (index: number, id: number): SelectAngle => ({
  type: SELECT_ANGLE,
  index,
  id,
});

export const selectHeightUnderCeiling = (
  index: number,
  id: number
): SelectHeightUnderCeiling => ({
  type: SELECT_PLENUM_HEIGHT,
  index,
  id,
});

export const selectMyHanger = (index: number, id: string): SelectMyHanger => ({
  type: SELECT_MYHANGER,
  index,
  id,
});

export const toggleCheck = (rowIndex: number): ToggleCheckAction => {
  return {
    type: TOGGLE_CHECK,
    Index: rowIndex,
  };
};
export const toggleAll = (checked: boolean = true): ToggleAllAction => {
  return {
    type: TOGGLE_ALL,
    checked,
  };
};
