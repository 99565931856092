import PropertyRow from '../PropertyRow'
import React from 'react'
import { Property } from '../../Selection/Actions/types'

import './index.scss'

interface DetailsContainer {
    title?: string
    properties?: Property[]
}


export const DetailsProperty: React.FC<DetailsContainer> = ({ title = 'Détails', properties }) => {
    return (
        <div className='PropertyDetailsContainer'>
            <div className='PropertyDetailsTitle'>{title}</div>
            <div className='PropertyDetailsContent'>
                {properties && properties.map(prop =>
                    <PropertyRow key={prop.title} title={prop.title} value={prop.value} onClick={prop.onClick}></PropertyRow>
                )}
            </div>
        </div>
    )
}

export default DetailsProperty
