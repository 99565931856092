import { api } from "./../../../../RevitJS/API/index";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { dbStoreNameReperage } from "../Components/root";

export async function getDocumentName() {
  return await api.queries.getActiveDocumentName();
}

export function getCurrentDate() {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(Date.now());
}

export const getDate = () => {
  let today = new Date();
  let dd: any = today.getDate();
  let mm: any = today.getMonth() + 1; //January is 0!

  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "/" + mm + "/" + yyyy;
};

export async function saveReperage(data: any, projectId: any, config: any) {
  data.time = data.bufferTime;
  data.bufferTime = 0;

  //await bimStorage.setItem(storageKey.REPERAGE, JSON.stringify([]));
  let reperageData = data;

  //await bimStorage.setItem(storageKey.REPERAGE, JSON.stringify(reperageData));

  reperageData.Id = `${projectId}` + "_c61281f0-c270";
  reperageData.Name = "c61281f0-reperage";

  await bimStorage.setModule(
    dbStoreNameReperage,
    projectId,
    reperageData.Name,
    reperageData.Date,
    reperageData.Id,
    JSON.stringify(reperageData)
  );

  // let savedReperageData = await bimStorage.listModule(
  //   dbStoreNameReperage,
  //   projectId
  // );

  // let testdata = await bimStorage.listDetailModule(
  //   dbStoreNameReperage,
  //   //projectId,
  //   reperageData.Id
  // );
}

export async function checkIfReperageExist(
  name: string,
  modelName: string,
  documentId: string,
  config: any
) {
  let dataList = await bimStorage.getItem(storageKey.REPERAGE);
  let reperage = dataList === null ? [] : JSON.parse(dataList);
  let rtn: boolean = false;
  if (reperage && reperage.length > 0) {
    reperage = reperage.filter(
      (a: any) =>
        a.name === name &&
        a.modelName === modelName &&
        a.documentId === documentId
    );
    if (reperage.length > 0) {
      rtn = true;
    } else {
      rtn = false;
    }
  }
  return rtn;
}

export async function deleteReperage(data: any, config: any) {
  let dataList = await bimStorage.getItem(storageKey.REPERAGE);
  let reperage = dataList === null ? [] : JSON.parse(dataList);
  if (reperage && reperage.length > 0) {
    reperage = reperage.filter((a: any) => a.documentId !== data.documentId);
  }
  bimStorage.setItem(storageKey.REPERAGE, JSON.stringify(reperage));
}

export const isArrayNull = (arr: any) => {
  return arr === null || arr === undefined ? [] : arr;
};

//   export const fetchChkList = (chkName: string) => {
//     let list: any = [];
//     let data: any = promptCheckBoxData;
//     data
//       .find((chkData: any) => chkData.name === chkName)
//       .chklist.map((chkData: any) => {
//         list.push({
//           name: chkData.value,
//           key: chkData.key,
//           isSelected: chkName === "downloadDAE" ? true : false,
//         });
//       });
//     return list;
//   };

export const onLinkClick = function (link: any) {
  window.revit.openAppLink(link);
};
