import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware, compose } from "redux";
import { reducers } from "../Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";
import { Routes, ElementType } from "../Util/type";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps;

interface State {
  route: Routes;
  element: ElementType;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
class MetresRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
    element: ElementType.WALL,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);

    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    this.store = createStore(
      reducers,
      composeEnhancers(applyMiddleware(thunk))
    );
  }

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  setElementType = (element: ElementType) => {
    this.setState({
      element,
    });
  };

  componentDidMount = () => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody onError={this.props.onError} />
      </Provider>
    );
  };
}

export default withErrorSubscription(MetresRoot);
