import { Borders, Cell, Worksheet } from "exceljs";
import _ from "lodash";
import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";
import { bimStorage, storageKey } from "../../../../BIMStore";
import Resizer from "react-image-file-resizer";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";

export const addCeilingDetailsArrayHeader = (
  datasheet: Worksheet,
  startingIndex: number,
  ifSelectionsAreZone?: boolean
) => {
  let detailsHeaderCells = [
    { letter: "A", value: "Niveau" },
    { letter: "B", value: "Zone" },
    { letter: "C", value: "Ouvrage" },
    { letter: "D", value: "Référence interne \n (Système REVIT)" },
    { letter: "F", value: "S (m²)" },
    { letter: "G", value: "Id du plafond" },
    { letter: "H", value: "Périmètre (m)" },
    { letter: "I", value: "HSP (m)" },
  ];

  detailsHeaderCells.forEach((cell: any) => {
    insertDetailsHeaderCell(
      datasheet.getCell(`${cell.letter}${startingIndex + 1}`),
      cell.value
    );
  });
  datasheet.mergeCells(`D${startingIndex + 1}:E${startingIndex + 1}`);

  if (!ifSelectionsAreZone) {
    datasheet.mergeCells(`A${startingIndex + 1}:B${startingIndex + 1}`);
    datasheet.mergeCells(`A${startingIndex}:I${startingIndex}`);
    let detailsCell = datasheet.getCell(`A${startingIndex}`);
    detailsCell.value = "DETAIL PAR NIVEAU PAR PLAFOND";
    detailsCell.alignment.horizontal = "left";
    detailsCell.font.bold = true;
    detailsCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "FFCE00",
      },
    };
  } else {
    datasheet.mergeCells(`A${startingIndex}:I${startingIndex}`);
    let detailsCell = datasheet.getCell(`A${startingIndex}`);
    detailsCell.value = "DETAIL PAR ETAGE PAR ZONE PAR PLAFOND";
    detailsCell.alignment.horizontal = "left";
    detailsCell.font.bold = true;
    detailsCell.font = { color: { argb: "FFFFFF" } };
    detailsCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "005EB8",
      },
    };
  }
};

export const addDetailsArrayHeader = (
  datasheet: Worksheet,
  startingIndex: number,
  ifSelectionsAreZone?: boolean
) => {
  let detailsHeaderCells = [
    { letter: "A", value: "Levels" },
    { letter: "B", value: "Zone" },
    { letter: "C", value: "Solution" },
    { letter: "D", value: "Revit System" },
    { letter: "F", value: "S (m²)" },
    { letter: "G", value: "Wall ID" },
    { letter: "H", value: "H (m)" },
    { letter: "I", value: "L (m)" },
  ];
  detailsHeaderCells.forEach((cell: any) => {
    insertDetailsHeaderCell(
      datasheet.getCell(`${cell.letter}${startingIndex + 1}`),
      cell.value
    );
  });
  datasheet.mergeCells(`D${startingIndex + 1}:E${startingIndex + 1}`);

  if (!ifSelectionsAreZone) {
    datasheet.mergeCells(`A${startingIndex + 1}:B${startingIndex + 1}`);
    datasheet.mergeCells(`A${startingIndex}:I${startingIndex}`);
    let detailsCell = datasheet.getCell(`A${startingIndex}`);
    detailsCell.value = "Detail by level by wall";
    detailsCell.alignment.horizontal = "left";
    detailsCell.font.bold = true;
    detailsCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "FFCE00",
      },
    };
  } else {
    datasheet.mergeCells(`A${startingIndex}:I${startingIndex}`);
    let detailsCell = datasheet.getCell(`A${startingIndex}`);
    detailsCell.value = "DETAIL PAR ETAGE PAR ZONE PAR MUR";
    detailsCell.alignment.horizontal = "left";
    detailsCell.font.bold = true;
    detailsCell.font = { color: { argb: "FFFFFF" } };
    detailsCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "005EB8",
      },
    };
  }
};
export const writeToCellAndStyle = (
  dataSheet: Worksheet,
  cellKey: string,
  value: any,
  NACells: boolean = false
) => {
  let cell = dataSheet.getCell(cellKey);
  cell.unmerge();
  cell.value = value;
  cell.alignment = { horizontal: "center", wrapText: true, vertical: "middle" };
  let num: any = cell.value;
  if (NACells && num === "NA") {
    cell.value = "";
    cell.style.fill = {
      type: "pattern",
      pattern: "lightUp",
      fgColor: {
        argb: "000000",
      },
      bgColor: {
        argb: "D9D9D9",
      },
    };
    let n: number = parseInt(cell.row);
    dataSheet.getRow(n).getCell("A").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
    dataSheet.getRow(n).getCell("B").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
    dataSheet.getRow(n).getCell("C").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
    dataSheet.getRow(n).getCell("D").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
  }
  cell.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
};
export const addGlobalLevelHeader = (
  datasheet: Worksheet,
  startingIndex: number,
  ifSelectionsAreZone?: boolean
) => {
  let detailsHeaderCells = [
    { letter: "A", value: "Levels" },
    { letter: "B", value: "Zone" },
    { letter: "C", value: "Solution" },
    { letter: "D", value: "Revit System" },
    { letter: "F", value: "S (m²)" },
  ];

  detailsHeaderCells.forEach((cell: any) => {
    insertDetailsHeaderCell(
      datasheet.getCell(`${cell.letter}${startingIndex + 1}`),
      cell.value
    );
  });
  datasheet.mergeCells(`D${startingIndex + 1}:E${startingIndex + 1}`);

  if (!ifSelectionsAreZone) {
    datasheet.mergeCells(`A${startingIndex + 1}:B${startingIndex + 1}`);
    datasheet.mergeCells(`A${startingIndex}:I${startingIndex}`);
    let detailsCell = datasheet.getCell(`A${startingIndex}`);
    detailsCell.value = "Global by level";
    detailsCell.alignment.horizontal = "left";
    detailsCell.font.bold = true;
    detailsCell.font = { color: { argb: "FFFFFF" } };
    detailsCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "005EB8",
      },
    };
  } else {
    datasheet.mergeCells(`A${startingIndex}:I${startingIndex}`);
    let detailsCell = datasheet.getCell(`A${startingIndex}`);
    detailsCell.value = "GLOBAL PAR ETAGE PAR ZONE";
    detailsCell.alignment.horizontal = "left";
    detailsCell.font.bold = true;
    detailsCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "FFCE00",
      },
    };
  }
};

const insertDetailsHeaderCell = (cell: Cell, value: any) => {
  let border: Partial<Borders> = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  cell.value = value;
  cell.border = _.cloneDeep(border);
  cell.font = { ...cell.font, bold: true, size: 11 };
  cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
};

const b64toBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      110,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const addImagetoWorkbook = async (excelBook: Excel.Workbook) => {
  let infoFormData: any = await bimStorage.getInfoForm();

  let logo = infoFormData.information_enterprise_logo;
  if (logo !== "") {
    if (!logo.includes("/html", 0)) {
      const resizedImg = await resizeFile(b64toBlob(logo));
      const imageId = excelBook.addImage({
        base64: resizedImg ? resizedImg : logo,
        extension: "png",
      });
      return imageId;
    }
  }
  return 0;
};

export const addLogoToSheet = async (
  workbook: Excel.Workbook,
  worksheets: any[]
) => {
  const logoImageId = await addImagetoWorkbook(workbook);
  if (logoImageId !== 0) {
    worksheets.forEach((e: any) => {
      logoImageId !== 0 &&
        e.addImage(logoImageId, {
          tl: { col: 0, row: 0 },
          ext: { width: 150, height: 110 },
        });
    });
  }
};

export const addProjectInfo = async (
  workbook: Excel.Workbook,
  datasheet: Worksheet
) => {
  let infoFormData: any = await bimStorage.getInfoForm();
  const documentName = await api.queries.getActiveDocumentName();
  const projData: any = await api.queries.getProjectInformation();
  await addLogoToSheet(workbook, [datasheet]);
  datasheet.getCell("F8").value = infoFormData.information_enterprise_name;
  datasheet.getCell(
    "F9"
  ).value = `${infoFormData.information_enterprise_address}, ${infoFormData.information_enterprise_postal} ${infoFormData.information_enterprise_villa} - ${infoFormData.information_enterprise_pays}`;

  datasheet.getCell(`C10`).value = infoFormData.information_chantier_reference;
  if (projData) {
    datasheet.getCell(`C8`).value = projData.Name.replace(/&apos;/g, "'");
  }

  if (documentName) {
    datasheet.getCell("F13").value = documentName;
  }
};
