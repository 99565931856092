import { ProductDetailData } from "../../../../RevitJS/Types/BddTypes";
import { AxiosInstance } from "axios";
import { getSystemDetailsById, getPlacoSystemsDetails } from "../Requests";
import {
  BuiltInCategory,
  ElementsTree,
  ID,
} from "../../../../RevitJS/Types/RevitTypes";
import { setFiltersAid } from "../../../../RevitJS/Helpers";
import { UnderSystemTree } from "../../../../RevitJS/Types/RigipsTypes";

export const getSystemsDetailsAndFilters = async (
  ids: ID[],
  bdd: AxiosInstance,
  filters: any[]
): Promise<{
  systemsDetails: ProductDetailData[];
  filters: any[];
  attributes: any[];
}> => {
  let systemDetailsRequest = await getSystemDetailsById(ids, bdd);

  let systemsDetails = systemDetailsRequest.data.objects.map((sys) => {
    return { ...sys, filterFields: {} } as unknown;
  }) as ProductDetailData[];
  let attributes =
    systemDetailsRequest.data.context.attributeDependencies[0].attributes;

  filters = setFiltersAid(filters, attributes);

  systemsDetails = addFilterFields(systemsDetails, filters);
  filters = setFiltersOptions(filters, systemsDetails);

  return {
    systemsDetails,
    filters,
    attributes,
  };
};

export const addFilterFields = (
  systemsDetails: ProductDetailData[],
  filters: any
) => {
  return systemsDetails.map((sys: any) => {
    filters.forEach((filter: any) => {
      let attr = sys.attributes.find((attr: any) => attr.aid === filter.aid);
      if (attr) {
        let attrValue;
        if (filter.type === "Numeric") attrValue = attr.values[0].numericValue;
        else {
          attrValue = attr.values[0].value;
        }
        sys.filterFields[filter.name] = attrValue;
      } else {
        sys.filterFields[filter.name] = undefined;
      }
    });
    return sys;
  });
};

export const setFiltersOptions = (
  filters: any,
  systemsDetails: ProductDetailData[]
) => {
  filters = [...filters];
  systemsDetails.forEach((system) => {
    filters.forEach((filter: any) => {
      filter.options = filter.options || [];
      let attrValue = system.filterFields[filter.name];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      } else {
      }
    });
  });
  return filters.map((filter: any) => {
    filter.options.sort((a: any, b: any) => {
      if (filter.pimAttribute === "GFR-Fire protection") {
        return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
      } else {
        return a - b;
      }
    });
    return filter;
  });
};

export const filterUnderSystems = (
  underSystems: any[],
  fireId: string,
  occupationId: string,
  spacingId: string,
  maxHeightId: string
) => {
  let underSystemTree = {
    EI: {
      "1": {
        "100": null,
        "62.5": null,
        "0": null,
      },
      "2": {
        "100": null,
        "62.5": null,
        "0": null,
      },
    },
    NEI: {
      "1": {
        "100": null,
        "62.5": null,
        "41.7": null,
        "31.25": null,
        "0": null,
      },
      "2": {
        "100": null,
        "62.5": null,
        "41.7": null,
        "31.25": null,
        "0": null,
      },
    },
  };
  for (let i = 0; i < underSystems.length; i++) {
    let fireConstrain = underSystems[i].attributes.find(
      (e: any) => e.aid === fireId
    );
    let occupation = underSystems[i].attributes.find(
      (e: any) => e.aid === occupationId
    );
    let spacing = underSystems[i].attributes.find(
      (e: any) => e.aid === spacingId
    );
    let maxHeight = underSystems[i].attributes.find(
      (e: any) => e.aid === maxHeightId
    );

    switch (fireConstrain.values[0].value) {
      case "ohne Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["1"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["NEI"]["1"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "41.7":
                underSystemTree["NEI"]["1"]["41.7"] =
                  maxHeight.values[0].numericValue;
                break;
              case "31.25":
                underSystemTree["NEI"]["1"]["31.25"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["NEI"]["1"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["2"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["NEI"]["2"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "41.7":
                underSystemTree["NEI"]["2"]["41.7"] =
                  maxHeight.values[0].numericValue;
                break;
              case "31.25":
                underSystemTree["NEI"]["2"]["31.25"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["NEI"]["2"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          default:
            // eslint-disable-next-line no-throw-literal
            throw "This spacing is not recognized";
        }
        break;
      case "mit Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["1"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["EI"]["1"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["EI"]["1"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["2"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["EI"]["2"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["EI"]["2"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          default:
            // eslint-disable-next-line no-throw-literal
            throw "This spacing is not recognized";
        }
        break;
      default:
        // eslint-disable-next-line no-throw-literal
        throw "Fire classification not recognized";
    }
  }
  return underSystemTree;
};

export const generateUnderSystemTree = () => ({
  EI: {
    "1": {
      "100": null,
      "62.5": null,
      "0": null,
    },
    "2": {
      "100": null,
      "62.5": null,
      "0": null,
    },
  },
  NEI: {
    "1": {
      "100": null,
      "62.5": null,
      "41.7": null,
      "31.25": null,
      "0": null,
    },
    "2": {
      "100": null,
      "62.5": null,
      "41.7": null,
      "31.25": null,
      "0": null,
    },
  },
});

export const fillUnderSystemsTree = (
  underSystems: any[],
  fireId: string,
  occupationId: string,
  spacingId: string,
  maxHeightId: string
): UnderSystemTree => {
  let underSystemTree: UnderSystemTree = generateUnderSystemTree();
  for (let i = 0; i < underSystems.length; i++) {
    let fireConstrain = underSystems[i].attributes.find(
      (e: any) => e.aid === fireId
    );
    let occupation = underSystems[i].attributes.find(
      (e: any) => e.aid === occupationId
    );
    let spacing = underSystems[i].attributes.find(
      (e: any) => e.aid === spacingId
    );

    switch (fireConstrain.values[0].value) {
      case "ohne Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["1"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["NEI"]["1"]["62.5"] = underSystems[i];
                break;
              case "41.7":
                underSystemTree["NEI"]["1"]["41.7"] = underSystems[i];
                break;
              case "31.25":
                underSystemTree["NEI"]["1"]["31.25"] = underSystems[i];
                break;
              case "0":
                underSystemTree["NEI"]["1"]["0"] = underSystems[i];
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["2"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["NEI"]["2"]["62.5"] = underSystems[i];
                break;
              case "41.7":
                underSystemTree["NEI"]["2"]["41.7"] = underSystems[i];
                break;
              case "31.25":
                underSystemTree["NEI"]["2"]["31.25"] = underSystems[i];
                break;
              case "0":
                underSystemTree["NEI"]["2"]["0"] = underSystems[i];
                break;
              default:
                throw "This spacing is not recognized";
            }
            break;
          default:
            throw "This spacing is not recognized";
        }
        break;
      case "mit Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["1"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["EI"]["1"]["62.5"] = underSystems[i];
                break;
              case "0":
                underSystemTree["EI"]["1"]["0"] = underSystems[i];
                break;
              default:
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["2"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["EI"]["2"]["62.5"] = underSystems[i];
                break;
              case "0":
                underSystemTree["EI"]["2"]["0"] = underSystems[i];
                break;
              default:
                throw "This spacing is not recognized";
            }
            break;
          default:
            throw "This spacing is not recognized";
        }
        break;
      default:
        throw "Fire classification not recognized";
    }
  }
  return underSystemTree;
};

export const selectedUnderSystem = (
  underSystems: any[],
  fireConstrain: boolean,
  publicConstrain: boolean,
  height: number,
  fireId: string,
  occupationId: string,
  spacingId: string,
  maxHeightId: string
) => {
  // let underSystemTree: UnderSystemTree = generateUnderSystemTree();
  let filteredUnderSystems = underSystems
    .filter((underSystem) => {
      let fireConstrainAttr = underSystem.attributes.find(
        (e: any) => e.aid === fireId
      ).values[0].value;
      if (fireConstrain && fireConstrainAttr === "mit Brand") {
        return true;
      }
      if (!fireConstrain && fireConstrainAttr === "ohne Brand") {
        return true;
      } else {
        return false;
      }
    })
    .filter((underSystem) => {
      let occupation = underSystem.attributes.find(
        (e: any) => e.aid === occupationId
      ).values[0].value;
      if (publicConstrain && occupation === "2") {
        return true;
      }
      if (!publicConstrain && occupation === "1") {
        return true;
      } else {
        return false;
      }
    });

  if (filteredUnderSystems.length === 0) {
    throw "Problem with under system selection";
  }
  if (filteredUnderSystems.length === 1) {
    return filteredUnderSystems[0];
  }

  let sortedSysems = filteredUnderSystems.sort((a, b) => {
    let maxHeightA = a.attributes.find((e: any) => e.aid === maxHeightId)
      .values[0].numericValue;
    let maxHeightB = b.attributes.find((e: any) => e.aid === maxHeightId)
      .values[0].numericValue;
    return maxHeightA - maxHeightB;
  });

  for (let j = 0; j < sortedSysems.length; j++) {
    let systemMaxHeight =
      sortedSysems[j].attributes.find((e: any) => e.aid === maxHeightId)
        .values[0].numericValue * 1000;
    if (height < systemMaxHeight) {
      return sortedSysems[j];
    }
  }
  return sortedSysems[sortedSysems.length - 1];
};

export const setFrameSpace = (
  fireConstrain: boolean,
  publicBuildingConstrain: boolean,
  height: number,
  spaceTree: any
) => {
  switch (fireConstrain) {
    case true:
      return 625;
    case false:
      switch (publicBuildingConstrain) {
        case true:
          if (height <= spaceTree["NEI"]["2"]["100"]) {
            return 100;
          } else if (height <= spaceTree["NEI"]["2"]["62.5"]) {
            return 625;
          } else if (height <= spaceTree["NEI"]["2"]["41.7"]) {
            return 417;
          } else {
            return 312.5;
          }
        case false:
          if (height <= spaceTree["NEI"]["1"]["100"]) {
            return 100;
          } else if (height <= spaceTree["NEI"]["1"]["62.5"]) {
            return 625;
          } else if (height <= spaceTree["NEI"]["1"]["41.7"]) {
            return 417;
          } else {
            return 312.5;
          }
      }
  }
};

export const generateResultObject = () => {
  return {
    systemName: null,
    framesSpace: null,
    plateWidth: null,
    E1: null,
    E2: null,
    E3: null,
    I1: null,
    I2: null,
    I3: null,
    E1_Name: "None",
    E2_Name: "None",
    E3_Name: "None",
    I1_Name: "None",
    I2_Name: "None",
    I3_Name: "None",
    Reverse: true,
    Flipped: false,
    Lining: false,
    railsSpace: 0,
    staggering: true,
    doubleFraming: false,
    doubleAmount: false,
    Frame1_Name: "Frame",
    Rail1_Name: "Rail",
    SAA: false,
    SAD: false,
  };
};

export const parseAlbaSystem = async (bdd: AxiosInstance, oid: string) => {
  const { attributes, objects } = await getPlacoSystemsDetails(bdd, [oid]).then(
    (response: any) => {
      return {
        attributes: response.data.context.attributeDependencies[0].attributes,
        objects: response.data.objects,
      };
    }
  );
  let nb_attributes = attributes.length;
  let rigpsTechnicalNames = [
    "TD_System_Plattendicken",
    "TD_System_Systemaufbau",
    "TD_Untersystem_Brandfalldef",
    "TD_Untersystem_Raumnutzung",
    "TD_Untersystem_Ständerabstand",
    "TD_Untersystem_Wandhöhe",
    "TD_Untersystem_Schlüssel",
  ];
  let layout_params: any = {};

  for (let i = 0; i < nb_attributes; i++) {
    if (rigpsTechnicalNames.indexOf(attributes[i].technicalName) > -1) {
      layout_params[attributes[i].technicalName] = attributes[i].aid;
    }
  }

  let underSystems = objects.filter(
    (obj: any) => obj.types[0] === "UnderSystem"
  );
  // let underSystemsTree = filterUnderSystems(underSystems, layout_params["TD_Untersystem_Brandfalldef"], layout_params["TD_Untersystem_Raumnutzung"], layout_params["TD_Untersystem_Ständerabstand"], layout_params["TD_Untersystem_Wandhöhe"])
  // let systemsTree = fillUnderSystemsTree(underSystems, layout_params["TD_Untersystem_Brandfalldef"], layout_params["TD_Untersystem_Raumnutzung"], layout_params["TD_Untersystem_Ständerabstand"], layout_params["TD_Untersystem_Wandhöhe"]);

  // let underSystem = selectedUnderSystem(underSystems, false, true, 15, layout_params["TD_Untersystem_Brandfalldef"], layout_params["TD_Untersystem_Raumnutzung"], layout_params["TD_Untersystem_Ständerabstand"], layout_params["TD_Untersystem_Wandhöhe"]);
  return parseUnderSystem(underSystems[0], layout_params);
};

export const parseRigipsSystem = async (
  config: any,
  oid: string,
  maxHeight: number,
  fireConstrain: boolean = false,
  switchParement: boolean = false,
  reverse: boolean = false,
  publicConstrain: boolean = false
) => {
  const { attributes, objects } = await getPlacoSystemsDetails(config, [
    oid,
  ]).then((response: any) => {
    return {
      attributes: response.data.context.attributeDependencies[0].attributes,
      objects: response.data.objects,
    };
  });
  let nb_attributes = attributes.length;
  let rigpsTechnicalNames = [
    "TD_System_Plattendicken",
    "TD_System_Systemaufbau",
    "TD_Untersystem_Brandfalldef",
    "TD_Untersystem_Raumnutzung",
    "TD_Untersystem_Ständerabstand",
    "TD_Untersystem_Wandhöhe",
    "TD_Untersystem_Schlüssel",
    "TD_System_Dämmung",
  ];
  let layout_params: any = {};

  for (let i = 0; i < nb_attributes; i++) {
    if (rigpsTechnicalNames.indexOf(attributes[i].technicalName) > -1) {
      layout_params[attributes[i].technicalName] = attributes[i].aid;
    }
  }

  let underSystems = objects.filter(
    (obj: any) => obj.types[0] === "UnderSystem"
  );
  filterUnderSystems(
    underSystems,
    layout_params["TD_Untersystem_Brandfalldef"],
    layout_params["TD_Untersystem_Raumnutzung"],
    layout_params["TD_Untersystem_Ständerabstand"],
    layout_params["TD_Untersystem_Wandhöhe"]
  );
  fillUnderSystemsTree(
    underSystems,
    layout_params["TD_Untersystem_Brandfalldef"],
    layout_params["TD_Untersystem_Raumnutzung"],
    layout_params["TD_Untersystem_Ständerabstand"],
    layout_params["TD_Untersystem_Wandhöhe"]
  );
  let underSystem = selectedUnderSystem(
    underSystems,
    fireConstrain,
    publicConstrain,
    maxHeight,
    layout_params["TD_Untersystem_Brandfalldef"],
    layout_params["TD_Untersystem_Raumnutzung"],
    layout_params["TD_Untersystem_Ständerabstand"],
    layout_params["TD_Untersystem_Wandhöhe"]
  );
  layout_params["SwitchParement"] = switchParement;
  layout_params["Reverse"] = reverse;
  return parseUnderSystem(underSystem, layout_params);
};

export const parseUnderSystem = (underSystem: any, layout_params: any) => {
  let thickness_string = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_System_Plattendicken"]
  ).values[0].value;
  let thicknesses;
  if (thickness_string.includes("= ")) {
    thicknesses = thickness_string.split("= ");
  }
  if (thickness_string.includes("=")) {
    thicknesses = thickness_string.split("=");
  } else if (thickness_string.includes("_ _")) {
    thicknesses = thickness_string.split("_ _");
  } else {
    thicknesses = thickness_string.split("_");
  }

  let plasterboardNamesString = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_System_Systemaufbau"]
  ).values[0].value;
  let plasterboardNames;
  if (plasterboardNamesString.includes("= ")) {
    plasterboardNames = plasterboardNamesString.split("= ");
  } else if (plasterboardNamesString.includes("=")) {
    plasterboardNames = plasterboardNamesString.split("=");
  } else if (plasterboardNamesString.includes("_ _")) {
    plasterboardNames = plasterboardNamesString.split("_ _");
  } else {
    plasterboardNames = plasterboardNamesString.split("_");
  }
  let framesSpace =
    underSystem.attributes.find(
      (e: any) => e.aid === layout_params["TD_Untersystem_Ständerabstand"]
    ).values[0].numericValue * 10;
  let nb_sides = thicknesses.length;

  let side_A_thicknesses = thicknesses[0].split("/");
  let side_A_names = plasterboardNames[0].split("/");
  let side_A_length = side_A_thicknesses.length;
  let result: any = {
    systemName: "Test",
    framesSpace,
    plateWidth: 1250,
    E1: "None",
    E2: "None",
    E3: "None",
    I1: "None",
    I2: "None",
    I3: "None",
    E1_Name: "None",
    E2_Name: "None",
    E3_Name: "None",
    I1_Name: "None",
    I2_Name: "None",
    I3_Name: "None",
    Reverse: layout_params["Reverse"],
    Flipped: layout_params["SwitchParement"],
    Lining: false,
    railsSpace: 0,
    staggering: true,
    doubleFraming: plasterboardNamesString.includes("=") ? true : false,
    doubleAmount: thickness_string.includes("_ _") ? true : false,
    Frame1_Name: "Frame",
    Rail1_Name: "Rail",
    SAA: false,
    SAD: false,
    systemStruct: underSystem.attributes.find(
      (e: any) => e.aid === layout_params["TD_System_Systemaufbau"]
    ).values[0].value,
    layersDesc: underSystem.attributes.find(
      (e: any) => e.aid === layout_params["TD_System_Systemaufbau"]
    ).values[0].value,
    whool: layout_params["TD_System_Dämmung"]
      ? underSystem.attributes.find(
          (e: any) => e.aid === layout_params["TD_System_Dämmung"]
        ).values[0].value
      : null,
  };

  for (let i = 0; i < side_A_length; i++) {
    // parementA.push({ number: i, content: side_A_names[i], name: "Rigips", thickness: side_A_thicknesses[i] });
    let layer = `E${i + 1}`;
    let layer_name = `E${i + 1}_Name`;
    result[layer] = parseFloat(side_A_thicknesses[i]);
    result[layer_name] = side_A_names[i];
  }

  if (nb_sides > 1) {
    let side_B_thicknesses = thicknesses[1].split("/");
    let side_B_names = plasterboardNames[1].split("/");
    let side_B_length = side_B_thicknesses.length;

    for (let i = 0; i < side_B_length; i++) {
      // parementA.push({ number: i, content: side_A_names[i], name: "Rigips", thickness: side_A_thicknesses[i] });
      let layer = `I${i + 1}`;
      let layer_name = `I${i + 1}_Name`;
      result[layer] = parseFloat(side_B_thicknesses[i]);
      result[layer_name] = side_B_names[i];
    }
  }
  return {
    ...result,
    doublage: nb_sides ? false : true,
  };
};

export const startInList = (start: string | null, checkList: string[]) => {
  if (start === null) return false;
  for (let i = 0; i < checkList.length; i++) {
    if (start.startsWith(checkList[i])) return true;
  }
  return false;
};

export const clearTree = (tree: ElementsTree): ElementsTree => {
  return {
    Tree: tree.Tree.filter(
      (elemsByLevelAndType) => elemsByLevelAndType.Elements.length > 0
    ),
  };
};

export const filterTree = (
  tree: ElementsTree,
  category: BuiltInCategory
): ElementsTree => {
  return {
    Tree: tree.Tree.map((elemsByLevelAndType) => {
      return {
        ...elemsByLevelAndType,
        Elements: elemsByLevelAndType.Elements.filter(
          (elemsByType) => elemsByType.ObjectType === category
        ),
      };
    }),
  };
};

export const selectedUnderSystemCeilings = (
  underSystems: any[],
  fireConstrain: boolean,
  fireId: string
) => {
  let filteredUnderSystems = underSystems.filter((underSystem) => {
    let fireConstrainAttr = underSystem.attributes.find(
      (e: any) => e.aid === fireId
    ).values[0].value;
    if (fireConstrain && fireConstrainAttr === "mit Brand") {
      return true;
    }
    if (!fireConstrain && fireConstrainAttr === "ohne Brand") {
      return true;
    } else {
      return false;
    }
  });
  if (filteredUnderSystems.length === 0) {
    throw "Problem with under system selection";
  }
  if (filteredUnderSystems.length === 1) {
    return filteredUnderSystems[0];
  }

  return filteredUnderSystems[filteredUnderSystems.length - 1];
};

export const parseUnderSystemCeilings = (
  underSystem: any,
  layout_params: any
) => {
  let thickness_string = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_System_Plattendicken"]
  ).values[0].value;
  let thicknesses;
  if (thickness_string.includes("= ")) {
    thicknesses = thickness_string.split("= ");
  }
  if (thickness_string.includes("=")) {
    thicknesses = thickness_string.split("=");
  } else if (thickness_string.includes("_ _")) {
    thicknesses = thickness_string.split("_ _");
  } else {
    thicknesses = thickness_string.split("_");
  }

  let plasterboardNamesString = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_System_Systemaufbau"]
  ).values[0].value;
  let plasterboardNames;
  if (plasterboardNamesString.includes("= ")) {
    plasterboardNames = plasterboardNamesString.split("= ");
  } else if (plasterboardNamesString.includes("=")) {
    plasterboardNames = plasterboardNamesString.split("=");
  } else if (plasterboardNamesString.includes("_ _")) {
    plasterboardNames = plasterboardNamesString.split("_ _");
  } else {
    plasterboardNames = plasterboardNamesString.split("_");
  }

  let profil_string = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_System_Profil"]
  ).values[0].value;
  let profil;
  if (profil_string.includes("= ")) {
    profil = profil_string.split("= ");
  }
  if (profil_string.includes("=")) {
    profil = profil_string.split("=");
  } else if (profil_string.includes("_ _")) {
    profil = profil_string.split("_ _");
  } else {
    profil = profil_string.split("_");
  }

  let side_A_thicknesses = thicknesses[1].split("/");

  let TD_Untersystem_y = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_Untersystem_y"]
  ).values[0].numericValue;

  let TD_Untersystem_x = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_Untersystem_x"]
  ).values[0].numericValue;
  let TD_Untersystem_x0 = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_Untersystem_x0"]
  ).values[0].numericValue;
  let TD_Untersystem_L = underSystem.attributes.find(
    (e: any) => e.aid === layout_params["TD_Untersystem_L"]
  ).values[0].numericValue;

  let result: any = {
    primary: {
      name: "montant Stil® M 48",
      familyName: "Profilés C pour plafonds",
      length: 4000,
      width: 60,
      height: 27,
      entraxeMax: TD_Untersystem_y,
      minLength: 500,
    },
    secondary: {
      name: "montant Stil® M 48",
      familyName: "Profilés C pour plafonds",
      length: 4000,
      width: 60,
      height: 27,
      entraxeMax: TD_Untersystem_L,
    },
    contour: {
      name: "UAP 29",
      familyName: "Profilés U de raccord",
      length: 4000,
      width: 48,
      height: 29,
    },
    board1: {
      name: "Board 1",
      familyName: "Board 1",
      length: 2000,
      width: 1250,
      thickness: parseFloat(side_A_thicknesses[0]),
      minDistanceFromContour: 0,
      offSetBetweenLayers: 250,
    },
    board2: {
      name: "Board 1",
      familyName: "Board 1",
      length: 2000,
      width: 1250,
      thickness: parseFloat(side_A_thicknesses[0]),
      minDistanceFromContour: 0,
      offSetBetweenLayer: 250,
      xOffsetLayer1: 400,
      yOffsetLayer1: 400,
    },
    hanger: {
      name: "Suspente WinFix® dB",
      familyName: "Suspensions selon systeme",
      maxDistanceFromContour: TD_Untersystem_x0,
      maxDistanceBetween: TD_Untersystem_x,
    },
    rotation: 0,
  };
  return result;
};

export const parseRigipsCeilingsSystem = async (
  bdd: any,
  oid: string,
  fireConstrain: boolean = false,
  AdditionalWeight: boolean = false
) => {
  const { attributes, objects } = await getPlacoSystemsDetails(bdd, [oid]).then(
    (response: any) => {
      return {
        attributes: response.data.context.attributeDependencies[0].attributes,
        objects: response.data.objects,
      };
    }
  );
  let nb_attributes = attributes.length;
  let rigpsTechnicalNames = [
    "TD_System_Plattendicken",
    "TD_System_Systemaufbau",
    "TD_Untersystem_Brandfalldef",
    "TD_Untersystem_Schlüssel",
    "TD_System_Profil",
    "TD_Untersystem_L",
    "TD_Untersystem_L0",
    "TD_Untersystem_x",
    "TD_Untersystem_x0",
    "TD_Untersystem_y",
    "TD_Untersystem_y0",
  ];
  let layout_params: any = {};

  for (let i = 0; i < nb_attributes; i++) {
    if (rigpsTechnicalNames.indexOf(attributes[i].technicalName) > -1) {
      layout_params[attributes[i].technicalName] = attributes[i].aid;
    }
  }

  let underSystems = objects.filter(
    (obj: any) => obj.types[0] === "UnderSystem"
  );

  selectedUnderSystemCeilings(
    underSystems,
    fireConstrain,
    layout_params["TD_Untersystem_Brandfalldef"]
  );

  return parseUnderSystemCeilings(underSystems[0], layout_params);
};
