import { file } from "jszip";
import { filter, find, map, remove, some } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Form, Icon, Label, Modal } from "semantic-ui-react";
import { cctpType } from ".";
import { api } from "../../../../../RevitJS/API";

import { Language } from "../../../Selection/Actions/types";
import { SelectionStore } from "../../../Selection/Reducers";

interface otherModalProps {
  open: boolean;
  closeModal: any;
  cctpData: cctpType[];
  fitcheModalRow: cctpType;
  setCctpData: any;
  otherOptions: any;
}

export const OtherFileModal = ({
  open,
  closeModal,
  cctpData,
  fitcheModalRow,
  setCctpData,
  otherOptions,
}: otherModalProps) => {
  const [files, setFiles] = useState<any>([]);
  const [defaultFile, setDefaultFile] = useState<any>({
    index: 0,
    fileType: "",
    fileid: "",
    fileData: null,
    screenName: "",
  });
  const [errorMsg, setErrormsg] = useState<string>("");
  const [uploadMsg, setUploadMsg] = useState<string>("");
  const [requestCancel, setRequestCancel] = useState<any>();
  const [fileSize, setFileSize] = useState<boolean>(false);

  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );
  const applicationLanguage: Language = reduxState.language;

  const changeStateOfValidateButton = useCallback(() => {
    let emptySelectionData = false;

    if (files.length > 0 || defaultFile.screenName !== "") {
      emptySelectionData = true;
    }

    if (!emptySelectionData || uploadMsg !== "" || fileSize === true) {
      return true;
    } else {
      return false;
    }
  }, [fileSize, files, defaultFile, uploadMsg]);

  useEffect(() => {
    const selectedRow = find(cctpData, { srno: fitcheModalRow?.srno });
    const visible = otherOptionsAvailable;
    const oo = otherOptions;
    if (selectedRow) {
      if (selectedRow.files.length > 0) setFiles(selectedRow.files);
    }
  }, [cctpData, fitcheModalRow]);

  const cancelOnGoingRequest = () => {
    setUploadMsg("");
    requestCancel && requestCancel.cancel();
  };

  const handleAdd = (data: any) => {
    if (data.screenName === "" || data.fileType === "") {
      setErrormsg("Please select the file and fileType for addition");
      return false;
    }

    let index = files.findIndex((f: any) => f.fileType === "Fiche système");
    if (index > -1 && defaultFile.fileType === "Fiche système") {
      setErrormsg("Multiple files are not allow for Fiche système");
      return false;
    }

    setErrormsg("");
    let fld = [...files];
    fld.push({
      index: fld.length + 1,
      fileType: data.fileType,
      fileid: data.fileid,
      fileData: data.fileData,
      screenName: data.screenName,
    });
    setFiles(fld);
    changeStateOfValidateButton();
    setDefaultFile({
      index: fld.length,
      fileType: "",
      fileid: "",
      fileData: null,
      screenName: "",
    });
  };

  const handleRemove = (index: number) => {
    setErrormsg("");
    if (files.length > 0) {
      let fld = [...files];
      remove(fld, function (n) {
        return n.index === index;
      });
      setFiles(fld);
    } else {
      setDefaultFile({
        index: 0,
        fileType: "",
        fileid: "",
        fileData: null,
        screenName: "",
      });
    }

    changeStateOfValidateButton();
  };

  const handleFileType = (value: string, index: number) => {
    setErrormsg("");
    let fld = defaultFile;
    fld.fileType = value;
    setDefaultFile({
      index: defaultFile.index,
      fileType: value,
      fileid: defaultFile.fileid,
      fileData: defaultFile.fileData,
      screenName: defaultFile.screenName,
    });
  };

  const handleFileSelect = async (previousFile: string, index: string) => {
    setErrormsg("");
    setFileSize(false);
    const uploadedFile = await api.queries.copyPhotoboxFile(previousFile);
    if (uploadedFile.Error === "") {
      setDefaultFile({
        index: defaultFile.index,
        fileType: defaultFile.fileType,
        fileid: defaultFile.fileid,
        fileData: uploadedFile.FileName,
        screenName: uploadedFile.ScreenName,
      });
      changeStateOfValidateButton();
    } else {
      if (uploadedFile.Error === "sizeError") {
        setErrormsg(wording.errorMsg[applicationLanguage]);
        setFileSize(true);
      }
    }
  };

  const onValidate = () => {
    setErrormsg("");
    let requiredFieldCheck = true;
    let defaultSelection = true;
    let nullCheck = some(files, {
      fileData: null,
    });

    if (
      fitcheModalRow.isNonPlaco &&
      ((defaultFile.fileType !== "" && defaultFile.screenName !== "") ||
        files.length > 0)
    ) {
      requiredFieldCheck = some(files, {
        fileType: "Fiche système",
      });

      defaultSelection =
        defaultFile.fileType === "Fiche système" ? true : false;
    }

    if (nullCheck) {
      setErrormsg("Please import a pdf!");
    } else if (!requiredFieldCheck && !defaultSelection) {
      setErrormsg("Selecting Fiche système is required");
    } else if (files.length === 0 && !defaultSelection) {
      setErrormsg("No data to validate!");
    } else if (fileSize === true) {
      setErrormsg(wording.errorMsg[applicationLanguage]);
    } else {
      // uploadFilesToPhotobox();
      if (defaultSelection && fitcheModalRow.isNonPlaco) {
        if (files.length > 0) {
          setErrormsg(
            "Multiple files are not allowed, please delete the old file than import the new one"
          );
          return false;
        } else {
          if (defaultFile.screenName !== "" && defaultFile.fileType !== "") {
            files.push({
              index: files.length + 1,
              fileType: defaultFile.fileType,
              fileid: defaultFile.fileid,
              fileData: defaultFile.fileData,
              screenName: defaultFile.screenName,
            });
          }
        }
      } else if (defaultFile.screenName !== "" && defaultFile.fileType !== "") {
        files.push({
          index: files.length + 1,
          fileType: defaultFile.fileType,
          fileid: defaultFile.fileid,
          fileData: defaultFile.fileData,
          screenName: defaultFile.screenName,
        });
      }

      let cloneFld = [...files];

      let cloneCctp = JSON.parse(JSON.stringify(cctpData));
      cloneCctp = map(cloneCctp, (clctp: cctpType, index: number) => {
        if (clctp.srno === fitcheModalRow?.srno) {
          clctp.files = cloneFld;
        }
        return clctp;
      });
      setCctpData(cloneCctp);
      closeModal();
    }
  };

  const truncate = (str: string) => {
    return str.length > 25 ? str.substring(0, 25) + "..." : str;
  };

  const otherOptionsAvailable =
    files.length !== otherOptions.length
      ? filter(otherOptions, { disabled: false }).length > 0
      : false;

  const validate = changeStateOfValidateButton();
  return (
    <Modal
      onClose={closeModal}
      open={open}
      size="tiny"
      dimmer="inverted"
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header className="modalHeader">Ajout fichier</Modal.Header>

      <Modal.Actions style={{ height: "150px", overflowY: "scroll" }}>
        <Form style={{ display: "grid" }}>
          <OtherFileFormField
            data={defaultFile}
            index={files.length}
            otherOptions={otherOptions}
            handleFileSelect={handleFileSelect}
            handleFileType={handleFileType}
            handleRemove={handleRemove}
            handleAdd={handleAdd}
            otherOptionsAvailable={otherOptionsAvailable}
            lastField={files.length - 1 === defaultFile.index}
            key={files.length}
            fitcheModalRow={fitcheModalRow}
          />
        </Form>
      </Modal.Actions>
      <Modal.Content>
        <div style={{ display: "grid" }}>
          {files.map((data: any, index: any) => {
            return (
              <div style={{ display: "flex", marginTop: "2px" }}>
                <Form.Field style={{ width: "100px", margin: "0px 5px" }}>
                  {data.fileType}
                </Form.Field>
                <Form.Field style={{ margin: "0px 5px" }}>
                  <Label icon labelPosition="right" color="blue">
                    {truncate(data.screenName)}
                    <Icon
                      name="delete"
                      onClick={() => handleRemove(data.index)}
                    />
                  </Label>
                </Form.Field>
              </div>
            );
          })}
        </div>
      </Modal.Content>
      <div className="modalButtonCCTP">
        <Button
          size="tiny"
          color="orange"
          onClick={() => {
            cancelOnGoingRequest();
            closeModal();
          }}
          content="Annuler"
        ></Button>
        <div
          className="ui red basic label"
          style={{
            margin: "0px",
            fontSize: "10px",
            display: errorMsg === "" ? "none" : "block",
            width: "55%",
          }}
        >
          {errorMsg}
        </div>
        <div
          className="ui blue basic label"
          style={{
            margin: "0px",
            display: uploadMsg === "" ? "none" : "block",
          }}
        >
          {uploadMsg}
        </div>
        <Button
          type="submit"
          primary
          size="tiny"
          content="Valider"
          onClick={() => {
            onValidate();
          }}
        ></Button>
      </div>
    </Modal>
  );
};

const wording: WordingType = {
  errorMsg: {
    French:
      "Attention, le fichier sélectionné ne peut excéder une taille de 10mb",
    English: "Attention, the selected file cannot exceed a size of 10mb",
  },
};

type WordingType = {
  errorMsg: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const OtherFileFormField = ({
  index,
  data,
  otherOptions,
  handleFileSelect,
  handleFileType,
  handleRemove,
  handleAdd,
  otherOptionsAvailable,
  lastField,
  fitcheModalRow,
}: {
  index: any;
  data: any;
  otherOptions: any;
  handleFileSelect: any;
  handleFileType: any;
  handleRemove: any;
  handleAdd: any;
  otherOptionsAvailable: boolean;
  lastField: boolean;
  fitcheModalRow: cctpType;
}) => {
  return (
    <div style={{ display: "flex" }}>
      <Form.Field>
        <label
          className="custom-file-upload"
          onClick={() => handleFileSelect(data.fileData, data.index)}
        >
          <Icon name="upload" size="small" color="blue" />
          Importer
        </label>
      </Form.Field>
      <Form.Field style={{ margin: "0px 25px" }}>
        <Dropdown
          placeholder="Type fichier"
          selection
          search
          options={otherOptions}
          onChange={(e, { value }) => handleFileType(value, data.index)}
          clearable
          value={data.fileType}
        />
        <div className="align-center">{data.screenName}</div>
      </Form.Field>
      <div style={{ paddingTop: "10px", cursor: "pointer" }}>
        {otherOptions.length === 1 && fitcheModalRow.isNonPlaco && (
          <Icon
            name="trash alternate outline"
            onClick={() => handleRemove(data.index)}
          />
        )}
        {(otherOptions.length > 1 || !fitcheModalRow.isNonPlaco) && (
          <Icon name="add" color="blue" onClick={() => handleAdd(data)} />
        )}
      </div>
    </div>
  );
};
