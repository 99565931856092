import React, { Component } from "react";
import {
  Button,
  Loader,
  Dimmer,
  Image,
  Table,
  Checkbox,
  Segment,
} from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../ProjectInfo/Components/FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { dbStoreNameReperage, Routes } from "./root";
import "../Resources/reperageStyles.css";
import ColorPicker from "./Colorisation/ColorPicker";
import { setReperage } from "../Actions";

import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import flattenDeep from "lodash/flattenDeep";
import forEach from "lodash/forEach";
import find from "lodash/find";
import { omit } from "lodash";
import { saveReperage } from "../Helpers";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";

interface Props {
  moduleData: any;
  Icon: string;
  name: string;
  wording: {
    selections: { [key: string]: string };
    reperage: { [key: string]: string };
    defaultCustomColors: { [key: string]: string };
    genLocPlans: { [key: string]: string };
    identificationTable: { [key: string]: string };
    systemName: { [key: string]: string };
    colorChoice: { [key: string]: string };
    solution: { [key: string]: string };
    validate: { [key: string]: string };
    back: { [key: string]: string };
    wallType: { [key: string]: string };
    ceilingType: { [key: string]: string };
    systemType: { [key: string]: string };
  };
  language: string;
  route: Routes;
  setRoute: any;
  selections: any;
  setReperage: any;
  projectData: initProjectData;
  config: any;
}

interface State {
  erasing: boolean;
  displayColorPicker: boolean;
  color: { r: any; g: any; b: any; a: any };
  wallsData: any;
  extractedSolutionProduct: any;
  loading: boolean;
  index: number;
  isValidated: boolean;
  solutionColors: any[];
}

export class IdentificationTable extends Component<Props, State> {
  state = {
    erasing: false,
    color: { r: 241, g: 112, b: 19, a: 1 },
    displayColorPicker: false,
    wallsData: [],
    extractedSolutionProduct: [],
    loading: true,
    index: 0,
    isValidated: false,
    solutionColors: [],
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  // Events Methods
  componentDidMount = () => {
    //api.windowsHandler.resizeWindow(880, 480);

    if (this.props.moduleData.reperageData.buffer.time) {
      /// load the walls data for "reperage" from selection else load saved "reperage data"

      if (
        this.props.moduleData.reperageData.buffer.reperage.list.length === 0
      ) {
        this.groupWallsDataFromSelection();
      } else {
        if (this.props.moduleData.reperageData.buffer.reperage.version) {
          /// else assign saved reperage list to wallsData
          this.groupWallsDataFromHistory();
        } else {
          //this.migrateToNewDataStructure();
        }
      }
    } else {
      this.groupWallsDataFromSelection();
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: any) => {
    //if (prevProps.moduleData.reperageData.buffer.reperage !== this.props.moduleData.reperageData.buffer.reperage) {
    if (this.state.isValidated) {
      await saveReperage(
        this.props.moduleData.reperageData.buffer,
        this.props.projectData.ProjectId,
        this.props.config
      );
      this.props.setRoute(Routes.ROOT);
    }
    //}
  };

  toggleModal = (action: boolean) => {
    this.setState({ displayColorPicker: action });
    //step 2
    localStorage.removeItem("personalize");
    localStorage.setItem("identification", "true");
  };

  colorChangeHandler = (color: any) => {
    let wallsData = this.state.wallsData;
    forEach(wallsData, (value: any, index: number) => {
      if (this.state.index === index) {
        value.color = color;
      }
    });

    this.setState({
      wallsData: wallsData,
      color: { r: color.r, g: color.g, b: color.b, a: color.a },
    });
  };

  /// Extract selection from selection module
  /// collect wall data from revit
  /// collect placo solutions from api
  groupWallsDataFromSelection = async () => {
    /// Extract selection ids
    let selectionIds = map(
      this.props.moduleData.reperageData.buffer.selections.list,
      (n) => {
        return n.Id;
      }
    );

    /// Extract selection list based on ids
    let selectionList = filter(this.props.selections, function (o) {
      return includes(selectionIds, o.Id);
    });

    /// 1. Extract selected wall ids from selection.
    let wallRows = flattenDeep(
      map(selectionList, (ele) => {
        return map(ele.SelectionByType.wall.Rows, (row) => {
          return {
            ...row,
            zone: ele.Zone ? ele.Name : "",
            color: ele.Color,
          };
        });
      })
    );

    /// 1. Extract selected ceiling ids from selection.
    let ceilingRows = flattenDeep(
      map(selectionList, (ele) => {
        return map(ele.SelectionByType.ceiling.Rows, (row) => {
          return {
            ...row,
            zone: ele.Zone ? ele.Name : "",
            color: ele.Color,
          };
        });
      })
    );

    let grpWallsCeilingsData: any[] = [];
    let strReperageData = await bimStorage.listDetailModule(
      dbStoreNameReperage,
      `${this.props.projectData.ProjectId}_c61281f0-c270`
    );

    let reperageData = JSON.parse(strReperageData);

    if (
      reperageData &&
      reperageData.reperage &&
      reperageData.reperage.list.length > 0
    ) {
      let solutionColors = map(reperageData.reperage.list, (n) => {
        return { solution: n.PlacoSolution, color: n.color };
      });

      this.setState({ solutionColors: solutionColors });
    }

    let personaliseColor = await bimStorage.getItem(
      storageKey.REPERAGE_PERSONALISE_COLOR
    );

    forEach(wallRows, (value: any, index: number) => {
      let savedSolution = personaliseColor.filter(
        (p: any) => p.oid === value.Options.MappedSystem.oid
      )[0];
      let productName: any = value.Options.MappedSystem.attributes?.find((a: any) => a.technicalName === "A-Solution product name")?.values[0]?.value;
      grpWallsCeilingsData.push({
        PlacoSolution: productName
          ? productName
          : value.Options.MappedSystem.translation,
        PlacoSolutionId: value.Options.MappedSystem.oid,
        PlacoSolutionHeight:
          value.Options.MappedSystem["GFR-Height limit in m"],
        Zone: value.zone,
        WallType: value.RevitSelection.RevitType,
        Ids: value.RevitSelection.Ids,
        color: this.random_rgba(
          value.RevitSelection.RevitType,
          reperageData,
          savedSolution,
          productName
          ? productName
          : value.Options.MappedSystem.translation,
        ),
        chk: true,
        type: this.props.wording.wallType[this.props.language],
      });
    });

    forEach(ceilingRows, (value: any, index: number) => {
      let savedSolution = personaliseColor.filter(
        (p: any) => p.oid === value.Options.MappedSystem.oid
      )[0];
      let productName: any = value.Options.MappedSystem.attributes?.find((a: any) => a.technicalName === "A-Solution product name")?.values[0]?.value;


      grpWallsCeilingsData.push({
        PlacoSolution: productName
          ? productName
          : value.Options.MappedSystem.translation,
        PlacoSolutionId: value.Options.MappedSystem.oid,
        PlacoSolutionHeight:
          value.Options.MappedSystem["GFR-Height limit in m"],
        Zone: value.zone,
        WallType: value.RevitSelection.RevitType,
        Ids: value.RevitSelection.Ids,
        color: this.random_rgba(
          value.RevitSelection.RevitType,
          reperageData,
          savedSolution,
          productName
          ? productName
          : value.Options.MappedSystem.translation,
        ),
        chk: true,
        type: this.props.wording.ceilingType[this.props.language],
      });
    });

    /// 5. Set state for wallsData, SolutionProducts
    this.setState({
      wallsData: grpWallsCeilingsData,
      loading: false,
    });
  };

  random_rgba = (
    wallType: any,
    reperageSavedData: any,
    savedSolution: any,
    solution: any
  ) => {
    let r: any;
    let g: any;
    let b: any;
    let a: any;

    if (
      reperageSavedData &&
      reperageSavedData.reperage &&
      reperageSavedData.reperage.list.length > 0
    ) {
      let selection = reperageSavedData.reperage.list.filter(
        (r: any) => r.WallType === wallType && r.PlacoSolution === solution
      );
      if (selection && selection.length > 0) {
        let color = selection[0].color;
        r = color.r;
        g = color.g;
        b = color.b;
        a = color.a;
        return { r, g, b, a };
      } else if (savedSolution) {
        r = savedSolution.color.r;
        g = savedSolution.color.g;
        b = savedSolution.color.b;
        a = savedSolution.color.a;
        return { r, g, b, a };
      }
    } else if (savedSolution) {
      r = savedSolution.color.r;
      g = savedSolution.color.g;
      b = savedSolution.color.b;
      a = savedSolution.color.a;
      return { r, g, b, a };
    }

    let solutionColors: any[] = this.state.solutionColors;
    let solutionWithColor = solutionColors.filter(
      (s: any) => s.solution === solution
    );
    if (solutionWithColor && solutionWithColor.length > 0) {
      let rgbColor = solutionWithColor[0].color;
      return rgbColor;
    }

    var o = Math.round,
      m = Math.random,
      s = 255;
    r = o(m() * s);
    g = o(m() * s);
    b = o(m() * s);
    a = parseFloat(m().toFixed(1));

    let solutionColor = { solution: solution, color: { r, g, b, a } };
    solutionColors.push(solutionColor);
    this.setState({ solutionColors: solutionColors });

    return { r, g, b, a };
  };

  groupWallsDataFromHistory = async () => {
    /// 1. Get saved reperage list in moduleData
    let reperageList = JSON.parse(
      JSON.stringify(this.props.moduleData.reperageData.buffer.reperage.list)
    );

    /// 2. Set state for wallsData, SolutionProducts
    this.setState({
      wallsData: reperageList,
      loading: false,
    });
  };

  processNewWallData = (preWallData: any, extractedSolutionProduct: any[]) => {
    return map(preWallData, (wallObject: any, index: number) => {
      const extractedSolutionProducts = find(
        extractedSolutionProduct,
        function (o: any) {
          return o.solutionProduct.oid === wallObject.PlacoSolutionId;
        }
      );

      let fetIsLayout: boolean = true,
        fetIsPlaco: boolean = true,
        plaqdef: string[] = [],
        mondef: string[] = [],
        plaqsArray: any[] = [],
        montantsArray: any[] = [];

      if (
        !wallObject.PlacoSolutionId.includes("custom") &&
        extractedSolutionProducts?.layoutPossible.toLowerCase() === "true"
      ) {
        fetIsLayout = true;
        fetIsPlaco = true;
      } else {
        fetIsLayout = false;
      }

      wallObject.plaque = plaqdef;
      wallObject.montant = mondef;

      wallObject.placo = fetIsPlaco;
      wallObject.plaqueArray = plaqsArray;
      wallObject.montantArray = montantsArray;

      if (wallObject.layoutPossible === undefined) {
        wallObject.layoutPossible = fetIsLayout;
      }

      if (wallObject.chk === undefined) {
        wallObject.chk = fetIsLayout && fetIsPlaco;
      }

      return wallObject;
    });
  };

  setCheckedWalls = async (id: any, value: boolean) => {
    //this.setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 5));

    let wallsDeepData = JSON.parse(JSON.stringify(this.state.wallsData));

    wallsDeepData[id].chk = value;

    await Promise.resolve(this.setState({ wallsData: wallsDeepData }));
  };

  // Toggle all rows
  setUnsetRows = async (checked: boolean) => {
    this.setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 100));

    let wallsDeepData = JSON.parse(JSON.stringify(this.state.wallsData));

    map(wallsDeepData, (val: any, ke: any) => {
      val.chk = checked;
      return val;
    });

    await Promise.resolve(
      this.setState({ wallsData: wallsDeepData, loading: false })
    );
  };

  defaultCheckedGroupCheckbox = () => {
    let defaultChecked = true;
    let allUnchecked = true;

    let allChk = filter(this.state.wallsData, { chk: false });
    defaultChecked = allChk.length === 0;

    allUnchecked = allChk.length === this.state.wallsData.length;

    return { defaultChecked, allUnchecked };
  };

  validateReperage = async () => {
    this.saveWallsData();
    // await this.createAndSetSystemParameter();
    // this.setTypeImageParameter();
    localStorage.setItem("isModification", "false");
  };

  saveWallsData = () => {
    const clearedWallsData = map(this.state.wallsData, (wld, ind) =>
      omit(wld, ["montantArray", "plaqueArray"])
    );

    this.props.setReperage({
      status: true,
      list: clearedWallsData,
      version: 1,
    });

    this.setState({ isValidated: true });
    // localStorage.removeItem("valueChanged");
    // localStorage.removeItem("valueDelete");

    //saveReperage(this.props.moduleData.reperageData.buffer);

    //this.props.setRoute(Routes.ROOT);
  };

  createAndSetSystemParameter = async () => {
    await api.familyEditor.createAndSetParameters("Wall", "Type", "DATA", [
      {
        Id: "0",
        Params: [{ Name: "Système", Type: "Text", Value: "" }],
      },
    ]);
  };

  setTypeImageParameter = async () => {
    const clearedWallsData = map(this.state.wallsData, (wld, ind) =>
      omit(wld, ["montantArray", "plaqueArray"])
    );

    let ParamsData: any = [];
    forEach(clearedWallsData, (value: any, index: number) => {
      let rgb = `${value.color.r},${value.color.g},${value.color.b}`;
      ParamsData.push({
        Name: value.WallType,
        Type: "Image",
        Value: rgb,
        TypeSolution: value.PlacoSolution,
      });
    });

    let Parameters = [
      {
        Id: "0",
        Params: ParamsData,
      },
    ];

    await api.queries.setScheduleImages(Parameters);
  };

  render() {
    const { defaultChecked, allUnchecked } = this.defaultCheckedGroupCheckbox();

    if (this.state.erasing || this.state.loading) {
      return (
        <Dimmer active={true}>
          <Loader content="Chargement en cours..."></Loader>
        </Dimmer>
      );
    }

    return (
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={this.props.Icon}
          header={this.props.wording.reperage[this.props.language]}
          subheader={
            this.props.wording.identificationTable[this.props.language]
          }
        />
        {/* MODAL FOR MAIN COLORPICKER */}
        {this.state.displayColorPicker && (
          <ColorPicker
            displayColorPicker={this.state.displayColorPicker}
            toggleModal={this.toggleModal}
            language={this.props.language}
            currentColor={this.state.color}
            colorChangeHandler={this.colorChangeHandler}
            config={this.props.config}
          ></ColorPicker>
        )}

        {/* COLORIZATION TABLE */}
        <Segment
          basic
          style={{ height: "calc(100vh - 30vh)", overflow: "auto" }}
        >
          <Table celled>
            <Table.Header>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell colSpan={1}>
                  {/* <Checkbox defaultChecked /> */}

                  <Checkbox
                    checked={defaultChecked}
                    onChange={(e, data) => {
                      this.setUnsetRows(!defaultChecked);
                      localStorage.setItem("isModification", "true");
                    }}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={3}>
                  {this.props.wording.systemType[this.props.language]}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={3}>
                  {this.props.wording.systemName[this.props.language]}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={3}>
                  {this.props.wording.solution[this.props.language]}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={2}>
                  {this.props.wording.colorChoice[this.props.language]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {map(this.state.wallsData, (walls: any, index: any) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell colSpan={1}>
                      {" "}
                      {/* <Checkbox defaultChecked /> */}
                      <Checkbox
                        checked={walls.chk}
                        onChange={(e, data) => {
                          //setChecked(!checked)
                          this.setCheckedWalls(index, !walls.chk);
                          localStorage.setItem("isModification", "true");
                        }}
                      //disabled={!walls.placo || !walls.layoutPossible}
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={3}>{walls.type}</Table.Cell>
                    <Table.Cell
                      colSpan={3}
                    >{`${walls.WallType} (${walls.Ids.length})`}</Table.Cell>
                    <Table.Cell colSpan={3}>{walls.PlacoSolution}</Table.Cell>
                    <Table.Cell colSpan={2} style={{ textAlign: "center" }}>
                      <div style={{ display: "inline-flex" }}>
                        <div className="swatch">
                          <div
                            //style={styles.color}
                            style={
                              walls.color && {
                                width: "36px",
                                height: "20px",
                                borderRadius: "2px",
                                background: `rgb(${walls.color.r}, ${walls.color.g}, ${walls.color.b})`,
                                //cursor: "pointer",
                              }
                            }
                          />
                        </div>
                        <div>
                          <Image
                            src={require("../Resources/Paintbucket.png")}
                            style={{
                              paddingLeft: "15px",
                              marginRight: "0px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.toggleModal(true);
                              this.setState({
                                color: walls.color,
                                index: index,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            position: "fixed",
            bottom: 15,
            width: "100%",
          }}
        >
          <Button
            color="orange"
            onClick={() => this.props.setRoute(Routes.ROOT)}
          >
            {this.props.wording.back[this.props.language]}
          </Button>
          <Button
            color="blue"
            disabled={allUnchecked}
            onClick={() => {
              this.validateReperage();
            }}
          >
            {this.props.wording.validate[this.props.language]}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    moduleData: state.moduleData,
    selections: state.selections,
    projectData: state.projectData,
    config: state.config,
  };
};

const mapDispatchToProps = {
  setReperage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentificationTable);
