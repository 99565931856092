import { Checkbox } from "@material-ui/core";
import _ from "lodash";
import React, { UIEvent, useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "semantic-ui-react";
import {
    setCurrentProjectCurrentItemCompatibleAndAvailableElements,
    setSelectedElementInItem,
} from "../../../../actions/projectsActions";
import { ElementType } from "../../../../assets/constants";
import Element from "../../../../models/Element.model";
import Item from "../../../../models/Item.model";
import StateModel from "../../../../models/State.model";
import SelectionCheckbox from "./selectionCheckbox/SelectionCheckbox";
import "./selectionComponent.scss";

function SelectionComponent({
    setSelectedElementInItem,
    setCurrentProjectCurrentItemCompatibleAndAvailableElements,
    handleValidateSelection,
    getElementName,
    filters,
    itemIndex,
    item,
    t,
}: IProps) {
    const [searchValue, setSearch] = useState("");

    const [nbrElementsToShow, setNbrElementsToShow] = useState(20);

    const [filteredElementsList, setFilteredElementsList] = useState<Element[]>(
        []
    );

    // useEffect(() => {
    //     setNbrElementsToShow(20);
    // }, [searchValue, filters]);

    const handleScroll = (e: UIEvent) => {
        const closeToBottom =
            e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                e.currentTarget.clientHeight ===
            0;
        if (closeToBottom) {
            setNbrElementsToShow(nbrElementsToShow + 20);
        }
    };

    const handleElementSelected = (value: boolean, element: Element) => {
        element.selected = value;
        setSelectedElementInItem(item.range.technicalName, element);
    };

    const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tmpItem = _.cloneDeep(item);
        tmpItem.compatibleAndAvailableElementsSanitized = tmpItem.compatibleAndAvailableElementsSanitized.map(
            (element) => {
                let found: boolean = false;
                filteredElementsList.forEach((filteredElement) => {
                    if (filteredElement.Id === element.Id) {
                        found = true;
                    }
                });
                if (found) {
                    element.selected = event.currentTarget.checked;
                }

                return element;
            }
        );

        tmpItem.compatibleAndAvailableElementsSanitized.forEach(
            (elementSanitized: Element) => {
                tmpItem.compatibleAndAvailableElements = tmpItem.compatibleAndAvailableElements.map(
                    (element: Element) => {
                        if (
                            getElementName(elementSanitized) ===
                            getElementName(element)
                        ) {
                            element.selected = elementSanitized.selected;
                        }
                        return element;
                    }
                );
            }
        );
        setCurrentProjectCurrentItemCompatibleAndAvailableElements(
            _.cloneDeep(tmpItem)
        );
    };

    const getElementTypeFilter = (element: Element) => {
        switch (element.Type) {
            case ElementType.PipeCurves:
                return "pipes";
            case ElementType.PipeFitting:
                return "fittings";
            case ElementType.PipeAccessory:
                return "accessories";
            default:
                return "";
        }
    };

    const filter2 = () => {
        let tmp = _.cloneDeep(
            item.compatibleAndAvailableElementsSanitized
        ).filter((element: Element) => {
            let found = false;

            if (
                Object.keys(filters.pipes).length === 0 &&
                Object.keys(filters.accessories).length === 0 &&
                Object.keys(filters.fittings).length === 0
            ) {
                found = true;
            } else {
                let elementTypeFilter: any = getElementTypeFilter(element);
                let angle: any = element.Angle ? `${element.Angle}` : "noAngle";

                if (
                    filters[elementTypeFilter][angle]?.find((el: any) => {
                        return el === element.Diameter;
                    }) !== undefined
                ) {
                    found = true;
                } else {
                    found = false;
                }
            }
            return found;
        });

        setFilteredElementsList(tmp);
    };

    useEffect(() => {
        filter2();
    }, [filters, nbrElementsToShow]);

    const search = () => {
        return filteredElementsList.filter((element: Element) => {
            return getElementName(element)
                .toLowerCase()
                .includes(searchValue.toLowerCase());
        });
    };

    return (
        <>
            <div
                className="row m-0"
                style={{
                    backgroundColor: "#505050",
                    height: "2.2em",
                }}
            >
                <div className="col-2">
                    <Checkbox
                        onChange={(event) => {
                            handleCheckAll(event);
                        }}
                    ></Checkbox>
                </div>
                <div className="col-10">
                    <input
                        id="search-input"
                        placeholder={`${t("SEARCH")}...`}
                        onChange={(e) => {
                            setSearch(e.currentTarget.value);
                        }}
                        value={searchValue}
                    />
                </div>
            </div>
            <div
                onScroll={handleScroll}
                style={{
                    maxHeight: "16.4em",
                    minHeight: "15em",
                    overflowY: "auto",
                }}
            >
                {search()
                    .splice(0, nbrElementsToShow)
                    .map((element: Element, elementIndex: number) => {
                        return (
                            <SelectionCheckbox
                                key={`element-range-selection-key-${itemIndex}-
                                    ${element.Id}`}
                                //     ${
                                //     element.SystemType
                                // }-${element.SystemName.split("")
                                //     .filter((e) => e.trim().length)
                                //     .join("")}-
                                element={element}
                                elementIndex={elementIndex}
                                handleElementSelected={handleElementSelected}
                                getElementName={getElementName}
                            ></SelectionCheckbox>
                        );
                    })}
            </div>
            <div
                style={{
                    maxHeight: "2.6em",
                    minHeight: "2.6em",
                    margin: ".3em 0",
                }}
            >
                <Button
                    id="range-selection-validate-selection-button"
                    variant="contained"
                    onClick={(event) => {
                        handleValidateSelection(item);
                    }}
                >
                    {t("VALIDATE")}
                </Button>
            </div>
        </>
    );
}

let mapStateToProps = (state: StateModel) => {
    return {
        userInfo: state.userInfo,
    };
};

let mapDispatchToProps = {
    setSelectedElementInItem,
    setCurrentProjectCurrentItemCompatibleAndAvailableElements,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux &
    WithTranslation & {
        filters: any;
        itemIndex: number;
        item: Item;
        getElementName: Function;
        handleValidateSelection: Function;
    };

type IState = {};

export default connector(withTranslation(["pam"])(SelectionComponent));
