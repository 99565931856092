import { PlacoOptions, RowOptions } from "../../../../RevitJS/Types/StoreTypes";
import { v4 } from "uuid";
import {
  ElementsTree,
  BuiltInCategory,
  ID,
} from "../../../../RevitJS/Types/RevitTypes";
import { AxiosInstance } from "axios";
import {
  ProductDetailData,
  AttributeDescriptor,
  LayoutAttributesAid,
  SolutionProduct,
  Product,
  Article,
  PimAttribute,
  BddLayer,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import {
  getSystemDetailsById,
  getPlacoSystemDetailsWithChildren,
} from "../Requests";
import {
  setFiltersAid,
  addFilterFields,
  setFiltersOptions,
} from "../../../../RevitJS/Helpers";
import { PlacoSystem } from "../Actions/objectTypes";
import _ from "lodash";
import { Selection } from "../Actions/types";

export function generateSelection<T extends RowOptions>(
  name: string,
  revitViewName: string
): Selection<RowOptions> {
  let date = new Date();
  return {
    Name: name,
    Id: v4(),
    Zone: false,
    Duplicated: 1,
    SelectionByType: {
      wall: {
        Type: "wall",
        Rows: [],
      },
      ceiling: {
        Type: "ceiling",
        Rows: [],
      },
      others: {
        Type: "others",
        Rows: [],
      },
    },
    Levels: [],
    Date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
    Update: false,
    Color: generateColor(),
    RevitView: revitViewName,
    Time: Date.now(),
  };
}

const generateColor = () => {
  let num = Math.round(0xffffff * Math.random());
  let r = num >> 16;
  let g = (num >> 8) & 255;
  let b = num & 255;
  let a = 1;
  return { r: r, g: g, b: b, a: a };
};

export const filterTree = (
  tree: ElementsTree,
  category: BuiltInCategory
): ElementsTree => {
  return {
    Tree: tree.Tree.map((elemsByLevelAndType) => {
      return {
        ...elemsByLevelAndType,
        Elements: elemsByLevelAndType.Elements.filter(
          (elemsByType) => elemsByType.ObjectType === category
        ),
      };
    }),
  };
};

export const clearTree = (tree: ElementsTree): ElementsTree => {
  return {
    Tree: tree.Tree.filter(
      (elemsByLevelAndType) => elemsByLevelAndType.Elements.length > 0
    ),
  };
};

// export const getSystemsDetailsAndFilters = async (
//   ids: ID[],
//   bdd: AxiosInstance,
//   filters: any[]
// ): Promise<{
//   systemsDetails: ProductDetailData[];
//   filters: any[];
//   attributes: any[];
// }> => {
//   let systemDetailsRequest = await getSystemDetailsById(ids, bdd);

//   let systemsDetails = systemDetailsRequest.data.objects.map((sys) => {
//     return { ...sys, filterFields: {} } as unknown;
//   }) as ProductDetailData[];
//   let attributes =
//     systemDetailsRequest.data.context.attributeDependencies[0].attributes;
//   filters = setFiltersAid(filters, attributes);

//   systemsDetails = addFilterFields(systemsDetails, filters);
//   filters = setFiltersOptions(filters, systemsDetails);

//   return {
//     systemsDetails,
//     filters,
//     attributes,
//   };
// };

export const getSystemsDetailsAndFilters = async (
  data: ProductMeta[],
  dataDetail: ProductDetailData[],
  filters: any[]
): Promise<PlacoSystem> => {
  let systemsDetails: ProductDetailData[] = [];
  let attributes: any[] = [];
  if (dataDetail.length > 0) {
    attributes = dataDetail[0].context;

    systemsDetails = dataDetail.map((sys) => {
      return { ...sys, filterFields: {} } as unknown;
    }) as ProductDetailData[];

    filters = setFiltersAid(filters, attributes);

    systemsDetails = addFilterFields(systemsDetails, filters);
    filters = setFiltersOptions(filters, systemsDetails);
    systemsDetails = systemsDetails.map(
      (sys: ProductDetailData, index: number) => {
        return {
          ...sys,
          thumbnailUrl: data[index].thumbnailUrl,
        };
      }
    );
  }

  return {
    systemsDetails,
    filters,
    attributes,
  };
};
////

export const detailedRequestHandler = async (
  oid: ID,
  bdd: any
): Promise<{
  Objects: ProductDetailData[];
  AttributeDescriptors: AttributeDescriptor[];
}> => {
  const detailRquiered = await getPlacoSystemDetailsWithChildren(bdd, oid).then(
    (response: any) => {
      return {
        Objects: response.data.objects,
        AttributeDescriptors:
          response.data.context.attributeDependencies[0].attributes,
      };
    }
  );
  return detailRquiered;
};

export const findAttributeAid = (
  attributeDescriptors: AttributeDescriptor[],
  attributeName: string
) => {
  return attributeDescriptors.find(
    (attr) => attr.technicalName === attributeName
  )?.aid;
};

export const attributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): LayoutAttributesAid => {
  return {
    layers: findAttributeAid(attributeDescriptors, "GFR-BIM layers") as string,
    layerName: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer name"
    ) as string,
    layerColor: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer color"
    ) as string,
    layerContent: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer content"
    ) as string,
    layerThickness: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer thickness mm"
    ) as string,
    frameSpace: findAttributeAid(
      attributeDescriptors,
      "GFR-Distance between frames (in m)"
    ) as string,
    doubleFraming: findAttributeAid(
      attributeDescriptors,
      "GFR-Profiles (1 or 2)"
    ) as string,
    solutionName: findAttributeAid(
      attributeDescriptors,
      "A-Solution product name"
    ) as string,
    layerNumber: findAttributeAid(
      attributeDescriptors,
      "GFR-Layer number"
    ) as string,
    articles: findAttributeAid(attributeDescriptors, "GFR-Articles") as string,
    categorypath: findAttributeAid(
      attributeDescriptors,
      "RS-CategoryPath"
    ) as string,
    packagedArticles: findAttributeAid(
      attributeDescriptors,
      "GFR-Packaged articles"
    ) as string,
  };
};

export const objectsSorter = (
  objects: ProductDetailData[]
): {
  solutionsProducts: ProductDetailData[];
  products: ProductDetailData[];
  articles: ProductDetailData[];
} => {
  return {
    solutionsProducts: objects.filter(
      (obj) => obj.types[0] === "Solution Product"
    ),
    products: objects.filter((obj) => obj.types[0] === "Product"),
    articles: objects.filter((obj) => obj.types[0] === "Article"),
  };
};

export const attributesFilterer = (
  attributes: PimAttribute[],
  attrDict: LayoutAttributesAid
): PimAttribute[] => {
  return attributes.filter((attribute) => attribute.aid === attrDict.layers);
};
// export const solutionProduct = ()

export const layersSorter = (
  layersAttributes: PimAttribute[],
  attrDict: LayoutAttributesAid
) => {
  return {
    claddings: layersAttributes.filter((attr) =>
      attr.subAttributes
        .find((subAttr) => subAttr.aid === attrDict.layerContent)
        ?.values[0].value.startsWith("Plaque")
    ),
  };
};

export const claddingBuilder = (
  claddingAttributes: PimAttribute[],
  attrDict: LayoutAttributesAid
): BddLayer[] => {
  return claddingAttributes.map((claddAttr) => {
    return {
      layerName: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerName
      )?.values[0].value,
      layerContent: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerContent
      )?.values[0].value,
      layerColor: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerColor
      )?.values[0].value,
      layerThickness: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerThickness
      )?.values[0].numericValue,
      layerNumber: claddAttr.subAttributes.find(
        (attr) => attr.aid === attrDict.layerNumber
      )?.values[0].numericValue,
    };
  });
};

export const setLayerProduct = (
  bddLayer: BddLayer,
  products: ProductDetailData[]
): BddLayer => {
  return {
    ...bddLayer,
    product: products.find(
      (product) => product.translation === bddLayer.layerName
    ) as ProductDetailData,
  };
};

export const getProductArticleNames = (
  product: ProductDetailData,
  attrDict: LayoutAttributesAid
): string[] => {
  return product.attributes
    .find((attr) => attr.aid === attrDict.articles)!
    .values.map((value) => value.value);
};

export const setLayerArticles = (
  bddLayer: BddLayer,
  articleNames: string[],
  articles: ProductDetailData[]
): BddLayer => {
  return {
    ...bddLayer,
    articles: articles.filter((article) =>
      articleNames.includes(article.translation)
    ),
  };
};

export const bddLayersProcessor = (
  layers: BddLayer[],
  attrDict: LayoutAttributesAid,
  products: ProductDetailData[],
  articles: ProductDetailData[]
): BddLayer[] => {
  return layers.map((layer) => {
    const { product } = setLayerProduct(layer, products);
    if (product) {
      return {
        ...layer,
        product,
        articles: setLayerArticles(
          layer,
          getProductArticleNames(product, attrDict),
          articles
        ).articles,
      };
    } else {
      return layer;
    }
  });
};

export const bundleFileDescriptors = (
  solutionName: any,
  fileDescriptors: any
) => {
  let downloadObj: any = {};
  let downloadArr: any = [];
  let expectedFilesList = [
    {
      fileType: "System Datasheet",
      contentType: "application/pdf",
      extension: "pdf",
    },
    {
      fileType: "RVT BIM object",
      contentType: "application/octet",
      extension: "rvt",
    },
    {
      fileType: "IFC BIM object",
      contentType: "application/octet",
      extension: "ifc",
    },
    {
      fileType: "System Diagram",
      contentType: "application/pdf",
      extension: "pdf",
    },
  ];
  if (fileDescriptors !== undefined) {
    expectedFilesList.forEach((fl) => {
      let isPresent = false;
      let fileData = fileDescriptors.find((files: any) => {
        return files.fileCategoryId.toLowerCase() === fl.fileType.toLowerCase();
      });
      if (fileData !== undefined) {
        isPresent = true;
      } else {
        isPresent = false;
      }
      downloadObj = {
        solution: solutionName,
        descriptorId:
          fileData !== undefined ? fileData.descriptor.descriptorId : null,
        fileName: fileData !== undefined ? fileData.descriptor.name : null,
        fileExtension: fl.extension,
        fileType: fl.fileType,
        contentType: fl.contentType,
        isPresent: isPresent,
      };
      downloadArr.push(downloadObj);
    });
  }
  return downloadArr;
};

export const getSystemsDetails = async (ids: ID[], bdd: AxiosInstance) => {
  let systemDetailsRequest = await getSystemDetailsById(ids, bdd);
  return systemDetailsRequest;
};

/***
 *
 */

export {
  sortPlacoDataByGFRWorksName,
  getLayoutAttributesAid,
} from "./placoHelper";
