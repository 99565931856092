import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Routes } from "./root";
import { fetchRemoteData, loadSelections } from "../../Selection/Actions";
import Axios from "axios";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import { Selection } from "../../Selection/Actions/types";
import { bimStorage, storageKey } from "../../../../BIMStore";
import _ from "lodash";
import { SuppressionHome } from "./SuppressionHome";
import SelectionRoot from "../../Selection/Components/root";
import { api } from "../../../../RevitJS/API";
import GroupSelector from "./GroupSelector";
import { initLanguage } from "../Actions";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};

const mapDispatchToProps = {
  fetchRemoteData,
  loadSelections,
  initLanguage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  route: string;
  setRoute: any;
  initLanguage: any;
  onError: any;
};

export class AppBody extends Component<Props> {
  state = {
    selectionID: [],
  };

  componentDidMount = async () => {
    // api.windowsHandler.showDevTools();
    this.props.initLanguage();

    let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
    if (placoSelections) {
      let selections = placoSelections as {
        [key: string]: Selection<PlacoOptions>;
      };
      this.props.loadSelections(selections);
    }
  };

  setSelectionId = (Id: string) => {
    if (_.includes(this.state.selectionID, Id)) {
      this.setState({
        selectionID: [..._.without(this.state.selectionID, Id)],
      });
    } else {
      this.setState({ selectionID: [...this.state.selectionID, Id] });
    }
  };

  render() {
    const { route, setRoute } = this.props;
    switch (route) {
      case Routes.ROOT:
        //api.windowsHandler.resizeWindow(900, 507);
        return (
          <SuppressionHome setRoute={setRoute} onError={this.props.onError} />
        );
      case Routes.SELECTION_HOME:
        //api.windowsHandler.resizeWindow(900, 507);
        return <SelectionRoot module="selection" />;
      case Routes.GROUP_SELECTION:
        //api.windowsHandler.resizeWindow(900, 550);
        return <GroupSelector setRoute={setRoute} module={module} />;
      default:
        return null;
    }
  }
}

export default connector(AppBody);
