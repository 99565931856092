import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Language } from "../../CommonModules/Selection/Type";

interface Props {
  setRoute: any;
  routes: any;
  applicationLanguage: Language;
  onAction: any;
}

export const RapidSelectionButton = (props: Props) => {
  return (
    <Grid columns={1} stackable style={{ ...style.editSelectionSegment }}>
      <Grid.Column style={{ padingBottom: "0px" }}>
        <div
          style={{
            padding: 5,
            cursor: "pointer",
            minWidth: "0px",
            float: "left",
          }}
          onClick={() => props.onAction()}
        >
          <Icon name="location arrow" color="blue" size="big" style={{ fontSize: "1.5em"}}/>
          <span style={{ color: "rgb(14,110,184)" }}>
            {wording.rapidSelection[props.applicationLanguage]}
          </span>
        </div>
      </Grid.Column>
    </Grid>
  );
};

const wording: WordingType = {
  rapidSelection: {
    French: "Sélection rapide",
    English: "Selection rapid",
  },
};

type WordingType = {
  rapidSelection: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};
