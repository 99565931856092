import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import AppBody from "./AppBody";

interface Props { }

export enum Routes {
    ROOT = "TestLanding",
}

export const dbStoreNameReperage = "Test"

interface State {
    route: Routes;
}

export class GenericTestRoot extends Component<Props, State> {
    state = {
        route: Routes.ROOT,
    };

    constructor(props: Props) {
        super(props);
    }

    setRoute = (route: Routes) => {
        this.setState({
            route,
        });
    };

    componentDidMount = () => {
        const queryString = window.location.hash;
        const urlParams = new URLSearchParams(queryString.split("?")[1]);
        // if (urlParams.get("token")) {
        //     setPluginDataToDB("token", urlParams.get("token")!);
        // }
        // if (urlParams.get("language")) {
        //     setPluginDataToDB("savedLanguage", urlParams.get("language")!);
        // }
    };

    render = () => {
        return (
            <AppBody route={this.state.route} setRoute={this.setRoute} />
        );
    };
}