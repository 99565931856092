import { AnyAction } from "redux";
import { RootState } from "./initialState";

export default function preProcessApiActions(
    state = RootState.app,
    action: AnyAction
) {
    if (action?.type?.includes("STARTED")) {
        return { ...state, isLoading: true };
    } else if (
        action?.type?.includes("SUCCESSFULL") ||
        action?.type?.includes("ERROR")
    ) {
        return { ...state, isLoading: false };
    }

    return state;
}
