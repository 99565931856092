import { AppWindowName } from "../assets/constants";
import {
  ADD_CSV_FILE,
  SELECT_FILE,
  SELECT_OPTIONAL_PROPERTIES,
} from "./actionTypes";

export function addCsvFile(csvList: { file: File; data: any }) {
  return {
    type: ADD_CSV_FILE,
    payload: csvList,
  };
}

export function selectFile(selectedFile: { file: File; data: any }) {
  return {
    type: SELECT_FILE,
    payload: selectedFile,
  };
}

export function selectOptionalProperties(property: any, checked: boolean) {
  return {
    type: SELECT_OPTIONAL_PROPERTIES,
    property,
    checked
  };
}
