import React from "react";

export const IframeTest = () => {
  return (
    <iframe
      width="90%"
      height="80%"
      src="https://bimlibrary.saint-gobain.com/en/France/embed/ecophon"
      sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
    ></iframe>
  );
};
