export const Standard = "Standard";
export const ConcealedGrid = "Concealed Grid";
export const CorridorMainMenu = "Corridor";
export const Corridor = "Corridor";
export const HygieneConcealedGrid = "Hygiene Concealed Grid";
export const Hygiene = "Hygiene";

export const A = "A";
export const E = "E";
export const Dg = "Dg";
export const edgeT24 = "Edge A T24";

export const HygieneEdgeA20 = "Hygiene Edge A (20 mm)";
export const HygieneEdgeA40 = "Hygiene Edge A (40 mm)";

export const EdgeAT24 = "Edge A T24";
export const EdgeAT15 = "Edge A T15";
export const EdgeET24 = "Edge E T24";
export const EdgeET15 = "Edge E T15";
export const EdgeDg = "Edge Dg";
export const EdgeLp = "Edge Lp";
export const EdgeDs = "Edge Ds";
export const HygieneEdgeDsC4 = "Edge Ds (C4)";
export const HygieneEdgeDsCleanRoom = "Edge Ds (Clean Room)";
export const CorridorEdgeDs = "Corridor Edge Ds";
export const CorridorEdgeA = "Corridor Edge A";
export const CorridorEdgeDA = "Corridor Edge DA";
export const CorridorEdgeE = "Corridor Edge E";

export const config_600 = "600";
export const config_300 = "300";
export const config_600x150 = "600x150";
export const config_600x300 = "600x300";
export const config_600x600 = "600x600";
export const config_1200x150 = "1200x150";
export const config_1200x300 = "1200x300";
export const config_1200x600 = "1200x600";
export const config_600x1200 = "600x1200";
export const config_1200x1200 = "1200x1200";
export const config_1600x600 = "1600x600";
export const config_1800x600 = "1800x600";
export const config_2000x600 = "2000x600";
export const config_2400x600 = "2400x600";

export const connect_t15_1200 = "Connect T15 Cross tee 1200 mm";
export const connect_t15_600 = "Connect T15 Cross tee 600 mm";
export const porteurs = "Porteur Connect T15 3700";

export const connect_t15_main_runner = "Connect T15 Main runner";
export const connect_t24_main_runner = "Connect T24 Main runner";
export const connect_t24_main_runnerhd = "Connect T24 Main runner HD";
export const connect_t24_main_runnerc3c4 = "Connect T24 Main runner C3/C4";
export const connect_t24_main_runnerc4 = "Connect T24 Main runner C4";
export const connect_t24_medium_runner = "Connect T24 Cross tee 1200 mm";
export const connect_t24_short_runner = "Connect T24 Cross tee 600 mm";
export const space_bar = "Connect Space Bar";
export const space_bar_c4 = "Connect Space Bar C4";
export const angle_trim_15_22 = "Connect Angle trim 15/22";
export const angle_trim_22_22 = "Connect Angle trim 22/22";
export const wall_bracket_1 = "Connect Wall Braket for Main runner";
export const wall_bracket_2 = "Connect Wall Bracket for Space bar";
export const wall_bracket_1_c4 = "Connect Wall Braket for Main runner C4";
export const wall_bracket_2_c4 = "Connect Wall Bracket for Space bar C4";

//Products
export const Ecophon_Focus_A20_T24_600x600 = "Ecophon Focus™ A20 T24 600x600";
export const Ecophon_Master_A40_T24_600x600 = "Ecophon Master™ A40 T24 600x600";
export const Ecophon_Gedina_A15_T24_600x600 = "Ecophon Gedina™ A15 T24 600x600";
export const Ecophon_Advantage_A15_T24_600x600 =  "Ecophon Advantage™ A15 T24 600x600";
export const Ecophon_Advantage_A20_T24_600x600 =  "Ecophon Advantage™ A20 T24 600x600";
export const Ecophon_Advantage_A40_T24_600x600 =  "Ecophon Advantage™ A40 T24 600x600";
export const Ecophon_Hygiene_Clinic_A15_T24_600x600 =  "Ecophon Hygiene Clinic™ A15 T24 600x600";
export const Ecophon_Combison_Uno_A35_T24_600x600 =  "Ecophon Combison™ Uno A35 T24 600x600";
export const Ecophon_Combison_Duo_A55_T24_600x600 =  "Ecophon Combison™ Duo A55 T24 600x600";
export const Ecophon_Combison_A50_dB42_T24_600x600 =  "Ecophon Combison™ A50 dB42 T24 600x600";
export const Ecophon_Focus_A20_T24_1200x600 = "Ecophon Focus™ A20 T24 1200x600";
export const Ecophon_Focus_A20_T24_600x1200 = "Ecophon Focus™ A20 T24 600x1200";
export const Ecophon_Master_A40_T24_1200x600 =  "Ecophon Master™ A40 T24 1200x600";
export const Ecophon_Gedina_A15_T24_1200x600 =  "Ecophon Gedina™ A15 T24 1200x600";
export const Ecophon_Advantage_A15_T24_1200x600 =  "Ecophon Advantage™ A15 T24 1200x600";
export const Ecophon_Advantage_A20_T24_1200x600 =  "Ecophon Advantage™ A20 T24 1200x600";
export const Ecophon_Advantage_A40_T24_1200x600 =  "Ecophon Advantage™ A40 T24 1200x600";
export const Ecophon_Combison_A50_dB42_T24_1200x600 =  "Ecophon Combison™ A50 dB42 T24 1200x600";
export const Ecophon_Combison_Uno_A35_T24_1200x600 =  "Ecophon Combison™ Uno A35 T24 1200x600";
//export const Ecophon_Advantage_A15_T24_1200x600 = "Ecophon Advantage™ A15 T24 1200x600"
//export const Ecophon_Advantage_A40_T24_1200x600 = "Ecophon Advantage™ A40 T24 1200x600"
export const Ecophon_Focus_A20_T24_1200x1200 =  "Ecophon Focus™ A20 T24 1200x1200";
export const Ecophon_Master_A20_T24_1200x1200 =  "Ecophon Master™ A20 T24 1200x1200";
export const Ecophon_Focus_A20_T24_1600x600 = "Ecophon Focus™ A20 T24 1600x600";
export const Ecophon_Focus_A20_T24_1800x600 = "Ecophon Focus™ A20 T24 1800x600";
export const Ecophon_Advantage_A20_T24_1800x600 =  "Ecophon Advantage™ A20 T24 1800x600";
export const Ecophon_Focus_A20_T24_2000x600 = "Ecophon Focus™ A20 T24 2000x600";
export const Ecophon_Focus_A20_T24_2400x600 = "Ecophon Focus™ A20 T24 2400x600";
export const Ecophon_Focus_A20_T15_600x600 = "Ecophon Focus™ A20 T15 600x600";
export const Ecophon_Master_A40_T15_600x600 = "Ecophon Master™ A40 T15 600x600";
export const Ecophon_Gedina_A15_T15_600x600 = "Ecophon Gedina™ A15 T15 600x600";
export const Ecophon_Advantage_A20_T15_600x600 =  "Ecophon Advantage™ A20 T15 600x600";
export const Ecophon_Advantage_A15_T15_600x600 =  "Ecophon Advantage™ A15 T15 600x600";
export const Ecophon_Advantage_A40_T15_600x600 =  "Ecophon Advantage™ A40 T15 600x600";
export const Ecophon_Focus_A20_T15_1200x600 = "Ecophon Focus™ A20 T15 1200x600";
export const Ecophon_Focus_A20_T15_600x1200 = "Ecophon Focus™ A20 T15 600x1200";
export const Ecophon_Master_A40_T15_1200x600 =  "Ecophon Master™ A40 T15 1200x600";
export const Ecophon_Gedina_A15_T15_1200x600 =  "Ecophon Gedina™ A15 T15 1200x600";
export const Ecophon_Advantage_A20_T15_1200x600 =  "Ecophon Advantage™ A20 T15 1200x600";
export const Ecophon_Advantage_A15_T15_1200x600 =  "Ecophon Advantage™ A15 T15 1200x600";
export const Ecophon_Advantage_A40_T15_1200x600 =  "Ecophon Advantage™ A40 T15 1200x600";
export const Ecophon_Focus_A20_T15_1200x1200 =  "Ecophon Focus™ A20 T15 1200x1200";
export const Ecophon_Master_A40_T15_1200x1200 =  "Ecophon Master™ A40 T15 1200x1200";
export const Ecophon_Focus_E20_T24_600x600 = "Ecophon Focus™ E20 T24 600x600";
export const Ecophon_Master_E40_T24_600x600 = "Ecophon Master™ E40 T24 600x600";
export const Ecophon_Master_Eg40_T24_600x600 =  "Ecophon Master™ Eg40 T24 600x600";
export const Ecophon_Hygiene_Clinic_E15_T24_600x600 =  "Ecophon Hygiene Clinic™ E15 T24 600x600";
export const Ecophon_Advantage_E150_T24_600x600 =  "Ecophon Advantage™ E150 T24 600x600";
export const Ecophon_Advantage_E20_T24_600x600 =  "Ecophon Advantage™ E20 T24 600x600";
export const Ecophon_Gedina_E15_T24_600x600 = "Ecophon Gedina™ E15 T24 600x600";
export const Ecophon_Combison_Duo_E55_T24_600x600 =  "Ecophon Combison™ Duo E55 T24 600x600";
export const Ecophon_Focus_Ez40_T24_600x600 = "Ecophon Focus™ Ez40 T24 600x600";
export const Ecophon_Focus_E20_T24_1200x600 = "Ecophon Focus™ E20 T24 1200x600";
export const Ecophon_Focus_E20_T24_600x1200 = "Ecophon Focus™ E20 T24 600x1200";
export const Ecophon_Master_E40_T24_1200x600 =  "Ecophon Master™ E40 T24 1200x600";
export const Ecophon_Master_Eg40_T24_1200x600 =  "Ecophon Master™ Eg40 T24 1200x600";
export const Ecophon_Hygiene_Clinic_E15_T24_1200x600 =  "Ecophon Hygiene Clinic™ E15 T24 1200x600";
export const Ecophon_Gedina_E15_T24_1200x600 =  "Ecophon Gedina™ E15 T24 1200x600";
export const Ecophon_Advantage_E20_T24_1200x600 =  "Ecophon Advantage™ E20 T24 1200x600";
export const Ecophon_Focus_E20_T24_1200x1200 =  "Ecophon Focus™ E20 T24 1200x1200";
export const Ecophon_Master_E40_T24_1200x1200 =  "Ecophon Master™ E40 T24 1200x1200";
export const Ecophon_Master_Eg40_T24_1200x1200 =  "Ecophon Master™ Eg40 T24 1200x1200";
export const Ecophon_Focus_E20_T24_1600x600 = "Ecophon Focus™ E20 T24 1600x600";
export const Ecophon_Focus_E20_T24_1800x600 = "Ecophon Focus™ E20 T24 1800x600";
export const Ecophon_Focus_E20_T24_2000x600 = "Ecophon Focus™ E20 T24 2000x600";
export const Ecophon_Focus_E20_T24_2400x600 = "Ecophon Focus™ E20 T24 2400x600";
export const Ecophon_Master_Eg40_T24_2400x600 =  "Ecophon Master™ Eg40 T24 2400x600";
export const Ecophon_Focus_E20_T15_600x600 = "Ecophon Focus™ E20 T15 600x600";
export const Ecophon_Gedina_E15_T15_600x600 = "Ecophon Gedina™ E15 T15 600x600";
export const Ecophon_Advantage_E15_T15_600x600 =  "Ecophon Advantage™ E15 T15 600x600 ";
export const Ecophon_Advantage_E20_T15_600x600 =  "Ecophon Advantage™ E20 T15 600x600 ";
export const Ecophon_Focus_E20_T15_1200x600 = "Ecophon Focus™ E20 T15 1200x600";
export const Ecophon_Focus_E20_T15_600x1200 = "Ecophon Focus™ E20 T15 600x1200";
export const Ecophon_Gedina_E15_T15_1200x600 =  "Ecophon Gedina™ E15 T15 1200x600";
export const Ecophon_Advantage_E15_T15_1200x600 =  "Ecophon Advantage™ E15 T15 1200x600";
export const Ecophon_Advantage_E20_T15_1200x600 =  "Ecophon Advantage™ E20 T15 1200x600";
export const Ecophon_Focus_E20_T15_1200x1200 =  "Ecophon Focus™ E20 T15 1200x1200";
export const Ecophon_Master_E40_T15_1200x1200 =  "Ecophon Master™ E40 T15 1200x1200";
export const Ecophon_Focus_Dg20_T24_600x600 = "Ecophon Focus™ Dg20 T24 600x600";
export const Ecophon_Focus_Dg20_T24_1200x600 =  "Ecophon Focus™ Dg20 T24 1200x600";
export const Ecophon_Focus_Dg25_T24_1200x1200 =  "Ecophon Focus™ Dg25 T24 1200x1200";
export const Ecophon_Focus_Dg20_T24_1600x600 =  "Ecophon Focus™ Dg20 T24 1600x600";
export const Ecophon_Focus_Dg20_T24_1800x600 =  "Ecophon Focus™ Dg20 T24 1800x600";
export const Ecophon_Focus_Dg20_T24_2000x600 =  "Ecophon Focus™ Dg20 T24 2000x600";
export const Ecophon_Focus_Dg20_T24_2400x600 =  "Ecophon Focus™ Dg20 T24 2400x600";
export const Ecophon_Focus_Lp20_T24_600x150 = "Ecophon Focus™ Lp20 T24 600x150";
export const Ecophon_Focus_Lp20_T24_600x300 = "Ecophon Focus™ Lp20 T24 600x300";
export const Ecophon_Focus_Lp20_T24_600x600 = "Ecophon Focus™ Lp20 T24 600x600";
export const Ecophon_Focus_Lp20_T24_1200x150 =  "Ecophon Focus™ Lp20 T24 1200x150";
export const Ecophon_Focus_Lp20_T24_1200x300 =  "Ecophon Focus™ Lp20 T24 1200x300";
export const Ecophon_Focus_Lp20_T24_1200x600 =  "Ecophon Focus™ Lp20 T24 1200x600";
export const Ecophon_Focus_Lp20_T24_600x1200 =  "Ecophon Focus™ Lp20 T24 600x1200";
export const Ecophon_Focus_Lp20_T24_1800x600 =  "Ecophon Focus™ Lp20 T24 1800x600";
export const Ecophon_Focus_Ds20_T24_600x600 = "Ecophon Focus™ Ds20 T24 600x600";
export const Ecophon_Master_Ds40_T24_600x600 =  "Ecophon Master™ Ds40 T24 600x600";
export const Ecophon_Combison_Uno_Ds35_T24_600x600 =  "Ecophon Combison™ Uno Ds35 T24 600x600";
export const Ecophon_Focus_Ds20_T24_1200x600 =  "Ecophon Focus™ Ds20 T24 1200x600";
export const Ecophon_Focus_Ds20_T24_1200x1200 =  "Ecophon Focus™ Ds20 T24 1200x1200";
export const Ecophon_Focus_Ds20_T24_1600x600 =  "Ecophon Focus™ Ds20 T24 1600x600";
export const Ecophon_Focus_Ds20_T24_1800x600 =  "Ecophon Focus™ Ds20 T24 1800x600";
export const Ecophon_Focus_Ds20_T24_2000x600 =  "Ecophon Focus™ Ds20 T24 2000x600";
export const Ecophon_Focus_Ds20_T24_2400x600 =  "Ecophon Focus™ Ds20 T24 2400x600";
export const Ecophon_Focus_Ds20_T24_1250_2400x300 =  "Ecophon Focus™ Ds20 T24 1250-2400x300";
export const Ecophon_Focus_Ds20_T24_1250_2400x600 =  "Ecophon Focus™ Ds20 T24 1250-2400x600";
export const Ecophon_Focus_DA20_T24_1250_2400x300 =  "Ecophon Focus™ DA20 T24 1250-2400x300";
export const Ecophon_Gedina_DA15_T24_1250_2400x300 =  "Ecophon Gedina™ DA15 T24 1250-2400x300";
export const Ecophon_Focus_DA20_T24_1250_2400x600 =  "Ecophon Focus™ DA20 T24 1250-2400x600";
export const Ecophon_Gedina_DA15_T24_1250_2400x600 =  "Ecophon Gedina™ DA15 T24 1250-2400x600";
export const Ecophon_Focus_A20_T24_1250_2400x300 =  "Ecophon Focus™ A20 T24 1250-2400x300";
export const Ecophon_Gedina_A15_T24_1250_2400x300 =  "Ecophon Gedina™ A15 T24 1250-2400x300";
export const Ecophon_Advantage_A15_T24_1250_2400x300 =  "Ecophon Advantage™ A15 T24 1250-2400x300";
export const Ecophon_Advantage_A20_T24_1250_2400x300 =  "Ecophon Advantage™ A20 T24 1250-2400x300";
export const Ecophon_Focus_A20_T24_1250_2400x600 =  "Ecophon Focus™ A20 T24 1250-2400x600";
export const Ecophon_Gedina_A15_T24_1250_2400x600 =  "Ecophon Gedina™ A15 T24 1250-2400x600";
export const Ecophon_Advantage_A15_T24_1250_2400x600 =  "Ecophon Advantage™ A15 T24 1250-2400x600";
export const Ecophon_Advantage_A20_T24_1250_2400x600 =  "Ecophon Advantage™ A20 T24 1250-2400x600";
export const Ecophon_Focus_E20_T24_1250_2400x300 =  "Ecophon Focus™ E20 T24 1250-2400x300";
export const Ecophon_Focus_E20_T24_1250_2400x600 =  "Ecophon Focus™ E20 T24 1250-2400x600";
//export const Ecophon_Hygiene_Clinic_A15_T24_600x600 = "Ecophon Hygiene Clinic™ A15 T24 600x600"
export const Ecophon_Hygiene_Meditec_A15_T24_600x600 =  "Ecophon Hygiene Meditec™ A15 T24 600x600";
//export const Ecophon_Hygiene_Clinic_A15_T24_600x600 = "Ecophon Hygiene Clinic™ A15 T24 600x600"
export const Ecophon_Hygiene_Protec_A20_T24_600x600 =  "Ecophon Hygiene Protec™ A20 T24 600x600";
export const Ecophon_Hygiene_ProtecAir_A20_T24_600x600 =  "Ecophon Hygiene Protec™Air A20 T24 600x600";
export const Ecophon_Hygiene_Performance_A20_T24_600x600 =  "Ecophon Hygiene Performance™ A20 T24 600x600";
export const Ecophon_Hygiene_Performance_Plus_A20_T24_600x600 =  "Ecophon Hygiene Performance™ Plus A20 T24 600x600";
export const Ecophon_Hygiene_Advance_A20_T24_600x600 =  "Ecophon Hygiene Advance™ A20 T24 600x600";
export const Ecophon_Hygiene_Clinic_A15_T24_1200x600 =  "Ecophon Hygiene Clinic™ A15 T24 1200x600";
export const Ecophon_Hygiene_Meditec_A15_T24_1200x600 =  "Ecophon Hygiene Meditec™ A15 T24 1200x600";
export const Ecophon_Hygiene_Protec_A20_T24_1200x600 =  "Ecophon Hygiene Protec™ A20 T24 1200x600";
export const Ecophon_Hygiene_ProtecAir_A20_T24_1200x600 =  "Ecophon Hygiene Protec™Air A20 T24 1200x600";
export const Ecophon_Hygiene_Performance_A20_T24_1200x600 =  "Ecophon Hygiene Performance™ A20 T24 1200x600";
export const Ecophon_Hygiene_Performance_Plus_A20_T24_1200x600 =  "Ecophon Hygiene Performance™ Plus A20 T24 1200x600";
export const Ecophon_Hygiene_Advance_A20_T24_1200x600 =  "Ecophon Hygiene Advance™ A20 T24 1200x600";
export const Ecophon_Hygiene_ProtecAir_A40_T24_600x600 =  "Ecophon Hygiene Protec™Air A40 T24 600x600";
export const Ecophon_Hygiene_Performance_A40_T24_600x600 =  "Ecophon Hygiene Performance™ A40 T24 600x600";
export const Ecophon_Hygiene_Performance_Plus_A40_T24_600x600 =  "Ecophon Hygiene Performance™ Plus A40 T24 600x600";
export const Ecophon_Hygiene_Advance_A40_T24_600x600 =  "Ecophon Hygiene Advance™ A40 T24 600x600";
export const Ecophon_Hygiene_ProtecAir_A40_T24_1200x600 =  "Ecophon Hygiene Protec™Air A40 T24 1200x600";
export const Ecophon_Hygiene_Performance_A40_T24_1200x600 =  "Ecophon Hygiene Performance™ A40 T24 1200x600";
export const Ecophon_Hygiene_Performance_Plus_A40_T24_1200x600 =  "Ecophon Hygiene Performance™ Plus A40 T24 1200x600";
export const Ecophon_Hygiene_Advance_A40_T24_1200x600 =  "Ecophon Hygiene Advance™ A40 T24 1200x600";
export const Ecophon_Hygiene_Performance_Ds20_T24_600x600 =  "Ecophon Hygiene Performance™ Ds20 T24 600x600";
export const Ecophon_Hygiene_Performance_Ds20_T24_1200x600 =  "Ecophon Hygiene Performance™ Ds20 T24 1200x600";
export const Ecophon_Hygiene_Protec_Ds20_600x600 =  "Ecophon Hygiene Protec™ Ds20 600x600";
export const Ecophon_Hygiene_Protec_Ds20_1200x600 =  "Ecophon Hygiene Protec™ Ds20 1200x600";

export const dbFaker = {
  EcophonSystems: {
    [Standard]: {
      id: Standard,
    },
    [ConcealedGrid]: {
      id: ConcealedGrid,
    },
    [Hygiene]: {
      id: Hygiene,
    },
    [HygieneConcealedGrid]: {
      id: HygieneConcealedGrid,
    },
    [CorridorMainMenu]: {
      id: CorridorMainMenu,
    },
    [Corridor]: {
      id: Corridor,
    },
  },
  Ranges: {
    [EdgeAT24]: {
      id: EdgeAT24,
      parentSystem: Standard,
    },
    [EdgeAT15]: {
      id: EdgeAT15,
      parentSystem: Standard,
    },
    [EdgeET24]: {
      id: EdgeET24,
      parentSystem: Standard,
    },
    [EdgeET15]: {
      id: EdgeET15,
      parentSystem: Standard,
    },
    [EdgeDg]: {
      id: EdgeDg,
      parentSystem: Standard,
    },
    [EdgeLp]: {
      id: EdgeLp,
      parentSystem: Standard,
    },
    [EdgeDs]: {
      id: EdgeDs,
      parentSystem: ConcealedGrid,
    },
    [HygieneEdgeA20]: {
      id: HygieneEdgeA20,
      parentSystem: Hygiene,
    },
    [HygieneEdgeA40]: {
      id: HygieneEdgeA40,
      parentSystem: Hygiene,
    },
    [HygieneEdgeDsC4]: {
      id: HygieneEdgeDsC4,
      parentSystem: HygieneConcealedGrid,
    },
    [HygieneEdgeDsCleanRoom]: {
      id: HygieneEdgeDsCleanRoom,
      parentSystem: HygieneConcealedGrid,
    },
    [CorridorEdgeDs]: {
      id: CorridorEdgeDs,
      parentSystem: CorridorMainMenu,
    },
    [CorridorEdgeA]: {
      id: CorridorEdgeA,
      parentSystem: CorridorMainMenu,
    },
    [CorridorEdgeDA]: {
      id: CorridorEdgeDA,
      parentSystem: CorridorMainMenu,
    },
    [CorridorEdgeE]: {
      id: CorridorEdgeE,
      parentSystem: CorridorMainMenu,
    },
  },
  Configurations: {
    [`${EdgeAT24}_${config_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}`,
      parentRange: EdgeAT24,
      config: config_600x600,
      parentConfig: Standard,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",
      main_runners: connect_t24_main_runner,
      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT24}_${config_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}`,
      parentRange: EdgeAT24,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",
      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT24}_${config_600x1200}`]: {
      id: `${EdgeAT24}_${config_600x1200}`,
      parentRange: EdgeAT24,
      config: config_600x1200,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",
      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT24}_${config_1200x1200}`]: {
      id: `${EdgeAT24}_${config_1200x1200}`,
      parentRange: EdgeAT24,
      config: config_1200x1200,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",
      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT24}_${config_1600x600}`]: {
      id: `${EdgeAT24}_${config_1600x600}`,
      parentRange: EdgeAT24,
      config: config_1600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_22_22,
    },

    [`${EdgeAT24}_${config_1800x600}`]: {
      id: `${EdgeAT24}_${config_1800x600}`,
      parentRange: EdgeAT24,
      config: config_1800x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT24}_${config_2000x600}`]: {
      id: `${EdgeAT24}_${config_2000x600}`,
      parentRange: EdgeAT24,
      config: config_2000x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT24}_${config_2400x600}`]: {
      id: `${EdgeAT24}_${config_2400x600}`,
      parentRange: EdgeAT24,
      config: config_2400x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_22_22,
    },
    [`${EdgeAT15}_${config_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}`,
      parentRange: EdgeAT15,
      config: config_600x600,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeAT15}_${config_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}`,
      parentRange: EdgeAT15,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeAT15}_${config_600x1200}`]: {
      id: `${EdgeAT15}_${config_600x1200}`,
      parentRange: EdgeAT15,
      config: config_600x1200,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeAT15}_${config_1200x1200}`]: {
      id: `${EdgeAT15}_${config_1200x1200}`,
      parentRange: EdgeAT15,
      config: config_1200x1200,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}`,
      parentRange: EdgeET24,
      config: config_600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_1200x600}`]: {
      id: `${EdgeET24}_${config_1200x600}`,
      parentRange: EdgeET24,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_600x1200}`]: {
      id: `${EdgeET24}_${config_600x1200}`,
      parentRange: EdgeET24,
      config: config_600x1200,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_1200x1200}`]: {
      id: `${EdgeET24}_${config_1200x1200}`,
      parentRange: EdgeET24,
      config: config_1200x1200,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_1600x600}`]: {
      id: `${EdgeET24}_${config_1600x600}`,
      parentRange: EdgeET24,
      config: config_1600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_1800x600}`]: {
      id: `${EdgeET24}_${config_1800x600}`,
      parentRange: EdgeET24,
      config: config_1800x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_2000x600}`]: {
      id: `${EdgeET24}_${config_2000x600}`,
      parentRange: EdgeET24,
      config: config_2000x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET24}_${config_2400x600}`]: {
      id: `${EdgeET24}_${config_2400x600}`,
      parentRange: EdgeET24,
      config: config_2400x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },

    [`${EdgeET15}_${config_600x600}`]: {
      id: `${EdgeET15}_${config_600x600}`,
      parentRange: EdgeET15,
      config: config_600x600,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET15}_${config_1200x600}`]: {
      id: `${EdgeET15}_${config_1200x600}`,
      parentRange: EdgeET15,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET15}_${config_600x1200}`]: {
      id: `${EdgeET15}_${config_600x1200}`,
      parentRange: EdgeET15,
      config: config_600x1200,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeET15}_${config_1200x1200}`]: {
      id: `${EdgeET15}_${config_1200x1200}`,
      parentRange: EdgeET15,
      config: config_1200x1200,
      parentConfig: Standard,
      main_runners: connect_t15_main_runner,
      other_runners_600: "Connect T15 Cross tee 600 mm",
      other_runners_1200: "Connect T15 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_600x600}`]: {
      id: `${EdgeDg}_${config_600x600}`,
      parentRange: EdgeDg,
      config: config_600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_1200x600}`]: {
      id: `${EdgeDg}_${config_1200x600}`,
      parentRange: EdgeDg,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_600x1200}`]: {
      id: `${EdgeDg}_${config_600x1200}`,
      parentRange: EdgeDg,
      config: config_600x1200,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_1200x1200}`]: {
      id: `${EdgeDg}_${config_1200x1200}`,
      parentRange: EdgeDg,
      config: config_1200x1200,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_1600x600}`]: {
      id: `${EdgeDg}_${config_1600x600}`,
      parentRange: EdgeDg,
      config: config_1600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_1800x600}`]: {
      id: `${EdgeDg}_${config_1800x600}`,
      parentRange: EdgeDg,
      config: config_1800x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_2000x600}`]: {
      id: `${EdgeDg}_${config_2000x600}`,
      parentRange: EdgeDg,
      config: config_2000x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDg}_${config_2400x600}`]: {
      id: `${EdgeDg}_${config_2400x600}`,
      parentRange: EdgeDg,
      config: config_2400x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_600x150}`]: {
      id: `${EdgeLp}_${config_600x150}`,
      parentRange: EdgeLp,
      config: config_600x150,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_600x300}`]: {
      id: `${EdgeLp}_${config_600x300}`,
      parentRange: EdgeLp,
      config: config_600x300,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_600x600}`]: {
      id: `${EdgeLp}_${config_600x600}`,
      parentRange: EdgeLp,
      config: config_600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_1200x150}`]: {
      id: `${EdgeLp}_${config_1200x150}`,
      parentRange: EdgeLp,
      config: config_1200x150,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_1200x300}`]: {
      id: `${EdgeLp}_${config_1200x300}`,
      parentRange: EdgeLp,
      config: config_1200x300,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_1200x600}`]: {
      id: `${EdgeLp}_${config_1200x600}`,
      parentRange: EdgeLp,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeLp}_${config_1800x600}`]: {
      id: `${EdgeLp}_${config_1800x600}`,
      parentRange: EdgeLp,
      config: config_1800x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runner,
      other_runners_150: "Connect T24 Cross tee 150 mm",
      other_runners_300: "Connect T24 Cross tee 300 mm",
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_900: "Connect T24 Cross tee 900 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
    },
    [`${EdgeDs}_${config_600x600}`]: {
      id: `${EdgeDs}_${config_600x600}`,
      parentRange: EdgeDs,
      config: config_600x600,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${EdgeDs}_${config_1200x600}`]: {
      id: `${EdgeDs}_${config_1200x600}`,
      parentRange: EdgeDs,
      config: config_1200x600,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${EdgeDs}_${config_1200x1200}`]: {
      id: `${EdgeDs}_${config_1200x1200}`,
      parentRange: EdgeDs,
      config: config_1200x1200,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${EdgeDs}_${config_1600x600}`]: {
      id: `${EdgeDs}_${config_1600x600}`,
      parentRange: EdgeDs,
      config: config_1600x600,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${EdgeDs}_${config_1800x600}`]: {
      id: `${EdgeDs}_${config_1800x600}`,
      parentRange: EdgeDs,
      config: config_1800x600,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${EdgeDs}_${config_2000x600}`]: {
      id: `${EdgeDs}_${config_2000x600}`,
      parentRange: EdgeDs,
      config: config_2000x600,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${EdgeDs}_${config_2400x600}`]: {
      id: `${EdgeDs}_${config_2400x600}`,
      parentRange: EdgeDs,
      config: config_2400x600,
      parentConfig: ConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${HygieneEdgeA20}_${config_600x600}`]: {
      id: `${HygieneEdgeA20}_${config_600x600}`,
      parentRange: HygieneEdgeA20,
      config: config_600x600,
      parentConfig: Hygiene,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",
      main_runners: connect_t24_main_runnerc3c4,

      angle_trim: angle_trim_22_22,
      hygiene_clip: "true",
    },
    [`${HygieneEdgeA20}_${config_1200x600}`]: {
      id: `${HygieneEdgeA20}_${config_1200x600}`,
      parentRange: HygieneEdgeA20,
      config: config_1200x600,
      parentConfig: Hygiene,
      main_runners: connect_t24_main_runnerc3c4,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_22_22,
      hygiene_clip: "true",
    },
    [`${HygieneEdgeDsC4}_${config_600x600}`]: {
      id: `${HygieneEdgeDsC4}_${config_600x600}`,
      parentRange: HygieneEdgeDsC4,
      config: config_600x600,
      parentConfig: HygieneConcealedGrid,
      main_runners: connect_t24_main_runnerc4,
      other_runners_600: "Connect T24 Cross tee 600 mm C4",
      other_runners_1200: "Connect T24 Cross tee 1200 mm C4",

      space_bar: space_bar_c4,
      angle_trim: angle_trim_15_22,
      wallBracketForProfile: "Connect Wall Braket for Main runner C4",

      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar C4",
      hygiene_clip: "false",
    },
    [`${HygieneEdgeDsC4}_${config_1200x600}`]: {
      id: `${HygieneEdgeDsC4}_${config_1200x600}`,
      parentRange: HygieneEdgeDsC4,
      config: config_1200x600,
      parentConfig: HygieneConcealedGrid,
      main_runners: connect_t24_main_runnerc4,
      other_runners_600: "Connect T24 Cross tee 600 mm C4",
      other_runners_1200: "Connect T24 Cross tee 1200 mm C4",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar_c4,
      wallBracketForProfile: "Connect Wall Braket for Main runner C4",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar C4",
      hygiene_clip: "false",
    },
    [`${HygieneEdgeDsCleanRoom}_${config_600x600}`]: {
      id: `${HygieneEdgeDsCleanRoom}_${config_600x600}`,
      parentRange: HygieneEdgeDsCleanRoom,
      config: config_600x600,
      parentConfig: HygieneConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
      hygiene_clip: "true",
    },
    [`${HygieneEdgeDsCleanRoom}_${config_1200x600}`]: {
      id: `${HygieneEdgeDsCleanRoom}_${config_1200x600}`,
      parentRange: HygieneEdgeDsCleanRoom,
      config: config_1200x600,
      parentConfig: HygieneConcealedGrid,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
      hygiene_clip: "true",
    },
    [`${CorridorEdgeDs}_${config_300}`]: {
      id: `${CorridorEdgeDs}_${config_300}`,
      parentRange: CorridorEdgeDs,
      config: config_300,
      parentConfig: CorridorMainMenu,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      space_bar: space_bar,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },
    [`${CorridorEdgeDs}_${config_600}`]: {
      id: `${CorridorEdgeDs}_${config_600}`,
      parentRange: CorridorEdgeDs,
      config: config_600,
      parentConfig: CorridorMainMenu,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
      wallBracketForProfile: "Connect Wall Braket for Main runner",
      wallBracketForSpaceBar: "Connect Wall Bracket for Space bar",
    },

    [`${CorridorEdgeA}_${config_300}`]: {
      id: `${CorridorEdgeA}_${config_300}`,
      parentRange: CorridorEdgeA,
      config: config_300,
      parentConfig: Corridor,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
    },
    [`${CorridorEdgeA}_${config_600}`]: {
      id: `${CorridorEdgeA}_${config_600}`,
      parentRange: CorridorEdgeA,
      config: config_600,
      parentConfig: Corridor,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
    },

    [`${CorridorEdgeDA}_${config_300}`]: {
      id: `${CorridorEdgeDA}_${config_300}`,
      parentRange: CorridorEdgeDA,
      config: config_300,
      parentConfig: Corridor,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
    },
    [`${CorridorEdgeDA}_${config_600}`]: {
      id: `${CorridorEdgeDA}_${config_600}`,
      parentRange: CorridorEdgeDA,
      config: config_600,
      parentConfig: Corridor,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
    },
    [`${CorridorEdgeE}_${config_300}`]: {
      id: `${CorridorEdgeE}_${config_300}`,
      parentRange: CorridorEdgeE,
      config: config_300,
      parentConfig: Corridor,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
    },
    [`${CorridorEdgeE}_${config_600}`]: {
      id: `${CorridorEdgeE}_${config_600}`,
      parentRange: CorridorEdgeE,
      config: config_600,
      parentConfig: Corridor,
      main_runners: connect_t24_main_runnerhd,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      space_bar: space_bar,
      angle_trim: angle_trim_15_22,
    },
    [`${HygieneEdgeA40}_${config_600x600}`]: {
      id: `${HygieneEdgeA40}_${config_600x600}`,
      parentRange: HygieneEdgeA40,
      config: config_600x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerc3c4,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      hygiene_clip: "true",
    },
    [`${HygieneEdgeA40}_${config_1200x600}`]: {
      id: `${HygieneEdgeA40}_${config_1200x600}`,
      parentRange: HygieneEdgeA40,
      config: config_1200x600,
      parentConfig: Standard,
      main_runners: connect_t24_main_runnerc3c4,
      other_runners_600: "Connect T24 Cross tee 600 mm",
      other_runners_1200: "Connect T24 Cross tee 1200 mm",

      angle_trim: angle_trim_15_22,
      hygiene_clip: "true",
    },
  },
  Products: {
    //Standard - Edge A T24 - 600x600
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Focus_A20_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Focus_A20_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Master_A40_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Master_A40_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_A40_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Gedina_A15_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Gedina_A15_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_A15_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Advantage_A15_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Advantage_A15_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A15_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Advantage_A20_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Advantage_A20_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A20_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Advantage_A40_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Advantage_A40_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A40_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Hygiene_Clinic_A15_T24_600x600}`]:
      {
        id: `${EdgeAT24}_${config_600x600}_${Ecophon_Hygiene_Clinic_A15_T24_600x600}`,
        parentRange: EdgeAT24,
        parentConfig: `${EdgeAT24}_${config_600x600}`,
        parentSystem: Standard,
        product: Ecophon_Hygiene_Clinic_A15_T24_600x600,
      },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Combison_Uno_A35_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Combison_Uno_A35_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Combison_Uno_A35_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Combison_Duo_A55_T24_600x600}`]: {
      id: `${EdgeAT24}_${config_600x600}_${Ecophon_Combison_Duo_A55_T24_600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Combison_Duo_A55_T24_600x600,
    },
    [`${EdgeAT24}_${config_600x600}_${Ecophon_Combison_A50_dB42_T24_600x600}`]:
      {
        id: `${EdgeAT24}_${config_600x600}_${Ecophon_Combison_A50_dB42_T24_600x600}`,
        parentRange: EdgeAT24,
        parentConfig: `${EdgeAT24}_${config_600x600}`,
        parentSystem: Standard,
        product: Ecophon_Combison_A50_dB42_T24_600x600,
      },
    //Standard - Edge A T24 - 1200x600
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Focus_A20_T24_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Focus_A20_T24_1200x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_1200x600,
    },
    [`${EdgeAT24}_${config_600x1200}_${Ecophon_Focus_A20_T24_600x1200}`]: {
      id: `${EdgeAT24}_${config_600x1200}_${Ecophon_Focus_A20_T24_600x1200}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_600x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_600x1200,
    },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Master_A40_T24_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Master_A40_T24_1200x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_A40_T24_1200x600,
    },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Gedina_A15_T24_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Gedina_A15_T24_1200x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_A15_T24_1200x600,
    },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Advantage_A15_T24_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Advantage_A15_T24_1200x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A15_T24_1200x600,
    },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Advantage_A20_T24_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Advantage_A20_T24_1200x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A20_T24_1200x600,
    },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Advantage_A40_T24_1200x600}`]: {
      id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Advantage_A40_T24_1200x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A40_T24_1200x600,
    },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Combison_A50_dB42_T24_1200x600}`]:
      {
        id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Combison_A50_dB42_T24_1200x600}`,
        parentRange: EdgeAT24,
        parentConfig: `${EdgeAT24}_${config_1200x600}`,
        parentSystem: Standard,
        product: Ecophon_Combison_A50_dB42_T24_1200x600,
      },
    [`${EdgeAT24}_${config_1200x600}_${Ecophon_Combison_Uno_A35_T24_1200x600}`]:
      {
        id: `${EdgeAT24}_${config_1200x600}_${Ecophon_Combison_Uno_A35_T24_1200x600}`,
        parentRange: EdgeAT24,
        parentConfig: `${EdgeAT24}_${config_1200x600}`,
        parentSystem: Standard,
        product: Ecophon_Combison_Uno_A35_T24_1200x600,
      },
    //Standard - Edge A T24 - 1200x1200
    [`${EdgeAT24}_${config_1200x1200}_${Ecophon_Focus_A20_T24_1200x1200}`]: {
      id: `${EdgeAT24}_${config_1200x1200}_${Ecophon_Focus_A20_T24_1200x1200}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_1200x1200,
    },
    [`${EdgeAT24}_${config_1200x1200}_${Ecophon_Master_A20_T24_1200x1200}`]: {
      id: `${EdgeAT24}_${config_1200x1200}_${Ecophon_Master_A20_T24_1200x1200}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Master_A20_T24_1200x1200,
    },
    //Standard - Edge A T24 - 1600x600
    [`${EdgeAT24}_${config_1600x600}_${Ecophon_Focus_A20_T24_1600x600}`]: {
      id: `${EdgeAT24}_${config_1600x600}_${Ecophon_Focus_A20_T24_1600x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_1600x600,
    },
    //Standard - Edge A T24 - 1800x600
    [`${EdgeAT24}_${config_1800x600}_${Ecophon_Focus_A20_T24_1800x600}`]: {
      id: `${EdgeAT24}_${config_1800x600}_${Ecophon_Focus_A20_T24_1800x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1800x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_1800x600,
    },
    [`${EdgeAT24}_${config_1800x600}_${Ecophon_Advantage_A20_T24_1800x600}`]: {
      id: `${EdgeAT24}_${config_1800x600}_${Ecophon_Advantage_A20_T24_1800x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_1800x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A20_T24_1800x600,
    },
    //Standard - Edge A T24 - 2000x600
    [`${EdgeAT24}_${config_2000x600}_${Ecophon_Focus_A20_T24_2000x600}`]: {
      id: `${EdgeAT24}_${config_2000x600}_${Ecophon_Focus_A20_T24_2000x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_2000x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_2000x600,
    },
    //Standard - Edge A T24 - 2400x600
    [`${EdgeAT24}_${config_2400x600}_${Ecophon_Focus_A20_T24_2400x600}`]: {
      id: `${EdgeAT24}_${config_2400x600}_${Ecophon_Focus_A20_T24_2400x600}`,
      parentRange: EdgeAT24,
      parentConfig: `${EdgeAT24}_${config_2400x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T24_2400x600,
    },

    //Standard - Edge A T15 - 600x600
    [`${EdgeAT15}_${config_600x600}_${Ecophon_Focus_A20_T15_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}_${Ecophon_Focus_A20_T15_600x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T15_600x600,
    },
    [`${EdgeAT15}_${config_600x600}_${Ecophon_Master_A40_T15_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}_${Ecophon_Master_A40_T15_600x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_A40_T15_600x600,
    },
    [`${EdgeAT15}_${config_600x600}_${Ecophon_Gedina_A15_T15_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}_${Ecophon_Gedina_A15_T15_600x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_A15_T15_600x600,
    },
    [`${EdgeAT15}_${config_600x600}_${Ecophon_Advantage_A20_T15_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}_${Ecophon_Advantage_A20_T15_600x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A20_T15_600x600,
    },
    [`${EdgeAT15}_${config_600x600}_${Ecophon_Advantage_A15_T15_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}_${Ecophon_Advantage_A15_T15_600x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A15_T15_600x600,
    },
    [`${EdgeAT15}_${config_600x600}_${Ecophon_Advantage_A40_T15_600x600}`]: {
      id: `${EdgeAT15}_${config_600x600}_${Ecophon_Advantage_A40_T15_600x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A40_T15_600x600,
    },
    //Standard - Edge A T15 - 1200x600
    [`${EdgeAT15}_${config_1200x600}_${Ecophon_Focus_A20_T15_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}_${Ecophon_Focus_A20_T15_1200x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T15_1200x600,
    },
    [`${EdgeAT15}_${config_600x1200}_${Ecophon_Focus_A20_T15_600x1200}`]: {
      id: `${EdgeAT15}_${config_600x1200}_${Ecophon_Focus_A20_T15_600x1200}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_600x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T15_600x1200,
    },
    [`${EdgeAT15}_${config_1200x600}_${Ecophon_Master_A40_T15_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}_${Ecophon_Master_A40_T15_1200x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_A40_T15_1200x600,
    },
    [`${EdgeAT15}_${config_1200x600}_${Ecophon_Gedina_A15_T15_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}_${Ecophon_Gedina_A15_T15_1200x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_A15_T15_1200x600,
    },
    [`${EdgeAT15}_${config_1200x600}_${Ecophon_Advantage_A20_T15_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}_${Ecophon_Advantage_A20_T15_1200x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A20_T15_1200x600,
    },
    [`${EdgeAT15}_${config_1200x600}_${Ecophon_Advantage_A15_T15_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}_${Ecophon_Advantage_A15_T15_1200x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A15_T15_1200x600,
    },
    [`${EdgeAT15}_${config_1200x600}_${Ecophon_Advantage_A40_T15_1200x600}`]: {
      id: `${EdgeAT15}_${config_1200x600}_${Ecophon_Advantage_A40_T15_1200x600}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_A40_T15_1200x600,
    },
    //Standard - Edge A T15 - 1200x1200
    [`${EdgeAT15}_${config_1200x1200}_${Ecophon_Focus_A20_T15_1200x1200}`]: {
      id: `${EdgeAT15}_${config_1200x1200}_${Ecophon_Focus_A20_T15_1200x1200}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_A20_T15_1200x1200,
    },
    [`${EdgeAT15}_${config_1200x1200}_${Ecophon_Master_A40_T15_1200x1200}`]: {
      id: `${EdgeAT15}_${config_1200x1200}_${Ecophon_Master_A40_T15_1200x1200}`,
      parentRange: EdgeAT15,
      parentConfig: `${EdgeAT15}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Master_A40_T15_1200x1200,
    },

    //Standard - Edge E T24 => (Edge E / Ez / Eg T24) - 600x600
    [`${EdgeET24}_${config_600x600}_${Ecophon_Focus_E20_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Focus_E20_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Master_E40_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Master_E40_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_E40_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Master_Eg40_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Master_Eg40_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_Eg40_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Hygiene_Clinic_E15_T24_600x600}`]:
      {
        id: `${EdgeET24}_${config_600x600}_${Ecophon_Hygiene_Clinic_E15_T24_600x600}`,
        parentRange: EdgeET24,
        parentConfig: `${EdgeET24}_${config_600x600}`,
        parentSystem: Standard,
        product: Ecophon_Hygiene_Clinic_E15_T24_600x600,
      },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Advantage_E150_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Advantage_E150_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E150_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Advantage_E20_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Advantage_E20_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E20_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Gedina_E15_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Gedina_E15_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_E15_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Combison_Duo_E55_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Combison_Duo_E55_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Combison_Duo_E55_T24_600x600,
    },
    [`${EdgeET24}_${config_600x600}_${Ecophon_Focus_Ez40_T24_600x600}`]: {
      id: `${EdgeET24}_${config_600x600}_${Ecophon_Focus_Ez40_T24_600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Ez40_T24_600x600,
    },
    //Standard - Edge E T24 - 1200x600
    [`${EdgeET24}_${config_1200x600}_${Ecophon_Focus_E20_T24_1200x600}`]: {
      id: `${EdgeET24}_${config_1200x600}_${Ecophon_Focus_E20_T24_1200x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_1200x600,
    },
    [`${EdgeET24}_${config_600x1200}_${Ecophon_Focus_E20_T24_600x1200}`]: {
      id: `${EdgeET24}_${config_600x1200}_${Ecophon_Focus_E20_T24_600x1200}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_600x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_600x1200,
    },
    [`${EdgeET24}_${config_1200x600}_${Ecophon_Master_E40_T24_1200x600}`]: {
      id: `${EdgeET24}_${config_1200x600}_${Ecophon_Master_E40_T24_1200x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_E40_T24_1200x600,
    },
    [`${EdgeET24}_${config_1200x600}_${Ecophon_Master_Eg40_T24_1200x600}`]: {
      id: `${EdgeET24}_${config_1200x600}_${Ecophon_Master_Eg40_T24_1200x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_Eg40_T24_1200x600,
    },
    [`${EdgeET24}_${config_1200x600}_${Ecophon_Hygiene_Clinic_E15_T24_1200x600}`]:
      {
        id: `${EdgeET24}_${config_1200x600}_${Ecophon_Hygiene_Clinic_E15_T24_1200x600}`,
        parentRange: EdgeET24,
        parentConfig: `${EdgeET24}_${config_1200x600}`,
        parentSystem: Standard,
        product: Ecophon_Hygiene_Clinic_E15_T24_1200x600,
      },
    [`${EdgeET24}_${config_1200x600}_${Ecophon_Gedina_E15_T24_1200x600}`]: {
      id: `${EdgeET24}_${config_1200x600}_${Ecophon_Gedina_E15_T24_1200x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_E15_T24_1200x600,
    },
    [`${EdgeET24}_${config_1200x600}_${Ecophon_Advantage_E20_T24_1200x600}`]: {
      id: `${EdgeET24}_${config_1200x600}_${Ecophon_Advantage_E20_T24_1200x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E20_T24_1200x600,
    },
    //Standard - Edge E T24 - 1200x1200
    [`${EdgeET24}_${config_1200x1200}_${Ecophon_Focus_E20_T24_1200x1200}`]: {
      id: `${EdgeET24}_${config_1200x1200}_${Ecophon_Focus_E20_T24_1200x1200}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_1200x1200,
    },
    [`${EdgeET24}_${config_1200x1200}_${Ecophon_Master_E40_T24_1200x1200}`]: {
      id: `${EdgeET24}_${config_1200x1200}_${Ecophon_Master_E40_T24_1200x1200}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Master_E40_T24_1200x1200,
    },
    [`${EdgeET24}_${config_1200x1200}_${Ecophon_Master_Eg40_T24_1200x1200}`]: {
      id: `${EdgeET24}_${config_1200x1200}_${Ecophon_Master_Eg40_T24_1200x1200}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Master_Eg40_T24_1200x1200,
    },
    //Standard - Edge E T24 - 1600x600
    [`${EdgeET24}_${config_1600x600}_${Ecophon_Focus_E20_T24_1600x600}`]: {
      id: `${EdgeET24}_${config_1600x600}_${Ecophon_Focus_E20_T24_1600x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_1600x600,
    },
    //Standard - Edge E T24 - 1800x600
    [`${EdgeET24}_${config_1800x600}_${Ecophon_Focus_E20_T24_1800x600}`]: {
      id: `${EdgeET24}_${config_1800x600}_${Ecophon_Focus_E20_T24_1800x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_1800x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_1800x600,
    },
    //Standard - Edge E T24 - 2000x600
    [`${EdgeET24}_${config_2000x600}_${Ecophon_Focus_E20_T24_2000x600}`]: {
      id: `${EdgeET24}_${config_2000x600}_${Ecophon_Focus_E20_T24_2000x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_2000x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_2000x600,
    },
    //Standard - Edge E T24 - 2400x600
    [`${EdgeET24}_${config_2400x600}_${Ecophon_Focus_E20_T24_2400x600}`]: {
      id: `${EdgeET24}_${config_2400x600}_${Ecophon_Focus_E20_T24_2400x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_2400x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T24_2400x600,
    },
    [`${EdgeET24}_${config_2400x600}_${Ecophon_Master_Eg40_T24_2400x600}`]: {
      id: `${EdgeET24}_${config_2400x600}_${Ecophon_Master_Eg40_T24_2400x600}`,
      parentRange: EdgeET24,
      parentConfig: `${EdgeET24}_${config_2400x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_Eg40_T24_2400x600,
    },

    //Standard - Edge E T15 - 600x600
    [`${EdgeET15}_${config_600x600}_${Ecophon_Focus_E20_T15_600x600}`]: {
      id: `${EdgeET15}_${config_600x600}_${Ecophon_Focus_E20_T15_600x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T15_600x600,
    },
    [`${EdgeET15}_${config_600x600}_${Ecophon_Gedina_E15_T15_600x600}`]: {
      id: `${EdgeET15}_${config_600x600}_${Ecophon_Gedina_E15_T15_600x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_E15_T15_600x600,
    },
    [`${EdgeET15}_${config_600x600}_${Ecophon_Advantage_E15_T15_600x600}`]: {
      id: `${EdgeET15}_${config_600x600}_${Ecophon_Advantage_E15_T15_600x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E15_T15_600x600,
    },
    [`${EdgeET15}_${config_600x600}_${Ecophon_Advantage_E20_T15_600x600}`]: {
      id: `${EdgeET15}_${config_600x600}_${Ecophon_Advantage_E20_T15_600x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E20_T15_600x600,
    },
    [`${EdgeET15}_${config_600x1200}_${Ecophon_Focus_E20_T15_600x1200}`]: {
      id: `${EdgeET15}_${config_600x1200}_${Ecophon_Focus_E20_T15_600x1200}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_600x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T15_600x1200,
    },
    //Standard - Edge E T15 - 1200x600
    [`${EdgeET15}_${config_1200x600}_${Ecophon_Focus_E20_T15_1200x600}`]: {
      id: `${EdgeET15}_${config_1200x600}_${Ecophon_Focus_E20_T15_1200x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T15_1200x600,
    },
    [`${EdgeET15}_${config_1200x600}_${Ecophon_Gedina_E15_T15_1200x600}`]: {
      id: `${EdgeET15}_${config_1200x600}_${Ecophon_Gedina_E15_T15_1200x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Gedina_E15_T15_1200x600,
    },
    [`${EdgeET15}_${config_1200x600}_${Ecophon_Advantage_E15_T15_1200x600}`]: {
      id: `${EdgeET15}_${config_1200x600}_${Ecophon_Advantage_E15_T15_1200x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E15_T15_1200x600,
    },
    [`${EdgeET15}_${config_1200x600}_${Ecophon_Advantage_E20_T15_1200x600}`]: {
      id: `${EdgeET15}_${config_1200x600}_${Ecophon_Advantage_E20_T15_1200x600}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Advantage_E20_T15_1200x600,
    },
    //Standard - Edge E T15 - 1200x1200
    [`${EdgeET15}_${config_1200x1200}_${Ecophon_Focus_E20_T15_1200x1200}`]: {
      id: `${EdgeET15}_${config_1200x1200}_${Ecophon_Focus_E20_T15_1200x1200}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_E20_T15_1200x1200,
    },
    [`${EdgeET15}_${config_1200x1200}_${Ecophon_Master_E40_T15_1200x1200}`]: {
      id: `${EdgeET15}_${config_1200x1200}_${Ecophon_Master_E40_T15_1200x1200}`,
      parentRange: EdgeET15,
      parentConfig: `${EdgeET15}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Master_E40_T15_1200x1200,
    },

    //Standard - Edge Dg - 600x600
    [`${EdgeDg}_${config_600x600}_${Ecophon_Focus_Dg20_T24_600x600}`]: {
      id: `${EdgeDg}_${config_600x600}_${Ecophon_Focus_Dg20_T24_600x600}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg20_T24_600x600,
    },
    //Standard - Edge Dg - 1200x600
    [`${EdgeDg}_${config_1200x600}_${Ecophon_Focus_Dg20_T24_1200x600}`]: {
      id: `${EdgeDg}_${config_1200x600}_${Ecophon_Focus_Dg20_T24_1200x600}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg20_T24_1200x600,
    },
    //Standard - Edge Dg - 1200x1200
    [`${EdgeDg}_${config_1200x1200}_${Ecophon_Focus_Dg25_T24_1200x1200}`]: {
      id: `${EdgeDg}_${config_1200x1200}_${Ecophon_Focus_Dg25_T24_1200x1200}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_1200x1200}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg25_T24_1200x1200,
    },
    //Standard - Edge Dg - 1600x600
    [`${EdgeDg}_${config_1600x600}_${Ecophon_Focus_Dg20_T24_1600x600}`]: {
      id: `${EdgeDg}_${config_1600x600}_${Ecophon_Focus_Dg20_T24_1600x600}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_1600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg20_T24_1600x600,
    },
    //Standard - Edge Dg - 1800x600
    [`${EdgeDg}_${config_1800x600}_${Ecophon_Focus_Dg20_T24_1800x600}`]: {
      id: `${EdgeDg}_${config_1800x600}_${Ecophon_Focus_Dg20_T24_1800x600}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_1800x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg20_T24_1800x600,
    },
    //Standard - Edge Dg - 2000x600
    [`${EdgeDg}_${config_2000x600}_${Ecophon_Focus_Dg20_T24_2000x600}`]: {
      id: `${EdgeDg}_${config_2000x600}_${Ecophon_Focus_Dg20_T24_2000x600}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_2000x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg20_T24_2000x600,
    },
    //Standard - Edge Dg - 2400x600
    [`${EdgeDg}_${config_2400x600}_${Ecophon_Focus_Dg20_T24_2400x600}`]: {
      id: `${EdgeDg}_${config_2400x600}_${Ecophon_Focus_Dg20_T24_2400x600}`,
      parentRange: EdgeDg,
      parentConfig: `${EdgeDg}_${config_2400x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Dg20_T24_2400x600,
    },


    //Standard - Edge Lp - 600x150
    [`${EdgeLp}_${config_600x150}_${Ecophon_Focus_Lp20_T24_600x150}`]: {
      id: `${EdgeLp}_${config_600x150}_${Ecophon_Focus_Lp20_T24_600x150}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_600x150}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_600x150,
    },
    //Standard - Edge Lp - 600x300
    [`${EdgeLp}_${config_600x300}_${Ecophon_Focus_Lp20_T24_600x300}`]: {
      id: `${EdgeLp}_${config_600x300}_${Ecophon_Focus_Lp20_T24_600x300}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_600x300}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_600x300,
    },
    //Standard - Edge Lp - 600x600
    [`${EdgeLp}_${config_600x600}_${Ecophon_Focus_Lp20_T24_600x600}`]: {
      id: `${EdgeLp}_${config_600x600}_${Ecophon_Focus_Lp20_T24_600x600}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_600x600,
    },
        //Standard - Edge Lp - 1200x150
    [`${EdgeLp}_${config_1200x150}_${Ecophon_Focus_Lp20_T24_1200x150}`]: {
      id: `${EdgeLp}_${config_1200x150}_${Ecophon_Focus_Lp20_T24_1200x150}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_1200x150}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_1200x150,
    },
        //Standard - Edge Lp - 1200x300
    [`${EdgeLp}_${config_1200x300}_${Ecophon_Focus_Lp20_T24_1200x300}`]: {
      id: `${EdgeLp}_${config_1200x300}_${Ecophon_Focus_Lp20_T24_1200x300}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_1200x300}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_1200x300,
    },
    //Standard - Edge Lp - 1200x600
    [`${EdgeLp}_${config_1200x600}_${Ecophon_Focus_Lp20_T24_1200x600}`]: {
      id: `${EdgeLp}_${config_1200x600}_${Ecophon_Focus_Lp20_T24_1200x600}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_1200x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_1200x600,
    },
    //Standard - Edge Lp - 1800x600
    [`${EdgeLp}_${config_1800x600}_${Ecophon_Focus_Lp20_T24_1800x600}`]: {
      id: `${EdgeLp}_${config_1800x600}_${Ecophon_Focus_Lp20_T24_1800x600}`,
      parentRange: EdgeLp,
      parentConfig: `${EdgeLp}_${config_1800x600}`,
      parentSystem: Standard,
      product: Ecophon_Focus_Lp20_T24_1800x600,
    },

    //ConcealedGrid - Edge Ds - 600x600
    [`${EdgeDs}_${config_600x600}_${Ecophon_Focus_Ds20_T24_600x600}`]: {
      id: `${EdgeDs}_${config_600x600}_${Ecophon_Focus_Ds20_T24_600x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_600x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_600x600,
    },
    [`${EdgeDs}_${config_600x600}_${Ecophon_Master_Ds40_T24_600x600}`]: {
      id: `${EdgeDs}_${config_600x600}_${Ecophon_Master_Ds40_T24_600x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_600x600}`,
      parentSystem: Standard,
      product: Ecophon_Master_Ds40_T24_600x600,
    },
    [`${EdgeDs}_${config_600x600}_${Ecophon_Combison_Uno_Ds35_T24_600x600}`]: {
      id: `${EdgeDs}_${config_600x600}_${Ecophon_Combison_Uno_Ds35_T24_600x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_600x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Combison_Uno_Ds35_T24_600x600,
    },
    //ConcealedGrid - Edge Ds - 1200x600
    [`${EdgeDs}_${config_1200x600}_${Ecophon_Focus_Ds20_T24_1200x600}`]: {
      id: `${EdgeDs}_${config_1200x600}_${Ecophon_Focus_Ds20_T24_1200x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_1200x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_1200x600,
    },
    //ConcealedGrid - Edge Ds - 1200x1200
    [`${EdgeDs}_${config_1200x1200}_${Ecophon_Focus_Ds20_T24_1200x1200}`]: {
      id: `${EdgeDs}_${config_1200x1200}_${Ecophon_Focus_Ds20_T24_1200x1200}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_1200x1200}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_1200x1200,
    },
    //ConcealedGrid - Edge Ds - 1600x600
    [`${EdgeDs}_${config_1600x600}_${Ecophon_Focus_Ds20_T24_1600x600}`]: {
      id: `${EdgeDs}_${config_1600x600}_${Ecophon_Focus_Ds20_T24_1600x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_1600x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_1600x600,
    },
    //ConcealedGrid - Edge Ds - 1800x600
    [`${EdgeDs}_${config_1800x600}_${Ecophon_Focus_Ds20_T24_1800x600}`]: {
      id: `${EdgeDs}_${config_1800x600}_${Ecophon_Focus_Ds20_T24_1800x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_1800x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_1800x600,
    },
    //ConcealedGrid - Edge Ds - 2000x600
    [`${EdgeDs}_${config_2000x600}_${Ecophon_Focus_Ds20_T24_2000x600}`]: {
      id: `${EdgeDs}_${config_2000x600}_${Ecophon_Focus_Ds20_T24_2000x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_2000x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_2000x600,
    },
    //ConcealedGrid - Edge Ds - 2400x600
    [`${EdgeDs}_${config_2400x600}_${Ecophon_Focus_Ds20_T24_2400x600}`]: {
      id: `${EdgeDs}_${config_2400x600}_${Ecophon_Focus_Ds20_T24_2400x600}`,
      parentRange: EdgeDs,
      parentConfig: `${EdgeDs}_${config_2400x600}`,
      parentSystem: ConcealedGrid,
      product: Ecophon_Focus_Ds20_T24_2400x600,
    },

    //Corridor - Edge Ds - 300mm
    [`${CorridorEdgeDs}_${config_300}_${Ecophon_Focus_Ds20_T24_1250_2400x300}`]:
      {
        id: `${CorridorEdgeDs}_${config_300}_${Ecophon_Focus_Ds20_T24_1250_2400x300}`,
        parentRange: CorridorEdgeDs,
        parentConfig: `${CorridorEdgeDs}_${config_300}`,
        parentSystem: Corridor,
        product: Ecophon_Focus_Ds20_T24_1250_2400x300,
      },
    //Corridor - Edge Ds - 600mm
    [`${CorridorEdgeDs}_${config_600}_${Ecophon_Focus_Ds20_T24_1250_2400x600}`]:
      {
        id: `${CorridorEdgeDs}_${config_600}_${Ecophon_Focus_Ds20_T24_1250_2400x600}`,
        parentRange: CorridorEdgeDs,
        parentConfig: `${CorridorEdgeDs}_${config_600}`,
        parentSystem: Corridor,
        product: Ecophon_Focus_Ds20_T24_1250_2400x600,
      },

    //Corridor - Edge DA - 300mm
    [`${CorridorEdgeDA}_${config_300}_${Ecophon_Focus_DA20_T24_1250_2400x300}`]:
      {
        id: `${CorridorEdgeDA}_${config_300}_${Ecophon_Focus_DA20_T24_1250_2400x300}`,
        parentRange: CorridorEdgeDA,
        parentConfig: `${CorridorEdgeDA}_${config_300}`,
        parentSystem: Corridor,
        product: Ecophon_Focus_DA20_T24_1250_2400x300,
      },
    [`${CorridorEdgeDA}_${config_300}_${Ecophon_Gedina_DA15_T24_1250_2400x300}`]:
      {
        id: `${CorridorEdgeDA}_${config_300}_${Ecophon_Gedina_DA15_T24_1250_2400x300}`,
        parentRange: CorridorEdgeDA,
        parentConfig: `${CorridorEdgeDA}_${config_300}`,
        parentSystem: Corridor,
        product: Ecophon_Gedina_DA15_T24_1250_2400x300,
      },
    //Corridor - Edge DA - 600mm
    [`${CorridorEdgeDA}_${config_600}_${Ecophon_Focus_DA20_T24_1250_2400x600}`]:
      {
        id: `${CorridorEdgeDA}_${config_600}_${Ecophon_Focus_DA20_T24_1250_2400x600}`,
        parentRange: CorridorEdgeDA,
        parentConfig: `${CorridorEdgeDA}_${config_600}`,
        parentSystem: Corridor,
        product: Ecophon_Focus_DA20_T24_1250_2400x600,
      },
    [`${CorridorEdgeDA}_${config_600}_${Ecophon_Gedina_DA15_T24_1250_2400x600}`]:
      {
        id: `${CorridorEdgeDA}_${config_600}_${Ecophon_Gedina_DA15_T24_1250_2400x600}`,
        parentRange: CorridorEdgeDA,
        parentConfig: `${CorridorEdgeDA}_${config_600}`,
        parentSystem: Corridor,
        product: Ecophon_Gedina_DA15_T24_1250_2400x600,
      },

    //Corridor - Edge A - 300mm
    [`${CorridorEdgeA}_${config_300}_${Ecophon_Focus_A20_T24_1250_2400x300}`]: {
      id: `${CorridorEdgeA}_${config_300}_${Ecophon_Focus_A20_T24_1250_2400x300}`,
      parentRange: CorridorEdgeA,
      parentConfig: `${CorridorEdgeA}_${config_300}`,
      parentSystem: Corridor,
      product: Ecophon_Focus_A20_T24_1250_2400x300,
    },
    [`${CorridorEdgeA}_${config_300}_${Ecophon_Gedina_A15_T24_1250_2400x300}`]:
      {
        id: `${CorridorEdgeA}_${config_300}_${Ecophon_Gedina_A15_T24_1250_2400x300}`,
        parentRange: CorridorEdgeA,
        parentConfig: `${CorridorEdgeA}_${config_300}`,
        parentSystem: Corridor,
        product: Ecophon_Gedina_A15_T24_1250_2400x300,
      },
    [`${CorridorEdgeA}_${config_300}_${Ecophon_Advantage_A15_T24_1250_2400x300}`]:
      {
        id: `${CorridorEdgeA}_${config_300}_${Ecophon_Advantage_A15_T24_1250_2400x300}`,
        parentRange: CorridorEdgeA,
        parentConfig: `${CorridorEdgeA}_${config_300}`,
        parentSystem: Corridor,
        product: Ecophon_Advantage_A15_T24_1250_2400x300,
      },
    [`${CorridorEdgeA}_${config_300}_${Ecophon_Advantage_A20_T24_1250_2400x300}`]:
      {
        id: `${CorridorEdgeA}_${config_300}_${Ecophon_Advantage_A20_T24_1250_2400x300}`,
        parentRange: CorridorEdgeA,
        parentConfig: `${CorridorEdgeA}_${config_300}`,
        parentSystem: Corridor,
        product: Ecophon_Advantage_A20_T24_1250_2400x300,
      },
    //Corridor - Edge A - 600mm
    [`${CorridorEdgeA}_${config_600}_${Ecophon_Focus_A20_T24_1250_2400x600}`]: {
      id: `${CorridorEdgeA}_${config_600}_${Ecophon_Focus_A20_T24_1250_2400x600}`,
      parentRange: CorridorEdgeA,
      parentConfig: `${CorridorEdgeA}_${config_600}`,
      parentSystem: Corridor,
      product: Ecophon_Focus_A20_T24_1250_2400x600,
    },
    [`${CorridorEdgeA}_${config_600}_${Ecophon_Gedina_A15_T24_1250_2400x600}`]:
      {
        id: `${CorridorEdgeA}_${config_600}_${Ecophon_Gedina_A15_T24_1250_2400x600}`,
        parentRange: CorridorEdgeA,
        parentConfig: `${CorridorEdgeA}_${config_600}`,
        parentSystem: Corridor,
        product: Ecophon_Gedina_A15_T24_1250_2400x600,
      },
    [`${CorridorEdgeA}_${config_600}_${Ecophon_Advantage_A15_T24_1250_2400x600}`]:
      {
        id: `${CorridorEdgeA}_${config_600}_${Ecophon_Advantage_A15_T24_1250_2400x600}`,
        parentRange: CorridorEdgeA,
        parentConfig: `${CorridorEdgeA}_${config_600}`,
        parentSystem: Corridor,
        product: Ecophon_Advantage_A15_T24_1250_2400x600,
      },
    [`${CorridorEdgeA}_${config_600}_${Ecophon_Advantage_A20_T24_1250_2400x600}`]:
      {
        id: `${CorridorEdgeA}_${config_600}_${Ecophon_Advantage_A20_T24_1250_2400x600}`,
        parentRange: CorridorEdgeA,
        parentConfig: `${CorridorEdgeA}_${config_600}`,
        parentSystem: Corridor,
        product: Ecophon_Advantage_A20_T24_1250_2400x600,
      },
    //Corridor - Edge E - 300mm
    [`${CorridorEdgeE}_${config_300}_${Ecophon_Focus_E20_T24_1250_2400x300}`]: {
      id: `${CorridorEdgeE}_${config_300}_${Ecophon_Focus_E20_T24_1250_2400x300}`,
      parentRange: CorridorEdgeE,
      parentConfig: `${CorridorEdgeE}_${config_300}`,
      parentSystem: Corridor,
      product: Ecophon_Focus_E20_T24_1250_2400x300,
    },
    //Corridor - Edge E - 600mm
    [`${CorridorEdgeE}_${config_600}_${Ecophon_Focus_E20_T24_1250_2400x600}`]: {
      id: `${CorridorEdgeE}_${config_600}_${Ecophon_Focus_E20_T24_1250_2400x600}`,
      parentRange: CorridorEdgeE,
      parentConfig: `${CorridorEdgeE}_${config_600}`,
      parentSystem: Corridor,
      product: Ecophon_Focus_E20_T24_1250_2400x600,
    },

    //Hygiene - Edge A (15,20 mm) => Hygiene Edge A (20 mm) - 600x600
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Clinic_A15_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Clinic_A15_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Clinic_A15_T24_600x600,
      },
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Meditec_A15_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Meditec_A15_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Meditec_A15_T24_600x600,
      },
    // [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Clinic_A15_T24_600x600}`]: {
    //   id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Clinic_A15_T24_600x600}`,
    //   parentRange: HygieneEdgeA20,
    //   parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
    //   parentSystem: Hygiene,
    //   product: Ecophon_Hygiene_Clinic_A15_T24_600x600,
    // },
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Protec_A20_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Protec_A20_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Protec_A20_T24_600x600,
      },
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_ProtecAir_A20_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_ProtecAir_A20_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_ProtecAir_A20_T24_600x600,
      },
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Performance_A20_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Performance_A20_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_A20_T24_600x600,
      },
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Performance_Plus_A20_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Performance_Plus_A20_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_Plus_A20_T24_600x600,
      },
    [`${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Advance_A20_T24_600x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_600x600}_${Ecophon_Hygiene_Advance_A20_T24_600x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Advance_A20_T24_600x600,
      },
    //Hygiene - Edge A (15,20 mm) => Hygiene Edge A (20 mm) - 1200x600
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Clinic_A15_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Clinic_A15_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Clinic_A15_T24_1200x600,
      },
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Meditec_A15_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Meditec_A15_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Meditec_A15_T24_1200x600,
      },
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Protec_A20_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Protec_A20_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Protec_A20_T24_1200x600,
      },
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_ProtecAir_A20_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_ProtecAir_A20_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_ProtecAir_A20_T24_1200x600,
      },
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Performance_A20_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Performance_A20_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_A20_T24_1200x600,
      },
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Performance_Plus_A20_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Performance_Plus_A20_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_Plus_A20_T24_1200x600,
      },
    [`${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Advance_A20_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA20}_${config_1200x600}_${Ecophon_Hygiene_Advance_A20_T24_1200x600}`,
        parentRange: HygieneEdgeA20,
        parentConfig: `${HygieneEdgeA20}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Advance_A20_T24_1200x600,
      },
    //Hygiene - Edge A (40 mm) => Hygiene Edge A (40 mm) - 600x600
    [`${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_ProtecAir_A40_T24_600x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_ProtecAir_A40_T24_600x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_ProtecAir_A40_T24_600x600,
      },
    [`${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_Performance_A40_T24_600x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_Performance_A40_T24_600x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_A40_T24_600x600,
      },
    [`${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_Performance_Plus_A40_T24_600x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_Performance_Plus_A40_T24_600x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_Plus_A40_T24_600x600,
      },
    [`${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_Advance_A40_T24_600x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_600x600}_${Ecophon_Hygiene_Advance_A40_T24_600x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_600x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Advance_A40_T24_600x600,
      },
    //Hygiene - Edge A (40 mm) => Hygiene Edge A (40 mm) - 1200x600
    [`${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_ProtecAir_A40_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_ProtecAir_A40_T24_1200x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_ProtecAir_A40_T24_1200x600,
      },
    [`${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_Performance_A40_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_Performance_A40_T24_1200x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_A40_T24_1200x600,
      },
    [`${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_Performance_Plus_A40_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_Performance_Plus_A40_T24_1200x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Performance_Plus_A40_T24_1200x600,
      },
    [`${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_Advance_A40_T24_1200x600}`]:
      {
        id: `${HygieneEdgeA40}_${config_1200x600}_${Ecophon_Hygiene_Advance_A40_T24_1200x600}`,
        parentRange: HygieneEdgeA40,
        parentConfig: `${HygieneEdgeA40}_${config_1200x600}`,
        parentSystem: Hygiene,
        product: Ecophon_Hygiene_Advance_A40_T24_1200x600,
      },

    //Hygiene Concealed grid - Edge Ds (C4) - 600x600
    [`${HygieneEdgeDsC4}_${config_600x600}_${Ecophon_Hygiene_Performance_Ds20_T24_600x600}`]:
      {
        id: `${HygieneEdgeDsC4}_${config_600x600}_${Ecophon_Hygiene_Performance_Ds20_T24_600x600}`,
        parentRange: HygieneEdgeDsC4,
        parentConfig: `${HygieneEdgeDsC4}_${config_600x600}`,
        parentSystem: HygieneConcealedGrid,
        product: Ecophon_Hygiene_Performance_Ds20_T24_600x600,
      },
    //Hygiene Concealed grid - Edge Ds (C4) - 1200x600
    [`${HygieneEdgeDsC4}_${config_1200x600}_${Ecophon_Hygiene_Performance_Ds20_T24_1200x600}`]:
      {
        id: `${HygieneEdgeDsC4}_${config_1200x600}_${Ecophon_Hygiene_Performance_Ds20_T24_1200x600}`,
        parentRange: HygieneEdgeDsC4,
        parentConfig: `${HygieneEdgeDsC4}_${config_1200x600}`,
        parentSystem: HygieneConcealedGrid,
        product: Ecophon_Hygiene_Performance_Ds20_T24_1200x600,
      },
    //Hygiene Concealed grid - Edge Ds (Clean room) - 600x600
    [`${HygieneEdgeDsCleanRoom}_${config_600x600}_${Ecophon_Hygiene_Protec_Ds20_600x600}`]:
      {
        id: `${HygieneEdgeDsCleanRoom}_${config_600x600}_${Ecophon_Hygiene_Protec_Ds20_600x600}`,
        parentRange: HygieneEdgeDsCleanRoom,
        parentConfig: `${HygieneEdgeDsCleanRoom}_${config_600x600}`,
        parentSystem: HygieneConcealedGrid,
        product: Ecophon_Hygiene_Protec_Ds20_600x600,
      },
    //Hygiene Concealed grid - Edge Ds (Clean room) - 1200x600
    [`${HygieneEdgeDsCleanRoom}_${config_1200x600}_${Ecophon_Hygiene_Protec_Ds20_1200x600}`]:
      {
        id: `${HygieneEdgeDsCleanRoom}_${config_1200x600}_${Ecophon_Hygiene_Protec_Ds20_1200x600}`,
        parentRange: HygieneEdgeDsCleanRoom,
        parentConfig: `${HygieneEdgeDsCleanRoom}_${config_1200x600}`,
        parentSystem: HygieneConcealedGrid,
        product: Ecophon_Hygiene_Protec_Ds20_1200x600,
      },
  },
};
