import { Store } from "redux";

export interface WindowsHandler {
  store: Store;
}

export enum Cursor {
  Wait = "WAIT",
  Normal = "ARROW",
}

export class WindowsHandler {
  openWindow = (width: number, height: number, url: string): boolean => {
    if (window.revit) {
      window.revit.openWindow(width + 16, height + 39, url);

      if (!window.revit.isArchicad) {
        setTimeout(() => {
          window.open();
        });
      }
      return true;
    }
    return false;
  };

  resizeWindow = async (width: number, height: number): Promise<boolean> => {
    if (window.revit) {
      await window.revit.resizeWindow(width + 16, height + 39);
      return true;
    }
    return false;
  };

  closeWindow = async () => {
    if (window.revit) {
      await window.revit.closeWindow();
      if (localStorage.getItem("min")) {
        localStorage.removeItem("min");
      }
      return true;
    }
    return false;
  };

  hideWindow = async () => {
    if (window.revit) {
      await window.revit.hideWindow();
      return true;
    }
    return false;
  };

  showWindow = async () => {
    if (window.revit) {
      await window.revit.showWindow();
      return true;
    }
    return false;
  };

  showDevTools = async () => {
    if (window.revit) {
      await window.revit.showDevTools();
      return true;
    }
    return false;
  };

  moveWindow = async () => {
    if (window.revit) {
      await window.revit.moveWindow();
      return true;
    }
    if (!window.revit) {
      return true;
    }
    return false;
  };

  minimizeWindow = async () => {
    if (window.revit) {
      await window.revit.minimizeWindow();
      return true;
    }
    return false;
  };

  maximizeWindow = async () => {
    if (window.revit) {
      await window.revit.maximizeWindow();
      return true;
    }
    return false;
  };

  restoreWindow = async () => {
    if (window.revit) {
      await window.revit.restoreWindow();
      return true;
    }
    return false;
  };

  showCursor = async (cursor: Cursor) => {
    if (window.revit) {
      await window.revit.showCursor(cursor);
      return true;
    }
    if (!window.revit) {
      return true;
    }
    return false;
  };

  reloadDock = async () => {
    if (window.revit) {
      await window.revit.dockReload();
      return true;
    }
    return false;
  };
}
