import React, { useEffect, useState, useContext } from "react";
import {
  Dimmer,
  Loader,
  Button,
  Modal,
  Form,
  Radio,
  Grid,
} from "semantic-ui-react";
import { connect, ConnectedProps, useSelector, useDispatch } from "react-redux";
import { MetresStore } from "../../Reducers";
import {
  setWallScheduleLoader,
  setUnitConversionArray,
} from "./../../Actions/index";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { Selection } from "../../../Selection/Actions/types";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../../RevitJS/Types/StoreTypes";
import { loadSelections } from "../../../Selection/Actions";
import { withTranslation, WithTranslation } from "react-i18next";
import "../../Resources/main.scss";
import { QuantiType, Routes } from "../../Util/type";
import {
  QuantityContext,
  IQuantityContextType,
} from "../../Context/quantityContext";

const MetresDownloader = (props: any) => {
  const [raiseWarning, setRaiseWarning] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const reduxState = useSelector((state: MetresStore) => state);
  const { quntiType, setRoot, setQuantiType } = useContext(
    QuantityContext
  ) as IQuantityContextType;
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadSelectionBimStorage() {
      let placoSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );

      if (placoSelections) {
        let selections = placoSelections as {
          [key: string]: Selection<PlacoOptions>;
        };
        dispatch(loadSelections(selections));
      }
    }
    props.i18n.changeLanguage(reduxState.language);
    loadSelectionBimStorage();
  }, []);

  const unSetWarning = () => {
    setRaiseWarning(false);
    setWarningMessage("");
  };
  if (raiseWarning) {
    return (
      <Modal
        size="tiny"
        open={raiseWarning}
        closeIcon
        onClose={() => unSetWarning()}
      >
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>{warningMessage}</p>
            <Button color="blue" onClick={() => unSetWarning()}>
              Fermer
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  if (reduxState.wallScheduleLoader) {
    return (
      <Dimmer
        active={reduxState.wallScheduleLoader}
        style={{ height: "calc(100vh - 30px)" }}
      >
        <Loader content={props.t("PREPARING_QUANTITATIFF")}></Loader>
      </Dimmer>
    );
  }
  return (
    <Grid className="custom-ratio-types">
      <Grid.Row style={{ height: "60vh", alignItems: "center" }}>
        <Grid.Column
          width={15}
          style={{
            justifyContent: "center",
            width: "100% !important",
            display: "flex",
          }}
        >
          <Form>
            <Form.Field style={{ justifyContent: "center", display: "flex" }}>
              <Radio
                label="Quantitatifs par étage"
                name="radioGroup"
                value="floor"
                checked={quntiType === QuantiType.FLOOR}
                onChange={() => setQuantiType(QuantiType.FLOOR)}
              />
            </Form.Field>
            <Form.Field style={{ justifyContent: "center", display: "flex" }}>
              <Radio
                label="Quantitatifs par étage et par zone"
                name="radioGroup"
                value="zone"
                checked={quntiType === QuantiType.ZONE}
                onChange={() => setQuantiType(QuantiType.ZONE)}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid.Row>

      <Grid className="middel-full-width" style={{ marginTop: "30px" }}>
        <Button
          primary
          onClick={() => setRoot(Routes.ELEMENTSELECTION)}
          disabled={quntiType.length === 0 ? true : false}
        >
          Continuer
        </Button>
      </Grid>
    </Grid>
  );
};

export default withTranslation(["quantitatiff"])(MetresDownloader);
