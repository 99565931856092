import React, { useContext, useEffect, useState } from "react";
import { initLanguage } from "../../Nomenclature/Actions";
import { MyConfig } from "../../../../Helper";
import MetresLanding from "./MetresLanding";
import { Routes } from "./root";
import MetSelection from "./MetSelection";
import NomenclatureRoot from "../../Nomenclature/Components/root";
import { AppBody } from "../../Selection/Page/AppBody";

interface Props {
  route: string;
  setRoute: any;
  // initLanguage: any;
}

export const AppBodyMet = ({
  route,
  setRoute,
}: // initLanguage,
Props) => {
  const [selectionPar, setSelectionPar] = useState<boolean>(false);
  const [selectionID, setSelectionID] = useState([]);

  const setSelectionsPar = (selectionPar: boolean) => {
    setSelectionPar(selectionPar);
  };
 
  switch (route) {
    case Routes.ROOT:
      return <MetresLanding route={route} setRoute={setRoute} />;
    case Routes.SELECTION_METRES_HOME:
      return <MetSelection route={route} setRoute={setRoute} />;
    case "SELECTION_HOME": 
      return  <AppBody />;
    case Routes.QUICK_CALEPINAGE:
      return <NomenclatureRoot />;

    default:
      return null;
  }
};

export default AppBodyMet;
