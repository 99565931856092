import {
  getPlacoSystemDetailsWithChildren,
  getLayoutAttributesAid,
} from "../Requests";

import flattenDeep from "lodash/flattenDeep";
import times from "lodash/times";
import forEach from "lodash/forEach";

import { api } from "../../../../RevitJS/API";

export const filterProjectTree = (
  projectTree: any,
  selectedIds: any,
  selectedLevels: any
) => {
  return projectTree.Tree.filter((level: any) => {
    return selectedLevels.includes(level.Level.Name);
  }).map((level: any) => {
    return {
      level: level.Level.Name,
      height: level.Level.Elevation,
      elems: level.Elements.map((elems: any) => {
        let filteredIds = elems.Ids.filter((id: any) =>
          selectedIds.includes(id)
        );
        return {
          type: elems.Type,
          ids: filteredIds,
        };
      }).filter((e: any) => e.ids.length),
    };
  });
};

export const formatProjectTree = (projectTree: any) => {
  return projectTree.Tree.map((level: any) => {
    return {
      level: level.Level.Name,
      height: level.Level.Elevation,
      elems: level.Elements.map((elems: any) => {
        // let filteredIds = elems.Ids.filter(id => selectedIds.includes(id));
        return {
          type: elems.Type,
          ids: elems.Ids,
        };
      }).filter((e: any) => e.ids.length),
    };
  });
};

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByArray(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r: any) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}

export const mergeItems = (arr: any) =>
  groupByArray(arr, "Type").map((el: any) => {
    return {
      Type: el.key,
      Count: el.values.reduce(function (prev: any, cur: any) {
        return prev + cur.Count;
      }, 0),
      Checked: false,
    };
  });

export const elementTypes = (data: any) =>
  data.Tree.length > 0
    ? mergeItems(
        data.Tree.map((level: any) => level.Elements)
          .map((s: any) =>
            s.map((nested: any) => {
              return {
                Type: nested.Type,
                Count: nested.Ids.length,
                Checked: false,
              };
            })
          )
          .reduce((prev: any, current: any) => prev.concat(current))
          .sort((a: any, b: any) => (a.Type < b.Type ? -1 : 1))
      )
    : null;

export const levelData = (data: any, levellist: any) => {
  if (levellist.length === 0) return data;
  let levelsData = data.Tree.filter((level: any) =>
    levellist.includes(level.Level.Name)
  );
  return { Tree: levelsData };
};

export const selectedIds = (
  data: any,
  selectedLevels: any,
  selectedTypes: any
) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
    return data.Tree.filter((level: any) =>
      selectedLevels.includes(level.Level.Name)
    )
      .map((level: any) => level.Elements)
      .map((s: any) => s.map((nested: any) => nested))
      .reduce((prev: any, current: any) => prev.concat(current))
      .filter((e: any) => selectedTypes.includes(e.Type))
      .map((el: any) => el.Ids)
      .reduce((prev: any, current: any) => prev.concat(current));
  return [];
};

export const selectedItems = (
  data: any,
  selectedLevels: any,
  selectedTypes: any
) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
    return groupByArray(
      // group array objects by unique 'Type'
      data.Tree.filter((level: any) =>
        selectedLevels.includes(level.Level.Name)
      ) //filter an object from 'Tree' of which 'Level.Name' includes in 'selectedlevels' array
        .map((level: any) => level.Elements) // map on or loop through 'Elements' object
        .map((s: any) => s.map((nested: any) => nested)) //  map on or loop through on objects inside 'Element' array
        .reduce((prev: any, current: any) => prev.concat(current)) // reduce or concat Elements objects into single array
        .filter((e: any) => selectedTypes.includes(e.Type)), // filter single array for which 'Type' includes in 'selectedTypes'
      "Type"
    ).map((el: any) => {
      return {
        Type: el.key,
        Ids: [].concat.apply(
          [],
          el.values.map((val: any) => val.Ids)
        ),
      };
    });
  // return [];
};

export const selectedItemsManualSelection = (data: any) => {
  return groupByArray(
    data.Tree.map((level: any) => level.Elements)
      .map((s: any) => s.map((nested: any) => nested))
      .reduce((prev: any, current: any) => prev.concat(current)),
    "Type"
  ).map((el: any) => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map((val: any) => val.Ids)
      ),
    };
  });
};

export const getSystemData = async (
  systemIDs: any,
  Reverse: boolean = false,
  Flipped: boolean = false,
  config: any
) => {
  let firstSystemID = systemIDs[0];
  let attributes = await getPlacoSystemDetailsWithChildren(
    firstSystemID,
    config
  ).then(function (response) {
    return response.data.context.attributeDependencies[0].attributes;
  });
  let systemData = [];
  let attributesId = getLayoutAttributesAid(attributes);
  let nb = systemIDs.length;
  for (let i = 0; i < nb; i++) {
    let system = await getPlacoSystemDetailsWithChildren(
      systemIDs[i],
      config
    ).then(function (response) {
      // let allAttributes = response.data.objects.filter(object => object.types.includes("Product")).map(object => object.attributes);
      return response.data.objects;
    });
    systemData.push(parseSystem(system, attributesId, Reverse, Flipped));
  }

  return systemData;
};

export const parseSystem = (
  systemAndChildren: any,
  attributesId: any,
  Reverse: boolean = false,
  Flipped: boolean = false
) => {
  let system = systemAndChildren.find((obj: any) =>
    obj.types.includes("Solution Product")
  ).attributes;

  let products = systemAndChildren.filter((obj: any) =>
    obj.types.includes("Product")
  );

  let framesSpace =
    system.find((e: any) => e.aid === attributesId.framesSpace) &&
    system.find((e: any) => e.aid === attributesId.framesSpace).values[0]
      .numericValue;

  let systemName =
    system.find((e: any) => e.aid === attributesId.systemName) &&
    system.find((e: any) => e.aid === attributesId.systemName).values[0].value;

  let layers = system.filter((e: any) => e.aid === attributesId.layers)
    ? system.filter((e: any) => e.aid === attributesId.layers)
    : [];

  layers.sort(function (a: any, b: any) {
    let a_number = a.subAttributes.find(
      (e: any) => e.aid === attributesId.layerNumber
    ).values[0].numericValue;
    let b_number = b.subAttributes.find(
      (e: any) => e.aid === attributesId.layerNumber
    ).values[0].numericValue;
    return a_number - b_number;
  });

  let sgRails;
  if (attributesId.rails) {
    for (let i = 0; i < attributesId.rails.length; i++) {
      let attribute = system.find((e: any) => e.aid === attributesId.rails[i]);
      if (attribute) {
        let name = attribute.values[0].value;
        if (name) {
          if (name.startsWith("Rail") || name.startsWith("Cornière")) {
            sgRails = name;
          }
        }
      }
    }
  }

  let sgFrames;
  if (attributesId.frames) {
    for (let i = 0; i < attributesId.frames.length; i++) {
      let attribute = system.find((e: any) => e.aid === attributesId.frames[i]);
      if (attribute) {
        let name = attribute.values[0].value;
        if (name) {
          if (name.startsWith("Montant")) {
            sgFrames = name;
          }
        }
      }
    }
  }

  let systemElems = attributesId && parseLayers(layers, attributesId);

  let plateName =
    systemElems.claddingB[0].I1_Name || systemElems.claddingA[0].E1_Name;

  let plateProduct = products.find(
    (product: any) => product.translation === plateName
  );
  let plateWidth =
    attributesId && plateProduct
      ? plateProduct.attributes.find(
          (e: any) => e.aid === attributesId.plateWidth
        ).values[0].numericValue
      : 0;

  let result = {
    systemName,
    framesSpace: framesSpace * 1000,
    plateWidth: plateWidth * 1000,
    E1: systemElems.claddingA[0].E1,
    E2: systemElems.claddingA[1].E2,
    E3: systemElems.claddingA[2].E3,
    I1: systemElems.claddingB[0].I1,
    I2: systemElems.claddingB[1].I2,
    I3: systemElems.claddingB[2].I3,
    E1_Name: systemElems.claddingA[0].E1_Name,
    E2_Name: systemElems.claddingA[1].E2_Name,
    E3_Name: systemElems.claddingA[2].E3_Name,
    I1_Name: systemElems.claddingB[0].I1_Name,
    I2_Name: systemElems.claddingB[1].I2_Name,
    I3_Name: systemElems.claddingB[2].I3_Name,
    E1_Color: systemElems.claddingA[0].E1_Color,
    E2_Color: systemElems.claddingA[1].E2_Color,
    E3_Color: systemElems.claddingA[2].E3_Color,
    I1_Color: systemElems.claddingB[0].I1_Color,
    I2_Color: systemElems.claddingB[1].I2_Color,
    I3_Color: systemElems.claddingB[2].I3_Color,
    Reverse,
    Flipped,
    Lining: systemName && systemName.includes("Doublage") ? true : false,
    railsSpace: 0,
    staggering: plateWidth * 1000 > 900 ? true : false,
    doubleFraming: system.find((e: any) => e.aid === attributesId.doubleFraming)
      ? system
          .find((e: any) => e.aid === attributesId.doubleFraming)
          .values[0].value.toLowerCase() === "simple"
        ? false
        : true
      : false,
    doubleAmount: false,
    Frame1_Name: sgFrames,
    Rail1_Name: sgRails,
    SAA: systemName && systemName.includes("SAA") ? true : false,
    SAD: systemName && systemName.includes("SAD") ? true : false,
    InputType: "",
    FreeEnd: "",
    board_height: 0,
    board_height_offset: 0,
    frame_length: 0,
  };

  if (result.E1 === "None") {
    let swapper;

    swapper = result.I1;
    result.I1 = result.E1;
    result.E1 = swapper;

    swapper = result.I1_Name;
    result.I1_Name = result.E1_Name;
    result.E1_Name = swapper;

    swapper = result.I2;
    result.I2 = result.E2;
    result.E2 = swapper;

    swapper = result.I2_Name;
    result.I2_Name = result.E2_Name;
    result.E2_Name = swapper;

    swapper = result.I3;
    result.I3 = result.E3;
    result.E3 = swapper;

    swapper = result.I3_Name;
    result.I3_Name = result.E3_Name;
    result.E3_Name = swapper;

    swapper = result.I1_Color;
    // result.I1_Color = result.E1_Color;
    result.E1_Color = swapper;

    swapper = result.I2_Color;
    // result.I2_Color = result.E2_Color;
    result.E2_Color = swapper;

    swapper = result.I3_Color;
    // result.I3_Color = result.E3_Color;
    result.E3_Color = swapper;
  }

  return result;
};

export const parseLayers = (layers: any, attributesId: any) => {
  let layers_nb = layers.length;
  let firstCladding = true;
  let claddingA: any = {},
    claddingB: any = {},
    rails: any[] = [],
    frames: any[] = [];

  let claddingOrder = 1;

  for (let j = 0; j < layers_nb; j++) {
    let layerAttributes = layers[j].subAttributes;
    let layerContent = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerContent
    )?.values[0].value;
    let layerThickness = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerThickness
    ).values[0].numericValue;
    let layerName = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerName
    ).values[0].value;

    let layerNumber = layerAttributes.find(
      (e: any) => e.aid === attributesId.layerNumber
    ).values[0].numericValue;

    if (layerContent === "Plaque de plâtre") {
      let layerColor = layerAttributes.find(
        (e: any) => e.aid === attributesId.layerColor
      ).values[0].value;

      if (firstCladding) {
        claddingA["E" + claddingOrder] = {
          board_height: 0,
          board_height_offset: 0,
          color: layerColor,
          name: layerName,
          width: layerThickness,
          layerIndex: layerNumber,
        };
      } else {
        claddingB["I" + claddingOrder] = {
          board_height: 0,
          board_height_offset: 0,
          color: layerColor,
          name: layerName,
          width: layerThickness,
          layerIndex: layerNumber,
        };
      }

      claddingOrder = claddingOrder + 1;
    } else {
      claddingOrder = 1;
      firstCladding = false;
    }
  }
  return {
    claddingA: claddingA,
    claddingB: claddingB,
    rails,
    frames,
  };
};

export const makeLayer = (
  side: any,
  index: any,
  layerThickness: any,
  layerName: any,
  layerColor: any
) => {
  let layer: any = {};
  const lstr = side + index.toString();
  layer[lstr] = layerThickness;
  layer[side + index.toString() + "_Name"] = layerName;
  layer[side + index.toString() + "_Color"] = layerColor;
  return layer;
};

export const addDefaultLayer = (cladding: any, side: any) => {
  cladding = JSON.parse(JSON.stringify(cladding));

  if (!cladding.E1) {
    cladding["E1"] = {
      board_height: 0,
      board_height_offset: 0,
      color: "None",
      name: "None",
      width: "None",
    };
  }

  if (!cladding.E2) {
    cladding["E2"] = {
      board_height: 0,
      board_height_offset: 0,
      color: "None",
      name: "None",
      width: "None",
    };
  }

  if (!cladding.E3) {
    cladding["E3"] = {
      board_height: 0,
      board_height_offset: 0,
      color: "None",
      name: "None",
      width: "None",
    };
  }

  if (!cladding.I1) {
    cladding["I1"] = cladding["E1"];
  }

  if (!cladding.I2) {
    cladding["I2"] = cladding["E2"];
  }

  if (!cladding.I3) {
    cladding["I3"] = cladding["E3"];
  }
  return cladding;
};

export const makeDefaultLayer = (side: any, index: any) => {
  let layer: any = {};
  layer[side + index] = "None";
  layer[side + index + "_Name"] = "None";
  return layer;
};

export const updatePackagedArticle = (wallTypesData: any, state: any) => {
  const { extractDetails, montant, plaque } = state;

  forEach(wallTypesData, (elem, index) => {
    times(3, (i) => {
      forEach(plaque[index], (item) => {
        if (
          wallTypesData[index][`E${i + 1}_Name`].includes(item.split(" ")[0])
        ) {
          wallTypesData[index][`E${i + 1}_Name`] = item;
          wallTypesData[index][`I${i + 1}_Name`] = item;
        }
      });
    });
    wallTypesData[index].Frame1_Name = montant[index][0];
    wallTypesData[index].Rail1_Name = extractRails(extractDetails[index]);
  });
};

const extractRails = (extractDetail: any) => {
  let gfrFirst = extractDetail.rails[0].GFR[0];
  if (extractDetail.rails[0].product.translation === "Rail Stil® R 48") {
    extractDetail.rails[0].GFR.forEach((element: any) => {
      let articles = element.packagedarticles.articles;
      if (articles.length > 0) {
        if (articles[0].translation === "Rail Stil® R 48/300 bot10L") {
          gfrFirst = element;
        }
      }
    });
  }
  let articles = gfrFirst.packagedarticles.articles;
  let article = articles[0];
  return article.translation;
};

export const drawWall =
  (setGroupNumber: any, increment: any, progress: any) =>
  async (layoutData: any) => {
    let incrementProgress = progress;
    let flattenGroups: any = flattenDeep(layoutData);

    let groupsNb: number = flattenGroups.length;

    setGroupNumber && setGroupNumber(groupsNb);

    // await api.familyEditor.drawWallSetter(flattenGroups);

    for (let g = 0; g < groupsNb; g++) {
      try {
        await api.familyEditor.createAndSetParameters(
          "Wall",
          "Instance",
          "DATA",
          [
            {
              Id: flattenGroups[g].id,
              Params: [
                { Name: "Processed", Type: "YesNo", Value: 1 },
                {
                  Name: "SG_System",
                  Type: "Text",
                  Value: flattenGroups[g].technicalName,
                },
                {
                  Name: "Flipped",
                  Type: "YesNo",
                  Value: flattenGroups[g].flipped ? 1 : 0,
                },
                {
                  Name: "Reverse",
                  Type: "YesNo",
                  Value: flattenGroups[g].reverse ? 1 : 0,
                },
              ],
            },
          ]
        );

        if (flattenGroups[g].rails.length)
          await api.familyEditor.placeFamiliesAtPoints(flattenGroups[g].rails);

        if (flattenGroups[g].flipped_rails.length)
          await api.familyEditor.placeFamiliesAtPoints(
            flattenGroups[g].flipped_rails
          );

        if (flattenGroups[g].frames.length) {
          let simpleFrames = flattenGroups[g].frames.filter(
            (frame: any) => frame.FamilyName === "Placo_Ossature"
          );
          let doubleFrames = flattenGroups[g].frames.filter(
            (frame: any) => frame.FamilyName === "Placo_Ossature-Double"
          );
          if (simpleFrames.length) {
            await api.familyEditor.placeFamiliesAtPoints(simpleFrames);
          }
          if (doubleFrames.length) {
            await api.familyEditor.placeFamiliesAtPoints(doubleFrames);
          }
        }

        flattenGroups[g].plasterboards.length &&
          (await api.familyEditor.placeFamiliesAtPoints(
            flattenGroups[g].plasterboards
          ));
      } catch (ex) {}
      incrementProgress = incrementProgress + 1;
      increment && increment(incrementProgress);
    }
  };

/**
 * Here export functions from different file within Helpers folder
 */
