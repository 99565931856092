import { Action } from 'redux'
import { PimAttribute, ProductDetailData, ProductMeta } from "../../../../../RevitJS/Types/BddTypes";
import { CustomParameter } from '../../../../../RevitJS/Types/RevitTypes';

export const LOAD_SYSTEMS = "LOAD_SYSTEMS";
export const LOAD_SYSTEMS_SUCCESS = "LOAD_SYSTEMS_SUCCESS";
export const LOAD_SYSTEMS_ERROR = "LOAD_SYSTEMS_ERROR";

export const LOAD_SYSTEMS_DETAILS = "LOAD_SYSTEMS_DETAILS";
export const LOAD_SYSTEMS_DETAILS_SUCCESS = "LOAD_SYSTEMS_DETAILS_SUCCESS";
export const LOAD_SYSTEMS_DETAILS_ERROR = "LOAD_SYSTEMS_DETAILS_ERROR";
export const LOAD_SYSTEMS_DETAILS_MORE = "LOAD_SYSTEMS_DETAILS_MORE"

export const RESTORE_SYSTEMS_DETAILS = 'RESTORE_SYSTEMS_DETAILS'

export const FILTER_SYSTEMS = "FILTER_SYSTEMS"
export const DISPLAY_FAVORITE = "DISPLAY_FAVORITE"
export const DISPLAY_SYSTEMS = "DISPLAY_SYSTEMS"

export const ADD_SEARCH_KEY_WORD = "ADD_SEARCH_KEY_WORD"
export const DELETE_SEARCH_KEY_WORD = "DELETE_SEARCH_KEY_WORD"
export const RESET_SEARCH_KEY_WORD = "RESET_SEARCH_KEY_WORD"

export const LOAD_EXISTING_SYSTEMS = "LOAD_EXISTING_SYSTEMS"

export interface SystemsState {
    loadingSystems: boolean,
    loadingSystemsDetails: boolean,
    systems: ProductMeta[]
    systemsDetails: ProductDetailData[],
    currentSystemDetail: ProductDetailData[],
    data: {
        wallsSystemDetails: ProductDetailData[],
        floorSystemDetails: ProductDetailData[],
        wallsSystemMetaData: any[],
        floorSystemMetaData: any[],

        // liningWallsDetails: ProductDetailData[],
        // partitionsDetails: ProductDetailData[],
        // ceilingsDetails: ProductDetailData[],
        // liningWallsMetaData: any[],
        // partitionsMetaData: any[],
        // ceilingsMetaData: any[],
    }
    systemsMetaData: any[],
    displayedSystemsCount: number,
    systemTypeID: any,//number,
    favoriteDisplayed: boolean,
    existingSystems: CustomParameter[],
    searchKeyWords: string[]
}

interface LoadSystemsAction extends Action {
    type: typeof LOAD_SYSTEMS
}


interface LoadSystemsSuccessAction extends Action {
    type: typeof LOAD_SYSTEMS_SUCCESS;
    systems: ProductMeta[]
}

interface LoadSystemsErrorAction extends Action {
    type: typeof LOAD_SYSTEMS_ERROR;
}

interface LoadSystemsDetailsAction extends Action {
    type: typeof LOAD_SYSTEMS_DETAILS
}


interface LoadSystemsDetailsSuccessAction extends Action {
    type: typeof LOAD_SYSTEMS_DETAILS_SUCCESS;
    currentSystemsDetailsWalls: ProductDetailData[],
    currentSystemsDetailsFloor: ProductDetailData[],
    systemsMetaDataWalls: ProductMeta[],
    systemsMetaDataFloor: ProductMeta[],
    // currentSystemsDetailsPartitions: ProductDetailData[],
    // currentSystemsDetailsPlafonds: ProductDetailData[],
    // currentSystemsDetailsLiningWalls: ProductDetailData[],
    // systemsMetaDataPartitions: ProductMeta[],
    // systemsMetaDataPlafonds: ProductMeta[],
    // systemsMetaDataLiningWalls: ProductMeta[],
    systemType: any,//number
}

interface RestoreSystemsDetailsAction extends Action {
    type: typeof RESTORE_SYSTEMS_DETAILS,
    currentSystemsDetails: ProductDetailData[],
    systemsMetaData: any[],
    systemType: number
}

interface LoadSystemsDetailsErrorAction extends Action {
    type: typeof LOAD_SYSTEMS_DETAILS_ERROR;
}

interface LoadSystemsDetailsMoreAction extends Action {
    type: typeof LOAD_SYSTEMS_DETAILS_MORE;
    systemsDetails: ProductDetailData[],
}

interface FilterSystemsAction extends Action {
    type: typeof FILTER_SYSTEMS;
    currentSystemsDetails: ProductDetailData[],
}

interface AddSearchKeyWordAction extends Action {
    type: typeof ADD_SEARCH_KEY_WORD;
    searchWord: string,
}

interface DeleteSearchKeyWordAction extends Action {
    type: typeof DELETE_SEARCH_KEY_WORD;
    searchWordIndex: number,
}

export interface ResetSearchKeyWordAction extends Action {
    type: typeof RESET_SEARCH_KEY_WORD;
}

interface DisplayFavoriteAction extends Action {
    type: typeof DISPLAY_FAVORITE;
    systemsDetails: ProductDetailData[]
}

interface DisplaySystemAction extends Action {
    type: typeof DISPLAY_SYSTEMS
    systemsDetails: ProductDetailData[]
}

interface LoadExistingSystemsAction extends Action {
    type: typeof LOAD_EXISTING_SYSTEMS
    existingSystems: CustomParameter[]
}

export interface PimAttributeWithCategorie {
    attribute: PimAttribute,
    technicalName: string,
    translateName: string,
    attributesCategorie: string,
}

export type SystemsActionTypes = LoadSystemsAction | LoadSystemsSuccessAction | LoadSystemsErrorAction
    | LoadSystemsDetailsAction | LoadSystemsDetailsSuccessAction | LoadSystemsDetailsErrorAction | LoadSystemsDetailsMoreAction
    | RestoreSystemsDetailsAction
    | FilterSystemsAction
    | DisplayFavoriteAction | DisplaySystemAction
    | LoadExistingSystemsAction
    | AddSearchKeyWordAction | DeleteSearchKeyWordAction | ResetSearchKeyWordAction
