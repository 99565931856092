import React, { useState } from "react";
import WindowHeader from "../WindowHeader/WindowHeader";
import { GetPluginDetails } from "./PluginRoot";
import { GetUserDetails } from "./../Session/Authentication";
import { bimStorage, storageKey } from "../../../BIMStore";
import _ from "lodash";
import { SessionBox } from "../Session/SessionBox";
import { MyConfig } from "../../../Helper";
interface Props {
  className?: string;
  children: any;
  pluginId: string;
  functionalityId: string;
  module: string;
  textColor?: string;
}

export const ParentRoot = (props: Props) => {
  const [name, setName] = useState<string>("");
  localStorage.setItem("module", props.module);
  async function getAppsDetails() {
    let nameStore: any;
    let getAppsData = await bimStorage.getItem(storageKey.ALL_APPLICATION_DATA);
    let plugindetails = GetPluginDetails(props.pluginId, props.functionalityId);
    // let userdetails = GetUserDetails();
    _.find(getAppsData, function (o) {
      if (o.applicationTechnicalName === plugindetails.technicalName) {
        if (o.applicationTechnicalName === "CLT") {
          nameStore = "CLT4BIM";
        } else {
          nameStore = o.applicationName;
        }
      }
    });
    setName(nameStore);
  }
  getAppsDetails();
  return (
    <React.Fragment>
      {!window.revit.isArchicad ? (
        <WindowHeader
          openConfirm={true}
          pluginName={name}
          module={props.module}
          textColor={props.textColor ? props.textColor : "#2185d0"}
          className={props.className}
        />
      ) : (
        ""
      )}
      <SessionBox parent="plugin">{props.children}</SessionBox>
      {/* {props.children} */}
    </React.Fragment>
  );
};

export default ParentRoot;
