import { applyMiddleware, createStore, Store } from "redux";
import { thunk } from "../middleware";
import reducers, { RootState } from "../reducers";

export default function configureStore(initialState = RootState): Store {
    let middleware = applyMiddleware(
        thunk
    );

    const store = createStore(
        reducers as any,
        initialState as any,
        middleware
    ) as Store;

    return store;
}
