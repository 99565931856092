import { Borders, Cell, Worksheet, Color } from "exceljs";
import _ from "lodash";

export const filterProjectTree = (
  projectTree: any,
  selectedIds: any,
  selectedLevels: any
) => {
  return projectTree.Tree.filter((level: any) => {
    return selectedLevels.includes(level.Level.Name);
  }).map((level: any) => {
    return {
      level: level.Level.Name,
      height: level.Level.Elevation,
      elems: level.Elements.map((elems: any) => {
        let filteredIds = elems.Ids.filter((id: any) =>
          selectedIds.includes(id)
        );
        return {
          type: elems.Type,
          ids: filteredIds,
        };
      }).filter((e: any) => e.ids.length),
    };
  });
};

export const formatProjectTree = (projectTree: any) => {
  return projectTree.Tree.map((level: any) => {
    return {
      level: level.Level.Name,
      height: level.Level.Elevation,
      elems: level.Elements.map((elems: any) => {
        // let filteredIds = elems.Ids.filter(id => selectedIds.includes(id));
        return {
          type: elems.Type,
          ids: elems.Ids,
        };
      }).filter((e: any) => e.ids.length),
    };
  });
};

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByArray(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r: any) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}

export const mergeItems = (arr: any) =>
  groupByArray(arr, "Type").map((el: any) => {
    return {
      Type: el.key,
      Count: el.values.reduce(function (prev: any, cur: any) {
        return prev + cur.Count;
      }, 0),
      Checked: false,
    };
  });

export const elementTypes = (data: any) =>
  data.Tree.length > 0
    ? mergeItems(
        data.Tree.map((level: any) => level.Elements)
          .map((s: any) =>
            s.map((nested: any) => {
              return {
                Type: nested.Type,
                Count: nested.Ids.length,
                Checked: false,
              };
            })
          )
          .reduce((prev: any, current: any) => prev.concat(current))
          .sort((a: any, b: any) => (a.Type < b.Type ? -1 : 1))
      )
    : null;

export const levelData = (data: any, levellist: any) => {
  if (levellist.length === 0) return data;
  let levelsData = data.Tree.filter((level: any) =>
    levellist.includes(level.Level.Name)
  );
  return { Tree: levelsData };
};

export const selectedIds = (
  data: any,
  selectedLevels: any,
  selectedTypes: any
) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
    return data.Tree.filter((level: any) =>
      selectedLevels.includes(level.Level.Name)
    )
      .map((level: any) => level.Elements)
      .map((s: any) => s.map((nested: any) => nested))
      .reduce((prev: any, current: any) => prev.concat(current))
      .filter((e: any) => selectedTypes.includes(e.Type))
      .map((el: any) => el.Ids)
      .reduce((prev: any, current: any) => prev.concat(current));
  return [];
};

export const selectedItems = (
  data: any,
  selectedLevels: any,
  selectedTypes: any
) => {
  return groupByArray(
    // group array objects by unique 'Type'
    data.Tree.filter((level: any) => selectedLevels.includes(level.Level.Name)) //filter an object from 'Tree' of which 'Level.Name' includes in 'selectedlevels' array
      .map((level: any) => level.Elements) // map on or loop through 'Elements' object
      .map((s: any) => s.map((nested: any) => nested)) //  map on or loop through on objects inside 'Element' array
      .reduce((prev: any, current: any) => prev.concat(current)) // reduce or concat Elements objects into single array
      .filter((e: any) => selectedTypes.includes(e.Type)), // filter single array for which 'Type' includes in 'selectedTypes'
    "Type"
  ).map((el: any) => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map((val: any) => val.Ids)
      ),
    };
  });
  // return [];
};

export const selectedItemsManualSelection = (data: any) => {
  return groupByArray(
    data.Tree.map((level: any) => level.Elements)
      .map((s: any) => s.map((nested: any) => nested))
      .reduce((prev: any, current: any) => prev.concat(current)),
    "Type"
  ).map((el: any) => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map((val: any) => val.Ids)
      ),
    };
  });
};

export const writeToCellAndStyle = (
  dataSheet: Worksheet,
  cellKey: string,
  value: any,
  NACells: boolean = false,
  isTwoDigit: boolean
) => {
  let cell = dataSheet.getCell(cellKey);
  cell.unmerge();
  cell.value = value;
  if (isTwoDigit) {
    cell.numFmt = "0.00";
  }
  cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
  let num: any = cell.value;
  if (NACells && num === "NA") {
    cell.value = "";
    cell.style.fill = {
      type: "pattern",
      pattern: "lightUp",
      fgColor: {
        argb: "000000",
      },
      bgColor: {
        argb: "D9D9D9",
      },
    };
    let n: number = parseInt(cell.row);
    dataSheet.getRow(n).getCell("A").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
    dataSheet.getRow(n).getCell("B").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
    dataSheet.getRow(n).getCell("C").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: "C0C0C0",
      },
    };
    // dataSheet.getRow(n).getCell("D").fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: {
    //         argb: "C0C0C0",
    //     },
    // };
  }
  cell.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
};

const insertDetailsHeaderCell = (cell: Cell, value: any) => {
  let border: Partial<Borders> = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  cell.value = value;
  cell.border = _.cloneDeep(border);
  cell.font = { ...cell.font, bold: true, size: 11 };
  cell.alignment = {
    ...cell.alignment,
    horizontal: "center",
    vertical: "middle",
  };
};

export const addDetailsArrayHeader = (
  datasheet: Worksheet,
  startingIndex: number
) => {
  let detailsHeaderCells = [
    { letter: "A", value: "Niveau" },
    { letter: "B", value: "Référence interne \n (Système REVIT)" },
    { letter: "C", value: "S (m²)" },
    { letter: "D", value: "Id du mur" },
    { letter: "E", value: "H (m)" },
    { letter: "F", value: "L (m)" },
  ];
  // datasheet.mergeCells(`B${startingIndex + 1}:C${startingIndex + 1}`);
  detailsHeaderCells.forEach((cell: any) => {
    insertDetailsHeaderCell(
      datasheet.getCell(`${cell.letter}${startingIndex + 1}`),
      cell.value
    );
  });

  datasheet.mergeCells(`A${startingIndex}:F${startingIndex}`);
  let detailsCell = datasheet.getCell(`A${startingIndex}`);
  detailsCell.value = "GLOBAL PAR ETAGE PAR MUR";
  detailsCell.alignment = {
    ...detailsCell.alignment,
    horizontal: "left",
    vertical: "middle",
  };
  detailsCell.font.bold = true;
  detailsCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "FFCE00",
    },
  };
};

export const addCeilingsDetailsArrayHeader = (
  datasheet: Worksheet,
  startingIndex: number
) => {
  let detailsHeaderCells = [
    { letter: "A", value: "Niveau" },
    { letter: "B", value: "Référence interne \n (Système REVIT)" },
    { letter: "C", value: "S (m²)" },
    { letter: "D", value: "Id du plafond" },
    { letter: "E", value: "Périmètre (m)" },
    { letter: "F", value: "HSP (m)" },
  ];
  //datasheet.mergeCells(`B${startingIndex + 1}:C${startingIndex + 1}`);
  detailsHeaderCells.forEach((cell: any) => {
    insertDetailsHeaderCell(
      datasheet.getCell(`${cell.letter}${startingIndex + 1}`),
      cell.value
    );
  });

  datasheet.mergeCells(`A${startingIndex}:F${startingIndex}`);
  let detailsCell = datasheet.getCell(`A${startingIndex}`);
  detailsCell.value = "GLOBAL PAR ETAGE PAR PLAFOND";
  detailsCell.alignment = {
    ...detailsCell.alignment,
    horizontal: "left",
    vertical: "middle",
  };
  detailsCell.font.bold = true;
  detailsCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "FFCE00",
    },
  };
};

export const addGlobalLevelHeader = (
  datasheet: Worksheet,
  startingIndex: number
) => {
  let detailsHeaderCells = [
    { letter: "A", value: "Niveau" },
    { letter: "B", value: "Référence interne \n (Système REVIT)" },
    { letter: "C", value: "S (m²)" },
  ];
  //datasheet.mergeCells(`B${startingIndex + 1}:C${startingIndex + 1}`);
  detailsHeaderCells.forEach((cell: any) => {
    insertDetailsHeaderCell(
      datasheet.getCell(`${cell.letter}${startingIndex + 1}`),
      cell.value
    );
  });

  datasheet.mergeCells(`A${startingIndex}:F${startingIndex}`);
  let detailsCell = datasheet.getCell(`A${startingIndex}`);
  detailsCell.value = "GLOBAL PAR ETAGE";
  detailsCell.alignment = {
    ...detailsCell.alignment,
    horizontal: "left",
    vertical: "middle",
  };
  detailsCell.font.bold = true;
  detailsCell.font = { color : {argb: "FFFFFF" }};
  detailsCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "005EB8",
    },
  };
};
