import React from "react";
import { Button, Modal, Header, List, Container } from "semantic-ui-react";
import "../../Resources/modal.css";

export const InformationModal = (props: any) => {
  let messageText = null;
  let listClass = props.infoState.messageList.length > 1 ? "" : "noLi"
;  messageText = (
    <Container>
      <div className="InfoHdrmsg">
        <p>{props.infoState.subjectMsg}</p>
      </div>
      {props.infoState.messageList && props.infoState.messageList.length > 0 ?
      <div className="msgListPn">
        <ul>
          {props.infoState.messageList.map((element: any, index: number) => (
            <li className={listClass} key={index}>{element}</li>
          ))}
        </ul>
      </div> : null
      }
      {props.infoState.footerMessage !== undefined ? (
        <div className="InfoFtrmsg">
          <p>{props.infoState.footerMessage}</p>
        </div>
      ) : null}
    </Container>
  );

  return (
    <Modal open={props.informationModal} size="tiny" dimmer="inverted">
      <Modal.Description style={{ textAlign: "center", margin: "15px 20px" }}>
        <Header style={{ color: "rgb(14, 110, 184)" }}>Information</Header>
        <Container>{messageText}</Container>
      </Modal.Description>
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
      {props.buttonList.map((button: any) =>
        <Button key={button.name} size="tiny" color={button.color && button.color!="" ? button.color : "blue"} onClick={() => button.action()}>
          {button.name}
        </Button>
      )}
      </div>
    </Modal>
  );
};