import {
  PlacoData,
  ProductDetailData,
  PIMLayoutAttributesAid,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import { ElementsTree, Language } from "../../../../RevitJS/Types/RevitTypes";
import {
  TypeData,
  LevelData,
  MappingRowEcophon,
} from "../../../../RevitJS/Types/StoreTypes";
import { elementTypes } from "../../../../RevitJS/Helpers";

export const FETCH_PLACO_DATA = "INIT_PLACO_DATA";
export const SET_SELECTION_TREE = "SET_SELECTION_TREE";
export const INIT_GROUP_SELECTOR = "SET_GROUP_SELECTOR";
export const SELECT_TYPE = "SELECT_TYPE";
export const SELECT_LEVEL = "SELECT_LEVEL";
export const INIT_MAPPING_TABLE = "INIT_MAPPING_TABLE";
export const SELECT_SYSTEM = "SELECT_SYSTEM";
export const TOGGLE_CHECK = "TOGGLE_CHECK";
export const TOGGLE_ALL = "TOGGLE_ALL";
export const INIT_PRODUCT_SELECTOR = "INIT_PRODUCT_SELECTOR";
export const SET_SELECTION_BUFFER = "SET_SELECTION_BUFFER";
export const SET_ACTIVE_MAPPING_ROW_INDEX = "SET_ACTIVE_MAPPING_ROW_INDEX";
export const RESET_ACTIVE_MAPPING_ROW = "RESET_ACTIVE_MAPPING_ROW";
export const RESET_SELECTION_BUFFER = "RESET_SELECTION_BUFFER";
export const SEARCH = "SEARCH";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const INIT_LAYOUT_PROGRESS = "INIT_LAYOUT_PROGRESS";
export const UPDATE_LAYOUT_PROGRESS = "UPDATE_LAYOUT_PROGRESS";
export const START_LAYOUT = "START_LAYOUT";
export const END_PROGRESS = "END_PROGRESS";

export const FAMILY_LOADING = "FAMILY_LOADING";
export const END_LAYOUT = "END_LAYOUT";
export const FETCH_RIGIPS_DATA = "FETCH_RIGIPS_DATA";
export const SET_FIRE_CONSTRAIN = "SET_FIRE_CONSTRAIN";
export const SET_PUBLIC_CONSTRAIN = "SET_PUBLIC_CONSTRAIN";
export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SELECT_ECOPHON_SYSTEM = "SELECT_ECOPHON_SYSTEM";
export const SELECT_RANGE = "SELECT_RANGE";
export const SELECT_CONFIGURATION = "SELECT_CONFIGURATION";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const SELECT_ANGLE = "SELECT_ANGLE";
export const SELECT_PLENUM_HEIGHT = "SELECT_PLENUM_HEIGHT";
export const SELECT_MYHANGER = "SELECT_MYHANGER";

export interface InitLanguageAction {
  type: typeof INIT_LANGUAGE;
  language: Language;
}

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: Language;
}

export type LanguageAction = InitLanguageAction | SetLanguageAction;

export interface FetchDataAction {
  type: typeof FETCH_PLACO_DATA;
  liningWallsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  partitionsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  layoutAttributesAid: PIMLayoutAttributesAid;
}

export interface SetSelectionTreeAction {
  type: typeof SET_SELECTION_TREE;
  tree: ElementsTree;
}

export interface InitGroupSelectorAction {
  type: typeof INIT_GROUP_SELECTOR;
  tree: ElementsTree;
}

export interface SelectLevelAction {
  type: typeof SELECT_LEVEL;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectTypeAction {
  type: typeof SELECT_TYPE;
  typesData: TypeData[];
  selectedTypes: string[];
}

export interface InitMappingTableAction {
  type: typeof INIT_MAPPING_TABLE;
  mappingRows: MappingRowEcophon[];
}

export interface SelectSystemAction {
  type: typeof SELECT_SYSTEM;
  index: number;
  system: ProductDetailData;
}

export interface ToggleCheckAction {
  type: typeof TOGGLE_CHECK;
  Index: number;
}

export interface ToggleAllAction {
  type: typeof TOGGLE_ALL;
  checked: boolean;
}

export interface InitProductSelectorAction {
  type: typeof INIT_PRODUCT_SELECTOR;
  displayedElements: ProductDetailData[];
  filteredElements: ProductDetailData[];
}

export interface SetSelectionBufferAction {
  type: typeof SET_SELECTION_BUFFER;
  system: ProductDetailData;
}

export interface SetActiveRowIndexAction {
  type: typeof SET_ACTIVE_MAPPING_ROW_INDEX;
  index: number;
}

export interface ResetActiveRowAction {
  type: typeof RESET_ACTIVE_MAPPING_ROW;
}

export interface ResetSelectionBufferAction {
  type: typeof RESET_SELECTION_BUFFER;
}

export interface SearchAction {
  type: typeof SEARCH;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  searchedWord: string;
}

export interface ApplyFiltersAction {
  type: typeof APPLY_FILTERS;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  filters: { partitions: any[]; liningWalls: any[] };
}

export interface ResetFiltersAction {
  type: typeof RESET_FILTERS;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  filters: { partitions: any[]; liningWalls: any[] };
}

export interface InitLayoutProgressAction {
  type: typeof INIT_LAYOUT_PROGRESS;
}

export interface UpdateLayoutProgressAction {
  type: typeof UPDATE_LAYOUT_PROGRESS;
  progress: number;
}

export interface StartLayoutAction {
  type: typeof START_LAYOUT;
}

export interface FamilyLoadingAction {
  type: typeof FAMILY_LOADING;
}

export interface EndLayoutAction {
  type: typeof END_LAYOUT;
}

export interface EndProgressAction {
  type: typeof END_PROGRESS;
}

export interface FetchRigipsDataAction {
  type: typeof FETCH_RIGIPS_DATA;
  products: ProductMeta[];
}

export interface SetFireConstrainAction {
  type: typeof SET_FIRE_CONSTRAIN;
  index: number;
  fireConstrain: boolean;
}

export interface SetPublicConstrainAction {
  type: typeof SET_PUBLIC_CONSTRAIN;
  index: number;
  publicConstrain: boolean;
}

export interface SelectEcophonSystemAction {
  type: typeof SELECT_ECOPHON_SYSTEM;
  index: number;
  id: string;
}

export interface SelectMyHanger {
  type: typeof SELECT_MYHANGER;
  index: number;
  id: string;
}

export interface SelectRange {
  type: typeof SELECT_RANGE;
  index: number;
  id: string;
}

export interface SelectConfiguration {
  type: typeof SELECT_CONFIGURATION;
  index: number;
  id: string;
}

export interface SelectProduct {
  type: typeof SELECT_PRODUCT;
  index: number;
  id: string;
}

export interface SelectAngle {
  type: typeof SELECT_ANGLE;
  index: number;
  id: number;
}

export interface SelectHeightUnderCeiling {
  type: typeof SELECT_PLENUM_HEIGHT;
  index: number;
  id: number;
}

export type ConstrainAction = SetFireConstrainAction | SetPublicConstrainAction;

export type DataAction = FetchDataAction | FetchRigipsDataAction;

export type FilterAction = FetchDataAction;

export type SelectionTreeAction = SetSelectionTreeAction;

export type SelectorAction =
  | InitGroupSelectorAction
  | SelectLevelAction
  | SelectTypeAction;

export type MappingTableAction =
  | InitMappingTableAction
  | SelectSystemAction
  | ToggleCheckAction
  | ToggleAllAction
  | SelectRangeAction
  | SelectConfigurationAction
  | SelectAngleAction
  | SelectHeightUnderCeilingAction
  | SelectMyHanger
  | SelectProductAction;

export type ProductSelectorAction =
  | InitProductSelectorAction
  | SetSelectionBufferAction
  | SetActiveRowIndexAction
  | ResetActiveRowAction
  | ResetSelectionBufferAction
  | SearchAction
  | ApplyFiltersAction
  | ResetFiltersAction;

export type LayoutAction =
  | InitLayoutProgressAction
  | UpdateLayoutProgressAction
  | StartLayoutAction
  | FamilyLoadingAction
  | EndLayoutAction
  | EndProgressAction;

export type SelectEcophonSystemActions = SelectEcophonSystemAction;
export type SelectRangeAction = SelectEcophonSystemAction | SelectRange;
export type SelectConfigurationAction =
  | SelectEcophonSystemAction
  | SelectRange
  | SelectConfiguration;

export type SelectProductAction =
  | SelectEcophonSystemAction
  | SelectRange
  | SelectConfiguration | SelectProduct | SelectMyHanger;

export type SelectAngleAction = SelectEcophonSystemAction | SelectAngle;
export type SelectHeightUnderCeilingAction = SelectEcophonSystemAction | SelectHeightUnderCeiling;
