import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Grid,
  Form,
  Input,
  Modal,
  Icon,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import { FacadeStore } from "../../Store/Reducers";
import wording from "../../Resources/wording.json";
import { SetModuleData, SetParameter } from "../../Store/Actions";
import { dbStoreNameFacadeCalepinage, Routes } from "./root";
import { bimStorage } from "../../../../BIMStore";

const Parameters = (props: any) => {
  const reduxState = useSelector((state: FacadeStore) => state);
  const language: string = reduxState.language;
  const buffer = reduxState.buffer;
  const projectData = reduxState.projectData;
  const dispatch = useDispatch();

  const [epais, setEpais] = useState<any>(buffer.parameteres.data.epais);
  const [rong, setRong] = useState<any>(buffer.parameteres.data.rong);
  const [large, setLarge] = useState<any>(buffer.parameteres.data.large);

  const [grid, setGrid] = useState<any>(buffer.parameteres.data.grid);
  const [minDist, setMinDist] = useState<any>(buffer.parameteres.data.minDist);
  const [minLength, setMinLength] = useState<any>(
    buffer.parameteres.data.minLength
  );
  const [maxLength, setMaxLength] = useState<any>(
    buffer.parameteres.data.maxLength
  );
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const translation: any = wording;

  const onInputChanged = (type: string, value: string) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) || value === "") {
      switch (type) {
        case "grid":
          setGrid(value);
          break;
        case "minDist":
          setMinDist(value);
          break;
        case "minLength":
          setMinLength(value);
          break;
        case "maxLength":
          setMaxLength(value);
          break;
        case "epais":
          setEpais(value);
          break;
        case "rong":
          setRong(value);
          break;
        case "large":
          setLarge(value);
          break;
        default:
          break;
      }
    }
  };

  const onValidateClicked = async () => {
    let data = {
      grid: grid,
      minDist: minDist,
      maxLength: maxLength,
      minLength: minLength,
      epais: epais,
      rong: rong,
      large: large,
    };

    dispatch(SetParameter(data));
    await onAutoSave();
    props.setRoute(Routes.NEW_CALEPINAGE);
  };

  const onBackClicked = () => {
    if (
      grid !== buffer.parameteres.data.grid ||
      minDist !== buffer.parameteres.data.minDist ||
      minLength !== buffer.parameteres.data.minLength ||
      maxLength !== buffer.parameteres.data.maxLength
    ) {
      setShowWarning(true);
    } else {
      props.setRoute(Routes.NEW_CALEPINAGE);
    }
  };

  const onNoClicked = () => {
    props.setRoute(Routes.NEW_CALEPINAGE);
  };

  const onAutoSave = async () => {
    await bimStorage.setModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId,
      buffer.name,
      buffer.date,
      buffer.id,
      JSON.stringify(buffer)
    );

    let calepinageData = await bimStorage.listModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId
    );

    dispatch(SetModuleData(calepinageData));
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={
            reduxState.config.REACT_APP_SERVERURL + "/Facade/calpinageTitle.png"
          }
          name={"Calepinage"}
          subheader=""
          config={reduxState.config}
        />

        <Container
          className="header-alignment subHeader green"
          style={{ marginTop: "1rem" }}
        >
          {translation.parameters[language]}
        </Container>

        <Container
          className="grid-container"
          style={{
            paddingTop: 15,
            height: "calc(100vh - 220px)",
            overflow: "auto",
            paddingBottom: 20,
          }}
        >
          <Form>
            <Form.Field inline>
              <Grid>
                {/* Epaisseur de la dalle */}
                <Grid.Row
                  style={{
                    alignItems: "center",
                    padding: "0 0 10x 0 !important",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.slabThickness[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.slabThickness[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("epais", e.target.value);
                      }}
                      value={epais}
                    />
                  </Grid.Column>
                </Grid.Row>

                {/* Rognage de la dalle */}
                <Grid.Row
                  style={{
                    alignItems: "center",
                    paddingTop: "0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.slabTrimming[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.slabTrimming[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("rong", e.target.value);
                      }}
                      value={rong}
                    />
                  </Grid.Column>
                </Grid.Row>

                {/* Largeur de la calle */}
                <Grid.Row
                  style={{
                    alignItems: "center",
                    paddingTop: "0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.wedgeWidth[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.wedgeWidth[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("large", e.target.value);
                      }}
                      value={large}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row
                  style={{
                    alignItems: "center",
                    paddingTop: "0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.layoutGrid[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.layoutGrid[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("grid", e.target.value);
                      }}
                      value={grid}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{
                    alignItems: "center",
                    paddingTop: "0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.minPlateDistance[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.minPlateDistance[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("minDist", e.target.value);
                      }}
                      value={minDist}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{
                    alignItems: "center",
                    paddingTop: "0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.maxLength[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.maxLength[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("maxLength", e.target.value);
                      }}
                      value={maxLength}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{
                    alignItems: "center",
                    paddingTop: "0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid.Column width="seven" textAlign="right">
                    <label>{translation.minLength[language]}</label>
                  </Grid.Column>
                  <Grid.Column width="five" textAlign="right">
                    <Input
                      placeholder={translation.minLength[language]}
                      className="textRedCenter"
                      onChange={(e: any) => {
                        onInputChanged("minLength", e.target.value);
                      }}
                      value={minLength}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
          </Form>
        </Container>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span>
            <Button
              color="grey"
              content={translation.back[language]}
              onClick={() => {
                onBackClicked();
              }}
            ></Button>
          </span>
          <span>
            <Button
              disabled={
                grid === "" ||
                minDist === "" ||
                maxLength === "" ||
                minLength === ""
              }
              color="green"
              content={translation.validate[language]}
              onClick={() => {
                onValidateClicked();
              }}
            ></Button>
          </span>
        </div>
      </div>

      <Modal
        open={showWarning}
        size="mini"
        dimmer="inverted"
        className="FacadeModal"
      >
        <Modal.Header
          className="modalHeader"
          style={{ backgroundColor: "#70ad47 !important" }}
        >
          {translation.save[language]}
          <Icon
            name="close"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setShowWarning(false);
            }}
          ></Icon>
        </Modal.Header>
        <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
          <p>{translation.saveParametersWarning[language]}</p>
        </Modal.Description>
        <div style={{ margin: "10px 0px", textAlign: "center" }}>
          <Button color="grey" size="tiny" onClick={() => onNoClicked()}>
            {translation.no[language]}
          </Button>
          <Button
            color="green"
            size="tiny"
            type="submit"
            primary
            onClick={() => {
              onValidateClicked();
            }}
          >
            {translation.yes[language]}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Parameters;
