import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../reducers";
import {
  loadSystemsRequest,
  loadSystemsSuccess,
  loadSystemsError,
  loadSystemsDetailsRequest,
  loadSystemsDetailsSuccess,
  loadSystemsDetailsError,
  loadSystemsDetailsMore,
  filterSystemsRequest,
  displayFavoriteRequest,
  restoreSystemDetails,
  displaySystem,
  loadExistingSystems,
  AddSearchKeyWordAction,
  DeleteSearchKeyWordAction,
} from "./actions";
import { SystemsActionTypes } from "./types";
import * as productsService from "../../services/project.service";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { addFilterFields, filterElements } from "../../utils/utils";
import {
  WALLSYSTEM_ID,
  FLOORSYSTEM_ID,
  systemTypes,
} from "../../assets/constants";
import { CustomParameter, ID } from "../../../../../RevitJS/Types/RevitTypes";
import _ from "lodash";
import { api } from "../../../../../RevitJS/API";

type Effect = ThunkAction<any, DrawStore, any, SystemsActionTypes>;

export const loadSystems =
  (config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsRequest());
    await productsService
      .loadProjects(config)
      .then((results) => {
        dispatch(loadSystemsSuccess(results));
      })
      .catch(() => dispatch(loadSystemsError()));
  };

export const loadSystemsDetails =
  (typeID: number, config: any): Effect =>
  async (dispatch: any, getState: any) => {
    let type = systemTypes.find((systemType) => systemType.value === typeID);
    dispatch(loadSystemsDetailsRequest());
    let { systems, data }: any = getState().systems;
    if (type?.value === WALLSYSTEM_ID && data.wallsSystemDetails.length > 0)
      dispatch(
        restoreSystemDetails(
          data.wallsSystemDetails,
          data.wallsSystemMetaData,
          type.value
        )
      );
    else if (
      type?.value === FLOORSYSTEM_ID &&
      data.floorSystemDetails.length > 0
    )
      dispatch(
        restoreSystemDetails(
          data.floorSystemDetails,
          data.floorSystemMetaData,
          type.value
        )
      );
    else {
      const systemIds = systems.map((product: { oid: ID }) => product.oid);
      if (systemIds && systemIds.length > 0) {
        productsService
          .getSystemDetailsById(systemIds, config)
          .then((results) => {
            let currentSystemDetail = results.data.objects.map((sys) => {
              return { ...sys, filterFields: {} } as unknown;
            }) as ProductDetailData[];
            currentSystemDetail = currentSystemDetail.map((sys) => ({
              ...sys,
              thumbnailUrl: systems.find(
                (system: any) => system.oid === sys.oid
              )?.thumbnailUrl,
            })) as ProductDetailData[];

            const currentSystemDetailWalls = currentSystemDetail.filter(
              (productDetail) => {
                const categoryObject = _.find(productDetail.attributes, {
                  technicalName: "WID-System Family",
                });
                if (categoryObject) {
                  const valueObject: any = _.get(categoryObject, "values");
                  return valueObject[0].value === "Wall System";
                }
              }
            );
            const currentSystemDetailWallsMetaData = systems.filter(
              (system: any) =>
                currentSystemDetailWalls
                  .map((product: { oid: ID }) => product.oid)
                  .includes(system.oid)
            );

            const currentSystemDetailFloor = currentSystemDetail.filter(
              (productDetail) => {
                const categoryObject = _.find(productDetail.attributes, {
                  technicalName: "WID-System Family",
                });
                if (categoryObject) {
                  const valueObject: any = _.get(categoryObject, "values");
                  return valueObject[0].value === "Floor System";
                }
              }
            );
            const currentSystemDetailFloorMetaData = systems.filter(
              (system: any) =>
                currentSystemDetailFloor
                  .map((product: { oid: ID }) => product.oid)
                  .includes(system.oid)
            );

            if (type) {
              dispatch(
                loadSystemsDetailsSuccess(
                  currentSystemDetailWalls,
                  currentSystemDetailFloor,
                  currentSystemDetailWallsMetaData,
                  currentSystemDetailFloorMetaData,
                  type.value
                )
              );
            }
          })
          .catch(() => dispatch(loadSystemsDetailsError()));
      }
      const existingSystems: CustomParameter[] = [];
      //  (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[]
      dispatch(loadExistingSystems(existingSystems));
    }
  };

export const loadMoreSystemsDetails =
  (): Effect => async (dispatch: any, getState: any) => {
    if (
      getState().systems.systemsDetails.length <
      getState().systems.currentSystemDetail.length
    ) {
      dispatch(
        loadSystemsDetailsMore(
          getState().systems.currentSystemDetail.slice(
            getState().systems.systemsDetails.length,
            getState().systems.systemsDetails.length + 20
          )
        )
      );
    }
  };

export const filterSystemsDetails =
  (): Effect => async (dispatch: any, getState: any) => {
    dispatch(loadSystemsDetailsRequest());
    let {
      currentSystemDetail,
      data,
      systemTypeID,
      searchKeyWords,
      favoriteDisplayed,
    } = getState().systems;
    let { currentFavoriteSystemItems } = getState().favorite;
    let dataToFilter: ProductDetailData[] = [];
    if (favoriteDisplayed) dataToFilter = currentFavoriteSystemItems;
    else if (systemTypeID === WALLSYSTEM_ID)
      dataToFilter = data.wallsSystemDetails;
    else if (systemTypeID === FLOORSYSTEM_ID)
      dataToFilter = data.floorSystemDetails;

    let { filters } = getState().filters;
    currentSystemDetail = addFilterFields(dataToFilter, filters);
    currentSystemDetail = filterElements(currentSystemDetail, filters);
    currentSystemDetail = currentSystemDetail.filter((el: ProductDetailData) =>
      searchKeyWords.every((keyWord: any) =>
        el.translation
          .replace("®", "")
          .replace(/\s/g, "")
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            keyWord
              ?.toUpperCase()
              .replace(/\s/g, "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") || ""
          )
      )
    );
    dispatch(filterSystemsRequest(currentSystemDetail));
  };

export const displayFavorite =
  (): Effect => async (dispatch: any, getState: any) => {
    const { currentFavoriteSystemItems } = getState().favorite;
    dispatch(displayFavoriteRequest(currentFavoriteSystemItems));
  };

export const displaySystems =
  (): Effect => async (dispatch: any, getState: any) => {
    let { data, systemTypeID } = getState().systems;
    let systems: ProductDetailData[] = [];
    if (systemTypeID === WALLSYSTEM_ID) systems = data.wallsSystemDetails;
    else if (systemTypeID === FLOORSYSTEM_ID) systems = data.floorSystemDetails;

    dispatch(displaySystem(systems));
  };

export const addSearchKeyWord =
  (word: string): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(AddSearchKeyWordAction(word));

    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: word,
          values: [],
        },
      ],
      eventAction: "Get",
      eventCategory: "User Query",
      eventLabel: "Keyword search",
      module: "WEBINDONPRESCRI",
    });
  };

export const deleteSearchKeyWord =
  (searchWordIndex: number): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(DeleteSearchKeyWordAction(searchWordIndex));
  };
