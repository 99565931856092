import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectionStore } from "../Reducers";
import { Table } from "semantic-ui-react";
import {
  PlacoOptions,
  GenericMappingRow,
} from "../../../../RevitJS/Types/StoreTypes";
import SelectionMappingRow from "./SelectionMappingRow";
import { toggleAllSelectionRow } from "../Actions";
import _ from "lodash";
import SystemDetails from "./SystemDetails";
import {
  InjectedCounterProps,
  withErrorSubscription,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps & {
  wording: {
    select: { [key: string]: string };
    revitSystemType: { [key: string]: string };
    otherSystemType: { [key: string]: string };
    systemChoice: { [key: string]: string };
    actions: { [key: string]: string };
    mapping: { [key: string]: string };
  };
  language: string;
  selectionGroup: GenericMappingRow<PlacoOptions>[];
  setRoute: any;
  toggleAllSelectionRow: any;
  selectorType: string;
  selectionPar: boolean;
  module: string;
  handleDimmer: any;
  setScrollPosition: any;
  setScrollConfigPosition: any;
};
interface State {}

export class SelectionMappingTable extends Component<Props, State> {
  state = {
    active: false,
  };

  public static defaultProps = {
    wording: {
      select: {
        French: "Sélectionner...",
        English: "Select a system...",
      },
      mapping: {
        French: "Table de correspondance des systèmes",
        English: "Solution mapping",
      },
      revitSystemType: {
        French: "Type de système Revit",
        English: "Revit System Type",
      },
      otherSystemType: {
        French: "Autre type de système",
        English: "Other System Type",
      },
      systemChoice: {
        French: "Systèmes",
        English: "Systems",
      },
      actions: {
        French: "Actions",
        English: "Actions",
      },
    },
  };

  render() {
    const {
      wording: { revitSystemType, systemChoice, actions },
      language,
      selectionGroup,
      setRoute,
      module,
      setScrollPosition,
    } = this.props;

    const notSelectedRows = _.filter(selectionGroup, { Checked: false });
    const isAllTrue = notSelectedRows.length > 0 ? false : true;

    return (
      <div>
        <SystemDetails />
        <Table celled>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell textAlign="center">
                <Checkbox
                  checked={isAllTrue}
                  onChange={(e, { checked }) => {
                    toggleAllSelectionRow(selectorType, checked);
                  }}
                /> 
              </Table.HeaderCell>*/}
              <Table.HeaderCell width="6" textAlign="center">
                {revitSystemType[language]}
              </Table.HeaderCell>
              <Table.HeaderCell width="8" textAlign="center">
                {systemChoice[language]}
              </Table.HeaderCell>
              <Table.HeaderCell width="1" textAlign="center">
                {actions[language]}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectionGroup?.map((row, index) => {
              return (
                <SelectionMappingRow
                  setRoute={setRoute}
                  mappingRow={row}
                  key={index}
                  module={module}
                  handleDimmer={this.props.handleDimmer}
                  setScrollPosition={this.props.setScrollPosition}
                  setScrollConfigPosition={this.props.setScrollConfigPosition}
                  onError={this.props.onError}
                />
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (
  state: SelectionStore,
  ownProps: {
    selectionGroup: GenericMappingRow<PlacoOptions>[];
    setRoute: any;
    selectionPar: boolean;
    module: string;
  }
) => ({
  language: state.language,
  selectionGroup: ownProps.selectionGroup,
  setRoute: ownProps.setRoute,
  selectorType: state.selectorType,
  selectionPar: ownProps.selectionPar,
  moduel: ownProps.module,
});

const mapDispatchToProps = {
  toggleAllSelectionRow,
};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(SelectionMappingTable);
export default withErrorSubscription(
  connect(mapStateToProps, mapDispatchToProps)(SelectionMappingTable)
);
