import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./rangeComponent.scss";

function RangeComponent({ name, desc, image, t }: IProps) {
    return (
        <div className="d-flex flex-column justify-content-between">
            <img
                className="xlarge-btn m-auto range-img"
                src={image}
                alt={"Range 1"}
            ></img>
            <strong>{name}</strong>
            <span>({desc})</span>
        </div>
    );
}

type IProps = WithTranslation & {
    name: string;
    desc: string;
    image: string;
};

export default withTranslation(["pam"])(RangeComponent);
