import { api } from "../../../RevitJS/API";

export const functionalityId = "a3d5f5cd-0485-4135-918f-48901ff8b600";

export const Components = [];

const language = "French";

export const colorFamily = async (
  familyTypeName: any,
  color = { Red: 255, Green: 0, Blue: 0 }
) => {

  const famsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: familyTypeName },
        Rule: "Equals",
      },
    ],
    null
  );

if (Array.isArray(famsIds) && famsIds.length) {
        let filter = await api.queries.createSelectionFilter(famsIds[0], famsIds);
        await api.viewHandler.setFilterColor(filter, color);
	} else {	}

};

export const colorizationTrigger = async () => {
  await colorFamily("Connect T24 Cross tee 150 mm", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T24 Cross tee 300 mm", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T24 Cross tee 600 mm", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T24 Cross tee 1200 mm", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T15 Cross tee 600 mm", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T15 Cross tee 1200 mm", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T24 Main runner", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T24 Main runner HD", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect T15 Main runner", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect Space Bar", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect Wall Bracket for Space bar", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect Wall Braket for Main runner", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect Angle trim 15/22", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect Angle trim 22/22", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
  await colorFamily("Connect Adjustable Hanger", { Red: getRandomInt(255), Green: getRandomInt(255), Blue: getRandomInt(255) });
};

function getRandomInt(max:any) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const Colorization = {
  Name: { French: "Colorisation", English: "Colorization", German: "Colorization", Italian: "Colorization" },
  Trigger: colorizationTrigger,
  ShortDesc: {
    French: "Repérer les systèmes",
    English: "Colorize systems",
    German: "Colorize systems",
    Italian: "Colorize systems",
  },
  Id: functionalityId,
  Components,
  Icon: "/pluginIcons/Icon-Color.jpg",
  ParentId:"0",
};
