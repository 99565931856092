import React from "react";
import { Grid, Header, Menu, SemanticCOLORS, Button } from "semantic-ui-react";
import { LevelItems } from "./LevelItems";
import { LevelData } from "../../../../RevitJS/Types/StoreTypes";
import _ from "lodash";

interface Props {
  columnTitle: string;
  color: SemanticCOLORS;
  levelsData: LevelData[];
  selectAllLevel:any;
}

interface State {}

export class LevelsColumn extends React.Component<Props> {
  state = {
    selection: null,
  };

  componentDidMount() {}

  render() {
    const { columnTitle, color, levelsData } = this.props;

    const isAllChecked = _.filter(levelsData, { 'Checked': false });

    return (
      <Grid.Column>
        <Header as="h3" textAlign="center" color={color} style={{fontSize : "14px", marginBottom : "2px"}}>
          {columnTitle}
        </Header>
        <Menu
          style={{ maxHeight: "45vh", overflow: "auto", marginTop : "7px"  }}
          vertical
          size="small"
          fluid
        >
          <LevelItems levelsData={levelsData} color="blue" />
        </Menu>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => this.props.selectAllLevel(isAllChecked.length > 0 ? true : false)}
            circular
            primary
            icon={isAllChecked.length > 0 ? "check" : "times"}
          />
        </div>
      </Grid.Column>
    );
  }
}
