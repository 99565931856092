import {
  SystemDetailsState,
  SystemDetailsActionTypes,
  SET_CURRENT_SYSTEM_DETAIL,
  SET_ADVANCE_SELECTOR_DETAIL
} from "./types";

export const initialState: SystemDetailsState = {
  system: {},
  advanceSelector: {},
};

export default function systemDetailsReducer(
  state = initialState,
  action: SystemDetailsActionTypes
) {
  switch (action.type) {
    case SET_CURRENT_SYSTEM_DETAIL:
      return {
        system: { ...action.system },
      };
    case SET_ADVANCE_SELECTOR_DETAIL:
      return {
        advanceSelector: { ...action.advanceSelector },
      };
    default:
      return state;
  }
}
