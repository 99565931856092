import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../reducers";
import {
  loadSystemsRequest,
  loadSystemsSuccess,
  loadSystemsError,
  loadSystemsDetailsRequest,
  loadSystemsDetailsSuccess,
  loadSystemsDetailsError,
  loadSystemsDetailsMore,
  filterSystemsRequest,
  displayFavoriteRequest,
  restoreSystemDetails,
  displaySystem,
  loadExistingSystems,
  AddSearchKeyWordAction,
  DeleteSearchKeyWordAction,
} from "./actions";
import { SystemsActionTypes } from "./types";
import * as productsService from "../../services/project.service";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import {
  addFilterFields,
  applyRulesToData,
  filterElements,
  getConditionValue1,
} from "../../utils/utils";
import {
  WALLSYSTEM_ID,
  FLOORSYSTEM_ID,
  systemTypes,
} from "../../assets/constants";
import { CustomParameter, ID } from "../../../../../RevitJS/Types/RevitTypes";
import _ from "lodash";
import { api } from "../../../../../RevitJS/API";
import {
  getAllPlugins,
  getPrescriptionActiveVersion,
} from "../../../../../Services/mapping-config-service";
import { flattenDataRules } from "../../../Common/utils/mapping-config";
import { asyncForEach } from "../../../../PlacoBIMv3/Selection/Actions";

type Effect = ThunkAction<any, DrawStore, any, SystemsActionTypes>;

export const loadSystems =
  (config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsRequest());
    await productsService
      .loadProjects(config)
      .then((results) => {
        dispatch(loadSystemsSuccess(results));
      })
      .catch(() => dispatch(loadSystemsError()));
  };

export const loadSystemsDetails =
  (
    typeID: number,
    config: any,
    categoryTypeOptions: any,
    advanceSelectorData: any,
    activeVersionData: any
  ): Effect =>
  async (dispatch: any, getState: any) => {
    //let type = systemTypes.find((systemType) => systemType.value === typeID);
    let type = categoryTypeOptions.find(
      (systemType: any) => systemType.value === typeID
    );

    if (type) {
      dispatch(loadSystemsDetailsRequest());
      let { systems, data }: any = getState().systems;
      if (type?.value === WALLSYSTEM_ID && data.wallsSystemDetails.length > 0) {
        dispatch(
          restoreSystemDetails(
            data.wallsSystemDetails,
            data.wallsSystemMetaData,
            type.value
          )
        );
      } else if (
        type?.value === FLOORSYSTEM_ID &&
        data.floorSystemDetails.length > 0
      ) {
        dispatch(
          restoreSystemDetails(
            data.floorSystemDetails,
            data.floorSystemMetaData,
            type.value
          )
        );
      } else {

        const systemIds = systems.map((product: { oid: ID }) => product.oid);
        if (systemIds && systemIds.length > 0) {
          let rules = await getDataRules(activeVersionData);
          let searchbarRules = flattenDataRules(
            advanceSelectorData?.searchbar?.dataRules
          )?.filter((r: any) => r.attribute && r.attribute !== "");
          let productNameRules = flattenDataRules(
            advanceSelectorData?.productname?.dataRules
          )?.filter((r: any) => r.attribute && r.attribute !== "");
          let imgRules = flattenDataRules(
            advanceSelectorData?.image?.dataRules
          )?.filter((r: any) => r.attribute && r.attribute !== "");

          //maincategory
          let mainCategoryRules: any = [];
          advanceSelectorData?.mainCategories?.forEach((cat: any) => {
            let mainCatRule = [];
            mainCatRule.push({
              attribute: cat?.attribute?.attributeName,
              caseValue: "OR",
              relation: cat?.relation,
              value: cat?.value,
            });
            let catRules = flattenDataRules([mainCatRule])?.filter(
              (r: any) => r.attribute && r.attribute !== ""
            );
            if (catRules && catRules.length > 0) {
              mainCategoryRules[cat.type] = catRules;
            }
          });

          //SelectorFilters
          let selectorFilterRule: any = [];
          advanceSelectorData?.selectorFilters?.forEach((filterData: any) => {
            for (let i = 0; i < filterData.category.length; i++) {
              if (!selectorFilterRule[filterData.category[i]]) {
                selectorFilterRule[filterData.category[i]] = [];
              }
              let selectorRules = flattenDataRules(
                filterData.attribute.dataRules
              )?.filter((r: any) => r.attribute && r.attribute !== "");

              if (selectorRules && selectorRules.length > 0) {
                selectorFilterRule[filterData.category[i]].push({selectorRules: selectorRules, attribute: filterData.attribute.attributeName});
              }
            }
          });

          productsService
            .getSystemDetailsById(systemIds, config)
            .then((results) => {
              let currentSystemDetail = results.data.objects.map((sys) => {
                return { ...sys, filterFields: {} } as unknown;
              }) as ProductDetailData[];
              currentSystemDetail = currentSystemDetail.map((sys) => ({
                ...sys,
                thumbnailUrl: systems.find(
                  (system: any) => system.oid === sys.oid
                )?.thumbnailUrl,
              })) as ProductDetailData[];

              //Apply data rules
              rules = rules.filter(
                (r: any) => r.attribute && r.attribute !== ""
              );
              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                rules
              );

              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                searchbarRules
              );
              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                productNameRules
              );
              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                imgRules
              );

              //Walls
              let currentSystemDetailWalls: ProductDetailData[] =
                applyRulesOnData(
                  currentSystemDetail,
                  mainCategoryRules[WALLSYSTEM_ID]
                );

              selectorFilterRule[WALLSYSTEM_ID]?.forEach((element: any) => {
                currentSystemDetailWalls = applyRulesOnData(
                  currentSystemDetailWalls,
                  element.selectorRules,
                  true,
                  element.attribute
                );
              });

              const currentSystemDetailWallsMetaData = systems.filter(
                (system: any) =>
                  currentSystemDetailWalls
                    .map((product: { oid: ID }) => product.oid)
                    .includes(system.oid)
              );

              // const currentSystemDetailWalls = currentSystemDetail.filter(
              //   (productDetail) => {
              //     const categoryObject = _.find(productDetail.attributes, {
              //       technicalName: "WID-System Family",
              //     });
              //     if (categoryObject) {
              //       const valueObject: any = _.get(categoryObject, "values");
              //       return valueObject[0].value === "Wall System";
              //     }
              //   }
              // );
              // const currentSystemDetailWallsMetaData = systems.filter(
              //   (system: any) =>
              //     currentSystemDetailWalls
              //       .map((product: { oid: ID }) => product.oid)
              //       .includes(system.oid)
              // );

              //Floor
              let currentSystemDetailFloor: ProductDetailData[] =
                applyRulesOnData(
                  currentSystemDetail,
                  mainCategoryRules[FLOORSYSTEM_ID]
                );

              selectorFilterRule[FLOORSYSTEM_ID]?.forEach((element: any) => {
                currentSystemDetailFloor = applyRulesOnData(
                  currentSystemDetailFloor,
                  element.selectorRules,
                  true,
                  element.attribute
                );
              });

              const currentSystemDetailFloorMetaData = systems.filter(
                (system: any) =>
                  currentSystemDetailFloor
                    .map((product: { oid: ID }) => product.oid)
                    .includes(system.oid)
              );

              // const currentSystemDetailFloor = currentSystemDetail.filter(
              //   (productDetail) => {
              //     const categoryObject = _.find(productDetail.attributes, {
              //       technicalName: "WID-System Family",
              //     });
              //     if (categoryObject) {
              //       const valueObject: any = _.get(categoryObject, "values");
              //       return valueObject[0].value === "Floor System";
              //     }
              //   }
              // );
              // const currentSystemDetailFloorMetaData = systems.filter(
              //   (system: any) =>
              //     currentSystemDetailFloor
              //       .map((product: { oid: ID }) => product.oid)
              //       .includes(system.oid)
              // );

              if (type) {
                dispatch(
                  loadSystemsDetailsSuccess(
                    currentSystemDetailWalls,
                    currentSystemDetailFloor,
                    currentSystemDetailWallsMetaData,
                    currentSystemDetailFloorMetaData,
                    type.value
                  )
                );
              }
            })
            .catch(() => dispatch(loadSystemsDetailsError()));
        }
        const existingSystems: CustomParameter[] = [];
        //  (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[]
        dispatch(loadExistingSystems(existingSystems));
      }
    }
  };

const applyRulesOnData = (
  data: any,
  rules: any,
  filterValue: boolean = false,
  assignedAttribute: any = null
) => {
  if (rules && rules.length > 0) {
    data = data.filter((element: any) => {
      if (filterValue) {
        let result: boolean = false;
        let count: number = 0;
        let condition: string = "";
        if(assignedAttribute !== null){
          let assignedAttributeIndex = element.attributes.findIndex((attr: any)=> attr.technicalName === assignedAttribute);
          if(assignedAttributeIndex > -1){
            element.attributes[assignedAttributeIndex].values = 
            element.attributes[assignedAttributeIndex].values.filter(async (val: any) => {
              await asyncForEach(rules, async (rule: any) => {
                condition = count === 0 ? rule.condition : rules[count - 1].condition;
                let index = element.attributes.findIndex((attr: any) => attr.technicalName === rule.attribute);
                if (index > -1) {
                    result = getConditionValue1(
                      rule,
                      val.value,
                      condition,
                      count,
                      result
                    );
                }
              });
    
              return result;
    
            });
          }
          
        }else {
          rules.forEach((rule: any) => {
            condition = count === 0 ? rule.condition : rules[count - 1].condition;
            let index = element.attributes.findIndex(
              (attr: any) => attr.technicalName === rule.attribute
            );
            if (index > -1) {
              element.attributes[index].values = element.attributes[
                index
              ].values.filter((val: any) => {
                result = getConditionValue1(
                  rule,
                  val.value,
                  condition,
                  count,
                  result
                );
                return result;
              });
            }
          });
  
        }
        
        
        
      }
      return applyRulesToData(element.attributes, rules);
    });
  }
  return data;
};

export const getDataRules = async (activeVersionData: any) => {
  if (activeVersionData) {
    // const prescriptionList = await getAllPrescriptions(config);
    // const prescriBIMplugin = prescriptionList.find((p: any) => p.name === 'Mortar Utama Indonesia');
    // let dataRules = await getPrescriptionDataRules(prescriBIMplugin.prescriptionPluginId, config);

    let dataRules = activeVersionData?.dataRule;

    let solutionLevels = flattenDataRules(dataRules.solutionLevels);
    let productLevels = flattenDataRules(dataRules.productLevels);

    let flattenRules = solutionLevels.concat(productLevels);
    return flattenRules;
  }
};

// export const flattenDataRules = (dataRules: any) => {
//   let rules: any = [];
//   let conditionCount = 1;
//   dataRules.forEach((dataRule: any) => {
//     conditionCount = 1;
//     dataRule.forEach((dr: any) => {
//       rules.push({
//         attribute: dr.attribute,
//         caseValue: dr.caseValue,
//         relation: dr.relation,
//         value: dr.value,
//         condition: conditionCount == dataRule.length ? "OR" : "AND"
//       });
//       conditionCount++;
//     });

//   });

//   return rules;
// }

export const loadMoreSystemsDetails =
  (): Effect => async (dispatch: any, getState: any) => {
    if (
      getState().systems.systemsDetails.length <
      getState().systems.currentSystemDetail.length
    ) {
      dispatch(
        loadSystemsDetailsMore(
          getState().systems.currentSystemDetail.slice(
            getState().systems.systemsDetails.length,
            getState().systems.systemsDetails.length + 20
          )
        )
      );
    }
  };

export const filterSystemsDetails =
  (advanceSelector: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsDetailsRequest());
    let {
      currentSystemDetail,
      data,
      systemTypeID,
      searchKeyWords,
      favoriteDisplayed,
    } = getState().systems;
    let { currentFavoriteSystemItems } = getState().favorite;
    let dataToFilter: ProductDetailData[] = [];
    if (favoriteDisplayed) dataToFilter = currentFavoriteSystemItems;
    else if (systemTypeID === WALLSYSTEM_ID)
      dataToFilter = data.wallsSystemDetails;
    else if (systemTypeID === FLOORSYSTEM_ID)
      dataToFilter = data.floorSystemDetails;

    let { filters } = getState().filters;
    currentSystemDetail = addFilterFields(dataToFilter, filters);
    currentSystemDetail = filterElements(currentSystemDetail, filters);
    currentSystemDetail = currentSystemDetail.filter(
      (el: ProductDetailData) => {
        let productAttribute = el.attributes.find(
          (p: any) =>
            p.technicalName === advanceSelector?.searchbar?.attributeName
        );
        let productName = productAttribute?.values[0]?.value
          ? productAttribute.values[0]?.value
          : el.translation;

        return searchKeyWords.every((keyWord: any) =>
          productName
            .replace("®", "")
            .replace(/\s/g, "")
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              keyWord
                ?.toUpperCase()
                .replace(/\s/g, "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") || ""
            )
        );
      }
    );
    dispatch(filterSystemsRequest(currentSystemDetail));
  };

export const displayFavorite =
  (): Effect => async (dispatch: any, getState: any) => {
    const { currentFavoriteSystemItems } = getState().favorite;
    dispatch(displayFavoriteRequest(currentFavoriteSystemItems));
  };

export const displaySystems =
  (): Effect => async (dispatch: any, getState: any) => {
    let { data, systemTypeID } = getState().systems;
    let systems: ProductDetailData[] = [];
    if (systemTypeID === WALLSYSTEM_ID) systems = data.wallsSystemDetails;
    else if (systemTypeID === FLOORSYSTEM_ID) systems = data.floorSystemDetails;

    dispatch(displaySystem(systems));
  };

export const addSearchKeyWord =
  (word: string): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(AddSearchKeyWordAction(word));

    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: word,
          values: [],
        },
      ],
      eventAction: "Get",
      eventCategory: "User Query",
      eventLabel: "Keyword search",
      module: "WEBINDONPRESCRI",
    });
  };

export const deleteSearchKeyWord =
  (searchWordIndex: number): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(DeleteSearchKeyWordAction(searchWordIndex));
  };
