import { pluginsData } from "../../../Plugins"

export const GetPluginDetails = (pluginId:string, functionalityId:string) => {
    let pluginData = pluginsData.find(a=>a.plugin.Id === pluginId);
    let functionality = pluginData?.plugin.Functionalities.find(a=>a.Id === functionalityId);
    let data = {
        pluginId:pluginId,
        technicalName:pluginData?.technicalName,
        Name:"",
        functionalityId:functionalityId,
        functionalityName:functionality?.Name
    }
    return data;
}