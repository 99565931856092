import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Radio,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Container,
} from "semantic-ui-react";
import { Routes } from "../root";
import SelectionLanding from "./Selection/SelectionLanding";
import {
  IQuantityContextType,
  QuantityContext,
} from "../../context/quantityContext";
import { collectWallFromBIMModel } from "../../util/groupWallModel";
import { collectCeilingFromBIMModel } from "../../util/groupCeilingModel";
import { getProcessedWalls } from "../../util/extractProcessWalls";
import ElementType from "./Selection/ElementType";
import { SaveProject } from "../../../CommonModules/PopUpModals/SaveProject";
import { api } from "../../../../../RevitJS/API";
import { initProjectData } from "../../../../../RevitJS/Types/StoreTypes";

interface Props {
  setRoute: any;
  route: string;
}

export const ChoiceOfSources = (props: Props) => {
  const [selectionChoiceOpen, setSelectionChoiceOpen] = React.useState(false);
  const [elementChoiceOpen, setElementChoiceOpen] = React.useState(false);
  const {
    config,
    setProcessWalls,
    setProcessCeilings,
    sourceChoice,
    setSourceChoice,
    setSelectionType,
    elementType,
    setElementType,
  } = useContext(QuantityContext) as IQuantityContextType;
  const [loading, setLoading] = useState<boolean>(false);
  const [raiseWarning, setRaiseWarning] = useState<boolean>(false);
  const [confirmModal, setconfirmModal] = useState(false);

  useEffect(() => {
    async function fetchProjectId() {
      let projectData: initProjectData = await api.queries.getSetProjectData();

      if (projectData.ProjectId === "") {
        toggleModal();
      }
    }

    fetchProjectId();
  }, [])

  const handleRadioChange = (event: any, value: any) => {
    setSourceChoice(value.value);
  };

  const handleContinuerChange = async () => {
    if (sourceChoice === "selection") {
      // props.setRoute(Routes.SELECTIONLANDING);
      setSelectionChoiceOpen(true);
    } else {
      setElementChoiceOpen(true);
    }
  };
  const onModalCloseIcon = () => {
    setSelectionChoiceOpen(false);
  };

  const onSelectionType = async (selType: string) => {
    setLoading(false);
    setSelectionType(selType);
    setSelectionChoiceOpen(false);
    if (selType === "floor") {
      props.setRoute(Routes.SELECTIONLIST);
    }

    if (selType === "zone") {
      props.setRoute(Routes.ZONELIST);
    }
  };

  const onElementSelection = async (eleType: string) => {
    setLoading(true);
    setElementType(eleType);
    if (eleType === "wall") {
      const collectProcessWalls = await collectWallFromBIMModel(config);
      setLoading(false);
      if (collectProcessWalls) {
        setProcessWalls(collectProcessWalls);
        props.setRoute(Routes.WALLTABLECOMPONENT);
      } else {
        setRaiseWarning(true);
      }
    } else {
      const collectProcessCeilings = await collectCeilingFromBIMModel(config);
      setLoading(false);
      if (collectProcessCeilings) {
        setProcessCeilings(collectProcessCeilings);
        props.setRoute(Routes.WALLTABLECOMPONENT);
      } else {
        setRaiseWarning(true);
      }
    }
  };

  const toggleModal = () => {
    setconfirmModal(!confirmModal);
  };

  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };

  if (loading) {
    return (
      <>
        <Container
          className="grid-container"
          style={{ width: "90%", border: "0 none", padding: "0" }}
        >
          <Dimmer
            active
            style={{
              height: "calc(100vh - 30px)",
            }}
          >
            <Loader />
          </Dimmer>
        </Container>
      </>
    );
  }

  if (raiseWarning)
    return (
      <Modal
        size="tiny"
        open={raiseWarning}
        closeIcon
        onClose={() => setRaiseWarning(false)}
      >
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>
              No Family Files found for the {elementType} in the Revit model
            </p>
            <Button color="blue" onClick={() => setRaiseWarning(false)}>
              Close
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

  return (
    <>
      <SaveProject
        toggleModal={toggleModal}
        confirmModal={confirmModal}
        onYesAction={onYesAction}
      />
      <Grid className="custom-ratio-types">
        <Grid.Row style={{ height: "60vh", alignItems: "center" }}>
          <Grid.Column
            width={15}
            style={{
              justifyContent: "center",
              width: "100% !important",
              display: "flex",
            }}
          >
            <Form>
              <Form.Field style={{ justifyContent: "center", display: "flex" }}>
                <Radio
                  label="A partir d’une sélection existante"
                  name="radioGroup"
                  value="selection"
                  checked={sourceChoice === "selection"}
                  onChange={handleRadioChange}
                />
              </Form.Field>
              <Form.Field style={{ justifyContent: "center", display: "flex" }}>
                <Radio
                  label=" À partir du model BIM"
                  name="radioGroup"
                  value="model"
                  checked={sourceChoice === "model"}
                  onChange={handleRadioChange}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid className="middel-full-width" style={{ marginTop: "30px" }}>
          <Button
            primary
            onClick={handleContinuerChange}
            disabled={sourceChoice.length === 0 ? true : false}
          >
            Continuer
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={selectionChoiceOpen}
        size="small"
        className="modal-close"
        closeIcon
        onClose={() => onModalCloseIcon()}
      >
        <Modal.Actions>
          <SelectionLanding onSelectionType={onSelectionType} />
        </Modal.Actions>
      </Modal>
      <Modal
        open={elementChoiceOpen}
        size="small"
        className="modal-close"
        closeIcon
        onClose={() => setElementChoiceOpen(false)}
      >
        <Modal.Actions>
          <ElementType
            setElementType={onElementSelection}
            elementType={elementType}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default ChoiceOfSources;
