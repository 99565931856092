import React, { useEffect, useState } from "react";
import { MyConfig } from "../../../../Helper";

export const UpdateProfile = () => {

  const [config, setConfig] = useState({REACT_APP_AUTHENTICATIONURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])
  
  const getLanguageCode = (lang: string | null) => {
    switch (lang) {
      case "German":
        return "de";
      case "French":
        return "fr";
      case "Italian":
        return "it";
      default:
        return "en";
    }
  };
  return (
    <>
      <iframe
        title="Update Account"
        frameBorder="no"
        src={`${
          config.REACT_APP_AUTHENTICATIONURL
        }/user/updateAccount?lang=${getLanguageCode(
          localStorage.getItem("savedLanguage")
        )}`}
        width="100%"
        scrolling="yes"
        style={{ height: "calc(100vh - 35px" }}
      />
    </>
  );
};
