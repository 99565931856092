import React from "react";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { Container } from "semantic-ui-react";
import { ChoiceOfSources } from "./ChoiceOfSources/ChoiceOfSources";

interface Props {
    setRoute: any;
    route: string;
}

export const Landing = (props: Props) => {
    return (
        <>
            <FunctionalityHeader
                Icon={""}
                name={"QUANTITATIFS PAR RATIO"}
                subheader=""
            />

            <Container
                className="header-alignment subHeader"
                style={{ marginTop: "1rem" }}
            >
                {`1/2 Choix du source`}
            </Container>
            <Container
                className="grid-container"
                style={{ paddingTop: 15, height: "calc(100% - 150px)" }}
            >
                <ChoiceOfSources
                    route={props.route}
                    setRoute={props.setRoute}
                />
            </Container>
        </>
    )
}
export default Landing;