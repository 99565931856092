import { rigipsBim } from "./RiggipsBIM";
import { placoBimv3 } from "./PlacoBIMv3";
import { placoBimv5 } from "./PlacoBIMv5";
import { CLT } from "./CLT";
import { pam } from "./PAM";
import { pamCanalisation } from "./PamCanalisation";
import { weberIndonesia } from "./WeberIndonesia";
import { GenaralUI } from "./General";
import { api } from "../RevitJS/API";
import _, { filter, includes } from "lodash";
import { pluginTraining } from "./PluginTraining";
import { Facade } from "./Facade";
import jwt_decode from "jwt-decode";
import { ModelGenerator } from "./ModelGen";
import { GenericPlugin } from "./GenericPlugin";
import { Gyproc } from "./Gyproc";
import { WeberIndonesiaTest } from "./WeberIndonesia-Test";
import { PlacoBimv5Test } from "./PlacoBIMv5-Test";
import { PlacoBimv3Test } from "./PlacoBIMv3-Test";

export const pluginsData = [
  { technicalName: rigipsBim.TechnicalName, plugin: rigipsBim },
  { technicalName: placoBimv5.TechnicalName, plugin: placoBimv5 },
  { technicalName: CLT.TechnicalName, plugin: CLT },
  { technicalName: pam.TechnicalName, plugin: pam },
  { technicalName: placoBimv3.TechnicalName, plugin: placoBimv3 },
  { technicalName: pamCanalisation.TechnicalName, plugin: pamCanalisation },
  { technicalName: GenaralUI.TechnicalName, plugin: GenaralUI },
  { technicalName: weberIndonesia.TechnicalName, plugin: weberIndonesia },
  { technicalName: pluginTraining.TechnicalName, plugin: pluginTraining },
  { technicalName: Facade.TechnicalName, plugin: Facade },
  { technicalName: ModelGenerator.TechnicalName, plugin: ModelGenerator },
  { technicalName: GenericPlugin.TechnicalName, plugin: GenericPlugin },
  { technicalName: Gyproc.TechnicalName, plugin: Gyproc },
  { technicalName: WeberIndonesiaTest.TechnicalName, plugin: WeberIndonesiaTest },
  { technicalName: PlacoBimv5Test.TechnicalName, plugin: PlacoBimv5Test },
  { technicalName: PlacoBimv3Test.TechnicalName, plugin: PlacoBimv3Test },
];

export const getMenuFromUser = async (appls: any) => {
  try {
    let allApplication: any = JSON.parse(JSON.stringify(appls));
    let token = localStorage.getItem("token");
    let tries = 0;
    while (!token) {
      await delay(500);
      token = localStorage.getItem("token");

      if (token || tries >= 4) {
        break;
      }
      tries = tries + 1;
    }

    //console.log("token out :", token);
    if (
      allApplication &&
      allApplication.data &&
      allApplication.data.length > 0
    ) {
      if (token) {
        try {
          let decode: any = jwt_decode(token);
          let { preferedBimdatabaseRegionId, applications } = decode;
          // applications = [...applications, "GYPROC"];
          let premiumPlugins = _.filter(allApplication.data, { premium: true });
          let filteredFreePlugins = _.filter(allApplication.data, (app) => {
            if (
              !app.premium &&
              _.includes(app.regions, preferedBimdatabaseRegionId)
            ) {
              return app;
            }
          });

          let allApps: any = [...premiumPlugins, ...filteredFreePlugins];

          let pluginList: string[] | any[];
          const revitVersion = parseInt(await api.framework.getRevitVersion());

          if (window.revit.isArchicad) {
            pluginList = _.compact(
              _.map(allApps, (app) => {
                if (
                  _.includes(app.supportedSoftwares, "Archicad") &&
                  _.includes(app.supportedArchicadVersions, revitVersion)
                ) {
                  return app;
                }
              })
            );
          } else {
            pluginList = _.compact(
              _.map(allApps, (app) => {
                if (
                  _.includes(app.supportedSoftwares, "Revit") &&
                  _.includes(app.supportedRevitVersions, revitVersion)
                ) {
                  return app;
                }
              })
            );
          }
          const plListByRegion = filter(pluginList, (plLst) => {
            return includes(applications, plLst.applicationTechnicalName);
          });
          const myApplications: any = pluginsData.filter((o1) =>
            plListByRegion.some(
              (o2) => o1.technicalName === o2.applicationTechnicalName
            )
          );

          return myApplications;
        } catch (Error) {
          console.log("getMenuFromUser error 1:", Error);
          return null;
        }
      } else {
        return null;
      }
    } else return null;
  } catch (error) {
    console.log("getMenuFromUser error 2:", error);
    throw error;
  }
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const CheckEnvironmentAccessibility = (
  allApps: any[],
  currentENV: string
) => {
  if (currentENV !== "PROD") {
    return allApps;
  } else {
    return filter(allApps, { beta: false });
  }
};
