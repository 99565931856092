import { jsPDF } from "jspdf";
import "./Roboto-bold.ts";
import "./Roboto-normal.ts";
import { bimStorage, storageKey } from "../../../../../BIMStore";

export const PDFPreview = async (val: any = "", config: any) => {
  const localStoragedata: any = await bimStorage.getItem(
    storageKey.PROJECT_INFO
  );
  const data = localStoragedata;

  if (data === null || data === undefined) {
    return new Promise((resolve, reject) => {
      reject("Veuillez d'abord remplir les informations de projet requises.");
    });
  }
  const SupplimentaryData = data.SUPPLIMENTARY_FORM?.find(
    (el: any) => el.model_name === val.modelName
  );
  const ChantierData = data.PROJECT_INFO_FORM?.modelInfo?.find(
    (el: any) => el.model_name === val.modelName
  );
  const CompanyInitial = val.documentType;
  const DossierTitle =
    CompanyInitial === "DAE"
      ? "DOSSIER AVANT EXÉCUTION"
      : CompanyInitial === "DAO"
      ? "DOSSIER D'APPEL D'OFFRES​"
      : "DOSSIER DES OUVRAGES EXÉCUTÉS";

  const DateOfEdition = val.documentDate;
  const {
    information_chantier_add_date,
    information_chantier_address,
    information_chantier_pays,
    information_chantier_postal,
    information_chantier_reference,
    information_chantier_referencecctp,
    information_chantier_villa,
  } = ChantierData;

  const EnterpriseAddress =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_address ?? "";
  const EnterpriseEmail =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_email ?? "";
  const EnterpriseLogo =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_logo ?? "";
  const EnterpriseName =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_name ?? "";
  const EnterpriseCountry =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_pays ?? "";
  const EnterprisePostalCode =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_postal ?? "";
  const EnterprisePhone =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_telephone ?? "";
  const EnterpriseCity =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_villa ?? "";

  const lot_1 = SupplimentaryData?.lot_1 ?? "";
  const lot_2 = SupplimentaryData?.lot_2 ?? "";
  const lot_3 = SupplimentaryData?.lot_3 ?? "";
  const enterprise_generale_address =
    SupplimentaryData?.enterprise_generale_address ?? "";
  const enterprise_generale_email =
    SupplimentaryData?.enterprise_generale_email ?? "";
  const enterprise_generale_name =
    SupplimentaryData?.enterprise_generale_name ?? "";
  const enterprise_generale_telephone =
    SupplimentaryData?.enterprise_generale_telephone ?? "";
  const maitre_doeuvre_address =
    SupplimentaryData?.maitre_doeuvre_address ?? "";
  const maitre_doeuvre_email = SupplimentaryData?.maitre_doeuvre_email ?? "";
  const maitre_doeuvre_name = SupplimentaryData?.maitre_doeuvre_name ?? "";
  const maitre_doeuvre_telephone =
    SupplimentaryData?.maitre_doeuvre_telephone ?? "";
  const maitre_douvrage_address =
    SupplimentaryData?.maitre_douvrage_address ?? "";
  const maitre_douvrage_email = SupplimentaryData?.maitre_douvrage_email ?? "";
  const maitre_douvrage_name = SupplimentaryData?.maitre_douvrage_name ?? "";
  const maitre_douvrage_telephone =
    SupplimentaryData?.maitre_douvrage_telephone ?? "";
  const model_name = SupplimentaryData?.model_name ?? "";

  /*==== Text to Vetical
 Function To convert the company name into vertical string 
 have to wrap each word in the company into different element to provide spacing between them
  ====== */
  let TopOffset = EnterprisePhone === "" && EnterpriseEmail === "" ? 365 : 380;

  if (CompanyInitial === "DOE") {
    TopOffset = EnterprisePhone === "" && EnterpriseEmail === "" ? 420 : 435;
  }

  if (CompanyInitial === "DAO") {
    TopOffset = EnterprisePhone === "" && EnterpriseEmail === "" ? 420 : 430;
  }

  // HTML Template jsPDF uses below html and convert the markup into PDF
  // do not use external style. Only inline style will work with below

  const PDFTemplate = `
  <table style="border-collapse:collapse; word-spacing:0px; vertical-align:top; border-color:#eeeeee; width:405px;font-family:'Roboto'; font-size:8px;line-height:1.4;">
  <tr>
    <td colspan="2" style="text-align:center;border:none;">
      <img src=${EnterpriseLogo} alt="test" style="height:50px; display:inline-block; max-height: 50px;"/>
    </td>
  </tr>
  <tr>
    <td style="width:5%; border:none;">
    </td>
    <td style="width:95%; border:none;  padding-bottom:20px;">
      <strong style="font-size:11px; font-family:'Roboto';text-transform:uppercase;">
      ${EnterpriseName?.replace(/\s+/g, "&nbsp;")}</strong><br/>
      ${EnterpriseAddress?.replace(/\s+/g, "&nbsp;")}</br>
      ${EnterprisePostalCode?.replace(
        /\s+/g,
        "&nbsp;"
      )} ${EnterpriseCity?.replace(/\s+/g, "&nbsp;")}</br>
      ${EnterpriseCountry?.replace(/\s+/g, "&nbsp;")}<br/><br/>
      ${
        EnterprisePhone === ""
          ? ""
          : `Tel :  ${EnterprisePhone?.replace(/\s+/g, "&nbsp;")} &nbsp;&nbsp;`
      } 
      ${
        EnterpriseEmail === ""
          ? ""
          : `Email: <a style="color:#2185d0;border-bottom:1px solid; padding-bottom:1px; display:inline-block; text-align:center; letter-spacing:0.2px;" href="mailto:${EnterpriseEmail}">${EnterpriseEmail}</a>`
      }
  </tr>
  <tr>
    <td rowspan="5" style="color:#fff; padding:5px; background:grey;">
     &nbsp;
    </td>
    <td style="border:1px solid #eee; border-left:none; border-bottom:none;vertical-align:top; padding:5px; ">
      <strong style="text-transform:uppercase; font-family:'Roboto'">${information_chantier_reference?.replace(
        /\s+/g,
        "&nbsp;"
      )}</strong></br>
      <strong>${information_chantier_address?.replace(
        /\s+/g,
        "&nbsp;"
      )}</strong><br/>
      <span>${information_chantier_villa?.replace(
        /\s+/g,
        "&nbsp;"
      )} ${information_chantier_pays?.replace(/\s+/g, "&nbsp;")} 
      ${information_chantier_postal?.replace(/\s+/g, "&nbsp;")}</span>
    </td>
  </tr>
  <tr>
    <td  style="border:1px solid #eee; border-left:none;border-bottom:none; vertical-align:top; padding:5px;">
      <table style="width:100%;min-height:100px; ">
      <tr>
      
      
      ${
        maitre_doeuvre_address === "" &&
        maitre_doeuvre_email === "" &&
        maitre_doeuvre_name === "" &&
        maitre_doeuvre_telephone === ""
          ? ""
          : `<td  style="width:50%;vertical-align:top;">
      <div style="padding:10px 0;"><span style="color:grey;">MAITRE D’ŒUVRE</span>
      <div>${maitre_doeuvre_name?.replace(/\s+/g, "&nbsp;")}</div>
      <div>${maitre_doeuvre_address?.replace(/\s+/g, "&nbsp;")}</div>
      ${
        maitre_doeuvre_email === ""
          ? ""
          : `<div>Email: ${maitre_doeuvre_email?.replace(
              /\s+/g,
              "&nbsp;"
            )}</div>`
      }      
      ${
        maitre_doeuvre_telephone === ""
          ? ""
          : `<div>Tél: ${maitre_doeuvre_telephone?.replace(/\s+/g, "&nbsp;")}
      </div>
      </div>`
      }
     
      </td>`
      }
      
      
      
      ${
        maitre_douvrage_address === "" &&
        maitre_douvrage_email === "" &&
        maitre_douvrage_name === "" &&
        maitre_douvrage_telephone === ""
          ? ""
          : `<td style="width:50%;vertical-align:top;">
      <div style="padding:10px 0;"><span style="color:grey;">MAITRE D’OUVRAGE</span>
      <div>${maitre_douvrage_name?.replace(/\s+/g, "&nbsp;")}</div>
      <div>${maitre_douvrage_address?.replace(/\s+/g, "&nbsp;")}</div>
      ${
        maitre_douvrage_email === ""
          ? ""
          : `<div>Email: ${maitre_douvrage_email?.replace(
              /\s+/g,
              "&nbsp;"
            )}</div>`
      }    
      ${
        maitre_douvrage_telephone === ""
          ? ""
          : `Tél: ${maitre_douvrage_telephone?.replace(/\s+/g, "&nbsp;")}</div>`
      }

      </td>`
      }
      
      </tr>
      <tr>
      
      
      ${
        enterprise_generale_address === "" &&
        enterprise_generale_email === "" &&
        enterprise_generale_name === "" &&
        enterprise_generale_telephone === ""
          ? ""
          : `<td style="vertical-align:top;">
      <div style="padding:10px 0;"><span style="color:grey;">ENTREPRISE GÉNÉRALE</span>​
      <div> ${enterprise_generale_name?.replace(/\s+/g, "&nbsp;")}</div>
      <div>${enterprise_generale_address?.replace(/\s+/g, "&nbsp;")}</div> 
      ${
        enterprise_generale_email === ""
          ? ""
          : `<div>Email: ${enterprise_generale_email?.replace(
              /\s+/g,
              "&nbsp;"
            )}</div>`
      }      
      ${
        enterprise_generale_telephone === ""
          ? ""
          : `<div>Tél: ${enterprise_generale_telephone?.replace(
              /\s+/g,
              "&nbsp;"
            )}</div>`
      }
     
      </td>`
      }
      
      <td></td>
      </tr>
      </table>
      
     
      
    </td>
  </tr>
  ${
    lot_1 === "" && lot_2 === "" && lot_3 === ""
      ? ""
      : `<tr>
    <td  style="border:1px solid #eee;border-left:none;border-bottom:none; text-align:center; text-transform:uppercase;padding:5px;">
      ${
        lot_1 === ""
          ? ""
          : `<div>LOT&nbsp;1: ${lot_1?.replace(/\s+/g, "&nbsp;")}</div>`
      }
      ${
        lot_2 === ""
          ? ""
          : `<div>LOT&nbsp;2: ${lot_2?.replace(/\s+/g, "&nbsp;")}</div>`
      }
      ${
        lot_3 === ""
          ? ""
          : `<div>LOT&nbsp;3: ${lot_3?.replace(/\s+/g, "&nbsp;")}</div>`
      }
    </td>
  </tr>`
  }
  <tr>
    <td style="border:1px solid #eee;border-left:none; padding:5px; border-bottom:none;">
      <div style="font-size:50px;color:grey;font-weight:bold; font-family:'Roboto'">${CompanyInitial}</div>
      <div style="text-align:right;">${information_chantier_referencecctp.replace(
        /\s+/g,
        "&nbsp;"
      )}</div>
      <div style="text-align:right;">Le ${(
        "0" + new Date(information_chantier_add_date).getDate()
      ).slice(-2)}/${(
    "0" +
    (new Date(information_chantier_add_date).getMonth() + 1)
  ).slice(-2)}/${new Date(information_chantier_add_date).getFullYear()}</div>
      <div style="text-align:right; color:grey;">${DossierTitle}</div>
    </td>
  </tr>
  <tr>
    <td colspan="2" style="background:grey; border:1px solid grey; height:18px;">&nbsp;</td>
  </tr>
</table>
  `;
  const doc = new jsPDF("p", "px", "a4");
  //doc.addFont("https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf", 'Roboto', 'normal');
  //doc.addFont("https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOKCWcynf_cDxXwCLxiixG1c.ttf", 'Roboto Bold', 'bold');
  doc.setFont("Roboto");
  // doc.setFont('Roboto', 'normal');
  const newVar = new Promise((resolve, reject) => {
    if (PDFTemplate) {
      doc.html(PDFTemplate, {
        callback: function (doc) {
          // setting up path as datauri string which can be used as tempory url other methods
          // can be found here https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html#output
          doc.setProperties({
            title: "Page de garde",
            subject: "Page de garde",
            author: "BIM",
            creator: "BIM",
          });
          doc.setTextColor(255, 255, 255);
          doc.setFontSize(18);
          doc.text(DossierTitle, 36, TopOffset, {
            angle: 90,
            rotationDirection: 1,
            charSpace: -1,
          });
          resolve([doc.output("datauristring"), doc.output("blob")]);
        },
        x: 20,
        y: 20,
      });
    } else {
      reject("400");
    }
  });
  newVar.catch((err) => {
    throw err;
  });
  return newVar;
};

//const PDFUri = PDFPreview().then(value=>value)
