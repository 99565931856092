import React from "react";
import { ReleaseNote } from "./ReleaseNote";
import { Routes } from "./root";

interface Props {
  route: Routes;
  setRoute: any;
}

export const AppBody = (props: Props) => {
  switch (props.route) {
    case Routes.ROOT:
      return <ReleaseNote />;
    default:
      return null;
  }
};
