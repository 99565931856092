import React, { Component } from "react";
import { SelectionStore } from "../../../Selection/Reducers";
import { FunctionalityHeader } from "../FunctionalityHeader";
import {
  Icon,
  Header,
  Button,
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";
import "../../Resources/CommonStyles.css";
import { connect } from "react-redux";
import { Routes } from "../root";

interface Props {
  backgroundColor: string;
  routes: string;
  setRoute: any;
  wording: {
    name: {
      [key: string]: string;
    };
    systemCorrespondenceTable: {
      [key: string]: string;
    };
    addSystemType: {
      [key: string]: string;
    };
    back: {
      [key: string]: string;
    };
    record: {
      [key: string]: string;
    };
  };
  language: string;
  Icon: string;
}

export class AddSystemType extends Component<Props> {
  public static defaultProps = {
    wording: {
      name: {
        French: "SELECTION",
        English: "SELECTION",
      },
      systemCorrespondenceTable: {
        French: "Table de correspondance des systèmes",
        English: "Systems correspondence table",
      },
      addSystemType: {
        French: "Ajouter un type de système",
        English: "Add a System Type",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      record: {
        French: "ENREGISTRER",
        English: "Record",
      },
    },
  };

  render() {
    const {
      language,
      wording: { name, systemCorrespondenceTable, addSystemType, back, record },
    } = this.props;
    return (
      <div>
        <FunctionalityHeader
          name={name[language]}
          Icon={this.props.Icon}
          subheader=""
        />
        <Container className="mainContainer">
          <Header className="subHeader" as="h5">
            Etape 2/2 :{systemCorrespondenceTable[language]}
          </Header>
          <Segment>
            <Grid columns={5} divided>
              <Grid.Row>
                <Grid.Column style={{ width: "100%" }}>
                  {/* {Object.keys(selections).length ? (
                  <SavedSelections setRoute={setRoute} />
                ) : (
                  <NoSelectionSaved language={language} />
                )} */}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    textAlign: "left",
                    display: "inline-grid",
                  }}
                >
                  <div>
                    <Icon name="plus circle" color="blue" size="big" />
                    <span style={{ color: "rgb(14,110,184)" }}>
                      {addSystemType[language]}
                    </span>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        <Container style={{ textAlign: "center", paddingTop: 20 }}>
          <Button.Group>
            <Button
              color="orange"
              onClick={() => this.props.setRoute(Routes.REFERENCE_CCTP)}
            >
              {back[language]}
            </Button>
            <Button.Or />
            <Button
              type="submit"
              primary
              onClick={() => this.props.setRoute(Routes.TECHNICAL_ROOT)}
            >
              {record[language]}
            </Button>
          </Button.Group>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    setRoute: ownProps.setRoute,
    routes: ownProps.route,
    language: state.language,
  };
};

export default connect(mapStateToProps)(AddSystemType);
