import { Action } from 'redux'
import { ProductDetailData, } from "../../../../../RevitJS/Types/BddTypes";

export const SET_CURRENT_SYSTEM_DETAIL = "SET_CURRENT_SYSTEM_DETAIL";

export interface SystemDetailsState {
  system: ProductDetailData | {}
}

interface SetCurrentSystemAction extends Action {
  type: typeof SET_CURRENT_SYSTEM_DETAIL
  system: ProductDetailData,
}


export type SystemDetailsActionTypes = SetCurrentSystemAction

