import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Icon, Input } from "semantic-ui-react";
import { SelectionStore } from "../../../Selection/Reducers";
interface FunctionProps {
  index: number;
  changeCloneNonPlacoName: any;
  defaultValue: any;
  nonPlacoName: any;
  setName: any;
  TempNonPlacoName: string;
  setTempName: any;
  updateStatus?: any;
  CCTPReference: any;
}

export const NonPlacoSolutionRow = (props: FunctionProps) => {
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  // for input field color(red or blue) validation
  const [saved, setSave] = useState(false);

  // handle input change
  const onInputChange = (e: any) => {
    if (e.target.value === "" || e.target.value !== props.TempNonPlacoName) {
      setSave(false);
      props.updateStatus(false); // disable valid button if any change made in saved value
    }
    props.setName({
      label: e.target.value,
      value: "custom_" + reduxState.selectorType + "_" + props.index,
      isNonPlaco: true,
    });
    props.setTempName("");
  };

  // validates the input field
  const validate = (nonPlacoName: {
    label: any;
    value?: string;
    isNonPlaco?: boolean;
  }) => {
    if (nonPlacoName.label !== "") {
      props.changeCloneNonPlacoName(props.index, nonPlacoName);
      setSave(true);
      props.setTempName(nonPlacoName.label); // set temporary name to compare previous saved and current value for validation
      if (props.CCTPReference !== "") {
        props.updateStatus(true); // if cctpreference field is empty then keep the valid button disabled
      }
    } else {
      setSave(false);
    }
  };

  return (
    <Input
      style={{ width: "100%" }}
      error={!saved}
      value={props.nonPlacoName.label}
      onChange={(e: any) => {
        onInputChange(e);
      }}
      onKeyPress={(event: any) => {
        if (event.key === "Enter") {
          validate(props.nonPlacoName);
        }
      }}
      icon={
        <Icon
          search
          link
          name="save"
          color={saved ? "blue" : "red"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            validate(props.nonPlacoName);
          }}
        />
      }
    />
  );
};
