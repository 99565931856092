import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { queryPlacoData } from "../API";
import { bimStorage, storageKey } from "../BIMStore";
import { GetTokenExpiryInSecs } from "../Components/CommonComponents/Session/Authentication";
import PluginHome from "../Components/Store/PluginHome";
import StoreHome from "../Components/Store/StoreHome";
import {
  InjectedCounterProps,
  withErrorSubscription,
} from "../ErrorManagement/components/ErrorBoundry";
import { getAllApplicationDetails, MyConfig } from "../Helper";
import { CheckEnvironmentAccessibility } from "../Plugins";
import { StoreState } from "../Reducers";
import { selectConfig, selectPlugin } from "../Store/Actions";
import { api } from "../RevitJS/API";

export const DockWindow = (props: InjectedCounterProps) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state: StoreState) => state);
  const tokenExpiry = GetTokenExpiryInSecs();

  useEffect(() => {
    // api.windowsHandler.showDevTools();

    async function loadConfig() {
      const con = await MyConfig();
      dispatch(selectConfig(con));
    }

    async function tokenExpired() {
      if (tokenExpiry.isExpire) {
        const config = await MyConfig();
        window.location.href =
          config.REACT_APP_AUTHENTICATIONURL +
          `` +
          config.REACT_APP_LOGIN_REDIRECT;
      }
    }
    tokenExpired();
    loadConfig();
  }, []);

  useEffect(() => {
    if (!tokenExpiry.isExpire && Object.keys(reduxState.config).length > 0) {
      fetchApplicationDetails().catch((error) => {
        props.onError(error.response.status, error.stack, true);
      });
      placoSolutionFromServer().catch((error) => {
        props.onError(error.response.status, error.stack, true);
      });
    }
  }, [tokenExpiry.isExpire, reduxState.config]);

  const placoSolutionFromServer = async () => {
    return await queryPlacoData(reduxState.config);
  };

  const fetchApplicationDetails = async () => {
    let allApps: any = await getAllApplicationDetails(reduxState.config);
    allApps = CheckEnvironmentAccessibility(
      allApps.data,
      reduxState.config.REACT_APP_ENV as string
    );
    bimStorage.setItem(
      storageKey.ALL_APPLICATION_DATA,
      JSON.stringify(allApps)
    );
  };

  if (!tokenExpiry.isExpire && Object.keys(reduxState.config).length > 0) {
    if (reduxState.selectedPluginId) {
      return <PluginHome />;
    }

    return (
      <StoreHome plugins={reduxState.plugins} selectPlugin={selectPlugin} />
    );
  }

  return <></>;
};

export default withErrorSubscription(DockWindow);
