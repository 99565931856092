import React from "react";
import { Button, Segment, Icon, Popup, Modal } from "semantic-ui-react";
import { Routes } from "./root";
import { BackupModal } from "./TechnicalLanding/BackupModal";
import { DownloadLoader } from "./TechnicalLanding/DownloadLoader";
import FormatChoiceModal from "./TechnicalLanding/FormatChoiceModal";
import { InformationModal } from "./TechnicalLanding/InformationModal";
import {
  isArrayNull,
  onLinkClick,
  saveDossierTechniques,
  getDate,
} from "../../DocumentTechniques/Helpers";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import {
  downloadZipFiles,
  getAllValidFiles,
  mergePdfs,
  savePDF,
} from "../Helpers/DownloadHelper";
import { setDossierData } from "../Actions";
import DocumentTypeModal from "./TechnicalLanding/DocumentTypeModal";
import {
  CheckFilePDFPhotoBox,
  DownloadPDFPhotoBox,
  GeneratePDFPhotoBox,
  //UploadLogoToPhotobox,
} from "../Helpers/PhotoboxApiHelper";
import { DownloadDocumentFromFileManagement } from "../Helpers/FilemanagementApiHelper";
import { PDFPreview } from "./CoverPage/PDFPreview";
import isEqual from "lodash/isEqual";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import { PDFPreview2019 } from "./CoverPage/PDFPreview2019";
import { DisplayMessage } from "../../CommonModules/PopUpModals/DisplayMessage";
import { wait } from "../../../../RevitJS/Helpers";
import { CctpUploader } from "./Cctp/cctpUploader";
import { AnyAction } from "redux";
import _ from "lodash";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";

interface Props {
  setRoute: any;
  route: Routes;
  dossierData: any;
  setDossierData: any;
  language: string;
  wording: {
    objects: { [key: string]: string };
    back: { [key: string]: string };
    cancel: { [key: string]: string };
    validate: { [key: string]: string };
    download: { [key: string]: string };
    pleaseContact: { [key: string]: string };
    technicalAssistance: { [key: string]: string };
    ifnecessary: { [key: string]: string };
    notAvailableforDownload: { [key: string]: string };
    // generateMsg: { [key: string]: string };
    // checkFileMsg: { [key: string]: string };
    downloadMsg: { [key: string]: string };
    wrongMsg: { [key: string]: string };
    popupObject: { [key: string]: string };
    popupdocument: { [key: string]: string };
    errormsg: { [key: string]: string };
    solutionIdErrorMsg: { [key: string]: string };
    documentNoAvailable: { [key: string]: string };
  };
  isAllStepsCompleted: boolean;
  showWarning: boolean;
  config: any;
  onError: any;
}

export class ButtonPack extends React.Component<Props> {
  state = {
    informationModal: false,
    documentTypeModal: false,
    formatChoiceModal: false,
    backupModal: false,
    dossierData: null,
    infoState: {
      subjectMsg: "",
      messageList: [],
      footerMessage: [],
    },
    loaderMessage: "",
    buttonList: [{ name: "", action: null, color: "" }],
    showWarning: false,
    messageBoxModal: false,
    showSolutionIdError: false,
    solutionNames: [],
    isDownloadSuccessful: false,
    isError: false,
    notFoundSolutions: [],
    cctpFilesUploader: false,
    fileLocation: "",
    fileDownloaded: false
  };

  componentDidMount = () => {
    this.setState({ dossierData: this.props.dossierData });
    if (this.props.dossierData.isSelectionUpdated) {
      this.setState({
        showWarning: true,
      });
    }
  };

  HandleOk = () => {
    this.setState({
      informationModal: false,
      formatChoiceModal: false,
    });
  };

  toggleButtonByType = (type: string) => {
    switch (type) {
      case "DAO":
        this.toggleModal("loaderMessage", "Generating File. Please wait");
        this.downloadDocument(type).catch((error) => {
          throw error;
        });

        //this.toggleModal("loader", true);
        break;
      case "DAE":
        this.toggleModal("documentTypeModal", true);
        break;
      case "DOE":
        this.toggleModal("documentTypeModal", true);
        break;
      default:
        alert("Please select other docuement type");
    }
  };

  toggleModal = (modalName: string, action: any) => {
    this.setState({ [modalName]: action });
  };

  downloadObjects = () => {
    let fileArr = isArrayNull(getAllValidFiles(this.state.dossierData));
    let filePresentList = fileArr.filter((file: any) => file.isPresent);
    let fileAbsentList = fileArr.filter((file: any) => !file.isPresent);

    if (fileAbsentList.length > 0) {
      this.setState({
        buttonList: [{ name: "OK", action: this.HandleOk, color: "blue" }],
      });
      this.toggleModal("informationModal", true);
      let infoState = {
        subjectMsg:
          this.props.wording.notAvailableforDownload[this.props.language],
        messageList: fileAbsentList.map(
          (file: any) =>
            `${file.solution} - (${file.fileExtension.toUpperCase()})`
        ),
        footerMessage: [
          this.props.wording.pleaseContact[this.props.language] + " ",
          <a
            style={{ cursor: "pointer" }}
            onClick={() =>
              onLinkClick("mailto:assistance.placo@saint-gobain.com")
            }
          >
            {this.props.wording.technicalAssistance[this.props.language]}
          </a>,
          " " + this.props.wording.ifnecessary[this.props.language],
        ],
        open: true,
      };
      this.setState({ infoState });
    }
    if (filePresentList.length > 0) {
      downloadZipFiles(filePresentList, this.props.config);
    }
  };

  onDownloadObjectCheck = (item: any) => {
    let itemsList = [];
    let data: any = this.state.dossierData;
    itemsList = data["downloadObj"];
    let selectedData = itemsList.find((dt: any) => dt.name === item);
    selectedData.isSelected = !selectedData.isSelected;
    this.setState({ dossierData: data });
    this.props.setDossierData(data);
  };

  onSolutionIdMissingHandle = (cctpName: string) => {
    let solutionName: string = "";
    this.props.dossierData.data.list.forEach((element: any) => {
      if (element.CCTPReference === cctpName) {
        solutionName = element.Solution.label;
      }
    });
    this.setState({ solutionNames: [solutionName] });
    this.toggleModal("loaderMessage", "");
    if (this.state.documentTypeModal) {
      this.setState({
        documentTypeModal: false,
      });
    }
    this.setState({ showSolutionIdError: true });
  };

  downloadDocument = async (type: any) => {
    try {
      localStorage.removeItem("notAvailableSolutions");

      //if cancel on loader is clicked,set it false and exit else execute rest

      let cancelProcess: boolean = false;

      this.setState({
        buttonList: [
          {
            name: this.props.wording.cancel[this.props.language],
            action: () => {
              this.toggleModal("loaderMessage", "");
              cancelProcess = true;
            },
            color: "orange",
          },
        ],
      });

      const cctpFiles = this.props.dossierData.data.list.filter(
        (lf: any) => lf.isSelected === true
      );

      if (!cancelProcess) {
        if (cctpFiles.length > 0) {
          this.setState({ cctpFilesUploader: true });
        } else {
          this.resumePdfGenerator(this.props.dossierData).catch((error) => {
            console.log("error code object is:", error);
            // this.props.onError("401", error.stack, true);
            throw error;
          });
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  resumePdfGenerator = async (dossierData: any) => {
    try {
      this.setState({ cctpFilesUploader: false });

      let timeout: boolean = false;
      let checkPDF: boolean = false;
      let checkPDFError: boolean = false;

      let cancelProcess: boolean = false;

      this.setState({
        buttonList: [
          {
            name: this.props.wording.cancel[this.props.language],
            action: () => {
              this.toggleModal("loaderMessage", "");
              cancelProcess = true;
            },
            color: "orange",
          },
        ],
      });

      if (cancelProcess) {
        cancelProcess = false;
      } else {
        this.toggleModal(
          "loaderMessage",
          this.props.wording.downloadMsg[this.props.language]
        );

        var fileName = `file_${dossierData.documentType}_${getDate().replace(
          /\//g,
          "_"
        )}_${new Date().getTime()}.pdf`;

        const generatePDF = await GeneratePDFPhotoBox(
          dossierData.documentType,
          dossierData,
          this.props.config
        )
          .then(async (response: any) => {
            if (response.status === 200) {
              return response.data.body;
            } else {
              return null;
            }
          })
          .catch((error) => {
            console.log("error iss:", error);
            //this.props.onError(error.response.status, error.stack, true);

            return Promise.reject({
              code: errorCodeKey.PB_DT_H_003,
              stack: error.stack,
            });
          });

        if (generatePDF === null) {
          this.toggleModal("loaderMessage", "");
          this.toggleModal("messageBoxModal", true);
        } else {
          setTimeout(() => {
            timeout = true;
          }, 300000);

          while (!checkPDF && !timeout && !checkPDFError && !cancelProcess) {
            const checkFile = await CheckFilePDFPhotoBox(
              generatePDF,
              this.props.config
            )
              .then(async (response: any) => {
                if (response.status === 200) {
                  if (response.data.errors.length > 0) {
                    const errmess = response.data.errors[0].error;
                    return {
                      isAvailable: false,
                      isError: true,
                      mesError: errmess,
                      errorResponse: response.data.errors[0],
                    };
                  }

                  return {
                    isAvailable: response.data.fileExists,
                    isError: false,
                    mesError: "",
                    errorResponse: [],
                  };
                } else {
                  return {
                    isAvailable: false,
                    isError: true,
                    mesError: "",
                    errorResponse: response.data.errors[0],
                  };
                }
              })
              .catch((error) => {
                //console.log("error :::", error);
                return Promise.reject({
                  code: errorCodeKey.PB_DT_L_004,
                  stack: error.stack,
                });
              });
            await wait(3000);

            if (checkFile.isError) {
              checkPDFError = checkFile.isError;
              if (checkFile.mesError !== "") {
                if (checkFile.mesError === "204 ") {
                  this.onSolutionIdMissingHandle(
                    checkFile.errorResponse.articleCCTP
                  );
                } else {
                  this.toggleModal("loaderMessage", checkFile.mesError);
                }
              } else {
                this.toggleModal("messageBoxModal", true);
              }
            }

            checkPDF = checkFile.isAvailable;
          }

          if (timeout) {
            this.toggleModal("loaderMessage", "");
            this.toggleModal("messageBoxModal", true);
          }

          if (checkPDF) {
            await DownloadPDFPhotoBox(generatePDF, this.props.config)
              .then(async (response: any) => {
                console.log("DownloadPDFPhotoBox response: ", response);
                if (response !== null && response.status === 200) {
                  let photoBoxResponse = response.data;

                  let coverPageResponse: any = null;
                  let file: string = dossierData.coverPage.fileData;

                  coverPageResponse = await this.getCoverPage(file, dossierData);

                  // const version = await api.framework.getVersion();

                  // if (file === "default") {
                  //   if (version && version.split(".")[0] === "19") {
                  //     let rawdata: any = await PDFPreview2019(
                  //       dossierData,
                  //       this.props.config
                  //     )
                  //       .then(async (resp: any) => {
                  //         return await new Response(resp[1]).arrayBuffer();
                  //       })
                  //       .catch((error) => { });
                  //     coverPageResponse = rawdata;
                  //   } else {
                  //     let rawdata: any = await PDFPreview(
                  //       dossierData,
                  //       this.props.config
                  //     )
                  //       .then(async (resp: any) => {
                  //         return await new Response(resp[1]).arrayBuffer();
                  //       })
                  //       .catch((error) => { });
                  //     coverPageResponse = rawdata;
                  //   }
                  // } else {
                  //   coverPageResponse =
                  //     await DownloadDocumentFromFileManagement(
                  //       file,
                  //       this.props.config
                  //     )
                  //       .then(async (resp: any) => {
                  //         return await new Response(resp.data).arrayBuffer();
                  //       })
                  //       .catch((error) => { });
                  // }

                  let finalFile = await mergePdfs(
                    coverPageResponse,
                    photoBoxResponse
                  );

                  var blob = new Blob([finalFile], {
                    type: "application/pdf",
                  });
                  savePDF(blob, fileName);
                  this.sendEvent(dossierData);
                  let notAvailableSolutions = JSON.parse(
                    localStorage.getItem("notAvailableSolutions") as string
                  );
                  if (
                    notAvailableSolutions &&
                    notAvailableSolutions.length > 0
                  ) {
                    this.setState({ isError: true });
                    this.setState({ notFoundSolutions: notAvailableSolutions });
                  } else {
                    this.setState({ isDownloadSuccessful: true });
                  }
                }
                else if (response.includes("Downloaded")) {
                  console.log("Downloaded start");
                  let coverPageResponse: any = null;
                  let file: string = dossierData.coverPage.fileData;
                  console.log("response.split('~'):", response.split('~'));
                  let downloadedFilename = response.split('~')[1]
                  console.log("downloadedFilename:", downloadedFilename);
                  coverPageResponse = await this.getCoverPage(file, dossierData);
                  console.log("coverPageResponse:", coverPageResponse);
                  let pdfstring: string = await api.queries.mergeAndSaveFile(coverPageResponse, downloadedFilename, fileName);
                  console.log("pdfstring:", pdfstring);
                  if (pdfstring !== "") {
                    this.setState({ fileLocation: pdfstring });
                    this.setState({ fileDownloaded: true });
                  }
                }
                else {
                  this.toggleModal("loaderMessage", "");
                  this.toggleModal("messageBoxModal", true);
                }
              })
              .catch((error) => {
                console.log("error caught", error);
                return Promise.reject({
                  code: errorCodeKey.PB_DT_L_006,
                  stack: error.stack,
                });
              });
            this.toggleModal("loaderMessage", "");
            setTimeout(() => {
              this.setState({
                documentTypeModal: false,
              });
            }, 1500);
          }
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getCoverPage = async (file: any, dossierData: any) => {
    let coverPageResponse: any = null;
    const version = await api.framework.getVersion();
    if (file === "default") {
      if (version && version.split(".")[0] === "19") {
        let rawdata: any = await PDFPreview2019(
          dossierData,
          this.props.config
        )
          .then(async (resp: any) => {
            return await new Response(resp[1]).arrayBuffer();
          })
          .catch((error) => { });
        coverPageResponse = rawdata;
      } else {
        let rawdata: any = await PDFPreview(
          dossierData,
          this.props.config
        )
          .then(async (resp: any) => {
            return await new Response(resp[1]).arrayBuffer();
          })
          .catch((error) => { });
        coverPageResponse = rawdata;
      }
    } else {
      coverPageResponse =
        await DownloadDocumentFromFileManagement(file, this.props.config)
          .then(async (resp: any) => {
            return await new Response(resp.data).arrayBuffer();
          })
          .catch((error) => { });
    }

    return coverPageResponse
  }

  openFileExplorer = async () => {
    await api.queries.openFileExplorer(this.state.fileLocation);
  }

  sendEvent = async (dossierData: any) => {
    let projectInfo: any = await api.queries.getProjectInformation();
    let projectName = projectInfo.Name;
    let projectData: any = await api.queries.getProjectData();
    let fileName = projectData.ProjectPath.split("\\").pop();
    let revitVersion = await api.framework.getRevitVersion();
    let solutionList: any = dossierData.data.list.map((o: AnyAction) => {
      return { elementType: o.RevitSystems, solution: o.Solution.label };
    });
    const annexDocumentPairs: { name: string; key: string }[] = _.compact(
      dossierData.downloadDAE.map((obj: any) => {
        if (obj.isSelected) {
          return { name: obj.name, key: obj.key };
        }
      })
    );
    const actualFiles: string[] = [];
    dossierData.data.list.forEach((dataItem: any) => {
      if (dataItem.isSelected) {
        dataItem.files.forEach((file: any) => {
          actualFiles.push(file.fileType);
        });
      }
    });
    const annexDocuments: string[] = annexDocumentPairs
      .filter((value) => actualFiles.includes(value.key))
      .map((value) => value.name);
    api.eventLog.SetEvent({
      data: [
        {
          name: "Name of solutions",
          value: "",
          values: solutionList,
        },
        {
          name: "List of Annex Documents",
          value: "",
          values: [
            {
              Dossier: dossierData.documentType,
              Annexes: annexDocuments.join(","),
            },
          ],
        },
        {
          name: "Information",
          value: "",
          values: [
            {
              File_Name: fileName,
              Project_Name: projectName,
              Version: revitVersion,
            },
          ],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Dossier Technique",
      module: "PLACOBIM",
    });
  };

  onDownloadDocumentCheck = (item: any) => {
    let itemsList = [];
    let data: any = this.state.dossierData;
    itemsList = data["downloadDAE"];
    let selectedData = itemsList.find((dt: any) => dt.name === item);
    selectedData.isSelected = !selectedData.isSelected;
    this.setState({ dossierData: data });
    this.props.setDossierData(data);
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  checkSavedDossier = async () => {
    let tempDossierData: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    let dossierData = tempDossierData === null ? [] : tempDossierData;
    let docId = this.props.dossierData.documentId;
    let existingDoc = dossierData.find((e: any) => e.documentId === docId);
    let newExisting = existingDoc;
    let currState: any = this.state.dossierData;
    let newState = currState;
    if (existingDoc !== undefined) {
      // externalID is not available sometimes to check for the equality
      // I have added temporary external id incase thers is not one
      let AddExternalIdtoExisting = existingDoc.data.list.map((e: any) => {
        e.Solution["externalId"] = e.Solution["externalId"];
        return e;
      });
      let AddExternalIdtoState = currState.data.list.map((e: any) => {
        e.Solution["externalId"] = e.Solution["externalId"];
        return e;
      });
      newExisting["list"] = AddExternalIdtoExisting;
      newState["list"] = AddExternalIdtoState;
    }

    let isExist = isEqual(newExisting, newState);
    isExist
      ? this.props.setRoute(Routes.TECHNICAL_ROOT)
      : this.toggleModal("backupModal", true);
  };

  closeUploader = () => {
    this.setState({ cctpFilesUploader: false });
    this.toggleModal("loaderMessage", "");
  };

  render() {
    return (
      <Segment basic style={{ marginBottom: 0 }}>
        {this.state.cctpFilesUploader && (
          <CctpUploader
            dossierData={this.props.dossierData}
            resumePdfGenerator={this.resumePdfGenerator}
            closeUploader={this.closeUploader}
            config={this.props.config}
            wording={this.props.wording}
            language={this.props.language}
            onError={this.props.onError}
          />
        )}
        <Modal size="tiny" open={this.state.showWarning} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>
              Un ou plusieurs murs appartenant à une ou plusieurs sélections
              enregistrées a été supprimé ou modifié (type) dans le projet.
              Veuillez revérifier le contenu de vos Dossier Technique
            </p>
            <Button
              primary
              onClick={() => {
                delete this.props.dossierData.isSelectionUpdated;
                this.setState({ showWarning: false });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <Modal
          size="tiny"
          open={this.state.isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>
              Information - Dossier Technique
            </h3>
            <p>Génération du dossier technique terminée avec succès.</p>
            <Button
              primary
              onClick={() => {
                let itemsList = [];
                let data = this.props.dossierData;
                itemsList = data["downloadDAE"];
                itemsList.map((dt: any) => {
                  dt.isSelected = true;
                  return dt;
                });
                this.setState({
                  dossierData: data,
                });

                this.props.setDossierData(data);
                this.setState({ isDownloadSuccessful: false });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <Modal size="small" open={this.state.isError} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>
              Information - Dossier Technique
            </h3>
            <p>Génération du dossier technique terminée. Les solutions :</p>
            {this.state.notFoundSolutions.map((solution, key) => {
              return (
                <p
                  key={key}
                  style={{ color: "rgb(33, 133, 208)", marginBottom: "5px" }}
                >
                  {solution}
                </p>
              );
            })}
            <p>N'ont pas pu être prise en compte.</p>
            <p>
              Veuillez contacter
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onLinkClick("mailto:suppport.placobim@saint-gobain.com")
                }
              >
                {" "}
                suppport.placobim@saint-gobain.com
              </a>{" "}
              de PLACO® si besoin.
            </p>
            <Button
              primary
              onClick={() => {
                api.windowsHandler.closeWindow();
                this.setState({ notFoundSolutions: [] });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <Modal
          size="tiny"
          open={this.state.showSolutionIdError}
          dimmer="blurring"
          style={{
            textAlign: "center",
          }}
        >
          <Modal.Description
            style={{
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            {this.props.wording.documentNoAvailable[this.props.language] +
              this.state.solutionNames +
              this.props.wording.solutionIdErrorMsg[this.props.language]}
          </Modal.Description>
          <Button
            primary
            onClick={() => {
              this.setState({ showSolutionIdError: false });
            }}
            style={{
              marginBottom: "5px",
            }}
          >
            {this.props.wording.cancel[this.props.language]}
          </Button>
        </Modal>

        <Modal size="tiny" open={this.state.fileDownloaded} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>
              File downloaded at below location
            </p>
            <a onClick={this.openFileExplorer} style={{ wordWrap: "break-word", cursor: "pointer" }}>{this.state.fileLocation}</a><br></br><br></br>
            <Button
              primary
              onClick={() => {
                this.setState({ fileDownloaded: false });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <FormatChoiceModal
          formatChoiceModal={this.state.formatChoiceModal}
          toggleFormatChoiceModal={() =>
            this.toggleModal("formatChoiceModal", true)
          }
          closeDimmer={() => this.toggleModal("formatChoiceModal", false)}
          downloadObjects={() => this.downloadObjects()}
          onDownloadObjectCheck={this.onDownloadObjectCheck}
          wording={this.props.wording}
          dossierData={this.state.dossierData}
        />
        <DocumentTypeModal
          documentTypeModal={this.state.documentTypeModal}
          toggleDocumentTypeModal={() =>
            this.toggleModal("documentTypeModal", true)
          }
          closeDimmer={() => this.toggleModal("documentTypeModal", false)}
          downloadDocument={() =>
            this.downloadDocument(this.props.dossierData.documentType)
          }
          onDownloadDocumentCheck={this.onDownloadDocumentCheck}
          wording={this.props.wording}
          dossierData={this.state.dossierData}
        />
        <DownloadLoader
          buttonList={this.state.buttonList}
          loaderMessage={this.state.loaderMessage}
        />
        <BackupModal
          backupModal={this.state.backupModal}
          docType={this.props.dossierData.documentType}
          toggleloader={() => this.toggleModal("backupModal", true)}
          closeDimmer={() => this.toggleModal("backupModal", false)}
          cancel={this.props.wording.cancel[this.props.language]}
          saveAction={() =>
            saveDossierTechniques(this.state.dossierData, this.props.config)
          }
          setRoute={this.props.setRoute}
        />
        <InformationModal
          informationModal={this.state.informationModal}
          toggleInfoModal={() => this.toggleModal("informationModal", true)}
          // closeDimmer={this.HandleOk}
          setRoute={this.props.setRoute}
          route={this.props.route}
          infoState={this.state.infoState}
          buttonList={this.state.buttonList}
        />
        <Button
          style={{ marginLeft: 30 }}
          floated="left"
          size="medium"
          color="orange"
          content={this.props.wording.back[this.props.language]}
          onClick={() => this.checkSavedDossier()}
        />
        <DisplayMessage
          open={this.state.messageBoxModal}
          message={this.props.wording.errormsg[this.props.language]}
          onClose={() => {
            this.setState({ messageBoxModal: false });
          }}
        />

        <Popup
          trigger={
            <Button
              style={{ marginRight: 50 }}
              floated="right"
              size="medium"
              primary
              icon
              disabled={!this.props.isAllStepsCompleted}
              onClick={() => {
                this.toggleButtonByType(this.props.dossierData.documentType);
              }}
            >
              <Icon name="download" />
              {" " + this.props.dossierData.documentType}
            </Button>
          }
          content={
            this.props.wording.popupdocument[this.props.language] +
            " " +
            this.props.dossierData.documentType
          }
          on="hover"
          inverted
          size="mini"
          position="bottom center"
        />
      </Segment>
    );
  }
}

const mapDispatch = {
  setDossierData,
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    language: state.language,
    setRoute: ownProps.setRoute,
    routes: ownProps.route,
    dossierData: state.moduleData.dossierData,
    Icon: state.functionalityIcon,
    config: state.config,
  };
};

export default connect(mapStateToProps, mapDispatch)(ButtonPack);
