import {
    USER_ACCEPTED_GENERAL_TERMS_OF_USE,
    USER_SKIP_INTRO,
} from "../assets/constants";
import { ACCEPT_TERMS_OF_USE, SHOW_INTRO } from "./actionTypes";

export function acceptTermsOfUse(accepted: boolean) {
    localStorage.setItem(
        USER_ACCEPTED_GENERAL_TERMS_OF_USE,
        accepted.toString()
    );
    return {
        type: ACCEPT_TERMS_OF_USE,
        payload: accepted,
    };
}

export function skipIntro(show: boolean) {
    localStorage.setItem(USER_SKIP_INTRO, show.toString());
    return {
        type: SHOW_INTRO,
        payload: show,
    };
}
