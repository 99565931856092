import React from "react";
import { Header, Grid, Label, Dropdown } from "semantic-ui-react";
import { Action } from "redux";
import { ID, Language } from "../../RevitJS/Types/RevitTypes";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import StoreBody from "./StoreBody";
import ReactFlagsSelect from "react-flags-select";
import { wait } from "../../RevitJS/Helpers";
import { api } from "../../RevitJS/API";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "../../Reducers";
import { initLanguage, setLanguage, setPlugins } from "../../Actions";
import Axios from "axios";
import { profileTrigger } from "../../Plugins/General/Profile";
import { MyConfig } from "../../Helper";
import UpdatePanel from "./UpdatePanel";
import { getNewJwtToken } from "../../API/authenticate.api";

const mapStateToProps = (state: StoreState) => {
  return {
    language: state.language,
    languageInitialized: state.languageInitialized,
    config: state.config,
  };
};

const mapDispatchToProps = {
  initLanguage,
  setLanguage,
  setPlugins,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type StoreContainerProps = PropsFromRedux & {
  plugins: Plugin[];
  selectPlugin: (id: ID) => Action;
  language: Language;
  wording: {
    refresh: { [key: string]: string };
    updateAccount: { [key: string]: string };
    logout: { [key: string]: string };
  };
};

interface StoreContainerState {
  version: any;
  last_version: string | null;
  last_version_number: string | null;
}

export class StoreHome extends React.Component<
  StoreContainerProps,
  StoreContainerState
> {
  state = {
    version: "default",
    last_version: null,
    last_version_number: null,
    visible: false,
  };

  public static defaultProps = {
    wording: require("../../Resources/wording.json"),
  };

  setVersion = async (version: string) => {
    let env = this.props.config.REACT_APP_ENV;
    let devConfig;
    try {
      devConfig = await api.framework.getDevConfig();
    } catch (error) {}
    let betaMode: Boolean = false;
    let vData = await this.GetVersion();
    if (devConfig && devConfig.DevMode === true) {
      betaMode = devConfig.EnableBetaVersion;
    }
    let versionType = "Release";
    if (betaMode) {
      versionType = "Beta";
    } else {
      versionType = "Release";
    }
    let versionDetails = vData.data.find(
      (a: any) => a.environment === env
    ).types;

    let ver = versionDetails.find((a: any) => a.name === versionType).versions;
    if (window.revit.isArchicad) {
      if (window.revit.isMac()) {
        ver = versionDetails.find(
          (a: any) => a.name === versionType
        ).archicadMacVersions;
      } else {
        ver = versionDetails.find(
          (a: any) => a.name === versionType
        ).archicadVersions;
      }
    }

    let vintage = version.split(".")[0];
    let last_version_number = ver.find(
      (a: any) => a.version === vintage
    ).minorVersion;
    let last_version = last_version_number.replace(/\./g, "_");

    // let last_version_request: {
    //   [key: string]: string;
    // } = await api.queries.getLastVersion(
    //   `${this.fileServerUrl}/releases/versions.json`
    // );
    // let last_version = last_version_request[vintage];

    // let last_version_number = `${vintage}.${last_version
    //   .split("_")
    //   .splice(1)
    //   .join(".")}`;

    // version = "20.0.33.90";
    // last_version = "20_0_33_91";
    // last_version_number = "20.0.33.91";

    this.setState({
      version,
      last_version,
      last_version_number,
    });
  };

  GetVersion = async () => {
    let data = Axios.create({
      baseURL: this.props.config.REACT_APP_ANONURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return await data.get("/versions");
  };

  async componentDidMount() {
    this.props.initLanguage();
    wait(600).then(async () => {
      const version = await api.framework.getVersion();
      if (version) {
        this.setVersion(version);
      } else {
        wait(600).then(async () => {
          const version = await api.framework.getVersion();
          if (version) {
            this.setVersion(version);
          } else {
            this.setState({
              version: "web",
            });
          }
        });
      }
    });
  }

  performAction = async (action: string) => {
    switch (action) {
      case "refresh":
        await getNewJwtToken(this.props.config);
        window.revit.dockReload();
        break;
      case "updateAccount":
        profileTrigger(this.props.config);
        break;
      case "logout":
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href =
          this.props.config.REACT_APP_AUTHENTICATIONURL +
          `` +
          this.props.config.REACT_APP_LOGOUT_REDIRECT;
        break;
      default:
        return null;
    }
  };

  onShow = () => {
    document.getElementById("flagId")?.click();
  };

  render() {
    const languageToCountry = (language: Language) => {
      switch (language) {
        case "English":
          return "GB";
        case "French":
          return "FR";
        case "German":
          return "DE";
        case "Italian":
          return "IT";
        case "Indonesian":
          return "ID";
        default:
          return "GB";
      }
    };

    const countryToLanguage = (country: string) => {
      switch (country) {
        case "GB":
          return "English";
        case "FR":
          return "French";
        case "DE":
          return "German";
        case "IT":
          return "Italian";
        case "ID":
          return "Indonesian";
        default:
          return "English";
      }
    };
    // alert(languageToCountry(this.props.language));
    return (
      <div style={{ padding: 10, width: "100%", height: "98vh" }}>
        <div
          style={{
            background: "#e8e8e8",
            display: "flex",
            alignItems: "center",
            margin: -10,
            padding: "0 15px",
            marginBottom: "0",
          }}
        >
          <div
            style={{
              marginRight: "auto",
              color: "#0D4D95",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: 0.8,
            }}
          >
            APPS
          </div>

          <div id="flagId">
            {this.props.languageInitialized && this.props.language && (
              <ReactFlagsSelect
                className="menu-flags border-0"
                onSelect={(countryCode) => {
                  this.props.setLanguage(countryToLanguage(countryCode));
                }}
                selectedSize={20}
                showSelectedLabel={false}
                countries={["GB", "FR", "DE", "IT", "ID"]}
                customLabels={{
                  GB: "EN",
                  FR: "FR",
                  DE: "DE",
                  IT: "IT",
                  ID: "ID",
                }}
                selected={languageToCountry(this.props.language)}
              />
            )}
          </div>
          <div style={{ marginLeft: 10 }}>
            <Dropdown
              icon="user circle big blue"
              pointing="top right"
              onClick={() => this.onShow()}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="refresh"
                  text={
                    this.props.wording.refresh[this.props.language] ??
                    "Refresh Menu"
                  }
                  onClick={() => this.performAction("refresh")}
                />
                <Dropdown.Item
                  icon="user"
                  text={
                    this.props.wording.updateAccount[this.props.language] ??
                    "Profile"
                  }
                  onClick={() => this.performAction("updateAccount")}
                />
                <Dropdown.Item
                  icon="power off"
                  text={
                    this.props.wording.logout[this.props.language] ?? "Logout"
                  }
                  onClick={() => this.performAction("logout")}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {this.state.version !== "default" && (
            <div style={{ fontSize: 9, marginLeft: 10 }}>
              v.{this.state.version}
            </div>
          )}
        </div>

        {sessionStorage.getItem("isActiveSeesion") === "true" &&
          this.state.version &&
          this.state.last_version_number &&
          this.state.version !== this.state.last_version_number && (
            <div
              style={{
                marginLeft: "-10px",
                marginRight: "-10px",
                backgroundColor: "rgba(217,217,217,255)",
                display: "flex",
                fontSize: "12px",
                padding: "5px 10px",
                justifyContent: "space-between",
              }}
            >
              <UpdatePanel language={this.props.language} />
            </div>
          )}
        <Grid
          columns="3"
          style={{ marginBottom: 5, display: "none", backgroundColor: "#eee" }}
        >
          <Grid.Row className="ml-1 mb-0">
            <Grid.Column textAlign="left" width="3">
              <Header
                color="blue"
                style={{ paddingTop: "1.5vh", paddingBottom: "1.5vh" }}
                content="Apps"
                as="h2"
              />
            </Grid.Column>
            <Grid.Column
              textAlign="left"
              width="5"
              style={{ display: "flex", alignItems: "center" }}
            >
              {this.props.languageInitialized && this.props.language && (
                <ReactFlagsSelect
                  onSelect={(countryCode) => {
                    this.props.setLanguage(countryToLanguage(countryCode));
                  }}
                  selectedSize={20}
                  showSelectedLabel={false}
                  countries={["GB", "FR", "DE", "IT", "ID"]}
                  customLabels={{
                    GB: "EN",
                    FR: "FR",
                    DE: "DE",
                    IT: "IT",
                    ID: "ID",
                  }}
                  selected={languageToCountry(this.props.language)}
                />
              )}
            </Grid.Column>
            <Grid.Column
              width="2"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Dropdown icon="user circle big blue" pointing="top left">
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="refresh"
                    text={
                      this.props.wording.refresh[this.props.language] ??
                      "Refresh Menu"
                    }
                    onClick={() => this.performAction("refresh")}
                  />
                  <Dropdown.Item
                    icon="edit"
                    text={
                      this.props.wording.updateAccount[this.props.language] ??
                      "Profile"
                    }
                    onClick={() => this.performAction("updateAccount")}
                  />
                  {/* <Dropdown.Item icon='power off' text={this.props.wording.logout[this.props.language]} onClick={() => this.performAction('logout')} /> */}
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column style={{ display: "flex", margin: "auto" }}>
              {this.state.version !== "default" && (
                <Label>v.{this.state.version}</Label>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <StoreBody
          language={this.props.language}
          version={this.state.version}
          last_version={this.state.last_version}
          last_version_number={this.state.last_version_number}
          selectPlugin={this.props.selectPlugin}
          plugins={this.props.plugins}
          setPlugins={this.props.setPlugins}
          environment=""
          config={this.props.config}
        />
      </div>
    );
  }
}

export default connector(StoreHome);
