import React, { useEffect, useState } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Controller } from "react-hook-form";
import { FormField } from "./FormField";
import "../Resources/FormPanel.css";
import { getProjectData } from "../Helper/index";
import { bimStorage, storageKey } from "../../../../BIMStore";

export function FormPanel(props: any) {
  const [preFillInfo, setPreFillInfo] = useState<any>(null);

  useEffect(() => {
    const getpreFillInfo = async () => {
      const preFillInfoRes = await bimStorage.getItem(storageKey.PROJECT_INFO);
      setPreFillInfo(preFillInfoRes);
    };
    getpreFillInfo();
  }, []);

  if (preFillInfo) {
    getProjectData().then((docName) => {
      if (preFillInfo && Object.keys(preFillInfo)[0] === props.routes) {
        let companyInfo = preFillInfo[props.routes].companyInfo;
        if (companyInfo) {
          Object.keys(companyInfo).forEach((company: any) => {
            //props.setValue(company, companyInfo[company]);
          });
        }
        let modelInfo = preFillInfo[props.routes].modelInfo;
        if (modelInfo) {
          modelInfo.forEach((model: any) => {
            if (model.model_name === docName) {
              if (Object.keys(model).length > 0) {
                for (let key in Object.keys(model)) {
                  if (key === "modal_name") continue;
                  // props.setValue(
                  //   Object.keys(model)[key],
                  //   Object.values(model)[key]
                  // );
                }
              }
            }
          });
        }
      } else if (preFillInfo && Object.keys(preFillInfo)[1] === props.routes) {
        let suppInfoArr = preFillInfo[props.routes];
        if (suppInfoArr) {
          suppInfoArr.forEach((suppInfo: any) => {
            if (suppInfo.model_name === docName)
              if (Object.keys(suppInfo).length > 0)
                for (let key in Object.keys(suppInfo)) {
                  props.setValue(
                    Object.keys(suppInfo)[key],
                    Object.values(suppInfo)[key]
                  );
                }
          });
        }
      }
    });
  }

  return (
    <div>
      <Header
        as="h5"
        attached="top"
        className="colData-header m-t-0"
        content={props.header}
      ></Header>
      <Segment attached className="colData">
        {props.fields.map((fieldd: any, index: any) => {
          fieldd.language = props.language;
          return (
            <div style={{ paddingBottom: "5px" }} key={index}>
              <Controller
                {...fieldd}
                name={fieldd.name}
                control={props.control}
                rules={{ required: fieldd.mandatory }}
                render={({ field }) => (
                  <FormField
                    {...field}
                    {...fieldd}
                    setValue={props.setValue}
                    errors={props.errors}
                    defaultValue={
                      (preFillInfo && preFillInfo[fieldd.name]) ||
                      fieldd.defaultData
                    }
                  />
                )}
              />
            </div>
          );
          // return (
          //   <div style={{ paddingBottom: "5px" }} key={index}>
          //     <Controller
          //       as={<FormField />}
          //       {...field}
          //       control={props.control}
          //       setValue={props.setValue}
          //       errors={props.errors}
          //       defaultValue={
          //         (preFillInfo && preFillInfo[field.name]) || field.defaultData
          //       }
          //       rules={{ required: field.mandatory }}
          //     />
          //   </div>
          // );
        })}
      </Segment>
      {/* <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <Button
          onClick={props.nextPageHandler}
          color="orange"
          size="medium"
          className={
            props.routes === "PROJECT_INFO_FORM" &&
            props.header === props.wording.formHeader2[props.language]
              ? ""
              : "divHide"
          }
          content={props.wording.infoprojectssubheader[props.language]}
        ></Button>
      </div> */}
    </div>
  );
}
