import React from "react";
import { SessionBox } from "../Components/CommonComponents/Session/SessionBox";
import DockWindow from "./DockWindow";

export const Dock = () => {
  return (
    <SessionBox parent="dock">
      <DockWindow />
    </SessionBox>
  );
};
