export const BRAND = "PAM Building"//"PAM Soil & Drain";

export const UK = "GBR";
export const FR = "FRA";

// RANGES ATTRIBUTES ////////////////////////////////////////////////////////
export const ASSETS_ATTR_TECHNICAL_NAME = "A-Category Assets";
export const ASSETS_LOCAL_URL_SUB_ATTR_TECHNICAL_NAME =
  "A-Category AssetLocalURL";
export const NAME_ATTR_TECHNICAL_NAME = "P-Range Name";
export const NEED_COUPLING_TECHNICAL_NAME = "P-Need Coupling";
// END RANGES ATTRIBUTES /////////////////////////////////////////////////////

// SGOBJECT ATTRIBUTES //////////////////////////////////////////////////////
// ELEMENTS (PIPES, ACCESSORIES & FITTINGS) ATTRIBUTES
export const SHORT_DESC_ATTR_TECHNICAL_NAME = "P-Range Short Description";
export const RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME = "P-Raw Product Name";
export const RAW_PRODUCT_PIM_ID_ATTR_TECHNICAL_NAME = "P-Raw Product PIM ID";

// COUPLING ATTRIBUTES
export const FAMILY_TYPE_ATTR_TECHNICAL_NAME = "P-Family Type";
export const MAIN_MATERIAL_TYPE_ATTR_TECHNICAL_NAME = "P-Main Material Type";
export const GASKET_MATERIAL_ATTR_TECHNICAL_NAME = "P-Gasket material";
export const RECOMMENDATION_LEVEL_ATTR_TECHNICAL_NAME =
  "P-Recommendation level for";

// END SGOBJECT ATTRIBUTES ///////////////////////////////////////////////////

export const DATA_INJECTION_OPTIONS: any = {
  FAMILY_TYPE: {
    attribute_name: "P-Family Type",
    param_group: "IDENTITY_DATA",
  },
  MODEL: {
    attribute_name: "P-Model Number",
    param_group: "IDENTITY_DATA",
  },
  DESCRIPTION: {
    attribute_name: "A-Product Name (multi-language)",
    param_group: "IDENTITY_DATA",
  },
  PRODUCT_CODE: {
    attribute_name: "A-Article Name",
    param_group: "IDENTITY_DATA",
  },
  QUALITY_REFERENCE_MARK: {
    attribute_name: "P-Quality Mark",
    param_group: "IDENTITY_DATA",
  },
  INDUSTRY_PRODUCT_STANDARDS: {
    attribute_name: "P-Industry Product Standards",
    param_group: "IDENTITY_DATA",
  },
  MANUFACTURER: {
    attribute_name: "P-Company Name",
    param_group: "IDENTITY_DATA",
  },
  MANUFACTURER_WEBSITE: {
    attribute_name: "P-Website URL",
    param_group: "IDENTITY_DATA",
  },
  PRODUCT_DATASHEET: {
    attribute_name: "A-AssetsLocalURL",
    param_group: "IDENTITY_DATA",
  },
  RANGE_NAME: {
    attribute_name: "P-Range Name",
    param_group: "IDENTITY_DATA",
  },
  ANGLE: {
    attribute_name: "P-Angle",
    param_group: "DIMENSION",
  },
  DN: {
    attribute_name: "P-DN",
    param_group: "DIMENSION",
  },
  NOMINAL_WEIGHT: {
    attribute_name: "P-Mass",
    param_group: "DIMENSION",
  },
  MATERIAL: {
    attribute_name: "P-Main Material Type",
    param_group: "OTHER",
  },
  COUPLING_GASKET_MATERIAL: {
    attribute_name: "P-Gasket material",
    param_group: "OTHER",
  },
  EXTERNAL_COLOR: {
    attribute_name: "P-BIM External Color",
    param_group: "OTHER",
  },
  INTERNAL_COATING_MATERIAL: {
    attribute_name: "P-BIM Internal Coating Material",
    param_group: "OTHER",
  },
  EXTERNAL_COATING_MATERIAL: {
    attribute_name: "P-BIM External Coating Material",
    param_group: "OTHER",
  },
  ADMISSIBLE_NEGATIVE_PRESSURE: {
    attribute_name: "P-Admissible Negative Pressure",
    param_group: "OTHER",
  },
  CHEMICAL_RESISTANCE: {
    attribute_name: "P-Chemical Resistance",
    param_group: "OTHER",
  },
  TEMPERATURE_RESISTANCE: {
    attribute_name: "P-Temperature Resistance",
    param_group: "OTHER",
  },
  DOP_URL: {
    attribute_name: "P-Website URL",
    param_group: "OTHER",
  },
  RANGE_FIRE_CLASSIFICATION: {
    attribute_name: "P-Range Fire Classification",
    param_group: "OTHER",
  },
  RECYCLING: {
    attribute_name: "P-Recycling Information",
    param_group: "OTHER",
  },
  FDES_URL: {
    attribute_name: "P-EPD-FDES url",
    param_group: "OTHER",
  },
  DN_SECONDARY: {
    attribute_name: "P-dn (Secondary)",
    param_group: "DIMENSION",
  },
  // "PIMID": {
  //     attribute_name: "A-Raw Product PIM ID",
  // param_group: "IDENTITY_DATA"
  // },
  // "Generic identification": {
  //     attribute_name: "A-Raw Product Name",
  // param_group: "IDENTITY_DATA"
  // },
  // "OmniClass Number": {
  //     attribute_name: "",
  // param_group: "IDENTITY_DATA"
  // },
  // "OmniClass Title": {
  //     attribute_name: "",
  // param_group: "IDENTITY_DATA"
  // },
  // "Maximum admissible pressure": {
  //     attribute_name: "",
  // param_group: "OTHER"
  // },
  // "Lenght expansion coefficient": {
  //     attribute_name: "",
  // param_group: "OTHER"
  // },
  // "Legal Notice ": {
  //     attribute_name: "",
  // param_group: "OTHER"
  // },
  // "User License Agreement": {
  //     attribute_name: "",
  // param_group: "OTHER"
  // },
};
