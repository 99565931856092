import React from "react";
import { IWording } from "../../../../Types";

const wording: IWording = {
  saveSelectionList: {
    French: "Liste des sélection de systèmes sauvegardées",
    English: "Saved selections list",
  },
  selectionName: {
    French: "Nom de la sélection",
    English: "Selection name",
  },
  levels: {
    French: "Niveaux",
    English: "Levels",
  },
  type: {
    French: "Type",
    English: "Type",
  },
  editDate: {
    French: "Date de modification",
    English: "Edit date",
  },
};

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0em",
  },
  height_435: {
    height: "435px",
  },
  height_486: {
    height: "calc(100vh - 50px)",
  },
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_row: {
    padding: 5,
    height: 30,
    backgroundColor: "rgb(33, 133, 208)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    fontWeight: 700,
    display: "flex",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    color: "black",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    fontWeight: 700,
    color: "black",
  },
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200",
    height: "calc(100vh - 195px)",
    overflowY: "auto" as const,
  },
};

export const HomeTableHeader = () => {
  const applicationLanguage = "English";
  return (
    <div style={style.tableSegment_Header}>
      <div style={style.header_row}>
        <div>{wording.saveSelectionList[applicationLanguage]}</div>
      </div>
      <div style={style.header_secondary_row}>
        <div className="w-5">&nbsp;</div>
        <div style={style.header_column_1} className="w-30 text-center">
          {wording.selectionName[applicationLanguage]}
        </div>
        <div style={style.header_column_2} className="w-15 text-center">
          {wording.levels[applicationLanguage]}
        </div>
        <div style={style.header_column_3} className="w-20 text-center">
          {wording.type[applicationLanguage]}
        </div>
        <div style={style.header_column_4} className="w-15 text-center">
          {wording.editDate[applicationLanguage]}
        </div>
        <div className="w-15">&nbsp;</div>
      </div>
    </div>
  );
};
