import React from "react";
import { pluginId } from "..";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { HelpRoot } from "./Components/root";

export const functionalityId = "3b11488e-6db7-410d-afec-2719fca997cf";

export const helpTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    500,
    //`https://www.placo.fr/Services/Le-batiment-numerique-avec-Placo-R/PrescriBIM-la-palette-BIM-de-Placo-R-se-renforce`
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <HelpRoot />
    </ParentRoot>
  );
};

export const RootHelpComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <HelpRoot />
    </ParentRoot>
  );
};

export const Help: Functionality = {
  Name: {
    English: "About",
    Indonesian: "Perihal",
  },
  Trigger: helpTrigger,
  ShortDesc: {
    English: "About this plugin",
    Indonesian: "Perihal Plugin",
  },
  Id: functionalityId,
  Icon: "/weberIcons/HelpBW.png",
  RootComponent,
  ParentId: "0",
};
