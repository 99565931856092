import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../../Selection/Reducers";
import { BuiltInCategory } from "../../../../../RevitJS/Types/RevitTypes";
import ProductsPageBody from "./ProductsPageBody";
import { selectorTp } from "../../../Selection/Actions/types";

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { setRoute: any }
) => {
  return {
    selectorType: state.selectorType,
    setRoute: ownProps.setRoute,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  selectorType: BuiltInCategory | selectorTp;
  setRoute: any;
};

interface State {}

export class ProductsPage extends Component<Props, State> {
  state = {};

  componentDidMount = () => {};

  render() {
    return <ProductsPageBody setRoute={this.props.setRoute} />;
  }
}

export default connector(ProductsPage);
