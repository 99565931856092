import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../reducers";
import {
  loadSystemsRequest,
  loadSystemsSuccess,
  loadSystemsError,
  loadSystemsDetailsRequest,
  loadSystemsDetailsSuccess,
  loadSystemsDetailsError,
  loadSystemsDetailsMore,
  filterSystemsRequest,
  displayFavoriteRequest,
  restoreSystemDetails,
  displaySystem,
  loadExistingSystems,
  AddSearchKeyWordAction,
  DeleteSearchKeyWordAction,
} from "./actions";
import { SystemsActionTypes } from "./types";
import * as productsService from "../../services/project.service";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import {
  applyRulesToData,
  addFilterFields,
  filterElements,
  getConditionValue1,
} from "../../utils/utils";
import {
  CLOISONS_ID,
  DOUBLAGES_ID,
  GAINES_ID,
  PLAFONDS_ID,
  systemTypes,
} from "../../assets/constants";
import { CustomParameter, ID } from "../../../../../RevitJS/Types/RevitTypes";
import _ from "lodash";
import { api } from "../../../../../RevitJS/API";
import { flattenDataRules } from "../../../Common/utils/mapping-config";
import { asyncForEach } from "../../../../PlacoBIMv3/Selection/Actions";

type Effect = ThunkAction<any, DrawStore, any, SystemsActionTypes>;

export const loadSystems =
  (config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsRequest());
    await productsService
      .loadProjects(config)
      .then((results) => {
        dispatch(loadSystemsSuccess(results));
      })
      .catch(() => dispatch(loadSystemsError()));
  };

export const loadSystemsDetails =
  (
    typeID: number,
    config: any,
    categoryTypeOptions: any,
    advanceSelectorData: any,
    activeVersionData: any
  ): Effect =>
  async (dispatch: any, getState: any) => {

    //let type = systemTypes.find((systemType) => systemType.value === typeID);
    let type = categoryTypeOptions.find(
      (systemType: any) => systemType.value === typeID
    );

    if (type) {
      dispatch(loadSystemsDetailsRequest());
      let { systems, data } = getState().systems;
      if (type?.value === CLOISONS_ID && data.partitionsDetails.length > 0) {
        dispatch(
          restoreSystemDetails(
            data.partitionsDetails,
            data.partitionsMetaData,
            type.value
          )
        );
      } else if (
        type?.value === PLAFONDS_ID &&
        data.ceilingsDetails.length > 0
      ) {
        dispatch(
          restoreSystemDetails(
            data.ceilingsDetails,
            data.ceilingsMetaData,
            type.value
          )
        );
      } else if (
        type?.value === DOUBLAGES_ID &&
        data.liningWallsDetails.length > 0
      ) {
        dispatch(
          restoreSystemDetails(
            data.liningWallsDetails,
            data.liningWallsMetaData,
            type.value
          )
        );
      }else if(type?.value === GAINES_ID && data.gainestechniqueswallDetails){
        dispatch(
          restoreSystemDetails(
            data.gainestechniqueswallDetails,
            data.gainestechniquesMetaData,
            type.value
          ));
      }
       else {
        const systemIds = systems.map((product: { oid: ID }) => product.oid);
        if (systemIds && systemIds.length > 0) {

          let rules = await getDataRules(activeVersionData);
          let searchbarRules = flattenDataRules(
            advanceSelectorData?.searchbar?.dataRules
          )?.filter((r: any) => r.attribute && r.attribute !== "");
          let productNameRules = flattenDataRules(
            advanceSelectorData?.productname?.dataRules
          )?.filter((r: any) => r.attribute && r.attribute !== "");
          let imgRules = flattenDataRules(
            advanceSelectorData?.image?.dataRules
          )?.filter((r: any) => r.attribute && r.attribute !== "");

          //maincategory
          let mainCategoryRules: any = [];
          advanceSelectorData?.mainCategories?.forEach((cat: any) => {
            let mainCatRule = [];
            mainCatRule.push({
              attribute: cat?.attribute?.attributeName,
              caseValue: "OR",
              relation: cat?.relation,
              value: cat?.value,
            });

            let catRules = flattenDataRules([mainCatRule])?.filter(
              (r: any) => r.attribute && r.attribute !== ""
            );
            if (catRules && catRules.length > 0) {
              mainCategoryRules[cat.type] = catRules;
            }
          });

          //SelectorFilters
          let selectorFilterRule: any = [];
          advanceSelectorData?.selectorFilters?.forEach((filterData: any) => {
            for (let i = 0; i < filterData.category.length; i++) {
              if (!selectorFilterRule[filterData.category[i]]) {
                selectorFilterRule[filterData.category[i]] = [];
              }
              let selectorRules = flattenDataRules(
                filterData.attribute.dataRules
              )?.filter((r: any) => r.attribute && r.attribute !== "");
              if (selectorRules && selectorRules.length > 0) {
                selectorFilterRule[filterData.category[i]].push({selectorRules: selectorRules, attribute: filterData.attribute.attributeName});
              }
            }
          });

          productsService
            .getSystemDetailsById(systemIds, config)
            .then((results) => {
              let currentSystemDetail = results.data.objects.map((sys) => {
                return { ...sys, filterFields: {} } as unknown;
              }) as ProductDetailData[];
              currentSystemDetail = currentSystemDetail.map((sys) => ({
                ...sys,
                thumbnailUrl: systems.find(
                  (system: any) => system.oid === sys.oid
                )?.thumbnailUrl,
              })) as ProductDetailData[];

              //Apply data rules
              rules = rules.filter(
                (r: any) => r.attribute && r.attribute !== ""
              );

              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                rules
              );
              // if (rules && rules.length > 0) {
              //   currentSystemDetail = currentSystemDetail.filter(element => {
              //     return applyRulesToData(element.attributes, rules);
              //   });
              // }

              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                searchbarRules
              );
              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                productNameRules
              );
              currentSystemDetail = applyRulesOnData(
                currentSystemDetail,
                imgRules
              );

              // advanceSelectorData?.selectorFilters?.forEach((filterData: any) => {
              //   if (selectorFilterRule[filterData.label] && selectorFilterRule[filterData.label].length > 0) {
              //     currentSystemDetail = applyRulesOnData(currentSystemDetail, selectorFilterRule[filterData.label]);
              //   }
              // });

              //Cloisons
              let currentSystemDetailCloisons: ProductDetailData[] =
                applyRulesOnData(
                  currentSystemDetail,
                  mainCategoryRules[CLOISONS_ID]
                );

              selectorFilterRule[CLOISONS_ID]?.forEach((element: any) => {
                currentSystemDetailCloisons = applyRulesOnData(
                  currentSystemDetailCloisons,
                  element.selectorRules,
                  true,
                  element.attribute
                );
              });
              
              const currentSystemDetailCloisonsMetaData = systems.filter(
                (system: any) =>
                  currentSystemDetailCloisons
                    .map((product: { oid: ID }) => product.oid)
                    .includes(system.oid)
              );


              //gaines
              let currentSystemDetailGaines: ProductDetailData[] =
                applyRulesOnData(
                  currentSystemDetail,
                  mainCategoryRules[GAINES_ID]
                );

              selectorFilterRule[GAINES_ID]?.forEach((element: any) => {
                currentSystemDetailGaines = applyRulesOnData(
                  currentSystemDetailGaines,
                  element.selectorRules,
                  true,
                  element.attribute
                );
              });

              const currentSystemDetailGainesMetaData = systems.filter(
                (system: any) =>
                  currentSystemDetailGaines
                    .map((product: { oid: ID }) => product.oid)
                    .includes(system.oid)
              );

              // const currentSystemDetailCloisons = currentSystemDetail.filter(
              //   (productDetail) => {
              //     const gfrWorksNameObject = _.find(productDetail.attributes, {
              //       technicalName: "GFR-Works name",
              //     });
              //     if (gfrWorksNameObject) {
              //       const valueObject: any = _.get(gfrWorksNameObject, "values");
              //       return valueObject[0].value === "Cloisons";
              //     }
              //   }
              // );

              // const currentSystemDetailCloisonsMetaData = systems.filter(
              //   (system: any) =>
              //     currentSystemDetailCloisons
              //       .map((product: { oid: ID }) => product.oid)
              //       .includes(system.oid)
              // );

              
              //Plafonds
              let currentSystemDetailPlafonds: ProductDetailData[] =
                applyRulesOnData(
                  currentSystemDetail,
                  mainCategoryRules[PLAFONDS_ID]
                );

              selectorFilterRule[PLAFONDS_ID]?.forEach((element: any) => {
                currentSystemDetailPlafonds = applyRulesOnData(
                  currentSystemDetailPlafonds,
                  element.selectorRules,
                  true,
                  element.attribute
                );
              });

              const currentSystemDetailPlafondsMetaData = systems.filter(
                (system: any) =>
                  currentSystemDetailPlafonds
                    .map((product: { oid: ID }) => product.oid)
                    .includes(system.oid)
              );

              // const currentSystemDetailPlafonds = currentSystemDetail.filter(
              //   (productDetail) => {
              //     const gfrWorksNameObject = _.find(productDetail.attributes, {
              //       technicalName: "GFR-Works name",
              //     });
              //     if (gfrWorksNameObject) {
              //       const valueObject: any = _.get(gfrWorksNameObject, "values");
              //       return (
              //         valueObject[0].value === "Plafonds décoratifs" ||
              //         valueObject[0].value === "Plafonds"
              //       );
              //     }
              //   }
              // );
              // const currentSystemDetailPlafondsMetaData = systems.filter(
              //   (system: any) =>
              //     currentSystemDetailPlafonds
              //       .map((product: { oid: ID }) => product.oid)
              //       .includes(system.oid)
              // );

              //Doublages
              let currentSystemDetailLiningWalls: ProductDetailData[] =
                applyRulesOnData(
                  currentSystemDetail,
                  mainCategoryRules[DOUBLAGES_ID]
                );

              selectorFilterRule[DOUBLAGES_ID]?.forEach((element: any) => {
                currentSystemDetailLiningWalls = applyRulesOnData(
                  currentSystemDetailLiningWalls,
                  element.selectorRules,
                  true,
                  element.attribute
                );
              });

              const currentSystemDetailLiningWallsMetaData = systems.filter(
                (system: any) =>
                  currentSystemDetailLiningWalls
                    .map((product: { oid: ID }) => product.oid)
                    .includes(system.oid)
              );
              // const currentSystemDetailLiningWalls = currentSystemDetail.filter(
              //   (productDetail) => {
              //     const gfrWorksNameObject = _.find(productDetail.attributes, {
              //       technicalName: "GFR-Works name",
              //     });
              //     if (gfrWorksNameObject) {
              //       const valueObject: any = _.get(gfrWorksNameObject, "values");
              //       return valueObject[0].value === "Isolation des murs";
              //     }
              //   }
              // );
              // const currentSystemDetailLiningWallsMetaData = systems.filter(
              //   (system: any) =>
              //     currentSystemDetailLiningWalls
              //       .map((product: { oid: ID }) => product.oid)
              //       .includes(system.oid)
              // );
              if (type) {
                dispatch(
                  loadSystemsDetailsSuccess(
                    currentSystemDetailCloisons,
                    currentSystemDetailPlafonds,
                    currentSystemDetailLiningWalls,
                    currentSystemDetailGaines,
                    currentSystemDetailCloisonsMetaData,
                    currentSystemDetailPlafondsMetaData,
                    currentSystemDetailLiningWallsMetaData,
                    currentSystemDetailGainesMetaData,
                    type.value
                  )
                );
              }
            })
            .catch(() => dispatch(loadSystemsDetailsError()));
        }
        const existingSystems: CustomParameter[] = [];
        //  (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[]
        dispatch(loadExistingSystems(existingSystems));
      }
    }
  };

const applyRulesOnData = (
  data: any,
  rules: any,
  filterValue: boolean = false,
  assignedAttribute: any = null
) => {
  if (rules && rules.length > 0) {
    data = data.filter((element: any) => {
      if (filterValue) {
        let result: boolean = false;
        let count: number = 0;
        let condition: string = "";
        if(assignedAttribute !== null){
          let assignedAttributeIndex = element.attributes.findIndex((attr: any)=> attr.technicalName === assignedAttribute);
          if(assignedAttributeIndex > -1){
            element.attributes[assignedAttributeIndex].values = 
            element.attributes[assignedAttributeIndex].values.filter(async (val: any) => {
              await asyncForEach(rules, async (rule: any) => {
                condition = count === 0 ? rule.condition : rules[count - 1].condition;
                let index = element.attributes.findIndex((attr: any) => attr.technicalName === rule.attribute);
                if (index > -1) {
                    result = getConditionValue1(
                      rule,
                      val.value,
                      condition,
                      count,
                      result
                    );
                }
              });
    
              return result;
    
            });
          }
          
        }else {
          rules.forEach((rule: any) => {
            condition = count === 0 ? rule.condition : rules[count - 1].condition;
            let index = element.attributes.findIndex(
              (attr: any) => attr.technicalName === rule.attribute
            );
            if (index > -1) {
              element.attributes[index].values = element.attributes[
                index
              ].values.filter((val: any) => {
                result = getConditionValue1(
                  rule,
                  val.value,
                  condition,
                  count,
                  result
                );
                return result;
              });
            }
          });
  
        }
        
        
        
      }
      return applyRulesToData(element.attributes, rules);
    });
  }
  return data;
};

export const getDataRules = async (activeVersionData: any) => {
  if (activeVersionData) {
    // const prescriptionList = await getAllPrescriptions(config);
    // const prescriBIMplugin = prescriptionList.find((p: any) => p.name === 'PrescriBIM');
    // let plugins = await getAllPlugins(config);
    // const application = plugins.find((p: any) => p.applicationName === 'PrescriBIM');
    // let data = await getPrescriptionActiveVersion(application.applicationId, config);

    let dataRules = activeVersionData.dataRule; //await getPrescriptionDataRules(prescriBIMplugin.prescriptionPluginId, config);

    let solutionLevels = flattenDataRules(dataRules.solutionLevels);
    let productLevels = flattenDataRules(dataRules.productLevels);

    let flattenRules = solutionLevels.concat(productLevels);
    return flattenRules;
  }
};

// export const flattenDataRules = (dataRules: any) => {
//   let rules: any = [];
//   let conditionCount = 1;
//   dataRules.forEach((dataRule: any) => {
//     conditionCount = 1;
//     dataRule.forEach((dr: any) => {
//       rules.push({
//         attribute: dr.attribute,
//         caseValue: dr.caseValue,
//         relation: dr.relation,
//         value: dr.value,
//         condition: conditionCount == dataRule.length ? "OR" : "AND"
//       });
//       conditionCount++;
//     });

//   });

//   return rules;
// }

export const loadMoreSystemsDetails =
  (): Effect => async (dispatch: any, getState: any) => {
    if (
      getState().systems.systemsDetails.length <
      getState().systems.currentSystemDetail.length
    ) {
      dispatch(
        loadSystemsDetailsMore(
          getState().systems.currentSystemDetail.slice(
            getState().systems.systemsDetails.length,
            getState().systems.systemsDetails.length + 20
          )
        )
      );
    }
  };

export const filterSystemsDetails =
  (advanceSelector: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsDetailsRequest());
    let {
      currentSystemDetail,
      data,
      systemTypeID,
      searchKeyWords,
      favoriteDisplayed,
    } = getState().systems;
    let { currentFavoriteSystemItems } = getState().favorite;
    let dataToFilter: ProductDetailData[] = [];
    if (favoriteDisplayed) dataToFilter = currentFavoriteSystemItems;
    else if (systemTypeID === CLOISONS_ID)
      dataToFilter = data.partitionsDetails;
    else if (systemTypeID === PLAFONDS_ID) dataToFilter = data.ceilingsDetails;
    else if (systemTypeID === DOUBLAGES_ID)
      dataToFilter = data.liningWallsDetails;
    else if (systemTypeID === GAINES_ID)
      dataToFilter = data.gainestechniqueswallDetails;

    let { filters } = getState().filters;
    currentSystemDetail = addFilterFields(dataToFilter, filters);
    currentSystemDetail = filterElements(currentSystemDetail, filters);
    currentSystemDetail = currentSystemDetail.filter(
      (el: ProductDetailData) => {
        let productAttribute = el.attributes.find(
          (p: any) =>
            p.technicalName === advanceSelector?.searchbar?.attributeName
        );
        let productName = productAttribute?.values[0]?.value
          ? productAttribute.values[0]?.value
          : el.translation;

        return searchKeyWords.every((keyWord: any) =>
          //el.translation
          productName
            .toUpperCase()
            .replace("®", "")
            .replace(/\s/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              keyWord
                ?.toUpperCase()
                .replace("®", "")
                .replace(/\s/g, "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") || ""
            )
        );
      }
    );
    dispatch(filterSystemsRequest(currentSystemDetail));
  };

export const displayFavorite =
  (): Effect => async (dispatch: any, getState: any) => {
    const { currentFavoriteSystemItems } = getState().favorite;
    dispatch(displayFavoriteRequest(currentFavoriteSystemItems));
  };

export const displaySystems =
  (): Effect => async (dispatch: any, getState: any) => {
    let { data, systemTypeID } = getState().systems;
    let systems: ProductDetailData[] = [];
    if (systemTypeID === CLOISONS_ID) systems = data.partitionsDetails;
    else if (systemTypeID === PLAFONDS_ID) systems = data.ceilingsDetails;
    else if (systemTypeID === DOUBLAGES_ID) systems = data.liningWallsDetails;
    else if (systemTypeID === GAINES_ID) systems = data.gainestechniqueswallDetails;
    dispatch(displaySystem(systems));
  };

export const addSearchKeyWord =
  (word: string): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(AddSearchKeyWordAction(word));
    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: word,
          values: [],
        },
      ],
      eventAction: "Get",
      eventCategory: "User Query",
      eventLabel:
        localStorage.getItem("moduleName") !== "PLACOBIM"
          ? "Keyword search"
          : "Library_Keyword search",
      module: localStorage.getItem("moduleName") as string, //"PRESCRIBIM",
    });
  };

export const deleteSearchKeyWord =
  (searchWordIndex: number): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(DeleteSearchKeyWordAction(searchWordIndex));
  };
