import React from "react";
import { api } from "../../../RevitJS/API";
import { CLT, pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { DeleteRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "43142c42-9fbb-458b-b7db-f3bafab505a5";
export const deleteTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

// export const pluginsData = [
//   { technicalName: CLT.TechnicalName, plugin: CLT },
// ];

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DeleteRoot />
    </ParentRoot>
  );
};

export const CltDeleteRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DeleteRoot />
    </ParentRoot>
  );
};

export const Delete: Functionality = {
  Name: {
    French: "Suppression",
    English: "Delete",
    German: "Delete",
    Italian: "Delete",
  },
  Trigger: deleteTrigger,
  ShortDesc: {
    French: "Effacer le calepinage",
    English: "Erase Layout",
    German: "Erase Layout",
    Italian: "Erase Layout",
  },
  Id: functionalityId,
  Icon: "/pluginIcons/Icon-Delete.jpg",
  RootComponent,
  ParentId: "0",
};
