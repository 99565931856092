import { ProductDetailData } from "../../../../RevitJS/Types/BddTypes"
import { initProductSelectorAction, resetFiltersAction, applyFiltersAction, searchAction } from "../Actions/types";

type filteredElementsAction = initProductSelectorAction | resetFiltersAction | applyFiltersAction | searchAction;

export const filteredElements = (filteredElements: ProductDetailData[] = [], action: filteredElementsAction): ProductDetailData[] =>{
    switch(action.type){
        case "INIT_SELECTOR":
            return action.filteredElements;
        case "APPLY_FILTERS":
            return action.filteredElements;
        case "RESET_FILTERS":
            return action.filteredElements;
        case "SEARCH":
            return action.filteredElements;
        default:
            return filteredElements;
    }
}