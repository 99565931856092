import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import "./SessionBox.scss";
import { GetTokenExpiryInSecs } from "./Authentication";
import { api } from "../../../RevitJS/API";
import { ConfirmModal } from "../../../Plugins/PlacoBIMv3/CommonModules/PopUpModals/ConfirmModal";
import { MyConfig } from "../../../Helper";

interface Props {
  parent: "plugin" | "dock";
  children: any;
}
type Langs = "French" | "English";

export function SessionBox(props: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [time, setTime] = useState<string>("NA");
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [clickedWarning, setClickedWarning] = useState<boolean>(false);
  const [lang, setLang] = useState<Langs>("English");

  const calculateTime = () => {
    let countdown: any;
    // const token = localStorage.getItem("token") as string;
    // let authData: any = jwt_decode(token);
    // const { exp } = authData;

    countdown = setInterval(updateDock, 1000);
    function updateDock() {
      let timeRemaining: any = GetTokenExpiryInSecs();
      if (timeRemaining.isExpire === true) {
        setDone(true);
      }
      if (timeRemaining.firstWarning === true && props.parent === "plugin") {
        let time = timeRemaining.tenMinWarning.toString();
        localStorage.setItem("time", time);
        setOpenWarning(true);
      }
      if (timeRemaining.secondWarning === true && props.parent === "plugin") {
        setClickedWarning(true);
        localStorage.removeItem("time");
      }
      return timeRemaining.isExpire === true
        ? clearInterval(countdown)
        : countdown;
    }
  };

  const onOKClicked = () => {
    setOpenWarning(false);
  };

  useEffect(() => {
    let language: any = localStorage.getItem("savedLanguage");
    const changeLanguage = async () => {
      if (language !== lang) {
        setLang(language);
      }
    };
    changeLanguage();
    calculateTime();
  }, [props]);
  //LS get

  const okTry = () => {
    if (
      localStorage.getItem("layoutStep2") !== "true" &&
      localStorage.getItem("dossierStep4") !== "true" &&
      localStorage.getItem("reperageDefault") !== "true" &&
      localStorage.getItem("selectionStep") !== "true"
    ) {
      api.windowsHandler.closeWindow();
      localStorage.removeItem("min");
    } else {
      setClickedWarning(false);
      setOpenWarning(false);
    }
  };

  const redirect = async () => {
    let config = await MyConfig();
    window.location.href =
      config.REACT_APP_AUTHENTICATIONURL + `` + config.REACT_APP_LOGIN_REDIRECT;
  };

  if (done === true && props.parent === "dock") {
    redirect();
  }
  return (
    <React.Fragment>
      {done === true && props.parent === "plugin" ? (
        <Modal
          size="mini"
          centered={false}
          open={clickedWarning}
          onClose={() => setClickedWarning(false)}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Content>{wording.expiredWarning[lang]}</Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => okTry()}>
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
      {/* to be tested */}
      {/* {done === true && props.parent === "dock" ? redirect() : null} */}
      <Modal
        size="mini"
        centered={false}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Warning</Modal.Header>
        <Modal.Content>
          <Modal.Description
            style={{ padding: "0 !important" }}
          ></Modal.Description>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        centered={false}
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Warning</Modal.Header>
        <Modal.Content>
          {wording.Warning[lang] + localStorage.getItem("time") + " minutes"}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => onOKClicked()}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
      {/* {time !== "NA" ? (
        <span
          className="timeLabel"
          style={{
            color: parseInt(time.split(":")[0]) < 600 ? "red" : "black",
          }}
          // onClick={() => setOpen(true)}
        >
          {time}
        </span>
      ) : null} */}
      {props.children}
    </React.Fragment>
  );
}
const wording: WordingType = {
  Warning: {
    French: "Votre session va a expirer dans ",
    English: "Your session will expired in ",
    German: "Your session will expired in ",
    Italian: "Your session will expired in ",
  },
  expiredWarning: {
    French: "Votre session a expiré. Veuillez vous reconnecter. ",
    English: "Your session expired. Please login again",
    German: "Your session expired. Please login again",
    Italian: "Your session expired. Please login again",
  },
};

type WordingType = {
  Warning: LanguageType;
  expiredWarning: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
  German: string;
  Italian: string;
};

// function jwt_decode(token: string): any {
//   throw new Error("Function not implemented.");
// }
