import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { DrawRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "8afc96d9-789d-4192-b9ed-8a361c6635c9";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Calepinage",
    module: "CLT",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DrawRoot />
    </ParentRoot>
  );
};

export const CLTRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DrawRoot />
    </ParentRoot>
  );
};

export const Draw2: Functionality = {
  Name: {
    French: "Calepinage",
    English: "Layout",
    German: "Layout",
    Italian: "Layout",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Générateur de systèmes",
    English: "Ceiling system generation",
    German: "Layout solutions",
    Italian: "Layout solutions",
  },
  Id: functionalityId,
  Icon: "/pluginIcons/Icon-Layout.jpg",
  RootComponent,
  ParentId: "0",
};
