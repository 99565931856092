import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import {
  writeToCellAndStyle,
  addDetailsArrayHeader,
  addGlobalLevelHeader,
} from "../Helpers";
import Resizer from "react-image-file-resizer";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { isEmpty } from "lodash";
import { addProjectInfo } from "../Helpers";
import { MyConfig } from "../../../../Helper";

export const getExcel = async (config: any) => {
  return await fetch(
    config.REACT_APP_SERVERURL + "/Excel/gypQuickMetresv3.xlsx"
  ).then((response) => {
    return response.arrayBuffer();
  });
};

export async function getDocumentName() {
  return await api.queries.getActiveDocumentName();
}

export const scheduleHandler = async (
  filteredTree: any,
  setIsDownloadSuccessful: any
) => {
  const config = await MyConfig();
  const workbook = new Excel.Workbook();
  let blob = await getExcel(config);
  workbook.xlsx.load(blob).then(async (wkbk) => {
    await writeWorkbook(wkbk, filteredTree, config);
    wkbk.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: "visible", // Set activeTab to 0
      },
    ];
    const buf = await wkbk.xlsx.writeBuffer();

    const documentName = await getDocumentName();
    const d = new Date();
    let monthNumber = d.getMonth() + 1;
    let month =
      monthNumber.toString().length === 1 ? "0" + monthNumber : monthNumber;
    let dayNumber = d.getDate();
    let day = dayNumber.toString().length === 1 ? "0" + dayNumber : dayNumber;
    const fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Schedule`;
    setIsDownloadSuccessful(true);
    // window.addEventListener("blur", () => api.windowsHandler.closeWindow());
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  });
};

const writeWorkbook = async (
  workbook: Excel.Workbook,
  filteredTree: any,
  config: any
) => {
  await writeData(workbook, filteredTree, config);
};

export const addImagetoWorkbook = async (
  excelBook: Excel.Workbook,
  config: any
) => {
  let infoFormData: any = await bimStorage.getInfoForm();

  let logo = infoFormData.information_enterprise_logo;

  if (logo !== "") {
    if (!logo.includes("/html", 0)) {
      const resizedImg = await resizeFile(b64toBlob(logo));
      const imageId = excelBook.addImage({
        base64: resizedImg ? resizedImg : logo,
        extension: "png",
      });
      return imageId;
    }
  }
  return 0;
};

const b64toBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      110,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const writeData = async (
  workbook: Excel.Workbook,
  filteredTree: any,
  config: any
) => {
  //
  const wallsDataSheet = getWorksheet(workbook, "Wall_Schedules");

  // const logoImageId = await addImagetoWorkbook(workbook, config);
  // add logo to Walls sheet
  // logoImageId !== 0 &&
  //   wallsDataSheet.addImage(logoImageId, {
  //     tl: { col: 0, row: 0 },
  //     ext: { width: 150, height: 110 },
  //   });

  // add logo to ceilings sheet
  // logoImageId !== 0 &&
  //   ceilingsDataSheet.addImage(logoImageId, {
  //     tl: { col: 0, row: 0 },
  //     ext: { width: 150, height: 110 },
  //   });
  // let mainRows, summaryRow: any[];
  // if (filteredTree.Tree) {
  //   let { mainRows, summaryRow } = await extractData(filteredTree.Tree);
  // } else {
  //   let { mainRows, summaryRow } = await extractDataForGroup(filteredTree);
  // }
  let elementsObjec: any = null;
  if (filteredTree.Tree) {
    elementsObjec = await extractData(filteredTree.Tree);
  } else {
    elementsObjec = await extractDataForGroup(filteredTree);
  }
  if (elementsObjec) {
    let { wallsDataCloned, summaryRow, parLevelRows } = elementsObjec;
    // let tempProjectInfo: any = await bimStorage.getItem(storageKey.PROJECT_INFO);
    // const projectInfo = tempProjectInfo === null ? {} : tempProjectInfo;
    // let ProjectData: any = await api.queries.getProjectData();
    // const projectName = await getDocumentName();
    // const projData: any = await api.queries.getProjectInformation();

    let startingPointForFirstTable = 17;
    for (let i = 0; i < summaryRow.length; i++) {
      // wallsDataSheet.insertRow(startingPointForFirstTable + i, {}, "o+");

      // wallsDataSheet.mergeCells(
      //   `B${startingPointForFirstTable + i}:C${startingPointForFirstTable + i}`
      // );

      let tmp = wallsDataSheet.getCell(`B${startingPointForFirstTable + i}`);
      tmp.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      writeToCellAndStyle(
        wallsDataSheet,
        `B${startingPointForFirstTable + i}`,
        summaryRow[i][1],
        true,
        false
      );

      writeToCellAndStyle(
        wallsDataSheet,
        `C${startingPointForFirstTable + i}`,
        summaryRow[i][2],
        true,
        true
      );

      wallsDataSheet.getRow(startingPointForFirstTable + i).font = {
        bold: false,
      };
    }
    let GlobalParLevelStartingPoint =
      startingPointForFirstTable + summaryRow.length + 3;

    let startingPointForSecondTable =
      GlobalParLevelStartingPoint + parLevelRows.length + 3;

    for (let i = 0; i < wallsDataCloned.length; i++) {
      writeToCellAndStyle(
        wallsDataSheet,
        `A${startingPointForSecondTable + i}`,
        wallsDataCloned[i][0],
        true,
        false
      );

      // wallsDataSheet.mergeCells(
      //   `B${startingPointForSecondTable + i}`
      // );

      let tmp = wallsDataSheet.getCell(`B${startingPointForSecondTable + i}`);
      tmp.alignment = {
        ...tmp.alignment,
        horizontal: "center",
        vertical: "middle",
      };

      writeToCellAndStyle(
        wallsDataSheet,
        `B${startingPointForSecondTable + i}`,
        wallsDataCloned[i][3],
        true,
        false
      );
      writeToCellAndStyle(
        wallsDataSheet,
        `C${startingPointForSecondTable + i}`,
        wallsDataCloned[i][4],
        true,
        true
      );

      writeToCellAndStyle(
        wallsDataSheet,
        `D${startingPointForSecondTable + i}`,
        wallsDataCloned[i][5],
        true,
        false
      );
      writeToCellAndStyle(
        wallsDataSheet,
        `E${startingPointForSecondTable + i}`,
        wallsDataCloned[i][6],
        true,
        true
      );
      writeToCellAndStyle(
        wallsDataSheet,
        `F${startingPointForSecondTable + i}`,
        wallsDataCloned[i][7],
        true,
        true
      );
      // writeToCellAndStyle(
      //   wallsDataSheet,
      //   `G${startingPointForSecondTable + i}`,
      //   mainRows[i][5],
      //   true,
      //   true
      // );
      wallsDataSheet.getRow(startingPointForSecondTable + i).font = {
        bold: false,
      };
    }

    addDetailsArrayHeader(wallsDataSheet, startingPointForSecondTable - 2);

    //Write heading of GLOBAL Par Level Table
    addGlobalLevelHeader(wallsDataSheet, GlobalParLevelStartingPoint - 2);

    //Write data into Global Par Level Table
    writeGlobalParLevel(
      wallsDataSheet,
      parLevelRows,
      GlobalParLevelStartingPoint
    );

    await addProjectInfo(workbook, wallsDataSheet);
  }
};

export const getWorksheet = (workbook: Excel.Workbook, name: string) => {
  return workbook.getWorksheet(name);
};

export const extractData = async (tree: any) => {
  let nbLevels = tree.length;
  let detailRows: any = [];
  let levelRows: any = {};
  let wallSolArr: any = [];
  let wallsDataCloned: any = [];
  let mainArr: any = [];

  for (let i = 0; i < nbLevels; i++) {
    let elems = tree[i].Elements;
    let nb_types = elems.length;
    let level = tree[i].Level.Name;

    for (let j = 0; j < nb_types; j++) {
      let ids = elems[j].Ids;
      let type = elems[j].Type;
      if (j === 0) levelRows[level] = {};
      if (ids.length > 0) {
        let wallsData = await api.queries.getWallsData(ids);

        const solutionData = await api.queries.getObjectsParams(ids, [
          "SG_System",
        ]);
        let wallSolutions = getWallsWithSolutionsData(wallsData, solutionData);
        let rows = await detailMainBuilder(
          wallSolutions,
          type,
          tree[i].Level.Name
        );
        detailRows = detailRows.concat(rows);

        let arr = wallSolutions.filter((a: any) => a.solution !== " ");
        if (arr.length > 0) {
          wallSolArr = wallSolArr.concat(arr);
        }
      }
    }
  }
  //for sorting data level wise, arranged in [key:value] pair

  detailRows.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      solution: wall[2],
      type: wall[3],
      area: wall[4],
      id: wall[5],
      height: wall[6],
      length: wall[7],
    });
  });

  // //data sorted
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  // //removing [key:value] pair and pushed it to another array
  mainArr.forEach((wall: any) => {
    wallsDataCloned.push([
      wall.level,
      wall.zone,
      wall.solution,
      wall.type,
      wall.area,
      wall.id,
      wall.height,
      wall.length,
    ]);
  });

  let newRows: any = await detailMainBodyBuilder(detailRows);
  let parLevelRows: any = await detailNewSummaryBuilder(newRows);
  let mainRows: any = detailRows;
  let summaryRow: any = await detailSummaryBuilder(mainRows);
  return {
    //mainRows,
    wallsDataCloned,
    levelRows,
    summaryRow,
    parLevelRows,
  };
};

export const extractDataForGroup = async (tree: any) => {
  let nbLevels = tree.length;
  let detailRows: any = [];
  let levelRows: any = {};
  let wallSolArr: any = [];
  let wallsDataCloned: any = [];
  let mainArr: any = [];

  for (let i = 0; i < nbLevels; i++) {
    let elems = tree[i].elems;
    let nb_types = elems.length;
    let level = tree[i].level;

    for (let j = 0; j < nb_types; j++) {
      let ids = elems[j].ids;
      let type = elems[j].type;
      if (j === 0) levelRows[level] = {};
      if (ids.length > 0) {
        let wallsData = await api.queries.getWallsData(ids);

        const solutionData = await api.queries.getObjectsParams(ids, [
          "SG_System",
        ]);
        let wallSolutions = getWallsWithSolutionsData(wallsData, solutionData);

        let rows = await detailMainBuilder(wallSolutions, type, tree[i].level);
        detailRows = detailRows.concat(rows);

        let arr = wallSolutions.filter((a: any) => a.solution !== " ");
        if (arr.length > 0) {
          wallSolArr = wallSolArr.concat(arr);
        }
      }
    }
  }
  //for sorting data level wise, arranged in [key:value] pair

  detailRows.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      solution: wall[2],
      type: wall[3],
      area: wall[4],
      id: wall[5],
      height: wall[6],
      length: wall[7],
    });
  });

  // //data sorted
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  // //removing [key:value] pair and pushed it to another array
  mainArr.forEach((wall: any) => {
    wallsDataCloned.push([
      wall.level,
      wall.zone,
      wall.solution,
      wall.type,
      wall.area,
      wall.id,
      wall.height,
      wall.length,
    ]);
  });

  let newRows: any = await detailMainBodyBuilder(detailRows);
  let parLevelRows: any = await detailNewSummaryBuilder(newRows);
  let mainRows: any = detailRows;
  let summaryRow: any = await detailSummaryBuilder(mainRows);
  return {
    //mainRows,
    wallsDataCloned,
    levelRows,
    summaryRow,
    parLevelRows,
  };
};

export const detailMainBodyBuilder = async (wallsData: any) => {
  const distinct = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
  let mainArr: any = [];
  wallsData.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      solution: wall[2],
      type: wall[3],
      area: wall[4],
      id: wall[5],
      height: wall[6],
      length: wall[7],
    });
  });
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  let levelArr: any = [];
  levelArr = mainArr.map((a: any) => a.level).filter(distinct);
  let wallType: string = "";
  let wallArea: number = 0;
  let summary: any = [];
  levelArr.forEach((level: any) => {
    wallArea = 0;
    wallType = "";
    mainArr
      .filter((a: any) => a.level === level)
      .forEach((wall: any) => {
        wallArea = 0;
        if (wallType !== wall.type) {
          let arr = mainArr.filter(
            (a: any) => a.type === wall.type && a.level === level
          );
          arr.forEach((item: any) => {
            wallArea += (item.area * 10) / 10;
          });
          wallType = wall.type;
          summary.push({
            level: level,
            walltype: wallType,
            area: wallArea,
          });
        }
      });
  });
  let rows: any = [];
  let level: string = "";
  summary = orderBy(summary, ["level", "area"], ["asc", "desc"]);
  mainArr.forEach((data: any) => {
    if (data.level !== level) {
      summary
        .filter((a: any) => a.level === data.level)
        .forEach((row: any) => {
          rows.push([row.level, row.walltype, row.area, "NA", "NA", "NA"]);
        });
      level = data.level;
    }
    rows.push([
      data.level,
      data.type,
      data.area,
      data.id,
      data.height,
      data.length,
    ]);
  });
  return rows;
};

export const detailSummaryBuilder = async (wallsData: any) => {
  let rowsArr: any = [];
  let rows: any = [];
  let strList: string[] = [];
  let str: string = "";
  wallsData.forEach((wall: any) => {
    if (wall[3] !== str) {
      if (!strList.includes(wall[3])) {
        strList.push(wall[3]);
      }
      str = wall[3];
    }
    let area: number = (wall[4] * 10) / 10;
    rowsArr.push({ walltype: wall[3], wallarea: area.toFixed(2) });
  });
  let wallType: string = "";
  let wallArea: number = 0;

  strList.forEach((str: any) => {
    wallType = str;
    wallArea = 0;
    let arr = rowsArr.filter((a: any) => a.walltype === str);
    arr.forEach((item: any) => {
      wallArea += (item.wallarea * 10) / 10;
    });
    rows.push(["", wallType, wallArea]);
  });

  let sortedRows = sortBy(rows, [
    function (o) {
      return o[2];
    },
  ]).reverse();
  return sortedRows;
};

export const detailNewSummaryBuilder = async (wallsData: any) => {
  return wallsData.filter((e: any) => e[e.length - 1] === "NA");
};

const getWallsWithSolutionsData = (wallsData: any, solutionData: any) => {
  wallsData.forEach((wall: any) => {
    let data = solutionData.find((a: any) => a.Id === wall.Id)?.Params[0];
    let solution = data === undefined ? null : data.Value;
    wall.solution = solution === null ? " " : getSolutionName(solution);
  });
  return wallsData;
};

const getSolutionName = (solution: string) => {
  let solutionname: string = "";
  let solArr: string[] = solution.split("-");
  for (let i = 1; i < solArr.length; i++) {
    solutionname += solArr[i];
  }
  return solutionname;
};

const writeGlobalParLevel = async (
  dataSheet: Excel.Worksheet,
  OuvrageRows: any,
  startingRow: number
) => {
  for (let i = 0; i < OuvrageRows.length; i++) {
    writeToCellAndStyle(
      dataSheet,
      `A${startingRow + i}`,
      OuvrageRows[i][0],
      false,
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `B${startingRow + i}`,
      OuvrageRows[i][1],
      false,
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `C${startingRow + i}`,
      OuvrageRows[i][2],
      false,
      true
    );
    dataSheet.getRow(startingRow + i).font = {
      bold: false,
    };
    dataSheet.getRow(startingRow + i).getCell(startingRow).alignment = {
      wrapText: true,
    };
  }
};

export const detailMainBuilder = async (
  wallsData: any,
  type: any,
  level: any
) => {
  let rows: any = [];
  wallsData.forEach((wall: any) => {
    let area: number = (wall.Area * 10) / 10;
    let height: number = (wall.Height as number) / 1000;
    let length: number = wall.Length / 1000;
    rows.push([
      level,
      "Zone 1",
      wall.solution,
      type,
      parseFloat(area.toFixed(2)),
      parseInt(wall.Id),
      parseFloat(height.toFixed(3)),
      parseFloat(length.toFixed(3)),
    ]);
  });
  return rows;
};
