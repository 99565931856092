import { get, map } from "lodash";
import React, { useState } from "react";
import { Button, Checkbox, Icon, Modal, Table } from "semantic-ui-react";
import CeilingTableRow from "./CeilingTableRow";

interface CeilingTableProps {
  defaultChecked: boolean;
  setUnsetRows: any;
  placo100: boolean;
  ceilingsData: any;
  language: string;
  setRoute: any;
  setPlaque: any;
  setMontant: any;
  setCheckedCeilings: any;
  showConfig: any;
  setCeilingX: any;
  setCeilingY: any;
}

interface wordingType {
  solutionPlaco: { [key: string]: string };
  revitSystemType: { [key: string]: string };
  noCeiling: { [key: string]: string };
}

const wording: wordingType = {
  solutionPlaco: {
    French: "Solutions Placo",
    English: "Solutions Placo",
  },
  revitSystemType: {
    French: "Système Revit",
    English: "Revit System Type",
  },
  noCeiling: {
    French: "Aucun plafonds n'a été sélectionné dans le projet",
    English: "There are no Ceilings",
  },
};

export const CeilingTable = (props: CeilingTableProps) => {
  const [xyModal, setxyModal] = useState(false);

  return (
    <>
      <Modal
        size={"tiny"}
        open={xyModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "1rem" }}>
              <Icon name="info circle" size="big" />
            </div>
            <div style={{ padding: "1rem" }}>
              <p>
                X: Distance (en m) entre le mur et la première solive <br />Y :
                Valeur (en m) de l’entraxe des solives ou des poutrelles
              </p>
              <img src="/xyImage.png" alt="" style={{ width: "100%" }} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions
          style={{
            textAlign: "center",
            borderTop: "none",
            paddingTop: 0,
          }}
        >
          <Button
            primary
            onClick={() => {
              setxyModal(false);
            }}
          >
            CONTINUER
          </Button>
        </Modal.Actions>
      </Modal>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "5%" }}>
              <Checkbox
                checked={props.defaultChecked}
                onChange={(e, data) => {
                  props.setUnsetRows(data.checked!, true);
                }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "5%" }}>Etage</Table.HeaderCell>

            {get(props.ceilingsData[0], "Zone") !== "" && (
              <Table.HeaderCell style={{ width: "5%" }}>Zone</Table.HeaderCell>
            )}

            <Table.HeaderCell style={{ width: "13%" }}>
              {wording.revitSystemType[props.language]}
            </Table.HeaderCell>

            <Table.HeaderCell style={{ width: "20%" }}>
              {wording.solutionPlaco[props.language]}
            </Table.HeaderCell>
            {/* Temporary hidden
  <Table.HeaderCell style={{ width: "5%" }}></Table.HeaderCell> */}

            <Table.HeaderCell style={{ width: "17%" }}>
              Choix de la plaque
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: "17%" }}>
              Choix du Ossatures
            </Table.HeaderCell>
            {/* <Table.HeaderCell style={{ width: "19%" }}>
              Solive et entraxe
              <Icon
                name="info circle"
                style={{ cursor: "pointer" }}
                onClick={() => setxyModal(true)}
              />
            </Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body style={{ textAlign: "center" }}>
          {map(props.ceilingsData, (ceilings: any, key: any) => {
            return (
              <CeilingTableRow
                ceilings={ceilings}
                setRoute={props.setRoute}
                setPlaque={props.setPlaque}
                setMontant={props.setMontant}
                rowKey={key}
                index={key}
                showConfig={props.showConfig}
                setCheckedCeilings={props.setCheckedCeilings}
                setCeilingX={props.setCeilingX}
                setCeilingY={props.setCeilingY}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
