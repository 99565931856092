import {
  getItem,
  setItem,
  listModule,
  listDetailModule,
  setModule,
  deleteModule,
  selectionIsUsed,
  renameModule,
} from "./storage";
import {
  saveSelection,
  listSelection,
  onProjectData,
  deleteSelection,
  getSelection,
  updateSelection,
  listCalepinage,
  saveCalepinage,
  getCalepinage,
  deleteCalepinage,
  updateCalepinage,
  saveSystemSelection,
  getTimeStamp,
  getInfoForm,
  saveInfoForm,
  updateInfoForm,
  getAddInfoForm,
  saveAddInfoForm,
  updateAddInfoForm,
  saveReperage,
  getReperage,
} from "./query";
export { storageKey, ModuleList } from "./key";

export const bimStorage = {
  getItem,
  setItem,
  listModule,
  setModule,
  deleteModule,
  listDetailModule,
  selectionIsUsed,
  renameModule,
  saveSelection,
  listSelection,
  onProjectData,
  deleteSelection,
  getSelection,
  updateSelection,
  listCalepinage,
  saveCalepinage,
  deleteCalepinage,
  getCalepinage,
  updateCalepinage,
  saveSystemSelection,
  getTimeStamp,
  getInfoForm,
  saveInfoForm,
  updateInfoForm,
  getAddInfoForm,
  saveAddInfoForm,
  updateAddInfoForm,
  saveReperage,
  getReperage,
};
