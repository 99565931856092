import { ProductDetailData } from "../../../../RevitJS/Types/BddTypes";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../Common/assets/attributes.injection";
import {
  DISTRIBUTIVE_STANDARD,
  ISOLATION_DES_MURS,
  SEPARATIVE_STANDARD,
  SEPARATIVE_STANDARD_SAA,
  SEPARATIVE_STANDARD_SAD,
  FILTER_CRITERION_TYPE,
  FILTER_VALUE_TYPE,
  DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR,
} from "../assets/constants";

export const addFilterFields = (
  systemsDetails: ProductDetailData[],
  filters: any
) => {
  return systemsDetails.map((sys: any) => {
    if(filters !== undefined){
    filters.forEach((filter: any) => {
      sys.filterFields[filter.name] = [];
      const attr = sys.attributes.find(
        (attr: any) => attr.technicalName === filter.pimAttribute
      );
      if (attr) {
        attr.values.forEach((element: any) => {
          let value =
            filter.type === FILTER_VALUE_TYPE.NUMERIC
              ? element.numericValue
              : element.value;
          sys.filterFields[filter.name].push(value);
        });
      } else {
        sys.filterFields[filter.name] = undefined;
      }
    });}
    return sys;
  });
};

export const setFiltersOptions = (
  systemsDetails: ProductDetailData[],
  filters: any
) => {
  if(filters !== undefined){
  filters = [...filters];
  systemsDetails.forEach((system) => {
    filters.forEach((filter: any) => {
      filter.options = filter.options || [];
      const attrValue = system.filterFields[filter.name];
      if (attrValue !== undefined) {
        attrValue.forEach((element: any) => {
          if (!filter.options.includes(element)) {
            filter.options.push(element);
          }
        });
      }
    });
  });

  return filters.map((filter: any) => {
    if (filter.options) {
      filter.options.sort((a: any, b: any) => {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;

        var aaN = parseInt(a);
        var bbN = parseInt(b);

        if (isNaN(aaN) && isNaN(bbN)) {
          var aA = a.replace(reA, "");
          var bA = b.replace(reA, "");

          if (aA === bA) {
            var aNN = parseInt(a.replace(reN, ""), 10);
            var bNN = parseInt(b.replace(reN, ""), 10);
            return aNN === bNN ? 0 : aNN > bNN ? 1 : -1;
          }
        }

        if (!isNaN(aaN) && !isNaN(bbN)) {
          return aaN < bbN ? -1 : aaN > bbN ? 1 : 0;
        }

        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    return filter;
  });
}
return filters;
};

export const filterElements = (systemsDetails: any[], filters: any[]) => {
  return filters.reduce(
    (acc, current) => {
      if (current.options) {
        if (current.value) {
          if (current.value === "") return acc;
          let matchingDetails;
          switch (current.criterion) {
            case FILTER_CRITERION_TYPE.GREATERTHEN:
              return acc.filter((el: { technicalName: string }, i: any) => {
                matchingDetails = systemsDetails.find(
                  (details) => details.technicalName === el.technicalName
                );
                return (
                  matchingDetails?.filterFields[current.name] >= current.value
                );
              });
            case FILTER_CRITERION_TYPE.EQUALS:
              return acc.filter((el: { technicalName: string }, i: any) => {
                matchingDetails = systemsDetails.find(
                  (details) => details.technicalName === el.technicalName
                );
                return (
                  matchingDetails?.filterFields[current.name] === current.value
                );
              });
            case FILTER_CRITERION_TYPE.INCLUDES:
              return acc.filter((el: { technicalName: string }, i: any) => {
                matchingDetails = systemsDetails.find(
                  (details) => details.technicalName === el.technicalName
                );
                let filterFields = matchingDetails?.filterFields[current.name];
                return (
                  current.value.filter((c: any) =>
                    filterFields?.some((m: any) => c === m)
                  ).length > 0
                );
                // return current.value.includes(
                //   matchingDetails?.filterFields[current.name]
                // );
              });
            case FILTER_CRITERION_TYPE.BETWEEN:
              if (
                current.value[0] !== undefined &&
                current.value[1] !== undefined
              ) {
                return acc.filter((el: any) => {
                  matchingDetails = systemsDetails
                    .filter(
                      (detail: any) =>
                        detail?.filterFields[current.name] !== undefined
                    )
                    .find(
                      (details: any) =>
                        details.technicalName === el.technicalName
                    );
                  return (
                    current.value[0] <=
                      matchingDetails?.filterFields[current.name] &&
                    matchingDetails?.filterFields[current.name] <=
                      current.value[1]
                  );
                });
              } else {
                return acc;
              }

            default:
              return acc;
          }
        }
        return acc;
      }
      return acc;
    },
    [...systemsDetails]
  );
};

export const getSystemType = (system: any) => {
  const SystemType = system.attributes.find(
    (attribute: any) =>
      attribute.technicalName ===
      PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION
  )?.values[0]?.value;

  const SystemName = system.attributes.find(
    (attribute: any) =>
      attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.SYSTEM_NAME
  )?.values[0]?.value;
  if (SystemType === DISTRIBUTIVE_STANDARD) return DISTRIBUTIVE_STANDARD;
  if (SystemType === DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR)
    return DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR;
  else if (SystemType === ISOLATION_DES_MURS || SystemName.includes("Doublage"))
    return ISOLATION_DES_MURS;
  else if (SystemType === SEPARATIVE_STANDARD && SystemName.includes("SAA"))
    return SEPARATIVE_STANDARD_SAA;
  else if (SystemType === SEPARATIVE_STANDARD && SystemName.includes("SAD"))
    return SEPARATIVE_STANDARD_SAD;
};

export const applyRulesToData = (attributes: any[], dataRules: any[]) => {
  let result: boolean = false;
  let count: number = 0;
  let condition: string = "";

  dataRules.forEach((rule: any) => {
    condition = count === 0 ? rule.condition : dataRules[count - 1].condition;
    if (attributes.some((a: any) => a.technicalName === rule.attribute)) {
      let getValues = attributes.find(
        (a: any) => a.technicalName === rule.attribute
      )?.values;

      let attributeValue = getValues.find((a: any) =>
        a.value.toLowerCase().includes(rule.value.toLowerCase())
      )?.value;
      attributeValue = attributeValue !== undefined ? attributeValue : "0";
      // condition = count == 0 ? rule.condition : dataRules[count - 1].condition;

      if (
        rule.caseValue.toUpperCase() === "CONTAINS" ||
        rule.relation.toUpperCase() === "CONTAINS"
      ) {
        result =
          count === 0
            ? isContains(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && isContains(attributeValue, rule.value)
            : result || isContains(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "ISNOTCONTAINS" ||
        rule.relation.toUpperCase() === "ISNOTCONTAINS"
      ) {
        result =
          count === 0
            ? !isContains(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && !isContains(attributeValue, rule.value)
            : result || !isContains(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "EQUALTO" ||
        rule.relation.toUpperCase() === "EQUALTO"
      ) {
        result =
          count === 0
            ? isEqualTo(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && isEqualTo(attributeValue, rule.value)
            : result || isEqualTo(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "ISNOTEMPTY" ||
        rule.relation.toUpperCase() === "ISNOTEMPTY"
      ) {
        result =
          count === 0
            ? isNotEmpty(attributeValue)
            : checkAndCondition(condition)
            ? result && isNotEmpty(attributeValue)
            : result || isNotEmpty(attributeValue);
      } else if (
        rule.caseValue.toUpperCase() === "ISEMPTY" ||
        rule.relation.toUpperCase() === "ISEMPTY"
      ) {
        result =
          count === 0
            ? isEmpty(attributeValue)
            : checkAndCondition(condition)
            ? result && isEmpty(attributeValue)
            : result || isEmpty(attributeValue);
      } else if (
        rule.caseValue.toUpperCase() === "GREATERTHAN" ||
        rule.relation.toUpperCase() === "GREATERTHAN"
      ) {
        result =
          count === 0
            ? isGreaterThan(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && isGreaterThan(attributeValue, rule.value)
            : result || isGreaterThan(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "LESSTHAN" ||
        rule.relation.toUpperCase() === "LESSTHAN"
      ) {
        result =
          count === 0
            ? isLessThan(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && isLessThan(attributeValue, rule.value)
            : result || isLessThan(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "STARTSWITH" ||
        rule.relation.toUpperCase() === "STARTSWITH"
      ) {
        result =
          count === 0
            ? isStartWith(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && isStartWith(attributeValue, rule.value)
            : result || isStartWith(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "ENDSWITH" ||
        rule.relation.toUpperCase() === "ENDSWITH"
      ) {
        result =
          count === 0
            ? isEndsWith(attributeValue, rule.value)
            : checkAndCondition(condition)
            ? result && isEndsWith(attributeValue, rule.value)
            : result || isEndsWith(attributeValue, rule.value);
      } else if (
        rule.caseValue.toUpperCase() === "BETWEEN" ||
        rule.relation.toUpperCase() === "BETWEEN"
      ) {
        result =
          count === 0
            ? isBetween(attributeValue, rule.Value)
            : checkAndCondition(condition)
            ? result && isBetween(attributeValue, rule.Value)
            : result || isBetween(attributeValue, rule.Value);
      }
    } else if (
      rule.attribute &&
      rule.attribute !== "" &&
      condition.toLowerCase() !== "or"
    ) {
      result = false;
    }
    count++;
  });
  return result;
};

export const getConditionValue1 = (
  rule: any,
  attributeValue: any,
  condition: string,
  count: number,
  result: boolean
): boolean => {
  if (
    rule.caseValue.toUpperCase() === "CONTAINS" ||
    rule.relation.toUpperCase() === "CONTAINS"
  ) {
    result =
      count === 0
        ? isContains(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && isContains(attributeValue, rule.value)
        : result || isContains(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "ISNOTCONTAINS" ||
    rule.relation.toUpperCase() === "ISNOTCONTAINS"
  ) {
    result =
      count === 0
        ? !isContains(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && !isContains(attributeValue, rule.value)
        : result || !isContains(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "EQUALTO" ||
    rule.relation.toUpperCase() === "EQUALTO"
  ) {
    result =
      count === 0
        ? isEqualTo(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && isEqualTo(attributeValue, rule.value)
        : result || isEqualTo(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "ISNOTEMPTY" ||
    rule.relation.toUpperCase() === "ISNOTEMPTY"
  ) {
    result =
      count === 0
        ? isNotEmpty(attributeValue)
        : checkAndCondition(condition)
        ? result && isNotEmpty(attributeValue)
        : result || isNotEmpty(attributeValue);
  } else if (
    rule.caseValue.toUpperCase() === "ISEMPTY" ||
    rule.relation.toUpperCase() === "ISEMPTY"
  ) {
    result =
      count === 0
        ? isEmpty(attributeValue)
        : checkAndCondition(condition)
        ? result && isEmpty(attributeValue)
        : result || isEmpty(attributeValue);
  } else if (
    rule.caseValue.toUpperCase() === "GREATERTHAN" ||
    rule.relation.toUpperCase() === "GREATERTHAN"
  ) {
    result =
      count === 0
        ? isGreaterThan(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && isGreaterThan(attributeValue, rule.value)
        : result || isGreaterThan(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "LESSTHAN" ||
    rule.relation.toUpperCase() === "LESSTHAN"
  ) {
    result =
      count === 0
        ? isLessThan(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && isLessThan(attributeValue, rule.value)
        : result || isLessThan(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "STARTSWITH" ||
    rule.relation.toUpperCase() === "STARTSWITH"
  ) {
    result =
      count === 0
        ? isStartWith(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && isStartWith(attributeValue, rule.value)
        : result || isStartWith(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "ENDSWITH" ||
    rule.relation.toUpperCase() === "ENDSWITH"
  ) {
    result =
      count === 0
        ? isEndsWith(attributeValue, rule.value)
        : checkAndCondition(condition)
        ? result && isEndsWith(attributeValue, rule.value)
        : result || isEndsWith(attributeValue, rule.value);
  } else if (
    rule.caseValue.toUpperCase() === "BETWEEN" ||
    rule.relation.toUpperCase() === "BETWEEN"
  ) {
    result =
      count === 0
        ? isBetween(attributeValue, rule.Value)
        : checkAndCondition(condition)
        ? result && isBetween(attributeValue, rule.Value)
        : result || isBetween(attributeValue, rule.Value);
  }

  return result;
};

const isContains = (attributeValue: string, dataRuleValue: string): boolean => {
  return attributeValue.toLowerCase().includes(dataRuleValue.toLowerCase());
};

const checkAndCondition = (condition: string): boolean => {
  return condition.toLowerCase() === "and" ? true : false;
};

const isEqualTo = (attributeValue: string, dataRuleValue: string) => {
  return attributeValue.toLowerCase() === dataRuleValue.toLowerCase()
    ? true
    : false;
};

const isNotEmpty = (attributeValue: string) => {
  return attributeValue && attributeValue !== "" ? true : false;
};

const isEmpty = (attributeValue: string) => {
  return !attributeValue && attributeValue === "" ? true : false;
};

const isGreaterThan = (attributeValue: string, dataRuleValue: string) => {
  return parseInt(attributeValue) > parseInt(dataRuleValue) ? true : false;
};

const isLessThan = (attributeValue: string, dataRuleValue: string) => {
  return parseInt(attributeValue) < parseInt(dataRuleValue) ? true : false;
};

const isStartWith = (attributeValue: string, dataRuleValue: string) => {
  return attributeValue.startsWith(dataRuleValue) ? true : false;
};

const isEndsWith = (attributeValue: string, dataRuleValue: string) => {
  return attributeValue.endsWith(dataRuleValue) ? true : false;
};

const isBetween = (attributeValue: string, dataRuleValue: string) => {
  return parseInt(attributeValue) > parseInt(dataRuleValue.split(",")[0]) &&
    parseInt(attributeValue) < parseInt(dataRuleValue.split(",")[1])
    ? true
    : false;
};
