import {
  PlacoData,
  ProductDetailData,
  PIMLayoutAttributesAid
} from "../../../../RevitJS/Types/BddTypes";
import { ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { TypeData, LevelData, MappingRow } from "../../../../RevitJS/Types/StoreTypes";
import { elementTypes } from "../../../../RevitJS/Helpers";

export const FETCH_PLACO_DATA = "INIT_PLACO_DATA";
export const SET_SELECTION_TREE = "SET_SELECTION_TREE";
export const INIT_GROUP_SELECTOR = "SET_GROUP_SELECTOR";
export const SELECT_TYPE = "SELECT_TYPE";
export const SELECT_LEVEL = "SELECT_LEVEL";
export const INIT_MAPPING_TABLE = "INIT_MAPPING_TABLE";
export const SELECT_SYSTEM = "SELECT_SYSTEM";
export const INIT_PRODUCT_SELECTOR = "INIT_PRODUCT_SELECTOR";
export const SET_SELECTION_BUFFER = "SET_SELECTION_BUFFER";
export const SET_ACTIVE_MAPPING_ROW_INDEX = "SET_ACTIVE_MAPPING_ROW_INDEX";
export const RESET_ACTIVE_MAPPING_ROW = "RESET_ACTIVE_MAPPING_ROW";
export const RESET_SELECTION_BUFFER = "RESET_SELECTION_BUFFER";
export const SEARCH = "SEARCH";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const INIT_LAYOUT_PROGRESS = "INIT_LAYOUT_PROGRESS";
export const UPDATE_LAYOUT_PROGRESS = "UPDATE_LAYOUT_PROGRESS";
export const START_LAYOUT = "START_LAYOUT";
export const END_LAYOUT = "END_LAYOUT";

export interface FetchDataAction {
  type: typeof FETCH_PLACO_DATA;
  liningWallsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  partitionsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  layoutAttributesAid: PIMLayoutAttributesAid
}

export interface SetSelectionTreeAction{
    type: typeof SET_SELECTION_TREE,
    tree: ElementsTree
}

export interface InitGroupSelectorAction{
    type: typeof INIT_GROUP_SELECTOR,
    tree: ElementsTree
}

export interface SelectLevelAction{
    type: typeof SELECT_LEVEL,
    typesData: TypeData[],
    levelsData: LevelData[],
    selectedLevels: string[],
    selectedTypes: string[]
}


export interface SelectTypeAction{
    type: typeof SELECT_TYPE,
    typesData: TypeData[],
    selectedTypes: string[]
}

export interface InitMappingTableAction{
    type: typeof INIT_MAPPING_TABLE,
    mappingRows: MappingRow[]
}

export interface SelectSystemAction{
    type: typeof SELECT_SYSTEM,
    index: number,
    system: ProductDetailData
}

export interface InitProductSelectorAction{
    type: typeof INIT_PRODUCT_SELECTOR
    displayedElements: ProductDetailData[],
    filteredElements: ProductDetailData[]
}

export interface SetSelectionBufferAction{
    type: typeof SET_SELECTION_BUFFER,
    system: ProductDetailData
}


export interface SetActiveRowIndexAction{
    type: typeof SET_ACTIVE_MAPPING_ROW_INDEX,
    index: number
}

export interface ResetActiveRowAction{
    type: typeof RESET_ACTIVE_MAPPING_ROW
}

export interface ResetSelectionBufferAction{
    type: typeof RESET_SELECTION_BUFFER
}

export interface SearchAction{
    type: typeof SEARCH;
    filteredElements: ProductDetailData[],
    displayedElements: ProductDetailData[],
    searchedWord: string,
}

export interface ApplyFiltersAction{
    type: typeof APPLY_FILTERS,
    filteredElements: ProductDetailData[],
    displayedElements: ProductDetailData[],
    filters: { partitions: any[], liningWalls: any[] };
}

export interface ResetFiltersAction{
    type: typeof RESET_FILTERS
    filteredElements: ProductDetailData[],
    displayedElements: ProductDetailData[],
    filters: { partitions: any[], liningWalls: any[] };
}

export interface InitLayoutProgressAction{
    type: typeof INIT_LAYOUT_PROGRESS
}

export interface UpdateLayoutProgressAction{
    type: typeof UPDATE_LAYOUT_PROGRESS,
    progress: number
}

export interface StartLayoutAction{
    type: typeof START_LAYOUT
}

export interface EndLayoutAction{
    type: typeof END_LAYOUT
}

export type DataAction = FetchDataAction;

export type FilterAction = FetchDataAction;

export type SelectionTreeAction = SetSelectionTreeAction;

export type SelectorAction = InitGroupSelectorAction | SelectLevelAction | SelectTypeAction;

export type MappingTableAction = InitMappingTableAction | SelectSystemAction;

export type ProductSelectorAction = InitProductSelectorAction | SetSelectionBufferAction | SetActiveRowIndexAction | ResetActiveRowAction | ResetSelectionBufferAction | SearchAction | ApplyFiltersAction | ResetFiltersAction;

export type LayoutAction = InitLayoutProgressAction | UpdateLayoutProgressAction | StartLayoutAction | EndLayoutAction;