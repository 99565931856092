import React from "react";
import { Container, Header } from "semantic-ui-react";

interface Props {
  name: string;
  Icon: string;
  width?: number;
  subheader?: string;
}

export const TopHeader = (props: Props) => {
  const { name, Icon, width, subheader } = props;
  return (
    <Container textAlign="center" style={{ marginBottom: 0, width: "100%" }}>
      <Header
        color="blue"
        style={{ paddingTop: "0", paddingBottom: 0 }}
        as="h3"
        subheader={subheader ?? ""}
        content={name}
        image={Icon !== "" ? Icon : "/plugin_new_logo/BeneluxIcon.png"}
      ></Header>
    </Container>
  );
};
export default TopHeader;
