import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Item from "../../../../models/Item.model";

function CouplingSelectionHeader({
    item,
    itemIndex,
    itemsLength,
    handleQualityOrGasketChange,
    t,
}: IProps) {
    return (
        <thead className="w-100">
            <tr className="w-100">
                <th colSpan={2}>
                    <span>{`${t("RANGE")} ${
                        itemIndex + 1
                    }/${itemsLength}`}</span>
                </th>
                {item.allCompatibleCouplings?.map((coupling, couplingIndex) => {
                    return (
                        <th
                            key={`coupling-selection-header-family-type-${itemIndex}-${couplingIndex}`}
                        >
                            <span>{coupling.familyType}</span>
                        </th>
                    );
                })}
            </tr>
            <tr className="w-100">
                <th
                    colSpan={2}
                    style={{
                        top: "25px",
                    }}
                >
                    <span>{t("QUALITY")}</span>
                </th>
                {item.allCompatibleCouplings?.map((coupling, couplingIndex) => {
                    return (
                        <th
                            key={`coupling-selection-header-quality-th-${itemIndex}-${couplingIndex}`}
                            style={{
                                top: "25px",
                            }}
                        >
                            {coupling.quality.length > 0 && (
                                <select
                                    name={`coupling-quality-select-${itemIndex}-${couplingIndex}`}
                                    id={`coupling-quality-select-${itemIndex}-${couplingIndex}`}
                                    value={coupling.selectedQuality}
                                    onChange={(e) => {
                                        handleQualityOrGasketChange(
                                            "selectedQuality",
                                            coupling,
                                            e.target.value
                                        );
                                    }}
                                >
                                    {coupling.quality.map(
                                        (
                                            quality: any,
                                            qualityIndex: number
                                        ) => {
                                            return (
                                                <option
                                                    key={`coupling-quality-option-${itemIndex}-${couplingIndex}-${qualityIndex}`}
                                                    value={quality.name}
                                                >
                                                    {quality.name}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            )}
                        </th>
                    );
                })}
            </tr>
            <tr className="w-100">
                <th
                    colSpan={2}
                    style={{
                        top: "50px",
                    }}
                >
                    <span>{t("GASKET_MATERIAL")}</span>
                </th>

                {item.allCompatibleCouplings?.map((coupling, couplingIndex) => {
                    return (
                        <th
                            key={`coupling-selection-header-gasket-th-${itemIndex}-${couplingIndex}`}
                            style={{
                                top: "50px",
                            }}
                        >
                            {coupling.gasketMaterial.length > 0 && (
                                <select
                                    name={`coupling-gasket-select-${itemIndex}-${couplingIndex}`}
                                    id={`coupling-gasket-select-${itemIndex}-${couplingIndex}`}
                                    value={coupling.selectedGasket}
                                    onChange={(e) => {
                                        handleQualityOrGasketChange(
                                            "selectedGasket",
                                            coupling,
                                            e.target.value
                                        );
                                    }}
                                >
                                    {coupling.gasketMaterial.map(
                                        (
                                            gasketMaterial: any,
                                            gasketMaterialIndex: number
                                        ) => {
                                            return (
                                                <option
                                                    key={`coupling-gasket-select-option-${itemIndex}-${couplingIndex}-${gasketMaterialIndex}`}
                                                    value={gasketMaterial.name}
                                                >
                                                    {gasketMaterial.name}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            )}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}

type IProps = WithTranslation & {
    item: Item;
    itemIndex: number;
    itemsLength: number;
    handleQualityOrGasketChange: Function;
};

export default withTranslation(["pam"])(CouplingSelectionHeader);
