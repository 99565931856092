import { parseLayers } from ".";
import { fetchPlacoSolutionsDetailsWithRelatedChildren } from "../../../../API";
import { CategoryMap, CategoryMapNonLayout } from "../Extract";

import map from "lodash/map";
import find from "lodash/find";
import includes from "lodash/includes";
import forEach from "lodash/forEach";
import filter from "lodash/filter";
import {
  fetchPlacoDataIncludeChildern,
  fetchPlacoDataIncludeRelatedObjects,
  fetchPlacoSolutionsDetailsWithRelatedChildrenRecursiveChildrenInGroup,
} from "../../../../API/request";
import { concat, uniq, uniqBy } from "lodash";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";

interface slProductType {
  context: {
    attributeDependencies: any;
    objectDependencies: any;
    relations: any;
  };
  message: null;
  objects: any;
}

const slProductInitialState = {
  context: {
    attributeDependencies: [
      { attributes: [], className: "SGObjectAttribute" },
      { attributes: [], className: "RelationAttribute" },
    ],
    objectDependencies: [],
    relations: [],
  },
  message: null,
  objects: [],
};
export const fetchPlacoDataInGroupSplit = async (
  uniqSystemsOids: string[],
  config: any
) => {
  try {
    let grpRelatedData: slProductType = slProductInitialState;

    const groupResponseIncludeRelatedObjects: any =
      await fetchPlacoDataIncludeRelatedObjects(uniqSystemsOids, config);

    forEach(groupResponseIncludeRelatedObjects, (relDat, ind) => {
      grpRelatedData.context.attributeDependencies[0].attributes = uniqBy(
        concat(
          grpRelatedData.context.attributeDependencies[0].attributes,
          relDat.context.attributeDependencies[0].attributes
        ),
        "aid"
      );
      grpRelatedData.context.attributeDependencies[1].attributes = uniqBy(
        concat(
          grpRelatedData.context.attributeDependencies[1].attributes,
          relDat.context.attributeDependencies[1].attributes
        ),
        "aid"
      );
      grpRelatedData.context.objectDependencies = uniqBy(
        concat(
          grpRelatedData.context.objectDependencies,
          relDat.context.objectDependencies
        ),
        "className"
      );
      grpRelatedData.context.relations = uniqBy(
        concat(grpRelatedData.context.relations, relDat.context.relations),
        "rid"
      );
      grpRelatedData.objects = uniqBy(
        concat(grpRelatedData.objects, relDat.objects),
        "oid"
      );
    });

    const filteredProduct = filter(grpRelatedData.objects, (object) => {
      if (object.types[0] === "Product") {
        return true;
      }
      return false;
    });

    const prodcutForPackandArticle = map(filteredProduct, "oid");

    const grpResponseIncludeChildren: any = await fetchPlacoDataIncludeChildern(
      prodcutForPackandArticle,
      config
    );

    forEach(grpResponseIncludeChildren, (relDat, ind) => {
      grpRelatedData.context.attributeDependencies[0].attributes = uniqBy(
        concat(
          grpRelatedData.context.attributeDependencies[0].attributes,
          relDat.context.attributeDependencies[0].attributes
        ),
        "aid"
      );
      grpRelatedData.context.attributeDependencies[1].attributes = uniqBy(
        concat(
          grpRelatedData.context.attributeDependencies[1].attributes,
          relDat.context.attributeDependencies[1].attributes
        ),
        "aid"
      );
      grpRelatedData.context.objectDependencies = uniqBy(
        concat(
          grpRelatedData.context.objectDependencies,
          relDat.context.objectDependencies
        ),
        "className"
      );
      grpRelatedData.context.relations = uniqBy(
        concat(grpRelatedData.context.relations, relDat.context.relations),
        "rid"
      );
      grpRelatedData.objects = uniqBy(
        concat(grpRelatedData.objects, relDat.objects),
        "oid"
      );
    });

    const extractedSolutionProduct: any[] = [];

    if (grpRelatedData) {
      const objects = grpRelatedData.objects;
      const contextAttributes =
        grpRelatedData.context.attributeDependencies[0].attributes;
      const relationAttributes =
        grpRelatedData.context.attributeDependencies[1].attributes;
      const contextRelations = grpRelatedData.context.relations;

      const solutionProducts = objects.filter(
        (obj: any) => obj.types[0] === "Solution Product"
      );
      const articles = objects.filter((obj: any) => obj.types[0] === "Article");

      const packagedArticles = objects.filter(
        (obj: any) => obj.types[0] === "Packaged Article"
      );

      await forEach(solutionProducts, async (solutionProduct, spIndex) => {
        const productLayoutPossible = find(solutionProduct.attributes, {
          technicalName: "GFR-Layout possible",
        });

        let layoutPossible = productLayoutPossible
          ? productLayoutPossible.values[0].value.toLowerCase()
          : false;

        // if (layoutPossible === "true") {
        const spOid = solutionProduct.oid;
        const spRelations = filter(contextRelations, { oid1: spOid });
        const mapSpRelationIdsOfProducts = map(spRelations, "oid2");
        const products = filter(objects, function (o) {
          return includes(mapSpRelationIdsOfProducts, o.oid);
        });

        const extractedProducts: any[] = [];

        forEach(products, async (product, productIndex) => {
          const productRSCategoryPath = find(product.attributes, {
            technicalName: "RS-CategoryPath",
          });

          let filteredCategoryPath = find(CategoryMap, function (o) {
            return includes(productRSCategoryPath.values[0].value, o.category);
          });

          if (layoutPossible === "false") {
            forEach(CategoryMapNonLayout, (mapnon, mapIndex) => {
              const isProductFound = mapnon.find(
                (mn) => mn.PRODUCT_CODE === product.externalName
              );
              if (isProductFound) {
                filteredCategoryPath = {
                  type:
                    mapIndex === "accessoires" && filteredCategoryPath
                      ? filteredCategoryPath.type
                      : mapIndex,
                  category: mapIndex,
                };
              }
            });
          }

          const productValid = productArticleLevelFilter(product.attributes);
          let extractedArticles: any[] = [];
          if (filteredCategoryPath?.type === "wool") {
            const productArticles = filter(articles, function (o) {
              return includes(o.parentOids, product.oid);
            });
            forEach(productArticles, (productArticle, prArticleIndex) => {
              const selectedPackagedArticle = filter(
                packagedArticles,
                (packArticle) => {
                  const isArticleObject = find(packArticle.attributes, {
                    technicalName: "A-Article Name",
                  });

                  if (isArticleObject) {
                    return (
                      isArticleObject.values[0].value ===
                      productArticle.translation
                    );
                  }
                  return false;
                }
              );

              if (selectedPackagedArticle) {
                const articleLength = find(productArticle.attributes, {
                  technicalName: "GFR-Length of the UB unpacked (m)",
                });

                forEach(selectedPackagedArticle, (pkArticle) => {
                  extractedArticles.push({
                    value: pkArticle.translation,
                    text: pkArticle.translation,
                    key: pkArticle.translation,
                    height: articleLength?.values[0].value,
                    article: productArticle,
                    placo100: productValid,
                    packagedArticle: pkArticle,
                  });
                });
              }
            });

            extractedProducts.push({
              product: product,
              articles: extractedArticles,
              type: filteredCategoryPath?.type,
            });
          } else if (productValid) {
            const productArticles = filter(articles, function (o) {
              return includes(o.parentOids, product.oid);
            });

            if (filteredCategoryPath) {
              forEach(productArticles, (productArticle, prArticleIndex) => {
                const validArticle = productArticleLevelFilter(
                  productArticle.attributes
                );
                if (validArticle) {
                  if (filteredCategoryPath?.type === "plaque") {
                    const selectedPackagedArticle = filter(
                      packagedArticles,
                      (packArticle) => {
                        const isArticleObject = find(packArticle.attributes, {
                          technicalName: "A-Article Name",
                        });

                        const packArticleValid = packagedArticleLevelFilter(
                          packArticle.attributes
                        );
                        if (packArticleValid) {
                          if (isArticleObject) {
                            return (
                              isArticleObject.values[0].value ===
                              productArticle.translation
                            );
                          }
                        }
                        return false;
                      }
                    );

                    if (selectedPackagedArticle) {
                      const articleLength = find(productArticle.attributes, {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      });

                      forEach(selectedPackagedArticle, (pkArticle) => {
                        extractedArticles.push({
                          value: pkArticle.translation,
                          text: pkArticle.translation,
                          key: pkArticle.translation,
                          height: articleLength?.values[0].value,
                          article: productArticle,
                          placo100: productValid,
                          packagedArticle: pkArticle,
                        });
                      });
                    }
                  } else if (filteredCategoryPath?.type === "montant") {
                    const selectedPackagedArticle = filter(
                      packagedArticles,
                      (packArticle) => {
                        const isArticleObject = find(packArticle.attributes, {
                          technicalName: "A-Article Name",
                        });

                        const packArticleValid = packagedArticleLevelFilter(
                          packArticle.attributes
                        );
                        if (packArticleValid) {
                          if (isArticleObject) {
                            return (
                              isArticleObject.values[0].value ===
                              productArticle.translation
                            );
                          }
                        }
                        return false;
                      }
                    );

                    if (selectedPackagedArticle) {
                      const articleLength = find(productArticle.attributes, {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      });
                      forEach(selectedPackagedArticle, (pkArticle) => {
                        extractedArticles.push({
                          value: pkArticle.translation,
                          text: pkArticle.translation,
                          key: pkArticle.translation,
                          height: articleLength?.values[0].value,
                          article: productArticle,
                          placo100: productValid,
                          packagedArticle: pkArticle,
                        });
                      });
                    }
                  } else {
                    const selectedPackagedArticle = filter(
                      packagedArticles,
                      (packArticle) => {
                        const isArticleObject = find(packArticle.attributes, {
                          technicalName: "A-Article Name",
                        });

                        if (isArticleObject) {
                          return (
                            isArticleObject.values[0].value ===
                            productArticle.translation
                          );
                        }
                        return false;
                      }
                    );

                    if (selectedPackagedArticle) {
                      const articleLength = find(productArticle.attributes, {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      });

                      forEach(selectedPackagedArticle, (pkArticle) => {
                        extractedArticles.push({
                          value: pkArticle.translation,
                          text: pkArticle.translation,
                          key: pkArticle.translation,
                          height: articleLength?.values[0].value,
                          article: productArticle,
                          placo100: productValid,
                          packagedArticle: pkArticle,
                        });
                      });
                    }
                  }
                }
              });

              extractedProducts.push({
                product: product,
                articles: extractedArticles,
                type: filteredCategoryPath?.type,
              });
            }
          }
        });

        extractedSolutionProduct.push({
          solutionProduct: solutionProduct,
          products: extractedProducts,
          layoutPossible: layoutPossible,
          attributes: contextAttributes,
          relationAttributes: relationAttributes,
          relations: contextRelations,
        });
      });
    }

    return { extractedSolutionProduct };
  } catch (error: any) {
    return Promise.reject(error);
  }
};

/**
 * The function to fetch product, article, and packaged articles data using api
 * @param uniqSystemsOids The Array of unique placo solution Id's
 * @returns
 */

export const parseSystemOptimized = (
  solutionProduct: any,
  products: any,
  attributesId: any,
  Reverse: boolean = false,
  Flipped: boolean = false
) => {
  try {
    let system = solutionProduct.attributes;

    let framesSpace =
      system.find((e: any) => e.aid === attributesId.framesSpace) &&
      system.find((e: any) => e.aid === attributesId.framesSpace).values[0]
        .numericValue;

    let systemName = solutionProduct.translation;

    let layers = system.filter((e: any) => e.aid === attributesId.layers)
      ? system.filter((e: any) => e.aid === attributesId.layers)
      : [];

    layers.sort(function (a: any, b: any) {
      let a_number = a.subAttributes.find(
        (e: any) => e.aid === attributesId.layerNumber
      ).values[0].numericValue;
      let b_number = b.subAttributes.find(
        (e: any) => e.aid === attributesId.layerNumber
      ).values[0].numericValue;
      return a_number - b_number;
    });

    let railSpace = 0;

    forEach(layers, (layer, layerIndex) => {
      let subAttribute = find(layer.subAttributes, {
        technicalName: "GFR-Layer content",
      });
      let layerContentValue = subAttribute?.values[0].value;
      if (layerContentValue?.toLowerCase() === "air") {
        let layerThickness = find(layer.subAttributes, {
          technicalName: "GFR-Layer thickness mm",
        });
        railSpace = layerThickness.values[0].numericValue;
      }
    });

    let sgRails;
    if (attributesId.rails) {
      for (let i = 0; i < attributesId.rails.length; i++) {
        let attribute = system.find(
          (e: any) => e.aid === attributesId.rails[i]
        );
        if (attribute) {
          let name = attribute.values[0].value;
          if (name) {
            if (name.startsWith("Rail") || name.startsWith("Cornière")) {
              sgRails = name;
            }
          }
        }
      }
    }

    let sgFrames;
    if (attributesId.frames) {
      for (let i = 0; i < attributesId.frames.length; i++) {
        let attribute = system.find(
          (e: any) => e.aid === attributesId.frames[i]
        );
        if (attribute) {
          let name = attribute.values[0].value;
          if (name) {
            if (name.startsWith("Montant")) {
              sgFrames = name;
            }
          }
        }
      }
    }

    let systemElems = attributesId && parseLayers(layers, attributesId);

    let plateName = systemElems.claddingB.I1
      ? systemElems.claddingB.I1.name
      : systemElems.claddingA.E1.name;

    let plateProduct = products.find(
      (product: any) => product.product.translation === plateName
    );

    let frameName = sgFrames as string;
    let frameProduct = products.find(
      (product: any) => product.product.translation === frameName
    );

    let plateWidth =
      attributesId && plateProduct
        ? plateProduct.product.attributes.find(
            (e: any) => e.aid === attributesId.plateWidth
          ).values[0].numericValue
        : framesSpace * 1000;

    let frameWidth =
      attributesId &&
      frameProduct &&
      frameProduct.product.attributes.find(
        (e: any) => e.aid === attributesId.frameWidth
      ).values[0].numericValue;

    let plateHeight =
      system.find((e: any) => e.aid === attributesId.plateHeight) &&
      system.find((e: any) => e.aid === attributesId.plateHeight).values[0]
        .numericValue;

    let plateLength =
      system.find((e: any) => e.aid === attributesId.plateLength) &&
      system.find((e: any) => e.aid === attributesId.plateLength).values[0]
        .numericValue;

    let result = {
      systemName,
      framesSpace: framesSpace * 1000,
      plateWidth: plateWidth * 1000,
      E1: systemElems.claddingA.E1,
      E2: systemElems.claddingA.E2,
      E3: systemElems.claddingA.E3,
      I1: systemElems.claddingB.I1,
      I2: systemElems.claddingB.I2,
      I3: systemElems.claddingB.I3,
      Reverse,
      Flipped,
      Lining: systemName && systemName.includes("Doublage") ? true : false,
      railsSpace: railSpace,
      staggering: plateWidth * 1000 > 900 ? true : false,
      doubleFraming: system.find(
        (e: any) => e.aid === attributesId.doubleFraming
      )
        ? system
            .find((e: any) => e.aid === attributesId.doubleFraming)
            .values[0].value.toLowerCase() === "simple"
          ? false
          : true
        : false,
      doubleAmount: systemName && systemName.includes("SAD") ? true : false,
      frame_width: frameWidth && frameWidth * 1000,
      Frame1_Name: sgFrames,
      Rail1_Name: sgRails,
      SAA: systemName && systemName.includes("SAA") ? true : false,
      SAD: systemName && systemName.includes("SAD") ? true : false,
      InputType: "",
      FreeEnd: "PLACO",
      board_height: plateHeight * 1000,
      board_height_offset: 0,
      frame_length: plateLength,
    };
    return result;
  } catch (ex) {
    console.error(
      `Data is missing for solution (${solutionProduct.translation})`,
      ex
    );
    return null;
  }
};

export const productArticleLevelFilter = (attributes: any) => {
  // const priceList = find(attributes, {
  //   technicalName: "GFR-For price list",
  // });

  const placo100 = find(attributes, {
    technicalName: "GFR-To Offre 100% Placo",
  });

  const activePlaco = find(attributes, {
    technicalName: "GFR-Active / archived",
  });

  const pl100 = placo100
    ? placo100.values[0].value.toLowerCase() === "true"
    : false;
  // const prList = priceList
  //   ? priceList.values[0].value.toLowerCase() === "true"
  //   : false;
  const aclaco = activePlaco
    ? activePlaco.values[0].value.toLowerCase() === "actif"
    : false;

  return pl100 && aclaco;
};

export const packagedArticleLevelFilter = (attributes: any) => {
  // const priceList = find(attributes, {
  //   technicalName: "GFR-For price list",
  // });

  const placo100 = find(attributes, {
    technicalName: "GFR-To Offre 100% Placo",
  });

  const activePlaco = find(attributes, {
    technicalName: "GFR-Active / archived",
  });

  const openChannel = find(attributes, {
    technicalName: "GFR-Open Channel 11",
  });

  const pl100 = placo100
    ? placo100.values[0].value.toLowerCase() === "true"
    : false;
  // const prList = priceList
  //   ? priceList.values[0].value.toLowerCase() === "true"
  //   : false;
  const aclaco = activePlaco
    ? activePlaco.values[0].value.toLowerCase() === "actif"
    : false;
  const opChannel = openChannel
    ? openChannel.values[0].value.toLowerCase() === "true"
    : false;

  return pl100 && aclaco && opChannel;
};

export const productArticleLevelFilterCeiling = (attributes: any) => {
  // const priceList = find(attributes, {
  //   technicalName: "GFR-For price list",
  // });

  const onPlacoFr = find(attributes, {
    technicalName: "GFR-On placo.fr",
  });

  const activePlaco = find(attributes, {
    technicalName: "GFR-Active / archived",
  });

  const pl100 = onPlacoFr
    ? onPlacoFr.values[0].value.toLowerCase() === "true"
    : false;
  // const prList = priceList
  //   ? priceList.values[0].value.toLowerCase() === "true"
  //   : false;
  const aclaco = activePlaco
    ? activePlaco.values[0].value.toLowerCase() === "actif"
    : false;

  return pl100 && aclaco;
};

export const packagedArticleLevelFilterCeiling = (attributes: any) => {
  // const priceList = find(attributes, {
  //   technicalName: "GFR-For price list",
  // });

  const onPlacoFr = find(attributes, {
    technicalName: "GFR-On placo.fr",
  });

  const activePlaco = find(attributes, {
    technicalName: "GFR-Active / archived",
  });

  const openChannel = find(attributes, {
    technicalName: "GFR-Open Channel 11",
  });

  const pl100 = onPlacoFr
    ? onPlacoFr.values[0].value.toLowerCase() === "true"
    : false;
  // const prList = priceList
  //   ? priceList.values[0].value.toLowerCase() === "true"
  //   : false;
  const aclaco = activePlaco
    ? activePlaco.values[0].value.toLowerCase() === "actif"
    : false;

  const opChannel = openChannel
    ? openChannel.values[0].value.toLowerCase() === "true"
    : false;

  return pl100 && aclaco && opChannel;
};
