import { Action } from 'redux'
import { ProductDetailData, } from "../../../../../RevitJS/Types/BddTypes";

export const ADD_SYSTEM_TO_FAVORITE = "ADD_SYSTEM_TO_FAVORITE";
export const REMOVE_SYSTEM_FROM_FAVORITE = "REMOVE_SYSTEM_FROM_FAVORITE";
export const CHANGE_TYPE_ID = 'CHANGE_TYPE_ID'
export const LOAD_SYSTEMS_FAVORITES = 'LOAD_SYSTEMS_FAVORITES'
export const CLEAR_SYSTEMS_FAVORITES = "CLEAR_SYSTEMS_FAVORITES"

export interface FavoriteState {
  currentFavoriteSystemItems: ProductDetailData[],
  favoriteCount: number,
  data: {
    liningWallsDetails: ProductDetailData[],
    partitionsDetails: ProductDetailData[],
    ceilingsDetails: ProductDetailData[],
    gainesTechniquesDetails: ProductDetailData[]
  }
}

interface AddSystemToFavoriteAction extends Action {
  type: typeof ADD_SYSTEM_TO_FAVORITE
  favoriteSystemItem: ProductDetailData,
  currentSystemTypeID: number,
  systemTypeID: number
}

interface RemoveSystemFromFavoriteAction extends Action {
  type: typeof REMOVE_SYSTEM_FROM_FAVORITE
  index: number,
  currentSystemTypeID: number
  systemTypeID: number
}

interface ChangeSystemTypeAction extends Action {
  type: typeof CHANGE_TYPE_ID
  favoriteSystemItems: ProductDetailData[],
}

interface LoadSystemsFavoritesAction extends Action {
  type: typeof LOAD_SYSTEMS_FAVORITES
  data: any,
  favoriteCount: number,
  systemTypeID: number,
}

interface ClearSystemsFavoritesAction extends Action {
  type: typeof CLEAR_SYSTEMS_FAVORITES
}


export type FavoriteActionTypes = LoadSystemsFavoritesAction | AddSystemToFavoriteAction | RemoveSystemFromFavoriteAction | ChangeSystemTypeAction | ClearSystemsFavoritesAction

