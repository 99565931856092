import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "154175d1-2872-4b5c-b719-d7616f9ee2c8";

export const superposerTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const SuperposerRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const Superposer: Functionality = {
  Name: {
    English: "Superposer",
    Indonesian: "Superposer",
  },
  Trigger: superposerTrigger,
  ShortDesc: {
    English: "Modèle de base et calepinage",
    Indonesian: "Modèle de base et calepinage",
  },
  Id: functionalityId,
  Icon: "/Facade/common.png",
  RootComponent,
  ParentId: "0",
};
