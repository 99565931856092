import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Grid, Icon, Popup } from "semantic-ui-react";
import { SetMasterFrame, SetSelection } from "../../Store/Actions";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import wording from "../../Resources/wording.json";
import ReactHtmlParser from "react-html-parser";
import _, { concat, flattenDeep, map, pick } from "lodash";
import { FacadeStore } from "../../Store/Reducers";
import { CommonBufferType } from "../../Store/Types";
import { api } from "../../../../RevitJS/API";
import { selectedItemsManualSelection } from "../../../../RevitJS/Helpers";
import { dbStoreNameFacadeCalepinage, Routes } from "./root";
import { bimStorage } from "../../../../BIMStore";
import { SetModuleData } from "../../Store/Actions/moduleAction";
import { RevitMappedWall } from "../../../../Layout/types";
import { requestToLayoutCal } from "../../Api/layoutCalculator";
import { MyConfig } from "../../../../Helper";

const NewCalepinage = (props: { setRoute: (arg0: string) => void }) => {
  const reduxState = useSelector((state: FacadeStore) => state);
  const language: string = reduxState.language;
  const buffer = reduxState.buffer;
  const projectData = reduxState.projectData;
  const dispatch = useDispatch();

  const translation: any = wording;

  const handleParametersClick = () => {
    props.setRoute(Routes.PARAMETERS);
  };

  const handleOptionsClick = () => {
    props.setRoute(Routes.OPTIONS);
  };

  const handleContextFacadeClick = () => {
    props.setRoute(Routes.CONTEXT_DE_LA_FACADE);
  };

  const singleManualSelectionHandler = async () => {
    api.windowsHandler.hideWindow();
    let tree = await api.selection.manualSelection("wall", true);

    await api.windowsHandler.showWindow();
    let selectedItems = selectedItemsManualSelection(tree);
    if (selectedItems && selectedItems.length > 0) {
      dispatch(SetMasterFrame(selectedItems[0]));
      await onAutoSave();
    }
  };

  const multiManualSelectionHandler = async () => {
    api.windowsHandler.hideWindow();
    let tree = await api.selection.manualSelection("wall");

    await api.windowsHandler.showWindow();
    let selectedItems = selectedItemsManualSelection(tree);
    if (selectedItems && selectedItems.length > 0) {
      dispatch(SetSelection(selectedItems));
      await onAutoSave();
    }
  };

  const checkButtonDisable = (key: string): boolean => {
    if (key === "masterFrame") {
      return false;
    } else if (key === "selections") {
      return false;
    } else if (key === "parameters") {
      return !buffer.selections.status;
    } else if (key === "context") {
      return !buffer.parameteres.status;
    } else if (key === "options") {
      return !buffer.context.status;
    }
    return true;
  };

  const onClickAction = (key: string) => {
    if (key === "masterFrame") {
      singleManualSelectionHandler();
    } else if (key === "selections") {
      multiManualSelectionHandler();
    } else if (key === "parameters") {
      handleParametersClick();
    } else if (key === "context") {
      handleContextFacadeClick();
    } else if (key === "options") {
      handleOptionsClick();
    }
  };

  const onBackClicked = () => {
    props.setRoute(Routes.ROOT);
  };

  const onAutoSave = async () => {
    await bimStorage.setModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId,
      buffer.name,
      buffer.date,
      buffer.id,
      JSON.stringify(buffer)
    );

    let calepinageData = await bimStorage.listModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId
    );

    dispatch(SetModuleData(calepinageData));
  };

  const getStepsCompleted = (): number => {
    let count: number = 0;
    if (buffer.masterFrame.status) {
      count = count + 1;
    }

    if (buffer.selections.status) {
      count = count + 1;
    }

    if (buffer.parameteres.status) {
      count = count + 1;
    }

    if (buffer.context.status) {
      count = count + 1;
    }

    if (buffer.options.status) {
      count = count + 1;
    }

    return count;
  };

  const callLayoutCalculator = async () => {
    const cng = await MyConfig();
    const selectionIds = flattenDeep(
      map(reduxState.buffer.selections.data, "Ids")
    );
    const wallIds = concat(
      reduxState.buffer.masterFrame.data.Ids,
      selectionIds
    );
    const wallDetails: RevitMappedWall[] = await api.queries.getWallsData(
      wallIds
    );
    const facade = map(wallDetails, (wlDetail: RevitMappedWall) => {
      return pick(wlDetail, [
        "Height",
        "Id",
        "InnerContours",
        "LevelId",
        "LocationLine",
        "NormalVector",
        "OuterContour",
        "Width",
      ]);
    });
    const lengthParamters = {
      acl_max: reduxState.buffer.parameteres.data.maxLength,
      acl_min: reduxState.buffer.parameteres.data.minLength,
      min_acl_dist: reduxState.buffer.parameteres.data.minDist,
      x_grid: reduxState.buffer.parameteres.data.grid,
    };

    const requestData = {
      facades: facade,
      parameters: {
        contextCode: `${reduxState.buffer.context.data.left.checked};${reduxState.buffer.context.data.top.checked};${reduxState.buffer.context.data.right.checked};${reduxState.buffer.context.data.bottom.checked}`,
        lengthParameters: lengthParamters,
        masterId: reduxState.buffer.masterFrame.data.Ids[0],
        options: reduxState.buffer.options.data,
        spl: {
          splDown: 0,
          splUp: 0,
        },
        slab: {
          offset: 100,
          width: 225,
        },
        window: {
          offset: 45,
        },
      },
    };

    const responseFromLayout = await requestToLayoutCal(requestData, cng);

    //          await api.familyEditor.loadFamilies([
    //            {
    //              FamilyName: "STD",
    //              FamilyTypes: ["F4 STD"],
    //              Date: "2019_12_03_10_34_32",
    //              Version: "1.0.0.3",
    //            },
    //          ]);

    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_STD",
        FamilyTypes: ["F4_STD"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);

    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_STD 3D",
        FamilyTypes: ["F4_STD 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);

    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Lierne",
        FamilyTypes: ["F4_Lierne"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Lierne 3D",
        FamilyTypes: ["F4_Lierne 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_AC Bas Droite 3D",
        FamilyTypes: ["F4_AC Bas Droite 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_AC Bas Droite",
        FamilyTypes: ["F4_AC Bas Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_AC Bas Gauche 3D",
        FamilyTypes: ["F4_AC Bas Gauche 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_AC Bas Gauche",
        FamilyTypes: ["F4_AC Bas Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_AC Haut Gauche 3D",
        FamilyTypes: ["F4_AC Haut Gauche 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_AC Haut Gauche",
        FamilyTypes: ["F4_AC Haut Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACC Droite 3D",
        FamilyTypes: ["F4_ACC Droite 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACC Droite",
        FamilyTypes: ["F4_ACC Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACC Gauche 3D",
        FamilyTypes: ["F4_ACC Gauche 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACC Gauche",
        FamilyTypes: ["F4_ACC Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACL Bas 3D",
        FamilyTypes: ["F4_ACL Bas 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACL Bas",
        FamilyTypes: ["F4_ACL Bas"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACL Haut 3D",
        FamilyTypes: ["F4_ACL Haut 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_ACL Haut",
        FamilyTypes: ["F4_ACL Haut"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Calle 3D",
        FamilyTypes: ["F4_Calle 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Calle",
        FamilyTypes: ["F4_Calle"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Demi Platine F4 Droite 3D",
        FamilyTypes: ["F4_Demi Platine F4 Droite 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Demi Platine F4 Droite",
        FamilyTypes: ["F4_Demi Platine F4 Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Demi Platine F4 Gauche 3D",
        FamilyTypes: ["F4_Demi Platine F4 Gauche 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Demi Platine F4 Gauche",
        FamilyTypes: ["F4_Demi Platine F4 Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 3D Bas Droite",
        FamilyTypes: ["F4_Platine depart F4 3D Bas Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 3D Bas Gauche",
        FamilyTypes: ["F4_Platine depart F4 3D Bas Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 3D Haut Droite",
        FamilyTypes: ["F4_Platine depart F4 3D Haut Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 3D Haut Gauche",
        FamilyTypes: ["F4_Platine depart F4 3D Haut Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 Bas Droite 3D",
        FamilyTypes: ["F4_Platine depart F4 Bas Droite 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 Bas Droite",
        FamilyTypes: ["F4_Platine depart F4 Bas Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 Bas Gauche",
        FamilyTypes: ["F4_Platine depart F4 Bas Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 Haut Droite",
        FamilyTypes: ["F4_Platine depart F4 Haut Droite"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine depart F4 Haut Gauche",
        FamilyTypes: ["F4_Platine depart F4 Haut Gauche"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine F4 3D",
        FamilyTypes: ["F4_Platine F4 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_Platine F4",
        FamilyTypes: ["F4_Platine F4"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_SPL retourne",
        FamilyTypes: ["F4_SPL retourne"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_SPL",
        FamilyTypes: ["F4_SPL"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_STD 3D",
        FamilyTypes: ["F4_STD 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_STD retourne 3D",
        FamilyTypes: ["F4_STD retourne 3D"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_STD retourne",
        FamilyTypes: ["F4_STD retourne"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);
    await api.familyEditor.loadFamilies([
      {
        FamilyName: "F4_STD",
        FamilyTypes: ["F4_STD"],
        Date: "2019_12_03_10_34_32",
        Version: "1.0.0.3",
      },
    ], cng.REACT_APP_DOWNLOAD_FAMILY_URL_FACADE);

    //
    console.log(responseFromLayout.data);

    for (var facadetorender of responseFromLayout.data.facades) {
      if (facadetorender.STD.length) {
        console.log("Placing Families STD :" + facadetorender.STD.length);
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.STD
        );
      }
      if (facadetorender.STDFlipped.length) {
        console.log(
          "Placing Families STDFlipped :" + facadetorender.STDFlipped.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.STDFlipped
        );
      }
      if (facadetorender.lierne.length) {
        console.log("Placing Families lierne :" + facadetorender.lierne.length);
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.lierne
        );
      }
      if (facadetorender.ACCLeft.length) {
        console.log(
          "Placing Families ACCLeft :" + facadetorender.ACCLeft.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACCLeft
        );
      }
      if (facadetorender.ACCRight.length) {
        console.log(
          "Placing Families ACCRight :" + facadetorender.ACCRight.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACCRight
        );
      }
      if (facadetorender.ACTopLeft.length) {
        console.log(
          "Placing Families ACTopLeft :" + facadetorender.ACTopLeft.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACTopLeft
        );
      }
      if (facadetorender.ACBottomLeft.length) {
        console.log(
          "Placing Families ACBottomLeft :" + facadetorender.ACBottomLeft.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACBottomLeft
        );
      }
      if (facadetorender.ACTopRight.length) {
        console.log(
          "Placing Families ACTopRight :" + facadetorender.ACTopRight.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACTopRight
        );
      }
      if (facadetorender.ACBottomRight.length) {
        console.log(
          "Placing Families ACBottomRight :" +
          facadetorender.ACBottomRight.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACBottomRight
        );
      }
      if (facadetorender.ACLBottom.length) {
        console.log(
          "Placing Families ACLBottom :" + facadetorender.ACLBottom.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACLBottom
        );
      }
      if (facadetorender.ACLTop.length) {
        console.log(
          "Placing Families ACLBottom :" + facadetorender.ACLTop.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.ACLTop
        );
      }
      if (facadetorender.Calles.length) {
        console.log("Placing Families Calles :" + facadetorender.Calles.length);
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.Calles
        );
      }
      if (facadetorender.SPLs.length) {
        console.log("Placing Families SPLs :" + facadetorender.SPLs.length);
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.SPLs
        );
      }
      if (facadetorender.platineBas.length) {
        console.log(
          "Placing Families platineBas :" + facadetorender.platineBas.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.platineBas
        );
      }
      if (facadetorender.platineHaut.length) {
        console.log(
          "Placing Families platineHauts :" + facadetorender.platineHaut.length
        );
        await api.familyEditor.placeFamiliesOnFace(
          facadetorender.Id,
          facadetorender.NormalVector,
          facadetorender.Direction,
          facadetorender.platineHaut
        );
      }
    }
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={
            reduxState.config.REACT_APP_SERVERURL + "/Facade/calpinageTitle.png"
          }
          name={"Calepinage"}
          subheader=""
          config={reduxState.config}
        />

        <Container
          className="header-alignment subHeader green"
          style={{ marginTop: "1rem" }}
        >
          {getStepsCompleted() +
            "/" +
            5 +
            " " +
            ReactHtmlParser(translation.completesteps[language])}
        </Container>

        <Container
          className="grid-container"
          style={{
            paddingTop: 15,
            height: "calc(100vh - 195px)",
            // overflow: "auto",
          }}
        >
          <Grid>
            {_.map(buffer, (buf: CommonBufferType, key: any) => {
              if (
                key !== "name" &&
                key !== "id" &&
                key !== "date" &&
                key !== "time" &&
                key !== "revitView"
              ) {
                return (
                  <Steps
                    content={translation[buf.key][language]}
                    status={buf.status}
                    optional={buf.optional}
                    uniqueKey={buf.key}
                    checkDisable={checkButtonDisable}
                    onClickAction={onClickAction}
                    key={buf.key}
                    translation={translation}
                    language={language}
                  />
                );
              }
            })}
          </Grid>
        </Container>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span>
            <Button
              color="grey"
              content={translation.back[language]}
              onClick={() => {
                onBackClicked();
              }}
            ></Button>
          </span>
          <span>
            <Button
              color="green"
              content={"Calepiner"}
              onClick={() => callLayoutCalculator()}
            ></Button>
          </span>
        </div>
      </div>
    </>
  );
};

export default NewCalepinage;

const Steps = (props: {
  content: string;
  status: boolean;
  optional: boolean;
  uniqueKey: string;
  checkDisable: any;
  onClickAction: any;
  translation: any;
  language: string;
}) => {
  const isDisable = props.checkDisable(props.uniqueKey);

  return (
    <Grid.Row style={{ alignItems: "center" }}>
      <Grid.Column width="four" textAlign="right">
        <Icon
          aria-hidden="true"
          className="grey caret right big icon margin-10"
        ></Icon>
      </Grid.Column>
      <Grid.Column width="seven">
        <Button
          onClick={() => {
            props.onClickAction(props.uniqueKey);
          }}
          disabled={isDisable}
          primary
          size="large"
          className="margin-10 green"
          content={props.translation[props.uniqueKey][props.language]}
          fluid
        ></Button>
      </Grid.Column>
      <Grid.Column
        width="five"
        textAlign="left"
        className={props.optional ? "disabled-text" : " "}
      >
        <Popup
          content={
            props.status
              ? ReactHtmlParser(
                props.translation[props.uniqueKey][props.language] +
                " " +
                props.translation.completed[props.language]
              )
              : ReactHtmlParser(
                props.translation[props.uniqueKey][props.language] +
                " " +
                props.translation.notCompleted[props.language]
              )
          }
          position="top center"
          inverted
          size="mini"
          trigger={
            <>
              <Icon
                aria-hidden="true"
                className={
                  props.status
                    ? "sea-green check circle outline big icon margin-10"
                    : !props.optional
                      ? "red times circle outline big icon margin-10"
                      : "orange times circle outline big icon margin-10"
                }
              ></Icon>
              <i>{props.optional ? "Optionnel" : " "}</i>
            </>
          }
        />
      </Grid.Column>
    </Grid.Row>
  );
};
