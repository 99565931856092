import { useEffect, useState } from "react";
import * as DOMPurify from 'dompurify';

export function useSanitizedHtml(htmlContent: string): string {

    const [html, setHtml] = useState('');

    useEffect (() => {
        setHtml(getSanitizedHtmlString(htmlContent));
    }, [htmlContent]);

    return html;

}

export const getSanitizedHtmlString = (htmlContent: string): string => {
    return DOMPurify.sanitize(htmlContent, {ADD_ATTR: ['target']});
}
