import React from "react";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./components/root";

// generate boilerplate from https://www.uuidgenerator.net/version4 and put it here
export const functionalityId = "ca3bfd85-f3c8-421a-b0ae-d28509e45d9f";

export const drawTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    800,
    400,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

export const PamCanalisationRootComponent = () => {
  return <Root />;
};

export const familliesImport: Functionality = {
  Name: {
    French: "Import de familles",
    English: "Famillies import",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Import de familles depuis CSV",
    English: "Famillies import from CSV",
  },
  Id: functionalityId,
  Icon: "/pluginIcons/logo-placeholder.png",
  RootComponent: Root,
  ParentId: "0",
};
