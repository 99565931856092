import React from "react";
import { connect } from "react-redux";
import { Segment, Card, Input, Button } from "semantic-ui-react";
import { ProductDetailData, PIMLayoutAttributesAid } from "../../../../RevitJS/Types/BddTypes";
import { ElementsFilter, FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";
import { api } from "../../../../RevitJS/API";
import { PlacoCard } from "./PlacoCard";
import { DrawStore } from "../Reducers";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Filters } from "./Filters";
import { setSelectionBuffer, selectSystem, resetSelectionBuffer, search, applyFilters, resetFilters} from '../Actions/index';
import { Routes } from "./root";
import { SystemDetails } from "./SystemDetails";
import {ConfigurationView} from './ConfigurationView';


// import {
//   setMappingMode,
//   initFilteredElements,
//   scrolledBottom,
//   search,
//   setSearchedWord,
//   resetFilters,
//   resetWord,
//   resetSelectionBuffer,
//   setSelectedSystem
// } from "../Actions";
// import { wording } from "../Ressources/wording";

interface Props {
  filteredElements: ProductDetailData[];
  Icon: string;
  wording: {
    back: { [key: string]: string };
    validate: { [key: string]: string };
  };
  language: string;

  partitionWalls: ProductDetailData[];
  liningWalls: ProductDetailData[];

  displayedElements: ProductDetailData[];
  filters: { liningWalls: any[]; partitions: any[] } ;
  selectionBuffer: ProductDetailData | null;

  filterTypeEnabled: FilterTypeEnabled;
  activeRowIndex: number;
  searchedWord: string,
  layoutAttributesAid: PIMLayoutAttributesAid |null;

  // scrolledBottom: any;
  // search: any;
  // setFilterValue: any;
  setSelectionBuffer: any;
  selectSystem: any;
  setRoute: any;
  resetSelectionBuffer: any;
  search: any;
  applyFilters: any;
  resetFilters: any;
  config:any;
  // resetSelectionBuffer: any;
  // setRoute: any;
  // resetWord: any;
  // resetFilters: any;
  // setProductDetailName: any;
  // setDetailOid: any;

  //NEVER
  // showSystemDetail: any;
  // hideSystemDetails: any;
  // showLayoutDetails: any;
  // hideLayoutDetails: any
  
}

interface State {
  systemDetailsDisplayed: boolean;
  layoutDetailsDisplayed: boolean;
}

export class ProductsPage extends React.Component<Props, State> {
  private ref: React.RefObject<HTMLInputElement>;

  public static defaultProps = {
    language: "French",
    wording: {
      back: {
        French: "Retour",
        English: "Back"
      },
      validate: {
        French: "Valider",
        English: "Validate"
      }
    }
  };



  constructor(props: Props) {
    super(props);
    this.state = {
      systemDetailsDisplayed: false,
      layoutDetailsDisplayed: false
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    api.windowsHandler.resizeWindow(970, 500);
    if (!this.props.filteredElements.length) {
      // this.props.initFilteredElements();
    }
  }

  scrollHandler = (e: any) => {
    if (this.ref.current !== null)
      if (
        this.ref.current.offsetHeight + this.ref.current.scrollTop >=
        this.ref.current.scrollHeight
      ) {
        // this.props.scrolledBottom();
      }
  };

  inputHandler = (e: any, data: any) => {
    this.props.search(data.value);
    // this.props.search(data.value, "walls");
    // this.props.setSearchedWord(data.value);

    if (this.ref.current !== null) this.ref.current.scrollTop = 0;
  };

  selectionHandler = () => {
    // if (this.props.selectionBuffer) {
    //   let buff = this.props.selectionBuffer;
    //   this.props.setSelectedSystem(
    //     buff.index,
    //     buff.oid,
    //     buff.longName,
    //     buff.technicalName
    //   );
    // }
  };

  showSystemDetails= () =>{
    this.setState({
      systemDetailsDisplayed: true
    })
  }

  hideSystemDetails= () =>{
    this.setState({
      systemDetailsDisplayed: false
    })
  }

  showLayoutDetails= () =>{
    this.setState({
      layoutDetailsDisplayed: true
    })
  }

  hideLayoutDetails= () =>{
    this.setState({
      layoutDetailsDisplayed: false
    })
  }

  render() {
    const cards = () => {
      if (this.props.displayedElements.length) {
        return this.props.displayedElements.map((el, index) => (
          <PlacoCard
            image={el.thumbnailUrl}
            key={index}
            index={index}
            oid={el.oid}
            productName={el.translation}
            technicalName={el.technicalName}
            setSelectionBuffer={this.props.setSelectionBuffer}
            system={el}
            activeRowIndex={this.props.activeRowIndex}
            selectionBuffer={this.props.selectionBuffer}
            // setProductDetailName={this.props.setProductDetailName}
            // setDetailOid={this.props.setDetailOid}
            showSystemDetails={this.showSystemDetails}
            showLayoutDetails={this.showLayoutDetails}
            config={this.props.config}
          />
        ));
      }

      return null;
    };

    const currentFilters = this.props.filterTypeEnabled === "liningWalls" ? this.props.filters.liningWalls : this.props.filters.partitions;

    return (
      <div style={{ margin: 0, overflow: "auto" }}>
        <FunctionalityHeader Icon={this.props.Icon} name="Selection" />
        <ConfigurationView
            layoutDetailsDisplayed={this.state.layoutDetailsDisplayed}
            hideLayoutDetails={this.hideLayoutDetails}
            selectionBuffer={this.props.selectionBuffer}
            layoutAttributesAid={this.props.layoutAttributesAid}
            // productDetailName={this.props.productDetailName}
         />
        <SystemDetails
          // detailOid={this.props.detailOid}
          selectionBuffer={this.props.selectionBuffer}
          systemDetailsDisplayed={this.state.systemDetailsDisplayed}
          // productDetailName={this.props.productDetailName}
          // partitionsDetails={this.props.partitionsDetails}
          filters={currentFilters}
          hideSystemDetails={this.hideSystemDetails}
          language={this.props.language}
          
          // hideSystemDetails={this.props.hideSystemDetails}
        />
        <Segment
          style={{
            backgroundColor: "none",
            height: 340,
            marginTop: 0,
            marginLeft: 10,
            marginRight: 10
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "27%",
                minWidth: "200px",
                backgroundColor: "none",
                height: "320px",
                minHeight: 50,
                overflow: "auto"
              }}
            >
              <Filters
                // setSelectionBuffer={this.props.setSelectionBuffer}
                // resetSelectionBuffer={this.props.resetSelectionBuffer}
                // partitions={this.props.partitions}
                filters={currentFilters}
                applyFilters = {this.props.applyFilters}
                resetFilters = {this.props.resetFilters}
                // setFilterValue={this.props.setFilterValue}
                // resetFilters={this.props.resetFilters}
                // setProductDetailName={this.props.setProductDetailName}
                // setDetailOid={this.props.setDetailOid}
              />
            </div>
            <div style={{ width: "73%" }}>
              <div
                style={{
                  width: "100",
                  backgroundColor: "none",
                  height: "30px",
                  minHeight: 40,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div style={{ backgroundColor: "none", display: "flex" }}>
                  <div>
                    <Input
                      icon="search"
                      placeholder="Rechercher..."
                      onChange={this.inputHandler}
                      value={this.props.searchedWord}
              
                    />
                  </div>
                  <div style={{ marginLeft: 30, paddingTop: 10 }}>
                    {this.props.filteredElements.length} systèmes trouvés
                  </div>
                </div>
              </div>
              <div
                onScroll={this.scrollHandler}
                ref={this.ref}
                style={{
                  width: "100",
                  backgroundColor: "none",
                  height: "280px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  overflow: "auto",
                  padding: 10
                }}
              >
                <Card.Group centered>{cards()}</Card.Group>
              </div>
            </div>
          </div>
        </Segment>

        <div
          style={{
            width: "100",
            backgroundColor: "none",
            minHeight: 0
          }}
        >
          <Segment basic style={{ marginBottom: 0 }}>
            <Button
              floated="left"
              primary
              onClick={e => {
                this.props.setRoute(Routes.MAPPING);
                this.props.resetFilters();
                // this.props.resetWord();
                // this.props.resetSelectionBuffer();
              }}
            >
              {this.props.wording.back[this.props.language]}
            </Button>
            <Button
              floated="right"
              primary
              disabled={this.props.selectionBuffer === null}
              onClick={e => {
                if(this.props.selectionBuffer !== null){
                  this.props.selectSystem(this.props.activeRowIndex, this.props.selectionBuffer);
                  this.props.setRoute(Routes.MAPPING);
                  this.props.resetSelectionBuffer();
                }
                
                
                // this.props.resetFilters();
                // this.props.resetWord();
                // //   this.selectionHandler();
                // this.props.resetSelectionBuffer();
              }}
            >
              {this.props.wording.validate[this.props.language]}
            </Button>
          </Segment>
        </div>
        <br />
        <div></div>
      </div>
    );
  }
}

// ProductsPage.defaultProps = {
//   wording,
//   language: "French"
// };

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    partitionWalls: state.placoData.partitionWalls.systemsDetails,
    liningWalls: state.placoData.liningWalls.systemsDetails,
    filters: state.filters,
    selectionBuffer: state.selectionBuffer,
    filterTypeEnabled: state.filterTypeEnabled,
    filteredElements: state.filteredElements,
    displayedElements: state.displayedElements,
    Icon: state.functionalityIcon,
    language: state.language,
    activeRowIndex: state.activeMappingRowIndex as number,
    setRoute: ownProps.setRoute,
    searchedWord: state.searchedWord,
    layoutAttributesAid: state.layoutAttributesAid,
    config: state.config
  };
};



export default connect(mapStateToProps, {
  setSelectionBuffer,
  selectSystem,
  resetSelectionBuffer,
  search,
  applyFilters,
  resetFilters
  // setMappingMode,
  // initFilteredElements,
  // scrolledBottom,
  // search,
  // setSearchedWord,
  // resetFilters,
  // resetWord,
  // resetSelectionBuffer,
  // setSelectedSystem
})(ProductsPage);
