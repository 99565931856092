import React, { useState } from "react";
import { HomeTableHeader } from "./HomeTableHeader";
import { HomeTableBody } from "./HomeTableBody";
import { forEach } from "lodash";
import { Modal, Button } from "semantic-ui-react";
import { getDateToCompare } from "../../../../Utils";

const style = {
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200)",
    height: "calc(100vh - 195px)",
    overflowY: "auto" as const,
  },
};

interface Props {
  data: any;
  onColorChanged: any;
  onRowEdit: any;
  onRowDuplicate: any;
  onRowDelete: any;
  editId: any;
  editName: any;
  setEditName: any;
  editSelectionName: any;
  eyeHandler: any;
  updateSelectionname: any;
  errorMessage: any;
  setEditedNameValue: any;
}

export const HomeTable = (props: Props) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);

  const onRowDeleteIconClick = (selectionId: any) => {
    setDeleteId(selectionId);
    setOpenDeleteModal(true);
  };

  const onDeleteYes = () => {
    if (deleteId) {
      props.onRowDelete(deleteId);
      setOpenDeleteModal(false);
    }
  };

  if (props.data.length === 0) {
    return (
      <>
        <HomeTableHeader />
        <div style={style.tableSegment_body}></div>
      </>
    );
  }
  return (
    <>
      <HomeTableHeader />
      <div style={style.tableSegment_body}>
        {props.data
        .slice()
        .sort((a:any, b:any) => getDateToCompare(b.Date) - getDateToCompare(a.Date))
        .map((dat: any, key: any) => {
          return (
            <HomeTableBody
              data={dat}
              onColorChanged={props.onColorChanged}
              onRowEdit={props.onRowEdit}
              onRowDuplicate={props.onRowDuplicate}
              onRowDeleteIconClick={onRowDeleteIconClick}
              editId={props.editId}
              editName={props.editName}
              setEditName={props.setEditName}
              editSelectionName={props.editSelectionName}
              eyeHandler={props.eyeHandler}
              updateSelectionname={props.updateSelectionname}
              errorMessage={props.errorMessage}
              setEditedNameValue={props.setEditedNameValue}
              key={key}
            />
          );
        })}
      </div>
      <Modal size="tiny" open={openDeleteModal}>
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            {/* <p> Vous allez supprimer une sélection. Ça pourra impacter une
              configuration de calepinage.</p> */}
            <p>Are you sure you want to delete this?</p>
            <Button color="orange" onClick={() => setOpenDeleteModal(false)}>
              No
            </Button>
            <Button color="blue" onClick={(index) => onDeleteYes()}>
              Yes
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
