import React, { Component } from "react";
import { Container, Button } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
    setSelectionTree,
    initGroupSelector,
    initMappingTableManualSelection,
} from "../Actions";
import { Routes } from "./root";
import { selectedItemsManualSelection } from "../../../../RevitJS/Helpers";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props {
    Icon: string;
    name: string;
    language: string;
    setRoute: any;
    setSelectionTree: any;
    initGroupSelector: any;
    initMappingTableManualSelection: any;
    config:any;
}

interface State { }

export class SelectionLanding extends Component<Props & WithTranslation, State> {
    state = {};

    componentDidMount = () => {
        api.windowsHandler.resizeWindow(600, 200);
    };

    manualSelectionHandler = async () => {
        api.windowsHandler.hideWindow();
        let tree = await api.selection.manualSelection("ceiling");
        if(tree.Tree.length > 0){
        let selectedItems = selectedItemsManualSelection(tree);
        this.props.initMappingTableManualSelection(selectedItems);
        this.props.setRoute(Routes.MAPPING);
        await api.windowsHandler.resizeWindow(1010,  510);
        await api.windowsHandler.showWindow();
        }
       else{
        await api.windowsHandler.resizeWindow(600,  200);
        await api.windowsHandler.showWindow();
       }
    };

    groupSelectionHandler = async () => {
        let tree = await api.selection.elementsByLevelAndType("ceiling");
        this.props.initGroupSelector(tree);
        await api.windowsHandler.resizeWindow(900,  507);
        this.props.setRoute(Routes.GROUP_SELECTION);
    };

    render() {
        return (
            <div id="draw2-selectionLanding-container">
                <FunctionalityHeader
                    name={this.props.t('selection')}
                    color="grey"
                    Icon={this.props.config.REACT_APP_SERVERURL + "/plugin_new_logo/ecophon-new-logo_icon.png"}
                    height={40}
                    width={40}
                />
                <Container textAlign="center" style={{ margin:"auto"}}>
                    <Button
                        color="yellow"
                        onClick={this.manualSelectionHandler}
                        size="big"
                        style={{ marginRight: "5%" }}
                    >
                        {this.props.t('manualSelection')}
                    </Button>
                    <Button
                        color="yellow"
                        onClick={this.groupSelectionHandler}
                        size="big"
                        style={{ marginLeft: "5%" }}
                    >
                        {this.props.t('groupSelection')}
                    </Button>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        Icon: state.functionalityIcon,
        language: state.language,
        name: ownProps.name,
        wording: ownProps.wording,
        setRoute: ownProps.setRoute,
        config:state.config
    };
};

export default connect(mapStateToProps, {
    setSelectionTree,
    initGroupSelector,
    initMappingTableManualSelection,
})(withTranslation(["clt"])(SelectionLanding));
