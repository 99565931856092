import { concat, remove } from "lodash";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { SaveProject } from "../../CommonModules/PopUpModals/SaveProject";
import { WordingType } from "../../CommonModules/Selection/Type";
import {
  createSelectionNew,
  initMappingTableManualSelection,
  loadSelections,
  savePlacoData,
  SetElementParametersToStore,
  setGroupTrees,
  setprojectData,
  setSelectorType,
} from "../Actions";
import { Language, Selection } from "../Actions/types";
import { clearTree, filterTree } from "../Helpers";
import { SelectionStore } from "../Reducers";
import { FunctionalityHeader } from "./FunctionalityHeader";
import NoSelectionSaved from "./NoSelectionSaved";
import { Routes } from "./root";
import SavedSelections from "./SavedSelections";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";
import { removeCloseWindow } from "../../CommonModules/Helpers/sessionRemoveCloseWindow";

type FunctionProps = InjectedCounterProps & {
  route: any;
  setRoute: any;
  module: any;
  setSelectionId: any;
  selectionPar: any;
  onError: any;
};

const wording: WordingType = {
  name: {
    French: "Sélection",
    English: "Selection",
  },
  saveSelectionList: {
    French: "Liste des sélection de systèmes sauvegardées",
    English: "Saved selections list",
  },
  selectionName: {
    French: "Nom de la sélection",
    English: "Selection name",
  },
  editDate: {
    French: "Date de modification",
    English: "Edit date",
  },
  noSelectionSaved: {
    French: "Aucune sélection sauvegardée",
    English: "No selection saved",
  },
  newManualSelection: {
    French: "Manuelle",
    English: "Manual",
  },
  newGroupSelection: {
    French: "Groupée",
    English: "Group",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
  invalidSelectionName: {
    French: "Nom de sélection invalide",
    English: "Invalid selection name",
  },
  levels: {
    French: "Niveaux",
    English: "Levels",
  },
  zone: {
    French: "Zone",
    English: "Zone",
  },
  newSelectionBtn: {
    French: "Nouvelle Sélection",
    English: "New Selection",
  },
  newZoneBtn: {
    French: "Nouvelle Zone",
    English: "New Zone",
  },
  type: {
    French: "Type",
    English: "Type",
  },
  cancel: {
    French: "Annuler",
    English: "Cancel",
  },
};

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0em",
  },
  height_435: {
    height: "435px",
  },
  height_486: {
    height: "calc(100vh - 50px)",
  },
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_row: {
    padding: 5,
    height: 30,
    backgroundColor: "rgb(33, 133, 208)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "40%",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "6%",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "24%",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    fontWeight: 700,
    width: "17%",
    color: "black",
  },
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200",
    height: "calc(100vh - 195px)",
    overflowY: "scroll" as const,
  },
};

const TableHeader = ({
  wording,
  applicationLanguage,
}: {
  wording: WordingType;
  applicationLanguage: Language;
}) => {
  return (
    <div style={style.tableSegment_Header}>
      <div style={style.header_row}>
        <div>{wording.saveSelectionList[applicationLanguage]}</div>
      </div>
      <div style={style.header_secondary_row}>
        <div style={style.header_column_1}>
          {wording.selectionName[applicationLanguage]}
        </div>
        <div style={style.header_column_2}>
          {wording.levels[applicationLanguage]}
        </div>
        <div style={style.header_column_3}>
          {wording.type[applicationLanguage]}
        </div>
        <div style={style.header_column_4}>
          {wording.editDate[applicationLanguage]}
        </div>
      </div>
    </div>
  );
};

export const SelectionList = (props: FunctionProps) => {
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );
  const dispatch = useDispatch();
  const [confirmModal, setconfirmModal] = useState(false);
  const [selectionID, setSelectionID] = useState<string[]>([]);
  const [isZone, setIsZone] = useState<boolean>(false);
  const [groupManualPopup, setGroupManualPopup] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<"Manual" | "Group">(
    "Manual"
  );
  const [openNamePopup, setOpenNamePopup] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<{
    status: boolean;
    message: string;
  }>({
    status: false,
    message: "",
  });
  const [validateLoading, setValidateLoading] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function loadSavedSelection() {
      setLoading(true);
      let projectData: initProjectData = await api.queries.getSetProjectData();
      const selectionList = await bimStorage.getItem(storageKey.PLACO_PRODUCTS);
      if (projectData.ProjectId === "") {
        setconfirmModal(!confirmModal);
      } else {
        let savedSelections = await bimStorage.getItem(
          storageKey.PLACOSELECTIONS
        );

        if (
          (selectionList !== 0 || selectionList !== undefined) &&
          !reduxState.placoData.available
        ) {
          let attributes =
            selectionList?.context?.attributeDependencies[0].attributes;
          dispatch(setprojectData(projectData));
          dispatch(
            savePlacoData(
              reduxState,
              attributes,
              selectionList?.objects,
              attributes
            )
          );

          let parsedSelections = savedSelections as {
            [key: string]: Selection<PlacoOptions>;
          };
          dispatch(loadSelections(parsedSelections));
        }
      }
      setLoading(false);
    }
    loadSavedSelection();

    // required for auto fill of solution
    async function loadElementParameters() {
      let elementParameters =
        await api.queries.getCustomParametersFromElementType();

      dispatch(
        SetElementParametersToStore(
          JSON.parse(JSON.stringify(elementParameters))
        )
      );
    }
    loadElementParameters();
    if (localStorage.getItem("selectionStep")) {
      localStorage.removeItem("selectionStep");
    }
    removeCloseWindow();
    // if (localStorage.getItem("min") === "0:0") {
    //   localStorage.removeItem("min");
    //   if (localStorage.getItem("load") === "false") {
    //     setTimeout(() => {
    //       api.windowsHandler.closeWindow();
    //     }, 1000);
    //   }
    //   localStorage.setItem("load", "false");
    // }
  }, []);

  const onYesAction = () => {
    setconfirmModal(!confirmModal);
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };

  const headerNameDisplay = (module: string, selectionname: string): string => {
    if (module.toLowerCase() !== "selection") {
      return module + " > " + selectionname;
    }
    return selectionname;
  };

  const crudSelectionId = (add: boolean, id: string, group: boolean) => {
    let selectionArray: string[] = selectionID;
    if (group) {
      if (add) {
        selectionArray = concat(selectionArray, [id]);
      } else {
        remove(selectionArray, function (d) {
          return d === id;
        });
      }
    } else {
      selectionArray = [id];
    }
    setSelectionID(selectionArray);
  };

  const onModalCloseIcon = () => {
    if (localStorage.getItem("selectionStep")) {
      localStorage.removeItem("selectionStep");
    }
    setGroupManualPopup(false);
  };

  const handleInput = (data: any) => {
    const currentValue = data.value.trim().toLowerCase();

    if (data.value.length >= 5) {
      const isExist = Object.values(reduxState.selections).some((e: any) => {
        return (
          e.Name.toLowerCase() === currentValue &&
          e.RevitView === reduxState.projectData.ProjectId
        );
      });

      if (!isExist) {
        setNameError({
          status: false,
          message: "",
        });
      }
    } else {
      setNameError({
        status: false,
        message: "",
      });
    }
    setName(data.value);
  };

  const checkforUniqueSelectionName = async (
    name: string,
    revitViewName: string,
    config: any
  ) => {
    let savedSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);

    if (savedSelections) {
      let selections = savedSelections as {
        [key: string]: Selection<PlacoOptions>;
      };

      // let filteredSelection = _.filter(selections, {
      //   Name: name,
      //   RevitView: revitViewName,
      // });

      const isUnique = Object.values(selections).some((e: any) => {
        return (
          e.Name.trim().toLowerCase() === name.trim().toLowerCase() &&
          e.RevitView === revitViewName
        );
      });

      return !isUnique;
    }

    return true;
  };

  const handleSelectionName = async (hprops: {
    name: string;
    revitViewName: string;
    setNameError: any;
    reduxState: SelectionStore;
    selectionType: string;
    setOpenNamePopup: any;
    isZone: boolean;
  }) => {
    const {
      name,
      setNameError,
      revitViewName,
      selectionType,
      setOpenNamePopup,
      isZone,
      reduxState,
    } = hprops;

    //If name length is not greater than or equeal to 5 then raise error
    // if (name.length < 5) {
    //   setNameError({
    //     status: true,
    //     message: "Un nom de sélection doit contenir au moins 5 caractères",
    //   });
    // }
    if (name.length >= 5) {
      const isExist = Object.values(reduxState.selections).some((e: any) => {
        return (
          e.Name.trim().toLowerCase() === name.trim() &&
          e.RevitView === reduxState.projectData.ProjectId
        );
      });

      if (isExist) {
        setNameError({
          status: true,
          message: "Une autre sélection existe déjà sous ce nom",
        });
      } else {
        setNameError({
          status: false,
          message: "",
        });
      }
    } else {
      setNameError({
        status: true,
        message: "Un nom de sélection doit contenir au moins 5 caractères",
      });
    }
    const uniquename = await checkforUniqueSelectionName(
      name,
      revitViewName,
      reduxState.config
    );
    // If given name is not unique raise error
    if (!uniquename) {
      setNameError({
        status: true,
        message: "Une autre sélection existe déjà sous ce nom",
      });
    }
    // const isSuperPoser = await bimStorage.getItem(storageKey.IS_SUPERPOSER_ENABLED);

    if (uniquename && name.length >= 5) {
      if (selectionType === "Manual") {
        // if(isSuperPoser && isSuperPoser === true){
        //   await overLapElements();
        //   bimStorage.setItem(storageKey.IS_SUPERPOSER_ENABLED, JSON.stringify(false));
        // }

        api.windowsHandler.hideWindow();
        let selectionTree = await api.selection.manualSelection("wall&ceiling");

        if (selectionTree.Tree.length > 0) {
          dispatch(createSelectionNew(name.trim(), revitViewName, isZone));
          let wallTree = clearTree(filterTree(selectionTree, "wall"));
          let ceilingTree = clearTree(filterTree(selectionTree, "ceiling"));

          if (wallTree && wallTree.Tree && wallTree.Tree.length > 0) {
            dispatch(setSelectorType("wall"));
          } else if (
            ceilingTree &&
            ceilingTree.Tree &&
            ceilingTree.Tree.length > 0
          ) {
            dispatch(setSelectorType("ceiling"));
          }

          dispatch(setGroupTrees(wallTree, ceilingTree));
          dispatch(initMappingTableManualSelection());
          props.setRoute(Routes.MAPPING);
        } else {
          setOpenNamePopup(false);
        }
        api.windowsHandler.showWindow();
      } else if (selectionType === "Group") {
        dispatch(createSelectionNew(name.trim(), revitViewName, isZone));
        let wallTree = await api.selection.elementsByLevelAndType("wall");

        let ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
        dispatch(setGroupTrees(wallTree, ceilingTree));
        props.setRoute(Routes.GROUP_SELECTION);
      }
    }
  };

  if (reduxState.projectData.ProjectId) {
    if (Object.keys(reduxState.selections).length > 0) {
      const CheckIfSelectionExistInCurrentProject = Object.values(
        reduxState.selections
      ).some((e: any) => {
        return e.RevitView === reduxState.projectData.ProjectId;
      });

      if (CheckIfSelectionExistInCurrentProject) {
        return (
          <div style={{ height: "100%" }}>
            <SaveProject
              dontUpdateSelections={true}
              toggleModal={() => setconfirmModal(!confirmModal)}
              confirmModal={confirmModal}
              onYesAction={onYesAction}
            />
            <FunctionalityHeader
              Icon={reduxState.functionalityIcon}
              name={headerNameDisplay(
                props.module,
                wording.name[reduxState.language]
              )}
            />

            <Grid
              columns={1}
              stackable
              style={
                props.module !== "selection"
                  ? {
                      ...style.tableSegment,
                      ...style.height_435,
                      marginTop: "0em",
                    }
                  : {
                      ...style.tableSegment,
                      ...style.height_486,
                      marginTop: "0em",
                    }
              }
            >
              <Grid.Column>
                <TableHeader
                  wording={wording}
                  applicationLanguage={reduxState.language}
                />
                <div style={style.tableSegment_body}>
                  <SavedSelections
                    {...props}
                    crudSelectionId={crudSelectionId}
                    revitViewName={reduxState.projectData.ProjectId}
                    onError={props.onError}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <div>
                    <Button
                      primary
                      onClick={() => {
                        localStorage.setItem("selectionStep", "true");
                        setIsZone(false);
                        setGroupManualPopup(true);
                      }}
                    >
                      {" "}
                      {wording.newSelectionBtn[reduxState.language]}
                    </Button>

                    <Button
                      secondary
                      onClick={() => {
                        localStorage.setItem("selectionStep", "true");
                        setIsZone(true);
                        setGroupManualPopup(true);
                      }}
                    >
                      {wording.newZoneBtn[reduxState.language]}
                    </Button>
                  </div>
                  <Modal
                    size="tiny"
                    open={groupManualPopup}
                    dimmer="blurring"
                    className="modal-close"
                    closeIcon
                    onClose={() => onModalCloseIcon()}
                  >
                    <Modal.Content>
                      <Grid columns={2} padded={true}>
                        <Grid.Row style={{ padding: "2.5rem 0.5rem 1.5rem" }}>
                          <Grid.Column>
                            <Button
                              style={{ minHeight: 80 }}
                              fluid
                              color="blue"
                              onClick={() => {
                                setSelectionType("Manual");
                                setGroupManualPopup(false);
                                setOpenNamePopup(true);
                              }}
                            >
                              {wording.newManualSelection[reduxState.language]}
                            </Button>
                          </Grid.Column>
                          <Grid.Column>
                            <Button
                              style={{ minHeight: 80 }}
                              fluid
                              color="blue"
                              onClick={() => {
                                setSelectionType("Group");
                                dispatch(setSelectorType("wall"));
                                setGroupManualPopup(false);
                                setOpenNamePopup(true);
                              }}
                            >
                              {wording.newGroupSelection[reduxState.language]}
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Modal.Content>
                  </Modal>
                </div>
              </Grid.Column>
            </Grid>
            <Modal
              onClose={() => setOpenNamePopup(false)}
              size="mini"
              dimmer="blurring"
              inverted="true"
              open={openNamePopup}
            >
              <Modal.Header
                style={{
                  // height: 30,
                  PaddingTop: 10,
                  backgroundColor: "#1678c2",
                  color: "white",
                  textAlign: "center",
                  padding: 8,
                }}
              >
                {wording.selectionName[reduxState.language]}
              </Modal.Header>
              <Modal.Actions style={{ backgroundColor: "rgb(221, 231, 246)" }}>
                <Form>
                  <Form.Input
                    autoFocus
                    placeholder="Texte de 5 caractères minimum "
                    fluid
                    name="newSelectionName"
                    onChange={(event, data) => handleInput(data)}
                  />
                </Form>
                {nameError.status && (
                  <Message
                    style={{ textAlign: "left" }}
                    error
                    content={nameError.message}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    paddingTop: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    color="orange"
                    onClick={() => {
                      setOpenNamePopup(false);
                      setName("");
                      setNameError({ status: false, message: "" });
                      setSelectionType("Manual");
                    }}
                  >
                    {wording.cancel[reduxState.language]}
                    {/* {wording.back[applicationLanguage]} */}
                  </Button>
                  <Button
                    disabled={
                      name.trim().length < 5 || nameError.status === true
                    }
                    type="submit"
                    primary
                    onClick={async () => {
                      setValidateLoading(true);
                      await handleSelectionName({
                        name,
                        revitViewName: reduxState.projectData.ProjectId,
                        setNameError,
                        reduxState,
                        selectionType,
                        setOpenNamePopup,
                        isZone,
                      });
                      setValidateLoading(false);
                    }}
                  >
                    {wording.validate[reduxState.language]}
                    {validateLoading === true ? (
                      <div
                        style={{
                          float: "right",
                          marginLeft: "5px",
                        }}
                      >
                        <Oval
                          color="#00BFFF" //3 secs
                          height={16}
                          width={16}
                        />{" "}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Button>
                </div>
              </Modal.Actions>
            </Modal>
          </div>
        );
      }
    }
  }

  if (isLoading) {
    return (
      <Dimmer active page>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div>
      <div style={{ height: "100%" }}>
        <SaveProject
          dontUpdateSelections={true}
          toggleModal={() => setconfirmModal(!confirmModal)}
          confirmModal={confirmModal}
          onYesAction={onYesAction}
        />
        <FunctionalityHeader
          Icon={reduxState.functionalityIcon}
          name={headerNameDisplay(
            props.module,
            wording.name[reduxState.language]
          )}
        />

        <Grid
          columns={1}
          stackable
          style={
            props.module !== "selection"
              ? { ...style.tableSegment, ...style.height_435, marginTop: "0em" }
              : { ...style.tableSegment, ...style.height_486, marginTop: "0em" }
          }
        >
          <Grid.Column>
            <TableHeader
              wording={wording}
              applicationLanguage={reduxState.language}
            />
            <div style={style.tableSegment_body}>
              <NoSelectionSaved language={reduxState.language} />
            </div>
            <div style={{ marginTop: 12 }}>
              <div>
                <Button
                  primary
                  onClick={() => {
                    localStorage.setItem("selectionStep", "true");
                    setIsZone(false);
                    setGroupManualPopup(true);
                  }}
                >
                  {" "}
                  {wording.newSelectionBtn[reduxState.language]}
                </Button>

                <Button
                  secondary
                  onClick={() => {
                    localStorage.setItem("selectionStep", "true");
                    setIsZone(true);
                    setGroupManualPopup(true);
                  }}
                >
                  {wording.newZoneBtn[reduxState.language]}
                </Button>
              </div>
              <Modal
                size="tiny"
                open={groupManualPopup}
                dimmer="blurring"
                className="modal-close"
                closeIcon
                onClose={() => onModalCloseIcon()}
              >
                <Modal.Content>
                  <Grid columns={2} padded={true}>
                    <Grid.Row style={{ padding: "2.5rem 0.5rem 1.5rem" }}>
                      <Grid.Column>
                        <Button
                          style={{ minHeight: 80 }}
                          fluid
                          color="blue"
                          onClick={() => {
                            setSelectionType("Manual");
                            setGroupManualPopup(false);
                            setOpenNamePopup(true);
                          }}
                        >
                          {wording.newManualSelection[reduxState.language]}
                        </Button>
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          style={{ minHeight: 80 }}
                          fluid
                          color="blue"
                          onClick={() => {
                            setSelectionType("Group");
                            dispatch(setSelectorType("wall"));
                            setGroupManualPopup(false);
                            setOpenNamePopup(true);
                          }}
                        >
                          {wording.newGroupSelection[reduxState.language]}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
              </Modal>
              <Modal
                onClose={() => setOpenNamePopup(false)}
                size="mini"
                dimmer="blurring"
                inverted="true"
                open={openNamePopup}
              >
                <Modal.Header
                  style={{
                    // height: 30,
                    PaddingTop: 10,
                    backgroundColor: "#1678c2",
                    color: "white",
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {wording.selectionName[reduxState.language]}
                </Modal.Header>
                <Modal.Actions
                  style={{ backgroundColor: "rgb(221, 231, 246)" }}
                >
                  <Form>
                    <Form.Input
                      autoFocus
                      placeholder="Texte de 5 caractères minimum "
                      fluid
                      name="newSelectionName"
                      onChange={(event, data) => handleInput(data)}
                    />
                  </Form>
                  {nameError.status && (
                    <Message
                      style={{ textAlign: "left" }}
                      error
                      content={nameError.message}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      paddingTop: 20,
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="orange"
                      onClick={() => {
                        setOpenNamePopup(false);
                        setName("");
                        setNameError({ status: false, message: "" });
                        setSelectionType("Manual");
                      }}
                    >
                      {wording.cancel[reduxState.language]}
                      {/* {wording.back[applicationLanguage]} */}
                    </Button>
                    <Button
                      disabled={
                        name.trim().length < 5 || nameError.status === true
                      }
                      type="submit"
                      primary
                      onClick={async () => {
                        setValidateLoading(true);
                        await handleSelectionName({
                          name,
                          revitViewName: reduxState.projectData.ProjectId,
                          setNameError,
                          reduxState,
                          selectionType,
                          setOpenNamePopup,
                          isZone,
                        });
                        setValidateLoading(false);
                      }}
                    >
                      {wording.validate[reduxState.language]}
                      {validateLoading === true ? (
                        <div
                          style={{
                            float: "right",
                            marginLeft: "5px",
                          }}
                        >
                          <Oval
                            color="#00BFFF" //3 secs
                            height={16}
                            width={16}
                          />{" "}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Button>
                  </div>
                </Modal.Actions>
              </Modal>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};
