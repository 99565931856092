import { ThunkAction } from "redux-thunk";
import { setPluginDataToDB } from "../../../../Helper";
import { SelectionStore } from "../../Selection/Reducers";
import * as type from "./types";
import { InitLanguageAction, INIT_LANGUAGE } from "./types";

export const setReparageSelectionScreen = (value: boolean) => {
  return {
    type: type.SET_METRE_SELECTION,
    value,
  };
};

export const setReperageSelectionType = (value: boolean) => {
  return {
    type: type.SET_MODULE_SELECTION,
    value,
  };
};

export const saveReperageBuffer = (): type.SaveReperageBuffer => {
  return {
    type: type.SAVE_REPERAGE_BUFFER,
  };
};

export const setReperage =
  (
    reperageData: any
  ): ThunkAction<void, SelectionStore, unknown, type.SetReperage> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: type.SET_REPERAGE,
      reperageData,
    });
  };

export const setRevitSetup =
  (revitSetupData: any): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: type.SET_REVIT_SETUP,
      revitSetupData,
    });
  };

const stringLitArray = <L extends string>(arr: L[]) => arr;
//const language = stringLitArray(["French", "English", "German", "Italian"]);
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, SelectionStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "French" as Language; //await api.queries.getRevitLanguage() as Language;
      setPluginDataToDB("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };
