import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../../Selection/Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";
import { setPluginDataToDB } from "../../../../Helper";

interface Props {}

export enum Routes {
  ROOT = "PROJECT_INFO_FORM",
  SUPPLIMENTARY = "SUPPLIMENTARY_FORM",
  TECHNICAL_LANDING = "TECHNICAL_LANDING",
}

interface State {
  route: Routes;
}

export class ProjectInfoRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    this.store = createStore(reducers, applyMiddleware(thunk));
  }

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      setPluginDataToDB("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      setPluginDataToDB("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody route={this.state.route} setRoute={this.setRoute} />
      </Provider>
    );
  };
}
