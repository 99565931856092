import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { fetchPlacoSolutionsDetails } from "../../../API";
import AccordionContainer from "../../../Plugins/PlacoBIMv3/CommonModules/AccordionContainer";
import {
  DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR,
  DISTRIBUTIVE_STANDARD,
  ISOLATION_DES_MURS,
  SEPARATIVE_STANDARD_SAA,
  SEPARATIVE_STANDARD_SAD,
} from "../../../Plugins/PlacoBIMv3/CommonModules/Assets/constants";
import { fillConfigurationModal } from "../../../Plugins/PlacoBIMv3/CommonModules/utils/attributes.injection.helpers";
import { FunctionalityHeader } from "../../../Plugins/PlacoBIMv3/Selection/Components/FunctionalityHeader";
import { getSystemType } from "../../../Plugins/PlacoBIMv3/Selection/utils/utils";

export const ConfigurationContainers = ({
  setRoute,
  returnRedirect,
  configProps,
}: any) => {
  const [systemConfiguration, setSystemConfiguration] = useState<any>();
  const [systemBuffer, setSystemBuffer] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [system, setSystem] = useState<any>([]);
  const [systemName, setSystemName] = useState("");

  const { t, i18n } = useTranslation(["library"]);

  useEffect(() => {
    async function callSystemDetails(oid: string) {
      try {
        const solutionDetails = await fetchPlacoSolutionsDetails(
          [oid],
          configProps.config
        ).catch((error) => {
          throw error;
        });
        const system = solutionDetails.data.objects[0];
        setSystem(system);

        let productName = system?.attributes?.find((a:any) => a.technicalName ==="A-Solution product name")?.values[0]?.value;
        setSystemName(productName);

        const configuration = fillConfigurationModal(system, t);
        setSystemConfiguration(configuration);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    if (configProps.selectionBuffer !== null) {
      const reduxSystem = configProps.selectionBuffer;
      setSystemBuffer(reduxSystem);
      callSystemDetails(reduxSystem.oid);
    }
  }, []);

  const getSystemImagePath = (system: any) => {
    const systemType = getSystemType(system);
    switch (systemType) {
      case DISTRIBUTIVE_STANDARD:
        return "/SyetemConfigImages/CloisonDistributive.png";
      case DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR:
        return "/SyetemConfigImages/CloisonDistributive.png";
      case ISOLATION_DES_MURS:
        return "/SyetemConfigImages/Doublage.png";
      case SEPARATIVE_STANDARD_SAA:
        return "/SyetemConfigImages/SAA.png";
      case SEPARATIVE_STANDARD_SAD:
        return "/SyetemConfigImages/SAD.png";
      default:
        break;
    }
  };

  if (loading) {
    return (
      <Dimmer active={loading} page>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div
      className="PropertyGlobalContainer"
      style={{ overflow: "auto", paddingRight: 20, marginRight: 0 }}
    >
      <FunctionalityHeader
        name={systemName}//{systemBuffer.translation}
        Icon={configProps.functionalityIcon}
      />
      <div className="DetailsContainer">
        <div className="PropertiesContent" style={{ height: "100%" }}>
          {systemConfiguration && (
            <>
              <div className="Properties">
                <div className="PropertyDetailsContent">
                  <AccordionContainer
                    key={"Parement"}
                    defaultExpand
                    title={
                      systemConfiguration.parement.filter(
                        (p: any) => p.parmentNumber === 2
                      ).length > 0
                        ? "Parement 1"
                        : "Parement"
                    }
                    properties={systemConfiguration.parement
                      .filter((p: any) => p.parmentNumber === 1)
                      .map((p: any) => ({
                        title: p.layerName,
                        value: p.layerThickness,
                      }))}
                  />
                  {systemConfiguration.parement.filter(
                    (p: any) => p.parmentNumber === 2
                  ).length > 0 && (
                    <AccordionContainer
                      key={"Parement 2"}
                      defaultExpand
                      title={"Parement 2"}
                      properties={systemConfiguration.parement
                        .filter((p: any) => p.parmentNumber === 2)
                        .map((p: any) => ({
                          title: p.layerName,
                          value: p.layerThickness,
                        }))}
                    />
                  )}
                  <AccordionContainer
                    key={"Montants"}
                    defaultExpand
                    title={"Montants"}
                    properties={[
                      {
                        title: systemConfiguration.montant.name,
                        value: systemConfiguration.montant.value,
                      },
                      {
                        title: "Montants (s ou d)",
                        value: systemConfiguration.profile,
                      },
                      {
                        title: "Entraxe (m)",
                        value: systemConfiguration.frameDistance,
                      },
                    ]}
                  />
                  <AccordionContainer
                    key={"Rails"}
                    defaultExpand
                    title={"Rails"}
                    properties={[
                      {
                        title: systemConfiguration.rail.name,
                        value: systemConfiguration.rail.value,
                      },
                    ]}
                  />
                  <AccordionContainer
                    key={"Isolation"}
                    defaultExpand
                    title={"Isolation"}
                    properties={[
                      {
                        title: systemConfiguration.isolation.name,
                        value: systemConfiguration.isolation.value,
                      },
                    ]}
                  />
                </div>
              </div>
              <img
                src={getSystemImagePath(system)}
                width={400}
                height={330}
                className="img-center"
              />
            </>
          )}
        </div>
      </div>
      <div className="PropertyButtonContainer">
        <Button
          className="loadActionButton detailActionButton"
          style={{ textTransform: "none" }}
          variant="contained"
          onClick={() => returnRedirect()}
        >
          {t("RETURN")}
        </Button>
      </div>
    </div>
  );
};
