import axios from "axios";
import { authFilter } from "../../../API/Interceptors/authentication.interceptor";

const token = localStorage.getItem("token");


export const localhost = axios.create({
  baseURL: ".", //process.env.REACT_APP_CALCULATORURL_PLACO,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token
  },
  cache: "no-store"
});

localhost.interceptors.request.use( async (request) => {
  return await authFilter(request);
})

export const getPlacoBrand = bdd =>
  bdd("/objects/class/Brand/locale/en", { text: "PLACO" });

export const getPlacoProducts = (bdd, oid) =>
  bdd("/objects/class/SGObject/locale/en", {
    dependencies: [
      {
        className: "Brand",
        oids: [oid]
      }
    ],
    types: ["Solution Product"],
    principal: true
  });

export const getPlacoProductByName = (bdd, oid, technicalNames) =>
  bdd("/objects/class/SGObject/locale/en", {
    dependencies: [
      {
        className: "Brand",
        oids: [oid]
      }
    ],
    types: ["Solution Product"],
    principal: true,
    technicalNames: technicalNames
  });

export const getPlacoSystemDetails = (bdd, systemOid) =>
  bdd("/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true", [systemOid]);
export const getPlacoSystemsDetails = (bdd, systemOid) =>
  bdd("/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true", systemOid);

  export const getPlacoSystemDetailsLegacy = (bdd, systemOid) =>
  bdd("/objects/details/class/SGObject/locale/en", [systemOid]);
export const getPlacoSystemsDetailsLegacy = (bdd, systemOid) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid);


  export const getPlacoSystemDetailsWithChildren = (bdd, systemOid) =>
  bdd("/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true", [systemOid]);

export const layout = (localhost, wallTypesData, walls) =>
  localhost.post("/", { wallMatching: wallTypesData, walls: walls });
