import React from 'react';
import { connect } from 'react-redux';
import {Filter} from './Filter';
// import { updateWallFilters, setPartitionsDetails } from '../Actions';


export class FiltersList extends React.Component {

    componentDidMount = async () => {

    }

    render() {
        // return null
        return this.props.filters.map((filter, index) => <Filter key={index} applyFilters={this.props.applyFilters} name={filter.name} value={filter.value} options={filter.options} choiceType={filter.choiceType} />)

    }
}

const mapStateToProps = state => {
    return {
        filters: state.filters,
        partitions: state.partitions
    }
}

// export default connect(mapStateToProps, { updateWallFilters, setPartitionsDetails })(FiltersList);