import { Language } from "../../../../RevitJS/Types/RevitTypes";

export const SET_METRE_SELECTION = "SET_METRE_SELECTION";
export const CLEAR_SELECT_METRE = "CLEAR_SELECT_METRE";
export const SELECT_METRE_PAR_ETAGE = "SELECT_METRE_PAR_ETAGE";
export const SELECT_METRE_PAR_ZONE = "SELECT_METRE_PAR_ZONE";
export const SET_REPERAGE_DATA = "SET_REPERAGE_DATA";
export const SET_PREV_PAGE = "SET_PREV_PAGE";
export const SET_MODULE_SELECTION = "SET_MODULE_SELECTION";
export const SAVE_REPERAGE_BUFFER = "SAVE_REPERAGE_BUFFER";
export const INIT_LANGUAGE = "INIT_LANGUAGE";

export const SET_REPERAGE = "SET_REPERAGE";
export const SET_REVIT_SETUP = "SET_REVIT_SETUP";

export interface SetReperage {
    type: typeof SET_REPERAGE;
    reperageData : any
}

export interface SetRevitSetup {
    type: typeof SET_REVIT_SETUP;
    revitSetupData : any
}

export interface SaveReperageBuffer {
    type: typeof SAVE_REPERAGE_BUFFER;
}

export interface InitLanguageAction{
    type: typeof INIT_LANGUAGE;
    language: Language
}