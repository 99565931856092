import { LinearProgress } from "@material-ui/core";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import saintGobainLoading from "../../../assets/saint-gobain-loading.gif";
import "./body.scss";

function Body({
    progress,
    buffer,
    currentElementNbr,
    currentElementName,
    totalElementsNbr,
    finished,
    t,
}: IProps): ReactElement {
    return (
        <div id="data-transfert-body-container">
            <div className="px-4 py-2 h-100">
                <div className="row h-100 d-flex flex-column justify-content-center">
                    <div id="data-transfert-body-lodaing-container">
                        <div className="row">
                            <div className="col-12 text-center">
                                {" "}
                                <img
                                    id="saing-gobain-loading-gif"
                                    className="xlarge-btn m-auto"
                                    src={saintGobainLoading}
                                    alt={"logo"}
                                ></img>
                            </div>
                        </div>
                        {progress < 100 && (
                            <>
                                <div className="orange-text text-center mt-3 mb-1">
                                    <strong>{`${currentElementNbr}/${totalElementsNbr}`}</strong>{" "}
                                    {t("ELEMENTS_TREATED")}
                                </div>
                                <div className="orange-text text-center mb-2">
                                    {currentElementName}
                                </div>
                            </>
                        )}
                        {progress >= 100 && (
                            <div className="green-text text-center mt-3 mb-1">
                                <strong>{t("INJECTING_DATA")}</strong>{" "}
                            </div>
                        )}

                        <div className="row">
                            <div
                                className={`col-12 loader-div ${
                                    finished ? "loader-div-finished" : ""
                                }`}
                            >
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    valueBuffer={buffer}
                                />{" "}
                            </div>
                            <div
                                className={`progress-percentage-div`}
                            >{`${progress}%`}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    progress: number;
    buffer: number;
    currentElementNbr: number;
    currentElementName: string;
    totalElementsNbr: number;
    finished: boolean;
};

export default withTranslation(["pam"])(Body);
