import PouchDB from "pouchdb";
import { ProductMeta } from "../../../../RevitJS/Types/BddTypes";
import { SystemDetails } from "../../../../Components/ProductsBrowser/SystemDetails";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import { bdd } from "../../../PAM/pam-import/helpers/interceptors";

export const getBrandByCode = (brandCode: string, config: any) =>
  bdd("/objects/class/Brand/locale/en", { text: brandCode }, config);

export const getRigipsProducts = (brandOid: string, config: any) =>
  bdd(
    "/objects/class/SGObject/locale/en",
    {
      dependencies: [
        {
          className: "Brand",
          oids: [brandOid],
        },
      ],
      types: ["Product System"],
      principal: true,
    },
    config
  );

export const getPlacoSystemsDetails = (systemOid: ID[], config: any) =>
  bdd(
    "/objects/details/class/SGObject/locale/en?includeChildren=true",
    systemOid,
    config
  );

export const fetchRigipsData = async (config: any): Promise<ProductMeta[]> => {
  let rigipsBrand = await getBrandByCode("RIGIPS", config);
  let rigipsBrandOid = rigipsBrand.data.objects[0].oid;
  const rigipsProducts: ProductMeta[] = await getRigipsProducts(
    rigipsBrandOid,
    config
  ).then(function (response) {
    return response.data.objects;
  });
  let albaBrand = await getBrandByCode("ALBA", config);
  let albaBrandOid = albaBrand.data.objects[0].oid;
  const albaProducts: ProductMeta[] = await getRigipsProducts(
    albaBrandOid,
    config
  ).then(function (response) {
    return response.data.objects;
  });
  return rigipsProducts.concat(albaProducts);
};

export const getSystemDetailsById = async (
  ids: ID[],
  config: any
): Promise<{ data: { context: any; objects: SystemDetails[] } }> => {
  let ids_container = split_array(ids);
  let systemDetailsRequests = await Promise.all(
    ids_container.map((ids) => getPlacoSystemsDetails(ids, config))
  );

  let systemDetailsRequest = {
    data: {
      context: systemDetailsRequests[0].data.context,
      objects: [],
    },
  };
  for (let i = 0; i < systemDetailsRequests.length; i++) {
    systemDetailsRequest.data.objects =
      systemDetailsRequest.data.objects.concat(
        systemDetailsRequests[i].data.objects
      );
  }

  return systemDetailsRequest;
};

const split_array = (arr: any[], chunk = 400): any[][] => {
  let res = [];
  var i, j, temparray;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray = arr.slice(i, i + chunk);
    res.push(temparray);
  }
  return res;
};
