import React from "react";
import { Test } from "../../../../Components/AdvanceSelector/List/test";
//import './style.css';

const App = () => {
  return (
  <>
  <Test/>
  </>
  )
};


export default App;
