import React from "react";
import { Container, Header, Image } from "semantic-ui-react";

interface FunctionalityHeaderProps {
  name: string;
  Icon: string;
  width?: number;
  subheader: string;
  config:any;
}

export class FunctionalityHeader extends React.Component<FunctionalityHeaderProps> {
 

  render() {
    const { name, Icon, width } = this.props;
    return (
      <Container
        textAlign="center"
        className="ModelGeneratorHeader"
        style={{ marginBottom: 0 }}
      >
        <Header
          style={{ paddingTop: "0", paddingBottom: 0 }}
          as="h3"
          content={name}
          image={this.props.Icon}
          subheader={this.props.subheader}
        />
      </Container>
    );
  }
}
