import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "090655ea-1648-4466-bb6a-833fa8b801a4";

export const vueCalepinageTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const VueCalepinageRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const VueCalepinage: Functionality = {
  Name: {
    English: "Vue de calepinage",
    Indonesian: "Vue de calepinage",
  },
  Trigger: vueCalepinageTrigger,
  ShortDesc: {
    English: "Afficher le calepinage",
    Indonesian: "Afficher le calepinage",
  },
  Id: functionalityId,
  Icon: "/Facade/common.png",
  RootComponent,
  ParentId: "0",
};
