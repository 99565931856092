import React, { Component } from 'react';
import { Plugin } from '../../RevitJS/Types/StoreTypes';
import { ID } from '../../RevitJS/Types/RevitTypes';
import { Action } from 'redux';
import PopupWindow from './PopupWindow';
import { FakeModel } from '../../RevitJS/Types/FakerTypes';
import {Dock} from '../../Dock';

interface Props {
    plugins: Plugin[],
    selectPlugin: (id: ID) => Action,
    resetSelectedPlugin: () => Action,
    authenticated: boolean,
    selectedPluginId: string,
    popUpWindow: boolean,
    popUpHidden: boolean,
    windowDimensions: {width: number, height: number},
    popUpComponent: any,
    selectedFakeModel: FakeModel
}

interface State {
    drag: boolean
}

const splitterStyle={
    width: "100%",
    display: "flex"
}

const separatorStyle={
    cursor: "col-resize",
    backgroundColor: "rgb(200,200,200)",
    // backgroundImage: "url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M2 0 v30 M5 0 v30 M8 0 v30' fill='none' stroke='black'/></svg>')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "3px",
    height: "79.7vh",
}

export class Pane extends Component<Props, State> {

    private separatorRef: React.RefObject<HTMLInputElement>;
    private leftRef: React.RefObject<HTMLInputElement>;
    private rightRef: React.RefObject<HTMLInputElement>;
    private leftStyle: any;
    private rightStyle: any;


    constructor(props: Props) {
        super(props);
        this.separatorRef = React.createRef();
        this.leftRef = React.createRef();
        this.rightRef = React.createRef();
        this.leftStyle = {
            backgroundColor: "#dde",
            width: "75%",
            height: "79.2vh",
            minWidth: "10px",
            backgroundSize: "cover",
            backgroundPosition: "left",
            backgroundImage: `url(${this.props.selectedFakeModel.Image})`
        }

        this.rightStyle={
            backgroundColor: "rbb(255,255,255)",
            width: "30%",
            height: "79.2vh",
            minWidth: "10px",
            paddingLeft:10
        }
        
        
    }

    componentDidMount = () =>{
        this.setState({
            drag: false
        });
        document.addEventListener("mouseup", e => this.setState({drag: false}))
        // document.addEventListener("mousemove", e =>{
        //     if(this.state.drag){
        //     }
        // });
        
    }

    render() {
        const windowContent = this.props.popUpComponent ? <this.props.popUpComponent/> : null;
        return (
            <div style={splitterStyle}>
                <div style={this.leftStyle} ref={this.leftRef}>
                    <PopupWindow windowDimensions={this.props.windowDimensions} popUpHidden={this.props.popUpHidden} popUpWindow={this.props.popUpWindow}>
                        {windowContent}
                    </PopupWindow>  
                </div>
                <div style={separatorStyle} ref={this.separatorRef} onMouseDown={e => this.setState({drag:true})}></div>
                <div style={this.rightStyle} ref={this.rightRef}>
                    <Dock/>
                </div>
            </div>
        )
    }
}


