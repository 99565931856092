import React from "react";
import { Button, List, Image } from "semantic-ui-react";

const items = [
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
  {
    label:
      "Cloison Habito® 120/70 - 1x Habito® 13 + 1x Placoplatre® BA 13 - EI60 - 42dB - 6,00m",
    subLabel: "Product",
  },
];

interface Props {
  handleView: any;
  isSelected: any;
  isActive: any;
}

const ProductInfoItems = (props: Props) => {
  const listItems = items;
  return (
    <>
      <List divided verticalAlign="middle">
        {listItems.map((item: any, index: any) => {
          return (
            <List.Item
              className={props.isSelected === true ? "Done" : "not-done"}
              key={index}
              onClick={() => props.handleView(index)}
            >
              <List.Content floated="right">
                <Button onClick={props.handleView}>View</Button>
              </List.Content>
              <Image avatar src="/images/avatar/small/lena.png" />
              <List.Content>
                {item.label}
                <div>{item.subLabel}</div>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </>
  );
};
export default ProductInfoItems;
