import { filter, find, forEach, includes, map, uniqBy, concat } from "lodash";
import { getAllPlugins, getLayoutRuleIdDetails, getPrescriptionActiveVersion } from "../../../../Services/mapping-config-service";


export const newLayoutWallMatchingObject = async (solutionDetails: any, cng: any) => {
  // let layoutRuleId = ["65d60199f969093b6a70a960", "65d70828f969093b6a70a9bf"];
  let wallmatchObj = {
    boardConfig: {},
    frameConfig: {},
    railsConfig: {}
  }
  let layoutRuleId = await getLayoutRuleId(cng);
  for (const ruleId of layoutRuleId) {
    let slnProduct = true;
    let systemVariationRuleDetails;
    if (cng && Object.keys(cng).length > 0) {
      let layoutRulesDetails = await getLayoutRuleIdDetails(ruleId, cng);

      if (layoutRulesDetails) {

        let flattenRules = layoutRulesDetails.solutionLevels.concat(layoutRulesDetails.productLevels);
        flattenRules = flattenRules.filter((r: any) => r.attribute && r.attribute !== "")
        slnProduct = evaluateDataRulesForBoolean(solutionDetails.solutionProduct.attributes, flattenRules);
        let systemVariationRules = layoutRulesDetails.systemVariationRule;
        if (slnProduct && systemVariationRules.length > 0) {
          for (const rule of systemVariationRules) {
            let datarule=flattenSystemVariation(rule);
            slnProduct = evaluateDataRulesForBoolean(solutionDetails.solutionProduct.attributes, datarule);
            if (slnProduct) {
              systemVariationRuleDetails = rule;
              //break;
            }
          }
          if (systemVariationRuleDetails) {
            if (systemVariationRuleDetails.boardConfig != null) {
              let bdCng = systemVariationRuleDetails.boardConfig;
              let boardConfigDetails: any;
              boardConfigDetails = {
                maxWidth: getParameterData(bdCng.maxWidth, solutionDetails),
                flagAboveDoor: bdCng.flagAboveDoor,
                flagAboveDoorLimit: getParameterData(bdCng.flagAboveDoorLimit, solutionDetails),
                flagSideWidth: getParameterData(bdCng.flagSideWidth,solutionDetails),
                minWidth: getParameterData(bdCng.minWidth, solutionDetails),
                minHeight: getParameterData(bdCng.minHeight, solutionDetails),
                staggeredStyle: bdCng.staggeredStyle,
                junction_Overlap: bdCng.junction_Overlap,
                junction_Extend: bdCng.junction_Extend,
                junction_Extend_Space: getParameterData(bdCng.junction_Extend_Space, solutionDetails),
                junction_Extend_OnlyRightAngles: bdCng.junction_Extend_OnlyRightAngles,
                verticalCut: bdCng.verticalCut,
                verticalCut_DistanceFromBoardBottomtoWallBottom: getParameterData(bdCng.verticalCut_DistanceFromBoardBottomtoWallBottom, solutionDetails),
                verticalCut_DistanceFromBoardToptoWallTop: getParameterData(bdCng.verticalCut_DistanceFromBoardToptoWallTop, solutionDetails),
                verticalCut_StaggeredVerticallyOnSameSide: bdCng.verticalCut_StaggeredVerticallyOnSameSide,
                verticalCut_StaggeredHorizontallyOnSameLayer: bdCng.verticalCut_StaggeredHorizontallyOnSameLayer,
                verticalCut_OffsetwithOtherSide: getParameterData(bdCng.verticalCut_OffsetwithOtherSide, solutionDetails),
                verticalCut_Height: getParameterData(bdCng.verticalCut_Height, solutionDetails),
                freeEnd_Type: bdCng.freeEnd_Type,
                layoutMethod: bdCng.layoutMethod,
              }
              wallmatchObj.boardConfig = boardConfigDetails;
            }
            if (systemVariationRuleDetails.framesConfig != null) {
              let fmCng = systemVariationRuleDetails.framesConfig;
              let frameConfigDetails: any;
              frameConfigDetails = {
                stopAtFromTop: getParameterData(fmCng.stopAtFromTop, solutionDetails),
                length: getParameterData(fmCng.length, solutionDetails),
                minLength: getParameterData(fmCng.minLength, solutionDetails),
                frame_double: fmCng.frame_double,
                space: getParameterData(fmCng.space, solutionDetails),
                alignmentSingleRow: fmCng.alignmentSingleRow,
                alignmentDoubleRow: fmCng.alignmentDoubleRow,
                staggeredWidth: getParameterData(fmCng.staggeredWidth, solutionDetails),
                additionalFrameAboveOpeningWider: fmCng.additionalFrameAboveOpeningWider,
                additionalFrameAboveOpeningWidth: getParameterData(fmCng.additionalFrameAboveOpeningWidth, solutionDetails),
                additionalFrameAboveOpeningForOpening: fmCng.additionalFrameAboveOpeningForOpening,
                additionalFrameAboveOpeningMinWidth: getParameterData(fmCng.additionalFrameAboveOpeningMinWidth, solutionDetails),
                centerAdditionalFrames: fmCng.centerAdditionalFrames,
                addExtraFrameOnTABySide: fmCng.addExtraFrameOnTABySide,
                wingLength: getParameterData(fmCng.wingLength, solutionDetails),
                orientation: fmCng.orientation,
                horizontal_thickness: getParameterData(fmCng.horizontal_thickness, solutionDetails),
                junction_ExtendType: fmCng.junction_ExtendType,
                verticalCut: fmCng.verticalCut,
                verticalCut_LengthOfIntermediate: getParameterData(fmCng.verticalCut_LengthOfIntermediate, solutionDetails),
                verticalCut_minDistanceFromUpperCutTopToWallTop: getParameterData(fmCng.verticalCut_minDistanceFromUpperCutTopToWallTop, solutionDetails),
                verticalCut_Double_Staggered_Offset: getParameterData(fmCng.verticalCut_Double_Staggered_Offset, solutionDetails),
                verticalCut_Double_Staggered: fmCng.verticalCut_Double_Staggered,
              }
              wallmatchObj.frameConfig = frameConfigDetails;

            }
            if (systemVariationRuleDetails.railsConfig != null) {
              let rlCng = systemVariationRuleDetails.railsConfig;
              let railsConfigDetails: any;
              railsConfigDetails = {
                aboveOpening: rlCng.aboveOpening,
                belowOpening: rlCng.belowOpening,
                name: getParameterData(rlCng.name, solutionDetails),
                length: getParameterData(rlCng.length, solutionDetails),
                minLength: getParameterData(rlCng.minLength, solutionDetails),
                junction_ExtendType: rlCng.junction_ExtendType,
                junction_DoubleRailExtendType: rlCng.junction_DoubleRailExtendType,
                angleTrim: {
                  height: getParameterData(rlCng.angleTrim.height, solutionDetails),
                  length: getParameterData(rlCng.angleTrim.length, solutionDetails),
                  name: getParameterData(rlCng.angleTrim.name, solutionDetails),
                  width: getParameterData(rlCng.angleTrim.width, solutionDetails),
                }
              }
              wallmatchObj.railsConfig = railsConfigDetails;

            }
          }
        }
      }
    }
  };

  return wallmatchObj;

}
const getLayoutRuleId = async (config: any) => {
  let layoutRuleIds = [];
  let plugins = await getAllPlugins(config);
  const application = plugins.find((p: any) => p.applicationTechnicalName === 'PLACOBIM');
  let data = await getPrescriptionActiveVersion(application.applicationId, config);
  let layoutModule = data?.version.layoutModule;
  layoutRuleIds.push(layoutModule.boardLayoutruleId)
  layoutRuleIds.push(layoutModule.framesLayoutruleId)
  layoutRuleIds.push(layoutModule.railLayoutruleId)
  return layoutRuleIds
}
const flattenSystemVariation = (dr: any) => {
  let dataRules:any=[];
  let rules: any = [];
  rules.push({
    attribute: dr.attribute,
    caseValue: dr.caseValue,
    relation: dr.relation,
    value: dr.value,
    condition: "OR"
  });
  if(rules.length>0){
    dataRules.push(rules);
  }
  return dataRules;
}

const getParameterData = (parameter: any, solutionData: any) => {
  if (parameter === "" || parameter === null) {
    return parameter;
  }
  let productItem = mapAttributeWithMappingConfig(parameter);
  let value = "";
  value = getParameterValue(productItem, solutionData.solutionProduct.attributes);
  if (value === "" || value === null) {
    let bimLayers = filter(solutionData.solutionProduct.attributes, {
      technicalName: "GFR-BIM layers",
    });
    if (bimLayers.length > 0) {
      for (const layers of bimLayers) {
        var layerName = layers.subAttributes.find(
          (i: any) => i.technicalName === "GFR-Layer name").values[0].value;
        var productData = solutionData.products.find(
          (product: any) => product.product.translation === layerName
        );
        if (productData) {
          value = getParameterValue(productItem, productData.product.attributes);
        }
        if (value != "" && value != null) {
          break;
        }
      }
    }
  }

  return value;
}
const getParameterValue = (productItem: any, filterAttribute: any) => {
  let value: string = productItem?.value;
  if (productItem.attributeName !== "" && filterAttribute.some((a: any) => a.technicalName === productItem.attributeName)) {
    var filterData = filterAttribute.find((a: any) => a.technicalName === productItem.attributeName)//[0];
    value = filterData?.values[0]?.value;
    if (!evaluateDataRulesForBoolean(filterAttribute,productItem.dataRules)) {
      value = "";
    }
  }
  else if (productItem.parentAttributeName !== "" && filterAttribute.some((a: any) => a.technicalName === productItem.parentAttributeName)) {
    var filterParentatrribute = filterAttribute.find((a: any) => a.technicalName === productItem.parentAttributeName)//[0];
    var filterData = filterParentatrribute.subAttribute.find((a: any) => a.technicalName === productItem.attributeName)//[0];
    value = filterData?.values[0]?.value;
    if (!evaluateDataRulesForBoolean(filterAttribute,productItem.dataRules)) {
      value = "";
    }
  }
  return value;
}
const mapAttributeWithMappingConfig = (parameter: any, mandatory: boolean = false) => {
  return {
    attributeName: parameter.attributeName,
    revitKey: parameter.revitKey === null ? parameter.parameterId : parameter.revitKey,
    parentAttributeName: parameter.attributeParentName.length > 0 ? parameter.attributeParentName[0] : "",
    mandatoryField: "",
    mandatory: parameter.mandatory,
    decimalPlace: parameter.numberOfDecimal.value,
    value: parameter.attributeName === "" || parameter.attributeName === null ? parameter.values.value !== "" ? parameter.values.value : parameter.values.numericValue : "",
    dataRules: parameter.dataRules,
  };
}
export const evaluateDataRulesForBoolean = (attributes: any, dataRules: any) => {
  let response: any = false;

  if (dataRules?.length === 0) {
    response = true;
  } else {
    dataRules?.forEach((dataRule: any, index: number) => {
      let conditionalDataRuleValue: any = [];
      dataRule.forEach((rule: any, idx: number) => {
        const dataRuleValue = getDataRuleValue(
          attributes,
          rule
        );

        if (idx > 0) {
          conditionalDataRuleValue = dataRuleValue && conditionalDataRuleValue;
        } else {
          conditionalDataRuleValue = dataRuleValue;
        }
      });

      if (index > 0) {
        response = conditionalDataRuleValue || response;
      } else {
        response = conditionalDataRuleValue;
      }
    });
  }

  return response;
};

export const getDataRuleValue = (attributes: any, rule: any) => {
  const { caseValue, attribute, relation, value } = rule;

  if (!attribute.length || !relation.length) {
    return true;
  }

  // const result = attributes.filter((solution: any) => {
    const attributeMatch = getAttributeFromTechnicalName(attributes, attribute);
    // const attributeMatch = solution.attributes.find(
    //     (attr: any) => attr.technicalName === attribute
    // );

    if (attributeMatch) {
      let numericValue = 0;
      if (!isNaN(Number(value))) {
        numericValue = attributeMatch.values[0]?.numericValue;
      }
      const textValue = attributeMatch.values[0]?.value || "";
      switch (relation) {
        case "CONTAINS":
          return textValue.includes(value);
        case "ENDSWITH":
          return textValue.endsWith(value);
        case "EQUALTO":
          if (!isNaN(Number(value))) {
            return numericValue === Number(value);
          } else {
            return textValue === value;
          }
        case "GREATERTHAN":
          return numericValue > Number(value);
        case "ISEMPTY":
          return textValue.length === 0;
        case "ISNOTCONTAINS":
          return !textValue.includes(value);
        case "ISNOTEMPTY":
          return textValue.length !== 0;
        case "LESSTHAN":
          return numericValue < Number(value);
        case "STARTSWITH":
          return textValue.startsWith(value);
        default:
          return false;
      }
    }
  // });

  // return result;
};

export const getAttributeFromTechnicalName = (
  attributes: any,
  attrName: any
) => {
  return attributes.find(
    (attr: any) => attr.technicalName === attrName
  );
};