import { ThunkAction } from "redux-thunk";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { FacadeStore } from "../Reducers";
import { ProjectDataActionType, SET_PROJECT_DATA } from "../Types";

export const SetProjectData =
  (data: initProjectData): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_PROJECT_DATA,
      data,
    });
  };
