
import { setHeaderStyle, informationTable, adjustColumnsSize, seTableStyle } from './tableHelaper'
import {
    map,
    flatten,
    compact,
    chain,
    find,
    sum,
    forEach,
    times,
    constant,
  } from "lodash";

export const hangersQuantities = async (plasterboardsSheet:any) => {
    let headersMainBoard = [
        { ref: 'A1', name: "Floor" },
        { ref: 'B1', name: "Rigips system" },
        { ref: 'C1', merge: 'C1:D1', name: "Rigips hanger type" },
        { ref: 'E1', merge: 'E1:G1', name: "Quantity hangers" },

    ]
    const mainTableHeaders = setHeaderStyle(plasterboardsSheet, headersMainBoard)

    let mainTableDetailColumns = [
        { name: "Nr", filterButton: true },
        { name: "Nr", filterButton: true },
        { name: "Name", filterButton: true },
        { name: "Name", filterButton: true },
        { name: "XX", filterButton: true },
        { name: "XX", filterButton: true },
        { name: "m2", filterButton: true },
    ];

    forEach(
        times(1 + 1, Number),
        (value, index) => {
          const rowNumber = index + 2;
          plasterboardsSheet.mergeCells(`C${rowNumber}:D${rowNumber}`);
          plasterboardsSheet.mergeCells(`E${rowNumber}:F${rowNumber}`);
        }
      );

    plasterboardsSheet.addTable({
        name: "Hangers",
        ref: "A2",
        headerRow: true,
        totalsRow: false,
        style: {
            theme: null,
            showRowStripes: true,
        },
        columns: mainTableDetailColumns,
        rows: [[" ", " ", " ", " ", " ", " ", " "]]
    });
    let totalTableBoard = [
        { ref: 'H1', merge: 'H1:J1', name: "Total quantity hangers per type" },
    ]
    const totalTableHeaders = setHeaderStyle(plasterboardsSheet, totalTableBoard, '548232')

    let totalTableeDetailColumns = [
        { name: "Name", filterButton: true },
        { name: "XX", filterButton: true },
        { name: "m2", filterButton: true },
    ];
    plasterboardsSheet.addTable({
        name: "Hangers",
        ref: "H2",
        headerRow: true,
        totalsRow: false,
        style: {
            theme: null,
            showRowStripes: true,
        },
        columns: totalTableeDetailColumns,
        rows: [[" ", " ", " "]]
    });
    const TableInformation = informationTable(plasterboardsSheet, 1 + 3)
    const TablesStyle = seTableStyle(plasterboardsSheet, 1 + 3)
    applyColumnSize(plasterboardsSheet);
};

const applyColumnSize = (insulationSheet:any) => {
    insulationSheet.getColumn("A").width = 12;
    insulationSheet.getColumn("B").width = 12;
    insulationSheet.getColumn("C").width = 11;
    insulationSheet.getColumn("D").width = 11;
    insulationSheet.getColumn("E").width = 11;
    insulationSheet.getColumn("F").width = 11;
    insulationSheet.getColumn("G").width = 11;
    insulationSheet.getColumn("H").width = 11;
    insulationSheet.getColumn("I").width = 11;
    insulationSheet.getColumn("J").width = 11;
  };
