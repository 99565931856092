import { Point, Vect3D, Basis, Contour, TurfContour, Line } from "./types";
import * as turf from "@turf/turf";

export const translatedAndRotate = (
  transformation: number[][],
  vect: Vect3D
): Point => {
  // let transformation = multiply(rotationMatrix, translationMatrix);
  let result = multiply(transformation, [[vect.X], [vect.Y], [vect.Z], [1]]);
  return { X: result[0][0], Y: result[1][0], Z: result[2][0] };
};

export const translatedAndRotateRevert = (
  point: Point,
  translation: Vect3D,
  rotation: Basis
): Point => {
  throw "Not Implemented Yet!";
};

function multiply(a: number[][], b: number[][]) {
  var aNumRows = a.length,
    aNumCols = a[0].length,
    bNumRows = b.length,
    bNumCols = b[0].length,
    m = new Array(aNumRows); // initialize array of rows
  for (var r = 0; r < aNumRows; ++r) {
    m[r] = new Array(bNumCols); // initialize the current row
    for (var c = 0; c < bNumCols; ++c) {
      m[r][c] = 0; // initialize the current cell
      for (var i = 0; i < aNumCols; ++i) {
        m[r][c] += a[r][i] * b[i][c];
      }
    }
  }
  return m;
}

export const normalize = (vect: Vect3D): Vect3D => {
  let norm = Math.sqrt(vect.X ** 2 + vect.Y ** 2 + vect.Z ** 2);
  return { X: vect.X / norm, Y: vect.Y / norm, Z: vect.Z / norm };
};

export const crossProduct = (vect1: Vect3D, vect2: Vect3D): Vect3D => {
  return {
    X: vect1.Y * vect2.Z - vect1.Z * vect2.Y,
    Y: vect1.Z * vect2.X - vect1.X * vect2.Z,
    Z: vect1.X * vect2.Y - vect1.Y * vect2.X,
  };
};

export const moveContour = (
  contour: Contour,
  translation: Vect3D,
  rotation: Basis
): Contour => {
  let translationMatrix = [
    [1, 0, 0, -translation.X],
    [0, 1, 0, -translation.Y],
    [0, 0, 1, -translation.Z],
    [0, 0, 0, 1],
  ];
  let rotationMatrix = [
    [rotation[0].X, rotation[0].Y, rotation[0].Z, 0],
    [rotation[1].X, rotation[1].Y, rotation[1].Z, 0],
    [rotation[2].X, rotation[2].Y, rotation[2].Z, 0],
    [0, 0, 0, 1],
  ];
  let transformation = multiply(rotationMatrix, translationMatrix);
  return contour.map((point) => translatedAndRotate(transformation, point));
};

export const moveLine = (
  line: Line,
  translation: Vect3D,
  rotation: Basis
): Line => {
  let translationMatrix = [
    [1, 0, 0, -translation.X],
    [0, 1, 0, -translation.Y],
    [0, 0, 1, -translation.Z],
    [0, 0, 0, 1],
  ];
  let rotationMatrix = [
    [rotation[0].X, rotation[0].Y, rotation[0].Z, 0],
    [rotation[1].X, rotation[1].Y, rotation[1].Z, 0],
    [rotation[2].X, rotation[2].Y, rotation[2].Z, 0],
    [0, 0, 0, 1],
  ];
  let transformation = multiply(rotationMatrix, translationMatrix);
  return {
    p1: translatedAndRotate(transformation, line.p1),
    p2: translatedAndRotate(transformation, line.p2),
  };
};

export const divideIntervalle = (
  start: number,
  end: number,
  cut: number,
  minCut: number
): number[][] => {
  const length = end - start;
  const quotient = Math.floor(length / cut);
  const remainder = length % cut;
  if (remainder >= minCut) {
    const l1 = Array.from(Array(quotient).keys()).map((k) => [
      start + k * cut,
      start + (k + 1) * cut,
    ]);
    const l2 = [[start + quotient * cut, start + quotient * cut + remainder]];
    return l1.concat(l2);
  } else if (remainder === 0) {
    return Array.from(Array(quotient).keys()).map((k) => [
      start + k * cut,
      start + (k + 1) * cut,
    ]);
  } else if (quotient === 0) {
    return [[start, end]];
  } else {
    const remaining_length = cut + remainder;
    const before_last = (Math.floor(remaining_length - minCut) / 100) * 100;
    const l1 = Array.from(Array(quotient - 1).keys()).map((k) => [
      start + k * cut,
      start + (k + 1) * cut,
    ]);
    const l2 = Array.from(Array(quotient - 1).keys()).map((k) => [
      start + (quotient - 1) * cut,
      start + (quotient - 1) * cut + before_last,
    ]);
    const l3 = [[start + (quotient - 1) * cut + before_last, end]];
    return l1.concat(l2).concat(l3);
  }
};

export const distance2D = (p1: Vect3D, p2: Vect3D): number => {
  return Math.sqrt(Math.pow(p2.X - p1.X, 2) + Math.pow(p2.Y - p1.Y, 2));
};

export const cleanContour = (contour: Contour): Contour => {
  let points = [...contour, contour[0]];
  let nbPoints = points.length;
  let contourPoints = [contour[0]];
  for (let k = 0; k < nbPoints - 2; k++) {
    let p1 = points[k];
    let p2 = points[k + 1];
    let p3 = points[k + 2];
    let dist_p1_p2 = distance2D(p1, p2);
    let dist_p2_p3 = distance2D(p2, p3);
    let dist_p1_p3 = distance2D(p1, p3);

    if (Math.abs(dist_p1_p3 - (dist_p1_p2 + dist_p2_p3)) > 1) {
      contourPoints.push(p2);
    }
  }
  return contourPoints;
};

export const moveContourBack = (
  contour: Contour,
  translation: Vect3D,
  rotation: Basis
): Contour => {
  let translationMatrix = [
    [1, 0, 0, translation.X],
    [0, 1, 0, translation.Y],
    [0, 0, 1, translation.Z],
    [0, 0, 0, 1],
  ];
  let rotationMatrix = [
    [rotation[0].X, rotation[1].X, rotation[2].X, 0],
    [rotation[0].Y, rotation[1].Y, rotation[2].Y, 0],
    [rotation[0].Z, rotation[1].Z, rotation[2].Z, 0],
    [0, 0, 0, 1],
  ];
  let transformation = multiply(translationMatrix, rotationMatrix);
  return contour.map((point) => translatedAndRotate(transformation, point));
};

export const contoursToTurfContour = (contour: Contour): TurfContour => {
  let contourPoints = contour.map((point) => {
    return [point.X, point.Y];
  }) as [number, number][];
  return [...contourPoints, [contour[0].X, contour[0].Y]];
};

export const contourToTurfPolygon = (
  outerContour: Contour,
  innerContours: Contour[]
): turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties> => {
  let outerContourTurf = contoursToTurfContour(outerContour);
  let innerTurfContours = innerContours.map((cont) =>
    contoursToTurfContour(cont)
  );
  return turf.polygon([outerContourTurf, ...innerTurfContours]);
};

export const computeExtremeCoords = (
  contour: Contour
): { xmin: number; ymin: number; xmax: number; ymax: number } => {
  return {
    xmin: Math.min(...contour.map((point) => point.X)),
    ymin: Math.min(...contour.map((point) => point.Y)),
    xmax: Math.max(...contour.map((point) => point.X)),
    ymax: Math.max(...contour.map((point) => point.Y)),
  };
};

export const reverseLine = (line: Line): Line => {
  const { p1, p2 } = line;
  return { p1: p2, p2: p1 };
};
