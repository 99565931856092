import React from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { Routes } from "../root";

export const BackupModal = (props: any) => {
 
  return (
    <Modal open={props.backupModal} size="mini" dimmer="inverted">
      <Modal.Header className="modalHeader">Sauvegarde <Icon name="close" style={{float: "right", cursor: "pointer"}} onClick={props.closeDimmer}></Icon></Modal.Header>
      <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
        <p>
          Voulez-vous sauvegarder la configuration {props.docType} avant de
          quitter ce menu ?
        </p>
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button
          color="orange"
          size="tiny"
          onClick={() => {
            props.setRoute(Routes.TECHNICAL_ROOT);
          }}
        >
          Non
        </Button>
        <Button
          size="tiny"
          type="submit"
          primary
          onClick={() => {
            props.saveAction();
            props.setRoute(Routes.TECHNICAL_ROOT);
          }}
        >
          Oui
        </Button>
      </div>
    </Modal>
  );
};
