import {
  LOAD_SYSTEMS,
  LOAD_SYSTEMS_SUCCESS,
  LOAD_SYSTEMS_ERROR,
  LOAD_SYSTEMS_TO_UPDATED,
  LOAD_UPDATED_SYSTEMS_SUCCESS,
  LOAD_SYSTEMS_TO_UPDATED_ERROR,
  SystemsActionTypes
} from "./types";

export const loadSystemsRequest = (): SystemsActionTypes => ({
  type: LOAD_SYSTEMS,
});

export const loadSystemsSuccess = (currentSystems: any[], systems: any[], data: any): SystemsActionTypes => ({
  type: LOAD_SYSTEMS_SUCCESS,
  data: data
});

export const loadSystemsError = (): SystemsActionTypes => ({
  type: LOAD_SYSTEMS_ERROR,
});

export const loadSystemsToUpdateRequest = (): SystemsActionTypes => ({
  type: LOAD_SYSTEMS_TO_UPDATED,
});

export const loadSystemsToUpdateSuccess = (currentSystems: any[]): SystemsActionTypes => {
  return {
    type: LOAD_UPDATED_SYSTEMS_SUCCESS,
    currentSystems: currentSystems,

  }
};

export const loadSystemsToUpdateError = (): SystemsActionTypes => ({
  type: LOAD_SYSTEMS_TO_UPDATED_ERROR,
});
