import * as type from "./types";
import { SelectionStore } from "../../Selection/Reducers";
import { ThunkAction } from "redux-thunk";
import {
  ResetCalpinageSelection,
  RESET_CALPINAGE_SELECTION,
} from "../../Selection/Actions/types";
import { InitLanguageAction, INIT_LANGUAGE } from "./types";
import { setPluginDataToDB } from "../../../../Helper";

export const setCalpinageData = (calpinageData: any) => {
  return {
    type: type.SET_CALPINAGE_DATA,
    calpinageData,
  };
};

export const setCalpinageSelection = (value: boolean) => {
  return {
    type: type.SET_MODULE_SELECTION,
    value,
  };
};

export const resetCalepinageSelection =
  (): ThunkAction<void, SelectionStore, unknown, ResetCalpinageSelection> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: RESET_CALPINAGE_SELECTION,
    });
  };

export const ClearCalpinageSelection =
  (): ThunkAction<void, SelectionStore, unknown, type.ClearCalpinage> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: type.CLEAR_CALEPINAGE,
    });
  };

export const setCalepinage =
  (
    calepinageData: any
  ): ThunkAction<void, SelectionStore, unknown, type.SetCalpinage> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: type.SET_CALPINAGE,
      calepinageData,
    });
  };

export const loadCalepinage = (calepinage: {
  [key: string]: any;
}): type.LoadCalpinage => {
  return {
    type: type.LOAD_CALEPINAGE,
    calepinage,
  };
};

export const saveCalpinageBuffer = (): type.SaveCalpinageBuffer => {
  return {
    type: type.SAVE_CALEPINAGE_BUFFER,
  };
};

export const editCalepinage = (calepinage: any): type.EditCalpinage => {
  return {
    type: type.EDIT_CALEPINAGE,
    calepinage,
  };
};

export const setEditBuffer = (calepinage: any): type.EditBufferCalpinage => {
  return {
    type: type.EDIT_BUFFER_CALEPINAGE,
    calepinage,
  };
};

export const deleteCalepinage = (Id: string): type.DeleteCalpinage => {
  return {
    type: type.DELETE_CALEPINAGE,
    Id,
  };
};

export const updateCalepinageName = (
  Id: string,
  Name: string,
  Date: string
): type.UpdateCalpinageName => {
  return {
    type: type.UPDATE_CALEPINAGE_NAME,
    Id,
    Name,
    Date,
  };
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
//const language = stringLitArray(["French", "English", "German", "Italian"]);
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, SelectionStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "French" as Language; //await api.queries.getRevitLanguage() as Language;
      setPluginDataToDB("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
        //language: "French"
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
          //language: "French"
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };
