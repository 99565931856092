import React from "react";
import ChipInput from 'material-ui-chip-input'
import { makeStyles } from "@material-ui/core/styles";

import './index.scss'

const useRowStyles = makeStyles({
    root: {
        width: '200px',

    },
    chipContainer: {
        width: '100%',
        height: '40px',
        overflow: 'auto'
    }
});


export default function SearchComponenet({ searchKeyWords, onAddKeyWord, onDeleteKeyWord, systemCount = 0 }: Search) {
    const classes = useRowStyles();
    return (
        <div className='SearchContainer'>
            <ChipInput
                classes={{ root: classes.root, chipContainer: classes.chipContainer }}
                value={searchKeyWords}
                onAdd={(chip) => onAddKeyWord(chip)}
                onDelete={(chip, index) => onDeleteKeyWord(index)}
                placeholder={'Recherche...'}
            />
            <div className='SearchSystems'>{systemCount} Systèmes trouvés</div>

        </div>

    );
}


export interface Search {
    systemCount?: number,
    searchKeyWords?: string[],
    onAddKeyWord?: any,
    onDeleteKeyWord?: any
}