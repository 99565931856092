import { CLOISONS_ID, DOUBLAGES_ID, GAINES_ID, PLAFONDS_ID } from "../../assets/constants";
import {
  CartState,
  CartActionTypes,
  ADD_SYSTEM_TO_CART,
  REMOVE_SYSTEM_FROM_CART,
  CLEAN_CART
} from "./types";

export const initialState: CartState = {
  currentSystemItems: [],
  data: {
    liningWallsDetails: [],
    partitionsDetails: [],
    ceilingsDetails: [],
    gainestechniqueswallDetails: []
  },
  cartCount: 0
};

export default function cartReducer(
  state = initialState,
  action: CartActionTypes
) {
  switch (action.type) {
    case ADD_SYSTEM_TO_CART:
      return {
        ...state,
        currentSystemItems: [...state.currentSystemItems, action.cartSystemItem],
        cartCount: state.cartCount + 1,
        data: {
          liningWallsDetails: action.systemTypeID === DOUBLAGES_ID ? [...state.data.liningWallsDetails, action.cartSystemItem] : [...state.data.liningWallsDetails],
          partitionsDetails: action.systemTypeID === CLOISONS_ID ? [...state.data.partitionsDetails, action.cartSystemItem] : [...state.data.partitionsDetails],
          ceilingsDetails: action.systemTypeID === PLAFONDS_ID ? [...state.data.ceilingsDetails, action.cartSystemItem] : [...state.data.ceilingsDetails],
          gainestechniqueswallDetails: action.systemTypeID === GAINES_ID ? [...state.data.gainestechniqueswallDetails, action.cartSystemItem] : [...state.data.gainestechniqueswallDetails],
        },
      };
    case REMOVE_SYSTEM_FROM_CART:
      return {
        ...state,
        currentSystemItems: [...state.currentSystemItems.slice(0, action.index),
        ...state.currentSystemItems.slice(action.index + 1)],
        data: {
          liningWallsDetails: action.systemTypeID === DOUBLAGES_ID ? [...state.data.liningWallsDetails.slice(0, action.index),
          ...state.data.liningWallsDetails.slice(action.index + 1)] : [...state.data.liningWallsDetails],
          partitionsDetails: action.systemTypeID === CLOISONS_ID ? [...state.data.partitionsDetails.slice(0, action.index),
          ...state.data.partitionsDetails.slice(action.index + 1)] : [...state.data.partitionsDetails],
          ceilingsDetails: action.systemTypeID === PLAFONDS_ID ? [...state.data.ceilingsDetails.slice(0, action.index),
          ...state.data.ceilingsDetails.slice(action.index + 1)] : [...state.data.ceilingsDetails],
          gainestechniqueswallDetails: action.systemTypeID === GAINES_ID ? [...state.data.gainestechniqueswallDetails.slice(0, action.index),
            ...state.data.gainestechniqueswallDetails.slice(action.index + 1)] : [...state.data.gainestechniqueswallDetails],
        },
        cartCount: state.cartCount - 1
      };
    case CLEAN_CART:
      return {
        ...state,
        currentSystemItems: [],
        data: {
          liningWallsDetails: [],
          partitionsDetails: [],
          ceilingsDetails: [],
          gainestechniqueswallDetails: []
        },
        cartCount: 0
      };
    default:
      return state;
  }
}
