import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { MyConfig } from "../../../../../Helper";
import { selectWindowToShow } from "../../actions/appActions";
import { api } from "../../../../../RevitJS/API";
import {
  resetCurrentProject,
  saveProject,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  STEP_4_COUPLING_SELECTION,
} from "../../assets/constants";
import {
  createParameter,
  getElementOrCouplingFromData,
  extractElementFromData,
} from "../../helpers/utils";
import Element from "../../models/Element.model";
import Item from "../../models/Item.model";
import StateModel from "../../models/State.model";
import { getObjectDetails } from "../../services/projects.service";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./dataTransfert.scss";

class DataTransfert extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      progress: 0,
      buffer: 0,
      currentElementNbr: 0,
      currentElementName: "",
      totalElementsNbr: 0,
      finished: false,
    };

    this.nextPage = this.nextPage.bind(this);
  }

  getElementName(element: Element): string {
    let tmpAngle = element.Angle !== null ? " - " + element.Angle + "deg" : "";
    let tmpDiameter =
      element.Diameter !== null ? " - DN" + element.Diameter : "";

    return element.FamilyName + tmpAngle + tmpDiameter;
  }

  async componentDidMount() {
    let { t } = this.props;
    let nbrAllElements: number = 0;
    let accumulateElementsDone: number = 0;

    this.props.validatedItems.forEach((item: Item) => {
      nbrAllElements += item.allCompatibleElements.length;
    });
    this.setState({
      totalElementsNbr: nbrAllElements,
    });

    let elementsToInject: {
      elementObject: any;
      element: any;
      coupling?: any;
    }[] = [];

    for (let i = 0; i < this.props.validatedItems.length; i++) {
      let item = this.props.validatedItems[i];
      for (let j = 0; j < item.allCompatibleElements.length; j++) {
        let element = item.allCompatibleElements[j];

        const config = await MyConfig();
        const response = await getObjectDetails(element, config);
        const objects = extractElementFromData(response);

        accumulateElementsDone++;

        this.setState({
          buffer: Math.ceil((accumulateElementsDone * 100) / nbrAllElements),
        });
        if (objects.length > 0) {
          let tmpCurrentElement;
          if (objects.length === 1) {
            tmpCurrentElement = this.getElementName(element);
            let elementObject = getElementOrCouplingFromData(
              objects,
              "ELEMENT"
            );
            if (!elementObject) {
            }
            elementsToInject.push({
              element,
              elementObject,
            });
          } else {
            tmpCurrentElement = this.getElementName(element);
            let elementObject = getElementOrCouplingFromData(
              objects,
              "ELEMENT"
            );
            let coupling = getElementOrCouplingFromData(objects, "COUPLING");
            if (!elementObject || !coupling) {
            }
            elementsToInject.push({
              element,
              elementObject,
              coupling,
            });
          }
          this.setState({
            progress: Math.ceil(
              (accumulateElementsDone * 100) / nbrAllElements
            ),
            currentElementNbr: accumulateElementsDone,
            currentElementName: tmpCurrentElement,
          });
        }
      }
    }
    //await createParametersByCategories(elementsToInject, t);

    for (let i = 0; i < elementsToInject.length; i++) {
      await createParameter(
        t,
        elementsToInject[i].element,
        elementsToInject[i].elementObject,
        elementsToInject[i].coupling
      );
    }

    let eventData = elementsToInject.map((obj: any) => {
      let data: any = {
        element_type: obj.element.FamilyName,
        pipe_system: obj.elementObject.translation,
        couplings: obj.coupling.translation,
      };
      return data;
    });

    this.setState({ finished: true });

    this.sendEvent(eventData);
    setTimeout(() => {
      this.nextPage();
    }, 500);
  }

  sendEvent = async (eventData: any) => {
    let projectInfo: any = await api.queries.getProjectInformation();
    let projectName = projectInfo.Name;
    let projectData: any = await api.queries.getProjectData();
    let fileName = projectData.ProjectPath.split("\\").pop();
    let revitVersion = await api.framework.getRevitVersion();

    await api.eventLog.SetEvent({
      data: [
        {
          name: "Name of solutions",
          value: "",
          values: eventData,
        },
        {
          name: "Information",
          value: "",
          values: [
            {
              File_Name: fileName,
              Project_Name: projectName,
              Version: revitVersion,
            },
          ],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Load",
      module: "PAMDI",
    });
  };

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.RANGE_SELECTION_WINDOW);
  }

  async nextPage() {
    let { selectWindowToShow, saveProject } = this.props;

    saveProject();
    selectWindowToShow(AppWindowName.FINISH_WINDOW);
  }

  render() {
    let { t } = this.props;
    return (
      <div id="data-transfert-container">
        <Header displayName={t(STEP_4_COUPLING_SELECTION) || ""}></Header>
        <Body
          progress={this.state.progress}
          buffer={this.state.buffer}
          currentElementNbr={this.state.currentElementNbr}
          currentElementName={this.state.currentElementName}
          totalElementsNbr={this.state.totalElementsNbr}
          finished={this.state.finished}
        ></Body>
        <span></span>
        <Footer
          savedProjectsButtonShow={false}
          showNextButton={false}
          showBackButton={false}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    validatedItems: state.projects.currentProject.validatedItems,
    projectRegion: state.projects.currentProject.country,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  saveProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {
  loading: boolean;
  progress: number;
  buffer: number;
  currentElementNbr: number;
  currentElementName: string;
  totalElementsNbr: number;
  finished: boolean;
};

export default connector(withTranslation(["pam"])(DataTransfert));
