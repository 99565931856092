import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import ReverseSidesRoot from "./Components/root";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { overLapElements } from "../Overlap/Components/Waiter";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { ErrorFallback } from "../../../Components/CommonComponents/ErrorManagement";

export const functionalityId = "f8add0ba-cb6c-42c7-b59f-93f7cfb335fc";

export const trigger = async (config: any) => {
  api.windowsHandler.openWindow(
    400,
    150,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
  await overLapElements();
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Reverse Sides"
    >
      <ReverseSidesRoot />
    </ParentRoot>
  );
};

export const ReversSideRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Reverse Sides"
    >
      <ReverseSidesRoot />
    </ParentRoot>
  );
};

export const ReverseSides: Functionality = {
  Name: {
    French: "Permuter",
    English: "Flip",
    German: "Permuter",
    Italian: "Permuter",
    Indonesian: "Permuter",
  },
  Trigger: trigger,
  ShortDesc: {
    French: "Inverser les parements",
    English: "Reverse the facings",
    German: "Inverser les parements",
    Italian: "Inverser les parements",
    Indonesian: "Inverser les parements",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Permuter.png",
  RootComponent,
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
