import Button from "@material-ui/core/Button";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import flagFr from "../../../assets/fr-flag.png";
import logo from "../../../assets/logo_pam_plugin.png";
import flagEn from "../../../assets/uk-flag.png";
import "./header.scss";

function Header({ displayName = "", i18n, t }: Props) {
    function changeLanguage(lang: string = "English") {
        i18n.changeLanguage(lang);
    }
    return (
        <div id="pam-canalisation-header-container">
            <div id="colored-header-band"></div>
            <div id="header-heigth" className="row py-3">
                <div className="col-3 d-flex justify-content-center">
                    <img id="logo" src={logo} alt="Logo placeholder" />
                </div>
                <div
                    className="col-6 d-flex flex-column justify-content-center"
                    id="display-name-div"
                >
                    <p className="m-0 text-center">
                        <strong>{displayName.toUpperCase()}</strong>
                    </p>
                </div>
                <div className="col-3 d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column justify-content-start">
                        <div className="px-3 my-2 text-center d-inline-flex">
                            <Button
                                id="header-i18n-fr-button"
                                className="ml-2 medium-btn  lang-btn"
                                variant="contained"
                                onClick={(event) => {
                                    changeLanguage("French");
                                }}
                            >
                                <img
                                    src={flagFr}
                                    //style={{ height: "2em !important" }}
                                    className="round elevated"
                                    alt="FR"
                                ></img>
                            </Button>
                            <Button
                                id="header-i18n-en-button"
                                className="ml-2 medium-btn lang-btn"
                                variant="contained"
                                onClick={(event) => {
                                    changeLanguage("English");
                                }}
                            >
                                <img
                                    src={flagEn}
                                    //style={{ height: "2em !important" }}
                                    className="round elevated"
                                    alt="EN"
                                ></img>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = WithTranslation & {
    displayName?: string;
};

export default withTranslation(["pam"])(Header);
