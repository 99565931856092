
import {
  LOAD_SYSTEMS,
  LOAD_SYSTEMS_SUCCESS,
  LOAD_SYSTEMS_ERROR,
  LOAD_SYSTEMS_TO_UPDATED,
  LOAD_SYSTEMS_TO_UPDATED_ERROR,
  LoadSystemsActionTypes,
  UpdateSystemsState,
  LOAD_UPDATED_SYSTEMS_SUCCESS
} from "./types";

const initialState: UpdateSystemsState = {
  loadingSystems: false,
  currentSystemsToUpdate: [],
  currentSystems: [],
  systems: []
}

export default function updateSystemsReducer(
  state = initialState,
  action: LoadSystemsActionTypes
): UpdateSystemsState {
  switch (action.type) {
    case LOAD_SYSTEMS:
      return {
        ...state,
        loadingSystems: true,
      };
    case LOAD_SYSTEMS_SUCCESS:
      return {
        ...state,
        currentSystems: action.currentSystems,
        systems: action.systems,
        loadingSystems: false,
      };
    case LOAD_SYSTEMS_ERROR:
      return {
        ...state,
        loadingSystems: false,
      };
    case LOAD_SYSTEMS_TO_UPDATED:
      return {
        ...state,
        loadingSystems: true,
      };
    case LOAD_UPDATED_SYSTEMS_SUCCESS:
      return {
        ...state,
        currentSystemsToUpdate: action.currentSystems,
        loadingSystems: false,
      };
    case LOAD_SYSTEMS_TO_UPDATED_ERROR:
      return {
        ...state,
        loadingSystems: false,
      };
    default:
      return state;
  }
}
