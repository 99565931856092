import Axios, { AxiosInstance } from "axios";
import _, { concat, flattenDeep, forEach, map, uniq } from "lodash";
import { errorCodeKey } from "../ErrorManagement/utils/errorCodeEnum";
import { requiredAttributeDefaultArray } from "./helper";
import { authFilter } from "./Interceptors/authentication.interceptor";

/**
 * Axios instance
 */

interface IRetryWrapper {
  retry_time: number;
}

export const retryWrapper = (
  instance: AxiosInstance,
  options: IRetryWrapper,
  cng: any,
  headers: any
) => {
  const max_time = options.retry_time;
  let counter = 0;

  instance.interceptors.request.use(async (request) => {
    return await authFilter(request, cng);
  }, undefined);

  instance.interceptors.response.use(undefined, (error) => {
    const config = error.config;

    if (counter < max_time) {
      counter++;
      return new Promise((resolve) => {
        config.headers = headers.headers;
        resolve(instance(config));
      });
    }
    return Promise.reject(error);
  });
};

export async function bdd(url: string, body: any, headers: any, cng: any) {
  const instance = Axios.create({
    baseURL: `${cng.REACT_APP_BDDURL}`,
  });

  retryWrapper(instance, { retry_time: 8 }, cng, headers);
  return await instance.post(`${url}`, body, headers);
}

/**
 *  Fetch brand oid from bimdatabase using brand name
 * @param brandName brand name in string
 * @returns brand oid/id
 */
export const fetchBrand = async (cng: any, brandName: string = "Placo") => {
  try {
    const fetchBrandResponse = await bdd(
      "/objects/class/Brand/locale/en",
      {
        technicalNames: [brandName],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
      cng
    );

    return fetchBrandResponse.data.objects.map((ob: any) => {
      return ob.oid;
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch region oid from bimdatabase using region name
 * @param region region name in string
 * @returns region oid/id
 */
export const fetchRegion = async (cng: any, region: string = "FRANCE") => {
  try {
    const fetchRegionResponse = await bdd(
      "/objects/class/Region/locale/en",
      {
        text: region,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
      cng
    );
    return fetchRegionResponse.data.objects[0].oid;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param brandId brand oid
 * @param regionId region oid
 * @param technicalNames Optional Array of attributes `technicalNames`
 * @returns Attributes detail list
 */
export const fetchAttributes = async (
  brandId: string[],
  regionId: string[],
  cng: any,
  technicalNames: string[] = requiredAttributeDefaultArray
) => {
  try {
    const fetchAttributesResponse = await bdd(
      "/attributes/class/SGObjectAttribute/locale/en",
      {
        dependencies: [
          {
            className: "Brand",
            oids: brandId,
          },
          {
            className: "Region",
            oids: regionId,
          },
        ],
        technicalNames: technicalNames,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
      cng
    );

    return [...fetchAttributesResponse.data.attributes];
  } catch (error) {
    throw error;
  }
};

/**
 * The post request to fetch Placo solutions.
 * @param brandId The brand id for which solutions should be fetch
 * @param regionId The region id for which solutions should be fetch
 * @param returnAttributes The array of attribute oid's which should be return for each solution
 * @param includeAttributes The array of type 'IncludeAttributeType' for which solutions should be fetch
 * @returns The Placo solution list fetched from bimdatabase.
 */
export const fetchPlacoSolutions = async (
  brandId: string[],
  regionId: string[],
  attributes: any[],
  cng: any
) => {
  let response: {
    context: {
      attributeDependencies: [{ attributes: any[]; className: string }];
      objectDependencies: any[];
      relation: any[];
    };
    message: null;
    objects: any[];
  } = {
    context: {
      attributeDependencies: [
        { attributes: [], className: "SGObjectAttribute" },
      ],
      objectDependencies: [],
      relation: [],
    },
    message: null,
    objects: [],
  };

  const cloisonRes = await cloisonData(brandId, regionId, attributes, cng);

  if (cloisonRes) {
    response.context.attributeDependencies[0].attributes = uniq(
      concat(
        response.context.attributeDependencies[0].attributes,
        cloisonRes.context.attributeDependencies[0].attributes
      )
    );
    response.context.objectDependencies = uniq(
      concat(
        response.context.objectDependencies,
        cloisonRes.context.objectDependencies
      )
    );
    response.context.relation = uniq(
      concat(response.context.relation, cloisonRes.context.relation)
    );
    response.objects = uniq(concat(response.objects, cloisonRes.objects));
  }
  const doublageRes = await doublageData(brandId, regionId, attributes, cng);

  if (doublageRes) {
    response.context.attributeDependencies[0].attributes = uniq(
      concat(
        response.context.attributeDependencies[0].attributes,
        doublageRes.context.attributeDependencies[0].attributes
      )
    );
    response.context.objectDependencies = uniq(
      concat(
        response.context.objectDependencies,
        doublageRes.context.objectDependencies
      )
    );
    response.context.relation = uniq(
      concat(response.context.relation, doublageRes.context.relation)
    );
    response.objects = uniq(concat(response.objects, doublageRes.objects));
  }

  const plafondsDecorativRes = await plafondsDecoratifsData(
    brandId,
    regionId,
    attributes,
    cng
  );

  if (plafondsDecorativRes) {
    response.context.attributeDependencies[0].attributes = uniq(
      concat(
        response.context.attributeDependencies[0].attributes,
        plafondsDecorativRes.context.attributeDependencies[0].attributes
      )
    );
    response.context.objectDependencies = uniq(
      concat(
        response.context.objectDependencies,
        plafondsDecorativRes.context.objectDependencies
      )
    );
    response.context.relation = uniq(
      concat(response.context.relation, plafondsDecorativRes.context.relation)
    );
    response.objects = uniq(
      concat(response.objects, plafondsDecorativRes.objects)
    );
  }

  const plafondsRes = await plafondsData(brandId, regionId, attributes, cng);

  if (plafondsRes) {
    response.context.attributeDependencies[0].attributes = uniq(
      concat(
        response.context.attributeDependencies[0].attributes,
        plafondsRes.context.attributeDependencies[0].attributes
      )
    );
    response.context.objectDependencies = uniq(
      concat(
        response.context.objectDependencies,
        plafondsRes.context.objectDependencies
      )
    );
    response.context.relation = uniq(
      concat(response.context.relation, plafondsRes.context.relation)
    );
    response.objects = uniq(concat(response.objects, plafondsRes.objects));
  }

  const gainesTechRes = await gainesTechData(brandId, regionId, attributes, cng);

  if (gainesTechRes) {
    response.context.attributeDependencies[0].attributes = uniq(
      concat(
        response.context.attributeDependencies[0].attributes,
        gainesTechRes.context.attributeDependencies[0].attributes
      )
    );
    response.context.objectDependencies = uniq(
      concat(
        response.context.objectDependencies,
        gainesTechRes.context.objectDependencies
      )
    );
    response.context.relation = uniq(
      concat(response.context.relation, gainesTechRes.context.relation)
    );
    response.objects = uniq(concat(response.objects, gainesTechRes.objects));
  }

  return response;
};

const cloisonData = async (
  brandId: string[],
  regionId: string[],
  attributes: any[],
  cng: any
) => {
  const gfrWorksNameaid = _.find(attributes, {
    technicalName: "GFR-Works name",
  }).aid;

  let checkAttribute = [
    {
      aid: gfrWorksNameaid,
      exactSearch: true,
      technicalName: "GFR-Works name",
      values: ["Cloisons"],
    },
  ];

  let returnAttributeOids = attributes.map((attr: any) => attr.aid);

  const responseCloisonData = await bdd(
    "/objects/class/SGObject/locale/en",
    {
      attributes: checkAttribute,
      dependencies: [
        {
          className: "Brand",
          oids: brandId,
        },
        {
          className: "Region",
          oids: regionId,
        },
      ],
      types: ["Solution Product"],
      principal: true,
      returnAttributes: returnAttributeOids,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );

  try {
    const parsedData = JSON.parse(responseCloisonData.data);
    return parsedData;
  } catch (error) { }

  return responseCloisonData.data;
};

const doublageData = async (
  brandId: string[],
  regionId: string[],
  attributes: any[],
  cng: any
) => {
  const gfrWorksNameaid = _.find(attributes, {
    technicalName: "GFR-Works name",
  }).aid;

  let checkAttribute = [
    {
      aid: gfrWorksNameaid,
      exactSearch: true,
      technicalName: "GFR-Works name",
      values: ["Isolation des murs"],
    },
  ];

  let returnAttributeOids = attributes.map((attr: any) => attr.aid);

  const responseDoublage = await bdd(
    "/objects/class/SGObject/locale/en",
    {
      attributes: checkAttribute,
      dependencies: [
        {
          className: "Brand",
          oids: brandId,
        },
        {
          className: "Region",
          oids: regionId,
        },
      ],
      types: ["Solution Product"],
      principal: true,
      returnAttributes: returnAttributeOids,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );

  try {
    const parsedData = JSON.parse(responseDoublage.data);
    return parsedData;
  } catch (error) { }

  return responseDoublage.data;
};

const plafondsDecoratifsData = async (
  brandId: string[],
  regionId: string[],
  attributes: any[],
  cng: any
) => {
  const gfrWorksNameaid = _.find(attributes, {
    technicalName: "GFR-Works name",
  }).aid;

  let checkAttribute = [
    {
      aid: gfrWorksNameaid,
      exactSearch: true,
      technicalName: "GFR-Works name",
      values: ["Plafonds décoratifs"],
    },
  ];

  let returnAttributeOids = attributes.map((attr: any) => attr.aid);

  const responseDecoratif = await bdd(
    "/objects/class/SGObject/locale/en",
    {
      attributes: checkAttribute,
      dependencies: [
        {
          className: "Brand",
          oids: brandId,
        },
        {
          className: "Region",
          oids: regionId,
        },
      ],
      types: ["Solution Product"],
      principal: true,
      returnAttributes: returnAttributeOids,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );

  try {
    const parsedData = JSON.parse(responseDecoratif.data);
    return parsedData;
  } catch (error) { }

  return responseDecoratif.data;
};

const plafondsData = async (
  brandId: string[],
  regionId: string[],
  attributes: any[],
  cng: any
) => {
  const gfrWorksNameaid = _.find(attributes, {
    technicalName: "GFR-Works name",
  }).aid;

  let checkAttribute = [
    {
      aid: gfrWorksNameaid,
      exactSearch: true,
      technicalName: "GFR-Works name",
      values: ["Plafonds"],
    },
  ];

  let returnAttributeOids = attributes.map((attr: any) => attr.aid);

  const responsePlafonds = await bdd(
    "/objects/class/SGObject/locale/en",
    {
      attributes: checkAttribute,
      dependencies: [
        {
          className: "Brand",
          oids: brandId,
        },
        {
          className: "Region",
          oids: regionId,
        },
      ],
      types: ["Solution Product"],
      principal: true,
      returnAttributes: returnAttributeOids,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );

  try {
    const parsedData = JSON.parse(responsePlafonds.data);
    return parsedData;
  } catch (error) { }

  return responsePlafonds.data;
};

const gainesTechData = async (
  brandId: string[],
  regionId: string[],
  attributes: any[],
  cng: any
) => {
  const gfrWorksNameaid = _.find(attributes, {
    technicalName: "GFR-Works name",
  }).aid;

  let checkAttribute = [
    {
      aid: gfrWorksNameaid,
      exactSearch: true,
      technicalName: "GFR-Works name",
      values: ["Gaines techniques"],
    },
  ];

  let returnAttributeOids = attributes.map((attr: any) => attr.aid);

  const responseGainesTech = await bdd(
    "/objects/class/SGObject/locale/en",
    {
      attributes: checkAttribute,
      dependencies: [
        {
          className: "Brand",
          oids: brandId,
        },
        {
          className: "Region",
          oids: regionId,
        },
      ],
      types: ["Solution Product"],
      principal: true,
      returnAttributes: returnAttributeOids,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );

  try {
    const parsedData = JSON.parse(responseGainesTech.data);
    return parsedData;
  } catch (error) { }

  return responseGainesTech.data;
};


/**
 * Returns Placo solution details
 * @param systemOids The Array of Placo products oid's
 * @returns The placo details
 */
export const fetchPlacoSolutionsDetails = async (
  systemOids: string[],
  cng: any
) => {
  try {
    const { data } = await bdd(
      `/objects/details/class/SGObject/locale/en`,
      systemOids,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
      cng
    );
    return { data };
  } catch (error) {
    throw error;
  }
};

/**
 * Returns Placo solution details
 * @param systemOids The Array of Placo products oid's
 * @returns The placo details
 */
export const fetchPlacoSolutionsDetailsWithRelatedChildren = async (
  systemOids: string[],
  cng: any
) => {
  const { data } = await bdd(
    `${cng.REACT_APP_BDDURL}/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true`,
    systemOids,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );
  return data;
};

export const fetchPlacoSolutionsDetailsWithRelatedChildrenRecursiveChildrenInGroup =
  async (systemOids: string[], cng: any) => {
    let requestArray: any[] = [];
    forEach(systemOids, (systemids, index) => {
      requestArray.push(
        bdd(
          `${cng.REACT_APP_BDDURL}/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true&recursiveChildren=true`,
          [systemids],
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          },
          cng
        )
      );
    });

    return Axios.all(requestArray)
      .then(
        Axios.spread((...responses) => {
          return flattenDeep(map(responses, "data"));

          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });
  };

export const fetchPlacoDataIncludeRelatedObjects = async (
  systemOids: string[],
  cng: any
) => {
  let requestArray: any[] = [];
  forEach(systemOids, (systemids, index) => {
    requestArray.push(
      bdd(
        `/objects/details/class/SGObject/locale/en?includeRelatedObjects=true`,
        [systemids],
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        },
        cng
      )
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return Promise.reject(error);
          //throw error;
        })
    );
  });

  return Axios.all(requestArray)
    .then(
      Axios.spread((...responses) => {
        return flattenDeep(map(responses, "data"));

        // use/access the results
      })
    )
    .catch((errors) => {
      return Promise.reject({
        code: errorCodeKey.PB_IN_H_001,
        stack: errors.stack,
      });
      //throw errors;
      // react on errors.
    });
};

export const fetchPlacoDataIncludeChildern = async (
  systemOids: string[],
  cng: any
) => {
  let requestArray: any[] = [];
  forEach(systemOids, (systemids, index) => {
    requestArray.push(
      bdd(
        `/objects/details/class/SGObject/locale/en?includeChildren=true&recursiveChildren=true`,
        [systemids],
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        },
        cng
      )
    );
  });

  return Axios.all(requestArray)
    .then(
      Axios.spread((...responses) => {
        return flattenDeep(map(responses, "data"));

        // use/access the results
      })
    )
    .catch((error: any) => {
      // react on errors.
      return Promise.reject(error);
    });
};

/**
 *
 * @param technicalNames string array of placo technical names
 * @returns List of placo solution object
 */
export const fetchPlacoSolutionsByTechnicalNames = async (
  technicalNames: string[],
  cng: any
) => {
  try {
    const { data } = await bdd(
      `/objects/class/SGObject/locale/en`,
      {
        technicalNames: technicalNames,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
      cng
    );

    return { data };
  } catch (error: any) {
    return Promise.reject(error);
    //throw error;
  }
};

export const fetchPlacoSolutionsByTranslation = async (
  translations: string[],
  cng: any
) => {
  try {
    const { data } = await bdd(
      `/objects/class/SGObject/locale/en`,
      {
        externalNames: translations,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
      cng
    );
    return { data };
  } catch (error) {
    throw error;
  }
};

/**
 * The post request to fetch Placo solutions.
 * @param brandId The brand id for which solutions should be fetch
 * @param regionId The region id for which solutions should be fetch
 * @param returnAttributes The array of attribute oid's which should be return for each solution
 * @param includeAttributes The array of type 'IncludeAttributeType' for which solutions should be fetch
 * @returns The Placo solution list fetched from bimdatabase.
 */
export const fetchSolutions = async (
  brandId: string[],
  regionId: string[],
  cng: any
) => {
  const response = await bdd(
    "/objects/class/SGObject/locale/en",
    {
      dependencies: [
        {
          className: "Brand",
          oids: brandId,
        },
        {
          className: "Region",
          oids: regionId,
        },
      ],
      types: ["Solution Product", "System"],
      principal: true,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    cng
  );

  if (response.status === 200) {
    return response.data.objects;
  }
  return [];
};
