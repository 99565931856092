import {
  setGroupTreesAction,
  setSelectorTypeAction,
  selectLevelAction,
  selectTypeAction,
  initMappingTableAction,
  updatingMappingTableAction,
  saveBufferAction,
  deleteSelectionAction,
  duplicateSelectionAction,
  DUPLICATE_SELECTION,
  editSelectionAction,
  EDIT_SELECTION,
  loadSelectionsAction,
  LOAD_SELECTIONS,
  selectSystemAction,
  duplicateSystemAction,
  applyFiltersAction,
  resetFiltersAction,
  setFilterTypeAction,
  searchAction,
  scrolledBottomAction,
  selectBufferAction,
  multiSelectBufferAction,
  tempMultiSelectBufferAction,
  resetBufferAction,
  setActiveRowIndexAction,
  showSystemDetailsAction,
  hideSystemDetailsAction,
  toggleZoneAction,
  DUPLICATE_BUFFER_SELECTION,
  DELETE_BUFFER_ELEMENT,
  deleteBufferElement,
  SET_GLOBAL_LOADER,
  globalLoading,
  updateBufferSelection,
  UPDATE_BUFFER_SELECTION,
  toggleSelectionRowAction,
  TOGGLE_SELECTION_ROW,
  toggleAllSelectionRowAction,
  TOGGLE_ALL_SELECTION_ROW,
  updateSelectionName,
  UPDATE_SELECTION_NAME,
  UPDATE_SELECTION_COLOR,
  updateSelectionColor,
  updatingOtherPlacoSystems,
  SELECT_PLACO_OTHER_SYSTEM,
  selectPlacoOtherSystemAction,
  SET_CALPINAGE_SELECTION,
  SET_REPERAGE_SELECTION,
  SET_DOSSIER_SELECTION,
  SET_METRES_SELECTION,
  showLayoutDetailsAction,
  hideLayoutDetailsAction,
  selectorTp,
  REPLACE_BUFFER_SELECTION,
  ReplaceBufferSelection,
  RESET_CALPINAGE_SELECTION,
  ResetCalpinageSelection,
  saveSelectionAction,
  SET_PROJECTDATA,
  SET_HISTORY_PATH,
  resetTempBufferAction,
} from "./types";
import { generateSelection } from "../Helpers";
import { ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { ThunkAction } from "redux-thunk";
import { SelectionStore } from "../Reducers";
import {
  TypeData,
  LevelData,
  SelectedItem,
  RowOptions,
  PlacoOptions,
  FilterType,
  initProjectData,
} from "../../../../RevitJS/Types/StoreTypes";
import {
  elementTypes,
  levelData,
  getSelectedItems,
  selectedItemsManualSelection,
  getLayoutAttributesAid,
  elementTypesByLevel,
} from "../../../../RevitJS/Helpers";
import { AxiosInstance } from "axios";
import { fetchPlacoData, sortPlacoDataByGFRWorksName } from "../Requests";
import {
  PIMLayoutAttributesAid,
  ProductDetailData,
  CustomProductDetailData,
} from "../../../../RevitJS/Types/BddTypes";
import { getSystemsDetails } from "../Helpers";
import { SELECT_SYSTEM } from "../../../PlacoBIM/Draw2/Actions/types";
import { api } from "../../../../RevitJS/API";
import { ElementParameter } from "../Actions/objectTypes";
import {
  InitLanguageAction,
  INIT_LANGUAGE,
  SET_ELEMENT_PARAMETERS,
} from "./types";
import _ from "lodash";
import { Selection } from "./types";
import { PlacoProducts, SolutionFilterType } from "../Types";

export async function asyncForEach(array: any, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export const createSelectionNew =
  (name: string, revitViewName: string, isZone?: boolean): any =>
  (dispatch: any, getState: any) => {
    const state = getState();
    const generateSel = generateSelection<RowOptions>(name, revitViewName);
    generateSel.Zone = isZone ?? state.moduleData.moduleParZone;
    dispatch({
      type: "CREATE_SELECTION",
      selection: generateSel,
    });
  };

export const setGroupTrees = (
  wallTree: ElementsTree,
  ceilingTree: ElementsTree
): setGroupTreesAction => {
  return {
    type: "SET_GROUP_TREES",
    wallTree,
    ceilingTree,
  };
};

export const setSelectorType = (
  selectorType: selectorTp
): setSelectorTypeAction => {
  return {
    type: "SET_SELECTOR_TYPE",
    selectorType,
  };
};

export const selectAllType =
  (
    toSelect: boolean
  ): ThunkAction<void, SelectionStore, unknown, selectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    let typesData: TypeData[];
    let selectedTypes: string[];

    if (state.selectorType === "wall") {
      typesData = state.wallTypesData;
      selectedTypes = state.wallSelectedTypes;
    } else {
      typesData = state.ceilingTypesData;
      selectedTypes = state.ceilingSelectedTypes;
    }

    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    typesData.forEach((element) => {
      if (!element.Checked && toSelect) {
        element.Checked = true;
        selectedTypesCopy = [...selectedTypesCopy, element.Type];
      }
      if (element.Checked && !toSelect) {
        element.Checked = false;
        selectedTypesCopy = selectedTypesCopy.filter(
          (type) => type !== element.Type
        );
      }
    });

    dispatch({
      type: "SELECT_TYPE",
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
      selectorType: state.selectorType,
    });
  };

export const selectType =
  (
    typeName: string
  ): ThunkAction<void, SelectionStore, unknown, selectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    let typesData: TypeData[];
    let selectedTypes: string[];

    if (state.selectorType === "wall") {
      typesData = state.wallTypesData;
      selectedTypes = state.wallSelectedTypes;
    } else {
      typesData = state.ceilingTypesData;
      selectedTypes = state.ceilingSelectedTypes;
    }

    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    let selectedTypeIndex = typesData.findIndex(
      (type) => type.Type === typeName
    );

    if (typesData[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedTypes.filter((type) => type !== typeName);
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedTypes, typeName];
    }

    dispatch({
      type: "SELECT_TYPE",
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
      selectorType: state.selectorType,
    });
  };

export const selectAllLevel =
  (
    toSelect: boolean
  ): ThunkAction<void, SelectionStore, unknown, selectLevelAction> =>
  async (dispatch: any, getState: any) => {
    let levelsData: LevelData[];
    let selectedLevels: string[];
    let selectionTree: ElementsTree;
    let state = getState();

    if (state.selectorType === "wall") {
      levelsData = state.wallLevelsData;
      selectedLevels = state.wallSelectedLevels;
      selectionTree = state.wallTree;
    } else {
      levelsData = state.ceilingLevelsData;
      selectedLevels = state.ceilingSelectedLevels;
      selectionTree = state.ceilingTree;
    }
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    levelsData.forEach((element) => {
      if (!element.Checked && toSelect) {
        element.Checked = true;
        selectedLevelsCopy = [...selectedLevelsCopy, element.Name];
      }
      if (element.Checked && !toSelect) {
        element.Checked = false;
        selectedLevelsCopy = selectedLevelsCopy.filter(
          (level) => level !== element.Name
        );
      }
    });

    dispatch({
      type: "SELECT_LEVEL",
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [] as string[],
      levelsData: levelsDataCopy,
      typesDataByLevel: elementTypesByLevel(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
      typesData: elementTypes(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
      selectorType: state.selectorType,
    });
  };

export const selectLevel =
  (
    levelName: string
  ): ThunkAction<void, SelectionStore, unknown, selectLevelAction> =>
  async (dispatch: any, getState: any) => {
    let levelsData: LevelData[];
    let selectedLevels: string[];
    let selectionTree: ElementsTree;
    let state = getState();

    if (state.selectorType === "wall") {
      levelsData = state.wallLevelsData;
      selectedLevels = state.wallSelectedLevels;
      selectionTree = state.wallTree;
    } else {
      levelsData = state.ceilingLevelsData;
      selectedLevels = state.ceilingSelectedLevels;
      selectionTree = state.ceilingTree;
    }
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    let selectedLevelIndex = levelsData.findIndex(
      (level) => level.Name === levelName
    );

    if(selectedLevelIndex<0)
    {
      dispatch({
        type: "SELECT_LEVEL",
        selectedLevels: [],
        selectedTypes: [] as string[],
        levelsData:state.wallLevelsData ,
        typesDataByLevel: elementTypesByLevel(
          levelData(state.wallTree, [])
        ) as TypeData[],
        typesData: elementTypes(
          levelData(state.wallTree, [])
        ) as TypeData[],
        selectorType: "wall",
      });
      dispatch({
        type: "SELECT_LEVEL",
        selectedLevels: [],
        selectedTypes: [] as string[],
        levelsData: state.ceilingLevelsData,
        typesDataByLevel: elementTypesByLevel(
          levelData(state.ceilingTree, [])
        ) as TypeData[],
        typesData: elementTypes(
          levelData(state.ceilingTree, [])
        ) as TypeData[],
        selectorType: "ceiling",
      });
    }else{
      if (levelsData[selectedLevelIndex].Checked) {
        levelsDataCopy[selectedLevelIndex].Checked = false;
        selectedLevelsCopy = selectedLevels.filter(
          (level) => level !== levelName
        );
      } else {
        levelsDataCopy[selectedLevelIndex].Checked = true;
        selectedLevelsCopy = [...selectedLevels, levelName];
      }
      dispatch({
        type: "SELECT_LEVEL",
        selectedLevels: selectedLevelsCopy,
        selectedTypes: [] as string[],
        levelsData: levelsDataCopy,
        typesDataByLevel: elementTypesByLevel(
          levelData(selectionTree, selectedLevelsCopy)
        ) as TypeData[],
        typesData: elementTypes(
          levelData(selectionTree, selectedLevelsCopy)
        ) as TypeData[],
        selectorType: state.selectorType,
      });
    }


    // dispatch({
    //   type: "SELECT_LEVEL",
    //   selectedLevels: selectedLevelsCopy,
    //   selectedTypes: [] as string[],
    //   levelsData: levelsDataCopy,
    //   typesDataByLevel: elementTypesByLevel(
    //     levelData(selectionTree, selectedLevelsCopy)
    //   ) as TypeData[],
    //   typesData: elementTypes(
    //     levelData(selectionTree, selectedLevelsCopy)
    //   ) as TypeData[],
    //   selectorType: state.selectorType,
    // });
  };

export const initMappingTable =
  (): ThunkAction<
    void,
    SelectionStore,
    unknown,
    initMappingTableAction<RowOptions> | updatingMappingTableAction<RowOptions>
  > =>
  async (dispatch: any, getState: any) => {
    const {
      wallTree,
      wallSelectedLevels,
      wallSelectedTypes,
      ceilingTree,
      ceilingSelectedLevels,
      ceilingSelectedTypes,
      bufferSelection,
    } = getState();

    let wallSelectedItems: SelectedItem[] = getSelectedItems(
      wallTree,
      wallSelectedLevels,
      wallSelectedTypes
    );
    let ceilingSelectedItems: SelectedItem[] = getSelectedItems(
      ceilingTree,
      ceilingSelectedLevels,
      ceilingSelectedTypes
    );

    let wallLevels: string[][] = wallSelectedItems.map((lev) => lev.Levels);
    let wallFlattenLevels = wallLevels.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    let ceilingLevels: string[][] = ceilingSelectedItems.map(
      (lev) => lev.Levels
    );
    let ceilingFlattenLevels = ceilingLevels.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    let levelsSet = new Set([...wallFlattenLevels, ...ceilingFlattenLevels]);
    let levels = Array.from(levelsSet) as string[];

    if (bufferSelection?.Update) {
      dispatch({
        type: "UPDATE_MAPPING_TABLE",
        levels: levels,
        wallMappingRows: wallSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              Duplicated: 1,
              MappedSystem: null,
            },
            OtherPlacoSystem: false,
          };
        }),
        ceilingMappingRows: ceilingSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              MappedSystem: null,
              Duplicated: 1,
            },
            OtherPlacoSystem: false,
          };
        }),
      });
    } else {
      dispatch({
        type: "INIT_MAPPING_TABLE",
        levels: levels,
        wallMappingRows: wallSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              Duplicated: 1,
              MappedSystem: null,
            },
            OtherPlacoSystem: false,
          };
        }),
        ceilingMappingRows: ceilingSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              MappedSystem: null,
              Duplicated: 1,
            },
            OtherPlacoSystem: false,
          };
        }),
        othersMappingRows: [],
      });
    }
  };

export const initMappingTableManualSelection =
  (): any => async (dispatch: any, getState: any) => {
    const { wallTree, ceilingTree, bufferSelection } = getState();
    let wallSelectedItems: SelectedItem[] =
      selectedItemsManualSelection(wallTree);
    let ceilingSelectedItems: SelectedItem[] =
      selectedItemsManualSelection(ceilingTree);

    let wallLevels: string[][] = wallSelectedItems.map((lev) => lev.Levels);
    let wallFlattenLevels = wallLevels.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    let ceilingLevels: string[][] = ceilingSelectedItems.map(
      (lev) => lev.Levels
    );
    let ceilingFlattenLevels = ceilingLevels.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    let levelsSet = new Set([...wallFlattenLevels, ...ceilingFlattenLevels]);
    let levels = Array.from(levelsSet) as string[];

    if (bufferSelection?.Update) {
      dispatch({
        type: "UPDATE_MAPPING_TABLE",
        levels,
        wallMappingRows: wallSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              MappedSystem: null,
              Duplicated: 1,
            },
            OtherPlacoSystem: false,
          };
        }),
        ceilingMappingRows: ceilingSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              MappedSystem: null,
              Duplicated: 1,
            },
            OtherPlacoSystem: false,
          };
        }),
      });
    } else {
      dispatch({
        type: "INIT_MAPPING_TABLE",
        levels,
        wallMappingRows: wallSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              MappedSystem: null,
              Duplicated: 1,
            },
            OtherPlacoSystem: false,
          };
        }),
        ceilingMappingRows: ceilingSelectedItems.map((item, index) => {
          return {
            Checked: true,
            Index: index,
            RevitSelection: { RevitType: item.Type, Ids: item.Ids },
            Options: {
              MappedSystem: null,
              Duplicated: 1,
            },
            OtherPlacoSystem: false,
          };
        }),
        othersMappingRows: [],
      });
    }
  };

export const saveBuffer =
  (): ThunkAction<
    void,
    SelectionStore,
    unknown,
    saveBufferAction<PlacoOptions>
  > =>
  async (dispatch: any, getState: any) => {
    const { bufferSelection } = getState();
    const date = new Date();
    if (bufferSelection) {
      bufferSelection.Date = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      bufferSelection.Time = Date.now();

      //BSPP-167 Getting wall details for to extract final levels of all walls
      const wallIds = _.flattenDeep(
        _.map(bufferSelection.SelectionByType.wall.Rows, "RevitSelection.Ids")
      );
      const wallDetails = await api.queries.getWallsData(wallIds);

      const ceilingIds = _.flattenDeep(
        _.map(
          bufferSelection.SelectionByType.ceiling.Rows,
          "RevitSelection.Ids"
        )
      );
      const ceilingDetails = await api.queries.getCeilingsData(ceilingIds);

      const wallLevelNames = _.map(wallDetails, "LevelName");
      const ceilingLevelNames = _.map(ceilingDetails, "LevelName");

      bufferSelection.Levels = _.uniq(
        _.concat(wallLevelNames, ceilingLevelNames)
      );
      //----

      dispatch({
        type: "SAVE_BUFFER",
        selection: bufferSelection,
      });
    }

    // if (bufferSelection) {

    //   /// Check bufferselection was change or not.
    //   /// If bufferselection change then only update selection.
    //   /// This will help for calepinage and Dossier updates which are based on selection update.

    //   let selectionObject = _.get(selections, bufferSelection.Id);
    //   if (!_.isEqual(bufferSelection, selectionObject)) {
    //     let date = new Date();
    //     bufferSelection.Date = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
    //     bufferSelection.Time = Date.now();
    //     dispatch({
    //       type: "SAVE_BUFFER",
    //       selection: bufferSelection,
    //     });
    //   }
    // }
  };

export const saveSelection =
  (updatedSelection: {
    [key: string]: Selection<PlacoOptions>;
  }): ThunkAction<void, SelectionStore, unknown, saveSelectionAction> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: "SAVE_SELECTIONS",
      selection: updatedSelection,
    });
  };

export const deleteSelection = (selectionId: string): deleteSelectionAction => {
  return {
    type: "DELETE_SELECTION",
    selectionId,
  };
};

/**
 * Fetch plco solutions
 * @param bdd AxiosInstance for calling bimdatabaseAPI
 */
export const fetchRemoteData =
  (bdd: AxiosInstance): ThunkAction<void, SelectionStore, unknown, any> =>
  async (dispatch: any, getState: any) => {
    const { filters } = getState();
    dispatch(toggleGlobalLoading(true));
    let systems = await fetchPlacoData(bdd);

    let allSystemDetails = await getSystemsDetails(
      systems.map((system) => system.oid),
      bdd
    );

    let sortedSystems = await sortPlacoDataByGFRWorksName(
      allSystemDetails.data,
      filters,
      systems,
      bdd
    );

    let partitionsDetails = sortedSystems.partitionsDetails;
    let liningWallsDetails = sortedSystems.liningWallsDetails;
    let ceilingsDetails = sortedSystems.ceilingsDetails;

    const protectionFeuDetails = sortedSystems.protectionFeuDetails;
    const gainesTechDetails = sortedSystems.gainesTechDetails;
    const conduitGainesDetails = sortedSystems.conduitGainesDetails;

    let layoutAttributesAid = getLayoutAttributesAid(
      sortedSystems.partitionsDetails.attributes
    ) as PIMLayoutAttributesAid;

    dispatch({
      type: "FETCH_PLACO_DATA",
      layoutAttributesAid,
      liningWallsDetails,
      partitionsDetails,
      ceilingsDetails,
      protectionFeuDetails,
      gainesTechDetails,
      conduitGainesDetails,
    });
  };

export const duplicateSelection = (
  selectionId: string
): duplicateSelectionAction => {
  return {
    type: DUPLICATE_SELECTION,
    selectionId,
  };
};

export const editSelection = <T extends RowOptions>(
  selection: Selection<T>
): editSelectionAction<T> => {
  return {
    type: EDIT_SELECTION,
    selection,
  };
};

export const updateSelectionNameAction = (
  Index: string,
  Name: string
): updateSelectionName => {
  return {
    type: UPDATE_SELECTION_NAME,
    Index,
    Name,
  };
};

export const updateSelectionColorAction = (
  Index: string,
  Color: any
): updateSelectionColor => {
  return {
    type: UPDATE_SELECTION_COLOR,
    Index,
    Color,
  };
};

export const loadSelections = <T extends RowOptions>(selections: {
  [key: string]: Selection<PlacoOptions>;
}): loadSelectionsAction<T> => {
  return {
    type: LOAD_SELECTIONS,
    selections,
  };
};

export const selectSystem = (
  Index: number,
  System: ProductDetailData | CustomProductDetailData,
  SelectorType: selectorTp
): selectSystemAction => {
  return {
    type: SELECT_SYSTEM,
    SelectorType,
    Index,
    System,
  };
};

export const duplicateBufferSystem = (
  Index: number,
  SelectorType: selectorTp
): duplicateSystemAction => {
  return {
    type: DUPLICATE_BUFFER_SELECTION,
    Index,
    SelectorType,
  };
};

export const deleteInBufferSystem = (
  Index: number,
  SelectorType: selectorTp
): deleteBufferElement => {
  return {
    type: DELETE_BUFFER_ELEMENT,
    Index,
    SelectorType,
  };
};

export const initProductSelector =
  (): any => async (dispatch: any, getState: any) => {
    const { filterType, placoData, selectorType } = getState();
    let systems = ((
      filterType:
        | "partitionWall"
        | "liningWall"
        | "ceiling"
        | "tous"
        | "protectionfeu"
        | "gainestechniques"
        | "conduitgaines",
      selectorType: selectorTp,
      placoData: PlacoProducts
    ) => {
      if (filterType === "partitionWall") {
        return placoData.partitionWalls;
      } else if (filterType === "liningWall") {
        return placoData.liningWalls;
      } else if (filterType === "ceiling") {
        return placoData.ceilings;
      } else if (filterType === "protectionfeu") {
        return placoData.protectionFeu;
      } else if (filterType === "conduitgaines") {
        return placoData.conduitGaines;
      } else if (filterType === "gainestechniques") {
        return placoData.gainesTech;
      } else {
        return placoData.partitionWalls;
      }
    })(filterType, selectorType, placoData);

    dispatch({
      type: "INIT_SELECTOR",
      filteredElements: systems,
      displayedElements: systems.length > 20 ? systems.slice(0, 20) : systems,
    });
  };

export const applyFilters =
  (
    filterName: string,
    filterValue: any
  ): ThunkAction<void, SelectionStore, unknown, applyFiltersAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterType, placoData, searchedWord } = getState();

    const activeFilters = ((
      filterType: FilterType,
      filters: SolutionFilterType
    ) => {
      switch (filterType) {
        case "partitionWall":
          return [...filters.partitionWalls];
        case "liningWall":
          return [...filters.liningWalls];

        case "protectionfeu":
          return [...filters.protectionFeu];
        case "gainestechniques":
          return [...filters.gainesTech];
        case "conduitgaines":
          return [...filters.conduitGaines];
        case "ceiling":
        default:
          return [...filters.ceilings];
      }
    })(filterType, filters);

    const activeData = ((filterType: FilterType, placoData: PlacoProducts) => {
      switch (filterType) {
        case "ceiling":
          return placoData.ceilings;
        case "liningWall":
          return placoData.liningWalls;
        case "protectionfeu":
          return placoData.protectionFeu;
        case "gainestechniques":
          return placoData.gainesTech;
        case "conduitgaines":
          return placoData.conduitGaines;
        default:
          return placoData.partitionWalls;
      }
    })(filterType, placoData);

    const updatedFilters = activeFilters.map((filter) => {
      if (filter.pimAttribute !== filterName) {
        return filter;
      }
      filter.value = filterValue;
      return filter;
    });

    let filtersCopy = { ...filters };

    switch (filterType) {
      case "ceiling":
        filtersCopy.ceilings = updatedFilters;
        break;
      case "liningWall":
        filtersCopy.liningWalls = updatedFilters;
        break;
      case "protectionfeu":
        filtersCopy.protectionFeu = updatedFilters;
        break;
      case "gainestechniques":
        filtersCopy.gainesTech = updatedFilters;
        break;
      case "conduitgaines":
        filtersCopy.conduitGaines = updatedFilters;
        break;
      default:
        filtersCopy.partitionWalls = updatedFilters;
        break;
    }

    let filteredElements = filterElements3(updatedFilters, activeData);

    if (searchedWord !== "") {
      filteredElements = _.filter(filteredElements, function (o) {
        let includeE = true;
        searchedWord.split(";").forEach((element: string) => {
          if (
            !_.includes(
              o.translation.toUpperCase().replace("®", "").replace(/\s/g, ""),
              element.toUpperCase().replace("®", "").replace(/\s/g, "")
            )
          ) {
            includeE = false;
          }
        });
        return includeE;
      });
    }

    switch (filterType) {
      case "ceiling":
        filtersCopy.ceilings = setFiltersOptions(
          filtersCopy.ceilings,
          filteredElements
          // should be fixed before use
          // true,
          // filterName
        );
        break;
      case "liningWall":
        filtersCopy.liningWalls = setFiltersOptions(
          filtersCopy.liningWalls,
          filteredElements
          // should be fixed before use
          // true,
          // filterName
        );
        break;
      case "protectionfeu":
        filtersCopy.protectionFeu = setFiltersOptions(
          filtersCopy.protectionFeu,
          filteredElements
          // should be fixed before use
          // true,
          // filterName
        );
        break;
      case "gainestechniques":
        filtersCopy.gainesTech = setFiltersOptions(
          filtersCopy.gainesTech,
          filteredElements
          // should be fixed before use
          // true,
          // filterName
        );
        break;
      case "conduitgaines":
        filtersCopy.conduitGaines = setFiltersOptions(
          filtersCopy.conduitGaines,
          filteredElements
          // should be fixed before use
          // true,
          // filterName
        );
        break;
      default:
        filtersCopy.partitionWalls = setFiltersOptions(
          filtersCopy.partitionWalls,
          filteredElements
          // should be fixed before use
          // true,
          // filterName
        );
        break;
    }

    dispatch({
      type: "APPLY_FILTERS",
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      filters: filtersCopy,
    });
  };

export const filterElements3 = (filters: any, partitionsDetails: any) => {
  return filters.reduce(
    (acc: any, current: any) => {
      if (current.options && current.value && current.value !== "") {
        if (typeof current.value === "object") {
          let ftArray = _.filter(current.value, function (o) {
            return o === null;
          });
          if (ftArray.length === current.value.length) {
            return acc;
          }
        }
        let matchingDetails;
        switch (current.criterion) {
          case "GreaterThan":
            return acc.filter((el: any, i: any) => {
              matchingDetails = partitionsDetails.find(
                (details: any) => details.technicalName === el.technicalName
              );

              return matchingDetails[current.pimAttribute] >= current.value;
            });
          case "Equals":
            return acc.filter((el: any, i: any) => {
              matchingDetails = partitionsDetails.find(
                (details: any) => details.technicalName === el.technicalName
              );

              return matchingDetails[current.pimAttribute] === current.value;
            });
          case "Includes":
            let gt = acc.filter((el: any, i: any) => {
              matchingDetails = partitionsDetails.find(
                (details: any) => details.technicalName === el.technicalName
              );

              return current.value.includes(
                matchingDetails[current.pimAttribute]
              );
            });
            return gt;
          case "Between":
            if (current.value[0] !== null && current.value[1] !== null) {
              return acc.filter((el: any, i: any) => {
                matchingDetails = partitionsDetails.find(
                  (details: any) => details.technicalName === el.technicalName
                );

                return (
                  current.value[0] <= matchingDetails[current.pimAttribute] &&
                  matchingDetails[current.pimAttribute] <= current.value[1]
                );
              });
            } else {
              return acc;
            }

          default:
            return acc;
        }
      } else {
        return acc;
      }
    },
    [...partitionsDetails]
  );
};

export const setFiltersOptions = (
  filters: any[],
  systemsDetails: any,
  update = false,
  restrictedFilter = null
) => {
  let filtersLocal = _.cloneDeep(filters);
  filtersLocal.forEach((filterLocal) => {
    if (
      update &&
      (restrictedFilter === null ||
        filterLocal.pimAttribute !== restrictedFilter)
    ) {
      let tmpOptions: any = [];
      systemsDetails.forEach((system: any) => {
        let attrValue = system[filterLocal.pimAttribute];
        if (attrValue !== undefined) {
          if (!tmpOptions.includes(attrValue)) {
            tmpOptions.push(attrValue);
          }
        } else {
        }
      });
      filterLocal.options = _.cloneDeep(tmpOptions);
    } else {
      systemsDetails.forEach((system: any) => {
        filterLocal.options = filterLocal.options || [];
        let attrValue = system[filterLocal.pimAttribute];
        if (attrValue !== undefined) {
          if (!filterLocal.options.includes(attrValue)) {
            filterLocal.options.push(attrValue);
          }
        }
      });
    }
  });
  return filtersLocal.map((filterLocal) => {
    filterLocal.options.sort((a: any, b: any) => {
      if (filterLocal.pimAttribute === "GFR-Fire protection") {
        return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
      } else {
        return a - b;
      }
    });
    return filterLocal;
  });
};

export const resetFilters =
  (): ThunkAction<void, SelectionStore, unknown, resetFiltersAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterType, placoData } = getState();

    const activeFilters = ((
      filterType: FilterType,
      filters: SolutionFilterType
    ) => {
      if (filterType === "partitionWall") {
        return [...filters.partitionWalls];
      }
      if (filterType === "liningWall") {
        return [...filters.liningWalls];
      }
      if (filterType === "protectionfeu") {
        return [...filters.protectionFeu];
      }

      if (filterType === "conduitgaines") {
        return [...filters.conduitGaines];
      }

      if (filterType === "gainestechniques") {
        return [...filters.gainesTech];
      }
      return [...filters.ceilings];
    })(filterType, filters);

    const activeData = ((filterType: FilterType, placoData: PlacoProducts) => {
      if (filterType === "partitionWall") {
        return placoData.partitionWalls;
      }
      if (filterType === "liningWall") {
        return placoData.liningWalls;
      }
      if (filterType === "protectionfeu") {
        return placoData.protectionFeu;
      }
      if (filterType === "conduitgaines") {
        return placoData.conduitGaines;
      }
      if (filterType === "gainestechniques") {
        return placoData.gainesTech;
      }
      return placoData.ceilings;
    })(filterType, placoData);

    const updatedFilters = activeFilters.map((filter) => {
      if (filter.choiceType === "Slider") {
        return { ...filter, value: [null, null] };
      }
      return { ...filter, value: null };
    });

    let filtersCopy = { ...filters };
    if (filterType === "partitionWall") {
      filtersCopy.partitionWalls = updatedFilters;
    } else if (filterType === "liningWall") {
      filtersCopy.liningWalls = updatedFilters;
    } else if (filterType === "protectionfeu") {
      filtersCopy.protectionFeu = updatedFilters;
    } else if (filterType === "conduitgaines") {
      filtersCopy.conduitGaines = updatedFilters;
    } else if (filterType === "gainestechniques") {
      filtersCopy.gainesTech = updatedFilters;
    } else {
      filtersCopy.ceilings = updatedFilters;
    }
    let filteredElements = filterElements3(updatedFilters, activeData);

    dispatch({
      type: "RESET_FILTERS",
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      filters: filtersCopy,
    });
  };

export const setFilterType = (filterType: FilterType): setFilterTypeAction => {
  return {
    type: "SET_FILTER_TYPE",
    filterType,
  };
};

export const search =
  (word: string): ThunkAction<void, SelectionStore, unknown, searchAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterType, placoData } = getState();

    const activeFilters = ((
      filterType: FilterType,
      filters: SolutionFilterType
    ) => {
      if (filterType === "partitionWall") {
        return [...filters.partitionWalls];
      }
      if (filterType === "liningWall") {
        return [...filters.liningWalls];
      }
      if (filterType === "ceiling") return [...filters.ceilings];

      if (filterType === "tous") return [];
      if (filterType === "protectionfeu") return [...filters.protectionFeu];
      if (filterType === "gainestechniques") return [...filters.gainesTech];
      if (filterType === "conduitgaines") return [...filters.conduitGaines];
    })(filterType, filters);

    const activeData = ((filterType: FilterType, placoData: PlacoProducts) => {
      if (filterType === "partitionWall") {
        return placoData.partitionWalls;
      }
      if (filterType === "liningWall") {
        return placoData.liningWalls;
      }
      if (filterType === "ceiling") return placoData.ceilings;
      if (filterType === "tous") return [];
      if (filterType === "protectionfeu") return placoData.protectionFeu;
      if (filterType === "gainestechniques") return placoData.gainesTech;
      if (filterType === "conduitgaines") return placoData.conduitGaines;
    })(filterType, placoData);

    let filteredElements = filterElements3(activeFilters, activeData);

    // filteredElements = filteredElements.filter((el: ProductDetailData) =>
    //     el.translation
    //         .replace("®", "")
    //         .toUpperCase()
    //         .includes(word.replace("®", "").toUpperCase())
    // );

    if (word !== "") {
      filteredElements = _.filter(filteredElements, function (o) {
        let includeE = true;
        word.split(";").forEach((element: string) => {
          let solutionProductNameAttr = o.attributes.find((sol: any) => sol.technicalName === 'A-Solution product name');
          let solutionName = solutionProductNameAttr ? solutionProductNameAttr.values[0].value : o.translation;
          let normalizeTranslation = solutionName
            .toUpperCase()
            .replace("®", "")
            .replace(/\s/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          let normalizeElement = element
            .toUpperCase()
            .replace("®", "")
            .replace(/\s/g, "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          if (
            !_.includes(
              //o.translation.toUpperCase().replace("®", "").replace(/\s/g, ""),
              //element.toUpperCase().replace("®", "").replace(/\s/g, "")
              normalizeTranslation,
              normalizeElement
            )
          ) {
            includeE = false;
          }
        });
        return includeE;
      });
    }

    dispatch({
      type: "SEARCH",
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      searchedWord: word,
    });
  };

export const scrolledBottom =
  (): ThunkAction<void, SelectionStore, unknown, scrolledBottomAction> =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    let currentDisplayedElements = state.displayedElements;
    let filteredElements = state.filteredElements;
    let nbDisplayed = currentDisplayedElements.length;
    let nbFiltered = filteredElements.length;
    let displayedElements = [];
    if (nbFiltered > nbDisplayed) {
      let elementsToAdd;
      if (nbFiltered - nbDisplayed >= 20) {
        elementsToAdd = filteredElements.slice(
          nbDisplayed - 1,
          nbDisplayed - 1 + 20
        );
      } else {
        elementsToAdd = filteredElements.slice(nbDisplayed);
      }
      displayedElements = currentDisplayedElements.concat(elementsToAdd);
    } else {
      displayedElements = filteredElements;
    }

    dispatch({
      type: "SCROLLED_BOTTOM",
      displayedElements,
    });
  };

export const selectBuffer = (system: ProductDetailData): selectBufferAction => {
  return {
    type: "SELECT_BUFFER",
    system,
  };
};

export const multiSelectBuffer = (
  systems: ProductDetailData[]
): multiSelectBufferAction => {
  return {
    type: "MULTI_SELECT_BUFFER",
    systems,
  };
};

export const tempMultiSelectBuffer = (
  systems: ProductDetailData[]
): tempMultiSelectBufferAction => {
  return {
    type: "TEMP_MULTI_SELECT_BUFFER",
    systems,
  };
};

export const resetTempBuffer = (): resetTempBufferAction => {
  return {
    type: "RESET_TEMP_MULTI_SELECT_BUFFER",
  };
};

export const resetBuffer = (): resetBufferAction => {
  return {
    type: "RESET_BUFFER",
  };
};

export const setActiveRowIndex = (Index: number): setActiveRowIndexAction => {
  return {
    type: "SET_ACTIVE_ROW_INDEX",
    Index,
  };
};

export const toggleSelectionRow =
  (
    Index: number,
    SelectorType: string
  ): ThunkAction<void, SelectionStore, unknown, toggleSelectionRowAction> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: TOGGLE_SELECTION_ROW,
      Index,
      SelectorType,
    });
  };

export const toggleAllSelectionRow =
  (
    SelectorType: string,
    Checked: boolean
  ): ThunkAction<void, SelectionStore, unknown, toggleAllSelectionRowAction> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: TOGGLE_ALL_SELECTION_ROW,
      SelectorType,
      Checked,
    });
  };

export const saveBufferSelector =
  (): ThunkAction<void, SelectionStore, unknown, selectSystemAction> =>
  async (dispatch: any, getState: any) => {
    const { selectorType, selectionBuffer, activeRowIndex } = getState();
    if (activeRowIndex !== null && selectionBuffer !== null) {
      dispatch({
        type: "SELECT_SYSTEM",
        SelectorType: selectorType,
        Index: activeRowIndex,
        System: selectionBuffer,
      });
    }
  };

export const showSystemDetails = (): showSystemDetailsAction => {
  return {
    type: "SHOW_SYSTEM_DETAILS",
  };
};

export const hideSystemDetails = (): hideSystemDetailsAction => {
  return {
    type: "HIDE_SYSTEM_DETAILS",
  };
};

export const showLayoutDetails = (): showLayoutDetailsAction => {
  return {
    type: "SHOW_LAYOUT_DETAILS",
  };
};

export const hideLayoutDetails = (): hideLayoutDetailsAction => {
  return {
    type: "HIDE_LAYOUT_DETAILS",
  };
};

export const toggleZone = (selectionId: string): toggleZoneAction => {
  return {
    type: "TOGGLE_ZONE",
    selectionId,
  };
};

export const toggleGlobalLoading = (loading: boolean): globalLoading => {
  return {
    type: SET_GLOBAL_LOADER,
    loading,
  };
};

export const toggleBufferUpdate = (update: boolean): updateBufferSelection => {
  return {
    type: UPDATE_BUFFER_SELECTION,
    update,
  };
};
const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English"]);
type Language = (typeof language)[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, SelectionStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "French" as Language; //(await api.queries.getRevitLanguage()) as Language;
      localStorage.setItem("savedLanguage", revitLanguage);
      //await bimStorage.setItem(storageKey.LANGUAGE, revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage"); //await bimStorage.getItem(storageKey.LANGUAGE);
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };

export const initOtherPlacoSystem =
  (): ThunkAction<
    void,
    SelectionStore,
    unknown,
    updatingOtherPlacoSystems<RowOptions>
  > =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: "UPDATE_OTHER_PLACO_SYSTEMS",
      othersMappingRows: [
        {
          Checked: true,
          Index: 0,
          RevitSelection: { RevitType: "", Ids: [] },
          Options: {
            Duplicated: 1,
            MappedSystem: null,
          },
          OtherPlacoSystem: true,
        },
      ],
    });
  };

export const selectOtherPlacoSystem = (
  Index: number,
  System: ProductDetailData | CustomProductDetailData,
  SelectorType: selectorTp
): selectPlacoOtherSystemAction => {
  return {
    type: SELECT_PLACO_OTHER_SYSTEM,
    SelectorType,
    Index,
    System,
  };
};

export const saveOtherPlacoBufferSelector =
  (): ThunkAction<
    void,
    SelectionStore,
    unknown,
    selectPlacoOtherSystemAction
  > =>
  async (dispatch: any, getState: any) => {
    const { selectorType, selectionBuffer, activeRowIndex } = getState();
    if (activeRowIndex !== null && selectionBuffer !== null) {
      dispatch({
        type: SELECT_PLACO_OTHER_SYSTEM,
        SelectorType: selectorType,
        Index: activeRowIndex,
        System: selectionBuffer,
      });
    }
  };

export const setMetresSelection =
  (metresData: string[]): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_METRES_SELECTION,
      metresData,
    });
  };

export const setCalepinageSelection =
  (selectionData: { Id: string; Time: number }[]): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_CALPINAGE_SELECTION,
      selectionData,
    });
  };

export const setReperageSelection =
  (selectionData: { Id: string; Time: number }[]): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_REPERAGE_SELECTION,
      selectionData,
    });
  };

export const resetCalepinageSelection =
  (): ThunkAction<void, SelectionStore, unknown, ResetCalpinageSelection> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: RESET_CALPINAGE_SELECTION,
    });
  };

export const setDossierSelection =
  (selectionData: Selection<PlacoOptions>[]): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_DOSSIER_SELECTION,
      selectionData,
    });
  };

/**
 * Set Element Parameters to Store
 * @param value Required array of "Element Parameters" extracted from Revit
 */
export const SetElementParametersToStore =
  (value: ElementParameter[]): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_ELEMENT_PARAMETERS,
      value,
    });
  };

/**
 * Replaces buffer selection object in Redux state
 * @param bufferSelection updated buffer selection object
 * @returns Dispatch of BufferSElection
 */
export const replaceBuffer =
  (
    bufferSelection: Selection<PlacoOptions>
  ): ThunkAction<void, SelectionStore, unknown, ReplaceBufferSelection> =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: REPLACE_BUFFER_SELECTION,
      bufferSelection,
    });
  };

/**
 * Export another action written in other files
 * from here so that impoter will import using single reference
 * for. ex import { Example } from "./Actions"
 *
 */

export { savePlacoData } from "./placoAction";

export const setprojectData =
  (data: initProjectData): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_PROJECTDATA,
      data,
    });
  };

export const setHistoryPath =
  (data: string): any =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_HISTORY_PATH,
      data,
    });
  };
