import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ColorizationRoot from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "103acfe1-f701-4a2b-95ef-a870fbe3aab6";

const colorizationTrigger = (config: any) =>
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="colorization"
    >
      <ColorizationRoot />
    </ParentRoot>
  );
};

export const ColorRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="colorization"
    >
      <ColorizationRoot />
    </ParentRoot>
  );
};

export const Colorization: Functionality = {
  Name: {
    French: "Colorer",
    English: "Color",
    German: "Colorer",
    Italian: "Colorer",
    Indonesian: "Colorer",
  },
  Trigger: colorizationTrigger,
  ShortDesc: {
    French: "Repérer les plaques",
    English: "Identify the plasterboards",
    German: "Repérer les plaques",
    Italian: "Repérer les plaques",
    Indonesian: "Repérer les plaques",
  },
  Id: functionalityId,
  RootComponent,
  Icon: "/PLACO_ICONS_New/Colorer.png",
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
