import { SystemDetailsActionTypes, SET_CURRENT_SYSTEM_DETAIL } from './types'
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";

export function setCurrentSystemAction(
  system: ProductDetailData,
): SystemDetailsActionTypes {
  return {
    type: SET_CURRENT_SYSTEM_DETAIL,
    system: system,
  };
}
