import _ from "lodash";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { api } from "../../../../../RevitJS/API";
import { selectWindowToShow } from "../../actions/appActions";
import {
  handleValidateSelection,
  resetCurrentProject,
  saveProject,
  setCurrentItemAndSanitizeAvailableElements,
  setElementsFilters,
  setProjectItems,
  setProjectLooseElements,
  setValidatedItems,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  Country,
  SelectionMethod,
  STEP_3_RANGE_SELECTION,
} from "../../assets/constants";
import { getElementName } from "../../helpers/utils";
import Element from "../../models/Element.model";
import Item, { initializedItem } from "../../models/Item.model";
import StateModel from "../../models/State.model";
import { getItemsAndCompatibleElements } from "../../services/projects.service";
import { saveNewProject } from "../../services/projects.service.local2";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./RangeSelection.scss";

class RangeSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.projectMenu = this.projectMenu.bind(this);
    this.handleValidateSelection = this.handleValidateSelection.bind(this);
    this.openCatalog = this.openCatalog.bind(this);
    this.state = {
      loading: false,
      isOneElementSelected: false,
    };
  }

  componentDidMount() {
    let { currentProject, allAvailableItems, setProjectLooseElements, setProjectItems } =
      this.props;
      currentProject.allAvailableElements = allAvailableItems;
    if (currentProject.items.length === 0) {
      this.setState({ loading: true });
      getItemsAndCompatibleElements(
        currentProject.country,
        currentProject.allAvailableElements
      )
        .then(({ items, looseElements }) => {
          setProjectLooseElements(looseElements);
          setProjectItems(items);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
    if (currentProject.validatedItems.length > 0) {
      this.setState({
        isOneElementSelected: true,
      });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.currentProject.looseElements !==
      this.props.currentProject.looseElements
    ) {
      saveNewProject(this.props.currentProject);
    }
  }

  componentWillUnmount() {
    let { setElementsFilters } = this.props;
    setElementsFilters(null, null);
  }

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    if (this.props.currentProject.selectionMethod === SelectionMethod.MANUAL) {
      selectWindowToShow(AppWindowName.ELEMENT_SELECTION_WINDOW);
    } else {
      selectWindowToShow(AppWindowName.SYSTEM_NAME_WINDOW);
    }
  }

  nextPage() {
    let { currentProject, setValidatedItems, saveProject, selectWindowToShow } =
      this.props;
    let validatedItems: Item[] = [];
    currentProject.items.forEach((item) => {
      let validatedItem: Item = { ...initializedItem };
      let validatedElements: Element[] = [];
      item.allCompatibleElements.forEach((element) => {
        if (element.selected) {
          validatedElements.push(element);
        }
      });
      if (validatedElements.length > 0) {
        validatedItem = {
          ...item,
          compatibleAndAvailableElements: _.cloneDeep(validatedElements),
          // compatibleAndAvailableElementsSanitized: [],
          allCompatibleElements: _.cloneDeep(validatedElements),
        };

        delete validatedItem.pipesLength;
        delete validatedItem.fittingsLength;
        delete validatedItem.accessoriesLength;
        validatedItem.current = true;
        validatedItems.push(validatedItem);
      } else {
        validatedItem.current = false;
      }
    });
    setValidatedItems(validatedItems);
    saveProject();
    selectWindowToShow(AppWindowName.COUPLING_SELECTION_WINDOW);
  }

  handleValidateSelection(item: Item) {
    this.props.handleValidateSelection(item);
    let found = false;
    this.props.currentProject.items.forEach((item) => {
      if (
        item.allCompatibleElements.find(
          (element: Element) => element.selected === true
        )
      ) {
        found = true;
      }
    });
    this.setState({
      isOneElementSelected: found,
    });
  }

  openCatalog() {
    let catalogLink = "";

    switch (this.props.currentProject.country) {
      case Country.FR:
        catalogLink =
          "https://bimlibrary.saint-gobain.com/en/France/home?brands=PAM";
        break;
      case Country.UK:
        catalogLink =
          "https://bimlibrary.saint-gobain.com/en/United%20Kingdom/home?brands=SAINT-GOBAIN%20PAM";
        break;
      default:
        catalogLink =
          "https://bimlibrary.saint-gobain.com/en/United%20Kingdom/home?brands=SAINT-GOBAIN%20PAM";
        break;
    }
    console.log("open cataloque: ", catalogLink);
    api.windowsHandler.openWindow(900, 520, `${catalogLink}`);
  }

  render() {
    let { currentProject, setCurrentItemAndSanitizeAvailableElements, t } =
      this.props;
    let { loading } = this.state;
    return (
      <div id="range-selection-container">
        <Header
          displayName={t(STEP_3_RANGE_SELECTION) || "Rage selection"}
        ></Header>
        <Body
          loading={loading}
          allAvailableElements={currentProject.allAvailableElements}
          looseElements={currentProject.looseElements}
          handleValidateSelection={this.handleValidateSelection}
          inEdition={currentProject.inEdition}
          items={currentProject.items}
          openCatalog={this.openCatalog}
          getElementName={getElementName}
          setCurrentItemAndSanitizeAvailableElements={
            setCurrentItemAndSanitizeAvailableElements
          }
        ></Body>

        <span></span>
        <Footer
          savedProjectsButtonShow={true}
          savedProjectsButtonDisabled={false}
          savedProjectButtonClicked={this.projectMenu}
          disabledNextButton={
            currentProject.inEdition || !this.state.isOneElementSelected
          }
          showNextButton={true}
          clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    currentProject: state.projects.currentProject,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  saveProject,
  setElementsFilters,
  setProjectItems,
  setProjectLooseElements,
  setCurrentItemAndSanitizeAvailableElements,
  handleValidateSelection,
  setValidatedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation & {
  allAvailableItems: Element[];
};

type IState = { loading: boolean; isOneElementSelected: boolean };

export default connector(withTranslation(["pam"])(RangeSelection));
