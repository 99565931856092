import React from "react";
import { Button, Modal, Header, List } from "semantic-ui-react";

//Modal for warning message If user revisit a saved configuration but there were changes in the model, where Revit systems were deleted from the chosen selection(s)

export const InformationModal = (props: any) => {
  return (
    <Modal open={props.showinfo} size="tiny">
      <Modal.Description style={{ textAlign: "center", margin: "15px 20px" }}>
        <Header style={{ color: "rgb(14, 110, 184)" }}>
          {props.information}
        </Header>
        <p>
          {props.systemsNotAvailable}
          <br />
          <List as="ul" style={{ fontStyle: "italic" }}>
            <List.Item as="li">MAC-03</List.Item>
            <List.Item as="li">CLO-1.03</List.Item>
            <List.Item as="li">CLO-1.05</List.Item>
          </List>
          <br />
          <p>{props.removeFromTrackingConfig}</p>
        </p>
      </Modal.Description>
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <Button size="tiny" primary onClick={() => props.closeDimmer()}>
          {props.ok}
        </Button>
      </div>
    </Modal>
  );
};
