import React, { Component } from "react";
import { Button, Input } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";

export default class Waiter extends Component {
//   state = {
//     distance: 400,
//   };

  componentDidMount = async () => {
    // api.windowHandler.resizeWindow(100, 100);
    // api.windowHandler.showDevTools();
    // window.revit.addCotesMM(1000);
  };
  render = () => {
    return (
      <div >
       <iframe frameBorder="no" src="https://www.placo.fr/loutil-placor-bim#1"
 width="100%" scrolling="yes" style={{height:"calc(100vh - 35px"}}/>       
      </div>
    );
  };
}
