import React, { createContext, useContext, useState } from "react";

type GlobalState = {
  [key: string]: any;
};

export interface ContextType {
  globalState: GlobalState;
  setGlobalStateByKey: (key: string, value: any) => void;
}

export const GlobalStateContext = createContext<ContextType | undefined>(
  undefined
);

// export const useGlobalState = () => {
//   const context = useContext(GlobalStateContext);
//   if (!context) {
//     throw new Error('useGlobalState must be used within a GlobalStateProvider');
//   }
//   return context;
// };

interface Props {
  children: React.ReactNode;
}

export const GlobalStateProvider: React.FC<Props> = ({ children }) => {
  const [globalState, setGlobalState] = useState<GlobalState>({});

  const setGlobalStateByKey = (key: string, value: any) => {
    setGlobalState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalStateByKey }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
