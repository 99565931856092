import { combineReducers } from "redux";
import app from "./app/reducers";
import cartReducer from "./cart/reducers";
import favoriteReducer from "./favorite/reducers";
import systemsReducer from "./systems/reducers";
import systemDetailsReducer from "./systemDetail/reducers";
import filtersReducer from "./filter/reducers";
import { CartActionTypes, CartState } from "./cart/types";
import { FavoriteActionTypes, FavoriteState } from "./favorite/types";
import { AppActionTypes, AppState } from "./app/types";
import { SystemsActionTypes, SystemsState } from "./systems/types"
import { SystemDetailsState } from "./systemDetail/types";
import { FiltersActionTypes, FiltersState } from "./filter/types";
import { config } from "../../../CLT/Delete/Reducers";


export const reducers = combineReducers({
    app: app,
    cart: cartReducer,
    favorite: favoriteReducer,
    systems: systemsReducer,
    systemDetails: systemDetailsReducer,
    filters: filtersReducer,
    config
});

export interface DrawStore {
    cart: CartState;
    app: AppState;
    favorite: FavoriteState;
    systems: SystemsState;
    systemDetails: SystemDetailsState;
    filters: FiltersState;
    config:any
}

export type ActionTypes = SystemsActionTypes | AppActionTypes | CartActionTypes | FavoriteActionTypes | SystemsActionTypes | FiltersActionTypes

export type RootState = ReturnType<typeof reducers>