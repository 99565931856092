export function descendingComparator(a, b, orderBy) {
  if (b && b[orderBy] && a && a[orderBy]) {
    if (
      b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()
    ) {
      return 1;
    }
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function stableFilter(array, filters) {
  let acceptable;
  return array.filter((element) => {
    acceptable = true;
    Object.keys(element).forEach((key) => {
      filters[key] =
        !(key in filters) || filters[key] === undefined || filters[key] === null
          ? ""
          : filters[key];
      element[key] =
        !(key in element) || element[key] === undefined || element[key] === null
          ? ""
          : element[key];
      try {
        acceptable =
          acceptable &&
          element[key]
            .toString()
            .toLowerCase()
            .includes(filters[key].toString().toLowerCase());
      } catch (e) {
        
      }
    });
    return acceptable;
  });
}

export function createFilterList(rows = []) {
  let filters = {};

  rows.forEach((row) => {
    const newKeys = Object.keys(row);
    newKeys.forEach((newKey) => {
      filters = { ...filters, [newKey]: "" };
    });
  });
  return filters;
}
