import React from "react";
import { IWording } from "../../../../Types";

const wording: IWording = {
  selectionName: {
    French: "Nom de la sélection",
    English: "Selection name",
  },
  editDate: {
    French: "Date de modification",
    English: "Edit date",
  },
};

const style = {
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    fontWeight: 700,
    display: "inline-flex",
    width: "55%",
    color: "black",
  },
  header_column_4: {
    textAlign: "center" as const,
    fontWeight: 700,
    width: "20%",
    color: "black",
  },
};

export const SelectionTableHeader = () => {
  const applicationLanguage = "English";
  return (
    <div style={style.tableSegment_Header}>
      <div style={style.header_secondary_row}>
        <div className="w-5">&nbsp;</div>
        <div style={style.header_column_1}>{"Selection name"}</div>
        <div style={style.header_column_4}>{"Levels"}</div>
        <div style={style.header_column_4}>{"Edit date"}</div>
      </div>
    </div>
  );
};
export default SelectionTableHeader;
