export interface IWording {
  [key: string]: { [key: string]: string };
}

export enum ESelectionMethod {
  MANUAL,
  GROUP,
}

export enum ELayoutMethod {
  NEW,
  OLD,
}
