import React, {Fragment} from 'react';
import LevelItem from './LevelItem';
import { LevelData } from '../../../../RevitJS/Types/StoreTypes';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

interface Props{
    levelsData: LevelData[],
    color: SemanticCOLORS
}

export const LevelItems = (props: Props): JSX.Element =>{
    return (
        <Fragment>
            {props.levelsData.map(item => <LevelItem key={item.Name}  name={item.Name} checked={item.Checked}/>)}
        </Fragment>
    )
};