import { FamilyEditor } from "./FamilyEditor";
import { Queries } from "./Queries";
import { WindowsHandler } from "./WindowsHandler";
import { ViewHandler } from "./ViewHandler";
import { Selection } from "./Selection";
import { Framework } from "./Framework";
import { License } from "./License";
import { EventsLog } from "./EventsLog";

interface API {
  familyEditor: FamilyEditor;
  queries: Queries;
  selection: Selection;
  windowsHandler: WindowsHandler;
  viewHandler: ViewHandler;
  framework: Framework;
  license: License;
  eventLog: EventsLog;
}

class API {
  constructor() {
    this.familyEditor = new FamilyEditor();
    this.queries = new Queries();
    this.selection = new Selection();
    this.windowsHandler = new WindowsHandler();
    this.viewHandler = new ViewHandler();
    this.framework = new Framework();
    this.license = new License();
    this.eventLog = new EventsLog();
  }
}

export const api = new API();
