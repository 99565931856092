import { ThunkAction } from "redux-thunk";
import { setPluginDataToDB } from "../../../../Helper";
import { SelectionStore } from "../../Selection/Reducers";

export const INIT_LANGUAGE = "INIT_LANGUAGE";
export interface InitLanguageAction{
    type: typeof INIT_LANGUAGE;
    language: Language
}

const stringLitArray = <L extends string>(arr: L[]) => arr;
//const language = stringLitArray(["French", "English", "German", "Italian"]);
const language = stringLitArray(["French", "English"]);
type Language = (typeof language)[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage = (
): ThunkAction<void, SelectionStore, unknown, InitLanguageAction> => async (
  dispatch,
  ) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "French" as Language;//await api.queries.getRevitLanguage() as Language;
      setPluginDataToDB("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage
        //language: "French"
      })
    }
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage
          //language: "French"
        })
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  }