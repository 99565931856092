import {
  elemsByLevelName,
  rowsByLevel,
  rowsByLevelAndName,
  rowsByLevelNameAndLength,
  rowsByLevelNameLengthAndHeight,
} from "./../Helpers/index";
import { api } from "../../../../RevitJS/API";
import _ from "lodash";

export const extractRailsDatafromRevit = async () => {
  const railsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-haut" },
        Rule: "Equals",
      },
    ],
    null
  );
  const basRailsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-bas" },
        Rule: "Equals",
      },
    ],
    null
  );
  const corniereIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Cornière Stil® CR2" },
        Rule: "Equals",
      },
    ],
    null
  );

  const ids = railsIds.concat(basRailsIds).concat(corniereIds);
  const railDetails = await api.queries.getObjectsParams(ids, [
    "id",
    "Longueur",
    "Nom",
  ]);

  const railsPerWall = railDetails.reduce(function (a: any, c: any) {
    a[c.Params[0].Value] = a[c.Params[0].Value] || [];
    a[c.Params[0].Value].push(c);
    return a;
  }, Object.create(null));

  let wallsIds = Object.keys(railsPerWall);

  const wallsByLevelName = await elemsByLevelName(wallsIds);

  let plasterBoardRows: any = [];

  _.forEach(railsPerWall, (wallData: any, wallId: any) => {
    _.forEach(wallData, (plasterBoardValue: any, index: any) => {
      let levelName = "";
      for (const [key, value] of Object.entries(wallsByLevelName)) {
        if ((value as any).includes(wallId)) {
          levelName = key;
        }
      }

      const railName = _.find(plasterBoardValue.Params, { Name: "Nom" }).Value;
      const railLength = _.find(plasterBoardValue.Params, {
        Name: "Longueur",
      }).Value;

      plasterBoardRows[`${wallId}`] = plasterBoardRows[`${wallId}`] || {};
      plasterBoardRows[`${wallId}`][railName] =
        plasterBoardRows[`${wallId}`][railName] || {};

      plasterBoardRows[`${wallId}`][railName][railLength] =
        plasterBoardRows[`${wallId}`][railName][railLength] || [];
      if (plasterBoardRows[`${wallId}`][railName][railLength].length === 0) {
        plasterBoardRows[`${wallId}`][railName][railLength].push({
          id: "",
          name: railName,
          height: "",
          length: railLength,
          width: "",
          surface: "",
          surfacePerQuantity: "",
          levelName: levelName,
          wallid: wallId,
          thickness: "",
          quantity: 1,
        });
      } else {
        plasterBoardRows[`${wallId}`][railName][railLength][0]["quantity"] =
          plasterBoardRows[`${wallId}`][railName][railLength][0]["quantity"] +
          1;
        plasterBoardRows[`${wallId}`][railName][railLength][0]["surface"] =
          plasterBoardRows[`${wallId}`][railName][railLength][0]["surface"];
      }
    });
  });

  let generatedPlasterBoardRows: any = [];

  _.forEach(plasterBoardRows, function (wallRows: any, wallId: any) {
    let platerBoards: any = [];
    if (wallRows) {
      _.forEach(
        wallRows,
        function (plasterBoardNameRows: any, plasterboardName: any) {
          _.forEach(
            plasterBoardNameRows,
            function (
              plasterBoardHeightLengthRows: any,
              pasterboardHeigthLenthName: any
            ) {
              _.forEach(
                plasterBoardHeightLengthRows,
                function (plasterRows: any, index: any) {
                  platerBoards.push(plasterRows);
                }
              );
            }
          );
          generatedPlasterBoardRows.push({
            data: platerBoards,
            levelName: platerBoards[0].levelName,
            productName: plasterboardName,
            thickness: platerBoards[0].thickness,
            wallId: wallId,
          });
        }
      );
    }
  });
  return generatedPlasterBoardRows;
};

export const extractPlasterboardDatafromRevit = async () => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );
  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );
  const plasterboardsPerWall = plasterboardDetails.reduce(function (
    a: any,
    c: any
  ) {
    a[c.Params[0].Value] = a[c.Params[0].Value] || [];
    a[c.Params[0].Value].push(c);
    return a;
  },
  Object.create(null));

  let wallsIds = Object.keys(plasterboardsPerWall);

  const wallsByLevelName = await elemsByLevelName(wallsIds);
  let rows = elemsDetailRowGenerator(
    wallsByLevelName,
    plasterboardsPerWall,
    plasterboardDetailGenerator
  );

  const plasterboardsByLevel = rowsByLevel(rows);

  const plasterboardsByLevelAndName = rowsByLevelAndName(plasterboardsByLevel);
  const plasterboardsByLevelNameAndLength = rowsByLevelNameAndLength(
    plasterboardsByLevelAndName
  );
  const plasterboardsByLevelNameLengthAndHeight =
    rowsByLevelNameLengthAndHeight(plasterboardsByLevelNameAndLength);

  return genratePlasterboardRows(plasterboardsByLevelNameLengthAndHeight);
};

export const extractPlasterboardDataForWallfromRevit = async () => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );
  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );
  const plasterboardsPerWall = plasterboardDetails.reduce(function (
    a: any,
    c: any
  ) {
    a[c.Params[0].Value] = a[c.Params[0].Value] || [];
    a[c.Params[0].Value].push(c);
    return a;
  },
  Object.create(null));

  let wallsIds = Object.keys(plasterboardsPerWall);

  const wallsByLevelName = await elemsByLevelName(wallsIds);

  let plasterBoardRows: any = [];

  _.forEach(plasterboardsPerWall, (wallData: any, wallId: any) => {
    _.forEach(wallData, (plasterBoardValue: any, index: any) => {
      let levelName = "";
      for (const [key, value] of Object.entries(wallsByLevelName)) {
        if ((value as any).includes(wallId)) {
          levelName = key;
        }
      }
      const plasterBoardId = _.find(plasterBoardValue.Params, {
        Name: "id",
      }).Value;
      const plasterBoardName = _.find(plasterBoardValue.Params, {
        Name: "name",
      }).Value;
      const plasterBoardHeight = _.find(plasterBoardValue.Params, {
        Name: "PlasterboardHeight",
      }).Value;
      const plasterBoardLength = _.find(plasterBoardValue.Params, {
        Name: "PlasterboardLength",
      }).Value;
      const plasterBoardWidth = _.find(plasterBoardValue.Params, {
        Name: "PlasterboardWidth",
      }).Value;

      plasterBoardRows[`${wallId}`] = plasterBoardRows[`${wallId}`] || {};
      plasterBoardRows[`${wallId}`][plasterBoardName] =
        plasterBoardRows[`${wallId}`][plasterBoardName] || {};
      const key = `${plasterBoardHeight}/${plasterBoardLength}`;
      plasterBoardRows[`${wallId}`][plasterBoardName][key] =
        plasterBoardRows[`${wallId}`][plasterBoardName][key] || [];
      if (plasterBoardRows[`${wallId}`][plasterBoardName][key].length === 0) {
        plasterBoardRows[`${wallId}`][plasterBoardName][key].push({
          id: plasterBoardId,
          name: plasterBoardName,
          height: plasterBoardHeight,
          length: plasterBoardLength,
          width: plasterBoardWidth,
          surface:
            Math.round(plasterBoardLength * plasterBoardHeight * 0.0001) / 100,
          surfacePerQuantity:
            Math.round(plasterBoardLength * plasterBoardHeight * 0.0001) / 100,
          levelName: levelName,
          wallid: wallId,
          thickness: plasterBoardWidth,
          quantity: 1,
        });
      } else {
        plasterBoardRows[`${wallId}`][plasterBoardName][key][0]["quantity"] =
          plasterBoardRows[`${wallId}`][plasterBoardName][key][0]["quantity"] +
          1;
        plasterBoardRows[`${wallId}`][plasterBoardName][key][0]["surface"] =
          plasterBoardRows[`${wallId}`][plasterBoardName][key][0]["surface"] +
          Math.round(plasterBoardLength * plasterBoardHeight * 0.0001) / 100;
      }
    });
  });

  let generatedPlasterBoardRows: any = [];

  _.forEach(plasterBoardRows, function (wallRows: any, wallId: any) {
    let platerBoards: any = [];
    if (wallRows) {
      _.forEach(
        wallRows,
        function (plasterBoardNameRows: any, plasterboardName: any) {
          _.forEach(
            plasterBoardNameRows,
            function (
              plasterBoardHeightLengthRows: any,
              pasterboardHeigthLenthName: any
            ) {
              _.forEach(
                plasterBoardHeightLengthRows,
                function (plasterRows: any, index: any) {
                  platerBoards.push(plasterRows);
                }
              );
            }
          );
          generatedPlasterBoardRows.push({
            data: platerBoards,
            levelName: platerBoards[0].levelName,
            productName: plasterboardName,
            thickness: platerBoards[0].thickness,
            wallId: wallId,
          });
          platerBoards = [];
        }
      );
    }
  });
  return generatedPlasterBoardRows;
};

export const elemsDetailRowGenerator = (
  wallsByLevelName: any,
  plasterboardsPerWalls: any,
  detailGenerator: any
) => {
  let rows: any = [];
  Object.keys(wallsByLevelName).forEach(function (levelName: any, index: any) {
    let wallIds = wallsByLevelName[levelName];
    wallIds.forEach((wallId: any) => {
      let plasterboards = plasterboardsPerWalls[wallId];
      let walls_rows = plasterboards.map(detailGenerator(levelName, wallId));
      rows = rows.concat(walls_rows);
    });
  });
  return rows;
};

const plasterboardDetailGenerator =
  (levelName: any, id: any) => (plasterboard: any) => {
    let length = Math.round(plasterboard.Params[2].Value);
    let height = Math.round(plasterboard.Params[1].Value);
    let width = plasterboard.Params[3].Value;
    return {
      levelName,
      name: plasterboard.Params[4].Value,
      length,
      height,
      width,
      surface: Math.round(length * height * 0.0001) / 100,
      id,
    };
  };

export const genratePlasterboardRows = (sorted_data: any) => {
  let dataList = [];

  let levels = Object.keys(sorted_data);
  let nb_levels = levels.length;
  let levelName, productName, thickness;
  let data = [];
  for (let i = 0; i < nb_levels; i++) {
    levelName = levels[i];
    let names = Object.keys(sorted_data[levels[i]]);
    let nb_names = names.length;
    for (let j = 0; j < nb_names; j++) {
      productName = names[j];

      let lengthes = Object.keys(sorted_data[levels[i]][names[j]]);
      let nb_lengthes = lengthes.length;
      let inter_obj = sorted_data[levels[i]][names[j]];
      let inter_obj_2 = inter_obj[Object.keys(inter_obj)[0]];
      let inter_obj_3 = inter_obj_2[Object.keys(inter_obj_2)[0]];

      thickness = inter_obj_3[0].width;
      for (let k = 0; k < nb_lengthes; k++) {
        let heights = Object.keys(
          sorted_data[levels[i]][names[j]][lengthes[k]]
        );
        let nb_heights = heights.length;
        for (let l = 0; l < nb_heights; l++) {
          let isoboards =
            sorted_data[levels[i]][names[j]][lengthes[k]][heights[l]];
          let length = isoboards[0].length;
          let height = isoboards[0].height;
          let surface = isoboards[0].surface;
          let quantity = isoboards.length;
          let surfacePerQuantity = surface * quantity;
          data.push({
            length: length,
            height: height,
            surface: surface,
            quantity: quantity,
            surfacePerQuantity: surfacePerQuantity,
          });
        }
      }
      dataList.push({
        levelName: levelName,
        productName: productName,
        thickness: thickness,
        data: data,
      });
    }
  }
  return dataList;
};

export const frameAndrailDetailGenerator =
  (levelName: any, id: any) => (entity: any) => {
    let length = Math.round(entity.Params[1].Value);
    return {
      levelName,
      name: entity.Params[2].Value,
      length,
      id,
    };
  };

export const extractFramesDatafromRevit = async () => {
  const idsSimple = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature" },
        Rule: "Equals",
      },
    ],
    null
  );
  const idsDouble = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Placo_Ossature-Double",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  let frameIds = [...idsSimple, ...idsDouble];
  const frameDetails = await api.queries.getObjectsParams(frameIds, [
    "id",
    "Longueur",
    "Nom",
  ]);

  const framesPerWall = frameDetails.reduce(function (a: any, c: any) {
    a[c.Params[0].Value] = a[c.Params[0].Value] || [];
    a[c.Params[0].Value].push(c);
    return a;
  }, Object.create(null));

  let wallsIds = Object.keys(framesPerWall);

  const wallsByLevelName = await elemsByLevelName(wallsIds);

  let plasterBoardRows: any = [];

  _.forEach(framesPerWall, (wallData: any, wallId: any) => {
    _.forEach(wallData, (plasterBoardValue: any, index: any) => {
      let levelName = "";
      for (const [key, value] of Object.entries(wallsByLevelName)) {
        if ((value as any).includes(wallId)) {
          levelName = key;
        }
      }

      const frameName = _.find(plasterBoardValue.Params, { Name: "Nom" }).Value;
      const frameLength = _.find(plasterBoardValue.Params, {
        Name: "Longueur",
      }).Value;

      plasterBoardRows[`${wallId}`] = plasterBoardRows[`${wallId}`] || {};
      plasterBoardRows[`${wallId}`][frameName] =
        plasterBoardRows[`${wallId}`][frameName] || {};

      plasterBoardRows[`${wallId}`][frameName][frameLength] =
        plasterBoardRows[`${wallId}`][frameName][frameLength] || [];
      if (plasterBoardRows[`${wallId}`][frameName][frameLength].length === 0) {
        plasterBoardRows[`${wallId}`][frameName][frameLength].push({
          id: "",
          name: frameName,
          height: "",
          length: frameLength,
          width: "",
          surface: "",
          surfacePerQuantity: "",
          levelName: levelName,
          wallid: wallId,
          thickness: "",
          quantity: 1,
        });
      } else {
        plasterBoardRows[`${wallId}`][frameName][frameLength][0]["quantity"] =
          plasterBoardRows[`${wallId}`][frameName][frameLength][0]["quantity"] +
          1;
        plasterBoardRows[`${wallId}`][frameName][frameLength][0]["surface"] =
          plasterBoardRows[`${wallId}`][frameName][frameLength][0]["surface"];
      }
    });
  });

  let generatedPlasterBoardRows: any = [];

  _.forEach(plasterBoardRows, function (wallRows: any, wallId: any) {
    let platerBoards: any = [];
    if (wallRows) {
      _.forEach(
        wallRows,
        function (plasterBoardNameRows: any, plasterboardName: any) {
          _.forEach(
            plasterBoardNameRows,
            function (
              plasterBoardHeightLengthRows: any,
              pasterboardHeigthLenthName: any
            ) {
              _.forEach(
                plasterBoardHeightLengthRows,
                function (plasterRows: any, index: any) {
                  platerBoards.push(plasterRows);
                }
              );
            }
          );
          generatedPlasterBoardRows.push({
            data: platerBoards,
            levelName: platerBoards[0].levelName,
            productName: plasterboardName,
            thickness: platerBoards[0].thickness,
            wallId: wallId,
          });
        }
      );
    }
  });

  return generatedPlasterBoardRows;
};
