import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import warning from "../../../assets/warning.png";
import {
    getImageAssetUrlAttributeFromRange,
    getNameAttributeFromRange,
    getShortDescAttributeFromRange,
} from "../../../helpers/utils";
import Element from "../../../models/Element.model";
import Item from "../../../models/Item.model";
import RangeComponent from "../../rangeSelection/body/rangeComponent/RangeComponent";
import "./body.scss";

function Body({ items, looseElements, t }: IProps) {
    const getElementName = (element: Element): string => {
        let tmpAngle =
            element.Angle !== null ? " - " + element.Angle + "deg" : "";
        let tmpDiameter =
            element.Diameter !== null ? " - DN" + element.Diameter : "";

        return element.FamilyName + tmpAngle + tmpDiameter;
    };

    const getFinalItems = () => {
        let tmpFinalItems: any[] = [];
        tmpFinalItems = items.map((item) => {
            let tmp: Element[] = [];
            item.allCompatibleElements.forEach((element) => {
                if (
                    !tmp.some(
                        (tmpElement: Element) =>
                            getElementName(element) ===
                            getElementName(tmpElement)
                    )
                ) {
                    tmp.push(element);
                }
            });

            return {
                ...item,
                compatibleAndAvailableElements: tmp,
            };
        });
        return tmpFinalItems;
    };

    const [finalItems] = useState(getFinalItems());

    const getElementCoupling = (element: Element) => {
        return `${element.selectedCoupling?.familyType} / ${element.selectedCoupling?.quality} / ${element.selectedCoupling?.gasket}`;
    };

    const getNbrAllElements = () => {
        let sum = 0;
        items.forEach((item: Item) => {
            sum += item.compatibleAndAvailableElements.length;
        });
        return sum;
    };

    const getNbrAllCouplings = () => {
        let sum = 0;
        items.forEach((item: Item) => {
            item.compatibleAndAvailableElements.forEach((element: Element) => {
                if (element.selectedCoupling) {
                    sum++;
                }
            });
        });
        return sum;
    };

    return (
        <div id="recap-container-body-container">
            <div className="px-4 py-2 h-100">
                <div className="row h-100">
                    <div className="col-12" id="recap-table-container">
                        <table id="recap-table">
                            <thead>
                                <tr id="recap-table-head">
                                    <th style={{ width: "30%" }}>
                                        {`${t("RANGE").toUpperCase()} (${
                                            items.length
                                        })`}
                                    </th>
                                    <th>
                                        {`${t(
                                            "ELEMENTS_SELECTED"
                                        ).toUpperCase()} (${getNbrAllElements()})`}
                                    </th>
                                    <th style={{ width: "30%" }}>
                                        {`${t(
                                            "COUPLINGS"
                                        ).toUpperCase()} (${getNbrAllCouplings()})`}
                                    </th>
                                </tr>
                            </thead>
                            {finalItems.map(
                                (item: Item, rangeIndex: number) => {
                                    return (
                                        <tbody
                                            key={`range-tbody-${rangeIndex}`}
                                        >
                                            {item.compatibleAndAvailableElements.map(
                                                (
                                                    element: Element,
                                                    elementIndex: number
                                                ) => {
                                                    return (
                                                        <tr
                                                            key={`elements-tr-${rangeIndex}-${elementIndex}`}
                                                        >
                                                            {elementIndex ===
                                                                0 && (
                                                                <td
                                                                    rowSpan={
                                                                        item
                                                                            .compatibleAndAvailableElements
                                                                            .length
                                                                    }
                                                                >
                                                                    <RangeComponent
                                                                        name={getNameAttributeFromRange(
                                                                            item.range
                                                                        )}
                                                                        desc={getShortDescAttributeFromRange(
                                                                            item.range
                                                                        )}
                                                                        image={getImageAssetUrlAttributeFromRange(
                                                                            item.range
                                                                        )}
                                                                    ></RangeComponent>
                                                                </td>
                                                            )}
                                                            <td>
                                                                {getElementName(
                                                                    element
                                                                )}
                                                            </td>
                                                            <td
                                                                className={`${
                                                                    !element.selectedCoupling
                                                                        ? "background-gray"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {element.selectedCoupling ? (
                                                                    getElementCoupling(
                                                                        element
                                                                    )
                                                                ) : item.range.attributes.find(
                                                                      (
                                                                          attr: any
                                                                      ) => {
                                                                          return (
                                                                              attr.technicalName ===
                                                                              "P-Need Coupling"
                                                                          );
                                                                      }
                                                                  )?.values[0]
                                                                      ?.value ===
                                                                  "True" ? (
                                                                    <div className="row">
                                                                        <div className="col-2 d-flex flex-column justify-content-center">
                                                                            <img
                                                                                src={
                                                                                    warning
                                                                                }
                                                                                alt="Logo placeholder"
                                                                                style={{
                                                                                    height:
                                                                                        "1.5em",
                                                                                    width:
                                                                                        "1.5em",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "#f67252",
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    "NO_SELECTED_COUPLING"
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    );
                                }
                            )}
                            <tbody key={`range-tbody-loose-elements`}>
                                {looseElements.map(
                                    (
                                        element: Element,
                                        elementIndex: number
                                    ) => {
                                        return (
                                            <tr
                                                key={`elements-tr-loose-elements-${elementIndex}`}
                                            >
                                                {elementIndex === 0 && (
                                                    <td
                                                        rowSpan={
                                                            looseElements.length
                                                        }
                                                    >
                                                        {t(
                                                            "ELEMENTS_WITH_NO_RANGE_AVAILABLE"
                                                        )}
                                                    </td>
                                                )}
                                                <td>
                                                    {getElementName(element)}
                                                </td>
                                                <td
                                                    className={
                                                        "background-gray"
                                                    }
                                                ></td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    items: Item[];
    looseElements: Element[];
};

export default withTranslation(["pam"])(Body);
