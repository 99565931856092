import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import {
  resetCurrentProject,
  setValidatedItems,
} from "../../actions/projectsActions";
import { AppWindowName, PROCEED, STEP_5_RECAP } from "../../assets/constants";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./recapComponent.scss";

// import "./elementSelection.scss";

class CouplingSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.projectMenu = this.projectMenu.bind(this);
  }

  componentDidMount() {}

  proceedHandler() {}

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    if (!this.props.validatedItems || this.props.validatedItems.length === 0) {
      this.props.selectWindowToShow(AppWindowName.RANGE_SELECTION_WINDOW);
    } else {
      this.props.selectWindowToShow(AppWindowName.COUPLING_SELECTION_WINDOW);
    }
  }

  async nextPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.DATA_TRANSFERT);
  }

  render() {
    let { validatedItems, looseElements, t } = this.props;
    return (
      <div id="recap-component-container">
        <Header displayName={t(STEP_5_RECAP) || ""}></Header>
        <Body items={validatedItems} looseElements={looseElements}></Body>
        <span></span>
        <Footer
          savedProjectsButtonShow={true}
          savedProjectsButtonDisabled={false}
          buttonLabel={PROCEED}
          savedProjectButtonClicked={this.projectMenu}
          disabledNextButton={false}
          showNextButton={true}
          clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    validatedItems: state.projects.currentProject.validatedItems,
    projectRegion: state.projects.currentProject.country,
    looseElements: state.projects.currentProject.looseElements,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  setValidatedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { loading: boolean };

export default connector(withTranslation(["pam"])(CouplingSelection));
