import _ from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { setElementsFilters } from "../../../../actions/projectsActions";
import { createFiltersFromElements } from "../../../../helpers/utils";
import Item from "../../../../models/Item.model";
import StateModel from "../../../../models/State.model";
import PixelAccordion from "../../../common/pixelAccordion/pixelAccordion";
import AngleFilters from "./AngleFilters/AngleFilters";
import "./filtersComponent.scss";

function FiltersComponent({ content, item, setElementsFilters, t }: IProps) {
    const [categorizedElementsLocal, setCategorizedElementsLocal] = useState(
        createFiltersFromElements(
            _.cloneDeep(item.compatibleAndAvailableElements).map((element) => {
                return { ...element, selected: false };
            })
        )
    );

    const [typesLength, setTypesLength] = useState({
        pipes: 0,
        fittings: 0,
        accessories: 0,
    });

    useEffect(() => {
        let tmpCategorized = _.cloneDeep(categorizedElementsLocal);
        Object.keys(item.filters).forEach((filter: string) => {
            if (
                filter === "pipes" ||
                filter === "fittings" ||
                filter === "accessories"
            )
                if (Object.keys(item.filters[filter]).length > 0) {
                    Object.keys(item.filters[filter]).forEach((angleKey) => {
                        item.filters[filter][angleKey].forEach((dnKey: any) => {
                            let tmp = tmpCategorized[filter][angleKey].find(
                                (dn: any) => {
                                    return dn.diameter === dnKey;
                                }
                            );
                            if (tmp) {
                                tmp.selected = true;
                            }
                        });
                    });
                }
        });
        setCategorizedElementsLocal(tmpCategorized);

        let pipesLength = 0;
        Object.keys(tmpCategorized.pipes).forEach((angleKey) => {
            let sumPipesLength = 0;
            tmpCategorized.pipes[angleKey].forEach((dn: any) => {
                sumPipesLength += dn.nbrElements;
            });
            pipesLength += sumPipesLength;
        });
        let fittingsLength = 0;
        Object.keys(tmpCategorized.fittings).forEach((angleKey) => {
            let sumFittingsLength = 0;
            tmpCategorized.fittings[angleKey].forEach((dn: any) => {
                sumFittingsLength += dn.nbrElements;
            });
            fittingsLength += sumFittingsLength;
        });
        let accessoriesLength = 0;
        Object.keys(tmpCategorized.accessories).forEach((angleKey) => {
            let sumAccessoriesLength = 0;
            tmpCategorized.accessories[angleKey].forEach((dn: any) => {
                sumAccessoriesLength += dn.nbrElements;
            });
            accessoriesLength += sumAccessoriesLength;
        });
        setTypesLength({
            pipes: pipesLength,
            fittings: fittingsLength,
            accessories: accessoriesLength,
        });
    }, []);

    const [countFilters, setCountFilters] = useState<any>({
        pipes: 0,
        fittings: 0,
        accessories: 0,
    });

    const resetFilters = () => {
        setElementsFilters(null, item);

        setCategorizedElementsLocal(
            createFiltersFromElements(
                _.cloneDeep(item.compatibleAndAvailableElements).map(
                    (element) => {
                        return { ...element, selected: false };
                    }
                )
            )
        );
        setCountFilters({
            pipes: 0,
            fittings: 0,
            accessories: 0,
        });
    };

    const sumFilterCount = () => {
        return (
            countFilters.pipes +
            countFilters.fittings +
            countFilters.accessories
        );
    };

    const sumTypesLength = () => {
        return (
            typesLength.pipes + typesLength.fittings + typesLength.accessories
        );
    };

    const handleFilter = (
        selected: boolean,
        type: string,
        angle: string,
        dn: string,
        nbrElements: number
    ) => {
        let tmp: any = _.cloneDeep(item.filters);
        let tmpCountFilters = _.cloneDeep(countFilters);
        if (selected) {
            if (
                Object.keys(tmp[type]).find((el) => el === angle) === undefined
            ) {
                tmp[type][angle] = [];
            }
            tmp[type][angle].push(dn);
            tmpCountFilters[type] += nbrElements;
        } else {
            let dnIndex = tmp[type][angle]?.indexOf(dn);
            if (dnIndex !== undefined && dnIndex > -1) {
                tmp[type][angle].splice(dnIndex, 1);
                if (tmp[type][angle].length === 0) {
                    delete tmp[type][angle];
                }
            }
            tmpCountFilters[type] -= nbrElements;
        }

        setElementsFilters(_.cloneDeep(tmp), item);

        setTimeout(() => {
            let tmpCategorized = _.cloneDeep(categorizedElementsLocal);
            let tmpCategorizedFilter = tmpCategorized[type][angle].find(
                (filter: any) => {
                    return filter.diameter === dn;
                }
            );

            if (tmpCategorizedFilter) {
                tmpCategorizedFilter.selected = selected;
                setCategorizedElementsLocal(tmpCategorized);
            }
            setCountFilters(_.cloneDeep(tmpCountFilters));
        });
    };

    return (
        <>
            <div
                id="range-selection-table-accordion-group"
                style={{
                    height: "2em",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 2px",
                }}
            >
                <span style={{ fontSize: "13px", fontWeight: 500 }}>
                    {`${t(
                        "FILTERS"
                    )} (${sumFilterCount()}/${sumTypesLength()})`}
                </span>
                <span
                    id="filters-reset-btn"
                    onClick={(e) => {
                        resetFilters();
                    }}
                >{`${t("RESET")} `}</span>
            </div>
            <div
                style={{
                    maxHeight: "19.8em",
                    overflowY: "auto",
                }}
            >
                {content.map(
                    (
                        element: {
                            filter: "pipes" | "accessories" | "fittings";
                            filterElementsNbr: number;
                        },
                        elementIndex
                    ) => {
                        return (
                            <PixelAccordion
                                key={"pixel-accordion-item-" + elementIndex}
                                headLabel={
                                    <strong style={{ cursor: "pointer" }}>
                                        {`${t(element.filter.toUpperCase())} (${
                                            countFilters[element.filter]
                                        }/${typesLength[element.filter]})`}
                                    </strong>
                                }
                            >
                                <AngleFilters
                                    handleFilter={handleFilter}
                                    categorizedElementsLocal={
                                        categorizedElementsLocal
                                    }
                                    filterObjectName={element.filter}
                                ></AngleFilters>
                            </PixelAccordion>
                        );
                    }
                )}
            </div>
        </>
    );
}

let mapStateToProps = (state: StateModel) => {
    return {
        userInfo: state.userInfo,
    };
};

let mapDispatchToProps = {
    setElementsFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux &
    WithTranslation & {
        content: any[];
        item: Item;
    };

type IState = {};

export default connector(withTranslation(["pam"])(FiltersComponent));
