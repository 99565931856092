import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export default function RenderSystemType({ systemTypes, selectedID, onChange }: SystemTypeProps) {
    return (
        <div className="SelectType"><Select MenuProps={{
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "center"
            },
            getContentAnchorEl: null
        }}
            classes={{ root: "SelectType" }}
            value={selectedID}
            onChange={event => onChange(event.target.value)}
            displayEmpty
            disableUnderline
            inputProps={{ 'aria-label': 'Without label' }}
            variant='standard'
        >
            {systemTypes.map(systemType => <MenuItem className="SelectType" key={systemType.value} value={systemType.value}>{systemType.label}</MenuItem>)}
        </Select></div>
    )
}

interface SystemTypeProps {
    selectedID: number
    onChange: any,
    systemTypes: any[]
}