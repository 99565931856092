//#region imports
import _, { filter } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dimmer, Form, Grid, Icon, Loader } from "semantic-ui-react";
import { v4 } from "uuid";
//#endregion imports
//#region internal imports
import { bimStorage, storageKey } from "../../../BIMStore";
import { getDate } from "../../../Plugins/PlacoBIM/Helper";
import { api } from "../../../RevitJS/API";
import { HeaderComponent } from "./common/headerComponent";
import { asyncForEach, loadSelections } from "../../../Plugins/PlacoBIMv3/Selection/Actions";
import { initProjectData, PlacoOptions } from "../../../RevitJS/Types/StoreTypes";
import { Selection } from "../../../Plugins/PlacoBIMv3/Selection/Actions/types";
import { dbStoreNameCalepinage } from "../../../Plugins/PlacoBIMv3/Calpinage/Components/root";
import { SuccesPopupComponent } from "./common/successPopupComponent";

//#endregion internal imports
interface Props {
  translations: any;
  language: string;
  pluginName?: string;
}

export const ImportComponent = (prop: Props) => {
    
    const[selectedValue, setValue] = useState('');
    const[recordList, setRecordList] = useState([]);
    const[selectionList, setSelectionList] = useState<any>();
    const[fileImportMessage, setFileImportMessage] = useState<string>('');
    const[loading, setLoading] = useState(false);
    const[active, setActive] = useState(false);
    const[productData, setProductData]= useState<any>([]);
    let checkSaveCalepinage: boolean = false;
    const[popupDetails, setPopupDetails] = useState<any>({header: prop.translations[prop.language].header.save , message: prop.translations[prop.language].message.importSuccess });
    const dispatch = useDispatch();
    useEffect(() => {
      async function loadSelection(){
        getSelection();
      }
      loadSelection();
    }, []);

    const getSelection=async ()=>{
      let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
      const productDataList = await bimStorage.getItem(storageKey.PLACO_PRODUCTS);
    if (placoSelections) {
      let selections = placoSelections as {
        [key: string]: Selection<PlacoOptions>;
      };
      setSelectionList(selections);
      setProductData(productDataList);
      dispatch(loadSelections(selections));
    }
  }

  const handleFileSelect = (event: any) => {
    event.preventDefault();
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
    setFileImportMessage(event.target.files[0].name);
  };

  const onReaderLoad = (event: any) => {
    var selectionList = JSON.parse(event.target.result);
    setValue(selectionList.moduleName);
    setRecordList(selectionList.records);
  }
  //#region SaveData
  //#region save selection
  const saveSelection = async (bufferSelection: any, selections: any) => {
    const date = new Date();
    let filterSelection: any;
    if (bufferSelection) {
      let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
      if (placoSelections) {
        selections = placoSelections as {
          [key: string]: Selection<PlacoOptions>;
        };

        filterSelection = filter(selections, function (s: any) {
          return s.Name.includes(bufferSelection.Name)
        });
      }

      bufferSelection.Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      bufferSelection.Time = Date.now();
      bufferSelection.Name = bufferSelection.Name + '_' + (filterSelection.length);
      // get only currentl project data
      const currentPlacoSelection = getCurrentZoneSelections(bufferSelection, selectionList);

    _.forEach(bufferSelection.SelectionByType.wall.Rows, async (row: any) =>{
       if(row.Options.MappedSystem === undefined || row.Options.MappedSystem === null){
        row.Options.MappedSystem = setDefaultSolution();
       } else {
        const solIndex = productData.objects.findIndex((s: any) => s.technicalName === row.Options.MappedSystem.technicalName);
        if(solIndex === -1){
          if(!(row.Options.MappedSystem.oid.includes("custom"))){
            row.Options.MappedSystem = setDefaultSolution();
          }
        }
       }
    });
    if(bufferSelection.Zone){
      // check if wall exist in other zone
      checkIfTypeExistInOtherZone(bufferSelection, currentPlacoSelection, "wall");

      // check if ceiling exist in other zone
      checkIfTypeExistInOtherZone(bufferSelection, currentPlacoSelection, "ceiling");
    }
      //Getting wall details for to extract final levels of all walls
      const wallIds = _.flattenDeep(
        _.map(bufferSelection.SelectionByType.wall.Rows, "RevitSelection.Ids")
      );
      const wallDetails = await api.queries.getWallsData(wallIds);

      const ceilingIds = _.flattenDeep(
        _.map(
          bufferSelection.SelectionByType.ceiling.Rows,
          "RevitSelection.Ids"
        )
      );
      const ceilingDetails = await api.queries.getCeilingsData(ceilingIds);

      const wallLevelNames = _.map(wallDetails, "LevelName");
      const ceilingLevelNames = _.map(ceilingDetails, "LevelName");
      checkSaveCalepinage = false;
      bufferSelection.Levels = _.uniq(
        _.concat(wallLevelNames, ceilingLevelNames)
      );
      if(wallIds.length > 0 || ceilingIds.length > 0){
        checkSaveCalepinage = true;
        dispatch({
          type: "SAVE_BUFFER",
          selection: bufferSelection,
        });
      } else {
        setPopupDetails({
          header: prop.translations[prop.language].header.save,
          message: prop.translations[prop.language].message.zone
        });
      }
    }
    }

    const saveGyprocSelection = async (bufferSelection: any, selections: any) => {
      const date = new Date();
      let filterSelection: any;
      if (bufferSelection) {
        const modules = await bimStorage.listSelection();
        if (modules) {
          selections = modules;
  
          filterSelection = filter(selections, function (s: any) {
            return s.Name.includes(bufferSelection.Name)
          });
        }
  
        bufferSelection.Date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        // bufferSelection.Time = Date.now();
        bufferSelection.Name = bufferSelection.Name + '_' + (filterSelection.length);
        delete bufferSelection.Id;
        bufferSelection.SelectionDetails.forEach((e: any) => {
          delete e.Id;
          delete e.SelectionId;
        });
        delete bufferSelection.SelectionDetails.Id;
        delete bufferSelection.SelectionDetails.SelectionId;
        const id = await bimStorage.saveSelection(bufferSelection);
        
        return id;
      }
    }

    const setDefaultSolution =()=>{
      let solutionData = productData.objects[0];
      _.forEach(solutionData.attributes, (attr: any) =>{
        if(attr.values[0]){
          solutionData[attr.technicalName] = attr.values[0].value;
        }
      });
      
      return solutionData;
    }
//#endregion save selection
//#region Save TechnicalDocument
    const SaveDossier= async (dossierTechnique: any)=>{
      await bimStorage.setItem(
        storageKey.DOSSIER_TECHNIQUE,
        JSON.stringify(dossierTechnique)
      );
    }
//#endregion Save TechnicalDocument
//#region Save Calepinage
  const SaveCalepinage = async (data: any, projectId: string) => {
    await bimStorage.setModule(
      dbStoreNameCalepinage,
      projectId,
      data.Name,
      data.Date,
      data.Id,
      JSON.stringify(data)
    );
  }

  const SaveGyprocCalepinage = async (data: any) => {
    await bimStorage.saveCalepinage(data);
  }
  //#endregion Save Calepinage
  //#endregion SaveData

  const processGyprocCalegpinage = async () => {
    let recordLength = 0;
    const calepinageList = await bimStorage.listCalepinage();

    await asyncForEach(recordList, async(element: any, index:number) => {
      element.SelectionIds = [];
      await asyncForEach(element.selectionList, async(sel: any, selIndex:number) => {
        let record = sel;
        const addedSelectionId = await saveGyprocSelection(record, null);
        element.SelectionIds.push(addedSelectionId);
      });

      delete element.selectionList;
      delete element.Id;

      element.CalepinageDetails.forEach((c: any) => {
        delete c.Id;
        delete c.CalepinageId;
      });

      const filterCalepinage = filter(calepinageList, function(c:any){
        return c.Name.includes(element.Name);
      });

      element.Name = element.Name+'_'+ filterCalepinage.length;
      setTimeout(async () =>  {
        await SaveGyprocCalepinage(element);
      }, 3000);
      recordLength++;
      if (recordLength === recordList.length) {
        setTimeout(async () => {
          setLoading(false);
          setActive(true);
        }, 3000);
      }

    });
  }


  //#region check zone uniq
  const getCurrentZoneSelections = (bufferSelection: any, placoSelections: any) => {
    if (placoSelections) {
      return Object.values(placoSelections).filter((e: any) => {
        return (
          e.RevitView === bufferSelection.RevitView &&
          e.Id !== bufferSelection.Id &&
          e.Zone
        );
      });
    }
  };

  const checkIfTypeExistInOtherZone = (bufferSelection: any, currentPlacoSolutions: any, type: string) => {
    let currentPlacoType = currentPlacoSolutions?.map((a: any) => {
      return a.SelectionByType[type].Rows.map((e: any) => e.RevitSelection.Ids);
    });
    currentPlacoType = _.flattenDepth(currentPlacoType, 2);

    let currentSelectionIds = bufferSelection.SelectionByType[
      type
    ].Rows.map((e: any) => e.RevitSelection.Ids);
    let duplicate = false;
    if (currentSelectionIds.length === 0) {
      duplicate = true;
    }

    currentSelectionIds = _.flatten(currentSelectionIds);
    _.forEach(currentSelectionIds, (e: any) => {
      if (currentPlacoType.includes(e)) {
        duplicate = true;
        _.forEach(bufferSelection.SelectionByType[type].Rows, (row: any) =>{
          const index = row.RevitSelection.Ids.findIndex((i: any)=> i === e);
          if(index > -1){
            row.RevitSelection.Ids.splice(index, 1);
          }
        });
      }
    });

    // const typeExist = currentSelectionIds.some((e: any) => {
    //   return currentPlacoType.includes(e);
    // });

    return duplicate;
  };
  //#endregion check zone uniq

  const closePopup = () => {
    window.revit.closeWindow();
  }

  const importData = async () => {
    let recordLength = 0;
    if (recordList.length === 0) {
      setFileImportMessage(prop.translations[prop.language].message.noFile);
      return false;
    }
    setLoading(true);
    if (selectedValue === 'Selection') {
      await asyncForEach(recordList, async (element: any, index: number) => {
        let record = JSON.parse(JSON.stringify(element));
        if(prop.pluginName === 'Gyproc'){
          await saveGyprocSelection(record, null);
        } else {
          record.Id = v4();
          await saveSelection(record, null);
        }
        recordLength++;
        if (recordLength === recordList.length) {
          setLoading(false);
          setActive(true);
        }
      });
    } else if (selectedValue === 'Dossier') {

      let tempDossierData: any = await bimStorage.getItem(storageKey.DOSSIER_TECHNIQUE);
      await asyncForEach(recordList, async (element: any, index: number) => {
        let selections: any;
        let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
        if (placoSelections) {
          selections = placoSelections as {
            [key: string]: Selection<PlacoOptions>;
          };
        }
        let record = JSON.parse(JSON.stringify(element.selectionList[0]));
        record.Id = v4();
        await saveSelection(record, selections);

        delete element.selectionList;

        let filterDossier = filter(tempDossierData, function (d: any) {
          return d.name.includes(element.name) && d.documentType === element.documentType;
        });
        element.documentId = v4();
        element.selections.list[0] = record.Id;
        element.name = element.name + '_' + filterDossier.length;
        element.coverPage.fileData = 'default';
        _.forEach(element.data.list, (fileList: any) => {
          fileList.files = [];
        });

        tempDossierData.push(element);
        recordLength++;
        if (recordLength === recordList.length) {
          setTimeout(async () => {
            await SaveDossier(tempDossierData);
            setLoading(false);
            setActive(true);
          }, 3000);
        }
      });

    }  else {
        if (prop.pluginName === 'Gyproc') {
          processGyprocCalegpinage();
        }
        else {
          const projectData: initProjectData = await api.queries.getSetProjectData();
        const calepinageList = await bimStorage.listModule(storageKey.CALEPINAGE, projectData.ProjectId);
        await asyncForEach(recordList, async(element: any, index:number) => {
          await asyncForEach(element.selectionList, async(sel: any, selIndex:number) => {
            let selections: any;
            let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
            if (placoSelections) {
              selections = placoSelections as {
                [key: string]: Selection<PlacoOptions>;
              };
            }

            let record = sel;
            record.Id = v4();
            element.selections.list[selIndex].Id = record.Id;
            await saveSelection(record, selections);
          });

          delete element.selectionList;
          const filterCalepinage = filter(calepinageList, function(c:any){
            return c.Name.includes(element.Name);
          });

          element.Id = v4();
          element.Name = element.Name+'_'+ filterCalepinage.length;
          setTimeout(async () =>  {
            if(checkSaveCalepinage){
              await SaveCalepinage(element, projectData.ProjectId);
            }
          }, 3000);
          recordLength++;
          if (recordLength === recordList.length) {
            setTimeout(async () => {
              setLoading(false);
              setActive(true);
            }, 3000);
          }
  
        });
        }
    }

    // window.revit.closeWindow();
  }

  if (loading) {
    return (
      <Dimmer active={loading} page>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div style={{ height: "100%" }} className="custom-file-uploader-button">
      <HeaderComponent
        Icon="/PlacoBIM.jpg"
        name={prop.translations[prop.language].header.import}
        subheader=""
      ></HeaderComponent>
      <Grid columns={3} padded={true}>
        <Grid.Row style={{ padding: "2rem 1.5rem 0" }}>
          <Form>
            <Form.Field >
              <label
                htmlFor={`file_${getDate().replace(
                  /\//g,
                  "_"
                )}_${new Date().getTime()}`}
                className="custom-file-upload">
                <Icon name="upload" size="small" color="blue" />
                {prop.translations[prop.language].button.import}
              </label>
              <input
                type="file"
                id={`file_${getDate().replace(
                  /\//g,
                  "_"
                )}_${new Date().getTime()}`}
                accept="application/JSON"
                onChange={(e: any) => handleFileSelect(e)}
              />
              <label>{fileImportMessage}</label>
            </Form.Field>
          </Form>
        </Grid.Row>
      </Grid>
      <div className="modalButton">
        <Button color="orange" onClick={closePopup}>
          {prop.translations[prop.language].button.cancel}
        </Button>
        <Button
          type="submit"
          primary
          onClick={importData}
        >
          {prop.translations[prop.language].button.import}
        </Button>
      </div>

      <SuccesPopupComponent
        active={active}
        setActive={setActive}
        closePopup={closePopup}
        header={popupDetails.header}
        message={popupDetails.message}></SuccesPopupComponent>
    </div>
  );
}


