import React, { Component } from "react";
import {
  Container,
  Button,
  Loader,
  Dimmer,
  Grid,
  Modal,
} from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { Routes } from "./root";
import ReactHtmlParser from "react-html-parser";
import {
  Selection,
  PlacoOptions,
  initProjectData,
} from "../../../../RevitJS/Types/StoreTypes";
import _ from "lodash";
import { scheduleHandler } from "../Export";
import { setParZoneSelection, clearMetresSelection } from "../Actions";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { SaveProject } from "../../CommonModules/PopUpModals/SaveProject";
import { setprojectData } from "../../Selection/Actions/index";
interface Props {
  Icon: string;
  name: string;
  wording: {
    metres: { [key: string]: string };
    paretage: { [key: string]: string };
    parzone: { [key: string]: string };
    schedulesPending: { [key: string]: string };
    quickMetre: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  setSelectionTree: any;
  initGroupSelector: any;
  setMetreSelection: any;
  clearMetreSelection: any;
  selectionData: any;
  setParZoneSelection: any;
  clearMetresSelection: any;
  setprojectData: any;
  config: any;
}

interface State {
  erasing: boolean;
  confirmModal: boolean;
  isDownloadSuccessful: boolean;
}

export class MetresLanding extends Component<Props, State> {
  state = {
    erasing: false,
    confirmModal: false,
    isDownloadSuccessful: false,
  };

  public static defaultProps = {
    wording: {
      metres: {
        French: "Métrés",
        English: "Nomencature",
      },
      paretage: {
        French: "Métrés par étage",
        English: "Metre per floor",
      },
      parzone: {
        French: "Métrés par étage <br/> et par zone",
        English: "Metre per floor and per zone",
      },
      schedulesPending: {
        French: "Métrés en cours",
        English: "Schedules pending",
        German: "Wandinventar in Bearbeitung",
        Italian: "Nomenclatura in corso",
      },
      quickMetre: {
        French: "Métrés rapide",
        English: "Quick Measurements",
        German: "Schnelle Messungen",
        Italian: "Misurazioni rapide",
      },
    },
  };
  componentDidMount = async () => {
    api.windowsHandler.resizeWindow(800, 220);
    let projectData: initProjectData = await api.queries.getSetProjectData();

    if (projectData.ProjectId === "") {
      this.toggleModal();
    } else {
      this.props.setprojectData(projectData);
      if (this.props.selectionData.length > 0) {
        this.setState({ erasing: true });

        let placoSelections = await bimStorage.getItem(
          storageKey.PLACOSELECTIONS
        );
        if (placoSelections) {
          let selections = placoSelections as {
            [key: string]: Selection<PlacoOptions>;
          };
          let selection: Selection<PlacoOptions>[] = [];

          _.forEach(selections, (item) => {
            _.forEach(this.props.selectionData, (elem) => {
              if (item.Id === elem) selection.push(item);
            });
          });
          this.downloadHandler(selection);
        }
      }
      await api.windowsHandler.resizeWindow(800, 220);
    }
  };

  toggleModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  onYesAction = () => {
    this.toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };

  downloadHandler = async (selection: any[]) => {
    const filteredTree = selection.map((item) => {
      return {
        level: item.Levels,
        Zone: item.Zone,
        Name: item.Name,
        height: 0,
        walls: item.SelectionByType.wall.Rows.map((ele: any) => {
          return {
            type: ele.RevitSelection.RevitType,
            ids: ele.RevitSelection.Ids,
            Solution:
              ele.Options.MappedSystem.longName ??
              ele.Options.MappedSystem.translation,
          };
        }),
        ceilings: item.SelectionByType.ceiling.Rows.map((ele: any) => {
          return {
            type: ele.RevitSelection.RevitType,
            ids: ele.RevitSelection.Ids,
            Solution:
              ele.Options.MappedSystem.longName ??
              ele.Options.MappedSystem.translation,
          };
        }),
      };
    });

    await scheduleHandler(
      filteredTree,
      this.props.clearMetresSelection,
      () => {
        this.setState({ erasing: false });
        if (!this.state.erasing) {
          setTimeout(function () {
            api.windowsHandler.closeWindow();
          }, 500);
        }
      },
      this.props.config
    );

    this.setState({ isDownloadSuccessful: true });
  };

  paretageSelectionHandler = async () => {
    this.props.setParZoneSelection(false);
    await api.windowsHandler.resizeWindow(900, 607);
    this.props.setRoute(Routes.SELECTION_METRES_HOME);
  };

  //start
  parzoneSelectionHandler = async () => {
    this.props.setParZoneSelection(true);
    await api.windowsHandler.resizeWindow(900, 607);
    this.props.setRoute(Routes.SELECTION_METRES_HOME);
  };

  render() {
    const { wording, language } = this.props;

    const schedulesPending =
      wording.schedulesPending[language] || wording.schedulesPending["English"];
    if (this.state.erasing) {
      return (
        <div>
          <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
            <Loader content={schedulesPending}></Loader>
          </Dimmer>
          <Modal
            size="tiny"
            open={this.state.isDownloadSuccessful}
            dimmer="blurring"
          >
            <Modal.Description
              style={{
                textAlign: "center",
                padding: "2rem 1.6rem 1.6rem 1.6rem",
              }}
            >
              <h3 style={{ color: "rgb(33, 133, 208)" }}>
                Information - Métrés
              </h3>
              <p>Génération de fichier de métrés terminée avec succès.</p>
              <Button
                primary
                onClick={() => {
                  this.setState({ isDownloadSuccessful: false });
                }}
              >
                Continuer
              </Button>
            </Modal.Description>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        <SaveProject
          toggleModal={this.toggleModal}
          confirmModal={this.state.confirmModal}
          onYesAction={this.onYesAction}
        />
        <FunctionalityHeader
          Icon={this.props.config.REACT_APP_SERVERURL + "/PlacoBIM.jpg"}
          name={this.props.wording.metres[this.props.language]}
        />
        <Container textAlign="center" style={{ marginTop: 20 }}>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Button
                  onClick={this.paretageSelectionHandler}
                  primary
                  style={{ height: 100 }}
                  size="big"
                  fluid
                  
                >
                  {ReactHtmlParser(
                    this.props.wording.paretage[this.props.language]
                  )}
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  onClick={this.parzoneSelectionHandler}
                  primary
                  style={{ height: 100 }}
                  size="big"
                  fluid
                >
                  {ReactHtmlParser(
                    this.props.wording.parzone[this.props.language]
                  )}
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  onClick={() => {
                    this.props.setRoute(Routes.QUICK_CALEPINAGE);
                  }}
                  primary
                  style={{ height: 100 }}
                  fluid
                  size="big"
                >
                  {ReactHtmlParser(
                    this.props.wording.quickMetre[this.props.language]
                  )}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Modal
          size="tiny"
          open={this.state.isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>Information - Métrés</h3>
            <p>Génération de fichier de métrés terminée avec succès.</p>
            <Button
              primary
              onClick={() => {
                this.setState({ isDownloadSuccessful: false });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }
}

const mapState = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    selectionData: state.moduleData.metresData,
    wallTree: state.wallTree,
    config: state.config,
  };
};

const mapDispatch = {
  setParZoneSelection,
  clearMetresSelection,
  setprojectData,
};

export default connect(mapState, mapDispatch)(MetresLanding);
