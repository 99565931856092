import { combineReducers } from "redux";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { LanguageType } from "../Types/languageType";
import { CriteriaType } from "../Types/criteriaType";
import { ProductType } from "../Types/productType";
import LanguageReducer from "./langReducer";
import CriteriaReducer from "./criteriaReducer";
import ProductReducer from "./productReducer";
import InfoReducer from "./infoReducer";
import { InfoType } from "../Types";


const reducer = combineReducers<ModelGeneratorStore>({
    language: LanguageReducer,
    CriteriaObj: CriteriaReducer,
    ProductsObj: ProductReducer,
    InfosObj: InfoReducer,
});


export interface ModelGeneratorStore {
    language: LanguageType,
    CriteriaObj: CriteriaType,
    ProductsObj: ProductType,
    InfosObj: InfoType,
}

export default reducer;
