import {
  BufferType,
  DeleteFacadeCalpinageType,
  DELETE_FACADE_CALEPINAGE,
  SetModuleDataActionType,
  SET_MODULE_DATA,
  updateSelectionName,
  UPDATE_SELECTION_NAME,
} from "../Types";

export const SetModuleData = (data: BufferType[]): SetModuleDataActionType => {
  return {
    type: SET_MODULE_DATA,
    payload: data,
  };
};

export const DeleteFacadeCalpinage = (
  Id: string
): DeleteFacadeCalpinageType => {
  return {
    type: DELETE_FACADE_CALEPINAGE,
    Id: Id,
  };
};

export const updateSelectionNameAction = (
  id: string,
  name: string
): updateSelectionName => {
  return {
    type: UPDATE_SELECTION_NAME,
    payload: [{ id: id, name: name }],
  };
};
