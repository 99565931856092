export enum PAM_ATTRIBUTES_KEYS {
  //GENERAL DATA
  DESCRIPTION = "DESCRIPTION",

  //SYSTEM INFORMATION
  CLASS = "CLASS",
  TYPE = "TYPE",
  TOTAL_NUMBER_LAYERS = "TOTAL_NUMBER_LAYERS",
  THICKNESS = "THICKNESS",
  WEIGHT = "WEIGHT",

  //TECHNICAL DATA
  STANDARD = "STANDARD",
  MANUFACTURERS_CERTIFICATE = "MANUFACTURERS_CERTIFICATE",

  //SAFETY AND REGULATION
  GREEN_BUILDING_CERTIFICATE = "GREEN_BUILDING_CERTIFICATE",

  //MANUFACTURES
  COUNTRY_ORIGIN = "COUNTRY_ORIGIN",
  MANUFACTURERS_OR_SUPPLIER = "MANUFACTURERS_OR_SUPPLIER",
  ADDRESS = "ADDRESS",
  CONTACT = "CONTACT",
  EMAIL = "EMAIL",

  //LINKS
  WEBSITE = "WEBSITE",
  VIDEO_APPLICATION = "VIDEO_APPLICATION",
  STORE_LOCATOR = "STORE_LOCATOR",

  //ADDITIONAL PARAMETERS
  ACTIVITY = "ACTIVITY",
  AREA_OF_APPLICATION = "AREA_OF_APPLICATION",
  SUB_CATEGORY = "SUB_CATEGORY",
  SYSTEM_NAME = "SYSTEM_NAME",
  TOTAL_THICKNESS = "TOTAL_THICKNESS",
  BIM_LAYERS = "BIM_LAYERS",

  LAYER_NUMBER = "LAYER_NUMBER",
  LAYER_CONTENT = "LAYER_CONTENT",
  LAYER_NAME = "LAYER_NAME",
  LAYER_DESCRIPTION = "LAYER_DESCRIPTION",
  LAYER_ELEMENT_TYPE = "LAYER_ELEMENT_TYPE",
  LAYER_THIKNESS = "LAYER_THIKNESS",
  LAYER_COLOR = "LAYER_COLOR",

  GENERATION_DATE = "GENERATION_DATE",
  SYSTEM_ID = "SYSTEM_ID",

  PRODUCT_NAME = "PRODUCT_NAME",
  PRODUCT_DESCRIPTION = "PRODUCT_DESCRIPTION",
  MANUFACTURER = "MANUFACTURER",
  COST = "COST",
  WEBSITE_LINK = "WEBSITE_LINK",
  APPEARANCE_COLOR = "APPEARANCE_COLOR",

  APPLICATION_THICKNESS = "APPLICATION_THICKNESS",
  CONSUMPTION = "CONSUMPTION",
  ADHESION_STRENGTH = "ADHESION_STRENGTH",
  COMPRESSIVE_STRENGTH = "COMPRESSIVE_STRENGTH",
  FLEXURAL_STRENGTH = "FLEXURAL_STRENGTH",
  ABRASIVE_RESISTANCE = "ABRASIVE_RESISTANCE",
  WATER_ADDITION = "WATER_ADDITION",
  PACKAGING = "PACKAGING",
  SHRINKAGE = "SHRINKAGE",
  FRESH_DENSITY = "FRESH_DENSITY",
  POT_LIFE = "POT_LIFE",
  BIM_THREED_TEXTURE = "BIM_THREED_TEXTURE",
  TWOD_TEXTURE_PATTERN = "TWOD_TEXTURE_PATTERN",

  //OLD
  //SYSTEM_NAME = "SYSTEM_NAME",
  SYSTEM_GENERIC_NAME = "SYSTEM_GENERIC_NAME",
  //THICKNESS = "THICKNESS",
  SYSTEM_DESCRIPTION = "SYSTEM_DESCRIPTION",
  SOLUTION_WIDTH = "SOLUTION_WIDTH",
  SYSTEM_URL = "SYSTEM_URL",
  MAIN_MANUFACTURER = "MAIN_MANUFACTURER",
  MANUFACTURER_INFO_WEBSITE = "MANUFACTURER_INFO_WEBSITE",
  //SYSTEM_ID = "SYSTEM_ID",
  NUMBER_OF_LAYERS = "NUMBER_OF_LAYERS",
  NAME_OF_THE_FACING = "NAME_OF_THE_FACING",
  TYPE_OF_PARTITION = "TYPE_OF_PARTITION",
  DUBBING_TYPE = "DUBBING_TYPE", // à vérifier dans la base
  SYSTEM_SHEET = "SYSTEM_SHEET", // à voir avec : "Plans, schémas, tutoriel de mise en œuvre ou guide d installation",
  CEILING_TYPE = "CEILING_TYPE",
  TYPICAL_DESCRIPTION = "TYPICAL_DESCRIPTION",
  SYSTEM_TYPE_ATT = "SYSTEM_TYPE_ATT",
  IMPACT_RESISTANCE_J = "IMPACT_RESISTANCE_J",
  SURFACE_HARDNESS = "SURFACE_HARDNESS",
  BURGLARY_RESISTANCE_TIME_MIN = "BURGLARY_RESISTANCE_TIME_MIN",
  LIMIT_HEIGHT_M = "LIMIT_HEIGHT_M",
  RESISTANCE_TO_OCCUPANCY_SHOCKS_J = "RESISTANCE_TO_OCCUPANCY_SHOCKS_J",
  TYPE_OF_SUPPORT_WALL = "TYPE_OF_SUPPORT_WALL",
  SPACE_ON_GROUND_MM = "SPACE_ON_GROUND_MM",
  ACOUSTIC_ATTENUATION_RA_ON_DB = "ACOUSTIC_ATTENUATION_RA_ON_DB",
  SOUND_REDUCTION_RW_C_CTR_IN_DB = "SOUND_REDUCTION_RW_C_CTR_IN_DB",
  ACOUSTIC_PV_N = "ACOUSTIC_PV_N",
  SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB = "SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB",
  SOUND_ABSORPTION_ΑW = "SOUND_ABSORPTION_ΑW", // à vérifier

  COEFFICIENT_K_OR_UP_W_M_K = "COEFFICIENT_K_OR_UP_W_M_K",
  TYPE_OF_INSULATION = "TYPE_OF_INSULATION",
  INSULATION_THICKNESS_MM = "INSULATION_THICKNESS_MM",
  NAME_OF_INSULATION = "NAME_OF_INSULATION",

  FIRE_RESISTANCE = "FIRE_RESISTANCE",
  FIRE_REPORT_NUMBER = "FIRE_REPORT_NUMBER",
  REACTION_TO_FIRE_OF_THE_SHEET_FACING = "REACTION_TO_FIRE_OF_THE_SHEET_FACING",
  FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT = "FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT",

  FDES_URL = "FDES_URL",
  CLASSIFICATION_OF_WET_ROOMS = "CLASSIFICATION_OF_WET_ROOMS",
  ACTIV_AIR = "ACTIV_AIR",
  VAPOR_BARRIER = "VAPOR_BARRIER",

  TECHNICAL_OPINION_OR_DTU_NUMBER = "TECHNICAL_OPINION_OR_DTU_NUMBER",

  // PLANS_DIAGRAMS_IMPLEMENTATION_TUTORIAL_OR_INSTALLATION_GUIDE = "PLANS_DIAGRAMS_IMPLEMENTATION_TUTORIAL_OR_INSTALLATION_GUIDE",
  FRAME_TYPE = "FRAME_TYPE",
  AMOUNTS_S_OR_D = "AMOUNTS_S_OR_D",
  CENTER_DISTANCE_BETWEEN_FRAMES_M = "CENTER_DISTANCE_BETWEEN_FRAMES_M",
  NUMBER_OF_PANELS_PER_FACING = "NUMBER_OF_PANELS_PER_FACING",
  WEIGHT_OF_THE_COMPLETE_SYSTEM_KG_M = "WEIGHT_OF_THE_COMPLETE_SYSTEM_KG_M",
  TYPE_OF_WORK = "TYPE_OF_WORK",
  RANGE_M = "RANGE_M",
  TYPE_OF_INSTALLATION = "TYPE_OF_INSTALLATION",
  TYPE_OF_SUPPORT_FLOOR = "TYPE_OF_SUPPORT_FLOOR",
  PRIMARY_FRAME_CENTER_DISTANCE_M = "PRIMARY_FRAME_CENTER_DISTANCE_M",
  DISTANCE_SECONDARY_FRAME_M = "DISTANCE_SECONDARY_FRAME_M",
  MINIMUM_HEIGHT_OF_THE_PLENUM = "MINIMUM_HEIGHT_OF_THE_PLENUM",
  MAXIMUM_HEIGHT_OF_THE_PLENUM = "MAXIMUM_HEIGHT_OF_THE_PLENUM",

  // PRICE_PROVIDED_IN_BATICHIFFRAGE = "PRICE_PROVIDED_IN_BATICHIFFRAGE",

  GFR_PRODUCT = "GFR_PRODUCT",

  // LAYER_CONTENT = "LAYER_CONTENT",
  // LAYER_NUMBER = "LAYER_NUMBER",
  // LAYER_NAME = "LAYER_NAME",
  // IFC_TYPE = "IFC_TYPE",
  // LAYER_THIKNESS_MM = "LAYER_THIKNESS_MM",
  // LAYER_COLOR = "LAYER_COLOR",
}

export enum PAM_CATEGORIES_KEYS {
  GENERAL_DATA = "GENERAL_DATA",
  INFORMATION = "INFORMATION",
  SYSTEM_INFORMATION = "SYSTEM_INFORMATION",
  TECHNICAL_DATA = "TECHNICAL_DATA",
  SAFETY_AND_REGULATION = "SAFETY_AND_REGULATION",
  MANUFACTURERS = "MANUFACTURERS",
  LINKS = "LINKS",

  //OLD
  MECHANICAL_PERFORMANCE = "MECHANICAL_PERFORMANCE",
  ACOUSTIC_PERFORMANCE = "ACOUSTIC_PERFORMANCE",
  THERMAL_PERFORMANCE = "THERMAL_PERFORMANCE",
  FIRE_PROTECTION = "FIRE_PROTECTION",
  ENVIRONMENT_AND_HEALTH = "ENVIRONMENT_AND_HEALTH",
  REGULATIONS_AND_CERTIFICATION = "REGULATIONS_AND_CERTIFICATION",
  IMPLEMENTATION = "IMPLEMENTATION",
  PRICE = "PRICE",
}

export enum PAM_CHAPTER_KEYS {
  //INFORMATION = "INFORMATION",
  SYSTEM = "SYSTEM",
  TECHNICAL = "TECHNICAL",
  PRODUCT_INFO = "PRODUCT_INFO",

  //OLD
  // PERFORMANCES = "PERFORMANCES",
  // IMPLEMENTATION_AND_PRICE = "IMPLEMENTATION_AND_PRICE",
  // SAFETY_AND_REGULATIONS = "SAFETY_AND_REGULATIONS",
}

export enum PAM_TYPE_KEYS {
  WALL = "WALL",
  CEILING = "CEILING",
  LINING_WALLS = "LINING_WALLS",
}

export interface IPAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY {
  WALL: IPAM_ATTRIBUTES_BY_CATEGORY[];
  FLOOR: IPAM_ATTRIBUTES_BY_CATEGORY[];
  // CEILING: IPAM_ATTRIBUTES_BY_CATEGORY[];
  // LINING_WALLS: IPAM_ATTRIBUTES_BY_CATEGORY[];
}

export interface IPAM_ATTRIBUTES_BY_CATEGORY {
  categoryName: PAM_CATEGORIES_KEYS;
  chapterName?: PAM_CHAPTER_KEYS;
  pamData: PAM_ATTRIBUTES_KEYS[];
}

export interface PamAttribute {
  category: PAM_CATEGORIES_KEYS;
  label: PAM_ATTRIBUTES_KEYS;
  technicalName: string;
  value?: string | null;
}

export type CustomParameter =
  | {
    solution: string;
    elementType: string;
    paramData: GroupParamData[];
  }
  | undefined;

export type GroupParamData = {
  category: PAM_CATEGORIES_KEYS | null;
  key: string;
  value: string | null;
};
