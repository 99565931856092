import React, { useEffect, useState } from "react";
import { MyConfig } from "../../Helper";

export const PluginServiceUnavailable = () => {
  return (
    <div className="content">
      <div className="inner">
        <div style={{textAlign: "center"}}>
          <div>
            <div style={{color: "#0d4d98", fontSize: "20px", fontWeight: "bold", marginTop: "10px"}}>
              Service temporarily not available
            </div>
            <div style={{color: "#0d4d98", fontSize: "18px", fontWeight: "bold", marginTop: "10px"}}>
              Please try again in few minutes
            </div>
            <div style={{color: "#0d4d98", fontWeight: "normal", marginTop: "20px", fontStyle: "italic", fontSize: "13px"}}>
              Le service est momentanément indisponible. Veuillez réessayer
              ultérieurement
            </div>
          </div>
          <div style={{marginTop: "20px"}}>
            <button
              style={{display: "inline-block", backgroundColor: "#0d4d98", color: "#fff", padding: "8px 20px", textDecoration: "none", borderRadius: "10px", minWidth: "80px", fontWeight:"bold"}}
              onClick={window.revit.closeWindow()}
            >
              Close <br />
              <span style={{fontStyle: "italic", fontSize: "12px"}}>Fermer</span>
            </button>
          </div>
        </div>
      </div>
      <div style={{position: "absolute", bottom: "20px", left: "50%", WebkitTransform: "translate(-50%, -50%)", transform: "translate(-50%, -50%);"}}>
        <img src="/saint-gobain-logo-new.png" style={{width: "80px" }}/>
      </div>
    </div>
  );
};


export const DockServiceUnavailable = () => {

    const [config, setConfig] = useState<any>();

    useEffect(() => {
        async function getConfig(){
            let cng = await MyConfig();
            setConfig(cng);
        }
        getConfig();
    }, [])
    
    
    return (
      <div className="content">
        <div className="inner">
          <div style={{textAlign: "center"}}>
            <div>
              <div style={{color: "#0d4d98", fontSize: "20px", fontWeight: "bold", marginTop: "10px"}}>
                Service temporarily not available
              </div>
              <div style={{color: "#0d4d98", fontSize: "18px", fontWeight: "bold", marginTop: "10px"}}>
                Please try again in few minutes
              </div>
              <div style={{color: "#0d4d98", fontWeight: "normal", marginTop: "20px", fontStyle: "italic", fontSize: "13px"}}>
                Le service est momentanément indisponible. Veuillez réessayer
                ultérieurement
              </div>
            </div>
            <div style={{marginTop: "20px"}}>
              <button
                style={{display: "inline-block", backgroundColor: "#0d4d98", color: "#fff", padding: "8px 20px", textDecoration: "none", borderRadius: "10px", minWidth: "80px", fontWeight:"bold"}}
                onClick={() => {window.location.href = config.REACT_APP_SERVERURL + "pluginServiceUnavailable"}}
              >
                Refresh <br />
                <span style={{fontStyle: "italic", fontSize: "12px"}}>Rafraîchir</span>
              </button>
            </div>
          </div>
        </div>
        <div style={{position: "absolute", bottom: "20px", left: "50%", WebkitTransform: "translate(-50%, -50%)", transform: "translate(-50%, -50%);"}}>
          <img src="/saint-gobain-logo-new.png" style={{width: "80px" }}/>
        </div>
      </div>
    );
  };
  
