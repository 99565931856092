import { map } from "lodash";
import { api } from ".";
import { wait } from "../Helpers";
import {
  InternalFamilySymbol,
  ID,
  int,
  XYZ,
  ElementParamSetter,
  FamilyData,
  Binding,
  ParamGroup,
  ParamQuery,
  JsonContour,
  LinePoints,
  WallLayer,
  MaterialParameters,
  Category,
} from "../Types/RevitTypes";
import { MyConfig } from "../../Helper";

export class FamilyEditor {
  placeFamilyAtPointAndLevel() {}

  async placeFamiliesAtPointsAndLevel(
    levelName: string,
    families: InternalFamilySymbol[]
  ) {
    if (!window.revit) {
      const setParam = async (families: InternalFamilySymbol[]) => {
        return families.map((family) =>
          Math.ceil(Math.random() * 1000000).toString()
        );
      };

      return setParam(families);
    }
    localStorage.setItem("placeFamiliesAtPointsAndLevel", "true");
    localStorage.setItem("placeFamiliesAtPointsAndLevelResult", "null");
    let processing = true;
    window.revit.placeFamiliesAtPointsAndLevel(
      levelName,
      JSON.stringify(families)
    );
    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("placeFamiliesAtPointsAndLevel") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("placeFamiliesAtPointsAndLevelResult") as string
    ).map((e: int) => e.toString());
  }

  async placeFamiliesAtPoints(families: InternalFamilySymbol[]) {
    /**
     * Temporary adjustment for API fault
     */
    families = map(families, (fam: InternalFamilySymbol) => {
      // if(fam.FamilyName === "Placo_Rail-bas")
      // {
      //   fam.FamilyName = "Rigips_Rail-bas";
      //   fam.Name = "Rigips_Rail-bas";
      // }

      // if(fam.FamilyName === "Placo_Rail-haut")
      // {
      //   fam.FamilyName = "Rigips_Rail-haut";
      //   fam.Name = "Rigips_Rail-haut";
      // }
      return fam;
    });
    //

    if (!window.revit) {
      const placeFamilies = async (families: InternalFamilySymbol[]) => {
        await wait(400);
        return families.map((family) => {
          return Math.ceil(Math.random() * 1000000).toString();
        });
      };

      return placeFamilies(families);
    }
    localStorage.setItem("placeFamiliesAtPoints", "true");
    localStorage.setItem("placeFamiliesAtPointsResult", "null");
    let processing = true;
    window.revit.placeFamiliesAtPoints(JSON.stringify(families));
    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("placeFamiliesAtPoints") as string
      );
    }

    return JSON.parse(
      localStorage.getItem("placeFamiliesAtPointsResult") as string
    ).map((e: int) => e.toString());
  }

  async placeFamiliesOnFace(
    faceId: ID,
    faceNormal: XYZ,
    faceDirection: XYZ,
    families: InternalFamilySymbol[]
  ) {
    if (!window.revit) {
      const setParam = async (families: InternalFamilySymbol[]) => {
        return families.map((family) =>
          Math.ceil(Math.random() * 1000000).toString()
        );
      };

      return setParam(families);
    } else {
      localStorage.setItem("placeFamiliesOnFace", "true");
      localStorage.setItem("placeFamiliesOnFaceResult", "null");
      let processing = true;
      window.revit.placeFamiliesOnFace(
        faceId,
        JSON.stringify(faceNormal),
        JSON.stringify(faceDirection),
        JSON.stringify(families)
      );

      while (processing) {
        await wait(100);
        console.log("Waiting...");

        processing = JSON.parse(
          localStorage.getItem("placeFamiliesOnFace") as string
        );
      }

      return JSON.parse(
        localStorage.getItem("placeFamiliesOnFaceResult") as string
      ).map((e: int) => e.toString());
    }
  }

  async setParams(paramSetters: ElementParamSetter[]): Promise<ID[]> {
    if (!window.revit) {
      const setParam = async (setters: ElementParamSetter[]) => {
        return setters.map((setter) =>
          Math.ceil(Math.random() * 1000000).toString()
        );
      };
      return setParam(paramSetters);
    }

    localStorage.setItem("setParams", "true");
    localStorage.setItem("setParamsResult", "null");
    localStorage.setItem("isErrorThrown", "False");
    let processing = true;
    window.revit.setParams(JSON.stringify(paramSetters));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("setParams") as string);
    }
    return JSON.parse(localStorage.getItem("setParamsResult") as string);
  }

  async addCotesMM(distance: number) {
    try {
      localStorage.setItem("addCotesMM", "true");
      localStorage.setItem("addCotesMMResult", "null");
      localStorage.setItem("isErrorThrown", "False");
      let processing = JSON.parse(localStorage.getItem("addCotesMM") as string);
      window.revit.addCotesMM(distance);
      // window.revit.testingDimension();
      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("addCotesMM") as string);
      }
      this.sendEvent();
      return true;
    } catch (e) {
      console.log("Issue in Cotation");
    }
  }

  async addCotes() {
    try {
      // localStorage.setItem("addCotesMM", "true");
      // localStorage.setItem("addCotesMMResult", "null");
      // let processing = JSON.parse(localStorage.getItem("addCotesMM") as string);
      window.revit.manualSelectionCotation();
      // while (processing) {
      //   await wait(50);
      //   processing = JSON.parse(localStorage.getItem("addCotesMM") as string);
      // }
      this.sendEvent();
      return true;
    } catch (e) {
      console.log("Issue in Cotation");
    }
  }

  sendEvent = async () => {
    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Cotation",
      module: "PLACOBIM",
    });
  };

  async joinMiter(): Promise<boolean> {
    if (!window.revit) {
      const createParam = async () => {
        await wait(200);
        return true;
      };

      return createParam();
    } else {
      localStorage.setItem("joinMiter", "true");
      localStorage.setItem("joinMiterResult", "null");
      let processing = true;
      window.revit.joinMiter();
      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("joinMiter") as string);
      }
      return JSON.parse(localStorage.getItem("joinMiterResult") as string);
    }
  }

  async loadFamilies(families: FamilyData[], config: any) {
    let token: string = localStorage.getItem("token") as string;

    return new Promise((resolve, reject) => 
      window.indec.loadFamilies(
        {
          families: JSON.stringify(families),
          url: config,
          token: token
        },
        resolve,
        reject
      )
      .then((response: any) => {
        return JSON.parse(response)
      })
      .catch((ex: any) => {
        console.error(ex);
        return false;
      })
    )
    // if (!window.revit) {
    //   const createParam = async () => {
    //     await wait(200);
    //     return true;
    //   };

    //   return createParam();
    // }
    // localStorage.setItem("loadFamilies", "true");
    // localStorage.setItem("loadFamiliesResult", "null");
    // localStorage.setItem("isErrorThrown", "False");
    // let processing = true;

    // let token: string = localStorage.getItem("token") as string;
    // window.revit.loadFamilies(JSON.stringify(families), config, token);
    // while (processing) {
    //   await wait(50);
    //   processing = JSON.parse(localStorage.getItem("loadFamilies") as string);
    // }
    // return JSON.parse(localStorage.getItem("loadFamiliesResult") as string);
  }

  async deleteElementsByIds(elementsIds: ID[]) {
    return new Promise((resolve, reject) => 
      window.indec.deleteElementsByIds(
        {
          elementsIds: JSON.stringify(elementsIds)
        },
        resolve,
        reject
      )
      .then((response: any) => {
        return JSON.parse(response)
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      })
    )

    // if (!window.revit) {
    //   const createParam = async () => {
    //     return true;
    //   };
    //   return createParam();
    // } else {
    //   localStorage.setItem("deleteElementsByIds", "true");
    //   localStorage.setItem("deleteElementsByIdsResult", "null");
    //   localStorage.setItem("isErrorThrown", "False");
    //   let processing = true;
    //   window.revit.deleteElementsByIds(JSON.stringify(elementsIds));

    //   while (processing) {
    //     await wait(50);
    //     processing = JSON.parse(
    //       localStorage.getItem("deleteElementsByIds") as string
    //     );
    //   }
    //   return JSON.parse(
    //     localStorage.getItem("deleteElementsByIdsResult") as string
    //   ).map((e: int) => e.toString());
    // }
  }

  async createParameter(
    category: Category,
    binding: Binding,
    paramGroup: ParamGroup,
    param: ParamQuery
  ): Promise<boolean> {
    return new Promise((resolve, reject) => 
      window.indec.createParameter(
        {
          category: category,
          binding: binding,
          paramGroup: paramGroup,
          param: JSON.stringify(param)
        },
        resolve,
        reject
      )
      .then((response: any) => {
        return JSON.parse(response)
      })
      .catch((ex: any) => {
        console.error(ex);
        return false;
      })
    )

    // if (!window.revit) {
    //   const createParam = async () => {
    //     return true;
    //   };

    //   return createParam();
    // }
    // localStorage.setItem("createParameter", "true");
    // localStorage.setItem("createParameterResult", "null");
    // let processing = true;
    // window.revit.createParameter(
    //   category,
    //   binding,
    //   paramGroup,
    //   JSON.stringify(param)
    // );

    // while (processing) {
    //   await wait(50);
    //   processing = JSON.parse(
    //     localStorage.getItem("createParameter") as string
    //   );
    // }
    // return JSON.parse(localStorage.getItem("createParameterResult") as string);
  }

  async createWallType(
    wallTypeName: string,
    layers: WallLayer[],
    reversed: boolean = false
  ): Promise<string> {
    if (!window.revit) {
      const createParam = async () => {
        return "true";
      };

      return createParam();
    }
    localStorage.setItem("createWallType", "true");
    localStorage.setItem("createWallTypeResult", "null");
    let processing = true;
    window.revit.createWallType(wallTypeName, JSON.stringify(layers), reversed);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("createWallType") as string);
    }

    return JSON.parse(localStorage.getItem("createWallTypeResult") as string);
    // return JSON.parse(localStorage.getItem('createParameterResult') as string);
  }

  async createSheet(
    sheetName: string,
    pathOrTemplate: string
  ): Promise<string> {
    if (!window.revit) {
      const createParam = async () => {
        return "true";
      };

      return createParam();
    }
    localStorage.setItem("createSheetType", "true");
    localStorage.setItem("createSheetResult", "null");
    let processing = true;
    window.revit.createSheet(sheetName, pathOrTemplate);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("createSheet") as string);
    }

    return JSON.parse(localStorage.getItem("createSheetResult") as string);
    // return JSON.parse(localStorage.getItem('createParameterResult') as string);
  }

  async createMaterial(
    materialParameters: MaterialParameters
  ): Promise<string> {
    if (!window.revit) {
      const createParam = async () => {
        return "true";
      };

      return createParam();
    }
    localStorage.setItem("createMaterialType", "true");
    localStorage.setItem("createMaterialResult", "null");
    let processing = true;
    window.revit.createMaterial(JSON.stringify(materialParameters));

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("createMaterial") as string);
    }

    return JSON.parse(localStorage.getItem("createMaterialResult") as string);
    // return JSON.parse(localStorage.getItem('createParameterResult') as string);
  }

  async createWallByContour(
    contour: JsonContour,
    locationLine: LinePoints,
    wallTypeName: string,
    levelName: string,
    allowJoin: boolean = true
  ): Promise<string> {
    // if (!window.navigator.appVersion.includes("Autodesk.Revit")) {
    //     const createParam = async () =>{
    //         return true;
    //     }

    //     return createParam();
    // }
    localStorage.setItem("createWallByContour", "true");
    localStorage.setItem("createWallByContourResult", "null");
    let processing = true;
    window.revit.createWallByContour(
      JSON.stringify(contour),
      JSON.stringify(locationLine),
      wallTypeName,
      levelName,
      allowJoin
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("createWallByContour") as string
      );
    }

    return JSON.parse(
      localStorage.getItem("createWallByContourResult") as string
    );
    // return JSON.parse(localStorage.getItem('createParameterResult') as string);
  }

  async injectCsvAndParams(
    fileName: string,
    familyName: string,
    params: { name: string; group: string; type: string }[]
  ): Promise<ID[]> {
    if (!window.revit) {
      const setParam = async (
        setters: { name: string; group: string; type: string }[]
      ) => {
        return setters.map((setter) =>
          Math.ceil(Math.random() * 1000000).toString()
        );
      };
      return setParam(params);
    }

    localStorage.setItem("InjectCsvAndParams", "true");
    localStorage.setItem("InjectCsvAndParamsResult", "null");
    let processing = true;
    window.revit.injectCsvAndParams(
      fileName,
      familyName,
      JSON.stringify(params)
    );
    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("InjectCsvAndParams") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("InjectCsvAndParamsResult") as string
    );
  }

  async createAndSetParameters(
    category: Category,
    binding: Binding,
    paramGroup: ParamGroup,
    param: ElementParamSetter[]
  ): Promise<boolean> {
    if (!window.revit) {
      const createParam = async () => {
        return true;
      };

      return createParam();
    }
    localStorage.setItem("createAndSetParameters", "true");
    localStorage.setItem("createAndSetParametersResult", "null");
    localStorage.setItem("isErrorThrown", "False");
    let processing = true;
    window.revit.createAndSetParameters(
      category,
      binding,
      paramGroup,
      JSON.stringify(param)
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("createAndSetParameters") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("createAndSetParametersResult") as string
    );
  }

  async drawWallSetter(ltData: string): Promise<boolean> {
    let processing = true;
    window.revit.drawWall(JSON.stringify(ltData));

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("createAndSetParameters") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("createAndSetParametersResult") as string
    );
  }

  async deleteParameters(
    category: Category,
    binding: Binding,
    paramGroup: ParamGroup,
    param: ElementParamSetter[]
  ): Promise<boolean> {
    if (!window.revit) {
      const deleteParam = async () => {
        return true;
      };

      return deleteParam();
    }
    localStorage.setItem("deleteParameters", "true");
    localStorage.setItem("deleteParametersResult", "null");
    let processing = true;
    window.revit.deleteParameters(
      category,
      binding,
      paramGroup,
      JSON.stringify(param)
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("deleteParameters") as string
      );
    }
    return JSON.parse(localStorage.getItem("deleteParametersResult") as string);
  }

  async drawElements(
    layoutData: any,
    setGroupNumber: any,
    setIncrement: any,
    url: any,
    setStep: any
  ) {
    if (!window.revit) {
      return;
    }
    localStorage.setItem("drawElements", "true");
    localStorage.setItem("drawElementsResult", "null");
    localStorage.setItem("isErrorThrown", "false");
    let processing = true;
    let token: string = localStorage.getItem("token") as string;

    const revitVersion = parseInt(await api.framework.getRevitVersion());

    window.revit.drawElements(JSON.stringify(layoutData), url, token);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("drawElements") as string);
      const totalProgress: any = localStorage.getItem("totalProgress");
      const totalNumber: any = localStorage.getItem("totalNumber");
      if (totalProgress === "0") {
        setStep("familyloading");
      } else {
        let progress = 0;
        let number = 0;
        if (totalProgress === "1") {
          progress = 0;
          number = 0;
        } else {
          progress = parseInt(totalProgress) - 1;
          number = parseInt(totalNumber) - 1;
        }

        setStep("drawing");
        setGroupNumber(number);
        setIncrement(progress);
      }
    }

    return JSON.parse(localStorage.getItem("drawElementsResult") as string);
  }

  async changeWallTypes(layoutData: any) {
    if (!window.revit) {
      return;
    }
    localStorage.setItem("changeWallType", "true");
    localStorage.setItem("changeWallTypeResult", "[]");
    let processing = true;

    window.revit.changeWallTypes(JSON.stringify(layoutData));

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("changeWallType") as string);
    }

    const changeWallResultValue = localStorage.getItem("changeWallTypeResult");
    if (changeWallResultValue) {
      if (typeof changeWallResultValue === "string") {
        return JSON.parse(changeWallResultValue.replace(/""/g, '\\""'));
      }
    }
    return JSON.parse(localStorage.getItem("changeWallTypeResult") as string);
  }

  async drawFraming(layoutData: any, url: any) {
    if (!window.revit) {
      return;
    }
    localStorage.setItem("drawFraming", "true");
    localStorage.setItem("drawFramingResult", "null");
    let processing = true;
    let token: string = localStorage.getItem("token") as string;

    window.revit.drawFraming(JSON.stringify(layoutData), url, token);

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("drawFraming") as string);
    }

    return JSON.parse(localStorage.getItem("drawFramingResult") as string);
  }

  async drawDryWall(layoutData: any) {
    if (!window.revit) {
      return;
    }
    localStorage.setItem("drawDryWall", "true");
    localStorage.setItem("drawDryWallResult", "null");
    let processing = true;

    const cng = await MyConfig();
    const showCuttingLines = cng ? cng.REACT_APP_CUTTING_LINES : "false";
    const smallPartExclude = cng ? cng.REACT_APP_SMALL_PART_EXCLUDE : "true";
    window.revit.drawDryWall(
      JSON.stringify(layoutData),
      showCuttingLines,
      smallPartExclude
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("drawDryWall") as string);
    }

    return JSON.parse(localStorage.getItem("drawDryWallResult") as string);
  }

  async createParts(wallIds: any) {
    if (!window.revit) {
      return;
    }
    localStorage.setItem("createParts", "true");
    localStorage.setItem("createPartsResult", "null");
    let processing = true;
    window.revit.createParts(JSON.stringify(wallIds));

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("createParts") as string);
    }

    return JSON.parse(localStorage.getItem("createPartsResult") as string);
  }
}
