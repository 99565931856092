import React, { useState } from "react";
import HomeTableHeader from "./HomeTableHeader";
import HomeTableRow from "./HomeTableRow";
import { map } from "lodash";
import { Modal, Button } from "semantic-ui-react";
import { getDateToCompare } from "../../../../Utils";

const style = {
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200)",
    height: "calc(100vh - 175px)",
    overflowY: "auto" as const,
    borderTop: "0 none",
  },
};

interface Props {
  data: any;
  onRowEdit: (id: any) => void;
  onRowDelete: (id: any) => void;
  editId: any;
  editName: any;
  setEditName: any;
  editCalpinageName: any;
  updateCalpinagename: any;
  errorMessage: any;
  setEditedNameValue: any;
}

export const HomeTable = (props: Props) => {
  const { data, onRowEdit, onRowDelete } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);

  const onRowDeleteIconClick = (selectionId: any) => {
    setDeleteId(selectionId);
    setOpenDeleteModal(true);
  };

  const onDeleteYes = () => {
    if (deleteId) {
      onRowDelete(deleteId);
      setOpenDeleteModal(false);
    }
  };

  return (
    <>
      <HomeTableHeader />
      <div style={style.tableSegment_body}>
        {data
        .slice()
        .sort((a:any, b:any) => getDateToCompare(b.Date) - getDateToCompare(a.Date))
        .map((dat: any,key: any) => (
          <HomeTableRow
            data={dat}
            onRowEdit={onRowEdit}
            onRowDelete={onRowDeleteIconClick}
            editId={props.editId}
            editName={props.editName}
            setEditName={props.setEditName}
            editCalpinageName={props.editCalpinageName}
            updateCalpinagename={props.updateCalpinagename}
            errorMessage={props.errorMessage}
            setEditedNameValue={props.setEditedNameValue}
            key={key}
          />
        ))}
      </div>
      <Modal size="tiny" open={openDeleteModal}>
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            {/* <p>Voulez-vous vraiment supprimer cette?</p> */}
            <p>Are you sure you want to delete this?</p>
            <Button color="orange" onClick={() => setOpenDeleteModal(false)}>
              No
            </Button>
            <Button color="blue" onClick={(index) => onDeleteYes()}>
              Yes
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default HomeTable;
