import React from 'react';
import {LevelItem} from './LevelItem';

export const LevelItems = props =>{
    return (props.levelsData.map(item => <LevelItem selectLevel={props.selectLevel} key={item.Name}  name={item.Name} checked={item.Checked} color={props.color}/>))
};

LevelItems.defaultProps={
    levelsData: [

    ]
}