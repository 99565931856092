import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../CommonModules/Assets/attributes.injection";
import { DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR, DISTRIBUTIVE_STANDARD, ISOLATION_DES_MURS, SEPARATIVE_STANDARD, SEPARATIVE_STANDARD_SAA, SEPARATIVE_STANDARD_SAD } from "../../CommonModules/Assets/constants";

export const getSystemType = (system: any) => {
    const SystemType = system.attributes.find(
        (attribute: any) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION
    )?.values[0]?.value;

    const SystemName = system.attributes.find(
        (attribute: any) =>
            attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.SYSTEM_NAME
    )?.values[0]?.value;
    if (SystemType === DISTRIBUTIVE_STANDARD) return DISTRIBUTIVE_STANDARD;
    if (SystemType === DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR) return DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR;
    else if (SystemType === ISOLATION_DES_MURS || SystemName.includes("Doublage"))
        return ISOLATION_DES_MURS;
    else if (SystemType === SEPARATIVE_STANDARD && SystemName.includes("SAA"))
        return SEPARATIVE_STANDARD_SAA;
    else if (SystemType === SEPARATIVE_STANDARD && SystemName.includes("SAD"))
        return SEPARATIVE_STANDARD_SAD;
};