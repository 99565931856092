import { jsPDF } from "jspdf";
import "./Roboto-bold.ts";
import "./Roboto-normal.ts";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { api } from "../../../../../RevitJS/API";

export const PDFPreview2019 = async (val: any = "", config: any) => {
  const localStoragedata: any = await bimStorage.getItem(
    storageKey.PROJECT_INFO
  );
  const data = localStoragedata;
  if (data === null || data === undefined) {
    return new Promise((resolve, reject) => {
      reject("Veuillez d'abord remplir les informations de projet requises.");
    });
  }
  const SupplimentaryData = data.SUPPLIMENTARY_FORM?.find(
    (el: any) => el.model_name === val.modelName
  );
  const ChantierData = data.PROJECT_INFO_FORM?.modelInfo?.find(
    (el: any) => el.model_name === val.modelName
  );
  const CompanyInitial = val.documentType;
  const DossierTitle =
    CompanyInitial === "DAE"
      ? "DOSSIER AVANT EXÉCUTION"
      : CompanyInitial === "DAO"
      ? "DOSSIER D'APPEL D'OFFRES​"
      : "DOSSIER DES OUVRAGES EXÉCUTÉS";

  const DateOfEdition = val.documentDate;
  const {
    information_chantier_add_date,
    information_chantier_address,
    information_chantier_pays,
    information_chantier_postal,
    information_chantier_reference,
    information_chantier_referencecctp,
    information_chantier_villa,
  } = ChantierData;

  const EnterpriseAddress =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_address ?? "";
  const EnterpriseEmail =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_email ?? "";
  const EnterpriseLogo =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_logo ?? "";
  const EnterpriseName =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_name ?? "";
  const EnterpriseCountry =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_pays ?? "";
  const EnterprisePostalCode =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_postal ?? "";
  const EnterprisePhone =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_telephone ?? "";
  const EnterpriseCity =
    data.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_villa ?? "";

  const lot_1 = SupplimentaryData?.lot_1 ?? "";
  const lot_2 = SupplimentaryData?.lot_2 ?? "";
  const lot_3 = SupplimentaryData?.lot_3 ?? "";
  const enterprise_generale_address =
    SupplimentaryData?.enterprise_generale_address ?? "";
  const enterprise_generale_email =
    SupplimentaryData?.enterprise_generale_email ?? "";
  const enterprise_generale_name =
    SupplimentaryData?.enterprise_generale_name ?? "";
  const enterprise_generale_telephone =
    SupplimentaryData?.enterprise_generale_telephone ?? "";
  const maitre_doeuvre_address =
    SupplimentaryData?.maitre_doeuvre_address ?? "";
  const maitre_doeuvre_email = SupplimentaryData?.maitre_doeuvre_email ?? "";
  const maitre_doeuvre_name = SupplimentaryData?.maitre_doeuvre_name ?? "";
  const maitre_doeuvre_telephone =
    SupplimentaryData?.maitre_doeuvre_telephone ?? "";
  const maitre_douvrage_address =
    SupplimentaryData?.maitre_douvrage_address ?? "";
  const maitre_douvrage_email = SupplimentaryData?.maitre_douvrage_email ?? "";
  const maitre_douvrage_name = SupplimentaryData?.maitre_douvrage_name ?? "";
  const maitre_douvrage_telephone =
    SupplimentaryData?.maitre_douvrage_telephone ?? "";
  const model_name = SupplimentaryData?.model_name ?? "";

  /*==== Text to Vetical
   Function To convert the company name into vertical string 
   have to wrap each word in the company into different element to provide spacing between them
    ====== */
  let TopOffset = EnterprisePhone === "" && EnterpriseEmail === "" ? 365 : 380;

  if (CompanyInitial === "DOE") {
    TopOffset = EnterprisePhone === "" && EnterpriseEmail === "" ? 420 : 435;
  }

  // HTML Template jsPDF uses below html and convert the markup into PDF
  // do not use external style. Only inline style will work with below

  const PDFTemplate = `<table class='main-table' cellpadding='0' cellspacing='0'> 
                            <tr> 
                                <td colspan='2' class='td1'> &nbsp; 
                                </td> 
                            </tr> 
                            <tr> 
                                <td colspan='2' class='td1'> &nbsp; 
                                </td> 
                            </tr> 
                            <tr> 
                                <td colspan='2' class='td1'> &nbsp; 
                                </td> 
                            </tr> 
                            <tr> 
                                <td class='td2'></td> 
                                <td class='td3'> 
                                    <div class='bottom-spacing'> 
                                        <strong class='strong-text'> 
                                        ${EnterpriseName?.toUpperCase()?.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )}</strong><br /> 
                                        ${EnterpriseAddress?.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )} <br /> 
                                        ${EnterprisePostalCode?.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )} ${EnterpriseCity?.replace(
    /\s+/g,
    "&nbsp;"
  )} <br /> 
                                        ${EnterpriseCountry?.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )}<br /><br /> 
                                        ${
                                          EnterprisePhone === ""
                                            ? ""
                                            : `Tel :  ${EnterprisePhone?.replace(
                                                /\s+/g,
                                                "&nbsp;"
                                              )} &nbsp;&nbsp;`
                                        } 
                                        ${
                                          EnterpriseEmail === ""
                                            ? ""
                                            : `Email: <a class='mail-to' href="mailto:${EnterpriseEmail}">${EnterpriseEmail}</a>`
                                        }
                                    </div> 
                                </td> 
                            </tr> 
                            <tr> 
                                <td rowspan = '1' class='td4'> 
                                        &nbsp; 
                                </td> 
                                <td class='td5'> 
                                    <div class='div-text'> 
                                    <strong class='str-text'> 
                                        ${information_chantier_reference
                                          ?.toUpperCase()
                                          ?.replace(/\s+/g, "&nbsp;")} 
                                    </strong><br /> 
                                    <strong> ${information_chantier_address?.replace(
                                      /\s+/g,
                                      "&nbsp;"
                                    )} </strong><br /> 
                                    <span> ${information_chantier_villa?.replace(
                                      /\s+/g,
                                      "&nbsp;"
                                    )} 
                                        ${information_chantier_pays?.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )} 
                                        ${information_chantier_postal?.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )} </span> 
                                    </div> 
                                </td> 
                            </tr> 
                            <tr> 
                                <td rowspan = '1' class='td4'> 
                                            &nbsp; 
                                </td> 
                                <td class='td6'> 
                                    <table class='table-inside'> 
                                        <tr> 
                                            ${
                                              maitre_doeuvre_address === "" &&
                                              maitre_doeuvre_email === "" &&
                                              maitre_doeuvre_name === "" &&
                                              maitre_doeuvre_telephone === ""
                                                ? ""
                                                : `<td class='td10'> 
                                                    <div class='div-add-info'> 
                                                        <span class='header-add-info'>MAITRE D’ŒUVRE</span> 
                                                        <div class='line-spacing'>${maitre_doeuvre_name?.replace(
                                                          /\s+/g,
                                                          "&nbsp;"
                                                        )}</div> 
                                                        <div class='line-spacing'>${maitre_doeuvre_address?.replace(
                                                          /\s+/g,
                                                          "&nbsp;"
                                                        )}</div> 
                                                        ${
                                                          maitre_doeuvre_email ===
                                                          ""
                                                            ? ""
                                                            : `<div class='line-spacing'>
                                                                    Email: ${maitre_doeuvre_email?.replace(
                                                                      /\s+/g,
                                                                      "&nbsp;"
                                                                    )}
                                                                </div>`
                                                        } 
                                                        ${
                                                          maitre_doeuvre_telephone ===
                                                          ""
                                                            ? ""
                                                            : `<div class='line-spacing'>
                                                                    Tél: ${maitre_doeuvre_telephone?.replace(
                                                                      /\s+/g,
                                                                      "&nbsp;"
                                                                    )}
                                                                </div>`
                                                        }
                                                    </div> 
                                                </td>`
                                            }

                                        ${
                                          maitre_douvrage_address === "" &&
                                          maitre_douvrage_email === "" &&
                                          maitre_douvrage_name === "" &&
                                          maitre_douvrage_telephone === ""
                                            ? ""
                                            : `<td class='td10'> 
                                                <div class='div-add-info'> 
                                                    <span class='header-add-info'>MAITRE D’OUVRAGE</span> 
                                                    <div class='line-spacing'>${maitre_douvrage_name?.replace(
                                                      /\s+/g,
                                                      "&nbsp;"
                                                    )}</div> 
                                                    <div class='line-spacing'>${maitre_douvrage_address?.replace(
                                                      /\s+/g,
                                                      "&nbsp;"
                                                    )}</div> 
                                                    ${
                                                      maitre_douvrage_email ===
                                                      ""
                                                        ? ""
                                                        : `<div class='line-spacing'>Email: 
                                                                ${maitre_douvrage_email?.replace(
                                                                  /\s+/g,
                                                                  "&nbsp;"
                                                                )}
                                                            </div>`
                                                    }
                                                    ${
                                                      maitre_douvrage_telephone ===
                                                      ""
                                                        ? ""
                                                        : `<div class='line-spacing'>Tél: 
                                                                ${maitre_douvrage_telephone?.replace(
                                                                  /\s+/g,
                                                                  "&nbsp;"
                                                                )}
                                                            </div>`
                                                    }
                                                </div> 
                                            </td>`
                                        } 
                                        </tr> 
                                        <tr> 
                                        ${
                                          enterprise_generale_address === "" &&
                                          enterprise_generale_email === "" &&
                                          enterprise_generale_name === "" &&
                                          enterprise_generale_telephone === ""
                                            ? ""
                                            : `<td class='td11'> 
                                                <div class='div-add-info'> 
                                                    <span class='header-add-info'>ENTREPRISE GÉNÉRALE</span>​ 
                                                    <div class='line-spacing'> ${enterprise_generale_name?.replace(
                                                      /\s+/g,
                                                      "&nbsp;"
                                                    )}</div> 
                                                    <div class='line-spacing'>${enterprise_generale_address?.replace(
                                                      /\s+/g,
                                                      "&nbsp;"
                                                    )}</div> 
                                                    ${
                                                      enterprise_generale_email ===
                                                      ""
                                                        ? ""
                                                        : `<div class='line-spacing'> 
                                                            Email: ${enterprise_generale_email?.replace(
                                                              /\s+/g,
                                                              "&nbsp;"
                                                            )}
                                                            </div>`
                                                    }
                                                    ${
                                                      enterprise_generale_telephone ===
                                                      ""
                                                        ? ""
                                                        : `<div class='line-spacing'>
                                                                Tél: ${enterprise_generale_telephone?.replace(
                                                                  /\s+/g,
                                                                  "&nbsp;"
                                                                )}
                                                            </div>`
                                                    }
                                                </div> 
                                            </td>`
                                        }
                                            <td></td> 
                                        </tr> 
                                    </table> 
                                </td> 
                            </tr> 
                            ${
                              lot_1 === "" && lot_2 === "" && lot_3 === ""
                                ? ""
                                : `<tr> 
                                    <td rowspan = '1' class='td4'> 
                                                &nbsp; 
                                    </td> 
                                    <td class='td7'> 
                                    ${
                                      lot_1 === ""
                                        ? ""
                                        : `<div class='lot lot-1'> LOT &nbsp; 1: ${lot_1?.replace(
                                            /\s+/g,
                                            "&nbsp;"
                                          )} </div>`
                                    }
                                    ${
                                      lot_2 === ""
                                        ? ""
                                        : `<div class='lot'> LOT &nbsp; 2: ${lot_2?.replace(
                                            /\s+/g,
                                            "&nbsp;"
                                          )} </div>`
                                    }
                                    ${
                                      lot_3 === ""
                                        ? ""
                                        : `<div class='lot lot-3'> LOT &nbsp; 3: ${lot_3?.replace(
                                            /\s+/g,
                                            "&nbsp;"
                                          )} </div>`
                                    } 
                                    </td> 
                                </tr>`
                            }
                            <tr> 
                                <td rowspan = '1' class='td4'> 
                                            &nbsp; 
                                </td> 
                                <td class='td8'> 
                                    <div class='div1'> ${CompanyInitial} </div> 
                                    <div class='text-right'>
                                        ${information_chantier_referencecctp.replace(
                                          /\s+/g,
                                          "&nbsp;"
                                        )} 
                                    </div> 
                                    <div class='text-right'> Le ${(
                                      "0" +
                                      new Date(
                                        information_chantier_add_date
                                      ).getDate()
                                    ).slice(-2)}/
                                        ${(
                                          "0" +
                                          (new Date(
                                            information_chantier_add_date
                                          ).getMonth() +
                                            1)
                                        ).slice(-2)}/
                                        ${new Date(
                                          information_chantier_add_date
                                        ).getFullYear()}
                                    </div> 
                                    <div class='div2'> ${DossierTitle} </div> 
                                </td> 
                            </tr> 
                            <tr> 
                                <td rowspan = '1' class='td4'> 
                                    &nbsp;
                                </td>
                                <td bgcolor='#808080' class='td9' colspan = '2'> &nbsp;</td>
                            </tr> 
                            </table>`;

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  let base64: any;

  if (EnterpriseLogo) {
    let arrLogo = EnterpriseLogo.split(",");
    if (arrLogo.length > 0) {
      base64 = arrLogo[1];
    }
  }

  let b64Data: any = await api.queries.genrateDossierPDF(
    PDFTemplate,
    CompanyInitial,
    base64
  );

  return new Promise((resolve, reject) => {
    let pdfContent =
      "data:application/pdf;filename=generated.pdf;base64," + b64Data;

    //base64 to blob
    const blob = b64toBlob(b64Data, "application/pdf");
    const blobUrl = URL.createObjectURL(blob);

    //base64 to blob different approch
    // const byteCharacters = atob(b64Data);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: 'application/pdf'});

    resolve([pdfContent, blob]);
  });
};
