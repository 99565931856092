import { crossProduct, normalize } from "../../../../Layout/helpers";
import { Vect3D } from "../../../../Layout/types";
import { api } from "../../../../RevitJS/API";
import { LinePoints } from "../../../../RevitJS/Types/RevitTypes";

export const createLeftLocationLine = (
  locationLine: LinePoints,
  width: number,
  thickness: number,
  leftDirection: Vect3D
) => ({
  p1: {
    ...locationLine.p1,
    X: locationLine.p1.X + ((width - thickness) / 2) * leftDirection.X,
    Y: locationLine.p1.Y + ((width - thickness) / 2) * leftDirection.Y,
  },
  p2: {
    ...locationLine.p2,
    X: locationLine.p2.X + ((width - thickness) / 2) * leftDirection.X,
    Y: locationLine.p2.Y + ((width - thickness) / 2) * leftDirection.Y,
  },
});

export const createRightLocationLine = (
  locationLine: LinePoints,
  width: number,
  thickness: number,
  leftDirection: Vect3D
) => ({
  p1: {
    ...locationLine.p1,
    X: locationLine.p1.X - ((width - thickness) / 2) * leftDirection.X,
    Y: locationLine.p1.Y - ((width - thickness) / 2) * leftDirection.Y,
  },
  p2: {
    ...locationLine.p2,
    X: locationLine.p2.X - ((width - thickness) / 2) * leftDirection.X,
    Y: locationLine.p2.Y - ((width - thickness) / 2) * leftDirection.Y,
  },
});

export const computeLeftDirection = (locationLine: LinePoints) =>
  crossProduct(
    {
      X: 0,
      Y: 0,
      Z: 1,
    },
    normalize({
      X: locationLine.p2.X - locationLine.p1.X,
      Y: locationLine.p2.Y - locationLine.p1.Y,
      Z: locationLine.p2.Z - locationLine.p1.Z,
    })
  );

export const setAlbaId = async (wallId: string, parentId: string) =>
  await api.familyEditor.setParams([
    {
      Id: wallId,
      Params: [
        {
          Name: "AlbaId",
          Type: "Text",
          Value: parentId,
        },
      ],
    },
  ]);
