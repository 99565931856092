import {
  find,
  filter,
  map,
  remove,
  isEmpty,
  round,
  startsWith,
  flattenDeep,
  keysIn,
  includes,
  pickBy,
  groupBy,
  uniq,
  orderBy,
  forEach,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import { fetchPlacoDataInGroupSplit } from "../../Calpinage/Helpers/placoHelper";
import {
  montantDefault,
  plaqueDefault,
} from "../../Calpinage/Components/Solution/TableRow";
import { processNewWallData } from "./processWall";
import { getProcessedWalls } from "./extractProcessWalls";
import { fetchPlacoSolutionsByTranslation } from "../../../../API";

export const groupWallModel = async (
  wallIdsExt: any[],
  solutionIds: string[],
  processedWallParams: any,
  config: any
) => {
  /// Extract selection ids

  if (wallIdsExt.length > 0) {
    /// 3. Get walls data from revit
    let wallsData = await api.queries.getWallsData(wallIdsExt);

    /// 4. Extract solution ids

    /// 5. Get solution data from api respected to solution ids
    const { extractedSolutionProduct } = await fetchPlacoDataInGroupSplit(
      solutionIds,
      config
    );

    wallsData = orderBy(wallsData, ["LevelElevation"], ["asc"]);

    let grpWallsData: any[] = [];

    forEach(wallsData, (value: any, index: number) => {
      //   let wallRow = find(wallRows, (walRow: any) => {
      //     return includes(walRow.RevitSelection.Ids, value.Id);
      //   });
      let placoSolutionForWall = find(
        extractedSolutionProduct,
        (solRow: any) => {
          let solutionProductNameAttr = solRow.solutionProduct.attributes.find((sol: any) => sol.technicalName === 'A-Solution product name');
          let solutionName = solutionProductNameAttr ? solutionProductNameAttr.values[0].value : solRow.solutionProduct.translation;
          return value.WallType.includes(solutionName);
        }
      );

      if (placoSolutionForWall) {
        let height: String = parseFloat(
          (value.Height / 1000).toString()
        ).toFixed(2);

        const placoSolutionHeightObject = find(
          placoSolutionForWall.solutionProduct.attributes,
          { technicalName: "GFR-Height limit in m" }
        );

        if (placoSolutionHeightObject != undefined) {
          const placoSolutionHeightValue =
            placoSolutionHeightObject.values[0].numericValue;

          let oneValue = find(grpWallsData, {
            LevelId: value.LevelId,
            Zone: "",
            WallType: value.WallType,
            Height: height,
            PlacoSolution: placoSolutionForWall.solutionProduct.translation,
          });

          if (oneValue) {
            let ids = oneValue.Ids;
            ids.push(value.Id);
            find(grpWallsData, {
              LevelId: value.LevelId,
              Zone: "",
              WallType: value.WallType,
              Height: height,
              PlacoSolution: placoSolutionForWall.solutionProduct.translation,
            }).Ids = ids;
          } else {
            grpWallsData.push({
              Height: height,
              LevelName: value.LevelName,
              PlacoSolution: placoSolutionForWall.solutionProduct.translation,
              PlacoSolutionId: placoSolutionForWall.solutionProduct.oid,
              PlacoSolutionHeight: placoSolutionHeightValue,
              Zone: "",
              WallType: value.WallType,
              LevelElevation: value.LevelElevation,
              LevelId: value.LevelId,
              Ids: [value.Id],
              montant: [],
              plaque: [],
            });
          }
        }
      }
    });

    const proceessWalls = processNewWallData(
      grpWallsData,
      extractedSolutionProduct
    );

    return proceessWalls;
  }

  return null;
};

export const collectWallFromBIMModel = async (config: any) => {
  //   const { wallIds, solutionIds, processedWallParams } = await getProcessedWalls(config);
  let elementParameters: any =
    await api.queries.getCustomParametersFromElementType();

  let placoSolFamily = filter(elementParameters, (elParam, ind) => {
    const idData = find(elParam.paramData, { key: "ID Système" });
    if (idData) {
      return idData.value !== null;
    } else {
      return false;
    }
  });

  if (placoSolFamily.length > 0) {
    placoSolFamily = map(placoSolFamily, (plsol, ind) => {
      const idData = find(plsol.paramData, { key: "ID Système" });
      return { ...plsol, externalName: idData.value };
    });
    const uniqueExternalNames = uniq(map(placoSolFamily, "externalName"));
    const uniqPlacoSolFamily = uniq(map(placoSolFamily, "solution"));
    // const filterElementsArray = map(uniqPlacoSolFamily, (plsol, ind) => {
    //   return {
    //     Param: {
    //       Name: "Name",
    //       Type: "Builtin",
    //       Value: plsol,
    //     },
    //     Rule: "Equals",
    //   };
    // });

    const processedWallIds = await api.queries.filterElements(
      "Wall",
      [
        {
          Param: {
            Name: "Name",
            Type: "Builtin",
            Value: uniqPlacoSolFamily,
          },
          Rule: "Includes",
        },
      ],
      null
    );

    // let processedWallIds: any[] = await api.queries.filterElements(
    //   "Wall",
    //   filterElementsArray,
    //   null
    // );
    if (processedWallIds.length > 0) {
      const { data } = await fetchPlacoSolutionsByTranslation(
        uniqueExternalNames,
        config
      );
      if (data) {
        // processedWallIds = processedWallIds.concat(wallIds);
        let processSolutionIds = map(data.objects, "oid");
        // processSolutionIds = processSolutionIds.concat(solutionIds);
        const processWalls = await groupWallModel(
          processedWallIds,
          processSolutionIds,
          [],
          config
        );
        return processWalls;
      }
    }
  }
  return null;
};
