// APP ACTIONS
export const CHANGE_CURRENT_WINDOW = "CHANGE_CURRENT_WINDOW";

// USER INFO ACTIONS
export const ACCEPT_TERMS_OF_USE = "ACCEPT_TERMS_OF_USE";
export const SHOW_INTRO = "SHOW_INTRO_VIDEO";

// PROJECTS ACTIONS
export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const RESET_CURRENT_PROJECT = "RESET_CURRENT_PROJECT";
export const SET_CURRENT_PROJECT_SELECTION_METHOD =
    "SET_CURRENT_PROJECT_SELECTION_METHOD";
export const SET_CURRENT_PROJECT_NAME = "SET_CURRENT_PROJECT_NAME";
export const SET_CURRENT_PROJECT_COUNTRY = "SET_CURRENT_PROJECT_COUNTRY";
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const SET_CURRENT_PROJECT_INITIAL_DATES =
    "SET_CURRENT_PROJECT_INITIAL_DATES";
export const SET_CURRENT_PROJECT_ITEMS = "SET_CURRENT_PROJECT_ITEMS";

export const SET_CURRENT_LOOSE_ELEMENTS = "SET_CURRENT_LOOSE_ELEMENTS";
export const SET_CURRENT_PROJECT_ALL_AVAILABLE_ELEMENTS =
    "SET_CURRENT_PROJECT_ALL_AVAILABLE_ELEMENTS";

export const SET_ELEMENTS_FILTERS = "SET_ELEMENTS_FILTERS";
export const SET_PROJECT_ITEMS = "SET_PROJECT_ITEMS";

export const SET_SELECTED_ELEMENT_IN_ITEM = "SET_SELECTED_ELEMENT_IN_ITEM";
export const SET_CURRENT_ITEM_AND_SANITIZE_AVAILABLE_ELEMENTS =
    "SET_CURRENT_ITEM_AND_SANITIZE_AVAILABLE_ELEMENTS";

export const VALIDATE_SELECTION = "VALIDATE_SELECTION";

export const SET_CURRENT_PROJECT_CURRENT_ITEM_COMPATIBLE_AND_AVAILABLE_ELEMENTS =
    "SET_CURRENT_PROJECT_CURRENT_ITEM_COMPATIBLE_AND_AVAILABLE_ELEMENTS";

export const SET_CURRENT_PROJECT_VALIDATED_ITEMS =
    "SET_CURRENT_PROJECT_VALIDATED_ITEMS";

export const SAVE_PROJECT = "SAVE_PROJECT";

export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
