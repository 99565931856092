import React from "react";
import { Button, Dimmer, Icon, Loader, Modal } from "semantic-ui-react";

interface Props {
  showPopup: boolean;
  onContinue: any;
  loading: boolean;
}

export const ScanElementPopup = ({ showPopup, onContinue, loading }: Props) => {
  
  if (loading) {
    return (
      <Dimmer active={loading} page>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <Modal size="tiny" open={showPopup} onClose={() => onContinue()}>
      {/* <Icon
        name="close"
        style={{
          float: "right",
          color: "#333333",
          marginTop: "-10px",
          cursor: "pointer",
        }}
        onClick={() => onContinue()}
      ></Icon> */}
      <Modal.Content>
        <div
          style={{
            textAlign: "center",
            padding: "2rem 1.6rem 1.6rem 1.6rem",
          }}
        >
          <p>
            Un ou plusieurs murs appartenant à une ou plusieurs sélections
            enregistrées a été supprimé ou modifié (type) dans le projet.
            Veuillez revérifier le contenu de vos calepinage
          </p>
          <Button color="blue" onClick={() => onContinue()}>
          Continuer
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
