import React from "react";
import { withTranslation } from "react-i18next";

const DiameterFilters = ({
    handleFilter,
    filterObjectRaw,
    filterObjectName,
    filterKey,
}: any) => {
    let filterObject = filterObjectRaw[filterKey];
    return (
        <>
            {filterObject.map((dn: any, index: number) => {
                return (
                    <div
                        key={"inner-filter-angle-diameter-" + index}
                        className="inner-filter-angle-diameter-div"
                    >
                        <input
                            className="mx-1"
                            type="checkbox"
                            checked={dn.selected}
                            onChange={(e) => {
                                handleFilter(
                                    e.target.checked,
                                    filterObjectName,
                                    filterKey,
                                    dn.diameter,
                                    dn.nbrElements
                                );
                            }}
                        ></input>
                        <span className={"mx-1"}>{`DN ${dn.diameter}`}</span>
                    </div>
                );
            })}
        </>
    );
};

export default withTranslation(["pam"])(DiameterFilters);
