import React from "react";
import { Container, Header } from "semantic-ui-react";

import "./index.scss"

interface FunctionalityHeaderProps {
  name: string;
  icon?: string;
}

export const FunctionalityHeader: React.FC<FunctionalityHeaderProps> =
  ({ name, icon = "/WeberIndonesia_logo.png" }) => (

    <Container textAlign="center" style={{ marginBottom: 0 }}>
      <Header
        color="black"
        style={{ paddingTop: "0", paddingBottom: 0 }}
        as="h3"
        content={name}
        image={icon}
      />
    </Container>
  )

export default FunctionalityHeader
