import axios from "axios";
import { MyConfig } from "../Helper";
import jwt_decode from "jwt-decode";
import { AuthorizationCodeGrantResponse } from "./Types/authenticate.type";
import { registerFing } from "../Components/Store/Authenticate";
import { AuthorizationCodeRequest } from "./Types/authorizationCodeRequest.type";

export const getValidJwtToken = async (cnf: any): Promise<string> => {
  let token = localStorage.getItem("token");
  if (token !== null && !hasTokenExpired(token)) {
    return token;
  }
  const refreshToken = await getValidRefreshToken(cnf);
  token = (await getJwtToken(refreshToken, cnf)).access_token;
  localStorage.setItem("token", token);
  registerFing(token, cnf);
  return token;
};

export const getNewJwtToken = async (cnf: any): Promise<string> => {
  const refreshToken = await getValidRefreshToken(cnf);
  const token = (await getJwtToken(refreshToken, cnf)).access_token;
  localStorage.setItem("token", token);
  registerFing(token, cnf);
  return token;
};

const getValidRefreshToken = async (cnf: any) => {
  let refreshToken = localStorage.getItem("refresh_token");
  if (refreshToken !== null && !hasTokenExpired(refreshToken)) {
    return refreshToken;
  }
  //TODO: REDIRECT LOGIC

  window.location.href =
    cnf.REACT_APP_AUTHENTICATIONURL + `` + cnf.REACT_APP_LOGIN_REDIRECT;
  // un reachable code
  return "";
};

const hasTokenExpired = (token: string): boolean => {
  let authData: any = jwt_decode(token);
  const { exp } = authData;
  let currentTime: Date = new Date();
  let stamp = currentTime.getTime() / 1000;
  return exp < stamp;
};

export const getRefreshToken = async (
  authCode: string,
  cnf: any
): Promise<AuthorizationCodeGrantResponse> => {
  const body: AuthorizationCodeRequest = {
    authCode: authCode,
    redirectUri: cnf.REACT_APP_REDIRECT_URI,
    clientId: cnf.REACT_APP_AUTH_CLIENT_ID,
    refreshToken: "",
  };
  return axios({
    method: "POST",
    url:
      cnf.REACT_APP_AUTHENTICATION_API_ANONYMOUS +
      "/oauth/token/authenticate-by-auth-code",
    data: body,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};

/*
 * DEPRECATED
 */
export const getRefreshToken2 = async (
  authCode: string,
  cnf: any
): Promise<AuthorizationCodeGrantResponse> => {
  // authEndPoint.post("asdaf").
  // return authEndPoint.post({
  //     url:
  //       process.env.REACT_APP_AUTHENTICATIONURL +
  //       "/oauth/token",
  //     params : {
  //         code : authCode,
  //         client_Id : process.env.REACT_APP_AUTH_CLIENT_ID,
  //         grant_type : 'authorization_code',

  //         redirect_uri : process.env.REACT_APP_SERVERURL + "/authenticate",
  //     }
  //   })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err: any) => {
  //         return Promise.reject(err);
  //     });
  return axios({
    method: "POST",
    url: cnf.REACT_APP_AUTHENTICATIONURL + "/oauth/token",
    params: {
      code: authCode,
      client_Id: cnf.REACT_APP_AUTH_CLIENT_ID,
      grant_type: "authorization_code",

      redirect_uri: cnf.REACT_APP_REDIRECT_URI,
    },
    headers: {
      Authorization:
        "Basic " +
        btoa(
          `${cnf.REACT_APP_AUTH_CLIENT_ID}:${cnf.REACT_APP_AUTH_CLIENT_SECRET}`
        ),
      "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};

export const getJwtToken = async (
  refreshToken: string,
  cnf: any
): Promise<AuthorizationCodeGrantResponse> => {
  const body: AuthorizationCodeRequest = {
    authCode: "",
    redirectUri: "",
    clientId: cnf.REACT_APP_AUTH_CLIENT_ID,
    refreshToken: refreshToken,
  };
  return axios({
    method: "POST",
    url:
      cnf.REACT_APP_AUTHENTICATION_API_ANONYMOUS +
      "/oauth/token/refresh-user-token",
    data: body,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};

/*
 * DEPRECATED
 */
export const getJwtToken2 = async (
  refreshToken: string,
  cnf: any
): Promise<AuthorizationCodeGrantResponse> => {
  return axios({
    method: "POST",
    url: cnf.REACT_APP_AUTHENTICATIONURL + "/oauth/token",
    params: {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    },
    headers: {
      Authorization:
        "Basic " +
        btoa(
          `${cnf.REACT_APP_AUTH_CLIENT_ID}:${cnf.REACT_APP_AUTH_CLIENT_SECRET}`
        ),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
};
