import React from "react";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { Icon, Grid } from "semantic-ui-react";
import "../Resources/daoSelection.css";
import FormModal from "./FormModal";
import { Routes } from "./root";
import { setDossierData } from "../Actions";
import {
  checkIfDossierTechniquesExist,
  saveDossierTechniques,
} from "../../DocumentTechniques/Helpers";
import { SavedDocuments } from "./SavedDocuments";
import { v4 } from "uuid";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { filter, flatMapDeep, map } from "lodash";
import { api } from "../../../../RevitJS/API";

interface Props {
  wording: {
    new: { [key: string]: string };
    noDocumentSaved: { [key: string]: string };
    documentNames: { [key: string]: string };
    editDate: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  route: Routes;
  tabName: string;
  setDossierData: any;
  dossierData: any;
  documents: [];
  refreshData: any;
  handleTabChange: any;
  config: any;
}

export class DocumentBuilderHome extends React.Component<Props> {
  state = {
    documentNameDimmer: false,
    selectedDocId: "",
    errorId: "",
    newName: "",
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  reroute = () => {
    this.props.setRoute(Routes.TECHNICAL_LANDING);
  };

  toggleAction = (actionName: string, action: boolean) => {
    this.setState({ [actionName]: action });
  };

  deleteDocumentData = async (documentId: string) => {
    let dossierTechnique: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    if (dossierTechnique && dossierTechnique.length > 0) {
      let dt = dossierTechnique.find((a: any) => a.documentId === documentId);
      const nonPlacoFiles = map(
        flatMapDeep(
          map(
            filter(dt.data.list, (dl) => dl.isNonPlaco),
            "files"
          )
        ),
        "fileData"
      );
      // if (nonPlacoFiles) {
      //   await api.queries.removePhotoboxFile(JSON.stringify(nonPlacoFiles));
      // }
      dossierTechnique = dossierTechnique.filter(
        (a: any) => a.documentId !== dt.documentId
      );
    }

    await bimStorage.setItem(
      storageKey.DOSSIER_TECHNIQUE,
      JSON.stringify(dossierTechnique)
    );
    setTimeout(async () => {
      await this.props.refreshData();
    }, 500);

  };

  cloneDocumentData = async (documentId: string, documentType: string) => {
    let activeIndex: any =
      documentType === "DAO"
        ? 0
        : documentType === "DAE"
          ? 1
          : documentType === "DOE"
            ? 2
            : "";
    this.props.handleTabChange({ activeIndex });
    let dossierTechnique: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    if (dossierTechnique && dossierTechnique.length > 0) {
      let dtOriginal = dossierTechnique.find(
        (a: any) => a.documentId === documentId
      );
      let dtNew = JSON.parse(JSON.stringify(dtOriginal));
      let duplicated = 1;

      let newName = `${dtNew.name} (${duplicated})`;

      const checkIfNewNameExist = () => {
        return Object.values(dossierTechnique).some((dossier: any) => {
          return dossier.name.toLowerCase() === newName.toLowerCase();
        });
      };
      while (checkIfNewNameExist()) {
        duplicated += 1;
        newName = `${dtNew.name} (${duplicated})`;
      }

      if (dtNew.documentType !== "DAO" && documentType === "DAO") {
        dtNew.data.list = map(dtNew.data.list, (lst, index) => {
          if (lst.files.length > 1) {
            lst.files = filter(lst.files, (file) => {
              return file.fileType === "Fiche système" || file.fileType === "FP";
            });
          }
          return lst;
        });
      }

      dtNew.name = newName;
      dtNew.duplicated = 0;
      dtNew.documentId = v4();
      let date = new Date();
      let day: any = date.getDate();
      let month: any = date.getMonth() + 1;
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      dtNew.documentDate = `${day}/${month}/${date.getFullYear()}`;
      dtNew.documentType = documentType;
      await saveDossierTechniques(dtNew, this.props.config);
      dtOriginal.duplicated = duplicated;
      setTimeout(async () => {
        await saveDossierTechniques(dtOriginal, this.props.config);
        //}
        await this.props.refreshData();
      }, 500);

    }
    localStorage.setItem("isModification", "true");
  };

  openDocumentData = async (documentId: string) => {
    let dossierTechnique: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    if (dossierTechnique && dossierTechnique.length > 0) {
      let dt = dossierTechnique.find((a: any) => a.documentId === documentId);
      this.props.setDossierData(dt);
      this.props.setRoute(Routes.TECHNICAL_LANDING);
    }
  };

  onChangeDocumentName = (e: any) => {
    this.setState({ newName: e.target.value });
    localStorage.setItem("isModification", "true");
  };

  onSaveDocumentName = async (doc: any) => {
    let dossierTechnique: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    if (dossierTechnique && dossierTechnique.length > 0) {
      let dt = doc;
      const ifExist: boolean = await checkIfDossierTechniquesExist(
        this.state.newName,
        dt.modelName,
        dt.documentId,
        this.props.tabName,
        this.props.config
      );
      if (ifExist) {
        this.setState({ errorId: dt.documentId });
      } else {
        if (dt.name !== this.state.newName) {
          let date = new Date();
          let day: any = date.getDate();
          let month: any = date.getMonth() + 1;
          if (day < 10) {
            day = "0" + day;
          }
          if (month < 10) {
            month = "0" + month;
          }
          dt.documentDate = `${day}/${month}/${date.getFullYear()}`;
        }
        dt.name = this.state.newName;
        this.setState({ newName: "", selectedDocId: "", errorId: "" });
        saveDossierTechniques(dt, this.props.config).then((data) => {
          this.props.refreshData();
        });
      }
    }
    localStorage.setItem("isModification", "false");
  };

  onDocumentSelect = (selectedDoc: any) => {
    let selectedDocId = selectedDoc.documentId;
    this.setState({ selectedDocId, newName: selectedDoc.name });
  };

  render() {
    const convertDate = (d: any) => {
      let sp = d.split("/");
      let ret = [sp[1], sp[0], sp[2]].join("/");
      return ret;
    };
    this.props.documents.sort((a: any, b: any) => {
      return +new Date(convertDate(b.documentDate)) - +new Date(convertDate(a.documentDate));
    });
    return (
      <div>
        <FormModal
          currentDocuments={this.props.documents}
          documentNameDimmer={this.state.documentNameDimmer}
          toggleDocumentDimmer={() =>
            this.toggleAction("documentNameDimmer", true)
          }
          closeDimmer={() => this.toggleAction("documentNameDimmer", false)}
          tabName={this.props.tabName}
          reroute={() => this.props.setRoute(Routes.TECHNICAL_LANDING)}
        />
        <div className="contentSegment">
          <Grid columns={3}>
            <Grid.Row
              className="contentHeader"
              style={{
                alignItems: "center",
                border: "0.5px solid rgb(200, 200, 200)",
                height: 30,
                padding: 0,
              }}
            >
              <Grid.Column width={7} style={{}}>
                {this.props.wording.documentNames[this.props.language]}
              </Grid.Column>
              <Grid.Column width={7} style={{ marginLeft: -75 }}>
                {this.props.wording.editDate[this.props.language]}
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{ lineHeight: "0em" }}
              ></Grid.Column>
            </Grid.Row>
            <div
              style={{
                border: "0.5px solid rgb(200, 200, 200)",
                width: "100%",
                height: "calc(100vh - 220px)",
                padding: 0,
                overflowY: "auto",
              }}
            >
              {this.props.documents && this.props.documents.length > 0 ? (
                this.props.documents.map((doc: any) => (
                  <SavedDocuments
                    doc={doc}
                    key={doc.documentId}
                    openDocumentData={this.openDocumentData}
                    deleteDocumentData={this.deleteDocumentData}
                    cloneDocumentData={this.cloneDocumentData}
                    onChangeDocumentName={this.onChangeDocumentName}
                    onSaveDocumentName={this.onSaveDocumentName}
                    onDocumentSelect={this.onDocumentSelect}
                    selectedDocId={this.state.selectedDocId}
                    errorId={this.state.errorId}
                    newName={this.state.newName}
                    language={this.props.language}
                  />
                ))
              ) : (
                <Grid.Row>
                  <Grid.Column
                    style={{
                      width: "100%",
                      color: "rgb(175,171,171)",
                      padding: 20,
                      textAlign: "center",
                    }}
                  >
                    {this.props.wording.noDocumentSaved[this.props.language]}
                  </Grid.Column>
                </Grid.Row>
              )}
            </div>

            <Grid.Row>
              <Grid.Column>
                <span
                  style={{
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                  onClick={() => this.toggleAction("documentNameDimmer", true)}
                >
                  <Icon name="plus circle" color="blue" size="big" />
                  <span style={{ color: "rgb(14,110,184)" }}>
                    {this.props.wording.new[this.props.language] +
                      " " +
                      this.props.tabName}
                  </span>
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  setDossierData,
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    routes: ownProps.route,
    wording: ownProps.wording,
    language: state.language,
    dossierData: state.moduleData.dossierData,
    config: state.config,
  };
};

export default connect(mapStateToProps, mapDispatch)(DocumentBuilderHome);
