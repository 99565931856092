import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { ProjectInfoRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "0ad35a84-d2f2-11ea-87d0-0242ac130003";

export const infoProjectsTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    800,
    650,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="project info"
    >
      <ProjectInfoRoot />
    </ParentRoot>
  );
};

export const ProjectInfoRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="project info"
    >
      <ProjectInfoRoot />
    </ParentRoot>
  );
};

export const ProjectInfo: Functionality = {
  Name: {
    French: "Projet",
    English: "Project",
    German: "Projet",
    Italian: "Projet",
    Indonesian: "Projet",
  },
  Trigger: infoProjectsTrigger,
  ShortDesc: {
    French: "Informations projet",
    English: "Project information",
    German: "Informations projet",
    Italian: "Informations projet",
    Indonesian: "Informations projet",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Projet.png",
  RootComponent,
  ParentId: "0",
};
