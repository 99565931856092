import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import checkMarkGray from "../../../../assets/check-mark-gray.png";
import checkMark from "../../../../assets/check-mark.png";
import CouplingFamilyType from "../../../../models/Coupling";
import Element from "../../../../models/Element.model";
import Item from "../../../../models/Item.model";

function TdComponent({
  item,
  element,
  coupling,
  itemIndex,
  elementIndex,
  couplingIndex,
  isElementCompatibleWithCoupling,
  toggleMatchElementCoupling,
  setModal,
  t,
}: IProps) {
  const isCouplingSelectedForElement = (
    element: Element,
    familyType: CouplingFamilyType
  ): boolean => {
    let found = false;

    familyType.couplings.forEach((coupling) => {
      if (element.selectedCoupling?.oid === coupling.oid) {
        found = true;
      }
    });
    return (
      found &&
      element.selectedCoupling !== undefined &&
      element.selectedCoupling.familyType === familyType.familyType &&
      element.selectedCoupling.quality === familyType.selectedQuality &&
      element.selectedCoupling.gasket === familyType.selectedGasket
    );
  };

  const isOtherCouplingSelectedForElement = (
    element: Element,
    familyType: CouplingFamilyType
  ): boolean => {
    return (
      element.selectedCoupling !== undefined &&
      element.selectedCoupling.familyType === familyType.familyType &&
      element.selectedCoupling !== null &&
      (element.selectedCoupling.quality !== familyType.selectedQuality ||
        element.selectedCoupling.gasket !== familyType.selectedGasket)
    );
  };

  const getSelectionAndCOmpatibility = (
    element: Element,
    coupling: CouplingFamilyType
  ) => {
    let compatibleCoupling = isElementCompatibleWithCoupling(element, coupling);
    let selectedCoupling = isCouplingSelectedForElement(element, coupling);
    let selectedForOtherCoupling = isOtherCouplingSelectedForElement(
      element,
      coupling
    );

    let selectedForSameFamily =
      selectedForOtherCoupling ||
      (element.selectedCoupling !== undefined &&
        element.selectedCoupling.familyType === coupling.familyType &&
        !selectedCoupling &&
        compatibleCoupling);

    return {
      compatibleCoupling,
      selectedCoupling,
      selectedForOtherCoupling,
      selectedForSameFamily,
    };
  };

  let {
    compatibleCoupling,
    selectedCoupling,
    selectedForOtherCoupling,
    selectedForSameFamily,
  } = getSelectionAndCOmpatibility(element, coupling);
  return (
    <td
      onClick={(e) => {
        selectedForSameFamily && compatibleCoupling
          ? setModal({
              open: true,
              target: e.currentTarget,
              item: item,
              itemIndex,
              elementIndex,
              couplingIndex,
            })
          : toggleMatchElementCoupling(
              item,
              elementIndex,
              couplingIndex,
              itemIndex,
              !e.currentTarget?.classList.contains("selected-coupling")
            );
      }}
      title={
        selectedForSameFamily
          ? t("CONFIRM_CHANGE_COUPLING_SELECTION_TOOLTIP") || ""
          : ""
      }
      key={`coupling-selection-inner-table-dn-td-${itemIndex}-${elementIndex}-${couplingIndex}`}
      id={`coupling-selection-inner-table-dn-td-${itemIndex}-${elementIndex}-${couplingIndex}`}
      className={`${compatibleCoupling ? "" : "incompatible-coupling"} 
                ${selectedCoupling ? "selected-coupling" : ""}
                ${selectedForOtherCoupling ? "selected-for-other-coupling" : ""}
                ${
                  element.selectedCoupling !== undefined ? "elementMatched" : ""
                }`}
    >
      <img
        style={{
          width: "1.3em",
        }}
        className="m-auto d-none selected-coupling-img"
        src={checkMark}
        alt="Matched & selected"
      ></img>
      {/* <div className="m-auto d-none selected-for-other-coupling-div">
                                                {element.selectedCoupling?.quality} / {element.selectedCoupling?.gasket}
                                            </div>  */}
      <div className="m-auto d-none selected-for-other-coupling-img">
        <img
          style={{
            width: "1.3em",
          }}
          className="m-auto"
          src={checkMarkGray}
          alt="Matched & selected"
        ></img>
      </div>
    </td>
  );
}

type IProps = WithTranslation & {
  item: Item;
  coupling: CouplingFamilyType;
  element: Element;
  itemIndex: number;
  elementIndex: number;
  couplingIndex: number;
  isElementCompatibleWithCoupling: Function;
  toggleMatchElementCoupling: Function;
  setModal: Function;
};

export default withTranslation(["pam"])(TdComponent);
