import React, { useEffect, useState } from "react";
import { MyConfig } from "../../../../Helper";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";

interface Props {
  setRoute: any;
}

const Superposer = (props: Props) => {
  const [config, setConfig] = useState({REACT_APP_SERVERURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])
  
  return (
    <>
     <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/Facade/superposer.png"}
        name={"superposer"}
        subheader={""}
        config={config}
      />
     
    </>
  );
};

export default Superposer;
