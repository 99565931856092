import React from "react";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { Icon, Popup } from "semantic-ui-react";

interface Props {
  color: any;
  onColorChanged: (color: {
    r: string;
    g: string;
    b: string;
    a: string;
  }) => void;
}

export const ColorPicker = (props: Props) => {
  const { onColorChanged, color } = props;
  const [display, setDisplay] = useState(false);

  return (
    <>
      <Popup
        trigger={
          <Icon
            // name="square full"
            style={{
              color: "#000",
              cursor: "pointer",
              marginRight: "10px",
              background: `rgba(${color.R}, ${color.G}, ${color.B}, ${color.A})`,
            }}
          />
        }
        content="Choisir une couleur"
        on="hover"
        // inverted
        size="mini"
        position="bottom right"
        // flowing
        hoverable
      >
        <div onClick={() => setDisplay(false)} />
        <SketchPicker
          color={{ r: color.R, g: color.G, b: color.B, a: color.A }}
          onChangeComplete={(color: any) => {
            onColorChanged(color.rgb);
          }}
        />
      </Popup>
    </>
  );
};
