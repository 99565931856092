import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import "../../Assets/main.scss";

export const ShoppingCartComponent = ({
  countSystem = 0,
  onClick,
  disabled,
}: ShoppingCart) => {
  return (
    <IconButton
      aria-label="add to shopping cart"
      onClick={() => onClick()}
      disabled={disabled}
    >
      <Badge badgeContent={countSystem} color="secondary" showZero>
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

export interface ShoppingCart {
  countSystem: number;
  onClick: any;
  disabled: boolean;
}
