import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ACCEPT_GENERAL_TERMS_OF_USE_TEXT } from "../../../assets/constants";
import AnimatedCheckbox from "../../common/animated-checkbox/AnimatedCheckbox";
import "./body.scss";

function Body({ acceptedTermsOfUse, changedTermsOfUse, t, i18n }: Props) {
  return (
    <div id="gtou-body-container">
      <div className="row">
        <div className="col-12 p-4">
          {i18n.language === "French" ? (
            <div id="terms-of-use-text-container">
              <h5>
                Conditions Générales d'Utilisation <br></br> du plugin PAM Data
                Import
              </h5>
              <p>Entre</p>
              <p>
                Saint-Gobain PAM Bâtiment, Société par Actions Simplifiée au
                capital de 6 812 970.75 euros, dont le siège social est 21
                avenue Camille Cavallier 54700 PONT-A-MOUSSON, immatriculée au
                Registre du Commerce et des Sociétés de Nancy sous le numéro 799
                283 882, ci-après dénommé le « Concédant »,
              </p>
              <p>et</p>
              <p>
                Toute personne téléchargeant le plugin PAM Data Import, ci-après
                le « Concessionnaire ».
              </p>
              <p>IMPORTANT - À LIRE AVEC ATTENTION</p>
              <p>
                A titre préalable, le Concessionnaire a dû accepter les
                conditions d’utilisation des Objets BIM lors de la création de
                son compte dans la bibliothèque d’objets BIM Saint-Gobain.{" "}
              </p>
              <p>
                En téléchargeant ou en utilisant le Plugin PAM Data Import
                (ci-après le « Plugin PAM ») vous vous engagez à respecter les
                termes des présentes conditions générales d’utilisation .{" "}
              </p>
              <p>
                VOUS ACCEPTEZ QUE CES CONDITIONS SOIENT APPLICABLES AU MEME
                TITRE QUE TOUT CONTRAT NEGOCIE ET SIGNE PAR VOUS.
              </p>
              <p>
                Si vous n'êtes pas d'accord avec ces termes vous ne devez pas
                utiliser le Plugin PAM.{" "}
              </p>
              <p>
                <strong>Article 1 : Définitions </strong>
              </p>
              <p>
                Dans les présentes conditions le terme “
                <strong>Plugin PAM</strong>” désigne le plugin PAM Data Import
                disponible dans sa version actuelle au sein du plugin store de
                Saint-Gobain.{" "}
              </p>
              <p>
                Le terme « <strong>Objet(s) BIM</strong> » signifie le / les
                objet(s) BIM disponible(s) dans la bibliothèque d’objets BIM
                Saint-Gobain.{" "}
              </p>
              <p>
                Le terme « <strong>Utilisation</strong> » désigne le
                téléchargement du Plugin PAM, son installation ou l’usage qui en
                est fait afin de permettre au Concessionnaire d’enrichir en
                données produits PAM une maquette BIM d’un Projet, ci-après la
                “Maquette BIM”.{" "}
              </p>
              <p>
                Le terme « <strong>Projet</strong> » désigne tout projet de
                construction neuve / réhabilitation ou aménagement pour lequel
                le Concessionnaire a été mandaté ou consulté.{" "}
              </p>
              <p>
                <strong>
                  Article 2 : Objet des conditions générales d’utilisation{" "}
                </strong>{" "}
              </p>
              <p>
                Les présentes conditions générales d’utilisation (ci-après les
                “Conditions”) ont pour objet de définir les principes et
                conditions aux termes desquels le Concédant attribue au
                Concessionnaire un droit d'utilisation, non exclusif et non
                cessible du Plugin PAM.{" "}
              </p>
              <p>
                <strong>Article 3 : Droits et limitations </strong>
              </p>
              <p>Le Concessionnaire est autorisé à : </p>
              <p>
                - Utiliser le Plugin PAM sur un (1) ou plusieurs ordinateurs
                uniquement dans le cadre du Projet.{" "}
              </p>
              <p>Le Concessionnaire n'est pas autorisé à : </p>
              <p>
                - Utiliser le Plugin PAM, en dehors des conditions autorisées
                par les présentes conditions.{" "}
              </p>
              <p>
                - Faire de l'ingénierie à rebours, désassembler, décompiler le
                Plugin PAM pour créer tout type d’objet BIM ou de plugin dérivé.{" "}
              </p>
              <p>
                En cas d’Utilisation à d’autres fins que l’étude ou la
                réalisation du Projet, le Concédant se réserve le droit d’agir à
                l’encontre du Concessionnaire devant les juridictions
                compétentes sur tout fondement utile.{" "}
              </p>
              <p>
                <strong>Article 4 : Mise à jour du Plugin PAM </strong>
              </p>
              <p>
                Le Concédant peut faire évoluer le Plugin PAM et peut, à son bon
                vouloir, mettre de nouvelles versions à la disposition du
                Concessionnaire. Toute nouvelle version du Plugin PAM vient
                remplacer la précédente et demeure soumise aux présentes
                Conditions.{" "}
              </p>
              <p>
                Il appartient au Concessionnaire de vérifier qu’il dispose du
                plugin PAM dans sa dernière version.{" "}
              </p>
              <p>
                <strong>
                  Article 5 : Validité des Conditions Générales d’Utilisation{" "}
                </strong>
              </p>
              <p>
                Sauf résiliation dans les conditions prévues à l'Article 11, les
                présentes Conditions sont valables pour la durée d’utilisation
                autorisée des Objets BIM, telle qu’indiquée dans les conditions
                d’utilisation des Objets BIM.{" "}
              </p>
              <p>
                <strong>Article 6 : Droits sur le Plugin PAM </strong>
              </p>
              <p>
                Le Plugin PAM est protégé par la loi sur les droits d'auteur et
                les conventions internationales. Le Concédant est titulaire de
                tous droits utiles sur le Plugin PAM.{" "}
              </p>
              <p>
                Il est rappelé que la violation de l'un des droits des auteurs
                est un délit de contrefaçon et que toute contrefaçon est
                sanctionnée en France par l'Article L335-2 du code de la
                propriété intellectuelle et punie de trois ans d'emprisonnement
                et de 300 000 euros d'amende.{" "}
              </p>
              <p>
                Tout acte de contrefaçon susceptible d'être ainsi qualifié et
                qui serait le fait de tiers et dont le Concessionnaire aurait
                connaissance devrait être par lui dénoncé au Concédant qui fera
                son affaire personnelle des poursuites à mener. Toute allégation
                de contrefaçon formée contre le Concessionnaire du fait de
                l'usage du Plugin PAM devra être portée à la connaissance du
                Concédant qui apportera son concours à la défense du
                Concessionnaire dans les conditions et formes qu'il jugera bon
                et fixera seul.{" "}
              </p>
              <p>
                <strong>Article 7 : Garantie </strong>
              </p>
              <p>
                Le Plugin PAM s’intègre dans un outil informatique complexe et
                en constante évolution. En l'absence de maitrise des évolutions
                futures de cet outil informatique aucune garantie ne peut être
                donnée par le Concédant.{" "}
              </p>
              <p>
                Autant que la loi applicable l'y autorise, l’utilisation du
                Plugin PAM est autorisée par le Concédant , sans aucune garantie
                ou assurance quelle qu'elle soit, écrite ou orale, expresse ou
                implicite. En particulier, le Concédant ne garantit pas que le
                Plugin PAM sera compatible avec l'équipement du Concessionnaire
                et sa configuration logicielle ni qu'il fonctionnera dans de
                bonnes conditions sur la configuration matérielle et logicielle
                du Concessionnaire.{" "}
              </p>
              <p>
                <strong>Article 8 : Responsabilité </strong>
              </p>
              <p>
                Dans toute la mesure permise par la loi applicable, le Concédant
                ne saurait voir sa responsabilité engagée en cas de dommages de
                quelque nature que ce soit subis par le Concessionnaire ou des
                tiers et résultant directement ou indirectement de son
                utilisation, notamment la perte ou détérioration de données, ou
                toute perte financière ou commerciale résultant de l’Utilisation
                ou de l'impossibilité d’utiliser le Plugin PAM, et ceci même si
                le Concédant a été avisé au préalable de l'éventualité de tels
                dommages.{" "}
              </p>
              <p>
                Il incombe au Concessionnaire de s’assurer si l’utilisation du
                Plugin PAM est appropriée dans le cadre du Projet et répond aux
                exigences réglementaires au niveau national, local ou régional.{" "}
              </p>
              <p>
                Le Concédant ne saurait d'aucune manière et en aucun cas être
                tenu de réparer d'éventuels dommages directs ou indirects liés à
                l’utilisation du plugin PAM.{" "}
              </p>
              <p>
                Le contenu de la Maquette BIM et ses fonctions permet une
                modélisation interactive d'objets provenant de différentes
                sources (ci-après la « Modélisation »).{" "}
              </p>
              <p>
                En aucune manière le Concédant ne pourra être tenu pour
                responsable en cas de dissemblance avec les objets modélisés ni
                de l’usage qui sera fait de la Modélisation.{" "}
              </p>
              <p>
                La Modélisation ne confère aucun droit au Concessionnaire sur
                les données ajoutées à la Maquette BIM grâce au Plugin PAM, en
                ce compris les marques et images ainsi que tout autre contenu
                propriété du Concédant.{" "}
              </p>
              <p>
                <strong>
                  Article 9 : Confidentialité - Données Personnelles{" "}
                </strong>
              </p>
              <p>
                Le Concessionnaire reconnaît que les informations techniques
                relatives au Plugin PAM et à son usage sont confidentielles et
                s’engage en conséquence à ne les divulguer qu’aux seuls
                entrepreneurs et à ses partenaires intervenant sur le Projet, en
                plus de ses propres employés.{" "}
              </p>
              <p>
                A ce titre, le Concessionnaire s’engage à faire respecter la
                confidentialité visée au précédent alinéa par ses employés et
                par les entrepreneurs et partenaires intervenant sur le Projet.{" "}
              </p>
              <p>
                L’obligation de confidentialité du Concédant demeurera en
                vigueur pendant toute la durée de validité des présentes
                Conditions telle que visée à l’Article 5.{" "}
              </p>
              <p>
                La collecte des données personnelles communiquées par le
                Concessionnaire lors de l’acceptation des présentes Conditions
                permet au Concédant d’avertir le Concessionnaire des mises à
                jour du Plugin PAM et de lui apporter toute information,
                notamment commerciale, relative aux produits et services PAM.
                Les données personnelles sont accessibles par / destinées aux
                services suivants : services informatiques, marketing,
                assistance technique, communication, prescription et
                commerciaux. Le Concessionnaire peut s’opposer à tout moment à
                cette utilisation de ses données à des fins commerciales en
                envoyant un e-mail à : privacycontact.sgpam.fr@saint-gobain.com.
                Ces données personnelles sont conservées trois (3) ans à compter
                de leur collecte ou du dernier contact émanant du
                Concessionnaire. Conformément à la réglementation applicable, le
                Concessionnaire dispose d’un droit d’accès, de rectification et
                d’effacement et du droit à la limitation du traitement le
                concernant qu’il peut exercer en envoyant un e-mail à :
                privacycontact.sgpam.fr@saint-gobain.com{" "}
              </p>
              <p>
                <strong>Article 10 : Indépendance des clauses </strong>
              </p>
              <p>
                Si l'une quelconque des dispositions des présentes Conditions
                est retenue pour nulle ou sans objet, elle sera réputée non
                écrite et n'entraînera pas la nullité des autres dispositions.{" "}
              </p>
              <p>
                <strong>
                  Article 11 : Non-respect des Conditions d’Utilisation{" "}
                </strong>
              </p>
              <p>
                Le Concessionnaire ne sera plus autorisé à utiliser le Plugin
                PAM s’il apparaît qu’il ne respecte pas les présentes Conditions
                ni celles liées à son compte dans la bibliothèque d’objets BIM
                Saint-Gobain, et ce malgré une mise en demeure par email
                adressée par le Concédant. Suite à une nouvelle notification du
                Concédant par email, le Concessionnaire devra désinstaller le
                Plugin PAM dans les plus brefs délais, et au plus tard dans les
                sept (7) jours suivant cette notification. Le Concédant se
                réserve le droit de vérifier que le Concessionnaire a bien fait
                le nécessaire et à défaut pourra mettre en œuvre toute action
                utile pour l’y contraindre.{" "}
              </p>
              <p>
                <strong>Article 12 : Incessibilité des Conditions </strong>
              </p>
              <p>
                L’adhésion aux présentes Conditions est faite à titre
                individuel. Elle ne peut donner lieu à une sous-licence ou à une
                quelconque autorisation donnée par le Concessionnaire à un
                tiers.{" "}
              </p>
              <p>
                <strong>Article 13 : Droit Applicable </strong>
              </p>
              <p>
                Les présentes Conditions seront régies et interprétées
                conformément au droit français.{" "}
              </p>
              <p>
                <strong>Article 14 : Règlement des litiges </strong>
              </p>
              <p>
                En cas de litige né de l'exécution ou de l'interprétation des
                présentes Conditions, les parties s'engagent à rechercher une
                solution amiable. A défaut, ce différend sera la compétence
                exclusive des tribunaux français.{" "}
              </p>
              <p>
                <strong>Article 15 : Langue </strong>
              </p>
              <p>
                La langue officielle de ces Conditions est le français, qui
                prévaudra sur toute autre traduction de ce document. {" "}
              </p>
              {/* <h2>{t(GENERAL_TERMS_OF_USE_HEAD)}</h2>
                        <p>{t(GENERAL_TERMS_OF_USE_INTRO)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_1_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_1)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_2_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_2)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_3_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_3)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_4_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_4)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_5_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_5)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_6_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_6)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_7_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_7)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_8_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_8)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_9_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_9)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_10_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_10)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_11_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_11)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_12_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_12)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_13_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_13)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_14_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_14)}</p>
                        <h3>{t(GENERAL_TERMS_OF_USE_<strong>Article_15_HEAD)}</h3>
                        <p>{t(GENERAL_TERMS_OF_USE_<strong>Article_15)}</p> */}
            </div>
          ) : (
            <div id="terms-of-use-text-container">
              <h5>
                PAM Data Import plugin <br></br> General Terms of Use{" "}
              </h5>
              <p>Between</p>
              <p>
                Saint-Gobain PAM Bâtiment, a French “Société par Actions
                Simplifiée” with share capital of 6 812 970.75 euros, having its
                head office at 21 avenue Camille Cavallier 54700 PONT-A-MOUSSON,
                registered under 799 283 882 at Registre du Commerce et des
                Sociétés de Nancy, hereinafter the « Licensor »,
              </p>
              <p>and</p>
              <p>
                any person downloading PAM Data Import plugin, hereinafter the
                « Licensee ».{" "}
              </p>
              <p>IMPORTANT - READ CAREFULLY </p>
              <p>
                As a preliminary, the Licensee had to accept the conditions of
                use of BIM Objects when creating his account at the Saint-Gobain
                BIM library platform.{" "}
              </p>
              <p>
                By downloading or using PAM Data Import Plugin (hereinafter "PAM
                Plugin") you agree to abide by the terms of these general
                conditions of use.{" "}
              </p>
              <p>
                YOU AGREE THAT THIS AGREEMENT IS APPLICABLE IN THE SAME WAY THAT
                ANY NEGOTIATED AGREEMENT SIGNED BY YOU.{" "}
              </p>
              <p>
                If you do not agree with these terms you should not use PAM
                Plugin.{" "}
              </p>
              <p>
                <strong>Article 1: Definitions </strong>
              </p>
              <p>
                In this agreement the term "<strong>PAM Plugin</strong>" means
                PAM Data Import plugin as it is available in its current version
                within Saint-Gobain plugin store.{" "}
              </p>
              <p>
                The term “<strong>BIM Object(s)</strong>” means BIM model(s)
                available within Saint-Gobain BIM Library platform.{" "}
              </p>
              <p>
                The term "<strong>Use</strong>" means downloading PAM plugin,
                installation or use which is made of it in order to allow the
                Licensee to enrich with PAM product data a BIM model of a
                Project, hereinafter the “BIM Model”.{" "}
              </p>
              <p>
                The term "<strong>Project</strong>" designates any new
                construction / refurbishment or development project for which
                the Licensee has been mandated or consulted.{" "}
              </p>
              <p>
                <strong>Article 2: Purpose of the agreement </strong>{" "}
              </p>
              <p>
                This agreement is intended to define the principles and
                conditions under which the Licensor to Licensee assigns a right
                of personal use, non-exclusive, non-transferable subject of PAM
                plugin.{" "}
              </p>
              <p>
                <strong>Article 3: Rights and Limitations  </strong>
              </p>
              <p>Licensee is authorized to: </p>
              <p>
                - Use the PAM plugin on one (1) or more computers only as part
                of the Project.{" "}
              </p>
              <p>Licensee is not authorized to: </p>
              <p>
                - Use the PAM plugin, except as authorized by this license.{" "}
              </p>
              <p>
                - Make any reverse engineering, disassemble, decompile the PAM
                plugin to create any derivative BIM Object or plugin.{" "}
              </p>
              <p>
                In the event of a Use for other purposes than the Project
                implementation, the Licensor reserves the right to take legal
                action against the Licensee before the competent courts on any
                useful basis.{" "}
              </p>
              <p>
                <strong>Article 4: PAM plugin update </strong>
              </p>
              <p>
                Licensor may create new versions of PAM plugin and may, at its
                own discretion, make them available to the Licensee. Any new
                version of the BIM Object replaces the previous one and remains
                subject to the principles and conditions of this license
                agreement.{" "}
              </p>
              <p>
                It is the Licensee's responsibility to verify that he has the
                PAM plugin in its latest version.{" "}
              </p>
              <p>
                <strong>Article 5: General Conditions of Use validity </strong>
              </p>
              <p>
                Unless terminated in accordance with Article 11, this agreement
                is concluded for the duration of authorized use of the BIM
                Objects, as indicated in the BIM Objects conditions of use.{" "}
              </p>
              <p>
                <strong>Article 6: Property </strong>
              </p>
              <p>
                The PAM plugin is the exclusive property of the Licensor. It is
                protected by copyright and by international conventions.{" "}
              </p>
              <p>
                It is reminded that any copyright infringement is punishable in
                France by Article L335-2 of the French Code of intellectual
                property and punishable by three years imprisonment and a
                300,000 euro fine.{" "}
              </p>
              <p>
                Any such infringement that would be attributable to a third
                party and of which the Licensee has knowledge should be
                denounced by the Licensee to the Licensor who shall personally
                decide whether to prosecute.{" "}
              </p>
              <p>
                Any infringement allegation made against the Licensee due to the
                use of the PAM plugin must be notified to the Licensor who shall
                assist in the defense of the Licensee under the conditions and
                forms that the Licensor sees fit and sets alone.{" "}
              </p>
              <p>
                <strong>Article 7: Warranty </strong>
              </p>
              <p>
                The PAM Plugin is integrated into a complex and constantly
                evolving IT tool. In the absence of control of future
                developments, no guarantee can be given.{" "}
              </p>
              <p>
                As far as the applicable law allows, this PAM plugin is provided
                to Licensee as is without any guarantees or assurances of any
                kind, written or oral, express or implied. In particular, the
                Licensor does not guarantee that the PAM plugin will be
                compatible with the equipment of the Licensee and software
                configuration, nor that it will work in good conditions on the
                hardware and software configuration of the Licensee.{" "}
              </p>
              <p>
                <strong>Article 8: Liability </strong>
              </p>
              <p>
                To the fullest extent permitted by applicable law, Licensor
                shall not be held liable for damages of any nature whatsoever
                suffered by the Licensee or third parties arising directly or
                indirectly from use, including loss or deterioration data, or
                any commercial or financial loss resulting from the use or
                inability to use, and this even if the Licensor has been advised
                beforehand of the possibility of such damages.{" "}
              </p>
              <p>
                It is incumbent upon interested persons to ascertain whether the
                PAM plugin is suitable for its intended purpose and meets the
                regulatory requirements at national, local or regional.{" "}
              </p>
              <p>
                The Licensor shall not in any way and under any circumstances be
                liable to compensate for any direct or consequential damages
                linked to the use of PAM plugin.{" "}
              </p>
              <p>
                The modelized rendering of the BIM Model and its functions
                (hereinafter the "Modeling") is an interactive modeling of an
                existing object.{" "}
              </p>
              <p>
                Under no circumstances shall the Licensor be liable to the
                Licensee for the use made of modeling nor in the event of any
                dissimilarity with the modeled object.{" "}
              </p>
              <p>
                The use of the Modeling confers no right to the Licensee on the
                data added to the Modeling thanks to the PAM plugin, this
                includes brands, data and images as well as any other content
                owned by the Licensor.{" "}
              </p>
              <p>
                <strong>Article 9: Confidentiality – Personal Data </strong>
              </p>
              <p>
                The Licensee acknowledges that the technical information
                relating to the PAM plugin is of a confidential nature and
                therefore undertakes to disclose it only to contractors and
                partners involved in the Project, in addition to its own
                employees.{" "}
              </p>
              <p>
                As such, the Licensee undertakes to ensure that the
                confidentiality referred to in the previous paragraph is
                respected by its employees and by the contractors and partners
                involved in the project.{" "}
              </p>
              <p>
                The Licensor's obligation of confidentiality shall remain in
                force throughout the duration of these Conditions as referred to
                in Article 5.{" "}
              </p>
              <p>
                The collection of personal data communicated by the Licensee
                upon acceptance of these Conditions allows the Licensor to
                notify the Licensee of PAM Plugin updates and to provide him
                with any information, notably commercial, relating to PAM
                products and services. Personal data is accessible by / intended
                for the following services: IT, marketing, technical assistance,
                communication, prescription and commercial services. The
                Licensee may object to this use of his data for commercial
                purposes at any time by sending an e-mail to:
                privacycontact.sgpam.fr@saint-gobain.com. These personal data
                are kept three (3) years and from their collection or the last
                contact from the Licensee. In accordance with the applicable
                regulations, the Licensee has a right of access, rectification
                and erasure and the right to limit the processing concerning him
                which he can exercise by sending an e-mail to:
                privacycontact.sgpam.fr@saint-gobain.com{" "}
              </p>
              <p>
                <strong>Article 10: Severability </strong>
              </p>
              <p>
                If any of the provisions of the agreement is held invalid or
                irrelevant, it shall be deemed unwritten and will not involve
                the nullity of the other provisions.{" "}
              </p>
              <p>
                <strong>Article 11: Non-compliance with the Agreement </strong>
              </p>
              <p>
                The License will no longer be authorized to use the PAM Plugin
                if it appears that he does not comply with these Conditions or
                those related to his account in the Saint-Gobain BIM object
                library, despite a formal notice by email sent by the Licensor.
                Following a new notification from the Licensor by email, the
                Concessionaire must uninstall the PAM Plugin as soon as
                possible, and at the latest within seven (7) days following this
                notification. The Licensor reserves the right to verify that the
                License has taken the necessary steps and, failing this, may
                implement any useful action to compel him to do so.{" "}
              </p>
              <p>
                <strong>Article 12: Non-transferable agreement </strong>
              </p>
              <p>
                This License Agreement is concluded intuitu personae. It is not
                transferable and may not be sublicensed.{" "}
              </p>
              <p>
                <strong>Article 13: Applicable Law </strong>
              </p>
              <p>
                This License Agreement shall be governed and construed in
                accordance with French law.{" "}
              </p>
              <p>
                <strong>Article 14: Dispute resolution </strong>
              </p>
              <p>
                In case of dispute arising from the execution or interpretation
                of this agreement, the parties undertake to seek an amicable
                solution. Failing this, the dispute will be the exclusive
                jurisdiction of French courts.{" "}
              </p>
              <p>
                <strong>Article 15: Language </strong>
              </p>
              <p>
                The official language of the Agreement is French, which shall
                prevail over any other language used in any translated document{" "}
              </p>
            </div>
          )}
          <div className="row py-2">
            <div className="offset-6 col-6 d-flex justify-content-end">
              <AnimatedCheckbox
                label={
                  t(ACCEPT_GENERAL_TERMS_OF_USE_TEXT) ||
                  "Accept general terms of use"
                }
                textColor={"#f67252"}
                checked={acceptedTermsOfUse}
                changed={changedTermsOfUse}
              ></AnimatedCheckbox>
            </div>
            {/* <Checkbox
<p>defaultChecked
<p>color="primary"
<p>inputProps={{ "aria-label": "secondary checkbox" }}
                        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

type Props = WithTranslation & {
  acceptedTermsOfUse: boolean;
  changedTermsOfUse: Function;
};

export default withTranslation(["pam"])(Body);
