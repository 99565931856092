export enum APP_WINDOW_NAME {
  BOILERPLATE_WINDOW = "BOILERPLATE_WINDOW",
}

export const BOILERPLATE_HEADER_TEXT = "Boilerplate header text";
export const SYSTEM_PROPERTIES = "SYSTEM_PROPERTIES";

export const CLOISONS = "Cloisons";
export const PLAFONDS = "Plafonds";
export const DOUBLAGES = "Doublages";

export const CLOISONS_ID = "wall";
export const PLAFONDS_ID = "ceiling";
export const DOUBLAGES_ID = "liningwall";

export const systemTypes = [
  {
    label: CLOISONS,
    value: CLOISONS_ID
  },
  {
    label: PLAFONDS,
    value: PLAFONDS_ID
  },
  {
    label: DOUBLAGES,
    value: DOUBLAGES_ID
  },
];
