import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import "./couplingSelectionModal.scss";

function CouplingSelectionModal({
    modal,
    setModal,
    toggleMatchElementCoupling,
    t,
}: IProps) {
    return (
        <Modal
            size={"mini"}
            id="coupling-selection-modal"
            open={modal.open}
            onClose={() =>
                setModal({
                    ...modal,
                    open: false,
                    target: null,
                    item: null,
                    itemIndex: -1,
                    elementIndex: -1,
                    couplingIndex: -1,
                })
            }
        >
            <Modal.Content>
                <p>{t("CONFIRM_CHANGE_COUPLING_SELECTION_TEXT")}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    style={{ backgroundColor: "darkgray" }}
                    onClick={() =>
                        setModal({
                            ...modal,
                            open: false,
                            target: null,
                            item: null,
                            itemIndex: -1,
                            elementIndex: -1,
                            couplingIndex: -1,
                        })
                    }
                >
                    {t("NO")}
                </Button>
                <Button
                    positive
                    style={{ backgroundColor: "#f67252" }}
                    onClick={() => {
                        toggleMatchElementCoupling(
                            modal.item,
                            modal.elementIndex,
                            modal.couplingIndex,
                            modal.itemIndex,
                            !modal.target?.classList.contains(
                                "selected-coupling"
                            )
                        );
                        return setModal({
                            ...modal,
                            open: false,
                            target: null,
                            item: null,
                            itemIndex: -1,
                            elementIndex: -1,
                            couplingIndex: -1,
                        });
                    }}
                >
                    {t("YES")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

type IProps = WithTranslation & {
    modal: any;
    setModal: Function;
    toggleMatchElementCoupling: Function;
};

export default withTranslation(["pam"])(CouplingSelectionModal);
