import React, { useEffect, useRef, useState } from "react";
import { Button, Icon, Table, Modal, Loader } from "semantic-ui-react";
import { errorCodeKey } from "../../../../../ErrorManagement/utils/errorCodeEnum";
import { api } from "../../../../../RevitJS/API";
import { wait } from "../../../../../RevitJS/Helpers";

export const CctpUploader = ({
  dossierData,
  resumePdfGenerator,
  closeUploader,
  config,
  wording,
  language,
  onError,
}: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  let mounted = useRef(true);

  useEffect(() => {
    const ccttps = [
      ...dossierData.data.list.filter((lf: any) => lf.isSelected === true),
    ];

    let filesGroup: any = [];

    ccttps.forEach((element: any) => {
      element.files.forEach((flt: any) => {
        const typeUnchecked = dossierData.downloadDAE.find((document: any) => {
          return (
            (document.key === flt.fileType && document.isSelected) ||
            flt.fileType === "Fiche système" ||
            flt.fileType === "FP"
          );
        });

        if (typeUnchecked) {
          filesGroup = filesGroup.concat([
            {
              ...flt,
              srno: element.srno,
              RevitSystems: element.RevitSystems,
              CCTPReference: element.CCTPReference,
              status: "pending",
            },
          ]);
        }
      });
    });

    setFiles(filesGroup);
    if (filesGroup.length > 0) {
      setOpen(true);
    } else {
      finalizeDossierData();
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (open) {
      processUploader();
    }
  }, [open]);

  const processUploader = (() => {
    let isReady = true;
    return async () => {
      const pendingUploader = files.filter(
        (fl: any) => fl.status === "pending"
      );
      if (isReady && pendingUploader.length > 0 && mounted.current) {
        const toUpload = pendingUploader[0];
        isReady = false;

        const tkn = localStorage.getItem("token");
        if (tkn) {
          updateFiles(toUpload, "running", "");

          const photobox = await api.queries.UploadPhotoboxFile(
            toUpload.fileData,
            tkn,
            config.REACT_APP_PHOTOBOXAPIURL_EXTERNAL + "/uploadFile?fileName="
          );

          await wait(5000);
          mounted.current && updateFiles(toUpload, "success", photobox);
        }
        isReady = true;
        processUploader();
      }
    };
  })();

  const updateFiles = (fl: any, status: string, fileid: string) => {
    let cloneFiles = [...files];
    cloneFiles = cloneFiles.map((cl: any, index: number) => {
      if (
        cl.srno === fl.srno &&
        cl.index === fl.index &&
        cl.fileType === fl.fileType
      ) {
        cl.status = fileid === "error" ? "error" : status;
        if (fileid !== "error") {
          cl.fileid = fileid;
        }
      }
      return cl;
    });
    setFiles(cloneFiles);
  };

  const finalizeDossierData = () => {
    try {
      let colneDossier = JSON.parse(JSON.stringify(dossierData));
      const dataList = colneDossier.data.list.map((element: any) => {
        const fil = files.filter((cl: any) => {
          if (
            cl.srno === element.srno &&
            cl.RevitSystems === element.RevitSystems &&
            cl.CCTPReference === element.CCTPReference
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (fil.length > 0) {
          element.files = fil;
        }
        return element;
      });

      colneDossier.data.list = dataList;
      resumePdfGenerator(colneDossier).catch((error: any) => {
        console.log("err:::", error);
        //onError(errorCodeKey.PB_DT_H_012, error.stack, true);
        onError(error.code, error.stack, true);
      });
      setOpen(false);
    } catch (error) {
      throw error;
    }
  };

  const retryUploding = (fl: any) => {
    let cloneFiles = [...files];
    cloneFiles = cloneFiles.map((cl: any, index: number) => {
      if (
        cl.srno === fl.srno &&
        cl.index === fl.index &&
        cl.fileType === fl.fileType
      ) {
        cl.status = "pending";
      }
      return cl;
    });
    setFiles(cloneFiles);

    processUploader();
  };

  const cancelUploadProcess = async () => {
    const cancelResult = await api.queries.CancelUploadPhotoboxFile();
    if (!cancelResult) {
      closeUploader();
      setOpen(false);
    }
  };
  const contDisable = files.find((fl: any) => fl.status !== "success");
  return (
    <Modal open={open} size="small">
      <Modal.Header className="modalHeader">
        {wording.cctpfileupload[language]}
      </Modal.Header>
      <Modal.Actions>
        <div style={{ height: "calc(100vh - 180px)", overflowY: "auto" }}>
          <Table celled className="cctp-uploder-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="width25">
                  {wording.revitsystems[language]}
                </Table.HeaderCell>
                <Table.HeaderCell className="width15">
                  {wording.referencecctp[language]}
                </Table.HeaderCell>
                <Table.HeaderCell className="width15">
                  {wording.fileformat[language]}
                </Table.HeaderCell>
                <Table.HeaderCell className="width30">
                  {wording.fileName[language]}
                </Table.HeaderCell>
                <Table.HeaderCell className="width15">
                  {wording.status[language]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {files.map((fl: any, index: number) => {
                return (
                  <Table.Row
                    disabled={fl.status === "pending"}
                    error={fl.status === "error"}
                    positive={fl.status === "success"}
                    key={`${fl.RevitSystems}_${index}`}
                  >
                    <Table.Cell>{fl.RevitSystems}</Table.Cell>
                    <Table.Cell>{fl.CCTPReference}</Table.Cell>
                    <Table.Cell>{fl.fileType}</Table.Cell>
                    <Table.Cell>{fl.screenName}</Table.Cell>
                    <Table.Cell>
                      {fl.status === "error" && (
                        <span
                          onClick={() => retryUploding(fl)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon name="attention" />
                          Retry
                        </span>
                      )}
                      {fl.status === "success" && (
                        <span>
                          <Icon name="checkmark" />
                          Téléchargé
                        </span>
                      )}
                      {fl.status === "pending" && <span>Pending</span>}
                      {fl.status === "running" && (
                        <Loader active inline size="tiny" />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <div className="modalButton">
          <Button
            color="orange"
            size="tiny"
            onClick={() => {
              cancelUploadProcess();
              //setOpen(false);
            }}
          >
            {wording.cancel[language]}
          </Button>
          <Button
            disabled={contDisable ? true : false}
            type="submit"
            size="tiny"
            primary
            onClick={() => {
              finalizeDossierData();
            }}
          >
            {wording.continue[language]}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
