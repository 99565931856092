import { ceil, forEach, map, uniq, find, cloneDeep } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Grid,
  Loader,
  Modal,
  Pagination,
  Table,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import { writeOptimizedBooksForQuantitatifs } from "../../Extract/extractOpt";
import { Routes, ElementType, QuantiType } from "../../Util/type";
import { MetresStore } from "../../Reducers";
import {
  collectAccessoryWithCustomRatio,
  collectEtageData,
  collectZoneAccessoryWithCustomRatio,
  collectZoneData,
} from "../../Extract/extractHelper";
import { woolType } from "../../Extract/type";
import { Accessory, Montant, Plaqs, Rails } from "../../Actions/types";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { writeOptimizedZoneBooksForQuantitatifs } from "../../Extract/extractOptZone";
import { wait } from "../../../../../RevitJS/Helpers";
import { useTranslation } from "react-i18next";
import "../../Resources/main.scss";
import { useForm } from "react-hook-form";
import { RatioTableRow } from "./RatioTableRow";
import { fetchPlacoDataInGroupSplit } from "../../../Calpinage/Helpers/placoHelper";
import { fetchPlacoSolutionsByTechnicalNames } from "../../../../../API";
import { api } from "../../../../../RevitJS/API";
import { getProcessedElements } from "../../Util/processedElement";
import {
  QuantityContext,
  IQuantityContextType,
} from "../../Context/quantityContext";
import {
  collectCeilingAccessoryWithCustomRatio,
  collectCeilingZoneAccessoryWithCustomRatio,
  collectEtageCeilingData,
  collectZoneCeilingData,
} from "../../Extract/extractCeilingHelper";
import { writeOptimizedCeilingBooksForQuantitatifs } from "../../Extract/extractOptCeiling";
import { writeOptimizedZoneCeilingBooksForQuantitatifs } from "../../Extract/extractOptZoneCeiling";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../../ErrorManagement/components/ErrorBoundry";
import { errorCodeKey } from "../../../../../ErrorManagement/utils/errorCodeEnum";
import { QuantitatiffRevitApi } from "../../../../../ErrorManagement/utils/revitApiError";

export interface IRationTable {
  typeArticle: string;
  nameArticle: string;
  ratioDefault: number;
  rationCustom: number;
  order: number;
  placoSolution: string;
  unit: string;
  articleList: any;
  solutionId?: number;
}

export interface IMapzoneWall {
  zone: any;
  walls: any;
}

export interface IRail {
  [Key: string]: Rails;
}

export interface IPlaque {
  [Key: string]: Plaqs;
}

export interface ICollectedArticle {
  collected_wool: woolType[];
  collected_accessory: Accessory[];
  collected_rail: Rails[];
  collected_montant: Montant[];
  collected_plaques: Plaqs[];
  default_ratio: IRationTable[];
  finalExtract: any;
  userRatio: { [key: string]: string };
  mapZoneWall: IMapzoneWall[] | null;
  processedWallParams: any;
  ratioListArray?: any;
}

const RatioTable = (props: any) => {
  const reduxState: MetresStore = useSelector((state: MetresStore) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInput, setIsInput] = useState<boolean>(false);
  const [raiseWarning, setRaiseWarning] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [extractData, setExtractData] = useState<
    ICollectedArticle | undefined
  >();
  const { t, i18n } = useTranslation(["library"]);
  const [activePage, setActivePage] = useState<number>(1);
  const [unitConversionArray, setUnitConversionArray] = useState<any>([]);
  const { quntiType, setRoot, element } = useContext(
    QuantityContext
  ) as IQuantityContextType;
  const [ratioPrompt, setRatioPrompt] = useState<boolean>(false);
  const [ratioMessage, setRatioMessage] = useState<string[]>();

  const handleCustomRatioCheckClick = (e: any, data: any) => {
    setIsInput(data.checked);
  };
  const { register, handleSubmit } = useForm();

  const setUnitArray = (conversionArray: any, element: any) => {
    if (conversionArray.length === 0) {
      setUnitConversionArray([]);
    } else {
      setUnitConversionArray((prevUnitConversion: any) => {
        map(conversionArray, (unitObj: any, unitObjIndex: any) => {
          if (
            !find(prevUnitConversion, {
              alternativeUnit: unitObj.alternativeUnit,
              ub: unitObj.ub,
              element: element,
            })
          ) {
            prevUnitConversion = [
              ...prevUnitConversion,
              { ...unitObj, element: element },
            ];
          }
        });
        return prevUnitConversion;
      });
    }
  };

  // useEffect - async
  useEffect(() => {
    const collectExtractData = async (
      processedWallParams: any,
      extractedSolutionProduct: any,
      elementsData: any,
      setUnitArray: any,
      mapZoneElement: any
    ) => {
      try {
        if (quntiType === QuantiType.ZONE) {
          if (element === ElementType.WALL) {
            return await collectZoneData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              mapZoneElement,
              unitConversionArray
            );
          } else {
            return await collectZoneCeilingData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              mapZoneElement,
              unitConversionArray
            );
          }
        } else {
          if (element === ElementType.WALL) {
            return await collectEtageData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              unitConversionArray
            );
          } else {
            return await collectEtageCeilingData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              unitConversionArray
            );
          }
        }
      } catch (error) {
        console.log(error);

        return Promise.reject(error);
      }
    };
    const handleProcessedWall = async () => {
      try {
        setLoading(true);

        const { processedElementIds, mapZoneElement } =
          await getProcessedElements(
            reduxState.selections,
            quntiType,
            reduxState.projectData.ProjectId,
            element
          );

        if (processedElementIds.length > 0) {
          let processedWallParams = await api.queries.getObjectsParams(
            processedElementIds,
            ["SG_System", "Plaque", "Montant"]
          );
          /// 11. Take wall ids from processed walls params
          QuantitatiffRevitApi(props);
          /// 12. Get walls data of processed wall ids
          let elementsData: any[] = [];
          elementsData =
            element === ElementType.WALL
              ? await api.queries.getWallsData(processedElementIds)
              : await api.queries.getCeilingsData(processedElementIds);

          QuantitatiffRevitApi(props);
          if (elementsData.length === 0) {
            throw "Element details not available from Revit";
          }

          let processedSolution = uniq(
            map(processedWallParams, "Params[0].Value")
          );
          /// 16. Fetch placo solutions from api database
          const data = await fetchPlacoSolutionsByTechnicalNames(
            processedSolution,
            reduxState.config
          )
            .then(({ data }) => {
              return data;
            })
            .catch((error) => {
              return Promise.reject({
                code: errorCodeKey.PB_QU_H_009,
                stack: error.stack,
              });
            });

          /// 17. Extract solution ids from fetch data
          const solutionOids: string[] = map(data.objects, "oid");

          /// 18. Fetch solution details with child and parent
          ///     Extract products, solution products and articles
          const extractedSolutionProduct = await fetchPlacoDataInGroupSplit(
            solutionOids,
            reduxState.config
          )
            .then(({ extractedSolutionProduct }) => {
              return extractedSolutionProduct;
            })
            .catch((error) => {
              return Promise.reject({
                code: errorCodeKey.PB_QU_H_009,
                stack: error.stack,
              });
            });
          if (extractedSolutionProduct.length > 0) {
            console.log(unitConversionArray);

            const collectedExtractData = await collectExtractData(
              processedWallParams,
              extractedSolutionProduct,
              elementsData,
              setUnitArray,
              mapZoneElement
            );

            if (collectedExtractData) {
              setExtractData(collectedExtractData);
            } else {
              setWarning(
                t(
                  "Les quantitatifs par étage et par zone ne peuvent pas être générés car aucun système calepiné n'appartient à une zone créée dans le module Sélection"
                )
              );
            }
          } else {
            setWarning(
              t(
                "Les quantitatifs par étage et par zone ne peuvent pas être générés car aucun système calepiné n'appartient à une zone créée dans le module Sélection"
              )
            );
          }
        }
      } catch (error: any) {
        props.onError(error.code, error.stack, true);
      } finally {
        setLoading(false);
      }
    };
    handleProcessedWall();
  }, [quntiType]);

  useEffect(() => {
    let containerToBeScrolled: any = document.querySelector(".height470");
    containerToBeScrolled.scrollTop = 0;
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, [activePage]);

  const getUnitConversionArray = useCallback(() => {
    return unitConversionArray;
  }, [unitConversionArray]);

  const setWarning = (message: string) => {
    setRaiseWarning(true);
    setWarningMessage(message);
  };

  if (raiseWarning) {
    return (
      <Modal
        size="tiny"
        open={raiseWarning}
        closeIcon
        onClose={() => {
          setRaiseWarning(false);
          setRoot(Routes.ROOT);
        }}
      >
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>{warningMessage}</p>
            <Button
              color="blue"
              onClick={() => {
                setRaiseWarning(false);
                setRoot(Routes.ROOT);
              }}
            >
              Fermer
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

  if (loading) {
    return (
      <>
        <Container
          className="grid-container"
          style={{ width: "90%", border: "0 none", padding: "0" }}
        >
          <Dimmer
            active
            style={{
              height: "calc(100vh - 30px)",
            }}
          >
            <Loader />
          </Dimmer>
        </Container>
      </>
    );
  }

  const totalPagesNumber = extractData?.ratioListArray
    ? ceil(extractData?.ratioListArray.length / 10)
    : 1;

  const onSubmit = async (data: any) => {
    if (extractData) {
      setLoading(true);
      await wait(1000);

      let ratioArray: any = [];

      map(extractData.ratioListArray, (ratArray, index) => {
        if (ratArray.articleList) {
          map(ratArray.articleList, (artLi, artIndex) => {
            if (artLi.selected) {
              ratioArray.push({
                typeArticle: ratArray.typeArticle,
                nameArticle: artLi.articleName,
                ratioDefault: artLi.ratio,
                rationCustom: artLi.rationCustom,
                order: ratArray.order,
                placoSolution: ratArray.placoSolution,
                unit: ratArray.unit,
                articleList: null,
                solutionId: ratArray.solutionId,
              });
            }
          });
        } else {
          ratioArray.push({
            typeArticle: ratArray.typeArticle,
            nameArticle: ratArray.nameArticle,
            ratioDefault: ratArray.ratioDefault,
            rationCustom: ratArray.rationCustom,
            order: ratArray.order,
            placoSolution: ratArray.placoSolution,
            unit: ratArray.unit,
            articleList: null,
            solutionId: ratArray.solutionId,
          });
        }
      });

      let moExtractState: any = {};
      moExtractState = extractData;
      moExtractState["default_ratio"] = JSON.parse(JSON.stringify(ratioArray));
      moExtractState["userRatio"] = JSON.parse(
        JSON.stringify(extractData["userRatio"])
      );

      if (Object.keys(moExtractState).length > 0) {
        forEach(data, (dtValue, dtKey) => {
          let articleName = "";
          const modifiedDefaultRation = map(
            extractData.default_ratio,
            (extrRatio: IRationTable) => {
              if (extrRatio.nameArticle) {
                const rationValue =
                  extrRatio.solutionId +
                  extrRatio.nameArticle
                    .split(" ")
                    .join("")
                    .replace(/[^a-zA-Z0-9]/g, "");
                if (rationValue === dtKey) {
                  extrRatio.rationCustom = dtValue;
                  articleName = extrRatio.solutionId + extrRatio.nameArticle;
                }
              }
              return extrRatio;
            }
          );

          moExtractState.default_ratio = modifiedDefaultRation;
          moExtractState.userRatio = {
            ...moExtractState.userRatio,
            [articleName]: dtValue,
          };
        });

        await bimStorage.setItem(
          storageKey.QUANTIFY,
          JSON.stringify(moExtractState.userRatio)
        );

        let emptyRatio: string[] = [];

        forEach(moExtractState.default_ratio, (dfRatio: any, inkey: number) => {
          if (isInput) {
            if (
              dfRatio.rationCustom === 0 ||
              dfRatio.rationCustom === "0" ||
              dfRatio.rationCustom === "" ||
              dfRatio.rationCustom === "999" ||
              dfRatio.rationCustom === "9999"
            ) {
              emptyRatio.push(dfRatio.nameArticle);
            }
          } else if (dfRatio.ratioDefault === 0) {
            emptyRatio.push(dfRatio.nameArticle);
          }
        });

        if (emptyRatio.length > 0) {
          setRatioMessage(emptyRatio);
          setRatioPrompt(true);
        } else {
          if (isInput) {
            if (quntiType === QuantiType.ZONE) {
              const { mapZoneElement } = await getProcessedElements(
                reduxState.selections,
                quntiType,
                reduxState.projectData.ProjectId,
                element
              );
              if (element === ElementType.WALL) {
                const updateZoneAccessoryByRatio =
                  await collectZoneAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitArray,
                    },
                    mapZoneElement
                  );

                if (updateZoneAccessoryByRatio) {
                  moExtractState.collected_accessory =
                    updateZoneAccessoryByRatio;
                }
              } else {
                const updateZoneAccessoryByRatio =
                  await collectCeilingZoneAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitArray,
                    },
                    mapZoneElement
                  );

                if (updateZoneAccessoryByRatio) {
                  moExtractState.collected_accessory =
                    updateZoneAccessoryByRatio;
                }
              }
            } else {
              if (element === ElementType.WALL) {
                const updateAccessoryByRatio =
                  await collectAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitArray,
                    }
                  );
                if (updateAccessoryByRatio) {
                  moExtractState.collected_accessory = updateAccessoryByRatio;
                }
              } else {
                const updateAccessoryByRatio =
                  await collectCeilingAccessoryWithCustomRatio(
                    moExtractState.finalExtract,
                    moExtractState.default_ratio,
                    {
                      setUnitConversionArray: setUnitArray,
                    }
                  );
                if (updateAccessoryByRatio) {
                  moExtractState.collected_accessory = updateAccessoryByRatio;
                }
              }
            }
          }

          if (quntiType === QuantiType.ZONE) {
            if (element === ElementType.CEILING) {
              setLoading(true);
              await writeOptimizedZoneCeilingBooksForQuantitatifs(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitArray,
                },
                getUnitConversionArray,
                reduxState.config,
                moExtractState,
                isInput
              );
              setLoading(false);
            } else {
              await writeOptimizedZoneBooksForQuantitatifs(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitArray,
                },
                getUnitConversionArray,
                reduxState.config,
                moExtractState,
                isInput
              );
              setLoading(false);
            }
          } else {
            if (element === ElementType.CEILING) {
              setLoading(true);
              await writeOptimizedCeilingBooksForQuantitatifs(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitArray,
                },
                getUnitConversionArray,
                reduxState.config,
                moExtractState,
                isInput
              );
              setLoading(false);
            } else {
              await writeOptimizedBooksForQuantitatifs(
                {
                  setWallScheduleLoader: () => {
                    setLoading(true);
                  },
                  setUnitConversionArray: setUnitArray,
                },
                getUnitConversionArray,
                reduxState.config,
                moExtractState,
                isInput
              );
              setLoading(false);
            }
          }
        }
      }
      setLoading(false);
    }
  };

  const onChangeArticle = (
    solutionId: any,
    articleId: any,
    articleName: any,
    typeArticle: any
  ) => {
    if (solutionId && articleId && articleName) {
      if (extractData) {
        let cloneExtractData = JSON.parse(JSON.stringify(extractData));
        let rationDeepListArray = cloneDeep(cloneExtractData.ratioListArray);
        rationDeepListArray = map(rationDeepListArray, (rat: any) => {
          if (rat.articleId === articleId && rat.solutionId === solutionId) {
            map(rat.articleList, (artList) => {
              if (artList.articleName === articleName) {
                rat.defaultValue = artList.articleName;
                rat.ratioDefault = artList.ratio;
                rat.rationCustom = artList.rationCustom;
                artList.selected = true;
              } else {
                artList.selected = false;
              }
              return artList;
            });
            return rat;
          }
          return rat;
        });

        if (typeArticle === "Produits isolants") {
          let woolListArray = cloneDeep(cloneExtractData.collected_wool);
          woolListArray = map(woolListArray, (rat: any) => {
            if (rat.solutionId === solutionId) {
              map(rat.articleLists, (artList) => {
                if (artList.articleName === articleName) {
                  artList.selected = true;
                } else {
                  artList.selected = false;
                }
                return artList;
              });
              return rat;
            }
            return rat;
          });
          cloneExtractData.collected_wool = woolListArray;
        } else if (typeArticle === "Produits plâtre") {
          let plaqueListArray = cloneDeep(cloneExtractData.collected_plaques);
          plaqueListArray = map(plaqueListArray, (rat: any) => {
            if (rat.articleId === articleId && rat.solutionId === solutionId) {
              map(rat.articleLists, (artList) => {
                if (artList.articleName === articleName) {
                  artList.selected = true;
                } else {
                  artList.selected = false;
                }
                return artList;
              });
              return rat;
            }
            return rat;
          });
          cloneExtractData.collected_plaques = plaqueListArray;
        } else if (typeArticle === "Ossatures Vertical") {
          let montantListArray = cloneDeep(cloneExtractData.collected_montant);
          montantListArray = map(montantListArray, (rat: any) => {
            if (rat.articleId === articleId && rat.solutionId === solutionId) {
              map(rat.articleLists, (artList) => {
                if (artList.articleName === articleName) {
                  artList.selected = true;
                } else {
                  artList.selected = false;
                }
                return artList;
              });
              return rat;
            }
            return rat;
          });
          cloneExtractData.collected_montant = montantListArray;
        }
        cloneExtractData.ratioListArray = rationDeepListArray;
        setExtractData(cloneExtractData);
      }
    }
  };

  return (
    <>
      <Container
        className="header-alignment subHeader"
        style={{ marginTop: "1rem", width: "90%" }}
      >
        {`2/2 Ratio personnalisés`}
      </Container>
      <Container
        className="grid-container"
        style={{ width: "90%", border: "0 none", padding: "0" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} className="height470">
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="width15">
                        Type article
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width30">
                        Ouvrage
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width20">
                        Nom de l’article
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width15">
                        Ratio par défaut{" "}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width20">
                        <Checkbox
                          label="Ratio personnalisé"
                          checked={isInput}
                          onChange={handleCustomRatioCheckClick}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width15">
                        Unité/m²{" "}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <RatioTableRow
                    default_ratio={extractData?.default_ratio}
                    isInput={isInput}
                    register={register}
                    activePage={activePage}
                    ratioListArray={extractData?.ratioListArray}
                    onChangeArticle={onChangeArticle}
                  />
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            className="middel-full-width aligned-pagination"
            style={{ marginTop: "0 !important" }}
          >
            <Grid.Column className="rationButton" floated="left" width={5}>
              <Button color="orange" onClick={() => setRoot(Routes.ROOT)}>
                Annuler
              </Button>
              <Button
                primary
                type="submit"
                // disabled={extractData?.default_ratio === undefined}
              >
                Générer
              </Button>
            </Grid.Column>
            <Grid.Column className="ratioPagination" floated="right" width={6}>
              <Pagination
                activePage={activePage}
                onPageChange={(e, { activePage }) => {
                  setActivePage(activePage as number);
                }}
                totalPages={totalPagesNumber}
                firstItem={null}
                lastItem={null}
              />
            </Grid.Column>
          </Grid>
        </form>
      </Container>
      <Modal
        size={"tiny"}
        open={ratioPrompt}
        onClose={() => {
          setRatioPrompt(false);
          setRatioMessage([]);
        }}
      >
        <Modal.Header>Ratio Empty</Modal.Header>
        <Modal.Content>
          <>
            <p>{t("EMPTY_RATIO")}</p>
            {ratioMessage &&
              ratioMessage.map((rmess) => {
                return <p>{rmess}</p>;
              })}
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={() => {
              setRatioPrompt(false);
              setRatioMessage([]);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withErrorSubscription(RatioTable);
