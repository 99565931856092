import { Action } from 'redux'
import { ProductDetailData, } from "../../../../../RevitJS/Types/BddTypes";

export const SET_CURRENT_SYSTEM_DETAIL = "SET_CURRENT_SYSTEM_DETAIL";
export const SET_ADVANCE_SELECTOR_DETAIL = "SET_ADVANCE_SELECTOR_DETAIL";

export interface SystemDetailsState {
  system: ProductDetailData | {}
  advanceSelector: any | {}
}

interface SetCurrentSystemAction extends Action {
  type: typeof SET_CURRENT_SYSTEM_DETAIL
  system: ProductDetailData,
}

interface SetAdvanceSelectorAction extends Action {
  type: typeof SET_ADVANCE_SELECTOR_DETAIL
  advanceSelector: any,
}

export type SystemDetailsActionTypes = SetCurrentSystemAction | SetAdvanceSelectorAction

