import Excel from "exceljs";
import { defaultTo, includes, map, round } from "lodash";
import { CeilingData } from "../../../../RevitJS/Types/RevitTypes";

export const overviewByRoom = async (
  overviewByRoomSheet: Excel.Worksheet,
  ceilingDetails: CeilingData[]
) => {
  buildProjectInfoSection(overviewByRoomSheet);
  buildMainTableSection(overviewByRoomSheet, ceilingDetails);
};

export const setHeaderStyle = async (
  overviewByRoomSheet: Excel.Worksheet,
  celReferences: any,
  color = "FFE000"
) => {
  celReferences.forEach((element: any) => {
    if (element.merge) overviewByRoomSheet.mergeCells(element.merge);
    overviewByRoomSheet.getCell(element.ref).value = element.name;
    overviewByRoomSheet.getCell(element.ref).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    overviewByRoomSheet.getCell(element.ref).font = {
      name: "Arial",
      size: 10,
      bold: true,
      color: { argb: element.fontColor || "000000" },
    };
    overviewByRoomSheet.getCell(element.ref).fill = {
      type: "pattern",
      pattern: "solid",
      bgColor: { argb: "000000" },
      fgColor: { argb: color },
    };
    overviewByRoomSheet.getColumn(element.ref.charAt(0)).width = element.width;
  });
};

export const setProjectInfo = async (
  overviewByRoomSheet: Excel.Worksheet,
  celReferences: any,
  color = "FFE000"
) => {
  celReferences.forEach((element: any) => {
    if (element.merge) overviewByRoomSheet.mergeCells(element.merge);
    overviewByRoomSheet.getCell(element.ref).value = element.name;
    overviewByRoomSheet.getCell(element.ref).alignment = element.alignment;
    overviewByRoomSheet.getCell(element.ref).font = {
      name: "Arial",
      size: element.fontSize,
      bold: false,
      color: { argb: element.fontColor || "000000" },
    };
    overviewByRoomSheet.getCell(element.ref).border =
      element.cellBorder > 0
        ? {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          }
        : {};
  });
};

const buildProjectInfoSection = (overviewByRoomSheet: Excel.Worksheet) => {
  let projectInfo = [
    {
      ref: "A1",
      merge: "A1:H1",
      name: "CLT4BIM",
      fontSize: 20,
      cellBorder: 0,
      alignment: {
        vertical: "bottom",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
    {
      ref: "A2",
      merge: "A2:H2",
      name: "Qty take off",
      fontSize: 12,
      cellBorder: 0,
      alignment: {
        vertical: "top",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
    {
      ref: "A3",
      merge: "A3:B3",
      name: "Project name",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A4",
      merge: "A4:B4",
      name: "Project code",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A5",
      merge: "A5:B5",
      name: "Project total area",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A6",
      merge: "A6:B6",
      name: "Region/country",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A7",
      merge: "A7:B7",
      name: "City",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A8",
      merge: "A8:B8",
      name: "Date",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "C3",
      merge: "C3:D3",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "C4",
      merge: "C4:D4",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "C5",
      merge: "C5:D5",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "C6",
      merge: "C6:D6",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "C7",
      merge: "C7:D7",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "C8",
      merge: "C8:D8",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "E3",
      name: "Project handled by",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "E4",
      name: "ASM in charge",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "E5",
      name: "Ceiling Contractor",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "F3",
      merge: "F3:H3",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "F4",
      merge: "F4:H4",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "F5",
      merge: "F5:H5",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
  ];

  setProjectInfo(overviewByRoomSheet, projectInfo);

  overviewByRoomSheet.getRow(1).height = 40;
  overviewByRoomSheet.getRow(2).height = 25;

  for (let index = 3; index <= 11; index++) {
    overviewByRoomSheet.getRow(index).height = 20;
  }
};

const buildMainTableSection = (
  overviewByRoomSheet: Excel.Worksheet,
  ceilingDetails: any
) => {
  let headersMainBoard = [
    { ref: "A11", name: "Level", width: 10.36 },
    { ref: "B11", name: "Ceiling ID", width: 15.18 },
    { ref: "C11", name: "Room name", width: 26.91 },
    { ref: "D11", name: "System Type", width: 26.91 },
    { ref: "E11", name: "Ecophon product", width: 42 },
    { ref: "F11", name: "Area (m2)", width: 14.18 },
    { ref: "G11", name: "Room perimeter (m)", width: 20.91 },
    { ref: "H11", name: "Ceiling height (m)", width: 18.64 },
  ];
  
  let totalCeilingArea = 0;
  const overviewByRoomData = map(ceilingDetails, (ceiling, index) => {
    totalCeilingArea = totalCeilingArea + round(defaultTo(ceiling.Area, 0), 0);
    //const roomNameString = map(ceiling.Rooms, 'RoomName').toString();
    return [
      ceiling.LevelName,
      parseFloat(ceiling.Id),
      ceiling.RoomName ? ceiling.RoomName : "",
      ceiling.CeilingType,
      ceiling.ProductName,
      ceiling.Area ?.toFixed(2),
      ceiling.Perimeter ?.toFixed(2),
      ceiling.Height ?.toFixed(2),
    ];
  });

  overviewByRoomSheet.addTable({
    name: "OverviewByRoom",
    ref: "A11",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: undefined,
      showRowStripes: false,
    },
    columns: headersMainBoard,
    rows: overviewByRoomData,
  });

  const mainTableHeaders = setHeaderStyle(
    overviewByRoomSheet,
    headersMainBoard
  );

  overviewByRoomSheet.properties.defaultRowHeight = 20;

  for (let index = 12; index <= overviewByRoomData.length + 12; index++) {
    let row = overviewByRoomSheet.getRow(index);
    row.height = 20;

    row.eachCell(function (cell: any, colNumber: number) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FFFFFF",
        },
      };

      cell.border = {
        bottom: { style: "thin" },
      };

      cell.alignment = {
        horizontal: includes([2, 6, 7, 8], colNumber) ? "center" : "left",
        vertical: "middle",
        wrapText: colNumber === 5 ? false : true,
      };

      cell.font = {
        name: "Arial",
        size: 10,
        bold: false,
        color: { argb: "000000" },
      };
    });

    row.commit();
  }

  let ceilingAreaRow = overviewByRoomData.length + 15;
  totalAreaSection(overviewByRoomSheet, totalCeilingArea, ceilingAreaRow);
};

const totalAreaSection = (
  overviewByRoomSheet: Excel.Worksheet,
  totalCeilingArea: number,
  ceilingAreaRow: number
) => {
  let ceilingAreaNameColumn = `A${ceilingAreaRow}:B${ceilingAreaRow}`;
  overviewByRoomSheet.mergeCells(ceilingAreaNameColumn);
  overviewByRoomSheet.getCell(`A${ceilingAreaRow}`).value =
    "TOTAL Ecophon Ceiling area";
  overviewByRoomSheet.getCell(`A${ceilingAreaRow}`).font = {
    name: "Arial",
    size: 10,
    bold: true,
    color: { argb: "000000" },
  };
  overviewByRoomSheet.getCell(`A${ceilingAreaRow}`).border = {
    bottom: { style: "thin" },
  };

  overviewByRoomSheet.getCell(`C${ceilingAreaRow}`).border = {
    bottom: { style: "thin" },
  };

  overviewByRoomSheet.getCell(`C${ceilingAreaRow}`).value = totalCeilingArea;
};
