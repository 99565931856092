import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../actions/appActions";
import { AppWindowName } from "../assets/constants";
import CsvImportComponent from "./csvImportComponent/CsvImportComponent";
import DataTransfert from "./dataTransfert/DataTransfert";
import FamilyComponent from "./familyComponent/FamilyComponent";
import PropertiesPreference from "./parameterPreference/PropertiesPreference";

export class AppBody extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    // what happens when clicking Enter key, uncomment next function if you need to define a global listener on your plugin,
    // and uncomment it's binding in the constructor, then uncomment it's init in componentDidount and it's clear in componentWillUnmount
    _handleKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            let next = document.getElementById(
                // change for your button id
                "footer-next-button"
            ) as HTMLButtonElement;
            if (next && !next.disabled) {
                next.click();
            }
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);

        //init language, defaults to English
        if (
            this.props.i18n.language !== "English" &&
            this.props.i18n.language !== "French"
        ) {
            this.props.i18n.changeLanguage("English");
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    render() {
        switch (this.props.app.currentWindow) {
            case AppWindowName.CSV_IMPORT_WINDOW:
                return <CsvImportComponent></CsvImportComponent>;
            case AppWindowName.FAMILY_WINDOW:
                return <FamilyComponent></FamilyComponent>;
            case AppWindowName.PROPERTIES_PREFERENCE:
                return <PropertiesPreference></PropertiesPreference>;
            case AppWindowName.TRANSFERT_FILES:
            return <DataTransfert></DataTransfert>; 
            default:
                return <CsvImportComponent></CsvImportComponent>;
        }
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        setRoute: ownProps.setRoute,
        route: ownProps.route,
        app: state.app,
    };
};

let mapDispatchToProps = {
    selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { allAvailableItems: Element[] };

// add translation files for this plugin, add them on the i18n.js on the root of the project and then change the argument for withTranslation([XXX])
export default connector(withTranslation(["pamCanalisation"])(AppBody));
