import React, { Component } from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import configureStore from "../store";
// import "bootstrap/dist/css/bootstrap.min.css";
import AppBody from "./AppBody";
import "./index.scss";

interface Props {}

export default class Root extends Component<Props> {
    public store: Store;

    constructor(props: Props) {
        super(props);
        this.store = configureStore();
    }

    componentDidMount = () => {};

    render = () => {
        return (
            <Provider store={this.store}>
                <div
                    id="famillies-import-container"
                    // if you want to use bootstrap in your plugin, uncomment next line
                    className="bootstrap-scope"
                >
                    <AppBody />
                </div>
            </Provider>
        );
    };
}
