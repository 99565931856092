import React from "react";
import { Container, Header } from "semantic-ui-react";

export const HeaderComponent = ({
    Icon,
    name,
    subheader
}: any) => {

    return (
       <Container textAlign="center" style={{ marginBottom: 0 }}>
        <Header
          color="blue"
          style={{ paddingTop: "0", paddingBottom: 0 }}
          as="h3"
          content={name}
          image={Icon}
          subheader={subheader}
        />
      </Container>
    );
}