import React from "react";
import { Segment, Button } from "semantic-ui-react";
import TopHeader from "../../../Components/Headers/TopHeader";
import SubHeader from "../../../Components/Headers/SubHeader";
import GroupBox from "./GroupBox";
import { IWording } from "../../../Types";

const wording: IWording = {
  stepOne: {
    French: "Etape 1/2 : Sélection des types de systèmes",
    English: "Step 1/2: Type system selection",
  },
};

interface Props {
  onBack: () => void;
  onValidate: (elements: any, floors: any) => void;
  headerName?: string;
  showHeader?: boolean;
  showSubHeader?: boolean;
}

export const GroupSelector = (props: Props) => {
  const { showHeader, showSubHeader, headerName } = props;
  return (
    <div style={{ height: "100%" }}>
      {showHeader && <TopHeader Icon={"/plugin_new_logo/BeneluxIcon.png"} name={headerName || ""} />}

      {showSubHeader && <SubHeader heading={wording["stepOne"]["English"]} />}
      <GroupBox {...props} />
    </div>
  );
};

export default GroupSelector;
