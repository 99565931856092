import React, { useState } from "react";
import {
  Icon,
  Checkbox,
  Radio,
  Input,
  Popup,
  Button,
  Select,
} from "semantic-ui-react";

interface Props {
  data: any;
  register: any;
  rowNumber: any;
  watch: any;
}

const style = {
  rowStyle: {
    padding: "5px",
    minHeight: "25px",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
    cursor: "pointer",
  },
};

export const CalepinerTableRow = (props: Props) => {
  const { data, register, rowNumber, watch } = props;
  const {
    Ids,
    Floor,
    ElementName,
    ElementHeight,
    Solution,
    ProductHeight,
    StudDistance,
    Key,
    Check,
    IsLayout,
  } = data;
  const [open, setOpen] = useState(false);

  return (
    <Popup
      inverted
      content={!IsLayout && "Ce système ne peut être calepiné actuellement"}
      on="hover"
      eventsEnabled={true}
      onClose={() => setOpen(false)}
      onOpen={() => {
        !IsLayout && setOpen(true);
      }}
      open={open}
      trigger={
        <div
          className="row-hover"
          style={
            IsLayout
              ? style.rowStyle
              : {
                  ...style.rowStyle,
                  backgroundColor: "rgba(236, 240, 241, 0.5)",
                  color: "GrayText",
                }
          }
        >
          <input
            {...register(`CalepinageDetails[${rowNumber}].IsLayout`)}
            type="checkbox"
            defaultChecked={IsLayout}
            style={{ display: "none" }}
            className="w-5"
          />
          <input
            {...register(`CalepinageDetails[${rowNumber}].Ids`)}
            type="hidden"
            value={JSON.stringify(Ids)}
            className="w-5"
          />
          <input
            {...register(`CalepinageDetails[${rowNumber}].Check`)}
            type="checkbox"
            defaultChecked={Check}
            disabled={!IsLayout}
            className="w-5"
          />

          <div
            style={{
              justifyContent: "center",
              fontWeight: "bold",
              display: "block",
              width: "10%",
              color: IsLayout ? "black" : "GrayText",
              border: "0.2px",
              cursor: "pointer",
              wordWrap: "break-word",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <input
              {...register(`CalepinageDetails[${rowNumber}].Floor`)}
              type="hidden"
              value={Floor}
            />
            <div>{Floor}</div>
          </div>

          <div
            style={{
              justifyContent: "center",
              fontWeight: "bold",
              display: "block",
              width: "25%",
              color: IsLayout ? "black" : "GrayText",
              border: "0.2px",
              cursor: "pointer",
              wordWrap: "break-word",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <input
              {...register(`CalepinageDetails[${rowNumber}].ElementName`)}
              type="hidden"
              value={ElementName}
            />
            <div>{`${ElementName} (${Ids.length})`}</div>
          </div>

          <div
            style={{
              justifyContent: "center",
              fontWeight: "bold",
              display: "block",
              width: "10%",
              color: IsLayout ? "black" : "GrayText",
              border: "0.2px",
              cursor: "pointer",
              wordWrap: "break-word",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <input
              {...register(`CalepinageDetails[${rowNumber}].ElementHeight`)}
              type="hidden"
              value={ElementHeight}
            />
            <div>{parseFloat(ElementHeight).toFixed(2)}</div>
          </div>

          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "bold",
              width: "25%",
              color: IsLayout ? "black" : "GrayText",
              display: "flex",
            }}
          >
            <input
              {...register(`CalepinageDetails[${rowNumber}].Solution`)}
              type="hidden"
              value={JSON.stringify(Solution)}
            />
            <div>{Solution.Name}</div>
          </div>
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "bold",
              width: "15%",
              color: "black",
              display: "flex",
            }}
          >
            <select
              {...register(`CalepinageDetails[${rowNumber}].ProductHeight`)}
              defaultValue={ProductHeight}
              disabled={!IsLayout}
            >
              <option value="Wall Height">Wall Height</option>
            </select>
          </div>
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "bold",
              width: "15%",
              color: "black",
              display: "flex",
            }}
          >
            <select
              {...register(`CalepinageDetails[${rowNumber}].StudDistance`)}
              defaultValue={StudDistance || "600"}
              disabled={!IsLayout}
            >
              <option value="400">400</option>
              <option value="600">600</option>
            </select>
          </div>
        </div>
      }
    />
  );
};

export default CalepinerTableRow;
