import React, { useEffect, useState } from "react";
import { Button, Container, Dimmer, Loader, Message } from "semantic-ui-react";
import { Routes } from "../root";
import { PDFPreview } from "./PDFPreview";
import { FunctionalityHeader } from "../FunctionalityHeader";
import { SelectionStore } from "../../../Selection/Reducers";
import { connect } from "react-redux";
import axios from "axios";
import { PDFPreview2019 } from "./PDFPreview2019";
import { Buffer } from "buffer";
import { InjectedCounterProps } from "../../../../../ErrorManagement/components/ErrorBoundry";
import { errorCodeKey } from "../../../../../ErrorManagement/utils/errorCodeEnum";

const token = localStorage.getItem("token");
type Props = InjectedCounterProps & {
  setRoute: any;
  routes: Routes;
  Icon: string;
  config: any;
};
export const PreviewPage = (Props: any, onError: any) => {
  const [pdfUri, setPdfUri]: any = useState([]);
  const [fileName, setfileName]: any = useState("");
  const [pdfErr, setPdfErr]: any = useState(false);
  const url = Props.config.REACT_APP_FILEMANAGEMENTAPIURL_EXTERNAL;
  
  let currentPDF = localStorage.getItem("currentPreviewPdf");
    let version = localStorage.getItem("version");

  useEffect(() => {
    localStorage.removeItem("isModification");
    if (version && version.split(".").length > 0) {
      version = version.split(".")[0];
    }

    if (currentPDF === null) {
      if (version === "19") {
        PDFPreview2019(Props.dossierData, Props.config)
          .then((value) => {
            setPdfUri(value);
          })
          .catch((err) => setPdfErr(err));
      } else {
        PDFPreview(Props.dossierData, Props.config)
          .then((value) => {
            setPdfUri(value);
          })
          .catch((err) => {
            Props.onError(errorCodeKey.PB_DT_L_008, "error", true);
            setPdfErr(err);
          });
      }
    } else {
      axios({
        url: url + "/download/" + currentPDF,
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          // const buffer =
          // "data:application/pdf;filename=generated.pdf;base64," +
          // Buffer.from(response.data, "binary").toString("base64");

          const buffer = response.data;
          const blobNAme = new Blob([buffer], {
            type: 'application/pdf'
          });
          var fileURL = URL.createObjectURL(blobNAme);

          // console.log("fileURL", fileURL);
          // // Create an iframe to demonstrate it:
          // let iframe = document.createElement('iframe');
          // iframe.className = 'sample-iframe';
          // iframe.src = fileURL;
          // document.body.appendChild(iframe);
          
          setfileName(fileURL);
          localStorage.removeItem("currentPreviewPdf");
        })
        .catch((err) => {
          Props.onError(errorCodeKey.PB_DT_L_008, "error", true);
          setPdfErr(err);
        });
    }
  }, []);

  return (
    <div>
      <Container style={{ width: 900 }}>
        <FunctionalityHeader
          name={Props.dossierData.name}
          subheader="Modèle par défaut"
          Icon={Props.Icon}
        />

        {pdfErr ? (
          <Message warning>
            <Message.Header>{pdfErr}</Message.Header>
            <br />
            <Button
              color="blue"
              size="medium"
              onClick={() => Props.setRoute(Routes.PROJECT_INFO)}
            >
              Informations projet
            </Button>
          </Message>
        ) : fileName === "" ? (
          <Dimmer active className="dimmerHeight30">
            <Loader size="huge" indeterminate>
              Générer un PDF...
            </Loader>
          </Dimmer>
        ) : (
          <div style={{ height: 475, width: "100%" }}>
            <iframe
              src={currentPDF === null ?  version === "19" ? pdfUri[0] : fileName : fileName}
              width="100%"
              height="100%"
            />
          </div> 
        )}
      </Container>

      <Container
        style={{
          textAlign: "center",
          width: "auto",
          borderRadius: "0 0 0 4px",
          padding: "6px 10px",
          right: 0,
          bottom: 5,
          background: "#ffffff",
          position: "fixed",
          marginBottom: "0",
        }}
      >
        <Button
          color="orange"
          size="medium"
          onClick={() => Props.setRoute(Routes.COVER_PAGE)}
        >
          Retour
        </Button>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    setRoute: ownProps.setRoute,
    dossierData: state.moduleData.dossierData,
  };
};

export default connect(mapStateToProps)(PreviewPage);
