export enum errorCodeKey {
  PB_SE_L_002 = "PB-SE-L-002",
  PB_SE_L_005 = "PB-SE-L-005",
  PB_SE_H_011 = "PB-SE-H-011",
  PB_DT_H_011 = "PB-DT-H-011",
  PB_DT_L_008 = "PB-DT-L-008",
  PB_DT_H_012 = "PB-DT-H-012",
  PB_DT_L_006 = "PB-DT-L-006",
  PB_DT_H_003 = "PB-DT-H-003",
  PB_DT_L_004 = "PB-DT-L-004",
  PB_LT_H_002 = "PB-LT-H-002",
  PB_LT_H_001 = "PB-LT-H-001",
  PB_LT_H_007 = "PB-LT-H-007",
  PB_PE_H_007 = "PB-PE-H-007",
  PB_PE_H_001 = "PB-PE-H-001",
  PB_IN_H_001 = "PB-IN-H-001",
  PB_IN_H_007 = "PB-IN-H-007",
  PB_DE_H_001 = "PB-DE-H-001",
  PB_CO_L_001 = "PB-CO-L-001",
  PB_QU_H_001 = "PB-QU-H-001",
  PB_QU_H_009 = "PB-QU-H-009",
  PB_CL_L_001 = "PB-CL-L-001",
  PB_CT_L_001 = "PB-CT-L-001",
  PB_AA_L_010 = "PB-AA-L-010",
}
