import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { DrawRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "8afc96d9-789d-4192-b9ed-8a361c6635c9";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Calepinage",
    module: "RIGIPSBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DrawRoot />
    </ParentRoot>
  );
};

export const RiggipsDrawRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <DrawRoot />
    </ParentRoot>
  );
};

export const Draw2: Functionality = {
  Name: { French: "Calepiner", English: "Layout", German: "Layout" },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Exécuter le calepinage",
    English: "Perform the layout",
    German: "Führen Sie das Layout durch",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Calepiner.png",
  RootComponent,
  ParentId: "0",
};
