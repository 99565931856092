import { api } from "../../../../RevitJS/API";
import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  filter,
  cloneDeep,
  find,
  map,
  reduce,
  flattenDeep,
  values,
  uniqBy,
  uniq,
  forEach,
  groupBy,
  flattenDepth,
  includes,
  isUndefined,
  sortBy,
} from "lodash";
import { LayoutConfigType } from "./types";
import { layoutGroup, layoutInGroup } from "../api";
import { MyConfig } from "../../../../Helper";
import _ from "lodash";
import { sendEvent } from "../../Utils/events";
import { ELayoutMethod } from "../../Types";

export const createNewCalepinage = async (name: string) => {
  const projectId = await bimStorage.onProjectData();
  var currentdate = new Date();
  return {
    Name: name,
    Date: `${currentdate.getTime()}`,
    ProjectId: projectId,
    SelectionStatus: false,
    CalepinerStatus: false,
    SelectionIds: [],
    CalepinageDetails: [],
  };
};

export const createCalepinableRow = async (selectionId: any) => {
  const response = await new Promise((resolve, reject) =>
    window.indec.getSelection(parseInt(selectionId), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    }); //await bimStorage.getSelection(parseInt(selectionId));
  const wallCombineDetail = reduce(
    response.SelectionDetails,
    function (result: any, value: any, key: any) {
      let resd: any = [];
      forEach(value.Ids, (vi) => {
        resd.push({
          ElementName: value.ElementName,
          Id: vi,
          Solution: value.Solution,
          Oid: value.Solution.Oid,
          ExternalName: "",
        });
      });

      return result.concat(resd);
    },
    []
  );

  const wallIds: string[] = map(wallCombineDetail, "Id");

  const wallsData = await new Promise((resolve, reject) =>
    window.indec.getWallsData({ ids: wallIds }, resolve, reject)
  )
    .then((x: any) => {
      console.log("promise resolve");
      return JSON.parse(x);
    })
    .catch((ex: any) => {
      console.error(ex);
      console.error("promise reject", ex);
      return [];
    });

  // const wallsDatad = await api.queries.getWallsData(wallIds);

  const datalist = map(wallsData, (wd) => {
    const wlComine = find(wallCombineDetail, { Id: wd.Id });
    return { ...wd, ...wlComine };
  });
  let clData: any = groupBy(datalist, (item: any) => {
    return [
      item["LevelName"],
      item["WallType"],
      item["Height"],
      item["SolutionName"],
    ];
  });

  clData = map(clData, (value, key) => {
    //const isLayout = isLayoutable(value[0].WallType);
    return {
      Ids: map(value, "Id"),
      Floor: value[0].LevelName,
      ElementName: value[0].WallType,
      ElementType: "Wall",
      ElementHeight: value[0].Height / 1000,
      Solution: value[0].Solution,
      ProductHeight: "Wall Height",
      StudDistance: "600",
      PlaqueArticles: [],
      MontantArticles: [],
      Key: key,
      Check: true,
      IsLayout: true,
    };
  });
  return clData;
};

const allowedSolution = [
  "GF 100/1.75.1.A",
  "GF 100 ECO HT V/75.2.A",
  "GF 100/2.50.2.A",
  "GF 205/2.75*75.2.AA",
  "GF 105 RF V/75.2",
];

export const isLayoutable = (name: string) => allowedSolution.includes(name);

export const isWallProcessed = async (
  layoutConfigure: LayoutConfigType,
  calepiange: any
): Promise<{
  hasProcessWalls: boolean;
  proccessedWallsIds: string[];
}> => {
  let selectedWallType = filter(calepiange.CalepinageDetails, "Check");
  if (selectedWallType) {
    const selectedWallsIds = flattenDeep(map(selectedWallType, "Ids"));
    if (selectedWallsIds) {
      let processedFraming: any = [];
      let processedDryWall: any = [];
      let processedWallType: any = [];
      const criteriaProcessArray = [
        {
          Param: { Name: "Processed", Type: "Boolean", Value: true },
          Rule: "Equals",
        },
      ];
      processedWallType = await api.queries.filterElements(
        "Wall",
        criteriaProcessArray,
        null
      );

      //#region get the walls which already layouted by other/custom parts
      // const wallPartsIds = await api.queries.getPartsIds(selectedWallsIds);
      // const wallsWithParts = filter(
      //   wallPartsIds,
      //   (wl: any) => wl.partsIds.length > 0
      // );
      // const currentWallsLayout = map(wallsWithParts, "wallId");
      //#endregion

      if (layoutConfigure.MetalFraming) {
        const criteriaArray = [
          {
            Param: { Name: "Processed", Type: "Boolean", Value: true },
            Rule: "Equals",
          },
          {
            Param: {
              Name: "Framing",
              Type: "Boolean",
              Value: true,
            },
            Rule: "Equals",
          },
        ];
        processedFraming = await api.queries.filterElements(
          "Wall",
          criteriaArray,
          null
        );
      }

      if (layoutConfigure.DryWall) {
        const criteriaArray = [
          {
            Param: { Name: "Processed", Type: "Boolean", Value: true },
            Rule: "Equals",
          },
          {
            Param: {
              Name: "Drywall",
              Type: "Boolean",
              Value: true,
            },
            Rule: "Equals",
          },
        ];
        processedDryWall = await api.queries.filterElements(
          "Wall",
          criteriaArray,
          null
        );
      }
      const processedWallIds = uniq(
        processedWallType.concat(
          processedFraming.concat(processedDryWall) //.concat(currentWallsLayout)
        )
      );

      if (processedWallIds.length > 0) {
        if (
          selectedWallsIds.some((item: any) =>
            processedWallIds.includes(item.toString())
          )
        ) {
          return {
            hasProcessWalls: true,
            proccessedWallsIds: filter(selectedWallsIds, (ids: any) => {
              return processedWallIds.includes(ids.toString());
            }),
          };
        }
      }
    }
  }
  return {
    hasProcessWalls: false,
    proccessedWallsIds: [],
  };
};

export const drawWallTypeAssigment = async (
  calepinage: any,
  revitVersion: number
) => {
  // 0. create deep clone of calepinage
  let cloneCalepinage = JSON.parse(JSON.stringify(calepinage));
  // 1. filter clepinagedetails row only check once.

  let selectedWallType = filter(
    cloneCalepinage.CalepinageDetails,
    (cd: any) => {
      return cd.Check;
    }
  );
  // if (revitVersion === 2022) {
  // selectedWallType = filter(selectedWallType, (cd: any) => {
  //   return cd.isProcess;
  // });
  //}

  // 2. create array of object consist of {wallId, typetoassign}
  let wallsToChange: any = [];
  forEach(selectedWallType, (wtype) => {
    forEach(wtype.Ids, (id) => {
      wallsToChange.push({
        id: id,
        technicalName: wtype.Solution.Name,
      });
    });
  });

  // 3. call revit change wall type api
  const chagedWallTypesResult = await new Promise((resolve, reject) =>
    window.indec.changeWallTypes({ data: wallsToChange }, resolve, reject)
  )
    .then((x: any) => {
      return JSON.parse(x);
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  // await api.familyEditor.changeWallTypes(wallsToChange);

  if (chagedWallTypesResult.length > 0) {
    // 4. update selection
    let selectionDetail = await new Promise((resolve, reject) =>
      window.indec.getSelection(
        parseInt(cloneCalepinage.SelectionIds[0]),
        resolve,
        reject
      )
    )
      .then((x: any) => {
        return x;
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      });

    const changedWallTypes = filter(chagedWallTypesResult, (fl: any) => {
      return fl.Changed === 0 || fl.Changed === 1;
    });
    if (changedWallTypes.length > 0) {
      const changedWallTypeNames = map(changedWallTypes, "WallType");
      const selectionDetails = map(
        selectionDetail.SelectionDetails,
        (sed: any) => {
          if (changedWallTypeNames.includes(sed.ElementName)) {
            sed.ElementName = sed.Solution.Name;
          }
          return sed;
        }
      );
      selectionDetail.SelectionDetails = selectionDetails;
      // 5. save updated selection to bimstorage
      await bimStorage.updateSelection(selectionDetail.Id, selectionDetail);

      // 6. update calepinage
      const calepinageDetails = map(
        cloneCalepinage.CalepinageDetails,
        (sed: any) => {
          if (changedWallTypeNames.includes(sed.ElementName) && sed.Check) {
            sed.ElementName = sed.Solution.Name;
            sed.isProcess = true;
          } else {
            sed.isProcess = false;
          }
          return sed;
        }
      );
      cloneCalepinage.CalepinageDetails = calepinageDetails;
      cloneCalepinage.CalepinerStatus = false;

      // 7. save or update calepinage based on Id
      if (cloneCalepinage.Id) {
        await bimStorage.updateCalepinage(cloneCalepinage.Id, cloneCalepinage);
      } else {
        const id = await bimStorage.saveCalepinage(cloneCalepinage);
        cloneCalepinage.Id=id;
        // cloneCalepinage = await new Promise((resolve, reject) =>
        //   window.indec.getCalepinage(parseInt(id), resolve, reject)
        // )
        //   .then((x: any) => {
        //     return x;
        //   })
        //   .catch((ex: any) => {
        //     console.error(ex);
        //     return [];
        //   });
        //await bimStorage.getCalepinage(id);
      }
    }
  }
  return {
    cloneCalepinage: cloneCalepinage,
    layoutWalls: chagedWallTypesResult.filter(
      (user: { Changed: number }) => user.Changed === 0 || user.Changed === 1
    ),
    nonLayoutWalls: filter(chagedWallTypesResult, { Changed: 2 }),
  };
};
export const calpinageNameChangeUtil = async (id: any, name: string) => {
  var currentdate = new Date();
  let calepinageToUpdate = await new Promise((resolve, reject) =>
    window.indec.getCalepinage(parseInt(id), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  //await bimStorage.getCalepinage(id);
  // calepinageToUpdate.Date = `${currentdate.getDate()}/${
  //   currentdate.getMonth() + 1
  // }/${currentdate.getFullYear()}`;
  calepinageToUpdate.Date = currentdate.getTime();
  calepinageToUpdate.Name = name;
  const response = await bimStorage.updateCalepinage(
    calepinageToUpdate.Id,
    calepinageToUpdate
  );
  return response;
};
export const callLayoutCalculator = async (
  calepinage: any,
  layoutWalls: any,
  erase: boolean,
  eraseWallIds: string[],
  wallParts: any,
  wallStructure: any,
  layoutDrawMethod: ELayoutMethod
) => {
  let selectedWallType = filter(calepinage.CalepinageDetails, "Check");
  const changedWallTypeNames = map(layoutWalls, "SolutionName");
  selectedWallType = selectedWallType.filter((user) =>
    changedWallTypeNames.includes(user.Solution.Name)
  );

  const wallMatching: any = [];
  forEach(selectedWallType, (wallType: any, index: any) => {
    let wallTypeParts = filter(wallParts, (wlp: any) => {
      return wlp.WallType === wallType.ElementName;
    });

    let wallTypeSturcture = filter(wallStructure, (wlp: any) => {
      return wlp.wallType === wallType.ElementName;
    });

    wallTypeSturcture = map(wallTypeSturcture, (wtps: any) => {
      const wallTypeRelatedToStructure = find(wallTypeParts, {
        LayerIndex: wtps.layerIndex,
      });
      return {
        LayerIndex: wtps.layerIndex,
        Name: wtps.material,
        Width: wtps.thickness,
        Height: wallTypeRelatedToStructure.Height,
        Construction: wallTypeRelatedToStructure.Construction,
      };
    });

    wallTypeParts = filter(wallTypeParts, (wlp: any) => {
      return (
        !wlp.Name.toLowerCase().includes("profile") &&
        !wlp.Name.toLowerCase().includes("profiel") &&
        !wlp.Name.toLowerCase().includes("air")
      );
    });

    let matchObject = {
      structure: sortBy(wallTypeSturcture, ["LayerIndex"]),
      boardsDescription: map(
        sortBy(wallTypeParts, ["LayerIndex"]),
        (prtLayer: any) => {
          return {
            color: "198, 198, 198",
            layerIndex: prtLayer.LayerIndex,
            name: prtLayer.Name,
            thickness: prtLayer.Width,
          };
        }
      ),
      InputType: `${wallType.ElementName} - ${wallType.ElementHeight} - ${wallType.Floor}`,
      systemName: wallType.Solution.Name,
      technicalName: `${wallType.Solution.Name}`,
      layoutConfig: {
        boardConfig: {
          maxWidth: 1200,
          flagAboveDoor: true,
          flagAboveDoorLimit: 1200,
          flagSideWidth: 300,
          minWidth: 150,
          minHeight: 190,
          staggeredStyle: "staggered",
          junction_Overlap: false,
          junction_Extend: true,
          junction_Extend_Space: 5,
          junction_Extend_OnlyRightAngles: true,
          verticalCut: false,
          verticalCut_DistanceFromBoardBottomtoWallBottom: 10,
          verticalCut_DistanceFromBoardToptoWallTop: 0,
          verticalCut_StaggeredVerticallyOnSameSide: false,
          verticalCut_StaggeredHorizontallyOnSameLayer: false,
          verticalCut_OffsetwithOtherSide: 900.0,
          verticalCut_Height: 2600.0,
          freeEnd_Type: "EMBED_SMALL",
          layoutMethod:
            layoutDrawMethod === ELayoutMethod.NEW ? "PARTS" : "FAMILY",
        },
        railConfig: {
          aboveOpening: true,
          belowOpening: true,
          name: "Rail Stil® R 70/300 bot10L",
          length: 3000,
          minLength: 200,
          junction_ExtendType: "realistic",
          junction_DoubleRailExtendType: "alignedAtMiddle",
        },
        frameConfig: {
          stopAtFromTop: 15,
          length: 3000.0,
          minLength: 200,
          frame_double: false,
          space: wallType.StudDistance,
          alignmentSingleRow: "none",
          alignmentDoubleRow: "aligned",
          staggeredWidth: 40,
          additionalFrameAboveOpeningWider: true,
          additionalFrameAboveOpeningWidth: 70,
          additionalFrameAboveOpeningForOpening: true,
          additionalFrameAboveOpeningMinWidth: 600,
          centerAdditionalFrames: true,
          addExtraFrameOnTABySide: true,
          wingLength: 60,
          orientation: "vertical",
          horizontal_thickness: 7.5,
          junction_ExtendType: "realistic",
          verticalCut: false,
          verticalCut_LengthOfIntermediate: 300,
          verticalCut_minDistanceFromUpperCutTopToWallTop: 400,
          verticalCut_Double_Staggered_Offset: 900,
          verticalCut_Double_Staggered: false,
        },
      },
    };
    wallMatching.push(matchObject);
  });

  let selectedIds = flattenDeep(map(selectedWallType, "Ids"));
  if (!erase) {
    selectedIds = filter(selectedIds, (ids: any) => {
      return !eraseWallIds.includes(ids.toString());
    });
  }
  if (selectedIds.length > 0) {
    const wallDetails = await api.queries.getWallsData(selectedIds);
    const walls = map(wallDetails, (wd: any) => {
      wd.WallType = `${wd.WallType} - ${wd.Height / 1000} - ${wd.LevelName}`;
      return wd;
    });
    let wallsDataByLevel = await api.queries.getWallsDataByLevel(
      uniq(map(wallDetails, "LevelId"))
    );

    const config = await MyConfig();
    let wallIdsGroups: any = await layoutGroup(
      wallMatching,
      walls,
      wallsDataByLevel,
      config
    ).catch((error: any) => {});

    let layoutData: any = await layoutInGroup(
      wallMatching,
      walls,
      wallIdsGroups,
      wallsDataByLevel,
      config
    ).catch((error: any) => {});

    // let eventData: any = [];
    // eventData = wallMatching.map((obj: any) => {
    //   let data: any = [];
    //   _.forEach(wallDetails, (fw) => {
    //     if (fw.WallType === obj.InputType) {
    //       data = {
    //         elementType: fw.WallType,
    //         solution: obj.systemName,
    //         surface: fw.Area,
    //         articles: {
    //           Rail: [obj?.Rail1_Name],
    //           Montants: [obj?.Frame1_Name, obj?.Frame2_Name],
    //           Plaques: [
    //             obj?.E1?.name,
    //             obj?.E2?.name,
    //             obj?.E3?.name,
    //             obj?.I1?.name,
    //             obj?.I2?.name,
    //             obj?.I3?.name,
    //           ],
    //         },
    //       };
    //     }
    //   });

    //   return data;
    // });

    // sendEvent(eventData);
    return layoutData;
  }
  return null;
};

export const checkForWallTypeAndHeight = async (calepinageStatus: any) => {
  let wallRows: any = flattenDepth(
    JSON.parse(JSON.stringify(calepinageStatus.CalepinageDetails)),
    1
  );

  let wallIds = map(wallRows, (ele) => {
    return ele.Ids;
  });

  wallIds = flattenDeep(wallIds);

  let selectionWalls = await new Promise((resolve, reject) =>
    window.indec.getSelection(
      parseInt(calepinageStatus.SelectionIds[0]),
      resolve,
      reject
    )
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return null;
    });
  //  await bimStorage.getSelection(
  //   parseInt(calepinageStatus.SelectionIds[0])
  // );

  if (selectionWalls != null) {
    let selectionWallsIds = map(selectionWalls.SelectionDetails, (ele) => {
      return ele.Ids;
    });

    selectionWallsIds = flattenDeep(selectionWallsIds);

    let wallsDetails = await api.queries.getWallsData(wallIds);

    let dictionary = Object.assign(
      {},
      ...wallsDetails.map((x: any) => ({
        [x.Id]: parseFloat(((x.Height as number) / 1000).toString()).toFixed(2),
      }))
    );
    //to check if wall deleted from selection
    let checkIfWallDeleted = _.isEqual(
      _.sortBy(selectionWallsIds),
      _.sortBy(wallIds)
    );

    //get all walls from modal
    const wallTree = await api.selection.elementsByLevelAndType("wall");
    const getAllTypeIdsfromSelection = async (typeTree: any) => {
      let finalIds: any = [];
      typeTree.forEach((level: any) => {
        const levelTypeIds: any = _.flatten(
          level.Elements.map((e: any) => {
            return e.Ids;
          })
        );
        finalIds.push(levelTypeIds);
      });
      return finalIds;
    };

    const allWallIds: any = await getAllTypeIdsfromSelection(wallTree.Tree);
    let onlyWallIds = flattenDeep(allWallIds);

    let wallsDeleted: any = 0;
    //to check if wall is deleted by model/exterior
    let checkIfWallDeletedByModel: boolean = false;
    forEach(selectionWalls.SelectionDetails, (ele: any) => {
      forEach(ele.Ids, (i: any) => {
        if (!onlyWallIds.includes(i)) {
          wallsDeleted = wallsDeleted + 1;
          checkIfWallDeletedByModel = true;
          return;
        }
      });
    });

    if (wallsDeleted === selectionWallsIds.length) {
      return "SelectionDeleted";
    }

    //to check if solution is changed from selection
    let solutionArray: any = [];
    forEach(selectionWalls.SelectionDetails, (stype) => {
      forEach(stype.Ids, (id) => {
        solutionArray.push({
          id: id,
          technicalName: stype.Solution.Name,
        });
      });
    });
    // console.log(solutionArray);

    let dictionaryForType = Object.assign(
      {},
      ...wallsDetails.map((x: any) => ({ [x.Id]: x.WallType }))
    );

    let dictionaryForSolution = Object.assign(
      {},
      ...solutionArray.map((x: any) => ({ [x.id]: x.technicalName }))
    );

    let countChanges: any = 0;
    forEach(wallRows, (wall: any, index: number) => {
      forEach(wall.Ids, (eachId: any, indexId: number) => {
        if (dictionaryForType[eachId] !== undefined) {
          if (dictionaryForType[eachId] !== wall.ElementName) {
            countChanges = countChanges + 1;
          }
        }
        if (dictionary[eachId] !== undefined) {
          if (
            dictionary[eachId] !== parseFloat(wall.ElementHeight).toFixed(2)
          ) {
            countChanges = countChanges + 1;
          }
        }
        if (dictionaryForSolution[eachId] !== undefined) {
          if (dictionaryForSolution[eachId] !== wall.Solution.Name) {
            countChanges = countChanges + 1;
          }
        }
      });
    });

    if (countChanges > 0 || !checkIfWallDeleted || checkIfWallDeletedByModel) {
      return true;
    } else {
      return false;
    }
  } else {
    return "SelectionDeleted";
  }
};
