import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { projectDataAction } from "../Actions/types";

export const projectData = (
  data: initProjectData | undefined = { ProjectId: "", ProjectPath: "" },
  action: projectDataAction
) => {
  switch (action.type) {
    case "SET_PROJECTDATA":
      return action.data;
    default:
      return data;
  }
};
