import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import { AppWindowName } from "../../assets/constants";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import { addCsvFile } from "../../actions/csvActions";
import "./CsvImportComponent.scss";

class CsvImportComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  nextPage() {
    this.props.selectWindowToShow(AppWindowName.FAMILY_WINDOW);
  }

  setFiles(file: { file: File; data: any }) {
    if (file) {
      this.props.addCsvFile(file);
    }
  }

  render() {
    let { csvList, t } = this.props;
    return (
      <>
        <div id="csv-import-component-container">
          <Header displayName={t("CHOOSE_DATA") || "Chosse data"}></Header>
          <Body files={csvList} setFiles={this.setFiles}></Body>
          <Footer
            disabledNextButton={csvList.length === 0}
            savedProjectsButtonShow={false}
            clickedNextButton={this.nextPage}
          ></Footer>
        </div>
      </>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    csvList: state.csv.list,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  addCsvFile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {};

export default connector(
  withTranslation(["pamCanalisation"])(CsvImportComponent)
);
