import { bimStorage, storageKey } from "../../../../BIMStore";

export async function getCurrentConfiguration() {
  let propertiesToReturn: any | null = null;
  // await db.transaction("r", [db.properties], async () => {
  //   await db.properties.each((prop) => {
  //     propertiesToReturn = prop;
  //   });
  // });
  propertiesToReturn = await bimStorage.getItem(
    storageKey.PRESCRIBIM_PROPERTIES_NEW
  );
  return propertiesToReturn;
}

export async function savePropertiesConfiguration(
  properties: any,
  isDefaultConfiguration: boolean
) {
  //db.properties.clear();
  bimStorage.setItem(storageKey.PRESCRIBIM_PROPERTIES_NEW, JSON.stringify([]));
  await getCurrentConfiguration();
  properties = {
    ceilingsProperties: properties.ceilingsProperties.filter(
      (prop: any) => prop.categoryName !== "PRICE"
    ),
    liningWallsProperties: properties.liningWallsProperties.filter(
      (prop: any) => prop.categoryName !== "PRICE"
    ),
    partitionsProperties: properties.partitionsProperties.filter(
      (prop: any) => prop.categoryName !== "PRICE"
    ),
  };

  // await db.transaction("rw", [db.properties], async () => {
  //   return await db.properties.add({
  //     propertiesConfiguration: { ...properties },
  //     isDefaultConfiguration: isDefaultConfiguration,
  //   });
  //   // }
  // });

  bimStorage.setItem(
    storageKey.PRESCRIBIM_PROPERTIES_NEW,
    JSON.stringify({
      propertiesConfiguration: { ...properties },
      isDefaultConfiguration: isDefaultConfiguration,
    })
  );
  return properties;
}
