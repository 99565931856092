import { CHANGE_CURRENT_WINDOW, INIT_LANGUAGE, AppActionTypes } from "./types"
import { APP_WINDOW_NAME } from "../../assets/constants";
import { Language } from "../../../../../RevitJS/Types/RevitTypes";

export const selectWindowToShowAction = (currentWindow: APP_WINDOW_NAME): AppActionTypes => {
  return {
    type: CHANGE_CURRENT_WINDOW,
    currentWindow: currentWindow,
  }
}


export const changeCurrentLanguageAction = (language: Language): AppActionTypes => {
  return {
    type: INIT_LANGUAGE,
    language: language,
  }
}
