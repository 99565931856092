import React from 'react'

interface Props{
    language: string
    wording:{
        noSelectionSaved:{[key:string]:string}
    }
}

export default function NoSelectionSaved({language, wording:{noSelectionSaved}}: Props) {
    return (
        <div style={{padding: 5, height: 25, backgroundColor:"rgb(250, 250, 255)", display:"flex", alignItems:"center", borderBottom:"0.5px solid white"}}>
            <div style={{justifyContent: "center", textAlign:"center", width:"100%", color:"grey"}}>
                {noSelectionSaved[language]}
            </div>
        </div>
    )
}

NoSelectionSaved.defaultProps={
    wording:{
        noSelectionSaved:{
            "French": "Aucune sélection sauvegardée",
            "English": "No selection saved"
        }
    }
}
