import { placoFilters } from "../../utils/placoFilters";
import {
  FiltersState, FiltersActionTypes, LOAD_FILTERS, APPLY_FILTERS
} from "./types";

export const initialState: FiltersState = {
  filters: [], //placoFilters.Walls,
  loadingFilters: true
};

export default function filtersReducer(state = initialState, action: FiltersActionTypes) {
  switch (action.type) {
    case LOAD_FILTERS:
      return { ...state, loadingFilters: false, filters: action.filters };
    case APPLY_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;

  }
}
