import { api } from "../../../../RevitJS/API";

export const removeCloseWindow = () => {
  if (localStorage.getItem("min") === "0:0") {
    localStorage.removeItem("min");
    if (localStorage.getItem("load") === "false") {
      setTimeout(() => {
        api.windowsHandler.closeWindow();
      }, 1000);
    }
    localStorage.setItem("load", "false");
  }
};
