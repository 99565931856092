import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "e59f9553-567d-460a-89cf-82634b875c00";

export const calpinageTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const FacadeCalepinageRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const CalepinageF4: Functionality = {
  Name: {
    English: "Calepinage F4",
    Indonesian: "Calepinage F4",
  },
  Trigger: calpinageTrigger,
  ShortDesc: {
    English: "Calepiner les façades",
    Indonesian: "Calepiner les façades",
  },
  Id: functionalityId,
  Icon: "/Facade/calepinageF4.png",
  RootComponent,
  ParentId: "0",
};
