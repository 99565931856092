import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import _, { isEmpty } from "lodash";
import orderBy from "lodash/orderBy";
import {
  addCeilingDetailsArrayHeader,
  addDetailsArrayHeader,
  addProjectInfo,
  writeToCellAndStyle,
  addGlobalLevelHeader,
} from "../Helper";
import Resizer from "react-image-file-resizer";
import { bimStorage, storageKey } from "../../../../BIMStore";
import jwt_decode from "jwt-decode";

export const getExcel = async (config: any) => {
  return await fetch(config.REACT_APP_SERVERURL + "/Excel/MetresV3.xlsx").then(
    (response) => {
      return response.arrayBuffer();
    }
  );
};

export const scheduleHandler = async (
  filteredTree: any,
  clearMetresSelection: any,
  loadingOff: any,
  config: any
) => {
  const workbook = new Excel.Workbook();
  let blob = await getExcel(config);
  workbook.xlsx.load(blob).then(async (wkbk) => {
    await writeWorkbook(wkbk, filteredTree, config);
    wkbk.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 0,
        visibility: "visible", // Set activeTab to 0
      },
    ];
    const buf = await wkbk.xlsx.writeBuffer();
    const documentName = await api.queries.getActiveDocumentName();
    const d = new Date();
    let monthNumber = d.getMonth() + 1;
    let month =
      monthNumber.toString().length === 1 ? "0" + monthNumber : monthNumber;
    let dayNumber = d.getDate();
    const ifSelectionsAreZone = isSelectionsAreZones(filteredTree);
    // - YYYY_MM_DD_ProjectName_Métrés par étage
    // - YYYY_MM_DD_ProjectName_Métrés par étage par zone

    let day = dayNumber.toString().length === 1 ? "0" + dayNumber : dayNumber;
    let fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Métrés par étage`;
    if (ifSelectionsAreZone) {
      fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Métrés par étage par zone`;
    }

    saveAs(new Blob([buf]), `${fileName}.xlsx`);

    clearMetresSelection();
    loadingOff();
    sendEvent();
    // api.windowsHandler.closeWindow();
  });
};

const sendEvent = async () => {
  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Métrés",
    module: "PLACOBIM",
  });
};

const writeWorkbook = async (
  workbook: Excel.Workbook,
  filteredTree: any,
  config: any
) => {
  await writeData(workbook, filteredTree, config);
};

const joinWithSemiColon = (cellValue: string) => {
  return _.uniq(cellValue.split(";")).join(";");
};
const getOuvrageRows = (SummaryRows: any) => {
  let ouvrageRows = _.cloneDeep(SummaryRows);
  let ouvrageRowsSummary: any = ouvrageRows.reduce((a: any, c: any) => {
    if (a.length === 0) {
      a.push(c);
    } else {
      let ouvrageExist = a.some((u: any) => u[2] === c[2]);
      if (ouvrageExist) {
        let index = a.findIndex((b: any) => b[2] === c[2]);
        a[index][4] = (a[index][4] / 10) * 10 + (c[4] / 10) * 10;
      } else {
        a.push(c);
      }
    }
    return a;
  }, []);

  return ouvrageRowsSummary;
};

const getGlobalZonesRows = (SummaryRows: any) => {
  let ZoneRows = _.cloneDeep(SummaryRows);
  let globalParZoneRow: any = ZoneRows.reduce((a: any, c: any) => {
    //a.push(c)
    if (a.length === 0) {
      a.push(c);
    } else {
      let zoneExist = a.some((u: any) => u[1] === c[1]);
      let ouvrageExist = a.some((u: any) => u[2] === c[2]);
      if (zoneExist && ouvrageExist) {
        let isZoneMerged = false;
        a.forEach((b: any) => {
          //index = index + 1;
          if (b[1] === c[1] && b[2] === c[2]) {
            isZoneMerged = true;
            b[3] = b[3] + ";" + c[3];
            b[4] = (b[4] / 10) * 10 + (c[4] / 10) * 10;
          }
        });

        if (!isZoneMerged) {
          a.push(c);
        }
        //let index = a.findIndex((b: any) => b[1] === c[1] && b[2] === c[2])
        // if(index !== -1){
        //     a[index][3] = a[index][3] + ";" + c[3]
        //     a[index][4] = (a[index][4] / 10) * 10 + (c[4] / 10) * 10
        // }
      } else {
        a.push(c);
      }
    }
    return a;
  }, []);
  return globalParZoneRow;
};

const writeOuvrageRows = async (
  dataSheet: Excel.Worksheet,
  OuvrageRows: any,
  startingRow: number
) => {
  for (let i = 0; i < OuvrageRows.length; i++) {
    // dataSheet.insertRow(startingRow + i, {}, "o+");

    dataSheet.mergeCells(`C${startingRow + i}:E${startingRow + i}`);
    writeToCellAndStyle(
      dataSheet,
      `C${startingRow + i}`,
      OuvrageRows[i][2],
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `F${startingRow + i}`,
      OuvrageRows[i][4],
      true
    );
    dataSheet.getRow(startingRow + i).font = {
      bold: false,
    };
    dataSheet.getRow(startingRow + i).getCell(startingRow).alignment = {
      wrapText: true,
    };
    /*
     * Since wrap text property does not work for merged cells,
     * the width of column P has been set to equal C + D + E.
     * Then the text is copied to column P, formatted and then hidden
     * by setting the color to white.
     */

    dataSheet.getRow(startingRow + i).getCell(`P`).value = OuvrageRows[i][2];
    dataSheet.getRow(startingRow + i).getCell(`P`).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    dataSheet.getRow(startingRow + i).getCell(`P`).font = {
      color: {
        argb: "FFFFFF",
      },
    };
    dataSheet.getRow(startingRow + i).alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
  }
};

const writeGlobalParLevel = async (
  dataSheet: Excel.Worksheet,
  OuvrageRows: any,
  startingRow: number
) => {
  for (let i = 0; i < OuvrageRows.length; i++) {
    // dataSheet.insertRow(startingRow + i, {}, "o+");

    let arr = OuvrageRows[i][3].split(";");
    // _.split(OuvrageRows[i][3], ';', 1);
    dataSheet.mergeCells(`D${startingRow + i}:E${startingRow + i}`);

    writeToCellAndStyle(
      dataSheet,
      `A${startingRow + i}`,
      OuvrageRows[i][0],
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `B${startingRow + i}`,
      OuvrageRows[i][1],
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `C${startingRow + i}`,
      OuvrageRows[i][2],
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `D${startingRow + i}`,
      // OuvrageRows[i][3],
      arr[0],
      true
    );
    writeToCellAndStyle(
      dataSheet,
      `F${startingRow + i}`,
      OuvrageRows[i][4],
      true
    );
    if (!OuvrageRows[i][1]) {
      dataSheet.mergeCells(`A${startingRow + i}:B${startingRow + i}`);
    }
    dataSheet.getRow(startingRow + i).font = {
      bold: false,
    };
    dataSheet.getRow(startingRow + i).getCell(startingRow).alignment = {
      wrapText: true,
    };
  }
};

const writeGlobalZones = async (
  dataSheet: Excel.Worksheet,
  ZonesRows: any,
  startingRow: number
) => {
  writeToCellAndStyle(dataSheet, `A${startingRow}`, "GLOBAL PAR ZONE", true);
  dataSheet.mergeCells(`A${startingRow}:I${startingRow}`);
  dataSheet.getRow(startingRow).getCell("A").alignment = {
    horizontal: "left",
    wrapText: true,
  };
  dataSheet.getRow(startingRow).getCell("A").font = {
    color: { argb: "FFFFFF" },
  };
  dataSheet.getRow(startingRow).getCell("A").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "005EB8",
    },
  };

  let ZonesHeader = [
    { letter: "A", value: "Zone" },
    { letter: "B", value: "Zone" },
    { letter: "C", value: "Ouvrage" },
    { letter: "D", value: "Référence interne \n (Système REVIT)" },
    { letter: "F", value: "S (m²)" },
    // { letter: "G", value: "Id du mur" },
    // { letter: "H", value: "H (m)" },
    // { letter: "I", value: "L (m)" },
  ];

  ZonesHeader.forEach((cell: any) => {
    let currCell = dataSheet.getCell(`${cell.letter}${startingRow + 1}`);
    currCell.value = cell.value;
    currCell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    currCell.alignment = {
      horizontal: "center",
      wrapText: true,
    };
    currCell.font = { bold: true, size: 11 };
  });
  dataSheet.mergeCells(`D${startingRow + 1}:E${startingRow + 1}`);
  dataSheet.mergeCells(`A${startingRow + 1}:B${startingRow + 1}`);

  for (let i = 0; i < ZonesRows.length; i++) {
    let cellArray = [
      { column: "A", data: ZonesRows[i][1] },
      { column: "B", data: ZonesRows[i][1] },
      { column: "C", data: ZonesRows[i][2] },
      { column: "D", data: ZonesRows[i][3] },
      { column: "F", data: ZonesRows[i][4] },
      // { column: "G", data: ZonesRows[i][5] },
      // { column: "H", data: ZonesRows[i][6] },
      // { column: "I", data: ZonesRows[i][7] },
    ];

    cellArray.forEach((e: any) => {
      let currCell = dataSheet.getCell(`${e.column}${startingRow + 2 + i}`);
      currCell.value = e.data;
      if (e.data === "NA") {
        currCell.value = "";
        currCell.style.fill = {
          type: "pattern",
          pattern: "lightUp",
          fgColor: {
            argb: "000000",
          },
          bgColor: {
            argb: "D9D9D9",
          },
        };
      }
      if (e.column === "D") {
        let value = joinWithSemiColon(e.data);
        currCell.value = value;
      }
      currCell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      currCell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      currCell.font = { bold: false, size: 11 };
    });
    dataSheet.mergeCells(`D${startingRow + 2 + i}:E${startingRow + 2 + i}`);
    dataSheet.mergeCells(`A${startingRow + 2 + i}:B${startingRow + 2 + i}`);
  }
};
const writeDetailsRows = async (
  dataSheet: Excel.Worksheet,
  detailRows: any,
  startingRow: number
) => {
  for (let i = 0; i < detailRows.length; i++) {
    let cellArray = [
      { column: "A", data: detailRows[i][0] },
      { column: "B", data: detailRows[i][1] },
      { column: "C", data: detailRows[i][2] },
      // { column: "D", data: detailRows[i][3] },
      // { column: "F", data: detailRows[i][4] },
      // { column: "G", data: detailRows[i][5] },
      // { column: "H", data: detailRows[i][6] },
      // { column: "I", data: detailRows[i][7] },
      { column: "D", data: detailRows[i][4] },
      { column: "F", data: detailRows[i][5] },
      { column: "G", data: detailRows[i][6] },
      { column: "H", data: detailRows[i][7] },
      { column: "I", data: detailRows[i][8] },
    ];

    cellArray.forEach((e: any) => {
      if (e.column === "D") {
        let combinedWalls = joinWithSemiColon(e.data);
        writeToCellAndStyle(
          dataSheet,
          `${e.column}${startingRow + i}`,
          combinedWalls,
          true
        );
      } else {
        writeToCellAndStyle(
          dataSheet,
          `${e.column}${startingRow + i}`,
          typeof e.data === "boolean" ? "" : e.data,
          true
        );
      }
    });
    dataSheet.mergeCells(`D${startingRow + i}:E${startingRow + i}`);
    if (!detailRows[i][1]) {
      dataSheet.mergeCells(`A${startingRow + i}:B${startingRow + i}`);
    }
    dataSheet.getRow(startingRow + i).font = {
      bold: false,
    };
  }
};

export const addLogoToSheet = async (
  workbook: Excel.Workbook,
  worksheets: any[],
  config: any
) => {
  const logoImageId = await addImagetoWorkbook(workbook, config);
  worksheets.forEach((e: any) => {
    logoImageId !== 0 &&
      e.addImage(logoImageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 150, height: 110 },
      });
  });
};

export const writeData = async (
  workbook: Excel.Workbook,
  filteredTree: any,
  config: any
) => {
  // Walls Sheet
  const dataSheet = getWorksheet(workbook, "Métrés_Cloisons");
  // Ceilings Sheet
  const ceilingDataSheet = getWorksheet(workbook, "Métrés_Plafonds");

  // Add logo to Walls and Ceilings Sheet
  addLogoToSheet(workbook, [dataSheet, ceilingDataSheet], config);

  // Extract Walls Data
  const { wallsDataCloned, summaryRow } = await extractData(filteredTree);
  // const { mainRows, summaryRow } = await extractData(filteredTree);
  //Extract Ceilings Data
  const { ceilingDataCloned, summaryCeilingRow } = await extractCeilingsData(
    filteredTree
  );
  // Below data are common for Walls and Ceilings
  let startingPointForOverageTable = 17;
  const ifSelectionsAreZone = isSelectionsAreZones(filteredTree);

  //Get Walls Rows
  const OverageRows = getOuvrageRows(summaryRow);

  // Get Ceilings Rows
  const OverageCeilingRows = getOuvrageRows(summaryCeilingRow);

  // Get Global Par Niveau Walls
  let GlobalParLevelStartingPoint =
    startingPointForOverageTable + OverageRows.length + 3;

  // Get Global Par Niveau Cielings
  let CeilingsGlobalParLevelStartingPoint =
    startingPointForOverageTable + OverageCeilingRows.length + 3;

  // Starting point for Walls Sheet
  let DetailsTableStartingPoint =
    GlobalParLevelStartingPoint + summaryRow.length + 3;
  // let DetailsTableStartingPoint =
  //   startingPointForOverageTable + OverageRows.length + 3;

  //Starting Point for Ceiling Sheet
  let CeilingsDetailsTableStartingPoint =
    CeilingsGlobalParLevelStartingPoint + summaryCeilingRow.length + 3;
  // let CeilingsDetailsTableStartingPoint =
  //   startingPointForOverageTable + OverageCeilingRows.length + 3;

  // title for walls sheet
  const titleCell = dataSheet.getCell("C3");
  titleCell.value = "Métrés Cloisons";
  dataSheet.mergeCells("C3:F4");
  titleCell.alignment = {
    horizontal: "center",
  };
  titleCell.font = { bold: true, size: 16 };

  // title for Ceilings sheet
  const CeilingTitleCell = ceilingDataSheet.getCell("C3");
  CeilingTitleCell.value = "Métrés Plafonds";
  ceilingDataSheet.mergeCells("C3:F4");
  CeilingTitleCell.alignment = {
    horizontal: "center",
  };
  CeilingTitleCell.font = { bold: true, size: 16 };

  //Add Ouvrage Rows to Walls
  writeOuvrageRows(dataSheet, OverageRows, startingPointForOverageTable);

  //Add OuvrageRows to Ceiling Sheet
  writeOuvrageRows(
    ceilingDataSheet,
    OverageCeilingRows,
    startingPointForOverageTable
  );

  if (ifSelectionsAreZone) {
    // Walls Sheet
    const GetGlobalZonesRows = getGlobalZonesRows(summaryRow);
    writeGlobalZones(
      dataSheet,
      GetGlobalZonesRows,
      startingPointForOverageTable + OverageRows.length + 1
    );
    GlobalParLevelStartingPoint =
      GlobalParLevelStartingPoint + GetGlobalZonesRows.length + 3;
    DetailsTableStartingPoint =
      DetailsTableStartingPoint + GetGlobalZonesRows.length + 3;

    //Ceiling Sheet
    const GetGlobalCeilingZonesRows = getGlobalZonesRows(summaryCeilingRow);
    writeGlobalZones(
      ceilingDataSheet,
      GetGlobalCeilingZonesRows,
      startingPointForOverageTable + OverageCeilingRows.length + 1
    );
    CeilingsGlobalParLevelStartingPoint =
      CeilingsGlobalParLevelStartingPoint +
      GetGlobalCeilingZonesRows.length +
      3;
    CeilingsDetailsTableStartingPoint =
      CeilingsDetailsTableStartingPoint + GetGlobalCeilingZonesRows.length + 3;
  }

  //Add GlobalParLevelRows to Walls Sheet
  writeGlobalParLevel(dataSheet, summaryRow, GlobalParLevelStartingPoint);
  writeGlobalParLevel(
    ceilingDataSheet,
    summaryCeilingRow,
    CeilingsGlobalParLevelStartingPoint
  );

  //Wall
  writeDetailsRows(dataSheet, wallsDataCloned, DetailsTableStartingPoint);

  //Ceiling
  writeDetailsRows(
    ceilingDataSheet,
    ceilingDataCloned,
    CeilingsDetailsTableStartingPoint
  );

  // Add Heading to Global Level Walls
  addGlobalLevelHeader(
    dataSheet,
    GlobalParLevelStartingPoint - 2,
    ifSelectionsAreZone
  );

  // Add Heading to Global Level Ceilings
  addGlobalLevelHeader(
    ceilingDataSheet,
    CeilingsGlobalParLevelStartingPoint - 2,
    ifSelectionsAreZone
  );

  //Add Heading to Walls
  addDetailsArrayHeader(
    dataSheet,
    DetailsTableStartingPoint - 2,
    ifSelectionsAreZone
  );

  //Add Heading to Ceiling
  addCeilingDetailsArrayHeader(
    ceilingDataSheet,
    CeilingsDetailsTableStartingPoint - 2,
    ifSelectionsAreZone
  );

  //Add Project Data info to Walls Sheet
  await addProjectInfo(dataSheet, config);

  //Add Project Data info to Ceilings Sheet
  await addProjectInfo(ceilingDataSheet, config);
};

export const isSelectionsAreZones = (filteredTree: any[]) => {
  return filteredTree.every((e: any) => e.Zone);
};

export const addImagetoWorkbook = async (
  excelBook: Excel.Workbook,
  config: any
) => {
  let tempProjectInfo: any = await bimStorage.getItem(storageKey.PROJECT_INFO);
  var data = tempProjectInfo === null ? {} : tempProjectInfo;
  if (!isEmpty(data)) {
    let logo = data.PROJECT_INFO_FORM.companyInfo.information_enterprise_logo;
    if (!logo.includes("/html", 0)) {
      const resizedImg = await resizeFile(b64toBlob(logo));
      const imageId = excelBook.addImage({
        base64: resizedImg ? resizedImg : logo,
        extension: "png",
      });
      return imageId;
    }
    return 0;
  } else {
    return 0;
  }
};

const b64toBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      110,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const getWorksheet = (workbook: Excel.Workbook, name: string) => {
  return workbook.getWorksheet(name);
};

export const extractData = async (tree: any) => {
  let nbLevels = tree.length;
  let detailRows: any = [];
  let levelRows: any = {};
  let wallSolArr: any = [];
  let wallsDataCloned: any = [];
  let mainArr: any = [];
  let wallsData: any = [];

  for (let i = 0; i < nbLevels; i++) {
    let elems = tree[i].walls;
    let nb_types = elems.length;
    let level = tree[i].level;

    for (let j = 0; j < nb_types; j++) {
      let ids = elems[j].ids;
      let type = elems[j].type;
      let Solution = elems[j].Solution;

      if (j === 0) levelRows[level] = {};

      if (ids.length > 0) {
        wallsData = await api.queries.getWallsData(ids);
        const solutionData = await api.queries.getObjectsParams(ids, [
          "SG_System",
        ]);
        let wallSolutions = getWallsWithSolutionsData(wallsData, solutionData);
        wallSolutions.Solution = Solution;
        let rows = await detailMainBuilder(
          wallSolutions,
          type,
          tree[i].level,
          tree[i].Name,
          tree[i].Zone
        );
        detailRows = detailRows.concat(rows); //details data

        let arr = wallSolutions.filter((a: any) => a.solution !== " ");
        if (arr.length > 0) {
          wallSolArr = wallSolArr.concat(arr);
        }
      }
    }
  }

  //for sorting data level wise, arranged in [key:value] pair
  detailRows.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      ouvrage: wall[2],
      solution: wall[3],
      type: wall[4],
      area: wall[5],
      id: wall[6],
      height: wall[7],
      length: wall[8],
    });
  });

  //data sorted
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  //removing [key:value] pair and pushed it to another array
  mainArr.forEach((wall: any) => {
    wallsDataCloned.push([
      wall.level,
      wall.zone,
      wall.ouvrage,
      wall.solution,
      wall.type,
      wall.area,
      wall.id,
      wall.height,
      wall.length,
    ]);
  });

  let mainRows: any = await detailMainBodyBuilder(detailRows);
  let summaryRow: any = await detailSummaryBuilder(mainRows);
  return {
    // detailRows: mainRows,
    wallsDataCloned,
    levelRows,
    summaryRow,
  };
};

export const extractCeilingsData = async (tree: any) => {
  let nbLevels = tree.length;
  let detailRows: any = [];
  let levelRows: any = {};
  let ceilingSolArr: any = [];
  let ceilingDataCloned: any = [];
  let mainArr: any = [];

  for (let i = 0; i < nbLevels; i++) {
    let elems = tree[i].ceilings;
    let nb_types = elems.length;
    let level = tree[i].level;

    for (let j = 0; j < nb_types; j++) {
      let ids = elems[j].ids;
      let type = elems[j].type;
      let Solution = elems[j].Solution;

      if (j === 0) levelRows[level] = {};

      if (ids.length > 0) {
        let ceilingsData = await api.queries.getCeilingsData(ids);
        const solutionData = await api.queries.getObjectsParams(ids, [
          "SG_System",
        ]);

        let ceilingSolutions = getWallsWithSolutionsData(
          ceilingsData,
          solutionData
        );
        ceilingSolutions.Solution = Solution;
        let rows = await detailCeilingsMainBuilder(
          ceilingSolutions,
          type,
          tree[i].level,
          tree[i].Name,
          tree[i].Zone
        );
        detailRows = detailRows.concat(rows);

        let arr = ceilingSolutions.filter((a: any) => a.solution !== " ");
        if (arr.length > 0) {
          ceilingSolArr = ceilingSolArr.concat(arr);
        }
      }
    }
  }
  //for sorting data level wise, arranged in [key:value] pair
  detailRows.forEach((ceiling: any) => {
    mainArr.push({
      level: ceiling[0],
      zone: ceiling[1],
      ouvrage: ceiling[2],
      solution: ceiling[3],
      type: ceiling[4],
      area: ceiling[5],
      id: ceiling[6],
      perimeter: ceiling[7],
      height: ceiling[8],
    });
  });

  //data sorted
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);

  //removing [key:value] pair and pushed it to another array
  mainArr.forEach((ceiling: any) => {
    ceilingDataCloned.push([
      ceiling.level,
      ceiling.zone,
      ceiling.ouvrage,
      ceiling.solution,
      ceiling.type,
      ceiling.area,
      ceiling.id,
      ceiling.perimeter,
      ceiling.height,
    ]);
  });
  let mainRows: any = await detailMainBodyBuilder(detailRows);
  let summaryRow: any = await detailSummaryBuilder(mainRows);
  return {
    //detailCeilingsRows: detailRows,
    ceilingDataCloned,
    levelRows,
    summaryCeilingRow: summaryRow,
  };
};

export const detailSummaryBuilder = async (wallsData: any) => {
  return wallsData.filter((e: any) => e[e.length - 1] === "NA");
};

export const detailMainBuilder = async (
  wallsData: any,
  type: any,
  level: any,
  Name?: any,
  Zone?: any
) => {
  const wallTree = await api.selection.elementsByLevelAndType("wall");
  const FindLevel = (wallID: string) => {
    const wall: any = Object.values(wallTree.Tree).find((e: any) => {
      return e.Elements.some((a: any) => {
        return a.Ids.includes(wallID);
      });
    });
    return wall.Level.Name;
  };
  let ZoneName = Zone ? Name : "";
  let rows: any = [];
  let Ouvrage = wallsData.Solution;
  wallsData.forEach((wall: any) => {
    rows.push([
      FindLevel(wall.Id),
      ZoneName,
      Ouvrage,
      wall.solution,
      type,
      Math.round(wall.Area * 100) / 100,
      parseInt(wall.Id),
      ((wall.Height as number) / 1000).toFixed(2),
      (wall.Length / 1000).toFixed(2),
    ]);
  });
  return rows;
};

export const detailCeilingsMainBuilder = async (
  ceilingsData: any,
  type: any,
  level: any,
  Name?: any,
  Zone?: any
) => {
  const ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
  const FindLevel = (ceilingID: string) => {
    const wall: any = Object.values(ceilingTree.Tree).find((e: any) => {
      return e.Elements.some((a: any) => {
        return a.Ids.includes(ceilingID);
      });
    });

    return wall.Level.Name;
  };
  let ZoneName = Zone ? Name : "";
  let rows: any = [];
  let Ouvrage = ceilingsData.Solution;
  ceilingsData.forEach((ceiling: any) => {
    rows.push([
      FindLevel(ceiling.Id),
      ZoneName,
      Ouvrage,
      ceiling.solution,
      type,
      Math.round(ceiling.Area * 100) / 100,
      parseInt(ceiling.Id),
      ceiling.Perimeter.toFixed(2),
      ceiling.Height.toFixed(2),
    ]);
  });
  return rows;
};

export const detailMainBodyBuilder = async (wallsData: any) => {
  let mainArr: any = [];
  wallsData.forEach((wall: any) => {
    mainArr.push({
      level: wall[0],
      zone: wall[1],
      ouvrage: wall[2],
      solution: wall[3],
      type: wall[4],
      area: wall[5],
      id: wall[6],
      height: wall[7],
      length: wall[8],
    });
  });
  mainArr = orderBy(mainArr, ["level", "type", "area"], ["asc", "asc", "desc"]);
  const clonedMainArr = _.cloneDeep(mainArr);

  let levelArr: any = [...new Set(clonedMainArr.map((a: any) => a.level))];
  let levelWiseArr: any = {};
  levelArr.forEach((e: any) => {
    levelWiseArr[e] = clonedMainArr.filter((a: any) => {
      return a.level === e;
    });
  });

  let ZoneArr: any = [...new Set(clonedMainArr.map((a: any) => a.zone))];
  let OuvrageArr: any = [...new Set(clonedMainArr.map((a: any) => a.ouvrage))];

  let ZoneWiseHeader: any = {};
  let clonedZoneWiseArr = _.cloneDeep(levelWiseArr);
  for (const [level1, rows1] of Object.entries(clonedZoneWiseArr)) {
    const newRows: any = rows1;
    ZoneArr.forEach((e: any) => {
      ZoneWiseHeader[level1] = ZoneWiseHeader[level1] ?? {};
      ZoneWiseHeader[level1][e] = newRows.filter((l: any) => {
        return l.zone === e;
      });

      let obj: any = {};
      OuvrageArr.forEach((o: any) => {
        obj[o] = ZoneWiseHeader[level1][e].filter((f: any) => {
          return f.ouvrage === o;
        });
        if (obj[o].length === 0) {
          delete obj[o];
        } else {
          obj[o] = ZoneWiseHeader[level1][e].reduce((a: any, c: any) => {
            if (o === c.ouvrage) {
              if (a.length === 0) {
                a = c;
              } else {
                a.type = a.type + ";" + c.type;
                a.area = a.area + c.area;
              }
            }
            return a;
          }, []);
        }
      });

      ZoneWiseHeader[level1][e] = obj;
    });
  }

  let rows: any = [];
  levelArr.forEach((e: any) => {
    Object.values(Object.values(ZoneWiseHeader[e])).forEach((e: any) => {
      Object.values(e).forEach((data: any) => {
        rows.push([
          data.level,
          data.zone,
          data.ouvrage,
          data.type,
          data.area,
          "NA",
          "NA",
          "NA",
        ]);
      });
    });
    levelWiseArr[e].forEach((data: any) => {
      rows.push([
        data.level,
        data.zone,
        data.ouvrage,
        data.type,
        data.area,
        data.id,
        data.height,
        data.length,
      ]);
    });
  });
  return rows;
};

const getWallsWithSolutionsData = (wallsData: any, solutionData: any) => {
  wallsData.forEach((wall: any) => {
    let data = solutionData.find((a: any) => a.Id === wall.Id)?.Params[0];
    let solution = data === undefined ? null : data.Value;
    wall.solution = solution === null ? " " : getSolutionName(solution);
  });
  return wallsData;
};

const getSolutionName = (solution: string) => {
  let solutionname: string = "";
  let solArr: string[] = solution.split("-");
  for (let i = 1; i < solArr.length; i++) {
    solutionname += solArr[i];
  }
  return solutionname;
};

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
