import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Grid, Segment, Button } from "semantic-ui-react";
import { LevelsColumn } from "../../../../Components/Selector/LevelsColumn";
import { TypesColumn } from "../../../../Components/Selector/TypesColumn";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
    selectLevel,
    selectAllLevels,
    selectType,
    selectAllTypes,
    initMappingTable,
    initMappingTableCeilings,
    selectTypeCeilings,
    selectAllTypesCeilings,
    selectLevelCeilings,
    selectAllLevelsCeilings,
} from "../Actions/index";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { WithTranslation, withTranslation } from "react-i18next";
import Tab from "./Tab";

interface Props {
    selectorType: string;
    Icon: string;
    levelsData: LevelData[];
    typesData: TypeData[];
    typesDataCeilings: TypeData[];
    levelsDataCeilings: LevelData[];
    selectedLevelsCeilings: string[];
    selectedTypesCeilings: string[];
    routes: any;
    selectedLevels: string[];
    selectedTypes: string[];
    selectLevel: any;
    selectAllLevels: Function;
    selectType: any;
    selectAllTypes: Function;
    selectAllTypesCeilings: Function;
    selectLevelCeilings: Function,
    selectAllLevelsCeilings: Function,
    setRoute: any;
    initMappingTable: any;
    initMappingTableCeilings: any
    selectTypeCeilings: Function
}

interface State { }

export class GroupSelector extends React.Component<
    Props & WithTranslation,
    State
> {
    componentDidMount = () => {
        api.windowsHandler.resizeWindow(900, 600);
    };

    state = {};

    // public static defaultProps = {
    //   wording: {
    //     levels: {
    //       French: "Niveaux",
    //       English: "Levels",
    //       German: "Ebenen",
    //       Italian: "Livelli",
    //     },
    //     ceilingTypes: {
    //       French: "Types de plafonds",
    //       English: "Ceiling types",
    //       German: "Deckentypen",
    //       Italian: "Tipi di soffito",
    //     },
    //     wallTypes: {
    //       French: "Types de murs",
    //       English: "Wall types",
    //       German: "Wandtypen",
    //       Italian: "Tipi di muro",
    //     },
    //     selection: {
    //       French: "Sélection",
    //       English: "Selection",
    //       German: "Auswahl",
    //       Italian: "Selezione",
    //     },
    //     back: {
    //       French: "Retour",
    //       English: "Back",
    //       German: "Zurück",
    //       Italian: "Indietro",
    //     },
    //     validate: {
    //       French: "Valider",
    //       English: "Validate",
    //       German: "Bestätigen",
    //       Italian: "Convalidare",
    //     },
    //   },
    // };

    selectorTypes = () => {
        const { t } = this.props;

        switch (this.props.selectorType) {
            case "wall":
                return t("wallTypes");
            case "ceiling":
                return t("CeilingTypes");
            default:
                return t("wallTypes");
        }
    };

    backHandler = () => {
        this.props.setRoute(Routes.ROOT);
    };

    validateHandler = () => {
        if (this.props.selectedTypes.length) {
            this.props.initMappingTable();
            this.props.initMappingTableCeilings();
            this.props.setRoute(Routes.MAPPING);
        }
    };

    render() {
        const { t } = this.props;

        return (
            <div id="draw2-solutionMapping-container" className="rigipsContainer">
                <FunctionalityHeader
                    Icon={this.props.Icon}
                    name={t("selection")}
                    width={80}
                    height={30}
                />
                <div style={{ padding: 5 }}>
                    <div style={{ width: "100%", backgroundColor: "none" }}>
                        <Tab
                            tabWallName={t("wall")}
                            tabWallContent={
                                <div style={{ margin: "auto" }}>
                                    <Grid
                                        columns={2}
                                        stackable
                                        style={{
                                            backgroundColor: "none",
                                        }}
                                        container
                                        centered
                                    >
                                        <LevelsColumn
                                            selectLevel={this.props.selectLevel}
                                            selectAllLevels={this.props.selectAllLevels}
                                            columnTitle={t("levels")}
                                            levelsData={this.props.levelsData}
                                            selectedLevels={this.props.selectedLevels}
                                            color="blue"
                                        />
                                        <TypesColumn
                                            selectType={this.props.selectType}
                                            selectAllTypes={this.props.selectAllTypes}
                                            selectedLevels={this.props.selectedLevels}
                                            columnTitle={this.selectorTypes()}
                                            color="blue"
                                            typesData={this.props.typesData}
                                        />
                                    </Grid>
                                </div>
                            }
                            tabCeilingName={t("ceiling")}
                            tabCeilingContent={
                                <div style={{ margin: "auto" }}>
                                    <Grid
                                        columns={2}
                                        stackable
                                        style={{
                                            backgroundColor: "none",
                                        }}
                                        container
                                        centered
                                    >
                                        <LevelsColumn
                                            selectLevel={this.props.selectLevelCeilings}
                                            selectAllLevels={this.props.selectAllLevelsCeilings}
                                            columnTitle={t("levels")}
                                            levelsData={this.props.levelsDataCeilings}
                                            selectedLevels={this.props.selectedLevelsCeilings}
                                            color="blue"
                                        />
                                        <TypesColumn
                                            selectType={this.props.selectTypeCeilings}
                                            selectAllTypes={this.props.selectAllTypesCeilings}
                                            selectedLevels={this.props.selectedLevelsCeilings}
                                            columnTitle={t("CeilingTypes")}
                                            color="blue"
                                            typesData={this.props.typesDataCeilings}
                                        />
                                    </Grid>
                                </div>
                            } />
                        <Segment basic>
                            <Button
                                onClick={this.backHandler}
                                floated="left"
                                primary
                            >
                                {t("back")}
                            </Button>
                            <Button
                                onClick={this.validateHandler}
                                disabled={this.props.selectedTypes.length === 0 && this.props.selectedTypesCeilings.length === 0}
                                floated="right"
                                primary
                            >
                                {t("validate")}
                            </Button>
                        </Segment>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        Icon: state.functionalityIcon,
        levelsData: state.levelsData,
        typesData: state.typesData,
        selectedLevels: state.selectedLevels,
        selectedTypes: state.selectedTypes,
        levelsDataCeilings: state.levelsDataCeilings,
        typesDataCeilings: state.typesDataCeilings,
        selectedLevelsCeilings: state.selectedLevelsCeilings,
        selectedTypesCeilings: state.selectedTypesCeilings,
        name: ownProps.name,
        setRoute: ownProps.setRoute,
    };
};

export default connect(mapStateToProps, {
    selectType,
    selectAllTypes,
    selectLevel,
    selectAllLevels,
    selectTypeCeilings,
    selectAllTypesCeilings,
    selectLevelCeilings,
    selectAllLevelsCeilings,
    initMappingTable,
    initMappingTableCeilings,
})(withTranslation("riggibs")(GroupSelector));
