import {
  IPAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY,
  PAM_ATTRIBUTES_KEYS,
  PAM_CATEGORIES_KEYS,
  PAM_CHAPTER_KEYS,
} from "./types";

export const PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY: IPAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY =
{
  WALL: [
    {
      categoryName: PAM_CATEGORIES_KEYS.GENERAL_DATA,
      pamData: [
        PAM_ATTRIBUTES_KEYS.GENERATION_DATE,
        PAM_ATTRIBUTES_KEYS.DESCRIPTION,
        PAM_ATTRIBUTES_KEYS.SYSTEM_ID,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.SYSTEM,
      categoryName: PAM_CATEGORIES_KEYS.INFORMATION,
      pamData: [
        PAM_ATTRIBUTES_KEYS.CLASS,
        PAM_ATTRIBUTES_KEYS.TYPE,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.TECHNICAL,
      categoryName: PAM_CATEGORIES_KEYS.SYSTEM_INFORMATION,
      pamData: [
        
        PAM_ATTRIBUTES_KEYS.TOTAL_NUMBER_LAYERS,
        PAM_ATTRIBUTES_KEYS.THICKNESS,
        PAM_ATTRIBUTES_KEYS.WEIGHT,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.TECHNICAL,
      categoryName: PAM_CATEGORIES_KEYS.TECHNICAL_DATA,
      pamData: [
        PAM_ATTRIBUTES_KEYS.STANDARD,
        PAM_ATTRIBUTES_KEYS.MANUFACTURERS_CERTIFICATE,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.TECHNICAL,
      categoryName: PAM_CATEGORIES_KEYS.SAFETY_AND_REGULATION,
      pamData: [
        PAM_ATTRIBUTES_KEYS.GREEN_BUILDING_CERTIFICATE,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.PRODUCT_INFO,
      categoryName: PAM_CATEGORIES_KEYS.MANUFACTURERS,
      pamData: [
        PAM_ATTRIBUTES_KEYS.COUNTRY_ORIGIN,
        PAM_ATTRIBUTES_KEYS.MANUFACTURERS_OR_SUPPLIER,
        PAM_ATTRIBUTES_KEYS.ADDRESS,
        PAM_ATTRIBUTES_KEYS.CONTACT,
        PAM_ATTRIBUTES_KEYS.EMAIL,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.PRODUCT_INFO,
      categoryName: PAM_CATEGORIES_KEYS.LINKS,
      pamData: [
        PAM_ATTRIBUTES_KEYS.WEBSITE,
        PAM_ATTRIBUTES_KEYS.VIDEO_APPLICATION,
        PAM_ATTRIBUTES_KEYS.STORE_LOCATOR,
      ],
    },
  ],
  FLOOR: [
    {
      categoryName: PAM_CATEGORIES_KEYS.GENERAL_DATA,
      pamData: [
        PAM_ATTRIBUTES_KEYS.GENERATION_DATE,
        PAM_ATTRIBUTES_KEYS.DESCRIPTION,
        PAM_ATTRIBUTES_KEYS.SYSTEM_ID,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.SYSTEM,
      categoryName: PAM_CATEGORIES_KEYS.INFORMATION,
      pamData: [
        PAM_ATTRIBUTES_KEYS.CLASS,
        PAM_ATTRIBUTES_KEYS.TYPE,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.TECHNICAL,
      categoryName: PAM_CATEGORIES_KEYS.SYSTEM_INFORMATION,
      pamData: [
        PAM_ATTRIBUTES_KEYS.TOTAL_NUMBER_LAYERS,
        PAM_ATTRIBUTES_KEYS.THICKNESS,
        PAM_ATTRIBUTES_KEYS.WEIGHT,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.TECHNICAL,
      categoryName: PAM_CATEGORIES_KEYS.TECHNICAL_DATA,
      pamData: [
        PAM_ATTRIBUTES_KEYS.STANDARD,
        PAM_ATTRIBUTES_KEYS.MANUFACTURERS_CERTIFICATE,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.TECHNICAL,
      categoryName: PAM_CATEGORIES_KEYS.SAFETY_AND_REGULATION,
      pamData: [
        PAM_ATTRIBUTES_KEYS.GREEN_BUILDING_CERTIFICATE,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.PRODUCT_INFO,
      categoryName: PAM_CATEGORIES_KEYS.MANUFACTURERS,
      pamData: [
        PAM_ATTRIBUTES_KEYS.COUNTRY_ORIGIN,
        PAM_ATTRIBUTES_KEYS.MANUFACTURERS_OR_SUPPLIER,
        PAM_ATTRIBUTES_KEYS.ADDRESS,
        PAM_ATTRIBUTES_KEYS.CONTACT,
        PAM_ATTRIBUTES_KEYS.EMAIL,
      ],
    },
    {
      chapterName: PAM_CHAPTER_KEYS.PRODUCT_INFO,
      categoryName: PAM_CATEGORIES_KEYS.LINKS,
      pamData: [
        PAM_ATTRIBUTES_KEYS.WEBSITE,
        PAM_ATTRIBUTES_KEYS.VIDEO_APPLICATION,
        PAM_ATTRIBUTES_KEYS.STORE_LOCATOR,
      ],
    },
  ],

};

export const PAM_LAYER_ATTRIBUTES = [
  PAM_ATTRIBUTES_KEYS.LAYER_CONTENT,
  PAM_ATTRIBUTES_KEYS.LAYER_NUMBER,
  PAM_ATTRIBUTES_KEYS.LAYER_NAME,
  PAM_ATTRIBUTES_KEYS.LAYER_THIKNESS,
  PAM_ATTRIBUTES_KEYS.LAYER_COLOR,
  PAM_ATTRIBUTES_KEYS.LAYER_DESCRIPTION,
  PAM_ATTRIBUTES_KEYS.LAYER_ELEMENT_TYPE,

  PAM_ATTRIBUTES_KEYS.PRODUCT_NAME,
  PAM_ATTRIBUTES_KEYS.PRODUCT_DESCRIPTION,
  PAM_ATTRIBUTES_KEYS.MANUFACTURER,
  PAM_ATTRIBUTES_KEYS.COST,
  PAM_ATTRIBUTES_KEYS.WEBSITE_LINK,
  PAM_ATTRIBUTES_KEYS.APPEARANCE_COLOR,
  PAM_ATTRIBUTES_KEYS.BIM_THREED_TEXTURE,
  PAM_ATTRIBUTES_KEYS.TWOD_TEXTURE_PATTERN,

  PAM_ATTRIBUTES_KEYS.APPLICATION_THICKNESS,
  PAM_ATTRIBUTES_KEYS.CONSUMPTION,
  PAM_ATTRIBUTES_KEYS.ADHESION_STRENGTH,
  PAM_ATTRIBUTES_KEYS.COMPRESSIVE_STRENGTH,
  PAM_ATTRIBUTES_KEYS.FLEXURAL_STRENGTH,
  PAM_ATTRIBUTES_KEYS.ABRASIVE_RESISTANCE,
  PAM_ATTRIBUTES_KEYS.WATER_ADDITION,
  PAM_ATTRIBUTES_KEYS.PACKAGING,
  PAM_ATTRIBUTES_KEYS.SHRINKAGE,
  PAM_ATTRIBUTES_KEYS.FRESH_DENSITY,
  PAM_ATTRIBUTES_KEYS.POT_LIFE,
  //PAM_ATTRIBUTES_KEYS.IFC_TYPE,
  //PAM_ATTRIBUTES_KEYS.SYSTEM_URL
];

export const GENERIC_ATTRIBUTES_TO_REMOVE = [
  // PAM_ATTRIBUTES_KEYS.SYSTEM_DESCRIPTION,
  // PAM_ATTRIBUTES_KEYS.SYSTEM_SHEET,
  // PAM_ATTRIBUTES_KEYS.MAIN_MANUFACTURER,
  // PAM_ATTRIBUTES_KEYS.MANUFACTURER_INFO_WEBSITE,
  // PAM_ATTRIBUTES_KEYS.FDES_URL,

  PAM_ATTRIBUTES_KEYS.TOTAL_NUMBER_LAYERS,
  PAM_ATTRIBUTES_KEYS.WEIGHT,
  PAM_ATTRIBUTES_KEYS.STANDARD,
  PAM_ATTRIBUTES_KEYS.MANUFACTURERS_CERTIFICATE,
  PAM_ATTRIBUTES_KEYS.WEBSITE,
];

export const SHARED_ATTRIBUTES = [
  PAM_ATTRIBUTES_KEYS.NAME_OF_INSULATION,
  PAM_ATTRIBUTES_KEYS.TYPE_OF_INSULATION,
  PAM_ATTRIBUTES_KEYS.INSULATION_THICKNESS_MM,
];

export const MATERIAL_PRODUCT_ATRRIBUTES = [
  PAM_ATTRIBUTES_KEYS.PRODUCT_NAME,
  PAM_ATTRIBUTES_KEYS.PRODUCT_DESCRIPTION,
  PAM_ATTRIBUTES_KEYS.MANUFACTURER,
  PAM_ATTRIBUTES_KEYS.COST,
  PAM_ATTRIBUTES_KEYS.WEBSITE_LINK,
  PAM_ATTRIBUTES_KEYS.APPEARANCE_COLOR,
  PAM_ATTRIBUTES_KEYS.BIM_THREED_TEXTURE,
  PAM_ATTRIBUTES_KEYS.TWOD_TEXTURE_PATTERN,
];

export const MATERIAL_CUSTOM_PARAMETER_ATRRIBUTES = [
  PAM_ATTRIBUTES_KEYS.APPLICATION_THICKNESS,
  PAM_ATTRIBUTES_KEYS.CONSUMPTION,
  PAM_ATTRIBUTES_KEYS.ADHESION_STRENGTH,
  PAM_ATTRIBUTES_KEYS.COMPRESSIVE_STRENGTH,
  PAM_ATTRIBUTES_KEYS.FLEXURAL_STRENGTH,
  PAM_ATTRIBUTES_KEYS.ABRASIVE_RESISTANCE,
  PAM_ATTRIBUTES_KEYS.WATER_ADDITION,
  PAM_ATTRIBUTES_KEYS.PACKAGING,
  PAM_ATTRIBUTES_KEYS.SHRINKAGE,
  PAM_ATTRIBUTES_KEYS.FRESH_DENSITY,
  PAM_ATTRIBUTES_KEYS.POT_LIFE,
];

export const PAM_ATTRIBUTES_TECHNICAL_NAME = {
  //GENERAL DATA
  DESCRIPTION: "A-Solution product name",

  //SYSTEM INFORMATION
  CLASS: "WID-Class",
  TYPE: "WID-Type",
  TOTAL_NUMBER_LAYERS: "WID-Layers Total Number",
  THICKNESS: "WID-Total Thickness",
  WEIGHT: "WID-System Weight",

  //TECHNICAL DATA
  STANDARD: "WID-System Certified Standard",
  MANUFACTURERS_CERTIFICATE: "A-Certificate name",

  //SAFETY AND REGULATION
  GREEN_BUILDING_CERTIFICATE: "WID-Green Building Certificate",

  //MANUFACTURES
  COUNTRY_ORIGIN: "A-Country of Origin",
  MANUFACTURERS_OR_SUPPLIER: "A-Manufacturer",
  ADDRESS: "WID-Address",
  CONTACT: "WID-Contact",
  EMAIL: "WID-Email",

  //LINKS
  WEBSITE: "WID-Website Link",
  VIDEO_APPLICATION: "A-Link to video",
  STORE_LOCATOR: "WID-Store Locator",

  //ADDITIONAL PARAMETERS
  ACTIVITY: "WID-Activity",
  AREA_OF_APPLICATION: "WID-Area of application",
  SUB_CATEGORY: "WID-SubCategory",
  SYSTEM_NAME: "A-System Name",
  TOTAL_THICKNESS: "WID-Total Thickness",
  BIM_LAYERS: "WID-BIM Layers",

  LAYER_NUMBER: "WID-Layer Number",
  LAYER_CONTENT: "WID-Layer Content",
  LAYER_NAME: "WID-Layer Name",
  LAYER_DESCRIPTION: "WID-Layer Description",
  LAYER_ELEMENT_TYPE: "WID-Element Type",
  LAYER_THIKNESS: "WID-Layer Thickness",
  LAYER_COLOR: "WID-Layer Color",
  LAYER_DATA: "WID-Layer",

  SYSTEM_ID: "A-PIM ID",

  PRODUCT_NAME: "A-Product Name",
  PRODUCT_DESCRIPTION: "A-Product Description",
  MANUFACTURER: "A-Manufacturer",
  COST: "WID-Cost",
  WEBSITE_LINK: "WID-Website Link",
  APPEARANCE_COLOR: "W-Color",
  BIM_THREED_TEXTURE: "BIM 3D texture",
  TWOD_TEXTURE_PATTERN: "WID-Revit 2D texture",

  APPLICATION_THICKNESS: "W-Application Thickness",
  CONSUMPTION: "W-Consumption",
  ADHESION_STRENGTH: "WID-Adhesion strength",
  COMPRESSIVE_STRENGTH: "WID-Compressive strength",
  FLEXURAL_STRENGTH: "W-Flexural Strength",
  ABRASIVE_RESISTANCE: "W-Abrasive Resistance",
  WATER_ADDITION: "W-Water addition",
  PACKAGING: "WID-Packaging",
  SHRINKAGE: "W-Shrinkage",
  FRESH_DENSITY: "W-Fresh density",
  POT_LIFE: "W-Pot life",


  //old
  GENERATION_DATE: "GENERATION_DATE",
  SYSTEM_GENERIC_NAME: "GFR-Generic name of the solution product",
  //SYSTEM_NAME: "A-Solution product name",
  SOLUTION_WIDTH: "A-Solution Width",
  //THICKNESS: "GFR-Total thickness in mm",
  SYSTEM_DESCRIPTION: "GFR-Solution product description",
  SYSTEM_URL: "GFR-Product datasheet link",
  MAIN_MANUFACTURER: "A-Manufacturer",
  MANUFACTURER_INFO_WEBSITE: "A-Website URL",
  //SYSTEM_ID: "A-PIM ID",
  NUMBER_OF_LAYERS: "GFR-Total number of layers",
  NAME_OF_THE_FACING: "GFR-Name cover",
  TYPE_OF_PARTITION: "GFR-Partitionwall type",
  DUBBING_TYPE: "GFR-Type of wall insulation", // à vérifier dans la base
  SYSTEM_SHEET: "GFR-Product datasheet link", // à voir avec : "Plans, schémas, tutoriel de mise en œuvre ou guide d installation",
  CEILING_TYPE: "GFR-Type of ceiling",
  CEILING_TYPE_OF_GRID: "GFR-Type of grid ceiling",
  TYPICAL_DESCRIPTION: "GFR-Typical description",

  IMPACT_RESISTANCE_J: "GFR-Shock resistance",
  SURFACE_HARDNESS: "GFR-Surface hardness",
  BURGLARY_RESISTANCE_TIME_MIN: "GFR-Class of resistance to break-in",
  LIMIT_HEIGHT_M: "GFR-Height limit in m",
  RESISTANCE_TO_OCCUPANCY_SHOCKS_J: "GFR-Occupational shocks resistance (J)",

  ACOUSTIC_ATTENUATION_RA_ON_DB: "GFR-Sound reduction",
  SOUND_REDUCTION_RW_C_CTR_IN_DB: "GFR-Rw in db",
  ACOUSTIC_PV_N: "GFR-Acoustic PV number",
  SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB:
    "GFR-Additionnal acoustic insulation (dB)",
  SOUND_ABSORPTION_ΑW: "Gypsum//G-Acoustics absorption alpha_w", // à vérifier

  COEFFICIENT_K_OR_UP_W_M_K: "GFR-K coefficient or up",
  TYPE_OF_INSULATION: "GFR-Insulation type",
  INSULATION_THICKNESS_MM: "GFR-Insulation thickness",

  FIRE_RESISTANCE: "GFR-Fire protection",
  FIRE_REPORT_NUMBER: "GFR-Fire PV number",
  REACTION_TO_FIRE_OF_THE_SHEET_FACING: "GFR-Reaction to fire of board cover",
  FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT: "GFR-Additionnal fire protection",

  FDES_URL: "A-Environmental Product Declaration URL",
  CLASSIFICATION_OF_WET_ROOMS: "GFR-Rooms moisture ranking",
  ACTIV_AIR: "G-Activ'Air",
  VAPOR_BARRIER: "GFR-Vapor barrier",

  TECHNICAL_OPINION_OR_DTU_NUMBER: "GFR-AT or DTU",

  FRAME_TYPE: "GFR-Type of framing",
  AMOUNTS_S_OR_D: "GFR-Profiles (1 or 2)",
  SPACE_ON_GROUND_MM: "GFR-Space on ground (mm)",
  CENTER_DISTANCE_BETWEEN_FRAMES_M: "GFR-Distance between frames (in m)",
  NUMBER_OF_PANELS_PER_FACING: "GFR-No. Of plasterboard per facing",
  WEIGHT_OF_THE_COMPLETE_SYSTEM_KG_M: "GFR-Surface mass in kg / m2",
  TYPE_OF_WORK: "GFR-Building or work type",
  RANGE_M: "GFR-Reach (m)",
  TYPE_OF_INSTALLATION: "GFR-Installation type",
  TYPE_OF_SUPPORT_FLOOR: "GFR-Support (ceiling)",
  TYPE_OF_SUPPORT_WALL: "GFR-Support type (wall)",
  PRIMARY_FRAME_CENTER_DISTANCE_M:
    "GFR-Distance between frames (primary framework) (m)",
  DISTANCE_SECONDARY_FRAME_M: "GFR-Distance secondary framework (m)",
  MINIMUM_HEIGHT_OF_THE_PLENUM: "GFR-Minimum height of the plenum",
  MAXIMUM_HEIGHT_OF_THE_PLENUM: "GFR-Maximum height of the plenum",
  MAXIMUM_INSULATING_MATERIAL_WEIGHT:
    "GFR-Maximum insulating material weight (kg/m²)",
  PRICE_PROVIDED_IN_BATICHIFFRAGE: "GFR-Price (bc)",
  PRODUCT: "GFR-Product ",
  //BIM_LAYERS: "GFR-BIM layers",
  RANDOM_REGULAR_PERFORATIONS: "GFR-Random/Regular perforations",

  //LAYER_CONTENT: "GFR-Layer content",
  //LAYER_NUMBER: "GFR-Layer number",
  //LAYER_NAME: "GFR-Layer name",
  IFC_TYPE: "GFR-IFC type",
  LAYER_THIKNESS_MM: "GFR-Layer thickness mm",
  //LAYER_COLOR: "GFR-Layer color",
  //LAYER_DATA: "GFR-Layer",


};
