import {
  Format,
  FormatModel,
  Source,
  SourceModel,
  Version,
  VersionModel,
} from "../../Shared/models/Criteria";
import Axios from "axios";
import { myConfigSync } from "../../Shared/myConfig";

const Configuration = myConfigSync();
const baseURL = Configuration.REACT_APP_MODEL_GEN_API_URL;

// Axios.interceptors.request.use((request) => {
//     if(request.responseType !== "arraybuffer")
//     {
//         request.headers.Authorization = "Bearer " + localStorage.getItem("token");
//         request.headers.Accept = "application/json";
//         request.headers["Content-Type"] = "application/json";
//     }
//     return request
// });

// Axios.interceptors.response.use((response) => {
//     return response
// });

export function getSource(): Source {
  let obj: any = {};
  obj.Status = true;
  obj.StatusCode = 200;
  obj.StatusText = "";
  let arr: SourceModel[] = [];
  let source = require("../Data/source.data.json");
  source.map((data: SourceModel) => {
    arr.push(data);
  });
  obj.Data = arr;
  return obj;
}
export function getFormat(): Format {
  let obj: any = {};
  obj.Status = true;
  obj.StatusCode = 200;
  obj.StatusText = "";
  let arr: FormatModel[] = [];
  let source = require("../Data/format.data.json");
  source.map((data: FormatModel) => {
    arr.push(data);
  });
  obj.Data = arr;
  return obj;
}
export function getVersion(): Version {
  let obj: any = {};
  obj.Status = true;
  obj.StatusCode = 200;
  obj.StatusText = "";
  let arr: VersionModel[] = [];
  let source = require("../Data/version.data.json");
  source.map((data: VersionModel) => {
    arr.push(data);
  });
  obj.Data = arr;
  return obj;
}

export function getType(): any {
  let obj: any = {};
  obj.Status = true;
  obj.StatusCode = 200;
  obj.StatusText = "";
  let arr: any[] = [];
  let source = require("../Data/type.data.json");
  source.map((data: any) => {
    arr.push(data);
  });
  obj.Data = arr;
  return obj;
}

export function getGeneric(): any {
  let obj: any = {};
  obj.Status = true;
  obj.StatusCode = 200;
  obj.StatusText = "";
  let arr: any[] = [];
  let source = require("../Data/generic.data.json");
  source.map((data: any) => {
    arr.push(data);
  });
  obj.Data = arr;
  return obj;
}

export const getBusinessData = async () => {
  let obj: any = {};
  let arr: any[] = [];

  const reqUrl = `${baseURL}/api/business/${Configuration.REACT_APP_MODEL_GEN_ENV}`;

  const responseLayout = await Axios.get(reqUrl, {
    headers: {
      "subscription-Key": "4ad40837abe44997897e19e635a08217",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .then((response) => {
      let list = response === null ? [] : response.data.data;
      console.log(list);
      list.forEach((data: any) => {
        arr.push(data);
      });
      obj.data = arr;
      obj.statusCode = 200;
      obj.statusText = "success";
      obj.status = true;
      return obj;
    })
    .catch((err) => {
      console.log(err);
      obj.status = false;
      obj.statusCode = 500;
      obj.statusText = err.message;
      obj.data = [];
      return obj;
    });
  return responseLayout;
};
