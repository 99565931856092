import { Language, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import {
  ProductDetailData,
  PIMLayoutAttributesAid,
} from "../../../../RevitJS/Types/BddTypes";
import {
  FETCH_PLACO_DATA,
  DataAction,
  FilterAction,
  SET_SELECTION_TREE,
  SelectionTreeAction,
  SelectorAction,
  INIT_GROUP_SELECTOR,
  SELECT_LEVEL,
  SELECT_TYPE,
  MappingTableAction,
  INIT_MAPPING_TABLE,
  SELECT_SYSTEM,
  ProductSelectorAction,
  INIT_PRODUCT_SELECTOR,
  SET_SELECTION_BUFFER,
  SET_ACTIVE_MAPPING_ROW_INDEX,
  RESET_SELECTION_BUFFER,
  SEARCH,
  APPLY_FILTERS,
  RESET_FILTERS,
  LayoutAction,
  INIT_LAYOUT_PROGRESS,
  UPDATE_LAYOUT_PROGRESS,
  START_LAYOUT,
  END_LAYOUT,
} from "../Actions/types";
import { placoFilters } from "../Ressources/placoFilters";
import {
  TypeData,
  LevelData,
  MappingRow,
} from "../../../../RevitJS/Types/StoreTypes";
import { levelData, elementTypes } from "../../../../RevitJS/Helpers";
import { FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";
import { LayoutState } from "../../../../Layout/types";

export const language = (
  language: Language = "French",
  action: any
): Language => {
  switch (action.type) {
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const placoData = (
  placoData = {
    partitionWalls: { systemsDetails: [], filters: [] },
    liningWalls: { systemsDetails: [], filters: [] },
  },
  action: DataAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return {
        partitionWalls: action.partitionsDetails,
        liningWalls: action.liningWallsDetails,
      };
    default:
      return placoData;
  }
};

export const selectionTree = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeAction | SelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE:
      return action.tree;
    case INIT_GROUP_SELECTOR:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const filters = (
  filters: { liningWalls: any[]; partitions: any[] } = placoFilters,
  action: FilterAction | ProductSelectorAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return {
        liningWalls: action.liningWallsDetails.filters,
        partitions: action.partitionsDetails.filters,
      };
    case APPLY_FILTERS:
      return action.filters;
    case RESET_FILTERS:
      return action.filters;
    default:
      return filters;
  }
};

export const levelsData = (
  levelsData: LevelData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const typesData = (
  typesData: TypeData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      // api.windowsHandler.showDevTools();
      console.log(elementTypes(levelData(action.tree, [])));
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL:
      return action.typesData;
    case SELECT_TYPE:
      return action.typesData;
    default:
      return typesData;
  }
};

export const selectorType = (selectorType: string = "wall", action: any) => {
  switch (action.type) {
    default:
      return selectorType;
  }
};

export const selectedTypes = (
  selectedTypes: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedTypes;
    case SELECT_TYPE:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedLevels = (
  selectedLevels: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const mappingRows = (
  mappingRows: MappingRow[] = [],
  action: MappingTableAction
) => {
  switch (action.type) {
    case INIT_MAPPING_TABLE:
      return action.mappingRows;
    case SELECT_SYSTEM:
      console.log(action.index);
      console.log(action.system);
      return mappingRows.map((sys) =>
        sys.Index === action.index
          ? { ...sys, MappedSystem: action.system }
          : sys
      );
    default:
      return mappingRows;
  }
};

export const filterTypeEnabled = (
  filterTypeEnabled: FilterTypeEnabled = "partitionsWalls",
  action: any
) => {
  switch (action.type) {
    default:
      return filterTypeEnabled;
  }
};

export const activeMappingRowIndex = (
  activeMappingRowIndex: null | number = null,
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SET_ACTIVE_MAPPING_ROW_INDEX:
      return action.index;
    default:
      return activeMappingRowIndex;
  }
};

export const filteredElements = (
  filteredElements: ProductDetailData[] = [],
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case INIT_PRODUCT_SELECTOR:
      return action.filteredElements;
    case SEARCH:
      return action.filteredElements;
    case APPLY_FILTERS:
      return action.filteredElements;
    case RESET_FILTERS:
      return action.filteredElements;
    default:
      return filteredElements;
  }
};

export const displayedElements = (
  displayedElements: ProductDetailData[] = [],
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case INIT_PRODUCT_SELECTOR:
      return action.displayedElements;
    case SEARCH:
      return action.displayedElements;
    case APPLY_FILTERS:
      return action.displayedElements;
    case RESET_FILTERS:
      return action.displayedElements;
    default:
      return displayedElements;
  }
};

export const selectionBuffer = (
  selectionBuffer: ProductDetailData | null = null,
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_BUFFER:
      return action.system;
    case RESET_SELECTION_BUFFER:
      return null;
    case APPLY_FILTERS:
      return null;
    case RESET_FILTERS:
      return null;
    case SEARCH:
      return null;
    default:
      return selectionBuffer;
  }
};

export const searchedWord = (
  searchedWord: string = "",
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SEARCH:
      return action.searchedWord;
    case APPLY_FILTERS:
      return "";
    case RESET_FILTERS:
      return "";
    default:
      return searchedWord;
  }
};

export const layoutAttributesAid = (
  layoutAttributesAid: PIMLayoutAttributesAid | null = null,
  action: DataAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return action.layoutAttributesAid;
    default:
      return layoutAttributesAid;
  }
};

export const layoutProgress = (
  layoutProgress: number = 0,
  action: LayoutAction
) => {
  switch (action.type) {
    case INIT_LAYOUT_PROGRESS:
      return 0;
    case UPDATE_LAYOUT_PROGRESS:
      return action.progress;
    default:
      return layoutProgress;
  }
};

export const layoutState = (
  layoutState: LayoutState = null,
  action: LayoutAction
): LayoutState => {
  switch (action.type) {
    case START_LAYOUT:
      return "FamilyLoading";
    case INIT_LAYOUT_PROGRESS:
      return "Processing";
    case END_LAYOUT:
      return "Ended";
    default:
      return layoutState;
  }
};

export const reducers = combineReducers({
  language,
  functionalityIcon,
  placoData,
  selectionTree,
  filters,
  levelsData,
  typesData,
  selectedLevels,
  selectedTypes,
  mappingRows,
  filterTypeEnabled,
  activeMappingRowIndex,
  filteredElements,
  displayedElements,
  selectionBuffer,
  searchedWord,
  layoutAttributesAid,
  layoutProgress,
  layoutState,
});

export interface DrawStore {
  language: Language;
  functionalityIcon: string;
  placoData: {
    partitionWalls: { systemsDetails: []; filters: [] };
    liningWalls: { systemsDetails: []; filters: [] };
  };
  selectionTree: ElementsTree;
  filters: { liningWalls: any[]; partitions: any[] };
  levelsData: LevelData[];
  typesData: TypeData[];
  selectedLevels: string[];
  selectedTypes: string[];
  mappingRows: MappingRow[];
  filterTypeEnabled: FilterTypeEnabled;
  activeMappingRowIndex: number | null;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  selectionBuffer: ProductDetailData | null;
  searchedWord: string;
  layoutAttributesAid: PIMLayoutAttributesAid | null;
  layoutProgress: number;
  layoutState: LayoutState;
}

// let tree = await api.selection.elementsByLevelAndType("wall");
//     let elementsByLevelAndType = tree.Tree;
//     let typesData = elementTypes(levelData(tree, [])) as TypeData[];
//     this.setState({
//       levelsData: elementsByLevelAndType.map(level => ({
//         Name: level.Level.Name,
//         Checked: false
//       })),
//       typesData,
//       tree
//     });
