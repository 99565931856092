import _ from "lodash";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import {
  resetCurrentProject,
  setCurrentProjectAllAvailableElements,
  setCurrentProjectItems,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  CREATE_PROJECT,
  SelectionMethod,
  STEP_2_ELEMENT_SELECTION,
} from "../../assets/constants";
import Element from "../../models/Element.model";
import StateModel from "../../models/State.model";
import { saveNewProject } from "../../services/projects.service.local2";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import PixelLoader from "../common/loader/PixelLoader";
import "./SystemSelection.scss";

class SystemSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.projectMenu = this.projectMenu.bind(this);

    // this.setNbrSelectedElements = this.setNbrSelectedElements.bind(this);
    // this.setAllSelectedElements = this.setAllSelectedElements.bind(this);
    this.initSystemTypeSelection = this.initSystemTypeSelection.bind(this);

    this.setAllSystemTypeSelected = this.setAllSystemTypeSelected.bind(this);
    this.handleSystemNameSelected = this.handleSystemNameSelected.bind(this);
    this.setAllSystemNamesSelected = this.setAllSystemNamesSelected.bind(this);
    this.handleSystemTypeSelected = this.handleSystemTypeSelected.bind(this);

    this.state = {
      nbrSelectedElements: 0,
      systemTypesList: [],
      systemNamesList: [],
      manualAllChecked: false,
    };
  }

  componentDidMount() {
    this.setState({
      systemTypesList: this.initSystemTypeSelection(),
    });
    let { allAvailableItems } = this.props;
    let nbr = 0;
    allAvailableItems.forEach((element: Element) => {
      if (element.selected) {
        nbr++;
      }
    });
    this.setState({
      nbrSelectedElements: nbr,
    });

    // if manual selection, we create systemTypeList in constructor, and set all checked systemTypes checked true in componentDidMount,
    // and wait for the update to happen to create systemNamesList in componentDidUpdate and then we can check all systemNames true and
    // immediatly go to range selection
    // this way, we get to apply the same processing as if group systeme selection but the user won't see this interface
    if (this.props.currentProject.selectionMethod === SelectionMethod.MANUAL) {
      setTimeout(() => {
        this.setAllSystemTypeSelected(true);
      });
    }
  }

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;

    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.ELEMENT_SELECTION_WINDOW);
  }

  nextPage() {
    let {
      currentProject,
      setCurrentProjectAllAvailableElements,
      selectWindowToShow,
    } = this.props;

    if (currentProject.items) {
      currentProject.items = [];
    }
    if (currentProject.looseElements) {
      currentProject.looseElements = [];
    }

    setCurrentProjectAllAvailableElements(this.sanitizeItemForRangeSelection());

    selectWindowToShow(AppWindowName.RANGE_SELECTION_WINDOW);

    setTimeout(() => {
      saveNewProject(currentProject);
    });
  }

  initSystemTypeSelection() {
    let { allAvailableItems } = this.props;

    let systemTypeList: { value: string; selected: boolean }[] = [];
    let elementWithNoSystemType: Element[] = [];

    allAvailableItems.forEach((element) => {
      let found = false;
      systemTypeList.forEach((systemType) => {
        if (systemType.value === element.SystemType) {
          found = true;
        }
      });
      if (element.SystemType) {
        if (!found) {
          systemTypeList.push({
            value: element.SystemType,
            selected: false,
          });
        }
      } else {
        elementWithNoSystemType.push(element);
      }
    });

    return systemTypeList;
  }

  // on cree la liste des items a ajouter au projet à partir de la séléction de range
  sanitizeItemForRangeSelection(): Element[] {
    let { allAvailableItems } = this.props;
    let elementsList: Element[] = [];
    // on enleve la propriété "selected" des ranges séléctionés (on en a plus besoin)
    // on instancie un nouvel item avec des prop par defaut + le range
    // et on ajoute ca a la liste des items qui va etre persisté
    allAvailableItems.forEach((element: Element) => {
      this.state.systemNamesList.forEach((systemName) => {
        if (
          systemName.selected &&
          systemName.systemType === element.SystemType &&
          systemName.value === element.SystemName
        ) {
          let tmp: any = { ...element };
          tmp.selected = false;

          elementsList.push(tmp);
        }
      });
    });
    return elementsList;
  }

  createSystemNamesFromSelection() {
    let { allAvailableItems } = this.props;
    let tmp: {
      value: string;
      systemType: string;
      selected: boolean;
    }[] = _.cloneDeep(this.state.systemNamesList);
    this.state.systemTypesList.forEach((systemType) => {
      if (systemType.selected) {
        allAvailableItems.forEach((element) => {
          let found = false;
          if (element.SystemType === systemType.value) {
            tmp.forEach((systemName) => {
              if (element.SystemName === systemName.value) {
                found = true;
              }
            });

            if (!found) {
              tmp.push({
                value: element.SystemName,
                systemType: systemType.value,
                selected: false,
              });
            }
          }
        });
      } else {
        for (let i = 0; i < tmp.length; i++) {
          if (systemType.value === tmp[i].systemType) {
            tmp.splice(i, 1);
            i--;
          }
        }
      }
    });

    return tmp;
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!_.isEqual(prevState.systemTypesList, this.state.systemTypesList)) {
      this.setState({
        ...this.state,
        systemNamesList: this.createSystemNamesFromSelection(),
      });
    }
    if (!_.isEqual(prevState.systemNamesList, this.state.systemNamesList)) {
      let countSelectedItems = 0;
      this.state.systemNamesList.forEach((systemName) => {
        if (systemName.selected) {
          countSelectedItems++;
        }
      });
      this.setState({
        ...this.state,
        nbrSelectedElements: countSelectedItems,
      });
      if (
        this.props.currentProject.selectionMethod === SelectionMethod.MANUAL
      ) {
        setTimeout(() => {
          this.setAllSystemNamesSelected(true);
        });
      }

      // if manual selection, we create systemTypeList in constructor, and set all checked systemTypes checked true in componentDidMount,
      // and wait for the update to happen to create systemNamesList in componentDidUpdate and then we can check all systemNames true and
      // immediatly go to range selection
      // this way, we get to apply the same processing as if group systeme selection but the user won't see this interface
    }
    if (
      this.props.currentProject.selectionMethod === SelectionMethod.MANUAL &&
      (this.state.manualAllChecked || this.state.systemTypesList.length === 0)
    ) {
      this.nextPage();
    }
  }

  handleSystemTypeSelected(selected: boolean, elementIndex: any) {
    let tmp = _.cloneDeep(this.state.systemTypesList);
    tmp[elementIndex].selected = selected;
    this.setState({ ...this.state, systemTypesList: tmp });
  }

  setAllSystemTypeSelected(selected: boolean) {
    let tmp = _.cloneDeep(this.state.systemTypesList);
    tmp.forEach((systemType) => {
      systemType.selected = selected;
    });
    this.setState({ ...this.state, systemTypesList: tmp });
  }

  handleSystemNameSelected(selected: boolean, element: string) {
    let tmp = _.cloneDeep(this.state.systemNamesList);
    tmp.forEach((systemName: any) => {
      if (systemName.value === element) {
        systemName.selected = selected;
      }
    });

    // setSystemNameList(tmp);
    this.setState({ ...this.state, systemNamesList: tmp });
  }

  setAllSystemNamesSelected(selected: boolean) {
    let tmp = _.cloneDeep(this.state.systemNamesList);
    tmp.forEach((systemName: any) => {
      systemName.selected = selected;
    });

    // setSystemNameList(tmp);
    this.setState({ ...this.state, systemNamesList: tmp });

    if (this.props.currentProject.selectionMethod === SelectionMethod.MANUAL) {
      this.setState({ ...this.state, manualAllChecked: true });
    }
  }

  render() {
    let { t } = this.props;
    let { systemTypesList, systemNamesList } = this.state;
    return (
      <div id="system-name-container">
        <Header
          displayName={t(STEP_2_ELEMENT_SELECTION) || "Element selection"}
        ></Header>
        {this.props.currentProject.selectionMethod ===
        SelectionMethod.MANUAL ? (
          <PixelLoader></PixelLoader>
        ) : (
          <Body
            systemTypesList={systemTypesList}
            systemNamesList={systemNamesList}
            setAllSystemTypeSelected={this.setAllSystemTypeSelected}
            handleSystemTypeSelected={this.handleSystemTypeSelected}
            setAllSystemNamesSelected={this.setAllSystemNamesSelected}
            handleSystemNameSelected={this.handleSystemNameSelected}
            // setNbrSelectedElements={this.setNbrSelectedElements}
            // setAllSelectedElements={this.setAllSelectedElements}
          ></Body>
        )}
        <span></span>
        <Footer
          savedProjectsButtonShow={true}
          savedProjectsButtonDisabled={false}
          savedProjectButtonClicked={this.projectMenu}
          disabledNextButton={this.state.nbrSelectedElements <= 0}
          buttonLabel={CREATE_PROJECT}
          showNextButton={true}
          clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    currentProject: state.projects.currentProject,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  setCurrentProjectItems,
  setCurrentProjectAllAvailableElements,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux &
  WithTranslation & {
    allAvailableItems: Element[];
  };

type IState = {
  nbrSelectedElements: number;
  systemTypesList: { value: string; selected: boolean }[];
  systemNamesList: { value: string; systemType: string; selected: boolean }[];
  manualAllChecked: boolean;
};

export default connector(withTranslation(["pam"])(SystemSelection));
