export const setWallScheduleLoader = (load: boolean) => {
  return { type: "SET_WALLSCHEDULE_LOADER", load };
};


export const setUnitConversionArray = (array : any, element : any) => {
  return { type: "SET_UNITCONVERSION_ARRAY", array , element};
};

export const setLanguage = (language : string) => {
  return { type: "SET_LANGUAGE", value: language};
};


