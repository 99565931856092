import React, { Component } from "react";
import { Container, Button, Table, Icon } from "semantic-ui-react";
import "../../Resources/CommonStyles.css";
import "../../Resources/CoverPage.css";
import { CoverPageTable } from "./CoverPageTable";
import { SelectionStore } from "../../../Selection/Reducers";
import { connect } from "react-redux";
import { Routes } from "../root";
import { FunctionalityHeader } from "../FunctionalityHeader";
import {
  UploadDocumentToFilemanagement,
  DeleteDocumentToFileManagement,
} from "../../Helpers/FilemanagementApiHelper";
import { setDossierData } from "../../Actions";
import { InformationModal } from "../TechnicalLanding/InformationModal";
import { DownloadLoader } from "../TechnicalLanding/DownloadLoader";
import { createFileWithNewName } from "../../Helpers";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { InjectedCounterProps } from "../../../../../ErrorManagement/components/ErrorBoundry";
import { errorCodeKey } from "../../../../../ErrorManagement/utils/errorCodeEnum";

type Props = InjectedCounterProps & {
  wording: {
    coverPage: { [key: string]: string };
    choiceCoverPage: { [key: string]: string };
    modelName: { [key: string]: string };
    editDate: { [key: string]: string };
    importModel: { [key: string]: string };
    back: { [key: string]: string };
    validate: { [key: string]: string };
    action: { [key: string]: string };
    selection: { [key: string]: string };
    uploadMessage: { [key: string]: string };
    deleteMessage: { [key: string]: string };
    no: { [key: string]: string };
    yes: { [key: string]: string };
    duplicateFileMsg: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  routes: Routes;
  Icon: string;
  dossierData: any;
  setDossierData: any;
  config: any;
};

export class CoverPage extends Component<Props> {
  state = {
    selectedFile: {},
    storedFiles: [],
    loaderMessage: "",
    selectedRadioFile: "",
    openModal: false,
    infoState: { subjectMsg: "", messageList: [], footerMessage: "" },
    buttonList: [{ name: "", action: null, color: "" }],
    defaultDate: "",
  };

  public static defaultProps = {
    wording: require("../../Resources/wording.json"),
  };

  componentDidMount = async () => {
    let tempCoverData: any = await bimStorage.getItem(
      storageKey.COVER_PAGE_FILES
    );
    let coverPageFiles = tempCoverData === null ? [] : tempCoverData;
    this.toggleModal("storedFiles", coverPageFiles);
    this.toggleModal(
      "selectedRadioFile",
      this.props.dossierData.coverPage === undefined ||
        this.props.dossierData.coverPage.status === undefined
        ? "default"
        : this.props.dossierData.coverPage.fileData
    );
    const projectInf = await bimStorage.getItem(storageKey.PROJECT_INFO_DATE);
    this.setState((prevState) => {
      return { ...prevState, defaultDate: projectInf };
    });
  };

  previewEyeHandler = async (pdf: any = null) => {
    if (pdf !== null) {
      localStorage.setItem("currentPreviewPdf", pdf);
    } else {
      localStorage.removeItem("currentPreviewPdf");
    }
    this.props.setRoute(Routes.PREVIEW_PDF);
  };

  uploadFiles = async (e: any) => {
    try {
      e.preventDefault();
      if (e.target.files && e.target.files.length > 0) {
        this.toggleModal(
          "loaderMessage",
          this.props.wording.uploadMessage[this.props.language]
        );
        this.toggleModal("selectedFile", e.target.files[0]);
        let fileData = e.target.files[0];
        let tempCoverData: any = await bimStorage.getItem(
          storageKey.COVER_PAGE_FILES
        );
        let coverData = tempCoverData === null ? [] : tempCoverData;
        let totalFiles: any = [];
        coverData.forEach((data: any) => {
          totalFiles.push(data.screenName);
        });
        if (totalFiles.includes(fileData["name"])) {
          await this.showDuplicateMsg(totalFiles);
        } else {
          let newFile = createFileWithNewName(fileData, "cover_");
          await this.UploadDocument(newFile, fileData["name"]).catch(
            (error) => {
              console.log("error inside function:", error);
              return Promise.reject(error);
            }
          );
          //Promise.reject(error);
        }
      } else {
        this.toggleModal("loaderMessage", "");
      }
    } catch (error) {
      //throw error;
      return Promise.reject(error);
    }
  };

  showDuplicateMsg = async (totalFiles: any) => {
    let buttonList = [];
    buttonList.push({
      name: this.props.wording.no[this.props.language],
      action: this.HandleNo,
      color: "orange",
    });
    buttonList.push({
      name: this.props.wording.yes[this.props.language],
      action: () => this.HandleYes(totalFiles),
      color: "primary",
    });
    this.setState({
      buttonList: buttonList,
      openModal: true,
      infoState: {
        subjectMsg: this.props.wording.duplicateFileMsg[this.props.language],
        messageList: [],
        footerMessage: "",
      },
    });
  };

  UploadDocument = async (fileData: any, screenName: string) => {
    await UploadDocumentToFilemanagement(fileData, this.props.config).then(
      async (response) => {
        if (response.status === 200) {
          if (response.data) {
            let coverPageFiles = await bimStorage.getItem(
              storageKey.COVER_PAGE_FILES
            );
            coverPageFiles.push({
              name: fileData.name,
              lastModifiedDate: fileData.lastModifiedDate,
              screenName: screenName,
            });
            this.toggleModal("storedFiles", coverPageFiles);
            bimStorage.setItem(
              storageKey.COVER_PAGE_FILES,
              JSON.stringify(coverPageFiles)
            );
          }
        } else {
          console.log("error is :", response);
          //this.props.onError("401", "stack will be displayed", true);
          return Promise.reject(response);
          //return response;
        }
      }
    );

    this.toggleModal("loaderMessage", "");
  };

  HandleYes = async (totalFiles: any) => {
    this.toggleModal(
      "loaderMessage",
      this.props.wording.uploadMessage[this.props.language]
    );
    let fileData: any = this.state.selectedFile;
    let start = fileData.name.split(".")[0];
    let end = fileData.name.split(".")[1];
    let new_screenName = "";
    let counter: any = [];
    let max = 0;
    let coverPageFiles = await bimStorage.getItem(storageKey.COVER_PAGE_FILES);

    let oldFileData = coverPageFiles.find(
      (f: any) => f.screenName === fileData.name
    );
    new_screenName = start + "(" + ++max + ")." + end;
    var blob = fileData.slice(0, fileData.size, "application/pdf");
    let newFile = new File([blob], oldFileData.name, {
      type: "application/pdf",
    });
    this.toggleModal("selectedFile", newFile);
    this.toggleModal("openModal", false);
    await this.deleteHandler(oldFileData.name);
    await this.UploadDocument(newFile, oldFileData.screenName);
  };

  HandleNo = () => {
    this.toggleModal("openModal", false);
    this.toggleModal("loaderMessage", "");
  };

  deleteHandler = (filename: string) => {
    return DeleteDocumentToFileManagement(filename, this.props.config)
      .then(async (response: any) => {
        if (response.data === "" && response.status === 200) {
          if (this.state.selectedRadioFile === filename)
            this.toggleModal("selectedRadioFile", "default");
          if (this.props.dossierData.coverPage)
            this.props.dossierData.coverPage.fileData = "default";
          let index = 0;
          let coverPageFiles = await bimStorage.getItem(
            storageKey.COVER_PAGE_FILES
          );
          for (let file of coverPageFiles) {
            if (file.name === filename) break;
            index++;
          }
          coverPageFiles.splice(index, 1);
          this.toggleModal("storedFiles", coverPageFiles);
          bimStorage.setItem(
            storageKey.COVER_PAGE_FILES,
            JSON.stringify(coverPageFiles)
          );
          // this.toggleModal("loaderMessage", "");
        } else if (response.error === "invalid_token") {
          this.setState({
            buttonList: [{ name: "Cancel", action: null, color: "red" }],
          });
          this.toggleModal(
            "loaderMessage",
            "Session expired. Please login again"
          );
        }
      })
      .catch(async (error: any) => {
        // this.toggleModal("loaderMessage", "");
        if (error.response.status === 401) {
          let index = 0;
          let coverPageFiles = await bimStorage.getItem(
            storageKey.COVER_PAGE_FILES
          );
          for (let file of coverPageFiles) {
            if (file.name === filename) break;
            index++;
          }
          coverPageFiles.splice(index, 1);
          this.toggleModal("storedFiles", coverPageFiles);
          bimStorage.setItem(
            storageKey.COVER_PAGE_FILES,
            JSON.stringify(coverPageFiles)
          );
          // this.toggleModal("loaderMessage", "");
        }
      });
  };

  handleChangeCheckbox = (e: any) => {
    this.toggleModal("selectedRadioFile", e.target.value);
    this.props.dossierData.bufferTime = Date.now();
    this.props.dossierData.coverPage.fileData = e.target.value;
  };

  handleValidate = () => {
    this.props.dossierData.coverPage = {
      ...this.props.dossierData.coverPage,
      status: true,
      fileData: this.state.selectedRadioFile,
    };
    this.props.setDossierData(this.props.dossierData);

    // bufferTime resets for flag in edit selection button if default cover page is already selected
    if (this.props.dossierData.coverPage.fileData === "default") {
      this.props.dossierData.bufferTime = Date.now();
    }
    this.props.setRoute(Routes.TECHNICAL_LANDING);
  };

  toggleModal = (modalName: string, action: any) => {
    this.setState({ [modalName]: action });
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
        }}
      >
        <FunctionalityHeader
          name={this.props.wording.coverPage[this.props.language]}
          Icon={this.props.Icon}
          subheader=""
        />
        {/* subheader={this.props.wording.coverPage[this.props.language]} */}
        <Container style={{ width: "100%", marginTop: "20px" }}>
          <DownloadLoader
            loaderMessage={this.state.loaderMessage}
            buttonList={[]}
          />
          <div
            className="common-table"
            style={{
              height: "calc(100vh - 200px)",
              overflowY: "auto",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="table-head-cell width5">
                    {this.props.wording.selection[this.props.language]}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="table-head-cell width50">
                    {this.props.wording.modelName[this.props.language]}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="table-head-cell width25">
                    {this.props.wording.editDate[this.props.language]}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="table-head-cell width20">
                    {this.props.wording.action[this.props.language]}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <CoverPageTable
                dossierData={this.props.dossierData}
                fileData={this.state.storedFiles}
                previewEyeHandler={this.previewEyeHandler}
                deleteHandler={this.deleteHandler}
                handleChangeCheckbox={(e: any) => this.handleChangeCheckbox(e)}
                selectedRadioFile={this.state.selectedRadioFile}
                defaultDate={this.state.defaultDate}
              />
            </Table>
          </div>
          <Container
            style={{ width: "100%", marginLeft: 0, marginTop: "15px" }}
          >
            <Icon.Group style={{ cursor: "pointer" }}>
              <Icon size="large" name="file text outline" color="blue" />
              <Icon name="upload" size="tiny" color="blue" />
            </Icon.Group>
            <label htmlFor="file-upload" className="customfile-upload">
              {this.props.wording.importModel[this.props.language]}
            </label>
            <input
              type="file"
              id="file-upload"
              name="file-upload"
              accept="application/pdf"
              onClick={(event: any) => {
                event.target.value = null;
              }}
              onChange={(e: any) =>
                this.uploadFiles(e).catch((error) => {
                  this.props.onError(
                    errorCodeKey.PB_DT_H_012,
                    error.stack,
                    true
                  );
                })
              }
            />
          </Container>
          <Container>
            <InformationModal
              informationModal={this.state.openModal}
              toggleInfoModal={() => this.toggleModal("informationModal", true)}
              // closeDimmer={this.HandleOk}
              setRoute={this.props.setRoute}
              route={this.props.routes}
              infoState={this.state.infoState}
              buttonList={this.state.buttonList}
            />
          </Container>
        </Container>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            bottom: 30,
            width: "100%",
          }}
        >
          <Button
            color="orange"
            size="medium"
            onClick={() => this.props.setRoute(Routes.TECHNICAL_LANDING)}
            content={this.props.wording.back[this.props.language]}
            style={{ marginRight: 30 }}
          ></Button>

          <Button
            disabled={this.state.selectedRadioFile === ""}
            size="medium"
            primary
            content={this.props.wording.validate[this.props.language]}
            onClick={() => this.handleValidate()}
          ></Button>
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  setDossierData,
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    setRoute: ownProps.setRoute,
    dossierData: state.moduleData.dossierData,
    config: state.config,
  };
};

export default connect(mapStateToProps, mapDispatch)(CoverPage);
