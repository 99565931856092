export const PAM_ATTRIBUTES_TECHNICAL_NAME = {
    GENERATION_DATE: "GENERATION_DATE",
    SYSTEM_GENERIC_NAME: "GFR-Generic name of the solution product",
    SYSTEM_NAME: "A-Solution product name",
    SOLUTION_WIDTH: "A-Solution Width",
    THICKNESS: "GFR-Total thickness in mm",
    SYSTEM_DESCRIPTION: "GFR-Solution product description",
    SYSTEM_URL: "GFR-Product datasheet link",
    MAIN_MANUFACTURER: "A-Manufacturer",
    MANUFACTURER_INFO_WEBSITE: "A-Website URL",
    SYSTEM_ID: "A-PIM ID",
    NUMBER_OF_LAYERS: "GFR-Total number of layers",
    NAME_OF_THE_FACING: "GFR-Name cover",
    TYPE_OF_PARTITION: "GFR-Partitionwall type",
    DUBBING_TYPE: "GFR-Type of wall insulation", // à vérifier dans la base
    SYSTEM_SHEET: "GFR-Product datasheet link", // à voir avec : "Plans, schémas, tutoriel de mise en œuvre ou guide d installation",
    CEILING_TYPE: "GFR-Type of ceiling",
    CEILING_TYPE_OF_GRID: "GFR-Type of grid ceiling",
    TYPICAL_DESCRIPTION: "GFR-Typical description",
    LYAOUT_POSSIBLE: "GFR-Layout possible",
    STRUCTURE:"GFR-Structure",
    TYPE_OF_PROTECTION:"GFR-Type of protection",
    MASSIVITY_OF_THE_STRUCTURE: "GFR-Massivity of the structure (m-1)",
    FIRE_PROTECTION_CLASSES_IN_OUT:"GFR-Fire protection classes (in/out)",
    WALL_FIRE_PROTECTION: "GFR-Wall fire protection",

    IMPACT_RESISTANCE_J: "GFR-Shock resistance",
    SURFACE_HARDNESS: "GFR-Surface hardness",
    BURGLARY_RESISTANCE_TIME_MIN: "GFR-Class of resistance to break-in",
    LIMIT_HEIGHT_M: "GFR-Height limit in m",
    RESISTANCE_TO_OCCUPANCY_SHOCKS_J: "GFR-Occupational shocks resistance (J)",
  
    ACOUSTIC_ATTENUATION_RA_ON_DB: "GFR-Sound reduction",
    SOUND_REDUCTION_RW_C_CTR_IN_DB: "GFR-Rw in db",
    ACOUSTIC_PV_N: "GFR-Acoustic PV number",
    SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB:
      "GFR-Additionnal acoustic insulation (dB)",
    SOUND_ABSORPTION_ΑW: "Gypsum//G-Acoustics absorption alpha_w", // à vérifier
  
    COEFFICIENT_K_OR_UP_W_M_K: "GFR-K coefficient or up",
    TYPE_OF_INSULATION: "GFR-Insulation type",
    INSULATION_THICKNESS_MM: "GFR-Insulation thickness",
  
    FIRE_RESISTANCE: "GFR-Fire protection",
    FIRE_REPORT_NUMBER: "GFR-Fire PV number",
    REACTION_TO_FIRE_OF_THE_SHEET_FACING: "GFR-Reaction to fire of board cover",
    FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT: "GFR-Additionnal fire protection",
  
    FDES_URL: "A-Environmental Product Declaration URL",
    CLASSIFICATION_OF_WET_ROOMS: "GFR-Rooms moisture ranking",
    ACTIV_AIR: "G-Activ'Air",
    VAPOR_BARRIER: "GFR-Vapor barrier",
  
    TECHNICAL_OPINION_OR_DTU_NUMBER: "GFR-AT or DTU",
  
    FRAME_TYPE: "GFR-Type of framing",
    AMOUNTS_S_OR_D: "GFR-Profiles (1 or 2)",
    SPACE_ON_GROUND_MM: "GFR-Space on ground (mm)",
    CENTER_DISTANCE_BETWEEN_FRAMES_M: "GFR-Distance between frames (in m)",
    NUMBER_OF_PANELS_PER_FACING: "GFR-No. Of plasterboard per facing",
    WEIGHT_OF_THE_COMPLETE_SYSTEM_KG_M: "GFR-Surface mass in kg / m2",
    TYPE_OF_WORK: "GFR-Building or work type",
    RANGE_M: "GFR-Reach (m)",
    TYPE_OF_INSTALLATION: "GFR-Installation type",
    TYPE_OF_SUPPORT_FLOOR: "GFR-Support (ceiling)",
    TYPE_OF_SUPPORT_WALL: "GFR-Support type (wall)",
    PRIMARY_FRAME_CENTER_DISTANCE_M:
      "GFR-Distance between frames (primary framework) (m)",
    DISTANCE_SECONDARY_FRAME_M: "GFR-Distance secondary framework (m)",
    MINIMUM_HEIGHT_OF_THE_PLENUM: "GFR-Minimum height of the plenum",
    MAXIMUM_HEIGHT_OF_THE_PLENUM: "GFR-Maximum height of the plenum",
    MAXIMUM_INSULATING_MATERIAL_WEIGHT:
      "GFR-Maximum insulating material weight (kg/m²)",
    PRICE_PROVIDED_IN_BATICHIFFRAGE: "GFR-Price (bc)",
    PRODUCT: "GFR-Product ",
    BIM_LAYERS: "GFR-BIM layers",
    RANDOM_REGULAR_PERFORATIONS: "GFR-Random/Regular perforations",
  
    LAYER_CONTENT: "GFR-Layer content",
    LAYER_NUMBER: "GFR-Layer number",
    LAYER_NAME: "GFR-Layer name",
    IFC_TYPE: "GFR-IFC type",
    LAYER_THIKNESS_MM: "GFR-Layer thickness mm",
    LAYER_COLOR: "GFR-Layer color",
  };
  
  