import React, { useState } from "react";
import { Provider } from "react-redux";
import store from "../../Store";
import AppBody from "./AppBody";

export enum Routes {
  ROOT = "ROOT",
  PRODUCT_LIST = "PRODUCT_LIST",
  BIM_PRODUCT_LIST = "BIM_PRODUCT_LIST",
  PRODUCT_INFO_BOX = "PRODUCT_INFO_BOX",
  SELECTED_PRODUCTS = "SELECTED_PRODUCTS",
}

const Root = () => {
  const [route, setRoute] = useState(Routes.ROOT);
  console.log("root", route);
  return (
    <>
      <Provider store={store}>
        <AppBody route={route} setRoute={setRoute} />
      </Provider>
    </>
  );
};

export default Root;
