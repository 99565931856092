import React from "react";
import { Table, Button, Popup, Checkbox } from "semantic-ui-react";

import ProductRow from "./ProductRow";

export const ProductTable = (props: any) => {
  return (
    <React.Fragment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "70%" }}>
              <Checkbox className="headerChk" label={"Product"} />
            </Table.HeaderCell>

            <Table.HeaderCell style={{ width: "10%" }}>View</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "10%" }}>Status</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "10%" }}>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* {list.map((item, index) => ( */}
          <ProductRow
          // key={index}
          // row={item}
          ></ProductRow>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};
export default ProductTable;
