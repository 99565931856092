import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "7d96c97f-06e0-4d5f-ba9d-69f9fedff6f5";

export const objectGeneratorTrigger = (config:any) => {
  api.windowsHandler.openWindow(
    800,
    550,
    // `${config.REACT_APP_SERVERURL}/#/${pluginId}/${functionalityId}`
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgba(0,0,0,.87)"
    >
      <Root />
    </ParentRoot>
  );
};

export const ModelGenObjectGeneratorRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgba(0,0,0,.87)"
    >
      <Root />
    </ParentRoot>
  );
};

export const ObjectGenerator: Functionality = {
  Name: {
    English: "Object Generator",
    French: "Generateur d'Objets",
    German: "Object Generator",
    Italian: "Object Generator"
  },
  Trigger: objectGeneratorTrigger,
  ShortDesc: {
    English: "From BIMDatabase / XLS",
    French: "A Partir de BIMDatabase / XLS",
    German: "From Database / XLS",
    Italian: "From Database / XLS"
  },
  Id: functionalityId,
  Icon: "/ModelGenerator/plugin-logo.png",
  RootComponent,
  ParentId: "0",
};
