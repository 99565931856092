import { LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { setGroupTreesAction, selectLevelAction } from "../Actions/types";

type ceilingLevelsDataAction = setGroupTreesAction | selectLevelAction;

export const ceilingLevelsData = (
    levelsData: LevelData[] = [],
    action: ceilingLevelsDataAction
  ): LevelData[] => {
    switch (action.type) {
      case "SET_GROUP_TREES":
        return action.ceilingTree.Tree.map(level => ({
          Name: level.Level.Name,
          Checked: false
        }));
      case "SELECT_LEVEL":
          if(action.selectorType === "ceiling"){
            return action.levelsData;
          }
          return levelsData;
      default:
        return levelsData;
    }
  };