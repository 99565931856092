import {
  LOAD_SYSTEMS,
  LOAD_SYSTEMS_SUCCESS,
  LOAD_SYSTEMS_ERROR,
  LOAD_SYSTEMS_TO_UPDATED,
  LOAD_UPDATED_SYSTEMS_SUCCESS,
  LOAD_SYSTEMS_TO_UPDATED_ERROR,
  LoadSystemsActionTypes
} from "./types";

export const loadSystemsRequest = (): LoadSystemsActionTypes => ({
  type: LOAD_SYSTEMS,
});

export const loadSystemsSuccess = (currentSystems: any[], systems: any[]): LoadSystemsActionTypes => ({
  type: LOAD_SYSTEMS_SUCCESS,
  currentSystems: currentSystems,
  systems: systems
});

export const loadSystemsError = (): LoadSystemsActionTypes => ({
  type: LOAD_SYSTEMS_ERROR,
});

export const loadSystemsToUpdateRequest = (): LoadSystemsActionTypes => ({
  type: LOAD_SYSTEMS_TO_UPDATED,
});

export const loadSystemsToUpdateSuccess = (currentSystems: any[]): LoadSystemsActionTypes => {
  return {
    type: LOAD_UPDATED_SYSTEMS_SUCCESS,
    currentSystems: currentSystems,

  }
};

export const loadSystemsToUpdateError = (): LoadSystemsActionTypes => ({
  type: LOAD_SYSTEMS_TO_UPDATED_ERROR,
});
