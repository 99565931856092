import React from "react";
import { pluginId } from "..";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { UpdateRoot } from "./Components/root";
import { Cursor } from "../../../RevitJS/API/WindowsHandler";

export const functionalityId = "1938f124-b5cb-4523-aff8-fc8e723bd521";

export const updateTrigger = (config: any) => {
  // api.windowsHandler.showCursor(Cursor.Wait)
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

export const DockUpdateRoot = () => {
  return <UpdateRoot />;
};

export const Update: Functionality = {
  Name: { English: "update" },
  Trigger: updateTrigger,
  ShortDesc: { English: "update" },
  Id: functionalityId,
  Icon: "/pluginIcons/ReverseSidesSmall.png",
  RootComponent: UpdateRoot,
  ParentId: "0",
};

export default Update;
