import React, { Component, useEffect, useState } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../../RevitJS/API";
import { saveAs } from "file-saver";
import { connect, ConnectedProps } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { DrawStore } from "../../store/reducers";

interface WaiterProps {
  config: any;
  t: any;
}

export const Waiter: React.FC<WaiterProps> = ({ config, t }) => {
  const [wait, setWait] = useState<boolean>(false);
  const handelCloseDialog = () => {
    api.windowsHandler.closeWindow();
    setWait(false);
  };

  if (!config && Object.keys(config).length === 0) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Button
          style={{ marginTop: 50 }}
          primary
          onClick={() => {
            setWait(true);
            fetch(
              `${config.REACT_APP_FILESERVER}/static/directdownload/PRESCRIBIM_Manuel%20d'utilisateur.pdf`
            )
              .then((response) => response.blob())
              .then((blob) =>
                saveAs(blob, "PRESCRIBIM_Manuel d'utilisateur.pdf")
              );
            window.addEventListener("blur", () => {
              handelCloseDialog();
            });
          }}
        >
          {t("Télécharger le manuel utilisateur")}
        </Button>
        {wait && (
          <Dimmer active style={{ height: "calc(100vh - 30px)" }}>
            <Loader>Veuillez patienter Le téléchargement est en cours.</Loader>
          </Dimmer>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: DrawStore) {
  return {
    config: state.config,
  };
}

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PropertiesContainerProps = PropsFromRedux & WithTranslation;

export default connector(withTranslation(["library"])(Waiter));
