import React, { StrictMode } from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { AppBodyCalepinage } from "./Page/AppBody";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import CalpinageContextProvider from "../Calepinage/Context/CaplinageContext";

export const functionalityId = "c2555bc8-b5dc-426d-ab22-603448ba023f";

export const CalpinageTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    610,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Calepinage",
    module: "GYPROC",
  });
};

export const GyprocCalepinageRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="calpinage"
    >
      <CalpinageContextProvider>
        <AppBodyCalepinage />
      </CalpinageContextProvider>
    </ParentRoot>
  );
};

export const Calpinage: Functionality = {
  Name: {
    French: "Calepiner / Quantifier",
    English: "Layout",
    German: "Calepiner / Quantifier",
    Italian: "Calepiner / Quantifier",
    Indonesian: "Calepiner / Quantifier",
  },
  Trigger: CalpinageTrigger,
  ShortDesc: {
    French: "Calepiner et préparer les quantitatifs",
    English: "Perform the layout",
    German: "Calepiner et préparer les quantitatifs",
    Italian: "Calepiner et préparer les quantitatifs",
    Indonesian: "Calepiner et préparer les quantitatifs",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Calepiner.png",
  RootComponent: GyprocCalepinageRootComponent,
  ParentId: "0",
};
