import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import { selectOptionalProperties } from "../../actions/csvActions";
import { AppWindowName } from "../../assets/constants";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./PropertiesPreference.scss";

class PropertiesPreference extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
  }

  nextPage() {
    this.props.selectWindowToShow(AppWindowName.TRANSFERT_FILES);
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <div id="propreties-preference-component-container">
          <Header displayName={t("SET_PROPERTY") || "Property"}></Header>
          <Body
            preferences={this.props.optionalProperties}
            setPreferences={this.props.selectOptionalProperties}
          ></Body>
          <Footer
            disabledNextButton={false}
            savedProjectsButtonShow={false}
            clickedNextButton={this.nextPage}
          ></Footer>
        </div>
      </>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    csvList: state.csv.list,
    selectedFile: state.csv.selected,
    optionalProperties: state.csv.optionalProperties,
    selectedOptionalProperties: state.csv.selectedOptionalProperties,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  selectOptionalProperties,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {
  preferences: {
    glandPresence1: boolean | undefined;
    lockingState1: boolean | undefined;
    glandPresence2: boolean | undefined;
    lockingState2: boolean | undefined;
    pn: boolean | undefined;
  };
};

export default connector(
  withTranslation(["pamCanalisation"])(PropertiesPreference)
);
