export enum FILTER_VALUE_TYPE {
    NUMERIC = "Numeric",
    TEXT = "Text",
  }
  
  export enum FILTER_CRITERION_TYPE {
    INCLUDES = "Includes",
    BETWEEN = "Between",
    EQUALS = "Equals",
    GREATERTHEN = "GreaterThen",
  }
  
  export enum FILTER_CHOICE_TYPE {
    CHECKBOX = "CheckBox",
    SLIDER = "Slider",
    RADIOBOX = "RadioBox",
  }