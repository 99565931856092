import React, { Component } from "react";
import { Dimmer, Loader, Button, Modal } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import { MetresStore } from "../../Reducers";
import {
  setWallScheduleLoader,
  setUnitConversionArray,
} from "./../../Actions/index";
import { writeOptimizedBooks } from "../../Extract/extractOpt";
import { writeOptimizedZoneBooks } from "../../Extract/extractOptZone";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { Selection } from "../../../Selection/Actions/types";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../../RevitJS/Types/StoreTypes";
import { loadSelections } from "../../../Selection/Actions";
import { withTranslation, WithTranslation } from "react-i18next";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  Icon: string;
  name: string;
  wording: {
    download: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  setWallScheduleLoader: any;
  wallScheduleLoader: any;
  setUnitConversionArray: any;
  unitConversionArray: any;
  loadSelections: any;
  selections: { [key: string]: Selection<PlacoOptions> };
  projectData: initProjectData;
  config: any;
} & WithTranslation;

interface State {
  wait: boolean;
  raiseWarning: boolean;
  warningMessage: string;
}

class MetresDownloader extends Component<Props, State> {
  state = {
    wait: true,
    raiseWarning: false,
    warningMessage: "",
  };

  public static defaultProps = {
    wording: {
      download: {
        French: "Télécharger",
        English: "Download",
      },
    },
  };

  getUnitConversionArray = () => {
    return this.props.unitConversionArray;
  };

  componentDidMount = async () => {
    // api.windowsHandler.showDevTools();

    setTimeout(() => {
      this.props.i18n.changeLanguage(this.props.language);
    });

    let placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);

    if (placoSelections) {
      let selections = placoSelections as {
        [key: string]: Selection<PlacoOptions>;
      };
      this.props.loadSelections(selections);
    }
  };

  setWarning = (message: string) => {
    this.setState({ raiseWarning: true });
    this.setState({ warningMessage: message });
  };

  unSetWarning = () => {
    this.setState({ raiseWarning: false });
    this.setState({ warningMessage: "" });
  };

  render = () => {
    const dimmer = () => (
      <Dimmer
        active={this.props.wallScheduleLoader}
        style={{ height: "calc(100vh - 30px)" }}
      >
        <Loader content={this.props.t("PREPARING_QUANTITATIFF")}></Loader>
      </Dimmer>
    );

    if (this.state.raiseWarning)
      return (
        <Modal
          size="tiny"
          open={this.state.raiseWarning}
          closeIcon
          onClose={() => this.unSetWarning()}
        >
          <Modal.Content>
            <div
              style={{
                textAlign: "center",
                padding: "2rem 1.6rem 1.6rem 1.6rem",
              }}
            >
              <p>{this.state.warningMessage}</p>
              <Button color="blue" onClick={() => this.unSetWarning()}>
                Fermer
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      );

    if (this.props.wallScheduleLoader) return dimmer();
    return (
      <div
        style={{
          display: "flex",
          marginTop: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{ height: 80, marginLeft: 20, width: 180 }}
          primary
          onClick={() => {
            writeOptimizedBooks(
              this.props,
              this.getUnitConversionArray,
              this.props.t,
              this.setWarning,
              this.props.config
            );
          }}
        >
          {/* Télécharger les quantitatifs */}
          {this.props.t("PER_FLOOR")}
        </Button>
        <Button
          style={{ height: 80, marginLeft: 20, width: 180 }}
          primary
          onClick={() => {
            writeOptimizedZoneBooks(
              this.props,
              this.getUnitConversionArray,
              this.props.t,
              this.setWarning,
              this.props.config
            );
          }}
        >
          {this.props.t("PER_FLOOR_PER_ZONE")}
        </Button>
      </div>
    );
  };
}

const mapStateToProps = (state: MetresStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    wallScheduleLoader: state.wallScheduleLoader,
    unitConversionArray: state.unitConversionArray,
    selections: state.selections,
    projectData: state.projectData,
    config: state.config,
  };
};

const mapDispatchToProps = {
  setWallScheduleLoader,
  setUnitConversionArray,
  loadSelections,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withTranslation(["quantitatiff"])(MetresDownloader));
