import { api } from "../../../../RevitJS/API";
import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  filter,
  cloneDeep,
  find,
  map,
  reduce,
  flattenDeep,
  values,
  uniqBy,
  uniq,
} from "lodash";
import { generateRandomColor } from "../../Utils";

const onProjectData = async () => {
  let projectData = await api.queries.getSetProjectData();
  if (projectData.ProjectId !== "") {
    return projectData.ProjectId;
  }
  return null;
};

export const getLevelElements = (
  levels: any,
  elements: any,
  activeItem: string
) => {
  // 1. clone elements.
  const cloneElements = cloneDeep(elements);

  // 2. filter out selected levels.
  const selectedLevels = filter(levels, { Checked: true });

  let walls: any = [];

  // 3. if any level is selected then filter out elements for selected level.
  let levelElements =
    selectedLevels.length > 0
      ? filter(cloneElements, (elem) => {
          return find(selectedLevels, { Id: elem.Levels.Id });
        })
      : cloneElements;

  // 4. based on what user selected for activeItem select elementsType or elementsThickness
  if (activeItem === "wallType") {
    walls = map(levelElements, "ElementsType");
  } else {
    walls = map(levelElements, "ElementsThickness");
  }

  // 5. use reduce to sum/concat Ids of same wall type or wall width
  if (walls.length > 0) {
    walls = flattenDeep(walls);
    walls = reduce(
      walls,
      function (result: any, value: any, key: any) {
        if (result[value.ElementName]) {
          result[value.ElementName].Ids = result[value.ElementName].Ids.concat(
            value.Ids
          );
        } else {
          result[value.ElementName] = value;
        }
        return result;
      },
      {}
    );
  }

  return walls;
};

export const onManualSelection = async (
  elementType: any
): Promise<{ elements: any; floors: any }> => {
  api.windowsHandler.hideWindow();
  // let tree = await api.selection.manualSelection(elementType);
  let tree = await new Promise((resolve, reject) =>
    window.indec.manualSelection(
      { isSingleSelection: false, category: elementType },
      resolve,
      reject
    )
  )
    .then((x: any) => {
      return JSON.parse(x);
    })
    .catch((ex: any) => {
      console.error(ex);
      return { Tree: [] };
    });
  api.windowsHandler.showWindow();
  if (tree.Tree.length > 0) {
    const elements = flattenDeep(tree.Tree.map((tr: any) => tr.Elements));
    const levels =
      uniqBy(flattenDeep(tree.Tree.map((tr: any) => tr.Level)), "Name") || [];
    const groupElements = reduce(
      elements,
      function (result: any, value: any, key: any) {
        (
          result[value.Type] ||
          (result[value.Type] = {
            Checked: true,
            Ids: [],
            ElementName: value.Type,
            ElementType: value.ObjectType,
          })
        ).Ids = result[value.Type].Ids.concat(value.Ids);
        return result;
      },
      {}
    );
    return { elements: values(groupElements), floors: levels };
  } else {
    return { elements: [], floors: [] };
  }
};

export const onDuplicateSelection = async (id: any, selectionList: any) => {
  var currentdate = new Date();
  let selectionToUpdate = await new Promise((resolve, reject) =>
    window.indec.getSelection(parseInt(id), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  //await bimStorage.getSelection(id);
  const count = filter(selectionList, (sl: any) =>
    sl.Name.includes(selectionToUpdate.Name)
  ).length;

  selectionToUpdate.Name = selectionToUpdate.Name + "_" + count;
  // selectionToUpdate.Date = `${currentdate.getDate()}/${currentdate.getMonth() + 1
  //   }/${currentdate.getFullYear()}`;
  selectionToUpdate.Date = currentdate.getTime();
  delete selectionToUpdate["Id"];
  map(selectionToUpdate.SelectionDetails, (sd) => delete sd["Id"]);

  const response = await bimStorage.saveSelection(selectionToUpdate);
  return response;
};

export const uniqueElements = (objects: any[]) => {
  const groupElements = reduce(
    objects,
    function (result: any, value: any, key: any) {
      let resultObject: any = {
        Checked: true,
        Ids: [],
        ElementName: value.ElementName,
        ElementType: value.ElementType,
        Id: null,
        SelectionId: null,
        Solution: null,
      };
      if (result[value.ElementName]) {
        resultObject = result[value.ElementName];
      }

      if (value.Id && value.SelectionId) {
        resultObject.Id = value.Id;
        resultObject.SelectionId = value.SelectionId;
      }
      if (value.Solution) {
        resultObject.Solution = value.Solution;
      }

      resultObject.Ids = uniq(resultObject.Ids.concat(value.Ids));
      result[value.ElementName] = cloneDeep(resultObject);
      return result;
    },
    {}
  );
  return groupElements;
};

export const uniqLevel = (levels: any[]) => {
  return uniqBy(levels, "Name");
};

export const selectionNameChangeUtil = async (id: any, name: string) => {
  var currentdate = new Date();
  let selectionToUpdate = await new Promise((resolve, reject) =>
    window.indec.getSelection(parseInt(id), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  //await bimStorage.getSelection(id);
  // selectionToUpdate.Date = `${currentdate.getDate()}/${currentdate.getMonth() + 1
  //   }/${currentdate.getFullYear()}`;
  selectionToUpdate.Date = currentdate.getTime();
  selectionToUpdate.Name = name;
  const response = await bimStorage.updateSelection(
    selectionToUpdate.Id,
    selectionToUpdate
  );
  return response;
};

export const colorChangeUtil = async (
  id: any,
  color: {
    r: string;
    g: string;
    b: string;
    a: string;
  }
) => {
  var currentdate = new Date();
  let selectionToUpdate = await new Promise((resolve, reject) =>
    window.indec.getSelection(parseInt(id), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  //await bimStorage.getSelection(id);
  // selectionToUpdate.Date = `${currentdate.getDate()}/${currentdate.getMonth() + 1
  //   }/${currentdate.getFullYear()}`;
  selectionToUpdate.Date = currentdate.getTime();
  selectionToUpdate.Color = {
    R: color.r,
    G: color.g,
    B: color.b,
    A: color.a,
  };
  const response = await bimStorage.updateSelection(
    selectionToUpdate.Id,
    selectionToUpdate
  );
  return response;
};

export const createNewSelection = async (name: string) => {
  const projectId = await bimStorage.onProjectData();
  if (projectId) {
    var currentdate = new Date();
    return {
      Name: name,
      Type: "Selection",
      Date: `${currentdate.getTime()}`,
      Color: generateRandomColor(),
      ProjectId: projectId,
      Floors: [],
      SelectionDetails: [],
    };
  }
  return null;
};

export const colorSelection = async (
  Id: any,
  name: string,
  color: {
    R: string;
    G: string;
    B: string;
    A: string;
  }
) => {
  let activeView = await api.queries.getActiveViewDetails();
  const nameTobe = (activeView.Name + "_" + name).replace(
    /[&\\/\\#, +()$~%.'":*?<>{}]/g,
    ""
  );
  let viewByName = await api.queries.getViewByName(nameTobe);

  if (viewByName) {
    await api.queries.deleteView(viewByName);
  }

  let newlyCreatedView = await api.queries.duplicateActiveView(nameTobe);
  await api.queries.setActiveView(newlyCreatedView);

  let activeViewObject = await api.queries.getActiveViewDetails();

  if (activeViewObject.Name === nameTobe) {
    const selectionIds = parseInt(Id);
    let selectionData = await new Promise((resolve, reject) =>
      window.indec.getSelection(selectionIds, resolve, reject)
    )
      .then((x: any) => {
        return x;
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      });
    // await bimStorage.getSelection(selectionIds);
    console.log(selectionData);
    let elements: string[] = [];
    selectionData.SelectionDetails.forEach((value: any) => {
      elements = elements.concat(value.Ids);
    });
    console.log(elements);
    console.log(color);

    const wallPartsIds = await api.queries.getPartsIds(elements);

    if (wallPartsIds && wallPartsIds.length > 0) {
      wallPartsIds.forEach((value: any) => {
        if (value.partsIds && value.partsIds.length > 0) {
          elements = elements.concat(value.partsIds);
        }
      });
    }

    let rgbColor = {
      Red: parseInt(color.R),
      Green: parseInt(color.G),
      Blue: parseInt(color.B),
    };

    let filter = await api.queries.createSelectionFilterForEye(
      nameTobe,
      elements
    );
    console.log(rgbColor);
    await api.viewHandler.setFilterColor(filter, rgbColor);
    const revitVersion = parseInt(await api.framework.getRevitVersion());
    if (revitVersion === 2023) {
      await api.queries.closeView(newlyCreatedView);
      await api.queries.setActiveView(newlyCreatedView);
    }
  }
};
