import _ from "lodash";

export const wait = (ms) => new Promise((r, j) => setTimeout(r, ms));

export const addFilterFields = (systemsDetails, filters) => {
    return systemsDetails.map((sys) => {
        filters.forEach((filter) => {
            let attr = sys.attributes.find((attr) => attr.aid === filter.aid);
            if (attr) {
                let attrValue;
                if (filter.type === "Numeric")
                    attrValue = attr.values[0].numericValue;
                else {
                    attrValue = attr.values[0].value;
                }
                sys[filter.name] = attrValue;
            } else {
                sys[filter.name] = undefined;
            }
        });
        return sys;
    });
};
export const setFiltersOptions = (
    filters,
    systemsDetails,
    update = false,
    restrictedFilter = null
) => {
    let filtersLocal = _.cloneDeep(filters);
    filtersLocal.forEach((filterLocal) => {
        if (
            update &&
            (restrictedFilter === null || filterLocal.name !== restrictedFilter)
        ) {
            let tmpOptions = [];
            systemsDetails.forEach((system) => {
                let attrValue = system[filterLocal.name];
                if (attrValue !== undefined) {
                    if (!tmpOptions.includes(attrValue)) {
                        tmpOptions.push(attrValue);
                    }
                } else {
                }
            });
            filterLocal.options = _.cloneDeep(tmpOptions);
        } else {
            systemsDetails.forEach((system) => {
                filterLocal.options = filterLocal.options || [];
                let attrValue = system[filterLocal.name];
                if (attrValue !== undefined) {
                    if (!filterLocal.options.includes(attrValue)) {
                        filterLocal.options.push(attrValue);
                    }
                }
            });
        }
    });
    return filtersLocal.map((filterLocal) => {
        filterLocal.options.sort((a, b) => {
            if (filterLocal.pimAttribute === "GFR-Fire protection") {
                return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
            } else {
                return a - b;
            }
        });
        return filterLocal;
    });
};

//

export const filterElements2 = (filters, partitionsDetails) => {
    return filters.reduce(
        (acc, current) => {
            if (current.options) {
                if (current.value) {
                    if (current.value === "") return acc;
                    let matchingDetails;
                    switch (current.criterion) {
                        case "GreaterThan":
                            return acc.filter((el, i) => {
                                matchingDetails = partitionsDetails.find(
                                    (details) =>
                                        details.technicalName ===
                                        el.technicalName
                                );
                                return (
                                    matchingDetails.filterFields[
                                        current.name
                                    ] >= current.value
                                );
                            });
                        case "Equals":
                            return acc.filter((el, i) => {
                                matchingDetails = partitionsDetails.find(
                                    (details) =>
                                        details.technicalName ===
                                        el.technicalName
                                );
                                return (
                                    matchingDetails.filterFields[
                                        current.name
                                    ] === current.value
                                );
                            });
                        case "Includes":
                            return acc.filter((el, i) => {
                                matchingDetails = partitionsDetails.find(
                                    (details) =>
                                        details.technicalName ===
                                        el.technicalName
                                );
                                return current.value.includes(
                                    matchingDetails.filterFields[current.name]
                                );
                            });
                        default:
                            return acc;
                    }
                }
                return acc;
            }
            return acc;
        },
        [...partitionsDetails]
    );
};

export const filterElements3 = (filters, partitionsDetails) => {
    return filters.reduce(
        (acc, current) => {
            if (current.options && current.value && current.value !== "") {
                let matchingDetails;
                switch (current.criterion) {
                    case "GreaterThan":
                        return acc.filter((el, i) => {
                            matchingDetails = partitionsDetails.find(
                                (details) =>
                                    details.technicalName === el.technicalName
                            );
                            // old not working code, did this fix that works but don't know what matchingDetails.filterFields is used for
                            // may cause regression
                            // return (
                            //     matchingDetails.filterFields[
                            //         current.name
                            //     ] >= current.value
                            // );
                            return (
                                matchingDetails[current.name] >= current.value
                            );
                        });
                    case "Equals":
                        return acc.filter((el, i) => {
                            matchingDetails = partitionsDetails.find(
                                (details) =>
                                    details.technicalName === el.technicalName
                            );
                            // old not working code, did this fix that works but don't know what matchingDetails.filterFields is used for
                            // may cause regression
                            // return (
                            //     matchingDetails.filterFields[
                            //         current.name
                            //     ] === current.value
                            // );
                            return (
                                matchingDetails[current.name] === current.value
                            );
                        });
                    case "Includes":
                        return acc.filter((el, i) => {
                            matchingDetails = partitionsDetails.find(
                                (details) =>
                                    details.technicalName === el.technicalName
                            );
                            // old not working code, did this fix that works but don't know what matchingDetails.filterFields is used for
                            // may cause regression
                            // return current.value.includes(
                            //     matchingDetails.filterFields[current.name]
                            // );
                            return current.value.includes(
                                matchingDetails[current.name]
                            );
                        });
                    case "Between":
                        if (current.value[0] && current.value[1]) {
                            return acc.filter((el, i) => {
                                matchingDetails = partitionsDetails.find(
                                    (details) =>
                                        details.technicalName ===
                                        el.technicalName
                                );
                                // this is new criteria (new code) but just in case matchingDetails.filterFields is used for something
                                // return (
                                //     current.value[0] >
                                //     matchingDetails[current.name] >
                                //     current.value[1]
                                // );

                                return (
                                    current.value[0] <=
                                        matchingDetails[current.name] &&
                                    matchingDetails[current.name] <=
                                        current.value[1]
                                );
                            });
                        } else {
                            return acc;
                        }

                    default:
                        return acc;
                }
            } else {
                return acc;
            }
        },
        [...partitionsDetails]
    );
};

export const getLayoutAttributesAid = (attributes) => {
    return {
        layers: attributes.find((i) => i.technicalName === "GFR-BIM layers")
            .aid,
        rails: attributes.find((i) => i.technicalName === "GFR-Product 3").aid,
        frames: attributes.find((i) => i.technicalName === "GFR-Product 2").aid,
        framesSpace: attributes.find(
            (i) => i.technicalName === "GFR-Distance between frames (in m)"
        ).aid,
        doubleFraming: attributes.find(
            (i) => i.technicalName === "GFR-Profiles (1 or 2)"
        ).aid,
        layerNumber: attributes.find(
            (i) => i.technicalName === "GFR-Layer number"
        ).aid,
        layerContent: attributes.find(
            (i) => i.technicalName === "GFR-Layer content"
        ).aid,
        layerName: attributes.find((i) => i.technicalName === "GFR-Layer name")
            .aid,
        layerThickness: attributes.find(
            (i) => i.technicalName === "GFR-Layer thickness mm"
        ).aid,
        plateWidth: attributes.find(
            (i) => i.technicalName === "A-Solution Width"
        ).aid,
        systemName: attributes.find(
            (i) => i.technicalName === "A-Solution product name"
        ).aid,
        technicalName: attributes.find(
            (i) => i.technicalName === "GFR-Profiles (1 or 2)"
        ).aid,
    };
};

export const makeDefaultLayer = (side, index) => {
    let layer = {};
    layer[`${side}${index}`] = "None";
    layer[`${side}${index}_Name`] = "None";
    return layer;
};

export const addDefaultLayer = (cladding, side) => {
    if (cladding.length >= 3) return cladding;
    let nb_layers_to_add = 3 - cladding.length;
    cladding = [...cladding];
    for (let i = 0; i < nb_layers_to_add; i++) {
        cladding.push(makeDefaultLayer(side, 3 - nb_layers_to_add + i + 1));
    }
    return cladding;
};

export const makeLayer = (side, index, layerThickness, layerName) => {
    let layer = {};
    layer[`${side}${index.toString()}`] = layerThickness;
    layer[`${side}${index.toString()}_Name`] = layerName;
    return layer;
};

export const parseLayers = (layers, attributesId) => {
    let layers_nb = layers.length;
    let firstCladding = true;
    let claddingA = [],
        claddingB = [],
        rails = [],
        frames = [];
    for (let j = 0; j < layers_nb; j++) {
        let layerAttributes = layers[j]?.subAttributes;
        let layerContent = layerAttributes?.find(
            (e) => e.aid === attributesId.layerContent
        )?.values[0].value;
        let layerThickness = layerAttributes?.find(
            (e) => e.aid === attributesId.layerThickness
        )?.values[0].numericValue;
        let layerName = layerAttributes?.find(
            (e) => e.aid === attributesId.layerName
        )?.values[0].value;
        if (layerContent === "Plaque de plâtre") {
            if (firstCladding) {
                claddingA.push(
                    makeLayer("E", j + 1, layerThickness, layerName)
                );
            } else {
                claddingB.push(
                    makeLayer("I", layers_nb - j, layerThickness, layerName)
                );
            }
        } else {
            firstCladding = false;
        }
    }
    claddingB.reverse();
    return {
        claddingA: addDefaultLayer(claddingA, "E"),
        claddingB: addDefaultLayer(claddingB, "I"),
        rails,
        frames,
    };
};

export const parseSystem = (
    system,
    attributesId,
    Reverse = false,
    Flipped = false
) => {
    let framesSpace = system.attributes.find(
        (e) => e.aid === attributesId.framesSpace
    )?.values[0].numericValue;
    let plateWidth = system.attributes.find(
        (e) => e.aid === attributesId.plateWidth
    )?.values[0].numericValue;
    let systemName = system.attributes.find(
        (e) => e.aid === attributesId.systemName
    )?.values[0].value;
    let layers = system.attributes.filter((e) => e.aid === attributesId.layers);
    layers.sort(function (a, b) {
        let a_number = a.subAttributes.find(
            (e) => e.aid === attributesId.layerNumber
        )?.values[0].numericValue;
        let b_number = b.subAttributes.find(
            (e) => e.aid === attributesId.layerNumber
        )?.values[0].numericValue;
        return a_number - b_number;
    });
    let systemElems = parseLayers(layers, attributesId);
    let result = {
        systemName,
        framesSpace: framesSpace * 1000,
        plateWidth,
        E1: systemElems.claddingA[0].E1,
        E2: systemElems.claddingA[1].E2,
        E3: systemElems.claddingA[2].E3,
        I1: systemElems.claddingB[0].I1,
        I2: systemElems.claddingB[1].I2,
        I3: systemElems.claddingB[2].I3,
        E1_Name: systemElems.claddingA[0].E1_Name,
        E2_Name: systemElems.claddingA[1].E2_Name,
        E3_Name: systemElems.claddingA[2].E3_Name,
        I1_Name: systemElems.claddingB[0].I1_Name,
        I2_Name: systemElems.claddingB[1].I2_Name,
        I3_Name: systemElems.claddingB[2].I3_Name,
        Reverse,
        Flipped,
        Lining: systemName?.includes("Doublage") ? true : false,
        railsSpace: 0,
        staggering: true,
        doubleFraming: false,
        doubleAmount: false,
        Frame1_Name: "Frame",
        Rail1_Name: "Rail",
        SAA: systemName?.includes("SAA") ? true : false,
        SAD: systemName?.includes("SAD") ? true : false,
    };

    if (result.E1 === "None") {
        let swapper;

        swapper = result.I1;
        result.I1 = result.E1;
        result.E1 = swapper;

        swapper = result.I1_Name;
        result.I1_Name = result.E1_Name;
        result.E1_Name = swapper;

        swapper = result.I2;
        result.I2 = result.E2;
        result.E2 = swapper;

        swapper = result.I2_Name;
        result.I2_Name = result.E2_Name;
        result.E2_Name = swapper;

        swapper = result.I3;
        result.I3 = result.E3;
        result.E3 = swapper;

        swapper = result.I3_Name;
        result.I3_Name = result.E3_Name;
        result.E3_Name = swapper;
    }

    return result;
};

export const setFiltersAid = (filters, attributes) => {
    return filters.map((filter) => {
        return {
            ...filter,
            aid: attributes.find(
                (att) => filter.pimAttribute === att.technicalName
            )?.aid,
        };
    });
};

export const getSelectedItems = (data, selectedLevels, selectedTypes) => {
    if (selectedLevels.length > 0 && selectedTypes.length > 0)
        return groupByArray(
            data.Tree.filter((level) =>
                selectedLevels.includes(level.Level.Name)
            )
                .map((level) =>
                    level.Elements.map((lev) => {
                        return { ...lev, Level: level.Level.Name };
                    })
                )
                .map((s) => s.map((nested) => nested))
                .reduce((prev, current) => prev.concat(current))
                .filter((e) => selectedTypes.includes(e.Type)),
            "Type"
        ).map((el) => {
            return {
                Type: el.key,
                Ids: [].concat.apply(
                    [],
                    el.values.map((val) => val.Ids)
                ),
                Levels: [].concat.apply(
                    [],
                    el.values.map((val) => val.Level)
                ),
            };
        });
    return [];
};

export const selectedItemsManualSelection = (data) => {
    return groupByArray(
        data.Tree.map((level) =>
            level.Elements.map((lev) => {
                return { ...lev, Level: level.Level.Name };
            })
        )
            .map((s) => s.map((nested) => nested))
            .reduce((prev, current) => prev.concat(current), []),
        "Type"
    ).map((el) => {
        return {
            Type: el.key,
            Ids: [].concat.apply(
                [],
                el.values.map((val) => val.Ids)
            ),
            Levels: [].concat.apply(
                [],
                el.values.map((val) => val.Level)
            ),
        };
    });
};

export const chunkArray = (arr, chunk = 10) => {
    let res = [];
    var i, j, temparray;
    for (i = 0, j = arr.length; i < j; i += chunk) {
        temparray = arr.slice(i, i + chunk);
        res.push(temparray);
        // do whatever
    }
    return res;
};

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);
        if (el) {
            el.values.push(x);
        } else {
            rv.push({ key: v, values: [x] });
        }
        return rv;
    }, []);
}

export const mergeItems = (arr) =>

    groupByArray(arr, "Type").map((el, i) => {
        console.log(el)
        return {
            Type: el.key,
            Count: el.values.reduce(function (prev, cur) {
                return prev + cur.Count;
            }, 0),
            Checked: false,
            Ids: el.values.reduce(function (prev, cur) {
                return [...prev, ...cur.Ids];
            }, []),
        };
    });

export const elementTypes = (data) => {
    

    return data.Tree.length > 0
        ? mergeItems(
              data.Tree.map((level) => level.Elements)
                  .map((s) =>
                      s.map((nested) => {
                          return {
                              Type: nested.Type,
                              Count: nested.Ids.length,
                              Checked: false,
                              Ids: nested.Ids
                          };
                      })
                  )
                  .reduce((prev, current) => prev.concat(current))
                  .sort((a, b) => (a.Type < b.Type ? -1 : 1))
          )
        : null;
}

export const elementTypesByLevel = (data) => {
    if (data.Tree.length > 0) {
        let levelsAndElements = data.Tree.map((level) => {
            return { elements: level.Elements, level: level.Level };
        });
        let tmp = levelsAndElements
            .map((levelAndElement) => {
                return levelAndElement.elements.map((nested) => {
                    return {
                        Level: levelAndElement.level,
                        Type: nested.Type,
                        Count: nested.Ids.length,
                        Checked: false,
                        Ids: nested.Ids
                    };
                });
            })
            .reduce((prev, current) => prev.concat(current))
            .sort((a, b) => (a.Type < b.Type ? -1 : 1));
        return mergeItemsByLevel(tmp);
    }
};
export const mergeItemsByLevel = (arr) =>
    groupByArray(arr, "Type").map((el,i) => {
        return {
            Levels: el.values.map((l) => l.Level),
            Type: el.key,
            Count: el.values.reduce(function (prev, cur) {
                return prev + cur.Count;
            }, 0),
            Checked: false,
            Ids: el.values.reduce(function (prev, cur) {
                return [...prev, ...cur.Ids];
            }, []),
        };
    });

export const levelData = (data, levellist) => {
    if (levellist.length === 0) return data;
    let levelsData = data.Tree.filter((level) =>
        levellist.includes(level.Level.Name)
    );
    return { Tree: levelsData };
};

export const escapeSpecialChars = (jsonStr) => {
    return jsonStr.replace(/\\n/g, "\\n")
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, "\\&")
    .replace(/\\r/g, "\\r")
    .replace(/\\t/g, "\\t")
    .replace(/\\b/g, "\\b")
    .replace(/\\f/g, "\\f");
}