import React, {  useState, useEffect, Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import AppBodyDelete from "./AppBody";
import { composeWithDevTools } from "redux-devtools-extension";
import { setPluginDataToDB } from "../../../../Helper";
import SaveProject from "../../CommonModules/SaveProject";
import { api } from "../../../../RevitJS/API";
import { bimStorage } from "../../../../BIMStore";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";
type Props = InjectedCounterProps;

export enum Routes {
  ROOT = "SelectionLanding",
  GROUP_SELECTION = "GROUP_SELECTION",
  SELECTION_HOME = "SELECTION_HOME",
}

interface State {
  route: Routes;
}


const SuppressionRoot = (props: any) => {
    const [route, setRoute] = useState(Routes.ROOT);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    // public store: Store;

    // store = createStore(
    //     reducers,
    //     composeWithDevTools(applyMiddleware(thunk))
    //   );

    const setRouteSuppression = (route: Routes) => {
        setRoute(route);
    }
    const toggleModal = () => {
      setConfirmModal(!confirmModal);
    };
    const onYesAction = () => {
      toggleModal();
      api.queries.callSaveDialog();
      api.windowsHandler.closeWindow();
    };
    useEffect(() => {
        const queryString = window.location.hash;
        const urlParams = new URLSearchParams(queryString.split("?")[1]);
        if (urlParams.get("token")) {
            setPluginDataToDB("token", urlParams.get("token")!);
        }
        if (urlParams.get("language")) {
            setPluginDataToDB("savedLanguage", urlParams.get("language")!);
        }
    }, []);

    return (
        <AppBodyDelete
          route={route}
          setRoute={setRouteSuppression}
          onError={props.onError}
        />
    )

}

export default withErrorSubscription(SuppressionRoot);