import React, { Component } from "react";
import AppBody from "./AppBody";
import { api } from "../../../../RevitJS/API";

interface Props {}

export enum Routes {
  ROOT = "WAITER",
}

interface State {
  route: Routes;
}

export class ShowWallsRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    // api.windowsHandler.showDevTools();
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return <AppBody route={this.state.route} setRoute={this.setRoute} />;
  };
}
