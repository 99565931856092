import _ from "lodash";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";

const updateSelectionIfWallDeleted = async (config: any) => {
  //add logic to remove deleted walls from modal
  const placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
  const RevitProject = await api.queries.getSetProjectData();

  // if walls used in the dossier has been deleted from the modal

  // get all walls and ceilings from modal
  const wallTree = await api.selection.elementsByLevelAndType("wall");
  const ceilingTree = await api.selection.elementsByLevelAndType("ceiling");

  const getAllTypeIdsfromSelection = async (typeTree: any) => {
    const LeveWiseObjectIds: any = {};

    typeTree.forEach((level: any) => {
      const levelTypeIds: any = _.flatten(
        level.Elements.map((e: any) => {
          return e.Ids;
        })
      );

      const ArrLenght = levelTypeIds.length;
      const newObj: any = {};
      for (let i = 0; i < ArrLenght; i++) {
        newObj[levelTypeIds[i]] = levelTypeIds[i];
      }

      LeveWiseObjectIds[level.Level.Name] = newObj;
    });
    return LeveWiseObjectIds;
  };

  const getIdsByItsTypefromSelection = async (typeTree: any) => {
    const TypeWiseObjectIds: any = {};
    let allTypes: any[] = [];
    typeTree.forEach((level: any) => {
      const types: any = _.flatten(
        level.Elements.map((e: any) => {
          return e.Type;
        })
      );

      allTypes = [...allTypes, ...types];
    });
    var uniqueTypes = allTypes.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    uniqueTypes.forEach((element) => {
      let typeIds: any[] = [];
      const newObj: any = {};
      typeTree.forEach((level: any) => {
        let type = level.Elements.filter((e: any) => {
          return e.Type === element;
        });

        if (type && type.length > 0) {
          typeIds = [...typeIds, ...type[0].Ids];
        }
      });
      for (let i = 0; i < typeIds.length; i++) {
        newObj[typeIds[i]] = typeIds[i];
      }

      TypeWiseObjectIds[element] = newObj;
    });

    return TypeWiseObjectIds;
  };

  const allWallIds: any = await getAllTypeIdsfromSelection(wallTree.Tree);
  const allCeilingsIds: any = await getAllTypeIdsfromSelection(
    ceilingTree.Tree
  );

  const allWallIdsByType: any = await getIdsByItsTypefromSelection(
    wallTree.Tree
  );
  const allCeilingIdsByType: any = await getIdsByItsTypefromSelection(
    ceilingTree.Tree
  );

  const updatedSelections = Object.values(placoSelections).reduce(
    (acc: any, curr: any) => {
      if (RevitProject.ProjectId === curr.RevitView) {
        // if the selection project id matches the current project id

        // create level array because only from one level it could be deleted we need to keep track
        const levelArr = new Set();
        let changedWallsIds: any[] = [];
        let changedCeilingsIds: any[] = [];

        // loop over every Wall
        let walls: any = curr.SelectionByType.wall.Rows.map((e: any) => {
          e.RevitSelection.Ids = e.RevitSelection.Ids.filter((a: any) => {
            for (const [key, value] of Object.entries(allWallIdsByType)) {
              const wall: any = value;
              if (wall[a] && key === e.RevitSelection.RevitType) {
                return true;
              } else if (!wall[a] && key === e.RevitSelection.RevitType) {
                let row = JSON.parse(JSON.stringify(e));
                let typeName: string = "";
                for (const [key, value] of Object.entries(allWallIdsByType)) {
                  const wallIds: any = value;
                  if (wallIds[a]) {
                    typeName = key;
                    break;
                  }
                }

                let isAdded: boolean = false;
                changedWallsIds.forEach((revitRow) => {
                  if (revitRow.RevitSelection.RevitType === typeName) {
                    if (!revitRow.RevitSelection.Ids.includes(a)) {
                      revitRow.RevitSelection.Ids = [
                        ...revitRow.RevitSelection.Ids,
                        a,
                      ];
                    }
                    isAdded = true;
                  }
                });

                let indexValue = 0;
                if (!isAdded && typeName !== "") {
                  if (changedWallsIds.length > 0) {
                    indexValue =
                      changedWallsIds[changedWallsIds.length - 1].Index + 1;
                  } else {
                    indexValue =
                      curr.SelectionByType.wall.Rows.length === 1
                        ? 0
                        : curr.SelectionByType.wall.Rows.length;
                  }

                  row.RevitSelection.RevitType = typeName;
                  row.RevitSelection.Ids = [a];
                  row.Index = indexValue;
                  for (const [key, value] of Object.entries(allWallIds)) {
                    const wall: any = value;
                    if (wall[a]) {
                      // 2. if any wall from the level exist add that level to level arr
                      levelArr.add(key);
                    }
                  }
                  changedWallsIds.push(row);
                }
              } else if (wall[a] && key !== e.RevitSelection.RevitType) {
                let row = JSON.parse(JSON.stringify(e));
                let typeName: string = key;

                let isAdded: boolean = false;
                changedWallsIds.forEach((revitRow) => {
                  if (revitRow.RevitSelection.RevitType === typeName) {
                    if (!revitRow.RevitSelection.Ids.includes(a)) {
                      revitRow.RevitSelection.Ids = [
                        ...revitRow.RevitSelection.Ids,
                        a,
                      ];
                    }
                    isAdded = true;
                  }
                });

                let indexValue = 0;
                if (!isAdded) {
                  if (changedWallsIds.length > 0) {
                    indexValue =
                      changedWallsIds[changedWallsIds.length - 1].Index + 1;
                  } else {
                    indexValue =
                      curr.SelectionByType.wall.Rows.length === 1
                        ? 0
                        : curr.SelectionByType.wall.Rows.length;
                  }

                  row.RevitSelection.RevitType = typeName;
                  row.RevitSelection.Ids = [a];
                  row.Index = indexValue;
                  for (const [key, value] of Object.entries(allWallIds)) {
                    const wall: any = value;
                    if (wall[a]) {
                      // 2. if any wall from the level exist add that level to level arr
                      levelArr.add(key);
                    }
                  }
                  changedWallsIds.push(row);
                }
              }
            }
          });

          e.RevitSelection.Ids = e.RevitSelection.Ids.filter((a: any) => {
            // 1. check if the id exist in every level
            for (const [key, value] of Object.entries(allWallIds)) {
              const wall: any = value;
              if (wall[a]) {
                // 2. if any wall from the level exist add that level to level arr
                levelArr.add(key);
                return true;
              }
            }
          });

          return e;
        });

        if (changedWallsIds && changedWallsIds.length > 0) {
          changedWallsIds.forEach((changedwallRow) => {
            let existingWall = walls.filter((w: any) => {
              return (
                w.RevitSelection.RevitType ==
                changedwallRow.RevitSelection.RevitType
              );
            });

            if (existingWall && existingWall.length > 0) {
              existingWall[0].RevitSelection.Ids = [
                ...existingWall[0].RevitSelection.Ids,
                ...changedwallRow.RevitSelection.Ids,
              ];
            } else {
              walls = [...walls, changedwallRow];
            }
          });
        }

        // loop over every Ceiling
        let ceilings: any = curr.SelectionByType.ceiling.Rows.map((e: any) => {
          e.RevitSelection.Ids = e.RevitSelection.Ids.filter((a: any) => {
            for (const [key, value] of Object.entries(allCeilingIdsByType)) {
              const ceiling: any = value;
              if (ceiling[a] && key === e.RevitSelection.RevitType) {
                return true;
              } else if (!ceiling[a] && key === e.RevitSelection.RevitType) {
                let row = JSON.parse(JSON.stringify(e));
                let typeName: string = "";
                for (const [key, value] of Object.entries(
                  allCeilingIdsByType
                )) {
                  const ceilingIds: any = value;
                  if (ceilingIds[a]) {
                    typeName = key;
                    break;
                  }
                }

                let isAdded: boolean = false;
                changedCeilingsIds.forEach((revitRow) => {
                  if (revitRow.RevitSelection.RevitType === typeName) {
                    if (!revitRow.RevitSelection.Ids.includes(a)) {
                      revitRow.RevitSelection.Ids = [
                        ...revitRow.RevitSelection.Ids,
                        a,
                      ];
                    }
                    isAdded = true;
                  }
                });

                let indexValue = 0;
                if (!isAdded && typeName !== "") {
                  if (changedCeilingsIds.length > 0) {
                    indexValue =
                      changedCeilingsIds[changedCeilingsIds.length - 1].Index +
                      1;
                  } else {
                    indexValue =
                      curr.SelectionByType.ceiling.Rows.length === 1
                        ? 0
                        : curr.SelectionByType.ceiling.Rows.length;
                  }

                  row.RevitSelection.RevitType = typeName;
                  row.RevitSelection.Ids = [a];
                  row.Index = indexValue;
                  for (const [key, value] of Object.entries(allCeilingsIds)) {
                    const ceiling: any = value;
                    if (ceiling[a]) {
                      // 2. if any wall from the level exist add that level to level arr
                      levelArr.add(key);
                    }
                  }
                  changedCeilingsIds.push(row);
                }
              } else if (ceiling[a] && key !== e.RevitSelection.RevitType) {
                let row = JSON.parse(JSON.stringify(e));
                let typeName: string = key;

                let isAdded: boolean = false;
                changedCeilingsIds.forEach((revitRow) => {
                  if (revitRow.RevitSelection.RevitType === typeName) {
                    if (!revitRow.RevitSelection.Ids.includes(a)) {
                      revitRow.RevitSelection.Ids = [
                        ...revitRow.RevitSelection.Ids,
                        a,
                      ];
                    }
                    isAdded = true;
                  }
                });

                let indexValue = 0;
                if (!isAdded) {
                  if (changedCeilingsIds.length > 0) {
                    indexValue =
                      changedCeilingsIds[changedCeilingsIds.length - 1].Index +
                      1;
                  } else {
                    indexValue =
                      curr.SelectionByType.ceiling.Rows.length === 1
                        ? 0
                        : curr.SelectionByType.ceiling.Rows.length;
                  }

                  row.RevitSelection.RevitType = typeName;
                  row.RevitSelection.Ids = [a];
                  row.Index = indexValue;
                  for (const [key, value] of Object.entries(allCeilingsIds)) {
                    const ceiling: any = value;
                    if (ceiling[a]) {
                      // 2. if any wall from the level exist add that level to level arr
                      levelArr.add(key);
                    }
                  }
                  changedCeilingsIds.push(row);
                }
              }
            }
          });

          e.RevitSelection.Ids = e.RevitSelection.Ids.filter((a: any) => {
            // 1. check if the id exist in every level
            for (const [key, value] of Object.entries(allCeilingsIds)) {
              const ceiling: any = value;
              if (ceiling[a]) {
                // 2. if any wall from the level exist add that level to level arr
                levelArr.add(key);
                return true;
              }
            }
          });
          return e;
        });

        if (changedCeilingsIds && changedCeilingsIds.length > 0) {
          changedCeilingsIds.forEach((changedCeilingRow) => {
            let existingCeiling = ceilings.filter((w: any) => {
              return (
                w.RevitSelection.RevitType ==
                changedCeilingRow.RevitSelection.RevitType
              );
            });

            if (existingCeiling && existingCeiling.length > 0) {
              existingCeiling[0].RevitSelection.Ids = [
                ...existingCeiling[0].RevitSelection.Ids,
                ...changedCeilingRow.RevitSelection.Ids,
              ];
            } else {
              ceilings = [...ceilings, changedCeilingRow];
            }
          });
        }

        acc[curr.Id] = curr;
        acc[curr.Id].SelectionByType.wall.Rows = walls.filter((wall: any) => {
          return wall.RevitSelection.Ids.length > 0;
        });

        acc[curr.Id].SelectionByType.ceiling.Rows = ceilings.filter(
          (ceiling: any) => {
            return ceiling.RevitSelection.Ids.length > 0;
          }
        );

        if (acc[curr.Id].Levels) {
          acc[curr.Id].Levels = acc[curr.Id].Levels.filter((level: string) => {
            return levelArr.has(level);
          });
        }

        if (
          acc[curr.Id].SelectionByType.wall.Rows.length === 0 &&
          acc[curr.Id].SelectionByType.ceiling.Rows.length === 0 &&
          acc[curr.Id].SelectionByType.others.Rows.length === 0
        ) {
          delete acc[curr.Id];
        }
      } else {
        // if selection is not from current Project ignore
        acc[curr.Id] = curr;
      }

      return acc;
    },
    {}
  );

  bimStorage.setItem(
    storageKey.PLACOSELECTIONS,
    JSON.stringify(updatedSelections)
  );

  return true;
};

export default updateSelectionIfWallDeleted;
