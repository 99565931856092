import React, { Component } from "react";
import { api } from "../../../../RevitJS/API";
import AppBody from "./AppBody";
import {
  withErrorSubscription,
  InjectedCounterProps,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps;

export enum Routes {
  ROOT = "Waiter",
}

interface State {
  route: Routes;
}

class ColorizationRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    // api.windowsHandler.showDevTools();
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <AppBody
        route={this.state.route}
        setRoute={this.setRoute}
        onError={this.props.onError}
      />
    );
  };
}

export default withErrorSubscription(ColorizationRoot);
