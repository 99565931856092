import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import {
  resetCurrentProject,
  saveProject,
  setValidatedItems,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  STEP_4_COUPLING_SELECTION,
} from "../../assets/constants";
import StateModel from "../../models/State.model";
import { NEED_COUPLING_TECHNICAL_NAME } from "../../services/constants.config";
import { getCouplingsForSelectedRanges } from "../../services/projects.service";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./couplingSelection.scss";

// import "./elementSelection.scss";

class CouplingSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: true,
    };
    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.projectMenu = this.projectMenu.bind(this);
  }

  componentDidMount() {
    let { validatedItems } = this.props;
    this.setState({
      ...this.state,
      loading: true,
    });
    getCouplingsForSelectedRanges(
      validatedItems,
      this.props.projectRegion
    ).then((items: any) => {
      this.props.setValidatedItems(items);
      this.setState({
        loading: false,
      });
      let notFoundRangeThatNeedsCoupling: boolean = true;
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (
          item.range.attributes.find((attr: any) => {
            return attr.technicalName === NEED_COUPLING_TECHNICAL_NAME;
          })?.values[0]?.value === "True"
        ) {
          notFoundRangeThatNeedsCoupling = false;
          break;
        }
      }
      if (notFoundRangeThatNeedsCoupling) {
        this.nextPage();
      }
    });
  }

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.RANGE_SELECTION_WINDOW);
  }

  async nextPage() {
    let { selectWindowToShow, saveProject } = this.props;
    saveProject();
    selectWindowToShow(AppWindowName.RECAP_WINDOW);
  }

  render() {
    let { validatedItems, t } = this.props;
    return (
      <div id="coupling-selection-container">
        <Header displayName={t(STEP_4_COUPLING_SELECTION) || ""}></Header>
        <Body
          items={validatedItems}
          loading={this.state.loading}
          setValidatedItems={this.props.setValidatedItems}
        ></Body>
        <span></span>
        <Footer
          savedProjectsButtonShow={true}
          savedProjectsButtonDisabled={false}
          savedProjectButtonClicked={this.projectMenu}
          disabledNextButton={false}
          showNextButton={true}
          clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    validatedItems: state.projects.currentProject.validatedItems,
    projectRegion: state.projects.currentProject.country,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  setValidatedItems,
  saveProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { loading: boolean };

export default connector(withTranslation(["pam"])(CouplingSelection));
