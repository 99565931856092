import Axios from "axios";
import { myConfigSync } from "../../Shared/myConfig";

const Configuration = myConfigSync();
const baseURL = Configuration.REACT_APP_MODEL_GEN_API_URL;
const modelGeneratorURL = Configuration.REACT_APP_MODEL_GEN_API_URL;
const env = Configuration.REACT_APP_MODEL_GEN_ENV;

// Axios.interceptors.request.use((request) => {
//     if(request.responseType !== "arraybuffer")
//     {
//         // request.headers.Authorization = "Bearer " + localStorage.getItem("token");
//         request.headers.Accept = "application/json";
//         request.headers["Content-Type"] = "application/json";
//     }
//     return request
// });

// Axios.interceptors.response.use((response) => {
//     return response
// });

export const getBusinessProducts = async (
  business: string,
  region: string,
  language: string,
  regionName: string,
  type: any = null
) => {
  let obj: any = {};
  let arr: any[] = [];

  console.log(type);
  let businessid = business.split("+")[0];
  let isPam = type !== null && type !== "" ? true : false;
  let reqUrl = `${baseURL}/api/products/${env}/${businessid}/${region}/${language}/${regionName}/${isPam}/${type}`;

  const responseLayout = await Axios.get(reqUrl)
    .then((response) => {
      return response;
    })
    .then((response) => {
      let list = response === null ? [] : response.data.data;
      console.log(list);

      arr = list.productList.map((product: any) => {
        let rObj = {
          productId: product.productId,
          productCode: product.productCode,
          productName: product.productName,
          filterData: product.filterData,
        };
        return rObj;
      });

      obj.data = {
        list: arr,
        filters: response.data.productFilters,
      };

      obj.statusCode = 200;
      obj.statusText = "success";
      obj.status = true;
      return obj;
    })
    .catch((err) => {
      console.log(err);
      obj.status = false;
      obj.statusCode = 500;
      obj.statusText = err.message;
      obj.data = [];
      return obj;
    });
  return responseLayout;
};

export const ValidationObjects = async (
  selectedProduct: any,
  language: any,
  mapping: any,
  business: any,
  format: any,
  version: any,
  type: any,
  region: any,
  regionName: any,
) => {
  let obj: any = {};

  let pimIdList: any[] = [];
  // selectedProduct.slice(0, 50).forEach(function (element: any, i: any) {pimIdList.push(element.productId);});
  selectedProduct.forEach(function (element: any, i: any) {
    pimIdList.push(element.productId);
  });
  let container = business.split("+")[1];
  let mappingInfo = null;
  if (mapping) {
    mappingInfo = mapping;
  }

  let reqUrl = null;
  reqUrl = `${baseURL}/v2/validation/products/${env}/${language}/${mappingInfo}/${container}/${region}/${regionName}/${format}/${version}`;

  const responseLayout = await Axios.post(reqUrl, {
    productId: pimIdList,
    type: type,
  })
    .then((response) => {
      return response;
    })
    .then((response) => {
      let list = response === null ? [] : response.data.data;
      console.log(list);
      obj.data = list;
      obj.statusCode = 200;
      obj.statusText = "success";
      obj.status = true;
      return obj;
    })
    .catch((err) => {
      console.log(err);
      obj.status = false;
      obj.statusCode = 500;
      obj.statusText = err.message;
      obj.data = [];
      return obj;
    });
  return responseLayout;
};

export const StoreGeneratedObjects = async (
  selectedProduct: any,
  dataFolder: any,
  productId: any
) => {
  let obj: any = {};
  let pimIdList: any[] = [];

  let reqUrl = null;
  reqUrl = `${modelGeneratorURL}/contrib/modelgenerator2/storeGenerated`;

  const responseLayout = await Axios.post(reqUrl, {
    productId: pimIdList,
  })
    .then((response) => {
      return response;
    })
    .then((response) => {
      obj.statusCode = 200;
      obj.statusText = "success";
      obj.status = true;
      return obj;
    })
    .catch((err) => {
      obj.status = false;
      obj.statusCode = 500;
      obj.statusText = err.message;
      obj.data = [];
      return obj;
    });
  return responseLayout;
};
