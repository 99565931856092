import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../Common/assets/attributes.injection";
import {
  FILTER_VALUE_TYPE,
  FILTER_CRITERION_TYPE,
  FILTER_CHOICE_TYPE,
} from "../assets/constants";

export const placoFilters = {
  Walls: [
    {
      name: "Product Category",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.ACTIVITY,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Area Application",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AREA_OF_APPLICATION,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Material Finishing​",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.SUB_CATEGORY,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Total Thickness",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TOTAL_THICKNESS,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
  ],
  Floor: [
    {
      name: "Product Category​",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.ACTIVITY,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Area Application",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AREA_OF_APPLICATION,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Material Finishing​",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.SUB_CATEGORY,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      name: "Total Thickness",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TOTAL_THICKNESS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
  ],
};
