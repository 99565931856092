import React from "react";
import { withTranslation } from "react-i18next";
import PixelAccordion from "../../../../common/pixelAccordion/pixelAccordion";
import DiameterFilters from "../DiameterFilters/DiameterFilters";
import "./angleFilters.scss";

const AngleFilters = ({
    handleFilter,
    filterObjectName,
    categorizedElementsLocal,
    t,
}: any) => {
    return (
        <>
            {Object.keys(categorizedElementsLocal[filterObjectName]).map(
                (filterKey, index) => {
                    return (
                        <div
                            className="inner-filter-angle"
                            key={"inner-filter-angle-" + index}
                        >
                            <PixelAccordion
                                key={
                                    "pixel-accordion-item-filter-angle-" + index
                                }
                                headLabel={
                                    <div className="inner-filter-angle-div">
                                        {filterKey === "noAngle" ? (
                                            <strong>{`${t(
                                                "NO_ANGLE"
                                            )} :`}</strong>
                                        ) : (
                                            <strong>{`Angle ${filterKey} :`}</strong>
                                        )}
                                    </div>
                                }
                            >
                                <DiameterFilters
                                    handleFilter={handleFilter}
                                    filterObjectRaw={
                                        categorizedElementsLocal[
                                            filterObjectName
                                        ]
                                    }
                                    filterObjectName={filterObjectName}
                                    filterKey={filterKey}
                                ></DiameterFilters>
                            </PixelAccordion>
                        </div>
                    );
                }
            )}
        </>
    );
};

export default withTranslation(["pam"])(AngleFilters);
