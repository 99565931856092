import { bdd } from "../../../PAM/pam-import/helpers/interceptors";

export const getPlacoBrand = (bdd: any) =>
  bdd("/objects/class/Brand/locale/en", { text: "PLACO" });

export const getPlacoProducts = (bdd: any, oid: any) =>
  bdd("/objects/class/SGObject/locale/en", {
    dependencies: [
      {
        className: "Brand",
        oids: [oid],
      },
    ],
    types: ["Solution Product"],
    principal: true,
  });

export const setLiningAttributesAid = (layoutAttributesAid: any) => {
  return {
    type: "SET_LINING_WALLS_ATTRIBUTES_AID",
    layoutAttributesAid,
  };
};

export const handleSystemDetails = async (ids: any) => {
  let idsLength = ids.length;
  let idsStartLength = 0;
  let idsEndlength = 0;
  let partitionsDetails: any = [],
    attributes: any = [];
  do {
    idsEndlength = idsEndlength + 399;
    if (idsEndlength > idsLength) {
      idsEndlength = idsLength;
    }

    let sliceIds = ids.slice(idsStartLength, idsEndlength);
    let systemDetailsRequest = await getPlacoSystemsDetails(bdd, sliceIds);
    partitionsDetails = [
      ...partitionsDetails,
      ...systemDetailsRequest.data.objects,
    ];
    attributes = [
      ...attributes,
      ...systemDetailsRequest.data.context.attributeDependencies[0].attributes,
    ];
    idsStartLength = idsStartLength + 399;
  } while (idsEndlength < idsLength);

  return { partitionsDetails, attributes };
};

export const getPlacoSystemDetails = (bdd: any, systemOid: any) =>
  bdd("/objects/details/class/SGObject/locale/en", [systemOid]);
export const getPlacoSystemsDetails = (bdd: any, systemOid: any) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid);

export const getLayoutAttributesAid = (attributes: any) => {
  return {
    layers: attributes.find((i: any) => i.technicalName === "GFR-BIM layers")
      ?.aid,
    rails: attributes.find((i: any) => i.technicalName === "GFR-Product 3")
      ?.aid,
    frames: attributes.find((i: any) => i.technicalName === "GFR-Product 2")
      ?.aid,
    framesSpace: attributes.find(
      (i: any) => i.technicalName === "GFR-Distance between frames (in m)"
    )?.aid,
    doubleFraming: attributes.find(
      (i: any) => i.technicalName === "GFR-Profiles (1 or 2)"
    )?.aid,
    layerNumber: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer number"
    )?.aid,
    layerContent: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer content"
    )?.aid,
    layerName: attributes.find((i: any) => i.technicalName === "GFR-Layer name")
      ?.aid,
    layerThickness: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer thickness mm"
    )?.aid,
    plateWidth: attributes.find(
      (i: any) => i.technicalName === "A-Solution Width"
    )?.aid,
    systemName: attributes.find(
      (i: any) => i.technicalName === "A-Solution product name"
    )?.aid,
  };
};

export const getLiningWallsAttributesAid = (attributes: any) => {
  return {
    layers: attributes.find((i: any) => i.technicalName === "GFR-BIM layers")
      .aid,
    rails: attributes.find((i: any) => i.technicalName === "GFR-Product 3").aid,
    frames: attributes.find((i: any) => i.technicalName === "GFR-Product 2")
      .aid,
    framesSpace: attributes.find(
      (i: any) => i.technicalName === "GFR-Distance between frames (in m)"
    ).aid,
    doubleFraming: attributes.find(
      (i: any) => i.technicalName === "GFR-Profiles (1 or 2)"
    ).aid,
    layerNumber: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer number"
    ).aid,
    layerContent: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer content"
    ).aid,
    layerName: attributes.find((i: any) => i.technicalName === "GFR-Layer name")
      .aid,
    layerThickness: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer thickness mm"
    ).aid,
    plateWidth: attributes.find(
      (i: any) => i.technicalName === "A-Solution Width"
    ).aid,
    systemName: attributes.find(
      (i: any) => i.technicalName === "A-Solution product name"
    ).aid,
    technicalName: attributes.find(
      (i: any) => i.technicalName === "GFR-Profiles (1 or 2)"
    ).aid,
  };
};

export const setFiltersAid = (filters: any, attributes: any) => {
  return filters.map((filter: any) => {
    return {
      ...filter,
      aid: attributes.find(
        (att: any) => filter.pimAttribute === att.technicalName
      ).aid,
    };
  });
};

export const addFilterFields = (systemsDetails: any, filters: any) => {
  return systemsDetails.map((sys: any) => {
    filters.map((filter: any) => {
      let attr = sys.attributes.find((attr: any) => attr.aid === filter.aid);
      if (attr) {
        let attrValue;
        if (filter.type === "Numeric") attrValue = attr.values[0].numericValue;
        else {
          attrValue = attr.values[0].value;
        }
        return (sys[filter.name] = attrValue);
      } else {
        return (sys[filter.name] = undefined);
      }
    });
    return sys;
  });
};

export const addLayoutPossibleField = (
  systemDetails: any,
  system: any,
  attributes: any
) => {
  const layoutPossibleAid = attributes.find(
    (i: any) => i.technicalName === "GFR-Layout possible"
  ).aid;
  const layoutPossibleName = "layoutPossible";

  return systemDetails.map((sys: any) => {
    let attr = sys.attributes.find(
      (attr: any) => attr.aid === layoutPossibleAid
    );
    let attrValue = true;
    // if (attr) {
    //   attrValue = attr.values[0].value.toLowerCase() === "true";
    // }
    sys[layoutPossibleName] = attrValue;
    system.find((ele: any) => ele.oid === sys.oid)[layoutPossibleName] =
      attrValue;
    return sys;
  });
};

export const setFiltersOptions = (filters: any, systemsDetails: any) => {
  filters = [...filters];
  systemsDetails.forEach((system: any) => {
    filters.forEach((filter: any) => {
      filter.options = filter.options || [];
      let attrValue = system[filter.name];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      } else {
      }
    });
  });
  return filters.map((filter: any) => {
    filter.options.sort((a: any, b: any) => {
      if (filter.pimAttribute === "GFR-Fire protection") {
        return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
      } else {
        return a - b;
      }
    });
    return filter;
  });
};

export const getPlacoSystemsDetailsAndRelation = (
  systemOid: any,
  config: any
) => {
  return bdd(
    "/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true",
    [systemOid],
    config
  );
};

export const getAnySystemDetails = (systemOid: any, config: any) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid, config);

export const getAnySystemDetailsWithChildren = (systemOid: any, config: any) =>
  bdd(
    "/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true",
    [systemOid],
    config
  );

export const getSystemDetailsByTechnicalName = (
  technicalName: any,
  config: any
) =>
  bdd(
    "/objects/class/SGObject/locale/en",
    {
      technicalNames: technicalName,
    },
    config
  );
