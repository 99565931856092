import { AnyAction } from "redux";
import {
  selectTypeAction,
  selectLevelAction,
  createSelectionAction,
} from "../Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";

type wallSelectedTypesAction =
  | selectTypeAction
  | selectLevelAction
  | createSelectionAction<PlacoOptions>;

export const wallSelectedTypes = (
  wallSelectedTypes: string[] = [],
  action: wallSelectedTypesAction
): string[] => {
  switch (action.type) {
    case "SELECT_LEVEL":
      if (action.selectorType === "wall") {
        return action.selectedTypes;
      }
      return wallSelectedTypes;
    case "SELECT_TYPE":
      if (action.selectorType === "wall") {
        return action.selectedTypes;
      }
      return wallSelectedTypes;
    case "CREATE_SELECTION":
      return [];
    default:
      return wallSelectedTypes;
  }
};
