import { Language } from "../../../../RevitJS/Types/RevitTypes";

export const SET_MODULE_SELECTION = "SET_MODULE_SELECTION";
export const CLEAR_CALEPINAGE = "CLEAR_CALEPINAGE";
export const SELECT_METRE_PAR_ETAGE = "SELECT_METRE_PAR_ETAGE";
export const SELECT_METRE_PAR_ZONE = "SELECT_METRE_PAR_ZONE";
export const SET_CALPINAGE_DATA = "SET_CALPINAGE_DATA";

export const EDIT_BUFFER_CALEPINAGE = "EDIT_BUFFER_CALEPINAGE";

export const INIT_LANGUAGE = "INIT_LANGUAGE";

export interface EditBufferCalpinage {
    type : typeof EDIT_BUFFER_CALEPINAGE;
    calepinage : any;
}
export const SET_CALPINAGE = "SET_CALPINAGE";
export interface SetCalpinage {
    type: typeof SET_CALPINAGE;
    calepinageData : any
}

export const LOAD_CALEPINAGE = "LOAD_CALEPINAGE";

export interface LoadCalpinage {
    type: typeof LOAD_CALEPINAGE;
    calepinage : any
}

export const SAVE_CALEPINAGE_BUFFER = "SAVE_CALEPINAGE_BUFFER";

export interface SaveCalpinageBuffer {
    type: typeof SAVE_CALEPINAGE_BUFFER;
}

export const EDIT_CALEPINAGE = "EDIT_CALEPINAGE";

export interface EditCalpinage {
    type : typeof EDIT_CALEPINAGE;
    calepinage : any;
}


export const DELETE_CALEPINAGE = "DELETE_CALEPINAGE";
export interface DeleteCalpinage {
    type : typeof DELETE_CALEPINAGE;
    Id : string;
}

export const UPDATE_CALEPINAGE_NAME = "UPDATE_CALEPINAGE_NAME";
export interface UpdateCalpinageName {
    type : typeof UPDATE_CALEPINAGE_NAME;
    Id : string;
    Name: string;
    Date: string;
}

export interface ClearCalpinage {
    type: typeof CLEAR_CALEPINAGE;
}

export interface InitLanguageAction{
    type: typeof INIT_LANGUAGE;
    language: Language
}