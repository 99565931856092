import { connect } from 'react-redux'
import { StoreState } from '../../Reducers'
import {RevitFaker} from '../../Components/RevitFaker/RevitFaker'
import {selectPlugin, resetSelectedPlugin, endManualSelection, cancelManualSelection} from '../../Store/Actions';

const mapStateToProps = (state: StoreState) => ({
    plugins: state.plugins,
    selectedPluginId: state.selectedPluginId,
    popUpWindow: state.popUpWindow,
    popUpHidden: state.popUpHidden,
    manualSelection: state.manualSelection,
    windowDimensions: state.windowDimensions,
    popUpComponent: state.popUpComponent,
    fakeModels: state.fakeModels,
    selectedFakeModel: state.selectedFakeModel
})


const mapDispatchToProps = {
    selectPlugin,
    resetSelectedPlugin,
    endManualSelection,
    cancelManualSelection
}

export default connect(mapStateToProps, mapDispatchToProps)(RevitFaker);
