import React from 'react';
import {FiltersList} from './FiltersList';
import { Button, Menu } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from "react-i18next";

// import {resetFilters} from '../Actions';
// import {wording} from '../Ressources/wording';


export class Filters extends React.Component {
    render() {
        const { t } = this.props ;
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Menu vertical fluid>
                    <Menu.Item
                        name='promotions'
                        onClick={this.handleItemClick}
                    >
                        <Button onClick={() => this.props.resetFilters()} primary>{t("resetFilters")}</Button>
                    </Menu.Item>

                    <FiltersList applyFilters={this.props.applyFilters} setSelectionBuffer={this.props.setSelectionBuffer} setFilterValue={this.props.setFilterValue} partitions={this.props.partitions} filters={this.props.filters}/>
                </Menu>
            </div>
        )
    }
}


Filters.defaultProps={
   
}

// export default connect(null,{resetFilters})(Filters);