import React, { useEffect, useState } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Controller } from "react-hook-form";
import { FormField } from "./FormField";
import "../../../Resources/FormPanel.css";
import { bimStorage, storageKey } from "../../../../../../BIMStore";
import { getProjectData } from "../../../Helper";

export const FormPanel = (props: any) => {
  const [preFillInfo, setPreFillInfo] = useState<any>(null);

  // useEffect(() => {
  //   const getpreFillInfo = async () => {
  //     const preFillInfoRes = await bimStorage.getItem(storageKey.PROJECT_INFO);
  //     setPreFillInfo(preFillInfoRes);
  //   };
  //   getpreFillInfo();
  // }, []);

  // if (preFillInfo) {
  //   getProjectData().then((docName: any) => {
  //     if (preFillInfo && Object.keys(preFillInfo)[0] === props.route) {
  //       let modelInfo = preFillInfo[props.route].modelInfo;
  //       if (modelInfo) {
  //         modelInfo.forEach((model: any) => {
  //           if (model.model_name === docName) {
  //             if (Object.keys(model).length > 0) {
  //               for (let key in Object.keys(model)) {
  //                 if (key === "modal_name") continue;
  //               }
  //             }
  //           }
  //         });
  //       }
  //     } else if (preFillInfo && Object.keys(preFillInfo)[1] === props.route) {
  //       let suppInfoArr = preFillInfo[props.route];
  //       if (suppInfoArr) {
  //         suppInfoArr.forEach((suppInfo: any) => {
  //           if (suppInfo.model_name === docName)
  //             if (Object.keys(suppInfo).length > 0)
  //               for (let key in Object.keys(suppInfo)) {
  //                 props.setValue(
  //                   Object.keys(suppInfo)[key],
  //                   Object.values(suppInfo)[key]
  //                 );
  //               }
  //         });
  //       }
  //     }
  //   });
  // }

  return (
    <div>
      <Header
        as="h5"
        attached="top"
        className="colData-header m-t-0"
        content={props.header}
      ></Header>
      <Segment attached className="colData">
        {props.fields.map((fieldd: any, index: any) => {
          fieldd.language = props.language;
          return (
            <div style={{ paddingBottom: "5px" }} key={index}>
              <Controller
                name={fieldd.name}
                control={props.control}
                rules={{ required: fieldd.mandatory }}
                {...fieldd}
                render={({ field }) => {
                  return (
                    <FormField
                      {...field}
                      {...fieldd}
                      setValue={props.setValue}
                      errors={props.errors}
                      defaultValue={
                        (preFillInfo && preFillInfo[fieldd.name]) ||
                        fieldd.defaultData
                      }
                    />
                  );
                }}
              />
            </div>
          );
        })}
      </Segment>
    </div>
  );
};
