import React from "react";
import { Language, wording } from "./Type";

export const ListHeader = ({
  applicationLanguage,
}: {
  applicationLanguage: Language;
}) => {
  return (
    <div style={style.tableSegment_Header}>
      <div style={style.header_row}>
        <div>{wording.saveSelectionList[applicationLanguage]}</div>
      </div>
      <div style={style.header_row1}>
        <div style={style.header_column_1}>
          {wording.selectionName[applicationLanguage]}
        </div>
        <div style={style.header_column_2}>
          {wording.levels[applicationLanguage]}
        </div>
        {/* <div style={style.header_column_3}>
          {wording.zone[applicationLanguage]}
        </div> */}
        <div style={style.header_column_4}>
          {wording.editDate[applicationLanguage]}
        </div>
      </div>
    </div>
  );
};

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
  },
  height_435: {
    height: "435px",
  },
  height_486: {
    height: "486px",
  },
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_row: {
    padding: 5,
    height: 30,
    backgroundColor: "rgb(33, 133, 208)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_row1: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "40%",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "20%",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    width: "24%",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    fontWeight: 700,
    width: "30%",
    color: "black",
  },
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200",
    height: "71%",
    overflowY: "scroll" as const,
  },
};
