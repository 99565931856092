import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import { BuiltInCategory } from "../../../../RevitJS/Types/RevitTypes";

import { api } from "../../../../RevitJS/API";
import { SelectionStore } from "../../Selection/Reducers";
import { selectorTp } from "../../Selection/Actions/types";
import ProductsPageBody from "./ProductPageBody";

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { setRoute: any }
) => {
  return {
    selectorType: state.selectorType,
    setRoute: ownProps.setRoute,
    config: state.config,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  selectorType: BuiltInCategory | selectorTp;
  setRoute: any;
  config: any;
};

interface State {}

export class ProductsPage extends Component<Props, State> {
  state = {};

  componentDidMount = () => {};

  render() {
    return (
      <ProductsPageBody
        setRoute={this.props.setRoute}
        config={this.props.config}
      />
    );
  }
}

export default connector(ProductsPage);
