import React, { Component } from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import "../assets/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import configureStore from "../store";
import AppBody from "./AppBody";
import "./index.scss";

interface Props {}

export class PamRoot extends Component<Props> {
    public store: Store;

    constructor(props: Props) {
        super(props);
        this.store = configureStore();
    }

    componentDidMount = () => {};

    render = () => {
        return (
            <Provider store={this.store}>
                <div
                    id="pam-plugin-high-level-container"
                    className="bootstrap-scope"
                >
                    <AppBody />
                </div>
            </Provider>
        );
    };
}
