import { api } from '../../../RevitJS/API';


export const functionalityId = "de1662f0-3345-4b42-8033-3f521eb6afee";

export const Components = [];


export const showSolutionsTrigger = async () => {
    const plasterboardIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Plasterboard" }, Rule: "Equals" }], null);
    const framesIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Ossature" }, Rule: "Equals" }], null);
    const bottomRailsIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail" }, Rule: "Equals" }], null);
    const upperRailsIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail-Retourne" }, Rule: "Equals" }], null);
    const processedWallIds = await api.queries.filterElements("Wall", [{ Param:{ Name:"Processed", Type:"Boolean", Value:true}, Rule:"Equals"}], null);
    const processedCeilingsIds = await api.queries.filterElements("Ceiling", [], null);

    const railsIds = bottomRailsIds.concat(upperRailsIds);
    let plasterboardsFilter = await api.queries.createSelectionFilter("SG_Plasterboards", plasterboardIds);
    let framesFilter = await api.queries.createSelectionFilter("SG_Frames", framesIds);
    let railsFilter = await api.queries.createSelectionFilter("SG_Rails", railsIds);
    let processedWallsFilter = await api.queries.createSelectionFilter("SG_Revit_Walls", processedWallIds);
    let processedCeilingsFilter = await api.queries.createSelectionFilter("SG_Revit_Ceilings", processedCeilingsIds);

    let activeView = await api.queries.getActiveViewDetails();
    let activeViewId = activeView.Id;

    await api.viewHandler.setSelectionFilterVisibility(activeViewId, plasterboardsFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, framesFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, railsFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, processedWallsFilter, false);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, processedCeilingsFilter, false);
};
// export const selectorTrigger = () => window.open("http://www.google.com");


export const ShowSolutions = {
    Name: {"French": "Vue du calepinage", "English": "Layout View", "German": "Ansicht des Layouts"},
    Trigger: showSolutionsTrigger,
    ShortDesc: {"French": "Afficher le calepinage", "English": "Show layout", "German": "Layout anzeigen"},
    Id: functionalityId,
    Components,
    Icon: "/pluginIcons_V3/PlacoBIM_Icon_VueCalepinage.png",
    ParentId:"0"
}

