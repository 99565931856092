import * as type from "./types";
import { ThunkAction } from "redux-thunk";
import { SelectionStore } from "../../Selection/Reducers";
import { InitLanguageAction, INIT_LANGUAGE } from "./types";
import { api } from "../../../../RevitJS/API";
import { setPluginDataToDB } from "../../../../Helper";
import { SelectedItem } from "../../../../RevitJS/Types/StoreTypes";
import { getSelectedItems } from "../../../../RevitJS/Helpers";
import _ from "lodash";
import { suppressionSetWall } from "../../Selection/Actions/types";

export const setMetreSelection = (value: boolean) => {
  return {
    type: type.SET_METRE_SELECTION,
    value,
  };
};

export const setMetreParEtage = (value: []) => {
  return {
    type: type.SELECT_METRE_PAR_ETAGE,
    value,
  };
};

export const setMetreParZone = (value: boolean) => {
  return {
    type: type.SELECT_METRE_PAR_ZONE,
    value,
  };
};

export const clearMetreSelection = (value: boolean) => {
  return {
    type: type.CLEAR_SELECT_METRE,
    value,
  };
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, SelectionStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "French" as Language; //(await api.queries.getRevitLanguage()) as Language;
      setPluginDataToDB("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };

export const saveGroupSelection =
  (): ThunkAction<void, SelectionStore, unknown, suppressionSetWall> =>
  async (dispatch: any, getState: any) => {
    const {
      wallTree,
      wallSelectedLevels,
      wallSelectedTypes,
      ceilingTree,
      ceilingSelectedLevels,
      ceilingSelectedTypes,
      bufferSelection,
    } = getState();

    // collect selected walls
    let wallSelectedItems: SelectedItem[] = getSelectedItems(
      wallTree,
      wallSelectedLevels,
      wallSelectedTypes
    );

    // refine and extract walls ids
    let finalWallArray = _.uniq(
      _.reduce(
        _.map(wallSelectedItems, "Ids"),
        (sum: any, n: any) => {
          return _.concat(sum, n);
        },
        []
      )
    );

    // collect selected walls
    let ceilingSelectedItems: SelectedItem[] = getSelectedItems(
      ceilingTree,
      ceilingSelectedLevels,
      ceilingSelectedTypes
    );

    // refine and extract walls ids
    let finalCeilingArray = _.uniq(
      _.reduce(
        _.map(ceilingSelectedItems, "Ids"),
        (sum: any, n: any) => {
          return _.concat(sum, n);
        },
        []
      )
    );

    dispatch({
      type: "SUPPRESSION_SET_WALL",
      walls: finalWallArray,
      ceilings: finalCeilingArray,
    });
  };
