import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { List } from "../../CommonModules/Selection/List";
import { Language } from "../../CommonModules/Selection/Type";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { Routes } from "./root";
import { Selection } from "../../Selection/Actions/types";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { EditSelectionButton } from "../../CommonModules/Selection/EditSelectionBtn";
import { RapidSelectionButton } from "./RapidSelectionButton";
import { RapidBoard } from "../Actions/types";
import { setGroupTrees, setprojectData } from "../../Selection/Actions";
import { clearTree, filterTree } from "../../Selection/Helpers";
import { SaveProject } from "../../CommonModules/PopUpModals/SaveProject";
import { overLapElements } from "../../Overlap/Components/Waiter";
import { Oval } from "react-loader-spinner";
import { SuppressionRevitApi } from "../../../../ErrorManagement/utils/revitApiError";

interface Props {
  setRoute: any;
  onError: any;
}

export const SuppressionHome = (props: Props) => {
  /**
   * The `useSelector()` hook is working like mapStateToProps from class component
   * Means that you cas access redux state using useSelector Hook
   * For ex. state.language
   */
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  /**
   * The `useDispatch() Hook is works like mapDispatch from class component
   * Means that you can dispatch any action using useDispatch()
   */
  const dispatch = useDispatch();

  const applicationLanguage: Language = reduxState.language;

  const [selectionID, setSelectionID] = useState<string[]>([]);
  const [erasing, setErasing] = useState<boolean>(false);
  const [selections, setSelections] = useState<{
    [key: string]: Selection<PlacoOptions>;
  }>({});
  const [revitViewName, setRevitViewName] = useState<string>("");
  const [rapidBoard, setRapidBoard] = useState<RapidBoard>({
    visible: false,
    type: "manual",
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [groupNumber, setGroupNumber] = useState<any>(0);
  const [progress, setProgress] = useState<any>(0);

  useEffect(() => {
    async function fetchRevitDocumentType() {
      let projectData: initProjectData = await api.queries.getSetProjectData();
      SuppressionRevitApi(props);
      if (projectData.ProjectId === "") {
        toggleModal();
      } else {
        dispatch(setprojectData(projectData));
      }
      const documentName = await api.queries.getActiveDocumentName();
      SuppressionRevitApi(props);
      setRevitViewName(documentName);
    }

    if (revitViewName === "") {
      fetchRevitDocumentType();
    }
    async function fetchPlacoSelections() {
      let savedSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );

      if (savedSelections) {
        let parsedSelections = savedSelections as {
          [key: string]: Selection<PlacoOptions>;
        };

        if (!_.isEqual(selections, parsedSelections)) {
          setSelections(parsedSelections);
        }
      }
    }

    fetchPlacoSelections();

    callSuppressionAfter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectionID.length > 0 ? setDisabled(false) : setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionID]);

  useEffect(() => {
    if ((progress / groupNumber) * 100 === 100) {
      setTimeout(async () => {
        await api.windowsHandler.closeWindow();
      }, 5000);
    }
  }, [progress, groupNumber]);

  const callSuppressionAfter = async () => {
    if (
      reduxState.moduleData.suppressionData.ceilings.length > 0 ||
      reduxState.moduleData.suppressionData.walls.length > 0
    ) {
      setErasing(true);
      if (reduxState.moduleData.suppressionData.walls.length > 0) {
        await eraseWalls(reduxState.moduleData.suppressionData.walls);
      }

      if (reduxState.moduleData.suppressionData.ceilings.length > 0) {
        await eraseCeilings(reduxState.moduleData.suppressionData.ceilings);
      }

      await sendEvent();
      if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "false") {
        api.windowsHandler.closeWindow();
      }
    }
  };

  const manualSelectionHandler = async () => {
    setLoading(true);
    const isSuperPoser = await bimStorage.getItem(
      storageKey.IS_SUPERPOSER_ENABLED
    );
    if (isSuperPoser && isSuperPoser === true) {
      await overLapElements();
      bimStorage.setItem(
        storageKey.IS_SUPERPOSER_ENABLED,
        JSON.stringify(false)
      );
    }
    //await overLapElements();
    api.windowsHandler.hideWindow();
    let selectionTree = await api.selection.manualSelection("wall&ceiling");
    SuppressionRevitApi(props);
    setLoading(false);
    api.windowsHandler.showWindow();

    const isWallErase = await manualWallEraser(selectionTree);
    const isCeilingErase = await manualCeilingEraser(selectionTree);

    setRapidBoard({
      ...rapidBoard,
      visible: false,
    });
    //for testing
    // if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "false") {
    //   await api.windowsHandler.closeWindow();
    // }
  };

  const manualWallEraser = async (selectionTree: any) => {
    try {
      let wallTree = clearTree(filterTree(selectionTree, "wall"));
      let elements = _.reduce(
        _.map(wallTree.Tree, "Elements"),
        (sum: any, n: any) => {
          return _.concat(sum, n);
        },
        []
      );
      let Ids = _.uniq(
        _.reduce(
          _.map(elements, "Ids"),
          (sum: any, n: any) => {
            return _.concat(sum, n);
          },
          []
        )
      );

      if (Ids.length > 0) {
        setErasing(true);
        await eraseWalls(Ids);
      }
    } catch (error) {
      throw error;
    }
  };

  const manualCeilingEraser = async (selectionTree: any) => {
    try {
      let wallTree = clearTree(filterTree(selectionTree, "ceiling"));
      let elements = _.reduce(
        _.map(wallTree.Tree, "Elements"),
        (sum: any, n: any) => {
          return _.concat(sum, n);
        },
        []
      );
      let Ids = _.uniq(
        _.reduce(
          _.map(elements, "Ids"),
          (sum: any, n: any) => {
            return _.concat(sum, n);
          },
          []
        )
      );

      if (Ids.length > 0) {
        setErasing(true);
        await eraseCeilings(Ids);
      }
    } catch (error) {
      throw error;
    }
  };

  const groupSelectionHandler = async () => {
    let wallTree = await api.selection.elementsByLevelAndType("wall");
    SuppressionRevitApi(props);
    let ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
    SuppressionRevitApi(props);
    dispatch(setGroupTrees(wallTree, ceilingTree));
    setRapidBoard({
      ...rapidBoard,
      visible: false,
    });

    props.setRoute(Routes.GROUP_SELECTION);
  };

  const selectRow = (id: string) => {
    if (_.includes(selectionID, id)) {
      let filtSelectionIds = _.remove(selectionID, (n) => {
        return n !== id;
      });
      setSelectionID(filtSelectionIds);
    } else {
      let filtSelectionIds = _.concat(selectionID, id);
      setSelectionID(filtSelectionIds);
    }
    localStorage.setItem("isModification", "true");
  };

  const handleSubmit = () => {
    if (selectionID.length > 0) {
      setErasing(true);
      let selectedSelectionObject = _.filter(
        selections,
        (sel: Selection<PlacoOptions>, key: string) => {
          return _.includes(selectionID, sel.Id);
        }
      );
      eraseSelection(selectedSelectionObject);
    }
    localStorage.setItem("isModification", "false");
  };

  const [confirmModal, setconfirmModal] = useState(false);
  const toggleModal = () => {
    setconfirmModal(!confirmModal);
  };

  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    SuppressionRevitApi(props);
    if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "false") {
      api.windowsHandler.closeWindow();
    }
  };

  const eraseSelection = async (selection: Selection<PlacoOptions>[]) => {
    let tree = _.flatten(
      selection.map((item) =>
        item.SelectionByType.wall.Rows.map((row) => row.RevitSelection.Ids)
      )
    );
    const idsToDelete = _.uniq(_.flatMap(tree));
    // const idsToDelete = [].concat.apply([], tree.map((e:any) => e.Ids));

    await eraseWalls(idsToDelete);

    let ceilingTree = _.flatten(
      selection.map((item) =>
        item.SelectionByType.ceiling.Rows.map((row) => row.RevitSelection.Ids)
      )
    );
    const ceilingIdsToDelete = _.uniq(_.flatMap(ceilingTree));

    await eraseCeilings(ceilingIdsToDelete);
    await sendEvent();
    if (localStorage.getItem("isErrorThrown")?.toLowerCase() === "false") {
      api.windowsHandler.closeWindow();
    }
  };

  /**
   * Delete the plasterboards
   * @param idsToDelete Ids of the walls
   */
  const eraseWalls = async (idsToDelete: string[]) => {
    const nb_walls = idsToDelete.length;

    setGroupNumber(nb_walls);

    let elemsArr: string[] = [];
    for (let i = 0; i < nb_walls; i++) {
      await api.familyEditor.setParams([
        {
          Id: idsToDelete[i],
          Params: [
            { Name: "Processed", Type: "YesNo", Value: 0 },
            { Name: "SG_System", Type: "Text", Value: " " },
            { Name: "Plaque", Type: "Text", Value: " " },
            { Name: "Montant", Type: "Text", Value: " " },
            { Name: "IsLayout", Type: "YesNo", Value: 0 },
          ],
        },
      ]);
      SuppressionRevitApi(props);
      const elems = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: {
              Name: "id",
              Type: "Integer",
              Value: parseInt(idsToDelete[i]),
            },
            Rule: "Equals",
          },
        ],
        null
      );
      SuppressionRevitApi(props);
      setIncrement(i);
      elemsArr = elemsArr.concat(elems);
    }
    if (elemsArr && elemsArr.length > 0)
      await api.familyEditor.deleteElementsByIds(elemsArr);
    SuppressionRevitApi(props);

    const processedWallIds = await api.queries.filterElements(
      "Wall",
      [
        {
          Param: { Name: "Processed", Type: "Boolean", Value: true },
          Rule: "Equals",
        },
      ],
      null
    );
    SuppressionRevitApi(props);
    let processedWallsFilter = await api.queries.createSelectionFilter(
      "SG_Revit_Walls",
      processedWallIds
    );
    SuppressionRevitApi(props);
    let activeView = await api.queries.getActiveViewDetails();
    SuppressionRevitApi(props);
    let activeViewId = activeView.Id;
    await api.viewHandler.setSelectionFilterVisibility(
      activeViewId,
      processedWallsFilter,
      true
    );
    SuppressionRevitApi(props);
  };

  const eraseCeilings = async (idsToDelete: string[]) => {
    const nb_walls = idsToDelete.length;

    setGroupNumber(nb_walls);

    for (let i = 0; i < nb_walls; i++) {
      await api.familyEditor.setParams([
        {
          Id: idsToDelete[i],
          Params: [
            { Name: "Processed", Type: "YesNo", Value: 0 },
            { Name: "SG_System", Type: "Text", Value: " " },
            { Name: "Plaque", Type: "Text", Value: " " },
            { Name: "Montant", Type: "Text", Value: " " },
            { Name: "IsLayout", Type: "YesNo", Value: 0 },
          ],
        },
      ]);
      SuppressionRevitApi(props);
      setIncrement(i);
    }

    const processedWallIds = await api.queries.filterElements(
      "Ceiling",
      [
        {
          Param: { Name: "Processed", Type: "Boolean", Value: true },
          Rule: "Equals",
        },
      ],
      null
    );
    SuppressionRevitApi(props);
    let processedWallsFilter = await api.queries.createSelectionFilter(
      "SG_Revit_Ceilings",
      processedWallIds
    );
    SuppressionRevitApi(props);
    let activeView = await api.queries.getActiveViewDetails();
    SuppressionRevitApi(props);
    let activeViewId = activeView.Id;
    await api.viewHandler.setSelectionFilterVisibility(
      activeViewId,
      processedWallsFilter,
      true
    );
    SuppressionRevitApi(props);
  };

  const sendEvent = async () => {
    await api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Delete",
      module: "PLACOBIM",
    });
  };

  const setIncrement = (index: any) => {
    let done = parseInt(index + 1);
    setProgress(done);
  };

  const steps = () => {
    return (
      "Suppression en cours... " +
      `${groupNumber ? Math.round((progress / groupNumber) * 100) : 0}%`
    );
  };

  if (erasing) {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={steps()}></Loader>
      </Dimmer>
    );
  }

  let sortedSuppressionList = _.filter(reduxState.selections, {
    RevitView: reduxState.projectData.ProjectId,
  });

  const converDate = (d: any) => {
    let sp = d.split("/");
    let ret = [sp[1], sp[0], sp[2]].join("/");
    return ret;
  };

  sortedSuppressionList.sort((a, b) => {
    return +new Date(converDate(b.Date)) - +new Date(converDate(a.Date));
  });

  return (
    <div style={{ height: "100%" }} className="suppressionTableBodyHeight">
      <SaveProject
        toggleModal={toggleModal}
        confirmModal={confirmModal}
        onYesAction={onYesAction}
      />
      <FunctionalityHeader
        Icon={reduxState.functionalityIcon}
        name={wording.name[applicationLanguage]}
      />
      <List
        selections={sortedSuppressionList}
        selectRow={selectRow}
        zone={"All"}
        applicationLanguage={applicationLanguage}
        moduleData={selectionID.length > 0 ? selectionID : []}
        height={{ height: "calc(100vh - 180px" }}
        //  height="auto"
      />

      <EditSelectionButton
        setRoute={props.setRoute}
        routes={Routes}
        applicationLanguage={applicationLanguage}
        popUpVisible={() => {
          return false;
        }}
        toggleConfirmModal={false}
        onYesAction={null}
        onNoAction={null}
        confirmModal={false}
        description="Voulez-vous sauvegarder votre document?"
      />

      <RapidSelectionButton
        setRoute={props.setRoute}
        routes={Routes}
        applicationLanguage={applicationLanguage}
        onAction={() =>
          setRapidBoard({
            ...rapidBoard,
            visible: true,
          })
        }
      />

      <Grid columns={1} stackable style={{ ...style.processButtonSegment }}>
        <Grid.Column style={{ padingBottom: "0px", textAlign: "center" }}>
          <Button
            color="orange"
            onClick={() => api.windowsHandler.closeWindow()}
          >
            {wording.back[applicationLanguage]}
          </Button>
          <Button
            disabled={disabled}
            type="submit"
            primary
            onClick={() => handleSubmit()}
          >
            {wording.validate[applicationLanguage]}
          </Button>
        </Grid.Column>
      </Grid>

      <Dimmer
        active={rapidBoard.visible}
        style={{ height: "calc(100vh - 30px)" }}
      >
        <Segment style={{ width: 600, padding: "0 0 10px 0" }}>
          {/* <Header className="modalBg" style={{ backgroundColor: "#000" }}> */}
          <span
            style={{ float: "right", cursor: "pointer" }}
            className="close-icon"
            onClick={() =>
              setRapidBoard({
                ...rapidBoard,
                visible: false,
              })
            }
          >
            <Icon name="times" />
          </span>
          {/* </Header> */}

          <Container
            textAlign="center"
            style={{ marginTop: 20, padding: "20px" }}
          >
            <Grid>
              <FunctionalityHeader
                Icon={reduxState.functionalityIcon}
                name={wording.name[applicationLanguage]}
              />

              <Grid.Row>
                <Grid.Column width={8}>
                  <Button
                    onClick={() => {
                      setRapidBoard({
                        ...rapidBoard,
                        type: "manual",
                      });
                      manualSelectionHandler();
                    }}
                    primary
                    size="big"
                    floated="right"
                    className="selectionBtn"
                    style={{ height: 80, width: 180 }}
                  >
                    {wording.newManualSelection[applicationLanguage]}
                    {loading === true ? (
                      <Oval
                        color="#00BFFF" //3 secs
                        height={16}
                        width={16}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Button>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    onClick={() => {
                      setRapidBoard({
                        ...rapidBoard,
                        type: "group",
                      });
                      groupSelectionHandler();
                    }}
                    primary
                    size="big"
                    floated="left"
                    className="selectionBtn"
                    style={{ height: 80, width: 180 }}
                  >
                    {wording.newGroupSelection[applicationLanguage]}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </Dimmer>
    </div>
  );
};

const wording: WordingType = {
  name: {
    French: "Suppression",
    English: "Suppression",
  },
  editSelection: {
    French: "Modifier mes sélections",
    English: "Modify my selections",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
  newManualSelection: {
    French: "Sélection manuelle",
    English: "Manual selection",
  },
  newGroupSelection: {
    French: "Sélection groupée",
    English: "Group selection",
  },
};

type WordingType = {
  name: LanguageType;
  editSelection: LanguageType;
  back: LanguageType;
  validate: LanguageType;
  newManualSelection: LanguageType;
  newGroupSelection: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
  processButtonSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};
