import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { Properties } from "./Properties";
import { LoadSystemContainer } from "./LoadSystemContainer";
import { Updates } from "./UpdateSystems";
import  {UserManual}  from "./UserManual";
import { Help } from "./Help";

export const pluginId = "370cbf02-2f20-11eb-adc1-0242ac120002";

export const placoBimv5: Plugin = {
  TechnicalName: "PRESCRIBIM",
  Name: "",
  Icon: "/PrescriBIM.png",
  ShortDesc: {
    French: "Modéliser avec des systèmes PLACO®",
    English: "Modeling with PLACO® systems",
    German: "Modéliser avec des systèmes PLACO®",
    Italian: "Modéliser avec des systèmes PLACO®",
    Indonesian: "Modéliser avec des systèmes PLACO®",
  },
  Id: pluginId,
  Color: "#005EB8",
  Functionalities: [
    Properties,
    LoadSystemContainer,
    Updates,
    UserManual,
    Help
  ],
};
