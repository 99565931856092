import React, { useEffect } from "react";
import Landing from "./Landing";
import { MyConfig } from "../../../../Helper";
import { api } from "../../../../RevitJS/API";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";

const AppBody = (props: { route: string; setRoute: any }) => {
  
    useEffect(() => {
      async function fetchProjectId() {
        let projectData: initProjectData = await api.queries.getSetProjectData();
  
        if (projectData.ProjectId === "") {
        } else {
          //dispatch(SetProjectData(projectData));
        }
      }
  
      fetchProjectId();
      fetchConfig();
      //dispatch(InitLanguage());
    }, []);
  
    const fetchConfig = async () => {
      const payload = await MyConfig();
      //dispatch({ type: "SET_CONFIG", payload });
    };
  
    switch (props.route) {
      case "ROOT":
        return <Landing setRoute={props.setRoute} />;
      default:
        return <Landing setRoute={props.setRoute} />;
    }
  };
  
  export default AppBody;