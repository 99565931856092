import React from "react";
import { useState, useEffect } from "react";
import { Home } from "./Home";
import { Detail } from "./Detail";
import { bimStorage } from "../../../../BIMStore";
import { AppBody } from "../../Selection/Page/AppBody";

export const AppBodyCalepinage = () => {
  const [route, setRoute] = useState<string>("ROOT");
  const [calepinage, setCalepinage] = useState<any>(null);

  const onCalepinageUpdate = async (calepinageObject: any) => {
    setCalepinage(calepinageObject);
  };
  switch (route) { 
    case "ROOT":
      return (
        <Home setRoute={setRoute} onCalepinageUpdate={onCalepinageUpdate} />
      );
    case "DETAIL":
      return (
        <Detail
          setRoute={setRoute}
          calepinage={calepinage}
          onCalepinageUpdate={onCalepinageUpdate}
        />
      );
    case "SELECTION_HOME": 
      return <AppBody />
    default:
      return null;
  }
};
