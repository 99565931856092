import { api } from "../../../RevitJS/API";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import Waiter from "./Components/Waiter";
import * as path from "path";
import { pluginId } from "../index";
import { albaQuantities, whoolQuantities } from "../WallSchedules/Helpers";
import { boardQuantities } from "./boardQuantities";
import { profileQuantities } from "./profilesQuantities";
import { insulationQuantities } from "./insulationQuantities";
import { hangersQuantities } from "./hangersQuantities";
import i18next from "i18next";
import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "4b478242-3f53-40ce-be91-8f9eda765b70";

export const Components = [
  {
    Id: "55064ff5-345b-4f5b-a4cd-7921d751172a",
    Component: Waiter,
    Name: "Group Selector",
    custom: {},
  },
];

const language = "German";

const levelNameFromWallId = async (wallId: any) => {
  const wallDetails = await api.queries.getWallsData([wallId]);
  const levelId = wallDetails[0].LevelId;
  const levelDetails = await api.queries.getObjectsParams([levelId], ["Name"]);
  return await levelDetails[0].Params[0].Value;
};

const elemsByLevelName = async (wallsIds: any) => {
  let projectTree = await api.selection.elementsByLevelAndType("wall");
  let tree = projectTree.Tree.map((level) => {
    return {
      level: level.Level.Name,
      elems: [].concat.apply(
        [],
        level.Elements.map((elems: any) =>
          elems.Ids.filter((id: any) => wallsIds.includes(id))
        )
      ),
    };
  });

  const wallsByLevelName: any = {};
  let nbLevels = tree.length;
  for (let i = 0; i < nbLevels; i++) {
    wallsByLevelName[tree[i].level] = tree[i].elems;
  }
  return wallsByLevelName;
};

const addBillOfQuantityTable = (
  worksheet: any,
  name: any,
  ref: any,
  theme: any,
  columns: any,
  rows: any
) => {
  return worksheet.addTable({
    name,
    ref,
    headerRow: true,
    totalsRow: false,
    style: {
      theme,
      showRowStripes: true,
    },
    columns,
    rows,
  });
};

const plasterboardDetailGenerator = (levelName: any) => (plasterboard: any) => {
  let length = Math.round(plasterboard.Params[2].Value);
  let height = Math.round(plasterboard.Params[1].Value);
  let width = plasterboard.Params[3].Value;
  return {
    levelName,
    name: plasterboard.Params[4].Value,
    length,
    height,
    width,
    surface: Math.round(length * height * 0.0001) / 100,
  };
};

const railDetailGenerator = (levelName: any) => (rail: any) => {
  let length = Math.round(rail.Params[1].Value);
  return {
    levelName,
    name: rail.Params[2].Value,
    length,
  };
};

const elemsDetailRowGenerator = (
  wallsByLevelName: any,
  plasterboardsPerWalls: any,
  detailGenerator: any
) => {
  let rows: any = [];
  Object.keys(wallsByLevelName).forEach(function (levelName, index) {
    let wallIds = wallsByLevelName[levelName];
    wallIds.forEach((wallId: any) => {
      let plasterboards = plasterboardsPerWalls[wallId];
      let walls_rows = plasterboards.map(detailGenerator(levelName));
      rows = rows.concat(walls_rows);
    });
  });
  return rows;
};

const genratePlasterboardRows = (sorted_data: any) => {
  let rows = [];
  let levels = Object.keys(sorted_data);
  let nb_levels = levels.length;

  for (let i = 0; i < nb_levels; i++) {
    rows.push([levels[i], " ", " ", " ", " ", " ", " ", " "]);
    let names = Object.keys(sorted_data[levels[i]]);
    let nb_names = names.length;
    for (let j = 0; j < nb_names; j++) {
      rows.push([" ", names[j], " ", " ", " ", " ", " ", " "]);
      let lengthes = Object.keys(sorted_data[levels[i]][names[j]]);
      let nb_lengthes = lengthes.length;
      let inter_obj = sorted_data[levels[i]][names[j]];
      let inter_obj_2 = inter_obj[Object.keys(inter_obj)[0]];
      let inter_obj_3 = inter_obj_2[Object.keys(inter_obj_2)[0]];
      rows.push([" ", " ", inter_obj_3[0].width, " ", " ", " "]);
      for (let k = 0; k < nb_lengthes; k++) {
        let heights = Object.keys(
          sorted_data[levels[i]][names[j]][lengthes[k]]
        );
        let nb_heights = heights.length;
        for (let l = 0; l < nb_heights; l++) {
          let isoboards =
            sorted_data[levels[i]][names[j]][lengthes[k]][heights[l]];
          let length = isoboards[0].length;
          let height = isoboards[0].height;
          let surface = isoboards[0].surface;
          let quantity = isoboards.length;
          let surfacePerQuantity = surface * quantity;
          rows.push([
            " ",
            length,
            height,
            surface,
            quantity,
            surfacePerQuantity,
          ]);
        }
      }
    }
  }
  return rows;
};

const genrateRailsRows = (sorted_data: any) => {
  let rows = [];
  let levels = Object.keys(sorted_data);
  let nb_levels = levels.length;

  for (let i = 0; i < nb_levels; i++) {
    rows.push([levels[i], " ", " ", " ", " "]);
    let names = Object.keys(sorted_data[levels[i]]);
    let nb_names = names.length;
    for (let j = 0; j < nb_names; j++) {
      rows.push([" ", names[j], " ", " ", " "]);
      let lengthes = Object.keys(sorted_data[levels[i]][names[j]]);
      let nb_lengthes = lengthes.length;
      for (let k = 0; k < nb_lengthes; k++) {
        let isorails = sorted_data[levels[i]][names[j]][lengthes[k]];
        let length = isorails[0].length;
        let quantity = isorails.length;
        let lengthPerQuantity = length * quantity;
        rows.push([" ", " ", length, quantity, lengthPerQuantity]);
      }
    }
  }
  return rows;
};

const generatePlasterboardLevels = (by_level: any, by_level_and_name: any) => {
  let rows = [];
  let levels = Object.keys(by_level_and_name);
  let nb_levels = levels.length;
  let total_sum = 0;
  for (let i = 0; i < nb_levels; i++) {
    let level_boards = by_level[levels[i]];
    let level_sum = level_boards.reduce(function (a: any, c: any) {
      return a + c.surface;
    }, 0);
    total_sum += level_sum;
    rows.push([levels[i], " ", " ", level_sum]);
    let names = Object.keys(by_level_and_name[levels[i]]);
    let nb_names = names.length;
    for (let j = 0; j < nb_names; j++) {
      let boards = by_level_and_name[levels[i]][names[j]];
      rows.push([" ", names[j], " ", " "]);
      rows.push([" ", " ", boards[0].width, " "]);

      let sum = boards.reduce(function (a: any, c: any) {
        return a + c.surface;
      }, 0);
      rows.push([" ", " ", " ", sum]);
      rows.push([" ", " ", " ", " "]);
    }
  }
  rows.push(["Total", " ", " ", total_sum]);
  return rows;
};

const generateRailsLevels = (by_level: any, by_level_and_name: any) => {
  let rows = [];
  let levels = Object.keys(by_level_and_name);
  let nb_levels = levels.length;
  let total_sum = 0;
  for (let i = 0; i < nb_levels; i++) {
    let level_elems = by_level[levels[i]];
    let level_sum = level_elems.reduce(function (a: any, c: any) {
      return a + c.length;
    }, 0);
    total_sum += level_sum;
    rows.push([levels[i], " ", level_sum / 1000]);
    let names = Object.keys(by_level_and_name[levels[i]]);
    let nb_names = names.length;
    for (let j = 0; j < nb_names; j++) {
      let elems = by_level_and_name[levels[i]][names[j]];
      rows.push([" ", names[j], " "]);
      let sum = elems.reduce(function (a: any, c: any) {
        return a + c.length;
      }, 0);
      rows.push([" ", " ", sum / 1000]);
      rows.push([" ", " ", " "]);
    }
  }
  rows.push(["Total", " ", total_sum / 1000]);
  return rows;
};

const generatePlasterboardsSummary = (by_name: any) => {
  let rows = [];
  let object_types = Object.keys(by_name);
  let nb_object_types = object_types.length;
  for (let i = 0; i < nb_object_types; i++) {
    let sum = by_name[object_types[i]].reduce(function (a: any, c: any) {
      return a + c.surface;
    }, 0);
    rows.push([object_types[i], by_name[object_types[i]][0].width, sum]);
  }

  return rows;
};

const generateRailsSummary = (by_name: any) => {
  let rows = [];
  let object_types = Object.keys(by_name);
  let nb_object_types = object_types.length;
  for (let i = 0; i < nb_object_types; i++) {
    let sum = by_name[object_types[i]].reduce(function (a: any, c: any) {
      return a + c.length;
    }, 0);
    rows.push([object_types[i], sum / 1000]);
  }
  return rows;
};

const rowsByLevel = (rows: any) =>
  rows.reduce(function (a: any, c: any) {
    a[c.levelName] = a[c.levelName] || [];
    a[c.levelName].push(c);
    return a;
  }, Object.create(null));

const rowsByName = (rows: any) =>
  rows.reduce(function (a: any, c: any) {
    a[c.name] = a[c.name] || [];
    a[c.name].push(c);
    return a;
  }, Object.create(null));

const rowsByLevelAndName = (byLevel: any) => {
  const rowsByLevelAndName: any = {};
  Object.keys(byLevel).forEach((level) => {
    rowsByLevelAndName[level] = byLevel[level].reduce(function (
      a: any,
      c: any
    ) {
      a[c.name] = a[c.name] || [];
      a[c.name].push(c);
      return a;
    },
    Object.create(null));
  });
  return rowsByLevelAndName;
};

const rowsByLevelNameAndLength = (byLevelAndName: any) => {
  let rowsByLevelNameAndLength: any = {};
  Object.keys(byLevelAndName).forEach((level) => {
    rowsByLevelNameAndLength[level] = {};
    Object.keys(byLevelAndName[level]).forEach((name) => {
      rowsByLevelNameAndLength[level][name] = byLevelAndName[level][
        name
      ].reduce(function (a: any, c: any) {
        a[c.length] = a[c.length] || [];
        a[c.length].push(c);
        return a;
      }, Object.create(null));
    });
  });
  return rowsByLevelNameAndLength;
};

const rowsByLevelNameLengthAndHeight = (by_level_name_and_length: any) => {
  let rowsByLevelNameLengthAndHeight: any = {};
  Object.keys(by_level_name_and_length).forEach((level) => {
    rowsByLevelNameLengthAndHeight[level] = {};
    Object.keys(by_level_name_and_length[level]).forEach((name) => {
      rowsByLevelNameLengthAndHeight[level][name] = {};
      Object.keys(by_level_name_and_length[level][name]).forEach((length) => {
        rowsByLevelNameLengthAndHeight[level][name][length] =
          by_level_name_and_length[level][name][length].reduce(function (
            a: any,
            c: any
          ) {
            a[c.height] = a[c.height] || [];
            a[c.height].push(c);
            return a;
          },
          Object.create(null));
      });
    });
  });
  return rowsByLevelNameLengthAndHeight;
};

const adjustColumnsSize = (sheet: any) => {
  for (let i = 0; i < sheet.columns.length; i++) {
    let dataMax = 0;
    const column = sheet.columns[i];
    for (let j = 1; j < column.values.length; j++) {
      const columnLength = column.values[j].length;
      if (columnLength > dataMax) {
        dataMax = columnLength;
      }
    }
    column.width = dataMax < 10 ? 10 : dataMax + 2;
  }
};

export const plasterboardQuantities = async (plasterboardsSheet: any) => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Plasterboard" },
        Rule: "Equals",
      },
    ],
    null
  );
  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );
  const plasterboardsPerWall = plasterboardDetails.reduce(function (
    a: any,
    c: any
  ) {
    a[c.Params[0].Value] = a[c.Params[0].Value] || [];
    a[c.Params[0].Value].push(c);
    return a;
  },
  Object.create(null));

  let wallsIds = Object.keys(plasterboardsPerWall);

  const wallsByLevelName = await elemsByLevelName(wallsIds);

  let rows = elemsDetailRowGenerator(
    wallsByLevelName,
    plasterboardsPerWall,
    plasterboardDetailGenerator
  );

  const plasterboardsByLevel = rowsByLevel(rows);
  const plasterboardsByName = rowsByName(rows);

  const plasterboardsByLevelAndName = rowsByLevelAndName(plasterboardsByLevel);
  const plasterboardsByLevelNameAndLength = rowsByLevelNameAndLength(
    plasterboardsByLevelAndName
  );
  const plasterboardsByLevelNameLengthAndHeight =
    rowsByLevelNameLengthAndHeight(plasterboardsByLevelNameAndLength);

  let detailRows = genratePlasterboardRows(
    plasterboardsByLevelNameLengthAndHeight
  );
  let levelRows = generatePlasterboardLevels(
    plasterboardsByLevel,
    plasterboardsByLevelAndName
  );
  let summaryRows = generatePlasterboardsSummary(plasterboardsByName);

  let detailColumns = [
    { name: i18next.t("riggibs:levels"), filterButton: true },
    { name: i18next.t("riggibs:plasterboardName"), filterButton: true },
    { name: i18next.t("riggibs:thickness"), filterButton: true },
    { name: i18next.t("riggibs:width"), filterButton: true },
    { name: i18next.t("riggibs:height"), filterButton: true },
    { name: i18next.t("riggibs:surface"), filterButton: true },
    { name: i18next.t("riggibs:quantity"), filterButton: true },
    { name: i18next.t("riggibs:surfacePerQuantity"), filterButton: true },
  ];

  let detailsTable = addBillOfQuantityTable(
    plasterboardsSheet,
    "PlasterboardsDetail",
    "A1",
    "TableStyleMedium9",
    detailColumns,
    detailRows
  );

  let levelsColumns = [
    { name: i18next.t("riggibs:levels"), filterButton: true },
    { name: i18next.t("riggibs:plasterboardName"), filterButton: true },
    { name: i18next.t("riggibs:thickness"), filterButton: true },
    { name: i18next.t("riggibs:totalSurface"), filterButton: true },
  ];

  let levelsTable = addBillOfQuantityTable(
    plasterboardsSheet,
    "PlasterboardsByLevel",
    "J1",
    "TableStyleMedium9",
    levelsColumns,
    levelRows
  );

  let summaryColumns = [
    { name: i18next.t("riggibs:plasterboardName"), filterButton: true },
    { name: i18next.t("riggibs:thickness"), filterButton: true },
    { name: i18next.t("riggibs:totalSurface"), filterButton: true },
  ];

  let summaryTable = addBillOfQuantityTable(
    plasterboardsSheet,
    "OverallSummary",
    "O1",
    "TableStyleMedium9",
    summaryColumns,
    summaryRows
  );

  adjustColumnsSize(plasterboardsSheet);
};

export const linearQuantities = async (
  railsSheet: any,
  ids: any,
  lengthWording: any,
  nameWording: any,
  table1Name: any,
  table2Name: any,
  table3Name: any,
  type: any
) => {
  const railsDetails = await api.queries.getObjectsParams(ids, [
    "id",
    lengthWording,
    nameWording,
  ]);
  const railsPerWall = railsDetails.reduce(function (a: any, c: any) {
    a[c.Params[0].Value] = a[c.Params[0].Value] || [];
    a[c.Params[0].Value].push(c);
    return a;
  }, Object.create(null));

  let wallsIds = Object.keys(railsPerWall);

  const railsByLevelName = await elemsByLevelName(wallsIds);

  let rows = elemsDetailRowGenerator(
    railsByLevelName,
    railsPerWall,
    railDetailGenerator
  );

  const railsByLevel = rowsByLevel(rows);
  const railsByName = rowsByName(rows);

  const railsByLevelAndName = rowsByLevelAndName(railsByLevel);
  const railsByLevelNameAndLength =
    rowsByLevelNameAndLength(railsByLevelAndName);

  let detailRows = genrateRailsRows(railsByLevelNameAndLength);
  let railsRows = generateRailsLevels(railsByLevel, railsByLevelAndName);
  let summaryRails = generateRailsSummary(railsByName);

  let detailColumns = [
    { name: i18next.t("levels"), filterButton: true },
    { name: type ? type : i18next.t("rails"), filterButton: true },
    { name: i18next.t("length"), filterButton: true },
    { name: i18next.t("quantity"), filterButton: true },
    { name: i18next.t("lengthPerQuantity"), filterButton: true },
  ];

  let detailsTable = addBillOfQuantityTable(
    railsSheet,
    table1Name,
    "A1",
    "TableStyleMedium9",
    detailColumns,
    detailRows
  );

  let levelsColumns = [
    { name: i18next.t("levels"), filterButton: true },
    { name: type ? type : "Rail", filterButton: true },
    { name: i18next.t("totalSurface"), filterButton: true },
  ];

  let levelsTable = addBillOfQuantityTable(
    railsSheet,
    table2Name,
    "G1",
    "TableStyleMedium9",
    levelsColumns,
    railsRows
  );

  let summaryColumns = [
    { name: "Type", filterButton: true },
    { name: "Longueur Cummulée", filterButton: true },
  ];

  let summuryTable = addBillOfQuantityTable(
    railsSheet,
    table3Name,
    "K1",
    "TableStyleMedium9",
    summaryColumns,
    summaryRails
  );
};

export const railsQuantities = async (railsSheet: any) => {
  const railsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Rail-haut" },
        Rule: "Equals",
      },
    ],
    null
  );
  const flippedRailsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Rail-basnpmnp",
        },
        Rule: "Equals",
      },
    ],
    null
  );
  const ids = railsIds.concat(flippedRailsIds);
  await linearQuantities(
    railsSheet,
    ids,
    "Longueur",
    "Nom",
    "RailsDetail",
    "RailsByLevel",
    "RailsSummary",
    ""
  );
};

export const framesQuantities = async (framesSheet: any) => {
  const idsSimple = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Ossature" },
        Rule: "Equals",
      },
    ],
    null
  );
  const idsDouble = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Ossature-Double",
        },
        Rule: "Equals",
      },
    ],
    null
  );
  await linearQuantities(
    framesSheet,
    [...idsSimple, ...idsDouble, ...idsDouble],
    "Longueur",
    "Nom",
    "FramesDetail",
    "FramesByLevel",
    "framesSummary",
    "Montant"
  );
};

export const excelExport = async () => {
  const workbook = new Excel.Workbook();

  const plasterboardsSheet = workbook.addWorksheet(
    i18next.t("Boards") || "Boards"
  );
  const profilesSheet = workbook.addWorksheet(
    i18next.t("Profiles") || "Profiles"
  );
  const insulationSheet = workbook.addWorksheet(
    i18next.t("Insulation") || "Insulation"
  );
  const hangersSheet = workbook.addWorksheet(i18next.t("Hangers") || "Hangers");

  // const imageId = workbook.addImage({
  //     filename: '/pluginIcons/riggibs-logo-icon.png',
  //     extension: 'png',
  //   });

  await boardQuantities(plasterboardsSheet);
  await profileQuantities(profilesSheet);
  await insulationQuantities(insulationSheet);
  await hangersQuantities(hangersSheet);
  // const railsSheet = workbook.addWorksheet(i18next.t("frames"));
  // // const framesSheet = workbook.addWorksheet(i18next.t("frames"));
  // const albaSheet = workbook.addWorksheet("Alba");
  // // const whoolSheet = workbook.addWorksheet(i18next.t("whool"));

  // await railsQuantities(railsSheet);
  // // await framesQuantities(framesSheet);
  // await albaQuantities(albaSheet);
  // await whoolQuantities(whoolSheet);
  const buff = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buff], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  await sendEvent();
  saveAs(blob, i18next.t("RLT_quantity export_FR") || "RLT_quantity export_FR");
};

const sendEvent = async () => {
  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Quantitatifs",
    module: "RIGIPSBIM",
  });
};

export const billOfQuantitiesTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Quantitatifs",
    module: "RIGIPSBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Waiter />
    </ParentRoot>
  );
};

export const RiggipsQuantityRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Waiter />
    </ParentRoot>
  );
};

export const QuantityTakeOff = {
  Name: {
    French: "Quantitatifs",
    English: "Quantitative",
    German: "Materialliste",
  },
  Trigger: billOfQuantitiesTrigger,
  ShortDesc: {
    French: "Télécharger les quantitatifs",
    English: "Download the quantities",
    German: "Laden Sie die Mengen herunter",
  },
  Id: functionalityId,
  RootComponent,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Quantitatifs.png",
  ParentId: "0",
};
