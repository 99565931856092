import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../actions/appActions";
import { AppWindowName } from "../assets/constants";
import { checkAcceptedGTOU, checkSkipIntro } from "../helpers/utils";
import Element from "../models/Element.model";
import CouplingSelection from "./couplingSelection/CouplingSelection";
import DataTransfert from "./dataTransfert/DataTransfert";
import ElementSelection from "./elementSelection/ElementSelection";
import Finish from "./finish/Finish";
import Intro from "./intro/Intro";
import ProjectCountry from "./projectCountry/ProjectCountry";
import ProjectMenu from "./projectMenu/ProjectMenu";
import ProjectName from "./projectName/ProjectName";
import RangeSelection from "./rangeSelection/RangeSelection";
import RecapComponent from "./recap/RecapComponent";
import SystemSelection from "./systemSelection/SystemSelection";
import TermsOfUse from "./termsOfUse/TermsOfUse";

export class AppBody extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            allAvailableItems: [],
        };

        if (checkAcceptedGTOU()) {
            this.props.selectWindowToShow(
                AppWindowName.INTRODUCTION_VIDEO_WINDOW
            );
            if (checkSkipIntro()) {
                this.props.selectWindowToShow(
                    AppWindowName.PROJECT_MENU_WINDOW
                );
            }
        }

        this.setAllAvailableItems = this.setAllAvailableItems.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    _handleKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            let next = document.getElementById(
                "footer-next-button"
            ) as HTMLButtonElement;
            if (next && !next.disabled) {
                next.click();
            }
        }
    }

    setAllAvailableItems(items: Element[]) {
        this.setState({
            allAvailableItems: [...items],
        });
    }

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);

        let selectedLanguage = localStorage.getItem('savedLanguage');

        if (selectedLanguage !== "English" && selectedLanguage !== "French") {
            this.props.i18n.changeLanguage("English");
        }
        else {
            this.props.i18n.changeLanguage(selectedLanguage);
        }

        // if (
        //     this.props.i18n.language !== "English" &&
        //     this.props.i18n.language !== "French"
        // ) {
        //     this.props.i18n.changeLanguage("English");
        // }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    render() {
        switch (this.props.app.currentWindow) {
            case AppWindowName.GENERAL_TERMS_OF_USE_WINDOW:
                return <TermsOfUse></TermsOfUse>;
            case AppWindowName.INTRODUCTION_VIDEO_WINDOW:
                return <Intro></Intro>;
            case AppWindowName.PROJECT_MENU_WINDOW:
                return <ProjectMenu></ProjectMenu>;
            case AppWindowName.PROJECT_NAME_WINDOW:
                return <ProjectName></ProjectName>;
            case AppWindowName.PROJECT_COUNTRY_WINDOW:
                return <ProjectCountry></ProjectCountry>;
            case AppWindowName.ELEMENT_SELECTION_WINDOW:
                return (
                    <ElementSelection
                        setAllAvailableItems={this.setAllAvailableItems}
                    ></ElementSelection>
                );
            case AppWindowName.SYSTEM_NAME_WINDOW:
                return (
                    <SystemSelection
                        allAvailableItems={this.state.allAvailableItems}
                    ></SystemSelection>
                );
            case AppWindowName.RANGE_SELECTION_WINDOW:
                return <RangeSelection
                allAvailableItems={this.state.allAvailableItems}></RangeSelection>;
            case AppWindowName.COUPLING_SELECTION_WINDOW:
                return <CouplingSelection></CouplingSelection>;
            case AppWindowName.RECAP_WINDOW:
                return <RecapComponent></RecapComponent>;
            case AppWindowName.DATA_TRANSFERT:
                return <DataTransfert></DataTransfert>;
            case AppWindowName.FINISH_WINDOW:
                return <Finish></Finish>;
            default:
                return <TermsOfUse></TermsOfUse>;
        }
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        setRoute: ownProps.setRoute,
        route: ownProps.route,
        app: state.app,
    };
};

let mapDispatchToProps = {
    selectWindowToShow,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { allAvailableItems: Element[] };

export default connector(withTranslation(["pam"])(AppBody));
