import { FiltersActionTypes, LOAD_FILTERS, APPLY_FILTERS } from './types'

export const loadFiltersRequest = (filters: any[]): FiltersActionTypes => ({
  type: LOAD_FILTERS,
  filters: filters,
});

export const applyFiltersRequest = (filters: any[]): FiltersActionTypes => ({
  type: APPLY_FILTERS,
  filters: filters
});

