//#region import
import React from "react";
import { ExportComponent } from "../components/exportComponent";

import { ImportComponent } from "../components/importComponent";
import { ReengineeringRoutes } from "./re_engineering_index";
import * as translations from '../json/reengineering_Wording.json';
import { ReendineeringComponent } from "../components/reengineeringComponent";

//#endregion import

interface Props {
  route: string;
  setRoute: any;
  pluginName?: string;
}



export const App = (props: Props) => {

  let revitLang = localStorage.getItem("savedLanguage") as string;
  let language = revitLang !== null ? revitLang: "French";

  switch (props.route) {
    case ReengineeringRoutes.ROOT:
      return <ExportComponent route={props.route} setRoute={props.setRoute} translations={translations} language={language} />;
    case ReengineeringRoutes.EXPORT:
      return <ExportComponent route={props.route} pluginName={props.pluginName ?? ""} setRoute={props.setRoute} translations={translations}  language={language}/>;
    case ReengineeringRoutes.IMPORT:
      return <ImportComponent pluginName={props.pluginName ?? ""} translations={translations} language={language}/>;
    case ReengineeringRoutes.ReEngineer:
      return <ReendineeringComponent translations={translations}  language={language}></ReendineeringComponent>;
    default:
      return <ExportComponent route={props.route} setRoute={props.setRoute} translations={translations}  language={language}/>;
  }
};

export default App;

