import { WALLSYSTEM_ID, FLOORSYSTEM_ID } from "../../assets/constants";
import {
  CartState,
  CartActionTypes,
  ADD_SYSTEM_TO_CART,
  REMOVE_SYSTEM_FROM_CART,
  CLEAN_CART
} from "./types";

export const initialState: CartState = {
  currentSystemItems: [],
  data: {
    wallsSystemDetails: [],
    floorSystemDetails: [],
    // liningWallsDetails: [],
    // partitionsDetails: [],
    // ceilingsDetails: []
  },
  cartCount: 0
};

export default function cartReducer(
  state = initialState,
  action: CartActionTypes
) {
  switch (action.type) {
    case ADD_SYSTEM_TO_CART:
      return {
        ...state,
        currentSystemItems: [...state.currentSystemItems, action.cartSystemItem],
        cartCount: state.cartCount + 1,
        data: {
          wallsSystemDetails: action.systemTypeID === WALLSYSTEM_ID ? [...state.data.wallsSystemDetails, action.cartSystemItem] : [...state.data.wallsSystemDetails],
          floorSystemDetails: action.systemTypeID === FLOORSYSTEM_ID ? [...state.data.floorSystemDetails, action.cartSystemItem] : [...state.data.floorSystemDetails],
        },
      };
    case REMOVE_SYSTEM_FROM_CART:
      return {
        ...state,
        currentSystemItems: [...state.currentSystemItems.slice(0, action.index),
        ...state.currentSystemItems.slice(action.index + 1)],
        data: {
          wallsSystemDetails: action.systemTypeID === WALLSYSTEM_ID ? [...state.data.wallsSystemDetails.slice(0, action.sytemIndex),
          ...state.data.wallsSystemDetails.slice(action.sytemIndex + 1)] : [...state.data.wallsSystemDetails],

          floorSystemDetails: action.systemTypeID === FLOORSYSTEM_ID ? [...state.data.floorSystemDetails.slice(0, action.sytemIndex),
          ...state.data.floorSystemDetails.slice(action.sytemIndex + 1)] : [...state.data.floorSystemDetails],
        },
        cartCount: state.cartCount - 1
      };
    case CLEAN_CART:
      return {
        ...state,
        currentSystemItems: [],
        data: {
          wallsSystemDetails: [],
          floorSystemDetails: [],
          // liningWallsDetails: [],
          // partitionsDetails: [],
          // ceilingsDetails: []
        },
        cartCount: 0
      };
    default:
      return state;
  }
}
