import React, { Component } from "react";
import { Container, Button, Loader, Dimmer, Modal } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import { initGroupSelector, setElementType } from "../Actions";
import { Routes } from "./root";
import { formatProjectTree } from "../Helpers";
import { scheduleHandler } from "../Export";
import { filterTree } from "../../Selection/Helpers";
import { overLapElements } from "../../Overlap/Components/Waiter";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { Oval } from "react-loader-spinner";

interface Props {
  Icon: string;
  name: string;
  wording: {
    selection: { [key: string]: string };
    manualSelection: { [key: string]: string };
    groupSelection: { [key: string]: string };
    schedulesPending: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  initGroupSelector: any;
  setElementType: any;
  config: any;
}

interface State {
  processing: boolean;
  loading: boolean;
  isDownloadSuccessful: boolean;
}

export class SelectionLanding extends Component<Props, State> {
  state = {
    processing: false,
    loading: false,
    isDownloadSuccessful: false,
  };

  public static defaultProps = {
    wording: {
      selection: {
        French: "Sélection",
        English: "Selection",
        German: "Auswahl",
        Italian: "Selezione",
      },
      manualSelection: {
        French: "Sélection Manuelle",
        English: "Manual Selection",
        German: "Manuelle Auswahl",
        Italian: "Selectionz manuale",
      },
      groupSelection: {
        French: "Sélection groupée",
        English: "Group selection",
        German: "Gruppenauswahl",
        Italian: "Selezione del gruppo",
      },
      deletePending: {
        French: "Suppression en cours",
        English: "Deleting",
        German: "Löschen",
        Italian: "La cancellazione in corso",
      },
      wall: {
        French: "Murs",
        English: "Walls",
        German: "Wände",
        Italian: "Muri",
      },
      level: {
        French: "Niveaux",
        English: "Levels",
        German: "Ebenen",
        Italian: "Livelli",
      },
      wallType: {
        French: "Types de murs",
        English: "Wall types",
        German: "Wandtyp",
        Italian: "Tipo de muro",
      },
      thickness: {
        French: "Epaisseur (mm)",
        English: "Thickness (mm)",
        German: "Dicke (mm)",
        Italien: "Spessore (mm)",
      },
      Id: {
        French: "Id du mur",
        English: "Wall Ids",
        German: "Wand-IDs",
        Italian: "ID de muri",
      },
      height: {
        French: "Hauteur (mm)",
        English: "Height (mm)",
        German: "Höhe (mm)",
        Italian: "Altezza (mm)",
      },
      length: {
        French: "Longueur (mm)",
        English: "Length (mm)",
        German: "Länge (mm)",
        Italian: "Lunghezza (mm)",
      },
      surface: {
        French: "Surface (m²)",
        English: "Surface (m²)",
        German: "Oberfläche (m²)",
        Italian: "Superficie (m²)",
      },
      fileName: {
        French: "Nomenclature des murs",
        English: "Wall schedules",
        German: "Wandinventar",
        Italian: "Nomenclatura murale",
      },
      schedulesPending: {
        French: "Métrés en cours",
        English: "Schedules pending",
        German: "Wandinventar in Bearbeitung",
        Italian: "Nomenclatura in corso",
      },
    },
  };

  componentDidMount = () => {
    api.windowsHandler.resizeWindow(600, 200);
  };

  setProcessing = () => {
    this.setState({ processing: false });
  };

  manualSelectionHandler = async () => {
    this.setState({ loading: true });
    const isSuperPoser = await bimStorage.getItem(
      storageKey.IS_SUPERPOSER_ENABLED
    );
    if (isSuperPoser && isSuperPoser === true) {
      await overLapElements();
      bimStorage.setItem(
        storageKey.IS_SUPERPOSER_ENABLED,
        JSON.stringify(false)
      );
    }
    await api.windowsHandler.hideWindow();
    const tree = await api.selection.manualSelection("wall&ceiling");
    let wallTree = formatProjectTree(filterTree(tree, "wall"));
    let ceilingTree = formatProjectTree(filterTree(tree, "ceiling"));

    if (tree.Tree.length > 0) {
      const filteredTree = {
        walls: wallTree,
        ceilings: ceilingTree,
      };

      await api.windowsHandler.showWindow();

      this.setState({
        processing: true,
      });

      await scheduleHandler(
        filteredTree,
        this.setProcessing,
        this.props.config
      );
      this.setState({ isDownloadSuccessful: true });
    }
    this.setState({ loading: false });
    await api.windowsHandler.showWindow();
  };

  groupSelectionHandler = async () => {
    //await api.windowsHandler.hideWindow();
    setTimeout(async () => {
      let wallTree = await api.selection.elementsByLevelAndType("wall");
      let ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
      if (wallTree.Tree.length === 0 && ceilingTree.Tree.length > 0) {
        setElementType("ceiling");
      } else {
        setElementType("wall");
      }

      this.props.initGroupSelector(wallTree, ceilingTree);
      this.props.setRoute(Routes.GROUP_SELECTION);
    });

    //   this.props.setRoute(Routes.GROUP_SELECTION);
    //await api.windowsHandler.showWindow();
    // this.setState({ loading: false });
    //window.addEventListener("blur", () => api.windowsHandler.closeWindow());
  };

  render() {
    const { wording, language } = this.props;
    const manualSelection =
      wording.manualSelection[language] || wording.manualSelection["French"];
    const groupSelection =
      wording.groupSelection[language] || wording.groupSelection["French"];
    const selection =
      wording.selection[language] || wording.selection["French"];
    const schedulesPending =
      wording.schedulesPending[language] || wording.schedulesPending["French"];

    if (this.state.processing) {
      return (
        <div>
          <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
            <Loader content={schedulesPending}></Loader>
          </Dimmer>
          <Modal
            size="tiny"
            open={this.state.isDownloadSuccessful}
            dimmer="blurring"
          >
            <Modal.Description
              style={{
                textAlign: "center",
                padding: "2rem 1.6rem 1.6rem 1.6rem",
              }}
            >
              <h3 style={{ color: "rgb(33, 133, 208)" }}>
                Information - Métrés
              </h3>
              <p>Génération de fichier de métrés terminée avec succès.</p>
              <Button
                primary
                onClick={() => {
                  this.setState({ isDownloadSuccessful: false });
                }}
              >
                Continuer
              </Button>
            </Modal.Description>
          </Modal>
        </div>
      );
    }
    return (
      <div>
        <FunctionalityHeader Icon={this.props.Icon} name={selection} />
        <Container textAlign="center" style={{ marginTop: 20 }}>
          <Button
            onClick={this.manualSelectionHandler}
            primary
            size="big"
            style={{ marginRight: "5%" }}
          >
            {manualSelection}
            {this.state.loading === true ? (
              <div
                style={{
                  float: "right",
                  marginLeft: "5px",
                }}
              >
                <Oval
                  color="#00BFFF" //3 secs
                  height={16}
                  width={16}
                />{" "}
              </div>
            ) : (
              <div></div>
            )}
          </Button>
          <Button
            onClick={this.groupSelectionHandler}
            primary
            size="big"
            style={{ marginLeft: "5%" }}
          >
            {groupSelection}
            {/* {this.state.loading === true ? <div style={{
              float: 'right',
              marginLeft: '5px'
            }}><LoaderSpinner
                type="Oval"
                color="#00BFFF" //3 secs
                height={16}
                width={16}
              /> </div> : <div></div>} */}
          </Button>
        </Container>

        <Modal
          size="tiny"
          open={this.state.isDownloadSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>Information - Métrés</h3>
            <p>Génération de fichier de métrés terminée avec succès.</p>
            <Button
              primary
              onClick={() => {
                this.setState({ isDownloadSuccessful: false });
                api.windowsHandler.closeWindow();
                //window.location.href = window.location.origin + "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/abfb1e2e-b36b-4be4-a78d-df2472cbb96c";
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  initGroupSelector,
  setElementType,
})(SelectionLanding);

export const wording = {
  select: {
    French: "Sélectionner...",
    English: "Select a system...",
  },
  errors: {
    French: "Erreurs",
    English: "Errors",
  },
  errorsHeader: {
    French:
      "Veuillez corriger les erreurs suivantes avant de pouvoir calepiner : ",
    English: "Please fix the following errors before to trigger layout: ",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
  selection: {
    French: "Sélection",
    English: "Selection",
  },
  mapping: {
    French: "Table de correspondance des solutions",
    English: "Solution mapping",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  revitSystemType: {
    French: "Type de système Revit",
    English: "Revit System Type",
  },
  systemChoice: {
    French: "Choix du système à calepiner",
    English: "Layout system selection",
  },
  actions: {
    French: "Actions",
    English: "Actions",
  },
  sideA: {
    French: "Parement A",
    English: "Side A",
  },
  sideB: {
    French: "Parement B",
    English: "Side B",
  },
  lining: {
    French: "Doublage",
    English: "Lining",
  },
  SAA: {
    French: "SAA",
    English: "SAA",
  },
  SAD: {
    French: "SAD",
    English: "SAD",
  },
  framesSpace: {
    French: "Entraxe",
    English: "Frame spacing",
  },
  plateLength: {
    French: "Largeur d'une plaque (mm)",
    English: "Plate length",
  },
  resetFilters: {
    French: "Réinitialiser les filtres",
    English: "Reset filters",
  },

  details: {
    French: "Détails",
    English: "Details",
  },
  configuration: {
    French: "Configuration",
    English: "Configuration",
  },
  loadingFamilies: {
    French: "Chargement des familles...",
    English: "Loading Families",
  },
  processing: {
    French: "Calepinage en cours: ",
    English: "Layout in progress: ",
  },
  fileName: {
    French: "Nomenclature des murs",
    English: "Wall schedules",
  },
  wall: {
    French: "Murs",
    English: "Walls",
  },
  level: {
    French: "Niveaux",
    English: "Levels",
  },
  wallType: {
    French: "Types de murs",
    English: "Wall types",
  },
  thickness: {
    French: "Epaisseur (mm)",
    English: "Thickness (mm)",
  },
  Id: {
    French: "Id du mur",
    English: "Wall Ids",
  },
  height: {
    French: "Hauteur (mm)",
    English: "Height (mm)",
  },
  length: {
    French: "Longueur (mm)",
    English: "Length (mm)",
  },
  surface: {
    French: "Surface (m²)",
    English: "Surface (m²)",
  },
};
