import { Country } from "../assets/constants";
import { bdd } from "../helpers/interceptors";
import {
  findCompatibleProductsForAllRanges,
  sanitizeCouplings,
  sanitizeSubCategories,
  getElementName,
} from "../helpers/utils";
import Element from "../models/Element.model";
import {
  ASSETS_ATTR_TECHNICAL_NAME,
  ASSETS_LOCAL_URL_SUB_ATTR_TECHNICAL_NAME,
  BRAND,
  FAMILY_TYPE_ATTR_TECHNICAL_NAME,
  FR,
  GASKET_MATERIAL_ATTR_TECHNICAL_NAME,
  MAIN_MATERIAL_TYPE_ATTR_TECHNICAL_NAME,
  NAME_ATTR_TECHNICAL_NAME,
  NEED_COUPLING_TECHNICAL_NAME,
  RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME,
  RAW_PRODUCT_PIM_ID_ATTR_TECHNICAL_NAME,
  SHORT_DESC_ATTR_TECHNICAL_NAME,
  UK,
} from "./constants.config";
import _ from "lodash";
import Item from "../models/Item.model";
import CouplingFamilyType from "../models/Coupling";
import { MyConfig } from "../../../../Helper";

export let local: "fr" | "en" = "en";

export const setLocal = (newLocal: "fr" | "en") => {
  local = newLocal;
};

const getNeededAttributes = async (config: any) => {
  let rawNeededCategoryAttributes = await bdd(
    `/attributes/class/CategoryAttribute/locale/${local}`,
    {
      technicalNames: [
        ASSETS_ATTR_TECHNICAL_NAME,
        ASSETS_LOCAL_URL_SUB_ATTR_TECHNICAL_NAME,
        NAME_ATTR_TECHNICAL_NAME,
        NEED_COUPLING_TECHNICAL_NAME,
        SHORT_DESC_ATTR_TECHNICAL_NAME,
      ],
    },
    config
  );
  let rawNeededSGObjectAttributes = await bdd(
    `/attributes/class/SGObjectAttribute/locale/${local}`,
    {
      technicalNames: [
        RAW_PRODUCT_NAME_ATTR_TECHNICAL_NAME,
        RAW_PRODUCT_PIM_ID_ATTR_TECHNICAL_NAME,
        FAMILY_TYPE_ATTR_TECHNICAL_NAME,
        MAIN_MATERIAL_TYPE_ATTR_TECHNICAL_NAME,
        GASKET_MATERIAL_ATTR_TECHNICAL_NAME,
      ],
    },
    config
  );

  let rawNeededSGObjectRecommendationAttributes = await bdd(
    `/attributes/class/SGObjectAttribute/locale/${local}`,
    {
      text: "Recommendation level for",
    },
    config
  );
  return [
    ...rawNeededCategoryAttributes.data.attributes,
    ...rawNeededSGObjectAttributes.data.attributes,
    ...rawNeededSGObjectRecommendationAttributes.data.attributes,
  ];
};

export const getCouplingsForSelectedRanges = async (
  items: Item[],
  projectRegion: string
) => {
  const config = await MyConfig();
  if (items.length > 0) {
    const brand = items[0].range.dependencies.find(
      (dep: any) => dep.className.toLowerCase() === "Brand".toLowerCase()
    );
    const region = items[0].range.dependencies.find(
      (dep: any) => dep.className.toLowerCase() === "Region".toLowerCase()
    );
    let neededAttributes = await getNeededAttributes(config);
    if (projectRegion === Country.UK) {
      let data = await getRanges(projectRegion, neededAttributes, config);
      let { brand, region, ranges } = data;
      let couplingsSubCategoryOids: any = [];
      let tmpItems = await Promise.all(
        ranges.map(async (range: any) => {
          let rangeSubCategories = await getRangesSubCategories(
            brand,
            region,
            range,
            config,
            neededAttributes
          );
          rangeSubCategories.data.objects.forEach((rangeSubCategory: any) => {
            if (
              rangeSubCategory.technicalName
                .toLowerCase()
                .includes("Coupling".toLowerCase())
            ) {
              couplingsSubCategoryOids.push(rangeSubCategory.oid);
            }
          });
        })
      );
      let couplingsByItem = await getElementObjectsFromCategory(
        brand,
        region,
        couplingsSubCategoryOids,
        config,
        neededAttributes
      );

      return Promise.all(
        items.map((item) => {
          item.allCompatibleCouplings = sanitizeCouplings(couplingsByItem);
          return Promise.resolve(item);
        })
      );
    } else {
      let allRanges = await getRanges(projectRegion, neededAttributes, config);
      let couplingsSubCategoryOids: any = [];
      allRanges.ranges.forEach((rangeSubCategory: any) => {
        if (
          rangeSubCategory.technicalName
            .toLowerCase()
            .includes("Coupling".toLowerCase())
        ) {
          couplingsSubCategoryOids.push(rangeSubCategory.oid);
        }
      });

      let couplingsByItem = await getElementObjectsFromCategory(
        brand,
        region,
        couplingsSubCategoryOids,
        config,
        neededAttributes
      );
      return Promise.all(
        items.map((item) => {
          item.allCompatibleCouplings = sanitizeCouplings(couplingsByItem);
          return Promise.resolve(item);
        })
      );
      // return await getElementDetailsFromElementObject(
    }
  }
};

export const getItemsAndCompatibleElements = async (
  projectRegion: string,
  allAvailableElements: Element[]
) => {
  const config = await MyConfig();
  let neededAttributes = await getNeededAttributes(config);
  return getRanges(projectRegion, neededAttributes, config).then(
    async (data) => {
      let { brand, region, ranges } = data;
      let tmpItems = await Promise.all(
        ranges.map(async (range: any) => {
          let rangeSubCategories = await getRangesSubCategories(
            brand,
            region,
            range,
            config,
            neededAttributes
          );

          let classifiedSubCategory: any = {
            pipes: [],
            fittings: [],
            accessories: [],
          };
          classifiedSubCategory = sanitizeSubCategories(rangeSubCategories);

          let pipes: any[] = [];

          if (
            classifiedSubCategory.pipes &&
            classifiedSubCategory.pipes.length > 0
          ) {
            pipes = await getElementObjectsFromCategory(
              brand,
              region,
              classifiedSubCategory.pipes,
              config,
              neededAttributes
            );

            // pipes = await getElementDetailsFromElementObject(
            //     pipeProducts.data.objects
            // );
          }

          let fittings: any[] = [];

          if (
            classifiedSubCategory.fittings &&
            classifiedSubCategory.fittings.length > 0
          ) {
            fittings = await getElementObjectsFromCategory(
              brand,
              region,
              classifiedSubCategory.fittings,
              config,
              neededAttributes
            );

            // fittings = await getElementDetailsFromElementObject(
            //     fittingProducts.data.objects
            // );
          }

          return Promise.resolve({
            range: range,
            pipes,
            fittings,
            allCompatibleElements: [],
            filters: {
              pipes: {},
              fittings: {},
              accessories: {},
            },
            current: false,
          });
        })
      );

      let items: any[] = [];
      await Promise.all(
        tmpItems.map(async (item: any) => {
          let tmp = await item;
          if (
            tmp.range.attributes !== null &&
            tmp.range.attributes !== undefined
          ) {
            items.push(tmp);
          }
        })
      );

      let looseElements: Element[] = [];
      items.forEach((item) => {
        if (item.allCompatibleElements === undefined) {
          item.allCompatibleElements = [];
        }
        let { compatibleElements: tmp, looseElements: tmpLooseElements } =
          findCompatibleProductsForAllRanges(item, allAvailableElements);
        item.allCompatibleElements.push(..._.cloneDeep(tmp));
        item.compatibleAndAvailableElements = _.cloneDeep(
          item.allCompatibleElements
        );

        item.compatibleAndAvailableElementsSanitized = [];
        _.cloneDeep(item.compatibleAndAvailableElements).forEach(
          (element: Element) => {
            let found = item.compatibleAndAvailableElementsSanitized.some(
              (elementSanitized: Element) => {
                return (
                  getElementName(element) === getElementName(elementSanitized)
                );
              }
            );
            if (!found) {
              item.compatibleAndAvailableElementsSanitized.push(element);
            }
          }
        );

        for (let i = 0; i < tmpLooseElements.length; i++) {
          let tmpLooseElement: Element = tmpLooseElements[i];
          let found = false;
          items.forEach((item: Item) => {
            let tmp = item.allCompatibleElements.find((element: Element) => {
              return tmpLooseElement.Id === element.Id;
            });
            if (tmp) {
              found = true;
            }
          });
          if (found) {
            tmpLooseElements.splice(i, 1);
            i--;
          }
        }

        for (let i = 0; i < tmpLooseElements.length; i++) {
          let alreadyExistingLooseElement = false;
          looseElements.forEach((element) => {
            if (
              tmpLooseElements[i].pimObject === undefined ||
              element.pimObject === undefined
            ) {
              alreadyExistingLooseElement = true;
            } else {
              if (element.Id === tmpLooseElements[i].Id) {
                alreadyExistingLooseElement = true;
              }
            }
          });
          if (alreadyExistingLooseElement) {
            tmpLooseElements.splice(i, 1);
            i--;
          }
        }

        for (let i = 0; i < looseElements.length; i++) {
          let tmpLooseElement: Element = looseElements[i];
          let found = false;
          items.forEach((item: Item) => {
            let tmp = item.allCompatibleElements.find((element: Element) => {
              return tmpLooseElement.Id === element.Id;
            });
            if (tmp) {
              found = true;
            }
          });
          if (found) {
            looseElements.splice(i, 1);
            i--;
          }
        }
        looseElements.push(...tmpLooseElements);
        delete item.fittings;
        delete item.pipes;
      });
      return Promise.resolve({ items, looseElements });
    }
  );
};

export const getRangesDetails = async (projectRegion: string, config: any) => {
  let brand: any;
  let region: any;
  let neededAttributes = await getNeededAttributes(config);
  let ranges = await getRanges(projectRegion, neededAttributes, config).then(
    async (data) => {
      brand = data.brand;
      region = data.region;
      let ids: string[] = [];
      ranges.data.forEach((range: any) => {
        ids.push(range.oid);
      });

      return bdd(
        `/objects/details/class/Category/locale/${local}`,
        [...ids],
        config
      );
    }
  );

  return { ranges: ranges.data.objects, brand, region };
};

export const getRanges = async (
  projectRegion: string,
  neededAttributes: any[],
  config: any
) => {
  let brand = await (await getBrand(config)).data.objects[0];
  let region = await (await getRegion(projectRegion, config)).data.objects[0];

  switch (projectRegion) {
    case Country.UK: {
      let allObjects = await (
        await getCategories(region, brand, config)
      ).data.objects;

      let parentsIds: any[] = [];
      allObjects.forEach((object: any) => {
        if (object.parentOids === undefined) {
          parentsIds.push(object.oid);
        }
      });

      let ranges = await bdd(
        `/objects/class/Category/locale/${local}`,
        {
          dependencies: [
            { className: "Brand", oids: [brand.oid] },
            { className: "Region", oids: [region.oid] },
          ],
          parentObjectIds: parentsIds,
          returnAttributes: neededAttributes.map((attr: any) => attr.aid),
        },
        config
      );

      return {
        ranges: ranges.data.objects,
        brand,
        region,
      };
    }
    default: {
      let allObjects = await (
        await getCategories(region, brand, neededAttributes, config)
      ).data.objects;
      let parents: any[] = [];
      allObjects.forEach((object: any) => {
        if (object.parentOids === undefined) {
          parents.push(object);
        }
      });
      return { ranges: parents, brand, region };
    }
  }
};

export const getCategories = async (
  region: any,
  brand: any,
  config: any,
  neededAttributes: any = []
) => {
  return bdd(
    `/objects/class/Category/locale/${local}`,
    {
      dependencies: [
        { className: "Brand", oids: [brand.oid] },
        { className: "Region", oids: [region.oid] },
      ],
      returnAttributes: neededAttributes.map((attr: any) => attr.aid),
    },
    config
  );
};

export const getBrand = (config: any) => {
  return bdd(
    `/objects/class/Brand/locale/${local}`,
    {
      technicalNames: [BRAND],
    },
    config
  );
};

export const getRegion = (region: string, config: any) => {
  switch (region) {
    case Country.FR:
      return bdd(
        `/objects/class/Region/locale/${local}`,
        {
          technicalNames: [FR],
        },
        config
      );
    case Country.UK:
      return bdd(
        `/objects/class/Region/locale/${local}`,
        {
          technicalNames: [UK],
        },
        config
      );
    default:
      return bdd(
        `/objects/class/Region/locale/${local}`,
        {
          technicalNames: [UK],
        },
        config
      );
  }
};

export const getRangesSubCategories = async (
  brand: any,
  region: any,
  range: any,
  config: any,
  neededAttributes: any = []
) => {
  return bdd(
    `/objects/class/Category/locale/${local}`,
    {
      dependencies: [
        { className: "Brand", oids: [brand.oid] },
        { className: "Region", oids: [region.oid] },
      ],
      parentObjectIds: [range.oid],
      returnAttributes: neededAttributes.map((attr: any) => attr.aid),
    },
    config
  );
};
export const getRangesParent = async (
  brand: any,
  region: any,
  range: any,
  config: any,
  neededAttributes: any = []
) => {
  return bdd(
    `/objects/class/Category/locale/${local}`,
    {
      dependencies: [
        { className: "Brand", oids: [brand.oid] },
        { className: "Region", oids: [region.oid] },
      ],
      oid: range.parentObjectId,
      returnAttributes: neededAttributes.map((attr: any) => attr.aid),
    },
    config
  );
};

export const getElementObjectsFromCategory = async (
  brand: any,
  region: any,
  subCategory: any[],
  config: any,
  neededAttributes: any[] = []
) => {
  let pipeObject = await bdd(
    `/objects/class/SGObject/locale/${local}?recursive=true`,
    {
      dependencies: [
        { className: "Brand", oids: [brand.oid] },
        { className: "Region", oids: [region.oid] },
        {
          className: "Category",
          oids: [...subCategory],
        },
      ],
      returnAttributes: neededAttributes.map((attr: any) => attr.aid),
    },
    config
  );
  return Promise.resolve(pipeObject.data.objects);
};

export const getElementDetailsFromElementObject = async (
  elementObjects: any[],
  config: any
) => {
  let elementDetails: any[] = [];

  let pipeObject = await bdd(
    `/objects/details/class/SGObject/locale/${local}`,
    [
      ...elementObjects
        .map((pipe) => {
          return pipe.oid;
        })
        .slice(20),
    ],
    config
  );

  // await Promise.all(
  //     elementObjects.map(async (pipe: any) => {
  //         let pipeObject = await bdd(
  //             `/objects/details/class/SGObject/locale/${local}`,
  //             [pipe.oid]
  //         );

  //         if (pipeObject) {
  //             elementDetails.push(...pipeObject.data.objects);
  //         }
  //         return Promise.resolve();
  //     })
  // );

  return Promise.resolve(pipeObject.data.objects);
};

export const getObjectDetails = (object: Element, config: any) => {
  let elementId = object.pimObject.oid;
  let couplingId = object.selectedCoupling?.oid;
  return bdd(
    `/objects/details/class/SGObject/locale/${local}?excludeContextAttributes=true&excludeContextObjects=true&includeChildren=true&includeRelatedObjects=true`,
    [elementId, couplingId],
    config
  );
};
