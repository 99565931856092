import React, { useEffect, useState } from "react";
import { TopHeader } from "../../Components/Headers";
import { SubHeader } from "../../Components/Headers";
import {
  Dimmer,
  Segment,
  Button,
  Modal,
  Progress,
  Header,
} from "semantic-ui-react";
import { Step } from "./Step";
import { Selection } from "./Selection";
import { Calpiner } from "./Calpiner";
import { bimStorage } from "../../../../BIMStore";
import {
  map,
  filter,
  flattenDeep,
  uniq,
  groupBy,
  isEqual,
  uniqWith,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import {
  callLayoutCalculator,
  createCalepinableRow,
  drawWallTypeAssigment,
  isWallProcessed,
} from "../utils/calepinage";
import { MyConfig } from "../../../../Helper";
import "../Resources/detail.css";
import { LayoutConfigType } from "../utils/types";
import { useForm } from "react-hook-form";
import { ELayoutMethod } from "../../Types";
import LayoutMethodPopup from "../Components/LayoutMethodPopup";
import { asyncForEach } from "../../../PlacoBIMv3/Selection/Actions/placoAction";

interface Props {
  setRoute: any;
  calepinage: any;
  onCalepinageUpdate: any;
}

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0em",
  },
  height_486: {
    height: "calc(100vh - 70px)",
  },
};

const inititalLayoutConfig: LayoutConfigType = {
  WallTypeAssignment: true,
  MetalFraming: false,
  DryWall: false,
};

interface ProgressBarType {
  percent: number;
  label: string;
  active: boolean;
}

export const Detail = (props: Props) => {
  const { setRoute, calepinage, onCalepinageUpdate } = props;
  const [detailRoute, setDetailRoute] = useState<string>("");
  const [progress, setProgress] = useState<ProgressBarType>({
    percent: 0,
    label: "",
    active: false,
  });
  const [showSuccess, setSuccess] = useState<boolean>(false);
  const [skipEraseOpen, setSkipEraseOpen] = useState<boolean>(false);
  const [erase, setErase] = useState<boolean>(false);
  const [openLayoutMethodSelection, setOpenLayoutMethodSelection] =
    useState<boolean>(false);
  const [processedWallsIds, setProcessedWallsIds] = useState<string[]>([]);
  const [layoutConfig, setLayoutConfig] =
    useState<LayoutConfigType>(inititalLayoutConfig);

  const { register, handleSubmit } = useForm();

  const onSelectionSelected = async (selectionId: any) => {
    const response = await createCalepinableRow(selectionId);

    if (response.length > 0) {
      let calObject = {
        ...calepinage,
        SelectionIds: [selectionId.toString()],
        SelectionStatus: true,
        CalepinerStatus: false,
        CalepinageDetails: response,
      };

      await onCalepinageUpdate(calObject);
      setDetailRoute("");
    }
  };

  const onCalepinerValidate = (data: any) => {
    const calObject = {
      ...calepinage,
      CalepinageDetails: data.CalepinageDetails.map((d: any) => {
        d.Ids = JSON.parse(d.Ids);
        d.Solution = JSON.parse(d.Solution);
        return d;
      }),
      CalepinerStatus: true,
    };
    onCalepinageUpdate(calObject);
    setDetailRoute("");
  };

  const updateProgress = (perc: number, label: string) => {
    setProgress((prevProgress: ProgressBarType) => {
      return {
        ...prevProgress,
        percent: perc === 0 ? 0 : prevProgress.percent + perc,
        label: label,
      };
    });
  };

  // const callUserInput = async () => {
  //   setOpenLayoutMethodSelection(false);
  //   const { hasProcessWalls, proccessedWallsIds } = await isWallProcessed(
  //     layoutConfig,
  //     calepinage
  //   );

  //   if (proccessedWallsIds.length > 0) {
  //     setProcessedWallsIds(proccessedWallsIds);
  //   }

  //   if (hasProcessWalls) {
  //     setSkipEraseOpen(true);
  //   } else {
  //     onCalepinageRun(false);
  //   }
  // };

  const onCalepinageRun = async (
    lyConfig: LayoutConfigType,
    lyMethod: ELayoutMethod,
    erase: boolean
  ) => {
    setSkipEraseOpen(false);
    let cloneCalepinage = calepinage;
    // let wallIds = flattenDeep(map(cloneCalepinage.CalepinageDetails, "Ids"));
    let wallIds = flattenDeep(
      map(filter(cloneCalepinage.CalepinageDetails, "Check"), "Ids")
    );
    if (!erase) {
      wallIds = filter(wallIds, (ids: any) => {
        return !processedWallsIds.includes(ids.toString());
      });
    }

    let notDownloadedSystemType: string[] = [];
    if (wallIds.length > 0) {
      setProgress((prevProgress) => {
        return { ...prevProgress, active: true };
      });

      updateProgress(0, "starting..");
      let layoutWalls: any;
      let nonLayoutWalls: any;
      if (lyConfig.WallTypeAssignment) {
        const revitVersion = parseInt(await api.framework.getRevitVersion());
        //#region gyproc solution check
        // if (revitVersion === 2022) {
        await window.revit.windowType();
        // const usedGyprocData: any = await getUsedGyprocSystemsData();

        // new Promise((resolve, reject) =>
        //   window.gyproc.changeGyprocLODForLayoutTool(resolve, reject)
        // ).then((x: any) => console.log("changesLODto600", JSON.parse(x)));
        await asyncForEach(
          calepinage.CalepinageDetails,
          async (cd: any, index: number) => {
            let solutionIndex = -1;
            // if (usedGyprocData?.length > 0) {
            //   solutionIndex = usedGyprocData.findIndex(
            //     (gyp: any) => gyp.gyprocSystemCode === cd.Solution.Name
            //   );
            // }

            //cloneCalepinage.CalepinageDetails[index].isProcess = true;
            //if (solutionIndex === -1) {
            let result = await downloadGyprocSystemData(cd.Solution.Name);
            console.log("family download status");
            console.log(cd.Solution.Name, result);

            if (result === false) {
              notDownloadedSystemType.push(cd.Solution.Name);
            }
            //cloneCalepinage.CalepinageDetails[index].isProcess = result;
            //  }
          }
        );
        // }

        if (notDownloadedSystemType.length > 0) {
          alert(
            "The following Gyproc system types are not downloaded or already present: " +
              notDownloadedSystemType.join(", ")
          );
        }

        //#endregion gyproc solution check
        updateProgress(20, "wall type assigning..");

        const onChangeWallType = await drawWallTypeAssigment(
          cloneCalepinage,
          revitVersion
        );

        cloneCalepinage = onChangeWallType.cloneCalepinage;
        layoutWalls = onChangeWallType.layoutWalls;
        nonLayoutWalls = onChangeWallType.nonLayoutWalls;
      }
      wallIds = flattenDeep(
        map(filter(cloneCalepinage.CalepinageDetails, "isProcess"), "Ids")
      );

      if (wallIds.length > 0) {
        if (lyConfig.MetalFraming || lyConfig.DryWall) {
          if (erase) {
            updateProgress(20, "erasing..");
            await api.queries.deletePartsWalls(
              processedWallsIds,
              lyConfig.MetalFraming.toString(),
              lyConfig.DryWall.toString()
            );
          }

          if (lyConfig.DryWall) {
            updateProgress(20, "Creating Parts..");
            await new Promise((resolve, reject) =>
              window.indec.createParts({ ids: wallIds }, resolve, reject)
            )
              .then((x: any) => {
                console.info("Create-Parts-Response:", JSON.parse(x));
                return JSON.parse(x);
              })
              .catch((ex: any) => {
                console.error(ex);
                return [];
              });
          }

          updateProgress(20, "Collecting Structure and Parts..");
          let wallStructure = await new Promise((resolve, reject) =>
            window.indec.getWallStructure({ ids: wallIds }, resolve, reject)
          )
            .then((x: any) => {
              return JSON.parse(x);
            })
            .catch((ex: any) => {
              console.error(ex);
              return [];
            });
          console.info("GetWallStructure-Response-Before:", wallStructure);
          wallStructure = uniqWith(wallStructure.data, isEqual);

          let wallParts = await new Promise((resolve, reject) =>
            window.indec.getWallPartsAsync({ ids: wallIds }, resolve, reject)
          )
            .then((x: any) => {
              return JSON.parse(x);
            })
            .catch((ex: any) => {
              console.error(ex);
              return [];
            });

          wallParts = uniqWith(wallParts.data, isEqual);
          console.info("GetWallStructure-Response:", wallStructure);
          console.info("GetWallPartsAsync-Response:", wallParts);
          updateProgress(20, "Calculating..");

          const layoutData = await callLayoutCalculator(
            cloneCalepinage,
            layoutWalls,
            erase,
            processedWallsIds,
            wallParts,
            wallStructure,
            lyMethod
          );

          if (layoutData) {
            const config = await MyConfig();
            if (config) {
              if (lyMethod == ELayoutMethod.NEW) {
                if (lyConfig.MetalFraming) {
                  updateProgress(0, "Applying framing..");
                  await api.familyEditor.drawFraming(
                    layoutData,
                    config.REACT_APP_DOWNLOAD_FAMILY_URL_GYPBEN
                  );
                }

                if (lyConfig.DryWall) {
                  updateProgress(20, "Applying drywall..");
                  const response = await api.familyEditor.drawDryWall(
                    layoutData
                  );
                }
              } else {
                updateProgress(20, "Layouting with OLD method..");
                await api.queries.deletePartsWalls(
                  processedWallsIds,
                  lyConfig.MetalFraming.toString(),
                  lyConfig.DryWall.toString()
                );
                const drResult = await api.familyEditor.drawElements(
                  layoutData,
                  () => {},
                  () => {},
                  config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO,
                  () => {}
                );
              }
            }
          }
        }
      } else {
      }
    }
    updateProgress(10, "saving..");
    let response = false;
    if (cloneCalepinage.Id) {
      var currentdate = new Date();
      cloneCalepinage = {
        ...cloneCalepinage,
        Date: currentdate.getTime(),
      };
      response = await bimStorage.updateCalepinage(
        cloneCalepinage.Id,
        cloneCalepinage
      );
      if (response) {
        onCalepinageUpdate(cloneCalepinage);
        setSuccess(true);
      }
    } else {
      let saveId = await bimStorage.saveCalepinage(cloneCalepinage);
      if (saveId) {
        const updatedCalepinage = await new Promise((resolve, reject) =>
          window.indec.getCalepinage(parseInt(saveId), resolve, reject)
        )
          .then((x: any) => {
            return x;
          })
          .catch((ex: any) => {
            console.error(ex);
            return [];
          });

        await onCalepinageUpdate(updatedCalepinage);
        setSuccess(true);
      }
    }
  };

  const onSelectAll = (checked: any) => {
    const calObject = {
      ...calepinage,
      CalepinageDetails: calepinage.CalepinageDetails.map((d: any) => {
        if (d.IsLayout) {
          d.Check = checked;
        }
        return d;
      }),
    };
    onCalepinageUpdate(calObject);
  };

  const onCalepinageSave = async () => {
    if (calepinage.Id !== undefined) {
      var currentdate = new Date();
      const calepinageObj = {
        ...calepinage,
        Date: currentdate.getTime(),
        // Date: `${currentdate.getDate()}/${
        //   currentdate.getMonth() + 1
        // }/${currentdate.getFullYear()}`,
      };
      await bimStorage.updateCalepinage(calepinage.Id, calepinageObj);
    } else {
      const saveId = await bimStorage.saveCalepinage(calepinage);
      if (saveId) {
        const updatedCalepinage = await new Promise((resolve, reject) =>
          window.indec.getCalepinage(parseInt(saveId), resolve, reject)
        )
          .then((x: any) => {
            return x;
          })
          .catch((ex: any) => {
            console.error(ex);
            return [];
          });

        // await bimStorage.getCalepinage(response);
        await onCalepinageUpdate(updatedCalepinage);
      }
    }
    // const calepi = await bimStorage.getCalepinage(id);
    // onCalepinageUpdate(calepi);
  };

  const onCalepinerClick = async (lyConfig: LayoutConfigType) => {
    setLayoutConfig(lyConfig);

    const { hasProcessWalls, proccessedWallsIds } = await isWallProcessed(
      layoutConfig,
      calepinage
    );

    if (proccessedWallsIds.length > 0) {
      setProcessedWallsIds(proccessedWallsIds);
    }

    if (hasProcessWalls) {
      setSkipEraseOpen(true);
    } else {
      setOpenLayoutMethodSelection(true);
    }
  };

  const onSkipEraseClose = (erase: boolean) => {
    setErase(erase);
    setSkipEraseOpen(false);
    setOpenLayoutMethodSelection(true);
  };

  const onLayoutMethodSelection = async (method: ELayoutMethod) => {
    setOpenLayoutMethodSelection(false);

    await onCalepinageRun(layoutConfig, method, erase);
  };

  const DetailSwitch = () => {
    switch (detailRoute) {
      case "selection":
        return (
          <>
            <SubHeader heading={"Saved selections list"} />
            <Selection
              setDetailRoute={setDetailRoute}
              onSelect={onSelectionSelected}
              selectionId={calepinage.SelectionIds[0]}
              setRoute={setRoute}
            />
          </>
        );
      case "calepinage":
        return (
          <>
            <SubHeader heading={"Saved selections list"} />
            <Calpiner
              setDetailRoute={setDetailRoute}
              data={calepinage}
              onCalepinerValidate={onCalepinerValidate}
              onSelectAll={onSelectAll}
            />
          </>
        );
      default:
        return (
          <>
            <Dimmer
              active={progress.active}
              inverted
              className="gyproc-caliper-progress"
            >
              <Progress percent={progress.percent} color="blue">
                {progress.label}
              </Progress>
            </Dimmer>
            <SubHeader heading={"1/2 completed steps"} />
            <Step
              setDetailRoute={setDetailRoute}
              setRoute={setRoute}
              selectionStatus={calepinage.SelectionStatus}
              calepinageStatus={calepinage.CalepinerStatus}
              onCalepinageRun={(lyConfig: LayoutConfigType) => {
                onCalepinerClick(lyConfig);
              }}
              onCalepinageSave={onCalepinageSave}
            />
          </>
        );
    }
  };

  //#region gyproc plugin methods
  const getUsedGyprocSystemsData = async () => {
    return await new Promise((resolve, reject) =>
      window.gyproc.getUsedGyprocSystemsData(resolve, reject)
    ).then((x: any) => {
      return JSON.parse(x);
    });
  };

  const downloadGyprocSystemData = async (gyprocSystemCode: string) => {
    return await new Promise(
      async (resolve, reject) =>
        await window.gyproc.downloadGyprocSystem(
          {
            gyprocSystemCode: gyprocSystemCode,
            typeName: gyprocSystemCode,
            lod: 600,
          },
          resolve,
          reject
        )
    ).then((x: any) => {
      if (x === "false") {
        return false;
      }
      return true;
    });
  };
  //#endregion gyproc plugin methods

  return (
    <div style={{ height: "100%" }}>
      <TopHeader Icon={""} name={calepinage.Name} />
      {DetailSwitch()}
      <Modal size="tiny" open={showSuccess} dimmer="blurring">
        <Modal.Description
          style={{
            textAlign: "center",
            padding: "2rem 1.6rem 1.6rem 1.6rem",
          }}
        >
          <h3 style={{ color: "rgb(33, 133, 208)" }}>Information</h3>
          {/* <p>Calepinage terminé avec succès.</p> */}
          <p>Layout completed successfully.</p>
          <Button
            primary
            onClick={() => {
              setProgress((prevProgress) => {
                return { ...prevProgress, active: false };
              });
              setSuccess(false);
            }}
          >
            Continuer
          </Button>
        </Modal.Description>
      </Modal>
      <Dimmer active={skipEraseOpen}>
        <Segment
          textAlign="left"
          style={{ width: 600, padding: 20, overflow: "auto" }}
        >
          <Header textAlign="center">Action required</Header>
          <p style={{ color: "black" }}>
            Your selection includes partitions already laid out, which do you
            want to do?
          </p>
          <form
            onSubmit={handleSubmit((data) => {
              onSkipEraseClose(data.radioGroup === "true");
            })}
          >
            <label className={"skip-control"}>
              <input type="radio" value={"false"} {...register("radioGroup")} />
              Ignore duplicates (the existing layout will be retained)
            </label>
            <label className={"skip-control"}>
              <input type="radio" value={"true"} {...register("radioGroup")} />
              Replace the layout (the existing layout will be erased)
            </label>
            <Button floated="right" type={"submit"} primary>
              OK
            </Button>
          </form>
        </Segment>
      </Dimmer>
      <LayoutMethodPopup
        show={openLayoutMethodSelection}
        onSave={(ly) => {
          onLayoutMethodSelection(ly);
        }}
        onClose={() => setOpenLayoutMethodSelection(false)}
      />
    </div>
  );
};
