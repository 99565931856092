import { combineReducers } from "redux";
import app from "./appReducer";
import csv from "./csvReducer";
import { RootState } from "./initialState";

export { RootState };

export default combineReducers({
    // add your reducers
    app,
    csv,
});
