import { AnyAction } from "redux";
import { CHANGE_CURRENT_WINDOW } from "../actions/actionTypes";
import preProcessApiActions from "./commonAsyncReducer";
import { RootState } from "./initialState";

export default function app(state = RootState.app, action: AnyAction) {
    let preProcessedState = {
        ...state,
        ...preProcessApiActions(state, action.type),
    };
    switch (action.type) {
        case CHANGE_CURRENT_WINDOW:
            return { ...preProcessedState, currentWindow: action.payload };
        default:
            return state;
    }
}
