import React from "react";
import Landing from "./Landing";
import BIMModel from "./ChoiceOfSources/BIMModel/BIMModel";
import CeilingTableComponent from "./ChoiceOfSources/BIMModel/CeilingTableComponent";
import WallsTableComponent from "./ChoiceOfSources/BIMModel/WallsTableComponent";
import SelectionLanding from "./ChoiceOfSources/Selection/SelectionLanding";
import FloorList from "./selection/FloorList";
import ZoneList from "./selection/ZoneList";
import RatioTable from "./ration/RatioTable";
import { QuantyTab } from "./TabBar/Tab";
import SelectionRoot from "../../Selection/Components/root";

interface Props {
  route: string;
  setRoute: any;
}

export const App = (props: Props) => {
  switch (props.route) {
    case "ROOT":
      return <Landing setRoute={props.setRoute} route={props.route} />;
    // case "SELECTIONLANDING":
    //   return <SelectionLanding setRoute={props.setRoute} route={props.route} />;
    case "SELECTIONLIST":
      return <FloorList setRoute={props.setRoute} route={props.route} />;
    case "ZONELIST":
      return <ZoneList setRoute={props.setRoute} route={props.route} />;
    case "BIMMODEL":
      return <BIMModel />;
    case "WALLTABLECOMPONENT":
      return <RatioTable setRoute={props.setRoute} route={props.route} />;
    case "RATIOTABLE":
      return <RatioTable setRoute={props.setRoute} route={props.route} />;
    case "SELECTIONHOME":
      //api.windowsHandler.resizeWindow(900, 507);
      return <SelectionRoot module="selection" />;
    default:
      return <Landing setRoute={props.setRoute} route={props.route} />;
  }
};

export default App;
