import Axios from "axios";

export const requestToLayoutCal = async (requestData: any, cng: any) => {
  const reqUrl = `${cng.REACT_APP_CALCULATORURL_FACADE}/`;

  Axios.interceptors.request.use((request: any) => {
    if (
      request.responseType !== "arraybuffer" &&
      !request.url?.includes("/auth/oauth") &&
      !request.url?.includes("/oauth/token")
    ) {
      request.headers.Authorization = "Bearer " + localStorage.getItem("token");
      request.headers.Accept = "application/json";
      request.headers["Content-Type"] = "application/json";
    }
    return request;
  });

  Axios.interceptors.response.use((response) => {
    return response;
  });

  const responseLayout = await Axios.post(reqUrl, requestData).then(
    (response) => {
      return response;
    }
  );
  return responseLayout;
};
