import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Icon,
  Modal,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import { FacadeStore } from "../../Store/Reducers";
import wording from "../../Resources/wording.json";
import { dbStoreNameFacadeCalepinage, Routes } from "./root";
import { SetModuleData, SetOptions } from "../../Store/Actions";
import _ from "lodash";
import { bimStorage } from "../../../../BIMStore";

const Options = (props: any) => {
  const reduxState = useSelector((state: FacadeStore) => state);
  const language: string = reduxState.language;
  const buffer = reduxState.buffer;
  const projectData = reduxState.projectData;
  const dispatch = useDispatch();

  const translation: any = wording;

  const [optimaChecked, setOptimaChecked] = useState<boolean>(
    buffer.options.data.optima
  );
  const [liernesChecked, setLiernesChecked] = useState<boolean>(
    buffer.options.data.liernes
  );
  const [STDChecked, setSTDChecked] = useState<boolean>(
    buffer.options.data.std
  );
  const [platineBasChecked, setPlatineBasChecked] = useState<boolean>(
    buffer.options.data.platineBas
  );
  const [ACLChecked, setACLChecked] = useState<boolean>(
    buffer.options.data.alc
  );
  const [platineHautChecked, setPlatineHauthecked] = useState<boolean>(
    buffer.options.data.platineHaut
  );
  const [ACCChecked, setACCChecked] = useState<boolean>(
    buffer.options.data.acc
  );
  const [ACChecked, setACChecked] = useState<boolean>(buffer.options.data.ac);

  const [showWarning, setShowWarning] = useState<boolean>(false);

  const toggleCheck = (type: string) => {
    if (type === "optima") {
      setOptimaChecked(!optimaChecked);
    } else if (type === "liernes") {
      setLiernesChecked(!liernesChecked);
    } else if (type === "STD") {
      setSTDChecked(!STDChecked);
    } else if (type === "platineBas") {
      setPlatineBasChecked(!platineBasChecked);
    } else if (type === "ACL") {
      setACLChecked(!ACLChecked);
    } else if (type === "platineHaut") {
      setPlatineHauthecked(!platineHautChecked);
    } else if (type === "ACC") {
      setACCChecked(!ACCChecked);
    } else if (type === "AC") {
      setACChecked(!ACChecked);
    }
  };

  const onValidateClicked = async () => {
    let data = getOptionsData();

    dispatch(SetOptions(data));
    await onAutoSave();
    props.setRoute(Routes.NEW_CALEPINAGE);
  };

  const onBackClicked = () => {
    let data = getOptionsData();

    if (!_.isEqual(data, buffer.options.data)) {
      setShowWarning(true);
    } else {
      props.setRoute(Routes.NEW_CALEPINAGE);
    }
  };

  const getOptionsData = () => {
    let data = {
      optima: optimaChecked,
      std: STDChecked,
      alc: ACLChecked,
      acc: ACCChecked,
      liernes: liernesChecked,
      platineBas: platineBasChecked,
      platineHaut: platineHautChecked,
      ac: ACChecked,
    };

    return data;
  };

  const onNoClicked = () => {
    props.setRoute(Routes.NEW_CALEPINAGE);
  };

  const checkDisabled = (): boolean => {
    if (
      optimaChecked ||
      STDChecked ||
      ACLChecked ||
      ACCChecked ||
      liernesChecked ||
      platineBasChecked ||
      platineHautChecked ||
      ACChecked
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onAutoSave = async () => {
    await bimStorage.setModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId,
      buffer.name,
      buffer.date,
      buffer.id,
      JSON.stringify(buffer)
    );

    let calepinageData = await bimStorage.listModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId
    );

    dispatch(SetModuleData(calepinageData));
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={
            reduxState.config.REACT_APP_SERVERURL + "/Facade/calpinageTitle.png"
          }
          name={translation.calepinage[language]}
          subheader=""
          config={reduxState.config}
        />

        <Container
          className="header-alignment subHeader green"
          style={{ marginTop: "1rem" }}
        >
          {translation.options[language]}
        </Container>
        <Container
          className="grid-container facade-checkbox-radio"
          style={{
            paddingTop: 15,
            height: "calc(100vh - 220px)",
            overflow: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid>
            <Grid.Row
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={optimaChecked}
                  label={translation.optima[language]}
                  onChange={() => {
                    toggleCheck("optima");
                  }}
                />
              </Grid.Column>
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={liernesChecked}
                  label={translation.liernes[language]}
                  onChange={() => {
                    toggleCheck("liernes");
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={STDChecked}
                  label={translation.STD[language]}
                  onChange={() => {
                    toggleCheck("STD");
                  }}
                />
              </Grid.Column>
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={platineBasChecked}
                  label={translation.platineBas[language]}
                  onChange={() => {
                    toggleCheck("platineBas");
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={ACLChecked}
                  label={translation.ACL[language]}
                  onChange={() => {
                    toggleCheck("ACL");
                  }}
                />
              </Grid.Column>
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={platineHautChecked}
                  label={translation.platineHaut[language]}
                  onChange={() => {
                    toggleCheck("platineHaut");
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={ACCChecked}
                  label={translation.ACC[language]}
                  onChange={() => {
                    toggleCheck("ACC");
                  }}
                />
              </Grid.Column>
              <Grid.Column width="six" textAlign="right">
                <Checkbox
                  checked={ACChecked}
                  label={translation.AC[language]}
                  onChange={() => {
                    toggleCheck("AC");
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span>
            <Button
              color="grey"
              content={translation.back[language]}
              onClick={() => {
                onBackClicked();
              }}
            ></Button>
          </span>
          <span>
            <Button
              disabled={checkDisabled()}
              color="green"
              content={translation.validate[language]}
              onClick={() => {
                onValidateClicked();
              }}
            ></Button>
          </span>
        </div>
      </div>

      <Modal
        open={showWarning}
        size="mini"
        dimmer="inverted"
        className="FacadeModal"
      >
        <Modal.Header
          className="modalHeader"
          style={{ backgroundColor: "#70ad47 !important" }}
        >
          {translation.save[language]}
          <Icon
            name="close"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setShowWarning(false);
            }}
          ></Icon>
        </Modal.Header>
        <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
          <p>{translation.saveParametersWarning[language]}</p>
        </Modal.Description>
        <div style={{ margin: "10px 0px", textAlign: "center" }}>
          <Button color="grey" size="tiny" onClick={() => onNoClicked()}>
            {translation.no[language]}
          </Button>
          <Button
            disabled={checkDisabled()}
            color="green"
            size="tiny"
            type="submit"
            primary
            onClick={() => {
              onValidateClicked();
            }}
          >
            {translation.yes[language]}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Options;
