/**
 * The Storage class which implements CRUD operations for BIM storage
 * The BIM storage works like local storage but creates .db file in user machine
 * Note :- Revit faker developers data will get stored in localStorage only.
 */

import { AnyAction } from "redux";
import { StringLiteral } from "typescript";
import { MyConfig } from "../Helper";
import { storageKey } from "./key";

/**
 * Get the data on the basis of key from BIM storage
 * @param key The key in string
 * @returns
 */
export const getItem = async (key: storageKey) => {
  let result = null;

  if (window.revit) {
    if (window.revit.isArchicad) {
      const cng = await MyConfig();
      result = (await window.revit.getItem(key, cng.REACT_APP_ENV)) as string;
    } else {
      result = (await window.revit.getItem(key)) as string;
    }
  } else {
    result = localStorage.getItem(key);
  }

  if (result === null) {
    result = "[]";
  }
  return result ? JSON.parse(result) : result;
};

/**
 *
 * @param key The key in string for which data to be stored in BIM store
 * @param jsonStringData The data in json string format.
 */
export const setItem = async (key: storageKey, jsonStringData: any) => {
  try {
    if (window.revit) {
      if (window.revit.isArchicad) {
        const cng = await MyConfig();
        window.revit.setItem(key, jsonStringData, cng.REACT_APP_ENV);
      } else {
        window.revit.setItem(key, jsonStringData);
      }
    } else {
      localStorage.setItem(key, jsonStringData);
    }
  } catch (e) {}
};

// This export is done for easy to use,
// (For ex:- the functions from Storage will get called like storage.getItem(key))
// export const storage = new Storage();

export const setModule = async (
  pluginId: string,
  projectId: string,
  moduleName: string,
  modificationDate: string,
  uniqueId: string,
  jsonStringData: string
) => {
  try {
    await window.revit.setModule(
      pluginId,
      projectId,
      moduleName,
      modificationDate,
      uniqueId,
      jsonStringData
    );
  } catch (e) {}
};

export const deleteModule = async (
  pluginId: string,
  projectId: string,
  moduleName: StringLiteral
) => {
  try {
    await window.revit.deleteModule(pluginId, projectId, moduleName);
  } catch (e) {}
};

export const listModule = async (pluginId: string, projectId: string) => {
  const moduleList = await window.revit.listModules(pluginId, projectId);
  if (moduleList) {
    return JSON.parse(moduleList);
  } else {
    return "";
  }
};

export const listDetailModule = async (pluginId: string, id: string) => {
  try {
    const detail = await window.revit.listDetailModules(pluginId, id);
    return detail;
  } catch (e) {
    return null;
  }
};

export const selectionIsUsed = async (
  pluginId: string,
  projectId: string,
  moduleId: string
) => {
  const moduleList = await window.revit.selectionUsed(
    pluginId,
    projectId,
    moduleId
  );
  if (moduleList) {
    return moduleList === "true";
  } else {
    return "";
  }
};

/**
 *
 * @param pluginId Unique plugin Id
 * @param projectId Uniques project Id
 * @param moduleNewName New name that to be given to module
 * @param modificationDate Modification date
 * @param uniqueId Module unique Id
 */
export const renameModule = async (
  pluginId: string,
  projectId: string,
  moduleNewName: string,
  modificationDate: string,
  uniqueId: string
) => {
  try {
    await window.revit.renameModule(
      pluginId,
      projectId,
      moduleNewName,
      modificationDate,
      uniqueId
    );
  } catch (e) {}
};
