import { RevitMappedWall2 } from "../../../../../Layout/types";
import { api } from "../../../../../RevitJS/API";
import { setAlbaId } from "../../Actions/utils";

export const whoolHandler = async (revitMappedWall: RevitMappedWall2) => {
  const { whoolsGeometry, InnerContours, Id, LevelId, whool } =
    revitMappedWall as RevitMappedWall2;

  if (whool && whool.trim() !== "–") {
    //api.windowsHandler.showDevTools();

    for (let i = 0; i < whoolsGeometry.length; i++) {
      let currentwhoolGeometry = whoolsGeometry[i];
      let levelName = await api.queries.getLevelById(LevelId);
      let whoolStructure = whool.split("/");
      let whoolWidth = parseInt(whoolStructure[0]) === 40 ? "MW_40" : "MW_60";

      let rightContours = {
        OuterContour: currentwhoolGeometry.OuterContour,
        InnerContours: InnerContours,
      };

      let mySide = await api.familyEditor.createWallByContour(
        {
          ...rightContours,
          NormalVector: revitMappedWall.NormalVector,
        },
        currentwhoolGeometry.LocationLine,
        whoolWidth,
        levelName.Name,
        false
      );
      await setAlbaId(mySide, Id);
    }
    //
    //    let wallBasis = computeWallBasis(LocationLine, minPoint);
    //    let translation = {
    //      X: LocationLine.p1.X,
    //      Y: LocationLine.p1.Y,
    //      Z: minPoint.Z,
    //    };
    //    let movedContours = moveWallContours(
    //      OuterContour,
    //      InnerContours,
    //      translation,
    //      wallBasis
    //    );
    //
    //    let leftDirection = computeLeftDirection(LocationLine);
    //    let leftContours = {
    //      OuterContour: OuterContour,
    //      InnerContours: InnerContours,
    //    };
    //    let rightContours = {
    //      OuterContour: OuterContour,
    //      InnerContours: InnerContours,
    //    };
    //
    //    let movedLeftContours = moveWallContours(
    //      leftContours.OuterContour,
    //      leftContours.InnerContours,
    //      translation,
    //      wallBasis
    //    );
    //    let movedRightContours = moveWallContours(
    //      rightContours.OuterContour,
    //      rightContours.InnerContours,
    //      translation,
    //      wallBasis
    //    );
    //    movedLeftContours.OuterContour = cleanContour(
    //      movedLeftContours.OuterContour
    //    );
    //    movedRightContours.OuterContour = cleanContour(
    //      movedRightContours.OuterContour
    //    );
    //
    //    if (revitMappedWall.Neighbours[0]) {
    //      if (revitMappedWall.Angle0) {
    //        let minX = Math.min(...movedContours.OuterContour.map((p) => p.X));
    //
    //        if (revitMappedWall.Angle0 === 90) {
    //          let targetXLeft = revitMappedWall.Neighbours[0].Width / 2;
    //          let distLeft = targetXLeft - minX;
    //          movedLeftContours.OuterContour = pointRingX(
    //            movedLeftContours.OuterContour,
    //            minX,
    //            distLeft
    //          );
    //          let movedLeftOutterContourBack = moveContourBack(
    //            movedLeftContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          leftContours.OuterContour = movedLeftOutterContourBack;
    //          let targetXRight = -revitMappedWall.Neighbours[0].Width / 2;
    //          let distRight = targetXRight - minX;
    //          movedRightContours.OuterContour = pointRingX(
    //            movedRightContours.OuterContour,
    //            minX,
    //            distRight
    //          );
    //          let movedRightOutterContourBack = moveContourBack(
    //            movedRightContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          rightContours.OuterContour = movedRightOutterContourBack;
    //        }
    //        if (revitMappedWall.Angle0 === 270) {
    //          let targetXLeft = -revitMappedWall.Neighbours[0].Width / 2;
    //          let distLeft = targetXLeft - minX;
    //          movedLeftContours.OuterContour = pointRingX(
    //            movedLeftContours.OuterContour,
    //            minX,
    //            distLeft
    //          );
    //          let movedLeftOutterContourBack = moveContourBack(
    //            movedLeftContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          leftContours.OuterContour = movedLeftOutterContourBack;
    //          let targetXRight = revitMappedWall.Neighbours[0].Width / 2;
    //          let distRight = targetXRight - minX;
    //          movedRightContours.OuterContour = pointRingX(
    //            movedRightContours.OuterContour,
    //            minX,
    //            distRight
    //          );
    //          let movedRightOutterContourBack = moveContourBack(
    //            movedRightContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          rightContours.OuterContour = movedRightOutterContourBack;
    //        }
    //      }
    //    }
    //    if (revitMappedWall.Neighbours[1]) {
    //      if (revitMappedWall.Angle1) {
    //        let maxX = Math.max(...movedContours.OuterContour.map((p) => p.X));
    //        if (revitMappedWall.Angle1 === 90) {
    //          let targetXLeft =
    //            moveContour(
    //              [revitMappedWall.LocationLine.p2],
    //              translation,
    //              wallBasis
    //            )[0].X +
    //            Width / 2 -
    //            AlbaStructure[0].thickness;
    //          let distLeft = targetXLeft - maxX;
    //          movedLeftContours.OuterContour = pointRingX(
    //            movedLeftContours.OuterContour,
    //            maxX,
    //            distLeft
    //          );
    //          let movedLeftOutterContourBack = moveContourBack(
    //            movedLeftContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          leftContours.OuterContour = movedLeftOutterContourBack;
    //
    //          let targetXRight =
    //            moveContour(
    //              [revitMappedWall.LocationLine.p2],
    //              translation,
    //              wallBasis
    //            )[0].X +
    //            Width / 2 -
    //            AlbaStructure[0].thickness;
    //          let distRight = targetXRight - maxX;
    //          movedRightContours.OuterContour = pointRingX(
    //            movedRightContours.OuterContour,
    //            maxX,
    //            distRight
    //          );
    //          let movedRightOutterContourBack = moveContourBack(
    //            movedRightContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          rightContours.OuterContour = movedRightOutterContourBack;
    //        } else if (revitMappedWall.Angle1 === 270) {
    //          let targetXLeft =
    //            moveContour(
    //              [revitMappedWall.LocationLine.p2],
    //              translation,
    //              wallBasis
    //            )[0].X +
    //            Width / 2 -
    //            AlbaStructure[2].thickness;
    //          let distLeft = targetXLeft - maxX;
    //          movedLeftContours.OuterContour = pointRingX(
    //            movedLeftContours.OuterContour,
    //            maxX,
    //            distLeft
    //          );
    //          let movedLeftOutterContourBack = moveContourBack(
    //            movedLeftContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          leftContours.OuterContour = movedLeftOutterContourBack;
    //          let targetXRight =
    //            moveContour(
    //              [revitMappedWall.LocationLine.p2],
    //              translation,
    //              wallBasis
    //            )[0].X +
    //            Width / 2 -
    //            AlbaStructure[2].thickness;
    //          let distRight = targetXRight - maxX;
    //          movedRightContours.OuterContour = pointRingX(
    //            movedRightContours.OuterContour,
    //            maxX,
    //            distRight
    //          );
    //          let movedRightOutterContourBack = moveContourBack(
    //            movedRightContours.OuterContour,
    //            translation,
    //            wallBasis
    //          );
    //          rightContours.OuterContour = movedRightOutterContourBack;
    //        }
    //      }
    //    }
    // Bypassing previous Calculation
    //
    //    rightContours.OuterContour = whoolOuterContour;
    //    if (whool && whool !== "–") {
    //      let levelName = await api.queries.getLevelById(LevelId);
    //
    //      let whoolStructure = whool.split("/");
    //      let whoolWidth = parseInt(whoolStructure[0]) === 40 ? "MW_40" : "MW_60";
    //
    //      if (whoolStructure.length === 2) {
    //        let leftLocationLine = createLeftLocationLine(
    //          LocationLine,
    //          Width / 2.0,
    //          parseInt(whoolStructure[0]),
    //          leftDirection
    //        );
    //
    //        let leftSide = await api.familyEditor.createWallByContour(
    //          {
    //            ...rightContours,
    //            NormalVector: revitMappedWall.NormalVector,
    //          },
    //          leftLocationLine,
    //          whoolWidth,
    //          levelName.Name,
    //          false
    //        );
    //        await setAlbaId(leftSide, Id);
    //
    //        let rightLocationLine = createLeftLocationLine(
    //          LocationLine,
    //          Width / 2.0,
    //          parseInt(whoolStructure[0]),
    //          { X: -leftDirection.X, Y: -leftDirection.Y, Z: -leftDirection.Z }
    //        );
    //
    //        let rightSide = await api.familyEditor.createWallByContour(
    //          {
    //            ...rightContours,
    //            NormalVector: revitMappedWall.NormalVector,
    //          },
    //          rightLocationLine,
    //          whoolWidth,
    //          levelName.Name,
    //          false
    //        );
    //        await setAlbaId(rightSide, Id);
    //      } else {
    //        let leftSide = await api.familyEditor.createWallByContour(
    //          {
    //            ...rightContours,
    //            NormalVector: revitMappedWall.NormalVector,
    //          },
    //          whoolLocationLine,
    //          whoolWidth,
    //          levelName.Name,
    //          false
    //        );
    //        await setAlbaId(leftSide, Id);
    //      }

    //   let leftLocationLine = createLeftLocationLine(
    //     LocationLine,
    //     Width,
    //     40,
    //     leftDirection
    //   );

    //   let levelName = await api.queries.getLevelById(LevelId);
    //   let leftSide = await api.familyEditor.createWallByContour(
    //     { ...leftContours, NormalVector: revitMappedWall.NormalVector },
    //     leftLocationLine,
    //     "AH60",
    //     levelName.Name,
    //     false
    //   );
    //   await setAlbaId(leftSide, Id);

    //   let rightLocationLine = createRightLocationLine(
    //     LocationLine,
    //     Width,
    //     AlbaStructure[2].thickness,
    //     leftDirection
    //   );
    //   let rightSide = await api.familyEditor.createWallByContour(
    //     { ...rightContours, NormalVector: revitMappedWall.NormalVector },
    //     rightLocationLine,
    //     AlbaStructure[2].name,
    //     levelName.Name,
    //     false
    //   );

    //   await setAlbaId(rightSide, Id);
    //}
  }
};
