import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import MetresRoot from "./Components/root";

export const functionalityId = "5377529d-ceae-4d27-9940-e55fa8158492";

const NomenclatureTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    500,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Métrés",
    module: "GYPROC",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={"Metres"}
    >
      <MetresRoot />
    </ParentRoot>
  );
};

export const GyprocMetresRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="Metres"
    >
      <MetresRoot />
    </ParentRoot>
  );
};

export const Metres: Functionality = {
  Name: {
    French: "Des horaires",
    English: "Schedules",
    German: "Zeitpläne",
    Italian: "Orari",
    Indonesian: "Jadwal",
  },
  Trigger: NomenclatureTrigger,
  ShortDesc: {
    French: "Cloisons / Doublages / Plafonds",
    English: "Partitions / Linings / Ceilings",
    German: "Cloisons / Doublages / Plafonds",
    Italian: "Cloisons / Doublages / Plafonds",
    Indonesian: "Cloisons / Doublages / Plafonds",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Schedules.png",
  RootComponent,
  ParentId: "0",
};
