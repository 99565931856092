import {
  SET_DEFAULT_CONFIGURATION, LOAD_PROPERTIES, PropertiesActionTypes, LOAD_PROPERTIES_SUCCESS, LOAD_PROPERTIES_ERROR, SET_PROPERTIES_DETAILS, SELECT_PROPERTIES,
} from "./types";

export const loadPropertiesRequest = (): PropertiesActionTypes => ({
  type: LOAD_PROPERTIES,
});

export const loadPropertiesSuccess = (properties: any[], data: any, selectedProperties: any,currentSelectedProperties: any, currentPropertiesDetail: any[]): PropertiesActionTypes => ({
  type: LOAD_PROPERTIES_SUCCESS,
  properties: properties,
  liningWallsProperties: data.liningWallsProperties,
  partitionsProperties: data.partitionsProperties,
  ceilingsProperties: data.ceilingsProperties,
  gainesTechniquesProperties: data.gainesTechniquesProperties,

  selectedProperties: selectedProperties,
  currentPropertiesDetail: currentPropertiesDetail,
  currentSelectedProperties: currentSelectedProperties

});

export const loadPropertiesError = (): PropertiesActionTypes => ({
  type: LOAD_PROPERTIES_ERROR,
});

export const restorePropertiesDetails = (propertiesDetails: any[], selectedProperties: any[], systemType: number): PropertiesActionTypes => ({
  type: SET_PROPERTIES_DETAILS,
  currentPropertiesDetails: propertiesDetails,
  selectedProperties: selectedProperties,
  systemType: systemType
})

export function defaultConfiguration(
  defaultConfiguration: boolean
): PropertiesActionTypes {
  return {
    type: SET_DEFAULT_CONFIGURATION,
    defaultConfiguration: defaultConfiguration,
  };
}

export function selectProperty(
  currentSelectedProperties: any[],
  currentPropertiesDetail: any[],
  selectedProperties: any,
  data: any
): PropertiesActionTypes {
  return {
    type: SELECT_PROPERTIES,
    currentSelectedProperties: currentSelectedProperties,
    currentPropertiesDetail: currentPropertiesDetail,
    selectedProperties: selectedProperties,
    data: data
  };
}
