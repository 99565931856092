import React, { Component, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../../Selection/Reducers";
import SavedSelection from "./SavedSelection";
import Moment from "moment";
interface State {}

const mapState = (state: SelectionStore, ownProps: { setRoute: any }) => ({
  moduleData: state.moduleData,
  setRoute: ownProps.setRoute,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  moduleData: any;
  setRoute: any;
  editSelectionName: any;
  updateSelectionname: any;
  editId: any;
  editName: any;
  setEditName: any;
  errorMessage: any;
  revitViewName: string;
}

export class SavedSelections extends Component<Props, State> {
  state = {};

  render() {
    const {
      moduleData,
      setRoute,
      editSelectionName,
      updateSelectionname,
      editId,
      editName,
      setEditName,
      errorMessage,
      revitViewName,
    } = this.props;

    let sortedCalpinage = Object.values(moduleData.calpinageData.data);
    let convertDate = (d: any) => {
      let sp = d.split("/");
      let str1: any = sp[1];
      let str2: any = sp[0];
      if (str1.length == 1) {
        str1 = 0 + sp[1];
      }
      if (str2.length == 1) {
        str2 = 0 + sp[0];
      }
      let ret = [str1, str2, sp[2]].join("/");
      return ret;
    };
    sortedCalpinage.sort((a: any, b: any) => {
      return +new Date(convertDate(b.Date)) - +new Date(convertDate(a.Date));
    });

    return (
      <Fragment>
        {sortedCalpinage.map((selection, key) => {
          return (
            <SavedSelection
              selection={selection}
              setRoute={setRoute}
              editSelectionName={editSelectionName}
              updateSelectionname={updateSelectionname}
              editId={editId}
              editName={editName}
              setEditName={setEditName}
              errorMessage={errorMessage}
              key={key}
            />
          );
        })}
      </Fragment>
    );
  }
}

export default connect(mapState, mapDispatch)(SavedSelections);
