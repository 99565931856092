export const SET_LANGUAGE = "SET_LANGUAGE";
export const RESET_LANGUAGE = "RESET_LANGUAGE";
export const INIT_LANGUAGE = "INIT_LANGUAGE";

export const SET_RESET_CRITERIA = "RESET_CRITERIA";
export const SET_SELECTED_SOURCE = "SET_SELECTED_SOURCE";
export const SET_SELECTED_MAPPING = "SET_SELECTED_MAPPING";
export const SET_SELECTED_BUSINESS = "SET_SELECTED_BUSINESS";
export const SET_SELECTED_BUSINESS_TECH_NAME = "SET_SELECTED_BUSINESS_TECH_NAME";
export const SET_SELECTED_BUSINESS_CONTAINERID = "SET_SELECTED_BUSINESS_CONTAINERID";
export const SET_SELECTED_REGION = "SET_SELECTED_REGION";
export const SET_SELECTED_REGIONName ="SET_SELECTED_REGIONName";
export const SET_SELECTED_ENVIRONMENT = "SET_SELECTED_ENVIRONMENT";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_SELECTED_FORMAT = "SET_SELECTED_FORMAT";
export const SET_SELECTED_VERSION = "SET_SELECTED_VERSION";
export const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
export const SET_SELECTED_GENERIC = "SET_SELECTED_GENERIC";
export const SET_SELECTED_ENTITY = "SET_SELECTED_ENTITY";
export const SET_IS_CRITERIA_VALID = "SET_IS_CRITERIA_VALID";


export const SET_RESET_PRODUCTS_LISTS = "SET_RESET_PRODUCTS_LISTS";
export const SET_PIM_PRODUCTS_LISTS = "SET_PIM_PRODUCTS_LISTS";
export const SET_PRODUCTS_LISTS = "SET_PRODUCTS_LISTS";
export const SET_PRODUCTS_FILTERS = "SET_PRODUCTS_FILTERS";


export const SET_INFO_STATUS = "SET_INFO_STATUS";