import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import { Button, Segment, Card, Message } from "semantic-ui-react";
import { FunctionalityHeader } from "./FunctionalityHeader";
import PlacoCard from "./PlacoCard";
import { Routes } from "./root";
import { FilterType } from "../../../../RevitJS/Types/StoreTypes";
import {
  resetFilters,
  setFilterType,
  initProductSelector,
  search,
  scrolledBottom,
  resetBuffer,
  saveBufferSelector,
  saveOtherPlacoBufferSelector,
  applyFilters,
} from "../Actions";
import SystemDetails from "./SystemDetails";
import _ from "lodash";
import ConfigurationView from "./ConfigurationView";
import { selectorTp } from "../Actions/types";
import { SolutionFilterType, ProductDetailData } from "../Types";
import "react-customize-token-input/dist/react-customize-token-input.css";
import { bimStorage, storageKey } from "../../../../BIMStore";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchComponent from "./SearchComponent";
import Filters from "../../../../Components/AdvanceSelector/Filter/Components";
import { api } from "../../../../RevitJS/API";
import { AdvanceProducts } from "../../../../Components/AdvanceSelector/List";

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { setRoute: any }
) => {
  const { filterType, filters } = state;
  const currentFilters = ((
    filterType: FilterType,
    filters: SolutionFilterType
  ) => {
    switch (filterType) {
      case "ceiling":
        return filters.ceilings;
      case "liningWall":
        return filters.liningWalls;
      case "protectionfeu":
        return filters.protectionFeu;
      case "gainestechniques":
        return filters.gainesTech;
      case "conduitgaines":
        return filters.conduitGaines;
      default:
        return filters.partitionWalls;
    }
  })(filterType, filters);
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    selectionBuffer: state.selectionBuffer,
    setRoute: ownProps.setRoute,
    displayedElements: state.displayedElements,
    currentFilters,
    filteredElements: state.filteredElements,
    filterType: state.filterType,
    selectorType: state.selectorType,
    searchedWord: state.searchedWord,
    config: state.config,
  };
};

const mapDispatchToProps = {
  resetFilters,
  setFilterType,
  initProductSelector: initProductSelector,
  search,
  scrolledBottom,
  resetBuffer,
  saveBufferSelector: saveBufferSelector,
  saveOtherPlacoBufferSelector,
  applyFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  displayedElements: ProductDetailData[];
  wording: {
    validate: { [key: string]: string };
    back: { [key: string]: string };
  };
  language: string;
  selectionBuffer: ProductDetailData | null;
  Icon: string;
  setRoute: any;
  currentFilters: any[];
  filteredElements: ProductDetailData[];
  filterType: FilterType;
  selectorType: selectorTp;
  searchedWord: string;
  config: any;
};

interface State {
  LayoutPossibleValue: any;
  activeProductname: string;
}

export class ProductsPageBody extends Component<Props, State> {
  state = {
    LayoutPossibleValue: null,
    activeProductname: "",
  };

  setLayoutPossible = (LayoutPossibleValue: any) => {
    this.setState({ LayoutPossibleValue });
    if (this.ref.current !== null) this.ref.current.scrollTop = 0;
  };

  private ref: React.RefObject<HTMLDivElement>;

  public static defaultProps = {
    wording: {
      back: {
        French: "Retour",
        English: "Back",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
    },
  };

  constructor(props: Props) {
    super(props);

    this.ref = React.createRef();
  }

  scrollHandler = (e: any) => {
    // if (this.ref.current !== null)
    //   if (
    //     this.ref.current.offsetHeight + this.ref.current.scrollTop >=
    //     this.ref.current.scrollHeight
    //   ) {
    this.props.scrolledBottom();
    // }
  };

  // inputHandler = (e: any, data: any) => {
  //     this.props.search(data.value);
  //     if (this.ref.current !== null) this.ref.current.scrollTop = 0;
  // };

  inputHandler = (data: any) => {
    if (data) {
      if (data && data.length > 0) {
        this.props.search(data.map((item: any) => item.value).join(";"));
        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      } else {
        this.props.search("");
        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      }
    }
  };

  onAddKeyWord = (data: any) => {
    if (data) {
      if (data && data !== "") {
        var keyword =
          this.props.searchedWord !== ""
            ? this.props.searchedWord + ";" + data
            : data;
        this.props.search(keyword);

        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      } else {
        this.props.search("");
        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      }
      this.sendEvent(keyword);
    }
  };

  sendEvent = async (keyword: string) => {
    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: keyword,
          values: [],
        },
      ],
      eventAction: "Get",
      eventCategory: "User Query",
      eventLabel: "Keyword search",
      module: "PLACOBIM",
    });
  };

  onDeleteKeyWord = (index: any) => {
    var keywords = this.props.searchedWord.split(";");
    if (index > -1) {
      keywords.splice(index, 1);
      if (keywords && keywords.length > 0) {
        this.props.search(keywords.join(";"));
      } else {
        this.props.search("");
      }
    }
    if (this.ref.current !== null) this.ref.current.scrollTop = 0;
  };

  componentDidMount = async () => {
    const activeProductname: string = await bimStorage.getItem(
      storageKey.ACTIVE_PRODUCT
    );
    this.setState({
      activeProductname: activeProductname,
    });
  };

  handlerLayoutPossible = (el: any) => {
    try {
      return (
        el.attributes.find(
          (x: any) => x.technicalName === "GFR-Layout possible"
        ).values[0].value === "True"
      );
    } catch (error) {
      return false;
    }
  };

  preprocessSearchValue = (inputValues: any) => {
    const values: string[] = [];

    _.forEach(inputValues, (searchInputValue: any, index: any) => {
      if (searchInputValue !== "") {
        values.push(searchInputValue);
      }
    });

    return values;
  };

  onFilterChange = (e: any, d: any, pimAttribute: string) => {
    if (d.value !== undefined) {
      this.props.applyFilters(pimAttribute, d.value);
    } else {
      this.props.applyFilters(pimAttribute, d);
    }
  };
  render() {
    const {
      wording: { validate },
      language,
      filteredElements,
      filterType,
      selectorType,
      setFilterType,
      resetFilters,
      initProductSelector,
      setRoute,
    } = this.props;
    const cards = () => {
      if (this.props.displayedElements.length > 0) {
        return this.props.displayedElements.map((el, index) => {
          let productName:any = el?.attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;
          return (
            <PlacoCard
              selectorType={selectorType}
              image={el.thumbnailUrl}
              key={index}
              oid={el.oid}
              productName={productName}//{el.translation}
              system={el}
              selectionBuffer={this.props.selectionBuffer}
              layoutPossible={this.handlerLayoutPossible(el)}
              setRoute={setRoute}
            />
          );
        });
      }

      return (
        <Message style={{ height: "50px", marginTop: "10px" }}>
          Aucun système trouvé selon les filtres sélectionnés
        </Message>
      );
    };

    const { currentFilters } = this.props;
    let filterTypeOptions = [
      { key: "partitionWall", text: "Cloisons", value: "partitionWall" },
      { key: "liningWall", text: "Doublages", value: "liningWall" },
    ];
    let filterTypeDefaultValue = "partitionWall";
    switch (filterType) {
      case "partitionWall":
      case "liningWall":
        filterTypeOptions = [
          {
            key: "partitionWall",
            text: "Cloisons",
            value: "partitionWall",
          },
          {
            key: "liningWall",
            text: "Doublages",
            value: "liningWall",
          },
        ];
        filterTypeDefaultValue = filterType;
        break;
      case "protectionfeu":
      case "gainestechniques":
      case "conduitgaines":
        filterTypeOptions = [
          {
            key: "protectionfeu",
            text: "Protection Feu",
            value: "protectionfeu",
          },
          {
            key: "gainestechniques",
            text: "Gaines Techniques",
            value: "gainestechniques",
          },
          {
            key: "conduitgaines",
            text: "Conduit Gaines",
            value: "conduitgaines",
          },
        ];
        filterTypeDefaultValue = filterType;
        break;
      case "ceiling":
        filterTypeOptions = [
          { key: "ceiling", text: "Plafonds", value: "ceiling" },
        ];
        filterTypeDefaultValue = "ceiling";
        break;
      default:
        filterTypeOptions = [
          {
            key: "partitionWall",
            text: "Cloisons",
            value: "partitionWall",
          },
          {
            key: "liningWall",
            text: "Doublages",
            value: "liningWall",
          },
        ];
        filterTypeDefaultValue = "partitionWall";
    }

    return (
      <div id="divId" style={{ overflow: "hidden" }}>
        <div style={{ margin: "10", overflow: "auto", maxHeight: "95vh" }}>
          <FunctionalityHeader
            Icon={this.props.Icon}
            name="SYSTÈMES PLACO®"
            subheader={this.state.activeProductname}
          />
          <SystemDetails />
          <ConfigurationView />
          {/* <Grid stackable style={{}}>
          <Grid.Row>
            <Grid.Column style={{ padding: "0px 0px" }}> */}
          <Segment
            style={{
              padding: "0em 0em 1em",
              display: "flex",
              flexDirection: "column",
              minHeight: "75vh",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "38%",
                  minWidth: "250px",
                  backgroundColor: "none",
                  height: "76vh",
                  minHeight: 50,
                  marginLeft: "20px",
                  overflow: "auto",
                }}
              >
                <Filters
                  filtersData={currentFilters}
                  isFavoriteDisplayed={false}
                  onChange={this.onFilterChange}
                  resetFilters={resetFilters}
                ></Filters>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100",
                    backgroundColor: "none",
                    height: "30px",
                    minHeight: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ backgroundColor: "none", display: "flex" }}>
                    <div>
                      <div className="SelectType" style={{ marginTop: "10px" }}>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            getContentAnchorEl: null,
                          }}
                          classes={{ root: "SelectType" }}
                          value={filterType}
                          onChange={(event) => {
                            setFilterType(event.target.value as FilterType);
                            initProductSelector();
                            this.setLayoutPossible(null);
                            resetFilters();
                          }}
                          displayEmpty
                          disableUnderline
                          inputProps={{ "aria-label": "Without label" }}
                          variant="standard"
                        >
                          {filterTypeOptions.map((filterTypeOption) => (
                            <MenuItem
                              className="SelectType"
                              key={filterTypeOption.value}
                              value={filterTypeOption.value}
                            >
                              {filterTypeOption.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <SearchComponent
                      searchKeyWords={this.preprocessSearchValue(
                        this.props.searchedWord.split(";")
                      )}
                      onAddKeyWord={this.onAddKeyWord}
                      onDeleteKeyWord={this.onDeleteKeyWord}
                    />
                    <div style={{ marginLeft: 7, paddingTop: 10 }}>
                      {filteredElements.length} systèmes trouvés
                    </div>
                  </div>
                </div>
                <div
                  onScroll={this.scrollHandler}
                  ref={this.ref}
                  style={{
                    width: "100",
                    backgroundColor: "none",
                    height: "67vh",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    overflow: "auto",
                    marginTop: 10,
                    alignItems: "flex-start",
                  }}
                >
                  <Card.Group style={{ margin: "0px 0px 5px 15px" }}>
                    {cards()}
                  </Card.Group>
                </div>
              </div>
            </div>
          </Segment>
          <Segment
            fluid
            basic
            style={{
              padding: "10px 20px",
              position: "fixed",
              maxHeight: "50px",
              width: "100%",
              margin: "0 auto",
              bottom: "0px",
            }}
          >
            <Button
              floated="left"
              color="orange"
              onClick={(e) => {
                this.props.setRoute(Routes.MAPPING);
                resetFilters();
                this.props.resetBuffer();
              }}
            >
              {this.props.wording.back[this.props.language]}
            </Button>
            <Button
              floated="right"
              primary
              disabled={
                this.props.selectionBuffer === null
                // || this.handlerLayoutPossible(this.props.selectionBuffer)
                // Commented by Yogesh : Check for Layout possible Not required as
                // if solution was non-layoutable then user will not able to select in placocard then buffer selection will null.
              }
              onClick={(e) => {
                if (this.props.selectionBuffer !== null) {
                  if (
                    filterType === "partitionWall" ||
                    filterType === "liningWall" ||
                    filterType === "ceiling"
                  ) {
                    this.props.saveBufferSelector();
                    resetFilters();
                    this.props.setRoute(Routes.MAPPING);
                  } else {
                    this.props.saveOtherPlacoBufferSelector();
                    resetFilters();
                    this.props.setRoute(Routes.MAPPING);
                  }
                }
              }}
            >
              {validate[language]}
            </Button>
          </Segment>
        </div>
      </div>
    );
  }
}

export default connector(ProductsPageBody);
