import _ from "lodash";
import {
  PimAttribute,
  PlacoProducts,
  ProductDetailData,
  SolutionFilterType,
} from "../Types";

export const sortPlacoDataByGFRWorksName = async (
  data: ProductDetailData[]
): Promise<PlacoProducts> => {
  let partitionWalls: ProductDetailData[] = [],
    liningWalls: ProductDetailData[] = [],
    ceilings: ProductDetailData[] = [],
    protectionFeu: ProductDetailData[] = [],
    gainesTech: ProductDetailData[] = [],
    conduitGaines: ProductDetailData[] = [];

  data.forEach((product) => {
    const attributes = product.attributes;

    const gfrWorksNameObject = _.find(attributes, {
      technicalName: "GFR-Works name",
    });

    if (gfrWorksNameObject) {
      const valueObject: any = _.get(gfrWorksNameObject, "values");

      if (valueObject[0].value === "Cloisons") {
        product && partitionWalls.push(product);
      } else if (valueObject[0].value === "Isolation des murs") {
        product && liningWalls.push(product);
      } else if (
        valueObject[0].value === "Plafonds décoratifs" ||
        valueObject[0].value === "Plafonds"
      ) {
        product && ceilings.push(product);
      } else if (valueObject[0].value === "Protection feu") {
        product && protectionFeu.push(product);
      } else if (valueObject[0].value === "Conduit gaines") {
        product && conduitGaines.push(product);
      } else if (valueObject[0].value === "Gaines techniques") {
        product && gainesTech.push(product);
      }
    }
  });

  return {
    partitionWalls,
    liningWalls,
    ceilings,
    protectionFeu,
    gainesTech,
    conduitGaines,
    available: true,
  };
};

export const getLayoutAttributesAid = (attributes: PimAttribute[]) => {
  return {
    layers: attributes.find((i) => i.technicalName === "GFR-BIM layers")?.aid,
    rails: attributes.find((i) => i.technicalName === "GFR-Product 3")?.aid,
    frames: attributes.find((i) => i.technicalName === "GFR-Product 2")?.aid,
    framesSpace: attributes.find(
      (i) => i.technicalName === "GFR-Distance between frames (in m)"
    )?.aid,
    doubleFraming: attributes.find(
      (i) => i.technicalName === "GFR-Profiles (1 or 2)"
    )?.aid,
    layerNumber: attributes.find((i) => i.technicalName === "GFR-Layer number")
      ?.aid,
    layerContent: attributes.find(
      (i) => i.technicalName === "GFR-Layer content"
    )?.aid,
    layerName: attributes.find((i) => i.technicalName === "GFR-Layer name")
      ?.aid,
    layerThickness: attributes.find(
      (i) => i.technicalName === "GFR-Layer thickness mm"
    )?.aid,
    plateWidth: attributes.find((i) => i.technicalName === "A-Solution Width")
      ?.aid,
    systemName: attributes.find(
      (i) => i.technicalName === "A-Solution product name"
    )?.aid,
    technicalName: attributes.find(
      (i) => i.technicalName === "GFR-Profiles (1 or 2)"
    )?.aid,
  };
};
