import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import { EditSelectionButton } from "../../CommonModules/Selection/EditSelectionBtn";
import { List } from "../../CommonModules/Selection/List";
import { Language } from "../../Selection/Actions/types";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { Selection } from "../../Selection/Actions/types";
import { Routes } from "./root";
import { setDossierSelection } from "../../Selection/Actions";
import { saveDossierTechniques } from "../Helpers";

import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import concat from "lodash/concat";
import filter from "lodash/filter";
import remove from "lodash/remove";

interface Props {
  setRoute: any;
}

export const DocSelection = (props: Props) => {
  /**
   * The `useSelector()` hook is working like mapStateToProps from class component
   * Means that you cas access redux state using useSelector Hook
   * For ex. state.language
   */
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );
  /**
   * The `useDispatch() Hook is works like mapDispatch from class component
   * Means that you can dispatch any action using useDispatch()
   */
  const dispatch = useDispatch();

  const applicationLanguage: Language = reduxState.language;
  const [confirmModal, setOpen] = useState(false);
  const [selectionID, setSelectionID] = useState<string[]>([]);
  const [selections, setSelections] = useState<{
    [key: string]: Selection<PlacoOptions>;
  }>({});
  const [revitViewName, setRevitViewName] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    async function fetchRevitDocumentType() {
      //const documentName = await api.queries.getActiveDocumentName();
      setRevitViewName(reduxState.projectData.ProjectId);
    }

    if (revitViewName === "") {
      fetchRevitDocumentType();
    }
    async function fetchPlacoSelections() {
      let savedSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );

      if (savedSelections) {
        let parsedSelections = savedSelections as {
          [key: string]: Selection<PlacoOptions>;
        };

        if (!isEqual(selections, parsedSelections)) {
          setSelections(parsedSelections);
        }
      }
    }

    fetchPlacoSelections();
    setSelectionID(
      reduxState.moduleData.dossierData.selections.list.map((id: any) => id)
    );
  }, []);

  useEffect(() => {
    if (selectionID.length > 0) {
      if (selections[selectionID[0]]) {
        setDisabled(false);
      }
    }
  }, [selectionID, selections]);

  const selectRow = (id: string) => {
    if (reduxState.moduleData.moduleParZone) {
      if (includes(selectionID, id)) {
        let filtSelectionIds = remove(selectionID, (n) => {
          return n !== id;
        });
        setSelectionID(filtSelectionIds);
      } else {
        let filtSelectionIds = concat(selectionID, id);
        setSelectionID(filtSelectionIds);
      }
    } else {
      setSelectionID([id]);
    }
    reduxState.moduleData.dossierData.bufferTime = Date.now();
  };

  const handleSubmit = (routeTo: string) => {
    let selectedSelectionObject = filter(
      selections,
      (sel: Selection<PlacoOptions>, key: string) => {
        return includes(selectionID, sel.Id);
      }
    );

    // if the current selection is same as old selection just redirect without changing anything
    if (
      isEqual(selectionID, reduxState.moduleData.dossierData.selections.list)
    ) {
      props.setRoute(Routes.TECHNICAL_LANDING);
      return;
    } else {
      let date = new Date();
      reduxState.moduleData.dossierData.documentDate = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
    }

    if (selectedSelectionObject.length > 0) {
      let dossierDataDuplicate = reduxState.moduleData.dossierData;

      dossierDataDuplicate.selections = {
        status: true,
        list: selectedSelectionObject.map((a) => a.Id),
      };
      dossierDataDuplicate.data = {
        ...dossierDataDuplicate.data,
        list: [],
        status: false,
      };
      Promise.resolve(dispatch(setDossierSelection(dossierDataDuplicate))).then(
        () => {
          if (routeTo === "routeToHome") {
            props.setRoute(Routes.TECHNICAL_LANDING);
          } else {
            saveDossierTechniques(
              reduxState.moduleData.dossierData,
              reduxState.config
            );
            toggleConfirmModal();
            props.setRoute(Routes.SELECTION_HOME);
          }
        }
      );
    } else {
      props.setRoute(Routes.TECHNICAL_LANDING);
    }

    if (routeTo === "routeToHome") {
      props.setRoute(Routes.TECHNICAL_LANDING);
    } else if (routeTo === "routeToSelection") {
      //reduxState.moduleData.dossierData.bufferTime = 0;
      saveDossierTechniques(
        reduxState.moduleData.dossierData,
        reduxState.config
      );
      toggleConfirmModal();
      props.setRoute(Routes.SELECTION_HOME);
    }
  };

  // toggle modal when clicking edit selection
  const toggleConfirmModal = () => {
    setOpen(!confirmModal);
  };

  const checkConfirmModal = () => {
    if (
      reduxState.moduleData.dossierData.bufferTime > 0 &&
      reduxState.moduleData.dossierData.bufferTime >
        reduxState.moduleData.dossierData.time
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (isLoading) {
    return (
      <Dimmer active page>
        <Loader />
      </Dimmer>
    );
  }

  const convertDate = (d: any) => {
    let sp = d.split("/");
    let ret = [sp[1], sp[0], sp[2]].join("/");
    return ret;
  };

  let sortedDocSelection = filter(selections, {
    RevitView: revitViewName,
  });

  sortedDocSelection.sort((a, b) => {
    return +new Date(convertDate(b.Date)) - +new Date(convertDate(a.Date));
  });

  return (
    <div style={{ height: "100%" }}>
      <FunctionalityHeader
        Icon={reduxState.functionalityIcon}
        name={wording.name[applicationLanguage]}
      />
      <List
        selections={sortedDocSelection}
        selectRow={selectRow}
        zone={false}
        applicationLanguage={applicationLanguage}
        moduleData={
          selectionID.length > 0
            ? selectionID
            : reduxState.moduleData.dossierData.selections.list
        }
      />

      <EditSelectionButton
        setRoute={props.setRoute}
        routes={Routes}
        applicationLanguage={applicationLanguage}
        popUpVisible={() => checkConfirmModal()}
        toggleConfirmModal={() => toggleConfirmModal()}
        onYesAction={() => {
          setIsLoading(true);
          saveDossierTechniques(
            reduxState.moduleData.dossierData,
            reduxState.config
          );
          toggleConfirmModal();
          //handleSubmit("routeToSelection")
          setTimeout(function () {
            props.setRoute(Routes.SELECTION_HOME);
            setIsLoading(false);
          }, 500);
        }}
        onNoAction={() => {
          toggleConfirmModal();
          setTimeout(function () {
            props.setRoute(Routes.SELECTION_HOME);
          }, 500);
        }}
        confirmModal={confirmModal}
        description="Voulez-vous enregistrer la configuration du dossier technique en cours de modification avant de quitter?"
      />

      <Grid columns={1} stackable style={{ ...style.processButtonSegment }}>
        <Grid.Column style={{ padingBottom: "0px", textAlign: "center" }}>
          <Button
            color="orange"
            onClick={() => props.setRoute(Routes.TECHNICAL_LANDING)}
          >
            {wording.back[applicationLanguage]}
          </Button>

          <Button
            type="submit"
            disabled={disabled}
            primary
            onClick={() => handleSubmit("routeToHome")}
          >
            {wording.validate[applicationLanguage]}
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const wording: WordingType = {
  name: {
    French: "Dossier technique",
    English: "Document technique",
  },
  editSelection: {
    French: "Modifier mes sélections",
    English: "Modify my selections",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
};

type WordingType = {
  name: LanguageType;
  editSelection: LanguageType;
  back: LanguageType;
  validate: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
  processButtonSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};
