import { WALLSYSTEM_ID, FLOORSYSTEM_ID } from '../../assets/constants';
import {
    LOAD_SYSTEMS,
    LOAD_SYSTEMS_SUCCESS,
    LOAD_SYSTEMS_ERROR,
    LOAD_SYSTEMS_DETAILS,
    LOAD_SYSTEMS_DETAILS_SUCCESS,
    LOAD_SYSTEMS_DETAILS_MORE,
    LOAD_SYSTEMS_DETAILS_ERROR, SystemsState, SystemsActionTypes, DISPLAY_FAVORITE, FILTER_SYSTEMS, RESTORE_SYSTEMS_DETAILS, DISPLAY_SYSTEMS, LOAD_EXISTING_SYSTEMS, ADD_SEARCH_KEY_WORD, DELETE_SEARCH_KEY_WORD, RESET_SEARCH_KEY_WORD
} from './types';

export const initialState: SystemsState = {
    loadingSystems: true,
    loadingSystemsDetails: true,
    systems: [],
    systemsDetails: [],
    currentSystemDetail: [],
    data: {
        wallsSystemDetails: [],
        floorSystemDetails: [],
        wallsSystemMetaData: [],
        floorSystemMetaData: [],

        // liningWallsDetails: [],
        // partitionsDetails: [],
        // ceilingsDetails: [],
        // liningWallsMetaData: [],
        // partitionsMetaData: [],
        // ceilingsMetaData: [],
    },
    systemsMetaData: [],
    systemTypeID: WALLSYSTEM_ID,
    displayedSystemsCount: 0,
    favoriteDisplayed: false,
    existingSystems: [],
    searchKeyWords: []
}

export default function systemsReducer(state = initialState, action: SystemsActionTypes) {
    switch (action.type) {
        case LOAD_SYSTEMS:
            return { ...state, loadingSystems: true };
        case LOAD_SYSTEMS_SUCCESS:
            return { ...state, loadingSystems: false, systems: action.systems };
        case LOAD_SYSTEMS_ERROR:
            return { ...state, loadingSystems: false };
        case LOAD_SYSTEMS_DETAILS:
            return { ...state, loadingSystemsDetails: true, currentSystemDetail: [], systemsDetails: [], displayedSystemsCount: 0 };
        case LOAD_SYSTEMS_DETAILS_SUCCESS:
            return {
                ...state,
                loadingSystemsDetails: false,
                data: {
                    ...state.data,
                    wallsSystemDetails: action.currentSystemsDetailsWalls,
                    floorSystemDetails: action.currentSystemsDetailsFloor,
                    wallsSystemMetaData: action.systemsMetaDataWalls,
                    floorSystemMetaData: action.systemsMetaDataFloor,
                },
                currentSystemDetail: action.systemType === WALLSYSTEM_ID ? action.currentSystemsDetailsWalls : action.systemType === FLOORSYSTEM_ID ? action.currentSystemsDetailsFloor : [],
                systemsDetails: action.systemType === WALLSYSTEM_ID ? action.currentSystemsDetailsWalls.slice(0, 20) : action.systemType === FLOORSYSTEM_ID ? action.currentSystemsDetailsFloor.slice(0, 20) : [],
                systemsMetaData: action.systemType === WALLSYSTEM_ID ? action.currentSystemsDetailsWalls : action.systemType === FLOORSYSTEM_ID ? action.currentSystemsDetailsFloor : [],
                systemTypeID: action.systemType,
                displayedSystemsCount: action.systemType === WALLSYSTEM_ID ? action.currentSystemsDetailsWalls.length : action.systemType === FLOORSYSTEM_ID ? action.currentSystemsDetailsFloor.length : 0,
            };
        case LOAD_SYSTEMS_DETAILS_ERROR:
            return { ...state, loadingSystemsDetails: false };
        case RESTORE_SYSTEMS_DETAILS:
            return {
                ...state,
                currentSystemDetail: action.currentSystemsDetails,
                systemsDetails: action.currentSystemsDetails.slice(0, 20),
                systemsMetaData: action.systemsMetaData,
                systemTypeID: action.systemType,
                displayedSystemsCount: action.currentSystemsDetails.length,
                loadingSystemsDetails: false
            };
        case LOAD_SYSTEMS_DETAILS_MORE:
            return { ...state, loadingSystemsDetails: false, systemsDetails: [...state.systemsDetails, ...action.systemsDetails] };
        case FILTER_SYSTEMS:
            return { ...state, loadingSystemsDetails: false, systemsDetails: action.currentSystemsDetails.slice(0, 20), currentSystemDetail: action.currentSystemsDetails, displayedSystemsCount: action.currentSystemsDetails.length }
        case DISPLAY_FAVORITE:
            return { ...state, systemsDetails: action.systemsDetails.slice(0, 20), favoriteDisplayed: true }
        case DISPLAY_SYSTEMS:
            return { ...state, systemsDetails: action.systemsDetails.slice(0, 20), displayedSystemsCount: action.systemsDetails.length, favoriteDisplayed: false }
        case ADD_SEARCH_KEY_WORD:
            return { ...state, searchKeyWords: [...state.searchKeyWords, action.searchWord] }
        case DELETE_SEARCH_KEY_WORD:
            return {
                ...state, searchKeyWords: [...state.searchKeyWords.slice(0, action.searchWordIndex),
                ...state.searchKeyWords.slice(action.searchWordIndex + 1)]
            }
        case RESET_SEARCH_KEY_WORD:
            return {
                ...state, searchKeyWords: []
            }
        case LOAD_EXISTING_SYSTEMS:
            return { ...state, existingSystems: action.existingSystems }
        default:
            return state;

    }
}

