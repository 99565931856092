import { escapeSpecialChars, wait } from "../Helpers";
import {
  BuiltInCategory,
  FaceData,
  ElementsTree,
  PlumbingElement,
} from "../Types/RevitTypes";
import { Store } from "redux";
import { Tree } from "../Types/TreeTypes";

export interface Selection {
  store: Store;
}

export class Selection {
  manualSelection = async (
    builtinCategory: BuiltInCategory | "wall&ceiling",
    isSingleSelection: boolean = false
  ) => {
    try {
      if (builtinCategory === "pipe") {
        return this.manualPipeSelection([
          "pipeFitting",
          "pipeAccessory",
          "pipeCurve",
        ]);
      } else {
        localStorage.setItem("elementsByLevelAndType", "true");
        localStorage.setItem("isErrorThrown", "False");
        let processing = true;
        window.revit.manualSelection(builtinCategory, isSingleSelection);

        while (processing) {
          await wait(50);
          processing = JSON.parse(
            localStorage.getItem("elementsByLevelAndType") as string
          );
        }

        let result = localStorage.getItem(
          "elementsByLevelAndTypeResult"
        ) as string;
        result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
          m.replace('"', '\\"')
        );
        result = result.replace(/\\/g, "\\");
        result = escapeSpecialChars(result);
        result = result.replace(/(:\s*"[^"]+:\s*)"([^"]+)"/gm, "$1$2");
        return JSON.parse(result);
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  async pickFaces(builtinCategory: BuiltInCategory): Promise<FaceData[]> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("pickedFaces", "true");
      let processing = true;
      window.revit.pickFaces(builtinCategory);
      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("pickedFaces") as string);
      }
      let result = localStorage.getItem("pickedFacesResult");
      // result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, m =>m.replace('"', '\\"'));
      return JSON.parse(result as string);
    }
  }

  elementsByLevelAndType = async (
    builtinCategory: BuiltInCategory
  ): Promise<ElementsTree> => {
      return new Promise((resolve, reject) => 
      window.indec.getElementsByTypeAndLevel(
        {category: builtinCategory},
        resolve,
        reject
      )
    )
    .then((x: any) => {
      return JSON.parse(x);
    })
    .catch((ex: any) => {
      console.error(ex);
      return {Tree: []};
    });
    // localStorage.setItem("elementsByLevelAndType", "true");
    // localStorage.setItem("isErrorThrown", "False");
    // let processing = true;
    // window.revit.getElementsByTypeAndLevel(builtinCategory);

    // while (processing) {
    //   await wait(50);
    //   processing = JSON.parse(
    //     localStorage.getItem("elementsByLevelAndType") as string
    //   );
    // }
    // let result = localStorage.getItem("elementsByLevelAndTypeResult") as string;
    // result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
    //   m.replace('"', '\\"')
    // );

    // result = result.replace(/\\/g, "\\");
    // result = escapeSpecialChars(result);
    // result = result.replace(/(:\s*"[^"]+:\s*)"([^"]+)"/gm, "$1$2");
    // return JSON.parse(result);
  };

  elementsLevelAndType = async (): Promise<Tree> => {
    localStorage.setItem("elementsLevelAndType", "true");
    let processing = true;

    window.revit.getElementsTypeAndLevel();

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("elementsLevelAndType") as string
      );
    }

    let result = localStorage.getItem("elementsLevelAndTypeResult") as string;
    result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );
    result = result.replace(/\\/g, "\\");
    return JSON.parse(result);
  };

  getElements = async (
    builtinCategory: BuiltInCategory,
    callBack: (response: string, status: string) => Promise<void>
  ): Promise<void> => {
    window.revitCallBack = callBack;
    window.revit.getElements(builtinCategory);
  };

  getRevitElements = async (
    builtinCategory: string[],
    callBack: (response: string, status: string) => Promise<void>
  ): Promise<void> => {
    window.revitCallBack = callBack;
    window.revit.getRevitElements(JSON.stringify(builtinCategory));
  };

  wallManualSelectionDraw02 = async (): Promise<Tree> => {
    if (!window.revit) {
      // TODO: add revitFaker compatibility
      return JSON.parse("{}");
    } else {
      localStorage.setItem("elementsByLevelAndType", "true");
      let processing = true;

      window.revit.getElementsForManualSelection();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("elementsByLevelAndType") as string
        );
      }

      let result = localStorage.getItem(
        "elementsByLevelAndTypeResult"
      ) as string;
      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      return JSON.parse(result);
    }
  };

  pipesBySystemNameSelection = async () => {
    localStorage.setItem("groupPipeSelection", "true");
    let processing = true;
    window.revit.groupPipeSelection(
      JSON.stringify(["pipeFitting", "pipeAccessory", "pipeCurve"])
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("groupPipeSelection") as string
      );
    }

    let result = localStorage.getItem("groupPipeSelectionResult") as string;
    result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );
    return JSON.parse(result).pipeList;
  };

  manualPipeSelection = async (
    pipeCategories: any[]
  ): Promise<PlumbingElement[]> => {
    localStorage.setItem("manualPipeSelection", "true");
    let processing = true;
    window.revit.manualPipeSelection(JSON.stringify(pipeCategories));

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("manualPipeSelection") as string
      );
    }

    let result = localStorage.getItem("manualPipeSelectionResult") as string;
    result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      m.replace('"', '\\"')
    );
    return JSON.parse(result).pipeList;
  };

  selectElementsById = async (
    elementsIdToHighlight: string[]
  ): Promise<string[]> => {
    if (!window.revit) {
      await wait(200);
      return [""];
    } else {
      localStorage.setItem("selectElementsById", "true");
      let processing = true;
      window.revit.selectElementsById(JSON.stringify(elementsIdToHighlight));

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("selectElementsById") as string
        );
      }

      let result = localStorage.getItem("selectElementsByIdResult") as string;
      // result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      //     m.replace('"', '\\"')
      // );
      return JSON.parse(result);
    }
  };

  getCouplingsIds = async (id: string): Promise<any[]> => {
    if (!window.revit) {
      await wait(200);
      return [12473778, 12473779, 12473780];
    } else {
      localStorage.setItem("getCouplingsIds", "true");
      let processing = true;
      window.revit.getCouplingsIds(id);
      let remainingAttempts = 20;
      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getCouplingsIds") as string
        );
        remainingAttempts--;
        if (remainingAttempts === 0) {
          return await this.getCouplingsIds(id);
        }
      }
      let result = localStorage.getItem("getCouplingsIdsResult") as string;
      // result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
      //     m.replace('"', '\\"')
      // );
      return JSON.parse(result).map((res: any) => res.IntegerValue);
    }
  };
}
