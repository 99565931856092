import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Grid, Segment, Button, Dimmer, Loader } from "semantic-ui-react";
import { LevelsColumn } from "../../../../Components/Selector/LevelsColumn";
import { TypesColumn } from "../../../../Components/Selector/TypesColumn";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import {
    selectLevel,
    selectAllLevels,
    selectType,
    selectAllTypes,
} from "../Actions/index";
import { Routes } from "./root";
import { api } from "../../../../RevitJS/API";
import { selectedItems, filterProjectTree, scheduleHandler } from "../Helpers";
import { WithTranslation, withTranslation } from "react-i18next";

const eraseSelectionHandler = async (props: Props) => {
    const tree = await api.selection.elementsByLevelAndType("wall");
    let test = 2;
    let selectedTypes: any[] = [];
    props.typesData.forEach((e) => {
        if (e.Checked) selectedTypes.push(e.Type);
    });
    const selectedElems = [].concat.apply(
        [],
        selectedItems(tree, props.selectedLevels, selectedTypes).map(
            (e: any) => e.Ids
        )
    );
    if (selectedElems.length) {
        const nb_walls = selectedElems.length;
        for (let i = 0; i < nb_walls; i++) {
            await api.familyEditor.setParams([
                {
                    Id: selectedElems[i],
                    Params: [{ Name: "Processed", Type: "YesNo", Value: 0 }],
                },
            ]);
            const elems = await api.queries.filterElements(
                "Generic",
                [
                    {
                        Param: {
                            Name: "id",
                            Type: "Integer",
                            Value: parseInt(selectedElems[i]),
                        },
                        Rule: "Equals",
                    },
                ],
                null
            );
            await api.familyEditor.deleteElementsByIds(elems);
        }
        api.windowsHandler.closeWindow();
    }
};

interface Props {
    selectorType: string;
    Icon: string;
    levelsData: LevelData[];
    typesData: TypeData[];
    routes: any;
    selectedLevels: string[];
    selectedTypes: string[];
    selectLevel: any;
    selectAllLevels: Function;
    selectAllTypes: Function;
    selectType: any;
    setRoute: any;
    language: string;
}

interface State {
    processing: boolean;
}

export class GroupSelector extends React.Component<
    Props & WithTranslation,
    State
> {
    componentDidMount = () => {
        api.windowsHandler.resizeWindow(800, 500);
    };

    state = {
        processing: false,
    };

    //   public static defaultProps={
    //     wording:{
    //       levels:{
    //           "French": "Niveaux",
    //           "English": "Levels",
    //           "German": "Ebenen",
    //           "Italian": "Livelli"
    //       },
    //       ceilingTypes:{
    //           "French": "Types de plafonds",
    //           "English": "Ceiling types",
    //           "German": "Deckentypen",
    //           "Italian": "Tipi di soffito"
    //       },
    //       wallTypes:{
    //         "French": "Types de murs",
    //         "English": "Wall types",
    //         "German": "Wandtypen",
    //         "Italian": "Tipi di muro"
    //       },
    //       selection:{
    //           "French": "Sélection",
    //           "English": "Selection",
    //           "German": "Auswahl",
    //           "Italian": "Selezione"
    //       },
    //       back:{
    //         "French": "Retour",
    //         "English": "Back",
    //         "German": "Zurück",
    //         "Italian": "Indietro"
    //       },
    //       validate:{
    //           "French": "Valider",
    //           "English": "Validate",
    //           "German": "Bestätigen",
    //           "Italian": "Convalidare"
    //       },
    //       deletePending:{
    //         "French": "Suppression en cours...",
    //         "English": "Deleting...",
    //         "German": "Löschen...",
    //         "Italian": "La cancellazione in corso..."
    //     },
    //     measurementsInProgress:{
    //       "French": "Métrés en cours...",
    //         "English": "Measurements in progress...",
    //         "German": "Messungen laufen...",
    //         "Italian": "Misure in corso..."
    //     },
    //     wall: {
    //       "French": "Murs",
    //       "English": "Walls",
    //       "German": "Wände",
    //       "Italian": "Muri"
    //   },
    //   level: {
    //       "French": "Niveaux",
    //       "English": "Levels",
    //       "German": "Ebenen",
    //       "Italian": "Livelli"
    //   },
    //   wallType: {
    //       "French": "Types de murs",
    //       "English": "Wall types",
    //       "German": "Wandtyp",
    //       "Italian": "Tipo de muro"
    //   },
    //   thickness: {
    //       "French": "Epaisseur (mm)",
    //       "English": "Thickness (mm)",
    //       "German": "Dicke (mm)",
    //       "Italien": "Spessore (mm)"
    //   },
    //   Id: {
    //       "French": "Id du mur",
    //       "English": "Wall Ids",
    //       "German": "Wand-IDs",
    //       "Italian": "ID de muri"
    //   },
    //   height: {
    //       "French": "Hauteur (mm)",
    //       "English": "Height (mm)",
    //       "German": "Höhe (mm)",
    //       "Italian": "Altezza (mm)"
    //   },
    //   length: {
    //       "French": "Longueur (mm)",
    //       "English": "Length (mm)",
    //       "German": "Länge (mm)",
    //       "Italian": "Lunghezza (mm)",

    //   },
    //   surface: {
    //       "French": "Surface (m²)",
    //       "English": "Surface (m²)",
    //       "German": "Oberfläche (m²)",
    //       "Italian": "Superficie (m²)"
    //   },
    //   fileName: {
    //       "French": "Nomenclature des murs",
    //       "English": "Wall schedules",
    //       "German": "Wandinventar",
    //       "Italian": "Nomenclatura murale"
    //   },
    //   }
    // }

    selectorTypes = () => {
        const { t } = this.props;
        switch (this.props.selectorType) {
            case "wall":
                return t("wallTypes");
            case "ceiling":
                return t("ceilingTypes");
            default:
                return t("wallTypes");
        }
    };

    backHandler = () => {
        this.props.setRoute(Routes.ROOT);
    };

    validateHandler = async () => {
        if (this.props.selectedTypes.length) {
            // window.addEventListener("blur", () => {
            //     api.windowsHandler.closeWindow();
            // });
            const tree = await api.selection.elementsByLevelAndType("wall");
            let selectedTypes: any[] = [];
            this.props.typesData.forEach((e) => {
                if (e.Checked) selectedTypes.push(e.Type);
            });
            const selectedElems = [].concat.apply(
                [],
                selectedItems(
                    tree,
                    this.props.selectedLevels,
                    selectedTypes
                ).map((e: any) => e.Ids)
            );
            const projectTree = await api.selection.elementsByLevelAndType(
                "wall"
            );
            let filteredTree = filterProjectTree(
                projectTree,
                selectedElems,
                this.props.selectedLevels
            );
            await scheduleHandler(filteredTree);
            api.windowsHandler.closeWindow();
        }
    };

    render() {
        const { t } = this.props;

        if (this.state.processing) {
            return (
                <Dimmer active={true}>
                    <Loader content={t("measurementsInProgress")}></Loader>
                </Dimmer>
            );
        }
        return (
            <div id="wallSchedules-groupSelector-container_Riggips" >
                <FunctionalityHeader
                    Icon={this.props.Icon}
                    name={t("selection")}
                    width={80}
                    height={30}
                />
                <div style={{ padding: 5, overflow: "auto" }}>
                    <div style={{ width: "100%", backgroundColor: "none" }}>
                        <div style={{ margin: "auto" }}>
                            <Grid
                                columns={2}
                                stackable
                                style={{
                                    backgroundColor: "none",
                                    height: 340,
                                    overflow: "hidden",
                                }}
                                container
                                centered
                            >
                                <LevelsColumn
                                    selectLevel={this.props.selectLevel}
                                    selectAllLevels={this.props.selectAllLevels}
                                    columnTitle={t("levels")}
                                    levelsData={this.props.levelsData}
                                    color="blue"
                                />
                                <TypesColumn
                                    selectType={this.props.selectType}
                                    selectedLevels={this.props.selectedLevels}
                                    selectAllTypes={this.props.selectAllTypes}
                                    columnTitle={this.selectorTypes()}
                                    color="blue"
                                    typesData={this.props.typesData}
                                />
                            </Grid>
                        </div>
                        <Segment basic>
                            <Button
                                onClick={this.backHandler}
                                floated="left"
                                primary
                            >
                                <table></table>
                                {t("back")}
                            </Button>
                            <Button
                                onClick={(e) => {
                                    this.setState({ processing: true });
                                    this.validateHandler();
                                }}
                                disabled={this.props.selectedTypes.length === 0}
                                floated="right"
                                primary
                            >
                                {t("validate")}
                            </Button>
                        </Segment>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        Icon: state.functionalityIcon,
        language: state.language,
        levelsData: state.levelsData,
        typesData: state.typesData,
        selectedLevels: state.selectedLevels,
        selectedTypes: state.selectedTypes,
        name: ownProps.name,
        setRoute: ownProps.setRoute,
    };
};

export default connect(mapStateToProps, {
    selectType,
    selectLevel,
    selectAllLevels,
    selectAllTypes,
})(withTranslation("riggibs")(GroupSelector));
