import {
  setHeaderStyle,
  setSecondHeaderStyle,
  informationTable,
  adjustColumnsSize,
  seTableStyle,
} from "./tableHelaper";
import _ from "lodash";
import { api } from "../../../RevitJS/API";

export const boardQuantities = async (plasterboardsSheet:any) => {
  let headersMainBoard = [
    { ref: "A1", name: "Floor" },
    { ref: "B1", name: "Rigips system" },
    { ref: "C1", name: "Rigips board type" },
    { ref: "D1", merge: "D1:E1", name: "Board thickness per board (mm)" },
    { ref: "F1", merge: "F1:G1", name: "Surface per board (m²)" },
  ];

  let mainTableDetailColumns = [
    { name: "Nr", filterButton: true, ref: "A2", horizontalAlignment: "left" },
    { name: "Nr", filterButton: true, ref: "B2", horizontalAlignment: "left" },
    {
      name: "Name",
      filterButton: true,
      ref: "C2",
      horizontalAlignment: "right",
    },
    { name: "XX", filterButton: true, ref: "D2", horizontalAlignment: "right" },
    { name: "mm", filterButton: true, ref: "E2", horizontalAlignment: "left" },
    { name: "XX", filterButton: true, ref: "F2", horizontalAlignment: "right" },
    { name: "m2", filterButton: true, ref: "G2", horizontalAlignment: "left" },
  ];
  const rowData = await getBoards();
  
  plasterboardsSheet.addTable({
    name: "Boards",
    ref: "A2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
      showRowStripes: true,
    },
    columns: mainTableDetailColumns,
    rows: rowData.boardsData,
  });

  let totalTableBoard = [
    { ref: "H1", merge: "H1:J1", name: "Total quantity boards per type" },
  ];

  let totalTableeDetailColumns = [
    {
      name: "Name",
      filterButton: false,
      ref: "H2",
      horizontalAlignment: "right",
    },
    {
      name: "XX",
      filterButton: false,
      ref: "I2",
      horizontalAlignment: "right",
    },
    { name: "m2", filterButton: false, ref: "J2", horizontalAlignment: "left" },
  ];
  plasterboardsSheet.addTable({
    name: "Boards",
    ref: "H2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
      showRowStripes: true,
    },
    columns: totalTableeDetailColumns,
    rows: rowData.boardsDataTotal,
  });

  for (let i = 2; i < rowData.boardsData.length; i++) {
    const row = plasterboardsSheet.getRow(i);

    row.eachCell({ includeEmpty: true }, function (cell:any, colNumber:any) {
      if (colNumber < 8) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "C6E0B4",
          },
        };
      } else {
        if (cell.value !== " ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "D0CECE",
            },
          };
        }
      }
    });
  }

  const mainTableHeaders = setHeaderStyle(plasterboardsSheet, headersMainBoard);
  const mainTableSecondsHeaders = setSecondHeaderStyle(
    plasterboardsSheet,
    mainTableDetailColumns,
    "C6E0B4"
  );
  const totalTableHeaders = setHeaderStyle(plasterboardsSheet, totalTableBoard);
  const totalTableSecondHeaders = setSecondHeaderStyle(
    plasterboardsSheet,
    totalTableeDetailColumns,
    "D0CECE"
  );
  const TableInformation = informationTable(
    plasterboardsSheet,
    rowData.boardsData.length + 3
  );
  const TablesStyle = seTableStyle(
    plasterboardsSheet,
    rowData.boardsData.length + 3
  );
  adjustColumnsSize(plasterboardsSheet);
};

const text = `Toutes les informations s’adressent à des spécialistes qualifiés et sont basées sur les derniers développements de la technique.Elles ont été élaborées au mieux des connaissances, mais ne constituent aucune garantie.Rigips SA s’efforce continuellement de vous offrir les meilleures solutions possibles, c’est pourquoi nous nous réservons le droit d’apporter des modifications à ces informations, en fonction de l’évolution des techniques de production ou d’application.Ces données ne remplacent pas les planifications techniques spécialisées qui peuvent s’avérer nécessaires.Les travaux de tous les corps de métier doivent impérativement être exécutés selon les règles de l’art.
    Nous ne pouvons exclure totalement la présence d’erreurs de calcul.Les documents les plus récents de la documentation technique ainsi que les directives de mise en œuvre se trouvent sur Internet, à l’adresse www.rigips.ch.
    Veuillez prendre note du fait que nos Conditions générales de vente, livraisons et paiements (CGV) en vigueur actuellement sont seules déterminantes dans nos relations d’affaires.Elles sont disponibles sur demande ou sur Internet à l’adresse www.rigips.ch.
        L’entreprise Rigips SA vous souhaite beaucoup de plaisir et de réussite avec les solutions et systèmes Rigips et vous remercie de votre confiance.
Tous droits réservés.Données fournies sans garantie.`;

const getBoards = async () => {
  const boardsData:any = [];
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Rigips_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );

  let plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "wallId",
      "id",
      "systemName",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );

  plasterboardDetails = plasterboardDetails.map((board:any) => ({
    Id: board.Id,
    WallId: board.Params.find((p:any) => p.Name === "id")?.Value, //145774,
    SystemName: board.Params.find((p:any) => p.Name === "systemName")?.Value,
    PlasterboardHeight: board.Params.find(
      (p:any) => p.Name === "PlasterboardHeight"
    )?.Value,
    PlasterboardLength: board.Params.find(
      (p:any) => p.Name === "PlasterboardLength"
    )?.Value,
    PlasterboardWidth: board.Params.find((p:any) => p.Name === "PlasterboardWidth")
      ?.Value,
    PlasterboardArea:
      board.Params.find((p:any) => p.Name === "PlasterboardLength")?.Value *
      board.Params.find((p:any) => p.Name === "PlasterboardWidth")?.Value,
    Name: board.Params.find((p:any) => p.Name === "name")?.Value,
  }));

  const wallIds = _.uniq(_.map(plasterboardDetails, "WallId"));
  const wallSystemParameter = await api.queries.getObjectsParams(wallIds, [
    "SG_System",
  ]);

  plasterboardDetails = plasterboardDetails.map((board:any) => {
    board.SystemName = wallSystemParameter.find(
      (p:any) => p.Id === board.WallId
    ).Params[0].Value;
    return board;
  });

  const walltree = await api.selection.elementsByLevelAndType("wall");
  console.log("walltree: ", walltree);
  walltree.Tree.forEach((level) => {
    boardsData.push([level.Level.Name, " ", " ", " ", " ", " ", " "]);
    let wallsIdByLevel:any = [];
    level.Elements.forEach((element) => {
      wallsIdByLevel.push(...element.Ids);
    });
    let plasterboardsBySystemName = plasterboardDetails.filter((board:any) =>
      wallsIdByLevel.includes(board.WallId.toString())
    );
    plasterboardsBySystemName = _.chain(plasterboardsBySystemName)
      .groupBy("SystemName")
      .map((value, key) => ({ SystemName: key, plasterboardDetails: value }))
      .value();
    plasterboardsBySystemName.forEach((p:any) => {
      boardsData.push([" ", p.SystemName, " ", " ", " ", " ", " "]);
      console.log("plasterboardsByBoardType:", p.plasterboardDetails);
      let plasterboardsByBoardType = _.chain(p.plasterboardDetails)
        .groupBy("Name")
        .map((value, key) => ({ BoardType: key, plasterboardDetails: value }))
        .value();
      console.log("plasterboardsByBoardType:", plasterboardsByBoardType);
      plasterboardsByBoardType.forEach((board) => {
        boardsData.push([" ", " ", board.BoardType, " ", " ", " ", " "]);
        let plasterboardsByThickness = _.chain(board.plasterboardDetails)
          .groupBy("PlasterboardWidth")
          .map((value, key) => ({ Thinkness: key, plasterboardDetails: value }))
          .value();
        plasterboardsByThickness.forEach((t) => {
          boardsData.push([
            " ",
            " ",
            " ",
            t.plasterboardDetails.length,
            parseFloat(t.Thinkness),
            " ",
            " ",
          ]);
        });
        let plasterboardsByArea = _.chain(board.plasterboardDetails)
          .groupBy("PlasterboardArea")
          .map((value, key) => ({ Area: key, plasterboardDetails: value }))
          .value();
        plasterboardsByArea.forEach((t) => {
          boardsData.push([
            " ",
            " ",
            " ",
            " ",
            " ",
            t.plasterboardDetails.length,
            parseFloat(t.Area),
          ]);
        });
      });
    });
  });

  _.forEach(_.times(3, _.constant(0)), function (value) {
    boardsData.push([" ", " ", " ", " ", " ", " ", " "]);
  });

  console.log("boardsData: ", boardsData);
  let boardsDataTotal:any = [];
  let plasterboardsTotal = _.chain(plasterboardDetails)
    .groupBy("Name")
    .map((value, key) => ({ SystemName: key, plasterboardDetails: value }))
    .value();

  plasterboardsTotal.forEach((p) => {
    boardsDataTotal.push([
      p.SystemName,
      p.plasterboardDetails.length,
      p.plasterboardDetails.reduce(
        (acc, board) => acc + board.PlasterboardArea,
        0
      ),
    ]);
  });

  const totalBlankRowAdded = boardsData.length - boardsDataTotal.length;

  _.forEach(_.times(totalBlankRowAdded, _.constant(0)), function (value) {
    boardsDataTotal.push([" ", " ", " "]);
  });

  console.log("boardsDataTotal: ", boardsDataTotal);
  return { boardsData, boardsDataTotal };
};
