import _ from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    NAME_ATTR_TECHNICAL_NAME,
    NEED_COUPLING_TECHNICAL_NAME,
} from "../../../services/constants.config";
import { getAttributeFromObject } from "../../../helpers/utils";
import CouplingFamilyType, { CouplingElement } from "../../../models/Coupling";
import Element from "../../../models/Element.model";
import Item from "../../../models/Item.model";
import PixelLoader from "../../common/loader/PixelLoader";
import "./body.scss";
import CouplingSelectionBody from "./couplingSelectionBody/CouplingSelectionBody";
import CouplingSelectionHeader from "./couplingSelectionHeader/CouplingSelectionHeader";
import CouplingSelectionModal from "./couplingSelectionModal/CouplingSelectionModal";

function Body({ items, loading, setValidatedItems, t }: IProps) {
    const [modal, setModal] = useState<{
        open: boolean;
        target: HTMLTableCellElement | null;
        item: Item | null;
        itemIndex: number;
        elementIndex: number;
        couplingIndex: number;
    }>({
        open: false,
        target: null,
        item: null,
        itemIndex: -1,
        elementIndex: -1,
        couplingIndex: -1,
    });

    useEffect(() => {
        items.forEach((item) => {
            if (
                item.range.attributes.find((attr: any) => {
                    return attr.technicalName === NEED_COUPLING_TECHNICAL_NAME;
                })?.values[0].value === "True"
            ) {
                item.compatibleAndAvailableElements = [];
                item.allCompatibleElements.forEach((element) => {
                    if (item.allCompatibleCouplings) {
                        item.allCompatibleCouplings.forEach((familyType) => {
                            familyType.couplings.forEach(
                                (coupling: CouplingElement) => {
                                    if (
                                        coupling.dn.toString() ===
                                        element.Diameter.toString()
                                    ) {
                                        let tmp = {
                                            familyType: _.cloneDeep(
                                                familyType.familyType
                                            ),
                                            quality: _.cloneDeep(
                                                coupling.quality
                                            ),
                                            gasket: _.cloneDeep(
                                                coupling.gasketMaterial
                                            ),
                                            oid: _.cloneDeep(coupling.oid),
                                            recommendations: [
                                                ..._.cloneDeep(
                                                    coupling.recommendations
                                                ),
                                            ],
                                        };
                                        if (
                                            !element.allCompatibleCouplings ||
                                            (element.allCompatibleCouplings &&
                                                !element.allCompatibleCouplings.find(
                                                    (compatible) => {
                                                        return (
                                                            compatible.familyType ===
                                                                tmp.familyType &&
                                                            compatible.quality ===
                                                                tmp.familyType &&
                                                            compatible.gasket ===
                                                                tmp.gasket
                                                        );
                                                    }
                                                ))
                                        ) {
                                            if (
                                                element.allCompatibleCouplings
                                            ) {
                                                element.allCompatibleCouplings.push(
                                                    tmp
                                                );
                                            } else {
                                                element.allCompatibleCouplings = [
                                                    tmp,
                                                ];
                                            }
                                        }
                                    }
                                    return;
                                }
                            );
                            return;
                        });
                    }
                    if (
                        !item.compatibleAndAvailableElements.find(
                            (tmpElement: Element) =>
                                tmpElement.Diameter === element.Diameter
                        )
                    ) {
                        item.compatibleAndAvailableElements.push(element);
                    }

                    item.compatibleAndAvailableElements.sort(
                        (a: any, b: any) =>
                            parseInt(a.Diameter) - parseInt(b.Diameter)
                    );
                    return;
                });

                if (item.allCompatibleCouplings) {
                    let tmpCouplingsRecCount: any = {};
                    item.allCompatibleCouplings.forEach(
                        (compatibleCoupling: CouplingFamilyType) => {
                            let first = null;
                            let count: any = {
                                "1": 0,
                                "2": 0,
                                "3": 0,
                                "4": 0,
                                "5": 0,
                                "6": 0,
                                "7": 0,
                                "8": 0,
                                "9": 0,
                                "10": 0,
                            };
                            compatibleCoupling.couplings.forEach(
                                (coupling: CouplingElement) => {
                                    coupling.recommendations.forEach(
                                        (recommendation) => {
                                            if (
                                                recommendation.rangeName.toLowerCase() ===
                                                getAttributeFromObject(
                                                    item.range,
                                                    NAME_ATTR_TECHNICAL_NAME
                                                ).toLowerCase()
                                            ) {
                                                count[ Math.floor(recommendation.value)]++;
                                            }
                                        }
                                    );
                                }
                            );
                            tmpCouplingsRecCount[
                                compatibleCoupling.familyType
                            ] = count;
                        }
                    );
                    let sortedCompatibleCouplings: string[] = [];
                    for (let i = 1; i <= 10; i++) {
                        let max = -1;
                        let maxKey = null;
                        Object.keys(tmpCouplingsRecCount).forEach(
                            (couplingReccomendationKey: any) => {
                                if (
                                    tmpCouplingsRecCount[
                                        couplingReccomendationKey
                                    ][i] !== 0 &&
                                    tmpCouplingsRecCount[
                                        couplingReccomendationKey
                                    ][i] > max
                                ) {
                                    max =
                                        tmpCouplingsRecCount[
                                            couplingReccomendationKey
                                        ][i];
                                    maxKey = couplingReccomendationKey;
                                }
                            }
                        );
                        if (
                            max !== -1 &&
                            maxKey &&
                            tmpCouplingsRecCount[maxKey] &&
                            sortedCompatibleCouplings.indexOf(maxKey) === -1
                        ) {
                            sortedCompatibleCouplings.push(maxKey);
                        }
                    }

                    for (let i = 0; i < sortedCompatibleCouplings.length; i++) {
                        let concernedIndex = item.allCompatibleCouplings.findIndex(
                            (compatibleCoupling) =>
                                compatibleCoupling.familyType ===
                                sortedCompatibleCouplings[i]
                        );
                        item.allCompatibleCouplings.splice(
                            i,
                            0,
                            item.allCompatibleCouplings.splice(
                                concernedIndex,
                                1
                            )[0]
                        );
                    }
                    item.sortedRecommendation = _.cloneDeep(
                        sortedCompatibleCouplings
                    );
                }
            }
        });
        items.forEach((item: Item, itemIndex: number) => {
            if (item.allCompatibleCouplings && item.sortedRecommendation) {
                for (let i = 0; i < item.allCompatibleCouplings.length; i++) {
                    let coupling = item.allCompatibleCouplings[i];
                    let found = false;
                    item.sortedRecommendation.forEach((recommendation) => {
                        if (coupling.familyType === recommendation) {
                            found = true;
                        }
                    });
                    if (!found) {
                        item.allCompatibleCouplings?.splice(i, 1);
                        i--;
                    }
                }

                toggleCouplingSelectedBestRecommendedForAllElements(
                    item,
                    itemIndex
                );
            }
        });
        setValidatedItems(items);
    }, [loading]);

    const toggleCouplingSelectedBestRecommendedForAllElements = (
        item: Item,
        itemIndex: number
    ) => {
        if (item.sortedRecommendation.length > 0) {
            item.sortedRecommendation.forEach((rec: string) => {
                item.compatibleAndAvailableElements.forEach(
                    (element: Element, elementIndex: number) => {
                        let couplingIndex = -1;
                        let tmp;
                        if (element.allCompatibleCouplings) {
                            tmp = element.allCompatibleCouplings.filter(
                                (compatibleCoupling, index) => {
                                    if (rec === compatibleCoupling.familyType) {
                                        couplingIndex = index;
                                        return true;
                                    }
                                }
                            );
                        }

                        if (
                            tmp &&
                            couplingIndex > -1 &&
                            !element.selectedCoupling
                        ) {
                            let minIndex = 0;
                            let minValue = 999999999;
                            let rangeName = getAttributeFromObject(
                                item.range,
                                NAME_ATTR_TECHNICAL_NAME
                            );
                            tmp.forEach((compatibleCoupling, index) => {
                                if (compatibleCoupling.recommendations) {
                                    let tmpRec = compatibleCoupling.recommendations.find(
                                        (rec2) => {
                                            return rec2.rangeName === rangeName;
                                        }
                                    );

                                    if (
                                        tmpRec &&
                                        Number(tmpRec.value) < minValue
                                    ) {
                                        minIndex = index;
                                        minValue = Number(tmpRec.value);
                                    }
                                }
                            });
                            if (item.allCompatibleCouplings) {
                                item.allCompatibleCouplings.forEach(
                                    (
                                        coupling: CouplingFamilyType,
                                        index: number
                                    ) => {
                                        if (coupling.familyType === rec) {
                                            couplingIndex = index;
                                        }
                                        return;
                                    }
                                );
                            }

                            toggleMatchElementCoupling(
                                item,
                                elementIndex,
                                couplingIndex,
                                itemIndex,
                                true,
                                true,
                                true,
                                tmp[minIndex].quality,
                                tmp[minIndex].gasket
                            );
                        }
                    }
                );
            });
        } else {
            setTimeout(() => {
                // toggleCouplingSelectedForAllElements(true, item, itemIndex, 2);
                // toggleCouplingSelectedForAllElements(true, item, itemIndex, 1);
                // toggleCouplingSelectedForAllElements(true, item, itemIndex, 0);
            });
        }
    };

    const isElementCompatibleWithCoupling = (
        element: Element,
        familyType: CouplingFamilyType,
        recommendation: boolean = false
    ): boolean => {
        let found: boolean = false;
        familyType.couplings.forEach((coupling) => {
            if (coupling.dn.toString() === element.Diameter.toString()) {
                if (recommendation) {
                    found = true;
                } else if (familyType.selectedQuality === coupling.quality) {
                    if (
                        familyType.selectedGasket &&
                        familyType.selectedGasket === coupling.gasketMaterial
                    ) {
                        found = true;
                    } else if (!familyType.selectedGasket) {
                        found = true;
                    }
                }
            }
        });
        return found;
    };

    const toggleMatchElementCoupling = (
        item: Item,
        elementIndex: number,
        couplingIndex: number,
        itemIndex: number,
        selectThis: boolean,
        multipleSelect: boolean = false,
        recommendation: boolean = false,
        quality: string | null = null,
        gasket: string | null = null
    ) => {
        let element: Element =
            item.compatibleAndAvailableElements[elementIndex];
        if (item.allCompatibleCouplings) {
            let coupling: CouplingFamilyType =
                item.allCompatibleCouplings[couplingIndex];

            if (
                isElementCompatibleWithCoupling(
                    element,
                    coupling,
                    recommendation
                )
            ) {
                let thisElement: HTMLTableCellElement = document.getElementById(
                    `coupling-selection-inner-table-dn-td-${itemIndex}-${elementIndex}-${couplingIndex}`
                ) as HTMLTableCellElement;
                if (
                    selectThis &&
                    thisElement &&
                    thisElement.classList &&
                    !thisElement.classList.contains("selected-coupling")
                ) {
                    if (
                        thisElement &&
                        thisElement instanceof HTMLTableCellElement &&
                        thisElement.parentElement &&
                        thisElement.parentElement.children &&
                        thisElement.parentElement
                    ) {
                        for (
                            let i = 0;
                            i < thisElement.parentElement?.children?.length;
                            i++
                        ) {
                            let childElement: HTMLTableCellElement = document.getElementById(
                                `coupling-selection-inner-table-dn-td-${itemIndex}-${elementIndex}-${couplingIndex}`
                            )?.parentElement?.children[
                                i
                            ] as HTMLTableCellElement;
                            if (
                                childElement.classList.contains(
                                    "selected-coupling"
                                )
                            ) {
                                if (
                                    thisElement.cellIndex !==
                                    childElement.cellIndex
                                )
                                    childElement.classList.remove(
                                        "selected-coupling"
                                    );
                            }
                        }
                    }
                    thisElement.classList.add("selected-coupling");
                } else if (
                    !selectThis &&
                    thisElement.classList.contains("selected-coupling")
                ) {
                    thisElement.classList.remove("selected-coupling");
                }

                if (selectThis) {
                    if (element.allCompatibleCouplings) {
                        element.allCompatibleCouplings.forEach(
                            (compatibleCoupling) => {
                                if (!recommendation) {
                                    if (
                                        coupling.familyType ===
                                            compatibleCoupling.familyType &&
                                        coupling.selectedQuality ===
                                            compatibleCoupling.quality &&
                                        coupling.selectedGasket ===
                                            compatibleCoupling.gasket
                                    ) {
                                        element.selectedCoupling = compatibleCoupling;
                                    }
                                } else {
                                    if (
                                        coupling.familyType ===
                                            compatibleCoupling.familyType &&
                                        quality ===
                                            compatibleCoupling.quality &&
                                        gasket === compatibleCoupling.gasket
                                    ) {
                                        element.selectedCoupling = compatibleCoupling;
                                    }
                                }

                                return;
                            }
                        );
                    }
                    item.allCompatibleElements.forEach((tmpElement) => {
                        if (element.Diameter === tmpElement.Diameter) {
                            tmpElement.selectedCoupling =
                                element.selectedCoupling;
                        }
                    });
                } else {
                    item.allCompatibleElements.forEach((tmpElement) => {
                        if (element.Diameter === tmpElement.Diameter) {
                            delete tmpElement.selectedCoupling;
                        }
                    });
                }
                if (!multipleSelect) {
                    setValidatedItems(items);
                }
            }
        }
    };

    const toggleCouplingSelectedForAllElements = (
        value: boolean,
        concernedItem: Item,
        concernedItemIndex: number,
        couplingIndex: number
    ) => {
        concernedItem.compatibleAndAvailableElements.forEach(
            (element: Element, elementIndex) => {
                toggleMatchElementCoupling(
                    concernedItem,
                    elementIndex,
                    couplingIndex,
                    concernedItemIndex,
                    value,
                    true
                );
            }
        );
        setValidatedItems(items);
    };

    const handleQualityOrGasketChange = (
        qualiyOrGasket: "selectedQuality" | "selectedGasket",
        coupling: CouplingFamilyType,
        value: string
    ) => {
        coupling[qualiyOrGasket] = value;
        setValidatedItems(items);
    };

    return (
        <div id="coupling-selection-body-container">
            <div className="px-4 py-2 h-100">
                <div className="row h-100">
                    <div
                        className="col-12"
                        id="coupling-selection-table-container"
                    >
                        {loading ? (
                            <PixelLoader></PixelLoader>
                        ) : (
                            items.map((item, itemIndex) => {
                                return item.range.attributes.find(
                                    (attr: any) => {
                                        return (
                                            attr.technicalName ===
                                            NEED_COUPLING_TECHNICAL_NAME
                                        );
                                    }
                                )?.values[0]?.value === "True" ? (
                                    <React.Fragment
                                        key={`coupling-selection-fragement-${itemIndex}`}
                                    >
                                        <table
                                            key={`coupling-selection-table-${itemIndex}`}
                                        >
                                            <CouplingSelectionHeader
                                                item={item}
                                                itemIndex={itemIndex}
                                                itemsLength={items.length}
                                                handleQualityOrGasketChange={
                                                    handleQualityOrGasketChange
                                                }
                                            ></CouplingSelectionHeader>
                                            <CouplingSelectionBody
                                                items={items}
                                                item={item}
                                                itemIndex={itemIndex}
                                                toggleMatchElementCoupling={
                                                    toggleMatchElementCoupling
                                                }
                                                isElementCompatibleWithCoupling={
                                                    isElementCompatibleWithCoupling
                                                }
                                                toggleCouplingSelectedForAllElements={
                                                    toggleCouplingSelectedForAllElements
                                                }
                                                setValidatedItems={
                                                    setValidatedItems
                                                }
                                                setModal={setModal}
                                            ></CouplingSelectionBody>
                                        </table>
                                    </React.Fragment>
                                ) : (
                                    ""
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <CouplingSelectionModal
                modal={modal}
                setModal={setModal}
                toggleMatchElementCoupling={toggleMatchElementCoupling}
            ></CouplingSelectionModal>
        </div>
    );
}

type IProps = WithTranslation & {
    items: Item[];
    loading: boolean;
    setValidatedItems: Function;
};

export default withTranslation(["pam"])(Body);
