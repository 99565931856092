
export const setHeaderStyle = async (plasterboardsSheet:any, celReferences:any, color = '4472c4') => {
    celReferences.forEach((element:any) => {
        if (element.merge) plasterboardsSheet.mergeCells(element.merge)
        plasterboardsSheet.getCell(element.ref).value = element.name
        plasterboardsSheet.getCell(element.ref).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
        plasterboardsSheet.getCell(element.ref).font = {
            name: 'Calibri',
            family: 4,
            size: 11,
            bold: true,
            color: { argb: element.fontColor || 'FFFFFF' }
        };
        plasterboardsSheet.getCell(element.ref).fill = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: '000000' },
            fgColor: { argb: color }
        };
    });
}

export const setSecondHeaderStyle = async (plasterboardsSheet:any, celReferences:any, color = '4472c4') => {
    celReferences.forEach((element:any) => {
        if (element.merge) plasterboardsSheet.mergeCells(element.merge)
        plasterboardsSheet.getCell(element.ref).value = element.name
        plasterboardsSheet.getCell(element.ref).alignment = { vertical: 'middle', horizontal: element.horizontalAlignment, wrapText: true }
        plasterboardsSheet.getCell(element.ref).font = {
            name: 'Calibri',
            family: 4,
            size: 11,
            bold: false,
            color: { argb: '000000' }
        };
        plasterboardsSheet.getCell(element.ref).fill = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: '000000' },
            fgColor: { argb: color }
        };
    });
}

export const seTableStyle = async (plasterboardsSheet:any, maxRow = 0) => {
    plasterboardsSheet.eachRow(function (row:any, rowNumber:any) {
        row.eachCell((cell:any, colNumber:any) => {
            if (rowNumber > 1 && rowNumber < maxRow) {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        })
        row.commit();
    })
}

export const setInformationCell = async (plasterboardsSheet:any, celReferences:any) => {
    celReferences.forEach((element:any) => {
        if (element.merge) plasterboardsSheet.mergeCells(element.merge)
        plasterboardsSheet.getCell(element.ref).alignment = { vertical: 'middle', wrapText: true, horizontal: element.horizontalAlignment || 'left' }

        plasterboardsSheet.getCell(element.ref).value = element.name
        plasterboardsSheet.getCell(element.ref).font = {
            name: 'Arial',
            family: 4,
            size: element.fontSize || 8,
            bold: false,
            color: { argb: '000000' }
        };

        if(element.border){
        plasterboardsSheet.getCell(element.ref).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }}
    })
}

export const informationTable = async (plasterboardsSheet:any, startPosition = 28) => {
    let informations = [
        { ref: `A${startPosition}`, merge: `A${startPosition}:J${startPosition + 1}`, name: text, horizontalAlignment: 'left', border: false },

        { ref: `A${startPosition + 2}`, name: "Costumer:", border: true, fontSize: 9  },
        { ref: `A${startPosition + 3}`, name: "Contact Name:", border: true, fontSize: 9 },
        { ref: `A${startPosition + 4}`, name: "PLZ/Place:", border: true , fontSize: 9},
        { ref: `A${startPosition + 5}`, name: "SAP-Nr.:" , border: true, fontSize: 9},
        { ref: `B${startPosition + 2}`, merge: `B${startPosition + 2}:C${startPosition + 2}`, name: "" , border: true, fontSize: 9},
        { ref: `B${startPosition + 3}`, merge: `B${startPosition + 3}:C${startPosition + 3}`, name: "", border: true, fontSize: 9 },
        { ref: `B${startPosition + 4}`, merge: `B${startPosition + 4}:C${startPosition + 4}`, name: "" , border: true, fontSize: 9},
        { ref: `B${startPosition + 5}`, merge: `B${startPosition + 5}:C${startPosition + 5}`, name: "", border: true, fontSize: 9 },

        { ref: `D${startPosition + 2}`, name: "Object:" , border: true, fontSize: 9},
        { ref: `D${startPosition + 3}`, name: "Address:" , border: true, fontSize: 9},
        { ref: `D${startPosition + 4}`, name: "PLZ/Place:" , border: true, fontSize: 9},
        { ref: `D${startPosition + 5}`, name: "Prime-Nr.:", border: true , fontSize: 9},
        { ref: `E${startPosition + 2}`, merge: `E${startPosition + 2}:F${startPosition + 2}`, name: "", border: true , fontSize: 9},
        { ref: `E${startPosition + 3}`, merge: `E${startPosition + 3}:F${startPosition + 3}`, name: "", border: true , fontSize: 9},
        { ref: `E${startPosition + 4}`, merge: `E${startPosition + 4}:F${startPosition + 4}`, name: "", border: true , fontSize: 9},
        { ref: `E${startPosition + 5}`, merge: `E${startPosition + 5}:F${startPosition + 5}`, name: "", border: true , fontSize: 9},


        { ref: `G${startPosition + 2}`, name: "Place, date:" , border: true, fontSize: 9},
        { ref: `G${startPosition + 3}`, name: "Signature:" , border: true, fontSize: 9},
        { ref: `G${startPosition + 4}`, name: "" , border: true, fontSize: 9},
        { ref: `G${startPosition + 5}`, name: "" , border: true, fontSize: 9},
        { ref: `H${startPosition + 2}`, merge: `H${startPosition + 2}:I${startPosition + 2}`, name: "" , border: true, fontSize: 9},
        { ref: `H${startPosition + 3}`, merge: `H${startPosition + 3}:I${startPosition + 3}`, name: "" , border: true, fontSize: 9},
        { ref: `H${startPosition + 4}`, merge: `H${startPosition + 4}:I${startPosition + 4}`, name: "" , border: true, fontSize: 9},
        { ref: `H${startPosition + 5}`, merge: `H${startPosition + 5}:I${startPosition + 5}`, name: "" , border: true, fontSize: 9},
        { ref: `J${startPosition + 2}`, merge: `J${startPosition + 2}:J${startPosition + 5}`, name: "" , border: true, fontSize: 9},

        { ref: `A${startPosition + 6}`, merge: `A${startPosition + 6}:J${startPosition + 6}`, name: "Description: " , border: true, fontSize: 9},
        { ref: `A${startPosition + 7}`, merge: `A${startPosition + 7}:J${startPosition + 10}` , border: true, fontSize: 9},
    ]
    const information = setInformationCell(plasterboardsSheet, informations)
}

export const adjustColumnsSize = (sheet:any) => {
    for (let i = 0; i < sheet.columns.length; i++) {
        let dataMax = 15;
        const column = sheet.columns[i];
        // for (let j = 1; j < column.values.length; j++) {
        //     const columnLength = column.values[j].length;
        //     if (columnLength > dataMax) {
        //         dataMax = columnLength / 3;
        //     }
        // }
        column.width = dataMax < 10 ? 10 : dataMax + 2;
    }
};

const text = `Toutes les informations s’adressent à des spécialistes qualifiés et sont basées sur les derniers développements de la technique.Elles ont été élaborées au mieux des connaissances, mais ne constituent aucune garantie.Rigips SA s’efforce /n continuellement de vous offrir les meilleures solutions possibles, c’est pourquoi nous nous réservons le droit d’apporter des modifications à ces informations, en fonction de l’évolution des techniques de production ou d’application.Ces données ne remplacent pas les planifications techniques spécialisées qui peuvent s’avérer nécessaires.Les travaux de tous les corps de métier doivent impérativement être exécutés selon les règles de l’art.
    Nous ne pouvons exclure totalement la présence d’erreurs de calcul.Les documents les plus récents de la documentation technique ainsi que les directives de mise en œuvre se trouvent sur Internet, à l’adresse www.rigips.ch.
    Veuillez prendre note du fait que nos Conditions générales de vente, livraisons et paiements (CGV) en vigueur actuellement sont seules déterminantes dans nos relations d’affaires.Elles sont disponibles sur demande ou sur Internet à l’adresse www.rigips.ch.
        L’entreprise Rigips SA vous souhaite beaucoup de plaisir et de réussite avec les solutions et systèmes Rigips et vous remercie de votre confiance.
Tous droits réservés.Données fournies sans garantie.`