import React from "react";
import IFCGenerator from "./IFCGenerator";

const App = (props: { route: string; setRoute: any }) => {
  switch (props.route) {
    case "ROOT":
      return <IFCGenerator setRoute={props.setRoute} />;
    default:
      return <IFCGenerator setRoute={props.setRoute} />;
  }
};

export default App;
