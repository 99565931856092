import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import TechniqueRoot from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "6a1b40a0-6e20-4fbd-9ae3-ad819351a69b";

export const documentTechniqueTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    580,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Dossier Technique",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="dossier techniques"
    >
      <TechniqueRoot />
    </ParentRoot>
  );
};

export const DocTechRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="dossier techniques"
    >
      <TechniqueRoot />
    </ParentRoot>
  );
};

export const DocumentTechniques: Functionality = {
  Name: {
    French: "Dossiers Techniques",
    English: "Technical files",
    German: "Dossiers Techniques",
    Italian: "Dossiers Techniques",
    Indonesian: "Dossiers Techniques",
  },
  Trigger: documentTechniqueTrigger,
  ShortDesc: {
    French: "DAO/DAE/DOE",
    English: "DAO/DAE/DOE",
    German: "DAO/DAE/DOE",
    Italian: "DAO/DAE/DOE",
    Indonesian: "DAO/DAE/DOE",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Dossier Techniques.png",
  RootComponent,
  ParentId: "0",
};
