import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Modal,
  Table,
  Button,
  Loader,
  Dimmer,
  Segment,
  Header,
} from "semantic-ui-react";
import { SelectionStore } from "../Reducers";
import { FilterType } from "../../../../RevitJS/Types/StoreTypes";
import { showSystemDetails, hideSystemDetails } from "../Actions";
import _ from "lodash";
import { SolutionFilterType, ProductDetailData } from "../Types";

const mapStateToProps = (state: SelectionStore) => {
  const { filterType, filters } = state;
  let selectedFilters = ((
    filterType: FilterType,
    filters: SolutionFilterType
  ) => {
    if (filterType === "partitionWall") {
      return state.filters.partitionWalls;
    }
    if (filterType === "liningWall") {
      return state.filters.liningWalls;
    }
    return state.filters.ceilings;
  })(filterType, filters);
  return {
    selectionBuffer: state.selectionBuffer,
    filters: selectedFilters,
    language: state.language,
    systemDetailsVisible: state.systemDetailsVisible,
  };
};

const mapDispatchToProps = {
  showSystemDetails: showSystemDetails,
  hideSystemDetails: hideSystemDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  selectionBuffer: ProductDetailData | null;
  filters: any[];
  language: string;
  wording: {
    description: {
      [key: string]: string;
    };
  };
};

const row = (key: any, value: string) => {
  // Condition to remove layout possible from details popUP
  if (key !== "Layout possible") {
    return (
      <Table.Row key={key}>
        <Table.Cell style={{ padding: "5px 10px" }}>{key}</Table.Cell>
        <Table.Cell style={{ padding: "5px 10px" }}>{value}</Table.Cell>
      </Table.Row>
    );
  }
  return null;
};

export class SystemDetails extends React.Component<Props> {
  componentDidMount = () => {};

  public static defaultProps = {
    wording: {
      description: {
        French: "Description",
        English: "Description",
      },
    },
  };

  render() {
    const details = () => {
      if (this.props.selectionBuffer !== null) {
        let rows = this.props.filters.map((filter) =>
          row(
            filter.name,
            _.get(this.props.selectionBuffer, filter.pimAttribute)
          )
        );

        const {
          wording: { description },
          language,
        } = this.props;
        return (
          <Table celled>
            <Table.Body>
              {[
                row(
                  description[language],
                  this.props.selectionBuffer.descriptionTranslation
                ),
                ...rows,
              ]}
            </Table.Body>
          </Table>
        );
      }
      return (
        <Segment basic>
          <Dimmer inverted="true" active>
            <Loader />
          </Dimmer>
        </Segment>
      );
    };
    if (this.props.selectionBuffer === null) return null;
    return (
      <Modal open={this.props.systemDetailsVisible} dimmer="blurring">
        <Modal.Header style={{ fontSize: "1.2em" }}>
          {this.props.selectionBuffer.translation}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description
            style={{
              minHeight: "4em",
            }}
          >
            {details()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={(e) => {
              this.props.hideSystemDetails();
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>

      // <Dimmer active={this.props.systemDetailsVisible}>
      //   <Segment
      //     style={{ width: 850, padding: 20, height: 450, overflow: "auto" }}
      //   >
      //     <Header>{this.props.selectionBuffer.translation}</Header>
      //     {details()}

      //     <Button
      //       floated="right"
      //       color="green"
      //       onClick={(e) => {
      //         this.props.hideSystemDetails();
      //       }}
      //     >
      //       {" "}
      //       OK{" "}
      //     </Button>
      //   </Segment>
      // </Dimmer>
    );
  }
}

export default connector(SystemDetails);
