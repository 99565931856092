import {
  cleanContour,
  moveContour,
  moveContourBack,
} from "../../../../../Layout/helpers";
import {
  computeWallBasis,
  moveWallContours,
  pointRingX,
} from "../../../../../Layout/plt";
import { RevitMappedWall2 } from "../../../../../Layout/types";
import { api } from "../../../../../RevitJS/API";
import {
  computeLeftDirection,
  createLeftLocationLine,
  createRightLocationLine,
  setAlbaId,
} from "../../Actions/utils";

export const A02Handler = async (revitMappedWall: RevitMappedWall2) => {
  const {
    LocationLine,
    BoundingBox: { minPoint },
    OuterContour,
    InnerContours,
    Width,
    Id,
    AlbaStructure,
    LevelId,
  } = revitMappedWall as RevitMappedWall2;

  if (AlbaStructure) {
    let wallBasis = computeWallBasis(LocationLine, minPoint);
    let translation = {
      X: LocationLine.p1.X,
      Y: LocationLine.p1.Y,
      Z: minPoint.Z,
    };
    let movedContours = moveWallContours(
      OuterContour,
      InnerContours,
      translation,
      wallBasis
    );
    let leftDirection = computeLeftDirection(LocationLine);
    let leftContours = {
      OuterContour: OuterContour,
      InnerContours: InnerContours,
    };
    let rightContours = {
      OuterContour: OuterContour,
      InnerContours: InnerContours,
    };

    let movedLeftContours = moveWallContours(
      leftContours.OuterContour,
      leftContours.InnerContours,
      translation,
      wallBasis
    );
    let movedRightContours = moveWallContours(
      rightContours.OuterContour,
      rightContours.InnerContours,
      translation,
      wallBasis
    );
    movedLeftContours.OuterContour = cleanContour(
      movedLeftContours.OuterContour
    );
    movedRightContours.OuterContour = cleanContour(
      movedRightContours.OuterContour
    );

    if (revitMappedWall.Neighbours[0]) {
      if (revitMappedWall.Angle0) {
        let minX = Math.min(...movedContours.OuterContour.map((p) => p.X));

        if (revitMappedWall.Angle0 === 90) {
          let targetXLeft = revitMappedWall.Neighbours[0].Width / 2;
          let distLeft = targetXLeft - minX;
          movedLeftContours.OuterContour = pointRingX(
            movedLeftContours.OuterContour,
            minX,
            distLeft
          );
          let movedLeftOutterContourBack = moveContourBack(
            movedLeftContours.OuterContour,
            translation,
            wallBasis
          );
          leftContours.OuterContour = movedLeftOutterContourBack;
          let targetXRight = -revitMappedWall.Neighbours[0].Width / 2;
          let distRight = targetXRight - minX;
          movedRightContours.OuterContour = pointRingX(
            movedRightContours.OuterContour,
            minX,
            distRight
          );
          let movedRightOutterContourBack = moveContourBack(
            movedRightContours.OuterContour,
            translation,
            wallBasis
          );
          rightContours.OuterContour = movedRightOutterContourBack;
        }
        if (revitMappedWall.Angle0 === 270) {
          let targetXLeft = -revitMappedWall.Neighbours[0].Width / 2;
          let distLeft = targetXLeft - minX;
          movedLeftContours.OuterContour = pointRingX(
            movedLeftContours.OuterContour,
            minX,
            distLeft
          );
          let movedLeftOutterContourBack = moveContourBack(
            movedLeftContours.OuterContour,
            translation,
            wallBasis
          );
          leftContours.OuterContour = movedLeftOutterContourBack;
          let targetXRight = revitMappedWall.Neighbours[0].Width / 2;
          let distRight = targetXRight - minX;
          movedRightContours.OuterContour = pointRingX(
            movedRightContours.OuterContour,
            minX,
            distRight
          );
          let movedRightOutterContourBack = moveContourBack(
            movedRightContours.OuterContour,
            translation,
            wallBasis
          );
          rightContours.OuterContour = movedRightOutterContourBack;
        }
      }
    }
    if (revitMappedWall.Neighbours[1]) {
      if (revitMappedWall.Angle1) {
        let maxX = Math.max(...movedContours.OuterContour.map((p) => p.X));
        if (revitMappedWall.Angle1 === 90) {
          let targetXLeft =
            moveContour(
              [revitMappedWall.LocationLine.p2],
              translation,
              wallBasis
            )[0].X +
            Width / 2 -
            AlbaStructure[0].thickness;
          let distLeft = targetXLeft - maxX;
          movedLeftContours.OuterContour = pointRingX(
            movedLeftContours.OuterContour,
            maxX,
            distLeft
          );
          let movedLeftOutterContourBack = moveContourBack(
            movedLeftContours.OuterContour,
            translation,
            wallBasis
          );
          leftContours.OuterContour = movedLeftOutterContourBack;

          let targetXRight =
            moveContour(
              [revitMappedWall.LocationLine.p2],
              translation,
              wallBasis
            )[0].X +
            Width / 2 -
            AlbaStructure[0].thickness;
          let distRight = targetXRight - maxX;
          movedRightContours.OuterContour = pointRingX(
            movedRightContours.OuterContour,
            maxX,
            distRight
          );
          let movedRightOutterContourBack = moveContourBack(
            movedRightContours.OuterContour,
            translation,
            wallBasis
          );
          rightContours.OuterContour = movedRightOutterContourBack;
        } else if (revitMappedWall.Angle1 === 270) {
          let targetXLeft =
            moveContour(
              [revitMappedWall.LocationLine.p2],
              translation,
              wallBasis
            )[0].X +
            Width / 2 -
            AlbaStructure[2].thickness;
          let distLeft = targetXLeft - maxX;
          movedLeftContours.OuterContour = pointRingX(
            movedLeftContours.OuterContour,
            maxX,
            distLeft
          );
          let movedLeftOutterContourBack = moveContourBack(
            movedLeftContours.OuterContour,
            translation,
            wallBasis
          );
          leftContours.OuterContour = movedLeftOutterContourBack;
          let targetXRight =
            moveContour(
              [revitMappedWall.LocationLine.p2],
              translation,
              wallBasis
            )[0].X +
            Width / 2 -
            AlbaStructure[2].thickness;
          let distRight = targetXRight - maxX;
          movedRightContours.OuterContour = pointRingX(
            movedRightContours.OuterContour,
            maxX,
            distRight
          );
          let movedRightOutterContourBack = moveContourBack(
            movedRightContours.OuterContour,
            translation,
            wallBasis
          );
          rightContours.OuterContour = movedRightOutterContourBack;
        }
      }
    }
    if (AlbaStructure) {
      let leftLocationLine = createLeftLocationLine(
        LocationLine,
        Width,
        AlbaStructure[0].thickness,
        leftDirection
      );

      let rightLocationLine = createRightLocationLine(
        LocationLine,
        Width,
        AlbaStructure[2].thickness,
        leftDirection
      );

      let levelName = await api.queries.getLevelById(LevelId);
      let leftSide = await api.familyEditor.createWallByContour(
        { ...leftContours, NormalVector: revitMappedWall.NormalVector },
        leftLocationLine,
        AlbaStructure[0].name,
        levelName.Name,
        false
      );
      let rightSide = await api.familyEditor.createWallByContour(
        { ...rightContours, NormalVector: revitMappedWall.NormalVector },
        rightLocationLine,
        AlbaStructure[2].name,
        levelName.Name,
        false
      );
      await setAlbaId(leftSide, Id);
      await setAlbaId(rightSide, Id);
    }
  }
};
