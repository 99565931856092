import React, { useEffect } from "react";
import { Provider } from "react-redux";
import AppBody from "./appBody";
import store from "../../Store";
import { api } from "../../../../RevitJS/API";

export enum Routes {
  ROOT = "ROOT",
  NEW_CALEPINAGE = "NEW_CALEPINAGE",
  PARAMETERS = "PARAMETERS",
  CONTEXT_DE_LA_FACADE = "CONTEXT_DE_LA_FACADE",
  OPTIONS = "OPTIONS",
}

export const dbStoreNameFacadeCalepinage = "FacadeCalepinage"

const Root = () => {
  const [route, setRoute] = React.useState(Routes.ROOT);

  useEffect(() => {
    //api.windowsHandler.showDevTools();
  }, []);

  return (
    <Provider store={store}>
      <AppBody route={route} setRoute={setRoute} />
    </Provider>
  );
};

export default Root;
