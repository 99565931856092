import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectionStore } from "../Reducers";
import { Grid, Message } from "semantic-ui-react";
import { LevelsColumn } from "./LevelsColumn";
import { TypesColumn } from "./TypesColumn";
import { TypeData, LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { selectAllLevel, selectAllType } from "../Actions";
import { selectorTp } from "../Actions/types";
import _ from "lodash";
import { bimStorage, storageKey } from "../../../../BIMStore";

interface Props {
  wording: {
    ceilingTypes: { [key: string]: string };
    wallTypes: { [key: string]: string };
    levels: { [key: string]: string };
    wall: { [key: string]: string };
    ceiling: { [key: string]: string };
  };
  language: string;
  selectorType: selectorTp;
  wallTypesData: TypeData[];
  wallLevelsData: LevelData[];
  wallSelectedLevels: string[];
  ceilingTypesData: TypeData[];
  ceilingLevelsData: LevelData[];
  ceilingSelectedLevels: string[];
  selectAllLevel: any;
  selectAllType: any;
  wallTree: any;
  ceilingTree: any;
  selections: any;
  projectId: string;
  isZone: boolean | undefined | null;
  selectionGroup: any;
  placoSolutions: any;
  config: any;
}
interface State {}

export class GroupSelectorBody extends Component<Props, State> {
  state = {
    wallLevelsData: this.props.wallLevelsData,
    ceilingLevelsData: this.props.ceilingLevelsData,
    placoSolutions: [],
  };

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
      },
      ceilingTypes: {
        French: "Types de plafonds",
        English: "Ceiling types",
      },
      wallTypes: {
        French: "Types de murs",
        English: "Wall types",
      },
      ceiling: {
        French: "plafond",
        English: "Ceiling",
      },
      wall: {
        French: "mur",
        English: "Wall",
      },
    },
  };

  selectorTypes = () => {
    switch (this.props.selectorType) {
      case "wall":
        return this.props.wording.wallTypes[this.props.language];
      case "ceiling":
        return this.props.wording.ceilingTypes[this.props.language];
      default:
        return this.props.wording.wallTypes[this.props.language];
    }
  };

  typesSelector = () => {
    switch (this.props.selectorType) {
      case "wall":
        return this.props.wording.wall[this.props.language];
      case "ceiling":
        return this.props.wording.ceiling[this.props.language];
      default:
        return this.props.wording.wall[this.props.language];
    }
  };

  componentDidMount = async () => {
    // Check if current selection is zone
    let usedWallsIds: any;
    let usedCeilingsIds: any;
    const placoSelections = await bimStorage.getItem(
      storageKey.PLACOSELECTIONS
    );

    this.setState({ placoSolutions: placoSelections });

    if (this.props.isZone) {
      // Get all the ceilings id from the saved selection of the curent project
      usedCeilingsIds = Object.values(this.props.selections).reduce(
        (a: any, e: any) => {
          if (e.RevitView === this.props.projectId && e.Zone) {
            e.SelectionByType.ceiling.Rows.forEach((ceiling: any) => {
              a.push(ceiling.RevitSelection.Ids);
            });
          }
          return a;
        },
        []
      );

      // get all the walls id from the save selection of the current project
      usedWallsIds = Object.values(this.props.selections).reduce(
        (a: any, e: any) => {
          if (e.RevitView === this.props.projectId && e.Zone) {
            e.SelectionByType.wall.Rows.forEach((wall: any) => {
              a.push(wall.RevitSelection.Ids);
            });
          }
          return a;
        },
        []
      );
    } else {
      usedWallsIds = [];
      usedCeilingsIds = [];
      this.props.selectionGroup.SelectionByType.wall.Rows.forEach(
        (element: any) => {
          let ids = element.RevitSelection.Ids;
          usedWallsIds.push(ids);
        }
      );

      this.props.selectionGroup.SelectionByType.ceiling.Rows.forEach(
        (element: any) => {
          let ids = element.RevitSelection.Ids;
          usedCeilingsIds.push(ids);
        }
      );
    }

    // function to check if all the wall/ceilings id already exist in the already saved selections
    const checkIftypeExistOnLevel = (type: string, typeIds: any[]) => {
      if (type === "wall") {
        if (_.difference(typeIds, _.flatten(usedWallsIds)).length > 0) {
          return false;
        }
        return true;
      }
      if (type === "ceiling") {
        if (_.difference(typeIds, _.flatten(usedCeilingsIds)).length > 0) {
          return false;
        }
        return true;
      }
    };

    // initialize new blank array to save updated floors
    let updatedWallLevels: any = [];
    let updatedCeilingLevels: any = [];

    // Map over Floor to check if all walls are already used by other saved/zoned selections
    this.props.wallLevelsData.forEach((wall: any) => {
      const LevelName = wall.Name;
      const wallTree = this.props.wallTree;
      let getCurrentWallIds = wallTree.reduce((allWalls: any, level: any) => {
        if (level.Level.Name === LevelName) {
          const getIdsofWalls = level.Elements.reduce((a: any, c: any) => {
            a.push(c.Ids);
            return a;
          }, []);
          allWalls.push(getIdsofWalls);
        }
        return allWalls;
      }, []);

      if (!checkIftypeExistOnLevel("wall", _.flattenDeep(getCurrentWallIds))) {
        updatedWallLevels.push(wall);
      }
    });

    // Map over Floor to check if all ceilings are already used by other saved/zoned selections
    this.props.ceilingLevelsData.forEach((ceiling: any) => {
      const LevelName = ceiling.Name;
      const ceilingTree = this.props.ceilingTree;
      let getCurrentCeilingIds = ceilingTree.reduce(
        (allCeilings: any, level: any) => {
          if (level.Level.Name === LevelName) {
            const getIdsofCeilings = level.Elements.reduce((a: any, c: any) => {
              a.push(c.Ids);
              return a;
            }, []);
            allCeilings.push(getIdsofCeilings);
          }
          return allCeilings;
        },
        []
      );

      if (
        !checkIftypeExistOnLevel("ceiling", _.flattenDeep(getCurrentCeilingIds))
      ) {
        updatedCeilingLevels.push(ceiling);
      }
    });

    // set the updated floors to the state
    this.setState({
      wallLevelsData: updatedWallLevels,
      ceilingLevelsData: updatedCeilingLevels,
    });
  };

  filterTypesData = (): TypeData[] => {
    let wallTypesData: TypeData[] = [];
    let ceilingTypesData: TypeData[] = [];
    if (!this.props.isZone) {
      if (this.props.selectorType === "wall") {
        const selectedTypes =
          this.props.selectionGroup.SelectionByType.wall.Rows;
        if (selectedTypes && selectedTypes.length > 0) {
          this.props.wallTypesData.forEach((element: any) => {
            const systemSelected = selectedTypes.find(
              (s: any) => s.RevitSelection.RevitType === element.Type
            );
            if (!systemSelected) {
              wallTypesData.push(element);
            } else {
              var ids = element.Ids.filter(
                (id: any) => !systemSelected.RevitSelection.Ids.includes(id)
              );
              if (systemSelected && ids && ids.length > 0) {
                element.Ids = ids;
                element.Count = ids.length;
                wallTypesData.push(element);
              }
            }
          });
          return wallTypesData;
        }
        return this.props.wallTypesData;
      } else if (this.props.selectorType === "ceiling") {
        const selectedTypes =
          this.props.selectionGroup.SelectionByType.ceiling.Rows;
        if (selectedTypes && selectedTypes.length > 0) {
          this.props.ceilingTypesData.forEach((element: any) => {
            const systemSelected = selectedTypes.find(
              (s: any) => s.RevitSelection.RevitType === element.Type
            );
            if (!systemSelected) {
              ceilingTypesData.push(element);
            } else {
              var ids = element.Ids.filter(
                (id: any) => !systemSelected.RevitSelection.Ids.includes(id)
              );
              if (systemSelected && ids && ids.length > 0) {
                element.Ids = ids;
                element.Count = ids.length;
                ceilingTypesData.push(element);
              }
            }
          });
          return ceilingTypesData;
        }
        return this.props.ceilingTypesData;
      }
    } else if (this.props.isZone) {
      return this.getWallsAndCeilingsForZones();
    }
    return [];
  };

  getWallsAndCeilingsForZones = (): TypeData[] => {
    let wallTypesData: TypeData[] = [];
    let ceilingTypesData: TypeData[] = [];
    // get all placoData
    //const placoSelections = await bimStorage.getItem(storageKey.PLACOSELECTIONS);

    if (this.state.placoSolutions) {
      // get only currentl project data
      const currentPlacoSelection = this.getCurrentZoneSelections(
        this.state.placoSolutions
      );

      // // check if wall exist in other zone
      // const wallExist = this.checkIfTypeExistInOtherZone(
      //   currentPlacoSelection,
      //   "wall"
      // );

      // // check if ceiling exist in other zone
      // const ceilingExist = this.checkIfTypeExistInOtherZone(
      //   currentPlacoSelection,
      //   "ceiling"`
      // );

      // now to delete the duplicate wall or ceiling first clone the current buffer
      let tempWalls = _.cloneDeep(this.props.wallTypesData);
      let tempCeilings = _.cloneDeep(this.props.ceilingTypesData);

      // function to check if wall id exist in other zones and return true or false
      const checkIfTypeIdExistInOtherZone = (type: any, id: any) => {
        let currentPlacoType = currentPlacoSelection
          ? currentPlacoSelection.map((a: any) => {
              return a.SelectionByType[type].Rows.map(
                (e: any) => e.RevitSelection.Ids
              );
            })
          : [];
        currentPlacoType = _.flattenDepth(currentPlacoType, 2);

        const typeExist = currentPlacoType.includes(id);

        return typeExist;
      };

      if (tempWalls && tempWalls.length > 0) {
        // get only unique walls
        const tempBufferWalls = tempWalls.map((e: any) => {
          return {
            ...e,
            RevitSelection: {
              ...e,
              Ids: e.Ids.filter((a: any) => {
                return !checkIfTypeIdExistInOtherZone("wall", a);
              }),
            },
          };
        });

        // remove walls, if no walls remaining after removing duplicate delte the wallRow
        const tempWallNoBlank: TypeData[] = tempBufferWalls.filter((e: any) => {
          return e.RevitSelection.Ids.length > 0;
        });

        wallTypesData = tempWallNoBlank;
      }

      if (tempCeilings && tempCeilings.length > 0) {
        // get only unique ceilings
        const tempBufferCeilings = tempCeilings.map((e: any) => {
          return {
            ...e,
            RevitSelection: {
              ...e,
              Ids: e.Ids.filter((a: any) => {
                return !checkIfTypeIdExistInOtherZone("ceiling", a);
              }),
            },
          };
        });

        // remove celining, if no ceiling remaining after removing duplicate delete the ceilingRow
        const tempCeilingNoBlank = tempBufferCeilings.filter((e: any) => {
          return e.RevitSelection.Ids.length > 0;
        });

        ceilingTypesData = tempCeilingNoBlank;
      }

      if (this.props.selectorType === "wall") {
        return wallTypesData;
      } else if (this.props.selectorType === "ceiling") {
        return ceilingTypesData;
      }
    }
    return [];
  };

  getCurrentZoneSelections = (placoSelections: any) => {
    if (placoSelections) {
      return Object.values(placoSelections).filter((e: any) => {
        return (
          e.RevitView === this.props.projectId &&
          //e.Id !== this.props.bufferSelection.Id &&
          e.Zone
        );
      });
    }
  };

  checkIfTypeExistInOtherZone = (currentPlacoSolutions: any, type: string) => {
    let currentPlacoType = currentPlacoSolutions?.map((a: any) => {
      return a.SelectionByType[type].Rows.map((e: any) => e.RevitSelection.Ids);
    });
    currentPlacoType = _.flattenDepth(currentPlacoType, 2);

    let currentSelectionIds: any;
    if (type === "wall") {
      currentSelectionIds = this.props.wallTypesData.map((e: any) => e.Ids);
    } else if (type === "ceiling") {
      currentSelectionIds = this.props.ceilingTypesData.map((e: any) => e.Ids);
    }
    currentSelectionIds = _.flatten(currentSelectionIds);

    const typeExist = currentSelectionIds.some((e: any) => {
      return currentPlacoType.includes(e);
    });

    return typeExist;
  };

  render() {
    const {
      wording: { levels },
      language,
      selectorType,
      wallTypesData,
      ceilingTypesData,
      wallSelectedLevels,
      ceilingSelectedLevels,
      selectionGroup,
    } = this.props;

    return (
      <div style={{ width: "100%", backgroundColor: "none" }}>
        <div>
          {selectorType === "wall" ? (
            this.state.wallLevelsData.length > 0 &&
            this.filterTypesData().length > 0 ? (
              <Grid columns={2} stackable style={{ backgroundColor: "none" }}>
                <LevelsColumn
                  color="blue"
                  columnTitle={levels[language]}
                  levelsData={this.state.wallLevelsData}
                  selectAllLevel={this.props.selectAllLevel}
                />
                <TypesColumn
                  columnTitle={this.selectorTypes()}
                  color="blue"
                  selectedLevels={wallSelectedLevels}
                  typesData={this.filterTypesData()}
                  selectAllType={this.props.selectAllType}
                />
              </Grid>
            ) : (
              <Message warning style={{ textAlign: "center" }}>
                Aucun élément de type {this.typesSelector()} n'est dessiné dans
                le projet
              </Message>
            )
          ) : this.state.ceilingLevelsData.length > 0 &&
            this.filterTypesData().length > 0 ? (
            <Grid columns={2} stackable style={{ backgroundColor: "none" }}>
              <LevelsColumn
                color="blue"
                columnTitle={levels[language]}
                levelsData={this.state.ceilingLevelsData}
                selectAllLevel={this.props.selectAllLevel}
              />
              <TypesColumn
                columnTitle={this.selectorTypes()}
                color="blue"
                selectedLevels={ceilingSelectedLevels}
                typesData={this.filterTypesData()}
                selectAllType={this.props.selectAllType}
              />
            </Grid>
          ) : (
            <Message warning style={{ textAlign: "center" }}>
              Aucun élément de type {this.typesSelector()} n'est dessiné dans le
              projet
            </Message>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { selectorType: selectorTp }
) => ({
  language: state.language,
  selectorType: ownProps.selectorType,
  wallLevelsData: state.wallLevelsData,
  wallTypesData: state.wallTypesData,
  ceilingLevelsData: state.ceilingLevelsData,
  ceilingTypesData: state.ceilingTypesData,
  wallSelectedLevels: state.wallSelectedLevels,
  ceilingSelectedLevels: state.ceilingSelectedLevels,
  wallTree: state.wallTree.Tree,
  ceilingTree: state.ceilingTree.Tree,
  selections: state.selections,
  projectId: state.projectData.ProjectId,
  isZone: state.bufferSelection?.Zone,
  selectionGroup: state.bufferSelection,
  placoSolutions: [],
  config: state.config,
});

const mapDispatchToProps = {
  selectAllLevel: selectAllLevel,
  selectAllType: selectAllType,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelectorBody);
