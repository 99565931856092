import React, { useEffect, useState } from "react";
import { Card, Image, Button } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { generalizeLanguage, MyConfig } from "../../../../Helper";
import { api } from "../../../../RevitJS/API";
import { Language } from "../../../../RevitJS/Types/RevitTypes";

type Props = {};

export const LicenseUI = (props: Props) => {
  let lang: any = localStorage.getItem("savedLanguage");
  const applicationLanguage: Language = generalizeLanguage(lang);
  const [ErrorMessage, setErrorMsg] = useState("");
  const [pluginName, setpluginName] = useState("");

  useEffect(() => {
    let pluginName: any = localStorage.getItem("TechnicalName");
    setpluginName(pluginName);
    async function getErrorMsg() {
      let message = await bimStorage.getItem(storageKey.LICENSE_ERROR_MESSAGE);

      if (message) {
        setErrorMsg(message);
      }
    }
    getErrorMsg();
  }, []);

  return (
    <>
      {ErrorMessage !== "" ? (
        <Card
          style={{
            padding: "0px 15px",
            boxShadow: "none",
            margin: "0 !important",
            width: "100%",
          }}
        >
          <Card.Content style={{ textAlign: "center" }}>
            <Image
              src={`/LicensePluginLogos/` + pluginName + `.png`}
              style={{ width: 155, height: 62 }}
            />
            <Card.Header
              style={{
                color: "#1c5dab",
                textAlign: "center",
                marginTop: "18px",
              }}
            >
              {wording.header[applicationLanguage]}
            </Card.Header>

            <Card.Description
              style={{
                textAlign: "center",
                wordWrap: "break-word",
                color: "red",
              }}
            >
              {ErrorMessage}
            </Card.Description>
            <div style={{ margin: "30px 0px 0px 0px", textAlign: "center" }}>
              <Button
                onClick={() => {
                  api.windowsHandler.closeWindow();
                }}
              >
                {wording.cancel[applicationLanguage]}
              </Button>
            </div>
          </Card.Content>
        </Card>
      ) : null}
    </>
  );
};

const wording: WordingType = {
  header: {
    French: "Vérification de licence",
    English: "License verification",
    German: "Lizenzüberprüfung",
    Italian: "Verifica della licenza",
    Indonesian: "License verification",
  },
  cancel: {
    French: "Annuler",
    English: "Cancel",
    German: "Stornieren",
    Italian: "Annulla",
    Indonesian: "Cancel",
  },
};

type WordingType = {
  header: LanguageType;
  cancel: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
  German: string;
  Italian: string;
  Indonesian: string;
};

export default LicenseUI;
