import { Checkbox } from "@material-ui/core";
import React, { UIEvent, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import AnimatedCheckbox from "../../../common/animated-checkbox/AnimatedCheckbox";

function SystemName({
    systemNamesList = [],
    handleSystemNameSelected,
    setAllSystemNamesSelected,
    t,
}: IProps) {
    const [nbrElementsToShow, setNbrElementsToShow] = useState(20);

    const handleScroll = (e: UIEvent) => {
        const closeToBottom =
            e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                e.currentTarget.clientHeight ===
            0;
        if (closeToBottom) {
            setNbrElementsToShow(nbrElementsToShow + 20);
        }
    };

    return (
        <>
            <div className="item-selection-table-div" onScroll={handleScroll}>
                <table className="w-100">
                    <tbody>
                        {systemNamesList.map((item: any, itemIndex: number) => {
                            return (
                                <tr key={`${item.value}-${itemIndex}`}>
                                    <td style={{ width: "10%" }}>
                                        <Checkbox
                                            checked={item.selected}
                                            onChange={(event) => {
                                                handleSystemNameSelected(
                                                    event.currentTarget.checked,
                                                    item.value
                                                );
                                            }}
                                        ></Checkbox>
                                    </td>
                                    <td
                                        style={{
                                            width: "90%",
                                            textAlign: "left",
                                        }}
                                    >
                                        {item.value}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div style={{ width: "max-content", margin: "10px auto" }}>
                <AnimatedCheckbox
                    label={""}
                    changed={(event: any) => {
                        setAllSystemNamesSelected(event.currentTarget.checked);
                    }}
                ></AnimatedCheckbox>
            </div>
        </>
    );
}

type IProps = WithTranslation & {
    systemNamesList: { value: string; selected: boolean }[];
    handleSystemNameSelected: Function;
    setAllSystemNamesSelected: Function;
};

export default withTranslation(["pam"])(SystemName);
