import {
  find,
  filter,
  map,
  remove,
  isEmpty,
  round,
  startsWith,
  flattenDeep,
  keysIn,
  includes,
  pickBy,
  groupBy,
  uniq,
  orderBy,
  forEach,
} from "lodash";
import {
  montantDefault,
  plaqueDefault,
} from "../../Calpinage/Components/Solution/TableRow";

export const processNewWallData = (
  preWallData: any,
  extractedSolutionProduct: any[]
) => {
  return map(preWallData, (wallObject: any, index: number) => {
    const extractedSolutionProducts = find(
      extractedSolutionProduct,
      function (o: any) {
        return o.solutionProduct.oid === wallObject.PlacoSolutionId;
      }
    );

    let fetIsLayout: boolean = true,
      fetIsPlaco: boolean = true,
      fetIsChk: boolean = true,
      plaqsArray: any[] = [],
      montantsArray: any[] = [];

    plaqsArray = filter(extractedSolutionProducts?.products, {
      type: "plaque",
    });

    map(plaqsArray, (plq, index) => {
      remove(plq.articles, function (n: any) {
        return startsWith(n.value, "PPM");
      });
      return plq;
    });
    montantsArray = filter(extractedSolutionProducts?.products, {
      type: "montant",
    });
    if (
      !wallObject.PlacoSolutionId.includes("custom") &&
      extractedSolutionProducts?.layoutPossible.toLowerCase() === "true"
    ) {
      fetIsLayout = true;
      fetIsPlaco = true;

      if (isEmpty(wallObject.plaque)) {
        wallObject.plaque = plaqsArray.map((item) =>
          plaqueDefault(
            item.articles,
            `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
          )
        );
      }

      if (isEmpty(wallObject.montant)) {
        wallObject.montant = montantsArray.map((item) =>
          montantDefault(
            item.articles,
            `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
          )
        );
      }
    } else {
      fetIsLayout = false;
      if (wallObject.PlacoSolutionId.includes("custom")) {
        fetIsChk = false;
        fetIsPlaco = false;
        wallObject.PlacoSolutionHeight = "";
        wallObject.plaque = [];
        wallObject.montant = [];
      } else {
        if (isEmpty(wallObject.plaque)) {
          wallObject.plaque = plaqsArray.map((item) =>
            plaqueDefault(
              item.articles,
              `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
            )
          );
        }

        if (isEmpty(wallObject.montant)) {
          wallObject.montant = montantsArray.map((item) =>
            montantDefault(
              item.articles,
              `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
            )
          );
        }
      }
    }

    wallObject.layoutPossible = fetIsLayout;
    wallObject.placo = fetIsPlaco;
    wallObject.plaqueArray = plaqsArray;
    wallObject.montantArray = montantsArray;
    wallObject.installationType = extractedSolutionProducts?.installationType;
    wallObject.supportCeiling = extractedSolutionProducts?.supportCeiling;
    wallObject.chk = wallObject.chk === undefined ? fetIsChk : wallObject.chk;

    if (wallObject.installationType && wallObject.supportCeiling) {
      wallObject.x = wallObject.x ? wallObject.x : "";
      wallObject.y = wallObject.y ? wallObject.y : "";
    }

    return wallObject;
  });
};
