import { Plugin } from "../../RevitJS/Types/StoreTypes";
import {Test} from "../GenericPlugin/TestPlugin"

export const pluginId = "992e2487-e768-4b71-b9d7-24bb7378531f";
export const functionalityId = "ef91f48a-3fff-4dc7-947b-b3023e62c401";

export const GenericPlugin: Plugin = {
    TechnicalName: "Generic Test Plugin",
    Name: "",
    Icon: "/generic-testing.jpg",
    ShortDesc: {
      French: "Genric tool for Testing pluigins",
      English: "Genric tool for Testing pluigins",
      German: "Genric tool for Testing pluigins",
      Italian: "Genric tool for Testing pluigins",
      Indonesian: "Genric tool for Testing pluigins",
    },
    Id: pluginId,
    Color: "#005EB8",
    Functionalities: [
        Test
    ],
  };