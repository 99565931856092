import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { ColorizationRoot } from "./Components/root";
import React from "react";

export const functionalityId = "a3d5f5cd-0485-4135-918f-48901ff8b600";

const colorizationTrigger = (config: any) =>
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

export const RiggipsColorization = () => {
  return <ColorizationRoot />;
};

export const Colorization: Functionality = {
  Name: { French: "Colorer", English: "Color" },
  Trigger: colorizationTrigger,
  ShortDesc: {
    French: "Repérer les plaques",
    English: "Identify the plasterboards",
  },
  Id: functionalityId,
  RootComponent: ColorizationRoot,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Colorisation.png",
  ParentId: "0",
};
