import _, {
  ceil,
  filter,
  find,
  includes,
  orderBy,
  round,
  startsWith,
} from "lodash";
import {
  fetchPlacoSolutionsByTechnicalNames,
  fetchPlacoSolutionsDetailsWithRelatedChildren,
} from "../../../../API";
import { api } from "../../../../RevitJS/API";
import Excel from "exceljs";
import {
  addGlobalAccessoryInSummarySheet,
  addGlobalMontantInSummarySheet,
  addGlobalPlqsInSummarySheet,
  addGlobalRailInSummarySheet,
  addGlobalWoolInSummarySheet,
  addImagetoWorkbook,
  alternativeUnit,
  alternativeUnitsSelector,
  coatingList,
  dbSalesUnit,
  distributionChannelsAttributeSelector,
  findProductToProduct,
  getAttValue,
  newConditionedQuantityForMontant,
  newConditionedQuantityForPlaq,
  newConditionedQuantityForRail,
  numeralConversion,
  oneToOneCommandeMapping,
  packageAttributeSelector,
  printNullOrZero,
  printSummaryDetailRow,
  relationAttributeSelector,
  screwList,
  setColumnType,
  setDossierTechnicalInfo,
  setSolidFill,
  uniteDeVenteArray,
  uniteDeVenteToUnit,
  unitTobePrinted,
} from "./helper";
import { woolType } from "./type";
import { setCellAlignment, setCellBorder, setCellFont } from "./excelUI";
import {
  Accessory,
  AccessoryArticle,
  DetailCommande,
  GlobalCommande,
  GlobalSummary,
  Montant,
  MontantArticle,
  PlaqArticle,
  Plaqs,
  PlaqSummary,
  RailArticle,
  Rails,
  SummaryDetail,
  SummaryGroupDetail,
} from "../Actions/types";
import {
  extractFramesDatafromRevit,
  extractPlasterboardDataForWallfromRevit,
  extractRailsDatafromRevit,
} from "./revit";
import { fetchPlacoDataInGroupSplit } from "../../Calpinage/Helpers/placoHelper";
import { saveAs } from "file-saver";

export async function asyncForEach(array: any, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export const writeOptimizedBooks = async (
  props: any,
  getUnitConversionArray: any,
  t: any,
  setWarning: any,
  config: any
) => {
  props.setWallScheduleLoader(true);

  const processedWallIds: any[] = await api.queries.filterElements(
    "Wall",
    [
      {
        Param: { Name: "Processed", Type: "Boolean", Value: true },
        Rule: "Equals",
      },
    ],
    null
  );

  /// 9. Get object params (i.e. ["Processed", "SG_System"]) of wall

  if (processedWallIds.length > 0) {
    let processedWallParams = await api.queries.getObjectsParams(
      processedWallIds,
      ["SG_System", "Plaque", "Montant"]
    );

    /// 10. Filtered out non-layout
    processedWallParams = processedWallParams.filter((element: any) => {
      return element.Params.find(
        (para: any) => para.Value !== null && para.Name !== "Plaque"
      );
    });

    const filteredProcessIds = processedWallParams.map(
      (element: any) => element.Id
    );

    if (filteredProcessIds.length > 0) {
      /// 11. Take wall ids from processed walls params

      /// 12. Get walls data of processed wall ids
      let wallsData = await api.queries.getWallsData(filteredProcessIds);

      let processedSolution = _.uniq(
        _.map(processedWallParams, "Params[0].Value")
      );
      /// 16. Fetch placo solutions from api database
      const { data } = await fetchPlacoSolutionsByTechnicalNames(
        processedSolution,
        config
      );

      /// 17. Extract solution ids from fetch data
      const solutionOids: string[] = _.map(data.objects, "oid");

      /// 18. Fetch solution details with child and parent
      ///     Extract products, solution products and articles
      const { extractedSolutionProduct } = await fetchPlacoDataInGroupSplit(
        solutionOids,
        config
      );

      const extractedArticlesIds = _.uniq(
        _.reduce(
          extractedSolutionProduct,
          function (result, solutionProduct) {
            _.map(solutionProduct.products, (product, prIndex) => {
              result = result.concat(product.oid);
              // _.map(product.articles, (articleObj, arIndex) => {
              //   result = result.concat(articleObj.article.oid);
              // });
            });

            return result;
          },
          []
        )
      );
      // const packagedArticles =
      //   await fetchPlacoSolutionsDetailsWithRelatedChildren(extractedArticlesIds);

      let finalExtract: any = [];

      await _.forEach(processedWallParams, (wall, index) => {
        const wallDetails = _.find(wallsData, { Id: wall.Id });
        const placoSolution = _.find(extractedSolutionProduct, (slProduct) => {
          return (
            slProduct.solutionProduct.technicalName === wall.Params[0].Value
          );
        });

        const wools = _.filter(placoSolution.products, (prd) => {
          return prd.type === "wool";
        });

        const accessories = _.filter(placoSolution.products, (prd) => {
          return (
            prd.type === "coating" ||
            prd.type === "joints" ||
            prd.type === "resilent" ||
            prd.type === "screw"
          );
        });

        const plaques = _.filter(placoSolution.products, (prd) => {
          return prd.type === "plaque";
        });
        const montants = _.filter(placoSolution.products, (prd) => {
          return prd.type === "montant";
        });
        const rails = _.filter(placoSolution.products, (prd) => {
          return prd.type === "rail";
        });

        finalExtract.push({
          wallDetails: wallDetails,
          placoSolution: placoSolution.solutionProduct,
          wools: wools,
          accessories: accessories,
          plaques: plaques,
          montants: montants,
          rails: rails,
          layoutPossible: placoSolution.layoutPossible,
          attributes: placoSolution.attributes,
          relationAttributes: placoSolution.relationAttributes,
          relations: placoSolution.relations,
        });
      });
      const workbook = new Excel.Workbook();

      let blob = await fetch(
        config.REACT_APP_SERVERURL + "/Excel/quantitatiff_per_etage_old.xlsx"
      ).then((response) => response.arrayBuffer());

      await workbook.xlsx.load(blob).then(async (workbookObject) => {
        // Wool

        const logoImageId = await addImagetoWorkbook(workbook);

        const woolSummary = await writeWool(
          workbookObject,
          logoImageId,
          finalExtract
        );

        const accessorySummary = await writeAccessory(
          workbookObject,
          logoImageId,
          finalExtract,
          // packagedArticles,
          props
        );

        const railSummary = await writeRail(
          workbookObject,
          logoImageId,
          finalExtract
        );

        const montantSummary = await writeMontant(
          workbookObject,
          logoImageId,
          finalExtract
        );

        const plaqueSummary = await writePlaques(
          workbookObject,
          logoImageId,
          finalExtract
        );

        const detailSummary = await writeSummary(
          workbookObject,
          finalExtract,
          plaqueSummary,
          montantSummary,
          railSummary,
          woolSummary,
          accessorySummary,
          props,
          logoImageId
        );

        let unitConversionArray = getUnitConversionArray();

        await writeCommandeNew(
          workbook,
          detailSummary,
          unitConversionArray,
          logoImageId
        );

        const buf = await workbookObject.xlsx.writeBuffer();

        const documentName = await api.queries.getActiveDocumentName();
        const d = new Date();
        let monthNumber = d.getMonth() + 1;
        let month =
          monthNumber.toString().length === 1 ? "0" + monthNumber : monthNumber;
        let dayNumber = d.getDate();
        let day =
          dayNumber.toString().length === 1 ? "0" + dayNumber : dayNumber;
        const fileName = `${d.getFullYear()}_${month}_${day}_${documentName}_Quantitatifs_par_étage`;

        props.setWallScheduleLoader(false);
        window.addEventListener("blur", () => {
          api.windowsHandler.closeWindow();
        });

        saveAs(new Blob([buf]), `${fileName}.xlsx`);
        sendEvent();
      });

      props.setWallScheduleLoader(false);
    } else {
      props.setWallScheduleLoader(false);
      setWarning(t("PER_FLOOR_WARNING"));
    }
  } else {
    props.setWallScheduleLoader(false);
    setWarning(t("PER_FLOOR_WARNING"));
  }
};

const sendEvent = async () => {
  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Quantitatifs",
    module: "PLACOBIM",
  });
};

const writeWool = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  finalExtract: any
): Promise<any> => {
  let woolSheet = workbookObject.getWorksheet("Laine");
  // logoImageId !== 0 && woolSheet.addImage(logoImageId, {

  // });
  logoImageId !== 0 &&
    woolSheet.addImage(logoImageId, {
      tl: { col: 7, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(woolSheet, "C", "G");
  let woolCollection: woolType[] = [];
  _.forEach(finalExtract, (extractObj, extractIndex) => {
    const woolKeySearchArray = ["PAR", "GR 32", "Isoconfort", "Laine"];

    extractObj.wools.forEach((element: any) => {
      const woolAcceptable = find(woolKeySearchArray, (o: any) => {
        return startsWith(element.product.translation, o);
      });

      const gfrSellingPackaging = find(element.product.attributes, {
        technicalName: "GFR-GP Selling Packaging",
      });
      let sellingPackage = "0";
      if (gfrSellingPackaging) {
        sellingPackage = gfrSellingPackaging.values[0].value;
      }

      const gfrLabelUnit = find(element.product.attributes, {
        technicalName: "GFR-Label unit (UE)",
      });
      let labelUnit = "";
      if (gfrLabelUnit) {
        labelUnit = gfrLabelUnit.values[0].value;
      }

      const gfrEan = find(element.product.attributes, {
        technicalName: "GFR-EAN code ISOVER article",
      });

      let ean = "";
      if (gfrEan) {
        ean = gfrEan.values[0].value;
      }

      if (woolAcceptable) {
        const includeLainDEVerre = includes(
          element.product.translation,
          "Laine de verre"
        );

        if (!includeLainDEVerre) {
          let woolRow: woolType = {
            Level: extractObj.wallDetails.LevelName,
            LevelElevation: extractObj.wallDetails.LevelElevation,
            Ovurage: extractObj.placoSolution.translation,
            ProductName: element.product.translation,
            WallHeight: numeralConversion(
              round(extractObj.wallDetails.Height / 1000)
            ),
            WallArea: round(extractObj.wallDetails.Area, 2),
            Ratio: 1.05,
            WoolArea: round(extractObj.wallDetails.Area * 1.05, 2),
            Rolls: "",
            WallType: extractObj.wallDetails.WallType,
            WallId: extractObj.wallDetails.Id,
            SellingPackage: sellingPackage,
            LabelUnit: labelUnit,
            Ean: ean,
          };

          woolCollection.push(woolRow);
        }
      }
    });
  });

  /**
   * Sort wools by level -> solution
   */
  let woolArray: woolType[] = _.orderBy(
    woolCollection,
    ["LevelElevation", "Solution", "WallArea"],
    ["asc", "asc", "desc"]
  );

  ///////////////////

  let woolSummary: any = [];
  if (woolArray.length > 0) {
    let rowNumber = 16;
    _.forEach(woolArray, (wool: woolType, index: number) => {
      let row = woolSheet.getRow(rowNumber);
      let qColumn = round(wool.WoolArea / parseFloat(wool.SellingPackage), 2);

      row.values = [
        wool.Level,
        wool.Ovurage,
        printNullOrZero(wool.WallHeight),
        printNullOrZero(wool.WallArea),
        wool.WallId,
        wool.ProductName,
        printNullOrZero(wool.Ratio),
        printNullOrZero(wool.WoolArea),
        // wool.Rolls,
        qColumn,
        wool.LabelUnit.split(",")[1],
      ];

      row.eachCell(function (cell: any, colNumber: number) {
        let num: any = cell.value;

        if (num === "NA") {
          cell.value = "";
        } else {
          setCellAlignment(cell, "center", "middle");
        }

        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 9, false, "black");

        if (colNumber === 5) {
          setColumnType(cell);
        }
      });

      row.commit();

      woolSummary.push({
        level: wool.Level,
        productName: wool.ProductName,
        quantity: round(wool.WallArea * wool.Ratio, 2),
        unite: "M2",
        quantityConditioned: 0,
        saleUnit: "",
        solution: wool.Ovurage,
        walltype: wool.WallType,
        sellingPackage: wool.SellingPackage,
        labelUnit: wool.LabelUnit.split(",")[1],
        ean: wool.Ean,
      });

      rowNumber = rowNumber + 1;
    });
  }
  return woolSummary;
};

const writeAccessory = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  finalExtract: any,
  props: any
): Promise<any> => {
  const primaryObject: any = {};

  let accessorySheet = workbookObject.getWorksheet("Accessoires");
  //logoImageId !== 0 && accessorySheet.addImage(logoImageId, "I1:K3");
  logoImageId !== 0 &&
    accessorySheet.addImage(logoImageId, {
      tl: { col: 8, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(accessorySheet, "C", "G");
  _.forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const wallId = extractObj.wallDetails.Id;

    _.forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 1) {
          const selectedArticle = _.find(accessoryObj.articles, function (o) {
            return (
              listToFind.findIndex((account: string) => {
                return account.startsWith(o.packagedArticle.translation);
              }, o.packagedArticle.translation) > -1
            );
          });
          if (selectedArticle) {
            selectedAccessoryPackagedArticle = selectedArticle.packagedArticle;
          }
        } else if (_.startsWith(accessoryObj.articles[0].translation, "Vis")) {
          if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        } else {
          selectedAccessoryPackagedArticle =
            accessoryObj.articles[0].packagedArticle;
        }

        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const ratio = findProductToProduct(
            extractObj.relations,
            accessoryObj.product.oid,
            relationAttributeAids.ratio
          );

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          const objSurfaceArea = numeralConversion(wallArea); //Surface Area
          const objFinalratio = ratio ? parseFloat(ratio) : 1.0; // Ratio
          const objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
          let objIQuantity: number | string = ""; //I Quantity
          let objSalesunit = ""; //J Unite De Vente

          const articleAttribute = selectedAccessoryPackagedArticle.attributes;
          const dbsalesunit = dbSalesUnit(
            distributionChannelsAids,
            articleAttribute
          );

          const saleunit = _.find(articleAttribute, [
            "aid",
            attributeAids.baseunit,
          ]);

          if (dbsalesunit) {
            objSalesunit = uniteDeVenteArray(dbsalesunit);
          } else {
            if (saleunit) {
              const saleunit_value = _.get(saleunit.values[0], "value");
              objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
            }
          }

          let alternativeunit;
          if (objFinalunite) {
            alternativeunit = alternativeUnit(
              alternativeUnitsAids,
              articleAttribute,
              parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
              objFinalunite,
              objSalesunit,
              props,
              "accessory"
            );

            if (alternativeunit) {
              objIQuantity = alternativeunit;
            }
          }

          /**
           * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
           */
          const primaryObjectKey: string = wallId;
          let primaryArticle: any = primaryObject[primaryObjectKey] || {};

          /**
           * Extract secondary article from primary article, Key = article name
           */
          const secondaryArticletKey: string = objArticle;

          /**
           * Secondary article are sorted based on Article name
           * Extract secondary article or assign new
           * While assigning new assign surfaceArea, ratio, quantiteI to 0
           */
          let secondaryArticle: any = primaryArticle[secondaryArticletKey] || {
            levelName: levelName,
            solutionName: ovurage,
            article: objArticle,
            wallHeight: wallHeight,
            surfaceArea: 0,
            ratio: 0,
            quantite: "",
            quantiteUnit: objFinalunite,
            quantiteI: 0,
            salesUnit: objSalesunit,
            wallType: wallType,
            levelElevation: levelElevation,
            wallId: wallId,
          };

          /**
           * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
           */
          primaryArticle[secondaryArticletKey] = {
            ...secondaryArticle,
            surfaceArea:
              secondaryArticle.surfaceArea +
              parseFloat(objSurfaceArea.toFixed(3)),
            ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
            quantiteI: secondaryArticle.quantiteI + objIQuantity,
          };

          /**
           * Assign newly calculate primary article .
           */

          primaryObject[primaryObjectKey] = primaryArticle;
          primaryObject[primaryObjectKey]["surfaceArea"] =
            primaryArticle[secondaryArticletKey].surfaceArea;
          primaryObject[primaryObjectKey]["level"] =
            primaryArticle[secondaryArticletKey].levelName;
          primaryObject[primaryObjectKey]["levelElevation"] =
            primaryArticle[secondaryArticletKey].levelElevation;
          primaryObject[primaryObjectKey]["solution"] =
            primaryArticle[secondaryArticletKey].solutionName;
          primaryObject[primaryObjectKey]["wallHeight"] =
            primaryArticle[secondaryArticletKey].wallHeight;
          primaryObject[primaryObjectKey]["wallId"] =
            primaryArticle[secondaryArticletKey].wallId;
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];
  _.forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let wallHeight = prmObj.wallHeight;
    let wallId = prmObj.wallId;

    let articles: AccessoryArticle[] = [];

    _.forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "wallHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "wallId"
      ) {
        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          wallHeight: round(wallHeight, 2),
          wallArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          wallType: articleObj.wallType,
          levelElevation: articleObj.levelElevation,
          wallId: wallId,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      wallHeight: round(wallHeight, 2),
      wallArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      wallId: wallId,
    });
  });

  let accessorySummary: any = [];

  /**
   * Sort montants by level -> solution
   */
  let accessoryArray: Accessory[] = [];

  accessoryArray = _.orderBy(
    collected_articles,
    ["levelElevation", "solution", "wallArea"],
    ["asc", "asc", "desc"]
  );

  ///////////////////

  let rowNumber: number = 16;
  _.forEach(accessoryArray, (accessory: Accessory, index: number) => {
    let row = accessorySheet.getRow(rowNumber);
    row.values = [
      accessory.level,
      accessory.solution,
      accessory.wallHeight,
      accessory.wallArea,
      accessory.wallId,
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    row.eachCell(function (cell: any, colNumber: number) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "D9D9D9",
        },
      };

      setCellBorder(cell, true);
      setCellAlignment(cell, "center", "middle");
      setCellFont(cell, "Arial", 9, true, "black");
      if (colNumber === 5) {
        setColumnType(cell);
      }
    });

    rowNumber = rowNumber + 1;

    _.forEach(accessory.articles, (article: AccessoryArticle, key: number) => {
      let row = accessorySheet.getRow(rowNumber);
      row.values = [
        article.level,
        "",
        "",
        printNullOrZero(article.wallArea),
        "",
        article.article,
        printNullOrZero(article.ratio),
        printNullOrZero(article.quantityI),
        unitTobePrinted(article.unitI),
        printNullOrZero(article.quantityS),
        unitTobePrinted(article.unitS),
      ];

      row.eachCell(function (cell: any) {
        setCellAlignment(cell, "center", "middle");

        // Calculate formula for Quantite column
        if (cell.address.startsWith("H")) {
          const unitValue = row.getCell("I").value;

          if (unitValue === "Kg" || unitValue === "M") {
            cell.value = round(article.wallArea * article.ratio, 2);
          } else {
            cell.value = ceil(article.wallArea * article.ratio);
          }
        }

        setCellBorder(cell, true);
        setCellFont(cell, "Arial", 9, false, "black");
      });

      accessorySummary.push({
        level: article.level,
        article: article.article,
        quantity:
          article.unitI === "Kilogramme" ||
          article.unitI === "M" ||
          article.unitI === "Kg"
            ? round(article.wallArea * article.ratio, 2)
            : ceil(article.wallArea * article.ratio),
        unite: article.unitI,
        quantityConditioned: article.quantityS,
        saleUnit: article.unitS,
        solution: article.solution,
        walltype: article.wallType,
        levelElevation: article.levelElevation,
      });

      rowNumber = rowNumber + 1;
    });
  });

  return accessorySummary;
};

const writeRail = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  finalExtract: any
): Promise<any> => {
  let railSheet = workbookObject.getWorksheet("Rails");

  //logoImageId !== 0 && railSheet.addImage(logoImageId, "H1:J3");
  logoImageId !== 0 &&
    railSheet.addImage(logoImageId, {
      tl: { col: 8, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(railSheet, "C", "G");

  const rails: {
    [Key: string]: Rails;
  } = {};

  let railsRevitData = await extractRailsDatafromRevit();

  _.forEach(finalExtract, (extractObj, extractIndex) => {
    let wallId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;

    _.forEach(extractObj.rails, function (rail, index) {
      let selectedArticle = rail.articles[0];

      if (rail.product.translation === "Rail Stil® R 48") {
        selectedArticle = _.find(rail.articles, {
          value: "Rail Stil® R 48/300 bot10L",
        });
      }

      if (selectedArticle) {
        const selectedPackagedArticle = selectedArticle.packagedArticle;
        // _.find(
        //   packagedArticles.objects,
        //   (object) => {
        //     if (
        //       object.translation === selectedArticle.key &&
        //       object.types[0] === "Packaged Article"
        //     ) {
        //       const packValid = packagedArticleLevelFilter(object.attributes);
        //       return packValid;
        //       // return true;
        //     }
        //     return false;
        //   }
        // );

        if (selectedPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const ubLengthObject = _.find(selectedPackagedArticle.attributes, {
            aid: attributeAids.unpackedLength,
          });

          const length = ubLengthObject
            ? ubLengthObject.values[0].numericValue
            : 0;

          let revitData = _.filter(railsRevitData, function (o) {
            return o.wallId === _.parseInt(wallId);
          });

          _.forEach(revitData, (rvtData, rvtIndex) => {
            const productname = rvtData.productName.includes("Rail Stil® R 48")
              ? "Rail Stil® R 48/300 bot10L"
              : rvtData.productName;
            if (
              selectedPackagedArticle.translation
                .replace(/[\s'®]/g, "")
                .replace(/[^a-zA-Z0-9]\s/g, "")
                .includes(
                  productname
                    .replace(/[\s'®]/g, "")
                    .replace(/[^a-zA-Z0-9]\s/g, "")
                )
            ) {
              let wallH = numeralConversion(round(wallHeight, 2));
              let wallA = numeralConversion(round(wallArea, 2));

              let newRailRow: Rails = rails[`${wallId}`] || {
                level: levelName,
                solution: ovurage,
                wallId: wallId,
                articleName: selectedPackagedArticle.translation,
                wallHeight: numeralConversion(round(wallHeight, 2)),
                wallArea: numeralConversion(round(wallArea, 2)),
                length: length,
                levelElevation: levelElevation,
                articles: {},
              };

              let sortedRevitData = _.sortBy(rvtData.data, [
                function (o) {
                  return round(o.length / 1000, 2);
                },
              ]);

              for (let dtt of sortedRevitData) {
                let length_in_m = _.ceil(dtt.length / 1000, 2);
                let selectedArticle = newRailRow.articles[
                  `${selectedPackagedArticle.translation}/${length_in_m}`
                ] || {
                  level: levelName,
                  solution: ovurage,
                  articleName: selectedPackagedArticle.translation,
                  wallHeight: wallH,
                  wallArea: wallA,
                  articleRevitLength: length_in_m,
                  nbUnit: 0,
                  nbTotal: "",
                  lengthTotal: 0,
                  articleLength: length,
                  wallType: wallType,
                  levelElevation: levelElevation,
                };

                selectedArticle.nbUnit = selectedArticle.nbUnit + dtt.quantity;

                newRailRow.articles[
                  `${selectedPackagedArticle.translation}/${length_in_m}`
                ] = selectedArticle;

                rails[`${wallId}`] = newRailRow;
              }
            }
          });
        }
      }
    });
  });

  /**
   * Sort rails by level -> solution
   */
  let railArray: Rails[] = [];

  _.forEach(rails, (plaq: Rails, key: string) => {
    railArray.push(plaq);
  });

  railArray = _.orderBy(
    railArray,
    ["levelElevation", "solution", "wallArea"],
    ["asc", "asc", "desc"]
  );

  ///////////////////

  let headRowNumber: number = 16; // variable for header row number to plot total unite(H) and total plaqs(I) in header rows
  let rowHeightUnitSum: number = 0; // SUM of F*G
  let firstArticle: string = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let quantitySum: string = ""; // SUM(G) sum of G
  let firstSolution: string = "";
  let railSummary: any[] = [];
  let lastArticleLength: number = 0;
  let lastWallType: string = "";
  let lastLevelElevation: number = 0;

  // in rails array element which is having length 9 is a header row.
  let rowNumber: number = 16;

  _.forEach(railArray, (rail: Rails, key: number) => {
    let articleName = "";
    _.forEach(rail.articles, (article: RailArticle, articleKey: string) => {
      if (articleName === "" || articleName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          let headerRow = railSheet.getRow(headRowNumber);

          railSummary.push({
            level: headerRow.getCell("A").value,
            solution: firstSolution,
            walltype: lastWallType,
            quantity: round(rowHeightUnitSum / lastArticleLength, 2),
            railLength: lastArticleLength,
            rail: firstArticle,
            levelElevation: lastLevelElevation,
          });

          /**
           * rowHeightUnitSum (i.e. SUM of F*G) divide by article length
           */
          headerRow.getCell("J").value = round(
            rowHeightUnitSum / lastArticleLength,
            2
          );

          headerRow.getCell("I").value = round(rowHeightUnitSum, 2);

          headerRow.getCell("H").value = {
            formula: `SUM(${quantitySum})`,
            sharedFormula: "none",
            date1904: false,
          };

          for (let index = 1; index <= headerRow.cellCount; index++) {
            let cell = headerRow.getCell(index);
            setCellBorder(cell, true);
            setCellFont(cell, "Arial", 9, true, "black");
            setCellAlignment(cell, "center", "middle");
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D9D9D9",
              },
            };
            if (index === 5) {
              setColumnType(cell);
            }
          }
        }

        if (articleName === "") {
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            article.solution,
            printNullOrZero(article.wallHeight),
            printNullOrZero(article.wallArea),
            rail.wallId,
            article.articleName,
            "",
            "",
            "",
            "",
          ];

          for (let index = 1; index <= row.cellCount; index++) {
            let cell = row.getCell(index);
            setCellBorder(cell, true);
            setCellAlignment(cell, "center", "middle");
            setCellFont(cell, "Arial", 9, false, "black");
          }
        } else {
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            "",
            "",
            "",
            "",
            article.articleName,
            "",
            "",
            "",
            "",
          ];

          for (let index = 1; index <= row.cellCount; index++) {
            let cell = row.getCell(index);
            setCellBorder(cell, true);
            setCellAlignment(cell, "center", "middle");
            setCellFont(cell, "Arial", 10, false, "black");
          }
        }

        articleName = article.articleName;
        firstArticle = article.articleName;
        firstSolution = article.solution;
        headRowNumber = rowNumber;
        rowHeightUnitSum = 0;
        quantitySum = "";
        lastArticleLength = article.articleLength;
        lastWallType = article.wallType;
        rowNumber = rowNumber + 1;
        lastLevelElevation = article.levelElevation;
      }
      quantitySum = quantitySum + `H${rowNumber},`;

      // rowLengthHeightUnitSum = rowLengthHeightUnitSum + F*G
      rowHeightUnitSum =
        rowHeightUnitSum + article.articleRevitLength * article.nbUnit;

      let row = railSheet.getRow(rowNumber);
      row.values = [
        article.level,
        "",
        "",
        "",
        "",
        article.articleName,
        printNullOrZero(article.articleRevitLength),
        printNullOrZero(article.nbUnit),
        "",
        "",
      ];

      for (let index = 1; index <= row.cellCount; index++) {
        let cell = row.getCell(index);
        setCellBorder(cell, true);
        setCellAlignment(cell, "center", "middle");
        setCellFont(cell, "Arial", 9, false, "black");
      }

      rowNumber = rowNumber + 1;
    });
  });

  if (railArray.length > 0) {
    let headerRow = railSheet.getRow(headRowNumber);

    railSummary.push({
      level: headerRow.getCell("A").value,
      solution: firstSolution,
      walltype: lastWallType,
      quantity: round(rowHeightUnitSum / lastArticleLength, 2),
      railLength: lastArticleLength,
      rail: firstArticle,
      levelElevation: lastLevelElevation,
    });

    /**
     * rowHeightUnitSum (i.e. SUM of F*G) divide by article length.
     */
    headerRow.getCell("J").value = round(
      rowHeightUnitSum / lastArticleLength,
      2
    );

    headerRow.getCell("I").value = round(rowHeightUnitSum, 2);

    headerRow.getCell("H").value = {
      formula: `SUM(${quantitySum})`,
      sharedFormula: "none",
      date1904: false,
    };

    for (let index = 1; index <= headerRow.cellCount; index++) {
      let cell = headerRow.getCell(index);
      setCellBorder(cell, true);
      setCellFont(cell, "Arial", 9, true, "black");
      setCellAlignment(cell, "center", "middle");
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "D9D9D9",
        },
      };
      if (index === 5) {
        setColumnType(cell);
      }
    }
  }
  return railSummary;
};

const writeMontant = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  finalExtract: any
): Promise<any> => {
  let montantSheet = workbookObject.getWorksheet("Montants");

  //logoImageId !== 0 && montantSheet.addImage(logoImageId, "G1:I3");
  logoImageId !== 0 &&
    montantSheet.addImage(logoImageId, {
      tl: { col: 7, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(montantSheet, "C", "G");

  const collected_articles: { [Key: string]: Montant } = {};
  let montantsRevitData = await extractFramesDatafromRevit();

  _.forEach(finalExtract, (extractObj, extractIndex) => {
    let wallId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;

    let revitData = _.filter(montantsRevitData, function (o) {
      return o.wallId === _.parseInt(wallId);
    });

    _.forEach(revitData, (revitMontant, index) => {
      _.forEach(extractObj.montants, (montant, index) => {
        const selectedArticle = find(montant.articles, function (o) {
          return revitMontant.productName === o.value;
        });

        if (selectedArticle) {
          const selectedPackagedArticle = selectedArticle.packagedArticle;
          // _.find(
          //   packagedArticles.objects,
          //   (object) => {
          //     if (
          //       object.translation === selectedArticle.key &&
          //       object.types[0] === "Packaged Article"
          //     ) {
          //       const packValid = packagedArticleLevelFilter(object.attributes);
          //       return packValid;
          //     }
          //     return false;
          //   }
          // );

          if (selectedPackagedArticle) {
            const attributeDescriptors = extractObj.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            let wallH = numeralConversion(round(wallHeight, 2));
            let wallA = numeralConversion(round(wallArea, 2));

            let newMontantRow: Montant = collected_articles[`${wallId}`] || {
              level: levelName,
              solution: ovurage,
              articleName: selectedPackagedArticle.translation,
              wallHeight: wallH,
              wallArea: wallA,
              wallType: wallType,
              articles: {},
              levelElevation: levelElevation,
              wallId: wallId,
            };

            const articleLength = _.find(selectedPackagedArticle.attributes, [
              "aid",
              attributeAids.unpackedLength,
            ]);

            if (
              selectedPackagedArticle.translation.includes(
                revitData[0].productName
              )
            ) {
              let sortedRevitData = _.sortBy(revitData[0].data, [
                function (o) {
                  return round(o.length / 1000, 2);
                },
              ]);

              for (let dtt of sortedRevitData) {
                const length_in_m = dtt.length / 1000;
                const quantity = dtt.quantity;

                let articleRevitHeight = round(length_in_m, 2);
                let calArticleLength: number = 0;

                if (articleLength) {
                  const metreValue = _.find(articleLength.values, [
                    "unit",
                    "m",
                  ]);

                  if (metreValue) {
                    const mValue = _.get(metreValue, "value");
                    calArticleLength = mValue;
                  } else {
                    const milliMetreValue = _.find(articleLength.values, [
                      "unit",
                      "mm",
                    ]);
                    if (milliMetreValue) {
                      const mmValue = _.get(milliMetreValue, "value");
                      calArticleLength = parseFloat(mmValue) / 1000;
                    }
                  }
                }

                let collected_article = newMontantRow.articles[
                  `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                ] || {
                  level: levelName,
                  solution: ovurage,
                  articleName: selectedPackagedArticle.translation,
                  wallHeight: wallH,
                  wallArea: wallA,
                  articleRevitHeight: articleRevitHeight,
                  nbUnit: 0,
                  nbTotal: 0,
                  articleLength: calArticleLength,
                  wallType: wallType,
                  levelElevation: levelElevation,
                };

                collected_article.nbUnit =
                  collected_article.nbUnit + numeralConversion(quantity);

                newMontantRow.articles[
                  `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                ] = collected_article;

                collected_articles[`${wallId}`] = newMontantRow;
              }
            }
          }
        }
      });
    });
  });

  /**
   * Sort montants by level -> solution
   */
  let montantArray: Montant[] = [];

  _.forEach(collected_articles, (plaq: Montant, key: string) => {
    montantArray.push(plaq);
  });

  montantArray = _.orderBy(
    montantArray,
    ["levelElevation", "solution", "wallArea"],
    ["asc", "asc", "desc"]
  );

  ///////////////////

  let headRowNumber: number = 16; // variable for header row number to plot `Nb unite` (G) and `Nb total` (I) in header rows
  let rowHeightUnitSum: number = 0; // Sum of (Montant Article Revit height * unit used i.e +[F*G])
  let quantitySum: string = ""; // Nb unites SUM formula
  let montantSummary: any[] = [];
  let rowNumber: number = 16;

  let lastArticle: string = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let lastWallType: string = "";
  let lastSolution: string = "";
  let lastArticleLength: number = 0;
  let lastLevelElevation: number = 0;

  _.forEach(montantArray, (montant: Montant, index: number) => {
    let articleName = "";
    _.forEach(
      montant.articles,
      (article: MontantArticle, articleKey: string) => {
        if (articleName === "" || articleName !== article.articleName) {
          if (headRowNumber !== rowNumber) {
            let headerRow = montantSheet.getRow(headRowNumber);

            montantSummary.push({
              level: headerRow.getCell("A").value,
              solution: lastSolution,
              walltype: lastWallType,
              quantity: round((rowHeightUnitSum * 1.05) / lastArticleLength, 0),
              montantLength: lastArticleLength,
              montant: lastArticle,
              levelElevation: lastLevelElevation,
            });

            /**
             * Formula for H Column sum of rowHeightUnitSum (i.e F*G) divide by
             * length of article
             */
            headerRow.getCell("I").value = round(
              (rowHeightUnitSum * 1.05) / lastArticleLength,
              0
            );

            headerRow.getCell("H").value = {
              formula: `SUM(${quantitySum})`,
              sharedFormula: "none",
              date1904: false,
            };

            for (let index = 1; index <= headerRow.cellCount; index++) {
              let cell = headerRow.getCell(index);
              setCellBorder(cell, true);
              setCellFont(cell, "Arial", 9, true, "black");
              setCellAlignment(cell, "center", "middle");
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: "D9D9D9",
                },
              };
              if (index === 5) {
                setColumnType(cell);
              }
            }
          }

          if (articleName === "") {
            let row = montantSheet.getRow(rowNumber);
            row.values = [
              article.level,
              article.solution,
              printNullOrZero(article.wallHeight),
              printNullOrZero(article.wallArea),
              montant.wallId,
              article.articleName,
              "",
              "",
              "",
            ];
            headRowNumber = rowNumber;
            rowNumber = rowNumber + 1;
          } else {
            let row = montantSheet.getRow(rowNumber);
            row.values = [
              article.level,
              "",
              "",
              "",
              "",
              article.articleName,
              "",
              "",
              "",
            ];
            headRowNumber = rowNumber;
            rowNumber = rowNumber + 1;
          }

          lastSolution = article.solution;
          lastArticle = article.articleName;
          articleName = article.articleName;
          lastWallType = article.wallType;
          lastArticleLength = article.articleLength;
          quantitySum = "";
          rowHeightUnitSum = 0;
          lastLevelElevation = article.levelElevation;
        }

        quantitySum = quantitySum + `H${rowNumber},`;

        // rowLengthHeightUnitSum = rowLengthHeightUnitSum + F*G
        rowHeightUnitSum =
          rowHeightUnitSum + article.articleRevitHeight * article.nbUnit;

        let row = montantSheet.getRow(rowNumber);
        row.values = [
          article.level,
          "",
          "",
          "",
          "",
          article.articleName,
          printNullOrZero(article.articleRevitHeight),
          printNullOrZero(article.nbUnit),
          "",
        ];

        for (let index = 1; index <= row.cellCount; index++) {
          let cell = row.getCell(index);
          setCellBorder(cell, true);
          setCellFont(cell, "Arial", 9, false, "black");
          setCellAlignment(cell, "center", "middle");
        }

        rowNumber = rowNumber + 1;
      }
    );
  });

  if (montantArray.length > 0) {
    let headerRow = montantSheet.getRow(headRowNumber);

    montantSummary.push({
      level: headerRow.getCell("A").value,
      solution: lastSolution,
      walltype: lastWallType,
      quantity: round((rowHeightUnitSum * 1.05) / lastArticleLength, 0),
      montantLength: lastArticleLength,
      montant: lastArticle,
      levelElevation: lastLevelElevation,
    });

    /**
     * Formula for H Column sum of rowHeightUnitSum (i.e F*G) divide by
     * length of article
     */
    headerRow.getCell("I").value = round(
      (rowHeightUnitSum * 1.05) / lastArticleLength,
      0
    );

    headerRow.getCell("H").value = {
      formula: `SUM(${quantitySum})`,
      sharedFormula: "none",
      date1904: false,
    };

    for (let index = 1; index <= headerRow.cellCount; index++) {
      let cell = headerRow.getCell(index);
      setCellBorder(cell, true);
      setCellFont(cell, "Arial", 9, false, "black");
      setCellAlignment(cell, "center", "middle");
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "D9D9D9",
        },
      };
      if (index === 5) {
        setColumnType(cell);
      }
    }
  }

  return montantSummary;
};

const writePlaques = async (
  workbookObject: Excel.Workbook,
  logoImageId: number,
  finalExtract: any
): Promise<any> => {
  const collected_articles: {
    [Key: string]: Plaqs;
  } = {};

  let plaqueRevitData = await extractPlasterboardDataForWallfromRevit();
  _.forEach(finalExtract, (extractObj, extractIndex) => {
    let wallId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;

    _.forEach(extractObj.plaques, (plaque, index) => {
      let revitData = _.filter(plaqueRevitData, function (o) {
        return o.wallId === _.parseInt(wallId);
      });

      if (revitData.length > 0) {
        _.forEach(revitData, (revtData, index) => {
          let selectedArticle = _.find(plaque.articles, {
            value: revtData.productName,
          });

          if (selectedArticle) {
            // const selectedArticleAPack = selectedArticle.article.attributes;
            // const selectedPaArticle = find(selectedArticleAPack, {
            //   technicalName: "GFR-Packaged articles",
            // }).values;
            const selectedPackagedArticle = selectedArticle.packagedArticle;
            // _.find(
            //   packagedArticles.objects,
            //   (object) => {
            //     // const df = find(selectedPaArticle, { value: object.translation });
            //     // if(df){
            //     // }
            //     if (
            //       object.translation === selectedArticle.key &&
            //       object.types[0] === "Packaged Article"
            //     ) {
            //       const packValid = packagedArticleLevelFilter(
            //         object.attributes
            //       );
            //       return packValid;
            //     }
            //     return false;
            //   }
            // );

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractObj.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              let newPlaqRow: Plaqs = collected_articles[`${wallId}`] || {
                level: levelName,
                solution: ovurage,
                wallId: wallId,
                articleName: selectedPackagedArticle.translation,
                wallHeight: numeralConversion(round(wallHeight, 2)),
                wallArea: numeralConversion(round(wallArea, 2)),
                articles: {},
                levelElevation: levelElevation,
              };

              const articleWidth = _.find(selectedPackagedArticle.attributes, [
                "aid",
                attributeAids.unpackedwidth,
              ]);
              const articleLength = _.find(selectedPackagedArticle.attributes, [
                "aid",
                attributeAids.unpackedLength,
              ]);

              if (
                selectedPackagedArticle.translation
                  .replace(/[\s'®]/g, "")
                  .replace(/[^a-zA-Z0-9]\s/g, "")
                  .includes(
                    revtData.productName
                      .replace(/[\s'®]/g, "")
                      .replace(/[^a-zA-Z0-9]\s/g, "")
                  )
              ) {
                /**
                 * Sort data by length
                 */

                let sortedRevitData = _.sortBy(revtData.data, [
                  function (o) {
                    return round(o.length / 1000, 2);
                  },
                ]).reverse();

                for (let dtt of sortedRevitData) {
                  const length_in_m = round(dtt.length / 1000, 2);
                  const height_in_m = round(dtt.height / 1000, 2);
                  const quantity = dtt.quantity;
                  const wallH = numeralConversion(round(wallHeight, 2));
                  const wallA = numeralConversion(round(wallArea, 2));
                  let placoArticleWidth: number = 0;

                  if (articleWidth) {
                    const metreValue = _.find(articleWidth.values, [
                      "unit",
                      "m",
                    ]);

                    if (metreValue) {
                      const mValue: string = _.get(metreValue, "value");
                      placoArticleWidth = parseFloat(mValue);
                    } else {
                      const milliMetreValue = _.find(articleWidth.values, [
                        "unit",
                        "mm",
                      ]);
                      if (milliMetreValue) {
                        const mmValue = _.get(milliMetreValue, "value");
                        placoArticleWidth = parseFloat(mmValue) / 1000;
                      }
                    }
                  }

                  let placoArticleLength: number = 0;

                  if (articleLength) {
                    const metreValue = _.find(articleLength.values, [
                      "unit",
                      "m",
                    ]);

                    if (metreValue) {
                      const mValue: string = _.get(metreValue, "value");
                      placoArticleLength = parseFloat(mValue);
                    } else {
                      const milliMetreValue = _.find(articleLength.values, [
                        "unit",
                        "mm",
                      ]);
                      if (milliMetreValue) {
                        const mmValue = _.get(milliMetreValue, "value");
                        placoArticleLength = parseFloat(mmValue) / 1000;
                      }
                    }
                  }

                  let selectedArticle = newPlaqRow.articles[
                    `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    articleName: selectedPackagedArticle.translation,
                    wallHeight: wallH,
                    wallArea: wallA,
                    articleRevitLength: length_in_m,
                    articleRevitHeight: height_in_m,
                    nbUnit: 0,
                    nbTotal: "",
                    articleWidth: placoArticleWidth,
                    articleLength: placoArticleLength,
                    wallType: wallType,
                    levelElevation: levelElevation,
                  };

                  selectedArticle.nbUnit =
                    selectedArticle.nbUnit + numeralConversion(quantity);

                  newPlaqRow.articles[
                    `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                  ] = selectedArticle;

                  collected_articles[`${wallId}`] = newPlaqRow;
                }
              }
            }
          }
        });
      }
    });
  });

  let railSheet = workbookObject.getWorksheet("Plaques");

  //logoImageId !== 0 && railSheet.addImage(logoImageId, "H1:J3");
  logoImageId !== 0 &&
    railSheet.addImage(logoImageId, {
      tl: { col: 8, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(railSheet, "C", "G");

  let headRowNumber: number = 16; // variable for header row number to plot total unite(H) and total plaqs(I) in header rows
  let rowLengthHeightUnitSum: number = 0; // SUM (F15*G15*H15 + F16*G16*H16) for single article
  let firstArticle = ""; // first article to check is there one or more article in single solution. According to that we will create header row for second article
  let quantitySum: string = ""; // SUM(H) for single article
  let plaqSummary: PlaqSummary[] = [];
  let firstSolution: string = "";

  let lastWallType: string = "";
  let lastPlaqWidth: number = 0;
  let lastPlaqLength: number = 0;
  let lastPlaqArticle: string = "";
  let lastLevelElevation: number = 0;

  // in plaqs array element which is having length 9 is a header row.
  let rowNumber: number = 16;

  /**
   * Sort Plaqs by level -> solution
   */
  let plaqArray: Plaqs[] = [];

  _.forEach(collected_articles, (plaq: Plaqs, key: string) => {
    plaqArray.push(plaq);
  });

  plaqArray = _.orderBy(
    plaqArray,
    ["levelElevation", "solution", "wallArea"],
    ["asc", "asc", "desc"]
  );

  ///////////////////

  _.forEach(plaqArray, (plaque: Plaqs, key: number) => {
    let articlePrevName = "";

    _.forEach(plaque.articles, (article: PlaqArticle, artilceKey: string) => {
      if (articlePrevName === "" || articlePrevName !== article.articleName) {
        if (headRowNumber !== rowNumber) {
          let headerRow = railSheet.getRow(headRowNumber);
          let cellAValue = headerRow.getCell("A").value;

          plaqSummary.push({
            level: cellAValue ? cellAValue.toString() : "",
            solution: firstSolution,
            walltype: lastWallType,
            quantity: round(
              (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
              2
            ),
            plaqWidth: lastPlaqWidth,
            plaqLength: lastPlaqLength,
            plaq: lastPlaqArticle,
            surfaceArticle: rowLengthHeightUnitSum,
            levelElevation: lastLevelElevation,
          });

          // Formula for I cell =SUM(J15:J18) * 1.08 / 1.2 /2.5
          // for Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m and	Aquaroc® 13 120/250
          // plaqs[i - 1][9] is last article length
          // plaqs[i - 1][10] is last article width
          headerRow.getCell("J").value = round(
            (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
            2
          );

          headerRow.getCell("I").value = {
            formula: `SUM(${quantitySum})`,
            sharedFormula: "none",
            date1904: false,
          };

          headerRow.eachCell(function (cell: any, colNumber: number) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D9D9D9",
              },
            };

            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };

            if (colNumber === 5) {
              setColumnType(cell);
            }
            setCellFont(cell, "Arial", 9, true, "black");
            setCellAlignment(cell, "center", "middle");
          });
        }

        /// if articlePrevName is not set or it's difference
        /// then add blank row
        if (articlePrevName === "") {
          /// if articlePrevName === "" then add solution row
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            article.solution,
            article.wallHeight,
            article.wallArea,
            plaque.wallId,
            article.articleName,
            "",
            "",
            "",
            "",
          ];

          headRowNumber = rowNumber;
          rowNumber = rowNumber + 1;
        } else {
          let row = railSheet.getRow(rowNumber);
          row.values = [
            article.level,
            "",
            "",
            "",
            "",
            article.articleName,
            "",
            "",
            "",
            "",
          ];

          headRowNumber = rowNumber;
          rowNumber = rowNumber + 1;
        }

        rowLengthHeightUnitSum = 0;
        quantitySum = "";
        articlePrevName = article.articleName;
      }

      quantitySum = quantitySum + `I${rowNumber},`;

      // rowLengthHeightUnitSum = rowLengthHeightUnitSum + F*G*H
      rowLengthHeightUnitSum =
        rowLengthHeightUnitSum +
        article.articleRevitLength *
          article.articleRevitHeight *
          article.nbUnit;

      firstSolution = article.solution;
      lastWallType = article.wallType;
      lastPlaqWidth = article.articleWidth;
      lastPlaqLength = article.articleLength;
      lastPlaqArticle = article.articleName;
      lastLevelElevation = article.levelElevation;

      let row = railSheet.getRow(rowNumber);
      row.values = [
        article.level,
        "",
        "",
        "",
        "",
        article.articleName,
        article.articleRevitLength,
        article.articleRevitHeight,
        article.nbUnit,
        "",
      ];

      row.eachCell(function (cell: any, colNumber: number) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        setCellFont(cell, "Arial", 9, false, "black");
        setCellAlignment(cell, "center", "middle");
      });

      rowNumber = rowNumber + 1;
    });
  });

  if (plaqArray.length > 0) {
    let headerRow = railSheet.getRow(headRowNumber);
    let cellAValue = headerRow.getCell("A").value;

    plaqSummary.push({
      level: cellAValue ? cellAValue.toString() : "",
      solution: firstSolution,
      walltype: lastWallType,
      quantity: round(
        (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
        2
      ),
      plaqWidth: lastPlaqWidth,
      plaqLength: lastPlaqLength,
      plaq: lastPlaqArticle,
      surfaceArticle: rowLengthHeightUnitSum,
      levelElevation: lastLevelElevation,
    });

    // Formula for I cell =SUM(J15:J18) * 1.08 / 1.2 /2.5
    // for Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m and	Aquaroc® 13 120/250
    // plaqs[i - 1][9] is last article length
    // plaqs[i - 1][10] is last article width
    headerRow.getCell("J").value = round(
      (1.05 * rowLengthHeightUnitSum) / lastPlaqWidth / lastPlaqLength,
      2
    );

    headerRow.getCell("I").value = {
      formula: `SUM(${quantitySum})`,
      sharedFormula: "none",
      date1904: false,
    };

    headerRow.eachCell(function (cell: any, colNumber: number) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "D9D9D9",
        },
      };

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      setCellFont(cell, "Arial", 9, true, "black");
      setCellAlignment(cell, "center", "middle");

      if (colNumber === 5) {
        setColumnType(cell);
      }
    });
  }

  return plaqSummary;
};

/**
 * Write 'Feuille Synthèse' (Summary) sheet
 * @param workbook Excel workbook
 * @param extractDetails Placo solution details containing Plaq, Montant, Wools, Accessory, Rails
 * @param qty Extracted data and unique data for Plasterboard, Montant, Rails, Accessory, Wools
 */
export const writeSummary = async (
  workbookObject: Excel.Workbook,
  finalExtract: any,
  plaqSummary: any,
  montantSummary: any,
  railSummary: any,
  woolSummary: any,
  accessorySummary: any,
  props: any,
  logoImageId: any
) => {
  let summarySheet = workbookObject.getWorksheet("Quantitatifs chantier");

  // logoImageId !== 0 && summarySheet.addImage(logoImageId, "H1:J3");
  logoImageId !== 0 &&
    summarySheet.addImage(logoImageId, {
      tl: { col: 7, row: 0 },
      ext: { width: 150, height: 110 },
    });
  await setDossierTechnicalInfo(summarySheet, "C", "F");

  let rowDetailsCount: number = 17;
  let detailsRow: { [Key: string]: SummaryGroupDetail } = {};
  let globalWool: {
    [Key: string]: GlobalSummary;
  } = {};
  let globalAccessory: {
    [Key: string]: GlobalSummary;
  } = {};

  await asyncForEach(finalExtract, async (extractDetail: any, index: any) => {
    let wallType = extractDetail.wallDetails.WallType;
    let levelName = extractDetail.wallDetails.LevelName;
    let levelElevation = extractDetail.wallDetails.LevelElevation;
    let solutionName = extractDetail.placoSolution.translation;
    let solutionId = extractDetail.placoSolution.oid;

    let plaqRow: { [Key: string]: SummaryDetail } = {};
    let montantRow: { [Key: string]: SummaryDetail } = {};
    let railRow: { [Key: string]: SummaryDetail } = {};
    let accRow: { [Key: string]: SummaryDetail } = {};
    let woolRow: { [Key: string]: SummaryDetail } = {};

    //#region plaqs

    const filteredPlaqSummary = _.filter(plaqSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredPlaqSummary,
      async (fltPlaqSum: any, fltPlaqSumIndex: any) => {
        await asyncForEach(
          extractDetail.plaques,
          async (plaqs: any, plaqsIndex: any) => {
            const selectedPackagedArticle = _.find(plaqs.articles, (object) => {
              return object.value === fltPlaqSum.plaq;
            });

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractDetail.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              const relationAttributeAids = relationAttributeSelector(
                extractDetail.relationAttributes
              );

              const distributionChannelsAids =
                distributionChannelsAttributeSelector(attributeDescriptors);

              const alternativeUnitsAids =
                alternativeUnitsSelector(attributeDescriptors);

              const articleAttribute =
                selectedPackagedArticle.packagedArticle.attributes;

              const dbsalesunit = dbSalesUnit(
                distributionChannelsAids,
                articleAttribute
              );

              const saleunit = _.find(articleAttribute, [
                "aid",
                attributeAids.baseunit,
              ]);

              let salesunit = "";
              if (dbsalesunit) {
                salesunit = uniteDeVenteArray(dbsalesunit);
              } else {
                if (saleunit) {
                  const saleunit_value = _.get(saleunit.values[0], "value");
                  salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
                }
              }

              const finalunite = uniteDeVenteArray(
                findProductToProduct(
                  extractDetail.relations,
                  plaqs.product.oid,
                  relationAttributeAids.unite
                )
              );

              let newPlaqRow = plaqRow[selectedPackagedArticle.value] || {
                levelName: levelName,
                ouvrage: "",
                wallType: wallType,
                article: selectedPackagedArticle.value,
                ean: getAttValue(articleAttribute, attributeAids.eancode),
                nbArticles: 0,
                quantity: 0,
                unite: uniteDeVenteToUnit(finalunite),
                quantityConditioned: 0,
                uniteDeVente: salesunit,
              };

              const updatedQuantity = round(
                (newPlaqRow.nbArticles + fltPlaqSum.quantity) *
                  fltPlaqSum.plaqWidth *
                  fltPlaqSum.plaqLength,
                2
              );

              const conditionedQuantity = await newConditionedQuantityForPlaq(
                alternativeUnitsAids,
                articleAttribute,
                uniteDeVenteToUnit(finalunite),
                updatedQuantity,
                salesunit,
                props
              );

              plaqRow[selectedPackagedArticle.value] = {
                ...newPlaqRow,
                nbArticles: newPlaqRow.nbArticles + fltPlaqSum.quantity,
                quantity: updatedQuantity,
                quantityConditioned: conditionedQuantity,
              };
            }
          }
        );
      }
    );
    //#endregion plaqs

    //#region montant
    const filteredMontantSummary = _.filter(montantSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredMontantSummary,
      async (fltMontantSum: any, fltMontantIndex: any) => {
        await asyncForEach(
          extractDetail.montants,
          async (montant: any, montantIndex: any) => {
            const selectedPackagedArticle = _.find(
              montant.articles,
              (object) => {
                return object.value === fltMontantSum.montant;
              }
            );

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractDetail.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              const relationAttributeAids = relationAttributeSelector(
                extractDetail.relationAttributes
              );

              const distributionChannelsAids =
                distributionChannelsAttributeSelector(attributeDescriptors);

              const alternativeUnitsAids =
                alternativeUnitsSelector(attributeDescriptors);

              const articleAttribute =
                selectedPackagedArticle.packagedArticle.attributes;

              const dbsalesunit = dbSalesUnit(
                distributionChannelsAids,
                articleAttribute
              );

              const saleunit = _.find(articleAttribute, [
                "aid",
                attributeAids.baseunit,
              ]);

              let salesunit = "";
              if (dbsalesunit) {
                salesunit = uniteDeVenteArray(dbsalesunit);
              } else {
                if (saleunit) {
                  const saleunit_value = _.get(saleunit.values[0], "value");
                  salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
                }
              }

              const finalunite = uniteDeVenteArray(
                findProductToProduct(
                  extractDetail.relations,
                  montant.product.oid,
                  relationAttributeAids.unite
                )
              );

              let newMontantRow = montantRow[selectedPackagedArticle.value] || {
                levelName: levelName,
                ouvrage: "",
                wallType: wallType,
                article: selectedPackagedArticle.value,
                ean: getAttValue(articleAttribute, attributeAids.eancode),
                nbArticles: 0,
                quantity: 0,
                unite: uniteDeVenteToUnit(finalunite),
                quantityConditioned: 0,
                uniteDeVente: salesunit,
              };

              const updatedQuantity = round(
                (newMontantRow.nbArticles + fltMontantSum.quantity) *
                  fltMontantSum.montantLength,
                2
              );

              const conditionedQuantity =
                await newConditionedQuantityForMontant(
                  alternativeUnitsAids,
                  articleAttribute,
                  uniteDeVenteToUnit(finalunite),
                  updatedQuantity,
                  salesunit,
                  props
                );

              montantRow[selectedPackagedArticle.value] = {
                ...newMontantRow,
                nbArticles: newMontantRow.nbArticles + fltMontantSum.quantity,
                quantity: updatedQuantity,
                quantityConditioned: conditionedQuantity,
              };
            }
          }
        );
      }
    );

    //#endregion

    //#region rail
    const filteredRailSummary = _.filter(railSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredRailSummary,
      async (fltRailSum: any, fltRailIndex: any) => {
        await asyncForEach(
          extractDetail.rails,
          async (rail: any, railIndex: any) => {
            const selectedPackagedArticle = _.find(rail.articles, (object) => {
              return object.value === fltRailSum.rail;
            });

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractDetail.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              const relationAttributeAids = relationAttributeSelector(
                extractDetail.relationAttributes
              );

              const distributionChannelsAids =
                distributionChannelsAttributeSelector(attributeDescriptors);

              const alternativeUnitsAids =
                alternativeUnitsSelector(attributeDescriptors);

              const articleAttribute =
                selectedPackagedArticle.packagedArticle.attributes;

              const dbsalesunit = dbSalesUnit(
                distributionChannelsAids,
                articleAttribute
              );

              const saleunit = _.find(articleAttribute, [
                "aid",
                attributeAids.baseunit,
              ]);

              let salesunit = "";
              if (dbsalesunit) {
                salesunit = uniteDeVenteArray(dbsalesunit);
              } else {
                if (saleunit) {
                  const saleunit_value = _.get(saleunit.values[0], "value");
                  salesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
                }
              }

              const finalunite = uniteDeVenteArray(
                findProductToProduct(
                  extractDetail.relations,
                  rail.product.oid,
                  relationAttributeAids.unite
                )
              );

              let newRailRow = railRow[selectedPackagedArticle.value] || {
                levelName: levelName,
                ouvrage: "",
                wallType: wallType,
                article: selectedPackagedArticle.value,
                ean: getAttValue(articleAttribute, attributeAids.eancode),
                nbArticles: 0,
                quantity: 0,
                unite: uniteDeVenteToUnit(finalunite),
                quantityConditioned: 0,
                uniteDeVente: salesunit,
              };

              const updatedQuantity = round(
                (newRailRow.nbArticles + fltRailSum.quantity) *
                  fltRailSum.railLength,
                2
              );

              const conditionedQuantity = await newConditionedQuantityForRail(
                alternativeUnitsAids,
                articleAttribute,
                uniteDeVenteToUnit(finalunite),
                updatedQuantity,
                salesunit,
                props
              );

              railRow[selectedPackagedArticle.value] = {
                ...newRailRow,
                nbArticles: newRailRow.nbArticles + fltRailSum.quantity,
                quantity: updatedQuantity,
                quantityConditioned: conditionedQuantity,
              };
            }
          }
        );
      }
    );

    //#endregion

    //#region acceosory
    const filteredAccessorySummary = _.filter(accessorySummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredAccessorySummary,
      async (fltAccSum: any, fltAccSumIndex: any) => {
        const art = _.flattenDeep(_.map(extractDetail.accessories, "articles"));

        const selectedPackagedArticle = _.find(art, (object) => {
          return object.value === fltAccSum.article;
        });
        // const selectedPackagedArticle = _.find(
        //   packagedArticles.objects,
        //   (object) => {
        //     if (
        //       object.translation === fltAccSum.article &&
        //       object.types[0] === "Packaged Article"
        //     ) {
        //       const packValid = packagedArticleLevelFilter(object.attributes);
        //       return packValid;
        //     }
        //     return false;
        //   }
        // );

        if (selectedPackagedArticle) {
          const attributeDescriptors = extractDetail.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const articleAttribute =
            selectedPackagedArticle.packagedArticle.attributes;
          // ean: getAttValue(articleAttribute, attributeAids.eancode),

          let newAccRow = accRow[selectedPackagedArticle.value] || {
            levelName: levelName,
            ouvrage: "",
            wallType: wallType,
            article: selectedPackagedArticle.value,
            ean: getAttValue(articleAttribute, attributeAids.eancode),
            quantity: 0,
            unite: fltAccSum.unite,
            quantityConditioned: 0,
            uniteDeVente: fltAccSum.saleUnit,
          };

          accRow[selectedPackagedArticle.value] = {
            ...newAccRow,
            quantity: newAccRow.quantity + fltAccSum.quantity,
            quantityConditioned:
              newAccRow.quantityConditioned + fltAccSum.quantityConditioned,
          };
        }
      }
    );

    //#endregion

    //#region wool

    const filteredWoolSummary = _.filter(woolSummary, {
      solution: solutionName,
      level: levelName,
    });

    await asyncForEach(
      filteredWoolSummary,
      async (fltWoolSum: any, fltWoolSumIndex: any) => {
        await asyncForEach(
          extractDetail.wools,
          async (woolObj: any, woolIndex: any) => {
            let newWoolRow = woolRow[fltWoolSum.productName] || {
              levelName: levelName,
              ouvrage: "",
              wallType: wallType,
              article: fltWoolSum.productName,
              ean: fltWoolSum.ean,
              quantity: 0,
              unite: fltWoolSum.unite,
              quantityConditioned: 0,
              uniteDeVente: fltWoolSum.labelUnit,
              sellingPackage: fltWoolSum.sellingPackage,
            };

            woolRow[fltWoolSum.productName] = {
              ...newWoolRow,
              quantity: newWoolRow.quantity + fltWoolSum.quantity,
              quantityConditioned: ceil(
                (newWoolRow.quantity + fltWoolSum.quantity) /
                  parseFloat(fltWoolSum.sellingPackage)
              ),
            };
          }
        );
      }
    );

    //#endregion wool

    const detailsRowObj = _.get(
      detailsRow,
      `${levelName}/${solutionName}`,
      null
    );
    const combineWallType = detailsRowObj
      ? detailsRowObj.walltype.includes(wallType)
        ? detailsRowObj.walltype
        : detailsRowObj.walltype + "; " + wallType
      : wallType;

    detailsRow[`${levelName}/${solutionName}`] = {
      solution: solutionName,
      walltype: combineWallType,
      level: levelName,
      plaqs: plaqRow,
      montants: montantRow,
      rails: railRow,
      accessory: accRow,
      wool: woolRow,
      levelElevation: levelElevation,
    };
  });

  //#region Global wool extract
  _.forEach(woolSummary, (woolSumm, woolRowIndex) => {
    let newGlobalWoolRow = globalWool[woolSumm.productName] || {
      level: woolSumm.level,
      ean: woolSumm.ean,
      article: woolSumm.productName,
      quantity: 0,
      unite: woolSumm.unite,
      quantityConditioned: 0,
      saleUnit: woolSumm.labelUnit,
    };

    globalWool[woolSumm.productName] = {
      ...newGlobalWoolRow,
      quantity: newGlobalWoolRow.quantity + woolSumm.quantity,
      quantityConditioned: ceil(
        (newGlobalWoolRow.quantity + woolSumm.quantity) /
          parseFloat(woolSumm.sellingPackage)
      ),
    };
  });

  //#endregion Global wool extract

  //#region Global accessory extract
  _.forEach(accessorySummary, (accessorySumm, accessoryRowIndex) => {
    let detailObjForean = _.get(
      detailsRow,
      `${accessorySumm.level}/${accessorySumm.solution}`
    );
    let newGlobalAccessRow = globalAccessory[accessorySumm.article] || {
      level: accessorySumm.level,
      article: accessorySumm.article,
      ean: _.get(
        _.find(detailObjForean.accessory, { article: accessorySumm.article }),
        "ean"
      ),
      quantity: 0,
      unite: accessorySumm.unite,
      quantityConditioned: 0,
      saleUnit: accessorySumm.saleUnit,
    };

    globalAccessory[accessorySumm.article] = {
      ...newGlobalAccessRow,
      quantity: newGlobalAccessRow.quantity + accessorySumm.quantity,
      quantityConditioned:
        newGlobalAccessRow.quantityConditioned +
        parseFloat(accessorySumm.quantityConditioned),
    };
  });

  //#endregion Global accessory extract

  rowDetailsCount = await addGlobalPlqsInSummarySheet(
    detailsRow,
    summarySheet,
    rowDetailsCount
  );

  rowDetailsCount = await addGlobalMontantInSummarySheet(
    detailsRow,
    summarySheet,
    rowDetailsCount
  );

  rowDetailsCount = await addGlobalRailInSummarySheet(
    detailsRow,
    summarySheet,
    rowDetailsCount
  );

  rowDetailsCount = await addGlobalWoolInSummarySheet(
    globalWool,
    summarySheet,
    rowDetailsCount
  );

  rowDetailsCount = await addGlobalAccessoryInSummarySheet(
    globalAccessory,
    summarySheet,
    rowDetailsCount
  );

  rowDetailsCount = rowDetailsCount + 1;

  //#region Detail Heading
  let summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = ["DETAILS", "", "", "", "", "", "", "", "", ""];

  summarySheetRow.eachCell(
    { includeEmpty: true },
    function (cell: Excel.Cell, colNumber: any) {
      cell.alignment = {
        horizontal: "left",
        vertical: "middle",
      };
      cell.font = {
        bold: true,
        color: { argb: "#000" },
        family: 2,
        name: "Calibri",
        size: 9,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FFFF00",
        },
      };
    }
  );

  summarySheet.mergeCells(`A${rowDetailsCount}:B${rowDetailsCount}`);
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  //#region Detail Headers row

  summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = [
    "Niveau",
    "Ouvrage",
    "Référence interne (Système REVIT)",
    "Code EAN",
    "Désignation de l'article",
    "Nb articles",
    "Q",
    "U",
    "Q",
    "U",
  ];

  summarySheetRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
    cell.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    cell.font = {
      bold: true,
      color: { argb: colNumber === 7 || colNumber === 8 ? "FF4473c4" : "#000" },
      family: 2,
      name: "Arial",
      size: 9,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  // add caculated detail row
  //#region detailsRow

  _.forEach(
    detailsRow,
    (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        detailRow.level,
        detailRow.solution,
        detailRow.walltype,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (let index = 1; index <= summarySheetRow.cellCount; index++) {
        let cell = summarySheetRow.getCell(index);
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: true,
          color: { argb: "#000" },
          family: 2,
          name: "Arial",
          size: 9,
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "D9D9D9",
          },
        };
      }

      rowDetailsCount = rowDetailsCount + 1;

      _.forEach(
        detailRow.plaqs,
        (plaqRow: SummaryDetail, plaqRowIndex: string) => {
          summarySheetRow = summarySheet.getRow(rowDetailsCount);
          printSummaryDetailRow(summarySheetRow, plaqRow);

          for (let index = 1; index <= summarySheetRow.cellCount; index++) {
            let cell = summarySheetRow.getCell(index);
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
            cell.font = {
              bold: index === 7 || index === 8 ? true : false,
              color: { argb: index === 7 || index === 8 ? "FF4473c4" : "#000" },
              family: 2,
              name: "Arial",
              size: 9,
            };
            if (cell.address.startsWith("D")) {
              cell.model.type = 2;
              cell.numFmt = "0";
            }
          }
          rowDetailsCount = rowDetailsCount + 1;
        }
      );

      _.forEach(
        detailRow.montants,
        (montantRow: SummaryDetail, montantRowIndex: string) => {
          summarySheetRow = summarySheet.getRow(rowDetailsCount);
          printSummaryDetailRow(summarySheetRow, montantRow);

          for (let index = 1; index <= summarySheetRow.cellCount; index++) {
            let cell = summarySheetRow.getCell(index);
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
            cell.font = {
              bold: index === 7 || index === 8 ? true : false,
              color: { argb: index === 7 || index === 8 ? "FF4473c4" : "#000" },
              family: 2,
              name: "Arial",
              size: 9,
            };
            if (cell.address.startsWith("D")) {
              cell.model.type = 2;
              cell.numFmt = "0";
            }
          }
          rowDetailsCount = rowDetailsCount + 1;
        }
      );

      _.forEach(
        detailRow.rails,
        (railRow: SummaryDetail, railRowIndex: string) => {
          summarySheetRow = summarySheet.getRow(rowDetailsCount);
          printSummaryDetailRow(summarySheetRow, railRow);

          for (let index = 1; index <= summarySheetRow.cellCount; index++) {
            let cell = summarySheetRow.getCell(index);
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
            cell.font = {
              bold: index === 7 || index === 8 ? true : false,
              color: { argb: index === 7 || index === 8 ? "FF4473c4" : "#000" },
              family: 2,
              name: "Arial",
              size: 9,
            };
            if (cell.address.startsWith("D")) {
              cell.model.type = 2;
              cell.numFmt = "0";
            }
          }
          rowDetailsCount = rowDetailsCount + 1;
        }
      );
    }
  );

  return detailsRow;
};

//#region Commande New

export const writeCommandeNew = async (
  workbookObject: Excel.Workbook,
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  unitConversionArray: any,
  logoImageId: number
) => {
  /// Loop over detailsRow to collect Plaqs, Montant, Rails, Wool, Accessory
  /// For Details table
  let detailsTableArray: {
    [Key: string]: DetailCommande;
  } = {};
  let globalTableArray: {
    [Key: string]: GlobalCommande;
  } = {};

  /// Collect Plaqs and Stored it in Key/Value pair
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.plaqs,
        (plaqRow: SummaryDetail, plaqRowIndex: string) => {
          /// Check key with Article name and Level name is present in detailsTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newPlaqRow = detailsTableArray[
            `${plaqRow.article}/${plaqRow.levelName}`
          ] || {
            levelName: plaqRow.levelName,
            article: plaqRow.article,
            ean: plaqRow.ean,
            quantity: 0,
            unite: plaqRow.unite,
            quantityConditioned: 0,
            uniteDeVente: plaqRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[`${plaqRow.article}/${plaqRow.levelName}`] = {
            ...newPlaqRow,
            quantity: newPlaqRow.quantity + plaqRow.quantity,
            quantityConditioned:
              newPlaqRow.quantityConditioned + plaqRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newPlaqGlobalRow = globalTableArray[`${plaqRow.article}`] || {
            article: plaqRow.article,
            ean: plaqRow.ean,
            quantity: 0,
            unite: plaqRow.unite,
            quantityConditioned: 0,
            uniteDeVente: plaqRow.uniteDeVente,
            element: "plaq",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${plaqRow.article}`] = {
            ...newPlaqGlobalRow,
            quantity: newPlaqGlobalRow.quantity + plaqRow.quantity,
            quantityConditioned:
              newPlaqGlobalRow.quantityConditioned +
              plaqRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Montants and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.montants,
        (montantRow: SummaryDetail, plaqRowIndex: string) => {
          /// Check key with Article name and Level name is present in detailsTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newMontantRow = detailsTableArray[
            `${montantRow.article}/${montantRow.levelName}`
          ] || {
            levelName: montantRow.levelName,
            article: montantRow.article,
            ean: montantRow.ean,
            quantity: 0,
            unite: montantRow.unite,
            quantityConditioned: 0,
            uniteDeVente: montantRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[`${montantRow.article}/${montantRow.levelName}`] = {
            ...newMontantRow,
            quantity: newMontantRow.quantity + montantRow.quantity,
            quantityConditioned:
              newMontantRow.quantityConditioned +
              montantRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newMontantGlobalRow = globalTableArray[
            `${montantRow.article}`
          ] || {
            article: montantRow.article,
            ean: montantRow.ean,
            quantity: 0,
            unite: montantRow.unite,
            quantityConditioned: 0,
            uniteDeVente: montantRow.uniteDeVente,
            element: "montant",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${montantRow.article}`] = {
            ...newMontantGlobalRow,
            quantity: newMontantGlobalRow.quantity + montantRow.quantity,
            quantityConditioned:
              newMontantGlobalRow.quantityConditioned +
              montantRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Rails and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.rails,
        (railRow: SummaryDetail, railRowIndex: string) => {
          let newRailRow = detailsTableArray[
            `${railRow.article}/${railRow.levelName}`
          ] || {
            levelName: railRow.levelName,
            article: railRow.article,
            ean: railRow.ean,
            quantity: 0,
            unite: railRow.unite,
            quantityConditioned: 0,
            uniteDeVente: railRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          detailsTableArray[`${railRow.article}/${railRow.levelName}`] = {
            ...newRailRow,
            quantity: newRailRow.quantity + railRow.quantity,
            quantityConditioned:
              newRailRow.quantityConditioned + railRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newRailGlobalRow = globalTableArray[`${railRow.article}`] || {
            article: railRow.article,
            ean: railRow.ean,
            quantity: 0,
            unite: railRow.unite,
            quantityConditioned: 0,
            uniteDeVente: railRow.uniteDeVente,
            element: "rail",
            levelElevation: detailRow.levelElevation,
          };
          globalTableArray[`${railRow.article}`] = {
            ...newRailGlobalRow,
            quantity: newRailGlobalRow.quantity + railRow.quantity,
            quantityConditioned:
              newRailGlobalRow.quantityConditioned +
              railRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Wool and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.wool,
        (woolRow: SummaryDetail, woolRowIndex: string) => {
          let newWoolRow = detailsTableArray[
            `${woolRow.article}/${woolRow.levelName}`
          ] || {
            levelName: woolRow.levelName,
            article: woolRow.article,
            ean: woolRow.ean,
            quantity: 0,
            unite: woolRow.unite,
            quantityConditioned: 0,
            uniteDeVente: woolRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
            sellingPackage: woolRow.sellingPackage,
          };
          detailsTableArray[`${woolRow.article}/${woolRow.levelName}`] = {
            ...newWoolRow,
            quantity: newWoolRow.quantity + woolRow.quantity,
            quantityConditioned:
              newWoolRow.quantityConditioned + woolRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newWoolGlobalRow = globalTableArray[`${woolRow.article}`] || {
            article: woolRow.article,
            ean: woolRow.ean,
            quantity: 0,
            unite: woolRow.unite,
            quantityConditioned: 0,
            uniteDeVente: woolRow.uniteDeVente,
            element: "wool",
            levelElevation: detailRow.levelElevation,
            sellingPackage: woolRow.sellingPackage,
          };
          globalTableArray[`${woolRow.article}`] = {
            ...newWoolGlobalRow,
            quantity: newWoolGlobalRow.quantity + woolRow.quantity,
            quantityConditioned:
              newWoolGlobalRow.quantityConditioned +
              woolRow.quantityConditioned,
          };
        }
      );
    }
  );

  ///Collect Accessory and stored in key/value paired
  _.forEach(
    detailsRow,
    async (detailRow: SummaryGroupDetail, detailRowIndex: string) => {
      _.forEach(
        detailRow.accessory,
        (accessoryRow: SummaryDetail, accessoryRowIndex: string) => {
          let newAccessoryRow = detailsTableArray[
            `${accessoryRow.article}/${accessoryRow.levelName}`
          ] || {
            levelName: accessoryRow.levelName,
            article: accessoryRow.article,
            ean: accessoryRow.ean,
            quantity: 0,
            unite: accessoryRow.unite,
            quantityConditioned: 0,
            uniteDeVente: accessoryRow.uniteDeVente,
            levelElevation: detailRow.levelElevation,
          };
          let newAccRowQuantityConditioned =
            +newAccessoryRow.quantityConditioned;
          let accRowQuantityConditioned = +accessoryRow.quantityConditioned;

          detailsTableArray[
            `${accessoryRow.article}/${accessoryRow.levelName}`
          ] = {
            ...newAccessoryRow,
            quantity: newAccessoryRow.quantity + accessoryRow.quantity,
            quantityConditioned:
              newAccRowQuantityConditioned + accRowQuantityConditioned,
            // newAccessoryRow.quantityConditioned +
            // accessoryRow.quantityConditioned,
          };

          /// Check key as Article name is present in globalTableArray
          /// if not then add new else make sum of quantity and quantityConditioned

          let newAccessoryGlobalRow = globalTableArray[
            `${accessoryRow.article}`
          ] || {
            article: accessoryRow.article,
            ean: accessoryRow.ean,
            quantity: 0,
            unite: accessoryRow.unite,
            quantityConditioned: 0,
            uniteDeVente: accessoryRow.uniteDeVente,
            element: "accessory",
            levelElevation: detailRow.levelElevation,
          };

          let newAccGlobalRowQuantityConditioned =
            +newAccessoryGlobalRow.quantityConditioned;

          globalTableArray[`${accessoryRow.article}`] = {
            ...newAccessoryGlobalRow,
            quantity: newAccessoryGlobalRow.quantity + accessoryRow.quantity,
            quantityConditioned:
              newAccGlobalRowQuantityConditioned + accRowQuantityConditioned,
            // newAccessoryGlobalRow.quantityConditioned +
            //accessoryRow.quantityConditioned,
          };
        }
      );
    }
  );

  _.map(globalTableArray, (globalRow: any, globalRowIndex: any) => {
    let roundedQuantityConditioned = String(
      ceil(globalRow.quantityConditioned)
    );
    globalRow.quantity = oneToOneCommandeMapping(
      unitConversionArray,
      globalRow.uniteDeVente,
      globalRow.unite,
      roundedQuantityConditioned,
      globalRow.element,
      globalRow.sellingPackage ? globalRow.sellingPackage : ""
    );
    globalRow.quantityConditioned = roundedQuantityConditioned;
  });

  let summarySheet = workbookObject.getWorksheet("Quantitatifs achats");

  //logoImageId !== 0 && summarySheet.addImage(logoImageId, "F1:G3");
  logoImageId !== 0 &&
    summarySheet.addImage(logoImageId, {
      tl: { col: 5, row: 0 },
      ext: { width: 150, height: 110 },
    });

  await setDossierTechnicalInfo(summarySheet, "B", "D");

  let rowDetailsCount: number = 17;

  _.forEach(
    globalTableArray,
    (globalRow: GlobalCommande, globalRowIndex: string) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        globalRow.ean,
        globalRow.article,
        printNullOrZero(globalRow.quantity),
        unitTobePrinted(globalRow.unite),
        printNullOrZero(globalRow.quantityConditioned),
        unitTobePrinted(globalRow.uniteDeVente),
      ];

      summarySheetRow.eachCell(function (cell: Excel.Cell, colNumber: number) {
        if (colNumber !== 1) {
          setCellBorder(cell, true);
        }
        setCellAlignment(cell, "center", "middle");

        if (colNumber === 4 || colNumber === 5) {
          setCellFont(cell, "Arial", 9, true, "blue");
        } else {
          setCellFont(cell, "Arial", 9, false, "black");
        }

        if (colNumber === 2 || colNumber === 6) {
          setColumnType(cell);
        }
      });

      rowDetailsCount = rowDetailsCount + 1;
    }
  );

  rowDetailsCount = rowDetailsCount + 1;

  //#region Detail Heading
  let summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = ["DETAILS", "", "", "", "", "", ""];

  summarySheetRow.eachCell(
    { includeEmpty: true },
    function (cell: Excel.Cell, colNumber: number) {
      setCellAlignment(cell, "left", "middle");
      setCellFont(cell, "Calibri", 9, true, "black");
      setSolidFill(cell);
    }
  );

  summarySheet.mergeCells(`A${rowDetailsCount}:B${rowDetailsCount}`);
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  //#region Detail Headers row
  summarySheetRow = summarySheet.getRow(rowDetailsCount);
  summarySheetRow.values = [
    "Niveau",
    "Code EAN",
    "Désignation de l'article",
    "Q",
    "U",
    "Q",
    "U",
  ];

  summarySheetRow.eachCell({ includeEmpty: true }, function (cell, colNumber) {
    setCellAlignment(cell, "center", "middle");

    if (colNumber === 4 || colNumber === 5) {
      setCellFont(cell, "Arial", 9, true, "blue");
    } else {
      setCellFont(cell, "Arial", 9, true, "black");
    }

    setCellBorder(cell, true);
  });
  //#endregion

  rowDetailsCount = rowDetailsCount + 1;

  const sortedCommande: DetailCommande[] = _.sortBy(detailsTableArray, [
    "levelElevation",
  ]);

  _.forEach(
    sortedCommande,
    (sortedDetailsRow: DetailCommande, sortedDetailsRowIndex: number) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        sortedDetailsRow.levelName,
        sortedDetailsRow.ean,
        sortedDetailsRow.article,
        printNullOrZero(sortedDetailsRow.quantity),
        unitTobePrinted(sortedDetailsRow.unite),
        printNullOrZero(sortedDetailsRow.quantityConditioned),
        unitTobePrinted(sortedDetailsRow.uniteDeVente),
      ];

      summarySheetRow.eachCell(
        { includeEmpty: true },
        function (cell: Excel.Cell, colNumber: number) {
          setCellBorder(cell, true);
          setCellAlignment(cell, "center", "middle");
          if (colNumber === 4 || colNumber === 5) {
            setCellFont(cell, "Arial", 9, true, "blue");
          } else {
            setCellFont(cell, "Arial", 9, false, "black");
          }

          if (colNumber === 2) {
            setColumnType(cell);
          }

          if (
            colNumber === 6 &&
            (cell.value === "00" ||
              cell.value === "000000" ||
              cell.value === 0 ||
              cell.value === "0")
          ) {
            setColumnType(cell);
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
};

//#endregion commande new
