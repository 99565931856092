import React, { Component } from "react";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import { Table, Select, Dropdown } from "semantic-ui-react";
import Checkbox from "@material-ui/core/Checkbox";
import { MappingRowRigips } from "../../../../RevitJS/Types/StoreTypes";
import { ProductMeta } from "../../../../RevitJS/Types/BddTypes";
import {
  selectSystem,
  toggleCheck,
  setPublicConstrain,
  setFireConstrain,
  setSwitchParement,
  setReverse,
  initProductSelector,
  setActiveMappingRowIndex,
} from "../Actions/index";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props {
  mappingRow: MappingRowRigips;
  placoData: any;
  rigipsData: ProductMeta[];
  selectSystem: any;
  setFireConstrain: any;
  setSwitchParement: any;
  setReverse: any;
  setPublicConstrain: any;
  setRoute: any;
  initProductSelector: any;
  setActiveMappingRowIndex: any;
  toggleCheck: Function;
}
interface State {}

export class MappingRowRigipsComponent extends Component<
  Props & WithTranslation,
  State
> {
  state = {};

  // public static defaultProps = {
  //   // wording: {
  //   //   select: {
  //   //     French: "Sélectionnez",
  //   //     English: "Select...",
  //   //     German: "Bitte auswählen...",
  //   //     Italian: "Per favore selezionare...",
  //   //   },
  //   //   yes: {
  //   //     French: "Oui",
  //   //     English: "Yes",
  //   //     German: "Ja",
  //   //     Italian: "Si",
  //   //   },
  //   //   no: {
  //   //     French: "Non",
  //   //     English: "No",
  //   //     German: "Nein",
  //   //     Italian: "No",
  //   //   },
  //   // },
  //   options: [
  //     {
  //       value: "test",
  //       label: "test",
  //     },
  //   ],
  // };

  render() {
    const {
      Index,
      Checked,
      RevitSelection,
      MappedSystem,
      PublicBuildingConstrain,
      Reverse,
      FireConstrain,
      SwitchParement,
    } = this.props.mappingRow;
    const { rigipsData, t } = this.props;

    return (
      <Table.Row key={Index}>
        <Table.Cell>
          <Checkbox
            color="primary"
            onChange={() => {
              this.props.toggleCheck(Index);
              //   this.props.tooglerHandler();
            }}
            checked={Checked}
          />
        </Table.Cell>

        <Table.Cell>{`${RevitSelection.RevitType} (${RevitSelection.Ids.length})`}</Table.Cell>
        <Table.Cell style={{ maxWidth: 50 }}>
          <Dropdown
            // style={{ width: 80 }}
            // style={{ minWidth: 300, maxWidth: 350 }}
            // placeholder={this.props.wording.select[this.props.language]}
            options={[
              {
                text: t("yes"),
                value: true,
              },
              {
                text: t("no"),
                value: false,
              },
            ]}
            onChange={(e, d) => {
              this.props.setFireConstrain(Index, d.value);
            }}
            defaultValue={FireConstrain || false}
          />
        </Table.Cell>
        <Table.Cell style={{ maxWidth: 50 }}>
          <Dropdown
            // style={{ width: 80 }}
            // style={{ minWidth: 300, maxWidth: 350 }}
            // placeholder={this.props.wording.select[this.props.language]}
            options={[
              {
                text: t("yes"),
                value: true,
              },
              {
                text: t("no"),
                value: false,
              },
            ]}
            onChange={(e, d) => {
              this.props.setSwitchParement(Index, d.value);
            }}
            defaultValue={SwitchParement || false}
          />
        </Table.Cell>
        <Table.Cell style={{ maxWidth: 350 }}>
          <Select
            style={{ minWidth: 300, maxWidth: 350 }}
            search
            placeholder={MappedSystem?.translation || t("select") || "select"}
            options={rigipsData.map((sys: ProductMeta) => {
              return { text: sys.translation, value: sys.oid };
            })}
            onChange={(e, d) => {
              this.props.selectSystem(
                Index,
                rigipsData.find((el) => el.oid === d.value)
              );
            }}
            defaultValue={MappedSystem?.oid}
          />
        </Table.Cell>
        <Table.Cell style={{ maxWidth: 50 }}>
          <Checkbox
            // style={{ width: 80 }}
            defaultChecked={Reverse && true}
            color="primary"
            onChange={(event, data) => {
              this.props.setReverse(Index, data);
            }}
          />
        </Table.Cell>
        <Table.Cell style={{ maxWidth: 50 }}>
          <Checkbox
            // style={{ width: 80 }}
            defaultChecked={PublicBuildingConstrain || false}
            color="primary"
            onChange={(event, data) => {
              this.props.setPublicConstrain(Index, data);
            }}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = (
  state: DrawStore,
  ownProps: { mappingRow: MappingRowRigips; setRoute: any }
) => ({
  mappingRow: ownProps.mappingRow,
  placoData: state.placoData,
  setRoute: ownProps.setRoute,
  rigipsData: state.rigipsMetaData,
});

const mapDispatchToProps = {
  selectSystem,
  setFireConstrain,
  setSwitchParement,
  setReverse,
  setPublicConstrain,
  initProductSelector,
  setActiveMappingRowIndex,
  toggleCheck,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("riggibs")(MappingRowRigipsComponent));
