import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../Reducers";
import {
    SET_SELECTION_TREE,
    InitGroupSelectorAction,
    INIT_GROUP_SELECTOR,
    SelectTypeAction,
    SELECT_TYPE,
    SelectLevelAction,
    SELECT_LEVEL,
    InitLanguageAction,
    INIT_LANGUAGE,
    InitGroupSelectorCeilingAction,
    INIT_GROUP_SELECTOR_CEILING,
    SelectLevelCeilingAction,
    SELECT_LEVEL_CEILING,
    SELECT_TYPE_CEILING,
    SelectTypeCeilingAction,
    SET_SELECTION_TREE_CEILING,
} from "./types";

import { ID, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { elementTypes, levelData } from "../../../../RevitJS/Helpers";
import { TypeData, SelectedItem } from "../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../RevitJS/API";

export const setSelectionTreeCeiling = (tree: ElementsTree) => {
    return {
        type: SET_SELECTION_TREE_CEILING,
        tree,
    };
};

export const setSelectionTree = (tree: ElementsTree) => {
  return {
      type: SET_SELECTION_TREE,
      tree,
  };
};

export const initGroupSelector = (
    tree: ElementsTree
): InitGroupSelectorAction => {
    return {
        type: INIT_GROUP_SELECTOR,
        tree,
    };
};

export const selectType = (
    typeName: string
): ThunkAction<void, DrawStore, unknown, SelectTypeAction> => async (
    dispatch,
    getState
) => {
    const { typesData, selectedTypes } = getState();
    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    let selectedTypeIndex = typesData.findIndex(
        (type) => type.Type === typeName
    );

    if (typesData[selectedTypeIndex].Checked) {
        typesDataCopy[selectedTypeIndex].Checked = false;
        selectedTypesCopy = selectedTypes.filter((type) => type !== typeName);
    } else {
        typesDataCopy[selectedTypeIndex].Checked = true;
        selectedTypesCopy = [...selectedTypes, typeName];
    }

    dispatch({
        type: SELECT_TYPE,
        typesData: typesDataCopy,
        selectedTypes: selectedTypesCopy,
    });
};

export const selectAllTypes = (
    check: boolean
): ThunkAction<void, DrawStore, unknown, SelectTypeAction> => async (
    dispatch,
    getState
) => {
    const { typesData, selectedTypes } = getState();

    let selectedTypesCopy: any[] = [];
    let typesDataCopy = typesData.map((type) => {
        type.Checked = check;
        if (check) {
            selectedTypesCopy.push(type.Type);
        }
        return type;
    });

    dispatch({
        type: SELECT_TYPE,
        typesData: typesDataCopy,
        selectedTypes: selectedTypesCopy,
    });
};

export const selectLevel = (
    levelName: string
): ThunkAction<void, DrawStore, unknown, SelectLevelAction> => async (
    dispatch,
    getState
) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    let selectedLevelIndex = levelsData.findIndex(
        (level) => level.Name === levelName
    );

    if (levelsData[selectedLevelIndex].Checked) {
        levelsDataCopy[selectedLevelIndex].Checked = false;
        selectedLevelsCopy = selectedLevels.filter(
            (level) => level !== levelName
        );
    } else {
        levelsDataCopy[selectedLevelIndex].Checked = true;
        selectedLevelsCopy = [...selectedLevels, levelName];
    }

    dispatch({
        type: SELECT_LEVEL,
        selectedLevels: selectedLevelsCopy,
        selectedTypes: [],
        levelsData: levelsDataCopy,
        typesData: elementTypes(
            levelData(selectionTree, selectedLevelsCopy)
        ) as TypeData[],
    });
};

export const selectAllLevels = (
    check: boolean
): ThunkAction<void, DrawStore, unknown, SelectLevelAction> => async (
    dispatch,
    getState
) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let selectedLevelsCopy: any[] = [];
    let levelsDataCopy = levelsData.map((level) => {
        level.Checked = check;
        if (check) {
            selectedLevelsCopy.push(level.Name);
        }
        return level;
    });
    dispatch({
        type: SELECT_LEVEL,
        selectedLevels: selectedLevelsCopy,
        selectedTypes: [],
        levelsData: levelsDataCopy,
        typesData: elementTypes(
            levelData(selectionTree, selectedLevelsCopy)
        ) as TypeData[],
    });
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English", "German"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage = (): ThunkAction<
    void,
    DrawStore,
    unknown,
    InitLanguageAction
> => async (dispatch) => {
    const setRevitLanguage = async () => {
        let revitLanguage = "English" as Language//(await api.queries.getRevitLanguage()) as Language;
        localStorage.setItem("savedLanguage", revitLanguage);
        dispatch({
            type: INIT_LANGUAGE,
            language: revitLanguage,
        });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
        if (isLanguage(savedLanguage)) {
            dispatch({
                type: INIT_LANGUAGE,
                language: savedLanguage,
            });
        } else {
            setRevitLanguage();
        }
    } else {
        setRevitLanguage();
    }
};

export const initGroupSelectorCeiling = (
    tree: ElementsTree
  ): InitGroupSelectorCeilingAction => {
    return {
      type: INIT_GROUP_SELECTOR_CEILING,
      tree,
    };
  };

  export const selectLevelCeilings = (
    levelName: string
  ): ThunkAction<void, DrawStore, unknown, SelectLevelCeilingAction> => async (
    dispatch,
    getState
  ) => {
    const {
      levelsDataCeilings,
      selectedLevelsCeilings,
      selectionTreeCeiling,
    } = getState();
    let levelsDataCopy = [...levelsDataCeilings];
    let selectedLevelsCopy = [...selectedLevelsCeilings];
  
    let selectedLevelIndex = levelsDataCeilings.findIndex(
      (level) => level.Name === levelName
    );
  
    if (levelsDataCeilings[selectedLevelIndex].Checked) {
      levelsDataCopy[selectedLevelIndex].Checked = false;
      selectedLevelsCopy = selectedLevelsCeilings.filter(
        (level) => level !== levelName
      );
    } else {
      levelsDataCopy[selectedLevelIndex].Checked = true;
      selectedLevelsCopy = [...selectedLevelsCeilings, levelName];
    }
  
    dispatch({
      type: SELECT_LEVEL_CEILING,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTreeCeiling, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

  export const selectAllLevelsCeilings = (
    check: boolean
  ): ThunkAction<void, DrawStore, unknown, SelectLevelCeilingAction> => async (
    dispatch,
    getState
  ) => {
    const { levelsDataCeilings, selectionTreeCeiling } = getState();
    let selectedLevelsCopy: any[] = [];
    let levelsDataCopy = levelsDataCeilings.map((level) => {
      level.Checked = check;
      if (check) {
        selectedLevelsCopy.push(level.Name);
      }
      return level;
    });
    dispatch({
      type: SELECT_LEVEL_CEILING,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTreeCeiling, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

  export const selectTypeCeilings = (
    typeName: string
  ): ThunkAction<void, DrawStore, unknown, SelectTypeCeilingAction> => async (
    dispatch,
    getState
  ) => {
    const { typesDataCeilings, selectedTypesCeilings } = getState();
    let typesDataCopy = [...typesDataCeilings];
    let selectedTypesCopy = [...selectedTypesCeilings];
  
    let selectedTypeIndex = typesDataCeilings.findIndex(
      (type) => type.Type === typeName
    );
  
    if (typesDataCeilings[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedTypesCeilings.filter(
        (type) => type !== typeName
      );
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedTypesCeilings, typeName];
    }
  
    dispatch({
      type: SELECT_TYPE_CEILING,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

  export const selectAllTypesCeilings = (
    check: boolean
  ): ThunkAction<void, DrawStore, unknown, SelectTypeCeilingAction> => async (
    dispatch,
    getState
  ) => {
    const { typesDataCeilings, selectedTypesCeilings } = getState();
  
    let selectedTypesCopy: any[] = [];
    let typesDataCopy = typesDataCeilings.map((type) => {
      type.Checked = check;
      if (check) {
        selectedTypesCopy.push(type.Type);
      }
      return type;
    });

    dispatch({
      type: SELECT_TYPE_CEILING,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };
  
