import React, { useState } from "react";
import {
  Container,
  Button,
  Message,
  Segment,
  Icon,
  Table,
  Modal,
  Grid,
} from "semantic-ui-react";
import ProductInfoItems from "./ProductInfoItems";
import productInfoView from "../Data/productInfoView.json";

interface Props {
  handleCloseProductInfoBox: any;
  showProductInfoBox: any;
}
const ProductInfoBox = (props: Props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const handleView = (e: any, i: any) => {
    setIsSelected(!isSelected);
    setIsActive(i);
    console.log("isActive", isActive);
    console.log("isSelected", isSelected);
  };
  return (
    <>
      <Modal
        size="large"
        open={props.showProductInfoBox}
        onClose={props.handleCloseProductInfoBox}
        //dimmer="inverted"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="text-center">
          <div className="product-header-name">
            {"productName"}
            {" - "}
            <span className="product-sub-header-name">{"business"}</span>
          </div>
        </Modal.Header>
        <Modal.Content className="product-content">
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={6}>
                <div style={{ overflowY: "auto", height: "60vh" }}>
                  <ProductInfoItems
                    handleView={handleView}
                    isSelected={isSelected}
                    isActive={isActive}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={10}>
                <Segment
                  className={
                    isSelected === true
                      ? "product-selected p-l-0"
                      : "divHide p-l-0"
                  }
                >
                  {/* <Segment className="product-selected p-l-0"> */}
                  <div
                    style={{
                      overflowY: "auto",
                      height: "55vh",
                      border: "1px solid rgba(34,36,38,.1)",
                    }}
                  >
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={{ width: "30%" }}>
                            Parameter
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{ width: "70%" }}>
                            Value
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {productInfoView.map((prod) => {
                          return (
                            <Table.Row>
                              <Table.Cell style={{ width: "30%" }}>
                                {prod.label}
                              </Table.Cell>
                              <Table.Cell style={{ width: "70%" }}>
                                {prod.value}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            content="Ok"
            onClick={props.handleCloseProductInfoBox}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProductInfoBox;
