import React from "react";
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Modal,
  Segment,
  Header,
  Form,
  Radio,
} from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "./FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { dbStoreNameCalepinage, Routes } from "./root";
import ReactHtmlParser from "react-html-parser";
import {
  setCalpinageSelection,
  ClearCalpinageSelection,
  saveCalpinageBuffer,
  resetCalepinageSelection,
} from "../Actions";
import "../Resources/SavedCalpinage.css";
import flattenDeep from "lodash/flattenDeep";
import map from "lodash/map";
import reduce from "lodash/reduce";
import find from "lodash/find";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import forEach from "lodash/forEach";
import merge from "lodash/merge";
import concat from "lodash/concat";
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import keysIn from "lodash/keysIn";
import pickBy from "lodash/pickBy";
import groupBy from "lodash/groupBy";
import { drawWall } from "../Helpers";
import {
  getLayoutAttributesAid,
  layoutGroup,
  layoutInGroup,
} from "../Requests";
import { BackButtonModal } from "./BackButtonModal";
import {
  setCalepinageSelection,
  setHistoryPath,
  setSelectorType,
} from "../../Selection/Actions";
import { setCalepinage, loadCalepinage } from "../Actions";
import { bimStorage } from "../../../../BIMStore";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { chunk, uniqBy } from "lodash";
import { asyncForEach } from "../../Quantitatiff/Extract/extractOptZone";
import {
  fetchPlacoDataInGroupSplit,
  parseSystemOptimized,
} from "../Helpers/placoHelper";
import { selectorTp } from "../../Selection/Actions/types";
import { fetchPlacoCeilingDataInGroupSplit } from "../Helpers/placoCeilingHelper";
import { onLinkClick } from "../../DocumentTechniques/Helpers";
import { Calepinage } from "../../../../Components/Calepinage1/calepinage";
import _ from "lodash";
import ltyoutJson from "./layoutJson.json";
import { CalpinageRevitApi } from "../../../../ErrorManagement/utils/revitApiError";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";
import { ScanSelectionController } from "./ScanSelection";

interface Props {
  Icon: string;
  name: string;
  wording: {
    title: { [key: string]: string };
    completedSteps: { [key: string]: string };
    paretage: { [key: string]: string };
    parzone: { [key: string]: string };
    selections: { [key: string]: string };
    calpinage: { [key: string]: string };
    back: { [key: string]: string };
    calepiner: { [key: string]: string };
    completed: { [key: string]: string };
    notCompleted: { [key: string]: string };
    loadingFamilies: { [key: string]: string };
    processing: { [key: string]: string };
    no: { [key: string]: string };
    yes: { [key: string]: string };
    backup: { [key: string]: string };
    description: { [key: string]: string };
    layoutSaved: { [key: string]: string };
    layoutSavedHeader: { [key: string]: string };
    save: { [key: string]: string };
    noWallsHeading: { [key: string]: string };
    noWalls: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  setCalpinageData: any;
  setSelectionTree: any;
  initGroupSelector: any;
  setCalpinageSelection: any;
  ClearCalpinageSelection: any;
  moduleData: any;
  saveCalpinageBuffer: any;
  selections: any;
  resetCalepinageSelection: any;
  setCalepinageSelection: any;
  setCalepinage: any;
  loadCalepinage: any;
  projectData: initProjectData;
  historyPath: string;
  setHistoryPath: any;
  setSelectorType: any;
  selectorType: selectorTp;
  config: any;
  onError: any;
}

export interface State {
  selectionDimmer: boolean;
  groupNumber: any;
  progress: number;
  processing: boolean;
  step: string;
  needUserInput: boolean;
  userInput: string;
  backupModal: boolean;
  stepsCompleted: Array<boolean>;
  active: boolean;
  noWallsSelected: boolean;
  saving: boolean;
  timer: number;
  timerName: string;
  timerId: NodeJS.Timeout | undefined;
  isSuccessful: boolean;
  isError: boolean;
  notFoundSolutions: string[];
}

export class CalpinageHomeOptimized extends React.Component<Props, State> {
  state = {
    selectionDimmer: false,
    groupNumber: 0,
    progress: 0,
    processing: false,
    step: "loading",
    needUserInput: false,
    userInput: "",
    backupModal: false,
    stepsCompleted: [
      this.props.moduleData.calpinageData.buffer.selections.status,
      this.props.moduleData.calpinageData.buffer.calepinage.status,
    ],
    active: false,
    noWallsSelected: false,
    saving: false,
    timer: 0,
    timerName: "",
    timerId: undefined,
    isSuccessful: false,
    isError: false,
    notFoundSolutions: [],
  };

  public static defaultProps = {
    wording: {
      title: {
        French: "CALEPINAGE",
        English: "LAYOUT",
      },
      completedSteps: {
        French: "étapes complétées",
        English: "completed steps",
      },
      paretage: {
        French: "Sélection",
        English: "Selection",
      },
      parzone: {
        French: "Zone",
        English: "Zone",
      },
      selections: {
        French: "Sélections",
        English: "Selections",
      },
      calpinage: {
        French: "Configuration de Calepinage",
        English: "Layout configuration",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      calepiner: {
        French: "Calepiner",
        English: "Calepine",
      },
      completed: {
        French: "Completed",
        English: "Completed",
      },
      notCompleted: {
        French: "Not Completed",
        English: "Not Completed",
      },
      loadingFamilies: {
        French: "Chargement des familles...",
        English: "Loading Families",
      },
      processing: {
        French: "Calepinage en cours: ",
        English: "Layout in progress: ",
      },
      no: {
        French: "Non",
        English: "No",
      },
      yes: {
        French: "Oui",
        English: "Yes",
      },
      backup: {
        French: "Sauvegarde",
        English: "Backup",
      },
      description: {
        French:
          "Voulez-vous enregistrer la configuration de calepinage  en cours de modification avant de quitter ?",
        English:
          "Do you want to save the configuration before exiting this menu?",
      },
      layoutSaved: {
        French: "Calepinage sauvegardé!",
        English: "Saved Layout!",
      },
      layoutSavedHeader: {
        French: "Sauver succès",
        English: "Layout saved successful",
      },
      save: {
        French: "Sauvegarder votre configuration",
        English: "Save your configuration",
      },
      noWalls: {
        French: "Aucun Murs n'a été sélectionné dans le projet",
        English: "No walls selected",
      },
      noWallsHeading: {
        French: "Aucun Murs",
        English: "No walls",
      },
    },
  };

  wait = (ms: number) => new Promise((r, j) => setTimeout(r, ms));
  CheckCalpinageSelectionList = (calpinageSelection: any) => {
    // if calepinage does not have selection
    if (calpinageSelection.length === 0) {
      this.setState({ stepsCompleted: [false, false] });
      return false;
    }

    return true;
  };

  CheckCalpinageList = (calpinageList: any) => {
    if (calpinageList.length === 0) {
      this.setState({ stepsCompleted: [true, false] });
    }
  };

  SetWallData = async (selections: any, calpinageList: any) => {
    // // Extract selected wall ids from selection.
    let wallRows = flattenDeep(
      map(selections, (ele) => {
        return ele.SelectionByType.wall.Rows;
      })
    );

    let wallIdsExt = flattenDeep(
      map(wallRows, (ele) => {
        return ele.RevitSelection.Ids;
      })
    );

    let dupliactesWallIds = keysIn(
      pickBy(groupBy(wallIdsExt), (x) => {
        return x.length > 1;
      })
    );
    if (dupliactesWallIds.length === 0) {
      let wallsData = await api.queries.getWallsData(wallIdsExt);
      //CalpinageRevitApi(this.props);
      let groupWallsData = reduce(
        wallsData,
        function (result: any, value: any) {
          // convert heignt m to mm
          let height: String = parseFloat(
            (value.Height / 1000).toString()
          ).toFixed(2);

          let wallRow = find(wallRows, (walRow: any) => {
            return includes(walRow.RevitSelection.Ids, value.Id);
          });

          const dd = {
            ...value,
            Height: height,
            LevelName: value.LevelName,
            PlacoSolution: wallRow.Options,
            layoutPossible:
              calpinageList[
                value.WallType + "-" + height + "-" + value.LevelId
              ][0].layoutPossible,
            montant:
              calpinageList[
                value.WallType + "-" + height + "-" + value.LevelId
              ][0].montant,
            plaque:
              calpinageList[
                value.WallType + "-" + height + "-" + value.LevelId
              ][0].plaque,
          };

          (
            result[value.WallType + "-" + height + "-" + value.LevelId] ||
            (result[value.WallType + "-" + height + "-" + value.LevelId] = [])
          ).push(dd);

          return result;
        },
        {}
      );

      this.props.setCalepinage(groupWallsData);
    }
  };

  UpdateCalpinageList = (
    calpinageSelectionList: any,
    selectionsList: any,
    calpinageList: any
  ) => {
    calpinageSelectionList.map((e: any) => {
      //check if any of the assigned selection is removed from seleciton module

      if (selectionsList[e.Id] === undefined) {
        this.setState({ stepsCompleted: [false, false] });
        return;
      }

      let SelectionKeysArr = [
        ...selectionsList[e.Id].SelectionByType.wall.Rows,
      ];
      //...e.SelectionByType.ceiling.Rows,
      //...selectionsList[e.Id].SelectionByType.others.Rows,

      let ExistingCalpinageSelectionArr = [...e.SelectionByType.wall.Rows];
      //...e.SelectionByType.ceiling.Rows,
      //...e.SelectionByType.others.Rows,

      let CombinedSelectionKeysArr = SelectionKeysArr.reduce((a, c) => {
        a.push(c.RevitSelection.RevitType);
        return a;
      }, []);

      let CombinedExistingCalpinageSelectionArr =
        ExistingCalpinageSelectionArr.reduce((a, c) => {
          a.push(c.RevitSelection.RevitType);
          return a;
        }, []);

      const NonExistingCalpinage = Object.keys(calpinageList).filter((e) => {
        return CombinedSelectionKeysArr.some((a: any) => !e.includes(a));
      });

      const ExistingCalpinage = Object.keys(calpinageList).filter((e) => {
        return CombinedSelectionKeysArr.some((a: any) => e.includes(a));
      });

      //check if selectionlist e.selectionByType all are inside current calepinage
      const ifAllSelectionKeyExistinCalpinage = CombinedSelectionKeysArr.every(
        (e: any) => {
          return CombinedSelectionKeysArr.every((a: any) => {
            return e.includes(a);
          });
        }
      );

      // if no walls/ceilings etc applied to the selection
      if (CombinedSelectionKeysArr.length === 0) {
        this.setState({ stepsCompleted: [false, false] });
        return;
      }

      if (!isEqual(e.SelectionByType, selectionsList[e.Id].SelectionByType)) {
        // set first step complete and second step incomplete
        this.setState({ stepsCompleted: [true, false] });

        // if selectionList and Calpinage Selection list is diffrent Update calpinage selection
        this.props.setCalepinageSelection([selectionsList[e.Id]]);

        if (
          isEqual(
            CombinedSelectionKeysArr,
            CombinedExistingCalpinageSelectionArr
          )
        ) {
          //alert('only quantity or solution has been modified, write function to update quantities')
          this.SetWallData([selectionsList[e.Id]], calpinageList);

          this.setState({ stepsCompleted: [true, true] });
          return;
        }

        // if Selection has more data i.e ceilings
        if (
          CombinedSelectionKeysArr.length >
          CombinedExistingCalpinageSelectionArr.length
        ) {
          // alert('The Selection has more wall/ceilings')
          return;
        }

        // checking if any walls or cieling has been removed and no new added then only remove the
        // the deleted ceiling or wall and make both the steps complete.
        if (
          ifAllSelectionKeyExistinCalpinage &&
          NonExistingCalpinage.length > 0
        ) {
          // if there are existing calpinage walls which is removed from selection module

          let updatedCalpinage = calpinageList;
          NonExistingCalpinage.map((e) => {
            delete updatedCalpinage[e];
          });
          this.props.setCalepinage(updatedCalpinage);
          this.setState({ stepsCompleted: [true, true] });
        }
      }
    });
  };

  ClearModuleDataCalepinage = () => {
    Promise.resolve(this.props.ClearCalpinageSelection()).then(() => {
      this.setState({
        stepsCompleted: [
          this.props.moduleData.calpinageData.buffer.selections.status,
          false,
        ],
      });
    });
  };

  componentDidMount = () => {
    console.log("in home optimized");
    this.isSelectionPresent(
      this.props.moduleData.calpinageData.buffer.selections.list,
      this.props.selections
    );

    if (
      this.state.stepsCompleted[0] === true ||
      this.state.stepsCompleted[1] === true
    ) {
      localStorage.removeItem("layoutStep2");
      this.onAutoSave();
    }
    this.props.setSelectorType("wall");
  };

  manualSelectionHandler = async () => {
    this.props.setCalpinageSelection(false);
    this.actionHandler(Routes.CAL_SELECTION_HOME);
  };

  groupSelectionHandler = async () => {
    this.props.setCalpinageSelection(true);
    this.actionHandler(Routes.CAL_SELECTION_HOME);
  };

  onBackButtonClick = async () => {
    const ExistingCalpinage =
      this.props.moduleData.calpinageData.data[
        this.props.moduleData.calpinageData.buffer.Id
      ];

    if (ExistingCalpinage) {
      const bufferCalepinage = this.props.moduleData.calpinageData.buffer;

      if (ExistingCalpinage) {
        const isCurrentCalpinageEqualToExisting = isEqual(
          bufferCalepinage.calepinage,
          ExistingCalpinage.calepinage
        );

        const isCurrentSelectionsEqualToExisting = isEqual(
          bufferCalepinage.selections,
          ExistingCalpinage.selections
        );

        /* if changeFlag value is false only then show backupModal i.e if layout is not saved or any change is made then changefalf value is true otherwise false */
        if (
          isCurrentCalpinageEqualToExisting &&
          isCurrentSelectionsEqualToExisting
        ) {
          this.actionHandler(Routes.ROOT);
        } else {
          this.setState({ backupModal: true });
        }
      } else {
        this.setState({ backupModal: true });
      }
    } else {
      this.setState({ backupModal: true });
    }
  };

  closeBackupModal = () => {
    this.setState({ backupModal: false });
  };

  toggleDimmer = (action: any) => {
    this.setState({
      selectionDimmer: action,
    });
  };

  actionHandler = (action: any) => {
    this.props.setRoute(action);
  };

  setGroupNumber = (value: any) => {
    this.setState({ groupNumber: value });
  };

  setIncrement = (incr: number) => {
    this.setState({ progress: incr });
  };

  setStep = (value: string) => {
    this.setState({ step: value });
  };

  ceilingValidationHandle = async (savedCeilings: any) => {
    let solutionIds = map(savedCeilings, "PlacoSolutionId");
    let uniqueSolutionIds = uniq(solutionIds);
    const { extractedSolutionProduct } =
      await fetchPlacoCeilingDataInGroupSplit(
        uniqueSolutionIds,
        this.props.config
      );

    let ceilingIds = map(savedCeilings, "Ids");
    let uniqueCeilingIds = flattenDeep(ceilingIds);

    let ceilingDetails = await api.queries.getCeilingsData(uniqueCeilingIds);
    //CalpinageRevitApi(this.props);
    interface celDetailObjectType {
      Id: string;
      InnerContours: any;
      LevelId: string;
      NormalVector: any;
      OuterContour: any;
      params: paramsCelDet;
    }

    interface boardType {
      length: number;
      name: string;
      thickness: number;
      width: number;
    }
    interface paramsCelDet {
      board1: boardType | undefined;
      board2: boardType | undefined;
      boardPerpendicular: boolean;
      boardStagerredXOffset: number;
      boardStagerredYOffset: number;
      boardStaggered: boolean;
      configuration: string;
      contour: {
        entraxeMax: number;
        height: number;
        length: number;
        name: string;
        productName: string;
        width: number;
      };
      hanger: {
        maxDistanceBetween: number;
        maxDistanceFromContour: number;
        name: string;
      };
      hangerType: string;
      heightUnderCeiling: number;
      rotation: number;
      runners: [
        {
          entraxeMax: number;
          height: number;
          length: number;
          name: string;
          productName: string;
          width: number;
        }
      ];
      solutionName: string;
    }
    forEach(ceilingDetails, (celDet, celIndex) => {
      let ceilingDetailObject: celDetailObjectType = {
        Id: celDet.Id,
        InnerContours: celDet.InnerContours,
        LevelId: celDet.LevelId,
        NormalVector: celDet.NormalVector,
        OuterContour: celDet.OuterContour,
        params: {
          board1: {
            length: 0,
            name: "",
            thickness: 0,
            width: 0,
          },
          board2: {
            length: 0,
            name: "",
            thickness: 0,
            width: 0,
          },
          boardPerpendicular: true,
          boardStagerredXOffset: 0,
          boardStagerredYOffset: 0,
          boardStaggered: true,
          configuration: "string",
          contour: {
            entraxeMax: 0,
            height: 0,
            length: 0,
            name: "",
            productName: "",
            width: 0,
          },
          hanger: {
            maxDistanceBetween: 0,
            maxDistanceFromContour: 0,
            name: "",
          },
          hangerType: "",
          heightUnderCeiling: 0,
          rotation: 0,
          runners: [
            {
              entraxeMax: 0,
              height: 0,
              length: 0,
              name: "",
              productName: "",
              width: 0,
            },
          ],
          solutionName: "",
        },
      };

      const ceilingConfiguration = find(savedCeilings, (value, index) => {
        return includes(value.Ids, celDet.Id);
      });

      const appliedSolution = find(extractedSolutionProduct, (value, index) => {
        return (
          value.solutionProduct.oid === ceilingConfiguration.PlacoSolutionId
        );
      });

      ceilingDetailObject.params.solutionName =
        appliedSolution.solutionProduct.translation;
      const solutionProductAttributes =
        appliedSolution.solutionProduct.attributes;

      const plaques = filter(appliedSolution.products, { type: "plaque" });

      const bimLayers = filter(solutionProductAttributes, {
        technicalName: "GFR-BIM layers",
      });

      bimLayers.sort(function (a: any, b: any) {
        let a_number = a.subAttributes.find(
          (e: any) => e.technicalName === "GFR-Layer number"
        ).values[0].numericValue;
        let b_number = b.subAttributes.find(
          (e: any) => e.technicalName === "GFR-Layer number"
        ).values[0].numericValue;
        return a_number - b_number;
      });

      let firstCladding = true;
      let board1: boardType | undefined, board2: boardType | undefined;

      for (let j = 0; j < bimLayers.length; j++) {
        let layerAttributes = bimLayers[j].subAttributes;
        let layerContent = layerAttributes.find(
          (e: any) => e.technicalName === "GFR-Layer content"
        ).values[0].value;

        if (layerContent === "Plaque de plâtre") {
          let layerThickness = layerAttributes.find(
            (e: any) => e.technicalName === "GFR-Layer thickness mm"
          ).values[0].numericValue;
          let layerName = layerAttributes.find(
            (e: any) => e.technicalName === "GFR-Layer name"
          ).values[0].value;

          if (firstCladding) {
            ceilingDetailObject.params.board1 = {
              length: 0,
              name: layerName,
              thickness: layerThickness,
              width: 0,
            };
          } else {
            ceilingDetailObject.params.board2 = {
              length: 0,
              name: layerName,
              thickness: layerThickness,
              width: 0,
            };
          }
        } else {
          firstCladding = false;
        }
      }

      if (plaques.length > 1) {
      }
      // let params: paramsCelDet = {
      //   board1: board1,
      //   board2: board2,
      //   solutionName: ceilingConfiguration.PlacoSolution,
      // };
    });
  };

  startTimer = (name: string) => {
    var intervalId = setInterval(() => {
      this.setState({ timer: this.state.timer + 1, timerName: name });
    }, 1000);
    this.setState({ timerId: intervalId });
  };

  endTimer = () => {
    clearInterval(this.state.timerId);
    this.setState({ timer: 0, timerName: "", timerId: undefined });
  };

  isSelectionPresent = (
    bufferSelectionLit: any,
    selectedSelectionList: any
  ) => {
    const selectionIds = bufferSelectionLit.map((ls: any) => ls.Id);

    const selns = filter(selectedSelectionList, (sl: any) => {
      return selectionIds.includes(sl.Id);
    });
    if (selns.length === 0) {
      this.setState({ stepsCompleted: [false, false] });
    }
  };

  extractTransalationNames = (
    bufferSelectionLit: any,
    selectedSelectionList: any,
  ) => {
    const selectionIds = bufferSelectionLit.map((ls: any) => ls.Id);

    const selns = filter(selectedSelectionList, (sl: any) => {
      return selectionIds.includes(sl.Id);
    });
    const wlRows = flattenDeep(
      selns.map((sn: any) => sn.SelectionByType.wall.Rows)
    );

    const clRows = flattenDeep(
      selns.map((sn: any) => sn.SelectionByType.ceiling.Rows)
    );

    const solutionTechnicalNames = uniqBy(
      wlRows.map((fg: any) => {
        return {
          oid: fg.Options.MappedSystem.oid,
          technicalName: fg.Options.MappedSystem.longName?fg.Options.MappedSystem.longName:fg.Options.MappedSystem.translation,
        };
      }),
      "oid"
    );

    const ceilingSolutionTechnicalNames = uniqBy(
      clRows.map((fg: any) => {
        return {
          oid: fg.Options.MappedSystem.oid,
          technicalName: fg.Options.MappedSystem.longName,
        };
      }),
      "oid"
    );

    return solutionTechnicalNames.concat(ceilingSolutionTechnicalNames);
  }

  extractTechnicalNames = (
    bufferSelectionLit: any,
    selectedSelectionList: any
  ) => {
    const selectionIds = bufferSelectionLit.map((ls: any) => ls.Id);

    const selns = filter(selectedSelectionList, (sl: any) => {
      return selectionIds.includes(sl.Id);
    });
    const wlRows = flattenDeep(
      selns.map((sn: any) => sn.SelectionByType.wall.Rows)
    );

    const clRows = flattenDeep(
      selns.map((sn: any) => sn.SelectionByType.ceiling.Rows)
    );

    const solutionTechnicalNames = uniqBy(
      wlRows.map((fg: any) => {
        return {
          oid: fg.Options.MappedSystem.oid,
          technicalName: fg.Options.MappedSystem.technicalName,
        };
      }),
      "oid"
    );

    const ceilingSolutionTechnicalNames = uniqBy(
      clRows.map((fg: any) => {
        return {
          oid: fg.Options.MappedSystem.oid,
          technicalName: fg.Options.MappedSystem.technicalName,
        };
      }),
      "oid"
    );

    return solutionTechnicalNames.concat(ceilingSolutionTechnicalNames);
  };

  validationHandler = async (method: string) => {
    try {
      this.setState({ processing: true, step: "checking" });
      let calepinagelistArray: any[] = [];
      let nonCalepinagelistArray: any[] = [];
      let nonCalepinageCeilingArray: any[] = [];

      let savedWalls = JSON.parse(
        JSON.stringify(
          this.props.moduleData.calpinageData.buffer.calepinage.list.walls
        )
      );

      const savedCeilings = JSON.parse(
        JSON.stringify(
          this.props.moduleData.calpinageData.buffer.calepinage.list.ceilings
        )
      );

      let eventData: any = [];

      const solutionTechnicalNames: any = this.extractTechnicalNames(
        this.props.moduleData.calpinageData.buffer.selections.list,
        this.props.selections
      );
      
      const solutionTransalationNames: any = this.extractTransalationNames(
        this.props.moduleData.calpinageData.buffer.selections.list,
        this.props.selections
      );

      forEach(savedCeilings, (value: any) => {
        if (value.chk) {
          forEach(value.Ids, (id, ind) => {
            const techname = solutionTechnicalNames.find(
              (sln: any) => sln.oid === value.PlacoSolutionId
            );

            nonCalepinageCeilingArray.push({
              LevelElevation: value.LevelElevation,
              LevelId: value.LevelId,
              LevelName: value.LevelName,
              PlacoSolution: techname?.technicalName,
              PlacoSolutionHeight: "0.00",
              PlacoSolutionId: value.PlacoSolutionId,
              CeilingType: value.CeilingType,
              Zone: value.Zone,
              montant: value.montant,
              plaque: value.plaque,
              Id: id,
              Height: value.Height,
            });
          });
        }
      });

      forEach(savedWalls, (value: any) => {
        if (value.layoutPossible && value.placo && value.chk) {
          forEach(value.Ids, (id, ind) => {
            const techname = solutionTransalationNames.find(
              (sln: any) => sln.oid === value.PlacoSolutionId
            );
            calepinagelistArray.push({
              LevelElevation: value.LevelElevation,
              LevelId: value.LevelId,
              LevelName: value.LevelName,
              PlacoSolution: techname?.technicalName,
              PlacoSolutionHeight: value.PlacoSolutionHeight,
              PlacoSolutionId: value.PlacoSolutionId,
              WallType: value.WallType,
              Zone: value.Zone,
              montant: ["Montant Hydrostil®+ M48/249 bot10L"],
              plaque: ["Glasroc® H Ocean 13 120/250"],
              Id: id,
              Height: value.Height,
            });
          });
        } else if (!value.layoutPossible && value.placo && value.chk) {
          forEach(value.Ids, (id, ind) => {
            const techname = solutionTechnicalNames.find(
              (sln: any) => sln.oid === value.PlacoSolutionId
            );

            nonCalepinagelistArray.push({
              LevelElevation: value.LevelElevation,
              LevelId: value.LevelId,
              LevelName: value.LevelName,
              PlacoSolution: techname?.technicalName,
              PlacoSolutionHeight: value.PlacoSolutionHeight,
              PlacoSolutionId: value.PlacoSolutionId,
              WallType: value.WallType,
              Zone: value.Zone,
              montant: value.montant,
              plaque: value.plaque,
              Id: id,
              Height: value.Height,
            });
          });
        }
      });

      let wallIds: any[] = [];
      let processedWallIds: any[] = [];
      let ceilingIds: any[] = [];
      let processedCeilingIds: any[] = [];

      try {
        if (nonCalepinageCeilingArray.length > 0) {
          forEach(
            nonCalepinageCeilingArray,
            (calepinageCeilingMember, groupIndex) => {
              if (calepinageCeilingMember.Zone) {
                calepinageCeilingMember.WallType =
                  calepinageCeilingMember.WallType +
                  " - " +
                  calepinageCeilingMember.Height +
                  " - " +
                  calepinageCeilingMember.LevelName +
                  " - " +
                  calepinageCeilingMember.Zone;
              } else {
                calepinageCeilingMember.WallType =
                  calepinageCeilingMember.WallType +
                  " - " +
                  calepinageCeilingMember.Height +
                  " - " +
                  calepinageCeilingMember.LevelName;
              }
              // ceilings.push(calepinageCeilingMember);
              merge(ceilingIds, concat(ceilingIds, calepinageCeilingMember.Id));
            }
          );

          if (ceilingIds.length > 0) {
            processedCeilingIds = await api.queries.filterElements(
              "Ceiling",
              [
                {
                  Param: { Name: "Processed", Type: "Boolean", Value: true },
                  Rule: "Equals",
                },
              ],
              null
            );
            //CalpinageRevitApi(this.props);
          }
        }

        if (
          calepinagelistArray.length > 0 ||
          nonCalepinagelistArray.length > 0
        ) {
          let walls: any[] = [];
          forEach(calepinagelistArray, (calepinageListMember, groupIndex) => {
            if (calepinageListMember.Zone) {
              calepinageListMember.WallType =
                calepinageListMember.WallType +
                " - " +
                calepinageListMember.Height +
                " - " +
                calepinageListMember.LevelName +
                " - " +
                calepinageListMember.Zone;
            } else {
              calepinageListMember.WallType =
                calepinageListMember.WallType +
                " - " +
                calepinageListMember.Height +
                " - " +
                calepinageListMember.LevelName;
            }
            // walls.push(calepinageListMember);
            merge(wallIds, concat(wallIds, calepinageListMember.Id));
          });

          forEach(
            nonCalepinagelistArray,
            (calepinageListMember, groupIndex) => {
              if (calepinageListMember.Zone) {
                calepinageListMember.WallType =
                  calepinageListMember.WallType +
                  " - " +
                  calepinageListMember.Height +
                  " - " +
                  calepinageListMember.LevelName +
                  " - " +
                  calepinageListMember.Zone;
              } else {
                calepinageListMember.WallType =
                  calepinageListMember.WallType +
                  " - " +
                  calepinageListMember.Height +
                  " - " +
                  calepinageListMember.LevelName;
              }
              // walls.push(calepinageListMember);
              merge(wallIds, concat(wallIds, calepinageListMember.Id));
            }
          );

          if (wallIds.length > 0) {
            processedWallIds = await api.queries.filterElements(
              "Wall",
              [
                {
                  Param: { Name: "Processed", Type: "Boolean", Value: true },
                  Rule: "Equals",
                },
              ],
              null
            );
          }
          //CalpinageRevitApi(this.props);
        }

        if (
          ceilingIds.some((item) =>
            processedCeilingIds.includes(item.toString())
          ) ||
          wallIds.some((item) => processedWallIds.includes(item.toString()))
        ) {
          this.setState({ processing: false });
          this.setState({
            needUserInput: true,
          });
          await this.wait(100);

          while (this.state.needUserInput) {
            await this.wait(400);
          }

          this.setState({ processing: true });
          this.setState({ step: "" });

          if (this.state.userInput === "keep") {
            // if keep remove processed wall ids

            if (calepinagelistArray.length > 0) {
              calepinagelistArray = filter(calepinagelistArray, (member) => {
                if (!processedWallIds.includes(member.Id)) return member;
              });
            }
            if (nonCalepinagelistArray.length > 0) {
              nonCalepinagelistArray = filter(
                nonCalepinagelistArray,
                (member) => {
                  if (!processedWallIds.includes(member.Id)) return member;
                }
              );
            }

            if (nonCalepinagelistArray.length > 0) {
              nonCalepinageCeilingArray = filter(
                nonCalepinageCeilingArray,
                (member) => {
                  if (!processedCeilingIds.includes(member.Id)) return member;
                }
              );
            }
          } else if (this.state.userInput === "erase") {
            // if erase delete layouted wall elements

            let selectedElems: any[] = [];
            let selectedWallElems: any[] = [];
            let selectedWallNonCalElems: any[] = [];
            let selectedCeilingNonCalElems: any[] = [];
            if (calepinagelistArray.length > 0) {
              selectedWallElems = map(
                filter(calepinagelistArray, (member) => {
                  return processedWallIds.includes(member.Id);
                }),
                "Id"
              );
              selectedElems = selectedElems.concat(selectedWallElems);
            }
            if (nonCalepinagelistArray.length > 0) {
              selectedWallNonCalElems = map(
                filter(nonCalepinagelistArray, (member) => {
                  return processedWallIds.includes(member.Id);
                }),
                "Id"
              );
              selectedElems = selectedElems.concat(selectedWallNonCalElems);
            }

            if (nonCalepinageCeilingArray.length > 0) {
              selectedCeilingNonCalElems = map(
                filter(nonCalepinageCeilingArray, (member) => {
                  return processedCeilingIds.includes(member.Id);
                }),
                "Id"
              );
              selectedElems = selectedElems.concat(selectedCeilingNonCalElems);
            }

            if (selectedElems.length) {
              let elemsArr: string[] = [];
              await asyncForEach(selectedElems, async (elem: any) => {
                await api.familyEditor.setParams([
                  {
                    Id: elem,
                    Params: [
                      { Name: "Processed", Type: "YesNo", Value: 0 },
                      { Name: "Plaque", Type: "Text", Value: "" },
                      { Name: "Montant", Type: "Text", Value: "" },
                      { Name: "SG_System", Type: "Text", Value: " " },
                      { Name: "IsLayout", Type: "YesNo", Value: 0 },
                    ],
                  },
                ]);
                //CalpinageRevitApi(this.props);
                const elems = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: parseInt(elem),
                      },
                      Rule: "Equals",
                    },
                  ],
                  null
                );

                elemsArr = elemsArr.concat(elems);
                //CalpinageRevitApi(this.props);
              });
              if (elemsArr && elemsArr.length > 0)
                await api.familyEditor.deleteElementsByIds(elemsArr);
              //CalpinageRevitApi(this.props);
            }
          }
        }

        if (nonCalepinagelistArray.length > 0) {
          const nonCalepinageWalls = nonCalepinagelistArray.map((ele) => {
            return {
              Id: ele.Id,
              Params: [
                { Name: "Processed", Type: "YesNo", Value: 1 },
                {
                  Name: "SG_System",
                  Type: "Text",
                  Value: ele.PlacoSolution,
                },
                {
                  Name: "Flipped",
                  Type: "YesNo",
                  Value: 0,
                },
                {
                  Name: "Reverse",
                  Type: "YesNo",
                  Value: 0,
                },
                {
                  Name: "Plaque",
                  Type: "Text",
                  Value: ele.plaque.join(";"),
                },
                {
                  Name: "Montant",
                  Type: "Text",
                  Value: ele.montant.join(";"),
                },
                {
                  Name: "IsLayout",
                  Type: "YesNo",
                  Value: 0,
                },
              ],
            };
          });
          await api.familyEditor.createAndSetParameters(
            "Wall",
            "Instance",
            "DATA",
            nonCalepinageWalls
          );
          //CalpinageRevitApi(this.props);
        }

        if (nonCalepinageCeilingArray.length > 0) {
          const nonCalepinageCeilings = nonCalepinageCeilingArray.map((ele) => {
            return {
              Id: ele.Id,
              Params: [
                { Name: "Processed", Type: "YesNo", Value: 1 },
                {
                  Name: "SG_System",
                  Type: "Text",
                  Value: ele.PlacoSolution,
                },
                {
                  Name: "Flipped",
                  Type: "YesNo",
                  Value: 0,
                },
                {
                  Name: "Reverse",
                  Type: "YesNo",
                  Value: 0,
                },
                {
                  Name: "Plaque",
                  Type: "Text",
                  Value: ele.plaque.join(";"),
                },
                {
                  Name: "Montant",
                  Type: "Text",
                  Value: ele.montant.join(";"),
                },
                {
                  Name: "IsLayout",
                  Type: "YesNo",
                  Value: 0,
                },
              ],
            };
          });
          await api.familyEditor.createAndSetParameters(
            "Ceiling",
            "Instance",
            "DATA",
            nonCalepinageCeilings
          );
          //CalpinageRevitApi(this.props);
        }

        if (calepinagelistArray.length > 0) {
          let placoSystemOids: string[] = map(
            calepinagelistArray,
            "PlacoSolutionId"
          );

          let uniqPlacoSystemsOids: string[] = uniq(placoSystemOids);
          console.time("Placo solution details");
          const extractedSolutionProduct = await fetchPlacoDataInGroupSplit(
            uniqPlacoSystemsOids,
            this.props.config
          )
            .then(({ extractedSolutionProduct }) => {
              return extractedSolutionProduct;
            })
            .catch((error) => {
              console.log("error in 1252:", error);

              return Promise.reject({
                code: errorCodeKey.PB_LT_H_001,
                stack: error.stack,
              });
            });
          console.timeEnd("Placo solution details");

          if (extractedSolutionProduct.length < uniqPlacoSystemsOids.length) {
            var arr = uniqPlacoSystemsOids.filter(function (item) {
              return !extractedSolutionProduct.some(
                (e: any) => e.solutionProduct.oid === item
              );
            });

            // arr =["154428"]
            if (arr && arr.length > 0) {
              let notFoundCalpinageList = calepinagelistArray.filter(
                (c: any) => {
                  return arr.some((a: any) => a === c.PlacoSolutionId);
                }
              );
              if (notFoundCalpinageList && notFoundCalpinageList.length > 0) {
                let solutions = notFoundCalpinageList.map(
                  (n: any) => n.PlacoSolution
                );
                solutions = uniq(solutions);
                this.setState({ notFoundSolutions: solutions });
              }
            }
          }

          console.time("Creating Request");
          let preWallsMatching: any[] = [];

          forEach(extractedSolutionProduct, (extData, index) => {
            if (extData.layoutPossible === "true") {
              console.log(
                "Attributes for solution ",
                extData.solutionProduct.technicalName
              );

              try {
                let attributesId: any = getLayoutAttributesAid(
                  extData.attributes
                );
                let prSystem = parseSystemOptimized(
                  extData.solutionProduct,
                  extData.products,
                  attributesId,
                  false,
                  false
                );
                if (prSystem) {
                  preWallsMatching.push(prSystem);
                }
              } catch (error) {
                console.error(
                  "last attribute is missing for above solution - ",
                  extData.solutionProduct.technicalName
                );
                //throw error;
              }
            }
          });

          // create final wallmatching array
          let wallsMatching: any[] = [];

          map(savedWalls, (calepinageListMember, groupIndex) => {
            if (
              calepinageListMember.layoutPossible &&
              calepinageListMember.placo &&
              calepinageListMember.chk
            ) {
              const trasaltedSolution = solutionTransalationNames.find(
                (sln: any) => sln.oid === calepinageListMember.PlacoSolutionId
              );
              const fnPreWallMatch = find(preWallsMatching, {
                systemName: trasaltedSolution.technicalName,
              });
              if (fnPreWallMatch) {
                let wmatch = JSON.parse(JSON.stringify(fnPreWallMatch));

                if (wmatch) {
                  if (calepinageListMember.Zone) {
                    let inputTypeKey =
                      calepinageListMember.WallType +
                      " - " +
                      calepinageListMember.Height +
                      " - " +
                      calepinageListMember.LevelName +
                      " - " +
                      calepinageListMember.Zone;

                    wmatch.InputType = inputTypeKey;
                    calepinageListMember.WallType = inputTypeKey;
                  } else {
                    let inputTypeKey =
                      calepinageListMember.WallType +
                      " - " +
                      calepinageListMember.Height +
                      " - " +
                      calepinageListMember.LevelName;

                    wmatch.InputType = inputTypeKey;
                    calepinageListMember.WallType = inputTypeKey;
                  }

                  wallsMatching.push({ ...wmatch });
                }
              }
            }
          });

          map(wallsMatching, (wallMatch: any, wallMatchIndex: number) => {
            let extSolProd = find(extractedSolutionProduct, function (o) {
              return o.solutionProduct.translation === wallMatch.systemName;
            });

            let plaqArticlesWithProduct = filter(extSolProd.products, {
              type: "plaque",
            });

            let calepinageListMember: any = find(savedWalls, {
              WallType: wallMatch.InputType,
            });

            if (wallMatch[`E1`]) {
              /// Set plaqs in E1 and E2
              if (calepinageListMember.plaque.length > 0) {
                const e1name = find(
                  calepinageListMember.plaque,
                  function (plq) {
                    return includes(
                      plq.toString().replace(/\s+/g, ""),
                      wallMatch[`E1`][`name`].toString().replace(/\s+/g, "")
                    );
                  }
                );
                wallMatch[`E1`][`name`] = e1name;
                // matchesCase(calepinageListMember.plaque, wallMatch[`E1`][`name`]);
              } else {
                wallMatch[`E1`] = undefined;
              }
            }

            if (wallMatch[`E2`]) {
              /// if plaque length is equal to 1
              /// then for E2_Name set E1_Name
              wallMatch[`E2`][`name`] = find(
                calepinageListMember.plaque,
                function (plq) {
                  return includes(
                    plq.toString().replace(/\s+/g, ""),
                    wallMatch[`E2`][`name`].toString().replace(/\s+/g, "")
                  );
                }
              );
            }

            if (wallMatch[`E3`]) {
              wallMatch[`E3`][`name`] =
                wallMatch[`E3`][`name`] !== "None" &&
                find(calepinageListMember.plaque, function (plq) {
                  return includes(
                    plq.toString().replace(/\s+/g, ""),
                    wallMatch[`E3`][`name`].toString().replace(/\s+/g, "")
                  );
                });
            }

            // calepinageListMember[0].plaque.length > 1
            //   ? calepinageListMember[0].plaque[1]
            //   : wallMatch[`E1_Name`];

            /// extract plaq board height
            let plaqArticleWithProduct =
              plaqArticlesWithProduct.length > 1
                ? filter(plaqArticlesWithProduct, function (o) {
                    const isHaveArticle = find(o.articles, (oa) => {
                      return includes(calepinageListMember.plaque, oa.value);
                    });
                    if (isHaveArticle) {
                      return true;
                    }
                    return false;
                    // return o.product.translation.includes(
                    //   calepinageListMember.plaque[0].split(" ")[0]
                    // );
                  })
                : [plaqArticlesWithProduct[0]];

            if (wallMatch[`I1`]) {
              if (calepinageListMember.plaque.length > 0) {
                wallMatch[`I1`][`name`] = find(
                  calepinageListMember.plaque,
                  function (plq) {
                    return includes(
                      plq.toString().replace(/\s+/g, ""),
                      wallMatch[`I1`][`name`].toString().replace(/\s+/g, "")
                    );
                  }
                );
              } else {
                wallMatch[`I1`] = undefined;
              }
            }

            if (wallMatch[`I2`]) {
              wallMatch[`I2`][`name`] = find(
                calepinageListMember.plaque,
                function (plq) {
                  return includes(
                    plq.toString().replace(/\s+/g, ""),
                    wallMatch[`I2`][`name`].toString().replace(/\s+/g, "")
                  );
                }
              );
            }

            if (wallMatch[`I3`]) {
              wallMatch[`I3`][`name`] =
                wallMatch[`I3`][`name`] !== "None" &&
                find(calepinageListMember.plaque, function (plq) {
                  return includes(
                    plq.toString().replace(/\s+/g, ""),
                    wallMatch[`I3`][`name`].toString().replace(/\s+/g, "")
                  );
                });
            }

            const prArray = ["E1", "E2", "E3", "I1", "I2", "I3"];

            forEach(prArray, function (value) {
              if (wallMatch[value]) {
                if (wallMatch[value][`name`]) {
                  let e1BoardHeight = wallMatch[value][`board_height`];
                  let plaqE1Article = find(
                    flattenDeep(map(plaqArticleWithProduct, "articles")),
                    function (o) {
                      return o.value === wallMatch[value][`name`];
                    }
                  );
                  if (plaqE1Article) {
                    let articleAttributes = find(
                      plaqE1Article.article.attributes,
                      {
                        technicalName: "GFR-Length of the UB unpacked (m)",
                      }
                    );
                    if (articleAttributes) {
                      let heightinmm =
                        articleAttributes.values[0].numericValue * 1000;
                      e1BoardHeight = heightinmm;
                    }
                  }

                  wallMatch[value][`board_height`] = e1BoardHeight;
                }
              }
            });

            wallMatch.Frame1_Name = calepinageListMember.montant[0];
            let montantArticlesWithProduct = find(extSolProd.products, {
              type: "montant",
            });

            if (montantArticlesWithProduct) {
              let montantArticle = find(
                montantArticlesWithProduct.articles,
                function (o) {
                  return o.value === calepinageListMember.montant[0];
                }
              );
              if (montantArticle) {
                let articleAttributes = find(
                  montantArticle.article.attributes,
                  {
                    technicalName: "GFR-Length of the UB unpacked (m)",
                  }
                );
                if (articleAttributes) {
                  let heightinmm =
                    articleAttributes.values[0].numericValue * 1000;
                  wallMatch.frame_length = heightinmm;
                }
              }
            }

            let rails = filter(extSolProd.products, { type: "rail" });
            wallMatch.Rail1_Name = this.extractRails(rails);
            wallMatch.technicalName = extSolProd.solutionProduct.technicalName;
            wallMatch.InputType = calepinageListMember.WallType;

            return wallMatch;
          });

          console.timeEnd("Creating Request");

          console.time("Family loading");

          this.setState({ step: "familyLoading" });

          this.setState({ step: "" });

          let finalWallIds = map(calepinagelistArray, "Id");
          let finalWallDetails = await api.queries.getWallsData(finalWallIds);
          //CalpinageRevitApi(this.props);
          let uniqueLevelIds = uniq(map(calepinagelistArray, "LevelId"));
          let wallsDataByLevel = await api.queries.getWallsDataByLevel(
            uniqueLevelIds
          );
          //CalpinageRevitApi(this.props);
          let finalWalls: any[] = [];
          forEach(finalWallDetails, (finalWallDetail, finalWallDetailIndex) => {
            finalWallDetail.WallType = find(calepinagelistArray, {
              Id: finalWallDetail.Id,
            }).WallType;

            const presentInWallsMatching = find(wallsMatching, {
              InputType: finalWallDetail.WallType,
            });
            if (presentInWallsMatching) {
              finalWalls.push(finalWallDetail);
            }
          });

          console.time("Layout process");

          // Remove by filtering wallsMatching which were not having plaque in E1 and I1

          const undefinedWall = filter(wallsMatching, (wmatch) => {
            return wmatch.E1 === undefined && wmatch.I1 === undefined;
          });

          if (undefinedWall && undefinedWall.length > 0) {
            let notFoundCalpinageList = calepinagelistArray.filter((c: any) => {
              return undefinedWall.some(
                (a: any) => a.systemName === c.PlacoSolution
              );
            });
            if (notFoundCalpinageList && notFoundCalpinageList.length > 0) {
              let solutions = notFoundCalpinageList.map(
                (n: any) => n.PlacoSolution
              );

              let finalNotCalpinagedSolutions = solutions.concat(
                this.state.notFoundSolutions
              );
              finalNotCalpinagedSolutions = uniq(finalNotCalpinagedSolutions);
              this.setState({
                notFoundSolutions: finalNotCalpinagedSolutions,
              });
            }
          }

          wallsMatching = filter(wallsMatching, (wmatch) => {
            return wmatch.E1 !== undefined || wmatch.I1 !== undefined;
          });

          finalWalls = filter(finalWalls, (fnwall) => {
            return (
              find(undefinedWall, (unwall) => {
                return unwall.InputType === fnwall.WallType;
              }) === undefined
            );
          });

          const finalWallsByLevel = finalWalls.reduce(
            (accumulator, currentObject) => {
              const { LevelName } = currentObject;
              if (!accumulator[LevelName]) {
                accumulator[LevelName] = [];
              }
              accumulator[LevelName].push(currentObject);
              return accumulator;
            },
            {}
          );

          let layoutData: any[] = [];

          for (let level in finalWallsByLevel) {
            let currFinalWalls = finalWallsByLevel[level];

            let currentIndex = 0;
            while (currentIndex < currFinalWalls.length) {
              const currFinalWallsChunk = currFinalWalls.slice(
                currentIndex,
                currentIndex + 100
              );

              const uniqueWallTypesSet = new Set(
                currFinalWallsChunk.map((wall: any) => wall.WallType)
              );
              const uniqueWallTypes = Array.from(uniqueWallTypesSet);

              const filteredWallsMatching = wallsMatching.filter((obj) =>
                uniqueWallTypes.includes(obj.InputType)
              );

              const filteredWallsDataByLevel = wallsDataByLevel.filter(
                (obj: any) => obj.LevelName === level
              );

              let wallIdsGroups: any = await layoutGroup(
                filteredWallsMatching,
                currFinalWallsChunk,
                filteredWallsDataByLevel,
                this.props.config
              ).catch((error) => {
                return Promise.reject({
                  code: errorCodeKey.PB_LT_H_007,
                  stack: error.stack,
                });
              });
              let currLayoutData: any = await layoutInGroup(
                filteredWallsMatching,
                currFinalWallsChunk,
                wallIdsGroups,
                filteredWallsDataByLevel,
                this.props.config
              ).catch((error) => {
                return Promise.reject({
                  code: errorCodeKey.PB_LT_H_007,
                  stack: error.stack,
                });
              });

              layoutData.push(...currLayoutData);

              currentIndex += 100;
            }
          }

          console.log("wallsMatching : ", wallsMatching);
          console.log("finalWalls : ", finalWalls);

          eventData = wallsMatching.map((obj: any) => {
            let data: any = [];
            _.forEach(finalWalls, (fw) => {
              if (fw.WallType === obj.InputType) {
                data = {
                  elementType: fw.WallType,
                  solution: obj.systemName,
                  surface: fw.Area,
                  articles: {
                    Rail: [obj?.Rail1_Name],
                    Montants: [obj?.Frame1_Name, obj?.Frame2_Name],
                    Plaques: [
                      obj?.E1?.name,
                      obj?.E2?.name,
                      obj?.E3?.name,
                      obj?.I1?.name,
                      obj?.I2?.name,
                      obj?.I3?.name,
                    ],
                  },
                };
              }
            });
            return data;
          });
          console.timeEnd("Layout process");

          //this.setState({ step: "drawing" });
          console.time("Drawing element");
          localStorage.setItem("totalProgress", "0");
          localStorage.setItem("totalNumber", "0");
          // layoutData = ltyoutJson;

          const drResult = await api.familyEditor.drawElements(
            layoutData,
            this.setGroupNumber,
            this.setIncrement,
            this.props.config.REACT_APP_DOWNLOAD_FAMILY_URL_PLACO,
            this.setStep
          );
          console.timeEnd("Drawing element");
          //CalpinageRevitApi(this.props);
        }

        this.sendEvent(eventData);
        this.setState({ step: "success" });
        if (
          this.state.notFoundSolutions &&
          this.state.notFoundSolutions.length > 0
        ) {
          this.setState({ isError: true });
        } else {
          this.setState({ isSuccessful: true });
        }
      } catch (ex) {
        console.error(ex);
        throw ex;
      }
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  extractRails = (rails: any) => {
    if (rails.length > 0) {
      if (rails[0].product.translation === "Rail Stil® R 48") {
        rails[0].articles.forEach((element: any) => {
          if (element.text === "Rail Stil® R 48/300 bot10L") {
            return element.text;
          }
        });
      }

      return rails[0].articles[0].text;
    }

    return "";
  };

  steps = () => {
    if (this.state.step === "familyloading") {
      return this.props.wording.loadingFamilies[this.props.language];
    } else if (this.state.step === "checking") {
      return "Recherche de doublons...";
    } else if (this.state.step === "drawing") {
      return `${this.props.wording.processing[this.props.language]} ${
        this.state.groupNumber
          ? Math.round((this.state.progress / this.state.groupNumber) * 100)
          : 0
      }%`;
    } else {
      return this.props.wording.processing[this.props.language];
    }
  };

  handleUserInput = (e: any, d: any) => {
    localStorage.setItem("isModification", "true");
    this.setState({
      userInput: d.value,
    });
  };

  toggleAction = () => {
    this.setState({ active: !this.state.active });
  };

  changeStatusStep = (selectionStatus: boolean, calepinageStatus: boolean) => {
    this.setState({ stepsCompleted: [selectionStatus, calepinageStatus] });
  };

  onCalepinageSelection = () => {
    localStorage.setItem("isModification", "true");
    localStorage.setItem("layoutStep2", "true");
    const cloneBufferSelectionList = JSON.parse(
      JSON.stringify(this.props.moduleData.calpinageData.buffer.selections.list)
    );

    /// Extract selections for calepinage only
    let selectionIds = map(cloneBufferSelectionList, (n) => {
      return n.Id;
    });

    let selectionCalepinageSpecific = filter(
      this.props.selections,
      function (o) {
        return includes(selectionIds, o.Id);
      }
    );

    const mapWall = reduce(
      selectionCalepinageSpecific,
      function (mapElements: any, mapSelection: any) {
        const wallRows = mapSelection.SelectionByType.wall.Rows;

        mapElements = concat(mapElements, wallRows);

        return mapElements;
      },
      []
    );
    const mapCeiling = reduce(
      selectionCalepinageSpecific,
      function (mapElements: any, mapSelection: any) {
        const ceilingRows = mapSelection.SelectionByType.ceiling.Rows;
        mapElements = concat(mapElements, ceilingRows);
        return mapElements;
      },
      []
    );

    if (mapWall.length >= 0 || mapCeiling.length > 0) {
      localStorage.setItem("isModification", "false");
      this.actionHandler(Routes.CALPINAGE_SOLUTION);
    }
    // else {
    //   this.setState({ noWallsSelected: true });
    // }
  };

  onBackSave = async () => {
    //this.setState({ saving: true });
    await new Promise((resolve) => setTimeout(resolve, 30));
    await bimStorage.setModule(
      dbStoreNameCalepinage,
      this.props.projectData.ProjectId,
      this.props.moduleData.calpinageData.buffer.Name,
      this.props.moduleData.calpinageData.buffer.Date,
      this.props.moduleData.calpinageData.buffer.Id,
      JSON.stringify(this.props.moduleData.calpinageData.buffer)
    );
    this.props.saveCalpinageBuffer();
    //  this.toggleAction();
    this.setState({ saving: false });
  };

  onSave = async () => {
    try {
      this.setState({ saving: true });
      await new Promise((resolve) => setTimeout(resolve, 30));

      await bimStorage.setModule(
        dbStoreNameCalepinage,
        this.props.projectData.ProjectId,
        this.props.moduleData.calpinageData.buffer.Name,
        this.props.moduleData.calpinageData.buffer.Date,
        this.props.moduleData.calpinageData.buffer.Id,
        JSON.stringify(this.props.moduleData.calpinageData.buffer)
      );

      this.props.saveCalpinageBuffer();
      this.toggleAction();
      this.setState({ saving: false });
    } catch (e) {
      console.log("error in calepinage save!", e);
    }
  };

  onAutoSave = async () => {
    //await new Promise((resolve) => setTimeout(resolve, 30));
    try {
      await bimStorage.setModule(
        dbStoreNameCalepinage,
        this.props.projectData.ProjectId,
        this.props.moduleData.calpinageData.buffer.Name,
        this.props.moduleData.calpinageData.buffer.Date,
        this.props.moduleData.calpinageData.buffer.Id,
        JSON.stringify(this.props.moduleData.calpinageData.buffer)
      );

      this.props.saveCalpinageBuffer();
    } catch (e) {
      console.log("error in calepinage save!", e);
    }
  };

  sendEvent = async (eventData: any) => {
    let projectInfo: any = await api.queries.getProjectInformation();
    //CalpinageRevitApi(this.props);
    let projectName = projectInfo.Name;
    let projectData: any = await api.queries.getProjectData();
    //CalpinageRevitApi(this.props);
    let fileName = projectData.ProjectPath.split("\\").pop();
    let revitVersion = await api.framework.getRevitVersion();
    api.eventLog.SetEvent({
      data: [
        {
          name: "Name of solutions",
          value: "",
          values: eventData,
        },
        {
          name: "Information",
          value: "",
          values: [
            {
              File_Name: fileName,
              Project_Name: projectName,
              Version: revitVersion,
            },
          ],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Calepinage",
      module: "PLACOBIM",
    });
  };

  render() {
    let steps = [
      {
        name: ReactHtmlParser(
          this.props.wording.selections[this.props.language]
        ),
        action: () => {
          this.toggleDimmer(true);
          localStorage.setItem("isModification", "true");
        },
        isActionCompleted: this.state.stepsCompleted[0],
      },
      {
        name: ReactHtmlParser(
          this.props.wording.calpinage[this.props.language]
        ),
        action: () => this.onCalepinageSelection(),
        isDisabled: !this.state.stepsCompleted[0],
        isActionCompleted: this.state.stepsCompleted[1],
      },
    ];

    const { selectionDimmer } = this.state;
    let totalStepsCompleted = 0;
    steps.forEach((step) => {
      if (step.isActionCompleted) totalStepsCompleted++;
    });

    return (
      <div style={{ height: "100%" }}>
        {this.props.historyPath !== "calpinagesolution" &&
          this.props.historyPath !== "calselection" && (
            <ScanSelectionController changeStatusStep={this.changeStatusStep} />
          )}
        <BackButtonModal
          backupModal={this.state.backupModal}
          closeBackupModal={this.closeBackupModal}
          no={this.props.wording.no[this.props.language]}
          yes={this.props.wording.yes[this.props.language]}
          description={this.props.wording.description[this.props.language]}
          backup={this.props.wording.backup[this.props.language]}
          saveAction={this.onBackSave}
          setRoute={this.props.setRoute}
        />

        <Dimmer active={this.state.needUserInput}>
          <Segment
            textAlign="left"
            style={{ width: 600, padding: 20, overflow: "auto" }}
          >
            <Header textAlign="center">Action requise</Header>
            <p style={{ color: "black" }}>
              Votre sélection comporte des cloisons déjà calepinées, que
              souhaitez-vous faire ?
            </p>
            <Form>
              <Form.Field>
                <Radio
                  label="Ignorer les doublons (le calepinage existant sera conservé)"
                  name="radioGroup"
                  value="keep"
                  checked={this.state.userInput === "keep"}
                  onChange={this.handleUserInput}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Remplacer le calepinage (le calepinage existant sera effacé)"
                  name="radioGroup"
                  value="erase"
                  checked={this.state.userInput === "erase"}
                  onChange={this.handleUserInput}
                />
              </Form.Field>
            </Form>
            <Button
              floated="right"
              disabled={this.state.userInput === ""}
              onClick={() => this.setState({ needUserInput: false })}
              primary
            >
              OK
            </Button>
          </Segment>
        </Dimmer>

        <Modal open={this.state.active} size="mini" dimmer="blurring">
          <Modal.Header className="modalHeader">
            {" "}
            {this.props.wording.layoutSavedHeader[this.props.language]}
            <Icon
              name="close"
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                this.toggleAction();
              }}
            ></Icon>
          </Modal.Header>
          <Modal.Description
            style={{ textAlign: "center", padding: "15px 0px" }}
          >
            <p>{this.props.wording.layoutSaved[this.props.language]}</p>
          </Modal.Description>
        </Modal>

        <Modal open={this.state.noWallsSelected} size="mini" dimmer="blurring">
          <Modal.Header className="modalHeader">
            {" "}
            {this.props.wording.noWallsHeading[this.props.language]}
            <Icon
              name="close"
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                this.setState({ noWallsSelected: false });
              }}
            ></Icon>
          </Modal.Header>
          <Modal.Description
            style={{ textAlign: "center", padding: "15px 0px" }}
          >
            <p>{this.props.wording.noWalls[this.props.language]}</p>
          </Modal.Description>
        </Modal>

        {/* <FunctionalityHeader
          Icon={this.props.Icon}
          name={this.props.moduleData.calpinageData.buffer.Name}
          subheader=""
        /> */}

        <Dimmer active={this.state.processing} page>
          <Loader content={this.steps()} />
        </Dimmer>

        <Dimmer active={this.state.saving} page>
          <Loader />
        </Dimmer>

        <Modal
          onClose={() => this.toggleDimmer(false)}
          open={selectionDimmer}
          size="small"
          dimmer="blurring"
          className="modal-close"
          closeIcon
        >
          {/* <Modal.Header
            className="modalHeader"
            style={{ padding: "7px 1.5rem", textAlign: "right" }}
          >
            <Icon
              name="close"
              size="large"
              inverted
              style={{ cursor: "pointer" }}
              onClick={() => this.toggleDimmer(false)}
            />
          </Modal.Header> */}
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <FunctionalityHeader
                    Icon={this.props.Icon}
                    name={this.props.wording.title[this.props.language]}
                    subheader=""
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginBottom: "25px" }}>
                <Grid.Column width={8}>
                  <Button
                    onClick={this.manualSelectionHandler}
                    primary
                    size="big"
                    floated="right"
                    className="selectionBtn"
                  >
                    {ReactHtmlParser(
                      this.props.wording.paretage[this.props.language]
                    )}
                  </Button>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    onClick={this.groupSelectionHandler}
                    primary
                    size="big"
                    floated="left"
                    className="selectionBtn"
                  >
                    {ReactHtmlParser(
                      this.props.wording.parzone[this.props.language]
                    )}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>

        <Calepinage
          name={this.props.moduleData.calpinageData.buffer.Name}
          Icon={this.props.Icon}
          steps={steps}
          language={this.props.language}
          totalStepsCompleted={totalStepsCompleted}
          onSave={this.onSave}
        ></Calepinage>
        {/* <Container
          className="header-alignment subHeader"
          style={{ marginTop: "1rem" }}
        >
          {totalStepsCompleted +
            "/" +
            steps.length +
            " " +
            ReactHtmlParser(
              this.props.wording.completedSteps[this.props.language]
            )}
        </Container> */}

        {/* <Container className="grid-container" style={{ paddingTop: 15 }}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={15}>
                <div style={{ textAlign: "right" }}>
                  <Popup
                    content={ReactHtmlParser(
                      this.props.wording.save[this.props.language]
                    )}
                    position="top center"
                    inverted
                    size="mini"
                    trigger={
                      <Icon
                        name="save"
                        color="blue"
                        size="big"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.onSave();

                          localStorage.setItem("isModification", "false");
                        }}
                      ></Icon>
                    }
                  />
                </div>
              </Grid.Column>
            </Grid.Row>


            <Grid
              style={{
                width: "100%",

              }}
            >
              <StepsData data={steps} {...this.props} />
            </Grid>
          </Grid>
        </Container> */}
        <div
          style={{
            position: "absolute",
            bottom: "30px",
            width: "100%",
            textAlign: "center",
          }}
        >
          <span>
            <Button
              color="orange"
              onClick={this.onBackButtonClick}
              content={this.props.wording.back[this.props.language]}
            ></Button>
          </span>
          <span>
            <Button
              color="blue"
              disabled={this.state.stepsCompleted.some((e: any) => e === false)}
              content={this.props.wording.calepiner[this.props.language]}
              onClick={() => {
                bimStorage.setModule(
                  dbStoreNameCalepinage,
                  this.props.projectData.ProjectId,
                  this.props.moduleData.calpinageData.buffer.Name,
                  this.props.moduleData.calpinageData.buffer.Date,
                  this.props.moduleData.calpinageData.buffer.Id,
                  JSON.stringify(this.props.moduleData.calpinageData.buffer)
                );

                this.props.saveCalpinageBuffer();
                this.validationHandler("new");
              }}
            ></Button>
            {/* <span
              onClick={() => {
                bimStorage.setModule(
                  dbStoreNameCalepinage,
                  this.props.projectData.ProjectId,
                  this.props.moduleData.calpinageData.buffer.Name,
                  this.props.moduleData.calpinageData.buffer.Date,
                  this.props.moduleData.calpinageData.buffer.Id,
                  JSON.stringify(this.props.moduleData.calpinageData.buffer)
                );
                this.props.saveCalpinageBuffer();
                this.validationHandler("old");
              }}
              style={{cursor: "pointer"}}
            >
              old Calepinage
            </span> */}
          </span>
        </div>

        <Modal size="tiny" open={this.state.isSuccessful} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>
              Information - Calepinage
            </h3>
            <p>Calepinage terminé avec succès.</p>
            <Button
              primary
              onClick={() => {
                api.windowsHandler.closeWindow();
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <Modal size="small" open={this.state.isError} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>
              Information - Calepinage
            </h3>
            <p>Calepinage terminée. Les solutions :</p>
            {this.state.notFoundSolutions.map((solution, key) => {
              return (
                <p
                  key={key}
                  style={{ color: "rgb(33, 133, 208)", marginBottom: "5px" }}
                >
                  {solution}
                </p>
              );
            })}
            <p>N'ont pas pu être prise en compte.</p>
            <p>
              Veuillez contacter
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onLinkClick("mailto:suppport.placobim@saint-gobain.com")
                }
              >
                {" "}
                suppport.placobim@saint-gobain.com
              </a>{" "}
              de PLACO® si besoin.
            </p>
            <Button
              primary
              onClick={() => {
                api.windowsHandler.closeWindow();
                this.setState({ notFoundSolutions: [] });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.Name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    moduleData: state.moduleData,
    selections: state.selections,
    projectData: state.projectData,
    historyPath: state.historyPath,
    selectorType: state.selectorType,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  setCalpinageSelection,
  ClearCalpinageSelection,
  saveCalpinageBuffer,
  setCalepinageSelection,
  setCalepinage,
  resetCalepinageSelection,
  loadCalepinage,
  setHistoryPath,
  setSelectorType,
})(CalpinageHomeOptimized);
