import { AnyAction } from "redux";
import { ACCEPT_TERMS_OF_USE, SHOW_INTRO } from "../actions/actionTypes";
import { RootState } from "./initialState";

export default function userInfo(
    state = RootState.userInfo,
    action: AnyAction
) {
    switch (action.type) {
        case ACCEPT_TERMS_OF_USE:
            return { ...state, acceptedTermsOfUse: action.payload };
        case SHOW_INTRO:
            return { ...state, skipIntro: action.payload };
        default:
            return state;
    }
}
