import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { Root } from "./components/root";

export const functionalityId = "61d9320a-9d54-42d5-bb36-632c2f49e3db";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Properties",
    module: "WEBINDONPRESCRI",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <Root />
    </ParentRoot>
  );
};

export const RootPropertiesComponentTest = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="#000000"
    >
      <Root />
    </ParentRoot>
  );
};

export const Properties: Functionality = {
  Name: {
    English: "Properties",
    Indonesian: "Bahan",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    English: "Set properties",
    Indonesian: "Pengaturan Properti",
  },
  Id: functionalityId,
  Icon: "/weberIcons/PropertBW.png",
  RootComponent,
  ParentId: "0",
};
