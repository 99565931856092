import { ThunkAction } from "redux-thunk";
import { FavoriteActionTypes } from "./types";
import {
  addSystemToFavoriteAction,
  loadSystemsFavorites,
  removeSystemFromFavoriteAction,
  selectFavoriteSystemType,
  clearAllFavoriteAction,
} from "./actions";
import { DrawStore } from "../reducers";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { ID } from "../../../../../RevitJS/Types/RevitTypes";
import {
  AddFavorite,
  getFavorites,
  RemoveFavorite,
  clearFavoris,
} from "../../services/projects.service.local";
import { WALLSYSTEM_ID, FLOORSYSTEM_ID } from "../../assets/constants";
import * as productsService from "../../services/project.service";
import { getSystemTypeByWorksNameObject } from "../../../Common/utils/attributes.injection.helpers";

type Effect = ThunkAction<any, DrawStore, any, FavoriteActionTypes>;

export const clearAllFavorite =
  (): Effect => (dispatch: any, getState: any) => {
    dispatch(clearAllFavoriteAction());
    clearFavoris();
  };

export const addSystemToFavorite =
  (system: ProductDetailData): Effect =>
  (dispatch: any, getState: any) => {
    let systemTypeID = getSystemTypeByWorksNameObject(system);
    let currentSystemTypeID = getState().systems.systemTypeID;
    dispatch(
      addSystemToFavoriteAction(system, currentSystemTypeID, systemTypeID)
    );
    AddFavorite({
      oid: system.oid,
      systemTypeID: systemTypeID,
    });
  };

export const removeSystemFromFavorite =
  (system: ProductDetailData): Effect =>
  (dispatch: any, getState: any) => {
    let systemTypeID = getSystemTypeByWorksNameObject(system);
    let currentSystemTypeID = getState().systems.systemTypeID;
    let index = 0;
    if (systemTypeID === WALLSYSTEM_ID)
      index = getState()
        .favorite.data.wallsSystemDetails.map(function (x: any) {
          return x.oid;
        })
        .indexOf(system.oid);
    if (systemTypeID === FLOORSYSTEM_ID)
      index = getState()
        .favorite.data.floorSystemDetails.map(function (x: any) {
          return x.oid;
        })
        .indexOf(system.oid);

    dispatch(
      removeSystemFromFavoriteAction(index, currentSystemTypeID, systemTypeID)
    );
    RemoveFavorite({
      oid: system.oid,
      systemTypeID: systemTypeID,
    });
  };

export const changeFavoriteSystemType =
  (): Effect => (dispatch: any, getState: any) => {
    let { data } = getState().favorite;
    let { systemTypeID } = getState().systems;
    if (systemTypeID === WALLSYSTEM_ID)
      dispatch(selectFavoriteSystemType(data.wallsSystemDetails));
    else if (systemTypeID === FLOORSYSTEM_ID)
      dispatch(selectFavoriteSystemType(data.floorSystemDetails));
  };

export const checkFavoriteSystem =
  (oid: ID): Effect =>
  (dispatch: any, getState: any) => {
    let { data } = getState().favorite;
    return (
      data.wallsSystemDetails.find((item: any) => oid === item.oid) ||
      data.floorSystemDetails.find((item: any) => oid === item.oid)
    );
    // return data.liningWallsDetails.find(item => oid === item.oid) || data.partitionsDetails.find(item => oid === item.oid) || data.ceilingsDetails.find(item => oid === item.oid)
  };

export const loadFavorites =
  (config: any): Effect =>
  async (dispatch: any, getState: any) => {
    let { systemTypeID, systems } = getState().systems;

    await getFavorites().then((favoris) => {
      productsService
        .getSystemDetailsById(
          favoris.map((r) => r.oid),
          config
        )
        .then((results) => {
          let currentSystemDetail = results.data.objects.map((sys) => {
            return { ...sys, filterFields: {} } as unknown;
          }) as ProductDetailData[];
          currentSystemDetail = currentSystemDetail.map((sys) => ({
            ...sys,
            thumbnailUrl: systems.find((system: any) => system.oid === sys.oid)
              ?.thumbnailUrl,
          })) as ProductDetailData[];
          if (favoris && favoris.length > 0) {
            let dataFavoris = {
              wallsSystemDetails: currentSystemDetail.filter((system) =>
                favoris.find(
                  (r) =>
                    r.oid === system.oid && r.systemTypeID === WALLSYSTEM_ID
                )
              ),
              floorSystemDetails: currentSystemDetail.filter((system) =>
                favoris.find(
                  (r) =>
                    r.oid === system.oid && r.systemTypeID === FLOORSYSTEM_ID
                )
              ),
            };
            dispatch(
              loadSystemsFavorites(dataFavoris, favoris.length, systemTypeID)
            );
          }
        });
    });
  };
