import _ from "lodash";
import {
  DELETE_FACADE_CALEPINAGE,
  IntNewCalpinageData,
  ModuleActionType,
  SetModuleDataActionType,
  SET_MODULE_DATA,
  UPDATE_SELECTION_NAME,
} from "../Types";

const intialState: IntNewCalpinageData = {
  data: [],
};

const ModuleReducer = (
  state: IntNewCalpinageData = intialState,
  action: ModuleActionType
): IntNewCalpinageData => {
  const newState = { ...state };
  switch (action.type) {
    case SET_MODULE_DATA:
      newState.data = action.payload;
      return newState;
    case DELETE_FACADE_CALEPINAGE:
      let { Id } = action;
      const removeIndex = state.data.findIndex((d: any) => d.Id === Id);
      state.data.splice(removeIndex, 1);
      return { ...state, data: state.data };
    case UPDATE_SELECTION_NAME:
      let { id, name } = action.payload[0];
      _.map(state.data, (obj) => {
        if (obj.id === id) {
          obj.name = name;
        }
      });
      return { ...state, data: state.data };
    default:
      return state;
  }
};

export default ModuleReducer;
