import { parseRigipsSystem } from "../..";
import { MappingRowRigips } from "../../../../../../RevitJS/Types/StoreTypes";
import { A11LayersParser } from "../../LayersParsers/a1LayersParser";
import { A511LayersParser } from "../../LayersParsers/layersParsers";

export const a5Mapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = A511LayersParser(parsedSystem.layersDesc);
    parsedSystem.E1 =
      AlbaStructure[0]?.thickness || AlbaStructure[2]?.thickness;
    parsedSystem.E1_Name = "Alba";
    parsedSystem.I1 = AlbaStructure[2]?.thickness;
    parsedSystem.I1_Name = "Ghost";
    parsedSystem.plateWidth = 10e9;
    parsedSystem.AlbaStructure = AlbaStructure;
    parsedSystem.Lining = true;
  }
  return parsedSystem;
};

export const a511Mapper = async (
  parsedSystem: any,
  config: any,
  row: MappingRowRigips,
  max_height: number
) => {
  if (row.MappedSystem !== null) {
    parsedSystem = await parseRigipsSystem(
      config,
      row.MappedSystem.oid,
      max_height,
      row.FireConstrain,
      row.SwitchParement,
      row.Reverse,
      row.PublicBuildingConstrain
    );
    let AlbaStructure = A11LayersParser(parsedSystem.layersDesc);
    parsedSystem.E1 = AlbaStructure[0]?.thickness;
    parsedSystem.E1_Name = "Alba";
    parsedSystem.I1 = AlbaStructure[2]?.thickness;
    parsedSystem.I1_Name = "Ghost";
    parsedSystem.plateWidth = 10e9;
    parsedSystem.AlbaStructure = AlbaStructure;
  }
  return parsedSystem;
};
