import React from "react";
import { Button } from "semantic-ui-react";
import { Routes } from "../../../Plugins/PlacoBIMv3/Selection/Components/root";
import { ModuleList } from "../Selection/moduleList";
import { SystemTypeList } from "../Selection/systemTypeList";
interface Props {
  routes: Routes;
  setRoute: any;
}

export const Test = () => {
  return (
    // <Main/>
    //<ModuleList />
    <SystemTypeList />
  );
};
