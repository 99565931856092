import { ThunkAction } from "redux-thunk";
import { PropertiesActionTypes } from "./types";
import {
  loadPropertiesRequest,
  loadPropertiesSuccess,
  restorePropertiesDetails,
  loadPropertiesError,
  selectProperty,
  defaultConfiguration,
} from "./actions";
import * as propertiesService from "../../services/properties.service";
import {
  CLOISONS_ID,
  DOUBLAGES_ID,
  PLAFONDS_ID,
  GAINES_ID,
} from "../../assets/constants";
import { DrawStore } from "../reducers";
import { getCurrentConfiguration } from "../../services/properties.service.local";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "../../../Common/assets/attributes.injection";
import {
  getDefaultConfigurationByType,
  setPAMAttributesModal,
} from "../../../Common/utils/attributes.injection.helpers";
import { PAM_SYSTEM_TYPE_KEYS } from "../../../Common/assets/constant";

type Effect = ThunkAction<any, DrawStore, any, PropertiesActionTypes>;

export const loadProperties =
  (t: any, config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadPropertiesRequest());
    let { systemTypeID } = getState().properties;

    let selectedProperties: any = {};
    let systemProperties: any[] = [];
    await propertiesService
      .loadProperties(Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME), config)
      .then((properties: any[]) => {
        systemProperties = properties;
      })
      .catch(() => dispatch(loadPropertiesError()));
    await getCurrentConfiguration()
      .then((properties) => {
        selectedProperties = properties?.propertiesConfiguration;
        if (
          selectedProperties &&
          selectedProperties["gainesTechniquesProperties"] === undefined
        ) {
          selectedProperties["gainesTechniquesProperties"] =
            getDefaultConfigurationByType(
              PAM_SYSTEM_TYPE_KEYS.GAINES_TECHNIQUES,
              systemProperties
            ) || [];
        }
        dispatch(defaultConfiguration(properties?.isDefaultConfiguration));
      })
      .catch(() => dispatch(loadPropertiesError()));
    if (!selectedProperties)
      selectedProperties = {
        liningWallsProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
            systemProperties
          ) || [],
        partitionsProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.WALL,
            systemProperties
          ) || [],
        ceilingsProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.CEILING,
            systemProperties
          ) || [],
        gainesTechniquesProperties:
          getDefaultConfigurationByType(
            PAM_SYSTEM_TYPE_KEYS.GAINES_TECHNIQUES,
            systemProperties
          ) || [],
      };

    const data = {
      liningWallsProperties: setPAMAttributesModal(
        PAM_SYSTEM_TYPE_KEYS.LINING_WALLS,
        systemProperties,
        selectedProperties.liningWallsProperties
      ),
      partitionsProperties: setPAMAttributesModal(
        PAM_SYSTEM_TYPE_KEYS.WALL,
        systemProperties,
        selectedProperties.partitionsProperties
      ),
      ceilingsProperties: setPAMAttributesModal(
        PAM_SYSTEM_TYPE_KEYS.CEILING,
        systemProperties,
        selectedProperties.ceilingsProperties
      ),
      gainesTechniquesProperties: setPAMAttributesModal(
        PAM_SYSTEM_TYPE_KEYS.GAINES_TECHNIQUES,
        systemProperties,
        selectedProperties.gainesTechniquesProperties
      ),
    };
    const currentPropertiesDetail =
      systemTypeID === CLOISONS_ID
        ? data.partitionsProperties
        : systemTypeID === PLAFONDS_ID
        ? data.ceilingsProperties
        : systemTypeID === DOUBLAGES_ID
        ? data.liningWallsProperties
        : data.gainesTechniquesProperties;
    const currentSelectedProperties =
      systemTypeID === CLOISONS_ID
        ? selectedProperties.partitionsProperties
        : systemTypeID === PLAFONDS_ID
        ? selectedProperties.ceilingsProperties
        : systemTypeID === DOUBLAGES_ID
        ? selectedProperties.liningWallsProperties
        : selectedProperties.gainesTechniquesProperties;

    dispatch(
      loadPropertiesSuccess(
        systemProperties,
        data,
        selectedProperties,
        currentSelectedProperties,
        currentPropertiesDetail
      )
    );
  };

export const restoreProperties =
  (typeId: number): Effect =>
  (dispatch: any, getState: any) => {
    dispatch(loadPropertiesRequest());
    let { data, selectedProperties } = getState().properties;
    if (typeId === CLOISONS_ID && data.partitionsProperties.length > 0)
      dispatch(
        restorePropertiesDetails(
          data.partitionsProperties,
          selectedProperties.partitionsProperties,
          typeId
        )
      );
    else if (typeId === PLAFONDS_ID && data.ceilingsProperties.length > 0)
      dispatch(
        restorePropertiesDetails(
          data.ceilingsProperties,
          selectedProperties.ceilingsProperties,
          typeId
        )
      );
    else if (typeId === DOUBLAGES_ID && data.liningWallsProperties.length > 0)
      dispatch(
        restorePropertiesDetails(
          data.liningWallsProperties,
          selectedProperties.liningWallsProperties,
          typeId
        )
      );
    else if (typeId === GAINES_ID && data.gainesTechniquesProperties.length > 0)
      dispatch(
        restorePropertiesDetails(
          data.gainesTechniquesProperties,
          selectedProperties.gainesTechniquesProperties,
          typeId
        )
      );
  };

export const checkProperty =
  (property: any, checked: boolean): Effect =>
  (dispatch: any, getState: any) => {
    let {
      currentSelectedProperties,
      currentPropertiesDetail,
      selectedProperties,
      data,
      systemTypeID,
    } = getState().properties;

    currentPropertiesDetail = currentPropertiesDetail.map((element: any) =>
      element.chapterData
        ? {
            ...element,
            chapterData: element.chapterData.map((prop: any) =>
              prop.categoryName === property.categoryName
                ? { ...prop, checked: checked }
                : prop
            ),
          }
        : element
    );

    //#region to show only selected attribute in properties
    let onlySelected = currentPropertiesDetail.map((element: any) =>
      element.chapterData
        ? {
            ...element,
            // chapterData: element.chapterData.filter((prop: any) => prop.checked === true)
            chapterData: element.chapterData.map((prop: any) =>
              prop.categoryName === property.categoryName
                ? { ...prop, checked: checked }
                : prop
            ),
          }
        : element
    );
    let getValue: any = currentSelectedProperties.filter(
      (prop: any) => prop.categoryName === property.categoryName
    )

    currentSelectedProperties = checked
      ? getValue.length > 0
        ? [
          ...currentSelectedProperties.map((prop: any) =>
            prop.categoryName === property.categoryName
              ? { ...prop, checked: checked }
              : prop
          ),
        ]
        : [...currentSelectedProperties, { ...property, checked: checked }]
      : [
        // ...currentSelectedProperties.filter(
        //   (prop: any) => prop.categoryName !== property.categoryName
        // ),
        ...currentSelectedProperties.map((prop: any) =>
          prop.categoryName === property.categoryName
            ? { ...prop, checked: checked }
            : prop
        )
      ];


    // console.log(currentSelectedProperties);
    // currentSelectedProperties = [
    //   ...currentSelectedProperties.map((prop: any) =>
    //     prop.categoryName === property.categoryName
    //       ? { ...prop, checked: checked }
    //       : prop
    //   ),
    // ];
    data = {
      liningWallsProperties:
        systemTypeID === DOUBLAGES_ID
          ? [...onlySelected]
          : [...data.liningWallsProperties],
      partitionsProperties:
        systemTypeID === CLOISONS_ID
          ? [...onlySelected]
          : [...data.partitionsProperties],
      ceilingsProperties:
        systemTypeID === PLAFONDS_ID
          ? [...onlySelected]
          : [...data.ceilingsProperties],
      gainesTechniquesProperties:
        systemTypeID === GAINES_ID
          ? [...onlySelected]
          : [...data.gainesTechniquesProperties],
    };
    selectedProperties = {
      liningWallsProperties:
        systemTypeID === DOUBLAGES_ID
          ? [...currentSelectedProperties]
          : [...selectedProperties.liningWallsProperties],
      partitionsProperties:
        systemTypeID === CLOISONS_ID
          ? [...currentSelectedProperties]
          : [...selectedProperties.partitionsProperties],
      ceilingsProperties:
        systemTypeID === PLAFONDS_ID
          ? [...currentSelectedProperties]
          : [...selectedProperties.ceilingsProperties],
      gainesTechniquesProperties:
        systemTypeID === GAINES_ID
          ? [...currentSelectedProperties]
          : [...selectedProperties.gainesTechniquesProperties],
    };

    dispatch(
      selectProperty(
        currentSelectedProperties,
        currentPropertiesDetail,
        selectedProperties,
        data
      )
    );
  };

export const setDefaultConfiguration =
  (checked: boolean): Effect =>
  (dispatch: any, getState: any) => {
    dispatch(defaultConfiguration(checked));
  };
