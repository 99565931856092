//#region imports
import React, { useState } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducers } from "../../../Plugins/PlacoBIMv3/Selection/Reducers";
//#endregion imports

//#region  internal imports
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../CommonComponents/Root/ParentRoot";
import ReengineeringProvider from "../context/re_engineering_provider";
import App from "./re-engineering_AppBody";

//#endregion internal imports
interface Props {
  moduleName: string;
  pluginName?: string;
}

export let pluginId: string = "531991b6-a570-46ac-be5d-8865058dcb13";
export const pluginIds: { [key: string]: string } = {
  Placo: "531991b6-a570-46ac-be5d-8865058dcb13",
  Gyproc: "99f4551c-183c-4745-97b4-7d05eea2835b",
};
export const functionalityId = "d5bda177-5473-49d4-a664-ce8045e6471d";
export enum ReengineeringRoutes {
  ROOT = "Root",
  EXPORT = "EXPORT",
  IMPORT = "IMPORT",
  ReEngineer = "ReEngineer",
}

export const getPluginIdForPluginName = async (pluginName: string) => {
  for (const key in pluginIds) {
    if (pluginName.startsWith(key)) {
      pluginId = pluginIds[key];
      console.log(pluginId);
      break;
    }
  }
};

export const reengineeringTrigger = async (
  config: any,
  moduleName: string,
  pluginName?: string | undefined
) => {
  if (pluginName) {
    console.log("inside if");
    await getPluginIdForPluginName(pluginName);
  }
  api.windowsHandler.openWindow(
    700,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}/${moduleName}`
  );
};

const RootComponent = (props: Props) => {
  let store: Store;
  const [route, setRoute] = useState();
  store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <ParentRoot
        pluginId={pluginId}
        functionalityId={functionalityId}
        module={"ReengineeringTool"}
      >
        <ReengineeringProvider>
          <App route={props.moduleName} setRoute={setRoute} />
        </ReengineeringProvider>
      </ParentRoot>
    </Provider>
  );
};

export const ReengineeringRootComponent = (props: Props) => {
  let store: Store;
  const [route, setRoute] = useState();
  store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <ParentRoot
        pluginId={pluginId}
        functionalityId={functionalityId}
        module={""}
      >
        <ReengineeringProvider>
          <App
            route={props.moduleName}
            setRoute={setRoute}
            pluginName={props.pluginName ?? ""}
          />
        </ReengineeringProvider>
      </ParentRoot>
    </Provider>
  );
};
export default ReengineeringRootComponent;

export const Reengineering: Functionality = {
  Name: {
    French: "Exporter",
    English: "Export",
  },
  Trigger: reengineeringTrigger,
  ShortDesc: {
    French: "Export the selected Module",
    English: "Export the selected Module",
  },
  Id: functionalityId,
  Icon: "/quantitatifsparratio.png",
  RootComponent,
  ParentId: "0",
};
