import { filter, find, map, remove } from "lodash";
import React, { useState } from "react";
import { Button, Checkbox, Icon, Input, Popup, Table } from "semantic-ui-react";
import { cctpType } from ".";
import { api } from "../../../../../RevitJS/API";
import { Cursor } from "../../../../../RevitJS/API/WindowsHandler";
import "../../Resources/daoSelection.css";
import { SolutionSelect } from "../ReferencesCCTP/SolutionSelect";
import { SelectionStore } from "../../../Selection/Reducers";
import { useDispatch, useSelector } from "react-redux";

interface props {
  cctpData: cctpType[];
  wording: any;
  language: string;
  setCctpData: any;
  setRoute: any;
  handleActiveRow: any;
  onRefChange: any;
  refin: any;
  setSort: any;
  sortBy: {
    RevitSystems: "ascending" | "descending";
    Solution: "ascending" | "descending";
    CCTPReference: "ascending" | "descending";
    column: string;
  };
  setFitcheModalOpen: any;
  setFitcheModalRow: any;
}

export const Ctable = ({
  cctpData,
  wording,
  language,
  setCctpData,
  setRoute,
  handleActiveRow,
  onRefChange,
  refin,
  setSort,
  sortBy,
  setFitcheModalOpen,
  setFitcheModalRow,
}: props) => {
  const reduxState = useSelector((state: SelectionStore) => state);
  const [drag, setDrag] = useState<{
    draggedItem: any;
    draggedIndex: any;
  }>({ draggedItem: null, draggedIndex: null });

  const cloneCctpData = () => {
    return JSON.parse(JSON.stringify(cctpData));
  };

  const toggleAll = (checked: boolean | undefined) => {
    if (checked !== undefined) {
      let cloneCctp = cloneCctpData();
      cloneCctp = map(cloneCctp, (clctp: cctpType, index: number) => {
        clctp.isSelected = checked;
        return clctp;
      });
      setCctpData(cloneCctp);
    }
  };

  const toggleSingle = (checked: boolean | undefined, sn: number) => {
    if (checked !== undefined) {
      let cloneCctp = cloneCctpData();
      cloneCctp = map(cloneCctp, (clctp: cctpType, index: number) => {
        if (clctp.srno === sn) {
          clctp.isSelected = checked;
        }
        return clctp;
      });
      setCctpData(cloneCctp);
    }
  };

  const onChangeReferenceCCtp = (sn: number, value: string) => {
    let cloneCctp = cloneCctpData();
    cloneCctp = map(cloneCctp, (clctp: cctpType, index: number) => {
      if (clctp.srno === sn) {
        clctp.CCTPReference = value;
      }
      return clctp;
    });
    setCctpData(cloneCctp);
  };

  const otherOptionsDAE = (data: cctpType) => {
    let options = [];
    if (data.isNonPlaco) {
      options.push({ value: "Fiche système", text: "Fiche système", disabled: false });
    }

    options.push({ value: "FP", text: "Fiche produit", disabled: false });
    options.push({ value: "ACERMI", text: "ACERMI", disabled: false });
    options.push({ value: "PV_INCENDIE", text: "PV Feu", disabled: false });
    options.push({ value: "PV_ACOUSTIQUE", text: "Rapport d’essai acoustique", disabled: false });
    options.push({ value: "FDES", text: "FDES", disabled: false });
    options.push({ value: "AT", text: "Avis technique", disabled: false });
    options.push({ value: "DOP", text: "DOP", disabled: false });

    return options;
  }

  const otherOptionsDAO = (data: cctpType) => {
    let options = [];
    options.push({ value: "Fiche système", text: "Fiche système", disabled: false });
    if (data.isNonPlaco) {
      options.push({ value: "FP", text: "Fiche produit", disabled: false });
    }
    return options;
  }

  const otherOptionsDAONonPlaco = [{ value: "FP", text: "Fiche produit", disabled: false }];

  const handleCloneSolution = (item: number, solution: any, sn: number) => {
    setTimeout(() => api.windowsHandler.showCursor(Cursor.Normal), 500);

    let cloneCctp = cloneCctpData();
    cloneCctp = map(cloneCctp, (clctp: cctpType, index: number) => {
      if (clctp.srno === sn) {
        clctp.Solution.label = solution.label;
        clctp.Solution.oid = solution.value;
        clctp.isNonPlaco = solution.isNonPlaco;

        let docType = reduxState.moduleData.dossierData.documentType === "DAO"
          ? solution.isNonPlaco ? otherOptionsDAO(solution) : otherOptionsDAONonPlaco
          : otherOptionsDAE(solution);

        let typeFichierValue = docType.map((o: any) => o.value);
        let files: any = clctp.files.map((f: any) => {
          if (typeFichierValue.includes(f.fileType)) {
            return f;
          }
        })

        clctp.files = files.filter((f:any) => f != undefined);
      }
      return clctp;
    });
    setCctpData(cloneCctp);
  };

  const duplicateData = (sn: number) => {
    const datatobeClone: cctpType | undefined = find(cctpData, { srno: sn });
    if (datatobeClone) {
      let duplicateData: cctpType = JSON.parse(JSON.stringify(datatobeClone));
      duplicateData.CCTPReference = "";
      duplicateData.srno = cctpData.length + 1;
      duplicateData.duplicated = true;
      let cloneCctp = cloneCctpData();
      cloneCctp.push(duplicateData);
      setCctpData(cloneCctp);
    }
  };

  const removeData = (sn: number) => {
    let cloneCctp: cctpType[] = cloneCctpData();
    remove(cloneCctp, (n: cctpType) => {
      return n.srno === sn;
    });
    setCctpData(cloneCctp);
  };

  const onInternalRefChange = (node: any) => {
    onRefChange(node);
  };

  const onDragStart = (e: any, index: any) => {
    setDrag({
      draggedItem: cctpData[index],
      draggedIndex: index,
    });
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragOver = (index: any) => {
    if (drag.draggedItem) {
      setDrag({
        draggedItem: drag.draggedItem,
        draggedIndex: index,
      });
      let data: any = [];
      data = cctpData;
      const draggedOverItem: any = data[index] as any;
      if (drag.draggedItem === draggedOverItem) {
        return;
      }
      let itemList = data.filter((item: any) => item !== drag.draggedItem);
      itemList.splice(index, 0, drag.draggedItem as never);
      let cloneCctp = itemList;
      setCctpData(cloneCctp);
    }
  };

  const onDragEnd = () => {
    setDrag({ draggedItem: null, draggedIndex: null });
    setTimeout(() => api.windowsHandler.showCursor(Cursor.Normal), 500);
  };

  const setScrollConfigPosition = () => {
    if (refin !== null) {
      const scrollPosition: any = refin.scrollTop;
      if (scrollPosition) {
        localStorage.setItem(
          "scrollConfigPosition",
          scrollPosition?.toString()
        );
      }
    }
  };

  const allSelected = filter(cctpData, { isSelected: false }).length === 0;
  return (
    <div
      style={{
        width: "100%",
        marginTop: 25,
        padding: 10,
        height: "75%",
        position: "absolute",
        overflowY: "auto",
      }}
      ref={onInternalRefChange}
    >
      <Table
        sortable
        celled
        style={{ width: "100%" }}
        className="referencecctp-table"
      >
        <Table.Header style={{ textAlign: "center" }}>
          <Table.Row>
            <Table.HeaderCell className="width10"></Table.HeaderCell>
            <Table.HeaderCell className="width5">
              <Checkbox
                checked={allSelected}
                onChange={(e, { checked }) => toggleAll(checked)}
              />
            </Table.HeaderCell>
            <Table.HeaderCell
              className="width20"
              sorted={sortBy.RevitSystems}
              onClick={() => setSort("RevitSystems")}
            >
              {wording.revitSystemType[language]}
            </Table.HeaderCell>
            <Table.HeaderCell
              className="width25"
              sorted={sortBy.Solution}
              onClick={() => setSort("Solution")}
            >
              {wording.systems[language]}
            </Table.HeaderCell>
            <Table.HeaderCell
              className="width15"
              sorted={sortBy.CCTPReference}
              onClick={() => setSort("CCTPReference")}
            >
              {wording.referenceCCTP[language]}
            </Table.HeaderCell>
            {/* uncomment this part to enable pdf column  */}
            <Table.HeaderCell className="width25" style={{ wordBreak: "breal-all", whiteSpace: "inherit" }}>{wording.pdfFile[language]}</Table.HeaderCell>
            {/* uncomment this part to enable pdf column  */}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {cctpData.map((item: cctpType, index: number) => {
            return (
              <Table.Row
                key={index}
                onDragOver={() => onDragOver(index)}
                className={drag.draggedIndex === index ? "dragClass" : ""}
              >
                <Table.Cell
                  draggable
                  onDragStart={(e: any) => onDragStart(e, index)}
                  onDragEnd={(e: any) => onDragEnd()}
                  className="dragRow"
                >
                  <Popup
                    trigger={<Icon name="move" />}
                    content="Glisser"
                    size="mini"
                    inverted
                  />

                  {item.duplicated ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => removeData(item.srno)}
                    >
                      <Popup
                        trigger={<Icon name="remove" color="red" />}
                        content="Supprimer"
                        size="mini"
                        inverted
                      />
                    </span>
                  ) : (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => duplicateData(item.srno)}
                    >
                      <Popup
                        trigger={<Icon name="copy outline" />}
                        content="Dupliquer"
                        size="mini"
                        inverted
                      />
                    </span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={item.isSelected}
                    onChange={(e, { checked }) => {
                      toggleSingle(checked, item.srno);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  {`${item.RevitSystems} (${item.Count})`}
                </Table.Cell>
                <Table.Cell>
                  {item.duplicated ? (
                    <div>
                      <SolutionSelect
                        setRoute={setRoute}
                        index={index}
                        solutionName={item.Solution.label!}
                        handleCloneSolution={handleCloneSolution}
                        CCTPReference={item.CCTPReference}
                        handleActiveRow={handleActiveRow}
                        srno={item.srno}
                        RevitSystem={item.RevitSystems}
                        oid={item.Solution.oid!}
                        setScrollConfigPosition={setScrollConfigPosition}
                      />
                    </div>
                  ) : (
                    `${item.Solution.label}`
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Input
                    onChange={(e: any, { value }) =>
                      onChangeReferenceCCtp(item.srno, value)
                    }
                    style={{ width: "100%" }}
                    placeholder={wording.referenceCCTP[language]}
                    value={item.CCTPReference}
                    key={item.srno}
                  />
                </Table.Cell>
                {/* uncomment this part to enable pdf column  */}
                <Table.Cell>
                  <Button
                    primary
                    onClick={() => {
                      setFitcheModalOpen(true);
                      setFitcheModalRow(item);
                    }}
                    size="tiny"
                  >
                    {wording.add[language]}
                  </Button>
                  <span>
                    <Icon name="file pdf outline" color="red" />
                    <span>({item.files.length})</span>
                  </span>
                </Table.Cell>
                {/* uncomment this part to enable pdf column  */}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
