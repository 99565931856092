import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { Routes } from "./root";

export const SavePopup = (props: any) => {
  return (
    <Modal open={props.savepopup} size="mini" dimmer="blurring">
      <Modal.Description style={{ textAlign: "center", padding: "15px 10px" }}>
        <p>{props.message}</p>
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button
          color="orange"
          size="tiny"
          onClick={() => {
            if (props.isBufferSelectionUpdate) {
              props.setRoute(Routes.MAPPING);
            } else {
              props.setRoute(Routes.ROOT);
            }

          }}
        >
          {props.no}
        </Button>
        <Button
          size="tiny"
          type="submit"
          primary
          onClick={() => {
            props.validateSelection();
            props.closeDimmer();
          }}
        >
          {props.yes}
        </Button>

      </div>
    </Modal>
  );
};
