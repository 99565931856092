import React, { Component } from "react";
import { Dimmer, Loader, Button, Container } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { excelExport } from "./Builder";
import { wait } from "../../../../RevitJS/Helpers";
import { withTranslation, WithTranslation } from "react-i18next";

class Waiter extends Component {
  state = {
    wait: true,
  };

  componentDidMount = async () => {
    // api.windowsHandler.showDevTools()
    this.setState({
      wait: false,
    });

    setTimeout(() => {
      let selectedLanguage = localStorage.getItem('savedLanguage');
      if (selectedLanguage !== "English" && selectedLanguage !== "French") {
        this.props.i18n.changeLanguage("English");
      }
      else {
        this.props.i18n.changeLanguage(selectedLanguage);
      }
    });
  };
  render = () => {
    const { t } = this.props;

    const dimmer = () => (
      <Dimmer active={true} style={{height: "calc(100vh - 30px)"}}>
        {/* <Loader content="Quantitatifs en en préparation..."></Loader> */}
        <Loader content={t('calculationInProgress')}></Loader>
      </Dimmer>
    );
    if (this.state.wait) return dimmer();
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Button
            style={{ marginTop: 50 }}
            color="yellow"
            onClick={() => {
              this.setState({
                wait: true,
              })
              excelExport(() => this.setState({
                wait: false,
              }));
              // window.addEventListener("blur", () =>{
              //     api.windowsHandler.closeWindow();
              // })
            }}
          >
            {t('quantitatifs')}
          </Button>
        </div>
      </div>
    );
  };
}

export default (withTranslation(["clt"])(Waiter));