import React from 'react';
import { connect } from 'react-redux';
import {FiltersList} from './FiltersList';
import { Button, Menu } from 'semantic-ui-react';
// import {resetFilters} from '../Actions';
// import {wording} from '../Ressources/wording';


const language = "French";

export class Filters extends React.Component {
    render() {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Menu vertical fluid>
                    <Menu.Item
                        name='promotions'
                        onClick={this.handleItemClick}
                    >
                        <Button onClick={() => this.props.resetFilters()} primary>{this.props.wording.resetFilters[language]}</Button>
                    </Menu.Item>

                    <FiltersList applyFilters={this.props.applyFilters} setSelectionBuffer={this.props.setSelectionBuffer} setFilterValue={this.props.setFilterValue} partitions={this.props.partitions} filters={this.props.filters}/>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = state =>{
    return{

    }
}

Filters.defaultProps={
    wording:{
        resetFilters:{
            French: "Réinitialiser les filtres",
            English: "Reset"
        }
    }
}

// export default connect(null,{resetFilters})(Filters);