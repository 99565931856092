import { PLAQUE_COLOR } from "../Assets/Plaques.Colors";
import { getSystemDetailsById } from "../Service/project.service";

export const getColor = async (oid) => {
  let systemDetailsRequest = await getSystemDetailsById([oid]).then((results) =>
    results.data.objects.map((sys) => {
      return { ...sys };
    })
  );

  let mainSystem = systemDetailsRequest.find(
    (obj) => obj.types[0] === "Product System"
  );

  let plaque_marque = mainSystem.attributes.find(
    (e) => e.technicalName === "TD_Systemtyp_Marke_Bezeichnung"
  ).values[0].value;

  let plaque_Color = PLAQUE_COLOR.find(
    (element) => element.mark === plaque_marque
  );

  return (
    plaque_Color && {
      Red: parseInt(plaque_Color.color[0]),
      Green: parseInt(plaque_Color.color[1]),
      Blue: parseInt(plaque_Color.color[2]),
    }
  );
};
