import { ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { AnyAction } from "redux";
import { setGroupTreesAction } from "../Actions/types";

export const ceilingTree = (
    selectionTree: ElementsTree = { Tree: [] },
    action: setGroupTreesAction
  ) => {
    switch (action.type) {
      case "SET_GROUP_TREES":
        return action.ceilingTree;
      default:
        return selectionTree;
    }
  };