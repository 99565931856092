import React from 'react';
import { Menu, Label, Checkbox } from 'semantic-ui-react';

export class TypeItem extends React.Component {
 
    render() {
        return (
            <Menu.Item
                name='inbox'
                onClick={this.handleItemClick}>
                <Label color={this.props.color}>{this.props.count}</Label> 
                <Checkbox disabled={this.props.selectedLevels.length < 1 ? true: false} onChange={(e, data) => { this.props.selectType(data.label.children)}} checked={this.props.checked} style={{ maxWidth: "80%" }} label={{ children: `${this.props.type}` }} />
            </Menu.Item>
        )
    }
}