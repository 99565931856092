import { Language } from "../../../../RevitJS/Types/RevitTypes";

export const SET_METRE_SELECTION = "SET_METRE_SELECTION";
export const CLEAR_SELECT_METRE = "CLEAR_SELECT_METRE";
export const SELECT_METRE_PAR_ETAGE = "SELECT_METRE_PAR_ETAGE";
export const SELECT_METRE_PAR_ZONE = "SELECT_METRE_PAR_ZONE";
export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export interface InitLanguageAction{
    type: typeof INIT_LANGUAGE;
    language: Language
}

export interface SetLanguageAction{
    type: typeof SET_LANGUAGE,
    language: Language
}

export type LanguageAction = InitLanguageAction | SetLanguageAction

export interface RapidBoard{
    visible: boolean,
    type: string
}