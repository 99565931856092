export const setParZoneSelection = (value: boolean) => {
  return {
    type: "SET_MODULE_SELECTION",
    value,
  };
};


export const clearMetresSelection = () => {
    return {
      type: "CLEAR_METRES_SELECTION"
    };
  };
  