import React, { useState } from "react";
import { pluginId } from "..";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { ReleaseNoteRoot } from "./Components/root";


export const functionalityId = "283f7f5c-b490-4058-a2ac-25fecfa7d75c";

export const releaseNoteTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    800,
    500,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  const [selectedPluginId, setSelectedPluginId] = useState(localStorage.getItem("selectedPluginId") as string);
  return (
    <ParentRoot pluginId={selectedPluginId} functionalityId={functionalityId} module={""}>
      <ReleaseNoteRoot />
    </ParentRoot>
  );
};
export const ReleaseNoteRootComponent = () => {
  const [selectedPluginId, setSelectedPluginId] = useState(localStorage.getItem("selectedPluginId") as string);
  return (
    <ParentRoot pluginId={selectedPluginId} functionalityId={functionalityId} module={""}>
      <ReleaseNoteRoot />
    </ParentRoot>
  );
};

export const ReleaseNote: Functionality = {
  Name: {},
  Trigger: releaseNoteTrigger,
  ShortDesc: {},
  Id: functionalityId,
  Icon: "",
  RootComponent,
  ParentId: "0",
};

export default ReleaseNote;
