import React from "react";
import { api } from "../../RevitJS/API";
import {
  Navigate,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

export const registerFing = async (token: string, config: any) => {
  try {
    await api.license.RegisterFingerprint(token, config);
  } catch (ex) {}
};

const Authenticate = () => {
  const token = useLoaderData();
  if (token) {
    return <Navigate to="/dock" replace={true} />;
  }
  return <>Login in...</>;
};

export default Authenticate;
