import {
  LayoutWall,
  PLTResult,
  RevitMappedWall,
  Line,
  Point,
  Basis,
  Contour,
  Vect3D,
  Rectangle,
  Panel,
  RevitWall,
  Conf,
  RevitMappedWall2,
} from "./types";
import {
  moveContour,
  normalize,
  crossProduct,
  computeExtremeCoords,
  contourToTurfPolygon,
  reverseLine,
} from "./helpers";
import { Feature, Polygon, MultiPolygon, geometry } from "@turf/turf";
import * as turf from "@turf/turf";

export function revitMappedToLayoutWall(wall: RevitMappedWall2): LayoutWall {
  return {
    ...wall,
    Neighbours: {
      0: {
        SideA: null,
        SideB: null,
        Angle: 0,
        Id: "test",
      },
      1: {
        SideA: null,
        SideB: null,
        Angle: 0,
        Id: "test",
      },
    },
  };
}

type MarkedWall = {
  Id: string;
  wall: RevitMappedWall;
  checked: boolean;
};

export function browseWalls(walls: MarkedWall[], s: MarkedWall) {
  let pointedWallIndex = walls.findIndex((wall) => wall.Id === s.Id);
  walls[pointedWallIndex].checked = true;
  let children = [];

  for (let i = 0; i < s.wall.Neighbours.length; i++) {
    let candidateNeighbour = s.wall.Neighbours[i];
    let candidateAdmissible = walls.find(
      (wall) => wall.Id === candidateNeighbour.Id
    );
    if (candidateAdmissible) {
      let admissibleNeighbourIds = candidateAdmissible.wall.Neighbours.map(
        (neigh) => neigh.Id
      );
      if (admissibleNeighbourIds.includes(s.Id)) {
        children.push(candidateAdmissible);
      }
    }
  }
  for (let i = 0; i < children.length; i++) {
    if (!children[i].checked) {
      browseWalls(walls, children[i]);
    }
  }
}

export function sortRevitMappedWalls(
  walls: RevitMappedWall[]
): RevitMappedWall[][] {
  let markedWalls = walls.map((wall) => {
    return { wall: wall, Id: wall.Id, checked: false };
  });

  let groups: RevitMappedWall[][] = [];

  while (markedWalls.length > 0) {
    browseWalls(markedWalls, markedWalls[0]);
    let group = markedWalls
      .filter((wall) => wall.checked)
      .map((wall) => wall.wall);
    groups.push(group);
    markedWalls = markedWalls.filter((wall) => wall.checked === false);
  }
  return groups;
}

export function moveWallContours(
  outerContour: Contour,
  innerContours: Contour[],
  translation: Vect3D,
  basis: Basis
): { OuterContour: Contour; InnerContours: Contour[] } {
  return {
    OuterContour: moveContour(outerContour, translation, basis),
    InnerContours: innerContours.map((contour) =>
      moveContour(contour, translation, basis)
    ),
  };
}

export function computeWallBasis(locationLine: Line, minPoint: Point): Basis {
  let X = normalize({
    X: locationLine.p2.X - locationLine.p1.X,
    Y: locationLine.p2.Y - locationLine.p1.Y,
    Z: locationLine.p2.Z - locationLine.p1.Z,
  });
  let Y = { X: 0, Y: 0, Z: 1 };
  let Z = crossProduct(X, Y);
  return [X, Y, Z];
}

export function pointRingX(
  points: Point[],
  xValue: number,
  xTranslation: number,
  epsilon: number = 0.1
) {
  return points.map((point) =>
    Math.abs(point.X - xValue) < epsilon
      ? { ...point, X: point.X + xTranslation }
      : point
  );
}

export function pointRingY(
  points: Point[],
  yValue: number,
  yTranslation: number,
  epsilon: number = 0.1
) {
  return points.map((point) =>
    Math.abs(point.Y - yValue) < epsilon
      ? { ...point, Y: point.Y + yTranslation }
      : point
  );
}

export function pointRingZ(
  points: Point[],
  zValue: number,
  zTranslation: number,
  epsilon: number = 0.1
) {
  return points.map((point) =>
    Math.abs(point.Z - zValue) < epsilon
      ? { ...point, Z: point.Z + zTranslation }
      : point
  );
}

export function cutWall(
  outerContour: Contour,
  innerContours: Contour[]
): Rectangle[] {
  function computeCuttingAbs(
    xmin: number,
    xmax: number,
    contour: Contour,
    innerContours: Contour[]
  ): number[] {
    let innerAbs = innerContours
      .map((contour) => contour.map((point) => point.X))
      .reduce((a, b) => a.concat(b), []);
    let outterAbs = outerContour.reduce((prev: number[], curr: Point) => {
      if (curr.X !== xmin && curr.X !== xmax) {
        return [...prev, curr.X];
      }
      return [...prev];
    }, []);
    let set = new Set([xmin, ...innerAbs, ...outterAbs, xmax]);
    return Array.from(set);
  }

  function computeRectanglesFromIntersections(
    prev: Rectangle[],
    curr: [number, number][]
  ) {
    if (curr.length === 5) {
      let xcoords = curr.map((coord) => coord[0]);
      let ycoords = curr.map((coord) => coord[1]);
      let x_min = Math.min(...xcoords);
      let x_max = Math.max(...xcoords);
      let y_min = Math.min(...ycoords);
      let y_max = Math.max(...ycoords);
      return [
        ...prev,
        { x: xmin, y: y_min, width: x_max - x_min, height: y_max - y_min },
      ];
    }
    return prev;
  }

  function computeCuttedGeometry(
    x1: number,
    x2: number,
    ymin: number,
    ymax: number,
    poly: Feature<Polygon>
  ): Rectangle[] {
    let cuttingRectangle = turf.polygon([
      [
        [x1, ymin - 1],
        [x2, ymin - 1],
        [x2, ymax + 1],
        [x1, ymax - 1],
        [x1, ymin - 1],
      ],
    ]);
    const intersection = turf.intersect(poly, cuttingRectangle);
    if (intersection !== null) {
      const {
        geometry: { type, coordinates },
      }: any = intersection;

      if (type === "Polygon") {
        return coordinates.reduce(computeRectanglesFromIntersections, []);
      } else {
      }
      //
    }
    return [];
  }

  const { xmin, ymin, xmax, ymax } = computeExtremeCoords(outerContour);
  let cuttingAbs = computeCuttingAbs(xmin, xmax, outerContour, innerContours);
  let poly = contourToTurfPolygon(outerContour, innerContours);
  let result: Rectangle[] = [];
  for (let i = 0; i < cuttingAbs.length - 1; i++) {
    let rectangles = computeCuttedGeometry(
      cuttingAbs[i],
      cuttingAbs[i + 1],
      ymin,
      ymax,
      poly
    );
    result = result.concat(rectangles);
  }
  return result;
}

export function setPannel(): Panel {
  throw "Not Implemented Yet";
}

export function revitMappedToRevitMapped2(
  wall: RevitMappedWall
): RevitMappedWall2 {
  let leftNeighbour = wall.Neighbours.find((neigh) => neigh.Loc === 0);
  let rightNeighbour = wall.Neighbours.find((neigh) => neigh.Loc === 1);
  return {
    ...wall,
    Neighbours: {
      0: leftNeighbour ? leftNeighbour : null,
      1: rightNeighbour ? rightNeighbour : null,
    },
  };
}

// export function compute(wall: LayoutWall): PLTResult{
//     const { LocationLine, BoundingBox:{minPoint}, OuterContour, InnerContours} = wall;
//     let wallBasis = computeWallBasis(LocationLine, minPoint);
//     let translation = {X: LocationLine.p1.X, Y: LocationLine.p1.Y, Z: minPoint.Z};
//     let movedContours = moveWallContours(OuterContour, InnerContours, translation, wallBasis);
//     let rectangles = cutWall(movedContours.OuterContour, movedContours.InnerContours);
//     const {xmin, ymin, xmax, ymax} = computeExtremeCoords(movedContours.OuterContour);

//     return {
//         rails: [],
//         flipped_rails: [],
//         frames: [],
//         plasterboards: [],
//     }
// }

// export function layout(walls: RevitMappedWall[]): PLTResult[][]{
//     let wallGroups = sortRevitMappedWalls(walls);

//     let layoutWallGroups = wallGroups.map(group => group.map(wall => revitMappedToLayoutWall(revitMappedToRevitMapped2(wall))));
//     return layoutWallGroups.map(group => group.map(wall => compute(wall)));
// }
