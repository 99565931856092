import {
  ProductMeta,
  ProductsByType,
} from "../../../../RevitJS/Types/BddTypes";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import { SystemDetails } from "../../../../Components/ProductsBrowser/SystemDetails";
import { bdd } from "../../../../API/request";

export const getBrandByCode = (config: any, brandCode: string) =>
  bdd(
    "/objects/class/Brand/locale/en",
    { text: brandCode },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    config
  );

export const getRigipsProducts = (config: any, brandOid: string) =>
  bdd(
    "/objects/class/SGObject/locale/en?staticdb=true",
    {
      dependencies: [
        {
          className: "Brand",
          oids: [brandOid],
        },
      ],
      types: ["Product System"],
      principal: true,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    config
  );

export const getPlacoProduts = (config: any, brandOid: string) =>
  bdd(
    "/objects/class/SGObject/locale/en",
    {
      dependencies: [
        {
          className: "Brand",
          oids: [brandOid],
        },
      ],
      types: ["Solution Product"],
      principal: true,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    config
  );

export const fetchPlacoData = async (config: any): Promise<ProductMeta[]> => {
  let placoBrand = await getBrandByCode(config, "PLACO ");
  let placoBrandOid = placoBrand.data.objects[0].oid;
  const products: ProductMeta[] = await getPlacoProduts(
    config,
    placoBrandOid
  ).then(function (response: any) {
    return response.data.objects;
  });
  return products;
};

export const fetchRigipsData = async (config: any): Promise<ProductMeta[]> => {
  let rigipsBrand = await getBrandByCode(config, "RIGIPS");
  let rigipsBrandOid = rigipsBrand.data.objects[0].oid;
  const rigipsProducts: ProductMeta[] = await getRigipsProducts(
    config,
    rigipsBrandOid
  ).then(function (response: any) {
    return response.data.objects;
  });
  let albaBrand = await getBrandByCode(config, "ALBA");
  let albaBrandOid = albaBrand.data.objects[0].oid;
  const albaProducts: ProductMeta[] = await getRigipsProducts(
    config,
    albaBrandOid
  ).then(function (response: any) {
    return response.data.objects;
  });
  return rigipsProducts.concat(albaProducts);
};

export const sortPlacoData = (products: ProductMeta[]): ProductsByType => {
  let partitions: ProductMeta[] = [],
    liningWalls: ProductMeta[] = [],
    complexes: ProductMeta[] = [],
    ceilings: ProductMeta[] = [];

  products.forEach((product) => {
    if (product.longName.startsWith("Cloison")) {
      if (!product.longName.includes("Cinéstil"))
        if (!product.longName.includes("Caro"))
          if (!product.longName.includes("Mega")) partitions.push(product);
    } else if (product.longName.startsWith("Doublage")) {
      liningWalls.push(product);
    } else if (product.longName.startsWith("Complexes")) {
      complexes.push(product);
    } else if (product.longName.startsWith("Plafond")) {
      ceilings.push(product);
    }
  });

  return {
    partitions,
    liningWalls,
    complexes,
    ceilings,
  };
};

export const getPlacoSystemsDetails = (config: any, systemOid: ID[]) =>
  bdd(
    "/objects/details/class/SGObject/locale/en?includeChildren=true",
    systemOid,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    },
    config
  );

const split_array = (arr: any[], chunk = 400): any[][] => {
  let res = [];
  var i, j, temparray;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray = arr.slice(i, i + chunk);
    res.push(temparray);
  }
  return res;
};

export const getSystemDetailsById = async (
  ids: ID[],
  config: any
): Promise<{ data: { context: any; objects: SystemDetails[] } }> => {
  let ids_container = split_array(ids);
  let systemDetailsRequests = await Promise.all(
    ids_container.map((ids) => getPlacoSystemsDetails(config, ids))
  );
  // let systemDetailsRequest = await getPlacoSystemsDetails(bdd, ids);
  let systemDetailsRequest = {
    data: {
      context: systemDetailsRequests[0].data.context,
      objects: [],
    },
  };
  for (let i = 0; i < systemDetailsRequests.length; i++) {
    systemDetailsRequest.data.objects =
      systemDetailsRequest.data.objects.concat(
        systemDetailsRequests[i].data.objects
      );
  }

  return systemDetailsRequest;
};
