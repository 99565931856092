import { combineReducers } from "redux";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { config } from "../../../CLT/Delete/Reducers";
import { BufferType, IntNewCalpinageData, LanguageType } from "../Types";
import BufferReducer from "./bufferReducer";
import LanguageReducer from "./langReducer";
import ModuleReducer from "./moduleReducer";
import { ProjectDataReducer } from "./projectDataReducer";

const reducer = combineReducers<FacadeStore>({
    language: LanguageReducer,
    buffer: BufferReducer,
    moduleData: ModuleReducer,
    projectData: ProjectDataReducer,
    config
});


export interface FacadeStore {
    language: LanguageType,
    buffer: BufferType,
    moduleData: IntNewCalpinageData,
    projectData: initProjectData,
    config:any
}

export default reducer;
