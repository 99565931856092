import { api } from '../../../RevitJS/API';


export const functionalityId = "c15c57a7-e94f-4965-bcb4-ab23d2924c1e";

export const Components = [];


export const showWallsTrigger = async () => {
    const plasterboardIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Plasterboard" }, Rule: "Equals" }], null);
    const simpleFramesIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Ossature" }, Rule: "Equals" }], null);
    const doubleFramesIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Ossature-Double" }, Rule: "Equals" }], null);
   
    const bottomRailsLegacyIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail" }, Rule: "Equals" }], null);
    const upperRailsLegacyIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail-Retourne" }, Rule: "Equals" }], null);
    const bottomRailsNewIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail-bas" }, Rule: "Equals" }], null);
    const upperRailsNewIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail-haut" }, Rule: "Equals" }], null);

    const upperRailsIds = upperRailsLegacyIds.concat(upperRailsNewIds);
    const bottomRailsIds = bottomRailsLegacyIds.concat(bottomRailsNewIds);

    const processedWallIds = await api.queries.filterElements("Wall", [{ Param:{ Name:"Processed", Type:"Boolean", Value:true}, Rule:"Equals"}], null);

    const processedCeilingsIds = await api.queries.filterElements("Ceiling", [], null);


    const railsIds = bottomRailsIds.concat(upperRailsIds);
    let plasterboardsFilter = await api.queries.createSelectionFilter("SG_Plasterboards", plasterboardIds);
    let framesIds = simpleFramesIds.concat(doubleFramesIds);
    let framesFilter = await api.queries.createSelectionFilter("SG_Frames", framesIds);
    let railsFilter = await api.queries.createSelectionFilter("SG_Rails", railsIds);
    let processedWallsFilter = await api.queries.createSelectionFilter("SG_Revit_Walls", processedWallIds);
    let processedCeilingsFilter = await api.queries.createSelectionFilter("SG_Revit_Ceilings", processedCeilingsIds);
    
    let activeView = await api.queries.getActiveViewDetails();
    let activeViewId = activeView.Id;

    await api.viewHandler.setSelectionFilterVisibility(activeViewId, plasterboardsFilter, false);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, framesFilter, false);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, railsFilter, false);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, processedWallsFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, processedCeilingsFilter, true);
};


export const ShowWalls = {
    Name: {"French": "Vue d’origine", "English": "Original view", "German": "Originalansicht"},
    Trigger: showWallsTrigger,
    ShortDesc: {"French": "Afficher le modèle de base", "English": "Show base model", "German": "Basismodell anzeigen"},
    Id: functionalityId,
    Components,
    Icon: "/pluginIcons_V3/PlacoBIM_Icon_VueSystems.png",
    ParentId:"0"
}

