import { ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers, AnyAction } from "redux";
import {
  LevelData,
  TypeData,
  PlacoOptions,
  FilterType,
  initProjectData,
  errorData,
} from "../../../../RevitJS/Types/StoreTypes";
import { ElementParameter } from "../Actions/objectTypes";
import { bufferSelection } from "./bufferSelection";
import { wallTree } from "./wallTree";
import { ceilingTree } from "./ceilingTree";
import {
  historyPathAction,
  selectorTp,
  setSelectorTypeAction,
} from "../Actions/types";
import { ceilingSelectedTypes } from "./ceilingSelectedTypes";
import { wallSelectedTypes } from "./wallSelectedTypes";
import { wallSelectedLevels } from "./wallSelectedLevels";
import { ceilingSelectedLevels } from "./ceilingSelectedLevels";
import { ceilingTypesData } from "./ceilingTypesData";
import { wallTypesData } from "./wallTypesData";
import { ceilingLevelsData } from "./ceilingLevelsData";
import { wallLevelsData } from "./wallLevelsData";
import { selections } from "./selections";
import { filters } from "./filters";
import { placoData, placoSGObjectAttribute } from "./placoData";
import { selectionBuffer } from "./selectionBuffer";
import { filterType } from "./filterType";
import { displayedElements } from "./displayedElements";
import { filteredElements } from "./filteredElements";
import { searchedWord } from "./searchedWord";
import { activeRowIndex } from "./activeRowIndex";
import { systemDetailsVisible } from "./systemDetailsVisible";
import { LanguageAction, INIT_LANGUAGE } from "../Actions/types";
import { moduleReducers as moduleData } from "./moduleReducer";
import { layoutDetailsVisible } from "./layoutDetailsVisible";
import { elementParameter } from "./elementParameters";
import { projectData } from "./projectData";
import { Selection, Language } from "../Actions/types";
import { PlacoProducts, SolutionFilterType, ProductDetailData } from "../Types";
import {
  multiSelectionBuffer,
  tempMultiSelectionBuffer,
} from "./multiSelectionBuffer";
import { SET_ERROR_MODAL } from "../../../../Actions/types";
import { config } from "../../../CLT/Delete/Reducers";

export const language = (
  language: Language = "French",
  action: LanguageAction
): Language => {
  switch (action.type) {
    case INIT_LANGUAGE:
      return action.language;
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const activeSelectionId = (
  activeSelectionId: string | null = null,
  action: AnyAction
) => {
  switch (action.type) {
    default:
      return activeSelectionId;
  }
};

export const selectorType = (
  selectorType: selectorTp = "wall",
  action: setSelectorTypeAction
) => {
  switch (action.type) {
    case "SET_SELECTOR_TYPE":
      return action.selectorType;
    default:
      return selectorType;
  }
};

export const globalLoader = (state: boolean = false, action: any): boolean => {
  switch (action.type) {
    case "SET_GLOBAL_LOADER":
      return action.loading;
    case "FETCH_PLACO_DATA":
      return false;
    default:
      return state;
  }
};

export const metreParZone = (state: boolean = false, action: any): boolean => {
  switch (action.type) {
    case "SET_METRE_SELECTION":
      return action.value;
    default:
      return state;
  }
};

export const historyPath = (
  state: string = "root",
  action: historyPathAction
): string => {
  switch (action.type) {
    case "SET_HISTORY_PATH":
      return action.data;
    default:
      return state;
  }
};

export const errorModalData = (
  errorModalData: errorData = {
    Open: false,
    Code: "001",
    Report: null,
    Reload: false,
  },
  action: any
): errorData => {
  switch (action.type) {
    case SET_ERROR_MODAL:
      return action.value;
    default:
      return errorModalData;
  }
};

export const reducers = combineReducers<SelectionStore>({
  language,
  functionalityIcon,
  selections,
  activeSelectionId,
  bufferSelection,
  wallTree,
  ceilingTree,
  wallTypesData,
  wallLevelsData,
  ceilingTypesData,
  ceilingLevelsData,
  wallSelectedLevels,
  wallSelectedTypes,
  ceilingSelectedLevels,
  ceilingSelectedTypes,
  selectorType,
  filters,
  placoData,
  selectionBuffer,
  filterType,
  displayedElements,
  filteredElements,
  searchedWord,
  activeRowIndex,
  systemDetailsVisible,
  layoutDetailsVisible,
  moduleData,
  globalLoader,
  metreParZone,
  elementParameter,
  placoSGObjectAttribute,
  projectData,
  historyPath,
  multiSelectionBuffer,
  tempMultiSelectionBuffer,
  errorModalData,
  config,
});

export interface SelectionStore {
  language: Language;
  functionalityIcon: string;
  selections: { [key: string]: Selection<PlacoOptions> };
  activeSelectionId: string | null;
  bufferSelection: Selection<PlacoOptions> | null;
  ceilingTree: ElementsTree;
  wallTree: ElementsTree;
  wallTypesData: TypeData[];
  wallLevelsData: LevelData[];
  ceilingTypesData: TypeData[];
  ceilingLevelsData: LevelData[];
  wallSelectedLevels: string[];
  ceilingSelectedLevels: string[];
  wallSelectedTypes: string[];
  ceilingSelectedTypes: string[];
  selectorType: selectorTp;
  filters: SolutionFilterType;
  placoData: PlacoProducts;
  selectionBuffer: ProductDetailData | null;
  filterType: FilterType;
  displayedElements: ProductDetailData[];
  filteredElements: ProductDetailData[];
  searchedWord: string;
  activeRowIndex: number | null;
  systemDetailsVisible: boolean;
  layoutDetailsVisible: boolean;
  globalLoader: any;
  moduleData: any;
  metreParZone: any;
  elementParameter: ElementParameter[];
  placoSGObjectAttribute: any;
  projectData: initProjectData;
  historyPath: string;
  multiSelectionBuffer: ProductDetailData[] | null;
  tempMultiSelectionBuffer: ProductDetailData[] | null;
  errorModalData: errorData;
  config: any;
}
