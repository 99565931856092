import { AnyAction } from "redux";
import { selectLevelAction, createSelectionAction, updateBufferSelection } from "../Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";

type wallSelectedLevelsAction =
  | selectLevelAction
  | createSelectionAction<PlacoOptions>
  | updateBufferSelection;

export const wallSelectedLevels = (
  wallSelectedLevels: string[] = [],
  action: wallSelectedLevelsAction
): string[] => {
  switch (action.type) {
    case "SELECT_LEVEL":
      if (action.selectorType === "wall") {
        return action.selectedLevels;
      }
      return wallSelectedLevels;
    case "CREATE_SELECTION":
    case "UPDATE_BUFFER_SELECTION":
      return [];
    default:
      return wallSelectedLevels;
  }
};
