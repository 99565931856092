import _, {
  compact,
  filter,
  find,
  flatMap,
  get,
  includes,
  isEqual,
  map,
} from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import {
  Selection,
  PlacoOptions,
} from "../../../../../RevitJS/Types/StoreTypes";
import { InitDossierData } from "../../../Selection/Actions/types";
import { bundleFileDescriptors } from "../../../Selection/Helpers";
import { SelectionStore } from "../../../Selection/Reducers";
import { setDossierData } from "../../Actions";
import { getCurrentDate } from "../../Helpers";
import wording from "../../Resources/wording.json";
import { FunctionalityHeader } from "../FunctionalityHeader";
import { Routes } from "../root";
import { Ctable } from "./ctable";
import "../../Resources/FormTask.css";
import { OtherFileModal } from "./OtherFileModal";
import { BackupModal } from "./BackupModal";
import { dataURLtoFile } from "../../../CommonModules/Helpers/FileHelper";

const translation: any = wording;

interface props {
  routes: string;
  setRoute: any;
}

export type cctpType = {
  CCTPReference: string;
  Count: number;
  RevitSystems: string;
  Solution: {
    oid: string | undefined;
    label: string | undefined;
    files: any;
  };
  duplicated: boolean;
  files: any;
  isNonPlaco: boolean;
  isSelected: boolean;
  srno: number;
};

export const Cctp = ({ routes, setRoute }: props) => {
  const reduxState = useSelector((state: SelectionStore) => state);
  const [cctpData, setCctpData] = useState<cctpType[]>([]);
  const [openBackup, setOpenBackup] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [ref, setRef] = useState<any>();
  const [sortBy, setSortBy] = useState<{
    RevitSystems: "ascending" | "descending";
    Solution: "ascending" | "descending";
    CCTPReference: "ascending" | "descending";
    column: string;
  }>({
    RevitSystems: "ascending",
    Solution: "ascending",
    CCTPReference: "ascending",
    column: "RevitSystems",
  });

  const otherOptionsDAE = (data: cctpType) =>{
    let options = [];
    if(data.isNonPlaco){
      options.push({ value: "Fiche système", text: "Fiche système", disabled: false });
    }
    
    options.push({ value: "FP", text: "Fiche produit", disabled: false });
    options.push({ value: "ACERMI", text: "ACERMI", disabled: false });
    options.push({ value: "PV_INCENDIE", text: "PV Feu", disabled: false });
    options.push({value: "PV_ACOUSTIQUE",text: "Rapport d’essai acoustique",disabled: false});
    options.push({ value: "FDES", text: "FDES", disabled: false });
    options.push({ value: "AT", text: "Avis technique", disabled: false });
    options.push({ value: "DOP", text: "DOP", disabled: false });

    return options;
  }

  const otherOptionsDAO = (data: cctpType) => {
    let options = [];
    options.push({ value: "Fiche système", text: "Fiche système", disabled: false });
    if(data.isNonPlaco){
      options.push({ value: "FP", text: "Fiche produit", disabled: false });
    }
  return options;
  }
  const otherOptionsDAONonPlaco = [{ value: "FP", text: "Fiche produit", disabled: false }];

  const [fitcheModalOpen, setFitcheModalOpen] = useState<boolean>(false);
  const [fitcheModalRow, setFitcheModalRow] = useState<cctpType | undefined>();

  useEffect(() => {
    async function placoSelection() {
      let placoSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );
      if (reduxState.moduleData.dossierData.data.list.length > 0) {
        checkChangeinSelection(placoSelections);
      } else {
        newCreateCCtp(placoSelections);
      }
    }
    placoSelection();
  }, []);

  useEffect(() => {
    const blankCCtp = filter(cctpData, {
      isSelected: true,
      CCTPReference: "",
    }).length;
    const flaseCCtp = filter(cctpData, {
      isSelected: false,
    }).length
    setValidate(blankCCtp === 0 && flaseCCtp !== cctpData.length);
  }, [cctpData]);

  const checkChangeinSelection = (placoSelections: {
    [key: string]: Selection<PlacoOptions>;
  }) => {
    try {
      const dossierHistorySelection = filter(
        placoSelections,
        (sel: Selection<PlacoOptions>, index: string) => {
          if (
            includes(reduxState.moduleData.dossierData.selections.list, index)
          ) {
            return true;
          }
          return false;
        }
      );

      const wallDossierSelectionNewData = flatMap(
        map(dossierHistorySelection, (sel, index) => {
          return map(sel.SelectionByType.wall.Rows, (row, ind) => {
            return {
              Count: row.RevitSelection.Ids.length,
              RevitSystems: row.RevitSelection.RevitType,
              solutionOid: row.Options.MappedSystem?.oid,
              translation: row.Options.MappedSystem?.translation,
            };
          });
        })
      );

      const ceilingDossierSelectionNewData = flatMap(
        map(dossierHistorySelection, (sel, index) => {
          return map(sel.SelectionByType.ceiling.Rows, (row, ind) => {
            return {
              Count: row.RevitSelection.Ids.length,
              RevitSystems: row.RevitSelection.RevitType,
              solutionOid: row.Options.MappedSystem?.oid,
              translation: row.Options.MappedSystem?.translation,
            };
          });
        })
      );

      const dossierSelectionNewData = wallDossierSelectionNewData.concat(
        ceilingDossierSelectionNewData
      );

      const dossierHistoryData = compact(
        map(reduxState.moduleData.dossierData.data.list, (row, ind) => {
          if (!row.duplicated) {
            return {
              Count: row.Count,
              RevitSystems: row.RevitSystems,
              solutionOid: row.Solution.oid,
              translation: row.Solution.label,
            };
          }
          return null;
        })
      );

      if (
        !isEqual(
          _.sortBy(dossierSelectionNewData, "RevitSystems"),
          _.sortBy(dossierHistoryData, "RevitSystems")
        )
      ) {
        let sn = -1;

        let newSelectionData: any = [];
        map(dossierHistorySelection, (sel, index) => {
          return map(sel.SelectionByType.wall.Rows, (row, ind) => {
            newSelectionData.push(row);
            return row;
          });
        });

        map(dossierHistorySelection, (sel, index) => {
          return map(sel.SelectionByType.ceiling.Rows, (row, ind) => {
            newSelectionData.push(row);
            return row;
          });
        });

        // update dossier cctp wall count, solution id, label if changed in selection
        let cctpData = map(
          reduxState.moduleData.dossierData.data.list,
          (row, ind) => {
            sn = sn + 1;

            if (row.duplicated === false) {
              // skip dupliacate

              // find slection data by Revit Type and solution oid
              const revitSystemWithSolutionPresent: any = find(
                newSelectionData,
                (dl: any, ind: any) => {
                  return (
                    row.RevitSystems === dl.RevitSelection.RevitType &&
                    row.Solution.oid === dl.Options.MappedSystem.oid
                  );
                }
              );

              // if present selection then update there wall count, solution id, label
              // else return null i.e means the case that that wall type was deleted from solution
              if (revitSystemWithSolutionPresent) {
                let fileDescriptors = bundleFileDescriptors(
                  revitSystemWithSolutionPresent.Options.MappedSystem
                    ?.translation,
                  revitSystemWithSolutionPresent.Options.MappedSystem
                    ?.fileDescriptors
                );

                row.Count =
                  revitSystemWithSolutionPresent.RevitSelection.Ids.length;
                row.Solution = {
                  oid: revitSystemWithSolutionPresent.Options.MappedSystem?.oid,
                  label:
                    revitSystemWithSolutionPresent.Options.MappedSystem
                      ?.translation,
                  files: fileDescriptors,
                };
              } else {
                return null;
              }
            }
            return row;
          }
        );

        cctpData = compact(cctpData);

        // now check new wall type where added in selection
        // if it is added then add new cctp row for that
        map(newSelectionData, (row, ind) => {
          sn = sn + 1;

          const revitSystemWithSolutionPresent: any = find(
            cctpData,
            (dl: any, ind: any) => {
              return (
                dl.RevitSystems === row.RevitSelection.RevitType &&
                dl.Solution.oid === row.Options.MappedSystem?.oid
              );
            }
          );
          if (!revitSystemWithSolutionPresent) {
            let fileDescriptors = bundleFileDescriptors(
              row.Options.MappedSystem?.translation,
              row.Options.MappedSystem?.fileDescriptors
            );

            cctpData.push({
              CCTPReference: "",
              Count: row.RevitSelection.Ids.length,
              RevitSystems: row.RevitSelection.RevitType,
              Solution: {
                oid: row.Options.MappedSystem?.oid,
                label: row.Options.MappedSystem?.translation,
                files: fileDescriptors,
              },
              duplicated: false,
              files: [],
              isNonPlaco: _.includes(row.Options.MappedSystem?.oid, "custom"),
              isSelected: true,
              srno: sn,
            });
          }
        });

        setCctpData(_.sortBy(_.flatMap(cctpData), ["RevitSystems"]));
      } else {
        setCctpData(reduxState.moduleData.dossierData.data.list);
      }

      // setCctpData(_.sortBy(_.flatMap(cctpData), ["RevitSystems"]));
    } catch (error) {}
  };

  const newCreateCCtp = (placoSelections: {
    [key: string]: Selection<PlacoOptions>;
  }) => {
    try {
      const selectedSelections = filter(
        placoSelections,
        (sel: Selection<PlacoOptions>, index: string) => {
          if (
            includes(reduxState.moduleData.dossierData.selections.list, index)
          ) {
            return true;
          }
          return false;
        }
      );

      let sn = -1;

      const wallCctpData = map(selectedSelections, (sel, index) => {
        return map(sel.SelectionByType.wall.Rows, (row, ind) => {
          sn = sn + 1;

          let fileDescriptors = bundleFileDescriptors(
            row.Options.MappedSystem?.translation,
            row.Options.MappedSystem?.fileDescriptors
          );

          return {
            CCTPReference: "",
            Count: row.RevitSelection.Ids.length,
            RevitSystems: row.RevitSelection.RevitType,
            Solution: {
              oid: row.Options.MappedSystem?.oid,
              label: row.Options.MappedSystem?.translation,
              files: fileDescriptors,
            },
            duplicated: false,
            files: [],
            isNonPlaco: _.includes(row.Options.MappedSystem?.oid, "custom"),
            isSelected: true,
            srno: sn,
          };
        });
      });

      const ceilingCctpData = map(selectedSelections, (sel, index) => {
        return map(sel.SelectionByType.ceiling.Rows, (row, ind) => {
          sn = sn + 1;

          let fileDescriptors = bundleFileDescriptors(
            row.Options.MappedSystem?.translation,
            row.Options.MappedSystem?.fileDescriptors
          );

          return {
            CCTPReference: "",
            Count: row.RevitSelection.Ids.length,
            RevitSystems: row.RevitSelection.RevitType,
            Solution: {
              oid: row.Options.MappedSystem?.oid,
              label: row.Options.MappedSystem?.translation,
              files: fileDescriptors,
            },
            duplicated: false,
            files: [],
            isNonPlaco: _.includes(row.Options.MappedSystem?.oid, "custom"),
            isSelected: true,
            srno: sn,
          };
        });
      });

      const cctpData: any = wallCctpData.concat(ceilingCctpData);

      setCctpData(_.sortBy(_.flatMap(cctpData), ["RevitSystems"]));
    } catch (error) {}
  };

  const onSubmitClick = (): void => {
    const cloneDossierData: InitDossierData = JSON.parse(
      JSON.stringify(reduxState.moduleData.dossierData)
    );
    cloneDossierData.data.list = cctpData;
    cloneDossierData.data.status = validate;
    cloneDossierData.documentDate = getCurrentDate();
    dispatch(setDossierData(cloneDossierData));
    setRoute(Routes.TECHNICAL_LANDING);
  };

  const onBackButton = (): void => {
    if (!isEqual(reduxState.moduleData.dossierData.data.list, cctpData)) {
      setOpenBackup(true);
    } else {
      setRoute(Routes.TECHNICAL_LANDING);
    }
  };

  const onBackupSubmit = (result: boolean) => {
    if (result) {
      setOpenBackup(false);
      onSubmitClick();
    } else {
      setOpenBackup(false);
      setRoute(Routes.TECHNICAL_LANDING);
    }
  };

  const onsort = (columnName: string) => {
    let cloneCctpData: cctpType[] = JSON.parse(JSON.stringify(cctpData));
    if (columnName === "Solution") {
      cloneCctpData = _.sortBy(cloneCctpData, [
        function (o) {
          return o.Solution.label;
        },
      ]);
    } else {
      cloneCctpData = _.sortBy(cloneCctpData, [columnName]);
    }

    const srtOrder = sortOrderTobe(get(sortBy, columnName));

    if (srtOrder === "descending") {
      cloneCctpData = cloneCctpData.slice().reverse();
    }

    setCctpData(cloneCctpData);

    setSortBy({
      RevitSystems: columnName === "RevitSystems" ? srtOrder : "ascending",
      Solution: columnName === "Solution" ? srtOrder : "ascending",
      CCTPReference: columnName === "CCTPReference" ? srtOrder : "ascending",
      column: columnName,
    });
  };

  const sortOrderTobe = (currentOrder: "ascending" | "descending") => {
    return currentOrder === "ascending" ? "descending" : "ascending";
  };

  const handleActiveRow = (sn: number) => {
    const cloneDossierData: InitDossierData = JSON.parse(
      JSON.stringify(reduxState.moduleData.dossierData)
    );
    cloneDossierData.duplicatedActiveRow = filter(cctpData, { srno: sn });
    cloneDossierData.data.list = cctpData;
    cloneDossierData.data.status = validate;
    cloneDossierData.documentDate = getCurrentDate();
    dispatch(setDossierData(cloneDossierData));
  };

  const onRefChange = (node: any) => {
    setRef(node);
  };

  return (
    <div style={{ height: "100%" }}>
      {fitcheModalRow !== undefined && (
        <OtherFileModal
          open={fitcheModalOpen}
          closeModal={() => {
            setFitcheModalOpen(false);
            setFitcheModalRow(undefined);
          }}
          cctpData={cctpData}
          setCctpData={setCctpData}
          fitcheModalRow={fitcheModalRow}
          otherOptions={
            reduxState.moduleData.dossierData.documentType === "DAO"
              ? fitcheModalRow.isNonPlaco ? otherOptionsDAO(fitcheModalRow) : otherOptionsDAONonPlaco
              : otherOptionsDAE(fitcheModalRow)
          }
        />
      )}
      <BackupModal
        open={openBackup}
        backupSubmit={onBackupSubmit}
        documentType={reduxState.moduleData.dossierData.documentType}
      />
      <FunctionalityHeader
        name={translation.classification[reduxState.language]}
        Icon={reduxState.functionalityIcon}
        subheader=""
      />
      <div>
        <Ctable
          setSort={onsort}
          cctpData={cctpData}
          setCctpData={setCctpData}
          wording={translation}
          language={reduxState.language}
          setRoute={setRoute}
          handleActiveRow={handleActiveRow}
          onRefChange={onRefChange}
          refin={ref}
          sortBy={sortBy}
          setFitcheModalOpen={setFitcheModalOpen}
          setFitcheModalRow={setFitcheModalRow}
        />
      </div>
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 30,
          width: "100%",
        }}
      >
        <Button
          color="orange"
          size="medium"
          onClick={() => onBackButton()}
          content={translation.back[reduxState.language]}
          style={{ marginRight: 30 }}
        ></Button>

        <Button
          disabled={!validate}
          size="medium"
          primary
          content={translation.validate[reduxState.language]}
          onClick={() => onSubmitClick()}
        ></Button>
      </div>
    </div>
  );
};
