import React, { createContext, useEffect, useState } from "react";
import { getAllPlugins, getPrescriptionActiveVersion, getPrescriptionPluginAdvanceSelector, getPrescriptionPluginFamilyAttribute } from "../../../../Services/mapping-config-service";
import { MyConfig } from "../../../../Helper";
import { CategoryTypeEnum } from "../assets/constant";
import _ from "lodash";

export interface IMappingConfigWeberContextType {
  advanseSelectorData: any;
  mainCategoryData: any;
  activeVersionData: any;
  prescriptionData: any;
  categoryTypeOptions: any;
  setCategoryTypeOption: (data: any[]) => void;
  setPrescriptionData: (data: string) => void;
  setAdvanceSelector: (data: string) => void;
  setMainCategory: (data: string) => void;
  setActiveVersion: (data: string) => void;
}

export const MappingConfigWeberContext =
  createContext<IMappingConfigWeberContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const MappingConfigWeberProvider: React.FC<Props> = ({ children }) => {
  //#region variableDeclaration
  const [prescriptionData, setPrescriptionData] = useState<any>(null);
  const [advanseSelectorData, setAdvanceSelector] = useState<any>(null);
  const [mainCategoryData, setMainCategory] = useState<any>(null);
  const [activeVersionData, setActiveVersion] = useState<any>(null);
  const [categoryTypeOptions, setCategoryTypeOption] = useState<any[]>([]);
  //#endregion variableDeclaration

  useEffect(() => {
    initialLoader();
  }, []);

  const bindCategoryTypes = (mainCategories: any) => {
    let options: any[] = [];
    _.forEach(CategoryTypeEnum, (dt: any) => {
      if (
        mainCategories.some(
          (m: any) =>
            m.type ===
            Object.keys(CategoryTypeEnum)[
              Object.values(CategoryTypeEnum).indexOf(dt)
            ]
        )
      ) {
        options.push({
          value:
            Object.keys(CategoryTypeEnum)[
              Object.values(CategoryTypeEnum).indexOf(dt)
            ],
          label: dt,
        });
      }
    });

    setCategoryTypeOption(options);
  };

  //#region query
  const  initialLoader = async() => {
    const config = await MyConfig();
    let plugins = await getAllPlugins(config);
    const application = plugins.find((p: any) => p.applicationName === "Mortar Utama Indonesia");
    if (application) {
      let prescription: any = await getPrescriptionActiveVersion(application.applicationId, config);
      setPrescriptionData(prescription);
      let advanceSelector = await getPrescriptionPluginAdvanceSelector(prescription.prescriptionPluginId, config, prescription.activeVersionId);
      let mainCategory = await getPrescriptionPluginFamilyAttribute(prescription.prescriptionPluginId, config, prescription.activeVersionId);
      setAdvanceSelector(advanceSelector);
      setMainCategory(mainCategory);
      setActiveVersion(prescription.version);
      bindCategoryTypes(advanceSelector.mainCategories);
    }
  }
  //#endregion query

  return (
    <MappingConfigWeberContext.Provider
      value={{
        advanseSelectorData,
        mainCategoryData,
        activeVersionData,
        prescriptionData, 
        categoryTypeOptions, 
        setCategoryTypeOption,
        setPrescriptionData,
        setAdvanceSelector,
        setMainCategory,
        setActiveVersion,
      }}
    >
      {children}
    </MappingConfigWeberContext.Provider>
  );
};

export default MappingConfigWeberProvider;
