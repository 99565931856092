import { ThunkAction } from "redux-thunk";
import { DrawStore } from "../Reducers";
import { AxiosInstance } from "axios";
import {
  FETCH_PLACO_DATA,
  SET_SELECTION_TREE,
  InitGroupSelectorAction,
  INIT_GROUP_SELECTOR,
  SelectTypeAction,
  SELECT_TYPE,
  SelectLevelAction,
  SELECT_LEVEL,
  InitMappingTableWallsAction,
  INIT_MAPPING_TABLE_WALLS,
  SelectSystemAction,
  SELECT_SYSTEM,
  InitProductSelectorAction,
  INIT_PRODUCT_SELECTOR,
  SetSelectionBufferAction,
  SET_SELECTION_BUFFER,
  RESET_SELECTION_BUFFER,
  ResetSelectionBufferAction,
  SetActiveRowIndexAction,
  SET_ACTIVE_MAPPING_ROW_INDEX,
  SearchAction,
  SEARCH,
  APPLY_FILTERS,
  ApplyFiltersAction,
  ResetFiltersAction,
  RESET_FILTERS,
  FetchDataAction,
  INIT_LAYOUT_PROGRESS,
  InitLayoutProgressAction,
  UpdateLayoutProgressAction,
  UPDATE_LAYOUT_PROGRESS,
  StartLayoutAction,
  START_LAYOUT,
  EndLayoutAction,
  END_LAYOUT,
  FETCH_RIGIPS_DATA,
  FetchRigipsDataAction,
  SetFireConstrainAction,
  SetSwitchParementAction,
  SetReverseAction,
  SET_FIRE_CONSTRAIN,
  SET_SWITCH_PAREMENT,
  SET_REVERSE,
  SetPublicConstrainAction,
  SET_PUBLIC_CONSTRAIN,
  InitLanguageAction,
  INIT_LANGUAGE,
  ToggleCheckAction,
  TOGGLE_CHECK,
  InitMappingTableCeilingsAction,
  INIT_MAPPING_TABLE_CEILINGS,
  SelectSystemCeilingsAction,
  ToggleCheckCeilingsAction,
  SetFireConstrainCeilingsAction,
  SET_ADDITIONAL_WEIGHT_CEILINGS,
  SET_FIRE_CONSTRAIN_CEILINGS,
  TOGGLE_CHECK_CEILINGS,
  SELECT_SYSTEM_CEILINGS,
  TOGGLE_ALL_CEILINGS,
  FetchRigipsDataCeilingsAction,
  FETCH_RIGIPS_DATA_CEILINGS,
  INIT_GROUP_SELECTOR_CEILING,
  InitGroupSelectorCeilingAction,
  SelectLevelCeilingAction,
  SELECT_LEVEL_CEILING,
  SelectTypeCeilingAction,
  SELECT_TYPE_CEILING,
  SET_SELECTION_TREE_CEILING,
  SetAdditionalWeightCeilingsAction,
  START_LAYOUT_CEILING,
  END_LAYOUT_CEILING,
  UPDATE_LAYOUT_PROGRESS_CEILING,
  INIT_LAYOUT_PROGRESS_CEILING,
  InitLayoutProgressCeilingAction,
  EndLayoutCeilingAction,
  StartLayoutCeilingAction,
  UpdateLayoutProgressCeilingAction,
} from "./types";
import { fetchPlacoData, fetchRigipsData } from "../../Draw2/Requests";
import { sortPlacoData } from "../Requests";
import {
  ProductMeta,
  ProductsByType,
  ProductDetailData,
  PIMLayoutAttributesAid,
} from "../../../../RevitJS/Types/BddTypes";
import { ID, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import {
  elementTypes,
  levelData,
  getSelectedItems,
  filterElements2,
  getLayoutAttributesAid,
} from "../../../../RevitJS/Helpers";
import { getSystemsDetailsAndFilters, startInList } from "../Helpers";
import { TypeData, SelectedItem } from "../../../../RevitJS/Types/StoreTypes";
import { api } from "../../../../RevitJS/API";
import { wait } from "../../../../RevitJS/Helpers";

import { A101Handler } from "../Helpers/LayoutHandlers/a101handler";
import { A301Handler } from "../Helpers/LayoutHandlers/a301Handler";
import { A02Handler } from "../Helpers/LayoutHandlers/a102Handler";
import { AyHandler } from "../Helpers/LayoutHandlers/ayHandler";
import { A302Handler } from "../Helpers/LayoutHandlers/a302Handler";
import { whoolHandler } from "../Helpers/LayoutHandlers/whoolHandler";
import { MyConfig } from "../../../../Helper";

export const fetchRemoteData =
  (
    bdd: AxiosInstance
  ): ThunkAction<void, DrawStore, unknown, FetchDataAction> =>
  async (dispatch: any, getState: any) => {
    const { filters } = getState();
    let systems = await fetchPlacoData(bdd);
    let sortedSystems = sortPlacoData(systems) as ProductsByType;

    let IdsByType: { partitions: ID[]; liningWalls: ID[] } = {
      partitions: sortedSystems.partitions.map((system) => system.oid),
      liningWalls: sortedSystems.liningWalls.map((system) => system.oid),
    };

    let partitionsDetails = await getSystemsDetailsAndFilters(
      IdsByType.partitions,
      bdd,
      filters.partitions
    );
    let liningWallsDetails = await getSystemsDetailsAndFilters(
      IdsByType.liningWalls,
      bdd,
      filters.liningWalls
    );

    liningWallsDetails.systemsDetails = liningWallsDetails.systemsDetails.map(
      (sys, index) => {
        return {
          ...sys,
          thumbnailUrl: sortedSystems.liningWalls[index].thumbnailUrl,
        };
      }
    );
    partitionsDetails.systemsDetails = partitionsDetails.systemsDetails.map(
      (sys, index) => {
        return {
          ...sys,
          thumbnailUrl: sortedSystems.partitions[index].thumbnailUrl,
        };
      }
    );

    let layoutAttributesAid = getLayoutAttributesAid(
      partitionsDetails.attributes
    ) as PIMLayoutAttributesAid;

    dispatch({
      type: FETCH_PLACO_DATA,
      liningWallsDetails: liningWallsDetails,
      partitionsDetails,
      layoutAttributesAid,
    });
  };

function compare(a: ProductMeta, b: ProductMeta) {
  if (a.translation < b.translation) {
    return -1;
  }
  if (a.translation > b.translation) {
    return 1;
  }
  return 0;
}

export const albaSystemsList = [
  "1-A.0",
  "1-AH.0",
  "1-A.1",
  "1-AH.1",
  "1-AB",
  "1-A.2",
  "1-AH.2",
  "3-A.0",
  "3-AP",
  "3-AT",
  "3-A.1.1",
  "3-A.1x",
  "3-A.1.2",
  "3-A.1.1",
  "5-A.0.1",
  "5-A.1.1",
];

export const rigipsSystemsList = [
  "1-AR",
  "1-DL",
  "1-DLI",
  "1-DTI",
  "1-DT",
  "1-GRF",
  "1-GRH",
  "1-RB",
  "1-RBI",
  "1-RBS",
  "1-RDH",
  "1-RF",
  "1-RFI",
  "1-HA",
  "1-XR",
  "3-AR",
  "3-DL",
  "3-GRF",
  "3-RB",
  "3-GRH",
  "3-RBS",
  "3-RDS",
  "3-RDH",
  "3-DT",
  "3-HA",
  "3-RF",
  "3-XR",
  "5-RF",
  "5-AR",
  "5-DL",
  "5-GRF",
  "5-RDH",
  "5-RF",
];

export const fetchRigipsRemoteData =
  (config: any): ThunkAction<void, DrawStore, unknown, FetchRigipsDataAction> =>
  async (dispatch: any, getState: any) => {
    let systems = await fetchRigipsData(config);
    let products = systems.filter((sys) =>
      startInList(sys.translation, albaSystemsList.concat(rigipsSystemsList))
    );
    products = products.sort((a, b) => compare(a, b));

    dispatch({
      type: FETCH_RIGIPS_DATA,
      products,
    });
  };

export const albaSystemsCeilingsList = [
  "6-A.0",
  "6-AH.0",
  "6-A.1",
  "6-AH.1",
  "6-AB",
  "6-A.2",
  "6-AH.2",
  "6-A.0",
  "6-AP",
  "6-AT",
  "6-A.1.1",
  "6-A.1x",
  "6-A.1.2",
  "6-A.1.1",
  "6-A.0.1",
  "6-A.1.1",
];

export const rigipsSystemsCeilingsList = [
  "6-AR",
  "6-DL",
  "6-DLI",
  "6-DTI",
  "6-DT",
  "6-GRF",
  "6-GRH",
  "6-RB",
  "6-RBI",
  "6-RBS",
  "6-RDH",
  "6-RF",
  "6-RFI",
  "6-HA",
  "6-XR",
  "6-AR",
  "6-DL",
  "6-GRF",
  "6-RB",
  "6-GRH",
  "6-RBS",
  "6-RDS",
  "6-RDH",
  "6-DT",
  "6-HA",
  "6-RF",
  "6-XR",
  "6-RF",
  "6-AR",
  "6-DL",
  "6-GRF",
  "6-RDH",
  "6-RF",
];

export const fetchRigipsRemoteDataCeilings =
  (
    config: any
  ): ThunkAction<void, DrawStore, unknown, FetchRigipsDataCeilingsAction> =>
  async (dispatch: any, getState: any) => {
    let systems = await fetchRigipsData(config);
    let products = systems.filter((sys) =>
      startInList(
        sys.translation,
        albaSystemsCeilingsList.concat(rigipsSystemsCeilingsList)
      )
    );
    products = products.sort((a, b) => compare(a, b));

    dispatch({
      type: FETCH_RIGIPS_DATA_CEILINGS,
      products,
    });
  };

export const setSelectionTree = (tree: ElementsTree) => {
  return {
    type: SET_SELECTION_TREE,
    tree,
  };
};

export const setSelectionTreeCeiling = (tree: ElementsTree) => {
  return {
    type: SET_SELECTION_TREE_CEILING,
    tree,
  };
};

export const initGroupSelector = (
  tree: ElementsTree
): InitGroupSelectorAction => {
  return {
    type: INIT_GROUP_SELECTOR,
    tree,
  };
};

export const initGroupSelectorCeiling = (
  tree: ElementsTree
): InitGroupSelectorCeilingAction => {
  return {
    type: INIT_GROUP_SELECTOR_CEILING,
    tree,
  };
};

export const selectType =
  (typeName: string): ThunkAction<void, DrawStore, unknown, SelectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const { typesData, selectedTypes } = getState();
    let typesDataCopy = [...typesData];
    let selectedTypesCopy = [...selectedTypes];

    let selectedTypeIndex = typesData.findIndex(
      (type: any) => type.Type === typeName
    );

    if (typesData[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedTypes.filter(
        (type: any) => type !== typeName
      );
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedTypes, typeName];
    }

    dispatch({
      type: SELECT_TYPE,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectAllTypes =
  (check: boolean): ThunkAction<void, DrawStore, unknown, SelectTypeAction> =>
  async (dispatch: any, getState: any) => {
    const { typesData } = getState();

    let selectedTypesCopy: any[] = [];
    let typesDataCopy = typesData.map((type: any) => {
      type.Checked = check;
      if (check) {
        selectedTypesCopy.push(type.Type);
      }
      return type;
    });

    dispatch({
      type: SELECT_TYPE,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectLevel =
  (
    levelName: string
  ): ThunkAction<void, DrawStore, unknown, SelectLevelAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsData, selectedLevels, selectionTree } = getState();
    let levelsDataCopy = [...levelsData];
    let selectedLevelsCopy = [...selectedLevels];

    let selectedLevelIndex = levelsData.findIndex(
      (level: any) => level.Name === levelName
    );

    if (levelsData[selectedLevelIndex].Checked) {
      levelsDataCopy[selectedLevelIndex].Checked = false;
      selectedLevelsCopy = selectedLevels.filter(
        (level: any) => level !== levelName
      );
    } else {
      levelsDataCopy[selectedLevelIndex].Checked = true;
      selectedLevelsCopy = [...selectedLevels, levelName];
    }

    dispatch({
      type: SELECT_LEVEL,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const selectAllLevels =
  (check: boolean): ThunkAction<void, DrawStore, unknown, SelectLevelAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsData, selectionTree } = getState();
    let selectedLevelsCopy: any[] = [];
    let levelsDataCopy = levelsData.map((level: any) => {
      level.Checked = check;
      if (check) {
        selectedLevelsCopy.push(level.Name);
      }
      return level;
    });
    dispatch({
      type: SELECT_LEVEL,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTree, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const selectTypeCeilings =
  (
    typeName: string
  ): ThunkAction<void, DrawStore, unknown, SelectTypeCeilingAction> =>
  async (dispatch: any, getState: any) => {
    const { typesDataCeilings, selectedTypesCeilings } = getState();
    let typesDataCopy = [...typesDataCeilings];
    let selectedTypesCopy = [...selectedTypesCeilings];

    let selectedTypeIndex = typesDataCeilings.findIndex(
      (type: any) => type.Type === typeName
    );

    if (typesDataCeilings[selectedTypeIndex].Checked) {
      typesDataCopy[selectedTypeIndex].Checked = false;
      selectedTypesCopy = selectedTypesCeilings.filter(
        (type: any) => type !== typeName
      );
    } else {
      typesDataCopy[selectedTypeIndex].Checked = true;
      selectedTypesCopy = [...selectedTypesCeilings, typeName];
    }

    dispatch({
      type: SELECT_TYPE_CEILING,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectAllTypesCeilings =
  (
    check: boolean
  ): ThunkAction<void, DrawStore, unknown, SelectTypeCeilingAction> =>
  async (dispatch: any, getState: any) => {
    const { typesDataCeilings } = getState();

    let selectedTypesCopy: any[] = [];
    let typesDataCopy = typesDataCeilings.map((type: any) => {
      type.Checked = check;
      if (check) {
        selectedTypesCopy.push(type.Type);
      }
      return type;
    });
    dispatch({
      type: SELECT_TYPE_CEILING,
      typesData: typesDataCopy,
      selectedTypes: selectedTypesCopy,
    });
  };

export const selectLevelCeilings =
  (
    levelName: string
  ): ThunkAction<void, DrawStore, unknown, SelectLevelCeilingAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsDataCeilings, selectedLevelsCeilings, selectionTreeCeiling } =
      getState();
    let levelsDataCopy = [...levelsDataCeilings];
    let selectedLevelsCopy = [...selectedLevelsCeilings];

    let selectedLevelIndex = levelsDataCeilings.findIndex(
      (level: any) => level.Name === levelName
    );

    if (levelsDataCeilings[selectedLevelIndex].Checked) {
      levelsDataCopy[selectedLevelIndex].Checked = false;
      selectedLevelsCopy = selectedLevelsCeilings.filter(
        (level: any) => level !== levelName
      );
    } else {
      levelsDataCopy[selectedLevelIndex].Checked = true;
      selectedLevelsCopy = [...selectedLevelsCeilings, levelName];
    }
    dispatch({
      type: SELECT_LEVEL_CEILING,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTreeCeiling, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const selectAllLevelsCeilings =
  (
    check: boolean
  ): ThunkAction<void, DrawStore, unknown, SelectLevelCeilingAction> =>
  async (dispatch: any, getState: any) => {
    const { levelsDataCeilings, selectionTreeCeiling } = getState();
    let selectedLevelsCopy: any[] = [];
    let levelsDataCopy = levelsDataCeilings.map((level: any) => {
      level.Checked = check;
      if (check) {
        selectedLevelsCopy.push(level.Name);
      }
      return level;
    });
    dispatch({
      type: SELECT_LEVEL_CEILING,
      selectedLevels: selectedLevelsCopy,
      selectedTypes: [],
      levelsData: levelsDataCopy,
      typesData: elementTypes(
        levelData(selectionTreeCeiling, selectedLevelsCopy)
      ) as TypeData[],
    });
  };

export const initMappingTable =
  (): ThunkAction<void, DrawStore, unknown, InitMappingTableWallsAction> =>
  async (dispatch: any, getState: any) => {
    const { selectionTree, selectedLevels, selectedTypes } = getState();
    let selectedItems: SelectedItem[] = getSelectedItems(
      selectionTree,
      selectedLevels,
      selectedTypes
    );
    dispatch({
      type: INIT_MAPPING_TABLE_WALLS,
      mappingRowsWalls: selectedItems.map((item, index) => {
        return {
          Checked: true,
          Index: index,
          RevitSelection: { RevitType: item.Type, Ids: item.Ids },
          MappedSystem: null,
          FireConstrain: false,
          SwitchParement: false,
          Reverse: true,
          PublicBuildingConstrain: false,
        };
      }),
    });
  };

export const initMappingTableWallsManualSelection = (
  selectedItemsWalls: SelectedItem[]
): InitMappingTableWallsAction => {
  return {
    type: INIT_MAPPING_TABLE_WALLS,
    mappingRowsWalls: selectedItemsWalls.map((item, index) => {
      return {
        Checked: true,
        Index: index,
        RevitSelection: { RevitType: item.Type, Ids: item.Ids },
        MappedSystem: null,
        FireConstrain: false,
        SwitchParement: false,
        Reverse: true,
        PublicBuildingConstrain: false,
      };
    }),
  };
};

export const initMappingTableCeilings =
  (): ThunkAction<void, DrawStore, unknown, InitMappingTableCeilingsAction> =>
  async (dispatch: any, getState: any) => {
    const {
      selectionTreeCeiling,
      selectedLevelsCeilings,
      selectedTypesCeilings,
    } = getState();
    let selectedItems: SelectedItem[] = getSelectedItems(
      selectionTreeCeiling,
      selectedLevelsCeilings,
      selectedTypesCeilings
    );

    dispatch({
      type: INIT_MAPPING_TABLE_CEILINGS,
      mappingRowsCeilings: selectedItems.map((item, index) => {
        return {
          Checked: true,
          Index: index,
          RevitSelection: { RevitType: item.Type, Ids: item.Ids },
          MappedSystem: null,
          FireConstrain: false,
          AdditionalWeight: false,
        };
      }),
    });
  };

export const initMappingTableCeilingsManualSelection = (
  selectedItemsCeilings: SelectedItem[]
): InitMappingTableCeilingsAction => {
  return {
    type: INIT_MAPPING_TABLE_CEILINGS,
    mappingRowsCeilings: selectedItemsCeilings.map((item, index) => {
      return {
        Checked: true,
        Index: index,
        RevitSelection: { RevitType: item.Type, Ids: item.Ids },
        MappedSystem: null,
        FireConstrain: false,
        AdditionalWeight: false,
      };
    }),
  };
};

export const selectSystem = (
  index: number,
  system: ProductDetailData
): SelectSystemAction => {
  return {
    type: SELECT_SYSTEM,
    index,
    system,
  };
};

export const toggleCheck = (rowIndex: number): ToggleCheckAction => {
  return {
    type: TOGGLE_CHECK,
    Index: rowIndex,
  };
};

export const setFireConstrain = (
  index: number,
  fireConstrain: boolean
): SetFireConstrainAction => {
  return {
    type: SET_FIRE_CONSTRAIN,
    Index: index,
    FireConstrain: fireConstrain,
  };
};

export const setSwitchParement = (
  index: number,
  switchParement: boolean
): SetSwitchParementAction => {
  return {
    type: SET_SWITCH_PAREMENT,
    Index: index,
    SwitchParement: switchParement,
  };
};

export const setReverse = (
  index: number,
  reverse: boolean
): SetReverseAction => {
  return {
    type: SET_REVERSE,
    Index: index,
    Reverse: reverse,
  };
};

export const setPublicConstrain = (
  index: number,
  publicConstrain: boolean
): SetPublicConstrainAction => {
  return {
    type: SET_PUBLIC_CONSTRAIN,
    Index: index,
    PublicConstrain: publicConstrain,
  };
};

export const setAdditionalWeightCeilings = (
  index: number,
  additionalWeight: boolean
): SetAdditionalWeightCeilingsAction => {
  return {
    type: SET_ADDITIONAL_WEIGHT_CEILINGS,
    Index: index,
    additionalWeight: additionalWeight,
  };
};

export const selectSystemCeilings = (
  index: number,
  system: ProductDetailData
): SelectSystemCeilingsAction => {
  return {
    type: SELECT_SYSTEM_CEILINGS,
    index,
    system,
  };
};

export const toggleCheckCeilings = (
  rowIndex: number
): ToggleCheckCeilingsAction => {
  return {
    type: TOGGLE_CHECK_CEILINGS,
    Index: rowIndex,
  };
};

export const setFireConstrainCeilings = (
  index: number,
  fireConstrain: boolean
): SetFireConstrainCeilingsAction => {
  return {
    type: SET_FIRE_CONSTRAIN_CEILINGS,
    Index: index,
    FireConstrain: fireConstrain,
  };
};

export const initProductSelector =
  (): ThunkAction<void, DrawStore, unknown, InitProductSelectorAction> =>
  async (dispatch: any, getState: any) => {
    const { filterTypeEnabled, placoData } = getState();
    let systems =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;
    dispatch({
      type: INIT_PRODUCT_SELECTOR,
      filteredElements: systems,
      displayedElements: systems.length > 20 ? systems.slice(0, 20) : systems,
    });
  };

export const setSelectionBuffer = (
  system: ProductDetailData
): SetSelectionBufferAction => {
  return {
    type: SET_SELECTION_BUFFER,
    system,
  };
};

export const resetSelectionBuffer = (): ResetSelectionBufferAction => {
  return {
    type: RESET_SELECTION_BUFFER,
  };
};

export const setActiveMappingRowIndex = (
  index: number
): SetActiveRowIndexAction => {
  return {
    type: SET_ACTIVE_MAPPING_ROW_INDEX,
    index,
  };
};

export const toggleAllCeilings = (checked: boolean = true) => {
  return {
    type: TOGGLE_ALL_CEILINGS,
    checked,
  };
};

export const search =
  (word: string): ThunkAction<void, DrawStore, unknown, SearchAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterTypeEnabled, placoData } = getState();

    const activeFilters =
      filterTypeEnabled === "partitionsWalls"
        ? filters.partitions
        : filters.liningWalls;
    const activeData =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;

    let filteredElements = filterElements2(activeFilters, activeData);

    filteredElements = filteredElements.filter((el: ProductDetailData) =>
      el.translation.replace("®", "").toUpperCase().includes(word.toUpperCase())
    );

    dispatch({
      type: SEARCH,
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      searchedWord: word,
    });
  };

export const applyFilters =
  (
    filterName: string,
    filterValue: any
  ): ThunkAction<void, DrawStore, unknown, ApplyFiltersAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterTypeEnabled, placoData } = getState();

    const activeFilters =
      filterTypeEnabled === "partitionsWalls"
        ? [...filters.partitions]
        : [...filters.liningWalls];
    const activeData =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;

    const updatedFilters = activeFilters.map((filter) => {
      if (filter.name !== filterName) {
        return filter;
      }
      filter.value = filterValue;
      return filter;
    });

    let filtersCopy = { ...filters };
    if (filterTypeEnabled === "partitionsWalls") {
      filtersCopy.partitions = updatedFilters;
    } else {
      filtersCopy.liningWalls = updatedFilters;
    }

    let filteredElements = filterElements2(updatedFilters, activeData);

    dispatch({
      type: APPLY_FILTERS,
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      filters: filtersCopy,
    });
  };

export const resetFilters =
  (): ThunkAction<void, DrawStore, unknown, ResetFiltersAction> =>
  async (dispatch: any, getState: any) => {
    const { filters, filterTypeEnabled, placoData } = getState();

    const activeFilters =
      filterTypeEnabled === "partitionsWalls"
        ? [...filters.partitions]
        : [...filters.liningWalls];
    const activeData =
      filterTypeEnabled === "partitionsWalls"
        ? placoData.partitionWalls.systemsDetails
        : placoData.liningWalls.systemsDetails;

    const updatedFilters = activeFilters.map((filter) => {
      return { ...filter, value: null };
    });

    let filtersCopy = { ...filters };
    if (filterTypeEnabled === "partitionsWalls") {
      filtersCopy.partitions = updatedFilters;
    } else {
      filtersCopy.liningWalls = updatedFilters;
    }
    let filteredElements = filterElements2(updatedFilters, activeData);

    dispatch({
      type: RESET_FILTERS,
      filteredElements,
      displayedElements:
        filteredElements.length > 20
          ? filteredElements.slice(0, 20)
          : filteredElements,
      filters: filtersCopy,
    });
  };

export const initLayoutProgress = (): InitLayoutProgressAction => {
  return {
    type: INIT_LAYOUT_PROGRESS,
  };
};

export const updateLayoutProgress = (
  progress: number
): UpdateLayoutProgressAction => {
  return {
    type: UPDATE_LAYOUT_PROGRESS,
    progress,
  };
};

export const startLayout = (): StartLayoutAction => {
  return {
    type: START_LAYOUT,
  };
};

export const endLayout = (): EndLayoutAction => {
  return {
    type: END_LAYOUT,
  };
};

export const initLayoutProgressCeiling =
  (): InitLayoutProgressCeilingAction => {
    return {
      type: INIT_LAYOUT_PROGRESS_CEILING,
    };
  };

export const updateLayoutProgressCeiling = (
  progress: number
): UpdateLayoutProgressCeilingAction => {
  return {
    type: UPDATE_LAYOUT_PROGRESS_CEILING,
    progress,
  };
};

export const startLayoutCeiling = (): StartLayoutCeilingAction => {
  return {
    type: START_LAYOUT_CEILING,
  };
};

export const endLayoutCeiling = (): EndLayoutCeilingAction => {
  return {
    type: END_LAYOUT_CEILING,
  };
};

export const drawLayout =
  (layoutData: any[][]): ThunkAction<void, DrawStore, unknown, any> =>
  async (dispatch: any) => {
    dispatch(startLayout());

    let config = await MyConfig();

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Plaque",
          FamilyTypes: ["Rigips_Plaque"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Ossature",
          FamilyTypes: ["Rigips_Ossature"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Profile",
          FamilyTypes: ["Rigips_Profile"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Rail-bas",
          FamilyTypes: ["Rigips_Rail-bas"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Rail-haut",
          FamilyTypes: ["Rigips_Rail-haut"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );
    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Ossature-Double",
          FamilyTypes: ["Rigips_Ossature-Double"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    dispatch(initLayoutProgress());
    let nb_groups = layoutData.length;
    for (let i = 0; i < nb_groups; i++) {
      let group = layoutData[i];
      for (let j = 0; j < group.length; j++) {
        let layoutResult = group[j];
        await api.familyEditor.createAndSetParameters(
          "Wall",
          "Instance",
          "DATA",
          [
            {
              Id: layoutResult.id,
              Params: [
                { Name: "Processed", Type: "YesNo", Value: 1 },
                {
                  Name: "SG_System",
                  Type: "Text",
                  Value: layoutResult.technicalName,
                },
                {
                  Name: "AlbaId",
                  Type: "Text",
                  Value: layoutResult.id,
                },
              ],
            },
          ]
        );
        if (
          group[j].layoutType !== "Alba" &&
          !startInList(group[j].translation, [
            "1-A.1.1",
            "1-AH.1.1",
            "1-AB.1.1",
            "1-A.1.2",
            "1-A.2.2y",
            "3-A.1.1",
            "5-A.1.1",
          ])
        ) {
          whoolHandler(group[j]);
          if (layoutResult.rails.length) {
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.rails);
          }
          if (layoutResult.plasterboards.length) {
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.plasterboards
            );
          }
          if (layoutResult.flipped_rails.length) {
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.flipped_rails
            );
          }

          if (layoutResult.frames.length) {
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.frames);
          }
        } else {
          if (startInList(group[j].translation, ["1-A.0.1", "1-AH.0.1"])) {
            await A101Handler(group[j]);
          } else if (
            startInList(group[j].translation, [
              "3-A.0.1",
              "3-AH.0.1",
              "3-AP.0.1",
              "3-AT.0.1",
              "5-A.0.1",
            ])
          ) {
            await A301Handler(group[j]);
          } else if (
            startInList(group[j].translation, ["1-A.0.2", "1-AH.0.2"])
          ) {
            whoolHandler(group[j]);
            await A02Handler(group[j]);
          } else if (
            startInList(group[j].translation, ["1-A.1.2y", "1-A.2.2y"])
          ) {
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.rails);
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.plasterboards.filter(
                (plast: any) =>
                  !["A25", "A40"].includes(
                    plast.props.find((prop: any) => prop.Name === "name").Value
                  )
              )
            );
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.flipped_rails
            );
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.frames);
            await AyHandler(group[j], 12.5);
          } else if (
            startInList(group[j].translation, [
              "1-A.1.1",
              "1-AH.1.1",
              "1-AB.1.1",
              "1-AH.1.2",
            ])
          ) {
            await whoolHandler(group[j]);
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.rails);
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.flipped_rails
            );
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.frames);
            await A02Handler(group[j]);
          } else if (
            startInList(group[j].translation, ["3-A.1.1", "3-A.1.2"])
          ) {
            await A301Handler(group[j]);
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.rails);
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.flipped_rails
            );
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.frames);
          } else if (startInList(group[j].translation, ["3-A.1.2"])) {
            await A302Handler(group[j]);
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.rails);
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.flipped_rails
            );
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.frames);
          } else if (startInList(group[j].translation, ["5-A.1.1"])) {
            await A301Handler(group[j]);
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.rails);
            await api.familyEditor.placeFamiliesAtPoints(
              layoutResult.flipped_rails
            );
            await api.familyEditor.placeFamiliesAtPoints(layoutResult.frames);
          }
        }
        dispatch(
          updateLayoutProgress(Math.ceil(((j + 1) * 100) / group.length))
        );
      }

      dispatch(endLayout());
    }
  };

export const drawLayoutCeilings =
  (layoutData: any[]): ThunkAction<void, DrawStore, unknown, any> =>
  async (dispatch: any, getState: any) => {
    dispatch(startLayoutCeiling());

    let config = await MyConfig();

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Profile",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_Hanger",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_ChannelTrim",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_HorizontalBoard",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    await api.familyEditor.loadFamilies(
      [
        {
          FamilyName: "Rigips_HorizontalOssature",
          FamilyTypes: ["Connect"],
          Date: "2019_12_03_10_34_32",
          Version: "1.0.0.0",
        },
      ],
      config.REACT_APP_DOWNLOAD_FAMILY_URL_RIGIPS
    );

    dispatch(initLayoutProgressCeiling());
    let nb_ceilings = layoutData.length;
    for (let i = 0; i < nb_ceilings; i++) {
      let ceiling = layoutData[i];
      await api.familyEditor.setParams([
        {
          Id: ceiling.id,
          Params: [{ Name: "Processed", Type: "YesNo", Value: 1 }],
        },
      ]);
      await api.familyEditor.setParams([
        {
          Id: ceiling.id,
          Params: [
            {
              Name: "SG_System",
              Type: "Text",
              Value: ceiling.technicalName,
            },
          ],
        },
      ]);
      if (ceiling.primaryPorteur && ceiling.primaryPorteur.length) {
        await api.familyEditor.placeFamiliesAtPoints(ceiling.primaryPorteur);
      }

      if (ceiling.secondaryPorteur && ceiling.secondaryPorteur.length) {
        await api.familyEditor.placeFamiliesAtPoints(ceiling.secondaryPorteur);
      }

      if (ceiling.thirdPorteur && ceiling.thirdPorteur.length) {
        await api.familyEditor.placeFamiliesAtPoints(ceiling.thirdPorteur);
      }
      if (ceiling.contourPorteur && ceiling.contourPorteur.length) {
        await api.familyEditor.placeFamiliesAtPoints(ceiling.contourPorteur);
      }
      if (ceiling.boards && ceiling.boards.length) {
        await api.familyEditor.placeFamiliesAtPoints(ceiling.boardss);
      }
      dispatch(
        updateLayoutProgressCeiling(Math.ceil(((i + 1) * 100) / nb_ceilings))
      );
    }
    dispatch(endLayoutCeiling());

    await wait(3000);
    //   if (getState().layoutState === null || getState().layoutState === "Ended")
    //     api.windowsHandler.closeWindow();
  };

export const toggleAll = (checked: boolean = true) => {
  return {
    type: "TOGGLE_ALL",
    checked,
  };
};

const stringLitArray = <L extends string>(arr: L[]) => arr;
const language = stringLitArray(["French", "English", "German"]);
type Language = typeof language[number];

const isLanguage = (x: any): x is Language => language.includes(x);

export const initLanguage =
  (): ThunkAction<void, DrawStore, unknown, InitLanguageAction> =>
  async (dispatch) => {
    const setRevitLanguage = async () => {
      let revitLanguage = "English" as Language; //(await api.queries.getRevitLanguage()) as Language;
      localStorage.setItem("savedLanguage", revitLanguage);
      dispatch({
        type: INIT_LANGUAGE,
        language: revitLanguage,
      });
    };
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    if (savedLanguage) {
      if (isLanguage(savedLanguage)) {
        dispatch({
          type: INIT_LANGUAGE,
          language: savedLanguage,
        });
      } else {
        setRevitLanguage();
      }
    } else {
      setRevitLanguage();
    }
  };
