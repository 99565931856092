import { ThunkAction } from "redux-thunk";
import { FiltersActionTypes } from "./types";
import { loadFiltersRequest, applyFiltersRequest } from "./actions";
import { DrawStore } from "../reducers";
import { setFiltersAid } from "../../../../../RevitJS/Helpers";
import { addFilterFields, setFiltersOptions } from "../../utils/utils";
import { placoFilters } from "../../utils/placoFilters";
import { WALLSYSTEM_ID, FLOORSYSTEM_ID } from "../../assets/constants";
import { ResetSearchKeyWordAction } from "../systems/actions";

type Effect = ThunkAction<any, DrawStore, any, FiltersActionTypes>;

export const loadFilters =
  (): Effect => async (dispatch: any, getState: any) => {
    let { currentSystemDetail, systemTypeID } = getState().systems;
    let filters = getState().filters.filters;
    if (systemTypeID === WALLSYSTEM_ID) {
      var wallsFilter = placoFilters.Walls;
      if (!checkFilters(wallsFilter, filters)) {
        filters = placoFilters.Walls;
      }
    } else if (systemTypeID === FLOORSYSTEM_ID) {
      var floorFilter = placoFilters.Floor;
      if (!checkFilters(floorFilter, filters)) {
        filters = placoFilters.Floor;
      }
    }

    currentSystemDetail = addFilterFields(currentSystemDetail, filters);
    filters = setFiltersOptions(currentSystemDetail, filters);
    dispatch(loadFiltersRequest(filters));
  };

const checkFilters = (filters: any[], existingFilters: any[]): any => {
  var flag = true;
  filters.forEach((nf) => {
    const result = existingFilters.some((ef) => ef.name === nf.name);
    if (!result) {
      flag = false;
    }
  });
  return flag;
};

export const applyFilters =
  (filterName: string, filterValue: any | any[]): Effect =>
    async (dispatch: any, getState: any) => {
      let value = filterValue;
      if (typeof filterValue === "object") {
        value = filterValue.length > 0 ? filterValue : null;
      }

      let { filters } = getState().filters;
      filters = filters.map((filter: any) =>
        filter.pimAttribute === filterName ? { ...filter, value: value } : filter
      );
      dispatch(applyFiltersRequest(filters));
    };

export const resetFilters =
  (): Effect => async (dispatch: any, getState: any) => {
    let { data, systemTypeID } = getState().systems;
    let currentSystemDetail: any[] = [];
    let filters = getState().filters.filters;
    if (systemTypeID === WALLSYSTEM_ID) {
      filters = placoFilters.Walls;
      currentSystemDetail = data.wallsSystemDetails;
    } else if (systemTypeID === FLOORSYSTEM_ID) {
      filters = placoFilters.Walls;
      currentSystemDetail = data.floorSystemDetails;
    }

    currentSystemDetail = addFilterFields(currentSystemDetail, filters);
    filters = setFiltersOptions(currentSystemDetail, filters);
    dispatch(loadFiltersRequest(filters));
    dispatch(ResetSearchKeyWordAction());
  };

export const resetSearchFilter =
  (): Effect => async (dispatch: any, getState: any) => {
    dispatch(ResetSearchKeyWordAction());
  };
