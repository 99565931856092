import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "208450ca-f4f5-4082-8144-f31ce11f1410";

export const configurationTrigger = (config:any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    // `${config.REACT_APP_SERVERURL}/#/${pluginId}/${functionalityId}`
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const ModelGenConfigurationRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const Configuration: Functionality = {
  Name: {
    English: "Configuration",
  },
  Trigger: configurationTrigger,
  ShortDesc: {
    English: "Mapping Files",
  },
  Id: functionalityId,
  Icon: "/ModelGenerator/configuration.png",
  RootComponent,
  ParentId: "0",
};
