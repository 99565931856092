import React, { useState } from "react";
import { LayoutConfigType } from "../utils/types";

export interface CaplinageContextType {
  layoutConfigure: any;
  setLayoutConfigure: (layoutConfigure: any) => void;
}

export const CalpinageContext =
  React.createContext<CaplinageContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

export const CalpinageContextProvider: React.FC<Props> = ({ children }) => {
  const [layoutConfigure, setLayoutConfigure] = useState<LayoutConfigType>({
    WallTypeAssignment: true,
    MetalFraming: false,
    DryWall: false,
  });

  return (
    <CalpinageContext.Provider
      value={{
        layoutConfigure,
        setLayoutConfigure,
      }}
    >
      {children}
    </CalpinageContext.Provider>
  );
};

export default CalpinageContextProvider;
