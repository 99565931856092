import {
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY,
  PAM_ATTRIBUTES_TECHNICAL_NAME,
  PAM_LAYER_ATTRIBUTES,
  GENERIC_ATTRIBUTES_TO_REMOVE,
  SHARED_ATTRIBUTES,
} from "../assets/attributes.injection";
import {
  CLOISONS_ID,
  DOUBLAGES_ID,
  PLAFONDS_ID,
  GAINES_ID
} from "../../LoadSystemContainer/assets/constants";
import { PAM_CATEGORIES_KEYS, PAM_ATTRIBUTES_KEYS } from "../assets/types";
import _ from "lodash";
import { getPlacoSystemsDetailsWithRelations } from "../../LoadSystemContainer/services/project.service";

export const setPAMAttributesModal = (type, attributes, selectedCategories) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  const generalData = attributesByType.find(
    (a) => a.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
  );
  attributesModal.push({
    checked: true,
    categoryName: generalData.categoryName,
    pamData: generalData.pamData.map((attribute) => {
      return {
        aid: attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        )?.aid,
        technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
        key: attribute,
      };
    }),
  });
  const chaptersNames = attributesByType
    .map((x) => x.chapterName)
    .filter(
      (value, index) =>
        value &&
        attributesByType.map((x) => x.chapterName).indexOf(value) === index
    );
  attributesModal.push(
    ...chaptersNames.map((chapterName) =>
      setPAMAttributesModalByChapter(
        chapterName,
        attributesByType,
        attributes,
        selectedCategories
      )
    )
  );

  return attributesModal;
};

export const getDefaultConfigurationByType = (type, attributes) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  attributesByType.forEach((conf) => {
    attributesModal.push({
      checked: true,
      categoryName: conf.categoryName,
      pamData: conf.pamData.map((attribute) => {
        return {
          aid: attributes.find(
            (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
          )?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
        };
      }),
    });
  });

  return attributesModal;
};

const setPAMAttributesModalByChapter = (
  chapterName,
  attributesByType,
  attributes,
  selectedCategories
) => {
  const attributesByChapter = attributesByType
    .filter((attribute) => attribute.chapterName === chapterName)
    .map((a) => ({
      // checked: selectedCategories.some(
      //   (cat) => cat.categoryName === a.categoryName
      // ),
      checked: selectedCategories.find(cat => cat.categoryName === a.categoryName)?.checked != undefined ? selectedCategories.find(cat => cat.categoryName === a.categoryName)?.checked :true,
      categoryName: a.categoryName,
      pamData: a.pamData.map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
        };
      }),
    }));
  return {
    chapterName: chapterName,
    chapterData: attributesByChapter,
  };
};

export const getRelationofObjects = async (data, config) => {
  let { liningWallsDetails, partitionsDetails, ceilingsDetails, gainesTechniquesDetails } = data;
  let liningOid = liningWallsDetails
    .map((a) => a.oid)
    .concat(partitionsDetails.map((a) => a.oid))
    .concat(ceilingsDetails.map((a) => a.oid))
  if (gainesTechniquesDetails) {
    liningOid = liningOid.concat(gainesTechniquesDetails.map((a) => a.oid));
  }
  let obj = null;
  await getPlacoSystemsDetailsWithRelations(liningOid, config).then(
    (response) => {
      obj = response.data;
      liningWallsDetails = GetMaterialDetails(liningWallsDetails, obj);
      partitionsDetails = GetMaterialDetails(partitionsDetails, obj);
      ceilingsDetails = GetMaterialDetails(ceilingsDetails, obj);
      gainesTechniquesDetails = gainesTechniquesDetails ? GetMaterialDetails(gainesTechniquesDetails, obj) : [];
    }
  );
};

export const GetMaterialDetails = (sectionDetails, obj) => {
  for (let section of sectionDetails) {
    let layerArr = section.attributes.filter(
      (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    );
    let linningRelArr = obj.context.relations.filter(
      (a) => a.oid1 === section.oid
    );
    let sectionChild = [];
    // let layeridArr = [];
    for (let layr of layerArr) {
      let order = layr.subAttributes.find(
        (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NUMBER
      ).values[0].numericValue;
      let layerId = GetLayerId(order, linningRelArr);
      //layeridArr.push({ Id: , order: order });

      let layerName = layr.subAttributes.find(
        (a) => a.technicalName === "GFR-Layer name"
      ).values[0].value;

      //for (let child of obj.objects.filter((a) => a.oid === layerId)) {
      for (let child of obj.objects.filter(
        (a) => a.translation === layerName
      )) {
        child.order = order;
        let url = child.attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.SYSTEM_URL
        )?.values[0].value;
        let dt = layr.subAttributes[0];
        dt.technicalName = PAM_ATTRIBUTES_TECHNICAL_NAME.SYSTEM_URL;
        dt.values[0].value = url === null || undefined ? null : url;
        dt.aid = 0;
        layr.subAttributes.push(dt);
        sectionChild.push(child);
      }
    }

    // for (let layId of layeridArr) {

    // }
    section.child = sectionChild;
  }
};

export const GetLayerId = (order, relationList) => {
  let layerId = null;
  for (let rel of relationList) {
    if (
      rel.attributes.find(
        (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_DATA
      )?.values !== null
    ) {
      let value = rel.attributes.find(
        (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_DATA
      )?.values;
      if (value?.filter((a) => a.numericValue === order).length === 1) {
        layerId = rel.oid2;
        break;
      }
    }
  }
  return layerId;
};

export const fillSystemsModel = (
  properties,
  data,
  isDefaultConfiguration,
  t
) => {
  const systemsModel = [];
  const { liningWallsDetails, partitionsDetails, ceilingsDetails, gainesTechniquesDetails } = data;
  let { liningWallsProperties, partitionsProperties, ceilingsProperties, gainesTechniquesProperties } =
    properties;
  let liningWallsProperties1 = [];
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.LINING_WALLS.forEach((cat) =>
    liningWallsProperties1.push({
      ...cat,
      toDelete:
        !liningWallsProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
        !partitionsProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
        !gainesTechniquesProperties.find(c => c.categoryName === cat.categoryName)?.checked,
      // !liningWallsProperties
      //   .map((c) => c.categoryName)
      //   .includes(cat.categoryName),
      toEmpty:
        (
        partitionsProperties.find(c => c.categoryName === cat.categoryName)?.checked ||
        gainesTechniquesProperties.find(c => c.categoryName === cat.categoryName)?.checked
        ) &&
        !liningWallsProperties.find(c => c.categoryName === cat.categoryName)?.checked
      // partitionsProperties
      //   .map((c) => c.categoryName)
      //   .includes(cat.categoryName) &&
      // !liningWallsProperties
      //   .map((c) => c.categoryName)
      //   .includes(cat.categoryName),
    })
  );
  let partitionsProperties1 = [];
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.WALL.forEach((cat) =>
    partitionsProperties1.push({
      ...cat,
      toDelete:
        !partitionsProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
        !liningWallsProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
        !gainesTechniquesProperties.find(c => c.categoryName === cat.categoryName)?.checked,
      // !partitionsProperties
      //   .map((c) => c.categoryName)
      //   .includes(cat.categoryName),
      toEmpty:
        !partitionsProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
        (
          gainesTechniquesProperties.find(c => c.categoryName === cat.categoryName)?.checked ||
          liningWallsProperties.find(c => c.categoryName === cat.categoryName)?.checked
        )
      // !partitionsProperties
      //   .map((c) => c.categoryName)
      //   .includes(cat.categoryName) &&
      // liningWallsProperties
      //   .map((c) => c.categoryName)
      //   .includes(cat.categoryName),
    })
  );
  let ceilingsProperties1 = [];
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.CEILING.forEach((cat) =>
    ceilingsProperties1.push({
      ...cat,
      toDelete:
        !ceilingsProperties.find(c => c.categoryName === cat.categoryName)?.checked,
      // !ceilingsProperties
      // .map((c) => c.categoryName)
      // .includes(cat.categoryName),
      toEmpty: false,
    })
  );
  if (gainesTechniquesProperties) {
    let gainesTechniquesProperties1 = [];
    PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.GAINES_TECHNIQUES.forEach((cat) =>
    gainesTechniquesProperties1.push({
        ...cat,
        toDelete:
          !gainesTechniquesProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
          !partitionsProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
          !liningWallsProperties.find(c => c.categoryName === cat.categoryName)?.checked,
        toEmpty: 
          !gainesTechniquesProperties.find(c => c.categoryName === cat.categoryName)?.checked &&
           (
            liningWallsProperties.find(c => c.categoryName === cat.categoryName)?.checked ||
           partitionsProperties.find(c => c.categoryName === cat.categoryName)?.checked
          ),
      })
    );
    systemsModel.push(
      ...fillSystemsModelCategorie(
        gainesTechniquesProperties1,
        gainesTechniquesDetails,
        PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.GAINES_TECHNIQUES,
        "wall",
        isDefaultConfiguration,
        t
      )
    );
  }
  systemsModel.push(
    ...fillSystemsModelCategorie(
      liningWallsProperties1,
      liningWallsDetails,
      PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.LINING_WALLS,
      "wall",
      isDefaultConfiguration,
      t
    )
  );
  systemsModel.push(
    ...fillSystemsModelCategorie(
      partitionsProperties1,
      partitionsDetails,
      PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.WALL,
      "wall",
      isDefaultConfiguration,
      t
    )
  );
  systemsModel.push(
    ...fillSystemsModelCategorie(
      ceilingsProperties1,
      ceilingsDetails,
      PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY.CEILING,
      "ceiling",
      isDefaultConfiguration,
      t
    )
  );
  return systemsModel;
};

const fillSystemsModelCategorie = (
  properties,
  systems,
  propertiesModel,
  systemType,
  isDefaultConfiguration,
  t
) => {
  if (isDefaultConfiguration) {
    properties = properties.map((property) =>
      isDefaultConfiguration &&
        property.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
        ? {
          categoryName: PAM_CATEGORIES_KEYS.GENERAL_DATA,
          checked: true,
          pamData: property.pamData.filter(
            (p) => !GENERIC_ATTRIBUTES_TO_REMOVE.some((att) => att === p.key)
          ),
        }
        : property
    );
  }
  return systems.map((system) => {
    let productName = system?.attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;
    return {
      solution: isDefaultConfiguration
        ? productName
          ? productName + " ou techniquement équivalent"
          : null
        : productName,
      productId: system.externalName,
      elementType: systemType,
      paramData: [
        ...fillSystemProperties(
          system,
          properties,
          propertiesModel,
          isDefaultConfiguration,
          t
        ),
      ],
      materialAttributes: fillMaterialAttributes(system, t),
    };
  });
};

const fillSystemProperties = (
  system,
  properties,
  propertiesModel,
  isDefaultConfiguration,
  t
) => {
  let finalPropertiesModel = [];
  propertiesModel.forEach((element) => {
    element.pamData.forEach((property) => {
      const att = system.attributes.find(
        (attribute) =>
          PAM_ATTRIBUTES_TECHNICAL_NAME[property] === attribute.technicalName
      );

      if (property === PAM_ATTRIBUTES_KEYS.GENERATION_DATE) {
        var today = new Date();

        finalPropertiesModel.push({
          category: t(element.categoryName),
          key: t(property),
          value: `${today.getFullYear()}-${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${today
              .getDate()
              .toString()
              .padStart(2, "0")} ${today
                .toTimeString()
                .toString()
                .replace("GMT", "UTC")}`,
          toDelete: false,
          toEmpty: false,
        });
      } else {
        finalPropertiesModel.push({
          category: t(element.categoryName),
          key: t(property),
          value:
            isDefaultConfiguration &&
              (property === PAM_ATTRIBUTES_KEYS.TYPICAL_DESCRIPTION ||
                property === PAM_ATTRIBUTES_KEYS.SYSTEM_NAME)
              ? att?.values[0]?.value
                ? att?.values[0]?.value?.split('"').join("") +
                " ou techniquement équivalent"
                : null
              : property === PAM_ATTRIBUTES_KEYS.TECHNICAL_OPINION_OR_DTU_NUMBER
                ? att?.values[0]?.value.replace('"', " ").replace('®"', " ")
                : property === PAM_ATTRIBUTES_KEYS.ACTIV_AIR
                  ? att?.values[0]?.value === "True"
                    ? "Vrai"
                    : "Faux"
                  : property === PAM_ATTRIBUTES_KEYS.NAME_OF_INSULATION
                    ? system.attributes.find(
                      (attribute) =>
                        attribute.technicalName.includes(
                          PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
                        ) &&
                        (attribute.values[0]?.value.includes("PAR") ||
                          attribute.values[0]?.value.includes("Isoconfort") ||
                          attribute.values[0]?.value.includes("GR 32") ||
                          attribute.values[0]?.value.includes("IBR") ||
                          attribute.values[0]?.value.startsWith("Laine"))
                    )?.values[0]?.value
                    : att?.values[0]?.numericValue ||
                    att?.values[0]?.value?.split('"').join("") ||
                    null,
          toDelete:
            (isDefaultConfiguration
              ? GENERIC_ATTRIBUTES_TO_REMOVE.some((prop) => prop === property)
              : false) ||
            properties.find((cat) => cat.categoryName === element.categoryName)
              .toDelete,
          toEmpty:
            properties.find((cat) => cat.categoryName === element.categoryName)
              .toEmpty &&
            (SHARED_ATTRIBUTES.some((prop) => prop === property)
              ? finalPropertiesModel
                .filter((prop) => prop.Key === t(property))
                .every((prop) => prop.toEmpty)
              : true),
        });
      }
    });
  });
  let finalPropertiesM = [];
  finalPropertiesModel.forEach((e) => {
    console.log(
      e.key,
      finalPropertiesModel.filter((prop) => prop.key === e.key && prop.categoryName === e.category),
      finalPropertiesModel
        .filter((prop) => prop.key === e.key)
        .every((prop) => prop.toDelete),
      SHARED_ATTRIBUTES.some((property) => t(property) === e.key && property.categoryName === e.category)
    );
    finalPropertiesM.push({
      ...e,
      toDelete:
        e.toDelete &&
        (SHARED_ATTRIBUTES.some((property) => t(property) === e.key)
          ? finalPropertiesModel
            .filter((prop) => prop.key === e.key && prop.categoryName === e.category)
            .every((prop) => prop.toDelete)
          : true),
      toEmpty:
        e.toEmpty &&
        (SHARED_ATTRIBUTES.some((property) => t(property) === e.key)
          ? finalPropertiesModel
            .filter((prop) => prop.key === e.key && prop.categoryName === e.category)
            .every((prop) => prop.toEmpty)
          : true),
    });
  });
  return finalPropertiesM;
};

const fillMaterialAttributes = (system, t) => {
  console.log("system", system);
  let propertiesModel = [];
  let attributes = system.attributes
    .filter(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    )
    .filter(
      (layer) =>
        !layer.subAttributes
          .find(
            (attribute) =>
              attribute.technicalName ===
              PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
          )
          ?.values[0]?.value?.includes("Mur support")
    );
  attributes.forEach((element) => {
    propertiesModel.push(
      PAM_LAYER_ATTRIBUTES.map((param) => {
        var subAtt = element?.subAttributes?.find(
          (att) => att.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[param]
        );

        return {
          label: t(param),
          value: subAtt?.values[0]?.value || null,
        };
      })
    );
  });
  return propertiesModel;
};

export const setPAMAttributesValuesModal = (type, attributes) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  const generalData = attributesByType.find(
    (a) => a.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
  );

  attributesModal.push({
    categoryName: generalData.categoryName,
    pamData: generalData.pamData
      .filter((attribute) => attribute !== PAM_ATTRIBUTES_KEYS.GENERATION_DATE)
      .map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
          value: att?.values[0]?.value?.split('"').join("") || null,
        };
      }),
  });
  const chaptersNames = attributesByType
    .map((x) => x.chapterName)
    .filter(
      (value, index) =>
        value &&
        attributesByType.map((x) => x.chapterName).indexOf(value) === index
    );
  attributesModal.push(
    ...chaptersNames.map((chapterName) =>
      setPAMAttributesValuesModalByChapter(
        chapterName,
        attributesByType,
        attributes
      )
    )
  );
  attributes = attributes.filter(
    (att) =>
      !Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME).some(
        (tech) => att.technicalName === tech
      )
  );

  return attributesModal;
};

const setPAMAttributesValuesModalByChapter = (
  chapterName,
  attributesByType,
  attributes
) => {
  const attributesByChapter = attributesByType
    .filter((attribute) => attribute.chapterName === chapterName)
    .map((a) => ({
      categoryName: a.categoryName,
      pamData: a.pamData.map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
          value:
            attribute === PAM_ATTRIBUTES_KEYS.TECHNICAL_OPINION_OR_DTU_NUMBER
              ? att?.values[0]?.value.replace('"', " ").replace('®"', " ")
              : attribute === PAM_ATTRIBUTES_KEYS.ACTIV_AIR
                ? att?.values[0]?.value === "True"
                  ? "Vrai"
                  : "Faux"
                : attribute === PAM_ATTRIBUTES_KEYS.NAME_OF_INSULATION
                  ? attributes.find(
                    (attribute) =>
                      attribute.technicalName.includes(
                        PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
                      ) &&
                      (attribute.values[0]?.value.includes("PAR") ||
                        attribute.values[0]?.value.includes("Isoconfort") ||
                        attribute.values[0]?.value.includes("GR 32") ||
                        attribute.values[0]?.value.includes("IBR") ||
                        attribute.values[0]?.value.startsWith("Laine"))
                  )?.values[0]?.value
                  : att?.values[0]?.numericValue ||
                  att?.values[0]?.value?.split('"').join("") ||
                  null,
        };
      }),
    }));
  return {
    chapterName: chapterName,
    chapterData: attributesByChapter,
  };
};

export const fillConfigurationModal = (system, t) => {
  const SystemConfiguration = {
    systemType: system.attributes.find(
      (attribute) =>
        attribute.technicalName ===
        PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION
    )?.values[0]?.value,
    parement: fillParmentConfiguration(system, t),
    profile: system.attributes.find(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D
    )?.values[0]?.value,
    frameDistance: system.attributes.find(
      (attribute) =>
        attribute.technicalName ===
        PAM_ATTRIBUTES_TECHNICAL_NAME.CENTER_DISTANCE_BETWEEN_FRAMES_M
    )?.values[0]?.value,
    rail: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) && attribute.values[0]?.value.includes("Rail")
      )?.values[0]?.value,
      value: "",
    },
    montant: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) && attribute.values[0]?.value.includes("Montant")
      )?.values[0]?.value,
      value: "",
    },
    isolation: fillIsolationConfiguration(system, t),
    // isolation: {
    //   name: system.attributes.find(
    //     (attribute) =>
    //       attribute.technicalName.includes(
    //         PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
    //       ) &&
    //       (attribute.values[0]?.value.includes('PAR') ||
    //         attribute.values[0]?.value.includes('Isoconfort') ||
    //         attribute.values[0]?.value.includes('GR 32') ||
    //         attribute.values[0]?.value.includes('IBR') ||
    //         attribute.values[0]?.value.startsWith('Laine'))
    //   )?.values[0]?.value,
    //   value: "",
    // },
  };
  return SystemConfiguration;
};

const fillIsolationConfiguration = (system, t) => {
  const attributes = system.attributes.filter((attribute) =>
    attribute.technicalName.includes(PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT)
  );

  const model = attributes
    .filter(
      (attribute) =>
        attribute.values[0]?.value.includes("PAR") ||
        attribute.values[0]?.value.includes("Isoconfort") ||
        attribute.values[0]?.value.includes("GR 32") ||
        attribute.values[0]?.value.includes("IBR") ||
        attribute.values[0]?.value.startsWith("Laine")
    )
    .map((attribute) => attribute?.values[0]?.value);

  return model;
};

const fillParmentConfiguration = (system, t) => {
  const configurationModel = [];
  const bimLayers = system.attributes
    .filter(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    )
    .filter(
      (layer) =>
        !layer.subAttributes
          .find(
            (attribute) =>
              attribute.technicalName ===
              PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
          )
          ?.values[0]?.value?.includes("Mur support")
    );
  let ParmentNumber = 1;
  for (
    let currentLayerNumber = 1;
    currentLayerNumber <= bimLayers.length + 1;
    currentLayerNumber++
  ) {
    const currentLayer = bimLayers.find(
      (lay) =>
        lay.subAttributes.find(
          (attribute) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NUMBER
        )?.values[0]?.numericValue === currentLayerNumber
    );
    if (
      currentLayer?.subAttributes.find(
        (attribute) =>
          attribute.technicalName ===
          PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
      )?.values[0]?.value === "Plaque de plâtre"
    ) {
      configurationModel.push({
        parmentNumber: ParmentNumber,
        layerThickness: currentLayer.subAttributes.find(
          (attribute) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_THIKNESS_MM
        )?.values[0]?.value,
        layerName: currentLayer.subAttributes.find(
          (attribute) =>
            attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NAME
        )?.values[0]?.value,
      });
    }
    if (
      currentLayer?.subAttributes.find(
        (attribute) =>
          attribute.technicalName ===
          PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
      )?.values[0]?.value !== "Plaque de plâtre" &&
      configurationModel.filter((p) => p.parmentNumber === 1).length > 0
    ) {
      ParmentNumber = 2;
    }
  }
  return configurationModel;
};

export const getSystemTypeByWorksNameObject = (system) => {
  const gfrWorksNameObject = _.find(system.attributes, {
    technicalName: "GFR-Works name",
  });
  if (gfrWorksNameObject) {
    const valueObject = _.get(gfrWorksNameObject, "values");
    if (valueObject[0].value === "Cloisons") return CLOISONS_ID;
    if (
      valueObject[0].value === "Plafonds décoratifs" ||
      valueObject[0].value === "Plafonds"
    )
      return PLAFONDS_ID;
    if (valueObject[0].value === "Isolation des murs") return DOUBLAGES_ID;
    if (valueObject[0].value === "Gaines techniques") return GAINES_ID;
  }
  return CLOISONS_ID;
};

function contains(target, pattern) {
  var value = 0;
  pattern.forEach(function (word) {
    value = value + target.includes(word);
  });
  return value === 1;
}
