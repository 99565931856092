import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware, compose } from "redux";
import { reducers } from "../Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";
import { api } from "../../../../RevitJS/API";

interface Props {}

export enum Routes {
  ROOT = "Draw",
  MAPPING = "Mapping",
  GROUP_SELECTION = "GroupSelection",
  PRODUCT = "Product",
  SOLUTION_MAPPING = "SolutionMapping",
  CONFIGURATION = "Configuration",
}

interface State {
  route: Routes;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
export class MetresRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    // this.store = createStore(reducers, applyMiddleware(thunk));

    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    this.store = createStore(
      reducers,
      composeEnhancers(applyMiddleware(thunk))
    );
  }

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    //api.windowsHandler.showDevTools();
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody route={this.state.route} setRoute={this.setRoute} />
      </Provider>
    );
  };
}
