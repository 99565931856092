import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { WallsSchedulesRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "17f8b723-6587-4d79-85f8-506b45a8df9e";

export const wallsSchedulesTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Métrés",
    module: "RIGIPSBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <WallsSchedulesRoot />
    </ParentRoot>
  );
};

export const RiggipsWallScheduleRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <WallsSchedulesRoot />
    </ParentRoot>
  );
};

export const WallsSchedules: Functionality = {
  Name: { French: "Métrés", English: "Walls Schedules", German: "Menge" },
  Trigger: wallsSchedulesTrigger,
  ShortDesc: {
    French: "Obtenir les métrés",
    English: "Walls schedules",
    German: "Wandinventar",
  },
  Id: functionalityId,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Metres.png",
  RootComponent,
  ParentId: "0",
};
