import React, { Component } from "react";
import { Container, Button, Loader, Dimmer } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import { setSelectionTree, initGroupSelector } from "../Actions";
import { Routes } from "./root";
import { selectedItemsManualSelection } from "../../../../RevitJS/Helpers";

const eraseSelection = async (tree: any) => {
  try {
    const idsToDelete = [].concat.apply(
      [],
      tree.map((e: any) => e.Ids)
    );
    const nb_walls = idsToDelete.length;
    for (let i = 0; i < nb_walls; i++) {
      await api.familyEditor.createAndSetParameters(
        "Ceiling",
        "Instance",
        "DATA",
        [
          {
            Id: idsToDelete[i],
            Params: [
              { Name: "Processed", Type: "YesNo", Value: 0 },
              {
                Name: "ProductName",
                Type: "Text",
                Value: " ",
              },
              {
                Name: "MaxLength",
                Type: "Text",
                Value: " ",
              },
              {
                Name: "MaxWidth",
                Type: "Text",
                Value: " ",
              },
              {
                Name: "RoomNames",
                Type: "Text",
                Value: " ",
              },
            ],
          },
        ]
      );

      const elems = await api.queries.filterElements(
        "Generic",
        [
          {
            Param: {
              Name: "id",
              Type: "Integer",
              Value: parseInt(idsToDelete[i]),
            },
            Rule: "Equals",
          },
        ],
        null
      );
      const albaElems = await api.queries.filterElements(
        "Wall",
        [
          {
            Param: {
              Name: "AlbaId",
              Type: "Text",
              Value: idsToDelete[i],
            },
            Rule: "Equals",
          },
        ],
        null
      );
      await api.familyEditor.deleteElementsByIds(elems.concat(albaElems));
    }

    await sendEvent();
  } catch (e) {
    console.log("Error in CLT Delete!");
  }
};

const sendEvent = async () => {
  await api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Generate",
    eventCategory: "Module Execution",
    eventLabel: "Delete",
    module: "CLT",
  });
};

interface Props {
  Icon: string;
  name: string;
  wording: {
    selection: { [key: string]: string };
    manualSelection: { [key: string]: string };
    groupSelection: { [key: string]: string };
    deletePending: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  setSelectionTree: any;
  initGroupSelector: any;
  config: any;
}

interface State {
  erasing: boolean;
}

export class SelectionLanding extends Component<Props, State> {
  state = {
    erasing: false,
  };

  public static defaultProps = {
    wording: {
      selection: {
        French: "Sélection",
        English: "Selection",
        German: "Auswahl",
        Italian: "Selezione",
      },
      manualSelection: {
        French: "Sélection Manuelle",
        English: "Manual Selection",
        German: "Manuelle Auswahl",
        Italian: "Selectionz manuale",
      },
      groupSelection: {
        French: "Sélection groupée",
        English: "Group selection",
        German: "Gruppenauswahl",
        Italian: "Selezione del gruppo",
      },
      deletePending: {
        French: "Suppression en cours",
        English: "Deleting",
        German: "Löschen",
        Italian: "La cancellazione in corso",
      },
    },
  };

  componentDidMount = () => {
    // api.windowsHandler.showDevTools();
    api.windowsHandler.resizeWindow(600, 200);
  };

  manualSelectionHandler = async () => {
    api.windowsHandler.hideWindow();
    let tree = await api.selection.manualSelection("ceiling");
    await api.windowsHandler.showWindow();

    if (tree.Tree.length !== 0) {
      this.setState({
        erasing: true,
      });
      let selectedItems = selectedItemsManualSelection(tree);
      await eraseSelection(selectedItems);
      api.windowsHandler.closeWindow();
    }
  };

  groupSelectionHandler = async () => {
    let tree = await api.selection.elementsByLevelAndType("ceiling");
    this.props.initGroupSelector(tree);
    this.props.setRoute(Routes.GROUP_SELECTION);
  };

  render() {
    const { wording, language } = this.props;
    const manualSelection =
      wording.manualSelection[language] || wording.manualSelection["English"];
    const groupSelection =
      wording.groupSelection[language] || wording.groupSelection["English"];
    const selection =
      wording.selection[language] || wording.selection["English"];
    const deletePending =
      wording.deletePending[language] || wording.deletePending["English"];

    if (this.state.erasing) {
      return (
        <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
          <Loader content={deletePending} />
        </Dimmer>
      );
    }
    return (
      <div id="delete-selectionLanding-container">
        <FunctionalityHeader
          name={selection}
          color="grey"
          Icon={
            this.props.config.REACT_APP_SERVERURL +
            "/plugin_new_logo/ecophon-new-logo_icon.png"
          }
          height={40}
          width={40}
        />
        <Container textAlign="center" style={{ margin: "auto" }}>
          <Button
            onClick={this.manualSelectionHandler}
            color="yellow"
            size="big"
            style={{ marginRight: "5%" }}
          >
            {manualSelection}
          </Button>
          <Button
            onClick={this.groupSelectionHandler}
            color="yellow"
            size="big"
            style={{ marginLeft: "5%" }}
          >
            {groupSelection}
          </Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  setSelectionTree,
  initGroupSelector,
})(SelectionLanding);
