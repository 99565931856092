import _ from "lodash";
import {
  fetchAttributes,
  fetchBrand,
  fetchPlacoSolutions,
  fetchRegion,
} from ".";
import { bimStorage, storageKey } from "../BIMStore";
import { fetchSolutions } from "./request";
import { MyConfig } from "../Helper";

/**
 * Fetches the placo data from bimdatabase.
 * @returns The placo data with objects and attributes.
 */
export async function queryPlacoData(cng: any) {
  try {
    const brandId = await fetchBrand(cng).catch((error) => {
      console.error("Brand Error: ", error);
      throw error;
    });
    const regionId = await fetchRegion(cng).catch((error) => {
      console.error("Region Error: ", error);
      throw error;
    });
    const returnAttributes = await fetchAttributes(
      brandId,
      [regionId],
      cng
    ).catch((error) => {
      console.error("Attributes Error: ", error);
      throw error;
    });
    if (returnAttributes.length > 0) {
      const placoData = await fetchPlacoSolutions(
        brandId,
        [regionId],
        returnAttributes!,
        cng
      ).catch((error) => {
        console.error("Placo Solutions Error: ", error);
        throw error;
      });

      if (placoData) {
        bimStorage.setItem(
          storageKey.PLACO_PRODUCTS,
          JSON.stringify(placoData)
        );
      }

      return placoData;
    }

    return null;
  } catch (error) {
    console.error("queryPlacoData method: ", error);
    return Promise.reject(error);
  }
}

export async function queryGyprocData() {
  try {
    const cng = await MyConfig();
    const brandName = "Gyproc";
    const timeStamp = await bimStorage.getTimeStamp(brandName);
    const brandId = await fetchBrand(cng, brandName).catch((error) => {
      throw error;
    });
    const regionId = await fetchRegion(cng, "Netherlands").catch((error) => {
      throw error;
    });

    let solutions = await fetchSolutions(brandId, [regionId], cng).catch(
      (error: any) => {
        throw error;
      }
    );

    if (solutions) {
      solutions = _.map(solutions, (sl: any) => {
        sl.BrandName = brandName;
        return sl;
      });
      await bimStorage.saveSystemSelection(solutions, brandName);
    }
    return solutions;
  } catch (error) {
    return null;
  }
}
/**
 * To fetch the placo data from local bimStore
 * @returns The placo data from local bimStore
 */
export async function queryPlacoDataFromStore() {
  const placoData = await bimStorage.getItem(storageKey.PLACO_PRODUCTS);
  return placoData;
}
