import { Language, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import {
  ProductDetailData,
  PIMLayoutAttributesAid,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import {
  FETCH_PLACO_DATA,
  DataAction,
  FilterAction,
  SET_SELECTION_TREE,
  SelectionTreeAction,
  SelectorAction,
  INIT_GROUP_SELECTOR,
  SELECT_LEVEL,
  SELECT_TYPE,
  MappingTableWallsAction,
  INIT_MAPPING_TABLE_WALLS,
  SELECT_SYSTEM,
  ProductSelectorAction,
  INIT_PRODUCT_SELECTOR,
  SET_SELECTION_BUFFER,
  SET_ACTIVE_MAPPING_ROW_INDEX,
  RESET_SELECTION_BUFFER,
  SEARCH,
  APPLY_FILTERS,
  RESET_FILTERS,
  LayoutAction,
  INIT_LAYOUT_PROGRESS,
  UPDATE_LAYOUT_PROGRESS,
  START_LAYOUT,
  END_LAYOUT,
  FETCH_RIGIPS_DATA,
  FetchRigipsDataAction,
  TOGGLE_ALL,
  TOGGLE_CHECK,
  SET_FIRE_CONSTRAIN,
  SET_SWITCH_PAREMENT,
  SET_REVERSE,
  SET_PUBLIC_CONSTRAIN,
  INIT_MAPPING_TABLE_CEILINGS,
  MappingTableCeilingsAction,
  SELECT_SYSTEM_CEILINGS,
  TOGGLE_CHECK_CEILINGS,
  TOGGLE_ALL_CEILINGS,
  SET_FIRE_CONSTRAIN_CEILINGS,
  FETCH_RIGIPS_DATA_CEILINGS,
  FetchRigipsDataCeilingsAction,
  selectorTp,
  INIT_GROUP_SELECTOR_CEILING,
  SelectorCeilingAction,
  SELECT_LEVEL_CEILING,
  SELECT_TYPE_CEILING,
  SelectionTreeCeilingAction,
  SET_SELECTION_TREE_CEILING,
  SET_ADDITIONAL_WEIGHT_CEILINGS,
  START_LAYOUT_CEILING,
  INIT_LAYOUT_PROGRESS_CEILING,
  END_LAYOUT_CEILING,
  LayoutCeilingAction,
  UPDATE_LAYOUT_PROGRESS_CEILING,
} from "../Actions/types";
import { placoFilters } from "../Ressources/placoFilters";
import {
  TypeData,
  LevelData,
  MappingRow,
  MappingRowRigips,
  MappingRowRigipsCeilings,
} from "../../../../RevitJS/Types/StoreTypes";
import { levelData, elementTypes } from "../../../../RevitJS/Helpers";
import { FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";
import { LayoutState } from "../../../../Layout/types";
import { LanguageAction, INIT_LANGUAGE } from "../../../../Actions/types";
import { config } from "../../../CLT/Delete/Reducers";

export const language = (
  language: Language = "German",
  action: LanguageAction
): Language => {
  switch (action.type) {
    case INIT_LANGUAGE:
      return action.language;
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/pluginIcons/riggibs-logo-icon.png",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const placoData = (
  placoData = {
    partitionWalls: { systemsDetails: [], filters: [] },
    liningWalls: { systemsDetails: [], filters: [] },
  },
  action: DataAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return {
        partitionWalls: action.partitionsDetails,
        liningWalls: action.liningWallsDetails,
      };
    default:
      return placoData;
  }
};

export const rigipsMetaData = (
  rigipsMetaData: ProductMeta[] = [],
  action: FetchRigipsDataAction
) => {
  switch (action.type) {
    case FETCH_RIGIPS_DATA:
      return action.products;
    default:
      return rigipsMetaData;
  }
};

export const rigipsMetaDataCeilings = (
  rigipsMetaDataCeilings: ProductMeta[] = [],
  action: FetchRigipsDataCeilingsAction
) => {
  switch (action.type) {
    case FETCH_RIGIPS_DATA_CEILINGS:
      return action.products;
    default:
      return rigipsMetaDataCeilings;
  }
};

export const selectionTree = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeAction | SelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE:
      return action.tree;
    case INIT_GROUP_SELECTOR:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const selectionTreeCeiling = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeCeilingAction | SelectorCeilingAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE_CEILING:
      return action.tree;
    case INIT_GROUP_SELECTOR_CEILING:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const filters = (
  filters: { liningWalls: any[]; partitions: any[] } = placoFilters,
  action: FilterAction | ProductSelectorAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return {
        liningWalls: action.liningWallsDetails.filters,
        partitions: action.partitionsDetails.filters,
      };
    case APPLY_FILTERS:
      return action.filters;
    case RESET_FILTERS:
      return action.filters;
    default:
      return filters;
  }
};

export const levelsData = (
  levelsData: LevelData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const levelsDataCeilings = (
  levelsData: LevelData[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR_CEILING:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL_CEILING:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const typesData = (
  typesData: TypeData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL:
      return action.typesData;
    case SELECT_TYPE:
      return action.typesData;
    default:
      return typesData;
  }
};

export const typesDataCeilings = (
  typesData: TypeData[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR_CEILING:
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL_CEILING:
      return action.typesData;
    case SELECT_TYPE_CEILING:
      return action.typesData;
    default:
      return typesData;
  }
};

export const selectorType = (selectorType: string = "wall", action: any) => {
  switch (action.type) {
    default:
      return selectorType;
  }
};

export const selectedTypes = (
  selectedTypes: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedTypes;
    case SELECT_TYPE:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedTypesCeilings = (
  selectedTypes: string[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case SELECT_LEVEL_CEILING:
      return action.selectedTypes;
    case SELECT_TYPE_CEILING:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedLevels = (
  selectedLevels: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const selectedLevelsCeilings = (
  selectedLevels: string[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case SELECT_LEVEL_CEILING:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const mappingRowsWalls = (
  mappingRowsWalls: MappingRowRigips[] = [],
  action: MappingTableWallsAction
) => {
  switch (action.type) {
    case INIT_MAPPING_TABLE_WALLS:
      return action.mappingRowsWalls;
    case SELECT_SYSTEM:
      return mappingRowsWalls.map((sys) =>
        sys.Index === action.index
          ? { ...sys, MappedSystem: action.system }
          : sys
      );
    case TOGGLE_CHECK: {
      let tmpMappingRows = mappingRowsWalls.map((row) => {
        if (row.Index === action.Index) {
          row.Checked = !row.Checked;
        }
        return row;
      });

      return tmpMappingRows;
    }
    case TOGGLE_ALL: {
      let tmpMappingRows = mappingRowsWalls.map((row) => {
        row.Checked = action.checked;
        return row;
      });
      return tmpMappingRows;
    }
    case SET_FIRE_CONSTRAIN: {
      let tmpMappingRows = mappingRowsWalls.map((row) => {
        if (row.Index === action.Index)
          row.FireConstrain = action.FireConstrain;
        return row;
      });
      return tmpMappingRows;
    }
    case SET_SWITCH_PAREMENT: {
      let tmpMappingRows = mappingRowsWalls.map((row) => {
        if (row.Index === action.Index)
          row.SwitchParement = action.SwitchParement;
        return row;
      });
      return tmpMappingRows;
    }
    case SET_REVERSE: {
      let tmpMappingRows = mappingRowsWalls.map((row) => {
        if (row.Index === action.Index) row.Reverse = action.Reverse;
        return row;
      });
      return tmpMappingRows;
    }
    case SET_PUBLIC_CONSTRAIN: {
      let tmpMappingRows = mappingRowsWalls.map((row) => {
        if (row.Index === action.Index)
          row.PublicBuildingConstrain = action.PublicConstrain;
        return row;
      });
      return tmpMappingRows;
    }
    default:
      return mappingRowsWalls;
  }
};

export const mappingRowsCeilings = (
  mappingRowsCeilings: MappingRowRigipsCeilings[] = [],
  action: MappingTableCeilingsAction
) => {
  switch (action.type) {
    case INIT_MAPPING_TABLE_CEILINGS:
      return action.mappingRowsCeilings;
    case SELECT_SYSTEM_CEILINGS:
      return mappingRowsCeilings.map((sys) =>
        sys.Index === action.index
          ? { ...sys, MappedSystem: action.system }
          : sys
      );
    case TOGGLE_CHECK_CEILINGS: {
      let tmpMappingRows = mappingRowsCeilings.map((row) => {
        if (row.Index === action.Index) {
          row.Checked = !row.Checked;
        }
        return row;
      });
      return tmpMappingRows;
    }
    case TOGGLE_ALL_CEILINGS: {
      let tmpMappingRows = mappingRowsCeilings.map((row) => {
        row.Checked = action.checked;
        return row;
      });
      return tmpMappingRows;
    }
    case SET_FIRE_CONSTRAIN_CEILINGS: {
      let tmpMappingRows = mappingRowsCeilings.map((row) => {
        if (row.Index === action.Index)
          row.FireConstrain = action.FireConstrain;
        return row;
      });
      return tmpMappingRows;
    }
    case SET_ADDITIONAL_WEIGHT_CEILINGS: {
      let tmpMappingRows = mappingRowsCeilings.map((row) => {
        if (row.Index === action.Index)
          row.AdditionalWeight = action.additionalWeight;
        return row;
      });
      return tmpMappingRows;
    }
    default:
      return mappingRowsCeilings;
  }
};

export const filterTypeEnabled = (
  filterTypeEnabled: FilterTypeEnabled = "partitionsWalls",
  action: any
) => {
  switch (action.type) {
    default:
      return filterTypeEnabled;
  }
};

export const activeMappingRowIndex = (
  activeMappingRowIndex: null | number = null,
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SET_ACTIVE_MAPPING_ROW_INDEX:
      return action.index;
    default:
      return activeMappingRowIndex;
  }
};

export const filteredElements = (
  filteredElements: ProductDetailData[] = [],
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case INIT_PRODUCT_SELECTOR:
      return action.filteredElements;
    case SEARCH:
      return action.filteredElements;
    case APPLY_FILTERS:
      return action.filteredElements;
    case RESET_FILTERS:
      return action.filteredElements;
    default:
      return filteredElements;
  }
};

export const displayedElements = (
  displayedElements: ProductDetailData[] = [],
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case INIT_PRODUCT_SELECTOR:
      return action.displayedElements;
    case SEARCH:
      return action.displayedElements;
    case APPLY_FILTERS:
      return action.displayedElements;
    case RESET_FILTERS:
      return action.displayedElements;
    default:
      return displayedElements;
  }
};

export const selectionBuffer = (
  selectionBuffer: ProductDetailData | null = null,
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_BUFFER:
      return action.system;
    case RESET_SELECTION_BUFFER:
      return null;
    case APPLY_FILTERS:
      return null;
    case RESET_FILTERS:
      return null;
    case SEARCH:
      return null;
    default:
      return selectionBuffer;
  }
};

export const searchedWord = (
  searchedWord: string = "",
  action: ProductSelectorAction
) => {
  switch (action.type) {
    case SEARCH:
      return action.searchedWord;
    case APPLY_FILTERS:
      return "";
    case RESET_FILTERS:
      return "";
    default:
      return searchedWord;
  }
};

export const layoutAttributesAid = (
  layoutAttributesAid: PIMLayoutAttributesAid | null = null,
  action: DataAction
) => {
  switch (action.type) {
    case FETCH_PLACO_DATA:
      return action.layoutAttributesAid;
    default:
      return layoutAttributesAid;
  }
};

export const layoutProgress = (
  layoutProgress: number = 0,
  action: LayoutAction
) => {
  switch (action.type) {
    case INIT_LAYOUT_PROGRESS:
      return 0;
    case UPDATE_LAYOUT_PROGRESS:
      return action.progress;
    default:
      return layoutProgress;
  }
};

export const layoutProgressCeiling = (
  layoutProgress: number = 0,
  action: LayoutCeilingAction
) => {
  switch (action.type) {
    case INIT_LAYOUT_PROGRESS_CEILING:
      return 0;
    case UPDATE_LAYOUT_PROGRESS_CEILING:
      return action.progress;
    default:
      return layoutProgress;
  }
};

export const layoutState = (
  layoutState: LayoutState = null,
  action: LayoutAction
): LayoutState => {
  switch (action.type) {
    case START_LAYOUT:
      return "FamilyLoading";
    case INIT_LAYOUT_PROGRESS:
      return "Processing";
    case END_LAYOUT:
      return "Ended";
    default:
      return layoutState;
  }
};

export const layoutStateCeiling = (
  layoutState: LayoutState = null,
  action: LayoutCeilingAction
): LayoutState => {
  switch (action.type) {
    case START_LAYOUT_CEILING:
      return "FamilyLoading";
    case INIT_LAYOUT_PROGRESS_CEILING:
      return "Processing";
    case END_LAYOUT_CEILING:
      return "Ended";
    default:
      return layoutState;
  }
};

export const reducers = combineReducers({
  language,
  functionalityIcon,
  placoData,
  selectionTree,
  selectionTreeCeiling,
  filters,
  levelsData,
  levelsDataCeilings,
  typesData,
  selectedLevels,
  selectedTypes,
  typesDataCeilings,
  selectedLevelsCeilings,
  selectedTypesCeilings,
  mappingRowsWalls,
  mappingRowsCeilings,
  filterTypeEnabled,
  activeMappingRowIndex,
  filteredElements,
  displayedElements,
  selectionBuffer,
  searchedWord,
  layoutAttributesAid,
  layoutProgress,
  layoutProgressCeiling,
  layoutState,
  layoutStateCeiling,
  rigipsMetaData,
  rigipsMetaDataCeilings,
  config
});

export interface DrawStore {
  language: Language;
  functionalityIcon: string;
  placoData: {
    partitionWalls: { systemsDetails: []; filters: [] };
    liningWalls: { systemsDetails: []; filters: [] };
  };
  selectorType: selectorTp;
  selectionTree: ElementsTree;
  selectionTreeCeiling: ElementsTree;
  filters: { liningWalls: any[]; partitions: any[] };
  levelsData: LevelData[];
  typesData: TypeData[];
  selectedLevels: string[];
  selectedTypes: string[];
  levelsDataCeilings: LevelData[];
  typesDataCeilings: TypeData[];
  selectedLevelsCeilings: string[];
  selectedTypesCeilings: string[];
  mappingRowsWalls: MappingRowRigips[];
  mappingRowsCeilings: MappingRowRigipsCeilings[];
  filterTypeEnabled: FilterTypeEnabled;
  activeMappingRowIndex: number | null;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  selectionBuffer: ProductDetailData | null;
  searchedWord: string;
  layoutAttributesAid: PIMLayoutAttributesAid | null;
  layoutProgress: number;
  layoutState: LayoutState;
  layoutProgressCeiling: number;
  layoutStateCeiling: LayoutState;
  rigipsMetaData: ProductMeta[];
  rigipsMetaDataCeilings: ProductMeta[];
  config: any;
}

// let tree = await api.selection.elementsByLevelAndType("wall");
//     let elementsByLevelAndType = tree.Tree;
//     let typesData = elementTypes(levelData(tree, [])) as TypeData[];
//     this.setState({
//       levelsData: elementsByLevelAndType.map(level => ({
//         Name: level.Level.Name,
//         Checked: false
//       })),
//       typesData,
//       tree
//     });
