import { moveContour, moveContourBack } from "../../../../../Layout/helpers";
import {
  computeWallBasis,
  moveWallContours,
  pointRingX,
} from "../../../../../Layout/plt";
import { RevitMappedWall2 } from "../../../../../Layout/types";
import { api } from "../../../../../RevitJS/API";
import { setAlbaId } from "../../Actions/utils";

export const A101Handler = async (mappedWall: RevitMappedWall2) => {
  const {
    LocationLine,
    BoundingBox: { minPoint },
    OuterContour,
    InnerContours,
    Id,
    AlbaStructure,
    LevelId,
  } = mappedWall;
  let wallBasis = computeWallBasis(LocationLine, minPoint);
  let translation = {
    X: LocationLine.p1.X,
    Y: LocationLine.p1.Y,
    Z: minPoint.Z,
  };
  if (mappedWall.Neighbours[0]) {
    let movedContours = moveWallContours(
      OuterContour,
      InnerContours,
      translation,
      wallBasis
    );
    let minX = Math.min(...movedContours.OuterContour.map((p) => p.X));
    let targetX = -60 / 2;
    let dist = targetX - minX;
    let movedOutterContour = pointRingX(movedContours.OuterContour, minX, dist);
    let movedOutterContourBack = moveContourBack(
      movedOutterContour,
      translation,
      wallBasis
    );
    mappedWall.OuterContour = movedOutterContourBack;
  }
  if (mappedWall.Neighbours[1]) {
    let movedContours = moveWallContours(
      mappedWall.OuterContour,
      InnerContours,
      translation,
      wallBasis
    );
    let maxX = Math.max(...movedContours.OuterContour.map((p) => p.X));
    let targetX =
      moveContour([mappedWall.LocationLine.p2], translation, wallBasis)[0].X -
      mappedWall.Neighbours[1].Width / 2;

    let dist = targetX - maxX;
    let movedOutterContour = pointRingX(movedContours.OuterContour, maxX, dist);
    let movedOutterContourBack = moveContourBack(
      movedOutterContour,
      translation,
      wallBasis
    );
    mappedWall.OuterContour = movedOutterContourBack;
  }
  if (AlbaStructure) {
    let levelName = await api.queries.getLevelById(LevelId);
    let albaWallId = await api.familyEditor.createWallByContour(
      mappedWall,
      LocationLine,
      AlbaStructure[0].name,
      levelName.Name,
      false
    );
    await setAlbaId(albaWallId, Id);
  }
};
