import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { connect } from "react-redux";
import { wait } from "../../../../RevitJS/Helpers";

interface Props {
  Icon: string;
  name: string;
  wording: {
    validate: { [key: string]: string };
    dimensions: { [key: string]: string };
    dimensionsDistance: { [key: string]: string };
  };
  language: string;
  setRoute: any;
}

export const overLapElements = async () => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );
  const framesIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature" },
        Rule: "Equals",
      },
    ],
    null
  );
  // const bottomRailsLegacyIds = await api.queries.filterElements(
  //   "Generic",
  //   [
  //     {
  //       Param: { Name: "Name", Type: "Builtin", Value: "Rail" },
  //       Rule: "Equals",
  //     },
  //   ],
  //   null
  // );
  // const upperRailsLegacyIds = await api.queries.filterElements(
  //   "Generic",
  //   [
  //     {
  //       Param: { Name: "Name", Type: "Builtin", Value: "Rail-Retourne" },
  //       Rule: "Equals",
  //     },
  //   ],
  //   null
  // );
  const bottomRailsNewIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-bas" },
        Rule: "Equals",
      },
    ],
    null
  );
  const upperRailsNewIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-haut" },
        Rule: "Equals",
      },
    ],
    null
  );

  const upperRailsIds = upperRailsNewIds; //upperRailsLegacyIds.concat(upperRailsNewIds);
  const bottomRailsIds = bottomRailsNewIds; //bottomRailsLegacyIds.concat(bottomRailsNewIds);
  const processedWallIds = await api.queries.filterElements(
    "Wall",
    [
      {
        Param: { Name: "Processed", Type: "Boolean", Value: true },
        Rule: "Equals",
      },
    ],
    null
  );

  const railsIds = bottomRailsIds.concat(upperRailsIds);
  let plasterboardsFilter = await api.queries.createSelectionFilter(
    "SG_Plasterboards",
    plasterboardIds
  );
  let framesFilter = await api.queries.createSelectionFilter(
    "SG_Frames",
    framesIds
  );
  let railsFilter = await api.queries.createSelectionFilter(
    "SG_Rails",
    railsIds
  );
  let processedWallsFilter = await api.queries.createSelectionFilter(
    "SG_Revit_Walls",
    processedWallIds
  );

  let activeView = await api.queries.getActiveViewDetails();
  let activeViewId = activeView.Id;

  await api.viewHandler.setSelectionFilterVisibility(
    activeViewId,
    plasterboardsFilter,
    true
  );
  await api.viewHandler.setSelectionFilterVisibility(
    activeViewId,
    framesFilter,
    true
  );
  await api.viewHandler.setSelectionFilterVisibility(
    activeViewId,
    railsFilter,
    true
  );
  await api.viewHandler.setSelectionFilterVisibility(
    activeViewId,
    processedWallsFilter,
    true
  );
}


class Waiter extends Component<Props> {
  componentDidMount = async () => {
    await wait(1000).then(async () => {
      await overLapElements();
    });
    api.windowsHandler.closeWindow();
  };

  render = () => {
    return (
      <Dimmer active={true} style={{height: "calc(100vh - 30px)"}}>
        <Loader content={"Superposition en cours..."} />
      </Dimmer>
    );
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    Icon: state.Icon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
  };
};

export default connect(mapStateToProps)(Waiter);
