import React, { useState } from "react";
import { Segment, Modal, Image,Loader } from "semantic-ui-react";

interface Props {
  infoType: any;
  open: any;
  infoText: any;
}
const CustomLoader = (props: Props) => {
  return (
    <>
      <Modal
        size="small"
        open={props.open}
        dimmer="inverted"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          {/* <Image src="/ModelGenerator/loading.gif" Fluid size="large"></Image> */}
          <Segment textAlign="center"></Segment>
          <Segment textAlign="center"></Segment>
          <Segment textAlign="center"></Segment>
          <Loader>{props.infoText}</Loader>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CustomLoader;
