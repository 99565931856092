import { showLayoutDetailsAction, hideLayoutDetailsAction } from "../Actions/types";

type layoutDetailsVisibleAction = showLayoutDetailsAction | hideLayoutDetailsAction

export const layoutDetailsVisible = (layoutDetailsVisible: boolean = false, action: layoutDetailsVisibleAction): boolean =>{
    switch(action.type){
        case "SHOW_LAYOUT_DETAILS":
            return true;
        case "HIDE_LAYOUT_DETAILS":
            return false;
        default:
            return layoutDetailsVisible;
    }
}