import React from "react";
import { Grid, Header, Menu } from "semantic-ui-react";
import { TypeItems } from "./TypeItems";
import TypesToogler from "./TypesToogler";

export class TypesColumn extends React.Component {
  render() {
    return (
      <Grid.Column>
        <Header as="h3" textAlign="center" color={this.props.color}>
          {this.props.columnTitle}
        </Header>
        <div style={{ maxHeight: "260px", overflow: "auto" }}>
          <Menu
            style={{ height: "260px", overflow: "auto" }}
            vertical
            size="small"
            fluid
          >
            <TypeItems
              selectedLevels={this.props.selectedLevels}
              selectType={this.props.selectType}
              typesData={this.props.typesData}
              color={this.props.color}
            />
          </Menu>
        </div>
        <TypesToogler
          toogleTypes={this.props.toogleTypes}
          toogleOn={!this.props.selectedTypes.length}
          activated={this.props.selectedLevels.length}
        />
      </Grid.Column>
    );
  }
}
