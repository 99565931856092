import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import add from "../../../assets/add.png";
import { NEW_PROJECT, ProjectTableHeadCells, NO_SAVED_PROJECTS } from "../../../assets/constants";
import warningIcon from "../../../assets/warning.png";
import Project from "../../../models/Project.model";
import { getEstimatedQuota } from "../../../services/projects.service.local2";
import PixelTable from "../../common/PixelTable/PixelTable";
import "./body.scss";

function Body({
    clickeNewProjectButton,
    loading,
    projectsList = [],
    deleteProject = () => {
        console.log(`NO DELETE METHOD SPECIFIED`);
    },
    modifyProject = () => {
        console.log(`NO UPDATE METHOD SPECIFIED`);
    },
    updateProjectProperty,
    setModal,
    setModalStockage,
    t,
}: IProps) {

    const [cota, setCota] = useState(0)
    useEffect(() => {
        getEstimatedQuota().then(result => result && setCota(result))
    }, [])

    return (
        <div id="project-menu-body-container">
            <div className="px-4 py-2">
                <div id="project-menu-body-list-container">
                    <PixelTable
                        loading={loading}
                        headCells={ProjectTableHeadCells}
                        rows={projectsList}
                        deleteRow={(row, index) => {
                            deleteProject(row);
                        }}
                        modifyProject={(projectToModify) => {
                            modifyProject(projectToModify);
                        }}
                        inputValueChanged={(row, key, value) => {
                            updateProjectProperty(row, key, value);
                        }}
                        noSavedProjectsLabel={t(NO_SAVED_PROJECTS)}
                        t={t}
                    ></PixelTable>
                </div>
            </div>
            <div className="text-left px-4">
                <button
                    onClick={() => {
                        clickeNewProjectButton();
                    }}
                    className="medium-btn my-1 p-1"
                    id="new-project-btn"
                >
                    <img
                        id="add-btn-image"
                        src={add}
                        alt="Logo placeholder"
                        className="mr-2"
                    />
                    <span className="mr-2">
                        <strong>{t(NEW_PROJECT)}</strong>
                    </span>
                </button>
                <img
                    src={warningIcon}
                    id="warning-img"
                    alt="Missing elements warning"
                    onClick={() => {
                        setModal({ open: true });
                    }}
                ></img>
                {cota > 90 && <img
                    src={warningIcon}
                    id="warning-img"
                    alt="Missing elements warning"
                    onClick={() => {
                        setModalStockage({ open: true });
                    }}
                ></img>}
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    clickeNewProjectButton: Function;
    projectsList: Project[];
    deleteProject: Function;
    modifyProject: Function;
    updateProjectProperty: Function;
    loading: boolean;
    setModal: Function;
    setModalStockage: Function
};

export default withTranslation(["pam"])(Body);
