import { CHANGE_CURRENT_WINDOW, AppActionTypes, AppState, INIT_LANGUAGE } from "./types";
import { APP_WINDOW_NAME } from "../../assets/constants";

const initialState: AppState = {
  currentWindow: APP_WINDOW_NAME.ROOT,
  language: "French"
};

export default function app(state = initialState, action: AppActionTypes) {
  let preProcessedState = {
    ...state,
  };
  switch (action.type) {
    case CHANGE_CURRENT_WINDOW:
      return { ...preProcessedState, currentWindow: action.payload };
    case INIT_LANGUAGE:
      return {
        ...state,
        language: action.language
      }
    default:
      return state;
  }
}
