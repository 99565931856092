import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { bimStorage, storageKey } from "../../../../BIMStore";

interface Props {
  name: any;
  value: string;
  setValue: any;
  errors: any[];
  wording: any;
  language: string;
  config: any;
}

interface State {
  file: string;
}

export class ImagePreview extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: "",
    };
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  fileUpload: any;

  toDataURL = (url: any, callback: any) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  loadDefaultmage = (dataUrl: any) => {
    this.setState({ file: dataUrl });
    this.props.setValue(this.props.name, dataUrl);
  };

  async componentDidMount() {
    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );
    var preFillInfo = tempProjectInfo === null ? {} : tempProjectInfo;
    if (preFillInfo) {
      preFillInfo["PROJECT_INFO_FORM"] &&
      preFillInfo["PROJECT_INFO_FORM"].companyInfo.information_enterprise_logo
        ? this.setState({
            file: preFillInfo["PROJECT_INFO_FORM"].companyInfo
              .information_enterprise_logo,
          })
        : this.toDataURL(
            this.props.config.REACT_APP_SERVERURL + "/saint-gobain.jpg",
            this.loadDefaultmage
          );
    } else
      this.toDataURL(
        this.props.config.REACT_APP_SERVERURL + "/saint-gobain.jpg",
        this.loadDefaultmage
      );
  }

  handleChange(e: any) {
    let files = e.target.files;
    var allFiles: any = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };
        allFiles.push(fileInfo);
        this.setState({ file: allFiles[0].base64 });
        this.props.setValue(this.props.name, allFiles[0].base64);
      };
    }
  }

  showFileUpload(e: any) {
    e.preventDefault();
    this.fileUpload.current.click();
  }

  render() {
    return (
      <div
        style={{ display: "block" }}
        className={
          this.props.errors[this.props.name] && !this.state.file
            ? "red-border"
            : ""
        }
      >
        <input
          type="file"
          style={{ display: "none" }}
          ref={this.fileUpload}
          onChange={this.handleChange}
          defaultValue={this.state.file}
          accept="image/*"
        />
        <img
          {...this.props}
          src={this.state.file}
          alt="logo"
          className="logoHolder"
          id="logoData"
        />
        <Button
          className="btnLogo"
          id="btnFileSelect"
          onClick={this.showFileUpload}
          content={this.props.wording.browse[this.props.language]}
        ></Button>
      </div>
    );
  }
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    wording: ownProps.wording,
    config: state.config,
  };
};

export default connect(mapStateToProps)(ImagePreview);
