import Button from "@material-ui/core/Button";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FilterComponent, { Filter } from "./filterComponent";

import '../assets/index.scss'
import { generalizeLanguage } from "../../../../Helper";
import { Language } from "../../../../RevitJS/Types/RevitTypes";
import { CircularProgress } from "@material-ui/core";


interface FilterProps {
  showFavorite: boolean;
  filtersData: Filter[];
  favoriteCount: number;
  isFavoriteDisplayed: boolean;
  onChange: any;
  clearAllFavorite: any;
  displaySystems: any;
  displayFavorite: any;
  resetFilters: any;
  buttonColor: { resetFavoriteButtonColor: string, showFavoriteButtonColor: string, resetFilterButtonColor: string, };
  wording: {
    resetFavorite: { [key: string]: string };
    displayFavorites: { [key: string]: string };
    displaySystems: { [key: string]: string };
    resetFilter: { [key: string]: string };
  };
}

export const Filters = (props: FilterProps) => {
  let savedLanguage: any = localStorage.getItem("savedLanguage");
  const language: Language = generalizeLanguage(savedLanguage);

  useEffect(()=>{
    console.log(props.filtersData);
  },[props.filtersData])
  
  const clearFavorite = () => {
    if (props.showFavorite) {
      return (
        <Button className='FilterButton loadActionButton' style={{ textTransform: 'none', backgroundColor: props.buttonColor.resetFavoriteButtonColor }} variant="contained" onClick={props.clearAllFavorite}> {props.wording.resetFavorite[language]}</Button>
      );
    }
  };

  const toggleFavoriteAndSystems = () => {
    if (props.showFavorite && props.isFavoriteDisplayed) {
      return (
        <Button className='FilterButton loadActionButton' style={{ textTransform: 'none', backgroundColor: props.buttonColor.showFavoriteButtonColor }} variant="contained" onClick={props.displaySystems}> {props.wording.displaySystems[language]}</Button>
      );
    }
    else if (props.showFavorite && !props.isFavoriteDisplayed) {
      return (
        <Button className='FilterButton loadActionButton' style={{ textTransform: 'none', backgroundColor: props.buttonColor.showFavoriteButtonColor }} variant="contained" onClick={props.displayFavorite}> {props.wording.displayFavorites[language]} ({props.favoriteCount})</Button>
      )
    }
  };

  return (
    <div className='FilterContainerNew'>
      {clearFavorite()}
      {toggleFavoriteAndSystems()}
      {/* <Button className='FilterButton loadActionButton configurationActionButton' style={{ textTransform: 'none' }} variant="contained" onClick={props.clearAllFavorite}> Réinitialiser les favoris</Button>

      {props.isFavoriteDisplayed ?
        <Button className='FilterButton loadActionButton detailActionButton' style={{ textTransform: 'none' }} variant="contained" onClick={props.displaySystems}> Afficher les systèmes</Button>
        : <Button className='FilterButton loadActionButton detailActionButton' style={{ textTransform: 'none' }} variant="contained" onClick={props.displayFavorite}> Afficher les favoris ({props.favoriteCount})</Button>
      } */}
      <Button className=' FilterButton loadActionButton' style={{ textTransform: 'none', backgroundColor: props.buttonColor.resetFilterButtonColor }} variant="contained" onClick={() => props.resetFilters()}> {props.wording.resetFilter[language]}</Button>

        {props.filtersData && props.filtersData.length > 0 ? (
          <div className='FilterContent'>
            {props.filtersData && props.filtersData.map((filter: any) =>
              <FilterComponent key={filter.name} name={filter.name} value={filter.value} onChange={props.onChange} options={filter.options} choiceType={filter.choiceType} loading={!filter.options} pimAttribute={filter.pimAttribute} />
            )}
          </div>
        ) : props.filtersData.length === 0 ? <>Data is loading or filters are empty
        <CircularProgress size={25} style={{ margin: "auto" }} className="LoadSystemCircularProgress" /></>: (
          <CircularProgress size={25} style={{ margin: "auto" }} className="LoadSystemCircularProgress" />
        )}
      
    </div>
  );
};

Filters.defaultProps = {
  showFavorite: false,
  favoriteCount: 0,
  clearAllFavorite: undefined,
  displaySystems: undefined,
  displayFavorite: undefined,
  isFavoriteDisplayed: false,
  buttonColor: { resetFavoriteButtonColor: "#21ba45", showFavoriteButtonColor: "#2185d0", resetFilterButtonColor: "#21ba45", },
  wording: {
    resetFavorite: {
      French: "Réinitialiser les favoris",
      English: "Reset Favorites",
      Indonesian: "Setel Ulang Pilihan",
    },
    displayFavorites: {
      French: "Afficher les favoris",
      English: "Show Favorites",
      Indonesian: "Tampil Ulang Pilihan",
    },
    displaySystems: {
      French: "Afficher les systèmes",
      English: "Show Systems",
      Indonesian: "Tampilan Sistem",
    },
    resetFilter: {
      French: "Réinitialiser les filtres",
      English: "Reset filters",
      Indonesian: "Setel Ulang Sistem",
    },
  }
};

export default Filters;