import { FormControlLabel, RadioGroup } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  Radio,
} from "semantic-ui-react";
import { bimStorage } from "../../../../BIMStore";
import { toggleCheck } from "../../../RiggipsBIM/Draw2/Actions";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import wording from "../../Resources/wording.json";
import { SetContext, SetModuleData } from "../../Store/Actions";
import { FacadeStore } from "../../Store/Reducers";
import { dbStoreNameFacadeCalepinage, Routes } from "./root";

const ContextFacade = (props: any) => {
  const reduxState = useSelector((state: FacadeStore) => state);
  const language: string = reduxState.language;
  const buffer = reduxState.buffer;
  const projectData = reduxState.projectData;
  const dispatch = useDispatch();

  const [top, setTop] = useState<string>(buffer.context.data.top.checked);

  const [left, setLeft] = useState<string>(buffer.context.data.left.checked);

  const [bottom, setBottom] = useState<string>(
    buffer.context.data.bottom.checked
  );

  const [right, setRight] = useState<string>(buffer.context.data.right.checked);

  const [topValue, setTopValue] = useState<any>(
    buffer.context.data.top.checked === "A" ? buffer.context.data.top.value : ""
  );
  const [bottomValue, setBottomValue] = useState<any>(
    buffer.context.data.bottom.checked === "A"
      ? buffer.context.data.bottom.value
      : ""
  );
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const translation: any = wording;

  const onInputChanged = (type: string, value: string) => {
    const reg = /^[0-9\b]+$/;
    if (reg.test(value) || value === "") {
      if (type === "top") {
        setTopValue(value);
      } else if (type === "bottom") {
        setBottomValue(value);
      }
    }
  };

  const onValidateClicked = async () => {
    let data = getContextData();

    dispatch(SetContext(data));
    await onAutoSave();
    props.setRoute(Routes.NEW_CALEPINAGE);
  };

  const onBackClicked = () => {
    let data = getContextData();

    if (!_.isEqual(data, buffer.context.data)) {
      setShowWarning(true);
    } else {
      props.setRoute(Routes.NEW_CALEPINAGE);
    }
  };

  const getContextData = () => {
    let data = {
      top: { checked: top, value: topValue },
      left: { checked: left, value: 0 },
      bottom: { checked: bottom, value: bottomValue },
      right: { checked: right, value: 0 },
    };

    return data;
  };

  const onNoClicked = () => {
    props.setRoute(Routes.NEW_CALEPINAGE);
  };

  const checkDisable = (): boolean => {
    if (top === "" || bottom === "" || left === "" || right === "") {
      return true;
    }
    if (top === "A") {
      if (topValue === "" || topValue === 0) {
        return true;
      }
    }

    if (bottom === "A") {
      if (bottomValue === "" || bottomValue === 0) {
        return true;
      }
    }

    return false;
  };

  const onAutoSave = async () => {
    await bimStorage.setModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId,
      buffer.name,
      buffer.date,
      buffer.id,
      JSON.stringify(buffer)
    );

    let calepinageData = await bimStorage.listModule(
      dbStoreNameFacadeCalepinage,
      projectData.ProjectId
    );

    dispatch(SetModuleData(calepinageData));
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={
            reduxState.config.REACT_APP_SERVERURL + "/Facade/calpinageTitle.png"
          }
          name={translation.calepinage[language]}
          subheader=""
          config={reduxState.config}
        />

        <Container
          className="header-alignment subHeader green"
          style={{ marginTop: "1rem" }}
        >
          {translation.context[language]}
        </Container>

        <Container
          className="grid-container context-container  facade-checkbox-radio"
          style={{
            height: "calc(100vh - 195px)",
            // overflow: "auto",
            width: "80%",
            textAlign: "left",
            padding: "15px",
            border: " 0 none",
          }}
        >
          <Grid columns={3} divided>
            <Grid.Row>
              <Grid.Column width="five">
                <Form.Field>
                  <Radio
                    name="ACLTop"
                    checked={top === "A"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>A :</strong>
                          <span>{translation.AT[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setTop("A");
                    }}
                  />
                </Form.Field>
                <Input
                  placeholder={translation.heightPlaceholder[language]}
                  value={topValue}
                  onChange={(e: any) => {
                    onInputChanged("top", e.target.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column width="six">
                <Form.Field>
                  <Radio
                    name="ACLTop"
                    checked={top === "C"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>C :</strong>
                          <span>{translation.CT[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setTop("C");
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="five">
                <Form.Field>
                  <Radio
                    name="ACLTop"
                    checked={top === "L"}
                    value="L"
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>L : </strong>
                          <span>{translation.LT[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setTop("L");
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="light-green">
              <Grid.Column width="five">
                <Form.Field>
                  <Radio
                    name="SRLeft"
                    checked={left === "S"}
                    value="S"
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>S :</strong>
                          <span>{translation.SL[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      //toggleCheck("SL");
                      setLeft("S");
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <Radio
                    name="SRLeft"
                    value="R"
                    checked={left === "R"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>R :</strong>
                          <span>{translation.RL[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setLeft("R");
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="six"></Grid.Column>
              <Grid.Column width="five">
                <Form.Field>
                  <Radio
                    name="SRRight"
                    value="S"
                    checked={right === "S"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>S :</strong>
                          <span>{translation.SR[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setRight("S");
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="SRRight"
                    value="R"
                    checked={right === "R"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>R :</strong>
                          <span>{translation.RR[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setRight("R");
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width="five">
                <Form.Field>
                  <Radio
                    name="ACLBotton"
                    value="A"
                    checked={bottom === "A"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>A :</strong>
                          <span>{translation.AB[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setBottom("A");
                    }}
                  />
                </Form.Field>

                <Input
                  placeholder={translation.heightPlaceholder[language]}
                  value={bottomValue}
                  onChange={(e: any) => {
                    onInputChanged("bottom", e.target.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column width="six">
                <Form.Field>
                  <Radio
                    name="ACLBotton"
                    value="C"
                    checked={bottom === "C"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>C :</strong>
                          <span>{translation.CB[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setBottom("C");
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width="five">
                <Form.Field>
                  <Radio
                    name="ACLBotton"
                    value="L"
                    checked={bottom === "L"}
                    label={
                      <>
                        <label className="checkbox-label-container">
                          <strong>L : </strong>
                          <span>{translation.LB[language]}</span>
                        </label>
                      </>
                    }
                    onChange={() => {
                      setBottom("L");
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <span>
            <Button
              color="grey"
              content={translation.back[language]}
              onClick={() => {
                onBackClicked();
              }}
            ></Button>
          </span>
          <span>
            <Button
              disabled={checkDisable()}
              color="green"
              content={translation.validate[language]}
              onClick={() => {
                onValidateClicked();
              }}
            ></Button>
          </span>
        </div>
      </div>

      <Modal
        open={showWarning}
        size="mini"
        dimmer="inverted"
        className="FacadeModal"
      >
        <Modal.Header
          className="modalHeader"
          style={{ backgroundColor: "#70ad47 !important" }}
        >
          {translation.save[language]}
          <Icon
            name="close"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setShowWarning(false);
            }}
          ></Icon>
        </Modal.Header>
        <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
          <p>{translation.saveParametersWarning[language]}</p>
        </Modal.Description>
        <div style={{ margin: "10px 0px", textAlign: "center" }}>
          <Button color="grey" size="tiny" onClick={() => onNoClicked()}>
            {translation.no[language]}
          </Button>
          <Button
            disabled={checkDisable()}
            color="green"
            size="tiny"
            type="submit"
            primary
            onClick={() => {
              onValidateClicked();
            }}
          >
            {translation.yes[language]}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ContextFacade;
