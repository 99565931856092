import React, { useState } from "react";
import { Icon, Modal } from "semantic-ui-react";

interface Props {
  active: boolean;
  setActive: any;
  header: string;
  message: string;
  closePopup: any;
}

export const SuccesPopupComponent = (props: Props) => {

  const toggleAction = (action: boolean) => {
    props.setActive(action);
    if (props.closePopup !== 'null') {
      props.closePopup();
    }
  };

  return (
      <Modal open={props.active} size="mini" dimmer="blurring">
        <Modal.Header className="modalHeader paddingRight">
          {props.header}
          <Icon
            name="close"
            style={{ float: "right", cursor: "pointer", marginRight: "0" }}
            onClick={() => {
              toggleAction(false);
            }}
          ></Icon>
        </Modal.Header>
        <Modal.Description
          style={{ textAlign: "center", padding: "15px 0px" }}
        >
          <p>{props.message}</p>
        </Modal.Description>
      </Modal>
  );
}