import { Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Element from "../../../../../models/Element.model";
import "./selectionCheckbox.scss";

function SelectionCheckbox({
    element,
    elementIndex,
    handleElementSelected,
    getElementName,
    t,
}: IProps) {
    const [elementState, setElementState] = useState(element);

    useEffect(() => {
        setElementState(element);
    }, [element]);

    return (
        <div
            // key={`element-range-selection-key-div-${element.SystemType}-${element.SystemName}-${element.Id}`}
            key={`element-range-selection-key-div-${element.Id}`}
            className="row m-0"
            id="selection-checkbox-item-element"
        >
            <div
                className="col-2"
                style={{
                    borderBottom: "1px solid lightgray",
                }}
            >
                <Checkbox
                    checked={elementState.selected}
                    onChange={(event) => {
                        handleElementSelected(
                            event.currentTarget.checked,
                            element
                        );
                        setElementState({
                            ...elementState,
                            selected: event.currentTarget.checked,
                        });
                    }}
                ></Checkbox>
            </div>
            <div className="col-10 pl-0 pr-1 element-selection-element-text">
                {getElementName(element)}
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    element: Element;
    elementIndex: number;
    handleElementSelected: Function;
    getElementName: Function;
};

export default withTranslation(["pam"])(SelectionCheckbox);
