import React, { useState } from "react";
import { Button, Grid, Input, Message, Modal } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { IWording } from "../../Types";

const wording: IWording = {
  selectionName: {
    French: "Nom de la sélection",
    English: "Selection name",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
  cancel: {
    French: "Annuler",
    English: "Cancel",
  },
};

interface RulesError {
  length?: boolean;
  lengthValue?: number;
  duplicate?: boolean;
  duplicateList?: string[];
  shallNotContain?: string[];
}

interface Props {
  show: boolean;
  onSave: (name: string) => void;
  onClose: () => void;
  rules?: RulesError;
  headerName?: string;
}

export const NamePopup = (props: Props) => {
  const { show, onSave, onClose, rules, headerName } = props;
  const { control, handleSubmit, reset } = useForm();
  const [namePopupError, setNamePoupError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputDisabled, setInputDisabled] = useState<boolean>(true);

  const onSubmit = (e: any) => {
    if (rules) {
      setNamePoupError(null);
      let error = false;
      e.name = e.name.trim();
      if (rules.length) {
        if (e.name.length < (rules.lengthValue ? rules.lengthValue : 1)) {
          error = true;
          setNamePoupError("minimum length 5");
        }
      }

      if(rules.shallNotContain?.length) {
        rules.shallNotContain.forEach((element: string) => {
          console.log(typeof e.name);
          if(e.name.includes(element)){
            error = true;
            setNamePoupError('Can not use the character (") in the name');
          }
        });
      }

      if (rules.duplicate && rules.duplicateList) {
        const isExist = rules.duplicateList.includes(e.name);
        if (isExist) {
          error = true;
          setNamePoupError("Name already exist");
        }
      }
      if (!error) {
        onSave(e.name);
      }
    } else {
      onSave(e.name);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid columns={1} stackable>
        <Grid.Column>
          <div style={{ marginTop: 12 }}>
            <Modal
              onClose={() => onClose()}
              size="mini"
              dimmer="blurring"
              inverted="true"
              open={show}
            >
              {" "}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header
                  style={{
                    // height: 30,
                    PaddingTop: 10,
                    backgroundColor: "#1678c2",
                    color: "white",
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {headerName}
                </Modal.Header>
                <Modal.Actions
                  style={{
                    backgroundColor: "rgb(221, 231, 246)",
                    padding: "10px",
                  }}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        autoFocus
                        placeholder="Text of at least 5 characters"
                        fluid
                        {...field}
                      />
                    )}
                  />

                  {namePopupError && (
                    <Message
                      style={{ textAlign: "left" }}
                      error
                      content={namePopupError}
                      className="error-msg"
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      paddingTop: 20,
                      justifyContent: "space-between",
                    }}
                  >
                    <Button color="orange" onClick={() => {
                      reset();
                      onClose()
                    }}>
                      {wording.cancel["English"]}
                      {/* {wording.back[applicationLanguage]} */}
                    </Button>
                    <Button type="submit" primary>
                      {wording.validate["English"]}
                    </Button>
                  </div>
                </Modal.Actions>
              </form>
            </Modal>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default NamePopup;
