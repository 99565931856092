import { get, map } from "lodash";
import React from "react";
import { Checkbox, Popup, Table } from "semantic-ui-react";
import TableRow from "./TableRow";
import TableRowNonLayout from "./TableRowNonLayout";

interface WallTableProps {
  defaultWallLayoutPossibleChecked: boolean;
  defaultWallNonLayoutPossibleChecked: boolean;
  disabledCheckWall: boolean;
  setUnsetRows: any;
  placo100: boolean;
  nonLayoutableSolution: any;
  layoutableSolution: any;
  language: string;
  setRoute: any;
  setPlaque: any;
  setMontant: any;
  setCheckedWalls: any;
  showConfig: any;
}

interface wordingType {
  solutionPlaco: { [key: string]: string };
  revitSystemType: { [key: string]: string };
  noWall: { [key: string]: string };
}

const wording: wordingType = {
  solutionPlaco: {
    French: "Solutions Placo",
    English: "Solutions Placo",
  },
  revitSystemType: {
    French: "Type de système Revit",
    English: "Revit System Type",
  },
  noWall: {
    French: "Aucun mur n'a été sélectionné dans le projet",
    English: "There are no Walls",
  },
};
export const WallTable = (props: WallTableProps) => {
  return (
    <>
      {props.layoutableSolution.length > 0 && (
        <Table celled className="fixed_header" style={{ marginBottom: "20px", height: props.nonLayoutableSolution.length === 0 ? "calc(100vh - 230px)" : "200px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={9} className="primary-header">
                Solutions calepinées
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row className="secondary-table-header">
              <Table.HeaderCell style={{ width: "5%" }}>
                <Checkbox
                  checked={props.defaultWallLayoutPossibleChecked}
                  onChange={(e, data) => {
                    props.setUnsetRows(data.checked!, true);
                  }}
                // disabled={props.placo100}
                />
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "5%" }}>Etage</Table.HeaderCell>
              {get(props.layoutableSolution[0], "Zone") !== "" && (
                <Table.HeaderCell>Zone</Table.HeaderCell>
              )}
              <Table.HeaderCell style={{ width: "15%" }}>
                {wording.revitSystemType[props.language]}
              </Table.HeaderCell>
              <Popup
                trigger={
                  <Table.HeaderCell style={{ width: "5%" }}>
                    H(m)
                  </Table.HeaderCell>
                }
                content="Hauteur du Système Revit"
                on="hover"
                position="top center"
                inverted
                size="mini"
              />
              <Table.HeaderCell style={{ width: "28%" }}>
                {wording.solutionPlaco[props.language]}
              </Table.HeaderCell>
              <Popup
                trigger={
                  <Table.HeaderCell style={{ width: "5%" }}>
                    H'(m)
                  </Table.HeaderCell>
                }
                content="Hauteur limite du système Placo®"
                on="hover"
                position="top center"
                inverted
                size="mini"
              />
              <Table.HeaderCell style={{ width: "5%" }}></Table.HeaderCell>
              <Table.HeaderCell style={{ width: "16%" }}>
                Choix de la plaque
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "16%" }}>
                Choix du Montant
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ textAlign: "center" }}>
            {map(props.layoutableSolution, (walls: any, key: any) => {
              return (
                <TableRow
                  walls={walls}
                  setRoute={props.setRoute}
                  setPlaque={props.setPlaque}
                  setMontant={props.setMontant}
                  setCheckedWalls={props.setCheckedWalls}
                  //checkedWalls={this.state.checkedWalls}
                  //setLayoutPossible={this.setLayoutPossible}
                  key={key}
                  index={key}
                  showConfig={props.showConfig}
                // setScrollPosition={this.props.setScrollPosition}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}

      {props.nonLayoutableSolution.length > 0 && (
        <Table celled className={`fixed_header ${props.layoutableSolution.length !== 0 ? `height200` : `height400`}`} style={{ marginBottom: "0" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={9} className="primary-header">
                Solutions non calepinées (Quantitatifs sur base de ratio)
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row className="secondary-table-header">
              <Table.HeaderCell style={{ width: "5%" }}>
                <Checkbox
                  checked={props.defaultWallNonLayoutPossibleChecked}
                  onChange={(e, data) => {
                    props.setUnsetRows(data.checked!, false);
                  }}
                  disabled={props.disabledCheckWall}
                />
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "5%" }}>Étage</Table.HeaderCell>
              {get(props.nonLayoutableSolution[0], "Zone") !== "" && (
                <Table.HeaderCell>Zone</Table.HeaderCell>
              )}
              <Table.HeaderCell style={{ width: "15%" }}>
                {wording.revitSystemType[props.language]}
              </Table.HeaderCell>
              <Popup
                trigger={
                  <Table.HeaderCell style={{ width: "5%" }}>
                    H(m)
                  </Table.HeaderCell>
                }
                content="Hauteur du Système Revit"
                on="hover"
                position="top center"
                inverted
                size="mini"
              />
              <Table.HeaderCell style={{ width: "28%" }}>
                {wording.solutionPlaco[props.language]}
              </Table.HeaderCell>
              <Popup
                trigger={
                  <Table.HeaderCell style={{ width: "5%" }}>
                    H'(m)
                  </Table.HeaderCell>
                }
                content="Hauteur limite du système Placo®"
                on="hover"
                position="top center"
                inverted
                size="mini"
              />
              <Table.HeaderCell style={{ width: "5%" }}></Table.HeaderCell>
              <Table.HeaderCell style={{ width: "16%" }}>
                Choix de la plaque
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "16%" }}>
                Choix du Montant
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ textAlign: "center" }}>
            {map(props.nonLayoutableSolution, (walls: any, key: any) => {
              return (
                <TableRowNonLayout
                  walls={walls}
                  setRoute={props.setRoute}
                  setPlaque={props.setPlaque}
                  setMontant={props.setMontant}
                  setCheckedWalls={props.setCheckedWalls}
                  key={key}
                  index={key}
                  showConfig={props.showConfig}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
