import { ThunkAction } from "redux-thunk";
import { CartActionTypes } from "./types";
import {
  addSystemToCartAction,
  cleanCart,
  removeSystemFromCartAction,
} from "./actions";
import { DrawStore } from "../reducers";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { ID } from "../../../../../RevitJS/Types/RevitTypes";

type Effect = ThunkAction<any, DrawStore, any, CartActionTypes>;

export const addSystemToCart =
  (system: ProductDetailData): Effect =>
  (dispatch: any, getState: any) => {
    let { systemTypeID } = getState().systems;
    dispatch(addSystemToCartAction(system, systemTypeID));
  };

export const removeSystemFromCart =
  (oid: ID): Effect =>
  (dispatch: any, getState: any) => {
    const index = getState()
      .cart.currentSystemItems.map(function (x: any) {
        return x.oid;
      })
      .indexOf(oid);
    let { systemTypeID } = getState().systems;
    dispatch(removeSystemFromCartAction(index, systemTypeID));
  };

export const checkCartSystem =
  (oid: ID): Effect =>
  (dispatch: any, getState: any) => {
    return getState().cart.currentSystemItems.find(
      (item: any) => oid === item.oid
    );
  };

export const cleanCartSystem = (): Effect => (dispatch: any, getState: any) => {
  dispatch(cleanCart());
};
