import { ExcelExtract } from "../../../PlacoBIM/Quantitatiff/Components/model";

import Excel from "exceljs";
import {
  Dictionary,
  filter,
  find,
  flatten,
  forEach,
  get,
  groupBy,
  map,
  sortBy,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import { CeilingData, JsonLevel } from "../../../../RevitJS/Types/RevitTypes";

export interface elementProperties {
  id: any;
  ceilingId: any;
  length: any;
  name: any;
  levelId: string | undefined;
  levelName: string | undefined;
  unit?: string
}

export interface ceilingElements {
  mainRunner: elementProperties[];
  crossTee: elementProperties[];
  hanger: elementProperties[];
  angleTrim: elementProperties[];
  bracket1: elementProperties[];
  bracket2: elementProperties[];
  spaceBar: elementProperties[];
  hygieneclip: elementProperties[];
  cornieres: elementProperties[];
}

export interface ceilingElementsgroupBy {
  mainRunner: Dictionary<elementProperties[]>;
  crossTee: Dictionary<elementProperties[]>;
  hanger: Dictionary<elementProperties[]>;
  angleTrim: Dictionary<elementProperties[]>;
  bracket1: Dictionary<elementProperties[]>;
  bracket2: Dictionary<elementProperties[]>;
  spaceBar: Dictionary<elementProperties[]>;
  hygieneclip: Dictionary<elementProperties[]>;
  cornieres: Dictionary<elementProperties[]>;
}

export const projectSection = (
  overviewByLevelSheet: Excel.Worksheet,
  startingRow: number,
  ceilingLevel: any
) => {
  overviewByLevelSheet.getRow(startingRow).getCell(1).value = "Project name";
  overviewByLevelSheet.getRow(startingRow).getCell(1).font = {
    name: "Arial",
    size: 14,
    bold: true,
    color: { argb: "000000" },
  };

  startingRow = startingRow + 1;

  overviewByLevelSheet.getRow(startingRow).getCell(1).value = "Level:";
  overviewByLevelSheet.getRow(startingRow).getCell(1).font = {
    name: "Arial",
    size: 12,
    bold: true,
    color: { argb: "000000" },
  };

  overviewByLevelSheet.getRow(startingRow).getCell(2).value = "Date:";
  overviewByLevelSheet.getRow(startingRow).getCell(2).font = {
    name: "Arial",
    size: 12,
    bold: true,
    color: { argb: "000000" },
  };

  overviewByLevelSheet
    .getRow(startingRow)
    .eachCell((cell: Excel.Cell, colNumber: number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: "000000" },
        fgColor: { argb: "FFE000" },
      };
    });

  startingRow = startingRow + 1;

  overviewByLevelSheet.getRow(startingRow).getCell(1).value = ceilingLevel.Name;
  overviewByLevelSheet.getRow(startingRow).getCell(1).font = {
    name: "Arial",
    size: 12,
    bold: false,
    color: { argb: "000000" },
  };
  return startingRow;
};

export const summaryTableHeaderRow = (
  startingRow: number,
  ceilingFromLevel: any
) => {
  const tableHeaderRow: any = [];

  tableHeaderRow.push({
    name: "Item nr",
    row: startingRow,
    column: 1,
  });

  tableHeaderRow.push({
    name: "Product name",
    row: startingRow,
    column: 2,
  });

  tableHeaderRow.push({
    name: "Dim.",
    row: startingRow,
    column: 3,
  });

  let columnIndex = 4;
  forEach(ceilingFromLevel, (ceiling, index) => {
    tableHeaderRow.push({
      name: ceiling.Id,
      row: startingRow,
      column: columnIndex,
    });
    columnIndex = columnIndex + 1;
  });

  tableHeaderRow.push({
    name: "Total",
    row: startingRow,
    column: columnIndex,
  });

  columnIndex = columnIndex + 1;

  tableHeaderRow.push({
    name: "Units",
    row: startingRow,
    column: columnIndex,
  });

  return tableHeaderRow;
};

export const summaryHeaderPrimaryRow = (
  overviewByLevelSheet: Excel.Worksheet,
  summaryHeaderRowNumber: number,
  topColumnLength: number
) => {
  overviewByLevelSheet.mergeCells(
    summaryHeaderRowNumber,
    1,
    summaryHeaderRowNumber,
    topColumnLength
  );

  overviewByLevelSheet.getCell(`A${summaryHeaderRowNumber}`).value = "Summary";
  overviewByLevelSheet.getCell(`A${summaryHeaderRowNumber}`).alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  overviewByLevelSheet.getCell(`A${summaryHeaderRowNumber}`).font = {
    name: "Arial",
    size: 12,
    bold: true,
    color: { argb: "000000" },
  };
  overviewByLevelSheet.getCell(`A${summaryHeaderRowNumber}`).fill = {
    type: "pattern",
    pattern: "solid",
    bgColor: { argb: "000000" },
    fgColor: { argb: "FFE000" },
  };
};

export const getCeilings = async (): Promise<{
  ceilingLevels: JsonLevel[];
  ceilingDetails: CeilingData[];
}> => {
  let ceilingTree = await api.selection.elementsByLevelAndType("ceiling");
  let ceilingLevels = map(ceilingTree.Tree, "Level");
  let ceilingIds = flatten(
    map(flatten(map(ceilingTree.Tree, "Elements")), "Ids")
  );
  let ceilingDetails = await api.queries.getCeilingsData(ceilingIds);

  ceilingDetails = map(ceilingDetails, (ceiling, index) => {
    let ceilingData = find(ceilingLevels, { Id: ceiling.LevelId });
    ceiling.Elevation = get(ceilingData, "Elevation");
    return ceiling;
  });

  // Sort by Elevation
  ceilingDetails = sortBy(ceilingDetails, ["Elevation"]);

  return { ceilingLevels, ceilingDetails };
};

export const getCeilingElement = async (
  ceilingDetails: CeilingData[]
): Promise<ceilingElements> => {
  const mainRunnersIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Family", Type: "Builtin", Value: "Ecophon_MainRunner" },
        Rule: "Equals",
      },
    ],
    null
  );

  const mainRunnerDetails = await api.queries.getObjectsParams(mainRunnersIds, [
    "id",
    "longueur T24/38",
    "name",
  ]);

  const brackets1Ids = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Family",
          Type: "Builtin",
          Value: "Ecophon_Connect Wall Bracket 1",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  const brackets1Details = await api.queries.getObjectsParams(brackets1Ids, [
    "id",
    "name",
  ]);

  const brackets2Ids = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Family",
          Type: "Builtin",
          Value: "Ecophon_Connect Wall Bracket 2",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  const brackets2Details = await api.queries.getObjectsParams(brackets2Ids, [
    "id",
    "name",
  ]);

  const angleTrimIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Family", Type: "Builtin", Value: "Ecophon_AngleTrim" },
        Rule: "Equals",
      },
    ],
    null
  );

  const angleTrimDetails = await api.queries.getObjectsParams(angleTrimIds, [
    "id",
    "longueur T24/38",
    "name",
  ]);

  const spaceBarIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Family", Type: "Builtin", Value: "Ecophon_SpaceBar" },
        Rule: "Equals",
      },
    ],
    null
  );

  const spaceBarDetails = await api.queries.getObjectsParams(spaceBarIds, [
    "id",
    "length",
    "name",
  ]);

  const hangerIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Family", Type: "Builtin", Value: "Ecophon_Hanger" },
        Rule: "Equals",
      },
    ],
    null
  );

  const hangerDetails = await api.queries.getObjectsParams(hangerIds, [
    "id",
    "Hauteur",
    "name",
  ]);

  const otherRunnersIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Family", Type: "Builtin", Value: "Ecophon_CrossTee" },
        Rule: "Equals",
      },
    ],
    null
  );

  const otherRunnerDetails = await api.queries.getObjectsParams(
    otherRunnersIds,
    ["id", "longueur T24/38", "name"]
  );

  const hygineneClipIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Family",
          Type: "Builtin",
          Value: "Ecophon_HygieneClip",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  const hygineneClipDetails = await api.queries.getObjectsParams(
    hygineneClipIds,
    ["id", "name"]
  );

  const cornieresIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Family",
          Type: "Builtin",
          Value: "Ecophon_ChannelTrim",
        },
        Rule: "Equals",
      },
    ],
    null
  );

  const corniereDetails = await api.queries.getObjectsParams(cornieresIds, [
    "id",
    "longueur T24/38",
    "name",
    "system",
    "configuration",
    "range",
  ]);

  const finalMainRunner: elementProperties[] = processGroup1(
    mainRunnerDetails,
    ceilingDetails
  );
  const finalCrossTee: elementProperties[] = processGroup1(
    otherRunnerDetails,
    ceilingDetails
  );
  const finalHanger: elementProperties[] = processGroup5(
    hangerDetails,
    ceilingDetails
  ); // Connect Hanger clip
  const finalAngleTrim: elementProperties[] = processGroup1(
    angleTrimDetails,
    ceilingDetails
  ); //Connect Angle trim

  
  const finalBracket1: elementProperties[] = processGroup4(
    brackets1Details,
    ceilingDetails
  ); // Connect Wall bracket for T-profiles
  const finalBracket2: elementProperties[] = processGroup4(
    brackets2Details,
    ceilingDetails
  ); // Connect Wall bracket for Space bar

  const finalSpaceBar: elementProperties[] = processGroup3(
    spaceBarDetails,
    ceilingDetails
  );
  const finalHygieneClip: elementProperties[] = processGroup2(
    hygineneClipDetails,
    ceilingDetails
  );
  const finalCornieres: elementProperties[] = processGroup1(
    corniereDetails,
    ceilingDetails
  );

  return {
    mainRunner: finalMainRunner,
    crossTee: finalCrossTee,
    hanger: finalHanger,
    angleTrim: finalAngleTrim,
    bracket1: finalBracket1,
    bracket2: finalBracket2,
    spaceBar: finalSpaceBar,
    hygieneclip: finalHygieneClip,
    cornieres: finalCornieres,
  };
};

export const getCeilingElementByLevel = async (
  ceilingLevel: JsonLevel,
  elementData: ceilingElements
): Promise<ceilingElements> => {
  const mainRunnerByLevel = filter(elementData.mainRunner, {
    levelId: ceilingLevel.Id,
  });

  const spaceBarByLevel = filter(elementData.spaceBar, {
    levelId: ceilingLevel.Id,
  });

  const crossTeeByLevel = filter(elementData.crossTee, {
    levelId: ceilingLevel.Id,
  });

  const mainBracket1ByLevel = filter(elementData.bracket1, {
    levelId: ceilingLevel.Id,
  });

  const mainBracket2ByLevel = filter(elementData.bracket2, {
    levelId: ceilingLevel.Id,
  });

  const mainAngleTrimByLevel = filter(elementData.angleTrim, {
    levelId: ceilingLevel.Id,
  });

  const hangerByLevel = filter(elementData.hanger, {
    levelId: ceilingLevel.Id,
  });

  const hygieneClipByLevel = filter(elementData.hygieneclip, {
    levelId: ceilingLevel.Id,
  });

  const corniersByLevel = filter(elementData.cornieres, {
    levelId: ceilingLevel.Id,
  });

  return {
    mainRunner: mainRunnerByLevel,
    crossTee: crossTeeByLevel,
    hanger: hangerByLevel,
    angleTrim: mainAngleTrimByLevel,
    bracket1: mainBracket1ByLevel,
    bracket2: mainBracket2ByLevel,
    spaceBar: spaceBarByLevel,
    hygieneclip: hygieneClipByLevel,
    cornieres: corniersByLevel,
  };
};

export const getCeilingElementByProduct = async (
  elementData: ceilingElements
): Promise<ceilingElementsgroupBy> => {
  const mainRunnerByLevel = groupBy(elementData.mainRunner, "name");

  const spaceBarByLevel = groupBy(elementData.spaceBar, "name");

  const crossTeeByLevel = groupBy(elementData.crossTee, "name");

  const mainBracket1ByLevel = groupBy(elementData.bracket1, "name");

  const mainBracket2ByLevel = groupBy(elementData.bracket2, "name");

  const mainAngleTrimByLevel = groupBy(elementData.angleTrim, "name");

  const hangerByLevel = groupBy(elementData.hanger, "name");

  const hygieneClipByLevel = groupBy(elementData.hygieneclip, "name");

  const corniersByLevel = groupBy(elementData.cornieres, "name");

  return {
    mainRunner: mainRunnerByLevel,
    crossTee: crossTeeByLevel,
    hanger: hangerByLevel,
    angleTrim: mainAngleTrimByLevel,
    bracket1: mainBracket1ByLevel,
    bracket2: mainBracket2ByLevel,
    spaceBar: spaceBarByLevel,
    hygieneclip: hygieneClipByLevel,
    cornieres: corniersByLevel,
  };
};

export const getElementOfCeiling = async (
  elementData: ceilingElements,
  id: any
): Promise<ceilingElementsgroupBy> => {
  const mainRunnerByLevel = groupBy(
    filter(elementData.mainRunner, {
      ceilingId: id,
    }),
    "name"
  );

  const spaceBarByLevel = groupBy(
    filter(elementData.spaceBar, {
      ceilingId: id,
    }),
    "name"
  );

  const crossTeeByLevel = groupBy(
    filter(elementData.crossTee, {
      ceilingId: id,
    }),
    "name"
  );

  const mainBracket1ByLevel = groupBy(
    filter(elementData.bracket1, {
      ceilingId: id,
    }),
    "name"
  );

  const mainBracket2ByLevel = groupBy(
    filter(elementData.bracket2, {
      ceilingId: id,
    }),
    "name"
  );

  const mainAngleTrimByLevel = groupBy(
    filter(elementData.angleTrim, {
      ceilingId: id,
    }),
    "name"
  );

  const hangerByLevel = groupBy(
    filter(elementData.hanger, {
      ceilingId: id,
    }),
    "name"
  );

  const hygieneClipByLevel = groupBy(
    filter(elementData.hygieneclip, {
      ceilingId: id,
    }),
    "name"
  );

  const corniersByLevel = groupBy(
    filter(elementData.cornieres, {
      ceilingId: id,
    }),
    "name"
  );

  return {
    mainRunner: mainRunnerByLevel,
    crossTee: crossTeeByLevel,
    hanger: hangerByLevel,
    angleTrim: mainAngleTrimByLevel,
    bracket1: mainBracket1ByLevel,
    bracket2: mainBracket2ByLevel,
    spaceBar: spaceBarByLevel,
    hygieneclip: hygieneClipByLevel,
    cornieres: corniersByLevel,
  };
};

const processGroup1 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
): elementProperties[] => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: ceilingId,
      length: find(mr.Params, { Name: "longueur T24/38" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};

const processGroup2 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
): elementProperties[] => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: find(mr.Params, { Name: "id" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
      length: 0,
    };
  });

  return fgd;
};

const processGroup3 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
): elementProperties[] => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: find(mr.Params, { Name: "id" }).Value,
      length: find(mr.Params, { Name: "length" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};

const processGroup4 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
): elementProperties[] => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });
    return {
      id: mr.Id,
      ceilingId: find(mr.Params, { Name: "id" }).Value,
      name: find(mr.Params, { Name: "name" }).Value,
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
      length: 0,
    };
  });

  return fgd;
};


/// For hanger only
const processGroup5 = (
  mainRunnerDetails: any,
  ceilingDetails: CeilingData[]
): elementProperties[] => {
  const fgd = map(mainRunnerDetails, (mr) => {
    const ceilingId = find(mr.Params, { Name: "id" }).Value;
    const ceilingdt = find(ceilingDetails, { Id: ceilingId });

    const hangerLength = find(mr.Params, { Name: "Hauteur" }).Value

    let hangerValueTobe = 120;
    if(hangerLength)
    {
      if(hangerLength < 200)
      {
        hangerValueTobe = 120;
      }
      if(hangerLength > 200 && hangerLength < 341)
      {
        hangerValueTobe = 201;
      }

      if(hangerLength > 340 && hangerLength < 601)
      {
        hangerValueTobe = 341;
      }

      if(hangerLength > 600 && hangerLength < 1001)
      {
        hangerValueTobe = 601;
      }

      if(hangerLength > 1000 && hangerLength < 1401)
      {
        hangerValueTobe = 1001;
      }

      if(hangerLength > 1400 && hangerLength < 1801)
      {
        hangerValueTobe = 1401;
      }

      if(hangerLength > 1800)
      {
        hangerValueTobe = 1801;
      }
    }
    return {
      id: mr.Id,
      ceilingId: ceilingId,
      length: hangerValueTobe,
      name: find(mr.Params, { Name: "name" }).Value && "Connect Adjustable Hanger",
      levelId: ceilingdt?.LevelId,
      levelName: ceilingdt?.LevelName,
    };
  });

  return fgd;
};
