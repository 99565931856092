import React, { useState, useEffect } from "react";
import wordingJson from "../../../Resources/wording.json";
import { SupplimentaryForm } from "./SupplimentaryForm";
import { TopHeader } from "../../../../Components/Headers";
import { bimStorage, storageKey } from "../../../../../../BIMStore";

interface Props {
  setRoute: any;
  route: any;
}

export const InfoSupplimentary = (props: Props) => {
  const [infoFormData, setInfoFormData] = useState<any>(null);
  let count = 1;
  const supplimentaryformData = [
    [
      {
        header: wordingJson.formHeader3["English"],
        fields: [
          {
            type: "input",
            name: "maitre_douvrage_name",
            label: wordingJson.name["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "maitre_douvrage_address",
            label: wordingJson.address["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "maitre_douvrage_telephone",
            label: wordingJson.telephone["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "maitre_douvrage_email",
            label: wordingJson.email["English"],
            mandatory: false,
          },
        ],
      },
      {
        header: wordingJson.formHeader4["English"],
        fields: [
          {
            type: "input",
            name: "maitre_doeuvre_name",
            label: wordingJson.name["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "maitre_doeuvre_address",
            label: wordingJson.address["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "maitre_doeuvre_telephone",
            label: wordingJson.telephone["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "maitre_doeuvre_email",
            label: wordingJson.email["English"],
            mandatory: false,
          },
        ],
      },
    ],
    [
      {
        header: wordingJson.formHeader5["English"],
        fields: [
          {
            type: "input",
            name: "enterprise_generale_name",
            label: wordingJson.name["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "enterprise_generale_address",
            label: wordingJson.address["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "enterprise_generale_telephone",
            label: wordingJson.telephone["English"],
            mandatory: false,
          },
          {
            type: "input",
            name: "enterprise_generale_email",
            label: wordingJson.email["English"],
            mandatory: false,
          },
        ],
      },
      {
        header: "Lot",
        fields: [
          {
            type: "input",
            name: "lot_1",
            label: "Lot " + count++,
            mandatory: false,
          },
          {
            type: "input",
            name: "lot_2",
            label: "Lot " + count++,
            mandatory: false,
          },
          {
            type: "input",
            name: "lot_3",
            label: "Lot " + count++,
            mandatory: false,
          },
        ],
      },
    ],
  ];

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    const response = await bimStorage.getAddInfoForm();

    if (response) {
      setInfoFormData(response);
    }
  };

  if (!infoFormData) {
    return <></>;
  }
  return (
    <>
      <TopHeader Icon={""} name={"Additional Information"} />
      <SupplimentaryForm
        data={supplimentaryformData}
        {...props}
        infoFormData={infoFormData}
      />
    </>
  );
};
