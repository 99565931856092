import { AnyAction } from "redux"
import { selectLevelAction, createSelectionAction, UPDATE_BUFFER_SELECTION, updateBufferSelection } from "../Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";

type ceilingSelectedLevelsAction = selectLevelAction | createSelectionAction<PlacoOptions> | updateBufferSelection;

export const ceilingSelectedLevels = (
    ceilingSelectedLevels: string[] = [],
    action: ceilingSelectedLevelsAction
  ): string[] =>{
    switch(action.type){
      case "SELECT_LEVEL":
          if(action.selectorType === "ceiling"){
            return action.selectedLevels;
          }
          return ceilingSelectedLevels;
      case "CREATE_SELECTION":
      case "UPDATE_BUFFER_SELECTION":
        return []
      default:
        return ceilingSelectedLevels
    }
  }
