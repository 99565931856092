import { getValidJwtToken } from "../../API/authenticate.api";
import { reportData } from "../../Components/CommonComponents/ErrorManagement/ErrorData";
import { MyConfig } from "../../Helper";
import { EventMeta } from "../Types/EventTypes";
import jwt_decode from "jwt-decode";
import { api } from ".";

export interface EventsLog {}

export class EventsLog {
  async SetEvent(eventData: EventMeta): Promise<any> {
    let config = await MyConfig();
    const tokenString = await getValidJwtToken(config);
    let decode: any = jwt_decode(tokenString);
    const { userId } = decode;
    let version = await api.framework.getVersion();
    let productName = await api.framework.getProductName();
    let addInfo = {
      ...eventData,
      application: "BIMTS",
      userName: userId,
      version: version,
      productName: productName,
      sessionId: localStorage.getItem("eventID"),
    };
    console.log("Event Data: ", addInfo);
    let url = config.REACT_APP_EVENTMANAGER_API + "/postEvent";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenString,
      },
      body: JSON.stringify(addInfo),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          console.log("response not ok : ", response.text());
        }
      })
      .catch((errors) => {
        console.log("Error while post event: ", errors.message);
      });
  }

  async SetErrorReport(errorData: reportData, config: any): Promise<any> {
    console.log("Error Report Data: ", errorData);
    const tokenString = await getValidJwtToken(config);
    let url = config.REACT_APP_EVENTMANAGER_API + "/postErrorReport";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenString,
      },
      body: JSON.stringify(errorData),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          console.log("response not ok : ", response.text());
        }
      })
      .catch((errors) => {
        console.log("Error while post error event: ", errors.message);
      });
  }
}
