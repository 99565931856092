import { Language } from "../../../../RevitJS/Types/RevitTypes";

export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export interface InitLanguageAction{
    type: typeof INIT_LANGUAGE;
    language: Language
}

export interface SetLanguageAction{
    type: typeof SET_LANGUAGE,
    language: Language
}

export type LanguageAction = InitLanguageAction | SetLanguageAction