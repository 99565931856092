import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { TrainingTwoRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "4c6a26db-2148-4670-8b8c-8661df38cf04";

export const TrainingTwoTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    610,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="trainingtwo"
    >
      <TrainingTwoRoot />
    </ParentRoot>
  );
};

export const TrainigTwoRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="trainingtwo"
    >
      <TrainingTwoRoot />
    </ParentRoot>
  );
};

export const TrainingTwo: Functionality = {
  Name: {
    French: "TrainingTwo",
    English: "TrainingTwo",
    German: "TrainingTwo",
    Italian: "TrainingTwo",
    Indonesian: "Calepiner",
  },
  Trigger: TrainingTwoTrigger,
  ShortDesc: {
    French: "For Manali",
    English: "For Manali",
    German: "For Manali",
    Italian: "For Manali",
    Indonesian: "For Manali",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Calepiner.png",
  RootComponent,
  ParentId: "0",
};
