import { AnyAction } from "redux";
import { setActiveRowIndexAction, selectSystemAction } from "../Actions/types";

type activeRowIndexAction = setActiveRowIndexAction | selectSystemAction;

export const activeRowIndex = (activeRowIndex: number | null = null, action: activeRowIndexAction): number | null =>{
    switch(action.type){
        case "SET_ACTIVE_ROW_INDEX":
            return action.Index;
        case "SELECT_SYSTEM":
            return null;
        default:
            return activeRowIndex;
    }
}