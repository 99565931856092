import React from 'react';
import { connect } from 'react-redux';
import { Menu, Header, Loader, Dropdown } from 'semantic-ui-react';
// import { setFilterValue } from '../Actions';
// import {wording} from '../Ressources/wording';


const language = "French";

export class Filter extends React.Component {
    filterChoice = (choiceType, options, name) =>{
        let filterOptions;
        switch(choiceType){
            case "RadioBox":
                filterOptions = options.map(option => {return {key: option, text: option, value: option}});
                return <Dropdown value={this.props.value} onChange={(e, d) => this.props.applyFilters(name, d.value)} placeholder='---' clearable selection search options={filterOptions} />
            case "CheckBox":
                filterOptions = options.map(option => {return {key: option, text: option, value: option}});
                return <Dropdown value={this.props.value} onChange={(e, d) => this.props.applyFilters(name, d.value)} placeholder='---' clearable selection search multiple options={filterOptions} />
            default:
                return null;
        }
        
    }
    render() {
        const filterCenter = () => this.props.options ? this.filterChoice(this.props.choiceType, this.props.options, this.props.name): <div style={{width:"100%", textAlign:"center"}}><Loader inline active/></div>
        return (
            <Menu.Item
                name={this.props.name}
                onClick={this.handleItemClick}
            >
                <Header as='h4'>{this.props.name}</Header>
                {filterCenter()}
            </Menu.Item>
        )

    }
}

Filter.defaultProps = {
    // wording
}

// export default connect(null, {setFilterValue})(Filter);