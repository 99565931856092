import React, { Component } from "react";
import { Routes } from "./root";
import { Home } from "./Home";

type Props = {
  route: string;
  setRoute: any;
};

export const AppBody = (props: Props) => <Home setRoute={props.setRoute}/>;
export default AppBody;
