import {
  PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY,
  PAM_ATTRIBUTES_TECHNICAL_NAME,
} from "../Assets/attributes.injection";

import { PAM_CATEGORIES_KEYS, PAM_ATTRIBUTES_KEYS } from "../Assets/types";

export const setPAMAttributesValuesModal = (type, attributes) => {
  const attributesByType = PAM_ATTRIBUTES_BY_TYPE_AND_CATEGORY[type];
  const attributesModal = [];
  const generalData = attributesByType.find(
    (a) => a.categoryName === PAM_CATEGORIES_KEYS.GENERAL_DATA
  );

  attributesModal.push({
    categoryName: generalData.categoryName,
    pamData: generalData.pamData
      .filter((attribute) => attribute !== PAM_ATTRIBUTES_KEYS.GENERATION_DATE)
      .map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
          value: att?.values[0]?.value?.split('"').join("") || null,
        };
      }),
  });
  const chaptersNames = attributesByType
    .map((x) => x.chapterName)
    .filter(
      (value, index) =>
        value &&
        attributesByType.map((x) => x.chapterName).indexOf(value) === index
    );
  attributesModal.push(
    ...chaptersNames.map((chapterName) =>
      setPAMAttributesValuesModalByChapter(
        chapterName,
        attributesByType,
        attributes
      )
    )
  );
  attributes = attributes.filter(
    (att) =>
      !Object.values(PAM_ATTRIBUTES_TECHNICAL_NAME).some(
        (tech) => att.technicalName === tech
      )
  );

  return attributesModal;
};

const setPAMAttributesValuesModalByChapter = (
  chapterName,
  attributesByType,
  attributes
) => {
  const attributesByChapter = attributesByType
    .filter((attribute) => attribute.chapterName === chapterName)
    .map((a) => ({
      categoryName: a.categoryName,
      pamData: a.pamData.map((attribute) => {
        const att = attributes.find(
          (a) => a.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME[attribute]
        );
        return {
          aid: att?.aid,
          technicalName: PAM_ATTRIBUTES_TECHNICAL_NAME[attribute],
          key: attribute,
          value:
            attribute === PAM_ATTRIBUTES_KEYS.TECHNICAL_OPINION_OR_DTU_NUMBER
              ? att?.values[0]?.value.replace("\"", " ").replace("\®\"", " ")
              : attribute === PAM_ATTRIBUTES_KEYS.ACTIV_AIR ?
                (att?.values[0]?.value === 'True' ? 'Vrai' : 'Faux')
                : attribute === PAM_ATTRIBUTES_KEYS.NAME_OF_INSULATION
                  ? attributes.find(
                    (attribute) =>
                      attribute.technicalName.includes(
                        PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
                      ) &&
                      (attribute.values[0]?.value.includes('PAR') ||
                        attribute.values[0]?.value.includes('Isoconfort') ||
                        attribute.values[0]?.value.includes('GR 32') ||
                        attribute.values[0]?.value.includes('IBR') ||
                        attribute.values[0]?.value.startsWith('Laine'))
                  )?.values[0]?.value
                  : att?.values[0]?.numericValue ||
                  att?.values[0]?.value?.split('"').join("") ||
                  null,
        };
      }),
    }));
  return {
    chapterName: chapterName,
    chapterData: attributesByChapter,
  };
};

export const fillConfigurationModal = (system, t) => {
  const SystemConfiguration = {
    systemType: system.attributes.find(
      (attribute) =>
        attribute.technicalName ===
        PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION
    )?.values[0]?.value,
    parement: fillParmentConfiguration(system, t),
    profile: system.attributes.find(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D
    )?.values[0]?.value,
    frameDistance: system.attributes.find(
      (attribute) =>
        attribute.technicalName ===
        PAM_ATTRIBUTES_TECHNICAL_NAME.CENTER_DISTANCE_BETWEEN_FRAMES_M
    )?.values[0]?.value,
    rail: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) && attribute.values[0]?.value.includes("Rail")
      )?.values[0]?.value,
      value: "",
    },
    montant: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) && attribute.values[0]?.value.includes("Montant")
      )?.values[0]?.value,
      value: "",
    },
    isolation: {
      name: system.attributes.find(
        (attribute) =>
          attribute.technicalName.includes(
            PAM_ATTRIBUTES_TECHNICAL_NAME.PRODUCT
          ) &&
          (attribute.values[0]?.value.includes('PAR') ||
            attribute.values[0]?.value.includes('Isoconfort') ||
            attribute.values[0]?.value.includes('GR 32') ||
            attribute.values[0]?.value.includes('IBR') ||
            attribute.values[0]?.value.startsWith('Laine'))
      )?.values[0]?.value,
      value: "",
    },
  };
  return SystemConfiguration;
};

const fillParmentConfiguration = (system, t) => {
  const configurationModel = [];
  const bimLayers = system.attributes
    .filter(
      (attribute) =>
        attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.BIM_LAYERS
    )
    .filter(
      (layer) =>
        !layer.subAttributes
          .find(
            (attribute) =>
              attribute.technicalName ===
              PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NAME
          )
          ?.values[0]?.value.includes("Mur support")
    );
  let ParmentNumber = 1;
  for (
    let currentLayerNumber = 1;
    currentLayerNumber < bimLayers.length + 1;
    currentLayerNumber++
  ) {
    const currentLayer = bimLayers.find(
      (lay) =>
        lay.subAttributes.find(
          (attribute) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NUMBER
        )?.values[0]?.numericValue === currentLayerNumber
    );
    if (
      currentLayer?.subAttributes.find(
        (attribute) =>
          attribute.technicalName ===
          PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
      )?.values[0]?.value === "Plaque de plâtre"
    ) {
      configurationModel.push({
        parmentNumber: ParmentNumber,
        layerThickness: currentLayer.subAttributes.find(
          (attribute) =>
            attribute.technicalName ===
            PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_THIKNESS_MM
        )?.values[0]?.value,
        layerName: currentLayer.subAttributes.find(
          (attribute) =>
            attribute.technicalName === PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_NAME
        )?.values[0]?.value,
      });
    }
    if (
      currentLayer?.subAttributes.find(
        (attribute) =>
          attribute.technicalName ===
          PAM_ATTRIBUTES_TECHNICAL_NAME.LAYER_CONTENT
      )?.values[0]?.value !== "Plaque de plâtre" &&
      configurationModel.filter((p) => p.parmentNumber === 1).length > 0
    ) {
      ParmentNumber = 2;
    }
  }
  return configurationModel;
};