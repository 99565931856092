import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

function RangeComponentHeader({ t }: IProps) {
    return (
        <thead className="w-100">
            <tr className="w-100">
                <th>
                    <span>{t("PIPE_SYSTEM_TYPE_TO_IMPORT")}</span>
                </th>
                <th>
                    <span>{t("ELEMENTS_AVAILABLE")}</span>
                </th>
                <th>
                    <span>{t("ELEMENTS_SELECTION_FOR_DATA_IMPORT")}</span>
                </th>
            </tr>
        </thead>
    );
}

type IProps = WithTranslation;

export default withTranslation(["pam"])(RangeComponentHeader);
