import { getDate, getProjectId } from ".";
import axios from "axios";
import { fetchPlacoSolutionsDetails } from "../../../../API";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import _, { compact, filter, find, map } from "lodash";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";
import { wait } from "../../../../RevitJS/Helpers";
export const GeneratePDFPhotoBox = async (
  docType: any,
  itemData: any,
  config: any
) => {
  try {
    var refId = getDate().replace(/\//g, "") + new Date().getTime();

    let cctpList: any = [];
    const solutionIds: any = compact(
      itemData.data.list.map((a: any) =>
        !a.isNonPlaco && a.isSelected ? a.Solution.oid : ""
      )
    );
    const { data } =
      solutionIds.length > 0
        ? await fetchPlacoSolutionsDetails(solutionIds, config).catch(
          (error) => {
            throw error;
          }
        )
        : { data: { objects: [] } };

    let notAvailableSolutions: string[] = [];

    const selectedItemDataList = filter(itemData.data.list, (dl) => {
      return dl.isSelected;
    });

    /// cctpList
    selectedItemDataList.forEach((item: any) => {
      if (item.isSelected) {
        if (item.isNonPlaco === false) {
          const placoSolution = data.objects.find(
            (a: any) => a.oid === item.Solution.oid
          );

          if (!placoSolution) {
            notAvailableSolutions.push(item.Solution.label);
          } else {
            let alfrescoNodeId: string = "";
            let solutionFileName: string = "";
            let userDocuments: any[] = [];
            let ficheFile = find(item.files, { fileType: "Fiche système" });
            alfrescoNodeId = ficheFile ? ficheFile.fileid : "";
            solutionFileName = ficheFile ? ficheFile.screenName : "";

            userDocuments = _.filter(item.files, (file) => {
              return file.fileType !== "Fiche système";
            });

            if (alfrescoNodeId !== "") {
              cctpList.push({
                cctp: item.CCTPReference,
                solutionId: "",
                solutionName: solutionFileName,
                solutionLongName: item.Solution.label,
                alfrescoNodeId: alfrescoNodeId.split(";")[0],
                isManualUpload: true,
                userDocuments: _.map(userDocuments, (file) => {
                  return {
                    alfrescoNodeId: file.fileid.split(";")[0],
                    documentName: file.screenName,
                    documentType: file.fileType.includes("PV_")
                      ? "PV"
                      : file.fileType,
                  };
                }),
              });
            } else {
              cctpList.push({
                cctp: item.CCTPReference,
                solutionId: placoSolution?.externalId,
                solutionName: placoSolution.technicalName?.split("-")[0],
                solutionLongName: item.Solution.label,
                isManualUpload: placoSolution?.externalId ? false : true,
                userDocuments: _.map(userDocuments, (file) => {
                  return {
                    alfrescoNodeId: file.fileid.split(";")[0],
                    documentName: file.screenName,
                    documentType: file.fileType.includes("PV_")
                      ? "PV"
                      : file.fileType,
                  };
                }),
                //isManualUpload: false,
              });
            }
          }
        } else if (item.isNonPlaco) {
          let alfrescoNodeId: string = "";
          let solutionFileName: string = "";
          let userDocuments: any[] = [];
          const ficheFile = find(item.files, { fileType: "Fiche système" });
          alfrescoNodeId = ficheFile ? ficheFile.fileid : "";
          solutionFileName = ficheFile ? ficheFile.screenName : "";

          userDocuments = _.filter(item.files, (file) => {
            return file.fileType !== "Fiche système";
          });
          if (alfrescoNodeId !== "") {
            cctpList.push({
              cctp: item.CCTPReference,
              solutionId: "",
              solutionName: solutionFileName,
              solutionLongName: item.Solution.label,
              alfrescoNodeId: alfrescoNodeId.split(";")[0],
              isManualUpload: true,
              userDocuments: _.map(userDocuments, (file) => {
                return {
                  alfrescoNodeId: file.fileid.split(";")[0],
                  documentName: file.screenName,
                  documentType: file.fileType.includes("PV_")
                    ? "PV"
                    : file.fileType,
                };
              }),
            });
          }
        }
      }
    });

    if (notAvailableSolutions && notAvailableSolutions.length > 0) {
      localStorage.setItem(
        "notAvailableSolutions",
        JSON.stringify(notAvailableSolutions)
      );
    }

    let documents = [];
    if (docType === "DAE" || docType === "DOE") {
      documents = itemData.downloadDAE
        .filter((dt: any) => dt.isSelected)
        .map((dt: any) => dt.key);
    }

    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );
    let ddt = tempProjectInfo === null ? {} : tempProjectInfo;
    const documentName = await getProjectId();
    let modelInfo: any = ddt.PROJECT_INFO_FORM.modelInfo.find(
      (a: any) => a.model_name === documentName
    );

    const requestBody = {
      referenceId: refId,
      requestId: refId,
      service: "BIM",
      companyName:
        ddt.PROJECT_INFO_FORM.companyInfo.information_enterprise_name,
      companyStreet1: "",
      companyStreet2: "",
      companyZipCode:
        ddt.PROJECT_INFO_FORM.companyInfo.information_enterprise_postal,
      companyCity:
        ddt.PROJECT_INFO_FORM.companyInfo.information_enterprise_villa,
      fileType: docType === "DOE" ? "DAE" : docType,
      projectName: modelInfo.information_chantier_referencecctp,
      siteStreet1: modelInfo.information_chantier_address,
      siteStreet2: "",
      siteZipCode: modelInfo.information_chantier_postal,
      siteCity: modelInfo.information_chantier_villa,
      alfrescoFileFullPath: "",
      siretNumber: "12345678901234",
      date: modelInfo.information_chantier_add_date.split("T")[0],
      rfpReference: "454365",
      documentType: documents,
      reference: "191230TOUTF1220335",
      cctpList: cctpList,
      // logoAlfrescoNodeId: nodeId ? nodeId : "",
    };
    return photoboxRequest("/generatePDF", requestBody, config).catch(
      (error) => {
        console.log("in generate error is:", error);
        throw error;
      }
    );
  } catch (error) {
    throw error;
  }
};

export async function photoboxRequest(
  url: string,
  body: any,
  cng: any,
  get: boolean = false
) {
  try {
    let token = localStorage.getItem("token");
    let bddInstance = axios.create({
      baseURL: cng.REACT_APP_PHOTOBOXAPIURL_EXTERNAL,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    });

    bddInstance.interceptors.request.use(async (request) => {
      return await authFilter(request, cng);
    });

    bddInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (!originalRequest._retry || originalRequest._retry < 8) {
          originalRequest._retry =
            originalRequest._retry || originalRequest._retry >= 0
              ? originalRequest._retry + 1
              : 0;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
          axios.defaults.headers.common["Content-Type"] = `application/json`;
          await wait(1000);
          return bddInstance(originalRequest);
        }

        console.log("error in photoboxapi:", error);
        // if (originalRequest._retry === 8) {
        //   window.location.href = cng.REACT_APP_SERVERURL + "#/home";
        //   return;
        // }
        return Promise.reject(error);
      }
    );

    if (get) {
      return await bddInstance.get(url);
    } else {
      return await bddInstance.post(url, body);
    }
  } catch (error) {
    throw error;
  }
}

export async function photoboxRequestBlob(
  url: string,
  body: any,
  cng: any,
  get: boolean = false
) {
  let token = localStorage.getItem("token");

  let bddInstance = axios.create({
    baseURL: `${cng.REACT_APP_FILEMANAGEMENTAPIURL_INTERNAL?.split("/")
      ?.slice(0, -1)
      ?.join("/")}/plugins/authenticated`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + token,
    },
    timeout: -1,
  });

  bddInstance.interceptors.request.use(async (request) => {
    return await authFilter(request, cng);
  });

  bddInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (!originalRequest._retry || originalRequest._retry < 8) {
        originalRequest._retry =
          originalRequest._retry || originalRequest._retry >= 0
            ? originalRequest._retry + 1
            : 0;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
        await wait(1000);
        return bddInstance(originalRequest);
      }

      if (originalRequest._retry === 8) {
        // window.location.href = config.REACT_APP_SERVERURL + "#/home";
        return;
      }
      return Promise.reject(error);
    }
  );
  if (get) {
    return await bddInstance.get(url, body);
  } else {
    return await bddInstance.post(url, body);
  }
}

export const CheckFilePDFPhotoBox = async (response: any, config: any) => {
  return await photoboxRequest("/checkFile", response, config).catch(
    (error) => {
      throw error;
    }
  );
};

export const DownloadPDFPhotoBox = async (response: any, config: any) => {
  var raw = JSON.stringify(response);
  let file: any = await photoboxRequestBlob(
    "/photobox/prepare",
    raw,
    config
  ).then((result) => {
    return result.data;
  });
  console.log("file :", file);
  if (file === "[]" || file.length === 0) {
    return null;
  } else {
    let token = localStorage.getItem("token");
    // if array contains only one value means direct download can be done
    if (file.length === 1 && file[0].substr(file[0].length - 3) === "pdf") {
      return photoboxRequestBlob(
        `/downloadPrepared/${file[0]}`,
        {
          responseType: "arraybuffer",
        },
        config,
        true
      );
    } else {
      console.log("downloadPhotoboxMultipartFile :");
      let pdfstring = await api.queries.downloadPhotoboxMultipartFile(
        `${config.REACT_APP_FILEMANAGEMENTAPIURL_INTERNAL?.split("/")
          ?.slice(0, -1)
          ?.join("/")}/plugins/authenticated`,
        `${file}`,
        `${response.requestId}`,
        "pdf",
        `Bearer ${token}`
      );
      console.log("pdfstring :", pdfstring);
      //const base64 = btoa(decodeURI(encodeURIComponent(pdfstring)));

      // return axios.get(`data:application/pdf;base64,${pdfstring}`, {
      //   responseType: "arraybuffer",
      // });
      if (pdfstring.includes("Downloaded")) {
        return pdfstring;
      }
      else {
        return axios.get(`data:application/pdf;base64,${pdfstring}`, {
          responseType: "arraybuffer",
        });
      }
    }
  }
};

export const UploadFilesToPhotobox = async (
  fileData: any,
  name: string,
  cancelToken: any,
  config: any
) => {
  let formData: FormData = new FormData();
  formData.append("file", fileData);
  formData.append("fileName", name);
  //photoboxRequest.defaults.cancelToken = cancelToken;
  const response = await photoboxRequest(
    "/uploadFile?fileName=" + name,
    formData,
    config
  );
  return response;
};
