import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { connect } from "react-redux";
import {
  fetchRigipsData,
  getSystemDetailsById,
} from "../Service/project.service";
import {
  ProductDetailData,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import { getColor } from "../Utils/helpers";
import { MyConfig } from "../../../../Helper";

export const albaSystemsList = [
  "1-A.0",
  "1-AH.0",
  "1-A.1",
  "1-AH.1",
  "1-AB",
  "1-A.2",
  "1-AH.2",
  "3-A.0",
  "3-AP",
  "3-AT",
  "3-A.1.1",
  "3-A.1x",
  "3-A.1.2",
  "3-A.1.1",
  "5-A.0.1",
  "5-A.1.1",
];

export const rigipsSystemsList = [
  "1-AR",
  "1-DL",
  "1-DLI",
  "1-DTI",
  "1-DT",
  "1-GRF",
  "1-GRH",
  "1-RB",
  "1-RBI",
  "1-RBS",
  "1-RDH",
  "1-RF",
  "1-RFI",
  "1-HA",
  "1-XR",
  "3-AR",
  "3-DL",
  "3-GRF",
  "3-RB",
  "3-GRH",
  "3-RBS",
  "3-RDS",
  "3-RDH",
  "3-DT",
  "3-HA",
  "3-RF",
  "3-XR",
  "5-RF",
  "5-AR",
  "5-DL",
  "5-GRF",
  "5-RDH",
  "5-RF",
];

export const startInList = (start: string | null, checkList: string[]) => {
  if (start === null) return false;
  for (let i = 0; i < checkList.length; i++) {
    if (start.startsWith(checkList[i])) return true;
  }
  return false;
};

function compare(a: ProductMeta, b: ProductMeta) {
  if (a.translation < b.translation) {
    return -1;
  }
  if (a.translation > b.translation) {
    return 1;
  }
  return 0;
}

export const colorization = async (config: any) => {
  const plasterboardIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: { Name: "Name", Type: "Builtin", Value: "Rigips_Plaque" },
        Rule: "Equals",
      },
    ],
    null
  );

  // const horizontalboardIds = await api.queries.filterElements(
  //   "Generic",
  //   [
  //     {
  //       Param: { Name: "Name", Type: "Builtin", Value: "Rigips_HorizontalBoard" },
  //       Rule: "Equals",
  //     },
  //   ],
  //   null
  // );

  const plasterboardDetails = await api.queries.getObjectsParams(
    plasterboardIds,
    [
      "id",
      "PlasterboardHeight",
      "PlasterboardLength",
      "PlasterboardWidth",
      "name",
    ]
  );

  // const horizontalboardDetails = await api.queries.getObjectsParams(
  //   horizontalboardIds,
  //   [
  //     "id",
  //     "HorizontalboardHeight",
  //     "HorizontalboardLength",
  //     "HorizontalboardWidth",
  //     "name",
  //   ]
  // );

  // const horizontalboardsPerName = horizontalboardDetails.reduce(function (
  //   a: { [x: string]: any[] },
  //   c: { Params: { Value: string | number }[] }
  // ) {
  //   a[c.Params[4].Value] = a[c.Params[4].Value] || [];
  //   a[c.Params[4].Value].push(c);
  //   return a;
  // },
  //   Object.create(null));
  // console.log("horizontalboardsPerName: ", horizontalboardsPerName)
  const plasterboardsPerName = plasterboardDetails.reduce(function (
    a: { [x: string]: any[] },
    c: { Params: { Value: string | number }[] }
  ) {
    a[c.Params[4].Value] = a[c.Params[4].Value] || [];
    a[c.Params[4].Value].push(c);
    return a;
  },
  Object.create(null));

  let systems = await fetchRigipsData(config);

  let products = systems.filter((sys) =>
    startInList(sys.translation, albaSystemsList.concat(rigipsSystemsList))
  );
  // api.windowsHandler.closeWindow()
  products = products.sort((a, b) => compare(a, b));

  // let keys = [...Object.keys(plasterboardsPerName), ...Object.keys(horizontalboardsPerName)];
  let keys = Object.keys(plasterboardsPerName);
  for (let i = 0; i < keys.length; i++) {
    let color_Plaque;
    let plasterboards = plasterboardsPerName[keys[i]];
    let idElement = plasterboards[0].Params.find(
      (param: { Name: string }) => param.Name === "id"
    ).Value;
    // api.windowsHandler.showDevTools()
    console.log("idElement: ", idElement);
    let productElement = (
      await api.queries.getObjectsParams([idElement], ["SG_System"])
    )[0].Params.find(
      (param: { Name: string }) => param.Name === "SG_System"
    ).Value;
    console.log("productElement: ", productElement);
    let product = products.find((p) => p.longName === productElement);
    if (product) {
      color_Plaque = await getColor(product.oid);
    }
    console.log("product: ", product);
    let rgbColor;
    if (color_Plaque) {
      rgbColor = color_Plaque;
      let ids = plasterboards.map((el: { Id: any }) => el.Id);
      let filter = await api.queries.createSelectionFilter(keys[i], ids);
      console.log("rgbColor: ", rgbColor);
      await api.viewHandler.setFilterColor(filter, rgbColor);
    }
  }
};

interface Props {
  language: string;
  setRoute: any;
}

class Waiter extends Component<Props> {
  state = {
    distance: 400,
  };

  componentDidMount = async () => {
    const config = await MyConfig();
    try {
      await colorization(config);
      this.sendEvent();
    } catch (e) {
      console.log("Error in Colorization Rigips!");
    }
    api.windowsHandler.closeWindow();
  };

  async sendEvent() {
    await api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Colorize",
      module: "RIGIPSBIM",
    });
  }

  render = () => {
    return (
      <Dimmer active={true}>
        <Loader content={"Colorisation en cours..."} />
      </Dimmer>
    );
  };
}

const mapStateToProps = (state: { language: any }) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(Waiter);
