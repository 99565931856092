import React from "react";
import { List, Image, Header } from "semantic-ui-react";
import { ID } from "../../RevitJS/Types/RevitTypes";
import { Action } from "redux";
import { api } from "../../RevitJS/API";
import { bimStorage, storageKey } from "../../BIMStore";
import _, { find } from "lodash";
import { useDispatch } from "react-redux";
import { MyConfig } from "../../Helper";

interface PluginLauncherProps {
  ShortDesc: { [key: string]: string };
  Id: ID;
  Icon: string;
  Name: string;
  language: string;
  selectPlugin: (id: string) => Action;
  Color: string;
  TechnicalName: string;
  config: any;
}

export const licenseManualTrigger = async (
  technicalName: string,
  selectPlugin: any,
  Id: string,
  dispatch: any,
  config: any
) => {
  localStorage.setItem("TechnicalName", technicalName);

  let isactive: boolean = await api.license.isActive(technicalName, config);

  let applicationList = await bimStorage.getItem(
    storageKey.ALL_APPLICATION_DATA
  );
  let application: any = find(applicationList, function (app) {
    return app.applicationTechnicalName === technicalName;
  });

  if (!isactive && application) {
    if (application.premium) {
      api.windowsHandler.openWindow(
        320,
        280,
        `${config.REACT_APP_SERVERURL}plugin/a1904a90-8130-4e50-8412-d03751af7d7c/58a79ec5-15a7-4c37-9862-debee03341c8`
      );
    } else {
      dispatch(selectPlugin(Id));
    }
  } else {
    dispatch(selectPlugin(Id));
  }
};

export const PluginLauncher = (props: PluginLauncherProps) => {
  const { ShortDesc, language, TechnicalName } = props;
  const shortDesc = ShortDesc[language] || ShortDesc["English"];
  const dispatch = useDispatch();

  return (
    <List.Item style={{ padding: "0px 0px 20px 0px" }}>
      <Image
        floated="left"
        style={
          props.Name === "Placo® BIM"
            ? { width: 50, height: 45, zIndex: -1 }
            : { width: 50, height: 50, zIndex: -1 }
        }
        src={props.Icon}
      />
      <List.Content>
        <List.Header
          style={{ fontSize: "90%" }}
          onClick={(e: any) => {
            //props.selectPlugin(props.Id);
            licenseManualTrigger(
              TechnicalName,
              props.selectPlugin,
              props.Id,
              dispatch,
              props.config
            );
          }}
        >
          <Header style={{ cursor: "pointer", color: props.Color }}>
            {props.Name}
          </Header>
        </List.Header>
        <List.Description style={{ fontSize: "90%" }}>
          {shortDesc}
        </List.Description>
      </List.Content>
    </List.Item>
  );
};
PluginLauncher.defaultProps = {
  Name: "Bim Studio Library",
  Icon: "/saint-gobain-bim.png",
  ShortDesc: {
    French: "A collection of JS bindings for Revit API",
    English: "Modules Javascript sur l'API de Revit",
  },
  language: "German",
};
export default PluginLauncher;
