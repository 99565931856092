import React from "react";
import { Button, Modal } from "semantic-ui-react";

export const SaveConfigurationModal = (props: any) => {
  return (
    <Modal open={props.saveModal} size="mini" dimmer="blurring">
      <Modal.Header className="modalHeader">Sauvegarde</Modal.Header>
      <Modal.Description
        style={{ textAlign: "center", padding: "15px 0px", color: "black" }}
      >
        <p>{props.saveNumConfig} </p>
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button
          color="orange"
          size="tiny"
          onClick={() => {
            props.closeDimmer();
          }}
        >
          {props.no}
        </Button>
        <Button
          size="tiny"
          type="submit"
          primary
          // onClick={() => {
          //   props.saveAction();
          // }}
        >
          {props.yes}
        </Button>
      </div>
    </Modal>
  );
};
