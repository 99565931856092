import React, { useEffect } from "react";
import { FunctionalityHeader } from "../../../../Selection/Components/FunctionalityHeader";
import { Container, Button, Grid } from "semantic-ui-react";
import { api } from "../../../../../../RevitJS/API";
import { Routes } from "../../root";

interface Props {
  elementType: string;
  setElementType: any;
}

const ElementType = (props: Props) => {
  return (
    <>
      <FunctionalityHeader
        Icon={""}
        name={"QUANTITATIFS PAR RATIO"}
        subheader=""
      />
      <Container textAlign="center" style={{ marginTop: 20 }}>
        <Grid columns={2} padded={true}>
          <Grid.Row style={{ padding: "2.5rem 0.5rem 1.5rem" }}>
            <Grid.Column>
              <Button
                style={{ minHeight: 80 }}
                fluid
                color="blue"
                onClick={() => props.setElementType("wall")}
              >
                Murs
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button
                style={{ minHeight: 80 }}
                fluid
                color="blue"
                onClick={() => props.setElementType("ceiling")}
              >
                Plafonds
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default ElementType;
