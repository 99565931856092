import React, { useEffect } from "react";
import { TopHeader } from "../../Components/Headers";
import { Button, Grid, Icon } from "semantic-ui-react";
import { HomeTable } from "../Components/Table/Home/HomeTable";
import { NamePopup } from "../../Components/Popup";
import { useState } from "react";
import { map, forEach, isEqual } from "lodash";
import { bimStorage } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import SaveProject from "../../CommonModules/SaveProject";
import updateSelectionIfWallDeleted from "../../CommonModules/Helpers/selectionUpdateHelper";
import {
  createNewCalepinage,
  calpinageNameChangeUtil,
  createCalepinableRow,
  checkForWallTypeAndHeight,
} from "../utils/calepinage";

interface Props {
  setRoute: any;
  onCalepinageUpdate: (object: any) => void;
}

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0em",
  },
  height_435: {
    height: "435px",
  },
  height_486: {
    height: "calc(100vh - 50px)",
  },
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200)",
  },
  header_row: {
    padding: 5,
    height: 30,
    backgroundColor: "rgb(33, 133, 208)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    // fontWeight: 700,
    display: "flex",
    width: "40%",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    // fontWeight: 700,
    display: "flex",
    width: "6%",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    // fontWeight: "700",
    display: "flex",
    width: "24%",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    width: "17%",
    color: "black",
  },
};

export const Home = (props: Props) => {
  const { setRoute, onCalepinageUpdate } = props;
  const [namePopupShowHide, setNamePopupShowHide] = useState<boolean>(false);
  const [calepinageList, setCalepinageList] = useState<any[]>([]);
  const [savePopupShowHide, setSavePopupShowHide] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editedNameValue, setEditedNameValue] = useState("");

  const listModules = async () => {
    const modules = await bimStorage.listCalepinage();
    if (modules) {
      setCalepinageList(modules);
    } else {
      setSavePopupShowHide(true);
    }
  };
  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };
  useEffect(() => {
    const getProjectId = async () => {
      const projectId = await bimStorage.onProjectData();
      if (projectId) {
        listModules();
      } else {
        toggleModal();
      }
    };
    getProjectId();
  }, []);

  const onRowDelete = async (id: any) => {
    const response = await bimStorage.deleteCalepinage(id);
    if (response) {
      listModules();
    }
  };

  const onRowEdit = async (id: any) => {
    const calepi = await new Promise((resolve, reject) =>
      window.indec.getCalepinage(parseInt(id), resolve, reject)
    )
      .then((x: any) => {
        return x;
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      });
    //await bimStorage.getCalepinage(id);
    const ifHeightChanged = await checkForWallTypeAndHeight(calepi);
    if (ifHeightChanged) {
      if (ifHeightChanged === true) {
        const response = await createCalepinableRow(calepi.SelectionIds[0]);
        if (response.length > 0) {
          let calObject = {
            ...calepi,
            SelectionIds: [calepi.SelectionIds[0].toString()],
            SelectionStatus: true,
            CalepinerStatus: false,
            CalepinageDetails: response,
          };
          onCalepinageUpdate(calObject);
        }
      } else {
        let calObject = {
          ...calepi,
          SelectionIds: "",
          SelectionStatus: false,
          CalepinerStatus: false,
          CalepinageDetails: "",
        };
        onCalepinageUpdate(calObject);
      }
    } else {
      onCalepinageUpdate(calepi);
    }
    setRoute("DETAIL");
  };

  const onNameSubmit = async (name: string) => {
    const response = await createNewCalepinage(name);

    onCalepinageUpdate(response);
    setNamePopupShowHide(false);
    setRoute("DETAIL");
  };
  const editCalpinageName = (Id: string, Name: string) => {
    setErrorMessage("");
    setEditId(Id);
    setEditName(Name);
  };
  const updateCalpinagename = async (id: any, name: string) => {
    let errorMessage = "";
    if (name === "") {
      errorMessage = "Nom de layout invalide";
    } else if (name.trim().length < 5) {
      errorMessage = "Texte de 5 caractères minimum";
    }
    forEach(calepinageList, (value) => {
      if (
        value.Name.toLowerCase() === name.trim().toLowerCase() &&
        id !== value.Id //&&
        // value.RevitView === currentRevit
      ) {
        errorMessage = "Une autre layout existe déjà sous ce nom";
      }
    });
    if (errorMessage === "") {
      const response = await calpinageNameChangeUtil(id, name);
      if (response) {
        editCalpinageName("", "");
        listModules();
      }
    } else {
      setErrorMessage(errorMessage);
    }
  };
  return (
    <div>
      <div style={{ height: "100%" }}>
        <TopHeader Icon={""} name={"LAYOUT"} />
        <Grid
          columns={1}
          stackable
          style={{
            ...style.tableSegment,
            ...style.height_486,
            marginTop: "0em",
          }}
        >
          <Grid.Column>
            <HomeTable
              data={calepinageList}
              onRowEdit={onRowEdit}
              onRowDelete={onRowDelete}
              editId={editId}
              editName={editName}
              setEditName={setEditName}
              editCalpinageName={editCalpinageName}
              updateCalpinagename={updateCalpinagename}
              errorMessage={errorMessage}
              setEditedNameValue={setEditedNameValue}
            />
            <span
              style={{
                padding: "10px 10px 10px 0",
                cursor: "pointer",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setNamePopupShowHide(true)}
            >
              <Icon name="plus circle" color="blue" size="big" />
              <span style={{ color: "rgb(14,110,184)" }}>New Layout</span>
            </span>
          </Grid.Column>
        </Grid>
        <NamePopup
          show={namePopupShowHide}
          onSave={onNameSubmit}
          onClose={() => setNamePopupShowHide(false)}
          rules={{
            length: true,
            lengthValue: 5,
            duplicate: true,
            duplicateList: map(calepinageList, "Name"),
          }}
          headerName={"Configuration Name"}
        />
        <SaveProject
          toggleModal={toggleModal}
          confirmModal={confirmModal}
          onYesAction={onYesAction}
        />
      </div>
    </div>
  );
};

export default Home;
