export const SET_MASTER_FRAME_BUFFER = "SET_MASTER_FRAME_BUFFER";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const RESET_LANGUAGE = "RESET_LANGUAGE";
export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_SELECTION_BUFFER = "SET_SELECTION_BUFFER";
export const SET_PARAMETER_BUFFER = "SET_PARAMETER_BUFFER";
export const SET_CONTEXT_BUFFER = "SET_CONTEXT_BUFFER";
export const SET_OPTIONS_BUFFER = "SET_OPTIONS_BUFFER";
export const SET_BUFFER_DETAILS = "SET_BUFFER_DETAILS";
export const SET_BUFFER_DATE_DETAILS = "SET_BUFFER_DATE_DETAILS";
export const SET_MODULE_DATA = "SET_MODULE_DATA";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";
export const SET_EDIT_BUFFER = "SET_EDIT_BUFFER";
export const DELETE_FACADE_CALEPINAGE = "DELETE_FACADE_CALEPINAGE";
export const UPDATE_SELECTION_NAME = "UPDATE_SELECTION_NAME";
export const UPDATE_FACADE_CALEPINAGE = "UPDATE_FACADE_CALEPINAGE";