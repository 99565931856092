import React from "react";
import { Grid, Header, Menu } from "semantic-ui-react";
import { LevelItems } from "./LevelItems";
import LevelsToogler from "./LevelsToogler";

export class LevelsColumn extends React.Component {
  state = {
    selection: null,
  };

  componentDidMount() {}

  render() {
    return (
      <Grid.Column>
        <Header as="h3" textAlign="center" color={this.props.color}>
          {this.props.columnTitle}
        </Header>
        <div style={{ maxHeight: "260px", overflow: "auto" }}>
          <Menu style={{ height: "260px" }} vertical size="small" fluid>
            <LevelItems
              selectLevel={this.props.selectLevel}
              levelsData={this.props.levelsData}
            />
          </Menu>
        </div>
        <LevelsToogler
          toogleLevels={this.props.toogleLevels}
          toogleOn={!this.props.selectedLevels.length}
        />
      </Grid.Column>
    );
  }
}
