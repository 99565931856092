import { ThunkAction } from "redux-thunk";
import { CustomParameter, SystemsActionTypes } from "./types";
import * as productsService from "../../services/project.service";
import {
  loadSystemsRequest,
  loadSystemsSuccess,
  loadSystemsError,
  loadSystemsToUpdateSuccess,
} from "./actions";
import { DrawStore } from "../reducers";
import { ID } from "../../../../../RevitJS/Types/RevitTypes";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import _ from "lodash";

type Effect = ThunkAction<any, DrawStore, any, SystemsActionTypes>;

export const loadSystems =
  (t: any, config: any): Effect =>
  async (dispatch: any, getState: any) => {
    dispatch(loadSystemsRequest());
    let systems: any[] = [];
    let customParameterResult2 =
      (await productsService.getCustomParametersFromElementType()) as unknown as CustomParameter[];
    await productsService
      .loadProjects(config)
      .then((results: any[]) => {
        systems = results;
        dispatch(loadSystemsError());
      })
      .catch(() => dispatch(loadSystemsError()));
    dispatch(loadSystemsToUpdateSuccess(customParameterResult2));
    // const test = "Cloison distributive Placostil® 120/70 - 2xAquaroc® 13 - EI90 - 52dB - 4,15m - avec isolant"
    // systems = systems.filter(system =>  system.translation.replace(" ", "").includes(test.replace(" ", "")))
    systems = systems?.filter((system) =>
      customParameterResult2?.some((currentSystem) =>
        currentSystem?.solution
          .replace(" ou techniquement équivalent", "")
          .includes(system.translation)
      )
    );

    customParameterResult2 = customParameterResult2.filter((system) =>
      systems?.some((currentSystem) =>
        system?.solution
          .replace(" ou techniquement équivalent", "")
          .includes(currentSystem.translation)
      )
    );

    const systemIds = systems.map((product: { oid: ID }) => product.oid);
    if (systemIds && systemIds.length > 0)
      await productsService
        .getSystemDetailsById(systemIds, config)
        .then((results) => {
          let currentSystemDetail = results.data.objects.map((sys) => {
            return { ...sys, filterFields: {} } as unknown;
          }) as ProductDetailData[];
          let data = {
            liningWallsDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                valueObject[0].value === "Isolation des murs" &&
                customParameterResult2.some((s) =>
                  s?.solution.includes(system.translation)
                )
              );
            }),
            partitionsDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                valueObject[0].value === "Cloisons" &&
                customParameterResult2.some((s) =>
                  s?.solution.includes(system.translation)
                )
              );
            }),
            ceilingsDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                (valueObject[0].value === "Plafonds décoratifs" ||
                  valueObject[0].value === "Plafonds") &&
                customParameterResult2.some((s) =>
                  s?.solution.includes(system.translation)
                )
              );
            }),
            gainestechniqueswallDetails: currentSystemDetail.filter((system) => {
              const gfrWorksNameObject = _.find(system.attributes, {
                technicalName: "GFR-Works name",
              });
              const valueObject: any = _.get(gfrWorksNameObject, "values");
              return (
                (valueObject[0].value.includes('gaines')) &&
                customParameterResult2.some((s) =>
                  s?.solution.includes(system.translation)
                )
              );
            }),
          };
          dispatch(loadSystemsSuccess(currentSystemDetail, systems, data));
        });
  };
