import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Dimmer, Image, Loader, Segment } from "semantic-ui-react";

function PixelLoader({ shortParagraph = false }: IProps) {
    return (
        <Segment className="m-auto">
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
            <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
        </Segment>
    );
}

type IProps = WithTranslation & {
    shortParagraph?: boolean;
};

export default withTranslation(["pam"])(PixelLoader);
