import React from "react";
import { Menu, Label, Checkbox } from "semantic-ui-react";
import { SelectionStore } from "../Reducers";
import { selectType } from "../Actions";
import { connect, ConnectedProps } from "react-redux";
import "../Resources/groupselector.css";
import { selectorTp } from "../Actions/types";

const mapState = (
  state: SelectionStore,
  ownProps: {
    checked: boolean;
    type: string;
    count: number;
    selectedLevels: string[];
  }
) => ({
  checked: ownProps.checked,
  count: ownProps.count,
  selectorType: state.selectorType,
  type: ownProps.type,
  selectedLevels: ownProps.selectedLevels,
});

const mapDispatch = {
  selectType,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  checked: boolean;
  selectedLevels: string[];
  selectorType: selectorTp;
  type: string;
  count: number;
};

export class TypeItem extends React.Component<Props> {
  handleItemClick = () => {};

  render() {
    return (
      <Menu.Item
        name="inbox"
        onClick={this.handleItemClick}
        style={{ padding: "8px 15px" }}
        className="GroupSelectorMenu"
      >
        <Label color="blue">{this.props.count}</Label>
        <Checkbox
          disabled={this.props.selectedLevels.length < 1 ? true : false}
          onChange={(e, data) => {
            this.props.selectType(data.label as string);
          }}
          checked={this.props.checked}
          style={{ maxWidth: "80%" }}
          label={`${this.props.type}`}
        />
      </Menu.Item>
    );
  }
}

export default connector(TypeItem);
