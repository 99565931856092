import {
  ceil,
  compact,
  filter,
  forEach,
  get,
  groupBy,
  includes,
  keyBy,
  map,
  round,
  sortBy,
  startsWith,
  uniqWith,
  isEqual,
} from "lodash";
import find from "lodash/find";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { setUnitConversionArray } from "../Actions";
import {
  Accessory,
  AccessoryArticle,
  Montant,
  MontantArticle,
  NewPlaqs,
  PlaqArticle,
  Plaqs,
  RailArticle,
  Rails,
} from "../Actions/types";
import { IRationTable } from "../Components/TypeOfQuantitatifs/RatioTable";
import { getLayoutAttributesAid } from "../Requests";
import {
  alternativeUnit,
  alternativeUnitsSelector,
  coatingList,
  dbSalesUnit,
  distributionChannelsAttributeSelector,
  findProductToProduct,
  numeralConversion,
  packageAttributeSelector,
  relationAttributeSelector,
  screwList,
  uniteDeVenteArray,
} from "./helper";
import {
  extractFramesDatafromRevit,
  extractPlasterboardDataForWallfromRevit,
  extractRailsDatafromRevit,
} from "./revit";
import { woolType } from "./type";
import articleunit from "../Util/articleunit.json";

export const collectEtageData = async (
  processedWallParams: any,
  extractedSolutionProduct: any,
  wallsData: any,
  setUnitArray: any,
  unitConversionArray: any,
  byRatio: boolean = false
) => {
  setUnitArray([], "");
  let finalExtract: any = [];

  await forEach(processedWallParams, (wall, index) => {
    const wallDetails = find(wallsData, { Id: wall.Id });
    const placoSolution = find(extractedSolutionProduct, (slProduct) => {
      return slProduct.solutionProduct.technicalName === wall.Params[0].Value;
    });

    if (placoSolution) {
      const wools = filter(placoSolution.products, (prd) => {
        return prd.type === "wool";
      });

      const accessories = filter(placoSolution.products, (prd) => {
        return (
          prd.type === "coating" ||
          prd.type === "joints" ||
          prd.type === "resilent" ||
          prd.type === "screw" ||
          prd.type === "accessoires"
        );
      });

      const plaques = filter(placoSolution.products, (prd) => {
        return prd.type === "plaque";
      });
      const montants = filter(placoSolution.products, (prd) => {
        return prd.type === "montant";
      });
      const rails = filter(placoSolution.products, (prd) => {
        return prd.type === "rail";
      });

      finalExtract.push({
        wallDetails: wallDetails,
        placoSolution: placoSolution.solutionProduct,
        wools: wools,
        accessories: accessories,
        plaques: plaques,
        montants: montants,
        rails: rails,
        layoutPossible: placoSolution.layoutPossible,
        attributes: placoSolution.attributes,
        relationAttributes: placoSolution.relationAttributes,
        relations: placoSolution.relations,
      });
    }
  });

  let ratioArray: IRationTable[] = [];
  let ratioListArray: any = [];

  const collected_wool = await collectWool(finalExtract);

  collected_wool.forEach((colwool: woolType) => {
    if (colwool.articleLists) {
      const selectedObj = find(colwool.articleLists, { selected: true });
      ratioListArray.push({
        typeArticle: "Produits isolants",
        nameArticle: colwool.ProductName,
        order: 4,
        placoSolution: colwool.Ovurage,
        solutionId: colwool.solutionId,
        unit: "M2",
        articleId: 1,
        articleList: colwool.articleLists,
        ratioDefault: selectedObj?.ratio,
        rationCustom: selectedObj?.rationCustom,
        defaultValue: selectedObj?.articleName,
      });
    } else {
      ratioListArray.push({
        typeArticle: "Produits isolants",
        nameArticle: colwool.ProductName,
        ratioDefault: colwool.Ratio,
        rationCustom: colwool.rationCustom,
        order: 4,
        placoSolution: colwool.Ovurage,
        solutionId: colwool.solutionId,
        unit: "M2",
        articleList: null,
        defaultValue: colwool.ProductName,
      });
    }
  });

  ratioListArray = uniqWith(ratioListArray, isEqual);
  const collected_accessory = await collectAccessory(finalExtract, {
    setUnitConversionArray: setUnitArray,
    unitConversionArray: unitConversionArray,
  });

  collected_accessory.forEach((colacc: Accessory) => {
    if (Object.keys(colacc.articles).length > 0) {
      forEach(colacc.articles, (clarticle: AccessoryArticle) => {
        ratioListArray.push({
          typeArticle: "Accessories",
          nameArticle: clarticle.article,
          ratioDefault: clarticle.ratio,
          rationCustom: clarticle.rationCustom,
          order: 5,
          placoSolution: colacc.solution,
          unit: clarticle.unitI,
          solutionId: clarticle.solutionId,
          articleList: null,
          defaultValue: clarticle.article,
        });
      });
    }
  });

  const collected_rail = await collectRail(finalExtract, byRatio);
  collected_rail.forEach((colrail: Rails) => {
    if (Object.keys(colrail.articles).length > 0) {
      forEach(colrail.articles, (clarticle: RailArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Ossatures Horizontal",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 3,
            placoSolution: colrail.solution,
            solutionId: clarticle.solutionId,
            unit: "M",
            articleList: null,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  const collected_montant = await collectMontant(
    finalExtract,
    processedWallParams,
    byRatio
  );

  collected_montant.forEach((colmont: Montant) => {
    if (colmont.articleLists) {
      const selectedObj = find(colmont.articleLists, { selected: true });

      ratioListArray.push({
        typeArticle: "Ossatures Vertical",
        nameArticle: colmont.articleName,
        placoSolution: colmont.solution,
        unit: "M",
        articleList: colmont.articleLists,
        articleId: colmont.articleId,
        solutionId: colmont.solutionId,
        order: 2,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        defaultValue: selectedObj.articleName,
      });
    } else if (Object.keys(colmont.articles).length > 0) {
      forEach(colmont.articles, (clarticle: MontantArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Ossatures Vertical",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 2,
            placoSolution: colmont.solution,
            unit: "M",
            articleList: null,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  const collected_plaques = await collectPlaques(
    finalExtract,
    processedWallParams,
    byRatio
  );

  forEach(collected_plaques, (colplaq: Plaqs) => {
    if (colplaq.articleLists) {
      const selectedObj = find(colplaq.articleLists, { selected: true });
      ratioListArray.push({
        typeArticle: "Produits plâtre",
        nameArticle: colplaq.articleName,
        placoSolution: colplaq.solution,
        unit: "M2",
        articleList: colplaq.articleLists,
        articleId: colplaq.articleId,
        solutionId: colplaq.solutionId,
        order: 1,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        defaultValue: selectedObj.articleName,
      });
    } else if (Object.keys(colplaq.articles).length > 0) {
      forEach(colplaq.articles, (clarticle: PlaqArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Produits plâtre",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 1,
            placoSolution: colplaq.solution,
            unit: "M2",
            articleList: null,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  if (byRatio) {
    ratioListArray = uniqWith(
      ratioListArray,
      (a: any, b: any) =>
        a.solutionId === b.solutionId && a.nameArticle === b.nameArticle
    );
  } else {
    ratioListArray = uniqWith(
      ratioListArray,
      (a: any, b: any) =>
        a.solutionId === b.solutionId && a.defaultValue === b.defaultValue
    );
  }
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  return {
    collected_wool,
    collected_accessory,
    collected_rail,
    collected_montant,
    collected_plaques,
    default_ratio: [], // ratioArray
    finalExtract: finalExtract,
    userRatio: userRation,
    mapZoneWall: null,
    processedWallParams: null,
    ratioListArray: ratioListArray,
  };
};

export const collectWool = async (finalExtract: any) => {
  let woolCollection: woolType[] = [];

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    const woolKeySearchArray = ["PAR", "GR 32", "Isoconfort", "IBR", "Laine"];

    extractObj.wools.forEach((element: any) => {
      const woolAcceptable = find(woolKeySearchArray, (o: any) => {
        return startsWith(element.product.translation, o);
      });

      if (woolAcceptable) {
        const gfrSellingPackaging = find(element.product.attributes, {
          technicalName: "GFR-GP Selling Packaging",
        });

        if (gfrSellingPackaging) {
          let sellingPackage = gfrSellingPackaging.values[0].value;
          const gfrLabelUnit = find(element.product.attributes, {
            technicalName: "GFR-Label unit (UE)",
          });
          let labelUnit: any = "";
          if (gfrLabelUnit) {
            labelUnit = gfrLabelUnit.values[0].value;
            if (labelUnit) {
              labelUnit = labelUnit.split(",")[0];
            }
          } else {
            labelUnit = articleunit["ceiling"]["Ovurages isolants"];
          }

          const gfrEan = find(element.product.attributes, {
            technicalName: "GFR-EAN code ISOVER article",
          });

          let ean = "";
          if (gfrEan) {
            ean = gfrEan.values[0].value;
          }

          const includeLainDEVerre = includes(
            element.product.translation,
            "Laine de verre"
          );

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            element.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );
          let finalRatio =
            extractObj.layoutPossible === "false"
              ? parseFloat(ration) || 1.05
              : 1.05;

          if (!includeLainDEVerre) {
            let customRatio = 0;
            if (
              userRation[
                extractObj.placoSolution.oid + element.product.translation
              ]
            ) {
              customRatio =
                userRation[
                  extractObj.placoSolution.oid + element.product.translation
                ];
            } else {
              customRatio = finalRatio;
            }

            let woolRow: woolType = {
              Level: extractObj.wallDetails.LevelName,
              LevelElevation: extractObj.wallDetails.LevelElevation,
              Ovurage: extractObj.placoSolution.translation,
              ProductName: element.product.translation,
              ElementHeight: numeralConversion(
                round(extractObj.wallDetails.Height / 1000)
              ),
              ElementArea: round(extractObj.wallDetails.Area, 2),
              Ratio: finalRatio,
              WoolArea: round(extractObj.wallDetails.Area * 1.05, 2),
              Rolls: "",
              ElementType: extractObj.wallDetails.WallType,
              ElementId: extractObj.wallDetails.Id,
              SellingPackage: sellingPackage,
              LabelUnit: labelUnit,
              Ean: ean,
              articleLists: null,
              selected: true,
              rationCustom: customRatio,
              solutionId: extractObj.placoSolution.oid,
            };

            woolCollection.push(woolRow);
          }
        }
      } else {
        const woolNonLayoutAcceptable = find(
          ["Doublissimo", "Placomur", "Placotherm"],
          (o: any) => {
            return startsWith(element.product.translation, o);
          }
        );

        if (woolNonLayoutAcceptable) {
          let sellingPackage = "0";
          const gfrLabelUnit = find(element.product.attributes, {
            technicalName: "GFR-Label unit (UE)",
          });
          let labelUnit: any = "";
          if (gfrLabelUnit) {
            labelUnit = gfrLabelUnit.values[0].value;
            if (labelUnit) {
              labelUnit = labelUnit.split(",")[0];
            }
          } else {
            labelUnit = articleunit["ceiling"]["Ovurages isolants"];
          }

          const gfrEan = find(element.product.attributes, {
            technicalName: "GFR-EAN code ISOVER article",
          });

          let ean = "";
          if (gfrEan) {
            ean = gfrEan.values[0].value;
          }

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            element.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );
          let finalRatio =
            extractObj.layoutPossible === "false"
              ? parseFloat(ration) || 1.05
              : 1.05;

          let customRatio = 0;
          if (
            userRation[
              extractObj.placoSolution.oid +
                extractObj.placoSolution.translation
            ]
          ) {
            customRatio =
              userRation[
                extractObj.placoSolution.oid +
                  extractObj.placoSolution.translation
              ];
          } else {
            customRatio = finalRatio;
          }

          let woolRow: woolType = {
            Level: extractObj.wallDetails.LevelName,
            LevelElevation: extractObj.wallDetails.LevelElevation,
            Ovurage: extractObj.placoSolution.translation,
            ProductName: element.product.translation,
            ElementHeight: numeralConversion(
              round(extractObj.wallDetails.Height / 1000)
            ),
            ElementArea: round(extractObj.wallDetails.Area, 2),
            Ratio: finalRatio,
            WoolArea: round(extractObj.wallDetails.Area * 1.05, 2),
            Rolls: "",
            ElementType: extractObj.wallDetails.WallType,
            ElementId: extractObj.wallDetails.Id,
            SellingPackage: sellingPackage,
            LabelUnit: labelUnit,
            Ean: ean,
            articleLists: null,
            solutionId: extractObj.placoSolution.oid,
            rationCustom: customRatio,
          };

          let collectedNewArticles: any = [];
          let selected = false;
          let isSelected = false;
          map(element.articles, (art, artIndex) => {
            if (userRation[extractObj.placoSolution.oid + art.value]) {
              customRatio =
                userRation[extractObj.placoSolution.oid + art.value];
            } else {
              customRatio = finalRatio;
            }

            let wallHeight = round(extractObj.wallDetails.Height / 10);
            let hHeight = round((wallHeight - 1) / 100);
            if (art.height >= hHeight) {
              if (!isSelected) {
                selected = true;
                isSelected = true;
              } else {
                selected = false;
              }
            }

            collectedNewArticles.push({
              level: extractObj.wallDetails.LevelName,
              solution: extractObj.placoSolution.translation,
              articleName: art.value,
              elementType: extractObj.wallDetails.WallType,
              levelElevation: extractObj.wallDetails.LevelElevation,
              ratio: finalRatio,
              nonLayout: true,
              selected: selected,
              rationCustom: customRatio,
              solutionId: extractObj.placoSolution.oid,
              articleId: art.article.oid,
              key: art.value,
              value: art.value,
              text: art.value,
            });
          });

          woolRow.articleLists = collectedNewArticles;

          woolCollection.push(woolRow);
        }
      }
    });
  });

  return woolCollection;
};

export const collectAccessory = async (finalExtract: any, props: any) => {
  const primaryObject: any = {};
  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const wallId = extractObj.wallDetails.Id;
    const solutionId = extractObj.placoSolution.oid;

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 0) {
          if (accessoryObj.articles.length > 1) {
            if (accessoryObj.type !== "accessoires") {
              const selectedArticle = find(accessoryObj.articles, function (o) {
                return (
                  listToFind.findIndex((account: string) => {
                    return account.startsWith(o.packagedArticle.translation);
                  }, o.packagedArticle.translation) > -1
                );
              });

              if (selectedArticle) {
                selectedAccessoryPackagedArticle =
                  selectedArticle.packagedArticle;
              }
            } else {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
            if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        }
        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const ratio = findProductToProduct(
            extractObj.relations,
            accessoryObj.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          if (objArticle) {
            const objSurfaceArea = numeralConversion(wallArea); //Surface Area
            const objFinalratio = ratio
              ? ratio === "" || ratio === "999" || ratio === "9999"
                ? 0
                : parseFloat(ratio)
              : 0; // Ratio
            let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
            let objIQuantity: number | string = ""; //I Quantity
            let objSalesunit = ""; //J Unite De Vente

            if (objFinalunite === "") {
              const accessoryObject = articleunit["wall"]["Accessories"];
              forEach(accessoryObject, (value: string, key: string) => {
                if (objArticle.includes(key)) {
                  objFinalunite = value;
                }
              });
            }

            const articleAttribute =
              selectedAccessoryPackagedArticle.attributes;
            const dbsalesunit = dbSalesUnit(
              distributionChannelsAids,
              articleAttribute
            );

            const saleunit = find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);

            if (dbsalesunit) {
              objSalesunit = uniteDeVenteArray(dbsalesunit);
            } else {
              if (saleunit) {
                const saleunit_value = get(saleunit.values[0], "value");
                objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
              }
            }

            let alternativeunit;
            if (objFinalunite) {
              alternativeunit = alternativeUnit(
                alternativeUnitsAids,
                articleAttribute,
                parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
                objFinalunite,
                objSalesunit,
                props,
                "accessory"
              );
              if (alternativeunit) {
                objIQuantity = alternativeunit;
              }

              if (objIQuantity === "0") {
                console.log("Cached");
              }
            }

            /**
             * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
             */
            const primaryObjectKey: string = wallId;
            let primaryArticle: any = primaryObject[primaryObjectKey] || {};

            /**
             * Extract secondary article from primary article, Key = article name
             */
            const secondaryArticletKey: string = objArticle;

            /**
             * Secondary article are sorted based on Article name
             * Extract secondary article or assign new
             * While assigning new assign surfaceArea, ratio, quantiteI to 0
             */
            let secondaryArticle: any = primaryArticle[
              secondaryArticletKey
            ] || {
              levelName: levelName,
              solutionName: ovurage,
              article: objArticle,
              elementHeight: wallHeight,
              surfaceArea: 0,
              ratio: 0,
              quantite: "",
              quantiteUnit: objFinalunite,
              quantiteI: 0,
              salesUnit: objSalesunit,
              elementType: wallType,
              levelElevation: levelElevation,
              elementId: wallId,
              alternativeUnitsAids: alternativeUnitsAids,
              articleAttribute: articleAttribute,
              selected: true,
              solutionId: solutionId,
            };

            /**
             * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
             */
            primaryArticle[secondaryArticletKey] = {
              ...secondaryArticle,
              surfaceArea:
                secondaryArticle.surfaceArea +
                parseFloat(objSurfaceArea.toFixed(3)),
              ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
              quantiteI: secondaryArticle.quantiteI + objIQuantity,
            };

            /**
             * Assign newly calculate primary article .
             */

            primaryObject[primaryObjectKey] = primaryArticle;
            primaryObject[primaryObjectKey]["surfaceArea"] =
              primaryArticle[secondaryArticletKey].surfaceArea;
            primaryObject[primaryObjectKey]["level"] =
              primaryArticle[secondaryArticletKey].levelName;
            primaryObject[primaryObjectKey]["levelElevation"] =
              primaryArticle[secondaryArticletKey].levelElevation;
            primaryObject[primaryObjectKey]["solution"] =
              primaryArticle[secondaryArticletKey].solutionName;
            primaryObject[primaryObjectKey]["elementHeight"] =
              primaryArticle[secondaryArticletKey].elementHeight;
            primaryObject[primaryObjectKey]["elementId"] =
              primaryArticle[secondaryArticletKey].elementId;
            primaryObject[primaryObjectKey]["solutionId"] =
              primaryArticle[secondaryArticletKey].solutionId;
          }
        }
      }
    });
  });
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);
  const collected_articles: Accessory[] = [];
  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let elementHeight = prmObj.elementHeight;
    let elementId = prmObj.elementId;
    let solutionId = prmObj.solutionId;

    let articles: AccessoryArticle[] = [];

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "elementHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "elementId" &&
        articleIndex !== "solutionId"
      ) {
        let customRatio = 0;
        if (userRation[solutionId + articleObj.article]) {
          customRatio = userRation[solutionId + articleObj.article];
        } else {
          customRatio = articleObj.ratio;
        }

        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(elementHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.elementType,
          levelElevation: articleObj.levelElevation,
          elementId: elementId,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
          rationCustom: customRatio,
          solutionId: solutionId,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(elementHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: elementId,
    });
  });
  return collected_articles;
};

export const collectAccessoryWithCustomRatio = async (
  finalExtract: any,
  defaultRatio: any,
  props: any
) => {
  const primaryObject: any = {};
  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const wallId = extractObj.wallDetails.Id;
    const solutionId = extractObj.placoSolution.oid;

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 0) {
          if (accessoryObj.articles.length > 1) {
            if (accessoryObj.type !== "accessoires") {
              const selectedArticle = find(accessoryObj.articles, function (o) {
                return (
                  listToFind.findIndex((account: string) => {
                    return account.startsWith(o.packagedArticle.translation);
                  }, o.packagedArticle.translation) > -1
                );
              });

              if (selectedArticle) {
                selectedAccessoryPackagedArticle =
                  selectedArticle.packagedArticle;
              }
            } else {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
            if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        }
        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          const objSurfaceArea = numeralConversion(wallArea); //Surface Area
          const customRatioObj: any = defaultRatio.find(
            (def: any) =>
              def.nameArticle === objArticle && def.solutionId === solutionId
          );
          const objFinalratio = customRatioObj.rationCustom
            ? customRatioObj.rationCustom === "" ||
              customRatioObj.rationCustom === "999" ||
              customRatioObj.rationCustom === "9999"
              ? 0
              : parseFloat(customRatioObj.rationCustom)
            : 0; // Ratio
          let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
          let objIQuantity: number | string = ""; //I Quantity
          let objSalesunit = ""; //J Unite De Vente

          if (objFinalunite === "") {
            const accessoryObject = articleunit["wall"]["Accessories"];
            forEach(accessoryObject, (value: string, key: string) => {
              if (objArticle.includes(key)) {
                objFinalunite = value;
              }
            });
          }

          const articleAttribute = selectedAccessoryPackagedArticle.attributes;
          const dbsalesunit = dbSalesUnit(
            distributionChannelsAids,
            articleAttribute
          );

          const saleunit = find(articleAttribute, [
            "aid",
            attributeAids.baseunit,
          ]);

          if (dbsalesunit) {
            objSalesunit = uniteDeVenteArray(dbsalesunit);
          } else {
            if (saleunit) {
              const saleunit_value = get(saleunit.values[0], "value");
              objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
            }
          }

          let alternativeunit;
          if (objFinalunite) {
            alternativeunit = alternativeUnit(
              alternativeUnitsAids,
              articleAttribute,
              parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
              objFinalunite,
              objSalesunit,
              props,
              "accessory"
            );

            if (alternativeunit) {
              objIQuantity = alternativeunit;
            }
          }

          /**
           * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
           */
          const primaryObjectKey: string = wallId;
          let primaryArticle: any = primaryObject[primaryObjectKey] || {};

          /**
           * Extract secondary article from primary article, Key = article name
           */
          const secondaryArticletKey: string = objArticle;

          /**
           * Secondary article are sorted based on Article name
           * Extract secondary article or assign new
           * While assigning new assign surfaceArea, ratio, quantiteI to 0
           */
          let secondaryArticle: any = primaryArticle[secondaryArticletKey] || {
            levelName: levelName,
            solutionName: ovurage,
            article: objArticle,
            elementHeight: wallHeight,
            surfaceArea: 0,
            ratio: 0,
            quantite: "",
            quantiteUnit: objFinalunite,
            quantiteI: 0,
            salesUnit: objSalesunit,
            elementType: wallType,
            levelElevation: levelElevation,
            elementId: wallId,
            alternativeUnitsAids: alternativeUnitsAids,
            articleAttribute: articleAttribute,
          };

          /**
           * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
           */
          primaryArticle[secondaryArticletKey] = {
            ...secondaryArticle,
            surfaceArea:
              secondaryArticle.surfaceArea +
              parseFloat(objSurfaceArea.toFixed(3)),
            ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
            quantiteI: secondaryArticle.quantiteI + objIQuantity,
          };

          /**
           * Assign newly calculate primary article .
           */

          primaryObject[primaryObjectKey] = primaryArticle;
          primaryObject[primaryObjectKey]["surfaceArea"] =
            primaryArticle[secondaryArticletKey].surfaceArea;
          primaryObject[primaryObjectKey]["level"] =
            primaryArticle[secondaryArticletKey].levelName;
          primaryObject[primaryObjectKey]["levelElevation"] =
            primaryArticle[secondaryArticletKey].levelElevation;
          primaryObject[primaryObjectKey]["solution"] =
            primaryArticle[secondaryArticletKey].solutionName;
          primaryObject[primaryObjectKey]["elementHeight"] =
            primaryArticle[secondaryArticletKey].elementHeight;
          primaryObject[primaryObjectKey]["elementId"] =
            primaryArticle[secondaryArticletKey].elementId;
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let elementHeight = prmObj.elementHeight;
    let elementId = prmObj.elementId;
    let solutionId = prmObj.solutionId;
    let articles: AccessoryArticle[] = [];

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "elementHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "elementId"
      ) {
        let customRatio = 0;
        if (userRation[solutionId + articleObj.article]) {
          customRatio = userRation[solutionId + articleObj.article];
        } else {
          customRatio = articleObj.ratio;
        }

        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(elementHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.elementType,
          levelElevation: articleObj.levelElevation,
          elementId: elementId,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
          rationCustom: customRatio,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(elementHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: elementId,
    });
  });
  return collected_articles;
};

export const collectRail = async (finalExtract: any, byRatio: boolean) => {
  const rails: {
    [Key: string]: Rails;
  } = {};

  let railsRevitData = await extractRailsDatafromRevit();
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    let elementId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const elementType = extractObj.wallDetails.WallType;
    const elementHeight = extractObj.wallDetails.Height / 1000;
    const elementArea = extractObj.wallDetails.Area;
    const solutionId = extractObj.placoSolution.oid;

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      forEach(extractObj.rails, function (rail, index) {
        let selectedArticle = rail.articles[0];

        if (rail.product.translation === "Rail Stil® R 48") {
          selectedArticle = find(rail.articles, {
            value: "Rail Stil® R 48/300 bot10L",
          });
        }

        if (selectedArticle) {
          const selectedPackagedArticle = selectedArticle.packagedArticle;
          if (selectedPackagedArticle) {
            const attributeDescriptors = extractObj.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            const ubLengthObject = find(selectedPackagedArticle.attributes, {
              aid: attributeAids.unpackedLength,
            });

            const length = ubLengthObject
              ? ubLengthObject.values[0].numericValue
              : 0;

            let revitData = filter(railsRevitData, function (o) {
              return o.wallId === parseInt(elementId);
            });

            forEach(revitData, (rvtData, rvtIndex) => {
              const productname = rvtData.productName.includes(
                "Rail Stil® R 48"
              )
                ? "Rail Stil® R 48/300 bot10L"
                : rvtData.productName;
              if (
                selectedPackagedArticle.translation
                  .replace(/[\s'®]/g, "")
                  .replace(/[^a-zA-Z0-9]\s/g, "")
                  .includes(
                    productname
                      .replace(/[\s'®]/g, "")
                      .replace(/[^a-zA-Z0-9]\s/g, "")
                  )
              ) {
                let wallH = numeralConversion(round(elementHeight, 2));
                let wallA = numeralConversion(round(elementArea, 2));

                let newRailRow: Rails = rails[`${elementId}`] || {
                  level: levelName,
                  solution: ovurage,
                  elementId: elementId,
                  articleName: selectedPackagedArticle.translation,
                  elementHeight: numeralConversion(round(elementHeight, 2)),
                  elementArea: numeralConversion(round(elementArea, 2)),
                  length: length,
                  levelElevation: levelElevation,
                  articles: {},
                };

                let sortedRevitData = sortBy(rvtData.data, [
                  function (o) {
                    return round(o.length / 1000, 2);
                  },
                ]);

                let customRatio = 0;
                if (userRation[solutionId + selectedArticle.value]) {
                  customRatio = userRation[solutionId + selectedArticle.value];
                } else {
                  customRatio = 0;
                }

                for (let dtt of sortedRevitData) {
                  let length_in_m = ceil(dtt.length / 1000, 2);
                  let selectedArticle = newRailRow.articles[
                    `${selectedPackagedArticle.translation}/${length_in_m}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    articleName: selectedPackagedArticle.translation,
                    elementHeight: wallH,
                    elementArea: wallA,
                    articleRevitLength: length_in_m,
                    nbUnit: 0,
                    nbTotal: "",
                    lengthTotal: 0,
                    articleLength: length,
                    elementType: elementType,
                    levelElevation: levelElevation,
                    ratio: 0,
                    nonLayout: false,
                    selected: true,
                    rationCustom: customRatio,
                  };

                  selectedArticle.nbUnit =
                    selectedArticle.nbUnit + dtt.quantity;

                  newRailRow.articles[
                    `${selectedPackagedArticle.translation}/${length_in_m}`
                  ] = selectedArticle;

                  rails[`${elementId}`] = newRailRow;
                }
              }
            });
          }
        }
      });
    } else {
      forEach(extractObj.rails, function (rail, index) {
        var selectedArticle = rail.articles[0];

        if (rail.product.translation === "Rail Stil® R 48") {
          selectedArticle = find(rail.articles, {
            value: "Rail Stil® R 48/300 bot10L",
          });
        }

        if (selectedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const ubLengthObject = find(
            selectedArticle.packagedArticle.attributes,
            {
              aid: attributeAids.unpackedLength,
            }
          );

          const length = ubLengthObject
            ? ubLengthObject.values[0].numericValue
            : 0;

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            rail.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          const objFinalratio = ration
            ? ration === "" || ration === "999" || ration === "9999"
              ? 0
              : parseFloat(ration)
            : 0;

          let wallH = numeralConversion(round(elementHeight, 2));
          let wallA = numeralConversion(round(elementArea, 2));

          let customRatio = 0;
          if (userRation[solutionId + selectedArticle.value]) {
            customRatio = userRation[solutionId + selectedArticle.value];
          } else {
            customRatio = objFinalratio;
          }

          let newRailRow: Rails = rails[`${elementId}`] || {
            level: levelName,
            solution: ovurage,
            elementId: elementId,
            articleName: selectedArticle.value,
            elementHeight: numeralConversion(round(elementHeight, 2)),
            elementArea: numeralConversion(round(elementArea, 2)),
            length: length,
            levelElevation: levelElevation,
            articles: {},
            solutionId: solutionId,
          };

          let collected_artcle = newRailRow.articles[
            `${selectedArticle.value}/${0}`
          ] || {
            level: levelName,
            solution: ovurage,
            articleName: selectedArticle.value,
            elementHeight: wallH,
            elementArea: wallA,
            articleRevitLength: 0,
            nbUnit: 0,
            nbTotal: "",
            lengthTotal: 0,
            articleLength: length,
            elementType: elementType,
            levelElevation: levelElevation,
            ratio: objFinalratio,
            nonLayout: true,
            selected: true,
            rationCustom: customRatio,
            solutionId: solutionId,
          };

          newRailRow.articles[`${selectedArticle.value}/${0}`] =
            collected_artcle;

          rails[`${elementId}`] = newRailRow;
        }
      });
    }
  });

  /**
   * Sort rails by level -> solution
   */
  let railArray: Rails[] = [];

  forEach(rails, (plaq: Rails, key: string) => {
    railArray.push(plaq);
  });

  return railArray;
};

export const collectMontant = async (
  finalExtract: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: { [Key: string]: Montant } = {};
  let montantsRevitData = await extractFramesDatafromRevit();
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    let elementId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const elementType = extractObj.wallDetails.WallType;
    const elementHeight = extractObj.wallDetails.Height / 1000;
    const elementArea = extractObj.wallDetails.Area;
    const solutionId = extractObj.placoSolution.oid;

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      let revitData = filter(montantsRevitData, function (o) {
        return o.wallId === parseInt(elementId);
      });

      forEach(revitData, (revitMontant, index) => {
        forEach(extractObj.montants, (montant, index) => {
          const selectedArticle = find(montant.articles, function (o) {
            return revitMontant.productName === o.value;
          });

          if (selectedArticle) {
            const selectedPackagedArticle = selectedArticle.packagedArticle;

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractObj.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              let wallH = numeralConversion(round(elementHeight, 2));
              let wallA = numeralConversion(round(elementArea, 2));

              let newMontantRow: Montant = collected_articles[
                `${elementId}`
              ] || {
                level: levelName,
                solution: ovurage,
                articleName: selectedPackagedArticle.translation,
                elementHeight: wallH,
                elementArea: wallA,
                elementType: elementType,
                articles: {},
                levelElevation: levelElevation,
                elementId: elementId,
                articleLists: null,
              };

              const articleLength = find(selectedPackagedArticle.attributes, [
                "aid",
                attributeAids.unpackedLength,
              ]);

              if (
                selectedPackagedArticle.translation.includes(
                  revitData[0].productName
                )
              ) {
                let sortedRevitData = sortBy(revitData[0].data, [
                  function (o) {
                    return round(o.length / 1000, 2);
                  },
                ]);

                for (let dtt of sortedRevitData) {
                  const length_in_m = dtt.length / 1000;
                  const quantity = dtt.quantity;

                  let articleRevitHeight = round(length_in_m, 2);
                  let calArticleLength: number = 0;

                  if (articleLength) {
                    const metreValue = find(articleLength.values, [
                      "unit",
                      "m",
                    ]);

                    if (metreValue) {
                      const mValue = get(metreValue, "value");
                      calArticleLength = mValue;
                    } else {
                      const milliMetreValue = find(articleLength.values, [
                        "unit",
                        "mm",
                      ]);
                      if (milliMetreValue) {
                        const mmValue = get(milliMetreValue, "value");
                        calArticleLength = parseFloat(mmValue) / 1000;
                      }
                    }
                  }

                  let collected_article = newMontantRow.articles[
                    `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    articleName: selectedPackagedArticle.translation,
                    elementHeight: wallH,
                    elementArea: wallA,
                    articleRevitHeight: articleRevitHeight,
                    nbUnit: 0,
                    nbTotal: 0,
                    articleLength: calArticleLength,
                    elementType: elementType,
                    levelElevation: levelElevation,
                    selected: true,
                  };

                  collected_article.nbUnit =
                    collected_article.nbUnit + numeralConversion(quantity);

                  newMontantRow.articles[
                    `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                  ] = collected_article;

                  collected_articles[`${elementId}`] = newMontantRow;
                }
              }
            }
          }
        });
      });
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === elementId;
      });

      if (revitData) {
        const userMontantObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Montant"
        );

        if (userMontantObj) {
          if (userMontantObj.Value) {
            const userMontantArray = userMontantObj.Value.split(";");

            if (userMontantArray) {
              userMontantArray.forEach((userMon: string) => {
                forEach(extractObj.montants, (montant, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    montant.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = montant.articles.find(
                    (plart: any) => plart.value === userMon
                  );

                  if (selectedArticle) {
                    let newMontantRow: Montant = collected_articles[
                      `${elementId}/${montant.product.oid}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      elementId: elementId,
                      articleName: montant.product.translation,
                      elementHeight: numeralConversion(round(elementHeight, 2)),
                      elementArea: numeralConversion(round(elementArea, 2)),
                      articles: {},
                      levelElevation: levelElevation,
                      elementType: elementType,
                      solutionId: solutionId,
                      articleId: montant.product.oid,
                      articleLists: null,
                    };

                    let collectedNewArticles: any = [];

                    forEach(montant.articles, (moarticle, moArticleIndex) => {
                      const attributeDescriptors = extractObj.attributes;

                      const attributeAids =
                        packageAttributeSelector(attributeDescriptors);

                      const articleLength = find(
                        selectedArticle.packagedArticle.attributes,
                        ["aid", attributeAids.unpackedLength]
                      );

                      let calArticleLength: number = 0;

                      if (articleLength) {
                        const metreValue = find(articleLength.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue = get(metreValue, "value");
                          calArticleLength = mValue;
                        } else {
                          const milliMetreValue = find(articleLength.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            calArticleLength = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      // let collectedArticle = newMontantRow.articles[
                      //   `${selectedArticle.value}/${0}/${1}`
                      // ] || {
                      //   level: levelName,
                      //   solution: ovurage,
                      //   articleName: selectedArticle.value,
                      //   elementHeight: numeralConversion(round(elementHeight, 2)),
                      //   elementArea: numeralConversion(round(elementArea, 2)),
                      //   articleRevitHeight: 0,
                      //   nbUnit: 0,
                      //   nbTotal: 0,
                      //   articleLength: calArticleLength,
                      //   elementType: elementType,
                      //   levelElevation: levelElevation,
                      //   ratio: objFinalratio,
                      //   nonLayout: true,
                      // };
                      let customRatio = 0;
                      if (userRation[solutionId + moarticle.value]) {
                        customRatio = userRation[solutionId + moarticle.value];
                      } else {
                        customRatio = objFinalratio;
                      }
                      collectedNewArticles.push({
                        level: levelName,
                        solution: ovurage,
                        articleName: moarticle.value,
                        elementHeight: numeralConversion(
                          round(elementHeight, 2)
                        ),
                        elementArea: numeralConversion(round(elementArea, 2)),
                        articleRevitHeight: 0,
                        nbUnit: 0,
                        nbTotal: 0,
                        articleLength: calArticleLength,
                        elementType: elementType,
                        levelElevation: levelElevation,
                        ratio: objFinalratio,
                        nonLayout: true,
                        selected: selectedArticle.value === moarticle.value,
                        rationCustom: customRatio,
                        key: moarticle.value,
                        value: moarticle.value,
                        text: moarticle.value,
                      });
                    });
                    newMontantRow.articleLists = collectedNewArticles;
                    collected_articles[`${elementId}/${montant.product.oid}`] =
                      newMontantRow;
                    // newMontantRow.articles[
                    //   `${selectedArticle.value}/${0}/${1}`
                    // ] = collectedArticle;
                    // collected_articles[`${elementId}`] = newMontantRow;
                  }
                });
              });
            }
          }
        }
      }
    }
  });

  /**
   * Sort montants by level -> solution
   */
  let montantArray: Montant[] = [];

  forEach(collected_articles, (plaq: Montant, key: string) => {
    montantArray.push(plaq);
  });

  return montantArray;
};

export const collectPlaquesAndRatio = async (
  finalExtract: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: {
    [Key: string]: Plaqs;
  } = {};

  let plaqueRevitData = await extractPlasterboardDataForWallfromRevit();

  forEach(finalExtract, (extractObj, extractIndex) => {
    let elementId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const elementType = extractObj.wallDetails.WallType;
    const elementHeight = extractObj.wallDetails.Height / 1000;
    const elementArea = extractObj.wallDetails.Area;
    let attributesId: any = getLayoutAttributesAid(extractObj.attributes);

    let layers = extractObj.placoSolution.attributes.filter(
      (e: any) => e.aid === attributesId.layers
    );
    let layerNames = compact(
      layers.map((ln: any) => {
        const lnmm = ln.subAttributes.find(
          (sl: any) => sl.technicalName === "GFR-Layer name"
        );
        return lnmm.values[0];
      })
    );

    let layerCount: any = groupBy(layerNames, "value");
    layerCount = keyBy(
      map(layerCount, (ly, key) => {
        return { key: key, count: ly.length };
      }),
      "key"
    );

    if (extractObj.layoutPossible === "true" && byRatio === false) {
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === elementId;
      });

      if (revitData) {
        const userPlaqueObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Plaque"
        );

        if (userPlaqueObj) {
          if (userPlaqueObj.Value) {
            const userPlaqueArray = userPlaqueObj.Value.split(";");

            if (userPlaqueArray) {
              userPlaqueArray.forEach((userPlq: string) => {
                forEach(extractObj.plaques, (plaque, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    plaque.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = plaque.articles.find(
                    (plart: any) => plart.value === userPlq
                  );
                  try {
                    if (selectedArticle) {
                      let collectedNewArticles: any = [];
                      forEach(plaque.articles, (plarticle, plArticleIndex) => {
                        const attributeDescriptors = extractObj.attributes;

                        const attributeAids =
                          packageAttributeSelector(attributeDescriptors);

                        const articleWidth = find(
                          plarticle.packagedArticle.attributes,
                          ["aid", attributeAids.unpackedwidth]
                        );
                        const articleLength = find(
                          plarticle.packagedArticle.attributes,
                          ["aid", attributeAids.unpackedLength]
                        );

                        let placoArticleWidth: number = 0;
                        if (articleWidth) {
                          const metreValue = find(articleWidth.values, [
                            "unit",
                            "m",
                          ]);

                          if (metreValue) {
                            const mValue: string = get(metreValue, "value");
                            placoArticleWidth = parseFloat(mValue);
                          } else {
                            const milliMetreValue = find(articleWidth.values, [
                              "unit",
                              "mm",
                            ]);
                            if (milliMetreValue) {
                              const mmValue = get(milliMetreValue, "value");
                              placoArticleWidth = parseFloat(mmValue) / 1000;
                            }
                          }
                        }

                        let placoArticleLength: number = 0;

                        if (articleLength) {
                          const metreValue = find(articleLength.values, [
                            "unit",
                            "m",
                          ]);

                          if (metreValue) {
                            const mValue: string = get(metreValue, "value");
                            placoArticleLength = parseFloat(mValue);
                          } else {
                            const milliMetreValue = find(articleLength.values, [
                              "unit",
                              "mm",
                            ]);
                            if (milliMetreValue) {
                              const mmValue = get(milliMetreValue, "value");
                              placoArticleLength = parseFloat(mmValue) / 1000;
                            }
                          }
                        }

                        let newPlaqRow: Plaqs = collected_articles[
                          `${elementId}`
                        ] || {
                          level: levelName,
                          solution: ovurage,
                          elementId: elementId,
                          articleName: plarticle.value,
                          elementHeight: numeralConversion(
                            round(elementHeight, 2)
                          ),
                          elementArea: numeralConversion(round(elementArea, 2)),
                          articles: {},
                          levelElevation: levelElevation,
                          selectedArticle: selectedArticle,
                          articleLists: null,
                        };

                        let collectedArticle = newPlaqRow.articles[
                          `${plarticle.value}/${0}/${1}`
                        ] || {
                          level: levelName,
                          solution: ovurage,
                          articleName: plarticle.value,
                          elementHeight: numeralConversion(
                            round(elementHeight, 2)
                          ),
                          elementArea: numeralConversion(round(elementArea, 2)),
                          articleRevitLength: 0,
                          articleRevitHeight: 0,
                          nbUnit: layerCount[plaque.product.translation].count,
                          nbTotal: "",
                          articleWidth: placoArticleWidth,
                          articleLength: placoArticleLength,
                          elementType: elementType,
                          levelElevation: levelElevation,
                          ratio: objFinalratio,
                          nonLayout: true,
                        };

                        collectedNewArticles.push({
                          level: levelName,
                          solution: ovurage,
                          articleName: plarticle.value,
                          elementHeight: numeralConversion(
                            round(elementHeight, 2)
                          ),
                          elementArea: numeralConversion(round(elementArea, 2)),
                          articleRevitLength: 0,
                          articleRevitHeight: 0,
                          nbUnit: layerCount[plaque.product.translation].count,
                          nbTotal: "",
                          articleWidth: placoArticleWidth,
                          articleLength: placoArticleLength,
                          elementType: elementType,
                          levelElevation: levelElevation,
                          ratio: objFinalratio,
                          nonLayout: true,
                          key: plarticle.value,
                          value: plarticle.value,
                          text: plarticle.value,
                        });
                        newPlaqRow.articles[`${plarticle.value}/${0}/${1}`] =
                          collectedArticle;
                        collected_articles[`${elementId}`] = newPlaqRow;
                      });

                      let newPlaqRow: Plaqs =
                        collected_articles[`${elementId}`];
                      let newArtilceList =
                        newPlaqRow.articleLists[plaque.product.translation] ||
                        [];
                      newArtilceList =
                        newArtilceList.concat(collectedNewArticles);
                      newPlaqRow.articleLists[plaque.product.translation] =
                        newArtilceList;
                      collected_articles[`${elementId}`] = newPlaqRow;
                    }
                  } catch (ex) {}
                });
              });
            }
          }
        }
      }
    }
  });

  let plaqArray: Plaqs[] = [];
  forEach(collected_articles, (plaq: Plaqs, key: string) => {
    plaqArray.push(plaq);
  });

  return plaqArray;
};

export const collectPlaques = async (
  finalExtract: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  try {
    const collected_articles: {
      [Key: string]: Plaqs;
    } = {};

    let plaqueRevitData = await extractPlasterboardDataForWallfromRevit();

    const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

    forEach(finalExtract, (extractObj, extractIndex) => {
      let elementId = extractObj.wallDetails.Id;
      const levelName = extractObj.wallDetails.LevelName;
      const levelElevation = extractObj.wallDetails.LevelElevation;
      const ovurage = extractObj.placoSolution.translation;
      const solutionId = extractObj.placoSolution.oid;
      const elementType = extractObj.wallDetails.WallType;
      const elementHeight = extractObj.wallDetails.Height / 1000;
      const elementArea = extractObj.wallDetails.Area;
      let attributesId: any = getLayoutAttributesAid(extractObj.attributes);

      let layers = extractObj.placoSolution.attributes.filter(
        (e: any) => e.aid === attributesId.layers
      );
      let layerNames = compact(
        layers.map((ln: any) => {
          const lnmm = ln.subAttributes.find(
            (sl: any) => sl.technicalName === "GFR-Layer name"
          );
          return lnmm.values[0];
        })
      );

      let layerCount: any = groupBy(layerNames, "value");
      layerCount = keyBy(
        map(layerCount, (ly, key) => {
          return { key: key, count: ly.length };
        }),
        "key"
      );

      if (extractObj.layoutPossible === "true" && byRatio === false) {
        forEach(extractObj.plaques, (plaque, index) => {
          let revitData = filter(plaqueRevitData, function (o) {
            return o.wallId === parseInt(elementId);
          });

          if (revitData.length > 0) {
            forEach(revitData, (revtData, index) => {
              let selectedArticle = find(plaque.articles, {
                value: revtData.productName,
              });

              if (selectedArticle) {
                const selectedPackagedArticle = selectedArticle.packagedArticle;
                if (selectedPackagedArticle) {
                  const attributeDescriptors = extractObj.attributes;

                  const attributeAids =
                    packageAttributeSelector(attributeDescriptors);

                  let newPlaqRow: Plaqs = collected_articles[
                    `${elementId}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    elementId: elementId,
                    articleName: selectedPackagedArticle.translation,
                    elementHeight: numeralConversion(round(elementHeight, 2)),
                    elementArea: numeralConversion(round(elementArea, 2)),
                    articles: {},
                    articleLists: null,
                    levelElevation: levelElevation,
                  };

                  const articleWidth = find(
                    selectedPackagedArticle.attributes,
                    ["aid", attributeAids.unpackedwidth]
                  );
                  const articleLength = find(
                    selectedPackagedArticle.attributes,
                    ["aid", attributeAids.unpackedLength]
                  );

                  if (
                    selectedPackagedArticle.translation
                      .replace(/[\s'®]/g, "")
                      .replace(/[^a-zA-Z0-9]\s/g, "")
                      .includes(
                        revtData.productName
                          .replace(/[\s'®]/g, "")
                          .replace(/[^a-zA-Z0-9]\s/g, "")
                      )
                  ) {
                    /**
                     * Sort data by length
                     */

                    let sortedRevitData = sortBy(revtData.data, [
                      function (o) {
                        return round(o.length / 1000, 2);
                      },
                    ]).reverse();

                    for (let dtt of sortedRevitData) {
                      const length_in_m = round(dtt.length / 1000, 2);
                      const height_in_m = round(dtt.height / 1000, 2);
                      const quantity = dtt.quantity;
                      const wallH = numeralConversion(round(elementHeight, 2));
                      const wallA = numeralConversion(round(elementArea, 2));
                      let placoArticleWidth: number = 0;

                      if (articleWidth) {
                        const metreValue = find(articleWidth.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue: string = get(metreValue, "value");
                          placoArticleWidth = parseFloat(mValue);
                        } else {
                          const milliMetreValue = find(articleWidth.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            placoArticleWidth = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let placoArticleLength: number = 0;

                      if (articleLength) {
                        const metreValue = find(articleLength.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue: string = get(metreValue, "value");
                          placoArticleLength = parseFloat(mValue);
                        } else {
                          const milliMetreValue = find(articleLength.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            placoArticleLength = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let selectedArticle = newPlaqRow.articles[
                        `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                      ] || {
                        level: levelName,
                        solution: ovurage,
                        articleName: selectedPackagedArticle.translation,
                        elementHeight: wallH,
                        elementArea: wallA,
                        articleRevitLength: length_in_m,
                        articleRevitHeight: height_in_m,
                        nbUnit: 0,
                        nbTotal: "",
                        articleWidth: placoArticleWidth,
                        articleLength: placoArticleLength,
                        elementType: elementType,
                        levelElevation: levelElevation,
                        ratio: 0,
                        nonLayout: false,
                        selected: true,
                      };

                      selectedArticle.nbUnit =
                        selectedArticle.nbUnit + numeralConversion(quantity);

                      newPlaqRow.articles[
                        `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                      ] = selectedArticle;

                      collected_articles[`${elementId}`] = newPlaqRow;
                    }
                  }
                }
              }
            });
          }
        });
      } else {
        let revitData: any = find(processedWallParams, function (o) {
          return o.Id === elementId;
        });

        if (revitData) {
          const userPlaqueObj = revitData.Params.find(
            (rvd: any) => rvd.Name === "Plaque"
          );

          if (userPlaqueObj) {
            if (userPlaqueObj.Value) {
              const userPlaqueArray = userPlaqueObj.Value.split(";");

              if (userPlaqueArray) {
                userPlaqueArray.forEach((userPlq: string) => {
                  forEach(extractObj.plaques, (plaque, index) => {
                    const relationAttributeAids = relationAttributeSelector(
                      extractObj.relationAttributes
                    );

                    const ration = findProductToProduct(
                      extractObj.relations,
                      plaque.product.oid,
                      relationAttributeAids.ratio,
                      extractObj.placoSolution.oid
                    );

                    const objFinalratio = ration
                      ? ration === "" || ration === "999" || ration === "9999"
                        ? 0
                        : parseFloat(ration)
                      : 0;

                    let selectedArticle = plaque.articles.find(
                      (plart: any) => plart.value === userPlq
                    );

                    try {
                      if (selectedArticle) {
                        let newPlaqRow: NewPlaqs = collected_articles[
                          `${elementId}/${plaque.product.oid}`
                        ] || {
                          level: levelName,
                          solution: ovurage,
                          elementId: elementId,
                          articleName: plaque.product.translation,
                          elementHeight: numeralConversion(
                            round(elementHeight, 2)
                          ),
                          elementArea: numeralConversion(round(elementArea, 2)),
                          levelElevation: levelElevation,
                          articleLists: null,
                          solutionId: solutionId,
                          articleId: plaque.product.oid,
                          elementType: elementType,
                        };

                        let collectedNewArticles: any = [];
                        forEach(
                          plaque.articles,
                          (plarticle, plArticleIndex) => {
                            const attributeDescriptors = extractObj.attributes;

                            const attributeAids =
                              packageAttributeSelector(attributeDescriptors);

                            const articleWidth = find(
                              plarticle.packagedArticle.attributes,
                              ["aid", attributeAids.unpackedwidth]
                            );
                            const articleLength = find(
                              plarticle.packagedArticle.attributes,
                              ["aid", attributeAids.unpackedLength]
                            );

                            let placoArticleWidth: number = 0;
                            if (articleWidth) {
                              const metreValue = find(articleWidth.values, [
                                "unit",
                                "m",
                              ]);

                              if (metreValue) {
                                const mValue: string = get(metreValue, "value");
                                placoArticleWidth = parseFloat(mValue);
                              } else {
                                const milliMetreValue = find(
                                  articleWidth.values,
                                  ["unit", "mm"]
                                );
                                if (milliMetreValue) {
                                  const mmValue = get(milliMetreValue, "value");
                                  placoArticleWidth =
                                    parseFloat(mmValue) / 1000;
                                }
                              }
                            }

                            let placoArticleLength: number = 0;

                            if (articleLength) {
                              const metreValue = find(articleLength.values, [
                                "unit",
                                "m",
                              ]);

                              if (metreValue) {
                                const mValue: string = get(metreValue, "value");
                                placoArticleLength = parseFloat(mValue);
                              } else {
                                const milliMetreValue = find(
                                  articleLength.values,
                                  ["unit", "mm"]
                                );
                                if (milliMetreValue) {
                                  const mmValue = get(milliMetreValue, "value");
                                  placoArticleLength =
                                    parseFloat(mmValue) / 1000;
                                }
                              }
                            }

                            // let collectedArticle = newPlaqRow.articles[
                            //   `${plarticle.value}/${0}/${1}`
                            // ] || {
                            //   level: levelName,
                            //   solution: ovurage,
                            //   articleName: plarticle.value,
                            //   elementHeight: numeralConversion(
                            //     round(elementHeight, 2)
                            //   ),
                            //   elementArea: numeralConversion(
                            //     round(elementArea, 2)
                            //   ),
                            //   articleRevitLength: 0,
                            //   articleRevitHeight: 0,
                            //   nbUnit:
                            //     layerCount[plaque.product.translation].count,
                            //   nbTotal: "",
                            //   articleWidth: placoArticleWidth,
                            //   articleLength: placoArticleLength,
                            //   elementType: elementType,
                            //   levelElevation: levelElevation,
                            //   ratio: objFinalratio,
                            //   nonLayout: true,
                            // };

                            let customRatio = 0;
                            if (userRation[solutionId + plarticle.value]) {
                              customRatio =
                                userRation[solutionId + plarticle.value];
                            } else {
                              customRatio = objFinalratio;
                            }
                            collectedNewArticles.push({
                              level: levelName,
                              solution: ovurage,
                              articleName: plarticle.value,
                              elementHeight: numeralConversion(
                                round(elementHeight, 2)
                              ),
                              elementArea: numeralConversion(
                                round(elementArea, 2)
                              ),
                              articleRevitLength: 0,
                              articleRevitHeight: 0,
                              nbUnit:
                                layerCount[plaque.product.translation].count,
                              nbTotal: "",
                              articleWidth: placoArticleWidth,
                              articleLength: placoArticleLength,
                              elementType: elementType,
                              levelElevation: levelElevation,
                              ratio: objFinalratio,
                              nonLayout: true,
                              selected:
                                selectedArticle.value === plarticle.value,
                              rationCustom: customRatio,
                              key: plarticle.value,
                              value: plarticle.value,
                              text: plarticle.value,
                            });
                            // newPlaqRow.articles[
                            //   `${plarticle.value}/${0}/${1}`
                            // ] = collectedArticle;
                            // collected_articles[`${elementId}`] = newPlaqRow;
                          }
                        );
                        newPlaqRow.articleLists = collectedNewArticles;
                        collected_articles[
                          `${elementId}/${plaque.product.oid}`
                        ] = newPlaqRow;
                      }
                    } catch (ex) {}
                  });
                });
              }
            }
          }
        }
      }
    });

    let plaqArray: Plaqs[] = [];

    forEach(collected_articles, (plaq: Plaqs, key: string) => {
      plaqArray.push(plaq);
    });

    return plaqArray;
  } catch (error) {
    throw "Schedules cannot be generated due to a plugin failure";
  }
};

export const collectZoneWool = async (finalExtract: any, mapZoneWall: any) => {
  let woolCollection: woolType[] = [];
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    const woolKeySearchArray = ["PAR", "GR 32", "Isoconfort", "Laine"];

    extractObj.wools.forEach((element: any) => {
      const woolAcceptable = find(woolKeySearchArray, (o: any) => {
        return startsWith(element.product.translation, o);
      });

      if (woolAcceptable) {
        const includeLainDEVerre = includes(
          element.product.translation,
          "Laine de verre"
        );

        if (!includeLainDEVerre) {
          const woolZone = find(mapZoneWall, (m) => {
            return includes(m.walls, extractObj.wallDetails.Id);
          });

          const gfrSellingPackaging = find(element.product.attributes, {
            technicalName: "GFR-GP Selling Packaging",
          });
          if (gfrSellingPackaging) {
            let sellingPackage = gfrSellingPackaging.values[0].value;
            const gfrLabelUnit = find(element.product.attributes, {
              technicalName: "GFR-Label unit (UE)",
            });
            let labelUnit: any = "";
            if (gfrLabelUnit) {
              labelUnit = gfrLabelUnit.values[0].value;
              if (labelUnit) {
                labelUnit = labelUnit.split(",")[0];
              }
            } else {
              labelUnit = articleunit["ceiling"]["Ovurages isolants"];
            }

            const gfrEan = find(element.product.attributes, {
              technicalName: "GFR-EAN code ISOVER article",
            });

            let ean = "";
            if (gfrEan) {
              ean = gfrEan.values[0].value;
            }

            const relationAttributeAids = relationAttributeSelector(
              extractObj.relationAttributes
            );

            const ration = findProductToProduct(
              extractObj.relations,
              element.product.oid,
              relationAttributeAids.ratio,
              extractObj.placoSolution.oid
            );

            let finalRatio =
              extractObj.layoutPossible === "false"
                ? parseFloat(ration) || 1.05
                : 1.05;

            let customRatio = 0;
            if (
              userRation[
                extractObj.placoSolution.oid + element.product.translation
              ]
            ) {
              customRatio =
                userRation[
                  extractObj.placoSolution.oid + element.product.translation
                ];
            } else {
              customRatio = finalRatio;
            }

            let woolRow: woolType = {
              Level: extractObj.wallDetails.LevelName,
              LevelElevation: extractObj.wallDetails.LevelElevation,
              Ovurage: extractObj.placoSolution.translation,
              ProductName: element.product.translation,
              ElementHeight: numeralConversion(
                round(extractObj.wallDetails.Height / 1000)
              ),
              ElementArea: round(extractObj.wallDetails.Area, 2),
              Ratio: finalRatio,
              WoolArea: round(extractObj.wallDetails.Area * 1.05, 2),
              Rolls: "",
              ElementType: extractObj.wallDetails.WallType,
              ElementId: extractObj.wallDetails.Id,
              Zone: woolZone.zone,
              SellingPackage: sellingPackage,
              LabelUnit: labelUnit,
              Ean: ean,
              articleLists: null,
              selected: true,
              solutionId: extractObj.placoSolution.oid,
              rationCustom: customRatio,
            };

            woolCollection.push(woolRow);
          }
        }
      } else {
        const woolNonLayoutAcceptable = find(
          ["Doublissimo", "Placomur", "Placotherm"],
          (o: any) => {
            return startsWith(element.product.translation, o);
          }
        );

        if (woolNonLayoutAcceptable) {
          const woolZone = find(mapZoneWall, (m) => {
            return includes(m.walls, extractObj.wallDetails.Id);
          });

          let sellingPackage = "0";
          const gfrLabelUnit = find(element.product.attributes, {
            technicalName: "GFR-Label unit (UE)",
          });
          let labelUnit: any = "";
          if (gfrLabelUnit) {
            labelUnit = gfrLabelUnit.values[0].value;
            if (labelUnit) {
              labelUnit = labelUnit.split(",")[0];
            }
          } else {
            labelUnit = articleunit["ceiling"]["Ovurages isolants"];
          }

          const gfrEan = find(element.product.attributes, {
            technicalName: "GFR-EAN code ISOVER article",
          });

          let ean = "";
          if (gfrEan) {
            ean = gfrEan.values[0].value;
          }

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            element.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          let finalRatio =
            extractObj.layoutPossible === "false"
              ? parseFloat(ration) || 1.05
              : 1.05;

          let collectedNewArticles: any = [];
          let selected = false;
          let isSelected = false;

          let customRatio = 0;
          if (
            userRation[
              extractObj.placoSolution.oid + element.product.translation
            ]
          ) {
            customRatio =
              userRation[
                extractObj.placoSolution.oid + element.product.translation
              ];
          } else {
            customRatio = finalRatio;
          }

          map(element.articles, (art, artIndex) => {
            if (userRation[extractObj.placoSolution.oid + art.value]) {
              customRatio =
                userRation[extractObj.placoSolution.oid + art.value];
            } else {
              customRatio = finalRatio;
            }

            let wallHeight = round(extractObj.wallDetails.Height / 10);
            let hHeight = round((wallHeight - 1) / 100);
            if (art.height >= hHeight) {
              if (!isSelected) {
                selected = true;
                isSelected = true;
              } else {
                selected = false;
              }
            }

            collectedNewArticles.push({
              level: extractObj.wallDetails.LevelName,
              solution: extractObj.placoSolution.translation,
              articleName: art.value,
              elementType: extractObj.wallDetails.WallType,
              levelElevation: extractObj.wallDetails.LevelElevation,
              ratio: finalRatio,
              nonLayout: true,
              selected: selected,
              rationCustom: customRatio,
              solutionId: extractObj.placoSolution.oid,
              articleId: art.article.oid,
              Zone: woolZone.zone,
              key: art.value,
              value: art.value,
              text: art.value,
            });
          });

          let woolRow: woolType = {
            Level: extractObj.wallDetails.LevelName,
            LevelElevation: extractObj.wallDetails.LevelElevation,
            Ovurage: extractObj.placoSolution.translation,
            ProductName: element.product.translation,
            ElementHeight: numeralConversion(
              round(extractObj.wallDetails.Height / 1000)
            ),
            ElementArea: round(extractObj.wallDetails.Area, 2),
            Ratio: finalRatio,
            WoolArea: round(extractObj.wallDetails.Area * 1.05, 2),
            Rolls: "",
            ElementType: extractObj.wallDetails.WallType,
            ElementId: extractObj.wallDetails.Id,
            Zone: woolZone.zone,
            SellingPackage: sellingPackage,
            LabelUnit: labelUnit,
            Ean: ean,
            articleLists: collectedNewArticles,
            selected: true,
            solutionId: extractObj.placoSolution.oid,
            rationCustom: customRatio,
          };
          woolCollection.push(woolRow);
        }
      }
    });
  });

  return woolCollection;
};

export const collectZoneAccessory = async (
  finalExtract: any,
  props: any,
  mapZoneWall: any
) => {
  const primaryObject: any = {};
  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const wallId = extractObj.wallDetails.Id;
    const solutionId = extractObj.placoSolution.oid;
    const accessoryZone = find(mapZoneWall, (m) => {
      return includes(m.walls, extractObj.wallDetails.Id);
    });

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 1) {
          const selectedArticle = find(accessoryObj.articles, function (o) {
            return (
              listToFind.findIndex((account: string) => {
                return account.startsWith(o.packagedArticle.translation);
              }, o.packagedArticle.translation) > -1
            );
          });
          if (selectedArticle) {
            selectedAccessoryPackagedArticle = selectedArticle.packagedArticle;
          }
        } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
          if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        } else {
          selectedAccessoryPackagedArticle =
            accessoryObj.articles[0].packagedArticle;
        }

        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const ratio = findProductToProduct(
            extractObj.relations,
            accessoryObj.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          const objSurfaceArea = numeralConversion(wallArea); //Surface Area
          const objFinalratio = ratio
            ? ratio === "" || ratio === "999" || ratio === "9999"
              ? 0
              : parseFloat(ratio)
            : 0; // Ratio
          let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
          let objIQuantity: number | string = ""; //I Quantity
          let objSalesunit = ""; //J Unite De Vente

          if (objFinalunite === "") {
            const accessoryObject = articleunit["wall"]["Accessories"];
            forEach(accessoryObject, (value: string, key: string) => {
              if (objArticle.includes(key)) {
                objFinalunite = value;
              }
            });
          }

          const articleAttribute = selectedAccessoryPackagedArticle.attributes;
          const dbsalesunit = dbSalesUnit(
            distributionChannelsAids,
            articleAttribute
          );

          const saleunit = find(articleAttribute, [
            "aid",
            attributeAids.baseunit,
          ]);

          if (dbsalesunit) {
            objSalesunit = uniteDeVenteArray(dbsalesunit);
          } else {
            if (saleunit) {
              const saleunit_value = get(saleunit.values[0], "value");
              objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
            }
          }

          let alternativeunit;
          if (objFinalunite) {
            alternativeunit = alternativeUnit(
              alternativeUnitsAids,
              articleAttribute,
              parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
              objFinalunite,
              objSalesunit,
              props,
              "accessory"
            );

            if (alternativeunit) {
              objIQuantity = alternativeunit;
            }
          }

          /**
           * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
           */
          const primaryObjectKey: string = wallId;
          let primaryArticle: any = primaryObject[primaryObjectKey] || {};

          /**
           * Extract secondary article from primary article, Key = article name
           */
          const secondaryArticletKey: string = objArticle;

          /**
           * Secondary article are sorted based on Article name
           * Extract secondary article or assign new
           * While assigning new assign surfaceArea, ratio, quantiteI to 0
           */

          let secondaryArticle: any = primaryArticle[secondaryArticletKey] || {
            levelName: levelName,
            solutionName: ovurage,
            article: objArticle,
            elementHeight: wallHeight,
            surfaceArea: 0,
            ratio: 0,
            quantite: "",
            quantiteUnit: objFinalunite,
            quantiteI: 0,
            salesUnit: objSalesunit,
            elementType: wallType,
            levelElevation: levelElevation,
            elementId: wallId,
            zone: accessoryZone.zone,
            alternativeUnitsAids: alternativeUnitsAids,
            articleAttribute: articleAttribute,
            selected: true,
            solutionId: solutionId,
          };

          /**
           * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
           */
          primaryArticle[secondaryArticletKey] = {
            ...secondaryArticle,
            surfaceArea:
              secondaryArticle.surfaceArea +
              parseFloat(objSurfaceArea.toFixed(3)),
            ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
            quantiteI: secondaryArticle.quantiteI + objIQuantity,
          };

          /**
           * Assign newly calculate primary article .
           */

          primaryObject[primaryObjectKey] = primaryArticle;
          primaryObject[primaryObjectKey]["surfaceArea"] =
            primaryArticle[secondaryArticletKey].surfaceArea;
          primaryObject[primaryObjectKey]["level"] =
            primaryArticle[secondaryArticletKey].levelName;
          primaryObject[primaryObjectKey]["levelElevation"] =
            primaryArticle[secondaryArticletKey].levelElevation;
          primaryObject[primaryObjectKey]["zone"] =
            primaryArticle[secondaryArticletKey].zone;
          primaryObject[primaryObjectKey]["solution"] =
            primaryArticle[secondaryArticletKey].solutionName;
          primaryObject[primaryObjectKey]["elementHeight"] =
            primaryArticle[secondaryArticletKey].elementHeight;
          primaryObject[primaryObjectKey]["elementId"] =
            primaryArticle[secondaryArticletKey].elementId;
          primaryObject[primaryObjectKey]["solutionId"] =
            primaryArticle[secondaryArticletKey].solutionId;
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let elementHeight = prmObj.elementHeight;
    let elementId = prmObj.elementId;
    let zone = prmObj.zone;
    let articles: AccessoryArticle[] = [];
    let solutionId = prmObj.solutionId;

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "elementHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "zone" &&
        articleIndex !== "elementId" &&
        articleIndex !== "solutionId"
      ) {
        let customRatio = 0;
        if (userRation[solutionId + articleObj.article]) {
          customRatio = userRation[solutionId + articleObj.article];
        } else {
          customRatio = articleObj.ratio;
        }

        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(elementHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.elementType,
          levelElevation: articleObj.levelElevation,
          elementId: elementId,
          zone: zone,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
          rationCustom: customRatio,
          solutionId: solutionId,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(elementHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: elementId,
      zone: zone,
    });
  });
  return collected_articles;
};

export const collectZoneAccessoryWithCustomRatio = async (
  finalExtract: any,
  defaultRatio: any,
  props: any,
  mapZoneWall: any
) => {
  const primaryObject: any = {};

  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const wallId = extractObj.wallDetails.Id;
    const solutionId = extractObj.placoSolution.oid;

    const accessoryZone = find(mapZoneWall, (m) => {
      return includes(m.walls, extractObj.wallDetails.Id);
    });

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 1) {
          const selectedArticle = find(accessoryObj.articles, function (o) {
            return (
              listToFind.findIndex((account: string) => {
                return account.startsWith(o.packagedArticle.translation);
              }, o.packagedArticle.translation) > -1
            );
          });
          if (selectedArticle) {
            selectedAccessoryPackagedArticle = selectedArticle.packagedArticle;
          }
        } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
          if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        } else {
          selectedAccessoryPackagedArticle =
            accessoryObj.articles[0].packagedArticle;
        }

        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          const objSurfaceArea = numeralConversion(wallArea); //Surface Area
          const customRatioObj: any = defaultRatio.find(
            (def: any) =>
              def.nameArticle === objArticle && def.solutionId === solutionId
          );
          const objFinalratio = customRatioObj.rationCustom
            ? customRatioObj.rationCustom === "" ||
              customRatioObj.rationCustom === "999" ||
              customRatioObj.rationCustom === "9999"
              ? 0
              : parseFloat(customRatioObj.rationCustom)
            : 0; // Ratio
          let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
          let objIQuantity: number | string = ""; //I Quantity
          let objSalesunit = ""; //J Unite De Vente

          if (objFinalunite === "") {
            const accessoryObject = articleunit["wall"]["Accessories"];
            forEach(accessoryObject, (value: string, key: string) => {
              if (objArticle.includes(key)) {
                objFinalunite = value;
              }
            });
          }

          const articleAttribute = selectedAccessoryPackagedArticle.attributes;
          const dbsalesunit = dbSalesUnit(
            distributionChannelsAids,
            articleAttribute
          );

          const saleunit = find(articleAttribute, [
            "aid",
            attributeAids.baseunit,
          ]);

          if (dbsalesunit) {
            objSalesunit = uniteDeVenteArray(dbsalesunit);
          } else {
            if (saleunit) {
              const saleunit_value = get(saleunit.values[0], "value");
              objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
            }
          }

          let alternativeunit;
          if (objFinalunite) {
            alternativeunit = alternativeUnit(
              alternativeUnitsAids,
              articleAttribute,
              parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
              objFinalunite,
              objSalesunit,
              props,
              "accessory"
            );

            if (alternativeunit) {
              objIQuantity = alternativeunit;
            }
          }

          /**
           * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
           */
          const primaryObjectKey: string = wallId;
          let primaryArticle: any = primaryObject[primaryObjectKey] || {};

          /**
           * Extract secondary article from primary article, Key = article name
           */
          const secondaryArticletKey: string = objArticle;

          /**
           * Secondary article are sorted based on Article name
           * Extract secondary article or assign new
           * While assigning new assign surfaceArea, ratio, quantiteI to 0
           */
          let secondaryArticle: any = primaryArticle[secondaryArticletKey] || {
            levelName: levelName,
            solutionName: ovurage,
            article: objArticle,
            elementHeight: wallHeight,
            surfaceArea: 0,
            ratio: 0,
            quantite: "",
            quantiteUnit: objFinalunite,
            quantiteI: 0,
            salesUnit: objSalesunit,
            elementType: wallType,
            levelElevation: levelElevation,
            elementId: wallId,
            zone: accessoryZone.zone,
            solutionId: solutionId,
          };

          /**
           * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
           */
          primaryArticle[secondaryArticletKey] = {
            ...secondaryArticle,
            surfaceArea:
              secondaryArticle.surfaceArea +
              parseFloat(objSurfaceArea.toFixed(3)),
            ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
            quantiteI: secondaryArticle.quantiteI + objIQuantity,
            alternativeUnitsAids: alternativeUnitsAids,
            articleAttribute: articleAttribute,
          };

          /**
           * Assign newly calculate primary article .
           */

          primaryObject[primaryObjectKey] = primaryArticle;
          primaryObject[primaryObjectKey]["surfaceArea"] =
            primaryArticle[secondaryArticletKey].surfaceArea;
          primaryObject[primaryObjectKey]["level"] =
            primaryArticle[secondaryArticletKey].levelName;
          primaryObject[primaryObjectKey]["levelElevation"] =
            primaryArticle[secondaryArticletKey].levelElevation;
          primaryObject[primaryObjectKey]["zone"] =
            primaryArticle[secondaryArticletKey].zone;
          primaryObject[primaryObjectKey]["solution"] =
            primaryArticle[secondaryArticletKey].solutionName;
          primaryObject[primaryObjectKey]["elementHeight"] =
            primaryArticle[secondaryArticletKey].elementHeight;
          primaryObject[primaryObjectKey]["elementId"] =
            primaryArticle[secondaryArticletKey].elementId;
          primaryObject[primaryObjectKey]["solutionId"] =
            primaryArticle[secondaryArticletKey].solutionId;
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let elementHeight = prmObj.elementHeight;
    let elementId = prmObj.elementId;
    let zone = prmObj.zone;
    let articles: AccessoryArticle[] = [];
    let solutionId = prmObj.solutionId;

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "elementHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "zone" &&
        articleIndex !== "elementId" &&
        articleIndex !== "solutionId"
      ) {
        let customRatio = 0;
        if (userRation[solutionId + articleObj.article]) {
          customRatio = userRation[solutionId + articleObj.article];
        } else {
          customRatio = articleObj.ratio;
        }

        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(elementHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.elementType,
          levelElevation: articleObj.levelElevation,
          elementId: elementId,
          zone: zone,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
          solutionId: solutionId,
          rationCustom: customRatio,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(elementHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: elementId,
      zone: zone,
    });
  });
  return collected_articles;
};

export const collectZoneRail = async (
  finalExtract: any,
  mapZoneWall: any,
  byRatio: boolean
) => {
  const rails: {
    [Key: string]: Rails;
  } = {};

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);
  let railsRevitData = await extractRailsDatafromRevit();

  forEach(finalExtract, (extractObj, extractIndex) => {
    let wallId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const solutionId = extractObj.placoSolution.oid;

    const railZone = find(mapZoneWall, (m) => {
      return includes(m.walls, extractObj.wallDetails.Id);
    });

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      forEach(extractObj.rails, function (rail, index) {
        let selectedArticle = rail.articles[0];

        if (rail.product.translation === "Rail Stil® R 48") {
          selectedArticle = find(rail.articles, {
            value: "Rail Stil® R 48/300 bot10L",
          });
        }

        if (selectedArticle) {
          const selectedPackagedArticle = selectedArticle.packagedArticle;

          if (selectedPackagedArticle) {
            const attributeDescriptors = extractObj.attributes;

            const attributeAids =
              packageAttributeSelector(attributeDescriptors);

            const ubLengthObject = find(selectedPackagedArticle.attributes, {
              aid: attributeAids.unpackedLength,
            });

            const length = ubLengthObject
              ? ubLengthObject.values[0].numericValue
              : 0;

            let revitData = filter(railsRevitData, function (o) {
              return o.wallId === parseInt(wallId);
            });

            forEach(revitData, (rvtData, rvtIndex) => {
              const productname = rvtData.productName.includes(
                "Rail Stil® R 48"
              )
                ? "Rail Stil® R 48/300 bot10L"
                : rvtData.productName;
              if (
                selectedPackagedArticle.translation
                  .replace(/[\s'®]/g, "")
                  .replace(/[^a-zA-Z0-9]\s/g, "")
                  .includes(
                    productname
                      .replace(/[\s'®]/g, "")
                      .replace(/[^a-zA-Z0-9]\s/g, "")
                  )
              ) {
                let wallH = numeralConversion(round(wallHeight, 2));
                let wallA = numeralConversion(round(wallArea, 2));

                let newRailRow: Rails = rails[`${wallId}`] || {
                  level: levelName,
                  solution: ovurage,
                  elementId: wallId,
                  articleName: selectedPackagedArticle.translation,
                  elementHeight: numeralConversion(round(wallHeight, 2)),
                  elementArea: numeralConversion(round(wallArea, 2)),
                  length: length,
                  levelElevation: levelElevation,
                  articles: {},
                  zone: railZone.zone,
                };

                let sortedRevitData = sortBy(rvtData.data, [
                  function (o) {
                    return round(o.length / 1000, 2);
                  },
                ]);

                let customRatio = 0;
                if (
                  userRation[solutionId + selectedPackagedArticle.translation]
                ) {
                  customRatio =
                    userRation[
                      solutionId + selectedPackagedArticle.translation
                    ];
                } else {
                  customRatio = 0;
                }

                for (let dtt of sortedRevitData) {
                  let length_in_m = ceil(dtt.length / 1000, 2);
                  let selectedArticle = newRailRow.articles[
                    `${selectedPackagedArticle.translation}/${length_in_m}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    articleName: selectedPackagedArticle.translation,
                    elementHeight: wallH,
                    elementArea: wallA,
                    articleRevitLength: length_in_m,
                    nbUnit: 0,
                    nbTotal: "",
                    lengthTotal: 0,
                    articleLength: length,
                    elementType: wallType,
                    levelElevation: levelElevation,
                    zone: railZone.zone,
                    ratio: 0,
                    nonLayout: false,
                    selected: true,
                    rationCustom: customRatio,
                  };

                  selectedArticle.nbUnit =
                    selectedArticle.nbUnit + dtt.quantity;

                  newRailRow.articles[
                    `${selectedPackagedArticle.translation}/${length_in_m}`
                  ] = selectedArticle;

                  rails[`${wallId}`] = newRailRow;
                }
              }
            });
          }
        }
      });
    } else {
      forEach(extractObj.rails, function (rail, index) {
        var selectedArticle = rail.articles[0];

        if (rail.product.translation === "Rail Stil® R 48") {
          selectedArticle = find(rail.articles, {
            value: "Rail Stil® R 48/300 bot10L",
          });
        }

        if (selectedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const ubLengthObject = find(
            selectedArticle.packagedArticle.attributes,
            {
              aid: attributeAids.unpackedLength,
            }
          );

          const length = ubLengthObject
            ? ubLengthObject.values[0].numericValue
            : 0;

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            rail.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          const objFinalratio = ration
            ? ration === "" || ration === "999" || ration === "9999"
              ? 0
              : parseFloat(ration)
            : 0;

          let wallH = numeralConversion(round(wallHeight, 2));
          let wallA = numeralConversion(round(wallArea, 2));

          let newRailRow: Rails = rails[`${wallId}`] || {
            level: levelName,
            solution: ovurage,
            elementId: wallId,
            articleName: selectedArticle.value,
            elementHeight: numeralConversion(round(wallHeight, 2)),
            elementArea: numeralConversion(round(wallArea, 2)),
            length: length,
            levelElevation: levelElevation,
            articles: {},
            zone: railZone.zone,
          };

          let customRatio = 0;
          if (userRation[solutionId + selectedArticle.value]) {
            customRatio = userRation[solutionId + selectedArticle.value];
          } else {
            customRatio = objFinalratio;
          }

          let collected_artcle = newRailRow.articles[
            `${selectedArticle.value}/${0}`
          ] || {
            level: levelName,
            solution: ovurage,
            articleName: selectedArticle.value,
            elementHeight: wallH,
            elementArea: wallA,
            articleRevitLength: 0,
            nbUnit: 0,
            nbTotal: "",
            lengthTotal: 0,
            articleLength: length,
            elementType: wallType,
            levelElevation: levelElevation,
            ratio: objFinalratio,
            nonLayout: true,
            zone: railZone.zone,
            selected: true,
            rationCustom: customRatio,
          };

          newRailRow.articles[`${selectedArticle.value}/${0}`] =
            collected_artcle;

          rails[`${wallId}`] = newRailRow;
        }
      });
    }
  });

  let railArray: Rails[] = [];

  forEach(rails, (plaq: Rails, key: string) => {
    railArray.push(plaq);
  });

  return railArray;
};

export const collectZoneMontant = async (
  finalExtract: any,
  mapZoneWall: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: { [Key: string]: Montant } = {};
  let montantsRevitData = await extractFramesDatafromRevit();
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    let wallId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const solutionId = extractObj.placoSolution.oid;

    const montantZone = find(mapZoneWall, (m) => {
      return includes(m.walls, extractObj.wallDetails.Id);
    });

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      let revitData = filter(montantsRevitData, function (o) {
        return o.wallId === parseInt(wallId);
      });

      forEach(revitData, (revitMontant, index) => {
        forEach(extractObj.montants, (montant, index) => {
          const selectedArticle = find(montant.articles, function (o) {
            return revitMontant.productName === o.value;
          });

          if (selectedArticle) {
            const selectedPackagedArticle = selectedArticle.packagedArticle;

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractObj.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              let wallH = numeralConversion(round(wallHeight, 2));
              let wallA = numeralConversion(round(wallArea, 2));

              let newMontantRow: Montant = collected_articles[`${wallId}`] || {
                level: levelName,
                solution: ovurage,
                articleName: selectedPackagedArticle.translation,
                elementHeight: wallH,
                elementArea: wallA,
                elementType: wallType,
                articles: {},
                levelElevation: levelElevation,
                elementId: wallId,
                zone: montantZone.zone,
                articleLists: null,
              };

              const articleLength = find(selectedPackagedArticle.attributes, [
                "aid",
                attributeAids.unpackedLength,
              ]);

              if (
                selectedPackagedArticle.translation.includes(
                  revitData[0].productName
                )
              ) {
                let sortedRevitData = sortBy(revitData[0].data, [
                  function (o) {
                    return round(o.length / 1000, 2);
                  },
                ]);

                for (let dtt of sortedRevitData) {
                  const length_in_m = dtt.length / 1000;
                  const quantity = dtt.quantity;

                  let articleRevitHeight = round(length_in_m, 2);
                  let calArticleLength: number = 0;

                  if (articleLength) {
                    const metreValue = find(articleLength.values, [
                      "unit",
                      "m",
                    ]);

                    if (metreValue) {
                      const mValue = get(metreValue, "value");
                      calArticleLength = mValue;
                    } else {
                      const milliMetreValue = find(articleLength.values, [
                        "unit",
                        "mm",
                      ]);
                      if (milliMetreValue) {
                        const mmValue = get(milliMetreValue, "value");
                        calArticleLength = parseFloat(mmValue) / 1000;
                      }
                    }
                  }

                  let customRatio = 0;
                  if (
                    userRation[solutionId + selectedPackagedArticle.translation]
                  ) {
                    customRatio =
                      userRation[
                        solutionId + selectedPackagedArticle.translation
                      ];
                  } else {
                    customRatio = 0;
                  }

                  let collected_article = newMontantRow.articles[
                    `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    articleName: selectedPackagedArticle.translation,
                    elementHeight: wallH,
                    elementArea: wallA,
                    articleRevitHeight: articleRevitHeight,
                    nbUnit: 0,
                    nbTotal: 0,
                    articleLength: calArticleLength,
                    elementType: wallType,
                    levelElevation: levelElevation,
                    zone: montantZone.zone,
                    ratio: 0,
                    nonLayout: false,
                    selected: true,
                    rationCustom: customRatio,
                  };

                  collected_article.nbUnit =
                    collected_article.nbUnit + numeralConversion(quantity);

                  newMontantRow.articles[
                    `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                  ] = collected_article;

                  collected_articles[`${wallId}`] = newMontantRow;
                }
              }
            }
          }
        });
      });
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === wallId;
      });

      if (revitData) {
        const userMontantObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Montant"
        );

        if (userMontantObj) {
          if (userMontantObj.Value) {
            const userMontantArray = userMontantObj.Value.split(";");

            if (userMontantArray) {
              userMontantArray.forEach((userMon: string) => {
                forEach(extractObj.montants, (montant, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    montant.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = montant.articles.find(
                    (plart: any) => plart.value === userMon
                  );

                  if (selectedArticle) {
                    let newMontantRow: Montant = collected_articles[
                      `${wallId}/${montant.product.oid}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      elementId: wallId,
                      articleName: montant.product.translation,
                      elementHeight: numeralConversion(round(wallHeight, 2)),
                      elementArea: numeralConversion(round(wallArea, 2)),
                      articles: {},
                      levelElevation: levelElevation,
                      elementType: wallType,
                      zone: montantZone.zone,
                      solutionId: solutionId,
                      articleId: montant.product.oid,
                      articleLists: null,
                    };

                    let collectedNewArticles: any = [];

                    forEach(montant.articles, (moarticle, moArticleIndex) => {
                      const attributeDescriptors = extractObj.attributes;

                      const attributeAids =
                        packageAttributeSelector(attributeDescriptors);

                      const articleLength = find(
                        moarticle.packagedArticle.attributes,
                        ["aid", attributeAids.unpackedLength]
                      );

                      let calArticleLength: number = 0;

                      if (articleLength) {
                        const metreValue = find(articleLength.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue = get(metreValue, "value");
                          calArticleLength = mValue;
                        } else {
                          const milliMetreValue = find(articleLength.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            calArticleLength = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let customRatio = 0;
                      if (userRation[solutionId + moarticle.value]) {
                        customRatio = userRation[solutionId + moarticle.value];
                      } else {
                        customRatio = objFinalratio;
                      }

                      collectedNewArticles.push({
                        level: levelName,
                        solution: ovurage,
                        articleName: moarticle.value,
                        elementHeight: numeralConversion(round(wallHeight, 2)),
                        elementArea: numeralConversion(round(wallArea, 2)),
                        articleRevitHeight: 0,
                        nbUnit: 0,
                        nbTotal: 0,
                        articleLength: calArticleLength,
                        elementType: wallType,
                        levelElevation: levelElevation,
                        ratio: objFinalratio,
                        zone: montantZone.zone,
                        nonLayout: true,
                        selected: selectedArticle.value === moarticle.value,
                        rationCustom: customRatio,
                        key: moarticle.value,
                        value: moarticle.value,
                        text: moarticle.value,
                      });
                    });

                    newMontantRow.articleLists = collectedNewArticles;
                    collected_articles[`${wallId}/${montant.product.oid}`] =
                      newMontantRow;
                  }
                });
              });
            }
          }
        }
      }
    }
  });

  /**
   * Sort montants by level -> solution
   */
  let montantArray: Montant[] = [];

  forEach(collected_articles, (plaq: Montant, key: string) => {
    montantArray.push(plaq);
  });

  return montantArray;
};

export const collectZonePlaque = async (
  finalExtract: any,
  mapZoneWall: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: {
    [Key: string]: Plaqs;
  } = {};

  let plaqueRevitData = await extractPlasterboardDataForWallfromRevit();

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    let wallId = extractObj.wallDetails.Id;
    const levelName = extractObj.wallDetails.LevelName;
    const levelElevation = extractObj.wallDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.wallDetails.WallType;
    const wallHeight = extractObj.wallDetails.Height / 1000;
    const wallArea = extractObj.wallDetails.Area;
    const solutionId = extractObj.placoSolution.oid;

    const plaqueZone = find(mapZoneWall, (m) => {
      return includes(m.walls, extractObj.wallDetails.Id);
    });

    let attributesId: any = getLayoutAttributesAid(extractObj.attributes);

    let layers = extractObj.placoSolution.attributes.filter(
      (e: any) => e.aid === attributesId.layers
    );
    let layerNames = compact(
      layers.map((ln: any) => {
        const lnmm = ln.subAttributes.find(
          (sl: any) => sl.technicalName === "GFR-Layer name"
        );
        return lnmm.values[0];
      })
    );

    let layerCount: any = groupBy(layerNames, "value");
    layerCount = keyBy(
      map(layerCount, (ly, key) => {
        return { key: key, count: ly.length };
      }),
      "key"
    );

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      forEach(extractObj.plaques, (plaque, index) => {
        let revitData = filter(plaqueRevitData, function (o) {
          return o.wallId === parseInt(wallId);
        });

        if (revitData.length > 0) {
          forEach(revitData, (revtData, index) => {
            let selectedArticle = find(plaque.articles, {
              value: revtData.productName,
            });

            if (selectedArticle) {
              const selectedPackagedArticle = selectedArticle.packagedArticle;

              if (selectedPackagedArticle) {
                const attributeDescriptors = extractObj.attributes;

                const attributeAids =
                  packageAttributeSelector(attributeDescriptors);

                let newPlaqRow: Plaqs = collected_articles[`${wallId}`] || {
                  level: levelName,
                  solution: ovurage,
                  elementId: wallId,
                  articleName: selectedPackagedArticle.translation,
                  elementHeight: numeralConversion(round(wallHeight, 2)),
                  elementArea: numeralConversion(round(wallArea, 2)),
                  articles: {},
                  levelElevation: levelElevation,
                  zone: plaqueZone.zone,
                  articleLists: null,
                };

                const articleWidth = find(selectedPackagedArticle.attributes, [
                  "aid",
                  attributeAids.unpackedwidth,
                ]);
                const articleLength = find(selectedPackagedArticle.attributes, [
                  "aid",
                  attributeAids.unpackedLength,
                ]);

                if (
                  selectedPackagedArticle.translation
                    .replace(/[\s'®]/g, "")
                    .replace(/[^a-zA-Z0-9]\s/g, "")
                    .includes(
                      revtData.productName
                        .replace(/[\s'®]/g, "")
                        .replace(/[^a-zA-Z0-9]\s/g, "")
                    )
                ) {
                  /**
                   * Sort data by length
                   */

                  let sortedRevitData = sortBy(revtData.data, [
                    function (o) {
                      return round(o.length / 1000, 2);
                    },
                  ]).reverse();

                  for (let dtt of sortedRevitData) {
                    const length_in_m = round(dtt.length / 1000, 2);
                    const height_in_m = round(dtt.height / 1000, 2);
                    const quantity = dtt.quantity;
                    const wallH = numeralConversion(round(wallHeight, 2));
                    const wallA = numeralConversion(round(wallArea, 2));
                    let placoArticleWidth: number = 0;

                    if (articleWidth) {
                      const metreValue = find(articleWidth.values, [
                        "unit",
                        "m",
                      ]);

                      if (metreValue) {
                        const mValue: string = get(metreValue, "value");
                        placoArticleWidth = parseFloat(mValue);
                      } else {
                        const milliMetreValue = find(articleWidth.values, [
                          "unit",
                          "mm",
                        ]);
                        if (milliMetreValue) {
                          const mmValue = get(milliMetreValue, "value");
                          placoArticleWidth = parseFloat(mmValue) / 1000;
                        }
                      }
                    }

                    let placoArticleLength: number = 0;

                    if (articleLength) {
                      const metreValue = find(articleLength.values, [
                        "unit",
                        "m",
                      ]);

                      if (metreValue) {
                        const mValue: string = get(metreValue, "value");
                        placoArticleLength = parseFloat(mValue);
                      } else {
                        const milliMetreValue = find(articleLength.values, [
                          "unit",
                          "mm",
                        ]);
                        if (milliMetreValue) {
                          const mmValue = get(milliMetreValue, "value");
                          placoArticleLength = parseFloat(mmValue) / 1000;
                        }
                      }
                    }

                    let selectedArticle = newPlaqRow.articles[
                      `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      articleName: selectedPackagedArticle.translation,
                      elementHeight: wallH,
                      elementArea: wallA,
                      articleRevitLength: length_in_m,
                      articleRevitHeight: height_in_m,
                      nbUnit: 0,
                      nbTotal: "",
                      articleWidth: placoArticleWidth,
                      articleLength: placoArticleLength,
                      elementType: wallType,
                      levelElevation: levelElevation,
                      zone: plaqueZone.zone,
                    };

                    selectedArticle.nbUnit =
                      selectedArticle.nbUnit + numeralConversion(quantity);

                    newPlaqRow.articles[
                      `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                    ] = selectedArticle;

                    collected_articles[`${wallId}`] = newPlaqRow;
                  }
                }
              }
            }
          });
        }
      });
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === wallId;
      });

      if (revitData) {
        const userPlaqueObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Plaque"
        );

        if (userPlaqueObj) {
          if (userPlaqueObj.Value) {
            const userPlaqueArray = userPlaqueObj.Value.split(";");

            if (userPlaqueArray) {
              userPlaqueArray.forEach((userPlq: string) => {
                forEach(extractObj.plaques, (plaque, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    plaque.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = plaque.articles.find(
                    (plart: any) => plart.value === userPlq
                  );

                  try {
                    if (selectedArticle) {
                      let newPlaqRow: Plaqs = collected_articles[
                        `${wallId}/${plaque.product.oid}`
                      ] || {
                        level: levelName,
                        solution: ovurage,
                        elementId: wallId,
                        articleName: plaque.product.translation,
                        elementHeight: numeralConversion(round(wallHeight, 2)),
                        elementArea: numeralConversion(round(wallArea, 2)),
                        articles: {},
                        levelElevation: levelElevation,
                        zone: plaqueZone.zone,
                        articleLists: null,
                        solutionId: solutionId,
                        articleId: plaque.product.oid,
                        elementType: wallType,
                      };

                      let collectedNewArticles: any = [];
                      forEach(plaque.articles, (plarticle, plArticleIndex) => {
                        const attributeDescriptors = extractObj.attributes;

                        const attributeAids =
                          packageAttributeSelector(attributeDescriptors);

                        const articleWidth = find(
                          plarticle.packagedArticle.attributes,
                          ["aid", attributeAids.unpackedwidth]
                        );
                        const articleLength = find(
                          plarticle.packagedArticle.attributes,
                          ["aid", attributeAids.unpackedLength]
                        );

                        let placoArticleWidth: number = 0;
                        if (articleWidth) {
                          const metreValue = find(articleWidth.values, [
                            "unit",
                            "m",
                          ]);

                          if (metreValue) {
                            const mValue: string = get(metreValue, "value");
                            placoArticleWidth = parseFloat(mValue);
                          } else {
                            const milliMetreValue = find(articleWidth.values, [
                              "unit",
                              "mm",
                            ]);
                            if (milliMetreValue) {
                              const mmValue = get(milliMetreValue, "value");
                              placoArticleWidth = parseFloat(mmValue) / 1000;
                            }
                          }
                        }

                        let placoArticleLength: number = 0;

                        if (articleLength) {
                          const metreValue = find(articleLength.values, [
                            "unit",
                            "m",
                          ]);

                          if (metreValue) {
                            const mValue: string = get(metreValue, "value");
                            placoArticleLength = parseFloat(mValue);
                          } else {
                            const milliMetreValue = find(articleLength.values, [
                              "unit",
                              "mm",
                            ]);
                            if (milliMetreValue) {
                              const mmValue = get(milliMetreValue, "value");
                              placoArticleLength = parseFloat(mmValue) / 1000;
                            }
                          }
                        }

                        let customRatio = 0;
                        if (userRation[solutionId + plarticle.value]) {
                          customRatio =
                            userRation[solutionId + plarticle.value];
                        } else {
                          customRatio = objFinalratio;
                        }

                        collectedNewArticles.push({
                          level: levelName,
                          solution: ovurage,
                          articleName: plarticle.value,
                          elementHeight: numeralConversion(
                            round(wallHeight, 2)
                          ),
                          elementArea: numeralConversion(round(wallArea, 2)),
                          articleRevitLength: 0,
                          articleRevitHeight: 0,
                          nbUnit: layerCount[plaque.product.translation].count,
                          nbTotal: "",
                          articleWidth: placoArticleWidth,
                          articleLength: placoArticleLength,
                          elementType: wallType,
                          levelElevation: levelElevation,
                          ratio: objFinalratio,
                          nonLayout: true,
                          zone: plaqueZone.zone,
                          selected: selectedArticle.value === plarticle.value,
                          rationCustom: customRatio,
                          key: plarticle.value,
                          value: plarticle.value,
                          text: plarticle.value,
                        });

                        // let collectedarticle = newPlaqRow.articles[
                        //   `${selectedArticle.value}/${0}/${1}`
                        // ] || {
                        //   level: levelName,
                        //   solution: ovurage,
                        //   articleName: selectedArticle.value,
                        //   elementHeight: numeralConversion(round(wallHeight, 2)),
                        //   elementArea: numeralConversion(round(wallArea, 2)),
                        //   articleRevitLength: 0,
                        //   articleRevitHeight: 0,
                        //   nbUnit: layerCount[plaque.product.translation].count,
                        //   nbTotal: "",
                        //   articleWidth: placoArticleWidth,
                        //   articleLength: placoArticleLength,
                        //   elementType: wallType,
                        //   levelElevation: levelElevation,
                        //   ratio: objFinalratio,
                        //   nonLayout: true,
                        //   zone: plaqueZone.zone,
                        // };
                      });

                      newPlaqRow.articleLists = collectedNewArticles;
                      collected_articles[`${wallId}/${plaque.product.oid}`] =
                        newPlaqRow;
                    }
                  } catch (ex) {
                    console.error(
                      `For ${ovurage} plaque name ${
                        plaque.product.translation
                      } is not present in layers ${Object.keys(
                        layerCount
                      ).toString()}`
                    );
                  }

                  // if (
                  //   selectedArticle &&
                  //   layerCount[plaque.product.translation]
                  // ) {

                  //   newPlaqRow.articles[`${selectedArticle.value}/${0}/${1}`] =
                  //     collectedarticle;
                  //   collected_articles[`${wallId}`] = newPlaqRow;
                  // } else {
                  //   console.error(
                  //     `For ${ovurage} plaque name ${
                  //       plaque.product.translation
                  //     } is not present in layers ${Object.keys(
                  //       layerCount
                  //     ).toString()}`
                  //   );
                  // }
                });
              });
            }
          }
        }
      }
    }
  });

  let plaqArray: Plaqs[] = [];

  forEach(collected_articles, (plaq: Plaqs, key: string) => {
    plaqArray.push(plaq);
  });

  return plaqArray;
};

export const collectZoneData = async (
  processedWallParams: any,
  extractedSolutionProduct: any,
  wallsData: any,
  setUnitArray: any,
  mapZoneWall: any,
  unitConversionArray: any,
  byRatio: boolean = false
) => {
  let finalExtract: any = [];

  await forEach(processedWallParams, (wall, index) => {
    const wallDetails = find(wallsData, { Id: wall.Id });
    mapZoneWall.forEach((fin: any) => {
      fin.walls.forEach((id: any) => {
        if (wallDetails.Id === id) {
          wallDetails.zone = fin.zone;
        }
      });
    });
    const placoSolution = find(extractedSolutionProduct, (slProduct) => {
      return slProduct.solutionProduct.technicalName === wall.Params[0].Value;
    });

    const wools = filter(placoSolution.products, (prd) => {
      return prd.type === "wool";
    });

    const accessories = filter(placoSolution.products, (prd) => {
      return (
        prd.type === "coating" ||
        prd.type === "joints" ||
        prd.type === "resilent" ||
        prd.type === "screw"
      );
    });

    const plaques = filter(placoSolution.products, (prd) => {
      return prd.type === "plaque";
    });
    const montants = filter(placoSolution.products, (prd) => {
      return prd.type === "montant";
    });
    const rails = filter(placoSolution.products, (prd) => {
      return prd.type === "rail";
    });

    finalExtract.push({
      wallDetails: wallDetails,
      placoSolution: placoSolution.solutionProduct,
      wools: wools,
      accessories: accessories,
      plaques: plaques,
      montants: montants,
      rails: rails,
      layoutPossible: placoSolution.layoutPossible,
      attributes: placoSolution.attributes,
      relationAttributes: placoSolution.relationAttributes,
      relations: placoSolution.relations,
    });
  });

  let ratioArray: IRationTable[] = [];
  let ratioListArray: any = [];
  const collected_wool = await collectZoneWool(finalExtract, mapZoneWall);
  collected_wool.forEach((colwool: woolType) => {
    if (colwool.articleLists) {
      const selectedObj = find(colwool.articleLists, { selected: true });
      if (selectedObj) {
        ratioListArray.push({
          typeArticle: "Produits isolants",
          nameArticle: colwool.ProductName,
          order: 4,
          placoSolution: colwool.Ovurage,
          solutionId: colwool.solutionId,
          unit: "M2",
          articleId: 1,
          articleList: colwool.articleLists,
          ratioDefault: selectedObj.ratio,
          rationCustom: selectedObj.rationCustom,
          defaultValue: selectedObj.articleName,
        });
      } else {
        ratioListArray.push({
          typeArticle: "Produits isolants",
          nameArticle: colwool.ProductName,
          ratioDefault: colwool.Ratio,
          rationCustom: colwool.rationCustom,
          order: 4,
          placoSolution: colwool.Ovurage,
          solutionId: colwool.solutionId,
          unit: "M2",
          articleList: null,
          defaultValue: colwool.ProductName,
        });
      }
    } else {
      ratioListArray.push({
        typeArticle: "Produits isolants",
        nameArticle: colwool.ProductName,
        ratioDefault: colwool.Ratio,
        rationCustom: colwool.rationCustom,
        order: 4,
        placoSolution: colwool.Ovurage,
        solutionId: colwool.solutionId,
        unit: "M2",
        articleList: null,
        defaultValue: colwool.ProductName,
      });
    }
  });

  const collected_accessory = await collectZoneAccessory(
    finalExtract,
    {
      setUnitConversionArray: setUnitArray,
      unitConversionArray: unitConversionArray,
    },
    mapZoneWall
  );

  collected_accessory.forEach((colacc: Accessory) => {
    if (Object.keys(colacc.articles).length > 0) {
      forEach(colacc.articles, (clarticle: AccessoryArticle) => {
        ratioListArray.push({
          typeArticle: "Accessories",
          nameArticle: clarticle.article,
          ratioDefault: clarticle.ratio,
          rationCustom: clarticle.rationCustom,
          order: 5,
          placoSolution: colacc.solution,
          unit: clarticle.unitI,
          solutionId: clarticle.solutionId,
          articleList: null,
          defaultValue: clarticle.article,
        });
      });
    }
  });

  const collected_rail = await collectZoneRail(
    finalExtract,
    mapZoneWall,
    byRatio
  );

  forEach(collected_rail, (colrail: Rails) => {
    if (Object.keys(colrail.articles).length > 0) {
      forEach(colrail.articles, (clarticle: RailArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Ossatures Horizontal",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 3,
            placoSolution: colrail.solution,
            solutionId: clarticle.solutionId,
            unit: "M",
            articleList: null,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  let collected_montant: Montant[] = await collectZoneMontant(
    finalExtract,
    mapZoneWall,
    processedWallParams,
    byRatio
  );
  collected_montant.forEach((colmont: Montant) => {
    if (colmont.articleLists) {
      const selectedObj = find(colmont.articleLists, { selected: true });

      ratioListArray.push({
        typeArticle: "Ossatures Vertical",
        nameArticle: colmont.articleName,
        placoSolution: colmont.solution,
        unit: "M",
        articleList: colmont.articleLists,
        articleId: colmont.articleId,
        solutionId: colmont.solutionId,
        order: 2,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        defaultValue: selectedObj.articleName,
      });
    } else if (Object.keys(colmont.articles).length > 0) {
      forEach(colmont.articles, (clarticle: MontantArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Ossatures Vertical",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 2,
            placoSolution: colmont.solution,
            unit: "M",
            articleList: null,
            solutionId: colmont.solutionId,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  let collected_plaques: Plaqs[] = await collectZonePlaque(
    finalExtract,
    mapZoneWall,
    processedWallParams,
    byRatio
  );

  forEach(collected_plaques, (colplaq: Plaqs) => {
    if (colplaq.articleLists) {
      const selectedObj = find(colplaq.articleLists, { selected: true });

      ratioListArray.push({
        typeArticle: "Produits plâtre",
        nameArticle: colplaq.articleName,
        placoSolution: colplaq.solution,
        unit: "M2",
        articleList: colplaq.articleLists,
        articleId: colplaq.articleId,
        solutionId: colplaq.solutionId,
        order: 1,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        defaultValue: selectedObj.articleName,
      });
    } else if (Object.keys(colplaq.articles).length > 0) {
      forEach(colplaq.articles, (clarticle: PlaqArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Produits plâtre",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 1,
            placoSolution: colplaq.solution,
            unit: "M2",
            articleList: null,
            solutionId: colplaq.solutionId,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  if (byRatio) {
    ratioListArray = uniqWith(
      ratioListArray,
      (a: any, b: any) =>
        a.solutionId === b.solutionId && a.nameArticle === b.nameArticle
    );
  } else {
    ratioListArray = uniqWith(
      ratioListArray,
      (a: any, b: any) =>
        a.solutionId === b.solutionId && a.defaultValue === b.defaultValue
    );
  }

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  return {
    collected_wool,
    collected_accessory,
    collected_rail,
    collected_montant,
    collected_plaques,
    default_ratio: [],
    finalExtract: finalExtract,
    userRatio: userRation,
    mapZoneWall: mapZoneWall,
    processedWallParams: processedWallParams,
    ratioListArray: ratioListArray,
  };
};
