import {
    BufferType,
    ContextDetailsType,
    OptionsDetailsType,
    ParameterDetailsType,
    SetBufferActionType,
    SetBufferDateActionType,
    SetContextActionType,
    SetEditBufferActionType,
    SetMasterFrameActionType,
    SetOptionsActionType,
    SetParameterActionType,
    SetSelectionActionType,
    SET_BUFFER_DATE_DETAILS,
    SET_BUFFER_DETAILS,
    SET_CONTEXT_BUFFER,
    SET_EDIT_BUFFER,
    SET_MASTER_FRAME_BUFFER,
    SET_OPTIONS_BUFFER,
    SET_PARAMETER_BUFFER,
    SET_SELECTION_BUFFER,
    WallDetailsType
} from "../Types"

export const SetMasterFrame = (data: WallDetailsType): SetMasterFrameActionType => {
    return {
        type: SET_MASTER_FRAME_BUFFER,
        payload: data
    }
}

export const SetSelection = (data: WallDetailsType[]): SetSelectionActionType => {
    return {
        type: SET_SELECTION_BUFFER,
        payload: data
    }
}

export const SetParameter = (data: ParameterDetailsType): SetParameterActionType => {
    return {
        type: SET_PARAMETER_BUFFER,
        payload: data
    }
}

export const SetContext = (data: ContextDetailsType): SetContextActionType => {
    return {
        type: SET_CONTEXT_BUFFER,
        payload: data
    }
}

export const SetOptions = (data: OptionsDetailsType): SetOptionsActionType => {
    return {
        type: SET_OPTIONS_BUFFER,
        payload: data
    }
}

export const SetBufferDetails = (data: any): SetBufferActionType => {
    return {
        type: SET_BUFFER_DETAILS,
        payload: data
    }
}

export const SetBufferDateDetails = (data: any): SetBufferDateActionType => {
    return {
        type: SET_BUFFER_DATE_DETAILS,
        payload: data
    }
}

export const SetEditBuffer = (data: BufferType): SetEditBufferActionType => {
    return {
        type: SET_EDIT_BUFFER,
        payload: data
    }
}