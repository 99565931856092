import { Action } from 'redux'
import { ProductDetailData } from '../../../../../RevitJS/Types/BddTypes';

export const LOAD_SYSTEMS = "LOAD_UPDATED_SYSTEMS";
export const LOAD_SYSTEMS_SUCCESS = "LOAD_SYSTEMS_SUCCESS";
export const LOAD_SYSTEMS_ERROR = "LOAD_UPDATED_SYSTEMS_ERROR";
export const LOAD_SYSTEMS_TO_UPDATED = "LOAD_UPDATED_SYSTEMS";
export const LOAD_UPDATED_SYSTEMS_SUCCESS = "LOAD_UPDATED_SYSTEMS_SUCCESS";
export const LOAD_SYSTEMS_TO_UPDATED_ERROR = "LOAD_UPDATED_SYSTEMS_ERROR";

export interface SystemsState {
  loadingSystems: boolean,
  currentSystemsToUpdate: any[],
  data: {
    liningWallsDetails: ProductDetailData[],
    partitionsDetails: ProductDetailData[],
    ceilingsDetails: ProductDetailData[],
    gainesTechniquesDetails: ProductDetailData[]
  }
}

interface LoadSystemsAction extends Action {
  type: typeof LOAD_SYSTEMS
}


interface LoadSystemsSuccessAction extends Action {
  type: typeof LOAD_SYSTEMS_SUCCESS;
  data: {
    liningWallsDetails: ProductDetailData[],
    partitionsDetails: ProductDetailData[],
    ceilingsDetails: ProductDetailData[],
    gainesTechniquesDetails: ProductDetailData[]
  }
}

interface LoadSystemsErrorAction extends Action {
  type: typeof LOAD_SYSTEMS_ERROR;
}


interface LoadSystemsToUpdateAction extends Action {
  type: typeof LOAD_SYSTEMS_TO_UPDATED
}


interface LoadSystemsToUpdateSuccessAction extends Action {
  type: typeof LOAD_UPDATED_SYSTEMS_SUCCESS;
  currentSystems: any[]
}

interface LoadSystemsToUpdateErrorAction extends Action {
  type: typeof LOAD_SYSTEMS_TO_UPDATED_ERROR;
}

export type CustomParameter = {
  solution: string;
  elementType: string;
  paramData: GroupParamData[];
} | undefined;

export type GroupParamData = {
  category: string;
  key: string;
  value: string | null;
};

export type SystemsActionTypes = LoadSystemsAction | LoadSystemsErrorAction | LoadSystemsSuccessAction | LoadSystemsToUpdateAction | LoadSystemsToUpdateSuccessAction | LoadSystemsToUpdateErrorAction

