import { activeActionType } from "../Types/activeType";

const activeReducer =(state = "firstCard", action : activeActionType) =>
    {
      switch(action.type)
      {
          case 'active':
             return action.payload ;
          case 'deactive':
             return action.payload;
          default:
            return state;
      }
    };
  
    export default activeReducer;