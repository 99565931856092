import React from "react";
import { LevelData, TypeData } from "../../../../RevitJS/Types/StoreTypes";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import {
  Segment,
  Button,
  Menu,
} from "semantic-ui-react";
import { SelectionStore } from "../../Selection/Reducers";
import { connect, ConnectedProps } from "react-redux";
import { Routes } from "./root";
import _ from "lodash";
import GroupSelectorBody from "./GroupSelectorBody";
import {
  setSelectorType,
  toggleBufferUpdate,
  selectLevel,
} from "../../Selection/Actions";
import { selectorTp } from "../../Selection/Actions/types";
import { saveGroupSelection } from "../Actions";

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  let typesData: TypeData[] = [];
  typesData = typesData.concat(state.wallTypesData, state.ceilingTypesData);
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    selectorType: state.selectorType,
    bufferSelection: state.bufferSelection,
    module: ownProps.module,
    typesData: typesData,
  };
};

const mapDispatch = {
  setSelectorType,
  saveGroupSelection,
  toggleBufferUpdate,
  selectLevel: selectLevel,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  Icon: string;
  language: string;
  wording: {
    wallTypes: { [key: string]: string };
    ceilingTypes: { [key: string]: string };
    selection: { [key: string]: string };
    levels: { [key: string]: string };
    back: { [key: string]: string };
    validate: { [key: string]: string };
    stepOne: { [key: string]: string };
    partitionWalls: { [key: string]: string };
    ceilings: { [key: string]: string };
    name: { [key: string]: string };
  };
  levelsData: LevelData[];
  typesData: TypeData[];
  selectorType: selectorTp;
  setRoute: any;
  bufferSelection: any;
  module: string;
  setSelectorType: any;
  saveGroupSelection: any;
  toggleBufferUpdate: any;
  selectLevel: any;
};

export class GroupSelector extends React.Component<Props> {
  state = {
    savepopup: false,
  };

  componentDidMount = () => {
    // api.windowsHandler.resizeWindow(1250, 700);   
  };

  public static defaultProps = {
    wording: {
      levels: {
        French: "Niveaux",
        English: "Levels",
      },
      ceilingTypes: {
        French: " de types plafonds",
        English: "Ceiling types",
      },
      wallTypes: {
        French: "de types murs",
        English: "Wall types",
      },
      selection: {
        French: "Sélection",
        English: "Selection",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
      stepOne: {
        French: "Etape 1/2 : Sélection des types de systèmes",
        English: "Step 1/2: Type system selection",
      },
      partitionWalls: {
        French: "Murs",
        English: "Partition Walls",
      },
      ceilings: {
        French: "Plafonds",
        English: "Ceilings",
      },
      yes: {
        French: "Oui",
        English: "Yes",
      },
      no: {
        French: "Non",
        English: "No",
      },
      message: {
        French:
          "Voulez-vous enregistrer 'la sélection' ou 'le calepinage' ou 'le dossier technique' en cours de modification avant de quitter ?",
        English:
          "Do you want to save the 'selection' or 'the layout' or 'the technical file' being modified before exiting?",
      },
      name: {
        French: "Suppression",
        English: "Suppression",
      }
    },
  };

  backHandler = () => {
    this.props.selectLevel([]);
    this.props.setRoute(Routes.ROOT);
  };

  validateHandler = () => {
    // this.props.toggleBufferUpdate(false);
    this.props.saveGroupSelection();
    this.props.setRoute(Routes.ROOT);
  };

  headerNameDisplay = (module: string, selectionname: string): string => {
    if (module.toLowerCase() !== "selection") {
      return module + " > " + selectionname;
    }
    return selectionname;
  };

  toggleModal = (modalName: string, action: boolean) => {
    this.setState({ [modalName]: action });
  };

  selectorTypes = () => {
    switch (this.props.selectorType) {
      case "wall":
        return this.props.wording.wallTypes[this.props.language];
      case "ceiling":
        return this.props.wording.ceilingTypes[this.props.language];
      default:
        return this.props.wording.wallTypes[this.props.language];
    }
  };

  render() {
    const {
      wording: { back, stepOne, partitionWalls, ceilings, validate, name },
      language,
      Icon,
      selectorType,
      setSelectorType,
      bufferSelection,
      module,
      typesData,
    } = this.props;

    const isOneChecked = _.filter(typesData, { Checked: true });

    // if(isOneChecked.length)
    // {
    //   throw new Error('I crashed!');
    // }

    return (
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={Icon}
          name={this.props.wording.name[language]}
        />

        <Segment basic style={{ width: "100%" }}>
          <div
            style={{
              padding: 5,
              height: 25,
              backgroundColor: "rgb(14,110,184)",
              color: "white",
              display: "flex",
              alignItems: "center",
              borderBottom: "0.5px solid white",
            }}
          >
            <div>{stepOne[language]}</div>
          </div>

          <Menu widths={2} style={{ marginTop: "5px", marginBottom: "0px" }}>
            <Menu.Item
              name={partitionWalls[language]}
              active={selectorType === "wall"}
              onClick={() => setSelectorType("wall")}
              className={`${selectorType === "wall" ? "menuItemActive" : "menuItemNotActive"
                }`}
            />
            <Menu.Item
              name={ceilings[language]}
              active={selectorType === "ceiling"}
              onClick={() => setSelectorType("ceiling")}
              className={`${selectorType === "ceiling"
                  ? "menuItemActive"
                  : "menuItemNotActive"
                }`}
            />
          </Menu>

          <Segment
            basic
            style={{
              border: "1px solid #d6d6d6",
              marginBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <GroupSelectorBody selectorType={selectorType} />
          </Segment>
        </Segment>
        <Segment
          basic
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            bottom: 30,
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Button onClick={this.backHandler}
            color="orange">
            {back[language]}
          </Button>
          <Button
            onClick={this.validateHandler}
            disabled={isOneChecked.length > 0 ? false : true}
            primary
          >
            {validate[language]}
          </Button>
        </Segment>
      </div>
    );
  }
}

export default connector(GroupSelector);
