import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "366def56-4c74-42cd-a886-b9588da828e7";

export const objectGeneratorTrigger = (config:any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    // `${config.REACT_APP_SERVERURL}/#/${pluginId}/${functionalityId}`
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const ModelGenConvertRevitVersionRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const ConvertRevitVersion: Functionality = {
  Name: {
    English: "Convert Revit Version",
  },
  Trigger: objectGeneratorTrigger,
  ShortDesc: {
    English: "Upgrade RVT/RFA",
  },
  Id: functionalityId,
  Icon: "/ModelGenerator/convert-revit-version.png",
  RootComponent,
  ParentId: "0",
};
