import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import {
  Icon,
  Checkbox,
  Radio,
  Input,
  Popup,
  Button,
  Modal,
} from "semantic-ui-react";
import {
  duplicateSelection,
  deleteSelection,
  editSelection,
  toggleZone,
  updateSelectionNameAction,
  updateSelectionColorAction,
  setSelectorType,
} from "../Actions";
import { Routes } from "./root";
import _ from "lodash";
import ColorPicker from "../../CommonModules/Colorisation/ColorPicker";
import "../Resources/index.css";
import { api } from "../../../../RevitJS/API";
import { setPrevPage } from "../../DocumentTechniques/Actions";
import { Selection } from "../Actions/types";
import { ConfirmModal } from "../../CommonModules/PopUpModals/ConfirmModal";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { dbStoreNameCalepinage } from "../../Calpinage/Components/root";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faClone,
  faTrash,
} from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Oval } from "react-loader-spinner";
import { InjectedCounterProps } from "../../../../ErrorManagement/components/ErrorBoundry";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";

interface State {}

const mapState = (
  state: SelectionStore,
  ownProps: {
    selection: Selection<PlacoOptions>;
    selections: any;
    setRoute: any;
    crudSelectionId: any;
    setSelectionId: any;
  }
) => ({
  selection: ownProps.selection,
  setRoute: ownProps.setRoute,
  selections: ownProps.selections,
  moduleData: state.moduleData,
  crudSelectionId: ownProps.crudSelectionId,
  setSelectionId: ownProps.setSelectionId,
  projectData: state.projectData,
  config: state.config,
});

const mapDispatch = {
  duplicateSelection,
  deleteSelection,
  editSelection,
  toggleZone,
  updateSelectionNameAction,
  updateSelectionColorAction,
  setPrevPage,
  setSelectorType,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InjectedCounterProps & {
    selection: Selection<PlacoOptions>;
    setRoute: any;
    module: string;
    selectionPar: boolean;
    setSelectionId: any;
    selections: {
      [key: string]: Selection<PlacoOptions>;
    };
    moduleData: any;
    crudSelectionId: any;
    setPrevPage: any;
    language: string;
    projectData: initProjectData;
    setSelectorType: any;
    config: any;
    onError: any;
  };

export class SavedSelection extends Component<Props, State> {
  state = {
    hovered: false,
    editId: "",
    editName: "",
    editedNameValue: "",
    errorMessage: "",
    color: {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    },
    displayColorPicker: false,
    confirmModal: false,
    showWarningPopup: false,
    eyeLoader: "",
    setShow: true,
    modifyLoader: false,
  };

  componentDidMount = () => {
    this.setState({ color: this.props.selection.Color });
  };

  componentDidUpdate = (oldProps: any) => {
    if (oldProps.selection !== this.props.selection) {
      this.setState({ editId: "", editName: "", errorMessage: "" });
      this.setState({ color: this.props.selection.Color });
    }
  };

  checkboxParZoneORLevel = (selectionPar: boolean, id: string) => {
    return selectionPar ? (
      this.props.moduleData.moduleParZone ? (
        <Checkbox
          onChange={(e, { checked }) => {
            this.props.crudSelectionId(checked, id, true);
            localStorage.setItem("isModification", "true");
          }}
        />
      ) : (
        <Radio
          name="radioGroup"
          onChange={() => {
            this.props.crudSelectionId(true, id, false);
            localStorage.setItem("isModification", "true");
          }}
        />
      )
    ) : (
      <Radio
        name="radioGroup"
        onChange={() => {
          this.props.crudSelectionId(true, id, false);
          localStorage.setItem("isModification", "true");
        }}
      />
    );
  };

  eyeHandler = async (name: string) => {
    try {
      this.setState({ eyeLoader: name });
      let activeView = await api.queries.getActiveViewDetails();
      const nameTobe = (activeView.Name + "_" + name).replace(
        /[&\\/\\#, +()$~%.'":*?<>{}]/g,
        ""
      );
      let viewByName = await api.queries.getViewByName(nameTobe);

      if (viewByName) {
        await api.queries.deleteView(viewByName);
      }

      let newlyCreatedView = await api.queries.duplicateActiveView(nameTobe);
      await api.queries.setActiveView(newlyCreatedView);

      let activeViewObject = await api.queries.getActiveViewDetails();

      if (activeViewObject.Name === nameTobe) {
        let wallsRows = this.props.selection.SelectionByType.wall.Rows;
        let ceilingRows = this.props.selection.SelectionByType.ceiling.Rows;
        let elements: string[] = [];
        wallsRows.forEach((value, index) => {
          elements = elements.concat(value.RevitSelection.Ids);
        });

        ceilingRows.forEach((value, index) => {
          elements = elements.concat(value.RevitSelection.Ids);
        });

        let rgbColor = {
          Red: this.state.color.r,
          Green: this.state.color.g,
          Blue: this.state.color.b,
        };
        let filter = await api.queries.createSelectionFilterForEye(
          nameTobe,
          elements
        );

        const plasterboardIds = await api.queries.filterElements(
          "Generic",
          [
            {
              Param: { Name: "Name", Type: "Builtin", Value: "Placo_Plaque" },
              Rule: "Equals",
            },
            {
              Param: { Name: "id", Type: "Integer", Value: elements },
              Rule: "Includes",
            },
          ],
          null
        );

        const simpleFramesIds = await api.queries.filterElements(
          "Generic",
          [
            {
              Param: { Name: "Name", Type: "Builtin", Value: "Placo_Ossature" },
              Rule: "Equals",
            },
            {
              Param: { Name: "id", Type: "Integer", Value: elements },
              Rule: "Includes",
            },
          ],
          null
        );
        const doubleFramesIds = await api.queries.filterElements(
          "Generic",
          [
            {
              Param: {
                Name: "Name",
                Type: "Builtin",
                Value: "Placo_Ossature-Double",
              },
              Rule: "Equals",
            },
            {
              Param: { Name: "id", Type: "Integer", Value: elements },
              Rule: "Includes",
            },
          ],
          null
        );

        const bottomRailsIds = await api.queries.filterElements(
          "Generic",
          [
            {
              Param: { Name: "Name", Type: "Builtin", Value: "Placo_Rail-bas" },
              Rule: "Equals",
            },
            {
              Param: { Name: "id", Type: "Integer", Value: elements },
              Rule: "Includes",
            },
          ],
          null
        );
        const upperRailsIds = await api.queries.filterElements(
          "Generic",
          [
            {
              Param: {
                Name: "Name",
                Type: "Builtin",
                Value: "Placo_Rail-haut",
              },
              Rule: "Equals",
            },
            {
              Param: { Name: "id", Type: "Integer", Value: elements },
              Rule: "Includes",
            },
          ],
          null
        );

        let framesIds = simpleFramesIds.concat(doubleFramesIds);
        const railsIds = bottomRailsIds.concat(upperRailsIds);

        let PlasterboardsFilter = await api.queries.createSelectionFilterForEye(
          nameTobe + "_Plasterboards",
          plasterboardIds
        );

        let FramesFilter = await api.queries.createSelectionFilterForEye(
          nameTobe + "_Frames",
          framesIds
        );

        let RailsFilter = await api.queries.createSelectionFilterForEye(
          nameTobe + "_Rails",
          railsIds
        );

        await api.viewHandler.setFilterColor(filter, rgbColor);
        if (plasterboardIds.length > 0) {
          await api.viewHandler.setFilterColor(PlasterboardsFilter, rgbColor);
        }

        if (framesIds.length > 0) {
          await api.viewHandler.setFilterColor(FramesFilter, rgbColor);
        }

        if (railsIds.length > 0) {
          await api.viewHandler.setFilterColor(RailsFilter, rgbColor);
        }
        const revitVersion = parseInt(await api.framework.getRevitVersion());
        if (revitVersion === 2023) {
          await api.queries.closeView(newlyCreatedView);
          await api.queries.setActiveView(newlyCreatedView);
        }
      }

      this.setState({ eyeLoader: "" });
    } catch (error) {
      throw error;
      //this.props.onError("PB-SE-L-005", error.stack);
    }
  };

  eyeOrCheckbox = (
    module: string,
    selectionPar: boolean,
    id: string,
    name: string
  ) => {
    if (module.toLowerCase() === "selection") {
      return (
        <Popup
          trigger={
            <Icon
              name="eye"
              color="black"
              onClick={() =>
                this.eyeHandler(name).catch((error) => {
                  this.props.onError(
                    errorCodeKey.PB_SE_L_005,
                    error.stack,
                    true
                  );
                })
              }
            />
          }
          content="  Visualiser la sélection par colorisation"
          on="hover"
          inverted
          size="mini"
          position="bottom left"
        />
      );
    }
    if (
      module.toLowerCase() === "suppression" ||
      module.toLowerCase() === "reperage"
    ) {
      return (
        <Checkbox
          onClick={() => {
            this.props.setSelectionId(this.props.selection.Id);
            localStorage.setItem("isModification", "true");
          }}
          defaultChecked={false}
        />
      );
    }
    if (module.toLowerCase() === "dossier techniques") {
      if (_.includes(this.props.moduleData.dossierData.selections.list, id)) {
        return (
          <Radio
            name="radioGroup"
            onChange={() => {
              this.props.crudSelectionId(true, id, false);
              localStorage.setItem("isModification", "true");
            }}
            defaultChecked
          />
        );
      }
      return (
        <Radio
          name="radioGroup"
          onChange={() => {
            this.props.crudSelectionId(true, id, false);
            localStorage.setItem("isModification", "true");
          }}
        />
      );
    }
    return this.checkboxParZoneORLevel(selectionPar, id);
  };

  toggleModal = (action: boolean) => {
    this.setState({ displayColorPicker: action });
  };

  onDeleteIconClick = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  onCloseModal = () => {
    this.setState({ showWarningPopup: !this.state.showWarningPopup });
  };

  render() {
    const {
      setRoute,
      selection,
      duplicateSelection,
      deleteSelection,
      editSelection,
      toggleZone,
      module,
      selectionPar,
      updateSelectionNameAction,
      selections,
      updateSelectionColorAction,
      setSelectorType,
    } = this.props;

    const { hovered } = this.state;

    const styleSelector = (hovered: boolean) => {
      if (!hovered) {
        return {
          padding: 5,
          minHeight: 25,
          // backgroundColor: "rgb(250, 250, 255)",
          display: "flex",
          alignItems: "center",
          borderBottom: "0.5px solid white",
        };
      }
      return {
        padding: 5,
        minHeight: 25,
        display: "flex",
        alignItems: "center",
        borderBottom: "0.5px solid white",
        cursor: "pointer",
      };
    };

    const editSelectionName = (Id: string, Name: string) => {
      // created editedNameValue to store the onchange value of inputbox and assign it to editName
      this.setState({ editId: Id, editName: Name });
    };

    const updateSelectionname = async (Id: string, Name: string) => {
      let errorMessage = "";
      const currentRevit = this.props.projectData.ProjectId;
      //const currentRevit = getPluginDataFromDB("getActiveDocumentNameResult");
      if (Name === "") {
        errorMessage = "Nom de sélection invalide";
      }
      if (Name.trim().length < 5) {
        errorMessage = "Texte de 5 caractères minimum";
      }
      _.forEach(selections, (value) => {
        if (
          value.Name.toLowerCase() === Name.trim().toLowerCase() &&
          Id !== value.Id &&
          value.RevitView === currentRevit
        ) {
          errorMessage = "Une autre sélection existe déjà sous ce nom";
        }
      });

      if (errorMessage === "") {
        updateSelectionNameAction(Id, Name.trim());
      } else {
        this.setState({ errorMessage: errorMessage });
      }
      localStorage.setItem("isModification", "false");
    };

    const colorChangeHandler = (color: { rgba: any }) => {
      updateSelectionColorAction(selection.Id, color);
      this.setState({ color: color });
      localStorage.setItem("isModification", "true");
    };

    const goToMapping = () => {
      editSelection(JSON.parse(JSON.stringify(selection)));
      if (
        selection.SelectionByType.wall &&
        selection.SelectionByType.wall.Rows.length > 0
      ) {
        this.props.setSelectorType("wall");
      } else if (
        selection.SelectionByType.ceiling &&
        selection.SelectionByType.ceiling.Rows.length > 0
      ) {
        this.props.setSelectorType("ceiling");
      }
      this.props.setPrevPage(Routes.ROOT);
      setRoute(Routes.MAPPING);
    };

    const ToggleWarningPopup = (toggleState: boolean): void => {
      this.setState({
        showWarningPopup: toggleState,
      });
    };

    const checkIfExistInCalOrDos = async (currentSelectionId: any) => {
      this.setState({ modifyLoader: true });
      //const activeDocumentName = getPluginDataFromDB("getActiveDocumentNameResult")
      const activeDocumentName = this.props.projectData.ProjectId;

      const checkIfinCalepinage = await bimStorage.selectionIsUsed(
        dbStoreNameCalepinage,
        activeDocumentName,
        currentSelectionId
      );
      const dossierTechniqueData: any = await bimStorage.getItem(
        storageKey.DOSSIER_TECHNIQUE
      );
      // const calepinageData = bimStorage.listModule(
      //   dbStoreNameCalepinage,
      //   this.props.projectData.ProjectId
      // );
      // need to check if the data of calepinage has the id of the current selection to show the conflict popu

      let checkIfinDossier: boolean = false;

      if (dossierTechniqueData) {
        //check if current selection is used in Dossier
        checkIfinDossier = dossierTechniqueData.some((e: any) => {
          return (
            activeDocumentName === e.modelName &&
            e.selections.list.some((e: any) => e === currentSelectionId)
          );
        });
      }

      if (checkIfinCalepinage || checkIfinDossier) {
        ToggleWarningPopup(true);
      } else {
        goToMapping();
      }

      this.setState({ modifyLoader: false });
      localStorage.setItem("scrollPosition", "0");
    };
    const convertDate = (d: any) => {
      let sp = d.split("/");
      let str1: any = sp[1];
      let str2: any = sp[0];
      if (str1.length == 1) {
        str1 = 0 + sp[1];
      }
      if (str2.length == 1) {
        str2 = 0 + sp[0];
      }
      let ret = [str2, str1, sp[2]].join("/");
      return ret;
    };

    return (
      <div
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        style={styleSelector(hovered)}
        className="row-hover"
      >
        <div
          style={{
            justifyContent: "center",
            fontWeight: "bold",
            display: "flex",
            width: "5%",
            color: "black",
            border: "0.2px",
          }}
        >
          {this.state.eyeLoader === selection.Name ? (
            <Oval
              color="#00BFFF" //3 secs
              height={18}
              width={18}
            />
          ) : (
            this.eyeOrCheckbox(
              module,
              selectionPar,
              selection.Id,
              selection.Name
            )
          )}
        </div>

        {/* //edit  start*/}
        {this.state.editId === "" && (
          <Popup
            trigger={
              <div
                style={{
                  justifyContent: "center",
                  fontWeight: "bold",
                  display: "block",
                  width:
                    this.props.module === "Suppression" ||
                    module === "Metres" ||
                    module.toLowerCase() === "calepinage"
                      ? "33%"
                      : module.toLowerCase() === "dossier techniques"
                      ? "26%"
                      : "32%",
                  color: "black",
                  border: "0.2px",
                  cursor: "pointer",
                  wordWrap: "break-word",
                  textAlign: "center",
                  overflow: "hidden",
                }}
                onClick={() =>
                  editSelectionName(selection.Id, selection.Name.trim())
                }
              >
                <div>{selection.Name}</div>
              </div>
            }
            content="Cliquer pour modifier le nom"
            on="hover"
            inverted
            size="mini"
            position="bottom center"
          />
        )}

        {this.state.editId === selection.Id && (
          <Popup
            trigger={
              <div
                style={{
                  justifyContent: "center",
                  fontWeight: "bold",
                  display: "flex",
                  width:
                    this.props.module === "Suppression" ||
                    module === "Metres" ||
                    module.toLowerCase() === "calepinage"
                      ? "33%"
                      : module.toLowerCase() === "dossier techniques"
                      ? "26%"
                      : "35%",
                  color: "black",
                  border: "0.2px",
                  cursor: "pointer",
                }}
              >
                <div>
                  <Input
                    type="text"
                    placeholder="Search..."
                    value={this.state.editName}
                    onChange={(e, { value }) => {
                      this.setState({
                        editName: value,
                        editedNameValue: value,
                      });
                      localStorage.setItem("isModification", "true");
                    }}
                    action
                    error={this.state.errorMessage !== ""}
                    style={{ width: "100%" }}
                  >
                    <input />
                    <Popup
                      trigger={
                        <Button
                          icon
                          onClick={() => {
                            updateSelectionname(
                              selection.Id,
                              this.state.editName
                            );
                          }}
                        >
                          <Icon name="check" />
                        </Button>
                      }
                      content="Valider la modification du nom/Validate name change"
                      on="hover"
                      inverted
                      size="mini"
                      position="bottom center"
                    />
                    <Popup
                      trigger={
                        <Button
                          icon
                          onClick={() => {
                            editSelectionName("", "");
                          }}
                        >
                          <Icon name="close" />
                        </Button>
                      }
                      content="Annuler la modification du nom /Undo name change"
                      on="hover"
                      inverted
                      size="mini"
                      position="bottom center"
                    />
                  </Input>
                  {this.state.errorMessage !== "" && (
                    <div style={{ fontSize: "9px", color: "red" }}>
                      {this.state.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            }
            content="Cliquer pour modifier le nom"
            on="hover"
            inverted
            size="mini"
            position="bottom center"
          />
        )}
{/* edit end */}
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "bold",
            width:
              module.toLowerCase() === "dossier techniques" ? "20%" : "15%",
            color: "black",
            display: "flex",
          }}
        >
          <div style={{ width: "100%" }}>
            {!_.isEmpty(selection.Levels) && selection.Levels.join("; ")}
          </div>
        </div>
        {module === "Suppression" ||
        module === "Metres" ||
        module.toLowerCase() === "calepinage" ? null : (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "bold",
              width:
                module.toLowerCase() === "dossier techniques" ? "20%" : "20%",
              color: "black",
              display: "flex",
            }}
          >
            {selection.Zone ? "Zone" : "Sélection"}
            {/* <Popup
              trigger={ 
                <Checkbox
                  disabled={selectionPar}
                  checked={selection.Zone}
                  disabled={true}
                  onChange={() => toggleZone(selection.Id)}
                />
               }
              content="Marquer la sélection comme une « Zone »"
              on="hover"
              inverted
              size="mini"
              position="bottom center"
            /> */}
          </div>
        )}
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "bold",
            width:
              this.props.module === "Suppression" ||
              module === "Metres" ||
              module.toLowerCase() === "calepinage"
                ? "31%"
                : module.toLowerCase() === "dossier techniques"
                ? "20%"
                : "20%",
            color: "black",
            display: "flex",
          }}
        >
          <div style={{ width: "30%" }}>
            {convertDate(selection.Date)}
            {/* {selection.Date} */}
          </div>
        </div>
        {module === "Suppression" ||
        module === "Metres" ||
        module.toLowerCase() === "calepinage" ||
        module.toLowerCase() === "dossier techniques" ? null : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: 15,
            }}
          >
            <div
              style={{
                marginRight: 6,
              }}
            >
              <Popup
                trigger={
                  <Icon
                    name="square full"
                    style={
                      this.state.color && {
                        color: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
                        cursor: "pointer",
                      }
                    }
                    onClick={() => this.toggleModal(true)}
                  />
                }
                content="Choisir une couleur"
                on="hover"
                inverted
                size="mini"
                position="bottom right"
              />
            </div>
            {this.state.displayColorPicker && (
              <ColorPicker
                displayColorPicker={this.state.displayColorPicker}
                toggleModal={this.toggleModal}
                language={this.props.language}
                currentColor={this.state.color}
                colorChangeHandler={colorChangeHandler}
                config={this.props.config}
              ></ColorPicker>
            )}

            <div
              className="editIcon"
              style={{
                marginRight: 6,
              }}
              onClick={() => checkIfExistInCalOrDos(selection.Id)}
            >
              {this.state.modifyLoader === true ? (
                <Oval
                  color="#00BFFF" //3 secs
                  height={18}
                  width={18}
                />
              ) : (
                <Popup
                  trigger={<FontAwesomeIcon icon={faPencilAlt as IconProp} />}
                  content="Modifier"
                  on="hover"
                  inverted
                  size="mini"
                  position="bottom center"
                />
              )}
            </div>
            {selection.Zone ? (
              <div style={{ width: 20 }}></div>
            ) : (
              <div
                className="cloneIcon"
                style={{
                  marginRight: 6,
                }}
                onClick={() => duplicateSelection(selection.Id)}
              >
                <Popup
                  trigger={<FontAwesomeIcon icon={faClone as IconProp} />}
                  content="Dupliquer"
                  on="hover"
                  inverted
                  size="mini"
                  position="bottom left"
                />
              </div>
            )}

            <div className="trashIcon" onClick={this.onDeleteIconClick}>
              <Popup
                trigger={<FontAwesomeIcon icon={faTrash as IconProp} />}
                content="Supprimer"
                on="hover"
                inverted
                size="mini"
                position="bottom right"
              />
            </div>
            {/* <ConfirmModal
              confirmModal={this.state.confirmModal}
              no="NON"
              yes="OUI"
              description="Voulez-vous vraiment supprimer cette sélection?"
              header="Supprimer la Sélection"
              onYesAction={() =>
                Promise.resolve(deleteSelection(selection.Id)).then(() =>
                  this.onDeleteIconClick()
                )
              }
              onNoAction={() => this.onDeleteIconClick()}
            /> */}
          </div>
        )}
        <Modal size="tiny" open={this.state.confirmModal}>
          <Modal.Content>
            <div
              style={{
                textAlign: "center",
                padding: "2rem 1.6rem 1.6rem 1.6rem",
              }}
            >
              <p>
                Vous allez supprimer une sélection. Ça pourra impacter un
                dossier technique ou une configuration de calepinage.
              </p>
              <Button color="orange" onClick={() => this.onDeleteIconClick()}>
                NON
              </Button>
              <Button
                color="blue"
                onClick={() =>
                  Promise.resolve(deleteSelection(selection.Id)).then(() =>
                    this.onDeleteIconClick()
                  )
                }
              >
                OUI
              </Button>
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          size="tiny"
          open={this.state.showWarningPopup}
          onClose={() => ToggleWarningPopup(false)}
        >
          {/* <Icon
            name="close"
            style={{
              float: "right",
              color: "#333333",
              marginTop: "-10px",
              cursor: "pointer",
            }}
            onClick={() => ToggleWarningPopup(false)}
          ></Icon> */}
          <Modal.Content>
            <div
              style={{
                textAlign: "center",
                padding: "2rem 1.6rem 1.6rem 1.6rem",
              }}
            >
              <p>
                Vous allez modifier une sélection utilisée pour un dossier
                technique ou une configuration de calepinage. Ça pourra impacter
                la configuration sauvegardé. Veuillez revérifier après votre
                modification
              </p>
              <Button color="orange" onClick={this.onCloseModal}>
                Annuler
              </Button>
              <Button color="blue" onClick={goToMapping}>
                Continuer
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default connector(SavedSelection);
