import { Country, SelectionMethod } from "../assets/constants";
import Element from "../models/Element.model";
import Item from "../models/Item.model";
import Project from "../models/Project.model";
import { setLocal } from "../services/projects.service";
import {
    CREATE_NEW_PROJECT,
    RESET_CURRENT_PROJECT,
    SET_CURRENT_PROJECT_ALL_AVAILABLE_ELEMENTS,
    SET_CURRENT_PROJECT_COUNTRY,
    SET_CURRENT_PROJECT_INITIAL_DATES,
    SET_CURRENT_PROJECT_ITEMS,
    SET_CURRENT_PROJECT_NAME,
    SET_ELEMENTS_FILTERS,
    SET_PROJECT_LIST,
    SET_SELECTED_ELEMENT_IN_ITEM,
    SET_CURRENT_ITEM_AND_SANITIZE_AVAILABLE_ELEMENTS,
    SET_CURRENT_PROJECT_CURRENT_ITEM_COMPATIBLE_AND_AVAILABLE_ELEMENTS,
    VALIDATE_SELECTION,
    SET_CURRENT_LOOSE_ELEMENTS,
    SET_CURRENT_PROJECT_VALIDATED_ITEMS,
    SAVE_PROJECT,
    SET_CURRENT_PROJECT,
    SET_CURRENT_PROJECT_SELECTION_METHOD,
} from "./actionTypes";

export function setProjectList(projectList: Project[]) {
    return {
        type: SET_PROJECT_LIST,
        payload: projectList,
    };
}

export function createNewProject() {
    return {
        type: CREATE_NEW_PROJECT,
    };
}

export function resetCurrentProject() {
    return {
        type: RESET_CURRENT_PROJECT,
    };
}

export function setCurrentProjectSelectionMethod(
    selectionMethod: SelectionMethod
) {
    return {
        type: SET_CURRENT_PROJECT_SELECTION_METHOD,
        payload: selectionMethod,
    };
}

export function setCurrentProjectName(projectName: string) {
    return {
        type: SET_CURRENT_PROJECT_NAME,
        payload: projectName,
    };
}

export function setCurrentProjectCountry(projectCountry: string) {
    switch (projectCountry) {
        case Country.UK:
            setLocal("en");
            break;
        case Country.FR:
            setLocal("fr");
            break;
        default:
            setLocal("en");
            break;
    }
    return {
        type: SET_CURRENT_PROJECT_COUNTRY,
        payload: projectCountry,
    };
}

export function setCurrentProjectInitialDate(dates: {
    creation_date: any;
    modification_date: any;
}) {
    return {
        type: SET_CURRENT_PROJECT_INITIAL_DATES,
        payload: dates,
    };
}

export function setCurrentProjectItems(items: Item[]) {
    return {
        type: SET_CURRENT_PROJECT_ITEMS,
        payload: items,
    };
}

export function setCurrentProjectAllAvailableElements(elements: Element[]) {
    return {
        type: SET_CURRENT_PROJECT_ALL_AVAILABLE_ELEMENTS,
        payload: elements,
    };
}

export function setElementsFilters(
    elementsFilters: any = null,
    item: Item | null
) {
    if (elementsFilters !== null && elementsFilters !== undefined) {
        return {
            type: SET_ELEMENTS_FILTERS,
            payload: { elementsFilters, item: item },
        };
    } else {
        if (item !== null && item !== undefined) {
            return {
                type: SET_ELEMENTS_FILTERS,
                payload: {
                    elementsFilters: {
                        pipes: {},
                        fittings: {},
                        accessories: {},
                    },
                    item,
                },
            };
        } else {
            return {
                type: SET_ELEMENTS_FILTERS,
                payload: {
                    pipes: {},
                    fittings: {},
                    accessories: {},
                },
            };
        }
    }
}

export function setProjectItems(items: Item[]) {
    return {
        type: SET_CURRENT_PROJECT_ITEMS,
        payload: items,
    };
}

export function setProjectLooseElements(items: Element[]) {
    return {
        type: SET_CURRENT_LOOSE_ELEMENTS,
        payload: items,
    };
}

export function setSelectedElementInItem(
    technicalName: string,
    element: Element
) {
    return {
        type: SET_SELECTED_ELEMENT_IN_ITEM,
        payload: {
            technicalName,
            element,
        },
    };
}

export function setCurrentItemAndSanitizeAvailableElements(item: Item) {
    return {
        type: SET_CURRENT_ITEM_AND_SANITIZE_AVAILABLE_ELEMENTS,
        payload: {
            ...item,
        },
    };
}

export function handleValidateSelection(item: Item) {
    return {
        type: VALIDATE_SELECTION,
        payload: {
            ...item,
        },
    };
}

export function setCurrentProjectCurrentItemCompatibleAndAvailableElements(
    item: Item
) {
    return {
        type: SET_CURRENT_PROJECT_CURRENT_ITEM_COMPATIBLE_AND_AVAILABLE_ELEMENTS,
        payload: {
            ...item,
        },
    };
}

export function setValidatedItems(items: Item[]) {
    return {
        type: SET_CURRENT_PROJECT_VALIDATED_ITEMS,
        payload: items,
    };
}

export function saveProject() {
    return {
        type: SAVE_PROJECT,
    };
}

export function setCurrentProject(project: Project) {
    return {
        type: SET_CURRENT_PROJECT,
        payload: project,
    };
}
