import { FetchPlacoDataActionType, PlacoProducts } from "../Types";



const initialData = {
  partitionWalls: [],
  liningWalls: [],
  ceilings: [],
  protectionFeu: [],
  gainesTech: [],
  conduitGaines: [],
  available: false,
  sgGObjectAttribute: []
}

export const placoData = (
  placoData: PlacoProducts = initialData,
  action: FetchPlacoDataActionType
): PlacoProducts => {
  switch (action.type) {
    case "SAVE_PLACO_FILTER_PRODUCTS":
      return action.solutions;
    default:
      return placoData;
  }
};

export const placoSGObjectAttribute = ( sgObjectAtrributes : any[] = [], action: FetchPlacoDataActionType) => {
  switch (action.type) {
    case "SAVE_PLACO_FILTER_PRODUCTS":
      return action.sgObjectAttribute;
    default:
      return sgObjectAtrributes;
  } 
}
