import moment from "moment";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import {
  resetCurrentProject,
  setCurrentProjectCountry,
  setCurrentProjectInitialDate,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  Country,
  STEP_1_DATA_LIBRARY_CONFIGURATION,
} from "../../assets/constants";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./projectCountry.scss";

class ProjectCountry extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.backPage = this.backPage.bind(this);
    this.projectMenu = this.projectMenu.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.setProjectCountry = this.setProjectCountry.bind(this);
  }

  state = {
    nameCorrect: false,
    nameNotAlreadyExisting: true,
  };

  componentDidMount() {
    this.setProjectCountry(Country.UK);
  }

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.PROJECT_NAME_WINDOW);
  }

  nextPage() {
    let { currentProject, selectWindowToShow, setCurrentProjectInitialDate } =
      this.props;
    setCurrentProjectInitialDate({
      creation_date: moment().format("MM/DD/YYYY"),
      modification_date: moment().format("MM/DD/YYYY"),
    });
    selectWindowToShow(AppWindowName.ELEMENT_SELECTION_WINDOW);
  }

  setProjectCountry(projectName: string) {
    let { setCurrentProjectCountry } = this.props;
    this.checkCorrectProjectCountry(projectName);
    setCurrentProjectCountry(projectName);
  }

  checkCorrectProjectCountry(projectName: string) {
    let { projectsList } = this.props;
    let nameNotAlreadyExisting: boolean = true;
    projectsList.forEach((project) => {
      if (projectName === project.name) {
        nameNotAlreadyExisting = false;
      }
    });
    this.setState({
      nameCorrect: projectName !== undefined && projectName.length > 2,
      nameNotAlreadyExisting,
    });
  }

  render() {
    let { currentProject, t } = this.props;
    return (
      <div id="project-country-container">
        <Header
          displayName={t(STEP_1_DATA_LIBRARY_CONFIGURATION) || ""}
          savedProjectsButtonShow={true}
          savedProjectsButtonDisabled={false}
          savedProjectButtonClicked={this.projectMenu}
        ></Header>
        <Body
          projectCountryChanged={this.setProjectCountry}
          projectCountry={currentProject.country}
        ></Body>
        <span></span>
        <Footer
          savedProjectsButtonDisabled={true}
          disabledNextButton={currentProject.country === undefined}
          clickedNextButton={this.nextPage}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    currentProject: state.projects.currentProject,
    projectsList: state.projects.projectsList,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  setCurrentProjectCountry,
  setCurrentProjectInitialDate,
  resetCurrentProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {};

export default connector(withTranslation(["pam"])(ProjectCountry));
