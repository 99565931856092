import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../../Selection/Reducers";
import SolutionTable from "./SolutionTable";
import "../../Resources/SolutionTable.css";
import { setHistoryPath, setSelectorType } from "../../../Selection/Actions";
import { selectorTp } from "../../../Selection/Actions/types";
import { InjectedCounterProps } from "../../../../../ErrorManagement/components/ErrorBoundry";
interface State {}

type Props = PropsFromRedux &
  InjectedCounterProps & {
    setRoute: any;
    moduleData: any;
    Icon: string;
    language: string;
    wording: {
      title: { [key: string]: string };
      subheader: { [key: string]: string };
      completedSteps: { [key: string]: string };
      paretage: { [key: string]: string };
      parzone: { [key: string]: string };
      selections: { [key: string]: string };
      validate: { [key: string]: string };
      back: { [key: string]: string };
      calepiner: { [key: string]: string };
      completed: { [key: string]: string };
      notCompleted: { [key: string]: string };
      partitionWalls: { [key: string]: string };
      ceilings: { [key: string]: string };
    };
    setSelectorType: any;
    selectorType: selectorTp;
    setHistoryPath: any;
  };

export class Solution extends Component<Props, State> {
  public static defaultProps = {
    wording: {
      title: {
        French: "CALEPINAGE",
        English: "LAYOUT",
      },
      subheader: {
        French: "Tableau de calepinage​",
        English: "Layout table",
      },
      completedSteps: {
        French: "étapes complétées",
        English: "completed steps",
      },
      paretage: {
        French: "Par étage",
        English: "Per floor",
      },
      parzone: {
        French: "Par étage <br/>et par zone",
        English: "Per floor <br/>and per zone",
      },
      selections: {
        French: "Sélections",
        English: "Selections",
      },
      validate: {
        French: "Validate",
        English: "Valider",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      calepiner: {
        French: "Calepiner",
        English: "Calepine",
      },
      completed: {
        French: "Completed",
        English: "Completed",
      },
      notCompleted: {
        French: "Not Completed",
        English: "Not Completed",
      },
      partitionWalls: {
        French: "Murs",
        English: "Walls",
      },
      ceilings: {
        French: "Plafonds",
        English: "Ceilings",
      },
    },
  };

  componentDidMount = () => {
    // api.windowsHandler.resizeWindow(1000, 550);
    this.props.setSelectorType("wall");
  };

  markSelectedStep = (stepType: string) => {
    return this.props.selectorType === stepType
      ? {
          width: "300px",
          textAlign: "center",
          padding: "0.5em 1em",
          fontWeight: "bold",
          border: "1px solid rgb(14,110,184)",
          cursor: "pointer",
          backgroundColor: "rgb(14,110,184)",
          color: "#fff",
        }
      : {
          width: "300px",
          textAlign: "center",
          padding: "0.5em 1em",
          fontWeight: "bold",
          border: "1px solid #a9a9a9",
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          color: "#a9a9a9",
        };
  };

  render() {
    const { setRoute, selectorType, onError } = this.props;

    return (
      <SolutionTable
        setRoute={setRoute}
        selectorType={selectorType}
        onError={onError}
      />
    );
  }
}

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { setRoute: any }
) => ({
  Icon: state.functionalityIcon,
  language: state.language,
  moduleData: state.moduleData,
  setRoute: ownProps.setRoute,
  selectorType: state.selectorType,
});

const mapDispatchToProps = {
  setSelectorType,
  setHistoryPath,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Solution);
