export const placoFilters = {
    Walls: [
        /// Do not remove "Layout possible"
        {
            aid:"",
            name: "Layout possible",
            pimAttribute: "GFR-Layout possible",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },   
          /// Do not remove "Works name"   
        {
            aid: "",
            name: "Works name",
            pimAttribute: "GFR-Works name",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Nom du parement",
            pimAttribute: "GFR-Name cover",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Hauteur limite (m)",
            pimAttribute: "GFR-Height limit in m",
            type: "Numeric",
            criterion: "Between",
            choiceType: "Slider",
            options: null,
            value: [null, null],
        },
        {
            aid: "",
            name: "Protection Incendie",
            pimAttribute: "GFR-Fire protection",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Affaiblissement acoustique (DB)",
            pimAttribute: "GFR-Sound reduction",
            type: "Numeric",
            criterion: "Between",
            choiceType: "Slider",
            options: null,
            value: [null, null],
        },
        {
            aid: "",
            name: "Type de cloison",
            pimAttribute: "GFR-Partitionwall type",
            type: "Text",
            criterion: "Equals",
            choiceType: "RadioBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Epaisseur totale (mm)",
            pimAttribute: "GFR-Total thickness in mm",
            type: "Numeric",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Réaction au feu du parement plaque",
            pimAttribute: "GFR-Reaction to fire of board cover",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Classement des locaux humides",
            pimAttribute: "GFR-Rooms moisture ranking",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        // {
        // aid: "",
        //     name: "Nombre de plaques par parement",
        //     pimAttribute: "GFR-No. Of plasterboard per facing",
        //     type: "Numeric",
        //     criterion: "Includes",
        //     choiceType: "CheckBox",
        //     options: null,
        //     value: null
        // },
        {
            aid: "",
            name: "Résistance aux chocs (J)",
            pimAttribute: "GFR-Shock resistance",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Montants (s ou d)",
            pimAttribute: "GFR-Profiles (1 or 2)",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
    ],
    LiningWalls: [
        /// Do not remove "Layout possible"   
        {
            aid:"",
            name: "Layout possible",
            pimAttribute: "GFR-Layout possible",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },   
        /// Do not remove "Works name"   
        {
            aid: "",
            name: "Works name",
            pimAttribute: "GFR-Works name",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Nom du parement",
            pimAttribute: "GFR-Name cover",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null
        },
        {
            aid: "",
            name: "Hauteur limite (m)",
            pimAttribute: "GFR-Height limit in m",
            type: "Numeric",
            criterion: "Between",
            choiceType: "Slider",
            options: null,
            value: [null, null],
        },
        {
            aid: "",
            name: "Protection incendie en + du support",
            pimAttribute: "GFR-Additionnal fire protection",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Isolation acoustique en + du support (DB)",
            pimAttribute: "GFR-Additionnal acoustic insulation (dB)",
            type: "Numeric",
            criterion: "Between",
            choiceType: "Slider",
            options: null,
            value: [null, null],
        },
        {
            aid: "",
            name: "Type de mur support",
            pimAttribute: "GFR-Support type (wall)",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Classement des locaux humides",
            pimAttribute: "GFR-Rooms moisture ranking",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        // values are not numeric -> not compatible with Slider
        // {
        //     name: "Résistance thermique (R)",
        //     pimAttribute: "GFR-Thermal Resistance",
        //     type: "Numeric",
        //     criterion: "Between",
        //     choiceType: "Slider",
        //     options: null,
        //     value: [null, null],
        // },
        {
            aid: "",
            name: "Résistance aux chocs d'occupation (J)",
            pimAttribute: "GFR-Occupational shocks resistance (J)",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Montants (s ou d)",
            pimAttribute: "GFR-Profiles (1 or 2)",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Encombrement au sol (mm)",
            pimAttribute: "GFR-Space on ground (mm)",
            type: "Numeric",
            criterion: "Between",
            choiceType: "Slider",
            options: null,
            value: [null, null],
        },
        // {
        // aid: "",
        //     name: "Epaisseur totale (mm)",
        //     pimAttribute: "GFR-Total thickness in mm",
        //     type: "Numeric",
        //     criterion: "Includes",
        //     choiceType: "CheckBox",
        //     options: null,
        //     value: [null],
        // },
        // {
        // aid: "",
        //     name: "Réaction au feu du parement plaque",
        //     pimAttribute: "GFR-Reaction to fire of board cover",
        //     type: "Text",
        //     criterion: "Includes",
        //     choiceType: "CheckBox",
        //     options: null,
        //     value: [null],
        // },
        // {
        //     name: "Pare vapeur",
        //     pimAttribute: "GFR-Vapor barrier",
        //     type: "Text",
        //     criterion: "Includes",
        //     choiceType: "CheckBox",
        //     options: null,
        //     value: [null]
        // },
    ],
    Ceiling: [
        /// Do not remove "Layout possible" 
        {
            aid:"",
            name: "Layout possible",
            pimAttribute: "GFR-Layout possible",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },   
        /// Do not remove "Works name"   
        {
            aid: "",
            name: "Works name",
            pimAttribute: "GFR-Works name",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Protection Incendie",
            pimAttribute: "GFR-Fire protection",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        {
            aid: "",
            name: "Affaiblissement acoustique (DB)",
            pimAttribute: "GFR-Sound reduction",
            type: "Numeric",
            criterion: "GreaterThan",
            choiceType: "RadioBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Epaisseur totale (mm)",
            pimAttribute: "GFR-Reaction to fire of board cover",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        {
            aid: "",
            name: "Support (plancher)",
            pimAttribute: "GFR-Support (ceiling)",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        // {aid: "",
        //     name: "Prix fourni posé en € (batichiffrage®)",
        //     pimAttribute: "GFR-Price (bc)",
        //     type: "Numeric",
        //     criterion: "GreaterThan",
        //     choiceType: "RadioBox",
        //     options: null,
        //     value: null
        // },
        {
            aid: "",
            name: "Entraxe ossature primaire (m)",
            pimAttribute: "GFR-Distance between frames (primary framework) (m)",
            type: "Numeric",
            criterion: "GreaterThan",
            choiceType: "RadioBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Classement des locaux humides",
            pimAttribute: "GFR-Rooms moisture ranking",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        {
            aid: "",
            name: "Poids maxi isolant (kg/m²)",
            pimAttribute: "GFR-Maximum insulating material weight (kg/m²)",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        {
            aid: "",
            name: "Type de plafond",
            pimAttribute: "GFR-Type of ceiling",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        {
            aid: "",
            name: "Type de plafond démontable",
            pimAttribute: "GFR-Type of grid ceiling",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
        {
            aid: "",
            name: "Hauteur maximum du plénum (mm)",
            pimAttribute: "GFR-Maximum height of the plenum",
            type: "Integer",
            criterion: "GreaterThan",
            choiceType: "RadioBox",
            options: null,
            value: null,
        },
        {
            aid: "",
            name: "Disposition des perforations",
            pimAttribute: "GFR-Random/Regular perforations",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: [null],
        },
    ],
};
