import * as React from "react"
import { Property } from '../../Selection/Actions/types'

import "./index.scss"



export const PropertyRow: React.FC<Property> =
    ({ title, value, onClick }) => (
        <div className="PropertyRowContainer" onClick={onClick}>
            <div className="PropertyRowTitle">{title}</div>
            {value && <div className="PropertyRowValue">{value}</div>}
        </div>
    )

export default PropertyRow