import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import SelectionRoot from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "fef4020e-8899-4d51-9e4e-ac8cee5b797c";

export const selectionTrigger = async (config: any) => {
  localStorage.setItem("load", "true");
  api.windowsHandler.openWindow(
    900,
    550,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Selection",
    module: "PLACOBIM",
  });
};

export const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="selection"
    >
      <SelectionRoot module="selection" />
    </ParentRoot>
  );
};

export const Selection: Functionality = {
  Name: {
    French: "Sélection",
    English: "Selection",
    German: "Sélection",
    Italian: "Sélection",
    Indonesian: "Sélection",
  },
  Trigger: selectionTrigger,
  ShortDesc: {
    French: "Enregistrer des sélections",
    English: "Save selections",
    German: "Enregistrer des sélections",
    Italian: "Enregistrer des sélections",
    Indonesian: "Enregistrer des sélections",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Sélection.png",
  RootComponent,
  ParentId: "0",
};
