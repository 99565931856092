import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import ImagePreview from "./ImagePreview";
import DatePicker from "react-datepicker";
import { enIN, fr, de, it } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../Resources/FormPanel.css";

export function FormField(props: any) {
  const [datecheck, setdatecheck] = useState(false);
  let lang;
  switch (props.language) {
    case "French":
      lang = fr;
      break;
    case "English":
      lang = enIN;
      break;
    case "German":
      lang = de;
      break;
    case "Italian":
      lang = it;
      break;
    default:
      lang = fr;
      break;
  }
  switch (props.type) {
    case "select":
      return (
        <Form.Select
          {...props}
          //selected={props.value ?? ""}
          label={
            props.mandatory ? (
              <span className="font-weight-field">
                {props.label}
                <span className="alert"> *</span>
              </span>
            ) : (
              <span className="font-weight-field">{props.label}</span>
            )
          }
          search
          className={
            props.errors[props.name] && !props.value
              ? "inputCont select-field red-border hide-cursor"
              : "inputCont select-field hide-cursor"
          }
          onChange={(d: any) => {
            props.setValue(props.name, d.target.innerText);
          }}
        ></Form.Select>
      );
    case "file":
      return (
        <ImagePreview
          {...props}
          className={
            props.errors[props.name] ? "inputCont red-border" : "inputCont"
          }
        />
      );
    case "date":
      return (
        <div className="field inputCont">
          <div className="font-weight-field">
            {props.label}
            <span className="alert"> *</span>
          </div>
          <DatePicker
            locale={lang}
            dateFormat="dd/MM/yyyy"
            selected={props.value && new Date(props.value)}
            onChange={(date) => {
              props.setValue(props.name, date);
              if (date) {
                setdatecheck(true);
              } else {
                setdatecheck(false);
              }
            }}
            className={
              datecheck
                ? "date-inputCont"
                : props.errors[props.name]
                ? "date-inputCont date-red-border"
                : "date-inputCont"
            }
            //required={props.mandatory}
          />
        </div>
      );
    case "info":
      return <h5 className="alert">{props.label}</h5>;
    default:
      return (
        <Form.Input
          {...props}
          label={
            props.mandatory ? (
              <span className="font-weight-field">
                {props.label}
                <span className="alert"> *</span>
              </span>
            ) : (
              <span className="font-weight-field">{props.label}</span>
            )
          }
          onChange={(e) => {
            props.setValue(props.name, e.target.value);
          }}
          error={props.mandatory}
          //required={props.mandatory}
          //value={props.errors[props.name] ? "" : props.value}
          className={
            props.errors[props.name] &&
            (props.value === undefined || props.value === "") &&
            props.mandatory
              ? "error inputCont red-border"
              : "inputCont"
          }
        />
      );
  }
}
