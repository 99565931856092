import {
  find,
  filter,
  map,
  remove,
  isEmpty,
  round,
  startsWith,
  flattenDeep,
  keysIn,
  includes,
  pickBy,
  groupBy,
  uniq,
  orderBy,
  forEach,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import { fetchPlacoDataInGroupSplit } from "../../Calpinage/Helpers/placoHelper";
import { processNewCeilingData } from "./processCeiling";
import { fetchPlacoSolutionsByTranslation } from "../../../../API";

export const groupCeilingModel = async (
  ceilingIdsExt: any[],
  solutionIds: string[],
  processedWallParams: any,
  config: any
) => {
  /// Extract selection ids

  if (ceilingIdsExt.length > 0) {
    /// 3. Get walls data from revit
    let ceilingsData = await api.queries.getCeilingsData(ceilingIdsExt);

    /// 4. Extract solution ids

    /// 5. Get solution data from api respected to solution ids
    const { extractedSolutionProduct } = await fetchPlacoDataInGroupSplit(
      solutionIds,
      config
    );

    ceilingsData = orderBy(ceilingsData, ["LevelElevation"], ["asc"]);

    let grpCeilingsData: any[] = [];

    forEach(ceilingsData, (value: any, index: number) => {
      let placoSolutionForCeiling = find(
        extractedSolutionProduct,
        (solRow: any) => {
          return value.CeilingType.includes(solRow.solutionProduct.translation);
        }
      );

      let height: String = parseFloat((value.Height / 1000).toString()).toFixed(
        2
      );

      //   const placoSolutionHeightObject = find(
      //     placoSolutionForCeiling.solutionProduct.attributes,
      //     { technicalName: "GFR-Height limit in m" }
      //   );

      //   const placoSolutionHeightValue =
      //     placoSolutionHeightObject.values[0].numericValue;

      let oneValue = find(grpCeilingsData, {
        LevelId: value.LevelId,
        Zone: "",
        CeilingType: value.CeilingType,
        Height: height,
        PlacoSolution: placoSolutionForCeiling.solutionProduct.translation,
      });

      if (oneValue) {
        let ids = oneValue.Ids;
        ids.push(value.Id);
        find(grpCeilingsData, {
          LevelId: value.LevelId,
          Zone: "",
          CeilingType: value.CeilingType,
          Height: height,
          PlacoSolution: placoSolutionForCeiling.solutionProduct.translation,
        }).Ids = ids;
      } else {
        grpCeilingsData.push({
          Height: height,
          LevelName: value.LevelName,
          PlacoSolution: placoSolutionForCeiling.solutionProduct.translation,
          PlacoSolutionId: placoSolutionForCeiling.solutionProduct.oid,
          PlacoSolutionHeight: "",
          Zone: "",
          CeilingType: value.CeilingType,
          LevelElevation: value.LevelElevation,
          LevelId: value.LevelId,
          Ids: [value.Id],
          montant: [],
          plaque: [],
        });
      }
    });

    const proceessCeilings = processNewCeilingData(
      grpCeilingsData,
      extractedSolutionProduct
    );

    return proceessCeilings;
  }

  return null;
};

export const collectCeilingFromBIMModel = async (config: any) => {
  let elementParameters: any =
    await api.queries.getCustomParametersFromElementType();

  let placoSolFamily = filter(elementParameters, (elParam, ind) => {
    const idData = find(elParam.paramData, { key: "ID Système" });
    if (idData) {
      return idData.value !== null;
    } else {
      return false;
    }
  });

  if (placoSolFamily.length > 0) {
    placoSolFamily = map(placoSolFamily, (plsol, ind) => {
      const idData = find(plsol.paramData, { key: "ID Système" });
      return { ...plsol, externalName: idData.value };
    });
    const uniqueExternalNames = uniq(map(placoSolFamily, "externalName"));
    const uniqPlacoSolFamily = uniq(map(placoSolFamily, "solution"));
    // const filterElementsArray = map(uniqPlacoSolFamily, (plsol, ind) => {
    //   return {
    //     Param: {
    //       Name: "Name",
    //       Type: "Builtin",
    //       Value: plsol,
    //     },
    //     Rule: "Equals",
    //   };
    // });

    const processedCeilingIds = await api.queries.filterElements(
      "Ceiling",
      [
        {
          Param: {
            Name: "Name",
            Type: "Builtin",
            Value: uniqPlacoSolFamily,
          },
          Rule: "Includes",
        },
      ],
      null
    );

    if (processedCeilingIds.length > 0) {
      const { data } = await fetchPlacoSolutionsByTranslation(
        uniqueExternalNames,
        config
      );
      if (data) {
        let processSolutionIds = map(data.objects, "oid");
        const proceessCeilings = await groupCeilingModel(
          processedCeilingIds,
          processSolutionIds,
          [],
          config
        );
        return proceessCeilings;
      }
    }
  }
  return null;
};
