import { CHANGE_CURRENT_WINDOW, AppActionTypes, AppState, INIT_LANGUAGE } from "./types";
import { APP_WINDOW_NAME } from "../../assets/constants";

export const initialState: AppState = {
  currentWindow: APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW,
  previousWindow: APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW,
  language: "French"
}

export default function app(state = initialState, action: AppActionTypes) {
  switch (action.type) {
    case CHANGE_CURRENT_WINDOW:
      return {
        ...state,
        previousWindow: state.currentWindow,
        currentWindow: action.currentWindow,
      };
    case INIT_LANGUAGE:
      return {
        ...state,
        language: action.language
      }
    default:
      return state;
  }
}
