import { getDate, isArrayNull } from ".";
import JSZip from "jszip";
import fileSaver from "file-saver";
import axios from "axios";
import {
  CheckFilePDFPhotoBox,
  DownloadPDFPhotoBox,
  GeneratePDFPhotoBox,
} from "./PhotoboxApiHelper";
import { PDFDocument } from "pdf-lib";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";

export const getAllValidFiles = (data: any) => {
  let fileArr: any = [];
  data.data.list.forEach((item: any) => {
    if (item.isSelected) {
      let fileLists = isArrayNull(item.Solution.files);
      if (fileLists.length > 0) {
        fileLists.forEach((file: any) => {
          data.downloadObj
            .filter((data: any) => data.isSelected)
            .forEach((data: any) => {
              if (
                file.fileExtension.toLocaleLowerCase() ===
                data.name.toLocaleLowerCase()
              ) {
                fileArr.push(file);
              }
            });
        });
      }
    }
  });
  return fileArr;
};

const token = localStorage.getItem("token");

export const downloadZipFiles = function (fileArr: any, config: any) {
  var zip = new JSZip();
  var count = 0;
  var zipFilename = `files_${getDate().replace(
    /\//g,
    "_"
  )}_${new Date().getTime()}.zip`;

  const downloadNonBlobFl = axios.create({
    baseURL: config.REACT_APP_BDDURL,
    headers: {
      "Content-Type": "application/json;application/octet-stream",
      Accept: "*/*",
      Authorization: "Bearer " + token,
    },
  });

  downloadNonBlobFl.interceptors.request.use(async (request) => {
    return await authFilter(request, config);
  });

  fileArr.forEach((fileObj: any) => {
    fetchFiles(downloadNonBlobFl, fileObj).then((response: any) => {
      zip.file(`${fileObj.fileName}.${fileObj.fileExtension}`, response.data, {
        binary: true,
      });
      count++;
      if (count === fileArr.length) {
        zip
          .generateAsync({
            type: "blob",
          })
          .then(function (content) {
            fileSaver.saveAs(content, zipFilename);
          });
      }
    });
  });
};

const fetchFiles = function (path: any, fileObj: any) {
  return path.get(
    "/file/" +
      fileObj.descriptorId +
      "?filename=" +
      fileObj.fileName +
      "." +
      fileObj.fileExtension
  );
};

export const downloadDAODAE = async (type: any, itemData: any, config: any) => {
  // third parameter was nodeId: any
  var fileName = `file_${type}_${getDate().replace(
    /\//g,
    "_"
  )}_${new Date().getTime()}.pdf`;
  // nodeId
  return GeneratePDFPhotoBox(type, itemData, config).then(
    async (response: any) => {
      let data = response.data.body;
      let fileExists = false;
      let errors: any = [];
      let status = false;
      while (!status) {
        await CheckFilePDFPhotoBox(response.data.body, config).then(
          async (response: any) => {
            fileExists = response.data.fileExists;
            errors = response.data.errors;
          }
        );
        if (!fileExists && errors.length > 0) {
          status = true;
          //setTechnicalLoader(false);
          return errors;
        } else if (fileExists && errors.length === 0) {
          status = true;
          await DownloadPDFPhotoBox(data, config).then(
            async (response: any) => {
              saveFile(response, fileName, "application/pdf");
              //setTechnicalLoader(false);
            }
          );
          return errors;
        }
      }
    }
  );
};

export const saveFile = function (
  response: any,
  fileName: any,
  contentType: any
) {
  var data = new Blob([response.data], { type: contentType });
  setTimeout(() => fileSaver.saveAs(data, fileName), 1000);
};

export const savePDF = function (blob: Blob, fileName: any) {
  setTimeout(() => fileSaver.saveAs(blob, fileName), 1000);
};

export const mergePdfs = async (
  coverPageBuffer: ArrayBuffer,
  photoboxPageBuffer: ArrayBuffer
) => {
  const coverPagePDF = await PDFDocument.load(coverPageBuffer);
  const photoboxPDF = await PDFDocument.load(photoboxPageBuffer);

  // get the first page of coverSheet
  const firstCoverPage = coverPagePDF.getPages()[0];

  // delete first page of photobox
  photoboxPDF.removePage(0);

  /* Replace the first page to coverpage
   1. Embed the first Page of coverpage to the pdf
   2. insert a blank page to photoboxPDF
   3. Add that embedded page to the inserted page */
  const coverPageEmbed = await photoboxPDF.embedPage(firstCoverPage);
  const coverPage = photoboxPDF.insertPage(0);
  coverPage.drawPage(coverPageEmbed);

  // save the generated pdf to get the blob
  const pdfBytes = await photoboxPDF.save();

  // return blob of pdf
  return pdfBytes;
};
