import { PlacoProducts } from "./productType";

export interface SolutionFilterType {
    partitionWalls: any[];
    liningWalls: any[];
    ceilings: any[];
    protectionFeu: any[];
    gainesTech: any[];
    conduitGaines: any[];
} 

export const SAVE_PLACO = "SAVE_PLACO_FILTER_PRODUCTS";

export interface FetchPlacoDataActionType {
    type: typeof SAVE_PLACO;
    solutions: PlacoProducts;
    filters: SolutionFilterType;
    sgObjectAttribute: any;
}

export enum FILTER_VALUE_TYPE {
    NUMERIC = "Numeric",
    TEXT = "Text",
  }
  
  export enum FILTER_CRITERION_TYPE {
    INCLUDES = "Includes",
    BETWEEN = "Between",
    EQUALS = "Equals",
    GREATERTHEN = "GreaterThen",
  }
  
  export enum FILTER_CHOICE_TYPE {
    CHECKBOX = "CheckBox",
    SLIDER = "Slider",
    RADIOBOX = "RadioBox",
  }
  