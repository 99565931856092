import { ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import {
  TypeData,
  LevelData,
  MappingRow,
} from "../../../../RevitJS/Types/StoreTypes";
import { elementTypes } from "../../../../RevitJS/Helpers";

export const SET_SELECTION_TREE = "SET_SELECTION_TREE";
export const INIT_GROUP_SELECTOR = "SET_GROUP_SELECTOR";
export const SELECT_TYPE = "SELECT_TYPE";
export const SELECT_LEVEL = "SELECT_LEVEL";

export const SET_CEILING_SELECTION_TREE = "SET_CEILING_SELECTION_TREE";
export const SELECT_CEILING_LEVEL = "SELECT_CEILING_LEVEL";
export const SELECT_CEILING_TYPE = "SELECT_CEILING_TYPE";
export const SET_ELEMENT_TYPE = "SET_ELEMENT_TYPE";

export interface SetSelectionTreeAction {
  type: typeof SET_SELECTION_TREE;
  tree: ElementsTree;
}

export interface SetCeilingTreeAction {
  type: typeof SET_CEILING_SELECTION_TREE;
  tree: ElementsTree;
}

export interface InitGroupSelectorAction {
  type: typeof INIT_GROUP_SELECTOR;
  tree: ElementsTree;
  ceilingTree: ElementsTree
}

export interface SelectLevelAction {
  type: typeof SELECT_LEVEL;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectCeilingLevelAction {
  type: typeof SELECT_CEILING_LEVEL;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectTypeAction {
  type: typeof SELECT_TYPE;
  typesData: TypeData[];
  selectedTypes: string[];
}

export interface SelectCeilingTypeAction {
  type: typeof SELECT_CEILING_TYPE;
  typesData: TypeData[];
  selectedTypes: string[];
}

export type Tree = {
  Level: Level[];
  Wall: Wall[];
  Ceiling: Ceiling[];
};

export type Wall = {
  Height: number;
  Id: string;
  LevelId: string;
  Name: string;
};

export type Level = {
  Elevation: number;
  ForElements: string;
  Id: string;
  Name: string;
};

export type Ceiling = {
  Id: string;
  LevelId: string;
  Name: string;
};

export type SelectionTreeAction = SetSelectionTreeAction | SetCeilingTreeAction;

export type SelectorAction =
  | InitGroupSelectorAction
  | SelectLevelAction
  | SelectTypeAction
  | SelectCeilingLevelAction
  | SelectCeilingTypeAction;
