import React from "react";
import { useForm } from "react-hook-form";
import { Form, Segment, Grid } from "semantic-ui-react";
import "../Resources/Technical.css";
import ButtonPack from "./ButtonPack";
import "../Resources/FormPanel.css";
import { FormPanels } from "./FormPanels";
import { Routes } from "./root";
import { bimStorage, storageKey } from "../../../../BIMStore";

export function SupplimentaryForm(props: any) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    var projectInfo = {};
    const documentName = props.projectData.ProjectId;
    data = { model_name: documentName, ...data };
    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );
    var preFillInfo = tempProjectInfo === null ? {} : tempProjectInfo;
    if (preFillInfo) {
      let index = 0;
      preFillInfo.SUPPLIMENTARY_FORM?.forEach((supplimentaryform: any) => {
        if (supplimentaryform.model_name === documentName)
          preFillInfo.SUPPLIMENTARY_FORM?.splice(index, 1);
        index++;
      });
      preFillInfo.SUPPLIMENTARY_FORM?.push(data);
      projectInfo = {
        PROJECT_INFO_FORM: preFillInfo.PROJECT_INFO_FORM,
        SUPPLIMENTARY_FORM: preFillInfo.SUPPLIMENTARY_FORM,
      };
    } else {
      projectInfo = {
        PROJECT_INFO_FORM: {},
        SUPPLIMENTARY_FORM: [data],
      };
    }
    bimStorage.setItem(storageKey.PROJECT_INFO, JSON.stringify(projectInfo));
    props.setRoute(Routes.ROOT);
    //window.location.href = props.custom.SystemTablePath;
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "1em" }}>
        <Segment className="formContainer">
          <Grid>
            {props.data.map((item: any, index: number) => {
              return (
                <Grid.Row
                  columns={item.length > 2 ? item.length : 2}
                  centered
                  style={{ padding: 0 }}
                >
                  <FormPanels
                    {...props}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    count={index}
                  />
                </Grid.Row>
              );
            })}
            {/* <Grid.Row columns={props.data.length}> */}
          </Grid>
          {Object.keys(errors).length > 0 ? (
            <p style={{ color: "red", margin: "5px 0px", textAlign: "center" }}>
              {props.wording.infoformerror[props.language]}
            </p>
          ) : (
            ""
          )}

        </Segment>
        <ButtonPack
          setRoute={props.setRoute}
          routes={props.routes}
          confirm={props.wording.confirm[props.language]}
          cancel={props.wording.back[props.language]}
          prevPage=""
        />
      </Form>
    </div>
  );
}
