import React, { useEffect, useState } from "react";
import { TopHeader } from "../../Components/Headers";
import { SubHeader } from "../../Components/Headers";
import { bimStorage } from "../../../../BIMStore";
import { Selection } from "../../Calepinage/Page/Selection";
import { Button, Dimmer, Grid, Icon, Loader, Modal } from "semantic-ui-react";
import { Step } from "./Step";
import { Colorization } from "./Colorization";
import { checkForWallType, createColorizationRow } from "../utils/colorization";
import { RevitSetup } from "./RevitSetup";
import { generatePlans, intitalRepData } from "../utils/colorization";
import { AppBody } from "../../Selection/Page/AppBody";
import { api } from "../../../../RevitJS/API";
import SaveProject from "../../CommonModules/SaveProject";
import updateSelectionIfWallDeleted from "../../CommonModules/Helpers/selectionUpdateHelper";

export interface ReperageData {
  selectionId: any;
  selectionStatus: boolean;
  colorizationStatus: boolean;
  revitSetupStatus: boolean;
  colorizations: any;
  revitSetup: any;
}

interface IProps {
  setRoute: any;
}

export const Home = (props: IProps) => {
  const [detailRoute, setDetailRoute] = useState<string>("");
  const [reperageData, setReperageData] =
    useState<ReperageData>(intitalRepData);

  const [loaderActive, setLoaderActive] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };
  useEffect(() => {
    console.log("Use Effect called");
    const getProjectId = async () => {
      const projectId = await bimStorage.onProjectData();
      console.log("Into getProjectID");
      if (projectId) {
        console.log("Getting into Load Reperage and ProjectID: ", projectId);
        await loadReperage();
      } else {
        console.log("Getting into ToggleModal");
        toggleModal();
      }
    };
    getProjectId();
  }, []);

  const loadReperage = async () => {
    const response = await bimStorage.getReperage();
    console.log("Reperage Details: ", response);
    const ifWallType = await checkForWallType(response);
    if (ifWallType) {
      const repResponce = await createColorizationRow(response.selectionId);
      console.log("Data from create colorization: ", repResponce);
      if (repResponce.length > 0) {
        let repObject = {
          ...response,
          selectionStatus: true,
          colorizationStatus: false,
          revitSetupStatus: false,
          colorizations: repResponce,
        };
        console.log(repObject);
        setReperageData(repObject);
      }
    } else {
      if (response) {
        setReperageData(response);
      } else {
        setReperageData(intitalRepData);
      }
    }
  };

  const onSelectionSelected = async (selectionId: any) => {
    const colorizationData = await createColorizationRow(selectionId);
    if (colorizationData.length > 0) {
      setReperageData((prev) => {
        return {
          ...intitalRepData,
          selectionId: selectionId,
          selectionStatus: true,
          colorizations: colorizationData,
        };
      });
    } else {
      setReperageData(intitalRepData);
    }

    setDetailRoute("home");
  };

  const onColorizationUpdate = (data: any) => {
    setReperageData((prev) => {
      return {
        ...prev,
        colorizationStatus: true,
        revitSetupStatus: false,
        colorizations: data,
      };
    });
  };

  const onRevitSetupUpdate = (data: any) => {
    setReperageData((prev) => {
      return {
        ...prev,
        revitSetupStatus: true,
        revitSetup: data,
      };
    });
    setDetailRoute("home");
  };

  const runGeneratePlan = async () => {
    setLoaderActive(true);
    await generatePlans(reperageData);
    await bimStorage.saveReperage(reperageData);
    localStorage.setItem("isModification", "false");
    setLoaderActive(false);
  };
  const HomePage = () => {
    return (
      <>
        <SubHeader heading={"0/3 Completed steps"} />
        <div
          className="grid-container-reperage"
          style={{ height: "calc(100vh - 30vh)", margin: "10px" }}
        >
          <Grid className="margin-top-10" textAlign="center">
            <Grid.Row>
              <Grid.Column style={{ margin: "15px 0px 65px 0px" }}>
                <Button
                  style={{
                    width: "max-content",
                    height: "45px",
                    fontSize: "16px",
                    margin: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    msTransform: "translate(-50%, -50%)",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => {}}
                  primary
                  size="medium"
                  content={"see my default custom colors"}
                  fluid
                ></Button>
              </Grid.Column>
            </Grid.Row>
            <Step
              setDetailRoute={setDetailRoute}
              selectionStatus={reperageData.selectionStatus}
              colorizationStatus={reperageData.colorizationStatus}
              revitSetupStatus={reperageData.revitSetupStatus}
              setRoute={props.setRoute}
            />
            <SaveProject
              toggleModal={toggleModal}
              confirmModal={confirmModal}
              onYesAction={onYesAction}
            />
          </Grid>
        </div>
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={() => {
                  runGeneratePlan();
                }}
                primary
                size="medium"
                style={{
                  width: "max-content",
                  margin: "5px 0px",
                }}
                disabled={false}
                content={"Generate Loation Plans"}
              ></Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  const DetailSwitch = () => {
    switch (detailRoute) {
      case "selection":
        return (
          <>
            <SubHeader heading={"Saved selections list"} />
            <Selection
              setDetailRoute={setDetailRoute}
              onSelect={onSelectionSelected}
              selectionId={reperageData.selectionId}
              setRoute={props.setRoute}
            />
          </>
        );
      case "SELECTION_HOME":
        return <AppBody />;
      case "colorization":
        return (
          <>
            <SubHeader heading={"Identification Table"} />
            <Colorization
              setDetailRoute={setDetailRoute}
              colorizations={reperageData.colorizations}
              onColorizationUpdate={onColorizationUpdate}
            />
          </>
        );
      case "revitsetup":
        if (reperageData.revitSetup) {
          return (
            <>
              <SubHeader heading={"Revit configuration"} />
              <RevitSetup
                onRevitSetupUpdate={onRevitSetupUpdate}
                revitSetupData={reperageData.revitSetup}
                onBack={() => setDetailRoute("home")}
              />
            </>
          );
        } else {
          return <></>;
        }
      default:
        return <HomePage />;
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Dimmer active={loaderActive} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Spotting in progress..."}></Loader>
      </Dimmer>
      {detailRoute === "SELECTION_HOME" ? (
        " "
      ) : (
        <TopHeader Icon={""} name={"COLORIZATION"} />
      )}
      {DetailSwitch()}
    </div>
  );
};
