import { FakeModel } from "../RevitJS/Types/FakerTypes";

export const Models: FakeModel[] = [
    {
        Name: "SimpleWalls",
        Image: "./FakeModels/SimpleWalls/SimpleWalls.PNG",
        WallsByLevelAndType:
            "./FakeModels/SimpleWalls/WallsByLevelAndType.json",
        CeilingsByLevelAndType:
            "./FakeModels/SimpleWalls/CeilingsByLevelAndType.json",
        PickWalls: "./FakeModels/SimpleWalls/PickWalls.json",
        PickCeilings: "./FakeModels/SimpleWalls/PickCeilings.json",
        PickWallFaces: "./FakeModels/SimpleWalls/PickWallFaces.json",
        PickCeilingFaces: "./FakeModels/SimpleWalls/PickCeilingFaces.json",
        WallsData: "./FakeModels/SimpleWalls/WallsData.json",
        CeilingsData: "./FakeModels/SimpleWalls/CeilingsData.json",
        PipesData: "./FakeModels/Pipes/PipesData.json",
        ManualSelectionPipesData:
            "./FakeModels/Pipes/ManualSelectionPipesData.json",
        ElementLevelType: "./FakeModels/Metres/ElementLevelByType.json",
        ElementsLevelAndTypeResult:
            "./FakeModels/Metres/ElementLevelByType.json",
        WallsObjectParamsResult:
            "./FakeModels/Metres/WallsObjectParamsResult.json",
        ElementParameters: "./FakeModels/SimpleWalls/ElementParameters.json"
    },
];
