import React, { Component, Fragment } from "react";
import { Menu, Header, Grid, Segment, Button, Image } from "semantic-ui-react";

interface Props {}
interface State {}

export default class BIMStudio extends Component<Props, State> {
  state = {};

  render() {
    return (
      <Fragment>
        <Grid style={{ height: "100vh", padding: 0 }}>
          <Grid.Column
            stretched
            width={3}
            style={{ margin: 0, paddingRight: 0 }}
          >
            <Menu
              stackable
              fluid
              vertical
              inverted
              color="blue"
              borderless
              style={{ borderRadius: 0, margin: 0, padding: 0 }}
            >
              <Menu.Item style={{ display: "flex", alignItems: "center" }}>
                <Image
                  circular
                  src="./saint-gobain.jpg"
                  style={{ minWidth: 60, width: "60px", maginRight: 20 }}
                />{" "}
                <div
                  style={{
                    marginLeft: 20,
                    fontSize: "1.5em",
                    fontWeight: "bold",
                  }}
                >
                  BIM JS
                </div>
              </Menu.Item>
              <Menu.Item>
                <Menu.Header
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Getting Started
                </Menu.Header>

                <Menu.Menu>
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Revit and BIM Softwares"
                  />
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="PIM BIM Interface"
                  />
                </Menu.Menu>
              </Menu.Item>

              <Menu.Item>
                <Menu.Header
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  BIM JS Solution
                </Menu.Header>

                <Menu.Menu>
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Revit development pitfalls"
                  />
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Tech principles"
                  />

                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Modules"
                  />
                </Menu.Menu>
              </Menu.Item>

              <Menu.Item>
                <Menu.Header
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  BIM Studio Services
                </Menu.Header>

                <Menu.Menu>
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Development"
                  />
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Hosting"
                  />
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="PIM Buffer"
                  />
                </Menu.Menu>
              </Menu.Item>

              <Menu.Item>
                <Menu.Header
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  For developers
                </Menu.Header>

                <Menu.Menu>
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Types"
                  />
                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="API"
                  />

                  <Menu.Item
                    style={{ fontSize: "1em", fontWeight: "bold" }}
                    name="Components"
                  />
                </Menu.Menu>
              </Menu.Item>
            </Menu>
          </Grid.Column>

          <Grid.Column
            stretched
            width={13}
            style={{ margin: 0, paddingLeft: 0, height: "100vh" }}
          >
            <Segment
              basic
              fluid
              style={{ margin: 0, backgroundColor: "rgb(240, 240, 250)" }}
            >
              <Grid relaxed stackable>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <div style={{ marginTop: "5em" }}>
                      <Header
                        textAlign="center"
                        as="h2"
                        content="Revit Addins Development."
                        style={{
                          fontSize: "3em",
                          fontWeight: "normal",
                          marginBottom: 0,
                        }}
                      />
                      <Header
                        textAlign="center"
                        as="h2"
                        content="Redefined."
                        style={{
                          fontSize: "2em",
                          fontWeight: "normal",
                          marginTop: "0.5em",
                        }}
                      />

                      <Header
                        textAlign="center"
                        as="h2"
                        content="Make the most of your PIM"
                        style={{
                          fontSize: "2em",
                          fontWeight: "normal",
                          marginTop: "0.5em",
                        }}
                      />

                      <Header
                        textAlign="center"
                        as="h2"
                        content="Build Web Apps. Run them on Revit"
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "normal",
                          marginTop: "0.5em",
                        }}
                      />
                      <Header textAlign="center">
                        <Button primary size="huge" style={{ margin: 10 }}>
                          BIMJS-19
                        </Button>
                        <Button primary size="huge" style={{ margin: 10 }}>
                          BIMJS-20
                        </Button>
                      </Header>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={10} textAlign="center">
                    <div style={{ textAlign: "center", marginTop: "2em" }}>
                      {/* <Header textAlign="center"> */}
                      <Image src="./home_image.png" size="big" />
                      {/* </Header> */}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}
