export const SET_METRE_SELECTION = "SET_METRE_SELECTION";
export const CLEAR_SELECT_METRE = "CLEAR_SELECT_METRE";
export const SELECT_METRE_PAR_ETAGE = "SELECT_METRE_PAR_ETAGE";
export const SELECT_METRE_PAR_ZONE = "SELECT_METRE_PAR_ZONE";
export const SET_DOSSIER_DATA = "SET_DOSSIER_DATA";
export const SET_PREV_PAGE = "SET_PREV_PAGE";
export const SET_DOSSIER_DATAFLAG ="SET_DOSSIER_DATAFLAG";

export const INIT_LANGUAGE = "INIT_LANGUAGE";
export interface InitLanguageAction {
    type: typeof INIT_LANGUAGE;
    language: Language;
}

export type Language = "French" | "English";