import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";
import "./body.scss";

function Body({ preferences, setPreferences, t }: IProps) {
    return (
        <>
            <div id="propreties-preference-component-body-container">
                <div id="properties-checkbox-div">
                    <div className="propreties-preference-title">
                        <h4>{t("CONSTRUCTION")}</h4>
                    </div>
                    {preferences.filter(preference => preference.param_group !== "DATA").map(preference =>
                        <Checkbox
                            label={t(preference.label)}
                            checked={preference.checked}
                            onChange={(event, data) => {
                                setPreferences(preference, data.checked);
                            }}
                            className="my-1"
                        />)}
                    <div className="propreties-preference-title">
                        <h4>{t("DATA")}</h4>
                    </div>
                    {preferences.filter(preference => preference.param_group === "DATA").map(preference =>
                        <Checkbox
                            label={t(preference.label)}
                            checked={preference.checked}
                            onChange={(event, data) => {
                                setPreferences(preference, data.checked);
                            }}
                            className="my-1"
                        />)}

                </div>
            </div>
        </>
    );
}

type IProps = WithTranslation & {
    preferences: any[];
    setPreferences: Function;
};

export default withTranslation(["pamCanalisation"])(Body);
