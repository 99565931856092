import React, { useState, useEffect, Component } from "react";
import { Button, Input, Dimmer, Loader } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { MyConfig } from "../../../../Helper";
import { DimensionRevitApi } from "../../../../ErrorManagement/utils/revitApiError";
import SaveProject from "../../CommonModules/SaveProject";
import { bimStorage } from "../../../../BIMStore";

interface Props {
  onError: any;
}

export const Waiter = (Props: any) => {
  const [distance, setDistance]: any = useState(400);
  const [config, setConfig]: any = useState("");
  const [activeLoader, setActiveLoader] = useState(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };
  useEffect(() => {
    async function getConfig() {
      const cng = await MyConfig();
      setConfig(cng);
    }
    const getProjectId = async () => {
      const projectId = await bimStorage.onProjectData();
      if(projectId){
        getConfig();
      }
      else{
        toggleModal();
      }
    }
    getProjectId();
  }, []);

  return (
    <div>
      <Dimmer active={activeLoader} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Loading..."}></Loader>
      </Dimmer>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/plugin_new_logo/BeneluxIcon.png"}
        name={"Dimensions"}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: 20 }}>
          Dimensions distance (mm):{" "}
          <Input
            style={{ margin: 10 }}
            value={distance}
            onChange={(e, d) => {
              isNaN(parseFloat(d.value))
                ? setDistance("")
                : setDistance(parseFloat(d.value));
            }}
          />
          <Button
            primary
            disabled={distance > 0 ? false : true}
            onClick={async () => {
              setActiveLoader(true);
              await api.familyEditor.addCotesMM(distance);
              DimensionRevitApi(Props);
              setActiveLoader(false);
            }}
          >
            Validate
          </Button>
        </div>
      </div>
      <SaveProject
              toggleModal={toggleModal}
              confirmModal={confirmModal}
              onYesAction={onYesAction}
      />
    </div>
  );
};
