import React, { Component } from "react";
import { connect } from "react-redux";
import { FunctionalityHeader } from "../Components/FunctionalityHeader";
import { SupplimentaryForm } from "./SupplimentaryForm";
import { Routes } from "./root";
import { SelectionStore } from "../../Selection/Reducers";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";

interface Props {
  Icon: string;
  name: string;
  wording: any;
  language: string;
  setRoute: any;
  routes: any;
  projectData: initProjectData;
}

export class InfoSupplimentary extends Component<Props> {
  state = {
    yourLanguage: null,
    currentView: null,
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  componentDidMount = async () => {};

  confirmHandler = async () => {
    this.props.setRoute(Routes.SUPPLIMENTARY);
  };

  render = () => {
    let count = 1;
    const supplimentaryformData = [
      [
        {
          header: this.props.wording.formHeader3[this.props.language],
          fields: [
            {
              type: "input",
              name: "maitre_douvrage_name",
              label: this.props.wording.name[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "maitre_douvrage_address",
              label: this.props.wording.address[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "maitre_douvrage_telephone",
              label: this.props.wording.telephone[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "maitre_douvrage_email",
              label: this.props.wording.email[this.props.language],
              mandatory: false,
            },
          ],
        },
        {
          header: this.props.wording.formHeader4[this.props.language],
          fields: [
            {
              type: "input",
              name: "maitre_doeuvre_name",
              label: this.props.wording.name[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "maitre_doeuvre_address",
              label: this.props.wording.address[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "maitre_doeuvre_telephone",
              label: this.props.wording.telephone[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "maitre_doeuvre_email",
              label: this.props.wording.email[this.props.language],
              mandatory: false,
            },
          ],
        },
      ],
      [
        {
          header: this.props.wording.formHeader5[this.props.language],
          fields: [
            {
              type: "input",
              name: "enterprise_generale_name",
              label: this.props.wording.name[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "enterprise_generale_address",
              label: this.props.wording.address[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "enterprise_generale_telephone",
              label: this.props.wording.telephone[this.props.language],
              mandatory: false,
            },
            {
              type: "input",
              name: "enterprise_generale_email",
              label: this.props.wording.email[this.props.language],
              mandatory: false,
            },
          ],
        },
        {
          header: "Lot",
          fields: [
            {
              type: "input",
              name: "lot_1",
              label: "Lot " + count++,
              mandatory: false,
            },
            {
              type: "input",
              name: "lot_2",
              label: "Lot " + count++,
              mandatory: false,
            },
            {
              type: "input",
              name: "lot_3",
              label: "Lot " + count++,
              mandatory: false,
            },
          ],
        },
      ],
    ];

    return (
      <div>
        <FunctionalityHeader
          Icon={this.props.Icon}
          header={this.props.wording.infoprojectsheader[this.props.language]}
          subheader={
            "(" +
            this.props.wording.infoprojectssubheader[this.props.language] +
            ")"
          }
          // style={{ position: "fixed" }}
        />
        <SupplimentaryForm data={supplimentaryformData} {...this.props} />
      </div>
    );
  };
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    routes: ownProps.routes,
    projectData: state.projectData,
  };
};

export default connect(mapStateToProps)(InfoSupplimentary);
