import React from "react";
import { TopHeader, SubHeader } from "../../Components/Headers";
import { Selection } from "./Selection";

interface Props{
  setRoute: any;
  onError: any;
}


export const SuppressionHome = (props: Props) => {
    // const onSelectionSelected = (selectionId: any) => {
    //     console.log(selectionId);
    //     setRoute("")
    // }
    return (
        <div style={{ height: "100%" }}>
        <TopHeader Icon={""} name={"Delete"} />
        <>
        <SubHeader heading={"Saved selections list"} />
            <Selection
              setRoute={props.setRoute}
              selectionId={1}
            />
        </>
      </div>
    )
}

export default SuppressionHome;
