import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import Waiter from "./Components/Waiter";

export const functionalityId = "0ef9830d-eac9-4346-b813-1b644eca541a";

export const dimensionsTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Waiter />
    </ParentRoot>
  );
};

export const RiggipsDimensionRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Waiter />
    </ParentRoot>
  );
};

export const Dimensions = {
  Name: { French: "Cotation", English: "Dimensions", German: "Masse" },
  Trigger: dimensionsTrigger,
  ShortDesc: {
    French: "Côtes de calepinage",
    English: "Layout ribs",
    German: "Layoutrippen",
  },
  Id: functionalityId,
  RootComponent,
  Icon: "/pluginIcons_V3/PlacoBIM_Icon_Cotation-2.png",
  ParentId: "0",
};
