import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Routes } from "./root";
import InfoProjects from "./InfoProjects";
import InfoSupplimentary from "./InfoSupplimentary";
import { initLanguage } from "../Actions";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";

interface Props {
  route: Routes;
  setRoute: any;
  initLanguage: any;
  setConfig: any;
}

export class AppBody extends Component<Props> {
  componentDidMount = async () => {
    this.props.initLanguage();
    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  render() {
    window.scrollTo(0, 0);
    switch (this.props.route) {
      case Routes.ROOT:
        return (
          <InfoProjects
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );

      case Routes.SUPPLIMENTARY:
        return (
          <InfoSupplimentary
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};

const mapDispatchToProps = {
  initLanguage,
  setConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBody);
