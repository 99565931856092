import React, { Component } from "react";
import { Button } from "semantic-ui-react";

export default class TypesToogler extends Component {
  render() {
    if (this.props.toogleOn) {
      return (
        <div style={{ textAlign: "center" }}>
          <Button
          style={{marginTop: 10}}
            disabled={!this.props.activated}
            onClick={this.props.toogleTypes}
            circular
            primary
            icon="check"
          />
        </div>
      );
    }
    return (
      <div style={{ textAlign: "center" }}>
        <Button
        style={{marginTop: 10}}
          disabled={!this.props.activated}
          onClick={this.props.toogleTypes}
          circular
          primary
          icon="times"
        />
      </div>
    );
  }
}
