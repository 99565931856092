import React from "react";
import { find } from "lodash";
import { Subtract } from "utility-types";
import { ErrorModal } from "./ErrorModal";
import errorData from "../utils/errorMessage.json";
import ReportErrorForm from "./ErrorReport";

interface IErrorType {
  code: string;
  message: string;
  severity: string;
}
export interface InjectedCounterProps {
  onError(
    statusCode: string,
    stackTrace: any,
    errorStatus: boolean,
    reload?: boolean,
    onReloadRedirect?: string
  ): void;
}

interface MakeCounterState {
  hasError: boolean;
  error: IErrorType;
  stackTrace: any;
  onReport: boolean;
  reload: boolean;
  onReloadRedirect: string;
  errorStatus: boolean;
}

const initialState: MakeCounterState = {
  hasError: false,
  error: { code: "0", message: "", severity: "" },
  stackTrace: undefined,
  errorStatus: false,
  onReport: false,
  reload: false,
  onReloadRedirect: "",
};

export const withErrorSubscription = <P extends InjectedCounterProps>(
  Component: React.ComponentType<P>
) =>
  class MakeCounter extends React.Component<
    Subtract<P, InjectedCounterProps>,
    MakeCounterState
  > {
    state: MakeCounterState = initialState;

    onError = (
      statusCode: string,
      stackTrace: any,
      errorStatus: boolean = false,
      reload: boolean = false,
      onReloadRedirect: string = ""
    ) => {
      let error = find(errorData, { code: statusCode });
      if (!error) {
        error = find(errorData, { code: "1" });
      }
      //if (errorStatus === false) {
      if (error && errorStatus && !this.state.onReport) {
        this.setState({
          hasError: true,
          error: error,
          stackTrace: stackTrace,
          onReport: false,
          reload: reload,
          onReloadRedirect: onReloadRedirect,
        });
      }
      // } else {
      //   window.location.reload();
      // }
    };

    setReportError = (status: boolean) => {
      this.setState((prevState) => {
        return { ...prevState, hasError: false, onReport: status };
      });
    };

    render() {
      if (this.state.hasError) {
        return (
          <ErrorModal
            open={this.state.hasError}
            // open={false}
            reload={this.state.reload}
            onReloadRedirect={this.state.onReloadRedirect}
            closeModal={() => {
              this.setState((prevState) => {
                return { ...prevState, hasError: false, reload: false };
              });
            }}
            openReport={this.setReportError}
            message={this.state.error.message}
            code={this.state.error.code}
          />
        );
      }

      if (this.state.onReport) {
        return (
          <ReportErrorForm
            {...this.state.error}
            stackTrace={this.state.stackTrace}
            setReportError={this.setReportError}
            open={this.state.onReport}
          />
        );
      }

      return <Component {...(this.props as P)} onError={this.onError} />;
    }
  };
