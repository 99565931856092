import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  Navigate,
} from "react-router-dom";
import BIMStudio from "./Components/BIMStudio";
import Authenticate, { registerFing } from "./Components/Store/Authenticate";
import RevitFaker from "./RevitFaker/Components/RevitFaker";
import LandingScreen from "./Dock/LandingScreen";
import { Dock } from "./Dock";
import {
  DockServiceUnavailable,
  PluginServiceUnavailable,
} from "./Components/Error/serviceUnavailable";
import { MyConfig } from "./Helper";
import { IframeTest } from "./Components/CommonComponents/iframtest";
import ErrorPage from "./ErrorPage";
import { getJwtToken, getRefreshToken } from "./API/authenticate.api";
import { v4 } from "uuid";
import { api } from "./RevitJS/API";
import { RootComponent } from "./Plugins/PlacoBIMv3/Selection";
import PluginHome from "./Components/Store/PluginHome";
import { ProjectInfoRootComponent } from "./Plugins/PlacoBIMv3/ProjectInfo";
import { CalepinageRootComponent } from "./Plugins/PlacoBIMv3/Calpinage";
import { QuantitatiffRootComponent } from "./Plugins/PlacoBIMv3/Quantitatiff";
import { BiblioPropertiesRootComponent } from "./Plugins/PlacoBIMv3/Bibliothèque/Properties";
import { BiblioLoadSystemRootComponent } from "./Plugins/PlacoBIMv3/Bibliothèque/LoadSystemContainer";
import { BiblioUpdateSystemsRootComponent } from "./Plugins/PlacoBIMv3/Bibliothèque/UpdateSystems";
import { MetresRootComponent } from "./Plugins/PlacoBIMv3/Metres";
import { NomenclatureRootComponent } from "./Plugins/PlacoBIMv3/Nomenclature";
import { DocTechRootComponent } from "./Plugins/PlacoBIMv3/DocumentTechniques";
import { ReperageRootComponent } from "./Plugins/PlacoBIMv3/Reperage";
import { ReversSideRootComponent } from "./Plugins/PlacoBIMv3/ReverseSides";
import { ReversDirectionRootComponent } from "./Plugins/PlacoBIMv3/ReverseDirection";
import { ColorRootComponent } from "./Plugins/PlacoBIMv3/Colorization";
import { DimensionRootComponent } from "./Plugins/PlacoBIMv3/Dimensions";
import { ShowWallRootComponent } from "./Plugins/PlacoBIMv3/ShowWalls";
import { ShowSolutionRootComponent } from "./Plugins/PlacoBIMv3/ShowSolutions";
import { OverlapRootComponent } from "./Plugins/PlacoBIMv3/Overlap";
import { HelpRootComponent } from "./Plugins/PlacoBIMv3/Help";

import { CLTRootComponent } from "./Plugins/CLT/Draw2";
import { CltDeleteRootComponent } from "./Plugins/CLT/Delete";
import { CltQuantitatiffRootComponent } from "./Plugins/CLT/Quantitatifs";
import { PamCanalisationRootComponent } from "./Plugins/PamCanalisation/FamilliesImport";
import { LicenseRootComponent } from "./Plugins/General/License";
import { RiggipsWallScheduleRootComponent } from "./Plugins/RiggipsBIM/WallSchedules";
import { RiggipsDrawRootComponent } from "./Plugins/RiggipsBIM/Draw2";
import { RiggipsDeleteRootComponent } from "./Plugins/RiggipsBIM/Delete";
import { RiggipsColorization } from "./Plugins/RiggipsBIM/Colorization";
import { RiggipsQuantityRootComponent } from "./Plugins/RiggipsBIM/QuantityTakeOff";
import { RiggipsDimensionRootComponent } from "./Plugins/RiggipsBIM/Dimensions";
import { DockUpdateRoot } from "./Plugins/General/Update";
import { SuppressionRootComponent } from "./Plugins/PlacoBIMv3/Suppression";
import { UpdateSystemRootComponent } from "./Plugins/PlacoBIMv5/UpdateSystems";
import { LoadSystemRootComponent } from "./Plugins/PlacoBIMv5/LoadSystemContainer";
import { PropertiesRootComponent } from "./Plugins/PlacoBIMv5/Properties";
import { RootLoadSystemsComponent } from "./Plugins/WeberIndonesia/LoadSystemContainer";
import { RootUpdateSystemsComponent } from "./Plugins/WeberIndonesia/UpdateSystems";
import { RootHelpComponent } from "./Plugins/WeberIndonesia/Help";
import { RootPropertiesComponent } from "./Plugins/WeberIndonesia/Properties";
import { UserManualRootComponent } from "./Plugins/PlacoBIMv5/UserManual";
import { RootUserManualComponent } from "./Plugins/PlacoBIMv3/UserManual";
import { PamImportRootComponent } from "./Plugins/PAM/pam-import";
import { TrainingOneRootComponent } from "./Plugins/PluginTraining/TrainingOne";
import { TrainigTwoRootComponent } from "./Plugins/PluginTraining/TrainingTwo";
import { FacadeCalepinageRoot } from "./Plugins/Facade/Calepinage";
import { MiterJointRootComponent } from "./Plugins/Facade/MiterJoint";
import { FacadeQuantitatiffRoot } from "./Plugins/Facade/Quantitatifs";
import { SuperposerRootComponent } from "./Plugins/Facade/Superposer";
import { FacadeSupprimerRoot } from "./Plugins/Facade/Supprimer";
import { VueCalepinageRoot } from "./Plugins/Facade/VueDeCalepinage";
import { VueDoRigineRoot } from "./Plugins/Facade/Vuedorigine";
import { ModelGenObjectGeneratorRoot } from "./Plugins/ModelGen/ObjectGenerator";
import { ModelGenConfigurationRoot } from "./Plugins/ModelGen/Configuration";
import { ModelGenConvertRevitVersionRoot } from "./Plugins/ModelGen/ConvertRevitVersion";
import { ModelGenIFCGeneratorRoot } from "./Plugins/ModelGen/IFCGenerator";
import { ReleaseNoteRootComponent } from "./Plugins/General/ReleaseNote";
import { ProfileRootComponent } from "./Plugins/General/Profile";
import { QuantitatiffOldRootComponent } from "./Plugins/PlacoBIMv3/QuantitatiffOldProd";
import { getNewJwtToken } from "./API/authenticate.api";
import { QuantifierParRatioRootComponent } from "./Plugins/PlacoBIMv3/QuantifierParRatio";
import { TestRootComponent } from "./Plugins/GenericPlugin/TestPlugin";
import {
  ReengineeringRootComponent,
  reengineeringTrigger,
} from "./Components/re-engineering/common/re_engineering_index";
import { GyprocSelectionRootComponent } from "./Plugins/Gyproc/Selection";
import { GyprocCalepinageRootComponent } from "./Plugins/Gyproc/Calepinage";
import { GyprocDeleteRootComponent } from "./Plugins/Gyproc/Delete";
import { GyprocMetresRootComponent } from "./Plugins/Gyproc/Metres";
import { GyprocReperageComponent } from "./Plugins/Gyproc/Reperage";
import { GyprocProjectInfoRootComponent } from "./Plugins/Gyproc/ProjectInfo";
import { GyprocDimensionsRootComponent } from "./Plugins/Gyproc/Dimensions";

import { RootLoadSystemsComponentTest } from "./Plugins/WeberIndonesia-Test/LoadSystemContainer";
import { RootUpdateSystemsComponentTest } from "./Plugins/WeberIndonesia-Test/UpdateSystems";
import { RootHelpComponentTest } from "./Plugins/WeberIndonesia-Test/Help";
import { RootPropertiesComponentTest } from "./Plugins/WeberIndonesia-Test/Properties";
import { UpdateSystemRootComponentTest } from "./Plugins/PlacoBIMv5-Test/UpdateSystems";
import { LoadSystemRootComponentTest } from "./Plugins/PlacoBIMv5-Test/LoadSystemContainer";
import { PropertiesRootComponentTest } from "./Plugins/PlacoBIMv5-Test/Properties";
import { CalepinageRootComponentTest } from "./Plugins/PlacoBIMv3-Test/Calpinage";
import { RootComponent as RootComponentTest } from "./Plugins/PlacoBIMv3-Test/Selection";
import { ProjectInfoRootComponentTest } from "./Plugins/PlacoBIMv3-Test/ProjectInfo";


declare global {
  var windowReload: () => Promise<void>;
  var reengineering: () => Promise<void>;
  var revitCallBack: (responseBack: any, status: string) => Promise<void>;
}

export const NewRouter = () => {
  const extractAuthCode = (url: string): string | null => {
    var pattern = "code=";
    if (url.indexOf(pattern) >= 0) {
      let poundIndex: number | undefined = url.indexOf("#");
      const patIndex = url.indexOf(pattern);
      if (poundIndex === -1 || poundIndex < patIndex) {
        poundIndex = undefined;
      }
      let code = url.substring(patIndex + pattern.length, poundIndex);
      return code;
    }
    return null;
  };

  async function authLoader() {
    const authCode: string | null = extractAuthCode(window.location.href);
    if (authCode) {
      const config = await MyConfig("Refresh token");
      const refToken = await getRefreshToken(authCode, config);
      if (refToken.refresh_token) {
        const accToken = await getJwtToken(refToken.refresh_token, config);
        if (accToken.access_token) {
          localStorage.setItem("refresh_token", refToken.refresh_token);
          localStorage.setItem("token", accToken.access_token);
          localStorage.setItem("eventID", v4());
          registerFing(accToken.access_token, config);
          return accToken.access_token;
        }
      }
    }
    return null;
  }

  window.windowReload = async () => {
    const config = await MyConfig();
    await getNewJwtToken(config);
    window.revit.dockReload();
  };

  window.reengineering = async () => {
    const config = await MyConfig();
    reengineeringTrigger(config, "ReEngineer");
  };

  const languageLoader = async () => {
    let revitLanguage = await api.queries.getRevitLanguage();
    return revitLanguage;
  };

  const loginLoader = async () => {
    const config = await MyConfig();
    return redirect(
      `${config.REACT_APP_AUTHENTICATIONURL}${config.REACT_APP_LOGIN_REDIRECT}`
    );
  };

  const routerArray = [
    {
      path: "/",
      element: <Navigate to="/home" replace />,
      loader: languageLoader,
      errorElement: <ErrorPage />,
    },
    {
      path: "/authenticate&scope=user:database:read",
      element: <Authenticate />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/authenticate",
      element: <Authenticate />,
      loader: authLoader,
      errorElement: <ErrorPage />,
    },
    {
      path: "/bimauth",
      action: loginLoader,
    },
    { path: "/bim", element: <BIMStudio />, errorElement: <ErrorPage /> },
    {
      path: "/home",
      element: <LandingScreen />,
      loader: languageLoader,
      errorElement: <ErrorPage />,
    },
    { path: "/dock", element: <Dock />, errorElement: <ErrorPage /> },
    {
      path: "/pluginHome",
      element: <PluginHome />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/revitFaker",
      element: <RevitFaker />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/pluginServiceUnavailable",
      element: <PluginServiceUnavailable />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/dockServiceUnavailable",
      element: <DockServiceUnavailable />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/bimiframe",
      element: <IframeTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/errorpage",
      element: <ErrorPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/0ad35a84-d2f2-11ea-87d0-0242ac130003",
      element: <ProjectInfoRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/fef4020e-8899-4d51-9e4e-ac8cee5b797b",
      element: <RootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/9c7b1ed8-69db-45fb-8550-e441f3306a4c",
      element: <CalepinageRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/e9ea6940-9055-4a76-a717-4579300b4ec5",
      element: <QuantitatiffRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/a995b07d-9260-42c0-9c32-4910a709e39a",
      element: <QuantitatiffOldRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/434b007f-9956-4c5f-a246-c129f595c9a8",
      element: <BiblioPropertiesRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/8d3e7ce8-0051-4b6f-a391-010fc400e6a4",
      element: <BiblioLoadSystemRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/902ff35b-d6fd-419d-bdcf-9ff22592501c",
      element: <BiblioUpdateSystemsRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/abfb1e2e-b36b-4be4-a78d-df2472cbb96c",
      element: <MetresRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/e7f1efde-96e9-4240-bd93-e09761e31d76",
      element: <NomenclatureRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/6a1b40a0-6e20-4fbd-9ae3-ad819351a69b",
      element: <DocTechRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/547f5d6a-8296-4d01-887b-39fa41e422c9",
      element: <ReperageRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/f8add0ba-cb6c-42c7-b59f-93f7cfb335fc",
      element: <ReversSideRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/a3a0c38d-db27-4ee2-96db-7e130824b3ff",
      element: <ReversDirectionRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/103acfe1-f701-4a2b-95ef-a870fbe3aab6",
      element: <ColorRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/4c002a66-3130-4053-a976-249a1d0a1488",
      element: <DimensionRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/aad1c6ac-6b71-411b-8b67-8ae16bd8660e",
      element: <ShowSolutionRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/bf6f37cc-f0c2-11ea-adc1-0242ac120002",
      element: <SuppressionRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/0602b372-7d70-4841-af29-ee8e02d95d40",
      element: <ShowWallRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/a2ae8520-fd6b-4b5b-8176-16d1b4a7647d",
      element: <OverlapRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/4b2aab5f-e91f-46b3-99be-fadccf9306fe",
      element: <HelpRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/58a79ec5-15a7-4c37-9862-debee03341c8",
      element: <UserManualRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/91896076-56ea-42bb-a9b6-1da281d4eef6/8afc96d9-789d-4192-b9ed-8a361c6635c9",
      element: <CLTRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/91896076-56ea-42bb-a9b6-1da281d4eef6/43142c42-9fbb-458b-b7db-f3bafab505a5",
      element: <CltDeleteRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/91896076-56ea-42bb-a9b6-1da281d4eef6/cbbc5a16-7230-4576-81ef-795a067da69f",
      element: <CltQuantitatiffRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/d969d046-cd21-4aa4-a822-b1f7fd96aeea/ca3bfd85-f3c8-421a-b0ae-d28509e45d9f",
      element: <PamCanalisationRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/a1904a90-8130-4e50-8412-d03751af7d7c/58a79ec5-15a7-4c37-9862-debee03341c8",
      element: <LicenseRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/e990ace7-9a6a-40e3-a8fa-a151773c7713/17f8b723-6587-4d79-85f8-506b45a8df9e",
      element: <RiggipsWallScheduleRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/e990ace7-9a6a-40e3-a8fa-a151773c7713/8afc96d9-789d-4192-b9ed-8a361c6635c9",
      element: <RiggipsDrawRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/e990ace7-9a6a-40e3-a8fa-a151773c7713/43142c42-9fbb-458b-b7db-f3bafab505a5",
      element: <RiggipsDeleteRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/e990ace7-9a6a-40e3-a8fa-a151773c7713/a3d5f5cd-0485-4135-918f-48901ff8b600",
      element: <RiggipsColorization />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/e990ace7-9a6a-40e3-a8fa-a151773c7713/4b478242-3f53-40ce-be91-8f9eda765b70",
      element: <RiggipsQuantityRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/e990ace7-9a6a-40e3-a8fa-a151773c7713/0ef9830d-eac9-4346-b813-1b644eca541a",
      element: <RiggipsDimensionRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/a1904a90-8130-4e50-8412-d03751af7d7c/1938f124-b5cb-4523-aff8-fc8e723bd521",
      element: <DockUpdateRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/370cbf02-2f20-11eb-adc1-0242ac120002/4b2aab5f-e91f-46b3-99be-fadccf9306fe",
      element: <HelpRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/370cbf02-2f20-11eb-adc1-0242ac120002/1e41dd56-3d5b-11eb-adc1-0242ac120002",
      element: <LoadSystemRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/370cbf02-2f20-11eb-adc1-0242ac120002/ca3bfd85-f3c8-421a-b0ae-d28509e45d9f",
      element: <PropertiesRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/370cbf02-2f20-11eb-adc1-0242ac120002/61c4c408-6c54-11eb-9439-0242ac130002",
      element: <UpdateSystemRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/370cbf02-2f20-11eb-adc1-0242ac120002/58a79ec5-15a7-4c37-9862-debee03341c8",
      element: <UserManualRootComponent />,
      errorElement: <ErrorPage />,
    },
    //START WEBER

    {
      path: "/plugin/9a5ac6ee-883f-4e64-92e1-e0fb170abcfa/3b11488e-6db7-410d-afec-2719fca997cf",
      element: <RootHelpComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/9a5ac6ee-883f-4e64-92e1-e0fb170abcfa/660e63a3-9857-418d-9504-0251ab5e5f8d",
      element: <RootLoadSystemsComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/9a5ac6ee-883f-4e64-92e1-e0fb170abcfa/45d61de6-b9e4-4d24-8a5c-bb2cbffe1df6",
      element: <RootPropertiesComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/9a5ac6ee-883f-4e64-92e1-e0fb170abcfa/6b7a209e-3aad-4c39-b407-9d9ad1aa7f45",
      element: <RootUpdateSystemsComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/9a5ac6ee-883f-4e64-92e1-e0fb170abcfa/7be7d054-e0e3-4a71-9604-c1ea99c70f85",
      element: <RootUserManualComponent />,
      errorElement: <ErrorPage />,
    },

    //Weber Test
    {
      path: "/plugin/3c7bd045-e64b-4ae8-a98d-8c2d66c2ace2/4f96122e-f63f-4962-a300-7fa7214f88d2",
      element: <RootHelpComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/3c7bd045-e64b-4ae8-a98d-8c2d66c2ace2/5893c78b-9e68-4580-9cc3-6b33581e7646",
      element: <RootLoadSystemsComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/3c7bd045-e64b-4ae8-a98d-8c2d66c2ace2/61d9320a-9d54-42d5-bb36-632c2f49e3db",
      element: <RootPropertiesComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/3c7bd045-e64b-4ae8-a98d-8c2d66c2ace2/89ac1269-e75f-46f0-8b92-9845789d0050",
      element: <RootUpdateSystemsComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/3c7bd045-e64b-4ae8-a98d-8c2d66c2ace2/88e1553b-0189-4092-94ba-ff62f00be367",
      element: <RootUserManualComponent />,
      errorElement: <ErrorPage />,
    },

    //Prescribim test
    {
      path: "/plugin/662a9500-3351-43ed-b4a0-79f5452881e5/4b2aab5f-e91f-46b3-99be-fadccf9306fe",
      element: <HelpRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/662a9500-3351-43ed-b4a0-79f5452881e5/076e22c9-69c0-40f0-8707-4ee3dc5d5d0e",
      element: <LoadSystemRootComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/662a9500-3351-43ed-b4a0-79f5452881e5/3a85132d-3740-47c2-a254-4be1f58238d4",
      element: <PropertiesRootComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/662a9500-3351-43ed-b4a0-79f5452881e5/7f8de14f-aa4f-4b81-b530-3515a83976cb",
      element: <UpdateSystemRootComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/662a9500-3351-43ed-b4a0-79f5452881e5/58a79ec5-15a7-4c37-9862-debee03341c8",
      element: <UserManualRootComponent />,
      errorElement: <ErrorPage />,
    },


    //PAMDI
    {
      path: "/plugin/317ca9f1-510b-4336-b98d-cd6f52b46dfb/bce977ee-25fb-46d6-9e1b-be4bc00afee3",
      element: <PamImportRootComponent />,
      errorElement: <ErrorPage />,
    },
    // plugin training
    {
      path: "/plugin/114e070d-5c07-4e6b-a0a3-d2ee17301fa5/a8468df1-523c-46ca-8fd1-c66a00ac1e5f",
      element: <TrainingOneRootComponent />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/plugin/114e070d-5c07-4e6b-a0a3-d2ee17301fa5/4c6a26db-2148-4670-8b8c-8661df38cf04",
      element: <TrainigTwoRootComponent />,
      errorElement: <ErrorPage />,
    },
    // Facade
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/e59f9553-567d-460a-89cf-82634b875c00",
      element: <FacadeCalepinageRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/5085ee0a-3002-4610-9b48-4c4f440065b5",
      element: <MiterJointRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/984a91d1-6216-44d8-8625-c2807e6a55e4",
      element: <FacadeQuantitatiffRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/154175d1-2872-4b5c-b719-d7616f9ee2c8",
      element: <SuperposerRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/10daf824-7d86-4edd-a6e3-cada7fd187c7",
      element: <FacadeSupprimerRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/090655ea-1648-4466-bb6a-833fa8b801a4",
      element: <VueCalepinageRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/6313b2e8-6e86-4dfd-bf3e-8c27c6212024/4ea24f46-8b97-416d-89f8-52d5b8f59719",
      element: <VueDoRigineRoot />,
      errorElement: <ErrorPage />,
    },
    // Model Gen
    {
      path: "/plugin/81b41a9f-2200-402b-ac72-4570738453e2/7d96c97f-06e0-4d5f-ba9d-69f9fedff6f5",
      element: <ModelGenObjectGeneratorRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/81b41a9f-2200-402b-ac72-4570738453e2/208450ca-f4f5-4082-8144-f31ce11f1410",
      element: <ModelGenConfigurationRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/81b41a9f-2200-402b-ac72-4570738453e2/2859247c-9dbb-4b05-a53f-6a44374c7a2b",
      element: <ModelGenIFCGeneratorRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/81b41a9f-2200-402b-ac72-4570738453e2/366def56-4c74-42cd-a886-b9588da828e7",
      element: <ModelGenConvertRevitVersionRoot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/a1904a90-8130-4e50-8412-d03751af7d7c/283f7f5c-b490-4058-a2ac-25fecfa7d75c",
      element: <ReleaseNoteRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/a1904a90-8130-4e50-8412-d03751af7d7c/f41ccce4-f6ad-442a-91aa-30db4308de5b",
      element: <ProfileRootComponent />,
      errorElement: <ErrorPage />,
    },
    // Quantifier par ratio
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb12/d5bda177-5473-49d4-a665-ce8045e6471d",
      element: <QuantifierParRatioRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb13/d5bda177-5473-49d4-a664-ce8045e6471d/EXPORT",
      element: <ReengineeringRootComponent moduleName="EXPORT" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb13/d5bda177-5473-49d4-a664-ce8045e6471d/IMPORT",
      element: <ReengineeringRootComponent moduleName="IMPORT" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/d5bda177-5473-49d4-a664-ce8045e6471d/EXPORT",
      element: <ReengineeringRootComponent moduleName="EXPORT" pluginName="Gyproc" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/d5bda177-5473-49d4-a664-ce8045e6471d/IMPORT",
      element: <ReengineeringRootComponent moduleName="IMPORT" pluginName="Gyproc" />,
      errorElement: <ErrorPage />,
    },
    //Generic test plugin
    {
      path: "/plugin/992e2487-e768-4b71-b9d7-24bb7378531f/8f98532c-d123-4b58-a487-af2146ae382e",
      element: <TestRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/531991b6-a570-46ac-be5d-8865058dcb13/d5bda177-5473-49d4-a664-ce8045e6471d/ReEngineer",
      element: <ReengineeringRootComponent moduleName="ReEngineer" />,
      errorElement: <ErrorPage />,
    },
    // Gyproc
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/32579ddf-b039-4dae-afa2-0f71dc48c4a2",
      element: <GyprocSelectionRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/c2555bc8-b5dc-426d-ab22-603448ba023f",
      element: <GyprocCalepinageRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/bf6f37cc-f0c2-11ea-adc1-0242ac120004",
      element: <GyprocDeleteRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/5377529d-ceae-4d27-9940-e55fa8158492",
      element: <GyprocMetresRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/547f5d6a-8296-4d01-887b-39fa41e422c0",
      element: <GyprocReperageComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/c34011d4-5ba0-43a3-9b8b-912f60c4f88b",
      element: <GyprocProjectInfoRootComponent />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/99f4551c-183c-4745-97b4-7d05eea2835b/cfd6750c-0aeb-46b4-b526-7fa0995c5b33",
      element: <GyprocDimensionsRootComponent />,
      errorElement: <ErrorPage />,
    },
     // PlacoBIM V3-Test
     {
      path: "/plugin/931991b6-a570-46ac-be5d-8865058dcb12/9c7b1ed8-69db-45fb-8550-e441f3306a4d",
      element: <CalepinageRootComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/931991b6-a570-46ac-be5d-8865058dcb12/fef4020e-8899-4d51-9e4e-ac8cee5b797c",
      element: <RootComponentTest />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/plugin/931991b6-a570-46ac-be5d-8865058dcb12/0ad35a84-d2f2-11ea-87d0-0242ac130004",
      element: <ProjectInfoRootComponentTest />,
      errorElement: <ErrorPage />,
    },
  ];
  const rte = createBrowserRouter(routerArray);
  return <RouterProvider router={rte} />;
};
