import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { Language } from "../../Selection/Actions/types";
import { SelectionStore } from "../../Selection/Reducers";

export const DisplayMessage = (props: any) => {
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  const applicationLanguage: Language = reduxState.language;

  return (
    <Modal size="mini" open={props.open}>
      <Modal.Description
        style={{
          textAlign: "center",
          padding: "2rem 1.6rem 1.6rem 1.6rem",
          color: "red",
        }}
      >
        <p>{props.message}</p>
        <Button primary onClick={props.onClose} size="tiny">
          {wording.cancel[applicationLanguage]}
        </Button>
      </Modal.Description>
    </Modal>
  );
};

const wording: WordingType = {
  cancel: {
    French: "Annuler",
    English: "Close",
  },
};

type WordingType = {
  cancel: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};
