import { api } from "../RevitJS/API";
import { wait } from "../RevitJS/Helpers";

export const onProjectData = async () => {
  try {
    let projectData = await api.queries.getSetProjectData();
    if (projectData.ProjectId !== "") {
      return projectData.ProjectId;
    }
    return null;
  } catch (ex) {
    return null;
  }
};

export const saveSelection = async (selectionObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.saveSelection(JSON.stringify(selectionObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return result.data;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.saveSelection(
      JSON.stringify(selectionObject)
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return false;
    }
  }
};

export const listSelection = async () => {
  const projectId = await onProjectData();
  if (projectId) {
    const revitVersion = await api.framework.getRevitVersion();
    if (revitVersion < 2023) {
      localStorage.setItem("revitProcess", "true");
      localStorage.setItem("revitResult", "");
      let processing = JSON.parse(
        localStorage.getItem("revitProcess") as string
      );
      window.revit.listSelection(projectId);
      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("revitProcess") as string);
      }
      let result = JSON.parse(localStorage.getItem("revitResult") as string);
      if (result.status === 200) {
        const sortByDateModules = result.data.sort(function (a: any, b: any) {
          return b.Date - a.Date;
        });
        // sortByDateModules.reverse();
        return sortByDateModules;
      } else {
        return [];
      }
    } else {
      const result = await window.revitasync.listSelection(projectId);
      if (result.status === 200) {
        const sortByDateModules = result.data.sort(function (a: any, b: any) {
          return b.Date - a.Date;
        });
        // sortByDateModules.reverse();
        return sortByDateModules;
      } else {
        return [];
      }
    }
  } else {
    return null;
  }
};

export const deleteSelection = async (selectionId: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.deleteSelection(selectionId);
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  } else {
    const result = await window.revitasync.deleteSelection(selectionId);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  }
};

export const getSelection = async (selectionId: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.getSelection(selectionId);
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  } else {
    const result = await window.revitasync.getSelection(selectionId);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  }
};

export const updateSelection = async (
  selectionId: any,
  selectionObject: any
) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.updateSelection(selectionId, JSON.stringify(selectionObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.updateSelection(
      selectionId,
      JSON.stringify(selectionObject)
    );
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }
};

export const saveCalepinage = async (calepinageObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.saveCalepiange(JSON.stringify(calepinageObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    return result.data;
  } else {
    const result = await window.revitasync.saveCalepiange(
      JSON.stringify(calepinageObject)
    );
    return result.data;
  }
};

export const listCalepinage = async () => {
  const projectId = await onProjectData();
  if (projectId) {
    const revitVersion = await api.framework.getRevitVersion();
    if (revitVersion < 2023) {
      localStorage.setItem("revitProcess", "true");
      localStorage.setItem("revitResult", "");
      let processing = JSON.parse(
        localStorage.getItem("revitProcess") as string
      );
      window.revit.listCalepinage(projectId);
      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("revitProcess") as string);
      }
      let result = JSON.parse(localStorage.getItem("revitResult") as string);
      if (result.status === 200) {
        const sortByDateModules = result.data.sort(function (a: any, b: any) {
          return b.Date - a.Date;
        });
        //sortByDateModules.reverse();
        return sortByDateModules;
      } else {
        return [];
      }
    } else {
      const result = await window.revitasync.listCalepinage(projectId);
      if (result.status === 200) {
        const sortByDateModules = result.data.sort(function (a: any, b: any) {
          return b.Date - a.Date;
        });
        //sortByDateModules.reverse();
        return sortByDateModules;
      } else {
        return [];
      }
    }
  } else {
    return null;
  }
};

export const deleteCalepinage = async (calepinageId: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.deleteCalepinage(calepinageId);
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  } else {
    const result = await window.revitasync.deleteCalepinage(calepinageId);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  }
};

export const getCalepinage = async (calepinageId: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.getCalepinage(calepinageId);
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  } else {
    const result = await window.revitasync.getCalepinage(calepinageId);
    if (result.status === 200) {
      return result.data;
    } else {
      return [];
    }
  }
};

export const updateCalepinage = async (
  calepinageId: any,
  calepinageObject: any
) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.updateCalepinage(
      calepinageId,
      JSON.stringify(calepinageObject)
    );
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.updateCalepinage(
      calepinageId,
      JSON.stringify(calepinageObject)
    );
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }
};

export const saveSystemSelection = async (
  solutions: any,
  brandName: string
) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.saveSystemSolutions(
      JSON.stringify(solutions),
      brandName,
      (Date.now() / 1000).toString()
    );
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.saveSystemSolutions(
      JSON.stringify(solutions),
      brandName,
      (Date.now() / 1000).toString()
    );
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }
};

export const getTimeStamp = async (key: string) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.getTimeStamp(key);
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.getTimeStamp(key);
    if (result.status === 200) {
      return result.data;
    } else {
      return "";
    }
  }
};

export const getInfoForm = async () => {
  const revitVersion = await api.framework.getRevitVersion();
  let resultToreturn = {
    information_enterprise_name: "",
    information_enterprise_logo: "",
    information_enterprise_address: "",
    information_enterprise_postal: "",
    information_enterprise_villa: "",
    information_enterprise_pays: "France",
    information_enterprise_telephone: "",
    information_enterprise_email: "",
    information_chantier_add_date: "",
    information_chantier_address: "",
    information_chantier_pays: "France",
    information_chantier_postal: "",
    information_chantier_reference: "",
    information_chantier_referencecctp: "",
    information_chantier_villa: "",
  };

  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.getInfoForm();
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      if (result.data) {
        resultToreturn = JSON.parse(result.data);
      }
    }
  } else {
    const result = await window.revitasync.getInfoForm();
    if (result.status === 200) {
      if (result.data) {
        resultToreturn = JSON.parse(result.data);
      }
    }
  }

  return resultToreturn;
};

export const saveInfoForm = async (infoObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.saveInfoForm(JSON.stringify(infoObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    return result.data;
  } else {
    const result = await window.revitasync.saveInfoForm(
      JSON.stringify(infoObject)
    );
    return result.data;
  }
};

export const updateInfoForm = async (infoFormObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.updateInfoForm(JSON.stringify(infoFormObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.updateInfoForm(
      JSON.stringify(infoFormObject)
    );
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }
};

export const getAddInfoForm = async () => {
  const revitVersion = await api.framework.getRevitVersion();
  let resultToreturn = {
    enterprise_generale_address: "",
    enterprise_generale_email: "",
    enterprise_generale_name: "",
    enterprise_generale_telephone: "",
    lot_1: "",
    lot_2: "",
    lot_3: "",
    maitre_doeuvre_address: "",
    maitre_doeuvre_email: "",
    maitre_doeuvre_name: "",
    maitre_doeuvre_telephone: "",
    maitre_douvrage_address: "",
    maitre_douvrage_email: "",
    maitre_douvrage_name: "",
    maitre_douvrage_telephone: "",
  };

  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.getAddInfoForm();
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      if (result.data) {
        resultToreturn = JSON.parse(result.data);
      }
    }
  } else {
    const result = await window.revitasync.getAddInfoForm();
    if (result.status === 200) {
      if (result.data) {
        resultToreturn = JSON.parse(result.data);
      }
    }
  }

  return resultToreturn;
};

export const saveAddInfoForm = async (infoObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.saveAddInfoForm(JSON.stringify(infoObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    return result.data;
  } else {
    const result = await window.revitasync.saveAddInfoForm(
      JSON.stringify(infoObject)
    );
    return result.data;
  }
};

export const updateAddInfoForm = async (infoFormObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.updateAddInfoForm(JSON.stringify(infoFormObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    const result = await window.revitasync.updateAddInfoForm(
      JSON.stringify(infoFormObject)
    );
    if (result.status === 200) {
      return true;
    } else {
      return false;
    }
  }
};

export const getReperage = async () => {
  const revitVersion = await api.framework.getRevitVersion();

  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.getReperage();
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    if (result.status === 200) {
      if (result.data) {
        return JSON.parse(result.data);
      }
    }
  } else {
    await wait(5000);
    const result = await window.revitasync.getReperage();

    if (result.status === 200) {
      if (result.data) {
        return JSON.parse(result.data);
      }
    }
  }
  return null;
};

export const saveReperage = async (infoObject: any) => {
  const revitVersion = await api.framework.getRevitVersion();
  if (revitVersion < 2023) {
    localStorage.setItem("revitProcess", "true");
    localStorage.setItem("revitResult", "");
    let processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    window.revit.saveReperage(JSON.stringify(infoObject));
    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("revitProcess") as string);
    }
    let result = JSON.parse(localStorage.getItem("revitResult") as string);
    return result.data;
  } else {
    const result = await window.revitasync.saveReperage(
      JSON.stringify(infoObject)
    );

    return result.data;
  }
};
