import React, { ReactElement, useEffect, useState } from "react";
import { PluginLauncherList } from "./PluginLauncherList";
import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { Action } from "redux";
import { ID, Language } from "../../RevitJS/Types/RevitTypes";
import { Button, Icon } from "semantic-ui-react";
import { api } from "../../RevitJS/API";
import { MyConfig } from "../../Helper";
import { getNewJwtToken } from "../../API/authenticate.api";
import { useNavigate } from "react-router";

interface Props {
  plugins: Plugin[];
  selectPlugin: (id: ID) => Action;
  version: string | null;
  revitVersion: number;
  translation: number;
  language: Language;
  environment: string;
  config: any;
}

export default function LoadedStore({
  plugins,
  selectPlugin,
  revitVersion,
  environment,
  language,
  config,
}: Props): ReactElement {
  sessionStorage.setItem("isActiveSeesion", "true");
  const [pluginsList, showPluginsList] = useState(false);
  const [inProgress, setInprogress] = useState(false);

  const navigate = useNavigate();
  async function performAction() {
    getNewJwtToken(config).then(() => navigate("/home"));
  }

  const getLanguageCode = (lang: string) => {
    switch (lang) {
      case "German":
        return "de";
      case "French":
        return "fr";
      case "Italian":
        return "it";
      default:
        return "en";
    }
  };

  const getSoftware = () => {
    if (window.revit.isArchicad) return "Archicad";
    else return "Revit";
  };
  return (
    <div className="container">
      <div
        style={{
          maxHeight: "calc(100vh - 150px)",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <iframe
          title="Plugins List"
          width="100%"
          style={{
            height: "calc(100vh - 110px)",
            display: pluginsList ? "block" : "none",
          }}
          src={`${
            config.REACT_APP_AUTHENTICATIONURL
          }/pluginsList?revitVersion=${revitVersion}&software=${getSoftware()}&lang=${getLanguageCode(
            language
          )}&environment=${config.REACT_APP_ENV}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
        <div style={{ display: pluginsList ? "none" : "block" }}>
          <PluginLauncherList
            language={language}
            selectPlugin={selectPlugin}
            plugins={plugins}
            config={config}
          />
          {plugins && plugins.length === 0 ? (
            <div style={{ padding: "2rem 0", textAlign: "center" }}>
              <i className="disabled huge inbox icon"></i>
              <p>No Plugin</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "2%",
          width: "100%",
          textAlign: "center",
        }}
      >
        {pluginsList ? (
          <Button
            icon="home"
            color="blue"
            style={{ borderRadius: 50 }}
            disabled={inProgress}
            loading={inProgress}
            onClick={() => {
              setInprogress(true);
              performAction();
            }}
          />
        ) : (
          <Button
            color="blue"
            content={"+ Plugins"}
            style={{ borderRadius: 50 }}
            size="small"
            // onClick={() => updateAccount()}
            onClick={() => showPluginsList(true)}
          />
        )}
      </div>
    </div>
  );
}
