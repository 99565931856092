import React, { useEffect } from "react";
import { TopHeader } from "../../Components/Headers";
import { Button, Grid } from "semantic-ui-react";
import { HomeTable } from "../Components/Table/Home/HomeTable";
import { NamePopup, SelectionMethodPopup } from "../../Components/Popup";
import { useState } from "react";
import { map, forEach } from "lodash";
import { bimStorage } from "../../../../BIMStore";
import { ESelectionMethod } from "../../Types";
import {
  onDuplicateSelection,
  onManualSelection,
  colorChangeUtil,
  selectionNameChangeUtil,
  colorSelection,
} from "../utils/selection";
import { api } from "../../../../RevitJS/API";
import SaveProject from "../../CommonModules/SaveProject";

const style = {
  tableSegment: {
    overflow: "auto",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0em",
  },
  height_435: {
    height: "435px",
  },
  height_486: {
    height: "calc(100vh - 50px)",
  },
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200)",
  },
  header_row: {
    padding: 5,
    height: 30,
    backgroundColor: "rgb(33, 133, 208)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    // fontWeight: 700,
    display: "flex",
    width: "40%",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    // fontWeight: 700,
    display: "flex",
    width: "6%",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    // fontWeight: "700",
    display: "flex",
    width: "24%",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    // fontWeight: "700",
    width: "17%",
    color: "black",
  },
  tableSegment_body: {
    border: "0.5px solid rgb(200, 200, 200)",
    height: "calc(100vh - 195px)",
    overflowY: "scroll" as const,
  },
};

interface IProps {
  onNewSelectionName: (name: string) => any;
  onSelectionEdit: (selectionId: any) => void;
  setRoute: any;
  onGroupValidate: any;
}

export const Home = (props: IProps) => {
  const { onNewSelectionName, onSelectionEdit, onGroupValidate, setRoute } =
    props;
  const [selectionMethodShowHide, setSelectionMethodShowHide] =
    useState<boolean>(false);
  const [method, setMethod] = useState<ESelectionMethod>(
    ESelectionMethod.MANUAL
  );
  const [namePopupShowHide, setNamePopupShowHide] = useState<boolean>(false);
  const [savePopupShowHide, setSavePopupShowHide] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [selectionList, setSelectionList] = useState<any[]>([]);
  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editedNameValue, setEditedNameValue] = useState("");

  const date = new Date();

  const onNamePopupClose = () => {
    setNamePopupShowHide(false);
  };

  const onSelectionMethodChanges = async (method: ESelectionMethod) => {
    setMethod(method);
    setSelectionMethodShowHide(false);
    if (method === ESelectionMethod.GROUP) {
      setRoute("GROUP_SELECTION");
    } else {
      const { elements, floors } = await onManualSelection("wall");
      if(elements.length>0){
        onGroupValidate(elements, floors);
      }
      else{
        setRoute("ROOT");
      }
    }
  };

  const listModules = async () => {
    const modules = await bimStorage.listSelection();
    if (modules) {
      setSelectionList(modules);
    } else {
      setSavePopupShowHide(true);
    }
  };

  const onRowDelete = async (selectionId: any) => {
    const result = await bimStorage.deleteSelection(selectionId);
    if (result) {
      listModules();
    }
  };

  const eyeHandler = async (
    Id: any,
    name: string,
    color: {
      R: string;
      G: string;
      B: string;
      A: string;
    }
  ) => {
    await colorSelection(Id, name, color);
  };

  const editSelectionName = (Id: string, Name: string) => {
    setErrorMessage("");
    setEditId(Id);
    setEditName(Name);
  };

  const updateSelectionname = async (id: any, name: string) => {
    let errorMessage = "";
    if (name === "") {
      errorMessage = "Nom de sélection invalide";
    } else if (name.trim().length < 5) {
      errorMessage = "Texte de 5 caractères minimum";
    } else if (name.trim().includes('"')){
      errorMessage = 'Can not use the character (") in the name';
    }
    forEach(selectionList, (value) => {
      if (
        value.Name.toLowerCase() === name.trim().toLowerCase() &&
        id !== value.Id //&&
        // value.RevitView === currentRevit
      ) {
        errorMessage = "Une autre sélection existe déjà sous ce nom";
      }
    });
    if (errorMessage === "") {
      const response = await selectionNameChangeUtil(id, name);
      if (response) {
        editSelectionName("", "");
        listModules();
      }
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const onSelectionDuplicate = async (id: any) => {
    const response = await onDuplicateSelection(id, selectionList);
    if (response) {
      listModules();
    }
  };

  const onSelectionName = async (name: string) => {
    const response = await onNewSelectionName(name);
    if (response) {
      setSelectionMethodShowHide(true);
    }
  };

  const onColorChanged = async (
    id: any,
    color: {
      r: string;
      g: string;
      b: string;
      a: string;
    }
  ) => {
    const response = await colorChangeUtil(id, color);
    if (response) {
      listModules();
    }
  };
  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };
  useEffect(() => {
    const getProjectId = async () => {
      const projectId = await bimStorage.onProjectData();
      if(projectId){
        listModules();
      }
      else{
        toggleModal();
      }
    }
    getProjectId();
  
  }, []);

  return (
    <div>
      <div style={{ height: "100%" }}>
        <TopHeader Icon={""} name={"Selection"} />
        <Grid
          columns={1}
          stackable
          style={{
            ...style.tableSegment,
            ...style.height_486,
            marginTop: "0em",
          }}
        >
          <Grid.Column>
            <HomeTable
              data={selectionList}
              onColorChanged={onColorChanged}
              onRowEdit={onSelectionEdit}
              onRowDuplicate={onSelectionDuplicate}
              onRowDelete={onRowDelete}
              editId={editId}
              editName={editName}
              setEditName={setEditName}
              editSelectionName={editSelectionName}
              eyeHandler={eyeHandler}
              updateSelectionname={updateSelectionname}
              errorMessage={errorMessage}
              setEditedNameValue={setEditedNameValue}
            />
            <div style={{ marginTop: 12 }}>
              <div>
                <Button
                  primary
                  onClick={() => {
                    setNamePopupShowHide(true);
                    // setSelectionMethodShowHide(true);
                  }}
                >
                  {"New Selection"}
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <SelectionMethodPopup
          show={selectionMethodShowHide}
          onClose={() => setSelectionMethodShowHide(false)}
          onSave={onSelectionMethodChanges}
        />
        <NamePopup
          show={namePopupShowHide}
          onSave={(name: any) => onSelectionName(name)}
          onClose={onNamePopupClose}
          rules={{
            length: true,
            lengthValue: 5,
            duplicate: true,
            duplicateList: map(selectionList, "Name"),
            shallNotContain: ['"']
          }}
          headerName={"Selection name"}
        />
        <SaveProject
          toggleModal={toggleModal}
          confirmModal={confirmModal}
          onYesAction={onYesAction}
        />
      </div>
    </div>
  );
};
