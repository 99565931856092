import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import { setCurrentProjectName } from "../../actions/projectsActions";
import { AppWindowName, NEW_PROJECT } from "../../assets/constants";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./projectName.scss";

class ProjectName extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.setProjectName = this.setProjectName.bind(this);
  }

  state = {
    nameCorrect: false,
    nameNotAlreadyExisting: true,
  };

  componentDidMount() {
    let { currentProject } = this.props;
    this.checkCorrectProjectName(currentProject.name);
  }

  backPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  nextPage() {
    let { selectWindowToShow } = this.props;
    selectWindowToShow(AppWindowName.PROJECT_COUNTRY_WINDOW);
  }

  setProjectName(projectName: string) {
    let { setCurrentProjectName } = this.props;

    this.checkCorrectProjectName(projectName);
    setCurrentProjectName(projectName);
  }

  checkCorrectProjectName(projectName?: string) {
    let { projectsList } = this.props;
    let nameNotAlreadyExisting: boolean = true;

    projectsList.forEach((project) => {
      if (projectName === project.name) {
        nameNotAlreadyExisting = false;
      }
    });
    this.setState({
      nameCorrect: projectName !== undefined && projectName.length > 2,
      nameNotAlreadyExisting,
    });
  }

  render() {
    let { currentProject, t } = this.props;
    return (
      <div id="project-name-container">
        <Header displayName={t(NEW_PROJECT) || ""}></Header>
        <Body
          projectNameChanged={this.setProjectName}
          projectName={currentProject.name}
          nameNotAlreadyExisting={this.state.nameNotAlreadyExisting}
        ></Body>
        <span></span>
        <Footer
          savedProjectsButtonDisabled={true}
          disabledNextButton={
            !(this.state.nameCorrect && this.state.nameNotAlreadyExisting)
          }
          clickedNextButton={this.nextPage}
          showBackButton={true}
          clickedBackButton={this.backPage}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    currentProject: state.projects.currentProject,
    projectsList: state.projects.projectsList,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  setCurrentProjectName,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {};

export default connector(withTranslation(["pam"])(ProjectName));
