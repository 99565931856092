import { setHeaderStyle, informationTable, seTableStyle } from "./tableHelaper";
import { api } from "../../../RevitJS/API";
import {
  map,
  flatten,
  compact,
  chain,
  find,
  forEach,
  times,
  constant,
} from "lodash";

export const insulationQuantities = async (insulationSheet: any) => {
  let headersMainBoard = [
    { ref: "A1", name: "Floor" },
    { ref: "B1", merge: "B1:D1", name: "Rigips system" },
    { ref: "E1", merge: "E1:G1", name: "quantity profiles" },
  ];
  setHeaderStyle(insulationSheet, headersMainBoard);

  let insulationProfileDetails = await getInsulations();

  let mainTableDetailColumns = [
    { name: "Nr", filterButton: true },
    { name: "Nr", filterButton: true },
    { name: "Nr", filterButton: true },
    { name: "Nr", filterButton: true },
    { name: "XX", filterButton: true },
    { name: "XX", filterButton: true },
    { name: "m2", filterButton: true },
  ];

  forEach(
    times(insulationProfileDetails.insulationData.length + 1, Number),
    (value, index) => {
      const rowNumber = index + 2;
      insulationSheet.mergeCells(`B${rowNumber}:D${rowNumber}`);
      insulationSheet.mergeCells(`E${rowNumber}:F${rowNumber}`);
    }
  );

  insulationSheet.addTable({
    name: "Insulation",
    ref: "A2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
      showRowStripes: true,
    },
    columns: mainTableDetailColumns,
    rows: insulationProfileDetails.insulationData,
  });
  let totalTableBoard = [
    { ref: "H1", merge: "H1:J1", name: "Total quantity insolation per type" },
  ];
  setHeaderStyle(insulationSheet, totalTableBoard, "548232");

  let totalTableeDetailColumns = [
    { name: "Name", filterButton: true },
    { name: "XX", filterButton: true },
    { name: "m2", filterButton: true },
  ];
  insulationSheet.addTable({
    name: "Insulation",
    ref: "H2",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
      showRowStripes: true,
    },
    columns: totalTableeDetailColumns,
    rows: insulationProfileDetails.insulationDataTotal,
  });
  informationTable(
    insulationSheet,
    insulationProfileDetails.insulationData.length + 3
  );
  seTableStyle(
    insulationSheet,
    insulationProfileDetails.insulationDataTotal.length + 3
  );
  applyColumnSize(insulationSheet);
};

const applyColumnSize = (insulationSheet: any) => {
  insulationSheet.getColumn("A").width = 12;
  insulationSheet.getColumn("B").width = 12;
  insulationSheet.getColumn("C").width = 11;
  insulationSheet.getColumn("D").width = 11;
  insulationSheet.getColumn("E").width = 11;
  insulationSheet.getColumn("F").width = 11;
  insulationSheet.getColumn("G").width = 11;
  insulationSheet.getColumn("H").width = 11;
  insulationSheet.getColumn("I").width = 11;
  insulationSheet.getColumn("J").width = 11;
};

const getInsulations = async () => {
  const insulationData: any = [];

  const walltree = await api.selection.elementsByLevelAndType("wall");
  console.log("walltree: ", walltree);

  const elements = walltree.Tree[0].Elements;

  const insulationIds = flatten(
    compact(
      map(elements, (n) => {
        // if (n.Type === "MW_60") {
        return n.Ids;
        // }
      })
    )
  );

  let albaParameters = await api.queries.getObjectsParams(insulationIds, [
    "AlbaId",
  ]);

  let relatedIds = compact(
    map(albaParameters, (albaP, index) => {
      if (albaP.Params[0].Value) {
        return { WallId: albaP.Params[0].Value, AlbaId: albaP.Id };
      }
    })
  );

  // let relatedAlbaIds = compact(map(albaParameters, (albaP, index) => {
  //   if(albaP.Params[0].Value)
  //   {
  //     return albaP.Id;
  //   }
  // }));

  const wallsDetailsData = await api.queries.getObjectsParams(
    map(relatedIds, "WallId"),
    ["SG_System"]
  );

  let insulationDetailsData = await api.queries.getWallsData(
    map(relatedIds, "AlbaId")
  );

  let insulationDetails = relatedIds.map((insulation) => {
    const wallObject: any = find(wallsDetailsData, { Id: insulation.WallId });
    const albaObject: any = find(insulationDetailsData, {
      Id: insulation.AlbaId,
    });

    return {
      Id: insulation.AlbaId,
      WallId: insulation.WallId,
      SystemName: wallObject.Params[0].Value,
      Name: albaObject.WallType,
      Area: parseFloat(albaObject.Area.toFixed(2)),
      LevelName: albaObject.LevelName,
    };
  });

  let insulationGroupByLevelName = chain(insulationDetails)
    .groupBy("LevelName")
    .map((value, key) => ({ LevelName: key, insulationDetails: value }))
    .value();

  insulationGroupByLevelName.forEach((p) => {
    insulationData.push([p.LevelName, " ", " ", " ", " ", " ", " "]);

    let insulationBySystemName = chain(p.insulationDetails)
      .groupBy("SystemName")
      .map((value: any, key: any) => ({
        SystemName: key,
        insulationDetails: value,
      }))
      .value();

    insulationBySystemName.forEach((p: any) => {
      insulationData.push([
        " ",
        p.SystemName,
        p.SystemName,
        p.SystemName,
        " ",
        " ",
        " ",
      ]);

      insulationData.push([
        " ",
        " ",
        " ",
        " ",
        p.insulationDetails.length,
        p.insulationDetails.length,
        parseFloat(
          p.insulationDetails.reduce(
            (acc: any, insulation: any) => acc + insulation.Area,
            0
          )
        ),
      ]);
    });
  });

  forEach(times(3, constant(0)), function (value) {
    insulationData.push([" ", " ", " ", " ", " ", " ", " "]);
  });

  let insulationDataTotal: any = [];
  let insulationTotal = chain(insulationDetails)
    .groupBy("Name")
    .map((value, key) => ({ Name: key, insulationDetails: value }))
    .value();

  insulationTotal.forEach((p: any) => {
    insulationDataTotal.push([
      p.Name,
      p.insulationDetails.length,
      parseFloat(
        p.insulationDetails.reduce(
          (acc: any, board: any) => acc + board.Area,
          0
        )
      ),
    ]);
  });

  const totalBlankRowAdded = insulationData.length - insulationDataTotal.length;

  forEach(times(totalBlankRowAdded, constant(0)), function (value) {
    insulationDataTotal.push([" ", " ", " "]);
  });

  console.log("InsulationData", insulationData);
  console.log("InsulationDataTotal", insulationDataTotal);

  return { insulationData, insulationDataTotal };
};
