export enum Routes {
  ROOT = "ROOT",
  CUSTOMERATIO = "CUSTOMERATIO",
  ELEMENTSELECTION = "ELEMENTSELECTION",
}

export enum ElementType {
  WALL = "WALL",
  CEILING = "CEILING",
}

export enum QuantiType {
  FLOOR = "FLOOR",
  ZONE = "ZONE",
}
