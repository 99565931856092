import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  Table,
  Button,
  Loader,
  Dimmer,
  Segment,
  Header
} from "semantic-ui-react";
// import { hideSystemDetails } from '../Actions';

const language = "French";

const row = (key, value) => {
  if (key) {
    return (
      <Table.Row key={key}>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
      </Table.Row>
    );
  }
  return null;
};

export class SystemDetails extends React.Component {
  componentDidMount = () => {
  };

  render() {
    const details = () => {
      if (this.props.selectionBuffer !== null) {
        
        let rows = this.props.filters.map(filter =>
          row(filter.name, this.props.selectionBuffer.filterFields[filter.name])
        );
        return (
          <Table celled>
            <Table.Body>
              {[row(this.props.wording.description[this.props.language], this.props.selectionBuffer.descriptionTranslation), ...rows]}
            </Table.Body>
          </Table>
        );
      }
      return (
        <Segment basic>
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        </Segment>
      );
    };
    if(this.props.selectionBuffer === null) return null;
    return (
      <Dimmer active={this.props.systemDetailsDisplayed}>
        <Segment style={{ width: 850, padding: 20, height:450, overflow: "auto" }}>
          <Header>{this.props.selectionBuffer.translation}</Header>
          {details()}

          <Button
          floated="right"
              color="green"
              onClick={e => {
                this.props.hideSystemDetails();
              }}
            > OK </Button>
        </Segment>
      </Dimmer>
      //   <Modal open={this.props.systemDetailsDisplayed}>
      //     <Modal.Header>{this.props.productDetailName}</Modal.Header>
      //     <Modal.Content>
      //       <Modal.Description>{details()}</Modal.Description>
      //     </Modal.Content>
      //     <Modal.Actions>
      //       <Button
      //         color="green"
      //         onClick={e => {
      //           this.props.hideSystemDetails();
      //         }}
      //       >
      //         OK
      //       </Button>
      //     </Modal.Actions>
      //   </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    systemDetailsDisplayed: state.systemDetailsDisplayed,
    systemDetails: state.systemDetails,
    partitionsDetails: state.partitionsDetails,
    detailOid: state.detailOid,
    filters: state.filters,
    layoutAttributesAid: state.layoutAttributesAid,
    productDetailName: state.productDetailName
  };
};

SystemDetails.defaultProps = {
  productName: "Cloison Placostil® 120/70 Lisaflam® - EI 120 - 50dB - 4,95m",
  wording:{
    description:{
      French: "Description",
      English: "Description"
    }
  }
};

// export default connect(mapStateToProps, {
//   hideSystemDetails
// })(SystemDetails);
