import { Draw2 } from './Draw2';
import { Delete } from './Delete';
import { WallsSchedules } from './WallSchedules';
import { QuantityTakeOff } from './QuantityTakeOff';
import { Colorization } from './Colorization';
import { Dimensions } from './Dimensions';
import { ShowSolutions } from './ShowSolutions';
import { ShowWalls } from './ShowWalls';
import { Overlap } from './Overlap';

export const pluginId = "e990ace7-9a6a-40e3-a8fa-a151773c7713";


export const rigipsBim = {
    TechnicalName:"RIGIPSBIM",
    Name: "",
    Icon: "/plugin_new_logo/Rigips.png",
    ShortDesc: {
        "French": "Solution BIM par Rigips®",
        "English": "Rigips® tools for BIM",
        "German": "BIM Rigips® Lösung",
        "Italian": "Rigips® tools for BIM"
    },
    Id: pluginId,
    Color: "#005EB8",
    Functionalities: [
        // Technical,
        // Spotting,
        // Draw,
        WallsSchedules,
        Draw2,
        Delete,
        // Delete,
        Colorization,
        QuantityTakeOff,
        // Kitting,
        Dimensions,
        ShowSolutions,
        ShowWalls,
        Overlap,
        // ReverseSides,
        // ReverseDirection,
        // Help
    ]
}