import { bimStorage, storageKey } from "../../BIMStore";
import { wait } from "../Helpers";
import { generalizeLanguage } from "../../Helper";
import { Language } from "../Types/RevitTypes";
import { getValidJwtToken } from "../../API/authenticate.api";

export interface License {}

const getLanguageCode = (lang: string | null) => {
  switch (lang) {
    case "German":
      return "de";
    case "French":
      return "fr";
    case "Italian":
      return "it";
    default:
      return "en";
  }
};

export class License {
  async GetMachineDetails() {
    if (!window.revit) {
      await wait(200);
      return null;
    } else {
      localStorage.setItem("getMachineDetails", "true");
      localStorage.setItem("getMachineDetailsResult", "null");
      let processing = JSON.parse(
        localStorage.getItem("getMachineDetails") as string
      );
      window.revit.getMachineDetails();

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("getMachineDetails") as string
        );
      }

      let result = localStorage.getItem("getMachineDetailsResult") as string;

      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      return JSON.parse(result.replace(/\r?\n|\r/g, ""));
    }
  }

  async isActive(appTechnicalName: string, config: any): Promise<boolean> {
    let savedLanguage: any = localStorage.getItem("savedLanguage");
    let lang = getLanguageCode(savedLanguage);
    const applicationLanguage: Language = generalizeLanguage(savedLanguage);
    await getValidJwtToken(config);
    let machine = await this.GetMachineDetails();
    let url =
      (config.REACT_APP_AUTHENTICATION_API as string) +
      "/license/validatefingerprint";
    let licenseDetails = {
      applicationName: appTechnicalName,
      computerName: machine.Name,
      fingerPrint: machine.FingerPrint,
    };

    const tokenString = await getValidJwtToken(config);

    let data = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": lang,
        Authorization: "Bearer " + tokenString,
      },
      body: JSON.stringify(licenseDetails),
    })
      .then((response) => {
        if (response.ok) {
          return true;
        }
        return response.json();
      })
      .then((response) => {
        if (response.message) {
          bimStorage.setItem(
            storageKey.LICENSE_ERROR_MESSAGE,
            JSON.stringify(response.message)
          );
          return false;
        }
        return true;
      })
      .catch((errors) => {
        bimStorage.setItem(
          storageKey.LICENSE_ERROR_MESSAGE,
          JSON.stringify(wording.wrongMsg[applicationLanguage])
        );
        return false;
      });

    return data;
  }

  async RegisterFingerprint(token: string, cng: any) {
    localStorage.setItem("registerFingerprint", "true");
    localStorage.setItem("registerFingerprintResult", "null");

    let processing = true;
    window.revit.registerFingerprint(token, cng.REACT_APP_ENV as string);

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("registerFingerprint") as string
      );
    }

    return JSON.parse(
      localStorage.getItem("registerFingerprintResult") as string
    );
  }
}

const wording: WordingType = {
  wrongMsg: {
    French: "Un problème est survenu",
    English: "Something went wrong",
    German: "Something went wrong",
    Italian: "Something went wrong",
    Indonesian: "Something went wrong",
  },
};

type WordingType = {
  wrongMsg: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
  German: string;
  Italian: string;
  Indonesian: string;
};
