import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { api } from "../../../../RevitJS/API";
import { Language } from "../../Selection/Actions/types";
import { SelectionStore } from "../../Selection/Reducers";
import updateSelectionIfWallDeleted from "../Helpers/selectionUpdateHelper";

interface Props {
  toggleModal: any;
  confirmModal: boolean;
  onYesAction: any;
  dontUpdateSelections?: any;
}

export const SaveProject = (props: Props) => {
  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  const applicationLanguage: Language = reduxState.language;

  useEffect(() => {
    // create IIFE for async operations
    if (props.dontUpdateSelections === undefined) {
      updateSelectionIfWallDeleted(reduxState.config);
    }
  }, [props.dontUpdateSelections]);

  return (
    <Modal open={props.confirmModal} size="mini" dimmer="blurring">
      <Modal.Header className="modalHeader">
        {" "}
        {wording.header[applicationLanguage]}
      </Modal.Header>
      <Modal.Description style={{ ...style.modalDescription }}>
        {wording.message[applicationLanguage]}
      </Modal.Description>
      <div style={{ margin: "10px 0px", textAlign: "center" }}>
        <Button
          size="tiny"
          type="submit"
          primary
          onClick={() => {
            props.onYesAction();
          }}
        >
          {wording.Yes[applicationLanguage]}
        </Button>
      </div>
    </Modal>
  );
};

const wording: WordingType = {
  header: {
    French: "Sauvegarder",
    English: "Save",
  },
  message: {
    French: "Veuillez enregistrer le projet avant de continuer",
    English: "Please save the project before continuing",
  },
  No: {
    French: "Non",
    English: "No",
  },
  Yes: {
    French: "Accepter",
    English: "Yes",
  },
};

type WordingType = {
  header: LanguageType;
  message: LanguageType;
  No: LanguageType;
  Yes: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const style = {
  modalDescription: {
    textAlign: "center",
    padding: "15px 0px",
  },
};
