import { Checkbox } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import checkMark from "../../../../assets/check-mark.png";
import crossSign from "../../../../assets/cross-sign.png";
import {
    getImageAssetUrlAttributeFromRange,
    getNameAttributeFromRange,
    getShortDescAttributeFromRange,
} from "../../../../helpers/utils";
import Item from "../../../../models/Item.model";
import RangeComponent from "../../../rangeSelection/body/rangeComponent/RangeComponent";
import TdComponent from "./TdComponent";

function CouplingSelectionBody({
    items,
    item,
    itemIndex,
    toggleMatchElementCoupling,
    isElementCompatibleWithCoupling,
    setValidatedItems,
    toggleCouplingSelectedForAllElements,
    setModal,
    t,
}: IProps) {
    return (
        <tbody>
            <tr
                style={{
                    height: ".5px",
                    textAlign: "center",
                }}
            >
                <td
                    className="range-component-td"
                    rowSpan={item.compatibleAndAvailableElements.length + 1}
                >
                    <RangeComponent
                        name={getNameAttributeFromRange(item.range)}
                        desc={getShortDescAttributeFromRange(item.range)}
                        image={getImageAssetUrlAttributeFromRange(item.range)}
                    ></RangeComponent>
                </td>
                <td
                    style={{
                        width: "13%",
                        background: "#f67252",
                        color: "white",
                    }}
                >
                    <div>
                        <strong>DN</strong>
                    </div>
                </td>
                {item.allCompatibleCouplings?.map((coupling, couplingIndex) => {
                    return (
                        <td key={`dn-select-all-${itemIndex}-${couplingIndex}`}>
                            <Checkbox
                                onClick={(e: any) => {
                                    toggleCouplingSelectedForAllElements(
                                        e.target.checked,
                                        item,
                                        itemIndex,
                                        couplingIndex
                                    );
                                }}
                            ></Checkbox>
                        </td>
                    );
                })}
            </tr>
            {item.compatibleAndAvailableElements.map(
                (element, elementIndex) => {
                    return (
                        <tr
                            key={`coupling-selection-inner-table-dn-tr-${itemIndex}-${elementIndex}`}
                        >
                            <td
                                style={{
                                    width: "10%",
                                }}
                                className={`${
                                    element.selectedCoupling !== undefined
                                        ? "elementMatched"
                                        : ""
                                }`}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    <span>
                                        {!element.selectedCoupling && (
                                            <img
                                                style={{
                                                    width: ".8em",
                                                }}
                                                className="mx-1"
                                                src={crossSign}
                                                alt="Not matched"
                                            ></img>
                                        )}
                                        {element.selectedCoupling && (
                                            <img
                                                style={{
                                                    width: ".8em",
                                                }}
                                                className="mx-1"
                                                src={checkMark}
                                                alt="Matched"
                                            ></img>
                                        )}
                                    </span>
                                    <strong className="mx-1">{`DN${element.Diameter}`}</strong>
                                </div>
                            </td>
                            {item.allCompatibleCouplings?.map(
                                (coupling, couplingIndex) => {
                                    return (
                                        <TdComponent
                                            key={`coupling-selection-inner-table-dn-td-component-${itemIndex}-${elementIndex}-${couplingIndex}`}
                                            item={item}
                                            element={element}
                                            coupling={coupling}
                                            itemIndex={itemIndex}
                                            elementIndex={elementIndex}
                                            couplingIndex={couplingIndex}
                                            isElementCompatibleWithCoupling={
                                                isElementCompatibleWithCoupling
                                            }
                                            toggleMatchElementCoupling={
                                                toggleMatchElementCoupling
                                            }
                                            setModal={setModal}
                                        ></TdComponent>
                                    );
                                }
                            )}
                        </tr>
                    );
                }
            )}
        </tbody>
    );
}

type IProps = WithTranslation & {
    items: Item[];
    item: Item;
    itemIndex: number;
    setValidatedItems: Function;
    toggleMatchElementCoupling: Function;
    isElementCompatibleWithCoupling: Function;
    toggleCouplingSelectedForAllElements: Function;
    setModal: Function;
};

export default withTranslation(["pam"])(CouplingSelectionBody);
