import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow, initLanguage } from "../store/app/effects";
import { APP_WINDOW_NAME } from "../assets/constants";
import LoadSystemContainer from "./LoadSystemContainer"
import CartSystemContainer from './SystemCartContainer'
import DetailSystemContainer from './SystemDetailsContainer'
import SystemConfigurationContainer from "./SystemConfigurationContainer";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";


export class AppBody extends Component<IProps, IState> {

  componentDidMount = async () => {
    this.props.initLanguage();
    setTimeout(() => {
      this.props.i18n.changeLanguage(this.props.currentLanguage);
    });

    const cng = await MyConfig();
    this.props.setConfig(cng)
  };

  componentWillUnmount() {
  }

  render() {
    switch (this.props.app.currentWindow) {
      case APP_WINDOW_NAME.LOAD_SYSTEM_WINDOW:
        return <LoadSystemContainer />;
      case APP_WINDOW_NAME.DETAILS_CART_WINDOW:
        return <CartSystemContainer />;
      case APP_WINDOW_NAME.DETAILS_SYSTEM_WINDOW:
        return <DetailSystemContainer />
      case APP_WINDOW_NAME.CONFIGURATION_CART_WINDOW:
        return <SystemConfigurationContainer />
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    app: state.app,
    currentLanguage: state.app.language,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  initLanguage,
  setConfig
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = { allAvailableItems: Element[] };

export default connector(withTranslation(["weberIndonesia"])(AppBody));
