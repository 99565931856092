import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import SuppressionRoot from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "bf6f37cc-f0c2-11ea-adc1-0242ac120002";

export const SuppressionTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    507,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="suppression"
    >
      <SuppressionRoot />
    </ParentRoot>
  );
};

export const SuppressionRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="suppression"
    >
      <SuppressionRoot />
    </ParentRoot>
  );
};

export const Suppression: Functionality = {
  Name: {
    French: "Supprimer",
    English: "Remove",
    German: "Supprimer",
    Italian: "Supprimer",
    Indonesian: "Supprimer",
  },
  Trigger: SuppressionTrigger,
  ShortDesc: {
    French: "Effacer le calepinage",
    English: "Clear the layout",
    German: "Effacer le calepinage",
    Italian: "Effacer le calepinage",
    Indonesian: "Effacer le calepinage",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Supprimer.png",
  RootComponent,
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
