import React, { useState, useEffect } from "react";
import { Segment, Button } from "semantic-ui-react";
import TopHeader from "../../Components/Headers/TopHeader";
import SubHeader from "../../Components/Headers/SubHeader";
import GroupBox from "./GroupBox";
import { IWording } from "../../Types";
import { api } from "../../../../RevitJS/API";

const wording: IWording = {
  stepOne: {
    French: "Etape 1/2 : Sélection des types de systèmes",
    English: "Step 1/2: Type system selection",
  },
};

interface Props {
  onBack: () => void;
}

export const GroupSelector = (props: Props) => {
  useEffect(() => {
    api.windowsHandler.resizeWindow(900, 607);
  }, []);
  // const { showHeader, showSubHeader, headerName } = props;
  return (
    <div style={{ height: "100%" }}>
      <TopHeader Icon={""} name={"Schedules"} />

      {/* <SubHeader heading={wording["stepOne"]["English"]} /> */}
      <GroupBox {...props} />
    </div>
  );
};

export default GroupSelector;
