import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { familliesImport } from "./FamilliesImport";

// generate boilerplate from https://www.uuidgenerator.net/version4 and put it here
export const pluginId = "d969d046-cd21-4aa4-a822-b1f7fd96aeea";

export const pamCanalisation: Plugin = {
  TechnicalName: "PAMCANA",
  Name: "",
  Icon: "/plugin_new_logo/PAM.png",
  ShortDesc: {
    French: "...",
    English: "...",
    German: "...",
    Italian: "..."
  },
  Id: pluginId,
  Color: "#1A171B",
  Functionalities: [
    // Functionalities go in here
    familliesImport,
  ],
};
