import { wait } from "../Helpers";
import { ID, ColorRGB } from "../Types/RevitTypes";

export class ViewHandler {
  async setFilterColor(id: ID, color: ColorRGB) {
    if (!window.revit) {
      await wait(50);
      return true;
    }

    localStorage.setItem("setFilterColor", "true");
    localStorage.setItem("setFilterColorResult", "null");
    localStorage.setItem("isErrorThrown", "False");

    let processing = true;
    window.revit.setFilterColor(id, JSON.stringify(color));

    while (processing) {
      await wait(50);
      // alert(JSON.parse(localStorage.getItem('getWallsData')));
      processing = JSON.parse(localStorage.getItem("setFilterColor") as string);
    }
    return JSON.parse(localStorage.getItem("setFilterColorResult") as string);
  }

  async setSelectionFilterVisibility(
    viewId: ID,
    filterId: ID,
    visibility: boolean
  ): Promise<boolean> {
    localStorage.setItem("setSelectionFilterVisibility", "true");
    localStorage.setItem("setSelectionFilterVisibilityResult", "null");
    localStorage.setItem("isErrorThrown", "False");
    let processing = true;
    window.revit.setSelectionFilterVisibility(
      viewId,
      filterId,
      JSON.stringify(visibility)
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("setSelectionFilterVisibility") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("setSelectionFilterVisibilityResult") as string
    );
  }

  async setElementColor(id: ID[], color: ColorRGB, elementType: string) {
    localStorage.setItem("setElementColorView", "true");
    localStorage.setItem("setElementColorViewResult", "null");
    let processing = true;
    window.revit.setElementColor(
      JSON.stringify(id),
      JSON.stringify(color),
      elementType
    );

    while (processing) {
      await wait(50);
      processing = JSON.parse(
        localStorage.getItem("setElementColorView") as string
      );
    }
    return JSON.parse(
      localStorage.getItem("setElementColorViewResult") as string
    );
  }

  async clearElementColor(id: ID[]) {
    localStorage.setItem("clearColor", "true");
    localStorage.setItem("clearColorResult", "null");
    let processing = true;
    window.revit.clearElementColor(JSON.stringify(id));

    while (processing) {
      await wait(50);
      processing = JSON.parse(localStorage.getItem("clearColor") as string);
    }
    return JSON.parse(localStorage.getItem("clearColorResult") as string);
  }
}
