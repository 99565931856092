import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { connect } from "react-redux";
import { Routes } from "./root";
import "../Resources/technicalLanding.css";
import "../Resources/CommonStyles.css";
import DocumentBuilderHome from "./DocumentBuilderHome";
import { SelectionStore } from "../../Selection/Reducers";
import { FunctionalityHeader } from "./FunctionalityHeader";
import { SaveProject } from "../../CommonModules/PopUpModals/SaveProject";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { setprojectData } from "../../Selection/Actions/index";
interface Props {
  wording: {
    documentTechnique: { [key: string]: string };
    savedListDocuments: { [key: string]: string };
    dao: { [key: string]: string };
    dae: { [key: string]: string };
    doe: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  routes: Routes;
  Icon: string;
  dossierData: any;
  setprojectData: any;
  config: any;
}

export class TechnicalFileLanding extends Component<Props> {
  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  state = {
    documents: [],
    modelName: "",
    documentType: null,
    confirmModal: false,
  };

  panes = [
    {
      menuItem: this.props.wording.dao[this.props.language],
      name: "DAO",
      index: 0,
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentBuilderHome
            route={this.props.routes}
            setRoute={this.props.setRoute}
            tabName={this.props.wording.dao[this.props.language]}
            documents={this.state.documents.filter((doc: any) => {
              return (
                doc.documentType ===
                  this.props.wording.dao[this.props.language] &&
                doc.modelName === this.state.modelName
              );
            })}
            refreshData={() => this.refreshData()}
            handleTabChange={this.handleTabChange}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: this.props.wording.dae[this.props.language],
      name: "DAE",
      index: 1,
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentBuilderHome
            route={this.props.routes}
            setRoute={this.props.setRoute}
            tabName={this.props.wording.dae[this.props.language]}
            documents={this.state.documents.filter((doc: any) => {
              return (
                doc.documentType ===
                  this.props.wording.dae[this.props.language] &&
                doc.modelName === this.state.modelName
              );
            })}
            refreshData={() => this.refreshData()}
            handleTabChange={this.handleTabChange}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: this.props.wording.doe[this.props.language],
      name: "DOE",
      index: 2,
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentBuilderHome
            route={this.props.routes}
            setRoute={this.props.setRoute}
            tabName={this.props.wording.doe[this.props.language]}
            documents={this.state.documents.filter((doc: any) => {
              return (
                doc.documentType ===
                  this.props.wording.doe[this.props.language] &&
                doc.modelName === this.state.modelName
              );
            })}
            refreshData={() => this.refreshData()}
            handleTabChange={this.handleTabChange}
          />
        </Tab.Pane>
      ),
    },
  ];

  componentDidMount = async () => {
    let projectData: initProjectData = await api.queries.getSetProjectData();

    if (projectData.ProjectId === "") {
      this.toggleModal();
    } else {
      this.props.setprojectData(projectData);

      //api.windowsHandler.resizeWindow(900, 580);
      await this.refreshData();
      this.setState({
        modelName: projectData.ProjectId,
        documentType:
          this.props.dossierData.documentType === undefined
            ? this.panes.find((a) => a.index === 0)?.name
            : this.props.dossierData.documentType,
      });
    }
  };

  toggleModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  onYesAction = () => {
    this.toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };

  refreshData = async () => {
    let dossierTechnique: any = await bimStorage.getItem(
      storageKey.DOSSIER_TECHNIQUE
    );
    if (dossierTechnique && dossierTechnique.length > 0)
      this.setState({ documents: dossierTechnique });
    else this.setState({ documents: [] });
  };

  handleTabChange = (activeTab: any) => {
    this.setState({
      documentType: this.panes.find((a) => a.index === activeTab.activeIndex)
        ?.name,
    });
  };

  render() {
    return (
      <div>
        <SaveProject
          toggleModal={this.toggleModal}
          confirmModal={this.state.confirmModal}
          onYesAction={this.onYesAction}
        />
        <FunctionalityHeader
          name={this.props.wording.documentTechnique[this.props.language]}
          Icon={this.props.Icon}
          subheader={this.props.wording.savedListDocuments[this.props.language]}
        />
        <Tab
          menu={{ attached: false }}
          activeIndex={
            this.panes.find((a) => a.name === this.state.documentType)?.index
          }
          panes={this.panes}
          className="tabMenu"
          style={{ padding: 10 }}
          onTabChange={(event, data) => {
            this.handleTabChange(data);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    heading: ownProps.name,
    setRoute: ownProps.setRoute,
    dossierData: state.moduleData.dossierData,
    config: state.config,
  };
};

const mapDispatch = {
  setprojectData,
};

export default connect(mapStateToProps, mapDispatch)(TechnicalFileLanding);
