import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { ShowSolutionsRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "aad1c6ac-6b71-411b-8b67-8ae16bd8660e";

export const showSolutionsTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <ShowSolutionsRoot />
    </ParentRoot>
  );
};

export const ShowSolutionRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <ShowSolutionsRoot />
    </ParentRoot>
  );
};

export const ShowSolutions: Functionality = {
  Name: {
    French: "Vue du calepinage",
    English: "View of the layout",
    German: "Vue du calepinage",
    Italian: "Vue du calepinage",
    Indonesian: "Vue du calepinage",
  },
  Trigger: showSolutionsTrigger,
  ShortDesc: {
    French: "Afficher le calepinage",
    English: "Show layout",
    German: "Afficher le calepinage",
    Italian: "Afficher le calepinage",
    Indonesian: "Afficher le calepinage",
  },
  Id: functionalityId,
  RootComponent,
  Icon: "/PLACO_ICONS_New/Vue du calepinage.png",
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
