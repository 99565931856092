import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, TextArea } from "semantic-ui-react";
import jwt_decode from "jwt-decode";
import { api } from "../../RevitJS/API";
import { MyConfig } from "../../Helper";

export default function ReportErrorForm(props: any) {
  const [username, setUserName] = useState<string>("");
  const [shortMessage, setShortMessage] = useState<string>("");
  const [config, setConfig] = useState<any>();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (config) {
      api.eventLog.SetErrorReport(
        {
          environment: `${config.REACT_APP_ENV}`,
          errorCode: props.code,
          errorSeverity: props.severity,
          moduleName: localStorage.getItem("module"),
          pluginName: localStorage.getItem("TechnicalName"),
          pluginVersion: localStorage.getItem("getVersionResult"),
          shortMessage: shortMessage,
          stackTrace: JSON.stringify(props.stackTrace),
          timestamp: new Date().toLocaleString(),
          url: `${config.REACT_APP_SERVERURL}`,
          userEmail: username,
        },
        config
      );
    }
    props.setReportError(false);
    if (
      localStorage.getItem("module") === "Reverse Direction" ||
      localStorage.getItem("module") === "Reverse Sides" ||
      localStorage.getItem("module") === "suppression" ||
      localStorage.getItem("module") === "dimensions" ||
      localStorage.getItem("module") === "Quantitatives" ||
      localStorage.getItem("module") === "colorization"
    ) {
      setTimeout(() => {
        api.windowsHandler.closeWindow();
      }, 2000);
    }
  };

  useEffect(() => {
    let token: any = localStorage.getItem("token");
    let decode: any = jwt_decode(token);
    const { userId } = decode;
    setUserName(userId);
    async function callConfig() {
      const configres = await MyConfig();
      setConfig(configres);
    }
    callConfig();
  }, []);

  if (config) {
    return (
      <Modal open={props.open} dimmer="blurring" style={{ padding: "15px" }}
      className="error-management-modal">
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              id="plugin-id"
              label="Plugin ID"
              value={localStorage.getItem("TechnicalName")}
              readOnly
              disabled
            />
            <Form.Field
              control={Input}
              id="module-name"
              label="Module Name"
              value={localStorage.getItem("module")}
              readOnly
              disabled
            />
            <Form.Field
              control={Input}
              id="plugin-version"
              label="Plugin Version"
              value={localStorage.getItem("getVersionResult")}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              id="env"
              label="Environment"
              value={`${config.REACT_APP_ENV}`}
              placeholder="Environment"
              readOnly
              disabled
            />
            <Form.Field
              id="url"
              label="URL"
              placeholder="URL"
              control={Input}
              value={`${config.REACT_APP_SERVERURL}`}
              readOnly
              disabled
            />
            <Form.Field
              control={Input}
              id="timestamp"
              label="Date and Time"
              placeholder="Date and Time"
              value={new Date().toLocaleString()}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="email"
              control={Input}
              label="Email"
              value={username}
              readOnly
              disabled
            />
            <Form.Field
              control={Input}
              id="error-code"
              label="Error Code"
              value={props.code}
              readOnly
              disabled
            />
            <Form.Field
              control={Input}
              id="error-severity"
              label="Error Severity"
              value={props.severity}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Field
            id="message"
            control={TextArea}
            label="Short Message"
            placeholder="Pease give your feedback"
            value={shortMessage}
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => {
              setShortMessage(e.target.value);
            }}
          />
          <Form.Field id="stackTrace">
            <input type="hidden" value={props.stackTrace} />
          </Form.Field>
          <Button primary type="submit" floated="right" className="btn-ok">
            Submit
          </Button>
          <Button
          className="btn-cancel"
            floated="left"
            type="submit"
            onClick={() => {
              props.setReportError(false);
            }}
          >
            Close
          </Button>
        </Form>
      </Modal>
    );
  }
  return <></>;
}
