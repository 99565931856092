import _ from "lodash";
import { AnyAction } from "redux";
import {
  CREATE_NEW_PROJECT,
  RESET_CURRENT_PROJECT,
  SET_CURRENT_ITEM_AND_SANITIZE_AVAILABLE_ELEMENTS,
  SET_CURRENT_LOOSE_ELEMENTS,
  SET_CURRENT_PROJECT_ALL_AVAILABLE_ELEMENTS,
  SET_CURRENT_PROJECT_COUNTRY,
  SET_CURRENT_PROJECT_CURRENT_ITEM_COMPATIBLE_AND_AVAILABLE_ELEMENTS,
  SET_CURRENT_PROJECT_INITIAL_DATES,
  SET_CURRENT_PROJECT_ITEMS,
  SET_CURRENT_PROJECT_NAME,
  SET_ELEMENTS_FILTERS,
  SET_PROJECT_LIST,
  SET_SELECTED_ELEMENT_IN_ITEM,
  VALIDATE_SELECTION,
  SET_CURRENT_PROJECT_VALIDATED_ITEMS,
  SAVE_PROJECT,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_SELECTION_METHOD,
} from "../actions/actionTypes";
import {
  getElementName,
  setCurrentItemAndSanitizeAvailableElements,
} from "../helpers/utils";
import Item from "../models/Item.model";
import { initializedProject } from "../models/Project.model";
import { saveNewProject } from "../services/projects.service.local2";
import { RootState } from "./initialState";

export default function userInfo(
  state = RootState.projects,
  action: AnyAction
) {
  switch (action.type) {
    case CREATE_NEW_PROJECT:
      return { ...state, currentProject: { ...initializedProject } };
    case RESET_CURRENT_PROJECT:
      return { ...state, currentProject: { ...initializedProject } };

    case SET_CURRENT_PROJECT_NAME:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          name: action.payload,
        },
      };
    case SET_CURRENT_PROJECT_COUNTRY:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          country: action.payload,
        },
      };
    case SET_PROJECT_LIST:
      return {
        ...state,
        projectsList: action.payload,
      };
    case SET_CURRENT_PROJECT_INITIAL_DATES:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          ...action.payload,
        },
      };
    case SET_CURRENT_PROJECT_ITEMS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          items: [...action.payload],
        },
      };
    case SET_CURRENT_PROJECT_SELECTION_METHOD:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          selectionMethod: action.payload,
        },
      };
    case SET_CURRENT_LOOSE_ELEMENTS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          looseElements: [...action.payload],
        },
      };
    case SET_CURRENT_PROJECT_CURRENT_ITEM_COMPATIBLE_AND_AVAILABLE_ELEMENTS:
      let tmp = _.cloneDeep(state.currentProject.items);
      tmp = tmp.map((tmpItem) => {
        if (
          action.payload.range.technicalName === tmpItem.range.technicalName
        ) {
          return action.payload;
        } else {
          return tmpItem;
        }
      });

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          items: [...tmp],
        },
      };

      break;

    case SET_CURRENT_PROJECT_ALL_AVAILABLE_ELEMENTS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          allAvailableElements: [...action.payload],
        },
      };
    case SET_ELEMENTS_FILTERS:
      let tmpItems = _.cloneDeep(state.currentProject.items);
      tmpItems.forEach((item) => {
        if (action.payload.item !== null && action.payload.item !== undefined) {
          if (_.isEqual(item, action.payload.item)) {
            item.filters = action.payload.elementsFilters;
          }
        } else {
          item.filters = action.payload;
        }
      });
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          items: _.cloneDeep(tmpItems),
        },
      };

    case SET_SELECTED_ELEMENT_IN_ITEM:
      let tmpItemsList = _.cloneDeep(state.currentProject.items);
      let tmpItem = tmpItemsList.find((item) => {
        return item.range.technicalName === action.payload.technicalName;
      });

      if (tmpItem) {
        tmpItem.compatibleAndAvailableElements.forEach((element) => {
          if (
            getElementName(action.payload.element) === getElementName(element)
          ) {
            element.selected = action.payload.element.selected;
          }
        });
        tmpItem.compatibleAndAvailableElementsSanitized[
          tmpItem.compatibleAndAvailableElementsSanitized
            .map((elementSanitized) => getElementName(elementSanitized))
            .indexOf(getElementName(action.payload.element))
        ].selected = action.payload.element.selected;
        return {
          ...state,
          currentProject: {
            ...state.currentProject,
            items: _.cloneDeep(tmpItemsList),
          },
        };
      } else {
        return state;
      }

    case SET_CURRENT_ITEM_AND_SANITIZE_AVAILABLE_ELEMENTS:
      let tmpItems2 = _.cloneDeep(state.currentProject.items);
      let currentItem = tmpItems2?.find((item: Item) => {
        return item.range.technicalName === action.payload.range.technicalName;
      });

      if (currentItem) {
        currentItem.current = true;
        return {
          ...state,
          currentProject: {
            ...state.currentProject,
            items: _.cloneDeep(tmpItems2),
            inEdition: true,
          },
        };
      } else {
        return state;
      }

      break;

    case VALIDATE_SELECTION: {
      let tmpProject = _.cloneDeep(state.currentProject);
      tmpProject.inEdition = false;
      tmpProject.items = setCurrentItemAndSanitizeAvailableElements(
        action.payload,
        _.cloneDeep(state.currentProject.items)
      );
      return {
        ...state,
        currentProject: _.cloneDeep(tmpProject),
      };
    }

    case SET_CURRENT_PROJECT_VALIDATED_ITEMS: {
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          validatedItems: action.payload ? [...action.payload] : [],
        },
      };
    }

    case SAVE_PROJECT: {
      saveNewProject(state.currentProject);
      return state;
    }

    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: action.payload,
      };
    }

    default:
      return state;
  }
}
