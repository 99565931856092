import { combineReducers } from "redux";
import app from "./app/reducers";
import updateSystemsReducer from "./updateSystems/reducers";
import { AppActionTypes, AppState } from "./app/types";
import { LoadSystemsActionTypes, UpdateSystemsState } from "./updateSystems/types"
import { config } from "../../../CLT/Delete/Reducers";


export const reducers = combineReducers({
    app: app,
    updateSystems: updateSystemsReducer,
    config
});

export interface DrawStore {
    updateSystems: UpdateSystemsState;
    app: AppState;
    config: any
}

export type ActionTypes = LoadSystemsActionTypes | AppActionTypes

export type RootState = ReturnType<typeof reducers>