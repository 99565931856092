import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../../Selection/Reducers";
import {
  Table,
  Checkbox,
  Modal,
  Button,
  Dimmer,
  Loader,
  Header,
  Segment,
  Grid,
} from "semantic-ui-react";
import map from "lodash/map";
import forEach from "lodash/forEach";
import flattenDeep from "lodash/flattenDeep";
import filter from "lodash/filter";
import includes from "lodash/includes";
import uniq from "lodash/uniq";
import keysIn from "lodash/keysIn";
import pickBy from "lodash/pickBy";
import groupBy from "lodash/groupBy";
import find from "lodash/find";

import { api } from "../../../../../RevitJS/API";
import { Routes } from "../root";
import { ExcelExtract } from "../../Model";
import { setCalepinage } from "../../Actions";
import { montantDefault, plaqueDefault } from "./TableRow";
import {
  resetCalepinageSelection,
  setHistoryPath,
  setSelectorType,
} from "../../../Selection/Actions";
import {
  isEmpty,
  isUndefined,
  omit,
  orderBy,
  remove,
  round,
  startsWith,
} from "lodash";
import { selectBuffer } from "../../../Selection/Actions";
import { fetchPlacoDataInGroupSplit } from "../../Helpers/placoHelper";
import { selectorTp } from "../../../Selection/Actions/types";
import { WallTable } from "./WallTable";
import { CeilingTable } from "./CeilingTable";
import { fetchPlacoCeilingDataInGroupSplit } from "../../Helpers/placoCeilingHelper";
import { SystemConfigurationContainer } from "../../../Selection/Components/SystemConfigurationContainer";
import { FunctionalityHeader } from "../FunctionalityHeader";
import { InjectedCounterProps } from "../../../../../ErrorManagement/components/ErrorBoundry";
import { removeCloseWindow } from "../../../CommonModules/Helpers/sessionRemoveCloseWindow";
import { CalpinageRevitApi } from "../../../../../ErrorManagement/utils/revitApiError";
import { errorCodeKey } from "../../../../../ErrorManagement/utils/errorCodeEnum";
interface State {
  duplicate: boolean;
  wallsData: any;
  extractDetails: Array<ExcelExtract>;
  plaque: Array<Array<string>>;
  montant: Array<string>;
  showNonLayoutPossible: boolean;
  extractedSolutionProduct: any;
  loading: boolean;
  configVisible: boolean;
  configSystem: any;
  checkedWalls: Object;
  ref: any;
  ceilingsData: any;
}

type Props = PropsFromRedux &
  InjectedCounterProps & {
    moduleData: any;
    wording: {
      solutionPlaco: { [key: string]: string };
      revitSystemType: { [key: string]: string };
      validate: { [key: string]: string };
      continue: { [key: string]: string };
      back: { [key: string]: string };
      sideA: { [key: string]: string };
      sideB: { [key: string]: string };
      lining: { [key: string]: string };
      SAA: { [key: string]: string };
      SAD: { [key: string]: string };
      loader: { [key: string]: string };
      noWall: { [key: string]: string };
      noCeiling: { [key: string]: string };
      partitionWalls: { [key: string]: string };
      ceilings: { [key: string]: string };
      title: { [key: string]: string };
      subheader: { [key: string]: string };
    };
    Icon: string;
    language: string;
    setRoute: any;
    setCalepinage: any;
    selections: any;
    resetCalepinageSelection: any;
    selectBuffer: any;
    setHistoryPath: any;
    selectorType: selectorTp;
    setSelectorType: any;
    config: any;
    onError: any;
  };
export class SolutionTable extends Component<Props, State> {
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.ref = React.createRef();
  }
  state = {
    duplicate: false,
    wallsData: [],
    layoutableSoltion: [],
    nonLayoutableSolution: [],
    ceilingsData: [],
    extractDetails: [],
    plaque: [],
    montant: [],
    showNonLayoutPossible: false,
    extractedSolutionProduct: [],
    loading: true,
    configSystem: undefined,
    checkedWalls: {},
    ref: null,
    configVisible: false,
  };

  public static defaultProps = {
    wording: {
      title: {
        French: "CALEPINAGE",
        English: "LAYOUT",
      },
      subheader: {
        French: "Tableau de calepinage​",
        English: "Layout table",
      },
      solutionPlaco: {
        French: "Solutions Placo",
        English: "Solutions Placo",
      },
      revitSystemType: {
        French: "Système Revit",
        English: "Revit System Type",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
      continue: {
        French: "Continuer",
        English: "Continue",
      },
      back: {
        French: "Retour",
        English: "Back",
      },
      sideA: {
        French: "Parement A",
        English: "Side A",
      },
      sideB: {
        French: "Parement B",
        English: "Side B",
      },
      lining: {
        French: "Doublage",
        English: "Lining",
      },
      SAA: {
        French: "SAA",
        English: "SAA",
      },
      SAD: {
        French: "SAD",
        English: "SAD",
      },
      loader: {
        French: "Chargement de données, merci de bien vouloir patienter",
        English: "Loading data, please wait patiently",
      },
      noWall: {
        French: "Aucun mur n'a été sélectionné dans le projet",
        English: "There are no Walls",
      },
      noCeiling: {
        French: "Aucun plafond n'a été sélectionné dans le projet",
        English: "There are no Plafonds",
      },
      partitionWalls: {
        French: "Murs",
        English: "Walls",
      },
      ceilings: {
        French: "Plafonds",
        English: "Ceilings",
      },
    },
  };

  updateExtractDetail = (
    index: number,
    value: ExcelExtract,
    itemKey: string
  ) => {
    const { extractDetails } = this.state;
    (
      (extractDetails as ExcelExtract[]) ||
      (extractDetails[index] as ExcelExtract[])
    ).push({ ...value, ...{ itemKey: itemKey } });
    this.setState({
      extractDetails,
    });
  };

  onRefChange = (node: any) => {
    this.setState({ ref: node });
  };

  setMontant = async (wall:any, value: string[]) => {
    let prevWwallsData =
      this.props.selectorType === "wall"
        ? JSON.parse(JSON.stringify(this.state.wallsData))
        : JSON.parse(JSON.stringify(this.state.ceilingsData));
    // prevWwallsData[key]["montant"] = value;
    // map(prevWwallsData, (val: any, ke: any) => {
    //   if (ke === key) {
    //     map(val, (wallObj: any, wallKey: any) => {
    //       wallObj["montant"] = value;
    //       return wallObj;
    //     });
    //   }
    //   return val;
    // });
    map(prevWwallsData, (val: any) => {
      if (val.LevelId === wall.LevelId && val.Height === wall.Height && val.WallType === wall.WallType && val.Zone === wall.Zone) {
        val['montant'] = value;
      }
      return val;
    })
    if (this.props.selectorType === "wall") {
      return Promise.resolve(this.setState({ wallsData: prevWwallsData }));
    } else {
      return Promise.resolve(this.setState({ ceilingsData: prevWwallsData }));
    }
  };

  setPlaque = async (wall:any , value: string[]) => {
    let prevWwallsData =
      this.props.selectorType === "wall"
        ? JSON.parse(JSON.stringify(this.state.wallsData))
        : JSON.parse(JSON.stringify(this.state.ceilingsData));

    // prevWwallsData[key]["plaque"] = value;
    // map(prevWwallsData, (val: any, ke: any) => {
    //   if (ke === key) {
    //     map(val, (wallObj: any, wallKey: any) => {
    //       wallObj["plaque"] = value;
    //       return wallObj;
    //     });
    //   }
    //   return val;
    // });
    map(prevWwallsData, (val: any) => {
      if (val.LevelId === wall.LevelId && val.Height === wall.Height && val.WallType === wall.WallType && val.Zone === wall.Zone) {
        val['plaque'] = value;
        // map(val, (wallObj: any) => {
        //   // wallObj["plaque"] = value;
        //   return wallObj;
        // });
      }
      return val;
    })
    if (this.props.selectorType === "wall") {
      return Promise.resolve(this.setState({ wallsData: prevWwallsData }));
    } else {
      return Promise.resolve(this.setState({ ceilingsData: prevWwallsData }));
    }
  };

  setCeilingX = async (key: string, value: string[]) => {
    let prevCeilingsData = JSON.parse(JSON.stringify(this.state.ceilingsData));
    prevCeilingsData[key]["x"] = value;
    return Promise.resolve(this.setState({ ceilingsData: prevCeilingsData }));
  };

  setCeilingY = async (key: string, value: string[]) => {
    let prevCeilingsData = JSON.parse(JSON.stringify(this.state.ceilingsData));
    prevCeilingsData[key]["y"] = value;
    return Promise.resolve(this.setState({ ceilingsData: prevCeilingsData }));
  };

  // Toggle all rows
  setUnsetRows = async (checked: boolean, isLayoutPossible: boolean) => {
    this.setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 100));

    let wallsDeepData =
      this.props.selectorType === "wall"
        ? JSON.parse(JSON.stringify(this.state.wallsData))
        : JSON.parse(JSON.stringify(this.state.ceilingsData));
    map(wallsDeepData, (val: any, ke: any) => {
      // if (val.layoutPossible) val.chk = checked;
      // if val.layoutPossible
      // if not
      if (isLayoutPossible === val.layoutPossible) {
        val.chk = checked;
      }

      return val;
    });

    if (this.props.selectorType === "wall") {
      await Promise.resolve(
        this.setState({ wallsData: wallsDeepData, loading: false })
      );
    } else {
      await Promise.resolve(
        this.setState({ ceilingsData: wallsDeepData, loading: false })
      );
    }
    /// if proposed checked value is false then set state for "layoutPossible" to false
    /// this will raise prompt or dialog box on valider button click
  };

  defaultCheckedGroupCheckbox = () => {
    let defaultWallLayoutPossibleChecked: boolean = true;
    let defaultWallNonLayoutPossibleChecked = true;
    let disabledCheckWall: boolean = true;

    let defaultCeilingChecked = true;
    let allUnchecked = true;
    let placo100 = true;

    let allCeilingPlaco = filter(this.state.ceilingsData, { placo: false });
    let allCeilingChk = filter(this.state.ceilingsData, { chk: false });

    let allWallPlaco = filter(this.state.wallsData, { placo: false });
    let allWallChk = filter(this.state.wallsData, { chk: false });

    // 1. check true and layoutpossible true //ans: 3
    // 2. layout possible true // ans: 3
    // 3. 1===2 // true

    let allX = filter(this.state.ceilingsData, { x: "" });
    let allY = filter(this.state.ceilingsData, { y: "" });
    let checkedXY = this.state.ceilingsData.filter((c: any) => c.chk === true);
    let checkAllX = filter(checkedXY, { x: "" });
    let checkAllY = filter(checkedXY, { y: "" });
    let xValue: any, yValue: any;
    checkedXY.map((xy: any) => {
      xValue = xy.x;
      return xy;
    });
    checkedXY.map((xy: any) => {
      yValue = xy.y;
      return xy;
    });
    if (this.props.selectorType === "wall") {
      let allLayoutableSolutionWall = filter(this.state.wallsData, {
        layoutPossible: true,
      });
      let allLayoutableSolutionWallChk = filter(this.state.wallsData, {
        chk: true,
        layoutPossible: true,
      });

      defaultWallLayoutPossibleChecked =
        allLayoutableSolutionWallChk.length ===
        allLayoutableSolutionWall.length;

      let allNonLayoutableSolutionWall = filter(this.state.wallsData, {
        layoutPossible: false,
      });

      let allNonLayoutableSolutionWallChk = filter(this.state.wallsData, {
        chk: true,
        layoutPossible: false,
      });

      let allNonLayoutableSolutionWallChkPlaco = filter(this.state.wallsData, {
        chk: false,
        layoutPossible: false,
        placo: false,
      });

      defaultWallNonLayoutPossibleChecked =
        allNonLayoutableSolutionWall.length ===
        allNonLayoutableSolutionWallChk.length;

      disabledCheckWall =
        allNonLayoutableSolutionWallChkPlaco.length ===
        allNonLayoutableSolutionWall.length;
    } else {
      defaultCeilingChecked =
        // allCeilingLayoutPossible.length === 0 &&
        allCeilingPlaco.length === 0 && allCeilingChk.length === 0;
    }

    allUnchecked =
      //allWallLayoutPossible.length === this.state.wallsData.length ||
      (allWallPlaco.length === this.state.wallsData.length ||
        allWallChk.length === this.state.wallsData.length) && //allCeilingLayoutPossible.length === this.state.ceilingsData.length ||
      (allCeilingPlaco.length === this.state.ceilingsData.length ||
        allCeilingChk.length === this.state.ceilingsData.length);

    placo100 =
      allWallPlaco.length === this.state.wallsData.length ||
      // allWallLayoutPossible.length === this.state.wallsData.length ||
      allCeilingPlaco.length === this.state.ceilingsData.length;
    // allCeilingLayoutPossible.length === this.state.ceilingsData.length;

    let xyEmpty = allX.length > 0 || allY.length > 0;
    let checkXYEmpty = checkAllX.length > 0 || checkAllY.length > 0;
    return {
      defaultWallLayoutPossibleChecked,
      defaultWallNonLayoutPossibleChecked,
      defaultCeilingChecked,
      disabledCheckWall,
      allUnchecked,
      placo100,
      xyEmpty,
      xValue,
      yValue,
      checkXYEmpty,
    };
  };
  nonLayoutPossiblePropmpt = async () => {
    let anyNonLayoutPossilbe: boolean = false;

    // if (
    //   find(this.state.wallsData, { layoutPossible: false }) ||
    //   find(this.state.wallsData, { placo: false }) ||
    //   find(this.state.ceilingsData, { layoutPossible: false }) ||
    //   find(this.state.ceilingsData, { placo: false })
    //   //|| find(this.state.wallsData, { chk:false})
    // ) {
    //   anyNonLayoutPossilbe = true;
    // }
    // this.setState({ showNonLayoutPossible: anyNonLayoutPossilbe });

    if (
      this.state.wallsData.some(
        (w: any) =>
          w.chk === true && (w.layoutPossible === false || w.placo === false)
      ) ||
      this.state.ceilingsData.some(
        (c: any) =>
          c.chk === true && (c.layoutPossible === false || c.placo === false)
      )
    ) {
      anyNonLayoutPossilbe = true;
      this.setState({ showNonLayoutPossible: anyNonLayoutPossilbe });
    }

    this.saveWallsData();
    // if (!anyNonLayoutPossilbe) {
    //   this.saveWallsData();
    // }
    this.props.moduleData.calpinageData.buffer.changeFlag = true;
  };

  /// Extract selection from selection module
  /// collect wall data from revit
  /// collect placo solutions from api
  groupWallsDataFromSelection = async () => {
    try {
      /// Extract selection ids
      let selectionIds = map(
        this.props.moduleData.calpinageData.buffer.selections.list,
        (n) => {
          return n.Id;
        }
      );

      /// Extract selection list based on ids
      let selectionList = filter(this.props.selections, function (o) {
        return includes(selectionIds, o.Id);
      });

      /// 1. Extract selected wall ids from selection.
      let wallRows = flattenDeep(
        map(selectionList, (ele) => {
          return map(ele.SelectionByType.wall.Rows, (row) => {
            return {
              ...row,
              zone: ele.Zone ? ele.Name : "",
            };
          });
        })
      );

      let wallIdsExt = flattenDeep(
        map(wallRows, (ele) => {
          if (ele.RevitSelection) {
            return ele.RevitSelection.Ids;
          }
        })
      );

      /// 2. Check for duplicate wall ids
      let dupliactesWallIds = keysIn(
        pickBy(groupBy(wallIdsExt), (x) => {
          return x.length > 1;
        })
      );

      if (dupliactesWallIds.length === 0) {
        /// 3. Get walls data from revit
        let wallsData = await api.queries.getWallsData(wallIdsExt);
        //CalpinageRevitApi(this.props);
        /// 4. Extract solution ids
        let solutionIds = uniq(
          flattenDeep(
            map(wallRows, (ele) => {
              return ele.Options.MappedSystem.oid;
            })
          )
        );
        /// 5. Get solution data from api respected to solution ids
        const extractedSolutionProduct = await fetchPlacoDataInGroupSplit(
          solutionIds,
          this.props.config
        )
          .then(({ extractedSolutionProduct }) => {
            return extractedSolutionProduct;
          })
          .catch((error) => {
            console.log("error in 518:", error);
            return Promise.reject({
              code: errorCodeKey.PB_LT_H_001,
              stack: error.stack,
            });
          });
        wallsData = orderBy(wallsData, ["LevelElevation"], ["asc"]);

        let grpWallsData: any[] = [];

        forEach(wallsData, (value: any, index: number) => {
          let wallRow = find(wallRows, (walRow: any) => {
            return includes(walRow.RevitSelection.Ids, value.Id);
          });

          let height: String = parseFloat(
            (value.Height / 1000).toString()
          ).toFixed(2);

          let oneValue = find(grpWallsData, {
            LevelId: value.LevelId,
            Zone: wallRow.zone,
            WallType: value.WallType,
            Height: height,
            // PlacoSolution: wallRow.Options.MappedSystem.longName
            //   ? wallRow.Options.MappedSystem.longName
            //   : wallRow.Options.MappedSystem.translation,
            PlacoSolution: wallRow.Options.MappedSystem["A-Solution product name"],
          });

          if (oneValue) {
            let ids = oneValue.Ids;
            ids.push(value.Id);
            find(grpWallsData, {
              LevelId: value.LevelId,
              Zone: wallRow.zone,
              WallType: value.WallType,
              Height: height,
              // PlacoSolution: wallRow.Options.MappedSystem.longName
              //   ? wallRow.Options.MappedSystem.longName
              //   : wallRow.Options.MappedSystem.translation,
              PlacoSolution: wallRow.Options.MappedSystem["A-Solution product name"],
            }).Ids = ids;
          } else {
            grpWallsData.push({
              Height: height,
              LevelName: value.LevelName,
              // PlacoSolution: wallRow.Options.MappedSystem.longName
              //   ? wallRow.Options.MappedSystem.longName
              //   : wallRow.Options.MappedSystem.translation,
              PlacoSolution: wallRow.Options.MappedSystem["A-Solution product name"],
              PlacoSolutionId: wallRow.Options.MappedSystem.oid,
              PlacoSolutionHeight:
                wallRow.Options.MappedSystem["GFR-Height limit in m"],
              Zone: wallRow.zone,
              WallType: value.WallType,
              LevelElevation: value.LevelElevation,
              LevelId: value.LevelId,
              Ids: [value.Id],
              montant: wallRow.montant,
              plaque: wallRow.plaque,
            });
          }
        });

        const proceessWalls = this.processNewWallData(
          grpWallsData,
          extractedSolutionProduct
        );

        /// 7. Set state for wallsData, SolutionProducts

        this.setState({
          wallsData: proceessWalls,
          loading: false,
        });
      } else {
        /// Set duplicate state to true
        this.setState({ duplicate: true, loading: false });
      }
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  groupCeilingsDataFromSelection = async () => {
    try {
      this.setState({ loading: true });
      /// Extract selection ids
      let selectionIds = map(
        this.props.moduleData.calpinageData.buffer.selections.list,
        (n) => {
          return n.Id;
        }
      );

      /// Extract selection list based on ids
      let selectionList = filter(this.props.selections, function (o) {
        return includes(selectionIds, o.Id);
      });

      /// 1. Extract selected wall ids from selection.
      let ceilingRows = flattenDeep(
        map(selectionList, (ele) => {
          return map(ele.SelectionByType.ceiling.Rows, (row) => {
            return {
              ...row,
              zone: ele.Zone ? ele.Name : "",
            };
          });
        })
      );

      let ceilingIdsExt = flattenDeep(
        map(ceilingRows, (ele) => {
          if (ele.RevitSelection) {
            return ele.RevitSelection.Ids;
          }
        })
      );

      /// 2. Check for duplicate wall ids
      let dupliactesCeilingIds = keysIn(
        pickBy(groupBy(ceilingIdsExt), (x) => {
          return x.length > 1;
        })
      );

      if (dupliactesCeilingIds.length === 0) {
        /// 3. Get walls data from revit
        let ceilingsData = await api.queries.getCeilingsData(ceilingIdsExt);
        //CalpinageRevitApi(this.props);

        /// 4. Extract solution ids
        let solutionIds = uniq(
          flattenDeep(
            map(ceilingRows, (ele) => {
              return ele.Options.MappedSystem.oid;
            })
          )
        );
        console.log("in line 656");

        /// 5. Get solution data from api respected to solution ids
        const extractedSolutionProduct =
          await fetchPlacoCeilingDataInGroupSplit(
            solutionIds,
            this.props.config
          )
            .then(({ extractedSolutionProduct }) => {
              return extractedSolutionProduct;
            })
            .catch((error) => {
              console.log("error in 668:", error);
              return Promise.reject({
                code: errorCodeKey.PB_LT_H_001,
                stack: error.stack,
              });
            });

        ceilingsData = orderBy(ceilingsData, ["LevelElevation"], ["asc"]);

        let grpCeilingsData: any[] = [];

        forEach(ceilingsData, (value: any, index: number) => {
          let ceilingRow = find(ceilingRows, (walRow: any) => {
            return includes(walRow.RevitSelection.Ids, value.Id);
          });

          let height: String = parseFloat(
            (value.Height / 1000).toString()
          ).toFixed(2);

          let oneValue = find(grpCeilingsData, {
            LevelId: value.LevelId,
            Zone: ceilingRow.zone,
            CeilingType: value.CeilingType,
            Height: height,
            // PlacoSolution: ceilingRow.Options.MappedSystem.longName
            //   ? ceilingRow.Options.MappedSystem.longName
            //   : ceilingRow.Options.MappedSystem.translation,
            PlacoSolution: ceilingRow.Options.MappedSystem["A-Solution product name"],
          });

          if (oneValue) {
            let ids = oneValue.Ids;
            ids.push(value.Id);
            find(grpCeilingsData, {
              LevelId: value.LevelId,
              Zone: ceilingRow.zone,
              CeilingType: value.CeilingType,
              Height: height,
              // PlacoSolution: ceilingRow.Options.MappedSystem.longName
              //   ? ceilingRow.Options.MappedSystem.longName
              //   : ceilingRow.Options.MappedSystem.translation,
              PlacoSolution: ceilingRow.Options.MappedSystem["A-Solution product name"],
            }).Ids = ids;
          } else {
            grpCeilingsData.push({
              Height: height,
              LevelName: value.LevelName,
              // PlacoSolution: ceilingRow.Options.MappedSystem.longName
              //   ? ceilingRow.Options.MappedSystem.longName
              //   : ceilingRow.Options.MappedSystem.translation,
              PlacoSolution: ceilingRow.Options.MappedSystem["A-Solution product name"],
              PlacoSolutionId: ceilingRow.Options.MappedSystem.oid,
              PlacoSolutionHeight:
                ceilingRow.Options.MappedSystem["GFR-Height limit in m"],
              Zone: ceilingRow.zone,
              CeilingType: value.CeilingType,
              LevelElevation: value.LevelElevation,
              LevelId: value.LevelId,
              Ids: [value.Id],
              montant: ceilingRow.montant,
              plaque: ceilingRow.plaque,
            });
          }
        });

        const proceessWalls = this.processNewWallData(
          grpCeilingsData,
          extractedSolutionProduct
        );
        /// 7. Set state for wallsData, SolutionProducts
        this.setState({
          ceilingsData: proceessWalls,
          loading: false,
        });
      } else {
        /// Set duplicate state to true
        this.setState({ duplicate: true, loading: false });
      }
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  groupWallsDataFromHistory = async () => {
    try {
      /// 1. Get saved calepinage list in moduleData
      let calepinageList = this.props.moduleData.calpinageData.buffer.calepinage
        .list.walls
        ? this.props.moduleData.calpinageData.buffer.calepinage.list.walls
        : this.props.moduleData.calpinageData.buffer.calepinage.list;

      if (calepinageList.length > 0) {
        /// 3. Extract splution ids from wall object
        let solutionIds = uniq(
          flattenDeep(
            map(calepinageList, (ele) => {
              return ele.PlacoSolutionId;
            })
          )
        );
        console.log("in line 509");

        /// 4. Get placo solutions using solution ids
        const extractedSolutionProduct = await fetchPlacoDataInGroupSplit(
          solutionIds,
          this.props.config
        )
          .then(({ extractedSolutionProduct }) => {
            return extractedSolutionProduct;
          })
          .catch((error) => {
            console.log("error in 777:", error);
            return Promise.reject({
              code: errorCodeKey.PB_LT_H_001,
              stack: error.stack,
            });
          });

        const proceessWalls = this.processNewWallData(
          calepinageList,
          extractedSolutionProduct
        );

        /// 7. Set state for wallsData, SolutionProducts
        this.setState({
          wallsData: proceessWalls,
          loading: false,
        });
      } else {
        this.setState({
          wallsData: [],
          loading: false,
        });
      }
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  groupCeilingsDataFromHistory = async () => {
    try {
      if (this.props.moduleData.calpinageData.buffer.calepinage.list.ceilings) {
        this.setState({
          loading: true,
        });
        /// 1. Get saved calepinage list in moduleData
        let calepinageList =
          this.props.moduleData.calpinageData.buffer.calepinage.list.ceilings;

        /// 3. Extract splution ids from wall object
        let solutionIds = uniq(
          flattenDeep(
            map(calepinageList, (ele) => {
              return ele.PlacoSolutionId;
            })
          )
        );
        console.log("in line 821");

        /// 4. Get placo solutions using solution ids
        const extractedSolutionProduct =
          await fetchPlacoCeilingDataInGroupSplit(
            solutionIds,
            this.props.config
          )
            .then(({ extractedSolutionProduct }) => {
              return extractedSolutionProduct;
            })
            .catch((error) => {
              console.log("error in 835:", error);
              return Promise.reject({
                code: errorCodeKey.PB_LT_H_001,
                stack: error.stack,
              });
            });

        const proceessWalls = this.processNewWallData(
          calepinageList,
          extractedSolutionProduct
        );

        /// 7. Set state for wallsData, SolutionProducts
        this.setState({
          ceilingsData: proceessWalls,
          loading: false,
        });
      }
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  migrateToNewDataStructure = async () => {
    try {
      let calepinageList =
        this.props.moduleData.calpinageData.buffer.calepinage.list;

      if (calepinageList[0]) {
        if (calepinageList[0].PlacoSolutionId) {
          return this.groupWallsDataFromHistory();
        }
      } else {
        let wallRows = flattenDeep(
          map(calepinageList, (ele) => {
            return ele;
          })
        );

        let solutionIds = uniq(
          flattenDeep(
            map(wallRows, (ele) => {
              return ele.PlacoSolution.MappedSystem.oid;
            })
          )
        );
        console.log("in line 878");

        const extractedSolutionProduct = await fetchPlacoDataInGroupSplit(
          solutionIds,
          this.props.config
        )
          .then(({ extractedSolutionProduct }) => {
            return extractedSolutionProduct;
          })
          .catch((error) => {
            console.log("error in 891:", error);

            return Promise.reject({
              code: errorCodeKey.PB_LT_H_001,
              stack: error.stack,
            });
          });

        let grpWallsData: any[] = [];
        forEach(wallRows, (walls, index) => {
          let oneValue = find(grpWallsData, {
            LevelId: walls.LevelId,
            Zone: !isUndefined(walls.zone) ? walls.zone : walls.Zone,
            WallType: walls.WallType,
            Height: walls.Height,
            PlacoSolution: walls.PlacoSolution.MappedSystem.longName
              ? walls.PlacoSolution.MappedSystem.longName
              : walls.Options.MappedSystem.translation,
          });

          if (oneValue) {
            let ids = oneValue.Ids;
            ids.push(walls.Id);
            find(grpWallsData, {
              LevelId: walls.LevelId,
              Zone: !isUndefined(walls.zone) ? walls.zone : walls.Zone,
              WallType: walls.WallType,
              Height: walls.Height,
              PlacoSolution: walls.PlacoSolution.MappedSystem.longName
                ? walls.PlacoSolution.MappedSystem.longName
                : walls.Options.MappedSystem.translation,
            }).Ids = ids;
          } else {
            grpWallsData.push({
              Height: walls.Height,
              LevelName: walls.LevelName,
              PlacoSolution: walls.PlacoSolution.MappedSystem.longName
                ? walls.PlacoSolution.MappedSystem.longName
                : walls.Options.MappedSystem.translation,
              PlacoSolutionId: walls.PlacoSolution.MappedSystem.oid,
              PlacoSolutionHeight:
                walls.PlacoSolution.MappedSystem["GFR-Height limit in m"],
              Zone: !isUndefined(walls.zone) ? walls.zone : walls.Zone,
              WallType: walls.WallType,
              LevelElevation: walls.LevelElevation,
              LevelId: walls.LevelId,
              Ids: [walls.Id],
              montant: walls.montant,
              plaque: walls.plaque,
            });
          }
        });

        const proceessWalls = this.processNewWallData(
          grpWallsData,
          extractedSolutionProduct
        );

        /// 7. Set state for wallsData, SolutionProducts
        this.setState({
          wallsData: proceessWalls,
          extractedSolutionProduct: extractedSolutionProduct,
          loading: false,
        });
      }
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  processNewWallData = (preWallData: any, extractedSolutionProduct: any[]) => {
    return map(preWallData, (wallObject: any, index: number) => {
      const extractedSolutionProducts = find(
        extractedSolutionProduct,
        function (o: any) {
          return o.solutionProduct.oid === wallObject.PlacoSolutionId;
        }
      );

      let fetIsLayout: boolean = true,
        fetIsPlaco: boolean = true,
        fetIsChk: boolean = true,
        plaqsArray: any[] = [],
        montantsArray: any[] = [];

      plaqsArray = filter(extractedSolutionProducts?.products, {
        type: "plaque",
      });

      map(plaqsArray, (plq, index) => {
        remove(plq.articles, function (n: any) {
          return startsWith(n.value, "PPM");
        });
        return plq;
      });
      montantsArray = filter(extractedSolutionProducts?.products, {
        type: "montant",
      });
      if (
        !wallObject.PlacoSolutionId.includes("custom") &&
        extractedSolutionProducts?.layoutPossible.toLowerCase() === "true"
      ) {
        fetIsLayout = true;
        fetIsPlaco = true;

        if (isEmpty(wallObject.plaque)) {
          wallObject.plaque = plaqsArray.map((item) =>
            plaqueDefault(
              item.articles,
              `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
            )
          );
          //filter out the undefined values if any
          wallObject.plaque = wallObject.plaque.filter((plq : any) => plq !== undefined);
        }

        if (isEmpty(wallObject.montant)) {
          wallObject.montant = montantsArray.map((item) =>
            montantDefault(
              item.articles,
              `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
            )
          );
        }
      } else {
        fetIsLayout = false;
        if (wallObject.PlacoSolutionId.includes("custom")) {
          fetIsChk = false;
          fetIsPlaco = false;
          wallObject.PlacoSolutionHeight = "";
          wallObject.plaque = [];
          wallObject.montant = [];
        } else {
          if (isEmpty(wallObject.plaque)) {
            wallObject.plaque = plaqsArray.map((item) =>
              plaqueDefault(
                item.articles,
                `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
              )
            );
          }

          if (isEmpty(wallObject.montant)) {
            wallObject.montant = montantsArray.map((item) =>
              montantDefault(
                item.articles,
                `${round(parseFloat(wallObject.Height) - 0.01, 2)}`
              )
            );
          }
        }
      }

      wallObject.layoutPossible = fetIsLayout;
      wallObject.placo = fetIsPlaco;
      wallObject.plaqueArray = plaqsArray;
      wallObject.montantArray = montantsArray;
      wallObject.installationType = extractedSolutionProducts?.installationType;
      wallObject.supportCeiling = extractedSolutionProducts?.supportCeiling;
      wallObject.chk = wallObject.chk === undefined ? fetIsChk : wallObject.chk;

      if (wallObject.installationType && wallObject.supportCeiling) {
        wallObject.x = wallObject.x ? wallObject.x : "";
        wallObject.y = wallObject.y ? wallObject.y : "";
      }

      return wallObject;
    });
  };

  setCheckedWalls = async (
    wallType: string,
    height: string,
    LevelName: string,
    value: boolean
  ) => {
    this.setScrollPosition();
    this.setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 5));

    let wallsDeepData = JSON.parse(JSON.stringify(this.state.wallsData));
    wallsDeepData = map(wallsDeepData, (wall: any, ind: any) => {
      if (
        wall.WallType === wallType &&
        wall.Height === height &&
        wall.LevelName === LevelName
      ) {
        wall.chk = value;
      }
      return wall;
    });

    await Promise.resolve(
      this.setState({ wallsData: wallsDeepData, loading: false })
    );

    // const updatedCheckedWalls: any = this.state.checkedWalls;
    // updatedCheckedWalls[id] = value;

    // this.setState({ checkedWalls: updatedCheckedWalls });

    if (
      this.state.ref !== null &&
      localStorage.getItem("scrollConfigPosition")
    ) {
      const divElement: any = this.state.ref;
      divElement.scroll({
        top: Number(localStorage.getItem("scrollConfigPosition")),
        left: 0,
        behaviour: "auto",
      });
      localStorage.removeItem("scrollConfigPosition");
    }
  };

  setCheckedCeilings = async (
    ceilingType: string,
    height: string,
    LevelName: string,
    value: boolean
  ) => {
    this.setScrollPosition();
    this.setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 5));

    let wallsDeepData = JSON.parse(JSON.stringify(this.state.ceilingsData));

    wallsDeepData = map(wallsDeepData, (wall: any, ind: any) => {
      if (
        wall.CeilingType === ceilingType &&
        wall.Height === height &&
        wall.LevelName === LevelName
      ) {
        wall.chk = value;
      }
      return wall;
    });

    await Promise.resolve(
      this.setState({ ceilingsData: wallsDeepData, loading: false })
    );

    if (
      this.state.ref !== null &&
      localStorage.getItem("scrollConfigPosition")
    ) {
      const divElement: any = this.state.ref;
      divElement.scroll({
        top: Number(localStorage.getItem("scrollConfigPosition")),
        left: 0,
        behaviour: "auto",
      });
      localStorage.removeItem("scrollConfigPosition");
    }
  };

  componentDidMount = async () => {
    if (this.props.moduleData.calpinageData.buffer.time) {
      if (
        Object.keys(this.props.moduleData.calpinageData.buffer.calepinage.list)
          .length === 0
      ) {
        await this.groupWallsDataFromSelection();
        await this.groupCeilingsDataFromSelection(); // Temp hide for Prod deployment
      } else {
        await this.groupWallsDataFromHistory();
        await this.groupCeilingsDataFromHistory(); // Temp hide for Prod deployment
      }
    } else {
      await this.groupWallsDataFromSelection();
      await this.groupCeilingsDataFromSelection(); // Temp hide for Prod deployment
    }

    if (
      this.state.wallsData.some(
        (w: any) =>
          w.chk === true && (w.layoutPossible === false || w.placo === false)
      ) ||
      this.state.ceilingsData.some(
        (c: any) =>
          c.chk === true && (c.layoutPossible === false || c.placo === false)
      )
    ) {
      this.setState({ showNonLayoutPossible: true });
    }
  };

  saveWallsData = () => {
    const clearedWallsData = map(this.state.wallsData, (wld, ind) =>
      omit(wld, ["montantArray", "plaqueArray"])
    );

    const clearedCeilingsData = map(this.state.ceilingsData, (wld, ind) =>
      omit(wld, ["montantArray", "plaqueArray"])
    );

    this.props.setCalepinage({
      status: true,
      list: { walls: clearedWallsData, ceilings: clearedCeilingsData },
      version: 1,
    });
    this.props.setRoute(Routes.CALPINAGE_HOME);
  };

  /**
   * Show config for solution
   * @param visible hide/unhide the config
   * @param id solution id
   */
  ShowConfig = async (id: string) => {
    this.setScrollConfigPosition();
    try {
      this.setState({
        loading: true,
      });
      console.log("in line 1199");

      const { extractedSolutionProduct } =
        this.props.selectorType === "wall"
          ? await fetchPlacoDataInGroupSplit([id], this.props.config).catch(
              (error) => {
                console.log("error in 1210:", error);

                return Promise.reject({
                  code: errorCodeKey.PB_LT_H_001,
                  stack: error.stack,
                });
              }
            )
          : await fetchPlacoCeilingDataInGroupSplit(
              [id],
              this.props.config
            ).catch((error) => {
              console.log("error in 1222:", error);

              return Promise.reject({
                code: errorCodeKey.PB_LT_H_001,
                stack: error.stack,
              });
            });

      let extData = extractedSolutionProduct[0];

      this.props.selectBuffer(extData.solutionProduct);
      this.setState({
        loading: false,
      });
      this.setState({ configVisible: true });
    } catch (error: any) {
      this.props.onError(error.code, error.stack, true);
    }
  };

  setPositionOnHover() {
    if (localStorage.getItem("scrollConfigPosition")) {
      setTimeout(() => {
        const refValue: any = this.state.ref;
        refValue?.scroll({
          top: Number(localStorage.getItem("scrollConfigPosition")),
          left: 0,
          behaviour: "auto",
        });
        localStorage.removeItem("scrollConfigPosition");
      });
    }
  }

  sideA = (systemConf: any) => {
    if (systemConf.lining)
      return (
        <Segment basic>
          <Checkbox
            checked
            label={this.props.wording.lining[this.props.language]}
            readOnly
          />
          <br />
          <br />
        </Segment>
      );
    return (
      <Segment basic>
        <Header>{this.props.wording.sideA[this.props.language]}</Header>
        <Table celled>
          <Table.Body>
            {systemConf.E1_Name !== "None"
              ? this.row(systemConf.E1_Name, systemConf.E1)
              : null}
            {systemConf.E2_Name !== "None"
              ? this.row(systemConf.E2_Name, systemConf.E2)
              : null}
            {systemConf.E3_Name !== "None"
              ? this.row(systemConf.E3_Name, systemConf.E3)
              : null}
          </Table.Body>
        </Table>
        <br />
      </Segment>
    );
  };

  sideB = (systemConf: any) => {
    if (systemConf.lining) return <div></div>;
    return (
      <Segment basic>
        <Header>{this.props.wording.sideB[this.props.language]}</Header>
        <Table celled>
          <Table.Body>
            {systemConf.I1_Name !== "None"
              ? this.row(systemConf.I1_Name, systemConf.I1)
              : null}
            {systemConf.I2_Name !== "None"
              ? this.row(systemConf.I2_Name, systemConf.I2)
              : null}
            {systemConf.I3_Name !== "None"
              ? this.row(systemConf.I3_Name, systemConf.I3)
              : null}
          </Table.Body>
        </Table>
        <br />
      </Segment>
    );
  };

  SAASAD = (systemConf: any) => {
    if (systemConf.SAA)
      return (
        <Segment basic>
          <Checkbox
            checked
            label={this.props.wording.SAA[this.props.language]}
            readOnly
          />
          <br />
          <br />
        </Segment>
      );
    if (systemConf.SAD)
      return (
        <Segment basic>
          <Checkbox
            checked
            label={this.props.wording.SAD[this.props.language]}
            readOnly
          />
          <br />
          <br />
        </Segment>
      );
    return null;
  };

  details = (systemConf: any) => [
    // <SideA systemConf={systemConf} wording={this.props.wording} />,
    this.sideA(systemConf),
    this.sideB(systemConf),
    this.SAASAD(systemConf),
  ];

  row = (key: any, value: string) => {
    if (key) {
      return (
        <Table.Row key={key}>
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{value}</Table.Cell>
        </Table.Row>
      );
    }
    return null;
  };

  setScrollPosition = () => {
    if (this.state.ref !== null) {
      const refValue: any = this.state.ref;

      const scrollPosition: any = refValue?.scrollTop;
      if (scrollPosition)
        localStorage.setItem(
          "scrollConfigPosition",
          scrollPosition?.toString()
        );
    }
  };

  setScrollConfigPosition = () => {
    if (this.state.ref !== null) {
      const refValue: any = this.state.ref;
      const scrollPosition: any = refValue.scrollTop;
      if (scrollPosition) {
        localStorage.setItem(
          "scrollConfigPosition",
          scrollPosition?.toString()
        );
      }
    }
  };

  markSelectedStep = (stepType: string) => {
    return this.props.selectorType === stepType
      ? {
          width: "300px",
          textAlign: "center",
          padding: "0.5em 1em",
          fontWeight: "bold",
          border: "1px solid rgb(14,110,184)",
          cursor: "pointer",
          backgroundColor: "rgb(14,110,184)",
          color: "#fff",
        }
      : {
          width: "300px",
          textAlign: "center",
          padding: "0.5em 1em",
          fontWeight: "bold",
          border: "1px solid #a9a9a9",
          cursor: "pointer",
          backgroundColor: "#f0f0f0",
          color: "#a9a9a9",
        };
  };

  selectedTab =
    this.props.selectorType === "wall"
      ? this.props.wording.partitionWalls[this.props.language]
      : this.props.wording.ceilings[this.props.language];

  render() {
    const { wording, language, setRoute, setSelectorType } = this.props;
    const {
      defaultWallLayoutPossibleChecked,
      defaultWallNonLayoutPossibleChecked,
      defaultCeilingChecked,
      disabledCheckWall,
      allUnchecked,
      placo100,
      checkXYEmpty,
    } = this.defaultCheckedGroupCheckbox();

    if (this.state.loading) {
      return (
        <Dimmer active page>
          <Loader>{wording.loader[language]}</Loader>
        </Dimmer>
      );
    }

    if (this.state.configVisible) {
      return (
        <SystemConfigurationContainer
          setRoute={setRoute}
          returnRedirect={() => this.setState({ configVisible: false })}
          onError={this.props.onError}
        />
      );
    } else {
      return (
        <div style={{ position: "relative", height: "100%" }}>
          <FunctionalityHeader
            // name={title[language]}
            name={wording.title[language] + " " + this.selectedTab}
            Icon={this.props.Icon}
            //subheader=""
            subheader={wording.subheader[language]}
          />
          <Grid columns={1} stackable>
            <Grid.Row
              columns={2}
              style={{
                marginTop: "10px",
                paddingLeft: "7rem",
                paddingRight: "3rem",
                paddingBottom: "10px",
              }}
            >
              <Grid.Column>
                <Segment
                  style={this.markSelectedStep("wall")}
                  onClick={() => setSelectorType("wall")}
                >
                  {wording.partitionWalls[language]}
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment
                  style={this.markSelectedStep("ceiling")}
                  onClick={() => setSelectorType("ceiling")}
                >
                  {wording.ceilings[language]}
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Column style={{ paddingTop: "10px" }}>
              <div
                style={{
                  padding: "0 10px",
                  overflowY: "auto",
                  height: "calc(100vh - 215px)",
                }}
                ref={this.onRefChange}
                id="divId"
                onMouseOver={() => this.setPositionOnHover()}
              >
                <Modal
                  size={"tiny"}
                  open={this.state.duplicate}
                  onClose={() => this.setState({ duplicate: false })}
                >
                  <Modal.Header
                    style={{
                      textAlign: "center",
                      color: "#2185d0",
                      borderBottom: "none",
                    }}
                  >
                    Conflit de systèmes
                  </Modal.Header>
                  <Modal.Content
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    <p>
                      Vous avez un ou plusieurs systèmes pour le même type de
                      système Revit. Merci de bien vouloir sélectionner un seul
                      système par type.
                    </p>
                  </Modal.Content>
                  <Modal.Actions
                    style={{
                      textAlign: "center",
                      borderTop: "none",
                    }}
                  >
                    <Button
                      primary
                      onClick={() => setRoute(Routes.CALPINAGE_HOME)}
                    >
                      Ok
                    </Button>
                  </Modal.Actions>
                </Modal>

                <Modal
                  size={"tiny"}
                  open={this.state.showNonLayoutPossible}
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                >
                  <Modal.Header
                    style={{
                      textAlign: "center",
                      color: "#2185d0",
                      borderBottom: "none",
                    }}
                  >
                    Calepinage
                  </Modal.Header>
                  <Modal.Content
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    <p>
                      Les solutions en rouge ne sont pas calepinable ou ne sont
                      pas disponibles actuellement.
                    </p>
                  </Modal.Content>
                  <Modal.Actions
                    style={{
                      textAlign: "center",
                      borderTop: "none",
                    }}
                  >
                    <Button
                      primary
                      onClick={() => {
                        // if (this.props.selectorType === "ceiling") {
                        //   this.saveWallsData();
                        // }
                        // else {
                        this.setState({ showNonLayoutPossible: false });
                        //}
                      }}
                    >
                      CONTINUER
                    </Button>
                  </Modal.Actions>
                </Modal>

                {this.props.selectorType === "wall" ? (
                  this.state.wallsData.length === 0 ? (
                    <div
                      style={{
                        padding: 20,
                        overflowY: "auto",
                        height: "62vh",
                        textAlign: "center",
                      }}
                    >
                      {wording.noWall[language]}
                    </div>
                  ) : (
                    <WallTable
                      defaultWallLayoutPossibleChecked={
                        defaultWallLayoutPossibleChecked
                      }
                      defaultWallNonLayoutPossibleChecked={
                        defaultWallNonLayoutPossibleChecked
                      }
                      setUnsetRows={this.setUnsetRows}
                      placo100={placo100}
                      disabledCheckWall={disabledCheckWall}
                      layoutableSolution={this.state.wallsData.filter(
                        (isLayoutPossible: any) => {
                          return isLayoutPossible.layoutPossible === true;
                        }
                      )}
                      nonLayoutableSolution={this.state.wallsData.filter(
                        (nonLayoutable: any) => {
                          return nonLayoutable.layoutPossible === false;
                        }
                      )}
                      language={this.props.language}
                      setRoute={setRoute}
                      setPlaque={this.setPlaque}
                      setMontant={this.setMontant}
                      setCheckedWalls={this.setCheckedWalls}
                      showConfig={this.ShowConfig}
                    />
                  )
                ) : this.state.ceilingsData.length === 0 ? (
                  <div
                    style={{
                      padding: 20,
                      overflowY: "auto",
                      height: "62vh",
                      textAlign: "center",
                    }}
                  >
                    {wording.noCeiling[language]}
                  </div>
                ) : (
                  <CeilingTable
                    defaultChecked={defaultCeilingChecked}
                    setUnsetRows={this.setUnsetRows}
                    placo100={placo100}
                    ceilingsData={this.state.ceilingsData}
                    language={this.props.language}
                    setRoute={setRoute}
                    setPlaque={this.setPlaque}
                    setMontant={this.setMontant}
                    setCheckedCeilings={this.setCheckedCeilings}
                    showConfig={this.ShowConfig}
                    setCeilingX={this.setCeilingX}
                    setCeilingY={this.setCeilingY}
                  />
                )}

                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    position: "fixed",
                    bottom: 15,
                    width: "100%",
                  }}
                >
                  <span>
                    <Button
                      color="orange"
                      onClick={() => {
                        if (this.props.selectorType === "ceiling") {
                          setSelectorType("wall");
                        } else {
                          this.props.setHistoryPath("calpinagesolution");
                          setRoute(Routes.CALPINAGE_HOME);
                        }
                      }}
                      content={wording.back[language]}
                    ></Button>
                  </span>
                  <span>
                    <Button
                      color="blue"
                      disabled={
                        this.props.selectorType === "ceiling"
                          ? // ? allUnchecked || checkXYEmpty
                            allUnchecked
                          : false
                      }
                      onClick={() => {
                        if (this.props.selectorType === "ceiling") {
                          this.props.setHistoryPath("calpinagesolution");
                          this.nonLayoutPossiblePropmpt();
                          removeCloseWindow();
                          if (localStorage.getItem("layoutStep2")) {
                            localStorage.removeItem("layoutStep2");
                          }
                        } else {
                          setSelectorType("ceiling");
                        }
                      }}
                      // content={wording.continue[language]}
                      content={
                        this.props.selectorType === "wall"
                          ? wording.continue[language]
                          : wording.validate[language]
                      }
                    ></Button>
                  </span>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      );
    }
  }
}

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { setRoute: any }
) => ({
  Icon: state.functionalityIcon,
  moduleData: state.moduleData,
  language: state.language,
  setRoute: ownProps.setRoute,
  selections: state.selections,
  config: state.config,
});

const mapDispatchToProps = {
  setCalepinage,
  resetCalepinageSelection,
  selectBuffer,
  setHistoryPath,
  setSelectorType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SolutionTable);
