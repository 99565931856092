export enum APP_WINDOW_NAME {
  LOAD_SYSTEM_WINDOW = "LOAD_SYSTEM_WINDOW",
  DETAILS_SYSTEM_WINDOW = "DETAILS_SYSTEM_WINDOW",
  DETAILS_CART_WINDOW = "DETAILS_CART_WINDOW",
  CONFIGURATION_CART_WINDOW = "CONFIGURATION_CART_WINDOW",
}

export const BOILERPLATE_HEADER_TEXT = "Boilerplate header text";
export const SYSTEM_PROPERTIES = "SYSTEM_PROPERTIES";

export const CLOISONS = "Cloisons";
export const PLAFONDS = "Plafonds";
export const DOUBLAGES = "Doublages";

export const CLOISONS_ID = "wall";
export const PLAFONDS_ID = "ceiling";
export const DOUBLAGES_ID = "liningwall";
export const GAINES_ID = "gainestechniqueswall";

const REACT_APP_BDDURL =
  "https://uat.bimdatabaseapi.saint-gobain.com:9551/sgdsi/test/bimdatabase-api/v1/image/5f0e59fcd497d20006257e6f?filename=sp00002232-cloison-aquarocR-12070---ei-90---52db---415m.jpg&size=THUMBNAIL";
export const baseImageUrl = REACT_APP_BDDURL.replace(
  "/plugins/authenticated",
  ""
);

export const DISTRIBUTIVE_STANDARD = "Distributive standard";
export const DISTRIBUTIVE_OU_SEPARATIVE_GRANDE_HAUTEUR = "Distributive ou séparative grande hauteur";
export const SEPARATIVE_STANDARD_SAA = "Séparative standard SAA";
export const SEPARATIVE_STANDARD_SAD = "Séparative standard SAD";
export const SEPARATIVE_STANDARD = "Séparative standard";
export const ISOLATION_DES_MURS = "Isolation des murs";

export enum FILTER_VALUE_TYPE {
  NUMERIC = "Numeric",
  TEXT = "Text",
}

export enum FILTER_CRITERION_TYPE {
  INCLUDES = "Includes",
  BETWEEN = "Between",
  EQUALS = "Equals",
  GREATERTHEN = "GreaterThen",
}

export enum FILTER_CHOICE_TYPE {
  CHECKBOX = "CheckBox",
  SLIDER = "Slider",
  RADIOBOX = "RadioBox",
}

export enum SELECTOR_FILTER_TYPE {
  SEARCHBAR = 'Search bar',
  selectionlistsingle = 'Selection List (single value)',
  selectionlistmultiple = 'Selection List (multiple value)',
  checkbox = 'Checkbox',
  slideronevalue = 'Slider (1 value)',
  sliderminmaxvalue = 'Slider (Min/Max value)',
  ratingstar = 'Rating/Star',
  date = 'Date',
  numbericinput = 'Numeric Input',
}

export const systemTypes = [
  {
    label: CLOISONS,
    value: CLOISONS_ID,
    onSelect: (products: any[]) =>
      products.filter(
        (product) =>
          product.longName.startsWith("Cloison")
        // &&  !contains(product.longName, ["Cinéstil", "Caro", "Mega"])
      ),
  },
  {
    label: PLAFONDS,
    value: PLAFONDS_ID,
    onSelect: (products: any[]) =>
      products.filter((product) => product.longName.startsWith("Plafond")),
  },
  {
    label: DOUBLAGES,
    value: DOUBLAGES_ID,
    onSelect: (products: any[]) =>
      products.filter((product) => product.longName.startsWith("Doublage")),
  },
];

function contains(target: { includes: (arg0: any) => number }, pattern: any[]) {
  var value = 0;
  pattern.forEach(function (word) {
    value = value + target.includes(word);
  });
  return value === 1;
}
