import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Checkbox,
  Progress,
  List,
  Message,
  Icon,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import "./index.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Routes } from "./root";
import { MyConfig } from "../../../../Helper";
import { ModelGeneratorStore } from "../../Store/Reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessData,
  getFormat,
  getGeneric,
  getSource,
  getType,
  getVersion,
} from "../API/ObjectGeneratorApi";
import {
  FormatModel,
  SourceModel,
  VersionModel,
} from "../../Shared/models/Criteria";
import {
  SetResetCriteria,
  setSelectBusiness,
  SetSelectBusinessContainerId,
  SetSelectBusinessTechnicalName,
  SetSelectEnvironment,
  SetSelectFormat,
  SetSelectGeneric,
  SetSelectLanguage,
  SetSelectMapping,
  SetSelectRegion,
  SetSelectSource,
  SetSelectType,
  SetSelectVersion,
  SetIsCriteriaValid,
  setSelectedRegionName,
} from "../../Store/Actions/criteriaAction";
import {
  setPimProductsList,
  setProductFilters,
  setProductsList,
  SetResetProductsList,
} from "../../Store/Actions/productAction";
import { myConfigSync } from "../../Shared/myConfig";
import {
  FORMAT,
  Source,
  TechnicalName,
  Version,
} from "../../Shared/constants/criteria";
import { getBusinessProducts, ValidationObjects } from "../API/BimDatabaseApi";
import { api } from "../../../../RevitJS/API";
import CustomLoader from "../../Shared/Loader/CustomLoader";
import { setInfoStatus } from "../../Store/Actions/infoAction";
import { asyncForEach } from "../../../PlacoBIMv3/Selection/Actions";
import { Block } from "@material-ui/icons";

interface Props {
  setRoute: any;
}
let abort = false;
const ObjectGenerator = (props: Props) => {
  const reduxState = useSelector((state: ModelGeneratorStore) => state);
  const dispatch = useDispatch();
  const [showLanguageOption, setShowLanguageOption] = useState<boolean>(true);
  const [showMapping, setShowMapping] = useState<boolean>(true);
  const [showGeneric, setShowGeneric] = useState<boolean>(false);
  const [sourcesList, setSourcesList] = useState<SourceModel[]>([]);
  const [formatListAll, setFormatListAll] = useState<FormatModel[]>([]);
  const [versionListAll, setVersionListAll] = useState<VersionModel[]>([]);
  const [businessDataList, setBusinessDataList] = useState<any[]>([]);
  const [businessList, setBusinessList] = useState<any[]>([]);
  const [typeListAll, setTypeListAll] = useState<any[]>([]);
  const [languageList, setLanguageList] = useState<any[]>([]);
  const [mappingList, setMappingList] = useState<any[]>([]);
  const [regionList, setRegionList] = useState<any[]>([]);
  const [genericList, setGenericList] = useState<any[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedMapping, setSelectedMapping] = useState();
  const [showMasssGenerator, setShowMasssGenerator] = useState<boolean>(false);
  const [masssGeneratorPercent, setMasssGeneratorPercent] = useState(0);
  const [totalObject, setTotalObject] = useState(0);
  const [batchSize, setBatchSize] = useState(0);

  const [totalGenerated, setTotalGenerated] = useState(0);
  const [totalGeneratedSuccess, setTotalGeneratedSuccess] = useState(0);
  const [totalGeneratedFail, setTotalGeneratedFail] = useState(0);
  const [showInformationModal, setShowInformationModal] =
    useState<boolean>(false);
  const [config, setConfig] = useState({ REACT_APP_SERVERURL: "" });
  const [directoryCopy, setDirectoryCopy] = useState("");
  const [generationDone, setGenerationDone] = useState(false);

  const [showAbortgeneration, setShowAbortgeneration] = useState(false);
  const [fileName, setFileName] = useState("");

  const Configuration = myConfigSync();

  useEffect(() => {
    async function getConfig() {
      const cng = await MyConfig();
      setConfig(cng);
    }

    console.log("ObjectGenerator Component did mount");
    console.log(reduxState);
    dispatch(
      setInfoStatus({
        infoType: "Information",
        isLoading: true,
        openInfo: true,
        infoText: "Validating User. Please wait...",
      })
    );
    loadBusinessData();
    setSourcesList(getSource().Data);
    setFormatListAll(getFormat().Data);
    setVersionListAll(getVersion().Data);
    setGenericList(getGeneric().Data);
    setTypeListAll(getType().Data);

    dispatch(SetResetCriteria());
    dispatch(SetSelectEnvironment(Configuration.REACT_APP_MODEL_GEN_ENV));
    getConfig();

    dispatch(
      setInfoStatus({
        infoType: "Information",
        isLoading: false,
        openInfo: false,
        infoText: "",
      })
    );

    localStorage.setItem("countGenerated", "");
    localStorage.setItem("countSuccessGenerated", "");
    localStorage.setItem("countFailGenerated", "");
    localStorage.setItem("totalObject", "");

    const interval = setInterval(() => {
      console.log("This will run every minute!");
      let countGenerated = localStorage.getItem("countGenerated")
        ? (localStorage.getItem("countGenerated") as string)
        : "";
      let countSuccessGenerated = localStorage.getItem("countSuccessGenerated")
        ? (localStorage.getItem("countSuccessGenerated") as string)
        : "";
      let countFailGenerated = localStorage.getItem("countFailGenerated")
        ? (localStorage.getItem("countFailGenerated") as string)
        : "";
      let totalObjects = localStorage.getItem("totalObject")
        ? (localStorage.getItem("totalObject") as string)
        : "";
      if (
        countSuccessGenerated !== "" &&
        countFailGenerated !== "" &&
        countGenerated !== "" &&
        totalObjects !== ""
      ) {
        console.log("countGenerated =" + countGenerated);
        console.log("countSuccessGenerated =" + countSuccessGenerated);
        console.log("countFailGenerated =" + countFailGenerated);
        console.log("totalObjects =" + totalObjects);
        setTotalGeneratedSuccess(parseInt(countSuccessGenerated));
        setTotalGeneratedFail(parseInt(countFailGenerated));
        setTotalGenerated(parseInt(countGenerated));

        let percentNumber =
          Math.round((parseInt(countGenerated) / parseInt(totalObjects)) * 100);
        console.log("percentNumber =" + percentNumber);
        setMasssGeneratorPercent(percentNumber);
      } else {
        console.log(
          "countGenerated & countSuccessGenerated & countFailGenerated are null"
        );
        console.log("countGenerated =" + countGenerated);
        console.log("countSuccessGenerated =" + countSuccessGenerated);
        console.log("countFailGenerated =" + countFailGenerated);
        console.log("totalObjects =" + totalObjects);
      }
    }, 1000 * 60 * 1);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBusinessData = () => {
    getBusinessData().then((obj) => {
      if (obj.status === true) {
        if (obj.data.length > 0) {
          setBusinessDataList(obj.data);
          let businessList: any[] = [];
          obj.data.map((biz: any) => {
            businessList.push({
              key: biz.id,
              value: `${biz.id}+${biz.containers[0]}`,
              text: biz.name,
              oid: biz.oid,
            });
            return biz;
          });

          const businessListFiltered = businessList;
          setBusinessList(businessListFiltered);

          localStorage.setItem(
            "bimDatabaseBusinessList",
            JSON.stringify(businessList)
          );
        }
      } else if (obj.status === false) {
        console.log(obj.statusText);
      }
    });
  };

  const onChangeSource = (ev: any, data: any) => {
    dispatch(SetResetCriteria());
    dispatch(SetResetProductsList());
    dispatch(SetSelectSource(data.value));
    dispatch(SetSelectEnvironment(Configuration.REACT_APP_MODEL_GEN_ENV));

    if (data.value !== Source.EXCEL) {
      setShowLanguageOption(true);
    } else {
      setShowLanguageOption(false);
    }
  };

  const onHelpClick = () => {
    setShowInformationModal(!showInformationModal);
  };
  const onChangeLanguage = (ev: any, data: any) => {
    setSelectedLanguage(data.value);
    dispatch(SetSelectLanguage(data.value));
  };
  const onChangeFormat = (ev: any, data: any) => {
    dispatch(SetSelectFormat(data.value));
  };

  const onChangeGeneric = (ev: any, data: any) => {
    dispatch(SetSelectGeneric(data.value));
  };

  const onChangeHandler = () => {};

  const onChangeVersion = (ev: any, data: any) => {
    dispatch(SetSelectVersion(data.value));
  };

  const onChangeType = (ev: any, data: any) => {
    dispatch(SetSelectType(data.value));
  };

  const onChangeBusiness = (ev: any, data: any) => {
    const value = data.value;
    let businessid = value.split("+")[0];
    dispatch(setSelectBusiness(value));
    dispatch(SetSelectBusinessContainerId(businessid));
    setFileName(ev.target.innerText);

    let languageList: any[] = [];
    let mappingListe: any[] = [];
    let regionListe: any[] = [];

    businessDataList
      .find((a) => a.id === businessid)
      .mappingList.map((item: any) => {
        mappingListe.push({
          key: item.mappingId,
          text: item.mappingName,
          value: item.mappingId,
        });
        return item;
      });

    setMappingList(mappingListe);

    let biz = businessDataList.find((a) => a.id === businessid);
    if (reduxState.CriteriaObj.selectedSource !== Source.EXCEL) {
      let langList =
        reduxState.CriteriaObj.selectedSource === Source.PIM
          ? biz.pimLanguageList
          : biz.bimLanguageList;
      langList.map((item: any) => {
        languageList.push({
          key: item.languageCode,
          text: item.language,
          value: item.languageCode,
        });
        return item;
      });
    }
    setLanguageList(languageList);
    dispatch(SetSelectBusinessTechnicalName(biz.technicalName));

    businessDataList
      .find((a) => a.id === businessid)
      .regionList.map((item: any) => {
        regionListe.push({
          key: item.regionId,
          text: item.region,
          value: item.regionId,
        });
        return item;
      });

    setRegionList(regionListe);
    const bimDatabaseBusinessObj = businessDataList.find(
      (business) => business.id === businessid
    );

    if (bimDatabaseBusinessObj) {
      if (
        bimDatabaseBusinessObj.technicalName === TechnicalName.PAM ||
        bimDatabaseBusinessObj.technicalName === TechnicalName.PAM_INTERNATIONAL
      ) {
        setShowMapping(false);
        setShowGeneric(true);

        const versionListFiltered = versionListAll.filter(
          (verison) =>
            verison.key === Version.v2024 ||
            verison.key === Version.v2021 ||
            verison.key === Version.v2022 ||
            verison.key === Version.v2023
        );
        setVersionListAll(versionListFiltered);
      } else {
        setShowMapping(true);
        setShowGeneric(false);
      }
    }
  };

  const onChangeRegion = (ev: any, data: any) => {
    setSelectedRegion(data.value);
    dispatch(SetSelectRegion(data.value));

    var region = regionList.filter((a) => a.key === data.value)[0];
    dispatch(setSelectedRegionName(region.text));

    const date = new Date();
    setFileName(fileName + "_" + ev.target.innerText + "_" + date.getTime());
  };

  const onChangeMapping = (ev: any, data: any) => {
    setSelectedMapping(data.value);
    dispatch(SetSelectMapping(data.value));
  };

  const onCriteriaValidation = () => {
    let rtn = true;
    if (reduxState.CriteriaObj.selectedSource !== null) {
      switch (reduxState.CriteriaObj.selectedSource) {
        case Source.EXCEL:
          rtn = true;
          break;
        case Source.PIM:
          if (
            reduxState.CriteriaObj.selectedMapping === null ||
            reduxState.CriteriaObj.selectedBusiness === null ||
            reduxState.CriteriaObj.selectedLanguage === null
          ) {
            rtn = false;
          }
          break;
      }
      if (reduxState.CriteriaObj.selectedFormat === null) {
        rtn = false;
      } else {
        if (reduxState.CriteriaObj.selectedFormat === FORMAT.REVIT) {
          if (reduxState.CriteriaObj.selectedVersion === null) {
            rtn = false;
          }
        }
      }
    } else {
      rtn = false;
    }
    return rtn;
  };

  const onMassGeneratorClick = async () => {
    let rtn = onCriteriaValidation();
    if (rtn) {
      await onMassBIMDBExtraction();
    } else {
      dispatch(
        SetIsCriteriaValid({
          status: false,
          text: "No criteria have been selected",
        })
      );
      dispatch(
        setInfoStatus({
          infoType: "Information",
          isLoading: false,
          openInfo: false,
          infoText: "",
        })
      );
    }
  };

  const onMassBIMDBExtraction = () => {
    dispatch(
      SetIsCriteriaValid({
        status: true,
        text: "Extracting data based on the selected business...",
      })
    );
    dispatch(
      setInfoStatus({
        infoType: "Information",
        isLoading: true,
        openInfo: true,
        infoText: "Extracting data based on the selected criteria...",
      })
    );


    getBusinessProducts(
      reduxState.CriteriaObj.selectedBusiness,
      reduxState.CriteriaObj.selectedRegion,
      reduxState.CriteriaObj.selectedLanguage,
      reduxState.CriteriaObj.selectedRegionName,
      reduxState.CriteriaObj.selectedType
    ).then(async (obj) => {
      if (obj.status === true) {
        if (obj.data.list.length > 0) {
          console.log("Okay");
          obj.data.list.map(function (el: any) {
            el.isSelected = false;
            el.isProcessed = false;
            return el;
          });
          let productFilters = obj.data.filters;
          dispatch(setPimProductsList(obj.data.list));
          dispatch(setProductsList(obj.data.list));
          dispatch(setProductFilters(productFilters));
          console.log(reduxState);
          dispatch(
            setInfoStatus({
              infoType: "Information",
              isLoading: true,
              openInfo: true,
              infoText: "Validating BIM Data...",
            })
          );

          let setObject: any[] = [];
          let count: any = 0;
          let d = obj.data.list;
          let batchSizeCount = 0;
          await asyncForEach(d, async (element: any, index: number) => {
            setObject.push(element);
            if (abort) {
              setGenerationDone(true);
              return false;
            }
            count++;

            if (setObject.length === 20 || count === d.length) {
              batchSizeCount++;
              await ValidationObjects(
                // obj.data.list.slice(0,5),
                setObject,
                reduxState.CriteriaObj.selectedLanguage,
                reduxState.CriteriaObj.selectedMapping,
                reduxState.CriteriaObj.selectedBusiness,
                reduxState.CriteriaObj.selectedFormat,
                reduxState.CriteriaObj.selectedVersion,
                reduxState.CriteriaObj.selectedGeneric,
                reduxState.CriteriaObj.selectedRegion,
                reduxState.CriteriaObj.selectedRegionName
              ).then(async (obj) => {
                try {
                  console.log(obj);
                  setObject = [];
                  if (obj.status === true) {
                    obj.data.products = obj.data.products.filter((p: any)=> p.errors.length === 0);
                    if (obj.data.products.length > 0) {
                     
                      
                      const productsNum = obj.data.products.length;
                      setTotalObject(obj.data.products.length);
                      setBatchSize(batchSizeCount);
                      localStorage.setItem("totalObject", productsNum);

                      // dispatch(setInfoStatus({
                      //   infoType: "Information",
                      //   isLoading: true,
                      //   openInfo: true,
                      //   infoText: "Generating BIM Objects..."
                      // }))
                      dispatch(
                        setInfoStatus({
                          infoType: "Information",
                          isLoading: false,
                          openInfo: false,
                          infoText: "",
                        })
                      );

                      // setTotalGenerated(obj.data.products.length)
                      setShowMasssGenerator(true);

                      let token = localStorage.getItem("token")
                        ? (localStorage.getItem("token") as string)
                        : "";

                      await api.queries.getHelloWord(
                        JSON.stringify(obj.data),
                        fileName,
                        Configuration.REACT_APP_MODEL_GEN_BIMDATABASE_SCHEDULE_API_URL,
                        token
                      );

                      let myvar2 = localStorage.getItem("myvar2");
                      if (myvar2 !== null || myvar2 !== "") {
                        let datas = JSON.parse(myvar2 as string);
                        setDirectoryCopy(datas.OutputPath);
                      }

                      // dispatch(setInfoStatus({
                      //   infoType: "Information",
                      //   isLoading: false,
                      //   openInfo: false,
                      //   infoText: ""
                      // }))

                      setShowMasssGenerator(true);
                      // let dataFolder: any = await api.queries.generateObjects(JSON.stringify(obj.data));
                      if (count === d.length) {
                        setGenerationDone(true);
                      }
                    }
                  } else {
                    dispatch(
                      setInfoStatus({
                        infoType: "Information",
                        isLoading: false,
                        openInfo: false,
                        infoText: "",
                      })
                    );
                    alert(obj.statusText);
                  }
                } catch (error) {
                  dispatch(
                    setInfoStatus({
                      infoType: "Information",
                      isLoading: false,
                      openInfo: false,
                      infoText: "",
                    })
                  );
                  alert(error);
                }
              });
            }
          });
        } else {
          console.log("No Data");

          dispatch(
            setInfoStatus({
              infoType: "Information",
              isLoading: false,
              openInfo: false,
              infoText: "",
            })
          );
          alert("No Data");
        }
      } else if (obj.status === false) {
        dispatch(
          setInfoStatus({
            infoType: "Information",
            isLoading: false,
            openInfo: false,
            infoText: "",
          })
        );
        alert(obj.statusText);
      }
    });
  };

  const languageDiv = (
    <Form.Select
      id="language"
      label="Language"
      options={languageList}
      placeholder="Language"
      value={selectedLanguage}
      onChange={onChangeLanguage}
    />
  );

  const fileSelectionDiv = (
    <Form.Group widths="equal">
      <Form.Input
        type="file"
        fluid
        label="Select File"
        onChange={onChangeHandler}
        id="fileUpload"
        className="fileUpload"
      />
    </Form.Group>
  );

  const formatDiv = (
    <Form.Select
      id="format"
      label="Format"
      options={formatListAll}
      placeholder="Format"
      value={reduxState.CriteriaObj.selectedFormat}
      onChange={onChangeFormat}
    />
  );

  const versionDiv = (
    <Form.Select
      className={
        reduxState.CriteriaObj.selectedFormat !== FORMAT.REVIT ? "hide" : ""
      }
      id="version"
      label="Version"
      options={versionListAll}
      placeholder="Version"
      value={reduxState.CriteriaObj.selectedVersion}
      onChange={onChangeVersion}
    />
  );

  const genericDiv = (
    <Form.Select
      className={
        reduxState.CriteriaObj.selectedFormat !== FORMAT.REVIT
          ? "hide"
          : "fullWidth"
      }
      id="generic"
      label="Generic/Manufacturer"
      options={genericList}
      placeholder="Generic/Manufacturer"
      value={reduxState.CriteriaObj.selectedGeneric}
      onChange={onChangeGeneric}
    />
  );

  const mappingDiv = (
    <Form.Group widths={"equal"}>
      <Form.Select
        className={showMapping ? "margin-top-2pc" : "hide"}
        id="mapping"
        label="Mapping"
        options={mappingList}
        placeholder="Mapping"
        value={selectedMapping}
        onChange={onChangeMapping}
      />
    </Form.Group>
  );

  const optionsAfterSourceSelectionWithoutType = (
    <Form.Group widths="equal">
      <Form.Select
        id="business"
        label="Business"
        options={businessList}
        placeholder="Business"
        value={reduxState.CriteriaObj.selectedBusiness}
        onChange={onChangeBusiness}
        className="fullWidth"
      />

      {/* Region */}
      <Form.Select
        id="region"
        label="Region"
        options={regionList}
        placeholder="Region"
        value={selectedRegion}
        onChange={onChangeRegion}
        className="fullWidth"
      />
    </Form.Group>
  );

  const optionsAfterSourceSelectionWithType = (
    <>
      <Form.Group widths="equal">
        <Form.Select
          id="business"
          label="Business"
          options={businessList}
          placeholder="Business"
          value={reduxState.CriteriaObj.selectedBusiness}
          onChange={onChangeBusiness}
          className="fullWidth"
        />
      </Form.Group>
      <Form.Group widths="equal">
        {/* Region */}
        <Form.Select
          id="type"
          label="Type"
          options={typeListAll}
          placeholder="Type"
          value={reduxState.CriteriaObj.selectedType}
          onChange={onChangeType}
          className="fullWidth"
        />
        <Form.Select
          id="region"
          label="Region"
          options={regionList}
          placeholder="Region"
          value={selectedRegion}
          onChange={onChangeRegion}
          className="fullWidth"
        />
      </Form.Group>
    </>
  );

  const emptyDiv = <></>;

  const massGenerationDiv = (
    <>
      <Button
        color="green"
        content={"Mass Generator"}
        style={{ marginRight: "10px" }}
        onClick={onMassGeneratorClick}
      ></Button>
      <Checkbox
        label="Force Update"
        style={{ marginRight: "10px", marginTop: "15px" }}
      />
    </>
  );

  const CriteriaMsgBox = (
    <Message attached="bottom" error>
      <Icon name="attention" /> No records found
    </Message>
  );

  const onHandleExtractClick = () => {
    props.setRoute(Routes.BIM_PRODUCT_LIST);
  };

  const onAbortGenerationClick = () => {
    console.log("onAbortGenerationClick");
    setShowAbortgeneration(true);
  };

  const onAbortGenerationYESClick = () => {
    console.log("Abort YES");
    // let myReturn: any = await api.queries.abortGenerationModGen();
    abort = true;
    setShowAbortgeneration(false);
  };

  const onAbortGenerationNOClick = () => {
    console.log("Abort NO");
    setShowAbortgeneration(false);
  };

  return (
    <>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/ModelGenerator/plugin-logo.png"}
        name={"Object Generator"}
        subheader={"From Database / XLS"}
        config={config.REACT_APP_SERVERURL}
      />
      <Container
        style={{ marginTop: 20, height: "calc(100vh - 120px)", width: "95%" }}
      >
        <CustomLoader
          infoType={reduxState.InfosObj.infoType}
          open={reduxState.InfosObj.openInfo}
          infoText={reduxState.InfosObj.infoText}
        ></CustomLoader>
        <Form className="attached fluid showForm" style={{ height: "85vh" }}>
          {/* SOURCE */}
          <Form.Group widths="two">
            <Form.Select
              id="source"
              label="Source"
              options={sourcesList}
              placeholder="Source"
              value={reduxState.CriteriaObj.selectedSource}
              onChange={onChangeSource}
              className="fullWidth"
            />
            <Form.Button
              className={
                reduxState.CriteriaObj.selectedSource === Source.EXCEL
                  ? "margin-top-2pc"
                  : "hide"
              }
              circular
              color="blue"
              icon="help"
              onClick={onHelpClick}
              style={{
                marginTop: "22px",
              }}
            ></Form.Button>
          </Form.Group>

          {/* Select File */}
          {reduxState.CriteriaObj.selectedSource === Source.EXCEL
            ? fileSelectionDiv
            : emptyDiv}

          {/* Business */}
          {reduxState.CriteriaObj.selectedSource === Source.BIM ? (
            reduxState.CriteriaObj.selectedBusinessTechnicalName ===
              TechnicalName.PAM ||
            reduxState.CriteriaObj.selectedBusinessTechnicalName ===
              TechnicalName.PAM_INTERNATIONAL ? (
              <>
                {optionsAfterSourceSelectionWithType}
                {mappingDiv}
              </>
            ) : (
              <>
                {optionsAfterSourceSelectionWithoutType}
                {mappingDiv}
              </>
            )
          ) : (
            emptyDiv
          )}

          {/* Language */}
          <Form.Group widths="equal">
            {showLanguageOption ? languageDiv : emptyDiv}
            {/* Version */}
            {reduxState.CriteriaObj.selectedSource === Source.EMPTY ||
            reduxState.CriteriaObj.selectedSource === Source.EXCEL ||
            reduxState.CriteriaObj.selectedSource === Source.BIM
              ? formatDiv
              : emptyDiv}
          </Form.Group>

          <Form.Group widths="equal">
            {reduxState.CriteriaObj.selectedSource === Source.EXCEL ||
            reduxState.CriteriaObj.selectedSource === Source.BIM
              ? versionDiv
              : emptyDiv}
            {showGeneric ? genericDiv : emptyDiv}
          </Form.Group>

          <Form.Group
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              width: " 100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 0 10px",
            }}
          >
            {reduxState.CriteriaObj.selectedSource === Source.BIM
              ? massGenerationDiv
              : emptyDiv}

            <Button
              color="red"
              content={"Reset"}
              //onClick={() => props.setRoute(Routes.PRODUCT_LIST)}
            ></Button>
            <Button
              color="green"
              onClick={onHandleExtractClick}
              content={"Extract"}
            ></Button>
          </Form.Group>
        </Form>
        {CriteriaMsgBox}
      </Container>

      <Dialog
        open={showMasssGenerator}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {"Generating BIM Objects..."}
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0" }}>
          <DialogContentText id="alert-dialog-description">
            {/* <Typography>Batch ID = {}</Typography> */}
            <Progress
              percent={masssGeneratorPercent}
              progress
              indicating
              inverted
              // color="green"
              style={{ marginBottom: "1rem" }}
            />

            {/* <Typography>Brand = {reduxState.CriteriaObj.selectedBusiness.split("+")[0]}</Typography> */}
            <Typography>
              Source = {reduxState.CriteriaObj.selectedSource}
            </Typography>
            <Typography>
              Business = {reduxState.CriteriaObj.selectedBusinessTechnicalName}
            </Typography>
            <Typography>
              Type = {reduxState.CriteriaObj.selectedType}
            </Typography>
            {/* <Typography>Region = {reduxState.CriteriaObj.selectedRegion}</Typography> */}
            <Typography>
              Language = {reduxState.CriteriaObj.selectedLanguage}
            </Typography>
            <Typography>
              Format = {reduxState.CriteriaObj.selectedFormat}
            </Typography>
            <Typography>
              Version = {reduxState.CriteriaObj.selectedVersion}
            </Typography>
            <Typography>
              Generic/Manufacturer = {reduxState.CriteriaObj.selectedGeneric}
            </Typography>
            {/* <Typography>Mapping File = {reduxState.CriteriaObj.selectedMapping}</Typography> */}
            {generationDone ? (
              <Typography>
                Copy directory to use later : {directoryCopy}
              </Typography>
            ) : (
              <></>
            )}
            <Typography>Batch Number = {batchSize}</Typography>
            <Typography>Total Objects = {totalObject}</Typography>
            <Typography>Generated = {totalGenerated}</Typography>
            <Typography component={"div"} style={{ color: "green" }}>
              {/* Success = {reduxState.ProductsObj.productList.length} */}
              Success = {totalGeneratedSuccess}
            </Typography>
            <Typography component={"div"} style={{ color: "red" }}>
              Fail = {totalGeneratedFail}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {generationDone ? (
            <Button
              onClick={() => setShowMasssGenerator(false)}
              classes={{ root: "button", label: "buttonLabel" }}
              color="blue"
              variant="contained"
            >
              {"Back"}
            </Button>
          ) : (
            <>
              <Button
                classes={{ root: "button", label: "buttonLabel" }}
                color="green"
                variant="contained"
                loading
              >
                {"Progressing ..."}
              </Button>
              <Button
                onClick={onAbortGenerationClick}
                classes={{ root: "button", label: "buttonLabel" }}
                color="red"
                variant="contained"
              >
                {"Abort Generation"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAbortgeneration}
        onClose={() => setShowAbortgeneration(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{"Abort Generation"}</DialogTitle>
        <DialogContent style={{ paddingTop: "0" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              Are you sure you want to abort the operation? If yes, The process
              will terminate after the current batch execution.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              onClick={onAbortGenerationYESClick}
              classes={{ root: "button", label: "buttonLabel" }}
              color="red"
              variant="contained"
            >
              {"YES"}
            </Button>
            <Button
              onClick={onAbortGenerationNOClick}
              classes={{ root: "button", label: "buttonLabel" }}
              color="blue"
              variant="contained"
            >
              {"NO"}
            </Button>
          </>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showInformationModal}
        onClose={() => setShowInformationModal(false)}
        aria-labelledby="information"
        aria-describedby="information-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="information">{"Information"}</DialogTitle>
        <DialogContent style={{ paddingTop: "0" }}>
          <DialogContentText id="information-description">
            <List
              bulleted
              style={{
                backgroundColor: "#90cffe",
                padding: "15px 15px 15px 35px",
                margin: "0",
                color: "rgba(0, 0, 0, .8)",
              }}
            >
              <List.Item>Selected file must be an excel file.</List.Item>
              <List.Item>
                The name of the excel file must be in the format
                &gt;filename&lt;_&gt;language code&lt;, where language code
                denotes 'en' for 'English', 'fr' for 'French' etc.
              </List.Item>
              <List.Item>
                If the language code is missing, it will be considered as Enlish
                by default
              </List.Item>
              <List.Item>
                The file must be in format as shown{" "}
                <List.Item href="#" style={{ color: "#fff" }}>
                  here
                </List.Item>
              </List.Item>
              <List.Item>
                Excel file must not contain more than 500 products.
              </List.Item>
              <List.Item>
                The file and all its contents must be validated before
                proceeding with model generation.
              </List.Item>
              <List.Item>
                Use the on-screen editor to validate the errors based on the
                errors identified by the system.
              </List.Item>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowInformationModal(false)}
            classes={{ root: "button", label: "buttonLabel" }}
            color="blue"
            variant="contained"
          >
            {"Back"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ObjectGenerator;
