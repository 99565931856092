import React, { useEffect, useState } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { userManualStore } from "../Reducers";
import { connect } from "react-redux";
import { GetAnonymousFile } from "../../CommonModules/Helpers/FileHelper";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { MyConfig } from "../../../../Helper";

interface Props {
  wording: {
    downloadManual: { [key: string]: string };
    downloading: { [key: string]: string };
  };
  language: string;
  setRoute: any;
}

const Waiter = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<any>({});

  useEffect(() => {
    async function fetchConfig() {
      const cng = await MyConfig();
      setConfig(cng);
    }

    fetchConfig();
  }, []);

  const downloadPDF = async (): Promise<void> => {
    setLoading(true);
    const FileName = "Manuel_Utilisateur_PLACO_BIM";
    const FileExt = "pdf";
    const HelpPdf = await GetAnonymousFile(FileName, FileExt, config);
    setLoading(false);
    saveAs(HelpPdf, `${FileName}.${FileExt}`);
    window.addEventListener("blur", () => {
      api.windowsHandler.closeWindow();
    });
  };

  if (!config && Object.keys(config).length === 0) {
    return <></>;
  }

  return (
    <div>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/PlacoBIM.jpg"}
        name={"Manuel utilisateur"}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          primary
          style={{ marginTop: "20px" }}
          onClick={() => downloadPDF()}
        >
          {props.wording.downloadManual[props.language]}
        </Button>
        {loading && (
          <Dimmer active style={{ height: "calc(100vh - 30px)" }}>
            <Loader>{props.wording.downloading[props.language]}</Loader>
          </Dimmer>
        )}
      </div>
    </div>
  );
};
// add default props to component
Waiter.defaultProps = {
  wording: {
    downloadManual: {
      French: "Télécharger",
      English: "Download",
    },
    downloading: {
      French: "Veuillez patienter Le téléchargement est en cours.",
      English: "Please Wait Download is in progress.",
    },
  },
};

const mapStateToProps = (state: userManualStore, ownProps: any) => {
  return {
    language: state.language,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
  };
};

export default connect(mapStateToProps)(Waiter);
