import React from "react";
import { Container, Header, Image } from "semantic-ui-react";

interface FunctionalityHeaderProps {
  name: string;
  Icon: string;
  width?: number;
  subheader: string;
  config:any;
}

export class FunctionalityHeader extends React.Component<FunctionalityHeaderProps> {
  

  render() {
    const { name, Icon, width } = this.props;
    return (
      <Container textAlign="center" style={{ marginBottom: 0 }}>
        <Header
          color="green"
          style={{ paddingTop: "0", paddingBottom: 0 }}
          as="h3"
          content={name}
          image={this.props.config.REACT_APP_SERVERURL + "/saint-gobain.jpg"}
          subheader={this.props.subheader}
        />
      </Container>
    );
  }
}
