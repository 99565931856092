

import {
  FILTER_VALUE_TYPE,
  FILTER_CRITERION_TYPE,
  FILTER_CHOICE_TYPE,
} from "../Types/filterType";
import { PAM_ATTRIBUTES_TECHNICAL_NAME } from "./technicalNames";

export const placoFilters = {
  partitionWalls: [
    {
      aid:"",
      name: "Solution Calepinable",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LYAOUT_POSSIBLE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      
      aid:"",
      name: "Type de cloison",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PARTITION,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Nom du parement",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.NAME_OF_THE_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Hauteur limite (m)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LIMIT_HEIGHT_M,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Protection Incendie",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_RESISTANCE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Affaiblissement acoustique (DB)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.ACOUSTIC_ATTENUATION_RA_ON_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },

    {
      aid:"",
      name: "Epaisseur totale (mm)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.THICKNESS,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Réaction au feu du parement plaque",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.REACTION_TO_FIRE_OF_THE_SHEET_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },

    {
      aid:"",
      name: "Résistance aux chocs (J)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.IMPACT_RESISTANCE_J,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Montants (s ou d)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
  ],
  liningWalls: [
    {
      aid:"",
      name: "Solution Calepinable",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LYAOUT_POSSIBLE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Type de doublage",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.DUBBING_TYPE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Nom du parement",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.NAME_OF_THE_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Hauteur limite (m)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LIMIT_HEIGHT_M,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Protection incendie en + du support",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_PROTECTION_IN_ADDITION_TO_SUPPORT,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Isolation acoustique en + du support (DB)",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.SOUND_INSULATION_IN_ADDITION_TO_THE_SUPPORT_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },

    {
      aid:"",
      name: "Type de mur support",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_SUPPORT_WALL,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Résistance aux chocs (J)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.IMPACT_RESISTANCE_J,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Montants (s ou d)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.AMOUNTS_S_OR_D,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Encombrement au sol (mm)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.SPACE_ON_GROUND_MM,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
  ],
  ceilings: [
    {
      aid:"",
      name: "Solution Calepinable",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LYAOUT_POSSIBLE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Type de plafond",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CEILING_TYPE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Protection Incendie",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_RESISTANCE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Affaiblissement acoustique (DB)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.ACOUSTIC_ATTENUATION_RA_ON_DB,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Réaction au feu du parement plaque",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.REACTION_TO_FIRE_OF_THE_SHEET_FACING,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Support (plancher)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_SUPPORT_FLOOR,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Classement des locaux humides",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Poids maxi isolant (kg/m²)",
      pimAttribute:
        PAM_ATTRIBUTES_TECHNICAL_NAME.MAXIMUM_INSULATING_MATERIAL_WEIGHT,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: null,
    },

    {
      aid:"",
      name: "Type de plafond démontable",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CEILING_TYPE_OF_GRID,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Disposition des perforations",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.RANDOM_REGULAR_PERFORATIONS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: null,
    },
  ],
  protectionFeu: [
    {
        aid:"",
        name: "Structure",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.STRUCTURE,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
    {
        aid:"",
        name: "Type de protection",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.TYPE_OF_PROTECTION,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
    {
        aid:"",
        name: "Prix fourni posé en € (BatiChiffrage®)",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.PRICE_PROVIDED_IN_BATICHIFFRAGE,
        type: FILTER_VALUE_TYPE.NUMERIC,
        criterion: FILTER_CRITERION_TYPE.BETWEEN,
        choiceType: FILTER_CHOICE_TYPE.SLIDER,
        options: null,
        value: [null, null],
    },
    {
        aid:"",
        name: "Massivité de la structure (m-1)",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.MASSIVITY_OF_THE_STRUCTURE,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
    {
        aid:"",
        name: "Protection incendie",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_RESISTANCE,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
  ],
  gainesTech: [
    {
        aid:"",
        name: "Hauteur limite (m)",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.LIMIT_HEIGHT_M,
        type: FILTER_VALUE_TYPE.NUMERIC,
        criterion: FILTER_CRITERION_TYPE.BETWEEN,
        choiceType: FILTER_CHOICE_TYPE.SLIDER,
        options: null,
        value: [null, null],
    },
    {
        aid:"",
        name: "Coupe-feu de traversée",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_PROTECTION_CLASSES_IN_OUT,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
    {
        aid:"",
        name: "Coupe-feu de paroi",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.WALL_FIRE_PROTECTION,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
    {
        aid:"",
        name: "Prix fourni posé en € (BatiChiffrage®)",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.PRICE_PROVIDED_IN_BATICHIFFRAGE,
        type: FILTER_VALUE_TYPE.NUMERIC,
        criterion: FILTER_CRITERION_TYPE.BETWEEN,
        choiceType: FILTER_CHOICE_TYPE.SLIDER,
        options: null,
        value: [null, null],
    },
    {
        aid:"",
        name: "Classement des locaux humides",
        pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
        type: FILTER_VALUE_TYPE.TEXT,
        criterion: FILTER_CRITERION_TYPE.INCLUDES,
        choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
        options: null,
        value: [null],
    },
  ],
  conduitGaines: [
    {
      aid:"",
      name: "Protection incendie",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.FIRE_RESISTANCE,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: [null],
    },
    {
      aid:"",
      name: "Prix fourni posé en € (BatiChiffrage®)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.PRICE_PROVIDED_IN_BATICHIFFRAGE,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      options: null,
      value: [null, null],
    },
    {
      aid:"",
      name: "Orientation du conduit",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.MAXIMUM_HEIGHT_OF_THE_PLENUM,
      type: FILTER_VALUE_TYPE.NUMERIC,
      criterion: FILTER_CRITERION_TYPE.GREATERTHEN,
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      options: null,
      value: null,
    },
    {
      aid:"",
      name: "Hauteur entre reprise d'appuis (m)",
      pimAttribute: PAM_ATTRIBUTES_TECHNICAL_NAME.CLASSIFICATION_OF_WET_ROOMS,
      type: FILTER_VALUE_TYPE.TEXT,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      options: null,
      value: [null],
    },
  ],
};
