import React from "react";
import { Dropdown } from "semantic-ui-react";
import { FILTER_CHOICE_TYPE } from "../assets/constants"
//import SliderFilter from "../SliderFilter";

import '../assets/index.scss'
import { RangeSlider } from "./rangeSlider";

export interface Filter {
    name: string,
    loading?: boolean,
    choiceType?: string,
    value?: any[] | any,
    options?: any[],
    multiple?: true | false,
    onChange: any,
    pimAttribute?: string,
}

export const FilterComponent = ({ name, value, options, choiceType, loading = true, onChange, pimAttribute }: Filter) => {
    const filterOptions = options && options.map((option) => { return { key: option, text: option, value: option }; })
    const LPoptions = [
        { key: 1, text: "Oui", value: "True" },
        { key: 2, text: "Non", value: "False" },
      ];
    return (
        <div>
            {(choiceType === FILTER_CHOICE_TYPE.CHECKBOX || choiceType === FILTER_CHOICE_TYPE.RADIOBOX) && <>
                <div className='FilterName'>{name}</div>
                <Dropdown
                    className='FilterOption'
                    value={choiceType === FILTER_CHOICE_TYPE.CHECKBOX ? value || [] : value}
                    onChange={(e, d) => onChange(e, d, pimAttribute)}
                    placeholder="---" clearable selection search
                    multiple={choiceType === FILTER_CHOICE_TYPE.CHECKBOX}
                    options={name === "Solution Calepinable" ? LPoptions : filterOptions} loading={loading} /></>
            }
            {(choiceType === FILTER_CHOICE_TYPE.SLIDER) && <>
                <div className='FilterName'>{name}</div>
                <RangeSlider
                    step={filterOptions && filterOptions.length > 0 && filterOptions.every(op => Number(op.value) === op.value && op.value % 1 === 0) ? 1 : 0.1}
                    name={name}
                    value={value}
                    onChange={onChange}
                    min={filterOptions && filterOptions.length > 0 ? Math.min.apply(null, filterOptions.map(op => Number(op.value))) : 0}
                    max={filterOptions && filterOptions.length > 0 ? Math.max.apply(null, filterOptions.map(op => Number(op.value))) : 0}
                    pimAttribute={pimAttribute} /></>
            }
        </div >
    )
}

export default FilterComponent;
