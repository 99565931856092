import { wait } from "../Helpers";

export interface Framework {}

export class Framework {
  async downloadUpdateIds(
    baseUrl: string,
    url: string,
    token: string,
    pluginVersion: string,
    revitVersion: string
  ): Promise<void> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("downloadUpdate", "true");
      localStorage.setItem("downloadUpdateResult", "null");
      let processing = true;
      await window.revit.downloadUpdateIds(
        baseUrl,
        url,
        token,
        pluginVersion,
        revitVersion
      );

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("downloadUpdate") as string
        );
      }

      return JSON.parse(localStorage.getItem("downloadUpdateResult") as string);
    }
  }

  async downloadUpdate(url: string, revitVersion: string): Promise<void> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      localStorage.setItem("downloadUpdate", "true");
      localStorage.setItem("downloadUpdateResult", "null");
      let processing = true;
      window.revit.downloadUpdate(url, revitVersion);

      while (processing) {
        await wait(50);
        processing = JSON.parse(
          localStorage.getItem("downloadUpdate") as string
        );
      }

      return JSON.parse(localStorage.getItem("downloadUpdateResult") as string);
    }
  }

  async installUpdate(
    revitVersion: string,
    pluginVersion: string
  ): Promise<void> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      window.revit.installUpdate(revitVersion, pluginVersion);
    }
  }

  async installUpdateIds(
    revitVersion: string,
    pluginVersion: string
  ): Promise<void> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      window.revit.installUpdateIds(revitVersion, pluginVersion);
    }
  }

  async runInstaller(
    environment: string,
    forceUrl: string | null
  ): Promise<void> {
    if (!window.revit) {
      await wait(200);
      return JSON.parse("{}");
    } else {
      window.revit.runInstaller(environment, forceUrl);
    }
  }

  async getVersion() {
    let result = window.revit && (await window.revit.getVersion());
    if (!result) {
      if (window.revit) {
        try {
          localStorage.setItem("getVersion", "true");
          localStorage.setItem("getVersionResult", "null");
          let processing = JSON.parse(
            localStorage.getItem("getVersion") as string
          );
          window.revit.getVersion();
          while (processing) {
            await wait(50);
            processing = JSON.parse(
              localStorage.getItem("getVersion") as string
            );
          }
          result = localStorage.getItem("getVersionResult");
        } catch (error) {}
      }
    }
    return result;
  }

  async getProductName() {
    let result = window.revit && (await window.revit.getProductName());
    if (!result) {
      if (window.revit) {
        try {
          localStorage.setItem("getProductName", "true");
          localStorage.setItem("getProductNameResult", "null");
          let processing = JSON.parse(
            localStorage.getItem("getProductName") as string
          );
          window.revit.getProductName();
          while (processing) {
            await wait(50);
            processing = JSON.parse(
              localStorage.getItem("getProductName") as string
            );
          }
          result = localStorage.getItem("getProductNameResult");
        } catch (error) {}
      }
    }
    return result;
  }

  async getLastVersion(url: string): Promise<{ [key: string]: string }> {
    // console.log('ok');
    return await fetch(url, { cache: "no-store" })
      .then((response) => response.json())
      .then((data) => data);
  }

  async getDevConfig() {
    if (window.revit) {
      localStorage.setItem("getDevConfig", "true");
      localStorage.setItem("getDevConfigResult", "null");
      let processing = JSON.parse(
        localStorage.getItem("getDevConfig") as string
      );
      window.revit.getDevConfig();
      while (processing) {
        await wait(50);
        processing = JSON.parse(localStorage.getItem("getDevConfig") as string);
      }
      let result = localStorage.getItem("getDevConfigResult") as string;
      result = result.replace(/([0-9]|\p{L})"("|[0-9]|\p{L}|\s)/g, (m) =>
        m.replace('"', '\\"')
      );
      result = result.replace(/\\/g, "\\");
      return JSON.parse(result);
    }
  }

  redirectLink = (link: string) => {
    if (window.revit) {
      window.revit.redirectLink(link);
    }
    if (!window.revit) {
      return false;
    }
  };

  openAppLink = (link: string) => {
    if (window.revit) {
      window.revit.openAppLink(link);
    }
    if (!window.revit) {
      return false;
    }
  };

  windowType = async () => {
    if (window.revit) {
      await window.revit.windowType();
    }
    if (!window.revit) {
      return "";
    }
  };

  CloseApplication = async () => {
    if (window.revit) {
      await window.revit.closeApplication();
    }
  };

  getRevitVersion = async () => {
    const revitVersion = await window.revit.getRevitVersion();
    return revitVersion;
  };
}
