import { combineReducers } from "redux";
import { Language } from "../../../../RevitJS/Types/RevitTypes";
import {
  IntCalpinageData,
  InitCalpinageAction,
  SetCalpinageSelection,
  InitDossierData,
  InitDossierAction,
  ClearMetresAction,
  MetresAction,
  ResetCalpinageSelection,
  IntSuppressionData,
  suppressionSetWall,
  IntReperageData,
  InitReperageAction,
  SetReperageSelection,
} from "../Actions/types";
import { LanguageAction, INIT_LANGUAGE } from "../../../../Actions/types";
import {
  SetCalpinage,
  LoadCalpinage,
  SaveCalpinageBuffer,
  EditCalpinage,
  DeleteCalpinage,
  UpdateCalpinageName,
  EditBufferCalpinage,
} from "../../Calpinage/Actions/types";
import { ClearCalpinage } from "../../Calpinage/Actions/types";
import { SetReperage, SetRevitSetup } from "../../Reperage/Actions/types";

export const suppressionData = (
  state: IntSuppressionData = {
    walls: [],
    ceilings: [],
  },
  action: suppressionSetWall
): IntSuppressionData => {
  switch (action.type) {
    case "SUPPRESSION_SET_WALL":
      return { ...state, walls: action.walls, ceilings: action.ceilings };
    default:
      return state;
  }
};

export const reperageData = (
  state: IntReperageData = {
    buffer: {
      Name: "",
      Date: "",
      selections: { status: false, list: [] },
      reperage: { status: false, list: {} },
      revitSetup: { status: false, list: {}, saveExistingReperage: false },
      Id: "",
      changeFlag: false,
    },
    data: {},
  },
  action:
    | InitReperageAction
    | SetReperageSelection
    | SetReperage
    | SetRevitSetup
): IntReperageData => {
  let date = new Date();
  switch (action.type) {
    case "SET_REPERAGE_DATA":
      return { ...state, buffer: action.reperageData };
    case "SET_REPERAGE_SELECTION":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          selections: {
            ...state.buffer.selections,
            status: true,
            list: (action as SetReperageSelection).selectionData,
          },
          reperage: {
            ...state.buffer.selections,
            status: false,
            list: [],
          },
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
        },
      };
    case "SET_REPERAGE":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          reperage: (action as SetReperage).reperageData,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          time: Date.now(),
        },
      };
    case "SET_REVIT_SETUP":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          revitSetup: (action as SetRevitSetup).revitSetupData,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          time: Date.now(),
        },
      };
    default:
      return state;
  }
};

export const calpinageData = (
  state: IntCalpinageData = {
    buffer: {
      Name: "",
      Date: "",
      selections: { status: false, list: [] },
      calepinage: { status: false, list: {}, version: 1 },
      Id: "",
      changeFlag: false,
    },
    data: {},
  },
  action:
    | InitCalpinageAction
    | SetCalpinageSelection
    | SetCalpinage
    | LoadCalpinage
    | SaveCalpinageBuffer
    | EditCalpinage
    | DeleteCalpinage
    | UpdateCalpinageName
    | ResetCalpinageSelection
    | ClearCalpinage
    | EditBufferCalpinage
): IntCalpinageData => {
  let date = new Date();
  let result;

  switch (action.type) {
    case "SET_CALPINAGE_DATA":
      return { ...state, buffer: action.calpinageData };
    case "SET_CALPINAGE_SELECTION":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          selections: {
            ...state.buffer.selections,
            status: true,
            list: (action as SetCalpinageSelection).selectionData,
          },
          calepinage: {
            ...state.buffer.selections,
            status: false,
            list: {},
            version: 1,
          },
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
        },
      };
    case "SET_CALPINAGE":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          calepinage: (action as SetCalpinage).calepinageData,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          time: Date.now(),
        },
      };
    case "LOAD_CALEPINAGE":
      return { ...state, data: action.calepinage };
    case "SAVE_CALEPINAGE_BUFFER":
      if (state.buffer === null) {
        return state;
      }

      result = {
        ...state,
        data: {
          ...state.data,
          [state.buffer.Id]: { ...state.buffer, time: Date.now() },
        },
      };
      return result;
    case "EDIT_CALEPINAGE":
      return {
        ...state,
        buffer: action.calepinage,
      };
    case "EDIT_BUFFER_CALEPINAGE":
      /** This two line code is done for the reason of Renaming
       * If module was renamed earlier then that will not get reflacted
       * into details for the first time this is because of byte storage implementation
       */
      const cloneSelectedCalepinage = JSON.parse(
        JSON.stringify(state.data[action.calepinage.Id])
      );
      action.calepinage.Name = cloneSelectedCalepinage.Name;
      //
      return {
        ...state,
        buffer: action.calepinage,
        data: {
          ...state.data,
          [action.calepinage.Id]: action.calepinage,
        },
      };
    case "DELETE_CALEPINAGE":
      let { Id } = action;
      const { [Id]: foo, ...newSelections } = state.data;
      return { ...state, data: newSelections };
    case "UPDATE_CALEPINAGE_NAME":
      result = {
        ...state.data,
        [action.Id]: {
          ...state.data[action.Id],
          Name: action.Name,
          Date: action.Date,
        },
      };

      // bimStorage.setItem(storageKey.CALEPINAGE, JSON.stringify(result));
      return { ...state, data: result };
    case "RESET_CALPINAGE_SELECTION":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          selections: {
            ...state.buffer.selections,
            status: false,
            list: [],
          },
          calepinage: {
            ...state.buffer.selections,
            status: false,
            list: {},
            version: 1,
          },
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
        },
      };
    case "CLEAR_CALEPINAGE":
      return {
        ...state,
        buffer: {
          ...state.buffer,
          calepinage: {
            ...state.buffer.selections,
            status: false,
            list: {},
            version: 1,
          },
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
        },
      };
    default:
      return state;
  }
};

export const dossierData = (
  state: InitDossierData = {} as InitDossierData,
  action: InitDossierAction | any
): InitDossierData => {
  switch (action.type) {
    case "SET_DOSSIER_DATA":
      return action.dossierData;
    case "SET_DOSSIER_SELECTION":
      return action.selectionData;
    default:
      return state;
  }
};

export const moduleParZone = (state: boolean = false, action: any): boolean => {
  switch (action.type) {
    case "SET_MODULE_SELECTION":
      return action.value;
    default:
      return state;
  }
};

export const prevPage = (state: any = null, action: any): any => {
  switch (action.type) {
    case "SET_PREV_PAGE":
      return action.prevPage;
    default:
      return state;
  }
};

export const language = (
  language: Language = "French",
  action: LanguageAction
): Language => {
  switch (action.type) {
    case INIT_LANGUAGE:
      return action.language;
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const metresData = (
  state: string[] = [] as string[],
  action: MetresAction | ClearMetresAction
): string[] => {
  switch (action.type) {
    case "SET_METRES_SELECTION":
      return action.metresData;
    case "CLEAR_METRES_SELECTION":
      return [];
    default:
      return state;
  }
};

export const moduleReducers = combineReducers<SelectionStore>({
  moduleParZone,
  functionalityIcon,
  calpinageData,
  dossierData,
  language,
  prevPage,
  metresData,
  suppressionData,
  reperageData,
});

export interface SelectionStore {
  moduleParZone: boolean;
  functionalityIcon: string;
  language: Language;
  dossierData: InitDossierData;
  calpinageData: IntCalpinageData;
  prevPage: string;
  metresData: string[];
  suppressionData: IntSuppressionData;
  reperageData: IntReperageData;
}
