import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";
import {
  saveBufferAction,
  deleteSelectionAction,
  duplicateSelectionAction,
  loadSelectionsAction,
  toggleZoneAction,
  updateSelectionName,
  updateSelectionColor,
  Selection,
  saveSelectionAction,
} from "../Actions/types";
import { v4 } from "uuid";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { cloneDeep, isEqual, omit } from "lodash";

type selectionsAction =
  | saveBufferAction<PlacoOptions>
  | deleteSelectionAction
  | duplicateSelectionAction
  | loadSelectionsAction<PlacoOptions>
  | toggleZoneAction
  | updateSelectionName
  | updateSelectionColor
  | saveSelectionAction;

export const selections = (
  selections: { [key: string]: Selection<PlacoOptions> } = {},
  action: selectionsAction
): { [key: string]: Selection<PlacoOptions> } => {
  let result;
  const date = new Date();
  switch (action.type) {
    case "LOAD_SELECTIONS":
      return action.selections;
    case "SAVE_BUFFER":
      if (action.selection === null) {
        return selections;
      }

      // check if current selection and exisiting selection is different
      if (
        !isEqual(
          omit(selections[action.selection.Id], "Time"),
          omit(action.selection, "Time")
        )
      ) {
        result = {
          ...selections,
          [action.selection.Id]: action.selection,
        };

        //update identifier "isSelectionUpdated" to all related dossier that selection has been modified
        bimStorage.getItem(storageKey.DOSSIER_TECHNIQUE).then((data: any) => {
          if (data) {
            let updatedDossier = data.map((e: any) => {
              // if(e.selections?.list){
              if (e.selections?.list[0] === action?.selection?.Id) {
                e["isSelectionUpdated"] = true;
              }
              // }
              return e;
            });
            bimStorage.setItem(
              storageKey.DOSSIER_TECHNIQUE,
              JSON.stringify(updatedDossier)
            );
          }
        });
        bimStorage.setItem(storageKey.PLACOSELECTIONS, JSON.stringify(result));
        return result;
      } else {
        return selections;
      }

    case "SAVE_SELECTIONS":
      if (action.selection === null) {
        return selections;
      }
      bimStorage.setItem(
        storageKey.PLACOSELECTIONS,
        JSON.stringify(action.selection)
      );
      return action.selection;
    case "DELETE_SELECTION":
      let { selectionId } = action;
      const { [selectionId]: foo, ...newSelections } = selections;
      result = newSelections;
      bimStorage.setItem(storageKey.PLACOSELECTIONS, JSON.stringify(result));
      return result;
    case "DUPLICATE_SELECTION":
      let selectionToCopy = action.selectionId;
      let newId = v4();
      let duplicatedValue = 1;
      let Newname =
        selections[selectionToCopy].Name + " (" + duplicatedValue + ")";
      const checkIfNewNameExist = () => {
        return Object.values(selections).some((selection: any) => {
          return selection.Name.toLowerCase() === Newname.toLowerCase();
        });
      };
      while (checkIfNewNameExist()) {
        duplicatedValue = duplicatedValue + 1;
        Newname =
          selections[selectionToCopy].Name + " (" + duplicatedValue + ")";
      }
      result = {
        ...selections,
        [selectionToCopy]: {
          ...selections[selectionToCopy],
          Duplicated: duplicatedValue,
        },
        [newId]: {
          ...selections[selectionToCopy],
          Id: newId,
          Duplicated: 1,
          Name: Newname,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          Time: Date.now(),
        },
      };
      bimStorage.setItem(storageKey.PLACOSELECTIONS, JSON.stringify(result));
      return result;
    case "TOGGLE_ZONE":
      result = {
        ...selections,
        [action.selectionId]: {
          ...selections[action.selectionId],
          Zone: !selections[action.selectionId].Zone,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          Time: Date.now(),
        },
      };
      bimStorage.setItem(storageKey.PLACOSELECTIONS, JSON.stringify(result));
      return result;
    case "UPDATE_SELECTION_NAME":
      result = {
        ...selections,
        [action.Index]: {
          ...selections[action.Index],
          Name: action.Name,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          Time: Date.now(),
        },
      };
      bimStorage.setItem(storageKey.PLACOSELECTIONS, JSON.stringify(result));
      return result;
    case "UPDATE_SELECTION_COLOR":
      result = {
        ...selections,
        [action.Index]: {
          ...selections[action.Index],
          Color: action.Color,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          Time: Date.now(),
        },
      };
      bimStorage.setItem(storageKey.PLACOSELECTIONS, JSON.stringify(result));
      return result;
    default:
      return selections;
  }
};
