import React from "react";
import { Button, Loader, Header, Modal } from "semantic-ui-react";
import "../../Resources/modal.css";
export const DownloadLoader = (props: any) => {
  return (
    <Modal
      basic
      open={props.loaderMessage!==""}
      size="small"
      style={{ textAlign: "center" }}
      dimmer="inverted"
    >
      <Modal.Content
        style={{ color: "black", fontWeight: "bold", fontSize: "1.3em" }}
      >
        <p style={{ marginBottom: "0px" }}>
          {/* Document en cours de téléchargement. */}
          {props.loaderMessage}
        </p>
        {/* <p> Merci de patienters?</p> */}
      </Modal.Content>

      <Modal.Content style={{ margin: "45px 0px" }}>
        <Loader inverted size="large"></Loader>
      </Modal.Content>

      <Modal.Actions style={{ textAlign: "center", borderTop: "none" }}>
        {props.buttonList.map((button: any) =>
        <Button key={button.name} size="tiny" color={button.color && button.color!="" ? button.color : "blue"} onClick={() => button.action()}>
          {button.name}
        </Button>
      )}
      </Modal.Actions>
    </Modal>
  );
};
