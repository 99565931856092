import { ADD_SYSTEM_TO_CART, REMOVE_SYSTEM_FROM_CART, CartActionTypes, CLEAN_CART } from './types'
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";

export function addSystemToCartAction(
  cartSystemItem: ProductDetailData,
  systemTypeID: number
): CartActionTypes {
  return {
    type: ADD_SYSTEM_TO_CART,
    cartSystemItem: cartSystemItem,
    systemTypeID: systemTypeID
  };
}

export function removeSystemFromCartAction(
  index: number,
  systemTypeID: number
): CartActionTypes {
  return {
    type: REMOVE_SYSTEM_FROM_CART,
    systemTypeID: systemTypeID,
    index: index,
  };
}

export const cleanCart = (): CartActionTypes => ({
  type: CLEAN_CART,
})

