import {
  forEach,
  find,
  filter,
  includes,
  startsWith,
  round,
  map,
  uniqBy,
  get,
  sortBy,
  compact,
  groupBy,
  keyBy,
  ceil,
  uniqWith,
} from "lodash";
import { IRationTable } from "../Components/TypeOfQuantitatifs/RatioTable";
import {
  alternativeUnit,
  alternativeUnitsSelector,
  coatingList,
  dbSalesUnit,
  distributionChannelsAttributeSelector,
  findProductToProduct,
  numeralConversion,
  packageAttributeSelector,
  relationAttributeSelector,
  screwList,
  uniteDeVenteArray,
} from "./helper";
import articleunit from "../Util/articleunit.json";

import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  extractFramesDatafromRevit,
  extractPlasterboardDataForWallfromRevit,
} from "./revit";
import { getLayoutAttributesAid } from "../Requests";
import {
  Plaqs,
  PlaqArticle,
  Accessory,
  AccessoryArticle,
  Montant,
  MontantArticle,
} from "../Actions/types";
import { woolType } from "./type";

export const collectWool = async (finalExtract: any) => {
  let woolCollection: woolType[] = [];
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    const woolKeySearchArray = ["PAR", "GR 32", "Isoconfort", "Laine"];

    extractObj.wools.forEach((element: any) => {
      const woolAcceptable = find(woolKeySearchArray, (o: any) => {
        return startsWith(element.product.translation, o);
      });

      const gfrSellingPackaging = find(element.product.attributes, {
        technicalName: "GFR-GP Selling Packaging",
      });
      let sellingPackage = "0";
      if (gfrSellingPackaging) {
        sellingPackage = gfrSellingPackaging.values[0].value;
      }

      const gfrLabelUnit = find(element.product.attributes, {
        technicalName: "GFR-Label unit (UE)",
      });
      let labelUnit: any = "";
      if (gfrLabelUnit) {
        labelUnit = gfrLabelUnit.values[0].value;
        if (labelUnit) {
          labelUnit = labelUnit.split(",")[0];
        }
      } else {
        labelUnit = articleunit["ceiling"]["Ovurages isolants"];
      }

      const gfrEan = find(element.product.attributes, {
        technicalName: "GFR-EAN code ISOVER article",
      });

      let ean = "";
      if (gfrEan) {
        ean = gfrEan.values[0].value;
      }

      if (woolAcceptable) {
        const includeLainDEVerre = includes(
          element.product.translation,
          "Laine de verre"
        );

        const relationAttributeAids = relationAttributeSelector(
          extractObj.relationAttributes
        );

        const ration = findProductToProduct(
          extractObj.relations,
          element.product.oid,
          relationAttributeAids.ratio,
          extractObj.placoSolution.oid
        );

        let finalRatio =
          extractObj.layoutPossible === "false"
            ? parseFloat(ration) || 1.05
            : 1.05;

        if (!includeLainDEVerre) {
          let customRatio = 0;
          if (
            userRation[
              extractObj.placoSolution.oid + element.product.translation
            ]
          ) {
            customRatio =
              userRation[
                extractObj.placoSolution.oid + element.product.translation
              ];
          } else {
            customRatio = finalRatio;
          }

          let woolRow: woolType = {
            Level: extractObj.ceilingDetails.LevelName,
            LevelElevation: extractObj.ceilingDetails.LevelElevation,
            Ovurage: extractObj.placoSolution.translation,
            ProductName: element.product.translation,
            ElementHeight: numeralConversion(
              round(extractObj.ceilingDetails.Height / 1000)
            ),
            ElementArea: round(extractObj.ceilingDetails.Area, 2),
            Ratio: finalRatio,
            WoolArea: round(extractObj.ceilingDetails.Area * 1.05, 2),
            Rolls: "",
            ElementType: extractObj.ceilingDetails.CeilingType,
            ElementId: extractObj.ceilingDetails.Id,
            SellingPackage: sellingPackage,
            LabelUnit: labelUnit,
            Ean: ean,
            articleLists: null,
            selected: true,
            rationCustom: customRatio,
            solutionId: extractObj.placoSolution.oid,
          };

          woolCollection.push(woolRow);
        }
      } else {
        const woolNonLayoutAcceptable = find(
          ["Doublissimo", "Placomur", "Placotherm"],
          (o: any) => {
            return startsWith(element.product.translation, o);
          }
        );

        if (woolNonLayoutAcceptable) {
          let sellingPackage = "0";

          const gfrLabelUnit = find(element.product.attributes, {
            technicalName: "GFR-Label unit (UE)",
          });
          let labelUnit: any = "";
          if (gfrLabelUnit) {
            labelUnit = gfrLabelUnit.values[0].value;
            if (labelUnit) {
              labelUnit = labelUnit.split(",")[0];
            }
          } else {
            labelUnit = articleunit["ceiling"]["Ovurages isolants"];
          }

          const gfrEan = find(element.product.attributes, {
            technicalName: "GFR-EAN code ISOVER article",
          });

          let ean = "";
          if (gfrEan) {
            ean = gfrEan.values[0].value;
          }

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            element.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          let finalRatio =
            extractObj.layoutPossible === "false"
              ? parseFloat(ration) || 1.05
              : 1.05;

          let collectedNewArticles: any = [];
          let selected = false;
          let isSelected = false;
          map(element.articles, (art, artIndex) => {
            let customRatio = 0;
            if (userRation[extractObj.placoSolution.oid + art.value]) {
              customRatio =
                userRation[extractObj.placoSolution.oid + art.value];
            } else {
              customRatio = finalRatio;
            }

            collectedNewArticles.push({
              level: extractObj.wallDetails.LevelName,
              solution: extractObj.placoSolution.translation,
              articleName: art.value,
              elementType: extractObj.wallDetails.WallType,
              levelElevation: extractObj.wallDetails.LevelElevation,
              ratio: finalRatio,
              nonLayout: true,
              selected: selected,
              rationCustom: customRatio,
              solutionId: extractObj.placoSolution.oid,
              articleId: art.article.oid,
              key: art.value,
              value: art.value,
              text: art.value,
            });
          });
        }
      }
    });
  });

  return woolCollection;
};

export const collectAccessory = async (finalExtract: any, props: any) => {
  const primaryObject: any = {};
  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const ceilingType = extractObj.ceilingDetails.CeilingType;
    const ceilingHeight = extractObj.ceilingDetails.Height / 1000;
    const ceilingArea = extractObj.ceilingDetails.Area;
    const ceilingId = extractObj.ceilingDetails.Id;
    const solutionId = extractObj.placoSolution.oid;
    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 0) {
          if (accessoryObj.articles.length > 1) {
            if (accessoryObj.type !== "accessoires") {
              const selectedArticle = find(accessoryObj.articles, function (o) {
                return (
                  listToFind.findIndex((account: string) => {
                    return account.startsWith(o.packagedArticle.translation);
                  }, o.packagedArticle.translation) > -1
                );
              });

              if (selectedArticle) {
                selectedAccessoryPackagedArticle =
                  selectedArticle.packagedArticle;
              }
            } else {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
            if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        }
        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const ratio = findProductToProduct(
            extractObj.relations,
            accessoryObj.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article

          if (objArticle) {
            const objSurfaceArea = numeralConversion(ceilingArea); //Surface Area
            const objFinalratio = ratio
              ? ratio === "" || ratio === "999" || ratio === "9999"
                ? 0
                : parseFloat(ratio)
              : 0; // Ratio
            let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite

            if (objFinalunite === "") {
              const accessoryObject = articleunit["ceiling"]["Accessories"];
              forEach(accessoryObject, (value: string, key: string) => {
                if (objArticle.includes(key)) {
                  objFinalunite = value;
                }
              });
            }

            let objIQuantity: number | string = ""; //I Quantity
            let objSalesunit = ""; //J Unite De Vente

            const articleAttribute =
              selectedAccessoryPackagedArticle.attributes;
            const dbsalesunit = dbSalesUnit(
              distributionChannelsAids,
              articleAttribute
            );

            const saleunit = find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);

            if (dbsalesunit) {
              objSalesunit = uniteDeVenteArray(dbsalesunit);
            } else {
              if (saleunit) {
                const saleunit_value = get(saleunit.values[0], "value");
                objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
              }
            }

            let alternativeunit;
            if (objFinalunite) {
              alternativeunit = alternativeUnit(
                alternativeUnitsAids,
                articleAttribute,
                parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
                objFinalunite,
                objSalesunit,
                props,
                "accessory"
              );

              if (alternativeunit) {
                objIQuantity = alternativeunit;
              }
            }

            /**
             * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
             */
            const primaryObjectKey: string = ceilingId;
            let primaryArticle: any = primaryObject[primaryObjectKey] || {};

            /**
             * Extract secondary article from primary article, Key = article name
             */
            const secondaryArticletKey: string = objArticle;

            /**
             * Secondary article are sorted based on Article name
             * Extract secondary article or assign new
             * While assigning new assign surfaceArea, ratio, quantiteI to 0
             */
            let secondaryArticle: any = primaryArticle[
              secondaryArticletKey
            ] || {
              levelName: levelName,
              solutionName: ovurage,
              article: objArticle,
              ceilingHeight: ceilingHeight,
              surfaceArea: 0,
              ratio: 0,
              quantite: "",
              quantiteUnit: objFinalunite,
              quantiteI: 0,
              salesUnit: objSalesunit,
              ceilingType: ceilingType,
              levelElevation: levelElevation,
              ceilingId: ceilingId,
              alternativeUnitsAids: alternativeUnitsAids,
              articleAttribute: articleAttribute,
              selected: true,
              solutionId: solutionId,
            };

            /**
             * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
             */
            primaryArticle[secondaryArticletKey] = {
              ...secondaryArticle,
              surfaceArea:
                secondaryArticle.surfaceArea +
                parseFloat(objSurfaceArea.toFixed(3)),
              ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
              quantiteI: secondaryArticle.quantiteI + objIQuantity,
            };

            /**
             * Assign newly calculate primary article .
             */

            primaryObject[primaryObjectKey] = primaryArticle;
            primaryObject[primaryObjectKey]["surfaceArea"] =
              primaryArticle[secondaryArticletKey].surfaceArea;
            primaryObject[primaryObjectKey]["level"] =
              primaryArticle[secondaryArticletKey].levelName;
            primaryObject[primaryObjectKey]["levelElevation"] =
              primaryArticle[secondaryArticletKey].levelElevation;
            primaryObject[primaryObjectKey]["solution"] =
              primaryArticle[secondaryArticletKey].solutionName;
            primaryObject[primaryObjectKey]["ceilingHeight"] =
              primaryArticle[secondaryArticletKey].ceilingHeight;
            primaryObject[primaryObjectKey]["ceilingId"] =
              primaryArticle[secondaryArticletKey].ceilingId;
            primaryObject[primaryObjectKey]["solutionId"] =
              primaryArticle[secondaryArticletKey].solutionId;
          }
        }
      }
    });
  });
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);
  const collected_articles: Accessory[] = [];
  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let ceilingHeight = prmObj.ceilingHeight;
    let ceilingId = prmObj.ceilingId;
    let solutionId = prmObj.solutionId;

    let articles: AccessoryArticle[] = [];

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "ceilingHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "ceilingId" &&
        articleIndex !== "solutionId"
      ) {
        let customRatio = 0;
        if (userRation[solutionId + articleObj.article]) {
          customRatio = userRation[solutionId + articleObj.article];
        } else {
          customRatio = articleObj.ratio;
        }

        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(ceilingHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.ceilingType,
          levelElevation: articleObj.levelElevation,
          elementId: ceilingId,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
          rationCustom: customRatio,
          solutionId: solutionId,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(ceilingHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: ceilingId,
    });
  });
  return collected_articles;
};

export const collectCeilingAccessoryWithCustomRatio = async (
  finalExtract: any,
  defaultRatio: any,
  props: any
) => {
  const primaryObject: any = {};
  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const ceilingType = extractObj.ceilingDetails.CeilingType;
    const ceilingHeight = extractObj.ceilingDetails.Height / 1000;
    const ceilingArea = extractObj.ceilingDetails.Area;
    const ceilingId = extractObj.ceilingDetails.Id;
    const solutionId = extractObj.placoSolution.oid;

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 0) {
          if (accessoryObj.articles.length > 1) {
            if (accessoryObj.type !== "accessoires") {
              const selectedArticle = find(accessoryObj.articles, function (o) {
                return (
                  listToFind.findIndex((account: string) => {
                    return account.startsWith(o.packagedArticle.translation);
                  }, o.packagedArticle.translation) > -1
                );
              });

              if (selectedArticle) {
                selectedAccessoryPackagedArticle =
                  selectedArticle.packagedArticle;
              }
            } else {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
            if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
              selectedAccessoryPackagedArticle =
                accessoryObj.articles[0].packagedArticle;
            }
          } else {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        }
        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          const objSurfaceArea = numeralConversion(ceilingArea); //Surface Area
          const customRatioObj: any = defaultRatio.find(
            (def: any) =>
              def.nameArticle === objArticle && def.solutionId === solutionId
          );
          const objFinalratio = customRatioObj.rationCustom
            ? customRatioObj.rationCustom === "" ||
              customRatioObj.rationCustom === "999" ||
              customRatioObj.rationCustom === "9999"
              ? 0
              : parseFloat(customRatioObj.rationCustom)
            : 0; // Ratio
          let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
          let objIQuantity: number | string = ""; //I Quantity
          let objSalesunit = ""; //J Unite De Vente

          if (objFinalunite === "") {
            const accessoryObject = articleunit["ceiling"]["Accessories"];
            forEach(accessoryObject, (value: string, key: string) => {
              if (objArticle.includes(key)) {
                objFinalunite = value;
              }
            });
          }

          const articleAttribute = selectedAccessoryPackagedArticle.attributes;
          const dbsalesunit = dbSalesUnit(
            distributionChannelsAids,
            articleAttribute
          );

          const saleunit = find(articleAttribute, [
            "aid",
            attributeAids.baseunit,
          ]);

          if (dbsalesunit) {
            objSalesunit = uniteDeVenteArray(dbsalesunit);
          } else {
            if (saleunit) {
              const saleunit_value = get(saleunit.values[0], "value");
              objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
            }
          }

          let alternativeunit;
          if (objFinalunite) {
            alternativeunit = alternativeUnit(
              alternativeUnitsAids,
              articleAttribute,
              parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
              objFinalunite,
              objSalesunit,
              props,
              "accessory"
            );

            if (alternativeunit) {
              objIQuantity = alternativeunit;
            }
          }

          /**
           * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
           */
          const primaryObjectKey: string = ceilingId;
          let primaryArticle: any = primaryObject[primaryObjectKey] || {};

          /**
           * Extract secondary article from primary article, Key = article name
           */
          const secondaryArticletKey: string = objArticle;

          /**
           * Secondary article are sorted based on Article name
           * Extract secondary article or assign new
           * While assigning new assign surfaceArea, ratio, quantiteI to 0
           */
          let secondaryArticle: any = primaryArticle[secondaryArticletKey] || {
            levelName: levelName,
            solutionName: ovurage,
            article: objArticle,
            ceilingHeight: ceilingHeight,
            surfaceArea: 0,
            ratio: 0,
            quantite: "",
            quantiteUnit: objFinalunite,
            quantiteI: 0,
            salesUnit: objSalesunit,
            ceilingType: ceilingType,
            levelElevation: levelElevation,
            ceilingId: ceilingId,
            alternativeUnitsAids: alternativeUnitsAids,
            articleAttribute: articleAttribute,
          };

          /**
           * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
           */
          primaryArticle[secondaryArticletKey] = {
            ...secondaryArticle,
            surfaceArea:
              secondaryArticle.surfaceArea +
              parseFloat(objSurfaceArea.toFixed(3)),
            ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
            quantiteI: secondaryArticle.quantiteI + objIQuantity,
          };

          /**
           * Assign newly calculate primary article .
           */

          primaryObject[primaryObjectKey] = primaryArticle;
          primaryObject[primaryObjectKey]["surfaceArea"] =
            primaryArticle[secondaryArticletKey].surfaceArea;
          primaryObject[primaryObjectKey]["level"] =
            primaryArticle[secondaryArticletKey].levelName;
          primaryObject[primaryObjectKey]["levelElevation"] =
            primaryArticle[secondaryArticletKey].levelElevation;
          primaryObject[primaryObjectKey]["solution"] =
            primaryArticle[secondaryArticletKey].solutionName;
          primaryObject[primaryObjectKey]["ceilingHeight"] =
            primaryArticle[secondaryArticletKey].ceilingHeight;
          primaryObject[primaryObjectKey]["ceilingId"] =
            primaryArticle[secondaryArticletKey].ceilingId;
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];
  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let ceilingHeight = prmObj.ceilingHeight;
    let ceilingId = prmObj.ceilingId;

    let articles: AccessoryArticle[] = [];

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "ceilingHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "ceilingId"
      ) {
        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(ceilingHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.ceilingType,
          levelElevation: articleObj.levelElevation,
          elementId: ceilingId,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(ceilingHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: ceilingId,
    });
  });
  return collected_articles;
};

export const collectMontant = async (
  finalExtract: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: { [Key: string]: Montant } = {};
  let montantsRevitData = await extractFramesDatafromRevit();
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    let elementId = extractObj.ceilingDetails.Id;
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const elementType = extractObj.ceilingDetails.CeilingType;
    const elementHeight = extractObj.ceilingDetails.Height / 1000;
    const elementArea = extractObj.ceilingDetails.Area;
    const solutionId = extractObj.placoSolution.oid;

    if (extractObj.layoutPossible === "true" && byRatio === false) {
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === elementId;
      });

      if (revitData) {
        const userMontantObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Montant"
        );

        if (userMontantObj) {
          if (userMontantObj.Value) {
            const userMontantArray = userMontantObj.Value.split(";");

            if (userMontantArray) {
              userMontantArray.forEach((userMon: string) => {
                forEach(extractObj.montants, (montant, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    montant.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = montant.articles.find(
                    (plart: any) => plart.value === userMon
                  );

                  if (selectedArticle) {
                    let newMontantRow: Montant = collected_articles[
                      `${elementId}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      elementId: elementId,
                      articleName: selectedArticle.value,
                      elementHeight: numeralConversion(round(elementHeight, 2)),
                      elementArea: numeralConversion(round(elementArea, 2)),
                      articles: {},
                      levelElevation: levelElevation,
                      elementType: elementType,
                      solutionId: solutionId,
                      articleId: montant.product.oid,
                      articleLists: null,
                    };

                    let collectedNewArticles: any = [];

                    forEach(montant.articles, (moarticle, moArticleIndex) => {
                      const attributeDescriptors = extractObj.attributes;

                      const attributeAids =
                        packageAttributeSelector(attributeDescriptors);

                      const articleLength = find(
                        moarticle.packagedArticle.attributes,
                        ["aid", attributeAids.unpackedLength]
                      );

                      let calArticleLength: number = 0;

                      if (articleLength) {
                        const metreValue = find(articleLength.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue = get(metreValue, "value");
                          calArticleLength = mValue;
                        } else {
                          const milliMetreValue = find(articleLength.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            calArticleLength = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let customRatio = 0;
                      if (userRation[solutionId + moarticle.value]) {
                        customRatio = userRation[solutionId + moarticle.value];
                      } else {
                        customRatio = objFinalratio;
                      }

                      collectedNewArticles.push({
                        level: levelName,
                        solution: ovurage,
                        articleName: moarticle.value,
                        elementHeight: numeralConversion(
                          round(elementHeight, 2)
                        ),
                        elementArea: numeralConversion(round(elementArea, 2)),
                        articleRevitHeight: 0,
                        nbUnit: 0,
                        nbTotal: 0,
                        articleLength: calArticleLength,
                        elementType: elementType,
                        levelElevation: levelElevation,
                        ratio: objFinalratio,
                        nonLayout: true,
                        selected: selectedArticle.value === moarticle.value,
                        rationCustom: customRatio,
                        key: moarticle.value,
                        value: moarticle.value,
                        text: moarticle.value,
                      });
                    });
                    newMontantRow.articleLists = collectedNewArticles;
                    collected_articles[`${elementId}/${montant.product.oid}`] =
                      newMontantRow;
                  }
                });
              });
            }
          }
        }
      }
    }
  });

  /**
   * Sort montants by level -> solution
   */
  let montantArray: Montant[] = [];

  forEach(collected_articles, (plaq: Montant, key: string) => {
    montantArray.push(plaq);
  });

  return montantArray;
};

export const collectPlaques = async (
  finalExtract: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  try {
    const collected_articles: {
      [Key: string]: Plaqs;
    } = {};

    const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

    forEach(finalExtract, (extractObj, extractIndex) => {
      let elementId = extractObj.ceilingDetails.Id;
      const levelName = extractObj.ceilingDetails.LevelName;
      const levelElevation = extractObj.ceilingDetails.LevelElevation;
      const ovurage = extractObj.placoSolution.translation;
      const solutionId = extractObj.placoSolution.oid;
      const elementType = extractObj.ceilingDetails.CeilingType;
      const elementHeight = extractObj.ceilingDetails.Height / 1000;
      const elementArea = extractObj.ceilingDetails.Area;
      let attributesId: any = getLayoutAttributesAid(extractObj.attributes);

      let layers = extractObj.placoSolution.attributes.filter(
        (e: any) => e.aid === attributesId.layers
      );
      let layerNames = compact(
        layers.map((ln: any) => {
          const lnmm = ln.subAttributes.find(
            (sl: any) => sl.technicalName === "GFR-Layer name"
          );
          return lnmm.values[0];
        })
      );

      let layerCount: any = groupBy(layerNames, "value");
      layerCount = keyBy(
        map(layerCount, (ly, key) => {
          return { key: key, count: ly.length };
        }),
        "key"
      );

      if (extractObj.layoutPossible === "true" && byRatio === false) {
      } else {
        let revitData: any = find(processedWallParams, function (o) {
          return o.Id === elementId;
        });

        if (revitData) {
          const userPlaqueObj = revitData.Params.find(
            (rvd: any) => rvd.Name === "Plaque"
          );

          if (userPlaqueObj) {
            if (userPlaqueObj.Value) {
              const userPlaqueArray = userPlaqueObj.Value.split(";");

              if (userPlaqueArray) {
                userPlaqueArray.forEach((userPlq: string) => {
                  forEach(extractObj.plaques, (plaque, index) => {
                    const relationAttributeAids = relationAttributeSelector(
                      extractObj.relationAttributes
                    );

                    const ration = findProductToProduct(
                      extractObj.relations,
                      plaque.product.oid,
                      relationAttributeAids.ratio,
                      extractObj.placoSolution.oid
                    );

                    const objFinalratio = ration
                      ? ration === "" || ration === "999" || ration === "9999"
                        ? 0
                        : parseFloat(ration)
                      : 0;

                    let selectedArticle = plaque.articles.find(
                      (plart: any) => plart.value === userPlq
                    );

                    if (selectedArticle) {
                      let newPlaqRow: Plaqs = collected_articles[
                        `${elementId}/${plaque.product.oid}`
                      ] || {
                        level: levelName,
                        solution: ovurage,
                        elementId: elementId,
                        articleName: plaque.product.translation,
                        elementHeight: numeralConversion(
                          round(elementHeight, 2)
                        ),
                        elementArea: numeralConversion(round(elementArea, 2)),
                        articles: {},
                        levelElevation: levelElevation,
                        articleLists: null,
                        solutionId: solutionId,
                        articleId: plaque.product.oid,
                        elementType: elementType,
                      };

                      let collectedNewArticles: any = [];
                      forEach(plaque.articles, (plarticle, plArticleIndex) => {
                        const attributeDescriptors = extractObj.attributes;

                        const attributeAids =
                          packageAttributeSelector(attributeDescriptors);

                        const articleWidth = find(
                          plarticle.packagedArticle.attributes,
                          ["aid", attributeAids.unpackedwidth]
                        );
                        const articleLength = find(
                          plarticle.packagedArticle.attributes,
                          ["aid", attributeAids.unpackedLength]
                        );

                        let placoArticleWidth: number = 0;
                        if (articleWidth) {
                          const metreValue = find(articleWidth.values, [
                            "unit",
                            "m",
                          ]);

                          if (metreValue) {
                            const mValue: string = get(metreValue, "value");
                            placoArticleWidth = parseFloat(mValue);
                          } else {
                            const milliMetreValue = find(articleWidth.values, [
                              "unit",
                              "mm",
                            ]);
                            if (milliMetreValue) {
                              const mmValue = get(milliMetreValue, "value");
                              placoArticleWidth = parseFloat(mmValue) / 1000;
                            }
                          }
                        }

                        let placoArticleLength: number = 0;

                        if (articleLength) {
                          const metreValue = find(articleLength.values, [
                            "unit",
                            "m",
                          ]);

                          if (metreValue) {
                            const mValue: string = get(metreValue, "value");
                            placoArticleLength = parseFloat(mValue);
                          } else {
                            const milliMetreValue = find(articleLength.values, [
                              "unit",
                              "mm",
                            ]);
                            if (milliMetreValue) {
                              const mmValue = get(milliMetreValue, "value");
                              placoArticleLength = parseFloat(mmValue) / 1000;
                            }
                          }
                        }

                        let customRatio = 0;
                        if (userRation[solutionId + plarticle.value]) {
                          customRatio =
                            userRation[solutionId + plarticle.value];
                        } else {
                          customRatio = objFinalratio;
                        }

                        collectedNewArticles.push({
                          level: levelName,
                          solution: ovurage,
                          articleName: plarticle.value,
                          elementHeight: numeralConversion(
                            round(elementHeight, 2)
                          ),
                          elementArea: numeralConversion(round(elementArea, 2)),
                          articleRevitLength: 0,
                          articleRevitHeight: 0,
                          nbUnit:
                            layerCount[plaque.product.translation] === undefined
                              ? 1
                              : layerCount[plaque.product.translation].count,
                          nbTotal: "",
                          articleWidth: placoArticleWidth,
                          articleLength: placoArticleLength,
                          elementType: elementType,
                          levelElevation: levelElevation,
                          ratio: objFinalratio,
                          nonLayout: true,
                          selected: selectedArticle.value === plarticle.value,
                          rationCustom: customRatio,
                          key: plarticle.value,
                          value: plarticle.value,
                          text: plarticle.value,
                        });
                      });
                      newPlaqRow.articleLists = collectedNewArticles;
                      collected_articles[`${elementId}/${plaque.product.oid}`] =
                        newPlaqRow;
                    } else {
                      console.error(
                        `For ${ovurage} plaque name ${
                          plaque.product.translation
                        } is not present in layers ${Object.keys(
                          layerCount
                        ).toString()}`
                      );
                    }
                  });
                });
              }
            }
          }
        }
      }
    });

    let plaqArray: Plaqs[] = [];

    forEach(collected_articles, (plaq: Plaqs, key: string) => {
      plaqArray.push(plaq);
    });

    return plaqArray;
  } catch (error) {
    throw "Schedules cannot be generated due to a plugin failure";
  }
};

export const collectEtageCeilingData = async (
  processedWallParams: any,
  extractedSolutionProduct: any,
  ceilingsData: any,
  setUnitArray: any,
  unitConversionArray: any,
  byRatio: boolean = false
) => {
  try {
    setUnitArray([], "");
    let finalExtract: any = [];

    await forEach(processedWallParams, (ceiling, index) => {
      const ceilingDetails = find(ceilingsData, { Id: ceiling.Id });
      const placoSolution = find(extractedSolutionProduct, (slProduct) => {
        return (
          slProduct.solutionProduct.technicalName === ceiling.Params[0].Value
        );
      });

      if (placoSolution) {
        const wools = filter(placoSolution.products, (prd) => {
          return prd.type === "wool";
        });

        const accessories = filter(placoSolution.products, (prd) => {
          return (
            prd.type === "coating" ||
            prd.type === "joints" ||
            prd.type === "resilent" ||
            prd.type === "screw" ||
            prd.type === "accessoires"
          );
        });

        const plaques = filter(placoSolution.products, (prd) => {
          return prd.type === "plaque";
        });
        const montants = filter(placoSolution.products, (prd) => {
          return prd.type === "montant";
        });

        finalExtract.push({
          ceilingDetails: ceilingDetails,
          placoSolution: placoSolution.solutionProduct,
          wools: wools,
          accessories: accessories,
          plaques: plaques,
          montants: montants,
          rails: [],
          layoutPossible: false,
          attributes: placoSolution.attributes,
          relationAttributes: placoSolution.relationAttributes,
          relations: placoSolution.relations,
        });
      }
    });

    let ratioArray: IRationTable[] = [];
    let ratioListArray: any = [];

    const collected_wool = await collectWool(finalExtract);
    collected_wool.forEach((colwool: woolType) => {
      if (colwool.articleLists) {
        const selectedObj = find(colwool.articleLists, { selected: true });
        ratioListArray.push({
          typeArticle: "Produits isolants",
          nameArticle: colwool.ProductName,
          ratioDefault: selectedObj.ratio,
          rationCustom: selectedObj.rationCustom,
          order: 4,
          placoSolution: colwool.Ovurage,
          solutionId: colwool.solutionId,
          unit: "M2",
          articleId: 1,
          articleList: colwool.articleLists,
          defaultValue: selectedObj.articleName,
        });
      } else {
        ratioListArray.push({
          typeArticle: "Produits isolants",
          nameArticle: colwool.ProductName,
          ratioDefault: colwool.Ratio,
          rationCustom: colwool.rationCustom,
          order: 4,
          placoSolution: colwool.Ovurage,
          unit: colwool.LabelUnit,
          articleList: null,
          solutionId: colwool.solutionId,
          defaultValue: colwool.ProductName,
        });
      }
    });

    const collected_accessory = await collectAccessory(finalExtract, {
      setUnitConversionArray: setUnitArray,
    });
    collected_accessory.forEach((colacc: Accessory) => {
      if (Object.keys(colacc.articles).length > 0) {
        forEach(colacc.articles, (clarticle: AccessoryArticle) => {
          ratioListArray.push({
            typeArticle: "Accessories",
            nameArticle: clarticle.article,
            ratioDefault: clarticle.ratio,
            rationCustom: clarticle.rationCustom,
            order: 5,
            placoSolution: colacc.solution,
            unit: clarticle.unitI,
            solutionId: clarticle.solutionId,
            articleList: null,
            defaultValue: clarticle.article,
          });
        });
      }
    });

    const collected_rail: any[] = [];

    const collected_montant = await collectMontant(
      finalExtract,
      processedWallParams,
      byRatio
    );

    collected_montant.forEach((colmont: Montant) => {
      if (colmont.articleLists) {
        const selectedObj = find(colmont.articleLists, { selected: true });

        ratioListArray.push({
          typeArticle: "Ossatures Vertical",
          nameArticle: colmont.articleName,
          placoSolution: colmont.solution,
          unit: "M",
          articleList: colmont.articleLists,
          articleId: colmont.articleId,
          solutionId: colmont.solutionId,
          order: 2,
          ratioDefault: selectedObj.ratio,
          rationCustom: selectedObj.rationCustom,
          defaultValue: selectedObj.articleName,
        });
      } else if (Object.keys(colmont.articles).length > 0) {
        forEach(colmont.articles, (clarticle: MontantArticle) => {
          if (clarticle.nonLayout) {
            ratioListArray.push({
              typeArticle: "Ossatures Vertical",
              nameArticle: clarticle.articleName,
              ratioDefault: clarticle.ratio || 0,
              rationCustom: clarticle.rationCustom,
              order: 2,
              placoSolution: colmont.solution,
              unit: "M",
              articleList: null,
              defaultValue: clarticle.articleName,
            });
          }
        });
      }
    });

    const collected_plaques = await collectPlaques(
      finalExtract,
      processedWallParams,
      byRatio
    );

    forEach(collected_plaques, (colplaq: Plaqs) => {
      if (colplaq.articleLists) {
        const selectedObj = find(colplaq.articleLists, { selected: true });

        ratioListArray.push({
          typeArticle: "Produits plâtre",
          nameArticle: colplaq.articleName,
          placoSolution: colplaq.solution,
          unit: "M2",
          articleList: colplaq.articleLists,
          articleId: colplaq.articleId,
          solutionId: colplaq.solutionId,
          order: 1,
          ratioDefault: selectedObj.ratio,
          rationCustom: selectedObj.rationCustom,
          defaultValue: selectedObj.articleName,
        });
      } else if (Object.keys(colplaq.articles).length > 0) {
        forEach(colplaq.articles, (clarticle: PlaqArticle) => {
          if (clarticle.nonLayout) {
            ratioListArray.push({
              typeArticle: "Produits plâtre",
              nameArticle: clarticle.articleName,
              ratioDefault: clarticle.ratio || 0,
              rationCustom: clarticle.rationCustom,
              order: 1,
              placoSolution: colplaq.solution,
              unit: "M2",
              articleList: null,
              defaultValue: clarticle.articleName,
            });
          }
        });
      }
    });

    if (byRatio) {
      ratioListArray = uniqWith(
        ratioListArray,
        (a: any, b: any) =>
          a.solutionId === b.solutionId && a.nameArticle === b.nameArticle
      );
    } else {
      ratioListArray = uniqWith(
        ratioListArray,
        (a: any, b: any) =>
          a.solutionId === b.solutionId && a.defaultValue === b.defaultValue
      );
    }

    const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

    return {
      collected_wool,
      collected_accessory,
      collected_rail,
      collected_montant,
      collected_plaques,
      default_ratio: [],
      finalExtract: finalExtract,
      userRatio: userRation,
      mapZoneWall: null,
      processedWallParams: null,
      ratioListArray: ratioListArray,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const collectZoneCeilingData = async (
  processedWallParams: any,
  extractedSolutionProduct: any,
  ceilingssData: any,
  setUnitArray: any,
  mapZoneWall: any,
  unitConversionArray: any,
  byRatio: boolean = false
) => {
  let finalExtract: any = [];

  await forEach(processedWallParams, (wall, index) => {
    const ceilingDetails = find(ceilingssData, { Id: wall.Id });
    mapZoneWall.forEach((fin: any) => {
      fin.ceilings.forEach((id: any) => {
        if (ceilingDetails.Id === id) {
          ceilingDetails.zone = fin.zone;
        }
      });
    });
    const placoSolution = find(extractedSolutionProduct, (slProduct) => {
      return slProduct.solutionProduct.technicalName === wall.Params[0].Value;
    });

    const wools = filter(placoSolution.products, (prd) => {
      return prd.type === "wool";
    });

    const accessories = filter(placoSolution.products, (prd) => {
      return (
        prd.type === "coating" ||
        prd.type === "joints" ||
        prd.type === "resilent" ||
        prd.type === "screw"
      );
    });

    const plaques = filter(placoSolution.products, (prd) => {
      return prd.type === "plaque";
    });
    const montants = filter(placoSolution.products, (prd) => {
      return prd.type === "montant";
    });

    finalExtract.push({
      ceilingDetails: ceilingDetails,
      placoSolution: placoSolution.solutionProduct,
      wools: wools,
      accessories: accessories,
      plaques: plaques,
      montants: montants,
      rails: [],
      layoutPossible: placoSolution.layoutPossible,
      attributes: placoSolution.attributes,
      relationAttributes: placoSolution.relationAttributes,
      relations: placoSolution.relations,
    });
  });

  let ratioArray: IRationTable[] = [];
  let ratioListArray: any = [];

  const collected_wool = await collectZoneWool(finalExtract, mapZoneWall);
  collected_wool.forEach((colwool: woolType) => {
    if (colwool.articleLists) {
      const selectedObj = find(colwool.articleLists, { selected: true });
      ratioListArray.push({
        typeArticle: "Produits isolants",
        nameArticle: colwool.ProductName,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        order: 4,
        placoSolution: colwool.Ovurage,
        solutionId: colwool.solutionId,
        unit: "M2",
        articleId: 1,
        articleList: colwool.articleLists,
        defaultValue: selectedObj.articleName,
      });
    } else {
      ratioListArray.push({
        typeArticle: "Produits isolants",
        nameArticle: colwool.ProductName,
        ratioDefault: colwool.Ratio,
        rationCustom: colwool.rationCustom,
        order: 4,
        placoSolution: colwool.Ovurage,
        unit: colwool.LabelUnit,
        articleList: null,
        solutionId: colwool.solutionId,
        defaultValue: colwool.ProductName,
      });
    }
  });

  const collected_accessory = await collectZoneAccessory(
    finalExtract,
    {
      setUnitConversionArray: setUnitArray,
    },
    mapZoneWall
  );
  collected_accessory.forEach((colacc: Accessory) => {
    if (Object.keys(colacc.articles).length > 0) {
      forEach(colacc.articles, (clarticle: AccessoryArticle) => {
        ratioListArray.push({
          typeArticle: "Accessories",
          nameArticle: clarticle.article,
          ratioDefault: clarticle.ratio,
          rationCustom: clarticle.rationCustom,
          order: 5,
          placoSolution: colacc.solution,
          unit: clarticle.unitI,
          solutionId: clarticle.solutionId,
          articleList: null,
          defaultValue: clarticle.article,
        });
      });
    }
  });

  const collected_rail: any[] = [];

  let collected_montant: Montant[] = await collectZoneMontant(
    finalExtract,
    mapZoneWall,
    processedWallParams,
    byRatio
  );
  collected_montant.forEach((colmont: Montant) => {
    if (colmont.articleLists) {
      const selectedObj = find(colmont.articleLists, { selected: true });

      ratioListArray.push({
        typeArticle: "Ossatures Vertical",
        nameArticle: colmont.articleName,
        placoSolution: colmont.solution,
        unit: "M",
        articleList: colmont.articleLists,
        articleId: colmont.articleId,
        solutionId: colmont.solutionId,
        order: 2,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        defaultValue: selectedObj.articleName,
      });
    } else if (Object.keys(colmont.articles).length > 0) {
      forEach(colmont.articles, (clarticle: MontantArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Ossatures Vertical",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 2,
            placoSolution: colmont.solution,
            unit: "M",
            articleList: null,
            solutionId: colmont.solutionId,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  let collected_plaques: Plaqs[] = await collectZonePlaque(
    finalExtract,
    mapZoneWall,
    processedWallParams,
    byRatio
  );

  forEach(collected_plaques, (colplaq: Plaqs) => {
    if (colplaq.articleLists) {
      const selectedObj = find(colplaq.articleLists, { selected: true });

      ratioListArray.push({
        typeArticle: "Produits plâtre",
        nameArticle: colplaq.articleName,
        placoSolution: colplaq.solution,
        unit: "M2",
        articleList: colplaq.articleLists,
        articleId: colplaq.articleId,
        solutionId: colplaq.solutionId,
        order: 1,
        ratioDefault: selectedObj.ratio,
        rationCustom: selectedObj.rationCustom,
        defaultValue: selectedObj.articleName,
      });
    } else if (Object.keys(colplaq.articles).length > 0) {
      forEach(colplaq.articles, (clarticle: PlaqArticle) => {
        if (clarticle.nonLayout) {
          ratioListArray.push({
            typeArticle: "Produits plâtre",
            nameArticle: clarticle.articleName,
            ratioDefault: clarticle.ratio || 0,
            rationCustom: clarticle.rationCustom,
            order: 1,
            placoSolution: colplaq.solution,
            unit: "M2",
            articleList: null,
            solutionId: colplaq.solutionId,
            defaultValue: clarticle.articleName,
          });
        }
      });
    }
  });

  if (byRatio) {
    ratioListArray = uniqWith(
      ratioListArray,
      (a: any, b: any) =>
        a.solutionId === b.solutionId && a.nameArticle === b.nameArticle
    );
  } else {
    ratioListArray = uniqWith(
      ratioListArray,
      (a: any, b: any) =>
        a.solutionId === b.solutionId && a.defaultValue === b.defaultValue
    );
  }

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  return {
    collected_wool,
    collected_accessory,
    collected_rail,
    collected_montant,
    collected_plaques,
    default_ratio: [],
    finalExtract: finalExtract,
    userRatio: userRation,
    mapZoneWall: mapZoneWall,
    processedWallParams: processedWallParams,
    ratioListArray: ratioListArray,
  };
};

export const collectZoneWool = async (finalExtract: any, mapZoneWall: any) => {
  let woolCollection: woolType[] = [];
  forEach(finalExtract, (extractObj, extractIndex) => {
    const woolKeySearchArray = ["PAR", "GR 32", "Isoconfort", "Laine"];

    extractObj.wools.forEach((element: any) => {
      const woolAcceptable = find(woolKeySearchArray, (o: any) => {
        return startsWith(element.product.translation, o);
      });

      if (woolAcceptable) {
        const includeLainDEVerre = includes(
          element.product.translation,
          "Laine de verre"
        );

        if (!includeLainDEVerre) {
          const woolZone = find(mapZoneWall, (m) => {
            return includes(m.ceilings, extractObj.ceilingDetails.Id);
          });

          const gfrSellingPackaging = find(element.product.attributes, {
            technicalName: "GFR-GP Selling Packaging",
          });
          let sellingPackage = "0";
          if (gfrSellingPackaging) {
            sellingPackage = gfrSellingPackaging.values[0].value;
          }

          const gfrLabelUnit = find(element.product.attributes, {
            technicalName: "GFR-Label unit (UE)",
          });

          let labelUnit: any = "";
          if (gfrLabelUnit) {
            labelUnit = gfrLabelUnit.values[0].value;
            if (labelUnit) {
              labelUnit = labelUnit.split(",")[0];
            }
          } else {
            labelUnit = articleunit["ceiling"]["Ovurages isolants"];
          }

          const gfrEan = find(element.product.attributes, {
            technicalName: "GFR-EAN code ISOVER article",
          });

          let ean = "";
          if (gfrEan) {
            ean = gfrEan.values[0].value;
          }

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const ration = findProductToProduct(
            extractObj.relations,
            element.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          let finalRatio =
            extractObj.layoutPossible === "false"
              ? parseFloat(ration) || 1.05
              : 1.05;

          let woolRow: woolType = {
            Level: extractObj.ceilingDetails.LevelName,
            LevelElevation: extractObj.ceilingDetails.LevelElevation,
            Ovurage: extractObj.placoSolution.translation,
            ProductName: element.product.translation,
            ElementHeight: numeralConversion(
              round(extractObj.ceilingDetails.Height / 1000)
            ),
            ElementArea: round(extractObj.ceilingDetails.Area, 2),
            Ratio: finalRatio,
            WoolArea: round(extractObj.ceilingDetails.Area * 1.05, 2),
            Rolls: "",
            ElementType: extractObj.ceilingDetails.WallType,
            ElementId: extractObj.ceilingDetails.Id,
            Zone: woolZone.zone,
            SellingPackage: sellingPackage,
            LabelUnit: labelUnit,
            Ean: ean,
            articleLists: null,
            selected: true,
          };

          woolCollection.push(woolRow);
        }
      }
    });
  });

  return woolCollection;
};

export const collectZoneAccessory = async (
  finalExtract: any,
  props: any,
  mapZoneWall: any
) => {
  const primaryObject: any = {};

  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.ceilingDetails.WallType;
    const wallHeight = extractObj.ceilingDetails.Height / 1000;
    const wallArea = extractObj.ceilingDetails.Area;
    const wallId = extractObj.ceilingDetails.Id;
    const solutionId = extractObj.placoSolution.oid;

    const accessoryZone = find(mapZoneWall, (m) => {
      return includes(m.ceilings, extractObj.ceilingDetails.Id);
    });

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 1) {
          const selectedArticle = find(accessoryObj.articles, function (o) {
            return (
              listToFind.findIndex((account: string) => {
                return account.startsWith(o.packagedArticle.translation);
              }, o.packagedArticle.translation) > -1
            );
          });
          if (selectedArticle) {
            selectedAccessoryPackagedArticle = selectedArticle.packagedArticle;
          }
        } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
          if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        } else {
          selectedAccessoryPackagedArticle =
            accessoryObj.articles[0].packagedArticle;
        }

        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const ratio = findProductToProduct(
            extractObj.relations,
            accessoryObj.product.oid,
            relationAttributeAids.ratio,
            extractObj.placoSolution.oid
          );

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article

          if (objArticle) {
            const objSurfaceArea = numeralConversion(wallArea); //Surface Area
            const objFinalratio = ratio
              ? ratio === "" || ratio === "999" || ratio === "9999"
                ? 0
                : parseFloat(ratio)
              : 0; // Ratio
            let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
            let objIQuantity: number | string = ""; //I Quantity
            let objSalesunit = ""; //J Unite De Vente

            if (objFinalunite === "") {
              const accessoryObject = articleunit["ceiling"]["Accessories"];
              forEach(accessoryObject, (value: string, key: string) => {
                if (objArticle.includes(key)) {
                  objFinalunite = value;
                }
              });
            }

            const articleAttribute =
              selectedAccessoryPackagedArticle.attributes;
            const dbsalesunit = dbSalesUnit(
              distributionChannelsAids,
              articleAttribute
            );

            const saleunit = find(articleAttribute, [
              "aid",
              attributeAids.baseunit,
            ]);

            if (dbsalesunit) {
              objSalesunit = uniteDeVenteArray(dbsalesunit);
            } else {
              if (saleunit) {
                const saleunit_value = get(saleunit.values[0], "value");
                objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
              }
            }

            let alternativeunit;
            if (objFinalunite) {
              alternativeunit = alternativeUnit(
                alternativeUnitsAids,
                articleAttribute,
                parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
                objFinalunite,
                objSalesunit,
                props,
                "accessory"
              );

              if (alternativeunit) {
                objIQuantity = alternativeunit;
              }
            }

            /**
             * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
             */
            const primaryObjectKey: string = wallId;
            let primaryArticle: any = primaryObject[primaryObjectKey] || {};

            /**
             * Extract secondary article from primary article, Key = article name
             */
            const secondaryArticletKey: string = objArticle;

            /**
             * Secondary article are sorted based on Article name
             * Extract secondary article or assign new
             * While assigning new assign surfaceArea, ratio, quantiteI to 0
             */
            let secondaryArticle: any = primaryArticle[
              secondaryArticletKey
            ] || {
              levelName: levelName,
              solutionName: ovurage,
              article: objArticle,
              elementHeight: wallHeight,
              surfaceArea: 0,
              ratio: 0,
              quantite: "",
              quantiteUnit: objFinalunite,
              quantiteI: 0,
              salesUnit: objSalesunit,
              elementType: wallType,
              levelElevation: levelElevation,
              elementId: wallId,
              zone: accessoryZone.zone,
              alternativeUnitsAids: alternativeUnitsAids,
              articleAttribute: articleAttribute,
              selected: true,
              solutionId: solutionId,
            };

            /**
             * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
             */
            primaryArticle[secondaryArticletKey] = {
              ...secondaryArticle,
              surfaceArea:
                secondaryArticle.surfaceArea +
                parseFloat(objSurfaceArea.toFixed(3)),
              ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
              quantiteI: secondaryArticle.quantiteI + objIQuantity,
            };

            /**
             * Assign newly calculate primary article .
             */

            primaryObject[primaryObjectKey] = primaryArticle;
            primaryObject[primaryObjectKey]["surfaceArea"] =
              primaryArticle[secondaryArticletKey].surfaceArea;
            primaryObject[primaryObjectKey]["level"] =
              primaryArticle[secondaryArticletKey].levelName;
            primaryObject[primaryObjectKey]["levelElevation"] =
              primaryArticle[secondaryArticletKey].levelElevation;
            primaryObject[primaryObjectKey]["zone"] =
              primaryArticle[secondaryArticletKey].zone;
            primaryObject[primaryObjectKey]["solution"] =
              primaryArticle[secondaryArticletKey].solutionName;
            primaryObject[primaryObjectKey]["elementHeight"] =
              primaryArticle[secondaryArticletKey].elementHeight;
            primaryObject[primaryObjectKey]["elementId"] =
              primaryArticle[secondaryArticletKey].elementId;
            primaryObject[primaryObjectKey]["solutionId"] =
              primaryArticle[secondaryArticletKey].solutionId;
          }
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let elementHeight = prmObj.elementHeight;
    let elementId = prmObj.elementId;
    let zone = prmObj.zone;
    let articles: AccessoryArticle[] = [];
    let solutionId = prmObj.solutionId;

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "elementHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "zone" &&
        articleIndex !== "elementId" &&
        articleIndex !== "solutionId"
      ) {
        let customRatio = 0;
        if (userRation[solutionId + articleObj.article]) {
          customRatio = userRation[solutionId + articleObj.article];
        } else {
          customRatio = articleObj.ratio;
        }
        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(elementHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.elementType,
          levelElevation: articleObj.levelElevation,
          elementId: elementId,
          zone: zone,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
          rationCustom: customRatio,
          solutionId: solutionId,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(elementHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: elementId,
      zone: zone,
    });
  });
  return collected_articles;
};

export const collectCeilingZoneAccessoryWithCustomRatio = async (
  finalExtract: any,
  defaultRatio: any,
  props: any,
  mapZoneWall: any
) => {
  const primaryObject: any = {};

  forEach(finalExtract, (extractObj, extractIndex) => {
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const wallType = extractObj.ceilingDetails.WallType;
    const wallHeight = extractObj.ceilingDetails.Height / 1000;
    const wallArea = extractObj.ceilingDetails.Area;
    const wallId = extractObj.ceilingDetails.Id;
    const accessoryZone = find(mapZoneWall, (m) => {
      return includes(m.ceilings, extractObj.ceilingDetails.Id);
    });

    forEach(extractObj.accessories, (accessoryObj, accessoryId) => {
      let listToFind: any = undefined;

      if (accessoryObj.type === "coating") {
        listToFind = coatingList;
      }

      if (accessoryObj.type === "screw") {
        listToFind = screwList;
      }

      let selectedAccessoryPackagedArticle = undefined;

      if (accessoryObj.articles) {
        if (accessoryObj.articles.length > 1) {
          const selectedArticle = find(accessoryObj.articles, function (o) {
            return (
              listToFind.findIndex((account: string) => {
                return account.startsWith(o.packagedArticle.translation);
              }, o.packagedArticle.translation) > -1
            );
          });
          if (selectedArticle) {
            selectedAccessoryPackagedArticle = selectedArticle.packagedArticle;
          }
        } else if (startsWith(accessoryObj.articles[0].translation, "Vis")) {
          if (listToFind.indexOf(accessoryObj.articles[0].translation) > -1) {
            selectedAccessoryPackagedArticle =
              accessoryObj.articles[0].packagedArticle;
          }
        } else {
          selectedAccessoryPackagedArticle =
            accessoryObj.articles[0].packagedArticle;
        }

        if (selectedAccessoryPackagedArticle) {
          const attributeDescriptors = extractObj.attributes;

          const attributeAids = packageAttributeSelector(attributeDescriptors);

          const relationAttributeAids = relationAttributeSelector(
            extractObj.relationAttributes
          );

          const distributionChannelsAids =
            distributionChannelsAttributeSelector(attributeDescriptors);

          const alternativeUnitsAids =
            alternativeUnitsSelector(attributeDescriptors);

          const unite =
            selectedAccessoryPackagedArticle.translation !==
            "Joint-Colle PU Aquaroc®"
              ? findProductToProduct(
                  extractObj.relations,
                  accessoryObj.product.oid,
                  relationAttributeAids.unite,
                  extractObj.placoSolution.oid
                )
              : "Pièce";

          const objArticle = selectedAccessoryPackagedArticle.translation; // Article
          const objSurfaceArea = numeralConversion(wallArea); //Surface Area
          const customRatioObj: any = defaultRatio.find(
            (def: any) => def.nameArticle === objArticle
          );
          const objFinalratio = customRatioObj.rationCustom
            ? customRatioObj.rationCustom === "" ||
              customRatioObj.rationCustom === "999" ||
              customRatioObj.rationCustom === "9999"
              ? 0
              : parseFloat(customRatioObj.rationCustom)
            : 0; // Ratio
          let objFinalunite = unite ? uniteDeVenteArray(unite) : ""; // final unite
          let objIQuantity: number | string = ""; //I Quantity
          let objSalesunit = ""; //J Unite De Vente

          if (objFinalunite === "") {
            const accessoryObject = articleunit["ceiling"]["Accessories"];
            forEach(accessoryObject, (value: string, key: string) => {
              if (objArticle.includes(key)) {
                objFinalunite = value;
              }
            });
          }

          const articleAttribute = selectedAccessoryPackagedArticle.attributes;
          const dbsalesunit = dbSalesUnit(
            distributionChannelsAids,
            articleAttribute
          );

          const saleunit = find(articleAttribute, [
            "aid",
            attributeAids.baseunit,
          ]);

          if (dbsalesunit) {
            objSalesunit = uniteDeVenteArray(dbsalesunit);
          } else {
            if (saleunit) {
              const saleunit_value = get(saleunit.values[0], "value");
              objSalesunit = uniteDeVenteArray(saleunit_value.split(",")[0]);
            }
          }

          let alternativeunit;
          if (objFinalunite) {
            alternativeunit = alternativeUnit(
              alternativeUnitsAids,
              articleAttribute,
              parseFloat((objSurfaceArea * objFinalratio).toFixed(3)),
              objFinalunite,
              objSalesunit,
              props,
              "accessory"
            );

            if (alternativeunit) {
              objIQuantity = alternativeunit;
            }
          }

          /**
           * Single object from primaryObject, extracted using key(For ex. `${levelName}/${solnName}/${wallHeight}`)
           */
          const primaryObjectKey: string = wallId;
          let primaryArticle: any = primaryObject[primaryObjectKey] || {};

          /**
           * Extract secondary article from primary article, Key = article name
           */
          const secondaryArticletKey: string = objArticle;

          /**
           * Secondary article are sorted based on Article name
           * Extract secondary article or assign new
           * While assigning new assign surfaceArea, ratio, quantiteI to 0
           */
          let secondaryArticle: any = primaryArticle[secondaryArticletKey] || {
            levelName: levelName,
            solutionName: ovurage,
            article: objArticle,
            elementHeight: wallHeight,
            surfaceArea: 0,
            ratio: 0,
            quantite: "",
            quantiteUnit: objFinalunite,
            quantiteI: 0,
            salesUnit: objSalesunit,
            elementType: wallType,
            levelElevation: levelElevation,
            elementId: wallId,
            zone: accessoryZone.zone,
            alternativeUnitsAids: alternativeUnitsAids,
            articleAttribute: articleAttribute,
          };

          /**
           * Now add newly calculated value of surfaceArea, ratio, quantiteI to old
           */
          primaryArticle[secondaryArticletKey] = {
            ...secondaryArticle,
            surfaceArea:
              secondaryArticle.surfaceArea +
              parseFloat(objSurfaceArea.toFixed(3)),
            ratio: secondaryArticle.ratio + numeralConversion(objFinalratio),
            quantiteI: secondaryArticle.quantiteI + objIQuantity,
          };

          /**
           * Assign newly calculate primary article .
           */

          primaryObject[primaryObjectKey] = primaryArticle;
          primaryObject[primaryObjectKey]["surfaceArea"] =
            primaryArticle[secondaryArticletKey].surfaceArea;
          primaryObject[primaryObjectKey]["level"] =
            primaryArticle[secondaryArticletKey].levelName;
          primaryObject[primaryObjectKey]["levelElevation"] =
            primaryArticle[secondaryArticletKey].levelElevation;
          primaryObject[primaryObjectKey]["zone"] =
            primaryArticle[secondaryArticletKey].zone;
          primaryObject[primaryObjectKey]["solution"] =
            primaryArticle[secondaryArticletKey].solutionName;
          primaryObject[primaryObjectKey]["elementHeight"] =
            primaryArticle[secondaryArticletKey].elementHeight;
          primaryObject[primaryObjectKey]["elementId"] =
            primaryArticle[secondaryArticletKey].elementId;
        }
      }
    });
  });

  const collected_articles: Accessory[] = [];
  forEach(primaryObject, (prmObj: any, prmObj_index: any) => {
    // let prmObjKeyArray = prmObj_index.split("/~/");
    let level = prmObj.level;
    let levelElevation = prmObj.levelElevation;
    let solution = prmObj.solution;
    let elementHeight = prmObj.elementHeight;
    let elementId = prmObj.elementId;
    let zone = prmObj.zone;
    let articles: AccessoryArticle[] = [];

    forEach(prmObj, (articleObj: any, articleIndex: any) => {
      if (
        articleIndex !== "surfaceArea" &&
        articleIndex !== "level" &&
        articleIndex !== "solution" &&
        articleIndex !== "elementHeight" &&
        articleIndex !== "levelElevation" &&
        articleIndex !== "zone" &&
        articleIndex !== "elementId"
      ) {
        articles.push({
          level: articleObj.levelName,
          solution: solution,
          article: articleObj.article,
          elementHeight: round(elementHeight, 2),
          elementArea: articleObj.surfaceArea,
          ratio: articleObj.ratio,
          quantityI: 0,
          unitI: articleObj.quantiteUnit,
          quantityS: articleObj.quantiteI,
          unitS: articleObj.salesUnit,
          elementType: articleObj.elementType,
          levelElevation: articleObj.levelElevation,
          elementId: elementId,
          zone: zone,
          alternativeUnitsAids: articleObj.alternativeUnitsAids,
          articleAttribute: articleObj.articleAttribute,
        });
      }
    });

    collected_articles.push({
      level: level,
      solution: solution,
      elementHeight: round(elementHeight, 2),
      elementArea: prmObj.surfaceArea,
      articles: articles,
      levelElevation: levelElevation,
      elementId: elementId,
      zone: zone,
    });
  });
  return collected_articles;
};

export const collectZoneMontant = async (
  finalExtract: any,
  mapZoneWall: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: { [Key: string]: Montant } = {};
  let montantsRevitData = await extractFramesDatafromRevit();
  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);

  forEach(finalExtract, (extractObj, extractIndex) => {
    let elementId = extractObj.ceilingDetails.Id;
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const elementType = extractObj.ceilingDetails.CeilingType;
    const elementHeight = extractObj.ceilingDetails.Height / 1000;
    const elementArea = extractObj.ceilingDetails.Area;
    const solutionId = extractObj.placoSolution.oid;
    const montantZone = find(mapZoneWall, (m) => {
      return includes(m.ceilings, extractObj.ceilingDetails.Id);
    });

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      let revitData = filter(montantsRevitData, function (o) {
        return o.wallId === parseInt(elementId);
      });

      forEach(revitData, (revitMontant, index) => {
        forEach(extractObj.montants, (montant, index) => {
          const selectedArticle = find(montant.articles, function (o) {
            return revitMontant.productName === o.value;
          });

          if (selectedArticle) {
            const selectedPackagedArticle = selectedArticle.packagedArticle;

            if (selectedPackagedArticle) {
              const attributeDescriptors = extractObj.attributes;

              const attributeAids =
                packageAttributeSelector(attributeDescriptors);

              let wallH = numeralConversion(round(elementHeight, 2));
              let wallA = numeralConversion(round(elementArea, 2));

              let newMontantRow: Montant = collected_articles[
                `${elementId}`
              ] || {
                level: levelName,
                solution: ovurage,
                articleName: selectedPackagedArticle.translation,
                elementHeight: wallH,
                elementArea: wallA,
                elementType: elementType,
                articles: {},
                levelElevation: levelElevation,
                elementId: elementId,
                zone: montantZone.zone,
                articleLists: null,
              };

              const articleLength = find(selectedPackagedArticle.attributes, [
                "aid",
                attributeAids.unpackedLength,
              ]);

              if (
                selectedPackagedArticle.translation.includes(
                  revitData[0].productName
                )
              ) {
                let sortedRevitData = sortBy(revitData[0].data, [
                  function (o) {
                    return round(o.length / 1000, 2);
                  },
                ]);

                for (let dtt of sortedRevitData) {
                  const length_in_m = dtt.length / 1000;
                  const quantity = dtt.quantity;

                  let articleRevitHeight = round(length_in_m, 2);
                  let calArticleLength: number = 0;

                  if (articleLength) {
                    const metreValue = find(articleLength.values, [
                      "unit",
                      "m",
                    ]);

                    if (metreValue) {
                      const mValue = get(metreValue, "value");
                      calArticleLength = mValue;
                    } else {
                      const milliMetreValue = find(articleLength.values, [
                        "unit",
                        "mm",
                      ]);
                      if (milliMetreValue) {
                        const mmValue = get(milliMetreValue, "value");
                        calArticleLength = parseFloat(mmValue) / 1000;
                      }
                    }
                  }

                  let collected_article = newMontantRow.articles[
                    `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                  ] || {
                    level: levelName,
                    solution: ovurage,
                    articleName: selectedPackagedArticle.translation,
                    elementHeight: wallH,
                    elementArea: wallA,
                    articleRevitHeight: articleRevitHeight,
                    nbUnit: 0,
                    nbTotal: 0,
                    articleLength: calArticleLength,
                    elementType: elementType,
                    levelElevation: levelElevation,
                    zone: montantZone.zone,
                    ratio: 0,
                    nonLayout: false,
                    selected: true,
                  };

                  collected_article.nbUnit =
                    collected_article.nbUnit + numeralConversion(quantity);

                  newMontantRow.articles[
                    `${selectedPackagedArticle.translation}/${articleRevitHeight}`
                  ] = collected_article;

                  collected_articles[`${elementId}`] = newMontantRow;
                }
              }
            }
          }
        });
      });
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === elementId;
      });

      if (revitData) {
        const userMontantObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Montant"
        );

        if (userMontantObj) {
          if (userMontantObj.Value) {
            const userMontantArray = userMontantObj.Value.split(";");

            if (userMontantArray) {
              userMontantArray.forEach((userMon: string) => {
                forEach(extractObj.montants, (montant, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    montant.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = montant.articles.find(
                    (plart: any) => plart.value === userMon
                  );

                  if (selectedArticle) {
                    let newMontantRow: Montant = collected_articles[
                      `${elementId}/${montant.product.oid}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      elementId: elementId,
                      articleName: selectedArticle.value,
                      elementHeight: numeralConversion(round(elementHeight, 2)),
                      elementArea: numeralConversion(round(elementArea, 2)),
                      elementType: elementType,
                      articles: {},
                      levelElevation: levelElevation,
                      zone: montantZone.zone,
                      solutionId: solutionId,
                      articleId: montant.product.oid,
                      articleLists: null,
                    };

                    let collectedNewArticles: any = [];

                    forEach(montant.articles, (moarticle, moArticleIndex) => {
                      const attributeDescriptors = extractObj.attributes;

                      const attributeAids =
                        packageAttributeSelector(attributeDescriptors);

                      const articleLength = find(
                        moarticle.packagedArticle.attributes,
                        ["aid", attributeAids.unpackedLength]
                      );

                      let calArticleLength: number = 0;

                      if (articleLength) {
                        const metreValue = find(articleLength.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue = get(metreValue, "value");
                          calArticleLength = mValue;
                        } else {
                          const milliMetreValue = find(articleLength.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            calArticleLength = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let customRatio = 0;
                      if (userRation[solutionId + moarticle.value]) {
                        customRatio = userRation[solutionId + moarticle.value];
                      } else {
                        customRatio = objFinalratio;
                      }

                      collectedNewArticles.push({
                        level: levelName,
                        solution: ovurage,
                        articleName: moarticle.value,
                        elementHeight: numeralConversion(
                          round(elementHeight, 2)
                        ),
                        elementArea: numeralConversion(round(elementArea, 2)),
                        articleRevitHeight: 0,
                        nbUnit: 0,
                        nbTotal: 0,
                        articleLength: calArticleLength,
                        elementType: elementType,
                        levelElevation: levelElevation,
                        ratio: objFinalratio,
                        zone: montantZone.zone,
                        nonLayout: true,
                        selected: selectedArticle.value === moarticle.value,
                        rationCustom: customRatio,
                        key: moarticle.value,
                        value: moarticle.value,
                        text: moarticle.value,
                      });
                    });

                    newMontantRow.articleLists = collectedNewArticles;
                    collected_articles[`${elementId}/${montant.product.oid}`] =
                      newMontantRow;
                  }
                });
              });
            }
          }
        }
      }
    }
  });

  /**
   * Sort montants by level -> solution
   */
  let montantArray: Montant[] = [];

  forEach(collected_articles, (plaq: Montant, key: string) => {
    montantArray.push(plaq);
  });

  return montantArray;
};

export const collectZonePlaque = async (
  finalExtract: any,
  mapZoneWall: any,
  processedWallParams: any,
  byRatio: boolean
) => {
  const collected_articles: {
    [Key: string]: Plaqs;
  } = {};

  let plaqueRevitData = await extractPlasterboardDataForWallfromRevit();

  const userRation = await bimStorage.getItem(storageKey.QUANTIFY);
  forEach(finalExtract, (extractObj, extractIndex) => {
    let elementId = extractObj.ceilingDetails.Id;
    const levelName = extractObj.ceilingDetails.LevelName;
    const levelElevation = extractObj.ceilingDetails.LevelElevation;
    const ovurage = extractObj.placoSolution.translation;
    const elementType = extractObj.ceilingDetails.CeilingType;
    const elementHeight = extractObj.ceilingDetails.Height / 1000;
    const elementArea = extractObj.ceilingDetails.Area;
    const solutionId = extractObj.placoSolution.oid;
    const plaqueZone = find(mapZoneWall, (m) => {
      return includes(m.ceilings, extractObj.ceilingDetails.Id);
    });

    let attributesId: any = getLayoutAttributesAid(extractObj.attributes);

    let layers = extractObj.placoSolution.attributes.filter(
      (e: any) => e.aid === attributesId.layers
    );
    let layerNames = compact(
      layers.map((ln: any) => {
        const lnmm = ln.subAttributes.find(
          (sl: any) => sl.technicalName === "GFR-Layer name"
        );
        return lnmm.values[0];
      })
    );

    let layerCount: any = groupBy(layerNames, "value");
    layerCount = keyBy(
      map(layerCount, (ly, key) => {
        return { key: key, count: ly.length };
      }),
      "key"
    );

    if (extractObj.layoutPossible === "true" && byRatio === false) {
      forEach(extractObj.plaques, (plaque, index) => {
        let revitData = filter(plaqueRevitData, function (o) {
          return o.wallId === parseInt(elementId);
        });

        if (revitData.length > 0) {
          forEach(revitData, (revtData, index) => {
            let selectedArticle = find(plaque.articles, {
              value: revtData.productName,
            });

            if (selectedArticle) {
              const selectedPackagedArticle = selectedArticle.packagedArticle;

              if (selectedPackagedArticle) {
                const attributeDescriptors = extractObj.attributes;

                const attributeAids =
                  packageAttributeSelector(attributeDescriptors);

                let newPlaqRow: Plaqs = collected_articles[`${elementId}`] || {
                  level: levelName,
                  solution: ovurage,
                  elementId: elementId,
                  articleName: selectedPackagedArticle.translation,
                  elementHeight: numeralConversion(round(elementHeight, 2)),
                  elementArea: numeralConversion(round(elementArea, 2)),
                  articles: {},
                  levelElevation: levelElevation,
                  zone: plaqueZone.zone,
                };

                const articleWidth = find(selectedPackagedArticle.attributes, [
                  "aid",
                  attributeAids.unpackedwidth,
                ]);
                const articleLength = find(selectedPackagedArticle.attributes, [
                  "aid",
                  attributeAids.unpackedLength,
                ]);

                if (
                  selectedPackagedArticle.translation
                    .replace(/[\s'®]/g, "")
                    .replace(/[^a-zA-Z0-9]\s/g, "")
                    .includes(
                      revtData.productName
                        .replace(/[\s'®]/g, "")
                        .replace(/[^a-zA-Z0-9]\s/g, "")
                    )
                ) {
                  /**
                   * Sort data by length
                   */

                  let sortedRevitData = sortBy(revtData.data, [
                    function (o) {
                      return round(o.length / 1000, 2);
                    },
                  ]).reverse();

                  for (let dtt of sortedRevitData) {
                    const length_in_m = round(dtt.length / 1000, 2);
                    const height_in_m = round(dtt.height / 1000, 2);
                    const quantity = dtt.quantity;
                    const wallH = numeralConversion(round(elementHeight, 2));
                    const wallA = numeralConversion(round(elementArea, 2));
                    let placoArticleWidth: number = 0;

                    if (articleWidth) {
                      const metreValue = find(articleWidth.values, [
                        "unit",
                        "m",
                      ]);

                      if (metreValue) {
                        const mValue: string = get(metreValue, "value");
                        placoArticleWidth = parseFloat(mValue);
                      } else {
                        const milliMetreValue = find(articleWidth.values, [
                          "unit",
                          "mm",
                        ]);
                        if (milliMetreValue) {
                          const mmValue = get(milliMetreValue, "value");
                          placoArticleWidth = parseFloat(mmValue) / 1000;
                        }
                      }
                    }

                    let placoArticleLength: number = 0;

                    if (articleLength) {
                      const metreValue = find(articleLength.values, [
                        "unit",
                        "m",
                      ]);

                      if (metreValue) {
                        const mValue: string = get(metreValue, "value");
                        placoArticleLength = parseFloat(mValue);
                      } else {
                        const milliMetreValue = find(articleLength.values, [
                          "unit",
                          "mm",
                        ]);
                        if (milliMetreValue) {
                          const mmValue = get(milliMetreValue, "value");
                          placoArticleLength = parseFloat(mmValue) / 1000;
                        }
                      }
                    }

                    let selectedArticle = newPlaqRow.articles[
                      `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      articleName: selectedPackagedArticle.translation,
                      elementHeight: wallH,
                      elementArea: wallA,
                      articleRevitLength: length_in_m,
                      articleRevitHeight: height_in_m,
                      nbUnit: 0,
                      nbTotal: "",
                      articleWidth: placoArticleWidth,
                      articleLength: placoArticleLength,
                      elementType: elementType,
                      levelElevation: levelElevation,
                      zone: plaqueZone.zone,
                    };

                    selectedArticle.nbUnit =
                      selectedArticle.nbUnit + numeralConversion(quantity);

                    newPlaqRow.articles[
                      `${selectedPackagedArticle.translation}/${length_in_m}/${height_in_m}`
                    ] = selectedArticle;

                    collected_articles[`${elementId}`] = newPlaqRow;
                  }
                }
              }
            }
          });
        }
      });
    } else {
      let revitData: any = find(processedWallParams, function (o) {
        return o.Id === elementId;
      });

      if (revitData) {
        const userPlaqueObj = revitData.Params.find(
          (rvd: any) => rvd.Name === "Plaque"
        );

        if (userPlaqueObj) {
          if (userPlaqueObj.Value) {
            const userPlaqueArray = userPlaqueObj.Value.split(";");

            if (userPlaqueArray) {
              userPlaqueArray.forEach((userPlq: string) => {
                forEach(extractObj.plaques, (plaque, index) => {
                  const relationAttributeAids = relationAttributeSelector(
                    extractObj.relationAttributes
                  );

                  const ration = findProductToProduct(
                    extractObj.relations,
                    plaque.product.oid,
                    relationAttributeAids.ratio,
                    extractObj.placoSolution.oid
                  );

                  const objFinalratio = ration
                    ? ration === "" || ration === "999" || ration === "9999"
                      ? 0
                      : parseFloat(ration)
                    : 0;

                  let selectedArticle = plaque.articles.find(
                    (plart: any) => plart.value === userPlq
                  );

                  if (selectedArticle) {
                    let newPlaqRow: Plaqs = collected_articles[
                      `${elementId}/${plaque.product.oid}`
                    ] || {
                      level: levelName,
                      solution: ovurage,
                      elementId: elementId,
                      articleName: selectedArticle.value,
                      elementHeight: numeralConversion(round(elementHeight, 2)),
                      elementArea: numeralConversion(round(elementArea, 2)),
                      articles: {},
                      levelElevation: levelElevation,
                      zone: plaqueZone.zone,
                      articleLists: null,
                      solutionId: solutionId,
                      articleId: plaque.product.oid,
                      elementType: elementType,
                    };

                    let collectedNewArticles: any = [];
                    forEach(plaque.articles, (plarticle, plArticleIndex) => {
                      const attributeDescriptors = extractObj.attributes;

                      const attributeAids =
                        packageAttributeSelector(attributeDescriptors);

                      const articleWidth = find(
                        plarticle.packagedArticle.attributes,
                        ["aid", attributeAids.unpackedwidth]
                      );
                      const articleLength = find(
                        plarticle.packagedArticle.attributes,
                        ["aid", attributeAids.unpackedLength]
                      );

                      let placoArticleWidth: number = 0;
                      if (articleWidth) {
                        const metreValue = find(articleWidth.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue: string = get(metreValue, "value");
                          placoArticleWidth = parseFloat(mValue);
                        } else {
                          const milliMetreValue = find(articleWidth.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            placoArticleWidth = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let placoArticleLength: number = 0;

                      if (articleLength) {
                        const metreValue = find(articleLength.values, [
                          "unit",
                          "m",
                        ]);

                        if (metreValue) {
                          const mValue: string = get(metreValue, "value");
                          placoArticleLength = parseFloat(mValue);
                        } else {
                          const milliMetreValue = find(articleLength.values, [
                            "unit",
                            "mm",
                          ]);
                          if (milliMetreValue) {
                            const mmValue = get(milliMetreValue, "value");
                            placoArticleLength = parseFloat(mmValue) / 1000;
                          }
                        }
                      }

                      let customRatio = 0;
                      if (userRation[solutionId + plarticle.value]) {
                        customRatio = userRation[solutionId + plarticle.value];
                      } else {
                        customRatio = objFinalratio;
                      }

                      collectedNewArticles.push({
                        level: levelName,
                        solution: ovurage,
                        articleName: plarticle.value,
                        elementHeight: numeralConversion(
                          round(elementHeight, 2)
                        ),
                        elementArea: numeralConversion(round(elementArea, 2)),
                        articleRevitLength: 0,
                        articleRevitHeight: 0,
                        nbUnit:
                          layerCount[plaque.product.translation] === undefined
                            ? 1
                            : layerCount[plaque.product.translation].count,
                        nbTotal: "",
                        articleWidth: placoArticleWidth,
                        articleLength: placoArticleLength,
                        elementType: elementType,
                        levelElevation: levelElevation,
                        ratio: objFinalratio,
                        nonLayout: true,
                        zone: plaqueZone.zone,
                        selected: selectedArticle.value === plarticle.value,
                        rationCustom: customRatio,
                        key: plarticle.value,
                        value: plarticle.value,
                        text: plarticle.value,
                      });
                    });
                    newPlaqRow.articleLists = collectedNewArticles;
                    collected_articles[`${elementId}/${plaque.product.oid}`] =
                      newPlaqRow;
                  }
                });
              });
            }
          }
        }
      }
    }
  });

  let plaqArray: Plaqs[] = [];

  forEach(collected_articles, (plaq: Plaqs, key: string) => {
    plaqArray.push(plaq);
  });

  return plaqArray;
};
