import jwt_decode from "jwt-decode";

export const isAuthenticated = () => {
  const token = localStorage.getItem("refresh_token") as string;
  let result: boolean = false;
  try {
    let authData: any = jwt_decode(token);
    const { exp } = authData;
    if (exp < Date.now() / 1000) {
      localStorage.removeItem("refresh_token");
      result = false;
    }
    result = true;
  } catch (err) {
    localStorage.removeItem("refresh_token");
    result = false;
  }
  return result;
};

export const GetTokenExpiryInSecs = (): {
  isExpire: boolean;
  firstWarning: boolean;
  tenMinWarning: number;
  tenMinSecWarning: number;
  secondWarning: boolean;
} => {
  const token = localStorage.getItem("refresh_token") as string;
  try {
    let authData: any = jwt_decode(token);
    const { exp } = authData;
    let currentTime: any = new Date();
    let currentTimeStamp = currentTime / 1000;
    let isExpire = exp < currentTimeStamp;
    let tenMinHoursWarning = (exp - currentTimeStamp) / 60 / 60;
    //let tenMinWarning = Math.floor((exp - currentTimeStamp)/60) - (tenMinHoursWarning * 60)
    let tenMinWarning = Math.floor(tenMinHoursWarning * 60);
    let tenMinSecWarning = Math.round((exp - currentTimeStamp) % 60);
    let firstWarning = tenMinWarning === 10 && tenMinSecWarning === 1;
    let secondWarning = tenMinWarning === 0 && tenMinSecWarning === 1;
    if (tenMinWarning === 0 && tenMinSecWarning === 0) {
      localStorage.setItem(
        "min",
        tenMinWarning.toString() + ":" + tenMinSecWarning.toString()
      );
    }
    return {
      isExpire,
      firstWarning,
      tenMinWarning,
      tenMinSecWarning,
      secondWarning,
    };
  } catch (err) {
    return {
      isExpire: true,
      firstWarning: true,
      tenMinWarning: 0,
      tenMinSecWarning: 0,
      secondWarning: true,
    };
  }
};

export const GetUserDetails = () => {
  const token = localStorage.getItem("token") as string;
  let userdetails = null;
  try {
    let authData: any = jwt_decode(token);
    const { email, fullName } = authData;
    userdetails = {
      email,
      fullName,
    };
  } catch (err) {
    localStorage.removeItem("token");
  }
  return userdetails;
};
