import {
    SetInfosStatusActionType,
    SET_INFO_STATUS
} from "../Types"


export const setInfoStatus = (data: any): SetInfosStatusActionType => {
    return {
        type: SET_INFO_STATUS,
        infoObj: data
    }
}
