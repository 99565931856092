import PouchDB from "pouchdb";
import {
  ProductDetailData,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import { SystemDetails } from "../../../../Components/ProductsBrowser/SystemDetails";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import { api } from "../../../../RevitJS/API";
import { bdd } from "../../../PAM/pam-import/helpers/interceptors";

let db = new PouchDB("pam_projects_db");

export const getProjectsDetails = async (
  elementObjects: ProductMeta[],
  config: any
): Promise<ProductDetailData[]> => {
  let pipeObject: ProductDetailData[] = await bdd(
    "/objects/details/class/SGObject/locale/en",
    [
      ...elementObjects
        .map((pipe) => {
          return pipe.oid;
        })
        .slice(20),
    ],
    config
  ).then(function (response) {
    return response.data.objects;
  });

  return pipeObject;
};

export const loadProjects = async (config: any): Promise<ProductMeta[]> => {
  let weberBrand = await bdd(
    "/objects/class/Brand/locale/en",
    {
      text: "WEBER ",
    },
    config
  );
  const products: ProductMeta[] = await getWeberProduts(
    weberBrand.data.objects[0].oid,
    config
  ).then(function (response) {
    return response.data.objects;
  });

  return products;
};

export const getSystemDetailsById = async (
  ids: ID[],
  config: any
): Promise<{ data: { context: any; objects: SystemDetails[] } }> => {
  const ids_container = split_array(ids);
  const systemDetailsRequest: {
    data: { context: any; objects: SystemDetails[] };
  } = await Promise.all(
    ids_container.map((ids) => getWeberSystemsDetails(ids, config))
  ).then((systemDetailsRequests) => {
    const systemDetailsRequest = {
      data: {
        context: systemDetailsRequests[0].data.context,
        objects: [],
      },
    };
    for (let i = 0; i < systemDetailsRequests.length; i++) {
      systemDetailsRequest.data.objects =
        systemDetailsRequest.data.objects.concat(
          systemDetailsRequests[i].data.objects
        );
    }
    return systemDetailsRequest;
  });

  return systemDetailsRequest;
};

export const getWeberSystemsDetails = (systemOid: ID[], config: any) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid, config);

const split_array = (arr: any[], chunk = 400): any[][] => {
  let res = [];
  var i, j, temparray;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray = arr.slice(i, i + chunk);
    res.push(temparray);
  }
  return res;
};

export const getCustomParametersFromElementType = async () => {
  const systemElement = await api.queries.getCustomParametersFromElementType();
  return systemElement && systemElement.length > 0
    ? systemElement
    : currentSystems;
};

const currentSystems = [
  {
    solution: "Generic - 150mm",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 150mm",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Interior - 138mm Partition (1-hr)",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Type Name",
        value: "Interior - 138mm Partition (1-hr)",
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior - Brick and Block on Mtl. Stud",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior - Brick and Block on Mtl. Stud",
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 200mm",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 200mm",
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 250mm",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 250mm",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 300mm",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 300mm",
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 375mm",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 375mm",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 300mm Masonry",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 300mm Masonry",
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 200mm CMU",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 200mm CMU",
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 150mm Masonry",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 150mm Masonry",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior - EIFS on Mtl Stud",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior - EIFS on Mtl Stud",
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 90mm Brick",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 90mm Brick",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Interior - 79mm Partition (1-hr)",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Interior - 79mm Partition (1-hr)",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Interior - 123mm Partition (1-hr)",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Interior - 123mm Partition (1-hr)",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Interior - 135mm Partition (2-hr)",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Interior - 135mm Partition (2-hr)",
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Interior - 126mm Partition (2-hr)",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Interior - 126mm Partition (2-hr)",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior - Brick on CMU",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior - Brick on CMU",
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Curtain Wall",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Interior Type",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Curtain Wall",
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Join Condition",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Border 2 Type",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Layout",
        value: null,
      },
      {
        category: null,
        key: "Spacing",
        value: null,
      },
      {
        category: null,
        key: "Layout",
        value: null,
      },
      {
        category: null,
        key: "Adjust for Mullion Size",
        value: null,
      },
      {
        category: null,
        key: "Curtain Panel",
        value: null,
      },
      {
        category: null,
        key: "Border 1 Type",
        value: null,
      },
      {
        category: null,
        key: "Adjust for Mullion Size",
        value: null,
      },
      {
        category: null,
        key: "Border 2 Type",
        value: null,
      },
      {
        category: null,
        key: "Border 1 Type",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Curtain Wall",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Automatically Embed",
        value: null,
      },
      {
        category: null,
        key: "Spacing",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Interior Type",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Foundation - 300mm Concrete",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Foundation - 300mm Concrete",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Retaining - 300mm Concrete",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Retaining - 300mm Concrete",
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 200mm - Filled",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 200mm - Filled",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior Glazing",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Family Name",
        value: "Curtain Wall",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Adjust for Mullion Size",
        value: null,
      },
      {
        category: null,
        key: "Spacing",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Adjust for Mullion Size",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Border 1 Type",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Spacing",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Border 1 Type",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Layout",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Interior Type",
        value: null,
      },
      {
        category: null,
        key: "Curtain Panel",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Join Condition",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Automatically Embed",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Border 2 Type",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Layout",
        value: null,
      },
      {
        category: null,
        key: "Border 2 Type",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior Glazing",
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Interior Type",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Storefront",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Automatically Embed",
        value: null,
      },
      {
        category: null,
        key: "Adjust for Mullion Size",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Border 1 Type",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Curtain Wall",
      },
      {
        category: null,
        key: "Adjust for Mullion Size",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Interior Type",
        value: null,
      },
      {
        category: null,
        key: "Layout",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Layout",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Border 2 Type",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Spacing",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Storefront",
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Border 2 Type",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Curtain Panel",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Border 1 Type",
        value: null,
      },
      {
        category: null,
        key: "Join Condition",
        value: null,
      },
      {
        category: null,
        key: "Spacing",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Interior Type",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior - Brick on Mtl. Stud",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior - Brick on Mtl. Stud",
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior - Brick Over Block w Metal Stud",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Family Name",
        value: "Stacked Wall",
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior - Brick Over Block w Metal Stud",
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Exterior - Block on Mtl. Stud",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Exterior - Block on Mtl. Stud",
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
    ],
    materialAttributes: null,
  },
  {
    solution:
      "Doublage de grande hauteur High-Stil® - support béton 16cm - 1x Placo® Duo'Tech® 25 - 4,7m",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: "255.0mm",
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: "sur ossature",
      },
      {
        category: null,
        key: "System name",
        value:
          "Doublage de grande hauteur High-Stil® - support béton 16cm - 1x Placo® Duo'Tech® 25 - 4,7m",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: "https://www.placo.fr/",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value:
          "Doublage de grande hauteur High-Stil® - support béton 16cm - 1x Placo® Duo'Tech® 25 - 4,7m",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "System ID",
        value: "SP00003927",
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: "2021-03-05T13:18:19.858Z",
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value:
          "Les doublages High-Stil® sont composés de rails et de montants en acier à haute limite élastique, sur lesquels sont fi xées des plaques de plâtre Placo® d’épaisseur 25 mm. Les profilés High-Stil® sont en acier galvanisé Z 275 d’épaisseur 12/10e mm. Le système High-Stil® permet de réaliser des doublages jusqu’à 7,50 m de hauteur, et sont destinés au doublage de murs dans les constructions neuves ou anciennes où les contraintes mécaniques et acoustiques sont importantes, comme des cinémas, théâtres, salles de spectacle ou bâtiments industriels.",
      },
      {
        category: null,
        key: "System url",
        value: "https://www.placo.fr/placolog/Solution/SP00003927/",
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: "Placo® Duo'Tech® 25",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: "Placoplatre® - Groupe Saint-Gobain",
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: "3.0",
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution:
      "Doublage de grande hauteur High-Stil® - support béton 16cm - 1x Placo® Duo'Tech® 25 - 5,9m",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: "sur ossature",
      },
      {
        category: null,
        key: "Thickness",
        value: "285.0mm",
      },
      {
        category: null,
        key: "System ID",
        value: "SP00003925",
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: "https://www.placo.fr/placolog/Solution/SP00003925/",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: "Placoplatre® - Groupe Saint-Gobain",
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value:
          "Doublage de grande hauteur High-Stil® - support béton 16cm - 1x Placo® Duo'Tech® 25 - 5,9m",
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value:
          "Les doublages High-Stil® sont composés de rails et de montants en acier à haute limite élastique, sur lesquels sont fi xées des plaques de plâtre Placo® d’épaisseur 25 mm. Les profilés High-Stil® sont en acier galvanisé Z 275 d’épaisseur 12/10e mm. Le système High-Stil® permet de réaliser des doublages jusqu’à 7,50 m de hauteur, et sont destinés au doublage de murs dans les constructions neuves ou anciennes où les contraintes mécaniques et acoustiques sont importantes, comme des cinémas, théâtres, salles de spectacle ou bâtiments industriels.",
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: "2021-03-05T13:18:19.861Z",
      },
      {
        category: null,
        key: "Number of layers",
        value: "3.0",
      },
      {
        category: null,
        key: "Name of the facing",
        value: "Placo® Duo'Tech® 25",
      },
      {
        category: null,
        key: "Type Name",
        value:
          "Doublage de grande hauteur High-Stil® - support béton 16cm - 1x Placo® Duo'Tech® 25 - 5,9m",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: "https://www.placo.fr/",
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Type Name",
        value: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m",
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: "https://www.placo.fr/",
      },
      {
        category: null,
        key: "System url",
        value: "https://www.placo.fr/placolog/Solution/SP00002234/",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value:
          "Les plaques Aquaroc® sont en ciment allégé et très haute dureté. Elles garantissent la durabilité des ouvrages dans les locaux à très forte hygrométrie. Les cloisons Aquaroc® sont particulièrement destinées aux locaux classés EC ou aux locaux exigeant une forte résistance aux chocs superficiels.",
      },
      {
        category: null,
        key: "Number of layers",
        value: "5.0",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Name of the facing",
        value: "Aquaroc® 13",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: "2021-03-05T13:18:19.864Z",
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: "120.0mm",
      },
      {
        category: null,
        key: "System ID",
        value: "SP00002234",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: "Placoplatre® - Groupe Saint-Gobain",
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Type of partition",
        value: "Distributive standard",
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 4,15m",
    elementType: "wall",
    paramData: [
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 4,15m",
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: "5.0",
      },
      {
        category: null,
        key: "System description",
        value:
          "Les plaques Aquaroc® sont en ciment allégé et très haute dureté. Elles garantissent la durabilité des ouvrages dans les locaux à très forte hygrométrie. Les cloisons Aquaroc® sont particulièrement destinées aux locaux classés EC ou aux locaux exigeant une forte résistance aux chocs superficiels.",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 4,15m",
      },
      {
        category: null,
        key: "Fire Rating",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: "SP00002232",
      },
      {
        category: null,
        key: "Generation date",
        value: "2021-03-05T13:18:19.868Z",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Wall",
      },
      {
        category: null,
        key: "Dubbing type",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: "120.0mm",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: "https://www.placo.fr/",
      },
      {
        category: null,
        key: "Name of the facing",
        value: "Aquaroc® 13",
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: "https://www.placo.fr/placolog/Solution/SP00002232/",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: "Placoplatre® - Groupe Saint-Gobain",
      },
      {
        category: null,
        key: "Type of partition",
        value: "Distributive standard",
      },
      {
        category: null,
        key: "Width",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Function",
        value: null,
      },
      {
        category: null,
        key: "Structural Material",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Ends",
        value: null,
      },
      {
        category: null,
        key: "Wrapping at Inserts",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Material",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "System sheet",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Basic Ceiling",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic",
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Ceiling type",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "600 x 1200mm grid",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "600 x 1200mm grid",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "System sheet",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Ceiling type",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Compound Ceiling",
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "600 x 600mm grid",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Compound Ceiling",
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "System sheet",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "600 x 600mm grid",
      },
      {
        category: null,
        key: "Ceiling type",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "GWB on Mtl Stud",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Ceiling type",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "GWB on Mtl Stud",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "System description",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Main manufacturer",
        value: null,
      },
      {
        category: null,
        key: "System sheet",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "System url",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Compound Ceiling",
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution: "Generic - 300mm",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value: "Generic - 300mm",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Roof Soffit",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
    ],
    materialAttributes: null,
  },
  {
    solution:
      "Plafond décoratif et acoustique Gyptone® Activ'Air® Line 5 - αw = 0,45 - Plenum 200",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "Ceiling type",
        value: "non démontable",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: "Placoplatre® - Groupe Saint-Gobain",
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: "https://www.placo.fr/",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value:
          "Plafond décoratif et acoustique Gyptone® Activ'Air® Line 5 - αw = 0,45 - Plenum 200",
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: "SP00004582",
      },
      {
        category: null,
        key: "Type Name",
        value:
          "Plafond décoratif et acoustique Gyptone® Activ'Air® Line 5 - αw = 0,45 - Plenum 200",
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: "2021-03-05T13:18:19.872Z",
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: "3.0",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Family Name",
        value: "Compound Ceiling",
      },
      {
        category: null,
        key: "System description",
        value:
          "Les plaques Gyptone® Activ’Air®, à hautes performances acoustiques, proposent des perforations élégantes et variées qui en font une source d’inspiration pour de nombreux projets architecturaux.Les plaques Gyptone® Curve, de seulement 6,5 mm d’épaisseur, permettent de réaliser des plafonds cintrés aux performances acoustiques exceptionnelles.",
      },
      {
        category: null,
        key: "System url",
        value: "https://www.placo.fr/placolog/Solution/SP00004582/",
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "System sheet",
        value: "https://www.placo.fr/placolog/Solution/SP00004582/",
      },
    ],
    materialAttributes: null,
  },
  {
    solution:
      "Plafond décoratif et acoustique Gyptone® Activ'Air® Line 5 - αw = 0,5 - Plenum 58",
    elementType: "ceiling",
    paramData: [
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Assembly Code",
        value: "",
      },
      {
        category: null,
        key: "Type Comments",
        value: null,
      },
      {
        category: null,
        key: "Cost",
        value: null,
      },
      {
        category: null,
        key: "Model",
        value: null,
      },
      {
        category: null,
        key: "Ceiling type",
        value: "non démontable",
      },
      {
        category: null,
        key: "Category",
        value: null,
      },
      {
        category: null,
        key: "Description",
        value: null,
      },
      {
        category: null,
        key: "System ID",
        value: "SP00004581",
      },
      {
        category: null,
        key: "Type Mark",
        value: null,
      },
      {
        category: null,
        key: "Keynote",
        value: null,
      },
      {
        category: null,
        key: "URL",
        value: null,
      },
      {
        category: null,
        key: "Structure",
        value: null,
      },
      {
        category: null,
        key: "Heat Transfer Coefficient (U)",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Pattern",
        value: null,
      },
      {
        category: null,
        key: "System sheet",
        value: "https://www.placo.fr/placolog/Solution/SP00004581/",
      },
      {
        category: null,
        key: "Thickness",
        value: null,
      },
      {
        category: null,
        key: "Roughness",
        value: null,
      },
      {
        category: null,
        key: "Design Option",
        value: null,
      },
      {
        category: null,
        key: "Generation date",
        value: "2021-03-05T13:18:19.875Z",
      },
      {
        category: null,
        key: "Family Name",
        value: "Compound Ceiling",
      },
      {
        category: null,
        key: "Type Image",
        value: null,
      },
      {
        category: null,
        key: "System name",
        value:
          "Plafond décoratif et acoustique Gyptone® Activ'Air® Line 5 - αw = 0,5 - Plenum 58",
      },
      {
        category: null,
        key: "System description",
        value:
          "Les plaques Gyptone® Activ’Air®, à hautes performances acoustiques, proposent des perforations élégantes et variées qui en font une source d’inspiration pour de nombreux projets architecturaux.Les plaques Gyptone® Curve, de seulement 6,5 mm d’épaisseur, permettent de réaliser des plafonds cintrés aux performances acoustiques exceptionnelles.",
      },
      {
        category: null,
        key: "Assembly Description",
        value: "",
      },
      {
        category: null,
        key: "Manufacturer",
        value: null,
      },
      {
        category: null,
        key: "Coarse Scale Fill Color",
        value: null,
      },
      {
        category: null,
        key: "Type Name",
        value:
          "Plafond décoratif et acoustique Gyptone® Activ'Air® Line 5 - αw = 0,5 - Plenum 58",
      },
      {
        category: null,
        key: "System url",
        value: "https://www.placo.fr/placolog/Solution/SP00004581/",
      },
      {
        category: null,
        key: "Main manufacturer",
        value: "Placoplatre® - Groupe Saint-Gobain",
      },
      {
        category: null,
        key: "Thermal mass",
        value: null,
      },
      {
        category: null,
        key: "Number of layers",
        value: "3.0",
      },
      {
        category: null,
        key: "Manufacturer info (website)",
        value: "https://www.placo.fr/",
      },
      {
        category: null,
        key: "Absorptance",
        value: null,
      },
      {
        category: null,
        key: "Thermal Resistance (R)",
        value: null,
      },
    ],
    materialAttributes: null,
  },
];

const getWeberProduts = (brandOid: string, config: any) =>
  bdd(
    "/objects/class/SGObject/locale/en",
    {
      dependencies: [
        {
          className: "Brand",
          oids: [brandOid],
        },
      ],
      types: [],
      principal: true,
    },
    config
  );
