import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { selectWindowToShow } from "../../actions/appActions";
import {
  resetCurrentProject,
  saveProject,
} from "../../actions/projectsActions";
import {
  AppWindowName,
  STEP_4_COUPLING_SELECTION_TRANSFER_SUCCESS,
} from "../../assets/constants";
import StateModel from "../../models/State.model";
import Footer from "../common/footer/Footer";
import Header from "../common/header/Header";
import Body from "./body/Body";
import "./finish.scss";

class Finish extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.projectMenu = this.projectMenu.bind(this);
    this.newProject = this.newProject.bind(this);
  }

  projectMenu() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_MENU_WINDOW);
  }

  newProject() {
    let { resetCurrentProject, selectWindowToShow } = this.props;
    resetCurrentProject();
    selectWindowToShow(AppWindowName.PROJECT_NAME_WINDOW);
  }

  render() {
    let { t } = this.props;
    return (
      <div id="finish-container">
        <Header
          displayName={t(STEP_4_COUPLING_SELECTION_TRANSFER_SUCCESS) || ""}
        ></Header>
        <Body
          projectMenu={this.projectMenu}
          newProject={this.newProject}
        ></Body>
        <span></span>
        <Footer
          savedProjectsButtonShow={false}
          showNextButton={false}
          showBackButton={false}
        ></Footer>
      </div>
    );
  }
}

let mapStateToProps = (state: StateModel) => {
  return {
    userInfo: state.userInfo,
    validatedItems: state.projects.currentProject.validatedItems,
    projectRegion: state.projects.currentProject.country,
  };
};

let mapDispatchToProps = {
  selectWindowToShow,
  resetCurrentProject,
  saveProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & WithTranslation;

type IState = {};

export default connector(withTranslation(["pam"])(Finish));
