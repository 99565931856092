import { Language } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import _ from "lodash";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { loadSelectionsAction, Selection } from "../../Selection/Actions/types";
import { projectData } from "../../Selection/Reducers/projectData";
import { config } from "../../../CLT/Delete/Reducers";

type selectionsAction = loadSelectionsAction<PlacoOptions>;

export const language = (
  language: Language = "French",
  action: any
): Language => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return action.value;
    default:
      return language;
  }
};

export const wallScheduleLoader = (loading: boolean = false, action: any) => {
  switch (action.type) {
    case "SET_WALLSCHEDULE_LOADER":
      return action.load;
    default:
      return loading;
  }
};

export const unitConversionArray = (unitArray: any[] = [], action: any) => {
  switch (action.type) {
    case "SET_UNITCONVERSION_ARRAY":
      _.map(action.array, (unitObj: any, unitObjIndex: any) => {
        if (
          !_.find(unitArray, {
            alternativeUnit: unitObj.alternativeUnit,
            ub: unitObj.ub,
            element: action.element,
          })
        ) {
          unitArray = [...unitArray, { ...unitObj, element: action.element }];
        }
      });
      return unitArray;
    default:
      return unitArray;
  }
};

export const selections = (
  selections: { [key: string]: Selection<PlacoOptions> } = {},
  action: selectionsAction
): { [key: string]: Selection<PlacoOptions> } => {
  switch (action.type) {
    case "LOAD_SELECTIONS":
      return action.selections;
    default:
      return selections;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export interface MetresStore {
  language: Language;
  functionalityIcon: string;
  wallScheduleLoader: any;
  unitConversionArray: any;
  selections: { [key: string]: Selection<PlacoOptions> };
  projectData: initProjectData;
  config: any;
}

//#endregion

export const reducers = combineReducers({
  language,
  wallScheduleLoader,
  unitConversionArray,
  selections,
  projectData,
  config,
  functionalityIcon,
});
