import React from "react";
import { IWording } from "../../../../Types";

const wording: IWording = {
  selectionName: {
    French: "Nom de la sélection",
    English: "Selection name",
  },
  editDate: {
    French: "Date de modification",
    English: "Edit date",
  },
};

const style = {
  tableSegment_Header: {
    border: "0.5px solid rgb(200, 200, 200",
  },
  header_secondary_row: {
    padding: 5,
    height: 25,
    backgroundColor: "rgb(221, 231, 246)",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid white",
  },
  header_column_1: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    color: "black",
    border: "0.2px",
  },
  header_column_2: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    color: "black",
    border: "0.2px",
  },
  header_column_3: {
    justifyContent: "center",
    fontWeight: 700,
    display: "flex",
    color: "black",
    border: "0.2px",
  },
  header_column_4: {
    justifyContent: "center",
    textAlign: "center" as const,
    fontWeight: 700,
    color: "black",
  },
};

interface Props {
  register: any;
  onSelectAll: any;
  isAllSelected: boolean;
}
export const CalepinerTableHeader = (props: Props) => {
  const { register, onSelectAll, isAllSelected } = props;
  return (
    <div style={style.tableSegment_Header}>
      <div style={style.header_secondary_row}>
        <div style={style.header_column_1} className="w-5">
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={(d) => onSelectAll(d.target.checked)}
          />
        </div>
        <div style={style.header_column_2} className="w-10">
          {"Floor"}
        </div>
        <div style={style.header_column_3} className="w-25">
          {"Revit System Type"}
        </div>
        <div style={style.header_column_1} className="w-5">
          {"H(m)"}
        </div>
        <div style={style.header_column_4} className="w-25">
          {"Solution Gyproc"}
        </div>
        <div style={style.header_column_3} className="w-15">
          {"Product Height"}
        </div>
        <div style={style.header_column_3} className="w-15">
          {"Stud distance"}
        </div>
      </div>
    </div>
  );
};
export default CalepinerTableHeader;
