import Excel from "exceljs";
import Resizer from "react-image-file-resizer";
import { api } from "../../../../RevitJS/API";
import numeral from "numeral";
import {
  AttributeDescriptor,
  PimAttribute,
} from "../../../../RevitJS/Types/BddTypes";
import {
  AlternativeUnitsAid,
  DistributionChannelsAid,
  PackageAttributesAid,
  RelationAttributesAid,
} from "../Components/model";
import _, { ceil, concat, isEmpty, round } from "lodash";
import { unitConversionTable } from "./type";
import {
  GlobalSummary,
  SummaryDetail,
  SummaryGroupDetail,
} from "../Actions/types";
import { getProjectData } from "../../ProjectInfo/Helper";
import { bimStorage, storageKey } from "../../../../BIMStore";

export const addImagetoWorkbook = async (excelBook: Excel.Workbook) => {
  let tempProjectInfo: any = await bimStorage.getItem(storageKey.PROJECT_INFO);
  let data = tempProjectInfo === null ? {} : tempProjectInfo;
  // let data: any = JSON.parse(await bimStorage.getItem(storageKey.PROJECT_INFO) as string);
  if (!isEmpty(data)) {
    if (data.PROJECT_INFO_FORM.companyInfo.information_enterprise_logo !== "") {
      const resizedImg = await resizeFile(
        b64toBlob(
          data.PROJECT_INFO_FORM.companyInfo.information_enterprise_logo
        )
      );
      const imageId = excelBook.addImage({
        base64: resizedImg
          ? resizedImg
          : data.PROJECT_INFO_FORM.companyInfo.information_enterprise_logo,
        extension: "png",
      });
      return imageId;
    }
    return 0;
  } else {
    return 0;
  }
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      110,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const b64toBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

export const setDossierTechnicalInfo = async (
  excelSheet: Excel.Worksheet,
  firstColumn: string,
  secondColumn: string
) => {
  if (excelSheet) {
    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );
    let projectInfo = tempProjectInfo === null ? {} : tempProjectInfo;

    const projectId = await getProjectData();

    if (projectInfo?.PROJECT_INFO_FORM) {
      excelSheet.getCell(`${secondColumn}8`).value =
        projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_name;
      excelSheet.getCell(
        `${secondColumn}9`
      ).value = `${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_address}, ${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_postal} ${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_villa} - ${projectInfo.PROJECT_INFO_FORM.companyInfo?.information_enterprise_pays}`;

      const chantierInfo = projectInfo.PROJECT_INFO_FORM.modelInfo.find(
        (e: any) => e.model_name === projectId
      );

      excelSheet.getCell(`${firstColumn}10`).value = `${
        chantierInfo?.information_chantier_reference ?? ""
      }`;
    }

    const documentName: string = await api.queries.getActiveDocumentName();
    if (documentName)
      excelSheet.getCell(`${secondColumn}13`).value = documentName;

    const projData: any = await api.queries.getProjectInformation();
    if (projData)
      excelSheet.getCell(`${firstColumn}8`).value = projData.Name.replace(
        "&apos;",
        "'"
      );
  }
};

export const numeralConversion = (numberToConvert: any) => {
  if (numeral.locales["fr"] === undefined) {
    numeral.register("locale", "fr", {
      delimiters: {
        thousands: " ",
        decimal: ",",
      },
      abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t",
      },
      ordinal: function (number) {
        return number === 1 ? "er" : "ème";
      },
      currency: {
        symbol: "€",
      },
    });
  }
  numeral.locale("fr");
  const vv = numeral(numberToConvert).value();

  if (vv) {
    return vv;
  } else {
    return 0;
  }
};

export const printNullOrZero = (printNumber: number | string) => {
  if (printNumber) {
    if (typeof printNumber === "string") {
      return parseInt(printNumber) === 0 ? "" : printNumber;
    }
  } else {
    printNumber = 0;
  }

  return printNumber === 0 ? "" : printNumber;
};

export const alternativeUnitsSelector = (
  attributeDescriptors: AttributeDescriptor[]
): AlternativeUnitsAid => ({
  alternativeunits: findAttributeAid(
    attributeDescriptors,
    "GFR-Alternative units"
  ) as string,
  denominator: findAttributeAid(
    attributeDescriptors,
    "GFR-Denominator"
  ) as string,
  alternativeunit: findAttributeAid(
    attributeDescriptors,
    "GFR-Alternative unit"
  ) as string,
  numerator: findAttributeAid(attributeDescriptors, "GFR-Numerator") as string,
  ubunit: findAttributeAid(attributeDescriptors, "GFR-UB column") as string,
});

export const distributionChannelsAttributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): DistributionChannelsAid => ({
  dbchannels: findAttributeAid(
    attributeDescriptors,
    "GFR-Distribution Channels"
  ) as string,
  dbchannel: findAttributeAid(
    attributeDescriptors,
    "GFR-Distribution Channel"
  ) as string,
  articleatprice: findAttributeAid(
    attributeDescriptors,
    "GFR-Article at price list (yes or no)"
  ) as string,
  salesunit: findAttributeAid(
    attributeDescriptors,
    "GFR-Sales unit (size at which the product is sold)"
  ) as string,
});

export const packageAttributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): PackageAttributesAid => ({
  eancode: findAttributeAid(
    attributeDescriptors,
    "GFR-EAN low level (base unit)"
  ) as string,
  saleunit: findAttributeAid(
    attributeDescriptors,
    "GFR-Sales unit (Transac)"
  ) as string, //"Unité de vente
  qtypurchased: findAttributeAid(
    attributeDescriptors,
    "GFR-Number of invoicing units per base unit"
  ) as string, //Quantité réelle achetée
  qtybase: findAttributeAid(
    attributeDescriptors,
    "GFR-Number of base units per sales unit (channel 11)"
  ) as string, //Quantité conditionnée
  qtybaseUnit: findAttributeAid(
    attributeDescriptors,
    "GFR-Quantity (in base unit)"
  ) as string,
  surface: findAttributeAid(
    attributeDescriptors,
    "GFR-Surface of the UB unwrapped (m²)"
  ) as string,
  unpackedLength: findAttributeAid(
    attributeDescriptors,
    "GFR-Length of the UB unpacked (m)"
  ) as string, //rails & plaque
  qtydecoupe: findAttributeAid(
    attributeDescriptors,
    "GFR-Number of invoicing units per base unit"
  ) as string, //rais,montant, plaque
  hauter: findAttributeAid(
    attributeDescriptors,
    "GFR-Thickness / UB unpacked height (m)"
  ) as string, //montant plaque
  hauterart: findAttributeAid(
    attributeDescriptors,
    "GFR-Packaged article thickness/height (mm)"
  ) as string, //montatn plaque hauter article
  baseunit: findAttributeAid(
    attributeDescriptors,
    "GFR-Base unit (UB)"
  ) as string, ///Unit
  mixingRatio: findAttributeAid(
    attributeDescriptors,
    "GFR-Mixing ratio"
  ) as string, ///Unit
  unpackedwidth: findAttributeAid(
    attributeDescriptors,
    "GFR-Width of the UB unpacked (m)"
  ) as string, /// width rails / plaque
});

export const relationAttributeSelector = (
  attributeDescriptors: AttributeDescriptor[]
): RelationAttributesAid => ({
  ratio: findAttributeAid(
    attributeDescriptors,
    "GFR-Quantity (in base unit)"
  ) as string,
  unite: findAttributeAid(attributeDescriptors, "GFR-Base unit") as string,
});

export const findAttributeAid = (
  attributeDescriptors: AttributeDescriptor[],
  attributeName: string
) => {
  return attributeDescriptors.find(
    (attr) => attr.technicalName === attributeName
  )?.aid;
};

export const coatingList = [
  "Placojoint® PR4 25kg 60s",
  "Placojoint® PR4 25kg 60sc",
  "Placojoint® SN 25kg 60s",
  "Placojoint® SN 25kg 60sc",
  "Joint-Colle PU Aquaroc®",
  "Placomix® Hydro 15kg 11seaux",
];
export const screwList = [
  "Vis Aquaroc® HB 25 Bte 500P",
  "Vis Aquaroc® HB 41 Bte 500P",
  "Vis Habito 25/boite250pces",
  "Vis Habito 35/boite800pces",
  "Vis Habito 41/boite800pces",
  "Vis TTPC 25/bte1500P",
  "Vis TTPC 35/bte1500P",
  "Vis TTPC 45/bte1500P",
  "Vis Hydrostil+ TTPC 25 bte 1000P",
  "Vis Hydrostil+ TTPC 41 bte 1000P",
  "Vis TRPF 13/bte500P",
  "Vis TTPC 55/bte300P",
  "Vis TTPF 35/bte500P",
];

export const findProductToProduct = (
  relations: any,
  productId: string,
  attributeId: string
): string => {
  const relationObject = _.find(relations, {
    oid2: productId,
    relationType: "Solution Product to Product",
  });
  const relationAttribute = _.find(relationObject.attributes, {
    aid: attributeId,
  });
  if (relationAttribute) {
    return relationAttribute.values[0].value;
  } else {
    return "";
  }
};

export const uniteDeVenteArray = (unite: string | ""): string => {
  const uniteObj = _.find(unitDeArr, ["unit", unite]);
  if (uniteObj) {
    return uniteObj.mapping;
  }

  return unite;
};

export const unitDeArr = [
  { unit: "PIL", mapping: "Pile" },
  { unit: "BOT", mapping: "Botte" },
  { unit: "PAL", mapping: "Palette" },
  { unit: "BTE", mapping: "BOITE" },
  { unit: "CAR", mapping: "Carton" },
  { unit: "KG", mapping: "Kilogramme" },
  { unit: "PAQ", mapping: "Paquet" },
  { unit: "LOT", mapping: "Lot" },
  { unit: "SAC", mapping: "Sac" },
  { unit: "COL", mapping: "Colis" },
  { unit: "BBG", mapping: "BIG BAG" },
  { unit: "UN", mapping: "Pièce" },
  { unit: "TO", mapping: "Tonne" },
  { unit: "EKG", mapping: "Equivalent Kilogramme" },
  { unit: "M", mapping: "M" },
  { unit: "ML", mapping: "Millilitre" },
  { unit: "RL", mapping: "Rouleau" },
  { unit: "PCE", mapping: "Pièce" },
  { unit: "EM2", mapping: "Equivalent Mètre carré" },
  { unit: "M2", mapping: "Mètre Carré" },
  { unit: "PLA", mapping: "Plaque" },
];

export const dbSalesUnit = (
  dbChannelsAid: DistributionChannelsAid,
  articleAttribute: PimAttribute[]
): string => {
  const dbChannels = _.filter(articleAttribute, {
    aid: dbChannelsAid.dbchannels,
  });

  const db11Channel = _.filter(dbChannels, function (o) {
    const db11Object = _.find(o.subAttributes, [
      "aid",
      dbChannelsAid.dbchannel,
    ]);
    const db11numericvalue = _.get(db11Object, "values[0].numericValue");
    return db11numericvalue === 11;
  });

  if (db11Channel.length > 0) {
    const db11Subattributes = db11Channel[0].subAttributes;
    const salesunitObject = _.find(db11Subattributes, [
      "aid",
      dbChannelsAid.salesunit,
    ]);
    if (salesunitObject?.values[0].value) {
      return salesunitObject?.values[0].value;
    }
  }
  return "";
};

export const alternativeUnit = (
  alternativeUnitAid: AlternativeUnitsAid,
  articleAttribute: PimAttribute[],
  quantity: number,
  unite: string,
  saleunit: string,
  props: any,
  element: string
) => {
  const unitConversionArray = getUnitConversionArray(
    alternativeUnitAid,
    articleAttribute
  );
  props.setUnitConversionArray(unitConversionArray, element);

  const isOneToOne = oneToOneMapping(
    unitConversionArray,
    unite,
    saleunit,
    String(quantity)
  );
  return isOneToOne;
};

export const getUnitConversionArray = (
  alternativeUnitAid: AlternativeUnitsAid,
  articleAttribute: PimAttribute[]
) => {
  const alternativeUnits = _.filter(articleAttribute, {
    aid: alternativeUnitAid.alternativeunits,
  });

  let unitConversionArray: unitConversionTable[] = [];

  _.map(alternativeUnits, function (o) {
    const unDenominatorObject = _.find(o.subAttributes, [
      "aid",
      alternativeUnitAid.denominator,
    ]);
    const unDenominatorValue = _.defaultTo(
      String(_.get(unDenominatorObject, "values[0].value")),
      ""
    );

    const unAlternativeUnitObject = _.find(o.subAttributes, [
      "aid",
      alternativeUnitAid.alternativeunit,
    ]);
    const unAlternativeUnitValue = _.defaultTo(
      String(_.get(unAlternativeUnitObject, "values[0].value")),
      ""
    );

    const unNumeratorObject = _.find(o.subAttributes, [
      "aid",
      alternativeUnitAid.numerator,
    ]);
    const unNumeratorValue = _.defaultTo(
      String(_.get(unNumeratorObject, "values[0].value")),
      ""
    );

    const unUBObject = _.find(o.subAttributes, [
      "aid",
      alternativeUnitAid.ubunit,
    ]);
    const unFromUBValue = _.defaultTo(
      String(_.get(unUBObject, "values[0].value")),
      ""
    );

    let unitCoversionObject: unitConversionTable = {
      denominator: unDenominatorValue,
      alternativeUnit: unAlternativeUnitValue,
      numeratuer: unNumeratorValue,
      ub: unFromUBValue,
    };
    unitConversionArray.push(unitCoversionObject);
    return true;
  });

  return unitConversionArray;
};

export const oneToOneMapping = (
  unitConversionArray: unitConversionTable[],
  unite: string,
  saleunit: string,
  quantity: string
) => {
  const resultObject = _.find(unitConversionArray, function (o) {
    if (
      o.alternativeUnit.split(",")[1].toLowerCase() === unite.toLowerCase() ||
      o.alternativeUnit.split(",")[0].toLowerCase() === unite.toLowerCase()
    ) {
      if (
        o.ub.split(",")[1].toLowerCase() === saleunit.toLowerCase() ||
        o.ub.split(",")[0].toLowerCase() === saleunit.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  });

  if (resultObject) {
    const saleResultUnit = round(
      (parseFloat(quantity) * parseFloat(resultObject.numeratuer)) /
        parseFloat(resultObject.denominator),
      2
    );
    return saleResultUnit;
  } else {
    const isOneToTwo = oneToTwoMapping(
      unitConversionArray,
      unite,
      saleunit,
      quantity
    );
    return isOneToTwo;
  }
};

export const oneToTwoMapping = (
  unitConversionArray: unitConversionTable[],
  unite: string,
  saleunit: string,
  quantity: string
) => {
  const resultFirstObject = _.find(unitConversionArray, function (o) {
    if (
      o.alternativeUnit.split(",")[1].toLowerCase() === unite.toLowerCase() ||
      o.alternativeUnit.split(",")[0].toLowerCase() === unite.toLowerCase()
    ) {
      return true;
    }
    return false;
  });

  if (resultFirstObject) {
    const resultSecondObject = _.find(unitConversionArray, function (o) {
      if (
        o.alternativeUnit.split(",")[1].toLowerCase() ===
          saleunit.toLowerCase() ||
        o.alternativeUnit.split(",")[0].toLowerCase() === saleunit.toLowerCase()
      ) {
        if (
          o.ub.split(",")[1].toLowerCase() ===
            resultFirstObject.ub.split(",")[1].toLowerCase() ||
          o.ub.split(",")[0].toLowerCase() ===
            resultFirstObject.ub.split(",")[0].toLowerCase()
        ) {
          return true;
        }
      }
      return false;
    });

    if (resultSecondObject) {
      const saleResultUnit = Math.ceil(
        (parseFloat(quantity) * parseFloat(resultFirstObject.numeratuer)) /
          parseFloat(resultFirstObject.denominator)
      );
      const saleSecondResultUnit = round(
        (saleResultUnit * parseFloat(resultSecondObject.denominator)) /
          parseFloat(resultSecondObject.numeratuer),
        2
      );
      return saleSecondResultUnit;
    }
  }

  return 0;
};

export const unitTobePrinted = (unite: string) => {
  switch (unite) {
    case "Kilogramme":
      return "Kg";
    case "Pile":
      return "Palette";
    default:
      return unite;
  }
};

export const getAttValue = (articleAttribute: PimAttribute[], aid: string) => {
  const attribute = _.find(articleAttribute, ["aid", aid]);
  if (attribute) {
    // const findm2ValueObj = _.find(length.values, ['unit', "m"]);
    const attribute_value = attribute && _.get(attribute.values[0], "value");

    return attribute_value;
  }

  return "";
};

export const uniteDeVenteToUnit = (unite: string | ""): string => {
  const uniteObj = _.find(unitDeArr, ["mapping", unite]);
  if (uniteObj) {
    return uniteObj.unit;
  }
  return unite;
};

export const newConditionedQuantityForPlaq = async (
  alternativeUnitAid: AlternativeUnitsAid,
  articleAttribute: PimAttribute[],
  fromUnite: string,
  plaqsQuantity: any,
  toUnite: string,
  props: any
) => {
  const unitConversionArray = getUnitConversionArray(
    alternativeUnitAid,
    articleAttribute
  );
  props.setUnitConversionArray(unitConversionArray, "plaq");
  const uniteToPlaqueObj = _.find(unitConversionArray, function (o) {
    if (
      o.alternativeUnit.split(",")[0].toLowerCase() === fromUnite.toLowerCase()
    ) {
      if (o.ub.split(",")[0].toLowerCase() === "pla") {
        return true;
      }
    }
    return false;
  });

  if (uniteToPlaqueObj) {
    const oneUniteToPlaque =
      parseFloat(uniteToPlaqueObj.numeratuer) /
      parseFloat(uniteToPlaqueObj.denominator);
    const calculatedPlaqs = plaqsQuantity * oneUniteToPlaque;

    if (toUnite.toLowerCase() === "pla") {
      return round(calculatedPlaqs, 2);
    }

    const plaqueToUniteObj = _.find(unitConversionArray, function (o) {
      if (o.ub.split(",")[0].toLowerCase() === "pla") {
        if (
          o.alternativeUnit.split(",")[1].toLowerCase() ===
          toUnite.toLowerCase()
        ) {
          return true;
        }
      }
      return false;
    });

    if (plaqueToUniteObj) {
      const onetoUniteToPlaque =
        parseFloat(plaqueToUniteObj.numeratuer) /
        parseFloat(plaqueToUniteObj.denominator);

      const calculatedToUnite = calculatedPlaqs / onetoUniteToPlaque;

      return round(calculatedToUnite, 2);
    }
  }
  return 0;
};

export const newConditionedQuantityForMontant = async (
  alternativeUnitAid: AlternativeUnitsAid,
  articleAttribute: PimAttribute[],
  fromUnite: string,
  plaqsQuantity: any,
  toUnite: string,
  props: any
) => {
  const unitConversionArray = getUnitConversionArray(
    alternativeUnitAid,
    articleAttribute
  );
  props.setUnitConversionArray(unitConversionArray, "montant");
  const uniteToPlaqueObj = _.find(unitConversionArray, function (o) {
    if (
      o.alternativeUnit.split(",")[0].toLowerCase() === fromUnite.toLowerCase()
    ) {
      if (o.ub.split(",")[0].toLowerCase() === "pce") {
        return true;
      }
    }
    return false;
  });

  if (uniteToPlaqueObj) {
    const oneUniteToPlaque =
      parseFloat(uniteToPlaqueObj.numeratuer) /
      parseFloat(uniteToPlaqueObj.denominator);
    const calculatedPlaqs = plaqsQuantity * oneUniteToPlaque;

    if (toUnite.toLowerCase() === "pce") {
      return round(calculatedPlaqs, 2);
    }

    const plaqueToUniteObj = _.find(unitConversionArray, function (o) {
      if (o.ub.split(",")[0].toLowerCase() === "pce") {
        if (
          o.alternativeUnit.split(",")[1].toLowerCase() ===
          toUnite.toLowerCase()
        ) {
          return true;
        }
      }
      return false;
    });

    if (plaqueToUniteObj) {
      const onetoUniteToPlaque =
        parseFloat(plaqueToUniteObj.numeratuer) /
        parseFloat(plaqueToUniteObj.denominator);

      const calculatedToUnite = calculatedPlaqs / onetoUniteToPlaque;

      return round(calculatedToUnite, 2);
    }
  }
  return 0;
};

export const newConditionedQuantityForRail = async (
  alternativeUnitAid: AlternativeUnitsAid,
  articleAttribute: PimAttribute[],
  fromUnite: string,
  plaqsQuantity: any,
  toUnite: string,
  props: any
) => {
  const unitConversionArray = getUnitConversionArray(
    alternativeUnitAid,
    articleAttribute
  );
  props.setUnitConversionArray(unitConversionArray, "rail");
  const uniteToPlaqueObj = _.find(unitConversionArray, function (o) {
    if (
      o.alternativeUnit.split(",")[0].toLowerCase() === fromUnite.toLowerCase()
    ) {
      if (o.ub.split(",")[0].toLowerCase() === "pce") {
        return true;
      }
    }
    return false;
  });

  if (uniteToPlaqueObj) {
    const oneUniteToPlaque =
      parseFloat(uniteToPlaqueObj.numeratuer) /
      parseFloat(uniteToPlaqueObj.denominator);
    const calculatedPlaqs = plaqsQuantity * oneUniteToPlaque;

    if (toUnite.toLowerCase() === "pce") {
      return round(calculatedPlaqs, 2);
    }

    const plaqueToUniteObj = _.find(unitConversionArray, function (o) {
      if (o.ub.split(",")[0].toLowerCase() === "pce") {
        if (
          o.alternativeUnit.split(",")[1].toLowerCase() ===
          toUnite.toLowerCase()
        ) {
          return true;
        }
      }
      return false;
    });

    if (plaqueToUniteObj) {
      const onetoUniteToPlaque =
        parseFloat(plaqueToUniteObj.numeratuer) /
        parseFloat(plaqueToUniteObj.denominator);

      const calculatedToUnite = calculatedPlaqs / onetoUniteToPlaque;

      return round(calculatedToUnite, 2);
    }
  }
  return 0;
};

export const addGlobalPlqsInSummarySheet = async (
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  let golbalPlaq: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalPlaq(detailsRow);

  _.forEach(golbalPlaq, async (globalPlaqRow: GlobalSummary, index: string) => {
    let summarySheetRow = summarySheet.getRow(rowDetailsCount);
    summarySheetRow.values = [
      "",
      "",
      "",
      globalPlaqRow.ean,
      globalPlaqRow.article,
      printNullOrZero(globalPlaqRow.nbArticles),
      printNullOrZero(globalPlaqRow.quantity),
      unitTobePrinted(globalPlaqRow.unite),
      printNullOrZero(globalPlaqRow.quantityConditioned),
      unitTobePrinted(globalPlaqRow.uniteDeVente),
    ];

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        if (colNumber === 4) {
          cell.model.type = 2;
          cell.numFmt = "0";
        }
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: colNumber === 7 || colNumber === 8 ? true : false,
          color: {
            argb: colNumber === 7 || colNumber === 8 ? "FF4473c4" : "#000",
          },
          family: 2,
          name: "Arial",
          size: 9,
        };
        if (colNumber > 3) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
  });
  return rowDetailsCount;
};

export const addGlobalZonePlqsInSummarySheet = async (
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  summarySheet: Excel.Worksheet,
  plaqCount: number
) => {
  let golbalPlaq: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalPlaq(detailsRow);

  let globalZonePlq: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalPlaqbyZone(detailsRow);
  _.forEach(golbalPlaq, async (globalPlaqRow: GlobalSummary, index: string) => {
    let summarySheetRow = summarySheet.getRow(plaqCount);
    let row = [
      "",
      "",
      "",
      "",
      globalPlaqRow.ean,
      globalPlaqRow.article,
      printNullOrZero(globalPlaqRow.nbArticles),
      printNullOrZero(globalPlaqRow.quantity),
      unitTobePrinted(globalPlaqRow.unite),
      printNullOrZero(globalPlaqRow.quantityConditioned),
      unitTobePrinted(globalPlaqRow.uniteDeVente),
    ];

    summarySheetRow.values = row;

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        if (colNumber === 5) {
          cell.model.type = 2;
          cell.numFmt = "0";
        }
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: colNumber === 8 || colNumber === 9 ? true : false,
          color: {
            argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
          },
          family: 2,
          name: "Arial",
          size: 9,
        };
        if (colNumber > 4) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    plaqCount = plaqCount + 1;
  });
  return { plaqCount, globalZonePlq };
};

export const addGlobalPlqsInCommandeSheet = async (
  detailsRow: any,
  summarySheet: any,
  rowDetailsCount: any
) => {
  let golbalPlaq: any = await collectGlobalPlaq(detailsRow);
  await _.forEach(golbalPlaq, async (globalPlaqRow: any, index: any) => {
    let summarySheetRow = summarySheet.getRow(rowDetailsCount);
    summarySheetRow.values = [
      "",
      globalPlaqRow.article,
      globalPlaqRow.ean,
      ceil(globalPlaqRow.quantity),
      globalPlaqRow.unite,
      ceil(globalPlaqRow.quantityConditioned),
      globalPlaqRow.uniteDeVente,
    ];

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        if (colNumber === 3) {
          cell.model.type = 2;
          cell.numFmt = "0";
        }
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        cell.font = {
          bold: false,
          color: { argb: "#000" },
          family: 2,
          name: "Arial",
          size: 12,
        };
        if (colNumber > 1) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
  });
  return rowDetailsCount;
};

export const collectGlobalPlaq = async (detailsRow: any) => {
  let golbalPlaq: {
    [Key: string]: GlobalSummary;
  } = {};

  _.forEach(detailsRow, (detailRow, detailRowIndex) => {
    _.forEach(detailRow.plaqs, (plaqRow, plaqRowIndex) => {
      let newGlobalPlaqRow = golbalPlaq[plaqRow.article] || {
        article: plaqRow.article,
        ean: plaqRow.ean,
        nbArticles: 0,
        quantity: 0,
        unite: plaqRow.unite,
        quantityConditioned: 0,
        uniteDeVente: plaqRow.uniteDeVente,
        zone: plaqRow.zone,
        level: plaqRow.levelName,
      };

      golbalPlaq[plaqRow.article] = {
        ...newGlobalPlaqRow,
        nbArticles: newGlobalPlaqRow.nbArticles + plaqRow.nbArticles,
        quantity: newGlobalPlaqRow.quantity + plaqRow.quantity,
        quantityConditioned:
          newGlobalPlaqRow.quantityConditioned + plaqRow.quantityConditioned,
      };
    });
  });

  return golbalPlaq;
};

export const collectGlobalPlaqbyZone = async (detailsRow: any) => {
  let golbalPlaqbyZone: {
    [Key: string]: GlobalSummary;
  } = {};

  _.forEach(detailsRow, (detailRow, detailRowIndex) => {
    _.forEach(detailRow.plaqs, (plaqRow, plaqRowIndex) => {
      let newGlobalPlaqRow = golbalPlaqbyZone[
        `${plaqRow.article}-${plaqRow.zone}`
      ] || {
        article: plaqRow.article,
        ean: plaqRow.ean,
        nbArticles: 0,
        quantity: 0,
        unite: plaqRow.unite,
        quantityConditioned: 0,
        uniteDeVente: plaqRow.uniteDeVente,
        zone: plaqRow.zone,
        level: plaqRow.levelName,
      };

      golbalPlaqbyZone[`${plaqRow.article}-${plaqRow.zone}`] = {
        ...newGlobalPlaqRow,
        nbArticles: newGlobalPlaqRow.nbArticles + plaqRow.nbArticles,
        quantity: newGlobalPlaqRow.quantity + plaqRow.quantity,
        quantityConditioned:
          newGlobalPlaqRow.quantityConditioned + plaqRow.quantityConditioned,
      };
    });
  });

  return golbalPlaqbyZone;
};

//#region gloabl montant

export const addGlobalMontantInSummarySheet = async (
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  let golbalMontant: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalMontant(detailsRow);

  _.forEach(
    golbalMontant,
    async (globalMontantRow: GlobalSummary, index: string) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        "",
        "",
        globalMontantRow.ean,
        globalMontantRow.article,
        printNullOrZero(globalMontantRow.nbArticles),
        printNullOrZero(globalMontantRow.quantity),
        unitTobePrinted(globalMontantRow.unite),
        printNullOrZero(globalMontantRow.quantityConditioned),
        unitTobePrinted(globalMontantRow.uniteDeVente),
      ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 4) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 7 || colNumber === 8 ? true : false,
            color: {
              argb: colNumber === 7 || colNumber === 8 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 9,
          };
          if (colNumber > 3) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
  return rowDetailsCount;
};

export const addGlobalZoneMontantInSummarySheet = async (
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  summarySheet: Excel.Worksheet,
  montantCount: number
) => {
  let golbalMontant: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalMontant(detailsRow);

  let globalZoneMontant: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalMontantbyZone(detailsRow);

  _.forEach(
    golbalMontant,
    async (globalMontantRow: GlobalSummary, index: string) => {
      let summarySheetRow = summarySheet.getRow(montantCount);
      let row = [
        "",
        "",
        "",
        "",
        globalMontantRow.ean,
        globalMontantRow.article,
        printNullOrZero(globalMontantRow.nbArticles),
        printNullOrZero(globalMontantRow.quantity),
        unitTobePrinted(globalMontantRow.unite),
        printNullOrZero(globalMontantRow.quantityConditioned),
        unitTobePrinted(globalMontantRow.uniteDeVente),
      ];

      summarySheetRow.values = row;

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 5) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 8 || colNumber === 9 ? true : false,
            color: {
              argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 9,
          };
          if (colNumber > 4) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }
      );

      montantCount = montantCount + 1;
    }
  );
  return { montantCount, globalZoneMontant };
};

export const addGlobalMontantInCommandeSheet = async (
  detailsRow: any,
  summarySheet: any,
  rowDetailsCount: any
) => {
  let golbalMontant: any = await collectGlobalMontant(detailsRow);
  await _.forEach(golbalMontant, async (globalMontantRow: any, index: any) => {
    let summarySheetRow = summarySheet.getRow(rowDetailsCount);
    summarySheetRow.values = [
      "",
      globalMontantRow.article,
      globalMontantRow.ean,
      ceil(globalMontantRow.quantity),
      globalMontantRow.unite,
      ceil(globalMontantRow.quantityConditioned),
      globalMontantRow.uniteDeVente,
    ];

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        if (colNumber === 3) {
          cell.model.type = 2;
          cell.numFmt = "0";
        }
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        cell.font = {
          bold: false,
          color: { argb: "#000" },
          family: 2,
          name: "Arial",
          size: 12,
        };
        if (colNumber > 1) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
  });
  return rowDetailsCount;
};

//#region gloabl rail

export const addGlobalRailInSummarySheet = async (
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  let golbalRail: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalRail(detailsRow);

  _.forEach(golbalRail, async (globalRailRow: GlobalSummary, index: string) => {
    let summarySheetRow = summarySheet.getRow(rowDetailsCount);
    summarySheetRow.values = [
      "",
      "",
      "",
      globalRailRow.ean,
      globalRailRow.article,
      printNullOrZero(globalRailRow.nbArticles),
      printNullOrZero(globalRailRow.quantity),
      unitTobePrinted(globalRailRow.unite),
      printNullOrZero(globalRailRow.quantityConditioned),
      unitTobePrinted(globalRailRow.uniteDeVente),
    ];

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        if (colNumber === 4) {
          cell.model.type = 2;
          cell.numFmt = "0";
        }
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: colNumber === 7 || colNumber === 8 ? true : false,
          color: {
            argb: colNumber === 7 || colNumber === 8 ? "FF4473c4" : "#000",
          },
          family: 2,
          name: "Arial",
          size: 9,
        };
        if (colNumber > 3) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
  });
  return rowDetailsCount;
};

export const addGlobalZoneRailInSummarySheet = async (
  detailsRow: {
    [Key: string]: SummaryGroupDetail;
  },
  summarySheet: Excel.Worksheet,
  railCount: number
) => {
  let golbalRail: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalRail(detailsRow);

  let globalZoneRail: {
    [Key: string]: GlobalSummary;
  } = await collectGlobalRailbyZone(detailsRow);
  _.forEach(golbalRail, async (globalRailRow: GlobalSummary, index: string) => {
    let summarySheetRow = summarySheet.getRow(railCount);
    let row = [
      "",
      "",
      "",
      "",
      globalRailRow.ean,
      globalRailRow.article,
      printNullOrZero(globalRailRow.nbArticles),
      printNullOrZero(globalRailRow.quantity),
      unitTobePrinted(globalRailRow.unite),
      printNullOrZero(globalRailRow.quantityConditioned),
      unitTobePrinted(globalRailRow.uniteDeVente),
    ];
    summarySheetRow.values = row;

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        if (colNumber === 5) {
          cell.model.type = 2;
          cell.numFmt = "0";
        }
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.font = {
          bold: colNumber === 8 || colNumber === 9 ? true : false,
          color: {
            argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
          },
          family: 2,
          name: "Arial",
          size: 9,
        };
        if (colNumber > 4) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    railCount = railCount + 1;
  });
  return { railCount, globalZoneRail };
};

export const collectGlobalMontant = async (detailsRow: any) => {
  let golbalMontant: {
    [Key: string]: GlobalSummary;
  } = {};

  _.forEach(detailsRow, (detailRow, detailRowIndex) => {
    _.forEach(detailRow.montants, (montantRow, montantRowIndex) => {
      let newGlobalMontantRow = golbalMontant[montantRow.article] || {
        article: montantRow.article,
        ean: montantRow.ean,
        nbArticles: 0,
        quantity: 0,
        unite: montantRow.unite,
        quantityConditioned: 0,
        uniteDeVente: montantRow.uniteDeVente,
        zone: montantRow.zone,
        level: montantRow.levelName,
      };

      golbalMontant[montantRow.article] = {
        ...newGlobalMontantRow,
        nbArticles: newGlobalMontantRow.nbArticles + montantRow.nbArticles,
        quantity: newGlobalMontantRow.quantity + montantRow.quantity,
        quantityConditioned:
          newGlobalMontantRow.quantityConditioned +
          montantRow.quantityConditioned,
      };
    });
  });

  return golbalMontant;
};

export const collectGlobalMontantbyZone = async (detailsRow: any) => {
  let golbalMontantbyZone: {
    [Key: string]: GlobalSummary;
  } = {};

  _.forEach(detailsRow, (detailRow, detailRowIndex) => {
    _.forEach(detailRow.montants, (montantRow, montantRowIndex) => {
      let newGlobalMontantRow = golbalMontantbyZone[
        `${montantRow.article}-${montantRow.zone}`
      ] || {
        article: montantRow.article,
        ean: montantRow.ean,
        nbArticles: 0,
        quantity: 0,
        unite: montantRow.unite,
        quantityConditioned: 0,
        uniteDeVente: montantRow.uniteDeVente,
        zone: montantRow.zone,
        level: montantRow.levelName,
      };

      golbalMontantbyZone[`${montantRow.article}-${montantRow.zone}`] = {
        ...newGlobalMontantRow,
        nbArticles: newGlobalMontantRow.nbArticles + montantRow.nbArticles,
        quantity: newGlobalMontantRow.quantity + montantRow.quantity,
        quantityConditioned:
          newGlobalMontantRow.quantityConditioned +
          montantRow.quantityConditioned,
      };
    });
  });

  return golbalMontantbyZone;
};

export const collectGlobalRail = async (detailsRow: any) => {
  let golbalRail: {
    [Key: string]: GlobalSummary;
  } = {};

  _.forEach(detailsRow, (detailRow, detailRowIndex) => {
    _.forEach(detailRow.rails, (railRow, montantRowIndex) => {
      let newGlobalRailRow = golbalRail[railRow.article] || {
        article: railRow.article,
        ean: railRow.ean,
        nbArticles: 0,
        quantity: 0,
        unite: railRow.unite,
        quantityConditioned: 0,
        uniteDeVente: railRow.uniteDeVente,
        zone: railRow.zone,
        level: railRow.levelName,
      };

      golbalRail[railRow.article] = {
        ...newGlobalRailRow,
        nbArticles: newGlobalRailRow.nbArticles + railRow.nbArticles,
        quantity: newGlobalRailRow.quantity + railRow.quantity,
        quantityConditioned:
          newGlobalRailRow.quantityConditioned + railRow.quantityConditioned,
      };
    });
  });

  return golbalRail;
};

export const collectGlobalRailbyZone = async (detailsRow: any) => {
  let golbalRail: {
    [Key: string]: GlobalSummary;
  } = {};

  _.forEach(detailsRow, (detailRow, detailRowIndex) => {
    _.forEach(detailRow.rails, (railRow, montantRowIndex) => {
      let newGlobalRailRow = golbalRail[
        `${railRow.article}-${railRow.zone}`
      ] || {
        article: railRow.article,
        ean: railRow.ean,
        nbArticles: 0,
        quantity: 0,
        unite: railRow.unite,
        quantityConditioned: 0,
        uniteDeVente: railRow.uniteDeVente,
        zone: railRow.zone,
        level: railRow.levelName,
      };

      golbalRail[`${railRow.article}-${railRow.zone}`] = {
        ...newGlobalRailRow,
        nbArticles: newGlobalRailRow.nbArticles + railRow.nbArticles,
        quantity: newGlobalRailRow.quantity + railRow.quantity,
        quantityConditioned:
          newGlobalRailRow.quantityConditioned + railRow.quantityConditioned,
      };
    });
  });

  return golbalRail;
};

//#region global wool

export const addGlobalWoolInSummarySheet = async (
  globalWools: {
    [Key: string]: GlobalSummary;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  await _.forEach(
    globalWools,
    async (globalWoolRow: GlobalSummary, index: string) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        "",
        "",
        globalWoolRow.ean,
        globalWoolRow.article,
        "",
        printNullOrZero(globalWoolRow.quantity),
        "M2",
        globalWoolRow.quantityConditioned,
        globalWoolRow.saleUnit,
      ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 4) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 7 || colNumber === 8 ? true : false,
            color: {
              argb: colNumber === 7 || colNumber === 8 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 9,
          };
          if (colNumber > 3) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
          if (colNumber === 6) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
  return rowDetailsCount;
};

export const addGlobalZoneWoolInSummarySheet = async (
  globalWools: {
    [Key: string]: GlobalSummary;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  await _.forEach(
    globalWools,
    async (globalWoolRow: GlobalSummary, index: string) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        "",
        "",
        "",
        globalWoolRow.ean,
        globalWoolRow.article,
        "",
        printNullOrZero(globalWoolRow.quantity),
        "M2",
        globalWoolRow.quantityConditioned,
        globalWoolRow.uniteDeVente,
      ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 8 || colNumber === 9 ? true : false,
            color: {
              argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 9,
          };
          if (colNumber > 4) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }

          if (colNumber === 5) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }
          if (colNumber === 7) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
  return rowDetailsCount;
};

export const addGlobalWoolInCommandeSheet = async (
  globalWools: any,
  summarySheet: any,
  rowDetailsCount: any
) => {
  await _.forEach(globalWools, async (globalWoolRow: any, index: any) => {
    let summarySheetRow = summarySheet.getRow(rowDetailsCount);
    summarySheetRow.values = [
      "",
      globalWoolRow.article,
      "",
      ceil(globalWoolRow.quantity),
      "M2",
      "",
      "",
    ];

    summarySheetRow.eachCell(
      { includeEmpty: false },
      function (cell: any, colNumber: any) {
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        cell.font = {
          bold: false,
          color: { argb: "#000" },
          family: 2,
          name: "Arial",
          size: 12,
        };
        if (colNumber > 1) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      }
    );

    rowDetailsCount = rowDetailsCount + 1;
  });
  return rowDetailsCount;
};
//#endregion Global wool

//#region global Accessory

export const addGlobalAccessoryInSummarySheet = async (
  globalAccessory: {
    [Key: string]: GlobalSummary;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  _.forEach(
    globalAccessory,
    async (globalAccessoryRow: GlobalSummary, index: string) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        "",
        "",
        globalAccessoryRow.ean,
        globalAccessoryRow.article,
        "",
        printNullOrZero(globalAccessoryRow.quantity),
        unitTobePrinted(globalAccessoryRow.unite),
        printNullOrZero(globalAccessoryRow.quantityConditioned),
        unitTobePrinted(globalAccessoryRow.saleUnit),
      ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 4) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          if (colNumber === 9 && cell.value === "0") {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 7 || colNumber === 8 ? true : false,
            color: {
              argb: colNumber === 7 || colNumber === 8 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 9,
          };
          if (colNumber > 3) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
          if (colNumber === 6) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
  return rowDetailsCount;
};

export const addGlobalZoneAccessoryInSummarySheet = async (
  globalAccessory: {
    [Key: string]: GlobalSummary;
  },
  summarySheet: Excel.Worksheet,
  rowDetailsCount: number
) => {
  _.forEach(
    globalAccessory,
    async (globalAccessoryRow: GlobalSummary, index: string) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        "",
        "",
        "",
        globalAccessoryRow.ean,
        globalAccessoryRow.article,
        "",
        printNullOrZero(globalAccessoryRow.quantity),
        unitTobePrinted(globalAccessoryRow.unite),
        printNullOrZero(globalAccessoryRow.quantityConditioned),
        unitTobePrinted(globalAccessoryRow.uniteDeVente),
      ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 5) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          if (colNumber === 10 && cell.value === "0") {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
          cell.font = {
            bold: colNumber === 8 || colNumber === 9 ? true : false,
            color: {
              argb: colNumber === 8 || colNumber === 9 ? "FF4473c4" : "#000",
            },
            family: 2,
            name: "Arial",
            size: 9,
          };
          if (colNumber > 4) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
          if (colNumber === 7) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D9D9D9",
              },
            };
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
  return rowDetailsCount;
};

export const addGlobalAccessoryInCommandeSheet = async (
  globalAccessory: any,
  summarySheet: any,
  rowDetailsCount: any
) => {
  await _.forEach(
    globalAccessory,
    async (globalAccessoryRow: any, index: any) => {
      let summarySheetRow = summarySheet.getRow(rowDetailsCount);
      summarySheetRow.values = [
        "",
        globalAccessoryRow.article,
        globalAccessoryRow.ean,
        globalAccessoryRow.quantity,
        globalAccessoryRow.unite === "Kilogramme"
          ? "Kg"
          : globalAccessoryRow.unite,
        ceil(globalAccessoryRow.quantityConditioned),
        globalAccessoryRow.saleUnit,
      ];

      summarySheetRow.eachCell(
        { includeEmpty: false },
        function (cell: any, colNumber: any) {
          if (colNumber === 3) {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          if (colNumber === 6 && cell.value === "0") {
            cell.model.type = 2;
            cell.numFmt = "0";
          }

          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
          };
          cell.font = {
            bold: false,
            color: { argb: "#000" },
            family: 2,
            name: "Arial",
            size: 12,
          };
          if (colNumber > 1) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }
      );

      rowDetailsCount = rowDetailsCount + 1;
    }
  );
  return rowDetailsCount;
};
//#endregion Global Accessory

export const printSummaryDetailRow = (
  excelRow: Excel.Row,
  summaryDetail: SummaryDetail
) => {
  excelRow.values = [
    summaryDetail.levelName,
    "",
    "",
    summaryDetail.ean,
    summaryDetail.article,
    printNullOrZero(summaryDetail.nbArticles),
    printNullOrZero(summaryDetail.quantity),
    unitTobePrinted(summaryDetail.unite),
    printNullOrZero(summaryDetail.quantityConditioned),
    unitTobePrinted(summaryDetail.uniteDeVente),
  ];
};

export const printSummaryZoneDetailRow = (
  excelRow: Excel.Row,
  summaryDetail: SummaryDetail
) => {
  excelRow.values = [
    summaryDetail.levelName,
    "",
    "",
    "",
    summaryDetail.ean,
    summaryDetail.article,
    printNullOrZero(summaryDetail.nbArticles),
    printNullOrZero(summaryDetail.quantity),
    unitTobePrinted(summaryDetail.unite),
    printNullOrZero(summaryDetail.quantityConditioned),
    unitTobePrinted(summaryDetail.uniteDeVente),
  ];
};

export const oneToOneCommandeMapping = (
  unitConversionArray: unitConversionTable[],
  unite: string,
  saleunit: string,
  quantity: string,
  element: string,
  sellingPackage: string
) => {
  /// denominator | alternativeUnit | numeratuer | ub
  /// 1. saleunit => Plaque, Piece, BOITE, Rouleau, Sac

  if (element === "plaq" || element === "rail" || element === "montant") {
    const fromUnitObject: any = _.find(unitConversionArray, function (o) {
      if (
        (o.alternativeUnit.split(",")[1].toLowerCase() ===
          unite.toLowerCase() ||
          o.alternativeUnit.split(",")[0].toLowerCase() ===
            unite.toLowerCase()) &&
        o.element === element
      ) {
        return true;
      }
      return false;
    });

    const toUnitObject: any = _.find(unitConversionArray, function (o) {
      if (
        (o.alternativeUnit.split(",")[1].toLowerCase() ===
          saleunit.toLowerCase() ||
          o.alternativeUnit.split(",")[0].toLowerCase() ===
            saleunit.toLowerCase()) &&
        o.element === element
      ) {
        return true;
      }
      return false;
    });

    if (fromUnitObject) {
      let fromUnitValue = round(
        fromUnitObject.numeratuer / fromUnitObject.denominator,
        2
      );

      if (toUnitObject) {
        let toUnitValue = round(
          toUnitObject.denominator / toUnitObject.numeratuer,
          2
        );
        let toCalculatedValue = ceil(
          Number(quantity) * fromUnitValue * toUnitValue
        );
        return toCalculatedValue;
      }
    }
  }

  if (element === "accessory") {
    const toUnitObject: any = _.find(unitConversionArray, function (o) {
      if (
        (o.alternativeUnit.split(",")[1].toLowerCase() ===
          saleunit.toLowerCase() ||
          o.alternativeUnit.split(",")[0].toLowerCase() ===
            saleunit.toLowerCase()) &&
        o.element === element
      ) {
        return true;
      }
      return false;
    });

    if (toUnitObject) {
      const fromUnitObject: any = _.find(unitConversionArray, function (o) {
        if (
          (o.alternativeUnit.split(",")[1].toLowerCase() ===
            unite.toLowerCase() ||
            o.alternativeUnit.split(",")[0].toLowerCase() ===
              unite.toLowerCase()) &&
          o.element === element
        ) {
          if (
            o.ub.toLowerCase() === toUnitObject.ub.toLowerCase() &&
            o.element === element
          ) {
            return true;
          }
        }
        return false;
      });

      if (fromUnitObject) {
        let fromUnitValue = round(
          fromUnitObject.numeratuer / fromUnitObject.denominator,
          2
        );

        if (toUnitObject) {
          let toUnitValue = round(
            toUnitObject.denominator / toUnitObject.numeratuer,
            2
          );
          let toCalculatedValue = ceil(
            Number(quantity) * fromUnitValue * toUnitValue
          );
          return toCalculatedValue;
        }
      }
    }
  }
  /// 2. alternativeUnit === unite && ub === saleunit

  if (element === "wool") {
    return ceil(parseFloat(quantity) * parseFloat(sellingPackage));
  }
  return 0;

  // const resultObject = _.find(unitConversionArray, function (o) {
  //   if ((o.alternativeUnit.split(",")[1].toLowerCase() === unite.toLowerCase() || o.alternativeUnit.split(",")[0].toLowerCase() === unite.toLowerCase()) && o.element === element) {
  //     if ((o.ub.split(",")[1].toLowerCase() === saleunit.toLowerCase() || o.ub.split(",")[0].toLowerCase() === saleunit.toLowerCase()) && o.element === element) {
  //       return true;
  //     }
  //   }
  //   return false;
  // });

  // if (resultObject) {
  //   const saleResultUnit = round(
  //     (parseFloat(quantity) * parseFloat(resultObject.numeratuer)) /
  //       parseFloat(resultObject.denominator),
  //     2
  //   );
  //   return saleResultUnit;
  // } else {
  //   const isOneToTwo = oneToTwoCommandeMapping(
  //     unitConversionArray,
  //     unite,
  //     saleunit,
  //     quantity,
  //     element
  //   );
  //   return isOneToTwo;
  // }
};

export const oneToTwoCommandeMapping = (
  unitConversionArray: unitConversionTable[],
  unite: string,
  saleunit: string,
  quantity: string,
  element: string
) => {
  const resultFirstObject = _.find(unitConversionArray, function (o) {
    if (
      (o.alternativeUnit.split(",")[1].toLowerCase() === unite.toLowerCase() ||
        o.alternativeUnit.split(",")[0].toLowerCase() ===
          unite.toLowerCase()) &&
      o.element === element
    ) {
      return true;
    }
    return false;
  });

  if (resultFirstObject) {
    const resultSecondObject = _.find(unitConversionArray, function (o) {
      if (
        (o.alternativeUnit.split(",")[1].toLowerCase() ===
          saleunit.toLowerCase() ||
          o.alternativeUnit.split(",")[0].toLowerCase() ===
            saleunit.toLowerCase()) &&
        o.element === element
      ) {
        if (
          (o.ub.split(",")[1].toLowerCase() ===
            resultFirstObject.ub.split(",")[1].toLowerCase() ||
            o.ub.split(",")[0].toLowerCase() ===
              resultFirstObject.ub.split(",")[0].toLowerCase()) &&
          o.element === element
        ) {
          return true;
        }
      }
      return false;
    });

    if (resultSecondObject) {
      const saleResultUnit = Math.ceil(
        (parseFloat(quantity) * parseFloat(resultFirstObject.numeratuer)) /
          parseFloat(resultFirstObject.denominator)
      );
      const saleSecondResultUnit = round(
        (saleResultUnit * parseFloat(resultSecondObject.denominator)) /
          parseFloat(resultSecondObject.numeratuer),
        2
      );
      return saleSecondResultUnit;
    }
  }

  return 0;
};

export const setColumnType = (cell: Excel.Cell) => {
  cell.model.type = 2;
  cell.numFmt = "0";
};

export const setSolidFill = (cell: Excel.Cell) => {
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: "FFFF00",
    },
  };
};
