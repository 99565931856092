import axios from "axios";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";
import { MyConfig } from "../../../../Helper";

export async function bdd(url: string, body: any, config: any) {
  let myBdd = axios.create({
    baseURL: config.REACT_APP_BDDURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });

  myBdd.interceptors.request.use(async (request) => {
    return await authFilter(request, config);
  });

  myBdd.interceptors.response.use(
    // on success interceptor
    (res) => {
      return res;
    },
    // on error (4xx | 5xx ... statuses ) interceptor
    (res) => {
      if (res.response) {
        switch (res.response.status) {
          case 401:
          case 403:
            // if (res.response.data.error === "invalid_token") {
            if (
              config.REACT_APP_AUTHENTICATIONURL +
              "" +
              config.REACT_APP_LOGIN_REDIRECT
            ) {
              window.location.href =
                config.REACT_APP_AUTHENTICATIONURL +
                "" +
                config.REACT_APP_LOGIN_REDIRECT;
            }
            // }
            break;
          default:
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            break;
        }
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }

      return res;
    }
  );
  return await myBdd.post(url, body);
}
