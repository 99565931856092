import _, { has } from "lodash";
import { sortPlacoDataByGFRWorksName } from "../Helpers";
import { SelectionStore } from "../Reducers";
import { PlacoProducts, ProductDetailData, SolutionFilterType } from "../Types";

export async function asyncForEach(array: any, callback: any) {
  if (array.length > 0) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
}

/**
 * save placo data to store
 * @param reduxState The store
 * @param returnAttributes The placo attributes
 * @param products The placo products
 * @returns
 */
export const savePlacoData =
  (
    reduxState: SelectionStore,
    returnAttributes: any[],
    products: any,
    sgObjectAttribute: any
  ): any =>
  async (dispatch: any, getState: any) => {
    try {
      let productsWithFilterFields = await addFilterFields(
        products,
        returnAttributes
      );

      let distingutedPlacoProducts = await sortPlacoDataByGFRWorksName(
        productsWithFilterFields
      );

      let filterCopy = await assignFiltersId(
        reduxState.filters,
        returnAttributes
      );

      filterCopy = await assembleFilters(
        distingutedPlacoProducts,
        reduxState.filters
      );

      sortFilterData(filterCopy);

      dispatch({
        type: "SAVE_PLACO_FILTER_PRODUCTS",
        solutions: distingutedPlacoProducts,
        filters: filterCopy,
        sgObjectAttribute: sgObjectAttribute,
      });
    } catch (err) {}
  };

/**
 * Function to add filter fields to products
 * @param products The placo products
 * @param returnAttributes The placo attributes
 * @returns The placo products with filter fields
 */
const addFilterFields = async (
  products: ProductDetailData[],
  returnAttributes: any[]
) => {
  await asyncForEach(products, async (sys: any) => {
    const gfrWorksNameObject = _.find(sys.attributes, {
      technicalName: "GFR-Works name",
    });

    if (gfrWorksNameObject) {
      await asyncForEach(returnAttributes, async (returnAttr: any) => {
        let attr = sys.attributes.find(
          (attr: any) => attr.aid === returnAttr.aid
        );

        if (attr && returnAttr.type) {
          let attrValue;
          if (attr.values && attr.values.length > 0) {
            if (returnAttr.type.toUpperCase() === "INTEGER")
              attrValue = attr.values[0].numericValue;
            else {
              attrValue = attr.values[0].value;
            }
          }
          sys[attr.technicalName] = attrValue;
        }
        return returnAttr;
      });

      if (!has(sys, "GFR-Layout possible")) {
        sys["GFR-Layout possible"] = "False";
      }
    }
    return sys;
  });

  return products;
};

/**
 *
 * @param filterCopy The filters
 * @param returnAttributes the placo attributes
 * @returns The filters with aid
 */
const assignFiltersId = async (
  filterCopy: SolutionFilterType,
  returnAttributes: any[]
) => {
  filterCopy.partitionWalls = _.map(
    filterCopy.partitionWalls,
    (wall, index) => {
      const attr = _.find(returnAttributes, {
        technicalName: wall.pimAttribute,
      });
      if (attr) {
        wall.aid = attr.aid;
      }
      return wall;
    }
  );

  filterCopy.liningWalls = _.map(filterCopy.liningWalls, (wall, index) => {
    const attr = _.find(returnAttributes, {
      technicalName: wall.pimAttribute,
    });
    if (attr) {
      wall.aid = attr.aid;
    }
    return wall;
  });

  filterCopy.ceilings = _.map(filterCopy.ceilings, (wall, index) => {
    const attr = _.find(returnAttributes, {
      technicalName: wall.pimAttribute,
    });
    if (attr) {
      wall.aid = attr.aid;
    }
    return wall;
  });

  filterCopy.protectionFeu = _.map(filterCopy.protectionFeu, (wall, index) => {
    const attr = _.find(returnAttributes, {
      technicalName: wall.pimAttribute,
    });
    if (attr) {
      wall.aid = attr.aid;
    }
    return wall;
  });

  filterCopy.conduitGaines = _.map(filterCopy.conduitGaines, (wall, index) => {
    const attr = _.find(returnAttributes, {
      technicalName: wall.pimAttribute,
    });
    if (attr) {
      wall.aid = attr.aid;
    }
    return wall;
  });

  filterCopy.gainesTech = _.map(filterCopy.gainesTech, (wall, index) => {
    const attr = _.find(returnAttributes, {
      technicalName: wall.pimAttribute,
    });
    if (attr) {
      wall.aid = attr.aid;
    }
    return wall;
  });

  return filterCopy;
};

/**
 * Fill the filters with options
 * @param distingutedPlacoProducts The sorted placo products based on "GFR-Works Name"
 * @param filterCopy The filters
 * @returns The filters with aid and options
 */
const assembleFilters = async (
  distingutedPlacoProducts: PlacoProducts,
  filterCopy: SolutionFilterType
) => {
  await asyncForEach(
    distingutedPlacoProducts.partitionWalls,
    async (sys: any) => {
      filterCopy.partitionWalls.forEach((filter) => {
        filter.options = filter.options || [];
        let attrValue = sys[filter.pimAttribute];
        if (attrValue !== undefined) {
          if (!filter.options.includes(attrValue))
            filter.options.push(attrValue);
        }
        return filter;
      });
    }
  );

  await asyncForEach(distingutedPlacoProducts.liningWalls, async (sys: any) => {
    filterCopy.liningWalls.forEach((filter) => {
      filter.options = filter.options || [];
      let attrValue = sys[filter.pimAttribute];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      }
      return filter;
    });
  });

  await asyncForEach(distingutedPlacoProducts.ceilings, async (sys: any) => {
    filterCopy.ceilings.forEach((filter) => {
      filter.options = filter.options || [];
      let attrValue = sys[filter.pimAttribute];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      }
      return filter;
    });
  });

  await asyncForEach(
    distingutedPlacoProducts.protectionFeu,
    async (sys: any) => {
      filterCopy.protectionFeu.forEach((filter) => {
        filter.options = filter.options || [];
        let attrValue = sys[filter.pimAttribute];
        if (attrValue !== undefined) {
          if (!filter.options.includes(attrValue))
            filter.options.push(attrValue);
        }
        return filter;
      });
    }
  );

  await asyncForEach(distingutedPlacoProducts.gainesTech, async (sys: any) => {
    filterCopy.gainesTech.forEach((filter) => {
      filter.options = filter.options || [];
      let attrValue = sys[filter.pimAttribute];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      }
      return filter;
    });
  });

  await asyncForEach(
    distingutedPlacoProducts.conduitGaines,
    async (sys: any) => {
      filterCopy.conduitGaines.forEach((filter) => {
        filter.options = filter.options || [];
        let attrValue = sys[filter.pimAttribute];
        if (attrValue !== undefined) {
          if (!filter.options.includes(attrValue))
            filter.options.push(attrValue);
        }
        return filter;
      });
    }
  );

  return filterCopy;
};

const sortFilterData = (filterCopy: SolutionFilterType) => {
  filterCopy.partitionWalls.map((o) => sortOptions(o));
  filterCopy.liningWalls.map((o) => sortOptions(o));
  filterCopy.ceilings.map((o) => sortOptions(o));
  filterCopy.protectionFeu.map((o) => sortOptions(o));
  filterCopy.gainesTech.map((o) => sortOptions(o));
  filterCopy.conduitGaines.map((o) => sortOptions(o));
};

const sortOptions = (data: any) => {
  if (data !== null && data.options !== null) {
    data.options.sort((a: any, b: any) => {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;

      var aN = parseInt(a);
      var bN = parseInt(b);

      if (isNaN(aN) && isNaN(bN)) {
        var aaA = a.replace(reA, "");
        var bbA = b.replace(reA, "");

        if (aaA === bbA) {
          var aN = parseInt(a.replace(reN, ""), 10);
          var bN = parseInt(b.replace(reN, ""), 10);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
        }
      }

      if (!isNaN(aN) && !isNaN(bN)) {
        return aN < bN ? -1 : aN > bN ? 1 : 0;
      }

      return a < b ? -1 : a > b ? 1 : 0;
    });
  }

  return data;
};
