import React from "react";
import { Image, Button, Card, Popup, Modal } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import { selectBuffer, showLayoutDetails, showSystemDetails } from "../Actions";
import { selectorTp } from "../Actions/types";
import { ProductDetailData } from "../Types";
import { Routes } from "./root";

const language = "French";

const mapStateToProps = (state: SelectionStore) => {
  return {
    language: state.language,
    config: state.config,
  };
};

const mapDispatchToProps = {
  selectBuffer,
  showSystemDetails: showSystemDetails,
  showLayoutDetails: showLayoutDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  selectorType: selectorTp;
  selectionBuffer: ProductDetailData | null;
  oid: string;
  image: string;
  system: ProductDetailData;
  productName: string;
  language: string;
  wording: {
    details: { [key: string]: string };
    configuration: { [key: string]: string };
    contentWarning: { [key: string]: string };
  };
  layoutPossible: boolean;
  setRoute: any;
  config: any;
};

export class PlacoCard extends React.Component<
  Props,
  { clickedWarning: boolean; lang: string }
> {
  public static defaultProps = {
    wording: {
      details: {
        French: "Détails",
        English: "Details",
      },
      configuration: {
        French: "Configuration",
        English: "Configuration",
      },
      contentWarning: {
        French:
          "Votre session a expiré, veuillez vous reconnecter pour voir cette page",
        English:
          "Your session has expired, please log in again to see this page",
      },
    },
  };
  constructor(props: any) {
    super(props);
    this.state = {
      clickedWarning: false,
      lang: "English",
    };
  }
  componentDidMount() {
    let language: any = localStorage.getItem("savedLanguage");
    const changeLanguage = async () => {
      if (language === "English") {
        this.setState({ lang: "English" });
      } else if (language === "French") {
        this.setState({ lang: "French" });
      } else {
        this.setState({ lang: "English" });
      }
    };
    changeLanguage();
  }

  baseImageUrl = this.props.config.REACT_APP_BDDURL?.replace(
    "/plugins/authenticated",
    ""
  ) as string;

  render() {
    const {
      selectionBuffer,
      oid,
      wording: { configuration, contentWarning },
      productName,
      image,
      system,
    } = this.props;
    return (
      <Card
        style={{
          cursor: "pointer",
          margin: ".2em .2em .2em 5px",
          border:
            selectionBuffer !== null
              ? oid === selectionBuffer.oid
                ? `2px solid rgb(${100}, ${100}, ${255})`
                : "none"
              : "none",
        }}
      >
        <Popup
          inverted
          position="top right"
          disabled={!this.props.layoutPossible ? false : true}
          content="Solution non calepinable dans la version actuelle du PLACO®BIM"
          trigger={
            <Card.Content
              onClick={(e: any) => {
                this.props.selectBuffer(system);
              }}
              style={
                !this.props.layoutPossible
                  ? { backgroundColor: "#d4d0d0bf", border: "none" }
                  : {}
              }
            >
              <Image
                floated="left"
                size="tiny"
                src={
                  image
                    ? this.baseImageUrl + image
                    : "/dummy-img.png"
                }
              />
              <Card.Content>{productName}</Card.Content>
            </Card.Content>
          }
        />
        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              color="blue"
              onClick={() => {
                if (localStorage.getItem("min") !== "0:0") {
                  // api.windowsHandler.showDevTools();
                  // this.props.setDetailOid(this.props.oid);
                  this.props.selectBuffer(this.props.system);
                  // this.props.showSystemDetails();
                  this.props.setRoute(Routes.SYSTEM_DETAILS);
                  // this.props.setProductDetailName(this.props.productName);
                } else {
                  this.setState({ clickedWarning: true });
                }
              }}
            // disabled={productName.includes("Gaine technique")}
            >
              {this.props.wording.details[language]}
            </Button>
            {this.props.selectorType !== "ceiling" &&
              this.props.layoutPossible && (
                <Button
                  color="green"
                  onClick={() => {
                    if (localStorage.getItem("min") !== "0:0") {
                      this.props.selectBuffer(this.props.system);
                      //this.props.showLayoutDetails();
                      this.props.setRoute(Routes.SYSTEM_CONFIGURATION);
                      // this.props.setDetailOid(this.props.oid);
                      // this.props.setProductDetailName(this.props.productName);
                    } else {
                      this.setState({ clickedWarning: true });
                    }
                  }}
                  disabled={productName.includes("Gaine technique")}
                >
                  {configuration[language]}
                </Button>
              )}
          </div>
        </Card.Content>
        <Modal
          size="mini"
          open={this.state.clickedWarning}
        //onClose={() => setClickedWarning(false)}
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Content>{contentWarning[this.state.lang]}</Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() => this.setState({ clickedWarning: false })}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </Card>
    );
  }
}

export default connector(PlacoCard);
