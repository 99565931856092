import { AxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";
import { api } from "../../RevitJS/API";
import { getJwtToken } from "../authenticate.api";
import { MyConfig } from "../../Helper";

export const getValidatedToken = (
  token: string | null,
  tokenLabel: string
): string | null => {
  if (token !== null) {
    let authData: any = jwt_decode(token);
    const { exp } = authData;
    let currentTime: Date = new Date();
    let stamp = currentTime.getTime() / 1000;
    if (exp < stamp) {
      token = null;
      localStorage.removeItem(tokenLabel);
    }
  }
  return token;
};

const registerFing = async (token: string, config: any) => {
  try {
    await api.license.RegisterFingerprint(token, config);
  } catch (ex) {}
};

export const authFilter = async (
  request: AxiosRequestConfig,
  config: any
): Promise<AxiosRequestConfig> => {
  let token = localStorage.getItem("token");

  token = getValidatedToken(token, "token");
  let refreshToken = localStorage.getItem("refresh_token");
  refreshToken = getValidatedToken(refreshToken, "refresh_token");

  if (refreshToken === null) {
    //TODO: initiate login
    window.location.href =
      config.REACT_APP_AUTHENTICATIONURL + `` + config.REACT_APP_LOGIN_REDIRECT;
  }
  if (token === null && refreshToken !== null) {
    const jwtToken = await getJwtToken(refreshToken, config);
    if (jwtToken) {
      token = jwtToken.access_token;
      await registerFing(token, config);
      localStorage.setItem("token", token);
    }
  }
  if (request.headers !== undefined) {
    if (request.headers.Authorization !== undefined) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return request ;
};

// export function AuthenticationInterceptor() {

//     Axios.interceptors.request.use( async (request) => {
//         if(request.url?.includes('/user/') || request.url?.includes('/bimlibrary/prepare/')) {
//             request = await authFilter(request);
//         }
//         return request;
//     })
// }
