import { AppWindowName } from "../assets/constants";
import StateModel from "../models/State.model";
import { DATA_INJECTION_OPTIONS } from "../services/constants.config";

export let RootState: StateModel = {
    app: {
        currentWindow: AppWindowName.CSV_IMPORT_WINDOW,
    },
    csv: {
        list: [],
        selected: [],
        optionalProperties: DATA_INJECTION_OPTIONS,
        selectedOptionalProperties:[]
    },
};
