import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import CalpinageRoot from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "9c7b1ed8-69db-45fb-8550-e441f3306a4d";

export const CalpinageTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    610,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Calepinage",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="calepinage"
    >
      <CalpinageRoot />
    </ParentRoot>
  );
};

export const CalepinageRootComponentTest = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="calepinage"
    >
      <CalpinageRoot />
    </ParentRoot>
  );
};

export const Calpinage: Functionality = {
  Name: {
    French: "Calepiner / Quantifier",
    English: "Layout",
    German: "Calepiner / Quantifier",
    Italian: "Calepiner / Quantifier",
    Indonesian: "Calepiner / Quantifier",
  },
  Trigger: CalpinageTrigger,
  ShortDesc: {
    French: "Calepiner et préparer les quantitatifs",
    English: "Perform the layout",
    German: "Calepiner et préparer les quantitatifs",
    Italian: "Calepiner et préparer les quantitatifs",
    Indonesian: "Calepiner et préparer les quantitatifs",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Calepiner.png",
  RootComponent,
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
