import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

export const BackupModal = (props: {
    open: boolean;
    backupSubmit: any;
    documentType: string;
  }) => {
    return (
      <Modal open={props.open} size="mini" dimmer="inverted">
        <Modal.Header className="modalHeader">
          Sauvegarde
          <Icon
            name="close"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => props.backupSubmit(false)}
          ></Icon>
        </Modal.Header>
        <Modal.Description style={{ textAlign: "center", padding: "15px 0px" }}>
          <p>
            Voulez-vous sauvegarder la configuration
            {props.documentType} avant de quitter ce menu ?
          </p>
        </Modal.Description>
        <div style={{ margin: "10px 0px", textAlign: "center" }}>
          <Button
            color="orange"
            size="tiny"
            onClick={() => {
              props.backupSubmit(false);
            }}
          >
            Non
          </Button>
          <Button
            size="tiny"
            type="submit"
            primary
            onClick={() => {
              props.backupSubmit(true);
            }}
          >
            Oui
          </Button>
        </div>
      </Modal>
    );
  };