import React, { Component } from "react";
import {
  Modal,
  Icon,
  Form,
  Message,
  Button,
  Loader,
  Grid,
} from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../../Selection/Reducers";
import { FunctionalityHeader } from "../FunctionalityHeader";
import { api } from "../../../../../RevitJS/API/index";
import "../../Resources/SavedCalpinage.css";
import "../../Resources/modalCalpinage.css";
import { dbStoreNameCalepinage, Routes } from "../root";
import {
  setCalpinageData,
  loadCalepinage,
  updateCalepinageName,
} from "../../Actions/index";
import { v4 } from "uuid";
import SavedSelections from "./SavedSelections";
import forEach from "lodash/forEach";
import { bimStorage, storageKey } from "../../../../../BIMStore";
import { SaveProject } from "../../../CommonModules/PopUpModals/SaveProject";
import {
  setHistoryPath,
  setprojectData,
} from "../../../Selection/Actions/index";
import { initProjectData } from "../../../../../RevitJS/Types/StoreTypes";
import isEmpty from "lodash/isEmpty";
import { isEqual, reduce, size } from "lodash";
import { Selection } from "../../../Selection/Actions/types";
import { PlacoOptions } from "../../../../../RevitJS/Types/StoreTypes";
import { loadSelections } from "../../../Selection/Actions";

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  Icon: string;
  name: string;
  wording: {
    title: { [key: string]: string };
    saveCalpinageList: { [key: string]: string };
    documentName: { [key: string]: string };
    modificationDate: { [key: string]: string };
    newCalpinage: { [key: string]: string };
    noDocuments: { [key: string]: string };
    invalidDocumentName: { [key: string]: string };
    layoutName: { [key: string]: string };
    documentNameErrorMsg: { [key: string]: string };
    documentDuplicateErrorMsg: { [key: string]: string };
    back: { [key: string]: string };
    validate: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  route: string;
  setCalpinageData: any;
  loadCalepinage: any;
  moduleData: any;
  updateCalepinageName: any;
  setprojectData: any;
  projectData: initProjectData;
  setHistoryPath: any;
  config: any;
  loadSelections: any;
  selections: any;
};

interface State {
  documentNameDimmer: boolean;
  newDocumentName: string;
  documentNameError: boolean;
  editId: string;
  editName: string;
  errorMessage: string;
  documentDuplicateError: boolean;
  disabled: boolean;
  confirmModal: boolean;
  loading: boolean;
}

class SavedCalpinage extends Component<Props, State> {
  state = {
    documentNameDimmer: false,
    newDocumentName: "",
    documentNameError: false,
    editId: "",
    editName: "",
    errorMessage: "",
    documentDuplicateError: false,
    disabled: true,
    confirmModal: false,
    loading: false,
  };

  componentDidMount = async () => {
    this.props.setHistoryPath("savedcalpinage");
    //api.windowsHandler.resizeWindow(900, 610);
    if (!(Object.keys(this.props.selections).length > 0)) {
      let placoSelections = await bimStorage.getItem(
        storageKey.PLACOSELECTIONS
      );

      if (placoSelections) {
        let selections = placoSelections as {
          [key: string]: Selection<PlacoOptions>;
        };
        this.props.loadSelections(selections);
      }
    }

    this.setState({ loading: true });
    this.fetchProjectId();
  };

  loadSaveModule = async (projectId: string) => {
    if (isEmpty(this.props.moduleData.calpinageData.data)) {
      let calepinageData = await bimStorage.listModule(
        dbStoreNameCalepinage,
        projectId
      );

      if (calepinageData) {
        let selections = reduce(
          calepinageData,
          function (obj: any, param: any) {
            obj[param.Id] = param;
            return obj;
          },
          {}
        );

        if (!isEqual(this.props.moduleData.calpinageData.data, selections)) {
          await this.props.loadCalepinage(selections);
        }
      }
    }

    this.setState({ loading: false });
  };

  fetchProjectId = async () => {
    if (this.props.projectData.ProjectId === "") {
      let projectData: initProjectData = await api.queries.getSetProjectData();

      if (projectData.ProjectId === "") {
        this.toggleModal();
      } else {
        this.props.setprojectData(projectData);
        this.loadSaveModule(projectData.ProjectId);
      }
    } else {
      this.loadSaveModule(this.props.projectData.ProjectId);
    }
  };
  toggleModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  onYesAction = () => {
    this.toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };

  public static defaultProps = {
    wording: {
      title: {
        French: "CALEPINAGE",
        English: "LAYOUT",
      },
      saveCalpinageList: {
        French: "Liste des calepinages sauvegardés",
        English: "List of saved workbooks",
      },
      documentName: {
        French: "Nom de la configuration",
        English: "Configuration Name",
      },
      modificationDate: {
        French: "Date de modification",
        English: "Modification Date",
      },
      newCalpinage: {
        French: "Nouveau calepinage",
        English: "New Layout",
      },
      noDocuments: {
        French: "Aucune Configuration de Calepinage sauvegardée",
        English: "No documents saved",
      },

      invalidDocumentName: {
        French: "Nom de document non valide",
        English: "Invalid document name",
      },
      layoutName: {
        French: "Nom du calepinage",
        English: "Name of the layout",
      },
      documentNameErrorMsg: {
        French: "Le nom du document doit contenir au moins 5 caractères",
        English: "Document name must contain at least 5 characters",
      },
      documentDuplicateErrorMsg: {
        French: "Une autre sélection existe déjà sous ce nom",
        English: "Another selection already exists under this name",
      },
      back: {
        French: "Annuler",
        English: "Cancel",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
    },
  };

  closeDimmer = () => {
    this.setState({
      documentNameDimmer: false,
      documentNameError: false,
      documentDuplicateError: false,
      newDocumentName: "",
    });
  };

  openDimmer = () => {
    this.setState({
      documentNameDimmer: true,
    });
  };

  handleDocumentName = async () => {
    if (this.state.newDocumentName.trim().length < 5) {
      this.setState({
        documentNameError: true,
      });
    } else {
      let isDocumentFound = Object.keys(
        this.props.moduleData.calpinageData.data
      ).find((e: any) => {
        return (
          this.props.moduleData.calpinageData.data[e].Name.toLowerCase() ===
          this.state.newDocumentName.trim().toLowerCase()
        );
      });

      if (isDocumentFound) {
        this.setState({
          documentDuplicateError: true,
        });
      } else {
        let date = new Date();
        let data: any = {
          Name: this.state.newDocumentName,
          Date: `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          selections: { status: false, list: [] },
          calepinage: {
            status: false,
            list: { walls: [], ceilings: [] },
            version: 1,
          },
          Id: v4(),
          revitView: this.props.projectData.ProjectId,
          time: Date.now(),
        };
        this.props.setCalpinageData(data);
        this.props.setRoute(Routes.CALPINAGE_HOME);
        this.closeDimmer();
      }
    }
  };

  resetNewDocumentName = () => this.setState({ newDocumentName: "" });
  resetDocumentNameError = () => this.setState({ documentNameError: false });

  editSelectionName = (Id: string, Name: string) => {
    this.setState({ editId: Id, editName: Name });
    this.setState({ errorMessage: "" });
  };

  updateSelectionname = async (Id: string, Name: string) => {
    let errorMessage = "";
    if (Name === "") {
      errorMessage = "Nom de sélection invalide";
    }
    if (Name.trim().length < 5) {
      errorMessage = "Texte de 5 caractères minimum";
    }
    forEach(this.props.moduleData.calpinageData.data, (value) => {
      if (
        value.Name.toLowerCase() === Name.trim().toLowerCase() &&
        Id !== value.Id
      ) {
        errorMessage = "Une autre sélection existe déjà sous ce nom";
      }
    });

    if (errorMessage === "") {
      let date = new Date();
      const modifiedDate = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;

      await bimStorage.renameModule(
        dbStoreNameCalepinage,
        this.props.projectData.ProjectId,
        Name.trim(),
        modifiedDate,
        Id
      );

      this.props.updateCalepinageName(Id, Name.trim(), modifiedDate);
      this.editSelectionName("", "");
      this.setState({ errorMessage: "" });
    } else {
      this.setState({ errorMessage: errorMessage });
    }
    localStorage.setItem("isModification", "false");
  };

  render() {
    const {
      documentNameDimmer,
      newDocumentName,
      documentNameError,
      documentDuplicateError,
    } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <SaveProject
          toggleModal={this.toggleModal}
          confirmModal={this.state.confirmModal}
          onYesAction={this.onYesAction}
        />
        <FunctionalityHeader
          Icon={this.props.Icon}
          name={this.props.wording.title[this.props.language]}
          subheader={this.props.wording.saveCalpinageList[this.props.language]}
        />

        <Modal
          onClose={this.closeDimmer}
          open={documentNameDimmer}
          size="mini"
          dimmer="blurring"
        >
          <Modal.Header
            className="modalHeader"
            content={this.props.wording.documentName[this.props.language]}
          ></Modal.Header>
          <Modal.Actions className="modalActions">
            <Form>
              <Form.Input
                autoFocus
                //error={documentNameError}
                fluid
                placeholder="Texte de 5 caractères minimum"
                value={newDocumentName}
                onChange={(e, data) => {
                  let value = data.value.trim().toLowerCase();
                  this.setState({ newDocumentName: data.value });

                  if (value.length < 5) {
                    this.setState({
                      disabled: true,
                      documentDuplicateError: false,
                    });
                  } else {
                    this.setState({
                      disabled: false,
                      documentDuplicateError: false,
                    });
                  }
                }}
              />
            </Form>
            {documentNameError && (
              <Message
                style={{ textAlign: "left" }}
                error
                // header={
                //   this.props.wording.invalidDocumentName[this.props.language]
                // }
                content={
                  this.props.wording.documentNameErrorMsg[this.props.language]
                }
              />
            )}
            {documentDuplicateError && (
              <Message
                style={{ textAlign: "left" }}
                error
                content={
                  this.props.wording.documentDuplicateErrorMsg[
                    this.props.language
                  ]
                }
              />
            )}

            <div className="modalButton">
              <Button color="orange" onClick={this.closeDimmer}>
                {this.props.wording.back[this.props.language]}
              </Button>
              <Button
                disabled={this.state.disabled}
                type="submit"
                primary
                onClick={this.handleDocumentName}
              >
                {this.props.wording.validate[this.props.language]}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>

        <div
          style={{ height: "calc(100vh - 140px)", width: "100%", padding: 10 }}
        >
          <div style={{ height: "100%" }}>
            <div
              className="headerRow"
              style={{ border: "0.5px solid rgb(200, 200, 200)" }}
            >
              <div
                className="headerCols"
                style={{
                  // borderRight: "solid white 0.2px",
                  width: "50%",
                  height: 30,
                }}
              >
                {this.props.wording.documentName[this.props.language]}
              </div>
              <div
                className="headerCols"
                style={{
                  // borderLeft: "solid white 0.2px",
                  width: "50%",
                  height: 30,
                }}
              >
                {this.props.wording.modificationDate[this.props.language]}
              </div>
            </div>
            <div
              style={{
                border: "0.5px solid rgb(200, 200, 200)",
                height: "92%",
                overflowY: "auto",
              }}
            >
              {this.state.loading && <Loader active={this.state.loading} />}
              {size(this.props.moduleData.calpinageData.data) === 0 ? (
                <Grid.Row>
                  <Grid.Column
                    style={{
                      width: "100%",
                      color: "rgb(175,171,171)",
                      padding: 20,
                      textAlign: "center",
                    }}
                  >
                    {/* <div className="noDocs"> */}
                    {this.props.wording.noDocuments[this.props.language]}
                    {/* </div> */}
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <SavedSelections
                  setRoute={this.props.setRoute}
                  editSelectionName={this.editSelectionName}
                  updateSelectionname={this.updateSelectionname}
                  editId={this.state.editId}
                  editName={this.state.editName}
                  setEditName={(name: string) =>
                    this.setState({ editName: name })
                  }
                  revitViewName={this.props.projectData.ProjectId}
                  errorMessage={this.state.errorMessage}
                />
              )}
            </div>
          </div>
        </div>

        <span
          style={{
            padding: 10,
            cursor: "pointer",
          }}
          onClick={this.openDimmer}
        >
          <Icon name="plus circle" color="blue" size="big" />
          <span style={{ color: "rgb(14,110,184)" }}>
            {this.props.wording.newCalpinage[this.props.language]}
          </span>
        </span>
      </div>
    );
  }
}

const mapState = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    setRoute: ownProps.setRoute,
    route: ownProps.route,
    moduleData: state.moduleData,
    projectData: state.projectData,
    config: state.config,
    selections: state.selections,
  };
};

const mapDispatch = {
  setCalpinageData,
  loadCalepinage,
  updateCalepinageName,
  setprojectData,
  setHistoryPath,
  loadSelections,
};

const connector = connect(mapState, mapDispatch);

export default connect(mapState, mapDispatch)(SavedCalpinage);
