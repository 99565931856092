import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { ProfileRoot } from "./Components/root";

export const functionalityId = "f41ccce4-f6ad-442a-91aa-30db4308de5b";

export const profileTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    500,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <ProfileRoot />
    </ParentRoot>
  );
};

export const ProfileRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <ProfileRoot />
    </ParentRoot>
  );
};

export const UpdateProfile: Functionality = {
  Name: {},
  Trigger: profileTrigger,
  ShortDesc: {},
  Id: functionalityId,
  Icon: "",
  RootComponent,
  ParentId: "0",
};

export default UpdateProfile;
