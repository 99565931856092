import React, { Component } from "react";
import { Button, Input } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { MyConfig } from "../../../../Helper";
import { DimensionRevitApi } from "../../../../ErrorManagement/utils/revitApiError";

interface Props {
  onError: any;
}

export default class Waiter extends Component<Props> {
  state = {
    distance: 400,
    config: { REACT_APP_SERVERURL: "" },
  };

  componentDidMount = async () => {
    const cng = await MyConfig();
    this.setState({ config: cng });
  };
  render = () => {
    return (
      <div>
        <FunctionalityHeader
          Icon={this.state.config.REACT_APP_SERVERURL + "/PlacoBIM.jpg"}
          name={"Cotation"}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 20 }}>
            Distance des cotes (mm):{" "}
            <Input
              style={{ margin: 10 }}
              value={this.state.distance}
              onChange={(e, d) => {
                isNaN(parseFloat(d.value))
                  ? this.setState({ distance: "" })
                  : this.setState({ distance: parseFloat(d.value) });
              }}
            />
            <Button
              primary
              disabled={this.state.distance > 0 ? false : true}
              onClick={async () => {
                await api.familyEditor.addCotesMM(this.state.distance);
                DimensionRevitApi(this.props);
                //api.windowsHandler.closeWindow();
              }}
            >
              Valider
            </Button>
          </div>
        </div>
      </div>
    );
  };
}
