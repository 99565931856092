import { AnyAction } from "redux";
import {
  ADD_CSV_FILE,
  SELECT_FILE,
  SELECT_OPTIONAL_PROPERTIES,
} from "../actions/actionTypes";
import { RootState } from "./initialState";
import _ from "lodash";
import propertiesReducer from "../../../PlacoBIMv5/Properties/store/properties/reducers";

export default function csv(state = RootState.csv, action: AnyAction) {
  switch (action.type) {
    case ADD_CSV_FILE:
      let tmp = _.cloneDeep(state.list);
      tmp.push(action.payload);
      return { ...state, list: tmp };
    case SELECT_FILE:
      let tmp1 = _.cloneDeep(state.selected);
      tmp1.push(action.payload);
      return { ...state, selected: tmp1 };
    case SELECT_OPTIONAL_PROPERTIES:
      return {
        ...state,
        selectedOptionalProperties: action.checked
          ? [
              ...state.selectedOptionalProperties,
              { ...action.property, checked: action.checked },
            ]
          : [
              ...state.selectedOptionalProperties.filter(
                (property) => property.label !== action.property.label
              ),
            ],
        optionalProperties: [
          ...state.optionalProperties.map((property) =>
            property.label === action.property.label
              ? { ...property, checked: action.checked }
              : property
          ),
        ],
      };
    default:
      return state;
  }
}
