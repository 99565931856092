import React, { Component } from "react";
import { connect } from "react-redux";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../Components/FunctionalityHeader";
import { InfoForm } from "./InfoForm";
import "../Resources/Technical.css";
import { SelectionStore } from "../../Selection/Reducers";
import { setPrevPage } from "../../DocumentTechniques/Actions";
import { SaveProject } from "../../CommonModules/PopUpModals/SaveProject";
import { setprojectData } from "../../Selection/Actions";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { setDossierData } from "../../DocumentTechniques/Actions";
import { Cursor } from "../../../../RevitJS/API/WindowsHandler";
interface Props {
  Icon: string;
  name: string;
  wording: any;
  language: string;
  setRoute: any;
  routes: any;
  prevPage: string;
  setPrevPage: any;
  setprojectData: any;
  projectData: initProjectData;
  dossierData: any;
  setDossierData: any;
  config: any;
}

export class InfoProjects extends Component<Props> {
  state = {
    yourLanguage: null,
    currentView: null,
    confirmModal: false,
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  componentDidMount = async () => {
    api.windowsHandler.showCursor(Cursor.Normal);
    let projectData: initProjectData = await api.queries.getSetProjectData();
    if (projectData.ProjectId === "") {
      this.toggleModal();
    } else {
      this.props.setprojectData(projectData);
    }
  };

  toggleModal = () => {
    this.setState({ confirmModal: !this.state.confirmModal });
  };

  onYesAction = async () => {
    this.toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };

  changeDateHandler = () => {
    let today: any = new Date();
    let date =
      today.getDate() +
      "/" +
      parseInt(today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    bimStorage.setItem(storageKey.PROJECT_INFO_DATE, JSON.stringify(date));
  };

  render = () => {
    const country = getCountry();
    const formData = [
      {
        header: this.props.wording.formHeader1[this.props.language],
        fields: [
          {
            type: "input",
            name: "information_enterprise_name",
            label: this.props.wording.name[this.props.language],
            mandatory: true,
          },
          {
            type: "file",
            name: "information_enterprise_logo",
            label: "Logo",
            mandatory: true,
          },
          {
            type: "input",
            name: "information_enterprise_address",
            label: this.props.wording.address[this.props.language],
            mandatory: true,
          },
          {
            type: "input",
            name: "information_enterprise_postal",
            label: this.props.wording.codePostal[this.props.language],
            mandatory: true,
          },
          {
            type: "input",
            name: "information_enterprise_villa",
            label: this.props.wording.city[this.props.language],
            mandatory: true,
          },
          {
            type: "select",
            name: "information_enterprise_pays",
            label: this.props.wording.pays[this.props.language],
            options: country,
            defaultData: "France",
            mandatory: true,
          },
          {
            type: "input",
            name: "information_enterprise_telephone",
            label: this.props.wording.telephone[this.props.language],
            mandatory: false,
          },
          {
            type: "input",
            name: "information_enterprise_email",
            label: this.props.wording.email[this.props.language],
            mandatory: false,
          },
        ],
      },
      {
        header: this.props.wording.formHeader2[this.props.language],
        fields: [
          {
            type: "input",
            name: "information_chantier_reference",
            label: this.props.wording.reference[this.props.language],
            mandatory: true,
          },
          {
            type: "input",
            name: "information_chantier_address",
            label: this.props.wording.address[this.props.language],
            mandatory: true,
          },
          {
            type: "input",
            name: "information_chantier_postal",
            label: this.props.wording.codePostal[this.props.language],
            mandatory: true,
          },
          {
            type: "input",
            name: "information_chantier_villa",
            label: this.props.wording.city[this.props.language],
            mandatory: true,
          },
          {
            type: "select",
            name: "information_chantier_pays",
            label: this.props.wording.pays[this.props.language],
            options: country,
            defaultData: "France",
            mandatory: true,
          },
          {
            type: "input",
            name: "information_chantier_referencecctp",
            label: this.props.wording.referenceCCTP[this.props.language],
            mandatory: true,
          },
          {
            type: "date",
            name: "information_chantier_add_date",
            label: this.props.wording.dateOfEdition[this.props.language],
            mandatory: true,
          },
          {
            type: "info",
            name: "mandatory_fields",
            label: this.props.wording.mandatory[this.props.language],
            mandatory: false,
          },
        ],
      },
    ];

    return (
      <div>
        <FunctionalityHeader
          Icon={this.props.Icon}
          header={this.props.wording.infoprojectsheader[this.props.language]}
          subheader=""
          // style={{ position: "fixed" }}
        />
        <InfoForm
          data={formData}
          {...this.props}
          dossierData={this.props.dossierData}
          changeDateHandler={this.changeDateHandler}
        />
        <SaveProject
          toggleModal={this.toggleModal}
          confirmModal={this.state.confirmModal}
          onYesAction={this.onYesAction}
        />
      </div>
    );
  };
}

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    setRoute: ownProps.setRoute,
    routes: ownProps.routes,
    prevPage: state.moduleData.prevPage,
    projectData: state.projectData,
    dossierData: state.moduleData.dossierData,
    config: state.config,
  };
};

const mapDispatch = {
  setPrevPage,
  setprojectData,
  setDossierData,
};

export default connect(mapStateToProps, mapDispatch)(InfoProjects);

export function getCountry() {
  let arr: any[] = [];
  let country = require("../Resources/country.json");
  country.map((data: any) => {
    return arr.push({
      key: data.key,
      text: data.value,
      value: data.key,
    });
  });
  return arr;
}
