import { Message } from "semantic-ui-react";
import { filter } from "lodash";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Checkbox, Table } from "semantic-ui-react";
import { getPrintDate } from "../../../../Plugins/Gyproc/Utils";

interface Props {
  moduleData: any[];
  selectionId: any[];
  selectedRecords: any[];
  setSelectedRecords: any;
  translations: any;
  language: string;
  revitViewName: string;
  pluginName?: string;
}
export const SelectionComponent = (prop: Props) => {
  const [selectionPresent, isSelectionPresent] = useState(false);
  const [selectionData, setSelectionData] = useState([]);

  useEffect(() => {
    let data: any = [];
    if (prop.selectionId.length > 0) {
      _.forEach(prop.selectionId, (row: any) => {
        if (prop.pluginName === 'Gyproc'){
          isSelectionPresent(true);
          data.push([prop.moduleData[row]]);
          setSelectionData(data);
        }
        else {
          let selectedData = filteredSelection(row);
          if (selectedData.length > 0) {
            isSelectionPresent(true);
            data.push(selectedData);
            setSelectionData(data);
          }
        }
      });
    }
  }, []);

  const filteredSelection = (id: string) =>
    filter(prop.moduleData, function (selection: any) {
      return (
        selection?.Id !== null &&
        selection?.Id === id &&
        selection.RevitView === prop.revitViewName
      );
    });

  const selectedRecord = (e: any, data: any) => {
    let r = prop.selectedRecords;
    let index = prop.selectedRecords.findIndex((f: any) => f.Id === data.Id);
    if (index === -1) {
      r.push(data);
    } else {
      r.splice(index, 1);
    }

    prop.setSelectedRecords(r);
  };

  return (
    <Table className="tableborderexport fixed-table-header">
      <Table.Header className="header">
        <Table.Row>
          <Table.HeaderCell
            textAlign="center"
            className="width5"
          ></Table.HeaderCell>
          <Table.HeaderCell className="width45">
            {prop.translations[prop.language].tableHeader.selectionName}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className="width15">
            {prop.translations[prop.language].tableHeader.levels}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className="width15">
            {prop.translations[prop.language].tableHeader.type}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" className="width20">
            {prop.translations[prop.language].tableHeader.modifiedDate}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Fragment>
          {selectionData.map((row: any, index: any) => {
            return (
              selectionPresent && (
                <Table.Row>
                  <Table.Cell className="windowCursor width5">
                    <Checkbox
                      type="checkbox"
                      onChange={(e) => selectedRecord(e, row[0])}
                    />
                  </Table.Cell>
                  <Table.Cell className="windowCursor width45">
                    {row[0].Name}
                  </Table.Cell>
                  <Table.Cell
                    className="windowCursor width15"
                    textAlign="center"
                  >
                    {prop.pluginName === 'Gyproc' && row[0].Floors.map((y: any) => y.Name).join(",")}
                    {prop.pluginName !== 'Gyproc' && row[0].Levels.join(",")}
                  </Table.Cell>
                  <Table.Cell
                    className="windowCursor width15"
                    textAlign="center"
                  >
                    {row[0].Zone ? "Zone" : "Selection"}
                  </Table.Cell>
                  <Table.Cell
                    className="windowCursor width20"
                    textAlign="center"
                  >
                    {getPrintDate(row[0].Date)}
                  </Table.Cell>
                </Table.Row>
              )
            );
          })}
        </Fragment>
        {!selectionPresent && selectionPresent !== undefined && (
          <Table.Row>
            <Table.Cell colSpan={6} textAlign="center" className="windowCursor">
              {prop.translations[prop.language].message.noSelection}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
