import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Button, Segment, Card, Message } from "semantic-ui-react";
import { FunctionalityHeader } from "../../Selection/Components/FunctionalityHeader";
import PlacoCard from "./PlacoCard";
import { Routes } from "./root";

import { FilterType } from "../../../../RevitJS/Types/StoreTypes";
import {
  resetFilters,
  setFilterType,
  initProductSelector,
  search,
  scrolledBottom,
  resetBuffer,
  saveBufferSelector,
  saveOtherPlacoBufferSelector,
  multiSelectBuffer,
  tempMultiSelectBuffer,
  applyFilters,
} from "../../Selection/Actions";
import SystemDetails from "../../Selection/Components/SystemDetails";
import _, { forEach } from "lodash";
import ConfigurationView from "../../Selection/Components/ConfigurationView";
import { selectorTp } from "../../Selection/Actions/types";
import { SolutionFilterType, ProductDetailData } from "../../Selection/Types";

import { bimStorage, storageKey } from "../../../../BIMStore";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchComponent from "../../Selection/Components/SearchComponent";
import Filters from "../../../../Components/AdvanceSelector/Filter/Components";

const mapStateToProps = (
  state: SelectionStore,
  ownProps: { setRoute: any }
) => {
  const { filterType, filters } = state;
  const currentFilters = ((
    filterType: FilterType,
    filters: SolutionFilterType
  ) => {
    switch (filterType) {
      case "ceiling":
        return filters.ceilings;
      case "liningWall":
        return filters.liningWalls;
      case "protectionfeu":
        return filters.protectionFeu;
      case "gainestechniques":
        return filters.gainesTech;
      case "conduitgaines":
        return filters.conduitGaines;
      default:
        return filters.partitionWalls;
    }
  })(filterType, filters);
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    selectionBuffer: state.selectionBuffer,
    moduleData: state.moduleData,
    setRoute: ownProps.setRoute,
    displayedElements: state.displayedElements,
    currentFilters,
    filteredElements: state.filteredElements,
    filterType: state.filterType,
    selectorType: state.selectorType,
    searchedWord: state.searchedWord,
    multiSelectionBuffer: state.multiSelectionBuffer,
    tempMultiSelectionBuffer: state.tempMultiSelectionBuffer,
  };
};

const mapDispatchToProps = {
  resetFilters,
  setFilterType,
  initProductSelector: initProductSelector,
  search,
  scrolledBottom,
  resetBuffer,
  saveBufferSelector: saveBufferSelector,
  saveOtherPlacoBufferSelector,
  multiSelectBuffer,
  tempMultiSelectBuffer,
  applyFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  displayedElements: ProductDetailData[];
  wording: {
    validate: { [key: string]: string };
    back: { [key: string]: string };
  };
  language: string;
  selectionBuffer: ProductDetailData | null;
  Icon: string;
  setRoute: any;
  currentFilters: any[];
  filteredElements: ProductDetailData[];
  filterType: FilterType;
  selectorType: selectorTp;
  searchedWord: string;
  moduleData: any;
  multiSelectionBuffer: ProductDetailData[] | null;
  tempMultiSelectionBuffer: ProductDetailData[] | null;
  config: any;
};

interface State {
  LayoutPossibleValue: any;
  activeProductname: string;
  multiSelectionBuffer: ProductDetailData[];
}

export class ProductsPageBody extends Component<Props, State> {
  state = {
    LayoutPossibleValue: null,
    activeProductname: "",
    multiSelectionBuffer: [],
  };

  setLayoutPossible = (LayoutPossibleValue: any) => {
    this.setState({ LayoutPossibleValue });
    if (this.ref.current !== null) this.ref.current.scrollTop = 0;
  };

  private ref: React.RefObject<HTMLDivElement>;

  public static defaultProps = {
    wording: {
      back: {
        French: "Retour",
        English: "Back",
      },
      validate: {
        French: "Valider",
        English: "Validate",
      },
    },
  };

  constructor(props: Props) {
    super(props);

    this.ref = React.createRef();
  }

  scrollHandler = (e: any) => {
    this.props.scrolledBottom();
  };

  inputHandler = (data: any) => {
    if (data) {
      if (data && data.length > 0) {
        this.props.search(data.map((item: any) => item.value).join(";"));
        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      } else {
        this.props.search("");
        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      }
    }
  };

  onAddKeyWord = (data: any) => {
    if (data) {
      if (data && data !== "") {
        var keyword =
          this.props.searchedWord !== ""
            ? this.props.searchedWord + ";" + data
            : data;
        this.props.search(keyword);

        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      } else {
        this.props.search("");
        if (this.ref.current !== null) this.ref.current.scrollTop = 0;
      }
    }
  };

  onDeleteKeyWord = (index: any) => {
    var keywords = this.props.searchedWord.split(";");
    if (index > -1) {
      keywords.splice(index, 1);
      if (keywords && keywords.length > 0) {
        this.props.search(keywords.join(";"));
      } else {
        this.props.search("");
      }
    }
    if (this.ref.current !== null) this.ref.current.scrollTop = 0;
  };

  componentDidMount = async () => {
    const activeProductname: string = await bimStorage.getItem(
      storageKey.ACTIVE_PRODUCT
    );
    this.setState({
      activeProductname: activeProductname,
    });

    let strBuffer: any = localStorage.getItem("multiSelectionBuffer");
    let buffer = JSON.parse(strBuffer);
    if (buffer) {
      this.setState({ multiSelectionBuffer: buffer });
    } else {
      this.setState({ multiSelectionBuffer: this.getAllSelectedSystems() });
    }
  };

  handlerLayoutPossible = (el: any) => {
    try {
      return (
        el.attributes.find(
          (x: any) => x.technicalName === "GFR-Layout possible"
        ).values[0].value === "True"
      );
    } catch (error) {
      return false;
    }
  };

  preprocessSearchValue = (inputValues: any) => {
    const values: string[] = [];

    _.forEach(inputValues, (searchInputValue: any, index: any) => {
      if (searchInputValue !== "") {
        values.push(searchInputValue);
      }
    });

    return values;
  };

  setMultiSelectionBuffer = (system: ProductDetailData) => {
    if (system) {
      let multiSelectionBuffer = this.state
        .multiSelectionBuffer as ProductDetailData[];
      localStorage.removeItem("multiSelectionBuffer");
      if (!multiSelectionBuffer.some((s: any) => s.oid === system.oid)) {
        if (!system.color) {
          system.color = this.random_rgba();
        }
        multiSelectionBuffer.push(system);
      } else {
        let index = multiSelectionBuffer.findIndex(
          (s: any) => s.oid === system.oid
        );
        if (index !== -1) {
          multiSelectionBuffer.splice(index, 1);
        }
      }
      this.setState({ multiSelectionBuffer: multiSelectionBuffer });
      let buffer = JSON.stringify(multiSelectionBuffer);
      localStorage.setItem("multiSelectionBuffer", buffer);
    }
  };

  random_rgba = () => {
    var o = Math.round,
      m = Math.random,
      s = 255;
    let r = o(m() * s);
    let g = o(m() * s);
    let b = o(m() * s);
    let a = parseFloat(m().toFixed(1));
    return { r, g, b, a };
  };

  getAllSelectedSystems = () => {
    let selectedSolutionData: ProductDetailData[] = [];
    if (this.props.tempMultiSelectionBuffer) {
      forEach(
        this.props.tempMultiSelectionBuffer,
        (value: any, index: number) => {
          if (!selectedSolutionData.some((s: any) => s.oid === value.oid)) {
            selectedSolutionData.push(value);
          }
        }
      );
    } else if (this.props.multiSelectionBuffer) {
      forEach(this.props.multiSelectionBuffer, (value: any, index: number) => {
        selectedSolutionData.push(value);
      });
    }
    return selectedSolutionData;
  };

  onFilterChange = (e: any, d: any, pimAttribute: string) => {
    if (d.value !== undefined) {
      this.props.applyFilters(pimAttribute, d.value);
    } else {
      this.props.applyFilters(pimAttribute, d);
    }
  };

  render() {
    const {
      wording: { validate },
      language,
      filteredElements,
      filterType,
      selectorType,
      setFilterType,
      resetFilters,
      initProductSelector,
    } = this.props;

    const cards = () => {
      if (this.props.displayedElements.length > 0) {
        return this.props.displayedElements.map((el, index) => {
          let productName:any = el?.attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;
          return (
            <PlacoCard
              selectorType={selectorType}
              image={el.thumbnailUrl}
              key={index}
              oid={el.oid}
              productName={productName}//{el.translation}
              system={el}
              selectionBuffer={this.props.selectionBuffer}
              layoutPossible={this.handlerLayoutPossible(el)}
              setRoute={this.props.setRoute}
              setMultiSelectionBuffer={this.setMultiSelectionBuffer}
              selectedSystems={this.state.multiSelectionBuffer}
            />
          );
        });
      }

      return (
        <Message style={{ height: "50px", marginTop: "10px" }}>
          Aucun système trouvé selon les filtres sélectionnés
        </Message>
      );
    };

    const { currentFilters } = this.props;
    let filterTypeOptions = [
      { key: "partitionWall", text: "Cloisons", value: "partitionWall" },
      { key: "liningWall", text: "Doublages", value: "liningWall" },
      { key: "ceiling", text: "Plafonds", value: "ceiling" },
    ];
    switch (filterType) {
      case "partitionWall":
      case "liningWall":
        filterTypeOptions = [
          {
            key: "partitionWall",
            text: "Cloisons",
            value: "partitionWall",
          },
          {
            key: "liningWall",
            text: "Doublages",
            value: "liningWall",
          },
          {
            key: "ceiling",
            text: "Plafonds",
            value: "ceiling",
          },
        ];
        break;
      case "protectionfeu":
      case "gainestechniques":
      case "conduitgaines":
        filterTypeOptions = [
          {
            key: "protectionfeu",
            text: "Protection Feu",
            value: "protectionfeu",
          },
          {
            key: "gainestechniques",
            text: "Gaines Techniques",
            value: "gainestechniques",
          },
          {
            key: "conduitgaines",
            text: "Conduit Gaines",
            value: "conduitgaines",
          },
        ];
        break;
      case "ceiling":
        filterTypeOptions = [
          {
            key: "partitionWall",
            text: "Cloisons",
            value: "partitionWall",
          },
          {
            key: "liningWall",
            text: "Doublages",
            value: "liningWall",
          },
          {
            key: "ceiling",
            text: "Plafonds",
            value: "ceiling",
          },
          //{ key: "ceiling", text: "Plafonds", value: "ceiling" },
        ];
        break;
      default:
        filterTypeOptions = [
          {
            key: "partitionWall",
            text: "Cloisons",
            value: "partitionWall",
          },
          {
            key: "liningWall",
            text: "Doublages",
            value: "liningWall",
          },
          {
            key: "ceiling",
            text: "Plafonds",
            value: "ceiling",
          },
        ];
    }

    return (
      <div style={{ margin: "10", overflow: "auto" }}>
        <FunctionalityHeader Icon={this.props.Icon} name="SYSTÈMES PLACO®" />
        <SystemDetails />
        <ConfigurationView />
        <Segment style={{ padding: "0em 0em 1em" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "38%",
                minWidth: "250px",
                backgroundColor: "none",
                height: "76vh",
                minHeight: 50,
                marginLeft: "20px",
                overflow: "auto",
              }}
            >
              <Filters
                filtersData={currentFilters}
                isFavoriteDisplayed={false}
                onChange={this.onFilterChange}
                resetFilters={resetFilters}
              ></Filters>
              {/* <FiltersOld
                filters={currentFilters}
                LayoutPossibleValue={this.state.LayoutPossibleValue}
                setLayoutPossible={this.setLayoutPossible}
              /> */}
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100",
                  backgroundColor: "none",
                  height: "30px",
                  minHeight: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ backgroundColor: "none", display: "flex" }}>
                  <div>
                    <div className="SelectType" style={{ marginTop: "10px" }}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                          getContentAnchorEl: null,
                        }}
                        classes={{ root: "SelectType" }}
                        value={filterType}
                        onChange={(event) => {
                          setFilterType(event.target.value as FilterType);
                          resetFilters();
                          initProductSelector();
                          this.props.resetBuffer();
                          this.setLayoutPossible(null);
                        }}
                        displayEmpty
                        disableUnderline
                        inputProps={{ "aria-label": "Without label" }}
                        variant="standard"
                      >
                        {filterTypeOptions.map((filterTypeOption) => (
                          <MenuItem
                            className="SelectType"
                            key={filterTypeOption.value}
                            value={filterTypeOption.value}
                          >
                            {filterTypeOption.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <SearchComponent
                    searchKeyWords={this.preprocessSearchValue(
                      this.props.searchedWord.split(";")
                    )}
                    onAddKeyWord={this.onAddKeyWord}
                    onDeleteKeyWord={this.onDeleteKeyWord}
                  />
                  <div style={{ marginLeft: 7, paddingTop: 10 }}>
                    {filteredElements.length} systèmes trouvés
                  </div>
                </div>
              </div>
              <div
                onScroll={this.scrollHandler}
                ref={this.ref}
                style={{
                  width: "100",
                  backgroundColor: "none",
                  height: "67vh",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  overflow: "auto",
                  marginTop: 10,
                }}
              >
                <Card.Group style={{ margin: "0px 0px 5px 15px" }}>
                  {cards()}
                </Card.Group>
              </div>
            </div>
          </div>
        </Segment>

        <Segment fluid basic style={{ marginTop: -25, padding: "0px 20px" }}>
          <Button
            floated="left"
            color="orange"
            onClick={(e) => {
              localStorage.removeItem("multiSelectionBuffer");
              this.props.setRoute(Routes.PERSONALISECOLOR_TABLE);
              //resetFilters();
              //this.props.resetBuffer();
            }}
          >
            {this.props.wording.back[this.props.language]}
          </Button>
          <Button
            floated="right"
            primary
            disabled={this.state.multiSelectionBuffer.length === 0}
            onClick={(e) => {
              //if (this.props.selectionBuffer !== null) {
              if (this.state.multiSelectionBuffer.length !== 0) {
                if (
                  filterType === "partitionWall" ||
                  filterType === "liningWall" ||
                  filterType === "ceiling"
                ) {
                  //this.props.multiSelectBuffer(this.state.multiSelectionBuffer);
                  this.props.tempMultiSelectBuffer(
                    this.state.multiSelectionBuffer
                  );
                  //this.props.saveBufferSelector();
                  //handleValidate();
                  //resetFilters();
                  this.props.setRoute(Routes.PERSONALISECOLOR_TABLE);
                }
                // else {
                //   this.props.saveOtherPlacoBufferSelector();
                //   handleValidate();
                //   resetFilters();
                //   this.props.setRoute(Routes.PERSONALISECOLOR_TABLE);
                // }
              }
              resetFilters();
              localStorage.removeItem("multiSelectionBuffer");
            }}
          >
            {validate[language]}
          </Button>
        </Segment>
      </div>
    );
  }
}

export default connector(ProductsPageBody);
