/**
 * The key used for Storing data in local bim store
 * Defined uniquely over here so that used across application
 */

export enum storageKey {
  PLACO_PRODUCTS_LOG = "PLACO_PRODUCTS_LOG",
  PLACO_PRODUCTS = "PLACO_PRODUCTS",
  LANGUAGE = "LANGUAGE",
  TOKEN = "TOKEN",
  PLACOSELECTIONS = "PLACOSELECTIONS",
  GYPROCSELECTIONS = "GYPROCSELECTIONS",
  CALEPINAGE = "CALEPINAGE",
  PROJECT_INFO = "PROJECT_INFO",
  TECHNICAL_FILES = "TECHNICAL_FILES",
  REPERAGE = "REPERAGE",
  DOSSIER_TECHNIQUE = "DOSSIER_TECHNIQUE",
  COVER_PAGE_FILES = "COVER_PAGE_FILES",
  FAV_COLORS = "FAV_COLORS",
  TEMP_CCTP = "TEMP_CCTP",
  CURR_SELECTION_TYPE = "CURR_SELECTION_TYPE",
  SET_PROJECTDATA_STATUS = "SET_PROJECTDATA_STATUS",
  SET_PROJECTDATA_RESULT = "SET_PROJECTDATA_RESULT",
  ACTIVE_PRODUCT = "ACTIVE_PRODUCT",
  PROJECT_INFO_DATE = "PROJECT_INFO_DATE",
  PRESCRIBIM_FAVORITE = "PRESCRIBIM_FAVORITE",
  PRESCRIBIM_PROPERTIES = "PRESCRIBIM_PROPERTIES",
  IS_SUPERPOSER_ENABLED = "IS_SUPERPOSER_ENABLED",
  FAV_COLORS_REPERAGE = "FAV_COLORS_REPERAGE",
  REPERAGE_PERSONALISE_COLOR = "REPERAGE_PERSONALISE_COLOR",
  WEBERINDONESIA_FAVORITE = "WEBERINDONESIA_FAVORITE",
  WEBERINDONESIA_PROPERTIES = "WEBERINDONESIA_PROPERTIES",
  ALL_APPLICATION_DATA = "ALL_APPLICATION_DATA",
  APPLICATION_NAME = "APPLICATION_NAME",
  LICENSE_ERROR_MESSAGE = "LICENSE_ERROR_MESSAGE",
  PHOTOBOXFILE = "PHOTOBOXFILE",
  F4 = "F4",
  QUANTIFY = "QUANTIFY",

  PRESCRIBIM_PROPERTIES_NEW = "PRESCRIBIM_PROPERTIES_NEW",
  WEBERINDONESIA_PROPERTIES_NEW = "WEBERINDONESIA_PROPERTIES_NEW",
}

export enum ModuleList {
  SELECTION = 'Selection',
  DOSSIER = 'Dossier',
  CALEPINAGE = 'Calepinage'
}