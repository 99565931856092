import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Segment, Grid, Button } from "semantic-ui-react";
import { FormPanels } from "./Form/FormPanels";
import wordingJson from "../../Resources/wording.json";
import { bimStorage } from "../../../../../BIMStore";
import { api } from "../../../../../RevitJS/API";

interface Props {
  changeDateHandler: any;
  data: any;
  route: any;
  language: any;
  projectData: any;
  setProjectData: any;
  setRoute: any;
  infoFormData: any;
}

export const InfoForm = (props: Props) => {
  const { infoFormData } = props;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    shouldFocusError: true,
    criteriaMode: "firstError",
    defaultValues: infoFormData,
  });

  const onSubmit = async (data: any) => {
    await bimStorage.saveInfoForm(data);
    localStorage.setItem("isModification", "false");
    api.windowsHandler.closeWindow();
  };

  const onAdditionalButton = async (data: any) => {
    await bimStorage.saveInfoForm(data);
    localStorage.setItem("isModification", "false");
    props.setRoute("SUPPLIMENTARY_FORM");
  };

  return (
    <div>
      <Form
        style={{ marginTop: "1em" }}
        onChange={(event: any) => {
          if (event) localStorage.setItem("isModification", "true");
        }}
      >
        <Segment className="formContainer">
          <Grid>
            <Grid.Row
              columns={props.data.length > 2 ? props.data.length : 2}
              centered
              style={{ paddingLeft: 0 }}
            >
              <FormPanels
                {...props}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid.Row>
          </Grid>
        </Segment>
        {Object.keys(errors).length > 0 ? (
          <p
            style={{
              color: "red",
              margin: "5px 0px",
              textAlign: "center",
            }}
          >
            {wordingJson.infoformerror["English"]}
          </p>
        ) : (
          ""
        )}
      </Form>
      <div className="infoProjectBtn">
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {wordingJson.confirm["English"]}
        </Button>
        <Button
          color="orange"
          size="medium"
          style={{ marginLeft: "5%" }}
          onClick={handleSubmit(onAdditionalButton)}
          type="submit"
        >
          {wordingJson.infoprojectssubheader["English"]}
        </Button>
      </div>
    </div>
  );
};
