import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import { getEstimatedQuota } from "../../../services/projects.service.local2";
import "./warningModal.scss";

function WarningModal({ modal, setModal, stockage = false, t }: IProps) {

    const [cota, setCota] = useState(0)
    useEffect(() => {
        getEstimatedQuota().then(result => result && setCota(result))
    }, [])

    return (
        <Modal
            size={"mini"}
            id="warning-modal"
            open={modal.open}
            onClose={() =>
                setModal({
                    open: false,
                })
            }
        >
            <Modal.Header>
                <p>{t("MISSING_ELEMENTS_WARNING_HEADER").toUpperCase()}</p>
            </Modal.Header>
            <Modal.Content>
                {
                    stockage && cota > 90 ?
                        <p>{t("STOCKAGE_MAX_PART_1") + cota + t("STOCKAGE_MAX_PART_2")}</p>
                        : <p>{t("MISSING_ELEMENTS_WARNING_TEXT")}</p>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button
                    positive
                    style={{ backgroundColor: "#f67252" }}
                    onClick={() => {
                        return setModal({
                            open: false,
                        });
                    }}
                >
                    {t("OK")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

type IProps = WithTranslation & {
    modal: any;
    setModal: Function;
    stockage?: boolean;
};

export default withTranslation(["pam"])(WarningModal);
