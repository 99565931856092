import axios from "axios";
import flattenDeep from "lodash/flattenDeep";
import map from "lodash/map";
import { api } from "../../../../RevitJS/API";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";

export async function bddPlaco(url: string, body: any, cng: any) {
  let myBdd = axios.create({
    baseURL: cng.REACT_APP_CALCULATORURL_GYPROC,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });

  myBdd.interceptors.request.use(async (request) => {
    return await authFilter(request, cng);
  });

  myBdd.interceptors.response.use(
    // on success interceptor
    (res) => {
      return res;
    },
    // on error (4xx | 5xx ... statuses ) interceptor
    (res) => {
      if (res.response) {
        switch (res.response.status) {
          case 401:
          case 403:
            // if (res.response.data.error === "invalid_token") {
            if (
              cng.REACT_APP_AUTHENTICATIONURL +
              "" +
              cng.REACT_APP_LOGIN_REDIRECT
            ) {
              window.location.href =
                cng.REACT_APP_AUTHENTICATIONURL +
                "" +
                cng.REACT_APP_LOGIN_REDIRECT;
            }
            // }
            break;
          default:
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            break;
        }
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }

      return res;
    }
  );
  return await myBdd.post(url, body);
}

export const layout = async (wallTypesData: any, walls: any, config: any) => {
  return bddPlaco(
    "/",
    { wallMatching: wallTypesData, walls: walls },
    config
  ).catch((error) => {
    throw new Error(error);
  });
};

export const layoutGroup = async (
  wallTypesData: any,
  finalWalls: any,
  wallsDataByLevel: any,
  config: any
) => {
  try {
    let wallGroups: any = [];
    let url = "";
    let version = "";

    if (config.REACT_APP_CALCULATORURL_GYPROC?.includes("?")) {
      url = (config.REACT_APP_CALCULATORURL_GYPROC as string).split("?")[0];
      version =
        "?" + (config.REACT_APP_CALCULATORURL_GYPROC as string).split("?")[1];
    } else {
      url = config.REACT_APP_CALCULATORURL_GYPROC as string;
      version = "";
    }

    let deviceFingerprint = await api.license.GetMachineDetails();
    await axios
      .post(
        "",
        {
          wallMatching: wallTypesData,
          walls: finalWalls,
          allwalls: wallsDataByLevel,
        },
        {
          baseURL: url + "/group" + version,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-Fingerprint": deviceFingerprint.FingerPrint,
          },
        }
      )
      .then((response) => {
        wallGroups = response.data;
      })
      .catch((errors) => {
        return Promise.reject(errors);
      });
    return wallGroups;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const layoutInGroup = async (
  wallTypesData: any,
  finalWalls: any,
  groupWallIds: any,
  wallsDataByLevel: any,
  config: any
) => {
  try {
    let requestArray: any[] = [];
    let finalWallsArr: any[] = [];
    let deviceFingerprint = await api.license.GetMachineDetails();

    for (let wallIds of groupWallIds) {
      finalWallsArr = finalWalls.filter((a: any) => wallIds.includes(a.Id));
      requestArray.push(
        axios
          .post(
            "",
            {
              wallMatching: wallTypesData,
              walls: finalWallsArr,
              allwalls: wallsDataByLevel,
            },
            {
              baseURL: config.REACT_APP_CALCULATORURL_GYPROC,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
                "X-Fingerprint": deviceFingerprint.FingerPrint,
              },
            }
          )
          .then(function (response) {
            return response;
          })
          .catch(function (error: any) {
            return Promise.reject(error);
            // throw error;
          })
      );
    }

    return axios
      .all(requestArray)
      .then(
        axios.spread((...responses) => {
          return flattenDeep(map(responses, "data"));
          // use/access the results
        })
      )
      .catch((errors) => {
        throw errors;
        // react on errors.
      });
  } catch (error: any) {
    return Promise.reject(error);
  }
};
