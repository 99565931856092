import React, { useState } from "react";
import { List, Image, Header, Transition, Icon } from "semantic-ui-react";
import { ID } from "../../RevitJS/Types/RevitTypes";
import { Plugin } from "../../RevitJS/Types/StoreTypes";

interface FunctionalityLauncherProps {
  language: string;
  Trigger: Function;
  ShortDesc: { [key: string]: string };
  Name: { [key: string]: string };
  Icon: string;
  ParentId: ID;
  plugin: Plugin | undefined;
  Id: ID;
  childList: any;
  currentPluginId: ID;
  setPluginId: Function;
  config: any;
}

export const FunctionalityLauncher = (props: FunctionalityLauncherProps) => {
  const [visible, setVisible] = useState(false);
  const shortDesc =
    props.ShortDesc[props.language] || props.ShortDesc["English"];
  const name = props.Name[props.language] || props.Name["English"];
  const [isActive, setActive] = useState(false);

  let items = document.getElementsByClassName("item");
  for (let i = 0; i < items.length; i++) {
    items[i].addEventListener("click", function () {
      let getcname = document.getElementsByClassName("active");
      if (getcname.length > 0) {
        getcname[0].className = getcname[0].className.replace(" active", "");
      }
    });
  }

  return (
    <>
      <List.Item
        onClick={() => {
          setActive(!isActive);
        }}
        className={isActive ? "active" : ""}
      >
        <Image
          floated="left"
          style={{ width: 45, height: 45, zIndex: -1 }}
          src={props.Icon}
        />

        {props.Id === "9922aa18-a188-49d7-af00-faf69e903659" ||
        props.Id === "98fe05e3-266c-4671-8821-c9887747f678" ? (
          <Icon
            name={visible ? "angle up" : "angle down"}
            style={{ float: "right" }}
            size="large"
          />
        ) : null}

        <List.Content
          onClick={() => {
            props.Id === "9922aa18-a188-49d7-af00-faf69e903659" ||
            props.Id === "98fe05e3-266c-4671-8821-c9887747f678"
              ? setVisible(!visible)
              : props.Trigger(props.config);
            props.setPluginId(props.Id);
          }}
          className="hover-list-content "
        >
          <div
            className={
              props.plugin?.Id === "6313b2e8-6e86-4dfd-bf3e-8c27c6212024" ||
              props.plugin?.Id === "9a5ac6ee-883f-4e64-92e1-e0fb170abcfa" ||
              props.plugin?.Id === "81b41a9f-2200-402b-ac72-4570738453e2"
                ? "list-item-black"
                : ""
            }
          >
            <List.Header>
              <Header as="h3">{name}</Header>
            </List.Header>
            <List.Description>{shortDesc}</List.Description>
          </div>
        </List.Content>
      </List.Item>
      {visible === true && (
        <Transition.Group
          as={List}
          duration={500}
          visible={visible}
          animation="slide down"
        >
          {props.childList.map(
            (
              obj: {
                Icon: any;
                Name: { [x: string]: React.ReactNode };
                ShortDesc: { [x: string]: React.ReactNode };
              },
              idx: number
            ) => (
              <List.Item key={idx} style={{ height: "auto" }}>
                <Image
                  floated="left"
                  style={{ width: 45, height: 45, zIndex: -1 }}
                  src={obj.Icon}
                />
                <List.Content
                  className="hover-list-content"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setTimeout(() => {
                      props.childList[idx].Trigger(props.config);
                    })
                  }
                >
                  <List.Header>
                    <Header as="h3">
                      {obj.Name[props.language] || obj.Name["English"]}
                    </Header>
                  </List.Header>
                  <List.Description>
                    {obj.ShortDesc[props.language] || obj.ShortDesc["English"]}
                  </List.Description>
                </List.Content>
              </List.Item>
            )
          )}
        </Transition.Group>
      )}
    </>
  );
};
FunctionalityLauncher.defaultProps = {
  Name: "Functionality Name",
  Icon: "/saint-gobain-bim.png",
  ShortDesc: "Functionality Description",
};
