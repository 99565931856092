import { AnyAction } from "redux";
import { showSystemDetailsAction, hideSystemDetailsAction } from "../Actions/types";

type systemDetailsVisibleAction = showSystemDetailsAction | hideSystemDetailsAction

export const systemDetailsVisible = (systemDetailsVisible: boolean = false, action: systemDetailsVisibleAction): boolean =>{
    switch(action.type){
        case "SHOW_SYSTEM_DETAILS":
            return true;
        case "HIDE_SYSTEM_DETAILS":
            return false;
        default:
            return systemDetailsVisible;
    }
}