import React from "react";
import { Menu, Checkbox } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import { SelectionStore } from "../Reducers";
import { selectLevel } from "../Actions";
import { selectorTp } from "../Actions/types";

const mapState = (
  state: SelectionStore,
  ownProps: { checked: boolean; name: string }
) => ({
  checked: ownProps.checked,
  name: ownProps.name,
  selectorType: state.selectorType,
});

const mapDispatch = {
  selectLevel: selectLevel,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  checked: boolean;
  name: string;
  selectorType: selectorTp;
};

export class LevelItem extends React.Component<Props> {
  render() {
    const { checked, name } = this.props;
    return (
      <Menu.Item
        name="inbox"
        style={{ padding: "8px 15px" }}
        className="GroupSelectorMenu"
      >
        <Checkbox
          onChange={(e, data) => {
            this.props.selectLevel(data.label as string);
          }}
          checked={checked}
          style={{ maxWidth: "80%" }}
          label={`${name}`}
        />
      </Menu.Item>
    );
  }
}

export default connector(LevelItem);
