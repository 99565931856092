import { ID } from "../../RevitJS/Types/RevitTypes"

export const selectConfig = (cng: any) =>{
    return{
        type: "SET_CONFIG",
        payload: cng
    }
}

export const selectPlugin = (pluginId: ID) =>{
    localStorage.setItem("selectedPluginId", pluginId);
    return{
        type: "SELECT_PLUGIN",
        id: pluginId
    }
}

export const resetSelectedPlugin = () =>{
    localStorage.setItem("selectedPluginId", "false");
    return{
        type: "RESET_SELECTED_PLUGIN",
    }
}

export const endManualSelection = () =>{
    localStorage.setItem('elementsByLevelAndType', "false");
    return{
        type: "END_SELECTION"
    }
}

export const cancelManualSelection = () =>{
    localStorage.setItem('cancelManualSelection', "true");
    localStorage.setItem('elementsByLevelAndType', "false");
    return{
        type: "CANCEL_SELECTION"
    }
}



// const popUpWindow = function(popUpWindow = false, action: any){
//     switch (action.type) {
//         case "SHOW_WINDOW":
//             return true;
//         case "HIDE_WINDOW":
//             return false;
//         default:
//             return popUpWindow;
//     }
// }

// const manualSelection = function(manualSelection = false, action: any){
//     switch (action.type) {
//         case "START_SELECTION":
//             return true;
//         case "END_SELECTION":
//             return false;
//         default:
//             return manualSelection;
//     }
// }