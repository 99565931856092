export const placoFilters={
    partitions:[
        {
            name: "Hauteur limite (m)",
            pimAttribute: "GFR-Height limit in m",
            type: "Numeric",
            criterion: "GreaterThan",
            choiceType: "RadioBox",
            options: null,
            value: null
        },
        {
            name: "Protection Incendie",
            pimAttribute: "GFR-Fire protection",
            type: "Text",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null
        },
        
        {
            name: "Affaiblissement acoustique (DB)",
            pimAttribute: "GFR-Sound reduction",
            type: "Numeric",
            criterion: "GreaterThan",
            choiceType: "RadioBox",
            options: null,
            value: null
        },
        {
            name: "Type de cloison",
            pimAttribute: "GFR-Partitionwall type",
            type: "Text",
            criterion: "Equals",
            choiceType: "RadioBox",
            options: null,
            value: null
        },
        {
            name: "Epaisseur totale (mm)",
            pimAttribute: "GFR-Total thickness in mm",
            type: "Numeric",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null
        },
    ],
    liningWalls:[
        {
            name: "Hauteur limite (m)",
            pimAttribute: "GFR-Height limit in m",
            type: "Numeric",
            criterion: "GreaterThan",
            choiceType: "RadioBox",
            options: null,
            value: null
        },
        {
            name: "Epaisseur totale (mm)",
            pimAttribute: "GFR-Total thickness in mm",
            type: "Numeric",
            criterion: "Includes",
            choiceType: "CheckBox",
            options: null,
            value: null
        },
    ]
}