import React, { Component } from "react";
import { Button, Modal, Form, Message } from "semantic-ui-react";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { setDossierData } from "../Actions";
import {
  fetchChkList,
  getCurrentDate,
  checkIfDossierTechniquesExist,
  getProjectId,
} from "../../../PlacoBIMv3/DocumentTechniques/Helpers";

import { v4 } from "uuid";
import { InitDossierData } from "../../Selection/Actions/types";
import { initProjectData } from "../../../../RevitJS/Types/StoreTypes";

interface Props {
  closeDimmer: any;
  documentNameDimmer: any;
  toggleDocumentDimmer: any;
  wording: {
    documentTechniqueName: { [key: string]: string };
    cancel: { [key: string]: string };
    validate: { [key: string]: string };
  };
  language: string;
  disabled: boolean;
  setDossierData: any;
  tabName: string;
  reroute: any;
  dossierData: any;
  currentDocuments: any;
  projectData: initProjectData;
  documentDuplicateError: boolean;
  config: any;
}

export class FormModal extends Component<Props> {
  state = {
    newDocumentName: null,
    disabled: true,
    errorMesg: false,
    currentRevitDocumentName: "",
    documentDuplicateError: false,
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  validHandler = async () => {
    const documentName = this.props.projectData.ProjectId;

    const isExist = await checkIfDossierTechniquesExist(
      this.state.newDocumentName ?? "",
      documentName,
      v4(),
      this.props.tabName,
      this.props.config
    );

    if (!isExist) {
      this.setState({
        errorMesg: false,
      });
      let data: InitDossierData = {} as InitDossierData;

      data = {
        duplicated: 0,
        modelName: documentName,
        documentId: v4(),
        name: this.state.newDocumentName,
        documentType: this.props.tabName,
        documentDate: getCurrentDate(),
        selections: { status: false, list: [] },
        time: Date.now(),
        bufferTime: 0,
        data: { status: false, list: [] },
        downloadDAE: fetchChkList("downloadDAE"),
        downloadObj: fetchChkList("downloadObj"),
        duplicatedActiveRow: null,
      };
      this.props.setDossierData(data);
      this.props.reroute();
    } else {
      this.setState({
        errorMesg: true,
      });
    }
  };

  handleInput = (e: { target: { value: string } }) => {
    this.setState({
      errorMesg: false,
    });
    // trim out the input value and make it lowercase for case sensitive comparison
    let currentValue = e.target.value.trim().toLowerCase();

    // check if the lenght of the input is equal or over 5 characters
    if (currentValue.length >= 5) {
      // check if the same name value is exist in same document type withing same revit modal
      if (
        this.props.currentDocuments.some(
          (a: any) =>
            a.modelName === this.state.currentRevitDocumentName &&
            a.name.toLowerCase() === currentValue &&
            a.documentType === this.props.tabName
        )
      ) {
        // if exists disable the button and show error message
        this.setState({ disabled: true });
        this.setState({
          errorMesg: true,
        });
      } else {
        // if name is unique set the new document aand enable the button and set the error state to false
        this.setState({ newDocumentName: e.target.value });
        this.setState({ disabled: false });
        this.setState({
          errorMesg: false,
        });
      }
    } else {
      // if characters are less than 5 characters
      this.setState({ disabled: true });
      this.setState({
        errorMesg: false,
      });
    }
  };

  async componentDidMount() {
    const documentName = this.props.projectData.ProjectId;
    this.setState({
      currentRevitDocumentName: documentName,
    });
  }

  render() {
    return (
      <Modal
        open={this.props.documentNameDimmer}
        size="mini"
        dimmer="blurring"
        inverted="true"
      >
        <Modal.Header
          className="modalHeader"
          style={{ padding: 8 }}
          content={
            this.props.wording.documentTechniqueName[this.props.language]
          }
        ></Modal.Header>
        <Modal.Actions className="modalActions">
          <Form>
            <Form.Input
              autoFocus
              autoComplete="off"
              placeholder="Texte de 5 caractères minimum"
              fluid
              name="newDocumentName"
              //onChange={(e) => this.handleInput(e)}
              onChange={(e, data) => {
                let value = data.value.trim().toLowerCase();
                this.setState({ newDocumentName: data.value });

                if (value.length < 5) {
                  this.setState({
                    disabled: true,
                    errorMesg: false,
                  });
                } else {
                  this.setState({
                    disabled: false,
                    errorMesg: false,
                  });
                }
              }}
            />
          </Form>
          {this.state.errorMesg && (
            <Message
              style={{ textAlign: "left" }}
              error
              content="Une autre sélection existe déjà sous ce nom"
            />
          )}
          <div className="modalButton">
            <Button
              color="orange"
              onClick={() => {
                this.props.closeDimmer();
                this.setState({
                  errorMesg: false,
                });
              }}
            >
              {this.props.wording.cancel[this.props.language]}
            </Button>
            <Button
              disabled={this.state.disabled}
              type="submit"
              primary
              onClick={this.validHandler}
            >
              {this.props.wording.validate[this.props.language]}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatch = {
  setDossierData,
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    setRoute: ownProps.setRoute,
    routes: ownProps.route,
    wording: ownProps.wording,
    language: state.language,
    dossierData: state.moduleData.dossierData,
    projectData: state.projectData,
    config: state.config,
  };
};

export default connect(mapStateToProps, mapDispatch)(FormModal);
