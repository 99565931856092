import React, { Component } from "react";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import { Table, Checkbox, Select, Dropdown } from "semantic-ui-react";
import { MappingRowRigipsCeilings } from "../../../../RevitJS/Types/StoreTypes";
import { ProductMeta } from "../../../../RevitJS/Types/BddTypes";
import {
  selectSystemCeilings,
  toggleCheckCeilings,
  setAdditionalWeightCeilings,
  setFireConstrainCeilings,
  initProductSelector,
  setActiveMappingRowIndex,
} from "../Actions/index";
import { WithTranslation, withTranslation } from "react-i18next";
import { api } from "../../../../RevitJS/API";

interface Props {
  mappingRow: MappingRowRigipsCeilings;
  placoData: any;
  rigipsDataCeilings: ProductMeta[];
  selectSystemCeilings: any;
  setFireConstrainCeilings: any;
  setAdditionalWeightCeilings: any;
  setRoute: any;
  initProductSelector: any;
  setActiveMappingRowIndex: any;
  toggleCheckCeilings: Function;
}
interface State {}

export class MappingRowRigipsCeilingsComponent extends Component<
  Props & WithTranslation,
  State
> {
  state = {};

  render() {
    const {
      Index,
      Checked,
      RevitSelection,
      FireConstrain,
      AdditionalWeight,
      MappedSystem,
    } = this.props.mappingRow;
    const { rigipsDataCeilings, t } = this.props;

    return (
      <Table.Row key={Index}>
        <Table.Cell>
          <Checkbox
            onChange={() => {
              this.props.toggleCheckCeilings(Index);
              //   this.props.tooglerHandler();
            }}
            checked={Checked}
          />
        </Table.Cell>

        <Table.Cell>{`${RevitSelection.RevitType} (${RevitSelection.Ids.length})`}</Table.Cell>
        <Table.Cell style={{ maxWidth: 50 }}>
          <Dropdown
            style={{ width: 80 }}
            // style={{ minWidth: 300, maxWidth: 350 }}
            // placeholder={this.props.wording.select[this.props.language]}
            options={[
              {
                text: t("yes"),
                value: true,
              },
              {
                text: t("no"),
                value: false,
              },
            ]}
            onChange={(e, d) => {
              this.props.setFireConstrainCeilings(Index, d.value);
            }}
            defaultValue={FireConstrain || false}
          />
        </Table.Cell>
        <Table.Cell style={{ maxWidth: 50 }}>
          <Dropdown
            style={{ width: 80 }}
            // placeholder={this.props.wording.select[this.props.language]}
            options={[
              {
                text: t("yes"),
                value: true,
              },
              {
                text: t("no"),
                value: false,
              },
            ]}
            onChange={(e, d) => {
              this.props.setAdditionalWeightCeilings(Index, d.value);
            }}
            defaultValue={AdditionalWeight || false}
          />
        </Table.Cell>
        <Table.Cell style={{ maxWidth: 350 }}>
          <Select
            style={{ minWidth: 300, maxWidth: 350 }}
            search
            placeholder={t("select") || "select"}
            options={rigipsDataCeilings.map((sys: ProductMeta) => {
              return { text: sys.translation, value: sys.oid };
            })}
            onChange={(e, d) => {
              this.props.selectSystemCeilings(
                Index,
                rigipsDataCeilings.find((el) => el.oid === d.value)
              );
            }}
            defaultValue={MappedSystem?.oid}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = (
  state: DrawStore,
  ownProps: { mappingRow: MappingRowRigipsCeilings; setRoute: any }
) => ({
  mappingRow: ownProps.mappingRow,
  placoData: state.placoData,
  setRoute: ownProps.setRoute,
  rigipsDataCeilings: state.rigipsMetaDataCeilings,
});

const mapDispatchToProps = {
  selectSystemCeilings,
  toggleCheckCeilings,
  setAdditionalWeightCeilings,
  setFireConstrainCeilings,
  initProductSelector,
  setActiveMappingRowIndex,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("riggibs")(MappingRowRigipsCeilingsComponent));
