import { Button } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./footer.scss";

function Footer({
    disabledNextButton = true,
    savedProjectsButtonShow = true,
    savedProjectsButtonDisabled = false,
    savedProjectButtonClicked = () => {
        console.log("clicked home button");
    },
    showNextButton = true,
    buttonLabel = "NEXT",
    clickedNextButton = () => {
        console.log("clicked next button");
    },
    showBackButton = false,
    clickedBackButton = () => {
        console.log("clicked back button");
    },
    t,
}: Props) {
    return (
        <>
            <div id="pam-canalisation-footer-container">
                <div id="footer-top-border-div"></div>
                <div id="footer-container-div" className="row px-3">
                    <div className="col-3 d-flex">
                        {savedProjectsButtonShow && (
                            <Button
                                id="header-saved-projects-button"
                                className="ml-2"
                                variant="contained"
                                disabled={savedProjectsButtonDisabled}
                                onClick={(event) => {
                                    savedProjectButtonClicked();
                                }}
                            >
                                {t("SAVED_PROJECTS")}
                            </Button>
                        )}
                    </div>

                    <div className="offset-3 col-6 d-flex justify-content-end">
                        {showBackButton && (
                            <Button
                                id="footer-back-button"
                                className="mx-2"
                                variant="contained"
                                onClick={() => {
                                    clickedBackButton();
                                }}
                            >
                                {t("BACK")}
                            </Button>
                        )}
                        {showNextButton && (
                            <Button
                                id="footer-next-button"
                                className="ml-2"
                                variant="contained"
                                disabled={disabledNextButton}
                                onClick={() => {
                                    clickedNextButton();
                                }}
                            >
                                {t(buttonLabel)}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

type Props = WithTranslation & {
    buttonLabel?: string;
    disabledNextButton?: boolean;
    showNextButton?: boolean;
    clickedNextButton?: Function;
    showBackButton?: boolean;
    clickedBackButton?: Function;
    savedProjectsButtonShow?: boolean;
    savedProjectsButtonDisabled?: boolean;
    savedProjectButtonClicked?: Function;
};

export default withTranslation(["pam"])(Footer);
