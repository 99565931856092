import { A01LayersParser } from "./a1LayersParser";

export const A3PLayersParser = (layersDesc: string) => {
  switch (layersDesc) {
    case "3-AP.0.1v-01-WB-AP 45":
      return [
        {
          name: "AP 45 mm",
          thickness: 45,
        },
      ];
    case "3-AP.0.1v-02-WB-AP 55":
      return [
        {
          name: "AP 55 mm",
          thickness: 55,
        },
      ];
    case "3-AP.0.1v-03-WB-AP 65":
      return [
        {
          name: "AP 65 mm",
          thickness: 65,
        },
      ];
    case "3-AP.0.1v-04-WB-AP 75":
      return [
        {
          name: "AP 75 mm",
          thickness: 75,
        },
      ];
    case "3-AP.0.1v-05-WB-AP 60":
      return [
        {
          name: "AP 60 mm",
          thickness: 60,
        },
      ];
    case "3-AP.0.1v-06-WB-AP 70":
      return [
        {
          name: "AP 70 mm",
          thickness: 70,
        },
      ];
    case "3-AP.0.1v-07-WB-AP 80":
      return [
        {
          name: "AP 80 mm",
          thickness: 80,
        },
      ];
    case "3-AP.0.1v-08-WB-AP 90":
      return [
        {
          name: "AP 90 mm",
          thickness: 90,
        },
      ];

    default:
      throw Error("Not existing Alba");
  }
};

export const A3TLayersParser = (layersDesc: string) => {
  switch (layersDesc) {
    case "3-AT.0.1v-01-WB-AT-EPS 45":
      return [
        {
          name: "AT-EPS 45 mm",
          thickness: 45,
        },
      ];
    case "3-AT.0.1v-02-WB-AT-EPS 55":
      return [
        {
          name: "AT-EPS 55 mm",
          thickness: 55,
        },
      ];
    case "3-AT.0.1v-03-WB-AT-EPS 65":
      return [
        {
          name: "AT-EPS 65 mm",
          thickness: 65,
        },
      ];
    case "3-AT.0.1v-04-WB-AT-EPS 75":
      return [
        {
          name: "AT-EPS 75 mm",
          thickness: 75,
        },
      ];
    case "3-AT.0.1v-05-WB-AT-EPS 85":
      return [
        {
          name: "AT-EPS 85 mm",
          thickness: 85,
        },
      ];
    case "3-AT.0.1v-06-WB-AT-EPS 105":
      return [
        {
          name: "AT-EPS 105 mm",
          thickness: 105,
        },
      ];
    case "3-AT.0.1v-07-WB-AT-EPS 125":
      return [
        {
          name: "AT-EPS 125 mm",
          thickness: 125,
        },
      ];
    case "3-AT.0.1v-08-WB-AT-EPS 145":
      return [
        {
          name: "AT-EPS 145 mm",
          thickness: 145,
        },
      ];
    case "3-AT.0.1v-09-WB-AT-EPS 165":
      return [
        {
          name: "AT-EPS 165 mm",
          thickness: 165,
        },
      ];
    case "3-AT.0.1v-10-WB-AT-EPS 185":
      return [
        {
          name: "AT-EPS 185 mm",
          thickness: 185,
        },
      ];
    case "3-AT.0.1v-20-WB-AT-EPS 60":
      return [
        {
          name: "AT-EPS 60 mm",
          thickness: 60,
        },
      ];
    case "3-AT.0.1v-21-WB-AT-EPS 70":
      return [
        {
          name: "AT-EPS 70 mm",
          thickness: 70,
        },
      ];
    case "3-AT.0.1v-22-WB-AT-EPS 80":
      return [
        {
          name: "AT-EPS 80 mm",
          thickness: 80,
        },
      ];
    case "3-AT.0.1v-23-WB-AT-EPS 90":
      return [
        {
          name: "AT-EPS 90 mm",
          thickness: 90,
        },
      ];

    case "3-AT.0.1v-24-WB-AT-EPS 100":
      return [
        {
          name: "AT-EPS 100 mm",
          thickness: 100,
        },
      ];

    case "3-AT.0.1v-25-WB-AT-EPS 120":
      return [
        {
          name: "AT-EPS 120 mm",
          thickness: 120,
        },
      ];

    case "3-AT.0.1v-26-WB-AT-EPS 140":
      return [
        {
          name: "AT-EPS 140 mm",
          thickness: 140,
        },
      ];
    case "3-AT.0.1v-27-WB-AT-EPS 160":
      return [
        {
          name: "AT-EPS 160 mm",
          thickness: 160,
        },
      ];
    case "3-AT.0.1v-28-WB-AT-EPS 180":
      return [
        {
          name: "AT-EPS 180 mm",
          thickness: 180,
        },
      ];
    case "3-AT.0.1v-29-WB-AT-EPS 200":
      return [
        {
          name: "AT-EPS 200 mm",
          thickness: 200,
        },
      ];
    case "3-AT.0.1v-41-WB-AT-XPS 55":
      return [
        {
          name: "AT-XPS 55 mm",
          thickness: 55,
        },
      ];
    case "3-AT.0.1v-42-WB-AT-XPS 65":
      return [
        {
          name: "AT-XPS 65 mm",
          thickness: 65,
        },
      ];
    case "3-AT.0.1v-43-WB-AT-XPS 75":
      return [
        {
          name: "AT-XPS 75 mm",
          thickness: 75,
        },
      ];
    case "3-AT.0.1v-44-WB-AT-XPS 85":
      return [
        {
          name: "AT-XPS 85 mm",
          thickness: 85,
        },
      ];
    case "3-AT.0.1v-45-WB-AT-XPS 105":
      return [
        {
          name: "AT-XPS 105 mm",
          thickness: 105,
        },
      ];
    case "3-AT.0.1v-46-WB-AT-XPS 125":
      return [
        {
          name: "AT-XPS 125 mm",
          thickness: 125,
        },
      ];
    case "3-AT.0.1v-47-WB-AT-XPS 145":
      return [
        {
          name: "AT-XPS 145 mm",
          thickness: 145,
        },
      ];
    case "3-AT.0.1v-48-WB-AT-XPS 165":
      return [
        {
          name: "AT-XPS 165 mm",
          thickness: 165,
        },
      ];
    case "3-AT.0.1v-49-WB-AT-XPS 185":
      return [
        {
          name: "AT-XPS 185 mm",
          thickness: 185,
        },
      ];
    case "3-AT.0.1v-60-WB-AT-XPS 60":
      return [
        {
          name: "AT-XPS 60 mm",
          thickness: 60,
        },
      ];
    case "3-AT.0.1v-61-WB-AT-XPS 70":
      return [
        {
          name: "AT-XPS 70 mm",
          thickness: 70,
        },
      ];
    case "3-AT.0.1v-62-WB-AT-XPS 80":
      return [
        {
          name: "AT-XPS 80 mm",
          thickness: 80,
        },
      ];
    case "3-AT.0.1v-63-WB-AT-XPS 90":
      return [
        {
          name: "AT-XPS 90 mm",
          thickness: 90,
        },
      ];

    case "3-AT.0.1v-64-WB-AT-XPS 100":
      return [
        {
          name: "AT-XPS 100 mm",
          thickness: 100,
        },
      ];

    case "3-AT.0.1v-65-WB-AT-XPS 120":
      return [
        {
          name: "AT-XPS 120 mm",
          thickness: 120,
        },
      ];

    case "3-AT.0.1v-66-WB-AT-XPS 140":
      return [
        {
          name: "AT-XPS 140 mm",
          thickness: 140,
        },
      ];
    case "3-AT.0.1v-67-WB-AT-XPS 160":
      return [
        {
          name: "AT-XPS 160 mm",
          thickness: 160,
        },
      ];
    case "3-AT.0.1v-68-WB-AT-XPS 180":
      return [
        {
          name: "AT-XPS 180 mm",
          thickness: 180,
        },
      ];
    case "3-AT.0.1v-69-WB-AT-XPS 200":
      return [
        {
          name: "AT-XPS 200 mm",
          thickness: 200,
        },
      ];
    default:
      throw Error("Not existing Alba");
  }
};

export const A02LayersParser = (layersDesc: string) => {
  let layers = layersDesc.split("/").map((word) => word.trim());
  return [
    A01LayersParser(layers[0])?.[0],
    A01LayersParser(layers[1])?.[0],
    A01LayersParser(layers[2])?.[0],
  ];
};

export const A511LayersParser = (layersDesc: string) => {
  let layers = layersDesc.split("=").map((word) => word.trim());
  return [
    A01LayersParser(layers[0])?.[0],
    null,
    A01LayersParser(layers[1])?.[0],
  ];
};
