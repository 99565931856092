import React, { Component } from "react";
import { Dimmer, Loader, Button } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { excelExport } from "../index";
import { wait } from "../../../../RevitJS/Helpers";
import { withTranslation } from "react-i18next";

class Waiter extends Component {
  
    state={
        wait: true
    }
        
    componentDidMount = async() =>{
        // api.windowsHandler.showDevTools();
        await wait(1000);
        this.setState({
            wait: false
        })
    }
    render = () => {
        const { t } = this.props
        const dimmer = () =>(
            <Dimmer active={true} style={{height: "calc(100vh - 30px)"}}>
                    <Loader content={t("quantitiesInPreparation")}>
                        
                    </Loader>
                </Dimmer>
        )
        if(this.state.wait) return (dimmer());
        return(
            <div style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                <div>
                <Button style={{marginTop: 50}} primary onClick={() =>{
                    excelExport();
                    // window.addEventListener("blur", () =>{
                    //     api.windowsHandler.closeWindow();
                    // })
                }}>{t("saveMaterialList")}</Button>
                </div>
            </div>
  
        )
            
    }
}

export default withTranslation("riggibs")(Waiter) ;
