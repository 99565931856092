import React, { useState } from "react"
import { Property } from '../../../Properties/store/properties/types'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropertyRow from '../PropertyRow'


interface AccordionContainer {
  title: string
  properties?: Property[]
  onClick?: any,
  defaultExpand? : boolean
}

export const AccordionContainer: React.FC<AccordionContainer> = ({ title, properties, onClick, defaultExpand = false }) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpand)

  const handleChange = () => {
    setExpanded(expanded ? false : true)
    onClick && onClick()
  }

  return (
    <Accordion square expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={properties && <ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {properties && properties.map(prop =>
          <PropertyRow key={prop.title} title={prop.title} value={prop.value} onClick={prop.onClick}></PropertyRow>)
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionContainer

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    width: '100%',
    minHeight: '40px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'initial',
      minHeight: '40px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    height: 40,
    '&$expanded': {
      minHeight: 40,
      height: 40,
    },
    fontWeight: 600,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: 0
  },
}))(MuiAccordionDetails);


