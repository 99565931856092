import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Input, Image } from "semantic-ui-react";
import { generalizeLanguage, MyConfig } from "../../../../Helper";
import { StoreState } from "../../../../Reducers";
import { api } from "../../../../RevitJS/API";
import { Language } from "../../../../RevitJS/Types/RevitTypes";
import { SelectionStore } from "../../../PlacoBIMv3/Selection/Reducers";

// import { Language } from "../../../../RevitJS/Types/RevitTypes";

type Langs = "French" | "English";

export const UpdateToolBoxWindow = () => {
  //let language: any = localStorage.getItem("savedLanguage");
  const [lang, setLang] = useState<Langs>("English");

  const [config, setConfig] = useState({
    REACT_APP_ENV: "",
    REACT_APP_FILESERVER: "",
    REACT_APP_SERVERURL: "",
  });

  useEffect(() => {
    let language: any = localStorage.getItem("savedLanguage");
    if (!language || language.includes("USA")) {
      language = "English";
    }

    const changeLanguage = async () => {
      if (language !== lang) {
        setLang(language);
      }
      //   else if (language === "French") {
      //     setLang("French");
      //   }
      //  else {
      //     setLang("English");
      //   }
    };
    changeLanguage();

    async function getConfig() {
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, []);

  const onBackClick = () => {
    api.windowsHandler.closeWindow();
  };

  const onContinueClick = () => {
    //api.framework.runInstaller();
    //api.framework.runInstaller(config.REACT_APP_ENV as string, null);

    if (!window.revit.isArchicad) {
      api.framework.runInstaller(
        config.REACT_APP_ENV as string,
        null //`${config.REACT_APP_FILESERVER}/static/directdownload/bimtoolbox.zip`
      );
    } else {
      if (!window.revit.isMac())
        api.framework.runInstaller(
          config.REACT_APP_ENV as string,
          `${config.REACT_APP_FILESERVER}/static/directdownload/archicad.msi`
        );
      else
        api.framework.runInstaller(
          config.REACT_APP_ENV as string,
          `${config.REACT_APP_FILESERVER}/static/directdownload/archicad.pkg`
        );
    }

    api.windowsHandler.closeWindow();
    //api.framework.CloseApplication();
  };

  // const applicationLanguage: Language = reduxState.language;
  return (
    <div className="updateTool">
      <div
        style={{
          borderStyle: "solid",
          margin: "0vh 4vh 0vh 4vh",
          borderRadius: "5vh",
          borderColor: "#285080",
        }}
      >
        <div style={{ width: "fit-content" }}>
          <Image
            src={config.REACT_APP_SERVERURL + "/saint-gobain-logo-new.png"}
            style={{ marginTop: "2vh" }}
            size="tiny"
            wrapped
            centered
          />
        </div>
        <h3
          style={{
            textDecorationLine: "underline",
            textAlign: "center",
            marginTop: "1vh",
            color: "#285080",
          }}
        >
          BIM TOOLBOX
        </h3>
        <p
          style={{
            textAlign: "left",
            fontSize: "15px",
            fontStyle: "italic",
            fontWeight: "bold",
            wordSpacing: "3px",
            color: "#285080",
            margin: "0vh 5vh 0vh 5vh",
          }}
        >
          {wording.updateToolBox[lang]}
        </p>
        <br />
        <div style={{ position: "relative", width: "100%" }}>
          <div style={{ margin: "0vh 50% 0vh 0vh", width: "fit-content" }}>
            <Button onClick={onBackClick}> {wording.back[lang]}</Button>
          </div>
          <div style={{ margin: "-6% 0vh 0vh 50%", width: "fit-content" }}>
            <Button
              style={{ backgroundColor: "#285080", color: "white" }}
              onClick={onContinueClick}
            >
              {wording.validate[lang]}
            </Button>
          </div>
        </div>{" "}
        <br />
        {/* <Button
              color="#285080"
              //content={wording.back[language]}
            ></Button> */}
      </div>
    </div>
  );
  // };
};

const wording: WordingType = {
  updateToolBox: {
    French:
      "Veuillez enregistrer vos projets ouverts avant de mettre à jour le plugin, Revit va redémarrer.",
    English:
      "Please save your open projects before updating the plugin, Revit will reboot.",
    German:
      "Please save your open projects before updating the plugin, Revit will reboot.",
    Italian:
      "Please save your open projects before updating the plugin, Revit will reboot.",
    Indonesian:
      "Please save your open projects before updating the plugin, Revit will reboot.",
  },
  back: {
    French: "Annuler la mis à jour",
    English: "Cancel update",
    German: "Cancel update",
    Italian: "Cancel update",
    Indonesian: "Cancel update",
  },
  validate: {
    French: "Fermer Revit et mettre à jour",
    English: "Close Revit and update",
    German: "Close Revit and update",
    Italian: "Close Revit and update",
    Indonesian: "Close Revit and update",
  },
};

type WordingType = {
  updateToolBox: LanguageType;
  back: LanguageType;
  validate: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
  German: string;
  Italian: string;
  Indonesian: string;
};

export default UpdateToolBoxWindow;
