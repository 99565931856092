export const promptCheckBoxData = [
  {
    name: "downloadObj",
    header: "choiceFormat",
    chklist: [
      { key: "RVT", value: "RVT" },
      { key: "IFC", value: "IFC" },
    ],
  },
  {
    name: "downloadDAE",
    header: "choiceCertificate",
    chklist: [
      { key: "ACERMI", value: "ACERMI" },
      { key: "PV_INCENDIE", value: "Résistance au feu" },
      { key: "PV_ACOUSTIQUE", value: "Rapport d’essai acoustique" },
      { key: "FDES", value: "FDES" },
      { key: "AT", value: "Avis technique" },
      { key: "DOP", value: "DOP" }
    ],
  },
];
