import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { api } from "../../../../../RevitJS/API";
import { BuiltInCategory } from "../../../../../RevitJS/Types/RevitTypes";
import { saveSelection } from "../../../Selection/Actions";
import { SelectionStore } from "../../../Selection/Reducers";
import { mapWallType } from "./ScanSelectionModel";
import { setCalepinage } from "../../Actions";
import { ScanElementPopup } from "./ScanSelectionView";

import flattenDeep from "lodash/flattenDeep";
import map from "lodash/map";
import reduce from "lodash/reduce";
import find from "lodash/find";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import forEach from "lodash/forEach";
import concat from "lodash/concat";
import filter from "lodash/filter";
import remove from "lodash/remove";
import differenceWith from "lodash/differenceWith";
import eq from "lodash/eq";
import compact from "lodash/compact";
import { flattenDepth, isUndefined, orderBy, values } from "lodash";

interface Props {
  changeStatusStep: any;
}

export const ScanSelectionController = ({ changeStatusStep }: Props) => {
  let [showPopup, setShowPopup] = useState(false);
  let [showCeilingPopup, setShowCeilingPopup] = useState(false);
  let [loading, setLoading] = useState(false);

  const reduxState: SelectionStore = useSelector(
    (state: SelectionStore) => state
  );

  const dispatch = useDispatch();

  const onContinue = () => {
    setShowPopup(false);
    setShowCeilingPopup(false);
  };

  useEffect(() => {
    async function scanElement() {
      // To show page loader
      setLoading(true);
      console.log("Calling scanElement");
      const calepinageOld = JSON.parse(
        JSON.stringify(reduxState.moduleData.calpinageData.buffer.calepinage)
      );

      if (Array.isArray(calepinageOld.list)) {
        // for older support
        updateCalepinage(
          {
            list: { walls: calepinageOld.list },
            status: calepinageOld.status,
            version: calepinageOld.version,
          },
          dispatch
        );
        changeStatusStep(true, false);
      } else {
        if (calepinageOld.list.walls) {
          const cloneBufferSelectionList = JSON.parse(
            JSON.stringify(
              reduxState.moduleData.calpinageData.buffer.selections.list
            )
          );

          // if (selectionsViewSpecific.length > 0) {
          /// Extract selections for calepinage only
          let selectionIds = map(cloneBufferSelectionList, (n) => {
            return n.Id;
          });

          let selectionCalepinageSpecific = filter(
            reduxState.selections,
            function (o) {
              return includes(selectionIds, o.Id);
            }
          );

          if (selectionCalepinageSpecific.length > 0) {
            /// map selection walls int "mapWallType"
            const mapSavedSelectionElement = MapSavedSelectionElements(
              selectionCalepinageSpecific
            );

            const cloneCalepinageData = JSON.parse(
              JSON.stringify(
                reduxState.moduleData.calpinageData.buffer.calepinage
              )
            );

            if (
              mapSavedSelectionElement.length === 0 &&
              cloneCalepinageData.list.walls.length > 0
            ) {
              cloneCalepinageData.list.walls = [];
              let calepinageStatus = {
                isCalepinageUpdated: true,
                updatedCalepinage: cloneCalepinageData,
              };

              calepinageStatus.isCalepinageUpdated &&
                updateCalepinage(calepinageStatus.updatedCalepinage, dispatch);

              calepinageStatus.isCalepinageUpdated &&
                changeStatusStep(true, false);

              setShowPopup(calepinageStatus.isCalepinageUpdated);
            } else {
              if (mapSavedSelectionElement.length > 0) {
                /// Extract and format revit walls into "mapWallType"
                const revitWalls = await ReadRevitElement(
                  map(mapSavedSelectionElement, "Id")
                );

                //if (revitWalls) {
                const mapRevitWalls = MapRevitElement(revitWalls);

                if (mapRevitWalls.length > 0) {
                  /// Distinct wall between Selection and Revit
                  const distinctSelectionElement = DistinctWall(
                    mapSavedSelectionElement,
                    mapRevitWalls
                  );

                  /// Initialize selectionStatus so that we can use it to check calepinage
                  let selectionStatus = {
                    isSelectionUpdated: false,
                    updatedSelection: selectionCalepinageSpecific,
                  };

                  if (distinctSelectionElement) {
                    /// Remove distinct element from selections
                    selectionStatus = RemoveDistinctElementsFromSelection(
                      selectionCalepinageSpecific,
                      distinctSelectionElement
                    );
                  }

                  /// Extract and map wall from buffer calepinage
                  const cloneCalepinageData = JSON.parse(
                    JSON.stringify(
                      reduxState.moduleData.calpinageData.buffer.calepinage
                    )
                  );

                  const savedCalepinage = GetSavedWallList(cloneCalepinageData);
                  const mapCalepinageWalls = MapCalepinageWall(savedCalepinage);

                  let calepinageStatus = {
                    isCalepinageUpdated: false,
                    updatedCalepinage: cloneCalepinageData,
                  };

                  let finalRemovableWalls: any = [];

                  if (mapCalepinageWalls.length > 0) {
                    /// Distinct wall between Revit and Calepinage
                    const removableWallsByRevit = DistinctWall(
                      mapCalepinageWalls,
                      mapRevitWalls
                    );

                    /// Again map wall from updated selection
                    const mapSavedSelectionElement = MapSavedSelectionElements(
                      selectionStatus.updatedSelection
                    );

                    /// Distinct wall between Calepinage and Selection
                    const removableWallsBySelection = DistinctWall(
                      mapCalepinageWalls,
                      mapSavedSelectionElement
                    );

                    finalRemovableWalls = compact(
                      concat(removableWallsByRevit, removableWallsBySelection)
                    );
                  }
                  /// New Wall to be added from selection

                  const addWalls = compact(
                    DistinctWall(mapSavedSelectionElement, mapCalepinageWalls)
                  );

                  console.log("AddWalls", addWalls);
                  /// Collect details of the wall if wall was added
                  let wallsDetails = addWalls
                    ? await api.queries.getWallsData(map(addWalls, "Id"))
                    : null;

                  /// Create a object of walls and wall details for added walls
                  const wallsTobeAdded = {
                    walls: addWalls,
                    detailWalls: wallsDetails,
                  };

                  /// Add and remove walls from calepinage
                  calepinageStatus = AddRemoveDistinctWallFromCalepinage(
                    cloneCalepinageData,
                    finalRemovableWalls,
                    wallsTobeAdded
                  );

                  /// Check for Placo solution changes
                  calepinageStatus = CheckForPlacoSolution(
                    calepinageStatus.updatedCalepinage,
                    calepinageStatus.isCalepinageUpdated,
                    mapSavedSelectionElement
                  );

                  calepinageStatus = await checkForWallHeightChange(
                    calepinageStatus
                  );

                  const updatedSavedSelections = updateSavedSelection(
                    reduxState.selections,
                    selectionStatus.updatedSelection
                  );

                  selectionStatus.isSelectionUpdated &&
                    updateSelection(updatedSavedSelections, dispatch);

                  calepinageStatus.isCalepinageUpdated &&
                    updateCalepinage(
                      calepinageStatus.updatedCalepinage,
                      dispatch
                    );

                  calepinageStatus.isCalepinageUpdated &&
                    changeStatusStep(true, false);

                  setShowPopup(calepinageStatus.isCalepinageUpdated);
                }
                // } else {
                //   changeStatusStep(false, false);
                // }
              }
            }
          } else {
            changeStatusStep(false, false);
          }
        }
      }
      setLoading(false);
      await scanCeilingElement(); // Temp hide for Prod deployment
    }

    async function scanCeilingElement() {
      const cloneCalepinageData = JSON.parse(
        JSON.stringify(reduxState.moduleData.calpinageData.buffer.calepinage)
      );

      if (cloneCalepinageData.list.ceilings) {
        // To show page loader
        setLoading(true);

        /// Get Revit View Name
        //const revitViewName = await api.queries.getActiveDocumentName();
        const revitViewName = reduxState.projectData.ProjectId;

        /// Extract selection by revit view name
        // let selectionsViewSpecific = filter(reduxState.selections, function (o) {
        //   return o.RevitView === revitViewName;
        // });

        const cloneBufferSelectionList = JSON.parse(
          JSON.stringify(
            reduxState.moduleData.calpinageData.buffer.selections.list
          )
        );

        // if (selectionsViewSpecific.length > 0) {
        /// Extract selections for calepinage only
        let selectionIds = map(cloneBufferSelectionList, (n) => {
          return n.Id;
        });

        let selectionCalepinageSpecific = filter(
          reduxState.selections,
          function (o) {
            return includes(selectionIds, o.Id);
          }
        );

        if (selectionCalepinageSpecific.length > 0) {
          /// map selection ceilings int "mapWallType"
          const mapSavedSelectionElement = MapSavedSelectionCeilings(
            selectionCalepinageSpecific
          );

          if (
            mapSavedSelectionElement.length === 0 &&
            cloneCalepinageData.list.ceilings.length > 0
          ) {
            cloneCalepinageData.list.ceilings = [];
            let calepinageStatus = {
              isCalepinageUpdated: true,
              updatedCalepinage: cloneCalepinageData,
            };

            calepinageStatus.isCalepinageUpdated &&
              updateCalepinage(calepinageStatus.updatedCalepinage, dispatch);

            calepinageStatus.isCalepinageUpdated &&
              changeStatusStep(true, false);

            setShowCeilingPopup(calepinageStatus.isCalepinageUpdated);
          } else {
            if (mapSavedSelectionElement.length > 0) {
              /// Extract and format revit ceilings into "mapWallType"
              let ceilingWalls = await api.queries.getCeilingsData(
                map(mapSavedSelectionElement, "Id")
              );

              // if (ceilingWalls.length > 0) {
              const mapRevitCeilings = MapRevitCeilingElement(ceilingWalls);

              if (mapRevitCeilings.length > 0) {
                /// Distinct wall between Selection and Revit
                const distinctSelectionElement = DistinctWall(
                  mapSavedSelectionElement,
                  mapRevitCeilings
                );

                /// Initialize selectionStatus so that we can use it to check calepinage
                let selectionStatus = {
                  isSelectionUpdated: false,
                  updatedSelection: selectionCalepinageSpecific,
                };

                if (distinctSelectionElement) {
                  /// Remove distinct element from selections
                  selectionStatus = RemoveDistinctElementsFromSelection(
                    selectionCalepinageSpecific,
                    distinctSelectionElement
                  );
                }

                /// Extract and map wall from buffer calepinage

                const savedCalepinage =
                  GetSavedCeilingList(cloneCalepinageData);
                const mapCalepinageCeilings =
                  MapCalepinageCeiling(savedCalepinage);

                let calepinageStatus = {
                  isCalepinageUpdated: false,
                  updatedCalepinage: cloneCalepinageData,
                };

                let finalRemovableCeilings: any[] = [];

                if (mapCalepinageCeilings.length > 0) {
                  /// Distinct wall between Revit and Calepinage
                  const removableCeilingssByRevit = DistinctWall(
                    mapCalepinageCeilings,
                    mapRevitCeilings
                  );

                  /// Again map wall from updated selection
                  const mapSavedSelectionElement = MapSavedSelectionCeilings(
                    selectionStatus.updatedSelection
                  );

                  /// Distinct wall between Calepinage and Selection
                  const removableCeilingsBySelection = DistinctWall(
                    mapCalepinageCeilings,
                    mapSavedSelectionElement
                  );

                  finalRemovableCeilings = compact(
                    concat(
                      removableCeilingssByRevit,
                      removableCeilingsBySelection
                    )
                  );
                }
                /// New Wall to be added from selection

                const addCeilings = compact(
                  DistinctWall(mapSavedSelectionElement, mapCalepinageCeilings)
                );

                /// Collect details of the wall if wall was added
                let ceilingsDetails = addCeilings
                  ? await api.queries.getCeilingsData(map(addCeilings, "Id"))
                  : null;

                /// Create a object of walls and wall details for added walls
                const ceilingsTobeAdded = {
                  ceilings: addCeilings,
                  detailCeilings: ceilingsDetails,
                };

                /// Add and remove walls from calepinage
                calepinageStatus = AddRemoveDistinctCeilingFromCalepinage(
                  cloneCalepinageData,
                  finalRemovableCeilings,
                  ceilingsTobeAdded
                );

                /// Check for Placo solution changes
                calepinageStatus = CheckForPlacoSolutionCeiling(
                  calepinageStatus.updatedCalepinage,
                  calepinageStatus.isCalepinageUpdated,
                  mapSavedSelectionElement
                );

                // calepinageStatus = await checkForWallHeightChange(
                //   calepinageStatus
                // );

                const updatedSavedSelections = updateSavedSelection(
                  reduxState.selections,
                  selectionStatus.updatedSelection
                );

                selectionStatus.isSelectionUpdated &&
                  updateSelection(updatedSavedSelections, dispatch);

                calepinageStatus.isCalepinageUpdated &&
                  updateCalepinage(
                    calepinageStatus.updatedCalepinage,
                    dispatch
                  );

                calepinageStatus.isCalepinageUpdated &&
                  changeStatusStep(true, false);

                setShowCeilingPopup(calepinageStatus.isCalepinageUpdated);
                // } else {
                //   changeStatusStep(true, false);
                // }
              } else {
                const cloneCalepinageData = JSON.parse(
                  JSON.stringify(
                    reduxState.moduleData.calpinageData.buffer.calepinage
                  )
                );

                if (cloneCalepinageData.list.ceilings) {
                  cloneCalepinageData.list.ceilings = [];
                  let calepinageStatus = {
                    isCalepinageUpdated: true,
                    updatedCalepinage: cloneCalepinageData,
                  };

                  calepinageStatus.isCalepinageUpdated &&
                    updateCalepinage(
                      calepinageStatus.updatedCalepinage,
                      dispatch
                    );

                  calepinageStatus.isCalepinageUpdated &&
                    changeStatusStep(true, false);

                  setShowCeilingPopup(calepinageStatus.isCalepinageUpdated);
                }
              }
              // } else {
              //   changeStatusStep(false, false);
              // }
            }
          }
        } else {
          changeStatusStep(false, false);
        }

        setLoading(false);
      }
    }

    scanElement();
  }, []);

  return (
    <ScanElementPopup
      onContinue={onContinue}
      showPopup={showPopup || showCeilingPopup}
      loading={loading}
    />
  );
};

const updateSavedSelection = (
  savedSelections: any,
  revitSpecificSelections: any
) => {
  let cloneSavedSelections = JSON.parse(JSON.stringify(savedSelections));

  forEach(revitSpecificSelections, (rvtSelection, rvtIndex) => {
    cloneSavedSelections[rvtSelection.Id] = rvtSelection;
  });

  return cloneSavedSelections;
};

const updateCalepinage = (updatedCalepinage: any, dispatch: any) => {
  return Promise.resolve(dispatch(setCalepinage(updatedCalepinage)));
};

const updateSelection = (updatedSelection: any, dispatch: any) => {
  return Promise.resolve(dispatch(saveSelection(updatedSelection)));
};

const MapSavedSelectionElements = (selection: any) => {
  return reduce(
    selection,
    function (mapElements: any, mapSelection: any) {
      const wallRows = mapSelection.SelectionByType.wall.Rows;

      forEach(wallRows, (wallSelection: any, i: any) => {
        const rvt = wallSelection.RevitSelection;
        forEach(rvt.Ids, (wallId: string, ind: number) => {
          mapElements.push({
            Id: wallId,
            ObjectType: "wall",
            Type: rvt.RevitType,
            SelectionName: mapSelection.Name,
            PlacoSolution: wallSelection.Options,
            Zone: mapSelection.Zone ? mapSelection.Name : "",
          });
        });
      });

      return mapElements;
    },
    []
  );
};

const MapSavedSelectionCeilings = (selection: any) => {
  return reduce(
    selection,
    function (mapElements: any, mapSelection: any) {
      const ceilingRows = mapSelection.SelectionByType.ceiling.Rows;

      forEach(ceilingRows, (ceilingSelection: any, i: any) => {
        const rvt = ceilingSelection.RevitSelection;
        forEach(rvt.Ids, (ceilingId: string, ind: number) => {
          mapElements.push({
            Id: ceilingId,
            ObjectType: "ceiling",
            Type: rvt.RevitType,
            SelectionName: mapSelection.Name,
            PlacoSolution: ceilingSelection.Options,
            Zone: mapSelection.Zone ? mapSelection.Name : "",
          });
        });
      });

      return mapElements;
    },
    []
  );
};

const AddRemoveDistinctWallFromCalepinage = (
  calepinageData: any,
  removeWalls: any,
  wallsTobeAdded: any
) => {
  let cloneBufferCalepinage = calepinageData.list.walls
    ? JSON.parse(JSON.stringify(calepinageData.list.walls))
    : JSON.parse(JSON.stringify(calepinageData.list));

  map(cloneBufferCalepinage, (bffCalepinage, index) => {
    /// Remove unwanted walls
    remove(bffCalepinage.Ids, function (n: any) {
      return find(removeWalls, { Id: n }) !== undefined;
    });

    return bffCalepinage;
  });

  remove(cloneBufferCalepinage, (n: any) => {
    return n.Ids.length === 0;
  });

  if (wallsTobeAdded.walls && wallsTobeAdded.detailWalls.length > 0) {
    console.log("dt walls", wallsTobeAdded.detailWalls);
    map(wallsTobeAdded.walls, (adWall, index) => {
      let wallDetails = find(wallsTobeAdded.detailWalls, {
        Id: adWall.Id,
      });

      console.log("wall details", wallDetails);
      let wallsObjectAvailable = find(cloneBufferCalepinage, {
        WallType: adWall.Type,
        Zone: !isUndefined(adWall.Zone) ? adWall.Zone : adWall.zone,
        PlacoSolution: adWall.PlacoSolution.MappedSystem.longName,
        Height: parseFloat((wallDetails.Height / 1000).toString()).toFixed(2),
        LevelId: wallDetails.LevelId,
      });

      if (wallsObjectAvailable) {
        find(cloneBufferCalepinage, {
          WallType: adWall.Type,
          Zone: !isUndefined(adWall.Zone) ? adWall.Zone : adWall.zone,
          PlacoSolution: adWall.PlacoSolution.MappedSystem.longName,
          Height: parseFloat((wallDetails.Height / 1000).toString()).toFixed(2),
          LevelId: wallDetails.LevelId,
        }).Ids.push(adWall.Id);
      } else {
        cloneBufferCalepinage.push({
          Height: parseFloat((wallDetails.Height / 1000).toString()).toFixed(2),
          LevelName: wallDetails.LevelName,
          PlacoSolution: adWall.PlacoSolution.MappedSystem.longName,
          PlacoSolutionId: adWall.PlacoSolution.MappedSystem.oid,
          PlacoSolutionHeight:
            adWall.PlacoSolution.MappedSystem["GFR-Height limit in m"],
          Zone: !isUndefined(adWall.Zone) ? adWall.Zone : adWall.zone,
          WallType: adWall.Type,
          LevelElevation: wallDetails.LevelElevation,
          LevelId: wallDetails.LevelId,
          Ids: [adWall.Id],
          montant: [],
          plaque: [],
        });
      }
    });
  }

  cloneBufferCalepinage = orderBy(
    cloneBufferCalepinage,
    (item) => item.LevelElevation,
    ["asc"]
  );

  if (!isEqual(calepinageData.list.walls, cloneBufferCalepinage)) {
    calepinageData.status = false;
    calepinageData.list.walls = cloneBufferCalepinage;
    return {
      isCalepinageUpdated: true,
      updatedCalepinage: calepinageData,
    };
  }

  return {
    isCalepinageUpdated: false,
    updatedCalepinage: calepinageData,
  };
};

const AddRemoveDistinctCeilingFromCalepinage = (
  calepinageData: any,
  removeCeilings: any,
  ceilingsTobeAdded: any
) => {
  let cloneBufferCalepinage = JSON.parse(
    JSON.stringify(calepinageData.list.ceilings)
  );

  map(cloneBufferCalepinage, (bffCalepinage, index) => {
    /// Remove unwanted walls
    remove(bffCalepinage.Ids, function (n: any) {
      return find(removeCeilings, { Id: n }) !== undefined;
    });

    return bffCalepinage;
  });

  remove(cloneBufferCalepinage, (n: any) => {
    return n.Ids.length === 0;
  });

  if (ceilingsTobeAdded.ceilings) {
    map(ceilingsTobeAdded.ceilings, (adCeiling, index) => {
      let ceilingDetails = find(ceilingsTobeAdded.detailCeilings, {
        Id: adCeiling.Id,
      });

      let ceilingsObjectAvailable = find(cloneBufferCalepinage, {
        CeilingType: adCeiling.Type,
        Zone: !isUndefined(adCeiling.Zone) ? adCeiling.Zone : adCeiling.zone,
        PlacoSolution: adCeiling.PlacoSolution.MappedSystem.longName,
        Height: parseFloat((ceilingDetails.Height / 1000).toString()).toFixed(
          2
        ),
        LevelId: ceilingDetails.LevelId,
      });

      if (ceilingsObjectAvailable) {
        find(cloneBufferCalepinage, {
          CeilingType: adCeiling.Type,
          Zone: !isUndefined(adCeiling.Zone) ? adCeiling.Zone : adCeiling.zone,
          PlacoSolution: adCeiling.PlacoSolution.MappedSystem.longName,
          Height: parseFloat((ceilingDetails.Height / 1000).toString()).toFixed(
            2
          ),
          LevelId: ceilingDetails.LevelId,
        }).Ids.push(adCeiling.Id);
      } else {
        cloneBufferCalepinage.push({
          Height: parseFloat((ceilingDetails.Height / 1000).toString()).toFixed(
            2
          ),
          LevelName: ceilingDetails.LevelName,
          PlacoSolution: adCeiling.PlacoSolution.MappedSystem.longName,
          PlacoSolutionId: adCeiling.PlacoSolution.MappedSystem.oid,
          PlacoSolutionHeight:
            adCeiling.PlacoSolution.MappedSystem["GFR-Height limit in m"],
          Zone: !isUndefined(adCeiling.Zone) ? adCeiling.Zone : adCeiling.zone,
          CeilingType: adCeiling.Type,
          LevelElevation: ceilingDetails.LevelElevation,
          LevelId: ceilingDetails.LevelId,
          Ids: [adCeiling.Id],
          montant: [],
          plaque: [],
        });
      }
    });
  }

  cloneBufferCalepinage = orderBy(
    cloneBufferCalepinage,
    (item) => item.LevelElevation,
    ["asc"]
  );

  if (!isEqual(calepinageData.list.ceilings, cloneBufferCalepinage)) {
    calepinageData.status = false;
    calepinageData.list.ceilings = cloneBufferCalepinage;
    return {
      isCalepinageUpdated: true,
      updatedCalepinage: calepinageData,
    };
  }

  return {
    isCalepinageUpdated: false,
    updatedCalepinage: calepinageData,
  };
};

const CheckForPlacoSolution = (
  calepinageData: any,
  isCalepinageUpdatedBrfore: boolean,
  mapSavedSelectionElement: any
) => {
  let cloneBufferCalepinage = JSON.parse(
    JSON.stringify(calepinageData.list.walls)
  );

  let isUpdated = false;
  map(cloneBufferCalepinage, (calWall, cllIns) => {
    // if (!calWall.PlacoSolutionId.includes("custom")) {
    let selectionObject = find(mapSavedSelectionElement, (o) => {
      return includes(calWall.Ids, o.Id);
    });

    if (selectionObject) {
      if (
        !isEqual(
          calWall.PlacoSolutionId,
          selectionObject.PlacoSolution.MappedSystem.oid
        )
      ) {
        if (
          !selectionObject.PlacoSolution.MappedSystem.oid.includes("custom")
        ) {
          calWall.PlacoSolution =
            selectionObject.PlacoSolution.MappedSystem.longName;
          calWall.PlacoSolutionId =
            selectionObject.PlacoSolution.MappedSystem.oid;
          calWall.PlacoSolutionHeight =
            selectionObject.PlacoSolution.MappedSystem["GFR-Height limit in m"];
          calWall.plaque = [];
          calWall.montant = [];
          calWall.chk = true;
        } else {
          calWall.PlacoSolution =
            selectionObject.PlacoSolution.MappedSystem.translation;
          calWall.PlacoSolutionId =
            selectionObject.PlacoSolution.MappedSystem.oid;
          calWall.PlacoSolutionHeight = "";
          calWall.plaque = [];
          calWall.montant = [];
          calWall.chk = false;
        }
        isUpdated = true;
      }
    }
    // }
    return calWall;
  });

  if (isUpdated) {
    calepinageData.status = false;
    calepinageData.list.walls = cloneBufferCalepinage;
    return {
      isCalepinageUpdated: true,
      updatedCalepinage: calepinageData,
    };
  }

  return {
    isCalepinageUpdated: isCalepinageUpdatedBrfore,
    updatedCalepinage: calepinageData,
  };
};

const CheckForPlacoSolutionCeiling = (
  calepinageData: any,
  isCalepinageUpdatedBrfore: boolean,
  mapSavedSelectionElement: any
) => {
  let cloneBufferCalepinage = JSON.parse(
    JSON.stringify(calepinageData.list.ceilings)
  );

  let isUpdated = false;
  map(cloneBufferCalepinage, (calCeiling, cllIns) => {
    // if (!calWall.PlacoSolutionId.includes("custom")) {
    let selectionObject = find(mapSavedSelectionElement, (o) => {
      return includes(calCeiling.Ids, o.Id);
    });

    if (selectionObject) {
      if (
        !isEqual(
          calCeiling.PlacoSolutionId,
          selectionObject.PlacoSolution.MappedSystem.oid
        )
      ) {
        if (
          !selectionObject.PlacoSolution.MappedSystem.oid.includes("custom")
        ) {
          calCeiling.PlacoSolution =
            selectionObject.PlacoSolution.MappedSystem.longName;
          calCeiling.PlacoSolutionId =
            selectionObject.PlacoSolution.MappedSystem.oid;
          calCeiling.PlacoSolutionHeight =
            selectionObject.PlacoSolution.MappedSystem["GFR-Height limit in m"];
          calCeiling.plaque = [];
          calCeiling.montant = [];
          calCeiling.chk = true;
        } else {
          calCeiling.PlacoSolution =
            selectionObject.PlacoSolution.MappedSystem.translation;
          calCeiling.PlacoSolutionId =
            selectionObject.PlacoSolution.MappedSystem.oid;
          calCeiling.PlacoSolutionHeight = "";
          calCeiling.plaque = [];
          calCeiling.montant = [];
          calCeiling.chk = false;
        }
        isUpdated = true;
      }
    }
    // }
    return calCeiling;
  });

  let groupCeiling: any = [];

  forEach(cloneBufferCalepinage, (item) => {
    let isGroupCeilingHas = find(groupCeiling, {
      CeilingType: item.CeilingType,
      LevelId: item.LevelId,
    });
    if (isGroupCeilingHas) {
      let ids = isGroupCeilingHas.Ids;
      ids.push(item.Id);
      find(groupCeiling, {
        LevelId: item.LevelId,
        CeilingType: item.CeilingType,
      }).Ids = ids;
    } else {
      groupCeiling.push(item);
    }
  });

  if (isUpdated) {
    calepinageData.status = false;
    calepinageData.list.ceilings = groupCeiling;
    return {
      isCalepinageUpdated: true,
      updatedCalepinage: calepinageData,
    };
  }

  return {
    isCalepinageUpdated: isCalepinageUpdatedBrfore,
    updatedCalepinage: calepinageData,
  };
};

const checkForWallHeightChange = async (calepinageStatus: any) => {
  let wallRows: any = flattenDepth(
    JSON.parse(JSON.stringify(calepinageStatus.updatedCalepinage.list.walls)),
    1
  );

  let wallIds = map(wallRows, (ele) => {
    return ele.Ids;
  });

  wallIds = flattenDeep(wallIds);

  let wallsDetails = await api.queries.getWallsData(wallIds);

  let wallsDetailsSettled = map(wallsDetails, (wall, index) => {
    // change height from meter to mm
    wall.Height = parseFloat(
      ((wall.Height as number) / 1000).toString()
    ).toFixed(2);

    // Extract wall from history on the basis of key (i.e. "Wall type" + "Wall Height" + "LevelId")
    // so that can tak placo solutions that previously assigned
    let wallFormHistory = find(wallRows, function (o) {
      return includes(o.Ids, wall.Id);
    });

    // if (!wallFormHistory) {
    //   wallFormHistory = find(wallRows, function (o) {
    //     return o.Id === wall.Id;
    //   });
    // }

    // if found by "Wall type" + "Wall Height" + "LevelId"
    if (wallFormHistory) {
      wall["plaque"] =
        wall.Height === wallFormHistory.Height
          ? wallFormHistory.plaque
          : undefined;
      wall["montant"] =
        wall.Height === wallFormHistory.Height
          ? wallFormHistory.montant
          : undefined;
      wall["layoutPossible"] = wallFormHistory.layoutPossible;
      wall["placo"] = wallFormHistory.placo;
      wall["PlacoSolution"] = wallFormHistory.PlacoSolution;
      wall["Zone"] = wallFormHistory.Zone;
      if (!isUndefined(wallFormHistory.chk)) {
        wall["chk"] = wallFormHistory.chk;
      } else {
        wall["chk"] = true;
      }

      if (!isUndefined(wallFormHistory.layoutPossible)) {
        wall["layoutPossible"] = wallFormHistory.layoutPossible;
      } else {
        wall["layoutPossible"] = true;
      }

      if (!isUndefined(wallFormHistory.placo)) {
        wall["placo"] = wallFormHistory.placo;
      } else {
        wall["placo"] = true;
      }

      if (wallFormHistory.montantArray) {
        wall["montantArray"] = wallFormHistory.montantArray;
      }

      if (wallFormHistory.plaqueArray) {
        wall["plaqueArray"] = wallFormHistory.plaqueArray;
      }
    }

    return wall;
  });

  let grpWallsData: any[] = [];

  forEach(wallsDetailsSettled, (value: any, index: number) => {
    let wallRow = find(wallRows, (walRow: any) => {
      return includes(walRow.Ids, value.Id);
    });

    let oneValue = find(grpWallsData, {
      LevelId: value.LevelId,
      Zone: wallRow.Zone,
      WallType: value.WallType,
      Height: value.Height,
      PlacoSolution: wallRow.PlacoSolution,
    });

    if (oneValue) {
      let ids = oneValue.Ids;
      ids.push(value.Id);
      find(grpWallsData, {
        LevelId: value.LevelId,
        Zone: wallRow.Zone,
        WallType: value.WallType,
        Height: value.Height,
        PlacoSolution: wallRow.PlacoSolution,
      }).Ids = ids;
    } else {
      let objectGrWall: any = {
        Height: value.Height,
        LevelName: value.LevelName,
        PlacoSolution: wallRow.PlacoSolution,
        PlacoSolutionId: wallRow.PlacoSolutionId,
        PlacoSolutionHeight: wallRow.PlacoSolutionHeight,
        Zone: wallRow.Zone,
        WallType: value.WallType,
        LevelElevation: value.LevelElevation,
        LevelId: value.LevelId,
        Ids: [value.Id],
        montant: wallRow.montant,
        plaque: wallRow.plaque,
        layoutPossible: wallRow.layoutPossible,
        chk: wallRow.chk,
        placo: wallRow.placo,
      };

      if (value.montantArray) {
        objectGrWall = { ...objectGrWall, montantArray: value.montantArray };
      }

      if (value.plaqueArray) {
        objectGrWall = { ...objectGrWall, plaqueArray: value.plaqueArray };
      }

      grpWallsData.push(objectGrWall);
    }
  });

  if (!isEqual(calepinageStatus.updatedCalepinage.list.walls, grpWallsData)) {
    calepinageStatus.isCalepinageUpdated = true;
    calepinageStatus.updatedCalepinage.list.walls = grpWallsData;
    calepinageStatus.updatedCalepinage.status = false;
    return calepinageStatus;
  }

  return calepinageStatus;
};

const GetSavedWallList = (savedCalepinage: any) => {
  if (savedCalepinage.list.walls) {
    if (savedCalepinage.list.walls.length > 0) {
      /// 1. Flatten the calepinage list
      let wallList = flattenDeep(savedCalepinage.list.walls);
      return wallList ? wallList : null;
    }
  }

  if (savedCalepinage.list.length > 0) {
    /// 1. Flatten the calepinage list
    let wallList = flattenDeep(savedCalepinage.list);
    return wallList ? wallList : null;
  }

  return null;
};

const GetSavedCeilingList = (savedCalepinage: any) => {
  if (savedCalepinage.list.ceilings.length > 0) {
    /// 1. Flatten the calepinage list
    let ceilingList = flattenDeep(savedCalepinage.list.ceilings);
    return ceilingList ? ceilingList : null;
  }

  return null;
};

const MapCalepinageWall = (elements: any) => {
  let mapElement: mapWallType[] = [];

  forEach(elements, (element, index) => {
    forEach(element.Ids, (id, ind) => {
      mapElement.push({
        Id: id,
        ObjectType: "wall",
        Type: element.WallType,
        Zone: element.Zone,
      });
    });
  });
  return mapElement;
};

const MapCalepinageCeiling = (elements: any) => {
  let mapElement: mapWallType[] = [];

  forEach(elements, (element, index) => {
    forEach(element.Ids, (id, ind) => {
      mapElement.push({
        Id: id,
        ObjectType: "ceiling",
        Type: element.CeilingType,
        Zone: element.Zone,
      });
    });
  });
  return mapElement;
};

const MapRevitElement = (elements: any) => {
  let mapElement: any = [];
  forEach(elements, (element: any, ind: number) => {
    mapElement.push({
      Id: element.Id,
      ObjectType: "wall",
      Type: element.WallType,
      Zone: element.Zone,
    });
  });
  return mapElement;
};

const MapRevitCeilingElement = (elements: any) => {
  let mapElement: any = [];
  forEach(elements, (element: any, ind: number) => {
    mapElement.push({
      Id: element.Id,
      ObjectType: "ceiling",
      Type: element.CeilingType,
      Zone: element.Zone ? element.Zone : "",
    });
  });
  return mapElement;
};

const DistinctWall = (calepinageWalls: any, revitWalls: any) => {
  let distinctWall = differenceWith(
    calepinageWalls,
    revitWalls,
    (x: any, y: any) => {
      return eq(x.Id, y.Id) && eq(x.Type, y.Type);
    }
  );

  return distinctWall?.length > 0 ? distinctWall : null;
};

const ReadRevitElement = async (wallIds: string[]) => {
  let wallTree = await api.queries.getWallsData(wallIds);
  return wallTree;
};

const RemoveDistinctElementsFromSelection = (
  selectionData: any,
  distinctElements: any
) => {
  let cloneReduxSelections = JSON.parse(JSON.stringify(selectionData));

  let isUserUpdated = false;
  map(distinctElements, (diff, index) => {
    // let rdxselections = find(cloneReduxSelections, {
    //   Name: diff.SelectionName,
    // });

    if (diff.ObjectType === "wall") {
      map(cloneReduxSelections, (rdxselections, index) => {
        let wallRows = JSON.parse(
          JSON.stringify(rdxselections.SelectionByType.wall.Rows)
        );
        let modifiedWallRows = map(wallRows, (wlrow, wlindex) => {
          remove(wlrow.RevitSelection.Ids, (n) => {
            return n === diff.Id;
          });
          return wlrow;
        });

        remove(modifiedWallRows, (n: any) => {
          return n.RevitSelection.Ids.length === 0;
        });

        if (
          !isEqual(rdxselections.SelectionByType.wall.Rows, modifiedWallRows)
        ) {
          isUserUpdated = true;
          rdxselections.Time = Date.now();
        }
        rdxselections.SelectionByType.wall.Rows = modifiedWallRows;
        return rdxselections;
      });
    }

    if (diff.ObjectType === "ceiling") {
      map(cloneReduxSelections, (rdxselections, index) => {
        let ceilingRows = JSON.parse(
          JSON.stringify(rdxselections.SelectionByType.ceiling.Rows)
        );
        let modifiedCeilingRows = map(ceilingRows, (clrow, wlindex) => {
          remove(clrow.RevitSelection.Ids, (n) => {
            return n === diff.Id;
          });
          return clrow;
        });

        remove(modifiedCeilingRows, (n: any) => {
          return n.RevitSelection.Ids.length === 0;
        });

        if (
          !isEqual(
            rdxselections.SelectionByType.ceiling.Rows,
            modifiedCeilingRows
          )
        ) {
          isUserUpdated = true;
          rdxselections.Time = Date.now();
        }
        rdxselections.SelectionByType.ceiling.Rows = modifiedCeilingRows;
        return rdxselections;
      });
    }
  });

  if (isUserUpdated) {
    return { isSelectionUpdated: true, updatedSelection: cloneReduxSelections };
  }

  return { isSelectionUpdated: false, updatedSelection: null };
};
