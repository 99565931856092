import React, { useEffect } from "react";
import Slider from "@material-ui/core/Slider";

import "../assets/index.scss";

function valuetext(value: number) {
  return `${value}°C`;
}

export interface SliderProps {
  name: string;
  value?: any[] | any;
  min?: number;
  max?: number;
  onChange: any;
  step: number;
  pimAttribute?: string;
}

export const RangeSlider = ({
  name,
  value,
  min,
  max,
  onChange,
  step,
  pimAttribute,
}: SliderProps) => {
  const handleChange = (event: any, newValue: any) => {
    onChange(event, newValue, pimAttribute);
  };
  return (
    <div className="Slider">
      <Slider
        value={
          value && value.every((op: any) => op !== null) ? value : [min, max]
        }
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={step}
        min={min}
        max={max}
      />
    </div>
  );
};

export default RangeSlider;
