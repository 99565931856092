import { ThunkAction } from "redux-thunk";
import { FiltersActionTypes } from "./types";
import { loadFiltersRequest, applyFiltersRequest } from "./actions";
import { DrawStore } from "../reducers";
import { addFilterFields, setFiltersOptions } from "../../utils/utils";
import {
  CLOISONS_ID,
  DOUBLAGES_ID,
  FILTER_CHOICE_TYPE,
  PLAFONDS_ID,
  FILTER_CRITERION_TYPE,
  GAINES_ID,
} from "../../assets/constants";
import { ResetSearchKeyWordAction } from "../systems/actions";

type Effect = ThunkAction<any, DrawStore, any, FiltersActionTypes>;

export const loadFilters =
  (advanseSelectorData: any): Effect =>
  async (dispatch: any, getState: any) => {
    let { currentSystemDetail, systemTypeID } = getState().systems;
    let filters = getState().filters.filters;

    let newFilters = await getFilters(advanseSelectorData);
    if (!filters || !(filters.length > 0)) {
      filters = newFilters.wall;
    }
    if (systemTypeID === DOUBLAGES_ID) {
      //var liningWallsFilter = placoFilters.LiningWalls;
      var liningWallsFilter = newFilters.liningwall;
      if (!checkFilters(liningWallsFilter, filters)) {
        filters = newFilters.liningwall;
      }
    } else if (systemTypeID === CLOISONS_ID) {
      //var wallsFilter = placoFilters.Walls;
      var wallsFilter = newFilters.wall;
      if (!checkFilters(wallsFilter, filters)) {
        //filters = placoFilters.Walls;
        filters = newFilters.wall;
      }
    } else if (systemTypeID === PLAFONDS_ID) {
      //var ceilingFilter = placoFilters.Ceiling;
      var ceilingFilter = newFilters.ceiling;
      if (!checkFilters(ceilingFilter, filters)) {
        //filters = placoFilters.Ceiling;
        filters = newFilters.ceiling;
      }
    } else if (systemTypeID === GAINES_ID) {
      //var ceilingFilter = placoFilters.Ceiling;
      var gainesFilter = newFilters.gainestechniqueswall;
      if (!checkFilters(gainesFilter, filters)) {
        //filters = placoFilters.Ceiling;
        filters = newFilters.gainestechniqueswall;
      }
    }

    if (filters !== undefined) {
      currentSystemDetail = addFilterFields(currentSystemDetail, filters);
      filters = setFiltersOptions(currentSystemDetail, filters);
      dispatch(loadFiltersRequest(filters));
    } else {
      dispatch(loadFiltersRequest([]));
    }
  };

const checkFilters = (filters: any[], existingFilters: any[]): any => {
  var flag = true;
  filters.forEach((nf) => {
    const result = existingFilters.some((ef) => ef.name === nf.name);
    if (!result) {
      flag = false;
    }
  });
  return flag;
};

export const getFilters = async (advancedSelectorData: any) => {
  // const prescriptionList = await getAllPrescriptions(config);
  // const prescriBIMplugin = prescriptionList.find((p: any) => p.name === 'PrescriBIM');
  // let plugins = await getAllPlugins(config);
  // const application = plugins.find((p: any) => p.applicationName === 'PrescriBIM');

  const typeOptions = ["wall", "ceiling", "liningwall", "gainestechniquewall"];

  if (advancedSelectorData) {
    // let data = await getPrescriptionActiveVersion(application.applicationId, config);
    // let advancedSelector = data?.versions[0]?.advanceSelector;
    let selectorFilters = advancedSelectorData.selectorFilters;

    let filterValues: any = {};
    let filters = selectorFilters.map((f: any) => {
      if (f.category.includes("all")) {
        f.category = typeOptions;
      }
      let choiceTypeAndCriterion = getChoiceTypeAndCriterion(f?.type);

      for (let i = 0; i < f.category.length; i++) {
        if (!filterValues[f.category[i]]) {
          filterValues[f.category[i]] = [];
        }

        filterValues[f.category[i]].push({
          name: f.label,
          pimAttribute: f?.attribute?.attributeName,
          type: "Text",
          criterion: choiceTypeAndCriterion.criterion, //"Equals",
          choiceType: choiceTypeAndCriterion.choiceType, //getChoiceType(f?.type),
          options: null,
          value: null,
        });
      }
    });
    return filterValues;
  }
};

const getChoiceTypeAndCriterion = (type: string): any => {
  if (type === "selectionlistsingle") {
    return {
      choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
      criterion: FILTER_CRITERION_TYPE.EQUALS,
    };
  } else if (type === "selectionlistmultiple") {
    return {
      choiceType: FILTER_CHOICE_TYPE.CHECKBOX,
      criterion: FILTER_CRITERION_TYPE.INCLUDES,
    };
    //return FILTER_CHOICE_TYPE.CHECKBOX;
  } else if (type === "slideronevalue") {
    return {
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      criterion: FILTER_CRITERION_TYPE.EQUALS,
    };
    //return FILTER_CHOICE_TYPE.SLIDER;
  } else if (type === "sliderminmaxvalue") {
    return {
      choiceType: FILTER_CHOICE_TYPE.SLIDER,
      criterion: FILTER_CRITERION_TYPE.BETWEEN,
    };
  }
  return {
    choiceType: FILTER_CHOICE_TYPE.RADIOBOX,
    criterion: FILTER_CRITERION_TYPE.EQUALS,
  };
};

export const applyFilters =
  (filterName: string, filterValue: any | any[]): Effect =>
  async (dispatch: any, getState: any) => {
    let value = filterValue;
    if (typeof filterValue === "object") {
      value = filterValue.length > 0 ? filterValue : null;
    }

    let { filters } = getState().filters;
    filters = filters.map((filter: any) =>
      filter.pimAttribute === filterName ? { ...filter, value: value } : filter
    );
    dispatch(applyFiltersRequest(filters));
  };

export const resetFilters =
  (advancedSelectorData: any): Effect =>
  async (dispatch: any, getState: any) => {
    let { data, systemTypeID } = getState().systems;
    let currentSystemDetail: any[] = [];
    let filters = getState().filters.filters;
    let config = getState().config;
    let newFilters = await getFilters(advancedSelectorData);
    if (!filters || !(filters.length > 0)) {
      filters = newFilters.wall;
    }

    if (systemTypeID === DOUBLAGES_ID) {
      //filters = placoFilters.LiningWalls;
      filters = newFilters.liningwall;
      currentSystemDetail = data.liningWallsDetails;
    } else if (systemTypeID === CLOISONS_ID) {
      //filters = placoFilters.Walls;
      filters = newFilters.wall;
      currentSystemDetail = data.partitionsDetails;
    } else if (systemTypeID === PLAFONDS_ID) {
      //filters = placoFilters.Ceiling;
      filters = newFilters.ceiling;
      currentSystemDetail = data.ceilingsDetails;
    } else if (systemTypeID === GAINES_ID) {
      //filters = placoFilters.Ceiling;
      filters = newFilters.gainestechniqueswall;
      currentSystemDetail = data.gainestechniqueswallDetails;
    }

    if (filters !== undefined) {
      currentSystemDetail = addFilterFields(currentSystemDetail, filters);
      filters = setFiltersOptions(currentSystemDetail, filters);
      dispatch(loadFiltersRequest(filters));
    } else {
      dispatch(loadFiltersRequest([]));
    }

    dispatch(ResetSearchKeyWordAction());
  };

export const resetSearchFilter =
  (): Effect => async (dispatch: any, getState: any) => {
    dispatch(ResetSearchKeyWordAction());
  };
