import React from "react";
import { pluginId } from "../..";
import ParentRoot from "../../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../../RevitJS/API";
import { Functionality } from "../../../../RevitJS/Types/StoreTypes";
import { Root } from "../../../PlacoBIMv5/Properties/components/root";
import jwt_decode from "jwt-decode";

export const functionalityId = "434b007f-9956-4c5f-a246-c129f595c9a8";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Library_Properties",
    module: "PLACOBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root openedFrom={"PRESCRIBIM"}/>
    </ParentRoot>
  );
};

export const BiblioPropertiesRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root openedFrom={"PRESCRIBIM"}/>
    </ParentRoot>
  );
};

export const Properties: Functionality = {
  Name: {
    French: "Propriétés",
    English: "Properties",
    German: "Propriétés",
    Italian: "Propriétés",
    Indonesian: "Propriétés",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Paramétrer les propriétés",
    English: "Set properties",
    German: "Paramétrer les propriétés",
    Italian: "Paramétrer les propriétés",
    Indonesian: "Paramétrer les propriétés",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Param.png",
  RootComponent,
  ParentId: "98fe05e3-266c-4671-8821-c9887747f678",
};
