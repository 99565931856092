import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Container, Header } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "./FunctionalityHeader";
import { Routes } from "./root";
import { useDispatch, useSelector } from "react-redux";
import { setRevitSetup } from "../Actions";
import { SelectionStore } from "../../Selection/Reducers";
import { saveReperage } from "../Helpers";
import wording from "../Resources/wording.json";

interface Props {
  setRoute: any;
}

const ReperageParametrageRevit = (props: Props) => {
  const reduxState = useSelector((state: SelectionStore) => state);
  const moduleData = reduxState.moduleData;
  const language: string = reduxState.language;

  const [floorData, setFloorData] = useState<any>([]);
  const [ceilingData, setCeilingData] = useState<any>([]);
  const [threeDData, setThreeDData] = useState<any>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [saveExistingReperage, setSaveExistingReperage] =
    useState<boolean>(false);
  const [checkedPlans, setCheckedPlans] = useState<any>([]);
  const dispatch = useDispatch();
  const translation: any = wording;

  useEffect(() => {
    async function getInitialData() {
      let floors: any = await api.queries.getViewsByType("floor");
      let floorPlans: any[] = [];
      let revitSetupList = moduleData.reperageData.buffer.revitSetup.list;
      setCheckedPlans(revitSetupList);

      if (moduleData.reperageData.buffer.revitSetup.saveExistingReperage) {
        setSaveExistingReperage(
          moduleData.reperageData.buffer.revitSetup.saveExistingReperage
        );
      }

      let revitSetUpFloorList =
        revitSetupList && revitSetupList.length > 0
          ? revitSetupList?.filter((f: any) => f.type === "floor")
          : [];
      let revitSetUpCeilingList =
        revitSetupList && revitSetupList.length > 0
          ? revitSetupList?.filter((f: any) => f.type === "ceiling")
          : [];
      let revitSetUpThreeDList =
        revitSetupList && revitSetupList.length > 0
          ? revitSetupList?.filter((f: any) => f.type === "threeD")
          : [];

      floors.forEach((floor: any) => {
        let checked: boolean = false;
        let savedData = revitSetUpFloorList?.find(
          (f: any) => f.ViewName === floor.ViewName
        );
        if (savedData) {
          checked = savedData.Checked;
        }

        floorPlans.push({
          LevelId: floor.LevelId,
          LevelName: floor.LevelName,
          ViewId: floor.ViewId,
          ViewName: floor.ViewName,
          Checked: checked,
          type: "floor",
        });
      });
      setFloorData(floorPlans);

      let ceilings: any = await api.queries.getViewsByType("ceiling");
      let ceilingPlans: any[] = [];
      ceilings.forEach((ceiling: any) => {
        let checked: boolean = false;
        let savedData = revitSetUpCeilingList?.find(
          (f: any) => f.ViewName === ceiling.ViewName
        );
        if (savedData) {
          checked = savedData.Checked;
        }

        ceilingPlans.push({
          LevelId: ceiling.LevelId,
          LevelName: ceiling.LevelName,
          ViewId: ceiling.ViewId,
          ViewName: ceiling.ViewName,
          Checked: checked,
          type: "ceiling",
        });
      });
      setCeilingData(ceilingPlans);

      let threeDs: any = await api.queries.getViewsByType("threeD");
      let threeDPlans: any[] = [];
      threeDs.forEach((threeD: any) => {
        let checked: boolean = false;
        let savedData = revitSetUpThreeDList?.find(
          (f: any) => f.ViewName === threeD.ViewName
        );
        if (savedData) {
          checked = savedData.Checked;
        }

        threeDPlans.push({
          LevelId: threeD.LevelId,
          LevelName: threeD.LevelName,
          ViewId: threeD.ViewId,
          ViewName: threeD.ViewName,
          Checked: checked,
          type: "threeD",
        });
      });
      setThreeDData(threeDPlans);
    }

    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function saveData() {
      if (isValidated) {
        await saveReperage(
          moduleData.reperageData.buffer,
          reduxState.projectData.ProjectId,
          reduxState.config
        );
        props.setRoute(Routes.ROOT);
      }
    }
    saveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleData]);

  const toggleCheck = (data: any, type: string) => {
    let floor = JSON.parse(JSON.stringify(floorData));
    let ceiling = JSON.parse(JSON.stringify(ceilingData));
    let threeD = JSON.parse(JSON.stringify(threeDData));

    if (type === "floor") {
      floor.filter((f: any) => f.ViewName === data.label)[0].Checked =
        data.checked;
      setFloorData(floor);
    } else if (type === "ceiling") {
      ceiling.filter((c: any) => c.ViewName === data.label)[0].Checked =
        data.checked;
      setCeilingData(ceiling);
    } else if (type === "threeD") {
      threeD.filter((c: any) => c.ViewName === data.label)[0].Checked =
        data.checked;
      setThreeDData(threeD);
    }

    let checkedFloor = floor.filter((f: any) => f.Checked === true);
    let checkedCeiling = ceiling.filter((c: any) => c.Checked === true);
    let checkedThreeD = threeD.filter((c: any) => c.Checked === true);
    let list = checkedFloor.concat(checkedCeiling).concat(checkedThreeD);
    setCheckedPlans(list);

    setIsValidated(false);
  };

  const validateRevitSetup = async () => {
    let floor = floorData.filter((f: any) => f.Checked === true);
    let ceiling = ceilingData.filter((c: any) => c.Checked === true);
    let threeD = threeDData.filter((c: any) => c.Checked === true);

    let list = floor.concat(ceiling).concat(threeD);

    let data = {
      status: true,
      list: list,
      version: 1,
      saveExistingReperage: saveExistingReperage,
    };

    dispatch(setRevitSetup(data));
    setIsValidated(true);

    localStorage.setItem("isModification", "false");
  };

  const toggleSaveExistingReperage = () => {
    setSaveExistingReperage(!saveExistingReperage);
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <FunctionalityHeader
          Icon={
            reduxState.config.REACT_APP_SERVERURL +
            "/pluginIcons_V3/ReperageV3-trans.png"
          }
          name={translation.reperage[language]}
          subheader=""
        />

        <Container
          className="header-alignment subHeader"
          style={{ marginTop: "10px", marginBottom: "0" }}
        >
          {"Paramétrage Revit"}
        </Container>

        <Container
          className="grid-container context-container"
          style={{
            height: "calc(100vh - 165px)",
            overflow: "auto",
            textAlign: "left",
            padding: "15px",
            border: "1px solid #ccc",
            marginTop: 0,
          }}
        >
          <div>
            <Header size="small" className="blue" style={{ marginBottom: "0" }}>
              Plans d’étage
            </Header>
            <p style={{ marginBottom: "5px" }}>
              Veuillez sélectionner les plans d’étage REVIT à utiliser comme
              base pour générer les plans de Repérage
            </p>
            <div className="parameter-check-container">
              {_.map(floorData, (floor: any, key) => {
                return (
                  <Checkbox
                    key={key}
                    label={floor.ViewName}
                    checked={floor.Checked}
                    onChange={(e, data) => {
                      toggleCheck(data, "floor");
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div>
            <Header size="small" className="blue" style={{ marginBottom: "0" }}>
              Plans de plafond
            </Header>
            <p style={{ marginBottom: "5px" }}>
              Veuillez sélectionner le plan de plafond REVIT à utiliser comme
              base pour générer les plans de Repérage
            </p>
            <div className="parameter-check-container">
              {_.map(ceilingData, (ceiling: any, key) => {
                return (
                  <Checkbox
                    key={key}
                    label={ceiling.ViewName}
                    checked={ceiling.Checked}
                    onChange={(e, data) => {
                      toggleCheck(data, "ceiling");
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div>
            <Header size="small" className="blue" style={{ marginBottom: "0" }}>
              Vue 3D
            </Header>
            <p style={{ marginBottom: "5px" }}>
              Veuillez sélectionner le vue 3D REVIT à utiliser comme base pour
              générer les plans de Repérage
            </p>
            <div className="parameter-check-container">
              {_.map(threeDData, (threeD: any, key) => {
                return (
                  <Checkbox
                    key={key}
                    label={threeD.ViewName}
                    checked={threeD.Checked}
                    onChange={(e, data) => {
                      toggleCheck(data, "threeD");
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div style={{ paddingLeft: "5px" }}>
            <Checkbox
              checked={saveExistingReperage}
              onChange={(e, data) => {
                toggleSaveExistingReperage();
              }}
              label="Cochez cette casse si vous souhaitez créer un nouveau plan de repérage. Si cette case n’est pas cochée, le plan existant sera régénéré"
            />
          </div>
        </Container>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "8px",
            marginBottom: "20px",
          }}
        >
          <span>
            <Button
              color="orange"
              content={translation.back[language]}
              onClick={() => {
                props.setRoute(Routes.ROOT);
              }}
            ></Button>
          </span>
          <span>
            <Button
              color="blue"
              content={translation.validate[language]}
              disabled={checkedPlans.length < 1 ? true : false}
              onClick={() => {
                validateRevitSetup();
              }}
            ></Button>
          </span>
        </div>
      </div>
    </>
  );
};

export default ReperageParametrageRevit;
