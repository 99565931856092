import moment from "moment";
import { Country, SelectionMethod } from "../assets/constants";
import Element from "./Element.model";
import Item from "./Item.model";

// Contains Project data
export default interface Project {
    name?: string;
    country: Country;
    creation_date: any;
    modification_date: any;
    selectionMethod?: SelectionMethod;
    allAvailableElements: Element[];
    validatedItems: Item[];
    items: Item[];
    inEdition: boolean;
    looseElements: Element[];
}

export const initializedProject: Project = {
    name: "",
    country: Country.UK,
    selectionMethod: undefined,
    creation_date: moment().format("MM/DD/YYYY"),
    modification_date: moment().format("MM/DD/YYYY"),
    items: [],
    inEdition: false,
    allAvailableElements: [],
    validatedItems: [],
    looseElements: [],
};
