import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import addIcon from "../../../assets/add-icon.png";
import checkMark from "../../../assets/check-mark.png";
import savedIcon from "../../../assets/save-icon.png";
import "./body.scss";

function Body({ projectMenu, newProject, t }: IProps): ReactElement {
    return (
        <div id="finish-body-container">
            <div className="px-4 py-2 h-100">
                <div className="row h-100 d-flex flex-column justify-content-center">
                    <div id="finish-body-inner-container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img
                                    id="check-mark"
                                    className="xlarge-btn"
                                    src={checkMark}
                                    alt={"check"}
                                ></img>
                                <span className="finish-body-inner-container-header-text">
                                    {t("DATA_TRANFERT_SUCCESSFULL")}
                                </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <Button
                                    id="saved-projects-button"
                                    className="ml-2 finish-button"
                                    variant="contained"
                                    onClick={(event) => {
                                        projectMenu();
                                    }}
                                >
                                    <div>
                                        <img
                                            id="saved-projects-icon"
                                            src={savedIcon}
                                            alt={"check"}
                                        ></img>
                                        <div className="finish-button-inner-div">
                                            <span>{t("SAVED_PROJECTS")}</span>
                                        </div>
                                    </div>
                                </Button>
                                <Button
                                    id="new-project-button"
                                    className="ml-2 finish-button"
                                    variant="contained"
                                    onClick={(event) => {
                                        newProject();
                                    }}
                                >
                                    {" "}
                                    <div>
                                        <img
                                            id="new-project-icon"
                                            src={addIcon}
                                            alt={"check"}
                                        ></img>
                                        <div className="finish-button-inner-div">
                                            <span>{t("NEW_PROJECT")}</span>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & { projectMenu: Function; newProject: Function };

export default withTranslation(["pam"])(Body);
