import {
    LOAD_SYSTEMS,
    LOAD_SYSTEMS_SUCCESS,
    LOAD_SYSTEMS_ERROR,
    LOAD_SYSTEMS_DETAILS,
    LOAD_SYSTEMS_DETAILS_SUCCESS,
    LOAD_SYSTEMS_DETAILS_ERROR, SystemsActionTypes, LOAD_SYSTEMS_DETAILS_MORE, FILTER_SYSTEMS, DISPLAY_FAVORITE, RESTORE_SYSTEMS_DETAILS, DISPLAY_SYSTEMS, LOAD_EXISTING_SYSTEMS, DELETE_SEARCH_KEY_WORD, ADD_SEARCH_KEY_WORD, RESET_SEARCH_KEY_WORD
} from './types';
import { ProductDetailData, ProductMeta } from "../../../../../RevitJS/Types/BddTypes";
import { CustomParameter } from '../../../../../RevitJS/Types/RevitTypes';
import { FiltersActionTypes } from '../filter/types';



export const loadSystemsRequest = (): SystemsActionTypes => ({
    type: LOAD_SYSTEMS,
});

export const loadSystemsSuccess = (systems: ProductMeta[]): SystemsActionTypes => ({
    type: LOAD_SYSTEMS_SUCCESS,
    systems: systems,
});

export const loadSystemsError = (): SystemsActionTypes => ({
    type: LOAD_SYSTEMS_ERROR,
});


export const loadSystemsDetailsRequest = (): SystemsActionTypes => ({
    type: LOAD_SYSTEMS_DETAILS,
});

export const loadSystemsDetailsSuccess = (currentSystemsDetailsPartitions: ProductDetailData[], currentSystemsDetailsPlafonds: ProductDetailData[], currentSystemsDetailsLiningWalls: ProductDetailData[], currentSystemsDetailsGainesTechniques: ProductDetailData[], systemsMetaDataPartitions: ProductMeta[], systemsMetaDataPlafonds: ProductMeta[], systemsMetaDataLiningWalls: ProductMeta[], systemsMetaDataGainesTechniques: ProductMeta[], systemType: number): SystemsActionTypes => ({
    type: LOAD_SYSTEMS_DETAILS_SUCCESS,
    currentSystemsDetailsPartitions: currentSystemsDetailsPartitions,
    currentSystemsDetailsPlafonds: currentSystemsDetailsPlafonds,
    currentSystemsDetailsLiningWalls: currentSystemsDetailsLiningWalls,
    currentSystemsDetailsGainesTechniques: currentSystemsDetailsGainesTechniques,
    systemsMetaDataPartitions: systemsMetaDataPartitions,
    systemsMetaDataPlafonds: systemsMetaDataPlafonds,
    systemsMetaDataLiningWalls: systemsMetaDataLiningWalls,
    systemsMetaDataGainesTechniques: systemsMetaDataGainesTechniques,
    systemType: systemType
});

export const restoreSystemDetails = (currentSystemsDetails: ProductDetailData[], systemsMetaData: any[], systemType: number): SystemsActionTypes => ({
    type: RESTORE_SYSTEMS_DETAILS,
    currentSystemsDetails: currentSystemsDetails,
    systemsMetaData: systemsMetaData,
    systemType: systemType
});

export const loadSystemsDetailsError = (): SystemsActionTypes => ({
    type: LOAD_SYSTEMS_DETAILS_ERROR,
});

export const displaySystem = (systemsDetails: ProductDetailData[]): SystemsActionTypes => ({
    type: DISPLAY_SYSTEMS,
    systemsDetails: systemsDetails
});

export const loadSystemsDetailsMore = (systemsDetails: ProductDetailData[]): SystemsActionTypes => ({
    type: LOAD_SYSTEMS_DETAILS_MORE,
    systemsDetails: systemsDetails,
});

export const filterSystemsRequest = (currentSystemsDetails: ProductDetailData[]): SystemsActionTypes => ({
    type: FILTER_SYSTEMS,
    currentSystemsDetails: currentSystemsDetails
});

export const AddSearchKeyWordAction = (searchWord: string): SystemsActionTypes => ({
    type: ADD_SEARCH_KEY_WORD,
    searchWord: searchWord
});

export const DeleteSearchKeyWordAction = (searchWordIndex: number): SystemsActionTypes => ({
    type: DELETE_SEARCH_KEY_WORD,
    searchWordIndex: searchWordIndex
});

export const ResetSearchKeyWordAction = (): FiltersActionTypes => ({
    type: RESET_SEARCH_KEY_WORD
});

export const displayFavoriteRequest = (systemsDetails: ProductDetailData[]): SystemsActionTypes => ({
    type: DISPLAY_FAVORITE,
    systemsDetails: systemsDetails
});

export const loadExistingSystems = (existingSystems: CustomParameter[]): SystemsActionTypes => ({
    type: LOAD_EXISTING_SYSTEMS,
    existingSystems: existingSystems
});
