import React, { Component } from "react";
import { Container, Button, Loader, Dimmer } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { DrawStore } from "../Reducers";
import { connect } from "react-redux";
import { setSelectionTree, initGroupSelector } from "../Actions";
import { Routes } from "./root";
import { formatProjectTree, scheduleHandler } from "../Helpers";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props {
    Icon: string;
    name: string;
    setRoute: any;
    setSelectionTree: any;
    initGroupSelector: any;
}

interface State {
    processing: boolean;
}

export class SelectionLanding extends Component<
    Props & WithTranslation,
    State
> {
    state = {
        processing: false,
    };

    // public static defaultProps={
    //     wording:{
    //         selection:{
    //             "French": "Sélection",
    //             "English": "Selection",
    //             "German": "Auswahl",
    //             "Italian": "Selezione"
    //         },
    //         manualSelection:{
    //             "French": "Sélection Manuelle",
    //             "English": "Manual Selection",
    //             "German": "Manuelle Auswahl",
    //             "Italian": "Selectionz manuale"
    //         },
    //         groupSelection:{
    //             "French": "Sélection groupée",
    //             "English": "Group selection",
    //             "German": "Gruppenauswahl",
    //             "Italian": "Selezione del gruppo"
    //         },
    //         deletePending:{
    //             "French": "Suppression en cours",
    //             "English": "Deleting",
    //             "German": "Löschen",
    //             "Italian": "La cancellazione in corso"
    //         },
    //         wall: {
    //             "French": "Murs",
    //             "English": "Walls",
    //             "German": "Wände",
    //             "Italian": "Muri"
    //         },
    //         level: {
    //             "French": "Niveaux",
    //             "English": "Levels",
    //             "German": "Ebenen",
    //             "Italian": "Livelli"
    //         },
    //         wallType: {
    //             "French": "Types de murs",
    //             "English": "Wall types",
    //             "German": "Wandtyp",
    //             "Italian": "Tipo de muro"
    //         },
    //         thickness: {
    //             "French": "Epaisseur (mm)",
    //             "English": "Thickness (mm)",
    //             "German": "Dicke (mm)",
    //             "Italien": "Spessore (mm)"
    //         },
    //         Id: {
    //             "French": "Id du mur",
    //             "English": "Wall Ids",
    //             "German": "Wand-IDs",
    //             "Italian": "ID de muri"
    //         },
    //         height: {
    //             "French": "Hauteur (mm)",
    //             "English": "Height (mm)",
    //             "German": "Höhe (mm)",
    //             "Italian": "Altezza (mm)"
    //         },
    //         length: {
    //             "French": "Longueur (mm)",
    //             "English": "Length (mm)",
    //             "German": "Länge (mm)",
    //             "Italian": "Lunghezza (mm)",

    //         },
    //         surface: {
    //             "French": "Surface (m²)",
    //             "English": "Surface (m²)",
    //             "German": "Oberfläche (m²)",
    //             "Italian": "Superficie (m²)"
    //         },
    //         fileName: {
    //             "French": "Nomenclature des murs",
    //             "English": "Wall schedules",
    //             "German": "Wandinventar",
    //             "Italian": "Nomenclatura murale"
    //         },
    //         schedulesPending: {
    //             "French": "Métrés en cours",
    //             "English": "Schedules pending",
    //             "German": "Wandinventar in Bearbeitung",
    //             "Italian": "Nomenclatura in corso"
    //         },

    //     }
    // }

    componentDidMount = () => {
        api.windowsHandler.resizeWindow(600, 200);
    };

    manualSelectionHandler = async () => {
        await api.windowsHandler.hideWindow();
        const tree = await api.selection.manualSelection("wall");

        //window.addEventListener("blur", () => api.windowsHandler.closeWindow());
        let filteredTree = formatProjectTree(tree);
        await api.windowsHandler.showWindow();
        this.setState({
            processing: true,
        });
        
        await scheduleHandler(filteredTree);
        api.windowsHandler.closeWindow()
    };

    groupSelectionHandler = async () => {
        let tree = await api.selection.elementsByLevelAndType("wall");
        this.props.initGroupSelector(tree);
        this.props.setRoute(Routes.GROUP_SELECTION);
    };

    render() {
        const { t } = this.props;

        if (this.state.processing) {
            return (
                <Dimmer active={true}>
                    <Loader content={t("schedulesPending")}></Loader>
                </Dimmer>
            );
        }
        return (
            <div id="wallSchedules-selectionLanding-container">
                <FunctionalityHeader
                    Icon={this.props.Icon}
                    name={t("selection")}
                    width={100}
                    height={40}
                />
                <Container textAlign="center" style={{ margin: "auto" }}>
                    <Button
                        onClick={this.manualSelectionHandler}
                        primary
                        size="big"
                        style={{ marginRight: "5%" }}
                    >
                        {t("manualSelection")}
                    </Button>
                    <Button
                        onClick={this.groupSelectionHandler}
                        primary
                        size="big"
                        style={{ marginLeft: "5%" }}
                    >
                        {t("groupSelection")}
                    </Button>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        Icon: state.functionalityIcon,
        name: ownProps.name,
        setRoute: ownProps.setRoute,
    };
};

export default connect(mapStateToProps, {
    setSelectionTree,
    initGroupSelector,
})(withTranslation("riggibs")(SelectionLanding));

// export const wording = {
//     select: {
//         "French": "Sélectionner...",
//         "English": "Select a system..."
//     },
//     errors: {
//         "French": "Erreurs",
//         "English": "Errors"
//     },
//     errorsHeader: {
//         "French": "Veuillez corriger les erreurs suivantes avant de pouvoir calepiner : ",
//         "English": "Please fix the following errors before to trigger layout: "
//     },
//     validate: {
//         "French": "Valider",
//         "English": "Validate"
//     },
//     selection: {
//         "French": "Sélection",
//         "English": "Selection"
//     },
//     mapping: {
//         "French": "Table de correspondance des solutions",
//         "English": "Solution mapping"
//     },
//     back: {
//         "French": "Retour",
//         "English": "Back"
//     },
//     revitSystemType: {
//         "French": "Type de système Revit",
//         "English": "Revit System Type"
//     },
//     systemChoice: {
//         "French": "Choix du système à calepiner",
//         "English": "Layout system selection"
//     },
//     actions: {
//         "French": "Actions",
//         "English": "Actions"
//     },
//     sideA: {
//         "French": "Parement A",
//         "English": "Side A"
//     },
//     sideB: {
//         "French": "Parement B",
//         "English": "Side B"
//     },
//     lining: {
//         "French": "Doublage",
//         "English": "Lining"
//     },
//     SAA: {
//         "French": "SAA",
//         "English": "SAA"
//     },
//     SAD: {
//         "French": "SAD",
//         "English": "SAD"
//     },
//     framesSpace: {
//         "French": "Entraxe",
//         "English": "Frame spacing"
//     },
//     plateLength: {
//         "French": "Largeur d'une plaque (mm)",
//         "English": "Plate length"
//     },
//     resetFilters: {
//         French: "Réinitialiser les filtres",
//         English: "Reset filters"
//     },

//     details: {
//         "French": "Détails",
//         "English": "Details"
//     },
//     configuration: {
//         "French": "Configuration",
//         "English": "Configuration"
//     },
//     loadingFamilies: {
//         "French": "Chargement des familles...",
//         "English": "Loading Families"
//     },
//     processing: {
//         "French": "Calepinage en cours: ",
//         "English": "Layout in progress: "
//     },
//     fileName: {
//         "French": "Nomenclature des murs",
//         "English": "Wall schedules"
//     },
//     wall: {
//         "French": "Murs",
//         "English": "Walls"
//     },
//     level: {
//         "French": "Niveaux",
//         "English": "Levels"
//     },
//     wallType: {
//         "French": "Types de murs",
//         "English": "Wall types"
//     },
//     thickness: {
//         "French": "Epaisseur (mm)",
//         "English": "Thickness (mm)"
//     },
//     Id: {
//         "French": "Id du mur",
//         "English": "Wall Ids"
//     },
//     height: {
//         "French": "Hauteur (mm)",
//         "English": "Height (mm)"
//     },
//     length: {
//         "French": "Longueur (mm)",
//         "English": "Length (mm)"
//     },
//     surface: {
//         "French": "Surface (m²)",
//         "English": "Surface (m²)"
//     },
// }
