import { ThunkAction } from "redux-thunk";
import { FavoriteActionTypes } from "./types";
import {
  addSystemToFavoriteAction,
  loadSystemsFavorites,
  removeSystemFromFavoriteAction,
  selectFavoriteSystemType,
  clearAllFavoriteAction,
} from "./actions";
import { DrawStore } from "../reducers";
import { ProductDetailData } from "../../../../../RevitJS/Types/BddTypes";
import { ID } from "../../../../../RevitJS/Types/RevitTypes";
import {
  AddFavorite,
  getFavorites,
  RemoveFavorite,
  clearFavoris,
} from "../../services/projects.service.local";
import { CLOISONS_ID, DOUBLAGES_ID, GAINES_ID, PLAFONDS_ID } from "../../assets/constants";
import * as productsService from "../../services/project.service";
import { getSystemTypeByWorksNameObject } from "../../../Common/utils/attributes.injection.helpers";

type Effect = ThunkAction<any, DrawStore, any, FavoriteActionTypes>;

export const clearAllFavorite =
  (): Effect => (dispatch: any, getState: any) => {
    dispatch(clearAllFavoriteAction());
    clearFavoris();
  };

export const addSystemToFavorite =
  (system: ProductDetailData): Effect =>
    (dispatch: any, getState: any) => {
      let systemTypeID = getSystemTypeByWorksNameObject(system);
      let currentSystemTypeID = getState().systems.systemTypeID;
      dispatch(
        addSystemToFavoriteAction(system, currentSystemTypeID, systemTypeID)
      );
      AddFavorite({
        oid: system.oid,
        systemTypeID: systemTypeID,
      });
    };

export const removeSystemFromFavorite =
  (system: ProductDetailData): Effect =>
    (dispatch: any, getState: any) => {
      let systemTypeID = getSystemTypeByWorksNameObject(system);
      let currentSystemTypeID = getState().systems.systemTypeID;
      let index = 0;
      if (systemTypeID === CLOISONS_ID)
        index = getState()
          .favorite.data.partitionsDetails.map(function (x: any) {
            return x.oid;
          })
          .indexOf(system.oid);
      if (systemTypeID === PLAFONDS_ID)
        index = getState()
          .favorite.data.ceilingsDetails.map(function (x: any) {
            return x.oid;
          })
          .indexOf(system.oid);
      if (systemTypeID === DOUBLAGES_ID)
        index = getState()
          .favorite.data.liningWallsDetails.map(function (x: any) {
            return x.oid;
          })
          .indexOf(system.oid);
      if (systemTypeID === GAINES_ID)
        index = getState()
          .favorite.data.gainesTechniquesDetails.map(function (x: any) {
            return x.oid;
          })
          .indexOf(system.oid);
      dispatch(
        removeSystemFromFavoriteAction(index, currentSystemTypeID, systemTypeID)
      );
      RemoveFavorite({
        oid: system.oid,
        systemTypeID: systemTypeID,
      });
    };

export const changeFavoriteSystemType =
  (): Effect => (dispatch: any, getState: any) => {
    let { data } = getState().favorite;
    let { systemTypeID } = getState().systems;
    if (systemTypeID === DOUBLAGES_ID)
      dispatch(selectFavoriteSystemType(data.liningWallsDetails));
    else if (systemTypeID === CLOISONS_ID)
      dispatch(selectFavoriteSystemType(data.partitionsDetails));
    else if (systemTypeID === PLAFONDS_ID)
      dispatch(selectFavoriteSystemType(data.ceilingsDetails));
    else if (systemTypeID === GAINES_ID)
      dispatch(selectFavoriteSystemType(data.gainesTechniquesDetails));
  };

export const checkFavoriteSystem =
  (oid: ID): Effect =>
    (dispatch: any, getState: any) => {
      let { data } = getState().favorite;
      return (
        data.liningWallsDetails.find((item: any) => oid === item.oid) ||
        data.partitionsDetails.find((item: any) => oid === item.oid) ||
        data.ceilingsDetails.find((item: any) => oid === item.oid) ||
        data.gainesTechniquesDetails.find((item: any) => oid === item.oid)
      );
    };

export const loadFavorites =
  (config: any): Effect =>
    async (dispatch: any, getState: any) => {
      let { systemTypeID, systems } = getState().systems;

      await getFavorites().then((favoris) => {
        productsService
          .getSystemDetailsById(
            favoris.map((r) => r.oid),
            config
          )
          .then((results) => {
            let currentSystemDetail = results.data.objects.map((sys) => {
              return { ...sys, filterFields: {} } as unknown;
            }) as ProductDetailData[];
            currentSystemDetail = currentSystemDetail.map((sys) => ({
              ...sys,
              thumbnailUrl: systems.find((system: any) => system.oid === sys.oid)
                ?.thumbnailUrl,
            })) as ProductDetailData[];
            if (favoris && favoris.length > 0) {
              let dataFavoris = {
                liningWallsDetails: currentSystemDetail.filter((system) =>
                  favoris.find(
                    (r) => r.oid === system.oid && r.systemTypeID === DOUBLAGES_ID
                  )
                ),
                partitionsDetails: currentSystemDetail.filter((system) =>
                  favoris.find(
                    (r) => r.oid === system.oid && r.systemTypeID === CLOISONS_ID
                  )
                ),
                ceilingsDetails: currentSystemDetail.filter((system) =>
                  favoris.find(
                    (r) => r.oid === system.oid && r.systemTypeID === PLAFONDS_ID
                  )
                ),
                gainesTechniquesDetails: currentSystemDetail.filter((system) =>
                  favoris.find(
                    (r) => r.oid === system.oid && r.systemTypeID === GAINES_ID
                  )
                ),
              };
              console.log("Favourites: ",dataFavoris);
              let favoritesCount =
                dataFavoris.liningWallsDetails.length +
                dataFavoris.partitionsDetails.length +
                dataFavoris.ceilingsDetails.length +
                dataFavoris.gainesTechniquesDetails.length;
              dispatch(
                loadSystemsFavorites(dataFavoris, favoritesCount, systemTypeID)
              );
            }
          });
      });
    };
