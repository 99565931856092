import React, { useState, useContext } from "react";
import {
  Dimmer,
  Loader,
  Button,
  Modal,
  Form,
  Radio,
  Grid,
  Container
} from "semantic-ui-react";
import { Routes, ElementType, QuantiType } from "../../Util/type";
import {
  QuantityContext,
  IQuantityContextType,
} from "../../Context/quantityContext";
import { useSelector, useDispatch } from "react-redux";
import { MetresStore } from "../../Reducers";

export const ElementSelector = () => {
  const [raiseWarning, setRaiseWarning] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [radioValue, setRadioValue] = useState<string>("");
  const reduxState = useSelector((state: MetresStore) => state);
  const { element, quntiType, setRoot, setElementType } = useContext(
    QuantityContext
  ) as IQuantityContextType;
  const dispatch = useDispatch();

  return (
    <Container
      className="grid-container"
      style={{ paddingTop: 15, height: "calc(100% - 150px)" }}
    >
      <Grid className="custom-ratio-types">
        <Grid.Row style={{ height: "70vh", alignItems: "center" }}>
          <Grid.Column
            width={15}
            style={{
              justifyContent: "center",
              width: "100% !important",
              display: "flex",
            }}
          >
            <Form>
              <Form.Field style={{ justifyContent: "center", display: "flex" }}>
                <Radio
                  label="Murs"
                  name="radioGroup"
                  value={ElementType.WALL}
                  checked={element === ElementType.WALL}
                  onChange={() => setElementType(ElementType.WALL)}
                />
              </Form.Field>
              <Form.Field style={{ justifyContent: "center", display: "flex" }}>
                <Radio
                  label="Plafonds"
                  name="radioGroup"
                  value={ElementType.CEILING}
                  checked={element === ElementType.CEILING}
                  onChange={() => setElementType(ElementType.CEILING)}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>


        <Grid className="middel-full-width">
          <Button color="orange" onClick={() => setRoot(Routes.ROOT)}>
            Annuler
          </Button>
          <Button
            primary
            onClick={() => setRoot(Routes.CUSTOMERATIO)}
            disabled={element.length === 0 ? true : false}
          >
            Continuer
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
