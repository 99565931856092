import React from "react";
import { Segment } from "semantic-ui-react";

interface Props {
  heading: string;
}

export const SubHeader = (props: Props) => {
  const { heading } = props;
  return (
    <Segment
      basic
      style={{ width: "100%", marginBottom: "0px", paddingBottom: "0px" }}
    >
      <div
        style={{
          padding: "5px",
          height: "25px",
          backgroundColor: "rgb(14,110,184)",
          color: "white",
          display: "flex",
          alignItems: "center",
          borderBottom: "0.5px solid white",
        }}
      >
        <div>{heading}</div>
      </div>
    </Segment>
  );
};

export default SubHeader;
