import { ElementsTree, Language } from "../../../../RevitJS/Types/RevitTypes";
import {
  TypeData,
  LevelData,
  MappingRow,
} from "../../../../RevitJS/Types/StoreTypes";

export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_SELECTION_TREE = "SET_SELECTION_TREE";
export const INIT_GROUP_SELECTOR = "SET_GROUP_SELECTOR";
export const SELECT_TYPE = "SELECT_TYPE";
export const SELECT_LEVEL = "SELECT_LEVEL";

export const INIT_GROUP_SELECTOR_CEILING = "INIT_GROUP_SELECTOR_CEILING";
export const SELECT_TYPE_CEILING = "SELECT_TYPE_CEILING";
export const SELECT_LEVEL_CEILING = "SELECT_LEVEL_CEILING";
export const SET_SELECTION_TREE_CEILING = "SET_SELECTION_TREE_CEILING";

export interface InitLanguageAction {
  type: typeof INIT_LANGUAGE;
  language: Language;
}

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: Language;
}

export type LanguageAction = InitLanguageAction | SetLanguageAction;

export interface SetSelectionTreeAction {
  type: typeof SET_SELECTION_TREE;
  tree: ElementsTree;
}

export interface SetSelectionTreeCeilingAction {
  type: typeof SET_SELECTION_TREE_CEILING;
  tree: ElementsTree;
}

export interface InitGroupSelectorAction {
  type: typeof INIT_GROUP_SELECTOR;
  tree: ElementsTree;
}

export interface SelectLevelAction {
  type: typeof SELECT_LEVEL;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectTypeAction {
  type: typeof SELECT_TYPE;
  typesData: TypeData[];
  selectedTypes: string[];
}

export interface InitGroupSelectorCeilingAction {
  type: typeof INIT_GROUP_SELECTOR_CEILING;
  tree: ElementsTree;
}

export interface SelectLevelCeilingAction {
  type: typeof SELECT_LEVEL_CEILING;
  typesData: TypeData[];
  levelsData: LevelData[];
  selectedLevels: string[];
  selectedTypes: string[];
}

export interface SelectTypeCeilingAction {
  type: typeof SELECT_TYPE_CEILING;
  typesData: TypeData[];
  selectedTypes: string[];
}

export type SelectorCeilingAction =
  | InitGroupSelectorCeilingAction
  | SelectLevelCeilingAction
  | SelectTypeCeilingAction;


export type SelectionTreeAction = SetSelectionTreeAction;

export type SelectionTreeCeilingAction = SetSelectionTreeCeilingAction

export type SelectorAction =
  | InitGroupSelectorAction
  | SelectLevelAction
  | SelectTypeAction;
