import { Language, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import {
  SET_SELECTION_TREE,
  SelectionTreeAction,
  SelectorAction,
  INIT_GROUP_SELECTOR,
  SELECT_LEVEL,
  SELECT_TYPE,
  INIT_GROUP_SELECTOR_CEILING,
  SELECT_LEVEL_CEILING,
  SelectorCeilingAction,
  SELECT_TYPE_CEILING,
  SelectionTreeCeilingAction,
} from "../Actions/types";

import { TypeData, LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { levelData, elementTypes } from "../../../../RevitJS/Helpers";
import { FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";
import { INIT_LANGUAGE, LanguageAction } from "../../../../Actions/types";
import { SET_SELECTION_TREE_CEILING } from "../../Draw2/Actions/types";

export const language = (
  language: Language = "German",
  action: LanguageAction
): Language => {
  switch (action.type) {
    case INIT_LANGUAGE:
      return action.language;
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/pluginIcons/riggibs-logo-icon.png",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const selectionTree = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeAction | SelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE:
      return action.tree;
    case INIT_GROUP_SELECTOR:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const selectionTreeCeiling = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeCeilingAction | SelectorCeilingAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE_CEILING:
      return action.tree;
    case INIT_GROUP_SELECTOR_CEILING:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const levelsData = (
  levelsData: LevelData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const typesData = (
  typesData: TypeData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL:
      return action.typesData;
    case SELECT_TYPE:
      return action.typesData;
    default:
      return typesData;
  }
};

export const selectorType = (selectorType: string = "wall", action: any) => {
  switch (action.type) {
    default:
      return selectorType;
  }
};

export const selectedTypes = (
  selectedTypes: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedTypes;
    case SELECT_TYPE:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedTypesCeilings = (
  selectedTypes: string[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case SELECT_LEVEL_CEILING:
      return action.selectedTypes;
    case SELECT_TYPE_CEILING:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedLevels = (
  selectedLevels: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const filterTypeEnabled = (
  filterTypeEnabled: FilterTypeEnabled = "partitionsWalls",
  action: any
) => {
  switch (action.type) {
    default:
      return filterTypeEnabled;
  }
};

export const levelsDataCeilings = (
  levelsData: LevelData[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR_CEILING:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL_CEILING:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const typesDataCeilings = (
  typesData: TypeData[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR_CEILING:
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL_CEILING:
      return action.typesData;
    case SELECT_TYPE_CEILING:
      return action.typesData;
    default:
      return typesData;
  }
};

export const selectedLevelsCeilings = (
  selectedLevels: string[] = [],
  action: SelectorCeilingAction
) => {
  switch (action.type) {
    case SELECT_LEVEL_CEILING:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const reducers = combineReducers({
  language,
  functionalityIcon,
  selectionTree,
  levelsData,
  typesData,
  selectedLevels,
  selectedTypes,
  selectionTreeCeiling,
  levelsDataCeilings,
  typesDataCeilings,
  selectedLevelsCeilings,
  selectedTypesCeilings,
});

export interface DrawStore {
  language: Language;
  functionalityIcon: string;
  selectionTree: ElementsTree;
  levelsData: LevelData[];
  typesData: TypeData[];
  selectedLevels: string[];
  selectedTypes: string[];
  selectionTreeCeiling: ElementsTree;
  levelsDataCeilings: LevelData[];
  typesDataCeilings: TypeData[];
  selectedLevelsCeilings: string[];
  selectedTypesCeilings: string[];
}
