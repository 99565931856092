import React, { useEffect, useState } from "react";
import { MyConfig } from "../../../../Helper";
import { Routes } from "./root";
import { SelectionLanding } from "./SelectionLanding";
import GroupSelector from "./GroupSelector";

interface Props {
  route: string;
  setRoute: any;
  // initLanguage: any;
}

export const AppBody = ({ route, setRoute }: Props) => {
  const [selection, setSelection] = useState<any>(null);

  const onGroupValidate = (elements: any, floors: any) => {
    setSelection((prevSelection: any) => {
      return { ...prevSelection, Floors: floors, SelectionDetails: elements };
    });
    console.log(selection);
    return selection;
  };

  const onGroupBack = () => {
    setRoute("ROOT");
  };

  switch (route) {
    case Routes.ROOT:
      return <SelectionLanding route={route} setRoute={setRoute} />;
    case Routes.GROUP_SELECTION:
      return <GroupSelector onBack={onGroupBack} />;

    default:
      return null;
  }
};

export default AppBody;
