import { Checkbox } from "@material-ui/core";
import React from "react";
import { Grid, Header, Menu, Column } from "semantic-ui-react";
import { TypeItems } from "./TypeItems";
import TypesToogler from "./TypesToogler";
import "./selectorStyle.scss";
import { selectedLevels } from "../../Plugins/PlacoBIM/Draw2/Reducers";
import {filter} from "lodash";

export class TypesColumn extends React.Component {
    render() {

        let allChk  = filter(this.props.typesData, { Checked: true });

        return (
            <Grid.Column>
                <Header as="h3" textAlign="center" color={this.props.color}>
                    {this.props.columnTitle}
                </Header>
                    <Menu
                        style={{
                            height: "calc(100vh - 260px)",
                            overflowY: "auto",
                            margin: "auto",
                        }}
                        vertical
                        size="small"
                        fluid
                    >
                        <TypeItems
                            selectedLevels={this.props.selectedLevels}
                            selectType={this.props.selectType}
                            typesData={this.props.typesData}
                            color={this.props.color}
                        />
                    </Menu>

               

                {this.props.selectAllTypes && (
                    <div
                        id="select-all-checkbox-div"
                        className={`d-flex justify-content-center checkbox-color-${this.props.color}`}
                    >
                        <Checkbox
                            disabled={this.props.selectedLevels.length === 0}
                            onChange={(e) => {
                                this.props.selectAllTypes(
                                    e.currentTarget.checked
                                );
                            }}
                            checked={this.props.typesData.length === allChk.length}
                            style={{ maxWidth: "80%" }}
                        />
                    </div>
                )}
            </Grid.Column>
        );
    }
}
