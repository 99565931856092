import { orderBy, sortBy } from "lodash";
import { LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { setGroupTreesAction, selectLevelAction } from "../Actions/types";

type wallLevelsDataAction = setGroupTreesAction | selectLevelAction;

export const wallLevelsData = (
    levelsData: LevelData[] = [],
    action: wallLevelsDataAction
  ): LevelData[] => {
    switch (action.type) {
      case "SET_GROUP_TREES":
        return orderBy(action.wallTree.Tree.map(level => ({
          Name: level.Level.Name,
          Checked: false,
          Elevation: level.Level.Elevation
        })), ['Elevation'], ['asc']) ;
      case "SELECT_LEVEL":
          if(action.selectorType === "wall"){
              return action.levelsData
          }
          return levelsData;
      default:
        return levelsData;
    }
  };