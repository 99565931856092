import React, { Component } from "react";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import { Routes } from "./root";
import SelectionLanding from "./SelectionLanding";
import GroupSelector from "./GroupSelector";
import { initLanguage } from "../Actions";
import { api } from "../../../../RevitJS/API";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";

interface Props {
  route: Routes;
  setRoute: any;
  initLanguage: any;
  config: any;
  setConfig:any;
}

export class AppBody extends Component<Props> {
  componentDidMount = async () => {
    this.props.initLanguage();
    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  render() {
    switch (this.props.route) {
      case Routes.ROOT:
        return (
          <SelectionLanding
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      case Routes.GROUP_SELECTION:
        return (
          <GroupSelector
            routes={this.props.route}
            setRoute={this.props.setRoute}
          />
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {  
  return {
    setRoute: ownProps.setRoute,
    route: ownProps.route,
  };
};

export default connect(mapStateToProps, {
  initLanguage,
  setConfig
})(AppBody);
