import { find, map } from "lodash";
import * as React from "react";
import { createContext, useState, useEffect } from "react";
import { MyConfig } from "../../../Helper";


export interface IReengineeringContextType {
  selections: any;
  config: any;
  placoProducts: any;
  selectSelection: string[];
  setSelectedSelection: React.Dispatch<any>;
  processWalls: any[];
  setProcessWalls: React.Dispatch<any>;
  unitConversionArray: any[];
  setUnitConversionArray: any;
  getUnitConversionArray: any;
}

export const ReengineeringContext = createContext<IReengineeringContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const ReengineeringProvider: React.FC<Props> = ({ children }) => {
  const [selections, setSelection] = useState<any>(null);
  const [config, setConfig] = useState<any>(null);
  const [placoProducts, setPlacoProducts] = useState<any>(null);
  const [selectSelection, setSelectedSelection] = useState<string[]>([]);
  const [processWalls, setProcessWalls] = useState<any[]>([]);
  const [unitConversionArray, setUnitArray] = useState<any[]>([]);

  useEffect(() => {
    async function initialLoader() {
      const cng = await MyConfig();
      setConfig(cng);
    }

    initialLoader();
  }, []);

  const setUnitConversionArray = (array: any, element: any) => {
    setUnitArray((prevArray: any[]) => {
      map(array, (unitObj: any, unitObjIndex: any) => {
        if (
          !find(prevArray, {
            alternativeUnit: unitObj.alternativeUnit,
            ub: unitObj.ub,
            element: element,
          })
        ) {
          prevArray = [...prevArray, { ...unitObj, element: element }];
        }
      });
      return prevArray;
    });
  };

  const getUnitConversionArray = () => {
    return unitConversionArray;
  };

  return (
    <ReengineeringContext.Provider
      value={{
        selections,
        config,
        placoProducts,
        selectSelection,
        setSelectedSelection,
        processWalls,
        setProcessWalls,
        unitConversionArray,
        setUnitConversionArray,
        getUnitConversionArray,
      }}
    >
      {children}
    </ReengineeringContext.Provider>
  );
};

export default ReengineeringProvider;
