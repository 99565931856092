import { Checkbox } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import React, { UIEvent, useReducer, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import AnimatedCheckbox from "../../common/animated-checkbox/AnimatedCheckbox";
import "./body.scss";
import SystemName from "./systemName/SystemName";

function Body({
    systemTypesList = [],
    systemNamesList,
    setAllSystemTypeSelected,
    handleSystemTypeSelected,
    setAllSystemNamesSelected,
    handleSystemNameSelected,
    t,
}: IProps) {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [nbrElementsToShow, setNbrElementsToShow] = useState(20);

    const handleScroll = (e: UIEvent) => {
        const closeToBottom =
            e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                e.currentTarget.clientHeight ===
            0;
        if (closeToBottom) {
            setNbrElementsToShow(nbrElementsToShow + 20);
        }
    };

    return (
        <div id="system-name-body-container">
            <div className="px-4 py-2">
                <div className="row">
                    {/* <div className="col-12 text-center">
                        <img
                            src={systemTypeImg}
                            style={{ height: "2.8em" }}
                            alt="System name"
                            className="round m-2"
                        />
                        <span className="m-2" style={{ color: "orange" }}>
                            {t("SYSTEM_NAME_SELECTED")}
                        </span>
                    </div> */}
                    <div className="offset-1 col-4">
                        <div className="text-center my-2">
                            <span>
                                <strong>
                                    {t("CANALIZATION_SYSTEM").toUpperCase()}
                                </strong>
                            </span>
                        </div>
                        <div
                            className="item-selection-table-div"
                            onScroll={handleScroll}
                        >
                            <table className="w-100">
                                <tbody>
                                    {systemTypesList.map(
                                        (item: any, itemIndex: number) => {
                                            return (
                                                <tr
                                                    key={`${item.value}-${itemIndex}`}
                                                >
                                                    <td
                                                        style={{ width: "10%" }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                item.selected
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleSystemTypeSelected(
                                                                    event
                                                                        .currentTarget
                                                                        .checked,
                                                                    itemIndex
                                                                );
                                                            }}
                                                        ></Checkbox>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "90%",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {item.value}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div
                            style={{
                                width: "max-content",
                                margin: "10px auto",
                            }}
                        >
                            <AnimatedCheckbox
                                label={""}
                                changed={(event: any) => {
                                    setAllSystemTypeSelected(
                                        event.currentTarget.checked
                                    );
                                }}
                            ></AnimatedCheckbox>
                        </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-center">
                        <DoubleArrowIcon
                            className="m-auto"
                            style={{ width: "3em", height: "3em" }}
                        ></DoubleArrowIcon>
                    </div>
                    <div className="col-4">
                        <div className="text-center my-2">
                            <span>
                                <strong>
                                    {t("SYSTEM_NAME").toUpperCase()}
                                </strong>
                            </span>
                        </div>
                        <SystemName
                            systemNamesList={systemNamesList}
                            setAllSystemNamesSelected={
                                setAllSystemNamesSelected
                            }
                            handleSystemNameSelected={handleSystemNameSelected}
                        ></SystemName>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    systemTypesList: { value: string; selected: boolean }[];
    systemNamesList: { value: string; systemType: string; selected: boolean }[];
    setAllSystemTypeSelected: Function;
    handleSystemTypeSelected: Function;
    setAllSystemNamesSelected: Function;
    handleSystemNameSelected: Function;
};

export default withTranslation(["pam"])(Body);
