
import { api } from '../../../../RevitJS/API';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const language = "French";

export const addBillOfQuantityTable = (worksheet, name, ref, theme, columns, rows) => {
    return worksheet.addTable({
        name,
        ref,
        headerRow: true,
        totalsRow: false,
        style: {
            theme,
            showRowStripes: true,
        },
        columns,
        rows
    });
}

export const filterProjectTree = (projectTree, selectedIds, selectedLevels) => {
    return projectTree.Tree.filter(level => { return selectedLevels.includes(level.Level.Name); }).map(level => {
        return {
            level: level.Level.Name,
            height: level.Level.Elevation,
            elems: level.Elements.map(elems => {
                let filteredIds = elems.Ids.filter(id => selectedIds.includes(id));
                return {
                    type: elems.Type,
                    ids: filteredIds
                }
            }).filter(e => e.ids.length)
        }
    })
}

export const formatProjectTree = (projectTree, selectedIds, selectedLevels) => {
    return projectTree.Tree.map(level => {
        return {
            level: level.Level.Name,
            height: level.Level.Elevation,
            elems: level.Elements.map(elems => {
                // let filteredIds = elems.Ids.filter(id => selectedIds.includes(id));
                return {
                    type: elems.Type,
                    ids: elems.Ids
                }
            }).filter(e => e.ids.length)
        }
    })
}

export const adjustColumnsSize = (sheet) => {
    for (let i = 0; i < sheet.columns.length; i++) {
        let dataMax = 0;
        const column = sheet.columns[i];
        for (let j = 1; j < column.values.length; j++) {
            const columnLength = column.values[j].length;
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        }
        column.width = dataMax < 10 ? 10 : dataMax + 2;
    }
}

export const detailRowBuilder = async (wallsData, type, j, level) => {
    let rows = []
    if (j === 0) rows.push([level, " ", " ", " ", " "])

    rows.push([" ", type, " ", " ", " "]);
    rows.push([" ", " ", wallsData[0].Width, " ", " "]);
    wallsData.forEach(wall => {
        rows.push([" ", " ", " ", parseInt(wall.Id), Math.round(wall.Area * 10) / 10])
    });
    return rows;
}


export const detailNewRowBuilder = async (wallsData, type, j, level) => {
  let rows = []
  wallsData.forEach(wall => {
    rows.push({productName : wall.ProductName, ceilingType: wall.CeilingType, area : wall.Area, id: parseInt(wall.Id), perimeter: wall.Perimeter?.toFixed(2), ods: wall.Height})
    //  rows.push([level, wall.ProductName, wall.CeilingType, Math.round(wall.Area * 10) / 10, parseInt(wall.Id), Math.round(wall.Perimeter), ""])
  });
  return rows;
}

export const generatelLevelRows = (levelRows) => {
    let levels = Object.keys(levelRows);
    let rows = [];
    let levelNb = levels.length;
    for (let i = 0; i < levelNb; i++) {
        let types = Object.keys(levelRows[levels[i]]);
        let typesNb = types.length;
        let sum = 0;
        for (let j = 0; j < typesNb; j++) {
            sum += levelRows[levels[i]][types[j]]
        }
        sum = Math.round(sum * 100) / 100
        for (let j = 0; j < typesNb; j++) {
            if (j === 0) rows.push([levels[i], " ", sum])
            rows.push([" ", types[j], Math.round(levelRows[levels[i]][types[j]] * 100) / 100])
        }
    }
    return rows;
}

export const generateSchedulesRows = async tree => {
    let nbLevels = tree.length;
    let detailRows = {};
    let levelRows = {};
    let typeRows = {};
    for (let i = 0; i < nbLevels; i++) {
        let elems = tree[i].elems;
        let nb_types = elems.length;
        let level = tree[i].level;

        for (let j = 0; j < nb_types; j++) {
            let ids = elems[j].ids;
            let type = elems[j].type;
            if (j === 0) levelRows[level] = {};
            if (ids.length > 0) {
                let ceilingsData = await api.queries.getCeilingsData(ids);
                let rows = await detailNewRowBuilder(ceilingsData, type, j, tree[i].level);
                (detailRows[tree[i].level] || (detailRows[tree[i].level] = [])).push(rows);

                let typeArea = ceilingsData.reduce((a, c) => a + c.Area, 0);
                levelRows[level][type] = {productName:ceilingsData[0].ProductName, surfaceArea: typeArea};
                typeRows[type] = typeRows[type] || 0;
                typeRows[type] += typeArea;
            }
        }
    }
    return {
        detailRows,
        levelRows,
        typeRows
    }
}

const wording = {
  levels:{
      "French": "Niveaux",
      "English": "Levels",
      "German": "Ebenen",
      "Italian": "Livelli"
  },
  ceilingTypes:{
      "French": "Types de plafonds",
      "English": "Ceiling types",
      "German": "Deckentypen",
      "Italian": "Tipi di soffito"
  },
  wallTypes:{
    "French": "Types de murs",
    "English": "Wall types",
    "German": "Wandtypen",
    "Italian": "Tipi di muro"
  },
  selection:{
      "French": "Sélection",
      "English": "Selection",
      "German": "Auswahl",
      "Italian": "Selezione"
  },
  back:{
    "French": "Retour",
    "English": "Back",
    "German": "Zurück",
    "Italian": "Indietro"
  },
  validate:{
      "French": "Valider",
      "English": "Validate",
      "German": "Bestätigen",
      "Italian": "Convalidare"
  },
  deletePending:{
    "French": "Suppresion en cours...",
    "English": "Deleting...",
    "German": "Löschen...",
    "Italian": "La cancellazione in corso..."
  },
  measurementsInProgress:{
    "French": "Métrés en cours...",
      "English": "Measurements in progress...",
      "German": "Messungen laufen...",
      "Italian": "Misure in corso..."
  },
  wall: {
    "French": "Murs",
    "English": "Walls",
    "German": "Wände",
    "Italian": "Muri"
  },
  level: {
    "French": "Niveaux",
    "English": "Levels",
    "German": "Ebenen",
    "Italian": "Livelli"
  },
  wallType: {
    "French": "Types de murs",
    "English": "Wall types",
    "German": "Wandtyp",
    "Italian": "Tipo de muro"
  },
  ceilingType: {
    "French": "Types de plafonds",
    "English": "Ceiling types",
    // "German": "Wandtyp",
    // "Italian": "Tipo de muro"
  },
  thickness: {
    "French": "Epaisseur (mm)",
    "English": "Thickness (mm)",
    "German": "Dicke (mm)",
    "Italien": "Spessore (mm)"
  },
  Id: {
    "French": "Id du mur",
    "English": "Wall Ids",
    "German": "Wand-IDs",
    "Italian": "ID de muri"
  },
  height: {
    "French": "Hauteur (mm)",
    "English": "Height (mm)",
    "German": "Höhe (mm)",
    "Italian": "Altezza (mm)"
  },
  length: {
    "French": "Longueur (mm)",
    "English": "Length (mm)",
    "German": "Länge (mm)",
    "Italian": "Lunghezza (mm)",

  },
  surface: {
    "French": "Surface (m²)",
    "English": "Surface (m²)",
    "German": "Oberfläche (m²)",
    "Italian": "Superficie (m²)"
  },
  fileName: {
    "French": "Nomenclature des murs",
    "English": "Wall schedules",
    "German": "Wandinventar",
    "Italian": "Nomenclatura murale"
  }
}

export const whoolQuantities = async wallsSheet =>{
  const tree = await api.selection.elementsByLevelAndType("wall");
  let levels = tree.Tree.map(lev => lev.Level.Name);
  let albaTypes = [
          "MW_40",
          "MW_60"
        ];
  const selectedElems = [].concat.apply([], selectedItems(tree, levels, albaTypes).map(e => e.Ids));
  let filteredTree = filterProjectTree(tree, selectedElems, levels);
  let { detailRows, levelRows, typeRows } = await generateSchedulesRows(filteredTree);
  let columns = [
    { name: wording.level[language], filterButton: true },
    { name: wording.wallType[language], filterButton: true },
    { name: wording.thickness[language], filterButton: true },
    { name: wording.Id[language], filterButton: true },
    // { name: wording.height[language], filterButton: true },
    // { name: wording.length[language], filterButton: true },
    { name: wording.surface[language], filterButton: true },
  ];

  addBillOfQuantityTable(wallsSheet, "DetailedTable", "A1", "TableStyleMedium9", columns, detailRows);

  let formatedLevelRows = generatelLevelRows(levelRows);

  let columnsLevel = [
      { name: wording.level[language], filterButton: true },
      { name: wording.wallType[language], filterButton: true },
      { name: wording.surface[language], filterButton: true },
  ];

  addBillOfQuantityTable(wallsSheet, "DetailedLevelTable", "I1", "TableStyleMedium9", columnsLevel, formatedLevelRows);

  let formatedTypesRows = Object.keys(typeRows).map(type => [type, Math.round(typeRows[type] * 100) / 100]);

  let columnsType = [
      { name: wording.wallType[language], filterButton: true },
      { name: wording.surface[language], filterButton: true },
  ];

  addBillOfQuantityTable(wallsSheet, "DetailedTypeTable", "M1", "TableStyleMedium9", columnsType, formatedTypesRows);

  adjustColumnsSize(wallsSheet);
  return wallsSheet;
}

export const albaQuantities = async wallsSheet =>{
  const tree = await api.selection.elementsByLevelAndType("wall");
  let levels = tree.Tree.map(lev => lev.Level.Name);
  let albaTypes = [
          "AH25",
          "AH40",
          "AB25",
          "AB40",
          "A25",
          "A40",
          "A60", 
          "A80", 
          "A100", 
          "A100, ASS", 
          "AG100, ASS", 
          "A140", 
          "AH60", 
          "AH80", 
          "AH100", 
          "AH100, ASS", 
          "AGH100", 
          "AH140", 
          "AGH100, ASS", 
          "AG100"
        ];
  const selectedElems = [].concat.apply([], selectedItems(tree, levels, albaTypes).map(e => e.Ids));
  let filteredTree = filterProjectTree(tree, selectedElems, levels);
  let { detailRows, levelRows, typeRows } = await generateSchedulesRows(filteredTree);
  let columns = [
    { name: wording.level[language], filterButton: true },
    { name: wording.wallType[language], filterButton: true },
    { name: wording.thickness[language], filterButton: true },
    { name: wording.Id[language], filterButton: true },
    // { name: wording.height[language], filterButton: true },
    // { name: wording.length[language], filterButton: true },
    { name: wording.surface[language], filterButton: true },
  ];

  addBillOfQuantityTable(wallsSheet, "DetailedTable", "A1", "TableStyleMedium9", columns, detailRows);

  let formatedLevelRows = generatelLevelRows(levelRows);

  let columnsLevel = [
      { name: wording.level[language], filterButton: true },
      { name: wording.wallType[language], filterButton: true },
      { name: wording.surface[language], filterButton: true },
  ];

  addBillOfQuantityTable(wallsSheet, "DetailedLevelTable", "I1", "TableStyleMedium9", columnsLevel, formatedLevelRows);

  let formatedTypesRows = Object.keys(typeRows).map(type => [type, Math.round(typeRows[type] * 100) / 100]);

  let columnsType = [
      { name: wording.wallType[language], filterButton: true },
      { name: wording.surface[language], filterButton: true },
  ];

  addBillOfQuantityTable(wallsSheet, "DetailedTypeTable", "M1", "TableStyleMedium9", columnsType, formatedTypesRows);

  adjustColumnsSize(wallsSheet);
  return wallsSheet;
}

export const scheduleHandler = async (filteredTree, wording, language) => {
    let { detailRows, levelRows, typeRows } = await generateSchedulesRows(filteredTree);
    const workbook = new Excel.Workbook();
    const wallsSheet = workbook.addWorksheet(wording.ceiling[language]);
    let columns = [
        { name: wording.level[language], filterButton: true },
        { name: wording.ceilingType[language], filterButton: true },
        { name: wording.thickness[language], filterButton: true },
        { name: wording.Id[language], filterButton: true },
        { name: wording.surface[language], filterButton: true },
    ];

    addBillOfQuantityTable(wallsSheet, "DetailedTable", "A1", "TableStyleMedium9", columns, detailRows);

    let formatedLevelRows = generatelLevelRows(levelRows);

    let columnsLevel = [
        { name: wording.level[language], filterButton: true },
        { name: wording.ceilingType[language], filterButton: true },
        { name: wording.surface[language], filterButton: true },
    ];

    addBillOfQuantityTable(wallsSheet, "DetailedLevelTable", "I1", "TableStyleMedium9", columnsLevel, formatedLevelRows);

    let formatedTypesRows = Object.keys(typeRows).map(type => [type, Math.round(typeRows[type] * 100) / 100]);

    let columnsType = [
        { name: wording.ceilingType[language], filterButton: true },
        { name: wording.surface[language], filterButton: true },
    ];

    addBillOfQuantityTable(wallsSheet, "DetailedTypeTable", "M1", "TableStyleMedium9", columnsType, formatedTypesRows);
    const buff = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, wording.fileName[language]);
}

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByArray(xs, key) {
  return xs.reduce(function(rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find(r => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }
    return rv;
  }, []);
}

export const mergeItems = arr =>
  groupByArray(arr, "Type").map(el => {
    return {
      Type: el.key,
      Count: el.values.reduce(function(prev, cur) {
        return prev + cur.Count;
      }, 0),
      Checked: false
    };
  });

export const elementTypes = data =>
  data.Tree.length > 0
    ? mergeItems(
        data.Tree.map(level => level.Elements)
          .map(s =>
            s.map(nested => {
              return {
                Type: nested.Type,
                Count: nested.Ids.length,
                Checked: false
              };
            })
          )
          .reduce((prev, current) => prev.concat(current))
          .sort((a, b) => (a.Type < b.Type ? -1 : 1))
      )
    : null;

export const levelData = (data, levellist) => {
  if (levellist.length === 0) return data;
  let levelsData = data.Tree.filter(level =>
    levellist.includes(level.Level.Name)
  );
  return { Tree: levelsData };
};

export const selectedIds = (data, selectedLevels, selectedTypes) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
    return data.Tree.filter(level => selectedLevels.includes(level.Level.Name))
      .map(level => level.Elements)
      .map(s => s.map(nested => nested))
      .reduce((prev, current) => prev.concat(current))
      .filter(e => selectedTypes.includes(e.Type))
      .map(el => el.Ids)
      .reduce((prev, current) => prev.concat(current));
  return [];
};

export const selectedItems = (data, selectedLevels, selectedTypes) => {
  if (selectedLevels.length > 0 && selectedTypes.length > 0)
 
  return groupByArray(
    // group array objects by unique 'Type'
    data.Tree.filter(level => selectedLevels.includes(level.Level.Name)) //filter an object from 'Tree' of which 'Level.Name' includes in 'selectedlevels' array
      .map(level => level.Elements) // map on or loop through 'Elements' object
      .map(s => s.map(nested => nested)) //  map on or loop through on objects inside 'Element' array
      .reduce((prev, current) => prev.concat(current)) // reduce or concat Elements objects into single array
      .filter(e => selectedTypes.includes(e.Type)), // filter single array for which 'Type' includes in 'selectedTypes'
    "Type"
  ).map(el => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map(val => val.Ids)
      )
    };
  });
  // return [];
};

export const selectedItemsManualSelection = data => {
  return groupByArray(
    data.Tree.map(level => level.Elements)
      .map(s => s.map(nested => nested))
      .reduce((prev, current) => prev.concat(current)),
    "Type"
  ).map(el => {
    return {
      Type: el.key,
      Ids: [].concat.apply(
        [],
        el.values.map(val => val.Ids)
      )
    };
  });
};
