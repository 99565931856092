import { ProductDetailData } from "../../../../RevitJS/Types/BddTypes";
import { AxiosInstance } from "axios";
import { getSystemDetailsById, getPlacoSystemsDetails } from "../Requests";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import { setFiltersAid } from "../../../../RevitJS/Helpers";
import { UnderSystemTree } from "../../../../RevitJS/Types/RigipsTypes";
import { findAllInRenderedTree } from "react-dom/test-utils";

export const getSystemsDetailsAndFilters = async (
  ids: ID[],
  bdd: AxiosInstance,
  filters: any[]
): Promise<{
  systemsDetails: ProductDetailData[];
  filters: any[];
  attributes: any[];
}> => {
  let systemDetailsRequest = await getSystemDetailsById(ids, bdd);

  let systemsDetails = systemDetailsRequest.data.objects.map((sys) => {
    return { ...sys, filterFields: {} } as unknown;
  }) as ProductDetailData[];
  let attributes =
    systemDetailsRequest.data.context.attributeDependencies[0].attributes;

  filters = setFiltersAid(filters, attributes);

  systemsDetails = addFilterFields(systemsDetails, filters);
  filters = setFiltersOptions(filters, systemsDetails);

  return {
    systemsDetails,
    filters,
    attributes,
  };
};

export const addFilterFields = (
  systemsDetails: ProductDetailData[],
  filters: any
) => {
  return systemsDetails.map((sys: any) => {
    filters.map((filter: any) => {
      let attr = sys.attributes.find((attr: any) => attr.aid === filter.aid);
      if (attr) {
        let attrValue;
        if (filter.type === "Numeric") attrValue = attr.values[0].numericValue;
        else {
          attrValue = attr.values[0].value;
        }
        sys.filterFields[filter.name] = attrValue;
      } else {
        sys.filterFields[filter.name] = undefined;
      }
    });
    return sys;
  });
};

export const setFiltersOptions = (
  filters: any,
  systemsDetails: ProductDetailData[]
) => {
  filters = [...filters];
  systemsDetails.forEach((system) => {
    filters.forEach((filter: any) => {
      filter.options = filter.options || [];
      let attrValue = system.filterFields[filter.name];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      } else {
      }
    });
  });
  return filters.map((filter: any) => {
    filter.options.sort((a: any, b: any) => {
      if (filter.pimAttribute === "GFR-Fire protection") {
        return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
      } else {
        return a - b;
      }
    });
    return filter;
  });
};

export const filterUnderSystems = (
  underSystems: any[],
  fireId: string,
  occupationId: string,
  spacingId: string,
  maxHeightId: string
) => {
  let underSystemTree = {
    EI: {
      "1": {
        "100": null,
        "62.5": null,
        "0": null,
      },
      "2": {
        "100": null,
        "62.5": null,
        "0": null,
      },
    },
    NEI: {
      "1": {
        "100": null,
        "62.5": null,
        "41.7": null,
        "31.25": null,
        "0": null,
      },
      "2": {
        "100": null,
        "62.5": null,
        "41.7": null,
        "31.25": null,
        "0": null,
      },
    },
  };
  for (let i = 0; i < underSystems.length; i++) {
    let fireConstrain = underSystems[i].attributes.find(
      (e: any) => e.aid === fireId
    );
    let occupation = underSystems[i].attributes.find(
      (e: any) => e.aid === occupationId
    );
    let spacing = underSystems[i].attributes.find(
      (e: any) => e.aid === spacingId
    );
    let maxHeight = underSystems[i].attributes.find(
      (e: any) => e.aid === maxHeightId
    );

    switch (fireConstrain.values[0].value) {
      case "ohne Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["1"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["NEI"]["1"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "41.7":
                underSystemTree["NEI"]["1"]["41.7"] =
                  maxHeight.values[0].numericValue;
                break;
              case "31.25":
                underSystemTree["NEI"]["1"]["31.25"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["NEI"]["1"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["2"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["NEI"]["2"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "41.7":
                underSystemTree["NEI"]["2"]["41.7"] =
                  maxHeight.values[0].numericValue;
                break;
              case "31.25":
                underSystemTree["NEI"]["2"]["31.25"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["NEI"]["2"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          default:
            // eslint-disable-next-line no-throw-literal
            throw "This spacing is not recognized";
        }
        break;
      case "mit Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["1"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["EI"]["1"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["EI"]["1"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["2"]["100"] =
                  maxHeight.values[0].numericValue;
                break;
              case "62.5":
                underSystemTree["EI"]["2"]["62.5"] =
                  maxHeight.values[0].numericValue;
                break;
              case "0":
                underSystemTree["EI"]["2"]["0"] =
                  maxHeight.values[0].numericValue;
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          default:
            // eslint-disable-next-line no-throw-literal
            throw "This spacing is not recognized";
        }
        break;
      default:
        // eslint-disable-next-line no-throw-literal
        throw "Fire classification not recognized";
    }
  }
  return underSystemTree;
};

export const generateUnderSystemTree = () => ({
  EI: {
    "1": {
      "100": null,
      "62.5": null,
      "0": null,
    },
    "2": {
      "100": null,
      "62.5": null,
      "0": null,
    },
  },
  NEI: {
    "1": {
      "100": null,
      "62.5": null,
      "41.7": null,
      "31.25": null,
      "0": null,
    },
    "2": {
      "100": null,
      "62.5": null,
      "41.7": null,
      "31.25": null,
      "0": null,
    },
  },
});

export const fillUnderSystemsTree = (
  underSystems: any[],
  fireId: string,
  occupationId: string,
  spacingId: string,
  maxHeightId: string
): UnderSystemTree => {
  let underSystemTree: UnderSystemTree = generateUnderSystemTree();
  for (let i = 0; i < underSystems.length; i++) {
    let fireConstrain = underSystems[i].attributes.find(
      (e: any) => e.aid === fireId
    );
    let occupation = underSystems[i].attributes.find(
      (e: any) => e.aid === occupationId
    );
    let spacing = underSystems[i].attributes.find(
      (e: any) => e.aid === spacingId
    );

    switch (fireConstrain.values[0].value) {
      case "ohne Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["1"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["NEI"]["1"]["62.5"] = underSystems[i];
                break;
              case "41.7":
                underSystemTree["NEI"]["1"]["41.7"] = underSystems[i];
                break;
              case "31.25":
                underSystemTree["NEI"]["1"]["31.25"] = underSystems[i];
                break;
              case "0":
                underSystemTree["NEI"]["1"]["0"] = underSystems[i];
                break;
              default:
                // eslint-disable-next-line no-throw-literal
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["NEI"]["2"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["NEI"]["2"]["62.5"] = underSystems[i];
                break;
              case "41.7":
                underSystemTree["NEI"]["2"]["41.7"] = underSystems[i];
                break;
              case "31.25":
                underSystemTree["NEI"]["2"]["31.25"] = underSystems[i];
                break;
              case "0":
                underSystemTree["NEI"]["2"]["0"] = underSystems[i];
                break;
              default:
                throw "This spacing is not recognized";
            }
            break;
          default:
            throw "This spacing is not recognized";
        }
        break;
      case "mit Brand":
        switch (occupation.values[0].value) {
          case "1":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["1"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["EI"]["1"]["62.5"] = underSystems[i];
                break;
              case "0":
                underSystemTree["EI"]["1"]["0"] = underSystems[i];
                break;
              default:
                throw "This spacing is not recognized";
            }
            break;
          case "2":
            switch (spacing.values[0].value) {
              case "100":
                underSystemTree["EI"]["2"]["100"] = underSystems[i];
                break;
              case "62.5":
                underSystemTree["EI"]["2"]["62.5"] = underSystems[i];
                break;
              case "0":
                underSystemTree["EI"]["2"]["0"] = underSystems[i];
                break;
              default:
                throw "This spacing is not recognized";
            }
            break;
          default:
            throw "This spacing is not recognized";
        }
        break;
      default:
        throw "Fire classification not recognized";
    }
  }
  return underSystemTree;
};

export const selectedUnderSystem = (
  underSystems: any[],
  fireConstrain: boolean,
  publicConstrain: boolean,
  height: number,
  fireId: string,
  occupationId: string,
  spacingId: string,
  maxHeightId: string
) => {
  // let underSystemTree: UnderSystemTree = generateUnderSystemTree();
  let filteredUnderSystems = underSystems
    .filter((underSystem) => {
      let fireConstrainAttr = underSystem.attributes.find(
        (e: any) => e.aid === fireId
      ).values[0].value;
      if (fireConstrain && fireConstrainAttr === "mit Brand") {
        return true;
      }
      if (!fireConstrain && fireConstrainAttr === "ohne Brand") {
        return true;
      } else {
        return false;
      }
    })
    .filter((underSystem) => {
      let occupation = underSystem.attributes.find(
        (e: any) => e.aid === occupationId
      ).values[0].value;
      if (publicConstrain && occupation === "2") {
        return true;
      }
      if (!publicConstrain && occupation === "1") {
        return true;
      } else {
        return false;
      }
    });

  if (filteredUnderSystems.length === 0) {
    throw "Problem with under system selection";
  }
  if (filteredUnderSystems.length === 1) {
    return filteredUnderSystems[0];
  }

  let sortedSysems = filteredUnderSystems.sort((a, b) => {
    let maxHeightA = a.attributes.find((e: any) => e.aid === maxHeightId)
      .values[0].numericValue;
    let maxHeightB = b.attributes.find((e: any) => e.aid === maxHeightId)
      .values[0].numericValue;
    return maxHeightA - maxHeightB;
  });

  for (let j = 0; j < sortedSysems.length; j++) {
    let systemMaxHeight =
      sortedSysems[j].attributes.find((e: any) => e.aid === maxHeightId)
        .values[0].numericValue * 1000;
    if (height < systemMaxHeight) {
      return sortedSysems[j];
    }
  }
  return sortedSysems[sortedSysems.length - 1];
};

export const parseAlbaSystem = async (bdd: AxiosInstance, oid: string) => {
  const { attributes, objects } = await getPlacoSystemsDetails(bdd, [oid]).then(
    (response: any) => {
      return {
        attributes: response.data.context.attributeDependencies[0].attributes,
        objects: response.data.objects,
      };
    }
  );
  let nb_attributes = attributes.length;
  let rigpsTechnicalNames = [
    "TD_System_Plattendicken",
    "TD_System_Systemaufbau",
    "TD_Untersystem_Brandfalldef",
    "TD_Untersystem_Raumnutzung",
    "TD_Untersystem_Ständerabstand",
    "TD_Untersystem_Wandhöhe",
    "TD_Untersystem_Schlüssel",
  ];
  let layout_params: any = {};

  for (let i = 0; i < nb_attributes; i++) {
    if (rigpsTechnicalNames.indexOf(attributes[i].technicalName) > -1) {
      layout_params[attributes[i].technicalName] = attributes[i].aid;
    }
  }

  let mainSystem = objects.find(
    (obj: any) => obj.types[0] === "Product System"
  );
  let underSystems = objects.filter(
    (obj: any) => obj.types[0] === "UnderSystem"
  );

  // let underSystemsTree = filterUnderSystems(underSystems, layout_params["TD_Untersystem_Brandfalldef"], layout_params["TD_Untersystem_Raumnutzung"], layout_params["TD_Untersystem_Ständerabstand"], layout_params["TD_Untersystem_Wandhöhe"])
  // let systemsTree = fillUnderSystemsTree(underSystems, layout_params["TD_Untersystem_Brandfalldef"], layout_params["TD_Untersystem_Raumnutzung"], layout_params["TD_Untersystem_Ständerabstand"], layout_params["TD_Untersystem_Wandhöhe"]);

  // let underSystem = selectedUnderSystem(underSystems, false, true, 15, layout_params["TD_Untersystem_Brandfalldef"], layout_params["TD_Untersystem_Raumnutzung"], layout_params["TD_Untersystem_Ständerabstand"], layout_params["TD_Untersystem_Wandhöhe"]);
  return parseUnderSystem(underSystems[0], layout_params);
};

export const parseRigipsSystem = async (
  bdd: AxiosInstance,
  oid: string,
  maxHeight: number,
  fireConstrain: boolean = false,
  publicConstrain: boolean = false
) => {
  const { attributes, objects } = await getPlacoSystemsDetails(bdd, [oid]).then(
    (response: any) => {
      return {
        attributes: response.data.context.attributeDependencies[0].attributes,
        objects: response.data.objects,
      };
    }
  );
  let nb_attributes = attributes.length;
  let rigpsTechnicalNames = [
    "TD_System_Plattendicken",
    "TD_System_Systemaufbau",
    "TD_Untersystem_Brandfalldef",
    "TD_Untersystem_Raumnutzung",
    "TD_Untersystem_Ständerabstand",
    "TD_Untersystem_Wandhöhe",
    "TD_Untersystem_Schlüssel",
    "TD_System_Dämmung",
  ];
  let layout_params: any = {};

  for (let i = 0; i < nb_attributes; i++) {
    if (rigpsTechnicalNames.indexOf(attributes[i].technicalName) > -1) {
      layout_params[attributes[i].technicalName] = attributes[i].aid;
    }
  }

  let mainSystem = objects.find(
    (obj: any) => obj.types[0] === "Product System"
  );

  let underSystems = objects.filter(
    (obj: any) => obj.types[0] === "UnderSystem"
  );

  let underSystemsTree = filterUnderSystems(
    underSystems,
    layout_params["TD_Untersystem_Brandfalldef"],
    layout_params["TD_Untersystem_Raumnutzung"],
    layout_params["TD_Untersystem_Ständerabstand"],
    layout_params["TD_Untersystem_Wandhöhe"]
  );
  let systemsTree = fillUnderSystemsTree(
    underSystems,
    layout_params["TD_Untersystem_Brandfalldef"],
    layout_params["TD_Untersystem_Raumnutzung"],
    layout_params["TD_Untersystem_Ständerabstand"],
    layout_params["TD_Untersystem_Wandhöhe"]
  );

  let underSystem = selectedUnderSystem(
    underSystems,
    fireConstrain,
    publicConstrain,
    maxHeight,
    layout_params["TD_Untersystem_Brandfalldef"],
    layout_params["TD_Untersystem_Raumnutzung"],
    layout_params["TD_Untersystem_Ständerabstand"],
    layout_params["TD_Untersystem_Wandhöhe"]
  );

  return parseUnderSystem(underSystem, layout_params);
};

export const parseUnderSystem = (underSystem: any, layout_params: any) => {
  let thicknesses = underSystem.attributes
    .find((e: any) => e.aid === layout_params["TD_System_Plattendicken"])
    .values[0].value.split("_");
  let plasterboardNames = underSystem.attributes
    .find((e: any) => e.aid === layout_params["TD_System_Systemaufbau"])
    .values[0].value.split("_");
  let framesSpace =
    underSystem.attributes.find(
      (e: any) => e.aid === layout_params["TD_Untersystem_Ständerabstand"]
    ).values[0].numericValue * 10;
  let nb_sides = thicknesses.length;

  let side_A_thicknesses = thicknesses[0].split("/");
  let side_A_names = plasterboardNames[0].split("/");
  let side_A_length = side_A_thicknesses.length;

  let result: any = {
    systemName: "Test",
    framesSpace,
    plateWidth: 1250,
    E1: "None",
    E2: "None",
    E3: "None",
    I1: "None",
    I2: "None",
    I3: "None",
    E1_Name: "None",
    E2_Name: "None",
    E3_Name: "None",
    I1_Name: "None",
    I2_Name: "None",
    I3_Name: "None",
    Reverse: false,
    Flipped: false,
    Lining: false,
    railsSpace: 0,
    staggering: false,
    doubleFraming: false,
    doubleAmount: false,
    Frame1_Name: "Frame",
    Rail1_Name: "Rail",
    SAA: false,
    SAD: false,
    systemStruct: underSystem.attributes.find(
      (e: any) => e.aid === layout_params["TD_System_Systemaufbau"]
    ).values[0].value,
    layersDesc: underSystem.attributes.find(
      (e: any) => e.aid === layout_params["TD_System_Systemaufbau"]
    ).values[0].value,
    whool: layout_params["TD_System_Dämmung"]
      ? underSystem.attributes.find(
          (e: any) => e.aid === layout_params["TD_System_Dämmung"]
        ).values[0].value
      : null,
  };

  for (let i = 0; i < side_A_length; i++) {
    // parementA.push({ number: i, content: side_A_names[i], name: "Rigips", thickness: side_A_thicknesses[i] });
    let layer = `E${i + 1}`;
    let layer_name = `E${i + 1}_Name`;
    result[layer] = parseFloat(side_A_thicknesses[i]);
    result[layer_name] = side_A_names[i];
  }

  if (nb_sides > 1) {
    let side_B_thicknesses = thicknesses[1].split("/");
    let side_B_names = plasterboardNames[1].split("/");
    let side_B_length = side_B_thicknesses.length;

    for (let i = 0; i < side_B_length; i++) {
      // parementA.push({ number: i, content: side_A_names[i], name: "Rigips", thickness: side_A_thicknesses[i] });
      let layer = `I${i + 1}`;
      let layer_name = `I${i + 1}_name`;
      result[layer] = parseFloat(side_B_thicknesses[i]);
      result[layer_name] = side_B_names[i];
    }
  }

  return {
    ...result,
    doublage: nb_sides ? false : true,
  };

  // let result =  {
  //   systemName,
  //   framesSpace: framesSpace * 1000,
  //   plateWidth,
  //   E1: systemElems.claddingA[0].E1,
  //   E2: systemElems.claddingA[1].E2,
  //   E3: systemElems.claddingA[2].E3,
  //   I1: systemElems.claddingB[0].I1,
  //   I2: systemElems.claddingB[1].I2,
  //   I3: systemElems.claddingB[2].I3,
  //   E1_Name: systemElems.claddingA[0].E1_Name,
  //   E2_Name: systemElems.claddingA[1].E2_Name,
  //   E3_Name: systemElems.claddingA[2].E3_Name,
  //   I1_Name: systemElems.claddingB[0].I1_Name,
  //   I2_Name: systemElems.claddingB[1].I2_Name,
  //   I3_Name: systemElems.claddingB[2].I3_Name,
  //   Reverse :false,
  //   Flipped: false,
  //   Lining: false,
  //   railsSpace: 0,
  //   staggering: false,
  //   doubleFraming: false,
  //   doubleAmount: false,
  //   Frame1_Name: "Frame",
  //   Rail1_Name: "Rail",
  //   SAA: false,
  //   SAD: false
  // };
};

export const startInList = (start: string | null, checkList: string[]) => {
  if (start === null) return false;
  for (let i = 0; i < checkList.length; i++) {
    if (start.startsWith(checkList[i])) return true;
  }
  return false;
};
