import {
  TypeData,
  LevelData,
  GenericMappingRow,
  PlacoOptions,
  RowOptions,
  FilterType,
  initProjectData,
} from "../../../../RevitJS/Types/StoreTypes";
import { ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import {
  ProductDetailData,
  PIMLayoutAttributesAid,
  CustomProductDetailData,
} from "../../../../RevitJS/Types/BddTypes";
import { ElementParameter } from "./objectTypes";
import { SolutionFilterType } from "../Types";
import { typedArrayFor } from "pdf-lib";

export const CREATE_SELECTION = "CREATE_SELECTION";

export interface createSelectionAction<T extends RowOptions> {
  type: typeof CREATE_SELECTION;
  selection: Selection<T>;
}

export const SET_GROUP_TREES = "SET_GROUP_TREES";

export interface setGroupTreesAction {
  type: typeof SET_GROUP_TREES;
  wallTree: ElementsTree;
  ceilingTree: ElementsTree;
}

export const SET_SELECTOR_TYPE = "SET_SELECTOR_TYPE";

export interface setSelectorTypeAction {
  type: typeof SET_SELECTOR_TYPE;
  selectorType: selectorTp;
}

export type selectorTp = "wall" | "ceiling" | "others";

export const SELECT_LEVEL = "SELECT_LEVEL";

export interface selectLevelAction {
  type: typeof SELECT_LEVEL;
  selectorType: selectorTp;
  selectedTypes: string[];
  levelsData: LevelData[];
  typesDataByLevel?: TypeData[];
  typesData: TypeData[];
  selectedLevels: string[];
}

export const SELECT_TYPE = "SELECT_TYPE";

export interface selectTypeAction {
  type: typeof SELECT_TYPE;
  selectorType: selectorTp;
  typesData: TypeData[];
  typesDataByLevel?: TypeData[];
  selectedTypes: string[];
}

export const INIT_MAPPING_TABLE = "INIT_MAPPING_TABLE";

export interface initMappingTableAction<T extends PlacoOptions> {
  type: typeof INIT_MAPPING_TABLE;
  wallMappingRows: GenericMappingRow<T>[];
  ceilingMappingRows: GenericMappingRow<T>[];
  othersMappingRows: GenericMappingRow<T>[];
  levels: string[];
}

export const UPDATE_MAPPING_TABLE = "UPDATE_MAPPING_TABLE";

export interface updatingMappingTableAction<T extends PlacoOptions> {
  type: typeof UPDATE_MAPPING_TABLE;
  wallMappingRows: GenericMappingRow<T>[];
  ceilingMappingRows: GenericMappingRow<T>[];
  levels: string[];
}

export const UPDATE_OTHER_PLACO_SYSTEMS = "UPDATE_OTHER_PLACO_SYSTEMS";

export interface updatingOtherPlacoSystems<T extends PlacoOptions> {
  type: typeof UPDATE_OTHER_PLACO_SYSTEMS;
  othersMappingRows: GenericMappingRow<T>[];
}

export const UPDATE_BUFFER_SELECTION = "UPDATE_BUFFER_SELECTION";

export interface updateBufferSelection {
  type: typeof UPDATE_BUFFER_SELECTION;
  update: boolean;
}

export const SAVE_BUFFER = "SAVE_BUFFER";

export interface saveBufferAction<T extends RowOptions> {
  type: typeof SAVE_BUFFER;
  selection: Selection<T> | null;
}

export const SAVE_SELECTIONS = "SAVE_SELECTIONS";

export interface saveSelectionAction {
  type: typeof SAVE_SELECTIONS;
  selection: { [key: string]: Selection<PlacoOptions> };
}
export const DELETE_SELECTION = "DELETE_SELECTION";

export interface deleteSelectionAction {
  type: typeof DELETE_SELECTION;
  selectionId: string;
}

export const FETCH_PLACO_DATA = "FETCH_PLACO_DATA";

export interface fetchPlacoDataAction {
  type: typeof FETCH_PLACO_DATA;
  liningWallsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  partitionsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  ceilingsDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  layoutAttributesAid: PIMLayoutAttributesAid;
  protectionFeuDetails: {
    systemsDetails: ProductDetailData[];
    filters: any[];
  };
  gainesTechDetails: { systemsDetails: ProductDetailData[]; filters: any[] };
  conduitGainesDetails: {
    systemsDetails: ProductDetailData[];
    filters: any[];
  };
}

export const DUPLICATE_SELECTION = "DUPLICATE_SELECTION";

export interface duplicateSelectionAction {
  type: typeof DUPLICATE_SELECTION;
  selectionId: string;
}

export const EDIT_SELECTION = "EDIT_SELECTION";

export interface editSelectionAction<T extends RowOptions> {
  type: typeof EDIT_SELECTION;
  selection: Selection<T>;
}

export const LOAD_SELECTIONS = "LOAD_SELECTIONS";

export interface loadSelectionsAction<T extends RowOptions> {
  type: typeof LOAD_SELECTIONS;
  selections: { [key: string]: Selection<PlacoOptions> };
}

export const SELECT_SYSTEM = "SELECT_SYSTEM";

export interface selectSystemAction {
  type: typeof SELECT_SYSTEM;
  Index: number;
  System: ProductDetailData | CustomProductDetailData;
  SelectorType: selectorTp;
}

export const DUPLICATE_BUFFER_SELECTION = "DUPLICATE_BUFFER_SELECTION";

export interface duplicateSystemAction {
  type: typeof DUPLICATE_BUFFER_SELECTION;
  Index: number;
  SelectorType: selectorTp;
}

export const DELETE_BUFFER_ELEMENT = "DELETE_BUFFER_ELEMENT";

export interface deleteBufferElement {
  type: typeof DELETE_BUFFER_ELEMENT;
  Index: number;
  SelectorType: selectorTp;
}

export const INIT_SELECTOR = "INIT_SELECTOR";

export interface initProductSelectorAction {
  type: typeof INIT_SELECTOR;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
}

export const APPLY_FILTERS = "APPLY_FILTERS";

export interface applyFiltersAction {
  type: typeof APPLY_FILTERS;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  filters: SolutionFilterType;
}

export const RESET_FILTERS = "RESET_FILTERS";

export interface resetFiltersAction {
  type: typeof RESET_FILTERS;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  filters: SolutionFilterType;
}

export const SET_FILTER_TYPE = "SET_FILTER_TYPE";

export interface setFilterTypeAction {
  type: typeof SET_FILTER_TYPE;
  filterType: FilterType;
}

export const SEARCH = "SEARCH";

export interface searchAction {
  type: typeof SEARCH;
  filteredElements: ProductDetailData[];
  displayedElements: ProductDetailData[];
  searchedWord: string;
}

export const SCROLLED_BOTTOM = "SCROLLED_BOTTOM";

export interface scrolledBottomAction {
  type: typeof SCROLLED_BOTTOM;
  displayedElements: ProductDetailData[];
}

export const SELECT_BUFFER = "SELECT_BUFFER";
export const MULTI_SELECT_BUFFER = "MULTI_SELECT_BUFFER";
export const TEMP_MULTI_SELECT_BUFFER = "TEMP_MULTI_SELECT_BUFFER";
export const RESET_TEMP_MULTI_SELECT_BUFFER = "RESET_TEMP_MULTI_SELECT_BUFFER";

export interface selectBufferAction {
  type: typeof SELECT_BUFFER;
  system: ProductDetailData;
}

export interface multiSelectBufferAction {
  type: typeof MULTI_SELECT_BUFFER;
  systems: ProductDetailData[];
}

export interface tempMultiSelectBufferAction {
  type: typeof TEMP_MULTI_SELECT_BUFFER;
  systems: ProductDetailData[];
}

export interface resetTempBufferAction {
  type: typeof RESET_TEMP_MULTI_SELECT_BUFFER;
}

export const RESET_BUFFER = "RESET_BUFFER";

export interface resetBufferAction {
  type: typeof RESET_BUFFER;
}

export const SET_ACTIVE_ROW_INDEX = "SET_ACTIVE_ROW_INDEX";

export interface setActiveRowIndexAction {
  type: typeof SET_ACTIVE_ROW_INDEX;
  Index: number;
}

export const TOGGLE_SELECTION_ROW = "TOGGLE_SELECTION_ROW";

export interface toggleSelectionRowAction {
  type: typeof TOGGLE_SELECTION_ROW;
  Index: number;
  SelectorType: string;
}

export const TOGGLE_ALL_SELECTION_ROW = "TOGGLE_ALL_SELECTION_ROW";

export interface toggleAllSelectionRowAction {
  type: typeof TOGGLE_ALL_SELECTION_ROW;
  SelectorType: string;
  Checked: boolean;
}

export const UPDATE_SELECTION_NAME = "UPDATE_SELECTION_NAME";

export interface updateSelectionName {
  type: typeof UPDATE_SELECTION_NAME;
  Index: string;
  Name: string;
}

export const UPDATE_SELECTION_COLOR = "UPDATE_SELECTION_COLOR";

export interface updateSelectionColor {
  type: typeof UPDATE_SELECTION_COLOR;
  Index: string;
  Color: any;
}

export const SHOW_SYSTEM_DETAILS = "SHOW_SYSTEM_DETAILS";

export interface showSystemDetailsAction {
  type: typeof SHOW_SYSTEM_DETAILS;
}

export const HIDE_SYSTEM_DETAILS = "HIDE_SYSTEM_DETAILS";

export interface hideSystemDetailsAction {
  type: typeof HIDE_SYSTEM_DETAILS;
}

export const SHOW_LAYOUT_DETAILS = "SHOW_LAYOUT_DETAILS";

export interface showLayoutDetailsAction {
  type: typeof SHOW_LAYOUT_DETAILS;
}

export const HIDE_LAYOUT_DETAILS = "HIDE_LAYOUT_DETAILS";

export interface hideLayoutDetailsAction {
  type: typeof HIDE_LAYOUT_DETAILS;
}

export const TOGGLE_ZONE = "TOGGLE_ZONE";

export interface toggleZoneAction {
  type: typeof TOGGLE_ZONE;
  selectionId: string;
}

export const SET_GLOBAL_LOADER = "SET_GLOBAL_LOADER";

export interface globalLoading {
  type: typeof SET_GLOBAL_LOADER;
  loading: boolean;
}
export const INIT_LANGUAGE = "INIT_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export interface InitLanguageAction {
  type: typeof INIT_LANGUAGE;
  language: Language;
}

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: Language;
}

export const SELECT_PLACO_OTHER_SYSTEM = "SELECT_PLACO_OTHER_SYSTEM";

export interface selectPlacoOtherSystemAction {
  type: typeof SELECT_PLACO_OTHER_SYSTEM;
  Index: number;
  System: ProductDetailData | CustomProductDetailData;
  SelectorType: selectorTp;
}

export const REPLACE_BUFFER_SELECTION = "REPLACE_BUFFER_SELECTION";

export interface ReplaceBufferSelection {
  type: typeof REPLACE_BUFFER_SELECTION;
  bufferSelection: Selection<PlacoOptions> | null;
}

export type LanguageAction = InitLanguageAction | SetLanguageAction;

export const SET_CALPINAGE_DATA = "SET_CALPINAGE_DATA";

export interface CalepinageData {
  Name: string;
  Date: string;
  selections: { status: boolean; list: { Id: string; Time: number }[] };
  calepinage: { status: boolean; list: any; version?: number };
  Id: string;
  time?: number;
  changeFlag: boolean;
}
export interface IntCalpinageData {
  buffer: CalepinageData;
  data: { [key: string]: CalepinageData };
}

export interface InitCalpinageAction {
  type: typeof SET_CALPINAGE_DATA;
  calpinageData: CalepinageData;
}

export const SET_CALPINAGE_SELECTION = "SET_CALPINAGE_SELECTION";
export interface SetCalpinageSelection {
  type: typeof SET_CALPINAGE_SELECTION;
  selectionData: { Id: string; Time: number }[];
}

export const RESET_CALPINAGE_SELECTION = "RESET_CALPINAGE_SELECTION";
export interface ResetCalpinageSelection {
  type: typeof RESET_CALPINAGE_SELECTION;
}

//Reperage
export const SET_REPERAGE_DATA = "SET_REPERAGE_DATA";
// export interface InitReperageData {
//     modelName: string;
//     duplicated: number;
//     documentId: string;
//     name: string | null;
//     documentDate: string;
//     selections: { status: boolean; list: Selection<PlacoOptions>[] };
//     data: { status: boolean; list: any[] };
// }
export interface ReperageData {
  Name: string;
  Date: string;
  selections: { status: boolean; list: { Id: string; Time: number }[] };
  reperage: { status: boolean; list: any };
  revitSetup: { status: boolean; list: any; saveExistingReperage: boolean };
  Id: string;
  time?: number;
  changeFlag: boolean;
}

export interface IntReperageData {
  buffer: ReperageData;
  data: { [key: string]: ReperageData };
}

export interface InitReperageAction {
  type: typeof SET_REPERAGE_DATA;
  reperageData: ReperageData;
}

export const SET_REPERAGE_SELECTION = "SET_REPERAGE_SELECTION";
export interface SetReperageSelection {
  type: typeof SET_REPERAGE_SELECTION;
  selectionData: { Id: string; Time: number }[];
}

export const SET_METRES_SELECTION = "SET_METRES_SELECTION";
// export interface SetMetresSelection {
//     type: typeof SET_METRES_SELECTION;
//     selectionData : Selection<PlacoOptions>[]
// }

export interface MetresAction {
  type: typeof SET_METRES_SELECTION;
  metresData: string[];
}

export const CLEAR_METRES_SELECTION = "CLEAR_METRES_SELECTION";
export interface ClearMetresAction {
  type: typeof CLEAR_METRES_SELECTION;
}

export const SET_DOSSIER_DATA = "SET_DOSSIER_DATA";
export interface InitDossierData {
  modelName: string;
  duplicated: number;
  documentId: string;
  name: string | null;
  documentType: string;
  documentDate: string;
  time?: number;
  bufferTime: number;
  selections: { status: boolean; list: Selection<PlacoOptions>[] };
  data: { status: boolean; list: any[] };
  downloadDAE: any[];
  downloadObj: any[];
  duplicatedActiveRow: any;
}

export interface InitDossierAction {
  type: typeof SET_DOSSIER_DATA;
  dossierData: InitDossierData;
}

export const SET_DOSSIER_SELECTION = "SET_DOSSIER_SELECTION";
export interface SetDossierSelection {
  type: typeof SET_DOSSIER_SELECTION;
  selectionData: Selection<PlacoOptions>[];
}

export const SET_ELEMENT_PARAMETERS = "SET_ELEMENT_PARAMETERS";

export interface SetElementParametersAction {
  type: typeof SET_ELEMENT_PARAMETERS;
  value: ElementParameter[];
}

export type Selection<T extends RowOptions> = {
  Name: string;
  Id: string;
  Duplicated: number;
  Zone: boolean;
  SelectionByType: {
    wall: SelectionGroup<T>;
    ceiling: SelectionGroup<T>;
    others: SelectionGroup<T>;
  };
  Date: string;
  Levels: string[];
  Update: boolean;
  Color: any;
  RevitView?: string;
  Time: number;
};

export type SelectionGroup<T extends RowOptions> = {
  Type: selectorTp;
  Rows: GenericMappingRow<T>[];
};

export type Language = "French" | "English";

export interface IntSuppressionData {
  walls: string[];
  ceilings: string[];
}

export const SUPPRESSION_SET_WALL = "SUPPRESSION_SET_WALL";
export interface suppressionSetWall {
  type: typeof SUPPRESSION_SET_WALL;
  walls: string[];
  ceilings: string[];
}

export const SET_PROJECTDATA = "SET_PROJECTDATA";
export interface projectDataAction {
  type: typeof SET_PROJECTDATA;
  data: initProjectData;
}

export interface Property {
  title: string;
  value?: any;
  onClick?: any;
}

export const SET_HISTORY_PATH = "SET_HISTORY_PATH";

export interface historyPathAction {
  type: typeof SET_HISTORY_PATH;
  data: string;
}

export const SET_ERROR_LOG = "SET_ERROR_LOG";
export const UNSET_ERROR_LOG = "UNSET_ERROR_LOG";
