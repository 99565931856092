export const requiredAttributeDefaultArray = [
  "GFR-Name cover",
  "GFR-Fire protection",
  "GFR-Sound reduction",
  "GFR-Partitionwall type",
  "GFR-Total thickness in mm",
  "GFR-Reaction to fire of board cover",
  "GFR-Rooms moisture ranking",
  "GFR-Shock resistance",
  "GFR-Support (ceiling)",
  "GFR-Distance between frames (primary framework) (m)",
  "GFR-Maximum insulating material weight (kg/m²)",
  "GFR-Type of ceiling",
  "GFR-Type of grid ceiling",
  "GFR-Maximum height of the plenum",
  "GFR-Random/Regular perforations",
  "GFR-Layout possible",
  "GFR-Layer color",
  "GFR-Works name",
  "GFR-Height limit in m",
  "GFR-Additionnal fire protection",
  "GFR-Additionnal acoustic insulation (dB)",
  "GFR-Support type (wall)",
  "GFR-Occupational shocks resistance (J)",
  "GFR-Profiles (1 or 2)",
  "GFR-Space on ground (mm)",
  "GFR-BIM layers",
  "GFR-Product 3",
  "GFR-Product 2",
  "GFR-Distance between frames (in m)",
  "GFR-Layer number",
  "GFR-Layer content",
  "GFR-Layer name",
  "GFR-Layer thickness mm",
  "A-Solution Width",
  "A-Solution product name",
  "GENERATION_DATE",
  "GFR-Generic name of the solution product",
  "GFR-Solution product description",
  "GFR-Product datasheet link",
  "A-Manufacturer",
  "A-Website URL",
  "A-PIM ID",
  "GFR-Total number of layers",
  "GFR-Type of wall insulation", // à vérifier dans la base
  "GFR-Product datasheet link", // à voir avec : "Plans, schémas, tutoriel de mise en œuvre ou guide d installation",
  "GFR-Typical description",
  "GFR-Surface hardness",
  "GFR-Class of resistance to break-in",
  "GFR-Rw in db",
  "GFR-Acoustic PV number",
  "Gypsum//G-Acoustics absorption alpha_w", // à vérifier
  "GFR-K coefficient or up",
  "GFR-Insulation type",
  "GFR-Insulation thickness",
];
