import React, { useEffect, useState } from "react";
import { api } from "../../../../RevitJS/API";
import AppBody from "./AppBody";

interface Props {}

export enum Routes {
  ROOT = "ROOT",
}

export const LicenseRoot = (props: Props) => {
  const [route, setRoute] = useState(Routes.ROOT);

  useEffect(() => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", urlParams.get("language")!);
    }
  });

  return <AppBody route={route} setRoute={setRoute} />;
};
