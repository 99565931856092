import { AnyAction } from "redux";
import { selectLevelAction, selectTypeAction, createSelectionAction } from "../Actions/types";
import { PlacoOptions } from "../../../../RevitJS/Types/StoreTypes";

type ceilingSelectedTypesAction = selectLevelAction | selectTypeAction | createSelectionAction<PlacoOptions>;

export const ceilingSelectedTypes = (
  ceilingSelectedTypes: string[] = [],
  action: ceilingSelectedTypesAction
): string[] => {
  switch (action.type) {
    case "SELECT_LEVEL":
      if (action.selectorType === "ceiling") {
        return action.selectedTypes;
      }
      return ceilingSelectedTypes;
    case "SELECT_TYPE":
      if (action.selectorType === "ceiling") {
        return action.selectedTypes;
      }
      return ceilingSelectedTypes;
    case "CREATE_SELECTION":
      return [];
    default:
      return ceilingSelectedTypes;
  }
};
