import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { TrainingOneRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "a8468df1-523c-46ca-8fd1-c66a00ac1e5f";

export const TrainingOneTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    900,
    610,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="trainingone"
    >
      <TrainingOneRoot />
    </ParentRoot>
  );
};

export const TrainingOneRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module="trainingone"
    >
      <TrainingOneRoot />
    </ParentRoot>
  );
};

export const TrainingOne: Functionality = {
  Name: {
    French: "TrainingOne",
    English: "TrainingOne",
    German: "TrainingOne",
    Italian: "TrainingOne",
    Indonesian: "Calepiner",
  },
  Trigger: TrainingOneTrigger,
  ShortDesc: {
    French: "For Manali",
    English: "For Manali",
    German: "For Manali",
    Italian: "For Manali",
    Indonesian: "For Manali",
  },
  Id: functionalityId,
  Icon: "/PLACO_ICONS_New/Calepiner.png",
  RootComponent,
  ParentId: "0",
};
