import React from "react";
import VueCalepinage from "./vueCalepinage";

const App = (props: {route: string, setRoute: any}) => {


  switch(props.route)
  {
    case "ROOT":
      return <VueCalepinage setRoute={props.setRoute}/>   
    default:
      return <VueCalepinage setRoute={props.setRoute}/>
  }
}

export default App;
