import React from "react";
import { useForm } from "react-hook-form";
import { Form, Segment, Grid, Button } from "semantic-ui-react";
import "../../../Resources/Technical.css";
import "../../../Resources/FormPanel.css";
import { FormPanels } from "../Form/FormPanels";
import wordingJson from "../../../Resources/wording.json";
import { bimStorage, storageKey } from "../../../../../../BIMStore";

export const SupplimentaryForm = (props: any) => {
  const { infoFormData } = props;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    criteriaMode: "firstError",
    defaultValues: infoFormData,
  });

  const onSubmit = async (data: any) => {
    console.log("supp", data);
    await bimStorage.saveAddInfoForm(data);
    props.setRoute("PROJECT_INFO_FORM");
  };

  const onRegisterBackClick = () => {
    props.setRoute("PROJECT_INFO_FORM");
  };
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "1em" }}>
        <Segment className="formContainer">
          <Grid>
            {props.data.map((item: any, index: number) => {
              return (
                <Grid.Row
                  columns={item.length > 2 ? item.length : 2}
                  centered
                  style={{ padding: 0 }}
                >
                  <FormPanels
                    {...props}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    count={index}
                  />
                </Grid.Row>
              );
            })}
          </Grid>
        </Segment>
        <div className="infoProjectBtn">
          <Button
            color="orange"
            size="medium"
            style={{ marginLeft: "5%" }}
            onClick={() => onRegisterBackClick()}
          >
            {wordingJson.back["English"]}
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            {wordingJson.confirm["English"]}
          </Button>
        </div>
      </Form>
    </div>
  );
};
