import React from "react";
import { LicenseUI } from "./LicenseUI";
import { Routes } from "./root";

interface Props {
  route: Routes;
  setRoute: any;
}

export const AppBody = (props: Props) => {
  switch (props.route) {
    case Routes.ROOT:
      return <LicenseUI />;
    default:
      return null;
  }
};

export default AppBody;
