import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Country, COUNTRY_OF_CONSTRUCTION } from "../../../assets/constants";
import "./body.scss";

function Body({ projectCountryChanged, projectCountry, t }: IProps) {
    return (
        <div id="project-country-body-container">
            <div className="px-4 py-2">
                <div className="row">
                    <div className="offset-3 col-6">
                        <FormControl>
                            <InputLabel id="country-select-label">
                                {t(COUNTRY_OF_CONSTRUCTION)}
                            </InputLabel>
                            <Select
                                labelId="country-select-label"
                                id="country-simple-select"
                                value={projectCountry}
                                onChange={(event) => {
                                    projectCountryChanged(event.target.value);
                                }}
                            >
                                <MenuItem value={Country.UK}>
                                    {t("UNITED_KINGDOM")}
                                </MenuItem>
                                <MenuItem value={Country.FR}>
                                    {t("FRANCE")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    );
}

type IProps = WithTranslation & {
    projectCountryChanged: Function;
    projectCountry: Country;
};

export default withTranslation(["pam"])(Body);
