import { api } from "../../../../RevitJS/API";
import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  filter,
  cloneDeep,
  find,
  map,
  reduce,
  flattenDeep,
  values,
  uniqBy,
  uniq,
  forEach,
  groupBy,
  flattenDepth,
} from "lodash";
import { MyConfig } from "../../../../Helper";
import { ReperageData } from "../Components/Home";
import { asyncForEach } from "../../../PlacoBIMv3/Selection/Actions";
import { generateRandomColor } from "../../Utils";
import _ from "lodash";
import { async } from "q";

export const createColorizationRow = async (selectionId: any) => {
  const response = await new Promise((resolve, reject) =>
    window.indec.getSelection(parseInt(selectionId), resolve, reject)
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  //await bimStorage.getSelection(parseInt(selectionId));
  console.log("Console in colorization: ", response);
  const wallCombineDetail = reduce(
    response.SelectionDetails,
    function (result: any, value: any, key: any) {
      let resd: any = [];
      forEach(value.Ids, (vi) => {
        resd.push({
          ElementName: value.ElementName,
          Id: vi,
          Solution: value.Solution,
          Oid: value.Solution.Oid,
          ExternalName: "",
        });
      });

      return result.concat(resd);
    },
    []
  );

  const wallIds = map(wallCombineDetail, "Id");
  const wallsData = await api.queries.getWallsData(wallIds);
  const datalist = map(wallsData, (wd) => {
    const wlComine = find(wallCombineDetail, { Id: wd.Id });
    return { ...wd, ...wlComine };
  });
  let clData: any = groupBy(datalist, (item: any) => {
    return [
      item["LevelName"],
      item["WallType"],
      item["Height"],
      item["SolutionName"],
    ];
  });

  let solCodeColor: any = {};
  clData = map(clData, (value, key) => {
    //const isLayout = isLayoutable(value[0].WallType);
    let color = generateRandomColor();

    if (solCodeColor[value[0].Solution.Name]) {
      color = solCodeColor[value[0].Solution.Name];
    } else {
      solCodeColor[value[0].Solution.Name] = color;
    }
    return {
      Ids: map(value, "Id"),
      Floor: value[0].LevelName,
      ElementName: value[0].WallType,
      ElementType: "Wall",
      ElementHeight: value[0].Height / 1000,
      Solution: value[0].Solution,
      Key: key,
      Check: true,
      IsLayout: true,
      Color: color,
    };
  });
  return clData;
};

const getReperageViewName = async (
  existingViewName: string,
  viewType: string,
  createNewPlan: any
) => {
  let nameTobe: string = "";
  let existingName: string;
  if (viewType === "floor") {
    nameTobe = (existingViewName + "_layout_plans_floor").replace(
      /[&\\/\\#,+()$~%.'":*?<>{}]/g,
      ""
    );
  } else if (viewType === "threeD") {
    nameTobe = (existingViewName + "_layout_plans_3D").replace(
      /[&\\/\\#,+()$~%.'":*?<>{}]/g,
      ""
    );
  }

  existingName = nameTobe;

  if (createNewPlan) {
    for (let i = 1; i < 1000; i++) {
      let viewByName = await api.queries.getViewByName(nameTobe);

      if (viewByName) {
        nameTobe = existingName + "_" + i;
      } else {
        break;
      }
    }
  } else {
    return nameTobe;
  }

  return nameTobe;
};

export const generatePlans = async (repData: ReperageData) => {
  try {
    let previousView: any = {
      viewName: null,
      reperageViewName: null,
      type: null,
    };
    let selectedWallType = filter(repData.colorizations, "Check");
    await api.familyEditor.createAndSetParameters("Wall", "Type", "DATA", [
      {
        Id: "0",
        Params: [{ Name: "Système", Type: "Text", Value: "" }],
      },
    ]);

    const imageParameters = map(selectedWallType, (value: any) => {
      let rgb = `${value.Color.r},${value.Color.g},${value.Color.b}`;
      return {
        Name: value.ElementName,
        Type: "Wall",
        Value: rgb,
        TypeSolution: value.Solution.Name,
      };
    });

    let Parameters = [
      {
        Id: "0",
        Params: imageParameters,
      },
    ];

    await api.queries.setScheduleImages(Parameters);

    await api.queries.duplicateViewType("Plan d'étage", "Layout Plan", "floor");

    await api.queries.duplicateViewType("Vue 3D", "Layout Plan 3D", "threeD");

    let viewsOpen: any = [];

    if (repData.revitSetup.floor) {
      const floorRvt = repData.revitSetup.floor;
      await asyncForEach(floorRvt, async (floorPlan: any) => {
        const floorColorization = filter(selectedWallType, {
          Floor: floorPlan.ViewName,
        });
        if (floorColorization.length > 0) {
          const nameTobe = await getReperageViewName(
            floorPlan.ViewName,
            "floor",
            repData.revitSetup.createNewPlan
          );

          let existingViewId = await api.queries.getViewByNameAndType(
            floorPlan.ViewName,
            "floor"
          );
          await api.queries.setActiveView(existingViewId);
          let viewByName = await api.queries.getViewByName(nameTobe);

          if (viewByName) {
            await api.queries.deleteView(viewByName);
          }

          let newlyCreatedView =
            await api.queries.duplicateActiveViewInNewViewType(
              "Layout Plan",
              nameTobe
            );
          const reperageViewId = await api.queries.setActiveView(
            newlyCreatedView
          );

          let activeViewObject = await api.queries.getActiveViewDetails();
          if (activeViewObject.Name === nameTobe) {
            await asyncForEach(floorColorization, async (clr: any) => {
              await colorElements(nameTobe, clr.Ids, {
                Red: clr.Color.r,
                Green: clr.Color.g,
                Blue: clr.Color.b,
              });
            });
          }

          viewsOpen.push({
            viewId: existingViewId,
            reperageViewId: reperageViewId,
            type: "floor",
          });
        }
      });
    }

    if (repData.revitSetup.threeD) {
      const threedRvt = repData.revitSetup.threeD;
      await asyncForEach(threedRvt, async (threeDView: any) => {
        const nameTobe = await getReperageViewName(
          threeDView.ViewName,
          "threeD",
          repData.revitSetup.createNewPlan
        );
        let existingViewId = await api.queries.getViewByNameAndType(
          threeDView.ViewName,
          "threeD"
        );

        await api.queries.setActiveView(existingViewId);

        let viewByName = await api.queries.getViewByName(nameTobe);

        if (viewByName) {
          await api.queries.deleteView(viewByName);
        }

        let newlyCreatedView =
          await api.queries.duplicateActiveViewInNewViewType(
            "Layout Plan 3D",
            nameTobe
          );
        const reperageViewId = await api.queries.setActiveView(
          newlyCreatedView
        );

        viewsOpen.push({
          viewId: existingViewId,
          reperageViewId: reperageViewId,
          type: "threeD",
        });

        let activeViewObject = await api.queries.getActiveViewDetails();
        if (activeViewObject.Name === nameTobe) {
          await asyncForEach(selectedWallType, async (clr: any) => {
            await colorElements(nameTobe, clr.Ids, {
              Red: clr.Color.r,
              Green: clr.Color.g,
              Blue: clr.Color.b,
            });
          });
        }
      });
    }

    viewsOpen.pop();

    await asyncForEach(viewsOpen, async (vie: any, vieInedx: number) => {
      await api.queries.closeView(vie.viewId);
      await api.queries.closeView(vie.reperageViewId);
    });

    await api.queries.deleteSchedule("Layout Plan");
    await api.queries.createSchedule("wall", "Layout Plan");

    api.eventLog.SetEvent({
      data: [
        {
          name: "",
          value: "",
          values: [],
        },
      ],
      eventAction: "Generate",
      eventCategory: "Module Execution",
      eventLabel: "Repérage",
      module: "PLACOBIM",
    });
  } catch (ex) {
    console.log("exception", ex);
  }
};

const colorElements = async (
  filterName: string,
  elementIds: any,
  rgbColor: any
) => {
  const count = Math.floor(Math.random() * 100 + 1);

  const simpleFramesIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Placo_Ossature",
        },
        Rule: "Equals",
      },
      {
        Param: {
          Name: "id",
          Type: "Integer",
          Value: elementIds,
        },
        Rule: "Includes",
      },
    ],
    null
  );
  const doubleFramesIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Placo_Ossature-Double",
        },
        Rule: "Equals",
      },
      {
        Param: {
          Name: "id",
          Type: "Integer",
          Value: elementIds,
        },
        Rule: "Includes",
      },
    ],
    null
  );

  const bottomRailsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Placo_Rail-bas",
        },
        Rule: "Equals",
      },
      {
        Param: {
          Name: "id",
          Type: "Integer",
          Value: elementIds,
        },
        Rule: "Includes",
      },
    ],
    null
  );
  const upperRailsIds = await api.queries.filterElements(
    "Generic",
    [
      {
        Param: {
          Name: "Name",
          Type: "Builtin",
          Value: "Placo_Rail-haut",
        },
        Rule: "Equals",
      },
      {
        Param: {
          Name: "id",
          Type: "Integer",
          Value: elementIds,
        },
        Rule: "Includes",
      },
    ],
    null
  );
  let framesIds = simpleFramesIds.concat(doubleFramesIds);
  const railsIds = bottomRailsIds.concat(upperRailsIds);
  const wallPartsIds = await api.queries.getPartsIds(elementIds);
  const partsIds = flattenDeep(map(wallPartsIds, "partsIds"));

  let filter = await api.queries.createSelectionFilterForEye(
    filterName + "_" + count,
    elementIds
  );

  await api.viewHandler.setFilterColor(filter, rgbColor);

  if (partsIds.length > 0) {
    let PartsFilter = await api.queries.createSelectionFilterForEye(
      filterName + "_" + count + "_Parts",
      partsIds
    );
    await api.viewHandler.setFilterColor(PartsFilter, rgbColor);
  }
  if (framesIds.length > 0) {
    let FramesFilter = await api.queries.createSelectionFilterForEye(
      filterName + "_" + count + "_Frames",
      framesIds
    );
    await api.viewHandler.setFilterColor(FramesFilter, rgbColor);
  }

  if (railsIds.length > 0) {
    let RailsFilter = await api.queries.createSelectionFilterForEye(
      filterName + "_" + count + "_Rails",
      railsIds
    );
    await api.viewHandler.setFilterColor(RailsFilter, rgbColor);
  }
};

export const intitalRepData = {
  selectionId: null,
  selectionStatus: false,
  colorizationStatus: false,
  revitSetupStatus: false,
  colorizations: null,
  revitSetup: { floor: null, threeD: null, createNewPlan: false },
};

export const checkForWallType = async (calepinageStatus: any) => {
  let wallRows: any = flattenDepth(
    JSON.parse(JSON.stringify(calepinageStatus.colorizations)),
    1
  );

  let wallIds = map(wallRows, (ele) => {
    return ele.Ids;
  });

  wallIds = flattenDeep(wallIds);

  let selectionWalls = await new Promise((resolve, reject) =>
    window.indec.getSelection(
      parseInt(calepinageStatus.selectionId),
      resolve,
      reject
    )
  )
    .then((x: any) => {
      return x;
    })
    .catch((ex: any) => {
      console.error(ex);
      return [];
    });
  // await bimStorage.getSelection(parseInt(calepinageStatus.selectionId));

  let selectionWallsIds = map(selectionWalls.SelectionDetails, (ele) => {
    return ele.Ids;
  });

  selectionWallsIds = flattenDeep(selectionWallsIds);

  let wallsDetails = await api.queries.getWallsData(wallIds);

  //to check if wall deleted from selection
  let checkIfWallDeleted =  _.isEqual(_.sortBy(selectionWallsIds), _.sortBy(wallIds));

  //get all walls from modal
  const wallTree = await api.selection.elementsByLevelAndType("wall");
  const getAllTypeIdsfromSelection = async (typeTree: any) => {
    let finalIds: any = [];
    typeTree.forEach((level: any) => {
      const levelTypeIds: any = _.flatten(
        level.Elements.map((e: any) => {
          return e.Ids;
        })
      );
      finalIds.push(levelTypeIds);
    });
    return finalIds;
  };

  const allWallIds: any = await getAllTypeIdsfromSelection(wallTree.Tree);
  let onlyWallIds = flattenDeep(allWallIds);

  //to check if wall is deleted by model/exterior
  let checkIfWallDeletedByModel: boolean = false;
  forEach(selectionWalls.SelectionDetails, (ele: any) => {
    forEach(ele.Ids, (i: any) => {
      if (!onlyWallIds.includes(i)) {
        checkIfWallDeletedByModel = true;
        return;
      }
    });
  });

  //to check if solution is changed from selection
  let solutionArray: any = [];
  forEach(selectionWalls.SelectionDetails, (stype) => {
    forEach(stype.Ids, (id) => {
      solutionArray.push({
        id: id,
        technicalName: stype.Solution.Name,
      });
    });
  });
  // console.log(solutionArray);

  let dictionaryForType = Object.assign(
    {},
    ...wallsDetails.map((x: any) => ({ [x.Id]: x.WallType }))
  );

  let dictionaryForSolution = Object.assign(
    {},
    ...solutionArray.map((x: any) => ({ [x.id]: x.technicalName }))
  );

  let countChanges: any = 0;
  forEach(wallRows, (wall: any, index: number) => {
    forEach(wall.Ids, (eachId: any, indexId: number) => {
      if (dictionaryForType[eachId] !== undefined) {
        if (dictionaryForType[eachId] !== wall.ElementName) {
          countChanges = countChanges + 1;
        }
      }
      if (dictionaryForSolution[eachId] !== undefined) {
        if (dictionaryForSolution[eachId] !== wall.Solution.Name) {
          countChanges = countChanges + 1;
        }
      }
    });
  });

  if (countChanges > 0 || !checkIfWallDeleted || checkIfWallDeletedByModel) {
    return true;
  } else {
    return false;
  }
};
