import React from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { ELayoutMethod } from "../../Types";

interface Props {
  show: boolean;
  onSave: (method: ELayoutMethod) => void;
  onClose: () => void;
}

export const LayoutMethodPopup = (props: Props) => {
  const { show, onSave, onClose } = props;

  return (
    <div>
      <div style={{ height: "100%" }}>
        <Grid.Column>
          <div style={{ marginTop: 12 }}>
            <Modal
              size="tiny"
              dimmer="blurring"
              className="modal-close"
              closeIcon
              open={show}
              onClose={() => onClose()}
            >
              <Modal.Content>
                <Grid columns={2} padded={true}>
                  <Grid.Row style={{ padding: "2.5rem 0.5rem 1.5rem" }}>
                    <Grid.Column>
                      <Button
                        style={{ minHeight: 80 }}
                        fluid
                        color="blue"
                        onClick={() => {
                          onSave(ELayoutMethod.NEW);
                        }}
                      >
                        {"New"}
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        style={{ minHeight: 80 }}
                        fluid
                        color="blue"
                        onClick={() => {
                          onSave(ELayoutMethod.OLD);
                        }}
                      >
                        {"Old"}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          </div>
        </Grid.Column>
      </div>
    </div>
  );
};

export default LayoutMethodPopup;
