import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Container,
  Grid,
  Icon,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { bimStorage } from "../../../../BIMStore";
import { SelectionTable } from "../../Calepinage/Components/Table/Selection";
import { useForm } from "react-hook-form";
import { api } from "../../../../RevitJS/API";
import { wait } from "../../../../RevitJS/Helpers";
import SaveProject from "../../CommonModules/SaveProject";
interface Props {
  setRoute: any;
  // onSelect: (selectionId: any) => void;
  selectionId: any;
}
export const Selection = (props: Props) => {
  // const { setRoute, onSelect, selectionId } = props;
  const [erasing, setErasing] = useState<boolean>(false);
  const { setRoute, selectionId } = props;
  const [selectionList, setSelectionList] = useState<any>(null);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, dirtyFields },
  } = useForm({
    defaultValues: {
      selections: [],
    },
  });

  const listModules = async () => {
    const modules = await bimStorage.listSelection();
    if (modules) {
      setSelectionList(modules);
    }
  };

  const onSubmit = async (formData: any) => {
    setErasing(true);
    const selectionIdsArray = formData.selections;
    for (const selectionId of selectionIdsArray) {
      const selectionIds = parseInt(selectionId);
      let selectionData = await new Promise((resolve, reject) =>
        window.indec.getSelection(selectionIds, resolve, reject)
      )
        .then((x: any) => {
          return x;
        })
        .catch((ex: any) => {
          console.error(ex);
          return [];
        }); // bimStorage.getSelection(selectionIds);
      let tree: any = selectionData.SelectionDetails.map((item: any) => {
        return item.Ids;
      });
      const idsToDelete: string[] = _.uniq(_.flatMap(tree));
      console.log(idsToDelete);
      const deleteResponse = await api.queries.deletePartsWalls(idsToDelete);
    }

    setRoute("");
    await wait(1000);
    setErasing(false);
    api.windowsHandler.closeWindow();

    // onSelect(formData.selections);
  };

  const toggleModal = () => {
    setConfirmModal(!confirmModal);
  };
  const onYesAction = () => {
    toggleModal();
    api.queries.callSaveDialog();
    api.windowsHandler.closeWindow();
  };
  useEffect(() => {
    const getProjectId = async () => {
      const projectId = await bimStorage.onProjectData();
      if (projectId) {
        listModules();
      } else {
        setSelectionList([]);
        toggleModal();
      }
    };
    getProjectId();
  }, []);

  if (!selectionList) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (erasing) {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Loading..."}></Loader>
      </Dimmer>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Segment style={{ height: "calc(100vh - 30px)" }}>
        {selectionList.length === 0 ? (
          <div>No saved selection</div>
        ) : (
          <SelectionTable
            data={selectionList}
            register={register}
            selectionId={selectionId}
            multiSelection={true}
          />
        )}
        <SaveProject
          toggleModal={toggleModal}
          confirmModal={confirmModal}
          onYesAction={onYesAction}
        />
      </Segment>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          padding: 5,
          cursor: "pointer",
          minWidth: "0px",
          left: "10px",
          zIndex: "2",
        }}
        onClick={() => {
          props.setRoute("SELECTION_HOME");
        }}
      >
        <Icon
          name="edit"
          color="blue"
          size="big"
          style={{ fontSize: "1.5em" }}
        />
        <span style={{ color: "rgb(14,110,184)", textTransform: "capitalize" }}>
          create / modify selection
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <span>
          <Button
            color="orange"
            onClick={() => {
              setRoute("");
              api.windowsHandler.closeWindow();
            }}
            content={"Back"}
          ></Button>
        </span>
        <span>
          <Button
            color="blue"
            content={"Validate"}
            type={"submit"}
            disabled={!isDirty}
          ></Button>
        </span>
      </div>
    </form>
  );
};
