import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import Root from "./Components/root";
import QuantityProvider from "./context/quantityContext";
import { GlobalStateProvider } from "./context/golbalStateContext";

export const functionalityId = "d5bda177-5473-49d4-a665-ce8045e6471d";

export const quantifierParRatioTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    920,
    610,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={"Quantifier Par Ratio"}
    >

      <QuantityProvider>
      <GlobalStateProvider  >
        <Root />
      </GlobalStateProvider>
      </QuantityProvider>
    </ParentRoot>
  );
};

export const QuantifierParRatioRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <QuantityProvider>
      <GlobalStateProvider >
        <Root />
        </GlobalStateProvider>
      </QuantityProvider>
    </ParentRoot>
  );
};
export default QuantifierParRatioRootComponent;

export const QuantifierParRatio: Functionality = {
  Name: {
    French: "Quantifier par ratio",
    English: "Quantify by ratio",
    German: "Quantifier par ratio",
    Italian: "Quantifier par ratio",
    Indonesian: "Quantifier par ratio",
  },
  Trigger: quantifierParRatioTrigger,
  ShortDesc: {
    French: "Télécharger les quantitatifs par ratio",
    English: "Download the quantities by ratio",
    German: "Télécharger les quantitatifs par ratio",
    Italian: "Télécharger les quantitatifs par ratio",
    Indonesian: "Télécharger les quantitatifs par ratio",
  },
  Id: functionalityId,
  Icon: "/quantitatifsparratio.png",
  RootComponent,
  ParentId: "0",
};
