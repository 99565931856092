import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "5085ee0a-3002-4610-9b48-4c4f440065b5";

export const miterJointTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const MiterJointRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const MiterJoint: Functionality = {
  Name: {
    English: "Miter joint",
    Indonesian: "Miter joint",
  },
  Trigger: miterJointTrigger,
  ShortDesc: {
    English: "Joindre des murs",
    Indonesian: "Joindre des murs",
  },
  Id: functionalityId,
  Icon: "/Facade/miterJoint.png",
  RootComponent,
  ParentId: "0",
};
