import React, { useState } from "react"
import { Tab } from "semantic-ui-react"
import { DossierComponent } from "./dossierComponent";
import { filter } from "lodash";

interface Props {
    moduleData: any[];
    selectionId: any[];
    selectedRecords: any[];
    setSelectedRecords: any;
    translations: any;
    language: string;
    setSelectionID: any;
    getDossierData: any;
}
export const DossierTabComponent = (prop: Props) => {
    const [documentType, setDocumentType] = useState<string | undefined>('DAO');
    const panes = [
        {
            menuItem: 'DAO',
            name: 'DAO',
            index: 0,
            render: () => (
                <Tab.Pane className="ui attached tabular menu" style={{ padding: '0px' }}>
                    <DossierComponent moduleData={prop.moduleData} selectionId={prop.selectionId} selectedRecords={prop.selectedRecords} setSelectedRecords={prop.setSelectedRecords} documentType={documentType} translations={prop.translations} language={prop.language}></DossierComponent>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'DAE',
            name: 'DAE',
            index: 1,
            render: () => (
                <Tab.Pane className="ui attached tabular menu" style={{ padding: '0px' }}>
                    <DossierComponent moduleData={prop.moduleData} selectionId={prop.selectionId} selectedRecords={prop.selectedRecords} setSelectedRecords={prop.setSelectedRecords} documentType={documentType} translations={prop.translations} language={prop.language}></DossierComponent>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'DOE',
            name: 'DOE',
            index: 2,
            render: () => (
                <Tab.Pane className="ui attached tabular menu" style={{ padding: '0px' }}>
                    <DossierComponent moduleData={prop.moduleData} selectionId={prop.selectionId} selectedRecords={prop.selectedRecords} setSelectedRecords={prop.setSelectedRecords} documentType={documentType} translations={prop.translations} language={prop.language}></DossierComponent>
                </Tab.Pane>
            )
        }
    ];

    const handleTabChange = async (activeTab: any) => {
        const documentName = panes.find((a) => a.index === activeTab.activeIndex)?.name;
        setDocumentType(documentName);
        await prop.getDossierData(documentName);
    };

    return (
        <div>
            <Tab
                activeIndex={
                    panes.find((a: any) => a.name === documentType)?.index
                }
                panes={panes}
                className="tabMenu"
                onTabChange={(event, data) => {
                    handleTabChange(data);
                }}
            />
        </div>
    )
}

