import {
  find,
  filter,
  map,
  flattenDeep,
  keysIn,
  includes,
  pickBy,
  groupBy,
  uniq,
  orderBy,
  forEach,
} from "lodash";
import { api } from "../../../../RevitJS/API";
import { fetchPlacoCeilingDataInGroupSplit } from "../../Calpinage/Helpers/placoCeilingHelper";
import { processNewWallData } from "./processWall";

export const groupCeilingsDataFromSelection = async (
  selections: any,
  selectSelection: string[],
  config: any
) => {
  let selectionList = filter(selections, function (o) {
    return includes(selectSelection, o.Id);
  });

  /// 1. Extract selected wall ids from selection.
  let ceilingRows = flattenDeep(
    map(selectionList, (ele) => {
      return map(ele.SelectionByType.ceiling.Rows, (row) => {
        return {
          ...row,
          zone: ele.Zone ? ele.Name : "",
        };
      });
    })
  );

  let ceilingIdsExt = flattenDeep(
    map(ceilingRows, (ele) => {
      if (ele.RevitSelection) {
        return ele.RevitSelection.Ids;
      }
    })
  );

  /// 2. Check for duplicate wall ids
  let dupliactesCeilingIds = keysIn(
    pickBy(groupBy(ceilingIdsExt), (x) => {
      return x.length > 1;
    })
  );

  if (dupliactesCeilingIds.length === 0) {
    /// 3. Get walls data from revit
    let ceilingsData = await api.queries.getCeilingsData(ceilingIdsExt);

    /// 4. Extract solution ids
    let solutionIds = uniq(
      flattenDeep(
        map(ceilingRows, (ele) => {
          return ele.Options.MappedSystem.oid;
        })
      )
    );

    /// 5. Get solution data from api respected to solution ids
    const { extractedSolutionProduct } =
      await fetchPlacoCeilingDataInGroupSplit(solutionIds, config);

    ceilingsData = orderBy(ceilingsData, ["LevelElevation"], ["asc"]);

    let grpCeilingsData: any[] = [];

    forEach(ceilingsData, (value: any, index: number) => {
      let ceilingRow = find(ceilingRows, (walRow: any) => {
        return includes(walRow.RevitSelection.Ids, value.Id);
      });

      let height: String = parseFloat((value.Height / 1000).toString()).toFixed(
        2
      );

      let oneValue = find(grpCeilingsData, {
        LevelId: value.LevelId,
        Zone: ceilingRow.zone,
        CeilingType: value.CeilingType,
        Height: height,
        PlacoSolution: ceilingRow.Options.MappedSystem.longName
          ? ceilingRow.Options.MappedSystem.longName
          : ceilingRow.Options.MappedSystem.translation,
      });

      if (oneValue) {
        let ids = oneValue.Ids;
        ids.push(value.Id);
        find(grpCeilingsData, {
          LevelId: value.LevelId,
          Zone: ceilingRow.zone,
          CeilingType: value.CeilingType,
          Height: height,
          PlacoSolution: ceilingRow.Options.MappedSystem.longName
            ? ceilingRow.Options.MappedSystem.longName
            : ceilingRow.Options.MappedSystem.translation,
        }).Ids = ids;
      } else {
        grpCeilingsData.push({
          Height: height,
          LevelName: value.LevelName,
          PlacoSolution: ceilingRow.Options.MappedSystem.longName
            ? ceilingRow.Options.MappedSystem.longName
            : ceilingRow.Options.MappedSystem.translation,
          PlacoSolutionId: ceilingRow.Options.MappedSystem.oid,
          PlacoSolutionHeight:
            ceilingRow.Options.MappedSystem["GFR-Height limit in m"],
          Zone: ceilingRow.zone,
          CeilingType: value.CeilingType,
          LevelElevation: value.LevelElevation,
          LevelId: value.LevelId,
          Ids: [value.Id],
          montant: ceilingRow.montant,
          plaque: ceilingRow.plaque,
        });
      }
    });

    const proceessCeilings = processNewWallData(
      grpCeilingsData,
      extractedSolutionProduct
    );
    /// 7. Set state for wallsData, SolutionProducts
    return proceessCeilings;
  }
  return null;
};
