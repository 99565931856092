import React from "react";
import { api } from "../../../RevitJS/API";
import { pluginId } from "../index";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { OverlapRoot } from "./Components/root";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";

export const functionalityId = "a2ae8520-fd6b-4b5b-8176-16d1b4a7647d";

export const overlapTrigger = (config: any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <OverlapRoot />
    </ParentRoot>
  );
};

export const OverlapRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <OverlapRoot />
    </ParentRoot>
  );
};

export const Overlap: Functionality = {
  Name: {
    French: "Superposer",
    English: "Overlap",
    German: "Superposer",
    Italian: "Superposer",
    Indonesian: "Superposer",
  },
  Trigger: overlapTrigger,
  ShortDesc: {
    French: "Modèle de base et calepinage",
    English: "Basic model and layout",
    German: "Modèle de base et calepinage",
    Italian: "Modèle de base et calepinage",
    Indonesian: "Modèle de base et calepinage",
  },
  Id: functionalityId,
  RootComponent,
  Icon: "/PLACO_ICONS_New/Superposer.png",
  ParentId: "9922aa18-a188-49d7-af00-faf69e903659",
};
