import { Action } from 'redux'

export const SET_DEFAULT_CONFIGURATION = 'SET_DEFAULT_CONFIGURATION'

export const LOAD_PROPERTIES = "LOAD_PROPERTIES";
export const LOAD_PROPERTIES_SUCCESS = "LOAD_PROPERTIES_SUCCESS";
export const LOAD_PROPERTIES_ERROR = "LOAD_PROPERTIES_ERROR";
export const SET_PROPERTIES_DETAILS = 'SET_PROPERTIES_DETAILS'
export const SELECT_PROPERTIES = 'SELECT_PROPERTIES'

export interface Property {
  title: string
  value?: any
  onClick?: any
}

export interface PropertiesState {
  loadingProperties: boolean,
  properties: any[],
  currentSelectedProperties: any[],
  currentPropertiesDetail: any[],
  data: {
    liningWallsProperties: any[],
    partitionsProperties: any[],
    ceilingsProperties: any[],
    gainesProperties: any[],
  },
  selectedProperties: {
    liningWallsProperties: any[],
    partitionsProperties: any[],
    ceilingsProperties: any[],
    gainesProperties: any[],
  }
  isDefaultConfiguration: boolean,
  systemTypeID: any,
}

interface LoadPropertiesAction extends Action {
  type: typeof LOAD_PROPERTIES
}


interface LoadPropertiesSuccessAction extends Action {
  type: typeof LOAD_PROPERTIES_SUCCESS;
  properties: any[],
  liningWallsProperties: any[],
  gainesProperties: any[],
  partitionsProperties: any[],
  ceilingsProperties: any[],
  currentPropertiesDetail: any[],
  currentSelectedProperties: any,
  selectedProperties: any
}

interface SelectPropertiesAction extends Action {
  type: typeof SELECT_PROPERTIES;
  currentSelectedProperties: any[],
  currentPropertiesDetail: any[],
  selectedProperties: any,
  data: any,

}

interface LoadPropertiesErrorAction extends Action {
  type: typeof LOAD_PROPERTIES_ERROR;
}


interface SetDefaultConfigurationAction extends Action {
  type: typeof SET_DEFAULT_CONFIGURATION
  defaultConfiguration: boolean
}

interface SetPropertiesDetailsAction extends Action {
  type: typeof SET_PROPERTIES_DETAILS,
  currentPropertiesDetails: any[],
  selectedProperties: any[],
  systemType: number
}


export type PropertiesActionTypes = SetDefaultConfigurationAction | LoadPropertiesAction | LoadPropertiesSuccessAction | LoadPropertiesErrorAction | SetPropertiesDetailsAction | SelectPropertiesAction

