import React from "react";
import ObjectGenerator from "./ObjectGenertor";
import ProductList from "./ProductList";
import BIMProductList from "./BIMProductList";
import ProductInfoBox from "./ProductInfoBox";
import SelectedProducts from "./SelectedProducts";

const App = (props: { route: string; setRoute: any }) => {
  switch (props.route) {
    case "ROOT":
      return <ObjectGenerator setRoute={props.setRoute} />;
    case "PRODUCT_LIST":
      return <ProductList setRoute={props.setRoute} />;
    case "BIM_PRODUCT_LIST":
      return <BIMProductList setRoute={props.setRoute} />;
    case "SELECTED_PRODUCTS":
      return <SelectedProducts setRoute={props.setRoute} />;
    default:
      return <ObjectGenerator setRoute={props.setRoute} />;
  }
};

export default App;
