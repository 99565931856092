import { Plugin } from "../../RevitJS/Types/StoreTypes";
import {MiterJoint} from './MiterJoint';
import { CalepinageF4 } from "./Calepinage";
import { Quantitatifs } from "./Quantitatifs";
import './Resources/style.scss';
import { Superposer } from "./Superposer";
import { Supprimer } from "./Supprimer";
import { VueCalepinage } from "./VueDeCalepinage";
import { VueOrigine } from "./Vuedorigine";

export const pluginId = "6313b2e8-6e86-4dfd-bf3e-8c27c6212024";

export const Facade: Plugin = {
  TechnicalName: "Facade",
  Name: "F4",
  Icon: "/f4-Icon.png",
  ShortDesc: {
    English: "Configurateur BIM"
  },
  Id: pluginId,
  Color: "#000000",
  Functionalities: [
    MiterJoint,
    CalepinageF4,
    Quantitatifs,
    Supprimer,
    VueOrigine,
    Superposer,
    VueCalepinage,
  ],
};
