import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Message,
  Segment,
  Icon,
  Table,
} from "semantic-ui-react";

const BatchPanel = () => {
  return (
    <>
      <Table.Row>
        <Table.Cell style={{ width: "5%" }}>
          <Icon
            color={"grey"}
            size="big"
            name={"clock outline"}
            // loading={loadingIcon}
          />
        </Table.Cell>
        <Table.Cell style={{ width: "75%" }}>
          {
            "Isolation des bardages horizontaux - Système Cladisol - CLADIPAN 32 110 mm + ISOBARDAGE 32 120 mm - plateau 450x70"
          }
        </Table.Cell>
        <Table.Cell style={{ width: "10%" }}>
          <span>
            <div
              //onClick={() => }
              style={{ color: "teal", cursor: "pointer" }}
            >
              <Icon name="eye" size="large" />
            </div>
          </span>
        </Table.Cell>
        <Table.Cell style={{ width: "10%" }}>
          <Button size="mini" color="red">
            Stop
          </Button>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default BatchPanel;
