export enum PAM_SYSTEM_TYPE_KEYS {
  WALL = "WALL",
  FLOOR = "FLOOR"
  // CEILING = "CEILING",
  // LINING_WALLS = "LINING_WALLS",
}

export enum CategoryTypeEnum {
  wall = <any>'Wall',
  floor = <any>'Floor',
}