import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import { Root } from "./components/root";

export const functionalityId = "61c4c408-6c54-11eb-9439-0242ac130002";

export const drawTrigger = async (config: any) => {
  api.windowsHandler.openWindow(
    900,
    520,
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );

  api.eventLog.SetEvent({
    data: [
      {
        name: "",
        value: "",
        values: [],
      },
    ],
    eventAction: "Open",
    eventCategory: "Module Access",
    eventLabel: "Updates",
    module: "PRESCRIBIM",
  });
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root />
    </ParentRoot>
  );
};

export const UpdateSystemRootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
    >
      <Root />
    </ParentRoot>
  );
};

export const Updates: Functionality = {
  Name: {
    French: "Mettre à jour",
    English: "Updates",
    German: "Mettre à jour",
    Italian: "Mettre à jour",
    Indonesian: "Mettre à jour",
  },
  Trigger: drawTrigger,
  ShortDesc: {
    French: "Mettre à jour les données",
    English: "Update systems data",
    German: "Mettre à jour les données",
    Italian: "Mettre à jour les données",
    Indonesian: "Mettre à jour les données",
  },
  Id: functionalityId,
  Icon: "./pluginIcons_V3/PlacoBIM_Icon_Library_Update.png",
  RootComponent,
  ParentId: "0",
};
