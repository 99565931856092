import React, { Fragment } from "react";
import {
  Grid,
  Menu,
  Button,
  Segment,
  Label,
  Checkbox,
  Tab,
  Header,
} from "semantic-ui-react";
import { map } from "lodash";

interface Props {
  data: any;
  showHeader: boolean;
  headerText?: string;
  onChecked: (item: any, checked: boolean) => void;
  onAllChecked: (items: any, checked: boolean) => void;
  allChecked: boolean;
  disabled: boolean;
}

const GroupColumn = (props: Props) => {
  const {
    data,
    showHeader,
    headerText,
    onChecked,
    onAllChecked,
    allChecked,
    disabled,
  } = props;
  return (
    <Grid.Column>
      {showHeader && (
        <Header
          as="h3"
          textAlign="center"
          // color={color}
          style={{ fontSize: "14px", marginBottom: "2px" }}
        >
          {headerText}
        </Header>
      )}
      <Menu
        style={{
          maxHeight: "45vh",
          overflow: "auto",
          marginTop: "7px",
        }}
        vertical
        size="small"
        fluid
      >
        <Fragment>
          {map(data, (item: any, key: any) => {
            return (
              <Menu.Item
                name="inbox"
                style={{ padding: "8px 15px" }}
                className="GroupSelectorMenu"
                key={key}
              >
                {item.Ids && <Label color="blue">{item.Ids.length}</Label>}
                <Checkbox
                  onChange={(e, { checked }) => {
                    onChecked(item, checked!);
                  }}
                  checked={item.Checked}
                  style={{ maxWidth: "80%" }}
                  label={`${item.ElementName || item.Name}`}
                  disabled={disabled}
                />
              </Menu.Item>
            );
          })}
        </Fragment>
      </Menu>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            onAllChecked(data, !allChecked);
          }}
          circular
          primary
          icon={!allChecked ? "check" : "times"}
          disabled={disabled}
        />
      </div>
    </Grid.Column>
  );
};

export default GroupColumn;
