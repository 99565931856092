import Excel from "exceljs";
import { forEach, map } from "lodash";
import { JsonLevel } from "../../../../RevitJS/Types/RevitTypes";
import {
  addBillOfQuantityTable,
  filterProjectTree,
  generatelLevelRows,
  generateSchedulesRows,
} from "../../WallSchedules/Helpers";
import { setProjectInfo } from "./OverviewByRoom";

export const overviewByProject = async (
  bomSheet: Excel.Worksheet,
  ceilingTree: any,
  ceilingLevels: JsonLevel[],
  ceilingIds: string[]
) => {
  /// function will insert project information in sheet.
  buildProjectInfoSection(bomSheet);

  // total ceiling surfaces
  bomSheet.mergeCells("A10:H10");
  bomSheet.getRow(10).getCell("A").value = "Total ceiling surfaces in project";
  bomSheet.getRow(10).getCell("A").font = {
    name: "Arial",
    size: 10,
    bold: true,
    color: { argb: "000000" },
  };
  bomSheet.getRow(10).getCell("A").border = {
    top: { style: "thin" },
    bottom: { style: "thin" },
  };

  bomSheet.getRow(10).getCell("A").alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  bomSheet.getRow(10).getCell("A").fill = {
    type: "pattern",
    pattern: "solid",
    bgColor: { argb: "000000" },
    fgColor: { argb: "FFE000" },
  };
  /////

  let filteredTree = filterProjectTree(
    ceilingTree,
    ceilingIds,
    map(ceilingLevels, "Name")
  );

  let { detailRows, levelRows, typeRows }: any = await generateSchedulesRows(
    filteredTree
  );

  ///
  bomSheet.mergeCells("B11:D11");
  bomSheet.getRow(11).getCell("B").value = "Ceiling Types";
  bomSheet.getRow(11).getCell("B").font = {
    name: "Calibri",
    size: 11,
    bold: true,
    color: { argb: "000000" },
  };
  bomSheet.getRow(11).getCell("B").border = {
    left: { style: "thin" },
    bottom: { style: "thin" },
  };
  bomSheet.getRow(11).getCell("B").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };

  bomSheet.getRow(11).getCell("E").value = "S (m²)";
  bomSheet.getRow(11).getCell("E").font = {
    name: "Calibri",
    size: 11,
    bold: true,
    color: { argb: "000000" },
  };
  bomSheet.getRow(11).getCell("E").border = {
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  bomSheet.getRow(11).getCell("E").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ////

  let startingRow = 12;

  forEach(typeRows, (tprow, key) => {
    bomSheet.mergeCells(`B${startingRow}:D${startingRow}`);
    bomSheet.getRow(startingRow).getCell("B").value = key;
    bomSheet.getRow(startingRow).getCell("B").font = {
      name: "Calibri",
      size: 11,
      bold: false,
      color: { argb: "000000" },
    };
    bomSheet.getRow(startingRow).getCell("B").border = {
      left: { style: "thin" },
      bottom: { style: "thin" },
    };
    bomSheet.getRow(startingRow).getCell("B").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };

    bomSheet.getRow(startingRow).getCell("E").value = Math.round(tprow * 100) / 100;
    bomSheet.getRow(startingRow).getCell("E").font = {
      name: "Calibri",
      size: 11,
      bold: false,
      color: { argb: "000000" },
    };
    bomSheet.getRow(startingRow).getCell("E").border = {
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    bomSheet.getRow(startingRow).getCell("E").alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    startingRow = startingRow + 1;
  });

  startingRow = startingRow + 1;

  // ceiling surfaces per floor / ceiling Id
  bomSheet.mergeCells(`A${startingRow}:H${startingRow}`);
  bomSheet.getRow(startingRow).getCell("A").value =
    "Ceiling surfaces per floor / Ceiling ID";
  bomSheet.getRow(startingRow).getCell("A").font = {
    name: "Calibri",
    size: 10,
    bold: true,
    color: { argb: "000000" },
  };
  bomSheet.getRow(startingRow).getCell("A").border = {
    top: { style: "thin" },
    bottom: { style: "thin" },
  };

  bomSheet.getRow(startingRow).getCell("A").alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  bomSheet.getRow(startingRow).getCell("A").fill = {
    type: "pattern",
    pattern: "solid",
    bgColor: { argb: "000000" },
    fgColor: { argb: "FFE000" },
  };
  /////

  startingRow = startingRow + 1;

  bomSheet.getRow(startingRow).getCell("A").value = "Level";
  bomSheet.getRow(startingRow).getCell("B").value = "Product Name";
  bomSheet.mergeCells(`C${startingRow}:D${startingRow}`);
  bomSheet.getRow(startingRow).getCell("C").value = "Ceiling Type";
  bomSheet.getRow(startingRow).getCell("E").value = "Surface (m²)";
  bomSheet.getRow(startingRow).getCell("F").value = "Ceiling ID";
  bomSheet.getRow(startingRow).getCell("G").value = "Perimeter (m)";
  bomSheet.getRow(startingRow).getCell("H").value = "O.d.s (mm)";

  bomSheet.getRow(startingRow).eachCell((cell) => {
    cell.font = {
      name: "Calibri",
      size: 11,
      bold: true,
      color: { argb: "000000" },
    };
    cell.border = {
      right: { style: "thin" },
      bottom: { style: "thin" },
    };
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
  });

  startingRow = startingRow + 1;

  forEach(levelRows, (level, levelName) => {
    forEach(level, (type, typeName) => {
      bomSheet.getRow(startingRow).getCell("A").value = levelName;
      bomSheet.getRow(startingRow).getCell("B").value = type.productName ? type.productName : " ";
      bomSheet.mergeCells(`C${startingRow}:D${startingRow}`);
      bomSheet.getRow(startingRow).getCell("C").value = typeName;
      bomSheet.getRow(startingRow).getCell("E").value =
        Math.round(type.surfaceArea * 100) / 100;
      bomSheet.getRow(startingRow).getCell("F").value = "";
      bomSheet.getRow(startingRow).getCell("G").value = "";
      bomSheet.getRow(startingRow).getCell("H").value = "";
      bomSheet.getRow(startingRow).eachCell((cell) => {
        cell.font = {
          name: "Calibri",
          size: 11,
          bold: false,
          color: { argb: "000000" },
        };
        cell.border = {
          right: { style: "thin" },
          bottom: { style: "thin" },
        };
        cell.fill = {
          type: "pattern",
          pattern:  [6,7,8].includes(parseInt(cell.col)) ? "lightUp" : "solid",
          bgColor: { argb: [6,7,8].includes(parseInt(cell.col)) ? "C0C0C0" : "000000" },
          fgColor: { argb: "D9D9D9" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
      startingRow = startingRow + 1;
    });

    const detRow = detailRows[levelName];

    forEach(detRow, (detCeilingType, key) => {
      forEach(detCeilingType, (det, key) => {
        bomSheet.getRow(startingRow).getCell("A").value = levelName;
        bomSheet.getRow(startingRow).getCell("B").value = det.productName ? det.productName : " ";
        bomSheet.mergeCells(`C${startingRow}:D${startingRow}`);
        bomSheet.getRow(startingRow).getCell("C").value = det.ceilingType;
        bomSheet.getRow(startingRow).getCell("E").value =
          Math.round(det.area * 100) / 100;
        bomSheet.getRow(startingRow).getCell("F").value = det.id;
        bomSheet.getRow(startingRow).getCell("G").value = det.perimeter;
        bomSheet.getRow(startingRow).getCell("H").value = det.ods * 1000;
        bomSheet.getRow(startingRow).eachCell((cell) => {
          cell.font = {
            name: "Calibri",
            size: 11,
            bold: false,
            color: { argb: "000000" },
          };
          cell.border = {
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
        startingRow = startingRow + 1;
      });
    });
  });

  bomSheet.getColumn("A").width = 20.82;
  bomSheet.getColumn("B").width = 43.91;
  bomSheet.getColumn("C").width = 25.82;
  bomSheet.getColumn("D").width = 14.36;
  bomSheet.getColumn("E").width = 7.55;
  bomSheet.getColumn("F").width = 8.82;
  bomSheet.getColumn("G").width = 9.36;
  bomSheet.getColumn("H").width = 5.64;

  let projectInfo = [
    {
      ref: "A1",
      merge: "A1:H1",
      name: "CLT4BIM",
      fontSize: 20,
      cellBorder: 0,
      alignment: {
        vertical: "bottom",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
  ];
  //   let columns = [
  //     { name: "Levels", filterButton: true },
  //     { name: "Ceiling types", filterButton: true },
  //     { name: "Thickness (mm)", filterButton: true },
  //     { name: "Wall Ids", filterButton: true },
  //     { name: "Surface (m²)", filterButton: true },
  //   ];

  //   addBillOfQuantityTable(
  //     bomSheet,
  //     "DetailedTable",
  //     "A1",
  //     "TableStyleMedium9",
  //     columns,
  //     detailRows
  //   );

  //   let formatedLevelRows = generatelLevelRows(levelRows);

  //   let columnsLevel = [
  //     { name: "Levels", filterButton: true },
  //     { name: "Ceiling types", filterButton: true },
  //     { name: "Surface (m²)", filterButton: true },
  //   ];

  //   addBillOfQuantityTable(
  //     bomSheet,
  //     "DetailedLevelTable",
  //     "I1",
  //     "TableStyleMedium9",
  //     columnsLevel,
  //     formatedLevelRows
  //   );

  //   let formatedTypesRows = Object.keys(typeRows).map((type: any) => [
  //     type,
  //     Math.round(typeRows[type] * 100) / 100,
  //   ]);

  //   let columnsType = [
  //     { name: "Ceiling types", filterButton: true },
  //     { name: "Surface (m²)", filterButton: true },
  //   ];

  //   addBillOfQuantityTable(
  //     bomSheet,
  //     "DetailedTypeTable",
  //     "M1",
  //     "TableStyleMedium9",
  //     columnsType,
  //     formatedTypesRows
  //   );
};

const buildProjectInfoSection = (overviewByRoomSheet: Excel.Worksheet) => {
  let projectInfo = [
    {
      ref: "A1",
      merge: "A1:H1",
      name: "CLT4BIM",
      fontSize: 20,
      cellBorder: 0,
      alignment: {
        vertical: "bottom",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
    {
      ref: "A2",
      merge: "A2:H2",
      name: "Qty take off",
      fontSize: 12,
      cellBorder: 0,
      alignment: {
        vertical: "top",
        horizontal: "center",
        wrapText: true,
      },
      fontColor: "707173",
    },
    {
      ref: "A3",
      name: "Project name",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A4",
      name: "Project code",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A5",
      name: "Project total area",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A6",
      name: "Region/country",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A7",
      name: "City",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "A8",
      name: "Date",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "B3",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B4",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B5",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B6",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B7",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "B8",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "C3",
      name: "Project handled by",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "C4",
      name: "ASM in charge",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "C5",
      name: "Ceiling Contractor",
      fontSize: 9,
      cellBorder: 4,
      alignment: {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
        indent: 1,
      },
    },
    {
      ref: "D3",
      merge: "D3:H3",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "D4",
      merge: "D4:H4",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
    {
      ref: "D5",
      merge: "D5:H5",
      name: "",
      fontSize: 9,
      cellBorder: 4,
    },
  ];

  setProjectInfo(overviewByRoomSheet, projectInfo);

  overviewByRoomSheet.getRow(1).height = 40;
  overviewByRoomSheet.getRow(2).height = 25;

  for (let index = 3; index <= 11; index++) {
    overviewByRoomSheet.getRow(index).height = 20;
  }
};
