import React, { useState } from "react";
import { InfoProjects } from "./InfoProjects";
import { InfoSupplimentary } from "./SupplyInfo/InfoSupplimentary";

export const AppBody = () => {
  const [route, setRoute] = useState<string>("PROJECT_INFO_FORM");

  switch (route) {
    case "PROJECT_INFO_FORM":
      return <InfoProjects setRoute={setRoute} route={route} />;
    case "SUPPLIMENTARY_FORM":
      return <InfoSupplimentary setRoute={setRoute} route={route} />;
    default:
      return null;
  }
};
