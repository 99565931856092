import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Checkbox,
  Message,
  Segment,
  Pagination,
  Icon,
  Sticky,
  List,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import productDisplayList from "./productDisplayList.json";
import { Routes } from "./root";
import PaginationComponent from "../../Common/Components/Pagination/pagination";
import { MyConfig } from "../../../../Helper";

interface Props {
  setRoute: any;
}

const BIMProductList = (props: Props) => {
  const onHandleProceedClick = () => {
    props.setRoute(Routes.PRODUCT_LIST);
  };
  const onHandleCancelClick = () => {
    props.setRoute(Routes.ROOT);
  };

  const [config, setConfig] = useState({REACT_APP_SERVERURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])

  return (
    <>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/ModelGenerator/plugin-logo.png"}
        name={"Object Generator"}
        subheader={"From Database / XLS"}
        config={config}
      />
      <Container
        style={{ marginTop: 20, height: "calc(100vh - 120px)", width: "95%" }}
      >
        <Segment className="fluid segment p-0">
          <Sticky>
            <Form style={{ marginBottom: "0" }}>
              <Form.Group widths="two">
                <Form.Input
                  type="text"
                  fluid
                  icon="search"
                  placeholder="Search..."
                  label="Keyword Search : "
                />

                <PaginationComponent pageObj={undefined}></PaginationComponent>
              </Form.Group>
            </Form>
          </Sticky>
          <Segment
            className="productCont"
            style={{ marginBottom: "2%", padding: "0" }}
          >
            <List
              divided
              verticalAlign="middle"
              style={{ height: "calc(100vh - 150px)", overflowY: "auto" }}
            >
              {productDisplayList.map((data: any) => (
                <List.Item key={data.productId} className="productBxList">
                  <List.Content>
                    <Checkbox
                      label={data.productName}
                      //onChange={(e) => this.onHandleProductSelect(data, e)}
                      checked={data.isSelected}
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Segment>
        <Segment
          className="form-btn-block"
          style={{ position: "sticky", bottom: "0px", padding: "0 0 10px" }}
        >
          <Message attached="bottom" error className="divHide">
            <Icon name="attention" />
            Select a product to proceed
          </Message>
          <Segment style={{ textAlign: "right", padding: "0" }}>
            <Button color="red" onClick={onHandleCancelClick}>
              Cancel
            </Button>
            <Button color="green" onClick={onHandleProceedClick}>
              Proceed
            </Button>
          </Segment>
        </Segment>
      </Container>
    </>
  );
};

export default BIMProductList;
