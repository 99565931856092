import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { SelectionStore } from "../../Selection/Reducers";
import { Routes } from "./root";
import TechnicalFileLanding from "./TechnicalFileLanding";
import CoverPage from "./CoverPage/CoverPage";
import TechnicalLanding from "./TechnicalLanding/TechnicalLanding";
import InfoProjects from "../../ProjectInfo/Components/InfoProjects";
import InfoSupplimentary from "../../ProjectInfo/Components/InfoSupplimentary";
import AddSystemType from "./Selections/AddSystemType";
import PreviewPage from "./CoverPage/PreviewPage";
import GroupSelector from "../../Selection/Components/GroupSelector";
import ProductsPage from "./ReferencesCCTP/ProductsPage";
import SelectionMapping from "../../Selection/Components/SelectionMapping";
import { DocSelection } from "./DocSelection";
import SelectionRoot from "../../Selection/Components/root";
import { SystemDetailsContainer } from "../../Selection/Components/SystemDetailsContainer";
import { SystemConfigurationContainer } from "../../Selection/Components/SystemConfigurationContainer";
import { initLanguage } from "../Actions";
import { Cctp } from "./Cctp";
import { setConfig } from "../../../CLT/Delete/Actions";
import { MyConfig } from "../../../../Helper";

import { ConfigurationContainers } from "../../../../Components/AdvanceSelector/systemConfiguration/ConfigurationContainers";
import { DetailsContainers } from "../../../../Components/AdvanceSelector/systemDetails/DetailsContainers";

const mapState = (state: SelectionStore, ownProps: any) => ({
  setRoute: ownProps.setRoute,
  routes: ownProps.route,
  config: state.config,
  selectionBuffer: state.selectionBuffer
});
const mapDispatch = {
  initLanguage,
  setConfig,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  route: string;
  setRoute: any;
  selectionPar: boolean;
  module: string;
  initLanguage: any;
  config: any;
  selectionBuffer: any;
  onError:any;
};

export class AppBody extends Component<Props> {
  public static defaultProps = {
    module: "Dossier Techniques",
  };

  componentDidMount = async () => {
    this.props.initLanguage();

    const cng = await MyConfig();
    this.props.setConfig(cng);
  };

  returnRedirect = () => {
    this.props.setRoute(Routes.PRODUCT_SELECTOR);
  };

  render() {
    const { route, setRoute, selectionPar, module } = this.props;
    switch (route) {
      case Routes.TECHNICAL_ROOT:
        return <TechnicalFileLanding routes={route} setRoute={setRoute} />;
      case Routes.TECHNICAL_LANDING:
        return (
          <TechnicalLanding
            routes={route}
            setRoute={setRoute}
            onError={this.props.onError}
          />
        );
      case Routes.PROJECT_INFO:
        return <InfoProjects routes={route} setRoute={setRoute} />;
      case Routes.PROJECT_INFO_SUPPLIMENTARY:
        return <InfoSupplimentary routes={route} setRoute={setRoute} />;
      case Routes.COVER_PAGE:
        return <CoverPage routes={route} setRoute={setRoute} onError={this.props.onError} />;
      case Routes.DOC_SELECTION_HOME:
        return <DocSelection setRoute={setRoute} />;
      case Routes.REFERENCE_CCTP:
        return <Cctp routes={route} setRoute={setRoute} />;
      case Routes.ADD_SYSTEM_TYPE:
        return <AddSystemType routes={route} setRoute={setRoute} />;
      case Routes.PREVIEW_PDF:
        return (
          <PreviewPage
            routes={route}
            setRoute={setRoute}
            config={this.props.config}
            onError={this.props.onError}
          />
        );
      case Routes.GROUP_SELECTION:
        return (
          <GroupSelector route={route} setRoute={setRoute} module={module} />
        );
      case Routes.MAPPING:
        return (
          <SelectionMapping route={route} setRoute={setRoute} module={module} />
        );
      case Routes.PRODUCT_SELECTOR:
        return <ProductsPage setRoute={setRoute} />;
      case Routes.SELECTION_HOME:
        //api.windowsHandler.resizeWindow(900, 507);
        return <SelectionRoot module="selection" />;
      case Routes.SYSTEM_DETAILS:
        return (
          <DetailsContainers
            setRoute={setRoute}
            returnRedirect={this.returnRedirect}
            detailsProp={this.props}
          />
        );
      case Routes.SYSTEM_CONFIGURATION:
        return (
          <ConfigurationContainers
            setRoute={setRoute}
            returnRedirect={this.returnRedirect}
            configProps={this.props}
          />
        );
      default:
        return null;
    }
  };
}

//export default connect(mapState)(AppBody);
export default connector(AppBody);

// interface Props {
//   route: string;
//   setRoute: any;
//   selectionPar: boolean;
//   module: string;
//   initLanguage: any;
//   setConfig: any;
//   config: any;
//   onError: any;
// }

// export const AppBody = ({
//   route,
//   setRoute,
//   onError,
// }: {
//   route: Routes;
//   setRoute: any;
//   onError: any;
// }) => {
//   const { config } = useSelector((state: SelectionStore) => state);
//   const dispatch = useDispatch();
//   const props = useSelector((state: SelectionStore) => state);

//   const module = "Dossier Techniques";

//   useEffect(() => {
//     dispatch(initLanguage());
//     fetchConfig();
//   }, []);

//   const fetchConfig = async () => {
//     const cng = await MyConfig();
//     dispatch(setConfig(cng));
//   };

//   switch (route) {
//     case Routes.TECHNICAL_ROOT:
//       return <TechnicalFileLanding routes={route} setRoute={setRoute} />;
//     case Routes.TECHNICAL_LANDING:
//       return (
//         <TechnicalLanding
//           routes={route}
//           setRoute={setRoute}
//           onError={onError}
//         />
//       );
//     case Routes.PROJECT_INFO:
//       return <InfoProjects routes={route} setRoute={setRoute} />;
//     case Routes.PROJECT_INFO_SUPPLIMENTARY:
//       return <InfoSupplimentary routes={route} setRoute={setRoute} />;
//     case Routes.COVER_PAGE:
//       return <CoverPage routes={route} setRoute={setRoute} onError={onError} />;
//     case Routes.DOC_SELECTION_HOME:
//       return <DocSelection setRoute={setRoute} />;
//     case Routes.REFERENCE_CCTP:
//       return <Cctp routes={route} setRoute={setRoute} />;
//     case Routes.ADD_SYSTEM_TYPE:
//       return <AddSystemType routes={route} setRoute={setRoute} />;
//     case Routes.PREVIEW_PDF:
//       return (
//         <PreviewPage
//           routes={route}
//           setRoute={setRoute}
//           config={config}
//           onError={onError}
//         />
//       );
//     case Routes.GROUP_SELECTION:
//       return (
//         <GroupSelector route={route} setRoute={setRoute} module={module} />
//       );
//     case Routes.MAPPING:
//       return (
//         <SelectionMapping route={route} setRoute={setRoute} module={module} />
//       );
//     case Routes.PRODUCT_SELECTOR:
//       return <ProductsPage setRoute={setRoute} />;
//     case Routes.SELECTION_HOME:
//       //api.windowsHandler.resizeWindow(900, 507);
//       return <SelectionRoot module="selection" />;
//     case Routes.SYSTEM_DETAILS:
//       return (
//         <DetailsContainers
//           setRoute={setRoute}
//           returnRedirect={setRoute(Routes.PRODUCT_SELECTOR)}
//           detailsProp={props}
//         />
//       );
//     case Routes.SYSTEM_CONFIGURATION:
//       return (
//         <ConfigurationContainers
//           setRoute={setRoute}
//           returnRedirect={setRoute(Routes.PRODUCT_SELECTOR)}
//           configProps={props}
//         />
//       );
//     default:
//       return null;
//   }
// };

// export default AppBody;