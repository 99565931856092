import axios from "axios";
import forEach from "lodash/forEach";
import flattenDeep from "lodash/flattenDeep";
import map from "lodash/map";
import { api } from "../../../../RevitJS/API";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";
import { bdd } from "../../../PAM/pam-import/helpers/interceptors";
import { MyConfig } from "../../../../Helper";
import { errorCodeKey } from "../../../../ErrorManagement/utils/errorCodeEnum";

const token = localStorage.getItem("token");

export async function bddPlaco(url: string, body: any, cng: any) {
  let myBdd = axios.create({
    baseURL: cng.REACT_APP_CALCULATORURL_PLACO,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });

  myBdd.interceptors.request.use(async (request) => {
    return await authFilter(request, cng);
  });

  myBdd.interceptors.response.use(
    // on success interceptor
    (res) => {
      return res;
    },
    // on error (4xx | 5xx ... statuses ) interceptor
    (res) => {
      if (res.response) {
        switch (res.response.status) {
          case 401:
          case 403:
            // if (res.response.data.error === "invalid_token") {
            if (
              cng.REACT_APP_AUTHENTICATIONURL +
              "" +
              cng.REACT_APP_LOGIN_REDIRECT
            ) {
              window.location.href =
                cng.REACT_APP_AUTHENTICATIONURL +
                "" +
                cng.REACT_APP_LOGIN_REDIRECT;
            }
            // }
            break;
          default:
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            break;
        }
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }

      return res;
    }
  );
  return await myBdd.post(url, body);
}

// Request interceptor for API calls
// bdd.interceptors.request.use(
//   async (config) => {
//     config.headers = {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     };
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// bdd.interceptors.request.use( async (request) => {
//   return await authFilter(request);
// });

// Response interceptor for API calls
// bdd.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error.config;
//     if (
//       (error.response.status === 401 || error.response.status === 500) &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       axios.defaults.headers.common[
//         "Authorization"
//       ] = `Bearer ${localStorage.getItem("token")}`;
//       return bdd(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

export const layout = async (wallTypesData: any, walls: any, config: any) => {
  return bddPlaco(
    "/",
    { wallMatching: wallTypesData, walls: walls },
    config
  ).catch((error) => {
    throw new Error(error);
  });
};

export const layoutGroup = async (
  wallTypesData: any,
  finalWalls: any,
  wallsDataByLevel: any,
  config: any
) => {
  try {
    let wallGroups: any = [];
    let url = "";
    let version = "";

    if (config.REACT_APP_CALCULATORURL_PLACO?.includes("?")) {
      url = (config.REACT_APP_CALCULATORURL_PLACO as string).split("?")[0];
      version =
        "?" + (config.REACT_APP_CALCULATORURL_PLACO as string).split("?")[1];
    } else {
      url = config.REACT_APP_CALCULATORURL_PLACO as string;
      version = "";
    }

    let deviceFingerprint = await api.license.GetMachineDetails();
    await axios
      .post(
        "",
        {
          wallMatching: wallTypesData,
          walls: finalWalls,
          allwalls: wallsDataByLevel,
        },
        {
          baseURL: url + "/group" + version,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "X-Fingerprint": deviceFingerprint.FingerPrint,
          },
        }
      )
      .then((response) => {
        wallGroups = response.data;
      })
      .catch((errors) => {
        return Promise.reject(errors);
      });
    return wallGroups;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const layoutInGroup = async (
  wallTypesData: any,
  finalWalls: any,
  groupWallIds: any,
  wallsDataByLevel: any,
  config: any
) => {
  try {
    let requestArray: any[] = [];
    let finalWallsArr: any[] = [];
    let deviceFingerprint = await api.license.GetMachineDetails();

    let responses: any[] = [];

    for (let wallIds of groupWallIds) {
      finalWallsArr = finalWalls.filter((a: any) => wallIds.includes(a.Id));

      await axios
        .post(
          "",
          {
            wallMatching: wallTypesData,
            walls: finalWallsArr,
            allwalls: wallsDataByLevel,
          },
          {
            baseURL: config.REACT_APP_CALCULATORURL_PLACO,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "X-Fingerprint": deviceFingerprint.FingerPrint,
            },
          }
        )
        .then((response) => {
          responses.push(response);
        })
        .catch(function (error: any) {
          console.log(error);
          // return Promise.reject(error);
          // throw error;
        });
    }
    return flattenDeep(map(responses, "data"));
  } catch (error: any) {
    console.log(error);
    // return Promise.reject(error);
  }
};

export const layoutGroup1 = async (
  wallTypesData: any,
  finalWalls: any,
  config: any
) => {
  let requestArray: any[] = [];
  forEach(finalWalls, (wallArray, index) => {
    requestArray.push(
      axios.post(
        "/",
        { wallMatching: wallTypesData, walls: wallArray },
        {
          baseURL: config.REACT_APP_CALCULATORURL_PLACO,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
    );
  });

  return axios
    .all(requestArray)
    .then(
      axios.spread((...responses) => {
        return flattenDeep(map(responses, "data"));
        // use/access the results
      })
    )
    .catch((errors) => {
      // react on errors.
    });
};

export const getPlacoBrand = (bdd: any) =>
  bdd("/objects/class/Brand/locale/en", { text: "PLACO" });

export const getPlacoProducts = (bdd: any, oid: any) => {
  return bdd("/objects/class/SGObject/locale/en", {
    dependencies: [
      {
        className: "Brand",
        oids: [oid],
      },
    ],
    types: ["Solution Product"],
    principal: true,
  });
};

export const setLiningAttributesAid = (layoutAttributesAid: any) => {
  return {
    type: "SET_LINING_WALLS_ATTRIBUTES_AID",
    layoutAttributesAid,
  };
};

export const handleSystemDetails = async (ids: any, config: any) => {
  let idsLength = ids.length;
  let idsStartLength = 0;
  let idsEndlength = 0;
  let partitionsDetails: any = [],
    attributes: any = [];
  do {
    idsEndlength = idsEndlength + 399;
    if (idsEndlength > idsLength) {
      idsEndlength = idsLength;
    }

    let sliceIds = ids.slice(idsStartLength, idsEndlength);
    let systemDetailsRequest = await getPlacoSystemsDetails(
      bdd,
      sliceIds,
      config
    );
    partitionsDetails = [
      ...partitionsDetails,
      ...systemDetailsRequest.data.objects,
    ];
    attributes = [
      ...attributes,
      ...systemDetailsRequest.data.context.attributeDependencies[0].attributes,
    ];
    idsStartLength = idsStartLength + 399;
  } while (idsEndlength < idsLength);

  return { partitionsDetails, attributes };
};

export const getPlacoSystemDetails = (bdd: any, systemOid: any, config: any) =>
  bdd("/objects/details/class/SGObject/locale/en", [systemOid], config);
export const getPlacoSystemsDetails = (bdd: any, systemOid: any, config: any) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid, config);

export const getPlacoSystemDetailsWithChildren = (
  systemOid: any,
  config: any
) =>
  bdd(
    "/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true",
    [systemOid],
    config
  );

export const getLayoutAttributesAid = (attributes: any) => {
  let plateWidth;
  if (
    attributes.find(
      (i: any) => i.technicalName === "GFR-Width of the UB unpacked (m)"
    )
  ) {
    plateWidth = attributes.find(
      (i: any) => i.technicalName === "GFR-Width of the UB unpacked (m)"
    ).aid;
  } else {
    if (attributes.find((i: any) => i.technicalName === "A-Solution Width")) {
      plateWidth = attributes.find(
        (i: any) => i.technicalName === "A-Solution Width"
      ).aid;
    }
  }
  let layoutProducts = [
    "GFR-Product 1",
    "GFR-Product 2",
    "GFR-Product 2",
    "GFR-Product 3",
    "GFR-Product 4",
    "GFR-Product 5",
    "GFR-Product 6",
    "GFR-Product 7",
    "GFR-Product 8",
    "GFR-Product 9",
    "GFR-Product 10",
    "GFR-Product 11",
    "GFR-Product 12",
    "GFR-Product 13",
    "GFR-Product 14",
    "GFR-Product 15",
  ];

  const layers = attributes.find(
    (i: any) => i.technicalName === "GFR-BIM layers"
  ).aid;

  const rails = attributes
    .filter((i: any) => layoutProducts.includes(i.technicalName))
    .map((attr: any) => attr.aid);

  const frames = attributes
    .filter((i: any) => layoutProducts.includes(i.technicalName))
    .map((attr: any) => attr.aid);

  const framesSpace = attributes.find(
    (i: any) => i.technicalName === "GFR-Distance between frames (in m)"
  ).aid;

  const doubleFraming = attributes.find(
    (i: any) => i.technicalName === "GFR-Profiles (1 or 2)"
  ).aid;

  const layerNumber = attributes.find(
    (i: any) => i.technicalName === "GFR-Layer number"
  ).aid;

  const layerContent = attributes.find(
    (i: any) => i.technicalName === "GFR-Layer content"
  ).aid;

  const layerName = attributes.find(
    (i: any) => i.technicalName === "GFR-Layer name"
  ).aid;

  const layerColor = attributes.find(
    (i: any) => i.technicalName === "GFR-Layer color"
  ).aid;

  const layerThickness = attributes.find(
    (i: any) => i.technicalName === "GFR-Layer thickness mm"
  ).aid;

  const systemName = attributes.find(
    (i: any) => i.technicalName === "A-Solution product name"
  ).aid;

  const plateHeight = attributes.find(
    (i: any) => i.technicalName === "GFR-Height limit in m"
  ).aid;

  const plateLength = attributes.find(
    (i: any) => i.technicalName === "A-Solution Length"
  ).aid;

  const frameWidth = attributes.find(
    (i: any) => i.technicalName === "GFR-Thickness / UB unpacked height (m)"
  ).aid;

  return {
    layers: layers,
    rails: rails,
    frames: frames,
    framesSpace: framesSpace,
    doubleFraming: doubleFraming,
    layerNumber: layerNumber,
    layerContent: layerContent,
    layerName: layerName,
    layerColor: layerColor,
    layerThickness: layerThickness,
    plateWidth,
    systemName: systemName,
    plateHeight: plateHeight,
    plateLength: plateLength,
    frameWidth: frameWidth,
  };
};

export const getLiningWallsAttributesAid = (attributes: any) => {
  return {
    layers: attributes.find((i: any) => i.technicalName === "GFR-BIM layers")
      .aid,
    rails: attributes.find((i: any) => i.technicalName === "GFR-Product 3").aid,
    frames: attributes.find((i: any) => i.technicalName === "GFR-Product 2")
      .aid,
    framesSpace: attributes.find(
      (i: any) => i.technicalName === "GFR-Distance between frames (in m)"
    ).aid,
    doubleFraming: attributes.find(
      (i: any) => i.technicalName === "GFR-Profiles (1 or 2)"
    ).aid,
    layerNumber: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer number"
    ).aid,
    layerContent: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer content"
    ).aid,
    layerName: attributes.find((i: any) => i.technicalName === "GFR-Layer name")
      .aid,
    layerThickness: attributes.find(
      (i: any) => i.technicalName === "GFR-Layer thickness mm"
    ).aid,
    plateWidth: attributes.find(
      (i: any) => i.technicalName === "A-Solution Width"
    ).aid,
    systemName: attributes.find(
      (i: any) => i.technicalName === "A-Solution product name"
    ).aid,
    technicalName: attributes.find(
      (i: any) => i.technicalName === "GFR-Profiles (1 or 2)"
    ).aid,
  };
};

export const setFiltersAid = (filters: any, attributes: any) => {
  return filters.map((filter: any) => {
    return {
      ...filter,
      aid: attributes.find(
        (att: any) => filter.pimAttribute === att.technicalName
      ).aid,
    };
  });
};

export const addFilterFields = (systemsDetails: any, filters: any) => {
  return systemsDetails.map((sys: any) => {
    filters.map((filter: any) => {
      let attr = sys.attributes.find((attr: any) => attr.aid === filter.aid);
      if (attr) {
        let attrValue;
        if (filter.type === "Numeric") attrValue = attr.values[0].numericValue;
        else {
          attrValue = attr.values[0].value;
        }
        return (sys[filter.name] = attrValue);
      } else {
        return (sys[filter.name] = undefined);
      }
    });
    return sys;
  });
};

export const addLayoutPossibleField = (
  systemDetails: any,
  system: any,
  attributes: any
) => {
  const layoutPossibleAid = attributes.find(
    (i: any) => i.technicalName === "GFR-Layout possible"
  ).aid;
  const layoutPossibleName = "layoutPossible";

  return systemDetails.map((sys: any) => {
    let attr = sys.attributes.find(
      (attr: any) => attr.aid === layoutPossibleAid
    );
    let attrValue = attr.values[0].value === "True" ? true : false;
    // if (attr) {
    //   attrValue = attr.values[0].value.toLowerCase() === "true";
    // }
    sys[layoutPossibleName] = attrValue;
    system.find((ele: any) => ele.oid === sys.oid)[layoutPossibleName] =
      attrValue;
    return sys;
  });
};

export const setFiltersOptions = (filters: any, systemsDetails: any) => {
  filters = [...filters];
  systemsDetails.forEach((system: any) => {
    filters.forEach((filter: any) => {
      filter.options = filter.options || [];
      let attrValue = system[filter.name];
      if (attrValue !== undefined) {
        if (!filter.options.includes(attrValue)) filter.options.push(attrValue);
      } else {
      }
    });
  });
  return filters.map((filter: any) => {
    filter.options.sort((a: any, b: any) => {
      if (filter.pimAttribute === "GFR-Fire protection") {
        return parseFloat(a.slice(2)) - parseFloat(b.slice(2));
      } else {
        return a - b;
      }
    });
    return filter;
  });
};

export const getPlacoSystemsDetailsAndRelation = (
  systemOid: any,
  config: any
) => {
  return bdd(
    "/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true",
    [systemOid],
    config
  );
};

export const getAnySystemDetails = (systemOid: any, config: any) =>
  bdd("/objects/details/class/SGObject/locale/en", systemOid, config);

export const getAnySystemDetailsWithChildren = (systemOid: any, config: any) =>
  bdd(
    "/objects/details/class/SGObject/locale/en?includeRelatedObjects=true&includeChildren=true",
    [systemOid],
    config
  );
