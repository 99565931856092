import { filter, map, flattenDeep, startCase, lowerCase } from "lodash";
import { api } from "../../../../RevitJS/API";

import { QuantiType, ElementType } from "../Util/type";

export const getProcessedElements = async (
  selections: any,
  quantiType: QuantiType,
  projectId: string,
  element: ElementType
) => {
  try {
    let filterCriteria: any = [
      {
        Param: { Name: "Processed", Type: "Boolean", Value: true },
        Rule: "Equals",
      },
    ];

    let mapZoneElement: any = null;
    if (quantiType === QuantiType.ZONE) {
      const zonedSelections = filter(selections, (sel: any) => {
        return sel.Zone && sel.RevitView === projectId;
      });

      if (!zonedSelections) {
        throw "No zone selection exist";
      }

      mapZoneElement = map(zonedSelections, (zs) => {
        return {
          zone: zs.Name,
          walls: flattenDeep(
            map(zs.SelectionByType.wall.Rows, "RevitSelection.Ids")
          ),
          ceilings: flattenDeep(
            map(zs.SelectionByType.ceiling.Rows, "RevitSelection.Ids")
          ),
        };
      });

      const wallIds = flattenDeep(
        map(mapZoneElement, element.toLowerCase() + `s`)
      );

      filterCriteria = filterCriteria.concat([
        {
          Param: { Name: "Element", Type: "Id", Value: wallIds },
          Rule: "Includes",
        },
      ]);
    }

    const processedElementIds: any[] = await api.queries.filterElements(
      startCase(lowerCase(element)),
      filterCriteria,
      null
    );

    return { processedElementIds, mapZoneElement };
  } catch (error) {
    throw error;
  }
};
