import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";

interface Props {}

export enum Routes {
  ROOT = "Nomenclature",
  MAPPING = "Mapping",
  GROUP_SELECTION = "GroupSelection",
  PRODUCT_SELECTOR = "ProductSelector",
}

interface State {
  route: Routes;
}

export class NomenclatureRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
  };

  public store: Store;

  constructor(props: Props) {
    super(props);
    this.store = createStore(reducers, applyMiddleware(thunk));
  }

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = () => {
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", `${urlParams.get("token")}`);
    }
    if (urlParams.get("language")) {
      localStorage.setItem("savedLanguage", `${urlParams.get("language")}`);
    }
  };

  render = () => {
    return (
      <Provider store={this.store}>
        <AppBody route={this.state.route} setRoute={this.setRoute} />
      </Provider>
    );
  };
}
