import { ProductDetailData } from "../../../../RevitJS/Types/BddTypes";
import { initProductSelectorAction, resetFiltersAction, applyFiltersAction, searchAction, scrolledBottomAction } from "../Actions/types";



type displayedElementsAction = initProductSelectorAction | resetFiltersAction | applyFiltersAction | searchAction | scrolledBottomAction;


export const displayedElements = (displayedElements: ProductDetailData[] = [], action: displayedElementsAction) =>{
    switch(action.type){
        case "INIT_SELECTOR":
            return action.displayedElements;
        case "APPLY_FILTERS":
            return action.displayedElements;
        case "RESET_FILTERS":
            return action.displayedElements;
        case "SEARCH":
            return action.displayedElements;
        case "SCROLLED_BOTTOM":
            return action.displayedElements;
        default:
            return displayedElements
    }
}