import React from 'react';
import { Menu, Checkbox } from 'semantic-ui-react';

export class LevelItem extends React.Component {
   
    render() {
        return (
            <Menu.Item name='inbox'>
                <Checkbox onChange={(e, data) => {this.props.selectLevel(data.label.children)}}  checked={this.props.checked} style={{ maxWidth: "80%" }} label={{ children: `${this.props.name}` }} />
            </Menu.Item>
        )
    }
}