/**
 * React and Redux compliment each other well. 
 * React Components subscribe to Redux store due to which the child components 
 * re renders when the state (Redux state) is changed every time. 
 * So any component which is subscribed to Redux re renders when there 
 * is a state change (unless and until we mention not to do so). 
 * This is a prime reason for which there is a need for optimizing 
 * React Redux applications in order to avoid unnecessary re renders. 
 * Reselect is a library which can be effectively used in a React Redux 
 * application for the same.
 * 
 * for more information : 
 * https://www.npmjs.com/package/reselect
 * https://codebrahma.com/reselect-tutorial-optimizing-react-redux-application-development-with-reselect/
 */

import { createSelector } from "reselect";
import { SelectionStore } from "../Reducers";
import {
  GenericMappingRow,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { ProductDetailData } from "../Types";

const getElementParameter = (state: SelectionStore) => state.elementParameter;

const getSelectionListByType = (state: SelectionStore) =>
  state.bufferSelection?.SelectionByType;

const getSelectorType = (state: SelectionStore, props: string) =>
  props ? props : state.selectorType;

const getPlacoData = (state: SelectionStore) => state.placoData;

export const getWallPlacoSystems = (state: SelectionStore) =>
  [
    ...state.placoData.partitionWalls,
    ...state.placoData.liningWalls,
    ...state.placoData.gainesTech,
  ] as ProductDetailData[];

export const getCeilingPlacoSystems = (state: SelectionStore) =>
  state.placoData.ceilings as ProductDetailData[];

export const getOthersPlacoSystems = (state: SelectionStore) =>
  state.placoData.protectionFeu
    .concat(state.placoData.conduitGaines)
    .concat(state.placoData.gainesTech) as ProductDetailData[];

export const makeGetRelatedPlacoSolutions = () => {
  return createSelector(
    [
      getSelectorType,
      getWallPlacoSystems,
      getCeilingPlacoSystems,
      getOthersPlacoSystems,
    ],
    (selectorType, cloisons, ceilings, others) => {
      switch (selectorType) {
        case "wall":
          return cloisons;
        case "ceiling":
          return ceilings;
        case "others":
          return others;
        default:
          return [];
      }
    }
  );
};

export const makeGetDropdownPlacoSolutions = () => {
  return createSelector(
    [
      getSelectorType,
      getWallPlacoSystems,
      getCeilingPlacoSystems,
      getOthersPlacoSystems,
    ],
    (selectorType, cloisons, ceilings, others) => {
      switch (selectorType) {
        case "wall":
          return cloisons.map((sys: ProductDetailData) => {
            let productName: any = sys?.attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;
            return { label: productName, value: sys.oid };
          });
        case "ceiling":
          return ceilings.map((sys: ProductDetailData) => {
            let productName: any = sys?.attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;
            return { label: productName, value: sys.oid };
          });
        case "others":
          return others.map((sys: ProductDetailData) => {
            let productName: any = sys?.attributes?.find(a => a.technicalName === "A-Solution product name")?.values[0]?.value;
            return { label: productName, value: sys.oid };
          });
        default:
          return [];
      }
    }
  );
};

export const makeGetMappingList = () => {
  return createSelector(
    [getSelectorType, getSelectionListByType],
    (selectorType, selectionListByType) => {
      if (selectionListByType) {
        switch (selectorType) {
          case "wall":
            return selectionListByType.wall.Rows;
          case "ceiling":
            return selectionListByType.ceiling.Rows;
          case "others":
            return selectionListByType.others?.Rows;
          default:
            return [];
        }
      }

      return [];
    }
  );
};
