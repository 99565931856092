import React, { Component } from "react";
import { connect, Provider } from "react-redux";
import { createStore, Store, applyMiddleware } from "redux";
import { reducers } from "../Reducers";
import thunk from "redux-thunk";
import AppBody from "./AppBody";
import { composeWithDevTools } from "redux-devtools-extension";
import { bimStorage, storageKey } from "../../../../BIMStore";
import updateSelectionIfWallDeleted from "../../CommonModules/Helpers/selectionUpdateHelper";
import { MyConfig } from "../../../../Helper";
import { setConfig } from "../../../CLT/Delete/Actions";
import {
  InjectedCounterProps,
  withErrorSubscription,
} from "../../../../ErrorManagement/components/ErrorBoundry";

type Props = InjectedCounterProps & {
  module: string;
  selectionPar: boolean;
  setConfig: any;
};

/**
 * Remeber to wirte rootes value in CAPITAL Letters
 */
export enum Routes {
  ROOT = "SELECTION_HOME",
  MAPPING = "MAPPING",
  GROUP_SELECTION = "GROUP_SELECTION",
  PRODUCT_SELECTOR = "PRODUCT_SELECTOR",
  SUPPRESSION = "SUPPRESSION",
  TECHNICAL_LANDING = "TECHNICAL_LANDING",
  METRES = "METRESROOT",
  METRES_LANDING = "METRESLANDING",
  BACK_FROM_SELECTION = "BACK_FROM_SELECTION",
  REFERENCE_CCTP = "REFERENCECCTPLANDING",
  SYSTEM_DETAILS = "SYSTEM_DETAILS",
  SYSTEM_DETAILS_MAPPING = "SYSTEM_DETAILS_MAPPING",
  SYSTEM_CONFIGURATION = "SYSTEM_CONFIGURATION",
}

interface State {
  route: Routes;
  isSelectionUpdating: Boolean;
}

export const dbStoreNameSelection = "Selection";

class SelectionRoot extends Component<Props, State> {
  state = {
    route: Routes.ROOT,
    isSelectionUpdating: true,
  };

  public store: Store;

  public static defaultProps = {
    selectionPar: false,
  };

  constructor(props: Props) {
    super(props);
    this.store = createStore(
      reducers,
      composeWithDevTools(applyMiddleware(thunk))
    );
  }

  componentWillUnmount = () => {};

  setRoute = (route: Routes) => {
    this.setState({
      route,
    });
  };

  componentDidMount = async () => {
    // api.windowsHandler.showDevTools();
    const cng = await MyConfig();
    this.props.setConfig(cng);

    const checkIfUpdated = await updateSelectionIfWallDeleted(cng);

    if (checkIfUpdated) {
      this.setState({
        isSelectionUpdating: false,
      });
    }

    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString.split("?")[1]);
    if (urlParams.get("token")) {
      localStorage.setItem("token", urlParams.get("token")!);
    }
    if (urlParams.get("language")) {
      bimStorage.setItem(storageKey.LANGUAGE, urlParams.get("language")!);
    }
  };

  render = () => {
    if (this.state.isSelectionUpdating) {
      return <div></div>;
    }
    return (
      <Provider store={this.store}>
        <AppBody
          route={this.state.route}
          setRoute={this.setRoute}
          {...this.props}
        />
      </Provider>
    );
  };
}

const mapDispatchToProps = {
  setConfig,
};

export default withErrorSubscription(
  connect(null, mapDispatchToProps)(SelectionRoot)
);
