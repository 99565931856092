import React, { useEffect, useState } from "react";
import { TopHeader, SubHeader } from "../../Components/Headers";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { MyConfig } from "../../../../Helper";
import { api } from "../../../../RevitJS/API";
import { groupBy, concat } from "lodash";
import { clearMetresSelection } from "../Actions";
import { scheduleHandler } from "../Export";
import _, { ceil, round, sortBy, find, map, forEach, filter } from "lodash";
import {
  Container,
  Grid,
  Icon,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { bimStorage } from "../../../../BIMStore";
import { SelectionTable } from "../../Calepinage/Components/Table/Selection";
import { wait } from "../../../../RevitJS/Helpers";
import { Routes } from "./root";

interface Props {
  route: string;
  setRoute: any;
}

const MetSelection = (props: Props) => {
  const [erasing, setErasing] = useState<boolean>(false);
  const [selectionList, setSelectionList] = useState<any>(null);
  const [config, setConfig]: any = useState("");
  const { register, handleSubmit, formState } = useForm();
  const [activeLoader, setActiveLoader] = useState(false);

  const listModules = async () => {
    const modules = await bimStorage.listSelection();
    if (modules) {
      setSelectionList(modules);
    }
  };

  const onSubmit = async (formData: any) => {
    setActiveLoader(true);
    const selectionIds = parseInt(formData.selections);
    let selectionData: any = await new Promise((resolve, reject) =>
      window.indec.getSelection(selectionIds, resolve, reject)
    )
      .then((x: any) => {
        return x;
      })
      .catch((ex: any) => {
        console.error(ex);
        return [];
      });
    // await bimStorage.getSelection(selectionIds);
    let finalArray: any = [];
    finalArray.push({
      Zone: false,
      Name: selectionData.Name,
      height: 0,
      level: selectionData.Floors.map((item: any) => {
        return item.Name;
      }),
      walls: selectionData.SelectionDetails.map((ele: any) => {
        return {
          type: ele.ElementName,
          ids: ele.Ids,
          Solution: ele.Solution.Name,
        };
      }),
    });

    await scheduleHandler(
      finalArray,
      clearMetresSelection,
      () => {
        setErasing(false);
      },
      config
    );
    setActiveLoader(false);
  };

  useEffect(() => {
    api.windowsHandler.resizeWindow(900, 607);
    async function getConfig() {
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
    listModules();
  }, []);

  if (!selectionList) {
    return (
      <Dimmer inverted active>
        <Loader />
      </Dimmer>
    );
  }

  if (erasing) {
    return (
      <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Loading..."}></Loader>
      </Dimmer>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      <Dimmer active={activeLoader} style={{ height: "calc(100vh - 30px)" }}>
        <Loader content={"Schedules pending..."}></Loader>
      </Dimmer>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/plugin_new_logo/BeneluxIcon.png"}
        name={"Schedules"}
      />
      <>
        <SubHeader heading={"Saved selections list"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Segment>
            {selectionList.length === 0 ? (
              <div>No saved selection</div>
            ) : (
              <SelectionTable
                data={selectionList}
                register={register}
                selectionId={1}
              />
            )}
          </Segment>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              padding: 5,
              cursor: "pointer",
              minWidth: "0px",
              left: "10px",
              zIndex: "2",
            }}
            onClick={() => {
              props.setRoute("SELECTION_HOME");
            }}
          >
            <Icon
              name="edit"
              color="blue"
              size="big"
              style={{ fontSize: "1.5em" }}
            />
            <span
              style={{ color: "rgb(14,110,184)", textTransform: "capitalize" }}
            >
              create / modify selection
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "30px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <span>
              <Button
                color="orange"
                onClick={() => {
                  props.setRoute(Routes.ROOT);
                }}
                content={"Back"}
              ></Button>
            </span>
            <span>
              <Button
                color="blue"
                content={"Validate"}
                type={"submit"}
              ></Button>
            </span>
          </div>
        </form>
      </>
    </div>
  );
};

export default MetSelection;
