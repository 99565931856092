import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Segment, Grid } from "semantic-ui-react";
import "../Resources/Technical.css";
import ButtonPack from "./ButtonPack";
import "../Resources/FormPanel.css";
import { FormPanels } from "./FormPanels";
import { api } from "./../../../../RevitJS/API/index";
import { Routes } from "./root";
import { bimStorage, storageKey } from "../../../../BIMStore";
import { getCurrentDate } from "../../DocumentTechniques/Helpers";
import { setDossierData } from "../../DocumentTechniques/Actions";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";

export function InfoForm(props: any) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    shouldFocusError: true,
    criteriaMode: "firstError",
    defaultValues: {
      information_enterprise_name: "",
      information_enterprise_logo: "",
      information_enterprise_address: "",
      information_enterprise_postal: "",
      information_enterprise_villa: "",
      information_enterprise_pays: "France",
      information_enterprise_telephone: "",
      information_enterprise_email: "",
      information_chantier_add_date: "",
      information_chantier_address: "",
      information_chantier_pays: "France",
      information_chantier_postal: "",
      information_chantier_reference: "",
      information_chantier_referencecctp: "",
      information_chantier_villa: "",
    },
  });
  useEffect(() => {
    const documentName = props.projectData.ProjectId;
    if (documentName !== "") {
      bimStorage.getItem(storageKey.PROJECT_INFO).then((data: any) => {
        const modelInfo = data?.PROJECT_INFO_FORM?.modelInfo.find((e: any) => {
          return e.model_name === documentName;
        });
        reset({
          information_enterprise_name:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_name,
          information_enterprise_logo:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_logo,
          information_enterprise_address:
            data?.PROJECT_INFO_FORM?.companyInfo
              ?.information_enterprise_address,
          information_enterprise_postal:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_postal,
          information_enterprise_villa:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_villa,
          information_enterprise_pays:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_pays,
          information_enterprise_telephone:
            data?.PROJECT_INFO_FORM?.companyInfo
              ?.information_enterprise_telephone,
          information_enterprise_email:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_email,
          information_chantier_reference:
            modelInfo?.information_chantier_reference,
          information_chantier_address: modelInfo?.information_chantier_address,
          information_chantier_postal: modelInfo?.information_chantier_postal,
          information_chantier_villa: modelInfo?.information_chantier_villa,
          information_chantier_pays: modelInfo?.information_chantier_pays,
          information_chantier_referencecctp:
            modelInfo?.information_chantier_referencecctp,
          information_chantier_add_date:
            modelInfo?.information_chantier_add_date,
        });
      });
    } else {
      bimStorage.getItem(storageKey.PROJECT_INFO).then((data: any) => {
        reset({
          information_enterprise_name:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_name,
          information_enterprise_logo:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_logo,
          information_enterprise_address:
            data?.PROJECT_INFO_FORM?.companyInfo
              ?.information_enterprise_address,
          information_enterprise_postal:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_postal,
          information_enterprise_villa:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_villa,
          information_enterprise_pays:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_pays,
          information_enterprise_telephone:
            data?.PROJECT_INFO_FORM?.companyInfo
              ?.information_enterprise_telephone,
          information_enterprise_email:
            data?.PROJECT_INFO_FORM?.companyInfo?.information_enterprise_email,
          information_chantier_reference: "",
          information_chantier_address: "",
          information_chantier_postal: "",
          information_chantier_villa: "",
          information_chantier_pays: "",
          information_chantier_referencecctp: "",
          information_chantier_add_date: "",
        });
      });
    }
  }, [props, reset]);
  const onSubmitPrev = async (data: any) => {
    let details = props.dossierData;
    var projectInfo: any = {};
    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );
    var preFillInfo = tempProjectInfo === null ? {} : tempProjectInfo;
    let preFillInfoClone = JSON.parse(JSON.stringify(preFillInfo));
    const documentName = props.projectData.ProjectId;
    let companyInfo = {};
    let modelInfoObj = { model_name: documentName };
    for (const [key, value] of Object.entries(data)) {
      if (key.indexOf("enterprise") > -1)
        companyInfo = { ...companyInfo, [key]: value };
      if (key.indexOf("chantier") > -1)
        modelInfoObj = { ...modelInfoObj, [key]: value };
    }
    if (
      preFillInfo &&
      preFillInfo[props.routes] &&
      preFillInfo[props.routes].modelInfo
    ) {
      let index = 0;
      preFillInfo[props.routes].modelInfo?.forEach((model: any) => {
        if (model.model_name === documentName)
          preFillInfo[props.routes].modelInfo?.splice(index, 1);
        index++;
      });
      preFillInfo[props.routes].modelInfo?.push(modelInfoObj);
      if (!Array.isArray(preFillInfo.SUPPLIMENTARY_FORM)) {
        preFillInfo.SUPPLIMENTARY_FORM = {
          model_name: documentName,
          ...preFillInfo.SUPPLIMENTARY_FORM,
        };
        preFillInfo.SUPPLIMENTARY_FORM = [preFillInfo.SUPPLIMENTARY_FORM];
      }
      projectInfo = {
        PROJECT_INFO_FORM: {
          companyInfo: companyInfo,
          modelInfo: preFillInfo[props.routes].modelInfo,
        },
        SUPPLIMENTARY_FORM: preFillInfo.SUPPLIMENTARY_FORM,
      };
    } else {
      projectInfo = {
        PROJECT_INFO_FORM: {
          companyInfo: companyInfo,
          modelInfo: [modelInfoObj],
        },
        SUPPLIMENTARY_FORM: [],
      };
    }
    if (
      JSON.stringify(projectInfo.PROJECT_INFO_FORM) !==
      JSON.stringify(preFillInfoClone.PROJECT_INFO_FORM)
    ) {
      details.documentDate = getCurrentDate();
      props.setDossierData(details);
    }
    localStorage.removeItem("isModification");
    await bimStorage.setItem(
      storageKey.PROJECT_INFO,
      JSON.stringify(projectInfo)
    );
    props.changeDateHandler();
    if (props.prevPage === Routes.TECHNICAL_LANDING)
      props.setRoute(Routes.TECHNICAL_LANDING);
    else api.windowsHandler.closeWindow();
    //window.location.href = props.custom.SystemTablePath;
  };

  const onSubmitNext = async (data: any) => {
    var projectInfo = {};
    const documentName = props.projectData.ProjectId;
    let companyInfo = {};
    let modelInfoObj = { model_name: documentName };
    for (const [key, value] of Object.entries(data)) {
      if (key.indexOf("enterprise") > -1)
        companyInfo = { ...companyInfo, [key]: value };
      if (key.indexOf("chantier") > -1)
        modelInfoObj = { ...modelInfoObj, [key]: value };
    }
    let tempProjectInfo: any = await bimStorage.getItem(
      storageKey.PROJECT_INFO
    );
    let preFillInfo = tempProjectInfo === null ? {} : tempProjectInfo;
    if (
      preFillInfo[props.routes] &&
      preFillInfo[props.routes].modelInfo.length > 0
    ) {
      let index = 0;
      preFillInfo[props.routes].modelInfo?.forEach((model: any) => {
        if (model.model_name === documentName)
          preFillInfo[props.routes].modelInfo?.splice(index, 1);
        index++;
      });
      preFillInfo[props.routes].modelInfo?.push(modelInfoObj);
      if (!Array.isArray(preFillInfo.SUPPLIMENTARY_FORM)) {
        preFillInfo.SUPPLIMENTARY_FORM = {
          model_name: documentName,
          ...preFillInfo.SUPPLIMENTARY_FORM,
        };
        preFillInfo.SUPPLIMENTARY_FORM = [preFillInfo.SUPPLIMENTARY_FORM];
      }
      projectInfo = {
        PROJECT_INFO_FORM: {
          companyInfo: companyInfo,
          modelInfo: preFillInfo[props.routes].modelInfo,
        },
        SUPPLIMENTARY_FORM: preFillInfo.SUPPLIMENTARY_FORM,
      };
    } else {
      projectInfo = {
        PROJECT_INFO_FORM: {
          companyInfo: companyInfo,
          modelInfo: [modelInfoObj],
        },
        SUPPLIMENTARY_FORM: [],
      };
    }
    await bimStorage.setItem(
      storageKey.PROJECT_INFO,
      JSON.stringify(projectInfo)
    );
    props.changeDateHandler();
    props.setRoute(Routes.SUPPLIMENTARY);
    //window.location.href = props.custom.SystemTablePath;
  };

  //  const consoleData = async (newData:any) => {
  //   let tempProjectInfo: any = await bimStorage.getItem(storageKey.PROJECT_INFO);
  //   let preFillInfo = tempProjectInfo === null ? {} : tempProjectInfo;
  //   let companyInfo = preFillInfo[props.routes].companyInfo;
  //   }

  return (
    <div>
      <Form
        onSubmit={handleSubmit(onSubmitPrev)}
        style={{ marginTop: "1em" }}
        onChange={(event: any) => {
          if (event) localStorage.setItem("isModification", "true");
        }}
      >
        <Segment className="formContainer">
          <Grid>
            {/* <Grid.Row columns={props.data.length}> */}
            <Grid.Row
              columns={props.data.length > 2 ? props.data.length : 2}
              centered
              style={{ paddingLeft: 0 }}
            >
              <FormPanels
                nextPageHandler={handleSubmit(onSubmitNext)}
                {...props}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid.Row>
          </Grid>
        </Segment>
        {Object.keys(errors).length > 0 ? (
          <p
            style={{
              color: "red",
              margin: "5px 0px",
              textAlign: "center",
            }}
          >
            {props.wording.infoformerror[props.language]}
          </p>
        ) : (
          ""
        )}
        <ButtonPack
          setRoute={props.setRoute}
          routes={props.routes}
          nexPagehandler={handleSubmit(onSubmitNext)}
          confirm={props.wording.confirm[props.language]}
          cancel={props.wording.cancel[props.language]}
          prevPage={props.prevPage}
          // console={consoleData}
        />
      </Form>
    </div>
  );
}

const mapDispatch = {
  setDossierData,
};
export function mapStateToProps(state: SelectionStore) {
  return { dossierData: state.moduleData.dossierData, config: state.config };
}
export default connect(mapStateToProps, mapDispatch)(InfoForm);
