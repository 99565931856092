import React, { Component, useEffect, useState } from "react";
import { api } from "../../../../RevitJS/API";
import {
  Segment,
  Table,
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Header,
  Form,
  Radio,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../../../Components/Store/FunctionalityHeader";
import { Routes } from "./root";
import { DrawStore } from "../Reducers";
import { connect, useDispatch } from "react-redux";
import { MappingRowEcophon } from "../../../../RevitJS/Types/StoreTypes";
import {
  drawLayout,
  toggleAll,
  albaSystemsList,
  startLayout,
  endLayout,
  endProgress,
} from "../Actions";
import { PIMLayoutAttributesAid } from "../../../../RevitJS/Types/BddTypes";
import axios from "axios";
import {
  PLTResult,
  LayoutState,
  RevitMappedWall,
} from "../../../../Layout/types";
import MappingRowEcophonComponent from "./MappingRowEcophon";
import AlertDialog from "./AlertDialog";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  filter,
  find,
  flattenDeep,
  includes,
  intersection,
  map,
  remove,
  uniq,
} from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { authFilter } from "../../../../API/Interceptors/authentication.interceptor";

export const rigipsSystemDispatcher = () => {};
export const layout = (localhost: any, ceilingsData: any, wallsData: any) =>
  localhost.post("", { ceilingsData, wallsData });

interface Props {
  layoutAttributesAid: PIMLayoutAttributesAid | null;
  language: string;
  Icon: string;
  mappingRows: MappingRowEcophon[];
  layoutState: LayoutState;
  layoutProgress: number;
  setRoute: any;
  drawLayout: any;
  toggleAll: Function;
  selectedEcophonSystem: string | null;
  selectedRange: string | null;
  selectedConfiguration: string | null;
  selectedAngle: any | 0;
  selectedHeigtUnderCeiling: any | 2300;
  selectedMyHanger: any | "";
  configurations: {
    [key: string]: {
      id: string;
      config: string;
      parentRange: string;
    };
  };
  products: { [key: string]: { id: string } };
  config: any;
}

interface State {
  toogler: boolean;
  finalCeilings: any[];
}

export const SolutionMapping: React.FC<Props & WithTranslation> = ({
  layoutAttributesAid,
  language,
  Icon,
  mappingRows,
  layoutState,
  layoutProgress,
  setRoute,
  drawLayout,
  toggleAll,
  selectedEcophonSystem,
  selectedRange,
  selectedConfiguration,
  selectedAngle,
  selectedHeigtUnderCeiling,
  selectedMyHanger,
  configurations,
  products,
  config,
  t,
}) => {
  const [toogler, setToogler] = useState(true);
  const [open, setOpen] = useState(false);
  const [needUserInput, setNeedUserInput] = useState(false);
  const [userInput, setUserInput] = useState("keep");
  const [finalCeilings, setFinalCeilings] = useState<any[]>([]);
  const [processWallIds, setProcessWallIds] = useState<any[]>([]);
  const [layoutCeilings, setLayoutCeilings] = useState(false);
  const [ceilingsDataState, setCeilingsData] = useState<any[]>([]);
  const [wallsDataByLevelState, setWallsDataByLevel] = useState<any[]>([]);
  const [notPerfectCeilingsState, setNotPerfectCeilings] = useState<any[]>([]);
  const [notPerfectCeilingsShow, setNotPerfectCeilingsShow] =
    useState<boolean>(false);
  const [notPerfectUserInput, setNotPerfectUserInput] = useState<
    "keep" | "discard" | "cancel" | ""
  >("");
  const [layoutCalculatorFlag, setLayoutCalculatorFlag] = useState(false);
  // is used to disable the validate button when no mapping row is selected
  const [isAnyMappingRowSelected, setMappingRowSelection] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // api.windowsHandler.showDevTools()
    //api.windowsHandler.resizeWindow(900, 400)
  }, []);

  useEffect(() => {
    if (layoutCeilings) processeCeilings();
  }, [layoutCeilings]);

  useEffect(() => {
    if (layoutState === "Ended" && !open) api.windowsHandler.closeWindow();
  }, [layoutState, open]);

  useEffect(() => {
    if (notPerfectUserInput === "keep" || notPerfectUserInput === "discard") {
      resetState();
      setLayoutCalculatorFlag(true);
      processLayoutCalculator();
    }

    if (notPerfectUserInput === "cancel") {
      resetState();
      dispatch(endProgress());
    }
  }, [notPerfectUserInput]);

  useEffect(() => {
    if (layoutCalculatorFlag) {
      processLayoutCalculator();
    }
  }, [layoutCalculatorFlag]);

  useEffect(() => {
    let isSelected = false;
    for (let i = 0; i < mappingRows.length; i++) {
      if (mappingRows[i].Checked === true) {
        isSelected = true;
        break;
      }
    }
    setMappingRowSelection(isSelected);
  }, [mappingRows]);

  const resetState = () => {
    setNotPerfectUserInput("");
    setNotPerfectCeilingsShow(false);
    setLayoutCeilings(false);
  };

  const layoutProgressFunction = () => {
    switch (layoutState) {
      case "StartLoading":
        return t("processing");
      case "FamilyLoading":
        return t("familyLoading");
      case "Processing":
        return `${t("processing")} ${layoutProgress} %`;
      case "Ended":
        return t("endLayout");
      default:
        return "";
    }
  };

  const tooglerHandler = () => {
    let allSelected = true;
  };

  const processeCeilings = async () => {
    if (finalCeilings.length > 0) {
      dispatch(startLayout());
      let ceilingTypesData: any[] = [];
      let ceilingsData: any[] = [];
      let token = localStorage.getItem("token");

      for (let i = 0; i < mappingRows.length; i++) {
        let row = mappingRows[i];
        if (row.EcophonSystem !== null && row.Checked) {
          let finalConfiguration: any = configurations[row.Configuration!];
          let finalProduct: any = products[row.Product];

          finalConfiguration["productName"] = finalProduct.product;
          let ceilingTypeData = {
            InputType: row.RevitSelection.RevitType,
            EcophonSystem: row.EcophonSystem,
            Range: row.Range,
            Configuration: finalConfiguration,
            rotation: row.Angle,
            heightUnderCeiling: row.HeightUnderCeiling,
            hangerType: row.MyHanger,
          };
          ceilingTypesData.push(ceilingTypeData);

          const found = intersection(row.RevitSelection.Ids, finalCeilings);

          let rowCeilingsData = await api.queries.getCeilingsData(found);
          rowCeilingsData = rowCeilingsData.map((ceiling: any) => ({
            ...ceiling,
            params: ceilingTypeData,
            ProductName: finalProduct.product,
            Height: row.HeightUnderCeiling,
            LevelBottomZ: row.HeightUnderCeiling,
          }));
          ceilingsData = [...ceilingsData, rowCeilingsData];
        }
        ceilingsData = ceilingsData.reduce((acc, curr) => acc.concat(curr), []);
      }

      let uniqueLevelIds = uniq(map(ceilingsData, "LevelId"));
      let wallsDataByLevel = await api.queries.getWallsDataByLevel(
        uniqueLevelIds
      );

      let deviceFingerprint = await api.license.GetMachineDetails();

      const perfectCeilingsRequest = axios.create({
        baseURL: config.REACT_APP_CALCULATORURL_ECOPHON + "/check",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "X-Fingerprint": deviceFingerprint.FingerPrint,
        },
      });

      perfectCeilingsRequest.interceptors.request.use(async (request) => {
        return await authFilter(request, config);
      });

      let perfectCeilingsResponse = await layout(
        perfectCeilingsRequest,
        ceilingsData,
        wallsDataByLevel
      );

      if (perfectCeilingsResponse.status === 200) {
        if (perfectCeilingsResponse.data.length > 0) {
          const ceilingRooms = await getCeilingRooms(
            ceilingsData,
            wallsDataByLevel
          );
          const notPerfectCeilingDetails = map(
            perfectCeilingsResponse.data,
            (d) => {
              const cel = find(ceilingsData, { Id: d.ceilingId });
              const rms = find(ceilingRooms, { CeilingId: d.ceilingId });
              if (rms) {
                const rmName = map(rms.Rooms, "RoomName").join(", ");
                d.roomname = rmName;
              }
              d.levelname = cel.LevelName;
              d.ceilingtype = cel.CeilingType;
              return d;
            }
          );
          setCeilingsData(ceilingsData);
          setWallsDataByLevel(wallsDataByLevel);
          setNotPerfectCeilings(notPerfectCeilingDetails);
          setNotPerfectCeilingsShow(true);
        } else {
          setCeilingsData(ceilingsData);
          setWallsDataByLevel(wallsDataByLevel);
          setLayoutCalculatorFlag(true);
        }
      } else {
        setOpen(true);
      }
    } else {
      dispatch(endLayout());
    }
  };

  const processLayoutCalculator = async () => {
    setLayoutCalculatorFlag(false);
    let ceilingsData = JSON.parse(JSON.stringify(ceilingsDataState));

    if (notPerfectUserInput === "discard") {
      const notPerfectCeilingsId = map(notPerfectCeilingsState, "ceilingId");
      remove(ceilingsData, function (n: any) {
        return includes(notPerfectCeilingsId, n.Id);
      });

      if (processWallIds.length > 0) {
        remove(processWallIds, function (n: any) {
          return includes(notPerfectCeilingsId, n);
        });
      }
    }

    if (ceilingsData.length > 0) {
      if (userInput === "erase") {
        for (let i = 0; i < processWallIds.length; i++) {
          await api.familyEditor.deleteParameters(
            "Ceiling",
            "Instance",
            "DATA",
            [
              {
                Id: processWallIds[i],
                Params: [
                  { Name: "Processed", Type: "YesNo", Value: 0 },
                  {
                    Name: "ProductName",
                    Type: "Text",
                    Value: "",
                  },
                  {
                    Name: "MaxLength",
                    Type: "Text",
                    Value: "",
                  },
                  {
                    Name: "MaxWidth",
                    Type: "Text",
                    Value: "",
                  },
                ],
              },
            ]
          );

          // await api.familyEditor.setParams([
          //   {
          //     Id: processWallIds[i],
          //     Params: [{ Name: "Processed", Type: "YesNo", Value: 0 }],
          //   },
          // ]);
          const elems = await api.queries.filterElements(
            "Generic",
            [
              {
                Param: {
                  Name: "id",
                  Type: "Integer",
                  Value: parseInt(processWallIds[i]),
                },
                Rule: "Equals",
              },
            ],
            null
          );
          await api.familyEditor.deleteElementsByIds(elems);
        }
      }

      const ceilingRooms = await getCeilingRooms(
        ceilingsData,
        wallsDataByLevelState
      );

      let token = localStorage.getItem("token");
      const localhost = axios.create({
        baseURL: config.REACT_APP_CALCULATORURL_ECOPHON + "/java",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });
      localhost.interceptors.request.use(async (request) => {
        return await authFilter(request, config);
      });

      let layoutRequest = await layout(
        localhost,
        ceilingsData,
        wallsDataByLevelState
      );
      let layoutData: any = layoutRequest.data;

      let eventDataArray = ceilingsDataState.map((obj: any) => {
        let data = {
          elementType: obj.params.InputType,
          solution: obj.ProductName,
          surface: obj.Area,
        };
        return data;
      });

      if (layoutData.messages.length > 0) setOpen(true);
      drawLayout(layoutData, ceilingRooms, eventDataArray, ceilingsData);
    } else {
      dispatch(endProgress());
    }
  };

  const getCeilingRooms = async (
    ceilingsData: any[],
    wallsDataByLevel: RevitMappedWall[]
  ) => {
    let deviceFingerprint = await api.license.GetMachineDetails();
    let token = localStorage.getItem("token");
    const neighbourWalls = axios.create({
      baseURL: config.REACT_APP_CALCULATORURL_ECOPHON + "/neighbourWalls",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "X-Fingerprint": deviceFingerprint.FingerPrint,
      },
    });
    neighbourWalls.interceptors.request.use(async (request) => {
      return await authFilter(request, config);
    });

    let neighbourWallRequest = await layout(
      neighbourWalls,
      ceilingsData,
      wallsDataByLevelState
    );
    const ceilingRooms = await api.queries.getRoomsWithWalls(
      JSON.stringify(neighbourWallRequest.data)
    );
    return ceilingRooms;
  };

  const handleUserInput = async () => {
    setNeedUserInput(false);

    if (userInput === "keep") {
      const ceilingToBeLayouted = filter(finalCeilings, (member) => {
        return !processWallIds.includes(member);
      });

      setFinalCeilings(ceilingToBeLayouted);
      setLayoutCeilings(true);
    } else if (userInput === "erase") {
      setLayoutCeilings(true);
    }
  };

  let defaultChecked = true;
  let allChk: any = filter(mappingRows, { Checked: false });
  defaultChecked = allChk.length === 0;

  const validationHandler = async () => {
    try {
      let allWalls = map(mappingRows, "RevitSelection.Ids");
      let ceilingToBeLayouted = flattenDeep(allWalls);

      const processedWallIdsByRevit: any[] = await api.queries.filterElements(
        "Ceiling",
        [
          {
            Param: {
              Name: "Processed",
              Type: "Boolean",
              Value: ceilingToBeLayouted,
            },
            Rule: "Includes",
          },
        ],
        null
      );

      setFinalCeilings(ceilingToBeLayouted);

      if (processedWallIdsByRevit.length > 0) {
        setNeedUserInput(true);
        setProcessWallIds(processedWallIdsByRevit);
      } else {
        setLayoutCeilings(true);
      }
    } catch (e) {
      console.log("Error in Calepinage CLT!!");
    }
  };

  return (
    <div id="draw2-solutionMapping-container">
      <FunctionalityHeader
        Icon={
          config.REACT_APP_SERVERURL +
          "/plugin_new_logo/ecophon-new-logo_icon.png"
        }
        name={t("mapping")}
        width={40}
        color="grey"
        height={40}
      />
      <Dimmer active={needUserInput}>
        <Segment
          textAlign="left"
          style={{ width: 600, padding: 20, height: 250, overflow: "auto" }}
        >
          <Header textAlign="center">Action requise</Header>
          <p style={{ color: "black" }}>
            Votre sélection comporte des plafond déjà calepinées, que
            souhaitez-vous faire ?
          </p>
          <Form>
            <Form.Field>
              <Radio
                label="Ignorer les doublons (le calepinage existant sera conservé)"
                name="radioGroup"
                value="keep"
                checked={userInput === "keep"}
                onChange={() => setUserInput("keep")}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="Remplacer le calepinage (le calepinage existant sera effacé)"
                name="radioGroup"
                value="erase"
                checked={userInput === "erase"}
                onChange={() => setUserInput("erase")}
              />
            </Form.Field>
          </Form>
          <br />
          <Button floated="right" onClick={() => handleUserInput()} primary>
            OK
          </Button>
        </Segment>
      </Dimmer>
      <Dimmer
        active={layoutState !== null}
        style={{ height: "calc(100vh - 30px)" }}
      >
        <Loader content={layoutProgressFunction()} />
      </Dimmer>
      <Segment
        style={{
          height: "calc(100vh - 180px)",
          overflow: "auto",
          padding: 10,
          paddingBottom: 1,
          marginBottom: 0,
          fontSize: ".9rem",
        }}
      >
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ width: "3%" }}>
                <Checkbox
                  //checked={toogler}
                  checked={defaultChecked}
                  onChange={(e, data) => {
                    setToogler(!toogler);
                    toggleAll(data.checked);
                    tooglerHandler();
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "5%" }}>
                {t("level")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "20%" }}>
                {t("revitSystemType")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "14%" }}>
                {t("ecophonSystem")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "10%" }}>
                {t("range")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "6%" }}>
                {t("configuration")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "15%" }}>
                {t("product")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "5%" }}>
                {t("angle")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "10%" }}>
                {t("heightUnderCeiling")}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "12%" }}>
                {t("hangerType")}
              </Table.HeaderCell>
              {/* <Table.HeaderCell style={{ width: 50 }}>
                  {this.props.wording.actions[this.props.language]}
                </Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {mappingRows.map((row, index) => (
              <MappingRowEcophonComponent
                key={`ecophone-mapping-row-${row.Index}-${row.Checked}-${index}`}
                setRoute={setRoute}
                mappingRow={row}
              />
            ))}
          </Table.Body>
        </Table>
      </Segment>
      <Segment basic>
        <Button
          floated="left"
          color="yellow"
          onClick={(e) => {
            setRoute(Routes.ROOT);
            //   this.props.resetSelectedItems();
            //   this.props.resetSelectedSystems();
          }}
        >
          {t("back")}
        </Button>
        <Button
          disabled={
            !selectedEcophonSystem ||
            !selectedRange ||
            !selectedConfiguration ||
            !isAnyMappingRowSelected
          }
          floated="right"
          color="yellow"
          onClick={validationHandler}
        >
          {t("validate")}
        </Button>
      </Segment>
      <AlertDialog
        open={open && layoutState === "Ended"}
        setOpen={setOpen}
        title={t("warning")}
        content={t("corridorMessage")}
      />
      <Dialog
        open={notPerfectCeilingsShow}
        onClose={() => setNotPerfectUserInput("cancel")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CLT4BIM"}</DialogTitle>
        <DialogContent style={{ paddingTop: "0" }}>
          <DialogContentText id="alert-dialog-description">
            {
              "We have detected that these ceilings might not be fully compliant with the workflow."
            }
            <br />
            {"This regards: "}
            <Table celled className="CLTConfirmTable">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      width: "25%",
                    }}
                  >
                    Floor
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      width: "25%",
                    }}
                  >
                    Room
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      width: "30%",
                    }}
                  >
                    Ceilings
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      width: "20%",
                    }}
                  >
                    ID
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {map(notPerfectCeilingsState, (notp: any, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell style={{ width: "25%" }}>
                        {notp.levelname}
                      </Table.Cell>
                      <Table.Cell style={{ width: "25%" }}>
                        {notp.roomname}
                      </Table.Cell>
                      <Table.Cell style={{ width: "30%" }}>
                        {notp.ceilingtype}
                      </Table.Cell>
                      <Table.Cell style={{ width: "20%" }}>
                        {notp.ceilingId}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {/* {map(notPerfectCeilingsState, (notp: any) => {
              return (
                <>
                  <br />
                  {`Floor '${notp.levelname}', Room '${notp.roomname}' and ceilings '${notp.ceilingtype}', with ceiling ID '${notp.ceilingId}'`}
                </>
              );
            })} */}

            {/* {map(notPerfectCeilingsState, "ceilingId").join("; ")} */}

            {"Do you want to pursue?"}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setNotPerfectUserInput("cancel")}
            classes={{ root: "button", label: "buttonLabel" }}
            style={{
              backgroundColor: "#808080",
              color: "white",
              margin: "0px auto",
            }}
            variant="contained"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={() => setNotPerfectUserInput("keep")}
            classes={{ root: "button", label: "buttonLabel" }}
            style={{
              backgroundColor: "#fbbd08",
              color: "white",
              margin: "0px auto",
            }}
            variant="contained"
          >
            {"Continue"}
          </Button>
          <Button
            onClick={() => setNotPerfectUserInput("discard")}
            classes={{ root: "button", label: "buttonLabel" }}
            style={{
              backgroundColor: "#fbbd08",
              color: "white",
              margin: "0px auto",
            }}
            variant="contained"
          >
            {"Continue without these ceilings"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: DrawStore, ownProps: any) => {
  return {
    language: state.language,
    Icon: state.functionalityIcon,
    mappingRows: state.mappingRows,
    setRoute: ownProps.setRoute,
    configurations: state.configurations,
    layoutAttributesAid: state.layoutAttributesAid,
    layoutState: state.layoutState,
    layoutProgress: state.layoutProgress,
    selectedEcophonSystem: state.selectedEcophonSystem,
    selectedRange: state.selectedRange,
    selectedConfiguration: state.selectedConfiguration,
    selectedAngle: state.selectedAngle,
    selectedHeightUnderCeiling: state.selectedHeightUnderCeiling,
    products: state.products,
    config: state.config,
  };
};

export default connect(mapStateToProps, {
  drawLayout,
  toggleAll,
})(withTranslation(["clt"])(SolutionMapping));
