import React from "react";
import { Button, Modal, Form, Checkbox } from "semantic-ui-react";
import "../../Resources/modal.css";
import { SelectionStore } from "../../../Selection/Reducers";
import { connect } from "react-redux";

const DocumentTypeModal = (props: any) => {
  return (
    <Modal open={props.documentTypeModal} size="mini" dimmer="inverted">
      <Modal.Header className="modalHeader">
        Choix des types de documents
      </Modal.Header>
      <Modal.Actions>
        <Form style={{ margin: "0px 0px 0px 75px" }}>
          <Form.Field style={{ display: "grid" }}>
            {props.dossierData &&
              props.dossierData["downloadDAE"].map((item: any, i: number) => (
                <Checkbox
                  key={i}
                  checked={item.isSelected}
                  className="chkFields"
                  onChange={(e) => props.onDownloadDocumentCheck(item.name)}
                  label={item.name}
                />
              ))}
          </Form.Field>
        </Form>
        <div className="modalButton">
          <Button
            color="orange"
            size="tiny"
            onClick={() => {
              props.closeDimmer();
            }}
          >
            {props.wording.cancel[props.language]}
          </Button>
          <Button
            // disabled={!props.newDocumentName}
            type="submit"
            size="tiny"
            primary
            onClick={() => {
              props.downloadDocument().catch((error: any) => {
                throw error;
              });
            }}
          >
            Télécharger
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    language: state.language,
    setRoute: ownProps.setRoute,
    routes: ownProps.route,
    Icon: state.functionalityIcon,
  };
};
export default connect(mapStateToProps)(DocumentTypeModal);
