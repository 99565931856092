import React from "react";
import { Icon, Table, Radio, Popup } from "semantic-ui-react";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";

export const CoverPageTable = (props: any) => {
  let arr: any[] = [];
  if (props.fileData !== null) {
    arr = Array.from(props.fileData);
  }
  const convertDate = (d: any) => {
    const split = d.split("/");
    let date = split[0];
    let month = split[1];
    if (date < 10) {
      date = "0" + date;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let ret = [date, month, split[2]].join("/");
    return ret;
  }

  return (
    <Table.Body className="coverpage-table">
      <Table.Row textAlign="center">
        <Table.Cell>
          <Radio
            name="radioGroup"
            value="default"
            checked={props.selectedRadioFile === "default"}
            id="default"
            onChange={(e: any) => props.handleChangeCheckbox(e)}
            style={{ marginTop: "-5px" }}
          />
        </Table.Cell>
        <Table.Cell>Modèle par défaut</Table.Cell>
        <Table.Cell>{convertDate(props.defaultDate)}</Table.Cell>
        <Table.Cell>
          <div onClick={() => props.previewEyeHandler()}>
            <Popup
              content={ReactHtmlParser("Afficher le document")}
              position="top center"
              inverted
              size="mini"
              trigger={<Icon name="eye" />}
            />
          </div>
        </Table.Cell>
      </Table.Row>
      {props.fileData !== null
        ? arr.map((file: any) => (
          <Table.Row textAlign="center" key={file.name}>
            <Table.Cell>
              <Radio
                name="radioGroup"
                value={file.name}
                id={file.name}
                checked={props.selectedRadioFile === file.name}
                onChange={(e: any) => props.handleChangeCheckbox(e)} style={{ marginTop: "-5px" }}
              />
            </Table.Cell>
            <Table.Cell>
              {file.screenName
                ? file.screenName.split(".")[0]
                : "Please delete this file & upload again"}
            </Table.Cell>
            <Table.Cell>
              {format(new Date(file.lastModifiedDate), "dd/MM/yyyy")}
            </Table.Cell>
            <Table.Cell>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div onClick={() => props.previewEyeHandler(file.name)} style={{ cursor: "pointer" }}>
                  <Popup
                    content={ReactHtmlParser("Afficher le document")}
                    position="top center"
                    inverted
                    size="mini"
                    trigger={<Icon name="eye" />}
                  />
                </div>
                &nbsp;&nbsp;
                <div onClick={() => props.deleteHandler(file.name)} style={{ cursor: "pointer" }}>
                  <Popup
                    content={ReactHtmlParser("Supprimer le document")}
                    position="top center"
                    inverted
                    size="mini"
                    trigger={<Icon name="delete" />}
                  />
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
        ))
        : null}
    </Table.Body>
  );
};
