import React, { useEffect, useState } from "react";
import { Table, Checkbox, Dropdown, Icon, Popup } from "semantic-ui-react";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import uniq from "lodash/uniq";
import matchesProperty from "lodash/matchesProperty";
import minBy from "lodash/minBy";
import { conforms, sortBy } from "lodash";
import maxBy from "lodash/maxBy";
import join from "lodash/join";
import compact from "lodash/compact";

const compareceilingAndSolutionHeight = (
  ceilingHeight: number,
  solutionHeight: number
) => {
  if (ceilingHeight !== undefined) {
    if (solutionHeight < ceilingHeight) {
      return "redHeight";
    }

    return "greenHeight";
  }
  return "";
};

export const plaqueDefault = (plaqueArray: any, ceilingHeight: string) => {
  // 1. retrun equal height (= ceilingHeight) plaque
  let selectedPlaque = find(
    plaqueArray,
    matchesProperty("height", ceilingHeight)
  );

  // 2. if selectedPlaque null
  if (!selectedPlaque) {
    // 3. filter plaqs with "height" greater than ceilingheight only
    //    then retrun minimum height plaqs from that
    selectedPlaque = minBy(
      filter(
        plaqueArray,
        conforms({
          height: function (n: any) {
            let newHt = parseFloat(ceilingHeight);
            return n > newHt;
          },
        })
      ),
      "height"
    );
  }

  // 4. If selectedPlaque is null or empty
  //    return maximum height plaq.

  if (!selectedPlaque) {
    selectedPlaque = maxBy(plaqueArray, "height");
  }
  return selectedPlaque.value;
};

export const montantDefault = (montantArray: any, ceilingHeight: string) => {
  let selectedMontant: any = find(
    montantArray,
    matchesProperty("height", ceilingHeight)
  );

  if (!selectedMontant) {
    selectedMontant = minBy(
      filter(
        montantArray,
        conforms({
          height: function (n: any) {
            let newHt = parseFloat(ceilingHeight);
            return n > newHt;
          },
        })
      ),
      "height"
    );
  }

  if (!selectedMontant) {
    selectedMontant = maxBy(montantArray, "height");
  }
  return selectedMontant.value;
};

const CeilingTableRow = (props: {
  setRoute: any;
  ceilings: any;
  setPlaque: any;
  setMontant: any;
  index: any;
  showConfig: any;
  setCheckedCeilings: any;
  rowKey: any;
  setCeilingX: any;
  setCeilingY: any;
}) => {
  const [checked, setChecked] = useState<boolean>(
    props.ceilings.layoutPossible
  );

  const [valueX, setX] = useState<number>(props.ceilings.x);

  const [valueY, setY] = useState<number>(props.ceilings.y);

  const handlePlaqueChange = (index: number, data: any) => {
    let plaque = props.ceilings.plaque;
    (plaque[index] as string[]) = data;
    props.setPlaque(props.ceilings, plaque);
  };

  const handleMontantChange = (index: number, data: any) => {
    let montant = props.ceilings.montant;
    (montant[data.index] as string[]) = data;
    props.setMontant(props.ceilings, montant);
  };

  const { ceilings } = props;

  useEffect(() => {
    setChecked(ceilings.layoutPossible && ceilings.chk && ceilings.placo);
  }, [ceilings]);

  return (
    <Popup
      inverted
      content={
        !ceilings.placo
          ? "Ce système Placo ne peut être calepiné actuellement"
          : !ceilings.layoutPossible &&
          "Ce système Placo ne peut être calepiné actuellement"
      }
      disabled={ceilings.layoutPossible}
      key={props.rowKey}
      trigger={
        <Table.Row
          style={!checked ? { color: "#cecece" } : {}}
          key={props.rowKey}
        >
          <Table.Cell style={{ width: "5%", padding: "1em" }}>
            <Checkbox
              checked={checked}
              onChange={(e, data) => {
                setChecked(!checked);
                props.setCheckedCeilings(
                  ceilings.CeilingType,
                  ceilings.Height,
                  ceilings.LevelName,
                  !checked
                );
              }}
              disabled={!ceilings.placo || !ceilings.layoutPossible}
            />
          </Table.Cell>
          <Table.Cell style={{ width: "5%" }}>{ceilings.LevelName}</Table.Cell>

          {ceilings.Zone !== "" && (
            <Table.Cell style={{ width: "5%" }}>{ceilings.Zone}</Table.Cell>
          )}
          <Table.Cell
            style={{ width: "13%" }}
          >{`${ceilings.CeilingType} (${ceilings.Ids.length})`}</Table.Cell>
          <Table.Cell style={{ width: "20%" }}>
            {ceilings.PlacoSolution}
          </Table.Cell>
          {/* Temporary hidden
          <Table.Cell style={{ width: "5%" }}>
            {ceilings[0].plaque && ceilings[0].layoutPossible ? (
              <Icon
                name="wrench"
                color="blue"
                onClick={() => {
                  props.showConfig(
                    true,
                    ceilings[0].PlacoSolution.MappedSystem.oid
                  );
                }}
                style={{ padding: "0px" }}
              />
            ) : null}
          </Table.Cell> */}
          <Table.Cell style={{ width: "17%" }}>
            {ceilings.plaque &&
              map(ceilings.plaqueArray, (item, index) => {
                // we need to check if default plaque is not blank else it may render before state taking values
                const sortedArticles = sortBy(item.articles, [
                  function (o) {
                    return o.text;
                  },
                ]);

                const finalPlaqDropdownElement = map(
                  sortedArticles,
                  (srtArticle, srtIndex) => {
                    return {
                      text: srtArticle.text,
                      value: srtArticle.value,
                      artid: srtArticle.article.oid,
                      partid: srtArticle.packagedArticle.oid,
                    };
                  }
                );

                return (
                  ceilings.plaque[index] && (
                    <Popup
                      content={
                        typeof ceilings.plaque[index] === "string"
                          ? ceilings.plaque[index]
                          : ceilings.plaque[index].value
                      }
                      position="left center"
                      trigger={
                        <Dropdown
                          name="plaque"
                          fluid
                          selection
                          options={finalPlaqDropdownElement}
                          defaultValue={
                            typeof ceilings.plaque[index] === "string"
                              ? ceilings.plaque[index]
                              : ceilings.plaque[index].value
                          }
                          onChange={(e: any, data: any) => {
                            const dataOpt = find(data.options, {
                              value: data.value,
                            });
                            handlePlaqueChange(data.index, dataOpt);
                          }}
                          placeholder="Select Plaque"
                          index={index}
                          // disabled={!ceilings.layoutPossible}
                          disabled={!checked}
                        />
                      }
                    />
                  )
                );
              })}
          </Table.Cell>
          <Table.Cell style={{ width: "17%" }}>
            {ceilings.montant &&
              map(ceilings.montantArray, (item, index) => {
                const sortedArticles = sortBy(item.articles, [
                  function (o) {
                    return o.text;
                  },
                ]);

                const finalMontantDropdownElement = map(
                  sortedArticles,
                  (srtArticle, srtIndex) => {
                    return {
                      text: srtArticle.text,
                      value: srtArticle.value,
                      artid: srtArticle.article.oid,
                      partid: srtArticle.packagedArticle.oid,
                    };
                  }
                );

                return (
                  ceilings.montant[index] && (
                    <Popup
                      content={ceilings.montant[index]}
                      trigger={
                        <Dropdown
                          name="montant"
                          fluid
                          selection
                          options={sortedArticles}
                          defaultValue={ceilings.montant[index]}
                          onChange={(e: any, data: any) =>
                            handleMontantChange(e, data)
                          }
                          placeholder="Select Montant"
                          index={index}
                          //disabled={!ceilings.layoutPossible}
                          disabled={!checked}
                        />
                      }
                    />
                  )
                );
              })}
          </Table.Cell>

          {/*  // do not delete or uncomment. (confirm with Yogesh)
          {ceilings.installationType && ceilings.supportCeiling ? (  
            <Table.Cell style={{ width: "19%" }}>
              <div>
                <label style={{ display: "flex", whiteSpace: "nowrap" }}>
                  x (m)
                  <input
                  type="number"
                    style={{ width: 30, marginLeft: 5 }}
                    defaultValue={valueX}
                    disabled={!checked}
                    onChange={(e) => {
                      props.setCeilingX(
                        props.index,
                        //parseInt(e.target.value) ? parseInt(e.target.value) : ""
                        e.target.value ? e.target.value : ""
                      );
                    }}
                  />
                </label>
              </div>
              <div>
                <label style={{ display: "flex", whiteSpace: "nowrap" }}>
                  y (m)
                  <input
                    type="number"
                    style={{ width: 30, marginLeft: 5 }}
                    defaultValue={valueY}
                    disabled={!checked}
                    onChange={(e) => { 
                      props.setCeilingY(
                        props.index,
                       // parseInt(e.target.value) ? parseInt(e.target.value) : ""
                       e.target.value ? e.target.value : ""
                      );
                    }}
                  />
                </label>
              </div>
            </Table.Cell>
          ) : (
            <Table.Cell style={{ width: "19%" }}></Table.Cell>
          )} */}
        </Table.Row>
      }
    />
  );
};

export default CeilingTableRow;
