import React, { useEffect, useContext, useState } from "react";
import { FunctionalityHeader } from "../../../Selection/Components/FunctionalityHeader";
import {
  Grid,
  Button,
  Radio,
  Table,
  Icon,
  Container,
  Dimmer,
  Loader,
  Modal,
} from "semantic-ui-react";
import { Routes } from "../root";
import {
  IQuantityContextType,
  QuantityContext,
} from "../../context/quantityContext";
import _,{ map } from "lodash";
import { groupWallsDataFromSelection } from "../../util/groupWallSelection";
import { groupCeilingsDataFromSelection } from "../../util/groupCeilingSelection";
import ElementType from "../ChoiceOfSources/Selection/ElementType";

interface Props {
  setRoute: any;
  route: string;
}
const style = {
  editSelectionSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
    marginBottom: "0",
  },
  processButtonSegment: {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "0px",
  },
};

const wording: WordingType = {
  name: {
    French: "CALEPINAGE",
    English: "LAYOUT",
  },
  editSelection: {
    French: "Créer / Modifier des sélections",
    English: "Create / Modify selections",
  },
  back: {
    French: "Retour",
    English: "Back",
  },
  validate: {
    French: "Valider",
    English: "Validate",
  },
  saveSelectionList: {
    French: "Liste des sélection de systèmes sauvegardées",
    English: "Saved selections list",
  },
};

type WordingType = {
  name: LanguageType;
  editSelection: LanguageType;
  back: LanguageType;
  validate: LanguageType;
  saveSelectionList: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
};

const FloorRow = (props: any) => {
  const convertDate = (d: any) => {
    let sp = d.split("/");
    let str1: any = sp[1];
    let str2: any = sp[0];
    if (str1.length == 1) {
      str1 = 0 + sp[1];
    }
    if (str2.length == 1) {
      str2 = 0 + sp[0];
    }
    let ret = [str2, str1, sp[2]].join("/");
    return ret;
  };

  return (
    <Table.Row style={{ cursor: "pointer" }}>
      <Table.Cell className="width5">
        <Radio
          label=""
          name={"selection-radio"}
          style={{ marginLeft: "5px" }}
          value={props.Id}
          checked={props.selectSelection.includes(props.Id)}
          onChange={(e, { value }) => {
            props.setSelectedFloor(value);
          }}
        />
      </Table.Cell>
      <Table.Cell className="width45">{props.Name}</Table.Cell>
      {/* <Table.Cell className="width25">{props.Levels[0]}</Table.Cell> //for reference */}
      <Table.Cell className="wdth25">{!_.isEmpty(props.Levels) && props.Levels.join("; ")}</Table.Cell>
      <Table.Cell className="width25">{convertDate(props.Date)}</Table.Cell>
    </Table.Row>
  );
};

const FloorList = (props: Props) => {
  const {
    selections,
    selectSelection,
    setSelectedSelection,
    config,
    setProcessWalls,
    elementType,
    setElementType,
    setProcessCeilings,
  } = useContext(QuantityContext) as IQuantityContextType;
  const [loading, setLoading] = useState<boolean>(false);
  const [elementChoiceOpen, setElementChoiceOpen] = React.useState(false);

  useEffect(() => {
    setProcessWalls([]);
    setProcessCeilings([]);
    setSelectedSelection([]);
  }, []);
  const applicationLanguage = "French";

  let convertDate = (d: any) => {
    let sp = d.split("/");
    let ret = [sp[1], sp[0], sp[2]].join("/");
    return ret;
  };
  const sortedFloorList = Object.values(selections).sort((a: any, b: any) => {
    return +new Date(convertDate(b.Date)) - +new Date(convertDate(a.Date));
  });

  const onValider = async () => {
    setLoading(true);
    setElementChoiceOpen(true);
    setLoading(false);
  };

  const onElementSelection = async (eleType: string) => {
    setLoading(true);
    setElementType(eleType);
    if (eleType === "wall") {
      let processWalls = await groupWallsDataFromSelection(
        selections,
        selectSelection,
        config
      );

      if (!processWalls) {
        processWalls = [];
      }

      if (processWalls && processWalls.length === 0) {
        processWalls = [];
      }
      setProcessWalls(processWalls);
    }
    if (eleType === "ceiling") {
      let processCeilings = await groupCeilingsDataFromSelection(
        selections,
        selectSelection,
        config
      );

      if (!processCeilings) {
        processCeilings = [];
      }

      if (processCeilings && processCeilings.length === 0) {
        processCeilings = [];
      }
      setProcessCeilings(processCeilings);
    }
    setLoading(false);
    props.setRoute(Routes.WALLTABLECOMPONENT);
  };

  if (loading) {
    return (
      <>
        <Container
          className="grid-container"
          style={{ width: "90%", border: "0 none", padding: "0" }}
        >
          <Dimmer
            active
            style={{
              height: "calc(100vh - 30px)",
            }}
          >
            <Loader />
          </Dimmer>
        </Container>
      </>
    );
  }

  return (
    <>
      <FunctionalityHeader
        Icon={""}
        name={"QUANTITATIFS PAR RATIO"}
        subheader=""
      />
      <Container style={{ paddingTop: "10px" }}>
        <Table
          compact
          role="grid"
          aria-labelledby="header"
          className="selectionTable common-table"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                id="all-header"
                className="width100 bg-blue-header"
                colSpan="4"
              >
                {wording.saveSelectionList[applicationLanguage]}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row className="bg-light-blue">
              <Table.HeaderCell
                id="radio-header"
                className="width5 text-center"
              >
                <Radio
                  label=""
                  name={"selection-radio"}
                  style={{ visibility: "hidden" }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                id="selectionName-header"
                className="width45 text-center"
              >
                Nom de la sélection
              </Table.HeaderCell>
              <Table.HeaderCell
                id="niveaux-header"
                className="width25 text-center"
              >
                Niveaux
              </Table.HeaderCell>
              <Table.HeaderCell
                id="date-header"
                className="width25 text-center"
              >
                Date de modification
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {map(sortedFloorList, (selectionItem: any, index: any) => {
              return (
                !selectionItem.Zone && (
                  <FloorRow
                    {...selectionItem}
                    key={index}
                    setSelectedFloor={setSelectedSelection}
                    selectSelection={selectSelection}
                  />
                )
              );
            })}
          </Table.Body>
        </Table>
      </Container>
      <Grid columns={1} stackable style={{ ...style.editSelectionSegment }}>
        <Grid.Column
          style={{
            paddingBottom: "5px !important",
            paddingTop: "15px",
            paddingLeft: "25px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              minWidth: "0px",
              float: "left",
            }}
            // onClick={() => this.handleEditButton()}
            onClick={() => {
              props.setRoute(Routes.SELECTIONHOME);
            }}
          >
            <Icon
              name="edit"
              color="blue"
              size="big"
              style={{ fontSize: "1.5em" }}
            />
            <span style={{ color: "rgb(14,110,184)" }}>
              {wording.editSelection[applicationLanguage]}
            </span>
          </div>
        </Grid.Column>
      </Grid>

      <Grid columns={1} stackable style={{ ...style.processButtonSegment }}>
        <Grid.Column
          style={{ paddingBottom: 0, textAlign: "center", paddingTop: 0 }}
        >
          <Button color="orange" onClick={() => props.setRoute(Routes.ROOT)}>
            Retour
          </Button>
          <Button
            type="submit"
            primary
            onClick={() => onValider()}
            disabled={selectSelection.length === 0}
          >
            Valider
          </Button>
        </Grid.Column>
      </Grid>

      <Modal
        open={elementChoiceOpen}
        size="small"
        className="modal-close"
        closeIcon
        onClose={() => setElementChoiceOpen(false)}
      >
        <Modal.Actions>
          <ElementType
            setElementType={onElementSelection}
            elementType={elementType}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default FloorList;
