import React from "react";
import { Button, Image, Modal } from "semantic-ui-react";
import { api } from "../../RevitJS/API";
import errorImg from "./Resources/alert.png";
import "./Resources/errorManagement.scss";

interface Props {
  open: boolean;
  reload: boolean;
  closeModal: any;
  openReport: any;
  message: string;
  code: any;
  onReloadRedirect: string;
}

export const ErrorModal = ({
  open,
  reload,
  closeModal,
  openReport,
  message,
  code,
  onReloadRedirect,
}: Props) => {
  return (
    <Modal
      open={open}
      size="mini"
      dimmer="blurring"
      className="error-management-modal"
    >
      <Modal.Header
        style={{
          textAlign: "center",
          color: "#2185d0",
          padding: "13px",
          fontSize: "1em",
          borderBottom: "0 none",
        }}
      >
        Application Error!
      </Modal.Header>
      <Modal.Content
        style={{ textAlign: "center", padding: "0px 20px 10px !important" }}
      >
        <Image src={errorImg} alt="Alert Image" className="center-img" />
        {/* <Icon name="exclamation triangle" size="large" color="yellow" /> */}
        <p style={{ margin: "0 10px 5px" }}>
          <label
            style={{
              color: "#2185d0",
              marginRight: "3px",
              fontWeight: "bold",
            }}
          >
            Code :
          </label>
          {code}
        </p>
        <p>
          <label
            style={{
              color: "#2185d0",
              marginRight: "3px",
              fontWeight: "bold",
            }}
          >
            Message :
          </label>
          {message}
        </p>

        {/* if this div contains one button then use CLASSNAME "containing-one-button" and remove "containing-two-buttons" */}
        <div
          style={{
            width: "90%",
            margin: "0 auto",
            display: "flex",
          }}
          className="containing-two-buttons"
        >
          <Button         
            className="btn-cancel"
            size="mini"
            floated="left"
            onClick={() => {
              if (reload) {
                if (onReloadRedirect !== "") {
                  window.location.href = onReloadRedirect;
                } else {
                  window.location.reload();
                  if (
                    localStorage.getItem("module") === "Reverse Direction" ||
                    localStorage.getItem("module") === "Reverse Sides" ||
                    localStorage.getItem("module") === "suppression" ||
                    localStorage.getItem("module") === "dimensions" ||
                    localStorage.getItem("module") === "Quantitatives" ||
                    localStorage.getItem("module") === "colorization"
                  ) {
                    api.windowsHandler.closeWindow();
                  }
                }
              } else {
                closeModal();
                if (
                  localStorage.getItem("module") === "Reverse Direction" ||
                  localStorage.getItem("module") === "Reverse Sides" ||
                  localStorage.getItem("module") === "suppression" ||
                  localStorage.getItem("module") === "dimensions" ||
                  localStorage.getItem("module") === "Quantitatives" ||
                  localStorage.getItem("module") === "colorization"
                ) {
                  api.windowsHandler.closeWindow();
                }
              }
            }}
          >
            Close
          </Button>
          <Button
            primary
            size="mini"
            onClick={() => openReport(true)}
            floated="right"
            className="btn-ok"
            style={{ margin: "5px" }}
          >
            Report
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
