import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { bimStorage, storageKey } from "../../../BIMStore";
import { api } from "../../../RevitJS/API";
import "./WindowHeader.scss";

interface Props {
  openConfirm: boolean;
  pluginName: string;
  module: string;
  textColor?: string;
  className?: string;
}
type WordingType = {
  msg: LanguageType,
  no: LanguageType;
  yes: LanguageType;
};

type LanguageType = {
  French: string;
  English: string;
  German: string;
  Italian: string;
  Indonesian: string
};

type Langs = "French" | "English";

const WindowHeader = (props: Props) => {
  const { module } = props;
  const [lang, setLang] = useState<Langs>("English");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  //onclick is commented temporary
  // const openConfirmModal = () => {
  //   if (props.openConfirm) {
  //     setOpenConfirm(true);
  //   } else {
  //     api.windowsHandler.closeWindow();
  //   }
  // };
  const openBtnConfirmModal = async () => {
    // check if localstorage does exist isModification true then show modification window or else close modal straightaway
    console.log("is Modification on :- ", localStorage.getItem("isModification"))
    if (localStorage.getItem("isModification") === "true") {
      if (!openConfirm) {
        setOpenConfirm(true);
      } else {
        localStorage.removeItem("valueChanged");
        localStorage.removeItem("valueDelete");
        localStorage.removeItem("isModification");
        setOpenConfirm(false);
        api.windowsHandler.closeWindow();
      }
    } else if (localStorage.getItem("isModification") === "false") {
      localStorage.removeItem("isModification");
      localStorage.removeItem("valueChanged");
      localStorage.removeItem("valueDelete");
      setOpenConfirm(false);
      api.windowsHandler.closeWindow();
    } else {
      localStorage.removeItem("valueChanged");
      localStorage.removeItem("valueDelete");
      api.windowsHandler.closeWindow();
    }
  };

  const onOKClicked = () => {
    //localStorage.removeItem("isModification");
    setOpenConfirm(false);
  };

  const onNoClicked = () => {
    localStorage.removeItem("isModification");
    setOpenConfirm(false);
    api.windowsHandler.closeWindow();
  };

  useEffect(() => {
    let language: any = localStorage.getItem("savedLanguage");
    const changeLanguage = async () => {
      if (language !== lang) {
        setLang(language);
      }
    };
    changeLanguage();
  }, []);

  const wording: WordingType = {
    msg: {
      French: "Voulez-vous sauvegarder vos modifications ou progrès avant de fermer la fenêtre?",
      English: "Do you want to save your changes or progress before closing the window?",
      German: "Möchten Sie Ihre Änderungen oder Ihren Fortschritt speichern, bevor Sie das Fenster schließen?",
      Italian: "Vuoi salvare le modifiche o i progressi prima di chiudere la finestra?",
      Indonesian: "Apakah Anda ingin menyimpan perubahan atau progres Anda sebelum menutup jendela?"
    },
    no: {
      French: "Non",
      English: "No",
      German: "Nö",
      Italian: "No",
      Indonesian: "Tidak"
    },
    yes: {
      French: "Oui",
      English: "Yes",
      German: "Ja",
      Italian: "sì",
      Indonesian: "Ya"
    },
  };

  return (
    <div
      style={{
        display: "flex",
        height: 30,
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="moveIcon"
    >
      <div style={{ position: "fixed", paddingLeft: 10, width: "500px" }}>
        {/* <img
          src={"/revit-logo.png"}
          width={15}
          height={16}
          style={{ float: "left", marginRight: "10px" }}
          className="noDrag"
        /> */}
        <h4 style={{ margin: 0, float: "left", color: props.textColor }}>
          {props.pluginName}
        </h4>
      </div>
      <div style={{ position: "fixed", right: 0, top: -3, display: "flex" }}>
        <Button
          size="small"
          style={{ borderRadius: 0, marginRight: 0, backgroundColor: "#fff" }}
          icon="minus"
          className="noDrag"
          onClick={() => {
            api.windowsHandler.minimizeWindow();
          }}
        />
        <Button
          size="small"
          style={{ borderRadius: 0, marginRight: 0, backgroundColor: "#fff" }}
          icon="window maximize"
          className="noDrag"
          onClick={() => {
            api.windowsHandler.maximizeWindow();
          }}
        />
        <Button
          size="small"
          style={{ borderRadius: 0, marginRight: 0, backgroundColor: "#fff" }}
          className="noDrag"
          onClick={() => {
            openBtnConfirmModal();
          }}
          icon="close"
        />
      </div>
      {module === "project info" ||
        module === "selection" ||
        module === "calepinage" ||
        module === "dossier techniques" ||
        module === "reperage" ||
        module === "suppression" ? (
        <Modal open={openConfirm} size="mini" style={{ maxWidth: 300 }}>
          <Modal.Content>
            <p style={{ padding: "20px 10px", textAlign: "center" }}>
              {/* By closing the window all your unsaved changes will be lost? */}
              {wording.msg[lang]}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content={wording.yes[lang]}
              onClick={() => {
                onOKClicked();
              }}
              positive
            />
            <Button
              negative
              onClick={() => {
                onNoClicked();
              }}
            >
              {wording.no[lang]}
            </Button>

          </Modal.Actions>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default WindowHeader;
