import { api } from '../../../RevitJS/API';
import { pluginId } from '../index';

export const functionalityId = "b3d00ba4-d08d-4a06-965a-5400e2826d5d";

export const Components = [ ];


export const overlapTrigger = async () => {
    const plasterboardIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Plasterboard" }, Rule: "Equals" }], null);
    const framesIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Ossature" }, Rule: "Equals" }], null);
    const bottomRailsIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail" }, Rule: "Equals" }], null);
    const upperRailsIds = await api.queries.filterElements("Generic", [{ Param: { Name: "Name", Type: "Builtin", Value: "Rail-Retourne" }, Rule: "Equals" }], null);
    const processedWallIds = await api.queries.filterElements("Wall", [{ Param:{ Name:"Processed", Type:"Boolean", Value:true}, Rule:"Equals"}], null);
    const processedCeilingsIds = await api.queries.filterElements("Ceiling", [], null);

    const railsIds = bottomRailsIds.concat(upperRailsIds);
    let plasterboardsFilter = await api.queries.createSelectionFilter("SG_Plasterboards", plasterboardIds);
    let framesFilter = await api.queries.createSelectionFilter("SG_Frames", framesIds);
    let railsFilter = await api.queries.createSelectionFilter("SG_Rails", railsIds);
    let processedWallsFilter = await api.queries.createSelectionFilter("SG_Revit_Walls", processedWallIds);
    let processedCeilingsFilter = await api.queries.createSelectionFilter("SG_Revit_Ceilings", processedWallIds);

    let activeView = await api.queries.getActiveViewDetails();
    let activeViewId = activeView.Id;

    await api.viewHandler.setSelectionFilterVisibility(activeViewId, plasterboardsFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, framesFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, railsFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, processedWallsFilter, true);
    await api.viewHandler.setSelectionFilterVisibility(activeViewId, processedCeilingsFilter, true);
};


export const Overlap = {
    Name: {"French": "Superposition", "English": "Overlay", "German": "Overlay"},
    Trigger: overlapTrigger,
    ShortDesc: {"French": "Modèle de base et calepinage", "English": "Basic model and layout", "German": "Grundmodell und Layout"},
    Id: functionalityId,
    Components,
    Icon: "/pluginIcons_V3/PlacoBIM_Icon_VueSuperposition.png",
    ParentId:"0"
}

