import React, { Component } from "react";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import {
    Table,
    Checkbox,
    Dropdown,
    DropdownItemProps,
    Input,
    Popup,
} from "semantic-ui-react";
import { MappingRowEcophon } from "../../../../RevitJS/Types/StoreTypes";
import {
    selectSystem,
    setPublicConstrain,
    setFireConstrain,
    initProductSelector,
    setActiveMappingRowIndex,
    selectEcophonSystem,
    selectRange,
    selectConfiguration,
    selectProduct,
    selectAngle,
    selectHeightUnderCeiling,
    selectMyHanger,
    toggleCheck,
} from "../Actions/index";
import Slider from "./Slider";
import { Corridor } from "../dbFaker";

interface Props {
    mappingRow: MappingRowEcophon;
    language: string;
    options: { value: string; label: string }[];
    selectSystem: any;
    setFireConstrain: any;
    setPublicConstrain: any;
    setRoute: any;
    initProductSelector: any;
    setActiveMappingRowIndex: any;
    selectEcophonSystem: any;
    selectRange: any;
    toggleCheck: Function;
    selectConfiguration: any;
    selectProduct: any;
    selectAngle: any;
    selectHeightUnderCeiling: any;
    selectMyHanger: any;
    ecophonSystems: { [key: string]: { id: string } };
    ranges: { [key: string]: { id: string; parentSystem: string } };
    configurations: {
        [key: string]: { id: string; config: string; parentRange: string };
    };
    products: {
        [key: string]: {
            id: string;
            parentRange: string;
            parentConfig: string;
            parentSystem: string;
            product: string;
        };
    };
}
interface State { }

export class MappingRowEcophonComponent extends Component<Props, State> {
    state = {};

    public static defaultProps = {
        options: [
            {
                value: "test",
                label: "test",
            },
        ],
    };

    componentDidMount = () => {
    };

    render() {
        const {
            Index,
            Checked,
            Level,
            RevitSelection,
            EcophonSystem,
            Range,
            Configuration,
            Angle,
            Product,
            HeightUnderCeiling,
            MyHanger,
        } = this.props.mappingRow;

        const { language, configurations, products, ranges, ecophonSystems } =
            this.props;

        const productOptions: DropdownItemProps[] = Object.keys(products)
            .filter((conf: string) => products[conf].parentConfig === Configuration)
            .map((key) => ({
                text: products[key].product,
                value: products[key].id,
            }))
            
        const configurationOptions: DropdownItemProps[] = Object.keys(
            configurations
        )
            .filter((conf: string) => configurations[conf].parentRange === Range)
            .map((key) => ({
                text: configurations[key].config,
                value: configurations[key].id,
            }))
            //.concat({ text: "", value: "" });

        const rangeOptions: DropdownItemProps[] = Object.keys(ranges)
            .filter((range) => ranges[range].parentSystem === EcophonSystem)
            .map((key) => ({ text: ranges[key].id, value: ranges[key].id }))
            //.concat({ text: "", value: "" });

        const systemOptions: DropdownItemProps[] = Object.keys(ecophonSystems)
            .map((key) => ({
                text: ecophonSystems[key].id,
                value: ecophonSystems[key].id,
            }))
            //.concat({ text: "", value: "" });
        const myhangerOptions = [
            { value: "Connect Adjustable Hanger", text: "Connect Adjustable Hanger" },
            { value: "Threaded Rog", text: "Threaded Rog" },
        ];
        const getProductTitle = (productId: any) => {
            let productName: string = "";
            if (productId) {
                let arrProductId = productId.split("_");
                if (arrProductId.length > 1) {
                    productName = arrProductId[2];
                }
            }
            return productName;
        };
        return (
            <Table.Row key={Index}>
                <Table.Cell style={{ width: "3%" }}>
                    <Checkbox
                        onChange={() => {
                            this.props.toggleCheck(Index);
                            //   this.props.tooglerHandler();
                        }}
                        checked={Checked}
                    />
                </Table.Cell>
                <Table.Cell className="level-width" style={{ width: "5%" }}>
                    {Level}
                </Table.Cell>
                <Table.Cell style={{ width: "20%" }}>
                    <Popup
                        content={`${RevitSelection.RevitType} (${RevitSelection.Ids.length})`}
                        trigger={
                            <div className="ellipsis">
                                <span>{`${RevitSelection.RevitType} (${RevitSelection.Ids.length})`}</span>
                            </div>
                        }
                    />
                </Table.Cell>
                <Table.Cell style={{ width: "14%" }}>
                    <style>
                        {`\
                        .dropdown.ecophonsystem .text {  
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; width: 90px;
                        float:left}\
                        .dropdown.ecophonsystem .item .text {  
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; width: auto;
                        float:none}\
                        
                        .dropdown.range .text {  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; float:left;   width: 50px;}\
                        .dropdown.range .item .text {  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; float:none;   width: auto;}\
                        `}
                    </style>
                    <Popup
                        content={`${EcophonSystem ?? "---"}
                        `}
                        trigger={
                            <Dropdown
                            // title={EcophonSystem}
                            options={systemOptions}
                            className={"ecophonsystem"}
                            style={{ width: "100%" }}
                            onChange={(e, d) => {
                                this.props.selectEcophonSystem(
                                    Index,
                                    d.value === "---" ? null : d.value
                                );
                            }}
                            value={EcophonSystem ?? "---"}
                            />
                        }
                    />
                    
                </Table.Cell>
                <Table.Cell style={{ width: "10%" }}>
                    <Popup
                        content={`${Range}`}
                        trigger={
                            <Dropdown
                                // title={Range}
                                options={rangeOptions}
                                className={"range"}
                                style={{ width: "100%" }}
                                onChange={(e, d) => {
                                    this.props.selectRange(Index, d.value);
                                }}
                                value={Range ?? "---"}
                            />
                        }
                    />
                    
                </Table.Cell>
                <Table.Cell style={{ width: "6%" }}>
                    <Dropdown
                        style={{ width: 100 }}
                        options={configurationOptions}
                        onChange={(e, d) => {
                            this.props.selectConfiguration(Index, d.value);
                        }}
                        value={Configuration ?? "---"}
                    />
                    
                </Table.Cell>
                <Table.Cell style={{ width: "15%" }}>
                    <style>
                        {`\
                        .dropdown.product .text {  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; width: 100px;float:left}\
                        
                        .dropdown.product .item .text {  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; width: auto;float:none}\

                        .dropdown.hanger-type .text {  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;    width: 68px;float:left}\
                        
                        .dropdown.hanger-type .item .text {  white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; width:auto;float:none}\

                        .level-width { width: 8% }\

                        .dropdown .menu.transition {
                            overflow: auto;
                            max-height: 180px;
                        }
                        `}
                    </style>
                    <Popup
                        content={`${getProductTitle(Product)}`}
                        trigger={
                            <Dropdown
                                //title={getProductTitle(Product)}
                                className={"product"}
                                style={{ width: "100%" }}
                                options={productOptions}
                                onChange={(e, d) => {
                                    this.props.selectProduct(Index, d.value);
                                }}
                                value={Product ?? "---"}
                                direction="left"
                            />
                        }
                    />
                    
                </Table.Cell>
                <Table.Cell style={{ width: "5%" }}>
                    {/* <Slider
                        step={1}
                        value={this.props.selectedAngle ?? 0}
                        min={0}
                        max={90}
                        index={Index}
                        onChange={this.props.selectAngle}
                        disabled={this.props.selectedEcophonSystem === Corridor} /> */}
                    <Input
                        // onKeyPress={(event: any) => {
                        //     debugge
                        //     if (!/^\d*\.?\d*$/.test(event.key)) {
                        //         event.preventDefault();
                        //     }
                        // }}
                        type="number"
                        min="0"
                        max="90"
                        onChange={(e: any) => {
                            this.props.selectAngle(Index, e.target.value);
                        }}
                        style={{ width: "100%" }}
                        //placeholder={ }
                        value={Angle ?? 0}
                    //key={this.props.selectedAngle ?? 0}
                    />
                </Table.Cell>
                <Table.Cell style={{ width: "10%" }}>
                    {/* <Slider
                        step={10}
                        value={this.props.selectedHeightUnderCeiling ?? 300}
                        min={0}
                        max={2000}
                        index={Index}
                        onChange={this.props.selectHeightUnderCeiling}
                        disabled={this.props.selectedEcophonSystem === Corridor} /> */}

                    <style>
                        {`\
                        .height-width input{
                            width:100%;
                            min-width: 70px;
                            padding:.67857143em .5em !important
                        }
                        `}
                    </style>
                    <Input
                        className="height-width"
                        type="number"
                        min="0"
                        onChange={(e: any) => {
                            this.props.selectHeightUnderCeiling(Index, e.target.value);
                        }}
                        style={{ width: "100%" }}
                        value={HeightUnderCeiling ?? 2300}
                    />
                </Table.Cell>
                <Table.Cell style={{ width: "12%" }}>
                    <Popup
                        content={`${MyHanger ?? "---"}`}
                        position="top right"
                        trigger={
                            <Dropdown
                            //title={MyHanger}
                            className="hanger-type"
                            style={{ width: "100%" }}
                            options={myhangerOptions}
                            onChange={(e, d) => {
                                this.props.selectMyHanger(Index, d.value);
                            }}
                            value={MyHanger ?? "---"}
                            direction="left"
                            />
                        }
                    />
                </Table.Cell>
            </Table.Row>
        );
    }
}

const mapStateToProps = (
    state: DrawStore,
    ownProps: { mappingRow: MappingRowEcophon; setRoute: any }
) => ({
    mappingRow: ownProps.mappingRow,
    language: state.language,
    setRoute: ownProps.setRoute,
    ecophonSystems: state.ecophonSystems,
    ranges: state.ranges,
    configurations: state.configurations,
    products: state.products,
});

const mapDispatchToProps = {
    selectSystem,
    setFireConstrain,
    setPublicConstrain,
    initProductSelector,
    setActiveMappingRowIndex,
    selectEcophonSystem,
    selectRange,
    selectConfiguration,
    selectProduct,
    selectAngle,
    selectHeightUnderCeiling,
    selectMyHanger,
    toggleCheck,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MappingRowEcophonComponent);
