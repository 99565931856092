import React, { Component } from "react";
import { Button, Dimmer, Grid, Icon, Loader, Modal } from "semantic-ui-react";
import { api } from "../../../../RevitJS/API";
import { FunctionalityHeader } from "./FunctionalityHeader";
import { SelectionStore } from "../../Selection/Reducers";
import { connect } from "react-redux";
import { dbStoreNameReperage, Routes } from "./root";
import ReactHtmlParser from "react-html-parser";
import "../Resources/reperageStyles.css";
import { StepsData } from "../../../../Components/StepsData/StepsData";
import { SaveConfigurationModal } from "./PopupModals/SaveConfigurationModal";
import { InformationModal } from "../Components/PopupModals/InformationModal";
import {
  setReparageSelectionScreen,
  setReperage,
  setReperageSelectionType,
  setRevitSetup,
} from "./../Actions";
import { bimStorage, storageKey } from "../../../../BIMStore";
import {
  loadSelections,
  multiSelectBuffer,
  setprojectData,
  setReperageSelection,
} from "../../Selection/Actions";
import { filter, flattenDeep, forEach, includes, map, omit } from "lodash";
import {
  initProjectData,
  PlacoOptions,
} from "../../../../RevitJS/Types/StoreTypes";
import { Selection } from "../../Selection/Actions/types";

interface Props {
  Icon: string;
  name: string;
  wording: {
    selections: { [key: string]: string };
    reperage: { [key: string]: string };
    defaultCustomColors: { [key: string]: string };
    genLocPlans: { [key: string]: string };
    completesteps: { [key: string]: string };
    completed: { [key: string]: string };
    notCompleted: { [key: string]: string };
    reperagelowercase: { [key: string]: string };
    saveNumConfig: { [key: string]: string };
    yes: { [key: string]: string };
    no: { [key: string]: string };
    information: { [key: string]: string };
    systemsNotAvailable: { [key: string]: string };
    ok: { [key: string]: string };
    removeFromTrackingConfig: { [key: string]: string };
    title: { [key: string]: string };
    paretage: { [key: string]: string };
    parzone: { [key: string]: string };
    revitSetup: { [key: string]: string };
  };
  language: string;
  setRoute: any;
  setReparageSelectionScreen: any;
  reperageData: any;
  selectionDimmer: boolean;
  setReperageSelectionType: any;
  setReperageSelection: any;
  setReperage: any;
  setRevitSetup: any;
  multiSelectBuffer: any;
  showWarning: boolean;
  selections: any;
  projectData: initProjectData;
  setprojectData: any;
  config: any;
  loadSelections: any;
}

export class ReperageLanding extends Component<Props> {
  state = {
    objectSelected: false,
    // reperageSelected: false,
    saveModal: false,
    showinfo: false,
    selectionDimmer: false,
    isSelectionCompleted: this.props.reperageData.buffer.selections.status,
    reperageSelected: this.props.reperageData.buffer.reperage.status,
    showWarning: false,
    removedSelections: [],
    addedSelections: [],
    loading: false,
    progress: 0,
    total: 0,
    selectionChanged: false,
    previousLevelView: undefined,
    isRevitSetupCompleted: false,
    isReperageSuccessful: false,
  };

  public static defaultProps = {
    wording: require("../Resources/wording.json"),
  };

  componentDidMount = async () => {
    var localValue = localStorage.getItem("valueChanged");
    var localValueDelete = localStorage.getItem("valueDelete");
    //api.windowsHandler.resizeWindow(900, 500);
    //await bimStorage.setItem(storageKey.REPERAGE, JSON.stringify([]));
    let data = await bimStorage.getItem(storageKey.PLACOSELECTIONS);
    let placoSolutions: {} = data === null ? "" : data;
    let selections;
    if (!(Object.keys(this.props.selections).length > 0)) {
      if (placoSolutions) {
        selections = placoSolutions as {
          [key: string]: Selection<PlacoOptions>;
        };
        this.props.loadSelections(selections);
      }
    }

    this.state.objectSelected = placoSolutions ? true : false;

    //let reperageData1 = await bimStorage.getItem(storageKey.REPERAGE);

    if (this.props.projectData.ProjectId === "") {
      await this.fetchProjectId();
    }

    let strReperageData = await bimStorage.listDetailModule(
      dbStoreNameReperage,
      `${this.props.projectData.ProjectId}` + "_c61281f0-c270"
      //"c61281f0-c270-4e02-9e7a-7bb2537986ee"
    );

    if (strReperageData !== "") {
      let reperageData = JSON.parse(strReperageData);
      if (reperageData) {
        if (
          reperageData.selections &&
          reperageData.selections.list.length > 0
        ) {
          let selectionExists = await this.selectionExists(
            reperageData.selections,
            selections
          );
          if (selectionExists) {
            this.props.setReperageSelection(reperageData.selections.list);
            this.setState({
              isSelectionCompleted: reperageData.selections.status,
            });
          }
        }
        if (reperageData.reperage && reperageData.reperage.list.length > 0) {
          const {
            isSelectionRemoved,
            isSelectionAdded,
            isWallsChanged,
            isSolutionChanged,
          } = await this.updatedSelection(reperageData);
          if (localValueDelete !== "true") {
            if (isSelectionRemoved) {
              this.setState({ showWarning: true });
            }
          }
          if (localValue !== "true") {
            if (isSelectionAdded || isWallsChanged || isSolutionChanged) {
              this.setState({ selectionChanged: true });
            }
          }

          if (
            !isSelectionRemoved &&
            !isSelectionAdded &&
            !isWallsChanged &&
            !isSolutionChanged
          ) {
            this.props.setReperage({
              status: reperageData.reperage.status,
              list: reperageData.reperage.list,
              version: reperageData.reperage.version,
            });

            this.setState({ reperageSelected: reperageData.reperage.status });

            if (
              reperageData.reperage.status &&
              reperageData.revitSetup &&
              reperageData.revitSetup.list.length > 0
            ) {
              this.props.setRevitSetup({
                status: reperageData.revitSetup.status,
                list: reperageData.revitSetup.list,
                version: reperageData.revitSetup.version,
                saveExistingReperage:
                  reperageData.revitSetup.saveExistingReperage,
              });

              this.setState({
                isRevitSetupCompleted: reperageData.revitSetup.status,
              });
            } else {
              this.props.setRevitSetup({
                status: false,
                list: [],
                version: 1,
                saveExistingReperage: false,
              });

              this.setState({ isRevitSetupCompleted: false });
            }
          }
        }
      }
    }
    if (localStorage.getItem("min") === "0:0") {
      localStorage.removeItem("min");
    }
    if (localStorage.getItem("reperageDefault")) {
      localStorage.removeItem("reperageDefault");
    }
  };

  fetchProjectId = async () => {
    if (this.props.projectData.ProjectId === "") {
      let projectData: initProjectData = await api.queries.getSetProjectData();

      if (projectData.ProjectId !== "") {
        this.props.setprojectData(projectData);
      }
    }
  };

  updatedSelection = async (reperageSelection: any) => {
    let updatedSelection = await this.groupWallsDataFromSelection(
      reperageSelection
    );
    let savedSelection = reperageSelection.reperage.list;

    let updatedSelectionWallTypes = map(updatedSelection, (n) => {
      return n.WallType;
    });

    let existingSelectionWallTypes = map(savedSelection, (n) => {
      return { wallType: n.WallType, ids: n.Ids };
    });

    let selectionRemoved = filter(savedSelection, function (o) {
      return !includes(updatedSelectionWallTypes, o.WallType);
    });

    function arrayEquals(a: any, b: any) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    }

    let selectionAdded = filter(updatedSelection, function (o) {
      let selection = existingSelectionWallTypes.filter(
        (s: any) => s.wallType === o.WallType && arrayEquals(s.ids, o.Ids[0])
      );
      if (selection && selection.length > 0) {
        return false;
      } else {
        return true;
      }
      //return !includes(selection[0].wallType, o.WallType);
    });

    let updatedSolutions = map(updatedSelection, (n) => {
      return { walltype: n.WallType, solution: n.PlacoSolution };
    });

    let existingSolutions = map(savedSelection, (n) => {
      return { walltype: n.WallType, solution: n.PlacoSolution };
    });

    let solutionsChanged = filter(updatedSolutions, function (o) {
      let selection = existingSolutions.filter(
        (s: any) => s.walltype === o.walltype
      );
      if (selection && selection.length > 1) {
        let zoneSelection = selection.filter(
          (s: any) =>
            s.WallType === o.walltype && s.PlacoSolution === o.solution
        );

        if (zoneSelection && zoneSelection.length > 0) {
          return !(zoneSelection[0].solution === o.solution);
        }
        // return !includes(solutions, o.solution);
      } else if (selection && selection.length > 0) {
        return !(selection[0].solution === o.solution);
      }
    });

    let wallChanged = filter(savedSelection, function (o) {
      let selection = updatedSelection.filter(
        (s: any) => s.WallType === o.WallType
      );
      if (selection && selection.length > 1) {
        let zoneSelection = selection.filter(
          (s: any) =>
            s.WallType === o.WallType &&
            s.PlacoSolution === o.PlacoSolution &&
            arrayEquals(s.Ids[0], o.Ids)
        );

        if (zoneSelection && zoneSelection.length > 0) {
          return false;
        } else {
          return true;
        }

        //return !arrayEquals(selection[0].Ids[0], o.Ids);
      } else if (selection && selection.length > 0) {
        return !arrayEquals(selection[0].Ids[0], o.Ids);
      }
    });

    this.setState({
      removedSelections: selectionRemoved,
      addedSelections: selectionAdded,
    });

    let isSelectionRemoved = selectionRemoved.length > 0;
    let isSelectionAdded = selectionAdded.length > 0;
    let isWallsChanged = wallChanged.length > 0;
    let isSolutionChanged = solutionsChanged.length > 0;

    return {
      isSelectionRemoved,
      isSelectionAdded,
      isWallsChanged,
      isSolutionChanged,
    };
  };

  selectionExists = async (selection: any, selections: any) => {
    let selectionIds = map(selection.list, (n) => {
      return n.Id;
    });

    /// Extract selection list based on ids
    //let selectionList = filter(this.props.selections, function (o) {
    let selectionList = filter(selections, function (o) {
      return includes(selectionIds, o.Id);
    });

    return selectionList && selectionList.length > 0;
  };

  /// Extract selection from selection module
  /// collect wall data from revit
  /// collect placo solutions from api
  groupWallsDataFromSelection = async (reperageSelection: any) => {
    /// Extract selection ids
    let selectionIds = map(
      //this.props.reperageData.buffer.selections.list,
      reperageSelection.selections.list,
      (n) => {
        return n.Id;
      }
    );

    /// Extract selection list based on ids
    let selectionList = filter(this.props.selections, function (o) {
      return includes(selectionIds, o.Id);
    });

    /// 1. Extract selected wall ids from selection.
    let wallRows = flattenDeep(
      map(selectionList, (ele) => {
        return map(ele.SelectionByType.wall.Rows, (row) => {
          return {
            ...row,
            zone: ele.Zone ? ele.Name : "",
            color: ele.Color,
          };
        });
      })
    );

    let ceilingRows = flattenDeep(
      map(selectionList, (ele) => {
        return map(ele.SelectionByType.ceiling.Rows, (row) => {
          return {
            ...row,
            zone: ele.Zone ? ele.Name : "",
            color: ele.Color,
          };
        });
      })
    );

    let grpWallsCeilingsData: any[] = [];

    forEach(wallRows, (value: any, index: number) => {
      let productName: any = value.Options.MappedSystem.attributes?.find((a: any) => a.technicalName === "A-Solution product name")?.values[0]?.value;
      grpWallsCeilingsData.push({
        PlacoSolution: productName
        ? productName
        : value.Options.MappedSystem.translation,
        PlacoSolutionId: value.Options.MappedSystem.oid,
        PlacoSolutionHeight:
          value.Options.MappedSystem["GFR-Height limit in m"],
        Zone: value.zone,
        WallType: value.RevitSelection.RevitType,
        Ids: [value.RevitSelection.Ids],
      });
    });

    forEach(ceilingRows, (value: any, index: number) => {
      let productName: any = value.Options.MappedSystem.attributes?.find((a: any) => a.technicalName === "A-Solution product name")?.values[0]?.value;
      grpWallsCeilingsData.push({
        PlacoSolution: productName
          ? productName
          : value.Options.MappedSystem.translation,
        PlacoSolutionId: value.Options.MappedSystem.oid,
        PlacoSolutionHeight:
          value.Options.MappedSystem["GFR-Height limit in m"],
        Zone: value.zone,
        WallType: value.RevitSelection.RevitType,
        Ids: [value.RevitSelection.Ids],
      });
    });

    return grpWallsCeilingsData;
  };

  onClickHandler = async () => {
    localStorage.setItem("reperageDefault", "true");
    let personaliseColor = await bimStorage.getItem(
      storageKey.REPERAGE_PERSONALISE_COLOR
    );
    if (personaliseColor) {
      this.props.multiSelectBuffer(personaliseColor);
    }

    this.props.setRoute(Routes.PERSONALISECOLOR_TABLE);
  };

  actionHandler = (action: any) => {
    this.props.setRoute(action);
  };

  toggleModal = (modalName: string, action: boolean) => {
    this.setState({ [modalName]: action });
  };

  openSelection = () => {
    this.props.setReparageSelectionScreen(true);
    this.actionHandler(Routes.SELECTION_HOME);
    localStorage.setItem("isModification", "true");
  };

  toggleDimmer = (action: any) => {
    this.setState({
      selectionDimmer: action,
    });
  };

  manualSelectionHandler = async () => {
    this.props.setReperageSelectionType(false);
    this.actionHandler(Routes.REPERAGE_SELECTION);
  };

  groupSelectionHandler = async () => {
    this.props.setReperageSelectionType(true);
    this.actionHandler(Routes.REPERAGE_SELECTION);
  };

  createAndSetSystemParameter = async () => {
    await api.familyEditor.createAndSetParameters("Wall", "Type", "DATA", [
      {
        Id: "0",
        Params: [{ Name: "Système", Type: "Text", Value: "" }],
      },
    ]);

    await api.familyEditor.createAndSetParameters("Ceiling", "Type", "DATA", [
      {
        Id: "0",
        Params: [{ Name: "Système", Type: "Text", Value: "" }],
      },
    ]);
  };

  setTypeImageParameter = async () => {
    var wallsData = this.props.reperageData.buffer.reperage.list;
    wallsData = wallsData.filter((w: any) => w.chk === true);
    const clearedWallsData = map(wallsData, (wld, ind) =>
      omit(wld, ["montantArray", "plaqueArray"])
    );

    let ParamsData: any = [];
    forEach(clearedWallsData, (value: any, index: number) => {
      let rgb = `${value.color.r},${value.color.g},${value.color.b}`;
      ParamsData.push({
        Name: value.WallType,
        Type: value.type,
        Value: rgb,
        TypeSolution: value.PlacoSolution,
      });
    });

    let Parameters = [
      {
        Id: "0",
        Params: ParamsData,
      },
    ];

    await api.queries.setScheduleImages(Parameters);
  };

  getSelectionName = () => {
    let selectionIds = map(
      this.props.reperageData.buffer.selections.list,
      (n) => {
        return n.Id;
      }
    );

    /// Extract selection list based on ids
    let selectionList = filter(this.props.selections, function (o) {
      return includes(selectionIds, o.Id);
    });

    return selectionList && selectionList.length > 0
      ? selectionList[0].Name
      : "";
  };

  btnClick = async () => {
    this.setState({ selectionChanged: false });
    localStorage.setItem("valueChanged", "true");
  };

  genrateReperagePlans = async () => {
    try {
      this.setState({ loading: true });
      this.setState({ progress: 0 });
      this.setState({ previousLevelView: undefined });
      await this.createAndSetSystemParameter();
      await this.setTypeImageParameter();

      let reperageRows = this.props.reperageData.buffer.reperage.list;
      let revitSetupRows = this.props.reperageData.buffer.revitSetup.list;

      let wallIds: any[] = [];
      let wallIdsWithColor: any[] = [];
      let wallsRows = reperageRows.filter(
        (w: any) => w.chk === true && (w.type === "Mur" || w.type === "Wall")
      );
      wallsRows.forEach((value: any, index: any) => {
        wallIds = wallIds.concat(value.Ids);
        let element = { ids: value.Ids, color: value.color };
        wallIdsWithColor = [...wallIdsWithColor, element];
      });

      let ceilingIds: any[] = [];
      let ceilingIdsWithColor: any[] = [];
      let ceilingRows = reperageRows.filter(
        (w: any) =>
          w.chk === true && (w.type === "Plafond" || w.type === "Ceiling")
      );
      ceilingRows.forEach((value: any, index: any) => {
        ceilingIds = ceilingIds.concat(value.Ids);
        let element = { ids: value.Ids, color: value.color };
        ceilingIdsWithColor = [...ceilingIdsWithColor, element];
      });

      let finalWallDetails = await api.queries.getWallsData(wallIds);
      let finalCeilingDetails = await api.queries.getCeilingsData(ceilingIds);

      let floorLevelsWalls = finalWallDetails
        .map((wall: any) => wall.LevelName)
        .filter((value, index, self) => self.indexOf(value) === index);

      let floorLevelsCeilings = finalCeilingDetails
        .map((ceiling: any) => ceiling.LevelName)
        .filter((value, index, self) => self.indexOf(value) === index);

      let floorLevels = [
        ...new Set([...floorLevelsWalls, ...floorLevelsCeilings]),
      ];

      let threeDViews: any[] = revitSetupRows.filter(
        (r: any) => r.type === "threeD"
      );
      if (threeDViews && threeDViews.length > 0) {
        let total =
          finalWallDetails.length * (threeDViews.length + 1) +
          finalCeilingDetails.length * (threeDViews.length + 1) +
          2;
        this.setState({
          total: total,
        });
      } else {
        this.setState({
          total: finalWallDetails.length + finalCeilingDetails.length + 2,
        });
      }

      await api.queries.duplicateViewType(
        "Plan d'étage",
        "Plan de Repérage Murs - PLACO(r)BIM",
        "floor"
      );
      await api.queries.duplicateViewType(
        "Plan du plafond",
        "Plan de Repérage Plafonds - PLACO(r)BIM",
        "ceiling"
      );

      await api.queries.duplicateViewType(
        "Vue 3D",
        "Plan de Repérage 3D - PLACO(r)BIM",
        "threeD"
      );

      this.setState({ progress: 1 });
      let wallElements: any[] = [];
      let ceilingElements: any[] = [];

      for (const level of floorLevels) {
        let floorWiseWalls = finalWallDetails.filter((wall: any) => {
          if (wall.LevelName === level) return true;
        });

        let floorWiseCeilings = finalCeilingDetails.filter((ceiling: any) => {
          if (ceiling.LevelName === level) return true;
        });

        let floorWiseWallIds = floorWiseWalls.map((w: any) => w.Id);
        let floorWiseCeilingIds = floorWiseCeilings.map((c: any) => c.Id);
        let IdsWithColorWalls: any[] = [];
        let IdsWithColorCeilings: any[] = [];

        floorWiseWallIds.forEach((id) => {
          let wall = wallIdsWithColor.filter((wall: any) => {
            if (wall.ids.includes(id)) return true;
          });

          if (wall && wall.length > 0) {
            let selectedWall = IdsWithColorWalls.filter((w: any) => {
              if (
                w.color.r === wall[0].color.r &&
                w.color.g === wall[0].color.g &&
                w.color.b === wall[0].color.b
              )
                return true;
            });

            if (selectedWall && selectedWall.length > 0) {
              selectedWall[0].ids.push(id);
            } else {
              let element = { ids: [id], color: wall[0].color };
              IdsWithColorWalls = [...IdsWithColorWalls, element];
            }
          }
        });

        floorWiseCeilingIds.forEach((id) => {
          let ceiling = ceilingIdsWithColor.filter((ceiling: any) => {
            if (ceiling.ids.includes(id)) return true;
          });

          if (ceiling && ceiling.length > 0) {
            let selectedCeiling = IdsWithColorCeilings.filter((c: any) => {
              if (
                c.color.r === ceiling[0].color.r &&
                c.color.g === ceiling[0].color.g &&
                c.color.b === ceiling[0].color.b
              )
                return true;
            });

            if (selectedCeiling && selectedCeiling.length > 0) {
              selectedCeiling[0].ids.push(id);
            } else {
              let element = { ids: [id], color: ceiling[0].color };
              IdsWithColorCeilings = [...IdsWithColorCeilings, element];
            }
          }
        });

        if (IdsWithColorWalls.length > 0) {
          wallElements = wallElements.concat(IdsWithColorWalls);
          let floorPlanNames = revitSetupRows.filter(
            (r: any) => r.LevelName === level && r.type === "floor"
          );

          if (floorPlanNames && floorPlanNames.length > 0) {
            for (const floorPlan of floorPlanNames) {
              const nameTobe = await this.getReperageViewName(
                floorPlan.ViewName,
                "floor"
              );

              let existingViewByName = await api.queries.getViewByNameAndType(
                floorPlan.ViewName,
                "floor"
              );
              await api.queries.setActiveView(existingViewByName);

              if (this.state.previousLevelView) {
                let floorDetail: any = this.state.previousLevelView;

                let viewId = await api.queries.getViewByNameAndType(
                  floorDetail.viewName,
                  floorDetail.type
                );
                let reperageViewId = await api.queries.getViewByNameAndType(
                  floorDetail.reperageViewName,
                  floorDetail.type
                );

                await api.queries.closeView(viewId);
                await api.queries.closeView(reperageViewId);
                this.setState({ previousLevelView: undefined });
              }

              let viewDetail = {
                viewName: floorPlan.ViewName,
                reperageViewName: nameTobe,
                type: "floor",
              };
              this.setState({ previousLevelView: viewDetail });

              let viewByName = await api.queries.getViewByName(nameTobe);

              if (viewByName) {
                await api.queries.deleteView(viewByName);
              }

              let newlyCreatedView =
                await api.queries.duplicateActiveViewInNewViewType(
                  "Plan de Repérage Murs - PLACO(r)BIM",
                  nameTobe
                );
              await api.queries.setActiveView(newlyCreatedView);

              let activeViewObject = await api.queries.getActiveViewDetails();

              if (activeViewObject.Name === nameTobe) {
                let count = 0;
                for (let i = 0; i < IdsWithColorWalls.length; i++) {
                  let element = IdsWithColorWalls[i];
                  let rgbColor = {
                    Red: element.color.r,
                    Green: element.color.g,
                    Blue: element.color.b,
                  };

                  let filter = await api.queries.createSelectionFilterForEye(
                    nameTobe + "_" + count,
                    element.ids
                  );
                  count = count + 1;

                  await api.viewHandler.setFilterColor(filter, rgbColor);

                  let progress = this.state.progress + element.ids.length;
                  this.setState({ progress: progress });
                }
              }
            }
          }
        }

        if (IdsWithColorCeilings.length > 0) {
          ceilingElements = ceilingElements.concat(IdsWithColorCeilings);
          let ceilingPlanNames = revitSetupRows.filter(
            (r: any) => r.LevelName === level && r.type === "ceiling"
          );

          if (ceilingPlanNames && ceilingPlanNames.length > 0) {
            for (const ceilingPlan of ceilingPlanNames) {
              const nameTobe = await this.getReperageViewName(
                ceilingPlan.ViewName,
                "ceiling"
              );

              let existingViewByName = await api.queries.getViewByNameAndType(
                ceilingPlan.ViewName,
                "ceiling"
              );
              await api.queries.setActiveView(existingViewByName);

              if (this.state.previousLevelView) {
                let floorDetail: any = this.state.previousLevelView;

                let viewId = await api.queries.getViewByNameAndType(
                  floorDetail.viewName,
                  floorDetail.type
                );
                let reperageViewId = await api.queries.getViewByNameAndType(
                  floorDetail.reperageViewName,
                  floorDetail.type
                );

                await api.queries.closeView(viewId);
                await api.queries.closeView(reperageViewId);
                this.setState({ previousLevelView: undefined });
              }

              let viewDetail = {
                viewName: ceilingPlan.ViewName,
                reperageViewName: nameTobe,
                type: "ceiling",
              };
              this.setState({ previousLevelView: viewDetail });

              let viewByName = await api.queries.getViewByName(nameTobe);

              if (viewByName) {
                await api.queries.deleteView(viewByName);
              }

              let newlyCreatedView =
                await api.queries.duplicateActiveViewInNewViewType(
                  "Plan de Repérage Plafonds - PLACO(r)BIM",
                  nameTobe
                );
              await api.queries.setActiveView(newlyCreatedView);

              let activeViewObject = await api.queries.getActiveViewDetails();

              if (activeViewObject.Name === nameTobe) {
                let count = 0;
                for (let i = 0; i < IdsWithColorCeilings.length; i++) {
                  let element = IdsWithColorCeilings[i];
                  let rgbColor = {
                    Red: element.color.r,
                    Green: element.color.g,
                    Blue: element.color.b,
                  };

                  let filter = await api.queries.createSelectionFilterForEye(
                    nameTobe + "_" + count,
                    element.ids
                  );
                  count = count + 1;

                  await api.viewHandler.setFilterColor(filter, rgbColor);

                  let progress = this.state.progress + element.ids.length;
                  this.setState({ progress: progress });
                }
              }
            }
          }
        }
      }

      //3D
      //let threeDViews: any[] = revitSetupRows.filter((r: any) => r.type === "threeD")
      if (threeDViews && threeDViews.length > 0) {
        if (wallElements.length > 0 || ceilingElements.length > 0) {
          //for (const threeDView of threeDViews) {
          for (let i = 0; i < threeDViews.length; i++) {
            let threeDView = threeDViews[i];
            const nameTobe = await this.getReperageViewName(
              threeDView.ViewName,
              "threeD"
            );
            let existingViewByName = await api.queries.getViewByNameAndType(
              threeDView.ViewName,
              "threeD"
            );

            await api.queries.setActiveView(existingViewByName);

            if (this.state.previousLevelView) {
              let threeDDetail: any = this.state.previousLevelView;

              let viewId = await api.queries.getViewByNameAndType(
                threeDDetail.viewName,
                threeDDetail.type
              );
              let reperageViewId = await api.queries.getViewByNameAndType(
                threeDDetail.reperageViewName,
                threeDDetail.type
              );

              await api.queries.closeView(viewId);
              await api.queries.closeView(reperageViewId);
              this.setState({ previousLevelView: undefined });
            }

            let viewDetail = {
              viewName: threeDView.ViewName,
              reperageViewName: nameTobe,
              type: "threeD",
            };
            this.setState({ previousLevelView: viewDetail });

            let viewByName = await api.queries.getViewByName(nameTobe);

            if (viewByName) {
              await api.queries.deleteView(viewByName);
            }

            let newlyCreatedView =
              await api.queries.duplicateActiveViewInNewViewType(
                "Plan de Repérage 3D - PLACO(r)BIM",
                nameTobe
              );
            await api.queries.setActiveView(newlyCreatedView);

            let activeViewObject = await api.queries.getActiveViewDetails();

            if (activeViewObject.Name === nameTobe) {
              let count = 0;
              for (let i = 0; i < wallElements.length; i++) {
                let element = wallElements[i];
                let rgbColor = {
                  Red: element.color.r,
                  Green: element.color.g,
                  Blue: element.color.b,
                };

                let filter = await api.queries.createSelectionFilterForEye(
                  nameTobe + "_" + count,
                  element.ids
                );
                count = count + 1;

                //await api.viewHandler.setFilterColor(filter, rgbColor);
                const plasterboardIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Plaque",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );

                const simpleFramesIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Ossature",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );
                const doubleFramesIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Ossature-Double",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );

                const bottomRailsIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Rail-bas",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );
                const upperRailsIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Rail-haut",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );

                let framesIds = simpleFramesIds.concat(doubleFramesIds);
                const railsIds = bottomRailsIds.concat(upperRailsIds);

                let PlasterboardsFilter =
                  await api.queries.createSelectionFilterForEye(
                    nameTobe + "_" + count + "_Plasterboards",
                    plasterboardIds
                  );

                let FramesFilter =
                  await api.queries.createSelectionFilterForEye(
                    nameTobe + "_" + count + "_Frames",
                    framesIds
                  );

                let RailsFilter = await api.queries.createSelectionFilterForEye(
                  nameTobe + "_" + count + "_Rails",
                  railsIds
                );

                await api.viewHandler.setFilterColor(filter, rgbColor);
                if (plasterboardIds.length > 0) {
                  await api.viewHandler.setFilterColor(
                    PlasterboardsFilter,
                    rgbColor
                  );
                }

                if (framesIds.length > 0) {
                  await api.viewHandler.setFilterColor(FramesFilter, rgbColor);
                }

                if (railsIds.length > 0) {
                  await api.viewHandler.setFilterColor(RailsFilter, rgbColor);
                }

                let progress = this.state.progress + element.ids.length;
                this.setState({ progress: progress });
              }

              //count = 0;
              for (let i = 0; i < ceilingElements.length; i++) {
                let element = ceilingElements[i];
                let rgbColor = {
                  Red: element.color.r,
                  Green: element.color.g,
                  Blue: element.color.b,
                };

                let filter = await api.queries.createSelectionFilterForEye(
                  nameTobe + "_" + count,
                  element.ids
                );
                count = count + 1;

                //await api.viewHandler.setFilterColor(filter, rgbColor);
                const plasterboardIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Plaque",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );

                const simpleFramesIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Ossature",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );
                const doubleFramesIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Ossature-Double",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );

                const bottomRailsIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Rail-bas",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );
                const upperRailsIds = await api.queries.filterElements(
                  "Generic",
                  [
                    {
                      Param: {
                        Name: "Name",
                        Type: "Builtin",
                        Value: "Placo_Rail-haut",
                      },
                      Rule: "Equals",
                    },
                    {
                      Param: {
                        Name: "id",
                        Type: "Integer",
                        Value: element.ids,
                      },
                      Rule: "Includes",
                    },
                  ],
                  null
                );

                let framesIds = simpleFramesIds.concat(doubleFramesIds);
                const railsIds = bottomRailsIds.concat(upperRailsIds);

                let PlasterboardsFilter =
                  await api.queries.createSelectionFilterForEye(
                    nameTobe + "_" + count + "_Plasterboards",
                    plasterboardIds
                  );

                let FramesFilter =
                  await api.queries.createSelectionFilterForEye(
                    nameTobe + "_" + count + "_Frames",
                    framesIds
                  );

                let RailsFilter = await api.queries.createSelectionFilterForEye(
                  nameTobe + "_" + count + "_Rails",
                  railsIds
                );

                await api.viewHandler.setFilterColor(filter, rgbColor);
                if (plasterboardIds.length > 0) {
                  await api.viewHandler.setFilterColor(
                    PlasterboardsFilter,
                    rgbColor
                  );
                }

                if (framesIds.length > 0) {
                  await api.viewHandler.setFilterColor(FramesFilter, rgbColor);
                }

                if (railsIds.length > 0) {
                  await api.viewHandler.setFilterColor(RailsFilter, rgbColor);
                }

                let progress = this.state.progress + element.ids.length;
                this.setState({ progress: progress });
              }
            }
          }
        }
      }

      await api.queries.deleteSchedule("Repérage de murs");
      await api.queries.deleteSchedule("Repérage de plafonds");

      if (finalWallDetails.length > 0) {
        await api.queries.createSchedule("wall", "Repérage de murs");
      }

      if (finalCeilingDetails.length > 0) {
        await api.queries.createSchedule("ceiling", "Repérage de plafonds");
      }

      let progress = this.state.progress + 1;
      this.setState({ progress: progress });

      this.setState({ loading: false });
      this.setState({ isReperageSuccessful: true });

      localStorage.setItem("isModification", "false");

      api.eventLog.SetEvent({
        data: [
          {
            name: "",
            value: "",
            values: [],
          },
        ],
        eventAction: "Generate",
        eventCategory: "Module Execution",
        eventLabel: "Repérage",
        module: "PLACOBIM",
      });
      // }
    } catch (e) {
      console.log("Error in Reperage generate plans!!", e);
    }
  };

  getReperageViewName = async (existingViewName: string, viewType: string) => {
    let nameTobe: string = "";
    let existingName: string;
    if (viewType === "floor") {
      nameTobe = (existingViewName + "_plans_de_repérage_mur").replace(
        /[&\\/\\#,+()$~%.'":*?<>{}]/g,
        ""
      );
    } else if (viewType === "ceiling") {
      nameTobe = (existingViewName + "_plans_de_repérage_plafond").replace(
        /[&\\/\\#,+()$~%.'":*?<>{}]/g,
        ""
      );
    } else if (viewType === "threeD") {
      nameTobe = (existingViewName + "_plans_de_repérage_3D").replace(
        /[&\\/\\#,+()$~%.'":*?<>{}]/g,
        ""
      );
    }

    existingName = nameTobe;

    if (this.props.reperageData.buffer.revitSetup.saveExistingReperage) {
      for (let i = 1; i < 1000; i++) {
        let viewByName = await api.queries.getViewByName(nameTobe);

        if (viewByName) {
          nameTobe = existingName + "_" + i;
        } else {
          break;
        }
      }
    } else {
      return nameTobe;
    }

    return nameTobe;
  };

  render() {
    let steps = [
      {
        name: ReactHtmlParser(
          this.props.wording.selections[this.props.language]
        ),
        action: () => this.toggleDimmer(true),
        isActionCompleted: this.state.isSelectionCompleted,
      },
      {
        name: ReactHtmlParser(
          this.props.wording.reperagelowercase[this.props.language]
        ),
        action: () => this.actionHandler(Routes.COLORISATION_TABLE),
        isActionCompleted: this.state.reperageSelected,
        isDisabled: !this.state.isSelectionCompleted,
      },
      {
        name: ReactHtmlParser(
          this.props.wording.revitSetup[this.props.language]
        ),
        action: () => this.actionHandler(Routes.REVIT_SETUP),
        isActionCompleted: this.state.isRevitSetupCompleted,
        isDisabled: !this.state.reperageSelected,
      },
    ];

    let totalStepsCompleted = 0;
    steps.forEach((step) => {
      if (step.isActionCompleted) totalStepsCompleted++;
    });

    const content = () => {
      return (
        "Repérage en cours... " +
        `${
          this.state.total
            ? Math.round((this.state.progress / this.state.total) * 100) > 100
              ? 100
              : Math.round((this.state.progress / this.state.total) * 100)
            : 0
        }%`
      );
    };

    if (this.state.loading) {
      return (
        <Dimmer active={true} style={{ height: "calc(100vh - 30px)" }}>
          <Loader content={content()}></Loader>
        </Dimmer>
      );
    }

    return (
      <div>
        {/*a warning message will appear asking the user to save or not the configuration if user close window. */}
        <SaveConfigurationModal
          saveModal={this.state.saveModal}
          toggleloader={() => this.toggleModal("saveModal", true)}
          closeDimmer={() => this.toggleModal("saveModal", false)}
          saveNumConfig={this.props.wording.saveNumConfig[this.props.language]}
          yes={this.props.wording.yes[this.props.language]}
          no={this.props.wording.no[this.props.language]}
        />
        {/* If user revisit a saved configuration but there were changes in the model, where Revit systems were deleted from the chosen selection(s), show the warning message, and remove those systems from the configuration */}
        <InformationModal
          showinfo={this.state.showinfo}
          showInfoModal={() => this.toggleModal("showinfo", true)}
          closeDimmer={() => this.toggleModal("showinfo", true)}
          information={this.props.wording.information[this.props.language]}
          systemsNotAvailable={
            this.props.wording.systemsNotAvailable[this.props.language]
          }
          removeFromTrackingConfig={
            this.props.wording.removeFromTrackingConfig[this.props.language]
          }
          ok={this.props.wording.ok[this.props.language]}
        />
        <FunctionalityHeader
          Icon={this.props.Icon}
          name={this.props.wording.reperage[this.props.language]}
          subheader=""
        />
        <div className="header-alignment" style={{ marginTop: "10px" }}>
          {totalStepsCompleted +
            "/" +
            steps.length +
            " " +
            ReactHtmlParser(
              this.props.wording.completesteps[this.props.language]
            )}
        </div>
        <div
          className="grid-container-reperage"
          style={{ height: "calc(100vh - 30vh)", margin: "10px" }}
        >
          <Grid className="margin-top-10">
            <Grid.Row>
              <Grid.Column style={{ margin: "15px 0px 65px 0px" }}>
                <Button
                  style={{
                    width: "max-content",
                    height: "45px",
                    fontSize: "16px",
                    margin: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    msTransform: "translate(-50%, -50%)",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={() => this.onClickHandler()}
                  primary
                  size="medium"
                  content={ReactHtmlParser(
                    this.props.wording.defaultCustomColors[this.props.language]
                  )}
                  fluid
                ></Button>
              </Grid.Column>
            </Grid.Row>
            <StepsData data={steps} {...this.props} />
          </Grid>
        </div>
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={() => this.genrateReperagePlans()}
                primary
                size="medium"
                style={{
                  width: "max-content",
                  margin: "5px 0px",
                }}
                disabled={!this.state.isRevitSetupCompleted}
                content={ReactHtmlParser(
                  this.props.wording.genLocPlans[this.props.language]
                )}
              ></Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal
          onClose={() => this.toggleDimmer(false)}
          open={this.state.selectionDimmer}
          size="small"
          dimmer="blurring"
        >
          <Modal.Header
            className="modalHeader"
            style={{ padding: "7px 1.5rem", textAlign: "right" }}
          >
            <Icon
              name="close"
              size="large"
              inverted
              style={{ cursor: "pointer" }}
              onClick={() => this.toggleDimmer(false)}
            />
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <FunctionalityHeader
                    Icon={this.props.Icon}
                    name={this.props.wording.title[this.props.language]}
                    subheader=""
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginBottom: "25px" }}>
                <Grid.Column width={8}>
                  <Button
                    onClick={this.manualSelectionHandler}
                    primary
                    size="big"
                    floated="right"
                    className="selectionBtn"
                  >
                    {ReactHtmlParser(
                      this.props.wording.paretage[this.props.language]
                    )}
                  </Button>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    onClick={this.groupSelectionHandler}
                    primary
                    size="big"
                    floated="left"
                    className="selectionBtn"
                  >
                    {ReactHtmlParser(
                      this.props.wording.parzone[this.props.language]
                    )}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>

        <Modal size="tiny" open={this.state.showWarning} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p style={{ textAlign: "left" }}>
              Les systèmes suivants ne sont plus disponibles dans vos sélections
              :{" "}
            </p>
            <ul
              style={{
                fontWeight: 600,
                textAlign: "left",
                paddingLeft: "15px",
              }}
            >
              {map(
                this.state.removedSelections,
                (selection: any, index: any) => {
                  return <li>{selection.PlacoSolution}</li>;
                }
              )}
            </ul>
            <p
              style={{
                textAlign: "left",
                marginBottom: "0px",
                fontSize: "13px",
              }}
            >
              Ils seront supprimées de cette configuration de repérage.{" "}
            </p>
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Pour mettre à jour votre repérage, cliquer sur "Générer les plans
              de repérage"{" "}
            </p>
            <Button
              primary
              onClick={() => {
                localStorage.setItem("valueDelete", "true");
                delete this.props.reperageData.isSelectionUpdated;
                this.setState({ showWarning: false });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <Modal size="tiny" open={this.state.selectionChanged} dimmer="blurring">
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <p>
              Nous avons détecté quelques modifications apportées à votre
              sélection. Veuillez vérifier vos paramétrage de repérage.{" "}
            </p>
            <Button primary onClick={this.btnClick}>
              Continuer
            </Button>
          </Modal.Description>
        </Modal>

        <Modal
          size="tiny"
          open={this.state.isReperageSuccessful}
          dimmer="blurring"
        >
          <Modal.Description
            style={{
              textAlign: "center",
              padding: "2rem 1.6rem 1.6rem 1.6rem",
            }}
          >
            <h3 style={{ color: "rgb(33, 133, 208)" }}>
              Information - Repérage
            </h3>
            <p>
              Génération des plans de cloisons/plafonds terminée avec succès.{" "}
            </p>
            <Button
              primary
              onClick={() => {
                this.setState({ isReperageSuccessful: false });
              }}
            >
              Continuer
            </Button>
          </Modal.Description>
        </Modal>
      </div>
    );
  }
}

const mapDispatch = {
  setReparageSelectionScreen,
  setReperageSelectionType,
  setReperageSelection,
  setReperage,
  setRevitSetup,
  multiSelectBuffer,
  setprojectData,
  loadSelections,
};

const mapStateToProps = (state: SelectionStore, ownProps: any) => {
  return {
    Icon: state.functionalityIcon,
    language: state.language,
    name: ownProps.name,
    wording: ownProps.wording,
    setRoute: ownProps.setRoute,
    reperageData: state.moduleData.reperageData,
    selections: state.selections,
    projectData: state.projectData,
    config: state.config,
  };
};

export default connect(mapStateToProps, mapDispatch)(ReperageLanding);
