import React, { Component } from "react";
import "./RiggibsDeleteStyles.scss";
import { connect } from "react-redux";
import { DrawStore } from "../Reducers";
import { Routes } from "./root";
import SelectionLanding from "./SelectionLanding";
import GroupSelector from "./GroupSelector";
import { initLanguage } from "../Actions";

interface Props {
    route: Routes;
    setRoute: any;
    initLanguage: any;
}

export class AppBody extends Component<Props> {
    componentDidMount() {
        this.props.initLanguage();
    }

    render() {
        switch (this.props.route) {
            case Routes.ROOT:
                return (
                    <SelectionLanding
                        routes={this.props.route}
                        setRoute={this.props.setRoute}
                    />
                );
            case Routes.GROUP_SELECTION:
                return (
                    <GroupSelector
                        routes={this.props.route}
                        setRoute={this.props.setRoute}
                    />
                );
            default:
                return null;
        }
    }
}

const mapStateToProps = (state: DrawStore, ownProps: any) => {
    return {
        setRoute: ownProps.setRoute,
        route: ownProps.route,
    };
};

const mapDispatchToProps = {
    initLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBody);
