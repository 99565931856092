import { Language, ElementsTree } from "../../../../RevitJS/Types/RevitTypes";
import { combineReducers } from "redux";
import {
  SET_SELECTION_TREE,
  SelectionTreeAction,
  SelectorAction,
  INIT_GROUP_SELECTOR,
  SELECT_LEVEL,
  SELECT_TYPE,
  SET_CEILING_SELECTION_TREE,
  SELECT_CEILING_LEVEL,
  SELECT_CEILING_TYPE,
  SET_ELEMENT_TYPE,
} from "../Actions/types";

import { TypeData, LevelData } from "../../../../RevitJS/Types/StoreTypes";
import { levelData, elementTypes } from "../../../../RevitJS/Helpers";
import { FilterTypeEnabled } from "../../../../RevitJS/Types/SelectorTypes";

import { LanguageAction } from "../../../../Actions/types";
import { Tree } from "../../Nomenclature/Actions/types";
import { config } from "../../../CLT/Delete/Reducers";

export const language = (
  language: Language = "French",
  action: LanguageAction
): Language => {
  switch (action.type) {
    // case INIT_LANGUAGE:
    //   return action.language;
    default:
      return language;
  }
};

export const functionalityIcon = (
  functionalityIcon = "/PlacoBIM.jpg",
  action: any
): string => {
  switch (action.type) {
    default:
      return functionalityIcon;
  }
};

export const selectionTree = (
  selectionTree: ElementsTree = { Tree: [] },
  action: SelectionTreeAction | SelectorAction
) => {
  switch (action.type) {
    case SET_SELECTION_TREE:
      return action.tree;
    case INIT_GROUP_SELECTOR:
      return action.tree;
    default:
      return selectionTree;
  }
};

export const ceilingSelectionTree = (
  ceilingTree: ElementsTree = { Tree: [] },
  action: SelectionTreeAction | SelectorAction
) => {
  switch (action.type) {
    case SET_CEILING_SELECTION_TREE:
      return action.tree;
    case INIT_GROUP_SELECTOR:
      return action.ceilingTree;
    default:
      return ceilingTree;
  }
};

export const levelsData = (
  levelsData: LevelData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return action.tree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_LEVEL:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const ceilingLevelsData = (
  levelsData: LevelData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return action.ceilingTree.Tree.map((level) => ({
        Name: level.Level.Name,
        Checked: false,
      }));
    case SELECT_CEILING_LEVEL:
      return action.levelsData;
    default:
      return levelsData;
  }
};

export const typesData = (
  typesData: TypeData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return elementTypes(levelData(action.tree, [])) as TypeData[];
    case SELECT_LEVEL:
      return action.typesData;
    case SELECT_TYPE:
      return action.typesData;
    default:
      return typesData;
  }
};

export const ceilingTypesData = (
  typesData: TypeData[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case INIT_GROUP_SELECTOR:
      return elementTypes(levelData(action.ceilingTree, [])) as TypeData[];
    case SELECT_CEILING_LEVEL:
      return action.typesData;
    case SELECT_CEILING_TYPE:
      return action.typesData;
    default:
      return typesData;
  }
};

export const selectorType = (selectorType: string = "wall", action: any) => {
  switch (action.type) {
    default:
      return selectorType;
  }
};

export const elementType = (
  elementType: "wall" | "ceiling" = "wall",
  action: any
) => {
  switch (action.type) {
    case SET_ELEMENT_TYPE:
      return action.elementType;
    default:
      return elementType;
  }
};

export const selectedTypes = (
  selectedTypes: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedTypes;
    case SELECT_TYPE:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedCeilingTypes = (
  selectedTypes: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_CEILING_LEVEL:
      return action.selectedTypes;
    case SELECT_CEILING_TYPE:
      return action.selectedTypes;
    default:
      return selectedTypes;
  }
};

export const selectedLevels = (
  selectedLevels: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_LEVEL:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const selectedCeilingLevels = (
  selectedLevels: string[] = [],
  action: SelectorAction
) => {
  switch (action.type) {
    case SELECT_CEILING_LEVEL:
      return action.selectedLevels;
    default:
      return selectedLevels;
  }
};

export const filterTypeEnabled = (
  filterTypeEnabled: FilterTypeEnabled = "partitionsWalls",
  action: any
) => {
  switch (action.type) {
    default:
      return filterTypeEnabled;
  }
};

export const reducers = combineReducers({
  language,
  functionalityIcon,
  selectionTree,
  levelsData,
  typesData,
  selectedLevels,
  selectedTypes,
  ceilingSelectionTree,
  ceilingLevelsData,
  ceilingTypesData,
  selectedCeilingTypes,
  selectedCeilingLevels,
  elementType,
  config,
});

export interface DrawStore {
  language: Language;
  functionalityIcon: string;
  selectionTree: ElementsTree;
  levelsData: LevelData[];
  typesData: TypeData[];
  selectedLevels: string[];
  selectedTypes: string[];
  tree: Tree;
  ceilingSelectionTree: ElementsTree;
  ceilingLevelsData: LevelData[];
  ceilingTypesData: TypeData[];
  selectedCeilingTypes: string[];
  selectedCeilingLevels: string[];
  elementType: "wall" | "ceiling";
  config: any;
}
