import React from "react";
import { Provider } from "react-redux";
import AppBody from "./AppBody";
import store from "../../Store";

export enum Routes {
  ROOT = "ROOT"
}

const Root = () => {
  const [route, setRoute] = React.useState(Routes.ROOT);

  return (
    <Provider store={store}>
      <AppBody route={route} setRoute={setRoute} />
    </Provider>
  );
};

export default Root;
