import { IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React from "react";
import PixelLoader from "../loader/PixelLoader";
import "./PixelTable.scss";
import PixelTableHead from "./PixelTableHead";
import {
  createFilterList,
  getComparator,
  stableFilter,
  stableSort,
} from "./PixelTableUtils";

const PixelTable = ({
  headCells,
  rows,
  deleteRow,
  modifyProject,
  loading = false,
  inputValueChanged = (project, key, value) => {
    console.log("INPUT VALUE CHANGED NOT IMPLEMENTED");
  },
  openUpdateRowModal,
  noSavedProjectsLabel,
  t
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [filters, setFilter] = React.useState({ ...createFilterList(rows) });
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // FOR PAGINATION ONLY
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestFilter = (filterName, value) => {
    let newFilters = { ...filters, [filterName]: value };

    setFilter(newFilters);
    stableFilter(rows, newFilters);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const editValue = (trIndex, tdIndex) => {
    document
      .getElementById(`pixel-table-body-item-td-span-${trIndex}-${tdIndex}`)
      .setAttribute("style", "display:none");

    let inputElement = document.getElementById(
      `pixel-table-body-item-td-input-${trIndex}-${tdIndex}`
    );

    inputElement.setAttribute("style", "display: inline-block !important");
    inputElement.focus();
    document
      .getElementById(`pixel-table-body-item-td-btn-${trIndex}-${tdIndex}`)
      .setAttribute("style", "display: inline-block !important");
    document
      .getElementById(
        `pixel-table-body-item-td-cancel-btn-${trIndex}-${tdIndex}`
      )
      .setAttribute("style", "display: inline-block !important");
  };

  const validateEditValue = (trIndex, tdIndex, row, rowKey) => {
    let inputElement = document.getElementById(
      `pixel-table-body-item-td-input-${trIndex}-${tdIndex}`
    );
    let isDuplicate = rows.some(element => { return (element.name === inputElement.value && element.id !== row.id) });

    if (isDuplicate) {
      document.getElementById(`pixel-table-body-item-td-error-msg-${trIndex}-${tdIndex}`).style.display = "block";
      return;
    }
    else {
      document.getElementById(`pixel-table-body-item-td-error-msg-${trIndex}-${tdIndex}`).style.display = "none";
    }

    inputElement.setAttribute("style", "display: none !important");
    document
      .getElementById(`pixel-table-body-item-td-btn-${trIndex}-${tdIndex}`)
      .setAttribute("style", "display: none !important");
    document
      .getElementById(
        `pixel-table-body-item-td-cancel-btn-${trIndex}-${tdIndex}`
      )
      .setAttribute("style", "display: none !important");

    document
      .getElementById(`pixel-table-body-item-td-span-${trIndex}-${tdIndex}`)
      .setAttribute("style", "display: inline-block !important");
    inputValueChanged(row, rowKey, inputElement.value);
  };

  const cancelEditValue = (trIndex, tdIndex, row, key) => {
    let inputElement = document.getElementById(
      `pixel-table-body-item-td-input-${trIndex}-${tdIndex}`
    );
    document.getElementById(`pixel-table-body-item-td-error-msg-${trIndex}-${tdIndex}`).style.display = "none";
    inputElement.setAttribute("style", "display: none !important");
    inputElement.value = row[key];
    document
      .getElementById(`pixel-table-body-item-td-btn-${trIndex}-${tdIndex}`)
      .setAttribute("style", "display: none !important");
    document
      .getElementById(
        `pixel-table-body-item-td-cancel-btn-${trIndex}-${tdIndex}`
      )
      .setAttribute("style", "display: none !important");

    document
      .getElementById(`pixel-table-body-item-td-span-${trIndex}-${tdIndex}`)
      .setAttribute("style", "display: inline-block !important");
  };

  return (
    <div id="pixel-table-container">
      <Paper>
        <TableContainer>
          <table
            className="w-100"
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <PixelTableHead
              rows={rows}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              filters={filters}
              setFilter={handleRequestFilter}
            />
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <PixelLoader></PixelLoader>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {rows.length > 0 ? (
                  stableFilter(
                    stableSort(rows, getComparator(order, orderBy)),
                    filters
                  )
                    // FOR PAGINATION ONLY
                    // .slice(
                    //     page * rowsPerPage,
                    //     page * rowsPerPage + rowsPerPage
                    // )
                    .map((row, trIndex) => {
                      return (
                        <tr key={`pixel-table-body-item-${trIndex}`}>
                          {headCells.map((cell, tdIndex) => {
                            return (
                              <td
                                key={cell.id}
                                align="center"
                                onDoubleClick={(event) => {
                                  editValue(trIndex, tdIndex);
                                }}
                              >
                                <span
                                  id={`pixel-table-body-item-td-span-${trIndex}-${tdIndex}`}
                                >
                                  {row[cell.id]}
                                </span>
                                <input
                                  id={`pixel-table-body-item-td-input-${trIndex}-${tdIndex}`}
                                  className="d-none update-input"
                                  defaultValue={row[cell.id]}
                                />
                                <button
                                  id={`pixel-table-body-item-td-btn-${trIndex}-${tdIndex}`}
                                  className="d-none icon-btn btn-green"
                                  style={{
                                    color: "green !important",
                                  }}
                                  onClick={(event) => {
                                    validateEditValue(
                                      trIndex,
                                      tdIndex,
                                      row,
                                      cell.id
                                    );
                                  }}
                                >
                                  <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                                </button>
                                <button
                                  id={`pixel-table-body-item-td-cancel-btn-${trIndex}-${tdIndex}`}
                                  className="d-none icon-btn btn-red"
                                  onClick={(event) => {
                                    cancelEditValue(
                                      trIndex,
                                      tdIndex,
                                      row,
                                      cell.id
                                    );
                                  }}
                                >
                                  <CancelOutlinedIcon></CancelOutlinedIcon>
                                </button>
                                <div id={`pixel-table-body-item-td-error-msg-${trIndex}-${tdIndex}`}
                                  style={{ fontSize: "9px", color: "red", display: "none" }}>
                                  {t("PROJECT_ALREADY_EXISTS")}
                                </div>
                              </td>
                            );
                          })}
                          {/* ACTION TD */}
                          <td align="center" style={{ width: "10%" }}>
                            <IconButton
                              color={
                                row.allAvailableElements.length === 0
                                  ? "default"
                                  : "primary"
                              }
                              aria-label="Modifier le projet"
                              component="span"
                              disabled={
                                row.allAvailableElements.length +
                                row.looseElements.length ===
                                0
                              }
                              onClick={() => modifyProject(row)}
                            >
                              <EditIcon></EditIcon>
                            </IconButton>
                            <IconButton
                              color="primary"
                              aria-label="Supprimer le projet"
                              component="span"
                              onClick={() => deleteRow(row, trIndex)}
                            >
                              <DeleteIcon></DeleteIcon>
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={2} align="center">
                      <p
                        className="text-center mt-3"
                        id="no-projects-tables-text"
                      >
                        {noSavedProjectsLabel}
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </TableContainer>
        <div className="pixel-table-footer"></div>
      </Paper>
    </div>
  );
};

PixelTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  deleteRow: PropTypes.func,
  modifyProject: PropTypes.func,
  openUpdateRowModal: PropTypes.func,
  inputValueChanged: PropTypes.func,
  loading: PropTypes.bool,
  noSavedProjectsLabel: PropTypes.string
};

export default PixelTable;
