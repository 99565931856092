import { Plugin } from "../../RevitJS/Types/StoreTypes";
import { ObjectGenerator } from "./ObjectGenerator";
import { Configuration } from "./Configuration";
import { IFCGenerator } from "./IFCGenerator";
import { ConvertRevitVersion } from "./ConvertRevitVersion";
import "./Common/Common.scss";

export const pluginId = "81b41a9f-2200-402b-ac72-4570738453e2";

export const ModelGenerator: Plugin = {
  TechnicalName: "ModelGeneratorPlugin",
  Name: "Model Generator",
  Icon: "/ModelGenerator/plugin-logo.png",
  ShortDesc: {
    English: "BIM Object Generator",
    French: "Generateur d'objets BIM",
    // French: "Generateur d'objets BIM Local",
  },
  Id: pluginId,
  Color: "#000000",
  className: "model-generator",
  Functionalities: [
    ObjectGenerator,
    Configuration,
    IFCGenerator,
    ConvertRevitVersion,
  ],
};
