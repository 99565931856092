import React from "react";
import { Routes } from "./root";
import UpdateToolBoxWindow from "./UpdateToolBoxWindow";

interface Props {
  route: Routes;
  setRoute: any;
}
export const AppBody = (props: Props) => {
  switch (props.route) {
    case Routes.ROOT:
      return <UpdateToolBoxWindow />;
    default:
      return null;
  }
};

export default AppBody;
