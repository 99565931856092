import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  Header,
  Table,
  Checkbox,
  Button,
  Segment,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { parseSystem } from "../../../../RevitJS/Helpers";
// import { hideLayoutDetails } from '../Actions';
// import { parseSystem } from "../../Helpers";
// import {wording} from '../Ressources/wording';
// import { SideA } from './SideA';

const language = "French";

const row = (key, value) => {
  if (key) {
    return (
      <Table.Row>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
      </Table.Row>
    );
  }
  return null;
};

export class ConfigurationView extends React.Component {
  state = {
    systemConf: null
  };

  render() {
    if (this.props.selectionBuffer !== null) {
      if (this.props.layoutDetailsDisplayed) {
      
        let systemConf = parseSystem(
          this.props.selectionBuffer,
          this.props.layoutAttributesAid
        );
       
        return (
          <Dimmer active={this.props.layoutDetailsDisplayed}>
            <Segment
              style={{ width: 850, padding: 20, height: 450, overflow: "auto" }}
            >
              <Header>{systemConf.systemName}</Header>
              {/* {center()} */}
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Header textAlign="left">{this.props.wording.sideA[language]}</Header>
                <Table celled>
                  <Table.Body>
                    {systemConf.E1_Name !== "None"
                      ? row(systemConf.E1_Name, systemConf.E1)
                      : null}
                    {systemConf.E2_Name !== "None"
                      ? row(systemConf.E2_Name, systemConf.E2)
                      : null}
                    {systemConf.E3_Name !== "None"
                      ? row(systemConf.E3_Name, systemConf.E3)
                      : null}
                  </Table.Body>
                </Table>
              </div>

              {!systemConf.Lining && <div style={{marginTop: 10, marginBottom: 10}}>
                <Header textAlign="left">{this.props.wording.sideB[language]}</Header>
                <Table celled>
                  <Table.Body>
                    {systemConf.I1_Name !== "None"
                      ? row(systemConf.I1_Name, systemConf.I1)
                      : null}
                    {systemConf.I2_Name !== "None"
                      ? row(systemConf.I2_Name, systemConf.I2)
                      : null}
                    {systemConf.I3_Name !== "None"
                      ? row(systemConf.I3_Name, systemConf.I3)
                      : null}
                  </Table.Body>
                </Table>
              </div>}

              <Button
                floated="right"
                color="green"
                onClick={e => {
                  this.props.hideLayoutDetails();
                }}
              >
                {" "}
                OK{" "}
              </Button>
            </Segment>
          </Dimmer>
        );
      } else {
        return null;
      }
    }

    return (
      <Dimmer active={this.props.layoutDetailsDisplayed}>
        <Segment
          style={{ width: 850, padding: 20, height: 450, overflow: "auto" }}
        >
          <Header></Header>
          {/* {center()} */}

          <Button
            floated="right"
            color="green"
            onClick={e => {
              this.props.hideLayoutDetails();
            }}
          >
            {" "}
            OK{" "}
          </Button>
        </Segment>
      </Dimmer>
    );
  }
}

// const mapStateToProps = state => {
//     return {
//         layoutDetailsDisplayed: state.layoutDetailsDisplayed,
//         layoutAttributesAid: state.layoutAttributesAid,
//         partitionsDetails : state.partitionsDetails,
//         detailOid: state.detailOid,
//         productDetailName: state.productDetailName
//     }
// }

ConfigurationView.defaultProps = {
  productName: "Cloison Placostil® 120/70 Lisaflam® - EI 120 - 50dB - 4,95m",
  wording: {
    lining: {
      French: "Doublage",
      English: "Lining"
    },
    sideA:{
      French: "Parement A",
      English: "Side A"
    },
    sideB:{
      French: "Parement B",
      English: "Side B"
    },
    rails:{
      French: "Rails",
      English: "Rails"
    },
    frames:{
      French: "Ossatures",
      English: "Frames"
    }
  },
  lining: false,
  E1: 12.5,
  E1_Name: "Aquaroc 13",
  E2: 12.5,
  E2_Name: "Aquaroc 13",
  E3: 12.5,
  E3_Name: "Aquaroc 13",
  I1: 12.5,
  I1_Name: "Aquaroc 13",
  I2: 12.5,
  I2_Name: "Aquaroc 13",
  SAA: false,
  SAD: true
};

// export default connect(mapStateToProps, {
//     hideLayoutDetails
// })(ConfigurationView);
