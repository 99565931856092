import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Routes } from "./root";
import {
  Container,
  Button,
  Message,
  Segment,
  Icon,
  Table,
} from "semantic-ui-react";
import { FunctionalityHeader } from "../../Common/Components/functionalityHeader";
import BatchPanel from "./BatchPanel";
import { MyConfig } from "../../../../Helper";

interface Props {
  setRoute: any;
}
const SelectedProducts = (props: Props) => {

  const [config, setConfig] = useState({REACT_APP_SERVERURL : ""})

  useEffect(() => {
    async function getConfig(){
      const cng = await MyConfig();
      setConfig(cng);
    }
    getConfig();
  }, [])
  
  return (
    <>
      <FunctionalityHeader
        Icon={config.REACT_APP_SERVERURL + "/ModelGenerator/plugin-logo.png"}
        name={"Object Generator"}
        subheader={"From Database / XLS"}
        config={config}
      />
      <Container style={{ width: "95%" }}>
        {/* <Message attached header="Selected Products" /> */}

        <Segment className="productCont" style={{ paddingTop: "20px" }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "5%" }}> </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "75%" }}>
                  Product
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "10%" }}>
                  View
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "10%" }}>
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {/* {this.state.batchList.map((data) => ( */}
              <BatchPanel
              // data={data}
              // onStopProcessing={() => this.onStopProcessing(data)}
              // readProductInfo={this.readProductInfo}
              ></BatchPanel>
              {/* ))} */}
            </Table.Body>
          </Table>
        </Segment>
        {/* <Message
            attached="bottom"
            success
            
          >
            <Icon name="attention" />
            Objects downloaded
          </Message> */}
        <Segment style={{ textAlign: "center" }}>
          <Button
            color="blue"
            onClick={() => props.setRoute(Routes.PRODUCT_LIST)}
          >
            Back
          </Button>
          <Button
            color="green"
            //onClick={this.onGenerateClick}
          >
            Generate
          </Button>
          <Button color="green" className={"divHide"}>
            Packages
          </Button>
          <Button color="green" className={"divHide"}>
            Logs
          </Button>
          <Button
            color="red"
            style={{ float: "right" }}
            onClick={() => props.setRoute(Routes.ROOT)}
          >
            Cancel
          </Button>
        </Segment>
      </Container>
    </>
  );
};

export default SelectedProducts;
