import React from "react";
import ParentRoot from "../../../Components/CommonComponents/Root/ParentRoot";
import { api } from "../../../RevitJS/API";
import { Functionality } from "../../../RevitJS/Types/StoreTypes";
import { pluginId } from "../index";
import Root from "./Components/root";

export const functionalityId = "2859247c-9dbb-4b05-a53f-6a44374c7a2b";

export const ifcGeneratorTrigger = (config:any) => {
  api.windowsHandler.openWindow(
    600,
    200,
    // `${config.REACT_APP_SERVERURL}/#/${pluginId}/${functionalityId}`
    `${config.REACT_APP_SERVERURL}plugin/${pluginId}/${functionalityId}`
  );
};

const RootComponent = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const ModelGenIFCGeneratorRoot = () => {
  return (
    <ParentRoot
      pluginId={pluginId}
      functionalityId={functionalityId}
      module={""}
      textColor="rgb(112,173,71)"
    >
      <Root />
    </ParentRoot>
  );
};

export const IFCGenerator: Functionality = {
  Name: {
    English: "IFC Generator",
  },
  Trigger: ifcGeneratorTrigger,
  ShortDesc: {
    English: "From Existing Revit Objects",
  },
  Id: functionalityId,
  Icon: "/ModelGenerator/IFCgenerator.png",
  RootComponent,
  ParentId: "0",
};
