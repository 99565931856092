import React from 'react';
import { levelsData, selectedLevels } from '../../../PlacoBIM/Draw2/Reducers';
import {LevelItem} from './LevelItem';

export const LevelItems = props =>{
    props.levelsData.sort((a, b) => (a.Name > b.Name) ? -1 : 1)
    return (props.levelsData.map(item =>
         <LevelItem selectLevel={props.selectLevel} key={item.Name}  name={item.Name} checked={item.Checked} color={props.color}/>))
};

LevelItems.defaultProps={
    levelsData: [

    ]
}