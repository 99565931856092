import PouchDB from "pouchdb";
import {
  ProductDetailData,
  ProductMeta,
} from "../../../../RevitJS/Types/BddTypes";
import { SystemDetails } from "../../../../Components/ProductsBrowser/SystemDetails";
import { addFilterFields, setFiltersOptions } from "../utils/utils";
import { setFiltersAid } from "../../../../RevitJS/Helpers";
import { ID } from "../../../../RevitJS/Types/RevitTypes";
import { api } from "../../../../RevitJS/API";
import { bdd } from "../../../PAM/pam-import/helpers/interceptors";

let db = new PouchDB("pam_projects_db");

export const getProjectsDetails = async (
  elementObjects: ProductMeta[],
  config: any
): Promise<ProductDetailData[]> => {
  let pipeObject: ProductDetailData[] = await bdd(
    "/objects/details/class/SGObject/locale/fr",
    [
      ...elementObjects
        .map((pipe) => {
          return pipe.oid;
        })
        .slice(20),
    ],
    config
  ).then(function (response: any) {
    return response.data.objects;
  });

  return pipeObject;
};

export const loadProjects = async (config: any): Promise<ProductMeta[]> => {
  let placoBrand = await bdd(
    "/objects/class/Brand/locale/fr",
    {
      text: "PLACO ",
    },
    config
  );
  const products: ProductMeta[] = await getPlacoProduts(
    placoBrand.data.objects[0].oid,
    config
  ).then(function (response: any) {
    return response.data.objects;
  });

  return products;
};

export const getSystemDetailsById = async (
  ids: ID[],
  config: any
): Promise<{ data: { context: any; objects: SystemDetails[] } }> => {
  const ids_container = split_array(ids);
  const systemDetailsRequest: {
    data: { context: any; objects: SystemDetails[] };
  } = await Promise.all(
    ids_container.map((ids) => getPlacoSystemsDetails(ids, config))
  ).then((systemDetailsRequests) => {
    const systemDetailsRequest = {
      data: {
        context: systemDetailsRequests[0]?.data.context,
        objects: [],
      },
    };
    for (let i = 0; i < systemDetailsRequests.length; i++) {
      systemDetailsRequest.data.objects =
        systemDetailsRequest.data.objects.concat(
          systemDetailsRequests[i].data.objects
        );
    }
    return systemDetailsRequest;
  });

  return systemDetailsRequest;
};

export const getPlacoSystemsDetails = (systemOid: ID[], config: any) =>
  bdd("/objects/details/class/SGObject/locale/fr", systemOid, config);

export const getPlacoSystemsDetailsWithRelations = (
  systemOid: ID[],
  config: any
) =>
  bdd(
    "/objects/details/class/SGObject/locale/fr?includeRelatedObjects=true",
    systemOid,
    config
  );

const split_array = (arr: any[], chunk = 400): any[][] => {
  let res = [];
  var i, j, temparray;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray = arr.slice(i, i + chunk);
    res.push(temparray);
  }
  return res;
};

export const getSystemsDetailsAndFilters = async (
  ids: ID[],
  filters: any[],
  config: any
): Promise<{
  systemsDetails: ProductDetailData[];
  filters: any[];
  attributes: any[];
}> => {
  let systemDetailsRequest = await getSystemDetailsById(ids, config);
  let systemsDetails = systemDetailsRequest.data.objects.map((sys) => {
    return { ...sys, filterFields: {} } as unknown;
  }) as ProductDetailData[];
  let attributes =
    systemDetailsRequest.data.context.attributeDependencies[0].attributes;

  filters = setFiltersAid(filters, attributes);

  systemsDetails = addFilterFields(systemsDetails, filters);
  filters = setFiltersOptions(filters, systemsDetails);

  return {
    systemsDetails,
    filters,
    attributes,
  };
};

const getPlacoProduts = (brandOid: string, config: any) =>
  bdd(
    "/objects/class/SGObject/locale/fr",
    {
      dependencies: [
        {
          className: "Brand",
          oids: [brandOid],
        },
      ],
      types: [],
      principal: true,
    },
    config
  );

export const getCustomParametersFromElementType = async () => {
  const systemElement = await api.queries.getCustomParametersFromElementType();
  return systemElement && systemElement.length > 0
    ? systemElement
    : currentSystems;
};

const currentSystems = [
  {
    solution: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 3,75m",
    elementType: "wall",
    paramData: [
      {
        category: "Performances Mécaniques",
        key: "Résistance aux chocs (J)",
        value: "120.0",
      },
      {
        category: "Performances Mécaniques",
        key: "Dureté superficielle",
        value: "THD",
      },
      {
        category: "Performances Mécaniques",
        key: "Temps de résistance à l effraction(min)",
      },
      {
        category: "Performances Mécaniques",
        key: "Hauteur limite (m)",
        value: "3.75",
      },
      {
        category: "Performances Acoustiques",
        key: "Epaisseur d isolant(mm)",
      },
      {
        category: "Performances Acoustiques",
        key: "Type d'isolant",
        value: "Laine de verre",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique (Ra en dB)",
        value: "52.0",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique Rw(C,Ctr) (en dB)",
      },
      {
        category: "Performances Acoustiques",
        key: "N° PV Acoustique",
        value: "Simulation AcouS STIFF® ",
      },
    ],
  },
  {
    solution: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 4,15m",
    elementType: "wall",
    paramData: [
      {
        category: "Performances Mécaniques",
        key: "Résistance aux chocs (J)",
        value: "120.0",
      },
      {
        category: "Performances Mécaniques",
        key: "Dureté superficielle",
        value: "THD",
      },
      {
        category: "Performances Mécaniques",
        key: "Temps de résistance à l effraction(min)",
      },
      {
        category: "Performances Mécaniques",
        key: "Hauteur limite (m)",
        value: "4.15",
      },
      {
        category: "Performances Acoustiques",
        key: "Epaisseur d isolant(mm)",
      },
      {
        category: "Performances Acoustiques",
        key: "Type d'isolant",
        value: "Laine de verre",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique (Ra en dB)",
        value: "52.0",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique Rw(C,Ctr) (en dB)",
      },
      {
        category: "Performances Acoustiques",
        key: "N° PV Acoustique",
        value: "Simulation AcouS STIFF® ",
      },
    ],
  },
  {
    solution: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 4,5m",
    elementType: "wall",
    paramData: [
      {
        category: "Performances Mécaniques",
        key: "Résistance aux chocs (J)",
        value: "120.0",
      },
      {
        category: "Performances Mécaniques",
        key: "Dureté superficielle",
        value: "THD",
      },
      {
        category: "Performances Mécaniques",
        key: "Temps de résistance à l effraction(min)",
      },
      {
        category: "Performances Mécaniques",
        key: "Hauteur limite (m)",
        value: "4.5",
      },
      {
        category: "Performances Acoustiques",
        key: "Epaisseur d isolant(mm)",
      },
      {
        category: "Performances Acoustiques",
        key: "Type d'isolant",
        value: "Laine de verre",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique (Ra en dB)",
        value: "52.0",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique Rw(C,Ctr) (en dB)",
      },
      {
        category: "Performances Acoustiques",
        key: "N° PV Acoustique",
        value: "Simulation AcouS STIFF® ",
      },
    ],
  },
  {
    solution: "Cloison Aquaroc® 120/70 - EI 90 - 52dB - 4,95m",
    elementType: "wall",
    paramData: [
      {
        category: "Performances Mécaniques",
        key: "Résistance aux chocs (J)",
        value: "120.0",
      },
      {
        category: "Performances Mécaniques",
        key: "Dureté superficielle",
        value: "THD",
      },
      {
        category: "Performances Mécaniques",
        key: "Temps de résistance à l effraction(min)",
      },
      {
        category: "Performances Mécaniques",
        key: "Hauteur limite (m)",
        value: "4.95",
      },
      {
        category: "Performances Acoustiques",
        key: "Epaisseur d isolant(mm)",
      },
      {
        category: "Performances Acoustiques",
        key: "Type d'isolant",
        value: "Laine de verre",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique (Ra en dB)",
        value: "52.0",
      },
      {
        category: "Performances Acoustiques",
        key: "Affaiblissement acoustique Rw(C,Ctr) (en dB)",
      },
      {
        category: "Performances Acoustiques",
        key: "N° PV Acoustique",
        value: "Simulation AcouS STIFF® ",
      },
    ],
  },
];
