import React from "react";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";
import { useState } from "react";
import { IWording } from "../../Types";
import { ESelectionMethod } from "../../Types";

interface Props {
  show: boolean;
  onSave: (method: ESelectionMethod) => void;
  onClose: () => void;
}

const wording: IWording = {
  newManualSelection: {
    French: "Manuelle",
    English: "Manual",
  },
  newGroupSelection: {
    French: "Groupée",
    English: "Group",
  },
};

export const SelectionMethodPopup = (props: Props) => {
  const { show, onSave, onClose } = props;

  return (
    <div>
      <div style={{ height: "100%" }}>
        <Grid.Column>
          <div style={{ marginTop: 12 }}>
            <Modal
              size="tiny"
              dimmer="blurring"
              className="modal-close"
              closeIcon
              open={show}
              onClose={() => onClose()}
            >
              <Modal.Content>
                <Grid columns={2} padded={true}>
                  <Grid.Row style={{ padding: "2.5rem 0.5rem 1.5rem" }}>
                    <Grid.Column>
                      <Button
                        style={{ minHeight: 80 }}
                        fluid
                        color="blue"
                        onClick={() => {
                          onSave(ESelectionMethod.MANUAL);
                        }}
                      >
                        {wording.newManualSelection["English"]}
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        style={{ minHeight: 80 }}
                        fluid
                        color="blue"
                        onClick={() => {
                          onSave(ESelectionMethod.GROUP);
                        }}
                      >
                        {wording.newGroupSelection["English"]}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          </div>
        </Grid.Column>
      </div>
    </div>
  );
};

export default SelectionMethodPopup;
